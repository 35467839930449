<template>
  <div
    class="tag-selector"
    :class="{'selected': selected, 'removed': removed, 'tag-selector__skeleton': isLoading}"
    :title="label">
    <template v-if="!isLoading">
      <div v-if="highlightFirstLetter" class="tag-selector__label">
        <span style="font-weight: 500">{{ label.charAt(0) }}</span>{{ label.slice(1) }}
      </div>
      <div v-else class="tag-selector__label">
        {{ label }}
      </div>
      <div
        class="accept-button" @click="$emit('accept')"
        v-if="showAcceptButton">
        <a
          class="icon-check"
        />
      </div>
      <div class="deny-button" @click="$emit('deny')" v-if="showDenyButton">
        <a
          class="icon-cross"
        />
      </div>
    </template>
  </div>
</template>

<script>
  export default {
    emits: ['accept', 'deny'],
    props: {
      selected: { type: Boolean, default: false },
      removed: { type: Boolean, default: false },
      isLoading: { type: Boolean, default: false },
      showAcceptButton: { type: Boolean, default: false },
      showDenyButton: { type: Boolean, default: false },
      highlightFirstLetter: { type: Boolean, default: false },
      label: String,
    },
    computed: {},
    components: {},
  }
</script>

<style lang="scss" scoped></style>
