<template>
  <div>
    <template v-for="(_, index) in countRows">
      <div class="row">
        <div
          class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 home-simplified__space-vertical"
          v-for="(product, productIndex) in products.slice(index * 3, (index + 1) * 3)"
        >
          <SimpleCard
            :imgUrl="product.featured_image_url || product.website_screenshot || getDefaultFallbackImageUrl()"
            :title="product.to_name"
            :force-aspect-ratio="true"
            :content="localizedDisplayProperty(product, 'short_description') || localizedDisplayProperty(product, 'activities_description')"
            @click="handleClickActorCard(product)"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import SimpleCard from '../../components/SimpleCard/SimpleCard.vue'

  import { DEFAULT_LOCALE } from '../../store/modules/localization'
  import { trackHeapEvent } from '../../util/analytics'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { SiteTemplate } from '../../store/modules/config.js'
  import TranslationsMixin from '../../util/TranslationsMixin'
  import { getDefaultFallbackImageUrl } from '../../util/helpers.js'

  export default {
    name: 'ActorDetailOfferingProductGallery',
    props: {
      actor: Object,
    },
    computed: {
      locale () {
        return this.$store.getters.activeLocale
      },
      isSimplifiedTemplateUsed () {
        return [SiteTemplate.NEW_SIMPLIFIED, SiteTemplate.SIMPLIFIED].includes(this.$store.getters.activeSiteTemplate)
      },
      maybeLocale () {
        return this.isSimplifiedTemplateUsed ? this.locale : DEFAULT_LOCALE
      },
      countRows () {
        return Math.ceil(this.products.length / 3)
      },
      products () {
        const products = this.actor.has_product

        if (this.actor.members) {
          const memberProducts = this.actor.members.filter(member => member.actor_type === 'Product')

          for (let i = 0; i < memberProducts.length; i++) {
            products.push(memberProducts[i])
          }
        }

        return products
      },
    },
    methods: {
      getDefaultFallbackImageUrl,
      maybeTranslate (key) {
        return this.$t(key, { lang: this.maybeLocale })
      },
      localeEnabled (locale) {
        return this.$store.state.localization.enabledLocales.includes(locale)
      },
      handleClickActorCard (product) {
        // Track the click
        trackHeapEvent('actorDetail.offers.clickProductCard')

        // this.$router.push(`/actors/${product.to}`) // Use the pivot property "to", which is the ID of the related product
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'scores', metaData: { actorId: product.to, isPreview: true }})
      },
    },
    mixins: [
      TranslationsMixin,
    ],
    components: {
      SimpleCard,
    },
  }
</script>

<style scoped>

</style>
