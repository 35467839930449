<template>
  <div>
    <h3 class="h2">RSS Feeds</h3>
    <div class="guide_text">
      Configure RSS feeds that will be used to import data into your DataScouts platform.
    </div>
    <br>
    <table class="table table--has-button">
      <thead>
        <tr>
          <th>URL</th>
          <th>Display name</th>
          <th># Articles From Source</th>
          <th>Type</th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      <tr v-if="loading">
        <td colspan="6"><loading></loading></td>
      </tr>
        <tr v-for="feed in feeds">
          <td>{{ feed.value }}</td>
          <td v-if="! editingFeeds.includes(feed.id)">
            <a href="" @click="filterKnowledgeBaseBySource($event, feed)" v-tooltip="'Filter articles by source'">{{feed.display_name}}</a>
          </td>
          <td v-else>
            <ds-input v-model="feed.display_name" />
          </td>
          <td>{{ feed.article_count }}</td>
          <td>{{ feed.type }}</td>

          <template v-if="! editingFeeds.includes(feed.id)">
            <td>
              <ds-button size="small" icon="edit" @click="edit(feed.id)" />
            </td>
            <td>
              <ds-button size="small" icon="trash" @click="remove(feed.id)" />
            </td>
          </template>
          <template v-else>
            <td>
              <ds-button size="small" icon="check" @click="acceptChange(feed)" />
            </td>
            <td>
              <ds-button size="small" icon="remove" @click="cancelChange(feed.id)" />
            </td>
          </template>
        </tr>
      </tbody>
    </table>

    <br>
    <br>

    <form class="panel panel-default" v-if="showAdd" @submit.prevent="add">
        <h3 class="h2">Add new data feed</h3>
        <form-group label="URL" errors="value" :errors="errors.value">
          <ds-input v-model="form.value" />
        </form-group>
        <form-group label="Type" errors="type" :errors="errors.type">
          <dropdown :options="typeOptions" v-model="form.type" />
        </form-group>
        <ds-button variant="secondary" type="submit" :icon="adding ? 'spinner' : 'plus'" label="Add feed" :disabled="adding"/>
        <ds-button variant="outline" label="Cancel" @click="showAdd = false" :disabled="adding"/>
      </form>
      <ds-button v-else icon="plus" label="Add a data feed" @click="addFeed" />
      <p class="form-group__error" v-if="errors.message">
        {{errors.message}}
      </p>
    </div>
</template>

<script>

import Dropdown from '../Dropdown/Dropdown.vue'

import { Feeds } from '../../api/feeds.js'
import { defineComponent } from 'vue'
import { MUTATION_TYPES as KNOWLEDGE_BASE_MUTATION_TYPES } from '@/store/modules/knowledgeBase'
import Loading from '@/components/Dashboard/ConceptMap/Loading.vue'

export default defineComponent({
  name: 'feeds',
  data () {
    return {
      feeds: [],
      loading: true,
      editingFeeds: [],
      // Form variables
      showAdd: false,
      adding: false,
      form : {
        value: "",
        type: "rss"
      },
      errors: {}
    }
  },
  computed: {
    typeOptions () {
      if (['local', 'vopak-monitoring'].includes(this.$store.getters.ecosystemName.toLowerCase())) {
        return ['rss', 'reddit', 'news_feed']
      }

      return ['rss', 'reddit']
    }
  },
  methods: {
    filterKnowledgeBaseBySource (event, feed) {
      event.preventDefault()

      this.addTagToFilters(event, { value: feed.web_domain_id, label: feed.domain }, 'source')
    },
    addTagToFilters (event, tag, facet = 'tags') {
      const tagFilter = { facet: facet, value: tag.value, label: tag.label }

      this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.CLEAR)


      let filters = []
      filters.push(tagFilter)

      this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.SET_KNOWLEDGE_BASE_FILTERS, filters)

      this.$router.push('/dashboards/knowledge-base')
    },
    acceptChange (feed) {
      Feeds.post(feed);

      var index = this.editingFeeds.indexOf(feed.id);

      if (index > - 1) {
        this.editingFeeds.splice(index, 1)
      }
    },
    cancelChange (feedId) {
      var index = this.editingFeeds.indexOf(feedId);

      if (index > - 1) {
        this.editingFeeds.splice(index, 1)
      }
    },
    edit(feedId) {
      this.editingFeeds.push(feedId);
    },
    fetch () {
      Feeds.get()
        .then(feeds => {
          this.feeds = feeds.filter(feed => feed.type === 'rss' || feed.type === 'reddit' || feed.type === 'news_feed')
          this.loading = false
        })
        .catch(errors => {
          this.loading = false
        })
    },
    add () {
      this.errors = {};

      this.form.value = this.form.value.trim();

      Feeds.post(this.form)
        .then(feed => {
          // Reset the form fields
          this.showAdd = false
          this.form.value = ""
          this.form.type = "rss"
          this.fetch()
        })
        .catch(errors => {
          this.errors = errors;
          console.log(this.errors.message, "error")
        });
    },
    remove (feedId) {
      Feeds.delete(feedId)
        .then(response => {
          this.fetch()
        })
        .catch()
    },
    addFeed () {
      this.showAdd = true
    },
  },
  created () {
    this.fetch()
  },
  components: {
    Loading,
    Dropdown,
  }
})
</script>
