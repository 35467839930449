<template>
  <div class="avatar" :class="extraClasses" :style="{backgroundColor: backgroundColor}">
    <img
      class="avatar__img" v-if="(src&&error!==src) && tooltipTitle" :src="src" @error="error=src"
      :alt="alt" :title="alt" v-tooltip.top="alt"/>
    <img
      class="avatar__img" v-else-if="(src&&error!==src) && !tooltipTitle" :src="src" @error="error=src"
      :alt="alt" :title="alt"/>
    <div class="avatar__text" v-else>{{ initials }}</div>
    <div v-if="allowEdit" class="edit-icon">
      <icon name="edit" size="14"/>
      {{ editText }}
    </div>
    <div class="icon" v-if="icon">
      <icon :name="icon" size="14"/>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      src: {
        type: String,
      },
      alt: {
        type: String,
      },
      variant: {
        type: String,
        default: 'default',
      },
      allowEdit: {
        type: Boolean,
        default: false,
      },
      editText: {
        type: String,
      },
      tooltipTitle: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: String,
      },
      letters: {
        type: Array,
      },
      backgroundColor: {
        type: String,
        default: '#FFF',
      },
      color: {},
    },
    methods: {},
    computed: {
      initials () {
        return this.alt && this.alt[0] || ''
      },
    },
    data () {
      return {
        // Error will contain the url of the last image that threw an error
        error: false,
        extraClasses: {
          [`avatar--${this.variant}`]: this.variant,
        },
      }
    },
  })
</script>

<style lang="scss" scoped>
  :deep(.avatar) {
    width: 30px;
    height: 30px;
  }

  .avatar__text {
    padding-top: 3px;
    color: black;
  }

  .edit-icon {
    display: none;
    position: absolute;
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 10px;
    color: white;
    stroke: white;
  }

  .avatar {
    position: relative;

    &:hover {
      .edit-icon {
        display: flex;
      }
    }
  }
</style>
