import { fetchRichTagSuggestion, fetchTagSuggestion } from '../api/contents.js'
import { _unique } from './helpers.js'
import ConfigMixin from './ConfigMixin.js'

export default {
  data () {
    return {
      tagOptions: [], // Non "rich" way of providing tag options, eventually this will change to "rich" tags, providing an ID, possible URI and type
      richTagOptions: [],
    }
  },
  methods: {
    // NOTE: updating the tagOptions could happen in a configured way, for example
    // - only tags from suggestions
    // - tags from suggestions + taxonomy values
    // - ...
    // For now our only use case is tags + taxonomies
    updateTagOptions (tag) {
      // Assign a value to the tagOptions that is a merge of
      // 1/ filtered tag suggestions
      // 2/ filtered taxonomy suggestions
      // taking into account the given tag
      // Return an empty array if the tag is empty
      if (!tag || tag.length === 0) {
        this.tagOptions = []
      }

      // Create a list of taxonomy options
      let options = this.taxonomyOptions

      options = options.filter(label => label.toLowerCase().includes(tag.toLowerCase()))

      // Fetch a list of tag suggestions and update the tagOptions
      if (!this.enableFreeTextTags) {
        this.tagOptions = options
        return
      }

      fetchRichTagSuggestion(tag)
        .then(response => {
          if (!response || response.length === 0) {
            return options
          }

          for (let index = 0; index < response.length; index++) {
            options.push({ text: response[index].label, label: response[index].label, value: response[index].value })
          }

          options = _unique(options)

          this.tagOptions = options
        })
        .catch(() => {
          options = _unique(options)
          this.tagOptions = options
        })
    },
    updateRichTagOptions (tag) {
      let options = []

      // Fetch a list of tag suggestions and update the richTagOptions
      fetchRichTagSuggestion(tag)
        .then(response => {
          if (!response || response.length === 0) {
            return options
          }

          for (let index = 0; index < response.length; index++) {
            options.push({
              value: response[index].value,
              text: response[index].label,
            })
          }

          this.richTagOptions = options
        })
        .catch(() => {
          options = _unique(options)
          this.richTagOptions = options
        })
    },
    addChildrenForTwoLevelTaxonomy (options, taxonomy, childName) {
      if (taxonomy[childName] && taxonomy[childName].length > 0) {
        taxonomy[childName].map(child => {
          options.push({ value: child.name, label: child.name })
        })
      }
    }
  },
  computed: {
    enableFreeTextTags () {
      // true by default -> returns true if undefined
      return this.config.enableFreeTextTags !== false
    },
    activities () {
      return this.activityLabels
    },
    industries () {
      return this.$store.state.taxonomies.industries
    },
    domains () {
      return this.$store.state.taxonomies.domains
    },
    productFeaturesA () {
      return this.$store.state.taxonomies.productFeaturesA
    },
    productFeaturesB () {
      return this.$store.state.taxonomies.productFeaturesB
    },
    productFeaturesC () {
      return this.$store.state.taxonomies.productFeaturesC
    },
    aspects () {
      return this.$store.state.taxonomies.aspects
    },
    memberships () {
      return this.membershipLabels
    },
    technologies () {
      return this.technologyLabels
    },
    announcementCategories () {
      return this.$store.state.taxonomies.announcementCategories
    },
    eventCategories () {
      return this.$store.state.taxonomies.eventCategories
    },
    taxonomyOptions () {
      // We create a list of all the taxonomies so that the user can add them as tags in the announcement.
      let options = []

      const activities = this.activityLabels
      const industries = this.industries
      const memberships = this.membershipLabels
      const technologies = this.technologyLabels
      const domains = this.domains
      const expertises = this.expertiseLabels
      const productFeaturesA = this.productFeaturesA
      const productFeaturesB = this.productFeaturesB
      const productFeaturesC = this.productFeaturesC
      const motivations = this.motivationLabels
      const processSteps = this.processStepLabels
      const aspects = this.aspects
      const readinessLevels = this.readinessLevelLabels

      activities.map(activity => {
        options.push({ value: activity, label: activity })
      })

      memberships.map(membership => {
        options.push({ value: membership, label: membership })
      })

      technologies.map(technology => {
        options.push({ value: technology, label: technology })
      })

      industries.map(industry => {
        options.push({ value: industry.name, label: industry.name })
        this.addChildrenForTwoLevelTaxonomy(options, industry, 'industries')
      })

      domains.map(domain => {
        options.push({ value: domain.name, label: domain.name })
        this.addChildrenForTwoLevelTaxonomy(options, domain, 'domains')
      })

      productFeaturesA.map(productFeatureA => {
        options.push({ value: productFeatureA.name, label: productFeatureA.name })
        this.addChildrenForTwoLevelTaxonomy(options, productFeatureA, 'children')
      })

      productFeaturesB.map(productFeatureB => {
        options.push({ value: productFeatureB.name, label: productFeatureB.name })
        this.addChildrenForTwoLevelTaxonomy(options, productFeatureB, 'children')
      })

      productFeaturesC.map(productFeatureC => {
        options.push({ value: productFeatureC.name, label: productFeatureC.name })
        this.addChildrenForTwoLevelTaxonomy(options, productFeaturesB, 'children')
      })

      expertises.map(entry => {
        options.push({ value: entry, label: entry })
      })

      motivations.map(entry => {
        options.push({ value: entry, label: entry })
      })

      // If the extended taxonomies are available, add them to the tag options
      if (this.$store.getters.extendedTaxonomiesEnabled) {
        processSteps.map(entry => {
          options.push({ value: entry, label: entry })
        })

        aspects.map(entry => {
          options.push({ value: entry.name, label: entry.name })

          if (entry.aspects) {
            entry.aspects.forEach(function (entry) {
              options.push({ value: entry.name, label: entry.name })
            })
          }
        })

        readinessLevels.map(entry => {
          options.push({ value: entry, label: entry })
        })

        processSteps.map(entry => {
          options.push({ value: entry, label: entry })
        })
      }

      options = _unique(options.map(option => option.label))

      return options
    },
  },
  mixins: [ConfigMixin]
}
