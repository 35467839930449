<template>
  <div class="actor-scores__number">
    <div class="actor-scores__number__body">
      <h4 class="actor-scores__h4">{{ label }}</h4>
      <div class="actor-scores__number__value">
        {{ items }}&nbsp;
      </div>
    </div>
    <div class="actor-scores__number__footer" v-if="getMetrics && getMetrics.value">
      <div class="actor-scores__number__item">
        <avatar :src="actorLogo(actor)" :alt="actor.name" variant="border"></avatar>
        {{ toAmount(getMetrics.value) }} {{getMetrics.label}}
      </div>
      <div class="actor-scores__number__item" v-if="hasPortfolioActor && actor.id !== portfolioActor.id && getMetrics.reference_value">
        <avatar :src="actorLogo(portfolioActor)" :alt="portfolioActor.name" variant="border"></avatar>
        {{ toAmount(getMetrics.reference_value) }} {{getMetrics.label}}
      </div>
    </div>
  </div>
</template>

<script>
  import Avatar from '../Avatar/Avatar.vue'
  import { actorLogo } from '../../util/actor'
  import { round2digits, toAmount } from '../../util/currency'

  export default {
    name: 'actor-scores',
    props: {
      data: {
        type: Number,
        required: true
      },
      actor: Object,
      label: String
    },
    computed: {
      hasPortfolioActor () {
        return this.portfolioActor && this.portfolioActor.id
      },
      portfolioActor () {
        return this.$store.getters.portfolioActor
      },
      items () {
        if (this.label == "Digital Footprint Growth" || this.label == "Business Size Growth") {
          return this.percentage(this.data)
        }

        if (! this.data) {
          return "0"
        }

        return this.data.toPrecision(4)
      },
      getMetrics () {
        if (! this.actor.latest_metrics || this.actor.latest_metrics.length == 0) {
          return
        }

        switch (this.label) {
          case "Digital Footprint Score":
            if (this.actor.latest_metrics[0]) {
              return this.actor.latest_metrics[0]
            }
            break;
          case "Digital Footprint Growth":
            if (this.actor.latest_metrics[1]) {
              return this.actor.latest_metrics[1]
            }
            break;
          case "Business Size Score":
            if (this.actor.latest_metrics[2]) {
              return this.actor.latest_metrics[2]
            }
            break;
          case "Business Size Growth":
            if (this.actor.latest_metrics[3]) {
              return this.actor.latest_metrics[3]
            }
            break;
        }
      }
    },
    methods: {
      actorLogo,
      toAmount,
      percentage (value) {
        return ((value > 0) ? '+' : '') + round2digits(value * 100) + ' %'
      },
    },
    components: {
      Avatar
    }
  }
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

  .actor-scores__h4 {
    font-size: 11px;
    font-family: $font-stack-secondary;
    text-transform: uppercase;
    margin-bottom: 1em;
  }
</style>
