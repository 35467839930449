<template>
  <div class="fixed-heading portfolio portfolio-create-page">
    <div class="heading portfolio-create-page-header" v-if="portfolio">
      <h1 class="portfolio-create-page-title" v-if="portfolioName && portfolioName.length > 0">Portfolio: {{ portfolioName }}</h1>
      <h1 class="portfolio-create-page-title" v-else>No Portfolio Selected</h1>
      <div v-if="canUserEditPortfolio(portfolio)" class="portfolio-create-page-controls" style="padding-left: 15px">
        <ds-button class="button--small edit-button" icon="cog" variant="minimal" @click.prevent="openEditPanel"/>
      </div>
      <div class="explore-content__filter-button-container">
        <template v-if="isMember">
          <div class="explore-content__filter-indicator" v-if="keywords.length > 0 && keywords.facet !== 'actor_type'"></div>
          <ds-button variant="minimal" size="small" label="Filter" icon="filter-box" class="explore-content__filter-button"
            :class="{ 'explore-content__filter-button-active' : this.$store.state.filters.filter.visible }" @click="toggleFilter"/>
        </template>
      </div>
      <div class="portfolio-create-page-controls" v-if="isMember">
        <form-group class="explore__controls__actor-types">
          <template v-if="actorTypes.length > 1">
            <checkbox
              v-for="(actorType, index) in actorTypes"
              :key="index"
              inline
              multiple
              :label="actorType.name"
              :val="actorType.value"
              :model-value="activeActorTypes"
              @update:modelValue="updateActiveActorTypes"
            />
          </template>
        </form-group>
      </div>
      <div class="portfolio-create-page-controls" style="margin-right: 15px;">
        <badge :name="countActors + ' Actors'" variant="primary" class="score-overview__actor-couter"/>
      </div>

      <p class="guide_text">
        The portfolio is based on the activated filters, which can be changed by adjusting the filters. Exclude actors from the portfolio, by unticking the checkbox. Add additional
        actors via the side
        panel, submenu "actors". Share and publish the portfolio via the side panel, submenu "sharing".
      </p>
    </div>
    <top-filters/>
    <div class="scrollable has-action-bar" style="overflow: auto; margin-bottom: 0px !important; max-height: 80%;" v-if="!isLoading">
      <div class="list-view">
        <table class="table table--fill table--striped table--hover" v-if="listData.data.length">
          <thead>
          <tr>
            <th-sort width="50" class="portfolio-actor-list__table-header"></th-sort>
            <th-sort by="name" class="portfolio-actor-list__table-header" width="200">{{ $store.getters.actorString }}</th-sort>
            <template v-for="column in columns">
              <th-sort v-if="column.sortable" :by="column.name" width="column.width">{{ humanizeColumn(column) }}</th-sort>
              <th v-else :width="column.width">{{ humanizeColumn(column) }}</th>
            </template>
          </tr>
          </thead>
          <tbody>
          <sortable-portfolio-actor-list-row
            v-for="(actor, index) in listData.data"
            :columns="displayedColumns"
            :key="index"
            :company="actor"
          />
          </tbody>
        </table>
        <div class="explore__empty-state" v-else>
          No actors found!
        </div>
      </div>
    </div>
    <div class="explore-pane__footer">
      <page-items :paging="listData.paging"></page-items>
      <a v-if="$store.getters.isMember"
        class="column-toggle a"
        @click="show.columns = !show.columns"
      >Columns</a>
      <pagination :paging="listData.paging"/>
    </div>

    <modal title="VISIBLE COLUMNS"
      v-if="show.columns"
      close-on-blur
      closeable
      @close="show.columns = false">
      <template v-slot:body>
        <div>
          <p class="guide_text">Drag the properties you like to display from the left to the right list. The order of the properties in the list of “Visible columns” defines the
            order of the
            columns.</p>
          <draggable-column-picker v-model="displayedColumns"/>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
  import Badge from '../components/Badge/Badge.vue'
  import ThSort from '../components/ListView/ThSort.vue'
  import SortablePortfolioActorListRow from '../components/ListView/SortablePortfolioActorListRow.vue'
  import TopFilters from '../components/Filters/TopFilters.vue'
  import Pagination from '../components/Pagination/Pagination.vue'
  import PageItems from '../components/PageItems/PageItems.vue'
  import Modal from '../components/Modals/Modal.vue'
  import DraggableColumnPicker from '../components/Form/DraggableColumnPicker.vue'
  import Checkbox from '../components/Form/Checkbox.vue'

  import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../store/modules/filters.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../store/modules/ui'
  import { MUTATION_TYPES as PORTFOLIO_MUTATION_TYPES } from '../store/modules/managePortfolio'
  import { Portfolios } from '../api/portfolios'
  import { getReportFieldById } from '../util/helpers.js'
  import { humanize } from '../constants/properties'
  import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../store/modules/actors'

  const thProperties = [
    {
      columnName: 'name',
      text: '',
      columnWidth: 200,
    },
    {
      columnName: 'total_funding',
      text: 'Funding raised',
      columnWidth: 160,
    },
    /* {
      columnName: 'total_investment',
      text: 'Amount of funding rounds',
      columnWidth: 160
    }, */
    {
      columnName: 'funding_date',
      text: 'Funding round - date',
      columnWidth: 150,
    },
    /* {
      columnName: 'funding_type',
      text: 'Funding round - type',
      columnWidth: 150
    }, */
    /* {
      columnName: 'funding_amount',
      text: 'Funding round - amount',
      columnWidth: 150
    }, */
    {
      columnName: 'founding_date',
      text: 'Founding date',
      columnWidth: 150,
    },
    {
      columnName: 'zip',
      text: 'ZIP code',
      columnWidth: 70,
    },
    {
      columnName: 'city',
      text: 'City',
      columnWidth: 150,
    },
    {
      columnName: 'country',
      text: 'Country',
      columnWidth: 100,
    },
    {
      columnName: 'market',
      text: 'Target markets',
      columnWidth: 200,
    },
    {
      columnName: 'industries',
      text: 'Industries',
      columnWidth: 200,
    },
    {
      columnName: 'lastFundingType',
      text: 'Last funding round - type',
      columnWidth: 200,
    },
    {
      columnName: 'lastFundingDate',
      text: 'Last funding round - date',
      columnWidth: 200,
    },
    {
      columnName: 'lastFundingRaised',
      text: 'Last funding round - amount',
      columnWidth: 200,
    },
    {
      columnName: 'stage',
      text: 'Growth Stage',
      columnWidth: 100,
    },
    /* {
      columnName: 'funnel_stage',
      text: 'CRM Stage',
      columnWidth: 100
    }, */
    {
      columnName: 'category',
      text: 'Category',
      columnWidth: 200,
    },
    {
      columnName: 'subIndustries',
      text: 'Subindustries',
      columnWidth: 160,
    },
    {
      columnName: 'membership',
      text: 'Membership',
      columnWidth: 140,
    },
    {
      columnName: 'maturity',
      text: 'Maturity',
      columnWidth: 120,
    },
    {
      columnName: 'funding_stage',
      text: 'Funding stage',
      columnWidth: 120,
    },
    {
      columnName: 'technology',
      text: 'Technologies',
      columnWidth: 140,
    },
    {
      columnName: 'activities',
      text: 'Activities',
      columnWidth: 140,
    },
    /* {
      columnName: 'possible_duplicate',
      text: 'Possible duplicate',
      columnWidth: 70
    },
    {
      columnName: 'is_zombie',
      text: 'Zombie',
      columnWidth: 70
    }, */
    {
      columnName: 'completeness',
      text: 'Complete',
      columnWidth: 120,
    },
  ]

  export default {
    data () {
      return {
        show: {
          columns: false,
        },
        portfolio: null,
        portfolioName: '',
        numberOfActor: 0,
      }
    },
    computed: {
      displayedColumns: {
        get () {
          /*const columns = (this.$store.getters.userSettings.viewList || '')
            .split(',')
            .filter(Boolean)

          const configColumns = this.$store.state.config.viewList

          // If the view list configuration is empty, a default column (name) is returned
          return columns.length > 2 ? columns : (configColumns.length > 0) ? configColumns: ['name']*/
          return this.columns.map(column => column.name)
        },
        set (v) {
          this.$store.commit('USER/STORE_SETTINGS', { viewList: v.join(',') })
        },
      },
      // Returns mapping e.g. [{ industries: {name: 'columnName', text: 'columnText', width: 'columnWidth', sortable: true/false}, ... }]
      columns () {
        const viewList = (this.$store.getters.userSettings.viewList || '').split(',')

        // Columns that cannot be used to sort
        var unsortableCollumns = ['total_investment', 'industries', 'lastFundingType', 'lastFundingDate']
        var fundingDashboardCollumns = ['total_investment', 'funding_date', 'funding_type', 'funding_amount']

        var columns = []
        if (viewList) {
          for (var i = 0; i < viewList.length; i++) {
            var columnObject = {}
            const columnText = thProperties.filter(th => th.columnName == viewList[i]).length > 0 ? thProperties.filter(th => th.columnName == viewList[i])[0].text : ''
            const columnWidth = thProperties.filter(th => th.columnName == viewList[i]).length > 0 ? thProperties.filter(th => th.columnName == viewList[i])[0].columnWidth : ''

            if (columnText === '') {
              continue;
            }

            if (unsortableCollumns.includes(viewList[i])) {
              columnObject.sortable = false
            } else {
              columnObject.sortable = true
            }

            columnObject.name = viewList[i]
            columnObject.text = columnText
            columnObject.width = columnWidth
            columns.push(columnObject)
          }
        }

        if (columns.length === 0) {
          const viewList = this.$store.state.config.viewList
          for (var i = 0; i < viewList.length; i++) {
            var columnObject = {}

            if (unsortableCollumns.includes(viewList[i])) {
              columnObject.sortable = false
            } else {
              columnObject.sortable = true
            }

            columnObject.name = viewList[i]
            columnObject.text = viewList[i]
            columnObject.width = 200

            columns.push(columnObject)
          }
        }

        return columns
      },
      actorTypes () {
        var types = [
          { name: 'Organizations', value: 'LegalEntity' },
          { name: 'People', value: 'Person' },
          // { name: 'Communities', value: 'Community' },
          { name: 'Products', value: 'Product' },
        ]

        // Keep only the ones that are allowed
        var r = types.filter((type) => this.$store.getters.viewActorTypes.includes(type.value))
        return r
      },
      activeActorTypes () {
        // Take a shallow copy of the state values as the checkbox component will change the value we pass to it
        // which is not allowed, vuex state can only be changed within the scope of the state
        return this.$store.state.filters.actorTypes.slice(0)
      },
      order () {
        return this.$store.state.filters.paging.order
      },
      isLoading () {
        return this.$store.state.actors.listData.loading
      },
      addedActorsIds () {
        return this.$store.state.managePortfolio.portfolio.actor_ids
      },
      filters () {
        const filters = Object.assign({}, this.$store.getters.listFilterObject)
        delete filters.br
        delete filters.group
        delete filters.limit
        delete filters.offset
        delete filters.order
        delete filters.portfolio
        delete filters.tl
        delete filters.view
        return filters
      },
      pagedFilters () {
        return Object.assign({}, this.filters, {
          id: this.addedActorsIds,
        }, this.pagination)
      },
      pagination () {
        // We get the current page with 'this.$store.getters.currentPage' which is set by the pagination component
        // We get the limit of items with  'this.$store.getters.currentPage' which is set by the page-items component
        return Object.assign({}, {
          limit: this.$store.state.filters.paging.limit,
          offset: this.$store.getters.currentPage * this.$store.state.filters.paging.limit,
          order: this.order,
        })
      },
      countActors () {
        return this.$store.state.actors.listData.count
      },
      listData () {
        return this.$store.state.actors.listData
      },
      keywords () {
        return this.$store.state.filters.keywords
      },
      activePortfolio () {
        return this.$route.params.id
      },
      isMember () {
        return this.$store.getters.isMember
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
    },
    methods: {
      canUserEditPortfolio (portfolio) {
        return this.canEdit(portfolio)
      },
      canEdit (portfolio) {
        if (portfolio.virtual || portfolio.is_immutable) {
          return false
        }

        if (this.isOwner) {
          return true
        }

        if (!portfolio.private && this.isMember) {
          return true
        }

        var userPortfolios = this.$store.state.user.profile.portfolios
        var contributePortfolios = []

        if (this.$store.getters.isPortfolioMember) {
          contributePortfolios = this.$store.state.user.profile.contributePortfolios
        }

        return (userPortfolios &&
          userPortfolios.length &&
          userPortfolios.includes(portfolio.id)) || (this.$store.getters.isPortfolioMember && contributePortfolios.includes(portfolio.id))
      },
      humanizeColumn (column) {
        var columnText = column.text

        if (!columnText) {
          columnText = column.name
        }

        if (columnText && columnText.indexOf('report_field_') >= 0) {
          var reportFieldId = columnText.replace('report_field_', '')
          var reportField = getReportFieldById(reportFieldId)

          if (!reportField) {
            return 'report'
          }

          return reportField.label
        }

        return humanize(columnText)
      },
      updateActiveActorTypes (actorTypes) {
        this.$store.commit(FILTERS_MUTATION_TYPES.SET_ACTIVE_ACTOR_TYPES, actorTypes)
      },
      toggleOrder (order) {
        this.$store.commit(FILTERS_MUTATION_TYPES.TOGGLE_ORDER, order)
      },
      openEditPanel () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'edit-portfolio' })
      },
      openSidePanel (val) {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'scores', metaData: val.id })
      },
      fetchActors () {
        this.pagedFilters.portfolio = this.$route.params.id
        delete this.pagedFilters.id
        this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_LIST, this.pagedFilters)
        this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_COUNT, this.pagedFilters)
      },
      toggleFilter () {
        if (this.$store.state.filters.filter.visible) {
          this.$store.commit(FILTERS_MUTATION_TYPES.TOGGLE_FILTER, false)
        } else {
          this.$store.commit(FILTERS_MUTATION_TYPES.TOGGLE_FILTER, true)
        }
      },
      fetchPortfolio () {
        Portfolios.get(this.activePortfolio)
          .then(portfolio => {
            this.portfolio = portfolio
            this.portfolioName = portfolio.name
          })
      },
    },
    mounted () {
      // Set the filters to be visible when entering the page
      // If the user is not a team member or owner (so quite likely a portfolio member with contributor permissions, otherwise the user has no access to this component)
      // then we need to hide the filters
      if (!this.isMember) {
        this.$store.commit(FILTERS_MUTATION_TYPES.TOGGLE_FILTER, false)
      } else {
        this.$store.commit(FILTERS_MUTATION_TYPES.TOGGLE_FILTER, true)
      }

      this.fetchPortfolio()
      this.fetchActors()

      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'edit-portfolio' })
    },
    beforeUnmount () {
      this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      // Set the filters to not be visible when leaving the page
      this.$store.commit(FILTERS_MUTATION_TYPES.TOGGLE_FILTER, false)
      this.$store.commit(UI_MUTATION_TYPES.TOGGLE_SIDE_PANEL, false)
      this.$store.commit(PORTFOLIO_MUTATION_TYPES.CLEAR_ACTIVE_PORTFOLIO)
    },
    watch: {
      pagedFilters () {
        this.fetchActors()
      },
      activePortfolio () {
        this.fetchPortfolio()
      },
    },
    components: {
      ThSort,
      Badge,
      SortablePortfolioActorListRow,
      TopFilters,
      Pagination,
      PageItems,
      Modal,
      DraggableColumnPicker,
      Checkbox,
    },
  }
</script>
<style lang="scss">
  .portfolio-actor-list__table-header {
    vertical-align: middle;
  }

  .portfolio-create-page {
    overflow: hidden;
  }

  .portfolio-create-page-header {
    .portfolio-create-page-title {
      display: inline-block;
    }

    .portfolio-create-page-controls {
      display: inline-block;
      vertical-align: top;
      float: right;

      .form-group__header {
        display: none;
      }
    }

    .explore-content__filter-button-container {
      margin-right: 20px;
    }
  }

</style>
