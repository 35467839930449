<template>
  <div class="page-items">
    Page items

    <ul>
      <li v-for="limit in [10, 50, 100]"
          @click.prevent="setLimit(limit)"
          :class="{ 'active': currentLimit === limit }">
        <div class="page-items__limit">
          {{limit}}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
  import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters'
  import { ACTION_TYPES as FILTERS_ACTION_TYPES } from '../../store/modules/actors'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'page-items',
    props: ['paging'],
    computed: {
      currentLimit() {
        return this.$store.state.filters.paging.limit
      }
    },
    methods: {
      setLimit(amount) {
        this.$store.commit(FILTERS_MUTATION_TYPES.SET_LIMIT, amount)
      }
    }
  })
</script>
