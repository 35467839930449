<template>
  <EcosystemInfoCard>
    <ul>
      <li v-for="label in labels" :key="label.text">
        <div class="label-section">
          <icon v-if="label.icon" :name="label.icon"></icon>
          <span v-else class="label-color"
                :style="{ backgroundColor: label.color }"></span>
          {{ label.text }}
        </div>
        <template v-if="showFinalValue && label.finalValue >= 0">
          <div class="value-section">
            <h3 class="h3">
              {{ label.finalValue }}
            </h3>
          </div>
        </template>
      </li>
    </ul>
  </EcosystemInfoCard>
</template>

<script>
  import EcosystemInfoCard from './EcosystemInfoCard.vue'

  export default {
    props: {
      data: Object,
      showFinalValue: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      labels () {
        if (!this.data) {
          return []
        }
        return this.data.datasets.map(dataset => ({
          color: dataset.borderColor,
          icon: dataset.icon,
          finalValue: dataset.finalValue,
          text: dataset.label,
        }))
      },
    },
    components: {
      EcosystemInfoCard,
    },
  }
</script>

<style lang="scss" scoped>
  ul {
    color: #9b9b9b;
    margin-left: 0;
    margin-bottom: -5px;
    margin-top: -5px;
    text-transform: uppercase;
  }

  li {
    display: flex;
    flex-direction: column;
    padding: 8px 5px;

    .label-section {
      display: flex;
      align-items: center;
    }

    .value-section {
      font-size: 26px;
      color: black;
      margin-top: 5px;
    }
  }

  .label-color {
    display: block;
    border-radius: 5px;
    height: 10px;
    margin-right: 8px;
    width: 10px;
  }
</style>
