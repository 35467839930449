<template>
  <div class="page-announcements-new scrollable" :class="{'has-parent-page': isSecondTopbarDisplayed}" ref="scrollable">
    <announcement-hero
      :title="$t('announcements_title')"
      :show-search="true"
      @search="fetch"
      @removeAnnouncementsFilter="toggleActiveAnnouncementsFilter"
      @removeTagFilter="toggleTags"
      :is-event="true"
    />
    <div>
      <main
        :style="ui.isMobile || (!canSeeFilters && events.length === 0 && !canSeeCommunitiesFilter) ? `grid-template-columns: 1fr;` : `grid-template-columns: 2.5fr 1fr;`"
        class="container">
        <!-- left side: column with content -->
        <div class="announcements">
          <!-- Display a section that opens a popup in order to create a post -->
          <div class="create-section" v-if="canCreateAnnouncement" id="introjs-message-board-post">
            <img
              :src="userProfile.photo_url"
              :alt="userProfile.name"
              class="create-section__profile-picture"
              @click="showCreateModal('announcement')"
            >
            <div class="talkbubble" @click="showCreateModal('event')"
                 v-tooltip.top="$t('announcement_title_create', { announcement: postLabel })">
              {{
                !activeCommunityId ? $t('add_event_cta', { user: userProfile.name, event: eventLabel.toLowerCase() }) : $t('announcements_create_cta', { user: userProfile.name })
              }}
            </div>
          </div>
          <!-- Show results -->
          <template v-if="announcements && announcements.length">
            <div style="color: red; margin-bottom: 10px" v-if="eventsError">
              something went wrong:
              {{ eventsError }}
            </div>
            <!-- Show the first featured post -->
            <template v-else-if="firstFeaturedAnnouncement">
              <SimpleHeading level="2">
                {{ $t('announcements_featured_title', { announcement: postsLabel }) }}
              </SimpleHeading>
              <announcement-card
                :key="'announcementFeatured'"
                :available-actions="getAvailableActionsForAnnouncement(firstFeaturedAnnouncement)"
                :announcement="firstFeaturedAnnouncement"
                :is-tag-active="isTagActive"
                :is-community-active="isCommunityActive"
                @clickActionButton="clickActionButton($event, firstFeaturedAnnouncement)"
                @clickTag="filterByTag($event)"
              />
            </template>
            <template v-if="announcementsWithoutFirstFeatured">
              <SimpleHeading level="2">
                {{ $t('announcements_recent_title', { announcements: postsLabel }) }}
              </SimpleHeading>
              <announcement-card
                v-for="(announcement, index) in announcementsWithoutFirstFeatured"
                :key="'announcement__' + index"
                :available-actions="getAvailableActionsForAnnouncement(announcement)"
                :announcement="announcement"
                :is-tag-active="isTagActive"
                :is-community-active="isCommunityActive"
                @clickActionButton="clickActionButton($event, announcement)"
                @clickTag="filterByTag($event)"
              />
              <template v-if="announcementsWithoutFirstFeatured.length === 0">
                No posts available
              </template>
            </template>
            <loading
              v-if="announcementsListIsLoading"
              style="color: var(--primary-community)"/>
          </template>

          <!-- Display message with no results -->
          <template v-else-if="selectedAnnouncementsFilter && !announcements.length && !announcementsListIsLoading">
            <br>
            {{ $t('actors_none_for_current_filters_1') }}
            <ds-button
              :label="$t(`announcements_reset_${selectedAnnouncementsFilter}_filter`, {ecosystem: ecosystemDisplayName, announcements: announcementsLabel, events: eventsLabel})"
              size="extra-small"
              variant="rounded"
              class="announcements-hero__filter-button"
              @click="removeFilter"
            />
            {{ $t('actors_none_for_current_filters_2') }}
          </template>

          <!-- Show loading -->
          <template v-else-if="announcementsListIsLoading">
            <loading style="color: var(--primary-community)"/>
          </template>

          <!-- Display message with no results -->
          <template v-else>
            <br>
            {{ $t('announcements_empty', { announcements: postsLabel }) }}
            <template v-if="canCreateAnnouncement">
              <ds-button
                size="extra-small"
                @click="showCreateModal('event')"
                :label="$t('add_announcement_panel_title', {announcement: $t('event_default_value') })"/>
            </template>
          </template>
        </div>

        <div class="filters-sticky" :class="isSimplifiedTemplateUsed ? 'is-simplified' : ''"
             v-if="!ui.isMobile && (canSeeFilters || events.length > 0 || canSeeCommunitiesFilter)">
          <!-- right side: column with filters -->
          <!-- Column title -->
          <SimpleHeading level="2">
            {{ $t('announcements_filters_title') }}
          </SimpleHeading>

          <button
            class="clear-filters-button"
            v-if="this.hasActiveAnnouncementFilter"
            @click="clearFilters"
          >
            {{
              $t('knowledge_base_filters_clear_filters_button', {
                count: this.countActiveAnnouncementFilters,
              })
            }}
          </button>


          <!-- Panel card: filter on event taxonomy category -->
          <template v-if="availableEventCategories.length > 0">
            <div class="announcements-info-container">
              <div class="announcements-info-title">
                <icon name="tags" :style="{fill: ecosystemColor, 'margin-right': '10px'}"/>
                {{ $t('announcement_categories_filters_title') }}
              </div>
              <div class="announcements-info-content">
                <div class="announcements-info-text borderless">
                  <ul
                    :style="'list-style-type: none;'"
                  >
                    <li
                      v-for="(taxonomy, index) in displayedEventCategories"
                      :key="'taxonomy-' + index"
                    >
                      <button
                        :class="isEventCategoryActive(taxonomy) ? 'active' : ''"
                        @click="toggleEventCategoryFilter(taxonomy)"
                      >
                        {{ getLabelForTaxonomyValue('eventCategories', taxonomy.id, this.locale) }}
                      </button>
                    </li>
                  </ul>
                </div>
                <div v-if="displayedEventCategories.length >= limitFilterDisplayedEventCategories"
                     class="announcements-info-button-container">
                  <ds-button
                    size="extra-small"
                    @click="showAllTags()"
                    variant="rounded"
                    :label="!displayAllTags ? $t('announcements_categories_view_all', {announcement_categories: 'tags'}) : $t('announcements_categories_hide_all', {announcement_categories: 'tags'})"
                  />
                </div>
              </div>

            </div>
          </template>

          <!-- Panel card: filter on communities -->
          <template v-if="canSeeCommunitiesFilter">
            <div class="announcements-info-container">
              <div class="announcements-info-title">
                <icon name="users" :style="{fill: ecosystemColor, 'margin-right': '10px'}"/>
                {{ $t('communities_filters_title') }}
              </div>
              <div class="announcements-info-content">
                <div class="announcements-info-text borderless">
                  <ul
                    :style="'list-style-type: none;'"
                  >
                    <li
                      v-for="(availableCommunity, index) in availableCommunities.slice(0, 5)"
                      :key="'community-' + index"
                    >
                      <button
                        :class="selectedCommunity === availableCommunity.id ? 'active' : ''"
                        @click="toggleCommunityFilter(availableCommunity.id)"
                      >
                        {{ availableCommunity.title }}
                      </button>
                    </li>
                  </ul>
                </div>
                <div class="announcements-info-button-container">
                  <ds-button
                    size="extra-small"
                    @click="seeCommunitiesOverview()"
                    variant="rounded"
                    :label="$t('announcements_view_all_communities_cta')"
                  />
                </div>
              </div>

            </div>
          </template>

          <!-- Panel card: loop possible filter types -->
          <template v-if="!filteredListData.loading || hasAtLeastOneFilteredAnnouncement || events.length > 0">
            <div class="announcements-info-wrapper">
              <div v-for="(filteredAnnouncementsType, index) in filteredAnnouncementsTypes">
                <div
                  class="announcements-info-container content-calendar"
                  v-if="(filteredAnnouncementsType.content && filteredAnnouncementsType.content.length) || filteredAnnouncementsType.type === 'is_event'"
                  :key="'announcements-type__' + filteredAnnouncementsType.type + index">
                  <div class="announcements-info-title">
                    <icon :name="filteredAnnouncementsType.icon"
                          :style="{fill: ecosystemColor, 'margin-right': '10px'}"/>
                    {{
                      filteredAnnouncementsType.title
                    }}
                  </div>
                  <calendar
                    v-if="filteredAnnouncementsType.type === 'is_event'"
                    :events="events"
                    @cell-click="onCellClick"
                    @month-click="onMonthChange"
                    :selectedDate="eventDay"
                    :key="calendarKey"
                  />
                  <div
                    :class="{'announcements-info-content': true, 'filter-by-dates': filteredAnnouncementsType.type === 'is_event'}"
                    v-if="filteredAnnouncementsType.buttonText !== ''"
                  >
                    <div class="announcements-info-text borderless"
                         v-if="filteredAnnouncementsType.type === 'communities'">
                      <ul
                        :style="'list-style-type: none;'"
                      >
                        <li
                          v-for="(community, index) in filteredAnnouncementsType.content.slice(0, 5)"
                          :key="filteredAnnouncementsType.type + index + 'content' + index"
                        >
                          <a @click="toggleActiveAnnouncementsFilter(filteredAnnouncementsType.type, community.id)">
                            {{ community.name }}
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="announcements-info-text borderless"
                         v-if="filteredAnnouncementsType.type !== 'is_event'">
                      <ul
                        :style="'list-style-type: none;'"
                      >
                        <li
                          v-for="(announcement, index) in filteredAnnouncementsType.content.slice(0, 5)"
                          :key="filteredAnnouncementsType.type + index + 'content' + index"
                        >
                          <router-link
                            :to="getLinkForAnnouncement(announcement)"
                            class="announcements-info-text">{{ announcement.title }}
                          </router-link>
                        </li>
                      </ul>
                    </div>

                    <div
                      class="announcements-info-button-container"
                      v-if="(filteredAnnouncementsType.content.length >= 5 || filteredAnnouncementsType.type === 'is_event' || filteredAnnouncementsType.type === 'upcoming_events' || filteredAnnouncementsType.type === 'communities') && filteredAnnouncementsType.buttonText !== ''">
                      <ds-button
                        size="extra-small"
                        :variant="filteredAnnouncementsType.buttonVariant"
                        @click="toggleActiveAnnouncementsFilter(filteredAnnouncementsType.type)"
                        :label="filteredAnnouncementsType.buttonText"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>

          <template v-else-if="filteredListData.loading">
            <loading style="color: var(--primary-community)"/>
          </template>
        </div>
      </main>
    </div>
  </div>
</template>

<script lang="ts">
  import Avatar from '../components/Avatar/Avatar.vue'
  import TranslationsMixin from '../util/TranslationsMixin.js'
  import AnnouncementCard from '../components/Simplified/AnnouncementCard.vue'
  import { ACTION_TYPES as NOTIFICATION_ACTION_TYPES, MUTATION_TYPES as NOTIFICATION_MUTATION_TYPES, validAnnouncementFilters } from '../store/modules/notifications.js'
  import AnnouncementMixin from '../util/AnnouncementMixin.js'
  import Calendar from '../components/Calendar.vue'
  import AnnouncementHero from '../components/Simplified/AnnouncementHero.vue'
  import SimpleHeading from '../components/Simplified/SimpleHeading.vue'
  import Loading from '../components/Dashboard/ConceptMap/Loading.vue'
  import UiMixin from '../util/UiMixin.js'
  import _throttle from 'lodash/throttle.js'
  import _uniqBy from 'lodash/uniqBy.js'
  import moment from 'moment'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../store/modules/ui.js'
  import IntroJsMixin from '../util/IntroJsMixin.js'
  import { SiteTemplate } from '../store/modules/config.js'
  import { defineComponent } from 'vue'
  import ConfigMixin from '@/util/ConfigMixin'

  export default defineComponent({
    components: {
      Avatar,
      AnnouncementCard,
      AnnouncementHero,
      SimpleHeading,
      Loading,
      Calendar,
    },
    props: {
      allowCreateAnnouncement: {
        type: Boolean,
        default: true,
      },
    },
    mixins: [ConfigMixin],
    data() {
      return {
        eventDay: null,
        startofMonth: null,
        currentScroll: 0,
        displayAllTags: false,
        limitFilterDisplayedEventCategories: 5,
        calendarKey: null,
      }
    },
    computed: {
      hasJoinedCommunity() {
        if (this.isOwner || this.isMember) {
          return true
        }

        return this.$store.getters.joinedCommunityIds.includes(Number(this.activeCommunityId))
      },
      ecosystemColor() {
        return this.$store.state.config.primaryColor
      },
      displayedEventCategories() {
        if (!this.displayAllTags) {
          return this.availableEventCategories.slice(0, this.limitFilterDisplayedEventCategories)
        }

        return this.availableEventCategories
      },
      hasAccessToAnnouncementsAndEventsForCommunities() {
        return this.$store.getters.hasAccessToAnnouncementsAndEventsForCommunities
      },
      type() {
        return 'event'
      },
      activeCommunityId() {
        if (this.$route.name !== 'community-detail') {
          return this.$store.state.notifications.listData.community
        }

        return this.$route.params.communityId
      },
      query() {
        return this.$store.state.notifications.listData.query
      },
      userProfile() {
        return this.$store.state.user.profile
      },
      hasActiveAnnouncementFilter() {
        return !!this.selectedAnnouncementsFilter
      },
      countActiveAnnouncementFilters() {
        let count = 0

        count += (this.selectedAnnouncementsFilter ? 1 : 0)
        count += (this.tags.length > 0 ? 1 : 0)

        return count
      },
      canSeeFilters() {
        let canSeeFilters = false

        this.filteredAnnouncementsTypes.forEach(filteredType => {
          if (filteredType.content.length > 0) {
            canSeeFilters = true
          }
        })

        return canSeeFilters
      },
      canSeeCommunitiesFilter() {
        if (!this.$store.getters.hasAccessToAnnouncementsAndEventsForCommunities) {
          return false
        }

        return this.isOwner && this.$route.name !== 'community-detail' && this.$store.getters.hasAccessToCommunities
      },
      canCreateAnnouncement() {
        return this.isLoggedIn && this.$store.getters.canUsePublicAnnouncements && this.$store.getters.canCreateAnnouncement && this.allowCreateAnnouncement
      },
      selectedAnnouncementsFilter() {
        return this.$store.state.notifications.listData.selectedFilter
      },
      announcementsListIsLoading() {
        return this.$store.state.notifications.listData.loading
      },
      firstFeaturedAnnouncement() {
        if (this.selectedAnnouncementsFilter || this.$store.state.notifications.listData.community || this.query || this.tags.length > 0) {
          return null
        }

        const filterFeaturedEvents = this.featuredAnnouncements.filter(announcement => {
          return announcement.is_event === 1
        })

        return filterFeaturedEvents.length ? this.featuredAnnouncements[0] : null
      },
      featuredAnnouncements() {
        return this.$store.state.notifications.featuredAnnouncements
      },
      announcementsWithoutFirstFeatured() {
        return this.announcements.filter(announcement => {
          return announcement.is_event === 1
        })
      },
      announcements() {
        var announcements = this.$store.state.notifications.listData.data || []

        if (this.firstFeaturedAnnouncement) {
          announcements = announcements.filter(announcement => {
            return announcement.type !== this.firstFeaturedAnnouncement.id
          })
        }

        return this.removeDuplicates(announcements)
      },
      tags() {
        return this.$store.state.notifications.listData.tags
      },
      eventCategories() {
        return this.$store.state.notifications.listData.eventCategories
      },
      events() {
        return this.$store.state.notifications.eventListData.data
      },
      filteredAnnouncementsTypes() {
        let types = []
        // is_event, upcoming_events, commented, featured, liked, ecosystem, pinned, community

        if (this.events.length > 0 || this.filteredListData.upcoming_events.length > 0) {
          types = [...types,
            {
              type: 'is_event',
              title: this.$t('announcements_filter_on_dates', { events: this.$t('events_default_value') }),
              buttonText: this.selectedAnnouncementsFilter === 'is_event' ? this.$t(`announcements_reset_is_event_filter`, { events: this.$t('events_default_value') }) : '',
              buttonVariant: this.selectedAnnouncementsFilter === 'is_event' ? 'rounded-active' : 'rounded',
              icon: 'bullseye',
              content: this.events,
            },
          ]
        }

        types = [...types,
          {
            type: 'upcoming_events',
            title: this.$t('announcements_upcoming_events_title', { events: this.$t('events_default_value') }),
            buttonText: this.selectedAnnouncementsFilter === 'upcoming_events' ? this.$t(`announcements_reset_upcoming_events_filter`, {
              events: this.$t('events_default_value'),
            }) : this.$t('announcements_upcoming_events_view_all', { events: this.$t('events_default_value') }),
            buttonVariant: this.selectedAnnouncementsFilter === 'upcoming_events' ? 'rounded-active' : 'rounded',
            icon: 'calendar-plus',
            content: this.filteredListData.upcoming_events,
          },
        ]

        if (this.isMember) {
          types = [...types,
            {
              type: 'featured',
              title: this.$t('announcements_featured_title', { announcement: this.postsLabel }),
              buttonText: this.selectedAnnouncementsFilter === 'featured' ? this.$t(`announcements_reset_featured_filter`, {
                announcements: this.postsLabel,
              }) : this.$t('announcements_featured_view_all', { announcements: this.postsLabel }),
              buttonVariant: this.selectedAnnouncementsFilter === 'featured' ? 'rounded-active' : 'rounded',
              icon: 'bullhorn',
              content: this.filteredListData.featured,
            },
            {
              type: 'commented',
              title: this.$t('announcements_commented_title', { announcements: this.postsLabel }),
              buttonText: this.selectedAnnouncementsFilter === 'commented' ? this.$t(`announcements_reset_commented_filter`, {
                announcements: this.postsLabel,
              }) : this.$t('announcements_commented_view_all', { announcements: this.postsLabel }),
              buttonVariant: this.selectedAnnouncementsFilter === 'commented' ? 'rounded-active' : 'rounded',
              icon: 'comments',
              content: this.filteredListData.commented,
            },
            {
              type: 'liked',
              title: this.$t('announcements_liked_title', { announcements: this.postsLabel }),
              buttonText: this.selectedAnnouncementsFilter === 'liked' ? this.$t(`announcements_reset_liked_filter`, {
                announcements: this.postsLabel,
              }) : this.$t('announcements_liked_view_all', { announcements: this.postsLabel }),
              buttonVariant: this.selectedAnnouncementsFilter === 'liked' ? 'rounded-active' : 'rounded',
              icon: 'thumbs-up-outline',
              content: this.filteredListData.liked,
            },
          ]
        } else {
          types = [...types,
            {
              type: 'pinned',
              title: this.$t('announcements_pinned_title', { announcements: this.postsLabel }),
              buttonText: this.selectedAnnouncementsFilter === 'pinned' ? this.$t(`announcements_reset_pinned_filter`, {
                announcements: this.postsLabel,
              }) : this.$t('announcements_pinned_view_all', { announcements: this.postsLabel }),
              buttonVariant: this.selectedAnnouncementsFilter === 'pinned' ? 'rounded-active' : 'rounded',
              icon: 'bullhorn',
              content: this.filteredListData.pinned,
            },
          ]
        }

        return types
      },
      isOwner() {
        return this.$store.getters.isOwner
      },
      isMember() {
        return this.$store.getters.isMember
      },
      config() {
        return this.$store.state.config
      },
      announcementKeywords() {
        return this.$store.state.filters.announcementKeywords
      },
      scrollPosition() {
        return this.$store.state.notifications.scrollPosition
      },
      isLoggedIn() {
        return this.$store.getters.isLoggedIn
      },
      isSimplifiedTemplateUsed() {
        return [SiteTemplate.NEW_SIMPLIFIED, SiteTemplate.SIMPLIFIED].includes(this.$store.getters.activeSiteTemplate)
      },
      isSecondTopbarDisplayed() {
        return this.$route.name === 'community-detail'
      },
    },
    methods: {
      getLinkForAnnouncement(announcement) {
        const announcementSlug = announcement.slug || announcement.id

        if (this.$route.name === 'community-detail') {
          return `/communities/${this.$route.params.communityId}/announcements/${announcementSlug}`
        }

        if (this.$route.name === 'events-simplified') {
          return `/events/${announcementSlug}`
        }

        return `/announcements/${announcementSlug}`
      },
      openSidePanelFilters() {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'announcements-filters', metaData: {} })
      },
      isCommunityActive(community) {
        if (!this.activeCommunityId) {
          return false
        }

        return community.id == this.activeCommunityId
      },
      isTagActive(tag) {
        return Boolean(this.tags.find(filter => filter.value === tag.value))
      },
      isEventCategoryActive(taxonomy) {
        return Boolean(this.eventCategories.find(filter => filter.id === taxonomy.id))
      },
      filterByTag(tag) {
        this.toggleTags(tag)
        this.fetch()
      },
      onCellClick(event) {
        // If a filter from the right hand side (the facets) is clicked, remove all existing filters
        this.$store.commit(NOTIFICATION_MUTATION_TYPES.RESET_ACTIVE_TAGS)

        if (this.eventDay == moment(String(event)).format('YYYY-MM-DD')) {
          //this.$store.commit(NOTIFICATION_MUTATION_TYPES.TOGGLE_ACTIVE_DEFAULT_FILTER, 'is_event')
          this.eventDay = null

          this.calendarKey = Array.from({ length: 10 }, () => String.fromCharCode(Math.floor(Math.random() * 62) + 48)).join('')

          this.startOfMonth = moment(new Date()).format('YYYY-MM-DD')

          this.fetch()
          this.fetchEvents()

          return
        }

        this.eventDay = null

        if (this.selectedAnnouncementsFilter === 'is_event') {
          this.eventDay = moment(String(event)).format('YYYY-MM-DD')
        }

        this.eventDay = moment(String(event)).format('YYYY-MM-DD')

        this.fetch()
      },
      onMonthChange(event) {
        this.startOfMonth = moment(String(event.startDate)).format('YYYY-MM-DD')

        this.fetchEvents()
      },
      showAllTags() {
        this.displayAllTags = !this.displayAllTags
      },
      seeCommunitiesOverview() {
        this.$router.push('communities')
      },
      fetchEvents() {
        if (!this.$store.state.notifications.eventListData.loading) {
          this.$store.dispatch(NOTIFICATION_ACTION_TYPES.FETCH_NOTIFICATIONS_EVENTS_CALENDAR, {
            limit: 5,
            offset: 0,
            filters: {
              date: this.startOfMonth,
              communities: this.activeCommunityId,
            },
          })
        }
      },
      removeDuplicates(announcements) {
        return _uniqBy(announcements, 'id')
      },
      scrollAnnouncements: _throttle(function () {
        const elem = this.$refs.scrollable
        if (elem && elem.offsetHeight + elem.scrollTop > 0.95 * elem.scrollHeight - 1500) {
          this.fetch(true)
        }

        this.currentScroll = elem.scrollTop
      }, 300),
      removeFilter() {
        this.$store.commit(NOTIFICATION_MUTATION_TYPES.TOGGLE_ACTIVE_DEFAULT_FILTER, this.selectedAnnouncementsFilter)

        this.fetch()
      },
      toggleTags(tag) {
        this.$store.commit(NOTIFICATION_MUTATION_TYPES.TOGGLE_ACTIVE_TAGS, tag)

        this.fetch()
      },
      toggleActiveAnnouncementsFilter(type, value) {
        // Filters from the right hand side can only be active 1 at a time
        this.$store.commit(NOTIFICATION_MUTATION_TYPES.RESET_ACTIVE_TAGS)

        if (type) {
          this.$store.commit(NOTIFICATION_MUTATION_TYPES.TOGGLE_ACTIVE_DEFAULT_FILTER, type, value)
        }

        this.fetch()
      },
      toggleCommunityFilter(communityId) {
        this.$store.commit(NOTIFICATION_MUTATION_TYPES.TOGGLE_ACTIVE_COMMUNITY_FILTER, communityId)

        this.fetch()
      },
      toggleEventCategoryFilter(eventCategory) {
        this.$store.commit(NOTIFICATION_MUTATION_TYPES.TOGGLE_ACTIVE_EVENT_CATEGORIES_FILTER, eventCategory)

        this.fetch()
      },
      clearFilters() {
        this.$store.commit(NOTIFICATION_MUTATION_TYPES.RESET_ACTIVE_DEFAULT_FILTER)
        this.$store.commit(NOTIFICATION_MUTATION_TYPES.RESET_ACTIVE_TAGS)
        this.$store.commit(NOTIFICATION_MUTATION_TYPES.RESET_COMMUNITY_FILTER)
      },
      refreshData() {
        this.fetch()
        this.fetchFacets()
        this.fetchEvents()
        this.fetchFeaturedAnnouncements()
      },
      fetchFeaturedAnnouncements() {
        this.$store.dispatch(NOTIFICATION_ACTION_TYPES.FETCH_FEATURED_ANNOUNCEMENTS, {
          params: {
            limit: 1,
            filters: { communities: this.activeCommunityId, is_event: true },
          },
        }).then(() => {
          this.startIntroJs()
        })
      },
      fetchUpdated(id) {
        this.$store.dispatch(NOTIFICATION_ACTION_TYPES.FETCH_UPDATED_NOTIFICATION, {
          id: id,
        })
      },
      selectedAnnouncementsFilterValue(filter) {
        if (filter === 'communities') {
          return this.filteredListData.selectedCommunity
        }

        return filter ? true : null
      },
      fetch(append = false) {
        // if (!append) {
        //  this.$store.commit(NOTIFICATION_MUTATION_TYPES.RESET_NOTIFICATIONS_LIST_DATA)
        // }
        if (append && (this.announcementsListIsLoading || this.$store.state.notifications.listData.endOfFeed)) {
          return
        }

        this.$store.dispatch(NOTIFICATION_ACTION_TYPES.FETCH_NOTIFICATIONS_LIST, {
          limit: this.$store.state.notifications.listData.fetchLimit,
          offset: append ? this.announcements.length : null,
          filters: {
            q: this.query,
            include_community_posts: !this.activeCommunityId && !this.hasAccessToAnnouncementsAndEventsForCommunities,
            communities: this.activeCommunityId,
            [this.selectedAnnouncementsFilter]: this.selectedAnnouncementsFilterValue(this.selectedAnnouncementsFilter),
            is_event: true,
            event_date: this.eventDay,
            tags: this.tags.map(tag => {
              return tag.value
            }),
            event_categories: this.eventCategories.map(taxonomy => {
              return taxonomy.id
            }),
            by_start_date: true
          },
        })
      },
      fetchFacets() {
        this.$store.dispatch(NOTIFICATION_ACTION_TYPES.FETCH_DEFAULT_FILTERED_NOTIFICATIONS_LIST, {
          filterTypes: this.filteredAnnouncementsTypes
            .filter(filteredAnnouncementType => {
              return validAnnouncementFilters.includes(filteredAnnouncementType.type)
            })
            .map(filteredAnnouncementType => {
              return {
                facet: filteredAnnouncementType.type,
                value: true,
              }
            }),
          params: {
            offset: 0,
            filters: {
              event_date: this.eventDay,
              communities: this.activeCommunityId,
              is_event: true,
            },
          },
        })
      },
      startIntroJs() {
        if (this.seenIntros.includes('messageBoard') || !this.isLoggedIn) {
          return
        }
        const config = this.buildIntroJsConfig(this.config, this.$store.getters.userRole)
        if (!config || !config.messageBoard || !config.messageBoard.steps) {
          return
        }

        config.messageBoard.steps = this.getIntroJsStepsConfig(config.messageBoard.steps)
        const intro = this.introJs().setOptions(config.messageBoard)
        this.currentIntro = intro

        const componentScope = this
        intro.onbeforechange(this.introJsBeforeStepCallback.bind(this))
        intro.onafterchange(this.introJsAfterStepCallback.bind(this))
        intro.oncomplete(this.introJsAfterStepCallback.bind(this))
        intro.onexit(this.introJsAfterStepCallback.bind(this))
        intro.oncomplete(function () {
          componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
        })
        intro.onexit(function () {
          componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
        })

        intro.start()
      },
    },
    mounted() {
      // When a previous scroll position has been stored, set its position
      if (this.scrollPosition) {
        const elem = this.$refs.scrollable
        elem.scrollTop = this.scrollPosition
      }

      if (this.activeCommunityId && !this.hasJoinedCommunity) {
        this.$router.push('/communities/' + this.activeCommunityId)
      }

      this.calendarKey = Array.from({ length: 10 }, () => String.fromCharCode(Math.floor(Math.random() * 62) + 48)).join('')

      this.startOfMonth = moment().startOf('month').format('YYYY-MM-DD')

      this.refreshData()
      this.$bus.on('announcementUpdated', (response) => {
        // Update the announcement card
        this.fetchUpdated(response.id)
      })

      this.$bus.on('announcementDeleted', () => {
        this.refreshData()
      })

      this.$bus.on('announcementCreated', () => {
        this.refreshData()
      })

      this.$bus.on('announcementDeleteConfirmation', (modalContext) => {
        this.deleteAnnouncement(modalContext.announcement)
      })
      this.$refs.scrollable.addEventListener('scroll', this.scrollAnnouncements, { passive: true })
    },
    beforeUnmount() {
      this.$bus.off('announcementUpdated')
      this.$bus.off('announcementDeleted')
      this.$bus.off('announcementCreated')
      this.$bus.off('announcementDeleteConfirmation')

      if (!this.isDetail) {
        this.$store.commit(NOTIFICATION_MUTATION_TYPES.CACHE_CURRENT_SCROLL, this.currentScroll)
      }
      this.$refs.scrollable.removeEventListener('scroll', this.scrollAnnouncements, { passive: true })
    },
    mixins: [TranslationsMixin, AnnouncementMixin, UiMixin, IntroJsMixin]
  })
</script>

<style lang="scss" scoped>
  @import "../../scss/variables.scss";

  .page-announcements-new {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  main {
    flex: 1;
    display: grid;
    margin: 0 auto;
  }

  // other height is necessary for page sections on detail pages
  .has-parent-page {
    .filters-sticky {
      top: #{$top-bar-height};
      height: calc(100vh - #{$top-bar-height} - #{$second-top-bar-height});
    }
  }

  // other height is necessary on simplified pages
  .filters-sticky {
    position: sticky;
    position: -webkit-sticky;
    height: calc(100vh - #{$top-bar-height});
    top: 5px;
    flex-direction: column;
    color: #fff;
    padding: 1rem 2rem;
    overflow-y: auto;
  }

  .announcements,
  .filters {
    overflow-y: auto;
    padding: 1.5rem 2rem;

    @media (max-width: $screen-md) {
      padding: 1.5rem;
    }
  }

  .filters-title-container {
    display: flex;
    align-items: center;
  }

  .page-announcements-new {
    h1.h1 {
      font-size: 26px;
      text-transform: uppercase;
      font-family: $font-stack-secondary;
      font-weight: 200;
    }

    h4.h4 {
      font-family: $font-stack-secondary;
      font-weight: 200;
      font-size: 16px;
    }

    .button {
      font-family: $font-stack-primary;
    }

    .create-section {
      background-color: var(--primary-community-extra-lightest);
      border-radius: $default-border-radius;
      display: flex;
      margin-bottom: 1rem;

      > .talkbubble {
        cursor: pointer;

        &:hover {
          filter: drop-shadow(0 0 4px rgba(#000, 0.4));
        }
      }

      &.create-section {
        padding: 20px;

        .create-section__profile-picture {
          height: 45px;
          width: 45px;
          border-radius: 50%;
          margin-right: 32px;
          cursor: pointer;

          &:hover {
            // box-shadow: 0 0 4px rgba(#000, 0.4);
            filter: drop-shadow(0 0 4px rgba(#000, 0.4));
          }
        }
      }

      > .icon {
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: white;
        padding: 0px 14px;
        border-radius: 12px;
        margin-left: 7px;

        &:hover {
          // box-shadow: 0 0 4px rgba(#000, 0.4);
          filter: drop-shadow(0 0 4px rgba(#000, 0.4));
        }

        > svg {
          color: var(--primary-community);
          font-size: 18px;
        }
      }
    }

    .clear-filters-button {
      margin-bottom: 1rem;
      font-size: 0.75rem;
      font-family: inherit;
      color: var(--primary-community);
      background-color: transparent;
      border: 0;
      text-decoration: underline;
      white-space: nowrap;

      &:hover {
        cursor: pointer;
      }
    }
  }
</style>
