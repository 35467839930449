<template>
  <NewCard title="General user statistics">
    <div class="horizontal-cards" v-if="users">
      <EcosystemInfoCard v-if="users.total_users" title="Users">
        <span class="text-lg">
          {{ users.total_users }}
        </span>
        <span class="text-muted" v-if="users.new_users > 0">
          Of which <span class="heartbeat__new-users">{{
            users.new_users
          }}</span> {{ users.new_users > 1 ? 'users are' : 'user is' }} new<span
          v-if="users.total_invitations > 0"> and {{
            users.total_invitations
          }} {{ users.total_invitations > 1 ? 'are' : 'is' }} invited</span>
        </span>
      </EcosystemInfoCard>
      <EcosystemInfoCard v-if="users.total_invitations > 0" title="Invitees">
        <span class="text-lg">
          {{ users.total_invitations }}
        </span>
      </EcosystemInfoCard>
      <EcosystemInfoCard v-if="users.owner_count" title="Owners">
        <span class="text-lg">
          {{ users.owner_count + users.owner_invites_count }}
        </span>
        <span class="text-muted" v-if="users.owner_invites_count">
          Of which {{
            users.owner_invites_count
          }} {{ users.owner_invites_count > 1 ? 'are' : 'is' }} invited
        </span>
      </EcosystemInfoCard>
      <EcosystemInfoCard v-if="users.team_member_count" title="Team members">
        <span class="text-lg">
          {{ users.team_member_count + users.team_member_invites_count }}
        </span>
        <span class="text-muted" v-if="users.team_member_invites_count">
          Of which {{
            users.team_member_invites_count
          }} {{ users.team_member_invites_count > 1 ? 'are' : 'is' }} invited
        </span>
      </EcosystemInfoCard>
      <EcosystemInfoCard v-if="users.ecosystem_member_count" title="Ecosystem members">
        <span class="text-lg">
          {{ users.ecosystem_member_count }}
          <span class="text-muted" v-if="users.ecosystem_member_invites_count">
            +
            {{ users.ecosystem_member_invites_count }}
          </span>
        </span>
        <span class="text-muted" v-if="users.ecosystem_member_invites_count">
          invited
        </span>
      </EcosystemInfoCard>
      <EcosystemInfoCard v-if="users.portfolio_member_count" title="Portfolio members">
        <span class="text-lg">
          {{ users.portfolio_member_count }}
          <span class="text-muted" v-if="users.portfolio_member_invites_count">
            +
            {{ users.portfolio_member_invites_count }}
          </span>
        </span>
        <span class="text-muted" v-if="users.portfolio_member_invites_count">
          invited
        </span>
      </EcosystemInfoCard>
    </div>

    <p class="graphic-title" style="margin-top: 10px;">
      The graph below shows the evolution of sessions and visitors of all times
    </p>
    <p v-if="!loading && Object.keys(errors.trafficCurrentMonth).length"
      class="text-warning">
      <icon name="warning" size="14"></icon>
      <span v-for="(error, index) in errors.trafficCurrentMonth" :key="index">
            {{ error.message }}
          </span>
    </p>

    <div v-if="loading">
      Loading...
    </div>
    <div v-else-if="traffic && Object.keys(traffic.all_sessions).length && !Object.keys(errors.trafficCurrentMonth).length">
      <div class="chart-container">
        <div class="chart-main chart-main-left">
          <SimpleLineChart :data="allMonthsChartData">
          </SimpleLineChart>
        </div>

        <div class="chart-aside chart-aside-right">
          <EcosystemInfoCard labelColor="#F5A623" title="Total sessions">
                  <span class="text-lg">
                    {{ sessionsToDate }}
                  </span>
          </EcosystemInfoCard>

          <EcosystemInfoCard labelColor="#B36ADD" title="Total visitors">
                  <span class="text-lg">
                    {{ allVisitors }}
                  </span>
          </EcosystemInfoCard>
        </div>
      </div>
    </div>
    <div v-if="traffic && !Object.keys(traffic.all_sessions).length">
      No data
    </div>

    <p class="graphic-title">
      The graph below shows the evolution of sessions and unique visitors in {{ selectedMonth }}
    </p>
    <p v-if="!loading && Object.keys(errors.trafficCurrentMonth).length"
      class="text-warning">
      <icon name="warning" size="14"></icon>
      <span v-for="(error, index) in errors.trafficCurrentMonth" :key="index">
            {{ error.message }}
          </span>
    </p>

    <div v-if="loading">
      Loading...
    </div>
    <div
      v-else-if="traffic && Object.keys(traffic.users_current_month).length && !Object.keys(errors.trafficCurrentMonth).length"
      class="chart-container">
      <div class="chart-main chart-main-right">
        <SimpleLineChart :data="currentMonthChartData">
        </SimpleLineChart>
      </div>

      <div class="chart-aside chart-aside-left">
        <EcosystemInfoCard labelColor="#F5A623" :title="'Sessions in ' + selectedMonth">
          <span class="text-lg">
            {{ sessionsCurrentMonth }}
            <span class="text-muted">
              <DeltaPercentage
                :current="sessionsCurrentMonth"
                :previous="sessionsPreviousMonth"
              >
              </DeltaPercentage>
            </span>
          </span>
        </EcosystemInfoCard>

        <EcosystemInfoCard labelColor="#B36ADD" :title="'Unique visitors in ' + selectedMonth">
          <span class="text-lg">
            {{ usersCurrentMonth }}
            <span class="text-muted">
              <DeltaPercentage
                :current="usersCurrentMonth"
                :previous="usersPreviousMonth"
              >
              </DeltaPercentage>
            </span>
          </span>
        </EcosystemInfoCard>
      </div>
    </div>

    <div v-if="traffic && !Object.keys(traffic.sessions_per_month).length">
      No data
    </div>
    <p class="graphic-title" v-if="motivationsChart.datasets">
      The graph below shows the most occurring motivations
    </p>
    <plotly :chart="motivationsChart" v-if="motivationsChart.datasets" :should-transform="false"></plotly>
    <p class="graphic-title" v-if="expertisesChart.datasets">
      The graph below shows the most occurring expertises
    </p>
    <plotly :chart="expertisesChart" v-if="expertisesChart.datasets" :should-transform="false"></plotly>
    <p></p>
  </NewCard>
</template>

<script>
  import DeltaPercentage from './DeltaPercentage.vue'
  import EcosystemInfoCard from './EcosystemInfoCard.vue'
  import NewCard from '../../../components/NewCard/NewCard.vue'
  import SimpleLineChart from '../../../components/Chart/SimpleLineChart.vue'
  import chartsMixin, { parseChartData } from '../../Chart/chartsMixin.js'
  import moment from 'moment'

  const sum = (a, b) => a + b
  const sumValues = obj => Object.values(obj).reduce(sum)

  export default {
    props: {
      users: Object,
      traffic: Object,
      date: String,
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        errors: {
          trafficCurrentMonth: [],
          trafficPreviousMonth: [],
        },
      }
    },
    computed: {
      avgUsers () {
        if (this.traffic.users_per_month.error) {
          return 0
        }

        if (!this.traffic.users_per_month || Object.keys(this.traffic.users_per_month).length === 0) {
          return 0
        }

        return Math.round(sumValues(this.traffic.users_per_month) / Object.keys(this.traffic.users_per_month).length)
      },
      allVisitors () {
        if (this.traffic.users_per_month.error) {
          return 0
        }

        if (!this.traffic.users_per_month || Object.keys(this.traffic.users_per_month).length === 0) {
          return 0
        }

        return sumValues(this.traffic.users_per_month)
      },
      sessionsToDate () {
        if (this.traffic.all_sessions.error) {
          return 0
        }

        return sumValues(this.traffic.all_sessions)
      },
      allMonthsChartData () {
        if (this.traffic.users_per_month.error || this.traffic.sessions_per_month.error) {
          return {}
        }

        const datasets = []

        if (!Array.isArray(this.traffic.users_per_month)) {
          datasets.push({
            color: '#B36ADD',
            label: 'Users',
            cumulate: false,
            data: this.traffic.users_per_month,
          })
        }

        if (!Array.isArray(this.traffic.all_sessions)) {
          datasets.push({
            color: '#F5A623',
            label: 'Sessions',
            cumulate: false,
            data: this.traffic.all_sessions,
          })
        }

        if (datasets.length > 0) {
          return parseChartData(datasets)
        } else {
          return null
        }
      },
      currentMonthChartData () {
        const datasets = []

        if (!Array.isArray(this.traffic.users_per_month)) {
          datasets.push({
            color: '#B36ADD',
            label: 'Users',
            cumulate: false,
            data: this.traffic.users_current_month,
          })
        }

        if (!Array.isArray(this.traffic.sessions_current_month)) {
          datasets.push({
            color: '#F5A623',
            label: 'Sessions',
            cumulate: false,
            data: this.traffic.sessions_current_month,
          })
        }

        if (datasets.length > 0) {
          return parseChartData(datasets)
        } else {
          return null
        }
      },
      selectedMonth () {
        return moment(this.date).format('MMMM')
      },
      sessionsCurrentMonth () {
        if (this.traffic.sessions_per_month.error) {
          return 0
        }

        const selectedMonth = moment(this.date).endOf('month').format('YYYY-MM')
        const sessions = this.traffic.sessions_per_month[selectedMonth]
        if (sessions) {
          return sessions
        }

        return 0
      },
      sessionsPreviousMonth () {
        if (this.traffic.sessions_per_month.error) {
          return 0
        }

        const selectedMonth = moment(this.date).subtract(1, 'months').endOf('month').format('YYYY-MM')
        const sessions = this.traffic.sessions_per_month[selectedMonth]
        if (sessions) {
          return sessions
        }

        return 0
      },
      usersCurrentMonth () {
        if (this.traffic.users_per_month.error) {
          return 0
        }

        const selectedMonth = moment(this.date).endOf('month').format('YYYY-MM')
        const users = this.traffic.users_per_month[selectedMonth]
        if (users) {
          return users
        }

        return 0
      },
      usersPreviousMonth () {
        if (this.traffic.users_per_month.error) {
          return 0
        }

        const selectedMonth = moment(this.date).subtract(1, 'months').endOf('month').format('YYYY-MM')
        const users = this.traffic.users_per_month[selectedMonth]
        if (users) {
          return users
        }

        return 0
      },
      expertisesChart () {
        if (!this.users || !this.users.ecosystem_user_expertises || !this.users.ecosystem_user_expertises.length) {
          return {}
        }
        return {
          datasets: [{
            x: this.users.ecosystem_user_expertises.map(datapoint => datapoint.label),
            y: this.users.ecosystem_user_expertises.map(datapoint => datapoint.value),
            type: 'bar',
            hoverinfo: 'none',
            marker: { 'color': '#F9A825' },
          }],
          layout: {
            showlegend: false,
            height: 300,
            autoMargin: true,
            margin: { l: 40, r: 20, b: 40, t: 10, pad: 0 },
          },
          plotlyType: 'bar',
        }
      },
      motivationsChart () {
        if (!this.users || !this.users.ecosystem_user_motivations || !this.users.ecosystem_user_motivations.length) {
          return {}
        }
        return {
          datasets: [{
            x: this.users.ecosystem_user_motivations.map(datapoint => datapoint.label),
            y: this.users.ecosystem_user_motivations.map(datapoint => datapoint.value),
            type: 'bar',
            hoverinfo: 'none',
            marker: { 'color': '#F9A825' },
          }],
          layout: {
            showlegend: false,
            height: 300,
            autoMargin: true,
            margin: { l: 40, r: 20, b: 40, t: 10, pad: 0 },
          },
          plotlyType: 'bar',
        }
      },
    },
    watch: {
      traffic () {
        this.errors = {
          trafficCurrentMonth: [],
          trafficPreviousMonth: [],
        }
        Object.entries(this.traffic).forEach(([key, item]) => {
          switch (key) {
            case 'users_current_month':
            case 'sessions_current_month':
              if (item.error) {
                if (this.errors.trafficCurrentMonth.findIndex(item => item.status) < 0) {
                  this.errors.trafficCurrentMonth.push({
                    'message': item.error,
                    'code': item.status,
                  })
                }
              }

              break
            case 'users_per_month':
            case 'sessions_per_month':
              if (item.error) {
                if (this.errors.trafficPreviousMonth.findIndex(item => item.status) < 0) {
                  this.errors.trafficPreviousMonth.push({
                    'message': item.error,
                    'code': item.status,
                  })
                }
              }

              break
          }
        })
      },
    },
    components: {
      DeltaPercentage,
      EcosystemInfoCard,
      NewCard,
      SimpleLineChart,
    },
    mixins: [chartsMixin],
  }
</script>

<style lang="scss" scoped>
  @import "../../../../scss/variables";

  .horizontal-cards {
    display: flex;

    > * {
      flex: 1;
    }

    > * + * {
      margin-left: 20px;
    }

    .text-lg {
      font-size: 24px;
      font-weight: bolder;
      margin-bottom: 5px;
    }
  }

  .graphic-title {
    margin-top: 10px;
  }

  .heartbeat__new-users {
    color: $color-primary;
  }
</style>
