<template>
  <div class="range-slider">
    <div class="range-slider__info">
      <div class="range-slider__title">Funded</div>
      <div class="range-slider__range-label">{{ rangeLabel }}</div>
    </div>
    <div ref="slider"></div>
  </div>
</template>

<script>
import noUiSlider from 'nouislider'
import {
  getMinMaxYear,
  formatTime,
  fromPhpTime,
  toPhpTime
} from './slider'
import { defineComponent } from 'vue'

const step = 1 / 4
const minYear = 2000//getMinMaxYear(step).min
const maxYear = getMinMaxYear(step).max

export default defineComponent({
  data () {
    return {
      // Live values
      start: null,
      end: null
    }
  },
  computed: {
    state () {
      return this.$store.state.filters.dateRanges
    },
    uiState () {
      return [
        fromPhpTime(this.state.fundingDateStart) || minYear,
        fromPhpTime(this.state.fundingDateEnd) || maxYear
      ]
    },
    rangeLabel () {
      const startValue = fromPhpTime(this.start || this.state.fundingDateStart) || minYear;
      const endValue = fromPhpTime(this.end || this.state.fundingDateEnd) || maxYear;

      if (startValue <= minYear && endValue >= maxYear) {
        return 'All time';
      }

      if (startValue <= minYear) {
        return `until ${formatTime(endValue, step)}`
      }

      if (endValue >= maxYear) {
        return `starting from ${formatTime(startValue, step)}`
      }

      return `${formatTime(startValue, step)} - ${formatTime(endValue, step)}`
    }
  },
  mounted () {
    noUiSlider.create(this.$refs.slider, {
      start: this.uiState,
      connect: true,
      step: step,
      range: {
        min: minYear,
        max: maxYear
      }
    })

    this.$refs.slider.noUiSlider.on('set', (a, handleId, values) => {
      // Trigger filter update
      const start = toPhpTime(values[0] > minYear ? values[0] : null)
      const end = toPhpTime(values[1] < maxYear ? values[1] : null)
      this.$emit('on-update', { start, end })
    })

    this.$refs.slider.noUiSlider.on('slide', (a, handleId, values) => {
      this.start = toPhpTime(values[0] > minYear ? values[0] : null)
      this.end = toPhpTime(values[1] < maxYear ? values[1] : null)
    })
  },
  watch: {
    state: {
      deep: true,
      handler (v) {
        if (v) {
          this.start = this.state.fundingDateStart
          this.end = this.state.fundingDateEnd
          this.$refs.slider.noUiSlider.set(this.uiState, true)
        }
      }
    }
  }
})
</script>
