<template>
  <toggle :off-label="textDisabled" :on-label="textEnabled" @change="toggle" :modelValue="enabled" />
</template>
<script>
  import Toggle from '@vueform/toggle'

  export default {
    name: 'ToggleButton',
    data () {
      return {
        isEnabled: false
      }
    },
    props: {
      enabled: {
        type: Boolean
      },
      textEnabled: String,
      textDisabled: String,
      theme: {
        type: String,
        default: 'bootstrap' // Or bulma
      },
      color: {
        type: String,
        default: 'default' // Bootstrap: default, primary, success, info, warning, and danger || Bulma: default, red, blue, green, yellow and orange
      },
      typeBold: {
        type: Boolean,
        default: true
      }
    },
    emits: ['update:modelValue'],
    methods: {
      toggle (checked) {
        this.$emit('update:modelValue', checked)
      }
    },
    components: {
      Toggle,
    }
  }
</script>
<style lang="scss">
  @import "../../../scss/variables";

  // Primary Color
  .vue-switcher-color--success div {
    background-color: $color-primary !important;
  }
  .vue-switcher-color--success.vue-switcher--unchecked div {
    background-color: $color-body-borders !important;
  }
  .vue-switcher-color--success div:after, .vue-switcher-color--success.vue-switcher--unchecked div:after {
    background-color: white !important;
  }
  .vue-switcher__label {
    font-size: 13px !important;
  }

</style>
