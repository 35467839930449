<template>
  <div class="color-picker__main-body">
    <div class="color-picker__color-list-container">
      <div class="color-picker__color-header"
           :clas="{'with-presets': localColors.length}">
      <span>{{ title }}</span>
        <ds-input type="color" @update:modelValue="updateEcosystemColor"
                  class="color-picker__color-items" :model-value="initialValue"/>
        <ds-input @update:modelValue="updateEcosystemColor" :model-value="initialValue"
                  class="color-picker__color-input-items"/>
      </div>
      <template v-for="(color, index) in localColors" :key="'colour' + index">
        <div class="color-picker__color-item-container">
          <ds-input type="color" @update:modelValue="updateColor"
                    class="color-picker__color-items" :model-value="color.color"
                    @click="setColorName(color.name)"/>
          <ds-input @update:modelValue="updateColor" :model-value="color.color"
                    @click="setColorName(color.name)"
                    class="color-picker__color-input-items"/>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'color-picker',
    props: {
      colors: {
        type: Object,
      },
      title: {
        type: String,
      },
      initialValue: {
        type: String,
      }
    },
    computed: {
      localColors () {
        const colorsArray = []
        const colorPropertyNames = Object.keys(this.colors)

        for (let i = 0; i < colorPropertyNames.length; i++) {
          const colorObject = {
            name: colorPropertyNames[i],
            color: this.colors[colorPropertyNames[i]],
          }
          colorsArray.push(colorObject)
        }
        return colorsArray
      },
    },
    methods: {
      setColorName (name) {
        // Gets the index of the selected color in order to be able to update in the array
        this.name = name
      },
      updateColor (color) {
        if (color !== '') {
          // We create a copy of the colors object in order to change the value (color) of one of its properties
          const copyOfColors = Object.assign({}, this.colors)
          // We create an object that will have data from the changed color
          const changedColorObject = {}

          copyOfColors[this.name] = color
          changedColorObject[this.name] = color

          this.$emit('update:modelValue', copyOfColors, changedColorObject)
        }
      },
      updateEcosystemColor (color) {
        if (color !== '') {
          this.$emit('updatePrimary', color)
        }
      },
    },
  }
</script>
