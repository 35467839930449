<template>
  <div class="actor-suggestions">
    <div class="fixed-heading">
      <div class="card__general side-panel__header">
        <div
            class="card__title side-panel__actor-suggestions-card__title side-panel__actor-suggestions-header-card__title" style="min-width: calc(100% - 36px); padding-right: 0;">
          {{ actor.name }} {{ $t('actor_detail_connections') }}
        </div>
        <div class="pull-right side-panel__announcement-header-button-container"
             style="text-align: right; min-width: 36px">
          <ds-button icon="remove" variant="minimal" @click="hidePreview" size="small"
                     class="side-panel__announcement-header-icon"/>
        </div>
      </div>
    </div>
    <div class="scrollable side-panel__scrollable__content has-padding"
         style="padding-bottom: 150px">
      <NewCard :title="$t('actor_detail_search')" hideHeaderSeparator
               v-if="connections && connections.length > 0">
        <DsInput
            class="search-input"
            icon="search"
            :placeholder="`${$t('actor_detail_search')}…`"
            v-model="searchTerm"
        />
      </NewCard>
      <AddConnection ref="addConnection" @create="handleAddRelation"
                     :existing-actor="actor"
                     @createWithNewActor="handleAddNewActorRelation"/>

      <tabs v-if="shouldShowTabs && !editingConnection" :tabs="tabs"
            class="side-panel-portfolio__tab-section"></tabs>
      <template v-for="connectionType in connectionsByType">
        <NewCard
          v-if="(connectionType.relationshipType === activeTab || !shouldShowTabs) && !editingConnection"
          alternateCollapseButton
          compactCollapseButton
          collapsable
          class="connections-list"
          :forceOpen="!!searchTerm"
          :title="`${getTranslationForRelationName(connectionType.label)} (${connectionType.connections.length})`"
          :key="connectionType.id"
        >
          <div class="card-body">
            <div class="connection-cards">
              <ConnectionCard
                v-for="(connection, index) in connectionType.connections"
                :key="connection.id"
                :actor="actor"
                :connection="connection"
                @remove="handleRemoveRelation(connection.type, index)"
                @edit="handleEditRelation(connection, index)"
              />
            </div>
          </div>
        </NewCard>
      </template>
      <AddConnection ref="editConnection" @edit="confirmEditRelation"
                     :editing-connection="editingConnection" v-if="editingConnection"
                     :existing-actor="actor"/>
      <icon name="spinner" v-if="isSavingEditedConnection"></icon>
    </div>
  </div>
</template>

<script>
  import _groupBy from 'lodash/groupBy'
  import NewCard from '../../components/NewCard/NewCard.vue'
  import AddConnection from './ActorConnections/AddConnection.vue'
  import ConnectionCard from './ActorConnections/ConnectionCard.vue'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../../store/modules/actors'
  import MODAL_IDS from '../../constants/modal-ids'
  import KEYCODES from '../../constants/keycodes'
  import FiltersMixin from '../../util/FiltersMixin'
  import { updateActor } from '../../api/actors'
  import { trackHeapEvent } from '../../util/analytics'
  import Tabs from '../Tabs/Tabs.vue'

  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import { getConnectionsForActor } from '../../util/helpers.js'

  export default {
    data () {
      return {
        tabs: ['business', 'data'],
        activeTab: 'business',
        searchTerm: '',
        connectionToEdit: null,
        connectionToEditIndex: 0,
        editingConnection: null,
      }
    },
    computed: {
      isMember () {
        return this.$store.getters.isMember
      },
      shouldShowTabs () {
        return this.hasDataRelationships && this.hasBusinessRelationships && !this.searchTerm
      },
      hasDataRelationships () {
        return !!this.connectionsByType.find(connectionType => connectionType.relationshipType === 'data')
      },
      hasBusinessRelationships () {
        return !!this.connectionsByType.find(connectionType => connectionType.relationshipType === 'business')
      },
      ecosystemRelationships () {
        return this.$store.getters.fullActorRelationships
      },
      actor () {
        return this.$store.state.actors.detail.data
      },
      connections () {
        return getConnectionsForActor(this.$store.getters.detailActor, this.ecosystemRelationships)
      },
      connectionsByType () {
        const connections = this.connections.filter(connection =>
            connection.name &&
            connection.name.toLowerCase().includes(this.searchTerm.toLowerCase()),
        )

        const connectionsGroupedByType = _groupBy(connections, 'type')

        const c = Object.keys(connectionsGroupedByType).reduce(
            (list, type) => {
              list.push({
                type: type,
                label: this.ecosystemRelationships.find(r => r.name === type).label,
                relationshipType: this.ecosystemRelationships.find(r => r.name === type).type,
                connections: connectionsGroupedByType[type],
              })

              return list
            },
            [],
        )

        return c.sort(function (a, b) {
          if (a.type === 'subsidiaries' || a.type === 'main_company') {
            return 1
          }
          return (a.type < b.type) ? -1 : (a.type > b.type) ? 1 : 0
        })
      },
      isSavingEditedConnection () {
        return !this.editingConnection && this.connectionToEdit
      },
    },
    methods: {
      fetch () {
        this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_DETAIL, this.$route.params.id)
      },
      handleAddRelation (relationType, actor, relationshipMetadata) {
        let relation = []

        if (this.actor[relationType]) {
          relation = [...this.actor[relationType]]
        }
        // relation = [...this.actor[relationType]] || [];

        relation.push({
          to: actor.id,
          start: relationshipMetadata.start,
          end: relationshipMetadata.end,
          score: relationshipMetadata.score,
          tags: relationshipMetadata.tags,
          status: relationshipMetadata.status,
          comment: relationshipMetadata.comment,
        })

        updateActor({
          id: this.actor.id,
          data: {
            [relationType]: relation,
          },
        }).then(() => {
          this.fetch()
        })
      },
      handleAddNewActorRelation (relationType, name, defaultActorType, relationshipMetadata) {
        trackHeapEvent('addConnections.addNewActor', { name })

        const modalContext = {
          prefilled: {
            name: name,
            actor_type: defaultActorType,
          },
          successCallback: actor => {
            this.$refs.addConnection.clear()
            this.handleAddRelation(relationType, actor, relationshipMetadata)
          },
        }

        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, modalContext)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)
      },
      handleEditRelation (connection, index) {
        this.connectionToEdit = null
        this.editingConnection = null
        this.$nextTick(() => {
          this.connectionToEdit = connection
          this.connectionToEditIndex = index
          this.editingConnection = {
            ...this.actor[connection.type][index],
            ...{ type: connection.type, label: connection.label },
          }
        })
      },
      confirmEditRelation (connection, metadata) {
        this.editingConnection = null
        const result = {
          type: connection.type,
          to: connection.to,
          start: metadata.start,
          end: metadata.end,
          score: metadata.score,
          tags: metadata.tags,
          status: metadata.status,
          comment: metadata.comment,
        }
        if (result.type === this.connectionToEdit.type) {
          const newRelations = [...this.actor[this.connectionToEdit.type]]
          newRelations[this.connectionToEditIndex] = result
          updateActor({
            id: this.actor.id,
            data: {
              [this.connectionToEdit.type]: newRelations,
            },
          }).then(() => {
            this.fetch()
          }).finally(() => {
            this.connectionToEditIndex = 0
            this.connectionToEdit = null
          })
        } else {
          const oldRelations = [...this.actor[this.connectionToEdit.type]]
          oldRelations.splice(this.connectionToEdit, 1)

          const newRelations = [...this.actor[result.type]] || []
          newRelations.push(result)

          updateActor({
            id: this.actor.id,
            data: {
              [this.connectionToEdit.type]: oldRelations,
              [result.type]: newRelations,
            },
          }).then(() => {
            this.connectionToEdit = null
            this.connectionToEditIndex = 0
            this.fetch()
          })
        }
      },
      handleRemoveRelation (relationType, index) {
        if (relationType === 'subsidiaries' || relationType === 'main_company') {
          // Don't allow these relationships to be removed via this panel
          return
        }

        if (!window.confirm('Are you sure you want to remove this connection?')) {
          return
        }
        const relations = [...this.actor[relationType]]
        relations.splice(index, 1)

        updateActor({
          id: this.actor.id,
          data: {
            [relationType]: relations,
          },
        }).then(() => {
          this.fetch()
        })
      },
      hidePreview (evt) {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
    },
    mounted () {
      window.addEventListener('keyup', (e) => {
        if (e.keyCode === KEYCODES.ESC) {
          this.hidePreview()
        }
      })

      this.$bus.on('tab', (tab) => {
        this.activeTab = tab
      })
    },
    beforeUnmount () {
      this.$bus.off('tab')
    },
    components: {
      AddConnection,
      ConnectionCard,
      NewCard,
      Tabs,
    },
    mixins: [FiltersMixin, TranslationsMixin],
  }
</script>

<style lang="scss">
  @import "../../../scss/variables";

  .actor-suggestions {
    .connection-cards {
      display: flex;
      flex-wrap: wrap;
      margin-left: -5px;
      margin-right: -5px;

      > * {
        margin: 0 5px;
        width: calc(50% - 10px);
      }
    }

    .search-input {
      box-shadow: 0 2px 3px rgba(0, 0, 0, .1);

      .ds-input__input {
        padding-left: 48px;
      }

      .svg-icon {
        align-items: center;
        border-right: 1px solid #d8d8d8;
        display: flex;
        height: 100%;
        justify-content: center;
        margin-top: 0;
        top: 0;
        width: 26px;

        svg {
          width: 16px;
        }

        .fill-fg {
          fill: $color-primary;
        }
      }
    }
  }
</style>
