import moment from 'moment'
import ConfigMixin from './ConfigMixin'
import {sustainabilityGoals} from '../constants/config'

export default {
  mixins: [ConfigMixin],
  data() {
    return {
      metrics: [
        'activities',
        'business_type',
        'categories',
        'domains',
        'employee_size',
        'expertises',
        'founding_date',
        'funding_date',
        'funding_round',
        'funnel_stage',
        'growth_stage',
        'industries',
        'maturity',
        'process_steps',
        'revenue',
        'sustainability',
        'technologies',
        'total_funding',
        //'vacancies',
      ],
    }
  },
  methods: {
    defaultMetricParameters (metric) {
      switch (metric) {
        case 'categories':
          return this.categoryValuesAndLabels || []

        case 'domains':
          return this.domainValuesAndLabels || []

        case 'productFeaturesA':
          return this.productFeatureAValuesAndLabels || []

        case 'productFeaturesB':
          return this.productFeatureBValuesAndLabels || []

        case 'productFeaturesC':
          return this.productFeatureCValuesAndLabels || []

        case 'funnel_stage':
          return this.funnelStageValuesAndLabels || []

        case 'industries':
          return this.industryValuesAndLabels || []

        case 'expertises':
          return this.expertiseValuesAndLabels || []

        case 'employee_size':
          return [
            {value: 'XS', label: 'XS (1-10)'},
            {value: 'S', label: 'S (11-50)'},
            {value: 'M', label: 'M (51-100)'},
            {value: 'L', label: 'L (101-500)'},
            {value: 'XL', label: 'XL (501-1000)'},
            {value: 'XXL', label: 'XXL (1000+)'},
          ]

        case 'activities':
          return this.activityValuesAndLabels || []

        case 'technologies':
          return this.technologyValuesAndLabels || []

        case 'process_steps':
          return this.processStepValuesAndLabels || []

        case 'funding_round':
          return [
            {value: 'pre-seed', label: 'Pre-seed'},
            {value: 'Seed', label: 'Seed'},
            {value: 'vc', label: 'VC'},
            {value: 'Series A', label: 'Series A'},
            {value: 'Series B', label: 'Series B'},
            {value: 'Series C', label: 'Series C'},
            {value: 'Series D', label: 'Series D'},
            {value: 'Series E', label: 'Series E'},
            {value: 'Series F', label: 'Series F'},
            {value: 'Series G', label: 'Series G'},
            {value: 'Convertible loans', label: 'Convertible loans'},
            {value: 'ICO', label: 'ICO'},
            {value: 'other', label: 'Other'},
          ]

        case 'sustainability':
          return sustainabilityGoals.map(x => ({value: x, label: x}))

        case 'maturity':
          return [
            {value: 'formation', label: 'Formation'},
            {value: 'validation', label: 'Validation'},
            {value: 'growth', label: 'Growth'},
            {value: 'mature', label: 'Mature'},
            {value: 'acquired', label: 'Acquired'},
            {value: 'dead', label: 'Dead'},
          ]

        case 'business_type':
          return [
            {value: 'B2B', label: 'B2B'},
            {value: 'B2C', label: 'B2C'},
          ]

        case 'growth_stage':
          return this.stageValuesAndLabels || []

        case 'revenue':
          return [
            {value: {from: 0, to: 1000000}, label: '< 1 million €'},
            {value: {from: 1000000, to: 10000000}, label: '1-10 million €'},
            {value: {from: 10000000, to: 100000000}, label: '10-100 million €'},
            {value: {from: 100000000, to: null}, label: '100+ million €'},
          ]

        case 'total_funding':
          return [
            {value: {from: 0, to: 1000000}, label: '< 1 million €'},
            {value: {from: 1000000, to: 10000000}, label: '1-10 million €'},
            {value: {from: 10000000, to: 100000000}, label: '10-100 million €'},
            {value: {from: 100000000, to: null}, label: '100+ million €'},
          ]

        case 'founding_date':
          return [
            {value: {from: getRelativeTimestamp(1, 'year'), to: null}, label: 'Last year'},
            {value: {from: getRelativeTimestamp(5, 'year'), to: null}, label: 'Last 5 years'},
            {value: {from: getRelativeTimestamp(15, 'year'), to: null}, label: 'Last 15 years'},
            {value: {from: getRelativeTimestamp(50, 'year'), to: null}, label: 'Last 50 years'},
          ]

        case 'funding_date':
          return [
            {value: {from: getRelativeTimestamp(1, 'year'), to: null}, label: 'Last year'},
            {value: {from: getRelativeTimestamp(2, 'year'), to: null}, label: 'Last 2 years'},
            {value: {from: getRelativeTimestamp(3, 'year'), to: null}, label: 'Last 3 years'},
            {value: {from: getRelativeTimestamp(5, 'year'), to: null}, label: 'Last 5 years'},
          ]

        default:
          return []
      }
    },
  },
}

export function getRelativeTimestamp(amount, unit) {
  return moment().startOf(unit).subtract(amount, unit).unix()
}
