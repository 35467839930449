<template>
  <div class="offline-page onboarding-container">
    <div class="onboarding-header">
      <img :src="config.logo_url_light || config.logo_url" :alt="currentEcosystem.name" class="onboarding-logo">
    </div>
    <div class="onboarding-body scrollable" :style="{justifyContent: `${justifyContent}px`, paddingTop: `${onboardingPaddingTop}px`}">
      <div class="onboarding-card container">
        <div class="onboarding-card-left"
             :style="{backgroundImage: `url(${coverImage})`}">
        </div>
        <div class="onboarding-card-right">
          <div class="onboarding-content">
            <div class="onboarding-component-container" style="display: inline-block;">
              <h1 class="h1">{{ ecosystemName }} is temporarily unavailable</h1>
              <p style="margin-top: 10px;">You are not able to login at the moment. If this problem persists, please&nbsp;contact&nbsp;your&nbsp;admin for further assistance.</p>
              <button class="button button--rounded" @click.prevent="contactAdministrator">Contact administrator</button>
            </div>
          </div>
          <template
            v-if="showLanguageSelection">
            <div class="language-selection language-selection--english"
                 :class="{'language-selected': activeLocale === 'en'}"
                 @click="setActiveLocaleLocally('en')">
              English
            </div>
            <div class="language-selection language-selection--dutch"
                 :class="{'language-selected': activeLocale === 'nl'}"
                 @click="setActiveLocaleLocally('nl')">
              Nederlands
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="onboarding-footer">
      <span>
        {{ $t('onboarding_hosted_by') }}
        <a href="https://www.datascouts.eu" target="_blank" style="color: white">DATASCOUTS</a>
      </span>
    </div>
  </div>
</template>

<script>
  import Avatar from '../components/Avatar/Avatar.vue'
  import { MUTATION_TYPES as LOCALIZATION_MUTATION_TYPES } from '../store/modules/localization.js'
  import OnboardingMixin from '../util/OnboardingMixin.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      type: String,
      isCloseable: {
        type: Boolean,
        default: true,
      },
      isModal: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['close'],
    data () {
      return {
        onboardingPaddingTop: 0,
        justifyContent: 'flex-start',
      }
    },
    methods: {
      contactAdministrator () {
        window.open('mailto:' + this.emailAddress)
      },
      setActiveLocaleLocally (locale) {
        // !! we don't use "dispatch -> action_types -> update_active_locale" here because the user is not logged in
        this.$store.commit(LOCALIZATION_MUTATION_TYPES.SET_ACTIVE_LOCALE, { locale, i18n: this.$i18n })
      },
      calculateCardHeight () {
        this.onboardingPaddingTop = 40

        if (window.innerHeight >= 992) {
          this.justifyContent = 'center'
        }

        if (window.innerHeight >= 600) {
          return
        }

        this.onboardingPaddingTop = 60
      },
    },
    computed: {
      emailAddress () {
        return this.$store.state.config.email
      },
      ecosystemName () {
        return this.$store.getters.currentEcosystem.displayName || this.$store.getters.currentEcosystem.name
      },
      coverImage () {
        return this.config.onboarding_image_url || this.config.coverSrc || '/images/covers/skyline.jpeg'
      },
      showLanguageSelection () {
        return this.config.languages && this.config.languages.includes('nl') && this.currentStep === 1 && this.STEPS.length > 1 && this.type !== 'packages-sign-up'
      },
      activeLocale () {
        return this.$store.getters.activeLocale
      },
      coverSrc () {
        return this.config.coverSrc || '/images/covers/skyline.jpeg'
      },
      config () {
        return this.$store.state.config
      },
      isPublic () {
        return this.$store.getters.isPublic
      },
    },
    mixins: [OnboardingMixin],
    components: {
      Avatar
    },
  })
</script>

<style lang="scss" scoped>
  @import "../../scss/variables.scss";

  .offline-page {
    .onboarding-card-right {
      padding: 40px 30px;
    }

    @media only screen and (max-width: $screen-xl) {
      .onboarding-body {
        margin-top: 100px;
      }
    }
  }
</style>
