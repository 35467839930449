<template>
  <div class="authentication-modal authentication-modal--small sign-up-modal">
    <modal :id="modalId" ref="modal" :title="title" :enable-mask="false" :is-closeable="isCloseable" @close="$emit('close')">
      <template v-slot:body>
        <div>
          <div class="row">
            <div class="col-xs-12 col-sm-12">
              <form class="form" @submit.prevent="register">
                <fieldset>
                  <div class="form-group" :class="{ error: errors.name.length}">
                    <label class="label" for="register-name">Your name</label>
                    <input class="input" type="text" placeholder="John Doe" id="register-name" v-model="name" @input="errors.name=[]" autofocus>
                    <p v-for="msg in errors.name" v-text="msg" class="form-msg error"></p>
                  </div>

                  <div class="form-group" :class="{ error: errors.email.length}">
                    <label class="label" for="register-email">Your email address</label>
                    <input class="input" type="email" placeholder="john@example.com" id="register-email" v-model="email" @input="errors.email=[]" disabled>
                    <p v-for="msg in errors.email" v-text="msg" class="form-msg error"></p>
                  </div>

                  <div class="form-group">
                    <label class="label" for="register-password">Password</label>
                    <input class="input" type="password" placeholder="Choose a strong password" id="register-password" v-model="password" @input="errors.password=[]">
                  </div>

                  <div class="pw-strength" :class="'pw-strength' + passwordScore">
                    <div class="pw-strength-line"></div>
                  </div>

                  <div class="form-group">
                    <input class="input" type="password" placeholder="Confirm your password" v-model="passwordConfirmation">
                    <p v-for="msg in errors.password" v-text="msg" class="form-msg error"></p>
                  </div>

                  <div class="form-group">
                    <input type="checkbox" v-model="acceptedTerms"/>
                    <span>&nbsp;I agreed to the
                    <a :href="termsOfUseUrl" target="_blank">Terms of Use</a> and
                    <a :href="privacyPolicyUrl" target="_blank">Privacy Policy</a>
                  </span>
                    <p v-for="msg in errors.accepted_terms" v-text="msg" class="form-msg error"></p>
                  </div>
                </fieldset>

                <p v-if="errors.message" class="form-group__error" v-text="errors.message"></p>
                <p v-if="signupDone" class="form-msg success">
                  You have succesfully completed your registration!
                </p>
                <fieldset class="action-buttons">
                  <ds-button type="submit" variant="secondary" icon="chevron-right" label="Finalise" v-if="! signupDone" :disabled="! this.acceptedTerms" />
                  <ds-button variant="secondary" icon="chevron-right" label="Registration completed" v-else :disabled="true" />
                </fieldset>
              </form>
              <ul class="links">
                <li><a href="#" @click.prevent="openLoginModal">Log In</a></li>
              </ul>
            </div>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
  import { loadChunk } from '../../util/chunk-loader'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'

  import { finaliseRegistrationRequest } from '../../api/user'

  import Modal from './Modal.vue'
  import AuthenticationModal from './AuthenticationModal.vue'
  import MODAL_IDS from '../../constants/modal-ids'
  import { DEFAULT_URLS } from '../../constants/default-urls.js'

  export default {
    extends: AuthenticationModal,
    name: 'finalise-sign-up-modal',
    data () {
      return {
        email: window.email || this.$route.query.email || '',
        modalId: MODAL_IDS.FINALISE_SIGN_UP,
        name: this.$route.query.name || '',
        password: '',
        passwordConfirmation: '',
        passwordScore: 0,
        acceptedTerms: false,
        errors: {
          name: [],
          email: [],
          password: [],
          message: null
        },
        signupDone: false
      }
    },
    props: {
      isCloseable: {
        type: Boolean,
        default: true
      },
    },
    computed: {
      title () {
        return 'Finalise your registration'
      },
      config () {
        return this.$store.state.config
      },
      termsOfUseUrl () {
        return this.config.termsOfUseUrl || DEFAULT_URLS.TERMS_OF_USE
      },
      privacyPolicyUrl () {
        return this.config.privacyPolicyUrl || DEFAULT_URLS.PRIVACY_POLICY
      },
    },
    methods: {
      performValidation () {
        if (!this.name) {
          this.errors.name = ['The name field is required.']
        }

        if (!this.email) {
          this.errors.email = ['The email field is required.']
        }

        if (!this.password) {
          this.errors.password = ['The password field is required.']
        } else if (this.password.length < 6) {
          this.errors.password = [`The password must be at least 6 characters.`]
        } else if (this.passwordScore < 2) {
          this.errors.password = [`The password must be stronger.`]
        } else if (!this.passwordConfirmation) {
          this.errors.password = ['Please confirm your password.']
        } else if (this.password !== this.passwordConfirmation) {
          this.errors.password = [`Passwords don't match.`]
        }
      },
      register () {
        this.performValidation()

        if (this.errors.name.length || this.errors.email.length || this.errors.password.length || this.passwordScore < 2) {
          return
        }

        const data = {
          name: this.name,
          email: this.email,
          password: this.password,
          password_confirmation: this.passwordConfirmation,
          token: window.finaliseRegistrationToken,
          accepted_terms: this.acceptedTerms
        }

        finaliseRegistrationRequest(data)
          .then(data => {
            this.message = null;
            this.errors.message = null;

            if (data.success && data.redirect_uri && window.redirect_uri) {
              window.location.href = window.redirect_uri
            } else {
              window.location.href = '/'
            }

            this.signupDone = data.success
          })
          .catch(err => {
            this.errors.message = err.message
          })
      },
      openLoginModal () {
        if (this.$route.name === 'sign-up') {
          this.$router.push('/login')
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
        }
        this.$router.push({
          query: { email: this.email }
        })
      }
    },
    watch: {
      password (password) {
        return loadChunk('zxcvbn', () => {
          const score = window.zxcvbn(password).score

          if (password.length < 6 && score > 2) {
            this.passwordScore = 2
          } else {
            this.passwordScore = score
          }
        })
      }
    },
    components: {
      Modal
    }
  }
</script>
