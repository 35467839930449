<template>
  <div>
    <users-invite />
    <br>
    <br>
    <users-invited />
  </div>
</template>

<script>
import UsersInvite from './UsersInvite.vue'
import UsersInvited from './UsersInvited.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    UsersInvite,
    UsersInvited
  },
})
</script>
