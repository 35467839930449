<template>
  <transition name="modal">
    <div class="modal__mask" @click="blur">
      <div class="modal__wrapper">
        <div class="modal__container" :class="classes" @click.stop>
          <div class="modal__header">
            <h4 class="title">Active filters</h4>
            <hr class="divider">
            <button class="close" @click="close" v-if="isCloseable">×</button>
          </div>
          <div class="modal__body">
            <keyword
            v-for="(keyword, index) in activePortfolioKeywords"
            :key="index"
            :label="keyword.value"
            :model-value="keyword"
            removeable
            @remove="handleRemoveKeyword"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import Keyword from '../Keyword/Keyword.vue'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { MUTATION_TYPES as PORTFOLIOS_MUTATION_TYPES } from '../../store/modules/portfolios.js'
  import KEYCODES from '../../constants/keycodes'
  import MODAL_IDS from '../../constants/modal-ids'


  export default {
    props: {
      closeOnBlur: Boolean,
      id: {
        type: String
      },
      isCloseable: {
        type: Boolean,
        default: true
      },
      title: {
        type: String,
        default: ''
      },
      customTitle: {
        type: Boolean,
        default: false
      },
      enableMask: {
        type: Boolean,
        default: true
      },
      size: {
        type: String,
        default: '',
        validator: val => ['large', ''].includes(val)
      }
    },
    created () {
      window.addEventListener('keyup', this.keyUpHandler)
    },
    methods: {
      keyUpHandler (e) {
        if (e.keyCode === KEYCODES.ESC) {
          this.close()
        }
      },
      blur () {
        if (this.closeOnBlur) {
          this.close()
        }
      },
      close () {
        if (this.isCloseable) {
          this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.MORE_TAGS)
          this.$emit('close')
        }
      },
      handleRemoveKeyword (keyword) {
        this.$store.commit(PORTFOLIOS_MUTATION_TYPES.REMOVE_PORTFOLIO_KEYWORD, keyword)
        this.$bus.emit('portfolioRemovedTag', keyword)
      },
    },
    computed: {
      classes () {
        return {
          [`modal__container--${this.size}`]: this.size,
        }
      },
      activePortfolioKeywords () {
        return this.$store.getters.activePortfolioKeywords
      },
    },
    mounted () {
      document.body.className = document.body.className + ' modal--open'
    },
    unmounted () {
      document.body.className = (document.body.className || '').replace('modal--open', '')
      window.removeEventListener('keyup', this.keyUpHandler)
    },
    components: {
      Keyword
    }
  }
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";
.modal--scrollable {
  display: block!important;
  padding: 100px 0;
  overflow: auto;
  bottom: 0;
  >.modal__wrapper {
    display: block;
    margin: 0 auto;
  }
}
.modal--open .main-view,
.modal--open .main-view .actor-detail {
  overflow: hidden
}

.modal__body {
    .keyword {
        background-color:$color-background-light-grey;
        .keyword__label {
            color:$color-background-grey;
        }
    }
    .keyword::before {
        display:none;
    }
    .keyword:first-child {
        margin-top:10px;
    }
}
</style>
