<template>
  <div class="home-simplified static-home-foodleap">
    <div class="banner_image">
      <div class="static-home-foodleap_body">
        <h1>{{ $t('static_home_foodvalley_title') }}</h1>
        <ds-button
          variant="secondary"
          :label="$t('action_register')"
          :size="null"
          :style="registerButtonStyle"
          @click="openSignupModal"
        />
        <a style="scroll-behavior: smooth;" href="#more-information">{{ $t('static_home_foodvalley_more_information') }}</a>
      </div>
    </div>
    <Container>
      <div class="static-home-foodleap__info">
        <div class="col-12 static-home-foodleap__cards">
          <div v-for="data in cardData" class=" col-xs-12 col-sm-10 col-md-6 col-lg-4 static-home-foodleap__card" style="color: #21244A;">
            <h2>{{ data.title }}</h2>
            <p style="height: 100px;">{{ data.description }}</p>
            <h4>{{ data.subtitle }}</h4>
            <div class="static-home-foodleap__info_content">
              <div v-for="item in data.partners" class="static-home-foodleap__card_item">
                <avatar :src="item.image" :alt="item.text" variant="border"></avatar>
                <p style="font-weight: 700;">{{ item.text }}</p>
              </div>
            </div>
            <div class="static-home-foodleap__card_button">
              <p @click="openSignupModal" style="margin: 0;">{{ data.buttonText }}</p>
            </div>
          </div>
        </div>
      </div>
    </Container>
    <div class="row static-home-foodleap__about" id="more-information">
      <Container
        style="display: flex; padding: 10px; align-items: center;">
        <div class="col-xs-12 col-md-6 col-lg-5 static-home-foodleap_information">
          <h1>{{ $t('static_home_foodvalley_subtitle') }}</h1>
          <p>{{ $t('static_home_foodvalley_description') }}</p>
          <ds-button
            variant="secondary"
            :label="$t('action_watch_video')"
            :size="null"
            :style="registerButtonStyle"
            style="font-size: 16px;"
            href="https://youtu.be/XOJ89ZI2RTo"
          />
        </div>
        <div class="col-xs-12 col-md-6 col-lg-7 static-home-foodleap__video" style="text-align: right;">
          <iframe
            class="foodvalley_video"
            width="580" height="335" src="https://www.youtube.com/embed/XOJ89ZI2RTo" title="Foodvalley"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
      </Container>
    </div>
    <Container>
      <div v-if="isMobile || isIpad" class="static-home-foodleap__discover">
        <h1>{{ $t('static_home_foodvalley_discover_title') }}</h1>
        <ul>
          <li><a>{{ $t('static_home_foodvalley_qualified_item') }}</a></li>
        </ul>
        <div class="static-home-foodleap__discover_body">
          <div class="col-12 panel static-home-foodleap__content">
            <div class="col-sm-12 col-md-6 static-home-foodleap__image">
              <img
                src="/images/static-home-page/personal-home-image.png"
                alt="Unique database of qualified partners"/>
            </div>
            <div class="col-sm-12 col-md-6 static-home-foodleap__text">
              <h2>{{ $t('static_home_foodvalley_qualified_title') }}</h2>
              <p>{{ $t('static_home_foodvalley_qualified_description') }}</p>
            </div>
          </div>
        </div>
        <ul>
          <li><a>{{ $t('static_home_foodvalley_shared_item') }}</a></li>
        </ul>
        <div class="static-home-foodleap__discover_container">

          <div class="static-home-foodleap__discover_body">
            <div class="col-12 panel static-home-foodleap__content">
              <div class="col-sm-12 col-md-6 static-home-foodleap__image">
                <img
                  src="/images/static-home-page/Facilities-image.png"
                  alt="Interact with interesting partners"/>
              </div>
              <div class="col-sm-12 col-md-6 static-home-foodleap__text">
                <h2>{{ $t('static_home_foodvalley_innovation_title') }}</h2>
                <p>{{ $t('static_home_foodvalley_innovation_description') }}</p>
              </div>
            </div>
          </div>
        </div>
        <ul>
          <li><a>{{ $t('static_home_foodvalley_interact_item') }}</a></li>
        </ul>
        <div class="static-home-foodleap__discover_body">
          <div class="col-12 panel static-home-foodleap__content">
            <div class="col-sm-12 col-md-6 static-home-foodleap__image">
              <img
                src="/images/static-home-page/Message-board-image.png"
                alt="Innovation facilities"/>
            </div>
            <div class="col-sm-12 col-md-6 static-home-foodleap__text">
              <h2>{{ $t('static_home_foodvalley_interact_title') }}</h2>
              <p>{{ $t('static_home_foodvalley_interact_description') }}</p>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="static-home-foodleap__discover">
        <h1>{{ $t('static_home_foodvalley_discover_title') }}</h1>
        <div class="static_home_foodvalley_navigation">
          <ul>
            <li v-for="dataItem in data" :class="{'active' : dataItem.isActive }"><a @click="checkIsActive(dataItem.id)">{{ dataItem.item }}</a></li>
          </ul>
        </div>
        <div class="border"></div>
        <div v-for="dataItem in data" class="static-home-foodleap__discover_body">
          <div :style="dataItem.isActive ? 'display: flex;' : 'display: none'" class="col-12 panel static-home-foodleap__content">
            <div class="col-sm-12 col-md-6 static-home-foodleap__image">
              <img
                :src="dataItem.image"
                alt="Interact with interesting partners"/>
            </div>
            <div class="col-sm-12 col-md-6 static-home-foodleap__text">
              <h2>{{ dataItem.title }}</h2>
              <p>{{ dataItem.description }}</p>
            </div>
          </div>
        </div>
      </div>
    </Container>
    <div class="static-home-foodleap-joining">
      <Container
        style="  display: flex;
        flex-direction: column;
        align-items: center;">
        <h1>{{ $t('static_home_foodvalley_access_title') }}</h1>
        <p>{{ $t('static_home_foodvalley_access_description') }}</p>
        <ds-button
          variant="secondary"
          :label="$t('action_join_register')"
          :size="null"
          :style="registerButtonStyle"
          style="font-size: 18px; background-color: white; color: #21244A;"
          @click="openSignupModal"
        />
      </Container>
    </div>
    <SimplifiedFooterFoodleap class="footer"/>
  </div>
</template>


<script>
  import { trackHeapEvent } from '../../util/analytics.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import Avatar from '../Avatar/Avatar.vue'
  import SimplifiedFooterFoodleap from '../SimplifiedFooter/SimplifiedFooterFoodleap.vue'
  import UiMixin from '../../util/UiMixin.js'
  import Container from '../Container/Container.vue'

  export default {
    components: { SimplifiedFooterFoodleap, Avatar, Container },
    data: () => {
      return {
        cardData: [
          {
            title: 'Find your partner',
            description: 'Find interesting high quality contacts, with extensive experience and working in innovation ecosystems worldwide',
            subtitle: 'Latest added',
            partners: [
              {
                image: '/images/static-home-page/Wageningen-logo.svg',
                text: 'Wageningen university & research',
              },
              {
                image: '/images/static-home-page/Greenfood-logo-square.png',
                text: 'GreenFood50',
              },
              {
                image: '/images/static-home-page/dsm-logo.svg',
                text: 'DSM',
              },
            ],
            buttonText: 'view more',
          },
          {
            title: 'Find your innovation facility',
            description: 'Search for innovation facilities to speed up time to market or share your spare capacity with others and connect with interesting partners.',
            subtitle: 'Latest added',
            partners: [
              {
                image: '/images/static-home-page/Wageningen-logo.svg',
                text: 'Shared research facilities wageningen university & research',
              },
              {
                image: '/images/static-home-page/utrecht-science-park-logo.png',
                text: 'Utrecht science park',
              },
              {
                image: '/images/static-home-page/nizo-logo.jpeg',
                text: 'Nizo facility',
              },

            ],
            buttonText: 'view more',
          },
          {
            title: 'Interact and share',
            description: 'Interact with interesting partners to ask questions and share your ideas and discuss earlier findings.',
            subtitle: 'Latest added',
            partners: [
              {
                image: '/images/static-home-page/thomas-logo.png',
                text: '€305 MOBILITY VOUCHERS AVAILABLE FOR VISITING …',
              },
              {
                image: '/images/static-home-page/Leo-logo.png',
                text: 'NEW ON FOODLEAP: FULL RECORDING AND SLIDES …',
              },
              {
                image: '/images/static-home-page/Tjerna-logo.png',
                text: 'INTERNATIONAL COLLABORATION IN SOUTHEAST ASIA.,,',
              },
            ],
            buttonText: 'view more',
          },
        ],
        data: [
          {
            id: 1,
            isActive: true,
            item: '',
            image: '/images/static-home-page/personal-home-image.png',
            title: '',
            description: '',
          },
          {
            id: 2,
            isActive: false,
            item: '',
            image: '/images/static-home-page/Facilities-image.png',
            title: '',
            description: '',
          },
          {
            id: 3,
            isActive: false,
            item: '',
            image: '/images/static-home-page/Message-board-image.png',
            title: '',
            description: '',
          },
        ],
      }
    },
    computed: {
      isMobile () {
        return this.ui.isMobile
      },
      isIpad () {
        return this.ui.isIpad
      },
      registerButtonStyle () {
        return {
          backgroundColor: '#21244A',
          color: 'white',
          fontFamily: '',
          fontWeight: 'bold',
          height: 'auto',
          width: '125px',
          textTransform: 'capitalize',
          borderRadius: '4px',
          alignSelf: 'center',
          textAlign: 'center',
          padding: '4px 12px'
        }
      },
    },
    methods: {
      checkIsActive (id) {
        this.data.forEach(item => {
          if (id === item.id) {
            item.isActive = true
          } else {
            item.isActive = false
          }
        })
      },
      openSignupModal () {
        trackHeapEvent('staticHomePage.topBarRegister')

        this.$router.push('/register')
      },
    },
    mounted () {
      this.data[0].item = this.$t('static_home_foodvalley_qualified_item')
      this.data[1].item = this.$t('static_home_foodvalley_shared_item')
      this.data[2].item = this.$t('static_home_foodvalley_interact_item')

      this.data[0].description = this.$t('static_home_foodvalley_qualified_description')
      this.data[1].description = this.$t('static_home_foodvalley_innovation_description')
      this.data[2].description = this.$t('static_home_foodvalley_interact_description')

      this.data[0].title = this.$t('static_home_foodvalley_qualified_title')
      this.data[1].title = this.$t('static_home_foodvalley_innovation_title')
      this.data[2].title = this.$t('static_home_foodvalley_interact_title')
    },
    mixins: [UiMixin],
  }
</script>


<style lang="scss" scoped>
  @import "resources/assets/scss/variables";

  .static-home-foodleap {
    font-family: Museo, serif;

    p, h2 {
      font-family: 'Zen Kaku Gothic New', sans-serif;
      font-style: normal;
      font-weight: 400;
    }

    .banner_image {
      background-image: url("/images/static-home-page/static-home-page-foodleap.png");
      width: 100%;
      height: 515px;
      background-size: cover;
      background-position: center 27%;
    }

    .static-home-foodleap_body {
      text-align: center;
      display: flex;
      flex-direction: column;

      h1 {
        color: white;
        text-align: center;
        padding-top: 3.5em;
        width: 55%;
        max-width: 750px;
        margin: auto;
        font-size: 35px;
        text-shadow: 0 3px 6px rgba(0, 0, 0, 0.6);
      }

      button {
        margin-top: 26px;
      }

      a {
        text-decoration: underline;
        color: white;
        margin-top: 8px;
      }
    }

    .static-home-foodleap__info {
      margin-bottom: 4em;
      padding: 20px;

      .static-home-foodleap__cards {
        display: flex;
        justify-content: center;
        margin-top: -85px;
        gap: 2rem;

        .static-home-foodleap__info_content {
          margin-top: 20px;
        }

        .static-home-foodleap__card {
          width: 400px;
          max-width: 340px;
          background-color: #F2F9FE;
          filter: drop-shadow(0 3px 6px rgba(#000, 0.16));
          height: 100%;
          border-radius: 8px;
          padding: 30px 20px 20px 20px;
          position: relative;

          h2 {
            font-weight: 700;
            color: #21244A;
            font-family: Museo, serif;
          }

          .static-home-foodleap__card_item {
            display: flex;
            margin-bottom: 25px;
            align-items: center;

            p {
              margin: 0 10px;
              color: #21244A;
              font-size: 14px;
              text-transform: uppercase;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            .avatar {
              width: 35px;
              height: 35px;
              background-color: rgba(33, 36, 74, .20) !important;

              :deep(.avatar__img) {
                width: 35px;
                height: 35px;
              }
            }
          }

          p {
            margin: 20px 0 20px 0;
            color: #788088;
            font-size: 14px;
          }

          .static-home-foodleap__card_button {
            text-decoration: underline;
            cursor: pointer;

            p {
              color: #21244A;
            }
          }
        }
      }
    }

    .static-home-foodleap__about {
      background-color: #DAE1DA;
      display: flex;
      align-items: center;


      .foodvalley_video {
        border: 8px solid white;
      }

      h1 {

      }

      .static-home-foodleap_information {
        margin: 45px 0 45px 0;

        p {
          color: #707070;
          font-size: 18px;
          padding-top: 20px;
          line-height: 30px;
          white-space: pre-line;
        }

        button {
          font-size: 18px;
        }
      }
    }

    .static-home-foodleap__discover {
      text-align: center;
      margin-top: 8rem;
      margin-bottom: 20px;
      height: 400px;

      h1 {
        color: #21244A;
      }

      .static-home-foodleap_progress_line {
        background: #707070;
        width: 970px;
        align-self: center;
        margin-top: -2px;
        opacity: 0.3;
        height: 2px;
        margin-left: auto;
        margin-right: auto;


        .static-home-foodleap_progress_percentage {
          background: #21244A;
          height: 3px;
          width: 25%;
        }
      }


      .static_home_foodvalley_navigation {
        margin-top: 60px;
        display: flex;
        flex-direction: column;


        .active {
          border-bottom: 2px solid #21244A;

          a {
            color: #21244A;
            opacity: 1;
          }
        }


        ul {
          display: flex;
          justify-content: space-between;


          li {
            cursor: pointer;
            padding-bottom: 20px;
            font-family: Museo, sans-serif;
            margin: 0 auto;

            a {
              font-size: 22px;
              color: #788088;
              opacity: 0.58;
              font-weight: 700;
            }
          }
        }
      }

      .border {
        border-bottom: 2px solid rgba(112, 112, 112, 0.5);
        width: 84%;
        margin: -2px auto;
      }

      .static-home-foodleap__discover_body {
        margin-bottom: 20px;

        .static-home-foodleap__content {
          display: flex;
          margin: 30px 30px 50px 10px;

          .static-home-foodleap__text {
            text-align: left;

            h2 {
              font-weight: bold;
            }

            p {
              margin-top: 10px;
              color: rgba(120, 128, 136, 0.5);
              font-size: 16px;
            }

            h1 {
              color: #21244A;
            }

          }

          .static-home-foodleap__image {

            img {
              width: 80%;
            }
          }
        }
      }
    }

    .static-home-foodleap-joining {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 14rem;
      background-color: #21244A;
      color: white;
      padding: 40px;
      width: 100%;

      position: relative;
      left: 50%;
      transform: translateX(-50%);

      p {
        margin-top: 15px;
        white-space: pre-line;
        width: 33%;
        text-align: center;
        color: white;
        font-size: 16px;
      }

    }

    .footer {
      margin-top: 0rem;
    }

    @media(max-width: $screen-sm) {
      .static-home-foodleap__discover {
        .static-home-foodleap__discover_body {
          .static-home-foodleap__content {
            .static-home-foodleap__text {
              margin-left: 10px;

              h2 {
                font-weight: bold;
              }
            }
          }
        }

        ul {
          li {
            a {
              margin: 15px 0 0 15%;
            }
          }
        }
      }
    }

    @media(max-width: $screen-md-max) {
      .banner_image {
        object-fit: cover;

        .static-home-foodleap_body {
          h1 {
            font-size: 24px;
            width: 90%;
            align-items: center;
            display: flex;
          }
        }
      }


      .static-home-foodleap__info {
        .static-home-foodleap__cards {
          display: flex;
          flex-direction: column;
          gap: 2rem;
          margin-top: -200px;
          margin-left: 0;
          margin-right: 0;

          .static-home-foodleap__card {
            width: 100% !important;
            margin: 20px;
          }
        }
      }

      .static-home-foodleap__about {
        display: flex;
        flex-direction: column;

        .container {
          padding: 2rem !important;


          .static-home-foodleap_information {
            p {
              width: 100%;

            }
          }

          .static-home-foodleap__video {
            display: none;
            color: white;
          }
        }
      }

      .static-home-foodleap-joining {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 4rem;

        h1 {
          text-align: center;
        }

        p {
          width: 100%;
        }
      }

      .static-home-foodleap__discover {
        margin-top: 4rem;
        height: auto;

        .static-home-foodleap__discover_body {
          .static-home-foodleap__content {
            margin: 0;

            .static-home-foodleap__text {
              padding-left: 10px;

              h2 {
                font-weight: bold;
              }
            }
          }
        }

        ul {
          margin-top: 5rem;


          li {
            margin-left: 0 !important;
            margin-right: 0 !important;

            a {
              font-size: 24px;
              text-align: left;
              align-items: flex-start;
              display: flex;
              margin: 15px 0 15px 5%;
              color: #21244A;
              font-weight: 700;
            }
          }
        }
      }

      .static-home-foodleap__content {
        display: flex;
        flex-direction: column;

        .static-home-foodleap__image {
          max-width: 100% !important;

          img {
            width: 90% !important;
          }
        }

        .static-home-foodleap__text {
          max-width: 100% !important;
          margin: 28px 30px 30px 30px;

          h2 {
            font-size: 18px;
          }
        }

      }
    }
    @media(max-width: $screen-lg) {
      .banner_image {
        object-fit: cover;

        .static-home-foodleap_body {
          h1 {
            font-size: 24px;
            width: 90%;
            align-items: center;
            display: flex;
          }
        }
      }

      .static-home-foodleap__info {
        .static-home-foodleap__cards {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 2rem;
          margin-top: -200px;

          .static-home-foodleap__card {
            width: 100% !important;
            max-width: 100% !important;
          }
        }
      }

      .static-home-foodleap__about {
        display: flex;
        flex-direction: column !important;

        .container {
          padding: 2rem !important;

          .static-home-foodleap_information {
            p {
              width: 100%;

            }
          }

          .static-home-foodleap__video {
            display: none;
            color: white;
          }
        }
      }

      .static-home-foodleap-joining {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 4rem;

        p {
          width: 100%;
        }
      }

      .container {
        .static-home-foodleap__discover {
          margin-top: 4rem;
          height: auto;

          .static-home-foodleap__discover_body {
            .static-home-foodleap__content {
              margin: 0;

              .static-home-foodleap__text {
                padding-left: 10px;
              }
            }
          }

          ul {
            margin-top: 5rem;

            li {
              margin-left: 0 !important;
              margin-right: 0 !important;

              a {
                font-size: 24px;
                text-align: left;
                align-items: flex-start;
                display: flex;
                margin: 15px 0 15px 5%;
                color: #21244A;
                font-weight: 700;
              }
            }
          }
        }
      }

      .static-home-foodleap__content {
        display: flex;
        flex-direction: column;

        .static-home-foodleap__image {
          max-width: 100% !important;

          img {
            width: 90% !important;
          }
        }

        .static-home-foodleap__text {
          max-width: 100% !important;
          margin: 28px 30px 30px 30px;
        }

      }
    }

    @media(max-width: $screen-xl) {
      .static-home-foodleap__info {
        .static-home-foodleap__cards {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: center;
          gap: 2rem;
        }
      }

      .static-home-foodleap__about {
        justify-content: center;

        .static-home-foodleap__video {
          display: flex;
          justify-content: center;
        }

        .static-home-foodleap_information {

          p {
            width: 100%;
          }
        }
      }
    }

    @media(max-width: $screen-lg, $screen-xl) {
      .static-home-foodleap__discover {
        margin-top: 4rem;

        ul {
          li {
            margin-left: 0 !important;
            margin-right: 0 !important;

            a {
              font-size: 20px;
              text-align: left;
              align-items: flex-start;
              display: flex;
              margin: 20px 0 0 15%;
              color: #21244A;
              font-weight: 700;
            }
          }
        }
      }
    }

  }
</style>
