<template>
  <modal
    :id="modalId"
    ref="modal"
    :title="$t('article_relation_title')"
    class="article-relation-modal"
    @close="close"
    is-simplified-modal
  >
    <template v-slot:body>
      <div class="article-relation__container">
        <div class="mandatory-message">
          <i>{{ $t('all_fields_with_asterisk_are_mandatory') }}</i>
        </div>
        <form-group
          required
          is-simplified
          :label="$t('article_relation_first_actor')" :errors="errors.firstActor">
          <dropdown :options="availableActors" v-model="firstActor"/>
        </form-group>
        <form-group
          is-simplified required :label="$t('article_relation_type')"
          :errors="errors.type">
          <dropdown :options="types" v-model="relationType"/>
        </form-group>
        <form-group
          is-simplified required :label="$t('article_relation_second_actor')"
          :errors="errors.secondActor">
          <dropdown :options="availableActors" v-model="secondActor"/>
        </form-group>
        <form-group
          is-simplified :label="$t('article_relation_status')" :errors="errors.status">
          <dropdown
            v-model="relationshipMetaData.status" :options="statusOptions"
            :multiple="false"
            :allow-clear="true"/>
        </form-group>
        <form-group
          is-simplified :label="$t('article_relation_strength')" :errors="errors.score">
          <input-range
            class="input-range--score card"
            :label="$t('article_relation_strength')"
            v-model="relationshipMetaData.score"
            :max="100"
          />
        </form-group>
        <form-group
          is-simplified :label="$t('article_relation_comment')" :errors="errors.comment">
          <ds-textarea v-model="relationshipMetaData.comment"/>
        </form-group>
      </div>
    </template>
    <template v-slot:footer>
      <div style="width: 100%;">
        <ds-button
          variant="rounded"
          @click="save"
          :label="$t('article_relation_add')"
          size="extra-small"
          :disabled="!canSubmit"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import { updateActor } from '../../api/actors.js'
  import FormGroup from '../Form/FormGroup.vue'
  import DsTextarea from '../Form/DsTextarea.vue'
  import Dropdown from '../Dropdown/Dropdown.vue'
  import InputRange from '../Slider/InputRange.vue'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'

  export default {
    data () {
      return {
        modalId: MODAL_IDS.ARTICLE_RELATION_MODAL,
        relationType: '',
        errors: {},
        firstActor: null,
        secondActor: null,
        relationshipMetaData: {},
      }
    },
    computed: {
      canSubmit () {
        return this.firstActor && this.secondActor && this.relationType
      },
      availableActors () {
        return this.modalContext.availableActors.map(actor => {
          return {
            value: actor.id,
            label: actor.name,
          }
        })
      },
      types () {
        return this.ecosystemRelationships.filter(r => !['subsidiaries', 'main_company', 'has_collaboration_with'].includes(r.name)).map(t => ({
          value: t.name,
          label: t.label || t.name,
        })).reverse().sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
      },
      ecosystemRelationships () {
        return this.$store.getters.fullActorRelationships
      },
      statusOptions () {
        return [
          'active',
          'inactive',
          'future',
        ]
      },
      modalContext () {
        return this.$store.state.ui.modalContext
      },
    },
    props: {},
    methods: {
      close () {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.ARTICLE_RELATION_MODAL)
        this.$emit('close')
      },
      save () {
        this.errors = {}
        if (!this.firstActor) {
          this.errors.firstActor = [this.$t('onboarding_field_required')]
        }
        if (!this.secondActor) {
          this.errors.secondActor = [this.$t('onboarding_field_required')]
        }
        if (this.firstActor === this.secondActor) {
          this.errors.firstActor = [this.$t('article_detail_no_duplicate_actor_relation')]
        }
        if (!this.relationType) {
          this.errors.relationType = [this.$t('onboarding_field_required')]
        }
        if (Object.keys(this.errors).length !== 0) {
          return
        }
        let relation = []

        if (this.firstActor[this.relationType]) {
          relation = [...this.secondActor[this.relationType]]
        }

        relation.push({
          to: this.secondActor,
          start: this.relationshipMetaData.start,
          end: this.relationshipMetaData.end,
          score: this.relationshipMetaData.score,
          tags: this.relationshipMetaData.tags,
          status: this.relationshipMetaData.status,
          comment: this.relationshipMetaData.comment,
        })

        updateActor({
          id: this.firstActor,
          data: {
            [this.relationType]: relation,
          },
        }).then(() => {
          this.$bus.emit('article-relation-created')
        }).catch((errors) => {
          this.errors = errors
        }).finally(() => {
          this.close()
        })
      },
    },
    mounted () {
    },
    components: { Modal, FormGroup, DsTextarea, Dropdown, InputRange },
    mixins: [],
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/_variables.scss";

  .article-relation__container {
    padding: 10px 40px 0 40px;
  }

  .article-relation-modal :deep(.modal__wrapper) .modal__container .modal__header .close {
    color: black;
  }

  .article-relation-modal :deep(.modal__wrapper) .modal__container .modal__header .title {
    background: var(--primary-community-extra-lightest);
    color: black;
  }
</style>
