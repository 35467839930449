import { SiteTemplate } from '../store/modules/config.js'

const MOBILE_MAX_WIDTH = 768
const SMALL_MOBILE_MAX_WIDTH = 576
const IPAD_MAX_WIDTH = 990

export default {
  data () {
    return {
      ui: {
        isMobile: window.innerWidth < MOBILE_MAX_WIDTH,
        isSmallMobile: window.innerWidth < SMALL_MOBILE_MAX_WIDTH,
        windowWidth: window.innerWidth,
        isIpad: window.innerWidth < IPAD_MAX_WIDTH,
      },
      featureFlags: {},
    }
  },
  computed: {
    canShowBreadcrumbs () {
      // Exclude specific routes that are otherwise receive breadcrumbs
      if (this.$route.path && this.$route.path.toLowerCase().indexOf('/dashboards/email-reports') >= 0) {
        return false
      }

      if (this.$store.getters.isMember || this.$store.getters.isPortfolioMember) {
        return [
          'community-detail',
        ].includes(this.$route.name)
      }

      return this.$route.path.indexOf('/dashboards/') >= 0 ||
        this.shouldShowActorSimplifiedDetailPage ||
        [
          'communities',
          'community-detail',
          'challenges',
          'challenges-detail',
          'announcements',
          'announcements-simplified',
          'events-simplified',
          'events-detail',
          'announcements-detail',
          'spotting-areas',
          'spottingAreas',
          'spotting-area-detail',
          'actors-simplified',
          'explore',
          'actor-detail',
          'product-gallery',
          'products-simplified',
          'intelligence-simplified',
          'intelligence-detail',
          'portfolios',
          'portfolio-detail',
          'info',
          'profile',
          'notifications',
        ].includes(this.$route.name)
    },
    shouldShowActorSimplifiedDetailPage () {
      return this.$route.name === 'actor-detail' && this.isSiteTemplateSimplified
    },
    isSiteTemplateSimplified () {
      return [SiteTemplate.SIMPLIFIED, SiteTemplate.NEW_SIMPLIFIED].includes(this.$store.state.config.siteTemplate)
    },
  },
  mounted () {
    this.$bus.on('resize300', () => {
      this.ui.isMobile = window.innerWidth < MOBILE_MAX_WIDTH
      this.ui.isSmallMobile = window.innerWidth < SMALL_MOBILE_MAX_WIDTH
      this.ui.windowWidth = window.innerWidth
      this.ui.isIpad = window.innerWidth < IPAD_MAX_WIDTH
    })
  },
  beforeUnmount () {
    this.$bus.off('resize300')
  },
}
