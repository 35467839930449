<template>
  <span class="alternate--dropdown" v-click-away="closeDropdown">
    <ds-button
      :icon="open ? 'chevron-up' : 'chevron-down'"
      class="alternate__dropdown__button"
      :label="selection ? `${label} ${selection.name}` : label"
      :variant="variant"
      :size="size"
      @blur="blur"
      @click="toggle"
      @focus="focus"
    />
    <div v-if="open" class="alternate--dropdown__options">
      <div class="scrollable alternate--dropdown__background">
        <div>
          <div v-for="item in options">
            <ds-button
              class="alternate--dropdown__options--items"
              :class="(selection && item.id && selection.id === item.id) && 'selected'"
              :label="itemLabel(item)"
              :variant="variant"
              size="small"
              @blur="blur"
              @focus="focus"
              @click="input(item)"
            />
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
  props: ['options', 'label', 'variant', 'selection', 'icon', 'size', 'closeOnSelection'],
  data () {
    return {
      open: false,
    }
  },
  methods: {
    itemLabel (item) {
      // If a label property is set, use that instead of the name property
      if (item.label) {
        return item.label
      }

      return item.name
    },
    toggle () {
      this.open = !this.open || this.active
    },
    focus () {
      this.active = true
      this.open = true
      setTimeout(() => {
        this.active = false
      }, 500)
    },
    input (value) {
      if (this.closeOnSelection) {
        this.open = false
      }

      this.$emit('input', value)
    },
    blur () {
      setTimeout(() => {
        if (this.$el && document.activeElement && !this.$el.contains(document.activeElement)) {
          this.open = false
        }
      }, 0)
    },
    closeDropdown (event) {
      if (event.target.parentElement && event.target.parentElement.classList.value !== 'cb-dropdown') {
        this.open = false
      }
    },
  },
})
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";
.hidden__option{
  display:none !important;
}

.alternate--dropdown {
  position: relative;
  border: none;
  background: none;
  margin-left:1em;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $color-primary;
    .fill-fg {
      fill: $color-primary;
    }
  }
  &:hover .fill-fg {
    fill: $color-primary;
  }
  .button {
    text-align: left;
  }
  >.svg-icon {
    display: block;
  }
}

.alternate__dropdown__button {
  width: fit-content;
  height:33px;
  border: 1px solid $color-borders !important;
  margin-right: 0px !important;
  span {
    color: $color-text-grey-light;
    padding-left:0px !important;
    text-transform:capitalize;
  }
  .svg-icon {
    left:auto !important;
    right:15px;
    path{
      fill: $color-secondary
    }
  }
  svg {
    width:20px;
    height:20px;
    margin-top:-1px;
  }
  .button__label {
    font-family: $font-stack-primary;
    line-height:19px;
    letter-spacing: .06em;
  }
}

.alternate--dropdown__options {
  z-index:6;
  width:150px;
  max-width:150px;
  margin-top: 5px;
  position: absolute;
  max-height: 200px;
  top: 100%;
  margin-bottom: 50px;
  left: 0px;
  white-space: nowrap;
  font-size: 14px;
  text-align: left;
  background: $color-secondary;
  > .button {
    &:focus {
      z-index: 1;
    }
  }
  .svg-icon {
    width: 12px ;
    height: 12px ;
  }

  .alternate--dropdown__background {
    background: white;
    border-left:1px solid $color-borders;
    border-bottom:1px solid $color-borders;
    border-right:1px solid $color-borders;
    max-height: 250px;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    background-color: $color-secondary;
    background: $color-secondary
  }

  ::-webkit-scrollbar-thumb {
    background-color: $color-background-grey;
    -webkit-border-radius: 1ex;
  }
}

.alternate--dropdown__options--items {
  width:100%;
  .button__label {
    text-transform:capitalize;
  }

  &.selected {
    background-color: rgba(204, 204, 204, .2);
  }
}

.cb-dropdown__options {
  position: absolute;
  z-index: 2;
  height: 200px;
  top: 100%;
  margin-bottom: 50px;
  left: 0px;
  white-space: nowrap;
  font-size: 14px;
  text-align: left;
  background: $color-secondary;
  > .button {
    &:focus {
      z-index: 1;
    }
  }
  .svg-icon {
    width: 12px ;
    height: 12px ;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    background-color: $color-secondary;
    background: $color-secondary
  }

  ::-webkit-scrollbar-thumb {
    background-color: $color-background-grey;
    -webkit-border-radius: 1ex;
  }
}

.cb-dropdown__button {
  width: 95%;
  background-color: $color-background-grey !important;
  color: white !important;
  text-align: center !important;
  margin-bottom: 4px;
  margin-left: 4px;
  &:hover {
    background-color: rgba(255, 255, 255, .3);
  }
}

.cb-dropdown__button-container {
  background: $color-secondary;
  width: 100%;
}

.cb-dropdown__inactive_button {
  width: 95%;
  background-color: white !important;
  text-align: center !important;
  &:hover {
    background-color: rgba(255, 255, 255, .3);
  }
}
</style>
