<template>
  <span class="benchmark-dropdown" v-click-away="closeDropdown">
    <ds-button
      :icon="open ? 'chevron-up' : 'chevron-down'"
      class="benchmark__dropdown__button"
      :label="label"
      :variant="open ? variant : 'outline'"
      :size="size"
      @blur="blur"
      @click="toggle"
      @focus="focus"
    />
    <div v-if="open" class="benchmark-dropdown__options">
      <ds-input v-model="portfolioQuery" placeholder="Search" style="padding:4px;" @blur="blur"/>
      <div class="scrollable benchmark-dropdown__background">
        <div>
          <template v-for="(option, index) in portfolioList">
          <checkbox-button
            :key="'a' + index"
            v-if="option.favourited == true"
            variant="secondary"
            :val="option.id"
            :model-value="portfolioSelection"
            :label="option.name"
            :disabled="portfolioSelection.length == 5 && portfolioSelection.indexOf(option.id) < 0"
            icon="star"
            :multiple="isMultiple"
            block
            @focus="focus"
            @blur="blur"
            @update:modelValue="input"
          />
          </template>
          <div class="navigation__divider" v-if="hasFavourites"></div>
          <template v-for="(option, index) in portfolioList">
          <checkbox-button :key="'b' + index"
                           v-if="option.favourited == false"
                           variant="secondary"
                           :val="option.id"
                           :model-value="portfolioSelection"
                           :label="option.name"
                           :disabled="portfolioSelection.length == 5 && portfolioSelection.indexOf(option.id) < 0"
                           :multiple="isMultiple"
                           block
                           @focus="focus"
                           @blur="blur"
                           @update:modelValue="input"
          />
          </template>
        </div>
      </div>
      <div class="benchmark-dropdown__button-container">
        <ds-button class="benchmark-dropdown__button" @click="submit()" variant="small button--default" :label="benchmarkButton"/>
      </div>
    </div>
  </span>
</template>

<script lang="ts">
import { Portfolios, fetchPortfolio, fetchAllFavourites } from '../../api/portfolios.js'
import CheckboxButton from '../CheckboxButton/CheckboxButton.vue'
import _throttle from 'lodash/throttle'
import { defineComponent } from 'vue'
export default defineComponent({
  // ID is the id of the portfolio on which the dropdown is triggered
  props: ['options', 'label', 'variant', 'selection', 'id', 'icon', 'size','multiple'],
  data () {
    return {
      open: false,
      portfolioQuery: null,
      hasFavourites: false,
      portfolioList: null
    }
  },
  computed: {
    benchmarkButton () {
      if (this.portfolioSelection.length == 0) {
        return "Select a portfolio"
      }

      if (this.portfolioSelection.length >= 5) {
        return "Benchmark " + this.portfolioSelection.length + ' (max)'
      }

      return "Benchmark " + this.portfolioSelection.length
    },
    isMultiple () {
      if (this.multiple == undefined) {
        return true
      }
      if(this.multiple) {
        return true
      } else {
        return false
      }
    },
    portfolioSelection () {
      // Removes the undefined values from the array, including the undefined value that came in the first position of the array previously.
      // TODO: See where the undefined value that comes in the first position comes from
      if (this.selection.indexOf(undefined) > -1) {
         var indexOfUndefined = this.selection.indexOf(undefined)
        this.selection.splice(indexOfUndefined, 1)
      }
      return this.selection
    }
  },
  methods: {
    submit () {
      if (this.portfolioSelection.length > 0) {
        this.$emit("submit")
      }
    },
    toggle () {
      this.open = !this.open || this.active

      // Add the current portfolio to the selection if it's not in there yet
      const index = this.portfolioSelection.indexOf(this.id)

      this.fetch()

      if (index < 0) {
        return this.$emit('update:modelValue', [this.id])
      }
    },
    updateDropdownList (filteredList) {
      if (filteredList == undefined) {
        this.portfolioList = this.options
        return
      }
      this.portfolioList = filteredList
    },
    focus () {
      this.active = true
      this.open = true
      setTimeout(() => {
        this.active = false
      }, 500)
    },
    input (value) {
      this.$emit('update:modelValue', value)
    },
    blur () {
      setTimeout(() => {
        if (this.$el && document.activeElement && !this.$el.contains(document.activeElement)) {
          this.open = false
        }
      }, 0)
    },
    closeDropdown (event) {
      if (event.target.parentElement && event.target.parentElement.classList.value !== 'benchmark-dropdown') {
        this.open = false
      }
    },
    fetch () {
      // Fetch all portfolios
      Portfolios.get('', {benchmarkable: true})
        .then(portfolios => {
          this.fetchAllFavourites(portfolios)
        })
        .catch(errors => {
          this.errors = errors
        })
    },
    fetchAllFavourites (nonFavouritePortfolios) {
      fetchAllFavourites({benchmarkable: true}).then(favouritePortfolios => {
        favouritePortfolios.map(item => {
          if (item.favourited == true) {
            this.hasFavourites = true
          }
        })
        var allPortfolios = nonFavouritePortfolios.concat(favouritePortfolios)
        this.updateDropdownList(allPortfolios)
      }).catch(errors => {
        //console.log(errors)
      })
    },
  },
  mounted () {
    this.fetch()
  },
  watch: {
    options () {
      this.updateDropdownList()
    },
    portfolioQuery:{
       deep: true,
        handler: _throttle(function () {
          if (! this.portfolioQuery) {
            this.fetch()
            return
          }

          // This will search for a portfolio which matches the input value in the search field
          var searchValue = Object.assign({}, {query : this.portfolioQuery, benchmarkable: true})

          fetchPortfolio(searchValue).then(searchOutput =>{
            this.updateDropdownList(searchOutput)
          }).catch(errors => {
            console.log(errors)
          })
        }, 300, {
          trailing: true
        })
    }
  },
  components: {
    CheckboxButton
  }
})
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";
.hidden__option{
  display:none !important;
}

.benchmark-dropdown {
  position: relative;
  border: none;
  background: none;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $color-primary;
    .fill-fg {
      fill: $color-primary;
    }
  }
  &:hover .fill-fg {
    fill: $color-primary;
  }
  .button {
    text-align: left;
  }
  >.svg-icon {
    display: block;
  }
}

.benchmark-dropdown__options {
  position: absolute;
  z-index: 2;
  height: 200px;
  top: 100%;
  margin-bottom: 50px;
  left: 0px;
  white-space: nowrap;
  font-size: 14px;
  text-align: left;
  background: $color-secondary;
  > .button {
    &:focus {
      z-index: 1;
    }
  }
  .svg-icon {
    width: 12px ;
    height: 12px ;
    path {
      fill: $color-primary;
    }
  }

  .benchmark-dropdown__background {
    background: $color-secondary;
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    background-color: $color-secondary;
    background: $color-secondary
  }

  ::-webkit-scrollbar-thumb {
    background-color: $color-background-grey;
    -webkit-border-radius: 1ex;
  }
}

.benchmark-dropdown__button {
  width: 95%;
  background-color: $color-background-grey !important;
  color: white !important;
  text-align: center !important;
  margin-bottom: 4px;
  margin-left: 4px;
  &:hover {
    background-color: rgba(255, 255, 255, .3);
  }
}

.benchmark-dropdown__button-container {
  background: $color-secondary;
  width: 100%;
}

.benchmark-dropdown__inactive_button {
  width: 95%;
  background-color: white !important;
  text-align: center !important;
  &:hover {
    background-color: rgba(255, 255, 255, .3);
  }
}
</style>
