<template>
  <div class="authentication-modal authentication-modal--big">
    <modal
      :id="modalId"
      ref="modal"
      :title="title"
      :enable-mask="false"
      :is-closeable="true"
    >
      <template v-slot:body>
        <div>
          <domain-create @create="handleAddDomain" />
          <p :style="{ visibility: busy ? 'visible' : 'hidden' }">
            <ds-button
              variant="secondary"
              :icon="busy === 'Saving...' ? 'spinner' : 'check'"
              :label="busy"
            />
          </p>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from './Modal.vue'
import DomainCreate from '../Domain/Create.vue'

import { updateBulkActor } from '../../api/actors'



export default {
  name: 'add-domain-modal',
  data () {
    return {
      busy: '',
      errors: {},
      modalId: 'ADD_DOMAIN'
    }
  },
  computed: {
    actors () {
      return this.$store.state.ui.modalContext.actors || []
    },
    title () {
      return 'Add domain to ' + (this.actors && this.actors.length > 1 ? this.actors.length + ' ' + this.$store.getters.actorStrings : (this.actors.length === 1 ? this.actors[0].name : 'unknown'))
    }
  },
  methods: {
    handleAddDomain (domain) {
      const changes = this.actors.map(actor => ({
        id: actor.id,
        domains: (actor._domains || []).concat(domain)
      }))

      this.busy = 'Saving...'
      updateBulkActor(changes).then(() => {
        this.busy = 'Done!'
        this.$bus.emit('actorUpdated')
        setTimeout(() => {
          this.$refs.modal.close()
        }, 1000)
      })
    }
  },
  components: {
    Modal,
    DomainCreate
  }
}
</script>
