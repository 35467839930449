<template>
  <th
    class="th-sortable"
    :class="{up:order==by,down:order=='-'+by}"
    :width="width"
  >
    <slot></slot>
    <icon class="th-sortable__sort" @click="toggleOrder(by)" :name="order == by ? 'sort-up' : order == '-' + by ? 'sort-down' : 'sort'"/>
    <icon class="th-sortable__filter" name="filter" @click.prevent="show = !show" v-if="options">filter</icon>
    <div class="filter-dropdown" v-if="options && show" v-click-away="closeDropdown">
      <radio-button
        v-for="(option, index) in options"
        :key="index"
        :model-value="modelValue"
        name="th-sortable-dropdown"
        :label="option.text"
        :val="option.value"
        @update:modelValue="$emit('update:modelValue', $event)"
      />
    </div>

  </th>
</template>

<script>
  import RadioButton from '../Form/RadioButton.vue'

  export default {
    props: ['by', 'width', 'options', 'modelValue'],
    emits: ['update:modelValue'],
    data () {
      return {
        'show': false,
      }
    },
    computed: {
      order () {
        // TODO: this computed property needs to be rewritten as this is prone to errors
        if (this.$parent.$parent && this.$parent.$parent.filters) {
          return this.$parent.$parent.filters.order
        }

        if (typeof this.$parent.filters === 'undefined') {
          return null
        }

        return this.$parent.filters.order
      },
    },
    methods: {
      toggleOrder (order) {
        if (typeof this.$parent.orderBy === 'function') {
          this.$parent.orderBy(order)
        }

        if (typeof this.$parent.$parent.orderBy === 'function') {
          this.$parent.$parent.orderBy(order)
        }
      },
      closeDropdown () {
        this.show = false
      },
    },
    components: {
      RadioButton,
    },
  }
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

  .filter-dropdown {
    text-transform: none;
    position: absolute;
    z-index: 1;
    background: $color-background-lightest-grey;
    border: 1px #CECECE solid;
    padding: 8px;
    top: calc(100% - 8px);
    left: 4px;
    font-weight: 400;
    font-size: 0.8rem;
    font-family: "Fira Sans", sans-serif;
    white-space: pre;
  }

  .svg-icon.th-sortable__filter {
    vertical-align: top;
    margin-left: -4px;
    padding: 3px;
    opacity: .6;

    &:hover {
      opacity: 1;
    }
  }

  .th-sortable__sort {

  }
</style>
