<template>
  <div>
    <template v-if="part === 1">
      <h2>What are you looking for?</h2>
      <form-group label="Provide the most relevant keywords (max. 3) for your research. We will use those keywords to retrieve relevant content. Based on the description of the keywords, we will identify the top 100 most relevant pieces of content. Please be aware that we do not have pre-defined descriptions for user-generated keywords. Your input in step 3 will help us interpret the keyword correctly." id="introjs-exploration-keywords">
        <keywords-with-suggestions-dropdown v-model="internalKeywords"
                                            class="description-keyword-list"
                                            :suggestion-source="suggestionSource"
                                            :max-tags="3"
                                            @fetching-suggestions="handleFetchingSuggestions"
                                            :allowFreeTextTag="true"/>
                <p style="margin-top: 5px">Add up to 3 keywords</p>
      </form-group>
    </template>
    <template v-if="part === 2">
      <h2>Give us some more context...</h2>
      <div class="scope-description__items">
        <p>In order to scout for valid startups and potential solutions, please describe the problem you aim to solve. Provide some additional context by describing the typical user, the circumstances in which the problem occurs, the application domain, valid technologies or business models that you know of...</p>
      </div>
      <p>You are searching for: {{keywords.map(keyword => keyword.label).join(', ')}}</p>
      <ds-textarea class="description-with-keywords__description" default-text="Use Jargon, context and explain how these terms are related"
                   v-model="internalDescription"/>
      <p>example: Aquaponics refers to systems that combines conventional aquaculture, I.e. raising aquatic animals such as snails, fish, crayfish or prawns in tanks, with hydroponics, i.e. cultivating plants in water, in a symbiotic environment. In an aquaponic system.</p>
    </template>
  </div>
</template>

<script lang="ts">
  import debounce from 'lodash/debounce.js'

  import DsTextarea from '../../components/Form/DsTextarea.vue'
  import KeywordsWithSuggestionsDropdown from '../Dropdown/KeywordsWithSuggestionsDropdown.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      part: Number,
      description: {
        type: String,
      },
      keywords: {
        type: Array,
      },
      withCancel: {
        type: Boolean
      },
    },
    data () {
      return {
        suggestionSource: this.description,
        fetchingSuggestions: false,
        suggestionSourceUpdated: false,
      }
    },
    emits: ['update:description', 'update:keywords'],
    computed: {
      internalDescription: {
        get () {
          return this.description
        },
        set (value) {
          this.$emit('update:description', value)
        },
      },
      internalKeywords: {
        get () {
          return this.keywords
        },
        set (value) {
          this.$emit('update:keywords', value)
        },
      },
    },
    methods: {
      handleFetchingSuggestions (fetchingSuggestions) {
        this.fetchingSuggestions = fetchingSuggestions
      },
      updateSuggestionSource (e) {
        if (e) {
          e.preventDefault()
          e.stopPropagation()
        }

        this.suggestionSourceUpdated = true
        this.suggestionSource = this.description
      },
      updateSuggestionSourceOnce: debounce(function () {
        if (!this.suggestionSourceUpdated) {
          this.updateSuggestionSource();
        }
      }, 3000),
    },
    watch: {
      description () {
        this.updateSuggestionSourceOnce()
      },
    },
    components: {
      KeywordsWithSuggestionsDropdown,
      DsTextarea,
    },
  })
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables";
  .no-description-message {
    color: $color-error;
  }
  .description-with-keywords__description {
    min-height: 150px;
    margin-bottom: 20px;
  }

  .description-with-keywords__trigger {
    &.disabled {
      opacity: 0.333;
      pointer-events: none;
    }
  }

  :deep(.form-group__label__text) {
    margin: 15px 0;
  }

  .description-keyword-list {
    :deep(.multiselect__content-wrapper) {
      max-height: 268px!important;
    }
  }
</style>
