<template>
  <div class="has-action-bar">
    <div class="tabs-border">
      <ul class="tabs__navigation">
        <li
          v-for="tab in tabs"
          class="tabs__navigation__item"
          :class="{ 'tabs__navigation__item--active': activeTab === tab }"
          @click="activeTab = tab"
          v-text="tab"
        ></li>
      </ul>
    </div>

    <component :is="panel"></component>
  </div>
</template>

<script>
  import Translations from './Onboarding/Translations.vue'
  import Personalisation from './Onboarding/Personalisation.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    data () {
      return {
        tabs: ['translations', 'personalisation'],
        activeTab: 'translations'
      }
    },
    computed: {
      panel () {
        switch (this.activeTab) {
          case 'translations':
            return 'Translations'
          case 'personalisation':
            return 'Personalisation'
          default:
            return this.activeTab
        }
      }
    },
    methods: {},
    components: {
      Translations,
      Personalisation
    },
  })
</script>

<style scoped>
</style>
