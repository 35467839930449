<template>
  <!-- DEPRECATED -->
  <div ref="scrollable" :class="classes">
    <div class="row">
      <div class="col--grow">
        <template v-if="!actor">
          <general-error v-if="error" title="Unexpected error" :message="error" />
          <p v-else-if="fetching">
            Fetching tweets...
          </p>
          <p v-else-if="!news.items.length">
            {{ fetching ? 'Fetching tweets...' : 'No tweets found!' }}
          </p>
        </template>

        <div class="row news__row" ref="columns">
          <div class="news__column" v-for="column in columns">
            <card-news v-for="(item, index) in news.items.filter((v, i) => i % columns === column - 1)"
              :key="index"
              :item="item"
              @hide="hideHandle"
            />
          </div>
        </div>
      </div>
      <div class="col--aside" v-if="! actor">
        <BuzzTable :disabled="fetching" :hiddenTables="hiddenTables" />
        <section class="news__section" v-if="socialMediaMonitoringFilters.exclude_twitter.length">
          <h3 class="h2">Hiding posts from</h3>
          <div v-for="handle in socialMediaMonitoringFilters.exclude_twitter">@{{ handle }}</div>
          <p v-if="socialMediaMonitoringFilters.exclude_twitter" @click="hideHandle(null)">
            <button class="a">Show all</button>
          </p>
        </section>
      </div>
    </div>
  </div>
</template>

<script>

import _throttle from 'lodash/throttle'

import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters'
import { MUTATION_TYPES as BUZZ_MUTATION_TYPES } from '../../store/modules/buzz'

import GeneralError from '../Errors/GeneralError.vue'
import CardNews from '../Card/CardNews.vue'
import BuzzTable from '../RankedList/BuzzTable.vue'


import { fetchNews } from '../../api/dashboard'

export default {
  name: 'news',
  props: ['actor'],
  data () {
    return {
      columns: 0,
      error: '',
      fetching: false,
      news: {
        items: [],
        mostFollowedMembers: [],
        trendingHashtags: [],
        trendingUrls: [],
      },
      excludeFromProfile: [],
      hiddenTables: ['channels'] // Can be 'channels', 'topics', 'influencers', 'filter',
    }
  },
  computed: {
    portfolio () {
      return this.$store.state.filters.portfolio
    },
    filters () {
      if (this.actor) {
        return {actor_id: this.actor.id}
      }

      var parameters = this.$store.getters.buzzQueryParameters

      // The channel parameter is always twitter
      delete parameters.channel

      // If there are handles that need hiding
      if (this.$store.state.filters.socialMediaMonitoring && this.$store.state.filters.socialMediaMonitoring.exclude_twitter) {
        parameters['exclude_twitter'] = this.$store.state.filters.socialMediaMonitoring.exclude_twitter
      }

      // Add the portfolio
      parameters.portfolio = this.portfolio

      // We set the default channel value for fetching the data, only the twitter channel is available
      return Object.assign(parameters, { channel: 'twitter' })
    },
    socialMediaMonitoringFilters () {
      return this.$store.state.filters.socialMediaMonitoring
    },
    classes () {
      return {
        scrollable: ! this.actor,
        'newsfeed--single': this.actor && this.actor.twitter && this.actor.twitter.length === 1
      }
    },
    buzzFilters () {
      return this.$store.state.buzz.filters
    }
  },
  methods: {
    fetch (next) {
      if (this.fetching || this.endOfFeed) {
        return
      }

      this.fetching = true

      var offset = {}

      // If the next page is requested, then use the latest tweet ID to pass as the ID to start from to the tweet API
      if (next) {
        var tweetIds = this.news.items.map(tweet => parseInt(tweet.tweet_id))

        var lastTweetId = Math.min(...tweetIds)

        if (isFinite(lastTweetId)) {
          offset = { lastTweetId: lastTweetId }
        }
      }

      var newsFilter = Object.assign(offset, this.filters)

      fetchNews(newsFilter)
        .then(items => {
          this.error = ''
          if (next) {
            this.news.items = this.news.items.concat(items)
          } else {
            this.news.items = items
          }

          this.endOfFeed = items.length < 50

          if (! this.endOfFeed) {
            setTimeout(this.actor ? this.parentScroll : this.scroll, 1000)
          }
        })
        .catch((error) => {
          console.log(error)
          this.endOfFeed = true
        })
        .then(() => {
          this.fetching = false
        })
    },
    render () {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.actualRender, 200)
    },
    actualRender () {
      if (this.$refs.columns) {
        this.columns = Math.floor(this.$refs.columns.scrollWidth / 300) || 1
      }
    },
    hideHandle (value) {
      if (value !== null) {
        // this.$store.commit(FILTERS_MUTATION_TYPES.SET_SOCIAL_MEDIA_MONITORING_EXCLUDED_TWITTERS, value)
      } else {
        // this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR_SOCIAL_MEDIA_MONITORING_EXCLUDED_TWITTERS)
      }
    },
    /*toggle (facet, value) {
      if (facet == 'hashtag') {
        if (value !== null) {
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_SOCIAL_MEDIA_MONITORING_HASHTAG, value)
        } else {
          this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR_SOCIAL_MEDIA_MONITORING_HASHTAG)
        }
      } else {
        if (value !== null) {
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_SOCIAL_MEDIA_MONITORING_TWITTER, value)
        } else {
          this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR_SOCIAL_MEDIA_MONITORING_TWITTER)
        }
      }
    },*/
    showActorDetail (actor) {
      if (this.$store.getters.canShowActorDetail) {
        this.$router.push(`/actors/${actor.id}`)
      }
    },
    shortUrl (u) {
      return u.includes('://') ? u.slice(u.indexOf('://') + 3) : u
    },
    scroll: _throttle(function () {
      const elem = this.$refs.scrollable
      if (elem && elem.offsetHeight + elem.scrollTop > 0.95 * elem.scrollHeight - 1500) {
        this.fetch(1)
      }
    }, 1000),
    parentScroll: _throttle(function () {
      const { bottom } = this.$el.getBoundingClientRect();

      if (bottom < document.documentElement.clientHeight + 100) {
        this.fetch(1);
      }
    }, 1000)
  },
  mounted () {
    this.actualRender()

    this.$bus.on('resize300', this.actualRender);

    // This component supports having an event (topic) injected via the URL
    // if this is the case, remove all filters, rewrite the query params and
    // set the topic in the vuex state of the buzz filters and reset all filters (buzz and actor filters)
    const topic = this.$route.query.topic;

    if (! this.actor && topic && topic.length > 0) {
      this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR);
      this.$store.commit(BUZZ_MUTATION_TYPES.UPDATE_BUZZ_FILTER, {name: '', facet: 'topic', value: topic});
    }

    if (this.actor) {
      this.scrollElem = $(this.$el).closest('.scrollable').get(0)
      this.scrollElem.addEventListener('scroll', this.parentScroll, { passive: true })
      this.parentScroll();
    } else {
      this.$refs.scrollable.addEventListener('scroll', this.scroll, { passive: true })
      this.scroll();
    }
  },
  beforeUnmount () {
    this.$bus.off('resize300', this.actualRender)

    if (this.actor) {
      this.scrollElem.removeEventListener('scroll', this.parentScroll, { passive: true })
    } else {
      this.$refs.scrollable.removeEventListener('scroll', this.scroll, { passive: true })
    }
  },
  watch: {
    'news.items' () {
      this.render()
    },
    filters: {
      deep: true,
      handler () {
        if (! this.actor) {
          this.endOfFeed = false;
          this.fetch()
        }
      }
    }
  },
  components: {
    CardNews,
    GeneralError,
    BuzzTable
  }
}
</script>

<style lang="scss">
.news__item {
  padding: 20px;
}
.news__section {
  margin-bottom: 2rem;
  .a {
    font-size: inherit;
    border: 0;
    background: 0;
  }
}
.news__a {
  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.news__a--active {
  text-decoration: none;
  color: inherit;
}
.news__row.row {
  margin: 0 -10px;
}
.news__column {
  flex: 1 0 10rem;
  flex-basis: 10rem;
  padding: 0 10px;
  width: 10rem;
}
.col--aside {
  width: 20rem;
  flex: 0 0 20rem;
  padding-right: 1rem;
  padding-left: 1rem;
  line-height: 1.5em;
}
.col--grow {
  flex: 1 1 0;
  padding-right: 1rem;
  padding-left: 1rem;
}
</style>
