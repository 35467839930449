<template>
  <div>
    <div class="concept-search-deleting-overlay" v-if="deleting">
      Deleting...
    </div>
    <div class="fixed-heading">
      <div class="card__general side-panel__header">
        <div class="card__title side-panel__announcement-card__title side-panel__announcement-header-card__title">Edit Your Search</div>
        <div class="pull-right side-panel__announcement-header-button-container"  style="text-align: right;">
          <ds-button icon="trash" variant="minimal" @click="remove" size="small" class="side-panel__announcement-header-icon"/>
          <ds-button icon="remove" variant="minimal" @click="closeSidePanel" size="small" class="side-panel__announcement-header-icon"/>
        </div>
      </div>
    </div>
    <div class="scrollable side-panel__scrollable__content has-padding" style="height: 90% !important;">
      <div>
        <form-group label="Title">
          <ds-input v-model="modifiedConceptSearch.title" placeholder="Title"/>
        </form-group>
        <form-group label="Search for..." v-if="displaySearchTopics">
          <AutocompleteDropdown v-model="searchTopics" placeholder="Search for..." :do-search="autocompleteSearchTerm"
                                :multiple="true" :maxTags="5"/>
        </form-group>
        <form-group label="Search context">
          <dropdown :multiple="true" :options="topicOptions" v-model="modifiedConceptSearch.context"/>
        </form-group>
        <form-group label="Analyse on">
          <dropdown :multiple="true" :options="topicOptions" v-model="modifiedConceptSearch.topics"/>
        </form-group>
        <form-group label="Exclude">
          <dropdown
            :multiple="false"
            :options="topicOptions"
            :allowClear="true"
            v-model="modifiedConceptSearch.exclude"
          />
        </form-group>
        <form-group label="Geographical context (optional)">
          <AutocompleteTagInput
            :tags="geographyContextTags"
            :options="geographyOptions"
            :addOnlyFromAutocomplete="true"
            placeholder="Search within specific areas"
            @tagChanged="updateGeographyOptions"
            @input:raw="updateGeographyContext"
          />
        </form-group>
        <form-group label="Description (optional)">
          <ds-textarea v-model="modifiedConceptSearch.description" lazy autoresize />
        </form-group>
        <form-group label="Timespan" v-if="$store.getters.hasAccessToExploration && ! $route.path.startsWith('/datalab')">
          <checkbox
          v-model="modifiedConceptSearch.timespan"
          label="Search for news, up until 30 days ago"
          val="month"
          />
          <checkbox
          v-model="modifiedConceptSearch.timespan"
          label="Search for news, up until a year ago"
          val="year"
          />
        </form-group>
      </div>
      <hr />
      <div style="margin-top: 20px;" v-if="! $route.path.startsWith('/datalab')">
        <template v-if="modifiedConceptSearch.status !== 'done'">
          <div>We're still processing the content that we found. You can look for new content once we're finished.</div>
        </template>
        <template v-else-if="allowManualSearch">
          <form-group label="Search for new content" v-if="! hasExplored">
            <ds-button @click="exploreContent" :label="exploring ? 'Searching...' : 'Search'" :disabled="modifiedConceptSearch.status !== 'done'" />
          </form-group>
          <form-group v-else>
            <div v-if="exploreResult.organisation_count == 0 && exploreResult.article_count == 0">
              We have found no articles for your search, try changing some parameters to broaden the search criteria.
            </div>
            <div v-else>
              We have found approximately {{exploreResult.organisation_count}} actors and {{exploreResult.article_count}} articles.
            </div>
          </form-group>
        </template>
      </div>
    </div>
    <action-bar editing="1" class="side-panel__announcement-bottom-section">
      <template v-if="! updated">
        <ds-button variant="secondary" size="extra-small" :label="updating ? 'Saving...' : 'Save changes'" @click="update" :disabled="! canUpdate && updating" />
      </template>
      <template v-else>
        <ds-button variant="secondary" size="extra-small" label="Ok" @click="resetUpdateFlag()" />
        <span class="action-bar__message">Your changes were saved.</span>
      </template>
    </action-bar>
  </div>
</template>

<script>
  import debounce from 'lodash/debounce'

  import AutocompleteTagInput from '../../components/Form/AutocompleteTagInput.vue'
  import AutocompleteDropdown from '../../components/Dropdown/AutocompleteDropdown.vue'
  import Card from '../../components/Card/Card.vue'
  import ActionBar from '../../components/Form/ActionBar.vue'
  import RadioButton from '../../components/Form/RadioButton.vue'
  import ImageInput from '../../components/Form/ImageInput.vue'
  import Dropdown from '../../components/Dropdown/Dropdown.vue'
  import DsTextarea from '../../components/Form/DsTextarea.vue'
  import Checkbox from '../../components/Form/Checkbox.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'

  import {
    deleteConceptSearch,
    Dictionary,
    explore,
    fetchConceptSearch,
    fetchConceptSuggestions,
    fetchGeographySuggestions,
    updateConceptSearch,
  } from '../../api/exploration.js'

  export default {
    name: 'concept-search-panel',
    data () {
      return {
        conceptSearch: {
          search_topics: [],
          context: [],
          dictionary_context_id: null,
          exclude: [],
          geography_context: [],
          timespan: 'month',
          title: '',
        },
        modifiedConceptSearch: {
          search_topics: [],
          context: [],
          exclude: [],
          dictionary_context_id: null,
          geography_context: [],
          timespan: 'month',
          title: '',
        },
        dictionaries: [],
        geographyContextTags: [], // The labels for the configured geography context
        geographyOptions: [],
        errors: {},
        updating: false,
        updated: false,
        exploreResult: null,
        exploring: false,
        hasExplored: false, // Did we refresh the search for new content
        deleting: false,
      }
    },
    props: ['conceptSearchId', 'allowManualSearch'],
    computed: {
      topicOptions () {
        if (!this.dictionaries) {
          return []
        }

        return this.dictionaries.map(item => ({
          value: item.id,
          label: item.value,
        }))
      },
      canUpdate () {
        return (this.modifiedConceptSearch.search_topics.length > 0 || this.modifiedConceptSearch.context.length > 0) && this.modifiedConceptSearch.title.length > 0
      },
      searchTopics: {
        get () {
          return this.modifiedConceptSearch.search_topics
        },
        set (v) {
          this.modifiedConceptSearch.search_topics = v
        }
      },
      displaySearchTopics () {
        if (this.modifiedConceptSearch.search_topics.length > 0) {
          return true
        }

        return !this.$route.path.startsWith('/datalab')
      }
    },
    methods: {
      autocompleteSearchTerm (query) {
        return fetchConceptSuggestions({ query: query, conceptType: 'any' })
          .then(response => {
            var options = []

            response.forEach(function (concept) {
              options.push({
                label: concept.value,
                value: concept.uri
              })
            })

            return options
          })
          .catch(err => {
            console.log(err)
          })
      },
      resetUpdateFlag () {
        this.updating = false
        this.updated = false
      },
      exploreContent () {
        this.exploring = true

        explore(this.conceptSearchId)
        .then(response => {
          this.hasExplored = true
          this.exploring = false
          this.exploreResult = response
        })
      },
      updateGeographyOptions: debounce(async function (input) {
        fetchGeographySuggestions({ query: input })
        .then(response => {
          this.geographyOptions = []

          if (!Array.isArray(response)) {
            return
          }

          response.forEach(item => {
            if (!item.label.eng) {
              return
            }

            var label = item.label.eng

            if (item.type == 'place' && item.country && item.country.label && item.country.label.eng && !label.includes(item.country.label.eng) && !label.includes(',')) {
              label += ' (' + item.country.label.eng + ')'
            }

            this.geographyOptions.push({
              text: label,
              geographyObject: item
            })
          })
        })
        .catch(err => {
          this.geographyOptions = []
        })
      }, 150),
      updateGeographyContext (context) {
        this.modifiedConceptSearch.geography_context = context.map(item => {
          return item.optionValue.geographyObject
        })
      },
      closeSidePanel () {
        // TODO: check if there's a diff between the original concept search and the current concept search
        // if there is, we should probably put up a modal screen indicating that there are unsaved changes
        this.hidePreview()
      },
      hidePreview () {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      remove () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, { body: 'Do you want to delete this search?', resource: this.conceptSearch, modalContextType: 'concept-search' })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
      },
      update () {
        this.updating = true

        updateConceptSearch(this.conceptSearchId, {
          title: this.modifiedConceptSearch.title,
          search_topics: this.modifiedConceptSearch.search_topics,
          context: this.modifiedConceptSearch.context,
          exclude: this.modifiedConceptSearch.exclude,
          dictionary_context_id: this.modifiedConceptSearch.topics,
          description: this.modifiedConceptSearch.description,
          geography_context: this.modifiedConceptSearch.geography_context,
          timespan: this.modifiedConceptSearch.timespan,
        })
        .then(response => {
          this.updating = false
          this.updated = true
          this.conceptSearch = response
          this.modifiedConceptSearch = this.conceptSearch
          this.parseGeographyTags()

          this.$bus.emit('refreshConceptSearches')
        })
        .catch(err => {
          this.updating = false
        })
      },
      parseGeographyTags () {
        this.geographyContextTags = []

        if (!this.modifiedConceptSearch.geography_context) {
          return
        }

        this.modifiedConceptSearch.geography_context.forEach(item => {
          if (item.label.eng) {
            var label = item.label.eng

            if (item.type == 'place' && item.country && item.country.label && item.country.label.eng && !label.includes(item.country.label.eng) && !label.includes(',')) {
              label += ' (' + item.country.label.eng + ')'
            }

            this.geographyContextTags.push({
              text: label,
              optionValue: {
                text: label,
                geographyObject: item
              }
            })
          }
        })
      }
    },
    mounted () {
      fetchConceptSearch(this.conceptSearchId)
        .then(response => {
          this.conceptSearch = response
          this.modifiedConceptSearch = this.conceptSearch
          this.parseGeographyTags()
        })
        .catch(err => {
          // console.log(err);
        })

      Dictionary.get().then(dictionaries => {
        this.dictionaries = dictionaries
      })

      this.$bus.on('conceptSearchDeleteConfirmation', (context) => {
        if (context.resource && context.resource.id == this.conceptSearchId) {
          this.deleting = true
          deleteConceptSearch(this.conceptSearchId)
          .then(response => {
            // Refresh the concept searches
            this.$bus.emit('refreshConceptSearches')
            this.deleting = false
            this.hidePreview()
          }).catch(error => {
            this.deleting = false
            console.error(error)
          })
        }
      })
    },
    beforeUnmount () {
      this.$bus.off('conceptSearchDeleteConfirmation')
  },
    components: {
      Card,
      ActionBar,
      RadioButton,
      ImageInput,
      Checkbox,
      Dropdown,
      DsTextarea,
      AutocompleteTagInput,
      AutocompleteDropdown,
    }
  }
</script>

<style lang="scss">
  .concept-search-deleting-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5);
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
</style>
