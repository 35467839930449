<template>
  <div
      class="icon-button__container"
      @click.stop="emitClick"
  >
    <div v-if="label">{{ label }}</div>
    <div class="icon-button__icon" :key="componentKey">
      <icon
          @click.stop="emitClick"
          :name="iconName"
          v-tooltip.left="toolTip"
          :size="iconSize"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from '~/vue'

export default defineComponent({
  name: 'IconButton',
  props: {
    fill: {
      type: Boolean,
      required: true
    },
    icon: {
      type: String,
      required: true,
      validator(value) {
        return ['star', 'thumbs-up', 'spinner', 'check', 'email-outline'].includes(value)
      }
    },
    label: {
      type: String,
      required: false,
    },
    toolTip: {
      type: String,
      required: false,
    },
    iconSize: {
      type: String,
      required: false,
    }
  },
  emits: ['click'],
  data() {
    return {
      componentKey: 0
    }
  },
  computed: {
    iconName(): string {
      if (this.icon === 'star') {
        return this.fill ? 'star' : 'star-outline'
      }

      return this.icon
    },
  },
  methods: {
    emitClick() {
      this.$emit('click')
    }
  }
})

</script>

<style scoped lang="scss">

.icon-button__container {
  display: flex;
  justify-content: center;
  margin-left: auto;
  cursor: pointer;
  align-items: center;
}

.icon-button__icon {
  margin-left: 0.2rem;
}

</style>
