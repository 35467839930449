<template>
  <span :class="classes" v-if="show">
    ({{ sign }}{{ delta }}%)
  </span>
</template>

<script>
export default {
  props: {
    current: Number,
    previous: Number,
  },
  computed: {
    classes() {
      return this.sign === '+' ? 'positive' : 'negative';
    },
    delta() {
      if (! this.current || ! this.previous) {
        return;
      }

      return ((this.current / this.previous ) * 100 - 100).toFixed();
    },
    show () {
      return this.current && this.previous;
    },
    sign() {
      return this.delta >= 0 ? '+' : '';
    },
  }
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/variables';

.positive {
  color: $color-primary;
}

.negative {
  color: $color-error-light;
}
</style>
