<template>
  <div>
    <template v-if="noCount">
      <span class="counter__badge counter__indicator" ></span>
    </template>
    <template v-else>
      <span class="counter__badge" v-text="displayCount" :class="{ 'counter__small': displayCount.length >= 3 }"></span>
    </template>
  </div>
</template>

<script>
  export default {
    props: {
      count: {
        type: Number,
        required: false
      },
      noCount: {
        type: Boolean,
        default: false
      }
    },
    computed: {
     displayCount () {
       if (this.count > 99) {
         return "+99"
       }
       return this.count
     }
   }
 }
</script>

<style>
.counter__badge {
   position: absolute;
   top: 1px;
   right: 8px;
   font-size: .7em;
   background: red;
   color: white;
   width: 17px;
   height: 17px;
   text-align: center;
   line-height: 19px;
   border-radius: 50%;
   box-shadow: 0 0 1px #333;
}

.counter__indicator {
  height: 10px;
  width: 10px;
  right: -5px;
  top: -5px;
}

.counter__small {
  font-size: .6em !important;
}

.numberCircle {
    border-radius: 50%;

    width: 3px;
    height: 3px;
    padding: 1px;

    background: #fff;
    border: 2px solid #666;
    color: #666;
    text-align: center;

    font: 32px Arial, sans-serif;
}
</style>
