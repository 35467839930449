<template>
  <div class="container">
    <slot></slot>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: "Container"
  })
</script>

<style scoped lang="scss">
  @import "resources/assets/scss/variables";

  .container {
    max-width: 1200px;
    margin: 0 auto;

    @media (max-width: $screen-md) {
      padding: 0 0.5rem;
      max-width: 100%;
    }
  }
</style>
