<template>
  <div>
    <form-group label="Add feature" :error="validationError">
      <div class="emp-features__feature-input">
        <ds-input v-model="feature" style="width: 220px;"/>
        <ds-button @click="addFeature()" label="add feature" icon="plus" size="small" :disabled="!canAddFeature" style="margin-left: 0.5em;"/>
      </div>
    </form-group>

    <!-- table -->
    <table class="emp-features__table">
      <thead>
      <tr>
        <th class="emp-features__feature-th">
          Feature
        </th>
        <th class="emp-features__package-th" v-for="(packageOption, index) in packages" :key="'feature_row_header_' + index">
          {{packageOption.title}}
        </th>
        <th>
        </th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="(feature, featureIndex) in features" :key="'feature_row_' + featureIndex">
        <td class="emp-features__feature-name">
          {{feature.name}}
        </td>
        <td v-for="(packageOption, index) in packages" :key="'feature_package_column_' + index + featureIndex">
          <!-- it's better to have a computed property to steer the value of the checkbox than a method -->
          <EMPFeatureCheckbox :packageOption="packageOption" :featureId="feature.id" @toggleFeatureForPackage="toggleFeatureForPackage(packageOption, feature.id)" />
        </td>
        <td>
          <ds-button icon="trash" @click="removeFeature(feature.id)"/>
        </td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { EcosystemMemberPackageFeatures, linkPackageWithFeature, unlinkPackageWithFeature } from '../../../api/ecosystem-member-packages'
  import Checkbox from '../../Form/Checkbox.vue'
  import EMPFeatureCheckbox from './EMPFeatureCheckbox.vue'

  export default {
    name: 'EcosystemMemberPackageFeatures.vue',
    props: {
      packages: Array,
    },
    data () {
      return {
        feature: '',
        validationError: null,
        features: [],
        saving: false,
      }
    },
    computed: {
      canAddFeature () {
        return this.feature.length > 6 && this.feature.length < 40
      },
    },
    methods: {
      isFeatureSelectedForPackage (packageOption, featureId) {
        return packageOption.features && packageOption.features.find(feature => feature.id === featureId)
      },
      toggleFeatureForPackage (packageOption, featureId) {
        this.saving = true

        if (this.isFeatureSelectedForPackage(packageOption, featureId)) {
          unlinkPackageWithFeature(packageOption.id, featureId)
            .then(response => {
              this.fetchFeatures()
              this.saving = false
            })
            .catch(e => {
              console.log(e)
              this.saving = false
            })

          return
        }

        linkPackageWithFeature(packageOption.id, featureId)
          .then(response => {
            this.fetchFeatures()
            this.saving = false
          })
          .catch(e => {
            console.log(e)
            this.saving = false
          })
      },
      addFeature () {
        if (!this.canAddFeature) {
          return
        }

        EcosystemMemberPackageFeatures
          .post({ name: this.feature })
          .then(response => {
            this.fetchFeatures()
            this.feature = ''
            this.saving = false
          })
          .catch(e => {
            this.saving = false
            console.log(e)
          })
      },
      removeFeature (featureId) {
        EcosystemMemberPackageFeatures
          .delete(featureId)
          .then(response => {
            this.fetchFeatures()
          })
          .catch(e => {
            console.log(e)
          })
      },
      fetchFeatures () {
        EcosystemMemberPackageFeatures
          .get()
          .then(response => {
            this.features = response
          })
      },
    },
    async mounted () {
      try {
        this.features = await EcosystemMemberPackageFeatures.get()
      } catch (e) {
        console.log(e)
      }
    },
    components: {
      EMPFeatureCheckbox,
      Checkbox,
    },
    watch: {
      feature (v) {
        if (v && v.length > 40) {
          this.validationError = 'A feature name can be maximum 40 characters long'
        }

        this.validationError = null
      },
    },
  }
</script>

<style scoped lang="scss">
  .emp-features__feature-input {
    display: flex;
    flex-direction: row;
  }

  .emp-features__feature-th {
    font-weight: 500;
    min-width: 200px;
  }

  .emp-features__package-th {
    min-width: 200px;
  }

  .emp-features__feature-name {
    padding-left: 10px;
  }

  .emp-features__table {
    border-collapse: collapse;
    border: 1px #CECECE solid;

    td {
      border: 1px #CECECE solid;
    }
  }

  .emp-features__feature-checkbox {
    display: flex;
    justify-content: center;
  }

</style>
