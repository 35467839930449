<template>
  <div class="home-simplified">
    <basic-simplified-hero :title="onboardingTextConfig.intelligenceSimplifiedTitle"
                           :subtitle="onboardingTextConfig.intelligenceSimplifiedSubtitle"></basic-simplified-hero>
    <Container style="flex-grow: 1">
      <div style="display: flex; justify-content: flex-end" v-if="isLoggedIn && !isMember">
        <!-- v-show hack as dynamic fa-icon doesn't seem to work here -->
        <label-filter @updateFilter="onlyShowFollowing = !onlyShowFollowing"
                      @click="trackHeapEvent('intelligenceSimplified.toggleFollowingFilter')"
                      v-show="showOnlyIcon"
                      icon="paper-plane"
                      variant="transparent"
                      :model-value="onlyShowFollowing"
                      :text="$t('intelligence_simplified_show_only')" :is-enabled="onlyShowFollowing"
        ></label-filter>
        <label-filter @updateFilter="onlyShowFollowing = !onlyShowFollowing"
                      v-show="!showOnlyIcon"
                      @click="trackHeapEvent('intelligenceSimplified.toggleFollowingFilter')"
                      icon="paper-plane"
                      variant="transparent"
                      :model-value="onlyShowFollowing"
                      :text="$t('intelligence_simplified_show_only')" :is-enabled="onlyShowFollowing"
        ></label-filter>
      </div>
      <h2 class="h2">{{ $t('shp_intelligence_title').toUpperCase() }}</h2>

      <div v-if="showIntelligenceUpgradeCTA" class="intelligence_article">
        <div class="row mt-3 home-simplified__articles-container">
          <div class="col col-xs-12 col-sm-12 col-md-12 home-simplified__article"
               v-for="index in [1,2,3,4,5,6,7]"
               :key="'fake-article' + index">
            <div class="carousel__slide-container">
              <UnstyledLink target="_blank" class="inaccessible-article__cta-container">
                <div class="intelligence-cta__overlay">
                  <h3 class="h3">
                    <router-link to="/profile/subscription#upgrade">{{ intellligenceSplitCtaText[1] }}</router-link>
                  </h3>
                </div>
                <icon name="lock" class="lock-icon"/>
                <MiniCard
                    class="inaccessible-article__cta"
                    title="inaccessible - upgrade required"
                    subtitle="inaccessible"
                    content="This content is not accessible because an upgrade to a higher-level package is required"
                />
              </UnstyledLink>
            </div>
          </div>
        </div>
      </div>

      <p v-if="!showIntelligenceUpgradeCTA && !hasArticleDigestEnabled && followedConceptSearchIds.length > 0 && !isOwner"
         style="color: red">
        {{ $t('relevant_articles_cta_1') }}
        <router-link to="/profile/update#notification-settings">{{ $t('profile_settings_notifications') }}</router-link>
        {{ $t('relevant_articles_cta_2') }}
      </p>
      <br>
      <div v-if="!showIntelligenceUpgradeCTA" class="row home__category_count_container">
        <div class="col col-xs-12 col-sm-4 col-md-3" style="display: block; position: relative;"
             v-for="conceptSearch in filteredConceptSearches">
          <div @click="handleConceptSearchClick(conceptSearch)" class="concept-search__wrapper__wrapper">
            <div class="concept-search__wrapper"
                 :class="{'concept-search__wrapper--disabled': conceptSearch.status !== 'done'}"
                 :style="conceptSearchStyle(conceptSearch)">
              <div class="concept-search__container">
                <div class="top-right-buttons" @click.stop v-if="$store.getters.hasAccessToMonitoring">
                  <follow-button class="a-button" :model-value="isFollowed(conceptSearch)"
                                 @update:modelValue="setFollowed(conceptSearch, $event)" v-if="!showingPublishButton"/>
                  <publish-button class="a-button" :model-value="isPublished(conceptSearch)"
                                  @update:modelValue="setPublished(conceptSearch, $event)" v-else/>
                </div>
                <div class="concept-search__title-and-description">
                  <div class="concept-search__title">{{ truncate(conceptSearch.title, 30) }}</div>
                  <div class="concept-search__description">{{ conceptSearch.description }}</div>
                </div>
              </div>
            </div>
            <div class="concept-search__spinner" v-if="conceptSearch.status !== 'done'">
              <div>
                <icon name="spinner"/>
                {{ $t('intelligence_simplified_processing') }}
              </div>
              <div style="display: block; position: absolute; margin-top: 30px;">
                <ds-button size="small" label="Reset" variant="secondary" @click="resetStatus(conceptSearch.id)"
                           style="margin-left: auto; color: white;"
                           v-if="$store.getters.isUserOfDataScoutsTeam"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
    <SimplifiedFooter/>
  </div>
</template>

<script lang="ts">
import Container from '../../components/Container/Container.vue'
import SimplifiedFooter from '../../components/SimplifiedFooter/SimplifiedFooter.vue'
import BasicSimplifiedHero from '../../components/Simplified/BasicSimplifiedHero.vue'
import { publishConceptSearch, resetExplorationStatus, unpublishConceptSearch, } from '../../api/exploration.js'
import LabelFilter from '../../components/Filters/LabelFilter.vue'
import ConceptSearchMixin from '../../util/ConceptSearchMixin.js'
import { splitCtaText, truncate } from '../../util/helpers.js'
import FollowButton from '../../components/FollowButton/FollowButton.vue'
import PublishButton from '../../components/PublishButton/PublishButton.vue'
import { trackHeapEvent } from '../../util/analytics.js'
import { ActionTypes as ConceptSearchesAcionTypes } from '../../store/modules/concept-searches.js'
import OnboardingMixin from '../../util/OnboardingMixin.js'
import { defineComponent } from 'vue'
import MiniCard from "@/components/MiniCard/MiniCard.vue";
import UnstyledLink from "@/components/UnstyledLink/UnstyledLink.vue";

export default defineComponent({
  name: 'IntelligenceSimplified',
  components: {
    UnstyledLink, MiniCard,
    FollowButton,
    PublishButton,
    LabelFilter,
    Container,
    SimplifiedFooter,
    BasicSimplifiedHero,
  },
  data: () => {
    return {
      onlyShowFollowing: false,
    }
  },
  created() {
    this.fetchConceptSearches()
  },
  computed: {
    showIntelligenceUpgradeCTA() {
      if (!this.isLoggedIn) {
        return !this.displayExploreContent

      }
      return this.isLoggedIn && !this.displayExploreContent && this.config.packageCTAs && this.config.packageCTAs.intelligence && this.config.packageCTAs.intelligence.enabled
    },
    displayExploreContent() {
      const hasAccess = (this.$store.getters.hasAccessToExploration || this.$store.getters.hasAccessToMonitoring)

      if (!this.isLoggedIn) {
        return hasAccess && this.config.viewDashboard && this.config.viewDashboard.includes('explore-content')
      }

      return this.$store.getters.isLoggedIn && hasAccess && this.config.viewDashboard && this.config.viewDashboard.includes('explore-content')
    },
    intellligenceSplitCtaText() {
      if (!this.isLoggedIn) {
        return splitCtaText('This content is not available. Please login')
      }

      return splitCtaText(this.intelligenceCtaUpgradeText)
    },
    intelligenceCtaUpgradeText() {
      if (this.showIntelligenceUpgradeCTA) {
        return this.config.packageCTAs.intelligence.text || this.$t('default_intelligence_upgrade_cta')
      }
      return ''
    },
    allConceptSearches() {
      return this.$store.state.conceptSearches.allConceptSearches || []
    },
    filteredConceptSearches() {
      if (!this.onlyShowFollowing) {
        return this.conceptSearches
      }
      return this.conceptSearches.filter(conceptSearch => this.isFollowed(conceptSearch))
    },
    conceptSearches() {
      return this.allConceptSearches.slice(0).sort((a, b) => b.explored_at.localeCompare(a.explored_at))
    },
    allConceptSearchesPromise() {
      return this.$store.state.conceptSearches.allConceptSearchesPromise
    },
    hasArticleDigestEnabled() {
      return ['daily', 'weekly'].includes(this.$store.getters.userSettings.contentDigest)
    },
    showOnlyIcon() {
      return this.onlyShowFollowing ? 'paper-plane' : ''
    },
    isOwner() {
      return this.$store.getters.isOwner
    },
    showingPublishButton() {
      return this.isOwner
    },
    config() {
      return this.$store.state.config
    },
    isMember() {
      return this.$store.getters.isMember
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
  },
  mixins: [ConceptSearchMixin, OnboardingMixin],
  methods: {
    trackHeapEvent,
    truncate,
    isFollowed(conceptSearch) {
      return this.conceptSearchIsFollowed[conceptSearch.id]
    },
    setFollowed(conceptSearch, followed) {
      trackHeapEvent('IntelligenceSimplified.toggleFollowing')
      if (followed) {
        this.followConceptSearch(conceptSearch.id)
      } else {
        this.unfollowConceptSearch(conceptSearch.id)
      }
    },
    isPublished(conceptSearch) {
      return conceptSearch.published || false
    },
    setPublished(conceptSearch, published) {
      this.conceptSearch.published = published

      if (published) {
        publishConceptSearch(conceptSearch.id)
      } else {
        unpublishConceptSearch(conceptSearch.id)
      }
    },
    conceptSearchStyle(conceptSearch) {
      return 'background-image: url(' + conceptSearch.image + ');'
    },
    fetchConceptSearches() {
      if (!this.allConceptSearchesPromise) {
        this.$store.dispatch(ConceptSearchesAcionTypes.FETCH_ALL_CONCEPT_SEARCHES)
      }

      this.allConceptSearchesPromise.then(() => {
        this.refreshSearchesIfContainsInProgress()
      })
    },
    refreshSearchesIfContainsInProgress() {
      if (this.conceptSearches.find(search => search.status === 'in_progress')) {
        setTimeout(() => {
          this.fetchConceptSearches()
        }, 30000)
      }
    },
    resetStatus(conceptSearchId) {
      resetExplorationStatus(conceptSearchId)
          .then(response => {
            this.fetchConceptSearches()
          })
    },
    handleConceptSearchClick(conceptSearch) {
      trackHeapEvent('intelligenceSimplified.clickConceptSearch')
      this.$router.push({
        name: 'intelligence-detail',
        params:
            { id: conceptSearch.id },
      })
    },
  },
})
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.home-simplified {
  flex-direction: column;
  display: flex;
}

:deep(.inaccessible-article__cta-container) {
  position: relative;
  overflow-y: hidden;
  display: block;

  .inaccessible-article__cta {
    div {
      color: #0000001c;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
  }

  .lock-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    fill: var(--primary)
  }

  &:hover,
  &:focus {
    .intelligence-cta__overlay {
      transform: translate3d(0, 0, 0);
    }
  }

}

.intelligence-cta__overlay {
  padding: 50px;
  margin: 1px;
  // padding: 0 5px;
  position: absolute;
  height: calc(100% - 1rem - 2px);
  width: calc(100% - 2px);
  background: rgba(255, 255, 255, 0.7);
  top: 0;
  transition: transform 300ms;
  transform: translate3d(0, 100%, 0);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

</style>
