<template>
  <form @submit.prevent="save" class="settings__ecosystem has-action-bar">
    <tabs :tabs="tabs" :saveToUrl="true">

      <template v-slot:[getTabName(0)]>
        <div>
          <data-enrichment v-model:form="form"/>
        </div>
      </template>
      <template v-slot:[getTabName(1)]>
        <div>
          <data-enrichment-frequency v-model:form="form"/>
        </div>
      </template>
      <template v-slot:[getTabName(2)]>
        <div>
          <other v-model:form="form"/>
        </div>
      </template>
    </tabs>
  </form>
</template>

<script>
  import DataEnrichment from './SubscriptionTabs/DataEnrichment.vue'
  import DataEnrichmentFrequency from './SubscriptionTabs/DataEnrichmentFrequency.vue'
  import Other from './SubscriptionTabs/Other.vue'
  import Tabs from '../Tabs/Tabs.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    data () {
      return {
        typeError: null,
      }
    },
    computed: {
      form () {
        return this.$parent.$parent.form
      },
      tabs () {
        return ['Enrichment Data', 'Enrichment Frequency', 'Other']
      },
    },
    methods: {
      getTabName (index) {
        return this.tabs[index]
      },
    },
    mounted () {
      // Make some of the configuration flags reactive
      if (!this.form.activitySuggestionEnabled) {
        this.form.activitySuggestionEnabled = false
        this.form.activitySuggestionAutoAcceptEnabled = false
      }

      if (!this.form.technologySuggestionEnabled) {
        this.form.technologySuggestionEnabled = false
        this.form.technologySuggestionAutoAcceptEnabled = false
      }

      if (!this.form.industrySuggestionEnabled) {
        this.form.industrySuggestionEnabled = false
        this.form.industrySuggestionAutoAcceptEnabled = false
      }

      if (!this.form.domainSuggestionEnabled) {
        this.form.domainSuggestionEnabled = false
        this.form.domainSuggestionAutoAcceptEnabled = false
      }

      if (!this.form.categorySuggestionEnabled) {
        this.form.categorySuggestionEnabled = false
        this.form.categorySuggestionAutoAcceptEnabled = false
      }

      if (!this.form.enrichmentScope || !['all', 'scoped'].includes(this.form.enrichmentScope)) {
        this.form.enrichmentScope = 'all'
      }

      if (!this.form.newsMonitorScope || !['all', 'scoped'].includes(this.form.newsMonitorScope)) {
        this.form.newsMonitorScope = ''
      }

      if (!this.form.profileEnrichment) {
        this.form.profileEnrichment = 'automatic'
      }
    },
    components: {
      DataEnrichment,
      DataEnrichmentFrequency,
      Other,
      Tabs,
    },
  })
</script>
