<template>
  <div class="with-configuration-tabs">
    <div class="with-configuration-tabs--content">
      <slot/>
    </div>
    <div class="with-configuration-tabs--menu" v-if="!ui.isMobile">
      <configuration-tabs :items="tabs"/>
    </div>
  </div>
</template>

<script lang="ts">
import ConfigurationTabs from './ConfigurationTabs.vue'
import ReportMixin from '../../util/ReportMixin.js'
import { ACTION_TYPES as CONVERSATION_ACTION_TYPES } from '../../store/modules/conversations.js'
import TranslationsMixin from '../../util/TranslationsMixin.js'
import UiMixin from '../../util/UiMixin.js'
import { defineComponent } from 'vue'

export default defineComponent({
  mixins: [
    TranslationsMixin,
    ReportMixin,
    UiMixin,
  ],
  components: { ConfigurationTabs },
  computed: {
    isMobile() {
      return this.$store.getters.isMobile
    },
    conversations() {
      return this.$store.state.conversations.listData.data
    },
    isMember() {
      return this.$store.getters.isMember
    },
    isOwner() {
      return this.$store.getters.isOwner
    },
    isPortfolioMember() {
      return this.$store.getters.isPortfolioMember
    },
    canUseDictionaries() {
      return this.$store.getters.hasAccessToExploration || this.$store.getters.hasAccessToMonitoring && (this.isOwner || this.isMember)
    },
    canShowEcosystemPackages() {
      return this.$store.getters.hasAccessToEcosystemMemberPackages &&
          this.isOwner
    },
    canDisplayCustomScores() {
      return this.$store.getters.hasAccessToCustomScores &&
          this.$store.getters.hasCustomScores &&
          ((['Satisco', 'own', 'local', 'sales', 'crypto'].includes(this.$store.getters.ecosystemName) &&
              this.isMember) || this.$store.getters.isDeveloper)
    },
    canDisplayDataConsumption() {
      if (this.$store.getters.packageType === 'standard') {
        return true
      }

      return (this.$store.getters.isOwn ||
          this.$store.getters.isSales ||
          this.$store.getters.isCi ||
          this.$store.getters.isWear ||
          this.$store.getters.isCrypto ||
          ['PropTechStudy', 'ForwardFooding', 'proptechlab', 'Workero'].includes(this.$store.getters.ecosystemName)) && this.isOwner
    },
    canShowUserBilling() {
      // Only show billing to users that are ecosystem members in an ecosystem that has packages enabled and ready to go
      return this.canShowEcosystemPackages && this.$store.getters.isLoggedIn && this.$store.getters.isActor
    },
    canShowBillingMethods() {
      if (this.$store.getters.isExplorationOnly) {
        return false
      }

      return this.isOwner
    },
    canShowConfiguration() {
      if (this.$store.getters.isExplorationOnly) {
        return false
      }

      return this.isMember
    },
    showShareInsights() {
      return false
    },
    showSuggestions() {
      return false
    },
    showCuration() {
      return this.$store.getters.isPublisherEnabled
    },
    showEmails() {
      return true
    },
    showOnboarding() {
      return this.$store.getters.isPublisherEnabled
    },
    isProfilePage() {
      return this.$route.path.startsWith('/profile')
    },
    canShowEcosystemTools() {
      return (this.isMember || this.isPortfolioMember) && !this.$store.getters.isExplorationOnly
    },
    canShowMembersAndPermissions() {
      return this.isOwner && !this.$store.getters.isExplorationOnly
    },
    hasAccessToNewCommunities() {
      return this.$store.getters.hasAccessToCommunities
    },
    hasAccessToSpottingAreas() {
      return this.$store.getters.hasAccessToNewSpottingAreas
    },
    tabs() {
      const isDeveloper = this.$store.getters.isDeveloper
      const hasOpenCall = this.hasOpenCall()

      if (this.isProfilePage) {
        return [
          { label: this.$t('profile_settings_my_info'), icon: 'id-badge', path: '/profile/update#my-info' },
          !this.isMember && {
            label: this.$t('profile_settings_my_actors'),
            icon: 'building',
            path: '/profile/update#my-actors',
          },
          {
            label: this.$t('profile_settings_password'),
            icon: 'key',
            path: '/profile/update#password-and-security',
          },
          {
            label: this.$t('profile_settings_notifications'),
            icon: 'bell',
            path: '/profile/update#notification-settings',
          },
          this.canShowUserBilling && {
            label: this.$t('profile_settings_billing'),
            icon: 'file-invoice-dollar',
            children: [
              { label: 'Upgrade', icon: 'arrow-up', path: '/profile/subscription#upgrade' },
              { label: 'Billing overview', icon: 'file-invoice-dollar', path: '/profile/subscription#billing' },
              { label: 'Invoices', icon: 'file-invoice', path: '/profile/subscription#invoices' },
            ],
          },
          this.conversations.length > 0 && {
            label: this.$t('conversations_title'),
            icon: 'comment',
            path: '/profile/conversations',
          },
        ]
      }

      if (this.$store.getters.isExplorationOnly) {
        return [
          (this.showShareInsights) && { label: 'Share Insights', path: '/dashboards/email-reports', icon: 'share' },
          { label: 'Users', icon: 'invite-user', path: '/settings/users' },
          { path: '/settings/exploration', icon: 'tag', label: 'Exploration' },
        ]
      }
      return [
        this.canShowEcosystemTools && {
          label: 'Ecosystem Tools',
          icon: 'toolbox',
          children: [
            (this.showShareInsights) && { label: 'Share Insights', path: '/dashboards/email-reports', icon: 'share' },
            (this.showCuration) && { label: 'Curation', icon: 'check', path: '/datalab/curation' },
            (this.isOwner || this.isMember) && { label: 'Spreadsheet', icon: 'grid', path: '/datalab/spreadsheet' },
            this.canDisplayCustomScores && { label: 'Custom Scores', icon: 'bar-chart', path: '/settings/custom-scores' },
            this.canUseDictionaries && { path: '/settings/exploration', icon: 'tag', label: 'Exploration' },
            (this.isOwner || this.isMember) && { label: 'Sources', icon: 'upload', path: '/datalab/sources' },
            (this.showSuggestions) && { label: 'Suggestions', path: '/datalab/suggestions', icon: 'tag' },
            this.$store.getters.knowledgeSharing && (this.isOwner || this.isMember) && { label: 'Reporting', icon: 'reporting', path: '/settings/reporting' },
          ],
        },
        {
          label: 'Members & Permissions',
          icon: 'users',
          children: [
            { label: 'Users', icon: 'invite-user', path: '/settings/users' },
            this.canShowMembersAndPermissions && { label: 'Permissions', icon: 'permissions', path: '/settings/publisher' },
            this.canShowEcosystemPackages && { label: 'Ecosystem Member Packages', icon: 'permissions', path: '/settings/ecosystem-member-packages' },
            (this.isOwner || this.isMember) && { label: 'Maintenance Requests', icon: 'invite-user', path: '/settings/maintenance-requests' },
          ],
        },
        this.canShowConfiguration && {
          label: 'Ecosystem Configuration',
          icon: 'settings',
          children: [
            this.isOwner && { label: 'Configuration', icon: 'wrench', path: '/settings/ecosystem' },
            this.isOwner && { label: 'Settings', icon: 'package', path: '/settings/subscription' },
            (this.isOwner || this.isMember) && { label: 'Taxonomies', icon: 'taxonomies', path: '/datalab/taxonomies' },
            (this.showOnboarding && isDeveloper) && {
              path: '/settings/onboarding',
              icon: 'invite-user',
              label: 'Onboarding & Translations',
            },
            this.isOwner && { label: 'Relationships', icon: 'link', path: '/datalab/relationships' },
            this.hasAccessToNewCommunities && { label: 'Communities', path: '/settings/communities', icon: 'fort-awesome' },
            this.hasAccessToSpottingAreas && { label: this.spottingAreasLabel, path: '/settings/spotting-areas', icon: 'microscope' },
            (this.showEmails && isDeveloper) && { label: 'Emails', icon: 'star', path: '/settings/custom-emails' },
          ],
        },
        (this.isOwner || this.isMember) && {
          label: 'Ecosystem Reporting',
          icon: 'monthly-report-icon',
          children: [
            {
              label: 'Ecosystem Dashboard',
              icon: 'gauge-high',
              path: '/settings/ecosystem-heartbeat',
            },
            (this.isOwner || this.isMember) && {
              label: 'Data Consumption',
              icon: 'data-consumption',
              path: '/settings/data-consumption',
            },
            hasOpenCall && {
              label: 'Open Call Report',
              icon: 'share',
              path: '/settings/ecosystem-reporting',
            },
          ],
        },
        (this.isOwner && !this.$store.getters.isExplorationOnly) && {
          label: 'Connected Services',
          icon: 'share',
          children: [
            { label: 'Integrations', icon: 'services', path: '/settings/integrations' },
            isDeveloper && { label: 'Developer', icon: 'developer', path: '/settings/developer' },
          ],
        },
      ]
    },
  },
  mounted() {
    this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
  },
})
</script>

<style lang="scss" scoped>
@import "resources/assets/scss/variables";

.with-configuration-tabs {
  display: flex;
  height: 100%;
}

.with-configuration-tabs--content {
  height: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  min-width: 0;
  flex-basis: 0;
  position: relative; // Needed because absolute positioning is sometimes used to position things to the right
}

.with-configuration-tabs--menu {
  user-select: none;
  height: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  border-left: 1px solid $color-borders;
  overflow: auto;
}
</style>
