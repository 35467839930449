<template>
  <Drawer :isOpen="isOpen" @close="$emit('close')">
    <ul class="mobile-navigation-drawer__menu-items">
      <li class="mobile-navigation-drawer__menu-item">
        <router-link to="/">
          <icon name="house" size="14" :style="{fill: ecosystemColor}"/>
          <span style="margin-left: 22px;">Home</span>
        </router-link>

        <router-link v-if="hasAccessToCommunities && communities.length > 0" to="/communities">
          <icon name="users" size="14" :style="{fill: ecosystemColor}"/>
          <span style="margin-left: 22px;">{{ $t('communities_generic') }}</span>
        </router-link>

        <router-link v-if="canViewSpottingAreas && spottingAreas.length > 0" to="/spotting-areas">
          <icon name="microscope" size="14" :style="{fill: ecosystemColor}"/>
          <span style="margin-left: 22px;">{{ spottingAreasLabel }}</span>
        </router-link>

        <router-link v-if="!canViewSpottingAreas && viewActorTypes.includes('LegalEntity')" to="/actors-simplified">
          <icon name="building" size="14" :style="{fill: ecosystemColor}"/>
          <span style="margin-left: 22px;">Actors</span>
        </router-link>

        <router-link v-if="viewActorTypes.includes('Product')" to="/products-simplified">
          <icon name="light-bulb-solid" size="14" :style="{fill: ecosystemColor}"/>
          <span style="margin-left: 25px;">{{ productsLabel }}</span>
        </router-link>
        <router-link to="/announcements-simplified" :label="announcementsLabel" v-if="displayAnnouncements">
          <icon name="bullhorn" size="14" :style="{fill: ecosystemColor}"/>
          <span style="margin-left: 21px;">{{ announcementsLabel }}</span>
        </router-link>
        <router-link to="/events-simplified" :label="eventsLabel" v-if="displayEvents">
          <icon name="calendar-plus" size="14" :style="{fill: ecosystemColor}"/>
          <span style="margin-left: 21px;">{{ eventsLabel }}</span>
        </router-link>
        <router-link v-if="displayChallenges" to="/challenges">
          <icon name="comment-outline" size="14" :style="{fill: ecosystemColor}"/>
          <span style="margin-left: 22px;">{{ challengesLabel }}</span>
        </router-link>
        <router-link to="/intelligence-simplified" label="Intelligence" v-if="displayExploreContent">
          <icon name="news-paper-solid" size="14" :style="{fill: ecosystemColor}"/>
          <span style="margin-left: 22px;">Intelligence</span>
        </router-link>
        <router-link to="/dashboards/knowledge-base" v-if="displayKnowledgeBase">
          <icon name="layer-group-solid" size="14" :style="{fill: ecosystemColor}"/>
          <span style="margin-left: 22px;">Knowledge Base</span>
        </router-link>
        <router-link to="/profile/subscription#invoices" v-if="isOwner">
          <icon name="receipt" size="14" :style="{fill: ecosystemColor}"/>
          <span style="margin-left: 22px;">Invoices</span>
        </router-link>
        <template v-if="false && this.$store.getters.isAriadne">
          <a href="https://ellieconnect.com/bawear-score-tool" target="blank" class="mobile-navigation-drawer__bottom"
             style="border-top: 2px solid lightgrey;">
            <icon name="comment" size="14" :style="{fill: ecosystemColor}"/>
            <span style="margin-left: 22px;">BAwear Score</span>
          </a>
        </template>

        <a href="#" @click.prevent="openAddActorModal" v-if="isOwner" class="mobile-navigation-drawer__bottom">
          <icon name="plus-big" size="14" :style="{fill: ecosystemColor}"/>
          <span style="margin-top: auto; margin-left: 22px;">Add new actor</span>
        </a>
        <router-link to="/profile/conversations" v-if="isLoggedIn"
                     :class="isOwner ? '' : 'mobile-navigation-drawer__bottom'"
                     style="border-top: 2px solid lightgrey;">
          <icon name="comment-filled" size="14" :style="{fill: ecosystemColor}"/>
          <span style="margin-left: 22px;">My conversations</span>
        </router-link>
        <router-link to="/notifications" v-if="isLoggedIn">
          <icon name="bell-solid" size="14" :style="{fill: ecosystemColor}"/>
          <span style="margin-left: 22px;">My Notifications</span>
        </router-link>
        <a :href="faqUrl" target="_blank">
          <icon name="information-outline-no-fill" size="14" :style="{fill: ecosystemColor}"/>
          <span style="margin-left: 22px;">FaQ</span>
        </a>
      </li>
    </ul>
  </Drawer>
</template>

<script>
import Drawer from '../Drawer/Drawer.vue'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
import MODAL_IDS from '../../constants/modal-ids'
import TranslationsMixin from '../../util/TranslationsMixin.js'
import { DEFAULT_URLS } from '../../constants/default-urls'
import NavigationMixin from '../../util/NavigationMixin.js'

export default {
  name: 'MobileNavigationDrawer',
  props: {
    isOpen: Boolean,
  },
  computed: {
    ecosystemColor() {
      return this.$store.state.config.primaryColor
    },
    isOwner() {
      return this.$store.getters.isOwner
    },
    isExplorationOnly() {
      return this.$store.getters.isExplorationOnly
    },
    hasAccessToCommunities() {
      return this.$store.getters.hasAccessToCommunities
    },
    canViewSpottingAreas() {
      return this.$store.getters.hasAccessToNewSpottingAreas
    },
    canShowUserBilling() {
      return this.canShowEcosystemPackages && this.$store.getters.isLoggedIn && this.$store.getters.isActor
    },
    canShowEcosystemPackages() {
      return (this.$store.getters.hasStripeKeys || this.$store.getters.isDeveloper) &&
          this.$store.getters.hasAccessToEcosystemMemberPackages
    },
    conversations() {
      return this.$store.state.conversations.listData.data
    },
    config() {
      return this.$store.state.config
    },
    displayExploreContent() {
      const hasAccess = (this.$store.getters.hasAccessToExploration || this.$store.getters.hasAccessToMonitoring)

      return this.$store.getters.isLoggedIn && hasAccess && this.config.viewDashboard && this.config.viewDashboard.includes('explore-content')
    },
    displayAnnouncements() {
      return this.$store.getters.canUsePublicAnnouncements
    },
    displayEvents() {
      return this.displayAnnouncements
    },
    viewActorTypes() {
      return this.$store.getters.viewActorTypes
    },
    displayChallenges() {
      return this.$store.state.config.challengesAreEnabled && this.shouldSeeChallenges
    },
    shouldSeeChallenges() {
      if (this.$store.getters.isMember) {
        return true
      }

      return this.$store.getters.canCreateChallenge ||
          this.$store.getters.viewableChallengeStatusses.length > 0 ||
          this.$store.getters.accessibleChallengeStatusses.length > 0
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    faqUrl() {
      if (this.isOwner) {
        return DEFAULT_URLS.FAQ
      }
      if (this.config.faqUrl) {
        return 'https://' + this.config.faqUrl
      } else {
        return DEFAULT_URLS.FAQ
      }
    },
  },
  methods: {
    openAddActorModal() {
      this.$emit('close')
      const isMember = this.$store.getters.isMember
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, { afterCreate: isMember ? 'addMore' : 'goToActor' })
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)
    },
  },
  mixins: [TranslationsMixin, NavigationMixin],
  components: {
    Drawer,
  },
}
</script>

<style lang="scss">
@import "../../../scss/variables";
@import "../../../scss/mixins/icon-color";

.mobile-navigation-drawer__menu-items,
.mobile-navigation-drawer__menu-item {
  height: 100%;
}

.mobile-navigation-drawer__menu-item {
  display: flex;
  flex-direction: column;

  a {
    display: flex;
    align-items: center;
    padding: 1rem 2rem;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 0.5px;
    text-decoration: none;
    color: var(--primary);

    &.mobile-navigation-drawer__bottom {
      margin-top: auto;
    }

    .svg-icon {
      margin-right: 1rem;
    }
  }
}

</style>
