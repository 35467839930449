import { createJSONRequest, createResource } from './create-request'

export const fetchTranslations = () => createJSONRequest({
  method: 'GET',
  url: '/onboarding'
})

export const Translations = createResource('/api/settings/onboarding/translations')

export const updatePersonalisation = (data) => createJSONRequest({
  method: 'POST',
  url: '/api/settings/onboarding/personalisation',
  data: data
})

export const fetchPersonalisation = () => createJSONRequest({
  method: 'GET',
  url: '/api/settings/onboarding/personalisation'
})

export const markPortfolioFavourite = (portfolio) => createJSONRequest({
  method: 'POST',
  url: `/portfolios/${portfolio.id}/favourite`
})

export const markPortfolioUnFavourite = (portfolio) => createJSONRequest({
  method: 'POST',
  url: `/portfolios/${portfolio.id}/unfavourite`
})

export const markSpottingAreaFavourite = (spottingArea) => createJSONRequest({
  method: 'POST',
  url: `/spotting-areas/${spottingArea.id}/favourite`
})

export const markSpottingAreaUnFavourite = (spottingArea) => createJSONRequest({
  method: 'POST',
  url: `/spotting-areas/${spottingArea.id}/unfavourite`
})
