<template>
  <th class="th-sortable"
    :class="{ up: order == by, down: order == '-' + by }"
    @click="$parent.toggleOrder(by)"
  >
    <slot></slot>
    <icon :name="order == by ? 'sort-up' : order == '-' + by ? 'sort-down' : 'sort'" />
  </th>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
  props: ['by'],
  computed: {
    order () {
      if (typeof this.$parent.order == 'undefined') {
        return null
      }

      return this.$parent.order
    }
  }
})
</script>
