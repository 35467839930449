<template>
  <a :href="href" :target="target" class="unstyled-link">
    <slot></slot>
  </a>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'UnstyledLink',
    props: {
      href: String,
      target: String,
    }
  })
</script>

<style lang="scss">
  .unstyled-link {
    color: currentColor;
    text-decoration: none;
  }
</style>
