<template>
  <div class="page-change-password" :class="{ 'page-authentication': !closeable }">
    <change-password-modal :is-closeable="closeable" @close="$router.push('/')"></change-password-modal>
  </div>
</template>

<script>
import ChangePasswordModal from '../components/Modals/ChangePasswordModal.vue'

export default {
  computed: {
    closeable () {
      return this.$store.getters.hasAccessToPublisher && this.$store.state.config.allow_anonymous
    }
  },
  components: {
    ChangePasswordModal
  }
}
</script>
