import { VueRenderer } from '@tiptap/vue-3'
import tippy from 'tippy.js'
import ActorMentionList from '../components/TextArea/ActorMentionList.vue'
import { getSearchResultsForQueryType } from '../api/general-search.js'
import { mapNewGlobalSearchResultForActorMentions } from '@/util/helpers.js'

export default {
  items: async ({ query }) => {
    if (query.length < 2) {
      return { query, data: [], error: 'At least 2 characters are required' }
    }

    let results = []
    await getSearchResultsForQueryType({ query, type: 'actors' })
      .then(result => {
        results = mapNewGlobalSearchResultForActorMentions([...result.actors, ...result.products])
      })
      .catch(error => {
        console.log(error)
      })

    // Only display the first 5 results
    const slicedResult = (results || []).slice(0, 5)

    return {
      query,
      data: slicedResult,
    }
  },
  render: () => {
    let component
    let popup

    return {
      onStart: props => {
        component = new VueRenderer(ActorMentionList, {
          props,
          editor: props.editor,
        })

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        })
      },

      onUpdate (props) {
        component.updateProps(props)

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        })
      },

      onKeyDown (props) {
        if (props.event.key === 'Escape') {
          popup[0].hide()

          return true
        }

        return component.ref?.onKeyDown(props)
      },

      onExit () {
        popup[0].destroy()
        component.destroy()
      },
    }
  },
  command: ({ editor, range, props }) => {
    range.to += 1

    editor
      .chain()
      .focus()
      .insertContentAt(range, `<a href="${window.location.protocol}//${window.location.hostname}/actors/${props.id}">${props.label}</a> `)
      .run()
  },
}
