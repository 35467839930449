<template>
  <!-- NOTE: this card should be deprecated, instead we should use the knowledge-base-item/announcement-or-resource component -->
  <div class="instant-monitoring-card">
    <div class="header-image"
         :style="{backgroundImage: `url(${this.articleImage})`}">
      <div class="header-image__actions-container">
        <div @click="markArticleRelevant">✓</div>
      </div>
    </div>
    <div class="card-content">
      <a class="card-title" :href="articleUrl" target="_blank" @click="trackHeapEvent('instantMonitoring.viewArticle')">
        {{ article.title }}
      </a>
      <div class="tags">
        <template v-if="article.tags">
          <tag v-for="(tag, index) in article.tags.slice(0, 5)" :key="'tag' + index"
               :label="tag.label"/>
        </template>
      </div>
      <div class="card-subtitle">
        {{ article.body }}
      </div>
    </div>
    <div class="card-footer">
      <a class="article-source" :href="articleUrl" v-if="articleUrl">
        - {{ getUriDomain(articleUrl) }}
      </a>
      <div class="article-created-date" v-if="article.date">
        {{ toDateString(article.date * 1000) }}
      </div>
    </div>
  </div>
</template>

<script>
  import Tag from '../../Tag/Tag.vue'
  import { toDateString } from '../../../util/date.js'
  import { getUriDomain } from '../../../util/helpers.js'
  import { trackHeapEvent } from '../../../util/analytics.js'

  export default {
    props: {
      article: Object,
    },
    data () {
      return {}
    },
    computed: {
      articleImage () {
        return this.article.image || '/images/ds-fallback.png'
      },
      articleUrl () {
        if (!this.article || !this.article.meta || !this.article.meta.length) {
          return ''
        }
        const result = this.article.meta.find(meta => meta.key === 'link')
        return result ? result.value : ''
      },
    },
    methods: {
      trackHeapEvent,
      getUriDomain,
      toDateString,
      markArticleRelevant () {
        this.$emit('mark-relevant', { article: this.article })
      },
    },
    mounted () {
    },
    components: {
      Tag,
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../../scss/variables";

  .instant-monitoring-card {
    width: 200px;
    margin-bottom: 20px;
    border: 1px solid $color-borders;

    .header-image {
      width: 100%;
      height: 180px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;

      .header-image__actions-container {
        width: 100%;
        padding: 5px;
        display: flex;

        div {
          margin-left: 5px;
          background: white;
          border: 1px solid $color-borders;
          width: 18px;
          height: 18px;
          cursor: pointer;

          &:first-child {
            color: $color-primary;
            margin-left: auto;
            padding-left: 2px;
          }

          //&:last-child {
          //  color: $color-error-light;
          //  font-size: 22px;
          //  line-height: 15px;
          //  padding-left: 2px;
          //}
        }
      }
    }

    .card-content {
      padding: 5px;

      .card-title {
        line-height: 20px;
        height: 2*20px;
        display: -webkit-box;
        width: 100%;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        text-decoration: none;
      }

      .card-subtitle {
        display: -webkit-box;
        width: 100%;
        height: 8*18px;
        -webkit-line-clamp: 8;
        line-height: 18px;
        font-weight: 300;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        color: $color-text-grey-light;
        font-size: 10px;
      }

      .tags {
        overflow: hidden;
        height: 25px;

        .tag {
          font-size: 0.6rem;
          padding: 0.2rem;
          line-height: 8px;
          margin-right: 0.2rem;
        }
      }
    }

    .card-footer {
      display: flex;

      .article-source {
        padding: 5px;
        text-decoration: none;
        font-size: 12px;
        max-width: 130px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-transform: uppercase;
        white-space: nowrap;
      }

      .article-created-date {
        margin-left: auto;
        color: $color-text-grey-light;
        padding: 5px;
        font-size: 10px;
        line-height: 14px;
        text-transform: uppercase;
      }
    }
  }
</style>
