import {
  fetchEventsCal,
  fetchPublicNotification,
  fetchPublicNotifications,
  fetchPublicNotificationsCount,
  fetchPublicNotificationsFacets,
} from '../../api/notifications.js'
import throttle from '../../util/throttle.js'
import { assertArray } from '../../util/helpers.js'

const state = {
  detail: {
    data: {},
    loading: false,
    error: null,
  },
  featuredAnnouncements: [],
  filteredListData: {
    fetchLimit: 5,
    endOfFeed: false,
    featured: [],
    commented: [],
    liked: [],
    is_event: [],
    upcoming_events: [],
    communities: [],
    ecosystem: [],
    pinned: [],
    error: null,
    loading: false,
  },
  listData: {
    selectedFilter: null,
    query: null,
    fetchLimit: 10,
    data: [],
    tags: [],
    announcementCategories: [],
    eventCategories: [],
    community: null,
    error: null,
    endOfFeed: false,
    loading: false,
  },
  eventListData: {
    data: [],
    error: null,
    loading: false,
  },
  notificationsCount: null,
  newNotificationsCount: null,
  scrollPosition: null,
}

export const validAnnouncementFilters = ['pinned', 'liked', 'ecosystem', 'commented', 'featured', 'is_event', 'upcoming_events', 'communities']

export const ACTION_TYPES = {
  FETCH_NOTIFICATIONS_COUNT: 'NOTIFICATIONS/FETCH_NOTIFICATIONS_COUNT',
  FETCH_NEW_NOTIFICATIONS_COUNT: 'NOTIFICATIONS/FETCH_NEW_NOTIFICATIONS_COUNT',
  FETCH_NOTIFICATIONS_LIST_NOW: 'NOTIFICATIONS/FETCH_NOTIFICATIONS_LIST_NOW',
  FETCH_NOTIFICATIONS_LIST: 'NOTIFICATIONS/FETCH_NOTIFICATIONS_LIST',
  FETCH_NOTIFICATIONS_EVENTS_CALENDAR_NOW: 'NOTIFICATIONS/FETCH_NOTIFICATIONS_EVENTS_CALENDAR_NOW',
  FETCH_NOTIFICATIONS_EVENTS_CALENDAR: 'NOTIFICATIONS/FETCH_NOTIFICATIONS_EVENTS_CALENDAR',
  FETCH_NOTIFICATION_DETAIL: 'NOTIFICATIONS/FETCH_NOTIFICATION_DETAIL',
  FETCH_UPDATED_NOTIFICATION: 'NOTIFICATIONS/FETCH_UPDATED_NOTIFICATION',
  FETCH_DEFAULT_FILTERED_NOTIFICATIONS_LIST: 'NOTIFICATIONS/FETCH_DEFAULT_FILTERED_NOTIFICATIONS_LIST',
  FETCH_FEATURED_ANNOUNCEMENTS: 'NOTIFICATIONS/FETCH_FEATURED_ANNOUNCEMENTS',
}

export const MUTATION_TYPES = {
  FETCH_NOTIFICATIONS_COUNT_RESULT: 'NOTIFICATIONS/FETCH_NOTIFICATIONS_COUNT_RESULT',
  FETCH_NEW_NOTIFICATIONS_COUNT_RESULT: 'NOTIFICATIONS/FETCH_NEW_NOTIFICATIONS_COUNT_RESULT',
  FETCH_NOTIFICATIONS_LIST_PENDING: 'NOTIFICATIONS/FETCH_NOTIFICATIONS_LIST_PENDING',
  FETCH_NOTIFICATIONS_LIST_SUCCESS: 'NOTIFICATIONS/FETCH_NOTIFICATIONS_LIST_SUCCESS',
  FETCH_NOTIFICATIONS_LIST_FAILURE: 'NOTIFICATIONS/FETCH_NOTIFICATIONS_LIST_FAILURE',
  FETCH_NOTIFICATION_DETAIL_PENDING: 'NOTIFICATIONS/FETCH_NOTIFICATION_DETAIL_PENDING',
  FETCH_NOTIFICATION_DETAIL_SUCCESS: 'NOTIFICATIONS/FETCH_NOTIFICATION_DETAIL_SUCCESS',
  FETCH_NOTIFICATION_DETAIL_FAILURE: 'NOTIFICATIONS/FETCH_NOTIFICATION_DETAIL_FAILURE',
  FETCH_NOTIFICATIONS_EVENTS_CALENDAR_PENDING: 'NOTIFICATIONS/FETCH_NOTIFICATIONS_EVENTS_CALENDAR_PENDING',
  FETCH_NOTIFICATIONS_EVENTS_CALENDAR_SUCCESS: 'NOTIFICATIONS/FETCH_NOTIFICATIONS_EVENTS_CALENDAR_SUCCESS',
  FETCH_NOTIFICATIONS_EVENTS_CALENDAR_FAILURE: 'NOTIFICATIONS/FETCH_NOTIFICATIONS_EVENTS_CALENDAR_FAILURE',
  FETCH_DEFAULT_FILTERED_NOTIFICATIONS_LIST_PENDING: 'NOTIFICATIONS/FETCH_DEFAULT_FILTERED_NOTIFICATIONS_LIST_PENDING',
  FETCH_DEFAULT_FILTERED_NOTIFICATIONS_LIST_SUCCESS: 'NOTIFICATIONS/FETCH_DEFAULT_FILTERED_NOTIFICATIONS_LIST_SUCCESS',
  FETCH_DEFAULT_FILTERED_NOTIFICATIONS_LIST_FAILURE: 'NOTIFICATIONS/FETCH_DEFAULT_FILTERED_NOTIFICATIONS_LIST_FAILURE',
  UPDATE_QUERY_FILTER: 'NOTIFICATIONS/UPDATE_QUERY_FILTER',
  TOGGLE_ACTIVE_DEFAULT_FILTER: 'NOTIFICATIONS/TOGGLE_ACTIVE_DEFAULT_FILTER',
  RESET_ACTIVE_DEFAULT_FILTER: 'NOTIFICATIONS/RESET_ACTIVE_DEFAULT_FILTER',
  TOGGLE_ACTIVE_TAGS: 'NOTIFICATIONS/TOGGLE_ACTIVE_TAGS',
  RESET_ACTIVE_TAGS: 'NOTIFICATIONS/RESET_ACTIVE_TAGS',
  RESET_ACTIVE_ANNOUNCEMENT_CATEGORIES_FILTER: 'NOTIFICATIONS/RESET_ACTIVE_ANNOUNCEMENT_CATEGORIES_FILTER',
  RESET_COMMUNITY_FILTER: 'NOTIFICATIONS/RESET_COMMUNITY_FILTER',
  FETCH_FEATURED_ANNOUNCEMENTS_PENDING: 'NOTIFICATIONS/FETCH_FEATURED_ANNOUNCEMENTS_PENDING',
  FETCH_FEATURED_ANNOUNCEMENTS_SUCCESS: 'NOTIFICATIONS/FETCH_FEATURED_ANNOUNCEMENTS_SUCCESS',
  FETCH_FEATURED_ANNOUNCEMENTS_FAILURE: 'NOTIFICATIONS/FETCH_FEATURED_ANNOUNCEMENTS_FAILURE',
  CACHE_CURRENT_SCROLL: 'NOTIFICATIONS/CACHE_CURRENT_SCROLL',
  FETCH_UPDATED_NOTIFICATION_SUCCESS: 'NOTIFICATION/FETCH_UPDATED_NOTIFICATION_SUCCESS',
  FETCH_UPDATED_NOTIFICATION_FAILURE: 'NOTIFICATION/FETCH_UPDATED_NOTIFICATION_FAILURE',
  TOGGLE_ACTIVE_COMMUNITY_FILTER: 'NOTIFICATIONS/TOGGLE_ACTIVE_COMMUNITY_FILTER',
  TOGGLE_ACTIVE_ANNOUNCEMENT_CATEGORIES_FILTER: 'NOTIFICATIONS/TOGGLE_ACTIVE_ANNOUNCEMENT_CATEGORIES_FILTER',
  TOGGLE_ACTIVE_EVENT_CATEGORIES_FILTER: 'NOTIFICATIONS/TOGGLE_ACTIVE_EVENT_CATEGORIES_FILTER',
  RESET_NOTIFICATIONS_LIST_DATA: 'NOTIFICATIONS/RESET_NOTIFICATIONS_LIST_DATA',
}

export const actions = {
  [ACTION_TYPES.FETCH_NOTIFICATIONS_COUNT] ({ commit }) {
    return fetchPublicNotificationsCount().then((response) => {
      commit(MUTATION_TYPES.FETCH_NOTIFICATIONS_COUNT_RESULT, response)
    })
  },
  [ACTION_TYPES.FETCH_NEW_NOTIFICATIONS_COUNT] ({ commit }) {
    return fetchPublicNotificationsCount({ type: 'new' }).then((response) => {
      commit(MUTATION_TYPES.FETCH_NEW_NOTIFICATIONS_COUNT_RESULT, response)
    })
  },
  [ACTION_TYPES.FETCH_FEATURED_ANNOUNCEMENTS] ({ commit }, { params }) {
    commit(MUTATION_TYPES.FETCH_FEATURED_ANNOUNCEMENTS_PENDING)

    return fetchPublicNotifications(params.limit, params.offset, { ...params.filters, featured: true })
      .then(response => {
        const result = {
          response,
          appendResults: params && params.offset,
        }

        commit(MUTATION_TYPES.FETCH_FEATURED_ANNOUNCEMENTS_SUCCESS, result)
      })
      .catch(errors => {
        commit(MUTATION_TYPES.FETCH_FEATURED_ANNOUNCEMENTS_FAILURE, errors)
      })
  },
  [ACTION_TYPES.FETCH_DEFAULT_FILTERED_NOTIFICATIONS_LIST] ({ commit }, { params }) {
    commit(MUTATION_TYPES.FETCH_DEFAULT_FILTERED_NOTIFICATIONS_LIST_PENDING)

    fetchPublicNotificationsFacets(state.filteredListData.fetchLimit, params.offset || 0, params.filters)
      .then(result => {
        commit(MUTATION_TYPES.FETCH_DEFAULT_FILTERED_NOTIFICATIONS_LIST_SUCCESS, {
          result,
          appendResults: params.offset !== 0,
        })
      })
      .catch(errors => {
        commit(MUTATION_TYPES.FETCH_NOTIFICATIONS_LIST_FAILURE, errors)
      })
  },
  [ACTION_TYPES.FETCH_UPDATED_NOTIFICATION] ({ commit }, params) {
    fetchPublicNotification(params.id)
      .then(notification => {
        commit(MUTATION_TYPES.FETCH_UPDATED_NOTIFICATION_SUCCESS, notification)
      })
      .catch(errors => {
        commit(MUTATION_TYPES.FETCH_UPDATED_NOTIFICATION_FAILURE, 'An error occurred while updating the announcement.')
      })
  },
  [ACTION_TYPES.FETCH_NOTIFICATIONS_LIST]: throttle(({ dispatch }, params) => {
    dispatch(ACTION_TYPES.FETCH_NOTIFICATIONS_LIST_NOW, params)
  }),
  [ACTION_TYPES.FETCH_NOTIFICATIONS_LIST_NOW] ({ commit }, params) {
    commit(MUTATION_TYPES.FETCH_NOTIFICATIONS_LIST_PENDING, { appendResults: params && params.offset })

    return fetchPublicNotifications(params.limit, params.offset, params.filters)
      .then(response => {
        const result = {
          response,
          appendResults: params && params.offset,
        }

        commit(MUTATION_TYPES.FETCH_NOTIFICATIONS_LIST_SUCCESS, result)
      })
      .catch(errors => {
        commit(MUTATION_TYPES.FETCH_NOTIFICATIONS_LIST_FAILURE, errors)
      })
  },
  [ACTION_TYPES.FETCH_NOTIFICATIONS_EVENTS_CALENDAR]: throttle(({ dispatch }, params) => {
    dispatch(ACTION_TYPES.FETCH_NOTIFICATIONS_EVENTS_CALENDAR_NOW, params)
  }),
  [ACTION_TYPES.FETCH_NOTIFICATIONS_EVENTS_CALENDAR_NOW] ({ commit }, params) {
    commit(MUTATION_TYPES.FETCH_NOTIFICATIONS_EVENTS_CALENDAR_PENDING)

    return fetchEventsCal(params.limit, params.offset, params.filters)
      .then(response => {
        const result = {
          response,
          appendResults: params && params.offset,
        }

        commit(MUTATION_TYPES.FETCH_NOTIFICATIONS_EVENTS_CALENDAR_SUCCESS, result)
      })
      .catch(errors => {
        commit(MUTATION_TYPES.FETCH_NOTIFICATIONS_EVENTS_CALENDAR_FAILURE, errors)
      })
  },
  [ACTION_TYPES.FETCH_NOTIFICATION_DETAIL] ({ commit }, id) {
    const cachedNotification = reuseFromListData(id)
    if (cachedNotification) {
      commit(MUTATION_TYPES.FETCH_NOTIFICATION_DETAIL_PENDING, id)
      commit(MUTATION_TYPES.FETCH_NOTIFICATION_DETAIL_SUCCESS, cachedNotification)
    }

    if (state.detail.loading === id) {
      return console.log('already loading detail', id)
    }

    if (state.detail.errorId === id) {
      return console.log('already tried loading detail', id)
    }

    if (id === true) {
      id = state.detail.data.id
    }

    commit(MUTATION_TYPES.FETCH_NOTIFICATION_DETAIL_PENDING, id)

    return fetchPublicNotification(id)
      .then(notification => {
        if (notification.length === 0) {
          commit(MUTATION_TYPES.FETCH_NOTIFICATION_DETAIL_FAILURE, 'An error occurred while fetching the announcement.')

          return
        }

        commit(MUTATION_TYPES.FETCH_NOTIFICATION_DETAIL_SUCCESS, notification)
      })
      .catch(errors => {
        commit(MUTATION_TYPES.FETCH_NOTIFICATION_DETAIL_FAILURE, 'An error occurred while fetching the announcement.')
      })
  },
}

function reuseFromListData (id) {
  return [...state.listData.data].find(a => a.id === id)
}

function isStillOnRequestedPage (id) {
  return state.detail.loading === id
}

export const mutations = {
  // This is the total of notifications that are new to the user
  [MUTATION_TYPES.FETCH_NOTIFICATIONS_COUNT_RESULT] (state, data) {
    state.notificationsCount = data.count
  },
  [MUTATION_TYPES.RESET_NOTIFICATIONS_LIST_DATA] (state) {
    state.filteredListData = {
      fetchLimit: 5,
      endOfFeed: false,
      featured: [],
      commented: [],
      liked: [],
      is_event: [],
      upcoming_events: [],
      communities: [],
      ecosystem: [],
      pinned: [],
      error: null,
      loading: false,
    }
    state.listData = {
      selectedFilter: null,
      query: null,
      fetchLimit: 10,
      data: [],
      tags: [],
      announcementCategories: [],
      eventCategories: [],
      community: null,
      error: null,
      endOfFeed: false,
      loading: false,
    }
  },
  [MUTATION_TYPES.FETCH_NEW_NOTIFICATIONS_COUNT_RESULT] (state, data) {
    state.newNotificationsCount = data.count
  },
  [MUTATION_TYPES.FETCH_NOTIFICATION_DETAIL_SUCCESS] (state, notification) {
    if (isStillOnRequestedPage(notification.id)) {
      state.detail.data = notification
    }

    if (isStillOnRequestedPage(notification.slug)) {
      state.detail.data = notification
    }

    state.detail.error = null
    state.detail.loading = false
  },
  [MUTATION_TYPES.FETCH_NOTIFICATION_DETAIL_FAILURE] (state, error) {
    state.detail.data = {}
    state.detail.errorId = state.detail.loading
    state.detail.error = error
    state.detail.loading = false
  },
  [MUTATION_TYPES.FETCH_NOTIFICATION_DETAIL_PENDING] (state, id) {
    state.detail.loading = id
  },
  [MUTATION_TYPES.FETCH_FEATURED_ANNOUNCEMENTS_PENDING] (state) {
    state.loading = true
  },
  [MUTATION_TYPES.FETCH_NOTIFICATIONS_LIST_PENDING] (state, { appendResults }) {
    state.listData.loading = true

    if (!appendResults) {
      state.listData.data = []
    }
  },
  [MUTATION_TYPES.FETCH_DEFAULT_FILTERED_NOTIFICATIONS_LIST_PENDING] (state) {
    state.filteredListData.loading = true
  },
  [MUTATION_TYPES.FETCH_DEFAULT_FILTERED_NOTIFICATIONS_LIST_SUCCESS] (state, { result, appendResults }) {
    const facetKeys = Object.keys(result)
    for (let i = 0; i < facetKeys.length; i++) {
      const facetName = facetKeys[i]
      const data = assertArray(result[facetName])
      if (state.filteredListData[facetName] && data) {
        if (appendResults) {
          state.filteredListData[facetName] = [...state.filteredListData[facetName], ...data]
          state.filteredListData.endOfFeed = data.length === 0
        } else {
          state.filteredListData[facetName] = data
        }
      }
    }

    state.filteredListData.loading = false
  },
  [MUTATION_TYPES.FETCH_DEFAULT_FILTERED_NOTIFICATIONS_LIST_FAILURE] (state, error) {
    state.filteredListData.error = error
    state.filteredListData.loading = false
  },
  [MUTATION_TYPES.TOGGLE_ACTIVE_DEFAULT_FILTER] (state, type, value) {
    if (!validAnnouncementFilters.includes(type)) {
      return
    }

    if (state.listData.selectedFilter === type) {
      state.listData.selectedFilter = null
    } else {
      state.listData.selectedFilter = type
    }

    if (state.listData.selectedFilter === 'community' && value) {
      state.filteredListData.selectedCommunity = value
    }

    state.listData.error = null
    state.listData.loading = false
    state.listData.endOfFeed = false
  },
  [MUTATION_TYPES.RESET_ACTIVE_DEFAULT_FILTER] (state) {
    state.listData.selectedFilter = null
  },
  [MUTATION_TYPES.CACHE_CURRENT_SCROLL] (state, scrollPosition) {
    state.scrollPosition = scrollPosition
  },
  [MUTATION_TYPES.TOGGLE_ACTIVE_TAGS] (state, tag) {
    const exists = state.listData.tags.filter(v => v.value === tag.value)

    if (exists.length === 0) {
      state.listData.tags.push(tag)
    } else {
      state.listData.tags = state.listData.tags.filter(v => v.value !== tag.value)
    }

    state.listData.error = null
    state.listData.endOfFeed = false
    state.listData.loading = false
  },
  [MUTATION_TYPES.RESET_ACTIVE_ANNOUNCEMENT_CATEGORIES_FILTER] (state) {
    state.listData.announcementCategories = []
  },
  [MUTATION_TYPES.RESET_ACTIVE_TAGS] (state) {
    state.listData.tags = []
  },
  [MUTATION_TYPES.RESET_COMMUNITY_FILTER] (state) {
    state.listData.community = null
  },
  [MUTATION_TYPES.FETCH_UPDATED_NOTIFICATION_SUCCESS] (state, result) {
    state.listData.loading = true

    state.listData.data = state.listData.data.map(notification => {
      if (notification.id !== result.id) {
        return notification
      } else {
        return result
      }
    })

    for (const filter of validAnnouncementFilters) {
      state.filteredListData[filter] = state.filteredListData[filter].map(notification => {
        if (notification.id !== result.id) {
          return notification
        } else {
          return result
        }
      })
    }

    state.listData.loading = false
  },
  [MUTATION_TYPES.FETCH_UPDATED_NOTIFICATION_FAILURE] (state, error) {
    state.listData.error = error
    state.listData.endOfFeed = true
    state.listData.loading = false
  },
  [MUTATION_TYPES.TOGGLE_ACTIVE_COMMUNITY_FILTER] (state, communityId) {
    if (state.listData.community === communityId) {
      state.listData.community = null
    } else {
      state.listData.community = communityId
    }

    state.listData.error = null
    state.listData.endOfFeed = false
    state.listData.loading = false
  },
  [MUTATION_TYPES.TOGGLE_ACTIVE_ANNOUNCEMENT_CATEGORIES_FILTER] (state, { announcementCategory, overwriteExists }) {
    const exists = state.listData.announcementCategories.filter(v => v.id === announcementCategory.id)

    if (exists.length === 0 || overwriteExists) {
      state.listData.announcementCategories.push(announcementCategory)
    } else {
      state.listData.announcementCategories = state.listData.announcementCategories.filter(v => v.id !== announcementCategory.id)
    }

    state.listData.error = null
    state.listData.endOfFeed = false
    state.listData.loading = false
  },
  [MUTATION_TYPES.TOGGLE_ACTIVE_EVENT_CATEGORIES_FILTER] (state, eventCategory) {
    const exists = state.listData.eventCategories.filter(v => v.id === eventCategory.id)

    if (exists.length === 0) {
      state.listData.eventCategories.push(eventCategory)
    } else {
      state.listData.eventCategories = state.listData.eventCategories.filter(v => v.id !== eventCategory.id)
    }

    state.listData.error = null
    state.listData.endOfFeed = false
    state.listData.loading = false
  },
  [MUTATION_TYPES.FETCH_COMMUNITIES_LIST_PENDING] (state) {
    state.filteredListData.loading = true
  },
  [MUTATION_TYPES.FETCH_COMMUNITIES_LIST_SUCCESS] (state, result) {
    state.filteredListData.communities = result
    state.filteredListData.loading = false
  },
  [MUTATION_TYPES.FETCH_COMMUNITIES_LIST_FAILURE] (state, error) {
    state.filteredListData.communities = []
    state.filteredListData.error = error
    state.filteredListData.loading = false
  },
  [MUTATION_TYPES.FETCH_NOTIFICATIONS_LIST_SUCCESS] (state, result) {
    const data = assertArray(result.response)
    const appendToExistingList = result.appendResults

    state.listData.endOfFeed = data.length === 0
    if (appendToExistingList) {
      state.listData.data = [...state.listData.data, ...data]
    } else {
      state.listData.data = data
    }

    state.listData.loading = false
  },
  [MUTATION_TYPES.FETCH_NOTIFICATIONS_LIST_FAILURE] (state, error) {
    state.listData.error = error
    state.listData.endOfFeed = true
    state.listData.loading = false
  },
  [MUTATION_TYPES.FETCH_FEATURED_ANNOUNCEMENTS_SUCCESS] (state, result) {
    const data = assertArray(result.response)
    const appendToExistingList = result.appendResults

    if (appendToExistingList) {
      state.featuredAnnouncements = data
    } else {
      state.featuredAnnouncements = data
    }

    state.loading = false
  },
  [MUTATION_TYPES.FETCH_FEATURED_ANNOUNCEMENTS_FAILURE] (state, error) {
    state.featuredAnnouncements = []
    state.loading = false
  },
  [MUTATION_TYPES.FETCH_NOTIFICATIONS_EVENTS_CALENDAR_PENDING] (state) {
    state.eventListData.loading = true
  },
  [MUTATION_TYPES.FETCH_NOTIFICATIONS_EVENTS_CALENDAR_SUCCESS] (state, result) {
    const data = assertArray(result.response)
    const events = []

    if (data.length > 0) {
      data.map(event => {
        if (event.end === null) {
          event.end = event.start
        }

        events.push({
          start: event.start,
          end: event.end,
          title: event.title,
        })
      })
    }

    state.eventListData.data = events
    state.eventListData.loading = false
  },
  [MUTATION_TYPES.FETCH_NOTIFICATIONS_EVENTS_CALENDAR_FAILURE] (state, error) {
    state.eventListData.error = error
    state.eventListData.loading = false
  },
  [MUTATION_TYPES.UPDATE_QUERY_FILTER] (state, query) {
    state.listData.query = query
  },
}

export default {
  state,
  mutations,
  actions,
}
