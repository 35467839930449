import { cancelIfLate, createJSONRequest, createPagedRequest, default as createRequest } from './create-request.js'

import escapeHTML from '../util/escapeHTML.js'
import querify, { convertObjectToQueryArray } from '../util/querify.js'

/**
 * ACTOR CRUD
 */
export const validateActor = cancelIfLate((data) => createJSONRequest({
  url: `/actors/validate`,
  data,
}))

export const createActor = (data) => createJSONRequest({
  url: `/actors`,
  data,
})

export const updateActor = ({ id, data }) => createJSONRequest({
  url: `/actors/${id}`,
  method: 'PUT',
  data,
})

export const updateActorDraft = (id, draft) => createJSONRequest({
  url: `/actors/${id}/draft`,
  method: 'PUT',
  data: {
    draft,
  },
})

export const updateActorPortfolioDraft = (id, draft, curationId) => createJSONRequest({
  url: `/actors/${id}/portfolio-draft`,
  method: 'PUT',
  data: {
    draft,
    curationId,
  },
})

export const updateBulkActor = (data) => createJSONRequest({
  url: `/spreadsheet`,
  method: 'PUT',
  data,
})

export const removeActor = (id, data = []) => createRequest({
  url: `/actors/${id}?${querify(data)}`,
  method: 'DELETE',
  data,
})

export const enrichActor = (id, data) => createJSONRequest({
  url: `/api/actors/${id}/enrich`,
  method: 'POST',
  data,
})

export const actorCount = (filters) => createRequest({
  url: `/api/actors?view=count&${querify(filters)}`,
  method: 'GET',
})

export const facetCount = (actorTypes, filters) => createJSONRequest({
  url: `/api/facet-count?actor_type=${actorTypes}&${querify(filters)}`,
  method: 'GET',
})

// TODO: use the same approach to counting actors as challenges & notifications, and put it under 1 api endpoint instead of 2 for "new" and "all" (cfr. actorCount)
export const newActorCount = (filters) => createRequest({
  url: `/api/actors-count?${querify(filters)}`,
  method: 'GET',
})

export const fetchActor = (id) => createJSONRequest({
  url: `/actors/${id}`,
  method: 'GET',
})

// Fetch actors specifically tailored to return only data we need for connections
export const fetchConnectionActors = (actorIds) => createJSONRequest({
  url: `/api/connected-actors`,
  method: 'POST',
  data: {
    actorIds,
  },
})

export const fetchActorApi = (id, path, query) => createRequest({
  url: '/api/actors' + (id ? '/' + id : '') + (path ? '/' + path : '') + (query ? '?' + querify(query) : ''),
})

export const fetchActors = (filters) => createPagedRequest({
  url: `/api/actors?${querify(filters)}`,
})

export const fetchActorsMap = cancelIfLate((filters) => createRequest({
  url: `/api/actors?view=map&${querify(filters)}`,
}))

export const fetchProductsMap = cancelIfLate((filters) => createRequest({
  url: `/api/actors?view=product-map&${querify(filters)}`,
}))

export const fetchProductsList = cancelIfLate((filters) => createPagedRequest({
  url: `/api/actors?${querify(filters)}`,
}))

export const fetchActorsList = cancelIfLate((filters) => createPagedRequest({
  url: `/api/actors?${querify(filters)}`,
}))

export const fetchActorsSpreadsheet = cancelIfLate((filters) => createPagedRequest({
  url: `/spreadsheet?${querify(filters)}`,
}))

export const fetchActorsFundingList = cancelIfLate((filters) => createPagedRequest({
  url: `/api/actors?view=funding-list&${querify(filters)}`,
}))

export const autocompleteActor = (actorName) => createJSONRequest({
  url: `/actors/autocomplete?query=${actorName}`,
  method: 'GET',
})

/**
 * Agents
 */
export const fetchAgentStatus = (actorId, processSuggestions = true) => createJSONRequest({
  url: `/api/agents/${actorId}?processSuggestions=${processSuggestions}`,
  method: 'GET',
})

export const runAgentForActor = (actorId, action) => createJSONRequest({
  url: `/api/agents/${actorId}`,
  method: 'PUT',
  data: {
    action: action,
  },
})

export const runSimilarityAgentForActor = (actorId, action, similarProperties) => {
  return createJSONRequest({
    url: `/api/agents/${actorId}`,
    method: 'PUT',
    data: {
      action: action,
      similar_properties: convertObjectToQueryArray(similarProperties, 'type', 'value'),
    },
  })
}

export const exportSpreadsheet = (filters) => createRequest({
  url: `/spreadsheet?${querify(filters)}`,
  method: 'GET',
})

export const fetchActorsGraph = cancelIfLate((filters) => createPagedRequest({
  url: `/api/actors?${querify(Object.assign({}, filters, {
    group: 'graph',
    order: '-updated_at',
  }))}`,
}))

/**
 * Claims
 */

export const claimActor = actorId => createJSONRequest({
  url: `/api/actors/${actorId}/claim`,
  data: {
    actorId,
  },
})

export const updateClaim = (actorId, claimId, data) => createJSONRequest({
  url: `/api/actors/${actorId}/claim/${claimId}`,
  method: 'PUT',
  data: data,
})

export const deleteClaim = (data) => createJSONRequest({
  url: `/api/actors/${data.actorId}/claim`,
  method: 'DELETE',
  data,
})

export const fetchContributors = (actorId, filters) => createJSONRequest({
  url: `/contributors/${actorId}?${querify(filters)}`,
  method: 'GET',
})

export const updateContactableContributors = (actorId, contributorIds) => createJSONRequest({
  url: `/contributors/${actorId}`,
  method: 'PUT',
  data: {
    actorId,
    contributorIds,
  },
})

export const inviteContributor = data => createJSONRequest({
  url: `/contributors`,
  data,
})

export const removeContributor = data => createJSONRequest({
  url: `/contributors/${data.actorId}`,
  method: 'DELETE',
  data,
})

export const fetchDuplicateActors = actorId => createJSONRequest({
  url: `/api/actors/${actorId}/reconcile`,
  method: 'GET',
})

export const blackListDuplicate = (actor1Id, actor2Id) => createJSONRequest({
  url: `/api/actors/${actor1Id}/blacklist-duplicate/${actor2Id}`,
  method: 'POST',
})

export const fetchDuplicateActorsForProperties = (actorId, properties) => createJSONRequest({
  url: `/api/actors/${actorId}/duplicates-for?` + querify(properties),
  method: 'GET',
})

export const mergeDuplicateActors = (actorId, duplicateActorId) => createJSONRequest({
  url: `/api/actors/${actorId}/merge/${duplicateActorId}`,
  method: 'POST',
})

export const fetchNameForId = (actorId) => createJSONRequest({
  url: `/api/actors/name?actorId=` + actorId,
  method: 'GET',
})

export const suggestActors = (query) => createJSONRequest({
  url: `/api/actors?name=${query}`,
  method: 'GET',
})

export const fetchSpottingAreaPortfolioActors = (spottingAreaId, query) => createJSONRequest({
  url: `/api/spotting-areas/${spottingAreaId}/actors`,
  method: 'GET',
})

export function suggestionSettings () {
  return {
    url: '/api/actors?name={query}',
    onResponse (data) {
      return {
        results: Object.values(data).map(company => ({
          title: escapeHTML(company.name),
          data: company,
        })),
      }
    },
  }
}

export const actorNameAutoComplete = cancelIfLate((query) => createJSONRequest({
  url: `/api/actors?name=` + query,
  method: 'GET',
}))
