<template>
  <form class="wrapper" @submit="handleSubmit">
    <div class="input-wrapper">
      <icon name="search" size="14" class="search-icon"/>
      <input
          type="text"
          v-model="query"
          ref="input"
          :style="searchInputStyling"
          @update:modelValue="handleInput"
          :placeholder="placeholder"
      >
      <button type="button" class="clear-button" @click="clearQuery">
        <icon name="x" size="14"/>
      </button>
    </div>
    <div class="suggestions" v-if="suggestions.length > 0">
      <div class="suggestions__title"></div>
      <ul class="suggestions__list">
        <li v-for="(suggestion, index) in suggestions"></li>
      </ul>
    </div>
  </form>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SearchBarWithSuggestions',
  data() {
    return {
      query: ''
    }
  },
  props: {
    initialQuery: {
      type: String,
      default: '',
    },
    placeholder: String,
    suggestionsTitle: String,
    suggestions: {
      type: Array,
      default: () => []
    },
    renderSuggestion: Function,
  },
  methods: {
    clearQuery() {
      this.query = ''
      this.$emit('onSubmitQuery', this.query)
    },
    handleSubmit(e) {
      e.preventDefault()
      this.$emit('onSubmitQuery', this.query)
    },
    handleInput(e) {
      this.$emit('onChangeQuery', this.query)
    }
  },
  computed: {
    isCommunityEcosystem() {
      return this.$store.getters.isCommunityEcosystem
    },
    searchInputStyling() {
      if (this.isCommunityEcosystem) {
        return `border: 1px solid #323441;`
      }
    }
  },
  watch: {
    initialQuery() {
      this.query = this.initialQuery
    }
  }
})
</script>

<style scoped lang="scss">
$input-height: 40px;

.wrapper {
  width: 100%;
}

.input-wrapper {
  display: inline-block;
  width: 100%;
  position: relative;
  height: $input-height;

  input {
    padding: 0.5rem;
    padding-left: 2rem;
    height: 100%;
    width: 100%;
    border: 0;
    border-radius: 0.5rem;
  }
}

.search-icon {
  color: #bbb;
  position: absolute;
  top: 50%;
  left: 0.5rem;
  transform: translateY(-50%);
}

.clear-button {
  height: $input-height;
  width: $input-height;
  position: absolute;
  right: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 0.25rem;
  background: transparent;
  border: 0;
}
</style>
