<template>
  <div v-if="Object.keys(usageData).length > 0">
    <div v-for="(value, key) in usageData">
      <template v-if="value.length">
        <h3>{{ prettify(key) }}</h3>
        <table class="table">
          <thead>
            <tr>
              <td>date</td>
              <td>credits used</td>
            </tr>
          </thead>
          <tr v-for="entry in value">
            <td>{{ getDate(entry) }}</td>
            <td>{{ entry.credits_used }}</td>
          </tr>
        </table>
      </template>
    </div>
  </div>
  <div v-else>
    No datascouts services are active.
  </div>
</template>

<script>

import { fetchServiceUsage } from '../../api/config'
import { toFullMonth } from '../../util/date'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      usageData: {}
    }
  },
  methods: {
    fetchServiceUsage,
    toFullMonth,
    prettify (key) {
      switch (key) {
        case 'ner':
          return 'Service: NER';
      }

      return 'Service: ' + key
    },
    getDate (value) {
      return toFullMonth(new Date(value.year, value.month - 1))
    }
  },
  mounted () {
    fetchServiceUsage()
    .then(data => {
      var usageData = {}

      for (var property in data) {
        if (data.hasOwnProperty(property)) {
          if (data[property] && data[property].length) {
            usageData[property] = data[property]
          }
        }
      }

      this.usageData = usageData
    })
    .catch()
  }
})
</script>
