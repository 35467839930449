<template>
  <div class="concept-map__landscape-panel">
    <div v-if="fetchingContent">
      <loading/>
    </div>
    <div v-if="! dictionaryGroup" class="landscape__select-group-to-analyse">
      Select a market analysis on the right to display the landscape.
    </div>
    <div class="chart-container" ref="bubbleChartContainer" style="height: 100%;" v-else>
      <bubble-chart :chartData="chartData" v-if="displayedKeywords.length > 0" @click="$emit('clickDictionary', $event)"/>

      <div class="chart-legend" v-if="displayedKeywords.length > 0">
        <table>
          <tr v-for="item in legendItems">
            <td>
              <div class="color-block" :style="{backgroundColor: item.color}"/>
            </td>
            <td>{{ item.dictionary }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import BubbleChart from '../../Chart/BubbleChart.vue'
  import DictionaryMixin from '../../../util/DictionaryMixin'
  import Loading from './Loading.vue'
  import { defineComponent } from 'vue'

  const KEYWORDS_PER_DICTIONARY = 10

  export default defineComponent({
    mixins: [DictionaryMixin],
    components: {BubbleChart, Loading},
    props: {
      keywords: Array,
      articles: Array,
      dictionaryGroup: String,
      fetchingContent: Boolean,
    },
    computed: {
      availableColors() {
        return Object.values(this.$store.state.config.hexColours)
      },
      allKeywords() {
        const byDictionaryIdAndId = {}

        for (const article of this.articles) {
          for (const dictionaryId of article.similar_dictionaries) {
            if (!this.activeDictionaryIds.includes(dictionaryId)) {
              continue
            }

            byDictionaryIdAndId[dictionaryId] = byDictionaryIdAndId[dictionaryId] || {}

            for (const tag of article.tags) {
              byDictionaryIdAndId[dictionaryId][tag.value] = byDictionaryIdAndId[dictionaryId][tag.value] || {
                ...tag,
                dictionaryId,
                dictionaryName: this.dictionaryNames[dictionaryId],
                color: this.dictionaryColors[dictionaryId],
                occurences: 0,
              }

              byDictionaryIdAndId[dictionaryId][tag.value].occurences++
            }
          }
        }

        let result = []

        for (const dictionaryId in byDictionaryIdAndId) {
          const keywords = Object.values(byDictionaryIdAndId[dictionaryId])
            .sort((a, b) => b.occurences - a.occurences)
            .slice(0, KEYWORDS_PER_DICTIONARY)

          result = result.concat(keywords)
        }

        return result
      },
      activeDictionaryIds() {
        return this.mixinDefaultDictionaries.filter(d => d.dictionary_group === this.dictionaryGroup).map(d => d.id)
      },
      dictionaryNames() {
        return this.mixinDefaultDictionaries.filter(d => d.dictionary_group === this.dictionaryGroup).reduce((names, dictionary) => {
          names[dictionary.id] = dictionary.title
          return names
        }, {})
      },
      dictionaryColors() {
        let currentColor = 0;

        return this.mixinDefaultDictionaries.filter(d => d.dictionary_group === this.dictionaryGroup).reduce((colors, dictionary) => {
          colors[dictionary.id] = this.availableColors[currentColor]
          currentColor = (currentColor + 1) % this.availableColors.length
          return colors
        }, {})
      },
      legendItems() {
        return this.mixinDefaultDictionaries.filter(d => d.dictionary_group === this.dictionaryGroup).map(dictionary => ({
          dictionary: dictionary.title,
          color: this.dictionaryColors[dictionary.id]
        }))
      },
      /*topicData() {
        let currentColor = 0;

        return this.mixinDefaultDictionaries.filter(d => d.dictionary_group === this.dictionaryGroup).reduce((uris, dictionary) => {
          for (const tagDescription of dictionary.tagDescriptions) {
            uris[tagDescription.uri] = {
              dictionary: dictionary.title,
              color: this.availableColors[currentColor]
            }
          }

          currentColor = (currentColor + 1) % this.availableColors.length

          return uris
        }, {})
      },*/
      displayedKeywords() {
        return this.keywords//.filter(k => this.topicData[k.uri] !== undefined)
      },
      chartData() {
        return {
          data: this.allKeywords.map(keyword => {
            return {
              keywordId: keyword.value,
              keywordName: keyword.label,
              keywordType: keyword.type,
              occurences: keyword.occurences,
              sentiment: 1, // translates to opacity
              tooltip: `${keyword.label}<br>from ${keyword.dictionaryName}<br>${keyword.occurences} occurences`,
              tooltipClass: 'concept-map__landscape-panel__tooltip-heading',
              fillColor: keyword.color,
              emitClickEvent: keyword.dictionaryId,
            }
          })
        }
      },
    }
  })

  const COLORS = [
    '#7293cb',
    '#e1974c',
    '#84ba5b',
    '#d35e60',
    '#808585',
    '#9067a7',
    '#ab6857',
    '#ccc210',
  ]
</script>

<style lang="scss">
  .concept-map__landscape-panel {
    height: 100%;

    .bubble-chart {
      margin-top: 0;

      svg {
        transform: none !important;
      }
    }

    .bubble-chart-error-message {
      display: none;
    }

    .chart-container {
      position: relative;

      .chart-legend {
        position: absolute;
        top: 20px;
        right: 20px;
        background-color: rgba(255, 255, 255, 0.666);
        padding: 4px;
        pointer-events: none;

        .color-block {
          width: 16px;
          height: 16px;
        }
      }
    }
  }

  .landscape__select-group-to-analyse {
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .concept-map__landscape-panel__tooltip-heading {
    text-transform: none !important;
    text-align: left !important;
  }
</style>
