import throttle from '../../util/throttle.js'
import { Conversations, getAllConversations, getConversationsUnreadCount, getSearchQueryConversations } from '../../api/conversations.js'

const state = {
  detail: {
    data: {},
    loading: false,
    error: null,
  },
  listData: {
    fetchLimit: 500,
    data: [],
    unreadCount: 0,
    loading: false,
    error: null,
    endOfFeed: false,
    promise: null,
  },
}

export const ACTION_TYPES = {
  FETCH_CONVERSATIONS_LIST_NOW: 'CONVERSATIONS/FETCH_CONVERSATIONS_LIST_NOW',
  FETCH_CONVERSATIONS_LIST: 'CONVERSATIONS/FETCH_CONVERSATIONS_LIST',
  FETCH_CONVERSATIONS_LIST_FILTERED: 'CONVERSATIONS/FETCH_CONVERSATIONS_LIST_FILTERED',
  FETCH_CONVERSATIONS_LIST_FILTERED_NOW: 'CONVERSATIONS/FETCH_CONVERSATIONS_LIST_FILTERED_NOW',
  FETCH_CONVERSATIONS_UNREAD_COUNT: 'CONVERSATIONS/FETCH_CONVERSATIONS_UNREAD_COUNT',
  FETCH_CONVERSATION_DETAIL: 'CONVERSATIONS/FETCH_CONVERSATION_DETAIL',
}

export const MUTATION_TYPES = {
  FETCH_CONVERSATIONS_LIST_PENDING: 'CONVERSATIONS_CONVERSATIONS_LIST_PENDING',
  FETCH_CONVERSATIONS_LIST_SUCCESS: 'CONVERSATIONS_CONVERSATIONS_LIST_SUCCESS',
  FETCH_CONVERSATIONS_UNREAD_COUNT_SUCCESS: 'FETCH_CONVERSATIONS_UNREAD_COUNT_SUCCESS',
  FETCH_CONVERSATIONS_LIST_FAILURE: 'CONVERSATIONS_CONVERSATIONS_LIST_FAILURE',
  FETCH_CONVERSATION_DETAIL_PENDING: 'CONVERSATIONS/FETCH_CONVERSATION_DETAIL_PENDING',
  FETCH_CONVERSATION_DETAIL_SUCCESS: 'CONVERSATIONS/FETCH_CONVERSATION_DETAIL_SUCCESS',
  FETCH_CONVERSATION_DETAIL_FAILURE: 'CONVERSATIONS/FETCH_CONVERSATION_DETAIL_FAILURE',
  SET_ALL_CONVERSATIONS_PROMISE: 'CONVERSATIONS/SET_ALL_CONVERSATIONS_PROMISE',
}

export const actions = {
  [ACTION_TYPES.FETCH_CONVERSATIONS_UNREAD_COUNT]: ({ commit }) => {
    return getConversationsUnreadCount()
      .then(response => {
        commit(MUTATION_TYPES.FETCH_CONVERSATIONS_UNREAD_COUNT_SUCCESS, response)
      })
  },
  [ACTION_TYPES.FETCH_CONVERSATIONS_LIST]: throttle(({ dispatch }, params) => {
    dispatch(ACTION_TYPES.FETCH_CONVERSATIONS_LIST_NOW, params)
  }),
  [ACTION_TYPES.FETCH_CONVERSATIONS_LIST_FILTERED]: throttle(({ dispatch }, params) => {
    dispatch(ACTION_TYPES.FETCH_CONVERSATIONS_LIST_FILTERED_NOW, params)
  }),
  [ACTION_TYPES.FETCH_CONVERSATIONS_LIST_FILTERED_NOW] ({ commit }, searchQuery) {
    commit(MUTATION_TYPES.FETCH_CONVERSATIONS_LIST_PENDING)

    const getSearchQueryConversationsPromise= getSearchQueryConversations(searchQuery)
      .then(response => {
        commit(MUTATION_TYPES.FETCH_CONVERSATIONS_LIST_SUCCESS, response)
      })
      .catch(errors => {
        commit(MUTATION_TYPES.FETCH_CONVERSATIONS_LIST_FAILURE, errors)
      })
    commit(MUTATION_TYPES.SET_ALL_CONVERSATIONS_PROMISE, getSearchQueryConversationsPromise)
  },
  [ACTION_TYPES.FETCH_CONVERSATIONS_LIST_NOW] ({ commit }, params) {
    commit(MUTATION_TYPES.FETCH_CONVERSATIONS_LIST_PENDING)

    // get the filters

    const getAllConversationsPromise = getAllConversations()
      .then(response => {
        commit(MUTATION_TYPES.FETCH_CONVERSATIONS_LIST_SUCCESS, response)
      })
      .catch(errors => {
        commit(MUTATION_TYPES.FETCH_CONVERSATIONS_LIST_FAILURE, errors)
      })
    commit(MUTATION_TYPES.SET_ALL_CONVERSATIONS_PROMISE, getAllConversationsPromise)
  },
  [ACTION_TYPES.FETCH_CONVERSATION_DETAIL] ({ commit }, id) {
    const cachedConversation = reuseFromListData(id)
    if (cachedConversation) {
      commit(MUTATION_TYPES.FETCH_CONVERSATION_DETAIL_PENDING, id)
      commit(MUTATION_TYPES.FETCH_CONVERSATION_DETAIL_SUCCESS, cachedConversation)
    }

    if (state.detail.loading === id) {
      return console.log('already loading detail', id)
    }

    if (state.detail.errorId === id) {
      return console.log('already tried loading detail', id)
    }

    if (id === true) {
      id = state.detail.data.id
    }

    commit(MUTATION_TYPES.FETCH_CONVERSATION_DETAIL_PENDING, id)

    return Conversations.get(id)
      .then(conversation => {
        commit(MUTATION_TYPES.FETCH_CONVERSATION_DETAIL_SUCCESS, conversation)
      })
      .catch(errors => {
        commit(MUTATION_TYPES.FETCH_CONVERSATION_DETAIL_FAILURE, 'An error occurred while fetching the announcement.')
      })
  },
}

function reuseFromListData (id) {
  return state.listData.data.find(a => a.id === id)
}

function isStillOnRequestedPage (id) {
  return state.detail.loading === id
}

export const mutations = {
  [MUTATION_TYPES.FETCH_CONVERSATIONS_LIST_PENDING] (state) {
    state.listData.loading = true
  },
  [MUTATION_TYPES.FETCH_CONVERSATIONS_UNREAD_COUNT_SUCCESS] (state, data) {
    if (data && typeof data.unread_count === 'number') {
      state.listData.unreadCount = data.unread_count
    }
  },
  [MUTATION_TYPES.SET_ALL_CONVERSATIONS_PROMISE] (state, data) {
    state.listData.promise = data
  },
  [MUTATION_TYPES.FETCH_CONVERSATIONS_LIST_SUCCESS] (state, data) {
    state.listData.data = data
    state.listData.loading = false
  },
  [MUTATION_TYPES.FETCH_CONVERSATIONS_LIST_FAILURE] (state, error) {
    state.listData.error = error
    state.listData.endOfFeed = true
    state.listData.loading = false
  },
  [MUTATION_TYPES.FETCH_CONVERSATION_DETAIL_SUCCESS] (state, conversation) {
    if (isStillOnRequestedPage(conversation.id)) {
      state.detail.data = conversation
    }

    state.detail.error = null
    state.detail.loading = false
  },
  [MUTATION_TYPES.FETCH_CONVERSATION_DETAIL_FAILURE] (state, error) {
    state.detail.data = {}
    state.detail.errorId = state.detail.loading
    state.detail.error = error
    state.detail.loading = false
  },
  [MUTATION_TYPES.FETCH_CONVERSATION_DETAIL_PENDING] (state, id) {
    state.detail.loading = id
  },
}

export default {
  state,
  mutations,
  actions,
}
