import { cancelIfLate, createJSONRequest } from './create-request'

export const getSearchResultsForQuery = cancelIfLate((query) => createJSONRequest({
  url: `/api/general-search?query=${query}`,
  method: 'GET',
}))

export const getSearchResultsForQueryType = cancelIfLate(({ query, type }) => createJSONRequest({
  url: `/api/general-search?query=${query}&type=${type}`,
  method: 'GET',
}))

export const getMainActorTypeActorSuggestions = (query) => createJSONRequest({
  url: `/api/general-search?query=${query}&type=actors&actor-types=main-actor-types`,
  method: 'GET',
})

export const getSuggestedKeywords = () => createJSONRequest({
  url: `/api/general-search-keywords`,
  method: 'GET',
})

export const getRecentlyViewedResults = () => createJSONRequest({
  url: `/api/general-search/recently-viewed`,
  method: 'GET',
})

export const getAnalyticsKeyword = (data, query) => createJSONRequest({
  url: `/api/general-search/analytics-keyword?query=${query}`,
  method: 'POST',
  data: data,
})
