<template>
  <with-configuration-tabs>
    <div class="share-articles fixed-heading">
      <div class="heading">
        <h1>Share Insights</h1>
        <p class="guide_text">Share insights with other interested parties.</p>
      </div>
      <div class="scrollable has-padding">
        <p v-if="editedReport.id !== null">
          <ds-button variant="secondary" label="Create another email" @click="createNew"/>
        </p>

        <form class="card card--share-articles" v-for="emailReport in allReports">
          <template v-if="editedReport.id === emailReport.id">
            <form-group label="Recipients" class="limited-width">
              <div class="recipients-radios-wrapper">
                <radio-button
                  v-model="editedRecipientsForRadioButtons"
                  inline
                  name="recipients-radio"
                  label="Custom"
                  val="custom"
                />
                <radio-button
                  v-model="editedRecipientsForRadioButtons"
                  inline
                  name="recipients-radio"
                  label="Everyone"
                  val="all"
                />
              </div>

              <!--<dropdown v-model="editedRecipientsTransformed" :options="recipientOptionsTransformed" :disabled="!Array.isArray(editedReport.sentTo)" :multiple="true" :search="true"/>-->
              <AutocompleteDropdown v-model="recipients" placeholder="Search for users..."
                                    :do-search="autocompleteSearchUser" :multiple="true"/>
            </form-group>
            <form-group label="Message subject" class="limited-width">
              <ds-input v-model="editedReport.title"/>
            </form-group>
            <form-group label="Message body" class="limited-width">
              <rich-text-area v-model="editedReport.body" :minimal="true"/>
            </form-group>
            <hr>
            <form-group label="Shared actors">
              <span class="text-muted"
                    v-if="editedReport.actors.length === 0">No actors.</span>
              <actor-block
                v-for="actor in editedReport.actors"
                :key="actor.id"
                mode="remove"
                :id="actor.id"
                :name="actor.name"
                :description="actor.description"
                :url="actor.url"
                @remove="removeActor"
                :allow-input="true"
              />
            </form-group>
            <form-group label="Add more actors">
              <ds-input v-model="actors.searchQuery" placeholder="Search for actors…"
                        @update:modelValue="fetchActorsDebounced" class="limited-width"/>
              <div class="search-indicator" v-if="actors.searching">
                <icon name="spinner" style="vertical-align: middle;"/>
                Searching...
              </div>
              <div class="search-actor-list" v-else>
                <actor-block
                  v-for="actor in notAddedActors"
                  :key="actor.id"
                  mode="add"
                  :disabled="editedActorIds.includes(actor.id)"
                  :id="actor.id"
                  :name="actor.name"
                  :description="actor.short_description"
                  :url="getActorLink(actor)"
                  @add="addActor"
                  :allow-input="true"
                />
              </div>
            </form-group>
            <hr>
            <form-group label="Shared articles">
              <span class="text-muted" v-if="editedReport.articles.length === 0">No articles.</span>
              <article-block
                v-for="article in editedReport.articles"
                :key="article.id"
                mode="remove"
                :id="article.id"
                :title="article.title"
                :body="article.body"
                :url="getArticleLink(article)"
                :internal-url="getInternalArticleLink(article)"
                :date="article.date"
                @remove="removeArticle"
              />
            </form-group>
            <form-group label="Add more articles">
              <ds-input v-model="articles.searchQuery" placeholder="Search for articles…"
                        @pressedEnter="fetchArticles" class="limited-width"/>
              <div class="search-indicator" v-if="articles.searching">
                <icon name="spinner" style="vertical-align: middle;"/>
                Searching...
              </div>
              <div class="search-article-list" v-else>
                <article-block
                  v-for="article in notAddedArticles"
                  :key="article.sql_media_id"
                  mode="add"
                  :disabled="editedArticleIds.includes(article.sql_media_id)"
                  :id="article.sql_media_id"
                  :title="article.title"
                  :body="article.body"
                  :url="getArticleLink(article)"
                  :internal-url="getInternalArticleLink(article)"
                  :date="article.date"
                  @add="addArticle"
                />
              </div>
            </form-group>
            <hr>
            <ds-button variant="secondary" size="small" label="Save" @click="save"/>
            <ds-button variant="outline" size="small" label="Cancel"
                       @click="cancelEditing"/>
          </template>
          <template v-else>
            <h3 class="report-title">{{ emailReport.title }}</h3>
            <div class="report-body" v-html="emailReport.body"/>
            <div class="report-actions">
              <ds-button variant="secondary" size="small" label="Send email"
                         :disabled="emailReport.sent" @click="send(emailReport)"
                         v-if="emailReport.liveSentState === 'not-sent'"/>
              <ds-button variant="secondary" size="small" label="Sending..."
                         :disabled="true" v-if="emailReport.liveSentState === 'sending'"/>
              <ds-button variant="primary" size="small" label="Ok"
                         @click="emailReport.liveSentState = 'not-sent'"
                         v-if="emailReport.liveSentState === 'sent'"/>
              <span class="email-report__sent-success"
                    v-if="emailReport.liveSentState === 'sent'">Your email was sent to all recipients, a copy has been sent to you as well.</span>
              <div class="edit-buttons">
                <ds-button variant="outline" size="small" label="Edit"
                           @click="editReport(emailReport)"/>
                <ds-button variant="outline" size="small" icon="trash"
                           @click="deleteReport(emailReport.id)"/>
              </div>
            </div>
          </template>
        </form>
      </div>
    </div>
  </with-configuration-tabs>
</template>

<script lang="ts">
  import debounce from 'lodash/debounce'
  import DsTextarea from '../Form/DsTextarea.vue'
  import { fetchKnowledgeBaseData } from '../../api/knowledge-base.js'
  import ArticleBlock from './EmailReports/ArticleBlock.vue'
  import ActorBlock from './EmailReports/ActorBlock.vue'
  import Dropdown from '../Dropdown/Dropdown.vue'
  import AutocompleteDropdown from '../Dropdown/AutocompleteDropdown.vue'
  import RichTextArea from '../TextArea/RichTextArea.vue'
  import RadioButton from '../Form/RadioButton.vue'
  import { EmailReports } from '../../api/email-reports.js'
  import { inert, mapNewGlobalSearchResultToLegacyFormat } from '../../util/helpers.js'
  import ResourceMixin from '../../util/ResourceMixin.js'
  import { searchUser } from '../../api/config.js'
  import { getSearchResultsForQueryType } from '../../api/general-search.js'
  import WithConfigurationTabs from '../../pages/WithConfigurationTabs/WithConfigurationTabs.vue'
  import { defineComponent } from 'vue'

  const NOT_EDITING = {
    id: undefined,
    articles: [],
  }

  export default defineComponent({
    components: {
      WithConfigurationTabs,
      RadioButton,
      RichTextArea,
      Dropdown,
      AutocompleteDropdown,
      ArticleBlock,
      ActorBlock,
      DsTextarea,
    },
    mixins: [ResourceMixin],
    data () {
      return {
        recipientOptions: [],
        actors: {
          searchQuery: '',
          lastUsedSearchQuery: '',
          searching: false,
          results: [],
        },
        articles: {
          searchQuery: '',
          lastUsedSearchQuery: '',
          searching: false,
          results: [],
        },
        emailReports: [],
        editedReport: NOT_EDITING,
      }
    },
    computed: {
      isEditing () {
        return this.editedReport.id !== undefined
      },
      allReports () {
        const result = [...this.emailReports]

        if (this.editedReport.id === null) {
          result.unshift(this.editedReport)
        }

        return result
      },
      editedArticleIds () {
        return this.editedReport.articles.map(a => a.id)
      },
      editedActorIds () {
        return this.editedReport.actors.map(a => a.id)
      },
      recipientOptionsTransformed () {
        return this.recipientOptions.map(option => ({
          value: JSON.stringify(option.value),
          label: option.label,
        }))
      },
      recipients: {
        get () {
          var recipients = this.isEditing && Array.isArray(this.editedReport.sentTo) ? this.editedReport.sentTo : []// this.editedReport.sentTo.map(v => JSON.stringify(v)) : []

          var options = recipients.map(recipient => {
            if (recipient.email) {
              return {
                value: recipient.email,
                label: recipient.name + ' <' + recipient.email + '>',
              }
            }

            return recipient
          })

          return options
        },
        set (recipients) {
          if (Array.isArray(this.editedReport.sentTo)) {
            this.editedReport.sentTo = recipients.map(recipient => {
              if (recipient.value) {
                var name = recipient.label
                name = name.replace(' <' + recipient.value + '>', '')

                return {
                  email: recipient.value,
                  name: name,
                }
              }

              return recipient
            })
          }
        },
      },
      notAddedActors () {
        return this.actors.results.filter(a => !this.editedActorIds.includes(a.id))
      },
      notAddedArticles () {
        return this.articles.results.filter(a => !this.editedArticleIds.includes(a.sql_media_id))
      },
      editedRecipientsForRadioButtons: {
        get () {
          return Array.isArray(this.editedReport.sentTo) ? 'custom' : this.editedReport.sentTo
        },
        set (recipients) {
          if (recipients === 'custom') {
            if (!Array.isArray(this.editedReport.sentTo)) this.editedReport.sentTo = []
          } else {
            this.editedReport.sentTo = recipients
          }
        },
      },
    },
    methods: {
      getArticleLink,
      getActorLink,
      getInternalArticleLink (article) {
        if (!article.media_type || !article.meta) {
          return
        }

        if (this.canOpenInternalPage(article)) {
          return this.internalPageUrl(article)
        }
      },
      createNew () {
        this.editedReport = emptyShare()
      },
      fetchActors () {
        if (this.actors.lastUsedSearchQuery === this.actors.searchQuery || this.actors.searchQuery.length <= 2) {
          return
        }

        this.actors.lastUsedSearchQuery = this.actors.searchQuery
        this.actors.searching = true

        getSearchResultsForQueryType({ query: this.actors.searchQuery, type: 'actors' })
          .then(result => {
            const combinedActorsWithoutDuplicates = mapNewGlobalSearchResultToLegacyFormat([...result.actors, ...result.products])

            this.actors.results = combinedActorsWithoutDuplicates.slice(0, 10)
            this.actors.searching = false
          })
          .catch(error => {
            this.actors.searching = false
            throw error
          })
      },
      fetchActorsDebounced: debounce(function () {
        this.fetchActors()
      }, 500),
      addActor (id) {
        const actor = this.actors.results.find(a => a.id === id)
        if (!actor) return

        this.editedReport.actors.push({
          id: actor.id,
          name: actor.name,
          description: actor.short_description,
          url: getActorLink(actor),
          date: actor.date,
        })
      },
      denyActorRecommendation (id) {
        const index = this.editedReport.actors.findIndex(a => a.id === id)
        if (index < 0) return

        this.editedReport.actors.splice(index, 1)
      },
      fetchArticles () {
        if (this.articles.lastUsedSearchQuery === this.articles.searchQuery) {
          return
        }

        this.articles.lastUsedSearchQuery = this.articles.searchQuery
        this.articles.searching = true

        fetchKnowledgeBaseData({
          media_types: 'rss,public_resource,private_resource',
          limit: 10,
          query: this.articles.searchQuery,
          'ml-supported': false,
        }).then(({ data }) => {
          this.articles.results = data.results
          this.articles.searching = false
        }).catch(error => {
          this.articles.searching = false
          throw error
        })
      },
      fetchArticlesDebounced: debounce(function () {
        this.fetchArticles()
      }, 500),
      addArticle (id) {
        const article = this.articles.results.find(a => a.sql_media_id === id)
        if (article === undefined) return

        this.editedReport.articles.push({
          id: article.sql_media_id,
          sql_media_id: article.sql_media_id, // the request to open the internal page needs this key
          title: article.title,
          body: article.body,
          url: getArticleLink(article),
          date: article.date,
          meta: article.meta,
          media_type: article.media_type,
          internal_url: this.getInternalArticleLink(article), // added here as well, as this data will be stored on post save
        })
      },
      removeArticle (id) {
        const index = this.editedReport.articles.findIndex(a => a.id === id)
        if (index < 0) return

        this.editedReport.articles.splice(index, 1)
      },
      cancelEditing () {
        this.editedReport = NOT_EDITING
      },
      save () {
        if (this.editedReport.id === null) {
          this.saveNewReport()
        } else {
          this.updateExistingReport()
        }

        this.cancelEditing()
      },
      saveNewReport () {
        EmailReports.post(toApiData(this.editedReport)).then(data => {
          this.emailReports.unshift(fromApiData(data))
        })
      },
      updateExistingReport () {
        const id = this.editedReport.id
        this.updateEmailReportData(id, this.editedReport)

        EmailReports.post(toApiData(this.editedReport)).then(data => {
          this.updateEmailReportData(id, fromApiData(data))
        })
      },
      updateEmailReportData (id, data) {
        const index = this.emailReports.findIndex(r => r.id === id)
        if (index < 0) return
        this.emailReports.index = data
      },
      fetchReports () {
        EmailReports.get().then(result => {
          this.emailReports = result.map(fromApiData)
        })
      },
      editReport (report) {
        this.editedReport = inert(report)
      },
      deleteReport (id) {
        const index = this.emailReports.findIndex(r => r.id === id)
        if (index < 0) return

        this.emailReports.splice(index, 1)
        EmailReports.delete(id)
      },
      autocompleteSearchUser (query) {
        return searchUser({ query: query })
          .then(response => {
            var options = []

            response.forEach(function (user) {
              options.push({
                label: user.name + ' <' + user.email + '>',
                value: user.email,
              })
            })

            return options
          })
          .catch(err => {
            console.log(err)
          })
      },
      send (report) {
        report.liveSentState = 'sending'
        EmailReports.do('send', report)
          .then(response => {
            report.liveSentState = 'sent'
          })
      },
      beforeRouteLeave (to, from, next) {
        // This is a method in methods, instead of on the component object itself (as would normally be the case) because
        // it's actually the Dashboards component that is receiving the beforeRouteLeave() call and allowing us to
        // customize it here.

        if (this.isEditing && !window.confirm('Do you really want to leave? You are still editing!')) {
          next(false)
        } else {
          next()
        }
      },
    },
    watch: {
      'articles.searchQuery': function () {
        this.fetchArticlesDebounced()
      },
    },
    created () {
      this.fetchReports()
    },
  })

  function toApiData (emailReport) {
    const data = {
      title: emailReport.title,
      sent_to: emailReport.sentTo,
      body: {
        body: emailReport.body,
        actors: emailReport.actors,
        articles: emailReport.articles,
      },
    }

    if (emailReport.id !== null) {
      data.id = emailReport.id
    }

    return data
  }

  function fromApiData (data) {
    return {
      id: data.id,
      sentTo: data.sent_to || [],
      title: data.title || '',
      body: data.body.body || '',
      actors: data.body.actors || [],
      articles: data.body.articles || [],
      sent: data.sent, // This tells us if the email has been sent in the past yes or no
      liveSentState: 'not-sent', // Keep track of the reports state in the front-end view
    }
  }

  function emptyShare () {
    return {
      id: null,
      sentTo: [],
      sent: false,
      title: '',
      body: '',
      actors: [],
      articles: [],
    }
  }

  function getArticleLink (article) {
    if (!article.sql_media_id) {
      article.sql_media_id = article.id
    }

    for (const item of article.meta || []) {
      if (item.key === 'link' || item.key === 'url') {
        if (item.value === '') {
          return `${window.location.origin}/api/files/${article.sql_media_id}`
        }

        return item.value
      }
    }

    return article.url
  }

  function getActorLink (actor) {
    return window.location.origin + '/actors/' + actor.id
  }
</script>

<style lang="scss" scoped>
  .share-articles section {
    margin-bottom: 2em;
  }

  .share-articles hr {
    border: none;
    border-top: 1px solid #CECECE;
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 20px;
  }

  .card--share-articles {
    overflow: auto;

    .limited-width {
      max-width: 40rem;
    }
  }

  .search-article-list, .search-actor-list {
    padding-top: 20px;
    max-height: 380px;
    overflow-y: scroll;
  }

  .search-indicator {
    margin-top: 20px;
  }

  .report-actions {
    margin-top: 20px;
    display: flex;

    .edit-buttons {
      margin-left: auto;
    }
  }

  .report-title {
    margin-bottom: 10px;
  }

  .recipients-radios-wrapper {
    margin-bottom: 5px;
  }
</style>

<style lang="scss">
  .email-report__sent-success {
    display: flex;
    align-items: center;
  }

  .share-articles {
    .report-body {
      p {
        font-size: 14px;
      }
    }

    .editor .editor__content {
      padding: 0;
      border: none;

      > div {
        margin-top: 6px;
        padding: 7px 10px;
        border: 1px solid #CECECE;

        &:focus {
          outline: 0;
          box-shadow: 0 0 0 2px #00C3AF !important;
        }

        p {
          font-size: 14px;
        }
      }
    }
  }
</style>
