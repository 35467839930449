<template>
  <div class="scrollable announcement-detail" ref="scrollable">
    <!-- for some reason, v-if="error" does not work here -->
    <template
      v-if="$store.state.challenges.detail.errorId !== Number(this.$route.params.id)">
      <SimplifiedHero
        :imgUrl="imgUrl"
        :show-next="false"
        :keep-aspect-ratio="true"
        style="padding: 0; margin: 0 auto;"
        class="container"
        :has-subtitle="challenge && challenge.end_date && !!endTimeDifference"
      >
        <template v-slot:actions>
          <DsButton
            :label="$t('actor_edit')" variant="primary" icon="edit"
            @click="showEditChallengePanel"
            v-if="challenge.id && canUserEditChallenge && challenge.id"/>
          <DsButton
            :label="$t('actor_detail_next')" variant="primary" v-if="challenges.length > 1"
            @click="goToNext()"/>
          <DsButton
            :label="$t('challenges_back')" variant="primary"
            @click="goBack()"/>
          <div class="sdg-actions-container">
            <actor-detail-SDGs
              target="simplified-challenge"
              :selected-goals="sdgs"></actor-detail-SDGs>
          </div>
          <div v-if="challenge.status" class="challenge-hero-tag">
            <tag :label="getLabelForChallengeStatus(challenge.status)"/>
          </div>
        </template>
        <template v-slot:title>
          <div style="width: 100%;">
            <div style="width: 100%;">
              <div class="title-container">
                <div>
                  <span>
                  {{ challenge.title }}
                  </span>
                  <template v-if="challenge.claimedActor && challenge.claimedActorId">
                    <span style="text-transform: initial;">{{ ' ' + $t('by') + ' ' }}
                      <template v-if="challenge.claimedActorId">
                        <a :href="'/actors/' + challenge.claimedActorId">{{ challenge.claimedActor }}</a>
                      </template>
                      <template v-else>
                        {{ challenge.claimedActor }}
                      </template>
                    </span>
                  </template>
                  <template v-else-if="challenge.user_name">
                    <span style="text-transform: initial;">{{
                        ' ' + $t('by') + ' ' + reduceLastName(challenge.user_name)
                      }}</span>
                  </template>
                </div>
                <div class="title-actions-container" v-if="isLoggedIn">
                  <ds-button
                    v-if="isOwner || (challenge.invite_only && userCanUseMatchmaking)"
                    variant="primary"
                    :label="(challenge.invite_only && userCanUseMatchmaking) ? 'matchmaking' : 'manage invited users'"
                    @click="manageUsers()"
                  />
                  <div class="title-actions-icons-container">
                    <icon
                      :name="challenge.is_pinned ? 'pin' : 'pin-outline'"
                      class="pin-icon"
                      @click="togglePinStatus()"></icon>
                  </div>
                  <div
                    class="title-actions-icons-container"
                    style="margin-left: 10px; cursor: pointer; font-size: 17px"
                    @click="scrollToCommentSection">
                    <icon name="comments" style="fill: white;" size="22"/>
                    {{ commentsCount }}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="challenge.user && challenge.user.claims && challenge.user.claims.length > 0" style="width:100%;">
              <div class="title-claims-container">
                <!--<i class="far fa-building"></i>&nbsp;-->
                <template v-for="(actor, actorIndex) in challenge.user.claims">
                  <tag :clickable="true"
                       :label="actor.name"
                       :style="'border-color: white; color: white;'"
                       @click="onClickActorTag(actor.id)"/>
                  <!--<router-link :to="'/actors/' + actor.id">
                    {{ actor.name }}
                  </router-link><span v-if="actorIndex !== challenge.user.claims.length - 1">, </span>-->
                </template>
              </div>
            </div>
          </div>
        </template>
        <template
          v-slot:subtitle
          v-if="challenge && challenge.end_date && endTimeDifference">
          <div class="subtitle-container">
            <span v-html="endTimeDifference"></span>
          </div>
        </template>
      </SimplifiedHero>
      <div class="container" style="flex-grow: 1; padding: 0">
        <div class="colored-container" style="margin-bottom: 0">
          <div
            class="challenge-detail__left_right_container"
            :class="{'sufficient-data-for-two-columns': hasSufficientDataForTwoColumns}">
            <div class="challenge-detail__left">
              <div v-if="!challenge.id">{{ $t('loading') }}</div>
              <template
                v-if="challenge.description && challenge.description !== '<p></p>'">
                <h2 class="h2">{{ $t('manage_challenge_subtitle_description') }}</h2>
                <richBodyContent :html="challenge.description"/>
              </template>
              <template v-if="challenge.objective && challenge.objective !== '<p></p>'">
                <br>
                <h2 class="h2">{{ $t('manage_challenge_subtitle_objective') }}</h2>
                <richBodyContent :html="challenge.objective"/>
              </template>
              <!--        <template v-if="(challenge.files && challenge.files.length) || canUserEditChallenge">-->
              <!--          <Container class="container__added-padding">-->
              <!--            <h2 class="h2">{{$t('actor_detail_images')}}-->
              <!--              <template v-if="canUserEditChallenge">-->
              <!--                <ds-button icon="plus" :label="$t('actor_add_images')" variant="primary"-->
              <!--                           @click="openCreateResourcePanel('image')" size="small"/>-->
              <!--                <ds-button icon="arrow-expand" :label="$t('actor_open_repository')"-->
              <!--                           variant="primary" @click="openFileRepositorySidepanel"-->
              <!--                           size="small"/>-->
              <!--              </template>-->
              <!--            </h2>-->
              <!--            <Carousel-->
              <!--              class="carousel&#45;&#45;minicards row expanded-navigation-buttons"-->
              <!--              :perPageCustom="[[767, 1], [Number.MAX_SAFE_INTEGER, 3]]"-->
              <!--              :mouse-drag="false"-->
              <!--              :paginationEnabled="false"-->
              <!--              :navigationEnabled="challenge.files.length > 3"-->
              <!--              :navigationPrevLabel="carouselIcons.navigationLeft"-->
              <!--              :navigationNextLabel="carouselIcons.navigationRight"-->
              <!--              :style="galleryStyling"-->
              <!--              v-if="challenge.files"-->
              <!--              @pageChange="handleFileCarouselPageChange"-->
              <!--            >-->
              <!--              <Slide-->
              <!--                class="col-xs-6 col-sm-4 col-md-4 col-lg-4 col-xl-4 home-simplified__space-vertical"-->
              <!--                v-for="(file, index) in challenge.files"-->
              <!--                :key="index"-->
              <!--              >-->
              <!--                <div v-if="file.mimetype === 'application/pdf'"-->
              <!--                     class="actor-detail__pdf-container">-->
              <!--                  <a :href="file.url" target="_blank" class="actor-detail__pdf-link">-->
              <!--                    <Icon name="file-pdf" size="large" style="height: calc(100% - 65px)"/>-->
              <!--                    <span class="actor-detail__pdf-name">{{file.title}}</span>-->
              <!--                  </a>-->
              <!--                </div>-->
              <!--                <div class="actor-detail__image" :style="`backgroundImage: url(${file.url})`"-->
              <!--                     v-else-if="file.mimetype !== 'video_link' && file.url"-->
              <!--                     @click="showImageDetail(file.url, file.title)"/>-->
              <!--              </Slide>-->
              <!--            </Carousel>-->
              <!--          </Container>-->
              <!--        </template>-->
              <br>
            </div>
            <div class="challenge-detail__right">
              <template
                v-if="selectedRegions && selectedRegions.length">
                <h2 class="h2">{{ $t('manage_challenge_subtitle_location') }}</h2>
                <div
                  class="challenge-detail__bullets"
                  v-for="(geography, index) in selectedRegions"
                  :key="'geo' + index">
                  • {{ geography }}
                </div>
                <br>
              </template>
              <template v-if="challenge.growth_stages && challenge.growth_stages.length">
                <h2 class="h2">{{ $t('manage_challenge_subtitle_growth_stage') }}</h2>
              </template>
              <h2
                class="h2"
                v-if="isOwner || (challenge && challenge.tags && challenge.tags.length)">
                {{ $t('manage_challenge_subtitle_tags') }}</h2>
              <tag
                :label="challenge.invite_only === 1 ? 'PRIVATE' : 'PUBLIC'"
                v-if="challenge.id && isOwner"/>
              <tag
                v-for="(tag, index) in challenge.tags" :key="index"
                :label="tag.label.toUpperCase()"/>
            </div>
          </div>
          <br>
          <div class="row">
            <div class="col-md-12 col-xs-12">
              <div class="invited-users-container" v-if="showInvitedUsers">
                <span v-if="invitees.length > 0">{{
                    $t('challenge_invited_users')
                  }}:</span>
                <span v-else>{{ $t('challenge_no_invited_users') }}</span>
                <div class="avatars-container">
                  <avatar
                    v-for="(invitee, index) in invitees" :key="'invitee' + index"
                    :src="invitee.photo_url" :alt="invitee.name"/>
                </div>
              </div>

              <!-- hide for now -->
              <div class="pull-right" v-if="false">
                <div>
                  <share-network
                    :url="challengeFullUrl" :title="challenge.title" network="facebook"
                    description="" :quote="challenge.title"
                    :hashtags="challengeTags" twitter-user=""
                    style="margin-right: 0.2em; cursor: pointer;">
                    <icon name="facebook-logo" size="22" class="icon--black"/>
                  </share-network>
                  <share-network
                    :url="challengeShortUrl" :title="challenge.title"
                    network="twitter"
                    description="" quote="" :hashtags="challengeTags"
                    twitter-user="datascouts"
                    style="margin-right: 0.3em; cursor: pointer;">
                    <icon name="twitter-logo" size="22"/>
                  </share-network>
                  <share-network
                    :url="challengeFullUrl" :title="challenge.title"
                    network="linkedin"
                    description="" quote="" :hashtags="challengeTags"
                    twitter-user=""
                    style="margin-right: 0.3em; cursor: pointer;">
                    <icon name="linkedin-logo" size="22" class="icon--black"/>
                  </share-network>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="colored-container" v-if="isLoggedIn && this.challenge.id">
          <hr style="border-color: transparent; background: white;border-width: 2px">
          <h2 class="h2" style="margin-top: 30px;">{{ $t('challenges_join_the_conversation') }}!</h2>
          <div class="row" style="margin-top: -10px;">
            <div class="col-md-12 col-xs-12">
              <div class="notification-detail__comments-container" ref="comment_section">
                <div class="notification-detail__comments">
                  <div
                    v-for="(deletedCommentId, index) in deletedCommentIds" :key="index"
                    class="notification-detail__comment">
                    <div class="comment__container">
                      <avatar src="http://www.gravatar.com/avatar/?d=mp"/>
                      <div class="comment__body">
                        <div>
                          <span class="comment__user">&nbsp;</span>
                        </div>
                        <div class="talkbubble">
                          <richBodyContent
                            :html="'<i>' + $t('challenge_comment_deleted') + '</i>'"></richBodyContent>
                        </div>
                      </div>
                      <div class="comment__cta">
                        <div>
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="getRepliesForCommentId(deletedCommentId).length"
                      style="margin-left: 72px">
                      <div
                        class="comment__container"
                        v-for="childComment in getRepliesForCommentId(deletedCommentId)"
                        :key="childComment.id" style="margin-top: 5px">
                        <avatar
                          :src="childComment.user_photo_url"
                          :alt="childComment.user_name"/>
                        <div class="comment__body">
                          <div>
                          <span class="comment__user">{{
                              childComment.user_name
                            }}&nbsp;</span>
                            <time class="comment__time">{{
                                timeAgoTranslated(childComment.created_at)
                              }}
                            </time>
                          </div>
                          <div class="talkbubble">
                            <richBodyContent
                              :html="childComment.comment"></richBodyContent>
                          </div>
                        </div>
                        <div class="comment__cta">
                          <div>
                            <ds-button
                              @click="toggleLike(childComment)"
                              class="button--minimal button--default"
                              style="margin-right: 0; min-width: 70px;"
                              v-tooltip.top="getTooltipForLikes(childComment.likedByUsers)"
                              :icon="getLikedStatus(childComment) ? 'thumbs-up' : 'thumbs-up-outline'"
                              size="small"
                              icon-size="12"
                              :key="componentKey"
                              :label="String(getLikes(childComment)) || '0'"/>
                            <ds-button
                              @click="replyTo(childComment.reply_to_id, childComment.user_name)"
                              class="button--minimal button--default"
                              icon="reply"
                              size="small"
                              icon-size="12"
                            />
                            <ds-button
                              @click="deleteComment(childComment)"
                              v-if="editable(childComment)"
                              class="button--minimal button--default"
                              icon="trash"
                              size="small"
                              icon-size="12"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="comment__row" v-if="replyToId === deletedCommentId"
                        style="margin-top: 20px">
                        <div class="notification-detail__newcomment">
                          <form-group class="newcomment_form" :errors="errors.comment">
                            <avatar
                              class="newcomment__avatar"
                              :src="$store.state.user.profile.photo_url"
                              :alt="$store.state.user.profile.name"/>
                            <div class="talkbubble talkbubble-input">
                              <rich-text-area
                                class="newcomment__input"
                                v-model="newReplyToComment"
                                :also-allow-user-mentions="true"
                                :placeholder="$t('comment_placeholder')"/>
                            </div>
                            <ds-button
                              @click="addReplyComment()" variant="primary"
                              class="challenge-detail__submit"
                              icon="paper-plane"
                              icon-size="small"
                              :label="$t('challenge_comment_send')"
                              size="small"/>
                          </form-group>
                        </div>
                      </div>
                    </div>
                  </div>
                  <template v-for="(comment, index) in comments">
                    <div
                      class="notification-detail__comment"
                      :key="comment.id"
                      v-if="!comment.reply_to_id">
                      <div class="comment__container">
                        <avatar :src="comment.user_photo_url" :alt="comment.user_name"/>
                        <div class="comment__body">
                          <div>
                            <span class="comment__user">{{ comment.user_name }}&nbsp;</span>
                            <time class="comment__time">{{
                                timeAgoTranslated(comment.created_at)
                              }}
                            </time>
                          </div>
                          <div class="talkbubble">
                            <richBodyContent :html="comment.comment"></richBodyContent>
                          </div>
                        </div>
                        <div class="comment__cta">
                          <div>
                            <ds-button
                              @click="toggleLike(comment)"
                              class="button--minimal button--default"
                              style="margin-right: 0; min-width: 70px;"
                              v-tooltip.top="getTooltipForLikes(comment.likedByUsers)"
                              :icon="getLikedStatus(comment) ? 'thumbs-up' : 'thumbs-up-outline'"
                              size="small" iconSize="14" :key="componentKey"
                              :label="String(getLikes(comment)) || '0'"/>
                            <ds-button
                              @click="replyTo(comment.id)"
                              class="button--minimal button--default"
                              icon="reply"
                              size="small" iconSize="14"/>
                            <ds-button
                              @click="deleteComment(comment)"
                              v-if="editable(comment)"
                              class="button--minimal button--default" icon="trash"
                              size="small" iconSize="14"/>
                          </div>
                        </div>
                      </div>
                      <div
                        v-if="getRepliesForCommentId(comment.id).length"
                        style="margin-left: 72px">
                        <div
                          class="comment__container"
                          v-for="childComment in getRepliesForCommentId(comment.id)"
                          :key="childComment.id" style="margin-top: 5px">
                          <avatar
                            :src="childComment.user_photo_url"
                            :alt="childComment.user_name"/>
                          <div class="comment__body">
                            <div>
                          <span class="comment__user">{{
                              childComment.user_name
                            }}&nbsp;</span>
                              <time class="comment__time">{{
                                  timeAgoTranslated(childComment.created_at)
                                }}
                              </time>
                            </div>
                            <div class="talkbubble">
                              <richBodyContent
                                :html="childComment.comment"></richBodyContent>
                            </div>
                          </div>
                          <div class="comment__cta">
                            <div>
                              <ds-button
                                @click="toggleLike(childComment)"
                                class="button--minimal button--default"
                                style="margin-right: 0; min-width: 70px;"
                                v-tooltip.top="getTooltipForLikes(childComment.likedByUsers)"
                                :icon="getLikedStatus(childComment) ? 'thumbs-up' : 'thumbs-up-outline'"
                                size="small" iconSize="14" :key="componentKey"
                                :label="String(getLikes(childComment)) || '0'"/>
                              <ds-button
                                @click="replyTo(comment.id, childComment.user_name)"
                                class="button--minimal button--default"
                                icon="reply"
                                size="small" iconSize="14"/>
                              <ds-button
                                @click="deleteComment(childComment)"
                                v-if="editable(childComment)"
                                class="button--minimal button--default"
                                icon="trash"
                                size="small" iconSize="14"/>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        class="comment__row" v-if="replyToId === comment.id"
                        style="margin-top: 20px; margin-left: 72px">
                        <div class="notification-detail__newcomment">
                          <form-group class="newcomment_form" :errors="errors.comment">
                            <avatar
                              class="newcomment__avatar"
                              :src="$store.state.user.profile.photo_url"
                              :alt="$store.state.user.profile.name"/>
                            <div class="talkbubble talkbubble-input">
                              <rich-text-area
                                class="newcomment__input"
                                v-model="newReplyToComment"
                                :also-allow-user-mentions="true"
                                :placeholder="$t('comment_placeholder')"/>
                            </div>
                            <ds-button
                              @click="addReplyComment()" variant="primary"
                              class="challenge-detail__submit"
                              icon="paper-plane" :label="$t('challenge_comment_send')"
                              size="small"/>
                          </form-group>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
                <div class="comment__row">
                  <div class="notification-detail__newcomment">
                    <form-group class="newcomment_form" :errors="errors.comment">
                      <avatar
                        class="newcomment__avatar"
                        :src="$store.state.user.profile.photo_url"
                        :alt="$store.state.user.profile.name"/>
                      <div class="talkbubble talkbubble-input">
                        <rich-text-area
                          class="newcomment__input"
                          v-model="newComment"
                          :also-allow-user-mentions="true"
                          :placeholder="$t('comment_placeholder')"/>
                      </div>
                      <ds-button
                        @click="addComment()" variant="primary"
                        class="challenge-detail__submit"
                        icon="paper-plane" :label="$t('challenge_comment_send')"
                        size="small"/>
                    </form-group>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="actor-detail__next-actors-list" :style="nextChallengesContainerStyles"
        v-if="similarAndNextChallenges.length > 0">
        <Container class="container__added-padding">
          <h3>{{ $t('more_actor_profiles') }}</h3>
          <div class="row">
            <div
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4"
              v-for="similarChallenge in similarAndNextChallenges.slice(0, 3)"
            >
              <SimpleCard
                :imgUrl="`/api/challenges/${similarChallenge.id}/image`"
                :title="similarChallenge.title"
                :force-aspect-ratio="true"
                :content="similarChallenge.shortDescription"
                @click="handleClickChallengeCard(similarChallenge)"
              />
            </div>
          </div>
        </Container>
      </div>
      <SimplifiedFooter style="margin-top: 0"/>
    </template>
    <template
      v-else-if="$store.state.challenges.detail.errorStatusCode && $store.state.challenges.detail.errorStatusCode == 404">
      <div style="text-align: center; margin: auto">
        <h3 class="h3">This {{ challengeLabel }} has been removed.</h3>
        <a href="/challenges">View all {{ challengesLabel }}</a>
      </div>
    </template>
    <template v-else>
      <div style="text-align: center; margin: auto">
        <h3 class="h3">You are not allowed to view the contents of this
          {{ challengeLabel }}</h3>
        <a href="#" @click="showLoginModal()" v-if="!isLoggedIn">Log in</a>
        <a href="/profile/subscription#billing" v-else-if="canShowSubscriptions">Upgrade</a>
      </div>
    </template>
    <hover-overlay-button
      class="application-plus-btn"
      @openPanel="plusButtonClick"
      v-if="canCreateChallenge && !sidePanelIsVisible"
      :label="$t('add_challenge_panel_title', {challenge: challengeLabel})"
      orientation="right"></hover-overlay-button>
  </div>
</template>

<script>
  import { getChallengeTimeDifference, timeAgoTranslated, } from '../../util/date.js'

  import {
    createComment,
    createReplyComment,
    deleteComment,
    fetchInvitees,
    fetchSimilarChallenges,
    getComments,
    updateLikeStatus,
    updatePinStatus,
  } from '../../Domain/Challenge/Api/challenges.ts'

  import DsTextarea from '../../components/Form/DsTextarea.vue'
  import Modal from '../../components/Modals/Modal.vue'
  import RadioButton from '../../components/Form/RadioButton.vue'
  import Checkbox from '../../components/Form/Checkbox.vue'
  import Avatar from '../../components/Avatar/Avatar.vue'
  import Tag from '../../components/Tag/Tag.vue'
  import SimplifiedHero from '../../components/SimplifiedHero/SimplifiedHero.vue'
  import RichBodyContent from '../../components/RichBodyContent/RichBodyContent.vue'
  import Container from '../../components/Container/Container.vue'

  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import ActorDetailSDGs from '../ActorDetail/ActorDetailSDGs.vue'
  import RichTextArea from '../../components/TextArea/RichTextArea.vue'
  import SimplifiedFooter from '../../components/SimplifiedFooter/SimplifiedFooter.vue'
  import { trackHeapEvent } from '../../util/analytics.js'
  import { ACTION_TYPES as CHALLENGES_ACTION_TYPES, MUTATION_TYPES as CHALLENGE_MUTATION_TYPES, } from '../../store/modules/challenges.js'
  import { ecosystemMemberHasAccessToChallenge, getLabelForChallengeStatus, getTooltipForLikes, } from '../../util/helpers.js'
  import HoverOverlayButton from '../../components/Overlay/HoverOverlayButton.vue'
  import ICON_MANIFEST from '../../../icons/manifest.json'
  import { mix } from 'polished'
  import SimpleCard from '../../components/SimpleCard/SimpleCard.vue'
  import _uniqBy from 'lodash/uniqBy.js'
  import { CONTINENTS, COUNTRIES } from '../../constants/countries.js'
  import _uniq from 'lodash/uniq.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'challenge-detail',
    components: {
      HoverOverlayButton,
      SimplifiedFooter,
      RichTextArea,
      Container,
      RichBodyContent,
      Avatar,
      DsTextarea,
      Checkbox,
      Modal,
      RadioButton,
      Tag,
      SimplifiedHero,
      ActorDetailSDGs,
      SimpleCard,
    },
    data () {
      return {
        errors: {},
        fetching: false,
        fetchingFeatured: false,
        commentsCount: '',
        comments: [],
        newComment: '',
        newReplyToComment: '',
        invitees: null,
        componentKey: 0,
        getCommentsPromiseCount: 0,
        similarLocalChallenges: [],
        nextChallenges: [],
        endTimeDifference: '',
        endTimeInterval: null,
        replyToId: '',
      }
    },
    computed: {
      communityId () {
        return this.$route.params.communityId
      },
      userCanUseMatchmaking () {
        return this.challengeMatchmakingEnabled && this.canUserEditChallenge
      },
      challengeMatchmakingEnabled () {
        return this.$store.getters.challengeMatchmakingEnabled
      },
      selectedRegions () {
        if (!this.challenge || !this.challenge.geography_scope) {
          return []
        }
        const geographyOptions = { ...CONTINENTS, ...COUNTRIES }

        const selectedRegions = []

        Object.keys(geographyOptions)
          .forEach(key => {
            if (this.challenge.geography_scope.includes(key)) {
              selectedRegions.push(geographyOptions[key])
            }
          })

        return selectedRegions
      },
      hasSufficientDataForTwoColumns () {
        return this.challenge.geography_scope && this.challenge.geography_scope.length// || hasGrowthStages
      },
      nextChallengesContainerStyles () {
        return {
          backgroundColor: mix(0.94, '#fff', this.$store.state.config.primaryColor),
        }
      },
      similarAndNextChallenges () {
        const result = this.similarLocalChallenges.concat(this.challenges).filter(challenge => challenge.id !== Number(this.$route.params.id))
        return _uniqBy(result, 'id')
      },
      carouselIcons () {
        return {
          navigationLeft: ICON_MANIFEST['chevron-left'],
          navigationRight: ICON_MANIFEST['chevron-right'],
        }
      },
      sidePanelIsVisible () {
        return this.$store.state.ui.sidePanel.isVisible
      },
      canShowSubscriptions () {
        return this.$store.getters.hasAccessToEcosystemMemberPackages
      },
      canCreateChallenge () {
        return this.$store.getters.canCreateChallenge
      },
      userId () {
        return this.$store.getters.userId
      },
      accessibleChallengeStatusses () {
        return this.$store.getters.accessibleChallengeStatusses
      },
      activeFilters () {
        return this.$store.state.challenges.challengesFilters
      },
      challenges () {
        return this.$store.state.challenges.listData.data
      },
      error () {
        return this.$store.state.challenges.detail.errorId === Number(this.$route.params.id)
      },
      challenge () {
        return this.$store.state.challenges.detail.data
      },
      sdgs () {
        return this.challenge.sdgs ? this.challenge.sdgs.map(sdg => sdg.label) : []
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      canFetchInvitees () {
        return this.$store.getters.isLoggedIn
      },
      showInvitedUsers () {
        if (!this.invitees) {
          return false
        }
        if (this.challenge.user_is_invited || this.challenge.user_id === this.userId || this.isMember) {
          return true
        }
        return this.$store.getters.isLoggedIn && this.challenge.invite_only === 0
      },
      imgUrl () {
        if (this.challenge && this.challenge.id && this.challenge.updated_at) {
          return `/api/challenges/${this.challenge.id}/image?updated_at=${this.challenge.updated_at}`
        }
      },
      isMember () {
        return this.$store.getters.isMember
      },
      config () {
        return this.$store.state.config
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      actionText () {
        return this.announcement.action_text
      },
      actionUrl () {
        return this.announcement.action_url
      },
      challengeTags () {
        if (!this.challenge.tags) {
          return ''
        }

        return this.challenge.tags.map(tag => tag.label).join(',')
      },
      challengeShortUrl () {
        if (this.challenge.short_url && this.challenge.short_url.length > 0) {
          return this.announcement.short_url
        }

        return window.location.href
      },
      challengeFullUrl () {
        return window.location.href
      },
      canUserEditChallenge () {
        return this.isOwner || (this.userId && this.userId === this.challenge.user_id)
      },
      totalChallengesCount () {
        return this.$store.state.challenges.challengesCount
      },
      deletedCommentIds () {
        return _uniq(this.comments.filter(comment => comment.reply_to_id && !this.comments.find(parentComment => parentComment.id === comment.reply_to_id)).map(comment => comment.reply_to_id))
      },
    },
    methods: {
      getChallengeTimeDifference,
      getLabelForChallengeStatus,
      parentCommentExistsFor (childComment) {
        if (!childComment.reply_to_id) {
          return false
        }

        return !!this.comments.find(comment => comment.id === childComment.reply_to_id)
      },
      getRepliesForCommentId (parentCommentId) {
        return this.comments.filter(comment => comment.reply_to_id === parentCommentId)
      },
      onClickActorTag (actorId) {
        this.$router.push('/actors/' + actorId)
      },
      replyTo (commentId, userToReplyTo) {
        if (this.replyToId === commentId) {
          this.replyToId = ''
        } else {
          this.replyToId = commentId
          if (userToReplyTo) {
            this.newReplyToComment = `@ ${userToReplyTo}`
          }
        }
      },
      handleClickChallengeCard (challenge) {
        this.$router.push('/challenges/' + challenge.id)
      },
      fetchSimilarChallenges () {
        fetchSimilarChallenges(this.$route.params.id).then(result => {
          this.similarLocalChallenges = result
        })
      },
      showImageDetail (image, name) {
        if (image) {
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
            detailImage: image,
            detailImageName: name || 'Image',
          })
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.IMAGE_DETAIL)
        }
      },
      handleFileCarouselPageChange (page) {
        trackHeapEvent('challengeDetailSimplified.fileCarousel', { page })
      },
      getTooltipForLikes,
      reduceLastName (name) {
        const split = name.split(' ')
        const last = split.pop()
        if (split.length > 0 && last.length > 1 && !last.endsWith('.')) {
          return split.join(' ') + ' ' + last.charAt(0) + '.'
        }
        return name
      },
      plusButtonClick () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'manage-challenge-panel' })
      },
      isAccessibleForUser (challenge) {
        if (this.isMember) {
          return true
        }
        return ecosystemMemberHasAccessToChallenge(challenge, this.userId, this.accessibleChallengeStatusses)
      },
      findNextAccessibleChallenge (currentIndex) {
        for (let i = currentIndex + 1; i < this.challenges.length; i++) {
          if (this.challenges[i] && this.isAccessibleForUser(this.challenges[i])) {
            return this.challenges[i]
          }
        }
        return false
      },
      goToNext () {
        trackHeapEvent('challengeDetail.clickNext')
        const currentChallengeIndex = this.challenges.findIndex(a => a.id === this.challenge.id)
        const nextAccessibleChallenge = this.findNextAccessibleChallenge(currentChallengeIndex)

        if (nextAccessibleChallenge) {
          if (this.communityId) {
            this.$router.push('/communities/' + this.communityId + '/challenges/' + nextAccessibleChallenge.id)
            return
          }

          this.$router.push('/challenges/' + nextAccessibleChallenge.id)
          return
        }

        if (this.$store.state.challenges.listData.endOfFeed || this.challenges.length === this.totalChallengesCount) {
          if (this.communityId) {
            this.$router.push('/communities/' + this.communityId + '/challenges/' + this.challenges[0].id)
            return
          }

          this.$router.push('/challenges/' + this.challenges[0].id)
          return
        }

        this.$store.dispatch(CHALLENGES_ACTION_TYPES.FETCH_CHALLENGES_LIST_NOW, {
          limit: this.$store.state.challenges.listData.fetchLimit,
          offset: this.challenges.length,
          filters: this.activeFilters,
        }).then((challenges) => {
          if (this.$route.params.id) {
            this.goToNext()
          }
        })
      },
      timeAgoTranslated,
      fetchChallenge () {
        this.$store.dispatch(CHALLENGES_ACTION_TYPES.FETCH_CHALLENGE_DETAIL, Number(this.$route.params.id))
      },
      togglePinStatus () {
        trackHeapEvent('challengeDetail.togglePin')
        this.challenge.is_pinned = !this.challenge.is_pinned

        updatePinStatus(this.challenge.id, this.challenge.is_pinned)
      },
      goBack () {
        trackHeapEvent('challengeDetail.back')

        if (this.communityId) {
          this.$router.push('/communities/' + this.communityId + '/challenges')
          return
        }
        this.$router.push('/challenges')
      },
      fetchInvitees () {
        fetchInvitees(Number(this.$route.params.id))
          .then(response => {
            this.invitees = response
          })
      },
      showEditChallengePanel () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          'component': 'manage-challenge-panel',
          metaData: this.challenge,
        })
      },
      manageUsers () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          'component': 'manage-challenge-invitees',
          metaData: this.challenge,
        })
      },
      showLoginModal () {
        window.redirect_uri = window.location.href
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
      },
      fetchComments () {
        // this fixes problems when rapidly clicking the like button multiple times
        this.getCommentsPromiseCount++
        const currentCommentsPromiseCount = this.getCommentsPromiseCount
        return getComments(Number(this.$route.params.id))
          .then(data => {
            if (currentCommentsPromiseCount < this.getCommentsPromiseCount) {
              return
            }
            this.comments = data.comments
            this.commentsCount = data.count
          })
          .catch(err => {
            // log the error to sentry
          })
      },
      addReplyComment () {
        trackHeapEvent('challengeDetail.addComment')
        createReplyComment(this.challenge.id, this.newReplyToComment, this.replyToId)
          .then(() => {
            this.newReplyToComment = '<p></p>'
            this.fetchComments()
          })
          .catch(errors => {
            this.errors = errors
          })
          .finally(() => {
            this.replyToId = ''
          })
      },
      addComment () {
        trackHeapEvent('challengeDetail.addComment')
        createComment(this.challenge.id, this.newComment)
          .then(() => {
            this.newComment = '<p></p>'
            this.fetchComments()
          })
          .catch(errors => {
            this.errors = errors
          })
      },
      // this fixes problems when rapidly clicking the like button multiple times
      getLikedStatus (comment) {
        if (comment.updated_is_liked_by_user === undefined) {
          return comment.is_liked_by_user
        }
        return comment.updated_is_liked_by_user
      },
      // this fixes problems when rapidly clicking the like button multiple times
      getLikes (comment) {
        if (comment.updatedLikes === undefined) {
          return comment.likes
        }
        return comment.updatedLikes
      },
      toggleLike (comment) {
        trackHeapEvent('challengeDetail.toggleLikeComment')
        comment.updated_is_liked_by_user = !this.getLikedStatus(comment)
        comment.updatedLikes = this.getLikes(comment) + (this.getLikedStatus(comment) ? 1 : -1)
        this.componentKey++
        // this fixes problems when rapidly clicking the like button multiple times
        // https://stackoverflow.com/questions/30233302/promise-is-it-possible-to-force-cancel-a-promise
        this.getCommentsPromiseCount++
        updateLikeStatus(this.challenge.id, comment.id, comment.updated_is_liked_by_user).then(() => {
          this.fetchComments().then(() => {
            this.componentKey++
          })
        })
      },
      deleteComment (comment) {
        trackHeapEvent('challengeDetail.removeComment')
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          name: 'comment',
          resource: comment,
          modalContextType: 'comment',
        })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
      },
      confirmDeleteComment (comment) {
        deleteComment(this.challenge.id, comment.id)
          .then(() => {
            this.fetchComments()
          })
      },
      editable (comment) {
        if (!this.$store.state.user || !this.$store.state.user.profile) {
          return false
        }

        return this.isOwner || comment.user_id === this.$store.state.user.profile.id
      },
      scrollToCommentSection () {
        if (!this.isLoggedIn) {
          this.showLoginModal()
          return
        }

        this.$refs.comment_section.scrollIntoView({ behavior: 'smooth' })
      },
      isLastChallengeInList () {
        return this.challenges[this.challenges.length - 1].id === this.challenge.id
      },
      refreshChallenge () {
        this.comments = []
        this.similarLocalChallenges = []
        this.invitees = null
        this.fetchComments()
        this.fetchChallenge()
        this.fetchSimilarChallenges()

        if (this.canFetchInvitees) {
          this.fetchInvitees()
        }
      },
    },
    async beforeRouteUpdate (to, from, next) {
      if (to.name === 'challenges-detail') {
        this.$route.params.id = to.params.id
        this.refreshChallenge()

        if (this.$refs.scrollable) {
          this.$refs.scrollable.scrollTop = 0
        }
      }
      next()
    },
    mounted () {
      this.$bus.on('commentDeleteConfirmation', (context) => {
        this.confirmDeleteComment(context.resource)
      })

      this.$bus.on('challengeUpdated', () => {
        this.fetchChallenge()
        this.$store.commit(CHALLENGE_MUTATION_TYPES.FLUSH_CACHE)
      })

      this.$bus.on('challengeDeleted', () => {
        if (this.communityId) {
          this.$router.push(`/communities/${this.communityId}/challenges`)
          return;
        }

        this.$router.push('/challenges')
      })

      this.$bus.on('challengeInviteesUpdated', () => {
        this.fetchInvitees()
      })

      this.endTimeInterval = setInterval(() => {
        this.endTimeDifference = this.getChallengeTimeDifference(this.challenge.end_date, true)
      }, 1000)

      if (this.challenges.length === 0 || this.isLastChallengeInList()) {
        this.$store.dispatch(CHALLENGES_ACTION_TYPES.FETCH_CHALLENGES_LIST, {
          limit: this.$store.state.challenges.listData.fetchLimit,
          offset: this.challenges.length,
          filters: this.activeFilters,
        })
      }
    },
    async created () {
      this.fetchChallenge()
      this.fetchComments()
      this.fetchSimilarChallenges()

      if (this.canFetchInvitees) {
        this.fetchInvitees()
      }
    },
    beforeUnmount () {
      if (this.endTimeInterval) {
        clearInterval(this.endTimeInterval)
      }
      this.$bus.off('challengeUpdated')
      this.$bus.off('challengeDeleted')
      this.$bus.off('challengeInviteesUpdated')
      this.$bus.off('commentDeleteConfirmation')

      this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
    },
    mixins: [TranslationsMixin],
    watch: {
      '$route.params.id': {
        async handler () {
          await this.fetchChallenge()
        }
      }
    }
  })
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables";

  .announcement-detail {
    color: $color-text-grey;
    padding-top: 20px;
    padding-bottom: 0;
    display: flex;
    flex-direction: column;

    .container {
      margin: 0 auto;
    }

    @media (max-width: $screen-md) {
      padding-top: 0;
      width: 100vw;

      .container {
        width: 100vw;
      }
    }
  }

  .rich-body-content {
    margin-bottom: 0;
  }

  .title-container, .subtitle-container, .title-claims-container {
    display: flex;
    width: 100%;
    padding: 0 40px;

    .title-actions-container {
      display: flex;
      margin-left: auto;
      flex-wrap: wrap;

      .title-actions-icon-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      :deep(.pin-icon) {
        cursor: pointer;

        svg {
          fill: white
        }
      }
    }
  }

  .title-claims-container {
    display: block;
    text-transform: none !important;
    font-size: 14px;

    :deep(.tag) {
      line-height: 24px;
    }
  }

  .notification-detail__comments-container {
    margin-bottom: 50px;

    .notification-detail__comment {
      padding: 20px 0;
    }

    .comment__container {
      display: flex;

      .comment__user {
        color: black;
        font-weight: 500;
      }

      .comment__time {

      }

      .comment__body {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
      }

      .comment__cta {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        min-width: 146px;
        margin-top: 16px;
        margin-left: 5px;
        text-align: right;
      }

    }

    :deep(.avatar) {
      width: 40px;
      height: 40px;
      margin-right: 32px;
      margin-top: 18px;

      &.newcomment__avatar {
        margin-top: 0
      }

      img {
        width: 40px;
        height: 40px;
      }
    }

    .newcomment_form {
      display: flex;

      .newcomment__input {
      }

      :deep(.form-group__header) {
        display: none;
      }
    }

    .challenge-detail__submit {
      width: 145px;
      margin-left: 5px;
      height: 32px;
      margin-top: auto;
    }

    .talkbubble {
      position: relative;
      flex-grow: 1;

      padding: 10px;
      border-radius: 5px;
      border: 1px solid var(--primary-lighter);
      background: $color-background-lightest-grey;

      &.talkbubble-input {
        padding-top: 0;
      }

    }

    .talkbubble:before {
      content: "";
      position: absolute;
      right: 100%;
      top: 4px;
      width: 0;
      height: 0;
      z-index: 1;

      border-top: 13px solid transparent;
      border-right: 26px solid $color-background-lightest-grey;
      border-bottom: 13px solid transparent;
    }

    .talkbubble:after {
      content: "";
      position: absolute;
      box-shadow: 0px 0px;
      right: 100%;
      top: 3px;
      width: 0;
      /* border: 1px solid black; */
      height: 0;
      border-top: 14px solid transparent;
      border-right: 28px solid var(--primary-lighter);
      border-bottom: 14px solid transparent;
      z-index: 0;
    }

    .talkbubble textarea {
      position: inherit;
      width: 100%;
      height: 100%;
      background: inherit;

      margin: 0;
      padding: 0;

      border: none;
      resize: none;

      &:focus {
        border: none;
      }
    }
  }

  .invited-users-container {
    float: left;
    display: flex;
    justify-content: center;
    line-height: 35px;

    .avatars-container {
      display: flex;
      align-items: center;
    }

    .avatar {
      margin-left: 10px;
      margin-right: 0;
    }
  }

  :deep(.simplified-hero__visual) {
    pointer-events: none;

    .simplified-hero__actions {
      pointer-events: all;
    }
  }

  :deep(.simplified-hero .sdg-actions-container) {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    padding-bottom: 35px;
  }

  h2.h2 {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 30px;

    .button {
      margin-left: 30px;
    }
  }

  @media screen and (min-width: $screen-md) {
    .challenge-detail__left_right_container.sufficient-data-for-two-columns {
      display: flex;

      .challenge-detail__left {
        width: 70%;
        padding-right: 30px;
      }

      .challenge-detail__right {
        width: calc(30% - 2px);
        border: 4px solid white;
        padding: 30px;
        background: transparent;

        span {
          font-weight: 500;
        }

        h2.h2 {
          text-align: center;
        }
      }
    }
  }

  .challenge-detail__bullets {
    font-size: 14px;
    text-transform: capitalize;
  }

  .actor-detail__next-actors-list {
    :deep(.simple-card__content) {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
  }

  .tag {
    font-size: 14px;
    padding: 0.2rem 0.5rem;
    border: 2px solid var(--primary);
    font-family: "Fira Sans", sans-serif;
    opacity: 1;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    background-color: transparent;
    color: var(--primary);

  }

  .challenge-hero-tag {
    position: absolute;
    top: 10px;
    left: 10px;
    background: white;
    height: 26px;

    .tag {
      color: white;
      // border-color: var(--primary);
      background: var(--primary);
    }
  }
</style>
