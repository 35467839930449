import {
  createJSONRequest,
  createResource,
} from './create-request.js'
import querify from '../util/querify'

export const getReportForPortfolio = (params = {}) => createJSONRequest({
  method: 'GET',
  url: `/api/dynamic-report?${querify(params)}`
})
