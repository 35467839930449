<template>
  <div id="evolution-div" class="evolution-chart">
    <svg ref="evolution" class="evolution-chart__graph"></svg>
  </div>
</template>

<script>
import * as d3v4 from 'd3'

import { inert } from '../../util/helpers'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'

const LEFT_INFO_WIDTH = 35
const LEFT_AXIS_WIDTH = 0
const RIGHT_INFO_WIDTH = 35
const RIGHT_AXIS_WIDTH = 0
const BOTTOM_INFO_HEIGHT = 15
const BOTTOM_AXIS_HEIGHT = 30
const SPACE_FOR_NAMES = 100

export default {
  data () {
    return {
      selectedLine: "",
      color:{
        mouseout: "lightgrey",
        mouseover: "#8f11fe",
        click: "#3c7f89",
        reference: this.$store.getters.primaryColor
      }
    }
  },
  props: { data: {}, type: null },
  computed: {
    bottomTitle () {
      if (this.type == 'digital_footprint') {
        return "Digital Footprint Score"
      } else if (this.type == 'business_size') {
        return "Business Size Score"
      }
    },
    startDate () {
      return this.data.startDate
    },
    endDate () {
      return this.data.endDate
    },
    lastRanks () {
      return this.data.lastRanks
    },
    portfolioName () {
      var portfolioName = this.$store.getters.activePortfolio.name
      if (portfolioName) {
        return portfolioName
      }
      return 'All'
    }
  },
  methods: {
    render (data) {
      var newRankData = inert(data)
      this.clearGraph()

      this.outerWidth = Math.floor(this.$el.offsetWidth)
      this.outerHeight = Math.floor(window.innerHeight - 230)
      this.companyCount = newRankData.length

      // set the dimensions and margins of the graph
      //var margin = {top: 10, right: 200, bottom: 30, left: 200}
      const margin = {
        top: 0,
        right: 0,
        bottom: BOTTOM_AXIS_HEIGHT + BOTTOM_INFO_HEIGHT,
        left: LEFT_AXIS_WIDTH + LEFT_INFO_WIDTH + SPACE_FOR_NAMES
      }
      const width = this.outerWidth - margin.left - margin.right - SPACE_FOR_NAMES - RIGHT_INFO_WIDTH
      const height = this.outerHeight - margin.top - margin.bottom

      // parse the date / time
      var parseTime = d3v4.timeParse("%Y-%m-%d");

      // set the ranges
      var x = d3v4.scaleTime().range([0, width]);
      var yLeft = d3v4.scaleLinear().range([0, height]);
      var yRight = d3v4.scaleLinear().range([0, height]);

      // define the line
      var valueline = d3v4.line()
        .x(function (d) { return x(d.date); })
        .y(function (d) {
          var rank = d.rank
          rank = rank > 20 ? 20 : rank

          return yLeft(rank)
        })
      // append the svg object to the body of the page
      // appends a 'group' element to 'svg'
      // moves the 'group' element to the top left margin
      const root = d3v4.select(this.$refs.evolution)
        .attr('width', this.outerWidth)
        .attr('height', this.outerHeight)

      var svg = root
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', width - 0)
        .attr('height', height - 0)

      var xAxisTicks = newRankData[0].ranks.length

      // Add the X-axis grid lines
      svg.append("g")
        .attr("class", "evolution-chart__graph-grid")
        .attr("transform", "translate(0," + height + ")")
        .call(d3v4.axisBottom(x)
        .ticks(xAxisTicks - 1)
        .tickSize(-height)
        .tickFormat(""))

      //to get the last two actors names so that later we can change the positions of the ranks
      var getActorsName
      if(newRankData.length < 2) {
        getActorsName = [newRankData[newRankData.length-1].name, newRankData[newRankData.length-1].name]
      } else {
        getActorsName = [newRankData[newRankData.length-1].name, newRankData[newRankData.length-2].name]
      }

      // Don't mutate the data outside of the vuex store
      for (var index in newRankData) {
        //The index value goes from 0-20, which causes an error in IE11 where it can't find the index for the last value which is 20. To get the 20 values, the index value should be from 0-19.
        if(index < 20) {
          this.draw(newRankData[index], x, yLeft, yRight, svg, valueline, width, height, getActorsName)
        }
      }

      var companies = Object.keys(newRankData)

      // Keep the amount of Y-ticks to 20
      var yTicks = Math.min(20, this.companyCount)

      var tickValues = Array.from(Array(yTicks), (x,i) => i + 1)

      var yLeftAxis = d3v4
        .axisLeft(yLeft)
        .tickValues(tickValues)
        .tickFormat(function (d, i) {
          var label = newRankData[i]['name']
          if (label.length > 8) {
            label = label.substring(0, 7) + '...'
          }
          return label + ' / ' + (i + 1)
        })
        .tickSizeOuter(0)

      var lastRanks = newRankData

      var yRightAxis = d3v4
        .axisRight(yRight)
        .tickValues(tickValues)
        .tickFormat(function (d, i) {
          lastRanks.sort(function(a,b){
            return a.latestRank-b.latestRank
          })
          var label = lastRanks[i].name
          if (label.length > 8) {
            label = label.substring(0, 7) + '...'
          }
          return (i + 1) + ' / ' + label
        })
        .tickSizeOuter(0)

      // Add the Y axes
      svg.append("g")
        .call(yLeftAxis)
        .attr('class', 'axis axis--y axis--left')

      var thisVue = this; // to get the scope of the vue component
      d3v4.selectAll(".axis--left g")
          .data(newRankData)
          .attr("class" , (d)=> `tick y-rank-text-left ${d.ranks[0].id}-rank-text`)
          .on("mouseover", function(d) {
            //To detect if it the browser is NOT IE 6-11
            if (!(/*@cc_on!@*/false || !!document.documentMode)) {
            // add the ranking texts to the empty objects and position them on chart
            d3v4.selectAll('.rank_text')
                .attr('x', function(d) { return x(d.date); })
                .attr('y', function(d) { return yLeft(d.rank); })
                .text(function(d) { return "Rank: " + d.rank; })

            d3v4.selectAll('.score_text')
                .attr('x', function(d) { return x(d.date); })
                .attr('y', function(d) { return yLeft(d.rank); })
                .text(function(d) { if (typeof d.score == 'number') {return "Score: " + d.score.toPrecision(4); } else {return "Score: " + d.score;} })
                .style('transform', 'translate(0px,15px)')
                .style('-ms-transform', 'translate(0px,15px)')

            // add the ranking empty objects backgrounds and position them on chart
            d3v4.selectAll('.rank_background')
                .attr('x', function(d) { return x(d.date); })
                .attr('y', function(d) { return yLeft(d.rank); })
            } else {
              d3v4.selectAll('.rank_text')
                .attr('x', function(d) { return x(d.date) + 5; })
                .attr('y', function(d) { return yLeft(d.rank) + 15; })
                .text(function(d) { return "Rank: " + d.rank; })

              d3v4.selectAll('.score_text')
                .attr('x', function(d) { return x(d.date)+ 5; })
                .attr('y', function(d) { return yLeft(d.rank)+ 30; })
                .text(function(d) { if (typeof d.score == 'number') {return "Score: " + d.score.toPrecision(4); } else {return "Score: " + d.score;} })
                .style('transform', 'translate(0px,15px)')
                .style('-ms-transform', 'translate(0px,15px)')

              // add the ranking empty objects backgrounds and position them on chart
              d3v4.selectAll('.rank_background')
                .attr('x', function(d) { return x(d.date); })
                .attr('y', function(d) { return yLeft(d.rank); })
            }

            d3v4.selectAll(`.rank-line-${d.ranks[0].id}`)
                .style("stroke", function(){
                  if(d.isReference == true) {
                    return thisVue.color.reference
                  }else{
                    return thisVue.color.mouseover
                  }
                })
                .style("stroke-width", 4)

              d3v4.selectAll(`.${d.ranks[0].id}_month_rank rect`)
                  .style('fill', function(){
                      if(d.isReference == true) {
                        return thisVue.color.reference
                      }else{
                        return thisVue.color.mouseover
                      }
                    })

              d3v4.selectAll(`.${d.ranks[0].id}-axis-background`)
                  .style('fill', function(){
                      if(d.isReference == true) {
                        return thisVue.color.reference
                      }else{
                        return thisVue.color.mouseover
                      }
                    })

            d3v4.selectAll(`.${d.ranks[0].id}-rank-text text`)
                .style('fill', "white")

            d3v4.selectAll(`.${d.ranks[0].id}_month_rank`)
                .style('display', 'block')
                .style("transform", function(d,i) {
                  // to apply diferent positions to the first and last ranks for each actor in order not to be on top the the axes
                  // and place the last two actor ranks above the line in order to be visible
                  if (d.name == getActorsName[0] || d.name == getActorsName[1]) {
                    if (i == 0) {
                      return `translate(10px,-25px)`
                    } else if (newRankData[i] && newRankData[i].ranks && i == newRankData[i].ranks.length-1) {
                      return `translate(-85px,-25px)`
                    } else {
                      return `translate(-35px,-25px)`
                    }
                  } else {
                    if (i == 0) {
                      return `translate(10px,25px)`
                    } else if (newRankData[i] && newRankData[i].ranks && i == newRankData[i].ranks.length-1) {
                      return `translate(-85px,25px)`
                    } else {
                      return `translate(-35px,25px)`
                    }
                  }
                })
          })
          .on("mouseout", function(d){
            d3v4.selectAll(`.rank-line-${d.ranks[0].id}`)
                .style("stroke", function(){
                  if(d.isReference == true) {
                    return thisVue.color.reference
                  }else{
                    return thisVue.color.mouseout
                  }
                })
                .style("stroke-width", 2)

            d3v4.selectAll(`.${d.ranks[0].id}_month_rank`)
                .style('display', 'none')

            d3v4.selectAll(`.${d.ranks[0].id}-axis-background`)
                .style('fill', "none")

            d3v4.selectAll(`.${d.ranks[0].id}-rank-text text`)
                .style('fill', "black")

            // keeps the selected ranks visible
            if(thisVue.selectedLine.length > 0) {
              d3v4.selectAll(thisVue.selectedLine)
                  .style('display', 'block')
              d3v4.selectAll(`${thisVue.selectedLine}_month_rank rect`)
                  .style('fill', thisVue.color.click)
              d3v4.selectAll(`${thisVue.selectedLine}_month_rank`)
                  .style('display', 'block')
              d3v4.selectAll(`${thisVue.selectedLine}-axis-background`)
                  .style('fill', thisVue.color.click)
              d3v4.selectAll(`${thisVue.selectedLine}-rank-text text`)
                  .style('fill', "white")
            }

            //To detect if it the browser is NOT IE 6-11
            if (!(/*@cc_on!@*/false || !!document.documentMode)) {
            // add the ranking texts to the empty objects
            d3v4.selectAll('.rank_text')
                .attr('x', function(d) { return x(d.date); })
                .attr('y', function(d) { return yLeft(d.rank); })
                .text(function(d) { return "Rank: " + d.rank; })

            d3v4.selectAll('.score_text')
                .attr('x', function(d) { return x(d.date); })
                .attr('y', function(d) { return yLeft(d.rank); })
                .text(function(d) { if (typeof d.score == 'number') {return "Score: " + d.score.toPrecision(4); } else {return "Score: " + d.score;} })
                .style('transform', 'translate(0px,15px)')
                .style('-ms-transform', 'translate(0px,15px)')
              } else {
                // add the ranking texts to the empty objects
            d3v4.selectAll('.rank_text')
                .attr('x', function(d) { return x(d.date) + 5; })
                .attr('y', function(d) { return yLeft(d.rank) + 15; })
                .text(function(d) { return "Rank: " + d.rank; })

            d3v4.selectAll('.score_text')
                .attr('x', function(d) { return x(d.date)+ 5; })
                .attr('y', function(d) { return yLeft(d.rank)+ 30; })
                .text(function(d) { if (typeof d.score == 'number') {return "Score: " + d.score.toPrecision(4); } else {return "Score: " + d.score;} })
                .style('transform', 'translate(0px,15px)')
                .style('-ms-transform', 'translate(0px,15px)')
              }
          })
          .on("click" , function(d){
            d3v4.selectAll(`.rank-line-${d.ranks[0].id}`)
                .style("stroke", function(){
                  if(d.isReference == true) {
                    return thisVue.color.reference
                  }else{
                    return thisVue.color.click
                  }
                })
                .style("stroke-width", 4)
            for(var i = 0; i < document.querySelectorAll(".rank-line").length; i++ ) {
              //To detect if it the browser is NOT IE 6-11, beacuse in IE the classlist property doesn't exist.
              if (!(/*@cc_on!@*/false || !!document.documentMode)) {
                if(document.querySelectorAll(".rank-line")[i].classList.contains("rank-line__selected")){
                  document.querySelectorAll(".rank-line")[i].classList.remove("rank-line__selected");
                }
              } else {
                //so that it displays the console error only once
                if(i < 1) {
                  console.error("Some functionalities are not available in IE")
                }
              }
            }
            if (!(/*@cc_on!@*/false || !!document.documentMode)) {
              d3v4.selectAll(`.rank-line-${d.ranks[0].id}`).attr('class',  (t)=>`rank-line rank-line-${d.ranks[0].id} rank-line__selected`)
            }

            //hide all ranks
            d3v4.selectAll('.points')
                .style('display', 'none')

            // to remove the fill of the selected axis background
            d3v4.selectAll(`${thisVue.selectedLine}-axis-background`)
                .style('fill', "none")

            // replace the color of the selected axis background
            d3v4.selectAll(`${thisVue.selectedLine}-rank-text text`)
                .style('fill', 'black')

            //assign the class name of the selected line in the chart
            thisVue.selectedLine = ""
            thisVue.selectedLine = '.'+d.ranks[0].id

            // show the rank for a specific actor
            d3v4.selectAll(`.${d.ranks[0].id}_month_rank`)
                .style('display', 'block')

            //To detect if it the browser is NOT IE 6-11
            if (!(/*@cc_on!@*/false || !!document.documentMode)) {
            // add the ranking texts to the empty objects
            d3v4.selectAll('.rank_text')
                .attr('x', function(d) { return x(d.date); })
                .attr('y', function(d) { return yLeft(d.rank); })
                .text(function(d) { return "Rank: " + d.rank; })

            d3v4.selectAll('.score_text')
                .attr('x', function(d) { return x(d.date); })
                .attr('y', function(d) { return yLeft(d.rank); })
                .text(function(d) { if (typeof d.score == 'number') {return "Score: " + d.score.toPrecision(4); } else {return "Score: " + d.score;} })
                .style('transform', 'translate(0px,15px)')
                .style('-ms-transform', 'translate(0px,15px)')
              } else {
                // add the ranking texts to the empty objects
                d3v4.selectAll('.rank_text')
                    .attr('x', function(d) { return x(d.date) + 5; })
                    .attr('y', function(d) { return yLeft(d.rank) + 15; })
                    .text(function(d) { return "Rank: " + d.rank; })

                d3v4.selectAll('.score_text')
                    .attr('x', function(d) { return x(d.date)+ 5; })
                    .attr('y', function(d) { return yLeft(d.rank)+ 30; })
                    .text(function(d) { if (typeof d.score == 'number') {return "Score: " + d.score.toPrecision(4); } else {return "Score: " + d.score;} })
                    .style('transform', 'translate(0px,15px)')
                    .style('-ms-transform', 'translate(0px,15px)')
              }

            d3v4.selectAll(`${thisVue.selectedLine}_month_rank rect`)
                .style('fill', function(){
                  if(d.isReference == true) {
                    return thisVue.color.reference
                  }else{
                    return thisVue.color.click
                  }
                })

            d3v4.selectAll(`.${d.ranks[0].id}-axis-background`)
                .style('fill', function(){
                  if(d.isReference == true) {
                    return thisVue.color.reference
                  }else{
                    return thisVue.color.click
                  }
                })

            d3v4.selectAll(`.${d.ranks[0].id}-rank-text text`)
                .style('fill', 'white')

            thisVue.$emit('actor', d.ranks[0])
            thisVue.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component:"scores", metaData: d.ranks[0].id})
          })

      svg.append("g")
         .attr("transform", "translate(" + width + ", 0)")
         .call(yRightAxis)
         .attr('class', 'axis axis--y axis--right')

      d3v4.selectAll(".axis--right g")
          .data(newRankData)
          .attr("class" , (d)=> `tick y-rank-text-left ${d.ranks[0].id}-rank-text`)
          .on("mouseover", function(d){
              //To detect if it the browser is NOT IE 6-11
              if (!(/*@cc_on!@*/false || !!document.documentMode)) {
                // add the ranking texts to the empty objects and position them on chart
                d3v4.selectAll('.rank_text')
                  .attr('x', function(d) { return x(d.date); })
                  .attr('y', function(d) { return yLeft(d.rank); })
                  .text(function(d) { return "Rank: " + d.rank; })

                d3v4.selectAll('.score_text')
                  .attr('x', function(d) { return x(d.date); })
                  .attr('y', function(d) { return yLeft(d.rank); })
                  .text(function(d) { if (typeof d.score == 'number') {return "Score: " + d.score.toPrecision(4); } else {return "Score: " + d.score;} })
                  .style('transform', 'translate(0px,15px)')
                  .style('-ms-transform', 'translate(0px,15px)')

                // add the ranking empty objects backgrounds and position them on chart
                d3v4.selectAll('.rank_background')
                    .attr('x', function(d) { return x(d.date); })
                    .attr('y', function(d) { return yLeft(d.rank); })
              } else {
                // add the ranking texts to the empty objects
                d3v4.selectAll('.rank_text')
                    .attr('x', function(d) { return x(d.date) + 5; })
                    .attr('y', function(d) { return yLeft(d.rank) + 15; })
                    .text(function(d) { return "Rank: " + d.rank; })

                d3v4.selectAll('.score_text')
                    .attr('x', function(d) { return x(d.date)+ 5; })
                    .attr('y', function(d) { return yLeft(d.rank)+ 30; })
                    .text(function(d) { if (typeof d.score == 'number') {return "Score: " + d.score.toPrecision(4); } else {return "Score: " + d.score;} })
                    .style('transform', 'translate(0px,15px)')
                    .style('-ms-transform', 'translate(0px,15px)')

                // add the ranking empty objects backgrounds and position them on chart
                d3v4.selectAll('.rank_background')
                    .attr('x', function(d) { return x(d.date); })
                    .attr('y', function(d) { return yLeft(d.rank); })
              }

              d3v4.selectAll(`.rank-line-${d.ranks[0].id}`)
                .style("stroke", function(){
                  if(d.isReference == true) {
                    return thisVue.color.reference
                  }else{
                    return thisVue.color.mouseover
                  }
                })
                .style("stroke-width", 4)

              d3v4.selectAll(`.${d.ranks[0].id}_month_rank rect`)
              .style('fill', function(){
                  if(d.isReference == true) {
                    return thisVue.color.reference
                  }else{
                    return thisVue.color.mouseover
                  }
                })

              d3v4.selectAll(`.${d.ranks[0].id}-axis-background`)
              .style('fill', function(){
                  if(d.isReference == true) {
                    return thisVue.color.reference
                  }else{
                    return thisVue.color.mouseover
                  }
                })

            d3v4.selectAll(`.${d.ranks[0].id}-rank-text text`)
            .style('fill', "white")

            d3v4.selectAll(`.${d.ranks[0].id}_month_rank`)
              .style('display', 'block')
              .style("transform", function(d,i) {
                // to apply diferent positions to the first and last ranks for each actor in order not to be on top the the axes
                // and place the last two actor ranks above the line in order to be visible
                if(d.name == getActorsName[0] || d.name == getActorsName[1]) {
                  if(i == 0) {
                    return `translate(10px,-25px)`
                  } else if(i == newRankData[i].ranks.length-1) {
                    return `translate(-85px,-25px)`
                  }else{
                    return `translate(-35px,-25px)`
                  }
                } else {
                  if(i == 0) {
                    return `translate(10px,25px)`
                  } else if(i == newRankData[i].ranks.length-1) {
                    return `translate(-85px,25px)`
                  }else{
                    return `translate(-35px,25px)`
                  }
                }
              })
          })
          .on("mouseout", function(d){
            d3v4.selectAll(`.rank-line-${d.ranks[0].id}`)
                .style("stroke", function(){
                  if(d.isReference == true) {
                    return thisVue.color.reference
                  }else{
                    return thisVue.color.mouseout
                  }
                })
                .style("stroke-width", 2)

            d3v4.selectAll(`.${d.ranks[0].id}_month_rank`)
                .style('display', 'none')

            d3v4.selectAll(`.${d.ranks[0].id}-axis-background`)
                .style('fill', "none")

            d3v4.selectAll(`.${d.ranks[0].id}-rank-text text`)
                .style('fill', "black")

            // keeps the selected ranks visible
            if(thisVue.selectedLine.length > 0) {
              d3v4.selectAll(thisVue.selectedLine).style('display', 'block')
              d3v4.selectAll(`${thisVue.selectedLine}_month_rank rect`)
              .style('fill', thisVue.color.click)
              d3v4.selectAll(`${thisVue.selectedLine}_month_rank`)
              .style('display', 'block')
              d3v4.selectAll(`${thisVue.selectedLine}-axis-background`)
              .style('fill', thisVue.color.click)
              d3v4.selectAll(`${thisVue.selectedLine}-rank-text text`)
              .style('fill', "white")
            }
          })
          .on("click" , function(d){
            d3v4.selectAll(`.rank-line-${d.ranks[0].id}`)
                .style("stroke", function(){
                  if(d.isReference == true) {
                    return thisVue.color.reference
                  }else{
                    return thisVue.color.click
                  }
                })
                .style("stroke-width", 4)
            for(var i = 0; i < document.querySelectorAll(".rank-line").length; i++ ) {
              //To detect if it the browser is NOT IE 6-11, beacuse in IE the classlist property doesn't exist.
              if (!(/*@cc_on!@*/false || !!document.documentMode)) {
                if(document.querySelectorAll(".rank-line")[i].classList.contains("rank-line__selected")){
                  document.querySelectorAll(".rank-line")[i].classList.remove("rank-line__selected");
                }
              } else {
                //so that it displays the console error only once
                if(i < 1) {
                  console.error("Some functionalities are not available in IE")
                }
              }
            }
            if (!(/*@cc_on!@*/false || !!document.documentMode)) {
              d3v4.selectAll(`.rank-line-${d.ranks[0].id}`).attr('class',  (t)=>`rank-line rank-line-${d.ranks[0].id} rank-line__selected`)
            }

            //hide all ranks
            d3v4.selectAll('.points')
            .style('display', 'none')

            // to remove the fill of the selected axis background
            d3v4.selectAll(`${thisVue.selectedLine}-axis-background`)
            .style('fill', "none")

            // replace the color of the selected axis background
            d3v4.selectAll(`${thisVue.selectedLine}-rank-text text`)
            .style('fill', 'black')

            //assign the class name of the selected line in the chart
            thisVue.selectedLine = ""
            thisVue.selectedLine = '.'+d.ranks[0].id

            // show the rank for a specific actor
            d3v4.selectAll(`.${d.ranks[0].id}_month_rank`)
            .style('display', 'block')

            //To detect if it the browser is NOT IE 6-11
            if (!(/*@cc_on!@*/false || !!document.documentMode)) {
              // add the ranking texts to the empty objects
              d3v4.selectAll('.rank_text')
                .attr('x', function(d) { return x(d.date); })
                .attr('y', function(d) { return yLeft(d.rank); })
                .text(function(d) { return "Rank: " + d.rank; })

              d3v4.selectAll('.score_text')
                .attr('x', function(d) { return x(d.date); })
                .attr('y', function(d) { return yLeft(d.rank); })
                .text(function(d) { if (typeof d.score == 'number') {return "Score: " + d.score.toPrecision(4); } else {return "Score: " + d.score;} })
                .style('transform', 'translate(0px,15px)')
                .style('-ms-transform', 'translate(0px,15px)')
            } else {
              // add the ranking texts to the empty objects
                d3v4.selectAll('.rank_text')
                    .attr('x', function(d) { return x(d.date) + 5; })
                    .attr('y', function(d) { return yLeft(d.rank) + 15; })
                    .text(function(d) { return "Rank: " + d.rank; })

                d3v4.selectAll('.score_text')
                    .attr('x', function(d) { return x(d.date)+ 5; })
                    .attr('y', function(d) { return yLeft(d.rank)+ 30; })
                    .text(function(d) { if (typeof d.score == 'number') {return "Score: " + d.score.toPrecision(4); } else {return "Score: " + d.score;} })
                    .style('transform', 'translate(0px,15px)')
                    .style('-ms-transform', 'translate(0px,15px)')
            }

            d3v4.selectAll(`${thisVue.selectedLine}_month_rank rect`)
            .style('fill', function(){
                  if(d.isReference == true) {
                    return thisVue.color.reference
                  }else{
                    return thisVue.color.click
                  }
                })

            d3v4.selectAll(`.${d.ranks[0].id}-axis-background`)
            .style('fill', function(){
                  if(d.isReference == true) {
                    return thisVue.color.reference
                  }else{
                    return thisVue.color.click
                  }
                })

            d3v4.selectAll(`.${d.ranks[0].id}-rank-text text`)
            .style('fill', 'white')

            thisVue.$emit('actor', d.ranks[0])
            thisVue.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component:"scores", metaData: d.ranks[0].id})
          })

       //this inserts rect tags inside the tick so that they can later be highlighted
      var yAxesWidth = d3v4.selectAll(".axis").node().getBBox()
      d3v4.selectAll(".axis--left g")
        .each(function(d,i){
          var tick = d3v4.select(this),
              text = tick.select('text'),
              bBox = text.node().getBBox(),
              bBox2 = yAxesWidth;
          tick.insert('rect', ':first-child')
            .attr('class', (f)=>`${f.ranks[0].id}-axis-background`)
            .attr('x', bBox2.x-4)
            .attr('y', bBox.y - 3)
            .attr('height', bBox.height + 6)
            .attr('width', bBox2.width + 4)
            .attr('fill', "none")
        });

      d3v4.selectAll(".axis--right g")
        .each(function(d,i){
          var tick = d3v4.select(this),
              text = tick.select('text'),
              bBox = text.node().getBBox(),
              bBox2 = yAxesWidth;
          tick.insert('rect', ':first-child')
            .attr('class', (f)=>`${f.ranks[0].id}-axis-background`)
            .attr('x', 0)
            .attr('y', bBox.y - 3)
            .attr('height', bBox.height + 6)
            .attr('width', bBox2.width + 1)
            .attr('fill', "none")
        });

      var xAxis = d3v4
        .axisBottom(x)
        .ticks(xAxisTicks)
        .tickFormat(d3v4.timeFormat("%b"));

      // Add the X axis
      svg.append("g")
          .attr("transform", `translate(0,${height + BOTTOM_INFO_HEIGHT})`)
          .call(xAxis)
          //.call(xAxis.tickSize(-height)) // in order to have the vertical tick lines, have this instead
          .attr('class', 'axis axis--x')

      // get the number of all the different ranks
      var rankObjects = []
      newRankData.map(function(d){
        d.ranks.map(function(c){
          rankObjects.push(c)
        })
      })

      // create empty objects which will later have the ranks to be displayd
      var points = svg.selectAll('.points')
        .data(rankObjects)
        .enter()
        .append('g')
        .attr("class", function(d){return "points " + d.id + "_month_rank"}) // add unique class to each line (the id of the actor)
        .style("display", "none")

      // empty objects background
      points.append('rect')
        .attr('class', 'rank_background')
        .style('height', '38px')
        .style('width', '85px')
        .attr('height', '38px')
        .attr('width', '85px')
        .style('opacity', '0.8')
        .attr('rx', '5')
        .attr('ry', '5')
        .style('transform', 'translate(-5px, -17px)')
        .style('-ms-transform', 'translate(-5px, -17px)')

      //empty objects texts
      points.append('text')
        .attr('class', 'rank_text')
        .style('fill', 'white')

      points.append('text')
        .attr('class', 'score_text')
        .style('fill', 'white')

      // Left info
      var leftInfo = root.append('g')
        .attr('class', 'info info--left')

      leftInfo
        .append('line')
        .attr('x1', LEFT_INFO_WIDTH - 1)
        .attr('x2', LEFT_INFO_WIDTH - 1)
        .attr('y1', 0)
        .attr('y2', this.outerHeight - BOTTOM_AXIS_HEIGHT)

      leftInfo
        .append('text')
        .text(this.bottomTitle + ' TOP 20')
        .attr('text-anchor', 'end')
        .attr('x', -90)
        .attr('y', 25)
        .attr('transform', function () {
          return `rotate(-90)`
        })

      // Left info
      var rightInfo = root.append('g')
        .attr('class', 'info info--right')

      rightInfo
        .append('line')
        .attr('x1', this.outerWidth - RIGHT_INFO_WIDTH - 1)
        .attr('x2', this.outerWidth - RIGHT_INFO_WIDTH - 1)
        .attr('y1', 0)
        .attr('y2', this.outerHeight - BOTTOM_AXIS_HEIGHT)

      rightInfo
        .append('text')
        .text(this.bottomTitle + ' TOP 20')
        .attr('text-anchor', 'end')
        .attr('x', - 90)
        .attr('y', this.outerWidth - 15)
        .attr('transform', function () {
          return `rotate(-90)`
        })

      // Bottom info
      var bottomInfo = root.append('g')
        .attr('class', 'info info--bottom')
        .attr('transform', `translate(0,${height + BOTTOM_INFO_HEIGHT})`)

      bottomInfo
        .append('line')
        .attr('x1', 0)
        .attr('x2', this.outerWidth)
        .attr('y1', 1)
        .attr('y2', 1)
    },
    draw (data, x, yLeft, yRight, svg, valueline, width, height, lastTwoRankActorName) {
      var parseTime = d3v4.timeParse("%Y-%m-%d");

      var ranks = data['ranks'].slice(0)

      // format the data
      ranks.forEach(function(d) {
          d.date = parseTime(d.date);
          d.rank = +d.rank;
      });

      // sort years ascending
      ranks.sort(function (a, b) {
        return a["date"] - b["date"];
      })

      // Scale the range of the ranks
      x.domain(d3v4.extent(ranks, function(d) { return d.date; }));

      // 1 => highest rank, > 1 => lower
      var lowestOverallRank = d3v4.max(ranks, function (d) {
        return d.actualRank
      });

      var highestOverallRank = d3v4.min(ranks, function (d) {
        return d.actualRank
      });

      var lowestPortfolioRank = d3v4.max(ranks, function (d) {
        return d.rank
      });

      var highestPortfolioRank = d3v4.min(ranks, function (d) {
        return d.rank
      });

      // Keep the amount of Y-ticks to 20
      var yTicks = Math.min(20, this.companyCount)

      yLeft.domain([0, yTicks]);
      yRight.domain([0, yTicks]);

      var bisectDate = d3v4.bisector(function (d) { return d.date }).left
      var portfolioName = this.portfolioName

      var strokeColor = this.color.mouseout;

      if (data['isReference'] == true) {
        strokeColor = this.$store.getters.primaryColor
      }

      var thisVue = this; // to get the scope of the vue component
      var pathRank = svg.append("path")
        .datum(ranks)
        .attr("class", (d)=> `rank-line rank-line-${d[0].id}`)
        .attr("d", valueline)
        .style("stroke", strokeColor)
        .style("stroke-width", 2)
        .on("mouseover", function(data) {
          var x0 = x.invert(d3v4.event.pageX),
            i = bisectDate(data, x0, 0)

          if (i == 1) {
            // The first rank does not have a previous rank
            // but we want to show the evolution of the flow anyway
            i = 2
          }

          var startRank = data[i - 2], // The bisector returns a 1-index based result, whereas our data array is 0 indexed
              endRank = data[i - 1]

          // show the rank for a specific actor
          d3v4.selectAll(`.${data[0].id}_month_rank`)
              .style("display", "block")
              .style("transform", function(d,i) {
                // to apply diferent positions to the first and last ranks for each actor in order not to be on top the the axes
                // and place the last two actor ranks above the line in order to be visible
                if(d.name == lastTwoRankActorName[0] || d.name == lastTwoRankActorName[1]) {
                  if(i == 0) {
                    return `translate(10px,-25px)`
                  } else if(i == ranks.length-1) {
                    return `translate(-85px,-25px)`
                  }else{
                    return `translate(-35px,-25px)`
                  }
                } else {
                  if(i == 0) {
                    return `translate(10px,25px)`
                  } else if(i == ranks.length-1) {
                    return `translate(-85px,25px)`
                  }else{
                    return `translate(-35px,25px)`
                  }
                }
              })

            //To detect if it the browser is NOT IE 6-11
            if (!(/*@cc_on!@*/false || !!document.documentMode)) {
            // add the ranking texts to the empty objects and position them on chart
            d3v4.selectAll('.rank_text')
                .attr('x', function(d) { return x(d.date); })
                .attr('y', function(d) { return yLeft(d.rank); })
                .text(function(d) { return "Rank: " + d.rank; })

            d3v4.selectAll('.score_text')
                .attr('x', function(d) { return x(d.date); })
                .attr('y', function(d) { return yLeft(d.rank); })
                .text(function(d) { if (typeof d.score == 'number') {return "Score: " + d.score.toPrecision(4); } else {return "Score: " + d.score;} })
                .style('transform', 'translate(0px,15px)')
                .style('-ms-transform', 'translate(0px,15px)')

             // add the ranking empty objects backgrounds and position them on chart
            d3v4.selectAll('.rank_background')
                .attr('x', function(d) { return x(d.date); })
                .attr('y', function(d) { return yLeft(d.rank); })
          } else {
            // add the ranking texts to the empty objects
            d3v4.selectAll('.rank_text')
                .attr('x', function(d) { return x(d.date) + 5; })
                .attr('y', function(d) { return yLeft(d.rank) + 15; })
                .text(function(d) { return "Rank: " + d.rank; })

            d3v4.selectAll('.score_text')
                .attr('x', function(d) { return x(d.date)+ 5; })
                .attr('y', function(d) { return yLeft(d.rank)+ 30; })
                .text(function(d) { if (typeof d.score == 'number') {return "Score: " + d.score.toPrecision(4); } else {return "Score: " + d.score;} })
                .style('transform', 'translate(0px,15px)')
                .style('-ms-transform', 'translate(0px,15px)')

            // add the ranking empty objects backgrounds and position them on chart
            d3v4.selectAll('.rank_background')
                .attr('x', function(d) { return x(d.date); })
                .attr('y', function(d) { return yLeft(d.rank); })
          }

          d3v4.selectAll(`.rank-line-${data[0].id}`)
              .style("stroke", function(){
                if(data.isReference == true) {
                  return thisVue.color.reference
                }else{
                  return thisVue.color.mouseover
                }
              })
              .style("stroke-width", 4)

          d3v4.selectAll(`.${data[0].id}_month_rank rect`)
              .style('fill', function(){
                if(data.isReference == true) {
                  return thisVue.color.reference
                }else{
                  return thisVue.color.mouseover
                }
              })

          d3v4.selectAll(`.${data[0].id}-axis-background`)
              .style('fill', function(){
                if(data.isReference == true) {
                  return thisVue.color.reference
                }else{
                  return thisVue.color.mouseover
                }
              })

          d3v4.selectAll(`.${data[0].id}-rank-text text`)
              .style('fill', "white")
        })
        .on("mouseout", function(d) {
          //hide all ranks
          d3v4.selectAll(`.${d[0].id}_month_rank`).style('display', 'none')

          d3v4.selectAll(`.rank-line-${d[0].id}`)
              .style("stroke", function(){
                if(d[0].isReference == true) {
                  return thisVue.color.reference
                }else{
                  return thisVue.color.mouseout
                }
              })
              .style("stroke-width", 2)

          d3v4.selectAll(`.${d[0].id}_month_rank`)
              .style('display', 'none')

          d3v4.selectAll(`.${d[0].id}-axis-background`)
              .style('fill', "none")

          d3v4.selectAll(`.${d[0].id}-rank-text text`)
              .style('fill', "black")

          // keeps the selected ranks visible
          if(thisVue.selectedLine.length > 0) {
            d3v4.selectAll(thisVue.selectedLine)
                .style('display', 'block')
            d3v4.selectAll(`${thisVue.selectedLine}_month_rank rect`)
                .style('fill', thisVue.color.click)
            d3v4.selectAll(`${thisVue.selectedLine}_month_rank`)
                .style('display', 'block')
            d3v4.selectAll(`${thisVue.selectedLine}-axis-background`)
                .style('fill', thisVue.color.click)
            d3v4.selectAll(`${thisVue.selectedLine}-rank-text text`)
                .style('fill', "white")
          }
        })
        .on("click", function (d) {
          for(var i = 0; i < document.querySelectorAll(".rank-line").length; i++ ) {
            //To detect if it the browser is NOT IE 6-11, beacuse in IE the classlist property doesn't exist.
            if (!(/*@cc_on!@*/false || !!document.documentMode)) {
              if(document.querySelectorAll(".rank-line")[i].classList.contains("rank-line__selected")){
                document.querySelectorAll(".rank-line")[i].classList.remove("rank-line__selected");
              }
            } else {
              //so that it displays the console error only once
              if(i < 1) {
                console.error("Some functionalities are not available in IE")
              }
            }
          }
          if (!(/*@cc_on!@*/false || !!document.documentMode)) {
            this.classList.add("rank-line__selected");
          }

          //hide all ranks
          d3v4.selectAll('.points')
              .style('display', 'none')

          // to remove the fill of the selected axis background
          d3v4.selectAll(`${thisVue.selectedLine}-axis-background`)
              .style('fill', "none")

          // replace the color of the selected axis background
          d3v4.selectAll(`${thisVue.selectedLine}-rank-text text`)
              .style('fill', 'black')

          //assign the class name of the selected line in the chart
          thisVue.selectedLine = ""
          thisVue.selectedLine = '.'+d[0].id

          // show the rank for a specific actor
          d3v4.selectAll(`.${d[0].id}_month_rank`)
              .style('display', 'block')

          //To detect if it the browser is NOT IE 6-11
          if (!(/*@cc_on!@*/false || !!document.documentMode)) {
            // add the ranking texts to the empty objects
            d3v4.selectAll('.rank_text')
                .attr('x', function(d) { return x(d.date); })
                .attr('y', function(d) { return yLeft(d.rank); })
                .text(function(d) { return "Rank: " + d.rank; })

            d3v4.selectAll('.score_text')
                .attr('x', function(d) { return x(d.date); })
                .attr('y', function(d) { return yLeft(d.rank); })
                .text(function(d) { if (typeof d.score == 'number') {return "Score: " + d.score.toPrecision(4); } else {return "Score: " + d.score;} })
                .style('transform', 'translate(0px,15px)')
                .style('-ms-transform', 'translate(0px,15px)')
          } else {
            // add the ranking texts to the empty objects
            d3v4.selectAll('.rank_text')
                .attr('x', function(d) { return x(d.date) + 5; })
                .attr('y', function(d) { return yLeft(d.rank) + 15; })
                .text(function(d) { return "Rank: " + d.rank; })

            d3v4.selectAll('.score_text')
                .attr('x', function(d) { return x(d.date)+ 5; })
                .attr('y', function(d) { return yLeft(d.rank)+ 30; })
                .text(function(d) { if (typeof d.score == 'number') {return "Score: " + d.score.toPrecision(4); } else {return "Score: " + d.score;} })
                .style('transform', 'translate(0px,15px)')
                .style('-ms-transform', 'translate(0px,15px)')
          }

          d3v4.selectAll(`${thisVue.selectedLine}_month_rank rect`)
              .style('fill', function(){
                if(d.isReference == true) {
                  return thisVue.color.reference
                }else{
                  return thisVue.color.click
                }
              })

          d3v4.selectAll(`.${d[0].id}-axis-background`)
              .style('fill', function(){
                if(d.isReference == true) {
                  return thisVue.color.reference
                }else{
                  return thisVue.color.click
                }
              })

          d3v4.selectAll(`.${d[0].id}-rank-text text`)
              .style('fill', 'white')

          thisVue.$emit('actor', d[0])
          thisVue.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component:"scores", metaData: d[0].id})
        })

    },
    clearGraph () {
      d3v4.select('.evolution-tt')
        .remove()

      d3v4.select(this.$refs.evolution)
        .selectAll('*')
        .remove()
    }
  },
  watch: {
    data (val) {
      if(val.length > 0){
        this.render(val)
      }
    },
  },
  async mounted () {
    var thisVue = this
    this.render(this.data)

    window.onresize = function(){
      thisVue.selectedLine=""
      thisVue.render(thisVue.data)
    }

    var filterObject = {}
    Object.assign(filterObject, this.$store.getters.exploreFilterObject, {score_type: this.scoreType})
    this.$router.replace({ query: filterObject })
  },
  beforeUnmount () {
    this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
  }
}

</script>

<style lang="scss">
@import "../../../scss/_variables.scss";

.landscape-chart__header {
  display: flex;
  align-items: center;
  padding: 10px;
}

.landscape-chart__header__title {
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: $font-stack-secondary;
  flex: 1;
}


.evolution-chart__graph-grid line {
  stroke: lightgrey;
  stroke-opacity: 0.7;
  shape-rendering: crispEdges;
}

.evolution-chart__graph-grid path {
  stroke-width: 0;
}

.evolution-chart__graph {
  border: 1px solid $color-borders;

  .info {
    > line {
      stroke: $color-borders;
      stroke-width: 1px;
      shape-rendering: crispEdges;
    }

    text {
      font-size: 18px;
      text-transform: uppercase;
      font-family: $font-stack-secondary;
    }
  }

  .quadrant-label {
    font-size: 18px;
    text-transform: uppercase;
    font-family: $font-stack-secondary;
  }

  .axis--y {
    fill: $color-borders;
    path {
      stroke: $color-borders;
    }
    text {
      font-size: 18px;
      text-transform: uppercase;
      font-family: $font-stack-secondary;
    }
  }

  .axis {
    path {
        stroke: rgba(0, 0, 0, 0) !important;
      }
  }

  .tick {
    text {
      font-size: 12px;
      font-family: $font-stack-secondary;
    }
    line{
      visibility:hidden;
    }
  }

}
.tick:hover {
    color:$color-alternative-one;
  }

path.rank-line {
  fill: none;
  shape-rendering: geometricPrecision;
}

path.rank-line:hover {
  stroke-width:5px !important;
  stroke: $color-alternative-one !important;
}

.rank-line__selected {
  stroke-width:5px !important;
  stroke: $color-alternative-two !important;
}

.points > text {
  fill: $color-text-grey;
  font-family: $font-stack-secondary;
}

/*.axis--x line {
  visibility: visible !important;
  stroke-width:25%;
  stroke: lightgrey;
  stroke-opacity: 0.15;
}*/

.y-rank-text-left, .y-rank-text-right, path.rank-line {
  cursor:pointer
}
</style>
