<template>
  <div>
    <div class="fixed-heading">
      <ds-button
        class="side-panel__header__close-button"
        icon="remove"
        variant="minimal"
        @click="hidePreview"
      />
      <div class="card__general side-panel__header side-panel__actor-header">
        <div class="card__logo">
          <avatar :src="user.profile_url" :alt="user.name" variant="border"></avatar>
        </div>
        <div class="card__title side-panel__card__title" v-if="user.name">{{ user.name }}</div>
      </div>
      <ds-button
        variant="minimal"
        icon="trash"
        size="extra-small"
        @click="showDeleteConfirmationModal(user)"
        :disabled="! user.id"
        class="side-panel__edit-button"
        v-if="this.isOwner && allowDelete"
      />
    </div>
    <div class="scrollable side-panel__scrollable__content">
      <div class="side-panel__company-section side-panel__contact-section" v-if="! user.verified" style="text-align: left; font-size: 0.9em;">
        <div class="pre-line user__panel--gray-info" style="margin-bottom: 0.1rem;" v-if="user.created_at || user.verification_email_sent_at">
          <icon name="information-outline" size="14"/>&nbsp;&nbsp;A confirmation email was sent on {{ user.verification_email_sent_at || user.created_at }}
        </div>
        <div class="pre-line">Resend the confirmation email &nbsp;<ds-button icon="email-outline" size="small" iconSize="14" @click="showUserEditModal('resend-confirmation')"/>
        </div>
      </div>
      <div class="side-panel__company-section side-panel__contact-section">
        <table class="table table--minimal table--side-panel">
          <tr>
            <td v-if="user.bio">
              <span class="side-panel__company-info__medium-title side-panel__company-info_text">
                Bio:
              </span>
              <div>{{ user.bio }}</div>
            </td>
          </tr>
        </table>
        <table class="table table--minimal table--side-panel">
          <tbody>
          <tr v-if="motivations.length > 0">
            <td style="width: 40%;">
                <span class="side-panel__company-info__medium-title side-panel__company-info_text">
                  Motivation:
                </span>
            </td>
            <td>
              <ul>
                <li v-for="motivation in motivations" style="list-style: none;">
                  <p>{{ motivation.name }}</p>
                </li>
              </ul>
            </td>
          </tr>
          <tr v-if="expertises.length > 0">
            <td style="width: 40%;">
                <span class="side-panel__company-info__medium-title side-panel__company-info_text">
                  Expertise:
                </span>
            </td>
            <td>
              <ul>
                <li v-for="expertise in user.expertise" style="list-style: none;">
                  <p>{{ expertise.name }}</p>
                </li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="side-panel__company-section side-panel__contact-section">
        <table class="table table--minimal table--side-panel">
          <tbody>
          <tr v-for="userInfo in generalUserInfo">
            <td style="width: 40%;">
                <span class="side-panel__company-info__medium-title side-panel__company-info_text">
                  {{ userInfo.property }}
                </span>
            </td>
            <td>
              <template v-if="userInfo.property == 'claimed'">
                <ul>
                  <li v-for="claim in userInfo.value" style="list-style: none;">
                    <a @click.stop="openActorSidePanel(claim.id)" style="cursor: pointer;">{{ claim.name }} ({{ toDateString(claim.createdAt) }})</a>
                  </li>
                </ul>
              </template>
              <template v-else-if="userInfo.property == 'spotting areas'">
                <ul>
                  <li v-for="spottingArea in userInfo.value" style="list-style: none;">
                    <router-link :to="'/spotting-areas/' + spottingArea.id" style="cursor: pointer;">{{ spottingArea.name }}</router-link>
                    ({{ spottingArea.role }})
                  </li>
                </ul>
              </template>
              <template v-else-if="userInfo.property == 'email'"><a :href="'mailto:' + userInfo.value" target="_blank" rel="noopener noreferrer">{{ userInfo.value }}</a></template>
              <template v-else>{{ userInfo.value }}</template>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div v-if="communities.length > 0" class="side-panel__company-section side-panel__community-section">
        <table class="table table--minimal table--side-panel">
          <tbody>
          <tr>
            <td style="width: 40%;">
                <span class="side-panel__company-info__medium-title side-panel__community-info_text">
                  Communities
                </span>
            </td>
            <td>
              <ul>
                <li v-for="community in communities">
                  <template v-if="community.value">
                    {{ community.name }}
                    <icon style="position: absolute;" class="side-panel__community-remove-icon" @click="openRemoveUserFromCommunityModal(community.value)" size="18" name="remove"/>
                  </template>
                </li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="side-panel__company-section side-panel__contact-section">
        <table class="table table--minimal table--side-panel">
          <tbody>
          <tr v-for="userInfo in otherUserInfo">
            <td style="width: 40%;">
                <span class="side-panel__company-info__medium-title side-panel__company-info_text">
                  {{ userInfo.property }}
                </span>
            </td>
            <td>
              <template v-if="userInfo.value">{{ userInfo.value }}</template>
            </td>
          </tr>
          </tbody>
        </table>
        <table v-if="user.event_registrations && user.event_registrations.length > 0" class="table table--minimal table--side-panel">
          <tbody>
          <tr>
            <td style="width: 40%;">
                <span class="side-panel__company-info__medium-title side-panel__community-info_text">
                  Registered Events
                </span>
            </td>
            <td>
              <ul>
                <li v-for="event in user.event_registrations">
                  <template v-if="event.notification_id">
                    <a :href="'/events/'+ (event.slug || event.notification_id)">{{ event.title }}</a>
                  </template>
                  <template v-else>
                    {{ event.title }}
                  </template>
                </li>
              </ul>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="side-panel__company-section side-panel__contact-section" v-if="userActivities && Object.keys(userActivities).length > 0">
        <h4 style="float: left;">Latest 50 Activities</h4>
        <table class="table table--minimal">
          <tbody>
          <tr v-for="(values, date) in userActivities" class="side-panel__activity-table-row">
            <td>
                <span class="side-panel__company-info__medium-title side-panel__company-info_text">
                  {{ date }}
                </span>
            </td>
            <td>
                <span>
                <ul class="side-panel__activity-list">
                  <li v-for="value in values" class="side-panel__activity-list-item">
                    {{ value.description }}
                  </li>
                </ul>
              </span>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
  import Avatar from '../Avatar/Avatar.vue'
  import Card from '../../components/Card/Card.vue'

  import { toDatetimeString, toOnlyMonthString } from '../../util/date'
  import { humanizeUserActivity } from '../../util/user-analytics.js'

  import { fetchUserDetail } from '../../api/config.js'
  import { fetchPublishedPortfolios } from '../../api/portfolios'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'
  import { toDateString } from '../../util/date.js'
  import { getCommunities } from '../../api/user'
  import { removeMember } from '../../api/communities'

  export default {
    name: 'user-panel',
    data () {
      return {
        user: {
          'id': null,
          'name': '',
          'email': '',
        },
        motivations: [],
        expertises: [],
        visitorPortfolios: [],
        communities: [],
      }
    },
    props: {
      userId: {
        default: false,
      },
      isInvitation: {
        type: Boolean,
        default: false,
      },
      allowDelete: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      isOwner () {
        return this.$store.getters.isOwner
      },
      userRole () {
        if (!window.ROLES) {
          return this.user.role
        }

        return window.ROLES[this.user.role]
      },
      generalUserInfo () {
        var userInfo = []

        // Base information
        userInfo.push(
          {
            property: 'email',
            value: this.user.email,
          },
        )

        // Claims that the user has made
        if (this.user.claims && this.user.claims.length > 0) {
          var claims = []

          this.user.claims.forEach(item => {
            claims.push({
              id: item.id,
              name: item.name,
              createdAt: item.created_at,
            })
          })

          if (claims.length > 0) {
            userInfo.push({
              property: 'claimed',
              value: claims,
            })
          }
        }

        userInfo.push(
          {
            property: 'department',
            value: this.user.department,
          },
          {
            property: 'experience level',
            value: this.user.function,
          },
        )

        if (this.$store.getters.isI18nEnabled) {
          userInfo.push({
            property: 'language',
            value: this.user.language,
          })
        }

        // Only show Spotting areas if user is a portfolio member
        if (this.user.role === 'portfolio_member' && this.user.spotting_areas && this.user.spotting_areas.length > 0) {
          userInfo.push({
            property: 'spotting areas',
            value: this.user.spotting_areas,
          })
        }

        return userInfo.filter(info => info.value)
      },
      otherUserInfo () {
        var userInfo = []

        var joinedAt = this.user.joined_at

        if (joinedAt) {
          joinedAt = new Date(joinedAt)
          joinedAt = toDatetimeString(joinedAt)
        }

        var lastActivity = this.user.last_activity

        if (lastActivity) {
          lastActivity = new Date(lastActivity)
          lastActivity = toDatetimeString(lastActivity)
        }

        userInfo.push(
          {
            property: 'role',
            value: this.userRole,
          },
          {
            property: 'joined on',
            value: joinedAt,
          },
          {
            property: 'last login',
            value: lastActivity,
          },
          {
            property: 'weekly digest',
            value: this.user.weeklyDigest ? 'subscribed' : 'not subscribed',
          },
          {
            property: 'subscription',
            value: this.user.package,
          },
          {
            property: 'subscription status',
            value: this.user.is_package_active,
          },
        )

        return userInfo.filter(info => info.value)
      },
      userActivities () {
        var activities = {}

        if (!this.user.activities || this.user.activities.length == 0) {
          return activities
        }

        Object.keys(this.user.activities).forEach(key => {
          var values = this.user.activities[key]

          activities[key] = []

          if (!values && values.length == 0) {
            return
          }

          activities[key] = values.map(activity => {
            activity.description = this.stringifyUserActivity(activity)
            return activity
          })

          activities[key].sort((a, b) => (a.description > b.description) ? 1 : -1)
        })

        return activities
      },
    },
    methods: {
      toDateString,
      humanizeUserActivity,
      toOnlyMonthString,
      hidePreview (evt) {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      fetchUser () {
        fetchUserDetail(this.userId)
          .then(data => {
            this.user = data
            this.motivations = data.motivation
            this.expertises = data.expertise
            this.communities = data.communities
            this.spottingAreas = data.spottingAreas
          })
      },
      openActorSidePanel (actorId) {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'scores', metaData: actorId })
      },
      showUserEditModal (action) {
        var user = this.user

        this.$store.commit('UI/SET_MODAL_CONTEXT', { user, action })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.USER_EDIT)
      },
      showDeleteConfirmationModal () {
        const user = this.user
        const action = 'remove'

        let promise = Promise.resolve(user)

        if (action === 'remove') {
          promise = fetchUserDetail(user.id)
        }

        promise.then(user => {
          this.$store.commit('UI/SET_MODAL_CONTEXT', { user: user, action })
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.REMOVE_USER_FROM_ECOSYSTEM)
        }).catch(() => {
          this.$store.commit('UI/SET_MODAL_CONTEXT', { user, action })
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.REMOVE_USER_FROM_ECOSYSTEM)
        })
      },
      stringifyUserActivity (activity) {
        var action = 'Viewed '

        if (activity.properties.method && activity.properties.method != 'GET') {
          // Non "view" actions should be getting their description from the humanizeUserActivity
          action = ''
        }

        return action + humanizeUserActivity(activity.description) + ' (x' + activity.count + ')'
      },
      openRemoveUserFromCommunityModal (communityId) {
        removeMember({ communityId: communityId, userId: this.user.id })
          .then(response => {
            getCommunities(this.user.id)
              .then(response => {
                this.communities = response
              })
          })
      },
    },
    mounted () {
      this.fetchUser(this.userId)

      if (this.$store.getters.isPublisherEnabled) {
        fetchPublishedPortfolios('actor')
          .then(response => {
            this.visitorPortfolios = response
          })
      }

      this.$bus.on('userUpdated', () => this.fetchUser(this.userId))
      this.$bus.on('userDeleted', () => this.hidePreview())
    },
    beforeUnmount () {
      this.$bus.off('userUpdated')
      this.$bus.off('userDeleted')
    },
    watch: {
      userId (val) {
        this.fetchUser(this.userId)
      },
    },
    components: {
      Avatar,
      Card,
    },
  }
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

  .user__panel--gray-info {
    color: $color-text-grey-light;
    font-style: italic;

    .fill-fg {
      fill: $color-text-grey-light;
    }
  }

  .side-panel__activity-table-row {
    td:first-child {
      width: 40%;
    }
  }

  .side-panel__activity-list {
    li {
      margin-top: -0.1rem !important;
    }
  }

  .side-panel__activity-list-item {
    list-style: none;
    height: 1rem;
    margin-bottom: 1px;
  }

  .side-panel__community-remove-icon {
    position: absolute;

    &:hover {
      cursor: pointer;
    }
  }
</style>
