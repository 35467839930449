<template>
  <div>
    <div class="concept-guide-scoring__container">
      <div class="concept-guide-scoring__left">
        <div class="concept-guide-scoring__header">
          <h2>Scoring for actors</h2>
          <p>Score actors based on certain properties.</p>
        </div>
        <div>
          <dropdown class="concept-guide-scoring__dropdown" :search="true" :options="addMetricOptions" placeholder="Select metric to add" @update:modelValue="handleAddMetric" />
          <div v-for="customScore in this.modelValue.customScores" @click="activeMetric = customScore.metric" class="concept-guide-scoring__metric" :class="{'concept-guide-scoring__metric--active': activeMetric === customScore.metric}">
            <form-group class="form-group__custom-scores">
              <input-range :label="humanize(customScore.metric)" :model-value="customScore.weight" @update:modelValue="setWeight(customScore, $event)" :step="5" style="display: inline-block; width: 75%;"/>
              <ds-button size="small" variant="outline" icon="trash" @click="handleRemoveMetric(customScore)" style="display: inline-block; float: right;" />
            </form-group>
          </div>
        </div>
      </div>
      <div class="concept-guide-scoring__right" :class="{'concept-guide-scoring__right--active': activeMetric !== null}">
        <div v-if="activeMetric !== null">
          <p>Select the parameters that should contribute to an actor's score.</p>
          <table class="table">
            <thead>
              <tr>
                <th style="width: 80%">Parameter</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="parameter in activeMetricParameters">
                <td style="vertical-align: middle; padding: 0;">
                  <label style="display: inline-block; width: 100%; padding: 6px 10px;">
                    <input type="checkbox" :checked="metricParameterEnabled[JSON.stringify(parameter.value)]" @input="handleToggleParameter(parameter.value)"/>
                    {{parameter.label}}
                  </label>
                </td>
              </tr>
              <tr v-if="activeMetricParameters.length === 0">
                <td style="vertical-align: middle;">No parameters available.</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="exploration-settings__button">
      <ds-button variant="secondary" label="Create search" @click="handleNextStep" v-if="modelValue.id === null"/>
      <ds-button label="Update search" @click="handleNextStep" v-else/>
      <ds-button label="Cancel" @click="handleCancel" v-if="withCancel"/>
    </div>
  </div>
</template>

<script>
  import Dropdown from '../Dropdown/Dropdown.vue'
  import InputRange from '../Slider/InputRange.vue'
  import MetricsMixin from '../../util/MetricsMixin'

  export default {
    mixins: [
      MetricsMixin,
    ],
    props: {
      modelValue: {
        type: Object,
      },
      withCancel: {
        type: Boolean
      },
    },
    emits: ['update:modelValue'],
    data() {
      return {
        activeMetric: null,
      }
    },
    computed: {
      activeMetricParameters() {
        return this.defaultMetricParameters(this.activeMetric)
      },
      activeCustomScore() {
        return this.modelValue.customScores.find(x => x.metric === this.activeMetric)
      },
      metricParameterEnabled() {
        if (!this.activeCustomScore) {
          return {}
        }

        const enabledParameters = this.activeCustomScore.parameters.map(x => JSON.stringify(x.value))
        const result = {}

        for (const parameter of this.activeMetricParameters) {
          result[JSON.stringify(parameter.value)] = enabledParameters.includes(JSON.stringify(parameter.value))
        }

        return result
      },
      addMetricOptions() {
        const configuredMetrics = this.modelValue.customScores.map(x => x.metric)
        return this.metrics.filter(v => !configuredMetrics.includes(v)).map(value => ({value, label: this.humanize(value)}))
      },
    },
    methods: {
      setWeight(customScore, weight) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          customScores: this.modelValue.customScores.map(cs => {
            if (cs !== customScore) {
              return cs
            } else {
              return {...cs, weight}
            }
          }),
        })
      },
      handleAddMetric(metric) {
        this.activeMetric = metric
        this.$emit('update:modelValue', {
          ...this.modelValue,
          customScores: [
            ...this.modelValue.customScores,
            {
              metric,
              weight: 50,
              parameters: [],
            },
          ],
        })
      },
      handleRemoveMetric(customScore) {
        this.$emit('update:modelValue', {
          ...this.modelValue,
          customScores: this.modelValue.customScores.filter(x => x !== customScore),
        })

        // Set activeMetric to null at the end of the event queue, otherwise the click on the score will overwrite null again
        setTimeout(() => {
          this.activeMetric = null
        }, 0)
      },
      handleToggleParameter(value) {
        const nextCustomScores = []

        for (const customScore of this.modelValue.customScores) {
          if (customScore.metric !== this.activeMetric) {
            nextCustomScores.push(customScore)
            continue
          }

          const nextParameters = []
          let foundParameter = false

          for (const parameter of customScore.parameters) {
            if (parameter.value !== value) {
              nextParameters.push(parameter)
            } else {
              foundParameter = true
            }
          }

          if (!foundParameter) {
            nextParameters.push({value, weight: 100})
          }

          nextCustomScores.push({...customScore, parameters: nextParameters})
        }

        this.$emit('update:modelValue', {...this.modelValue, customScores: nextCustomScores})
      },
      humanize (str) {
        return str && (str[0].toUpperCase() + str.replace(/_/g, ' ').slice(1))
      },
      handleNextStep() {
        this.$emit('next-step')
      },
      handleCancel() {
        this.$emit('cancel')
      },
    },
    components: {
      Dropdown,
      InputRange,
    },
  }
</script>

<style lang="scss" scoped>
  $highlight-color: #aaa;
  $highlight-background-color: #f6f6f6;

  .concept-guide-scoring__header {
    margin-bottom: 20px;
  }

  .concept-guide-scoring__container {
    display: flex;
  }

  .concept-guide-scoring__left {
    flex-shrink: 0;
    padding-right: 20px;
    width: 250px;
  }

  .concept-guide-scoring__right {
    padding-left: 20px;
    padding-bottom: 20px;
    flex-grow: 1;
    border-left: 1px solid #fff;
    border-bottom: 1px solid #fff;
  }

  .concept-guide-scoring__right--active {
    border-color: $highlight-color;
  }

  .concept-guide-scoring__metric {
    border: 1px solid transparent;
    margin-top: 10px;
    position: relative;
    z-index: 1;
    margin-right: -21px;
    padding-right: 21px;
    cursor: pointer;
    padding-left: 10px;

    &:hover {
      background: $highlight-background-color;
      border-right-color: $highlight-color;
    }
  }

  .concept-guide-scoring__metric--active {
    border-color: $highlight-color;
    border-right-color: $highlight-background-color;
    background: $highlight-background-color;

    &:hover {
      border-right-color: $highlight-background-color;
    }
  }

  .exploration-settings__button {
    margin-top: 20px;
  }
</style>

<style lang="scss">
  .concept-guide-scoring__metric .form-group__header {
    pointer-events: none;
  }
</style>
