<template>
  <div class="explore-frame product-gallery">
    <div class="explore-content">
      <div class="explore__control-bar">
        <div>
          <h2 class="explore__control-bar__title">
            {{ title }} GALLERY
          </h2>
          <p class="guide_text">Explore {{ productsLabel }} by clicking the top right filter button and enter keywords or use the drop downs to filter the directory of
            {{ productsLabel }}.</p>
        </div>
        <div class="explore-content__filter-button-container" v-if="config && config.filterControls && config.filterControls.length > 0">
          <div class="explore-content__filter-indicator" v-if="filtersActive"></div>
          <ds-button variant="minimal" size="small" label="Filter" icon="filter-box" class="explore-content__filter-button"
                     :class="{ 'explore-content__filter-button-active' : this.$store.state.filters.filter.visible }" @click="toggleFilter"/>
        </div>
        <badge :name="total + ' Actors'" variant="primary" class="score-overview__actor-couter"/>
      </div>

      <top-filters :only-show-product-filters="true"/>

      <div class="explore-content__panes" ref="content_panes">
        <!--<div style="position: absolute">
          <legend-dropdown style="position: relative; z-index: 107;" tooltipText="" :style="legendBoxCoordinatesStyling" :draggable="onDragged"/>
        </div>-->
        <!-- We must have the 'presentationModeData.length > 0' in order for the loop to work -->
        <div class="explore-pane explore-pane--right explore-pane--shown">
          <div class="explore-pane__body">
            <gallery-view :actors="listData.data" @detail="goToActorDetail"/>
          </div>
          <div class="explore-pane__footer">
            <page-items :paging="listData.paging"></page-items>
            <a v-if="$store.getters.isMember || $store.getters.isPortfolioMember"
               class="column-toggle a"
               @click="show.columns = !show.columns">
              Columns
            </a>
            <pagination :paging="listData.paging"/>
          </div>
        </div>
      </div>
    </div>

    <modal title="VISIBLE COLUMNS"
           v-if="show.columns"
           close-on-blur
           closeable
           @close="show.columns = false">
      <template v-slot:body>
        <div>
          <p class="guide_text">Drag the properties you like to display from the left to the right list. The order of the properties in the list of “Visible columns” defines the
          order of the
            columns.</p>
          <draggable-column-picker v-model="userColumns" :options="availableColumnOptions"/>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
  // require styles
  import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../store/modules/actors'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../store/modules/ui'
  import {
    MUTATION_TYPES as FILTERS_MUTATION_TYPES,
    MUTATION_TYPES as FILTER_MUTATION_TYPES,
  } from '../store/modules/filters'

  import DraggableColumnPicker from '../components/Form/DraggableColumnPicker.vue'
  import TopFilters from '../components/Filters/TopFilters.vue'
  import GalleryView from '../components/GalleryView/GalleryView.vue'
  import Keyword from '../components/Keyword/Keyword.vue'
  import Modal from '../components/Modals/Modal.vue'
  import PageItems from '../components/PageItems/PageItems.vue'
  import Pagination from '../components/Pagination/Pagination.vue'
  import Badge from '../components/Badge/Badge.vue'
  import FormGroup from '../components/Form/FormGroup.vue'
  import Checkbox from '../components/Form/Checkbox.vue'
  import LegendDropdown from '../components/Filters/LegendDropdown.vue'

  import { viewListOptions } from '../constants/config'
  import { getReportFieldByReportIdentifier, inert } from '../util/helpers'

  import FiltersMixin from '../util/FiltersMixin'
  import TranslationsMixin from '../util/TranslationsMixin.js'
  import HoverOverlayButton from '../components/Overlay/HoverOverlayButton.vue'
  import MODAL_IDS from '../constants/modal-ids.js'

  export default {
    name: 'ProductGallery',
    data () {
      return {
        show: {
          columns: false,
        },
        showFilters: false,
        legendBoxDefaultPosition: {},
      }
    },
    computed: {
      filterableRelationships () {
        return this.$store.getters.filterableRelationships
      },
      config () {
        return this.$store.state.config
      },
      reportFilters () {
        return this.$store.state.filters.reportFilters
      },
      keywords () {
        return this.$store.state.filters.keywords
      },
      isLoading () {
        return this.$store.state.actors.loading
      },
      isEcosystemMember () {
        return this.$store.getters.isActor
      },
      listData () {
        return this.$store.state.actors.productData
      },
      total () {
        return this.listData.total || 0
      },
      filters () {
        return this.$store.state.filters
      },
      legendBoxProperties () {
        // We check first if there is values in the state belonging to the 'explore'
        // Otherwise, in the case of the user refreshing the browser, we fetch the value saved in the user settings
        // Simply fetching directly from the state wont work as the state can contain objects from other legend boxes
        // resulting in the reset of the position of the other legend boxes, so we search only for the values for the 'explore'
        var legendPropertiesArray = []
        if (this.$store.getters.legendBoxProperties.length > 0) {
          this.$store.getters.legendBoxProperties.forEach((item, index) => {
            if (item.name == 'explore') {
              legendPropertiesArray.push(item)
            }
          })
        }
        return legendPropertiesArray.length > 0 ? legendPropertiesArray : this.$store.getters.userSettings.legendBoxProperties !== undefined ? this.$store.getters.userSettings.legendBoxProperties : []
      },
      exploreLegendBoxProperties () {
        return this.legendBoxProperties && this.legendBoxProperties.filter(legend => legend.name == 'explore')
      },
      legendBoxCoordinatesStyling () {
        // This will set the left and top values for the legend box
        // If there is a value in the state for the coordinates then we apply that value to the styling
        // otherwhise we set the fallback value for the style
        var values = {
          left: '1em',
          top: '1em',
        }
        if (this.exploreLegendBoxProperties.length > 0) {
          // We check first if the coordinates are out of the visible bounds and if so we return values that enable the legend box to be placed in the visible area of the screen
          var checkedCoordinates = this.checkLegendBoxCoordinates(this.exploreLegendBoxProperties[0].coordinates)
          values.left = checkedCoordinates.x + 'px'
          values.top = checkedCoordinates.y + 'px'
        } else if (this.legendBoxDefaultPosition && this.legendBoxDefaultPosition.x) {
          // Setting the horizontal default position
          values.left = this.legendBoxDefaultPosition.x
        } else if (this.legendBoxDefaultPosition && this.legendBoxDefaultPosition.y) {
          // Setting the vertical default position
          values.top = this.legendBoxDefaultPosition.y
        }

        return `left: ${values.left}; top: ${values.top}`
      },
      title () {
        return this.productLabel.toUpperCase()
      },
      userColumns: {
        get () {
          const blackListedColumns = ['lastFundingRaised', 'is_zombie', 'possible_duplicate']

          var columns = (this.$store.getters.userSettings.viewList || '')
            .split(',')
            .filter(column => !blackListedColumns.includes(column))
            .filter(Boolean)

          // Filter out report fields if knowledgeSharing is not enabled
          if (!this.$store.getters.reportsEnabled) {
            columns = columns
              .filter(column => !column.startsWith('report_field_'))
              .filter(Boolean)
          } else {
            // Make sure no old report fields are in the columns
            columns = columns
              .filter(function (column) {
                if (!column.startsWith('report_field_')) {
                  return true
                }

                var reportLabel = getReportFieldByReportIdentifier(column)
                var reportFieldExists = reportLabel && reportLabel.label && reportLabel.label.length > 0

                return reportFieldExists
              })
              .filter(Boolean)
          }

          var result = columns.length ? columns : this.config.viewList ? this.config.viewList : viewListOptions

          return result
        },
        set (v) {
          this.$store.commit('USER/STORE_SETTINGS', { viewList: v.join(',') })
        },
      },
      availableColumnOptions () {
        var options = viewListOptions

        if (!this.$store.getters.hasCustomScores) {
          options = options.filter(c => c != 'custom_score')
        }

        if (this.$store.getters.isOwner) {
          options = options.concat(this.filterableRelationships)
        }

        // If the we're not in the funding list dashboard, we filter out all possible columns that only belong to the funding list dashboard
        var fundingDashboardColumns = ['total_investment', 'funding_date', 'funding_type', 'funding_amount']

        options = options.filter(column => {
          return !fundingDashboardColumns.includes(column.name)
        })

        return options
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      reportFiltersActive () {
        if (!this.$store.getters.reportSettings || this.$store.getters.reportSettings.length === 0 || !this.$store.state.filters.reportFilters) {
          return false
        }

        var reportFilterEnabled = false

        for (var key in this.$store.state.filters.reportFilters) {
          if (this.$store.state.filters.reportFilters[key] && this.$store.state.filters.reportFilters[key].length > 0) {
            reportFilterEnabled = true
          }
        }

        return reportFilterEnabled
      },
      filtersActive () {
        return (this.keywords.length > 0 && this.keywords.find((f) => f.facet !== 'actor_type')) ||
        this.$store.state.filters.employeeRange.employeeLowerBound ||
        this.$store.state.filters.employeeRange.employeeUpperBound ||
        this.$store.state.filters.totalFunding.minTotalFunding ||
        this.$store.state.filters.totalFunding.maxTotalFunding ||
        this.$store.state.filters.patentCountRange.patentCountMin ||
        this.$store.state.filters.patentCountRange.patentCountMax ||
        this.$store.state.filters.relationshipCountRange.relationshipCountMin ||
        this.$store.state.filters.relationshipCountRange.relationshipCountMax ||
        this.$store.state.filters.revenueRange.revenueMin ||
        this.$store.state.filters.revenueRange.revenueMax ||
        this.$store.state.filters.dateRanges.foundingDateEnd ||
        this.$store.state.filters.dateRanges.foundingDateStart ||
        this.$store.state.filters.dateRanges.fundingDateEnd ||
        this.$store.state.filters.dateRanges.fundingDateStart ||
        this.$store.state.filters.dateRanges.createdAtStart ||
        this.$store.state.filters.dateRanges.createdAtEnd ||
        this.$store.state.filters.dateRanges.updatedAtStart ||
        this.$store.state.filters.dateRanges.updatedAtEnd ||
        this.$store.state.filters.customScoreRange.customScoreMin ||
        this.$store.state.filters.customScoreRange.customScoreMax ||
        this.$store.state.filters.portfolio ||
        this.reportFiltersActive
      },
    },
    methods: {
      getData () {
        var listFilters = inert(this.$store.getters.listFilterObject)

        // TODO: check with Tim if this is the way to filter out non-product filters
        // Filter out report fields that don't belong to a product enabled report template
        var filterableReportFieldIds = []

        if (this.filterableReportFields) {
          filterableReportFieldIds = this.filterableReportFields.map(field => 'report_field_' + field.id)
        }

        var validProductListFilters = {}

        Object.keys(listFilters).forEach(listFilter => {
          if (!listFilter.startsWith('report_field_') || filterableReportFieldIds.includes(listFilter)) {
            validProductListFilters[listFilter] = listFilters[listFilter]
          }
        })

        this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_PRODUCTS_LIST, Object.assign({}, { actor_type: 'Product' }, validProductListFilters))
      },
      goToActorDetail (actor) {
        if (!this.$store.getters.views.includes('actors/detail')) {
          this.$store.dispatch('ACTORS/FETCH_ACTOR_FOR_PREVIEW', actor)
            .then(actor => {
              this.$refs.mapPane && this.$refs.mapPane.forceVisibleMarker(actor)
            })
        } else if (this.$store.getters.isMember) {
          this.$router.push('/actors/' + actor.id)
        } else {
          this.openSidepanel(actor)
        }
      },
      toggleFilter () {
        if (this.$store.state.filters.filter.visible) {
          this.$store.commit(FILTER_MUTATION_TYPES.TOGGLE_FILTER, false)
        } else {
          this.$store.commit(FILTER_MUTATION_TYPES.TOGGLE_FILTER, true)
        }
      },
      openSidepanel (actor) {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'scores', metaData: actor.id })
      },
      checkLegendBoxCoordinates (coordinates) {
        var copyOfCoordinates = Object.assign({}, coordinates)
        // NOTE: Trying to solve the issue regarding the legend box positioning based on different screen sizes programaticaly wasn't working properly was costing too much time to fix. As such we are using hard coded values in order to properly position the legend box if it happens to be placed out of visible bounds
        var legendBoxDimenstions = {
          height: 40,
          width: 250,
        }
        var distanceTopBar = 185
        var topBarHeight = 44
        var totalDistanceToTop = distanceTopBar + topBarHeight
        var sideNavigationWidth = 40

        if (copyOfCoordinates.x >= window.innerWidth - sideNavigationWidth * 2) {
          copyOfCoordinates.x = window.innerWidth - legendBoxDimenstions.width - sideNavigationWidth * 2
        }
        if (copyOfCoordinates.x < 0) {
          copyOfCoordinates.x = sideNavigationWidth
        }
        if (copyOfCoordinates.y >= window.innerHeight - legendBoxDimenstions.height - totalDistanceToTop) {
          copyOfCoordinates.y = window.innerHeight - legendBoxDimenstions.height - totalDistanceToTop
        }
        if (copyOfCoordinates.y < legendBoxDimenstions.height - totalDistanceToTop) {
          copyOfCoordinates.y = -totalDistanceToTop + legendBoxDimenstions.height
        }

        return copyOfCoordinates
      },
      setLegendBoxDefaultPosition () {
        this.legendBoxDefaultPosition = {
          x: this.$refs.content_panes ? this.$refs.content_panes.clientWidth - 360 + 'px' : 0,
          y: '-3em',
        }
      },
      setLegendBoxCoordinates (coordinates) {
        this.$store.commit(UI_MUTATION_TYPES.SET_LEGEND_COORDINATES, { explore: coordinates })
      },
      saveLegendBoxCoordinatesToUserSettings (coordinates) {
        // We only save the coordinates once the user has dropped the legend box.
        var legendBoxUSerSettingsCopy
        if (this.$store.getters.userSettings.legendBoxProperties == undefined) {
          this.$store.commit('USER/STORE_SETTINGS', { legendBoxProperties: [{ name: 'explore', coordinates: coordinates }] })
        } else {
          legendBoxUSerSettingsCopy = this.$store.getters.userSettings.legendBoxProperties.slice()
          legendBoxUSerSettingsCopy = legendBoxUSerSettingsCopy.filter(legendBox => legendBox.name !== 'explore')
          legendBoxUSerSettingsCopy.push({ name: 'explore', coordinates: coordinates })
          this.$store.commit('USER/STORE_SETTINGS', { legendBoxProperties: legendBoxUSerSettingsCopy })
        }
      },
      onDragged ({ el, deltaX, deltaY, offsetX, offsetY, clientX, clientY, first, last }) {
        /*
          Definition
          el: The target element on which the diretive binds.
          deltaX: The change of the pointer (mouse/touch)'s x coordination from the last position.
          deltaY: The change of the pointer (mouse/touch)'s y coordination from the last position.
          offsetX: The change of the pointer (mouse/touch)'s x coordination from the starting position.
          offsetY: The change of the pointer (mouse/touch)'s y coordination from the starting position.
          clientX: Current x coordination of the pointer (mouse/touch).
          clientY: Current y coordination of the pointer (mouse/touch).
          first: A boolean to indicate whether it is the first move of the drag. (drag starts here).
          last: A boolean to indicate whether it is the last move of the drag. (drag ends here).
        */

        // The area ("explore-frame") in which the dragged object is contained.
        var boundingArea = this.$el.getBoundingClientRect()

        // The dragged object
        var draggedObjectArea = el.getBoundingClientRect()

        var insideLeftBorder = draggedObjectArea.left > boundingArea.left
        var insideRightBorder = draggedObjectArea.right < boundingArea.right
        var insideTopBorder = draggedObjectArea.top > boundingArea.top
        var insideBottomBorder = draggedObjectArea.bottom < boundingArea.bottom

        var l = +window.getComputedStyle(el)['left'].slice(0, -2) || 0
        var t = +window.getComputedStyle(el)['top'].slice(0, -2) || 0
        var coordinates = {
          x: this.exploreLegendBoxProperties.length > 0 ? this.exploreLegendBoxProperties[0].coordinates.x : 0,
          y: this.exploreLegendBoxProperties.length > 0 ? this.exploreLegendBoxProperties[0].coordinates.y : 0,
        }

        if (first) {
          this.dragged = true
          return
        }
        if (last) {
          this.dragged = false
          this.saveLegendBoxCoordinatesToUserSettings(coordinates)
          return
        }

        if (!insideLeftBorder) {
          coordinates.x = l + deltaX + 5
          this.setLegendBoxCoordinates(coordinates)
          return
        }
        if (!insideRightBorder) {
          coordinates.x = l + deltaX - 5
          this.setLegendBoxCoordinates(coordinates)
          return
        }
        if (!insideTopBorder) {
          coordinates.y = t + deltaY + 5
          this.setLegendBoxCoordinates(coordinates)
          return
        }
        if (!insideBottomBorder) {
          coordinates.y = t + deltaY - 5
          this.setLegendBoxCoordinates(coordinates)
          return
        }

        coordinates.x = l + deltaX
        coordinates.y = t + deltaY

        this.setLegendBoxCoordinates(coordinates)
      },
    },
    created () {
      this.$store.commit(FILTER_MUTATION_TYPES.REFRESH_KEYWORDS)
      this.getData()
    },
    mounted () {
      this.$bus.on('resize300', () => {
        this.setLegendBoxDefaultPosition()
      })

      this.setLegendBoxDefaultPosition()

      // Only for non-owners do we swap the legend to the product legend, if there's one available
      var productLegendProperty = this.$store.getters.productLegendProperty

      this.$store.commit(FILTERS_MUTATION_TYPES.SET_LEGEND_PROPERTY, { property: productLegendProperty, items: this.getAvailableLegendItems(productLegendProperty) })
    },
    beforeUnmount () {
      // Swap back to the default legend property
      var legendProperty = this.$store.getters.defaultLegendProperty

      this.$store.commit(FILTERS_MUTATION_TYPES.SET_LEGEND_PROPERTY, { property: legendProperty, items: this.getAvailableLegendItems(legendProperty) })
    },
    watch: {
      filters: {
        deep: true,
        handler (val) {
          this.getData()
        },
      },
    },
    mixins: [FiltersMixin, TranslationsMixin],
    components: {
      DraggableColumnPicker,
      TopFilters,
      GalleryView,
      Keyword,
      Modal,
      PageItems,
      Pagination,
      Badge,
      FormGroup,
      Checkbox,
      LegendDropdown,
      HoverOverlayButton,
    },
  }
</script>

<style lang="scss">
  a.column-toggle {
    padding: 0 1.5rem;
    font-size: .9em;
    line-height: 50px;
    color: inherit;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  .explore__control-bar__controls {
    .button .svg-icon + .button__label {
      padding-left: 24px;
    }
  }

  .product-gallery {
    .explore-content__filter-button-container {
      margin-left: auto;
    }
  }
</style>
