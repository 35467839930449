<template>
  <div id="ranking-div" class="ranking-chart">
    <svg ref="ranking" class="ranking-chart__graph"></svg>
  </div>
</template>

<script>
import * as d3v4 from 'd3'

import { inert, isIE11 } from '../../util/helpers'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'

const LEFT_INFO_WIDTH = 35
const LEFT_AXIS_WIDTH = 0
const BOTTOM_INFO_HEIGHT = 35
const BOTTOM_AXIS_HEIGHT = 25
const SPACE_FOR_NAMES = 0
const CHART_HORIZONTAL_ADJUSTMENT = -40 // to adjust the horizontal alignment of the chart and avoid clipping on the right bars on resize

/**
 * get the first scroll parent of an element
 * @param  {DOM} elm    the element which find scroll parent
 * @return {DOM}        the first scroll parent
 */
function getScrollParent (elm) {
  if (elm.tagName === 'BODY') {
    return window
  } else if (['scroll', 'auto'].indexOf(window.getComputedStyle(elm).overflowY) > -1) {
    return elm
  } else if (elm.hasAttribute('infinite-wrapper') || elm.hasAttribute('data-infinite-wrapper')) {
    return elm
  }
  return getScrollParent(elm.parentNode)
}

/**
 * get current distance from bottom
 * @param  {DOM}    scrollElm     scroll element
 * @param  {DOM}    infiniteElm   infinite element
 * @return {Number}     distance
 */
function getCurrentDistance (scrollElm, infiniteElm) {
  const infiniteElmOffsetTopFromBottom = infiniteElm.getBoundingClientRect().top
  const scrollElmOffsetTopFromBottom = scrollElm === window ? window.innerHeight : scrollElm.getBoundingClientRect().bottom
  return infiniteElmOffsetTopFromBottom - scrollElmOffsetTopFromBottom
}

export default {
  props: { data: {}, type: null, distance: {type: Number, default: 500}, loading: Boolean, complete: Boolean },
  computed: {
    bottomTitle () {
      if (this.type == 'digital_footprint') {
        return "Digital Footprint"
      } else if (this.type == 'business_size') {
        return "Business Size"
      }
    },
    rankData () {
      return this.$store.state.scores.ranking.data.datasets
    },
    portfolioName () {
      var portfolioName = this.$store.getters.activePortfolio.name

      if (portfolioName) {
        return portfolioName
      }

      return 'All'
    },
    referenceActor () {
      return this.rankData.data.find(a => a.isReference) || this.average || { name: 'N/A' }
    },
    referenceActorName () {
      return this.referenceActor.name
    },
    getCurrentDate () {
      function twoDigit(s) { return (s < 10) ? '0' + s : s; }
        // In order for the date to be read across all browsers, the date mus be seperated by "/" and not "-"
        var date;
        // In case of the date returning an empty value, the date will be now.
        if (this.rankData.data.length <= 0) {
          date = new Date()
          date.toISOString().split('-').join('/')
        } else {
          date = this.rankData.data[0].date.split('-').join('/')
        }
        const newDate = new Date(date)
        const changedDateFormat = [twoDigit(newDate.getMonth()+1), newDate.getFullYear().toString().substr(-2)].join('/');
        return changedDateFormat
    }
  },
  methods: {
   render (data) {
      var newData = inert(data)
      this.clearGraph()

      // set the dimensions and margins of the graph
      this.outerWidth = Math.floor(this.$el.offsetWidth)
      this.outerHeight = Math.floor(window.innerHeight - 230)
      this.companyCount = newData.data.length

      const margin = {
        top: 14,
        right: 0,
        bottom: BOTTOM_AXIS_HEIGHT + BOTTOM_INFO_HEIGHT,
        left: LEFT_AXIS_WIDTH + LEFT_INFO_WIDTH + SPACE_FOR_NAMES
      }

      const width = this.outerWidth - margin.left - margin.right - SPACE_FOR_NAMES + CHART_HORIZONTAL_ADJUSTMENT
      const height = this.outerHeight - margin.top - margin.bottom

      // select the element to aply the graph
      const root = d3v4.select(this.$refs.ranking)
        .attr('width', this.outerWidth)
        .attr('height', this.outerHeight)

      // define the line
      var valueline = d3v4.line()
        .x(function(d) { return x(d.name); })
        .y(function(d) { return y(d.score); });

      // create the on hover tooltip div
      var hoverTooltip = d3v4.select("body").append("div")
        .attr("class", "ranking-chart__d3-tip-hover")
        .style("opacity", 0);

      // create the on click tooltip div
      var clickTooltip = d3v4.select("body").append("div")
        .attr("class", "ranking-chart__d3-tip-click")
        .style("opacity", 0);

      // create the bets growth tooltip div
      var bestGrowthTooltip = d3v4.select("body").append("div")
        .attr("class", "ranking-chart__d3-tip-best-growth")
        .style("opacity", 0);

      // create the teamleader tooltip div
      var referenceActorTooltip = d3v4.select("body").append("div")
        .attr("class", "ranking-chart__d3-tip-teamleader")
        .style("opacity", 0);

      // create the svg and add its properties
      var svg = root
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', width - 0)
        .attr('height', height - 0)

      var x = d3v4.scaleBand().rangeRound([0, width]).padding(0.1),
          y = d3v4.scaleLinear().rangeRound([height, 0]);

      var g = svg.append("g")
          .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      x.domain(newData.data.map(function(d) {return  d.name;  }));
      y.domain([0, d3v4.max(newData.data, function(d) {return d.score;  })]);

      // add the x axis
      g.append("g")
        .attr("class", "ranking-chart__x-axis")
        .attr("transform", "translate(0," + height + ")")
        .call(d3v4.axisBottom(x));

      // add the y axis
      g.append("g")
        .attr("class", "ranking-chart__graph-grid")
        .call(d3v4.axisLeft(y).ticks(10).tickSize(-width))
        .append("text")
        .attr("transform", "rotate(-90)")
        .attr("y", 6)
        .attr("dy", "0.71em")
        .attr("text-anchor", "end")

      // add the bars in the graph
      var thisVue = this; // to get the scope of the vue component
      g.selectAll(".bar")
        .data(newData.data)
        .enter().append("rect")
          .attr("class", function(d){
            // if thisVue.companyCount > 300 we remove the stroke from the bars when the screen width is < 800px to prevent a problem related to having too many actors in the chart
            // and causing the bars to be completely colored white because of the stroke around each bar
            if (d.hasSignificantChange == true) {
              if (thisVue.companyCount > 300) {
                return "bar ranking-chart__bar-significant bar-stroke"
              }
              return "bar ranking-chart__bar-significant"
            }else if(d.isReference == true) {
              if (thisVue.companyCount > 300) {
                return "bar ranking-chart__bar-reference bar-stroke"
              }
              return "bar ranking-chart__bar-reference"
            }else {
              if (thisVue.companyCount > 300) {
                return "bar ranking-chart__bar bar-stroke"
              }
              return "bar ranking-chart__bar"
            }
          })
          .attr("x", function(d) {return x(d.name);})
          .attr("y", function(d) {return y(d.score);})
          .attr("width", x.bandwidth())
          .attr("height", function(d) {return height - y(d.score) + 8; })
          .on("mouseover", function(d) {
            var growthSymbol = '+';

            if (d.growth < 0) {
              var growthSymbol = '';
            }

            var toolTipHtml =
              '<div class="tooltip-heading">' + d.name + "</div>" +
              '<div class="tooltip-body">Mentions: ' + d.mentions +'</div>' +
              '<div class="tooltip-body">Current Score: ' + d.score.toPrecision(4)  + '  ' + '</div>' +
              '<div class="tooltip-body">Previous Score: ' + d.previous_score.toPrecision(4)  + '</div>' +
              '<div class="tooltip-body">Growth: ' + growthSymbol + (d.growth * 100).toPrecision(3) + '% ' + '</div>'

            if (d.hasSignificantChange == true) {
              bestGrowthTooltip.transition()
                .duration(100)
                .style("opacity", 1)

              var growthSymbol = '+';

              if (d.growth < 0) {
                var growthSymbol = '';
              }

              toolTipHtml = '<div class="tooltip-heading">' + d.name + "</div>" +
                  '<div class="tooltip-body">Significant change: ' + d.significantChange + '</div>'

              bestGrowthTooltip
                .style("left", (d3v4.event.pageX - 63) + "px")
                .style("top", (d3v4.event.pageY - 80) + "px")
                .html(toolTipHtml)
            }else if(d.name == thisVue.referenceActorName){
              toolTipHtml =
              '<div class="tooltip-heading">' + d.name + "</div>" +
              '<div class="tooltip-body">Current Score : ' + d.score.toPrecision(4)  + '  ' + '</div>' +
              '<div class="tooltip-body">Previous Score : ' + d.previous_score.toPrecision(4)  + '</div>' +
              '<div class="tooltip-body">Growth : ' + d.growth.toPrecision(3) + '% ' + '</div>'

              referenceActorTooltip.transition()
                .duration(100)
                .style("opacity", 1);
              referenceActorTooltip.html(toolTipHtml)
               .style("left", (d3v4.event.pageX - 68) + "px")
               .style("top", (d3v4.event.pageY - 130) + "px");
            } else {
              hoverTooltip.transition()
               .duration(100)
               .style("opacity", 1);
               hoverTooltip.html(toolTipHtml)
               .style("left", (d3v4.event.pageX - 68) + "px")
               .style("top", (d3v4.event.pageY - 130) + "px");
            }
          })
         .on("mouseout", function(d) {
          hoverTooltip.transition()
            .duration(200)
            .style("opacity", 0);
          bestGrowthTooltip.transition()
            .style("opacity", 0);
          referenceActorTooltip.transition()
            .style("opacity", 0);
         })
         .on("click", function(d) {
          // to check all elements with the class ranking-chart__bar and remove the class ranking-chart__bar-selected
          // if it is present in the class list of the element
          for (var i = 0; i < document.querySelectorAll(".ranking-chart__bar").length; i++) {
            //To detect if it the browser is NOT IE 6-11, beacuse in IE the classlist property doesn't exist.
            if (! isIE11() && document.querySelectorAll(".ranking-chart__bar")[i].classList.contains("ranking-chart__bar-selected")) {
                document.querySelectorAll(".ranking-chart__bar")[i].classList.remove("ranking-chart__bar-selected");
            }
          }

          if (! isIE11()) {
            this.classList.add("ranking-chart__bar-selected"); // add class to current selected bar in the graph
          }

          var growthSymbol = '+';

          if (d.growth < 0) {
            var growthSymbol = '';
          }

          var toolTipHtml =
              '<div class="tooltip-heading">' + d.name + "</div>" +
              '<div class="tooltip-body">Mentions: ' + d.mentions +'</div>' +
              '<div class="tooltip-body">Current Score: ' + d.score.toPrecision(4)  + '  ' + '</div>' +
              '<div class="tooltip-body">Previous Score: ' + d.previous_score.toPrecision(4)  + '</div>' +
              '<div class="tooltip-body">Growth: ' + growthSymbol + (d.growth * 100).toPrecision(3) + '% ' + '</div>'

          hoverTooltip.style("opacity", 0);

          if(d.name == thisVue.referenceActorName){
            toolTipHtml =
              '<div class="tooltip-heading">' + d.name + "</div>" +
              '<div class="tooltip-body">Current Score : ' + d.score.toPrecision(4)  + '  ' + '</div>' +
              '<div class="tooltip-body">Previous Score : ' + d.previous_score.toPrecision(4)  + '</div>' +
              '<div class="tooltip-body">Growth : ' + d.growth.toPrecision(3)+ '% ' + '</div>'

            clickTooltip.transition()
             .duration(100)
             .style("opacity", 1)
             .attr("class", "ranking-chart__d3-tip-click-alternative-one")
             clickTooltip.html(toolTipHtml)
             .style("left", (d3v4.event.pageX - 68) + "px")
             .style("top", (d3v4.event.pageY - 130) + "px");
            } else if(d.hasSignificantChange == true){
              clickTooltip.transition()
               .duration(100)
               .style("opacity", 1)
               .attr("class", "ranking-chart__d3-tip-click-alternative-two")
               clickTooltip.html(toolTipHtml)
               .style("left", (d3v4.event.pageX - 68) + "px")
               .style("top", (d3v4.event.pageY - 130) + "px");
            }else {
              clickTooltip.transition()
               .duration(100)
               .style("opacity", 1)
               .attr("class", "ranking-chart__d3-tip-click")
               clickTooltip.html(toolTipHtml)
               .style("left", (d3v4.event.pageX - 68) + "px")
               .style("top", (d3v4.event.pageY - 130) + "px");
            }

           thisVue.$emit('actor', d)
           thisVue.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component:"scores", metaData: d.id})
         });

      // Left info
      var leftInfo = root.append('g')
        .attr('class', 'info info--left')

      leftInfo
        .append('line')
        .attr('x1', LEFT_INFO_WIDTH - 1)
        .attr('x2', LEFT_INFO_WIDTH - 1)
        .attr('y1', 0)
        .attr('y2', this.outerHeight)

      leftInfo
        .append('text')
        .text(this.bottomTitle +' SCORE '+ this.getCurrentDate)
        .attr('text-anchor', 'end')
        .attr('x', -15)
        .attr('y', 25)
        .attr('transform', function () {
          return `rotate(-90)`
        })

      // Bottom info
      var bottomInfo = root.append('g')
        .attr('class', 'info info--bottom')
        .attr('transform', `translate(${LEFT_INFO_WIDTH},${height + BOTTOM_INFO_HEIGHT})`)

      bottomInfo
        .append('line')
        .attr('x1', 0)
        .attr('x2', this.outerWidth)
        .attr('y1', 1)
        .attr('y2', 1)

      bottomInfo
        .append('text')
        .text(this.bottomTitle +' RANK '+ this.getCurrentDate)
        .attr('x', this.outerWidth - LEFT_INFO_WIDTH - 20)
        .attr('y', 23)
        .attr('text-anchor', 'end')

      var findReference = newData.data.map(function(e) { return e.isReference; }).indexOf(true)

    },
    attemptLoad () {
      const currentDistance = getCurrentDistance(this.scrollParent, this.$el)
      if (!this.complete && currentDistance <= this.distance) {
        this.$emit('load')
      }
    },
    scrollHandler () {
      if (!this.loading) {
        this.attemptLoad()


        /**
         * to hide the onclick tooltip when scrolling
         */
        // to check all elements with the class ranking-chart__bar and remove the class ranking-chart__bar-selected
        // if it is present in the class list of the element
        for(var i = 0; i < document.querySelectorAll(".ranking-chart__bar").length; i++ ) {
          //To detect if it the browser is NOT IE 6-11, beacuse in IE the classlist property doesn't exist.
          if (!(/*@cc_on!@*/false || !!document.documentMode)) {
            if(document.querySelectorAll(".ranking-chart__bar")[i].classList.contains("ranking-chart__bar-selected")){
              document.querySelectorAll(".ranking-chart__bar")[i].classList.remove("ranking-chart__bar-selected");
            }
          } else {
            //so that it displays the console error only once
            if(i < 1) {
              console.error("Some functionalities are not available in IE")
            }
          }
        }

        d3v4.select('.ranking-chart__d3-tip-click').transition()
          .duration(100)
          .style("opacity", 0);
      }
    },
    clearGraph () {
      d3v4.select('.ranking-tt')
        .remove()

      d3v4.select(this.$refs.ranking)
        .selectAll('*')
        .remove()

      d3v4.select('.ranking-chart__d3-tip-hover').remove()
      d3v4.select('.ranking-chart__d3-tip-click').remove()
      d3v4.select('.ranking-chart__d3-tip-click-alternative-one').remove()
      d3v4.select('.ranking-chart__d3-tip-click-alternative-two').remove()
      d3v4.select('.ranking-chart__d3-tip-teamleader').remove()
      d3v4.select('.ranking-chart__d3-tip-best-growth').remove()
    }
  },
  watch: {
    data (val) {
      this.render(val)
    },
    complete (val, old) {
      if (val && !old) {
        this.scrollParent.removeEventListener('scroll', this.scrollHandler)
      }
      if (!val && old) {
        this.scrollParent.addEventListener('scroll', this.scrollHandler)
        this.$nextTick(this.scrollHandler)
      }
    }
  },
  async mounted () {
    var thisVue = this
    this.render(this.data)

    window.onresize = function(){
      thisVue.render(thisVue.data)
    }

    var filterObject = {}
    Object.assign(filterObject, this.$store.getters.exploreFilterObject, {score_type: this.scoreType})
    this.$router.replace({ query: filterObject })
  },
  unmounted () {
    this.clearGraph ()
  },
  beforeUnmount () {
    this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
  }
}

</script>

<style lang="scss">
@import "../../../scss/_variables.scss";


.ranking-chart__x-axis path, .ranking-chart__x-axis g{
  display: none;
}

@media screen and (min-width: 800px) {
  .bar {
    stroke:white;
    stroke-width:1;
    stroke-linecap:butt;
  }
  .bar-stroke {
    stroke-width: 0 !important;
  }
}

.bar-stroke {
  stroke-width: 1 ;
}

.ranking-chart__bar {
  fill: rgb(166, 166, 166);
}

.ranking-chart__bar:hover {
  fill: $color-alternative-one;
}
.ranking-chart__bar-selected {
  fill: $color-alternative-two;
}

.ranking-chart__bar-reference:hover {
  fill: $color-primary!important;
}

.ranking-chart__bar-reference {
  fill: $color-primary;
}

.ranking-chart__bar-significant:hover {
  fill: $color-alternative-three !important;
}

.ranking-chart__bar-significant {
  fill: $color-alternative-three;
}

.ranking-chart__d3-tip-hover{
  position: absolute;
  text-align: left;
  line-height: 1;
  font-weight: 500;
  padding: 12px;
  background: $color-alternative-one;
  color: #fff;
  border-radius: 2px;
  pointer-events: none;
  z-index:9999;
}

.ranking-chart__d3-tip-click{
  position: absolute;
  text-align: left;
  line-height: 1;
  font-weight: 500;
  padding: 12px;
  background: $color-alternative-two;
  color: #fff;
  border-radius: 2px;
  pointer-events: none;
}

.ranking-chart__d3-tip-click-alternative-one{
  position: absolute;
  text-align: left;
  line-height: 1;
  font-weight: 500;
  padding: 12px;
  background: $color-primary;
  color: #fff;
  border-radius: 2px;
  pointer-events: none;
}

.ranking-chart__d3-tip-click-alternative-two{
  position: absolute;
  text-align: left;
  line-height: 1;
  font-weight: 500;
  padding: 12px;
  background: $color-alternative-three;
  color: #fff;
  border-radius: 2px;
  pointer-events: none;
}

.ranking-chart__d3-tip-teamleader{
  position: absolute;
  text-align: left;
  line-height: 1;
  font-weight: 500;
  padding: 12px;
  background: $color-primary;
  color: #fff;
  border-radius: 2px;
  pointer-events: none;
}

.ranking-chart__d3-tip-best-growth{
  position: absolute;
  text-align: left;
  line-height: 1;
  font-weight: 500;
  padding: 12px;
  background: $color-alternative-three;
  color: #fff;
  border-radius: 2px;
  pointer-events: none;
}

.landscape-chart__header {
  display: flex;
  align-items: center;
  padding: 10px;
}

.landscape-chart__header__title {
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: $font-stack-secondary;
  flex: 1;
}


.ranking-chart__graph-grid line {
  stroke: lightgrey;
  stroke-opacity: 0.15;
  shape-rendering: crispEdges;
  stroke-width: 15px;
}

.ranking-chart__graph-grid path {
  stroke-width: 0;
}

.ranking-chart__graph {
  border: 1px solid $color-borders;

  .info {
    > line {
      stroke: $color-borders;
      stroke-width: 1px;
      shape-rendering: crispEdges;
    }

    text {
      font-size: 18px;
      text-transform: uppercase;
      font-family: $font-stack-secondary;
    }
  }

  .quadrant-label {
    font-size: 18px;
    text-transform: uppercase;
    font-family: $font-stack-secondary;
  }

  .axis--y {
    fill: $color-borders;
    path {
      stroke: $color-borders;
    }
  }

  .axis {
    path {
        stroke: rgba(0, 0, 0, 0) !important;
      }
  }

  .tick {
    text {
      font-size: 12px;
      font-family: $font-stack-secondary;
    }
    line{
      visibility:visible;
    }
  }
}

path.rank-line {
  fill: none;
  stroke-width: 2px;
  shape-rendering: geometricPrecision;
}

div.tooltip-heading {
  display: block;
  text-align: center;
  font-weight: 500;
  text-transform:uppercase;
  font-size: 11px;
  margin-bottom: 10px;
}

div.tooltip-title {
  display: block;
  text-align: left;
  font-size: 11px;
}

div.tooltip-body {
  display: block;
  font-size: 10px;
  margin-bottom: 5px;
}

.ranking-chart__d3-tip-best-growth {
  max-width:110px;
  line-break: auto;
  text-align:center;
}
</style>
