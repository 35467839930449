import { createJSONRequest, createResource } from './create-request'

export const EcosystemMemberPackages = createResource('/api/settings/ecosystem-member-packages')

export const EcosystemMemberPackageFeatures = createResource('/api/settings/ecosystem-member-package-features')

export const linkPackageWithFeature = (packageId, featureId) => createJSONRequest({
  url: `/api/settings/ecosystem-member-packages/${packageId}/feature/${featureId}`,
  method: 'PUT',
})

export const unlinkPackageWithFeature = (packageId, featureId) => createJSONRequest({
  url: `/api/settings/ecosystem-member-packages/${packageId}/feature/${featureId}`,
  method: 'DELETE',
})
