<template>
  <div class="collapsable-tab__content-container">
    <form-section
      v-if="isRelevantForActor('company_number', modifiedActor) || isRelevantForActor('founding_date', modifiedActor)">
      <div class="row" v-if="isRelevantForActor('company_number', modifiedActor)">
        <form-group
          :label="$t('actor_edit_company_number')"
          :errors="errors.company_number ? errors.company_number[0] : ''"
          class="col-xs-12 col-md-6"
          :class="{'actor-edit__private-field': isPrivateField('company_number')}">
          <div class="company_number">
            <dropdown
              :options="jurisdictionOptions" :model-value="modifiedActor.jurisdiction"
              @update:modelValue="updateJurisdiction" width="50px" :search="true"/>
            <ds-input
              :placeholder="$t('actor_edit_company_number')" name="actor-number"
              v-model="modifiedActor.company_number"/>
          </div>
        </form-group>
        <form-group
          :label="$t('actor_edit_vat_number')" :errors="errors.vat_number"
          v-if="isRelevantForActor('company_number', modifiedActor) || isRelevantForActor('founding_date', modifiedActor)"
          class="col-xs-12 col-md-6"
          :class="{'actor-edit__private-field': isPrivateField('vat_number')}">
          <div class="company_number">
            <dropdown
              :options="jurisdictionOptions" :model-value="modifiedActor.jurisdiction"
              @update:modelValue="updateJurisdiction" width="50px" :search="true"/>
            <ds-input
              :placeholder="$t('actor_edit_vat_number')" name="actor-vat-number"
              v-model="modifiedActor.vat_number"/>
          </div>
        </form-group>
      </div>
      <div class="row" v-if="isRelevantForActor('founding_date', modifiedActor)">
        <form-group
          :label="actorTypeFoundingLabel"
          :errors="errors.founding_date" class="col-xs-12"
          :class="{'actor-edit__private-field': isPrivateField('founding_date')}">
          <datepicker v-model="modifiedActor.founding_date" :year-range="[1900, new Date().getFullYear()]"/>
        </form-group>
      </div>
      <div class="row" v-if="(isRelevantForActor('company_number', modifiedActor) || isRelevantForActor('founding_date', modifiedActor)) && !isProduct">
        <form-group :label="$t('actor_edit_nace_codes')" class="col-xs-12">
          <a class="collapsable-tab__delete-all-button" @click="displayNaceModal()">{{ $t('edit_actor_nace_codes') }}</a>
          <DsTextarea :placeholder="$t('edit_actor_nace_codes_btn_cta')" :model-value="naceCodesList" :autoresize="true" :disabled="true" @click="displayNaceModal"/>
        </form-group>
      </div>
      <div class="row" v-if="isRelevantForActor('company_number', modifiedActor)">
        <div class="col-xs">
          <form-group
            :label="$t('actor_edit_nr_employees')" :errors="errors.employees"
            :class="{'actor-edit__private-field': isPrivateField('employees')}">
            <ds-input
              :placeholder="$t('actor_edit_nr_employees_placeholder')" name="actor-employee_size"
              v-model="employeeCount"/>
            <p v-if="!showEmployeesHistory" @click="showEmployeesHistory=1">
              <small>{{ $t('actor_edit_edit_earlier_employee_counts') }}</small>
            </p>
          </form-group>
        </div>
      </div>
      <div>
        <form-group
          :label="$t('actor_edit_founders')" class="actor-edit__founder-container"
          :errors="errors.name" v-if="userCanAddNewActors && isRelevantForActor('founders', modifiedActor)">
          <div
            class="actor-edit__new-activity-container"
            style="margin-bottom: 1em; display: flex;">
            <suggestion-input
              icon="search"
              ref="founderSuggestionInput"
              :api-settings="suggestionSettings"
              :clear-on-select="false"
              @on-select="createNewRelation($event, relationType)"
              @update:modelValue="founder.customInput = $event"
              @cancelCustomInput="founder.customInput = null"
            />
            <ds-button
              type="button"
              icon="plus"
              variant="add-actor"
              @click="addFounderNewActor"
              style="margin-left: 4px; line-height: 30px;"
              v-tooltip.left="$t('add_new_actor')"
            />
          </div>
          <div class="actor-edit__relationships">
            <template v-for="(_, index) in modifiedActor[relationType.name]">
              <relation-edit
                :from="modifiedActor.name" :type="relationType.name"
                v-model="modifiedActor[relationType.name][index]"
                @remove="handleRemoveRelation(relationType.name, index)"/>
            </template>
          </div>
        </form-group>
      </div>
      <form-group v-if="legalAddress" :label="$t('actor_edit_legal_address')">
        {{ legalAddress }}
      </form-group>
      <form-group
        v-if="showEmployeesHistory" :label="$t('actor_edit_historic_employee_counts')"
        :class="{'actor-edit__private-field': isPrivateField('employees')}">
        <historic-count v-model="modifiedActor.employees"></historic-count>
      </form-group>
    </form-section>
    <form-section id="funding-structure" v-if="isRelevantForActor('exit_round', modifiedActor)">
      <div :class="{'actor-edit__private-field': isPrivateField('exit_round')}">
        <exit-round
          v-if="modifiedActor.exit_round" :types="exitTypes"
          :data="modifiedActor.exit_round" :parentData="modifiedActor"
          :label="$t('actor_edit_exit')" :acquirers="acquirers"
          @remove="handleRemoveExit"/>
        <ds-button
          v-if="!modifiedActor.exit_round" type="button" icon="plus"
          :label="$t('actor_edit_add_exit')" size="small" class="collapsable-tab__exit-button"
          @click="handleAddExit"/>
      </div>
    </form-section>

  </div>
</template>

<script>
  import FormGroup from '../../Form/FormGroup.vue'
  import FormSection from '../../Form/FormSection.vue'
  import DsInput from '../../Form/DsInput.vue'
  import { addressLine, fromBounds, isRelevantForActor, toBounds } from '../../../util/helpers.js'
  import Dropdown from '../../Dropdown/Dropdown.vue'
  import { suggestionSettings, updateActor } from '../../../api/actors.js'
  import { trackHeapEvent } from '../../../util/analytics.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui.js'
  import MODAL_IDS from '../../../constants/modal-ids.js'
  import HistoricCount from '../../Form/HistoricCount.vue'
  import moment from 'moment'
  import Datepicker from '../../Form/Datepicker.vue'
  import SuggestionInput from '../../SuggestionInput/SuggestionInput.vue'
  import RelationEdit from '../../Relation/Edit.vue'
  import ExitRound from '../../FinancialRound/ExitRound.vue'
  import DsTextarea from '../../Form/DsTextarea.vue'
  import { getFlatNaceCodes } from '../../../api/config'

  export default {
    name: 'actor-edit-administration',
    props: {
      modifiedActor: Object,
      errors: Object,
      show: Object,
      jurisdictionOptions: Array,
    },
    data () {
      return {
        showEmployeesHistory: false,
        founder: {
          customInput: null,
        },
        relationType: {
          name: 'is_founded_by',
          label: this.$t('relation_is_co-founded_by'),
        },
        exitTypes: ['acquisition', 'merger', 'IPO'],
        naceCodeMapping: {},
      }
    },
    computed: {
      isPublisherEnabled () {
        return this.$store.getters.isPublisherEnabled === true
      },
      isProduct () {
        return this.modifiedActor.actor_type === 'Product'
      },
      naceCodesList () {
        if (!this.modifiedActor.nace_codes || !this.modifiedActor.nace_codes.codes || this.modifiedActor.nace_codes.codes.length === 0) {
          return ''
        }

        var list = ''

        var naceCodes = [...this.modifiedActor.nace_codes.codes].sort((a, b) => {
          var compareA = a
          var compareB = b

          if (a.length === 1) {
            compareA = a.charCodeAt(0) - 97
          }

          if (b.length === 1) {
            compareB = b.charCodeAt(0) - 97
          }

          return compareA - compareB
        })

        naceCodes.forEach((code) => {
          list += code + ' - ' + (this.naceCodeMapping[code] && this.naceCodeMapping[code].description) + '\n'
        })

        return list
      },
      actorTypeFoundingLabel () {
        var actorType = this.modifiedActor.actor_type

        return actorType === 'Person' ? this.$t('actor_edit_born_on') : actorType === 'Product' ? this.$t('actor_edit_launch_date') : this.$t('actor_edit_founding_date')
      },
      acquirers () {
        return (this.modifiedActor.acquired_by || []).map(({ to, to_name }) => ({ id: to, name: to_name }))
      },
      legalAddress () {
        return addressLine(this.modifiedActor.legal_address)
      },
      userCanAddNewActors () {
        return this.$store.getters.isMember
      },
      employeeCount: {
        get () {
          return fromBounds(this.modifiedActor.employees)
        },
        set (count) {
          const bounds = toBounds(count)
          if (!bounds) {
            return
          }
          bounds.date = new Date().toJSON().slice(0, 10)

          // Set first employee count
          if (!this.modifiedActor.employees || !this.modifiedActor.employees.length) {
            this.modifiedActor.employees = [bounds]
            return
          }

          // Replace todays employee count
          for (let i = this.modifiedActor.employees.length - 1; i >= 0; i--) {
            if (this.modifiedActor.employees[i].date === bounds.date) {
              this.modifiedActor.employees[i] = bounds
              return
            }
          }

          // Add a new employee count for today
          this.modifiedActor.employees.push(bounds)
        },
      },
      company () {
        return this.$store.getters.detailActor
      },
      suggestionSettings,
      // NOTE: this should be coming from the CompanyMixin, however since this class contains a lot of complexity and some overlapping functions
      // from the company mixin, we just copy the logic here, instead of importing the mixin and testing everything through again
    },
    methods: {
      getNaceCodeList () {
        getFlatNaceCodes()
          .then(response => {
            this.naceCodeMapping = response
          })
          .catch(e => {
            console.log(e)
          })
      },
      displayNaceModal () {
        var modalContext = {
          actorId: this.modifiedActor.id,
          naceCodes: (this.modifiedActor.nace_codes && this.modifiedActor.nace_codes.codes) || [],
          mainCode: (this.modifiedActor.nace_codes && this.modifiedActor.nace_codes.main_code) || '',
        }

        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, modalContext)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.EDIT_NACE_CODES)
      },
      handleRemoveExit () {
        this.modifiedActor.exit_round = null
      },
      handleAddExit () {
        this.modifiedActor.exit_round = {
          type: this.exitTypes[0],
          original_capital_raised: null,
          original_currency: 'EUR',
          started_at: moment().format('YYYY-MM-DD'),
        }
      },
      handleRemoveRelation (relationType, index) {
        if (index !== undefined) {
          this.modifiedActor[relationType].splice(index, 1)
        }
      },
      createNewRelation (actor, relationType) {
        const relation = this.modifiedActor[relationType.name] || []
        relation.push({
          from: this.modifiedActor.id,
          from_name: this.modifiedActor.name,
          to: actor.data ? actor.data.id : actor.id,
          to_name: actor.data ? actor.data.name : actor.name,
          start: null,
          end: null,
        })

        updateActor({
          id: this.modifiedActor.id,
          data: {
            [relationType.name]: relation,
          },
        }).then(data => {
          this.show.addFounder = false
        })

        setTimeout(() => {
          this.$refs.founderSuggestionInput.clear()
        }, 0)
      },
      addFounderNewActor () {
        trackHeapEvent('actorEdit.addNewActorFounder', { name: this.founder.customInput || '' })

        const modalContext = {
          prefilled: {
            actor_type: 'Person',
            name: this.founder.customInput || '',
          },
          successCallback: actor => {
            this.createNewRelation(actor, this.relationType)
          },
        }

        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, modalContext)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)
      },
      updateJurisdiction (jurisdiction) {
        this.modifiedActor.jurisdiction = jurisdiction
        this.$forceUpdate()
      },
      isRelevantForActor,
      isPrivateField (field) {
        return this.isPublisherEnabled && !this.publicFields.includes(field)
      },
    },
    mounted () {
      this.getNaceCodeList()

      this.$bus.on('actorNaceCodesUpdated', (context) => {
        if (this.modifiedActor.id !== context.actorId) {
          return
        }

        this.modifiedActor.nace_codes = {}
        this.modifiedActor.nace_codes.codes = context.naceCodes
        this.modifiedActor.nace_codes.main_code = context.mainCode

        // If the current modal is still the edit nace codes one, update the context
        if (this.$store.state.ui.visibleModals.includes(MODAL_IDS.EDIT_NACE_CODES)) {
          var modalContext = {
            actorId: this.modifiedActor.id,
            naceCodes: this.modifiedActor.nace_codes.codes,
            mainCode: this.modifiedActor.nace_codes.main_code,
          }

          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, modalContext)
        }
      })

      this.$bus.on('actorMainNaceCodeUpdate', (context) => {
        if (this.modifiedActor.id !== context.actorId) {
          return
        }

        this.modifiedActor.nace_codes.main_code = context.mainCode

        // If the current modal is still the edit nace codes one, update the context
        if (this.$store.state.ui.visibleModals.includes(MODAL_IDS.EDIT_NACE_CODES)) {
          var modalContext = {
            actorId: this.modifiedActor.id,
            naceCodes: this.modifiedActor.nace_codes.codes,
            mainCode: this.modifiedActor.nace_codes.main_code,
          }

          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, modalContext)
        }
      })
    },
    beforeUnmount () {
      this.$bus.off('actorNaceCodesUpdated')
    },
    components: {
      FormGroup,
      FormSection,
      DsInput,
      HistoricCount,
      Datepicker,
      SuggestionInput,
      Dropdown,
      RelationEdit,
      ExitRound,
      DsTextarea,
    },
    mixins: [],
  }
</script>
