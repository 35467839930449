<template>
  <modal
    :id="modalId"
    ref="modal"
    :title="title"
    :enable-mask="false"
    :is-closeable="true"
    @close="closeModal"
  >
    <template v-slot:body>
      <div>
        <form-group label="Add a title for your Instant Monitoring"
                    :error="errorMessage && errorMessage">
          <ds-input v-model="newTitle" @update:modelValue="errorMessage = ''"/>
        </form-group>
        <p v-if="saving">
          Saving...
        </p>
      </div>
    </template>
    <template v-slot:footer>
      <div>
        <ds-button @click="submit" label="Submit" :disabled="!newTitle"/>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import { storeConceptSearch } from '../../api/exploration.js'
  import FormGroup from '../Form/FormGroup.vue'

  export default {
    data () {
      return {
        modalId: 'ADD_INSTANT_MONITORING',
        newTitle: '',
        errorMessage: '',
        saving: false,
      }
    },
    computed: {
      modalContext () {
        return this.$store.state.ui.modalContext
      },
      title () {
        return 'Add Instant Monitoring'
      },
    },
    methods: {
      closeModal () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, this.modalId)
        this.$emit('close')
      },
      submit () {
        this.saving = true
        storeConceptSearch({
          title: this.newTitle,
          search_topics: this.modalContext.search_topics,
          geography_context: this.modalContext.geography_context,
        }).then((result) => {
          if (result.concept_search_id) {
            this.$bus.emit('confirmCreateInstantMonitoring', result.concept_search_id)
            this.closeModal()
          } else {
            this.errorMessage = 'An error occurred while creating ' + this.newTitle
          }
        }).catch((error) => {
          if (error && error.title && error.title.length) {
            this.errorMessage = error.title[0]
          } else {
            this.errorMessage = 'An error occurred while creating ' + this.newTitle
          }
        }).finally(() => {
          this.saving = false
        })
      },
    },
    components: {
      Modal,
      FormGroup,
    },
  }
</script>
