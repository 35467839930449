import { getExternalLinkFromItem, getInternalFileUrlForResource } from './helpers.js'
import moment from 'moment'
import _isEmpty from 'lodash/isEmpty.js'

export default {
  computed: {
    isOwner () {
      return this.$store.getters.isOwner
    },
    isMember () {
      return this.$store.getters.isMember
    },
    isDeveloper () {
      return this.$store.getters.isDeveloper
    },
    isPortfolioMember () {
      return this.$store.getters.isPortfolioMember
    },
  },
  methods: {
    getContentType (item) {
      return item.media_type || item.mimetype || (item.details && item.details.mimetype)
    },
    itemIsAnnouncement (item) {
      return ['announcement'].includes(this.getContentType(item))
    },
    itemIsResource (item) {
      return ['public_resource', 'private_resource'].includes(this.getContentType(item))
    },
    itemIsArticle (item) {
      return ['rss', 'pr', 'blog', 'event'].includes(this.getContentType(item))
    },
    itemIsPatent (item) {
      return ['patent'].includes(this.getContentType(item))
    },
    canOpenInternalPage (resource) {
      if (!this.isOwner && !this.isTeamMember) {
        return
      }

      if (resource.meta && ['public_resource', 'private_resource'].includes(this.getContentType(resource))) {
        const fileTypeMetaEntry = resource.meta.find(entry => entry.key === 'file_type')

        if (!fileTypeMetaEntry.value.startsWith('image') &&
          !fileTypeMetaEntry.value.startsWith('application') &&
          !fileTypeMetaEntry.value.startsWith('link') &&
          !fileTypeMetaEntry.value.startsWith('video_link')
        ) {
          return true
        }
      } else if (this.itemIsArticle(resource) || this.itemIsPatent(resource)) {
        return true
      }
    },
    internalPageUrl (item) {
      let id = item.sql_media_id
      if (typeof id === 'undefined' || _isEmpty(id)) {
        id = item.id
      }

      return `/files/${id}/analysis/`
    },
    handleClickResourceResult (item, forceExternal = false) {
      let metaLinkEntry = ''

      switch (this.getContentType(item)) {
        case 'announcement': {
          this.$router.push(`/announcements/${item.sql_media_id}`)
          break
        }
        case 'public_resource':
        case 'private_resource':
          if (!forceExternal && this.canOpenInternalPage(item)) {
            this.$router.push(this.internalPageUrl(item))
            break
          }

          const externalLink = getExternalLinkFromItem(item)
          if (externalLink) {
            window.open(externalLink)
            break
          }

          window.open(getInternalFileUrlForResource(item))
          break
        case 'rss':
        case 'blog':
        case 'pr':
        case 'event':
          if (!forceExternal && this.canOpenInternalPage(item)) {
            this.$router.push(this.internalPageUrl(item))
            break
          }

          if (item.original_article) {
            metaLinkEntry = item.original_article[0].url
          } else if (item.meta) {
            metaLinkEntry = getExternalLinkFromItem(item)
          }

          metaLinkEntry && window.open(metaLinkEntry)
          break
        case 'patent':
          if (!forceExternal && this.canOpenInternalPage(item)) {
            this.$router.push(this.internalPageUrl(item))
            break
          }

          if (item.meta && item.meta.patent_uri) {
            metaLinkEntry = item.meta.patent_uri
          } else if (item.meta) {
            metaLinkEntry = getExternalLinkFromItem(item)
          }

          metaLinkEntry && window.open(metaLinkEntry)
          break
      }
    },
    publishedDate (resourceDate) {
      if (resourceDate) {
        const date = moment(resourceDate * 1000)
        const year = date.format('YYYY')

        if (year < 9999) {
          return date.format('YYYY/MM/DD')
        }
      }

      return null
    },
  },
}
