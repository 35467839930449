import * as L from 'leaflet'
import 'leaflet.markercluster'
import { _unique } from '../../util/helpers'
import { trackHeapEvent } from '../../util/analytics'
import _flatten from 'lodash/flatten'
import _sum from 'lodash/sum'
import _sumBy from 'lodash/sumBy'
import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters'

export function setup (element, useDefaultConfigSettings) {
  let mostRecentMapOptions = Object.assign({}, window.viewMapOptions || {})
  if (useDefaultConfigSettings) {
    mostRecentMapOptions = {}
  }
  var opts = Object.assign({}, window.config.viewMapOptions || {}, mostRecentMapOptions)
  opts.zoomControl = false
  opts.zoomAnimation = false
  opts.zoom = opts.zoom || 6
  opts.center = opts.center || [50, 3]
  opts.minZoom = opts.minZoom || 2
  opts.maxZoom = opts.maxZoom || 18
  // opts.maxBounds = new L.LatLngBounds(new L.LatLng(90, -180))
  opts.worldCopyJump = true
  opts.inertia = false
  var map = L.map(element, opts)

  L.tileLayer(
    'https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png', {
      attribution: 'powered by <a href="https://carto.com">Carto</a>',
      attributionControl: false,
      useCache: true,
      // noWrap: true,
      layerOptions: {
        attribution: 'powered by <a href="https://carto.com">Carto</a>',
      },
    },
  ).addTo(map)

  L.Icon.Default.imagePath = 'images/map'
  new L.Control.Zoom({ position: 'bottomright' }).addTo(map)
  return map
}

/* export function addGroupLayer(selfData, layerData, styling) {
  //console.log(self.$el, layerData, 'addGroupLayer')
  //selfData.geoJson(layerData, {style: styling}).addTo(selfData.map());
} */

// export function init(self) {

// The uiParameters will determine if aditional controlers are added to the leaflet map
// Each controler can be controled depending on the parameteters
// By default the "SearchToggle" and "ClusterToggle" should be "on" by default as they are fundemental tools
// for the leafleat map on the directory page
export function init (self, uiParameters, clusterLabel, disableExpandButton, useDefaultConfigSettings) {
  const map = setup(self.$el.firstElementChild, useDefaultConfigSettings)

  if (!map) {
  }

  var ClusterToggle = L.Control.extend({
    options: {
      position: uiParameters.clusterPosition ? uiParameters.clusterPosition : 'topleft',
    },
    onAdd (map) {
      var container = L.DomUtil.create('div', 'leaflet-bar')
      const clusterMarkersLabel = clusterLabel
      container.innerHTML = `
        <a href="#" class="map-toggle">
          <input type="checkbox" checked class="map-toggle__input clustering--checked">
          <input type="checkbox" class="map-toggle__input clustering--unchecked">
          ${clusterMarkersLabel}
        </a>
      `
      container.onclick = function (e) {
        self.clustering = !self.clustering
        trackHeapEvent('map.options', { clustering: self.clustering })
        self.render()
        e.preventDefault()
      }
      return container
    },
  })

  if (!uiParameters.noCluster) {
    map.addControl(new ClusterToggle())
  }

  var SearchToggle = L.Control.extend({
    options: {
      position: uiParameters.searchWhileMovingPosition ? uiParameters.searchWhileMovingPosition : 'topleft',
    },
    onAdd (map) {
      var container = L.DomUtil.create('div', 'leaflet-bar')
      container.innerHTML = `
        <a href="#" class="map-toggle">
          <input type="checkbox" checked class="map-toggle__input list-sync--checked">
          <input type="checkbox" class="map-toggle__input list-sync--unchecked">
          Search while I move the map
        </a>
      `
      container.onclick = function (e) {
        self.$store.commit(FILTERS_MUTATION_TYPES.TOGGLE_LIST_SYNC)
        trackHeapEvent('map.options', { searchWhileMoving: self.$store.state.filters.listSync })
        self.$parent.getData()
        e.preventDefault()
      }
      return container
    },
  })
  if (!uiParameters.noSearchWhileMoving) {
    map.addControl(new SearchToggle())
  }

  var DirectoryRedirector = L.Control.extend({
    options: {
      position: 'topright',
    },
    onAdd (map) {
      var container = L.DomUtil.create('div')
      if (!disableExpandButton) {
        container.innerHTML = `
        <a href="#" class="map-redirect">
          <button type="button" class="button button--icon button--default">
            <div class="svg-icon svg-icon--regular svg-icon--arrow-expand">
              <svg viewBox=\"2 2 20 20\"><path class=\"fill-fg\" d=\"M10 21v-2H6.41l4.5-4.5-1.41-1.41-4.5 4.5V14H3v7h7m4.5-10.09l4.5-4.5V10h2V3h-7v2h3.59l-4.5 4.5 1.41 1.41z\"/></svg>
            </div>
            <span class="button__label">&nbsp;</span>
          </button>
        </a>
      `
        container.onclick = function (e) {
          self.$bus.emit('enlargeMap', { data: self.data, getMapData: self.getMapData })
          trackHeapEvent('map.enlarge')

          e.preventDefault()
        }
      }

      return container
    },
  })
  map.addControl(new DirectoryRedirector())
  return map
}

export function createClusterLayer (legendLookup) {
  return L.markerClusterGroup({
    showCoverageOnHover: false,
    spiderfyOnMaxZoom: false,
    zoomToBoundsOnClick: true,
    maxClusterRadius: 60,
    iconCreateFunction (cluster) {
      const childMarkers = cluster.getAllChildMarkers()

      // Markers have a string as legend, clusters have an array of strings
      const legend = _unique(_flatten(childMarkers.map(n => n.options.legend)))

      const count = { total: 0 }
      childMarkers.forEach(n => {
        if (typeof n.options.legend === 'string' || typeof n.options.legend === 'number' || !n.options.legend) {
          count[n.options.legend] = (count[n.options.legend] || 0) + (n.options.count || 1)
        } else {
          count.total += n.options.count || 1
          for (const legend in n.options.legend) {
            if (legend) {
              count[legend] = (count[legend] || 0) + (n.options.legend[legend] || 0)
            }
          }
        }
      }) || cluster.getChildCount()

      return createClusterIcon(count, legend, legendLookup)
    },
  })
}

export function createIcons (hexColors, borderColors, usePinPointIcon = false) {
  return hexColors.map((hex, index) => createIcon(hex, borderColors[index], usePinPointIcon))
}

export function createIcon (fill, stroke, usePinPointIcon = false) {
  let icon = '<svg viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg"><circle cx="20" cy="20" r="5" fill="' +
    fill + '"/></svg>'

  let iconSize = new L.Point(40, 40)

  if (usePinPointIcon) {
    icon = '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M215.7 499.2C267 435 384 279.4 384 192C384 86 298 0 192 0S0 86 0 192c0 87.4 117 243 168.3 307.2c12.3 15.3 35.1 15.3 47.4 0zM192 128a64 64 0 1 1 0 128 64 64 0 1 1 0-128z" fill="' + fill + '"/></svg>'
    iconSize = new L.Point(20, 20)
  }

  return L.divIcon({
    iconSize: iconSize,
    html: icon,
  })
}

export function createClusterIcon (total, legend, legendLookup) {
  var belgianMapOverlayEnabled = window.config.belgianMapOverlayEnabled

  if (typeof total !== 'number') {
    legend = total
    total = legend.total
    delete legend.total
    total = Math.max(total, _sum(Object.values(legend)))
  }

  if (!total) {
    return createIcon('#f00', '#666')
  }

  // Map legend items to colors
  let items = Object.keys(legend)
    .map(item => ({
      count: legend[item],
      color: (legendLookup[item] || {}).hex || '#bbb',
      border: (legendLookup[item] || {}).border || '#666',
    }))

  const missingLegend = total - _sumBy(items, 'count')

  if (missingLegend > 0) {
    items.push({
      count: missingLegend,
      color: '#bbb',
      border: '#666',
    })
  }

  // If the belgianMapOverlay is not enabled then we can create the the single circle icon
  // Otherwise we draw the clusted icon with the value "1"
  if (!belgianMapOverlayEnabled) {
    if (total === 1) {
      return createIcon(items[0].color, items[0].border)
    }
  }

  items.sort((a, b) => a.color > b.color)

  const radius = Math.round(Math.log10(total) * 5) + 7
  const size = radius * 2 + 10
  const part = 6.28 * radius / items.length

  let offset = 6.28 * radius / 4
  items.forEach(item => {
    offset += item.part = 6.28 * radius * item.count / total
    item.offset = offset
  })

  // Map items to arc
  items = items.map((item, i) => ({
    color: item.color,
    len: item.part,
    offset: item.offset,
    rest: 6.28 * radius - item.part,
  }))
  // let colors = legend
  //   .map(item => (legendLookup[item] || {}).hex)
  //   .filter(Boolean)
  // if (!colors.length) {
  //   colors = ['#999']
  // }
  // const radius = Math.round(Math.log10(total) * 5) + 7
  // const size = radius * 2 + 10
  // const part = 6.28 * radius / colors.length
  // const items = colors.map((c, i) => ({
  //   color: c,
  //   len: part,
  //   offset: part * (.5 + i),
  //   rest: 6.28 * radius - part
  // }))
  return L.divIcon({
    iconSize: new L.Point(size, size),
    html: `
<svg viewBox=\"0 0 ${size} ${size}\" xmlns=\"http://www.w3.org/2000/svg\">
  <g transform="translate(${radius + 5}, ${radius + 5})">
    <g fill="transparent" stroke-width="5">
      ` + items.map(({
      color,
      len,
      offset,
      rest,
    }) => `<circle r="${radius}" stroke="${color}" stroke-dasharray="${len}, ${rest}" stroke-dashoffset="${offset}" />`).join('') + `
    </g>
    <circle r="${radius}" class="circle-front" stroke="transparent" stroke-width="0" fill="#fff" />
    <text text-anchor="middle" style="font-weight:bold" y="5">${total}</text>
  </g>
</svg>

    `,
  })
}
