import { Dictionary } from '../api/exploration'
import { reactive } from '@vue/reactivity'

// This ended up being a little private store for the DictionaryMixin. Maybe we're better off just putting this into the actual store?
// An argument to not put it into the store is that our store already has a lot of calls it needs to do to populate it on boot
// while these data aren't required in many places
const globalState = reactive({
  promise: null,
  data: null,
  allDictionaryKeywordIds: {},
  dictionaryOptions: [],
})

export default {
  computed: {
    mixinDictionaries () {
      return globalState.data
    },
    dictionaryOptions () {
      return globalState.dictionaryOptions
    },
    mixinDefaultDictionaries () {
      return this.$store.getters.defaultDictionaries || []
    },
    dictionaryPromise () {
      return globalState.promise
    },
  },
  created () {
    if (!globalState.promise) {
      this.fetchDictionaries()
    }
  },
  methods: {
    fetchDictionaries () {
      globalState.promise = Dictionary.get()

      globalState.promise.then(dictionaries => {
        globalState.data = dictionaries

        const allDictionaryKeywordIds = {}

        for (const dictionary of globalState.data) {
          dictionary.included_keywords.forEach((keyword) => allDictionaryKeywordIds[keyword.id] = keyword.id)
        }

        globalState.allDictionaryKeywordIds = allDictionaryKeywordIds
        globalState.dictionaryOptions = this.buildDictionaryOptions()
      })
    },
    buildDictionaryOptions () {
      if (this.mixinDictionaries === null || this.mixinDictionaries.length === 0) {
        return [{ value: null, label: 'There are no lexicons yet', disabled: true }]
      }

      const options = [
        ...this.mixinDictionaries
          .map(dictionary => {
            if (dictionary.default) {
              return
            }

            const isDictionaryFull = dictionary.included_keywords.length === 15

            const label = dictionary.value + (isDictionaryFull ? ' (contains 15 keywords already)' : '')

            return {
              value: dictionary.id,
              label: label,
              disabled: isDictionaryFull,
              included_keywords: dictionary.included_keywords,
            }
          })
          .filter(r => r),
      ]
        .sort((a, b) => {
          if (!a.value) {
            return -1
          }

          if (a.label < b.label) {
            return -1
          }

          if (a.label > b.label) {
            return 1
          }

          return 0
        })

      return [{ value: null, label: 'Select a lexicon to add keyword to', disabled: true, selected: true }].concat(options)
    },
    getDictionary (id) {
      if (globalState.data === null) {
        return undefined
      }

      for (const dictionary of globalState.data) {
        if (dictionary.id === id) {
          return dictionary
        }
      }

      return undefined
    },
    keywordExistsInAnyDictionary (keyword) {
      return !!globalState.allDictionaryKeywordIds[keyword.value]
    },
  },
}
