<template>
  <transition name="modal">
    <div class="modal__mask" @click="blur">
      <div class="modal__wrapper">
        <div class="modal__container" style="max-width: 460px" :class="classes" @click.stop>
          <div class="modal__header">
            <h4 class="title">Choose a colour</h4>
            <hr class="divider">
            <button class="close" @click="close" v-if="isCloseable">×</button>
          </div>
          <div class="modal__body colour_picker__body">
            <div class="colour_picker__colour-container ">
              <div class="colour_picker__colour-item" v-for="hexColour in hexColours" @click="updateColours(hexColour.name)">
                <div class="legend_dropdown__color-items" :style="{ 'background-color': hexColour.colour, 'border-radius': '25px'}" ></div>
              </div>
            </div>
          </div>
          <div class="modal__footer">
            <p v-if="errors.message" style="display: inline-block; margin-right: 1em;" class="guide_text form-group__error">{{errors.message}}</p>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters'

  import KEYCODES from '../../constants/keycodes'
  import MODAL_IDS from '../../constants/modal-ids'

  import FiltersMixin from '../../util/FiltersMixin'
  import { inert } from '../../util/helpers'

  import { updateHexColour } from '../../api/taxonomies.js'

  export default {
    name: 'colour-picker-modal',
    data () {
      return {
        modifiedColours: [],
        invalidColours: [],
        errors: {},
      }
    },
    props: {
      closeOnBlur: Boolean,
      id: {
        type: String
      },
      isCloseable: {
        type: Boolean,
        default: true
      },
      size: {
        type: String,
        default: '',
        validator: val => ['large', ''].includes(val)
      }
    },
    computed: {
      config () {
        return this.$store.state.config;
      },
      classes () {
        return {
          [`modal__container--${this.size}`]: this.size,
        }
      },
      legendMapping () {
        return this.config.legendMapping;
      },
      hexColours () {
        var hexColours = Object.entries(this.config.hexColours).map(item => {
          return {name: item[0], colour: item[1]}
        })

        return hexColours;
      },
      modalContext () {
        return this.$store.state.ui.modalContext
      },
    },
    methods: {
      keyUpHandler (e) {
        if (e.keyCode === KEYCODES.ESC) {
          this.close()
        }
      },
      blur () {
        if (this.closeOnBlur) {
          this.close()
        }
      },
      close () {
        if (this.isCloseable) {
          // When closing the modal we reset to modal context.
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
          this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.COLOUR_PICKER)
          this.$emit('close')
        }
      },
      updateColours (hexName) {
        // Modal context will have the Taxonomy Name (ex. category) and Taxonomy Value (ex. Competitor)
        var colourDataObject = Object.assign(this.modalContext, { hex: hexName})

        updateHexColour(colourDataObject)
          .then(data => {
            // Clear the error message
            this.errors = {}
            // After the udpate of the hexColours, the window.config property and its state equivalent are not updated automaticaly and retain their original data
            // Even though we have a state property for the config, its never detecting any change on the window.config only when refreshing the page

            // We create a deep copy of the current config, otherwise vue won't let change the object
            var configCopy = JSON.parse(JSON.stringify(this.config));
            // We create a copy of the legend mapping (for the currently selected legend property)
            var taxonomyValuesCopy = Object.assign({}, configCopy.legendMapping[this.legendProperty]);

            // We update the color assigned to the legend item
            taxonomyValuesCopy = Object.assign(taxonomyValuesCopy, { [this.modalContext.taxonomyValue.name]: colourDataObject.hex, [this.modalContext.taxonomyValue.value]: colourDataObject.hex })
            // We assign the legend mapping object with its new values to the config object
            configCopy.legendMapping[this.legendProperty] = Object.assign(configCopy.legendMapping[this.legendProperty], taxonomyValuesCopy)

            // After updating the config copy we commit the new config with the updated legend mapping
            // This triggers the reactiveness of the state for the config
            this.$store.commit('CONFIG_SET', inert(configCopy));

            // After having the new config, we set again the legend property which will update the lookup property in the filters state
            // the lookup property is being used to set the colours of the legend, the actors ribbon ...
            this.handleLegendProperty(this.legendProperty);

            this.close()
          })
          .catch(errors => {
            this.errors = errors
            console.log(errors, 'update hex colours error');
          })
      },
      handleLegendProperty (value) {
        this.$store.commit(FILTERS_MUTATION_TYPES.SET_LEGEND_PROPERTY, { property: value, items: this.getLegendItems(value)});
      },
    },
    created () {
      window.addEventListener('keyup', this.keyUpHandler)
    },
    mounted () {
      document.body.className = document.body.className + ' modal--open'
    },
    unmounted () {
      document.body.className = (document.body.className || '').replace('modal--open', '')
      window.removeEventListener('keyup', this.keyUpHandler)
    },
    mixins: [FiltersMixin],
  }
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";
.modal--scrollable {
  display: block!important;
  padding: 100px 0;
  overflow: auto;
  bottom: 0;
  >.modal__wrapper {
    display: block;
    margin: 0 auto;
  }
}
.modal--open .main-view,
.modal--open .main-view .actor-detail {
  overflow: hidden
}

.modal__body {
  .keyword {
      background-color:$color-background-light-grey;
      .keyword__label {
          color:$color-background-grey;
      }
  }
  .keyword::before {
      display:none;
  }
  .keyword:first-child {
      margin-top:10px;
  }
}

.colour_picker__body {
  padding: 0px !important;
  border-bottom: none !important;
  position: relative;
  z-index: 37;
  .colour_picker__colour-container {
    display: flex;
    overflow: hidden;
    flex-direction: row;
    flex-wrap: wrap;
    .colour_picker__colour-item {
      padding: .5em .5em 0em .5em;
      margin: 0 .2em .2em .2em;
      display: inline-block;
      vertical-align: middle;
      border: none;
      .legend_dropdown__color-items {
        width: 20px;
        height: 20px;
      }
      &:hover {
        .legend_dropdown__color-items {
          cursor: pointer;
          border: 1px solid;
        }
      }
    }
    .colour_picker__colour-item-has-error {
      .ds-input__input {
        border-color: $color-error;
      }
    }
  }
}
</style>
