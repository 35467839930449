<template>
  <div class="actor-file-repository">
    <div class="fixed-heading">
      <div class="card__general side-panel__header">
        <div class="card__title side-panel__actor-file-repository-card__title side-panel__actor-file-repository-header-card__title">{{ $t('manage_resources_panel_title') }}</div>
        <div class="pull-right side-panel__announcement-header-button-container" style="text-align: right;">
          <ds-button icon="remove" variant="minimal" @click="hidePreview" size="small" class="side-panel__announcement-header-icon"/>
        </div>
      </div>
    </div>
    <div class="scrollable side-panel__scrollable__content has-padding" style="padding-bottom: 150px">
      <div style="margin: auto;">
        <ds-button icon="plus" variant="outline" label="Upload a file" style="width: 100%; margin-bottom: 14px;" @click="createResource"/>
        <div v-for="file in actorFiles" class="home__section__article-section article-section__news_feed">
          <div class="article-preview__media-container" style="max-width: unset;">
            <div style="position: absolute;">
              <div class="file-thumbnail__private-indicator filelist__private-indicator" v-if="file.is_private">
                <Icon name="lock" size="small"/>
              </div>
            </div>
            <a :href="file.path" target="_blank" :class="['article-preview__media' , classes(file)]">
              <div v-if="resourceIcon(file.mimetype)" class="file-thumbnail__visual">
                <Icon :name="resourceIcon(file.mimetype)" size="large"/>
              </div>
              <div v-else class="article-preview__media-background-image" :style="{backgroundImage : 'url(' + file.preview + ')'}"></div>
              <div class="card__general article-section__items">
                <div class="article-item__container">
                  <div class="article-item__content-container">
                    <div class="article-item__data-container">
                      <div class="article-item__article-section-title">{{ file.title != 'File' ? humanize(file.title) : humanize(file.filename) }}</div>
                      <p class="article-item__date">{{ file.created_at && toDayFullMonth(file.created_at) }}</p>
                      <p class="article-item__date" v-if="this.actor.featured_image_url === file.preview">(featured)</p>
                      <div class="article-preview__tags" v-if="file.tags && file.tags.length">
                        <Tag v-for="(tag, index) in file.tags.slice(0,3)" :key="index"
                          :label="tag.label"
                          @click="setTagFilter(tag)"/>
                        <Tag v-if="file.tags.length > 3" label="..."/>
                      </div>
                      <p class="article-item__description" v-if="file.description">{{ file.description }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div v-if="userCanEditFile(file)" class="article-preview__media__edit-delete-container">
            <ds-button size="extra-small" variant="outline" :disabled="isDeletingFile" icon="edit" @click="showEditResourcePanel(file)"/>
            <ds-button size="extra-small" variant="outline" :disabled="isDeletingFile" icon="trash" @click="showConfirmationModal(file)"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Card from '../../components/Card/Card.vue'
  import FileThumbnail from '../../components/FileThumbnail/FileThumbnail.vue'
  import ArticlePreview from '../ArticlePreview/ArticlePreview.vue'
  import Tag from '../Tag/Tag.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { ACTION_TYPES as ACTOR_ACTION_TYPES } from '../../store/modules/actors'

  import KEYCODES from '../../constants/keycodes'
  import { humanize } from '../../constants/properties'
  import MODAL_IDS from '../../constants/modal-ids'

  import { toDayFullMonth } from '../../util/date'

  import CompanyMixin from '../../util/CompanyMixin'

  import { deleteActorFile } from '../../api/files'

  const FILE_CATEGORIES = {
    IMAGE: 'image',
    PDF: 'pdf',
    PRESENTATION: 'presentation',
    SPREADSHEET: 'spreadsheet',
  }

  export default {
    name: 'actor-file-repository-panel',
    data () {
      return {
        errors: {},
        goToEditResource: false,
        isDeletingFile: false,
      }
    },
    props: {
      actor: Object,
    },
    computed: {
      publisher () {
        return this.$store.getters.publisher
      },
      actorFiles () {
        if (!this.actor.files) {
          return
        }

        const actorFiles = [...this.actor.files]

        if (this.actor.website_screenshot) {
          actorFiles.push({
            approved: true,
            created_at: '',
            mimetype: 'image/png',
            description: this.actor.actor_type === 'Product' ? 'This image is automatically fetched from pexels.com.' : 'This image is automatically fetched from the actor\'s website',
            is_private: false,
            url: this.actor.website_screenshot,
            id: null,
            tags: [],
            title: this.actor.actor_type === 'Product' ? 'Placeholder image' : 'Website Screenshot',
            isWebsiteScreenshot: true,
            originalName: this.actor.actor_type === 'Product' ? 'Placeholder image' : 'Website Screenshot',
          })
        }

        return actorFiles
          .map(file => {
            let path = file.url

            if (file.mimetype === 'link' || file.mimetype === 'video_link') {
              path = file.originalName
            }

            let deletable = true

            if (file.hasOwnProperty('deletable')) {
              deletable = file.deletable
            }

            return {
              actor_id: this.actor.id,
              actor_name: this.actor.name,
              approved: file.approved,
              deletable: deletable,
              created_at: file.created_at,
              description: file.description,
              filename: file.originalName,
              isPrivate: file.is_private,
              is_private: file.is_private,
              meta: [file.mimetype],
              mimetype: file.mimetype,
              path: path,
              preview: file.url || file.preview_w766 || file.preview_w320 || file.preview_w50,
              sql_media_id: file.id,
              isWebsiteScreenshot: file.isWebsiteScreenshot,
              tags: file.tags,
              title: file.title,
              mentions: file.mentions,
            }
          })
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      isMember () {
        return this.$store.getters.isMember
      },
      company () {
        return this.$store.getters.detailActor
      },
    },
    methods: {
      humanize,
      toDayFullMonth,
      userCanEditFile (file) {
        return this.userCanEdit && file.deletable
      },
      hidePreview (evt) {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      showEditResourcePanel (file) {
        this.goToEditResource = true
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          'component': 'edit-resource-panel',
          metaData: { resource: file, actor: this.actor, isFromFileRepositoryPanel: true },
        })
      },
      showConfirmationModal (resource) {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          body: 'Do you want to delete this resource?',
          resource: resource,
          modalContextType: 'file',
        })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
      },
      async deleteWebsiteScreenshot () {
        await this.$store.dispatch(ACTOR_ACTION_TYPES.UPDATE_ACTOR, {
          id: this.actor.id,
          data: { website_screenshot: '' },
        })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          component: 'actor-file-repository',
          metaData: this.$store.getters.detailActor,
        })
      },
      deleteActorFile (actorId, fileID) {
        this.isDeletingFile = true

        deleteActorFile(actorId, fileID)
          .catch(errors => {
            this.isDeletingFile = false
            console.log(errors)
          })
          .then(async () => {
            this.isDeletingFile = false

            await this.$store.dispatch('ACTORS/FETCH_ACTOR_DETAIL', true)
            this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
              component: 'actor-file-repository',
              metaData: this.$store.getters.detailActor, // Fetch the data straight from the state instead of via the computed property
            })
          })
      },
      classes (file) {
        return [
          'file-thumbnail',
          `file-thumbnail--${this.category(file.mimetype)}`,
          file.isPrivate && this.publisher ? 'file-thumbnail--private' : '',
        ].join(' ')
      },
      category (mimeType) {
        if (mimeType.startsWith('image/')) {
          return FILE_CATEGORIES.IMAGE
        } else if (mimeType.includes('pdf')) {
          return FILE_CATEGORIES.PDF
        } else if (mimeType.includes('presentation')) {
          return FILE_CATEGORIES.PRESENTATION
        } else if (mimeType.includes('spreadsheet')) {
          return FILE_CATEGORIES.SPREADSHEET
        }

        return 'default'
      },
      resourceIcon (mimeType) {
        switch (this.category(mimeType)) {
          case FILE_CATEGORIES.PDF: {
            return 'file-pdf'
          }
          case FILE_CATEGORIES.PRESENTATION: {
            return 'file-presentation'
          }
          case FILE_CATEGORIES.SPREADSHEET: {
            return 'file-spreadsheet'
          }
          case FILE_CATEGORIES.IMAGE: {
            return null
          }
          default: {
            return 'file'
          }
        }
      },
      isFeaturable (file) {
        return file.mimetype && file.mimetype.startsWith('image') && this.userCanEdit
      },
      setFeaturedImage (file) {
        const newFile = file && file.preview != this.company.featured_image_url ? file.preview : null

        if (!newFile) {
          return
        }

        const change = {
          featured_image_url: newFile,
        }

        this.$store.dispatch(ACTOR_ACTION_TYPES.UPDATE_ACTOR, { id: this.actor.id, data: change })
          .then(() => {
            // Update actor in the state
            this.$store.dispatch('ACTORS/FETCH_ACTOR_DETAIL', true)
          })
          .catch(errors => {
            this.saving = false
            this.errors = errors || {}
          })
      },
      createResource () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          component: 'create-resource-panel',
          metaData: {
            actor_id: this.actor.id,
            visibleMediaInputField: 'resource',
            closeCallback: () => {
              this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'actor-file-repository', metaData: this.actor })

              this.$store.dispatch('ACTORS/FETCH_ACTOR_DETAIL', true).then(() => {
                this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'actor-file-repository', metaData: this.$store.state.actors.detail.data })
              })
            },
          },
        })
      },
    },
    mixins: [
      CompanyMixin,
    ],
    mounted () {
      this.$bus.on('fileDeleteConfirmation', async (context) => {
        if (!context.resource.isWebsiteScreenshot) {
          return this.deleteActorFile(this.actor.id, context.resource.sql_media_id)
        }
        await this.deleteWebsiteScreenshot()
      })

      window.addEventListener('keyup', (e) => {
        if (e.keyCode === KEYCODES.ESC) {
          this.hidePreview()
        }
      })

      this.goToEditResource = false
    },
    beforeUnmount () {
      this.$bus.off('fileDeleteConfirmation')

      if (!this.goToEditResource && this.$store.state.ui.sidePanel.selectedComponent === 'actor-file-repository') {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      }
    },
    components: {
      Card,
      FileThumbnail,
      ArticlePreview,
      Tag,
    },
  }
</script>

<style lang="scss">
  .filelist__private-indicator {
    position: relative;
    z-index: 10 !important;
  }

  .filelist__bookmark-indicator {
    position: relative;
    z-index: 10 !important;
    top: 0.5rem;
    left: 0.5rem;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    .svg-icon {
      left: 2px !important;
    }

    .button--tiny {
      padding: 0;
    }
  }
</style>
