<template>
  <div>
    <form-group :label="humanizeSuggestion(property)" v-for="(suggestions, property) in byProp" :key="property">
      <div v-for="suggestion in suggestions">
          <checkbox
            multiple
            v-model="selection"
            :label="getValueFor(suggestion)"
            :val="suggestion.id"
            :href="getHrefFor(suggestion)"
            :meta="getMeta(suggestion)"
            :inlineLink="getInlineLink(suggestion)"
          >
            <ds-button
              v-if="property === 'company_number' && !showDuplicatesWarning(property, suggestion.identifier_id)"
              @click="addActorForCompanyNumberSuggestion(suggestion)"
              variant="add-actor"
              icon="plus"
              label="Add as actor"
              size="extra-small"
              class="suggestions-add-actor-button"
            />
            <ds-button
              v-if="property === 'description'"
              @click="appendDescription(suggestion)"
              variant="add-actor"
              icon="plus"
              label="Append to the description"
              size="extra-small"
              class="suggestions-add-actor-button"
            />
          </checkbox>
        <a href="#" v-if="hasDuplicates(property, suggestion.identifier_id) && $store.getters.isMember" @click="openDuplicatesPanel" style="margin-left: 20px;">
          There are other actors with the same value.
        </a>
      </div>
    </form-group>

    <p v-if="suggestions.length > 2">
      <checkbox label="Select all" @update:modelValue="selectAll" :model-value="suggestions.length === selection.length" />
    </p>
    <div class="actor-suggestions-action-button-conatiner">
      <ds-button variant="secondary" label="Accept selected suggestions" @click="accept" :disabled="saving" />
      <ds-button variant="outline" label="Deny selected suggestions" @click="deny" />
    </div>
  </div>
</template>

<script>
  import _groupBy from 'lodash/groupBy'
  import _flatten from 'lodash/flatten'
  import objectPath from 'object-path'

  import { mergeSuggestion, Suggestions } from '../../api/heartbeat.js'

  import Checkbox from '../Form/Checkbox.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { ACTION_TYPES as ACTOR_ACTION_TYPES } from '../../store/modules/actors'

  import MODAL_IDS from '../../constants/modal-ids'
  import { trackHeapEvent } from '../../util/analytics'
  import { humanize } from '../../constants/properties'

  function extractArray (array, by) {
    return _flatten(array.map(v => extractBy(v, by)))
  }

  function extractBy (item, by) {
    const value = item[by]
    if (Array.isArray(value)) {
      return value.map(v => Object.assign({}, item, { [by]: v }))
    }
    return [item]
  }

  export default {
    props: ['actor', 'suggestions', 'duplicates'],
    data () {
      return {
        selection: [],
        saving: false,
      }
    },
    computed: {
      ecosystemRelationships () {
        return this.$store.getters.fullActorRelationships
      },
      filterableRelationships () {
        return this.$store.getters.filterableRelationships
      },
      byProp () {
        var singleValueSuggestions = this.suggestions.filter(s => s.property != 'domains' && s.property != 'industries');

        singleValueSuggestions = _groupBy(extractArray(this.suggestions, ['value']), (suggestion) => {
          return suggestion.property
        })

        var multiValueSuggestions = this.suggestions.filter(s => s.property == 'domains' || s.property == 'industries')
        multiValueSuggestions = _groupBy(extractArray(multiValueSuggestions, ['value', 'id']), (suggestion) => {
            return suggestion.property
        })

        return Object.assign({}, singleValueSuggestions, multiValueSuggestions);
      }
    },
    methods: {
      getMeta (suggestion) {
        if (suggestion.meta && suggestion.meta.name) {
          var meta = (suggestion.meta.name ? suggestion.meta.name + ', ' : '') + (suggestion.meta.address_line ? suggestion.meta.address_line : '');

          meta.replace(/,\s*$/, "");

          if (suggestion.meta.full && suggestion.meta.full.inactive) {
            meta = meta + ' - inactive';
          }

          return meta;
        }
      },
      getValueFor (suggestion) {
        if (suggestion.value && suggestion.value.handle) {
          return suggestion.value.handle
        }

        if (suggestion.property == 'address') {
          if (suggestion.value['zip'] || suggestion.value['city']) {
            return suggestion.value['zip'] + ' ' + suggestion.value['city'] + ', ' + suggestion.value['country']
          }

          return suggestion.value['country']
        }

        // Check for a hierarchical suggestion (i.e. domains, industries, ...)
        if (suggestion.property == 'domains' || suggestion.property == 'industries') {
          if (suggestion.value.length == 2) {
            return suggestion.value[0].name + ' (' + suggestion.value[1].name + ')'
          }

          return suggestion.value[0].name
        }

        if (suggestion.value && suggestion.value.name) {
          return suggestion.value.name
        }

        return suggestion.value
      },
      getHrefFor (suggestion) {
        if (['facebook', 'twitter'].includes(suggestion.property)) {
          return suggestion.value.handle;
        }

        if (['linkedin', 'url', 'youtube', 'instagram', 'wikipedia', 'rss_feed'].includes(suggestion.property)) {
          return suggestion.value
        }

        if (suggestion.meta && suggestion.meta.url) {
          return suggestion.meta.url;
        }
      },
      getInlineLink (suggestion) {
        if (! this.filterableRelationships.includes(suggestion.property)) {
          return;
        }

        return suggestion.value && suggestion.value.url;
      },
      humanizeSuggestion (property) {
        // Make a human readable suggestion label for the property of the suggestion
        if (property == 'subsidiaries') {
          return 'Offices';
        }

        if (this.filterableRelationships.includes(property)) {
          var config = this.ecosystemRelationships.filter(config => config.name == property);

          if (config) {
            return config[0]['label'];
          }
        }

        return humanize(property);
      },
      selectAll (value) {
        this.selection = value ? this.suggestions.map(s => s.id) : []
      },
      accept () {
        this.saving = true

        const data = this.selection.map(id => ({
          id,
          status: 'accepted',
        }))

        Suggestions.post(data)
        .then(() => {
          this.$emit('change');
          this.saving = false;
        })
        .catch(errors => {
          this.saving = false;
          this.$emit("error", errors)
        });
      },
      deny () {
        this.saving = true;

        const data = this.selection.map(id => ({
          id,
          status: 'denied'
        }));

        Suggestions.post(data)
          .then(() => {
            this.$emit('change');
            this.saving = false;
          })
          .catch(() => {
            this.saving = false;
          });
      },
      hasDuplicates(property, identifierId) {
        for (const duplicate of this.duplicates) {
          for (let i = 0; i < duplicate.properties.length; i++) {
            if (duplicate.properties[i] === property && duplicate.property_identifier_ids[i] == identifierId) {
              return true
            }
          }
        }

        return false
      },
      showDuplicatesWarning(property, identifierId) {
        return (
          this.hasDuplicates(property, identifierId) &&
          this.$store.getters.isMember &&
          this.isDuplicateFeatureFlagEnabled
        );
      },
      openDuplicatesPanel () {
        this.$emit('openDuplicatesPanel')
      },
      appendDescription (suggestion) {
        trackHeapEvent('actorSuggestions.appendDescription', {suggestion});

        mergeSuggestion(suggestion.id)
          .then(response => {
            this.$emit('handledSuggestion', suggestion.id);
            this.$store.dispatch(ACTOR_ACTION_TYPES.FETCH_ACTOR_DETAIL, this.actor.id);
          })
          .catch(err => {
            this.$emit('handledSuggestion', suggestion.id);
          })
      },
      addActorForCompanyNumberSuggestion (suggestion) {
        trackHeapEvent('actorSuggestions.addAsActor', {suggestion});

        let jurisdiction = objectPath.get(suggestion, 'meta.full.jurisdiction_code', null)
        if (jurisdiction !== null) jurisdiction = jurisdiction.toUpperCase()

        const modalContext = {
          prefilled: {
            name: objectPath.get(suggestion, 'meta.full.name', null),
            actor_type: 'LegalEntity',
            company_number: suggestion.value,
            jurisdiction: jurisdiction,
          },
          successCallback: () => {
            Suggestions.post([{
              id: suggestion.id,
              status: 'denied',
            }])

            this.$emit('handledSuggestion', suggestion.id)
          },
        }

        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, modalContext)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)
      },
    },
    components: {
      Checkbox
    }
  }
</script>

<style>
  .button.suggestions-add-actor-button {
    padding: 0 0 0 6px;
    line-height: 11px;
    font-size: 10px;
    vertical-align: top;
  }
</style>
