<template>
  <Drawer :isOpen="isOpen" padded @close="$emit('close')">
    <SuggestionInput
      id="mobile-actor-search-input"
      placeholder="Search company, investor, university..."
      icon="search"
      :api-settings="actorSuggestionSettings.api"
      :clear-on-select="true"
      ref="suggestionInput"
      @actor="handleSelectActor"
    />
  </Drawer>
</template>

<script>
  import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../../store/modules/actors';
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui';
  import escapeHTML from '../../util/escapeHTML';

  import SuggestionInput from '../SuggestionInput/SuggestionInput.vue';
  import Drawer from '../Drawer/Drawer.vue';
  import { trackHeapEvent } from '../../util/analytics';

  export default {
    name: 'MobileSearchDrawer',
    props: {
      isOpen: Boolean,
    },
    data() {
      return {
        actorSuggestionSettings: {
          api: {
            url: '/api/actors?name={query}',
            onResponse: data => ({
              results: Object.values(data).map(company => ({
                title: escapeHTML(company.name),
                data: company,
              })),
            })
          }
        }
      }
    },
    methods: {
      handleSelectActor (actor) {
        trackHeapEvent('global.search.clickSearchedActor', { id: actor.id, medium: 'mobile' });

        if (this.$store.getters.views.includes('actors/detail')) {
          // Be sure to fetch it, because ActorDetail may not.
          this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_DETAIL, actor.id)
          this.$router.push(`/actors/${actor.id}`)
        } else if (this.$store.getters.isMember) {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component:"scores", metaData: actor.id})
        } else {
          this.$router.push(`/actors`)
          /*this.$store.dispatch('ACTORS/FETCH_ACTOR_FOR_PREVIEW', actor)
            .then(actor => {
              try {
                this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component:"scores", metaData: actor.id})
                this.$root.$children[0].$refs.view.$refs.mapPane.forceVisibleMarker(actor)
              } catch (e) {}
            })*/
          this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component:"scores", metaData: actor.id})
        }
      },
    },
    watch: {
      isOpen () {
        if (this.isOpen) {
          this.$refs.suggestionInput.focus()
        } else {
          this.$refs.suggestionInput.blur()
        }
      }
    },
    components: {
      Drawer,
      SuggestionInput,
    }
  }
</script>

<style lang="scss">
  @import "../../../scss/variables";
  @import "../../../scss/mixins/icon-color";
</style>
