<template>
  <div class="simple-article-preview" :class="`simple-article-preview--image-${imagePosition}`">
    <div class="simple-article-preview__image" :style="{ backgroundImage: `url(${image})`, order: imagePosition === 'left' ? 1 : 3 }">
      <ul class="simple-article-preview__community-stats">
        <li>
          <Icon name="thumbs-up" />
          <div>{{likeCount}}</div>
        </li>
        <li>
          <Icon name="comment" />
          <div>{{commentCount}}</div>
        </li>
      </ul>
    </div>
    <div class="simple-article-preview__content" :style="contentContainerStyles">
      <h3 class="simple-article-preview__title">{{title}}</h3>
      <div class="simple-article-preview__body" v-html="body"></div>
      <template v-if="displayCta">
        <div class="text-center">
          <template v-if="isLinkInternal">
            <ds-button variant="primary" :label="ctaText" :to="internalLink" @click="trackClickAction" :style="actionButtonStyles"/>
          </template>
          <template v-else>
            <ds-button variant="primary" :label="ctaText" :href="this.cta" @click="trackClickAction" :style="actionButtonStyles"/>
          </template>
        </div>
      </template>
      <template v-else>
        <router-link v-if="to" :to="to" class="simple-article-preview__read-more" :style="actionButtonStyles">
          {{ $t('announcement_call_to_action') }}
        </router-link>
      </template>
    </div>
  </div>
</template>

<script>
  import { darken } from 'polished'
  import { trackHeapEvent } from '../util/analytics'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: "SimpleArticlePreview",
    props: {
      notificationId: String,
      title: String,
      body: String,
      image: String,
      to: String,
      commentCount: Number,
      likeCount: Number,
      imagePosition: {
        type: String,
        default: 'left',
        validator: function (value) {
          return ['left', 'right'].includes(value)
        },
      },
      cta: '',
      ctaText: '',
    },
    methods: {
      trackClickAction() {
        trackHeapEvent('global.articlePreview.clickActionButton')
      },
    },
    computed: {
      displayCta () {
        // Display the call to action directly on the preview if the message of the announcement is entirely displayed
        return (this.body && !this.body.endsWith('...'))
          && this.cta && this.ctaText && this.ctaText.length > 0
      },
      config () {
        return this.$store.state.config
      },
      contentContainerStyles () {
        return {
          backgroundColor: this.config.primaryColor,
        }
      },
      actionButtonStyles () {
        return {
          backgroundColor: darken(0.25, this.config.primaryColor),
        }
      },
      internalLink () {
        // Return the relative path of the link so the router-link can use it
        if (!this.cta) {
          return
        }

        return this.cta.replace(window.location.protocol + '//' + window.location.hostname, '')
      },
      isLinkInternal () {
        return this.cta && this.cta.includes(window.location.hostname) && !this.action_url.includes('files')
      },
    },
  })
</script>

<style lang="scss">
  @import "resources/assets/scss/variables";

  .simple-article-preview {
    margin-top: 1rem;
    display: flex;

    @media (max-width: $screen-md) {
      flex-direction: column;

      .simple-article-preview__community-stats {
        left: 1rem !important;
        right: initial !important;
      }

      .simple-article-preview__image,
      .simple-article-preview__content {
        width: 100%;
        max-width: none;
      }
    }
  }

  .simple-article-preview--image-left {
    .simple-article-preview__community-stats {
      left: 1rem;
    }
  }

  .simple-article-preview--image-right {
    .simple-article-preview__community-stats {
      right: 1rem;
    }
  }

  .simple-article-preview__image,
  .simple-article-preview__content {
    flex: 1;
    min-width: 50%;
    max-width: 50%;
  }

  .simple-article-preview__image {
    order: 1;
    position: relative;
    background-size: cover;
    background-position: center;

    @media (max-width: $screen-md) {
      order: 1 !important;
      min-height: 300px;
      max-height: 300px;
    }
  }

  .simple-article-preview__community-stats {
    display: inline-flex;
    align-items: center;
    position: absolute;
    bottom: 1rem;
    padding: 0.5rem;
    list-style: none;
    background-color: #f7f7f7;

    li {
      display: inline-flex;
      align-items: center;
      margin-right: 0.5rem;

      .svg-icon {
        margin-right: 0.25rem;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .simple-article-preview__content {
    order: 2;
    padding: 2rem;
    color: #fff;
    text-align: center;
  }

  .simple-article-preview__title {
    text-transform: uppercase;
    letter-spacing: 0.5px;
  }

  .simple-article-preview__body {
    margin-top: 1rem;
    font-size: 1rem;

    p {
      font-size: 1rem;
    }

    a {
      color: inherit;
    }
  }

  .simple-article-preview__read-more {
    display: inline-block;
    margin-top: 2rem;
    padding: 0.75rem 2rem;
    border: 0;
    color: #fff;
    font-family: $font-stack-primary;
    font-size: 1rem;
    text-transform: uppercase;
    text-decoration: none;
  }
</style>
