<template>
  <div :class="isMobile ? 'mobile-category-count' : 'category-count'" @click="handleClick">
    <div class="category-count__label" :style="{ borderLeft: '5px solid ' + color}">
      <div>
        {{label}} ({{count}})
      </div>
    </div>
  </div>
</template>

<script>
  import { doColorsHaveSufficientContrast } from '../../util/color'
  import { getTranslationsForTaxonomyValue } from '../../store/modules/taxonomies'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'CategoryCount',
    props: {
      count: Number,
      taxonomyId: [Number, String],
      property: String,
      color: String,
    },
    emits: ['click'],
    computed: {
      labelFontColor () {
        return doColorsHaveSufficientContrast(
          '#ffffff',
          this.color,
        ) ? '#fff' : '#000'
      },
      isMobile () {
        return this.$store.getters.isMobile
      },
      locale () {
        return this.$store.getters.activeLocale
      },
      label () {
        var translations = getTranslationsForTaxonomyValue(this.property, this.taxonomyId)

        if (translations && this.locale && translations[this.locale] && translations[this.locale].name) {
          return translations[this.locale].name
        }

        return translations['en'].name
      },
    },
    methods: {
      handleClick () {
        this.$emit('click')
      },
    },
  })
</script>

<style lang="scss">
  @import "../../../scss/variables";

  $category-count-height: 40px;

  .category-count {
    display: flex;
    height: $category-count-height;
    border: 1px solid $color-borders;
    cursor: pointer;
  }

  .mobile-category-count {
    display: flex;
    height: $category-count-height;
    border: 1px solid $color-borders;
  }

  .category-count__label {
    padding: 0 14px;
    display: flex;
    flex: 1;
    color: $color-secondary;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 0.75rem;
    letter-spacing: 0.7px;
    text-transform: uppercase;

    div {
      align-self: center;
    }
  }
</style>
