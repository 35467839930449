<template>
  <modal
    :id="modalId"
    :componenyKey="componentKey"
    class="modal__has-header-tabs logged-out-modal"
    ref="modal"
    :title="'Logged Out'"
    @close="close"
    is-simplified-modal
  >
    <template v-slot:body>
      <div>
        <div class="announcement-edit__title-container">
          Your session has expired. Please <a href="#" @click.prevent="openLoginModal">Login</a>
        </div>
      </div>
    </template>
    <template v-slot:footer>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import TagsMixin from '../../util/TagsMixin.js'
  import FiltersMixin from '../../util/FiltersMixin'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '@/store/modules/ui'
  import { MUTATION_TYPES as USER_MUTATION_TYPES } from '@/store/modules/user-helpers'

  export default {
    data () {
      return {
        modalId: MODAL_IDS.LOGGED_OUT,
        componentKey: 0,
      }
    },
    methods: {
      openLoginModal () {
        this.$store.commit(USER_MUTATION_TYPES.LOGOUT_SUCCESS)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.LOGGED_OUT)

        const redirectUrl = window.location.href.replace(window.config.url + '/', '')

        window.location.href = window.config.url + '/login?redirect_uri=' + redirectUrl
      },
    },
    components: {
      Modal,
    },
    mixins: [TranslationsMixin, TagsMixin, FiltersMixin],
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/_variables.scss";

  .announcement-edit__title-container {
    background: var(--primary-community-extra-lightest);
    padding: 20px;

    :deep(input, .time-picker, .datepicker, .multiselect.multiselect--datascouts .multiselect__tags) {
      border: 0;

      &::placeholder {
        color: var(--primary-community);
      }
    }
  }

  .announcement-edit__content-container {
    padding: 20px 20px 17px 20px;

    :deep(input, .datepicker, .time-picker) {
      border: 1;

      &::placeholder {
        color: var(--primary-community);
      }
    }

    .multiselect.multiselect--datascouts {
      margin-right: 0px !important;
    }

    .form-group__error {
      font-size: 14px;
      margin-bottom: 7px;
    }
  }

  .modal__footer {
    width: 100%;

    .button {
      width: 100%;
      border-radius: $default-border-radius-narrow;
    }
  }

  .modal__header-tabs {
    display: flex;
    justify-content: flex-start;
    background: white;
    flex: 1;
    // max-width: 90%;

    > .tab-title {
      cursor: pointer;
      flex: 1;

      &.active {
        background: var(--primary-community-extra-lightest) !important;
      }

      &:last-child {
        margin-right: 58px;
      }

      > .title {
        padding: 20px !important;
        font-size: 14px !important;
      }
    }
  }

  .d-flex {
    display: flex;
  }

  .form-group {
    margin-bottom: 0px;
  }
</style>
