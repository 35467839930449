import { fetchAllConceptSearches, fetchConceptSearch } from '../../api/exploration'
import ls from '../../util/ls.js'
import { inert } from '../../util/helpers.js'

const state = {
  allConceptSearchesPromise: null,
  allConceptSearches: null,
  autoReload: ls('conceptSearchAutoReload') || false,
  detail: {
    data: {},
    loading: false,
    errorId: null,
  },
  filters: [],
}

export const MutationTypes = {
  SET_ALL_CONCEPT_SEARCHES: 'concept-searches/SET_ALL_CONCEPT_SEARCHES',
  SET_ALL_CONCEPT_SEARCHES_PROMISE: 'concept-searches/SET_ALL_CONCEPT_SEARCHES_PROMISE',
  PUBLISH_CONCEPT_SEARCH: 'concept-search/PUBLISH_CONCEPT_SEARCH',
  UNPUBLISH_CONCEPT_SEARCH: 'concept-search/UNPUBLISH_CONCEPT_SEARCH',
  TOGGLE_AUTO_RELOAD: 'concept-searches/TOGGLE_AUTO_RELOAD',
  FETCH_CONCEPT_SEARCH_DETAIL_PENDING: 'concept-searches/FETCH_CONCEPT_SEARCH_DETAIL_PENDING',
  FETCH_CONCEPT_SEARCH_DETAIL_SUCCESS: 'concept-searches/FETCH_CONCEPT_SEARCH_DETAIL_SUCCESS',
  FETCH_CONCEPT_SEARCH_DETAIL_FAILURE: 'concept-searches/FETCH_CONCEPT_SEARCH_DETAIL_FAILURE',
  CLEAR_CONCEPT_SEARCH_DETAIL: 'concept-searches/CLEAR_CONCEPT_SEARCH_DETAIL',
  SET_CONCEPT_SEARCH_FILTER: 'concept-searches/SET_CONCEPT_SEARCH_FILTER',
  CLEAR_CONCEPT_SEARCH_FILTER: 'concept-searches/CLEAR_CONCEPT_SEARCH_FILTER',
}

export const ActionTypes = {
  FETCH_ALL_CONCEPT_SEARCHES: 'concept-searches/FETCH_ALL_CONCEPT_SEARCHES',
  FETCH_CONCEPT_SEARCH_DETAIL: 'concept-searches/FETCH_CONCEPT_SEARCH_DETAIL',
}

const mutations = {
  [MutationTypes.SET_ALL_CONCEPT_SEARCHES] (state, allConceptSearches) {
    state.allConceptSearches = allConceptSearches
  },
  [MutationTypes.SET_ALL_CONCEPT_SEARCHES_PROMISE] (state, allConceptSearchesPromise) {
    state.allConceptSearchesPromise = allConceptSearchesPromise
  },
  [MutationTypes.TOGGLE_AUTO_RELOAD] (state) {
    state.autoReload = !state.autoReload
    ls('conceptSearchAutoReload', state.autoReload)
  },
  [MutationTypes.PUBLISH_CONCEPT_SEARCH] (state, conceptSearchId) {
    var conceptSearch = state.allConceptSearches.find(search => search.id == conceptSearchId)
    conceptSearch.published = true

    if (state.detail.data) {
      state.detail.data.published = true
    }
  },
  [MutationTypes.UNPUBLISH_CONCEPT_SEARCH] (state, conceptSearchId) {
    var conceptSearch = state.allConceptSearches.find(search => search.id == conceptSearchId)
    conceptSearch.published = false

    if (state.detail.data) {
      state.detail.data.published = false
    }
  },
  [MutationTypes.FETCH_CONCEPT_SEARCH_DETAIL_PENDING] (state, id) {
    state.detail.loading = Number(id)
  },
  [MutationTypes.FETCH_CONCEPT_SEARCH_DETAIL_SUCCESS] (state, conceptSearch) {
    if (state.detail.loading === conceptSearch.id) {
      state.detail.data = conceptSearch
    }

    state.detail.error = null
    state.detail.loading = false
  },
  [MutationTypes.FETCH_CONCEPT_SEARCH_DETAIL_FAILURE] (state, error) {
    state.detail.data = {}
    state.detail.errorId = state.detail.loading
    state.detail.error = error.message
    state.detail.loading = false
    state.detail.errorStatusCode = error.statusCode
  },
  [MutationTypes.CLEAR_CONCEPT_SEARCH_DETAIL] (state, error) {
    state.detail.data = {}
    state.detail.error = null
    state.detail.loading = false
  },
  [MutationTypes.SET_CONCEPT_SEARCH_FILTER] (state, filters) {
    state.filters = state.filters.filter(search => search.conceptSearchId !== filters.conceptSearchId)
    state.filters.push(inert(filters))
  },
  [MutationTypes.CLEAR_CONCEPT_SEARCH_FILTER] (state, conceptSearchId) {
    state.filters = state.filters.filter(search => search.conceptSearchId !== conceptSearchId)
  },
}

const actions = {
  [ActionTypes.FETCH_ALL_CONCEPT_SEARCHES] ({ commit }, filters) {
    const promise = fetchAllConceptSearches(filters || []).then(allConceptSearches => {
      commit(MutationTypes.SET_ALL_CONCEPT_SEARCHES, allConceptSearches)
    })

    commit(MutationTypes.SET_ALL_CONCEPT_SEARCHES_PROMISE, promise)
  },
  [ActionTypes.FETCH_CONCEPT_SEARCH_DETAIL] ({ commit }, id) {
    commit(MutationTypes.FETCH_CONCEPT_SEARCH_DETAIL_PENDING, id)

    return fetchConceptSearch(id)
      .then(conceptSearch => {
        commit(MutationTypes.FETCH_CONCEPT_SEARCH_DETAIL_SUCCESS, conceptSearch)
      })
      .catch(error => {
        commit(MutationTypes.FETCH_CONCEPT_SEARCH_DETAIL_FAILURE, { message: ': An error occurred while fetching a concept search detail.', statusCode: error.statusCode })
      })
  },
}

const getters = {
  hasAtLeastOneConceptSearch (state) {
    return state.allConceptSearches !== null && state.allConceptSearches.length > 0
  },
  conceptSearchAutoReloadEnabled (state) {
    return state.autoReload
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
