import { createJSONRequest, createResource } from './create-request'

export const Activities = createResource('/api/taxonomies/activities')
export const Memberships = createResource('/api/taxonomies/memberships')
export const Technologies = createResource('/api/taxonomies/technologies')
export const Stages = createResource('/api/taxonomies/stages')
export const Expertises = createResource('/api/taxonomies/expertises')
export const Motivations = createResource('/api/taxonomies/motivations')
export const ReadinessLevels = createResource('/api/taxonomies/readiness_levels')
export const BusinessAspects = createResource('/api/taxonomies/business_aspects')
export const ProcessSteps = createResource('/api/taxonomies/process_steps')
export const FunnelStages = createResource('/api/taxonomies/funnel_stages')
export const Categories = createResource('/api/taxonomies/categories')
export const Aspects = createResource('/api/taxonomies/aspects')
export const Domains = createResource('/api/taxonomies/domains')
export const Industries = createResource('/api/taxonomies/industries')
export const ProductFeaturesA = createResource('/api/taxonomies/product_features_a')
export const ProductFeaturesB = createResource('/api/taxonomies/product_features_b')
export const ProductFeaturesC = createResource('/api/taxonomies/product_features_c')
export const AnnouncementCategories = createResource('/api/taxonomies/announcement_categories')
export const EventCategories = createResource('/api/taxonomies/event_categories')

export const getAllTaxonomies = () => createJSONRequest({
  method: 'GET',
  url: '/api/taxonomies/all'
})

export const updateHexColour = (data) => createJSONRequest({
  method: 'PUT',
  url: `/api/taxonomies/colours`,
  data
})

export const fetchSdgs = () => createJSONRequest({
  method: 'GET',
  url: `/api/sdgs`
})
