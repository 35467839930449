<template>
  <div class="has-action-bar">
    <div class="row">
      <form-group label="Select a CRM" class="col-xs-6 col-md-4">
        <dropdown :options="crmTypes" v-model="selectedCrm"/>
      </form-group>
    </div>

    <div v-if="selectedCrm">
      <h2 class="h2">Authorization</h2>
      <form-group label="" class="col-xs-6 col-md-4">
        <template v-if="selectedCrm == 'teamleader'">
          <form-group label="Group id">
            <ds-input v-model="form.crmIntegrations.teamleader.keys.group_id"/>
          </form-group>
          <form-group label="API key">
            <ds-input v-model="form.crmIntegrations.teamleader.keys.api_key"/>
          </form-group>
        </template>
        <template v-else-if="selectedCrm == 'active_campaign'">
          <form-group label="Account URL">
            <ds-input placeholder="https://xxxxx.api-us1.com"
                      v-model="form.crmIntegrations.active_campaign.keys.account_url"/>
          </form-group>
          <form-group label="API key">
            <ds-input v-model="form.crmIntegrations.active_campaign.keys.account_key"/>
          </form-group>
        </template>
      </form-group>

      <div v-if="canShowSync">
        <form-group label="Import" class="col-xs-12 col-md-12">
          <checkbox v-model="importEnabled"
                    label="Import companies with a specific tag. New created companies or updated companies with at least one of the configured tags in the CRM below will be added to DataScouts."/>
          <div class="row" v-if="importEnabled">
            <form-group label="Tags to look for" class="col-xs-6 col-md-4">
              <tag-input v-model="importTags"/>
            </form-group>
          </div>
        </form-group>
        <form-group label="Full synchronisation" class="col-xs-12 col-md-12">
          <checkbox v-model="fullSyncEnabled"
                    label="Enable the synchronisation of data between DataScouts (actor profiles and users) and Teamleader"/>
          <br/>
          <div>
            <p>DataScouts will sync actor profiles with Teamleader and match them at best effort. The properties that
              will
              be synced are:</p>
            <ul style="margin-left: 20px;">
              <li>
                name
              </li>
              <li>
                email
              </li>
              <li>
                phone
              </li>
              <li>
                url
              </li>
              <li>
                address
              </li>
            </ul>
            <br/>
            <p>
              If you configure a custom field on a company named "DataScouts link", then DataScouts will fill the field
              with a link to the corresponding actor in DataScouts, in that custom field in Teamleader.
            </p>
          </div>
          <br/>
        </form-group>

        <div class="heading" v-if="displaySyncTabs">
          <tabs :tabs="tabs">
            <template v-slot:[getTabName(0)]>
              <div>
                <sync-logs :crmDisplayName="selectedCrmDisplayName" :crm="selectedCrm"/>
              </div>
            </template>
            <template v-slot:[getTabName(1)]>
              <div>
                <div class="row">
                  <form-group
                    :label="'Select the portfolio that you want to sync to ' + selectedCrmDisplayName + '.'"
                    class="col-xs-6 col-md-4">
                    <dropdown :options="portfolios" v-model="selectedManualSyncPortfolio"/>
                    <div style="margin-top: 5px; font-size:12px;">We will sync <b>only persons with an email</b>, together
                      with their name, private tags, email and phone number.
                    </div>
                  </form-group>
                </div>

                <template v-if="manualSyncState !== 'completed'">
                  <ds-button variant="secondary" label="Start sync" @click="startManualSync"
                             :disabled="! selectedManualSyncPortfolio || manualSyncState === 'syncing'"/>
                  <div class="form-group__error" v-if="Object.keys(errors).length" style="font-size: 14px">
                    <div v-for="error in errors">
                      {{ Array.isArray(error) ? error[0] : error }}
                    </div>
                  </div>
                </template>
                <template v-else>
                  We have started syncing the actors in the selected portfolio to {{selectedCrmDisplayName}}. This might
                  take a while to complete, check the logs to follow up on the progress.
                  <br/>
                  <ds-button @click="resetManualSyncForm" label="Ok"/>
                </template>
              </div>
            </template>
        </tabs>
      </div>
    </div>
  </div>
  </div>
</template>

<script>

  import DsInput from '../Form/DsInput.vue'
  import FormGroup from '../Form/FormGroup.vue'
  import Checkbox from '../Form/Checkbox.vue'
  import TagInput from '../Form/TagInput.vue'
  import Pagination from '../Pagination/SimplePagination.vue'

  import Tabs from '../Tabs/Tabs.vue'
  import DsButton from '../DsButton/DsButton.vue'
  import Dropdown from '../Dropdown/Dropdown.vue'

  import SyncLogs from './Integrations/SyncLogs.vue'

  import { fetchAllPortfolios } from '../../api/portfolios'
  import { syncCrmPortfolio, syncCrmTag } from '../../api/config.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    data () {
      return {
        crmTypes: [
          { value: 'teamleader', label: 'Teamleader' },
          { value: 'active_campaign', label: 'Active Campaign' },
        ],
        numberOfResults: 0,
        tabs: ['Logs', 'Manual sync'],
        syncTagForm: {
          portfolioId: '',
          tags: [],
          type: 'actors',
        },
        syncTagFormSubmitted: false,
        errors: {},
        selectedManualSyncPortfolio: null,
        manualSyncState: 'idle',
        selectedCrm: '',
      }
    },
    computed: {
      canShowSync () {
        return this.$store.getters.isOwn || this.$store.getters.isSales || ['teamleader', 'local', 'TeamleaderSegmentation', 'salesenablement', 'crypto'].includes(this.$store.getters.ecosystemName)
      },
      importEnabled: {
        get () {
          if (!this.selectedCrm) {
            return false
          }

          return this.form.crmIntegrations[this.selectedCrm].importEnabled

        },
        set (v) {
          if (!this.selectedCrm) {
            return
          }

          this.form.crmIntegrations[this.selectedCrm].importEnabled = v
        },
      },
      importTags: {
        get () {
          if (!this.selectedCrm) {
            return false
          }

          return this.form.crmIntegrations[this.selectedCrm].importTags
        },
        set (v) {
          if (!this.selectedCrm) {
            return
          }

          this.form.crmIntegrations[this.selectedCrm].importTags = v
        },
      },
      fullSyncEnabled: {
        get () {
          if (!this.selectedCrm) {
            return false
          }

          return this.form.crmIntegrations[this.selectedCrm].fullSyncEnabled

        },
        set (v) {
          if (!this.selectedCrm) {
            return
          }

          this.form.crmIntegrations[this.selectedCrm].fullSyncEnabled = v
        },
      },
      isManualSyncSupported () {
        return ['active_campaign'].includes(this.selectedCrm)
      },
      form () {
        return this.$parent.$parent.$parent.form
      },
      selectedCrmDisplayName () {
        switch (this.selectedCrm) {
          case 'teamleader':
            return 'Teamleader'
          case 'active_campaign':
            return 'ActiveCampaign'
        }

        return ''
      },
      displaySyncTabs () {
        if (!this.selectedCrm) {
          return false
        }

        if (this.selectedCrm === 'teamleader') {
          return this.form.crmIntegrations.teamleader.keys.api_key.length > 0 && this.form.crmIntegrations.teamleader.keys.group_id.length > 0
        }

        if (this.selectedCrm === 'active_campaign') {
          return this.form.crmIntegrations.active_campaign.keys.account_key.length > 0 && this.form.crmIntegrations.active_campaign.keys.account_url.length > 0
        }

        return false
      },
      portfolios () {
        var rawPortfolios = []

        fetchAllPortfolios({ 'type': 'all' })
          .then(portfolios => {
            portfolios.forEach(function (portfolio) {
              if (portfolio.virtual) {
                return
              }

              var portfolio = {
                'value': portfolio.id,
                'label': portfolio.name,
              }

              rawPortfolios.push(portfolio)
            })
          })
          .catch(errors => {
          })

        return rawPortfolios
      },
    },
    methods: {
      getTabName (index) {
        return this.tabs[index]
      },
      // Change the object type to sync tags with
      changeSyncType (val) {
        this.syncTagForm.type = val
      },
      startManualSync () {
        this.manualSyncState = 'syncing';

        var params = {
          portfolioId: this.selectedManualSyncPortfolio,
          crmSystem: this.selectedCrm
        }

        syncCrmPortfolio(params)
          .then(response => {
            this.manualSyncState = 'completed'
          })
          .catch(err => {
            this.errors = err
            this.manualSyncState = 'idle'
          })
      },
      startTagSync () {
        syncCrmTag(this.syncTagForm)
          .then(data => {
            this.errors = {}
            this.syncTagFormSubmitted = true
          })
          .catch(errors => {
            this.errors = errors
          })
      },
      resetForm () {
        this.syncTagForm.tags = []
        this.syncTagFormSubmitted = false
      },
      resetManualSyncForm () {
        this.manualSyncState = 'idle'
      },
    },
    mounted () {
      if (!this.form.crmIntegrations) {
        this.form.crmIntegrations = {
          'teamleader': {
            'keys': {
              group_id: '',
              api_key: '',
            },
            importEnabled: false,
            importTags: [],
            fullSyncEnabled: false,
          },
          'active_campaign': {
            'keys': {
              'account_url': '',
              'account_key': '',
            },
            importEnabled: false,
            importTags: [],
            fullSyncEnabled: false,
          },
        }
      }
    },
    components: {
      Checkbox,
      Dropdown,
      DsButton,
      DsInput,
      FormGroup,
      Pagination,
      Tabs,
      TagInput,
      SyncLogs,
    },
  })
</script>
