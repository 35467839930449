<template>
  <div class="portfolio-filters" v-if="displayFilters">
    <div class="portfolio-filters__search-bar">
      <keyword-ds-input
        v-model="searchPortfolioName"
        icon="search"
        class="portfolio-filters__search-box"
        placeholder="Search portfolio..."
        @removedKeyword="searchBarRemovedKeyword"
        @activeKeywords="activePortfolioKeywords"
        :disabled="disabled"
      />
    </div>
    <div class="portfolio-filters__filters-section">
      <div class="portfolio-filters__filter-items" v-if="canFilterOnAccess">
        <alternate-dropdown
          :options="stateOptions"
          icon="chevron-down"
          size="small"
          variant="minimal"
          label="Access"
          @input="selectedState"
        ></alternate-dropdown>
      </div>
      <div class="portfolio-filters__filter-items">
        <alternate-dropdown
          :options="allUsers"
          icon="chevron-down"
          size="small"
          variant="minimal"
          label="Created by"
          @input="selectedUser"
        ></alternate-dropdown>
      </div>
    </div>
  </div>
</template>

<script>
  import Dropdown from '../Dropdown/Dropdown.vue'
  import AlternateDropdown from '../Dropdown/AlternateDropdown.vue'
  import KeywordDsInput from '../Form/KeywordDsInput.vue'
  import { searchUser } from '../../api/config'
  import _throttle from 'lodash/throttle'

  import { MUTATION_TYPES as PORTFOLIOS_MUTATION_TYPES } from '../../store/modules/portfolios.js'

  export default {
    name: 'portfolio-filters',
    props: ['portfolios', 'disabled'],
    data () {
      return {
        searchQuery: {},
        state: null,
        accessFilter: {},
        searchPortfolioName: '',
        searchByUser: {},
        allUsers: null,
        userIds: [],
        searchBySharedWith: null,
        searchBySharedWithResults: [],
      }
    },
    computed: {
      stateOptions () {
        if (this.$store.getters.isPublisherEnabled) {
          return [{ name: 'Private' }, { name: 'Shared' }, { name: 'Public' }]
        }

        return [{ name: 'Private' }, { name: 'Shared' }]
      },
      canFilterOnAccess () {
        return this.isOwner || this.isMember
      },
      displayFilters () {
        // To toggle the visibility of the portfolio filters
        return this.$store.state.filters.filter.visible
      },
      queryByState () {
        // Selects by which state the data must be fetched and store it in a data property
        // Later this data property will be used to build the query object that will be used as the filters for the portfolio search
        var stateValue

        switch (this.state) {
          case 'Private':
            stateValue = 'private'
            break
          case 'Shared':
            stateValue = 'shared'
            break
          case 'Public':
            stateValue = 'public'
            break
        }

        this.accessFilter = stateValue
        this.queryBuilder()
      },
      activePortfolioKeywords () {
        return this.$store.getters.activePortfolioKeywords
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      isMember () {
        return this.$store.getters.isMember
      },
    },
    methods: {
      queryBuilder () {
        // This will build the query that will be used as filters to fetch the portofios in the portfolios page
        // After the query object is built, or set empty, the object will be sent to the portfolio page
        var searchValues = {}

        if (typeof this.accessFilter === 'string') {
          Object.assign(searchValues, { access: this.accessFilter })
        }
        if (this.userIds.length > 0) {
          Object.assign(searchValues, { created_by: this.userIds })
        }

        this.$emit('filters', searchValues)
      },
      selectedState (state) {
        this.state = state.name
      },
      selectedUser (user) {
        this.userIds.push(user.id)
        this.handleAddKeyword(user)
      },
      handleAddKeyword (item) {
        this.$store.commit(PORTFOLIOS_MUTATION_TYPES.ADD_PORTFOLIO_KEYWORD, { value: item.name })
      },
      handleRemoveKeyword (keyword) {
        this.$store.commit(PORTFOLIOS_MUTATION_TYPES.REMOVE_PORTFOLIO_KEYWORD, { value: keyword.name })
      },
      searchBarRemovedKeyword (removedKeyword) {
        this.handleRemoveKeyword({ name: removedKeyword.value })

        // When removing the tags on the portfolio search bar, it either sets the 'state' value to an empty string
        // Or searches for the user which name matches the removed tag and removes its Id from the array that is used to fetch the different users,
        if (removedKeyword.value == 'Private' || removedKeyword.value == 'Shared' || removedKeyword.value == 'Public') {
          this.state = ''
          return
        }

        var searchQuery = Object.assign({}, { query: removedKeyword.value })

        searchUser(searchQuery).then(f => {
          f.map(item => {
            if (removedKeyword.value == item.name) {
              this.userIds.map((itemId, index) => {
                if (item.id == itemId) {
                  this.userIds.splice(index, 1)
                }
              })
            }
          })
        })
      },
    },
    created () {
    },
    watch: {
      portfolios () {
        var allUsers = []
        var ids = []
        this.portfolios.forEach(portfolios => {
          var object = {
            id: portfolios.creator_id,
            name: portfolios.creatorName,
            email: portfolios.creatorEmail,
          }

          if (!ids.includes(object.id) && (object.name && object.name.toLowerCase() !== 'removed user')) {
            allUsers.push(object)
            ids.push(object.id)
          }
        })

        this.allUsers = allUsers
      },
      searchPortfolioName: {
        deep: true,
        handler: _throttle(function (d) {
          // This will send the input value of the search bar to the portfolo page so that later it can be use to fetch the portfolios
          var searchValue = {}
          if (d !== '') {
            Object.assign(searchValue, { query: d })
          }
          this.$emit('portfolioSearch', searchValue)
        }, 300, {
          trailing: true,
        }),
      },
      searchByUser: {
        deep: true,
        handler: _throttle(function (d) {
          // This will fetch the ID of each user that is selected in the 'created by' dropdown and store it in an array
          // Later this array will be used to build the query object that will be used as the filters for the portfolio search
          this.userIds.push(d.id)
          this.handleAddKeyword(d)
        }, 300, {
          trailing: true,
        }),

      },
      userIds: {
        deep: true,
        handler: function () {
          this.queryBuilder()
        },
      },
      state (option) {
        this.queryByState

        // Here will be controlled the addition and removal of the keywords
        if (option !== '') {
          var keywordValue = {}

          if (this.activePortfolioKeywords.length <= 0) {
            // If there are no portfolio keywords
            keywordValue = Object.assign({}, { name: option })
            this.handleAddKeyword(keywordValue)
          } else {
            this.activePortfolioKeywords.map(keyword => {
              this.stateOptions.map(item => {
                if (item.name == keyword.value) {
                  keywordValue = Object.assign({}, { name: item.name })
                  this.handleRemoveKeyword(keywordValue)
                }
              })
            })
            keywordValue = Object.assign({}, { name: option })
            this.handleAddKeyword(keywordValue)
          }
        }
      },
    },
    components: {
      Dropdown,
      AlternateDropdown,
      KeywordDsInput,
    },
  }
</script>
