<template>
  <modal :id="modalId"
         ref="modal"
         :title="'Address'"
         :class="'announcement-address-modal'"
         @close="close"
         is-simplified-modal
  >
    <template v-slot:body>
      <div class="announcement-edit__title-container">
        <p v-if="announcement.address_line" v-html="announcement.address_line"></p>
      </div>
      <div v-if="getLocation()" style="position: relative; height: 220px" class="announcement-content-map">
        <SimpleMap :location="getLocation()" :disableMouseWheelZoom="true"/>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import {MUTATION_TYPES as UI_MUTATION_TYPES} from '../../store/modules/ui.js'
  import SimpleMap from '../../components/SimpleMap/SimpleMap.vue'

  export default {
    data () {
      return {
        modalId: MODAL_IDS.ANNOUNCEMENT_ADDRESS,
        announcement: {
          address_line: '',
          context: {
            address: {
              street: '',
              number: '',
              zip: '',
              city: '',
              country: '',
            },
            location: {
              lat: '',
              long: '',
            }
          }
        },
      }
    },
    computed: {
      modalContext () {
        return this.$store.state.ui.modalContext
      },
      config () {
        return this.$store.state.config
      },
    },
    props: {},
    methods: {
      getLocation () {
        let context = this.announcement.context

        if (typeof context === 'string') {
          context = JSON.parse(this.announcement.context)
        }

        if (context.location.lat === null) {
          return false
        }

        return context.location
      },
      close () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.ANNOUNCEMENT_ADDRESS)
        this.$emit('close')
      },
    },
    mounted () {
      if (this.modalContext.announcement) {
        this.announcement = {...this.announcement, ...this.modalContext.announcement}
      }
    },
    components: {
      Modal,
      SimpleMap,
    },
    mixins: [TranslationsMixin],
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/_variables.scss";

  .announcement-edit__title-container {
    padding: 20px;

    :deep(input) {
      border-radius: $default-border-radius-narrow;

      &::placeholder {
        color: var(--primary);
      }
    }
  }

  .announcement-edit__content-container {
    padding: 20px 20px 17px 20px;

    .row {
      // margin-bottom: 0px;
    }

    :deep(input, .datepicker) {
      border: 1;
      border-radius: $default-border-radius-narrow;
      border-color: var(--primary-extra-lightest) !important;

      &::placeholder {
        color: var(--primary);
      }
    }
  }

  .modal__footer {
    width: 100%;

    .button {
      width: 100%;
      border-radius: $default-border-radius-narrow;
    }
  }

  .d-flex {
    display: flex;
  }

  .form-group {
    margin-bottom: 0px;
  }
</style>
