<template>
  <div class="kitchen-sink scrollable">
    <div class="kitchen-sink__section">
      <h2>Buttons</h2>
      <h3>Label/icon variants</h3>
      <ds-button variant="outline" label="With a label"/>
      <ds-button variant="outline" label="Icon and label" icon="plus"/>
      <ds-button variant="outline" label="FA icon and label" icon="vial" icon-fill-color="#090"/>
      <ds-button variant="outline" icon="plus"/>
      <ds-button variant="outline" icon="vial" icon-fill-color="#090"/>

      <h3>Size variants</h3>
      <ds-button variant="outline" label="With a label" size="small"/>
      <ds-button variant="outline" label="Icon and label" icon="plus" size="small"/>
      <ds-button variant="outline" label="FA icon and label" icon="vial" icon-fill-color="#090" size="small"/>
      <ds-button variant="outline" icon="plus" size="small"/>
      <ds-button variant="outline" icon="vial" icon-fill-color="#090" size="small"/>
      <ds-button variant="outline" icon="plus" label="Icon and label" size="extra-small"/>
      <ds-button variant="outline" icon="vial" icon-fill-color="#090" label="FA icon and label" size="extra-small"/>
      <ds-button variant="outline" icon="plus" size="extra-small"/>
      <ds-button variant="outline" icon="vial" icon-fill-color="#090" size="extra-small"/>
      <p>For an overview of FontAwesome icons, <a href="https://fontawesome.com/icons?d=gallery&m=free"
                                                  rel="noopener noreferrer nofollow" target="_blank">see here</a>.</p>

      <h3>Block button</h3>
      <div style="width: 300px">
        <ds-button variant="outline" block label="Block button"/>
      </div>

      <h3>Color variants</h3>
      <ds-button label="Default" icon="plus"/>
      <ds-button label="Primary" icon="plus" variant="primary"/>
      <ds-button label="Secondary" icon="plus" variant="secondary"/>
      <ds-button label="Outline" icon="plus" variant="outline"/>
      <ds-button label="Link" icon="plus" variant="link"/>
      <ds-button label="Minimal" icon="plus" variant="minimal"/>
      <ds-button label="Minimal (primary)" icon="plus" variant="minimal-primary"/>

      <h3>Color variants without icons</h3>
      <ds-button label="Default"/>
      <ds-button label="Primary" variant="primary"/>
      <ds-button label="Secondary" variant="secondary"/>
      <ds-button label="Outline" variant="outline"/>
      <ds-button label="Link" variant="link"/>
      <ds-button label="Minimal" variant="minimal"/>
      <ds-button label="Minimal (primary)" variant="minimal-primary"/>

      <h3>Color variants without icons</h3>
      <ds-button to="#" label="Default"/>
      <ds-button to="#" label="Primary" variant="primary"/>
      <ds-button to="#" label="Secondary" variant="secondary"/>
      <ds-button to="#" label="Outline" variant="outline"/>
      <ds-button to="#" label="Link" variant="link"/>
      <ds-button to="#" label="Minimal" variant="minimal"/>
      <ds-button to="#" label="Minimal (primary)" variant="minimal-primary"/>

      <h3>Edit relevancy popup</h3>
      <relevant-edit-popup
          v-model="relevantSearches"
          :active-search="1"
      />
      <relevant-edit-popup
          v-model="relevantSearches"
          :active-search="1"
          style="margin-left: 20px;"
      >
        Custom trigger content
      </relevant-edit-popup>

      <h3>Follow and publish</h3>
      <follow-button v-model="isFollowed"/>
      <publish-button v-model="isPublished"/>
    </div>

    <div class="kitchen-sink__section">
      <h2>Icons</h2>
      <div style="column-width: 10em;">
        <div v-for="(_, name) in ICONS">
          <icon :name="name"/>
          {{ name }}
        </div>
      </div>
    </div>

    <div class="kitchen-sink__section">
      <h2>Avatar</h2>
      <h3>With image</h3>
      <avatar src="https://s3.amazonaws.com/uifaces/faces/twitter/brad_frost/128.jpg"/>

      <h3>Color variants (used as a fallback for when no image is found)</h3>
      <h4>Default (has dark backdrop for illustrative purposes)</h4>
      <div class="kitchen-sink__dark-backdrop">
        <avatar alt="Datascouts" variant="default"/>
      </div>

      <h4>Primary</h4>
      <avatar alt="Datascouts" variant="primary"/>
    </div>

    <div class="kitchen-sink__section">
      <h2>Badge</h2>
      <h3>Color variants</h3>
      <badge name="default" variant="default"/>
      <badge name="primary" variant="primary"/>
    </div>

    <div class="kitchen-sink__section">
      <h2>Cards</h2>
      <h3>Default card</h3>
      <card cover="https://unsplash.it/500/200" title="Card title" sub-title="Card subtitle">
        <p>Card content here.</p>
      </card>
      <h3>'gallery' variant</h3>
      <card cover="https://unsplash.it/500/200" title="Card title" sub-title="Card subtitle" variant="gallery">
        <p>Card content here.</p>
      </card>
    </div>

    <div class="kitchen-sink__section">
      <h2>Dropdown</h2>
      <button @click="showTextarea = !showTextarea">Toggle textarea visibility (DEBUG)</button>
      <div v-if="!showTextarea">
        <h3>Single value</h3>
        <dropdown :options="dropdowns.single.options" v-model="dropdowns.single.value" :search="true"
                  placeholder="Type to search..."/>
        <pre class="kitchen-sink__code">v-model value: {{ dropdowns.single.value }}</pre>

        <h3>Multiple values</h3>
        <dropdown multiple :options="dropdowns.multiple.options" v-model="dropdowns.multiple.value"
                  :v-model:suggestions="dropdowns.multiple.suggestions" :search="true" :allowClear="true"
                  :noResultsHandlerEnabled="true" placeholder="Type to search..."/>
        <pre class="kitchen-sink__code">v-model value: {{ dropdowns.multiple.value }}</pre>

        <h3>Grouped multiple values (data)</h3>
        <dropdown multiple :data="dropdowns.groupedMultiple.data" v-model="dropdowns.groupedMultiple.value"/>
        <pre class="kitchen-sink__code">v-model value: {{ dropdowns.groupedMultiple.value }}</pre>

        <h3>Autocomplete Dropdown</h3>
        <autocomplete-dropdown v-model="dropdowns.autocomplete.value" placeholder="Type to search..."
                               :do-search="performAutocompleteDropdownSearch"/>
        <pre class="kitchen-sink__code">v-model value: {{ dropdowns.autocomplete.value }}</pre>
      </div>
      <div v-if="showTextarea">
        <ds-textarea placeholder="Enter a description"/>
      </div>
    </div>

    <div class="kitchen-sink__section" id="forms">
      <h2>Forms</h2>
      <form @submit.prevent>
        <form-section>
          <form-group label="An input with a label">
            <ds-input placeholder="Placeholder value"/>
          </form-group>
          <div class="row">
            <form-group label="An input with a label and instructions" instructions="The answer to everything is 42."
                        class="col-xs">
              <ds-input placeholder="Placeholder value"/>
            </form-group>
            <form-group class="col-xs">
              <ds-input placeholder="A placeholder"/>
            </form-group>
          </div>
          <div class="row">
            <form-group label="Datepicker" class="col-xs-3">
              <datepicker/>
            </form-group>
            <form-group label="Another datepicker" class="col-xs-3">
              <datepicker/>
            </form-group>
            <form-group label="Field with error" class="col-xs"
                        error="Something went terribly wrong with this field. Oh no!">
              <ds-input/>
            </form-group>
          </div>
        </form-section>
        <form-section title="Form section with title">
          <div class="row">
            <form-group label="Single dropdown" class="col-xs-3">
              <dropdown :options="dropdowns.single.options" v-model="dropdowns.single.value"/>
            </form-group>
            <form-group label="Multiple dropdown" class="col-xs-3">
              <dropdown multiple :options="dropdowns.multiple.options"/>
            </form-group>
            <form-group label="A label" class="col-xs">
              <ds-input v-model="dropdowns.single.value"/>
            </form-group>
          </div>
        </form-section>

        <form-section title="Misc controls">
          <form-group label="Textarea">
            <ds-textarea :rows="6"/>
          </form-group>
          <form-group label="Radio buttons">
            <radio-button
                v-for="(fruit, index) in ['Apple', 'Orange', 'Banana']"
                :key="index"
                v-model="dropdowns.single.value"
                name="radio-buttons-1"
                :label="fruit"
                :val="fruit"
            />
          </form-group>
          <form-group label="Inline radio buttons">
            <radio-button
                v-for="(fruit, index) in ['Apple', 'Orange', 'Banana']"
                :key="index"
                v-model="dropdowns.single.value"
                inline
                name="radio-buttons-2"
                :label="fruit"
                :val="fruit"
            />
          </form-group>
          <form-group label="Checkbox buttons">
            <checkbox
                v-for="(fruit, index) in ['Apple', 'Orange', 'Banana']"
                :key="index"
                v-model="dropdowns.single.value"
                inline
                :label="fruit"
                :val="fruit"
            />
          </form-group>
          <form-group label="Checkbox buttons with only true/false">
            <checkbox
                v-for="(fruit, index) in ['Apple', 'Orange', 'Banana']"
                :key="index"
                v-model="dropdowns.single.value"
                inline
                :label="fruit"
            />
          </form-group>
          <form-group label="Checkbox buttons with multiple value support">
            <checkbox
                v-for="(fruit, index) in dropdowns.multiple.options"
                :key="index"
                v-model="dropdowns.multiple.value"
                inline
                multiple
                :label="fruit"
                :val="fruit"
            />
          </form-group>

        </form-section>
        <form-section title="Switch controls">
          <form-group label="Switch buttons variants (Bootstrap theme)">
            <div style="margin-top: 1em; margin-bottom: 1em">
              <toggle-button theme="bootstrap" color="default"></toggle-button>
              <toggle-button theme="bootstrap" color="primary"></toggle-button>
              <toggle-button theme="bootstrap" color="success"></toggle-button>
              <toggle-button theme="bootstrap" color="info"></toggle-button>
              <toggle-button theme="bootstrap" color="warning"></toggle-button>
              <toggle-button theme="bootstrap" color="danger"></toggle-button>
            </div>
          </form-group>
          <form-group label="Switch buttons variants (Bulma theme)">
            <div style="margin-top: 1em; margin-bottom: 1em">
              <toggle-button theme="bulma" color="default"></toggle-button>
              <toggle-button theme="bulma" color="blue"></toggle-button>
              <toggle-button theme="bulma" color="green"></toggle-button>
              <toggle-button theme="bulma" color="yellow"></toggle-button>
              <toggle-button theme="bulma" color="red"></toggle-button>
            </div>
          </form-group>
          <form-group label="Switch buttons with labels">
            <div style="margin-top: 1em; margin-bottom: 1em">
              <toggle-button textEnabled="Enabled" textDisabled="Disabled" theme="bootstrap"
                             color="default"></toggle-button>
              <toggle-button textEnabled="Enabled" textDisabled="Disabled" theme="bootstrap"
                             color="primary"></toggle-button>
              <toggle-button textEnabled="Enabled" textDisabled="Disabled" theme="bootstrap"
                             color="success"></toggle-button>
              <toggle-button textEnabled="Enabled" textDisabled="Disabled" theme="bootstrap"
                             color="info"></toggle-button>
              <toggle-button textEnabled="Enabled" textDisabled="Disabled" theme="bootstrap"
                             color="warning"></toggle-button>
              <toggle-button textEnabled="Enabled" textDisabled="Disabled" theme="bootstrap"
                             color="danger"></toggle-button>
            </div>
          </form-group>
          <form-group label="Switch buttons Bold type">
            <div style="margin-top: 1em; margin-bottom: 1em">
              <toggle-button theme="bootstrap" color="success" :typeBold="true"
                             style="vertical-align: top;"></toggle-button>
              <toggle-button theme="bootstrap" color="success" :typeBold="false"
                             style="vertical-align: top; margin-left: 1em;"></toggle-button>
            </div>
          </form-group>
          <form-group label="Switch buttons v-model">
            <div style="margin-top: 1em; margin-bottom: 1em">
              <pre class="kitchen-sink__code" style="margin-bottom: 2em">v-model value: {{ switchButton }}</pre>
              <toggle-button v-model="switchButton.switch1" color="success"
                             :enabled="switchButton.switch1"></toggle-button>
              <toggle-button v-model="switchButton.switch2" color="info"
                             :enabled="switchButton.switch2"></toggle-button>
              <toggle-button v-model="switchButton.switch3" color="warning"
                             :enabled="switchButton.switch3"></toggle-button>
              <toggle-button v-model="switchButton.switch4" color="danger"
                             :enabled="switchButton.switch4"></toggle-button>
            </div>
          </form-group>
        </form-section>
      </form>
    </div>

  </div>
</template>

<script lang="ts">
import Avatar from '../components/Avatar/Avatar.vue'
import Badge from '../components/Badge/Badge.vue'
import Card from '../components/Card/Card.vue'
import Checkbox from '../components/Form/Checkbox.vue'
import Datepicker from '../components/Form/Datepicker.vue'
import Dropdown from '../components/Dropdown/Dropdown.vue'
import AutocompleteDropdown from '../components/Dropdown/AutocompleteDropdown.vue'
import DsInput from '../components/Form/DsInput.vue'
import DsTextarea from '../components/Form/DsTextarea.vue'
import FormGroup from '../components/Form/FormGroup.vue'
import FormSection from '../components/Form/FormSection.vue'
import RadioButton from '../components/Form/RadioButton.vue'
import ToggleButton from '../components/Form/ToggleButton.vue'
import RelevantEditPopup from '../components/RelevantEditPopup/RelevantEditPopup.vue'
import TagInput from '../components/Form/TagInput.vue'

import ICONS from '../../icons/manifest.json'
import { fetchGeographySuggestions } from '../api/exploration.js'
import FollowButton from '../components/FollowButton/FollowButton.vue'
import PublishButton from '../components/PublishButton/PublishButton.vue'
import { defineComponent } from '~/vue'

const DROPDOWN_OPTIONS = ['Apple', 'Orange', 'Banana', 'Pear', 'Kiwi', 'Pineapple', 'Watermelon', 'Strawberry']
const DROPDOWN_OPTIONS_VEGETABLES = ['Broccoli', 'Bell pepper', 'Carrot', 'Sweetcorn', 'Cucumber']
const GROUPED_DROPDOWN_DATA = [
  {
    text: 'Fruits',
    children: DROPDOWN_OPTIONS.map(v => ({ id: v, text: v }))
  },
  {
    text: 'Vegetables',
    children: DROPDOWN_OPTIONS_VEGETABLES.map(v => ({ id: v, text: v }))
  },
]
export default defineComponent({
  data() {
    return {
      ICONS,
      showTextarea: false,
      switchButton: {
        switch1: false,
        switch2: false,
        switch3: false,
        switch4: false,
      },
      dropdowns: {
        single: {
          options: DROPDOWN_OPTIONS,
          value: null,
        },
        multiple: {
          options: DROPDOWN_OPTIONS,
          value: [DROPDOWN_OPTIONS[1], DROPDOWN_OPTIONS[3]],
          suggestions: [DROPDOWN_OPTIONS[6], DROPDOWN_OPTIONS[7]],
        },
        groupedMultiple: {
          data: GROUPED_DROPDOWN_DATA,
          value: [GROUPED_DROPDOWN_DATA[0].children[0].id, GROUPED_DROPDOWN_DATA[1].children[1].id],
        },
        autocomplete: {
          value: null,
        }
      },
      relevantSearches: [],
      isFollowed: false,
      isPublished: false,
      tags: [
        { text: 'tag1' },
        { text: 'tag' }
      ],
      tagInputValidation: [{
        classes: 'min-length',
        rule: tag => tag.text.length < 8,
      }, {
        classes: 'no-numbers',
        rule: /^([^0-9]*)$/,
      }, {
        classes: 'avoid-item',
        rule: /^(?!Cannot).*$/,
        disableAdd: true,
      }, {
        classes: 'no-braces',
        rule: ({ text }) => text.indexOf('{') !== -1 || text.indexOf('}') !== -1,
      }]
    }
  },
  methods: {
    performAutocompleteDropdownSearch(input) {
      return fetchGeographySuggestions({ query: input })
          .then(response => {
            const geographyOptions = [];

            if (!Array.isArray(response)) {
              return
            }

            response.forEach(item => {
              if (!item.label.eng) {
                return
              }

              var label = item.label.eng

              if (item.type === 'place' && item.country && item.country.label && item.country.label.eng && !label.includes(item.country.label.eng) && !label.includes(',')) {
                label += ' (' + item.country.label.eng + ')'
              }

              geographyOptions.push({
                label,
                value: item,
              })
            })

            return geographyOptions
          })
    },
  },
  components: {
    PublishButton,
    FollowButton,
    Avatar,
    Badge,
    Card,
    Checkbox,
    Dropdown,
    AutocompleteDropdown,
    FormGroup,
    FormSection,
    Datepicker,
    DsTextarea,
    RadioButton,
    DsInput,
    ToggleButton,
    RelevantEditPopup,
    TagInput
  }
})
</script>
