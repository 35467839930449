<template>
  <div class="file-row">
    <a class="file-row__file" v-for="file in modelValue" :href="file.url" target="_blank">
      <div class="file-row__preview" :style="preview(file)"></div>
      <div class="file-row__name">{{ file.title && file.title.length && file.title !== 'File' ? file.title : file.originalName }}</div>
    </a>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: Array
  },
  methods: {
    preview (file) {
      return file && file.preview_w50 ? { backgroundImage: 'url(' + file.preview_w50 + ')' } : null
    }
  }
}
</script>

<style lang="scss">
.file-row__file {
  display: inline-block;
  max-width: 200px;
  text-align: center;
  vertical-align: top;
  margin-right: 1em;
  margin-bottom: 1em;
  color: inherit;
  text-decoration: inherit;
  &:hover {
    text-decoration: underline;
  }
}
.file-row__name {
  font-size: 12px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
.file-row__preview {
  height: 50px;
  width: 50px;
  background: #eee;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  margin: .5em auto;
}
</style>
