<template>
  <div>
    <h1 class="h1">{{ onboardingTextConfig.onboardingQuestion1 }}</h1>
    <h4 class="h4">{{ onboardingTextConfig.onboardingQuestion2 }}</h4>
    <form @submit.prevent="submit">
      <form-group :label="onboardingTextConfig.onboardingQuestion3" required :error="errors.bio"
        is-simplified>
        <ds-textarea is-simplified v-model="user.bio"/>
      </form-group>
      <div class="row">
        <form-group is-simplified v-if="expertiseOptions && expertiseOptions.length"
          :error="errors.expertises"
          required :label="onboardingTextConfig.onboardingQuestion5" class="col-xs-12 col-sm-6">
          <dropdown is-simplified v-model="user.expertises"
            multiple
            :options="expertiseOptions" :max-height="250"/>
        </form-group>
        <form-group is-simplified v-if="motivationOptions && motivationOptions.length"
          class="col-xs-12 col-sm-6"
          :error="errors.motivations"
          required
          :label="onboardingTextConfig.onboardingQuestion6">
          <dropdown is-simplified v-model="user.motivations"
            multiple
            :options="motivationOptions" :max-height="250"/>
        </form-group>
      </div>
      <form-group is-simplified label="hidden">
        <ds-button variant="rounded" :disabled="loading" :label="$t('actor_detail_next')" type="submit"/>
      </form-group>
    </form>
    <div class="onboarding-invited-by" v-if="invitedBy">
      {{ $t('onboarding_invited_by') }} {{ invitedBy }}
    </div>
  </div>
</template>
<script>
  import OnboardingMixin from '../../util/OnboardingMixin.js'
  import DsTextarea from '../../components/Form/DsTextarea.vue'
  import _isEmpty from 'lodash/isEmpty.js'
  import { prepareUserForEditing } from '../../util/user-settings.js'
  import FiltersMixin from '../../util/FiltersMixin.js'
  import Dropdown from '../../components/Dropdown/Dropdown.vue'
  import { updateProfile } from '../../api/user.js'
  import { MUTATION_TYPES as USER_MUTATION_TYPES } from '../../store/modules/user-helpers.js'
  import { trackHeapEvent } from '../../util/analytics.js'

  export default {
    data () {
      return {
        errors: {},
        user: this.currentUser(),
        invitedBy: window.invited_by,
        loading: false,
      }
    },
    methods: {
      currentUser () {
        const profile = this.$store.state.user.profile
        return prepareUserForEditing(profile)
      },
      performValidation () {
        this.errors = {}
        if (!this.user.bio) {
          this.errors.bio = this.$t('onboarding_field_required')
        }
        if (this.expertiseOptions && this.expertiseOptions.length && this.user.expertises.length === 0) {
          this.errors.expertises = this.$t('profile_error_expertise_required')
        }
        if (this.motivationOptions && this.motivationOptions.length && this.user.motivations.length === 0) {
          this.errors.motivations = this.$t('profile_error_motivation_required')
        }
      },
      submit () {
        this.performValidation()

        if (!_isEmpty(this.errors)) {
          if (this.invitedBy) {
            trackHeapEvent('onboarding-private-registration-more-details-invalid-form')
          } else {
            trackHeapEvent('onboarding-public-registration-more-details-invalid-form')
          }
          return
        }
        this.loading = true

        const userData = Object.assign({}, this.user)
        delete userData['password']
        delete userData['current_password']

        updateProfile(userData)
          .then(data => {
            this.$store.commit(USER_MUTATION_TYPES.UPDATE_PROFILE, data)
            this.$emit('next')
            this.loading = false
          })
          .catch(errors => {
            if (this.invitedBy) {
              trackHeapEvent('onboarding-private-registration-more-details-failure', errors)
            } else {
              trackHeapEvent('onboarding-public-registration-more-details-failure', errors)
            }
            this.errors = errors
          })
      },
    },
    computed: {},
    mounted () {
    },
    mixins: [OnboardingMixin, FiltersMixin],
    components: {
      DsTextarea,
      Dropdown,
    },
  }
</script>
