<template>
  <!-- NOTE: this card should be deprecated, instead we should use the knowledge-base-item/announcement-or-resource component -->
  <div class="home__section__article-section article-section__news_feed">
    <div class="article-preview__media-container" v-if="link.length > 0">
      <div class="card__general article-section__items">
        <div class="article-item__container">
          <div class="article-item__content-container">
            <div class="article-item__data-container article-item__data-container--full">
              <div class="article-item__title-container">
                <a target="_blank"
                   class="article-item__article-section-title"
                   @click="$emit('link-click', $event, conceptSearchId)">{{ item.title }}</a>
                <span class="article-item__article-section-title"
                      style="font-size: 0.7rem;" v-if="originalUriDomain"> -&nbsp;<a
                    :href="sourceUri" target="_blank">{{ originalUriDomain }}</a></span>
                <div class="article-item__title-container__right">
                  <template
                      v-if="(isOwner || isMember) && item.actor_type === 'content' && !hideActions">
                    <ds-button size="extra-small" variant="outline" icon="share"
                               v-tooltip.bottom="copied ? '✓ ' + $t('copied_article_url'): $t('copy_article_url')"
                               @click="copyUrl"/>
                    <ds-button
                        size="extra-small" variant="outline" :to="item.detail_link"
                        icon="view-graph" class="rss-item__analyse-icon"
                        v-if="item.detail_link && isDetailEnabled"/>
                    <ds-button size="extra-small" variant="outline" icon="edit"
                               @click="showEditResourcePanel"/>
                    <ds-button size="extra-small" variant="outline" icon="trash"
                               @click="showDeleteResourceConfirmationModal"/>
                  </template>
                  <span class="article-item__date">{{
                      toDateString(item.date * 1000)
                    }}</span>
                  <template
                      v-if="hasAccessToExplorationOrMonitoring && relevancyMode !== 'disabled'">
                    <template v-if="relevancyMode === 'emit+global'">
                      <icon name="trash" size="18"
                            id="introjs-trash-button"
                            class="action-icon"
                            v-tooltip.bottom="'Remove from top 100 results'"
                            @click="removeArticle"/>
                      <icon name="share" size="18"
                            id="introjs-share-button"
                            class="action-icon"
                            v-tooltip.bottom="copied ? '✓ Copied article url' : 'Copy article url'"
                            @click="copyUrl"/>
                      <div @click="likeArticle" class="action-icon" :class="item.is_liked_by_user && 'liked'"
                           :key="componentKey" v-if="showLikeButton">
                        <icon style="margin-top: -4px"
                              :name="item.is_liked_by_user ? 'thumbs-up' : 'thumbs-up-outline'"/>
                        {{ item.likes }}
                      </div>
                      <relevant-edit-popup
                          v-model="item.relevant_in"
                          @relevant="handleArticleRelevant"
                          @not-relevant="handleArticleNotRelevant"
                          :active-search="conceptSearchId"
                          style="margin-left: 6px; cursor: pointer"
                          icon-id="introjs-star-button"
                          v-if="$store.getters.isMember"
                      />
                    </template>
                    <template v-if="relevancyMode === 'global' && $store.getters.isMember">
                      <relevant-edit-popup
                          v-model="item.relevant_in"
                          @relevant="handleArticleRelevant"
                          @not-relevant="handleArticleNotRelevant"
                          style="margin-left: 6px;"
                      />
                      <icon name="trash" size="18"
                            class="action-icon"
                            v-tooltip.bottom="'Remove'"
                            @click="removeArticle"/>
                    </template>
                  </template>
                  <template v-if="relevancyMode === 'disabled' || !isMember">
                    <icon name="share" size="18"
                          class="action-icon"
                          v-tooltip.bottom="{content: copied ? '✓ Copied article url' : 'Copy article url', hideTriggers: ['hover']}"
                          @click="copyUrl"/>
                    <div @click="likeArticle" class="action-icon" :class="item.is_liked_by_user && 'liked'"
                         :key="componentKey">
                      <icon style="margin-top: -4px" :name="item.is_liked_by_user ? 'thumbs-up' : 'thumbs-up-outline'"/>
                    </div>
                  </template>
                </div>
              </div>
              <template v-if="item.actor_id && item.actor_id.length > 0">
                <template v-for="mention in item.actor_id">
                  <div class="article-item__article-section-poster"
                       @click="openActorSidePanel({actor_id: mention.id})">{{
                      mention.name
                    }}
                  </div>
                </template>
              </template>
              <div class="article-item__article-section-tags">
                <rich-tag v-for="(tag, index) in displayTags.slice(0, 5)" :key="index"
                          size="small" :tag="tag"
                          :title="tag.type === 'patent_cpc' ? tag.text : ''"
                          @click="addTagToFilters({value:tag, origin: 'tags'})"
                          :disable-add-to-dictionary="disableAddToDictionary"/>
                <div class="article-item__article-section-tags__more"
                     v-if="displayTags.length > 5 && isMember && item.actor_type === 'content'"
                     @click.stop="showEditResourcePanel">More…
                </div>
              </div>
              <div class="global-search__result__subtitle"
                   style="display: inline-flex; font-size: 11px;"
                   v-if="item.raw_score && $store.getters.isDeveloper"> Score:
                {{ item.raw_score }} <span style="text-transform: lowercase">&nbsp;(higher is better)</span>
              </div>
              <div class="article-item__description" v-if="item.body">
                {{ removeTitleFromBody(item.body, item.title) }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="article-preview__media-container" v-else>
      <div class="card__general article-section__items">
        <div class="article-item__container">
          <div class="article-item__content-container">
            <div class="article-item__data-container article-item__data-container--full">
              <div class="article-item__title-container">
                <div class="article-item__article-section-title">{{ item.title }}</div>
                <div class="article-item__title-container__right">
                  <span class="article-item__date">{{
                      toDateString(item.date * 1000)
                    }}</span>
                </div>
              </div>
              <div class="article-item__article-section-poster" v-if="item.actor_name"
                   @click="openActorSidePanel(item)">{{ item.actor_name }}
              </div>
              <div class="article-item__description" v-if="item.body">{{
                  item.body
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import RichTag from '../../Tag/RichTag.vue'
import RelevantEditPopup from '../../RelevantEditPopup/RelevantEditPopup.vue'

import { toDateString } from '../../../util/date'

import { copyToClipboard } from '../../../util/helpers.js'
import { trackArticle } from '../../../api/knowledge-base.js'
import DictionaryMixin from '../../../util/DictionaryMixin.js'
import IntroJsMixin from '../../../util/IntroJsMixin.js'

export default {
  components: { RelevantEditPopup, RichTag },
  props: {
    item: Object,
    link: String,
    sourceUri: String,
    maxScore: Number,
    hideActions: {
      type: Boolean,
      default: false,
    },
    disableAddToDictionary: {
      type: Boolean,
      default: false,
    },
    trimTags: {
      type: Boolean,
      default: false,
    },
    tagsClickable: {
      type: Boolean,
      default: false,
    },
    relevancyMode: {
      type: String,
      default: 'disabled',
      validator: value => ['disabled', 'emit+global', 'global'].includes(value),
    },
    conceptSearchId: {
      type: Number,
    },
  },
  data() {
    return {
      copied: false,
      componentKey: 1,
    }
  },
  computed: {
    showLikeButton() {
      return !this.$store.getters.isExplorationOnly && this.$store.getters.isPublisherEnabled
    },
    originalUriDomain() {
      if (!this.sourceUri) {
        return
      }

      var pathArray = this.sourceUri.split('/')
      var domain = pathArray[2]

      if (!domain) {
        return
      }

      return domain.replace('www.', '')
    },
    isDetailEnabled() {
      return ['VOPAK-monitoring', 'local', 'colruyt-actormapping'].includes(this.$store.getters.ecosystemName.toLowerCase())
    },
    displayTags() {
      if (!this.item.tags) {
        return []
      }

      var tags = this.item.tags

      tags.map(tag => {
        tag.keywordExistsInAnyDictionary = this.keywordExistsInAnyDictionary(tag)
        return tag
      })

      tags = tags.sort((tag1, tag2) => {
        return tag2.keywordExistsInAnyDictionary - tag1.keywordExistsInAnyDictionary
      })

      if (!this.trimTags) {
        return tags
      }

      var displayTags = tags.filter(tag => !['loc'].includes(tag.type))

      return displayTags.slice(0, 5)
    },
    hasAccessToExplorationOrMonitoring() {
      return this.$store.getters.hasAccessToExplorationOrMonitoring
    },
    isMember() {
      return this.$store.getters.isMember
    },
    isOwner() {
      return this.$store.getters.isOwner
    },
    config() {
      return this.$store.state.config
    },
  },
  methods: {
    removeArticle() {
      this.$emit('remove-article', this.item)
    },
    likeArticle() {
      if (this.isOwner || this.isMember) {
        return
      }

      this.$emit('like-article')
      this.item.is_liked_by_user = !this.item.is_liked_by_user
      this.componentKey++
    },
    copyUrl() {
      this.copied = true
      trackArticle(this.item.sql_media_id, this.$route.params.id, 'share')

      if (this.conceptSearchId) {
        return copyToClipboard(`${window.location.protocol}//${window.location.hostname}/dashboards/concept-map/${this.conceptSearchId}/content/${this.item.sql_media_id}`)
      } else {
        copyToClipboard(this.link)
      }
    },
    removeTitleFromBody(body, title) {
      // The following if is not efficiently done, skipping it because of it
      /*if (body.indexOf(title) === 0) {
        return body.substr(title.length)
      }*/
      return body
    },
    toDateString,
    showEditResourcePanel() {
      this.$emit('show-edit-resource-panel', this.item)
    },
    showDeleteResourceConfirmationModal() {
      this.$emit('show-delete-resource-confirmation-modal', this.item)
    },
    markArticleRelevant(isRelevant, conceptSearchId) {
      this.$emit('mark-article-relevant', {
        article: this.item,
        isRelevant: isRelevant,
        conceptSearchId: conceptSearchId,
      })
    },
    openActorSidePanel(actorInfo) {
      this.$emit('open-actor-side-panel', actorInfo)
    },
    addTagToFilters(tagInfo) {
      this.$emit('add-tag-to-filters', tagInfo)
    },
    handleArticleRelevant(conceptSearchId) {
      this.markArticleRelevant(true, conceptSearchId)
    },
    handleArticleNotRelevant(conceptSearchId) {
      this.markArticleRelevant(false, conceptSearchId)
    },
    startIntroJs() {
      if (this.seenIntros.includes('conceptMapMonitoring') && !this.isLoggedIn) {
        return
      }
      const config = this.buildIntroJsConfig(this.config, this.$store.getters.userRole)
      if (!config || !config.conceptMapMonitoring || !config.conceptMapMonitoring.steps) {
        return
      }

      config.conceptMapMonitoring.steps = this.getIntroJsStepsConfig(config.conceptMapMonitoring.steps)
      const intro = this.introJs().setOptions(config.conceptMapMonitoring)
      this.currentIntro = intro

      const componentScope = this
      intro.onbeforechange(this.introJsBeforeStepCallback.bind(this))
      intro.onafterchange(this.introJsAfterStepCallback.bind(this))
      intro.oncomplete(function () {
        componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
      })
      intro.onexit(function () {
        componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
      })

      intro.start()
    },
  },
  mounted() {
    this.startIntroJs()
  },
  mixins: [DictionaryMixin, IntroJsMixin],
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/_variables.scss";

.action-icon {
  cursor: pointer;
  margin-left: 3px;
  color: $color-borders;

  &.liked {
    color: black;
  }

  &.svg-icon--trash {
    margin-bottom: 2px;
  }

  :deep(path) {
    fill: $color-borders;
  }

  :deep([data-prefix="fas"] path) {
    fill: black;
  }
}

.rss-item__analyse-icon {
  .svg-icon {
    path {
      fill: white !important;
    }
  }
}

.rss-item__analyse-icon .fill-fg {
  fill: #000000 !important;
}

.knowledge-base .home__section__article-section .article-preview__media-container .article-item__description {
  font-size: 13px !important;
  line-height: 19px !important;
  max-height: 76px !important;
  margin-top: 12px !important;
}

.home__section__article-section .article-section__items .article-item__content-container .article-item__article-section-title {
  margin-top: auto;
}

.home__section__article-section .article-section__items .article-item__container .article-item__content-container {
  max-height: none;
}

.knowledge-base .article-item__article-section-tags {
  margin-top: 8px !important;
}

.global-search__result__subtitle {
  display: block;
  margin-bottom: 0;
  margin-top: 4px;
}
</style>
