<template>
  <div>
    <template v-if="!fetchingActors">
      <div
        class="article-actor__result"
        v-for="actor in actors.slice(0, 100)"
        @click="handleClickActorSearchResult(actor)">
        <div class="article-actor__result__visual"
             :style="{ backgroundImage: `url(${actor.featured_image_url || actor.website_screenshot || getDefaultFallbackImageUrl()})` }"></div>
        <div class="article-actor__result__info">
          <div class="article-actor__result__title">
            <Avatar variant="border" :src="actorLogo(actor)" :alt="actor.name"/>
            <span>{{ actor.name }}</span>
            <template v-if="$store.getters.hasAccessToExplorationOrSensing">
              <icon class="relevant-button" name="star-outline" @click.stop="markActorRelevant(actor, true)" v-tooltip.left="'Mark as relevant'" v-if="!actor.relevant"
                    :disabled="addingActor"/>
              <icon class="relevant-button" name="star" @click.stop="markActorRelevant(actor, false)" :disabled="addingActor" v-else/>
            </template>
          </div>
          <div class="article-actor__result__subtitle" v-if="$store.getters.isDeveloper">
            Score: {{ actor.score || 0 }} <span style="text-transform: lowercase">&nbsp;(higher is better)</span>
          </div>
          <div class="article-actor__result__description"
               v-if="actor.short_description || actor.activities_description">
            {{ textLimit(actor.short_description || actor.activities_description || '') }}
          </div>
          <div>
            <div class="article-actor__result__tags__tag" v-for="tag in topTagsForActor(actor)">
              {{ tag }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { actorLogo } from '../../../util/actor'
  import { textLimit } from '../../../util/string.ts'
  import { updateActorRelevancy } from '../../../api/exploration'
  import { trackHeapEvent } from '../../../util/analytics'

  import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../../../store/modules/actors'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui'

  import Loading from './Loading.vue'
  import Avatar from '../../Avatar/Avatar.vue'
  import { createActor } from '../../../api/actors'
  import { fetchProfile } from '../../../api/user'
  import { getDefaultFallbackImageUrl } from '../../../util/helpers.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    components: { Avatar, Loading },
    props: {
      fetchingActors: Boolean,
      actors: Array,
      conceptSearchId: Number,
    },
    data () {
      return {
        addingActor: false,
      }
    },
    methods: {
      getDefaultFallbackImageUrl,
      actorLogo,
      textLimit,
      markActorRelevant (actor, isRelevant) {
        if (!actor.id) {
          this.addNewActorAndMarkRelevant(actor, isRelevant)

          return
        }

        const originalRelevant = actor.relevant
        actor.relevant = isRelevant

        updateActorRelevancy(actor.id, isRelevant, this.conceptSearchId)
          .then(() => {
            this.$emit('mark-relevant')
          })
          .catch(err => {
            actor.relevant = originalRelevant
            console.log(err)
          })

        trackHeapEvent('exploration.clickRelevantActor')
      },
      async addNewActorAndMarkRelevant (actor, isRelevant) {
        if (this.addingActor) {
          return
        }

        this.addingActor = true

        var newActor = this.buildNewActor(actor)

        try {
          newActor = await createActor(newActor)

          actor.id = newActor.id
          this.markActorRelevant(actor, isRelevant)
          this.addingActor = false
        } catch (e) {
          console.log(e)

          this.addingActor = false
        }
      },
      subTitle (actor) {
        if (!this.displayScores || actor.score === null) {
          return 'Appears ' + actor.appearances + (actor.appearances != 1 ? ' times' : ' time')
        }

        return 'Appears ' + actor.appearances + (actor.appearances != 1 ? ' times' : ' time') + ' - Score: ' + (actor.score ? actor.score : '0')
      },
      topTagsForActor (actor) {
        return Object.values(actor.tags || []).sort((a, b) => b.count - a.count).slice(0, 10).map(x => x.label)
      },
      handleClickActorSearchResult (actor) {
        trackHeapEvent('exploration.clickActor')

        if (this.addingActor) {
          return
        }

        if (!actor.id) {
          this.storeActorAndViewDetail(actor)

          return
        }

        if (this.$store.getters.views.includes('actors/detail')) {
          // Be sure to fetch it, because ActorDetail may not.
          this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_DETAIL, actor.id)
          this.$router.push(`/actors/${actor.id}`)

        } else if (this.$store.getters.isMember) {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'scores', metaData: actor.id })

        } else {
          try {
            this.$router.push(`/actors`)
            setTimeout(() => {
              this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'scores', metaData: actor.id })
            }, 100)
          } catch (e) {
            //
          }
        }
      },
      async storeActorAndViewDetail (actor) {
        this.addingActor = true

        var newActor = this.buildNewActor(actor)

        try {
          newActor = await createActor(newActor)

          this.$router.push('/actors/' + newActor.id)
          this.addingActor = false
        } catch (e) {
          console.log(e)

          // If the error is an error that fails because the (or a similar) actor already exists, go to the profile
          if (e.id) {
            this.$router.push('/actors/' + e.id)
          }

          this.addingActor = false
        }
      },
      buildNewActor (actor) {
        return {
          name: actor.name,
          actor_type: actor.actor_type,
          url: actor.url,
          wikipedia: actor.wikipedia,
          crunchbase: actor.crunchbase,
          description: actor.description,
        }
      },
    },
  })
</script>

<style lang="scss" scoped>
  @import "../../../../scss/_variables.scss";
  .article-actor__result {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    border-bottom: 1px solid $color-borders;
    background-color: transparent;
    transition: all .2s;

    &:hover {
      cursor: pointer;
      /*background-color: rgba(#555, 0.05);*/
    }

    &:last-child {
      border: 0;
    }
  }

  .article-actor__result__visual {
    margin-right: 1rem;
    background-size: cover;
    background-position: center;

    @media (min-width: $screen-md) {
      min-width: 220px;
      max-width: 220px;
      min-height: 100px;
    }
  }

  .article-actor__result__title {
    display: flex;
    align-items: center;
  }

  .article-actor__result__subtitle {
    margin-bottom: 2px;
  }

  .article-actor__result__description {
    margin-bottom: 4px;
  }

  .article-actor__result__tags__tag {
    display: inline-block;
    background: #eee;
    color: #555;
    padding: 0 4px;
    margin-right: 3px;
    margin-bottom: 3px;
  }

  .relevant-button {
    cursor: pointer;
    margin-left: auto;
  }

  .article-actor__result__info {
    width: 100%;
  }

  .concept-map__loading {
    margin-top: 20px;
    text-align: center;

    .svg-icon {
      position: relative;
      top: 5px;
    }
  }
  .article-actor__result__subtitle {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-size: 12px;
    color: $color-text-grey-light;
  }
</style>
