import { SiteTemplate } from './config.js'

export function generateReportFiltersForActors () {
  if (!window.config.report_settings || !window.config.report_settings.length) {
    return {}
  }

  /* if (window.config.siteTemplate && [SiteTemplate.NEW_SIMPLIFIED, SiteTemplate.SIMPLIFIED].includes(window.config.siteTemplate)) {
    const reportSettingsForActors = window.config.report_settings.filter(setting => setting.enabled_for_legal_entity || setting.enabled_for_person || setting.enabled_for_community)
    return generateReportFilters(reportSettingsForActors)
  } */

  return generateReportFilters(window.config.report_settings)
}

export function generateReportFilters (reportSettings) {
  var reportFilters = {}

  for (var index = 0; index < reportSettings.length; index++) {
    var reportTemplate = reportSettings[index]

    for (let fieldIndex = 0; fieldIndex < reportTemplate.reportFields.length; fieldIndex++) {
      var reportField = reportTemplate.reportFields[fieldIndex]
      var reportFieldName = 'report_field_' + reportField.id

      if (reportField.type === 'number' || reportField.type === 'score') {
        var minReportFieldName = reportFieldName + '_min'
        var maxReportFieldName = reportFieldName + '_max'

        reportFilters[minReportFieldName] = null
        reportFilters[maxReportFieldName] = null
      } else if (['options', 'multi_select'].includes(reportField.type)) {
        reportFilters[reportFieldName] = []
      } else if (['checkbox'].includes(reportField.type)) {
        reportFilters[reportFieldName] = null
      } else if (reportField.type === 'date') {
        var minReportDateFieldName = reportFieldName + '_min'
        var maxReportDateFieldName = reportFieldName + '_max'

        reportFilters[minReportDateFieldName] = null
        reportFilters[maxReportDateFieldName] = null
      }
    }
  }

  return reportFilters
}
