<template>
  <div class="home" ref="home">
    <div class="home__titlebar" v-if="!ui.isMobile">
      <a class="home__logo" target="_blank" :href="$store.state.config.homepage" v-if="$store.state.config.homepage">
        <img :src="$store.state.config.logo_url">
      </a>
      <div class="home__logo" v-else>
        <img :src="$store.state.config.logo_url">
      </div>
      <div class="home__title-container">
        <div class="home__title">
          {{ title }}
        </div>
      </div>
      <template v-if="homeLinks && homeLinks.length > 0">
        <template v-for="homeLink in homeLinks">
          <div class="home__home-link-container" v-if="homeLink.url">
            <a class="home__home-link" :href="homeLinkUrl(homeLink.url)" target="_blank">
              <div class="col-xs-12 home__home-link-image">
                <Avatar :src="homeLink.image_url" :alt="homeLink.text || 'Link'"/>
              </div>
              <div class="col-xs-12 home__home-link-text">
                <span> {{ homeLink.text || 'Link' }} </span>
              </div>
            </a>
          </div>
        </template>
      </template>
    </div>

    <!-- DIRECTORY -->
    <!-- this section must be separate because 'ref' isn't allowed inside v-for anymore -->
    <template v-if="directorySectionIsVisible">
      <section class="home__section">
        <div class="row">
          <div class="col-xs-12 col-sm-12 col-lg-6">
            <div class="home__map-container" v-if="!isIE11()">
              <map-view
                  ref="mapPane"
                  :data="mapData.data"
                  :loading="mapData.loading"
                  :getMapData="getMapData"
                  :getData="getMapData"
                  :noSearchWhileMoving="true"
                  :disableMouseWheelZoom="true"
                  :custom-styling="{'min-height': '650px !important', 'position': 'relative'}"
              />
            </div>
            <div class="explore__empty-state" v-else>
              The map view is not supported by your browser.
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div class="home__report_call_countdown_container" v-if="callCountdownTemplate && isLoggedIn">
              <span class="home__report_call_countdown" v-html="callTimeDifference"></span><span
                class="home__report_call_text">{{ headlineText }}</span>
              <ds-button :icon="applyButtonIcon" :label="applyButtonLabel" @click="onClickApplyForCallOnHomepage"
                         v-tooltip.left="ownedLegalEntityReportUrl ? null : applyButtonTooltip" variant="primary"
                         class="home__report_call_button" size="small" v-if="!isMember"/>
              <div v-if="isMember && submittedReportsCount !== null" class="home__report_call_submit_count">
                {{ submittedReportsCount }} reports submitted so far
              </div>
            </div>
            <NewCard :title="ui.isMobile ? 'Legend' : 'Quick search'" hideHeaderSeparator
                     v-if="visibleKeywords.length === 0">
              <div class="row home__category_count_container">
                <div class="col col-xs-12 col-sm-6 col-md-4"
                     v-for="(categoryCount, chunkIndex) in categoryCounts.stats">
                  <CategoryCount
                      :count="categoryCount.total"
                      :taxonomyId="categoryCount.value"
                      :property="categoryCounts.property"
                      :color="categoryCount.color"
                      @click="handleClickCategoryCount(categoryCount, categoryCounts.property)"
                  />
                </div>
              </div>
            </NewCard>

            <NewCard title="Filter the directory" hideHeaderSeparator
                     v-if="!ui.isMobile && (visibleFilterControls.length > 0 || visibleKeywords.length > 0)">
              <HomePageDirectorySearch
                  @addFilter="handleAddKeyword"
                  @removeFilter="handleRemoveKeyword"
                  @toggleFilter="getMapData"
                  @clearFilters="clearKeywords"
              />
              <template v-if="visibleKeywords.length > 0">
                <br>
                <div class="row">
                  <div class="col-lg-4 col-sm-12 col-xs-12" v-for="actor in listData.data.slice(0, 6)"
                       style="margin-bottom: 1rem;">
                    <ActorMiniCard
                        :actor="actor"
                        :color="actor.color"
                    />
                  </div>
                  <NonIdealState v-if="!listData.loading && listData.data.length === 0"
                                 title="There are no results for your search">
                    <template v-slot:cta>
                      <div>
                        <DsButton label="Explore directory" icon="view-gallery" @click="goToExplore(true)"
                                  size="extra-small" variant="primary"
                                  heapEvent="homepage.clickExploreDirectory"
                                  v-tooltip.top="Tooltips.EXPLORE_DIRECTORY"/>
                      </div>
                    </template>
                  </NonIdealState>
                </div>
                <ds-button
                    v-if="listData.data.length > 0 && !ui.isMobile"
                    variant="primary"
                    block
                    :label="'See the '+ listData.total + ' actors found'"
                    @click="goToExplore(false)"
                />
              </template>
            </NewCard>

            <template v-if="visibleKeywords.length === 0">
              <NewCard
                  title="Latest updated actors"
                  id="introjs-latest-updated-actors"
                  v-if="latestActors.length > 0"
              >
                <template v-slot:headerRight v-if=!ui.isMobile>
                  <div>
                    <DsButton label="Explore directory" icon="view-gallery" @click="goToExplore(true)"
                              size="extra-small" variant="secondary"
                              heapEvent="homepage.clickExploreDirectory" v-tooltip.top="Tooltips.EXPLORE_DIRECTORY"/>
                  </div>
                </template>
                <Carousel
                    :class="{'carousel--minicards': true, 'has-navigation': (latestActors.length > Math.max(1, Math.floor(cardsPerCarouselPage / 2)))}"
                    :items-to-show="Math.max(1, Math.floor(cardsPerCarouselPage / 2))"
                    :mouse-drag="false"
                >
                  <Slide
                      v-for="(actor, index) in latestActors"
                      :key="index"
                  >
                    <div class="carousel__slide-container">
                      <ActorMiniCard
                          :actor="actor"
                          :color="actor.color"
                      />
                    </div>
                  </Slide>
                  <template #addons>
                    <Navigation v-if="latestActors.length > Math.max(1, Math.floor(cardsPerCarouselPage / 2))"/>
                  </template>
                </Carousel>
              </NewCard>
            </template>
          </div>
        </div>
      </section>
    </template>

    <template v-for="section in visibleHomepageSectionsWithoutDirectory" :key="section.key">
      <!-- SPOTLIGHT -->
      <template v-if="section.id === HOMEPAGE_SECTIONS.SPOTLIGHT.id && galleryData.length > 0">
        <section class="home__section">
          <NewCard title="Actors in the spotlight">
            <template v-slot:headerRight v-if="!ui.isMobile">
              <div>
                <DsButton label="Explore directory" icon="view-gallery" @click="goToExplore(true)" size="extra-small"
                          variant="secondary" heapEvent="homepage.clickExploreDirectory"
                          v-tooltip.top="Tooltips.EXPLORE_DIRECTORY"/>
              </div>
            </template>
            <Carousel
                :class="{'carousel--minicards': true, 'has-navigation': (galleryData.length > cardsPerCarouselPage)}"
                :mouse-drag="false"
                :items-to-show="cardsPerCarouselPage"
                :autoplay="10000"
            >
              <Slide
                  v-for="(actor, index) in galleryData"
                  :key="index"
              >
                <div class="carousel__slide-container">
                  <ActorMiniCard
                      :actor="actor"
                      :color="actor.color"
                  />
                </div>
              </Slide>
              <template #addons>
                <Navigation v-if="galleryData.length > cardsPerCarouselPage"/>
              </template>
            </Carousel>
          </NewCard>
        </section>
      </template>

      <!-- NEWS FEEDS -->
      <template v-if="section.id === HOMEPAGE_SECTIONS.NEWS.id && desktopNewsFeed && desktopNewsFeed.length > 0"
                :key="'news-feed-carousel'">
        <section class="home__section">
          <NewCard title="News feed" class="new-card--no-list-padding">
            <template v-slot:headerRight v-if="!ui.isMobile">
              <div>
                <ds-button to="/dashboards/news-overview" size="small"
                           v-if="displayNewsOverview"
                           icon="bullhorn"
                           :label="`More news`"
                           variant="minimal"
                           v-tooltip.top="`View more ${announcementsLabel} to keep up to date with the latest news`"/>
                <ds-button to="/datalab/sources#feeds" v-if="isDeveloper || isOwner"
                           size="small" label="Add RSS feed" icon="plus"
                           variant="secondary"/>
              </div>
            </template>
            <Carousel
                :class="{'carousel--minicards': false, 'has-navigation': false}"
                :settings="carouselSettings(2)"
                :breakpoints="carouselBreakpoints(2)"
            >
              <Slide
                  v-for="article of desktopNewsFeed"
                  style="align-items: flex-start"
                  :key="'newsfeed' + article.key"
              >
                <div style="max-width: 100%; padding: 10px; text-align: left; height: 100%;">
                  <AnnouncementOrResourceCard
                      :key="'announcement-or-resource-card-' + article.key"
                      :data="article"
                      :preview-mode="false"
                      :show-image="false"
                      @addTag="addTagToFilters"
                      @likeArticle="likeArticle(article)"
                      @resourceDeleted="fetchLatestNews"
                  />
                </div>
              </Slide>
              <template #addons>
                <Navigation v-if="desktopNewsFeed.length > 2"/>
              </template>
            </Carousel>
          </NewCard>
        </section>
      </template>

      <!-- FUNDING -->
      <template v-if="section.id === HOMEPAGE_SECTIONS.FUNDING.id && latestFundingRounds.data.length > 0">
        <section class="home__section">
          <NewCard title="Latest funding rounds">
            <Carousel
                :class="{'carousel--minicards': true, 'has-navigation': (latestFundingRounds.data.length > cardsPerCarouselPage)}"
                :mouse-drag="false"
                :items-to-show="cardsPerCarouselPage"
                :autoplay="10000"
                :items-to-scroll="1"
                :snap-align="'start'"
                :transition="600"
            >
              <Slide
                  v-for="(fundingRound, index) in latestFundingRounds.data"
                  :key="index"
              >
                <div class="carousel__slide-container">
                  <MiniCard
                      :title="fundingRound.name"
                      :subtitle="toDateString(fundingRound.started_at)"
                      :content="createFundingRoundCardTitle(fundingRound)"
                      :visual="fundingRound.featured_image_url || fundingRound.website_screenshot || getDefaultFallbackImageUrl()"
                      @click="handleClickActorCard(fundingRound.actorId)"
                  />
                </div>
              </Slide>
              <template #addons>
                <Navigation v-if="latestFundingRounds.data.length > cardsPerCarouselPage"/>
              </template>
            </Carousel>
          </NewCard>
        </section>
      </template>

      <!-- EVENTS -->
      <template
          v-if="section.id === HOMEPAGE_SECTIONS.EVENTS.id && hasSocialMediaMonitoringDashboard && trendingEvents.data.length > 0">
        <section class="home__section">
          <NewCard title="Trending events">
            <Carousel
                :class="{'carousel--minicards': true, 'has-navigation': (trendingEvents.data.length > cardsPerCarouselPage)}"
                :mouse-drag="true"
                :items-to-show="cardsPerCarouselPage"
            >
              <Slide
                  v-for="(event, index) in trendingEvents.data"
                  :key="index"
              >
                <div class="carousel__slide-container">
                  <MiniCard
                      :title="event.name"
                      :content="`${event.name} is trending, posted in ${event.occurences} new messages today.`"
                      :visual="event.image || '/images/placeholder-event.png'"
                      @click="handleClickTrendingEvent(event)"
                  />
                </div>
              </Slide>
              <template #addons="{ slidesCount }">
                <Navigation v-if="trendingEvents.data.length > cardsPerCarouselPage"/>
              </template>
            </Carousel>
          </NewCard>
        </section>
      </template>

      <!-- INFLUENCERS -->
      <template v-if="section.id === HOMEPAGE_SECTIONS.INFLUENCERS.id && keyInfluencers.data.length > 0">
        <section class="home__section">
          <NewCard title="Key influencers">
            <Carousel
                :class="{'carousel--minicards': true, 'has-navigation': (keyInfluencers.data.length > cardsPerCarouselPage)}"
                :items-to-show="cardsPerCarouselPage"
            >
              <Slide
                  v-for="(influencer, index) in keyInfluencers.data"
                  :key="index"
              >
                <div class="carousel__slide-container">
                  <MiniCard
                      :title="influencer.actorName"
                      :content="`${influencer.actorName} has posted ${influencer.occurences} times.`"
                      :visual="influencer.featured_image_url || influencer.website_screenshot || getDefaultFallbackImageUrl()"
                      @click="handleClickActorCard(influencer.actorId)"
                  />
                </div>
              </Slide>
              <template #addons="{ slidesCount }">
                <Navigation v-if="keyInfluencers.data.length > cardsPerCarouselPage"/>
              </template>
            </Carousel>
          </NewCard>
        </section>
      </template>

      <!-- LATEST ANNOUNCEMENTS -->
      <template
          v-if="section.id === HOMEPAGE_SECTIONS.ANNOUNCEMENTS.id && (publicNotifications.data.length > 0 || featuredNotifications.data.length > 0)">
        <section class="home__section home__section--announcements">
          <NewCard
              :title="featuredNotifications.data.length > 0 ? `Featured ${announcementLabel}` : `Latest ${announcementLabel}`">
            <template v-slot:headerRight v-if="!ui.isMobile">
              <div>
                <ds-button to="/announcements" size="small"
                           :label="`More ${announcementsLabel}`" icon="bullhorn"
                           variant="minimal" heapEvent="homepage.clickShowAnnouncements"
                           v-tooltip.top="`View more ${announcementsLabel} to keep up to date with the latest news`"/>
                <ds-button v-if="isLoggedIn" size="small"
                           :label="`Add ${announcementLabel}`" icon="plus"
                           variant="secondary" @click="showAnnouncementPanel"
                           heapEvent="homepage.clickAddAnnouncement"/>
              </div>
            </template>
            <div class="row" v-if="featuredNotifications.data.length > 0">
              <div class="col-sm-12 col-xs-12" style="max-width: 1200px; margin: 15px auto 0 auto;">
                <Carousel
                    :class="{'carousel--minicards': true, 'has-navigation': (featuredNotifications.data.length > 1)}"
                    :items-to-show="1"
                    :mouse-drag="false"
                >
                  <Slide v-for="(announcement, index) in featuredNotifications.data" :key="index"
                         style="padding-left: 20px; padding-right: 20px;">
                    <ArticlePreview
                        class="override-edit-button-position"
                        :image="getImageForAnnouncement(announcement)"
                        :notificationId="announcement.id"
                        :video="announcement.embedded_video"
                        :title="announcement.title"
                        :createdAt="announcement.created_at"
                        :author="announcement.user_name"
                        :body="announcement.shortHtml"
                        :tags="announcement.tags"
                        :type="announcement.type"
                        :notification="announcement"
                        :canLikeComment="true"
                        :actionText="announcement.action_text"
                        :actionUrl="announcement.action_url"
                        :canFeatureNotification="isOwner"
                        :showAutorData="true"
                        :canShowReadMoreButton="false"
                        :canDelete="false"
                        :aspectRatio="true"
                        notificationType="announcement"
                    />
                  </Slide>
                  <template #addons="{ slidesCount }">
                    <Navigation v-if="slidesCount > 1"/>
                  </template>
                </Carousel>
              </div>
            </div>
            <div class="row" v-else>
              <div class="col-sm-12 col-xs-12" style="max-width: 1200px; margin: 15px auto 0 auto;">
                <Carousel
                    :class="{'carousel--minicards': true, 'has-navigation': true}"
                    :items-to-show="1"
                    :mouse-drag="false"
                >
                  <Slide v-for="(announcement, index) in publicNotifications.data" :key="index"
                         style="padding-left: 20px; padding-right: 20px;">
                    <ArticlePreview
                        class="override-edit-button-position"
                        :image="getImageForAnnouncement(announcement)"
                        :notificationId="announcement.id"
                        :video="announcement.embedded_video"
                        :title="announcement.title"
                        :createdAt="announcement.created_at"
                        :author="announcement.user_name"
                        :body="announcement.shortHtml"
                        :tags="announcement.tags"
                        :type="announcement.type"
                        :notification="announcement"
                        :canLikeComment="true"
                        :actionText="announcement.action_text"
                        :actionUrl="announcement.action_url"
                        :canFeatureNotification="isOwner"
                        :showAutorData="true"
                        :canShowReadMoreButton="false"
                        :canDelete="false"
                        :aspectRatio="true"
                        notificationType="announcement"
                    />
                  </Slide>
                  <template #addons="{ slidesCount }">
                    <Navigation/>
                  </template>
                </Carousel>
              </div>
            </div>
          </NewCard>
        </section>
      </template>

      <!-- KNOWLEDGE BASE -->
      <template
          v-if="section.id === HOMEPAGE_SECTIONS.KNOWLEDGE_BASE.id && visibleKnowledgeBaseTags.length > 0 && !ui.isMobile">
        <section class="home__section" id="introjs-knowledge-base">
          <NewCard title="Explore the knowledge base">
            <template v-slot:headerRight v-if="!ui.isMobile">
              <div>
                <!--              TODO: implement this in a side panel in the future-->
                <ds-button to="/dashboards/knowledge-base" size="small"
                           label="Explore knowledge base" variant="secondary"
                           heapEvent="homepage.clickExploreKnowledgeBase"
                           v-tooltip.top="Tooltips.KNOWLEDGE_BASE"/>
              </div>
            </template>

            <p><em>Filter all articles, {{ announcementsLabel }} and other resources available in the knowledge base by
              clicking on the preselected topics.</em></p>
            <div class="row home__category_count_container">
              <div class="col col-xs-12 col-sm-4 col-md-3 col-lg-2" v-for="tag in visibleKnowledgeBaseTags">
                <CategoryCount
                    :count="tag.count"
                    :taxonomyId="tag.value"
                    :property="categoryCounts.property"
                    color="#018CC7"
                    @click="handleClickKnowledgeBaseTag(tag, categoryCounts.property)"
                />
              </div>
            </div>
          </NewCard>
        </section>
      </template>

      <!-- EXPLORATION -->
      <template
          v-if="(section.id === HOMEPAGE_SECTIONS.EXPLORATION.id || section.id === HOMEPAGE_SECTIONS.EXPLORATION_RESULTS_ONLY.id)  && !ui.isMobile">
        <section class="home__section">
          <NewCard title="Explore your world" id="introjs-explore-your-world">
            <p v-if="!hasArticleDigestEnabled && followedConceptSearchIds.length > 0 && !isOwner" style="color: red">
              {{ $t('relevant_articles_cta_1') }}
              <router-link to="/profile/update#notification-settings">Notification Settings</router-link>
              {{ $t('relevant_articles_cta_2') }}
            </p>
            <template v-slot:headerRight v-if="!ui.isMobile && !onlySeesCuratedContent">
              <div>
                <ds-button
                    v-if="isMember && section.id === HOMEPAGE_SECTIONS.EXPLORATION_RESULTS_ONLY.id && conceptSearches.length > 8"
                    to="/dashboards/explore-content"
                    size="small" label="All trend monitoring" icon="tag" variant="minimal"
                    heapEvent="homepage.clickTrendMonitoring"/>
                <ds-button v-if="isOwner && isExplorationOnly" size="small" :label="'START SCOUTING'" icon="plus"
                           variant="secondary" @click="showConceptSearchGuide"/>
              </div>
            </template>

            <p><em></em></p>
            <div class="row home__category_count_container">
              <div class="col col-xs-12 col-sm-4 col-md-3" style="display: block; position: relative;"
                   v-for="conceptSearch in conceptSearches">
                <div @click="handleConceptSearchClick(conceptSearch)" class="concept-search__wrapper__wrapper">
                  <div class="concept-search__wrapper"
                       :class="{'concept-search__wrapper--disabled': conceptSearch.status !== 'done'}"
                       :style="conceptSearchStyle(conceptSearch)">
                    <div class="concept-search__container">
                      <div class="top-right-buttons" @click.stop v-if="$store.getters.hasAccessToMonitoring">
                        <follow-button class="a-button" :model-value="isFollowed(conceptSearch)"
                                       @update-modelValue="setFollowed(conceptSearch, $event)"
                                       v-if="!showingPublishButton"/>
                        <publish-button class="a-button" :model-value="isPublished(conceptSearch)"
                                        @update-modelValue="setPublished(conceptSearch, $event)" v-else/>
                      </div>
                      <div class="concept-search__title-and-description">
                        <div class="concept-search__title">{{ truncate(conceptSearch.title, 30) }}</div>
                        <div class="concept-search__description">{{ conceptSearch.description }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="concept-search__spinner" v-if="conceptSearch.status !== 'done'">
                    <div>
                      <icon name="spinner"/>
                      Processing...
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </NewCard>
        </section>
      </template>
    </template>
    <hover-overlay-button class="application-plus-btn" @openPanel="addActor" v-if="isOwner"
                          label="add Actor"
                          orientation="right"></hover-overlay-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

import { Carousel, Navigation, Slide } from 'vue3-carousel'
import ExplorationKeywordInput from '../../components/ExplorationKeywordInput/ExplorationKeywordInput.vue'
import AutocompleteDropdown from '../../components/Dropdown/AutocompleteDropdown.vue'
import AnnouncementOrResourceCard from '../../components/Simplified/AnnouncementOrResourceCard.vue'
import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters.js'
import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../../store/modules/actors.js'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
import { MUTATION_TYPES as BUZZ_MUTATION_TYPES } from '../../store/modules/buzz.js'
import MODAL_IDS from '../../constants/modal-ids.js'
import { humanize } from '../../constants/properties.js'
import {
  fetchConceptSuggestions,
  publishConceptSearch,
  storeConceptSearch,
  unpublishConceptSearch,
  validConceptSearchTitle
} from '../../api/exploration.js'
import { SECTIONS as HOMEPAGE_SECTIONS, TEMPLATES as HOMEPAGE_TEMPLATES } from '../../constants/homepage.js'
// import { fetchTelex } from '../../api/heartbeat';
import { fetchPublicNotifications } from '../../api/notifications.js'
import {
  fetchGalleryData,
  fetchKeyInfluencers,
  fetchKnowledgeBaseTags,
  fetchLatestActors,
  fetchLatestFundingRounds,
  fetchLatestNews,
  fetchStatsData,
  fetchTrendingEvents,
} from '../../api/homepage.js'
import { likeArticle } from '../../api/knowledge-base.js'
import UiMixin from '../../util/UiMixin.js'
import NavigationMixin from '../../util/NavigationMixin.js'
import FiltersMixin from '../../util/FiltersMixin.js'
import CarouselMixin from '../../util/CarouselMixin.js'
import { trackHeapEvent } from '../../util/analytics.js'
import { getCallTimeDifference, toDateString, toDayFullMonth, toOnlyDayMonthString } from '../../util/date.js'
import { toAmount } from '../../util/currency.js'
import ls from '../../util/ls.js'
import {
  getCardsPerCarouselPageBasedOnWindowWidth,
  getDefaultFallbackImageUrl,
  getImageForAnnouncement,
  isIE11,
  shortTitle,
  truncate
} from '../../util/helpers.js'
import CategoryCount from '../CategoryCount/CategoryCount.vue'
import CollapsablePanel from '../CollapsablePanel/CollapsablePanel.vue'
import ArticlePreview from '../ArticlePreview/ArticlePreview.vue'
import GalleryCard from './../GalleryView/GalleryCard.vue'
import Avatar from '../Avatar/Avatar.vue'
import MapView from '../MapView/MapView.vue'
import Card from '../Card/Card.vue'
import NewCard from '../NewCard/NewCard.vue'
import ActorMiniCard from '../ActorMiniCard/ActorMiniCard.vue'
import TopFilters from '../Filters/TopFilters.vue'
import SuggestionInput from '../SuggestionInput/SuggestionInput.vue'
import KeywordWithFacet from '../Keyword/KeywordWithFacet.vue'
import FileThumbnail from '../FileThumbnail/FileThumbnail.vue'
import CardNews from '../Card/CardNews.vue'
import SemanticDropdown from '../Semantic/SemanticDropdown.vue'
import ActorDetailProfileMainContentBlock from '../../pages/ActorDetail/ActorDetailProfileMainContentBlock.vue'
import RadioButton from '../../components/Form/RadioButton.vue'
import BuzzCard from '../../components/Card/BuzzCard.vue'
import Tag from '../Tag/Tag.vue'
import ICON_MANIFEST from '../../../icons/manifest.json'
import HomePageDirectorySearch from '../HomePageDirectorySearch/HomePageDirectorySearch.vue'
import MiniCard from '../MiniCard/MiniCard.vue'
import UnstyledLink from '../UnstyledLink/UnstyledLink.vue'
import ResourceCard from '../ResourceCard/ResourceCard.vue'
import EcosystemIntroduction from '../EcosystemIntroduction/EcosystemIntroduction.vue'
import NonIdealState from '../NonIdealState/NonIdealState.vue'
import FollowButton from '../FollowButton/FollowButton.vue'
import PublishButton from '../PublishButton/PublishButton.vue'
import ConceptSearchMixin from '../../util/ConceptSearchMixin.js'
import IntroJsMixin from '../../util/IntroJsMixin.js'
import { ActionTypes as ConceptSearchesAcionTypes } from '../../store/modules/concept-searches.js'
import ReportMixin from '../../util/ReportMixin.js'
import { countSubmittedReports } from '../../api/reporting.js'
import TranslationsMixin from '../../util/TranslationsMixin.js'
import HoverOverlayButton from '../Overlay/HoverOverlayButton.vue'
import { MUTATION_TYPES as KNOWLEDGE_BASE_MUTATION_TYPES } from '../../store/modules/knowledgeBase.js'

const DEFAULT_HOMEPAGE_TEMPLATE = HOMEPAGE_TEMPLATES.ACTOR_FOCUSED.sections.map(section => ({
  ...section,
  isVisible: true,
}))

export default defineComponent({
  components: {
    AnnouncementOrResourceCard,
    FollowButton,
    PublishButton,
    ExplorationKeywordInput,
    NonIdealState,
    EcosystemIntroduction,
    ResourceCard,
    UnstyledLink,
    MiniCard,
    HomePageDirectorySearch,
    FileThumbnail,
    KeywordWithFacet,
    SuggestionInput,
    ArticlePreview,
    CollapsablePanel,
    CategoryCount,
    GalleryCard,
    Avatar,
    MapView,
    Card,
    NewCard,
    ActorMiniCard,
    TopFilters,
    Carousel,
    Slide,
    Navigation,
    CardNews,
    SemanticDropdown,
    ActorDetailProfileMainContentBlock,
    RadioButton,
    BuzzCard,
    Tag,
    AutocompleteDropdown,
    HoverOverlayButton,
  },
  name: 'dashboard',
  data() {
    return {
      callTimeDifference: '',
      HOMEPAGE_SECTIONS: HOMEPAGE_SECTIONS,
      filterTagNumberLimit: 5,
      // contents: '',
      carouselIcons: {
        navigationLeft: ICON_MANIFEST['chevron-left'],
        navigationRight: ICON_MANIFEST['chevron-right'],
      },
      cardsPerCarouselPage: getCardsPerCarouselPageBasedOnWindowWidth(window.innerWidth),
      homepageTemplate: this.$store.state.config.homepageTemplate || DEFAULT_HOMEPAGE_TEMPLATE,
      publicNotifications: {
        data: [],
        isLoading: false,
      },
      featuredNotifications: {
        data: [],
        isLoading: false,
      },
      changes: {
        data: [],
        isLoading: false,
      },
      latestFundingRounds: {
        data: [],
        isLoading: false,
      },
      trendingEvents: {
        data: [],
        isLoading: false,
      },
      keyInfluencers: {
        data: [],
        isLoading: false,
      },
      knowledgeBaseTags: {
        data: {},
        isLoading: false,
      },
      latestNews: [],
      latestActors: [],
      numberOfCategoryCards: 9, // Will define how many category cards are displayed in the home page.
      galleryData: [], // Will store the for each card for the gallery (actors in the spotlight)
      galleryDataOffset: 0, // Will be used to fetch the next set of data for the gallery
      galleryDataLimit: 50, // Will be used to set the limit of items to be returned in the call
      galleryDataCanFetchMore: true, // will be used to determine if we can fetch more data for the gallery
      statsData: {
        property: '',
        stats: [],
      }, // Will contain the data for the category cards (count and label)
      knowledgeBaseItemList: 10,
      conceptStatsData: {},
      explorationSearchInProgress: false,
      tagData: {},
      isFirstVisitForUser: !window.localStorage.getItem('datascouts.hasSeenEcosystemIntroduction'),
      searchTopics: [],
      showingConceptSearchGuide: false,
      Tooltips,
      submittedReportsCount: null,
      callTimeInterval: null,
    }
  },
  computed: {
    headlineText() {
      return this.localizedDisplayProperty(this.config, 'headlineText')
    },
    allConceptSearches() {
      return this.$store.state.conceptSearches.allConceptSearches || []
    },
    isExplorationOnly() {
      return this.$store.getters.isExplorationOnly
    },
    conceptSearches() {
      const allSortedConceptSearches = this.allConceptSearches.slice(0).sort((a, b) => b.explored_at.localeCompare(a.explored_at))

      if (this.isExplorationOnly) {
        return allSortedConceptSearches
      }

      return allSortedConceptSearches.slice(0, 8)
    },
    allConceptSearchesPromise() {
      return this.$store.state.conceptSearches.allConceptSearchesPromise
    },
    hasArticleDigestEnabled() {
      return ['daily', 'weekly'].includes(this.$store.getters.userSettings.contentDigest)
    },
    showingPublishButton() {
      return this.$store.getters.isAdmin || this.$store.getters.isOwner
    },
    onlySeesCuratedContent() {
      return this.$store.getters.onlySeesCuratedContent
    },
    canUserEditConceptSearch() {
      return this.isOwner
    },
    config() {
      return this.$store.state.config
    },
    visibleKeywords() {
      return this.keywords.filter((keyword, index) => keyword.value !== '-' && this.canShowTag(index) && keyword.facet !== 'actor_type' && keyword.facet !== 'legendProperty' && keyword.facet.indexOf('report_field_') === -1)
    },
    directorySectionIsVisible() {
      // the directory section must be separate because 'ref' isn't allowed inside v-for anymore
      return !!this.visibleHomepageSections.find(section => {
        return section.id == HOMEPAGE_SECTIONS.DIRECTORY.id
      })
    },
    visibleHomepageSectionsWithoutDirectory() {
      return this.visibleHomepageSections.filter(section => {
        return section.id != HOMEPAGE_SECTIONS.DIRECTORY.id
      })
    },
    visibleHomepageSections() {
      return this.homepageTemplate.filter(section => {
        switch (section.id) {
          case HOMEPAGE_SECTIONS.KNOWLEDGE_BASE.id: {
            return this.canSeeKnowledgeBase && section.isVisible
          }
        }

        return section.isVisible
      })
    },
    title() {
      return this.config.title
    },
    ecosystemColor() {
      return this.$store.state.config.primaryColor
    },
    homeLinks() {
      return window.config.homeLinks || []
    },
    hasSocialMediaMonitoringDashboard() {
      return (this.$store.state.config.viewDashboard || []).includes('news')
    },
    desktopNewsFeed() {
      const newsFeedItemList = this.latestNews

      return newsFeedItemList
    },
    isDeveloper() {
      return this.$store.getters.isDeveloper
    },
    isOwner() {
      return this.$store.getters.isOwner
    },
    isMember() {
      return this.$store.getters.isMember
    },
    isPortfolioMember() {
      return this.$store.getters.isPortfolioMember
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    canSeeKnowledgeBase() {
      return this.$store.getters.hasAccessToKnowledgeBase && this.config.viewDashboard && this.config.viewDashboard.includes('knowledge-base')
    },
    categoryCounts() {
      if (!this.statsData.stats) {
        return {}
      }

      const statsCopy = this.statsData.stats.slice(0, 6)

      // Returns the first number of stats defined in 'numberOfCategoryCards'
      statsCopy.splice(-(statsCopy.length - this.numberOfCategoryCards), (statsCopy.length - this.numberOfCategoryCards))

      const statsMeta = statsCopy.map(stat => {
        // This is commented for the time being in the case we need to change the behaviour of the category cards to adapt
        // to the legend property change from the directory page

        // var legendProperty = this.$store.state.config.legendProperty;
        const legendProperty = this.$store.state.config.defaultLegendProperty
        const legendColorName = this.$store.getters.legendMapping[legendProperty]
        const legendColor = this.$store.getters.hexColours[legendColorName[stat.value]]

        return Object.assign({}, stat, {
          label: stat.value,
          color: legendColor,
        })
      })

      return {
        stats: statsMeta,
        property: this.statsData.property,
      }
    },
    mapData() {
      return this.$store.state.actors.mapData
    },
    listData() {
      return this.$store.state.actors.listData
    },
    filters() {
      return this.$store.getters.baseFilterObject
    },
    keywords() {
      return this.$store.state.filters.keywords
    },
    visibleKnowledgeBaseTags() {
      return this.knowledgeBaseTags.data
    },
  },
  mounted() {
    this.$bus.on('resize', () => {
      this.cardsPerCarouselPage = getCardsPerCarouselPageBasedOnWindowWidth(window.innerWidth)
    })

    this.$bus.on('refreshConceptSearches', () => {
      this.fetchConceptSearches()
    })

    this.$bus.on('stop-intro', (type) => {
      if (type === 'home') {
        this.showingConceptSearchGuide = false
      }
    })

    if (this.callCountdownTemplate) {
      this.callTimeInterval = setInterval(() => {
        this.callTimeDifference = this.getCallTimeDifference(this.callCountdownTemplate.due_date)
      }, 1000)
    }

    this.fetchAllData()
    // The div in which the map is displayed is flexible in size, the map however is not aware of this resize and the bounds of the map then remain the old ones
    // making it so that you only see a part of the data

    // This is to force the video identified with the Id 'my-video' to autoplay
    // Using the attributes added to the video tag are not fullproof and the video can sometimes not autoplay
    // By setting the muted property, this increases also the chances of the autoplay to work as it seems that moder browsers could block
    // the video from autoplaying it if not mutted
    // Because of this we set the video to be muted, add the autoplay, load the video and then set it to play.
    if (document.getElementById('my-video')) {
      document.getElementById('my-video').muted = true
      document.getElementById('my-video').autoplay = true
      document.getElementById('my-video').load()
      document.getElementById('my-video').play()
    }
  },
  beforeUnmount() {
    this.$bus.off('resize')
    this.$bus.off('refreshConceptSearches')
    this.$bus.off('stop-intro')
    if (this.callTimeInterval) {
      clearInterval(this.callTimeInterval)
    }
  },
  methods: {
    getDefaultFallbackImageUrl,
    getImageForAnnouncement,
    getCallTimeDifference,
    isIE11,
    truncate,
    toOnlyDayMonthString,
    toDayFullMonth,
    toDateString,
    toAmount,
    trackHeapEvent,
    humanize,
    createFundingRoundCardTitle(fundingRound) {
      let amount = toAmount(fundingRound.capital_raised)

      if (fundingRound.capital_raised && amount.length) {
        amount += ' ' + fundingRound.currency.toUpperCase()
      } else {
        amount = 'an unknown amount'
      }

      return `${fundingRound.name} raised ${amount} ${fundingRound.type !== 'other' ? `in ${fundingRound.type}` : ''}`
    },
    likeArticle(resource) {
      likeArticle(resource.sql_media_id, !resource.is_liked_by_user)
    },
    countSubmittedReports() {
      if (this.isMember && this.selectedReportTemplateForHeadline) {
        countSubmittedReports(this.selectedReportTemplateForHeadline.id).then(result => {
          if (result && typeof result === 'string') {
            this.submittedReportsCount = result
          }
        })
      }
    },
    addTagToFilters(tag) {
      if (!tag.value) {
        return
      }

      this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.CLEAR)
      this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.SET_FACET_KEYWORD, tag)

      if (this.$route.name && this.$route.name.indexOf('knowledge-base') <= 0) {
        this.$router.push('/dashboards/knowledge-base')
      }
    },
    addActor() {
      const productModalContext = {
        prefilled: {
          actor_type: 'Product',
        },
        afterCreate: 'goToActor',
      }
      const actorModalContext = {
        afterCreate: 'goToActor',
      }

      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, this.isProductsPage ? productModalContext : actorModalContext)
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)
    },
    showConceptSearchGuide() {
      window.createConceptSearch = true
      this.$router.push('/dashboards/explore-content')
    },
    startIntroJs() {
      if (this.seenIntros.includes('home') || !this.isLoggedIn) {
        return
      }

      const config = this.buildIntroJsConfig(this.config, this.$store.getters.userRole)
      if (!config || !config.home || !config.home.steps) {
        return
      }

      config.home.steps = this.getIntroJsStepsConfig(config.home.steps)
      const intro = this.introJs().setOptions(config.home)
      this.currentIntro = intro

      const componentScope = this
      intro.onbeforechange(this.introJsBeforeStepCallback.bind(this))
      intro.onafterchange(this.introJsAfterStepCallback.bind(this))
      intro.oncomplete(function () {
        componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
      })
      intro.onexit(function () {
        componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
      })

      intro.start()
    },
    openExploreModal() {
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
        search_topics: this.searchTopics,
        onSaved: () => {
          this.searchTopics = []
          this.fetchConceptSearches()
        },
      })

      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.EXPLORE_CONTENT)
    },
    isHomePageSectionEnabled(sectionId) {
      const sectionTemplateEntry = this.visibleHomepageSections.find(section => section.id === sectionId)
      return sectionTemplateEntry && sectionTemplateEntry.isVisible
    },
    canShowTag(index) {
      return (index + 1) <= this.filterTagNumberLimit
    },
    renderMap() {
      this.$refs.mapPane && this.$refs.mapPane[0] && this.$refs.mapPane[0].invalidateSize()
    },
    homeLinkUrl(url) {
      const reg = /^[a-zA-Z0-9_-]+$/

      if (reg.test(url)) {
        return '/static/' + url
      }

      return url
    },
    getMapData() {
      // Clear the portfolio filter when entering the home otherwise the portfolio actors gets loaded on the map
      this.$store.commit(FILTERS_MUTATION_TYPES.SET_PORTFOLIO, null)

      // const filters = this.$store.getters.mapFilterObject;
      const filters = Object.assign({}, this.$store.getters.mapFilterObject)

      // if (filters.tl) {
      this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_MAP, filters)
      // }
    },
    handleAddKeyword(keyword) {
      this.$store.commit(FILTERS_MUTATION_TYPES.ADD_KEYWORD, keyword)
      this.getMapData()
      trackHeapEvent('homepage.addKeyword', { name: keyword })
    },
    handleRemoveKeyword(keyword) {
      this.$store.commit(FILTERS_MUTATION_TYPES.REMOVE_KEYWORD, keyword)
      this.getMapData()
    },
    fetchPublicNotifications() {
      fetchPublicNotifications(5).then((items) => {
        this.publicNotifications.data = items
      })
    },
    fetchConceptSearches() {
      this.$store.dispatch(ConceptSearchesAcionTypes.FETCH_ALL_CONCEPT_SEARCHES)

      this.allConceptSearchesPromise.then(() => {
        if (this.conceptSearches.length === 0) {
          this.showingConceptSearchGuide = true
        }

        this.refreshSearchesIfContainsInProgress()
      })
    },
    refreshSearchesIfContainsInProgress() {
      if (this.conceptSearches.find(search => search.status === 'in_progress')) {
        setTimeout(() => {
          this.fetchConceptSearches()
        }, 30000)
      }
    },
    fetchFeaturedNotifications() {
      fetchPublicNotifications(2, 0, { featured: 'true' }).then((items) => {
        this.featuredNotifications.data = items
      })
    },
    async fetchSectionData({ dataPropertyName, promise }) {
      this[dataPropertyName].isLoading = true

      try {
        const data = await promise
        this[dataPropertyName].data = data
      } finally {
        this[dataPropertyName].isLoading = false
      }
    },
    handleClickActorCard(actorId) {
      trackHeapEvent('homepage.handleClickActorCard')

      if (this.ui.isMobile) {
        this.$router.push(`/actors/${actorId}`)
      } else {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          component: 'scores',
          metaData: actorId,
        })
      }
    },
    handleClickTrendingEvent(event) {
      trackHeapEvent('homepage.clickTrendingEvent')

      if (this.hasSocialMediaMonitoringDashboard) {
        this.$store.commit(BUZZ_MUTATION_TYPES.UPDATE_BUZZ_FILTER, {
          name: event.name,
          facet: 'topic',
          value: event.id,
        })
        this.$router.push(`/dashboards/news?topic=${event.id}`)
      }
    },
    fetchLatestActors() {
      const parameters = { limit: 9 }

      fetchLatestActors(parameters).then((actors) => {
        this.latestActors = actors
      })
    },
    fetchLatestNews() {
      return fetchLatestNews({ limit: 10, offset: 0, transform: 'news-monitoring' })
          .then(news => {
            var items = news
                .map(response => {
                  if (['rss', 'pr', 'blog'].includes(response.media_type) && response.sql_media_id) {
                    response.detail_link = '/files/' + response.sql_media_id
                  }
                  return response
                })
                .slice(0, 10)

            const itemsWithBody = items.filter(item => item.body && item.body.length > 10)

            if (itemsWithBody.length > 1) {
              this.latestNews = itemsWithBody

              return
            }

            this.latestNews = items
          })
          .catch(error => {
            console.log(error)
          })
    },
    fetchGalleryData() {
      if (this.galleryDataCanFetchMore === false) {
        this.galleryDataOffset = 0
      }

      const parameters = {
        limit: this.galleryDataLimit,
        offset: this.galleryDataOffset,
      }

      fetchGalleryData(parameters).then(response => {
        if (!response.data || response.data.length == 0) {
          return
        }

        const paging = response.paging
        const galleryData = response.data

        this.galleryDataCanFetchMore = !!paging.next
        this.galleryData = galleryData.actors
      }).catch(errors => {
        // console.log(errors)
      })
    },
    goToDirectory() {
      // If the personalised homepage toggle was enabled, overwrite / set the portfolio filter to be the personalised portfolio
      this.$router.push('/actors')
    },
    goToKnowledgeBase(selectFilterTag) {
      // First we clear the existing knowledgeBaseKeywords
      ls('knowledgeBaseKeywords', [])

      // After clearing we set the filter value
      ls('knowledgeBaseKeywords', [{ facet: 'tags', value: selectFilterTag }])
      this.$router.push('/dashboards/knowledge-base')
    },
    goToExplore(shouldClearKeywords) {
      shouldClearKeywords && this.clearKeywords()
      this.$router.push('/actors')
    },
    fetchFilteredActors() {
      const listFilters = { ...this.$store.getters.listFilterObject }
      this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_LIST, listFilters)
    },
    handleClickCategoryCount(category, property) {
      this.clearKeywords()
      this.$store.commit('FILTERS/SET_PORTFOLIO', null)

      // this.handleAddKeyword({ facet: this.$store.state.filters.legendProperty, value: category.label });
      this.handleAddKeyword({ facet: property, value: category.label })

      trackHeapEvent('homepage.clickTaxonomyCount', { label: category.label })

      this.$router.push('/actors')

      if (this.ui.isMobile) {
        this.$router.push('/actors-simplified')
      }
    },
    handleClickKnowledgeBaseTag(tag) {
      ls('knowledgeBaseKeywords', [
        {
          'facet': 'tags',
          'value': tag.id,
          'displayName': tag.label,
        },
      ])

      trackHeapEvent('homepage.clickKnowledgeBaseTag')

      this.$router.push('/dashboards/knowledge-base')
    },
    handleConceptSearchClick(conceptSearch) {
      this.$router.push({
        name: 'ConceptMap',
        params:
            { id: conceptSearch.id },
      })
    },
    handleNewConceptSearchClick() {
      if (this.searchTopics.length === 0) {
        return
      }

      const searchTopics = this.searchTopics
      this.explorationSearchInProgress = true

      validConceptSearchTitle(this.searchTopics[0].label)
          .then(title => {
            return storeConceptSearch({
              title,
              report_type: null,
              context: [],
              exclude: [],
              search_topics: searchTopics,
              dictionary_context_id: [],
              description: '',
              geography_context: [],
              scope_description: '',
              scoring_config: [],
            })
          })
          .then(response => {
            this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
              result: response,
            })

            this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.EXPLORE_CONTENT)

            this.explorationSearchInProgress = false
            this.fetchConceptSearches()
          })
          .catch(err => {
            console.error(err)
            this.explorationSearchInProgress = false
          })

      this.searchTopics = []
    },
    clearKeywords() {
      this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR_KEYWORDS)
    },
    fetchStatsData() {
      // We might need in the future to pass a parameter "legendProperty" to fetch the changed property form te directory page
      // If so, the API endpoint needs to include the parameters in the call.
      const parameters = {}

      fetchStatsData(parameters).then(response => {
        this.statsData = response.data
      }).catch(errors => { // console.log(errors)
      })
    },
    shortTitle,
    openConceptSearchSidePanel(conceptSearch) {
      const params = { conceptSearchId: conceptSearch.id, allowManualSearch: true }

      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
        component: 'concept-search-panel',
        metaData: params,
      })
    },
    fetchAllData() {
      this.countSubmittedReports()
      this.fetchStatsData()
      this.fetchLatestActors()
      this.fetchFilteredActors()
      this.fetchLatestNews().then(() => {
        this.startIntroJs()
      })

      // Loop through the template and only fetch data for sections that are actually visible
      // this.homepageTemplate.forEach((section) => {
      this.visibleHomepageSections.forEach((section) => {
        switch (section.id) {
          case HOMEPAGE_SECTIONS.FUNDING.id: {
            this.fetchSectionData({
              dataPropertyName: 'latestFundingRounds',
              promise: fetchLatestFundingRounds(),
            })
            break
          }
          case HOMEPAGE_SECTIONS.EVENTS.id: {
            this.fetchSectionData({
              dataPropertyName: 'trendingEvents',
              promise: fetchTrendingEvents(),
            })
            break
          }
          case HOMEPAGE_SECTIONS.INFLUENCERS.id: {
            this.fetchSectionData({
              dataPropertyName: 'keyInfluencers',
              promise: fetchKeyInfluencers(),
            })
            break
          }
          case HOMEPAGE_SECTIONS.ANNOUNCEMENTS.id: {
            this.fetchPublicNotifications()

            if (this.isFirstVisitForUser) {
              this.fetchFeaturedNotifications()
            }
            break
          }
          case HOMEPAGE_SECTIONS.KNOWLEDGE_BASE.id: {
            this.fetchSectionData({
              dataPropertyName: 'knowledgeBaseTags',
              promise: fetchKnowledgeBaseTags(),
            })
            break
          }
          case HOMEPAGE_SECTIONS.SPOTLIGHT.id: {
            this.fetchGalleryData()
            break
          }
          case HOMEPAGE_SECTIONS.EXPLORATION_RESULTS_ONLY.id:
          case HOMEPAGE_SECTIONS.EXPLORATION.id: {
            this.fetchConceptSearches()
            break
          }
        }
      })
    },
    showAnnouncementPanel() {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.MANAGE_ANNOUNCEMENT)
    },
    showCreateResourcePanel() {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
        'component': 'create-resource-panel',
        'metaData': { 'reach': 'public' },
      })
    },
    conceptSearchStyle(conceptSearch) {
      return 'background-image: url(' + conceptSearch.image + ');'
    },
    handleAnnouncementCarouselPageChange(page) {
      // @deprecated via the new carousel slider
      trackHeapEvent('homepage.announcementCarousel', { page })
    },
    handleConceptSearchCreated() {
      this.fetchConceptSearches()
    },
    handleConceptSearchGuideDone() {
      this.showingConceptSearchGuide = false
    },
    autocompleteSearchTerm(query) {
      return fetchConceptSuggestions({ query: query, conceptType: 'any' })
          .then(response => {
            const options = []

            response.forEach(function (concept) {
              options.push({
                label: concept.value,
                value: concept.uri,
              })
            })

            return options
          })
          .catch(err => {
            console.log(err)
          })
    },
    isFollowed(conceptSearch) {
      return this.conceptSearchIsFollowed[conceptSearch.id]
    },
    setFollowed(conceptSearch, followed) {
      if (followed) {
        this.followConceptSearch(conceptSearch.id)
      } else {
        this.unfollowConceptSearch(conceptSearch.id)
      }
    },
    isPublished(conceptSearch) {
      return conceptSearch.published || false
    },
    setPublished(conceptSearch, published) {
      this.conceptSearch.published = published

      if (published) {
        publishConceptSearch(conceptSearch.id)
      } else {
        unpublishConceptSearch(conceptSearch.id)
      }
    },
  },
  watch: {
    'listData.data': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue.length !== oldValue.length) {
          setTimeout(this.renderMap, 200)
        }
      },
    },
    'galleryData': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue.length !== oldValue.length) {
          setTimeout(this.renderMap, 200)
        }
      },
    },
    'latestActors': {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue.length !== oldValue.length) {
          setTimeout(this.renderMap, 200)
        }
      },
    },
    filters: {
      deep: true,
      handler() {
        this.fetchFilteredActors()
      },
    },
  },
  mixins: [TranslationsMixin, CarouselMixin, FiltersMixin, NavigationMixin, UiMixin, ConceptSearchMixin, IntroJsMixin, ReportMixin],
})

const Tooltips = {
  EXPLORE_DIRECTORY: 'Explore the actors directory to get an overview of all available actors',
  // skip MORE_ANNOUNCEMENTS because 'this' isn't loaded yet
  // MORE_ANNOUNCEMENTS: `View more ${this.announcementsLabel} to keep up to date with the latest news`,
  KNOWLEDGE_BASE: 'Explore the knowledge base to get an overview of available content',
}
</script>

<style lang="scss">
.concept-search__wrapper {
  padding: 10px;
  padding-left: 20px;
  border: 1px solid #ccc;
  height: 225px;
  background-position: center;
  background-size: cover;
  color: white;
  text-shadow: 1px 1px 1px #000;
  cursor: pointer;
  font-size: 18px;
}

.concept-search__wrapper__wrapper {
  position: relative;
}

.concept-search__container {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;

  .top-right-buttons {
    text-align: right;

    .a-button {
      margin-right: 0;
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.concept-search__edit {
  position: absolute;
  right: 1.5rem;
  top: 0.5rem;
  z-index: 1;

  .svg-icon {
    left: 3px !important;
  }

  .svg-icon--cog {
    left: 3px !important;
  }
}

.concept-search__title-and-description {
  margin-top: auto;
}

.concept-search__title {
  font-size: 36px;
}

.concept-search__description {
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-height: 14px;
  max-height: 44px;
  padding-bottom: 2px;
}

.concept-search__stats {
  margin-top: auto;
  text-align: right;
  font-size: 14px;
}

.plus-container {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 120px;
  cursor: pointer;
}

.concept-search-bar {
  display: flex;
  align-items: center;
  padding-right: 1px;
  border: 1px solid #CECECE;
  max-width: 700px;
  margin: 0 auto;

  .button {
    margin-right: 0;
  }

  .multiselect.multiselect--datascouts {
    margin-right: 0;

    .multiselect__tags {
      border: none;
    }
  }
}
</style>

<style lang="scss">
.concept-search__spinner .fill-fg {
  fill: #ffffff !important;
}
</style>
