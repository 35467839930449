<template>
  <div class="list-view-container">
    <div class="list-view-button">
      <ds-button @click="clearFilters" label="clear filters"/>
    </div>
    <table class="table" style="border: 0px; word-break: break-all;">
      <thead>
      <tr>
        <th style="width: 150px;">Actor</th>
        <th style="width: 150px;">RelationShip</th>
        <th-sort by="name" v-model="connectionsList.name">Actor name 2</th-sort>
        <th-sort by="actorType" v-model="connectionsList.actorType">Actor type</th-sort>
        <th-sort by="category" v-model="connectionsList.category" style="width: 200px;">Category</th-sort>
        <th style="width: 150px;">Country</th>
        <th style="min-width: 250px;">Short description</th>
        <th-sort by="start" :options="connectionsList.start" v-model="connectionsList.start">Start Date</th-sort>
        <th-sort by="end" :options="connectionsList.end" v-model="connectionsList.end">End Date</th-sort>
        <th-sort by="status" :options="filterOptions.status" v-model="filters.status">Status</th-sort>
        <th style="width: 150px;">Comment</th>
      </tr>
      </thead>
      <tbody style="width: 100%;">
      <tr v-for="connection in filterConnections" class="table_row">
        <template v-if="connection.actorType === 'Product' || 'LegalEntity'">
          <td>
            {{ actor.name }}
          </td>
          <td>
            {{ connection.type }}
          </td>
          <td>
            <a :href="'/actors/' + connection.id" title="Go to actor profile" target="_blank">{{ connection.name }}</a>
          </td>
          <td>
            {{
              connection.actorType === 'Product' ? productLabel : (connection.actorType === 'Person' ? 'Person' : 'Organization')
            }}
          </td>
          <td>
            {{ connection.category && connection.category.name ? connection.category.name : '' }}
          </td>
          <td>
            {{ connection.country }}
          </td>
          <td>
            {{ connection.short_description }}
          </td>
          <td>
            {{ connection.start }}
          </td>
          <td>
            {{ connection.end }}
          </td>
          <td>
            {{ connection.status }}
          </td>
          <td>
            {{ connection.comment }}
          </td>
        </template>
      </tr>
      </tbody>
    </table>
    <br>
    <br>
    <div v-if="isPortfolioMember && (!isOwner || !isMember) && notAccessableConnectionsList.length > 0 && userCanEdit">
      <div>
        <h1>Connections suggestions</h1>
        <p>In the list below you can find connections that are not in one of your portfolio's or you do not have access
          to the actor. accept the suggestion to access the actor.
          (Note: The actor(s) will be added to the
          portfolio(s) of which <a href="#">{{ actor.name }}</a> is a member of)</p>
      </div>
      <div class="list-view-button">
        <ds-button class="call-to-action-card__action-button" :disabled="processingSuggestion" variant="primary"
                   label="Accept all suggestions" @click="approveSuggestion('all')"/>
      </div>
    </div>
    <br>
    <table class="table" style="border: 0px; word-break: break-all;"
           v-if="isPortfolioMember && !isOwner && notAccessableConnectionsList.length > 0 && userCanEdit">
      <thead>
      <tr>
        <th style="width: 150px;">Actor</th>
        <th style="width: 150px;">RelationShip</th>
        <th-sort by="name" v-model="notAccessableConnectionsList.name">Actor name 2</th-sort>
        <th-sort by="actorType" v-model="notAccessableConnectionsList.actorType">Actor type</th-sort>
        <th-sort style="width: 170px;">Category</th-sort>
        <th style="width: 150px;">Country</th>
        <th>Short description</th>
        <th-sort by="start" :options="notAccessableConnectionsList.start" v-model="notAccessableConnectionsList.start">
          Start Date
        </th-sort>
        <th-sort by="end" :options="notAccessableConnectionsList.end" v-model="notAccessableConnectionsList.end">End
          Date
        </th-sort>
        <th-sort by="status" :options="filterOptions.status" v-model="filters.status">Status</th-sort>
        <th style="width: 150px;">Actions</th>
      </tr>
      </thead>
      <tbody style="width: 100%;">
      <tr v-for="connection in notAccessableConnectionsList" class="table_row">
        <template v-if="connection.actorType === 'Product' || 'LegalEntity'">
          <td>
            {{ actor.name }}
          </td>
          <td>
            {{ connection.type }}
          </td>
          <td>
            {{ connection.name }}
          </td>
          <td>
            {{
              connection.actorType === 'Product' ? productLabel : (connection.actorType === 'Person' ? 'Person' : 'Organization')
            }}
          </td>
          <td>
            {{ connection.category && connection.category.name ? connection.category.name : '' }}
          </td>
          <td>
            {{ connection.country }}
          </td>
          <td>
            {{ connection.short_description }}
          </td>
          <td>
            {{ connection.start }}
          </td>
          <td>
            {{ connection.end }}
          </td>
          <td>
            {{ connection.status }}
          </td>
          <td>
            <div class="analysis-card-footer-actions--items">
              <ds-button
                  type="button"
                  @click.prevent="approveSuggestion(connection.id)"
                  title="Approve suggestion"
                  class="button"
                  :disabled="processingSuggestion"
                  style="margin-bottom: 0px;"
                  icon="check"
                  icon-fill-color="#0B3D8A"
              />
            </div>
          </td>
        </template>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import ThSort from '../components/Settings/ThSort.vue'
import { _intersect, getConnectionsForActor } from '../util/helpers.js'
import _throttle from 'lodash/throttle.js'
import Pagination from '../components/Pagination/SimplePagination.vue'
import TranslationsMixin from '../util/TranslationsMixin'
import { approveActorConnection } from '../store/modules/suggestions'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../store/modules/ui'
import MODAL_IDS from '../constants/modal-ids'
import { fetchAllPortfolios } from '../api/portfolios'
import { fetchSpottingAreas } from '../api/spottingareas'
import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '@/store/modules/filters'

export default {
  props: {},
  emits: ['actorUpdated'],
  data() {
    return {
      fullScroll: false,
      orderByProperty: null,
      orderByDirection: '',
      filters: {
        order: null,
        status: null,
      },
      connectionsList: [],
      notAccessableConnectionsList: [],
      processingSuggestion: false,
      accessiblePortfolios: [],
      accessibleSpottingAreas: [],
      accessibleSpottingAreaIds: [],
    }
  },
  beforeUnmount() {
  },
  mounted() {
    this.connections()
    this.getAccessiblePortfolios()
    this.getAccessibleSpottingAreas()
    this.resetPortfolio()
  },
  watch: {
    filters: {
      deep: true,
      handler: _throttle(function () {
        this.toggleOrder()
      }, 300),
    },
    filterConnections: {
      deep: true,
      handler: _throttle(function () {
        this.getAccessibleSpottingAreas()
      }, 300),
    },
  },
  computed: {
    userCanEdit() {
      // Actors cannot be edited on exploration-only platforms (aka scouting platform)
      if (this.$store.getters.isExplorationOnly) {
        return false
      }

      return (this.$store.getters.isMember && this.$store.getters.allowEdit) ||
          this.$store.getters.userClaimedIds.includes(this.actor.id) ||
          (_intersect(this.$store.getters.userContributionPortfolios, this.actorPortfolios).length > 0 && this.$store.getters.hasAccessToPortfolioSharing && this.$store.getters.isPortfolioMember) ||
          this.$store.getters.isOwner
    },
    actorPortfolios() {
      return this.actor.includedPortfolios || []
    },
    filterConnections() {
      if (this.filters.status) {
        return this.connectionsList.filter(connection => connection.status === this.filters.status && connection.has_access === true)
      }

      this.accessiblePortfolioList = _intersect(this.$store.getters.userContributionPortfolios, this.actorPortfolios)
      this.notAccessableConnectionsList = this.connectionsList.filter(connection => connection.has_access === false)
      return this.connectionsList.filter(connection => connection.has_access === true)
    },
    actor() {
      return this.$store.state.actors.detail.data
    },
    ecosystemRelationships() {
      return this.$store.getters.fullActorRelationships
    },
    filterOptions() {
      return {
        status: [
          { 'text': 'Active', 'value': 'active' },
          { 'text': 'Inactive', 'value': 'inactive' },
          { 'text': 'Future', 'value': 'future' },
        ],
      }
    },
    isPortfolioMember() {
      return this.$store.getters.isPortfolioMember
    },
    isOwner() {
      return this.$store.getters.isOwner
    },
    isMember() {
      return this.$store.getters.isMember
    },
  },
  methods: {
    resetPortfolio() {
      this.$store.commit(FILTERS_MUTATION_TYPES.SET_PORTFOLIO, null)
    },
    clearFilters() {
      this.filters.status = null
    },
    orderBy(property) {
      if (this.orderByProperty === property) {
        this.orderByDirection = (this.orderByDirection === '') ? '-' : ''
      } else {
        this.orderByProperty = property
      }

      this.filters.order = this.orderByDirection + this.orderByProperty
    },
    connections() {
      this.connectionsList = getConnectionsForActor(this.actor, this.ecosystemRelationships)
    },
    toggleOrder() {
      this.connectionsList.sort((a, b) => {
        if (this.filters.order === 'start') {
          return new Date(a.start).getTime() - new Date(b.start).getTime()
        }
        if (this.filters.order === '-start') {
          return new Date(b.start).getTime() - new Date(a.start).getTime()
        }
        if (this.filters.order === 'end') {
          return new Date(a.end).getTime() - new Date(b.end).getTime()
        }
        if (this.filters.order === '-end') {
          return new Date(b.end).getTime() - new Date(a.end).getTime()
        }
        if (this.filters.order === 'name') {
          return a.name.localeCompare(b.name)
        }
        if (this.filters.order === '-name') {
          return b.name.localeCompare(a.name)
        }

        if (this.filters.order === 'category') {
          let aCategoryName = ''
          let bCategoryName = ''

          if (a.category && a.category.name) {
            aCategoryName = a.category.name
          }
          if (b.category && b.category.name) {
            bCategoryName = b.category.name
          }

          return aCategoryName.localeCompare(bCategoryName)
        }

        if (this.filters.order === '-category') {
          let aCategoryName = ''
          let bCategoryName = ''

          if (a.category && a.category.name) {
            aCategoryName = a.category.name
          }
          if (b.category && b.category.name) {
            bCategoryName = b.category.name
          }

          return bCategoryName.localeCompare(aCategoryName)
        }
      })
    },
    approveSuggestion(approvedActorId) {
      // has multiple portfolios
      if (this.accessibleSpottingAreas.length > 1) {
        const allConnections = []
        if (approvedActorId === 'all') {
          this.notAccessableConnectionsList.forEach((connection) => {
            if (!allConnections.includes(connection.id)) {
              allConnections.push(connection.id)
            }
          })
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
            spottingAreas: this.accessibleSpottingAreas,
            mainActorId: this.actor.id,
            actorIds: allConnections
          })
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
            spottingAreas: this.accessibleSpottingAreas,
            mainActorId: this.actor.id,
            actorIds: [approvedActorId]
          })
        }
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.SELECT_SPOTTING_AREA_MODAL)
      } else {
        this.processingSuggestion = true
        if (approvedActorId === 'all') {
          const allConnections = []
          this.notAccessableConnectionsList.forEach((connection) => {
            if (!allConnections.includes(connection.id)) {
              allConnections.push(connection.id)
            }
          })

          approveActorConnection(this.actor.id, {
            actorIds: allConnections,
            spottingAreas: this.accessibleSpottingAreaIds
          })
              .then((response) => {
                this.$emit('actorUpdated')
                setTimeout(() => {
                  this.connections()
                  this.processingSuggestion = false
                }, 1000)
              })
              .catch(() => {
                this.processingSuggestion = false
              })
        } else {
          approveActorConnection(this.actor.id, {
            actorIds: [approvedActorId],
            spottingAreas: this.accessibleSpottingAreaIds
          })
              .then((response) => {
                this.$emit('actorUpdated')
                setTimeout(() => {
                  this.connections()
                  this.processingSuggestion = false
                }, 1000)
              })
              .catch(() => {
                this.processingSuggestion = false
              })
        }
      }
    },
    getAccessiblePortfolios() {
      fetchAllPortfolios([])
          .then((response) => {
            this.accessiblePortfolios = response.filter((portfolio) => portfolio.published_for_actors === false && portfolio.published_for_visitors === false && portfolio.published_for_portfolio_members === false)
          })
    },
    getAccessibleSpottingAreas() {
      fetchSpottingAreas({ actorId: this.actor.id, portfolioId: this.accessiblePortfolioList.toString() })
          .then((response) => {
            this.accessibleSpottingAreas = response.filter((spottingArea) => spottingArea.user_has_access === true && spottingArea.spotting_area_access === 'contributor')

            response.forEach((spottingArea) => {
              if (!this.accessibleSpottingAreaIds.includes(spottingArea.id)) {
                this.accessibleSpottingAreaIds.push(spottingArea.id)
              }
            })
          })
    },
  },
  mixins: [TranslationsMixin],
  components: {
    ThSort,
    Pagination,
  },
}
</script>

<style lang="scss">
.list-view-container {
  position: relative;
  width: 100%;
  min-height: 0;

  .table_row {
    background-color: #F9F9F9
  }

  .analysis-card-footer-actions--items {
    display: flex;
  }

  .list-view-button {
    display: flex;
    justify-content: flex-end;
    margin: -28px 10px 10px auto;
    width: 50%;

    .button {
      background-color: #fff;
      text-transform: lowercase;
      color: var(--primary);

      :hover {
        border: none;
        color: black;
      }

      .button__label {
        text-decoration: underline;
      }
    }
  }
}
</style>
