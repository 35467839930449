import { followConceptSearch, unfollowConceptSearch } from '../api/exploration.js'
import { MUTATION_TYPES } from '../store/modules/user-helpers.js'

export default {
  computed: {
    followedConceptSearchIds () {
      return this.$store.state.user.profile.conceptSearches || []
    },
    conceptSearchIsFollowed () {
      const map = {}

      for (const id of this.followedConceptSearchIds) {
        map[id] = true
      }

      return map
    },
    supportedLanguages () {
      return [
        {
          label: 'nl',
          value: 'nl',
        },
        {
          label: 'en',
          value: 'en'
        },
        {
          label: 'fr',
          value: 'fr'
        },
        {
          label: 'de',
          value: 'de'
        }
      ]
    }
  },
  methods: {
    followConceptSearch (id) {
      if (!this.followedConceptSearchIds.includes(id)) {
        this.$store.commit(MUTATION_TYPES.UPDATE_FOLLOWED_SEARCHES, [...this.followedConceptSearchIds, id])
      }

      followConceptSearch(id)
    },
    unfollowConceptSearch (id) {
      if (this.followedConceptSearchIds.includes(id)) {
        this.$store.commit(MUTATION_TYPES.UPDATE_FOLLOWED_SEARCHES, this.followedConceptSearchIds.filter(x => x !== id))
      }

      unfollowConceptSearch(id)
    },
  },
}
