<template>
  <div>
    <rss-item
      v-if="['rss', 'crunchbase_pro', 'patent', 'pr', 'blog'].includes(item.media_type)"
      :item="item"
      :link="getMetaPropertyValues('link', item)"
      :source-uri="getMetaPropertyValues('link', item)"
      :maxScore="maxScore"
      relevancy-mode="global"
      @link-click="trackLinkClick"
      @show-edit-resource-panel="showEditResourcePanel"
      @show-delete-resource-confirmation-modal="showDeleteResourceConfirmationModal"
      @mark-article-relevant="markArticleRelevant"
      @open-actor-side-panel="openActorSidePanel"
      @add-tag-to-filters="addTagToFilters"
      @like-article="likeArticle(item)"
      @remove-article="showDeleteArticleConfirmationModal"
    />
    <vacancy-item
      v-else-if="['vacancy', 'patent', 'product_service_offering'].includes(item.media_type)"
      :item="item"
      :url="getMetaPropertyValues('url', item)"
      :maxScore="maxScore"
      @link-click="trackLinkClick"
      @open-actor-side-panel="openActorSidePanel"
    />
    <announcement-item
      v-else-if="item.media_type === 'announcement'"
      :item="item"
      :maxScore="maxScore"
      @open-announcement-edit-side-panel="openAnnouncementEditSidePanel"
      @show-announcement-confirmation-modal="showAnnouncementConfirmationModal"
      @open-actor-side-panel="openActorSidePanel"
      @add-tag-to-filters="addTagToFilters"
    />
    <resource-item
      v-else-if="['public_resource', 'private_resource'].includes(item.media_type)"
      :item="item"
      :maxScore="maxScore"
      @show-edit-resource-panel="showEditResourcePanel"
      @show-delete-resource-confirmation-modal="showDeleteResourceConfirmationModal"
      @open-actor-side-panel="openActorSidePanel"
      @add-tag-to-filters="addTagToFilters"
    />
  </div>
</template>

<script>
  import Tag from '../Tag/Tag.vue'
  import RichTag from '../Tag/RichTag.vue'

  import { toDateString } from '../../util/date'
  import { getMetaPropertyValues } from '../../util/get-resource-file-link'

  import { humanize } from '../../constants/properties'
  import { updateFileRelevancy } from '../../api/exploration'

  import RssItem from './Item/RssItem.vue'
  import VacancyItem from './Item/VacancyItem.vue'
  import AnnouncementItem from './Item/AnnouncementItem.vue'
  import ResourceItem from './Item/ResourceItem.vue'
  import ResourceMixin from '../../util/ResourceMixin.js'
  import { trackHeapEvent } from '../../util/analytics.js'
  import { trackArticle } from '../../api/knowledge-base.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'knowledgebase-item',
    props: ['item', 'maxScore', 'conceptSearchId'],
    computed: {
      isOwner () {
        return this.$store.getters.isOwner
      },
      isMember () {
        return this.$store.getters.isMember
      },
      isDeveloper () {
        return this.$store.getters.isDeveloper
      },
      isPortfolioMember () {
        return this.$store.getters.isPortfolioMember
      },
    },
    methods: {
      toDateString,
      humanize,
      getMetaPropertyValues,
      likeArticle (item) {
        this.$emit('like-article', {
          resourceId: item.sql_media_id,
          status: !item.is_liked_by_user
        })
      },
      trackLinkClick () {
        trackArticle(this.item.id, this.conceptSearchId, 'view_external')
        trackHeapEvent('exploration.openArticle')

        this.handleClickResourceResult(this.item)
      },
      openActorSidePanel(actorInfo) {
        this.$emit('openActorSidePanel', actorInfo);
      },
      showEditResourcePanel(item) {
        this.$emit('showEditResourcePanel', item)
      },
      showDeleteResourceConfirmationModal(item) {
        this.$emit('showDeleteResourceConfirmationModal', item)
      },
      addTagToFilters(tagInfo) {
        var filter = {origin: tagInfo.origin}
        const tag = {...filter, ...tagInfo.value}

        this.$emit('addTagToFilters', tag)
      },
      openAnnouncementEditSidePanel(item) {
        this.$emit('openAnnouncementEditSidePanel', item)
      },
      showAnnouncementConfirmationModal(item) {
        this.$emit('showAnnouncementConfirmationModal', item)
      },
      showDeleteArticleConfirmationModal (item) {
        this.$emit('showDeleteArticleConfirmationModal', item)
      },
      markArticleRelevant ({ article, isRelevant, conceptSearchId }) {
        const originalRelevant = article.relevant
        article.relevant = isRelevant

        updateFileRelevancy(this.item.sql_media_id, isRelevant, conceptSearchId)
          .catch(err => {
            article.relevant = originalRelevant
            console.log(err)
          })
      },
    },
    mixins: [ResourceMixin],
    components: {
      ResourceItem,
      AnnouncementItem,
      VacancyItem,
      RssItem,
      RichTag,
      Tag
    }
  })
</script>
