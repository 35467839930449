<template>
  <div class="maintenance-requests-list fixed-heading" :class="{ scrollable: fullScroll }">
    <div class="heading">
      <h1>Maintenance Requests</h1>
      <p class="guide_text">Approve or deny maintenance requests.</p>
    </div>
    <div v-if="loading">
      <loading/>
    </div>
    <div :class="fullScroll ? 'not-scrollable' : 'scrollable'" style="overflow-x: auto;" v-else>
      <table class="table" style="border: 0px; word-break: break-all;">
        <thead>
        <tr>
          <th-sort by="status" :options="filterOptions.status" v-model="filters.status">Status</th-sort>
          <th-sort by="name">Name</th-sort>
          <th-sort by="role">Role</th-sort>
          <th-sort by="email">Email</th-sort>
          <th>Reason</th>
          <th>Actions</th>
          <th-sort by="created_at">Requested at</th-sort>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="request in requests"
          style="cursor: pointer;"
        >
          <td>{{ request.status }}</td>
          <td>{{ request.user_name}}</td>
          <td>{{ normaliseRole(request.role) }}</td>
          <td>{{ request.user_email }}</td>
          <td>{{ request.reason }}</td>
          <td>
            <template v-if="request.status === 'pending'">
              <ds-button
                @click.stop="approveRequest(request, 'approve')"
                icon="plus" size="extra-small" label="approve"
              />
            </template>
          </td>
          <td>{{ timeDifference(request.created_at) }}</td>
        </tr>
        <tr v-if="requests.length === 0">
          <td colspan="7">No maintenance requests found with {{ (filterOptions.status ? 'the status "' + filters.status + '"' : 'the selected status filter') }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <pagination class="simple-pagination--bottom" :paging="paging" @change="setOffset"/>
  </div>
</template>

<script>
  import _throttle from 'lodash/throttle'

  import Checkbox from '../Form/Checkbox.vue'
  import Pagination from '../Pagination/SimplePagination.vue'
  import ThSort from './ThSort.vue'
  import Badge from '../../components/Badge/Badge.vue'

  import { approveMaintenanceRequests, fetchMaintenainceRequests } from '../../api/config'

  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import moment from 'moment'
  import Loading from '../Dashboard/ConceptMap/Loading.vue'

  export default {
    data () {
      return {
        fullScroll: false,
        requests: [],
        paging: {},
        numberOfResults: null,
        loading: false,
        filters: {
          status: 'pending',
          limit: 100,
          offset: null,
          q: null
        },
      }
    },
    methods: {
      normaliseRole (selectedRole) {
        switch (selectedRole) {
          case 'actor':
            return 'Ecosystem Member'
          case 'portfolio_member':
            return 'Portfolio Member'
          case 'owner':
            return 'Owner'
          case 'member':
            return 'Team Member'
          default :
            return ''
        }
      },
      timeDifference (createdAtDate) {
        if (!createdAtDate) {
          return
        }

        const start = moment(createdAtDate, 'YYYY/MM/DD')
        const end = moment()

        const diffInDays = end.diff(start, 'days')

        if (diffInDays === 0) {
          return this.$t('timespan_today')
        }

        if (diffInDays === 1) {
          return this.$t('yesterday')
        }

        return this.$t('approx_days_ago', { total: diffInDays })
      },
      setOffset (offset) {
        this.filters = Object.assign({}, this.filters, { offset })
      },
      approveRequest (maintenanceRequest, action) {
        approveMaintenanceRequests({
          maintenance_request_id: maintenanceRequest.id,
          ecosystem_id: maintenanceRequest.ecosystem_id,
          email: maintenanceRequest.user_email,
          user_id: maintenanceRequest.user_id,
          role: maintenanceRequest.role
        }).then(() => {
          this.fetch()
        })
      },
      fetch () {
        this.loading = true

        fetchMaintenainceRequests(this.filters)
          .then((response) => {
            this.loading = false
            this.requests = response.data
            this.paging = response.paging
            this.numberOfResults = response.total
          })
          .catch(error => {
            this.loading = false
          })
      },
    },
    computed: {
      isMember () {
        return this.$store.getters.isMember
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      userId () {
        return this.$store.getters.userId
      },
      filterOptions () {
        return {
          status: [
            { 'text': 'All options', 'value': null },
            { 'text': 'Pending', 'value': 'pending' },
            { 'text': 'Denied', 'value': 'denied' },
            { 'text': 'Approved', 'value': 'approved' },
            { 'text': 'Expired', 'value': 'expired' },
          ],
        }
      },
      config () {
        return this.$store.state.config
      },
    },
    mounted () {
      this.fetch()
    },
    mixins: [TranslationsMixin],
    beforeUnmount () {
    },
    watch: {
      filters: {
        deep: true,
        handler: _throttle(function () {
          this.fetch()
        }, 300, {
          trailing: true,
        }),
      },
    },
    components: {
      Loading,
      Badge,
      ThSort,
      Checkbox,
      Pagination,
    },
  }
</script>

<style scoped>
  .users__cta-container {
    display: flex;
  }

  .maintenance-requests-list {
    padding-bottom: 49px;
  }

  @-webkit-keyframes expandAnimation {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @-moz-keyframes expandAnimation {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @-o-keyframes expandAnimation {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @keyframes expandAnimation {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(0%);
    }
  }

  @-webkit-keyframes collapseAnimation {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  @-moz-keyframes collapseAnimation {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  @-o-keyframes collapseAnimation {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  @keyframes collapseAnimation {
    0% {
      transform: translateX(0%);
    }
    100% {
      transform: translateX(100%);
    }
  }

  .panelExpanded {
    -webkit-animation: expandAnimation .5s forwards;
    -moz-animation: expandAnimation .5s forwards;
    -o-animation: expandAnimation .5s forwards;
    animation: expandAnimation .5s forwards;
  }

  .panelCollapsed {
    -webkit-animation: collapseAnimation .5s forwards;
    -moz-animation: collapseAnimation .5s forwards;
    -o-animation: collapseAnimation .5s forwards;
    animation: collapseAnimation .5s forwards;
  }
</style>
