<template>
  <div class="dictionary-component">
    <form-group label="Name" class="dictionary-component__grow">
      <ds-input :disabled="!allowRename" v-model="dictionary.value" ref="input" />
    </form-group>
    <dictionary-input
      v-model="dictionary.included_keywords"
      label="Search for topics (max. 15)"
      :maxTags="15"
      class="dictionary-component__grow"
      style="flex-grow: 2"
      :allowFreeText="true"
    />
    <div class="dictionary__buttons" v-if="showDeleteButton">
      <ds-button
        label="Remove lexicon"
        size="small"
        icon="trash"
        @click="removeDictionary()"
        style="margin-left: auto;"
      />
    </div>
  </div>
</template>

<script>
  import DictionaryInput from './DictionaryInput.vue'

  export default {
    props: ['dictionary', 'index', 'showDeleteButton', 'allowRename'],
    data () {
      return {}
    },
    methods: {
      removeDictionary () {
        this.$emit('remove', this.index)
      },
      focus () {
        this.$refs.input.focus()
      },
    },
    components: {
      DictionaryInput
    }
  }
</script>

<style lang="scss">
.dictionary-component {
  display: flex;
  align-items: flex-start;
  margin-bottom: 1rem;

  .form-group {
    margin-bottom: 0px !important;
  }

  .dictionary-component__grow {
    min-width: 0;
    flex-basis: 0;
    flex-grow: 1;
    margin-right: 20px;
  }

  .dictionary__buttons {
    padding-top: 20px;
  }
}
</style>
