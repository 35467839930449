<template>
  <div class="gallery-view">
    <div class="gallery-view__cards-container" v-if="actors.length">
      <div class="column" v-for="actor in actors">
        <gallery-card :actor="actor" @click="openSidePanel(actor)" />
      </div>
      <div class="column column--fix-bottom"></div>
      <div class="column column--fix-bottom"></div>
      <div class="column column--fix-bottom"></div>
      <div class="column column--fix-bottom"></div>
      <div class="column column--fix-bottom"></div>
      <div class="column column--fix-bottom"></div>
      <div class="column column--fix-bottom"></div>
      <div class="column column--fix-bottom"></div>
    </div>
    <div class="explore__empty-state" v-else-if="this.$store.state.actors.listData.loading">
      Loading actors...
    </div>
    <div v-else class="explore__empty-state">
      No actors found!
    </div>
  </div>
</template>

<script lang="ts">
import GalleryCard from './GalleryCard.vue'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
import { defineComponent } from 'vue'
export default defineComponent({
  props: ['actors'],
  methods: {
    openSidePanel (val) {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component:"scores", metaData: val.id})
    }
  },
  components: {
    GalleryCard
  }
})
</script>
