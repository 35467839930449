// import Tweet from 'tweet-to-html';

import querify from '../util/querify'
import { createJSONRequest, cancelIfLate } from './create-request'
import { timeAgo } from '../util/date';

export const fetchMetrics = (filters) => createJSONRequest({
  method: 'GET',
  url: `/api/dashboard?data=metrics&${querify(filters)}`
})

export const fetchFundingDashboard = (filters) => createJSONRequest({
  method: 'GET',
  url: `/api/dashboard?data=overview&${querify(filters)}`
})

export const fetchLandscape = (filters) => createJSONRequest({
  method: 'GET',
  url: `/api/dashboard?data=landscape&${querify(filters)}`
})

export const fetchCountryOverview = () => createJSONRequest({
  method: 'GET',
  url: `/api/dashboard/?data=country-overview`
})

export const fetchCompetitiveEurostat = (filters) => createJSONRequest({
  method: 'GET',
  url: `/api/dashboards/competitive-eurostat?${querify(filters)}`
})

export const saveCompetitiveEurostat = (filters) => createJSONRequest({
  method: 'GET',
  url: `/api/dashboards/competitive-eurostat`,
  data: filters
})

export const fetchNews = cancelIfLate((filters) => {
  return createJSONRequest({
    method: 'GET',
    url: `/api/feed?${querify(filters)}`
  }).then(function (items) {
    return items.map(item => Object.assign({}, item, {
        ago: timeAgo(item.meta.created_at),
        html: '' /*Tweet.parse(item.meta).html
          .replace(/href="http/g, 'target="_blank" href="http')
          .replace(/http:\/\/pbs.twimg.com/g, 'https://pbs.twimg.com')
          .replace(/http:\/\/twemoji.maxcdn.com/g, 'https://twemoji.maxcdn.com'),*/
      })
    );
  })
});

/*export const fetchNewsStats = (filters) => createJSONRequest({
  method: 'GET',
  url: `/api/feed/stats?${querify(filters)}`
})*/

export const fetchTopCompetitors = (filters) => createJSONRequest({
  method: 'GET',
  url: `/api/dashboard?data=top&${querify(filters)}`
})

/*export const fetchGrowers = (filters) => createJSONRequest({
  method: 'GET',
  url: `/api/scores?data=growers&${querify(filters)}`
})*/
