<template>
  <div>
    <h3 class="h2">Send notifications over Slack</h3>
    <div>
      Stay up to date with what's happening in your ecosystem using Slack!
      <br/>
      To get started, head over to the <a href="https://api.slack.com/incoming-webhooks" target="_blank">Slack API integration page</a>, create an incoming webhook and copy-paste the URL of the webhook in the form below.
    </div>
    <br><br>
    <div class="row">
      <form-group class="col-xs-12" label="Slack endpoint">
        <ds-input v-model="slack.endpoint" placeholder="https://hooks.slack.com/services/T00000000/B00000000/XXXXXXXXXXXXXXXXXXXXXXXX" />
      </form-group>
    </div>
    <!--<div class="row" v-if="slack.endpoint && !isNotificationSettingsPanelEnabled()">
      <form-group class="col-md-5" label="Choose for which events we should send a Slack notification for:">
        <checkbox
          v-for="event in possibleEvents"
          :val="event"
          :model-value="slack.events"
          :label="eventLabel(event)"
          multiple
          @update:modelValue="input"
        />
      </form-group>
    </div>-->
  </div>
</template>

<script>

import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'

import ConfigMixin from '../../util/ConfigMixin.js';

import Checkbox from '../Form/Checkbox.vue'

export default {
  name: 'slack-settings',
  mixins: [ConfigMixin],
  computed: {
    slack () {
      return this.$parent.$parent.$parent.form.slack
    },
    possibleEvents () {
      // These event strings need to match the constants defined in PHP (NotificationEvents.php)
      return [
        'actor_raised_funding',
        'actor_employees_changed',
      ]
    },
  },
  methods: {
    hidePanel () {
      this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
    },
    eventLabel (event) {
      switch (event) {
        case 'actor_raised_funding':
          return 'A company has raised funding.'
        case 'actor_employees_changed':
          return 'The employees of a company have changed.'
        default:
          return event
      }
    },
    input (events) {
      this.slack.events = events
    }
  },
  created () {
    if (! this.$parent.$parent.$parent.form.slack) {
      this.$parent.$parent.$parent.form.slack = {
        'endpoint': '',
        'events': []
      }
    }
  },
  components: {
    Checkbox
  }
}
</script>
