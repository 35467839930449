<template>
  <div class="wrapper">
    <header>
      <!--<i :class="iconClass" v-if="iconClass"/>--> <!-- NOTE: for some reason this will trigger fatal errors making it so that the user can't go to other pages when testing this play around with the filters and you'll see an error popping up sooner or later -->
      <div class="title">{{ title }}</div>
    </header>
    <ul>
      <li v-for="filter in filters.slice(0, visibleFiltersAmount)">
        <button
          :class="filter.active ? 'active' : ''"
          :title="filter.title"
          @click="$emit(filter.active ? 'removeFilter' : 'addFilter', filter.value, 'filter-category-panel')"
        >
          {{ filter.label }}
          <small v-if="filter.value.text" class="text-muted">
            <cite :title="filter.label + ': ' + filter.value.text">{{ filter.value.text }}</cite>
          </small>
        </button>
      </li>
      <li v-if="filters.length > visibleFiltersAmount">
        <button class="show-more" @click="showMore">
          <icon name="chevron-down"/>
          <span>{{ $t('knowledge_base_filters_show_more_button') }}</span>
        </button>
      </li>
    </ul>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'FilterCategory',
    props: {
      title: String,
      icon: String,
      filters: Array,
      addFilter: Function,
      removeFilter: Function,
      maxItemsDisplayedAtOnce: {
        type: Number,
        default: 5,
      }
    },
    data () {
      return {
        visibleFiltersAmount: this.maxItemsDisplayedAtOnce,
      }
    },
    computed: {
      iconClass () {
        if (!this.icon) {
          return
        }

        return 'icon fa fa-' + this.icon
      }
    },
    methods: {
      showMore () {
        this.visibleFiltersAmount = this.visibleFiltersAmount + this.maxItemsDisplayedAtOnce
      }
    }
  })
</script>

<style scoped lang="scss">
  $hover-color: var(--primary-community-lightest);
  $border-and-background-color: var(--primary-community-extra-lightest);
  //$hover-color: #fcfcfc;

  .wrapper {
    border-radius: 0.5rem;
    border: 1px solid $border-and-background-color;
    color: var(--primary-community);

    &:not(:last-child) {
      margin-bottom: 1rem;
    }
  }

  header {
    display: flex;
    align-items: center;
    padding: 0.75rem 0.5rem;
    background-color: $border-and-background-color;
    font-size: 1rem;

    .icon {
      margin-right: 0.5rem;
    }
  }

  ul {
    list-style-type: none;

    li:not(:last-child) {
      border-bottom: 1px solid $border-and-background-color;
    }

    button {
      text-align: left;
      display: block;
      width: 100%;
      padding: 0.5rem;
      background: transparent;
      border: 0;
      font-family: inherit;
      font-size: 0.875rem;
      color: inherit;

      text-overflow: ellipsis;
      word-break: break-word;
      white-space: nowrap;
      overflow: hidden;

      &:hover:not(.active) {
        cursor: pointer;
        background-color: $hover-color;
      }

      &.active {
        background-color: $border-and-background-color;
        font-weight: bold;
      }

      &.show-more {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
      }
    }
  }
</style>
