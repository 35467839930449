<template>
  <div class="compare fixed-heading">
    <div class="heading">
      <h1 class="country__title">Country overview</h1>
      <badge :name="numberOfActor + ' Actors'" variant="primary" class="score-overview__actor-couter" />
    </div>

    <div class="scrollable">
      This dashboard is deprecated
<!--      <funding-overview-chart-->
<!--        :data="graphData"-->
<!--        @actor-click="showActorDetail"-->
<!--      />-->
    </div>
  </div>
</template>

<script lang="ts">
import Badge from '../Badge/Badge.vue'

import { actorCount } from '../../api/actors.js'
import { fetchCountryOverview } from '../../api/dashboard.js'
import { defineComponent } from 'vue'

// import FundingOverviewChart from '../FundingOverviewChart/FundingOverviewChart.vue'
export default defineComponent({
  data () {
    return {
      data: [],
      numberOfActor: 0
    }
  },
  computed: {
    graphData () {
      if (this.data.length === 0) {
        return
      }

      function slugify(string) {
        return string ? string.replace(/\s/g, '-') : ''
      }

      return {
        name: 'Country overview',
        children: this.data.map(country => ({
          name: country.country,
          total: country.competitive_threat || 100,
          uniqueId: slugify(country.country),
          children: Object.keys(country.activities || {}).map(activityName => {
            const activity = country.activities[activityName]
            return {
              name: activityName,
              total: activity.average_score || 100,
              uniqueId: slugify([country.country, activityName].join('_')),
              children: activity.companies.map(actor => ({
                id: actor.id,
                uniqueId: slugify([country.country, activityName, actor.id].join('_')),
                name: actor.name,
                logo: actor.logo,
                total: actor.current_score || 100,
              }))
            }
          })
        }))
      }
    },
    totalActors (){
      actorCount(this.filters).then( number => {
        this.numberOfActor = number.count
      })
    },
    filters () {
      return this.$store.getters.baseFilterObject
    }
  },
  methods: {
    fetch () {
      fetchCountryOverview()
        .then(data => {
          if (Array.isArray(data)) {
            this.data = data
          }
        })
    },
    showActorDetail(actor) {
      if (this.$store.getters.canShowActorDetail) {
        this.$router.push(`/actors/${actor.id}`)
      }
    }
  },
  mounted () {
    this.fetch()
    this.totalActors
  },
  watch: {
    filters:{
      deep: true,
      handler () {
        this.totalActors
      }
    }
  },
  components: {
    // FundingOverviewChart,
    Badge
  }
})
</script>
<style lang="scss">
.country__title {
  display:inline-block;
}
</style>
