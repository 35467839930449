// This analytics file interacts with 3rd party analytics
// Only start analytics in production

import ls from './ls.js'
import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from '../constants/analytics-constants.js'

let shouldCreate = window.config && !window.config.debug

export function trackHeapEvent (eventName, data) {
  if (window.heap && window.config && !window.config.debug) {
    if (!canWeTrackUser()) {
      return
    }

    const userProperties = getUserProperties()
    // window.heap.addUserProperties(userProperties); // This needs to be figured out more, it seems we can't track multi-tenancy by doing this, it seems to screw up all of the results.

    data = { ...data, ...userProperties }
    window.heap.track(eventName, data)
  } else {
    if (!canWeTrackUser()) {
      console.log('[ANALYTICS - NOT SENT BECAUSE OF THE USER COOKIE POLICY]', eventName, data)

      return
    }

    console.log('[ANALYTICS - NOT SENT BECAUSE OF NO CONFIGURATION]', eventName, data)
  }
}

export function trackMatomoEvent (eventCategory, eventAction, eventData = null) {
  const _paq = window._paq = window._paq || []

  if (_paq &&
    (Object.values(MATOMO_EVENT_CATEGORIES).includes(eventCategory)) &&
    (Object.values(MATOMO_EVENT_ACTIONS).includes(eventAction))) {
    console.log('[ANALYTICS] ' + eventCategory + ' - ' + eventAction)
    _paq.push(['trackEvent', eventCategory, eventAction, eventData])
  }
}

function getUserProperties () {
  const userProperties = { 'ecosystem': window.config.name }

  if (window.user && window.user.id) {
    if (window.heap && window.heap.identify) {
      window.heap.identify(window.user.id)
    }

    userProperties.ds_user_id = window.user.id
    userProperties.role = window.user.roleOnCurrentTeam
    userProperties.locale = ls('activeLocale')

    if (window.config.ecosystem_member_packages_add_on_enabled && window.user.subscriptionInfo && window.user.subscriptionInfo.packageTitle) {
      userProperties.package = window.user.subscriptionInfo.packageTitle
    }

    const isDatascoutsUser = window.user.email.endsWith('@datascouts.eu') || window.user.email.endsWith('@weconnectdata.com')

    if (isDatascoutsUser) {
      userProperties.datascouts_user_type = 'Team'
    } else {
      userProperties.datascouts_user_type = 'Customer'
    }
  }

  return userProperties
}

export function identifyHeapUserById (id) {
  if (window.heap && shouldCreate) {
    window.heap.identify(id)
  }
}

export function canWeTrackUser () {
  if (ls('userAcceptedCookies')) {
    return ls('userAcceptedCookies')
  }

  return window.user && window.user.accepted_cookies
}

export default class Analytics {
  constructor (gaFunc, ua1, ua2) {
    if (shouldCreate) {
      shouldCreate = false
      this.create()
    }
  }

  _track () {
    if (!canWeTrackUser()) {
      if (window.config && window.config.debug) {
        console.log('[ANALYTICS - NOT SENT')
      }
    }

    // console.log('track event test category')
    // this.trackContent(arguments)
    // window._paq.push(['trackEvent', 'test category', 'test action', 'test name', 'test value'])

    // this.ua1 && ga('send', ...arguments)
    // this.ua2 && ga('b.send', ...arguments)
    // this.ua3 && ga('c.send', ...arguments)
  }

  trackEvent (category, action, label, value) {
    trackHeapEvent(category, { action, label, value })
  }

  trackPage (path, name) {

    // ga('set', 'page', path)
    // ga('b.set', 'page', path)
    // ga('c.set', 'page', path)

    // this._track('pageview', path, name);
  }

  create () {
  }
}

export const AnalyticsMixin = {
  methods: {
    ga () {
      this.Analytics && this.Analytics.trackEvent(...arguments)
    },
  },
  mounted () {
    this.Analytics = new Analytics()
  },
}

/**
 * List of paths that we need to track ourselves to monitor user behaviour
 */
export const userActivityPages = [
  '/dashboards/home',
  '/actors',
  '/dashboards/news',
  '/dashboards/analytics',
  '/announcements',
]
