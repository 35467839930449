<template>
  <div class="actor-info">
    <actor-info-card :actor="actor" @toggle-offices="$emit('toggle-offices')" />
  </div>
</template>

<script>
import ActorInfoCard from './ActorInfoCard.vue'

// Deprecated
export default {
  name: 'actor-info',
  props: ['actor'],
  components: {
    ActorInfoCard
  }
}
</script>
