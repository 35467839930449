<template>
  <with-configuration-tabs>
    <div class="catalogue-page scrollable">
      <card class="card-catalogue__header">
        <div class="catalogue__header">
            <h1>Data Catalogue</h1>
        </div>
      </card>
      <div class="catalogue-page__top__info">
        <div class="catalogue-page__top__info__center">
          <p>The Data Catalogue features a list of data providers, which enriches the profiles of the actors and keeps users informed about the significant changes of their ecosystem. Click the UPGRADE button below to contact us to integrate any of these data sources.</p>
          <ds-button
              tabindex="-1"
              label="UPGRADE"
              variant="primary"
              :href="emailReady"
              class="catalogue-page__upgrade__button"
            />
        </div>
      </div>
      <div class="gallery-view catalogue-page__gallery__view">
        <div class="gallery-view__cards-container">
          <catalogue-card class="catalogue-card__container" :description="cardDescriptions.crunchbase" :website="cardWebsites.crunchbase" :image="cardImages.crunchbase" :isPro="true"></catalogue-card>
          <catalogue-card class="catalogue-card__container" :description="cardDescriptions.semrush" :website="cardWebsites.semrush" :image="cardImages.semrush" :isPro="false"></catalogue-card>
          <catalogue-card class="catalogue-card__container" :description="cardDescriptions.ahrefs" :website="cardWebsites.ahrefs" :image="cardImages.ahrefs" :isPro="true"></catalogue-card>
          <catalogue-card class="catalogue-card__container" :description="cardDescriptions.alexa" :website="cardWebsites.alexa" :image="cardImages.alexa" :isPro="true"></catalogue-card>
          <catalogue-card class="catalogue-card__container" :description="cardDescriptions.securex" :website="cardWebsites.securex" :image="cardImages.securex" :isPro="true"></catalogue-card>
          <catalogue-card class="catalogue-card__container" :description="cardDescriptions.bloombergBusiness" :image="cardImages.bloombergBusiness" :website="cardWebsites.bloombergBusiness" :isPro="false"></catalogue-card>
          <catalogue-card class="catalogue-card__container" :description="cardDescriptions.kbo" :website="cardWebsites.kbo" :image="cardImages.kbo" :isPro="false"></catalogue-card>
          <catalogue-card class="catalogue-card__container" :description="cardDescriptions.eurostat" :website="cardWebsites.eurostat" :image="cardImages.eurostat" :isPro="false"></catalogue-card>
          <catalogue-card class="catalogue-card__container" :description="cardDescriptions.sagacify" :website="cardWebsites.sagacify" :image="cardImages.sagacify" :isPro="false"></catalogue-card>
          <catalogue-card class="catalogue-card__container" :description="cardDescriptions.google" :website="cardWebsites.google" :image="cardImages.google" :isPro="false"></catalogue-card>
          <catalogue-card class="catalogue-card__container" :description="cardDescriptions.pitchbook" :website="cardWebsites.pitchbook" :image="cardImages.pitchbook" :isPro="false"></catalogue-card>
          <catalogue-card class="catalogue-card__container" :description="cardDescriptions.nationalBankBelgium" :image="cardImages.nationalBankBelgium" :website="cardWebsites.nationalBankBelgium" :isPro="true"></catalogue-card>
          <catalogue-card class="catalogue-card__container" :description="cardDescriptions.opencorporates" :website="cardWebsites.opencorporates" :image="cardImages.opencorporates" :isPro="true"></catalogue-card>
        </div>
      </div>
    </div>
  </with-configuration-tabs>
</template>
<script>
import Card from '../../components/Card/Card.vue'
import CatalogueCard from '../../components/Card/CatalogueCard.vue'
import DsInput from '../../components/Form/DsInput.vue'

import CompanyMixin from '../../util/CompanyMixin'
import WithConfigurationTabs from '../../pages/WithConfigurationTabs/WithConfigurationTabs.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'catalogue',
  components: {
    CatalogueCard,
    Card,
    DsInput,
    WithConfigurationTabs,
  },
  data () {
    return {
      user: this.$store.getters.userName,
      ecosystem: this.$store.getters.currentEcosystem.ecosystem,
      cardDescriptions: {
        crunchbase: 'At Crunchbase, your decisions deserve the best data. Crunchbase is the leading destination for millions of users to discover industry trends, investments, and news about global companies—from startups to the Fortune 1000. Crunchbase is about more than just data – it’s about community.',
        startups: 'Startups.be, one-stop-shop for Belgian tech entrepreneurs, providing easy and qualitative access to investors, customers and experts. Since its foundation in 2013, Startups.be has quickly grown to represent more than 1500 startups, and over 120 Belgian organisations working for entrepreneurs.',
        semrush: "SEMrush was founded in 2008 as a small group of SEO and IT specialists united by one mission — to make online competition fair and transparent, with equal opportunities for everyone. After seven years of trailblazing experiments and constant progress, we've grown into the world's leading competitive research service for online marketing.",
        ahrefs: "Ahrefs values products that are easy and meaningful to use, often changing course to deliver to customers what matters most to them, and allow stay true to their motto: 'First, we do it, then we do it well, and finally we do better than others.'",
        alexa: 'At Alexa, we focus exclusively on delivering the richest and most meaningful analytics tools for our customers. At Alexa, we believe strongly in substance over style, which means you can count on getting the exact insights you need without having to navigate through extraneous content to get the information you need right now.',
        securex: 'Securex specialises in human resources, offering companies advice on everything to do with the world of work. Whatever your goal, you can rely on Securex every step of the way.',
        bloombergBusiness: 'Bloomberg delivers business and markets news, data, analysis, and video to the world, featuring stories from Businessweek and Bloomberg News.',
        kbo: 'The Crossroads Bank for Enterprises (BCE) is a database of the FPS Economy which includes all the basic data of companies and their units of establishment. The ECB has two objectives: make the functioning of public services more efficient, and simplify administrative procedures for businesses.',
        eurostat: 'Eurostat is the statistical office of the European Union situated in Luxembourg. Its mission is to provide high quality statistics for Europe and looking for ways to continually improve its products and services.',
        sagacify: 'Sagacify’s mission is to work with its clients to help them unearth valuable insights not just from company data, but from data that lies outside of internal reporting systems. Through this method, companies can make more timely decisions based on accurate analyses, providing them with an increased capacity to take action and drive their business forward.',
        google: 'Since the beginning, our goal has been to develop services that significantly improve the lives of as many people as possible. Not just for some. For everyone.',
        pitchbook: 'PitchBook Desktop started in 2009 as a database that covered private equity. Now, PitchBook tracks every aspect of the public and private equity markets, including venture capital, private equity and M&A.',
        nationalBankBelgium: "The National Bank of Belgium has been Belgium's central bank since 1850. It performs tasks in the general interest at both national and international level.",
        opencorporates: 'OpenCorporates is the largest open database of companies and company data in the world, with an excess of 100 million companies in a similarly large number of jurisdictions. Our primary goal is to make information on companies more usable and more widely available for the public benefit, particularly to tackle the use of companies for criminal or anti-social purposes, for example corruption, money laundering and organised crime.'
      },
      cardWebsites: {
        crunchbase: { name: 'Crunchbase', url: 'https://www.crunchbase.com/' },
        startups: { name: 'Startups.be', url: 'https://startups.be/' },
        semrush: { name: 'Semrush', url: 'https://fr.semrush.com/' },
        ahrefs: { name: 'Ahrefs', url: 'https://ahrefs.com/' },
        alexa: { name: 'Alexa', url: 'https://www.alexa.com/' },
        securex: { name: 'Securex', url: 'http://www.securex.be/' },
        bloombergBusiness: { name: 'Bloomberg', url: 'https://www.bloomberg.com/europe' },
        kbo: { name: 'KBO', url: 'https://economie.fgov.be/fr/themes/entreprises/banque-carrefour-des' },
        eurostat: { name: 'Eurostat', url: 'http://ec.europa.eu/eurostat' },
        sagacify: { name: 'Sagacify', url: 'https://sagacify.com/' },
        google: { name: 'Google', url: 'https://www.google.fr/' },
        pitchbook: { name: 'PitchBook', url: 'https://pitchbook.com/' },
        nationalBankBelgium: { name: 'National Bank of Belgium', url: 'https://www.nbb.be/en' },
        opencorporates: { name: 'Opencorporates', url: 'https://opencorporates.com/' }
      },
      cardImages: {
        crunchbase: 'crunchBase.png',
        startups: 'startups.be.png',
        semrush: 'semrush.png',
        ahrefs: 'ahrefs.png',
        alexa: 'alexa.png',
        securex: 'securex.png',
        bloombergBusiness: 'bloombergBusiness.png',
        kbo: 'kbo.jpg',
        eurostat: 'eurostat.jpg',
        sagacify: 'sagacify.jpg',
        google: 'google.png',
        pitchbook: 'pitchBook.jpg',
        nationalBankBelgium: 'nationalBankBelgium.jpg',
        opencorporates: 'openCorporates.png'
      },
      emailAdress: 'mailto:support@datascouts.eu',
      emailSubject: 'Enricher upgrade request from ',
      emailBody: 'is interested in upgrading enrichers.%0D%0AThank you.',
      emailReady: ''
    }
  },
  methods: {
    buildEmail () {
      const formatEmailSubject = this.emailSubject.split(' ').join('%20') + this.user
      const formatEmailBody = this.emailBody.split(' ').join('%20')
      this.emailReady = this.emailAdress + '?subject=' + formatEmailSubject + '&body=User%20' + this.user + '%20from%20' + this.ecosystem + '%20ecosystem%20' + formatEmailBody
    }
  },
  created () {
    this.buildEmail()
  },
  mixins: [CompanyMixin]
})
</script>
