<template>
  <NewCard title="Profile completeness">
    <div v-if="dataQuality" class="data-quality-cards">
      <EcosystemInfoCard title="Completeness">
        <ul class="completeness-list">
          <li v-for="actor of actorQuality" :key="actor.label">
            <span class="actor-label">{{ actor.label }}</span>
            <span :class="actor.level" class="actor-level">
              {{ actor.level }}
            </span>
          </li>
        </ul>
      </EcosystemInfoCard>

      <EcosystemInfoCard v-for="qualityField of qualityFields" :key="qualityField.label">
        <div class="text-lg">{{ qualityField.amount }}%</div>
        <div class="title">actors have the '{{ qualityField.label }}' property filled in</div>
      </EcosystemInfoCard>
    </div>
  </NewCard>
</template>

<script>
import NewCard from '../../../components/NewCard/NewCard.vue';
import EcosystemInfoCard from './EcosystemInfoCard.vue';
import { humanize } from '../../../constants/properties';

const toPercentage = int => (int * 100).toFixed();

export default {
  computed: {
    actorQuality() {
      const getLevel = amount => {
        if (amount < 0.5) {
          return 'Low';
        } else if (amount < 0.8) {
          return 'Medium'
        } else {
          return 'High';
        }
      };
      const labels = {
        LegalEntity: 'Organizations',
        Person: 'Individuals',
        Community: 'Communities',
      };
      return Object.entries(this.dataQuality.actor_quality)
        .map(entry => ({
          label: labels[entry[0]],
          level: getLevel(entry[1]),
        }))
    },
    qualityFields() {
      return Object.entries(this.dataQuality.highest_quality_fields)
        .filter(entry => entry[1] > 0)
        .map(entry => ({
          label: humanize(entry[0]),
          amount: toPercentage(entry[1]),
        }))
    }
  },
  components: {
    NewCard,
    EcosystemInfoCard
  },
  props: {
    dataQuality: Object,
  },
}
</script>

<style lang="scss" scoped>
.data-quality-cards {
  display: flex;

  > * {
    width: 220px;

    + * {
      margin-left: 20px;
    }
  }
}

.completeness-list {
  list-style-type: none;
  margin: -15px 0 -5px;

  li {
    align-items: center;
    display: flex;
    padding: 8px 5px;

    > * {
      flex: 1;
    }

    + li {
      border-top: 1px solid #d8d8d8;
    }
  }

  .actor-label {
    color: #9b9b9b;
  }

  .actor-level {
    font-weight: 500;
    font-size: 16px;
    text-transform: uppercase;

    &.Low {
      color: red;
    }

    &.Medium {
      color: blue;
    }

    &.High {
      color: green;
    }
  }
}
</style>
