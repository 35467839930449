import { createJSONRequest } from './create-request'
import querify from '../util/querify'

export const fetchGeoId = (geoId) => createJSONRequest({
    url: `/api/geo?geoid=` + geoId,
    method: 'GET'
})

// The 3 functions below fetch geographical data related to Belgium
export const fetchMunicipalities = () => createJSONRequest({
  url: `/json/municipalityGeoJson.json`,
  method: 'GET'
})

export const fetchCoordinates = () => createJSONRequest({
    url: `/json/cityCoordinates.json`,
    method: 'GET'
})

export const fetchNisZipMapping = () => createJSONRequest({
    url: `/json/zipNisMapping.json`,
    method: 'GET'
})
