import { fetchChart } from '../../api/analytics.js'

const state = {
  rankevolution: {
    loading: false,
    data: {
      datasets: {
        data: [],
        startDate: null,
        endDate: null,
        lastRanks: [],
      },
      score_type: ''
    },
    error: null
  },
  landscape: {
    loading: false,
    data: {
      datasets: {
        data: [],
        minGrowth: null,
        maxGrowth: null,
      },
      score_type: ''
    },
    error: null
  },
  ranking: {
    loading: false,
    data: {
      datasets: {
        data: []
      },
      score_type: ''
    },
    error: null
  }
}

export const MUTATION_TYPES = {
  // Rank
  FETCH_RANK_PENDING: 'SCORES/FETCH_RANK_PENDING',
  FETCH_RANK_SUCCESS: 'SCORES/FETCH_RANK_SUCCESS',
  FETCH_RANK_FAILURE: 'SCORES/FETCH_RANK_FAILURE',

  // Rank evolution
  FETCH_RANK_EVOLUTION_PENDING: 'SCORES/FETCH_RANK_EVOLUTION_PENDING',
  FETCH_RANK_EVOLUTION_SUCCESS: 'SCORES/FETCH_RANK_EVOLUTION_SUCCESS',
  FETCH_RANK_EVOLUTION_FAILURE: 'SCORES/FETCH_RANK_EVOLUTION_FAILURE',

  // Landscape
  FETCH_LANDSCAPE_PENDING: 'SCORES/FETCH_LANDSCAPE_PENDING',
  FETCH_LANDSCAPE_SUCCESS: 'SCORES/FETCH_LANDSCAPE_SUCCESS',
  FETCH_LANDSCAPE_FAILURE: 'SCORES/FETCH_LANDSCAPE_FAILURE',
}

export const ACTION_TYPES = {
  FETCH_RANK: 'SCORES/FETCH_RANK',
  FETCH_RANK_EVOLUTION: 'SCORES/FETCH_RANK_EVOLUTION',
  FETCH_LANDSCAPE: 'SCORES/FETCH_LANDSCAPE',
}

export const mutations = {
  // Rank
  [MUTATION_TYPES.FETCH_RANK_PENDING] (state) {
    state.ranking.loading = true
  },
  [MUTATION_TYPES.FETCH_RANK_SUCCESS] (state, data) {
    state.ranking.data.datasets = { 'data': data.datasets }
    state.ranking.error = null
    state.ranking.loading = false
  },
  [MUTATION_TYPES.FETCH_RANK_FAILURE] (state, error) {
    state.ranking.errorId = state.ranking.loading
    state.ranking.error = error || 'Failed to fetch ranking.'
    state.ranking.loading = false
  },

  // Rank evolution
  [MUTATION_TYPES.FETCH_RANK_EVOLUTION_PENDING] (state) {
    state.rankevolution.data = {
      datasets: {
        data: [],
        minGrowth: null,
        maxGrowth: null,
      },
      score_type: ''
    }
    state.rankevolution.loading = true
  },
  [MUTATION_TYPES.FETCH_RANK_EVOLUTION_SUCCESS] (state, data) {
    state.rankevolution.data = data
    state.rankevolution.error = null
    state.rankevolution.loading = false
  },
  [MUTATION_TYPES.FETCH_RANK_EVOLUTION_FAILURE] (state, error) {
    state.rankevolution.data = {}
    state.rankevolution.errorId = state.rankevolution.loading
    state.rankevolution.error = error || 'Failed to fetch rank evolution data.'
    state.rankevolution.loading = false
  },

  // Landscape
  [MUTATION_TYPES.FETCH_LANDSCAPE_PENDING] (state) {
    state.landscape.data = {
      datasets: {
        data: [],
        minGrowth: null,
        maxGrowth: null,
      },
      score_type: ''
    }
    state.landscape.loading = true
  },
  [MUTATION_TYPES.FETCH_LANDSCAPE_SUCCESS] (state, data) {
    state.landscape.data = data
    state.landscape.error = null
    state.landscape.loading = false
  },
  [MUTATION_TYPES.FETCH_LANDSCAPE_FAILURE] (state, error) {
    state.landscape.data = {
      datasets: {
        data: [],
        minGrowth: null,
        maxGrowth: null,
      },
      score_type: ''
    }
    state.landscape.errorId = state.landscape.loading
    state.landscape.error = error || 'Failed to fetch landscape.'
    state.landscape.loading = false
  }
}

export const actions = {
  // Rank
  [ACTION_TYPES.FETCH_RANK] ({ commit }, filters) {
    commit(MUTATION_TYPES.FETCH_RANK_PENDING)

    fetchChart('ranking', filters)
      .then(ranking => {
        commit(MUTATION_TYPES.FETCH_RANK_SUCCESS, ranking)
      })
      .catch(() => {
        commit(MUTATION_TYPES.FETCH_RANK_FAILURE, 'An error occurred while fetching the rank data.')
      })
  },

  // Rank evolution
  [ACTION_TYPES.FETCH_RANK_EVOLUTION] ({ commit }, filters) {
    commit(MUTATION_TYPES.FETCH_RANK_EVOLUTION_PENDING)

    fetchChart('rankevolution', filters)
      .then(data => {
        commit(MUTATION_TYPES.FETCH_RANK_EVOLUTION_SUCCESS, data)
      })
      .catch(() => {
        commit(MUTATION_TYPES.FETCH_RANK_EVOLUTION_FAILURE, 'An error occurred while fetching the rank evolution data.')
      })
  },

  // Landscape
  [ACTION_TYPES.FETCH_LANDSCAPE] ({ commit }, filters) {
    commit(MUTATION_TYPES.FETCH_LANDSCAPE_PENDING)

    fetchChart('landscape', filters)
      .then(landscape => {
        commit(MUTATION_TYPES.FETCH_LANDSCAPE_SUCCESS, landscape)
      })
      .catch(() => {
        commit(MUTATION_TYPES.FETCH_LANDSCAPE_FAILURE, 'An error occurred while fetching the landscape.')
      })
  },
}

export const getters = {}

export default {
  state,
  mutations,
  actions,
  getters,
}
