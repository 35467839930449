<template>
  <div>
    <div>
      <div class="sustainability__section--info" v-if="(keywords &&keywords.length)">
        <h1>Keywords found on the website</h1>
        <br>
        <table v-if="keywords && keywords.length" class="table table-borderless mb5 m-t-lg">
          <thead>
          <th style="width: 100px">Keyword</th>
          <th style="width: 100px">Times found</th>
          </thead>

          <tbody>
          <tr v-for="keyword in keywords">
            <td>
              {{ keyword.keyword }}
            </td>
            <td>
              {{ keyword.count }}
            </td>
          </tr>
          </tbody>
        </table>
        <div v-else>
          No interesting keywords found on the actors website
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ActorSustainability',
    data () {
      return {}
    },
    props: {
      actor: {
        type: Object,
        default: [],
      },
    },
    computed: {
      keywords () {
        return this.actor.scraped_keywords
      },
    },
    methods: {},
    mounted () {
    },
    beforeUnmount () {
    },
    watch: {
      actor () {
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables.scss";

  .page__header {
    display: flex;
    width: fit-content;
    flex-direction: column;
    text-align: center;
    margin-bottom: 2rem;

    h1 {
      margin-top: 40px;
    }
  }

  .sustainability__section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .sustainability__section--info {
    width: 49%;

    h1 {
      margin-top: 25px;
      margin-bottom: 5px;
    }
  }

  .category-stats__container {
    border: 1px solid $color-borders;
    display: flex;
    width: 100%;
    font-size: 20px;
    margin-bottom: 2rem;

    justify-content: space-evenly;
  }

  .CarbonTrust {
    height: 100px;
  }

  .BCorporation {
    height: 100px;
  }

  .TheClimateGroup {
    height: 100px;
  }

  .category-stats__child {
    width: 20%;
    float: left;
    text-align: center;
    margin: 2rem 0;
    display: flex;
    flex-direction: column;

    .category-stats-title {
      text-transform: uppercase;
      font-family: $font-stack-secondary;
      font-size: 12px;
      margin-bottom: 10px;
    }

    .category-stats-label {
      text-transform: uppercase;
      font-family: $font-stack-secondary;
      font-size: 30px;
      margin-bottom: 15px;
      font-weight: bold;
    }

    .category-stats-count__container {
      margin: 0 auto;

      .category-stats-count__value {
        font-size: 55px;
      }

      .category-stats-count__diff {
        color: red;
        font-family: $font-stack-secondary;
        font-size: 12px;
        text-align: right;

        &.is-positive {
          color: green;
        }
      }
    }
  }
</style>
