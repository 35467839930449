<template>
  <div class="authentication-modal authentication-modal--small">
    <modal :id="modalId" ref="modal" title="Warning" :enable-mask="false" :is-closeable="isCloseable" @close="close">
      <template v-slot:body>
        <div>
          <form class="form" @submit.prevent="remove" v-if="! sent">
            <fieldset>
              <div class="form-group">
                <label class="label" for="password-reset-email" style="font-weight: 400;">Are you sure? Deleting the
                  <span style="font-weight: 800;">"{{ modalContext.portfolio_name }}"</span>
                  portfolio will also remove all
                  <strong style="font-weight: 800;">{{ modalContext.actor_count }} assigned actors</strong> from your ecosystem. This action is irreversible.</label>
              </div>
            </fieldset>
            <fieldset class="action-buttons">
              <ds-button type="submit" class="red-variant" variant="primary" icon="trash" label="Delete" :disabled="sending"/>
            </fieldset>
          </form>
          <div v-if="sent">
            <form class="form" @submit.prevent="goToPortfolioOverview">
              <div>
                We have started removing the portfolio and the actors within that portfolio, it might take some time for all actors to be removed from the platform.
              </div>
            </form>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
  import Modal from './Modal.vue'
  import MODAL_IDS from '../../constants/modal-ids'

  import { removePortfolioActors } from '../../api/portfolios.js'
  import { trackMatomoEvent } from '../../util/analytics'
  import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from '../../constants/analytics-constants'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import FormGroup from '../Form/FormGroup.vue'

  export default {
    name: 'portfolio-actors-remove-modal',
    data () {
      return {
        modalId: MODAL_IDS.PORTFOLIO_ACTORS_REMOVE,
        sent: false,
        sending: false,
      }
    },
    props: {
      isCloseable: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      remove () {
        this.sending = true
        const portfolioId = this.$route.params.id

        trackMatomoEvent(MATOMO_EVENT_CATEGORIES.INTERACTIONS, MATOMO_EVENT_ACTIONS.REMOVE_PORTFOLIO_BUTTON, `{userId: ${this.$store.getters.userId},portfolioId: ${portfolioId}}`)

        removePortfolioActors(portfolioId).then(response => {
          this.sent = true
        }).catch()
      },
      goToPortfolioOverview () {
        window.location.href = '/portfolios'
      },
      close () {
        if (this.sent) {
          // The request to have the portfolio and actors removed has been sent, so lets go back to the overview of portfolios
          this.goToPortfolioOverview()
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        } else {
          this.$emit('close')
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        }
      },
    },
    computed: {
      modalContext () {
        return this.$store.state.ui.modalContext
      },
    },
    components: {
      FormGroup,
      Modal,
    },
  }
</script>

<style scoped lang="scss">
  .red-variant {
    background-color: red;
    border-radius: 4px;

    &:hover {
      background-color: lightcoral;
    }
  }
</style>
