<template>
  <EcosystemInfoCard title="Refill data pack">
    <p v-if="autoRefill" class="text-muted">
      Your actor credits will be refilled automatically. You will receive an invoice upon refilling
    </p>
    <p v-if="!autoRefill && moreThan20Remaining" class="text-muted">
      Your actor credits will not be refilled automatically and scores will not be updated. Please contact your Customer
      Success Manager to refill your data pack.
    </p>
    <p v-if="!autoRefill && !moreThan20Remaining && remainingCredits" class="text-warning">
      <icon name="warning"></icon>
      Please contact your Customer Success Manager to refill your data pack.
    </p>
    <p v-if="!autoRefill && !moreThan20Remaining && !remainingCredits" class="text-warning">
      <icon name="warning"></icon>
      Please contact your Customer Success Manager to refill your data pack.
    </p>

    <DsButton
        class="refill-contact-btn"
        :class="contactButtonClasses"
        v-if="!autoRefill"
        block
        size="small"
        @click="openContactModal"
        label="Contact"
        variant="outline"
    />

    <div class="refill-toggle-label">Automatic refill</div>

    <div class="toggle-button-container">
      <span>Off</span>
      <ToggleButton
          @update:modelValue="$emit('toggleAutoRefill', autoRefill)"
          :enabled="autoRefill"
          color="success"
          :disabled="!isOwner"
      >
      </ToggleButton>
      <span>On</span>
    </div>
  </EcosystemInfoCard>
</template>

<script>
import DsButton from '../../DsButton/DsButton.vue'
import ToggleButton from '../../Form/ToggleButton.vue'
import EcosystemInfoCard from '../Heartbeat/EcosystemInfoCard.vue'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui'
import MODAL_IDS from '../../../constants/modal-ids'

export default {
  props: {
    autoRefill: Boolean,
    remainingCredits: Number,
    totalCredits: Number,
    toggleAutoRefill: Function,
  },
  computed: {
    isOwner () {
      return this.$store.getters.isOwner
    },
    isMember () {
      return this.$store.getters.isMember
    },
    contactButtonClasses () {
      if (this.moreThan20Remaining) {
        return ''
      } else if (this.remainingCredits > 0) {
        return 'less-than-20'
      } else {
        return 'zero-credits'
      }
    },
    moreThan20Remaining () {
      return (this.remainingCredits / this.totalCredits) > 0.2
    },
  },
  methods: {
    openContactModal () {
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
        sendTo: 'datascouts-support',
        placeholder: 'I am out of actor credits. Could you contact me to refill my data pack?',
      })
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CONTACT_SUPPORT)
    },
  },
  components: {
    DsButton,
    EcosystemInfoCard,
    ToggleButton,
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../scss/variables';

.refill-contact-btn {
  background: white !important;
  border-width: 2px !important;
  margin-bottom: 20px;
  margin-top: -10px;

  &.less-than-20 {
    border-color: $color-error !important;
    color: $color-error !important;

    &:hover {
      background-color: $color-error !important;
      color: white !important;
    }
  }

  &.zero-credits {
    background-color: $color-error !important;
    border-color: $color-error !important;
    color: white !important;
  }
}

.refill-toggle-label {
  color: #9b9b9b;
  font-size: 12px;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.toggle-button-container {
  align-items: flex-start;
  color: #9b9b9b;
  display: flex;
  font-size: 12px;
  text-transform: uppercase;

  > * + * {
    margin-left: 8px;
  }
}

p {
  font-size: 12px;
}

.text-warning {
  color: $color-error;
  padding-left: 20px;
  position: relative;

  .svg-icon {
    position: absolute;
    left: 0;
    top: 0;
  }
}
</style>
