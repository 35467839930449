<template>
  <div :class="{'package-item__container': true, 'col-xs-12': true, 'col-md-6': totalItems <= 2, 'col-md-4': totalItems === 3, 'col-md-3': totalItems > 3}">
    <div class="package-picker__package-item">
      <div class="package-picker__package-title" v-if="title">
        <h3 class="h3">
          {{ title }}
        </h3>

        <div class="popular-circle">POPULAR</div>
      </div>

      <div v-if="marketingTitle" class="package-picker__marketing-title"
           :class="{'package-picker__marketing-subtitle': marketingSubTitle}">
        <div>
          {{ marketingTitle }}
        </div>
        <div>
          {{ marketingSubTitle }}
        </div>
      </div>
      <div class="package-picker__package-price" v-if="prices">
        <template v-if="prices[0].price === 0" class="free-package">
          <span class="price">
            FREE
          </span>
        </template>
        <template v-else><span class="price">€{{ monthlyPrice(prices) }}</span> <span
          class="per-month">/ {{ $t('month').toUpperCase() }}</span>
        </template>

      </div>
      <div class="package-picker__package-sub-title" v-if="subTitle">
        {{ subTitle }}
      </div>

      <div class="package-picker__package-description" v-if="featuresInfo">
        <ul>
          <li class="package-picker__package-feature-list-item"
              v-for="(feature, featureIndex) in featuresInfo"
              :key="'package-option-feature_' + featureIndex">
            <div class="package-picker__package-feature-item"
                 :class="{'package-picker__package-feature-disabled' : !feature.enabled}">
              <div>
                <icon :class="feature.enabled && 'enabled'"
                      :name="feature.enabled ? 'check' : 'line'"></icon>
              </div>
              <div style="margin-top: 0.1em; font-weight: 500;">&nbsp;{{
                  feature.name
                }}
              </div>
            </div>
          </li>
        </ul>
      </div>

      <slot name="actions"/>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'package-picker-item',
    props: ['title', 'subTitle', 'prices', 'totalItems', 'featuresInfo', 'marketingTitle', 'marketingSubTitle'],
    data () {
      return {}
    },
    methods: {
      monthlyPrice (prices) {
        let monthlyPrice = prices[0].price

        prices.forEach(price => {
          if (price.billing_cycle === 'month') {
            monthlyPrice = price.price
          }
        })

        return monthlyPrice
      },
    },
  }
</script>

<style scoped lang="scss">
@import "../../../../scss/variables";

.package-item__container {
  @media screen and (max-width: $screen-sm) {
    max-width: 100%;
    flex-basis: 100%;
  }

  margin-bottom: 20px;

  .package-picker__package-title {
    text-align: center;
    font-family: $font-stack-secondary;
    background: var(--primary);
    color: white;
    padding: 1rem;
    min-height: 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    margin-bottom: 50px;

    .popular-circle {
      display: none;
      text-align: center;
      font-family: $font-stack-primary;
      font-weight: bold;
      position: absolute;
      top: -15px;
      right: -15px;
      transform: rotate(35deg);
      border-radius: 50%;
      color: white;
      background: var(--primary);
      width: 70px;
      height: 70px;
      line-height: 70px;
      font-size: 12px;
      padding-left: 4px;
      letter-spacing: 1px;
    }
  }

  &:first-child {
    .package-picker__package-title {
      background: var(--primary);
    }
  }

  &:nth-child(2) {
    .package-picker__package-title {
      background: linear-gradient(90deg, var(--primary) 0%, var(--primary-lighter) 100%);
    }
  }

  &:nth-child(3) {
    .package-picker__package-title {
      background: linear-gradient(90deg, var(--primary) 0%, var(--primary-lighter) 100%);
    }

    .popular-circle {
      display: inline-block;
    }
  }

  &:last-child {
    .package-picker__package-title {
      background: var(--primary-lighter);
    }
  }

  .package-picker__marketing-title {
    background: white;
    border: 2px solid var(--primary-lighter);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 80%;
    font-weight: 500;
    height: 60px;
    width: 90%;
    color: var(--primary-lighter);
    margin: 0 auto 30px auto;
    text-align: center;

    &.package-picker__marketing-subtitle {
      border: none;
      background: var(--primary);
      color: white;
      text-transform: uppercase;

      div:nth-child(2) {
        font-family: $font-stack-secondary;
        margin-top: 5px;
        font-weight: 100;
      }
    }
  }
}

.package-picker__package-item {
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  border: 1px solid $color-borders;
  height: 100%;
}

.package-picker__package-sub-title {
  text-align: center;
  min-height: 30px;
  letter-spacing: 2px;
  font-weight: 100;
  font-family: $font-stack-secondary;
}

.package-picker__package-description {
  display: flex;
  justify-content: space-evenly;
  margin: 10px 10px 0.5em 10px;
}

.package-picker__package-price {
  text-align: center;
  font-weight: 500;
  display: flex;
  margin: 0 auto 15px auto;
  padding: 0 5px;
  letter-spacing: 2px;

  &.free-package {
    display: flex;
    margin: 0 auto;
  }

  .price {
    font-size: 35px;
    line-height: 25px;
    letter-spacing: 3px;
  }

  .per-month {
    font-weight: 100;
    font-family: $font-stack-secondary;
    align-self: flex-end;
    line-height: 20px;
    font-size: 20px;
  }
}

.package-picker__package-disclaimer-star {
  position: relative;
  top: -0.5em;
  font-size: 80%
}

.package-picker__package-disclaimer {
  font-size: 11px;
  opacity: 50%;
  margin-top: 0.4em;
}

.package-picker__package-feature-disabled {
  opacity: 30%;
}

.package-picker__package-feature-list-item {
  list-style-type: none;
}

.package-picker__package-feature-item {
  display: flex;
  flex-direction: row;

  :deep(.svg-icon.enabled) {
    path {
      fill: var(--primary);
    }
  }
}

</style>
