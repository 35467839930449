import { createJSONRequest, createResource } from './create-request'
import querify from '../util/querify.js'

export const ProofPoints = createResource('/api/proof-points')

export const fetchProofPointsForSpottingArea = (spottingAreaId, filters = {}) => createJSONRequest({
  url: `/api/proof-points?spotting_area_id=${spottingAreaId}&${querify(filters)}`,
  method: 'GET',
})

export const deleteProofPoint = (proofPointId) => createJSONRequest({
  url: `/api/proof-points/${proofPointId}`,
  method: 'DELETE',
})
