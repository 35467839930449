<template>
  <div class="authentication-modal authentication-modal--small">
    <modal :id="modalId" ref="modal" title="Terms and conditions" :enable-mask="false" :is-closeable="isCloseable">
      <template v-slot:body>
        <div>
          <form class="form">
            <fieldset>
              <p>
                Please review our updated <a :href="termsLink" target="_blank">Terms of Use</a> to avoid being blocked from using DataScouts in future.
              </p>
            </fieldset>
            <fieldset class="action-buttons">
              <ds-button variant="secondary" icon="check" label="Accept" @click="accept"></ds-button>
              <ds-button variant="secondary" icon="user" label="My profile" @click="goToUserProfile"></ds-button>
            </fieldset>
            <fieldset class="form" v-if="error && error.length > 0">
              <p>
                Oops, looks like something went wrong, <a href="mailto:support@datascouts.eu">contact us!</a>
              </p>
            </fieldset>
          </form>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from './Modal.vue'
import MODAL_IDS from '../../constants/modal-ids'

import { acceptTerms } from '../../api/user'

export default {
  name: 'gdpr-modal',
  props: {
    isCloseable: {
      type: Boolean,
      default: true
    },
  },
  data () {
    return {
      modalId: 'GDPR',
      error: '',
    }
  },
  computed: {
    termsLink() {
      if (! window.applicationDomain) {
        // This should never happen
        return 'https://kyc.datascouts.eu/terms'
      }

      return window.location.protocol + '//' + 'kyc' + window.applicationDomain + '/terms'
    }
  },
  methods: {
    accept () {
      acceptTerms()
        .then(response => {
          if (response.accepted_terms == true) {
            window.location.href = '/'
          } else {
            this.error = "Oops, looks like something went wrong..."
          }
        })
        .catch(err => {
          this.error = "Oops, looks like something went wrong..."
        })
    },
    goToUserProfile () {
      window.location.href = '/profile/update'
    }
  },
  components: {
      Modal
    }
  }
</script>
