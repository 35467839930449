<template>
  <div>
    <!-- This .knowledge-base shouldn't be required, but sadly it is for now -->
    <div class="knowledge-base" v-if="!fetchingContent">
      <AnnouncementOrResourceCard
        v-for="resource in results.slice(0, 100)"
        :key="'announcement-or-resource-card-' + resource.id"
        :data="resource"
        @addTag="addTagToFilters"
        @open-actor-side-panel="openActorSidePanel"
        @like-article="likeArticle(resource)"
        :activeConceptSearchId="conceptSearchId"
        @mark-article-relevant="markArticleRelevant"
      />
    </div>
  </div>
</template>

<script>
  import Avatar from '../../Avatar/Avatar.vue'
  import ResourceMixin from '../../../util/ResourceMixin'
  import DictionaryMixin from '../../../util/DictionaryMixin'
  import DictionaryTag from '../../Tag/DictionaryTag.vue'
  import RssItem from '../../KnowledgeBase/Item/RssItem.vue'

  import { trackHeapEvent } from '../../../util/analytics'
  import { getMetaPropertyValues } from '../../../util/get-resource-file-link.js'
  import AnnouncementOrResourceCard from '../../Simplified/AnnouncementOrResourceCard.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    mixins: [ResourceMixin, DictionaryMixin],
    components: { AnnouncementOrResourceCard, RssItem, DictionaryTag, Avatar },
    props: {
      fetchingContent: Boolean,
      userCanEdit: Boolean,
      results: Array,
      openLinkDirectly: Boolean,
      conceptSearchId: {
        type: Number,
        required: false,
      },
      relevancyMode: {
        type: String,
        default: 'disabled',
        validator: value => ['disabled', 'emit+global', 'global'].includes(value),
      },
    },
    computed: {
      isOwner () {
        return this.$store.getters.isOwner
      },
      isTeamMember () {
        return this.$store.getters.isTeamMember
      },
      articleRelevancyMode () {
        // Return the passed relevancy mode if possible, but take into account permissions of the user
        if (!this.userCanEdit) {
          return 'disabled'
        }

        if (!this.relevancyMode || this.relevancyMode === 'disabled') {
          return 'emit+global'
        }

        return this.relevancyMode
      },
    },
    methods: {
      removeArticle (resource) {
        this.$emit('remove-article', {
          resource: resource,
        })
      },
      likeArticle (resource) {
        this.$emit('like-article', {
          resourceId: resource.sql_media_id,
          status: !resource.is_liked_by_user,
        })
      },
      getMetaPropertyValues,
      addTagToFilters (tag) {
        trackHeapEvent('exploration.clickTag')

        this.$emit('add-tag-to-filters', tag)
      },
      displayedDictionaries (content) {
        if (content.similar_dictionaries) {
          return [...content.similar_dictionaries].sort((a, b) => a - b)
        }

        return []
      },
      markArticleRelevant ({ article, isRelevant, conceptSearchId }) {
        trackHeapEvent('exploration.clickRelevantContent')

        this.$emit('mark-relevant', { article, isRelevant, conceptSearchId })
      },
      openActorSidePanel (actor) {
        trackHeapEvent('exploration.clickActorInContent')

        this.$router.push(`/actors/${actor.actor_id}`)
      },
      openArticle (event, article) {
        event.preventDefault()

        if ((!this.isOwner && !this.isTeamMember) && !this.openLinkDirectly) {
          trackHeapEvent('exploration.openArticle')
          this.$emit('open-article', article)
        } else {
          trackHeapEvent('exploration.openArticleExternally')
          this.handleClickResourceResult(article)
        }
      },
    },
  })
</script>

<style lang="scss" scoped>
  .global-search__result__actors__actor {
    display: inline-block;
    margin-right: 20px;
    color: var(--primary-lighter) !important;
    margin-bottom: 5px;
  }

  .global-search__result__title {
    display: flex;
    align-items: center;
  }

  .global-search__result__subtitle {
    margin-bottom: 2px;
  }

  .global-search__result__description {
    margin-bottom: 4px;
  }

  .relevant-button {
    cursor: pointer;
    margin-left: auto;
  }

  .global-search__result__info {
    width: 100%;
  }

  .concept-map__content__subtitle {
    display: flex;
    align-items: center;
    margin-top: -5px;
  }

  .concept-map__loading {
    margin-top: 20px;
    text-align: center;

    .svg-icon {
      position: relative;
      top: 5px;
    }
  }
</style>

<style lang="scss">
  .concept-map__related-dictionaries-block {
    margin-left: auto;
    text-align: right;
    text-transform: none;

    .dictionary-tag {
      margin-right: 0.25rem;
      margin-bottom: 0.25rem;
    }
  }
</style>
