<template>
  <MiniCard
    class="actor-mini-card"
    :title="actor.name"
    :content="actor.activities_description || actor.short_description || actor.description_stripped_tags || actor.description"
    :visual="actor.featured_image_url || actor.website_screenshot || getDefaultFallbackImageUrl()"
    :color="cardColor"
    @click="click"
  >
    <template v-slot:avatar>
      <Avatar variant="border" :src="companyLogoUrl" :alt="actor.name"/>
    </template>
  </MiniCard>
</template>

<script lang="ts">
  import MiniCard from '../MiniCard/MiniCard.vue'
  import Avatar from '../Avatar/Avatar.vue'

  import FiltersMixin from '../../util/FiltersMixin.js'
  import UiMixin from '../../util/UiMixin.js'
  import {trackHeapEvent} from '../../util/analytics.js'

  import {actorLogo} from '../../util/actor.js'
  import {MUTATION_TYPES as UI_MUTATION_TYPES} from '../../store/modules/ui.js'
  import {getDefaultFallbackImageUrl} from '../../util/helpers.js'
  import {defineComponent} from 'vue'

  export default defineComponent({
    name: 'ActorMiniCard',
    components: {MiniCard, Avatar},
    props: {
      actor: Object,
      color: String,
    },
    computed: {
      companyLogoUrl() {
        return actorLogo(this.actor)
      },
      legendValue() {
        if (this.$store.state.filters.legendProperty && this.actor[this.$store.state.filters.legendProperty]) {
          const value = this.actor[this.$store.state.filters.legendProperty]

          // If the legend value is an array, then use the first item to use as the legend value
          if (value && typeof value !== 'string' && value.length > 0) {
            return value[0]
          }

          return value
        }

        if (this.actor && this.actor.legend) {
          return this.actor.legend
        }
      },
      legendProperty() {
        return this.$store.state.config.legendProperty
      },
      legendMapping() {
        return this.$store.state.config.legendMapping
      },
      hexColours() {
        return this.$store.getters.hexColours
      },
      cardColor() {
        return this.hexColours[this.legendMapping[this.legendProperty][this.legendValue]] || '#bbb'
      },
    },
    methods: {
      getDefaultFallbackImageUrl,
      click() {
        trackHeapEvent('homepage.clickLatestUpdatedActor')

        if (this.ui.isMobile) {
          this.$router.push(`/actors/${this.actor.id}`)
        } else {
          // this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_FOR_PREVIEW, this.actor);
          this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component: 'scores', metaData: this.actor.id})
        }
      },
    },
    mixins: [FiltersMixin, UiMixin],
  })
</script>

<style lang="scss">
  .actor-mini-card {
    .mini-card__avatar {
      align-self: flex-start;
    }

    .mini-card__content {
      font-size: 0.875rem;
      color: inherit;
    }
  }
</style>
