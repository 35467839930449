<template>
  <div class="result-data" @click="clickOnResult">
    <div class="result-data__icon-container">
      <icon :name="iconName" size="large"/>
    </div>
    <div class="result-data__container" v-if="result.result">
      <div class="result-data__info">
        <div class="result-data__title">
          <p v-html="getHightlightHtmlFor('name.rawText')" v-if="getHightlightHtmlFor('name.rawText')"/>
          <p v-html="getHightlightHtmlFor('title.text')" v-else-if="getHightlightHtmlFor('title.text')"/>
          <p v-html="getHightlightHtmlFor('name')" v-else-if="getHightlightHtmlFor('name')"/>
          <p v-else>{{ result.result.title ?? result.result.name }}</p>
        </div>
        <div class="result-data__sub-info" v-if="resultItems.length">
          <template v-for="(resultItem, index) in resultItems" :key="'result-item' + index">
            <template v-if="resultItem === RESULT_DATA_TYPES.CITY_AND_COUNTRY && result.result.address && (result.result.address.city || result.result.address.country)">
              <p
                v-if="getHightlightHtmlFor('address.city') || getHightlightHtmlFor('address.country')"
                v-html="getHighlightHtmlForCityOrCountry">
              </p>
              <p v-else>{{ result.result.address.city }}<span
                v-if="result.result.address.city && result.result.address.country">, </span>{{ result.result.address.country }}</p>
            </template>
            <template v-if="resultItem === RESULT_DATA_TYPES.CATEGORY && result.result.category">
              <p v-html="getHightlightHtmlFor('category_labels.rawText')" v-if="getHightlightHtmlFor('category_labels.rawText')"/>
              <p v-else :title="getLabelForTaxonomyValue('category', result.result.category)">{{ getLabelForTaxonomyValue('category', result.result.category) }}</p>
            </template>
            <template v-if="resultItem === RESULT_DATA_TYPES.CONNECTIONS && result.result.connections">
              <p>{{ exactTranslationForConnections }}</p>
            </template>
            <template v-if="resultItem === RESULT_DATA_TYPES.CREATED_AT && result.result.created_at">
              <p>{{ new Date(result.result.created_at).toLocaleDateString() }}</p>
            </template>
            <template v-if="resultItem === RESULT_DATA_TYPES.DATE && result.result.date">
              <p>{{ new Date(result.result.date).toLocaleDateString() }}</p>
            </template>
            <template v-if="resultItem === RESULT_DATA_TYPES.URL && result.result.url">
              <p>{{ result.result.url }}</p>
            </template>
            <template v-if="resultItem === RESULT_DATA_TYPES.SPOTTING_AREA && result.result.spotting_area">
              <p>{{ result.result.spotting_area }}</p>
            </template>
            <template v-if="resultItem === RESULT_DATA_TYPES.COMMENTS && result.result.comments">
              <p>{{ exactTranslationForComments }}</p>
            </template>
            <template v-if="resultItem === RESULT_DATA_TYPES.CREATED_BY && result.result.created_by && result.result.created_by.name">
              <p>{{ result.result.created_by.name }}</p>
            </template>
            <template v-if="resultItem === RESULT_DATA_TYPES.CREATED_BY_ACTOR && result.result.actor && result.result.actor.title">
              <p>{{ result.result.actor.title }}</p>
            </template>
            <template v-if="resultItem === RESULT_DATA_TYPES.DESCRIPTION && result.result.description">
              <p :title="result.result.description">{{ result.result.description }}</p>
            </template>
            <template v-if="resultItem === RESULT_DATA_TYPES.LINKED_ACTOR && firstIsProductOfRelation">
              <p>{{ $t('global_search_is_product_of', { actor: firstIsProductOfRelation }) }}</p>
            </template>
            <template
              v-if="resultItem === RESULT_DATA_TYPES.COMMUNITY && this.$store.getters.hasAccessToAnnouncementsAndEventsForCommunities && result.result.linked_community_labels && result.result.linked_community_labels.length">
              <p v-html="getHightlightHtmlFor('linked_community_labels.rawText')" v-if="getHightlightHtmlFor('linked_community_labels.rawText')"/>
              <p v-else :title="result.result.linked_community_labels[0]">{{ result.result.linked_community_labels[0] }}</p>
            </template>
            <template v-if="resultItem === RESULT_DATA_TYPES.LIKES && result.result.likes">
              <p>{{ exactTranslationForLikes }}</p>
            </template>
            <template v-if="resultItem === RESULT_DATA_TYPES.SINGLE_WORD_MATCH && extraMatches.singleWordMatchHTML">
              <p v-html="extraMatches.singleWordMatchHTML"/>
            </template>
          </template>
        </div>
        <div class="result-data__matching-text" v-if="extraMatches.extraLineHTML">
          <p v-html="extraMatches.extraLineHTML"/>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
  import { GLOBAL_SEARCH_RESULT_TYPES } from '../../../util/GlobalSearchResultTypes.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui.js'
  import MODAL_IDS from '../../../constants/modal-ids.js'
  import { getAnalyticsKeyword } from '../../../api/general-search'
  import TranslationsMixin from '../../../util/TranslationsMixin'
  import ConfigMixin from '../../../util/ConfigMixin'
  import ResourceMixin from '../../../util/ResourceMixin'
  import { getExternalLinkFromItem, getInternalFileUrlForResource } from '../../../util/helpers'
  import { trackMatomoEvent } from '../../../util/analytics'
  import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from '../../../constants/analytics-constants'

  const RESULT_DATA_TYPES = {
    CITY_AND_COUNTRY: 'CITY_AND_COUNTRY',
    CATEGORY: 'CATEGORY',
    CONNECTIONS: 'CONNECTIONS',
    CREATED_AT: 'CREATED_AT',
    ONELINER: 'ONELINER',
    LIKES: 'LIKES',
    CREATED_BY: 'CREATED_BY',
    CREATED_BY_ACTOR: 'CREATED_BY_ACTOR',
    COMMUNITY: 'COMMUNITY',
    URL: 'URL',
    COMMENTS: 'COMMENTS',
    SPOTTING_AREA: 'SPOTTING_AREA',
    DESCRIPTION: 'DESCRIPTION',
    LINKED_ACTOR: 'LINKED_ACTOR',
    SINGLE_WORD_MATCH: 'SINGLE_WORD_MATCH',
  }
  export default {
    name: 'ResultsCard.vue',
    props: {
      result: Object,
    },
    emits: ['view-result'],
    data () {
      return {
        RESULT_DATA_TYPES,
        GLOBAL_SEARCH_RESULT_TYPES,
      }
    },
    computed: {
      iconName () {
        switch (this.result.type) {
          case GLOBAL_SEARCH_RESULT_TYPES.ANNOUNCEMENT:
            return 'bull-horn'
          case GLOBAL_SEARCH_RESULT_TYPES.EVENT:
            return 'calendar'
          case GLOBAL_SEARCH_RESULT_TYPES.CHALLENGE:
            return 'message'
          case GLOBAL_SEARCH_RESULT_TYPES.RESOURCE:
            return 'layer-group'
          case GLOBAL_SEARCH_RESULT_TYPES.ARTICLE:
            return 'newspaper'
          case GLOBAL_SEARCH_RESULT_TYPES.ACTOR:
            if (this.result.result && this.result.result.actor_type === 'Product') {
              return 'light-bulb'
            }
            return 'building'
          default:
            return 'home'
        }
      },
      firstIsProductOfRelation () {
        if (!this.result || !this.result.result.actor_relationships) {
          return ''
        }
        const offeredBy = this.result.result.actor_relationships && this.result.result.actor_relationships.find(connection => connection.name === 'is_product_of')
        if (offeredBy) {
          return offeredBy.actor_name
        }
      },
      domainsLabel () {
        return this.getTaxonomyAliasWithDefault('domains')
      },
      activitiesLabel () {
        return this.getTaxonomyAliasWithDefault('activities')
      },
      technologyLabel () {
        return this.getTaxonomyAliasWithDefault('technology')
      },
      industriesLabel () {
        return this.getTaxonomyAliasWithDefault('industries')
      },
      extraMatches () {
        if (!this.result.highlights) {
          return {}
        }

        let highlightTypes = []
        let extraLineHTML = ''
        let singleWordMatchHTML = ''

        switch (this.result.type) {
          case GLOBAL_SEARCH_RESULT_TYPES.ACTOR:
            highlightTypes = [
              { type: 'single-word', attribute: 'tags_labels.rawText' },
              { type: 'single-word', attribute: 'private_tags_labels.rawText' },
              { type: 'single-word', attribute: 'description_annotation_tags_labels.rawText' },
              { type: this.$t('global_search_match_one_liner'), attribute: 'activities_description' },
              { type: 'single-word', attribute: 'industries_labels.rawText' },
              { type: 'single-word', attribute: 'technology_labels.rawText' },
              { type: 'single-word', attribute: 'activities_labels.rawText' },
              { type: 'single-word', attribute: 'domains_labels.rawText' },
              { type: 'single-word', attribute: 'sub_domains_remarks.rawText' },
              { type: 'single-word', attribute: 'funnel_stage_labels.rawText' },
              { type: 'single-word', attribute: 'stage_labels.rawText' },
              { type: 'single-word', attribute: 'subIndustries_labels.rawText' },
              { type: 'single-word', attribute: 'membership_labels.rawText' },
              { type: this.$t('global_search_result_description'), attribute: 'description' },
              { type: this.$t('actor_detail_reports'), attribute: 'all_text_from_reports' },
              { type: this.$t('actor_detail_notes'), attribute: 'notes.rawText' },
              { type: this.$t('actor_detail_description'), attribute: 'description_stripped_tags' },
              { type: this.$t('edit_actor_product'), attribute: 'product.rawText' },
            ]
            if (this.result.result && this.result.result.actor_type === 'Product') {
              highlightTypes = [...highlightTypes, ...[

                { type: 'single-word', attribute: 'product_features_a_labels.rawText' },
                { type: 'single-word', attribute: 'product_features_b_labels.rawText' },
                { type: 'single-word', attribute: 'product_features_c_labels.rawText' },
                { type: 'single-word', attribute: 'sub_product_features_a_labels.rawText' },
                { type: 'single-word', attribute: 'sub_product_features_b_labels.rawText' },
                { type: 'single-word', attribute: 'sub_product_features_c_labels.rawText' },
                { type: 'single-word', attribute: 'sub_product_features_a_remarks.rawText' },
                { type: 'single-word', attribute: 'sub_product_features_b_remarks.rawText' },
                { type: 'single-word', attribute: 'sub_product_features_c_remarks.rawText' },
              ]]
            } else {
              highlightTypes.push({ type: this.$t('actor_edit_connections_connection'), attribute: 'actor_relationships_labels.rawText' })
            }
            break
          case GLOBAL_SEARCH_RESULT_TYPES.ANNOUNCEMENT:
          case GLOBAL_SEARCH_RESULT_TYPES.CHALLENGE:
          case GLOBAL_SEARCH_RESULT_TYPES.EVENT:
          case GLOBAL_SEARCH_RESULT_TYPES.RESOURCE:
            highlightTypes = [
              { type: 'single-word', attribute: 'tags_labels.rawText' },
              { type: this.$t('global_search_result_description'), attribute: 'full_body' },
            ]
            break
          case GLOBAL_SEARCH_RESULT_TYPES.ARTICLE:
            highlightTypes = [
              { type: 'single-word', attribute: 'tags_labels.rawText' },
              { type: this.$t('global_search_actors_content'), attribute: 'full_body' },
            ]
            break
        }
        for (const highlightType of highlightTypes) {
          if (highlightType.type === 'single-word' && this.result.highlights[highlightType.attribute]) {
            if (!singleWordMatchHTML) {
              singleWordMatchHTML = this.result.highlights[highlightType.attribute]
            }
          } else if (!extraLineHTML && this.getHightlightTextForType(highlightType.type, highlightType.attribute)) {
            extraLineHTML = this.getHightlightTextForType(highlightType.type, highlightType.attribute)
          }
        }
        return {
          extraLineHTML,
          singleWordMatchHTML,
        }
      },
      resultItems () {
        let result = []

        switch (this.result.type) {
          case GLOBAL_SEARCH_RESULT_TYPES.ANNOUNCEMENT:
            result = [RESULT_DATA_TYPES.CREATED_BY, RESULT_DATA_TYPES.CREATED_AT, RESULT_DATA_TYPES.LIKES, RESULT_DATA_TYPES.COMMUNITY, RESULT_DATA_TYPES.SINGLE_WORD_MATCH]
            break
          case GLOBAL_SEARCH_RESULT_TYPES.EVENT:
            result = [RESULT_DATA_TYPES.CREATED_BY, RESULT_DATA_TYPES.CREATED_AT, RESULT_DATA_TYPES.LIKES, RESULT_DATA_TYPES.COMMUNITY, RESULT_DATA_TYPES.SINGLE_WORD_MATCH]
            break
          case GLOBAL_SEARCH_RESULT_TYPES.CHALLENGE:
            result = [RESULT_DATA_TYPES.CREATED_BY, RESULT_DATA_TYPES.CREATED_AT, RESULT_DATA_TYPES.COMMENTS, RESULT_DATA_TYPES.SINGLE_WORD_MATCH]
            break
          case GLOBAL_SEARCH_RESULT_TYPES.ACTOR:
            if (this.result.result.actor_type === 'Product') {
              result = [RESULT_DATA_TYPES.LINKED_ACTOR, RESULT_DATA_TYPES.SINGLE_WORD_MATCH]
            } else {
              result = [RESULT_DATA_TYPES.CITY_AND_COUNTRY, RESULT_DATA_TYPES.CATEGORY, RESULT_DATA_TYPES.CONNECTIONS, RESULT_DATA_TYPES.SINGLE_WORD_MATCH]
            }
            break
          case GLOBAL_SEARCH_RESULT_TYPES.RESOURCE:
            result = [RESULT_DATA_TYPES.CREATED_BY, RESULT_DATA_TYPES.CREATED_BY_ACTOR, RESULT_DATA_TYPES.COMMUNITY, RESULT_DATA_TYPES.SINGLE_WORD_MATCH]
            break
          case GLOBAL_SEARCH_RESULT_TYPES.ARTICLE:
            result = [RESULT_DATA_TYPES.CREATED_AT, RESULT_DATA_TYPES.URL, RESULT_DATA_TYPES.SPOTTING_AREA, RESULT_DATA_TYPES.SINGLE_WORD_MATCH]
            break
        }
        return result
      },
      detailPageUrl () {
        switch (this.result.type) {
          case GLOBAL_SEARCH_RESULT_TYPES.ACTOR:
          case GLOBAL_SEARCH_RESULT_TYPES.PRODUCT:
            return `/actors/${this.result.result.id}`
          case GLOBAL_SEARCH_RESULT_TYPES.ARTICLE:
          case GLOBAL_SEARCH_RESULT_TYPES.RESOURCE:
            if (this.canOpenInternalPage(this.result.result)) {
              return this.internalPageUrl(this.result.result)
            }
            break
          case GLOBAL_SEARCH_RESULT_TYPES.ANNOUNCEMENT:
          case GLOBAL_SEARCH_RESULT_TYPES.EVENT:
            const slugType = this.result.result.is_event ? 'events' : 'announcements'
            const slugId = this.result.result.slug ?? this.result.result

            return `/${slugType}/${slugId}`
          case GLOBAL_SEARCH_RESULT_TYPES.CHALLENGE:
            return `/challenges/${this.result.result.id}`
          default:
            console.log('result type not defined')
            return ''
        }
      },
      exactTranslationForConnections () {
        if (this.result.result.connections === 1) {
          return this.$t('global_search_connection', { connection: this.result.result.connections })
        } else {
          return this.$t('global_search_connections', { connection: this.result.result.connections })
        }
      },
      exactTranslationForComments () {
        if (this.result.result.comments === 1) {
          return this.$t('global_search_comment', { comment: this.result.result.comments })
        } else {
          return this.$t('global_search_comments', { comment: this.result.result.comments })
        }
      },
      exactTranslationForLikes () {
        if (this.result.result.likes === 1) {
          return this.$t('global_search_like', { like: this.result.result.likes })
        } else {
          return this.$t('global_search_likes', { like: this.result.result.likes })
        }
      },
      getHighlightHtmlForCityOrCountry () {
        if (this.result.highlights['address.city'] && this.result.highlights['address.country']) {
          return this.getHightlightHtmlFor('address.city') + ', ' + this.getHightlightHtmlFor('address.country')
        }
        if (!this.result.highlights['address.city'] && this.result.highlights['address.country']) {
          if (this.result.result.address.city) {
            return this.result.result.address.city + ', ' + this.getHightlightHtmlFor('address.country')
          }
          return this.getHightlightHtmlFor('address.country')
        }
        if (this.result.highlights['address.city'] && !this.result.highlights['address.country']) {
          if (this.result.result.address.country) {
            return this.getHightlightHtmlFor('address.city') + ', ' + this.result.result.address.country
          }
          return this.getHightlightHtmlFor('address.city')
        }
      },
    },
    methods: {
      removeAllHtmlTagsExceptSpan (text) {
        const allTagsExceptSpanRegex = /<(?!\/?span)[^>]+>/g
        return text.replaceAll(allTagsExceptSpanRegex, '')
      },
      getHightlightHtmlFor (attribute) {
        if (!this.result.highlights || !this.result.highlights[attribute] || !this.result.highlights[attribute][0]) {
          return
        }
        return this.removeAllHtmlTagsExceptSpan(this.result.highlights[attribute][0])
      },
      getTruncatedHightlightHtmlFor (attribute) {
        if (!this.result.highlights || !this.result.highlights[attribute] || !this.result.highlights[attribute][0]) {
          return
        }
        const highlightHtml = this.removeAllHtmlTagsExceptSpan(this.result.highlights[attribute][0])
        const indexOfSpan = highlightHtml.indexOf('<')
        if (indexOfSpan > 30) {
          const trimmedString = highlightHtml.substring(indexOfSpan - 30)
          return trimmedString.substring(Math.min(trimmedString.length, trimmedString.indexOf(' ') + 1))
        }
        return highlightHtml
      },
      getHightlightTextForType (type, attribute) {
        if (this.result.highlights[attribute]) {
          return this.$t('global_search_match_found', { field: type }) + this.getTruncatedHightlightHtmlFor(attribute)
        }
      },
      trackKeyword () {
        getAnalyticsKeyword(this.result, this.$store.state.ui.globalSearch.query)
          .then((result) => {
            if (result) {
              trackMatomoEvent(MATOMO_EVENT_CATEGORIES.GLOBAL_SEARCH, MATOMO_EVENT_ACTIONS.KEYWORDS, result)
            }
          })
      },
      viewDetail () {
        this.$emit('view-result')
        if (this.detailPageUrl) {
          this.$router.push(this.detailPageUrl)
        } else {
          if (this.result.result.external_url) {
            window.location.href = this.result.result.external_url
            return
          }

          window.location.href = getExternalLinkFromItem(this.result.result) ? getExternalLinkFromItem(this.result.result) : getInternalFileUrlForResource(this.result.result)
        }
      },
      accessRestrictedCommunity () {
        // @click toevoegen op a tag voor modal te tonen wanneer userHasAccess false is
        this.hideGlobalSearchModal()
        if (this.result.result.userHasAccess === false) {
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
            community: this.result.result.not_accessible_communities[0],
            type: this.result.type,
          })
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.NO_ACCESS_TO_COMMUNITY_MODAL)
        }
      },
      hideGlobalSearchModal () {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_GLOBAL_SEARCH)
      },
      clickOnResult () {
        this.trackKeyword()
        if (this.result.result.userHasAccess === false) {
          this.accessRestrictedCommunity()
        } else {
          this.viewDetail()
        }
      },
    },
    mounted: async function () {
    },
    mixins: [TranslationsMixin, ConfigMixin, ResourceMixin],
  }
</script>


<style lang="scss" scoped>
  @import "../../../../scss/variables.scss";

  .result-data {
    display: flex;
    text-decoration: none;
    color: black;
    padding: 10px 15px;
    width: 100%;
    cursor: pointer;

    @media (max-width: $screen-md-max) {
      padding: 15px 24px;
    }

    :deep(span.highlight) {
      background-color: #FFF50059;
    }

    &:hover {
      background-color: $color-background-lightest-grey;
    }

    .result-data__icon-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      @media (max-width: $screen-md-max) {
        .svg-icon {
          width: 18px;
          height: 18px;
        }
      }
    }

    .result-data__container {
      display: flex;
      flex-direction: column;
      min-height: 42px;
      width: calc(100% - 50px);

      p {
        margin: 0;
      }

      p:first-child {
        margin: 0;
      }

      .result-data__title {
        margin-top: auto;
        height: 14px;

        p {
          line-height: 14px;
          font-size: 14px;
          font-weight: 500;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis
        }
      }

      .result-data__info {
        display: flex;
        flex-direction: column;
        height: 100%;
        letter-spacing: 0.1px;
      }

      .result-data__sub-info {
        margin-bottom: auto;
        display: flex;
        letter-spacing: 0.1px;

        p {
          height: 15px;
          font-size: 12px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 33%;


          @media (max-width: $screen-md-max) {
            max-width: 100%;
          }
        }

        p:before {
          content: ' \2022';
          padding-right: 5px;
          padding-left: 5px;
        }

        p:first-child:before {
          content: none;
        }
      }

      .result-data__matching-text {
        height: 15px;
        margin-bottom: auto;

        p {
          font-size: 12px;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          max-width: 100%;
        }
      }

      .result-data__actor {
        display: flex;
      }

      @media (max-width: $screen-md-max) {
        margin-left: 20px;
      }
    }

    .svg-icon {

      :deep(.fill-fg) {
        fill: var(--primary) !important;
      }
    }
  }
</style>
