<template>
  <div class="landscape-chart">
    <svg ref="graph" class="landscape-chart__graph" @wheel.prevent></svg>
  </div>
</template>

<script>
import * as d3v4 from 'd3'
import round from 'lodash/round'
import _throttle from 'lodash/throttle'
import { inert } from '../../util/helpers'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
import { actorLogo } from '../../util/actor.js'

const GROWTH_VALUE_PADDING = 0.2

const LEFT_INFO_WIDTH = 35
const LEFT_AXIS_WIDTH = 50
const BOTTOM_INFO_HEIGHT = 35
const BOTTOM_AXIS_HEIGHT = 40

const MIN_ACTOR_RADIUS = 10
const MAX_ACTOR_RADIUS = 30
const MIN_GROWTH_DELTA = 5

function determineActorRadiusByMentions (delta, max, mentions) {
  const percentageToMax = (delta - (max - mentions)) / (delta || 1)

  return MIN_ACTOR_RADIUS + (MAX_ACTOR_RADIUS * percentageToMax)
}

export default {
  data () {
    return {
      bbox: null, viewBox: null, vx: null, vy: null, vw: null, vh: null, defaultView: null, clickScale: 1.7, scale: 1.0, clickZoom: null
    }
  },
  props: {
    data: Object,
    type: String
  },
  computed: {
    bottomTitle () {
      if (this.type == 'digital_footprint') {
        return 'Digital Footprint Score - '
      } else if (this.type == 'business_size') {
        return 'Business Size Score - '
      }
    },
    scoresData () {
      if (this.data.data) {
        return inert(this.data.data)
      }
      return []
    },
    bounds () {
      return {
        minScore: d3v4.min(this.scoresData, a => a.score) - 5,
        maxScore: d3v4.max(this.scoresData, a => a.score) + 5,
        minGrowth: this.data.minGrowth,
        maxGrowth: this.data.maxGrowth
      }
    },
    referenceActor () {
      return this.scoresData.find(a => a.isReference) || this.average || { name: 'N/A' }
    },
    referenceActorName () {
      return this.referenceActor.name
    },
    dataWithRadius () {
      if (this.scoresData !== undefined) {
        const min = d3v4.min(this.scoresData, actor => actor.mentions)
        const max = d3v4.max(this.scoresData, actor => actor.mentions)
        const delta = max - min

        this.scoresData.forEach(actor => {
          actor.radius = determineActorRadiusByMentions(delta, max, actor.mentions)
        })

        const largeToSmall = this.scoresData
          .slice()
          .sort((a, b) => b.radius - a.radius)

        // Move reference actor to last
        const index = largeToSmall.findIndex(a => a.isReference)
        largeToSmall.push(largeToSmall.splice(index, 1)[0])
        return largeToSmall
      }
    },
    getCurrentDate () {
      if (this.scoresData.length > 0) {
        function twoDigit (s) { return (s < 10) ? '0' + s : s }
        // In order for the date to be read across all browsers, the date mus be seperated by "/" and not "-"
        const date = this.scoresData[0].date.split('-').join('/')
        const newDate = new Date(date)
        const changedDateFormat = [twoDigit(newDate.getMonth() + 1), newDate.getFullYear().toString().substr(-2)].join('/')
        return changedDateFormat
      }
    },
    getPreviousDate () {
      if (this.scoresData.length > 0) {
        function twoDigit (s) { return (s < 10) ? '0' + s : s }
        // In order for the date to be read across all browsers, the date mus be seperated by "/" and not "-"
        const date = this.scoresData[0].date.split('-').join('/')
        const newDate = new Date(date)
        var changedDateFormat
        if (twoDigit(newDate.getMonth() + 1) - 1 == 0) {
          changedDateFormat = [twoDigit(newDate.getMonth() + 12), newDate.getFullYear().toString().substr(-2) - 1].join('/')
        } else {
          changedDateFormat = [twoDigit(newDate.getMonth() + 1) - 1, newDate.getFullYear().toString().substr(-2)].join('/')
        }
        return changedDateFormat
      }
    }
  },
  async mounted () {
    this.render()
    var thisVue = this // to get the scope of the vue component
    window.onresize = function () {
      thisVue.render()
    }
  },
  beforeUnmount () {
    this.clearGraph()
    this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
  },
  watch: {
    data: _throttle(function () {
      if (this.$refs.graph && !this._isunmounted) {
        if (this.$parent.rendered) {
          this.render()
        } else {
          this.render()
        }
      }
    }, 100, {
      trailing: true
    })
  },
  methods: {
    actorLogo,
    render () {
      this.outerWidth = Math.floor(this.$el.offsetWidth)
      this.outerHeight = Math.floor(window.innerHeight - 230)

      this.growthDelta = Math.max(MIN_GROWTH_DELTA, this.bounds.maxGrowth - this.bounds.minGrowth)
      this.minGrowthWithPadding = this.bounds.minGrowth - (this.growthDelta * GROWTH_VALUE_PADDING)
      this.maxGrowthWithPadding = this.bounds.maxGrowth + (this.growthDelta * GROWTH_VALUE_PADDING)

      const margin = {
        top: 0,
        right: 0,
        bottom: BOTTOM_AXIS_HEIGHT + BOTTOM_INFO_HEIGHT,
        left: LEFT_AXIS_WIDTH + LEFT_INFO_WIDTH
      }
      const width = this.outerWidth - margin.left - margin.right
      const height = this.outerHeight - margin.top - margin.bottom
      this.width = width
      this.height = height

      this.clearGraph()

      // Base svg with margin
      const root = d3v4.select(this.$refs.graph)
        .attr('width', this.outerWidth)
        .attr('height', this.outerHeight)
      this.svg = root
        .append('g')
        .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', width - 0)
        .attr('height', height - 0)

      this.axis()
      // controlles zoom on chart
      this.zoom()

      this.chart = this.view.append('g')
        .attr('class', 'chart')

      // create on hover tooltip
      this.hoverTooltip = d3v4.select('body').append('div')
        .attr('class', 'landscape-chart__d3-tip-hover')

      // create on click tooltip
      this.clickTooltip = d3v4.select('body').append('div')
        .attr('class', 'landscape-chart__d3-tip-click')

      // create reference actor tooltip
      this.referenceActorTooltip = d3v4.select('body').append('div')
        .attr('class', 'landscape-chart__d3-tip-teamleader')

      // create best growth tooltip
      this.bestGrowthTooltip = d3v4.select('body').append('div')
        .attr('class', 'landscape-chart__d3-tip-best-growth')

      // Left info
      this.leftInfo = root.append('g')
        .attr('class', 'info info--left')

      this.leftInfo
        .append('line')
        .attr('x1', LEFT_INFO_WIDTH - 1)
        .attr('x2', LEFT_INFO_WIDTH - 1)
        .attr('y1', 0)
        .attr('y2', this.outerHeight)

      this.leftInfo
        .append('text')
        .text('Growth speed per month - ' + this.getCurrentDate + ' to ' + this.getPreviousDate)
        .attr('text-anchor', 'end')
        .attr('x', -15)
        .attr('y', 25)
        .attr('transform', function () {
          return `rotate(-90)`
        })

      // Bottom info
      this.bottomInfo = root.append('g')
        .attr('class', 'info info--bottom')
        .attr('transform', `translate(${LEFT_INFO_WIDTH},${height + BOTTOM_INFO_HEIGHT})`)

      this.bottomInfo
        .append('line')
        .attr('x1', 0)
        .attr('x2', this.outerWidth)
        .attr('y1', 1)
        .attr('y2', 1)

      this.bottomInfo
        .append('text')
        .text(this.bottomTitle + this.getCurrentDate)
        .attr('x', this.outerWidth - LEFT_INFO_WIDTH - 20)
        .attr('y', 23)
        .attr('text-anchor', 'end')

      // Fill corner
      this.svg.append('rect')
        .attr('x', -100)
        .attr('y', height + 1)
        .attr('width', 100)
        .attr('height', 100)
        .attr('class', 'filler')
        .attr('fill', 'white')

      // Fill chart
      this.chart
        .append('rect')
        .attr('class', 'filler')
        .attr('fill', 'rgba(255, 255, 255, .1)')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', this.outerWidth)
        .attr('height', this.outerHeight)

      // Center cross
      if (typeof this.referenceActor.score === 'number') {
        this.chart
          .append('line')
          .attr('class', 'reference--score')
          .attr('x1', this.xScale(this.referenceActor.score))
          .attr('x2', this.xScale(this.referenceActor.score))
          .attr('y1', this.yScale(this.minGrowthWithPadding))
          .attr('y2', this.yScale(this.maxGrowthWithPadding))
          .attr('stroke-width', '2px')
          .attr('stroke', this.$store.getters.primaryColor)
      }
      if ((this.referenceActor.growth || this.referenceActor.growth == 0) && isFinite(this.referenceActor.growth) && typeof this.referenceActor.growth === 'number') {
        this.chart
          .append('line')
          .attr('class', 'reference--growth')
          .attr('x1', this.xScale(this.bounds.minScore))
          .attr('x2', this.xScale(this.bounds.maxScore))
          .attr('y1', this.yScale(this.referenceActor.growth || 0))
          .attr('y2', this.yScale(this.referenceActor.growth || 0))
          .attr('stroke-width', '2px')
          .attr('stroke', this.$store.getters.primaryColor)
      }
      // Text in corners
      const correction = 7
      const verticalPadding = 25

      // create the top left button
      this.topLeftButton = this.svg
        .append('g')
        .attr('transform', 'translate(' + 15 + ',' + 32 + ')')
        .attr('class', 'quadrant-label corner-button')
        .attr('id', 'landscape-chart_top-left-button')

      d3v4.select('#landscape-chart_top-left-button')
        .append('rect')
        .attr('width', 70)
        .attr('height', 20)
        .attr('transform', 'translate(' + -5 + ',' + -14 + ')')

      d3v4.select('#landscape-chart_top-left-button')
        .append('text')
        .text('Rising star')

      // create the bottom left button
      this.bottomLeftButton = this.svg
        .append('g')
        .attr('transform', d => {
          const x = 20
          const y = height - 19
          return `translate(${x},${y})`
        })
        .attr('class', 'quadrant-label corner-button')
        .attr('id', 'landscape-chart_bottom-left-button')

      d3v4.select('#landscape-chart_bottom-left-button')
        .append('rect')
        .attr('width', 60)
        .attr('height', 20)
        .attr('transform', 'translate(' + -7 + ',' + -14 + ')')

      d3v4.select('#landscape-chart_bottom-left-button')
        .append('text')
        .text('Laggard')

      // create the top right button
      this.topRightButton = this.svg
        .append('g')
        .attr('transform', d => {
          const x = width - 95
          const y = verticalPadding + correction
          return `translate(${x},${y})`
        })
        .attr('class', 'quadrant-label corner-button')
        .attr('id', 'landscape-chart_top-right-button')

      d3v4.select('#landscape-chart_top-right-button')
        .append('rect')
        .attr('width', 85)
        .attr('height', 20)
        .attr('transform', 'translate(' + -6 + ',' + -14 + ')')

      d3v4.select('#landscape-chart_top-right-button')
        .append('text')
        .text('Front runner')

      // create the bottom right button
      this.bottomRightButton = this.svg
        .append('g')
        .attr('transform', d => {
          const x = width - 60
          const y = height - 19
          return `translate(${x},${y})`
        })
        .attr('class', 'quadrant-label corner-button')
        .attr('id', 'landscape-chart_bottom-right-button')

      d3v4.select('#landscape-chart_bottom-right-button')
        .append('rect')
        .attr('width', 50)
        .attr('height', 20)
        .attr('transform', 'translate(' + -5 + ',' + -14 + ')')

      d3v4.select('#landscape-chart_bottom-right-button')
        .append('text')
        .text('Mature')

      if (!this.scoresData.length) {
        return
      }

      // Start from opacity 0 if first rendered
      this.opacity = this.$parent.rendered ? 0.8 : 0
      this.$parent.rendered = true

      this.update()

      this.opacity = 0.1
    },
    update () {
      if (this.dataWithRadius !== undefined && this.dataWithRadius.length > 0) {
        const selection = this.chart.selectAll('.actor')
        .data(this.dataWithRadius, a => {
          if (a !== undefined) {
            a.id
          }
        })

        selection
          .classed('actor--new', d => {
            if (d !== undefined) {
              d.new
            }
          })
          .classed('actor--danger', d => {
            if (d !== undefined) {
              d.danger
            }
          })
          .classed('actor--reference', d => {
            if (d !== undefined) {
              d.isReference
            }
          })
          .attr('data-translate', d => {
            if (d !== undefined) {
              const x = this.xScale(d.score)
              const y = this.yScale(d.growth)
              return `translate(${x},${y})`
            }
          })
          .style('opacity', 1)
        .transition()
          .duration((d, i) => 1000)
          .attr('transform', d => {
            if (d !== undefined) {
              const x = this.xScale(d.score)
              const y = this.yScale(d.growth)
              return `translate(${x},${y})`
            }
          })

        selection.exit().transition()
          .duration((d, i) => 200)
          .style('opacity', 0)
          .remove()

        var thisVue = this // to get the scope of the vue component
        this.actors = selection.enter()
          .append('g')
          .attr('class', 'actor')
          .classed('actor--new', d => d.new)
          .classed('actor--danger', d => d.danger)
          .classed('actor--reference', d => d.isReference)
          .attr('data-translate', d => {
            const x = this.xScale(d.score)
            const y = this.yScale(d.growth)
            return `translate(${x},${y})`
          })
          .attr('transform', d => {
            const x = this.xScale(d.score)
            const y = this.yScale(d.growth)
            return `translate(${x},${y})`
          })
          .style('opacity', this.opacity)
          .on('click', (d) => {
            var growthSymbol = '+'

            if (d.growth < 0) {
              var growthSymbol = ''
            }

            var toolTipHtml =
              '<div class="tooltip-heading">' + d.name + '</div>' +
              '<div class="tooltip-body">Mentions: ' + d.mentions + '</div>' +
              '<div class="tooltip-body">Current Score: ' + d.score.toPrecision(4) + '  ' + '</div>' +
              '<div class="tooltip-body">Previous Score: ' + d.previous_score.toPrecision(4) + '</div>' +
              '<div class="tooltip-body">Growth: ' + growthSymbol + (d.growth * 100).toPrecision(3) + '% ' + '</div>'

            if (d.name == thisVue.referenceActorName) {
              if (d.id !== undefined && d.id !== '') {
                toolTipHtml =
                  '<div class="tooltip-heading">' + d.name + '</div>' +
                  '<div class="tooltip-body">Current Score: ' + d.score.toPrecision(4) + '  ' + '</div>' +
                  '<div class="tooltip-body">Previous Score: ' + d.previous_score.toPrecision(4) + '</div>' +
                  '<div class="tooltip-body">Growth: ' + growthSymbol + (d.growth * 100).toPrecision(3) + '% ' + '</div>'
                this.clickTooltip.transition()
                   .duration(100)
                   .attr('class', 'landscape-chart__d3-tip-click-alternative-one')
                   .style('opacity', 1)
                this.clickTooltip.html(toolTipHtml)
                   .style('left', (d3v4.event.pageX - 60) + 'px')
                   .style('top', (d3v4.event.pageY - 120) + 'px')
              }
            } else if (d.hasSignificantChange == true) {
              this.clickTooltip.transition()
               .duration(100)
               .attr('class', 'landscape-chart__d3-tip-click-alternative-two')
               .style('opacity', 1)
              this.clickTooltip.html(toolTipHtml)
               .style('left', (d3v4.event.pageX - 60) + 'px')
               .style('top', (d3v4.event.pageY - 120) + 'px')
            } else {
              this.clickTooltip.transition()
               .duration(100)
               .attr('class', 'landscape-chart__d3-tip-click')
               .style('opacity', 1)
              this.clickTooltip.html(toolTipHtml)
               .style('left', (d3v4.event.pageX - 60) + 'px')
               .style('top', (d3v4.event.pageY - 120) + 'px')
            }
            this.$emit('actor', d)
            if (d.id !== undefined && d.id !== '') {
              this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'scores', metaData: d.id })
            }
          })
          .on('mouseover', function (d) {
            var growthSymbol = '+'

            if (d.growth < 0) {
              var growthSymbol = ''
            }

            var toolTipHtml =
              '<div class="tooltip-heading">' + d.name + '</div>' +
              '<div class="tooltip-body">Mentions: ' + d.mentions + '</div>' +
              '<div class="tooltip-body">Current Score: ' + d.score.toPrecision(4) + '  ' + '</div>' +
              '<div class="tooltip-body">Previous Score: ' + d.previous_score.toPrecision(4) + '</div>' +
              '<div class="tooltip-body">Growth: ' + growthSymbol + (d.growth * 100).toPrecision(3) + '% ' + '</div>'

            if (d.hasSignificantChange == true) {
              toolTipHtml =
                '<div class="tooltip-heading">' + d.name + '</div>' +
                '<div class="tooltip-body">Current Score: ' + d.score.toPrecision(4) + '  ' + '</div>' +
                '<div class="tooltip-body">Previous Score: ' + d.previous_score.toPrecision(4) + '</div>' +
                '<div class="tooltip-body">Growth: ' + growthSymbol + (d.growth * 100).toPrecision(3) + '% ' + '</div>'

              thisVue.bestGrowthTooltip.transition()
                .duration(100)
                .style('opacity', 1)

              var growthSymbol = '+'

              if (d.growth < 0) {
                var growthSymbol = ''
              }

              toolTipHtml =
                '<div class="tooltip-heading">' + d.name + '</div>' +
                '<div class="tooltip-body tooltip-body__significant-change">Significant change: ' + d.significantChange + '</div>'

              thisVue.bestGrowthTooltip
                .style('left', (d3v4.event.pageX - 68) + 'px')
                .style('top', (d3v4.event.pageY - 100) + 'px')
                .html(toolTipHtml)
            } else if (d.name == thisVue.referenceActor.name) {
              if (d.id !== undefined && d.id !== '') {
                toolTipHtml =
                  '<div class="tooltip-heading">' + d.name + '</div>' +
                  '<div class="tooltip-body">Current Score: ' + d.score.toPrecision(4) + '  ' + '</div>' +
                  '<div class="tooltip-body">Previous Score: ' + d.previous_score.toPrecision(4) + '</div>' +
                  '<div class="tooltip-body">Growth: ' + growthSymbol + (d.growth * 100).toPrecision(3) + '% ' + '</div>'

                thisVue.referenceActorTooltip.transition()
                  .duration(100)
                  .style('opacity', 1)
                thisVue.referenceActorTooltip.html(toolTipHtml)
                 .style('left', (d3v4.event.pageX - 68) + 'px')
                 .style('top', (d3v4.event.pageY - 130) + 'px')
              }
            } else {
              thisVue.hoverTooltip.transition()
                .duration(100)
                .style('opacity', 1)
              thisVue.hoverTooltip.html(toolTipHtml)
                .style('left', (d3v4.event.pageX - 68) + 'px')
                .style('top', (d3v4.event.pageY - 130) + 'px')
            }
          })
          .on('mouseout', function (d) {
            thisVue.hoverTooltip.transition()
              .duration(100)
              .style('opacity', 0)
            thisVue.bestGrowthTooltip.transition()
              .style('opacity', 0)
            thisVue.referenceActorTooltip.transition()
              .style('opacity', 0)
          })
          .style('opacity', 1)

        this.actors
          .filter(d => d.logo)
          .append('clipPath')
          .attr('id', (d, i) => `clip-${i}`)
          .append('circle')
          .attr('cx', 0)
          .attr('cy', 0)
          .attr('r', (d, i) => d.name == this.referenceActor.name ? 20 : d.radius - 4)

        // Add the background circle
        this.actors
          .append('circle')
          .attr('class', (d) => {
            if (d.hasSignificantChange == true) {
              return 'actor__background-circle landscape-chart__border-best-growth'
            }

            return 'actor__background-circle'
          })
          .attr('cx', 0)
          .attr('cy', 0)
          .attr('r', d => d.name == this.referenceActor.name ? 20 : d.radius)
          .attr('fill', 'url(#grump_avatar)')

        const NEW_INDICATOR_IMAGE_DIMENSIONS = 17

        // Add the logo
        this.actors
          .filter(d => {
            if (d.logo == null || d.logo == '') {
              var nameFirstLetter = d.name.charAt(0).toUpperCase()
              var letterImage = '/images/' + nameFirstLetter + '.png'
              return letterImage
            }
            return d.logo
          })
          .append('image')
          .attr('xlink:href', d => {
            if (d.logo == null || d.logo == '') {
              var nameFirstLetter = d.name.charAt(0).toUpperCase()
              var letterImage = '/images/' + nameFirstLetter + '.png'
              return letterImage
            }
            return this.actorLogo(d)
          })
          .attr('x', d => d.name == this.referenceActor.name ? -13 : -d.radius)
          .attr('y', d => d.name == this.referenceActor.name ? -13 : -d.radius)
          .attr('width', d => d.name == this.referenceActor.name ? 26 : d.radius * 2)
          .attr('height', d => d.name == this.referenceActor.name ? 26 : d.radius * 2)
          .attr('clip-path', (d, i) => `url(#clip-${i})`)
          .style('clip-path', d => {
            var nameFirstLetter = d.name.charAt(0)
            // for when the reference image at the center is the heartbeat
            if (nameFirstLetter == undefined || nameFirstLetter == '') {
              return `circle(${12}px at center)`
            }
            if (d.name == this.referenceActor.name) {
              return `circle(${20}px at center)`
            }
            return `circle(${d.radius - 4}px at center)`
          })
          .style('-webkit-clip-path', d => {
            var nameFirstLetter = d.name.charAt(0)
            // for when the reference image at the center is the heartbeat
            if (nameFirstLetter == undefined || nameFirstLetter == '') {
              return `circle(${12}px at center)`
            }
            if (d.name == this.referenceActor.name) {
              return `circle(${20}px at center)`
            }
            return `circle(${d.radius - 4}px at center)`
          })
          .on('error', function (d) {
            var nameFirstLetter = d.name.charAt(0).toUpperCase()
            var letterImage
            if (nameFirstLetter == undefined || nameFirstLetter == '') {
              // in case of not existing a referece actor image
              letterImage = '/favicon.png'
            } else {
              letterImage = '/images/' + nameFirstLetter + '.png'
            }
            this.setAttributeNS('http://www.w3.org/1999/xlink', 'href', letterImage)
          })

        // Add the 'new' indicator to new actors
        this.actors
          .filter(d => d.new)
          .append('image')
          .attr('xlink:href', '/images/new-actor-indicator.png')
          .attr('width', NEW_INDICATOR_IMAGE_DIMENSIONS)
          .attr('height', NEW_INDICATOR_IMAGE_DIMENSIONS)
          .attr('transform', d => {
            const x = -(NEW_INDICATOR_IMAGE_DIMENSIONS / 2)
            const y = -d.radius - (NEW_INDICATOR_IMAGE_DIMENSIONS / 2)
            return `translate(${x},${y})`
          })

        // Animate actor fade-in
        if (!this.opacity) {
          this.actors
            .transition()
            .duration((d, i) => this.data.length - 1 === i ? 0 : 500)
            .delay((d, i) => (1000 + (i + 1) * (1000 / this.data.length)) * (this.data.length - 1 === i ? 0 : 1))
            .style('opacity', 1)
        }

        // Center cross
        if (typeof this.referenceActor.score === 'number') {
          this.chart.select('.reference--score')
            .transition()
            .duration((d, i) => 1000)
            .attr('x1', this.xScale(this.referenceActor.score))
            .attr('x2', this.xScale(this.referenceActor.score))
            .attr('y1', this.yScale(this.minGrowthWithPadding) + 100)
            .attr('y2', this.yScale(this.maxGrowthWithPadding))
        }
        if (typeof this.referenceActor.growth === 'number') {
          this.chart.select('.reference--growth')
            .transition()
            .duration((d, i) => 1000)
            .attr('x1', this.xScale(this.bounds.minScore))
            .attr('x2', this.xScale(this.bounds.maxScore) + 100)
            .attr('y1', this.yScale(this.referenceActor.growth || 0))
            .attr('y2', this.yScale(this.referenceActor.growth || 0))
        }
      }
    },
    axis () {
      this.xScale = d3v4.scaleLinear()
        .domain([this.bounds.minScore, this.bounds.maxScore])
        .range([0, this.width])

      this.yScale = d3v4.scaleLinear()
        .domain([this.maxGrowthWithPadding, this.minGrowthWithPadding])
        .range([0, this.height])

      this.xAxis = d3v4.axisBottom(this.xScale)
        .ticks((this.width + 2) / (this.height + 2) * 10)
        .tickSize(this.height)
        .tickPadding(8 - this.height)

      this.yAxis = d3v4.axisLeft(this.yScale)
        .ticks(10)
        .tickSize(this.width)
        .tickPadding(8 - this.width)
        .tickFormat(d => `${Math.round(round(d, 2) * 100)}%`)

      this.view = this.svg.append('g')
        .attr('x', 0)
        .attr('y', 0)
        .attr('width', this.width - 0)
        .attr('height', this.height - 0)

      this.gX = this.svg.append('g')
        .attr('class', 'axis axis--x')
        .attr('transform', `translate(0, ${this.height})`)
        .call(this.xAxis)

      this.gY = this.svg.append('g')
        .attr('class', 'axis axis--y')
        .call(this.yAxis)
    },
    zoom () {
      const zoomed = () => {
        this.actors && this.actors.attr('transform', d => {
          const x = this.xScale(d.score)
          const y = this.yScale(d.growth)
          return `translate(${x},${y}) scale(${Math.pow(d3v4.event.transform.k, -0.8)})`
        })

        this.view.attr('transform', d3v4.event.transform)
        this.gX.call(this.xAxis.scale(d3v4.event.transform.rescaleX(this.xScale)))
        this.gY.call(this.yAxis.scale(d3v4.event.transform.rescaleY(this.yScale)))

        this.chart.select('.reference--growth')
            .attr('stroke-width', 2 / d3v4.event.transform.k + 'px')

        this.chart.select('.reference--score')
            .attr('stroke-width', 2 / d3v4.event.transform.k + 'px')

        if (this.hoverTooltip) {
          this.hoverTooltip
              .style('opacity', 0)
        }
        if (this.clickTooltip) {
          this.clickTooltip
              .style('opacity', 0)
        }
        if (this.referenceActorTooltip) {
          this.referenceActorTooltip
              .style('opacity', 0)
        }
        if (this.bestGrowthTooltip) {
          this.bestGrowthTooltip
              .style('opacity', 0)
        }
      }

      var zoom = d3v4.zoom()
        .scaleExtent([1, 16])
        .translateExtent([[0, 0], [this.outerWidth, this.outerHeight]])
        .on('zoom', zoomed)

      this.svg.call(zoom)
    },
    clearGraph () {
      if (this.hoverTooltip) {
        d3v4.select('.landscape-chart__d3-tip-hover').remove()
      }
      if (this.clickTooltip) {
        d3v4.select('.landscape-chart__d3-tip-click').remove()
        d3v4.select('.landscape-chart__d3-tip-click-alternative-one').remove()
        d3v4.select('.landscape-chart__d3-tip-click-alternative-two').remove()
      }
      if (this.referenceActorTooltip) {
        d3v4.select('.landscape-chart__d3-tip-teamleader').remove()
      }
      if (this.bestGrowthTooltip) {
        d3v4.select('.landscape-chart__d3-tip-best-growth').remove()
      }

      d3v4.select(this.$refs.graph)
        .selectAll('*')
        .remove()
    }
  },
}
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";

.landscape-chart--loading {
  margin-top: 40px;
  border: 1px solid $color-borders;
  height: calc(100vh - 320px);
}

.landscape-chart__header {
  align-items: center;
  padding: 10px;
}

.landscape-chart__header__title {
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: $font-stack-secondary;
  flex: 1;
}

.landscape-chart__header__legend {
  margin-left: 40px;
  display: flex;
}

.landscape-chart__header__legend__item {
  margin-right: 20px;
  display: flex;

  img {
    margin-right: 4px;
  }

  &:last-child {
    margin-right: 0;
  }
}

.landscape-chart__graph {
  border: 1px solid $color-borders;

  .actor {
    transition: transform .2s;
  }
  .axis {
    fill: white;
    path {
      // stroke: none;
    }
  }
  .tick {
    >line {
      stroke: $color-borders;
      stroke-width: 1px;
      shape-rendering: crispEdges;
      opacity: 0.2;
    }
  }

  .axis {
    path {
      stroke: rgba(0, 0, 0, 0);
    }
  }

  .axis--x {
    .tick>line {
      transform: translateY(-100%);
    }
  }
  .axis--y {
    .tick>line {
      transform: translateX(100%);
    }
  }

  .tick {
    text {
      font-size: 12px;
      font-family: $font-stack-secondary;
    }
  }

  .gridline {
    stroke: $color-borders;
    stroke-width: 1px;
    shape-rendering: crispEdges;
    opacity: 0.3;
  }

  .actor {
    opacity: 0.8;
    transition: opacity .2s;

    &:hover {
      cursor: pointer;
      opacity: 1;

      .actor__background-circle {
        stroke: $color-alternative-one;
      }
    }
  }

  .actor__new-indicator {
    fill: $color-error;
    stroke: #fff;
    stroke-width: 2px;
  }

  .actor__background-circle {
    fill: #fff;
    stroke: #000;
    stroke-width: .5px;
    transition: stroke .2s;
  }

  .actor--danger {
    .actor__background-circle {
      stroke: $color-error;
    }
  }

  .actor--reference {
    .actor__background-circle {
      stroke: $color-primary;
      stroke-width: 2px;
    }
  }

  .info {
    > line {
      stroke: $color-borders;
      stroke-width: 1px;
      shape-rendering: crispEdges;
    }

    text {
      font-size: 18px;
      text-transform: uppercase;
      font-family: $font-stack-secondary;
    }
  }

  .quadrant-label {
    font-size: 18px;
    text-transform: uppercase;
    font-family: $font-stack-secondary;
  }
}

.landscape-chart__d3-tip-hover {
  position: absolute;
  text-align: left;
  line-height: 1;
  font-weight: 500;
  padding: 12px;
  background: $color-alternative-one;
  color: #fff;
  border-radius: 2px;
  pointer-events: none;
  z-index:9999;
}

.landscape-chart__d3-tip-click{
  position: absolute;
  text-align: left;
  line-height: 1;
  font-weight: 500;
  padding: 12px;
  background: $color-alternative-two;
  color: #fff;
  border-radius: 2px;
  pointer-events: none;
}

.landscape-chart__d3-tip-click-alternative-one{
  position: absolute;
  text-align: left;
  line-height: 1;
  font-weight: 500;
  padding: 12px;
  background: $color-primary;
  color: #fff;
  border-radius: 2px;
  pointer-events: none;
}

.landscape-chart__d3-tip-click-alternative-two{
  position: absolute;
  text-align: left;
  line-height: 1;
  font-weight: 500;
  padding: 12px;
  background: $color-alternative-three;
  color: #fff;
  border-radius: 2px;
  pointer-events: none;
}

.landscape-chart__d3-tip-teamleader{
  position: absolute;
  text-align: left;
  line-height: 1;
  font-weight: 500;
  padding: 12px;
  background: $color-primary;
  color: #fff;
  border-radius: 2px;
  pointer-events: none;
}

.landscape-chart__d3-tip-best-growth{
  position: absolute;
  text-align: left;
  line-height: 1;
  font-weight: 500;
  padding: 12px;
  background: $color-alternative-three;
  color: #fff;
  border-radius: 2px;
  pointer-events: none;
}


.landscape-chart__border-best-growth {
  stroke: $color-alternative-three !important;
  stroke-width: 2px !important;
}

.corner-button > rect {
  fill: $color-secondary;
}

.corner-button > text {
  font-size: 10px;
  fill: white;
}

.zoom-button {
  cursor: pointer;
}

.zoom-button > rect {
  fill: $color-alternative-two;
}

.zoom-button > text {
  fill: white;
}

.tooltip-body__significant-change {
  max-width:100px;
  line-break: auto;
  text-align:center;
}

</style>
