<template>
  <div class="card news__card">
    <div class="card__ribbon" v-if="ribbonClass" :style="ribbonStyle"></div>
    <div class="news__username">
      <router-link v-if="actor" :to="'/actors/' + actor.id" class="news__actor">{{ actor.name }}</router-link>
      <div v-else-if="actorName" class="news__actor">{{ actorName }}</div>
      <span class="news__info">
        <a :href="'https://twitter.com/' + item.username" class="news__handle" target="_blank">@{{ item.username }}</a>
        &middot;
        <a :href="'https://twitter.com/i/web/status/' + item.meta.id_str" class="news__handle" target="_blank">
          <!-- <time v-if="item.ago" :title="item.meta.created_at">{{ item.ago }}</time> -->
          <time v-if="time" :title="item.meta.created_at">{{ time }}</time>
        </a>
      </span>
    </div>
    <span v-html="newsHTML"></span>
    <div class="card__ctas">
      <a class="card__cta" target="_blank" :href="'https://twitter.com/intent/retweet?tweet_id=' + item.meta.id_str" @click="popup">
        <icon name="retweet" size="regular" />
        <span class="card__cta__count" v-if="item.meta.favorites_count">{{ item.meta.favorites_count }}</span>
      </a>
      <a class="card__cta" target="_blank" :href="'https://twitter.com/intent/like?tweet_id=' + item.meta.id_str" @click="popup">
        <icon name="heart-outline" size="18" />
        <span class="card__cta__count" v-if="item.meta.retweet_count">{{ item.meta.retweet_count }}</span>
      </a>
      <a class="card__cta" target="_blank" :href="'https://twitter.com/i/web/status/' + item.meta.id_str">
        <icon name="open-in-new" size="18" />
      </a>
      <button class="card__cta card__cta--hide" type="button" style="float: right" @click="$emit('hide', item.username)" title="Hide this handle">
        <icon name="eye-blocked" size="18" />
      </button>
    </div>
  </div>
</template>

<script>
  import CompanyMixin from '../../util/CompanyMixin'
  import { timeAgo } from '../../util/date'
  // import Tweet from 'tweet-to-html'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: ['item'],
    computed: {
      actor () {
        return this.item.actor
      },
      actorName () {
        return this.item.actorName
      },
      company () {
        return this.actor
      },
      ribbonClass () {
        return this.actor
      },
      time () {
        return timeAgo(this.item.meta.created_at)
      },
      newsHTML () {
        return ''
        /*return Tweet.parse(this.item.meta).html
                  .replace(/href="http/g, 'target="_blank" href="http')
                  .replace(/http:\/\/pbs.twimg.com/g, 'https://pbs.twimg.com')
                  .replace(/http:\/\/twemoji.maxcdn.com/g, 'https://twemoji.maxcdn.com')*/
      }
    },
    methods: {
      popup (evt) {
        let target = evt ? evt.target : null

        while (target && target.nodeName.toLowerCase() !== 'a') {
          target = target.parentNode
        }

        if (target && target.href) {
          const left = Math.round((window.screen.width / 2) - (550 / 2))
          const top = window.screen.height > 420 ? Math.round((window.screen.height / 2) - (420 / 2)) : 0
          window.open(target.href, 'myWindow', 'width=550,height=420,left=' + left + ',top=' + top)
          evt.preventDefault && evt.preventDefault()
        }
      }
    },
    mixins: [CompanyMixin]
  })
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

  .news__card {
    position: relative;
    overflow: hidden;
    font-size: 16px;
    img, video {
      display: block;
      margin: 1rem -1rem 10px;
      max-width: calc(100% + 2rem);
      &:last-child {
        // margin-bottom: -1rem;
      }
    }
    .emoji {
      display: inline;
      margin: 0 .2em;
      height: 18px;
      width: 18px;
    }
  }

  .news__username {
    margin-bottom: .5rem;
    font-size: 14px;
    font-weight: 500;
  }

  .news__info {
    font-size: 13px;
    font-weight: normal;
    opacity: .5;
  }

  a.news__handle,
  a.news__actor {
    color: inherit;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .news__actor {
    margin-right: .5rem;
  }

  .card__ctas {
    margin: 10px -1rem -6px;
    font-size: 14px;
  }

  .card__cta {
    text-decoration: none;
    padding: 10px 1rem;
    display: inline-block;
    vertical-align: top;
    line-height: 20px;
    color: #AAB8C2;
    cursor: pointer;
    background: none;
    border: 0;

    .fill-fg {
      fill: #AAB8C2;
    }
    > .svg-icon {
      float: left;
    }
    &:focus {
      background: #eee;
      outline: none;
    }
    &:hover {
      color: $color-primary;
      .fill-fg {
        fill: $color-primary;
      }
    }
  }

  .card__cta__count {
    padding-left: .5rem;
    // float: left;
  }

  .newsfeed--single .card__cta--hide {
    display: none;
  }
</style>
