<template>
  <div class="overlay-plus-button" @click="clickEvent" @mouseover="mouseOver" @mouseout="mouseOut" v-tooltip.left="label">
    <template v-if="hasIconTransition && transitionIcon !== undefined ">
      <icon :name="transitionIcon" class="overlay-svg-icon" v-show="isHover"/>
      <icon name="plus" class="overlay-svg-icon overlay-svg-plus-icon" v-show="!isHover"/>
    </template>
    <template v-else>
      <icon name="plus" class="overlay-svg-icon overlay-svg-plus-icon"/>
    </template>
  </div>
</template>

<script>
  import IconHoverTag from '../Icon/IconHoverTag.vue'
  import ICONS from '../../../icons/manifest.json'

  export default {
    name: 'overlay-plus-button',
    data () {
      return {
        ICONS,
        isHover: false
      }
    },
    props: {
      label: String,
      orientation: String,
      hasIconTransition: { // Is used to determine if we do the icon transition when hovering
        type: Boolean,
        default: false
      },
      transitionIcon: String // Determines which icon to transition to.
    },
    methods: {
      clickEvent (evt) {
        this.$emit('openPanel', evt)
      },
      mouseOver () {
        this.isHover = true
      },
      mouseOut () {
        this.isHover = false
      }
    },
    components: {
      IconHoverTag
    },
  }
</script>
<style lang="scss" scoped>
  @import "../../../scss/_variables.scss";

  .tooltipOverride {
    width: 100px;
    margin-top: 10px;
    right: 55px;
  }
  .overlay-plus-button{
    position: absolute;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: var(--primary);
    bottom: 35px;
    right: 20px;
    z-index: 1000;
    cursor: pointer;

    @media (max-width: $screen-md) {
      display: none;
    }
  }
  .svg-icon--plus{
    margin: 10px;
    position: relative;
    width: 30px;
    height: 30px;
  }
  .svg-icon--plus path{
    fill: white;
  }
</style>
