<template>
  <div>
    <div>
      <h2 class="h2">Data Relationships</h2>

      <div class="manage-relationships__list">
        <ul>
          <li v-for="(relationship, index) in dataRelationships" :key="'data_' + index">
            <span
                @click="copyToClipboard(relationship.name)"
                v-tooltip.right="'Copy name to use in spreadsheets: ' + relationship.name"> {{
                relationship.label
              }} <icon name="copy" size="14"/> </span> / <span
              @click="copyToClipboard(relationship.inverse_name)"
              v-tooltip.right="'Copy name to use in spreadsheets: ' + relationship.inverse_name"> {{
              relationship.inverse_label
            }} <icon name="copy" size="14"/> </span>
          </li>
        </ul>
      </div>
    </div>

    <br>
    <h2 class="h2">{{ lists.length ? lists[0].title : defaultTitle }}
      <icon name="plus" style="margin-bottom: -3px; cursor: pointer"
            @click="showAddMore = !showAddMore;focusTagInput()"></icon>
    </h2>
    <div class="subtitle">List of Data types</div>
    <div class="manage-relationships__list">
      <div class="manage-relationships__list-item" v-show="showAddMore">
        <div class="manage-relationships__list-item-name hoverable selected">
          <input placeholder="" type="text" v-model="newTag"
                 ref="addMore" @keyup="handleTagKeyup">
          <div class="buttons">
            <icon v-if="newTag.length >= 0" class="icon" name="check"
                  @click="confirmNewTag"/>
          </div>
        </div>
      </div>
      <div class="manage-relationships__list-item"
           v-for="(tag, index) in lists.length ? lists[0].tags : []"
           :key="'data_' + index">
        <div class="manage-relationships__list-item-name hoverable"
             :class="{ 'selected': deletingTag === tag || editingTag === tag }">
          <div v-if="editingTag !== tag">
            {{ tag }}
          </div>
          <input placeholder="" type="text" v-model="editedTag" v-if="editingTag === tag"
                 :ref="'editing' + tag" @keyup="handleEditingTagKeyup">
          <div class="buttons">
            <icon class="icon" name="trash" @click="deleteTag(tag)"
                  v-if="!deletingTag && !editingInProgress && !editingTag"></icon>
            <icon class="icon" name="edit" @click="startEditingTag(tag)"
                  v-if="!deletingTag && !editingTag"></icon>
            <icon class="icon" name="check" @click="confirmEditTag"
                  v-if="editingTag === tag && !editingInProgress && editedTag.length > 0"></icon>
            <icon class="icon" name="spinner"
                  v-if="deletingTag === tag || (editingTag === tag && editingInProgress)"></icon>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import {
  countTagUsage,
  deleteTag,
  EcosystemRelationshipsMetaDataLists,
  updateTag,
} from '../../../api/ecosystem-meta-data.js'
import { ACTION_TYPES as RELATIONSHIP_ACTION_TYPES } from '../../../store/modules/actor-relationships.js'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui.js'
import MODAL_IDS from '../../../constants/modal-ids.js'

import { copyToClipboard } from '../../../util/helpers.js'
import { defineComponent } from 'vue'

const DEFAULT_TITLE = 'Data type'
export default defineComponent({
  name: 'ManageDataRelationships',
  data() {
    return {
      defaultTitle: DEFAULT_TITLE,
      errors: [],
      showAddMore: false,
      newTag: '',
      deletingTag: '',
      editingTag: '',
      editedTag: '',
      editingInProgress: false,
    }
  },
  computed: {
    lists() {
      return this.$store.state.actorRelationships.metaDataLists
    },
    relationships() {
      return this.$store.state.actorRelationships.relationships
    },
    dataRelationships() {
      return this.relationships.filter(relationship => relationship.type === 'data')
    },
  },
  methods: {
    confirmEditTag() {
      this.editingInProgress = true
      updateTag(this.lists[0].id, this.editingTag, this.editedTag).then(() => {
        this.$store.dispatch(RELATIONSHIP_ACTION_TYPES.FETCH_RELATIONSHIPS_META_DATA)
        this.metaDataListsPromise.finally(() => {
          this.editingInProgress = false
          this.editingTag = ''
          this.editedTag = ''
        })
      })
    },
    startEditingTag(tag) {
      this.editingTag = tag
      this.editedTag = tag

      this.$nextTick(() => {
        if (this.$refs['editing' + tag] && this.$refs['editing' + tag][0]) {
          this.$refs['editing' + tag][0].focus()
        }
      })
    },
    handleEditingTagKeyup($event) {
      if (this.editedTag.length < 1) {
        return
      }
      if ($event.keyCode === 13) {
        this.confirmEditTag()
      }
    },
    copyToClipboard,
    deleteTag(tag) {
      if (!tag) {
        return
      }

      countTagUsage(this.lists[0].id, tag).then(result => {
        if (result.tag_count === 0) {
          this.confirmDeleteTag(tag)
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
            name: tag,
            countableEntityName: 'tag',
            count: result.tag_count,
            resource: tag,
            modalContextType: 'countable-entity',
          })

          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
        }
      })
    },
    confirmDeleteTag(tag) {
      this.deletingTag = tag

      deleteTag(this.lists[0].id, tag).then(() => {
        this.$store.dispatch(RELATIONSHIP_ACTION_TYPES.FETCH_RELATIONSHIPS_META_DATA)
        this.metaDataListsPromise.finally(() => {
          this.deletingTag = ''
        })
      })
          .catch(() => {
            this.deletingTag = ''
          })
    },
    focusTagInput() {
      setTimeout(() => {
        this.$refs.addMore.focus()
      })
    },
    handleTagKeyup($event) {
      if (!this.newTag) {
        return
      }
      if ($event.keyCode === 13) {
        this.confirmNewTag()
      }
    },
    confirmNewTag() {
      if (this.lists.length === 0) {
        this.createList()
      } else {
        this.updateList()
      }
      this.showAddMore = false
    },
    createList() {
      EcosystemRelationshipsMetaDataLists
          .post({
            title: DEFAULT_TITLE,
            tags: [this.newTag],
          })
          .then(response => {
            this.$store.dispatch(RELATIONSHIP_ACTION_TYPES.FETCH_RELATIONSHIPS_META_DATA)
            this.$store.dispatch(RELATIONSHIP_ACTION_TYPES.LINK_RELATIONSHIP_WITH_LIST, {
              listId: this.lists[0].id,
              relationshipId: this.dataRelationships[0].id,
            })
          })
          .catch(errors => {
            this.errors = errors
          })
    },
    updateList() {
      EcosystemRelationshipsMetaDataLists
          .post({
            id: this.lists[0].id,
            tags: [this.newTag, ...this.lists[0].tags],
          })
          .then(response => {
            this.$store.dispatch(RELATIONSHIP_ACTION_TYPES.FETCH_RELATIONSHIPS_META_DATA)

            if (!this.dataRelationships.length || !this.dataRelationships[0].lists || !this.dataRelationships[0].lists.length) {
              this.$store.dispatch(RELATIONSHIP_ACTION_TYPES.LINK_RELATIONSHIP_WITH_LIST, {
                listId: this.lists[0].id,
                relationshipId: this.dataRelationships[0].id,
              })
            }
          })
          .catch(errors => {
            this.errors = errors
          })
          .finally(() => {
            this.newTag = ''
          })
    },
  },
  mounted() {
    this.$bus.on('confirmDeleteCountableEntity', (context) => {
      this.confirmDeleteTag(context.resource)
    })
  },
  beforeUnmount() {
    this.$bus.off('confirmDeleteCountableEntity')
  },
})
</script>

<style scoped>
</style>
