<template>
  <div class="authentication-modal video-modal">
    <modal :id="modalId" ref="modal" :title="title" @close="closeModal" :enable-mask="false" :is-closeable="isCloseable">
      <template v-slot:body>
        <div ref="video__modal__body" class="video__modal__body">
          <template v-if="modalContext.url && modalContext.url.length > 0">
            <iframe width="100%" :height="videoHeight" :src="modalContext.url"  frameBorder="0" >
              <p>Your browser does not support iframes.</p>
            </iframe>
          </template>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from './Modal.vue'
import MODAL_IDS from '../../constants/modal-ids'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'



export default {
  name: 'actor-video-modal',
  props: {
    isCloseable: {
      type: Boolean,
      default: true
    },
  },
  data () {
    return {
      modalId: 'ACTOR_VIDEO',
      videoHeight: {
        type: String,
        default: '400px'
      },
    }
  },
  computed: {
    modalContext () {
     return this.$store.state.ui.modalContext
    },
    title () {
     return this.modalContext.actorName || 'Video'
    },
  },
  methods: {
    closeModal () {
      this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, this.modalId)
      // When closing the modal we reset to modal context.
      this.$store.commit('UI/SET_MODAL_CONTEXT', null)
    },
    setVideoDimensions () {
      if (this.$refs.video__modal__body) {
        // The video dimentions are set to fit its container
        // The -100px is beacuse of the height oh the header of the modal
        // 0.77 to preserve the aspect ration (16:9)
        this.videoHeight = `${(this.$refs.video__modal__body.clientWidth * 0.77) - 100}px`
      }
    }
  },
  mounted () {
    this.$bus.on('resize', () => {
      if (this.$refs.video__modal__body) {
        this.setVideoDimensions()
      }
    })
    if (this.$refs.video__modal__body) {
      this.setVideoDimensions()
    }
  },
  components: {
      Modal
    }
  }
</script>
<style lang="scss" >
  @import "../../../scss/_variables.scss";
  .video-modal {
    .modal__header {
      .divider {
        max-width: unset;
      }
    }
    .modal__container {
      max-width: 90% !important;
      max-height: 95%;
      .modal__body {
        height: 100%;
      }
      .video__modal__body {
        height: 100%;
        display: flex;
        iframe {
          border: 1px solid $color-borders;
          margin: 0 auto;
          max-height: 75vh;
          max-width: 135vh;
        }
      }
    }
  }
</style>
