<template>
  <modal
    :id="modalId"
    ref="modal"
    title="Create a proof point"
    size="flex"
    @close="close"
    is-simplified-modal
  >
    <!-- Modal header tabs -->
    <template v-slot:body>
      <div>
        <div class="proof-point__title-container">
          <div class="mandatory-message">
            <i>{{ $t('all_fields_with_asterisk_are_mandatory') }}</i>
          </div>
        </div>

        <div class="proof-point__content-container">
          <form-group :label="spottingAreaLabel" required>
            <dropdown
              v-if="availableSpottingAreas && availableSpottingAreas.length"
              is-simplified
              v-model="proofPoint.spotting_area_id"
              :options="availableSpottingAreas"
            />
          </form-group>
          <br>

          <form-group :label="$t('signal_create_analysis_type')">
            <dropdown
              v-model="proofPoint.analysis_type"
              :allow-clear="true"
              is-simplified
              :options="proofPointAnalysisTypeOptions"
            />
          </form-group>
          <br v-if="proofPoint.analysis_type === 'key_trends' || proofPoint.analysis_type === 'industry_forces'">
          <!--Search for content & actors input field -->
          <!--          <form-group v-if="showSearchContentField" :label="$t('signal_create_actor')" required>
                      <autocomplete-dropdown
                        :required="true"
                        is-simplified
                        :model-value="chosenSignalType"
                        :options="actorOptions"
                        :do-search="updateActorOptions"
                        :multiple="false"
                        :allowClear="false"
                        @update:modelValue="updateActorForProofPoint"
                        :placeholder="$t('search_organisations_by_name')"
                        class="add-actor-to-article__dropdown"
                        :margin-top="'0px'"
                        width="325px"
                      />
                    </form-group>-->
          <div v-if="proofPoint.analysis_type === 'key_trends'" class="create-proof-point__pestel-container">
            <div class="create-proof-point__pestel-item" :class="{'selected': proofPoint.is_political}" @click="togglePestelItem('is_political')">P</div>
            <div class="create-proof-point__pestel-item" :class="{'selected': proofPoint.is_economical}" @click="togglePestelItem('is_economical')">E</div>
            <div class="create-proof-point__pestel-item" :class="{'selected': proofPoint.is_social}" @click="togglePestelItem('is_social')">S</div>
            <div class="create-proof-point__pestel-item" :class="{'selected': proofPoint.is_technological}" @click="togglePestelItem('is_technological')">T</div>
            <div class="create-proof-point__pestel-item" :class="{'selected': proofPoint.is_environmental}" @click="togglePestelItem('is_environmental')">E</div>
            <div class="create-proof-point__pestel-item" :class="{'selected': proofPoint.is_legal}" @click="togglePestelItem('is_legal')">L</div>
          </div>
          <!-- required to turn off because segments are not yet working -->
          <form-group v-if="proofPoint.analysis_type === 'industry_forces'" :label="'Choose a segment'">
            <dropdown
              v-model="proofPoint.segment"
              :allow-clear="true"
              is-simplified
              :options="segmentOptions"
            />
          </form-group>
          <br>
          <div>
            <form-group :label="$t('signal_create_type')" required>
              <dropdown
                v-model="proofPoint.type"
                is-simplified
                :allow-clear="false"
                :options="proofPointTypeOptions"
              />
            </form-group>
            <br>
            <form-group style="min-width: 300px" :label="$t('signal_create_argumentation')" required>
              <ds-textarea
                placeholder="Add some information about this proof point"
                is-simplified
                v-model="proofPoint.argumentation"
                class="proof-point__argumentation"
              />
            </form-group>
          </div>
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div style="width: 100%;">
        <ds-button
          variant="primary"
          @click="submit()"
          label="Create"
          size="extra-small"
          :disabled="!canSubmit"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import DsTextarea from '../Form/DsTextarea.vue'
  import AutocompleteDropdown from '../Dropdown/AutocompleteDropdown.vue'
  import RichTextArea from '../TextArea/RichTextArea.vue'
  import ImageInput from '../Form/ImageInput.vue'
  import Modal from './Modal.vue'
  import Dropdown from '../Dropdown/Dropdown.vue'
  import ToggleButton from '../Form/ToggleButton.vue'

  import MODAL_IDS from '../../constants/modal-ids.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import { ProofPoints } from '../../api/ProofPoints.js'
  import { ACTION_TYPES as SPOTTING_AREAS_ACTION_TYPES } from '../../store/modules/spottingareas.js'
  import { getSearchResultsForQuery } from '../../api/general-search.js'
  import { proofPointAnalysisTypes, proofPointTypes } from '../../constants/proofPoints.js'
  import { getSegments } from '../../api/spottingareas'

  export default {
    name: 'CreateSignalModal',
    data () {
      const modalContext = this.$store.state.ui.modalContext || {}
      return {
        modalId: MODAL_IDS.CREATE_PROOF_POINT,
        proofPoint: {
          argumentation: '',
          spotting_area_id: null,
          analysis_type: proofPointAnalysisTypes.KEY_TRENDS,
          type: proofPointTypes.PROOF_POINT,
          segment: null,
          is_political: false,
          is_economical: false,
          is_social: false,
          is_technological: false,
          is_environmental: false,
          is_legal: false
        },
        chosenSignalType: modalContext.chosenSignalType || null,
        actorOptions: [],
        segmentOptions: [],
        errors: {},
        fieldErrors: {},
        saving: false,
        highlight: modalContext.highlight
      }
    },
    computed: {
      showSearchContentField () {
        if ((this.$route.params.fileId != null && this.$route.params.viewType === 'analysis')
          || (this.$route.params.id != null && this.$route.name === 'actor-detail')) {
          return false
        }
        return true
      },
      proofPointTypeOptions () {
        return [
          {
            value: proofPointTypes.PROOF_POINT,
            label: this.$t('signal_type_proof_point')
          },
          {
            value: proofPointTypes.BLIND_SPOT,
            label: this.$t('signal_type_blind_spot')
          }
        ]
      },
      proofPointAnalysisTypeOptions () {
        return [
          {
            value: proofPointAnalysisTypes.KEY_TRENDS,
            label: this.$t('signal_analysis_type_key_trends')
          },
          {
            value: proofPointAnalysisTypes.INDUSTRY_FORCES,
            label: this.$t('signal_analysis_type_industry_forces')
          }
        ]
      },
      canSubmit () {
        return this.proofPoint.spotting_area_id && this.chosenSignalType && this.chosenSignalType.value && this.chosenSignalType.type && this.proofPoint.type && this.proofPoint.argumentation && !this.saving
      },
      availableSpottingAreas () {
        return this.$store.state.spottingAreas.listData.data.map(spottingArea => {
          return {
            value: spottingArea.id,
            label: spottingArea.name
          }
        })
      }
    },
    methods: {
      togglePestelItem (value) {
        this.proofPoint[value] = !this.proofPoint[value]
      },
      updateActorOptions (query) {
        return getSearchResultsForQuery(query)
          .then(response => {
            const allSignalableItems = [...response.actors.slice(10), ...response.articles.slice(10), ...response.articles_fuzzy.slice(10)]

            return allSignalableItems.map(item => {
              return {
                label: item.result.title,
                value: item.result.id,
                context: { type: item.result.type }
              }
            })
          })
          .catch(error => {
            console.log(error)
          })
      },
      updateActorForProofPoint (selectedSignalableItem) {
        this.chosenSignalType = {
          value: selectedSignalableItem.value,
          label: selectedSignalableItem.label,
          type: selectedSignalableItem.context.type
        }
      },
      submit () {
        this.saving = true
        this.fieldErrors = null
        this.errors = null

        const proofPointPostBody = {
          ...this.proofPoint
        }

        proofPointPostBody.signal_item_type = this.chosenSignalType.type
        proofPointPostBody.signal_item_id = this.chosenSignalType.value
        proofPointPostBody.highlight = this.highlight
        if (this.proofPoint.segment != null) {
          proofPointPostBody.segment_id = this.proofPoint.segment.value
        }
        ProofPoints
          .post(proofPointPostBody)
          .then(response => {
            this.$bus.emit('proofPointCreated')

            this.close()
          })
          .catch(errors => {
            this.saving = false
            this.fieldErrors = errors
            this.errors = Object.keys(errors).filter(key => key !== 'statusCode')
          })
      },
      close () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.CREATE_PROOF_POINT)
      }
    },
    mounted () {
      if (this.$route.params.spottingAreaId && !this.proofPoint.id) {
        this.proofPoint.spotting_area_id = Number(this.$route.params.spottingAreaId)
      }
      if (!this.$store.state.spottingAreas.listData.loading && !this.availableSpottingAreas.length) {
        this.$store.dispatch(SPOTTING_AREAS_ACTION_TYPES.FETCH_SPOTTING_AREAS_LIST)
      }
    },
    watch: {
      'proofPoint.spotting_area_id' () {
        if (this.proofPoint.spotting_area_id != null) {
          this.segmentOptions = []
          this.proofPoint.segment = null
          getSegments({ spottingAreaId: this.proofPoint.spotting_area_id })
            .then(res => {
              const result = []
              res.forEach(segment => {
                const seg = {
                  label: segment.name,
                  value: segment.id
                }
                if (!result.includes(seg)) {
                  result.push(seg)
                }
              })
              this.segmentOptions = result
            })
        }
      }
    },
    mixins: [TranslationsMixin],
    components: {
      AutocompleteDropdown,
      ToggleButton,
      DsTextarea,
      RichTextArea,
      ImageInput,
      Modal,
      Dropdown
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/_variables.scss";

  .proof-point__title-container {
    background: var(--primary-extra-lightest);
    padding: 20px;

    :deep(input) {
      border: 0;
      border-radius: $default-border-radius-narrow;

      &::placeholder {
        color: var(--primary);
      }
    }
  }

  .proof-point__content-container {
    padding: 20px 20px 17px 20px;

    :deep(input) {
      border: 1;
      border-radius: $default-border-radius-narrow;
      border-color: var(--primary-extra-lightest) !important;

      &::placeholder {
        color: var(--primary);
      }
    }
  }

  .modal__footer {
    width: 100%;

    .button {
      width: 100%;
      border-radius: $default-border-radius-narrow;
    }
  }

  .d-flex {
    display: flex;
  }

  .form-group {
    margin-bottom: 0px;
  }

  .mandatory-message {
    padding-bottom: 0;
  }

  .create-proof-point__pestel-container {
    display: flex;
    justify-content: space-between;

    .create-proof-point__pestel-item {
      border-radius: 50%;
      width: 50px;
      height: 50px;
      font-size: 22px;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-text-grey;
      border: 4px solid $color-borders;
      cursor: pointer;

      &:first-child {
        &:hover {
          border-color: $pestel-color-political;
        }

        &.selected {
          border-color: $pestel-color-political;
          background-color: $pestel-color-political;
          color: white;
        }
      }

      &:nth-child(2) {
        &:hover {
          border-color: $pestel-color-economical;
        }

        &.selected {
          border-color: $pestel-color-economical;
          background-color: $pestel-color-economical;
          color: white;
        }
      }

      &:nth-child(3) {
        &:hover {
          border-color: $pestel-color-social;
        }

        &.selected {
          border-color: $pestel-color-social;
          background-color: $pestel-color-social;
          color: white;
        }
      }

      &:nth-child(4) {
        &:hover {
          border-color: $pestel-color-technological;
        }

        &.selected {
          border-color: $pestel-color-technological;
          background-color: $pestel-color-technological;
          color: white;
        }
      }

      &:nth-child(5) {
        &:hover {
          border-color: $pestel-color-environmental;
        }

        &.selected {
          border-color: $pestel-color-environmental;
          background-color: $pestel-color-environmental;
          color: white;
        }
      }

      &:nth-child(6) {
        &:hover {
          border-color: $pestel-color-legal;
        }

        &.selected {
          border-color: $pestel-color-legal;
          background-color: $pestel-color-legal;
          color: white;
        }
      }
    }
  }

  :deep(.multiselect__content) {
    max-height: 200px;
  }

  :deep(.multiselect.multiselect--datascouts) {
    margin-right: 0;
  }
</style>
