<template>
  <transition name="modal">
    <div class="modal__mask" @click="blur">
      <div class="modal__wrapper">
        <div class="modal__container" :class="classes" @click.stop>
          <div class="modal__header">
            <h4 class="title">{{legendProperty}}</h4>
            <hr class="divider">
            <button class="close" @click="close" v-if="isCloseable">×</button>
          </div>
          <div class="modal__body legend-bar__alternative more_legends_modal_body">
            <div class="legend-bar__legends-container">
              <div class="legend-bar__item more_legends_modal_item" v-for="(item,index) in legendItems">
                <template v-if="item == 'Other'">
                  <div class="legend-bar__item-color" :style="{ 'background-color': 'grey' || '#808080'}"></div>
                  <div class="legend-bar__item-name">{{item}}</div>
                </template>
                <template v-else>
                  <div class="legend-bar__item-color" :style="{ 'background-color': legendColor(item)}"></div>
                  <div class="legend-bar__item-name">{{item}}</div>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'

  import KEYCODES from '../../constants/keycodes'
  import MODAL_IDS from '../../constants/modal-ids'

  import FiltersMixin from '../../util/FiltersMixin'

  export default {
    props: {
      closeOnBlur: Boolean,
      id: {
        type: String
      },
      isCloseable: {
        type: Boolean,
        default: true
      },
      title: {
        type: String,
        default: ''
      },
      customTitle: {
        type: Boolean,
        default: false
      },
      enableMask: {
        type: Boolean,
        default: true
      },
      size: {
        type: String,
        default: '',
        validator: val => ['large', ''].includes(val)
      }
    },
    created () {
      window.addEventListener('keyup', this.keyUpHandler)
    },
    methods: {
      keyUpHandler (e) {
        if (e.keyCode === KEYCODES.ESC) {
          this.close()
        }
      },
      blur () {
        if (this.closeOnBlur) {
          this.close()
        }
      },
      close () {
        if (this.isCloseable) {
          this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.MORE_LEGENDS)
          this.$emit('close')
        }
      }
    },
    computed: {
      classes () {
        return {
          [`modal__container--${this.size}`]: this.size,
        }
      }
    },
    mounted () {
      document.body.className = document.body.className + ' modal--open'
    },
    unmounted () {
      document.body.className = (document.body.className || '').replace('modal--open', '')
      window.removeEventListener('keyup', this.keyUpHandler)
    },
    mixins: [FiltersMixin],
    watch: {
      legendProperty (){
        this.setLegendColors
      }
    },
  }
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";
.modal--scrollable {
  display: block!important;
  padding: 100px 0;
  overflow: auto;
  bottom: 0;
  >.modal__wrapper {
    display: block;
    margin: 0 auto;
  }
}
.modal--open .main-view,
.modal--open .main-view .actor-detail {
  overflow: hidden
}

.modal__body {
    .keyword {
        background-color:$color-background-light-grey;
        .keyword__label {
            color:$color-background-grey;
        }
    }
    .keyword::before {
        display:none;
    }
    .keyword:first-child {
        margin-top:10px;
    }
}
.more_legends_modal_body {
  padding:0px !important;
  border-bottom:none !important;
  .legend-bar__legends-container {
    margin-left: 0px !important;
    overflow:visible !important;
    max-height:auto !important;
  }
}
.more_legends_modal_item {
  width:115px !important;
}

</style>
