<template>
  <div>
    <h3 class="h3" style="margin-bottom: 1em;">Invoices overview</h3>

    <template v-if="invoices.length === 0">
      <div v-if="!loading">
        You have no invoices yet.
      </div>
      <Loading v-else/>
    </template>
    <template v-else-if="loading">
      <Loading/>
    </template>
    <template v-else>
      <div class="user-invoices__container">
        <table class="table user-invoices__table">
          <thead>
          <tr>
            <td>
              Issue date
            </td>
            <td>
              Payment
            </td>
            <td>
              Description
            </td>
            <td>
              Price
            </td>
            <td>
              Status
            </td>
            <td>
            </td>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(invoice, index) in invoices" :key="'invoice_' + index">
            <td>
              {{invoice.date}}
            </td>
            <td>
              {{invoice.card_last_four ? ('**** ' + invoice.card_last_four) : ''}}
            </td>
            <td>
              {{ description(invoice) }}
            </td>
            <td>
              {{invoice.total}}
            </td>
            <td>
              {{invoice.status}}
            </td>
            <td>
              <ds-button
                style="line-height: 16px;"
                label="download"
                size="extra-small"
                @click="downloadInvoice(invoice.id)"
                v-if="invoice.id"
              />
              <ds-button
                style="line-height: 16px;"
                label="download"
                size="extra-small"
                @click="downloadPdf(invoice.pdf)"
                v-else-if="invoice.pdf"
                />
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
  import { getInvoices } from '../../../api/user'
  import Loading from '../../Dashboard/ConceptMap/Loading.vue'

  export default {
    name: 'UserInvoices.vue',
    data () {
      return {
        invoices: [],
        loading: false,
      }
    },
    methods: {
      getUserInvoices () {
        this.loading = true

        getInvoices()
          .then(response => {
            this.loading = false
            this.invoices = response
          })
          .catch(e => {
            this.loading = false
            console.log(e)
          })
      },
      downloadInvoice (invoiceId) {
        window.open('/api/profile/invoices/' + invoiceId, '_blank')
      },
      downloadPdf (pdf) {
        window.open(pdf, '_blank')
      },
      description (invoice) {
        if (!invoice.description) {
          return invoice.subscription
        }

        return invoice.description
      }
    },
    mounted () {
      this.getUserInvoices()
    },
    components: {
      Loading,
    },
  }
</script>

<style scoped lang="scss">
  .user-invoices__container {
    max-width: fit-content;
    min-width: 800px;
  }

  .user-invoices__table {
    th,
    td,
    thead th,
    tbody + tbody {
      border: 0;
    }

    font-size: 14px;
  }

  .user-invoices__invoice-item {
    display: flex;
    flex-direction: row;
    margin-top: 0.2em;
    justify-content: space-between;
  }

  .user-invoices__invoice-download-btn {
    margin-left: 1em;
  }
</style>
