<template>
  <modal
    ref="modal"
    title="Content Digest"
    :id="modalId"
    @close="$emit('close')"
  >
    <template v-slot:body>
      <div>
        You have enabled the content digest email. To get personalized content, start following concept searches.
        While you're not following any concept searches, the content of this email will be the same as the ecosystem
        digest.
      </div>
    </template>
    <template v-slot:footer>
      <div>
        <ds-button label="OK" variant="primary" @click="close"/>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import MODAL_IDS from '../../constants/modal-ids'

  export default {
    methods: {
      close () {
        this.$refs.modal.close()
      }
    },
    computed: {
      modalId() {
        return MODAL_IDS.CONTENT_DIGEST_INFO
      },
    },
    components: {
      Modal,
    }
  }
</script>
