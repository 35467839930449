<template>
  <div class="spotting-area__actor-suggestions">
    <Container style="flex-grow: 1">
      <div
          style="display: flex; justify-content: space-between; align-items:center; column-gap: 10px; margin-top: 1rem;">
        <h2>{{ $t('suggested_actors').toUpperCase() }} <span v-if="!fetchingContent">({{
            actorSuggestions.length
          }})</span></h2>
        <div
            v-if="!fetchingContent"
        >
          <DsButton
              type="button"
              @click="reloadSuggestions"
              title="Reload suggestions"
              class="button"
              icon="sync"
          />
        </div>
      </div>

      <div class="spotting-area__actor-suggestions" style="padding-top: 10px;">
        <template v-if="fetchingContent">
          <div class="row analysis-card-list">
            <div v-for="skeleton in [0,1,2]" class="col-sm-12 col-xs-12 col-md-12 col-lg-4">
              <skeleton-card :wrapper-class="'analysis-card__skeleton'"/>
            </div>
          </div>
        </template>
        <template v-else-if="slicedActorSuggestions && slicedActorSuggestions.length > 0">
          <div class="row analysis-card-list">
            <div v-for="actor in slicedActorSuggestions" class="col-xs-12 col-sm-6 col-md-6 col-lg-4">
              <actor-suggestion-card
                  :class="{'analysis-card': true}"
                  :spotting-area-id="spottingAreaId"
                  :actor="actor"
                  @disabled="disabledButtonWhenApproveOrDenied"
                  :processing-suggestion="processingSuggestion"
              />
            </div>
          </div>
        </template>
        <template v-else>
          <div style="display: flex; justify-content: center; margin-top: 1rem;">
            No results found
          </div>
        </template>
      </div>
      <div
          class="show-more-text" @click="showMoreForActors = !showMoreForActors"
          v-if="actorSuggestions.length > 3"
      >
        {{
          showMoreForActors ? $t('knowledge_sharing_read_less') : $t('knowledge_sharing_read_more')
        }}
      </div>
    </Container>
  </div>
</template>

<script>
import Avatar from '../Avatar/Avatar.vue'
import Container from '../../components/Container/Container.vue'
import SkeletonCard from '../../components/Skeleton/SkeletonCard.vue'
import Loading from '../Dashboard/ConceptMap/Loading.vue'
import { fetchActorSuggestions } from '../../api/spottingareas.js'
import ActorSuggestionCard from './ActorSuggestionCard.vue'
import { trackHeapEvent } from '../../util/analytics.js'
import { defineComponent } from 'vue'

export default defineComponent({
  components: { Loading, Container, Avatar, ActorSuggestionCard, SkeletonCard },
  props: {
    spottingAreaId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      actorSuggestions: [],
      fetchingContent: false,
      showMoreForActors: false,
      processingSuggestion: false,
    }
  },
  computed: {
    slicedActorSuggestions() {
      if (this.showMoreForActors) {
        return this.actorSuggestions
      }

      return this.actorSuggestions.slice(0, 3)
    },
  },
  methods: {
    disabledButtonWhenApproveOrDenied(disabled) {
      this.processingSuggestion = disabled
    },
    onClickActorCard(suggestion) {
      if (suggestion.actor && suggestion.actor.elastic_id) {
        this.$router.push('/actors/' + suggestion.actor.elastic_id)
      }
    },
    reloadSuggestions() {
      trackHeapEvent('spottingAreaDetail.reloadSuggestions')
      this.fetchActorSuggestions(true)
    },
    fetchActorSuggestions(bustCache = false) {
      this.fetchingContent = true

      fetchActorSuggestions({ spottingAreaId: this.spottingAreaId, bustCache: bustCache })
          .then(response => {
            if (response) {
              this.actorSuggestions = response
            }
          })
          .finally(() => {
            this.fetchingContent = false
            this.actorSuggestions.loading = false
          })
    },
  },
  mounted() {
    this.fetchActorSuggestions()
  },
})
</script>

<style lang="scss" scoped>
@import "../../../scss/variables.scss";

.analysis-card-list {
  margin-bottom: 0;

  .analysis-card {
    border: 1px solid $color-borders;
    border-radius: $default-border-radius-narrow;
    position: relative;
    cursor: pointer;
    margin-bottom: 1rem;
    word-break: break-word;
    height: calc(100% - 1rem);

    .card__header {
      padding: 0 20px 20px 20px;
      display: flex;
      justify-content: flex-start;
      flex-direction: row;

      .card__header-logo {

      }

      .card__header-text {
        display: flex;
        flex: 1;
        flex-direction: column;
      }
    }

    .analysis-card__text {
      $font-size: 12px;
      $line-height: 18px;
      $max-lines: 4;

      font-size: $font-size;
      line-height: $line-height;
      height: $max-lines*$line-height;
      max-height: unset;
      display: -webkit-box;
      width: 100%;
      -webkit-line-clamp: $max-lines;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-bottom: 0;
      margin-bottom: 20px;
    }

    .analysis-card-eye {
      position: absolute;
      top: 10px;
      right: 10px;
    }

    .analysis-card-overlay {
      display: none;
      font-size: 14px;
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: $color-background-lightest-grey;
      justify-content: center;
      align-items: center;
      border-radius: $default-border-radius-narrow;
    }

    &:hover {
      .analysis-card-overlay {
        display: flex;
      }
    }

    .content__meta {
      padding: 0 20px;
      height: 23px;
      margin-bottom: 20px;
      overflow: hidden;

      .tag {
        background-color: var(--primary-community-lightest);
        border-color: var(--primary-community-lightest);
        border-radius: 0.5rem;
        border-bottom: 20px;
      }
    }

    .analysis-card-icon {
      position: absolute;
      top: 5px;
      right: 5px;

      &.suggestion-success {
        > .fill-fg {
          fill: green !important;
        }
      }

      &.suggestion-denied {
        > .fill-fg {
          fill: orange !important;
        }
      }
    }

    .analysis-card-footer-actions {
      display: flex;
      position: absolute;
      bottom: 5px;
      right: 12px;
      color: #CECECE !important;

      &--item {
        padding-left: 10px;
        position: relative;

        .button {
          font-size: 16px;
          border-radius: 25px;
          color: #0D0D0D !important;
          background-color: transparent;

          &:hover {
            color: var(--primary);
            background-color: #F2F2F2;
          }
        }

        &:first-child {
          margin-left: 0px;

          &:after {
            font-size: 18px;
            cursor: default;
            left: calc(100% + 2px);
            top: 23%;
            content: '|';
            width: 100%;
            height: 100%;
            position: absolute;
            // left: 25px;
          }
        }
      }
    }

    .extracted-actor {
      text-decoration: none;
      color: black;

      &.clickable {
        text-decoration: underline;
      }
    }

    h4.h4 {
      font-size: 14px;
      padding: 20px 20px 10px 20px;
      color: var(--primary);

      &.one-line {
        white-space: nowrap;
        text-overflow: ellipsis;
        height: 47px;
        line-height: 17px;
        overflow: hidden;
      }
    }

    h6.h6 {
      font-size: 11px;
      padding: 5px 20px 5px 20px;
    }

    p {
      font-size: 11px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      line-height: 14px;
      max-height: 56px;
      overflow: hidden;
    }
  }
}

.show-more-text {
  text-decoration: underline;
  display: inline-block;
  cursor: pointer;
  font-size: 13px;
  margin-bottom: 1rem;
}
</style>
