<template>
  <div>
    <vue-timepicker
      v-model="model"
      :placeholder="placeholder"
      format="HH:mm"
      :minute-interval="5"
      input-class="ds-input is-simplified"
      close-on-complete
      auto-scroll
      drop-direction="down"
    />
  </div>
</template>

<script>
  import VueTimepicker from 'vue3-timepicker'

  export default {
    props: {
      modelValue: {},
      placeholder: '',
    },
    emits: ['update:modelValue'],
    computed: {
      model: {
        get () {
          return this.modelValue
        },
        set (value) {
          this.$emit('update:modelValue', value)
        },
      }
    },
    components: {
      VueTimepicker
    }
  }
</script>

<style lang="scss">
  @import "../../../scss/variables";

  span.vue__time-picker {
    width: auto;
  }

  .vue__time-picker input.vue__time-picker-input.is-simplified {
    border-radius: $simplified-input-border-radius;
    border-color: $simplified-input-border-color;
    height: 36px;
    display: block;
    width: 100%;
    outline: 0;
    font-size: 14px;
    line-height: 36px;
    padding: 0 10px;
  }

  .vue__time-picker .dropdown.drop-down ul li:not([disabled]).active {
    background: var(--primary-community);

    &:hover {
      background: var(--primary-community-light);
    }
  }
</style>
