<template>
  <div class="sidepanel__merge-options actor-suggestions">
    <div class="fixed-heading">
      <div class="card__general side-panel__header">
        <div class="card__title side-panel__actor-suggestions-card__title side-panel__actor-suggestions-header-card__title">Merge</div>
        <div class="pull-right side-panel__announcement-header-button-container" style="text-align: right;">
          <ds-button icon="remove" variant="minimal" @click="hideSidePanel" size="small" class="side-panel__announcement-header-icon"/>
        </div>
      </div>
    </div>
    <div class="scrollable side-panel__scrollable__content has-padding" style="padding-bottom: 150px">
      <template v-if="isLoading">
        Fetching actor details...
      </template>
      <template v-else>
        <div class="form-group__error" v-if="Object.keys(errors).length" style="font-size: 14px">
          There {{ Object.keys(errors).length === 1 ? 'was one error' : 'were ' + Object.keys(errors).length + ' errors' }} when saving:
          <div v-for="error in errors">
            {{ Array.isArray(error) ? error[0] : error }}
          </div>
        </div>

        <template v-if="! mergeComplete">
          <p style="margin-bottom: 0.5rem;">We will merge all fields, files and relationships into <span class="current-actor">"{{ actor.name }}"</span> and remove <span class="duplicate-actor"><a style="color: #4040ff;" :href="'/actors/' + duplicateActor.id" target="_blank">"{{ duplicateActor.name }}"</a> ({{Math.round(duplicateActor.completeness * 100)}}% complete)</span>. </p>
          <p v-if="this.showConflictingFields">The following fields need your attention:</p>

          <div class="collapsable-tab" v-for="tab in tabs" :class="{'collapsable-tab-open-state' : activeTab == tab}">
            <table class="collapsable-tab__tab-content-container" :class="{'collapsable-tab__tab-content-container-open-state' : activeTab == tab}" @click="setActiveTab(tab)">
              <tr>
                <td class="collapsable-tab__title">{{ getTabTitle(tab) }}</td>
                <td class="collapsable-tab__toggle-button">
                  <icon :name="activeTab == tab ? 'chevron-up' : 'chevron-down'"/>
                </td>
              </tr>
            </table>

            <!-- Resolve conflicting fields -->
            <div class="collapsable-tab__content-container" v-if="activeTab == tab && tab == 'Conflicting Fields'">
              <template v-for="(conflictingField, index) in conflictingFields">
                <!-- For now don't list the automerge fields, they can be merged automatically -->
                <form-group :label="humanize(conflictingField.property)" v-if="conflictChoices[conflictingField.property] != 'merge'">
                  <div>
                    <checkbox type="radio" variant="square" class="current-actor" :multiple="false" name="original-choice" val="original" :model-value="conflictChoices[conflictingField.property]" @update:modelValue="changeConflictChoice(conflictingField.property, 'original')" :label="createChoiceLabel(actor,conflictingField.property)" :href="createChoiceLabel(actor,conflictingField.property)" />
                    <checkbox type="radio" variant="square" class="duplicate-actor" :multiple="false" name="duplicate-choice" val="duplicate" :model-value="conflictChoices[conflictingField.property]" @update:modelValue="changeConflictChoice(conflictingField.property, 'duplicate')" :label="createChoiceLabel(duplicateActor,conflictingField.property)" :href="createChoiceLabel(actor,conflictingField.property)" />
                  </div>
                </form-group>
              </template>
            </div>

            <!-- Resolve report fields -->
            <div class="collapsable-tab__content-container" v-if="activeTab == tab && tab == 'Conflicting Reports'">
              <template v-for="(conflictingReport, index) in conflictingReports">
                <!-- For now don't list the automerge fields, they can be merged automatically -->
                <form-group :label="humanize(conflictingReport.label)" v-if="conflictingReportChoices[conflictingReport.identifier] != 'merge'">
                  <div>
                    <checkbox type="radio" variant="square" class="current-actor" :multiple="false" name="original-choice" val="original" :model-value="conflictingReportChoices[conflictingReport.identifier]" @update:modelValue="changeReportConflictChoice(conflictingReport.identifier, 'original')" :label="conflictingReport.originalValue" />
                    <checkbox type="radio" variant="square" class="duplicate-actor" :multiple="false" name="duplicate-choice" val="duplicate" :model-value="conflictingReportChoices[conflictingReport.identifier]" @update:modelValue="changeReportConflictChoice(conflictingReport.identifier, 'duplicate')" :label="conflictingReport.duplicateValue" />
                  </div>
                </form-group>
              </template>
            </div>
          </div>

          <div style="margin-top: 20px;">
            <ds-button label="Complete merge" variant="secondary" size="extra-small" @click="mergeActor()" v-if="! isSaving" />
            <ds-button icon="spinner" label="Complete merge" variant="secondary" size="extra-small" v-if="isSaving" />
            <ds-button label="Go back" variant="secondary" size="extra-small" @click="goToDuplicates()" />
          </div>
        </template>

        <template v-else>
          <p class="form-group__label__text" style="margin-bottom: 0.5rem;" v-if="mergeStatus == 'complete'">We have succesfully merged both actors!</p>
          <p class="form-group__label__text" style="margin-bottom: 0.5rem;" v-else="mergeStatus == 'errors'">It looks like there were some problems while merging both actors. Please review these manually.</p>
          <ds-button label="Other duplicates" variant="secondary" size="extra-small" @click="goToDuplicates()" v-if="actorHasDuplicatesAfterMerge" />
        </template>
      </template>
    </div>
  </div>
</template>

<script>
  import NewCard from '../../components/NewCard/NewCard.vue'
  import RadioButton from '../../components/Form/RadioButton.vue'
  import DsButton from '../../components/DsButton/DsButton.vue'
  import Checkbox from '../../components/Form/Checkbox.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { ACTION_TYPES as ACTOR_ACTION_TYPES } from '../../store/modules/actors'

  import KEYCODES from '../../constants/keycodes'

  import { updateActor, fetchActor, removeActor } from '../../api/actors'
  import { copyFiles } from '../../api/files'
  import { reconcileableProperties, humanize } from '../../constants/properties.js'
  import { toAmount } from '../../util/currency'
  import { inert } from '../../util/helpers'
  import { getReportFieldsByTypes, getReportFieldByReportIdentifier } from '../../util/helpers.js'
  import _groupBy from 'lodash/groupBy';

  export default {
    props: {
      duplicateActorId: String,
      meta: Object,
    },
    data () {
      return {
        duplicateActor: {},
        isLoading: true,
        isSaving: false,
        conflictingFields: [],
        conflictChoices: {},
        conflictingReports: [],
        conflictingReportChoices: {},
        actorReports: {},
        duplicateActorReports: {},
        mergeableFiles: [],
        mergeComplete: false,
        actorHasDuplicatesAfterMerge: false,
        errors: {},
        activeTab: '',
        // Fields that need special formatting when listing them in the conflicting fields section
        fieldsWithSpecialFormatting: [
          'activities',
          'description',
          'domains',
          'funding_rounds',
          'industries',
          'technology',
        ]
      };
    },
    computed: {
      ecosystemRelationships () {
        return this.$store.getters.fullActorRelationships
      },
      actor () {
        return this.$store.state.actors.detail.data;
      },
      tabs () {
        var tabs = [];

        if (this.showConflictingFields) {
          tabs.push('Conflicting Fields');
        }

        if (this.showConflictingReports) {
          tabs.push('Conflicting Reports');
        }

        return tabs;
      },
      showConflictingFields () {
        // For now we don't include conflicts where we can just merge the values
        return Object.keys(this.conflictChoices).filter(field => this.conflictChoices[field] != 'merge').length > 0;
      },
      conflictingFieldCount () {
        return Object.keys(this.conflictChoices).filter(field => this.conflictChoices[field] != 'merge').length;
      },
      showConflictingReports () {
        return this.conflictingReports.length > 0;
      },
      conflictingReportCount () {
        return this.conflictingReports.length;
      },
      isReportingEnabled () {
        return this.$store.getters.reportsEnabled;
      },
      reportLabel () {
        return 'Reports'
      },
    },
    methods: {
      humanize,
      goToDuplicates () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component: 'actor-duplicates-panel', metaData: this.meta});
      },
      setActiveTab (tab) {
        if (this.activeTab == tab) {
          this.activeTab = '';
        } else {
          this.activeTab = tab;
        }
      },
      getTabTitle (tab) {
        if (tab == 'Conflicting Fields') {
          return 'Conflicting Fields (' +  this.conflictingFieldCount + ')';
        }

        if (tab == 'Conflicting Reports') {
          return 'Conflicting ' + this.reportLabel + ' (' + this.conflictingReportCount + ')'
        }

        return tab;
      },
      hideSidePanel (evt) {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      getEmployeesValue (actor) {
        var employees = actor.current_employees ? actor.current_employees[0] : null

        if (! employees && ! actor['employees']) {
          return
        }

        if (! employees) {
          employees = actor['employees'][actor['employees'].length - 1]
        }

        if (employees.lower_bound == employees.upper_bound) {
          return employees.lower_bound + ''
        }

        return employees.lower_bound + ' - ' + employees.upper_bound
      },
      createChoiceLabel (actor, property) {
        var value = actor[property]

        // Make a display string for employees
        if (property == 'employees') {
          return this.getEmployeesValue(actor)
        }

        if (this.fieldsWithSpecialFormatting.includes(property)) {
          // Multi values are handled by providing a list of the original/duplicate values
          // If we return null, then the label will read "original" or "duplicate", which is intended behaviour
          return
        }

        // Make a display string for address
        if (property == 'address') {
          var addressParts = []

          if (value.street) {
            addressParts.push(value.street + ' ' + (value.number ? value.number : ''))
          }

          if (value.zip || value.city) {
            addressParts.push((value.zip ? value.zip : '') + ' ' + (value.city ? value.city : ''))
          }

          if (value.country) {
            addressParts.push(value.country)
          }

          return addressParts.join(', ')
        }

        if (Array.isArray(value) && value[0].label) {
          return value.map(entry => entry.label).join(', ')
        }

        if (Array.isArray(value)) {
          return value.join(', ')
        }

        if (property == 'total_funding') {
          return '€' + toAmount(value)
        }

        if (property === 'exit_round') {
          const result = []
          if (value.started_at) {
            result.push(`started at ${value.started_at}`)
          }
          if (value.type) {
            result.push(`type: ${value.type}`)
          }
          if (value.capital_raised) {
            result.push(`capital raised: ${value.capital_raised}`)
          }
          if (value.original_capital_raised) {
            result.push(`original capital raised: ${value.original_capital_raised}`)
          }
          if (value.original_capital_raised) {
            result.push(`original capital raised: ${value.original_capital_raised}`)
          }
          if (value.currency) {
            result.push(`currency: ${value.currency}`)
          }
          if (value.original_currency) {
            result.push(`original currency: ${value.original_currency}`)
          }
          if (result.length > 0) {
            return result.join(', ')
          }
        }

        if (value.value && value.label) {
          return value.label
        }

        return value
      },
      getValueForChoice(actor, property) {
        // Return a list of more complex values in the actor property
        // i.e. industries, funding rounds, ...
        // Tags for example are quite simple and can be displayed as the label of the radio button and don't need to be handled in this function
        if (! this.fieldsWithSpecialFormatting.includes(property)) {
          return
        }

        var value = actor[property];

        if (property == 'description') {
          return actor['description_html'];
        }

        if (property == 'funding_rounds') {
          var rounds = [];

          value.forEach(item => {
            var amount = '';

            if (item.capital_raised) {
              amount = '€' + toAmount(item.capital_raised);
            }

            rounds.push(amount + ' ' + item.type + ', ' + item.started_at);
          });

          return rounds;
        }

        if (['domains'].includes(property)) {
          return value.map(domain => domain.name);
        }

        // Make a display string for industries
        if (property == 'industries' && actor['displayIndustries']) {
          var displayIndustries = actor['displayIndustries'];

          var allIndustryNames = [];

          displayIndustries.forEach(industry => {
            allIndustryNames.push(industry.name);

            if (industry.subindustries && industry.subIndustries.length > 0) {
              industry.subIndustries.forEach(subIndustry => {
                allIndustryNames.push(subIndustry.name);
              });
            }
          });

          return allIndustryNames;
        }

        if (Array.isArray(value)) {
          return value;
        }
      },
      getMergeableFiles () {
        // Return the files that are available to be merged, identical files will be left out
        if (! this.duplicateActor.files || this.duplicateActor.files.length == 0) {
          return [];
        }

        var files = [];

        // If the original actor does not have any files, then we can let the user choose all the files of the duplicate
        if (! this.actor.files || this.actor.files.length == 0) {
          return this.duplicateActor.files;
        }

        // Filter out same links, other uploads where the name is the same can be merged as we don't know if it's really the same file just by looking at the name
        this.duplicateActor.files.forEach(file => {
          if (file.mimetype != 'video_link' && file.mimetype != 'link') {
            files.push(file.id);
          } else {
            var exists = false;

            this.actor.files.forEach(originalFile => {
              if (file.originalName == originalFile.originalName) {
                exists = true;
              }
            });

            if (! exists) {
              files.push(file.id);
            }
          }
        });

        return files;
      },
      getMergeableRelationships () {
        // Return the connections that are available to be merged, leave out common relationships and subsidiaries
        var connections = [];

        this.ecosystemRelationships.forEach(relationship => {
          // Gather all of the IDs of the connected actors and if the actor does not contain any related ID and it's not the ID of the actor itself, it can be chosen to be merged
          var relationshipName = relationship.name;

          if (relationshipName == 'subsidiaries' || relationshipName == 'main_company') {
            return;
          }

          if (!this.duplicateActor[relationshipName] || this.duplicateActor[relationshipName].length == 0) {
            return;
          }

          // Gather all the ids that the current actor is related with
          var alreadyConnectedIds = (this.actor[relationshipName] || []).map(relatedActor => relatedActor.to);

          this.duplicateActor[relationshipName].forEach(relatedActor => {
            if (relatedActor.to && relatedActor.to != this.actor.id && ! alreadyConnectedIds.includes(relatedActor.to)) {
              if (! connections[relationshipName]) {
                connections[relationshipName] = [];
              }

              connections[relationshipName].push(relatedActor);
            }
          });
        });

        return connections;
      },
      changeConflictChoice (property, choice) {
        this.conflictChoices[property] = choice;
      },
      changeReportConflictChoice (property, choice) {
        this.conflictingReportChoices[property] = choice;
      },
      checkConflictingFields () {
        this.conflictingFields = []

        var isFundingRoundsConflicting = false

        reconcileableProperties.forEach(field => {
          var actorValueString = JSON.stringify(this.actor[field.property]) || ''
          var duplicateActorValueString = JSON.stringify(this.duplicateActor[field.property]) || ''

          var actorsHaveDifferentValue = actorValueString.toLowerCase() != duplicateActorValueString.toLowerCase()

          if (field.property == 'employees') {
            actorsHaveDifferentValue = this.getEmployeesValue(this.actor) != this.getEmployeesValue(this.duplicateActor)
          }

          if (
            this.doesFieldHaveValue(this.actor, field.property) && this.doesFieldHaveValue(this.duplicateActor, field.property)
            && actorsHaveDifferentValue
          ) {
            field['originalValue'] = this.getValueForChoice(this.actor, field.property)
            field['duplicateValue'] = this.getValueForChoice(this.duplicateActor, field.property)

            if (field.property === 'funding_rounds') {
              isFundingRoundsConflicting = true;
            }

            // Default behaviour is 'merge' if possible, original otherwise
            var chosenOption = 'original';

            if (field.mergeable) {
              chosenOption = 'merge';
            }

            this.conflictingFields.push(field);
            this.conflictChoices[field.property] = chosenOption
          }
        });

        // Total funding can only be a conflict if there's no funding rounds
        if (isFundingRoundsConflicting) {
          this.conflictingFields = this.conflictingFields.filter(field => field.property !== 'total_funding');

          this.$delete(this.conflictChoices, 'total_funding');
        }
      },
      checkConflictingReports () {
        this.conflictingReports = [];

        this.actorReports = this.transformCurrentReport(this.actor.current_report);
        this.duplicateActorReports = this.transformCurrentReport(this.duplicateActor.current_report);

        getReportFieldsByTypes(['text', 'score', 'options', 'date', 'number']).forEach(reportField => {
          if (
            this.actorReports[reportField] && this.duplicateActorReports[reportField]
            && JSON.stringify(this.actorReports[reportField]) != JSON.stringify(this.duplicateActorReports[reportField])
          ) {
              var reportFieldInfo = getReportFieldByReportIdentifier(reportField);
              var field = {'id': reportField, label: reportFieldInfo.label, identifier: reportField};

              field['originalValue'] = this.actorReports[reportField]
              field['duplicateValue'] = this.duplicateActorReports[reportField];

              this.conflictingReports.push(field);
              this.conflictingReportChoices[reportField] = 'original'
          }
        });
      },
      transformCurrentReport (currentReport) {
        if (! currentReport || currentReport.length == 0) {
          return {};
        }

        var result = {};

        currentReport.forEach(report => {
          result['report_field_' + report.ecosystem_report_field_id] = report.value;
        });

        return result;
      },
      doesFieldHaveValue(actor, property) {
        var value = actor[property];

        if (property == 'address') {
          return value.zip || value.country || value.city
        }

        if (value) {
          if (! Array.isArray(value)) {
            return true;
          }

          return value.length > 0;
        }

        return value;
      },
      async mergeActor () {
        this.errors = {};
        this.isSaving = true;

        var updatedActor = await updateActor({id: this.actor.id, data: this.getActorChanges()}).catch(error => {
          if (Array.isArray(error)) {
            this.errors = error;
          } else {
            this.errors.push(error);
          }
        });

        var files = this.getMergeableFiles();

        if (files.length > 0) {
          await copyFiles(this.actor.id, files).catch(error => {
            if (Array.isArray(error)) {
              this.errors = error;
            } else {
              this.errors.push(error);
            }
          });
        }

        var relationships = this.getMergeableRelationships();

        if (Object.keys(relationships).length > 0) {
          // Update the relationships, one by one
          var changes = {};

          Object.keys(relationships).forEach(relationshipType => {
            const relation = [...this.actor[relationshipType]] || [];

            relationships[relationshipType].forEach(relatedActor => {
              relation.push({
                to: relatedActor.to,
                to_name: relatedActor.to_name,
                start: null,
                end: null
              });
            });

            changes[relationshipType] = relation;
          });

          await updateActor({
            id: this.actor.id,
            data: changes
          }).catch(error => {
            if (Array.isArray(error)) {
              this.errors = error;
            } else {
              this.errors.push(error);
            }
          });
        }

        if (this.isReportingEnabled) {
          var reportingChanges = this.getReportingChanges();

          if (reportingChanges.length > 0) {
            updatedActor = await updateActor({id: this.actor.id, data: { reports: reportingChanges}}).catch(error => {
              if (Array.isArray(error)) {
                this.errors = error;
              } else {
                this.errors.push(error);
              }
            });
          }
        }

        if (this.duplicateActor.offerings && this.duplicateActor.offerings.length > 0) {
          var offerings = this.duplicateActor.offerings.map(offering => {
            return {
              description: offering.description,
              name: offering.name,
              website: offering.website,
              pricing: offering.pricing,
              features: offering.features,
              type: offering.type,
              date: offering.data,
              jurisdiction: offering.jurisdiction,
              kind: offering.kind,
              publication_number: offering.publication_number,
              parties: offering.parties,
              end_date: offering.end_date,
            }
          });

          updatedActor = await updateActor({id: this.actor.id, data: { offerings: offerings}}).catch(error => {
            if (Array.isArray(error)) {
              this.errors = error;
            } else {
              this.errors.push(error);
            }
          });
        }

        // If there are no errors, just remove the actor
        if (Object.keys(this.errors) == 0) {
          await removeActor(this.duplicateActor.id, { hardDelete: 1 }).catch(error => {
            if (Array.isArray(error)) {
              this.errors = error;
            } else {
              this.errors.push(error);
            }
          });
        }

        this.isSaving = false;
        this.mergeComplete = true;

        // Update the actor detail
        this.$store.dispatch(ACTOR_ACTION_TYPES.FETCH_ACTOR_DETAIL, this.actor.id)

        if (Object.keys(this.errors) > 0) {
          this.mergeStatus = "errors";

          return;
        }

        this.mergeStatus = "complete";

        // If there are no errors, reload the actor list / map data in the state
        // The list of actors in the state is used to provide the "next" button with a
        // next actor in the list, however when that actor is the freshly removed one,
        // the user will get to see a "this has been removed" message
        if (Object.keys(this.errors) == 0) {
          var listFilters = inert(this.$store.getters.listFilterObject);
          this.$store.dispatch(ACTOR_ACTION_TYPES.FETCH_ACTORS_LIST, listFilters);

          const filters = this.$store.getters.mapFilterObject;

          if (filters.tl) {
            this.$store.dispatch(ACTOR_ACTION_TYPES.FETCH_ACTORS_MAP, filters);
          }
        }
      },
      getActorChanges () {
        var changes = {};

        // Update the original actor based on the conflict choices
        // Make sure to inherit the fields that have values in the duplicate, but not the original actor
        const conflictingFields = this.conflictingFields.map(field => field.property);

        // First get the values from the duplicate actor that are not filled in in the original actor
        // These values can be copied as they pose no conflict
        var autoMergeProperties = reconcileableProperties.filter(property => ! conflictingFields.includes(property.property));

        autoMergeProperties.forEach(propertyInfo => {
          if (! this.doesFieldHaveValue(this.actor, propertyInfo.property) && this.doesFieldHaveValue(this.duplicateActor, propertyInfo.property)) {
            changes[propertyInfo.property] = this.duplicateActor[propertyInfo.property];

            if (propertyInfo.property == 'industries') {
              changes['subIndustries'] = this.duplicateActor['subIndustries'];
            }
          }
        });

        // Then go through each conflict and based on the choice add the value to the original actor
        this.conflictingFields.forEach(conflictingField => {
          var property = conflictingField.property;
          var conflictChoice = this.conflictChoices[property];

          // Only if we don't keep the original value do we need to do something
          if (conflictChoice == 'merge' && conflictingField.mergeable) {
            changes[property] = this.mergeValues(this.actor[property], this.duplicateActor[property]);

            if (property == 'industries') {
              changes['subIndustries'] = this.mergeValues(this.actor['subIndustries'], this.duplicateActor['subIndustries']);
            }

          } else if (conflictChoice == 'duplicate') {
            changes[property] = this.duplicateActor[property];

            if (property == 'industries') {
              changes['subIndustries'] = this.duplicateActor['subIndustries'];
            }
          }
        });

        // Update the actor and force update (i.e. skip duplicate validation)
        changes['force_update'] = true;

        return changes;
      },
      getReportingChanges () {
        // Return the values that can be set to the original actor because it's empty and the duplicate provides a value
        var changes = [];

        // Update the original actor based on the conflict choices
        // Make sure to inherit the fields that have values in the duplicate, but not the original actor
        const conflictingFields = this.conflictingReports.map(field => field.identifier);

        // First get the values from the duplicate actor that are not filled in in the original actor
        // These values can be copied as they pose no conflict
        var autoMergeProperties = getReportFieldsByTypes(['text', 'score', 'options', 'date', 'number']).filter(reportField => ! conflictingFields.includes(reportField));

        autoMergeProperties.forEach(reportField => {
          if (! this.actorReports[reportField] && this.duplicateActorReports[reportField]) {
            var reportId = reportField.replace('report_field_', '');

            changes.push({
              ecosystem_report_field_id: reportId,
              value: this.duplicateActorReports[reportField]
            });
          }
        });

        // Then go through each conflict and based on the choice add the value to the original actor
        this.conflictingReports.forEach(conflictingField => {
          var reportField = conflictingField.identifier;
          var conflictChoice = this.conflictingReportChoices[reportField];

          // Only if we don't keep the original value do we need to do something
          if (conflictChoice == 'duplicate') {
            var reportId = reportField.replace('report_field_', '');

            changes.push({
              ecosystem_report_field_id: reportId,
              value: this.duplicateActorReports[reportField]
            });
          }
        });

        return changes;
      },
      mergeValues (val1, val2) {
        // Shouldn't occur, but it's just a safety, it could occur for example for subIndustries, because they're merged when industries are being merged
        // but that doesn't mean that both actors have subIndustries
        if (! val1 || (Array.isArray(val1) && val1.length == 0)) {
          return val2;
        }

        // Shouldn't occur, but it's just a safety
        if (! val2 || (Array.isArray(val2) && val2.length == 0)) {
          return val1;
        }

        // Safe to assume that if the value of a property is an array, than the other value is also an array
        // Values are normalised when they are fetched from the api
        if (Array.isArray(val1)) {
          return val1.concat(val2)
        }

        return val1 + " " + val2
      }
    },
    mounted () {
      fetchActor(this.duplicateActorId)
        .then(data => {
          this.duplicateActor = data

          this.isLoading = false
          this.checkConflictingFields()
          this.checkConflictingReports()
        })
        .catch(err => {
          this.isLoading = false
        })

      window.addEventListener('keyup', (e) => {
        if (e.keyCode === KEYCODES.ESC) {
          this.hideSidePanel()
        }
      });
    },
    watch: {
      actor (v) {
        if (! this.mergeComplete) {
          this.checkConflictingFields()
        }

        this.actorHasDuplicatesAfterMerge = this.actor.possible_duplicate
      },
      tabs (v) {
        if (! this.tabs.length) {
          return
        }

        this.activeTab = this.tabs[0]
      }
    },
    components: {
      NewCard,
      RadioButton,
      Checkbox,
      DsButton,
    }
  }
</script>

<style lang="scss">
  @import "../../../scss/variables";

  .sidepanel__merge-options {
    .form-group__label__text {
      font-size: 14px;
    }

    .current-actor {
      color: #208020;

      &.radio.radio--square input[type="radio"]:before {
        border-color: #208020;
      }

      &.radio.radio--square input[type="radio"]:checked:after {
        background-color: #208020 !important;
      }
    }

    .duplicate-actor {
      color: #4040ff !important;

      &.radio.radio--square input[type="radio"]:before {
        border-color: #4040ff;
      }

      &.radio.radio--square input[type="radio"]:checked:after {
        background-color: #4040ff !important;
      }
    }

    .form-group:last-child {
      margin-bottom: 0;
    }
  }
</style>
