<template>
  <div>
    <h2 class="h2">Business Relationships</h2>

    <h3 class="h3">Default relationships</h3>
    <div class="manage-relationships__list">
      <ul>
        <li
            v-for="(relationship, index) in defaultBusinessRelationships"
            :key="'business_' + index">
          <span
              @click="copyToClipboard(relationship.name)"
              v-tooltip.right="'Copy name to use in spreadsheets: ' + relationship.name">
              {{ relationship.label }} <icon name="copy" size="14"/>
          </span>
          /
          <span
              @click="copyToClipboard(relationship.inverse_name)"
              v-tooltip.right="'Copy name to use in spreadsheets: ' + relationship.inverse_name">
              {{ relationship.inverse_label }} <icon name="copy" size="14"/>
          </span>
        </li>
      </ul>
    </div>

    <br/>
    <h3 class="h3">Custom relationships
      <icon name="plus" style="margin-bottom: -5px; cursor: pointer"
            @click="showAddMore = !showAddMore;focusTagInput()"></icon>
    </h3>
    <div class="subtitle" style="margin-top: 0.5rem">List of custom relationships</div>
    <div class="manage-relationships__list">
      <div class="manage-relationships__list-item" v-show="showAddMore">
        <div class="manage-relationships__list-item-name hoverable selected">
          <input placeholder="provides liquidity" type="text" v-model="label"
                 ref="addMore" @keyup="handleCreateKeyup"> /
          <input placeholder="receives liquidity" type="text" v-model="inverseLabel"
                 @keyup="handleCreateKeyup">
          <div class="buttons">
            <icon v-if="label.length >= 2 && inverseLabel.length >= 2"
                  class="icon" name="check" @click="addRelationship"/>
          </div>
        </div>
      </div>
      <div class="manage-relationships__list-item"
           v-for="(relationship, index) in customBusinessRelationships"
           :key="'business_' + index">
        <div class="manage-relationships__list-item-name hoverable"
             :class="{ 'selected' : editingRelationship.id === relationship.id || deletingRelationshipId === relationship.id }">
          <div v-if="editingRelationship.id !== relationship.id">
            <span
                @click="copyToClipboard(relationship.name)"
                v-tooltip.right="'Copy name to use in spreadsheets: ' + relationship.name">
                {{ relationship.label }} <icon name="copy" size="14"/>
            </span>
            /
            <span
                @click="copyToClipboard(relationship.inverse_name)"
                v-tooltip.right="'Copy name to use in spreadsheets: ' + relationship.inverse_name">
              {{ relationship.inverse_label }} <icon name="copy" size="14"/>
            </span>
          </div>
          <div v-show="editingRelationship.id === relationship.id">
            <input type="text" v-model="editingRelationship.label"
                   @keyup="handleEditKeyup" :ref="'edit-custom' + relationship.id"> /
            <input type="text" @keyup="handleEditKeyup"
                   v-model="editingRelationship.inverse_label">
          </div>
          <div class="buttons">
            <icon v-if="!editingRelationship.id && !deletingRelationshipId" class="icon"
                  name="edit"
                  @click="setEditingRelationship(relationship)"/>
            <icon
                v-if="editingRelationship.id === relationship.id && !updatingRelationship && editingRelationship.inverse_label.length >= 2 && editingRelationship.label.length >= 2"
                class="icon" name="check" @click="updateRelationship"/>
            <icon
                v-if="(editingRelationship.id === relationship.id && updatingRelationship) || deletingRelationshipId === relationship.id"
                class="icon" name="spinner"/>
            <icon v-if="!editingRelationship.id && !deletingRelationshipId" class="icon"
                  name="trash"
                  @click="deleteRelationship(relationship)"></icon>
          </div>
          <!--<div style="margin-left: 15px;">For spreadsheet uploads use: {{ relationship.name + ' / ' + relationship.inverse_name }}</div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AutocompleteTagInput from '../../Form/AutocompleteTagInput.vue'
import TagsMixin from '../../../util/TagsMixin'
import Dropdown from '../../Dropdown/Dropdown.vue'

import { ACTION_TYPES as RELATIONSHIP_ACTION_TYPES } from '../../../store/modules/actor-relationships'
import {
  countRelationships,
  createRelationship,
  deleteRelationship,
  updateRelationship,
} from '../../../api/actor-relationships'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui.js'
import MODAL_IDS from '../../../constants/modal-ids.js'

import { copyToClipboard } from '../../../util/helpers.js'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ManageBusinessRelationships',
  data() {
    return {
      errors: [],
      label: '',
      inverseLabel: '',
      editingRelationship: {
        label: '',
        inverse_label: '',
      },
      updatingRelationship: false,
      submitting: false,
      showAddMore: false,
      deletingRelationshipId: null,
    }
  },
  computed: {
    relationships() {
      return this.$store.state.actorRelationships.relationships
    },
    businessRelationships() {
      return this.relationships.filter(relationship => relationship.type === 'business') || []
    },
    defaultBusinessRelationships() {
      return this.businessRelationships.filter(rel => rel.default)
    },
    customBusinessRelationships() {
      return this.businessRelationships.filter(rel => !rel.default).reverse()
    },
  },
  methods: {
    copyToClipboard,
    focusEditCustomRelationship(relationship) {
      setTimeout(() => {
        const ref = 'edit-custom' + relationship.id
        if (this.$refs[ref] && this.$refs[ref][0]) {
          this.$refs[ref][0].focus()
        }
      })
    },
    handleCreateKeyup($event) {
      if (!this.label || !this.inverseLabel) {
        return
      }
      if ($event.keyCode === 13) {
        this.addRelationship()
      }
    },
    handleEditKeyup($event) {
      if (!this.editingRelationship.label.length >= 2 || !this.editingRelationship.id || !this.editingRelationship.inverse_label.length >= 2) {
        return
      }
      if ($event.keyCode === 13) {
        this.updateRelationship()
      }
    },
    setEditingRelationship(relationship) {
      this.editingRelationship = { ...relationship }
      this.focusEditCustomRelationship(relationship)
    },
    updateRelationship() {
      if (!this.editingRelationship || !this.editingRelationship.id) {
        return
      }
      this.updatingRelationship = true

      updateRelationship(this.editingRelationship.id, {
        label: this.editingRelationship.label,
        inverseLabel: this.editingRelationship.inverse_label,
      })
          .then(response => {
            this.$store.dispatch(RELATIONSHIP_ACTION_TYPES.FETCH_RELATIONSHIPS)
          })
          .catch(error => {
            console.log(error)
          })
          .finally(() => {
            this.updatingRelationship = false
            this.editingRelationship = {
              label: '',
              inverse_label: '',
            }
          })
    },
    focusTagInput() {
      setTimeout(() => {
        this.$refs.addMore.focus()
      })
    },
    addRelationship() {
      this.submitting = true
      createRelationship({ label: this.label, inverseLabel: this.inverseLabel })
          .then(response => {
            this.$store.dispatch(RELATIONSHIP_ACTION_TYPES.FETCH_RELATIONSHIPS)
          })
          .catch(error => {
            console.log(error)
            this.$store.dispatch(RELATIONSHIP_ACTION_TYPES.FETCH_RELATIONSHIPS)
          })
          .finally(() => {
            this.submitting = false
            this.showAddMore = false
            this.label = ''
            this.inverseLabel = ''
          })
    },
    deleteRelationship(relationship) {
      countRelationships(relationship.id).then(result => {
        if (result.relationship_count === 0) {
          this.confirmDeleteCustomRelationship(relationship.id)
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
            name: relationship.label + ' / ' + relationship.inverse_label,
            countableEntityName: 'relationship',
            count: result.relationship_count,
            resource: relationship,
            modalContextType: 'countable-entity',
          })

          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
        }
      })
    },
    confirmDeleteCustomRelationship(relationshipId) {
      this.deletingRelationshipId = relationshipId
      deleteRelationship(relationshipId)
          .then(response => {
            this.$store.dispatch(RELATIONSHIP_ACTION_TYPES.FETCH_RELATIONSHIPS)
          })
          .catch(error => {
            console.log(error)
            this.$store.dispatch(RELATIONSHIP_ACTION_TYPES.FETCH_RELATIONSHIPS)
          })
          .finally(() => {
            this.deletingRelationshipId = null
          })
    },
  },
  mounted() {
    this.$bus.on('confirmDeleteCountableEntity', (context) => {
      this.confirmDeleteCustomRelationship(context.resource.id)
    })
  },
  beforeUnmount() {
    this.$bus.off('confirmDeleteCountableEntity')
  },
  mixins: [TagsMixin],
  components: {
    AutocompleteTagInput,
    Dropdown,
  },
})
</script>

<style lang="scss" scoped>
</style>
