<template>
  <table class="top-sources-list">
    <tbody>
    <tr v-for="source in sources">
      <td class="source__label">
        <!--<rich-tag class="source__label__tag" :tag="source"/>-->
        <!--<div class="source__label__tag"><a :href="source.url" target="_blank">{{ source.display_name }}</a></div>-->
        <news-source class="source__label__tag" :source="transformSource(source)"/>
      </td>
      <!--<td class="source__icon"><icon :name="iconForType(source.type)"/></td>-->
      <td>
        <div class="source__count">
          <div class="source__count__bar" :style="{width: `${source.count / highestCount * 100}%`}">
            {{ source.count }}
          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
  import NewsSource from '../NewsSource/NewsSource.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: ['sources'],
    computed: {
      highestCount () {
        let highestCount = 0

        for (const source of this.sources) {
          if (source.count > highestCount) {
            highestCount = source.count
          }
        }

        return highestCount
      },
    },
    methods: {
      transformSource (source) {
        return {
          label: source.display_name,
          value: source.id
        }
      },
      iconForType (type) {
        switch (type) {
          case 'loc':
            return 'map-marker'

          case 'person':
            return 'account'

          default:
            return 'notification-label'
        }
      }
    },
    components: {
      NewsSource
    },
  })
</script>

<style lang="scss" scoped>
  .top-sources-list {
    display: flex;
    justify-content: center;
  }

  .source__label {
    text-align: right;
  }

  .source__label__tag {
    margin-bottom: 0;
  }

  .source__icon {
    padding: 0 10px;
  }

  .source__count {
    position: relative;
    width: 400px;
    height: 20px;
    line-height: 20px;
    margin-left: 1rem;
  }

  .source__count__bar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #9B9FAD;
    color: #fff;
    text-align: right;
    padding: 0 5px;
    box-sizing: border-box;
    min-width: fit-content;
  }

  .source__label {
    .source__label__add {
      visibility: hidden;
    }

    &:hover {
      .source__label__add {
        visibility: visible;
      }
    }
  }
</style>

<style lang="scss">
  .top-sources-list .source__icon {
    .svg-icon--notification-label path {
      fill: #9B439C;
    }

    .svg-icon--account path {
      fill: #DC9A56;
    }

    .svg-icon--map-marker path {
      fill: #59B89A;
    }
  }
</style>
