export const MUTATION_TYPES = {
  ADD_PORTFOLIO_KEYWORD: 'PORTFOLIOS/ADD_PORTFOLIO_KEYWORD',
  REMOVE_PORTFOLIO_KEYWORD: 'PORTFOLIOS/REMOVE_PORTFOLIO_KEYWORD',
}

export const mutations = {
  // Keywords
  [MUTATION_TYPES.ADD_PORTFOLIO_KEYWORD] (state, keyword) {
    const existingEntry = state.portfolioKeywords.find(k => k.value === keyword.value && k.facet === keyword.facet)
    if (!existingEntry) {
      state.portfolioKeywords.push(keyword)
    }
  },

  [MUTATION_TYPES.REMOVE_PORTFOLIO_KEYWORD] (state, keyword) {
    // Note: clear by object equality
    state.portfolioKeywords = state.portfolioKeywords.filter(k => k.value !== keyword.value)
  },
}

export const roles = {
  EXPLORER: 'explorer',
  CONTRIBUTOR: 'contributor',
}

export const getters = {
  activePortfolioKeywords (state) {
    return state.portfolioKeywords
  },
  personalPortfolio (state) {
    var portfolios = window.PORTFOLIOS || []

    return portfolios.find(portfolio => {
      return portfolio.name == 'Personal portfolio'
    })
  },
}

export default {
  state: {
    portfolioKeywords: [],
  },
  getters,
  mutations,
}
