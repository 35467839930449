import ls from '../../util/ls.js'
import { updateActiveLocale } from '../../api/user.js'
import numeral from 'numeral'

export const AVAILABLE_LOCALES = [
  'en',
  'nl',
]

export const LOCALE_LABELS = {
  en: 'English',
  nl: 'Nederlands',
  cimode: 'Translation Keys',
}

export const DEFAULT_LOCALE = 'en'

export function deriveActiveLocale () {
  var enabledLocales = [DEFAULT_LOCALE, ...((window.config && window.config.languages) || [])]
  var activeLocale = (window.user && window.user.locale) || (!window.user && ls('activeLocale')) || (window.user && !window.user.id && ls('activeLocale')) || DEFAULT_LOCALE

  if (!enabledLocales || enabledLocales.length < 1 || (activeLocale && enabledLocales && !enabledLocales.includes(activeLocale))) {
    if (enabledLocales.length === 1) {
      return enabledLocales[0]
    }

    return DEFAULT_LOCALE
  }

  return activeLocale
}

const state = {
  activeLocale: deriveActiveLocale(),
  enabledLocales: [DEFAULT_LOCALE, ...((window.config && window.config.languages) || [])],
}

export const MUTATION_TYPES = {
  SET_ACTIVE_LOCALE: 'LOCALIZATION/SET_ACTIVE_LOCALE',
  SET_ENABLED_LOCALES: 'LOCALIZATION/SET_ENABLED_LOCALES',
  SET_NUMERIC_LOCALE: 'LOCALIZATION/SET_NUMERIC_LOCALE',
}

export const ACTION_TYPES = {
  UPDATE_ACTIVE_LOCALE: 'LOCALIZATION/UPDATE_ACTIVE_LOCALE',
  UPDATE_ENABLED_LOCALES: 'LOCALIZATION/UPDATE_ENABLED_LOCALES',
  UPDATE_NUMERIC_LOCALE: 'LOCALIZATION/UPDATE_NUMERIC_LOCALE',
}

const mutations = {
  [MUTATION_TYPES.SET_ACTIVE_LOCALE] (state, { locale, i18n }) {
    state.activeLocale = locale
    i18n.locale = locale
  },

  [MUTATION_TYPES.SET_ENABLED_LOCALES] (state, enabledLocales) {
    state.enabledLocales = enabledLocales
  },

  [MUTATION_TYPES.SET_NUMERIC_LOCALE] (state, displayCurrency) {
    if (displayCurrency === 'usd') {
      numeral.locale('datascouts-usd')
    } else {
      numeral.locale('datascouts-eur')
    }
  }
}

export const getters = {
  activeLocale (state, getters) {
    if (!getters.isI18nEnabled || (state.activeLocale && getters.enabledLocales && !getters.enabledLocales.includes(state.activeLocale))) {
      return DEFAULT_LOCALE
    }

    return state.activeLocale
  },
  enabledLocales (state) {
    return state.enabledLocales
  },
  isI18nEnabled (state) {
    return state.enabledLocales && state.enabledLocales.length > 1
  },
  languageOptions (state) {
    return state.enabledLocales.map(locale => ({
      value: locale,
      label: LOCALE_LABELS[locale] || locale,
    })) || []
  },
}

const actions = {
  [ACTION_TYPES.UPDATE_ACTIVE_LOCALE] ({ commit, getters }, { locale, i18n }) {
    commit(MUTATION_TYPES.SET_ACTIVE_LOCALE, { locale, i18n })

    if (locale === 'cimode') {
      return
    }

    updateActiveLocale(locale)

    if (getters.isLoggedIn) {
      updateActiveLocale(locale)
      ls('activeLocale', locale) // TODO: remove else if this turns out ok, this is used to track the locale in the analytics functions, which doesn't have access to the state
    } else {
      ls('activeLocale', locale)
    }
  },

  [ACTION_TYPES.UPDATE_ENABLED_LOCALES] ({ commit }) {
    commit(MUTATION_TYPES.SET_ENABLED_LOCALES, [DEFAULT_LOCALE, ...(window.config.languages || [])])
  },

  [ACTION_TYPES.UPDATE_NUMERIC_LOCALE] ({ commit, getters }) {
    commit(MUTATION_TYPES.SET_NUMERIC_LOCALE, getters.displayCurrency)
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
}
