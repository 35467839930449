import Vuex, { createStore as _createStore, StoreOptions } from 'vuex'
import RootState from './RootState.js'

import managePortfolio from './modules/managePortfolio.js'
import challenges from './modules/challenges.js'
import notifications from './modules/notifications.js'
import portfolios from './modules/portfolios.js'
import introJs from './modules/introJs.js'
import taxonomies from './modules/taxonomies.js'
import buzz from './modules/buzz.js'
import mediabuzz from './modules/mediabuzz.js'
import conceptSearchGuide from './modules/conceptSearchGuide.js'
import knowledgeBase from './modules/knowledgeBase.js'
import localization from './modules/localization.js'
import defaultDictionaries from './modules/defaultDictionaries.js'
import conceptSearches from './modules/concept-searches.js'
import actorRelationships from './modules/actor-relationships.js'

import filterUrlSync from './plugins/filter-url-sync.js'
import analyticsPlugin from './plugins/analytics.js'
import reports from './modules/reports.js'

import actors from './modules/actors.js'
import config from './modules/config.js'
import scores from './modules/scores.js'
import filters from './modules/filters.js'
import ui from './modules/ui.js'
import user from './modules/user.js'
import analytics from './modules/analytics.js'
import onboarding from './modules/onboarding.js'
import conversations from './modules/conversations.js'
import spottingAreas from './modules/spottingareas.js'
import communities from './modules/communities.js'

const modules = {
  config,
  user,
  ui,
  actors,
  analytics,
  // completion,
  // dashboard,
  scores,
  filters,
  notifications,
  challenges,
  portfolios,
  managePortfolio,
  reports,
  taxonomies,
  buzz,
  mediabuzz,
  conceptSearchGuide,
  knowledgeBase,
  localization,
  defaultDictionaries,
  conceptSearches,
  conversations,
  actorRelationships,
  introJs,
  onboarding,
  communities,
  spottingAreas
}

export default new Vuex.Store<RootState>({
  modules,
  plugins: [filterUrlSync, analyticsPlugin],
  strict: true,
})

/*export default _createStore({
  modules: modules,
  plugins: [filterUrlSync, analyticsPlugin],
  strict: process.env.APP_ENV === 'local',
})*/
