<!-- TODO remove this file when articleAnalysisPanel replaces this-->
<template>
  <div v-if="displayedArticle !== null">
    <div class="classification">
      <dictionary-tag class="dictionary" v-for="displayedDictionaryId in displayedDictionaries" :id="displayedDictionaryId" :key="displayedDictionaryId"/>
    </div>
    <div v-if="showRadars" class="explanation">
      <p>This article is automatically classified along the following axes:</p>
      <ol>
        <li v-for="axis in classificationAxes">{{ reportTypeLabels[axis] || axis }}</li>
      </ol>
      <p>We appreciate your feedback to further optimise the classification algorithm.</p>
    </div>

    <div v-for="(axis, index) in classificationAxes" class="classification-axis" :key="'classification_axis__' + index">
      <div class="axis-title">{{ reportTypeLabels[axis] || axis }}</div>
      axis{{axis}}
      <simple-multi-select
        @select="selectClassification"
        @deselect="deselectClassification"
        :options="optionsForAxis(axis)"
        :read-only="onlySeesCuratedContent || !userCanEdit"
        style="margin-bottom: 20px;"
      />
      <!--<div v-for="data in radarDataForAxis[axis].datasets.data">
        {{ data }}
      </div>-->
    </div>

  </div>
</template>

<script>
  import objectPath from 'object-path'
  import { contentFeedback } from '../../../api/knowledge-base'
  import Dropdown from '../../Dropdown/Dropdown.vue'
  import DictionaryMixin from '../../../util/DictionaryMixin'
  import SimpleMultiSelect from '../../SimpleMultiSelect/SimpleMultiSelect.vue'
  import DictionaryTag from '../../Tag/DictionaryTag.vue'
  import Radar from '../../Chart/Radar.vue'
  import { CONCEPT_SEARCH_REPORT_TYPES_LABELS } from '../../../constants/concept-search-constants'
  import { defineComponent } from 'vue'

  export default defineComponent({
    mixins: [DictionaryMixin],
    components: { Radar, DictionaryTag, SimpleMultiSelect, Dropdown },
    props: {
      displayedArticle: Object,
      userCanEdit: Boolean,
      showRadars: {
        type: Boolean,
        default: true,
      },
      sortOptionsBy: {
        type: String,
        default: 'title',
      },
    },
    computed: {
      onlySeesCuratedContent () {
        return this.$store.getters.onlySeesCuratedContent
      },
      reportTypeLabels () {
        return CONCEPT_SEARCH_REPORT_TYPES_LABELS
      },
      displayedDictionaries () {
        // These are the dictionary classifications that have been verified by the user
        if (this.displayedArticle.resource && this.displayedArticle.resource.dictionary_similarity) {
          return [...this.displayedArticle.resource.dictionary_similarity]
            .filter(dictionary => dictionary.feedback === 'attached')
            .map(dictionary => dictionary.dictionary_id)
            .sort((a, b) => a - b)
        }

        return []
      },
      classificationAxes () {
        return this.mixinDefaultDictionaries.reduce((axes, dictionary) => {
          if (dictionary.related_report && !axes.includes(dictionary.related_report)) {
            axes.push(dictionary.related_report)
          }

          return axes
        }, [])
      },
      classification () {
        return objectPath.get(this.displayedArticle.resource, 'dictionary_similarity', [])
      },
      radarOptions () {
        return {
          legend: {
            display: false
          },
          scale: {
            ticks: {
              display: false,
              min: 0,
              max: 1,
              stepSize: 0.2,
            },
          },
        }
      },
      radarDataForAxis () {
        return
        // Deprecated
        /*const result = {}

        for (const axis of this.classificationAxes) {
          const dictionaries = this.mixinDefaultDictionaries.filter(d => d.related_report === axis)

          result[axis] = {
            labels: dictionaries.map(d => d.abbreviation || d.title),
            datasets: [{
              data: this.similarityDataForDictionaries(dictionaries),
              backgroundColor: 'rgba(1, 140, 199, 0.2)',
              borderColor: '#018CC7',
            }]
          }
        }

        return result*/
      },
    },
    methods: {
      selectClassification (dictionaryId) {
        this.updateClassification(dictionaryId, 'attached')
      },
      deselectClassification (dictionaryId) {
        this.updateClassification(dictionaryId, 'removed')
      },
      updateClassification (dictionaryId, feedback) {
        const resourceSqlId = this.displayedArticle.resource.sql_media_id || this.displayedArticle.details.id

        if (!resourceSqlId) {
          console.log('No ID found for the article, cannot update classification')

          return
        }

        contentFeedback(resourceSqlId, {
          feedback: feedback,
          dictionary_id: dictionaryId,
        })
          .then(response => {
            this.displayedArticle.resource['dictionary_similarity'] = [...this.classification]
              .map(dictionaryClassification => {
                if (dictionaryClassification.dictionary_id == dictionaryId) {
                  dictionaryClassification.feedback = feedback
                }

                return dictionaryClassification
              })
          })
      },
      optionsForAxis (axis) {
        let options = this.mixinDefaultDictionaries.filter(d => d.related_report === axis).map(dictionary => {
          const dictionarySimilarity = this.getDictionarySimilarity(dictionary) || 0

          return {
            value: dictionary.id,
            label: labelForDefaultDictionary(dictionary) + ' - SCORE: ' + dictionarySimilarity.score,
            score: dictionarySimilarity.score,
            selected: dictionarySimilarity.feedback && dictionarySimilarity.feedback === 'attached'
          }
        })

        if (this.sortOptionsBy === 'score') {
          options = options.sort((a, b) => b.score - a.score)
        }

        return options
      },
      similarityDataForDictionaries (dictionaries) {
        const result = []

        for (const dictionary of dictionaries) {
          result.push(this.dictionarySimilarity(dictionary))
        }

        return result
      },
      getDictionarySimilarity (dictionary) {
        if (!this.displayedArticle.details) {
          return
        }

        for (const dictionarySimilarity of this.displayedArticle.details.dictionary_similarity || []) {
          if (dictionarySimilarity.dictionary_id === dictionary.id) {
            return dictionarySimilarity
          }
        }
      },
    },
  })

  function labelForDefaultDictionary (dictionary) {
    if (dictionary.abbreviation && dictionary.abbreviation !== dictionary.title) {
      return `${dictionary.abbreviation} - ${dictionary.title}`
    }

    return dictionary.title
  }
</script>

<style lang="scss" scoped>
  .classification {
    margin-bottom: 10px;

    .dictionary {
      margin-right: 0;
      margin-left: 0.25rem;
      margin-bottom: 0.25rem;
    }
  }

  .classification-axis {
    margin-bottom: 30px;
  }

  .axis-title {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 10px;
  }

  .top-line {
    display: flex;
    margin-bottom: 20px;
  }

  .explanation {
    margin-bottom: 30px;
    background-color: #eee;
    padding: 10px;

    p {
      font-size: inherit;
      margin-bottom: 0;
    }

    ol {
      margin-top: 5px;
      margin-bottom: 20px;
    }

    li {
      margin-left: 20px;
    }
  }
</style>
