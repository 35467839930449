<template>
  <div class="tabs">
    <ul class="tabs__navigation" v-if="tabs.length > 1 || edit">
      <li
        class="tabs__navigation__item"
        :class="{ 'tabs__navigation__item--active': tab === activeTab, 'tabs__navigation__item--beta': beta.includes(tab), 'tabs__navigation__item--dev': dev.includes(tab) }"
        v-for="tab in tabs"
        :key="tab"
        @click="setActiveTab(tab)"
        tabindex="0"
      >
        {{ tab }}
      </li>
      <li
        v-if="edit"
        class="tabs__navigation__item tabs__navigation__item--link"
        style="float: right; color: white"
        @click="$router.push(edit)"
        tabindex="0"
      >
        <icon name="edit" />
        Edit
      </li>
    </ul>
    <div class="tabs__content" :class="contentClass">
      <slot :name="activeTab"></slot>
    </div>
  </div>
</template>

<script>
  import slugify from '../../util/slugify'

  export default {
    props: {
      beta: {
        type: Array,
        default: () => []
      },
      dev: {
        type: Array,
        default: () => []
      },
      edit: String,
      tabs: {
        type: Array,
        default: []
      },
      saveToUrl: {
        type: Boolean,
        default: false
      },
      contentClass: {
        type: String
      },
      selectedTab: null
    },
    data () {
      return {
        activeTab: this.selectedTab
      }
    },
    methods: {
      setActiveTab (tab) {
        this.activeTab = tab
        this.$bus.emit('tab', tab)

        if (this.saveToUrl) {
          this.$router.push({ hash: '#' + slugify(this.activeTab) })
        }
      },
      hashchange (evt) {
        const hash = window.location.hash.replace('#', '')
        const tabInHash = this.tabs.find(tab => slugify(tab) === hash)

        if (this.saveToUrl && tabInHash) {
          this.activeTab = tabInHash
        }

        if (evt && evt.preventDefault) {
          evt.preventDefault()
        }
      }
    },
    mounted () {
      this.hashchange()
      window.addEventListener('hashchange', this.hashchange)

      this.$bus.emit('tab', this.activeTab)
    },
    beforeUnmount () {
      window.removeEventListener('hashchange', this.hashchange)
    },
    watch: {
      selectedTab (val) {
        this.activeTab = val
      }
    }
  }
</script>
