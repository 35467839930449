<template>
  <div>
    <tabs :tabs="tabs" :save-to-url="true">
      <template v-slot:[getTabName(0)]>
        <div>
          <ecosystem-member-package-management @packagesUpdated="fetchPackages()" />
        </div>
      </template>

      <template v-slot:[getTabName(1)]>
        <div style="overflow-x: auto">
          <ecosystem-member-package-features :packages="packages" />
        </div>
      </template>

      <template v-slot:[getTabName(2)]>
        <div>
          <cta-config v-model:form="form" />
        </div>
      </template>
    </tabs>
  </div>
</template>

<script>
  import Tabs from '../Tabs/Tabs.vue'
  import EcosystemMemberPackageManagement from './EcosystemMemberPackages/EcosystemMemberPackageManagement.vue'
  import EcosystemMemberPackageFeatures from './EcosystemMemberPackages/EcosystemMemberPackageFeatures.vue'

  import { EcosystemMemberPackages } from '../../api/ecosystem-member-packages'
  import CtaConfig from './EcosystemTabs/CtaConfig.vue'
  import { defineComponent } from 'vue'


  export default defineComponent({
    name: 'EcosystemMemberPackages.vue',
    computed: {
      form () {
        return this.$parent.$parent.form
      },
    },
    data () {
      return {
        tabs: [
          'package management',
          'package features',
          'cta'
        ],
        packages: []
      }
    },
    methods: {
      getTabName (index) {
        return this.tabs[index]
      },
      fetchPackages () {
        EcosystemMemberPackages
          .get()
          .then(response => {
            this.packages = response
          })
      }
    },
    components: {
      EcosystemMemberPackageFeatures,
      EcosystemMemberPackageManagement,
      CtaConfig,
      Tabs
    },
    mounted () {
      this.fetchPackages()
      this.$bus.on('tab', () => this.fetchPackages())
    },
    beforeUnmount () {
      this.$bus.off('tab')
    }
  })
</script>

<style scoped lang="scss">

</style>
