<template>
  <!-- DEPRECATED -->
  <div class="news fixed-heading">
    <div class="heading">
      <h1 class="news__title">Social Media Monitoring</h1>

      <template v-if="true">
        <p class="guide_text">This dashboard is no longer in use.</p>
      </template>
      <template v-else>
        <badge :name="actorCount + ' Actors'" variant="primary" class="score-overview__actor-couter" />
        <ds-button variant="minimal" size="small" label="Reset" icon="undo" class="buzz-page-header__reset-button" @click="resetBuzzParameters()"/>
        <p class="guide_text" v-if="$store.getters.isPublic">Discover the top trending hashtags, topics and profiles. Apply filters by clicking links on the right.</p>
        <p class="guide_text" v-else>Click trending hashtags or most followed profiles to filter the streaming messages.</p>
      </template>
    </div>

    <newsfeed class="scrollable has-padding" />
  </div>
</template>

<script lang="ts">
import Newsfeed from '../Newsfeed/Newsfeed.vue'
import Badge from '../Badge/Badge.vue'

import { actorCount } from '../../api/actors.js'

import { MUTATION_TYPES as BUZZ_MUTATION_TYPES } from '../../store/modules/buzz.js'
import { defineComponent } from 'vue'
export default defineComponent({
  data() {
    return {
      actorCount: 0
    }
  },
  computed: {
    filters () {
      return this.$store.getters.baseFilterObject
    }
  },
  methods: {
    resetBuzzParameters () {
      this.$store.commit(BUZZ_MUTATION_TYPES.RESET_BUZZ_FILTERS)
    },
    updateActorCount () {
      actorCount(this.filters).then( number => {
        this.actorCount = number.count
      })
    }
  },
  mounted () {
    //this.$router.replace({ query: this.$store.getters.exploreFilterObject })
    this.updateActorCount()
  },
  watch: {
    filters:{
      deep: true,
      handler () {
        this.updateActorCount()
      }
    }
  },
  components: {
    Newsfeed,
    Badge
  }
})
</script>
<style lang="scss">
.news__title {
  display:inline-block;
}
</style>
