<template>
  <div class="completion fixed-heading">
    <div class="heading">
      <h1>Completion of company profiles</h1>
      <p class="guide_text">Monitor and score the completion of database.</p>
      <div>
        <radio-button
          v-for="(label, index) in ['Groups', 'Weekly difference', 'Actual completion rate']"
          :key="index"
          v-model="tab"
          name="completion_tab"
          inline
          :label="label"
          :val="index"
        />
      </div>
    </div>
    <div class="scrollable has-padding" v-if="!tab">
      <div class="property-group" v-for="group in groups">
        <div>
          {{ group.label }}
        </div>
        <div class="property-group__list">
          {{ Math.round(1000 * group.sum / group.fields.length / total) / 10 }}% complete
        </div>
      </div>
    </div>
    <div class="scrollable" v-else>
      <table class="table table--striped table--fill">
        <thead>
          <tr>
            <th @click="sortBy('lower')" colspan="2" class="th-sortable"
              :class="{up:tableOrder=='lower','down':tableOrder=='-lower'}">Property</th>
            <th width="150" :colspan="snapshots.length+2" @click="sortBy('value')"
            class="th-sortable" :class="{up:tableOrder=='value','down':tableOrder=='-value'}">
              {{ relative ? 'Weekly difference' : 'Completion rate' }}
            </th>
          </tr>
          <tr>
            <th colspan="2">
              Date
              <br><span style="font-weight:normal">Total companies</span>
            </th>
            <th width="100" v-for="(s, date) in past" style="min-width:120px" :data-tooltip="period(1)" data-position="top center">
              {{date.slice(0, 10)}}
              <br><span style="font-weight:normal">{{s.total_companies}}</span>
            </th>
            <th width="100" colspan="1">
              Now
              <br><span style="font-weight:normal">{{total}}</span>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="c in sortedCompletionTable">
            <td width="100">{{c.title}}</td>
            <td width="100" class="bar-td">
              <div class="bar-h" v-for="(p, index) in c.history" :style="{height:32*p.value/c.total+'px', opacity: (index+1)/(c.history.length+1)}"></div><div class="bar-h" :style="{height:c.pctStr*.32+'px'}"></div>
            </td>
            <template v-if="relative">
              <td v-for="p in c.history" :class="{'table--positive':p.change>0, 'table--negative':p.change<0}">
                <small class="completion-small">{{p.changeStr>0 ? '+' : ''}}{{p.changeStr}}%</small>
                <div class="completion-h3">{{p.change>0 ? '+' : ''}}{{p.change}}</div>
              </td>
            </template>
            <template v-if="!relative">
              <td v-for="p in c.history">
                <small class="completion-small">{{p.pctStr}}%</small>
                <div class="completion-h3">{{p.value}}</div>
              </td>
            </template>
            <td>
              <small class="completion-small">{{c.pctStr}}%</small>
              <div class="completion-h3">{{c.value}}</div>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script lang="ts">
import _sum from 'lodash/sum.js'

import RadioButton from '../Form/RadioButton.vue'

import { propertyGroups } from '../../constants/config.js'

import { ACTION_TYPES as COMPLETION_ACTION_TYPES } from '../../store/modules/completion.js'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    RadioButton
  },
  data () {
    return {
      tab: 0,
      tableOrder: '-value'
    }
  },
  computed: {
    completion () {
      return this.$store.state.completion.data
    },
    past () {
      return this.completion.past || {}
    },
    // [date, date, date]
    snapshots () {
      return Object.keys(this.past).map(s => s.slice(0, 10))
    },
    total () {
      return this.completion.total_companies || 0
    },
    groups () {
      return Object.keys(propertyGroups).map(label => {
        const fields = propertyGroups[label]
        return {
          label,
          fields,
          sum: _sum(fields.map(f => this.completion.fields[f])) || 0
        }
      }).filter(g => g.sum)
    },
    relative () {
      return this.tab < 2
    },
    completionTable () {
      return this.$store.getters.completionTable
    },
    sortedCompletionTable () {
      if (!this.tableOrder) {
        return this.completionTable
      }
      // 'value' => sort by value property
      // '-value' => reverse sort by value property
      const reverse = this.tableOrder.indexOf('-') === 0 ? 1 : -1
      const prop = this.tableOrder.slice(this.tableOrder.indexOf('-') + 1)
      return this.completionTable.slice().sort((a, b) => a[prop] < b[prop] ? reverse : -reverse)
    }
  },
  methods: {
    sortBy (col) {
      if (this.tableOrder === col) {
        this.tableOrder = '-' + col
      } else {
        this.tableOrder = col
      }
    },
    period (i) {
      return this.relative ? 'Period: from ' + this.snapshots[i] + ' to ' + (this.snapshots[i + 1] || 'now') : null
    },
    fetch () {
      this.$store.dispatch(COMPLETION_ACTION_TYPES.FETCH_COMPLETION)
    }
  },
  created () {
    this.fetch()
  }
})
</script>

<style>
.bar-td {
  border-left-color: transparent!important;
  width: 70px;
  padding: 0 0 0 20px!important;
  vertical-align: bottom;
  line-height: 0!important;
}
.bar-h {
  display: inline-block;
  width: 8px;
  background: #6d95c4;
  max-height: 50px;
}
.completion-small {
  display: block;
  text-align: right;
  font-size: 10px;
}
.completion-h3 {
  text-align: right;
  font-size: 16px;
  font-family: $font-stack-secondary;
}
</style>
