<template>
  <a v-bind="containerWrapperProps" class="wrapper">
    <header>
      <div class="source">
        <div class="source__avatar" v-if="sourceAvatar">
          <img :src="sourceAvatar" :alt="sourceTitle">
        </div>
        <div class="source__info">
          <div class="source__title" v-if="sourceTitle">{{ sourceTitle }}</div>
          <div
              v-if="(isOwner || isTeamMember) && sourceSubtitle"
              class="source__subtitle"
          >
            <news-source
                :icon="isKennisWest ? 'west-flanders' : `${sourceSubtitle.icon}`"
                :is-kennis-west="isKennisWest"
                class="source__label__tag"
                :source="transformSource(sourceSubtitle.label)"
                @click="filterSource($event, sourceSubtitle.label)"
            />
            <a class="source__external-link" @click="trackandGoToExternalLinkClick" v-tooltip.top="'Open'">
              <icon name="external-link-alt" size="small"/>
            </a>
            <a
                class="source__external-link"
                v-tooltip.top="copied ? '✓ ' + $t('copied_article_url'): $t('copy_article_url')"
                @click="copyUrl"
            >
              <icon name="share" size="14"/>
            </a>
          </div>
          <!-- source icon + source domain (for non-owners) -->
          <div
              v-if="(!isOwner && !isTeamMember) && sourceSubtitle"
              class="source__subtitle"
              :source="transformSource(sourceSubtitle.label)"
              @click="filterSource($event, sourceSubtitle.label)"
          >
            <!--            <i v-if="sourceSubtitle.icon" :class="`source__subtitle-icon fa ${sourceSubtitle.icon}`"></i>-->
            <icon v-if="sourceSubtitle.icon" class="source__subtitle-icon" size="small" :name="sourceSubtitle.icon"/>
            {{ sourceSubtitle.label }}
            <a class="source__external-link" v-bind="wrapperProps">
              <icon :style="{ marginTop: '-2px' }" v-if="isKennisWest" name="west-flanders" size="14"/>
              <icon name="external-link-alt"/>
            </a>
          </div>
        </div>
      </div>
      <div class="timestamp" v-if="timestamp">
        <relevant-edit-popup
            v-if="$store.getters.isMember && canBeMarkedRelevant && !previewMode"
            v-model="data.relevant_in"
            :active-search="activeConceptSearchId"
            @relevant="handleArticleRelevant"
            @not-relevant="handleArticleNotRelevant"
            style="margin-left: auto; cursor: pointer"
        />
        <span>{{ timestamp }}</span>
      </div>
    </header>

    <div class="content">
      <div
          class="content__main"
          @click="handleContentClick()"
      >
        <div class="content__visual" :class="{'is-pdf': isPdf}" v-if="image && showImage"
             :style="{'background-size': isKennisWest ? 'auto' : 'cover', 'background-image': 'url(' + image + ')'}">
        </div>
        <div :class="{'content__text': true, 'has-image': image && showImage}">
          <div class="content_parent_title">
            <div class="content__title">{{ title }}</div>
          </div>
          <div class="content__body" v-if="body" v-html="body"></div>
        </div>
      </div>

      <div class="content__meta" v-if="displayTagsOrActors">
        <ul v-if="actors.length > 0 && !previewMode">
          <li
              v-for="(actor, index) in actors"
              :key="'actor__' + actor.id + index"
          >
            <tag
                :key="'actor-tag__' + actor.id + index"
                @click.prevent="clickActor($event, actor)"
                :label="actor.name"
                :title="actor.name"
                :class="previewMode ? 'tag tag--preview' : actor.isActive ? 'tag tag--active' : 'tag'"
                icon="building"
            />
          </li>
        </ul>

        <ul v-if="tags.length > 0 && !previewMode" :style="displayActors ? 'margin-left: 0.25rem;' : ''">
          <li
              v-for="(tag, index) in tags"
              :key="'tag__' + tag.value + index"
          >
            <RichTag
                :key="'rich-tag__' + tag.value + index"
                :tag="tag"
                :title="tag.type === 'patent_cpc' ? tag.text : ''"
                @click.prevent="clickTag($event, tag)"
                :disable-add-to-dictionary="false"
            />
          </li>
        </ul>
      </div>

      <!-- Added false, depending on the feedback whether or not we should show the locations tags separately from the tags -->
      <div class="content__meta" v-if="false && !previewMode && locationTags && locationTags.length > 0">
        <ul>
          <li
              v-for="(tag, index) in locationTags"
              :key="'tag__location-' + tag.value + index"
          >
            <RichTag
                v-if="!previewMode"
                :tag="tag"
                :title="tag.type === 'patent_cpc' ? tag.text : ''"
                @click.prevent="clickTag($event, tag)"
                :disable-add-to-dictionary="false"
            />
            <button
                v-else
                :class="previewMode ? 'tag tag--preview' : tag.isActive ? 'tag tag--active' : 'tag'"
                @click.prevent="clickTag($event, tag)"
            >
              {{ tag.label }}
            </button>
          </li>
        </ul>
      </div>
    </div>

    <footer v-if="!previewMode">
      <div class="view-count">
        {{ viewCount }}
      </div>
      <div class="actions" v-if="allowActions">
        <button
            v-if="isOwner || isPortfolioMember || isActor"
            class="action"
            @click="toggleLikeStatus($event, data.sql_media_id, data.is_liked_by_user)"
            :key="'like-' + data.id"
            style="margin-bottom: 0;"
        >
          <span v-if="data.is_liked_by_user" class="action__icon">
            <icon name="thumbs-up"/>
          </span>
          <span v-else class="action__icon">
            <icon name="thumbs-up-outline"/>
          </span>
          <span class="action__label">
            {{ data.is_liked_by_user ? 'LIKED' : 'LIKE' }}
            </span>
        </button>
        <template v-for="(action, index) in actions">
          <a
              v-if="action.externalLinkUrl"
              @click="trackExternalLinkClick"
              class="action"
              :href="action.externalLinkUrl"
              target="_blank"
              rel="noopener"
              :key="action.label + index"
          >
            <div class="action__label">
              {{ action.label }}
            </div>
          </a>
          <button
              v-if="!action.externalLinkUrl"
              class="action"
              @click="handleAction($event, action)"
              :key="action.label + index"
              style="margin-bottom: 0;"
          >
            <!--<span class="action__icon">
              <i v-if="action.faIcon" :class="`fa ${action.faIcon}`"></i>
              <icon v-if="action.icon" :name="action.icon"/>
            </span>-->
            <span class="action__label">
              {{ action.label }}
            </span>
          </button>
        </template>
      </div>
    </footer>
  </a>
</template>

<script>
import NewsSource from '../NewsSource/NewsSource.vue'
import ResourceMixin from '../../util/ResourceMixin'
import TranslationsMixin from '../../util/TranslationsMixin'
import AnnouncementMixin from '../../util/AnnouncementMixin'
import { ARTICLE_ACTION_TYPES } from '../../constants/article-constants'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
import MODAL_IDS from '../../constants/modal-ids'
import RelevantEditPopup from '../RelevantEditPopup/RelevantEditPopup.vue'
import { updateFileRelevancy } from '../../api/exploration'
import { fetchPublicNotification } from '../../api/notifications'
import { trackHeapEvent } from '../../util/analytics'
import { trackArticle } from '../../api/knowledge-base'
import RichTag from '../Tag/RichTag.vue'
import { copyToClipboard, getExternalLinkFromItem, getInternalFileUrlForResource } from '../../util/helpers.js'
import Tag from '../Tag/Tag.vue'
import { logUserActivity } from '../../util/user-analytics'
import { deleteEcosystemFile } from '../../api/files.js'
import _uniqBy from 'lodash/uniqBy.js'
import { defineComponent } from 'vue'
import pdfImage from '/resources/assets/icons/file-pdf-red.svg'

export default defineComponent({
  name: 'AnnouncementOrResourceCard',
  components: { Tag, RelevantEditPopup, RichTag, NewsSource },
  props: {
    data: Object,
    isTagActive: {
      type: Function,
      default: () => false,
    },
    isActorActive: {
      type: Function,
      default: () => false,
    },
    allowActions: {
      type: Boolean,
      default: true,
    },
    previewMode: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      default: '',
    },
    showImage: {
      type: Boolean,
      default: true,
    },
    newWindow: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    overrideUrl: {
      type: String,
      default: '',
    },
    prefixUrl: {
      type: String,
      default: '',
    },
    activeConceptSearchId: {
      type: Number,
      required: false,
    },
  },
  data: () => {
    return {
      copied: false,
      resizeKey: 0,
    }
  },
  mixins: [ResourceMixin, TranslationsMixin, AnnouncementMixin],
  methods: {
    copyToClipboard,
    copyUrl() {
      // trackArticle(this.data.sql_media_id, this.$route.params.id, 'share')

      if (this.conceptSearchId) {
        copyToClipboard(`${window.location.protocol}//${window.location.hostname}/dashboards/concept-map/${this.conceptSearchId}/content/${this.data.sql_media_id}`)
      } else {
        copyToClipboard(getExternalLinkFromItem(this.data))
      }

      this.copied = true
    },
    deleteEcosystemFile(fileId) {
      if (!fileId) {
        return
      }

      deleteEcosystemFile(fileId)
          .then(response => {
            this.resourceToDelete = {}
            // Give the ElasticSearch a small amount of time to update the document, before emitting the event
            setTimeout(() => {
              this.$emit('resourceDeleted')
            }, 500)
          })
          .catch(errors => {
            console.log(errors)
          })
    },
    deleteResource(resource) {
      if (!resource.sql_media_id || resource.sql_media_id != this.data.sql_media_id) {
        return
      }

      this.deleteEcosystemFile(resource.sql_media_id)
    },
    transformSource(tagLabel) {
      return {
        label: tagLabel,
        value: this.data.sql_handle_id,
      }
    },
    async toggleLikeStatus(event, sqlMediaId, isLiked) {
      event.preventDefault()
      event.stopPropagation()

      await this.$emit('like-article', {
        resourceId: sqlMediaId,
        status: !isLiked,
      })

      this.data.is_liked_by_user = !this.data.is_liked_by_user
    },
    clickTag(event, tag) {
      if (this.previewMode) {
        window.open(this.wrapperProps.href, '_blank')

        return
      }

      tag.isActive ? this.removeTagFromFilters(event, tag) : this.addTagToFilters(event, tag)
    },
    clickActor(event, actor) {
      if (this.previewMode) {
        window.open(this.wrapperProps.href, '_blank')

        return
      }

      this.toggleActorFilter(event, actor)
    },
    handleContentClick() {
      if (this.canOpenInternalPage(this.data)) {
        return this.trackandGoToInternalLinkClick()
      }

      return this.trackandGoToExternalLinkClick()
    },
    async trackandGoToInternalLinkClick() {
      await this.trackInternalLinkClick()

      this.handleClickResourceResult(this.data)
    },
    async trackandGoToExternalLinkClick() {
      await this.trackExternalLinkClick()

      this.handleClickResourceResult(this.data, true)
    },
    trackInternalLinkClick() {
      trackArticle(this.data.sql_media_id, null, 'view_internal')
      trackHeapEvent('knowledgeBaseSUI.clickExternalLink', { resourceMediaType: this.data.media_type })
    },
    trackExternalLinkClick() {
      trackArticle(this.data.sql_media_id, null, 'view_external')
      trackHeapEvent('knowledgeBaseSUI.clickExternalLink', { resourceMediaType: this.data.media_type })
      logUserActivity('', 'file-detail', this.data.sql_media_id)
    },
    filterSource(event, tagLabel) {
      this.addTagToFilters(event, { value: this.data.sql_handle_id, label: tagLabel }, 'source')
    },
    getInternalPageUrl(withOrigin = false) {
      const prefix = this.prefixUrl || '/files'

      return (withOrigin ? `${window.location.origin}` : ``) + `${prefix}/${this.data.sql_media_id}/analysis/`
    },
    openInternalPage() {
      this.$router.push(this.getInternalPageUrl())
    },
    openActorSidePanel(actorId) {
      this.$emit('open-actor-side-panel', { actor_id: actorId })
    },
    handleArticleRelevant(conceptSearchId) {
      trackHeapEvent('knowledgeBaseSUI.starArticle')
      this.markArticleRelevant(true, conceptSearchId)
    },
    handleArticleNotRelevant(conceptSearchId) {
      this.markArticleRelevant(false, conceptSearchId)
    },
    markArticleRelevant(isRelevant, conceptSearchId) {
      const originalRelevant = this.data.relevant
      this.data.relevant = isRelevant

      updateFileRelevancy(this.data.sql_media_id, isRelevant, conceptSearchId)
          .catch(err => {
            this.data.relevant = originalRelevant
            console.log(err)
          })

      this.$emit('mark-article-relevant', { article: this.data, isRelevant, conceptSearchId })
    },
    addTagToFilters(event, tag, facet = 'tags') {
      if (!this.isOwner && !this.$store.getters.hasAccessToAllKnowledgeBaseContent) {
        return false
      }

      const tagFilter = { facet: facet, value: tag.value, label: tag.label }
      this.$emit('addTag', tagFilter, 'resource-card')
    },
    removeTagFromFilters(event, tag) {
      event.stopPropagation()
      event.preventDefault()
      const tagFilter = { facet: 'tags', value: tag.value, label: tag.label }
      this.$emit('removeTag', tagFilter)
    },
    toggleActorFilter(event, actor) {
      event.stopPropagation()
      event.preventDefault()

      this.$emit('toggleActor', actor)
    },
    handleAction(event, action) {
      event.preventDefault()
      event.stopPropagation()
      action.handler()
      trackHeapEvent('knowledgeBaseSUI.resource.action', {
        resourceMediaType: this.data.media_type,
        action: action.type
      })
    },
    async openAnnouncementEditSidePanel() {
      // We need the "id" property to edit the resource.
      try {
        var announcement = await fetchPublicNotification(this.data.sql_media_id)

        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          announcement: announcement,
        })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.MANAGE_ANNOUNCEMENT)
      } catch (error) {
        console.log(error)
      }
    },
    showEditResourcePanel() {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
        'component': 'edit-resource-panel',
        metaData: { resource: this.data },
      })
    },
    showDeleteResourceConfirmationModal() {
      let modalContext = null

      if (this.isAnnouncement) {
        modalContext = {
          body: `Do you want to delete this ${this.announcementLabel}?`,
          modalContextType: 'announcement',
          announcement: this.data,
        }
      } else if (this.isResource) {
        modalContext = {
          body: 'Do you want to delete this resource?',
          resource: this.data,
          modalContextType: 'file',
        }
      } else if (this.isArticle) {
        modalContext = {
          body: 'Do you want to delete this article?',
          modalContextType: 'global-article',
          article: this.data,
        }
      }

      if (modalContext) {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, modalContext)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
      }
    },
  },
  computed: {
    contentType() {
      return this.data.media_type || this.data.mimetype || (this.data.details && this.data.details.mimetype)
    },
    isAnnouncement() {
      return this.itemIsAnnouncement(this.data)
    },
    isResource() {
      return this.itemIsResource(this.data)
    },
    isArticle() {
      return this.itemIsArticle(this.data)
    },
    isPatent() {
      return this.itemIsPatent(this.data)
    },
    displayTagsOrActors() {
      return this.tags.length > 0 || this.displayActors
    },
    displayActors() {
      return this.actors.length > 0 && !this.previewMode
    },
    isOwner() {
      return this.$store.getters.isOwner
    },
    isTeamMember() {
      return this.$store.getters.isTeamMember
    },
    isPortfolioMember() {
      return this.$store.getters.isPortfolioMember
    },
    isActor() {
      return this.$store.getters.isActor
    },
    containerWrapperProps() {
      if (this.isArticle || this.isPatent || this.prefixUrl) {
        return {}
      }

      return {} // this.wrapperProps
    },
    isKennisWest() {
      return this.sourceSubtitle && this.sourceSubtitle.label.indexOf('kenniswest') !== -1
    },
    isPdf() {
      const fileType = this.data.meta.find(entry => entry.key === 'file_type')

      if (fileType) {
        return fileType.value.includes('pdf')
      }

      return null
    },
    wrapperProps() {
      const externalLinkUrl = getExternalLinkFromItem(this.data)

      if (this.overrideUrl) {
        return { href: this.overrideUrl }
      }

      if (this.canOpenInternalPage(this.data)) {
        return {
          href: this.getInternalPageUrl(),
          target: '_blank',
        }
      }

      if (externalLinkUrl) {
        return {
          href: externalLinkUrl,
          target: '_blank',
          rel: 'noopener',
        }
      }

      if (this.isAnnouncement) {
        return {
          href: `/announcements/${this.data.sql_media_id}`,
        }
      }

      if (this.isResource) {
        return {
          href: getInternalFileUrlForResource(this.data),
          target: '_blank',
          rel: 'noopener',
        }
      }

      return {}
    },
    canBeMarkedRelevant() {
      return ['rss', 'pr', 'blog', 'patent', 'event'].includes(this.data.media_type)
    },
    timestamp() {
      if (this.data.date) {
        return this.publishedDate(this.data.date)
      }

      return null
    },
    sourceTitle() {
      if (this.isAnnouncement && Boolean(this.data.creator)) {
        return this.data.creator.name
      }

      return null
    },
    sourceSubtitle() {
      if ((!this.isArticle && !this.isPatent) || !this.data.meta || !Array.isArray(this.data.meta)) {
        return null
      }

      const metaLinkEntry = [...this.data.meta].find(meta => meta.key && meta.key === 'link')

      if (!metaLinkEntry) {
        return null
      }

      const fullUrl = new URL(metaLinkEntry.value)

      return {
        icon: 'rss',
        label: fullUrl.host.replace('www.', ''),
      }
    },
    sourceAvatar() {
      if (this.isAnnouncement && Boolean(this.data.creator)) {
        return this.data.creator.photo_url
      }

      return null
    },
    title() {
      return this.data.title
    },
    body() {
      if (this.isAnnouncement) {
        return this.data.shortHtml
      } else if (this.isResource) {
        return this.data.body
      }

      return this.data.body
    },
    allTags() {
      return (this.data.tags || [])
          .map(tag => ({
            ...tag,
            isActive: this.isTagActive(tag),
          }))
          .sort((a, b) => b.isActive - a.isActive)
    },
    tags() {
      return this.allTags
          .filter(tag => (!['loc', 'org', 'person'].includes(tag.type) && !((this.data.actor_id || []).find(actor => actor.name === tag.label))))
          .slice(0, 5)
    },
    locationTags() {
      return [] // We don't use this at the moment
      /* return this.allTags
        .filter(tag => (!((this.data.actor_id || []).find(actor => actor.name === tag.label))) && ['loc'].includes(tag.type))
        .slice(0, 5) */
    },
    actors() {
      if (!['public_resource', 'private_resource'].includes(this.data.media_type)) {
        return []
      }

      const attachedActors = this.data.actor_id || []
      const linkedActors = this.data.linked_actor_id || []

      return _uniqBy([...attachedActors, ...linkedActors], 'id')
          .map(actor => ({
            ...actor,
            text: actor.name,
            label: actor.name,
            type: 'org',
            isActive: this.isActorActive({ value: actor.id }),
          }))
          .sort((a, b) => b.isActive - a.isActive)
          .slice(0, 5)
    },
    viewCount() {
      const viewCount = this.data.view_count || 0
      let countTranslationKey = null
      let zeroCountTranslationKey = null

      if (this.isAnnouncement) {
        countTranslationKey = 'knowledge_base_resource_view_count_announcement'
        zeroCountTranslationKey = 'knowledge_base_resource_zero_view_count_announcement'
      } else if (this.isResource) {
        countTranslationKey = 'knowledge_base_resource_view_count_resource'
        zeroCountTranslationKey = 'knowledge_base_resource_zero_view_count_resource'
      } else if (this.isArticle) {
        countTranslationKey = 'knowledge_base_resource_view_count_rss'
        zeroCountTranslationKey = 'knowledge_base_resource_zero_view_count_rss'
      } else if (this.isPatent) {
        countTranslationKey = 'knowledge_base_resource_view_count_patent'
        zeroCountTranslationKey = 'knowledge_base_resource_zero_view_count_patent'
      }

      if (!countTranslationKey) {
        return ''
      }

      if (viewCount === 0) {
        countTranslationKey = zeroCountTranslationKey
      }

      let message = this.$t(countTranslationKey, {
        count: viewCount,
        announcement: this.announcementLabel,
      })

      // Split up the counts (in_app/external) for owners only
      // if (this.isOwner) {
      //   message += ` - ${this.data.view_count_in_app} internally and ${this.data.view_count_external} externally`
      // }

      // Append the total likes as well
      if (this.isOwner) {
        message = message.replace('.', ' ' + this.viewLikes)
      }

      return message
    },
    viewLikes() {
      if (!this.isOwner) {
        return
      }

      const viewLikes = this.data.likes || 0
      let countTranslationKey = null
      let zeroCountTranslationKey = null

      if (this.isAnnouncement) {
        countTranslationKey = 'knowledge_base_resource_view_count_likes_announcement'
        zeroCountTranslationKey = 'knowledge_base_resource_zero_view_count_likes_announcement'
      } else if (this.isResource) {
        countTranslationKey = 'knowledge_base_resource_view_count_likes_resource'
        zeroCountTranslationKey = 'knowledge_base_resource_zero_view_count_likes_resource'
      } else if (this.isArticle) {
        countTranslationKey = 'knowledge_base_resource_view_count_likes_rss'
        zeroCountTranslationKey = 'knowledge_base_resource_zero_view_count_likes_rss'
      } else if (this.isPatent) {
        countTranslationKey = 'knowledge_base_resource_view_count_likes_patent'
        zeroCountTranslationKey = 'knowledge_base_resource_zero_view_count_likes_patent'
      }

      if (!countTranslationKey) {
        return ''
      }

      if (viewLikes === 0) {
        countTranslationKey = zeroCountTranslationKey
      }

      return this.$t(countTranslationKey, {
        count: viewLikes,
        announcement: this.announcementLabel,
      })
    },
    image() {
      if (this.isAnnouncement && !this.data.is_event) {
        return `/notifications/${this.data.sql_media_id}/image`
      }

      if (this.isKennisWest) {
        return 'https://www.kenniswest.be/images/kenniswest-logo.svg'
      }

      if (this.isResource || this.isArticle || this.isPatent) {
        if (Array.isArray(this.data.meta)) {
          const fileTypeMetaEntry = this.data.meta.find(entry => entry.key === 'file_type')

          if (fileTypeMetaEntry && (fileTypeMetaEntry.value.includes('image') || fileTypeMetaEntry.value.includes('pdf'))) {

            if (fileTypeMetaEntry.value.includes('pdf')) {
              return pdfImage
            }

            const preview320MetaEntry = this.data.meta.find(entry => entry.key === 'file_preview_w320')

            if (preview320MetaEntry && preview320MetaEntry.value) {
              return preview320MetaEntry.value
            }
          }

          if (this.data.image) {
            if (this.data.image.startsWith('https://') || this.data.image.startsWith('http://') || this.data.image.startsWith('www')) {
              // Frontend hotfix: Make sure to wrap external URL images with quotes
              return '\'' + this.data.image + '\''
            }

            return 'https://public.datascouts.eu/' + this.data.image
          }
        }

        return null
      }
    },
    canUpdateOrDeleteResource() {
      return this.$store.getters.isMember || (this.data.is_created_by_user && this.$store.getters.canUserUploadContent)
    },
    actions() {
      const externalLinkUrl = this.overrideUrl || getExternalLinkFromItem(this.data)
      let actions = []

      if (this.isAnnouncement) {
        actions.push({
          type: ARTICLE_ACTION_TYPES.VIEW,
          icon: 'external-link-alt',
          externalLinkUrl,
        })

        if (this.$store.getters.isMember) {
          actions.push({
            type: 'EDIT',
            icon: 'edit',
            handler: this.openAnnouncementEditSidePanel,
          })

          actions.push({
            type: 'DELETE',
            icon: 'trash',
            handler: this.showDeleteResourceConfirmationModal,
          })
        }
      } else if (this.isResource) {
        const resourceLink = this.data.actor_id && this.data.actor_id[0] ? `${window.location.origin}/api/actors/${this.data.actor_id[0].id}/files/${this.data.sql_media_id}` : `${window.location.origin}/api/files/${this.data.sql_media_id}`

        if (this.canOpenInternalPage(this.data)) {
          actions.push({
            type: ARTICLE_ACTION_TYPES.INTERNAL,
            icon: 'eye',
            handler: () => {
              this.openInternalPage(true)
            },
          })
        } else {
          actions.push({
            type: ARTICLE_ACTION_TYPES.VIEW,
            icon: 'external-link-alt',
            externalLinkUrl: externalLinkUrl || resourceLink,
          })
        }

        if ((this.$store.getters.isMember || this.$store.getters.canCreateAnnouncement) && this.$store.getters.areMessageBoardsEnabled) {
          actions.push({
            type: 'SHARE',
            icon: 'share',
            handler: () => {
              this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
                announcement: {
                  body: '',
                  is_event: false,
                  file: this.data,
                },
              })
              this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.MANAGE_ANNOUNCEMENT)
            },
          })
        }

        if (this.$store.getters.isMember || this.canUpdateOrDeleteResource) {
          actions = [...actions, ...[
            {
              type: 'EDIT',
              icon: 'edit',
              handler: this.showEditResourcePanel,
            },
            {
              type: 'DELETE',
              icon: 'trash',
              handler: this.showDeleteResourceConfirmationModal,
            },
          ]]
        }
      } else if (this.isArticle || this.isPatent || this.isEvent) {
        if (this.canOpenInternalPage(this.data)) {
          actions.push({
            type: ARTICLE_ACTION_TYPES.INTERNAL,
            icon: 'eye',
            handler: () => {
              this.openInternalPage(true)
            },
          })
        } else {
          actions.push({
            type: ARTICLE_ACTION_TYPES.VIEW,
            icon: 'external-link-alt',
            externalLinkUrl,
          })
        }

        if ((this.$store.getters.isMember || this.$store.getters.canCreateAnnouncement) && this.$store.getters.areMessageBoardsEnabled) {
          actions.push({
            type: 'SHARE',
            icon: 'share',
            handler: () => {
              this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
                announcement: {
                  body: '',
                  is_event: false,
                  file: this.data,
                },
              })
              this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.MANAGE_ANNOUNCEMENT)
            },
          })
        }

        if (this.$store.getters.isMember) {
          actions.push({
            type: 'DELETE',
            icon: 'trash',
            handler: this.showDeleteResourceConfirmationModal,
          })
        }
      }

      return actions.map(action => ({
        label: this.$t(`knowledge_base_resource_actions_${this.data.media_type}_${action.type}`),
        ...action,
      }))
    },
  },
  mounted() {
    this.$bus.on('fileDeleteConfirmation', (context) => {
      this.deleteResource(context.resource)
    })

    this.$bus.on('articleDeleteConfirmation', (context) => {
      this.deleteResource(context.resource)
    })

    this.$bus.on('globalArticleDeleteConfirmation', (context) => {
      this.deleteResource(context.article)
    })
  },
  beforeUnmount() {
    this.$bus.off('fileDeleteConfirmation')
    this.$bus.off('globalArticleDeleteConfirmation')
    this.$bus.off('articleDeleteConfirmation')
  },
})
</script>

<style lang="scss" scoped>
$accent-color: var(--primary-community);
$border-and-background-color: var(--primary-community-extra-lightest);
$hover-color: var(--primary-community-lightest);
$title-height: 40px;
$nrOfLines: 3;
$lineHeight: 16px;
@import "../../../scss/variables.scss";

:deep(.svg-icon--west-flanders) {
  path {
    fill: $color-primary;
  }
}

.is-pdf {
  width: 50px !important;
  height: 50px !important;
  margin: 20px !important;
}

.wrapper {
  border-radius: 0.5rem;
  overflow: hidden;
  text-decoration: none;
  color: inherit;
  border: 1px solid $border-and-background-color;
  display: flex;
  flex-direction: column;
  text-align: left;

  &.has-actions {
    border-radius: 0 0.5rem 0.5rem 0;
  }

  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  header,
  .content {
    padding: 0.5rem;
    flex-grow: 1;
  }

  header {
    display: flex;
    align-items: center;
    height: 2.5rem;
    background-color: $border-and-background-color;
  }
}

.source {
  display: flex;
  align-items: center;
}

.source__avatar {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.25rem;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
  }
}

.source__title {
  font-size: 0.75rem;
}

.source__subtitle {
  font-size: 0.625rem;
  color: $accent-color;
}

.source__subtitle-icon {
  margin-right: 0.125rem;
}

.source__external-link {
  margin-left: 2px;

  &:hover {
    cursor: pointer;
  }
}

.timestamp {
  display: flex;
  align-items: center;
  margin-left: auto;
  font-size: 12px;
  color: $accent-color;

  > span {
    margin-left: 0.5rem;
  }
}

.content {
  display: flex;
  flex-direction: column;
  //height: calc(100% - 40px);
}

.content__main {
  display: flex;
  cursor: pointer;

  &:not(:last-child) {
    padding-bottom: 1rem;
  }
}

.content__visual {
  width: 7rem;
  height: 7rem;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  margin-right: 1rem;
}

.content__text {
  max-width: 95%;

  &.has-image {
    max-width: 80%;
  }
}

.content_parent_title {
  width: 100%;
  white-space: nowrap;
  display: inline-block;
}

.content__title {
  font-weight: bold;
  font-size: 0.875rem;
  margin-bottom: 0.25rem;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content__body {
  font-size: 0.75rem;
  height: $lineHeight * $nrOfLines;
  line-height: $lineHeight;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $nrOfLines;
}

.content__meta {
  display: flex;
  margin-bottom: 0.125rem;
  margin-top: auto;

  svg {
    color: var(--primary-dark);
    margin-right: 0.5rem;
    margin-top: 0.25rem;
    width: 1.25rem;
  }

  .tag--active svg {
    margin: 0px;
    width: 1rem;
  }

  ul {
    list-style-type: none;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    li {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 0.25rem;
        margin-bottom: 0.25rem;
      }
    }
  }
}

.tag {
  margin: 0;
  padding: 6px;
  border-radius: 0.5rem;
  border: 1px solid $border-and-background-color;
  background: transparent;
  font-family: inherit;
  font-size: 11px;

  &:hover {
    cursor: pointer;
    background-color: $hover-color;
    border-color: $hover-color;
  }
}

.tag--preview {
  &:hover {
    cursor: pointer;
    background-color: transparent;
    border: 1px solid $border-and-background-color;
  }
}

.tag--active {
  background-color: $border-and-background-color;
  font-weight: bold;
  // color: $border-and-background-color;
}

.tag--dictionary {
  .dropdown-button {
    font-weight: bold;

    svg > path {
      fill: var(--primary);
    }
  }
}

.view-count,
.actions {
  border-top: 1px solid $border-and-background-color;
}

.view-count {
  padding: 0.5rem;
  font-size: 11px;
  color: $accent-color;
}

.actions {
  display: flex;
}

.action {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  padding: 1rem;
  text-align: center;
  text-decoration: none;
  font-size: 0.75rem;
  font-family: inherit;
  border: 0;
  background-color: transparent;
  color: $accent-color;

  &:hover {
    background-color: $hover-color;
    cursor: pointer;
  }

  &:not(:last-child) {
    border-right: 1px solid $border-and-background-color;
  }
}

.action__icon {
  margin-right: 0.5rem;
}

.announcement-card__actions {
  display: flex;
  border-radius: 12px 0 0 12px;
  flex-direction: column;
  width: 62px;
  min-height: 100%;
  background-color: var(--primary-extra-lightest);

  .announcement-card__action {
    color: var(--primary);
    position: relative;
    margin: 15px 20px;
    text-align: center;
    cursor: pointer;

    path {
      fill: var(--primary);
    }

    &:not(.disable-action) {
      &:hover {
        // filter: drop-shadow(0 0 2px rgba(#000, 0.2));
      }
    }

    &.disable-action {
      opacity: .4;
    }
  }

  .announcement-card__action-top-right {
    position: absolute;
    left: 17px;
    top: -7px;
    font-size: 10px;
  }

  .announcement-card__action-top-left {
    position: absolute;
    right: 16px;
    top: -6px;
    font-size: 10px;
  }
}
</style>
