<template>
  <div class="simplified-footer">
    <div class="row">
      <div class="col-xs-12">
        <div class="simplified-footer__content">
          <div class="simplified-footer__top">
            <div class="simplified-footer__top__left" :style="{width: topSectionWidth}">
              <a class="logo__container">
                <div
                  v-if="logo"
                  :style="`background-image: url('${logo}');`"
                  class="simplified-footer__logo_image"
                />
              </a>
              <a v-if="config.footer_logo" class="logo__container">
                <div
                  :style="`background-image: url('${config.footer_logo}');`"
                  class="simplified-footer__logo_image"
                />
              </a>
            </div>
          </div>
          <div class="simplified-footer__bottom">
            <div class="simplified-footer__bottom__middle" :style="{width: bottomSectionWidth}">
              <div style="color: #21244A;">
                <a
                  :href="termsOfUseUrl" target="_blank" rel="noopener noreferrer"
                  @click="trackHeapEvent('simplifiedFooter.termsOfUse')">
                  {{ $t('terms_of_use') }}
                </a>&nbsp;-&nbsp;
                <a
                  :href="privacyPolicyUrl" target="_blank" rel="noopener noreferrer"
                  @click="trackHeapEvent('simplifiedFooter.privacyPolicy')">
                  {{ $t('privacy_policy') }}
                </a>&nbsp;-&nbsp;
                <a
                  :href="cookiePolicyUrl" target="_blank" rel="noopener noreferrer"
                  @click="trackHeapEvent('simplifiedFooter.cookiePolicy')">
                  {{ $t('cookie_policy') }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import objectPath from 'object-path'
  import Container from '../Container/Container.vue'
  import TranslationsMixin from '../../util/TranslationsMixin'
  import { DEFAULT_LOCALE } from '../../store/modules/localization'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'
  import { trackHeapEvent } from '../../util/analytics.js'
  import { DEFAULT_URLS } from '../../constants/default-urls.js'
  import UiMixin from '../../util/UiMixin.js'

  export default {
    computed: {
      numberOfTopSections () {
        let result = 1

        if (this.emailAddress) {
          result++
        }
        if (this.config.footerDescription) {
          result++
        }
        if (this.config.footerCtaEnabled) {
          result++
        }

        return result
      },
      isMobile () {
        return this.ui.isMobile
      },
      isIpad () {
        return this.ui.isIpad
      },
      bottomSectionWidth () {
        if (this.isIpad) {
          return '100%'
        }
        return '33.333%'
      },
      topSectionWidth () {
        if (this.isIpad) {
          return '100%'
        }

        switch (this.numberOfTopSections) {
          case 1:
            return '100%'
          case 2:
            return '50%'
          case 3:
            return '33.333%'
          case 4:
            return '25%'
        }
      },
      logo () {
        return this.config.logo_url_light || this.config.logoSrc
      },
      config () {
        return this.$store.state.config
      },
      userLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      emailAddress () {
        return this.config.email
      },
      termsOfUseUrl () {
        return this.config.termsOfUseUrl || DEFAULT_URLS.TERMS_OF_USE
      },
      privacyPolicyUrl () {
        return this.config.privacyPolicyUrl || DEFAULT_URLS.PRIVACY_POLICY
      },
      cookiePolicyUrl () {
        return this.config.cookiePolicyUrl || DEFAULT_URLS.COOKIE_POLICY
      },
    },
    methods: {
      trackHeapEvent,
    },
    mixins: [
      UiMixin,
      TranslationsMixin,
    ],
    components: {
      Container,
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables";

  $spacing: 30px;

  .simplified-footer {
    margin-top: 4rem;
    background: rgba(218, 225, 218, 1);
    font-size: 12px;
  }

  .simplified-footer__content {
    color: white;
    line-height: 1.5;
    padding: 15px 15px 15px 38px;
    display: flex;
    justify-content: space-between;

    a {
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .simplified-footer__top {
    display: flex;

    .logo__container {
      display: flex;
      justify-content: left;

      &:first-child {
        margin-bottom: 10px;
      }

      &:last-child {
        margin-top: 10px;
      }
    }
  }

  .simplified-footer__logo_image {
    min-height: 40px;
    width: 200px;
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
  }

  .simplified-footer__bottom__middle {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .simplified-footer__bottom__middle {
    padding: calc($spacing / 2);
    flex-grow: 1;

    & > div {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      a {
        color: #21244A;
      }
    }
  }

  .simplified-footer__bottom {
    display: flex;
  }

  @media screen and (max-width: $screen-md) {
    .simplified-footer__logo_image {
      background-position: center;
    }

    .simplified-footer__top {
      flex-wrap: wrap;

      .logo__container {
        justify-content: center;
      }
    }

    .simplified-footer__bottom {
      display: block;
    }

    .simplified-footer__top__left, .simplified-footer__bottom__middle {
      width: 100%;
      text-align: center;
      margin: 0 auto;
      justify-content: center;
      flex-grow: 1;
    }

    .simplified-footer__content {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
</style>
