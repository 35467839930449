<template>
  <div class="has-padding offering-input offering-input-card">
      <div class="row">
        <div class="col-xs-6">
          <form-group label="Image" :errors="errors.logo">
            <image-input v-model="offeringData.logo" width="140" height="100"/>
          </form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-xs-12">
          <form-group  :label="titles.name" :errors="errors.name" v-if="visibleFields.includes('Name')">
            <ds-input v-model="offeringData.name" />
          </form-group>
          <form-group  :label="titles.description" :errors="errors.description" v-if="visibleFields.includes('Description')">
            <ds-textarea autoresize v-model="offeringData.description" />
          </form-group>
        </div>
      </div>
      <div class="row" v-if="visibleFields.includes('Date')">
        <form-group class="col-xs-12" :label="titles.date" :errors="errors.date">
          <datepicker v-model="offeringData.date" input-id="date" />
        </form-group>
      </div>
      <div class="row" v-if="visibleFields.includes('EndDate')">
        <form-group class="col-xs-12" :label="titles.end_date" :errors="errors.end_date">
          <datepicker v-model="offeringData.end_date" input-id="endDate" />
        </form-group>
      </div>
      <div class="row" v-if="visibleFields.includes('Website')">
        <form-group class="col-xs-12" :label="titles.website" :errors="errors.website">
          <ds-input v-model="offeringData.website" />
        </form-group>
      </div>
      <div class="row" v-if="visibleFields.includes('Features')">
        <form-group class="col-xs-12" label="Key features" :errors="errors.features">
          <tag-input v-model="offeringData.features"/>
        </form-group>
      </div>
      <div class="row" v-if="visibleFields.includes('Pricing')">
        <form-group class="col-xs-12" label="Pricing" :errors="errors.pricing">
          <ds-input v-model="offeringData.pricing" />
        </form-group>
      </div>
    <ds-button variant="secondary" :icon="busy === 1 ? 'spinner' : '' " :label="editable" @click="update" />
  </div>
</template>

<script>
import _intersection from 'lodash/intersection'

import { createActor, updateActor } from '../../api/actors';

import Checkbox from '../Form/Checkbox.vue';
import DsTextarea from '../Form/DsTextarea.vue';
import Datepicker from '../../components/Form/Datepicker.vue';
import ImageInput from '../Form/ImageInput.vue';
import TagInput from '../Form/TagInput.vue';

import { emptyAddress } from '../../util/actor';
import { addressLine } from '../../util/helpers';

export default {
  props: {
    editable: null,
    actorId: null,
    offerings: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: 'Product'
    }
  },
  data () {
    return {
      busy: 0,
      errors: {},
      offeringData: {
        type: '',
        name: ''
      },
    }
  },
  computed: {
    titles () {
      var dateTitle = 'Date';

      if (this.type.toLowerCase() == 'patent') {
        dateTitle = 'Publication date';
      }

      return {
        name: `${this.type} name`,
        description: `${this.type} description`,
        website: `${this.type} website`,
        date: dateTitle,
        end_date: 'End date'
      }
    },
    visibleFields () {
      switch(this.type) {
        case 'Product':
          return ['Name', 'Description', 'Website', 'Features', 'Pricing'];
          break;
        case 'Service':
        case 'Technology':
          return ['Name', 'Description', 'Website', 'Pricing', 'Features'];
          break;
        case 'Vacancy':
          return ['Name', 'Description', 'Website', 'Date', 'Features', 'EndDate'];
          break;
        case 'Patent':
          return ['Name', 'Description', 'Website', 'Date'];;
          break;
        case 'Other':
          return ['Name', 'Description'];
          break;
      }
    }
  },
  methods: {
    update () {
      this.busy = 1
      this.offerings.push(this.offeringData)

      updateActor({
        id: this.actorId,
        data: {
          offerings: this.offerings
        }
      }).then(actor => {
        this.busy = 2
        this.errors = {}
        this.$emit('saved', actor.offerings)
      }).catch((errors) => {
        this.busy = 0
        this.errors = errors
      })
    }
  },
  created () {
    this.offeringData.type = this.type
  },
  components: {
    Checkbox,
    Datepicker,
    DsTextarea,
    ImageInput,
    TagInput,
  }
}
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";

.offering-input {
  display: block;
  margin-bottom: 1em;
  width: 100%;
  outline: 0;
  font-size: 14px;
  background-color: $color-background-lightest-grey;
  &.editable {
    border: 1px solid #CECECE;
    padding: 14px 10px 8px;
    background: #eee;
  }
  .tag-input {
    background-color: white;
  }
  .svg-icon--chevron-down {
    vertical-align: top;
    margin: -3px 5px -10px;
  }
}
.offering-input__btn {
  display: inline-block;
  margin: -8px 0;
  border: 2px solid transparent;
  padding: 3px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background: white;
    border-color: $color-error;
  }
  .svg-icon {
    vertical-align: bottom;
    // display: block;
  }
}
.offering-input__remove {
  &:hover {
    border-color: $color-error;
  }
}
.offering-input__edit {
  padding: 3px 15px 3px 8px;
  a {
    color: black;
  }
  path {
    fill: black;
  }
  &:hover {
    border-color: $color-primary;
  }
}
.offering-input__detail {
  margin-bottom: 3px;
}
.offering-input__editing {
  padding-top: 10px;
  margin-bottom: -20px;
}
</style>
