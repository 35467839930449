<template>
  <aside class="aside-section">
    <div class="aside-section__header">
      <div class="aside-section__header__title">{{title}}</div>
      <slot name="titleAction" class="aside-section__header__action"></slot>
    </div>
    <div class="aside-section__content">
      <slot></slot>
    </div>
  </aside>
</template>

<script>
  export default {
    name: 'AsideSection',
    props: {
      title: String,
    }
  }
</script>

<style lang="scss">
  @import "../../../scss/variables";

  .aside-section {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .aside-section__header {
    display: flex;
    padding: 0.5rem 0;
    align-items: center;

    &:not(.borderless) {
      margin-bottom: 0.5rem;
      border-bottom: 1px solid #91929A;
    }
  }

  .aside-section__header__title {
    flex: 1;
    text-transform: uppercase;
    font-weight: 500;
  }

  .aside-section__content {
    small {
      margin-top: 0.5rem;
      font-size: 0.75rem;
      line-height: 1.25rem;
      color: #909090;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }

    &.d-flex {
      display: flex;
      flex-direction: row;
    }
  }
</style>
