<template>
  <div class="collapsable-tab__content-container">
    <form-section>
      <div class="row">
        <form-group
          class="col-xs-12 col-md-6" :label="$t('edit_actor_segment_contact_phone')" :errors="errors.phone"
          :class="{'actor-edit__private-field': isPrivateField('phone')}"
        >
          <ds-input name="actor-phone" v-model="modifiedActor.phone" :disabled="allowToEditPhoneNumber"/>
          <checkbox
            :label="$t('edit_actor_segment_contact_phone_make_public')"
            v-model="modifiedActor.is_phone_published" v-if="! $store.getters.isPrivate || publisherActive"
          />
        </form-group>
      </div>
      <div id="address" v-if="modifiedActor.address">
        <div class="row">
          <form-group :label="$t('edit_actor_segment_contact_street')" class="col-xs-8 col-sm-8">
            <ds-input name="actor-address-street" v-model="modifiedActor.address.street"/>
          </form-group>
          <form-group :label="$t('edit_actor_segment_contact_number')" class="col-xs-4 col-sm-4">
            <ds-input name="actor-address-number" v-model="modifiedActor.address.number"/>
          </form-group>
          <form-group :label="$t('edit_actor_segment_contact_zip')" class="col-xs-4 col-sm-4">
            <ds-input name="actor-address-zip" v-model="modifiedActor.address.zip"/>
          </form-group>
          <form-group :label="$t('edit_actor_segment_contact_city')" class="col-xs-8 col-sm-8">
            <ds-input name="actor-address-city" v-model="modifiedActor.address.city"/>
          </form-group>
        </div>
        <div class="row">
          <form-group :label="$t('edit_actor_segment_contact_country')" class="col-xs">
            <country-dropdown
              @update:modelValue="handleCountryChange"
              :model-value="modifiedActor.address.country_code"
            />
          </form-group>
        </div>
      </div>
      <div class="row">
        <form-group
          :class="{'col-xs-12': true, 'col-sm-6' : (!$store.getters.isPrivate || publisherActive) && $store.getters.canActorsBeContacted, 'actor-edit__private-field': isPrivateField('email')}"
          :label="$t('edit_actor_segment_contact_email')"
          :errors="errors.email"
        >
          <ds-input name="actor-email-address" v-model="modifiedActor.email"/>
          <checkbox
            :label="$t('edit_actor_segment_contact_email_make_public')"
            v-model="modifiedActor.is_email_published"
            v-if="modifiedActor.email && (!$store.getters.isPrivate || publisherActive)"
          />
        </form-group>
      </div>
      <div class="row">
        <form-group
          :label="$t('edit_actor_segment_allow_users_to_contact_me_via_platform')"
          :class="{'col-xs-12': true, 'col-sm-9' : (!$store.getters.isPrivate || publisherActive), 'actor-edit__private-field': isPrivateField('email')}"
        >
          <dropdown
            :options="actorContributors"
            :search="true"
            :multiple="true"
            :model-value="contactableContributors"
            @update:modelValue="updateContactableContributors"
          />
        </form-group>
      </div>
      <!-- @deprecated Not many actors are using this features, commented on 03/12/'24 -->
      <!--      <div class="row">
              <form-group
                class="col-xs-12 col-sm-6" :label="$t('edit_actor_segment_contact_cta_url')" :errors="errors.email"
                :class="{'actor-edit__private-field': isPrivateField('email')}"
                v-if="(!$store.getters.isPrivate || publisherActive)"
              >
                <ds-input
                  name="actor-contact-cta-url" v-model="modifiedActor.contact_cta_url"
                  :disabled="!modifiedActor.show_contact_cta_url"
                />
                <checkbox
                  :label="$t('actor_edit_contact_cta')" v-model="modifiedActor.show_contact_cta_url"
                />
              </form-group>
              <form-group
                v-if="(!$store.getters.isPrivate || publisherActive)" class="col-xs-12 col-sm-6"
                :label="$t('actor_edit_cta_text')"
                :errors="errors.contact_cta_text"
              >
                <ds-input
                  v-model="modifiedActor.contact_cta_text" :placeholder="$t('get_in_touch')"
                  :disabled="!modifiedActor.show_contact_cta_url"
                />
              </form-group>
            </div>-->
      <div id="add-address" v-if="!modifiedActor.address">
        <ds-button
          variant="outline" icon="plus" :label="$t('edit_actor_segment_contact_add_office')"
          @click="setEmptyAddress"
        />
        <br>
      </div>
      <div v-if="modifiedActor.subsidiaries && modifiedActor.subsidiaries.length">
        <div v-for="(subsidiary, index) in modifiedActor.subsidiaries">
          <hr class="navigation__divider">
          <div class="row">
            <form-group class="col-xs-12" :label="$t('edit_actor_name')" :errors="errors.name">
              <ds-input
                class="col-xs-10" v-model="modifiedActor.subsidiaries[index].name"
                style="display: inline-block; padding: 0"
              />
              <ds-button
                class="col-xs-2 collapsable-tab__delete-office-button" type="button" icon="trash"
                size="small" @click="removeOffice(modifiedActor.subsidiaries[index])"
                style="margin-left: 1em"
              />
            </form-group>
          </div>
          <div class="row">
            <form-group
              class="col-xs-12 col-md-6" :label="$t('edit_actor_segment_contact_phone')"
              :errors="errors.phone"
            >
              <ds-input name="actor-phone" v-model="modifiedActor.subsidiaries[index].phone"/>
              <checkbox
                :label="$t('edit_actor_segment_contact_phone_make_public')"
                v-model="modifiedActor.subsidiaries[index].is_phone_published" v-if="! $store.getters.isPrivate"
              />
            </form-group>
            <form-group
              :label="$t('add_actor_company_number')" :errors="errors.company_number ? errors.company_number[0] : ''"
              v-if="isRelevantForActor('company_number', modifiedActor) || isRelevantForActor('founding_date', modifiedActor)"
              class="col-xs-12 col-md-6"
            >
              <div class="company_number">
                <dropdown
                  :options="jurisdictionOptions" :model-value="modifiedActor.subsidiaries[index].jurisdiction"
                  :reference="index" @update:modelValue="updateSubsidiaryJurisdiction"
                  width="50px"
                  :search="true"
                />
                <ds-input
                  :placeholder="$t('add_actor_company_number')" name="actor-vat-number"
                  v-model="modifiedActor.subsidiaries[index].company_number"
                />
              </div>
            </form-group>
            <template v-if="modifiedActor.subsidiaries[index].address">
              <form-group :label="$t('edit_actor_segment_contact_street')" class="col-xs-8 col-sm-8">
                <ds-input name="actor-address-street" v-model="modifiedActor.subsidiaries[index].address.street"/>
              </form-group>
              <form-group :label="$t('edit_actor_segment_contact_number')" class="col-xs-4 col-sm-4">
                <ds-input name="actor-address-number" v-model="modifiedActor.subsidiaries[index].address.number"/>
              </form-group>
              <form-group :label="$t('edit_actor_segment_contact_zip')" class="col-xs-4 col-sm-4">
                <ds-input name="actor-address-zip" v-model="modifiedActor.subsidiaries[index].address.zip"/>
              </form-group>
              <form-group :label="$t('edit_actor_segment_contact_city')" class="col-xs-8 col-sm-8">
                <ds-input name="actor-address-city" v-model="modifiedActor.subsidiaries[index].address.city"/>
              </form-group>
            </template>
          </div>
          <div class="row">
            <form-group :label="$t('edit_actor_segment_contact_country')" class="col-xs">
              <country-dropdown
                @update:modelValue="handleCountryChange"
                :model-value="modifiedActor.subsidiaries[index].address.country_code"
              />
            </form-group>
          </div>
          <div class="row">
            <form-group class="col-xs-12" :label="actorStringCapitalized + ' email'" :errors="errors.email">
              <ds-input name="actor-email-address" v-model="modifiedActor.subsidiaries[index].email"/>
            </form-group>
          </div>
        </div>
      </div>
      <template v-if="modifiedActor.actor_type != 'Person'">
        <ds-button
          type="button" icon="plus" size="small"
          :label="show.addOffice ? $t('add_actor_cancel_button') : $t('edit_actor_segment_contact_add_office')"
          @click="show.addOffice = !show.addOffice"
          class="collapsable-tab__funding-button col-xs-12" v-if="!show.addOffice"
        />
        <ds-button
          type="button" icon="chevron-down" size="small"
          :label="show.addOffice ? $t('add_actor_cancel_button') : $t('edit_actor_segment_contact_add_office')"
          @click="show.addOffice = !show.addOffice"
          class="collapsable-tab__funding-button col-xs-12" v-else
        />
        <office-input-card
          @saved="officeSaved"
          :model-value="{actor_type: 'LegalEntity', company_type: 'Subsidiary', address: {}, 'jurisdiction' : '', 'company_number': '' }"
          editable="Add office"
          :main="modifiedActor.id" v-if="show.addOffice"
        />
      </template>
    </form-section>
  </div>
</template>

<script>
  import FormGroup from '../../Form/FormGroup.vue'
  import FormSection from '../../Form/FormSection.vue'
  import ImageInput from '../../Form/ImageInput.vue'
  import Checkbox from '../../Form/Checkbox.vue'
  import DsInput from '../../Form/DsInput.vue'
  import CountryDropdown from '../../Dropdown/CountryDropdown.vue'
  import Dropdown from '../../Dropdown/Dropdown.vue'
  import OfficeInputCard from '../../Form/OfficeInputCard.vue'
  import { emptyAddress } from '../../../util/actor.js'
  import { isRelevantForActor } from '../../../util/helpers.js'

  export default {
    name: 'actor-edit-contact-details',
    props: {
      modifiedActor: Object,
      errors: Object,
      jurisdictionOptions: Array,
      show: Object,
      actorContributors: Array,
    },
    computed: {
      allowToEditPhoneNumber () {
        if (!this.publisherActive) {
          return false
        }

        return !this.modifiedActor.is_phone_published
      },
      actorStringCapitalized () {
        const actorString = this.$store.getters.actorString
        return actorString.charAt(0) + actorString.slice(1, actorString.length)
      },
      publisherActive () {
        return this.$store.getters.publisher
      },
      isPublisherEnabled () {
        return this.$store.getters.isPublisherEnabled === true
      },
      contactableContributors () {
        return this.actorContributors && this.actorContributors.filter(contributor => contributor.allow_conversation)
      },
    },
    methods: {
      updateContactableContributors (newContactableContributors) {
        this.$emit('updateContactableContributors', newContactableContributors)
      },
      officeSaved (newOffice) {
        newOffice.to = newOffice.id // Make sure the relationship data is valid
        this.modifiedActor.subsidiaries.push(newOffice)
        this.show.addOffice = false
      },
      isPrivateField (field) {
        return this.isPublisherEnabled && !this.publicFields.includes(field)
      },
      handleCountryChange (country) {
        this.modifiedActor.address.country_code = country.code
        this.modifiedActor.address.country = country.name
      },
      // This will also set an address for original actor because an empty address is not a real change
      setEmptyAddress () {
        this.originalActor.address = emptyAddress()
        this.modifiedActor.address = emptyAddress()
      },
      removeOffice (office) {
        this.modifiedActor.subsidiaries = this.modifiedActor.subsidiaries.filter(subsidiary => subsidiary.id !== office.id)
      },
      isRelevantForActor: isRelevantForActor,
      updateSubsidiaryJurisdiction (data) {
        const index = data.reference
        const value = data.val

        if (this.modifiedActor.subsidiaries && this.modifiedActor.subsidiaries[index]) {
          this.modifiedActor.subsidiaries[index].jurisdiction = value
        }
      },
    },
    components: {
      FormGroup,
      ImageInput,
      FormSection,
      DsInput,
      Checkbox,
      Dropdown,
      CountryDropdown,
      OfficeInputCard,
    },
    mixins: [],
  }
</script>
