<template>
  <transition name="modal">
    <div class="modal__mask" @click="blur">
      <div class="modal__wrapper">
        <div class="modal__container" :class="classes" @click.stop>
          <div class="modal__header">
            <h4 class="title">Active filters</h4>
            <hr class="divider">
            <button class="close" @click="close" v-if="isCloseable">×</button>
          </div>
          <div class="modal__body">
            <template v-for="(keyword, index) in announcementKeywords" :key="index">
              <keyword-with-facet
                v-if="keyword.value !== '-' && keyword.facet !== 'legendProperty'"
                :label="keyword.value"
                :model-value="keyword"
                removeable
                @remove="handleRemoveKeyword"
              />
            </template>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import KeywordWithFacet from '../Keyword/KeywordWithFacet.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters'

  import KEYCODES from '../../constants/keycodes'
  import MODAL_IDS from '../../constants/modal-ids'

  export default {
    props: {
      closeOnBlur: Boolean,
      id: {
        type: String
      },
      isCloseable: {
        type: Boolean,
        default: true
      },
      title: {
        type: String,
        default: ''
      },
      customTitle: {
        type: Boolean,
        default: false
      },
      enableMask: {
        type: Boolean,
        default: true
      },
      size: {
        type: String,
        default: '',
        validator: val => ['large', ''].includes(val)
      }
    },
    created () {
      window.addEventListener('keyup', this.keyUpHandler)
    },
    methods: {
      keyUpHandler (e) {
        if (e.keyCode === KEYCODES.ESC) {
          this.close()
        }
      },
      blur () {
        if (this.closeOnBlur) {
          this.close()
        }
      },
      close () {
        if (this.isCloseable) {
          this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.MORE_ANNOUNCEMENT_FILTER_TAGS)
          this.$emit('close')
        }
      },
      handleRemoveKeyword (keyword) {
        this.$store.commit(FILTERS_MUTATION_TYPES.REMOVE_ANNOUNCEMENT_KEYWORD, keyword)
      },
    },
    computed: {
      classes () {
        return {
          [`modal__container--${this.size}`]: this.size,
        }
      },
      announcementKeywords () {
        return this.$store.state.filters.announcementKeywords;
      },
    },
    mounted () {
      document.body.className = document.body.className + ' modal--open'
    },
    unmounted () {
      document.body.className = (document.body.className || '').replace('modal--open', '')
      window.removeEventListener('keyup', this.keyUpHandler)
    },
    components: {
      KeywordWithFacet
    }
  }
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";
.modal--scrollable {
  display: block!important;
  padding: 100px 0;
  overflow: auto;
  bottom: 0;
  >.modal__wrapper {
    display: block;
    margin: 0 auto;
  }
}
.modal--open .main-view,
.modal--open .main-view .actor-detail {
  overflow: hidden
}

.modal__body {
    .keyword {
        background-color:$color-background-light-grey;
        border: none;
        .keyword__label {
            color:$color-background-grey;
        }
    }
    .keyword::before {
        display:none;
    }
    .keyword:first-child {
        margin-top:10px;
    }
}
</style>
