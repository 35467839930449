<template>
  <div
      :class="{ 'ds-input': true, 'ds-input--has-icon': icon, 'ds-input--has-limit-helper': (maxLength > 0), 'is-simplified': isSimplified, 'is-onboarding-simplified': isOnboardingSimplified }"
      :data-limit-helper="limitHelper()"
      :style="'--helper-color: ' + colorTextHelper()"
  >
    <icon v-if="icon" :name="icon"/>
    <input
        ref="input"
        class="ds-input__input"
        :autofocus="autofocus"
        :readonly="readonly"
        :type="type"
        :value="model"
        :name="name"
        :maxLength="maxLength"
        :placeholder="placeholder"
        :disabled="disabled"
        :style="inputStyles"
        @blur="$emit('blur')"
        @focus="$emit('focus')"
        @input="handleInput"
        @change="handleChange"
        @keyup.enter="$emit('pressedEnter')"
        :autocomplete="autocomplete"
    >
  </div>
</template>

<script>
import { fromAmount, toAmount } from '../../util/currency'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    icon: {
      type: String
    },
    inputStyles: {
      type: Object,
      default: () => ({})
    },
    modelValue: null,
    placeholder: null,
    minLength: {
      type: Number,
      default: null,
    },
    maxLength: {
      type: Number,
      default: null,
    },
    isOnboardingSimplified: {
      type: Boolean,
      default: false
    },
    isSpottingAreaSimplified: {
      type: Boolean,
      default: false,
    },
    isSimplified: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'text'
    },
    name: {
      type: String
    },
    precision: {
      default: 0
    },
    readonly: {
      type: Boolean,
      default: false
    },
    noAutocomplete: {
      type: Boolean,
      default: false
    },
    disableAutoComplete: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    reference: null,
    autofocus: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue', 'blur', 'focus', 'pressedEnter'],
  computed: {
    model() {
      if (this.type === 'amount' && this.precision > 0) {
        return toAmount(this.modelValue, this.precision)
      }
      return this.modelValue
    },
    autocomplete() {
      if (this.disableAutoComplete) {
        return 'off'
      }

      return this.noAutocomplete ? 'new-password' : null
    },
  },
  methods: {
    focus() {
      this.$refs.input.focus()
    },
    handleInput(evt) {
      if (this.type === 'amount' && this.precision > 0) {
        return
      }
      const value = evt.target.value

      if (!this.reference) {
        this.$emit('update:modelValue', value)
      } else {
        this.$emit('update:modelValue', { value: value, reference: this.reference })
      }
    },
    handleChange(evt) {
      if (this.type === 'amount' && this.precision > 0) {
        this.$emit('update:modelValue', fromAmount(evt.target.value, this.precision))
      }
    },
    limitHelper() {
      if (this.maxLength > 0) {
        return (this.model.length + '/' + this.maxLength)
      }
    },
    colorTextHelper() {
      if (this.minLength > 0) {
        if (this.model.length < this.minLength) {
          return '#fd5505'
        }
      }

      return 'inherit'
    },
  }
})
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.ds-input--has-limit-helper input {
  padding-right: 75px;
}

.ds-input--has-limit-helper::after {
  content: attr(data-limit-helper);
  color: var(--helper-color);
  position: absolute;
  right: 8px;
  top: 50%;
  font-size: 11px;
  transform: translateY(-50%);
}
</style>
