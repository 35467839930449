<template>
  <canvas width="400" height="200">
  </canvas>
</template>

<script>
  import { Chart } from 'chart.js'
  import numeral from 'numeral'

  const createGradient = (colors, $el) => {
    const ctx = $el.getContext('2d')
    const result = ctx.createLinearGradient(0, 0, 600, 0)
    for (const i in colors) {
      result.addColorStop(i, colors[i])
    }
    return result
  }

  const parseColors = (dataset, $el) => {
    const result = {}
    if (!dataset) {
      return null
    }
    if (dataset.borderColor instanceof Array) {
      result.borderColor = createGradient(dataset.borderColor, $el)
    }
    if (dataset.pointBackgroundColor instanceof Array) {
      result.pointBackgroundColor = createGradient(dataset.pointBackgroundColor, $el)
    }
    return result
  }

  export default {
    name: 'SimpleLineChart',
    props: {
      data: Object,
      type: String,
      yStepSize: {
        type: Number,
        default: 0
      },
      moneyFormatted: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      computedData () {
        if (!this.data) {
          return null
        }

        return {
          ...this.data,
          datasets: this
            .data
            .datasets
            .map(dataset => ({
              ...dataset,
              ...parseColors(dataset, this.$el),
            })),
        }
      },
      options () {
        return {
          layout: {
            padding: {
              top: 30,
              bottom: 10,
              left: 10,
              right: 10,
            },
          },
          legend: {
            display: false,
          },
          scales: {
            xAxes: [{
              gridLines: {
                display: false
              },
              ticks: {
                fontColor: 'lightgrey'
              }
            }],
            yAxes: [{
              gridLines: {
                drawBorder: false,
              },
              ticks: {
                stepSize: this.yStepSize,
                fontColor: 'lightgrey',
                padding: 5,
                callback: function (value, index, values) {
                  if (this.moneyFormatted) {
                    return numeral(value).format('$0.00a')
                  }

                  return value
                }.bind(this),
                beginAtZero: true
              }
            }]
          },
          tooltips: {
            backgroundColor: 'var(--primary-lighter)',
            callbacks: {
              label: function (tooltipItem, data) {
                if (tooltipItem.yLabel && this.moneyFormatted) {
                  return numeral(tooltipItem.yLabel).format('$0.00a')
                }

                return tooltipItem.yLabel
              }.bind(this)
            }
          }
        }
      }
    },
    mounted () {
      this.chart = new Chart(this.$el, {
        type: this.type || 'line',
        data: this.computedData,
        options: this.options
      })
    },
    watch: {
      // Upon changing the data, this element is not redrawn (i.e. moving from one "info" actor tab to another using the "next actor button" will keep the same chart as the one of the actor before)
      data (data) {
        this.chart.destroy()

        this.chart = new Chart(this.$el, {
          type: this.type || 'line',
          data: this.computedData,
          options: this.options
        })
      }
    }
  }
</script>

<style lang="scss" scoped>
  canvas {
    background-color: #f7f7f7;
  }
</style>
