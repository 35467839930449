<template>
  <svg class="mini-chart" :viewBox="viewBox" :width="width" :height="height">
    <path class="fill-bg stroke-fg" :d="d"/>
    <path class="fill-bg stroke-fg" style="opacity:.3" :d="dBase" />
  </svg>
</template>

<script>
  export default {
    name: 'mini-chart',
    props: {
      values: {
        type: Array,
        default: []
      },
      width: {
        // type: Number,
        default: 100
      },
      height: {
        // type: Number,
        default: 100
      },
      base: {
        default: 100
      }
    },
    computed: {
      viewBox () {
        const padding = 2
        return (-padding) + ' ' + (-padding) + ' ' + (parseInt(this.width) + 2 * padding) + ' ' + (parseInt(this.height) + 2 * padding)
      },
      min () {
        return Math.min.apply(null, this.values.slice().concat(this.base))
      },
      max () {
        return Math.max.apply(null, this.values.slice().concat(this.base))
      },
      dBase () {
        const b = (this.height * (this.max - this.base) / (this.max - this.min))
        return 'M0 ' + b + 'L' + this.width + ' ' + b
      },
      d () {
        if (this.min === this.max) {
          return 'M0 100 L100 100'
        }
        const total = this.values.length - 1
        const res = 'M' + this.values
            .map((v, i) => (this.width / total * i) + ' ' + (this.height * (this.max - v) / (this.max - this.min)) + '')
            .join('L')
        return res
      }
    }
  }
</script>

<style lang="scss">
.mini-chart {
  /* Colors should be specified in more general css */
  .fill-fg {
    fill: #888;
  }
  .fill-bg {
    fill: transparent;
  }
  /* This is ok here */
  .stroke-fg {
    stroke: #666;
    stroke-width: 3px;
    stroke-linecap: round;
    stroke-linejoin: round;
  }
}
</style>
