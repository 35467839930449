<template>
  <button class="button radiobox-button" @click="handleChange" :class="classes">
    <input
      type="radio"
      :disabled="disabled"
      :name="name"
      :checked="checked"
      @change="handleChange"
      :required="required"
    >
    <span class="radio__label">{{ label }}</span>
    <span v-if="typeof disabled === 'string'" v-text="disabled"></span>
  </button>
</template>

<script>
  export default {
    props: {
      disabled: Boolean,
      inline: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        required: true
      },
      name: {
        type: String,
        required: true
      },
      // Value if checked
      val: {
        required: true
      },
      // Current value of v-model
      modelValue: {
        required: true
      },
      variant: {
        type: String,
        default: 'default'
      },
      size: {
        type: String,
        validator: val => ['extra-small', 'small', 'large'].includes(val)
      },
      block: String,
    },
    emits: ['update:modelValue'],
    computed: {
      checked () {
        return this.val === this.modelValue
      },
      classes () {
        return {
          'button--block': this.block !== undefined,
          [`button--${this.variant}`]: this.variant,
          [`button--${this.size}`]: this.size,
          'radio--inline': this.inline,
          'radio--disabled': this.disabled,
        }
      }
    },
    methods: {
      handleChange() {
        if (this.disabled) {
          return
        }

        this.$emit('update:modelValue', !this.val ? !this.modelValue : (this.checked ? null : this.val))
      }
    }
  }
</script>
