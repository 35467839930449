export const MUTATION_TYPES = {
  SET_SELECTED_REPORT_TEMPLATE: 'REPORTS/SET_SELECTED_REPORT_TEMPLATE',
  SET_FIRST_SELECTED_REPORT_TEMPLATE: 'REPORTS/SET_FIRST_SELECTED_REPORT_TEMPLATE',
  SET_OWNED_LEGAL_ENTITY: 'REPORTS/SET_OWNED_LEGAL_ENTITY',
}

export const mutations = {
  [MUTATION_TYPES.SET_SELECTED_REPORT_TEMPLATE] (state, reportTemplate) {
    state.selectedReportTemplate = reportTemplate
  },
  [MUTATION_TYPES.SET_FIRST_SELECTED_REPORT_TEMPLATE] (state, reportTemplate) {
    state.firstSelectedReportTemplate = reportTemplate
  },
  [MUTATION_TYPES.SET_OWNED_LEGAL_ENTITY] (state, ownedLegalEntity) {
    state.ownedLegalEntity = ownedLegalEntity
  }
}

export default {
  state: {
    selectedReportTemplate: {},
    firstSelectedReportTemplate: {},
    ownedLegalEntity: null
  },
  getters: {
    selectedReportTemplate (state) {
      return state.selectedReportTemplate
    },
    firstSelectedReportTemplate (state) {
      return state.firstSelectedReportTemplate
    },
    ownedLegalEntity (state) {
      return state.ownedLegalEntity
    }
  },
  mutations,
}
