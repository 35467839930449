<template>
  <div :class="{'simplified-image-card-with-cta': true, 'with-upgrade-overlay-cta': ctaUpgradeText}">
    <template v-if="ctaUpgradeText">
      <div class="card-cta__overlay">
        <h3 class="h3">
          <router-link :to="ctaUpgradeLink"  @click.capture="trackMatomo">{{
              ctaUpgradeText[1]
            }}
          </router-link>
        </h3>
      </div>
    </template>

    <template v-if="spottingArea">
      <simplified-image-card
        :name="spottingArea.name" :full-size="true"
        background-color="white"
        :image="getImageForSpottingArea(spottingArea)"
        :sub-title="spottingArea.description"
        :show-cta="true"
        :redirect-uri-login="'/login?redirect_uri=/spotting-areas/' + spottingArea.id"
        class="spotting-area-simplified-image-card"
        :left-align-text="true"
        :disabled="true"
      />
    </template>
  </div>
</template>

<script lang="ts">
  import FiltersMixin from '../../util/FiltersMixin.js'
  import {getDefaultFallbackImageUrl, getImageForSpottingArea, splitCtaText} from '../../util/helpers.js'
  import { defineComponent } from 'vue'
  import TranslationsMixin from "../../util/TranslationsMixin.js";
  import SimplifiedImageCard from "@/components/Connection/SimplifiedImageCard.vue";
  import {trackMatomoEvent} from "@/util/analytics.js";
  import {MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES, MATOMO_EVENT_NAMES} from "@/constants/analytics-constants.js";

  export default defineComponent({
    props: {
      spottingArea: Object,
      redirectUriLogin: {
        type: String,
        default: ''
      }
    },
    computed: {
      config () {
        return this.$store.state.config
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      isActor () {
        return this.$store.getters.isActor
      },
      subscriptionInfo () {
        return this.$store.state.user.profile.subscriptionInfo || {}
      },
      ctaType () {
        if (!this.isLoggedIn) {
          return 'loginRequired'
        }

        return 'upgradeRequired'
      },
      ctaUpgradeLink () {
        if (this.ctaType === 'loginRequired') {
          return '/login?redirect_uri=/spotting-areas/' + this.spottingArea.id
        }

        if (this.isActor && this.$store.getters.hasAccessToEcosystemMemberPackages) {
          return '/profile/subscription#upgrade'
        }

        return '/'
      },
      ctaUpgradeText () {
        if (this.ctaType === 'loginRequired') {
          return splitCtaText('To enjoy the content, simply log in.')
        }

        if (this.subscriptionInfo.package) {
          return this.intelligenceCtaUpgradeText
        }

        return '' //splitCtaText('Restricted access.')
      },
      intelligenceCtaUpgradeText () {
        let ctaUpgradeText = this.$t('default_intelligence_upgrade_cta')

        if (this.config.packageCTAs && this.config.packageCTAs.intelligence && this.config.packageCTAs.intelligence.text) {
          ctaUpgradeText = this.config.packageCTAs.intelligence.text
        }

        return splitCtaText(ctaUpgradeText)
      },
    },
    methods: {
      getImageForSpottingArea,
      getDefaultFallbackImageUrl,
      trackMatomo () {
        if (!this.ctaUpgradeText) {
          return
        }

        if (this.ctaType === 'loginRequired') {
          trackMatomoEvent(MATOMO_EVENT_CATEGORIES.INTERACTIONS, MATOMO_EVENT_ACTIONS.CLICK, MATOMO_EVENT_NAMES.SPOTTING_AREA_CTA_LOGIN)
        }

        trackMatomoEvent(MATOMO_EVENT_CATEGORIES.INTERACTIONS, MATOMO_EVENT_ACTIONS.CLICK, MATOMO_EVENT_NAMES.SPOTTING_AREA_CTA_UPGRADE)
      },
    },
    components: {
      SimplifiedImageCard
    },
    mixins: [FiltersMixin, TranslationsMixin],
  })
</script>

<style lang="scss">
  @import "../../../scss/variables";

  .simplified-image-card-with-cta {
    position: relative;

    &.with-upgrade-overlay-cta {
      overflow-y: hidden;
    }

    .card-cta__overlay {
      z-index: 1;
      position: absolute;
      height: 100%;
      width: 100%;
      background: rgba(255, 255, 255, 0.7);
      top: 0;
      transition: transform 300ms;
      transform: translate3d(0, 100%, 0);
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h3 {
        max-width: 60%;
        margin: 0 auto;
      }
    }

    &:hover,
    &:focus {
      .simplified-connection__logo {
        filter: grayscale(100%);
      }

      .card-cta__overlay {
        transform: translate3d(0, 0, 0);
      }

      &.with-upgrade-overlay-cta {
        overflow: hidden;

        //.challenge {
        //  background: none;
        //}
      }
    }

  }
</style>
