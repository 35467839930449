<template>
  <router-link
    :to="'/actors/' + connection.id"
    class="connection"
    :class="'connection--' + connection.type"
    :style="backgroundStyles"
  >
    <div class="connection__actor-name">{{connection.name}}</div>
    <div class="connection__relationship">{{relationship}}</div>
    <div class="connection__color-overlay" :style="{ backgroundColor: backgroundColor }"></div>
    <ds-button v-if="showRemoveIcon" icon="trash" size="extra-small" class="connection__remove" @click="handleRemoveRelation" />
  </router-link>
</template>

<script>
  import FiltersMixin from '../../util/FiltersMixin'

  export default {
    name: 'Connection',
    props: {
      actor: Object,
      connection: Object,
      showRemoveIcon: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      ecosystemRelationships () {
        return this.$store.getters.fullActorRelationships
      },
      backgroundStyles () {
        if (this.connection.featured_image_url) {
          return {
            backgroundImage: `url('${this.connection.featured_image_url}')`,
          }
        }

        return {
          backgroundImage: `url(https://picsum.photos/400/230)`,
        }
      },
      backgroundColor () {
        return this.legendColor(this.connection.legend)
      },
      relationship () {
        const inverseRelationType = this.ecosystemRelationships.find(relationshipType => relationshipType.inverse_name === this.connection.type)
        return `${inverseRelationType.label} ${this.actor.name}`
      },
    },
    methods: {
      handleRemoveRelation (e) {
        e.preventDefault()
        e.stopPropagation()
        this.$emit('remove', this.connection.type, this.connection.id)
      },
    },
    mixins: [FiltersMixin],
  }
</script>

<style lang="scss">
  @import "../../../scss/variables";

  .connection {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 1.5rem 1rem;
    margin-bottom: 1rem;
    text-decoration: none;
    color: #fff;
    text-align: center;
    background-size: cover;
    background-color: rgba(#444, 0.75);

    &:focus,
    &:active,
    &:visited {
      color: #fff;
    }
  }

  .connection__color-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background-color: #444;
    transition: background-color .2s;
    opacity: 0.75;
  }

  .connection__actor-name,
  .connection__relationship {
    position: relative;
    z-index: 2;
    text-shadow: 0 0 5px rgba(#000, 0.7);
  }

  .connection__actor-name {
    font-weight: 500;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    max-width: 90%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .connection__relationship {
    font-style: italic;
    font-size: 0.625rem;
  }

  .button.connection__remove {
    z-index: 2;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    width: 13px !important;
    height: 14px !important;
    padding: 1px 1px 1px 12px;
    .svg-icon {
      width: 12px;
      height: 12px;
      left: 0px;
    }
  }
</style>
