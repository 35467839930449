import { createJSONRequest, createPagedRequest, createResource } from './create-request'
import querify from '../util/querify'

export const fetchChanges = (filters) => createPagedRequest({
  url: `/api/changes?${querify(filters)}`,
})

export const fetchSignificantChanges = (actor_id) => createPagedRequest({
  url: `/api/significant-changes?actor_id=` + actor_id,
})

export const fetchAllSignificantChanges = () => createPagedRequest({
  url: `/api/significant-changes`,
})

export const fetchTelex = (filters) => createPagedRequest({
  url: `/api/telex?${querify(filters)}`,
})

export const fetchActorTelex = (actorId) => createPagedRequest({
  url: `/api/actors/${actorId}/telex`,
})

export const Suggestions = createResource('/api/suggestions')

export const mergeSuggestion = (id) => createJSONRequest({
  url: `/api/suggestions/${id}/merge`,
  method: 'POST',
})

export const fetchPortfolioCrawlerStatus = (actor_id) => createJSONRequest({
  url: `/api/suggestions/portfolio-crawler?actor_id=${actor_id}`,
  method: 'GET',
})

export const AllSuggestions = () => createJSONRequest({
  url: `/api/bulkSuggestions`,
  method: 'POST',
})

export const fetchNews = (filters) => createPagedRequest({
  url: `/api/news?${querify(filters)}`,
})

export const fetchScores = (filters) => createPagedRequest({
  url: `/api/actors?view=scores-list&${querify(filters)}`,
})

export const fetchInsights = (insight, date, community = '') => createJSONRequest({
  url: `/api/settings/insights?insight=${insight}&date=${date}&community_id=${community}`,
  method: 'GET',
})

export const fetchUserInsights = (insight, date, userId = '', range = '', specificDate) => createJSONRequest({
  url: `/api/settings/insights-user-behaviour?insight=${insight}&date=${date}&user_id=${userId}&range=${range}&specificDate=${specificDate}`,
  method: 'GET',
})

export const fetchDefaultUserBehaviour = (userId) => createJSONRequest({
  url: `/api/settings/insights-default-user-behaviour?user_id=${userId}`,
  method: 'GET',
})

export const fetchReportInsights = (insight, template) => createJSONRequest({
  url: `/api/settings/insights-reporting?insight=${insight}&template=${template}`,
  method: 'GET',
})

export const fetchReportInsightsData = (filters, template) => createPagedRequest({
  url: `/api/settings/insights-reporting-data?template=${template}&${querify(filters)}`,
  method: 'GET',
})

export const fetchDataConsumption = (date) => createJSONRequest({
  url: `/api/settings/data-consumption?date=${date}`,
  method: 'GET',
})

export const fetchDataFreshness = (date) => createJSONRequest({
  url: `/api/settings/data-freshness?date=${date}`,
  method: 'GET',
})
