<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-6 col-lg-2" style="max-width: 200px;">
        <dropdown :options="metricOptions" v-model="selectedMetric" />
      </div>
      <div class="col-xs-12 col-sm-9 col-lg-9">
        <ds-button @click="addMetric" label="Add Metric" variant="secondary" />
        <ds-button variant="secondary" icon="check" label="Save configuration" @click="save" />
        <ds-button variant="secondary" icon="check" label="Recalculate scores" @click="recalculateScores" v-if="scores && ! scores_are_recalculating" />
        <label variant="secondary" v-else-if="scores && scores_are_recalculating">Scores are being recalculated.</label>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="form-group__error" v-if="errors.message">{{ errors.message }}</div>
        <div class="form-group__error" v-if="errors.scores">{{ errors.scores[0] }}</div>
        <div class="form-group__success">{{ successMessage }}</div>
      </div>
    </div>
    <div class="row row__custom-scores" v-if="scores && scores.scores_configuration" style="margin-left: 5px;">
      <div class="col-md-12">
        <div class="row row__custom-scores">
          <div class="col-md-3" v-for="(metricConfiguration, metric) in scores.scores_configuration">
            <div class="row row__custom-scores">
              <form-group class="form-group__custom-scores">
                <input-range :label="humanize(metric)" v-model="scores.scores_configuration[metric].weight" :step="5" style="display: inline-block; width: 75%;"/>
                <ds-button size="small" variant="outline" icon="trash" @click="removeMetric(metric)" style="display: inline-block; float: right;" />
              </form-group>
            </div>

            <div class="row row__custom-scores">
              <form class="form-horizontal" style="width: 100%;" @submit.prevent="">
                <form-group class="form-group__custom-scores" style="width: 100%;">
                  <label>Add Parameter</label>
                  <template v-if="metric != 'vacancies'">
                    <dropdown :options="metricParameters(metric)" :reference="metric" @update:modelValue="addKpi" v-if="metricHasOptions(metric)"/>
                  </template>
                  <template v-else>
                    <div>
                      <ds-input v-model="metricKeywords[metric]" style="width: 70%; display: inline-block;" />
                      <ds-button size="small" label="Add" variant="outline" icon="plus" @click="addKeywordAsKpi(metric)" style="width: 20%; display: inline-block;" />
                    </div>
                  </template>
                </form-group>
              </form>
            </div>

            <div class="row row__custom-scores">
              <table class="table">
                <thead>
                  <tr>
                    <th style="width: 80%">Parameter</th>
                    <th style="width: 20%">Weight</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(weight, parameter) in scores.scores_configuration[metric].parameters">
                    <td style="vertical-align: middle;">{{parameter}}</td>
                    <td>
                      <ds-input v-model="scores.scores_configuration[metric].parameters[parameter]" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <br />
        <br/>
      </div>
    </div>
  </div>
</template>

<script>
import _sum from 'lodash/sum';
import _values from 'lodash/values';

import { Settings, recalculateScores } from '../../api/config.js';
import ConfigMixin from '../../util/ConfigMixin';

import InputRange from '../Slider/InputRange.vue';
import Dropdown from '../Dropdown/Dropdown.vue';
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      errors: {},
      successMessage: '',
      scores: {
        scores_configuration: {}
      },
      scores_are_recalculating: false,
      selectedMetric: '',
      metrics: [
        'employee_size',
        'activities',
        'industries',
        'technologies',
        'vacancies',
        //'digital_footprint' // TODO: what kind of metric would this be? just 1 - 1: 50 digital footprint score = 50 "points"
      ],
      metricKeywords: {
        vacancies: ''
      }
    }
  },
  computed: {
    metricOptions () {
      if (! this.scores || ! this.scores.scores_configuration) {
        return this.metrics;
      }

      var metrics = this.metrics;
      var selectedMetrics = Object.keys(this.scores.scores_configuration) || [];

      // Filter out options that were already in the scores configuration
      return metrics.filter(metric => ! selectedMetrics.includes(metric));
    },
    disabled () {
      return _values(this.sums).filter(s => s !== 100).length
    },
    sums () {
      return Object.keys(this.scores).reduce((carry, key) => {
        carry[key] = _sum(_values(this.scores[key]))
        return carry
      }, {})
    }
  },
  methods: {
    addKpi (metric) {
      if (! this.scores.scores_configuration[metric.reference]) {
        return;
      }

      var parameter = {};
      parameter[metric.val] = 0;

      this.scores.scores_configuration[metric.reference].parameters = Object.assign({}, this.scores.scores_configuration[metric.reference].parameters, parameter);
    },
    addKeywordAsKpi (metric) {
      if (! this.scores.scores_configuration[metric]) {
        return;
      }

      // Check if there's a non empty value for the metric that is passed
      var metricKeyword = this.metricKeywords[metric];

      if (! metricKeyword) {
        return;
      }

      var parameter = {};
      parameter[metricKeyword] = 0;

      this.scores.scores_configuration[metric].parameters = Object.assign({}, this.scores.scores_configuration[metric].parameters, parameter);
      this.metricKeywords[metric] = '';
    },
    metricHasOptions (metric) {
      return [
        'employee_size',
        'activities',
        'industries',
        'technologies',
      ].includes(metric);
    },
    metricParameters (metric) {
      var configuredParameters = this.getConfiguredOptions(metric);

      var values = [];

      switch (metric) {
        case 'employee_size':
          values = ['XS', 'S', 'M', 'L', 'XL', 'XXL'];
          break;
        case 'activities':
          values = this.activityLabels;
          break;
        case 'industries':
          values = this.$store.state.taxonomies.industries.map(i => i.name);
          break;
        case 'technologies':
          values = this.technologyLabels;
          break;
        default:
          values = [];
      }

      return values.filter(val => ! configuredParameters.includes(val));
    },
    addMetric() {
      // Don't add the metric to the scores array if it's already in there
      if (! this.selectedMetric || this.scores.scores_configuration[this.selectedMetric]) {
        return;
      }

      var scoreConfig = {};
      scoreConfig[this.selectedMetric] = {
        weight: 0,
        parameters: {}
      };

      var metricParameters = this.metricParameters(this.selectedMetric);

      if (metricParameters && metricParameters.length > 0) {
        for (var index = 0; index < metricParameters.length; index++) {
          scoreConfig[this.selectedMetric].parameters[metricParameters[index]] = 0;
        }
      }

      this.scores.scores_configuration = Object.assign({}, this.scores.scores_configuration, scoreConfig);
    },
    removeMetric (metric) {
      var scoresConfiguration = this.scores.scores_configuration;
      delete scoresConfiguration[metric];

      this.scores.scores_configuration = Object.assign({}, this.scores.scores_configuration, scoresConfiguration);
    },
    getConfiguredOptions (metric) {
      if (! this.scores.scores_configuration || ! this.scores.scores_configuration[metric]) {
        return [];
      }

      return Object.keys(this.scores.scores_configuration[metric].parameters);
    },
    fetchScores () {
      Settings.get('scores')
        .then(data => {
          this.scores = data.scores;
          this.scores.scores_configuration = data.scores.scores_configuration || {};
          this.scores_are_recalculating = data.scores_are_recalculating;
          this.errors = {};
        })
        .catch(errors => {
          this.errors = errors;
        })
    },
    humanize (str) {
      return str && (str[0].toUpperCase() + str.replace(/_/g, ' ').slice(1));
    },
    save () {
      Settings.post({
        id: 'scores',
        scores: this.scores
      }).then(data => {
        this.scores = data.scores;
        this.errors = {};
        this.successMessage = "The configuration has been saved.";

        setTimeout(() => {
          this.successMessage = '';
        }, 4000)

      }).catch(errors => {
        this.errors = errors;
      })
    },
    recalculateScores () {
      recalculateScores()
      .then(data => {
        this.scores_are_recalculating = true;
      })
      .catch();
    }
  },
  mounted () {
    this.fetchScores();
  },
  mixins: [ConfigMixin],
  components: {
    InputRange,
    Dropdown
  }
})
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";

.row__custom-scores {
  max-width: 100% !important;
  margin-bottom: 10px !important;
}

.delete__custom-scores {
  margin-top: 20px;
  margin-left: 30px;
}

.form-group__custom-scores {
  width: 100%;
  display: inline-block;
  margin-bottom: 0px !important;
}

.settings .noUi-target {
  margin: 10px 0;
}
.settings .noUi-origin {
  background: #ddd;
}
.settings .noUi-base {
  background: $color-primary;
}
.recalculating {
  opacity: .5;
  pointer-events: none;
}
</style>
