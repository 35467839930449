<template>
  <div class="fields">
    <div>
      <h3 class="h3" style="margin-bottom: 1rem;">Report templates</h3>
      <dropdown v-model="selectedReportDropdownOption" :options="reportOptions" style="width: 300px; margin-bottom: 1rem;" v-if="reportOptions.length > 0 && !addingNewTemplate"/>
      <ds-button label="Create new template" icon="plus" @click="createNewReportTemplate()" v-if="!addingNewTemplate"/>
      <ds-button label="" icon="trash" @click="deleteReportTemplate()" v-if="selectedReport && selectedReport.id >= 1"/>
    </div>

    <form-group :label="addingNewTemplate ? 'New Report Label' : 'Report Label'" style="width: 300px; margin-bottom: 1rem;">
      <ds-input placeholder="Report Template" name="report-label" v-model="selectedReport.name" :errors="errors.name"/>
    </form-group>
    <form-group label="Enable this report for">
      <checkbox v-model="selectedReport.enabled_for_legal_entity" inline label="Organization"/>
      <checkbox v-model="selectedReport.enabled_for_person" inline label="Person" :disabled="selectedReport.is_call"/>
      <checkbox v-model="selectedReport.enabled_for_product" inline :label="productLabel" :disabled="selectedReport.is_call"/>
    </form-group>

    <form-group label="Configure what actors can access the reports feature by selecting one or more portfolios (leave empty to allow all actors)">
      <AutocompleteTagInput
        :tags="selectedReport.portfolios"
        :options="portfolioOptions"
        :addOnlyFromAutocomplete="true"
        placeholder="Search for a portfolio"
        @tagChanged="updatePortfolioOptions"
        @input:raw="updateSelectedPortfolios"
        :minInputLength="1"
        style="width: 300px;"
      />
    </form-group>

    <form-group label="Display changes of reports over time (by month)">
      <checkbox v-model="selectedReport.show_historical_data" inline
                label="Show historical data" :disabled="selectedReport.is_call"/>
    </form-group>
    <form-group label="Enable &quot;call&quot; functionality, allowing users to submit a permanent version of the report">
      <checkbox v-model="selectedReport.is_call" inline :disabled="disableCallsForSelectedReport"
                label="Enable &quot;call&quot; functionality" style="margin-right: 0"/>
      <template v-if="selectedReport.is_call">
        with due date on
        <datepicker :model-value="getDueDateValue('date')" @update:modelValue="setDueDateValue('date', $event)" class="reporting-fields-due-date__date" />
        at
        <input type="number"
               class="reporting-fields-due-date__time"
               :value="getDueDateValue('hours')"
               @change="setDueDateValue('hours', $event.target.value)"
               :min="0"
               :max="23">
        :
        <input type="number"
               class="reporting-fields-due-date__time"
               :value="getDueDateValue('minutes') >= 10 ? getDueDateValue('minutes') : '0' + getDueDateValue('minutes')"
               @change="setDueDateValue('minutes', $event.target.value)"
               :min="0"
               :max="59">
        &nbsp;(timezone: Central European Time)
      </template>
    </form-group>
    <ds-button label="Save new template" variant="primary" @click="saveNewReportTemplate()" v-if="addingNewTemplate"/>
    <ds-button label="Cancel" variant="secondary" @click="cancelNewReportTemplate()" v-if="addingNewTemplate && reports.length > 0"/>
    <ds-button @click="updateReport" variant="secondary" label="Update" v-if="reportPropertiesHaveChanged && !addingNewTemplate"/>

    <div v-if="!addingNewTemplate">
      <div style="margin: 2em 0;">
        <h3 class="h2">Fields</h3>
        <table class="table" v-if="selectedReport.reportFields.length">
          <thead>
          <tr>
            <td class="reporting-fields-table__small-column">Type</td>
            <td class="reporting-fields-table__normal-column">Label</td>
            <td class="reporting-fields-table__normal-column">Options</td>
            <td class="reporting-fields-table__small-column">Use as filter</td>
            <td class="reporting-fields-table__small-column" v-if="selectedReport.is_call">Required for submission</td>
            <td class="reporting-fields-table__small-column"></td>
            <template v-if="editingFields == false">
              <td class="reporting-fields-table__small-column"></td>
            </template>
          </tr>
          </thead>
          <tbody>
          <template v-for="(field, index) in selectedReport.reportFields">
            <tr v-if="!fieldsToEdit.includes(field.id)">
              <td v-text="humanizeReportType(field.type)"></td>
              <td v-text="field.label"></td>
              <td>{{ Array.isArray(field.options) ? field.options.join(', ') : '' }}</td>
              <td>
                <input
                  type="checkbox"
                  :checked="field.can_be_used_as_filter"
                  @change="toggleFilter(field)"
                  :disabled="saving"
                  style="margin-left: 35%;"
                  v-if="filterableFieldTypes.includes(field.type)"
                >
              </td>
              <td v-if="selectedReport.is_call">
                <input
                  type="checkbox"
                  :checked="field.required_for_submission"
                  @change="toggleRequired(field)"
                  :disabled="saving"
                  style="margin-left: 35%;"
                  v-if="requiredForSubmissionFieldTypes.includes(field.type)"
                >
              </td>
              <td class="fields__tdbtn">
                <ds-button icon="edit" size="small" @click="addIdForEditing(field.id)" :disabled="saving"/>
                <ds-button icon="trash" size="small" @click="remove(field)" :disabled="saving"/>
              </td>
              <!-- We hide the arrows when editing the fields so that we don't experience problems while editing and changing the order of the fields -->
              <template v-if="editingFields == false">
                <td class="fields__tdbtn fields__tdbtn__arrows-container">
                  <ds-button icon="chevron-up" size="small" @click="changeItemOrder(field, index, 'up')" :disabled="saving"/>
                  <ds-button icon="chevron-down" size="small" @click="changeItemOrder(field, index, 'down')" :disabled="saving"/>
                </td>
              </template>
            </tr>
            <tr v-else>
              <td>
                <dropdown :options="dropdownTypeOptions" v-model="selectedReport.reportFields[index].type" :disabled="isFieldTypeDropdownDisabled(field)"/>
              </td>
              <td>
                <ds-textarea v-model="selectedReport.reportFields[index].label"/>
              </td>
              <td>
                <template v-if="multiValueFields.includes(field.type)">
                  <tag-input v-model="field.options"/>
                </template>
              </td>
              <td class="fields__tdbtn">
                <ds-button icon="check" size="small" @click="saveEditedField(selectedReport.reportFields[index], selectedReport.reportFields[index].id)"/>
                <ds-button icon="trash" size="small" disabled/>
              </td>
            </tr>
          </template>
          </tbody>
        </table>
      </div>

      <div>
        <h3 class="h2">Add report field</h3>
        <form @submit.prevent="addField">
          <form-group label="Label">
            <ds-input v-model="field.label"/>

            <div class="form-group__error" style="font-size: 14px" v-if="doesLabelAlreadyExist">
              A report field with this name already exists.
            </div>
          </form-group>
          <form-group label="Type">
            <radio-button
              v-for="(type, index) in typeOptions"
              :key="index"
              v-model="field.type"
              name="type"
              inline
              :label="type.label"
              :val="type.type"
            />
          </form-group>
          <form-group label="Options" v-if="['options', 'multi_select', 'checkbox'].includes(field.type)">
            <tag-input v-model="field.options"/>
          </form-group>
          <form-group label="hidden">
            <ds-button type="submit" variant="secondary" label="Add field" :disabled="! newFieldValid"/>
          </form-group>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import AutocompleteTagInput from '../Form/AutocompleteTagInput.vue'
  import RadioButton from '../Form/RadioButton.vue'
  import Checkbox from '../Form/Checkbox.vue'
  import Dropdown from '../Dropdown/Dropdown.vue'
  import TagInput from '../Form/TagInput.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import MODAL_IDS from '../../constants/modal-ids.js'

  import { EcosystemReports, updateReportField, addReportField, deleteReportField, updateFieldsOrder } from '../../api/reporting.js'
  import { ACTION_TYPES as CONFIG_ACTION_TYPES } from '../../store/modules/config.js'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import Datepicker from '../Form/Datepicker.vue'
  import InputRange from '../Slider/InputRange.vue'
  import DsTextarea from '../Form/DsTextarea.vue'
  import debounce from 'lodash/throttle.js'
  import { fetchPortfolio } from '../../api/portfolios.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Reporting',
    components: {
      AutocompleteTagInput,
      InputRange,
      Datepicker,
      Checkbox,
      RadioButton,
      DsTextarea,
      Dropdown,
      TagInput,
    },
    props: {},
    data () {
      return {
        portfolioOptions: [],
        selectedReport: {
          id: null,
          name: '',
          reportFields: [],
          portfolios: [],
          enabled_for_community: false,
          enabled_for_legal_entity: false,
          enabled_for_person: false,
          enabled_for_product: false,
          show_historical_data: false,
          is_call: false,
          due_date: null,
        },
        reports: [],
        originalReport: {
          id: null,
          name: '',
          reportFields: [],
          portfolios: [],
          enabled_for_community: false,
          enabled_for_legal_entity: false,
          enabled_for_person: false,
          enabled_for_product: false,
          show_historical_data: false,
          is_call: false,
          due_date: null,
        },
        editing: false,
        editingFields: false,
        saving: false,
        fieldsToEdit: [], // Will have the Ids of the fields that are being edited
        modifiedFields: [],
        errors: {},
        dropdownTypeOptions: ['title', 'subtitle', 'text', 'score', 'number', 'file', 'embed', 'options', 'multi_select', 'checkbox'],
        typeOptions: [
          { type: 'title', label: 'Title' },
          { type: 'subtitle', label: 'Subtitle' },
          { type: 'text', label: 'Text' },
          { type: 'score', label: 'Score 0-5' },
          { type: 'number', label: 'Number' },
          { type: 'file', label: 'File' },
          { type: 'embed', label: 'Embed' },
          { type: 'options', label: 'Dropdown' },
          { type: 'multi_select', label: 'Multi Select' },
          { type: 'checkbox', label: 'Checkbox' },
          { type: 'date', label: 'Date' },
          { type: 'multi_select_actor', label: 'Multi Select Actor' },
        ],
        multiValueFields: ['options', 'multi_select', 'checkbox'],
        filterableFieldTypes: ['options', 'number', 'date', 'score', 'checkbox', 'multi_select'],
        requiredForSubmissionFieldTypes: ['options', 'number', 'date', 'score', 'checkbox', 'multi_select', 'multi_select_actor', 'embed', 'file', 'text'],
        field: this.newField(),
      }
    },
    computed: {
      disableCallsForSelectedReport () {
        // don't disable if it's already a call, so that it can be disabled if necessary (this should never happen)
        return !this.selectedReport.is_call && (
          this.selectedReport.show_historical_data ||
          this.selectedReport.enabled_for_community ||
          this.selectedReport.enabled_for_person ||
          this.selectedReport.enabled_for_product ||
          !this.selectedReport.enabled_for_legal_entity
        )
      },
      reportPropertiesHaveChanged () {
        if (!this.selectedReport.name || this.selectedReport.name.length < 2) {
          return false
        }

        return this.originalReport.name !== this.selectedReport.name ||
        this.originalReport.enabled_for_legal_entity !== this.selectedReport.enabled_for_legal_entity ||
        this.originalReport.enabled_for_person !== this.selectedReport.enabled_for_person ||
        this.originalReport.enabled_for_community !== this.selectedReport.enabled_for_community ||
        this.originalReport.enabled_for_product !== this.selectedReport.enabled_for_product ||
        this.originalReport.show_historical_data !== this.selectedReport.show_historical_data ||
        this.originalReport.is_call !== this.selectedReport.is_call ||
        this.originalReport.portfolios !== this.selectedReport.portfolios ||
        new Date(this.originalReport.due_date).getTime() !== new Date(this.selectedReport.due_date).getTime()
      },
      legalEntityHasReport () {
        var reports = this.reports.filter(report => report.enabled_for_legal_entity == true)

        return reports.length > 0
      },
      personHasReport () {
        var reports = this.reports.filter(report => report.enabled_for_person == true)

        return reports.length > 0
      },
      productHasReport () {
        var reports = this.reports.filter(report => report.enabled_for_product == true)

        return reports.length > 0
      },
      communityHasReport () {
        var reports = this.reports.filter(report => report.enabled_for_community == true)

        return reports.length > 0
      },
      selectedReportDropdownOption: {
        get () {
          if (!this.selectedReport || !this.selectedReport.id) {
            return null
          }

          return {
            value: this.selectedReport.id,
            label: this.selectedReport.name,
          }
        },
        set (reportId) {
          var report = this.reports.find(p => p.id == reportId)

          this.selectedReport = report

          var portfolioOptions = []
          this.selectedReport.portfolios.forEach(portfolio => {
            portfolioOptions.push({
              text: portfolio.name,
              optionValue: {
                text: portfolio.name,
                id: portfolio.id
              }
            })
          })
          this.selectedReport.portfolios = portfolioOptions

          this.originalReport = { ...report }
          this.errors = {}
        },
      },
      reportOptions () {
        if (!this.reports || this.reports.length === 0) {
          return []
        }

        return this.reports.map(report => {
          return {
            value: report.id,
            label: report.name,
          }
        })
      },
      addingNewTemplate () {
        return this.selectedReport && this.selectedReport.id == -1
      },
      newFieldValid () {
        const existingLabels = (this.selectedReport.reportFields || []).map(report => report.label.toLowerCase()) || []

        if (!this.field.label || this.field.label.length === 0 || existingLabels.includes(this.field.label.toLowerCase())) {
          return false
        }

        if (this.multiValueFields.includes(this.field.type)) {
          return this.field.options.length > 0
        }

        return true
      },
      isKnowledgeSharingEnabled () {
        return this.form.knowledgeSharing ? this.form.knowledgeSharing.enabled : false
      },
      form () {
        return this.$parent.$parent.form
      },
      doesLabelAlreadyExist () {
        if (this.saving) {
          return false
        }

        const existingLabels = (this.selectedReport.reportFields || []).map(report => report.label.toLowerCase()) || []

        return existingLabels.includes(this.field.label.toLowerCase())
      },
    },
    methods: {
      updatePortfolioOptions: debounce(async function (query) {
        if (!query) {
          return
        }
        // This will search for a portfolio which matches the input value in the search field
        var filters = Object.assign({}, { query: query, limit: 200 })
        fetchPortfolio(filters)
          .then(portfolios => {
            this.portfolioOptions = []
            this.parsePortfolioOptions(portfolios)
          }).catch(errors => {
            console.log(errors)
          })
      }, 150),
      parsePortfolioOptions (portfolios) {
        if (!Array.isArray(portfolios)) {
          return
        }
        var options = []
        portfolios.forEach(portfolio => {
          if (portfolio.virtual) {
            return
          }
          options.push({
            text: portfolio.name,
            id: portfolio.id,
          })
        })
        this.portfolioOptions = options
      },
      updateSelectedPortfolios (input) {
        this.selectedReport.portfolios = input
      },
      async toggleFilter (field) {
        field.can_be_used_as_filter = !field.can_be_used_as_filter

        this.saveEditedField(field, field.id)
      },
      async toggleRequired (field) {
        field.required_for_submission = !field.required_for_submission

        this.saveEditedField(field, field.id)
      },
      setDueDateValue (type, value) {
        switch (type) {
          case 'date':
            const values = value.split('-')
            this.selectedReport.due_date = new Date(value)
            this.selectedReport.due_date.setFullYear(values[0])
            this.selectedReport.due_date.setMonth(values[1] - 1)
            this.selectedReport.due_date.setDate(values[2])
            break
          case 'hours':
            this.selectedReport.due_date = new Date(this.selectedReport.due_date).setHours(value)
            break
          case 'minutes':
            this.selectedReport.due_date = new Date(this.selectedReport.due_date).setMinutes(value)
            break
        }
      },
      getDueDateValue (type) {
        if (!this.selectedReport.due_date) {
          this.selectedReport.due_date = new Date()
        }

        switch (type) {
          case 'date':
            return new Date(this.selectedReport.due_date).toLocaleDateString()
          case 'hours':
            return new Date(this.selectedReport.due_date).getHours()
          case 'minutes':
            return new Date(this.selectedReport.due_date).getMinutes()
        }
      },
      async refreshTemplates () {
        this.reports = await EcosystemReports.get()

        if (this.reports.length > 0) {
          this.selectedReport = this.reports[0]

          // Parse selected template portfolios
          var portfolioOptions = []
          this.selectedReport.portfolios.forEach(portfolio => {
            portfolioOptions.push({
              text: portfolio.name,
              optionValue: {
                text: portfolio.name,
                id: portfolio.id
              }
            })
          })
          this.selectedReport.portfolios = portfolioOptions

          this.originalReport = { ...this.selectedReport }
        } else {
          this.createNewReportTemplate()
        }
      },
      async updateReport () {
        var reportChange = {
          id: this.selectedReport.id,
          name: this.selectedReport.name,
          enabled_for_community: this.selectedReport.enabled_for_community,
          enabled_for_legal_entity: this.selectedReport.enabled_for_legal_entity,
          enabled_for_person: this.selectedReport.enabled_for_person,
          enabled_for_product: this.selectedReport.enabled_for_product,
          portfolios: this.selectedReport.portfolios.map(portfolio => portfolio.optionValue.id),
          show_historical_data: this.selectedReport.show_historical_data,
          is_call: this.selectedReport.is_call,
          due_date: this.selectedReport.due_date && (new Date(this.selectedReport.due_date).getTime() / 1000),
        }

        try {
          this.selectedReport = await EcosystemReports.post(reportChange)

          // parse portfolio options
          var portfolioOptions = []
          this.selectedReport.portfolios.forEach(portfolio => {
            portfolioOptions.push({
              text: portfolio.name,
              optionValue: {
                text: portfolio.name,
                id: portfolio.id
              }
            })
          })
          this.selectedReport.portfolios = portfolioOptions

          this.originalReport = { ...this.selectedReport }

          await this.refreshTemplates()
        } catch (e) {
          console.log(e)
        }
      },
      createNewReportTemplate () {
        this.selectedReport = {
          id: -1,
          name: 'Report Template',
          enabled_for_community: false,
          enabled_for_legal_entity: false,
          enabled_for_person: false,
          enabled_for_product: false,
          show_historical_data: false,
          portfolios: [],
          is_call: false,
          due_date: null,
          reportFields: [],
        }
      },
      cancelNewReportTemplate () {
        this.selectedReport = {
          id: null,
          name: '',
          reportFields: [],
          enabled_for_community: false,
          enabled_for_legal_entity: false,
          enabled_for_person: false,
          enabled_for_product: false,
          show_historical_data: false,
          portfolios: [],
          is_call: false,
          due_date: null,
        }

        if (this.reports.length > 0) {
          this.selectedReport = this.reports[0]
          this.originalReport = { ...this.selectedReport }
        }
      },
      async saveNewReportTemplate () {
        this.saving = true

        var newReport = this.selectedReport
        delete newReport.id

        try {
          this.selectedReport.portfolios = this.selectedReport.portfolios.map(portfolio => portfolio.optionValue.id)
          this.selectedReport = await EcosystemReports.post(this.selectedReport)

          // parse portfolio options
          var portfolioOptions = []
          this.selectedReport.portfolios.forEach(portfolio => {
            portfolioOptions.push({
              text: portfolio.name,
              optionValue: {
                text: portfolio.name,
                id: portfolio.id
              }
            })
          })
          this.selectedReport.portfolios = portfolioOptions

          this.originalReport = { ...this.selectedReport }
          await this.refreshTemplates()
        } catch (e) {
          console.log(e)
        }

        this.saving = false
      },
      deleteReportTemplate () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, { report: this.selectedReport, modalContextType: 'reportTemplate' })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
      },
      newField () {
        return {
          label: '',
          type: 'text',
          options: [],
          can_be_used_as_filter: false,
          required_for_submission: false
        }
      },
      async addField () {
        if (this.field.label.length < 3) {
          window.alert('The field label must be at least 3 characters.')

          return
        }

        const { type } = this.field
        this.field.can_be_used_as_filter = this.filterableFieldTypes.includes(this.field.type)
        this.selectedReport.reportFields.push(this.field)

        try {
          if (this.selectedReport.id > 0) {
            this.saving = true
            this.selectedReport = await addReportField(this.selectedReport.id, this.field)
            EcosystemReports
              .get()
              .then(response => {
                this.reports = response
              })
          }
        } catch (e) {
          console.log(e)
        }

        this.saving = false

        // Reset the new field
        this.field = this.newField()
        this.field.type = type
      },
      remove (field) {
        if (field.actorsCount !== 0) {
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, { field: field, modalContextType: 'reportField', count: field.actorsCount, reportId: this.selectedReport.id })
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)

          return
        }

        this.deleteField(field)
      },
      deleteField (field) {
        this.saving = true

        deleteReportField(this.selectedReport.id, field.id)
          .then(response => {
            this.selectedReport = response
            this.saving = false
          })
          .catch(error => {
            console.log(error)
            this.saving = false
          })
      },
      deleteTemplate (templateId) {
        this.saving = true

        EcosystemReports
          .delete(templateId)
          .then(response => {
            this.refreshTemplates()
          })
          .catch(e => {
            console.log(e)
          })
      },
      addIdForEditing (fieldId) {
        this.fieldsToEdit.push(fieldId)
      },
      removeIdForEditing (fieldId) {
        var index = this.fieldsToEdit.indexOf(fieldId)
        if (index > -1) {
          this.fieldsToEdit.splice(index, 1)
        }
      },
      saveEditedField (field, fieldId) {
        this.removeIdForEditing(fieldId)

        this.saving = true

        updateReportField(this.selectedReport.id, fieldId, field)
          .then(response => {
            this.saving = false
            this.selectedReport = response
          })
          .catch(error => {
            console.log(error)
            this.saving = false
          })
      },
      async changeItemOrder (field, originalIndex, direction) {
        var otherItem // Item that was forced to move position

        switch (direction) {
          case 'up':
            otherItem = this.selectedReport.reportFields[originalIndex - 1]

            if (originalIndex == 0) {
              return
            }

            this.selectedReport.reportFields.splice(originalIndex, 1)
            this.selectedReport.reportFields.splice(originalIndex - 1, 1)

            // Reinserts the items in their new positions
            this.selectedReport.reportFields.splice(originalIndex - 1, 0, field)
            this.selectedReport.reportFields.splice(originalIndex, 0, otherItem)
            break
          case 'down':
            otherItem = this.selectedReport.reportFields[originalIndex + 1]

            if (originalIndex == this.selectedReport.reportFields.length - 1) {
              return
            }

            this.selectedReport.reportFields.splice(originalIndex + 1, 1)
            this.selectedReport.reportFields.splice(originalIndex, 1)

            // Reinserts the items in their new positions
            this.selectedReport.reportFields.splice(originalIndex, 0, otherItem)
            this.selectedReport.reportFields.splice(originalIndex + 1, 0, field)
            break
        }

        try {
          this.selectedReport = await updateFieldsOrder(this.selectedReport.id, this.selectedReport.reportFields)
        } catch (e) {
          console.log(e)
        }
      },
      isFieldTypeDropdownDisabled (field) {
        if (field.type == 'title' || field.type == 'subtitle') {
          return false
        }

        return field.actorsCount !== 0
      },
      humanizeReportType (type) {
        if (type == 'options') {
          return 'dropdown'
        }

        return type
      },
    },
    async mounted () {
      await this.refreshTemplates()

      this.$bus.on('deleteReportFieldConfirmation', (context) => {
        if (context.reportId === this.selectedReport.id) {
          this.deleteField(context.field)
        }
      })

      this.$bus.on('deleteReportTemplateConfirmation', (context) => {
        if (context.report.id === this.selectedReport.id) {
          this.deleteTemplate(context.report.id)
        }
      })
    },
    beforeUnmount () {
      this.$bus.off('deleteReportFieldConfirmation')
      this.$bus.off('deleteReportTemplateConfirmation')

      this.$store.dispatch(CONFIG_ACTION_TYPES.REFRESH_CONFIG)
    },
    watch: {
      fieldsToEdit (array) {
        if (array.length > 0) {
          this.editingFields = true
        } else {
          this.editingFields = false
        }
      },
    },
    mixins: [TranslationsMixin]
  })
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

  .reporting-fields-due-date__date {
    width: 100px;
    display: inline-block;
  }

  .reporting-fields-due-date__time {
    height: 36px;
    padding-left: 10px;
    border-radius: 0;
    border: 1px solid $color-borders;
  }

  .fields__tdbtn {
    padding: 0px !important;
  }

  .fields__tdbtn__arrows-container {
    background-color: $color-background-lightest-grey;

    .button {
      .svg-icon {
        width: 15px;
        height: 15px;
        left: 6px;
        padding-top: 3px;
      }
    }
  }

  .fields__tdbtn > .button {
    margin: 0px 0px !important;
    display: inline-block;
    width: calc(100% + 20px);
    font-size: 12px;

    .svg-icon--edit {
      left: 7px;
    }

    .svg-icon--remove {
      left: 7px;
    }
  }

  .reporting-fields-table__small-column {
    width: 5rem;
  }

  .reporting-fields-table__normal-column {
    width: 15rem;
  }
</style>
