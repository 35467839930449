<template>
  <data-point-card :title="proofPoint.signalItem.name || proofPoint.signalItem.title" :sub-title="subtitle" :show-image="showImage" :logo-url="proofPointLogo" :url="signalLink"
                   @click="onClickSignalCard">
    <template v-slot:body>
      {{ proofPoint.argumentation }}
    </template>
    <template v-slot:footer v-if="proofPoint.userName || proofPoint.signalDate">
      ⓘ
      <template v-if="proofPoint.signalDate">
        {{ proofPoint.signalDate }}
      </template>
      <template v-if="proofPoint.signalDate && proofPoint.userName">
        -
      </template>
      <template v-if="proofPoint.userName">
        {{ proofPoint.userName }}
      </template>
    </template>
  </data-point-card>
</template>

<script>
  import { proofPointItemTypes } from '../../constants/proofPoints.js'
  import DataPointCard from './DataPointCard.vue'

  export default {
    props: {
      proofPoint: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    data () {
      return {
        proofPointItemTypes,
      }
    },
    methods: {
      onClickSignalCard () {
        if (this.proofPoint.signalItem.url && this.proofPoint.signalItem.signalItemType !== proofPointItemTypes.ACTOR) {
          window.open(this.proofPoint.signalItem.url)
        }
      },
    },
    computed: {
      signalLink () {
        switch (this.proofPoint.signalItem.signalItemType) {
          case proofPointItemTypes.ACTOR:
            return `/actors/${this.proofPoint.signalItem.id}`
          default:
            return ''
        }
      },
      subtitle () {
        const results = []
        switch (this.proofPoint.signalItem.signalItemType) {
          case proofPointItemTypes.FILE:
            if (this.proofPoint.signalItem.source) {
              results.push(this.proofPoint.signalItem.source)
            }
            break
          case proofPointItemTypes.ARTICLE:
            if (this.proofPoint.signalItem.created_at) {
              results.push(this.proofPoint.signalItem.created_at)
            }
            break
          case proofPointItemTypes.ACTOR:
            if (this.proofPoint.signalItem.country) {
              results.push(this.proofPoint.signalItem.country)
            }
            if (this.proofPoint.signalItem.category) {
              results.push(this.proofPoint.signalItem.category)
            }
            break
          default:
            results.push('subtitle')
            break
        }
        return results.join(' • ')
      },
      proofPointLogo () {
        return this.proofPoint.signalItem.logo
      },
      proofPointItemType () {
        if (!this.proofPoint || !this.proofPoint.signalItem || !this.proofPoint.signalItem.proofPointItemType) {
          return ''
        }
        return this.proofPoint.signalItem.proofPointItemType
      },
      showImage () {
        return this.proofPointItemType === proofPointItemTypes.ACTOR
      },
    },
    mounted () {
    },
    components: {
      DataPointCard,
    },
  }
</script>
