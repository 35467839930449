<template>
  <with-configuration-tabs>
    <div class="settings fixed-heading fullScroll">
      <div class="heading">
        <h1>Data Sources</h1>
        <p class="guide_text">Configure data sources or data feeds to import data into the
          DataScouts platform.</p>
      </div>

      <div class="has-padding scrollable">
        <div class="has-action-bar">
          <div class="tabs-border">
            <ul class="tabs__navigation">
              <li class="tabs__navigation__label">
                Sources:
              </li>
              <li
                v-for="tab in tabs"
                class="tabs__navigation__item"
                :class="{ 'tabs__navigation__item--active': activeTab === tab }"
                @click="activeTab = tab"
                v-text="tab"
              ></li>
            </ul>
          </div>

          <component :is="panel"/>
        </div>
      </div>
    </div>
  </with-configuration-tabs>
</template>

<script>
  import Spreadsheets from './Spreadsheets.vue'
  import Feeds from './Feeds.vue'
  import OutletSources from './OutletSources.vue'
  import ContentApis from './ContentApis.vue'
  import WithConfigurationTabs from '../../pages/WithConfigurationTabs/WithConfigurationTabs.vue'
  import $store from '../../store'
  import { defineComponent } from 'vue'

  export default defineComponent({
    components: {
      Spreadsheets,
      WithConfigurationTabs,
      Feeds,
      OutletSources,
      ContentApis,
    },
    data () {
      return {
        activeTab: 'data sources',
      }
    },
    computed: {
      tabs () {
        var tabs = ['data sources']

        if (this.$store.getters.hasAccessToMonitoring && !$store.getters.hasAccessToActorScouting) {
          tabs.push('rss feeds')
        }

        // if (['local', 'community', 'tom', 'vopak-monitoring'].includes(this.$store.getters.ecosystemName.toLowerCase())) {
          tabs.push('news outlets')
        // }

        if (['local', 'own', 'pomwvl'].includes(this.$store.getters.ecosystemName.toLowerCase())) {
          tabs.push('content api\'s')
        }

        return tabs
      },
      panel () {
        switch (this.activeTab) {
          case 'data sources':
            return 'Spreadsheets'
          case 'rss feeds':
            if (this.$store.getters.hasAccessToMonitoring && !$store.getters.hasAccessToActorScouting) {
              return 'Feeds'
            }

            return ''
          case 'news outlets':
            return 'OutletSources'
          case 'content api\'s':
            return 'ContentApis'
          default:
            return this.activeTab
        }
      },
    },
    created () {
      if (location.hash === '#rss feeds') {
        this.activeTab = 'rss feeds'
      }
      if (location.hash === '#news outlets') {
        this.activeTab = 'news outlets'
      }
    },
  })
</script>
