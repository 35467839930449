<template>
  <div class="top-bar__breadcrumbs">
    <template v-for="(breadcrumb, index) in breadcrumbs" :key="'breadcrumb_index_' + index">
      <div class="top-bar__breadcrumb-item">
        <router-link :to="breadcrumb.path">{{ breadcrumb.label }}</router-link>
      </div>
      <span class="top-bar__breadcrumb-spacer" v-if="index < breadcrumbs.length - 1">&nbsp;></span>
    </template>
  </div>
</template>

<script>
import TranslationsMixin from '../../util/TranslationsMixin.js'
import OnboardingMixin from '../../util/OnboardingMixin.js'
import UiMixin from '../../util/UiMixin.js'
import { viewDashboardOptions } from '../../constants/config.js'

export default {
  name: 'Breadcrumbs',
  data () {
    return {}
  },
  computed: {
    breadcrumbs () {
      if (['communities', 'community-detail'].includes(this.$route.name)) {
        return this.communityBreadcrumbs
      }

      if (['spotting-areas', 'spotting-area-detail'].includes(this.$route.name)) {
        return this.spottingAreaBreadcrumbs
      }

      if (['actors-simplified', 'products-simplified'].includes(this.$route.name) || this.shouldShowActorSimplifiedDetailPage) {
        return this.actorsSimplifiedBreadcrumbs
      }

      if (['announcements', 'announcements-detail', 'announcements-simplified'].includes(this.$route.name)) {
        return this.announcementBreadcrumbs
      }

      if (['events', 'events-detail', 'events-simplified'].includes(this.$route.name)) {
        return this.eventBreadCrumbs
      }

      if (['intelligence-simplified', 'intelligence-detail'].includes(this.$route.name)) {
        return this.intelligenceBreadcrumbs
      }

      if (this.$route.path.indexOf('/dashboards/knowledge-base') >= 0 && this.shouldUseSimplifiedKnowledgeBase) {
        return this.knowledgeBaseBreadcrumbs
      }

      if (this.$route.path.indexOf('/dashboards/concept-map') >= 0 || this.$route.path.indexOf('/dashboards/explore-content') >= 0) {
        return this.getConceptMapBreadcrumbs
      }

      if (this.$route.path.indexOf('/dashboards/') >= 0) {
        return this.genericDashboardBreadcrumbs
      }

      if (['challenges', 'challenges-detail'].includes(this.$route.name)) {
        return this.challengeBreadcrumbs
      }

      if (['explore', 'actor-detail'].includes(this.$route.name)) {
        return this.actorBreadcrumbs
      }

      if (['product-gallery'].includes(this.$route.name)) {
        return this.productBreadcrumbs
      }

      if (['portfolios', 'portfolio-detail'].includes(this.$route.name)) {
        return this.portfolioBreadcrumbs
      }

      if (['profile'].includes(this.$route.name)) {
        return this.profileBreadcrumbs
      }

      if (['notifications'].includes(this.$route.name)) {
        return this.notificationBreadcrumbs
      }

      if (['info'].includes(this.$route.name)) {
        return this.infoBreadcrumbs
      }

      return [this.homeBreadcrumb]
    },
    communityBreadcrumbs () {
      const breadcrumbs = [this.homeBreadcrumb, { label: this.$t('communities_generic'), path: '/communities' }]

      if (this.$route.name === 'community-detail' && this.community && this.community.name) {
        let communityOverviewPath = `/communities/${this.$route.params.id}`

        if (this.$route.params.communityId !== '') {
          communityOverviewPath = `/communities/${this.$route.params.communityId}`
        }

        this.addComponentDetailRoutesToBreadcrumbs(breadcrumbs, 'community-detail', this.community.name, communityOverviewPath)
        this.addActorDetailToBreadcrumbs(breadcrumbs)
        this.addAnnouncementDetailToBreadcrumbs(breadcrumbs)
        this.addChallengeDetailToBreadcrumbs(breadcrumbs)
      }

      return breadcrumbs
    },
    spottingAreaBreadcrumbs () {
      const breadcrumbs = [this.homeBreadcrumb, { label: this.spottingAreasLabel, path: '/spotting-areas' }]

      if (this.spottingArea && this.spottingArea.name) {
        const spottingAreaOverviewPath = `/spotting-areas/` + this.spottingArea.id

        this.addComponentDetailRoutesToBreadcrumbs(breadcrumbs, 'spotting-area-detail', this.spottingArea.name, spottingAreaOverviewPath)
        this.addActorDetailToBreadcrumbs(breadcrumbs)
        this.addArticleDetailToBreadcrumbs(breadcrumbs)
      }

      return breadcrumbs
    },
    actorsSimplifiedBreadcrumbs () {
      if (this.$route.name === 'products-simplified') {
        return this.productsSimplifiedBreadcrumbs
      }

      const actorsBreadCrumb = {
        label: this.onboardingTextConfig.actorsSecondTopBarTitle,
        path: '/actors-simplified'
      }

      if (this.$route.params.id && this.detailActor && this.detailActor.id == this.$route.params.id) {
        if (this.detailActor.actor_type === 'Product') {
          actorsBreadCrumb.label = this.productsLabel
          actorsBreadCrumb.path = '/products-simplified'
        }
      }

      const breadcrumbs = [this.homeBreadcrumb, actorsBreadCrumb]

      this.addActorDetailToBreadcrumbs(breadcrumbs)

      return breadcrumbs
    },
    productsSimplifiedBreadcrumbs () {
      const breadcrumbs = [this.homeBreadcrumb, { label: this.productsLabel, path: '/products-simplified' }]

      this.addActorDetailToBreadcrumbs(breadcrumbs)

      return breadcrumbs
    },
    announcementBreadcrumbs () {
      const breadcrumbs = [this.homeBreadcrumb, { label: this.announcementsLabel, path: '/announcements' }]

      this.addAnnouncementDetailToBreadcrumbs(breadcrumbs)

      return breadcrumbs
    },
    eventBreadCrumbs () {
      const breadcrumbs = [this.homeBreadcrumb, { label: this.eventsLabel, path: '/events' }]

      this.addAnnouncementDetailToBreadcrumbs(breadcrumbs)

      return breadcrumbs
    },
    challengeBreadcrumbs () {
      const breadcrumbs = [this.homeBreadcrumb, { label: this.challengesLabel, path: '/challenges' }]

      this.addChallengeDetailToBreadcrumbs(breadcrumbs)

      return breadcrumbs
    },
    portfolioBreadcrumbs () {
      const breadcrumbs = [this.homeBreadcrumb, { label: 'portfolios', path: '/portfolios' }]

      if (this.$route.name === 'portfolio-detail') {
        breadcrumbs.push({
          label: 'Portfolio detail', // This isn't part of the state, adding it adds very little value as the user has the title is displayed in large letters on the detail page
          path: ''
        })
      }

      return breadcrumbs
    },
    notificationBreadcrumbs () {
      return [this.homeBreadcrumb, { label: 'Notifications', path: '/notifications' }]
    },
    profileBreadcrumbs () {
      // NOTE: this is an exception, ideally the conversations get their own route, instead of being part of the "profile" route
      if (this.$route.params && this.$route.params.panel == 'conversations') {
        return [this.homeBreadcrumb, { label: 'Conversations', path: '' }]
      }

      if (this.$route.params && this.$route.params.panel == 'subscription') {
        return [this.homeBreadcrumb, { label: 'My Subscription', path: '' }]
      }

      const breadcrumbs = [this.homeBreadcrumb, { label: 'My Profile Settings', path: '/profile' }]

      return breadcrumbs
    },
    actorBreadcrumbs () {
      const actorsBreadCrumb = {
        label: 'actors',
        path: '/actors'
      }

      if (this.$route.params.id && this.detailActor && this.detailActor.id == this.$route.params.id) {
        if (this.detailActor.actor_type === 'Product') {
          actorsBreadCrumb.label = this.productsLabel
          actorsBreadCrumb.path = '/product-gallery'
        }
      }

      const breadcrumbs = [this.homeBreadcrumb, actorsBreadCrumb]

      this.addActorDetailToBreadcrumbs(breadcrumbs)

      return breadcrumbs
    },
    productBreadcrumbs () {
      const breadcrumbs = [this.homeBreadcrumb, { label: this.productsLabel, path: '/product-gallery' }]

      this.addActorDetailToBreadcrumbs(breadcrumbs)

      return breadcrumbs
    },
    knowledgeBaseBreadcrumbs () {
      const breadcrumbs = [this.homeBreadcrumb, { label: this.knowledgeBaseLabel, path: '/dashboards/knowledge-base' }]

      return breadcrumbs
    },
    infoBreadcrumbs () {
      const breadcrumbs = [this.homeBreadcrumb, { label: 'Info', path: '/info' }]

      return breadcrumbs
    },
    getConceptMapBreadcrumbs () {
      const breadcrumbs = [this.homeBreadcrumb, { label: 'Trend Monitor Overview', path: '/dashboards/concept-map' }]

      this.addConceptMapDetailBreadcrumbs(breadcrumbs)

      return breadcrumbs
    },
    genericDashboardBreadcrumbs () {
      let dashboardName = this.$route.name

      // Determine the name of the dashboard
      if (this.$route.params && this.$route.params.panel) {
        const filteredOptions = viewDashboardOptions.filter(option => option.value === this.$route.params.panel.toLowerCase())

        if (filteredOptions.length > 0) {
          dashboardName = filteredOptions[0].label
        } else {
          dashboardName = this.$route.params.panel
        }
      }

      if (dashboardName === 'news-overview') {
        dashboardName = 'News Overview' // This is not yet part of the viewDashboardOptions because it's still behind a feature flag
      }

      const breadcrumbs = [this.homeBreadcrumb, { label: dashboardName, path: '' }]

      return breadcrumbs
    },
    intelligenceBreadcrumbs () {
      const breadcrumbs = [this.homeBreadcrumb, { label: 'intelligence', path: '/intelligence-simplified' }]

      this.addConceptMapDetailBreadcrumbs(breadcrumbs)

      return breadcrumbs
    },
    homeBreadcrumb () {
      return { label: this.ecosystemDisplayName, path: '/dashboards/home' }
    },
    detailActor () {
      return this.$store.getters.detailActor
    },
    detailAnnouncement () {
      return this.$store.state.notifications.detail.data
    },
    detailChallenge () {
      return this.$store.state.challenges.detail.data
    },
    detailConceptSearch () {
      return this.$store.state.conceptSearches.detail.data
    },
    detailArticle () {
      return { 'title': '' }
    },
    subNavSectionName () {
      switch (this.subNavSection) {
        case 'members':
          return this.$t('community_section_members')
        case 'actors':
          return this.$t('top_bar_actors')
        case 'announcements':
          return this.announcementsLabel
        case 'events':
          return this.eventsLabel
        case 'trends':
          return this.$t('spotting_area_tab_trends')
        case 'knowledge-base':
          return 'knowledge base'
        case 'challenges':
          return 'challenges'
      }
    },
    subNavSection () {
      return this.$route.params.section
    },
    spottingArea () {
      if (this.$route.name === 'spotting-area-detail') {
        return this.$store.state.spottingAreas.detail.data
      }

      return {}
    },
    community () {
      if (this.$route.name === 'community-detail') {
        return this.$store.state.communities.detail.data
      }

      return {}
    },
    ecosystemDisplayName () {
      return this.$store.getters.ecosystemDisplayName
    },
  },
  methods: {
    // Adds the breadcrumb routes of a component detail to the routes, i.e. community detail -> adds the community name and overview or subsection (i.e. knowledge base, announcements...) if applicable
    addComponentDetailRoutesToBreadcrumbs (breadcrumbs, componentDetailName, componentDetailLabel, componentOverviewPath) {
      breadcrumbs.push({
        label: componentDetailLabel,
        path: componentOverviewPath
      })

      if (this.subNavSection) {
        const pathConfig = {
          name: componentDetailName,
          params: {
            section: this.subNavSection
          }
        }

        breadcrumbs.push({
          label: this.subNavSectionName,
          path: pathConfig
        })
      } else {
        breadcrumbs.push({
          label: this.$t('top_bar_overview'),
          path: componentOverviewPath
        })
      }
    },
    addActorDetailToBreadcrumbs (breadcrumbs) {
      if (this.$route.params.id && this.detailActor && this.detailActor.id == this.$route.params.id) {
        breadcrumbs.push({
          label: this.detailActor.name,
          path: ''
        })
      }
    },
    addAnnouncementDetailToBreadcrumbs (breadcrumbs) {
      if (this.$route.params.id && this.detailAnnouncement && this.detailAnnouncement.id == this.$route.params.id) {
        breadcrumbs.push({
          label: this.detailAnnouncement.title,
          path: ''
        })
      }
    },
    addChallengeDetailToBreadcrumbs (breadcrumbs) {
      if (this.$route.params.id && this.detailChallenge && this.detailChallenge.id == this.$route.params.id) {
        breadcrumbs.push({
          label: this.detailChallenge.title,
          path: ''
        })
      }
    },
    addArticleDetailToBreadcrumbs (breadcrumbs) {
      if (this.$route.params.id && this.$route.params.section === 'trends-detail') {
        breadcrumbs.push({
          label: this.detailArticle.title,
          path: ''
        })
      }
    },
    addConceptMapDetailBreadcrumbs (breadcrumbs) {
      // The check on equal id's is to make sure there's no race condition influencing the displayed detail title
      // There could be a concept search in the state, when going to the detail of it, the server needs time to return the new concept search, so the title of the old concept search
      // could be displayed for a split second, resulting in a "flashing" title in the breadcrumbs
      if (['intelligence-detail', 'ConceptMap'].includes(this.$route.name) && this.detailConceptSearch && this.detailConceptSearch.id == this.$route.params.id && this.detailConceptSearch.title) {
        breadcrumbs.push({
          label: this.detailConceptSearch.title,
          path: ''
        })
      }
    }
  },
  mixins: [TranslationsMixin, OnboardingMixin, UiMixin]
}
</script>

<style scoped>

</style>
