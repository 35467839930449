<template>
  <modal
    :id="modalId"
    :componentKey="componentKey"
    class="select-portfolio-modal"
    ref="modal"
    title="Add connection(s) to spotting area(s)"
    @close="close"
    is-simplified-modal
  >
    <template v-slot:body>
      <form-group label="Select one or multiple spotting areas" required class="select-portfolios__modal-content">
        <dropdown
          :options="selectableSpottingAreas"
          is-simplified
          v-model="spottingAreas"
          multiple
        />
        <!--<p class="error">{{ error }}</p>-->
      </form-group>
    </template>
    <template v-slot:footer>
      <div style="width: 100%;">
        <ds-button
          variant="rounded"
          @click="submit"
          label="Confirm"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import MODAL_IDS from '../../constants/modal-ids'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import Dropdown from '../Dropdown/Dropdown.vue'
  import { approveActorConnection } from '../../store/modules/suggestions'
  import { ACTION_TYPES as ACTOR_ACTION_TYPES } from '../../store/modules/actors'

  export default {
    name: 'SelectSpottingAreaModal',
    data () {
      return {
        modalId: MODAL_IDS.SELECT_SPOTTING_AREA_MODAL,
        componentKey: 0,
        selectableSpottingAreas: [],
        spottingAreas: [],
      }
    },
    emits: ['submitPortfolioModal', 'close', 'actorUpdated'],
    computed: {
      modalContext () {
        return this.$store.state.ui.modalContext
      },
    },
    methods: {
      close () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.SELECT_SPOTTING_AREA_MODAL)
        this.$router.push('/actors/' + this.$route.params.id + '#connections')
        this.$emit('close')

        setTimeout(() => {
          window.location.reload()
        }, 100)
      },
      submit () {
        this.processingSuggestion = true
        approveActorConnection(this.modalContext.mainActorId, { actorIds: this.modalContext.actorIds, spottingAreas: this.spottingAreas })
          .then((response) => {
            this.$store.dispatch(ACTOR_ACTION_TYPES.FETCH_ACTOR_DETAIL, this.$route.params.id)
            this.processingSuggestion = false
          })
          .catch(() => {
            this.processingSuggestion = false
          })
          .finally(() => {
            this.close()
          })
      },
    },
    mounted () {
      this.modalContext.spottingAreas.forEach((spottingArea) => {
        const spottingAreaObject = {
          value: spottingArea.id,
          label: spottingArea.name,
        }
        this.selectableSpottingAreas.push(spottingAreaObject)
      })
    },
    components: {
      Modal,
      Dropdown,
    },
  }
</script>

<style scoped>
  .select-portfolios__modal-content {
    box-shadow: none;
    border-radius: 0;
    margin: 10px;
    padding: 10px;
  }

  .select-portfolio-modal :deep(.modal__wrapper .modal__container .modal__body) {
    overflow: visible;
    padding: 12px;
  }
</style>
