<template>
  <div>
    <div class="domains-input" v-for="(mainDomain, d) in mainDomains">
      <checkbox :label="getDomainName(mainDomain)" :model-value="enabled(mainDomain.id)" @update:modelValue="toggle(mainDomain, $event)"/>
      <div :class="{ 'domains-input--muted': !enabled(mainDomain.id, true) }" @mousedown="toggle(mainDomain, true)">
        <div class="domains-input__sub" v-for="subDomain in mainDomain.domains" @click.stop>
          <checkbox inline :label="getSubdomainName(subDomain)" :model-value="enabled(subDomain.id)" @update:modelValue="toggle(subDomain, $event)"/>
          <ds-textarea
            v-if="enabled(subDomain.id)"
            autoresize
            class="domains-input__remark"
            placeholder="Add details or remarks"
            :model-value="getRemark(subDomain.id)"
            @update:modelValue="setRemark(subDomain.id, $event)"
          />
        </div>
      </div>
      <ds-textarea
        v-if="enabled(mainDomain.id)"
        autoresize
        class="domains-input__remark"
        placeholder="Suggest other domains..."
        :model-value="getRemark(mainDomain.id)"
        @update:modelValue="setRemark(mainDomain.id, $event)"
      />
    </div>
  </div>
</template>

<script>
  import Checkbox from './Checkbox.vue'
  import DsTextarea from './DsTextarea.vue'
  import { inert } from '../../util/helpers'
  import TranslationsMixin from '../../util/TranslationsMixin'

  export default {
    props: {
      modelValue: Array,
    },
    emits: ['update:modelValue'],
    data () {
      return {
        collapsed: this.$store.state.taxonomies.domains.map(() => true),
      }
    },
    computed: {
      allIds () {
        return (this.modelValue || []).map(d => d.id)
      },
      parentIds () {
        return (this.modelValue || []).map(d => d.parentId)
      },
      mainDomains () {
        return this.$store.state.taxonomies.domains
      },
    },
    methods: {
      getDomainName (domain) {
        return this.getLabelForTaxonomyValue('domains', domain.id)
      },
      getSubdomainName (domain) {
        var label = this.getLabelForTaxonomyValue('domains', domain.id)

        if (label.includes(' > ')) {
          return label.split('>').pop()
        }

        return label
      },
      enabled (id, parent) {
        return this.allIds.includes(id) || (parent && this.parentIds.includes(id))
      },
      toggle (domain, value) {
        const current = this.modelValue || []
        if (value) {
          if (this.enabled(domain.id)) {
            return
          }
          domain = inert(domain)
          domain.offers = ['service']
          value = current.concat(inert(domain))
        } else {
          value = current.filter(d => d.id !== domain.id)
        }
        this.$emit('update:modelValue', value)
      },
      getRemark (id) {
        return (this.modelValue.find(d => d.id === id) || {}).remark
      },
      setRemark (id, value) {
        (this.modelValue.find(d => d.id === id) || {}).remark = value
      },
    },
    mixins: [TranslationsMixin],
    components: {
      Checkbox,
      DsTextarea,
    },
  }
</script>

<style lang="scss">
.domains-input {
  + .domains-input {
    margin-top: 1rem;
  }
}
.domains-input {
  > .textarea {
    margin: .5rem 0 2rem 1rem;
    width: calc(100% - 1rem);
  }
}
.domains-input__sub {
  padding-left: 1rem;
  display: flex;
  > .input {
    padding: 0;
    height: 22px;
  }
  > .textarea {
    max-height: 200px;
    width: auto;
    align-self: center;
    border-width: 0 0 1px 0;
    padding: 2px 5px 1px;
    color: #888;
    font-size: 12px;
    font-style: italic;
  }
}
.domains-input--muted {
  column-width: 12em;
  .domains-input__sub {
    pointer-events: none;
    color: #999;
    .radio > input {
      display: none;
    }
    > .textarea {
      display: none;
    }
  }
}
</style>
