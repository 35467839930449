<template>
  <div class="communities-title">
    {{
      $t('community_manager_title')
    }}
  </div>
  <div class="community-detail__manager">
    <div class="community-detail__manager-container">
      <div class="community-detail__manager-photo-container">
        <div
          class="community-detail__manager-photo"
          :style="{ backgroundImage: `url(${manager.photoUrl})` }"
        >
        </div>
      </div>
      <div class="community-detail__manager-info">
        <slot></slot>
        <div>
          <ds-button
            v-if="canStartConversation"
            variant="rounded"
            :href="!isLoggedIn ? 'mailto:' + manager.email : ''"
            :label="$t('community_contact_cta')"
            @click.stop="startConversationWithCommunityManager()"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { Conversations } from '../../api/conversations'
  import { ACTION_TYPES as CONVERSATION_ACTION_TYPES } from '../../store/modules/conversations'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'
  import { defineComponent } from 'vue'
  import { UserBaseInfoData } from '@/Domain/User/Types/types'
  import { PropType } from '~/vue'

  export default defineComponent({
    name: 'CommunityDetailManagerCard',
    emits: ['click-contact-button'],
    props: {
      manager: {
        type: Object as PropType<UserBaseInfoData>,
      },
    },
    computed: {
      canStartConversation() {
        return this.loggedInUserId !== this.manager.id && this.manager.name !== 'removed user'
      },
      isLoggedIn() {
        return this.$store.getters.isLoggedIn
      },
      loggedInUserId() {
        return this.$store.getters.userId
      },
      modalContext() {
        return this.$store.state.ui.modalContext
      },
    },
    methods: {
      showLoginModal() {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
      },
      startConversationWithCommunityManager() {
        if (!this.isLoggedIn) {
          this.$emit('click-contact-button')
          this.showLoginModal()

          return
        }

        if (this.manager.id === this.loggedInUserId) {
          return
        }

        this.$emit('click-contact-button')

        const recipientId = this.manager.id

        Conversations
          .post({ recipient_id: recipientId })
          .then((conversation) => {
            this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)

            this.$router.push('/profile/conversations/' + conversation.id)
              .then(() => {
                this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
                  component: 'user-information-side-panel',
                  metaData: {
                    userId: recipientId,
                    allowDelete: false,
                  },
                })
              })
          })
      },
    },
  })
</script>

<style scoped>
  h4.h4 {
    margin-top: 10px;
  }

</style>
