import { fetchGeoId } from '../api/geo.js'
import { fetchNameForId } from '../api/actors.js'
import { fetchTagById } from '../api/contents.js'
import { taxonomyOptions } from '../constants/config.js'
import { humanize } from '../constants/properties.js'
import TranslationsMixin from './TranslationsMixin.js'
import { fromEpochTimeToYear } from './date.js'
import { formatTime, fromPhpTime } from '../components/Slider/slider.js'
import { toAmount, toSymbol } from './currency.js'
import { getReportFieldById } from './helpers.js'
import FiltersMixin from './FiltersMixin.js'

function afterComma (str) {
  return str.slice(1 + str.lastIndexOf(','))
}

export default {
  data: function () {
    return {
      geoNames: [],
      actorNames: [],
      tagNames: [],
    }
  },
  methods: {
    geoLabel (geoid) {
      return this.geoToLabel(geoid, this.geoNames)
    },
    geoToLabel (geoid, geoNames) {
      if (geoid && geoid.display) {
        return afterComma(geoid.display)
      }

      const cached = geoNames.find(g => g.value == geoid.value)

      if (cached) {
        return afterComma(cached.display)
      }

      return 'City'
    },
    actorIdToLabel (actorId, actorNames) {
      const cached = actorNames.find(g => g.value == actorId)

      if (cached) {
        return cached.label
      }

      return 'Actor'
    },
    tagIdToLabel (tagId, tagNames) {
      const cached = tagNames.find(g => g.value == tagId)

      if (cached) {
        return cached.label
      }

      return 'Tag'
    },
    getFacetLabelForKeyword (keyword) {
      // This code was moved/refactored here from KeywordWithFacet.vue
      if (keyword.facet.indexOf('report_field_') >= 0) {
        var reportFieldId = keyword.facet.replace('report_field_', '')

        reportFieldId = reportFieldId.replace('_min', '')
        reportFieldId = reportFieldId.replace('_max', '')

        var reportField = getReportFieldById(reportFieldId)

        if (!reportField) {
          return 'report'
        }

        var reportFieldName = reportField.label

        if (keyword.facet.indexOf('_min') >= 0) {
          return reportFieldName + ' (min)'
        }

        if (keyword.facet.indexOf('_max') >= 0) {
          return reportFieldName + ' (max)'
        }

        return reportFieldName
      }

      return this.humanizeFacet(keyword.facet)
    },
    getGeoOrLabelForKeyword (keyword, label) {
      // This code was moved/refactored here from KeywordWithFacet.vue
      if (['possible_duplicate', 'is_zombie'].includes(keyword.facet)) {
        return 'Show'
      }

      if (keyword.facet == 'hq_and_local_offices_country_code') {
        return keyword.display
      }

      if (keyword.facet == 'claimed') {
        if (keyword.value == 'No') {
          return 'Show unclaimed'
        }

        return 'Show claimed'
      }

      if (keyword.facet == 'relevant') {
        if (keyword.value == 'No') {
          return 'Show Not relevant'
        }

        return 'Show Relevant'
      }

      if (keyword.value === '_empty') {
        return 'Show empty'
      }

      if (this.filterableRelationships.includes(keyword.facet) || keyword.facet === 'related_with') {
        return this.actorIdToLabel(keyword.value, this.actorNames)
      }

      if (['tags', 'private_tags', 'description_annotation_tags'].includes(keyword.facet)) {
        return this.tagIdToLabel(keyword.value, this.tagNames)
      }

      if (taxonomyOptions.includes(keyword.facet) && keyword.translations) {
        // When we implement i18n, select the correct label based on the set locale in the localization state
        if (keyword.translations[this.locale] && keyword.translations[this.locale].name) {
          return keyword.translations[this.locale].name
        }

        return keyword.translations['en'] && keyword.translations['en'].name
      } else if (taxonomyOptions.includes(keyword.facet)) {
        return this.getLabelForTaxonomyValue(keyword.facet, keyword.value, this.locale)
      }

      if (['Founding Date'].includes(keyword.facet)) {
        return this.createDateRangeLabel(keyword)
      }

      if (['Created At', 'Claimed At', 'Updated At'].includes(keyword.facet)) {
        return this.createFromToDateRangeLabel(keyword)
      }

      if (keyword.facet === 'Funding Date') {
        return this.createFundingDateLabel(keyword)
      }

      if (['Revenue', 'Total funding'].includes(keyword.facet)) {
        return this.createMoneyRangeLabel(keyword)
      }

      if (keyword.facet === 'current_employees.size_code') {
        return this.createEmployeeSizeLabel(keyword)
      }

      // If the filter consists of a number range, create a number range label
      if (['Employees', 'Patents', 'Custom Score', 'Total Connections', 'Mentions'].includes(keyword.facet) ||
        (keyword.type === 'report' && ['score', 'number'].includes(keyword.meta.type))
      ) {
        return this.createNumberRangeLabel(keyword)
      }

      if (keyword.facet === 'Completeness') {
        return this.createNumberRangeLabel(keyword, '%')
      }

      // If the filter consists of a date range, create a date range label (YYYY-mm-dd)
      if (keyword.type === 'report' && keyword.meta.type === 'date') {
        return this.createReportDateLabel(keyword)
      }

      if (keyword.type === 'report') {
        return keyword.value
      }

      return keyword && keyword.facet === 'geoid' ? this.geoToLabel(keyword, this.geoNames) : humanize(label)
    },
    createReportDateLabel (keyword) {
      // Create a date range label assuming that the start/end values are date strings formatted in YYYY-mm-dd
      var startDate = keyword.value.start
      var endDate = keyword.value.end

      if (!startDate && !endDate) {
        return
      }

      if (startDate && endDate) {
        return startDate + ' - ' + endDate
      }

      if (endDate) {
        return 'until ' + endDate
      }

      return 'starting from ' + startDate
    },
    createNumberRangeLabel (keyword, suffix = '') {
      var lowerBound = keyword.value.start
      var upperBound = keyword.value.end

      if (!lowerBound && upperBound >= 0) {
        return 'max. ' + upperBound + suffix
      }

      if (lowerBound >= 0 && !upperBound) {
        return 'min. ' + lowerBound + suffix
      }

      return lowerBound + ' - ' + upperBound + suffix
    },
    createEmployeeSizeLabel (keyword) {
      if (keyword.value === 'XS') {
        return keyword.value + ' (1-10)'
      } else if (keyword.value === 'S') {
        return keyword.value + ' (11-50)'
      } else if (keyword.value === 'M') {
        return keyword.value + ' (51-100)'
      } else if (keyword.value === 'L') {
        return keyword.value + ' (101-500)'
      } else if (keyword.value === 'XL') {
        return keyword.value + ' (501-1000)'
      } else if (keyword.value === 'XXL') {
        return keyword.value + ' (1000+)'
      }
    },
    createMoneyRangeLabel (keyword) {
      const startValue = keyword.value.start
      const endValue = keyword.value.end

      if (!startValue && !endValue) {
        return
      }

      if (!startValue) {
        return 'max. ' + this.formatMoney(endValue)
      }

      if (!endValue) {
        return 'min. ' + this.formatMoney(startValue)
      }

      return this.formatMoney(startValue) + ' - ' + this.formatMoney(endValue)
    },
    formatMoney (value) {
      if (value != null) {
        return `${toSymbol('EUR')}${toAmount(value)}`
      }

      return value
    },
    createFundingDateLabel (keyword) {
      const step = 1 / 4

      var startDate = keyword.value.start
      var endDate = keyword.value.end

      if (!startDate && !endDate) {
        return
      }

      if (endDate && !startDate) {
        endDate = fromPhpTime(endDate)

        return `until ${formatTime(endDate, step)}`
      }

      if (!endDate && startDate) {
        startDate = fromPhpTime(startDate)

        return `starting from ${formatTime(startDate, step)}`
      }

      endDate = fromPhpTime(endDate)
      startDate = fromPhpTime(startDate)

      return `${formatTime(startDate, step)} - ${formatTime(endDate, step)}`
    },
    createFromToDateRangeLabel (keyword) {
      var startDate = keyword.value.start
      var endDate = keyword.value.end

      // Compose the value of the label
      if (startDate && endDate) {
        return startDate + ' - ' + endDate
      }

      if (endDate) {
        return 'before ' + `${endDate}`
      }

      return 'starting from ' + startDate
    },
    createDateRangeLabel (keyword) {
      // Create a date range label assuming that the start/end values are PHP epoch times (= unix timestamps)

      var startDate = keyword.value.start
      var endDate = keyword.value.end

      if (startDate) {
        startDate = fromEpochTimeToYear(startDate)
      }

      if (endDate) {
        endDate = fromEpochTimeToYear(endDate)
      }

      // Compose the value of the label
      if (startDate && endDate) {
        return startDate + ' - ' + endDate
      }

      if (endDate) {
        return 'before ' + `${endDate}`
      }

      return 'starting from ' + startDate // + ' - ' + (new Date()).getFullYear();
    },
  },
  computed: {
    filterableRelationships () {
      return this.$store.getters.filterableRelationships
    },
    locale () {
      return this.$store.getters.activeLocale
    },
  },
  created () {
    this.geoNames = window.GEONAMES || []
  },
  mixins: [TranslationsMixin, FiltersMixin],
  mounted () {
    // If the mixin is part of a GeoId keyword without a display name, make sure we have the geo ID in the geoNames data property
    // We do this by checking if we get the default name back for the geoId mapping (geoLabel)
    if (this.modelValue && this.modelValue.facet === 'geoid' && this.geoLabel(this.modelValue) == 'City') {
      fetchGeoId(this.modelValue.value)
        .then(response => {
          this.$data.geoNames = this.$data.geoNames.concat(response)
        })
        .catch()
    }

    if (this.modelValue && (['tags', 'private_tags', 'description_annotation_tags'].includes(this.modelValue.facet))) {
      fetchTagById(this.modelValue.value)
        .then(response => {
          if (response[0]) {
            this.$data.tagNames.push({ value: response[0].value, label: response[0].label })
          }
        })
    }

    if (this.modelValue && (this.filterableRelationships.includes(this.modelValue.facet) || this.modelValue.facet === 'related_with')) {
      fetchNameForId(this.modelValue.value)
        .then(response => {
          this.$data.actorNames.push({ value: this.modelValue.value, label: response.name })
        })
        .catch()
    }
  },
}
