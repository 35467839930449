<template>
    <nav class="navigation navigation-panel-right" :class="[{'navigation-panel-scroll' : hasScroll }, 'navigation-panel-small-icons']">
      <ul class="navigation__links">
        <template v-for="item in items">
          <navigation-item
            v-if="item.to"
            :to="item.to"
            :isHomeLink="true"
            :icon="item.icon"
            :label="item.label"
            orientation="right"
          />
          <li v-if="item.to" class="navigation__divider"></li>
        </template>
      </ul>
    </nav>
</template>

<script>
import NavigationItem from '../Navigation/NavigationItem.vue'
import IconHoverTag from '../Icon/IconHoverTag.vue'

export default {
  data() {
    return {
      isHover:false,
      hasScroll: false
    }
  },
  computed: {
    homeLinks () {
      return window.config.homeLinks || []
    },
    items () {
      return this.homeLinks.map(item => {
        return {to: item.url, label: item.text, icon: item.image_url}
      }) || []
    }
  },
  methods: {
    mouseOver () {
      this.isHover = true;
    },
    mouseOut () {
      this.isHover = false;
    },
  },
  components: {
    NavigationItem,
    IconHoverTag
  }
}
</script>
