import TranslationsMixin from './TranslationsMixin'
import { taxonomyMapping } from '../store/modules/taxonomies'
import { camelize } from './string.ts'
import { filterControlOptions, simplifiedFilterControlOptions } from '../constants/config.js'

export default {
  methods: {
    getTaxonomyAliasWithDefault (taxonomy) {
      const generatedDefaultValue = taxonomy.split('_').map(partOfString => partOfString.charAt(0).toUpperCase() + partOfString.slice(1)).join(' ')
      const result = this.getTaxonomyAlias(taxonomy, false, generatedDefaultValue)

      if (result === taxonomy) {
        return generatedDefaultValue
      }

      return result
    },
    getTaxonomyAlias (taxonomy, toLowerCase = false, defaultLabel) {
      let taxonomyProperty = taxonomyMapping()[taxonomy] || taxonomy // i.e. category to categories
      taxonomyProperty = camelize(taxonomyProperty) // i.e. funnel_stage to funnelStage
      const taxonomyAlias = taxonomyProperty + 'Alias'

      const alias = this.localizedDisplayProperty(this.config, taxonomyAlias)

      if (alias && alias.length > 0) {
        if (toLowerCase) {
          return alias.toLowerCase()
        }

        return alias
      }

      return defaultLabel || taxonomy
    },
    doesTaxonomyAliasExist (taxonomy) {
      const taxonomyProperty = taxonomyMapping()[taxonomy] || taxonomy
      const taxonomyAlias = taxonomyProperty + 'Alias'

      return this.config[taxonomyAlias] && this.config[taxonomyAlias].length > 0
    },
  },
  computed: {
    filterControlOptionsProduct () {
      return this.translatedFilterControlOptions.filter(control => (
        ![
          'possible_duplicate',
          'is_zombie',
          'reports',
          'relevant',
        ].includes(control.value)
      ))
    },
    filterControlOptionsActor () {
      return this.translatedFilterControlOptions.filter(control => (
        ![
          'possible_duplicate',
          'is_zombie',
          'reports',
          'relevant',
        ].includes(control.value)
      ))
    },
    translatedSimplifiedFilterControlOptions () {
      return simplifiedFilterControlOptions.map((filterControl) => {
        filterControl.label = this.getTaxonomyAlias(filterControl.value, false, filterControl.label)
        return filterControl
      })
    },
    translatedFilterControlOptions () {
      return filterControlOptions.map((filterControl) => {
        filterControl.label = this.getTaxonomyAlias(filterControl.value, false, filterControl.label)
        return filterControl
      })
    },
    config () {
      return this.$store.state.config
    },
    /**
     * Taxonomy labels only
     */
    categoryLabels () {
      return this.$store.getters.categoryLabels
    },
    membershipLabels () {
      return this.$store.getters.membershipLabels
    },
    activityLabels () {
      return this.$store.getters.activityLabels
    },
    technologyLabels () {
      return this.$store.getters.technologyLabels
    },
    domainLabels () {
      return this.$store.getters.domainLabels
    },
    productFeatureALabels () {
      return this.$store.getters.productFeatureALabels
    },
    productFeatureBLabels () {
      return this.$store.getters.productFeatureBLabels
    },
    productFeatureCLabels () {
      return this.$store.getters.productFeatureCLabels
    },
    stageLabels () {
      return this.$store.getters.stageLabels
    },
    funnelStageLabels () {
      return this.$store.getters.funnelStageLabels
    },
    industryLabels () {
      return this.$store.getters.industryLabels
    },
    expertiseLabels () {
      return this.$store.getters.expertiseLabels
    },
    motivationLabels () {
      return this.$store.getters.motivationLabels
    },
    readinessLevelLabels () {
      return this.$store.getters.readinessLevelLabels
    },
    processStepLabels () {
      return this.$store.getters.processStepLabels
    },
    businessAspectLabels () {
      return this.$store.getters.businessAspectLabels
    },
    aspectLabels () {
      return this.$store.getters.aspectLabels
    },

    /**
     * Taxonomy values and labels
     */
    categoryValuesAndLabelsPerType () {
      var categories = this.$store.state.taxonomies.categories

      var result = {
        'LegalEntity': [],
        'Person': [],
        'Community': [],
        'Product': [],
      }

      categories.map(taxonomyObject => {
        var category = {
          value: taxonomyObject.id,
          label: this.getLabelForTaxonomyValue('categories', taxonomyObject.id, this.locale),
        }

        if (result[taxonomyObject.actor_type]) {
          result[taxonomyObject.actor_type].push(category)
        }
      })

      return result
    },
    categoryValuesAndLabels () {
      var items = this.$store.getters.categoryValuesAndLabels

      return items.map(taxonomyObject => {
        return {
          value: taxonomyObject.value,
          label: this.getLabelForTaxonomyValue('categories', taxonomyObject.value, this.locale),
        }
      })
    },
    membershipValuesAndLabels () {
      var items = this.$store.getters.membershipValuesAndLabels

      return items.map(taxonomyObject => {
        return {
          value: taxonomyObject.value,
          label: this.getLabelForTaxonomyValue('memberships', taxonomyObject.value, this.locale),
        }
      })
    },
    activityValuesAndLabels () {
      var items = this.$store.getters.activityValuesAndLabels

      return items.map(taxonomyObject => {
        return {
          value: taxonomyObject.value,
          label: this.getLabelForTaxonomyValue('activities', taxonomyObject.value, this.locale),
        }
      })
    },
    technologyValuesAndLabels () {
      var items = this.$store.getters.technologyValuesAndLabels

      return items.map(taxonomyObject => {
        return {
          value: taxonomyObject.value,
          label: this.getLabelForTaxonomyValue('technologies', taxonomyObject.value, this.locale),
        }
      })
    },
    domainValuesAndLabels () {
      var items = this.$store.getters.domainValuesAndLabels

      return items.map(taxonomyObject => {
        return {
          value: taxonomyObject.value,
          label: this.getLabelForTaxonomyValue('domains', taxonomyObject.value, this.locale),
        }
      })
    },
    productFeatureAValuesAndLabels () {
      const items = this.$store.getters.productFeatureAValuesAndLabels

      return items.map(taxonomyObject => {
        return {
          value: taxonomyObject.value,
          label: this.getLabelForTaxonomyValue('product_features_a', taxonomyObject.value, this.locale),
        }
      })
    },
    productFeatureBValuesAndLabels () {
      const items = this.$store.getters.productFeatureBValuesAndLabels

      return items.map(taxonomyObject => {
        return {
          value: taxonomyObject.value,
          label: this.getLabelForTaxonomyValue('product_features_b', taxonomyObject.value, this.locale),
        }
      })
    },
    productFeatureCValuesAndLabels () {
      const items = this.$store.getters.productFeatureCValuesAndLabels

      return items.map(taxonomyObject => {
        return {
          value: taxonomyObject.value,
          label: this.getLabelForTaxonomyValue('product_features_c', taxonomyObject.value, this.locale),
        }
      })
    },
    stageValuesAndLabels () {
      var items = this.$store.getters.stageValuesAndLabels

      return items.map(taxonomyObject => {
        return {
          value: taxonomyObject.value,
          label: this.getLabelForTaxonomyValue('stages', taxonomyObject.value, this.locale),
        }
      })
    },
    funnelStageValuesAndLabels () {
      var items = this.$store.getters.funnelStageValuesAndLabels

      return items.map(taxonomyObject => {
        return {
          value: taxonomyObject.value,
          label: this.getLabelForTaxonomyValue('funnel_stage', taxonomyObject.value, this.locale),
        }
      })
    },
    industryValuesAndLabels () {
      var items = this.$store.getters.industryValuesAndLabels

      return items.map(taxonomyObject => {
        return {
          value: taxonomyObject.value,
          label: this.getLabelForTaxonomyValue('industries', taxonomyObject.value, this.locale),
        }
      })
    },
    expertiseValuesAndLabels () {
      var items = this.$store.getters.expertiseValuesAndLabels

      return items.map(taxonomyObject => {
        return {
          value: taxonomyObject.value,
          label: this.getLabelForTaxonomyValue('expertises', taxonomyObject.value, this.locale),
        }
      })
    },
    motivationValuesAndLabels () {
      var items = this.$store.getters.motivationValuesAndLabels

      return items.map(taxonomyObject => {
        return {
          value: taxonomyObject.value,
          label: this.getLabelForTaxonomyValue('motivations', taxonomyObject.value, this.locale),
        }
      })
    },
    readinessLevelValuesAndLabels () {
      var items = this.$store.getters.readinessLevelValuesAndLabels

      return items.map(taxonomyObject => {
        return {
          value: taxonomyObject.value,
          label: this.getLabelForTaxonomyValue('readiness_levels', taxonomyObject.value, this.locale),
        }
      })
    },
    processStepValuesAndLabels () {
      var items = this.$store.getters.processStepValuesAndLabels

      return items.map(taxonomyObject => {
        return {
          value: taxonomyObject.value,
          label: this.getLabelForTaxonomyValue('process_steps', taxonomyObject.value, this.locale),
        }
      })
    },
    businessAspectValuesAndLabels () {
      var items = this.$store.getters.businessAspectValuesAndLabels

      return items.map(taxonomyObject => {
        return {
          value: taxonomyObject.value,
          label: this.getLabelForTaxonomyValue('business_aspects', taxonomyObject.value, this.locale),
        }
      })
    },
    aspectValuesAndLabels () {
      const items = this.$store.getters.aspectValuesAndLabels

      return items.map(taxonomyObject => {
        return {
          value: taxonomyObject.value,
          label: this.getLabelForTaxonomyValue('aspects', taxonomyObject.value, this.locale),
        }
      })
    },
    announcementCategoryValuesAndLabels () {
      var items = this.$store.getters.announcementCategoryValuesAndLabels

      return items.map(taxonomyObject => {
        return {
          value: taxonomyObject.value,
          label: this.getLabelForTaxonomyValue('announcement_categories', taxonomyObject.value, this.locale),
        }
      })
    },
    eventCategoryValuesAndLabels () {
      var items = this.$store.getters.eventCategoryValuesAndLabels

      return items.map(taxonomyObject => {
        return {
          value: taxonomyObject.value,
          label: this.getLabelForTaxonomyValue('event_categories', taxonomyObject.value, this.locale),
        }
      })
    },
  },
  mixins: [TranslationsMixin],
}
