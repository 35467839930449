<template>
  <form class="add-connection" @submit="handleSubmit">
    <DsButton
      block
      icon="plus"
      :label="$t('actor_add_connection')"
      variant="primary"
      v-if="!formVisible"
      @click="showForm"
    />

    <NewCard
      v-else
      :title="title"
    >
      <div class="card-body">
        <form-group label="Connection">
          <dropdown :options="types" v-model="relation" :open-direction="'bottom'"/>
        </form-group>

        <div v-if="alreadyHasSelectedRelationship" style="color: red"></div>

        <form-group v-if="!editingConnection" :label="expectedActor"
                    :error="alreadyHasSelectedRelationship && `This relationship cannot be created because ${existingActor.name} already has a '${types.find(type => type.value === relation).label}' relationship with ${actor.name}`">
          <suggestion-input
            icon="search"
            ref="suggestionInput"
            :api-settings="suggestionSettings"
            :clear-on-select="false"
            :showNoResults="false"
            @actor="actor = $event"
            @customInput="handleCustomInput"
            @cancelCustomInput="customActorName = null"
          />
        </form-group>

        <form-group :label="selectedRelationshipMetaDataList.title" v-if="selectedRelationshipMetaDataList && selectedRelationshipMetaDataList.title">
          <dropdown :model-value="relationshipMetaData.tags" :options="selectedRelationshipMetaDataList.tags" :multiple="true" @update:modelValue="updateRelationshipTags"/>
        </form-group>
        <div v-if="isEcosystemMember === false">
          <form-group label="Score">
            <input-range
              class="input-range--score card"
              label="Strength"
              :model-value="relationshipMetaData.score"
              @update:modelValue="updateRelationshipScore"
              :max="100"
            />
          </form-group>

          <form-group label="Start">
            <datepicker label="start" :model-value="relationshipMetaData.start" @update:modelValue="relationshipMetaData.start = $event" input-id="startDate"/>
          </form-group>

          <form-group label="End">
            <datepicker label="end" :model-value="relationshipMetaData.end" @update:modelValue="relationshipMetaData.end = $event" input-id="endDate"/>
          </form-group>

          <form-group label="Comment">
            <ds-textarea v-model="relationshipMetaData.comment"/>
          </form-group>

          <form-group label="Status">
            <dropdown v-model="relationshipMetaData.status" :options="statusOptions" :multiple="false" :allow-clear="true"/>
          </form-group>
        </div>
        <DsButton
          block
          variant="primary"
          :disabled="addButtonDisabled"
          :label="addButtonLabel"
          type="submit"
        />
      </div>
    </NewCard>
  </form>
</template>

<script>
  import DsButton from '../../DsButton/DsButton.vue'
  import NewCard from '../../NewCard/NewCard.vue'
  import Dropdown from '../../Dropdown/Dropdown.vue'
  import FormGroup from '../../Form/FormGroup.vue'
  import SuggestionInput from '../../SuggestionInput/SuggestionInput.vue'
  import InputRange from '../../Slider/InputRange.vue'
  import Datepicker from '../../Form/Datepicker.vue'
  import DsTextarea from '../../Form/DsTextarea.vue'

  import { suggestionSettings } from '../../../api/actors.js'

  export default {
    props: {
      existingActor: Object,
      editingConnection: {
        type: Object,
        default: null,
      },
    },
    data () {
      return {
        actor: null,
        formVisible: false,
        relation: null,
        customActorName: '',
        relationshipMetaData: {
          score: 0,
          start: '',
          end: '',
          tags: [],
          comment: '',
          status: '',
        },
      }
    },
    components: {
      Dropdown,
      DsButton,
      FormGroup,
      NewCard,
      SuggestionInput,
      InputRange,
      Datepicker,
      DsTextarea
    },
    computed: {
      suggestionSettings,
      statusOptions () {
        return [
          'active',
          'inactive',
          'future'
        ]
      },
      selectedRelationship () {
        if (!this.relation) {
          return
        }

        var relationships = this.ecosystemRelationships.filter(rel => rel.name === this.relation)

        if (relationships.length === 0) {
          return
        }

        return relationships[0]
      },
      metaDataLists () {
        return this.$store.getters.relationshipMetaDataLists || []
      },
      selectedRelationshipMetaDataList () {
        if (!this.selectedRelationship || !this.selectedRelationship.listIds || this.selectedRelationship.listIds.length === 0) {
          return
        }

        var lists = this.metaDataLists.filter(list => list.id === this.selectedRelationship.listIds[0])

        if (!lists.length) {
          return
        }

        return lists[0]
      },
      ecosystemRelationships () {
        return this.$store.getters.fullActorRelationships
      },
      expectedActor () {
        const type = this.ecosystemRelationships.find(t => t.name === this.relation) || {}
        return type.expects || this.$store.getters.actorString
      },
      defaultActorType () {
        const type = this.ecosystemRelationships.find(t => t.name === this.relation) || {}
        return type.default_actor_type || 'LegalEntity'
      },
      alreadyHasSelectedRelationship () {
        if (this.editingConnection) {
          return false
        }
        return this.actor && this.existingActor[this.relation] && !!this.existingActor[this.relation].find(relationship => relationship.to === this.actor.id)
      },
      types () {
        return this.ecosystemRelationships.filter(r => !['subsidiaries', 'main_company', 'has_collaboration_with'].includes(r.name)).map(t => ({
          value: t.name,
          label: t.label || t.name,
        })).reverse().sort((a, b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0))
      },
      addButtonDisabled () {
        return !this.relation || (!this.actor && !this.willAddNewActor && !this.editingConnection) || this.alreadyHasSelectedRelationship
      },
      title () {
        if (this.editingConnection) {
          return this.$t('actor_edit_connections_editing', {
            connectionType: this.editingConnection.label,
            actorName: this.editingConnection.to_name,
          })
        }
        return this.$t('actor_add_connection')
      },
      addButtonLabel () {
        if (this.editingConnection) {
          return 'Save changes'
        } else if (!this.willAddNewActor) {
          return 'Add connection'
        } else {
          return `Create “${this.customActorName}” and connect`
        }
      },
      willAddNewActor () {
        return !this.actor && this.customActorName
      },
      isEcosystemMember () {
        return this.$store.getters.userRole === 'actor'
      },
    },
    methods: {
      updateRelationshipTags (values) {
        this.relationshipMetaData.tags = values
      },
      updateRelationshipScore (value) {
        this.relationshipMetaData.score = value
      },
      handleSubmit (e) {
        e.preventDefault()

        if (this.editingConnection) {
          this.editingConnection.type = this.relation
          this.$emit('edit', this.editingConnection, this.relationshipMetaData)
        } else if (this.customActorName === null) {
          this.$emit('create', this.relation, this.actor, this.relationshipMetaData)
          this.clear()
        } else {
          this.$emit('createWithNewActor', this.relation, this.customActorName, this.defaultActorType, this.relationshipMetaData)
        }
      },
      handleCustomInput (input) {
        this.customActorName = input
        this.actor = null
      },
      clear () {
        this.actor = null
        this.customActorName = null
        this.relationshipMetaData = {
          score: 0,
          start: '',
          end: '',
          tags: [],
          comment: '',
          status: '',
        }

        if (this.$store.getters.detailActor['actor_type'] !== 'Product') {
          this.relation = null
        }

        if (this.$refs.suggestionInput) this.$refs.suggestionInput.clear()
      },
      showForm () {
        this.formVisible = true
      },
    },
    created () {
      if (this.$store.getters.detailActor['actor_type'] === 'Product') {
        this.formVisible = true
        this.relation = 'is_product_of'
      }

      if (this.editingConnection) {
        this.relation = this.editingConnection.type
        this.relationshipMetaData.score = this.editingConnection.score || 0
        this.relationshipMetaData.start = this.editingConnection.start || ''
        this.relationshipMetaData.end = this.editingConnection.end || ''
        this.relationshipMetaData.tags = this.editingConnection.tags || []
        this.relationshipMetaData.comment = this.editingConnection.comment || ''
        this.relationshipMetaData.status = this.editingConnection.status || ''
        this.showForm()
      }
    },
  }
</script>

<style lang="scss" scoped>
  .add-connection {
    margin-bottom: 15px;
  }
</style>
