<template>
  <div class="modal__wrapper modal__wrapper-large">
    <div class="modal__container modal__container__all-filters" :class="classes"
      @click.stop>
      <div class="modal__navigation scrollable">
        <div class="modal__navigation__button-container"
          :class="{'show-change-badges': showChangeBadges}"
          v-for="item in navigationTitles">
          <ds-button
            :class="{ 'modal__navigation__button-active' : item == visibleFilter } "
            :label="humanizeFilterItem(item)"
            variant="minimal"
            size="small"
            @click="changeFilter(item)"
            :no-count="showChangeBadges && hasChanges(item)"
            :badge-count="showChangeBadges && hasChanges(item)"
          />
        </div>
      </div>
      <div class="modal__body modal__body__all-filters">
        <div class="modal__header">
          <h4 class="title">{{ humanizeFilterItem(visibleFilter) }}</h4>
          <p class="guide_text" style="margin-right: 50px">{{ $t('all_filters_modal_guide_text') }}.</p>
          <a @click="clearAllFilters" style="text-decoration: none;"
            class="modal__delete-all" v-if="displayDeleteAllFilters">Delete All</a>
          <hr class="divider">
          <button class="close" @click="close" v-if="isCloseable">×</button>
        </div>
        <div>
          <div class="modal__body__filter-containers"
            :class="{'modal__body__scroll' : hasScroll}"
            v-if="filtersToDisplay.includes('category') && visibleFilter == 'Category'">
            <empty-field-filter-column
              :key="'field-empty-category'"
              :filter="visibleFilter"
              :selected-filters="selectedFilters"
              @handle-toggle-empty-filters="handleToggleEmptyFiltersSelection"
            />
            <ul class="modal__body__filter-column"
              :style="columnCounter(appropriateCategoryOptionsForPage)">
              <li :title="category" v-for="category in appropriateCategoryOptionsForPage">
                <ds-button
                  :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == category.value)}"
                  class="modal__body__list--items"
                  :label="category.label"
                  variant="minimal"
                  size="small"
                  @click="handleToggleCategory(category.value)"
                />
              </li>
            </ul>
          </div>
          <div class="modal__body__filter-containers"
            :class="{'modal__body__scroll' : hasScroll}"
            v-if="filtersToDisplay.includes('membership') && visibleFilter == 'Membership'">
            <empty-field-filter-column
              :key="'field-empty-membership'"
              :filter="visibleFilter"
              :selected-filters="selectedFilters"
              @handle-toggle-empty-filters="handleToggleEmptyFiltersSelection"
            />
            <ul class="modal__body__filter-column"
              :style="columnCounter(membershipOptions)">
              <li :title="item" v-for="item in membershipOptions">
                <ds-button
                  :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == item.value)}"
                  class="modal__body__list--items"
                  :label="item.label"
                  variant="minimal"
                  size="small"
                  @click="handleToggleMembership(item.value)"
                />
              </li>
            </ul>
          </div>
          <div class="modal__body__filter-containers"
            :class="{'modal__body__scroll' : hasScroll}"
            v-if="(filtersToDisplay.includes('stage') || filtersToDisplay.includes('revenue') || filtersToDisplay.includes('founding_date') || filtersToDisplay.includes('employees') || filtersToDisplay.includes('patent_count')) && visibleFilter == 'Growth Stages'">
            <div class="top-filter-section"
              v-if="filtersToDisplay.includes('founding_date')">
              <h6 class="top-filter-section__title">Founding date</h6>
              <slider-founding-date class="top-filter-slider"
                @on-update="handleFoundingDateRangeUpdate"
                v-if="filtersToDisplay.includes('founding_date')"/>
            </div>
            <br v-if="filtersToDisplay.includes('founding_date')">
            <div class="top-filter-section" v-if="filtersToDisplay.includes('employees')">
              <h6 class="top-filter-section__title">Employees</h6>
              <slider-employees lazy min="0" max="500" class="top-filter-slider"
                @update:modelValue="handleEmployeeRangeUpdate" :model-value="employeeRange"
                v-if="filtersToDisplay.includes('employees')"/>
            </div>
            <br v-if="filtersToDisplay.includes('employees')">
            <div class="top-filter-section" v-if="filtersToDisplay.includes('revenue')">
              <h6 class="top-filter-section__title">Revenue</h6>
              <slider-revenue class="top-filter-slider" rangeFormat="money" lazy min="0"
                :model-value="revenueRange" max="100000000" step="1000000"
                @update:modelValue="handleRevenueRangeUpdate"/>
            </div>
            <br v-if="filtersToDisplay.includes('revenue')">
            <div class="top-filter-section"
              v-if="filtersToDisplay.includes('patent_count')">
              <h6 class="top-filter-section__title">Total patent count</h6>
              <slider lazy min="0" max="30" class="top-filter-slider"
                @update:modelValue="handlePatentCountUpdate" :model-value="patentCountRange"
                v-if="filtersToDisplay.includes('patent_count')"/>
            </div>
            <br v-if="filtersToDisplay.includes('patent_count')">
            <div class="top-filter-section"
              v-if="filtersToDisplay.includes('relationship_count')">
              <h6 class="top-filter-section__title">Total Connections</h6>
              <slider lazy min="0" max="30" class="top-filter-slider"
                @update:modelValue="handleRelationshipCountUpdate"
                :model-value="relationshipCountRange"
                v-if="filtersToDisplay.includes('relationship_count')"/>
            </div>
            <br v-if="filtersToDisplay.includes('relationship_count')">
            <div class="container-fluid" style="padding: 0">
              <div class="row">
                <div class="col-xs-4" v-if="filtersToDisplay.includes('employees')">
                  <div class="top-filter-section">
                    <h6 class="top-filter-section__title"
                      style="max-width: unset; position: relative">Employee Size
                      Code</h6>
                  </div>
                  <ul class="modal__body__filter-column"
                    :style="columnCounter(employeeSizeOptions)"
                    v-if="filtersToDisplay.includes('employees')">
                    <li :title="item" v-for="item in employeeSizeOptions">
                      <ds-button
                        :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == item.toUpperCase())}"
                        class="modal__body__list--items"
                        :label="getEmployeeSizeCodeLabel(item)"
                        variant="minimal"
                        size="small"
                        @click="handleToggleEmployeeSize(item)"
                      />
                    </li>
                  </ul>
                </div>
                <div class="col-xs-4" v-if="filtersToDisplay.includes('stage')">
                  <div class="top-filter-section">
                    <h6 class="top-filter-section__title"
                      style="max-width: unset; position: relative">
                      {{ getTaxonomyAlias('stage') }}</h6>
                  </div>
                  <empty-field-filter-column
                    :key="'field-empty-stage'"
                    :filter="visibleFilter"
                    :selected-filters="selectedFilters"
                    @handle-toggle-empty-filters="handleToggleEmptyFiltersSelection"
                  />
                  <ul class="modal__body__filter-column"
                    :style="columnCounter(stageOptions)"
                    v-if="filtersToDisplay.includes('stage')">
                    <li :title="item" v-for="item in stageOptions">
                      <ds-button
                        :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == item.value)}"
                        class="modal__body__list--items"
                        :label="item.label"
                        variant="minimal"
                        size="small"
                        @click="handleToggleStage(item.value)"
                      />
                    </li>
                  </ul>
                </div>
                <div class="col-xs-4" v-if="filtersToDisplay.includes('exit_round')">
                  <div class="top-filter-section">
                    <h6 class="top-filter-section__title"
                      style="max-width: unset; position: relative">Exit Round</h6>
                  </div>
                  <ul class="modal__body__filter-column"
                    :style="columnCounter(exitRoundOptions)"
                    v-if="filtersToDisplay.includes('exit_round')">
                    <li :title="item" v-for="item in exitRoundOptions">
                      <ds-button
                        :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == item.toUpperCase())}"
                        class="modal__body__list--items"
                        :label="item"
                        variant="minimal"
                        size="small"
                        @click="handleToggleExitRoundType(item)"
                      />
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="modal__body__filter-containers"
            :class="{'modal__body__scroll' : hasScroll}"
            v-if="canDisplaySegmentation && visibleFilter == 'Segmentation'">
            <div class="top-filter-section"
              v-if="filtersToDisplay.includes('custom_score')">
              <div class="top-filter-section" style="padding-left: 0px;">
                <h6 class="top-filter-section__title"
                  style="max-width: unset; position: relative;">Custom Score</h6>
              </div>
              <ds-input v-model="customScoreMin"
                class="score-overview__filter_above_bellow filter_above"/>
              -
              <ds-input v-model="customScoreMax"
                class="score-overview__filter_above_bellow filter_bellow"/>
            </div>

            <div class="top-filter-section"
              v-if="filtersToDisplay.includes('funnel_stage')">
              <h6 class="top-filter-section__title"
                style="max-width: unset; position: relative">
                {{ getTaxonomyAlias('funnel_stage') }}</h6>
            </div>
            <div v-if="filtersToDisplay.includes('funnel_stage')">
              <ul class="modal__body__filter-column"
                :style="columnCounter(funnelStageOptions)">
                <li :title="item" v-for="item in funnelStageOptions">
                  <ds-button
                    :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == item.value)}"
                    class="modal__body__list--items"
                    :label="item.label"
                    variant="minimal"
                    size="small"
                    @click="handleToggleFunnelStage(item.value)"
                  />
                </li>
              </ul>
            </div>

            <div class="top-filter-section"
              v-if="true || filtersToDisplay.includes('nace_codes')">
              <h6 class="top-filter-section__title"
                style="max-width: unset; position: relative">
                NACE Codes</h6>
            </div>
            <div v-if="true || filtersToDisplay.includes('nace_codes')">
              <nace-code-picker
                :actorId="null"
                :actorNaceCodes="naceSelection"
                :mainCode="null"
                :searchQuery="null"
                @naceCodesChanged="handleToggleNaceCode"
                @mainNaceChanged="null"
                :isFilter="true"
              />
            </div>

            <div class="top-filter-section" v-if="filtersToDisplay.includes('relevant')">
              <h6 class="top-filter-section__title"
                style="max-width: unset; position: relative">Relevant</h6>
            </div>
            <div class="top-filter-section" v-if="filtersToDisplay.includes('relevant')">
              <ul class="modal__body__filter-column"
                :style="columnCounter(['Yes', 'No'])">
                <li :title="item" v-for="item in ['Yes', 'No']">
                  <ds-button
                    :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == item.toUpperCase())}"
                    :label="item"
                    class="modal__body__list--items"
                    variant="minimal"
                    size="small"
                    @click="handleToggleRelevancy(item)"
                  />
                </li>
              </ul>
            </div>
          </div>
          <div class="modal__body__filter-containers"
            :class="{'modal__body__scroll' : hasScroll}"
            v-if="filtersToDisplay.includes('activities') && visibleFilter == 'Activities'">
            <empty-field-filter-column
              :key="'field-empty-activities'"
              :filter="visibleFilter"
              :selected-filters="selectedFilters"
              @handle-toggle-empty-filters="handleToggleEmptyFiltersSelection"
            />
            <ul class="modal__body__filter-column"
              :style="columnCounter(activityOptions)">
              <li :title="item" v-for="item in activityOptions">
                <ds-button
                  :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == item.value)}"
                  :label="item.label"
                  class="modal__body__list--items"
                  variant="minimal"
                  size="small"
                  @click="handleToggleActivity(item.value)"
                />
              </li>
            </ul>
          </div>
          <div class="modal__body__filter-containers"
            :class="{'modal__body__scroll' : hasScroll}"
            v-if="filtersToDisplay.includes('sustainability_goal') && visibleFilter === 'Sustainability Goals' && !challengesAreEnabled">
            <ul class="modal__body__filter-column"
              :style="columnCounter(sustainabilityGoals)">
              <empty-field-filter-column
                :key="'field-empty-sgs'"
                :filter="visibleFilter"
                :selected-filters="selectedFilters"
                @handle-toggle-empty-filters="handleToggleEmptyFiltersSelection"
              />
              <li :title="item" v-for="item in sustainabilityGoals">
                <ds-button
                  :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == item.toUpperCase())}"
                  :label="item"
                  class="modal__body__list--items"
                  variant="minimal"
                  size="small"
                  @click="handleToggleSustainabilityGoal(item)"
                />
              </li>
            </ul>
          </div>
          <div class="modal__body__filter-containers"
            :class="{'modal__body__scroll' : hasScroll}"
            v-if="filtersToDisplay.includes('sustainability_goal') && visibleFilter === 'Sustainability Goals' && challengesAreEnabled">
            <ul class="modal__body__filter-SDG">
              <li :title="item" v-for="(item, index) in sustainabilityGoals"
                class="sdg-filter"
                v-tooltip.top="translatedSustainabilityGoals[index]"
                :class="{ 'modal__body__active-filter-img': activeFilterNames.find((f) => f === item.toUpperCase())}">
                <img :src="sdgImagesPath + (index + 1) + '.jpg'" :alt="translatedSustainabilityGoals[index]"
                  @click="handleToggleSustainabilityGoal(item)">
              </li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
          <div class="modal__body__filter-containers"
            :class="{'modal__body__scroll' : hasScroll}"
            v-if="filtersToDisplay.includes('expertises') && visibleFilter == 'Expertises'">
            <empty-field-filter-column
              :key="'field-empty-expertises'"
              :filter="visibleFilter"
              :selected-filters="selectedFilters"
              @handle-toggle-empty-filters="handleToggleEmptyFiltersSelection"
            />
            <ul class="modal__body__filter-column"
              :style="columnCounter(expertiseOptions)">
              <li :title="item" v-for="item in expertiseOptions">
                <ds-button
                  :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == item.value)}"
                  :label="item.label"
                  class="modal__body__list--items"
                  variant="minimal"
                  size="small"
                  @click="handleToggleExpertise(item.value)"
                />
              </li>
            </ul>
          </div>
          <div class="modal__body__filter-containers"
            :class="{'modal__body__scroll' : hasScroll}"
            v-if="filtersToDisplay.includes('motivations') && visibleFilter == 'Motivations'">
            <empty-field-filter-column
              :key="'field-empty-motivations'"
              :filter="visibleFilter"
              :selected-filters="selectedFilters"
              @handle-toggle-empty-filters="handleToggleEmptyFiltersSelection"
            />
            <ul class="modal__body__filter-column"
              :style="columnCounter(motivationOptions)">
              <li :title="item" v-for="item in motivationOptions">
                <ds-button
                  :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == item.value)}"
                  :label="item.label"
                  class="modal__body__list--items"
                  variant="minimal"
                  size="small"
                  @click="handleToggleMotivation(item.value)"
                />
              </li>
            </ul>
          </div>
          <div class="modal__body__filter-containers"
            :class="{'modal__body__scroll' : hasScroll}"
            v-if="filtersToDisplay.includes('readiness_levels') && visibleFilter == 'Readiness Levels'">
            <empty-field-filter-column
              :key="'field-empty-readiness_levels'"
              :filter="visibleFilter"
              :selected-filters="selectedFilters"
              @handle-toggle-empty-filters="handleToggleEmptyFiltersSelection"
            />
            <ul class="modal__body__filter-column"
              :style="columnCounter(readinessLevelOptions)">
              <li :title="item" v-for="item in readinessLevelOptions">
                <ds-button
                  :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == item.value)}"
                  :label="item.label"
                  class="modal__body__list--items"
                  variant="minimal"
                  size="small"
                  @click="handleToggleReadinessLevel(item.value)"
                />
              </li>
            </ul>
          </div>
          <div class="modal__body__filter-containers"
            :class="{'modal__body__scroll' : hasScroll}"
            v-if="filtersToDisplay.includes('process_steps') && visibleFilter == 'Process Steps'">
            <empty-field-filter-column
              :key="'field-empty-process_steps'"
              :filter="visibleFilter"
              :selected-filters="selectedFilters"
              @handle-toggle-empty-filters="handleToggleEmptyFiltersSelection"
            />
            <ul class="modal__body__filter-column"
              :style="columnCounter(processStepOptions)">
              <li :title="item" v-for="item in processStepOptions">
                <ds-button
                  :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == item.value)}"
                  :label="item.label"
                  class="modal__body__list--items"
                  variant="minimal"
                  size="small"
                  @click="handleToggleProcessStep(item.value)"
                />
              </li>
            </ul>
          </div>
          <div class="modal__body__filter-containers"
            :class="{'modal__body__scroll' : hasScroll}"
            v-if="filtersToDisplay.includes('business_aspects') && visibleFilter == 'Business Aspects'">
            <empty-field-filter-column
              :key="'field-empty-business_aspects'"
              :filter="visibleFilter"
              :selected-filters="selectedFilters"
              @handle-toggle-empty-filters="handleToggleEmptyFiltersSelection"
            />
            <ul class="modal__body__filter-column"
              :style="columnCounter(businessAspectOptions)">
              <li :title="item" v-for="item in businessAspectOptions">
                <ds-button
                  :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == item.value)}"
                  :label="item.label"
                  class="modal__body__list--items"
                  variant="minimal"
                  size="small"
                  @click="handleToggleBusinessAspect(item.value)"
                />
              </li>
            </ul>
          </div>
          <div class="modal__body__filter-containers"
            :class="{'modal__body__scroll' : hasScroll}"
            v-if="filtersToDisplay.includes('industries') && visibleFilter == 'Industries'">
            <empty-field-filter-column
              :key="'field-empty-industries'"
              :filter="visibleFilter"
              :selected-filters="selectedFilters"
              @handle-toggle-empty-filters="handleToggleEmptyFiltersSelection"
            />
            <ul class="modal__body__filter-column"
              :style="columnCounter(allIndustriesOptions)">
              <li :title="parent.text" v-for="(parent,index) in allIndustriesOptions">
                <ds-button
                  :class="[{'modal__body__active-filter-text': activeFilterNames.find((f) => f == parent.id)}, { 'modal__body__group-title-clip-text' : parent.industries.length > 0 }]"
                  class="modal__body__group-title"
                  :label="parent.name"
                  variant="minimal"
                  @click="handleToggleIndustries(parent.id)"
                  size="small"
                />
                <div class="modal__body__group-toggle-button"
                  @click="toggleGroup(parent.name)"
                  v-if="parent.industries.length > 0">&middot&middot&middot
                </div>
                <ul v-if="expandedFilterGroups.includes(parent.name)">
                  <li :title="child.name" v-for="child in parent.industries">
                    <ds-button
                      :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == child.id)}"
                      class="modal__body__group--items modal__body__group--items-child"
                      :label="child.name"
                      variant="minimal"
                      @click="handleToggleIndustries(parent.id, child.id)"
                      size="small"
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="modal__body__filter-containers"
            :class="{'modal__body__scroll' : hasScroll}"
            v-if="filtersToDisplay.includes('aspects') && visibleFilter == 'Aspects'">
            <empty-field-filter-column
              :key="'field-empty-aspects'"
              :filter="visibleFilter"
              :selected-filters="selectedFilters"
              @handle-toggle-empty-filters="handleToggleEmptyFiltersSelection"
            />
            <ul class="modal__body__filter-column"
              :style="columnCounter(allAspectOptions)">
              <li :title="parent.text" v-for="(parent,index) in allAspectOptions">
                <ds-button
                  :class="[{'modal__body__active-filter-text': activeFilterNames.find((f) => f == parent.id)}, { 'modal__body__group-title-clip-text' : parent.aspects.length > 0 }]"
                  class="modal__body__group-title"
                  :label="parent.name"
                  variant="minimal"
                  @click="handleToggleAspects(parent.id)"
                  size="small"
                />
                <div class="modal__body__group-toggle-button"
                  @click="toggleGroup(parent.name)" v-if="parent.aspects.length > 0">
                  &middot&middot&middot
                </div>
                <ul v-if="expandedFilterGroups.includes(parent.name)">
                  <li :title="child.name" v-for="child in parent.aspects">
                    <ds-button
                      :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == child.id)}"
                      class="modal__body__group--items modal__body__group--items-child"
                      :label="child.name"
                      variant="minimal"
                      @click="handleToggleAspects(parent.id, child.id)"
                      size="small"
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="modal__body__filter-containers"
            :class="{'modal__body__scroll' : hasScroll}"
            v-if="filtersToDisplay.includes('product_features_a') && visibleFilter == 'Product Features A'">
            <empty-field-filter-column
              :key="'field-empty-pf-a'"
              :filter="visibleFilter"
              :selected-filters="selectedFilters"
              @handle-toggle-empty-filters="handleToggleEmptyFiltersSelection"
            />
            <ul class="modal__body__filter-column"
              :style="columnCounter(productFeaturesAOptions)">
              <li :title="parent.text" v-for="(parent,index) in productFeaturesAOptions">
                <ds-button
                  :class="[{'modal__body__active-filter-text': activeFilterNames.find((f) => f == parent.id)}, { 'modal__body__group-title-clip-text' : parent.children.length > 0 }]"
                  class="modal__body__group-title"
                  :label="parent.text"
                  variant="minimal"
                  @click="handleToggleProductFeaturesA(parent.id)"
                  size="small"
                />
                <div class="modal__body__group-toggle-button"
                  @click="toggleGroup(parent.text)" v-if="parent.children.length > 0">
                  &middot&middot&middot
                </div>
                <ul v-if="expandedFilterGroups.includes(parent.text)">
                  <li :title="child.text" v-for="child in parent.children">
                    <ds-button
                      :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == child.id)}"
                      class="modal__body__group--items modal__body__group--items-child"
                      :label="child.text"
                      variant="minimal"
                      @click="handleToggleProductFeaturesA(parent.id, child.id)"
                      size="small"
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="modal__body__filter-containers"
            :class="{'modal__body__scroll' : hasScroll}"
            v-if="filtersToDisplay.includes('product_features_b') && visibleFilter == 'Product Features B'">
            <empty-field-filter-column
              :key="'field-empty-pf-b'"
              :filter="visibleFilter"
              :selected-filters="selectedFilters"
              @handle-toggle-empty-filters="handleToggleEmptyFiltersSelection"
            />
            <ul class="modal__body__filter-column"
              :style="columnCounter(productFeaturesBOptions)">
              <li :title="parent.text" v-for="(parent,index) in productFeaturesBOptions">
                <ds-button
                  :class="[{'modal__body__active-filter-text': activeFilterNames.find((f) => f == parent.id)}, { 'modal__body__group-title-clip-text' : parent.children.length > 0 }]"
                  class="modal__body__group-title"
                  :label="parent.text"
                  variant="minimal"
                  @click="handleToggleProductFeaturesB(parent.id)"
                  size="small"
                />
                <div class="modal__body__group-toggle-button"
                  @click="toggleGroup(parent.text)" v-if="parent.children.length > 0">
                  &middot&middot&middot
                </div>
                <ul v-if="expandedFilterGroups.includes(parent.text)">
                  <li :title="child.text" v-for="child in parent.children">
                    <ds-button
                      :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == child.id)}"
                      class="modal__body__group--items modal__body__group--items-child"
                      :label="child.text"
                      variant="minimal"
                      @click="handleToggleProductFeaturesB(parent.id, child.id)"
                      size="small"
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <div class="modal__body__filter-containers"
            :class="{'modal__body__scroll' : hasScroll}"
            v-if="filtersToDisplay.includes('product_features_c') && visibleFilter == 'Product Features C'">
            <empty-field-filter-column
              :key="'field-empty-pf-c'"
              :filter="visibleFilter"
              :selected-filters="selectedFilters"
              @handle-toggle-empty-filters="handleToggleEmptyFiltersSelection"
            />
            <ul class="modal__body__filter-column"
              :style="columnCounter(productFeaturesCOptions)">
              <li :title="parent.text" v-for="(parent,index) in productFeaturesCOptions">
                <ds-button
                  :class="[{'modal__body__active-filter-text': activeFilterNames.find((f) => f == parent.id)}, { 'modal__body__group-title-clip-text' : parent.children.length > 0 }]"
                  class="modal__body__group-title"
                  :label="parent.text"
                  variant="minimal"
                  @click="handleToggleProductFeaturesC(parent.id)"
                  size="small"
                />
                <div class="modal__body__group-toggle-button"
                  @click="toggleGroup(parent.text)" v-if="parent.children.length > 0">
                  &middot&middot&middot
                </div>
                <ul v-if="expandedFilterGroups.includes(parent.text)">
                  <li :title="child.text" v-for="child in parent.children">
                    <ds-button
                      :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == child.id)}"
                      class="modal__body__group--items modal__body__group--items-child"
                      :label="child.text"
                      variant="minimal"
                      @click="handleToggleProductFeaturesC(parent.id, child.id)"
                      size="small"
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
          <!--              <div class="modal__body__filter-containers">-->
          <!--                <ul class="modal__body__filter-column" :style="columnCounter(countryOptions)">-->
          <!--                  <li v-for="item in countryOptions">-->
          <!--                    <ds-button-->
          <!--                      :label="item.label"-->
          <!--                      variant="minimal"-->
          <!--                      size="small"-->
          <!--                    />-->
          <!--                  </li>-->
          <!--                </ul>-->
          <!--              </div>-->
          <div class="modal__body__filter-containers"
            :class="{'modal__body__scroll' : hasScroll}"
            v-if="filtersToDisplay.includes('domains') && visibleFilter == 'Domains'">
            <empty-field-filter-column
              :key="'field-empty-domains'"
              :filter="visibleFilter"
              :selected-filters="selectedFilters"
              @handle-toggle-empty-filters="handleToggleEmptyFiltersSelection"
            />
            <ul class="modal__body__filter-column" :style="columnCounter(domainsOptions)">
              <li :title="parent.text" v-for="(parent,index) in domainsOptions">
                <ds-button
                  :class="[{'modal__body__active-filter-text': activeFilterNames.find((f) => f == parent.id)}, { 'modal__body__group-title-clip-text' : parent.children.length > 0 }]"
                  class="modal__body__group-title"
                  :label="parent.text"
                  variant="minimal"
                  @click="handleToggleDomains(parent.id)"
                  size="small"
                />
                <div class="modal__body__group-toggle-button"
                  @click="toggleGroup(parent.text)" v-if="parent.children.length > 0">
                  &middot&middot&middot
                </div>
                <ul v-if="expandedFilterGroups.includes(parent.text)">
                  <li :title="child.text" v-for="child in parent.children">
                    <ds-button
                      :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == child.id)}"
                      class="modal__body__group--items modal__body__group--items-child"
                      :label="child.text"
                      variant="minimal"
                      @click="handleToggleDomains(parent.id, child.id)"
                      size="small"
                    />
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
        <div class="modal__body__filter-containers"
          :class="{'modal__body__scroll' : hasScroll}"
          v-if="filtersToDisplay.includes('technology') && visibleFilter === 'Technology'">
          <empty-field-filter-column
            :key="'field-empty-technology'"
            :filter="visibleFilter"
            :selected-filters="selectedFilters"
            @handle-toggle-empty-filters="handleToggleEmptyFiltersSelection"
          />
          <ul class="modal__body__filter-column"
            :style="columnCounter(technologyOptions)">
            <li :title="parent.text" v-for="(parent,index) in technologyOptions">
              <ds-button
                :class="[{'modal__body__active-filter-text': activeFilterNames.find((f) => f == parent.id)}, { 'modal__body__group-title-clip-text' : parent.children.length > 0 }]"
                class="modal__body__group-title"
                :label="parent.text"
                variant="minimal"
                @click="handleToggleTechnology(parent.id)"
                size="small"
              />
              <div class="modal__body__group-toggle-button"
                @click="toggleGroup(parent.text)" v-if="parent.children.length > 0">
                &middot&middot&middot
              </div>
              <ul v-if="expandedFilterGroups.includes(parent.text)">
                <li :title="child.text" v-for="child in parent.children">
                  <ds-button
                    :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == child.id)}"
                    class="modal__body__group--items modal__body__group--items-child"
                    :label="child.text"
                    variant="minimal"
                    @click="handleToggleTechnology(parent.id, child.id)"
                    size="small"
                  />
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <div class="modal__body__filter-containers"
          :class="{'modal__body__scroll' : hasScroll}"
          v-if="filtersToDisplay.includes('maturity') && visibleFilter == 'Maturity'">
          <empty-field-filter-column
            :key="'field-empty-maturity'"
            :filter="visibleFilter"
            :selected-filters="selectedFilters"
            @handle-toggle-empty-filters="handleToggleEmptyFiltersSelection"
          />
          <ul class="modal__body__filter-column" :style="columnCounter(maturityOptions)">
            <li :title="item" v-for="item in maturityOptions">
              <ds-button
                :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == item.toUpperCase())}"
                class="modal__body__list--items"
                :label="item"
                variant="minimal"
                @click="handleToggleMaturity(item)"
                size="small"
              />
            </li>
          </ul>
        </div>
        <div class="modal__body__filter-containers"
          :class="{'modal__body__scroll' : hasScroll}"
          v-if="filtersToDisplay.includes('type') && visibleFilter == 'Type'">
          <ul class="modal__body__filter-column"
            :style="columnCounter(businessTypeOptions)">
            <li :title="item" v-for="item in businessTypeOptions">
              <ds-button
                :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == item.toUpperCase())}"
                class="modal__body__list--items"
                :label="item"
                variant="minimal"
                @click="handleToggleType(item)"
                size="small"
              />
            </li>
          </ul>
        </div>
        <div class="modal__body__filter-containers"
          v-if="visibleFilter == 'Mentions' && filtersToDisplay.includes('mentions')">
          <div class="top-filter-section">
            <h6 class="top-filter-section__title">Metrics</h6>
            <slider class="top-filter-slider" label="Mentions" lazy
              v-model="mentionsFilter" max="500"
              :key="'slider-mentions-filter'"
              v-if="filtersToDisplay.includes('mentions')"/>
          </div>
        </div>
        <div class="modal__body__filter-containers"
          v-if="filtersToDisplay.includes('funding') && visibleFilter == 'Funding'">
          <div class="top-filter-section">
            <h6 class="top-filter-section__title">Total Funding Range</h6>
            <slider-total-funding class="top-filter-slider" rangeFormat="money"
              label="Total funding raised" lazy min="0"
              :model-value="totalFundingRange" max="100000000" step="500000"
              @update:modelValue="handleTotalFundingRangeUpdate"/>
          </div>
          <div class="top-filter-section" v-if="filtersToDisplay.includes('funding')">
            <h6 class="top-filter-section__title">Time frame funding</h6>
            <slider-funding-date class="top-filter-slider"
              @on-update="handleFundingDateRangeUpdate"
              v-if="filtersToDisplay.includes('funding')"/>
          </div>
          <br>
          <div class="top-filter-section">
            <h6 class="top-filter-section__title"
              style="max-width: unset; position: relative">Funding Types</h6>
          </div>
          <ul class="modal__body__filter-column"
            :style="columnCounter(fundingRoundTypes)">
            <li :title="item" v-for="item in fundingRoundTypes">
              <ds-button
                :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == item.toUpperCase())}"
                class="modal__body__list--items"
                :label="item"
                variant="minimal"
                size="small"
                @click="handleToggleFundingType(item)"
              />
            </li>
          </ul>
        </div>

        <div class="modal__body__filter-containers"
          v-if="filtersToDisplay.includes('market') && visibleFilter == 'Target markets'">
          <div class="top-filter-section">
            <h6 class="top-filter-section__title"
              style="max-width: unset; position: relative">{{ visibleFilter }}</h6>
          </div>
          <ul class="modal__body__filter-column"
            :style="columnCounter(targetMarkets)">
            <li :title="item" v-for="item in targetMarkets">
              <ds-button
                :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == item.toUpperCase())}"
                class="modal__body__list--items"
                :label="item"
                variant="minimal"
                size="small"
                @click="handleToggleTargetMarkets(item)"
              />
            </li>
          </ul>
        </div>

        <div v-if="reportsAreEnabled">
          <report-template-filter
            v-for="(reportTemplate, index) in filteredReportTemplates"
            :reportTemplate="reportTemplate"
            :key="'report-template-filter___' + index"
            @update-state="handleUpdatedReportTemplateFilterState"
          >
          </report-template-filter>
        </div>

        <div class="modal__body__filter-containers" v-if="visibleFilter == 'Clean Data'">

          <div class="top-filter-section">
            <empty-field-filter-column
              :key="'field-empty-url'"
              :filter="'URL'"
              :label="'Show empty URL'"
              :selected-filters="selectedFilters"
              @handle-toggle-empty-filters="handleToggleEmptyFiltersSelection"
            />
          </div>
          <div class="top-filter-section"
               v-if="filtersToDisplay.includes('possible_duplicate')">
            <ul class="modal__body__filter-column">
              <li :title="item" v-for="item in ['Possible Double']">
                <checkbox-button v-for="option in ['Yes']"
                 :key="option"
                 :val="true"
                 :model-value="possibleDuplicate"
                 class="modal__body__list--items"
                 label="Show duplicates"
                 :multiple="false"
                 @update:modelValue="handleTogglePossibleDouble"
                />
              </li>
            </ul>
          </div>
          <div class="top-filter-section" v-if="filtersToDisplay.includes('is_zombie')">
            <ul class="modal__body__filter-column">
              <li :title="item" v-for="item in ['Zombie']">
                <checkbox-button v-for="option in ['Yes']"
                 :key="option"
                 :val="true"
                 :model-value="zombie"
                 class="modal__body__list--items"
                 label="Show only zombie (dead) actors"
                 :multiple="false"
                 @update:modelValue="handleToggleZombie"
                />
              </li>
            </ul>
          </div>

          <div class="top-filter-section filter-section__date-ranges">
            <div>
              <h6 class="top-filter-section__title"
                style="max-width: unset; position: relative">Created at</h6>
              <div class="top-filter-section filter-section__datepicker">
                <datepicker
                  @update:modelValue="handleCreatedAtStartRangeUpdate"
                  :inputId="'created-at_start'"
                  :placeholder="'After'"
                  :allow-reset="true"
                  :model-value="createdAtDatesFilter.start"
                />
                <datepicker
                  @update:modelValue="handleCreatedAtEndRangeUpdate"
                  :inputId="'created-at_end'"
                  :placeholder="'Before'"
                  :min-date="createdAtDatesFilter.start"
                  :allow-reset="true"
                  :model-value="createdAtDatesFilter.end"
                />
              </div>
            </div>

            <div>
              <h6 class="top-filter-section__title"
                style="max-width: unset; position: relative">Updated at</h6>
              <div class="top-filter-section filter-section__datepicker">
                <datepicker
                  @update:modelValue="handleUpdatedAtStartRangeUpdate"
                  :inputId="'updated-at_start'"
                  :placeholder="'After'"
                  :allow-reset="true"
                  :model-value="updatedAtDatesFilter.start"
                />
                <datepicker
                  @update:modelValue="handleUpdatedAtEndRangeUpdate"
                  :inputId="'updated-at_end'"
                  :placeholder="'Before'"
                  :min-date="updatedAtDatesFilter.start"
                  :allow-reset="true"
                  :model-value="updatedAtDatesFilter.end"
                />
              </div>
            </div>
          </div>

          <div class="top-filter-section">
            <h6 class="top-filter-section__title"
              style="max-width: unset; position: relative">Completeness (%)</h6>
            <slider label=""
              min="0" max="100"
              :key="'slider-completeness-filter'"
              v-model="completenessFilter"
            />
          </div>

          <div class="top-filter-section" v-if="filtersToDisplay.includes('claimed')">
            <h6 class="top-filter-section__title"
              style="max-width: unset; position: relative">Claimed</h6>
          </div>
          <div class="top-filter-section" v-if="filtersToDisplay.includes('claimed')">
            <ul class="modal__body__filter-column" :style="columnCounter(['Yes', 'No'])">
              <li :title="item" v-for="item in ['Yes', 'No']">
                <ds-button
                  :class="{ 'modal__body__active-filter-text': activeFilterNames.find((f) => f == item.toUpperCase())}"
                  :label="item"
                  class="modal__body__list--items"
                  variant="minimal"
                  size="small"
                  @click="handleToggleClaimed(item)"
                />
              </li>
            </ul>
          </div>

          <div v-if="filtersToDisplay.includes('claimed') && activeFilterNames.find((f) => f === 'YES')" class="top-filter-section filter-section__claimed-date-ranges">
            <div>
              <h6 class="top-filter-section__title"
                  style="max-width: unset; position: relative">Claimed (created) date range</h6>
              <div class="top-filter-section filter-section__datepicker">
                <datepicker
                  @update:modelValue="handleClaimedAtStartRangeUpdate"
                  :inputId="'claimed_after'"
                  :placeholder="'After'"
                  :allow-reset="true"
                  :model-value="claimedAtDatesFilter.start"
                />
                <datepicker
                  @update:modelValue="handleClaimedAtEndRangeUpdate"
                  :inputId="'claimed_before'"
                  :placeholder="'Before'"
                  :min-date="claimedAtDatesFilter.start"
                  :allow-reset="true"
                  :model-value="claimedAtDatesFilter.end"
                />
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Slider from '../../Slider/Slider.vue'
  import SliderFoundingDate from '../../Slider/SliderFoundingDate.vue'
  import SliderFundingDate from '../../Slider/SliderFundingDate.vue'
  import SliderTotalFunding from '../../Slider/SliderTotalFunding.vue'
  import SliderRevenue from '../../Slider/SliderRevenue.vue'
  import SliderEmployees from '../../Slider/SliderEmployees.vue'
  import Datepicker from '../../Form/Datepicker.vue'
  import Checkbox from '../../Form/Checkbox.vue'
  import ReportTemplateFilter from './ReportTemplateFilter.vue'
  import EmptyFieldFilterColumn from './EmptyFieldFilterColumn.vue'

  import CheckboxButton from '../../CheckboxButton/CheckboxButton.vue'

  import { isTaxonomy, MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../../store/modules/filters.js'

  import KEYCODES from '../../../constants/keycodes.js'
  import { marketCountryOptions, marketOptions, sustainabilityGoals, sustainabilityGoalsNL } from '../../../constants/config.js'

  import { twoLevelTaxonomiesToOptions } from '../../../util/hierarchy-taxonomy.js'

  import { _unique, getAllReportFields, getReportTemplateByReportIdentifier } from '../../../util/helpers.js'

  import FiltersMixin from '../../../util/FiltersMixin.js'

  import { completenessFilter, countryFocus, createdAtDatesFilter, claimedAtDatesFilter, customScoreMax, customScoreMin, mentionsFilter, updatedAtDatesFilter } from '../../Filters/Filters.js'
  import NaceCodePicker from '../../NaceCodePicker/NaceCodePicker.vue'

  export default {
    props: {
      closeOnBlur: Boolean,
      id: {
        type: String,
      },
      isCloseable: {
        type: Boolean,
        default: true,
      },
      size: {
        type: String,
        default: '',
        validator: val => ['large', ''].includes(val),
      },
      filtersToDisplay: {
        type: Array,
        default: [],
      },
      selectedFilters: {
        type: Array,
        default: [],
      },
      showChangeBadges: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        visibleFilter: '',
        industrySelection: [],
        technologySelection: [],
        currentEmployeesSizeCode: [],
        currentExitRoundType: [],
        sustainabilityGoalSelection: [],
        aspectSelection: [],
        funnelStageSelection: [],
        relevantSelection: [],
        claimedSelection: [],
        emptyFiltersSelection: [],
        domainSelection: [],
        productFeatureASelection: [],
        productFeatureBSelection: [],
        productFeatureCSelection: [],
        typeSelection: [],
        fundingTypeSelection: [],
        maturitySelection: [],
        marketOptions,
        marketCountryOptions,
        targetMarketSelection: [],
        naceSelection: [],
        // countrySelection: [],
        defaultSelection: {
          legends: true,
          membership: true,
          stages: true,
          created_at_start: true,
          created_at_end: true,
          updated_at_start: true,
          updated_at_end: true,
        },
        hasScroll: false,
        expandedFilterGroups: [],
        sustainabilityGoals,
        createdAtStart: null,
        createdAtEnd: null,
        updatedAtStart: null,
        updatedAtEnd: null,
      }
    },
    computed: {
      isDeveloper () {
        return this.$store.getters.isDeveloper
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      isMember () {
        return this.$store.getters.isTeamMember
      },
      filteredReportTemplates () {
        if (!this.filterableReportTemplates) {
          return []
        }
        return this.filterableReportTemplates.filter(reportTemplate => {
          return this.visibleFilter === 'report_template_' + reportTemplate.id
        })
      },
      translatedSustainabilityGoals () {
        return this.$store.getters.activeLocale === 'nl' ? sustainabilityGoalsNL : sustainabilityGoals
      },
      sdgImagesPath () {
        return this.$store.getters.activeLocale === 'nl' ? '/images/SDG_nl/' : '/images/SDG/'
      },
      keywords: {
        get () {
          return this.selectedFilters || []
        },
      },
      appropriateCategoryOptionsForPage () {
        if (this.$route.name.indexOf('simplified') >= 0) {
          if (this.$route.name.indexOf('product') >= 0) {
            return this.productCategoryOptions
          }
          return this.nonProductCategoryOptions
        } else {
          return this.categoryOptions
        }
      },
      isMobile () {
        return this.$store.getters.isMobile
      },
      challengesAreEnabled () {
        return this.$store.state.config.challengesAreEnabled
      },
      messageBoardsAreEnabled () {
        return this.$store.getters.areMessageBoardsEnabled
      },
      classes () {
        return {
          [`modal__container--${this.size}`]: this.size,
        }
      },
      navigationTitles () {
        // Here the titles for the side navigation will be added with the correct title
        var filteredTitles = []

        this.filtersToDisplay.forEach(item => {
          switch (item) {
            case 'category':
            case 'legend':
              filteredTitles.push('Category')
              break
            case 'employees':
            case 'patent_count':
            case 'founding_date':
            case 'revenue':
            case 'stage':
              filteredTitles.push('Growth Stages')
              break
            case 'custom_score':
            case 'funnel_stage':
              filteredTitles.push('Segmentation')
              break
            case 'membership':
              filteredTitles.push('Membership')
              break
            case 'country-focus':
              // filteredTitles.push('Market Focus');
              break
            case 'industries':
              filteredTitles.push('Industries')
              break
            case 'domains':
              filteredTitles.push('Domains')
              break
            case 'product_features_a':
              filteredTitles.push('Product Features A')
              break
            case 'product_features_b':
              filteredTitles.push('Product Features B')
              break
            case 'product_features_c':
              filteredTitles.push('Product Features C')
              break
            case 'aspects':
              filteredTitles.push('Aspects')
              break
            case 'technology':
              filteredTitles.push('Technology')
              break
            case 'market':
              filteredTitles.push('Target markets')
              break
            case 'maturity':
              filteredTitles.push('Maturity')
              break
            case 'type':
              filteredTitles.push('Type')
              break
            case 'activities':
              filteredTitles.push('Activities')
              break
            case 'sustainability_goal':
              filteredTitles.push('Sustainability Goals')
              break
            case 'reports':
              // Push all filterable report templates as separate titles
              this.filterableReportTemplates.forEach(function (report) {
                filteredTitles.push('report_template_' + report.id)
              })
              break
            case 'expertises':
              filteredTitles.push('Expertises')
              break
            case 'motivations':
              filteredTitles.push('Motivations')
              break
            case 'business_aspects':
              filteredTitles.push('Business Aspects')
              break
            case 'readiness_levels':
              filteredTitles.push('Readiness Levels')
              break
            case 'process_steps':
              filteredTitles.push('Process Steps')
              break
            case 'possible_duplicate':
            case 'is_zombie':
            case 'claimed':
              if ((this.isOwner || this.is) && filteredTitles.find((f) => f == 'Clean Data') == undefined) {
                filteredTitles.push('Clean Data')
              }
              break
            case 'mentions':
              // We only display the "time frame" section if the "mentions" filter is enabled
              if (filteredTitles.find((f) => f == 'Mentions') == undefined && this.filtersToDisplay.includes('mentions')) {
                filteredTitles.push('Mentions')
              }
              break
            case 'funding':
              // Only if the filtersToDisplay is "funding" do we add the funding title
              if (item == 'funding') {
                if (filteredTitles.find((f) => f == 'Funding') == undefined) {
                  filteredTitles.push('Funding')
                }
              }
              break
          }
        })

        // Needed to show Clean data tab
        if (this.isOwner || this.isMember) {
          filteredTitles.push('Clean Data')
          filteredTitles = _unique(filteredTitles)
        }

        return filteredTitles
      },
      navigationTitlesAllowEmpties () {
        return this.navigationTitles.filter(t => t.toUpperCase() !== 'CLEAN DATA')
      },
      activeFilterNames () {
        var filterNames = []

        this.keywords.map(item => {
          if (item.value !== '-') {
            if (typeof item.value === 'number') {
              filterNames.push(item.value)
            } else if (typeof item.value === 'string') {
              filterNames.push(item.value.toUpperCase())
            }
          }
        })

        return filterNames
      },
      ecosystemHasFilterableReportFields () {
        // Filterable report fields are fields with type score, number or options
        return this.$store.getters.knowledgeSharing && this.filterableReportFields.length > 0
      },
      possibleDuplicate () {
        var keyword = this.keywords.filter(f => f.facet == 'possible_duplicate')

        if (keyword.length > 0) {
          return keyword[0].value
        }

        return false
      },
      zombie () {
        var keyword = this.keywords.filter(f => f.facet == 'is_zombie')

        if (keyword.length > 0) {
          return keyword[0].value
        }

        return false
      },
      domainsOptions () {
        const domains = twoLevelTaxonomiesToOptions(this.$store.state.taxonomies.domains, 'domains')

        domains.map(k => k.children).map(f => {
          if (f.length > 0) {
            f.shift()
          }
        })
        return domains
      },
      productFeaturesAOptions () {
        const productFeaturesA = twoLevelTaxonomiesToOptions(this.$store.state.taxonomies.productFeaturesA, 'children')

        productFeaturesA.map(k => k.children).map(f => {
          if (f.length > 0) {
            f.shift()
          }
        })
        return productFeaturesA
      },
      productFeaturesBOptions () {
        const productFeaturesB = twoLevelTaxonomiesToOptions(this.$store.state.taxonomies.productFeaturesB, 'children')

        productFeaturesB.map(k => k.children).map(f => {
          if (f.length > 0) {
            f.shift()
          }
        })
        return productFeaturesB
      },
      productFeaturesCOptions () {
        const productFeaturesC = twoLevelTaxonomiesToOptions(this.$store.state.taxonomies.productFeaturesC, 'children')

        productFeaturesC.map(k => k.children).map(f => {
          if (f.length > 0) {
            f.shift()
          }
        })
        return productFeaturesC
      },
      displayDeleteAllFilters () {
        return (this.keywords.length > 0 && this.keywords.find((f) => f.facet !== 'actor_type')) ||
          this.$store.state.filters.employeeRange.employeeLowerBound ||
          this.$store.state.filters.employeeRange.employeeUpperBound ||
          this.$store.state.filters.totalFunding.minTotalFunding ||
          this.$store.state.filters.totalFunding.maxTotalFunding ||
          this.$store.state.filters.patentCountRange.patentCountMin ||
          this.$store.state.filters.patentCountRange.patentCountMax ||
          this.$store.state.filters.relationshipCountRange.relationshipCountMin ||
          this.$store.state.filters.relationshipCountRange.relationshipCountMax ||
          this.$store.state.filters.revenueRange.revenueMin ||
          this.$store.state.filters.revenueRange.revenueMax ||
          this.$store.state.filters.dateRanges.foundingDateEnd ||
          this.$store.state.filters.dateRanges.foundingDateStart ||
          this.$store.state.filters.dateRanges.fundingDateEnd ||
          this.$store.state.filters.dateRanges.fundingDateStart ||
          this.$store.state.filters.dateRanges.createdAtStart ||
          this.$store.state.filters.dateRanges.createdAtEnd ||
          this.$store.state.filters.dateRanges.claimedAtStart ||
          this.$store.state.filters.dateRanges.claimedAtEnd ||
          this.$store.state.filters.dateRanges.updatedAtStart ||
          this.$store.state.filters.dateRanges.updatedAtEnd ||
          this.$store.state.filters.completenessRange.completenessMin ||
          this.$store.state.filters.completenessRange.completenessMax ||
          this.reportFiltersActive
      },
      reportFiltersActive () {
        if (!getAllReportFields() || !this.$store.state.filters.reportFilters) {
          return false
        }

        let reportFilterEnabled = false

        for (const key in this.$store.state.filters.reportFilters) {
          if (this.$store.state.filters.reportFilters[key]) {
            const reportValue = this.$store.state.filters.reportFilters[key]

            if (Array.isArray(reportValue) && reportValue.length > 0) {
              reportFilterEnabled = true
            } else if (!Array.isArray(reportValue) && reportValue) {
              reportFilterEnabled = true
            }
          }
        }

        return reportFilterEnabled
      },
      targetMarkets () {
        return this.marketOptions.concat(this.marketCountryOptions)
      },
      fundingRoundTypes () {
        return [
          'Angel',
          'Convertible Note',
          'Corporate Round',
          'Crowdfunding',
          'Debt Financing',
          'Grant',
          'ICO',
          'Post-IPO Debt',
          'Post-IPO Equity',
          'Post-IPO Secondary',
          'Pre-Seed',
          'Seed',
          'Series A',
          'Series B',
          'Series C',
          'Series D',
          'Series E',
          'Series F',
          'Series G',
          'Series H',
          'Series I',
          'Series J',
          'Other',
          'vc',
          /*'pre-seed',
          'Seed',
          'vc',
          'Series A',
          'Series B',
          'Series C',
          'Series D',
          'Series E',
          'Series F',
          'Series G',
          'Convertible loans',
          'ICO',
          'other',*/
        ]
      },
      totalFundingFilters () {
        return this.$store.state.filters.totalFunding
      },
      totalFundingRange () {
        if (!this.totalFundingFilters.minTotalFunding && !this.totalFundingFilters.maxTotalFunding) {
          return { start: 0, end: 100000000 }
        }
        return { start: this.totalFundingFilters.minTotalFunding, end: this.totalFundingFilters.maxTotalFunding }
      },
      revenueRange () {
        if (!this.$store.state.filters.revenueRange.revenueMin && !this.$store.state.filters.revenueRange.revenueMax) {
          return { start: 0, end: 100000000 }
        }
        return { start: this.$store.state.filters.revenueRange.revenueMin, end: this.$store.state.filters.revenueRange.revenueMax }
      },
      employeeRange () {
        if (!this.$store.state.filters.employeeRange.employeeUpperBound && !this.$store.state.filters.employeeRange.employeeLowerBound) {
          return { start: 0, end: 500 }
        }

        return { start: this.$store.state.filters.employeeRange.employeeLowerBound, end: this.$store.state.filters.employeeRange.employeeUpperBound }
      },
      patentCountRange () {
        if (!this.$store.state.filters.patentCountRange.patentCountMin && !this.$store.state.filters.patentCountRange.patentCountMax) {
          return { start: 0, end: 30 }
        }

        return { start: this.$store.state.filters.patentCountRange.patentCountMin, end: this.$store.state.filters.patentCountRange.patentCountMax }
      },
      relationshipCountRange () {
        if (!this.$store.state.filters.relationshipCountRange.relationshipCountMin && !this.$store.state.filters.relationshipCountRange.relationshipCountMax) {
          return { start: 0, end: 30 }
        }

        return { start: this.$store.state.filters.relationshipCountRange.relationshipCountMin, end: this.$store.state.filters.relationshipCountRange.relationshipCountMax }
      },
      reportsAreEnabled () {
        return true // deprecated
      },
      canDisplaySegmentation () {
        return this.filtersToDisplay.includes('funnel_stage') ||
          this.filtersToDisplay.includes('custom_score') ||
          this.filtersToDisplay.includes('relevant')
      },
      countryFocus,
      mentionsFilter,
      createdAtDatesFilter,
      claimedAtDatesFilter,
      updatedAtDatesFilter,
      completenessFilter,
      customScoreMax,
      customScoreMin,
    },
    methods: {
      hasChanges (navigationTile) {
        return !!this.keywords.find(keyword => keyword.facet === this.getNormalisedValue(navigationTile))
      },
      getEmployeeSizeCodeLabel (item) {
        if (item == 'XS') {
          return item + ' (1-10)'
        } else if (item == 'S') {
          return item + ' (11-50)'
        } else if (item == 'M') {
          return item + ' (51-100)'
        } else if (item == 'L') {
          return item + ' (101-500)'
        } else if (item == 'XL') {
          return item + ' (501-1000)'
        } else if (item == 'XXL') {
          return item + ' (1000+)'
        }
      },
      keyUpHandler (e) {
        if (e.keyCode === KEYCODES.ESC) {
          this.close()
        }
      },
      blur () {
        if (this.closeOnBlur) {
          this.close()
        }
      },
      close () {
        if (this.isCloseable) {
          this.$emit('close')
        }
      },
      toggleGroup (groupParentName) {
        var index = this.expandedFilterGroups.indexOf(groupParentName)
        if (index > -1) {
          this.expandedFilterGroups.splice(index, 1)
        } else {
          this.expandedFilterGroups.push(groupParentName)
        }
      },
      columnCounter (items) {
        if (this.isMobile) {
          return `column-count:${1}; width:100%;`
        }
        var itemCounter = items.length
        items.map(f => {
          if (f.children !== undefined && f.children.length > 0) {
            itemCounter += f.children.length
          }
          if (f.industries !== undefined && f.industries.length > 0) {
            itemCounter += f.industries.length
          }
        })
        if (itemCounter <= 10) {
          this.hasScroll = false
          return `column-count:${1}; width:150px;`
        }
        if (itemCounter > 10 && itemCounter <= 20) {
          this.hasScroll = false
          return `column-count:${2}; width:350px;`
        }
        if (itemCounter > 20 && itemCounter <= 30) {
          this.hasScroll = false
          return `column-count:${3};`
        }
        if (itemCounter > 30) {
          this.hasScroll = true
          return `column-count:${3};`
        }
      },
      changeFilter (filter) {
        this.visibleFilter = filter
      },
      handleToggleActivity (item) {
        if (this.defaultSelection.activities == true) {
          this.defaultSelection.activities = false
          this.setByFacet('activities', [])
        }
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.TOGGLE_LEGEND_ITEM,
          content: { facet: 'activities', value: item },
        })
      },
      handleToggleExpertise (item) {
        if (this.defaultSelection.expertises == true) {
          this.defaultSelection.expertises = false
          this.setByFacet('expertises', [])
        }
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.TOGGLE_LEGEND_ITEM,
          content: { facet: 'expertises', value: item },
        })
      },
      handleToggleMotivation (item) {
        if (this.defaultSelection.motivations == true) {
          this.defaultSelection.motivations = false
          this.setByFacet('motivations', [])
        }
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.TOGGLE_LEGEND_ITEM,
          content: { facet: 'motivations', value: item },
        })
      },
      handleToggleBusinessAspect (item) {
        if (this.defaultSelection.business_aspects == true) {
          this.defaultSelection.business_aspects = false
          this.setByFacet('business_aspects', [])
        }
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.TOGGLE_LEGEND_ITEM,
          content: { facet: 'business_aspects', value: item },
        })
      },
      handleToggleReadinessLevel (item) {
        if (this.defaultSelection.readiness_levels == true) {
          this.defaultSelection.readiness_levels = false
          this.setByFacet('readiness_levels', [])
        }
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.TOGGLE_LEGEND_ITEM,
          content: { facet: 'readiness_levels', value: item },
        })
      },
      handleToggleProcessStep (item) {
        if (this.defaultSelection.process_steps == true) {
          this.defaultSelection.process_steps = false
          this.setByFacet('process_steps', [])
        }
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.TOGGLE_LEGEND_ITEM,
          content: { facet: 'process_steps', value: item },
        })
      },
      handleToggleCategory (item) {
        if (this.defaultSelection.category == true) {
          this.defaultSelection.category = false
          this.setByFacet('category', [])
        }
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.TOGGLE_LEGEND_ITEM,
          content: { facet: 'category', value: item },
        })
      },
      handleToggleMembership (item) {
        if (this.defaultSelection.membership == true) {
          this.defaultSelection.membership = false
          this.setByFacet('membership', [])
        }
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.TOGGLE_LEGEND_ITEM,
          content:
            { facet: 'membership', value: item },
        })
      },
      handleToggleEmployeeSize (item) {
        var index = this.currentEmployeesSizeCode.indexOf(item)

        if (index > -1) {
          this.currentEmployeesSizeCode.splice(index, 1)
        } else {
          this.currentEmployeesSizeCode.push(item)
        }

        var currentEmployeesSizeCodeArray = this.currentEmployeesSizeCode
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.SET_BY_FACET,
          content: {
            facet: 'current_employees.size_code',
            values: currentEmployeesSizeCodeArray,
          },
        })
      },
      handleToggleExitRoundType (item) {
        var index = this.currentExitRoundType.indexOf(item)

        if (index > -1) {
          this.currentExitRoundType.splice(index, 1)
        } else {
          this.currentExitRoundType.push(item)
        }

        var currentExitRoundTypeArray = this.currentExitRoundType
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.SET_BY_FACET,
          content: { facet: 'exit_round_type', values: currentExitRoundTypeArray },
        })
      },
      handleToggleStage (item) {
        if (this.defaultSelection.stages == true) {
          this.defaultSelection.stages = false
          this.setByFacet('stage', [])
        }
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.TOGGLE_LEGEND_ITEM,
          content: { facet: 'stage', value: item },
        })
        // this.$store.commit('FILTERS/TOGGLE_STAGE', { facet: 'stage', value: item })
      },
      handleToggleAspects (parentId, childId) {
        // First assemble the string to be passed to the filter state
        /* var filterString = ''
        if (child !== undefined) {
          filterString = parent + ' > ' + child
        } else {
          filterString = parent
        } */

        var aspectId = parentId

        if (childId) {
          aspectId = childId
        }

        // Search for the existance of the current filter string in the array, and add or remove the value from it.
        var aspectSelectionIndex = this.aspectSelection.indexOf(aspectId)
        if (aspectSelectionIndex > -1) {
          this.aspectSelection.splice(aspectSelectionIndex, 1)
          // In case of a title is clicked and since it doesn't pass a value of the child, remove all its children
          if (!childId) {
            this.allAspectOptions.forEach(f => {
              if (f.id == parentId) {
                f.aspects.forEach(child => {
                  // Checks if the index child in the array, and removes it from the array based on it
                  var childIndex = this.aspectSelection.indexOf(child.id)
                  if (childIndex > -1) {
                    this.aspectSelection.splice(childIndex, 1)
                  }
                })
              }
            })
          }
        } else {
          this.aspectSelection.push(aspectId)
          // In case of a title is clicked and since it doesn't pass a value of the child, add all its children
          if (!childId) {
            this.allAspectOptions.forEach(f => {
              if (f.id == parentId) {
                f.aspects.forEach(child => {
                  // Checks if the child is already inserted in the array, if not its inserted
                  var childIndex = this.aspectSelection.indexOf(child.id)
                  if (childIndex < 0) {
                    this.aspectSelection.push(child.id)
                  }
                })
              }
            })
          }
        }

        var aspectsArray = this.aspectSelection
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.SET_BY_FACET,
          content: { facet: 'aspects', values: aspectsArray },
        })
      },
      handleToggleIndustries (parentId, childId) {
        /* // First assemble the string to be passed to the filter state
        var filterString = ''
        if (child !== undefined) {
          filterString = parent + ' > ' + child
        } else {
          filterString = parent
        } */

        var industryId = parentId

        if (childId) {
          industryId = childId
        }

        // Search for the existance of the current filter string in the array, and add or remove the value from it.
        var industrySelectionIndex = this.industrySelection.indexOf(industryId)
        if (industrySelectionIndex > -1) {
          this.industrySelection.splice(industrySelectionIndex, 1)
          // In case of a title is clicked and since it doesn't pass a value of the child, remove all its children
          if (!childId) {
            this.allIndustriesOptions.forEach(f => {
              if (f.id == parentId) {
                f.industries.forEach(child => {
                  // Checks if the index child in the array, and removes it from the array based on it
                  var childIndex = this.industrySelection.indexOf(child.id)
                  if (childIndex > -1) {
                    this.industrySelection.splice(childIndex, 1)
                  }
                })
              }
            })
          }
        } else {
          this.industrySelection.push(industryId)
          // In case of a title is clicked and since it doesn't pass a value of the child, add all its children
          if (!childId) {
            this.allIndustriesOptions.forEach(f => {
              if (f.id == parentId) {
                f.industries.forEach(child => {
                  // Checks if the child is already inserted in the array, if not its inserted
                  var childIndex = this.industrySelection.indexOf(child.id)
                  if (childIndex < 0) {
                    this.industrySelection.push(child.id)
                  }
                })
              }
            })
          }
        }

        var industryArray = this.industrySelection
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.SET_BY_FACET,
          content: { facet: 'industries', values: industryArray },
        })
      },
      handleToggleTechnology (parentId, childId) {
        let taxonomyId = parentId

        if (childId) {
          taxonomyId = childId
        }

        const technologySelectionIndex = this.technologySelection.indexOf(taxonomyId)
        if (technologySelectionIndex > -1) {
          this.technologySelection.splice(technologySelectionIndex, 1)
          // In case of a title is clicked and since it doesn't pass a value of the child, remove all its children
          if (!childId) {
            this.technologyOptions.forEach(f => {
              if (f.id === parentId) {
                f.children.forEach(child => {
                  // Checks if the index child in the array, and removes it from the array based on it
                  const childIndex = this.technologySelection.indexOf(child.id)
                  if (childIndex > -1) {
                    this.technologySelection.splice(childIndex, 1)
                  }
                })
              }
            })
          }
        } else {
          this.technologySelection.push(taxonomyId)
          // In case of a title is clicked and since it doesn't pass a value of the child, add all its children
          if (!childId) {
            this.technologyOptions.forEach(f => {
              if (f.id === parentId) {
                f.children.forEach(child => {
                  // Checks if the child is already inserted in the array, if not its inserted
                  const childIndex = this.technologySelection.indexOf(child.id)
                  if (childIndex < 0) {
                    this.technologySelection.push(child.id)
                  }
                })
              }
            })
          }
        }

        const technologiesFilter = this.technologySelection
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.SET_BY_FACET,
          content: { facet: 'technology', values: technologiesFilter },
        })
      },
      handleToggleSustainabilityGoal (item) {
        var index = this.sustainabilityGoalSelection.indexOf(item)

        if (index > -1) {
          this.sustainabilityGoalSelection.splice(index, 1)
        } else {
          this.sustainabilityGoalSelection.push(item)
        }

        var sustainabilityGoalsArray = this.sustainabilityGoalSelection
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.SET_BY_FACET,
          content: { facet: 'sustainability_goal', values: sustainabilityGoalsArray },
        })
      },
      handleToggleFunnelStage (item) {
        var index = this.funnelStageSelection.indexOf(item)
        if (index > -1) {
          this.funnelStageSelection.splice(index, 1)
        } else {
          this.funnelStageSelection.push(item)
        }
        var funnelStagesArray = this.funnelStageSelection
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.SET_BY_FACET,
          content: { facet: 'funnel_stage', values: funnelStagesArray },
        })
      },
      handleToggleRelevancy (item) {
        var index = this.relevantSelection.indexOf(item)

        if (index > -1) {
          this.relevantSelection.splice(index, 1)
        } else {
          this.relevantSelection.push(item)
        }

        var relevantArray = this.relevantSelection

        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.SET_BY_FACET,
          content: { facet: 'relevant', values: relevantArray },
        })
      },
      handleToggleNaceCode (item) {
        this.naceSelection = item.codes
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.SET_BY_FACET,
          content: { facet: 'nace', values: item.codes },
        })
      },
      handleToggleClaimed (item) {
        var index = this.claimedSelection.indexOf(item)

        if (index > -1) {
          this.claimedSelection.splice(index, 1)

          // Reset the claimed date range
          this.handleClaimedAtStartRangeUpdate(null)
          this.handleClaimedAtEndRangeUpdate(null)
        } else {
          this.claimedSelection.push(item)
        }

        var relevantArray = this.claimedSelection

        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.SET_BY_FACET,
          content: { facet: 'claimed', values: relevantArray },
        })
      },
      handleToggleType (item) {
        var index = this.typeSelection.indexOf(item)
        if (index > -1) {
          this.typeSelection.splice(index, 1)
        } else {
          this.typeSelection.push(item)
        }
        var typeArray = this.typeSelection
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.SET_BY_FACET,
          content: { facet: 'type', values: typeArray },
        })
      },
      handleToggleFundingType (item) {
        var index = this.fundingTypeSelection.indexOf(item)

        if (index > -1) {
          this.fundingTypeSelection.splice(index, 1)
        } else {
          this.fundingTypeSelection.push(item)
        }

        var fundingTypeArray = this.fundingTypeSelection
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.SET_BY_FACET,
          content: { facet: 'funding_type', values: fundingTypeArray },
        })
      },
      handleToggleTargetMarkets (item) {
        var index = this.targetMarketSelection.indexOf(item)

        if (index > -1) {
          this.targetMarketSelection.splice(index, 1)
        } else {
          this.targetMarketSelection.push(item)
        }

        var targetMarketArray = this.targetMarketSelection
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.SET_BY_FACET,
          content: { facet: 'market', values: targetMarketArray },
        })
      },
      handleTogglePossibleDouble (item) {
        if (item) {
          this.$emit('update-state', {
            name: FILTERS_MUTATION_TYPES.SET_BY_FACET,
            content: { facet: 'possible_duplicate', values: item ? '1' : '0' },
          })
        } else {
          this.$emit('update-state', {
            name: FILTERS_MUTATION_TYPES.CLEAR_BY_FACET,
            content: 'possible_duplicate',
          })
        }
      },
      handleToggleZombie (item) {
        if (item) {
          this.$emit('update-state', {
            name: FILTERS_MUTATION_TYPES.SET_BY_FACET,
            content: { facet: 'is_zombie', values: item ? '1' : '0' },
          })
        } else {
          this.$emit('update-state', {
            name: FILTERS_MUTATION_TYPES.CLEAR_BY_FACET,
            content: 'is_zombie',
          })
        }
      },
      handleToggleMaturity (item) {
        var index = this.maturitySelection.indexOf(item)

        if (index > -1) {
          this.maturitySelection.splice(index, 1)
        } else {
          this.maturitySelection.push(item)
        }

        var maturityArray = this.maturitySelection
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.SET_BY_FACET,
          content: { facet: 'maturity', values: maturityArray },
        })
      },
      handleToggleProductFeaturesA (parentId, childId) {
        let productFeatureAId = parentId

        if (childId) {
          productFeatureAId = childId
        }

        const productFeatureASelectionIndex = this.productFeatureASelection.indexOf(productFeatureAId)
        if (productFeatureASelectionIndex > -1) {
          this.productFeatureASelection.splice(productFeatureASelectionIndex, 1)
          // In case of a title is clicked and since it doesn't pass a value of the child, remove all its children
          if (!childId) {
            this.productFeaturesAOptions.forEach(f => {
              if (f.id === parentId) {
                f.children.forEach(child => {
                  // Checks if the index child in the array, and removes it from the array based on it
                  const childIndex = this.productFeatureASelection.indexOf(child.id)
                  if (childIndex > -1) {
                    this.productFeatureASelection.splice(childIndex, 1)
                  }
                })
              }
            })
          }
        } else {
          this.productFeatureASelection.push(productFeatureAId)
          // In case of a title is clicked and since it doesn't pass a value of the child, add all its children
          if (!childId) {
            this.productFeaturesAOptions.forEach(f => {
              if (f.id === parentId) {
                f.children.forEach(child => {
                  // Checks if the child is already inserted in the array, if not its inserted
                  const childIndex = this.productFeatureASelection.indexOf(child.id)
                  if (childIndex < 0) {
                    this.productFeatureASelection.push(child.id)
                  }
                })
              }
            })
          }
        }

        const productFeatureAArray = this.productFeatureASelection
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.SET_BY_FACET,
          content: { facet: 'product_features_a', values: productFeatureAArray },
        })
      },
      handleToggleProductFeaturesB (parentId, childId) {
        let productFeatureBId = parentId

        if (childId) {
          productFeatureBId = childId
        }

        const productFeatureBSelectionIndex = this.productFeatureBSelection.indexOf(productFeatureBId)
        if (productFeatureBSelectionIndex > -1) {
          this.productFeatureBSelection.splice(productFeatureBSelectionIndex, 1)
          // In case of a title is clicked and since it doesn't pass a value of the child, remove all its children
          if (!childId) {
            this.productFeaturesBOptions.forEach(f => {
              if (f.id === parentId) {
                f.children.forEach(child => {
                  // Checks if the index child in the array, and removes it from the array based on it
                  const childIndex = this.productFeatureBSelection.indexOf(child.id)
                  if (childIndex > -1) {
                    this.productFeatureBSelection.splice(childIndex, 1)
                  }
                })
              }
            })
          }
        } else {
          this.productFeatureBSelection.push(productFeatureBId)
          // In case of a title is clicked and since it doesn't pass a value of the child, add all its children
          if (!childId) {
            this.productFeaturesBOptions.forEach(f => {
              if (f.id === parentId) {
                f.children.forEach(child => {
                  // Checks if the child is already inserted in the array, if not its inserted
                  const childIndex = this.productFeatureBSelection.indexOf(child.id)
                  if (childIndex < 0) {
                    this.productFeatureBSelection.push(child.id)
                  }
                })
              }
            })
          }
        }

        const productFeatureBArray = this.productFeatureBSelection
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.SET_BY_FACET,
          content: { facet: 'product_features_b', values: productFeatureBArray },
        })
      },
      handleToggleProductFeaturesC (parentId, childId) {
        let productFeatureCId = parentId

        if (childId) {
          productFeatureCId = childId
        }

        const productFeatureCSelectionIndex = this.productFeatureCSelection.indexOf(productFeatureCId)
        if (productFeatureCSelectionIndex > -1) {
          this.productFeatureCSelection.splice(productFeatureCSelectionIndex, 1)
          // In case of a title is clicked and since it doesn't pass a value of the child, remove all its children
          if (!childId) {
            this.productFeaturesCOptions.forEach(f => {
              if (f.id === parentId) {
                f.children.forEach(child => {
                  // Checks if the index child in the array, and removes it from the array based on it
                  const childIndex = this.productFeatureCSelection.indexOf(child.id)
                  if (childIndex > -1) {
                    this.productFeatureCSelection.splice(childIndex, 1)
                  }
                })
              }
            })
          }
        } else {
          this.productFeatureCSelection.push(productFeatureCId)
          // In case of a title is clicked and since it doesn't pass a value of the child, add all its children
          if (!childId) {
            this.productFeaturesCOptions.forEach(f => {
              if (f.id === parentId) {
                f.children.forEach(child => {
                  // Checks if the child is already inserted in the array, if not its inserted
                  const childIndex = this.productFeatureCSelection.indexOf(child.id)
                  if (childIndex < 0) {
                    this.productFeatureCSelection.push(child.id)
                  }
                })
              }
            })
          }
        }

        const productFeatureCArray = this.productFeatureCSelection
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.SET_BY_FACET,
          content: { facet: 'product_features_c', values: productFeatureCArray },
        })
      },
      handleToggleDomains (parentId, childId) {
        // First assemble the string to be passed to the filter state
        /* var filterString = ''
        if (child !== undefined) {
          filterString = parent + ' > ' + child
        } else {
          filterString = parent
        } */

        var domainId = parentId

        if (childId) {
          domainId = childId
        }

        // Search for the existance of the current filter string in the array and add or remove the value from it.
        var domainSelectionIndex = this.domainSelection.indexOf(domainId)
        if (domainSelectionIndex > -1) {
          this.domainSelection.splice(domainSelectionIndex, 1)
          // In case of a title is clicked and since it doesn't pass a value of the child, remove all its children
          if (!childId) {
            this.domainsOptions.forEach(f => {
              if (f.id == parentId) {
                f.children.forEach(child => {
                  // Checks if the index child in the array, and removes it from the array based on it
                  var childIndex = this.domainSelection.indexOf(child.id)
                  if (childIndex > -1) {
                    this.domainSelection.splice(childIndex, 1)
                  }
                })
              }
            })
          }
        } else {
          this.domainSelection.push(domainId)
          // In case of a title is clicked and since it doesn't pass a value of the child, add all its children
          if (!childId) {
            this.domainsOptions.forEach(f => {
              if (f.id == parentId) {
                f.children.forEach(child => {
                  // Checks if the child is already inserted in the array, if not its inserted
                  var childIndex = this.domainSelection.indexOf(child.id)
                  if (childIndex < 0) {
                    this.domainSelection.push(child.id)
                  }
                })
              }
            })
          }
        }

        var domainArray = this.domainSelection
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.SET_BY_FACET,
          content: { facet: 'domains', values: domainArray },
        })
      },
      setByFacet (facet, values) {
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.SET_BY_FACET,
          content: { facet, values },
        })
      },
      handleFundingDateRangeUpdate (range) {
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.UPDATE_FUNDING_DATE_RANGE,
          content: range,
        })
      },
      handleCreatedAtStartRangeUpdate (value) {
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.UPDATE_CREATED_AT_START_DATE,
          content: {
            start: value,
          },
        })
      },
      handleCreatedAtEndRangeUpdate (value) {
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.UPDATE_CREATED_AT_END_DATE,
          content: {
            end: value,
          },
        })
      },
      handleUpdatedAtStartRangeUpdate (value) {
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.UPDATE_UPDATED_AT_START_DATE,
          content: {
            start: value,
          },
        })
      },
      handleUpdatedAtEndRangeUpdate (value) {
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.UPDATE_UPDATED_AT_END_DATE,
          content: {
            end: value,
          },
        })
      },
      handleClaimedAtStartRangeUpdate (value) {
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.UPDATE_CLAIMED_AT_START_DATE,
          content: {
            start: value,
          },
        })
      },
      handleClaimedAtEndRangeUpdate (value) {
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.UPDATE_CLAIMED_AT_END_DATE,
          content: {
            end: value,
          },
        })
      },
      handleFoundingDateRangeUpdate (range) {
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.UPDATE_FOUNDED_DATE_RANGE,
          content: range,
        })
      },
      handleEmployeeRangeUpdate (range) {
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.UPDATE_EMPLOYEE_RANGE,
          content: range,
        })
      },
      handleRevenueRangeUpdate (range) {
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.UPDATE_REVENUE_RANGE,
          content: range,
        })
      },
      handlePatentCountUpdate (range) {
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.UPDATE_PATENT_COUNT_RANGE,
          content: range,
        })
      },
      handleRelationshipCountUpdate (range) {
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.UPDATE_RELATIONSHIP_COUNT_RANGE,
          content: range,
        })
      },
      handleRemoveKeyword (keyword) {
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.REMOVE_KEYWORD,
          content: keyword,
        })
      },
      handleTotalFundingRangeUpdate (range) {
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.UPDATE_TOTAL_FUNDING_RANGE,
          content: range,
        })
      },
      transformFilterToFacet (item) {
        let facetName = item.toLowerCase()

        if (facetName === 'sustainability goals') {
          facetName = 'sustainability_goal'
        }
        if (facetName === 'growth stages') {
          facetName = 'stage'
        }

        return facetName
      },
      handleToggleEmptyFiltersSelection (item) {
        var index = this.emptyFiltersSelection.indexOf(item)

        if (index > -1) {
          this.emptyFiltersSelection.splice(index, 1)
        } else {
          this.emptyFiltersSelection.push(item)
        }

        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.TOGGLE_LEGEND_ITEM,
          content: { facet: this.transformFilterToFacet(item), value: '_empty' },
        })
      },
      handleUpdatedReportTemplateFilterState (context) {
        this.$emit('update-state', context)
      },
      clearAllFilters () {
        this.industrySelection = []
        this.aspectSelection = []
        this.technologySelection = []
        this.currentEmployeesSizeCode = []
        this.funnelStageSelection = []
        this.relevantSelection = []
        this.claimedSelection = []
        this.allowEmptySelection = []
        this.domainSelection = []
        this.createdAtStart = null
        this.createdAtEnd = null
        this.claimedAtStart = null
        this.claimedAtEnd = null
        this.updatedAtStart = null
        this.updatedAtEnd = null
        this.defaultSelection.legends = true
        this.defaultSelection.membership = true
        this.defaultSelection.stages = true
        this.defaultSelection.created_at_start = true
        this.defaultSelection.created_at_end = true
        this.defaultSelection.updated_at_start = true
        this.defaultSelection.updated_at_end = true

        this.$emit('update-state', { name: FILTERS_MUTATION_TYPES.CLEAR })
      },
      getNormalisedValue (item) {
        let lowerCaseItem = item.toLowerCase()

        // We don't work with any normalised taxonomy values unfortunately in this component, so convert the display name back to the taxomy name
        if (lowerCaseItem === 'growth stages') {
          lowerCaseItem = 'stage'
        } else if (lowerCaseItem === 'process steps') {
          lowerCaseItem = 'process_steps'
        } else if (lowerCaseItem === 'readiness levels') {
          lowerCaseItem = 'readiness_levels'
        } else if (lowerCaseItem === 'funnel stages' || lowerCaseItem === 'segmentation') {
          lowerCaseItem = 'funnel_stage'
        } else if (lowerCaseItem === 'product features a') {
          lowerCaseItem = 'product_features_a'
        } else if (lowerCaseItem === 'product features b') {
          lowerCaseItem = 'product_features_b'
        } else if (lowerCaseItem === 'product features c') {
          lowerCaseItem = 'product_features_c'
        }

        return lowerCaseItem
      },
      humanizeFilterItem (item) {
        if (item.includes('report_template_')) {
          const reportTemplate = getReportTemplateByReportIdentifier(item)

          return reportTemplate.name || 'Reports'
        }

        const normalisedValue = this.getNormalisedValue(item)
        // Taxonomy aliases
        if (isTaxonomy(normalisedValue)) {
          var alias = this.getTaxonomyAlias(normalisedValue)

          if (alias && alias !== normalisedValue) {
            return alias
          }
        }

        return item
      },
    },
    created () {
      window.addEventListener('keyup', this.keyUpHandler)

      // Set the active filter
      var firstVisibleFilter = this.filtersToDisplay[0]

      if (!firstVisibleFilter) {
        return
      }

      firstVisibleFilter = firstVisibleFilter.charAt(0).toUpperCase() + firstVisibleFilter.slice(1)
      this.changeFilter(firstVisibleFilter)
    },
    mounted () {
      document.body.className = document.body.className + ' modal--open'
    },
    unmounted () {
      document.body.className = (document.body.className || '').replace('modal--open', '')
      window.removeEventListener('keyup', this.keyUpHandler)
    },
    mixins: [FiltersMixin],
    components: {
      NaceCodePicker,
      EmptyFieldFilterColumn,
      Slider,
      SliderFoundingDate,
      SliderFundingDate,
      SliderTotalFunding,
      SliderRevenue,
      SliderEmployees,
      CheckboxButton,
      Checkbox,
      Datepicker,
      ReportTemplateFilter,
    },
  }
</script>

<style lang="scss">
  @import "../../../../scss/variables";

  .modal--scrollable {
    display: block !important;
    padding: 100px 0;
    overflow: auto;
    bottom: 0;

    > .modal__wrapper {
      display: block;
      margin: 0 auto;
    }
  }

  .modal--open .main-view,
  .modal--open .main-view .actor-detail {
    overflow: hidden
  }

  .modal__body {
    .keyword {
      background-color: $color-background-light-grey;

      .keyword__label {
        color: $color-background-grey;
      }
    }

    .keyword::before {
      display: none;
    }

    .keyword:first-child {
      margin-top: 10px;
    }
  }

  .modal__navigation, .modal__body__all-filters {
    display: inline-block;
  }

  .modal__container__all-filters {
    padding: 0px !important;
    height: auto;
    display: flex;
    max-width: 100% !important;
  }

  .modal__wrapper-large {
    max-height: 650px !important;
    min-height: 429px;
    max-width: 700px !important;
  }

  .modal__navigation.scrollable {
    width: 20%;
    min-width: 150px;
    min-height: 429px;
    max-height: 650px !important;
    background-color: $color-background-light-grey;

    .modal__navigation__feedback {
      padding: 20px;

      span {
        color: var(--primary-community);
        text-decoration: underline;
        cursor: pointer
      }
    }

    .modal__navigation__button-container {
      position: relative;
      width: 100%;

      .button {
        width: 100%;
        text-align: right;
        line-height: 25px;
        font-style: italic;
        font-family: $font-stack-primary;
        color: $color-background-grey;
      }

      .button:hover {
        background-color: white;
      }

      &.show-change-badges {
        padding: 5px 5px 0 5px;
      }
    }
  }

  .modal__navigation__button-active {
    background-color: white !important;
  }

  .modal__body__all-filters {
    min-width: calc(100% - 150px);
    height: 100%;
    vertical-align: top;
    padding: 1em;
    margin: 0 !important;
    position: relative;
  }

  .modal__body__filter-column {
    &.two-columns {
      display: flex;
      flex-wrap: wrap;

      > li {
        flex-basis: 50%;
      }
    }

    .modal__body__group-title-clip-text {
      .button__label {
        width: 85%;
      }
    }

    .modal__body__group-toggle-button {
      position: absolute;
      top: 0;
      right: 5px;
      font-size: 34px;
      line-height: 25px;
      cursor: pointer;
      color: $color-text-grey-light;
    }

    li {
      list-style: none;
      position: relative;
      margin-bottom: 5px;

      .button:hover {
        background-color: $color-borders !important;
      }

      .button {
        line-height: 15px;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        -ms-text-overflow: ellipsis;
        text-align: left;

        .button__label {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          -o-text-overflow: ellipsis;
          -ms-text-overflow: ellipsis;
          text-transform: capitalize;
          font-size: 11px;
          font-family: $font-stack-primary;
          letter-spacing: 0.41px;
        }
      }
    }

    .modal__body__group-title {
      background-color: $color-background-light-grey;

      .button__label {
        color: $color-text-grey;
        text-transform: uppercase !important;
        font-family: $font-stack-secondary !important;
        font-weight: 500;
      }
    }

    .modal__body__group--items-child {
      .button__label {
        padding-left: .7em;
      }
    }

    .modal__body__group--items {
      border-top: 1px solid $color-background-light-grey;

      .button__label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        -ms-text-overflow: ellipsis;
        text-transform: capitalize;
        font-size: 11px;
        color: $color-text-grey-light;
      }
    }

    .modal__body__list--items {
      color: $color-text-grey-light;

      .button__label {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        -ms-text-overflow: ellipsis;
        text-transform: capitalize;
        font-size: 11px;
      }
    }
  }

  .modal__body__filter-SDG {
    width: 100%;
    display: flex;
    list-style-type: none;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;

    li {
      height: 0;
      width: 100px;
    }

    li.sdg-filter {
      cursor: pointer;
      height: 100px;
      margin-bottom: 6px;
      border: 4px solid transparent;

      &:hover {
        border: 4px solid $color-borders;
      }

      &.modal__body__active-filter-img {
        border: 4px solid #00C3AF;
      }
    }

    img {
      width: 100%;
    }
  }

  .modal__body__scroll {
    overflow-y: scroll;
    max-height: 529px;
  }

  .modal__body__filter-containers {
    min-height: 250px;
  }

  .modal__body__active-filter-text .button__label {
    color: var(--primary-community);
    font-weight: bold;
  }

  .modal__body__filter-column .modal__body__group--items.modal__body__active-filter-text .button__label {
    color: var(--primary-community);
  }

  .modal__delete-all {
    text-decoration: none;
    position: absolute;
    top: 37px;
    right: 0px;
    font-family: $font-stack-primary;
    color: $color-background-grey;
    font-size: 11px;
    cursor: pointer;
  }
</style>
