<template>
  <div class="container--center">
    <card>
      <div class="card__title">{{ actor.name }}</div>
      <div class="card__description">
        <p v-if="actor.deleted_by">
          This actor profile has been deleted <span class="nowrap">{{ actor.deleted_by && actor.deleted_by.name ? 'by ' + actor.deleted_by.name : '' }}</span> at <span class="nowrap">{{ actor.deleted_at }}</span>.
        </p>
        <p v-else>
          This actor profile has been deleted
        </p>
        <p>
          <ds-button label="&larr; Go back" @click="$router.go(-1)" variant="secondary" />
          <ds-button v-if="actor.id" label="Next Actor &rarr;" @click="goToNext(actor.id)" variant="secondary" />
        </p>
      </div>
    </card>
  </div>
</template>


<script>
  import Card from '../Card/Card.vue'

  export default {
    props: ['actor'],
    data () {
      return {}
    },
    methods: {
      goToNext (currentActorId) {
        const { data } = this.$store.state.actors.listData
        const next = 1 + data.findIndex(a => a.id === currentActorId)

        if (data[next]) {
          // The next actor is cached
          return this.$router.push('/actors/' + data[next].id)
        }

        if (this.$store.state.filters.paging.offset < this.$store.getters.lastPageOffset) {
          // Fetch next page
          this.$store.commit('FILTERS/NEXT_PAGE')
        }

        return this.$store.dispatch('ACTORS/FETCH_ACTORS_LIST_NOW', this.$store.getters.listFilterObject)
          .then(() => {
            const { data } = this.$store.state.actors.listData
            // Load first actor on the page
            if (data && data.length) {
              this.$router.push('/actors/' + data[0].id)
            }
          })
      }
    },
    components: {
      Card
    }
  }
</script>

<style lang="scss">
  .container--center {
    padding: 2rem;
    > .card {
      max-width: 550px;
    }
  }

  .h1-center {
    font-weight: normal;
    font-size: 24px;
    line-height: 20px;
    font-family: 'Sofia Pro', 'Roboto', sans-serif;
    text-transform: uppercase;
    font-weight: 300;
    letter-spacing: 2px;
  }

  .svg-medium {
    width: 20px;
    height: 20px;
    vertical-align: top;
  }

  .deleted-message {
    margin: 0;
    border: 1px solid #ccc;
    padding: 2rem 2rem;
    font-size: 2vw;
    font-weight: 300;
    min-width: 450px;
  }
  .retry {
    margin: 0;
    float:left;
  }
  .nowrap {
    white-space: nowrap;
  }
</style>
