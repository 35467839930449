<template>
  <div class="ecosystem-introduction">
    <div class="ecosystem-introduction__header">
      <div class="ecosystem-introduction__title">{{title}}</div>
      <div class="ecosystem-introduction__more-info" @click="toggleExpandedState">
        <div>
          More information
        </div>
        <button class="ecosystem-introduction__toggle-expanded-state-button">
          <Icon v-if="isExpanded" name="chevron-up" />
          <Icon v-if="!isExpanded" name="chevron-down" />
        </button>
      </div>
    </div>

    <div class="ecosystem-introduction__content" v-if="isExpanded">
      <template v-if="!ui.isMobile">
        <div v-if="isVideoIframe" class="ecosystem-introduction__video" v-html="video"></div>
        <div v-else-if="isVideoLocal" class="ecosystem-introduction__video">
          <video id="my-video" class="video-js" controls autoplay="true" muted preload="auto" width="100%" height="100%"
                 poster="" data-setup="{}">
            <source :src="video" type='video/mp4'>
            <p class="vjs-no-js">
              To view this video please enable JavaScript, and consider upgrading to a web browser that
              <a href="https://videojs.com/html5-video-support/" target="_blank">supports HTML5 video</a>
            </p>
          </video>
        </div>
        <div v-else-if="video" class="ecosystem-introduction__video">
          <iframe width="640px" height="400px" :src="video" frameBorder="0">
            <p>Your browser does not support iframes.</p>
          </iframe>
        </div>
        <div v-else-if="image" class="ecosystem-introduction__video">
          <img max-width="640px" :src="image" />
        </div>
      </template>
      <div class="ecosystem-introduction__info" style="max-width: 60%;">
        <div v-html="tagline"></div>
        <div class="ecosystem-introduction__actions" v-if="!isLoggedIn">
          <!--<ds-button to="/register" label="Sign up" variant="secondary" />-->
          <ds-button @click="goToSignUp" label="Sign up" variant="secondary" />
          <ds-button @click="goToLogin" label="or log in" variant="link" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import UiMixin from '../../util/UiMixin.js'

  import { trackHeapEvent } from '../../util/analytics'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'EcosystemIntroduction',
    mixins: [UiMixin],
    data () {
      return {
        isExpanded: !localStorage.getItem('datascouts.hasSeenEcosystemIntroduction'),
      }
    },
    computed: {
      config () {
        return this.$store.state.config
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      title () {
        return this.isLoggedIn ? this.config.introTitleLoggedIn : this.config.introTitleLoggedOut
      },
      video () {
        return this.config.embedded_video_url
      },
      image () {
        return this.config.homepage_knowledge_base_image
      },
      tagline () {
        return this.isLoggedIn ? this.config.tagline : this.config.taglineLoggedOut
      },
      currentEcosystemName () {
        return this.$store.getters.currentEcosystem.displayName || this.$store.getters.currentEcosystem.name
      },
      isVideoIframe () {
        return this.video && this.video.indexOf('<iframe') > -1
      },
      isVideoLocal () {
        return this.video && this.video.startsWith('/')
      },
    },
    mounted () {
      localStorage.setItem('datascouts.hasSeenEcosystemIntroduction', 'true')
  },
    methods: {
      goToSignUp () {
        trackHeapEvent('homepage.clickSignUpCta')

        this.$router.push('/register')
      },
      goToLogin () {
        trackHeapEvent('homepage.clickLoginCta')

        this.$router.push('/login')
      },
      toggleExpandedState () {
        this.isExpanded = !this.isExpanded
      },
    }
  })
</script>




<style lang="scss">
  @import "../../../scss/variables";

  .ecosystem-introduction {
    padding: 1rem;
    border: 1px solid $color-body-borders;

    .button--link {
      .button__label {
        text-decoration: underline;
      }
    }
  }

  .ecosystem-introduction__header {
    display: flex;
    align-items: center;

    @media (max-width: $screen-md) {
      display: block;
      text-align: center;

      .ecosystem-introduction__toggle-expanded-state-button {
        display: inline-block;
      }

      .ecosystem-introduction__content {
        display: block;
      }
    }
  }

  .ecosystem-introduction__title {
    flex: 1;
    font-size: 1.125rem;
    letter-spacing: 0.4px;
    color: $color-text-grey;
  }

  .ecosystem-introduction__more-info {
    display: flex;
    align-items: center;
    text-decoration: underline;

    &:hover {
      cursor: pointer;

      .ecosystem-introduction__toggle-expanded-state-button {
        background-color: darken(#D3D2D3, 10%);
      }
    }
  }

  .ecosystem-introduction__toggle-expanded-state-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.5rem;
    border-radius: 50%;
    background-color: #D3D2D3;
    border: 0;
    transition: all .2s;
  }

  .ecosystem-introduction__content {
    display: flex;
    padding-top: 1rem;

    ul {
      margin-left: 15px;
    }
  }

  .ecosystem-introduction__info {
    /*flex: 1;*/
    max-width: 600px;
  }

  .ecosystem-introduction__video {
    margin-right: 2rem;
  }

  .ecosystem-introduction__info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    p {
      font-size: 14px;
    }

    li {
      margin-bottom: 0.2rem;
    }
  }

  .ecosystem-introduction__actions {
    margin-top: 1rem;
  }
</style>
