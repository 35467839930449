import { TEMPLATES as HOMEPAGE_TEMPLATES } from '../constants/homepage'
import { fetchConfig } from '../api/config'
import { inert } from './helpers.js'
import objectPath from 'object-path'

export function defaultConfig () {
  return {
    'allow_anonymous': null,
    'allowAdd': null,
    'allowRegister': null,
    'competitiveFilters': null,
    'copyright': '',
    'coverSrc': '/images/covers/default.jpg',
    'country_filter': false,
    'country_filter_codes': null,
    'dashboardType': null,
    'displayName': null,
    'enableFreeTextTags': true,
    'email': 'support@datascouts.eu',
    'favicon': '',
    'filterControls': null,
    'filterControlsProducts': null,
    'simplifiedFilterControls': null,
    'simplifiedFilterControlsProducts': null,
    'google_analytics': null,
    'google_analytics_2': null,
    'google_tag_manager': null,
    'google_tag_manager_2': null,
    'legendHidden': false,
    'homepage': '',
    'legendeItems': ['Startup', 'Enabler', 'Investment Firm / Fund'],
    'legendeProperty': null,
    'legendeStrict': true,
    'logoSrc': '/images/logos/default.png',
    'logoClass': null,
    'portfolio_actor': null,
    'public_fields': [],
    'publisher': null,
    'navigationItems': null,
    'title': 'dev',
    'type': null,
    'url': '',
    'viewActorTypes': null,
    'viewAdmin': null,
    'viewAnalytics': false,
    'viewDashboard': null,
    'viewGallery': false,
    'viewList': ['city', 'industries', 'stage', 'total_funding'],
    'viewMap': false,
    'viewMapOptions': { 'zoom': 8, 'center': [50.85, 4.35] },
    'zip_filter': false,
    'membership_filter': false,
    homepageTemplate: HOMEPAGE_TEMPLATES.ACTOR_FOCUSED.sections.map(section => ({
      ...section,
      isVisible: true,
    })),
  }
}

export function emptyConfig (role = '') {
  const config = defaultConfig()
  for (var key in config) {
    config[key] = null
  }

  if (role && ['visitor', 'actor', 'portfolio_member', 'member'].includes(role)) {
    delete config['google_analytics']
    delete config['google_analytics_2']
    delete config['google_tag_manager']
    delete config['google_tag_manager_2']
    delete config['logoSrc']
    delete config['logoClass']
    delete config['portfolio_actor']
    delete config['legendHidden']
    delete config['ecosystem']
    delete config['copyright']
    delete config['coverSrc']
    delete config['canPremiumEnrich']
    delete config['email']
    delete config['favicon']
    delete config['allowEdit']
    delete config['navigationItems']
    delete config['url']
    delete config['type']
    delete config['title']
    delete config['displayName']
  }

  if (role && ['actor', 'portfolio_member'].includes(role)) {
    delete config['allow_anonymous']
    delete config['allowAdd']
    delete config['allowRegister']
  }

  return config
}

export function addDefaultsTo (config) {
  return Object.assign(defaultConfig(), config)
}

/**
 * Removes null and default values from an object, e.g.:
 * config   = { view: 'map',  some: 'thing', an: null }
 * defaults = { view: 'list', some: 'thing', an: 'other' }
 *
 * removeDefaultsFrom(config, defaults)
 *          = { view: 'map' }
 */
export function removeDefaultsFrom (config, defaults) {
  for (const key in defaults) {
    if (config[key] == null || config[key] === defaults[key]) {
      delete config[key]
    }
  }
  if (!config.primaryColor) {
    delete config.primaryColor
  }
  return config
}

export function fetchAndPrepareConfigForEditing () {
  return fetchConfig({ url: window.config.url }).then(prepareConfigForEditing)
}

export function prepareConfigForEditing (config) {
  var inertConfig = inert(config)
  if (!inertConfig.viewActorTypes) {
    inertConfig.viewActorTypes = ['LegalEntity']
  }

  if (inertConfig.publisher && !inertConfig.publisher.viewActorTypes) {
    inertConfig.publisher.viewActorTypes = ['LegalEntity']
  }

  if (inertConfig.challengesAreEnabled && !inertConfig.viewDashboard.includes('challenges')) {
    inertConfig.viewDashboard.push('challenges')
  }

  // Append the message_baord as a selected option when:
  // - the permission messageBoardsAreEnabled does not exist
  // - the permission messageBoardsAreEnabled is enabled, but the dashboard option is not set
  if (inertConfig.forIntelligenceAndOperations && (!objectPath.has(inertConfig, 'messageBoardsAreEnabled') || inertConfig.messageBoardsAreEnabled)) {
    inertConfig.viewDashboard.push('message_dashboard')
  }

  return addDefaultsTo(inertConfig)
}

export const keysInTable = ['type', 'url']
