<template>
  <div class="legend_dropdown" @mouseover="isVisible = true"
       @mouseout="isVisible = false">
    <NewCard title="Legend" collapsable class="legend_dropdown__legend-container"
             :isCollapsedOnInit="isCollapsed" @isCollapsed="changeCollapseState">
      <template v-slot:headerLeft>
        <div style="position: absolute;top: 2.2rem;left: -1px;">
          <icon name="move" class="legend_dropdown__move-button" size="extra-small"/>
        </div>
      </template>
      <template v-slot:headerRight v-if="isOwner && !isCollapsed">
        <div>
          <ds-button icon="edit" class="legend_dropdown__edit-button"
                     :class="{'legend_dropdown__edit-button-active': isEditMode}"
                     size="extra-small" @click="changeEditState(isEditMode)"/>
        </div>
      </template>
      <div class="color-picker__main-body"
           style="width: 100%; height: 100%; background-color:white; overflow: hidden">
        <div v-if="isOwner" class="legend_dropdown__legend-options-container">
          <filters-dropdown
            :options="possibleLegendValues"
            :label="legendPropertyDisplayName"
            :selection="legendProperty"
            variant="secondary"
            @update:modelValue="handleLegendProperty"
            :use-taxonomy-alias="true"
            :isRadioBox="true"
          />
        </div>
        <div class="scrollable">
          <div class="legend_dropdown__color-list-container">
            <div class="row">
              <div class="col-xs-12" v-for="legend in legendItemsValues"
                   style="display: flex; margin: 5px 0">
                <div class="col-xs-2" style="display: flex; align-items: center;">
                  <div class="legend_dropdown__color-item-container">
                    <div v-if="isEditMode"
                         class="legend_dropdown__color-items legend_dropdown__color-items-edit-mode"
                         :style="{ 'background-color': getLegendColour(legend.colour), 'border-radius': '25px'}"
                         @click="openColorPickerModal(legend)">
                      <icon name="edit" size="14"></icon>
                    </div>
                    <div v-else class="legend_dropdown__color-items"
                         :style="{ 'background-color': getLegendColour(legend.colour), 'border-radius': '25px'}"></div>
                  </div>
                </div>
                <div class="col-xs-10 legend_dropdown__legend-text "
                     style="display: flex; align-items: center">
                  {{ legend.name }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </NewCard>
  </div>
</template>

<script>
  import NewCard from '../../components/NewCard/NewCard.vue'
  import FiltersDropdown from '../Dropdown/FiltersDropdown.vue'
  import IconHoverTag from '../Icon/IconHoverTag.vue'

  import FiltersMixin from '../../util/FiltersMixin.js'
  import { _unique } from '../../util/helpers.js'
  import { trackHeapEvent } from '../../util/analytics.js'

  import MODAL_IDS from '../../constants/modal-ids.js'

  import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'legend-dropdown',
    props: {
      tooltipText: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
        isCollapsed: true,
        isVisible: false,
        isEditMode: false,
        legendItemsValues: [],
      }
    },
    computed: {
      config () {
        return this.$store.state.config
      },
      legendMapping () {
        return this.$store.getters.legendMapping
      },
      hexColours () {
        return this.config.hexColours
      },
      isTeamMember () {
        return this.$store.getters.isTeamMember
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      possibleLegendValues () {
        // Only return the ones that the user should have access to
        var availableLegends = this
          .visibleFilters
          .filter(val => this.allLegendProperties.includes(val))
          .filter(f => this.$store.state.config.filterControls.includes(f))

        availableLegends.push(this.$store.state.config.defaultLegendProperty)
        availableLegends = _unique(availableLegends)

        return availableLegends
      },
    },
    methods: {
      generateLegendsArray () {
        const legendValueMapping = this.legendMapping[this.legendProperty]

        if (!legendValueMapping) {
          return []
        }

        const legendMappingNames = Object.keys(legendValueMapping).sort()

        const legendNames = this.legendItems.filter(item => legendMappingNames.includes(item.value + ''))
        const legendsArray = []

        for (let i = 0; i < legendNames.length; i++) {
          legendsArray.push({
            name: legendNames[i].label,
            value: legendNames[i].value,
            colour: this.legendMapping[this.legendProperty][legendNames[i].value],
          })
        }

        this.legendItemsValues = legendsArray
      },
      trackHeapEvent,
      changeCollapseState (state) {
        this.isCollapsed = state
      },
      changeEditState (state) {
        this.isEditMode = !state
        if (this.isEditMode == true) {
          trackHeapEvent('legend.editModeEnabled')
        }
      },
      openColorPickerModal (taxonomyValue) {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          taxonomyName: this.legendProperty,
          taxonomyValue: taxonomyValue,
        })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.COLOUR_PICKER)
      },
      getLegendColour (hexName) {
        return this.hexColours[hexName]
      },
      handleLegendProperty (value) {
        trackHeapEvent('legend.legendChange', { type: value })
        this.$store.commit(FILTERS_MUTATION_TYPES.SET_LEGEND_PROPERTY, {
          property: value,
          items: this.getLegendItems(value),
        })
        this.generateLegendsArray()
      },
    },
    mounted () {
      this.generateLegendsArray()
    },
    watch: {
      config: {
        deep: true,
        handler () {
          this.generateLegendsArray()
        },
      },
    },
    mixins: [FiltersMixin],
    components: {
      NewCard,
      FiltersDropdown,
      IconHoverTag,
    },
  })
</script>
