<template>
  <div>
    <div @click="goToActionUrl()" class="card notification-detail__card"
         :class="{'notification-detail__unread-notification': isLoggedIn && notification.user_read == false, 'notification-detail_clickable' : notification.action_url}">
      <div class="card__ribbon" :style="notificationColor(notification.type)"></div>
      <div class="notification-detail__heading">
        <h2 class="notification-detail__title" v-if="notification.title">
          <icon v-if="notification.type == 'user'" class="notification-detail-user__big-icon" :class="notificationColorClass(notification.type)"
                :name="notificationIconName(notification.type, notification.event)"></icon>
          <icon v-else class="notification-detail__icon" :class="notificationColorClass(notification.type)" :name="notificationIconName(notification.type, notification.event)"></icon>
          {{ notification.title }}
          <ds-button variant="default button--small notification-detail__edit-icon" class="notification-detail__cta" v-if="canEditAnnouncement(notification)" icon="edit"
                     @click="$parent.edit(notification)"/>
        </h2>
      </div>
      <div class="notification-detail__subtitle">
      <span class="news__info">
        {{ (notification.announcement ? announcementLabel : $t('notification_generic')) }}
        <span v-if="notification.ecosystem">{{
            $t('generic_from')
          }} {{ notification.ecosystem.name === $store.state.config.name ? $store.getters.ecosystemDisplayName : notification.ecosystem.name }}</span>
        &middot;
        <time :title="notification.created_at">{{ toDateStringTranslated(notification.created_at) }}</time>
        <span v-if="$store.getters.isOwner && displayRole(notification)">&middot; {{displayRole(notification)}}</span>
      </span>
      </div>
      <div class="notification-detail__body">
        <template v-if="notification.type != 'announcement' || notification.event != 'public'">
          <p class="pre-line" v-html="notification.body"></p>
        </template>
        <template v-else>
          <div style="margin-left:20px; margin-bottom: 10px;" v-html="notification.body"></div>
        </template>
        <div class="notification-detail-user__contact-info-container" variant="actor-info" v-if="notificationContext && notification.type == 'user'">
          <div class="notification-detail-user__contact-info-sender" v-if="notificationContext.email">{{ $t('notification_sent_by') }} {{ notificationContext.email }}</div>
          <br>
          <div class="notification-detail-user__contact-info">
            <div v-if="notificationContext.phone !== undefined && notificationContext.phone !== null && notificationContext.phone !== ''" class="notification-detail-user__contact-info-items">
              <div class="notification-detail-user__phone">{{$t('generic_phone')}}: {{ notificationContext.phone }}</div>
            </div>
          </div>
        </div>
        <div v-if="notification && notification.event === 'user_inherited_data'">
          <template v-if="notificationContext && notificationContext.challenges && notificationContext.challenges.length > 0">
            <div style="margin-bottom: 0.5rem;">Transferred Challenges</div>
            <ul>
              <li v-for="(challenge, index) in notificationContext.challenges">
                <a :href="'/challenges/' + challenge.id">{{ challenge.title }}</a>
              </li>
            </ul>
          </template>

          <template v-if="notificationContext && notificationContext.announcements && notificationContext.announcements.length > 0">
            <div style="margin-bottom: 0.5rem;">Transferred Announcements</div>
            <ul>
              <li v-for="(announcement, index) in notificationContext.announcements">
                <a :href="'/announcements/' + (announcement.slug || announcement.id)">{{ announcement.title }}</a>
              </li>
            </ul>
          </template>

          <template v-if="notificationContext && notificationContext.files && notificationContext.files.length > 0">
            <div style="margin-bottom: 0.5rem;">Transferred Files</div>
            <ul>
              <!-- to check -->
              <li v-for="(file, index) in notificationContext.files">
                <a :href="'/api/files/' + file.id">{{ file.title }}</a>
              </li>
            </ul>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { timeAgo, toDateStringTranslated } from '../../util/date'

  import { notificationColor, notificationColorClass, notificationIconName } from '../../util/NotificationsMixin'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'

import DsTextarea from '../Form/DsTextarea.vue'
  import Avatar from '../Avatar/Avatar.vue'
  import Card from '../Card/Card.vue'
  import TranslationsMixin from '../../util/TranslationsMixin.js'

  export default {
    name: 'notification-detail',
    props: ['notification'],
    data () {
      return {
        errors: {},
      }
    },
    methods: {
      timeAgo,
      toDateStringTranslated,
      notificationColor,
      notificationColorClass,
      notificationIconName,
      displayRole (item) {
        if (!item.event || item.type != 'announcement') {
          return
        }

        switch (item.event) {
          case 'all':
            return ' Targetted at every user.'
          case 'owner':
            return ' Targetted at owners.'
          case 'member':
            return ' Targetted at every team member.'
          case 'actor':
            return ' Targetted at ecosystem members.'
        }
      },
      canEditAnnouncement (notification) {
        if (!this.isLoggedIn) {
          return false
        }

        if (!notification.type || notification.type != 'announcement') {
          return false
        }

        if (notification.type == 'announcement' && notification.event != 'public') {
          return false
        }

        if (this.isDeveloper) {
          return true
        }

        if (this.$store.getters.ownedTeams.find(t => t.id === notification.ecosystem_id)) {
          return true
        }
      },
      openSidepanel (actorID) {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'scores', metaData: actorID })
      },
      goToActionUrl () {
        var url = this.notification.action_url
        var eventType = this.notification.event

        if (!url) {
          return
        }

        var index = eventType.indexOf('report')
        if (index > -1 && !url.endsWith('#reports')) {
          window.location.href = url + '#reports'
        }
        window.location.href = url
      },
      notificationLinkValidation (url, eventType) {
        // If the notification is of the "report" type, when redirecting to the reports tab of actor profile we add the "#reports" parameter so that
        // the tab component detects that parameter and opens the correct tab, in this case the reports tab.
        // Else its redirected to the normal URL with no parameter.
        var index = eventType.indexOf('report')
        if (index > -1 && !url.endsWith('#reports')) {
          return url + '#reports'
        }
        return url
      },
      showLoginModal () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
      },
    },
    computed: {
      notificationContext () {
        return this.notification.context
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
    },
    mixins: [TranslationsMixin],
    components: {
      DsTextarea,
      Avatar,
      Card,
    },
  }
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

  .notification-detail__submit {
    margin-top: 5px;
    float: right;
  }

  .notification-detail__card {
    position: relative;
    overflow: visible;
    font-size: 14px;
  }

  .notification-detail__unread-notification {
    background-color: $color-background-lightest-grey !important;
  }

  .notification-detail__heading {
    margin-bottom: 0rem;
    font-size: 14px;
    font-weight: 500;
  }

  .notification-detail__title {
    margin-bottom: 0em;
  }

  .notification-detail__icon {
    float: left;
    width: 30px !important;
    margin-top: .15em;
  }

  .notification-detail__edit-icon {
    .svg-icon {
      left: 3px !important;
    }
  }

  .notification-detail-user__contact-info-container {
    display: inline-flex !important;
    width: 100%;
  }

  .notification-detail-user__contact-info-sender {
    display: inline-table;
    width: 50%;
    margin: 7px 0;
  }

  .notification-detail-user__contact-info {
    display: inline-block;
    width: 50%;
    padding-right: 1em;
    font-size: 14px !important;
  }

  .notification-detail-user__contact-info-items {
    width: fit-content;
    float: right;
    display: inline-flex;
    margin: 10px 5px;
  }

  .notification-detail-user__account-icon, .notification-detail__actor-arrow {
    path {
      fill: $color-background-light-grey !important;
    }
  }

  .notification-detail-user__icon {
    float: left;
    width: 10px !important;
    height: 10px !important;
    margin-top: -.2em;
    margin-right: .5em;
  }

  .notification-detail-user__big-icon {
    float: left;
    width: 20px !important;
    height: 20px !important;
    margin-top: .15em;
    margin-right: .3em;
  }

  .notification-detail-user__email, .notification-detail-user__phone {
    font-size: 14px !important;
    color: $color-text-grey-light;
  }

  .notification-detail__subtitle {
    display: inline-block;
    width: 100%;
    padding-left: 30px;
    margin-bottom: 0.4rem;

    ul, li {
      margin-left: 1rem;
    }

    p {
      font-size: 14px;
    }
  }

  .notification-detail__body {
    display: inline-block;
    width: 100%;
    padding-left: 30px;

    ul, li {
      margin-left: 1rem;
    }

    p {
      font-size: 14px;
    }
  }

  .notification-detail-user__unlinked-user {
    margin-top: -10px;
    vertical-align: middle;
    display: inline-block;
  }

  .notification-detail__actor-info {
    width: 100%;
    height: 50px;
    background-color: $color-secondary;
    margin-top: 1em;
    margin-bottom: 1em;
    cursor: pointer;
  }

  .notification-detail__actor-arrow, .notification-detail__actor-info-text {
    float: left;
  }

  .notification-detail__actor-arrow {
    height: 25px !important;
    width: 25px !important;
    margin-top: 11px;
    margin-left: 1em;
    margin-right: .5em;
  }

  .notification-detail__actor-info-text {
    margin-top: 1em;
    color: white;
    font-family: $font-stack-primary;
    line-height: 1.5em;
    letter-spacing: 2px;
    font-size: 14px;
  }

  .notification-detail__actor-info-name {
    font-weight: bold;
  }

  .notification-detail__actor-info-type {
    font-weight: lighter;
    margin-left: 2em;
  }

  .notification-detail__actor-info-category {
    font-weight: lighter;
    margin-left: .5em;
  }

  .notification-detail__divider {
    padding: 0;
    margin: 0;
    height: 1px;
    width: 100%;
    background-color: $color-borders;
    margin: 20px 0;
    display: inline-block;
  }

  .notification-detail__cta {
    float: right;
  }

  .button {
    transition: unset !important;
  }

  .notification-detail_clickable {
    cursor: pointer
  }
</style>
