<template>
  <div class="fixed-heading">
    <div class="heading buzz-page-header">
      <h1 style="display: inline-block">
        <span v-if="chartExpanded == false"> Buzz Monitoring </span>
        <span v-else @click="chartExpanded = false" class="buzz_title-link"> Buzz Monitoring </span>
        <open-tabs :tabs="chartTitles" :saveToUrl="false" :selectedTab="selectedChart" class="open-tabs__container" v-if="chartExpanded == true"></open-tabs>
      </h1>
      <ds-button variant="minimal" size="small" label="Reset"  icon="undo" class="buzz-page-header__reset-button" @click="setParametersToDefault"/>
      <p class="guide_text" v-if="chartExpanded == false">
        Click the graphs below to analyse the main topics and key influencers of the buzz generated in your ecosystem via twitter, reddit and other news channels
      </p>
      <p class="guide_text" v-else>
        <template v-if="selectedChart == 'topics'">
          By clicking on a topic, the graph shows the keywords that are most commonly used together with the selected topic
        </template>
        <template v-if="selectedChart == 'influencers'">
          By clicking on an influencer, the graph shows the influencers creating buzz on the same keywords30 days and other related influencers
        </template>
        <template v-if="selectedChart == 'timeline'">
          By clicking on an influencer, the graph shows the influencers creating buzz on the same keywords
        </template>
        <template v-if="activeActorValue && activeTopicValue">
          <br>
          <strong>View actor profile: <span class="buzz_title-link" :style="{color: ecosystemColor}" @click="openActorSidePanel(activeActorValue)">{{activeActorName}}</span></strong> - <strong>View related posts: <span class="buzz_title-link" :style="{color: ecosystemColor}" @click="openPostsSidePanel()">{{activeTopicName}}</span></strong>
        </template>
        <template v-else-if="activeActorValue">
          <br>
          <strong>View actor profile: <span class="buzz_title-link" :style="{color: ecosystemColor}" @click="openActorSidePanel(activeActorValue)">{{activeActorName}}</span></strong>
        </template>
        <template v-else-if="activeTopicValue">
          <br>
          <strong>View related posts: <span class="buzz_title-link" :style="{color: ecosystemColor}" @click="openPostsSidePanel()">{{activeTopicName}}</span></strong>
        </template>
      </p>
    </div>
    <div class="has-padding scrollable">
      <div class="container-fluid buzz__main-container">
        <div class="row" v-if="chartExpanded == false">
          <div class=" col-xs-12 col-md-6">
            <div class="card card--analytics" ref="influencerChartContainer">
              <div class="buzz__card-title-container">
               <div class="buzz__card-title" v-if="activeActorValue">Key Influencers - <span class="buzz_title-link" :style="{color: ecosystemColor}" @click="openActorSidePanel(activeActorValue)">{{activeActorName}}</span></div>
                <div class="buzz__card-title" v-else>Key Influencers</div>
                <div class="buzz__card-subtitle">By clicking on an influencer, the graph shows the influencers creating buzz on the same keywords 30 days and other related influencers</div>
              </div>
              <ds-button variant="secondary" size="small" label="Create portfolio"  @click="createBuzzPortfolio()" style="right: 3rem;" v-if="this.activeTopicValue && influencerIds.length > 0" />
              <ds-button variant="default" size="small" iconSize="14" icon="arrow-expand"  @click="expandChart('influencers')"/>
              <influencer-chart :chartData="influencersData" @update-data="fetchAllBuzzData"/>
            </div>
          </div>
          <div class="col-xs-12 col-md-6">
            <div class="card card--analytics" ref="bubbleChartContainer">
              <div class="buzz__card-title-container">
                <div class="buzz__card-title" v-if="activeTopicValue">Main Keywords  - (related posts: <span class="buzz_title-link" :style="{color: ecosystemColor}" @click="openPostsSidePanel()">{{activeTopicName}}</span>)</div>
                <div class="buzz__card-title" v-else>Main topics</div>
                <div class="buzz__card-subtitle">By clicking on a topic, the graph shows the keywords that are most commonly used together with the selected topic</div>
              </div>
              <ds-button variant="default" size="small" iconSize="14" icon="arrow-expand" @click="expandChart('topics')"/>
              <bubble-chart :chartData="topicsData" @update-data="fetchAllBuzzData"/>
            </div>
          </div>
          <div class="col-xs-12 col-md-12">
            <div class="card card--analytics" ref="timelineChartContainer">
              <div class="buzz__card-title-container">
                <div class="buzz__card-title" v-if="activeActorValue && activeTopicValue">Timeline - (company profile: <span class="buzz_title-link" :style="{color: ecosystemColor}" @click="openActorSidePanel(activeActorValue)">{{activeActorName}}</span>) - (related posts: <span class="buzz_title-link" :style="{color: ecosystemColor}" @click="openPostsSidePanel()">{{activeTopicName}}</span>)</div>
                <div class="buzz__card-title" v-else-if="activeActorValue">Timeline - (company profile: <span class="buzz_title-link" :style="{color: ecosystemColor}" @click="openActorSidePanel(activeActorValue)">{{activeActorName}}</span>)</div>
                <div class="buzz__card-title" v-else-if="activeTopicValue">Timeline - (related posts: <span class="buzz_title-link" :style="{color: ecosystemColor}" @click="openPostsSidePanel()">{{activeTopicName}}</span>)</div>
                <div class="buzz__card-title" v-else>Timeline</div>
                <div class="buzz__card-subtitle">By clicking on an influencer, the graph shows the influencers creating buzz on the same keywords</div>
              </div>
              <ds-button variant="default" size="small" iconSize="14" icon="arrow-expand" @click="expandChart('timeline')"/>
             <timeline-chart :chartData="timelineData" @update-data="fetchAllBuzzData"/>
            </div>
          </div>
        </div>
        <template v-else>
          <buzz-chart-close-up :chart="selectedChart" :chartData="selectedChartData" @update-data="fetchAllBuzzData"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import BubbleChart from '../Chart/BubbleChart.vue'
import TimelineChart from '../Chart/TimelineChart.vue'
import InfluencerChart from '../Chart/InfluencerChart.vue'
import BuzzChartCloseUp from '../Dashboard/BuzzChartCloseUp.vue'
import OpenTabs from '../Tabs/OpenTabs.vue'
import Badge from '../Badge/Badge.vue'

import { fetchBuzzData, fetchBuzzDefaultTopics } from '../../api/buzz.js'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
import { MUTATION_TYPES as BUZZ_FILTER_MUTATION_TYPES } from '../../store/modules/buzz.js'

import { toDateStringSlashed, toDateStringSlashedUs } from '../../util/date.js';
import { defineComponent } from 'vue'

export default defineComponent({
  props: ['type'],
  data () {
    return {
      error: '',
      selectedChart: 'topics',
      chartTitles: ['topics', 'timeline', 'influencers'],
      chartExpanded: false,
      topicsData: {
        data: []
      },
      influencersData: {
        actors: [],
        connections: []
      },
      timelineData: {
        data: []
      },
    }
  },
  computed: {
    ecosystemColor () {
      return this.$store.state.config.primaryColor
    },
    buzzFilter () {
      return this.$store.state.buzz.filters
    },
    activeTopicName () {
      return this.fetchBuzzFilterName('topic')
    },
    activeTopicValue () {
      return this.fetchBuzzFilterValue('topic')
    },
    activeActorName () {
      return this.fetchBuzzFilterName('actor')
    },
    activeActorValue () {
      return this.fetchBuzzFilterValue('actor')
    },
    activeKeywordTypeName () {
      return this.fetchBuzzFilterName('keyword_type')
    },
    activeKeywordTypeValue () {
      return this.fetchBuzzFilterValue('keyword_type')
    },
    portfolio () {
      return this.$store.state.filters.portfolio
    },
    selectedChartData () {
      if (this.selectedChart == 'topics') {
        return this.topicsData
      } else if (this.selectedChart == 'influencers') {
        return this.influencersData
      } else if (this.selectedChart == 'timeline') {
        return this.timelineData
      }

      return []
    },
    influencerIds () {
      if (! this.influencersData || ! this.influencersData.data || ! this.influencersData.data.actors) {
        return [];
      }

      return this.influencersData.data.actors.map(actor => actor.id);
    }
  },
  methods: {
    fetchBuzzFilterValue (facet) {
      var filter = this.$store.state.buzz.filters.find(k => k.facet === facet)

      if (! filter) {
        return filter
      }

      return filter.value
    },
    fetchBuzzFilterName (facet) {
      var filter = this.$store.state.buzz.filters.find(k => k.facet === facet)

      if (! filter) {
        return filter
      }

      return filter.name
    },
    expandChart (chartName) {
      this.chartExpanded = true
      this.selectedChart = chartName
    },
    setParametersToDefault () {
      this.$store.commit(BUZZ_FILTER_MUTATION_TYPES.RESET_BUZZ_FILTERS)
    },
    openActorSidePanel (actorId) {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component:"scores", metaData: actorId})
    },
    openPostsSidePanel () {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component:"posts-panel", metaData: { topic: this.activeTopicValue, actor: this.activeActorValue, keywordType: this.activeKeywordTypeValue }})
    },
    async fetchAllBuzzData() {
      // First fetch the topics and influencers, await the response
      // then make the call for the timeline
      var topicsAndInfluencers = await this.fetchBuzzData('topics-influencers')

      if (topicsAndInfluencers.topics) {
        this.topicsData = topicsAndInfluencers.topics
        this.influencersData = topicsAndInfluencers.influencers
      }

      var timeline = await this.fetchBuzzData('timeline')

      if (timeline.data) {
        this.timelineData = timeline;
      }
    },
    fetchBuzzData (type) {
      var portfolio = {}
      var fullParameters = {}

      if (!this.$route.path.startsWith('/actors/') && this.$store.state.filters.portfolio) {
          portfolio = { portfolio: this.portfolio }
      }

      fullParameters = Object.assign(portfolio, this.$store.getters.buzzQueryParameters)
      fullParameters = Object.assign({ type: type }, fullParameters)

      return fetchBuzzData(fullParameters);
    },
    createBuzzPortfolio () {
      var buzzPortfolioConfig = {
        title: toDateStringSlashedUs(new Date()) + ' - Key Influencers - ' + this.activeTopicName,
        actor_ids: this.influencerIds,
        tags: [this.activeTopicName],
        description: "A portfolio with influencers that talked about " + this.activeTopicName + " the most on " + toDateStringSlashed(new Date()) + '.',
        locked: true,
        filters: []
      };

      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component:"create-portfolio", metaData: buzzPortfolioConfig });
    }
  },
  mounted () {
    this.fetchAllBuzzData();

    this.$bus.on('tab', (tab) => {
      this.selectedChart = tab
    })
    this.$bus.on('chartExpanded', (tab) => {
      this.chartExpanded = tab
    })
  },
  watch: {
    buzzFilter () {
      this.fetchAllBuzzData()
    },
    portfolio () {
      this.fetchAllBuzzData()
    }
  },
  beforeUnmount() {
    this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
  },
  components: {
    Badge,
    BubbleChart,
    TimelineChart,
    InfluencerChart,
    BuzzChartCloseUp,
    OpenTabs,
  }
})
</script>
