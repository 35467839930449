<template>
  <div class="add-actor">
    <icon class="add-actor__close" name="remove" size="large" @click="$router.go(-1)" />

    <div class="add-actor__banner">
      <div>
        <div class="add-actor__banner__visual">
          <icon name="plus" size="large" />
        </div>
        <h2 class="add-actor__banner__title">
          Add actor to
          <br>
          the {{ $store.getters.currentEcosystem.name }} ecosystem
        </h2>
      </div>
    </div>

    <div class="add-actor__content">
      <form class="add-actor__content__body" @submit.prevent="addActor">
          <div class="add-actor__title">
            Add an actor
          </div>
          <p v-if="errors.message && getFirst(errors.message) != 'Cannot read property \'status\' of undefined'" class="form-group__error" v-text="getFirst(errors.message)"></p>

          <form-group label="Type" :errors="errors.actor_type">
            <radio-button
              v-for="(actor_type, index) in $store.getters.viewActorTypes"
              :key="index"
              v-model="actorData.actor_type"
              name="actor_type"
              :label="actorTypeMapping[actor_type] || actor_type"
              :val="actor_type"
              :disabled="$store.getters.isActor && !$store.getters.claimableActorTypes.includes(actor_type)"
            />
          </form-group>

          <form-group v-if="enableSuggestions" label="Search for an organization" :errors="errors.name">
            <suggestion-input
              v-model="actorData.name"
              autofocus="autofocus"
              ref="suggestionInput"
              icon="search"
              :placeholder="suggestionsInputPlaceholder"
              :options="actorSuggestionSettings"
              :render-results="actorSuggestionSettings.renderResults"
              :clear-on-select="false"
              @on-select="handleSelectSuggestion"
              @update:modelValue="resetErrors"
            />
          </form-group>
          <form-group v-else label="Name" :errors="errors.name">
            <ds-input v-model="actorData.name" @update:modelValue="resetErrors" placeholder="Search..." />
          </form-group>

          <div class="row" v-if="$store.getters.isPortfolioMember">
            <form-group label="Select a portfolio" class="col-sm-6" :errors="errors.message">
              <dropdown :options="portfolioOptions" v-model="selectedPortfolio"/>
            </form-group>
          </div>

          <div class="row">
            <form-group class="col-xs-12 col-sm-6" label="Choose a category" :key="actorData.actor_type">
              <dropdown placeholder="Category" force :options="categoryOptions" v-model="actorData.category" />
            </form-group>
            <form-group class="col-xs-12 col-sm-6" label="Enter website url" v-if="actorData.actor_type !== 'Person'" :errors="errors.url">
              <ds-input placeholder="(Optional)" v-model="actorData.url" @update:modelValue="resetErrors"/>
            </form-group>
            <form-group class="col-xs-12 col-sm-6" label="Enter an email address" v-if="actorData.actor_type === 'Person'" :errors="errors.email">
              <ds-input placeholder="(Optional)" v-model="actorData.email" />
            </form-group>
          </div>
          <div class="row" style="margin-left: 1px;" v-if="actorData.actor_type == 'LegalEntity'">
            <form-group label="Company number" :errors="errors.company_number" v-if="$store.state.config.includeCompanyNumberInSearch">
              <div class="company_number">
                <dropdown :options="jurisdictionOptions" v-model="actorData.jurisdiction" width="50px" :search="true" />
                <ds-input placeholder="Company number" name="actor-number" v-model="actorData.company_number" @update:modelValue="resetErrors"/>
              </div>
            </form-group>
          </div>

          <p v-if="!$store.getters.userCanCreate" class="form-group__error">
            You cannot add another actor.
          </p>

          <p v-if="errors.id">
            <ds-button
              :href="'/actors/' + errors.id"
              @click.prevent="$router.push('/actors/' + errors.id)"
              variant="secondary"
              label="Show profile of existing actor"
            />
          </p>

          <ds-button
            type="submit"
            label="Add actor"
            variant="secondary"
            :icon="busy ? 'spinner' : 'plus'"
            :disabled="!isActorValidForCreation"
          />
          <ds-button
            @click="$router.go(-1)"
            :label="addedActors.length ? 'Stop adding' : 'Cancel'"
            variant="outline"
          />
      </form>
      <div class="add-actor__content__footer" v-if="isMember">
        <div class="add-actor__title">
          Added actor
        </div>
        <transition-group name="added-actors" tag="div">
          <a
            class="card add-actor__added"
            v-for="actor in addedActors.slice().reverse()"
            :key="actor.name"
            :href="'/actors/' + actor.id"
            @click.prevent="$router.push('/actors/' + actor.id)"
          >
            <div class="form-group__error pull-right text-right" v-if="actor.sameAs">
              Already an actor
            </div>
            <div class="pull-right text-right" v-else-if="!actor.id">
              Saving...
            </div>
            <div v-else class="card__sub-title pull-right">{{ subtitle(actor) }}</div>
            <h1 class="card__title">{{ actor.name }}</h1>
          </a>
        </transition-group>
        <p v-if="!addedActors.length" class="text-muted">
          Added actors will appear here.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
  import debounce from 'lodash/debounce'

  import Dropdown from '../components/Dropdown/Dropdown.vue'
  import FormGroup from '../components/Form/FormGroup.vue'
  import RadioButton from '../components/Form/RadioButton.vue'
  import DsTextarea from '../components/Form/DsTextarea.vue'
  import DsInput from '../components/Form/DsInput.vue'
  import SuggestionInput from '../components/SuggestionInput/SuggestionInput.vue'
  import ImageInput from '../components/ImageInput/ImageInput.vue'

  import MODAL_IDS from '../constants/modal-ids'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../store/modules/ui'
  import { MUTATION_TYPES as ACTORS_MUTATION_TYPES } from '../store/modules/actors'

  import { validateActor, createActor } from '../api/actors'
  import { fetchProfile } from '../api/user.js'
  import { Portfolios } from '../api/portfolios'
  import { extractDomain } from '../util/actor'
  import { getFirst } from '../util/getNested'
  import escapeHTML from '../util/escapeHTML'
  import ConfigMixin from '../util/ConfigMixin'

  import { COUNTRIES } from '../constants/countries'
  import { trackHeapEvent } from '../util/analytics'
import TranslationsMixin from '../util/TranslationsMixin.js'

  /* function getFakeDuration (maxDuration) {
    return Math.max(1000, Math.round(Math.random() * maxDuration))
  } */

  function emptyActor ({ actor_type, category }) {
    return {
      id: null,
      actor_type: actor_type || 'LegalEntity',
      company_type: 'Headquarter',
      name: null,
      url: null,
      category: category,
      logo: null,
      company_number: null,
      jurisdiction: null,
    }
  }

  export default {
    data () {
      return {
        actorData: emptyActor({
          category: null
        }),
        busy: false,
        errors: {},
        addedActors: [],
        portfolios: [],
        selectedPortfolio: null,
        ACTOR_TYPES: window.ACTOR_TYPES.map(t => ({ value: t, label: t === 'LegalEntity' ? 'Organization' : t })),
        // industries: window.INDUSTRIES.map(industry => ({ value: industry.name, label: industry.name })),
        actorSuggestionSettings: {
          apiSettings: {
            url: '/actors/autocomplete?query={query}',
            onResponse: data => ({
              results: Object.values(data).map(({ name, logo, domain, jurisdiction, company_number, founding_date }) => ({
                name,
                logo,
                url: domain,
                jurisdiction,
                company_number,
                founding_date
              }))
            })
          },
          showNoResults: false,
          renderResults (response) {
            return response.results.map(r => {
              if (r.logo) {
                return `
                  <a class="result company-suggestion">
                    <img class="company-suggestion__avatar" src="${r.logo}" />
                    <div class="company-suggestion__info">
                      <div class="company-suggestion__title">${escapeHTML(r.name)}</div>
                      <div class="company-suggestion__domain">${escapeHTML(r.url)}</div>
                    </div>
                  </a>
                `
              }

              if (r.company_number) {
                return `
                  <a class="result company-suggestion">
                    <div class="company-suggestion__info">
                      <div class="company-suggestion__title">${escapeHTML(r.name)}</div>
                      <div class="company-suggestion__domain">${escapeHTML(r.jurisdiction)}${escapeHTML(r.company_number)}</div>
                    </div>
                  </a>
                `
              }

              return `
                  <a class="result company-suggestion">
                    <div class="company-suggestion__info">
                      <div class="company-suggestion__title">${escapeHTML(r.name)}</div>
                    </div>
                  </a>
                `
            })
          }
        }
      }
    },
    computed: {
      industries () {
        return this.$store.state.taxonomies.industries.map(industry => ({ value: industry.name, label: industry.name }))
      },
      jurisdictionOptions () {
        // Filter out the UK, it's not considered a legal jurisdiction
        return Object.keys(COUNTRIES).filter(countryCode => countryCode !== 'UK')
      },
      isMember () {
        return this.$store.getters.isMember
      },
      isActorValidForCreation () {
        return this.$store.getters.userCanCreate &&
          this.actorData.name &&
          this.actorData.category &&
          Object.keys(this.errors).length === 0
      },
      enableSuggestions () {
        return this.$store.getters.canUseActorAutocomplete && this.actorData.actor_type === 'LegalEntity'
      },
      actorTypeMapping () {
        return {
          LegalEntity: 'Organization',
          Person: 'Person (expert, entrepreneur, ...)',
          Community: 'Collaboration (project, community, ...)',
          Product: this.productLabel
        }
      },
      categoryOptions () {
        const list = this.categoryLabels[this.actorData.actor_type] || []

        var categories = list.slice(0)

        categories = categories.sort((a, b) => {
          return a.label > b.label
        })

        return categories.map(item => ({
          value: item,
          label: item,
        }))
      },
      userPortfolioContributorIds () {
        return this.$store.getters.userContributionPortfolios
      },
      portfolioOptions () {
        var portfolios = this.portfolios || []

        return this.portfolios.map(item => ({
          value: item.id,
          label: item.name,
        }))
      },
      isVatSuggestionEnabled () {
        return this.$store.state.config.kboSuggestionsEnabled
      },
      suggestionsInputPlaceholder () {
        if (this.isVatSuggestionEnabled == true) {
          return 'Search by name or VAT number'
        } else {
          return 'Search...'
        }
      }
    },
    methods: {
      getFirst,
      resetErrors () {
        this.errors = {}
        this.validateActor()
      },
      validateActor () {
        if (this.actorData.name && this.actorData.name.length < 2) {
          // This error occurs way to many times as a user is typing, play it smart and handle this front-end
          this.errors = { 'name': ['The name must be at least 2 characters.'] }
        } else {
          validateActor(Object.assign({}, this.actorData, { logo: null }))
          .then(response => {
            this.errors = {}
          })
          .catch(this.handleErrors)
        }
      },
      addActor () {
        this.busy = true
        var parameters = []

        // These are the default settings when creating a new actor.
        // New default settings can be added, removed or modified when necessary.
        var defaultSettings = {
          logo: null,
          is_email_published: true
        }

        // If The user is a portfolio member and is a contributor of at least one portfolio
        if (this.$store.getters.isPortfolioMember && this.userPortfolioContributorIds.length > 0) {
          // If there is a portfolio selected, send the portfolio id in the request
          if (this.selectedPortfolio) {
            parameters = Object.assign({}, this.actorData, { portfolio_id: this.selectedPortfolio }, defaultSettings)
          } else {
            // Else send the id of the first portfolio if there are portfolios
            if (this.portfolios.length > 0) {
              parameters = Object.assign({}, this.actorData, { portfolio_id: this.portfolios[0].id }, defaultSettings)
            }
          }
        } else {
          // Else don't send the portfolios id
          parameters = Object.assign({}, this.actorData, defaultSettings)
        }

        createActor(parameters)
          .catch(error => {
            if (error && error.id) {
              if (!this.isMember) {
                // Go to profile page
                this.$router.push('/actors/' + error.id)
                throw new Error()
              }
            }
            console.error(error)
            throw error
          })
          .then(actor => {
            if (!actor || !actor.id) {
              throw new Error('finishCompletion expected an actor as return value')
            }

            trackHeapEvent('global.addActor', { name: actor.name, id: actor.id })

            if (!this.isMember) {
              // Update user profile
              this.$store.commit('USER/HAS_CLAIMED', actor)
              fetchProfile()
                .then(profile => {
                  this.$store.commit('USER/UPDATE_PROFILE', profile)

                  // Go to profile page
                  this.$router.push('/actors/' + actor.id)
                })
            } else {
              fetchProfile()
                .then(profile => {
                  this.$store.commit('USER/UPDATE_PROFILE', profile)
                  this.$bus.emit('updateUserProfile')
                })
              // Prepare to add next
              this.addedActors.push(actor)
              this.actorData = emptyActor(this.actorData)
              this.$refs.suggestionInput && this.$refs.suggestionInput.clear()
            }
            this.errors = {}
          })
          .catch(this.handleErrors)
          .then(() => { this.busy = false })
      },
      handleErrors (errors) {
        this.errors = errors || {}
      },
      handleSelectSuggestion (actor) {
        this.resetErrors()
        // Apply in next tick to fix v-model issue
        this.$nextTick(() => {
          Object.assign(this.actorData, actor, { id: null })
          this.validateActor()
        })
      },
      subtitle ({ url, category, industries, type }) {
        return [
          type,
          category,
          industries && industries.join && industries.join(', '),
          extractDomain(url || '')
        ].filter(Boolean).join(' - ')
      },
      showClaimModal (actor) {
        this.$store.commit(ACTORS_MUTATION_TYPES.SET_ACTOR_FOR_PREVIEW, actor)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CLAIM)
      },
      fetchPortfolios () {
        Portfolios.get()
          .then(portfolios => {
            portfolios.forEach(item => {
              if (this.userPortfolioContributorIds.length > 0 && this.userPortfolioContributorIds.includes(item.id)) {
                this.portfolios.push(item)
              }
            })

            this.selectedPortfolio = this.portfolios[0].id
          })
          .catch(errors => {
            this.errors = errors
          })
      }
    },
    watch: {
      'actorData.actor_type': function (newVal, oldVal) {
        // TODO: this line isn't necessary anymore
        this.actorData.company_type = 'Headquarter'
      }
    },
    created () {
      this.validateActor = debounce(this.validateActor, 150)
    },
    mounted () {
      if (this.$store.getters.isActor && !this.$store.getters.claimableActorTypes.includes(this.actorData.actor_type)) {
        this.actorData.actor_type = this.$store.getters.claimableActorTypes[0]
      }

      if (this.$store.getters.isPortfolioMember && this.userPortfolioContributorIds.length > 0) {
        this.fetchPortfolios()
      }
    },
    components: {
      Dropdown,
      DsInput,
      FormGroup,
      RadioButton,
      DsTextarea,
      SuggestionInput,
      ImageInput,
    },
    mixins: [ConfigMixin, TranslationsMixin]
  }
</script>
