<template>
  <div class="main-wrapper">
    <ds-button class="main-button" variant="primary" icon="paper-plane" :label="$t('intelligence_simplified_following')"
               size="extra-small" @click="handleClick" :class="{displayed: modelValue}" icon-size="12"
               :icon-fill-color="iconFillColor"/>
    <ds-button class="main-button" variant="outline" icon="paper-plane" :label="$t('intelligence_simplified_follow')"
               size="extra-small" @click="handleClick" :class="{displayed: !modelValue}" icon-size="12"
               :icon-fill-color="iconFillColor"/>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: Boolean,
    iconFillColor: {
      type: String,
      default: 'rgba(48, 50, 64, 0.7)'
    },
  },
  methods: {
    handleClick() {
      this.$emit('update:modelValue', !this.modelValue)
    },
  },
})
</script>

<style lang="scss" scoped>
.main-wrapper {
  display: inline-grid;
  margin-right: 10px;

  .main-button {
    grid-row: 1;
    grid-column: 1;
    margin-right: 0;
    visibility: hidden;
    width: 100%;
    text-align: left;

    &.displayed {
      visibility: visible;
    }

    &.button--outline:not(:hover) {
      background: rgba(255, 255, 255, 0.75);
    }

    &.button--outline:hover {
      background: rgba(0, 0, 0, 0.25);
    }
  }

  &:last-child {
    margin-right: 0;
  }
}
</style>
