<template>
  <!-- Benchmarking -->
  <div class="charts fixed-heading">
    <div class="heading">
      <h1>Benchmark {{ portfolios.length }} portfolios</h1>
    </div>

    <div class="scrollable">
      <div v-if="errors.message">Error! {{ errors.message }}</div>

      <!-- Chart selection -->
      <div class="charts__left">
        <div class="charts__absolute">
          <h3 class="card__title">Add charts</h3>

          <label class="radio">
            <input
              type="checkbox"
              :checked="enabledChartIds.length"
              @change="toggleAll"
            >
            <span class="radio__label">Select all</span>
          </label>

          <div v-for="(opts, type) in typeOptions">
            <div class="tree__parent">{{ type }}</div>
            <div class="tree__children">
              <div v-for="opt in opts" class="a--decorated" :class="emptyChartIds.includes(opt.id) ? 'a--nodata' : ''">
                <span class="tree__label">
                  <checkbox :model-value="enabledChartIds.includes(opt.id)" @update:modelValue="toggleChart(opt.id, $event)" :label="opt.formattedProperty" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="charts__container">

        <!-- Charts -->
        <div class="chart-card" v-for="chart in enabledCharts" :key="chart.id + chart.type + chart.property">
          <analytics-card :title="chart.title" enable :module="chart.id">
            <div v-text="chart.subtitle"></div>
            <component :is="chart.is" :chart="chart" @change="save" :full-size="true"/>
          </analytics-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import _groupBy from 'lodash/groupBy'
import _pick from 'lodash/pick'
import Checkbox from '../Form/Checkbox.vue'
import chartsMixin from '../Chart/chartsMixin.js'
import { fetchChart, fetchCharts, updateCharts } from '../../api/analytics.js'
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'charts',
  data () {
    return {
      expanded: false,
      options: [],
      charts: [],
      errors: {}
    }
  },
  computed: {
    portfolios () {
      return this.$route.query && this.$route.query.portfolios && this.$route.query.portfolios.split(',') || []
    },
    typeOptions () {
      return _groupBy(this.options, 'type')
    }
  },
  methods: {
    flipHorizontal (chart) {
      var h = !chart.horizontal
      chart.horizontal = h

      if (!this.chart) {
        return
      }
      this.chart.horizontal = h
    },
    flipPercentual (chart) {
      // Make sure average and percentual are mutually exclusive
      var showPercentual = !chart.percentual

      chart.average = false
      chart.percentual = showPercentual

      if (!this.chart) {
        return
      }

      this.chart.average = false
      this.chart.percentual = showPercentual
    },
    flipAverage (chart) {
      // Make sure average and percentual are mutually exclusive
      var showAverage = !chart.average

      chart.percentual = false
      chart.average = showAverage

      if (!this.chart) {
        return
      }

      this.chart.percentual = false
      this.chart.average = showAverage
    },
    toggleChart (id) {
      const charts = this.charts.filter(opt => opt.id !== id)
      if (charts.length === this.charts.length) {
        const chart = this.options.find(opt => opt.id === id)
        if (!chart) {
          this.errors = { message: 'Failed to add chart' }
          return
        }
        const options = _pick(chart, ['property', 'dateProperty', 'aggregate'])
        const filters = Object.assign({}, options, { portfolios: this.portfolios })
        fetchChart(chart.type, filters)
          .then(data => {
            this.charts.unshift(data)
            this.save()
          })
          .catch(errors => {
            this.errors = errors
          })
      } else {
        this.charts = charts
        this.save()
      }
    },
    toggleAll (evt) {
      if (!evt.target.checked) {
        this.charts = []
      } else {
        fetchCharts({ userFilter: false, portfolios: this.portfolios })
          .then(charts => {
            this.charts = charts
          })
          .catch(errors => {
            this.errors = errors
          })
      }
    },
    save () {
      // Don't save an empty charts array
      if (!this.enabledChartIds.length) {
        return
      }
      const charts = this.enabledCharts.map(chart => Object.assign({}, chart, {
        layout: null,
        datasets: [],
        data: []
      }))
      updateCharts({ charts })
    }
  },
  mounted () {
    // Reset the list of expanded charts
    // NOTE: this might not be necessary any more as we use the chart as a reference of the parent,
    // which has its charts refreshed correctly already
    this.$store.dispatch('ANALYTICS/EXPAND_CHART', null)

    fetchCharts({ portfolios: this.portfolios })
      .then(charts => {
        this.charts = charts
      })
      .catch(errors => {
        this.errors = errors
      })
    fetchCharts({ userFilter: false, noData: true })
      .then(options => {
        this.options = options
      })
      .catch(errors => {
        this.errors = errors
      })
  },
  mixins: [chartsMixin],
  components: {
    Checkbox,
  }
})
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";
.charts .card.card--expanded {
  top: 74px;
  margin-bottom: 0;
}
.chart-plotly-container {
  height: 35vh;
}
.chart-card {
  margin-bottom: 2em;
  .embed-responsive {
    overflow: visible;
  }
}
.charts__left {
  display: none;
}
.charts__container {
  padding: 0 20px;
    float: left;
    width: 100%;
  &::before {
    content: '';
    display: block;
    height: 20px;
  }
}
@media (min-width: 1040px) {
  .charts__left {
    // max-width: 50em;
    display: block;
    float: left;
    width: 15rem;
  }
  .charts__container {
    // max-width: 50em;
    width: calc(100% - 15rem);
  }
}
.charts__sticky {
  position: sticky;
  top: 0;
  bottom: 0;
  padding: 20px;
}
.charts__absolute {
  padding: 20px;
}
.tree__parent {
  color: #888;
  font-size: 12px;
  font-family: $font-stack-secondary;
  text-transform: uppercase;
  margin-top: 1em;
  margin-bottom: 4px;
  &:hover {
    >.tree__label {
      // text-decoration: underline;
    }
  }
}
.tree__label {
  vertical-align: top;
  line-height: 24px;
  >.radio {
    padding-left: 2px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.a--decorated {
  position: relative;
  text-decoration: none;
  cursor: pointer;
  &::after {
    content: "";
    position: absolute;
    bottom: 1px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: $color-primary;
    transition: width .2s;
  }
  &.a--active,
  &:focus,
  &:hover {
    text-decoration: none;
    &::after {
      width: 20px;
    }
  }
}
.a--nodata {
  opacity: .5;
  .radio__label {
    text-decoration: line-through;
  }
}
</style>
