<template>
  <dropdown :placeholder="defaultPlaceholder" search :options="countries" @update:modelValue="handleInput" :model-value="modelValue"
            v-model="countryCode" :is-simplified="isSimplified"/>
</template>

<script>
  import Dropdown from './Dropdown.vue'
  import { COUNTRIES } from '../../constants/countries'

  export default {
    props: {
      modelValue: {
        type: String,
        default: ''
      },
      isSimplified: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: '',
      },
    },
    emits: ['update:modelValue'],
    data () {
      return {
        countries: Object.keys(COUNTRIES).map(countryId => {
          return {
            label: COUNTRIES[countryId],
            value: countryId
          }
        }),
        countryCode: null,
      }
    },
    mounted () {
      this.countryCode = this.modelValue
    },
    methods: {
      handleInput (val) {
        this.$emit('update:modelValue', { code: val, name: COUNTRIES[val] })
      },
    },
    watch: {
      modelValue (val) {
        this.countryCode = val
      }
    },
    computed: {
      defaultPlaceholder () {
        return !this.placeholder ? this.$t('signup_company_country') : this.placeholder
      },
    },
    components: {
      Dropdown
    }
  }
</script>
