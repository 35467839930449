<template>
  <button
    :style="buttonStyle"
    :class="{disabled: disabled}"
    @click="handleClick"
  >
    <template v-if="facetName === 'portfolio_ids'">
      {{ facetLabel }}
    </template>
    <template v-else>
      {{
        (capitalize(getLabelForTaxonomyValue(facetName, facetValue) || facetLabel || facetName).split('>').slice(-1)[0].trim())
      }}
    </template>
  </button>
</template>

<script>
  import FiltersMixin from '../../util/FiltersMixin.js'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import ConfigMixin from '../../util/ConfigMixin.js'
  import UiMixin from '../../util/UiMixin.js'
  import OnboardingMixin from '../../util/OnboardingMixin.js'
  import { capitalize } from '../../util/string.ts'

  export default {
    name: 'FacetFilterButton',
    props: ['facetName', 'facetValue', 'facetParentId', 'facetLabel', 'facetCounts', 'allowToggle', 'disabled'],
    emits: ['click'],
    mixins: [FiltersMixin, TranslationsMixin, ConfigMixin, UiMixin, OnboardingMixin],
    computed: {
      keywords () {
        return this.$store.state.filters.keywords
      },
      buttonStyle () {
        const activeKeywords = this.$store.state.filters.keywords
        const activeCategoryButtonStyles = {
          backgroundColor: '#CECECE',
          color: 'black',
        }
        const inactiveCategoryButtonStyles = {
          backgroundColor: '#fff',
          color: 'black',
        }

        if (!this.allowToggle) {
          return activeCategoryButtonStyles
        }

        if (this.facetName.startsWith('report_field') && this.$store.state.filters.reportFilters[this.facetName]) {
          const isActive = Boolean(
            this.$store.state.filters.reportFilters[this.facetName].find(
              k => k === this.facetValue,
            ),
          )
          return isActive
            ? activeCategoryButtonStyles
            : inactiveCategoryButtonStyles
        }

        // Product features are treated differently, every sub feature is a separate filtering row
        if (['product_features_a', 'product_features_b', 'product_features_c'].includes(this.facetName)) {
          // !value identifies the "all" option
          if (!this.facetValue) {
            // Get the ids that we need to filter out
            var productFeatureRowOptions = []

            this.facetCounts.forEach((facet) => {
              if (facet.count.filter(facet => facet.parent_id == this.facetParentId).length > 0) {
                productFeatureRowOptions = facet.count.map(facet => facet.value)
              }
            })

            const isActive = Boolean(
              activeKeywords.find(
                k => k.facet === this.facetName && productFeatureRowOptions.includes(k.value),
              ),
            )

            return !isActive
              ? activeCategoryButtonStyles
              : inactiveCategoryButtonStyles
          } else {
            const isActive = Boolean(
              activeKeywords.find(
                k => k.facet === this.facetName && k.value === this.facetValue,
              ),
            )

            return isActive
              ? activeCategoryButtonStyles
              : inactiveCategoryButtonStyles
          }
        }

        if (!this.facetValue) {
          if (this.facetName === 'portfolio_ids') {
            const containsNoFilters = !this.$store.state.filters.portfolio
            return containsNoFilters
              ? activeCategoryButtonStyles
              : inactiveCategoryButtonStyles
          } else {
            const containsNoFilters = !activeKeywords.find(k => k.facet === this.facetName)
            return containsNoFilters
              ? activeCategoryButtonStyles
              : inactiveCategoryButtonStyles
          }
        } else if (this.facetName === 'portfolio_ids') {
          const isActive = this.$store.state.filters.portfolio === this.facetValue
          return isActive
            ? activeCategoryButtonStyles
            : inactiveCategoryButtonStyles
        } else {
          const isActive = Boolean(
            activeKeywords.find(
              k => k.facet === this.facetName && k.value === this.facetValue,
            ),
          )
          return isActive
            ? activeCategoryButtonStyles
            : inactiveCategoryButtonStyles
        }
      }
    },
    methods: {
      capitalize,
      handleClick () {
        if (this.disabled) {
          return
        }
        this.$emit('click')
      },
    }
  }
</script>

<style scoped>
</style>
