<template>
  <div v-if="canManageUsers">
    <h2 class="h2">Mailed invitations</h2>
    <table class="table">
      <thead>
      <tr>
        <th>Email</th>
        <th>Role</th>
        <th>Sent at</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="m in mailed">
        <td>{{ m.email }}</td>
        <td>{{ normaliseRole(m.role) }}</td>
        <td>{{ m.created_at }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
  import { fetchInvitations } from '../../api/config.js'
  export default {
    data () {
      return {
        form: {},
        mailed: []
      }
    },
    computed: {
      canManageUsers () {
        return this.$store.getters.publisher ? this.$store.getters.isMember : this.$store.getters.isOwner
      }
    },
    methods: {
      normaliseRole (selectedRole) {
        if (window.ROLES) {
            return window.ROLES[selectedRole]
        }

        switch (selectedRole) {
          case 'actor':
            return 'Ecosystem Member'
          case 'portfolio_member':
            return 'Portfolio Member'
          case 'owner':
            return 'Owner'
          case 'member':
            return 'Team Member'
          default :
            return ''
        }
      },
      fetch () {
        fetchInvitations().then(m => {
          this.mailed = m
        })
      }
    },
    mounted () {
      this.fetch()
      this.$bus.on('fetchInvites', this.fetch)
    },
  }
</script>
