import { getAllTaxonomies } from '../../api/taxonomies.js'
import { fromTwoLevelTaxonomyId } from '../../util/hierarchy-taxonomy.js'
import { AVAILABLE_LOCALES } from '../../store/modules/localization.js'
import { snakeCase } from '../../util/string.ts'
function getFlatTaxonomyLabels (taxonomy, state) {
  var labels = []

  if (!state[taxonomy]) {
    return labels
  }

  state[taxonomy].forEach(taxonomyObject => {
    labels.push(taxonomyObject.name)
  })

  return labels
}

function getFlatTaxonomyMapping (taxonomy, state) {
  if (!state[taxonomy]) {
    return []
  }

  return state[taxonomy].map(taxonomyObject => {
    return {
      value: taxonomyObject.id,
      label: taxonomyObject.name,
    }
  })
}

export function getDefaultTaxonomiesForType (type) {
  switch (type) {
    case 'category':
      return [
        'Academia',
        'Association',
        'Cluster',
        'Community',
        'Enabler',
        'Event',
        'Federation',
        'Hospital',
        'Investment Firm / Fund',
        'Knowledge Institute',
        'Marketplace',
        'NGO',
        'Manufacturer',
        'Project Team',
        'Public Agency',
        'Publisher',
        'Retailer',
        'Service Provider',
        'Technology Provider',
        'Incubator / Accelerator',
      ]
    case 'industries':
      return [
        'Aerospace & Defense',
        'Automotive',
        'Commerce & Shopping',
        'Commercial & Professional Services',
        'Consumer Goods',
        'Consumer Services',
        'Energy',
        'Financial Sector',
        'Food & Agriculture',
        'Hardware & Equipment',
        'Health & Care',
        'ICT',
        'Insurance',
        'Manufacturing',
        'Materials',
        'Media & Entertainment',
        'Real Estate',
        'Telecommunication',
        'Transportation',
        'Utilities',
      ]
    case 'technology':
      return [
        '3D Printing',
        '3D Technology',
        'Advanced Materials',
        'Artificial Intelligence',
        'Augmented Reality',
        'Autonomous Vehicle',
        'Battery Technology',
        'Big Data',
        'Biometrics',
        'BioTechnology',
        'Blockchain',
        'Clean Technology',
        'Cloud Computing',
        'Computer Vision',
        'Crypto Currency',
        'Cybersecurity',
        'Digital Signage',
        'Drones',
        'E-learning',
        'Electronics',
        'Gamification',
        'Genetics',
        'Geolocation based services',
        'Hardware',
        'Image Recognition',
        'Information Technology',
        'Internet Of Things',
        'Machine Learning',
        'Marketing Automation',
        'Messaging',
        'Mobile Technology',
        'Nanotechnology',
        'Optics',
        'Organic',
        'Personalisation',
        'Platform Technology',
        'Power Grid',
        'Renewable Energy',
        'Robotics',
        'Sensor Technology',
        'Social Media',
        'Virtualisation',
        'Voice Recognition',
        'Wearables',
        'Virtual Reality',
      ]
    case 'activities':
      return [
        'Agriculture',
        'Accommodation & Food Services',
        'Administrative & support service activities',
        'Arts, Design & Creative Activities',
        'Construction',
        'Consulting & professional Services',
        'Defence & Security',
        'Education',
        'Energy & Water Supply',
        'Financial & Insurance Activities',
        'Human health & social work activities',
        'Information Technology Services',
        'Manufacturing',
        'Real Estate Activities',
        'Scientific & technical activities',
        'Transportation & Storage',
        'Venturing Services',
        'Wholesale & Retail',
      ]
    default:
      return []
  }
}

const state = {
  categories: window.ALL_TAXONOMIES ? window.ALL_TAXONOMIES.categories : [],
  memberships: window.ALL_TAXONOMIES ? window.ALL_TAXONOMIES.memberships : [],
  activities: window.ALL_TAXONOMIES ? window.ALL_TAXONOMIES.activities : [],
  technologies: window.ALL_TAXONOMIES ? window.ALL_TAXONOMIES.technologies : [],
  domains: window.ALL_TAXONOMIES ? window.ALL_TAXONOMIES.domains : [],
  productFeaturesA: window.ALL_TAXONOMIES ? window.ALL_TAXONOMIES.productFeaturesA : [],
  productFeaturesB: window.ALL_TAXONOMIES ? window.ALL_TAXONOMIES.productFeaturesB : [],
  productFeaturesC: window.ALL_TAXONOMIES ? window.ALL_TAXONOMIES.productFeaturesC : [],
  stages: window.ALL_TAXONOMIES ? window.ALL_TAXONOMIES.stages : [],
  funnelStages: window.ALL_TAXONOMIES ? window.ALL_TAXONOMIES.funnelStages : [],
  industries: window.ALL_TAXONOMIES ? window.ALL_TAXONOMIES.industries : [],
  expertises: window.ALL_TAXONOMIES ? window.ALL_TAXONOMIES.expertises : [],
  motivations: window.ALL_TAXONOMIES ? window.ALL_TAXONOMIES.motivations : [],
  readinessLevels: window.ALL_TAXONOMIES ? window.ALL_TAXONOMIES.readinessLevels : [],
  processSteps: window.ALL_TAXONOMIES ? window.ALL_TAXONOMIES.processSteps : [],
  businessAspects: window.ALL_TAXONOMIES ? window.ALL_TAXONOMIES.businessAspects : [],
  aspects: window.ALL_TAXONOMIES ? window.ALL_TAXONOMIES.aspects : [],
  announcementCategories: window.ALL_TAXONOMIES ? window.ALL_TAXONOMIES.announcementCategories : [],
  eventCategories: window.ALL_TAXONOMIES ? window.ALL_TAXONOMIES.eventCategories : [],
}

export const MUTATION_TYPES = {
  UPDATE_ALL_TAXONOMIES: 'TAXONOMIES/UPDATE_ALL_TAXONOMIES',
  ADD_SUGGESTED_INDUSTRY: 'TAXONOMIES/ADD_SUGGESTED_INDUSTRY',
}

export const ACTION_TYPES = {
  REFRESH_ALL_TAXONOMIES: 'TAXONOMIES/REFRESH_ALL_TAXONOMIES',
}

export const mutations = {
  [MUTATION_TYPES.UPDATE_ALL_TAXONOMIES] (state, taxonomies) {
    var taxonomyProperties = Object.keys(taxonomies)

    taxonomyProperties.forEach(key => {
      if (!state.hasOwnProperty(key)) {
        return
      }

      state[key] = taxonomies[key]

      window.ALL_TAXONOMIES[key] = taxonomies[key]
    })
  },
  [MUTATION_TYPES.ADD_SUGGESTED_INDUSTRY] (state, { suggestedIndustries, idToAdd }) {
    addIndustry(state, suggestedIndustries, idToAdd)
  },
}

function addIndustry (state, suggestedIndustries, idToAdd) {
  if (findIndustry(state.industries, idToAdd) !== undefined) {
    return true
  }

  const industryToAdd = suggestedIndustries.find(i => i.id === idToAdd)
  if (!industryToAdd) return false

  if (industryToAdd.industry_id) {
    if (findIndustry(state.industries, industryToAdd.industry_id) === undefined) {
      if (!addIndustry(state, suggestedIndustries, industryToAdd.industry_id)) {
        return false
      }
    }

    const parent = findIndustry(state.industries, industryToAdd.industry_id)
    if (!parent) return false

    parent.industries.push({
      ...industryToAdd,
      description: '',
      industries: [],
    })
  } else {
    state.industries.push({
      ...industryToAdd,
      description: '',
      industries: [],
    })
  }
}

function findIndustry (industries, id) {
  if (!industries) return undefined

  for (const industry of industries) {
    if (industry.id === id) {
      return industry
    }

    const child = findIndustry(industry.industries, id)

    if (child !== undefined) {
      return child
    }
  }

  return undefined
}

export const actions = {
  [ACTION_TYPES.REFRESH_ALL_TAXONOMIES] ({ commit }) {
    getAllTaxonomies()
      .then(response => {
        commit(MUTATION_TYPES.UPDATE_ALL_TAXONOMIES, response)
      })
      .catch(err => {
        console.log(err)
      })
  },
}

export const getters = {
  categoryLabels (state) {
    var categories = []

    if (!state.categories) {
      return categories
    }

    state.categories.forEach(category => {
      if (!categories[category.actor_type]) {
        categories[category.actor_type] = []
      }

      categories[category.actor_type].push(category.name)
    })

    return categories
  },
  categoryFlatLabels (state) {
    var categories = []

    if (!state.categories) {
      return categories
    }

    state.categories.forEach(category => {
      if (!categories[category.actor_type]) {
        categories[category.actor_type] = []
      }

      categories.push(category.name)
    })

    return categories
  },
  membershipLabels (state) {
    return getFlatTaxonomyLabels('memberships', state)
  },
  activityLabels (state) {
    return getFlatTaxonomyLabels('activities', state)
  },
  technologyLabels (state) {
    return getFlatTaxonomyLabels('technologies', state)
  },
  domainLabels (state) {
    return getFlatTaxonomyLabels('domains', state)
  },
  productFeatureALabels (state) {
    return getFlatTaxonomyLabels('productFeaturesA', state)
  },
  productFeatureBLabels (state) {
    return getFlatTaxonomyLabels('productFeaturesB', state)
  },
  productFeatureCLabels (state) {
    return getFlatTaxonomyLabels('productFeaturesC', state)
  },
  stageLabels (state) {
    return getFlatTaxonomyLabels('stages', state)
  },
  funnelStageLabels (state) {
    return getFlatTaxonomyLabels('funnelStages', state)
  },
  industryLabels (state) {
    return getFlatTaxonomyLabels('industries', state)
  },
  expertiseLabels (state) {
    return getFlatTaxonomyLabels('expertises', state)
  },
  motivationLabels (state) {
    return getFlatTaxonomyLabels('motivations', state)
  },
  readinessLevelLabels (state) {
    return getFlatTaxonomyLabels('readinessLevels', state)
  },
  processStepLabels (state) {
    return getFlatTaxonomyLabels('processSteps', state)
  },
  businessAspectLabels (state) {
    return getFlatTaxonomyLabels('businessAspects', state)
  },
  aspectLabels (state) {
    return getFlatTaxonomyLabels('aspects', state)
  },
  categoryValuesAndLabels (state) {
    return getFlatTaxonomyMapping('categories', state)
  },
  categoryValuesAndLabelsPerType (state) {
    if (!state['categories']) {
      return []
    }

    var result = {
      'LegalEntity': [],
      'Person': [],
      'Community': [],
      'Product': [],
    }

    state['categories'].map(taxonomyObject => {
      var category = {
        value: taxonomyObject.id,
        label: taxonomyObject.name,
      }

      result[taxonomyObject.actor_type].push(category)
    })

    return result
  },
  membershipValuesAndLabels (state) {
    return getFlatTaxonomyMapping('memberships', state)
  },
  activityValuesAndLabels (state) {
    return getFlatTaxonomyMapping('activities', state)
  },
  technologyValuesAndLabels (state) {
    return getFlatTaxonomyMapping('technologies', state)
  },
  domainValuesAndLabels (state) {
    var result = []

    addTwoLevelTaxonomyValuesAndLabels(state.domains, result, 'domains')

    return result
  },
  productFeatureAValuesAndLabels (state) {
    var result = []

    addTwoLevelTaxonomyValuesAndLabels(state.productFeaturesA, result, 'children')

    return result
  },
  productFeatureBValuesAndLabels (state) {
    var result = []

    addTwoLevelTaxonomyValuesAndLabels(state.productFeaturesB, result, 'children')

    return result
  },
  productFeatureCValuesAndLabels (state) {
    var result = []

    addTwoLevelTaxonomyValuesAndLabels(state.productFeaturesC, result, 'children')

    return result
  },
  stageValuesAndLabels (state) {
    return getFlatTaxonomyMapping('stages', state)
  },
  funnelStageValuesAndLabels (state) {
    return getFlatTaxonomyMapping('funnelStages', state)
  },
  industryValuesAndLabels (state) {
    var result = []

    addTwoLevelTaxonomyValuesAndLabels(state.industries, result, 'industries')

    return result
  },
  expertiseValuesAndLabels (state) {
    return getFlatTaxonomyMapping('expertises', state)
  },
  motivationValuesAndLabels (state) {
    return getFlatTaxonomyMapping('motivations', state)
  },
  readinessLevelValuesAndLabels (state) {
    return getFlatTaxonomyMapping('readinessLevels', state)
  },
  processStepValuesAndLabels (state) {
    return getFlatTaxonomyMapping('processSteps', state)
  },
  businessAspectValuesAndLabels (state) {
    return getFlatTaxonomyMapping('businessAspects', state)
  },
  announcementCategoryValuesAndLabels (state) {
    return getFlatTaxonomyMapping('announcementCategories', state)
  },
  eventCategoryValuesAndLabels (state) {
    return getFlatTaxonomyMapping('eventCategories', state)
  },
  aspectValuesAndLabels (state) {
    return []
  },
}

function addTwoLevelTaxonomyValuesAndLabels (twoLevelTaxonomy, result, childrenName) {
  if (!twoLevelTaxonomy) {
    return
  }

  for (const taxonomyValue of twoLevelTaxonomy) {
    result.push({ value: taxonomyValue.id, label: taxonomyValue.name })
    addTwoLevelTaxonomyValuesAndLabels(twoLevelTaxonomy[childrenName], result, childrenName)
  }
}

// Return true if the taxonomy ID is linked to a "parent" taxonomy value, works for generic hierarchical taxonomies, like product features a,b,c
// Default value is true
export function isTaxonomyValueAParentValue (taxonomyValues, taxonomyId) {
  if (!taxonomyValues) {
    return true
  }

  const taxonomy = taxonomyValues.find(i => i.id === taxonomyId)

  if (!taxonomy) {
    return false
  }

  return !taxonomy.parent_id
}

export function taxonomyMapping () {
  return {
    category: 'categories',
    membership: 'memberships',
    stage: 'stages',
    funnel_stage: 'funnelStages',
    business_aspects: 'businessAspects',
    process_steps: 'processSteps',
    readiness_levels: 'readinessLevels',
    technology: 'technologies',
    product_features_a: 'productFeaturesA',
    product_features_b: 'productFeaturesB',
    product_features_c: 'productFeaturesC',
    expertises: 'expertises',
    motivations: 'motivations',
    announcement_categories: 'announcementCategories',
    event_categories: 'eventCategories',
  }
}

export function isTaxonomyHierarchical (taxonomy) {
  return [
    'product_features_a',
    'product_features_b',
    'product_features_c',
    'sub_product_features_a',
    'sub_product_features_b',
    'sub_product_features_c',
  ].includes(taxonomy)
}

/**
 * Return all translation labels for a taxonomy value
 *
 * NOTE: make sure there's always an 'en' label, which is the default throughout the application
 *
 * @param facet      The taxonomy property
 * @param value      The taxonomy id
 * @returns string
 */
export function getTranslationsForTaxonomyValue (facet, value) {
  let labels
  facet = snakeCase(facet)
  const taxonomyProperty = taxonomyMapping()[facet] || facet
  const taxonomies = { ...window.ALL_TAXONOMIES }
  let translations = {}
  const locales = AVAILABLE_LOCALES

  // When we introduce i18n taxonomy labels, replace this part with all labels of the matching taxonomy, instead of just the 1 "default" name
  if (facet === 'industries') {
    const industries = taxonomies ? taxonomies.industries : []
    labels = {}

    // Build up the labels for the different locales
    locales.forEach(function (locale) {
      labels[locale] = { name: fromTwoLevelTaxonomyId(value, industries, locale, 'industries') }
    })

    translations = labels
  } else if (facet === 'domains') {
    const domains = taxonomies ? taxonomies.domains : []
    labels = {}

    // Build up the labels for the different locales
    locales.forEach(function (locale) {
      labels[locale] = { name: fromTwoLevelTaxonomyId(value, domains, locale, 'domains') }
    })

    translations = labels
  } else if (taxonomyProperty === 'productFeaturesA') {
    const productFeaturesA = taxonomies ? taxonomies.productFeaturesA : []
    labels = {}

    // Build up the labels for the different locales
    locales.forEach(function (locale) {
      labels[locale] = { name: fromTwoLevelTaxonomyId(value, productFeaturesA, locale, 'children') }
    })

    translations = labels
  } else if (taxonomyProperty === 'productFeaturesB') {
    const productFeaturesB = taxonomies ? taxonomies.productFeaturesB : []
    labels = {}

    // Build up the labels for the different locales
    locales.forEach(function (locale) {
      labels[locale] = { name: fromTwoLevelTaxonomyId(value, productFeaturesB, locale, 'children') }
    })

    translations = labels
  } else if (taxonomyProperty === 'productFeaturesC') {
    const productFeaturesC = taxonomies ? taxonomies.productFeaturesC : []
    labels = {}

    // Build up the labels for the different locales
    locales.forEach(function (locale) {
      labels[locale] = { name: fromTwoLevelTaxonomyId(value, productFeaturesC, locale, 'children') }
    })

    translations = labels
  } else if (taxonomyProperty === 'technologies') {
    const technologies = taxonomies ? taxonomies.technologies : []
    labels = {}

    // Build up the labels for the different locales
    locales.forEach(function (locale) {
      labels[locale] = { name: fromTwoLevelTaxonomyId(value, technologies, locale, 'children') }
    })

    translations = labels
  } else if (facet === 'aspects') {
    const aspects = taxonomies ? taxonomies.aspects : []
    labels = {}

    // Build up the labels for the different locales
    locales.forEach(function (locale) {
      labels[locale] = { name: fromTwoLevelTaxonomyId(value, aspects, locale, 'aspects') }
    })

    translations = labels
  } else {
    const taxonomyValue = taxonomies[taxonomyProperty] && taxonomies[taxonomyProperty].find(taxonomy => {
      return taxonomy.id == value // Don't assume both are integers, back-end doesn't enforce json typing
    })

    if (taxonomyValue) {
      translations = { ...taxonomyValue.translations }
      translations['en'] = { name: taxonomyValue.name }
    }
  }

  return translations
}

export default {
  state,
  mutations,
  actions,
  getters,
}
