const scripts = {
  Handsontable: '//cdnjs.cloudflare.com/ajax/libs/handsontable/0.31.0/handsontable.full.min.js',
  Chart: '//cdnjs.cloudflare.com/ajax/libs/Chart.js/2.5.0/Chart.min.js',
  // L: '/js/chunks/leaflet-1.9.4.js',
  // MarkerCluster: '//cdnjs.cloudflare.com/ajax/libs/leaflet.markercluster/1.5.3/leaflet.markercluster.js',
  Plotly: '//cdn.plot.ly/plotly-latest.min.js',
  //Plotly: '//cdn.plot.ly/plotly-1.10.0.min.js',
  zxcvbn: '//cdnjs.cloudflare.com/ajax/libs/zxcvbn/4.3.0/zxcvbn.js',
  d3: '//cdnjs.cloudflare.com/ajax/libs/d3/3.5.17/d3.min.js'
}
const styles = {
  Handsontable: '//cdnjs.cloudflare.com/ajax/libs/handsontable/0.31.0/handsontable.full.min.css',
}
const fetched = {}

/**
 * Fetches a library and executes a callback when it's available in the window object
 * @param {string} lib - The name of the library in the window object
 * @param {string} cb - The callback to call after the library is loaded
 * @param {string} times - How many
 * @return {string} The result of the callback or the library
 */
export function loadChunk (lib, cb, times = 0) {
  if (window[lib]) {
    return typeof cb === 'function' ? cb() : window[lib]
  }
  if (times > 50) {
    return console.warn('Gave up on loading chunk', lib)
  }
  if (!scripts[lib]) {
    return console.error('Unexpected chunk', lib)
  }
  if (!fetched[lib]) {
    fetched[lib] = true
    let first, s
    s = document.createElement('script')
    s.src = scripts[lib]
    s.type = 'text/javascript'
    s.async = true
    first = document.getElementsByTagName('script')[0]
    first.parentNode.insertBefore(s, first)

    // Load chunk styles
    if (styles[lib]) {
      s = document.createElement('link')
      s.rel  = 'stylesheet'
      s.type = 'text/css'
      s.href = styles[lib]
      s.media = 'all'
      first = document.getElementsByTagName('link')[0]
      first.parentNode.insertBefore(s, first)
    }
  }
  setTimeout(() => loadChunk(lib, cb, times + 1), 10 + times * 10)
  return window[lib]
}
