<template>
  <ds-button v-if="options && options.length === 1"
    :variant="options[0].variant"
    :href="options[0].href"
    :icon="options[0].icon"
    :label="options[0].label"
    :to="options[0].to"
    class="button--small"
    @click.capture="$emit('select')"
  />
  <span v-else-if="options && options.length" class="subtle-dropdown">
    <ds-button
      class="button--small"
      icon="chevron-down"
      :label="label"
      :variant="variant"
      @blur="blur"
      @click="toggle"
      @focus="focus"
    />
    <div v-if="open" class="subtle-dropdown__options" @mousedown.stop @touchstart.stop>
      <ds-button v-for="(option, index) in options"
        :key="index"
        variant="secondary"
        class="button--small"
        block
        :icon="option.icon"
        :label="option.label"
        :to="option.value"
        @blur="blur"
        @click.capture="$emit('select')"
      />
    </div>
  </span>
</template>

<script>
export default {
  props: ['options', 'label', 'variant'],
  data () {
    return {
      open: false,
    }
  },
  methods: {
    toggle () {
      this.open = !this.open || this.active
    },
    focus () {
      this.active = true
      this.open = true
      setTimeout(() => {
        this.active = false
      }, 500)
    },
    blur () {
      setTimeout(() => {
        if (this.$el && document.activeElement && !this.$el.contains(document.activeElement)) {
          this.open = false
        }
      }, 0)
    }
  }
}
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";

.subtle-dropdown {
  position: relative;
  border: none;
  background: none;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $color-primary;
    .fill-fg {
      fill: $color-primary;
    }
  }
  &:hover .fill-fg {
    // fill: $color-primary;
  }
  .button {
    text-align: left;
  }
  >.svg-icon {
    display: block;
  }
}

.subtle-dropdown__options {
  position: absolute;
  z-index: 1;
  height: 150px;
  margin-bottom: 150px;
  top: 100%;
  left: -2px;
  white-space: nowrap;
  font-size: 14px;
  text-align: left;
  background: $color-secondary;
  > .button {
    &:focus {
      z-index: 2;
    }
    .svg-icon + .button__label {
      padding-left: 24px;
    }
  }
}
</style>
