import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {}
  },
  computed: {
    isLoggedIn (): boolean {
      return this.$store.getters.isLoggedIn
    },
    userId (): Number {
      return Number(this.$store.getters.userId)
    },
    isEcosystemMember (): boolean {
      return this.$store.getters.isActor
    },
    isPortfolioMember (): boolean {
      return this.$store.getters.isPortfolioMember
    },
    isMember (): boolean {
      return this.$store.getters.isMember
    },
    isOwner (): boolean {
      return this.$store.getters.isOwner
    },
    isDeveloper () {
      return this.$store.getters.isDeveloper
    },
  },
})
