<template>
  <div class="restricted_modal">
    <modal :allow-overflow="true" id="modalId" @close="close" :title="$t('add_product_warning_title')">
      <template v-slot:body>
        <div>
          <p>{{ $t('warning_modal_add_product') }}</p>
        </div>
      </template>
      <template v-slot:footer>
        <div>
          <DsButton
            :label="$t('add_new_actor')"
            variant="primary"
            icon="plus"
            @click="showAddActor"
          />
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
  import Modal from './Modal.vue'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import { trackHeapEvent } from '../../util/analytics.js'
  import { defineComponent } from 'vue'
  export default defineComponent({
    props: {
      body: {
        type: String,
      },
    },
    computed: {
      actors () {
        return this.$store.state.actors.listData
      },
      products () {
        return this.$store.state.actors.productData
      },
    },
    methods: {
      close () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.RESTRICTED_MODAL)
        this.$emit('close')
      },
      showAddActor () {
        trackHeapEvent('addActor')
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)
      },
      showAddProduct () {
        trackHeapEvent('addProduct')
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_PRODUCT)
      },
    },
    components: { Modal },
  })
</script>

<style lang="scss" scoped>

</style>
