<template>
  <div class="modals__container">
    <actor-sign-up-modal v-if="isModalVisible(MODAL_IDS.ACTOR_SIGN_UP)"/>
    <add-actor-modal v-if="isModalVisible(MODAL_IDS.ADD_ACTOR)"/>
    <add-domain-modal v-if="isModalVisible(MODAL_IDS.ADD_DOMAIN)"/>
    <add-instant-monitoring-modal v-if="isModalVisible(MODAL_IDS.ADD_INSTANT_MONITORING)"/>
    <add-relation-modal v-if="isModalVisible(MODAL_IDS.ADD_RELATION)"/>
    <all-filters-modal v-if="isModalVisible(MODAL_IDS.ALL_FILTERS)"/>
    <article-relation-modal v-if="isModalVisible(MODAL_IDS.ARTICLE_RELATION_MODAL)"></article-relation-modal>
    <manage-user-modal v-if="isModalVisible(MODAL_IDS.MANAGE_USER)"/>
    <custom-filters-modal v-if="isModalVisible(MODAL_IDS.CUSTOM_FILTERS)"/>
    <cancel-actor-edit-confirmation-modal v-if="isModalVisible(MODAL_IDS.CANCEL_ACTOR_EDIT_CONFIRMATION)"/>
    <change-password-modal v-if="isModalVisible(MODAL_IDS.CHANGE_PASSWORD)"/>
    <claim-modal v-if="isModalVisible(MODAL_IDS.CLAIM)"/>
    <confirmation-modal v-if="isModalVisible(MODAL_IDS.CONFIRMATION)"/>
    <contact-modal v-if="isModalVisible(MODAL_IDS.CONTACT)"/>
    <contact-support-modal v-if="isModalVisible(MODAL_IDS.CONTACT_SUPPORT)"/>
    <delete-confirmation-modal v-if="isModalVisible(MODAL_IDS.DELETE_CONFIRMATION)"/>
    <finalise-sign-up-modal v-if="isModalVisible(MODAL_IDS.FINALISE_SIGN_UP)"/>
    <forgot-password-modal v-if="isModalVisible(MODAL_IDS.FORGOT_PASSWORD)"/>
    <gdpr-modal v-if="isModalVisible(MODAL_IDS.GDPR)"/>
    <login-modal v-if="isModalVisible(MODAL_IDS.LOGIN)"/>
    <logged-out-modal v-if="isModalVisible(MODAL_IDS.LOGGED_OUT)"/>
    <more-active-filters-modal v-if="isModalVisible(MODAL_IDS.MORE_FILTER_TAGS)"/>
    <more-active-announcements-filters-modal v-if="isModalVisible(MODAL_IDS.MORE_ANNOUNCEMENT_FILTER_TAGS)"/>
    <more-legends-modal v-if="isModalVisible(MODAL_IDS.MORE_LEGENDS)"/>
    <more-tags-modal v-if="isModalVisible(MODAL_IDS.MORE_TAGS)"/>
    <remove-domain-modal v-if="isModalVisible(MODAL_IDS.REMOVE_DOMAIN)"/>
    <remove-relation-modal v-if="isModalVisible(MODAL_IDS.REMOVE_RELATION)"/>
    <restricted-modal v-if="isModalVisible(MODAL_IDS.RESTRICTED)"/>
    <sign-up-modal v-if="isModalVisible(MODAL_IDS.SIGN_UP)"/>
    <confirm-package-modal v-if="isModalVisible(MODAL_IDS.CONFIRM_PACKAGE)"/>
    <user-edit-modal v-if="isModalVisible(MODAL_IDS.USER_EDIT)"/>
    <user-leave-ecosystem-modal v-if="isModalVisible(MODAL_IDS.USER_LEAVE_ECOSYSTEM)"/>
    <user-leave-ecosystem-and-remove-data-modal v-if="isModalVisible(MODAL_IDS.USER_LEAVE_ECOSYSTEM_AND_TRANSFER_DATA)"/>
    <remove-user-from-ecosystem v-if="isModalVisible(MODAL_IDS.REMOVE_USER_FROM_ECOSYSTEM)"/>
    <portfolio-actors-remove-modal v-if="isModalVisible(MODAL_IDS.PORTFOLIO_ACTORS_REMOVE)"/>
    <actor-video-modal v-if="isModalVisible(MODAL_IDS.ACTOR_VIDEO)"/>
    <colour-picker-modal v-if="isModalVisible(MODAL_IDS.COLOUR_PICKER)"/>
    <explore-content-modal v-if="isModalVisible(MODAL_IDS.EXPLORE_CONTENT)"/>
    <edit-topic-modal v-if="isModalVisible(MODAL_IDS.EDIT_TOPIC)"/>
    <taxonomy-info-modal v-if="isModalVisible(MODAL_IDS.TAXONOMY_INFO)"/>
    <content-digest-info-modal v-if="isModalVisible(MODAL_IDS.CONTENT_DIGEST_INFO)"/>
    <edit-nace-codes v-if="isModalVisible(MODAL_IDS.EDIT_NACE_CODES)"/>
    <image-detail-modal v-if="isModalVisible(MODAL_IDS.IMAGE_DETAIL)"/>
    <map-modal v-if="isModalVisible(MODAL_IDS.MAP)"/>
    <welcome-message-modal v-if="isModalVisible(MODAL_IDS.WELCOME_MESSAGE)"/>
    <packages-sign-up-modal v-if="isModalVisible(MODAL_IDS.PACKAGES_SIGN_UP)"/>
    <manage-announcement-modal v-if="isModalVisible(MODAL_IDS.MANAGE_ANNOUNCEMENT)"/>
    <announcement-address-modal v-if="isModalVisible(MODAL_IDS.ANNOUNCEMENT_ADDRESS)"/>
    <announcement-calendar-modal v-if="isModalVisible(MODAL_IDS.ANNOUNCEMENT_CALENDAR)"/>
    <success-modal v-if="isModalVisible(MODAL_IDS.SUCCESS)"/>
    <create-community-modal v-if="isModalVisible(MODAL_IDS.CREATE_COMMUNITY)"/>
    <manage-community-members-modal v-if="isModalVisible(MODAL_IDS.MANAGE_COMMUNITY_MEMBERS)"/>
    <create-spotting-area-modal v-if="isModalVisible(MODAL_IDS.CREATE_SPOTTING_AREA)"/>
    <create-proof-point-modal v-if="isModalVisible(MODAL_IDS.CREATE_PROOF_POINT)"/>
    <edit-user-profile-modal v-if="isModalVisible(MODAL_IDS.EDIT_USER_PROFILE)"/>
    <add-product-warning-modal v-if="isModalVisible(MODAL_IDS.RESTRICTED_MODAL)"/>
    <add-product-modal v-if="isModalVisible(MODAL_IDS.ADD_PRODUCT)"/>
    <no-access-to-community-modal v-if="isModalVisible(MODAL_IDS.NO_ACCESS_TO_COMMUNITY_MODAL)"/>
    <add-actor-to-article-modal v-if="isModalVisible(MODAL_IDS.ADD_ACTOR_TO_ARTICLE_MODAL)"/>
    <add-member-to-community-modal v-if="isModalVisible(MODAL_IDS.ADD_MEMBER_TO_COMMUNITY_MODAL)"/>
    <add-contributor-modal v-if="isModalVisible(MODAL_IDS.ADD_CONTRIBUTOR_MODAL)"/>
    <manage-event-modal v-if="isModalVisible(MODAL_IDS.MANAGE_EVENT)"/>
    <select-spotting-area-modal v-if="isModalVisible(MODAL_IDS.SELECT_SPOTTING_AREA_MODAL)"/>
    <remove-actor-from-spotting-area-modal v-if="isModalVisible(MODAL_IDS.REMOVE_ACTOR_FROM_SPOTTING_AREA_MODAL)"/>
  </div>
</template>

<script lang="ts">
  import ActorSignUpModal from './ActorSignUpModal.vue'
  import ActorVideoModal from './ActorVideoModal.vue'
  import AddActorModal from './AddActorModal.vue'
  import AddDomainModal from './AddDomainModal.vue'
  import AddRelationModal from './AddRelationModal.vue'
  import AllFiltersModal from './AllFiltersModal/AllFiltersModal.vue'
  import CancelActorEditConfirmationModal from './CancelActorEditConfirmationModal.vue'
  import ChangePasswordModal from './ChangePasswordModal.vue'
  import ClaimModal from './ClaimModal.vue'
  import ColourPickerModal from './ColourPickerModal.vue'
  import ConfirmationModal from './ConfirmationModal.vue'
  import ContactModal from './ContactModal.vue'
  import ContactSupportModal from './ContactSupportModal.vue'
  import DeleteConfirmationModal from './DeleteConfirmationModal.vue'
  import EditTopicModal from './EditTopicModal.vue'
  import ExploreContentModal from './ExploreContentModal.vue'
  import FinaliseSignUpModal from './FinaliseSignUpModal.vue'
  import ForgotPasswordModal from './ForgotPasswordModal.vue'
  import GdprModal from './GdprModal.vue'
  import LoginModal from './LoginModal.vue'
  import LoggedOutModal from './LoggedOutModal.vue'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import MoreActiveAnnouncementsFiltersModal from './MoreActiveAnnouncementsFiltersModal.vue'
  import MoreActiveFiltersModal from './MoreActiveFiltersModal.vue'
  import MoreLegendsModal from './MoreLegendsModal.vue'
  import MoreTagsModal from './MoreTagsModal.vue'
  import PortfolioActorsRemoveModal from './PortfolioActorsRemoveModal.vue'
  import SelectSpottingAreaModal from './SelectSpottingAreaModal.vue'
  import RemoveDomainModal from './RemoveDomainModal.vue'
  import RemoveRelationModal from './RemoveRelationModal.vue'
  import RestrictedModal from './RestrictedModal.vue'
  import SignUpModal from './SignUpModal.vue'
  import TaxonomyInfoModal from './TaxonomyInfoModal.vue'
  import UserEditModal from './UserEditModal.vue'
  import ContentDigestInfoModal from './ContentDigestInfoModal.vue'
  import ConfirmPackageModal from './ConfirmPackageModal.vue'
  import EditNaceCodes from './EditNaceCodes.vue'
  import ImageDetailModal from './ImageDetailModal.vue'
  import MapModal from './MapModal.vue'
  import WelcomeMessageModal from './WelcomeMessageModal.vue'
  import PackagesSignUpModal from './PackagesSignUpModal.vue'
  import CustomFiltersModal from './AllFiltersModal/CustomFiltersModal.vue'
  import AddInstantMonitoringModal from './AddInstantMonitoringModal.vue'
  import ManageAnnouncementModal from './ManageAnnouncementModal.vue'
  import AnnouncementAddressModal from './AnnouncementAddressModal.vue'
  import AnnouncementCalendarModal from './AnnouncementCalendarModal.vue'
  import SuccessModal from './SuccessModal.vue'
  import CreateCommunityModal from './CreateCommunityModal.vue'
  import ManageCommunityMembersModal from './ManageCommunityMembersModal.vue'
  import CreateSpottingAreaModal from './CreateSpottingAreaModal.vue'
  import EditUserProfileModal from './EditUserProfileModal.vue'
  import ManageUserModal from './ManageUserModal.vue'
  import AddProductWarningModal from './AddProductWarningModal.vue'
  import AddProductModal from './AddProductModal.vue'
  import UserLeaveEcosystemModal from './UserLeaveEcosystemModal.vue'
  import UserLeaveEcosystemAndRemoveDataModal from './RemoveUserFromEcosystemAndTransferDataModal.vue'
  import RemoveUserFromEcosystem from './RemoveUserFromEcosystem.vue'
  import ArticleRelationModal from './ArticleRelationModal.vue'
  import NoAccessToCommunityModal from './NoAccessToCommunityModal.vue'
  import AddActorToArticleModal from './AddActorToArticleModal.vue'
  import AddMemberToCommunityModal from './AddMemberToCommunityModal.vue'
  import AddContributorModal from './AddContributorModal.vue'
  import CreateSignalModal from './createProofPointModal.vue'
  import createProofPointModal from './createProofPointModal.vue'
  import manageEventModal from './ManageEventModal.vue'
  import RemoveActorFromSpottingAreaModal from './RemoveActorFromSpottingAreaModal.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    data() {
      return {
        MODAL_IDS,
      }
    },
    methods: {
      isModalVisible(modalId) {
        return this.$store.state.ui.visibleModals.includes(modalId)
      },
    },
    components: {
      RemoveActorFromSpottingAreaModal,
      AddContributorModal,
      AddProductWarningModal,
      AddProductModal,
      ArticleRelationModal,
      ManageCommunityMembersModal,
      CreateCommunityModal,
      CreateSpottingAreaModal,
      CreateSignalModal,
      CustomFiltersModal,
      EditNaceCodes,
      ContentDigestInfoModal,
      ActorSignUpModal,
      ActorVideoModal,
      AddActorModal,
      AddDomainModal,
      AddInstantMonitoringModal,
      AddRelationModal,
      AllFiltersModal,
      CancelActorEditConfirmationModal,
      ChangePasswordModal,
      ClaimModal,
      ColourPickerModal,
      ConfirmationModal,
      ContactModal,
      ContactSupportModal,
      DeleteConfirmationModal,
      EditTopicModal,
      ExploreContentModal,
      FinaliseSignUpModal,
      ForgotPasswordModal,
      GdprModal,
      LoginModal,
      LoggedOutModal,
      MoreActiveAnnouncementsFiltersModal,
      MoreActiveFiltersModal,
      MoreLegendsModal,
      MoreTagsModal,
      PortfolioActorsRemoveModal,
      RemoveDomainModal,
      RemoveRelationModal,
      RestrictedModal,
      SignUpModal,
      TaxonomyInfoModal,
      UserEditModal,
      ConfirmPackageModal,
      ImageDetailModal,
      MapModal,
      WelcomeMessageModal,
      PackagesSignUpModal,
      ManageAnnouncementModal,
      AnnouncementAddressModal,
      AnnouncementCalendarModal,
      SuccessModal,
      EditUserProfileModal,
      ManageUserModal,
      UserLeaveEcosystemModal,
      UserLeaveEcosystemAndRemoveDataModal,
      RemoveUserFromEcosystem,
      NoAccessToCommunityModal,
      AddActorToArticleModal,
      AddMemberToCommunityModal,
      createProofPointModal,
      manageEventModal,
      SelectSpottingAreaModal,
    },
  })
</script>
