import { SiteTemplate, state as config } from './config.js'
import { getTranslationsForTaxonomyValue } from './taxonomies.js'
import ls from '../../util/ls.js'
import { _unique, getReportFieldByReportIdentifier } from '../../util/helpers.js'
import isString from 'lodash/isString'

import { generateReportFilters, generateReportFiltersForActors } from './filters-report.js'
import { actorTaxonomyProperties, taxonomyOptions } from '../../constants/config.js'

export const DEFAULT_LIMIT = 50
export const MUTATION_TYPES = {
  CLEAR: 'FILTERS/CLEAR',

  TOGGLE_FILTER: 'FILTERS/TOGGLE_FILTER',
  SET_LEGEND_PROPERTY: 'FILTERS/SET_LEGEND_PROPERTY',
  ADD_KEYWORD: 'FILTERS/ADD_KEYWORD',
  ADD_ANNOUNCEMENT_KEYWORD: 'FILTERS/ADD_ANNOUNCEMENT_KEYWORD',
  REMOVE_KEYWORD: 'FILTERS/REMOVE_KEYWORD',
  REMOVE_ANNOUNCEMENT_KEYWORD: 'FILTERS/REMOVE_ANNOUNCEMENT_KEYWORD',
  CLEAR_BY_FACET: 'FILTERS/CLEAR_BY_FACET',
  REFRESH_KEYWORDS: 'FILTERS/REFRESH_KEYWORDS',
  CLEAR_KEYWORDS: 'FILTERS/CLEAR_KEYWORDS',
  CLEAR_ANNOUNCEMENT_KEYWORDS: 'FILTERS/CLEAR_ANNOUNCEMENT_KEYWORDS',
  TOGGLE_KEYWORD: 'FILTERS/TOGGLE_KEYWORD',
  SET_ACTIVE_ACTOR_TYPES: 'FILTERS/SET_ACTIVE_ACTOR_TYPES',
  SET_GALLERY_ACTOR_TYPES_FOR_EM: 'FILTERS/SET_GALLERY_ACTOR_TYPES_FOR_EM',
  SET_INDUSTRIES: 'FILTERS/SET_INDUSTRIES',
  SET_DOMAINS: 'FILTERS/SET_DOMAINS',
  SET_PRODUCT_FEATURES_A: 'FILTERS/SET_PRODUCT_FEATURES_A',
  SET_PRODUCT_FEATURES_B: 'FILTERS/SET_PRODUCT_FEATURES_B',
  SET_PRODUCT_FEATURES_C: 'FILTERS/SET_PRODUCT_FEATURES_C',
  SET_BY_FACET: 'FILTERS/SET_BY_FACET',
  SET_NON_TAXONOMY_FACET: 'FILTERS/SET_NON_TAXONOMY_FACET',

  // SET_SOCIAL_MEDIA_MONITORING_HASHTAG: 'FILTERS/SET_SOCIAL_MEDIA_MONITORING_HASHTAG',
  // CLEAR_SOCIAL_MEDIA_MONITORING_HASHTAG: 'FILTERS/CLEAR_SOCIAL_MEDIA_MONITORING_HASHTAG',
  // SET_SOCIAL_MEDIA_MONITORING_TWITTER: 'FILTERS/SET_SOCIAL_MEDIA_MONITORING_TWITTER',
  // CLEAR_SOCIAL_MEDIA_MONITORING_TWITTER: 'FILTERS/CLEAR_SOCIAL_MEDIA_MONITORING_TWITTER',
  // SET_SOCIAL_MEDIA_MONITORING_EXCLUDED_TWITTERS: 'FILTERS/SET_SOCIAL_MEDIA_MONITORING_EXCLUDED_TWITTERS',
  // CLEAR_SOCIAL_MEDIA_MONITORING_EXCLUDED_TWITTERS: 'FILTERS/CLEAR_SOCIAL_MEDIA_MONITORING_EXCLUDED_TWITTERS',

  TOGGLE_LEGEND_ITEM: 'FILTERS/TOGGLE_LEGEND_ITEM',
  CLEAR_LEGEND_ITEMS: 'FILTERS/CLEAR_LEGEND_ITEMS',
  EMPTY_LEGEND_ITEMS: 'FILTERS/EMPTY_LEGEND_ITEMS',

  TOGGLE_STAGE: 'FILTERS/TOGGLE_STAGE',

  UPDATE_FUNDING_DATE_RANGE: 'FILTERS/UPDATE_FUNDING_DATE_RANGE',
  UPDATE_TOTAL_FUNDING_RANGE: 'FILTERS/UPDATE_TOTAL_FUNDING_RANGE',
  UPDATE_FOUNDED_DATE_RANGE: 'FILTERS/UPDATE_FOUNDED_DATE_RANGE',
  UPDATE_EMPLOYEE_RANGE: 'FILTERS/UPDATE_EMPLOYEE_RANGE',
  UPDATE_REVENUE_RANGE: 'FILTERS/UPDATE_REVENUE_RANGE',
  UPDATE_PATENT_COUNT_RANGE: 'FILTERS/UPDATE_PATENT_COUNT_RANGE',
  UPDATE_RELATIONSHIP_COUNT_RANGE: 'FILTERS/UPDATE_RELATIONSHIP_COUNT_RANGE',
  UPDATE_REPORT_FIELD: 'FILTERS/UPDATE_REPORT_FIELD',
  RESET_REPORT_FIELD: 'FILTERS/RESET_REPORT_FIELD',
  UPDATE_CUSTOM_SCORE_RANGE: 'FILTERS/UPDATE_CUSTOM_SCORE_RANGE',
  UPDATE_CREATED_AT_START_DATE: 'FILTERS/UPDATE_CREATED_AT_START_DATE',
  UPDATE_CREATED_AT_END_DATE: 'FILTERS/UPDATE_CREATED_AT_END_DATE',
  UPDATE_UPDATED_AT_START_DATE: 'FILTERS/UPDATE_UPDATED_AT_START_DATE',
  UPDATE_UPDATED_AT_END_DATE: 'FILTERS/UPDATE_UPDATED_AT_END_DATE',
  UPDATE_CLAIMED_AT_START_DATE: 'FILTERS/UPDATE_CLAIMED_AT_START_DATE',
  UPDATE_CLAIMED_AT_END_DATE: 'FILTERS/UPDATE_CLAIMED_AT_END_DATE',
  UPDATE_COMPLETENESS_RANGE: 'FILTERS/UPDATE_COMPLETENESS_RANGE',

  TOGGLE_LIST_SYNC: 'FILTERS/TOGGLE_LIST_SYNC',
  UPDATE_MAP_BOUNDS: 'FILTERS/UPDATE_MAP_BOUNDS',

  SET_LIMIT: 'FILTERS/SET_LIMIT',
  SET_OFFSET: 'FILTERS/SET_OFFSET',
  SET_ORDER: 'FILTERS/SET_ORDER',
  TOGGLE_ORDER: 'FILTERS/TOGGLE_ORDER',
  SET_PAGE: 'FILTERS/SET_PAGE',
  NEXT_PAGE: 'FILTERS/NEXT_PAGE',
  PREVIOUS_PAGE: 'FILTERS/PREVIOUS_PAGE',

  SET_COUNTRY_FOCUS: 'FILTERS/SET_COUNTRY_FOCUS',
  SET_PORTFOLIO: 'FILTERS/SET_PORTFOLIO',
  SET_PORTFOLIO_NO_RESET: 'FILTERS/SET_PORTFOLIO_NO_RESET',
  SET_SPOTTING_AREA: 'FILTERS/SET_SPOTTING_AREA',

  LOAD_PORTFOLIO: 'FILTERS/LOAD_PORTFOLIO',
}

export const mutations = {
  // General
  [MUTATION_TYPES.CLEAR] (state) {
    state.keywords = []
    state.paging.offset = null
    state.countryFocus = null
    state.portfolio = null
    state.dateRanges = {
      fundingDateStart: null,
      fundingDateEnd: null,
      foundingDateStart: null,
      foundingDateEnd: null,
      createdAtStart: null,
      createdAtEnd: null,
      updatedAtStart: null,
      updatedAtEnd: null,
    }

    state.totalFunding = {
      minTotalFunding: null,
      maxTotalFunding: null,
    }

    state.employeeRange = {
      employeeLowerBound: null,
      employeeUpperBound: null,
    }

    state.patentCountRange = {
      patentCountMin: null,
      patentCountMax: null,
    }

    state.relationshipCountRange = {
      relationshipCountMin: null,
      relationshipCountMax: null,
    }

    state.revenueRange = {
      revenueMin: null,
      revenueMax: null,
    }

    state.customScoreRange = {
      customScoreMin: null,
      customScoreMax: null,
    }

    state.completenessRange = {
      completenessMin: null,
      completenessMax: null,
    }

    state.spottingArea = null

    state.reportFilters = isProductPage() ? generateReportFiltersForProducts() : generateReportFiltersForActors()

    state.actorTypes = config.viewActorTypes || ['LegalEntity']
    state.paging.offset = null

    if (isProductPage()) {
      ls('productKeywords', [])
      ls('productReportFilters', null)
    } else {
      ls('actorKeywords', [])
      ls('actorReportFilters', null)
    }
    ls('portfolio', null)
    ls('spottingArea', null)
    ls('fundingDateStart', null)
    ls('fundingDateEnd', null)
    ls('createdAtStart', null)
    ls('createdAtEnd', null)
    ls('updatedAtStart', null)
    ls('updatedAtEnd', null)
    ls('minTotalFunding', null)
    ls('maxTotalFunding', null)
    ls('patentCountMin', null)
    ls('patentCountMax', null)
    ls('revenueMin', null)
    ls('revenueMax', null)
    ls('foundingDateStart', null)
    ls('foundingDateEnd', null)
    ls('mapBoundsTl', null)
    ls('mapBoundsBr', null)
    ls('reportFilters', null)
    ls('employeeLowerBound', null)
    ls('employeeUpperBound', null)
    ls('patentCountMin', null)
    ls('patentCountMax', null)
    ls('relationshipCountMin', null)
    ls('relationshipCountMax', null)
    ls('revenueMin', null)
    ls('revenueMax', null)
    ls('customScoreMin', null)
    ls('customScoreMax', null)
    ls('completenessMin', null)
    ls('completenessMax', null)
  },

  [MUTATION_TYPES.SET_ACTIVE_ACTOR_TYPES] (state, actorTypes) {
    // Only allow the actorTypes to which the user has access to
    var allowedActorTypes = config.viewActorTypes || ['LegalEntity']

    actorTypes = actorTypes.filter(value => allowedActorTypes.includes(value))

    var previousState = state.actorTypes

    if (actorTypes.length > 0) {
      state.actorTypes = actorTypes
    } else {
      state.actorTypes = []
      state.actorTypes = previousState
    }

    ls('actorTypes', state.actorTypes)
  },

  // Toggle the visibility of filters
  [MUTATION_TYPES.TOGGLE_FILTER] (state, isVisible) {
    state.filter.visible = isVisible
    ls('visible', isVisible)
  },

  // Set the Legend property value
  [MUTATION_TYPES.SET_LEGEND_PROPERTY] (state, legendConfig) {
    if (!legendConfig.property) {
      return
    }

    state.legendProperty = legendConfig.property

    // Change the color mapping accordingly
    const lookup = {}

    var items = legendConfig.items
    var hexColorNamesArray = config.hexColours ? Object.keys(config.hexColours) : []

    for (let i = 0; i < items.length && i < hexColorNamesArray.length; i++) {
      var value = items[i].id || items[i].value
      var label = items[i].name || items[i].label
      var colorName = 'hex0'

      if (legendConfig.property && config.legendMapping[legendConfig.property]) {
        colorName = config.legendMapping[legendConfig.property][value]
      }

      lookup[value] = { label: label, value: value, hex: config.hexColours[colorName], index: i, hexName: colorName }
    }

    // Make sure keywords are stored in the local storage that are properties
    // that are considered "isKeyword"
    saveKeywordsToLs(state.keywords)

    state.legendLookup = lookup

    if (legendConfig.noCache) {
      return
    }

    ls('legendProperty', state.legendProperty)
  },
  // Keywords
  [MUTATION_TYPES.ADD_KEYWORD] (state, keyword) {
    const existingEntry = state.keywords.find(k => k.value === keyword.value && k.facet === keyword.facet)

    // If the keyword is a taxonomy value, then add the i18n labels to the keyword object
    if (isTaxonomy(keyword.facet)) {
      var translations = getTranslationsForTaxonomyValue(keyword.facet, keyword.value) || {}

      keyword['translations'] = translations
    }

    if (!existingEntry) {
      state.keywords = [...state.keywords, keyword]
      state.paging.offset = null
    }

    saveKeywordsToLs(state.keywords)
  },
  [MUTATION_TYPES.REMOVE_KEYWORD] (state, keyword) {
    // Note: clear by object equality
    state.keywords = state.keywords.filter(k => k !== keyword)
    state.paging.offset = null

    saveKeywordsToLs(state.keywords)
  },
  [MUTATION_TYPES.CLEAR_BY_FACET] (state, facet) {
    state.keywords = state.keywords.filter(k => k.facet !== facet)
    state.paging.offset = null

    saveKeywordsToLs(state.keywords)
  },
  [MUTATION_TYPES.REFRESH_KEYWORDS] (state) {
    if (isProductPage()) {
      state.keywords = ls('productKeywords')
    } else {
      state.keywords = ls('actorKeywords')
    }
  },
  [MUTATION_TYPES.CLEAR_KEYWORDS] (state) {
    state.keywords = []
    state.paging.offset = null

    if (isProductPage()) {
      ls('productKeywords', [])
    } else {
      ls('actorKeywords', [])
    }
  },
  [MUTATION_TYPES.TOGGLE_KEYWORD] (state, keyword) {
    const filtered = state.keywords.filter(k => k.value !== keyword.value && k.facet !== keyword.facet)
    if (filtered.length === state.keywords.length) {
      state.keywords = [...state.keywords, keyword]
    } else {
      state.keywords = filtered
    }
    state.paging.offset = null

    saveKeywordsToLs(state.keywords)
  },
  [MUTATION_TYPES.SET_INDUSTRIES] (state, industries) {
    state.keywords = state.keywords.filter(k => k.facet !== 'industries')

    industries.forEach(industry => {
      mutations[MUTATION_TYPES.ADD_KEYWORD](state, { facet: 'industries', value: industry })
    })
    state.paging.offset = null
  },
  [MUTATION_TYPES.SET_DOMAINS] (state, domains) {
    state.keywords = state.keywords.filter(k => k.facet !== 'domain')

    domains.forEach(domain => {
      mutations[MUTATION_TYPES.ADD_KEYWORD](state, { facet: 'domain', value: domain })
    })
    state.paging.offset = null
  },
  [MUTATION_TYPES.SET_PRODUCT_FEATURES_A] (state, productFeaturesA) {
    state.keywords = state.keywords.filter(k => k.facet !== 'product_features_a')

    productFeaturesA.forEach(productFeatureA => {
      mutations[MUTATION_TYPES.ADD_KEYWORD](state, { facet: 'product_features_a', value: productFeatureA })
    })
    state.paging.offset = null
  },
  [MUTATION_TYPES.SET_PRODUCT_FEATURES_B] (state, productFeaturesB) {
    state.keywords = state.keywords.filter(k => k.facet !== 'product_features_b')

    productFeaturesB.forEach(productFeatureB => {
      mutations[MUTATION_TYPES.ADD_KEYWORD](state, { facet: 'product_features_b', value: productFeatureB })
    })
    state.paging.offset = null
  },
  [MUTATION_TYPES.SET_PRODUCT_FEATURES_C] (state, productFeaturesC) {
    state.keywords = state.keywords.filter(k => k.facet !== 'product_features_c')

    productFeaturesC.forEach(productFeatureC => {
      mutations[MUTATION_TYPES.ADD_KEYWORD](state, { facet: 'product_features_c', value: productFeatureC })
    })
    state.paging.offset = null
  },
  [MUTATION_TYPES.SET_NON_TAXONOMY_FACET] (state, { facet, value }) {
    state.keywords = state.keywords.filter(k => k.facet !== facet)

    mutations[MUTATION_TYPES.ADD_KEYWORD](state,
      {
        facet,
        value: value.value,
        label: value.label,
      })
    state.paging.offset = null
  },
  [MUTATION_TYPES.SET_BY_FACET] (state, { facet, values }) {
    state.keywords = state.keywords.filter(k => k.facet !== facet)

    // Accept single values
    if (!Array.isArray(values)) {
      values = values === null ? [] : [values]
    }

    values.forEach(v => {
      mutations[MUTATION_TYPES.ADD_KEYWORD](state, { facet, value: v })
    })
    state.paging.offset = null
  },

  // Announcement keywords
  [MUTATION_TYPES.ADD_ANNOUNCEMENT_KEYWORD] (state, keyword) {
    const existingEntry = state.announcementKeywords.find(k => k.value === keyword.value && k.facet === keyword.facet)

    if (!existingEntry) {
      state.announcementKeywords.push(keyword)
      state.paging.offset = null
    }

    saveAnnouncementKeywordsToLs(state.announcementKeywords)
  },
  [MUTATION_TYPES.REMOVE_ANNOUNCEMENT_KEYWORD] (state, keyword) {
    // Note: clear by object equality
    state.announcementKeywords = state.announcementKeywords.filter(k => k !== keyword)
    state.paging.offset = null

    saveAnnouncementKeywordsToLs(state.announcementKeywords)
  },
  [MUTATION_TYPES.CLEAR_ANNOUNCEMENT_KEYWORDS] (state) {
    state.announcementKeywords = []
    state.paging.offset = null

    ls('announcementKeywords', [])
  },

  /*[MUTATION_TYPES.SET_SOCIAL_MEDIA_MONITORING_HASHTAG] (state, hashtag) {
    state.socialMediaMonitoring.hashtag = hashtag
  },

  [MUTATION_TYPES.CLEAR_SOCIAL_MEDIA_MONITORING_HASHTAG] (state) {
    state.socialMediaMonitoring.hashtag = null
  },

  [MUTATION_TYPES.SET_SOCIAL_MEDIA_MONITORING_TWITTER] (state, twitter) {
    state.socialMediaMonitoring.twitter = twitter
  },

  [MUTATION_TYPES.CLEAR_SOCIAL_MEDIA_MONITORING_TWITTER] (state) {
    state.socialMediaMonitoring.twitter = null
  },

  [MUTATION_TYPES.SET_SOCIAL_MEDIA_MONITORING_EXCLUDED_TWITTERS] (state, excluded_twitter) {
    const existingEntry = state.socialMediaMonitoring.exclude_twitter.find(k => k == excluded_twitter)
    if (!existingEntry) {
      state.socialMediaMonitoring.exclude_twitter.push(excluded_twitter)
    }
  },

  [MUTATION_TYPES.CLEAR_SOCIAL_MEDIA_MONITORING_EXCLUDED_TWITTERS] (state) {
    state.socialMediaMonitoring.exclude_twitter = []
  },*/

  // Only works with legend and membership
  [MUTATION_TYPES.TOGGLE_LEGEND_ITEM] (state, { facet, value }) {
    let activeLegendItems = state.keywords.filter(k => k.facet === facet).length

    const index = state.keywords.findIndex(k => k.value === value && k.facet === facet)
    if (index > -1) {
      state.keywords = [...state.keywords.slice(0, index), ...state.keywords.slice(index + 1)]
      activeLegendItems--
    } else {
      state.keywords = [...state.keywords, { facet, value }]
      activeLegendItems++
    }

    saveKeywordsToLs(state.keywords)

    state.paging.offset = null
  },
  // Only works with stage
  [MUTATION_TYPES.TOGGLE_STAGE] (state, { facet, value }) {
    const index = state.keywords.findIndex(k => k.value === value && k.facet === facet)
    if (index > -1) {
      state.keywords = [...state.keywords.slice(0, index), ...state.keywords.slice(index + 1)]
      // activeStages--
    } else {
      state.keywords = [...state.keywords, { facet, value }]
      // activeStages++
    }

    saveKeywordsToLs(state.keywords)
    state.paging.offset = null
  },
  // Disable legend filter
  [MUTATION_TYPES.CLEAR_LEGEND_ITEMS] (state, value) {
    const facet = config.legendProperty
    state.keywords = state.keywords.filter(k => k.facet !== facet)
    state.paging.offset = null

    saveKeywordsToLs(state.keywords)
  },
  // Remove all legend items and add '-'
  [MUTATION_TYPES.EMPTY_LEGEND_ITEMS] (state, value) {
    const facet = config.legendProperty
    state.keywords = state.keywords.filter(k => k.facet !== facet).concat({ facet, value: '-' })
    state.paging.offset = null

    saveKeywordsToLs(state.keywords)
  },

  // Total funding range
  [MUTATION_TYPES.UPDATE_TOTAL_FUNDING_RANGE] (state, { start, end }) {
    state.totalFunding.minTotalFunding = start || null
    state.totalFunding.maxTotalFunding = end || null
    state.paging.offset = null

    ls('minTotalFunding', state.totalFunding.minTotalFunding)
    ls('maxTotalFunding', state.totalFunding.maxTotalFunding)
  },

  // Date ranges
  [MUTATION_TYPES.UPDATE_FUNDING_DATE_RANGE] (state, { start, end }) {
    state.dateRanges.fundingDateStart = start || null
    state.dateRanges.fundingDateEnd = end || null
    state.paging.offset = null

    ls('fundingDateStart', state.dateRanges.fundingDateStart)
    ls('fundingDateEnd', state.dateRanges.fundingDateEnd)
  },
  [MUTATION_TYPES.UPDATE_FOUNDED_DATE_RANGE] (state, { start, end }) {
    state.dateRanges.foundingDateStart = start || null
    state.dateRanges.foundingDateEnd = end || null
    state.paging.offset = null

    ls('foundingDateStart', state.dateRanges.foundingDateStart)
    ls('foundingDateEnd', state.dateRanges.foundingDateEnd)
  },
  [MUTATION_TYPES.UPDATE_CREATED_AT_START_DATE] (state, { start }) {
    state.dateRanges.createdAtStart = start || null

    ls('createdAtStart', state.dateRanges.createdAtStart)
  },
  [MUTATION_TYPES.UPDATE_CREATED_AT_END_DATE] (state, { end }) {
    state.dateRanges.createdAtEnd = end || null

    ls('createdAtEnd', state.dateRanges.createdAtEnd)
  },
  [MUTATION_TYPES.UPDATE_UPDATED_AT_START_DATE] (state, { start }) {
    state.dateRanges.updatedAtStart = start || null

    ls('updatedAtStart', state.dateRanges.updatedAtStart)
  },
  [MUTATION_TYPES.UPDATE_UPDATED_AT_END_DATE] (state, { end }) {
    state.dateRanges.updatedAtEnd = end || null

    ls('updatedAtEnd', state.dateRanges.updatedAtEnd)
  },
  // Claimed date range
  [MUTATION_TYPES.UPDATE_CLAIMED_AT_START_DATE] (state, { start }) {
    state.dateRanges.claimedAtStart = start || null

    ls('claimedAtStart', state.dateRanges.claimedAtStart)
  },
  [MUTATION_TYPES.UPDATE_CLAIMED_AT_END_DATE] (state, { end }) {
    state.dateRanges.claimedAtEnd = end || null

    ls('claimedAtEnd', state.dateRanges.claimedAtEnd)
  },
  // Employee range
  [MUTATION_TYPES.UPDATE_EMPLOYEE_RANGE] (state, { start, end }) {
    state.employeeRange.employeeLowerBound = start || null
    state.employeeRange.employeeUpperBound = end || null
    state.paging.offset = null

    ls('employeeLowerBound', state.employeeRange.employeeLowerBound)
    ls('employeeUpperBound', state.employeeRange.employeeUpperBound)
  },
  // Patent count range
  [MUTATION_TYPES.UPDATE_PATENT_COUNT_RANGE] (state, { start, end }) {
    state.patentCountRange.patentCountMin = start || null
    state.patentCountRange.patentCountMax = end || null
    state.paging.offset = null

    ls('patentCountMin', state.patentCountRange.patentCountMin)
    ls('patentCountMax', state.patentCountRange.patentCountMax)
  },
  // Relationship count range
  [MUTATION_TYPES.UPDATE_RELATIONSHIP_COUNT_RANGE] (state, { start, end }) {
    state.relationshipCountRange.relationshipCountMin = start || null
    state.relationshipCountRange.relationshipCountMax = end || null
    state.paging.offset = null

    ls('relationshipCountMin', state.relationshipCountRange.relationshipCountMin)
    ls('relationshipCountMax', state.relationshipCountRange.relationshipCountMax)
  },
  [MUTATION_TYPES.UPDATE_REVENUE_RANGE] (state, { start, end }) {
    state.revenueRange.revenueMin = start || null
    state.revenueRange.revenueMax = end || null

    ls('revenueMin', state.revenueRange.revenueMin)
    ls('revenueMax', state.revenueRange.revenueMax)
  },

  [MUTATION_TYPES.UPDATE_CUSTOM_SCORE_RANGE] (state, { start, end }) {
    state.customScoreRange.customScoreMin = start || null
    state.customScoreRange.customScoreMax = end || null

    ls('customScoreMin', state.customScoreRange.customScoreMin)
    ls('customScoreMax', state.customScoreRange.customScoreMax)
  },

  [MUTATION_TYPES.UPDATE_COMPLETENESS_RANGE] (state, { start, end }) {
    state.completenessRange.completenessMin = start || null
    state.completenessRange.completenessMax = end || null

    ls('completenessMin', state.completenessRange.completenessMin)
    ls('completenessMax', state.completenessRange.completenessMax)
  },

  [MUTATION_TYPES.RESET_REPORT_FIELD] (state, context) {
    if (!context.reportId) {
      return
    }

    // Capture passing the reportId as: "report_field_{id}" or "{id}"
    var reportFieldName = context.reportId

    if (!isNaN(context.reportId) || !context.reportId.includes('report_field_')) {
      reportFieldName = 'report_field_' + context.reportId
    }

    const reportField = getReportFieldByReportIdentifier(reportFieldName)

    const reportFieldType = context.type || reportField.type

    if (['options', 'multi_select', 'checkbox'].includes(reportFieldType)) {
      state.reportFilters = {
        ...state.reportFilters,
        ...{ [reportFieldName]: [] },
      }
    } else if (reportFieldType == 'number') {
      state.reportFilters = {
        ...state.reportFilters,
        ...{ [reportFieldName]: null },
      }
    } else if (reportFieldType == 'score') {
      state.reportFilters = {
        ...state.reportFilters,
        ...{ [reportFieldName + '_min']: null },
      }
      state.reportFilters = {
        ...state.reportFilters,
        ...{ [reportFieldName + '_max']: null },
      }
    }

    if (isProductPage()) {
      ls('productReportFilters', state.reportFilters)
    } else {
      ls('actorReportFilters', state.reportFilters)
    }
  },

  // Report fields
  [MUTATION_TYPES.UPDATE_REPORT_FIELD] (state, context) {
    // If the field is a range, then parse the value as a range
    if (!context.reportId) {
      return
    }

    // Capture passing the reportId as: "report_field_{id}" or "{id}"
    var reportFieldName = context.reportId

    if (!isNaN(context.reportId) || !context.reportId.includes('report_field_')) {
      reportFieldName = 'report_field_' + context.reportId
    }

    const reportField = getReportFieldByReportIdentifier(reportFieldName)

    const reportFieldType = context.type || reportField.type

    if (['options', 'multi_select', 'checkbox'].includes(reportFieldType)) {
      // If the field is a score, then parse the value is a single filter value and toggle the value as we would do with any other
      // property that has multiple values to choose from
      if (!state.reportFilters[reportFieldName]) {
        state.reportFilters = {
          ...state.reportFilters,
          ...{ [reportFieldName]: [] },
        }
      }

      const filterValue = context.value

      const index = state.reportFilters[reportFieldName].findIndex(k => k === filterValue)

      if (index > -1) {
        state.reportFilters = {
          ...state.reportFilters,
          ...{ [reportFieldName]: [...state.reportFilters[reportFieldName].slice(0, index), ...state.reportFilters[reportFieldName].slice(index + 1)] },
        }
      } else {
        state.reportFilters = {
          ...state.reportFilters,
          ...{ [reportFieldName]: [...state.reportFilters[reportFieldName], filterValue] },
        }
      }
    } else if (context.type == 'number' || context.type == 'date') {
      // With a number context, we expect the reportId to be the full report identifier (= report_field_{id}_(min|max))
      var field = context.reportId

      state.reportFilters = {
        ...state.reportFilters,
        ...{ [reportFieldName]: context.value },
      }
    } else if (context.type == 'score') {
      var reportFieldName = 'report_field_' + context.reportId

      state.reportFilters = {
        ...state.reportFilters,
        ...{ [reportFieldName + '_min']: context.start },
      }
      state.reportFilters = {
        ...state.reportFilters,
        ...{ [reportFieldName + '_max']: context.end },
      }
    }

    if (isProductPage()) {
      ls('productReportFilters', state.reportFilters)
    } else {
      ls('actorReportFilters', state.reportFilters)
    }
  },

  // Map bounds
  [MUTATION_TYPES.UPDATE_MAP_BOUNDS] (state, { tl, br }) {
    state.mapBounds.tl = tl
    state.mapBounds.br = br
    state.paging.offset = null

    ls('mapBoundsTl', state.mapBounds.tl)
    ls('mapBoundsBr', state.mapBounds.br)
  },
  [MUTATION_TYPES.TOGGLE_LIST_SYNC] (state) {
    state.listSync = !state.listSync
  },

  // Paging
  [MUTATION_TYPES.SET_LIMIT] (state, limit) {
    // Stay between 10 and 100
    limit = Math.max(10, Math.min(100, parseInt(limit)))
    state.paging.limit = limit

    // Align offset to a page
    state.paging.offset = Math.floor(state.paging.offset / limit) * limit
  },
  [MUTATION_TYPES.SET_OFFSET] (state, offset) {
    state.paging.offset = offset
  },
  [MUTATION_TYPES.SET_ORDER] (state, order) {
    state.paging.order = order
    state.paging.offset = null
  },
  [MUTATION_TYPES.TOGGLE_ORDER] (state, order) {
    const shouldReverse = ['product_relationship_count', 'relationship_count', 'vacancy_count', 'patent_count', 'completeness'].includes(order)

    if (shouldReverse === true) {
      if (state.paging.order === order) {
        state.paging.order = null
      } else if (state.paging.order === '-' + order) {
        state.paging.order = order
      } else {
        state.paging.order = '-' + order
      }
    } else {
      if (state.paging.order === order) {
        state.paging.order = '-' + order
      } else if (state.paging.order === '-' + order) {
        state.paging.order = null
      } else {
        state.paging.order = order
      }
    }
    state.paging.offset = null
  },
  [MUTATION_TYPES.SET_PAGE] (state, page) {
    state.paging.offset = page * state.paging.limit
  },
  [MUTATION_TYPES.NEXT_PAGE] (state) {
    state.paging.offset += state.paging.limit
  },
  [MUTATION_TYPES.PREVIOUS_PAGE] (state) {
    state.paging.offset -= state.paging.limit
  },

  [MUTATION_TYPES.SET_COUNTRY_FOCUS] (state, value) {
    state.countryFocus = value && value !== '-' ? value : null
  },

  'FILTERS/SET_PORTFOLIO' (state, value) {
    // Reset all filters
    // this has been commented out because it causes problems when the user wants to filter on the quick search filters on the home page
    // mutations[MUTATION_TYPES.CLEAR](state)

    state.portfolio = value && value !== '-' ? value : null

    ls('portfolio', state.portfolio)
  },

  'FILTERS/SET_SPOTTING_AREA' (state, value) {
    // Reset all filters
    mutations[MUTATION_TYPES.CLEAR](state)

    state.spottingArea = value || null

    ls('spottingArea', state.spottingAreaX)
  },

  'FILTERS/SET_PORTFOLIO_NO_RESET' (state, value) {
    state.portfolio = value && value !== '-' ? value : null

    ls('portfolio', state.portfolio)
  },

  'FILTERS/LOAD_PORTFOLIO' (state, portfolio) {
    // Reset all filters
    mutations[MUTATION_TYPES.CLEAR](state)

    // Apply portfolio keywords
    Object.keys(portfolio.filters).forEach(facet => {
      if (portfolio.filters[facet]) {
        const values = typeof portfolio.filters[facet] === 'string' ? portfolio.filters[facet].split(',') : portfolio.filters[facet]

        if (facet === 'actor_type') {
          mutations[MUTATION_TYPES.SET_ACTIVE_ACTOR_TYPES](state, values)
        } else {
          mutations[MUTATION_TYPES.SET_BY_FACET](state, { facet, values })
        }
      }
    })

    // Country focus
    mutations[MUTATION_TYPES.SET_COUNTRY_FOCUS](state, portfolio.filters['country-focus'])

    // Date ranges
    mutations[MUTATION_TYPES.UPDATE_FUNDING_DATE_RANGE](state, {
      start: portfolio.filters.fundingDateStart,
      end: portfolio.filters.fundingDateEnd,
    })

    mutations[MUTATION_TYPES.UPDATE_CREATED_AT_START_DATE](state, {
      start: portfolio.filters.createdAtStart,
    })

    mutations[MUTATION_TYPES.UPDATE_CREATED_AT_END_DATE](state, {
      end: portfolio.filters.createdAtEnd,
    })

    mutations[MUTATION_TYPES.UPDATE_UPDATED_AT_START_DATE](state, {
      start: portfolio.filters.updatedAtStart,
    })

    mutations[MUTATION_TYPES.UPDATE_UPDATED_AT_END_DATE](state, {
      end: portfolio.filters.updatedAtEnd,
    })

    mutations[MUTATION_TYPES.UPDATE_FOUNDED_DATE_RANGE](state, {
      start: portfolio.filters.foundingDateStart,
      end: portfolio.filters.foundingDateEnd,
    })

    // Employee range
    mutations[MUTATION_TYPES.UPDATE_EMPLOYEE_RANGE](state, {
      start: portfolio.filters.employeeLowerBound,
      end: portfolio.filters.employeeUpperBound,
    })

    // Patent count range
    mutations[MUTATION_TYPES.UPDATE_PATENT_COUNT_RANGE](state, {
      start: portfolio.filters.patentCountMin,
      end: portfolio.filters.patentCountMax,
    })

    // Relationship count range
    mutations[MUTATION_TYPES.UPDATE_RELATIONSHIP_COUNT_RANGE](state, {
      start: portfolio.filters.relationshipCountMin,
      end: portfolio.filters.relationshipCountMax,
    })

    // Turn over range
    mutations[MUTATION_TYPES.UPDATE_REVENUE_RANGE](state, {
      start: portfolio.filters.revenueMin,
      end: portfolio.filters.revenueMax,
    })

    // Custom score range
    mutations[MUTATION_TYPES.UPDATE_CUSTOM_SCORE_RANGE](state, {
      start: portfolio.filters.customScoreMin,
      end: portfolio.filters.customScoreMax,
    })

    // Completeness range
    mutations[MUTATION_TYPES.UPDATE_COMPLETENESS_RANGE](state, {
      start: portfolio.filters.completenessMin,
      end: portfolio.filters.completenessMax,
    })

    // Total funding range
    mutations[MUTATION_TYPES.UPDATE_TOTAL_FUNDING_RANGE](state, {
      start: portfolio.filters.minTotalFunding,
      end: portfolio.filters.maxTotalFunding,
    })

    // Report filters, add the manually instead of via the state
    // because we need to transform the properties in several ways otherwise
    var possibleReportFilters = isProductPage() ? generateReportFiltersForProducts() : generateReportFiltersForActors()

    for (var key in possibleReportFilters) {
      if (portfolio.filters.hasOwnProperty(key)) {
        var reportInfo = getReportFieldByReportIdentifier(key)

        if (reportInfo && reportInfo.type == 'options') {
          var value = portfolio.filters[key]

          state.reportFilters[key] = value.split(',')
        } else {
          state.reportFilters[key] = portfolio.filters[key]
        }
      }
    }

    if (isProductPage()) {
      ls('productReportFilters', state.reportFilters)
    } else {
      ls('actorReportFilters', state.reportFilters)
    }

    // Save the keywords in local storage
    saveKeywordsToLs(state.keywords)
  },
}

export const getters = {
  activeAnnouncementKeywords (state) {
    return state.announcementKeywords
  },
  currentPage (state) {
    return Math.floor(state.paging.offset / state.paging.limit)
  },
  pagingWithoutDefaults ({ paging }) {
    const withoutDefaults = {}
    if (paging.limit !== DEFAULT_LIMIT) {
      withoutDefaults.limit = paging.limit
    }
    if (paging.offset) {
      withoutDefaults.offset = paging.offset
    }
    if (paging.order) {
      withoutDefaults.order = paging.order
    }
    return withoutDefaults
  },
  actorTypeFilterObject (state) {
    if (!state.actorTypes) {
      return
    }

    if ([SiteTemplate.NEW_SIMPLIFIED, SiteTemplate.SIMPLIFIED].includes(config.siteTemplate)) {
      return { actor_type: state.actorTypes.filter(type => type !== 'Product') }
    }
    return { actor_type: state.actorTypes }
  },
  spottingAreaFilterObject (state) {
    return { 'spotting_area': state.spottingArea }
  },
  // This filter object represents all filters in the explore view
  sideFilterObject (state) {
    return Object.assign(
      getters.filterObjectKeywords(state),
      state.dateRanges,
      state.employeeRange,
      state.patentCountRange,
      state.relationshipCountRange,
      state.revenueRange,
      state.customScoreRange,
      state.completenessRange,
      state.totalFunding,
      state.reportFilters,
      getters.spottingAreaFilterObject(state),

      // This will replace 'LegalEntity' by null
      getters.actorTypeFilterObject(state),
    )
  },

  // This filter object represents the portfolio and actor_type
  portfolioFilterObject (state) {
    return Object.assign(
      { portfolio: state.portfolio },
      getters.actorTypeFilterObject(state),
    )
  },

  // Most dashboards are based on this filter object
  baseFilterObject (state) {
    // Merge portfolio and active filters if both are present
    var filters = state.portfolio ? getters.portfolioFilterObject(state) : {}

    return stringifyProperties(
      Object.assign(filters, getters.sideFilterObject(state)),
    )
  },

  // Filter objects for specific use cases
  mapFilterObject (state) {
    return stringifyProperties(Object.assign(
      getters.baseFilterObject(state),
      { 'legendProperty': state.legendProperty },
      state.mapBounds,
    ))
  },
  listFilterObject (state) {
    return stringifyProperties(Object.assign(
      getters.baseFilterObject(state),
      { 'legendProperty': state.legendProperty },
      state.paging,
      state.listSync ? state.mapBounds : {},
      { 'country-focus': state.countryFocus },
    ))
  },
  fundingListFilterObject (state) {
    return stringifyProperties(Object.assign(
      getters.baseFilterObject(state),
      state.paging,
      state.listSync ? state.mapBounds : {},
    ))
  },
  newsFilterObject (state) {
    return stringifyProperties(Object.assign(
      getters.baseFilterObject(state),
      { 'legendProperty': state.legendProperty },
      { 'country-focus': state.countryFocus },
    ))
  },
  exploreFilterObject (state) {
    return stringifyProperties(Object.assign(
      getters.baseFilterObject(state),
      getters.pagingWithoutDefaults(state),
    ))
  },
  spreadsheetFilterObject (state) {
    return stringifyProperties(Object.assign(
      getters.filterObjectKeywords(state),
      state.dateRanges,
      state.employeeRange,
      state.patentCountRange,
      state.relationshipCountRange,
      state.revenueRange,
      state.customScoreRange,
      state.completenessRange,
      state.totalFunding,
      state.reportFilters,
      { portfolio: state.portfolio },
    ))
  },
  filterObjectKeywords (state) {
    // Add all keywords to the base query
    return convertToFilterObject(state.keywords)
  },
  selectedIndustries (state) {
    return state.keywords.filter(k => k.facet === 'industries').map(k => k.value)
  },
  activeLegendItems (state) {
    return state.keywords.filter(k => k.facet === config.legendProperty).map(k => k.value).concat('')
  },
  activeKeywords (state) {
    return state.keywords
      .filter(isKeyword)
  },
  activePortfolio (state) {
    return (window.PORTFOLIOS || []).find(p => p.id === state.portfolio) || {}
  },
  // Available legend item values
  stages (state, getters) {
    var items = config.availableLegendItems && config.availableLegendItems['stage'] ? config.availableLegendItems['stage'] : []
    if (getters.isMember) {
      items.concat('Other')
    }
    return items
  },
  categories (state, getters) {
    // Only show categories based on what the selected actor types are
    const actorTypes = state.actorTypes

    var filteredCategories = []
    var allCategories = getters.categoryLabels

    actorTypes.forEach(function (actorType) {
      filteredCategories = filteredCategories.concat(allCategories[actorType])
    })

    var availableCategories = config.availableLegendItems && config.availableLegendItems['category'] ? config.availableLegendItems['category'] : []
    filteredCategories = filteredCategories.filter(category => availableCategories.includes(category))

    return _unique(filteredCategories)
  },
  domains (state) {
    return config.availableLegendItems && config.availableLegendItems['domains'] ? config.availableLegendItems['domains'] : []
  },
  productFeatureA (state) {
    return config.availableLegendItems && config.availableLegendItems['product_features_a'] ? config.availableLegendItems['product_features_a'] : []
  },
  productFeatureB (state) {
    return config.availableLegendItems && config.availableLegendItems['product_feature_b'] ? config.availableLegendItems['product_feature_b'] : []
  },
  productFeatureC (state) {
    return config.availableLegendItems && config.availableLegendItems['product_feature_c'] ? config.availableLegendItems['product_feature_c'] : []
  },
  industries (state) {
    return config.availableLegendItems && config.availableLegendItems['industries'] ? config.availableLegendItems['industries'] : []
  },
  activities (state, getters) {
    return config.availableLegendItems && config.availableLegendItems['activities'] ? config.availableLegendItems['activities'] : []
  },
  expertises (state, getters) {
    // var items = config.availableLegendItems && config.availableLegendItems['expertise'] ? config.availableLegendItems['expertise'] : []
    var items = getters.expertiseLabels || []

    return items
  },
  motivations (state, getters) {
    // var items = config.availableLegendItems && config.availableLegendItems['motivation'] ? config.availableLegendItems['motivation'] : []
    var items = getters.motivationLabels || []

    return items
  },
  businessAspects (state, getters) {
    // var items = config.availableLegendItems && config.availableLegendItems['business_aspect'] ? config.availableLegendItems['business_aspect'] : []
    if (getters.businessAspectTaxonomyEnabled == false) {
      return []
    }

    var items = getters.businessAspectLabels || []

    return items
  },
  readinessLevels (state, getters) {
    if (getters.readinessTaxonomyEnabled == false) {
      return []
    }
    // var items = config.availableLegendItems && config.availableLegendItems['readiness_level'] ? config.availableLegendItems['readiness_level'] : []
    var items = getters.readinessLevelLabels || []

    return items
  },
  processSteps (state, getters) {
    if (getters.projectStepsTaxonomyEnabled == false) {
      return []
    }
    // var items = config.availableLegendItems && config.availableLegendItems['readiness_level'] ? config.availableLegendItems['readiness_level'] : []
    var items = getters.processStepLabels || []

    return items
  },
  memberships (state, getters) {
    var items = config.availableLegendItems && config.availableLegendItems['membership'] ? config.availableLegendItems['membership'] : []

    if (getters.isMember) {
      items.concat('Other')
    }

    return items
  },
  totalFundingRange (state) {
    return state.totalFunding
  },
  fundingType (state) {
    return state.fundingRoundType
  },
}

// check if homepage to prevent a problem with ?_ga=xxx urls
let actorKeywords = window.location.pathname === '/dashboards/home' ? [] : keywordsFromQueryParams(window.location.search.substring(1))
let productKeywords = []
let actorReportFilters = generateReportFiltersForActors()
let productReportFilters = generateReportFiltersForProducts()

export function initActorTypes (keywords, config) {
  var allowedActorTypes = config.viewActorTypes || ['LegalEntity']

  const actorType = keywords.filter(k => k.facet === 'actor_type')

  if (actorType === undefined || actorType.length == 0) {
    return allowedActorTypes
  }

  var actorTypes = []

  actorType.forEach(function (actorTypeEntry) {
    actorTypes.push(actorTypeEntry.value)
  })

  return actorTypes.filter(val => allowedActorTypes.includes(val))
}

export function initPortfolios (keywords) {
  var portfolio = keywords.filter(k => k.facet === 'portfolio') || {}

  var portfolios = []

  portfolio.forEach(function (portfolioEntry) {
    portfolios.push(portfolioEntry.value)
  })

  return portfolios
}

function isProductPage () {
  return window.location.pathname.toLowerCase().indexOf('product') >= 0 || (window.location.pathname.toLowerCase().indexOf('actors/') >= 0 && window.isProductPage)
}

export default {
  state: {
    filter: {
      visible: ls('visible') || true,
    },
    legendProperty: ls('legendProperty'),
    legendLookup: {},
    countryFocus: null,
    get keywords () {
      if (isProductPage()) {
        return (productKeywords || ls('productKeywords') || []).filter(isKeyword)
      } else {
        return (actorKeywords || ls('actorKeywords') || []).filter(isKeyword)
      }
    },
    set keywords (keywordsToSet) {
      if (isProductPage()) {
        productKeywords = keywordsToSet
      } else {
        actorKeywords = keywordsToSet
      }
    },
    announcementKeywords: (actorKeywords || ls('announcementKeywords')).filter(isKeyword),
    portfolio: initPortfolios(actorKeywords) || ls('portfolio') || null,
    spottingArea: ls('spottingArea') || null,
    actorTypes: ls('actorTypes') || ['LegalEntity'],
    socialMediaMonitoring: {
      exclude_twitter: [],
      hashtag: null,
      twitter: null,
    },
    dateRanges: {
      fundingDateStart: ls('fundingDateStart') || null,
      fundingDateEnd: ls('fundingDateEnd') || null,
      foundingDateStart: ls('foundingDateStart') || null,
      foundingDateEnd: ls('foundingDateEnd') || null,
      createdAtStart: ls('createdAtStart') || null,
      createdAtEnd: ls('createdAtEnd') || null,
      updatedAtStart: ls('updatedAtStart') || null,
      updatedAtEnd: ls('updatedAtEnd') || null,
      claimedAtStart: ls('claimedAtStart') || null,
      claimedAtEnd: ls('claimedAtEnd') || null,
    },
    employeeRange: {
      employeeLowerBound: ls('employeeLowerBound') || null,
      employeeUpperBound: ls('employeeUpperBound') || null,
    },
    patentCountRange: {
      patentCountMin: ls('patentCountMin') || null,
      patentCountMax: ls('patentCountMax') || null,
    },
    relationshipCountRange: {
      relationshipCountMin: ls('relationshipCountMin') || null,
      relationshipCountMax: ls('relationshipCountMax') || null,
    },
    revenueRange: {
      revenueMin: ls('revenueMin') || null,
      revenueMax: ls('revenueMax') || null,
    },
    customScoreRange: {
      customScoreMin: ls('customScoreMin') || null,
      customScoreMax: ls('customScoreMax') || null,
    },
    completenessRange: {
      completenessMin: ls('completenessMin') || null,
      completenessMax: ls('completenessMax') || null,
    },
    mapBounds: {
      tl: ls('mapBoundsTl') || null,
      br: ls('mapBoundsBr') || null,
    },
    totalFunding: {
      minTotalFunding: ls('minTotalFunding') || null,
      maxTotalFunding: ls('maxTotalFunding') || null,
    },
    get reportFilters () {
      if (isProductPage()) {
        return productReportFilters || ls('productReportFilters') || []
      } else {
        return actorReportFilters || ls('actorReportFilters') || []
      }
    },
    set reportFilters (reportFiltersToSet) {
      if (isProductPage()) {
        productReportFilters = reportFiltersToSet
      } else {
        actorReportFilters = reportFiltersToSet
      }
    },
    listSync: false,
    paging: {
      limit: DEFAULT_LIMIT,
      offset: 0,
      order: isProductPage() ? (productKeywords.find(k => k.facet === 'order') || {}).value || null : (actorKeywords.find(k => k.facet === 'order') || {}).value || null,
    },
  },
  getters,
  mutations,
}

function generateReportFiltersForProducts () {
  if (!window.config.report_settings || !window.config.report_settings.length) {
    return {}
  }

  const reportSettingsForProducts = window.config.report_settings.filter(setting => setting.enabled_for_product)
  return generateReportFilters(reportSettingsForProducts)
}

export function convertToFilterObject (filters) {
  return filters.reduce((list, item) => {
    const facet = facetToFilter(item.facet)
    if (list[facet]) {
      list[facet].push(item.value)
    } else {
      list[facet] = [item.value]
    }
    return list
  }, {})
}

export function facetToFilter (f) {
  switch (f) {
    case 'tag':
      return 'tags'
    case 'has received investments from':
      return 'invested_by'
    case 'locality':
      return 'address.locality'
    case 'country':
      return 'address.country'
    case 'enabler':
      return 'accelerated_by'
    case 'custom area':
      return 'custom_filters'
  }
  return f
}

// Returns a copy of an object, ready to querify
export function stringifyProperties (filters) {
  filters = Object.assign({}, filters)
  Object.keys(filters).forEach(function (key) {
    if (!filters[key]) {
      delete filters[key]
    } else if (Array.isArray(filters[key])) {
      filters[key] = filters[key].join(',')
    }
  })
  return filters
}

export function keywordsFromQueryParams (query) {
  var match
  var output = []
  var pl = /\+/g
  var search = /([^&=]+)=?([^&]*)/g

  var decode = function (s) {
    return decodeURIComponent(s.replace(pl, ' '))
  }

  // Prepare the industries / domains
  // var industries = window.ALL_TAXONOMIES ? window.ALL_TAXONOMIES.industries : []
  // var domains = window.ALL_TAXONOMIES ? window.ALL_TAXONOMIES.domains : []

  // eslint-disable-next-line no-cond-assign
  while (match = search.exec(query)) {
    output = output.concat(
      decode(match[2])
        .split(',')
        .map((value) => {
            const facet = decode(match[1])

            if (facet === 'geoid') {
              value = parseInt(value)

              if (!window.GEONAMES || !window.GEONAMES.find(g => g.value === value)) {
                // return;
              }
            }

            // These if statements are to fix a URL translation bug that occurs when exporting Office documents to PDF
            // Some parts are being double encoded
            if (value && isString(value) && value.includes('%2520')) {
              var whitespace = '%2520'
              var reWs = new RegExp(whitespace, 'g')

              value = value.replace(reWs, '%20')
            }

            if (value && isString(value) && value.includes('%20')) {
              var doubleWhitespace = '%20'
              var reDoubleWs = new RegExp(doubleWhitespace, 'g')

              value = value.replace(reDoubleWs, ' ')
            }

            if (value && isString(value) && value.includes('%26')) {
              var doubleWhitespace = '%26'
              var reDoubleWs = new RegExp(doubleWhitespace, 'g')

              value = value.replace(reDoubleWs, '&')
            }

            if (value && isString(value) && value.includes('%2F')) {
              var doubleWhitespace = '%2F'
              var reDoubleWs = new RegExp(doubleWhitespace, 'g')

              value = value.replace(reDoubleWs, '/')
            }

            if (value && isString(value) && value.includes('%2B')) {
              var doubleWhitespace = '%2B'
              var reDoubleWs = new RegExp(doubleWhitespace, 'g')

              value = value.replace(reDoubleWs, ';')
            }

            if (isTaxonomy(facet)) {
              var translations = getTranslationsForTaxonomyValue(facet, value) || {}

              return { facet, value, translations }
            }

            return { facet, value }
          },
        ).filter(Boolean),
    )
  }

  return output
}

export function saveAnnouncementKeywordsToLs (keywords) {
  var lsKeywords = keywords.filter(isKeyword)

  ls('announcementKeywords', lsKeywords)
}

export function saveKeywordsToLs (keywords) {
  var lsKeywords = keywords.filter(isKeyword)

  if (isProductPage()) {
    ls('productKeywords', lsKeywords)
  } else {
    ls('actorKeywords', lsKeywords)
  }
}

export function isTaxonomy (property) {
  if (!property) {
    return false
  }

  return taxonomyOptions.includes(property) || actorTaxonomyProperties.includes(property)
}

export function isKeyword (f) {
  var facetAllowed = ![
    // Tricky query params
    'invitation',
    'redirect_path',
    'email',
    'token',
    'portfolios',
    'portfolio',
    'spottingArea',
    'undefined',
    'messageType',
    'Fbclid',
    'mc_eid',
    'mc_cid',
    'utm_term',
    'utm_source',
    'utm_content',
    'utm_medium',
    'utm_campaign',
    'utm_medium',
    '_hsmi',
    '_hsenc',
    'utm_content',
    'utm_source',
    'cn',
    'fbclid',
    'refsrc',
    'platform',
    'topic',
    'attachment_id',

    // External query params
    'external',
    'redirect_uri',
    'actor_name',
    'actor_id',

    // Other filters
    'limit',
    'offset',
    'order',
    'br',
    'tl',
    'foundingDateEnd',
    'foundingDateStart',
    'fundingDateEnd',
    'fundingDateStart',
    'createdAtStart',
    'createdAtEnd',
    'updatedAtStart',
    'updatedAtEnd',
    'employeeUpperBound',
    'employeeLowerBound',
    'patentCountMin',
    'patentCountMax',
    'relationshipCountMin',
    'relationshipCountMax',
    'customScoreMin',
    'customScoreMax',
    'revenueMin',
    'revenueMax',
    'reportFilters',
    'actorReportFilters',
    'productReportFilters',
    'maxTotalFunding',
    'minTotalFunding',
    'scoreMax',
    'scoreMin',
    'topic',
    'topic_name',
    'heartbeat_date',
  ].includes(f.facet)

  return facetAllowed && f.value != '-'
}
