<template>
  <div class="range-slider">
    <div class="range-slider__info">
      <div class="range-slider__title">{{ label }}</div>
      <div class="range-slider__range-label">{{ rangeLabel }}</div>
    </div>
    <div ref="slider"></div>
  </div>
</template>

<script>
import _isFinite from 'lodash/isFinite'
import noUiSlider from 'nouislider'

import { significantDigits } from './slider'

function toFloat (n) {
  n = parseInt(n, 10) || null
  return _isFinite(n) ? parseFloat(n.toFixed(5)) : null
}

export default {
  props: {
    label: null,
    max: null,
    min: null,
    step: null,
    lazy: {
      default: false,
      type: Boolean
    },
    options: {
      default: () => ({})
    },
    modelValue: {
      default: () => ({ start: null, end: null })
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      // Live values
      start: null,
      end: null
    }
  },
  computed: {
    state () {
      return this.$store.state.filters.employeeRange;
    },
    uiState () {
      return [this.state.employeeLowerBound || this.opts.range.min, this.state.employeeUpperBound || this.opts.range.max];
    },
    rangeLabel () {
      const startValue = this.state.employeeLowerBound || this.start;
      const endValue = this.state.employeeUpperBound || this.end;

      if (startValue <= this.opts.range.min || startValue == null) {
        if (endValue >= this.opts.range.max || endValue == null) {
          return 'Any size';
        }
        return `max. ${endValue}`;
      }
      if (endValue >= this.opts.range.max || endValue == null) {
        return `min. ${startValue}`;
      }

      return `${startValue} - ${endValue}`;
    },
    opts () {
      return Object.assign({
        connect: true,
        step: parseInt(this.step || 1, 10),
        range: {
          min: parseInt(this.min || 0, 10),
          max: parseInt(this.max || 1000, 10)
        }
      }, this.options)
    }
  },
  mounted () {
    noUiSlider.create(this.$refs.slider, Object.assign(this.opts, {
      start: this.uiState
    }))

    if (this.lazy) {
      this.$refs.slider.noUiSlider.on('set', (a, handleId, [start, end]) => {
        start = start > this.opts.range.min ? significantDigits(start) : null
        end = end < this.opts.range.max ? significantDigits(end) : null
        this.$emit('update:modelValue', { start, end })
      })
    }

    this.$refs.slider.noUiSlider.on('slide', (a, handleId, [start, end]) => {
      this.start = significantDigits(start)
      this.end = significantDigits(end)
      if (!this.lazy) {
        start = this.start > this.opts.range.min ? this.start : null
        end = this.end < this.opts.range.max ? this.end : null
        this.$emit('update:modelValue', { start, end })
      }
    })
  },
  watch: {
    modelValue (v) {
     if (v) {
        this.start = this.state.minTotalFunding;
        this.end = this.state.maxTotalFunding;
        this.$refs.slider.noUiSlider.set(this.uiState, true);
      }
    }
  }
}
</script>
