<template>
  <table class="top-keywords-list">
    <tbody>
    <tr v-for="keyword in sortedKeywords">
      <td class="keyword__label">
        <rich-tag class="keyword__label__tag" :tag="keyword"/>
      </td>
      <td class="keyword__icon">
        <icon :name="iconForType(keyword.type)" size="14"/>
      </td>
      <td>
        <div class="keyword__count">
          <div class="keyword__count__bar" :style="{width: `${keyword.count / highestCount * 100}%`}">
            {{ keyword.count }}
          </div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>
</template>

<script>
  import RichTag from '../Tag/RichTag.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: ['keywords'],
    computed: {
      sortedKeywords () {
        return this.keywords.filter(k => !['loc'].includes(k.type)).sort((a, b) => b.count - a.count).slice(0, 100)
      },
      highestCount () {
        let highestCount = 0

        for (const keyword of this.keywords) {
          if (keyword.count > highestCount) {
            highestCount = keyword.count
          }
        }

        return highestCount
      },
    },
    methods: {
      iconForType (type) {
        switch (type) {
          case 'person':
            return 'tag'
          case 'org':
            return 'building'
          case 'loc':
            return 'location-pin'
          default:
            return 'tag'
        }
      }
    },
    components: {
      RichTag,
    },
  })
</script>

<style lang="scss" scoped>
  .top-keywords-list {
    display: flex;
    justify-content: center;
  }

  .keyword__label {
    text-align: right;
  }

  .keyword__label__tag {
    margin-bottom: 0;
  }

  .keyword__icon {
    padding: 0 10px;
  }

  .keyword__count {
    position: relative;
    width: 400px;
    height: 20px;
    line-height: 20px;
  }

  .keyword__count__bar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: #9B9FAD;
    color: #fff;
    text-align: right;
    padding: 0 5px;
    box-sizing: border-box;
    min-width: fit-content;
  }

  .keyword__label {
    .keyword__label__add {
      visibility: hidden;
    }

    &:hover {
      .keyword__label__add {
        visibility: visible;
      }
    }
  }
</style>

<style lang="scss">
  .top-keywords-list .keyword__icon {
    .svg-icon--notification-label path {
      fill: #9B439C;
    }

    .svg-icon--account path {
      fill: #DC9A56;
    }

    .svg-icon--map-marker path {
      fill: #59B89A;
    }
  }
</style>
