import { taxonomyMapping } from '../store/modules/taxonomies'
import { camelize } from '../util/string.ts'
import { getReportFieldById } from '../util/helpers'

export function humanize (prop) {
  // TODO: when numbers are passed, this function will error
  if (!prop || !prop[0]) {
    return ''
  }

  var propLc = prop.toLowerCase()

  // Edge case for technologies
  if (propLc == 'technologies') {
    propLc = 'technology'
  }

  if (propLc && propLc.indexOf('report_field_') >= 0) {
    var reportFieldId = propLc.replace('report_field_', '')
    var reportField = getReportFieldById(reportFieldId)

    if (!reportField) {
      return 'report'
    }

    return reportField.label
  }

  if (propLc == 'crm stage') {
    propLc = 'funnel_stage'
  } else if (propLc == 'process steps') {
    propLc = 'process_steps'
  } else if (propLc == 'readiness levels') {
    propLc = 'readiness_levels'
  } else if (propLc == 'growth stage') {
    propLc = 'stage'
  }

  // TODO - if we have application wide i18N, we'll need to transfer this function to the TranslationsMixin
  if (['activities', 'technology', 'domains', 'product_features_a', 'product_features_b', 'product_features_c', 'industries', 'subindustries', 'expertises', 'membership', 'category', 'funnel_stage', 'stage', 'process_steps', 'readiness_levels'].includes(propLc)) {
    var taxonomyProperty = taxonomyMapping()[propLc] || propLc // i.e. category to categories
    taxonomyProperty = camelize(taxonomyProperty) // i.e. funnel_stage to funnelStage
    var taxonomyAlias = taxonomyProperty + 'Alias'

    if (window.config[taxonomyAlias] && window.config[taxonomyAlias].length > 0) {
      var alias = window.config[taxonomyAlias]

      prop = alias
    }
  }

  // Edge case - TODO - this should be kept in the constants/config.js, we have the mapping there for columns to display names for the filter controls (filterControlOptions)
  if (prop === 'Technologies') {
    return 'Technology'
  } else if (prop === 'relationship_count') {
    return 'Total Connections'
  } else if (prop === 'product_relationship_count') {
    return 'Product Count'
  } else if (prop === 'address.geoid') {
    return 'City'
  } else if (prop === 'address.country') {
    return 'Country'
  } else if (prop === 'current_employees.size_code') {
    return 'Employee size'
  } else if (prop === 'industry_codes') {
    return 'Main NACE code'
  } else if (prop === 'address.admin1_name') {
    return 'State/Province'
  } else if (prop === 'address.admin2_name') {
    return 'Region'
  }

  return propertyDisplayNames[prop] || (prop[0].toUpperCase() + prop.slice(1).replace(/_/g, ' '))
}

/* export function humanizeSuggestion (property) {
  // Make a human readable suggestion label for the property of the suggestion
  if (property == 'subsidiaries') {
    return 'Offices';
  }

  if (filterableRelationships.includes(property)) {
    var config = RELATION_TYPES.filter(config => config.name == property);

    if (config) {
      return config[0]['label'];
    }
  }

  return humanize(property);
} */

export const propertyDisplayNames = {
  embedded_video_url: 'Embedded video URL',
  linkedin: 'LinkedIn',
  lastFundingType: 'Last funding type',
  lastFundingDate: 'Last funding date',
  lastFundingRaised: 'Last funding raised',
  url: 'URL',
  is_sme: 'Is SME',
  vat_number: 'VAT number',
  funnel_stage: 'CRM Stage',
  sustainability_goal: 'Sustainability Goal',
}

export const exportableProperties = [
  'activities',
  'actor_type',
  'category',
  'city',
  'custom_score',
  'claimed',
  // 'claimable',
  'company_number',
  'company_type',
  'completeness',
  'country',
  'crunchbase',
  'description',
  'domains',
  'elastic_id',
  'employees',
  'email',
  'total_contactable_contributors',
  'contact_email_list',
  'embedded_video_url',
  'facebook',
  'founding_date',
  'funnel_stage',
  'funding_structure',
  'industries',
  'instagram',
  'juridical_situation',
  'jurisdiction',
  'legal_city',
  'legal_country',
  'legal_form',
  'legal_number',
  'legal_street',
  'legal_zip',
  'linkedin',
  'maturity',
  'membership',
  'name',
  'not_for_profit',
  'industry_codes',
  'number',
  'phone',
  'product',
  'stage',
  'status',
  'stock_symbol',
  'street',
  'subIndustries',
  'sustainability_goal',
  'tags',
  'private_tags',
  'seo_keywords',
  'technology',
  'total_funding',
  'twitter',
  'type',
  'url',
  'vacancy_count',
  'vat_number',
  'vimeo',
  'youtube',
  'zip',
]

if (window.hasMentions) {
  exportableProperties.push(
    'wins',
    'losses',
    'mentions',
  )
}

export const reconcileableProperties = [
// Description
  {
    'property': 'product',
    'mergeable': false,
  },
  {
    'property': 'description',
    'mergeable': true,
  },
  {
    'property': 'category',
    'mergeable': false,
  },
  {
    'property': 'membership',
    'mergeable': false,
  },
  {
    'property': 'tags',
    'mergeable': true,
  },
  {
    'property': 'private_tags',
    'mergeable': true,
  },
  // Skills & expertise
  {
    'property': 'expertises',
    'mergeable': true,
  },
  {
    'property': 'motivations',
    'mergeable': true,
  },

  // Activities
  {
    'property': 'activities_description',
    'mergeable': true,
  },
  {
    'property': 'additional_information',
    'mergeable': true,
  },
  {
    'property': 'type',
    'mergeable': false,
  },
  {
    'property': 'activities',
    'mergeable': true,
  },
  {
    'property': 'technology',
    'mergeable': true,
  },
  {
    'property': 'industries',
    'mergeable': true,
  },
  /* {
    "property" : "subIndustries",
    "mergeable": true,
  }, */
  {
    'property': 'domains',
    'mergeable': true,
  },
  {
    'property': 'sustainability_goal',
    'mergeable': true,
  },
  {
    'property': 'aspects',
    'mergeable': true,
  },
  {
    'property': 'business_aspect_description',
    'mergeable': true,
  },
  {
    'property': 'business_aspects',
    'mergeable': true,
  },
  {
    'property': 'process_step_description',
    'mergeable': true,
  },
  {
    'property': 'process_steps',
    'mergeable': true,
  },
  {
    'property': 'readiness_levels',
    'mergeable': true,
  },

  // Digital presence
  {
    'property': 'url',
    'mergeable': false,
  },
  {
    'property': 'other_urls',
    'mergeable': true,
  },
  {
    'property': 'twitter',
    'mergeable': true,
  },
  {
    'property': 'facebook',
    'mergeable': true,
  },
  {
    'property': 'crunchbase',
    'mergeable': false,
  },
  {
    'property': 'linkedin',
    'mergeable': false,
  },
  {
    'property': 'reddit',
    'mergeable': false,
  },

  // Contact
  {
    'property': 'email',
    'mergeable': false,
  },
  {
    'property': 'address',
    'mergeable': false,
  },

  // Administration
  {
    'property': 'jurisdiction',
    'mergeable': false,
  },
  {
    'property': 'company_number',
    'mergeable': false,
  },
  {
    'property': 'vat_number',
    'mergeable': false,
  },
  {
    'property': 'employees',
    'mergeable': false,
  },
  {
    'property': 'exit_date',
    'mergeable': false,
  },
  {
    'property': 'exit_round',
    'mergeable': false,
  },
  {
    'property': 'founding_date',
    'mergeable': false,
  },
  {
    'property': 'stop_date',
    'mergeable': false,
  },
  {
    'property': 'market',
    'mergeable': true,
  },

  // Financials
  {
    'property': 'funding_structure',
    'mergeable': true,
  },
  {
    'property': 'total_funding',
    'mergeable': false,
  },
  {
    'property': 'funding_rounds',
    'mergeable': true,
  },
]
