<template>
  <div class="legend-container">
    <loading v-if="loading"></loading>
    <div v-if="!loading" v-for="(legendItem, index) in mapLegend" :key="'legend' + index"
      class="legend-container__item">
      <div class="circle" :style="{backgroundColor: legendColor(legendItem.value)}">&nbsp;</div>
      <span style="float: left; display: inline-block">{{
          (legendItem.value ? getLabelForTaxonomyValue(legendProperty, legendItem.value) : legendItem.label) || legendItem.label
        }}</span>
    </div>
  </div>
</template>

<script lang="ts">
  import Loading from './../../components/Dashboard/ConceptMap/Loading.vue'
  import FiltersMixin from '../../util/FiltersMixin.js'
  import TranslationsMixin from '../../util/TranslationsMixin.js'

  export default {
    name: 'map-legend',
    props: {
      companies: {
        type: Array,
      },
      clusters: {
        type: Array
      },
      loading: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {}
    },
    computed: {
      legendItems() {
        return [...this.getLegendItems(this.legendProperty),
          {
            label: this.$t('actor_detail_other'),
            value: null,
          },
        ]
      },
      legendProperty() {
        return this.$store.state.config.legendProperty
      },
      mapLegend: function () {
        if (this.clusters) {
          let visibleColorsOnMap = []
          this.clusters.forEach(cluster => {
            visibleColorsOnMap = visibleColorsOnMap.concat(Object.keys(cluster.legend))
          })
          return this.legendItems.filter(legendItem => visibleColorsOnMap.includes(String(legendItem.value)))
        } else if (this.companies) {
          const legends = this.companies.map(company => company.legend)
          const visibleColorsOnMap = legends.filter((legend, i) => legends.findIndex(existingLegend => existingLegend === legend) === i)
          return this.legendItems.filter(legendItem => visibleColorsOnMap.includes(legendItem.value))
        }
      },
    },
    methods: {
      legendColor(legendValue) {
        const colorConfig = this.$store.state.filters.legendLookup

        if (colorConfig[legendValue] && colorConfig[legendValue].hex) {
          return colorConfig[legendValue].hex
        }

        return '#bbb'
      },
    },
    mounted() {
    },
    mixins: [FiltersMixin, TranslationsMixin],
    components: {
      Loading
    },
  }
</script>
<style lang="scss" scoped>
  .legend-container {
    display: flex;
    position: absolute;
    bottom: 0;
    height: 3rem;
    overflow-y: auto;
    max-width: 100%;
    padding-left: 16px;


    .legend-container__item {
      font-size: 12px;
      margin-right: 10px;
      display: flex;
      white-space: nowrap;
      margin-top: 16px;

      &:last-child {
        margin-right: 0;
      }

      .circle {
        display: inline-block;
        float: left;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        margin: 1px 3px 0 0;
      }
    }
  }
</style>
