<template>
  <div class="side-panel__actor-preview">
    <ds-button
      class="side-panel__actor-preview__header__close-button"
      icon="remove"
      variant="minimal"
      @click="hidePreview"
    />
    <div class="card__general">
      <div class="card__ribbon" v-if="ribbonClass" :style="ribbonStyle"></div>
      <div class="card__title side-panel__card__title">{{ company.name }}</div>
      <div class="card__sub-title">{{ `${company.type || ''} ${company.category || ''} ${industryString ? '- ' + industryString : ''}` }}</div>
      <div class="card__logo">
        <avatar :src="companyLogoUrl" :alt="company.name" variant="border"></avatar>
      </div>
    </div>

    <div class="card__cover" :style="coverStyling"></div>

    <div class="card__info">
      <table class="table table--preview" v-if="$route.path.includes('landscape')">
        <tbody>
          <tr>
            <td>Scores</td>
            <td class="text-right" v-for="date in companyScoresDates" v-text="toMonthString(date)" :title="date"></td>
          </tr>
          <tr v-for="(row, index) in companyScoresTable" :class="{ 'text-bold': !index }">
            <td v-text="row.score"></td>
            <td class="text-right" v-for="cell in row.values" v-text="cell"></td>
          </tr>
        </tbody>
      </table>
      <div class="card__description">{{ shortDescription }}</div>
      <div class="card-actor-info__links">
        <div v-if="company.url">
          <icon name="world" size="small" />
          <a :href="company.url" target="_blank">{{ company.url }}</a>
        </div>
        <actor-urls :urls="company.other_urls" />
        <div v-if="company.email">
          <icon name="email-outline" size="small" />
          <a :href="`mailto:${company.email}`" target="_blank">{{company.email}}</a>
        </div>
        <div v-if="company.phone">
          <icon name="phone" size="small" />
          <a :href="`tel:${company.phone}`" target="_blank">{{company.phone}}</a>
        </div>
      </div><br>
      <card-info-social :company="company" iconSize="14" />
      <p class="card__info__tags" v-if="company.tags">
        <keyword v-for="(tag, index) in company.tags" :key="index" :label="tag" />
      </p>
      <template v-if="isPublic && (isOwner || isTeamMember)">
        <p class="card__info__tags" v-if="company.private_tags">
          <keyword v-for="(tag, index) in company.private_tags" :key="index" :label="tag" />
        </p>
      </template>
      <div class="card__info__columns" v-if="showColumns">
        <div class="card__info__columns__column card__info__columns__employees" v-if="company.employees && company.employees.length > 0">
          ~ {{company.employees[company.employees.length - 1].upper_bound}}<br>
          employees
        </div>
        <div class="card__info__columns__column card__info__columns__revenue" v-if="company.financials && company.financials.length > 0">
          {{companyRevenue}} <br>
          revenue
        </div>
      </div>
      <div class="side-panel__actor-preview__footer">
        <ds-button
          tabindex="-1"
          v-if="$store.getters.views.includes('actors/detail')"
          icon="chevron-right"
          label="View full page"
          variant="secondary"
          @click="hidePreview"
          :to="'/actors/' + company.id"
        />
        <ds-button
          tabindex="-1"
          v-if="userCanClaim"
          icon="chevron-right"
          label="Claim"
          variant="secondary"
          @click="showClaimModal"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ActorUrls from '../ActorInfo/ActorUrls.vue'
  import Avatar from '../Avatar/Avatar.vue'
  import Card from '../../components/Card/Card.vue'
  import CardInfoSocial from '../../components/Card/CardInfoSocial.vue'
  import Keyword from '../../components/Keyword/Keyword.vue'
  import CompanyMixin from '../../util/CompanyMixin'
  import numeral from 'numeral'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import KEYCODES from '../../constants/keycodes'
  import MODAL_IDS from '../../constants/modal-ids'

  import { toMonthString } from '../../util/date'

  export default {
    name: 'actor-preview',
    computed: {
      isPublic () {
        return this.$store.getters.isPublic
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      isTeamMember () {
        return this.$store.getters.isTeamMember
      },
      company () {
        return this.$store.getters.previewedActor
      },
      companyRevenue () {
        if (!this.company.financials) return

        const lastYear = this.company.financials[this.company.financials.length - 1]
        if (!lastYear) return

        if (!lastYear.operating_income) return 'Undisclosed'

        return `${numeral(lastYear.operating_income).format('$ 0.00 a')}`
      },
      companyScoresAll () {
        // This one is NOT based on the fetched preview
        const actor = this.$store.state.actors.previewedActor
        return (actor.country_scores || []).slice(0, 5)
      },
      companyScoresDates () {
        return this.companyScoresAll.map(c => c.date).reverse()
      },
      companyScoresTable () {
        const scores = ['current', 'business_size', 'social', 'seo', 'traction']
        const scoreMapping = {
          current: 'DataScouts',
          business_size: 'Business',
          social: 'Social',
          seo: 'SEO',
          traction: 'Traction'
        }
        return scores.map(score => ({
          score: scoreMapping[score],
          values: this.companyScoresAll.map(s => s[score + '_score']).reverse()
        }))
      },
      showColumns () {
        return this.company &&
          this.company.employees &&
          this.company.employees.length > 0 &&
          this.company.financials &&
          this.company.financials.length > 0
      },
      coverStyling () {
        return { backgroundImage: `url(${this.websiteScreenshot})` }
      }
    },
    methods: {
      toMonthString,
      hidePreview () {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      showClaimModal () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CLAIM)
      }
    },
    created () {
      window.addEventListener('keyup', (e) => {
        if (e.keyCode === KEYCODES.ESC) {
          this.hidePreview()
        }
      })
    },
    mixins: [CompanyMixin],
    components: {
      ActorUrls,
      Avatar,
      Card,
      CardInfoSocial,
      Keyword
    }
  }
</script>
