<template>
    <div class="row">
        <div class="buzz__individual-chart-container " :class="hasFilterTable ? 'col-xs-10 col-md-10' : 'col-xs-12 col-md-12'">
          <div class="card" :ref="chartContainerReferences">
            <div class="row buzz__individual-chart-header">
              <div class="buzz__chart-keyword-container">
                <keyword-with-facet
                v-for="(activeKeyword, index) in activeKeywords"
                :key="index"
                :label="activeKeyword.name"
                :model-value="activeKeyword"
                removeable
                @remove="removeKeyword(activeKeyword)"
                />
              </div>
              <div style="flex-grow: 1"></div>
              <div class="buzz__chart-timerange-table-filter" v-if="!isMediaBuzz">
                <div class="buzz__individual-table-filter-container">
                  <ds-button variant="primary" size="small"
                             :icon="expandedTables.includes('Timerange') ? 'chevron-up' : 'chevron-down'"
                             :label="activeTimeframe.length ? 'Time ( ' + activeTimeframe + ' )' : 'Time'"
                             @click="collapseTable('Timerange')"
                             class="buzz__individual-table-section-header"/>
                  <div v-if="expandedTables.includes('Timerange')"
                       class="buzz__individual-table-section-item-container"
                       v-click-away="collapseRange">
                    <li :title="item.name" v-for="(item, index) in keywordTimeRange">
                      <ds-button variant="minimal" size="small" :label="item.text"
                                 @click="setTimeRange(item)"
                                 class="buzz__individual-table-section-item"
                                 :class="{'active-table-item': isTimeRangeActive(item)}"/>
                    </li>
                  </div>
                </div>
              </div>
              <div v-if="activeKeywords.length > 0"
                   class="buzz__chart-delete-all-container">
                <a @click="resetFilters" style="text-decoration: none;">Delete All</a>
              </div>
              <ds-button variant="default" size="small" icon="arrow-compress"
                         class="buzz__individual-chart-header-collapse-button"
                         @click="collapseChart"/>
            </div>
            <template v-if="chart == 'topics'">
              <bubble-chart :isInsideChartCloseup="isInsideChartCloseup" :chartData="chartData" />
            </template>
            <template v-else-if="chart == 'timeline'">
              <timeline-chart :isInsideChartCloseup="isInsideChartCloseup" :chartData="chartData" />
            </template>
             <template v-else-if="chart == 'influencers'">
              <influencer-chart :isInsideChartCloseup="isInsideChartCloseup" :chartData="chartData" />
            </template>
            <!--<template v-else="chart == 'segments'"> test</template> -->
          </div>
        </div>
        <BuzzTable class="col-xs-2 col-md-2" v-show="hasFilterTable"/>
    </div>
</template>

<script>
import BubbleChart from '../Chart/BubbleChart.vue'
import TimelineChart from '../Chart/TimelineChart.vue'
import InfluencerChart from '../Chart/InfluencerChart.vue'
import OpenTabs from '../Tabs/OpenTabs.vue'
import Badge from '../Badge/Badge.vue'
import KeywordWithFacet from '../Keyword/KeywordWithFacet.vue'
import BuzzTable from '../RankedList/BuzzTable.vue'

import { MUTATION_TYPES as BUZZ_MUTATION_TYPES } from '../../store/modules/buzz'

import { MUTATION_TYPES as MEDIA_BUZZ_MUTATION_TYPES } from '../../store/modules/mediabuzz.js'

export default {
  data () {
    return {
      keywordTimeRange: {
        day: { name:'day', text: 'Today', type: 'time' },
        week: { name:'week', text: 'Past week', type: 'time' },
        month: { name:'month', text: 'Past month', type: 'time' },
      },
      activeTimeframe: '',
      expandedTables: [],
    }
  },
  props: {
    chart: String,
    chartData: {
      type: Object,
      default: () => {}
    },
    isInsideChartCloseup: {
      type: Boolean,
      default: false
    },
    hasFilterTable: {
      type: Boolean,
      default: true
    }
  },
  emits: ['update-data', 'collapse'],
  computed: {
    filters () {
      return this.$store.state.filters
    },
    chartContainerReferences () {
      switch(this.chart) {
        case 'topics':
          return 'bubbleChartContainer'
          break;
        case 'timeline':
          return 'timelineChartContainer'
          break;
        case 'influencers':
          return 'influencerChartContainer'
          break;
      }
    },
    activeKeywords () {
      return this.isMediaBuzz ? this.$store.state.mediabuzz.filters : this.$store.state.buzz.filters
    },
    isMediaBuzz () {
      return this.$route.params.panel === 'media-buzz'
    },
    selectedMutationTypes () {
      return this.isMediaBuzz ? MEDIA_BUZZ_MUTATION_TYPES : BUZZ_MUTATION_TYPES
    },
  },
  methods: {
    removeKeyword (data) {
      this.$store.commit(this.selectedMutationTypes.REMOVE_BUZZ_FILTER, data)
      this.$emit('update-data')
    },
    setTimeRange (filterValue) {
      this.$store.commit(this.selectedMutationTypes.UPDATE_BUZZ_FILTER, {
        name: filterValue.name,
        facet: 'time',
        value: filterValue.name,
      })
      this.$emit('update-data')

      // After clicking in one of the options we collapse the dropdown
      this.collapseTable('Timerange')
    },
    collapseChart () {
      this.$bus.emit('chartExpanded', false)
      this.$emit('collapse');
    },
    isTimeRangeActive (item) {
      return this.activeKeywords.findIndex(parameter => parameter.facet == 'time' && item.name == parameter.value) > -1
    },
    collapseRange () {
      // The click away method doesn't allow to pass a parameter
      // Because of that we cannnot use the "collapseTable" method to colapse the dropdown
      var index = this.expandedTables.indexOf('Timerange')
      if (index > - 1) {
        this.expandedTables.splice(index, 1)
      }
    },
    collapseTable (table) {
      var index = this.expandedTables.indexOf(table)
      if (index > - 1) {
        this.expandedTables.splice(index, 1)
      } else {
        this.expandedTables.push(table)
      }
    },
    resetFilters () {
      this.$store.commit(this.selectedMutationTypes.RESET_BUZZ_FILTERS)
      this.$emit('update-data')
    }
  },
  components: {
    Badge,
    BubbleChart,
    TimelineChart,
    InfluencerChart,
    OpenTabs,
    KeywordWithFacet,
    BuzzTable
  }
}
</script>
