import { createJSONRequest, createResource } from './create-request'

export const EcosystemReports = createResource('/api/settings/reports')

export const updateReportField = (reportTemplateId, reportFieldId, fieldUpdate) => createJSONRequest({
  url: `/api/settings/reports/${reportTemplateId}/fields/${reportFieldId}`,
  method: 'POST',
  data: fieldUpdate
})

export const addReportField = (reportTemplateId, reportField) => createJSONRequest({
  url: `/api/settings/reports/${reportTemplateId}/fields`,
  method: 'POST',
  data: reportField
})

export const deleteReportField = (reportTemplateId, reportFieldId) => createJSONRequest({
  url: `/api/settings/reports/${reportTemplateId}/fields/${reportFieldId}`,
  method: 'DELETE',
})

export const updateFieldsOrder = (reportTemplateId, order) => createJSONRequest({
  url: `/api/settings/reports/${reportTemplateId}/fields-order`,
  method: 'POST',
  data: order
})

export const countSubmittedReports = (reportTemplateId) => createJSONRequest({
  url: `/api/settings/reports/${reportTemplateId}/submit-count`,
  method: 'GET'
})
