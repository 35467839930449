export const CONCEPT_SEARCH_REPORT_TYPES = [
  'market_trends',
  'sdg',
  'technology_radar',
]

export const CONCEPT_SEARCH_REPORT_TYPES_LABELS = {
  market_trends: 'PESTEL Analysis',
  sdg: 'Sustainable Development Goals',
  technology_radar: 'Technology Radar',
}

export const CONCEPT_SEARCH_DICTIONARY_GROUPS = {
  empty: 'global mega trends',
  market_trends: 'pestel',
  sdg: 'sustainability',
  technology_radar: 'emerging technologies',
}
