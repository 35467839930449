<template>
  <div class="collapsable-tab__content-container">
     <template v-if="modifiedActor.actor_type === 'Person'">
      <form-group :label="$t('edit_actor_person_seniority')">
        <dropdown placeholder="" :options="seniorityOptions" v-model="seniority"/>
      </form-group>

      <form-group :label="$t('edit_actor_person_position')">
        <ds-input placeholder="" v-model="title"/>
      </form-group>

      <form-group :label="$t('edit_actor_person_department')">
        <dropdown placeholder="" :options="departmentOptions" v-model="role"/>
      </form-group>

      <form-group :label="expertisesLabel" :errors="errors.expertises" v-if="expertisesOptions.length" :class="{'actor-edit__private-field': isPrivateField('expertises')}">
        <dropdown placeholder="" multiple :options="expertisesOptions" v-model="modifiedActor.expertises" :valueIsOption="true"/>
      </form-group>

      <form-group :label="motivationsLabel" :errors="errors.motivations" v-if="motivationsOptions.length" :class="{'actor-edit__private-field': isPrivateField('motivations')}">
        <dropdown placeholder="" multiple :options="motivationsOptions" v-model="modifiedActor.motivations" :valueIsOption="true"/>
      </form-group>
    </template>
  </div>
</template>

<script>

import ConfigMixin from '../../../util/ConfigMixin.js'
import Dropdown from '../../Dropdown/Dropdown.vue'
import FormGroup from '../../Form/FormGroup.vue'
import { departmentOptions, seniorityOptions } from '../../../constants/config.js'

export default {
  name: 'actor-edit-skills-and-expertise',
  props: {
    modifiedActor: Object,
    errors: Object,
  },
  data () {
    return {
      seniorityOptions,
      departmentOptions,
    }
  },
  computed: {
    config () {
      return this.$store.state.config
    },
    onboardingQuestions () {
      return this.config.onboarding ? this.config.onboarding.questions : {}
    },
    expertisesOptions () {
      return this.expertiseValuesAndLabels
    },
    motivationsLabel () {
      return this.getTaxonomyAlias('motivations', false, this.$t('actor_edit_motivations'))
    },
    motivationsOptions () {
      return this.motivationValuesAndLabels
    },
    expertisesLabel () {
      return this.getTaxonomyAlias('expertises', false, this.$t('actor_edit_expertises'))
    },
    processStepsLabel () {
      return this.getTaxonomyAlias('processSteps', false, this.$t('actor_edit_process_steps'))
    },
    businessAspectsLabel () {
      return this.getTaxonomyAlias('businessAspects', false,this.$t('actor_edit_business_aspects'))
    },
    readinessLevelsLabel () {
      return this.getTaxonomyAlias('readinessLevels', false, this.$t('actor_edit_readiness_level'))
    },
    businessAspectOptions () {
      return this.businessAspectValuesAndLabels
    },
    readinessLevelsOptions () {
      return this.readinessLevelValuesAndLabels
    },
    processStepsOptions () {
      return this.processStepValuesAndLabels
    },
    seniority: {
      set (v) {
        if (!this.modifiedActor.employment) {
          this.modifiedActor.employment = { seniority: '', role: '', title: '' }
        }

        if (!this.modifiedActor.employment.seniority) {
          this.modifiedActor.employment.seniority = ''
        }

        this.modifiedActor.employment.seniority = v
      },
      get () {
        return this.modifiedActor.employment && this.modifiedActor.employment.seniority
      }
    },
    role: {
      set (v) {
        if (!this.modifiedActor.employment) {
          this.modifiedActor.employment = { seniority: '', role: '', title: '' }
        }

        if (!this.modifiedActor.employment.role) {
          this.modifiedActor.employment.role = ''
        }

        this.modifiedActor.employment.role = v
      },
      get () {
        return this.modifiedActor.employment && this.modifiedActor.employment.role
      }
    },
    title: {
      set (v) {
        if (!this.modifiedActor.employment) {
          this.modifiedActor.employment = { seniority: '', role: '', title: '' }
        }

        if (!this.modifiedActor.employment.title) {
          this.modifiedActor.employment.title = ''
        }

        this.modifiedActor.employment.title = v
      },
      get () {
        return this.modifiedActor.employment && this.modifiedActor.employment.title
      }
    }
  },
  methods: {
    isPrivateField (field) {
      return this.isPublisherEnabled && !this.publicFields.includes(field)
    },
  },
  components: {
    FormGroup,
    Dropdown,
  },
  mixins: [ConfigMixin]
}
</script>
