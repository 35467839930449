<template>
  <div class="datalab">
    <component :is="panel"/>
  </div>
</template>

<script lang="ts">
import Changes from '../components/ActorChanges/ActorChanges.vue'
import Completion from '../components/Datalab/Completion.vue'
import Curation from '../components/Datalab/Curation.vue'
import Sources from '../components/Datalab/Sources.vue'
import Spreadsheet from '../components/Spreadsheet/Spreadsheet.vue'
import Suggestions from '../components/Datalab/Suggestions.vue'
import Taxonomies from '../components/Datalab/Taxonomies.vue'
import Catalogue from '../components/Datalab/Catalogue.vue'
import Relationships from '../components/Datalab/Relationships.vue'

import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../store/modules/ui.js'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'datalab',
  components: {
    Changes,
    Completion,
    Curation,
    Sources,
    Spreadsheet,
    Suggestions,
    Taxonomies,
    Catalogue,
    Relationships
  },
  computed: {
    panel() {
      return this.$route.params.panel || 'completion'
    },
  },
  methods: {
    routeLeaveCheck(to, from, next) {
      if (
          this.$store.state.ui.preventDatalabNavigation &&
          !window.confirm('Do you really want to leave? you have unsaved changes!')
      ) {
        next(false)
      } else {
        if (this.$store.state.ui.preventDatalabNavigation !== false) {
          // We HAVE to check before setting, or otherwise we end up in an infinite loop (this gets called recursively, somehow...)
          this.$store.commit(UI_MUTATION_TYPES.SET_PREVENT_DATALAB_NAVIGATION, false)
        }

        next()
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    if (!window.config.views || window.config.views.includes('datalab')) {
      next()
    } else {
      next('/')
    }
  },
  beforeRouteUpdate(to, from, next) {
    if (this !== undefined) { // How does that even happen?
      this.routeLeaveCheck(to, from, next)
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this !== undefined) {
      this.routeLeaveCheck(to, from, next)
    }
  },
})
</script>

<style>
.datalab {
  height: 100%;
}
</style>
