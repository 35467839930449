<template>
  <div class="main">
    <div class="buttons">
      <ds-button v-if="mode === 'add'" icon="plus" :disabled="disabled" @click="addArticle"/>
      <ds-button v-if="mode === 'remove'" icon="trash" :disabled="disabled" @click="removeArticle"/>
    </div>
    <div>
      <div class="header">
        <div class="title">
          {{ title }}
          <small class="url" v-if="url">
            &nbsp;
            <a :href="url" target="_blank" rel="noopener noreferrer" v-tooltip.top="'Open'">
              <icon name="external-link-alt" size="14"/>
            </a>
          </small>
          <small class="url" v-if="internalUrl">
            &nbsp;
            <a :href="internalUrl" target="_blank" rel="noopener noreferrer">(read more)</a>
          </small>
        </div>
        <div class="date">{{ toDateString(date * 1000) }}</div>
      </div>
      <div>{{ body }}</div>
    </div>
  </div>
</template>

<script>
import { toDateString } from '../../../util/date'

export default {
  props: {
    mode: {
      type: String,
      validator: val => ['add', 'remove'].includes(val),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: String,
    title: String,
    body: String,
    url: String,
    internalUrl: {
      type: String,
      default: '',
    },
    date: Number,
  },
  methods: {
    toDateString,
    addArticle() {
      this.$emit('add', this.id)
    },
    removeArticle() {
      this.$emit('remove', this.id)
    },
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/_variables.scss";

.main {
  display: flex;
  margin-bottom: 20px;
}

.buttons {
  margin-right: 10px;
}

.header {
  display: flex;
  margin-bottom: 10px;

  .title {
    font-weight: bold;
    margin-right: auto;
  }

  .date {
    margin-left: 20px;
    color: $color-text-grey-light;
    white-space: nowrap;
  }

  .url {
    white-space: nowrap;
  }
}
</style>
