<template>
  <ul class="pagination">
    <li :class="{disabled:!currentPage}" @click.prevent="fetch(0)">«</li>
    <li :class="{disabled:!currentPage}" @click.prevent="fetch(currentPage - 1)">‹</li>
    <li v-if="currentPage - 2 > 0 && lastPage > 4" class="disabled">...</li>

    <li v-if="currentPage - 4 >= 0 && currentPage == lastPage" @click.prevent="fetch(currentPage - 4)">
      <div class="pagination__label">{{ currentPage - 3}}</div>
    </li>
    <li v-if="currentPage - 3 >= 0 && currentPage >= lastPage - 1" @click.prevent="fetch(currentPage - 3)">
      <div class="pagination__label">{{ currentPage - 2}}</div>
    </li>
    <li v-if="currentPage - 2 >= 0" @click.prevent="fetch(currentPage - 2)">
      <div class="pagination__label">{{ currentPage - 1}}</div>
    </li>
    <li v-if="currentPage - 1  >= 0" @click.prevent="fetch(currentPage - 1)">
      <div class="pagination__label">{{ currentPage}}</div>
    </li>
    <li class="active">
      <div class="pagination__label">{{ currentPage +  1}}</div>
    </li>
    <li v-if="currentPage + 1 <= lastPage" @click.prevent="fetch(currentPage + 1)">
      <div class="pagination__label">{{ currentPage +  2}}</div>
    </li>
    <li v-if="currentPage + 2 <= lastPage" @click.prevent="fetch(currentPage + 2)">
      <div class="pagination__label">{{ currentPage +  3}}</div>
    </li>
    <li v-if="currentPage + 3 <= lastPage && currentPage <= 1" @click.prevent="fetch(currentPage + 3)">
      <div class="pagination__label">{{ currentPage +  4}}</div>
    </li>
    <li v-if="currentPage + 4 <= lastPage && currentPage == 0" @click.prevent="fetch(currentPage + 4)">
      <div class="pagination__label">{{ currentPage +  5}}</div>
    </li>

    <li v-if="currentPage + 2 < lastPage && lastPage > 4">...</li>
    <li :class="{disabled:currentPage == lastPage}" @click.prevent="fetch(currentPage + 1)">›</li>
    <li :class="{disabled:currentPage == lastPage}" @click.prevent="fetch(lastPage)">»</li>
  </ul>
</template>

<script>
import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters'
import { ACTION_TYPES as FILTERS_ACTION_TYPES } from '../../store/modules/actors'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'pagination',
  props: ['paging'],
  computed: {
    // Zero based index of current page
    currentPage () {
      return this.$store.getters.currentPage
    },
    // Zero based index of last page
    lastPage () {
      return Math.ceil(this.$store.getters.lastPageOffset / this.$store.state.filters.paging.limit)
    }
  },
  methods: {
    fetch(p) {
      if (p < 0 || p > this.lastPage) {
        return
      }
      this.$store.commit(FILTERS_MUTATION_TYPES.SET_PAGE, p)
      // Because of the watcher in Explore.vue, we don't need to call dispatch
      // this.$store.dispatch(FILTERS_ACTION_TYPES.FETCH_ACTORS_LIST, this.$store.getters.listFilterObject)
    }
  }
})
</script>
