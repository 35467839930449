<template>
  <div v-if="expand || list.length === 1">
    <div v-for="url in list" >
      <icon name="world" size="small" />
      <a :href="url" target="_blank">{{ url }}</a><br>
    </div>
  </div>
  <div v-else-if="list.length">
    <icon name="world" size="small" />
    <button type="button" class="a subtle-button" @click="expand = !expand">Show {{ list.length }} more urls...</button>
  </div>
</template>

<script>
export default {
  props: ['urls'],
  data () {
    return {
      expand: false
    }
  },
  computed: {
    list () {
      return Array.isArray(this.urls) ? this.urls : []
    }
  }
}
</script>
