<template>
  <div class="fixed-heading actor-scores" v-if="company && company.id">
    <div class="heading">
      <ds-button
        :to="'/actors/' + company.id"
        label="Profile"
        variant="secondary"
        class="pull-right"
      />
      <h1>Growth hacking dashboard</h1>
      <div class="card__title" style="margin:0">{{ company.name }}</div>
    </div>
    <div class="scrollable has-padding">
      <div>
        <h2>Score indicators</h2>
        <br>
        <score-indicators :actor-id="company.id" :actor-name="company.name" />
      </div>
    </div>
  </div>
  <div v-else class="actor-detail actor-detail--empty">
    <h1 v-if="loading">Loading...</h1>
    <h1 v-else>We couldn't find this member</h1>
  </div>
</template>

<script>
import moment from 'moment'
import numeral from 'numeral'

import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../store/modules/actors'
import CompanyMixin from '../util/CompanyMixin'
import ActorInfo from '../components/ActorInfo/ActorInfo.vue'
import ScoreIndicators from '../components/ScoreIndicators/ScoreIndicators.vue'
import { defineComponent } from 'vue'

function getFirst (a) {
  // TODO: sort by date
  return (Array.isArray(a) ? a[0] : a) || {}
}

function getNestedProp (a, prop) {
  prop = prop.split('.')
  if (prop.length === 1) {
    return a[prop[0]]
  }
  if (prop.length === 2) {
    return getFirst(a[prop[0]])[prop[1]]
  }
  if (prop[2] === 'monthly') {
    const item = (getFirst(a[prop[0]])[prop[1]] || [])
      .find(s => s.time_range_factor === 'months' && parseInt(s.time_range_count) === 1) || {}
    return item[prop[3]]
  } else {
    const [filterProp, filterValue] = prop[2].split(':')
    const list = getFirst(a[prop[0]])[prop[1]]
    const item = (list || []).find(s => s[filterProp] === filterValue) || {}
    return item[prop[3]] || (list ? 'not found' : 'empty list')
  }
}

function compareActors (actors, props) {
  if (!props || !props.length) {
    return []
  }

  return props
    .map(p => ({
      label: p.label || p.prop || p,
      prop: p.prop || p
    }))
    .map(({ label, prop }) => ({
      label,
      values: actors.map(a => getNestedProp(a, prop))
    }))
}

export default defineComponent({
  name: 'actor-scores',
  computed: {
    scoreTable () {
      const actor = this.$store.getters.detailActor
      const props = [
        'name',
        { prop: 'ahrefs_statistics.seo_details', label: 'SEO' },
        { prop: 'ahrefs_statistics.facebook_shares_count', label: 'ahrefs: facebook_shares_count' },
        //{ prop: 'ahrefs_statistics.total_facebook_interactions_count', label: 'ahrefs: total_facebook_interactions_count' },
        { prop: 'ahrefs_statistics.referring_domains_count', label: 'ahrefs: referring_domains_count' },
        { prop: 'ahrefs_statistics.backlinks_count', label: 'ahrefs: backlinks_count' },
        { prop: 'ahrefs_statistics.referring_pages_count', label: 'ahrefs: referring_pages_count' },
        //{ prop: 'ahrefs_statistics.total_shares_count', label: 'ahrefs: total_shares_count' },
        //{ prop: 'ahrefs_statistics.linkedin_shares_count', label: 'ahrefs: linkedin_shares_count' },
        //{ prop: 'ahrefs_statistics.facebook_clicks_count', label: 'ahrefs: facebook_clicks_count' },
        { prop: 'ahrefs_statistics.referring_ips_count', label: 'ahrefs: referring_ips_count' },
        { prop: 'ahrefs_statistics.rank', label: 'ahrefs: rank' },
        { prop: 'ahrefs_statistics.domain_rating', label: 'ahrefs: domain_rating' },
        { prop: 'ahrefs_statistics.facebook_likes_count', label: 'ahrefs: facebook_likes_count' },
        { prop: 'ahrefs_statistics.facebook_comments_count', label: 'ahrefs: facebook_comments_count' },
        { prop: 'ahrefs_statistics.referring_class_c_count', label: 'ahrefs: referring_class_c_count' },
        //{ prop: 'ahrefs_statistics.google_plus_shares_count', label: 'ahrefs: google_plus_shares_count' },
        //{ prop: 'ahrefs_statistics.median_shares_count', label: 'ahrefs: median_shares_count' },
        { prop: 'ahrefs_statistics.top_rank', label: 'ahrefs: top_rank' },
        //{ prop: 'ahrefs_statistics.twitter_shares_count', label: 'ahrefs: twitter_shares_count' },
        //{ prop: 'ahrefs_statistics.pinterest_shares_count', label: 'ahrefs: pinterest_shares_count' },

        { prop: 'facebook_details.likes_count', label: 'Facebook: likes' },
        { prop: 'facebook_details.talking_about_count', label: 'Facebook: talking about' },
        //{ prop: 'twitter_details.tweet_count', label: 'Twitter: tweets' },
        //{ prop: 'twitter_details.following_count', label: 'Twitter: following' },
        //{ prop: 'twitter_details.listed_count', label: 'Twitter: listed' },
        //{ prop: 'twitter_details.followers_count', label: 'Twitter: followers' },
        //{ prop: 'twitter_details.favourites_count', label: 'Twitter: favourites' },
        { prop: 'traction_details.unique_visitors', label: 'Traction: unique_visitors' },
        { prop: 'traction_details.time_on_site', label: 'Traction: time_on_site' },
        { prop: 'alexa_statistics.incoming_links_count', label: 'Alexa: incoming_links_count' },
        { prop: 'alexa_statistics.median_load_time', label: 'Alexa: median_load_time' },
        { prop: 'alexa_statistics.median_load_time_percentile', label: 'Alexa: median_load_time_percentile' },

        { prop: 'alexa_statistics.usage_statistics.monthly.page_views_per_million_change_percentile', label: 'Alexa monthly: page_views_per_million_change_percentile' },
        { prop: 'alexa_statistics.usage_statistics.monthly.page_views_per_million', label: 'Alexa monthly: page_views_per_million' },
        { prop: 'alexa_statistics.usage_statistics.monthly.alexa_rank', label: 'Alexa monthly: alexa_rank' },
        { prop: 'alexa_statistics.usage_statistics.monthly.reach_per_million_change_percentile', label: 'Alexa monthly: reach_per_million_change_percentile' },
        { prop: 'alexa_statistics.usage_statistics.monthly.reach_alexa_rank_change', label: 'Alexa monthly: reach_alexa_rank_change' },
        { prop: 'alexa_statistics.usage_statistics.monthly.page_views_alexa_rank_change', label: 'Alexa monthly: page_views_alexa_rank_change' },
        { prop: 'alexa_statistics.usage_statistics.monthly.alexa_rank_change', label: 'Alexa monthly: alexa_rank_change' },
        { prop: 'alexa_statistics.usage_statistics.monthly.reach_per_million', label: 'Alexa monthly: reach_per_million' },
        { prop: 'alexa_statistics.usage_statistics.monthly.page_views_alexa_rank', label: 'Alexa monthly: page_views_alexa_rank' },
        { prop: 'alexa_statistics.usage_statistics.monthly.page_views_per_user_change_percentile', label: 'Alexa monthly: page_views_per_user_change_percentile' },
        { prop: 'alexa_statistics.usage_statistics.monthly.page_views_per_user', label: 'Alexa monthly: page_views_per_user' },
        { prop: 'alexa_statistics.usage_statistics.monthly.reach_alexa_rank', label: 'Alexa monthly: reach_alexa_rank' },

        { prop: 'alexa_statistics.alexa_rank_by_country.country_code:ES.alexa_rank', label: 'Alexa ES: alexa_rank' },
        { prop: 'alexa_statistics.alexa_rank_by_country.country_code:US.contributing_page_views_percentile', label: 'Alexa US: contributing_page_views_percentile' },
        { prop: 'alexa_statistics.alexa_rank_by_country.country_code:GB.contributing_users_percentile', label: 'Alexa GB: contributing_users_percentile' },
        { prop: 'alexa_statistics.alexa_rank_by_country.country_code:FR.contributing_users_percentile', label: 'Alexa FR: contributing_users_percentile' },
        { prop: 'alexa_statistics.alexa_rank_by_country.country_code:IT.contributing_users_percentile', label: 'Alexa IT: contributing_users_percentile' },
        { prop: 'alexa_statistics.alexa_rank_by_country.country_code:DE.contributing_users_percentile', label: 'Alexa DE: contributing_users_percentile' },
        'digital_footprint',
        'business_size',
        'total_funding',
      ]
      return compareActors([this.portfolioActor, actor], props)
    },
    portfolioActor () {
      return this.$store.getters.portfolioActor
    },
    loading () {
      return this.$store.state.actors.detail.loading
    },
    company () {
      return this.$store.getters.detailActor
    },
    isCompetitive () {
      return this.$store.getters.isCompetitive
    },
    mappedFundingRounds () {
      return this.fundingRounds
        .map(round => {
          // TODO: this mutates the rounds which is not expected behaviour
          round.original_capital_raised = numeral(round.original_capital_raised).format('0.0a')
          round.started_at = moment(round.started_at).format('MMM, YYYY')
          return round
        })
    },
  },
  methods: {
    fetch () {
      this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_DETAIL, this.$route.params.id)
    }
  },
  created () {
    this.fetch()
    this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_PORTFOLIO_ACTOR, this.$store.state.config.portfolio_actor)
  },
  mixins: [CompanyMixin],
  components: {
    ActorInfo,
    ScoreIndicators,
  }
})
</script>
