<template>
  <div class="actor-contact-details">
    <div
      class="actor-contact-details__actor_name"
      v-if="isProduct && firstOffersRelation && firstOffersRelation.name">
      <span style="">
        <span
          style="font-weight: bold">{{
            $t('product_contact_title', { product: productLabel })
          }}</span>&nbsp;
        <router-link :to="'/actors/' + firstOffersRelation.id">{{
            firstOffersRelation.name
          }}</router-link>
      </span>
    </div>
    <div class="actor-contact-details__information">
      <div class="actor-address" v-if="showAddress">
        <icon name="map-marker" size="18"/>
        <div
          v-if="actor.address && (actor.address.street || actor.address.city || actor.address.zip || actor.address.country)">
          <span
            v-if="actor.address.street && actor.address.number">{{ actor.address.street }} {{
              actor.address.number
            }}<span
              style="margin-left: 0;"
              v-if="(actor.address.zip || actor.address.city) || actor.address.country">,</span></span>
          <span
            v-if="actor.address.street && !actor.address.number">{{
              actor.address.street
            }}</span>
          <span v-if="actor.address.zip || actor.address.city">{{
              actor.address.zip
            }} {{ actor.address.city }}<span
              style="margin-left: 0;" v-if="actor.address.country">,</span></span>
          <span
            v-if="actor.address.country">{{
              actor.address.country
            }}</span>
        </div>
      </div>
      <div class="hiding" v-if="showPhoneNumber">
        <div class="actor-phone_number">
          <icon name="phone" size="18"/>
          <span> {{ actor.phone }}</span>
          <div v-if="hidePhone" class="hide"></div>
        </div>
        <div class="eye-icon">
          <icon @click="toggleShow" :name="seeIcon" size="18"/>
        </div>
      </div>
      <div class="actor-contributor" v-if="showContactContributors">
        <icon name="user" size="18"/>
        <AvatarList
          style="margin-left: 12px;"
          :key="'avatars-list'"
          :tooltip-prefix-label="$t('start_conversation')"
          :members="avatars.list"
          @members:update="avatars.list = $event"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import Icon from '../Icon/Icon.vue'
  import AvatarList from '../../components/Avatar/AvatarList.vue'
  import { getConnectionsForActor } from '../../util/helpers.js'
  import { trackMatomoEvent } from '../../util/analytics'
  import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from '../../constants/analytics-constants'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'ActorContactDetails',
    props: {
      avatars: {
        type: Object,
      },
      showAddress: Boolean,
      showPhoneNumber: Boolean,
      showContactContributors: Boolean,
    },
    data () {
      return {
        hidePhone: true,
      }
    },
    computed: {
      seeIcon () {
        return this.hidePhone ? 'eye-slash' : 'eye-solid'
      },
      actor () {
        return this.$store.getters.detailActor
      },
      isProduct () {
        const result = this.actor && this.actor['actor_type'] === 'Product'
        // hack to fix problem with store/modules/filters.js where the current page can't be tracked correctly without an external reference when clicking "next"
        window.isProductPage = !!result
        return result
      },
      firstOffersRelation () {
        if (this.connections.find(connection => connection.type === 'is_product_of')) {
          return this.connections.find(connection => connection.type === 'is_product_of')
        }

        if (this.connections.find(connection => connection.type === 'member_of')) {
          return this.connections.find(connection => connection.type === 'member_of')
        }

        return false
      },
      ecosystemRelationships () {
        return this.$store.getters.fullActorRelationships
      },
      connections () {
        return getConnectionsForActor(this.actor, this.ecosystemRelationships)
      },
      userId () {
        return this.$store.getters.userId
      },
    },
    methods: {
      toggleShow () {
        this.hidePhone = !this.hidePhone

        if (!this.hidePhone) {
          const obj = {
            name: this.actor.name,
            id: this.actor.id,
            type: this.actor.actor_type === 'LegalEntity' ? 'Actor' : 'Product',
            type_official: this.actor.actor_type,
            is_logged_in: !!this.$store.getters.isLoggedIn,
          }

          trackMatomoEvent(MATOMO_EVENT_CATEGORIES.ACTOR_PHONE_NUMBER, MATOMO_EVENT_ACTIONS.BUTTON_CLICK, JSON.stringify(obj))
        }
      },
      updateContactableContributors (newContactableContributors) {
        this.$emit('updateContactableContributors', newContactableContributors)
      },
      handleContributorError (errors) {
        if (errors && errors.message === 'Unexpected error') {
          errors.message = 'You can only add contributors to published profiles.'
        }
      },
    },
    mixins: [TranslationsMixin],
    components: {
      Icon,
      AvatarList,
    },
  })
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables";

  .actor-contact-details {
    line-height: 1.5;

    .actor-contact-details__information {
      display: flex;
      flex-direction: column;
      margin-top: 1rem;
      justify-content: center;

      span {
        margin-left: 15px;
      }

      .svg-icon {
        fill: var(--primary);

        :deep(.fill-fg) {
          fill: var(--primary)
        }
      }


      .actor-address {
        margin-bottom: 1rem;
        display: flex;

        @media (max-width: $screen-md) {
          justify-content: center;
        }

      }

      .hiding {
        position: relative;
        display: flex;

        @media (max-width: $screen-md) {
          justify-content: center;
        }

        .hide {
          background: linear-gradient(to right, transparent 10%, var(--primary-extra-lightest) 60%);
          width: 100%;
          height: 100%;
          position: absolute;
          right: 0;
          top: 0;

          @media (max-width: $screen-md) {
            background: linear-gradient(to right, transparent 10%, #FFFF 60%);
          }

        }

      }

      .actor-phone_number {
        display: inline-block;
        margin-bottom: 1rem;
        position: relative;

      }

      .eye-icon {
        margin-top: 4px;
        margin-left: 10px;
        cursor: pointer;

        :deep(.fill-fg) {
          fill: var(--primary);
        }
      }

      .actor-contributor {
        margin-left: 0;
        display: flex;

        @media (max-width: $screen-md) {
          justify-content: center;
        }
      }
    }
  }

  .actor-contact-details__actor_name {
    margin-top: 10px;
    display: block;

    @media (max-width: $screen-md) {
      text-align: center;
    }
  }
</style>
