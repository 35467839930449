<template>
  <div class="has-action-bar">
    <div class="tabs-border">
      <div class="pull-right" v-if="roleForm">
        <ds-button size="small" variant="outline" label="Reset" @click="reset(show.tab)" style="margin-top: -8px;"/>
        <ds-button size="small" variant="outline" label="Disable" @click="disable(show.tab)" style="margin-top: -8px;"
          v-if="show.tab === 'member'"/>
      </div>
      <ul class="tabs__navigation">
        <li class="tabs__navigation__label">
          Role:
        </li>
        <li
          v-for="role in roles"
          class="tabs__navigation__item"
          :class="{ 'tabs__navigation__item--active': show.tab === role }"
          @click="show.tab = role"
          v-text="roleDisplayName(role)"
        ></li>
      </ul>
    </div>

    <div v-if="!show.tab">
      <p>
        Choose a role that you want to manage.
      </p>
    </div>

    <div v-if="show.tab === 'visitor'">
      <visitor-permissions
        :permissions="visitorPermissions"
        :generalPublisher="form"
        :selectedPortfolios="publishedVisitorPortfolios"
        :selectedSpottingAreas="accessibleVisitorSpottingAreas"
        @enable="enable('visitor')"
        @updatePortfolios="updateVisitorPortfolios"
        @updateAccessibleSpottingAreas="updateVisitorAccessibleSpottingAreas"
      />
    </div>

    <div v-else-if="show.tab === 'actor'">
      <actor-permissions
        :generalPublisher="form"
        :publisher="roleForm"
        :selectedPortfolios="publishedActorPortfolios"
        :selectedSpottingAreas="accessibleActorSpottingAreas"
        @enable="enable('actor')"
        @updatePortfolios="updateActorPortfolios"
        @updateAccessibleSpottingAreas="updateActorAccessibleSpottingAreas"
      />
    </div>

    <div v-else-if="show.tab === 'member'">
      <role-config :form="roleForm" v-if="form.permissions && form.permissions.member" role="member"/>
      <div v-else>
        <ds-button variant="secondary" label="Enable permissions for members" @click="enable('member')"/>
      </div>
    </div>

    <div v-else-if="show.tab === 'portfolio_member'">
      <role-config :form="roleForm" v-if="form.permissions && form.permissions.portfolio_member"
        role="portfolio_member"
        :selectedPortfolios="publishedPortfolioMemberPortfolios"
        @updatePortfolios="updatePortfolioMemberPortfolios"/>
      <div v-else>
        <ds-button variant="secondary" label="Enable permissions for portfolio members"
          @click="enable('portfolio_member')"/>
      </div>
    </div>
  </div>
</template>

<script>
  import Checkbox from '../Form/Checkbox.vue'
  import RoleConfig from './RoleConfig.vue'
  import { emptyConfig } from '../../util/config'
  import { propertyGroups } from '../../constants/config'
  import VisitorPermissions from './Permissions/VisitorPermissions.vue'
  import ActorPermissions from './Permissions/ActorPermissions.vue'

  import { fetchPublishedPortfolios, updatePublishedPortfolios } from '../../api/portfolios'
  import { fetchAccessibleSpottingAreas, updateAccessibleSpottingAreas } from '../../api/spottingareas.js'
  import { defineComponent } from 'vue'
  import { SPOTTING_AREA_TYPES } from '@/store/modules/config.js'

  export default defineComponent({
    data () {
      return {
        show: {
          tab: null,
        },
        publishedVisitorPortfolios: [],
        publishedActorPortfolios: [],
        publishedPortfolioMemberPortfolios: [],
        accessibleVisitorSpottingAreas: [],
        accessibleActorSpottingAreas: [],
      }
    },
    computed: {
      form () {
        return this.$parent.$parent.form
      },
      roles () {
        var options = this.$store.getters.hasAccessToPublisher && this.form.publisher ? ['visitor', 'actor', 'member'] : ['member']

        if (this.$store.getters.hasAccessToPortfolioSharing) {
          options = options.concat('portfolio_member')
        }

        return options
      },
      roleForm () {
        switch (this.show.tab) {
          case 'visitor':
            return this.form.permissions && this.form.permissions.visitor
          case 'actor':
            return this.form.publisher
          case 'member':
            return this.form.permissions && this.form.permissions.member
          case 'portfolio_member':
            return this.form.permissions && this.form.permissions.portfolio_member
        }
      },
      visitorPermissions () {
        return this.form.permissions && this.form.permissions.visitor
      },
      /*accessLevel () {
        return this.form.allow_anonymous ? (
          this.form.allowAdd ? 'public' : 'public'
        ) : (
          this.form.allowAdd ? 'login required' : 'private'
        )
      },*/
    },
    methods: {
      updateVisitorPortfolios (portfolios) {
        this.publishedVisitorPortfolios = portfolios
      },
      updateActorPortfolios (portfolios) {
        this.publishedActorPortfolios = portfolios
      },
      updatePortfolioMemberPortfolios (portfolios) {
        this.publishedPortfolioMemberPortfolios = portfolios
      },
      updateVisitorAccessibleSpottingAreas (spottingAreas) {
        this.accessibleVisitorSpottingAreas = spottingAreas
      },
      updateActorAccessibleSpottingAreas (spottingAreas) {
        this.accessibleActorSpottingAreas = spottingAreas
      },
      enable (role) {
        if (!this.form.permissions || Array.isArray(this.form.permissions) || typeof this.form.permissions === 'string') {
          this.form.permissions = {}
        }

        if (!this.form.permissions[role]) {
          // Empty permissions for reactivity
          var permissions = Object.assign(emptyConfig(), {

            // Add defaults that MUST override the parent config
            // Also, let's copy the parent view config
            public_fields: propertyGroups.Base.slice(),
            viewActorTypes: (this.form.viewActorTypes || ['LegalEntity']).slice(),
            viewDashboard: this.form.viewDashboard || [],
            viewGallery: this.form.viewGallery || false,
            viewList: this.form.viewList || [],
            viewMap: this.form.viewMap || [],
            filterControls: this.form.filterControls || [],
            defaultLegendProperty: this.form.defaultLegendProperty,
            availableLegendItems: Object.assign({}, this.form.availableLegendItems),

            allowEdit: role === 'member' ? true : null,

            // These options are defined in the parent config and can probably be removed
            allow_anonymous: null,
            allowAdd: null,
            allowRegister: null,
          })

          if (['actor', 'portfolio_member', 'visitor'].includes(role)) {
            permissions['viewDashboard'] = permissions['viewDashboard'].filter(d => !['buzz', 'connections', 'media-buzz', 'explore-content'].includes(d))
          }

          // For visitor, only keep a couple of things
          if (role === 'visitor') {
            var visitorPermissions = {};

            [
              'views',
              'viewActorTypes',
              'viewList',
              'viewGallery',
              'viewMap',
              'viewDashboard',
              'defaultLegendProperty',
              'availableLegendItems',
              'defaultLegendProperty',
              'public_fields',
              'siteTemplate',
              'enableFundingBuzz',
              'enableEventsBuzz',
              'enableInfluencersBuzz',
              'allowAllFilters',
              'allowAllFiltersProducts',
            ].forEach((key) => {
              visitorPermissions[key] = permissions[key]
            })

            permissions = visitorPermissions
          }

          this.form.permissions[role] = permissions
        }
      },
      disable (role) {
        this.form.permissions[role] = undefined
      },
      reset (role) {
        this.disable(role)
        this.enable(role)

        // Move actor permissions to publishers
        if (role === 'actor') {
          this.form.publisher = this.form.permissions.actor
          delete this.form.permissions.actor
          this.form.allow_anonymous = null
          this.form.allowAdd = null
          this.form.allowRegister = null
          this.form.allowCuratedAdd = null
        }
      },
      roleDisplayName (role) {
        if (role === 'visitor') {
          return 'Visitor'
        }

        if (window.ROLES) {
          return window.ROLES[role]
        }

        switch (role) {
          case 'actor':
            return 'Ecosystem Member'
          case 'portfolio_member':
            return 'Portfolio Member'
          case 'owner':
            return 'Owner'
          case 'member':
            return 'Team Member'
          default :
            return ''
        }
      },
      async fillInAccessibleSpottingAreas (role) {
        var response = await fetchAccessibleSpottingAreas(role)

        if (!Array.isArray(response)) {
          return
        }

        var selectedSpottingAreas = []

        response.forEach(spottingArea => {
          let showEye = true
          let invalidOption = spottingArea.type === SPOTTING_AREA_TYPES.PREMIUM || spottingArea.type === SPOTTING_AREA_TYPES.PRIVATE

          selectedSpottingAreas.push({
            text: spottingArea.name,
            id: spottingArea.id,
            optionValue: {
              showEye: showEye,
              invalidOption: invalidOption,
              text: spottingArea.name,
            },
          })
        })

        if (role === 'actor') {
          this.accessibleActorSpottingAreas = selectedSpottingAreas
        } else if (role === 'visitor') {
          this.accessibleVisitorSpottingAreas = selectedSpottingAreas
        }
      },
      async fillInPublishedPortfolios (role) {
        var response = await fetchPublishedPortfolios(role)

        if (!Array.isArray(response)) {
          return
        }

        var selectedPortfolios = []

        response.forEach(portfolio => {
          selectedPortfolios.push({
            text: portfolio.name,
            optionValue: {
              text: portfolio.name,
              id: portfolio.id,
            },
          })
        })

        if (role === 'actor') {
          this.publishedActorPortfolios = selectedPortfolios
        } else if (role === 'visitor') {
          this.publishedVisitorPortfolios = selectedPortfolios
        } else if (role === 'portfolio_member') {
          this.publishedPortfolioMemberPortfolios = selectedPortfolios
        }
      },
    },
    async mounted () {
      if (this.roles.length === 1) {
        this.show.tab = this.roles[0]
      }

      if (!this.roles.includes('actor')) {
        return
      }

      await this.fillInPublishedPortfolios('visitor')
      await this.fillInPublishedPortfolios('actor')
      await this.fillInPublishedPortfolios('portfolio_member')

      await this.fillInAccessibleSpottingAreas('visitor')
      await this.fillInAccessibleSpottingAreas('actor')

      this.$bus.on('save-settings', async () => {
        var publishedVisitorPortfolioIds = this.publishedVisitorPortfolios.map(portfolio => portfolio.optionValue.id)
        await updatePublishedPortfolios({ portfolio_ids: publishedVisitorPortfolioIds, role: 'visitor' })

        var publishedActorPortfolioIds = this.publishedActorPortfolios.map(portfolio => portfolio.optionValue.id)
        await updatePublishedPortfolios({ portfolio_ids: publishedActorPortfolioIds, role: 'actor' })

        var publishedPortfolioMemberPortfolioIds = this.publishedPortfolioMemberPortfolios.map(portfolio => portfolio.optionValue.id)
        await updatePublishedPortfolios({ portfolio_ids: publishedPortfolioMemberPortfolioIds, role: 'portfolio_member' })

        var accessibleVisitorSpottingAreaIds = this.accessibleVisitorSpottingAreas.map(spottingArea => spottingArea.id)
        await updateAccessibleSpottingAreas({ spotting_area_ids: accessibleVisitorSpottingAreaIds, role: 'visitor' })

        var accessibleActorSpottingAreaIds = this.accessibleActorSpottingAreas.map(spottingArea => spottingArea.id)
        await updateAccessibleSpottingAreas({ spotting_area_ids: accessibleActorSpottingAreaIds, role: 'actor' })
      })
    },
    beforeUnmount () {
      this.$bus.off('save-settings')
    },
    components: {
      ActorPermissions,
      VisitorPermissions,
      Checkbox,
      RoleConfig,
    },
  })
</script>

<style lang="scss">
  .tabs-border {
    margin: -10px -20px 20px;
    padding: 10px 20px;
    border-bottom: 1px solid #ccc;

    > .tabs__navigation {
      margin-bottom: 0;
    }
  }

  .tabs__navigation__label {
    display: inline-block;
    margin: 0 10px 10px;
  }
</style>
