<template>
  <div class="page-notifications fixed-heading">
    <div class="heading page-notifications__heading">
      <h1>{{ $t('generic_notifications') }}</h1>
      <div class="page-notifications__heading__buttons">
        <badge :name="unreadNotificationsBadgeCount + ' unread'" variant="primary"/>
        <ds-button variant="outline" icon="settings" @click="showNotificationSettings" v-if="shouldShowSettings"/>
        <ds-button variant="secondary" icon="check" label="Read All" @click="setReadAllNotifications()"
            v-if="isLoggedIn"/>
      </div>
    </div>
    <div ref="scrollable" class="scrollable">
      <div class="has-padding" v-if="topNotification && topNotification.type">
        <notification-detail :notification="topNotification"/>
      </div>
      <div class="has-padding">
        <div v-for="(notification, index) in notifications">
          <notification-detail :notification="notification" v-if="! topNotification || notification.id != topNotification.id"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _throttle from 'lodash/throttle'

import ConfigMixin from '../util/ConfigMixin.js'
import { NotificationsMixin } from '../util/NotificationsMixin.js'

import {
  fetchNotification,
  fetchNotificationPage,
  readAllNotifications,
  readNotifications,
} from '../api/notifications.js'

import DsTextarea from '../components/Form/DsTextarea.vue'
import Modal from '../components/Modals/Modal.vue'
import RadioButton from '../components/Form/RadioButton.vue'
import NotificationDetail from '../components/Notifications/NotificationDetail.vue'
import Dropdown from '../components/Dropdown/Dropdown.vue'
import ImageInput from '../components/Form/ImageInput.vue'
import Badge from '../components/Badge/Badge.vue'

import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../store/modules/ui'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'info',
  components: {
    DsTextarea,
    Modal,
    RadioButton,
    NotificationDetail,
    Dropdown,
    ImageInput,
    Badge,
  },
  data () {
    return {
      errors: {},
      notifications: [],
      topNotification: {},
      fetching: false,
      endOfFeed: false,
    }
  },
  mixins: [ConfigMixin, NotificationsMixin],
  computed: {
    isDeveloper () {
      return this.$store.getters.isDeveloper
    },
    isPrivate () {
      return this.$store.getters.isPrivate
    },
    unreadNotificationsBadgeCount () {
      return this.unreadNotificationsCount
    },
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    },
    shouldShowSettings () {
      return (
          this.$store.getters.isLoggedIn &&
          (this.$store.getters.isMember || this.$store.getters.isActor || this.$store.getters.isPortfolioMember)
      )
    },
  },
  methods: {
    fetch (next) {
      if (this.fetching || this.endOfFeed) {
        return
      }

      this.fetching = true

      // Fetch notifications
      var filters = {}

      if (this.$route.query && this.$route.query.messageType) {
        filters = { type: this.$route.query.messageType }
      }

      fetchNotificationPage(10, next ? this.notifications.length : 0, filters)
          .then(notifications => {
            var notificationsLength = notifications.length
            if (this.topNotification && this.topNotification.id) {
              notifications = notifications.filter(noti => noti.id !== this.topNotification.id)
            }

            if (next) {
              this.notifications = this.notifications.concat(notifications)
            } else {
              this.notifications = notifications
            }

            this.endOfFeed = notificationsLength < 10
          })
          .catch(() => {
            this.error = 'Oops! Something went wrong while fetching notifications.'
            this.endOfFeed = true
          })
          .then(() => {
            this.fetching = false
          })
    },
    fetchTopNotification () {
      // Fetch the top notification if specified
      this.topNotification = {}

      if (this.$route.params.id) {
        // Mark the notification as read
        readNotifications([this.$route.params.id])

        fetchNotification(this.$route.params.id).then(notification => {
          this.topNotification = notification
        })
      }
    },
    showNotificationSettings () {
      this.$router.push('/profile/update#notification-settings')
    },
    edit (item) {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'edit-announcement-panel', metaData: item })
    },
    render () {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(this.actualRender, 200)
    },
    actualRender () {
      if (this.$refs.columns) {
        this.columns = Math.floor(this.$refs.columns.scrollWidth / 300) || 1
      }
    },
    setReadAllNotifications () {
      // Since visitors can also see public announcements, make sure that a user is logged in before we mark the posts as "read",
      // because marking something as "read" happens on a per user basis
      if (this.isLoggedIn) {
        readAllNotifications().then(notification => {
          //by fetching the recent notifications, it will reset the unread notifications
          this.fetchRecentNotifications()
          this.fetch()
        })
      }
    },
    scroll: _throttle(function () {
      const elem = this.$refs.scrollable
      if (elem && elem.offsetHeight + elem.scrollTop > 0.95 * elem.scrollHeight - 1500) {
        this.fetch(1)
      }
    }, 1000),
  },
  mounted: function () {
    // This event comes from the announcement edit side panel once the user confirms the change
    this.$bus.on('announcementUpdated', () => {
      this.fetch()
    })

    this.fetchTopNotification()
    this.actualRender()
    this.$refs.scrollable.addEventListener('scroll', this.scroll, { passive: true })
    this.scroll()
  },
  beforeUnmount () {
    this.$bus.off('resize300', this.actualRender)
    this.$bus.off('announcementUpdated')

    if (this.$refs.scrollable) {
      this.$refs.scrollable.removeEventListener('scroll', this.scroll, { passive: true })
    }

    this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
  },
  watch: {
    notifications () {
      this.render()
    },
  },
})
</script>

<style lang="scss">
.page-notifications {
  .card {
    max-width: 50em;
  }
}

.page-notifications__heading {
  display: flex;

  .page-notifications__heading__buttons {
    margin-left: auto;
  }
}

@media (max-width: 768px) {
  .page-notifications__heading {
    display: block;

    h1 {
      margin-bottom: 10px;
    }

    .page-notifications__heading__buttons {
      float: right;
    }
  }
}
</style>
