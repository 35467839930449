import { createJSONRequest, createPagedRequest } from './create-request'
import querify from '../util/querify'

export const fetchLatestFundingRounds = (parameters = { limit: 50, offset: 0 }) => createJSONRequest({
  method: 'GET',
  url: `/api/homepage?type=funding&${querify(parameters)}`,
})

export const fetchTrendingEvents = (parameters = { limit: 50, offset: 0 }) => createJSONRequest({
  method: 'GET',
  url: `/api/homepage?type=events&${querify(parameters)}`,
})

export const fetchKeyInfluencers = (parameters = { limit: 50, offset: 0 }) => createJSONRequest({
  method: 'GET',
  url: `/api/homepage?type=influencers&${querify(parameters)}`,
})

export const fetchKnowledgeBaseTags = (parameters = { limit: 50, offset: 0 }) => createJSONRequest({
  method: 'GET',
  url: `/api/homepage?type=content_tags&${querify(parameters)}`,
})

export const fetchLatestNews = (parameters = { limit: 10, offset: 0, transform: 'telex' }) => createJSONRequest({
  method: 'GET',
  url: `/api/homepage?type=rss&${querify(parameters)}`,
})

export const fetchLatestActors = (parameters = {}) => createJSONRequest({
  method: 'GET',
  url: `/api/homepage?type=latest_actors&${querify(parameters)}`,
})

export const fetchGalleryData = (parameters) => createPagedRequest({
  method: 'GET',
  url: `/api/homepage?type=gallery&${querify(parameters)}`,
})

// This will fetch the data for the category cards in the Homepage
export const fetchStatsData = (parameters = {}) => createPagedRequest({
  method: 'GET',
  url: `/api/homepage?type=stats&${querify(parameters)}`,
})

export const fetchGalleryAndLatestActors = (parameters = {}) => createJSONRequest({
  method: 'GET',
  url: `/api/homepage?type=gallery_and_latest_actors&${querify(parameters)}`,
})
