<template>
  <div class="agent-suggestion" :class="{
    'agent-accepted': suggestion.state === 'accepted',
    'agent-declined': suggestion.state === 'denied',
    'isAdded': suggestion.isAdded,
  }">
    <div class="color-block">
      <div class="icon-cross" v-if="suggestion.state === 'denied'"/>
      <div class="icon-check" v-if="suggestion.state === 'accepted'"/>
    </div>

    <div class="suggestion-description" v-if="suggestion.state === 'denied' || suggestion.state === 'accepted'">
      {{ suggestion.statusMessage }}
    </div>

    <div class="suggestion-description" v-tooltip.top-center="tooltipContent" v-else>
      {{ suggestion.name }}&nbsp;

      <a :href="'/actors/' + suggestion.actorId" v-if="suggestion.actorId">
        <icon name="link" size="small"/>
      </a>
      <a :href="externalLink" style="text-decoration: none;" target="_blank" v-if="externalLink">
        <icon name="open-in-new" size="12"/>&nbsp;</a>
      <a :href="internalLink" style="text-decoration: none;" v-if="internalLink">
        <icon name="view-graph" size="12"/>&nbsp;</a>
      <span v-if="suggestion.description">- {{ stripHtmlTags(suggestion.description) }}</span>
    </div>

    <p style="position: absolute; right: 5px;" v-if="suggestion.isAdded" class="suggestion-relation-added">
      Relationship added
    </p>

    <ul v-if="suggestion.state === 'suggested'" class="tags">
      <li v-if="suggestion.source && suggestion.source.length > 0">
        <a
          :href="suggestion.source"
          target="_blank"
          rel="noopener"
        >
          Source
        </a>
      </li>
    </ul>

    <ul v-if="suggestion.state === 'suggested'" class="controls">
      <template v-if="isProcessing">
        <li>
          <ds-button variant="secondary" type="submit" icon="spinner" size="extra-small" style="background-color: transparent; top: 3px;"/>
        </li>
      </template>
      <template v-else-if="!disabled">
        <li>
          <a
            class="icon-check"
            href="#"
            @click="clickAcceptSuggestion"
          />
        </li>
        <li>
          <a
            class="icon-cross"
            href="#"
            @click="clickDeclineSuggestion"
          />
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
  import { stripHtmlTags } from '../../../util/helpers'

  export default {
    props: {
      suggestion: Object,
      disabled: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        isProcessing: false
      }
    },
    computed: {
      externalLink () {
        if (this.suggestion.website && this.suggestion.website.length > 0) {
          return this.suggestion.website
        }

        if (this.suggestion.linkedin && this.suggestion.linkedin.length > 0) {
          return this.suggestion.linkedin
        }
      },
      internalLink () {
        if ((this.$store.getters.ecosystemName == 'VOPAK-monitoring' || this.$store.getters.ecosystemName == 'colruyt-actormapping' || this.$store.getters.ecosystemName == 'local') &&
          this.suggestion.file_id
        ) {
          return '/files/' + this.suggestion.file_id
        }
      },
      tooltipContent () {
        var description = ''

        if (this.suggestion.description) {
          description = this.suggestion.description.replace(/(?:\r\n|\r|\n)/g, '')
          // Replace <p> tags with empty string
          description = description.replace(/<p>/g, '').replace(/<\/p>/g, '')
        }

        return description
      }
    },
    methods: {
      stripHtmlTags,
      clickAcceptSuggestion (e) {
        e.preventDefault()
        this.isProcessing = true
        this.$emit('acceptSuggestion')
      },
      clickDeclineSuggestion (e) {
        e.preventDefault()
        this.isProcessing = true
        this.$emit('declineSuggestion')
      }
    },
    watch: {
      suggestion (v) {
        this.isProcessing = false
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../../scss/variables";

  .suggestion-relation-added {
    margin-top: 1.25rem;
    font-size: 0.75rem;
    color: #909090;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  }

  .isAdded {
    background-color: $color-background-light-grey;
  }

  .agent-suggestion {
    align-items: center;
    border: 1px solid $color-text-grey;
    display: flex;
    margin-bottom: 5px;
    min-height: 28px;
    padding: 5px 1px 5px 15px;
    position: relative;

    &.agent-accepted,
    &.agent-declined {
      padding-left: 29px;

      .color-block {
        width: 22px;
      }
    }

    &.agent-accepted {
      border-color: $color-primary;

      .color-block {
        background-color: $color-primary;
      }

      .icon-check::before {
        border-color: white;
      }
    }

    &.agent-declined {
      border-color: $color-error-light;

      .color-block {
        background-color: $color-error-light;
      }

      .icon-cross::before,
      .icon-cross::after {
        background-color: white;
      }
    }

    .color-block {
      align-items: center;
      background-color: grey;
      display: flex;
      height: 100%;
      justify-content: center;
      left: 0;
      position: absolute;
      width: 8px;
    }

    .suggestion-description {
      font-size: 11px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .tags {
      display: flex;
      list-style-type: none;
      margin-left: auto;
      padding-left: 10px;

      li + li {
        margin-left: 2px;
      }

      a {
        border: 1px solid $color-borders;
        border-radius: 2px;
        color: $color-borders;
        display: block;
        font-weight: 500;
        font-size: 11px;
        padding: 1px 4px;
        text-decoration: none;
        text-transform: uppercase;
        white-space: nowrap;
      }
    }

    .controls {
      display: flex;
      list-style-type: none;

      * + * {
        border-left: 1px solid $color-borders;
      }
    }
  }
</style>
