<template>
  <with-configuration-tabs>
    <div class="suggestions fixed-heading">
      <div class="heading">
        <h1>Suggestions</h1>
        <p class="guide_text">Recommendations from DataScouts to improve profile
          completeness. </p>
        <div class="row">
          <form-group class="col-sm-4 col-md-3" label="Filter by property">
            <dropdown :options="formattedProperties" allow-clear
                      v-model="filters.property" placeholder="Choose a property..."/>
          </form-group>
          <form-group class="col-sm-4 col-md-3" label="Filter by value">
            <ds-input v-model="filters.value" placeholder="Search"/>
          </form-group>
        </div>
        <p>
          <ds-button variant="outline" @click="toggle" label="Select all"
                     v-if="!selection.length"/>
          <ds-button variant="outline" @click="toggle" label="Deselect all" v-else/>

          <ds-button variant="outline" class="button--primary" @click="acceptAll"
                     label="Accept all" v-if="canUserAcceptAll"/>

          <ds-button variant="secondary" @click="accept"
                     :label="'Accept ' + selection.length + ' selected suggestions'"
                     v-if="selection.length" :icon="loading ? 'spinner' : 'check'"/>
          <ds-button variant="secondary" @click="deny"
                     :label="'Deny ' + selection.length + ' selected suggestions'"
                     v-if="selection.length"/>
        </p>

        <div class="form-group__error" v-if="Object.keys(errors).length"
             style="font-size: 12px; margin-bottom: 0.5rem;">
          There {{
            Object.keys(errors).length === 1 ? 'was 1 error' : 'were ' + Object.keys(errors).length + ' errors'
          }} while processing the suggestions:
          <div v-for="error in errors">
            {{ Array.isArray(error) ? error[0] : error }}
          </div>
        </div>
      </div>
      <div class="scrollable">
        <table class="table table--fill table--hover">
          <thead>
          <tr>
            <th></th>
            <th>Actor</th>
            <th>Property</th>
            <th>Suggestion</th>
            <th>Context</th>
            <th>Suggested&nbsp;by</th>
            <th width="80">Date</th>
          </tr>
          </thead>
          <tbody @click="$root.curating = true">
            <suggestion-row
              v-for="(suggestion, index) in suggestions"
              :key="'suggestion-' + index"
              :suggestion="suggestion"
              :selection="selection"
              @change="fetch(true)"
              @remove="remove(index)"
            />
          </tbody>
        </table>

        <infinite-loading :loading="loading" :complete="complete" @load="fetch()"
                          ref="infinite">
          Loaded {{ suggestions.length }} suggestions
        </infinite-loading>
      </div>
    </div>
  </with-configuration-tabs>
</template>

<script>
import debounce from 'lodash/debounce'
import _flatten from 'lodash/flatten'

import SuggestionRow from './SuggestionRow.vue'
import InfiniteLoading from '../InfiniteLoading/InfiniteLoading.vue'
import Dropdown from '../Dropdown/Dropdown.vue'

import { Suggestions, AllSuggestions } from '../../api/heartbeat.js'

import WithConfigurationTabs
  from '../../pages/WithConfigurationTabs/WithConfigurationTabs.vue'
import { humanize } from '../../constants/properties'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    Dropdown,
    InfiniteLoading,
    // Pagination,
    SuggestionRow,
    WithConfigurationTabs,
  },
  props: ['company'],
  data () {
    return {
      complete: false,
      loading: false,
      selection: [],
      suggestions: [],
      paging: null,
      propertyOptions: [],
      errors: {},
      filters: {
        property: null,
        agent: null,
        value: null,
        limit: 50,
      }
    }
  },
  computed: {
    canUserAcceptAll () {
      return this.$store.getters.isDeveloper
    },
    formattedProperties () {
      return (this.propertyOptions || []).map(({ property, count }) => ({
        label: this.humanizeSuggestion(property) + ' (' + count + ')',
        value: property
      }))
    },
    values () {
      return _flatten(this.suggestions.map(s => s.property === 'reports'
        ? s.id
        : Array.isArray(s.value)
        ? s.value
        : typeof s.value === 'string'
        ? s.value
        : []
      ))
    },
    portfolio () {
      return this.$store.state.filters.portfolio
    },
    ecosystemRelationships () {
      return this.$store.getters.fullActorRelationships
    },
    filterableRelationships () {
      return this.$store.getters.filterableRelationships
    }
  },
  methods: {
    humanizeSuggestion (property) {
      // Make a human readable suggestion label for the property of the suggestion
      if (property == 'subsidiaries') {
        return 'Offices'
      }

      if (this.filterableRelationships.includes(property)) {
        var config = this.ecosystemRelationships.filter(config => config.name == property)

        if (config) {
          return config[0]['label']
        }
      }

      return humanize(property)
    },
    fetch: debounce(function (reset) {
      if (reset) {
        this.selection = []
        this.complete = false
      }

      if (this.loading) {
        return
      }

      this.loading = true

      var suggestionFilters = Object.assign({ offset: reset ? 0 : this.suggestions.length }, this.filters)

      if (this.portfolio) {
        suggestionFilters['portfolio'] = this.portfolio
      }

      Suggestions.get('', suggestionFilters)
        .then(data => {
          if (!data.length) {
            this.complete = true
          }
          if (suggestionFilters.property === this.filters.property && suggestionFilters.value === this.filters.value) {
            this.suggestions = reset ? data : this.suggestions.concat(data)
          }
        }, this.fetchError)
        .then(() => {
          this.loading = false
        })

      Suggestions.get('properties', suggestionFilters)
        .then(data => {
          if (data && data.length) {
            this.propertyOptions = data
          } else {
            return Promise.reject()
          }
        })
    }, 600),
    fetchError (error) {
      this.complete = true
    },
    toggle () {
      if (this.selection.length) {
        this.selection = []
      } else {
        this.selection = this.suggestions.map(s => s.id)
      }
    },
    acceptAll () {
      if (! window.confirm('You are about to accept all changes that are in this ecosystem, when doubles occur only the first suggestion will be accepted, the others denied. Are you sure you want to proceed?')) {
        return
      }

      this.loading = true

      AllSuggestions()
        .then(() => {
          this.loading = false
          this.fetch(true)
        })
        .catch(() => {
          this.loading = false
        })
    },
    accept () {
      const data = this.selection.map(id => ({
        id,
        status: 'accepted'
      }))
      if (data.length > 50 && !window.confirm('Accepting more than 50 suggestions might take a long while')) {
        return
      }
      this.loading = true
      Suggestions.post(data)
        .then(() => {
          this.errors = {}
          this.loading = false
          this.fetch(true)
        })
        .catch(errors => {
          this.errors = errors
          this.loading = false
        })
    },
    deny () {
      const data = this.selection.map(id => ({
        id,
        status: 'denied'
      }))
      Suggestions.post(data)
        .then(() => {
          this.fetch(true)
        })
    },
    remove (index) {
      this.suggestions.splice(index, 1)
    },
    setOffset (offset) {
      this.filters.offset = offset
    }
  },
  mounted () {
    this.fetch(true)
  },
  watch: {
    portfolio (v) {
      this.fetch(true)
    },
    filters: {
      deep: true,
      handler () {
        this.fetch(true)
      }
    }
  }
})
</script>

<style lang="scss">
.suggestions {
  .infinite-loading {
    padding: 20px;

  }
}
</style>
