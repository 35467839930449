<template>
  <div class="buzz-card__general">
    <div class="buzz-card__general-container">
      <a @click="followRoute()" :class="{'buzz-card__column-override': !data.featured_image_url && !data.website_screenshot, 'buzz-card__is-topics' : data.buzz_type == 'topics'}">
        <div class="buzz-card__image-container" v-if="data.featured_image_url || data.website_screenshot">
          <div class="buzz-card__article-image" :style="newsImageStyle(data)"></div>
        </div>
        <div class="buzz-card__text-container">
            <div class="buzz-card__title" v-if="data.name">{{ data.name }}</div>
            <div class="buzz-card__meta-data" v-if="metaData"> {{metaData}} </div>
            <div class="buzz-card__description" v-if="description"> {{ description }}</div>
        </div>
      </a>
    </div>
  </div>
</template>

<script>
  import { toAmount } from '../../util/currency'
import { toDayFullMonth } from '../../util/date'

import { MUTATION_TYPES as BUZZ_MUTATION_TYPES } from '../../store/modules/buzz'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'buzz-card',
    props: {
      data: {
        type: Object,
        default: {}
      }
    },
    computed: {
      metaData () {
        switch (this.data.buzz_type) {
          case 'funding':
            return toDayFullMonth(this.data.started_at)
            break
        }
      },
      occurences () {
        return this.data.occurences || 'unknown'
      },
      description () {
        switch (this.data.buzz_type) {
          case 'funding':
            return `${this.data.name} raised ${this.totalFunding(this.data.capital_raised, this.data.currency)} ${this.data.type}`
            break
          case 'events':
            return `${this.data.name} is trending, posted in ${this.occurences} new messages today`
            break
          case 'influencers':
            return `${this.data.actorName} is generating a lot of buzz`
          // return `${this.data.actorName} is generating buzz, ${ this.occurences } new messages today`
            break
        }
      }
    },
    methods: {
      followRoute () {
        // Use the route property of the data object to determine the route to go to
        // If the route contains a filter state (i.e. a keyword) then reset the filters and set the keyword before pushing the route
        if (this.data.route.startsWith('/dashboard')) {
          this.$store.commit(BUZZ_MUTATION_TYPES.UPDATE_BUZZ_FILTER, { name: this.data.name, facet: 'topic', value: this.data.id })

          this.$router.push(this.data.route)
        } else if (this.data.route.startsWith('/actors')) {
          this.$router.push(this.data.route)
        } else {
          // Do nothing
        }
      },
      newsImageStyle (item) {
        if (!item.screenshot && !item.logo && !item.thumbnail && !item.featured_image_url && !item.website_screenshot) {
          return
        }

        if (item.screenshot) {
          return { backgroundImage: `url(${item.screenshot})` }
        }

        if (item.logo) {
          return { backgroundImage: `url(${item.logo})` }
        }

        if (item.thumbnail) {
          return { backgroundImage: `url(${item.thumbnail})` }
        }

        if (item.featured_image_url) {
          return { backgroundImage: `url(${item.featured_image_url})` }
        }

        if (item.website_screenshot) {
          return { backgroundImage: `url(${item.website_screenshot})` }
        }
      },
      totalFunding (funding, currency) {
        if (funding == 0 || funding == undefined || funding == null) {
          return 'unknown amount (other)'
        }
        return `${toAmount(funding, 5)} ${currency}`
      },
    }
  })
</script>
<style lang="scss">
  @import "../../../scss/_variables.scss";
  .buzz-card__general {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: auto;
    margin-bottom: 1em;
    border: 1px solid $color-borders;
    overflow: hidden;
    .buzz-card__is-topics {
      min-height: unset !important;
      height: unset !important;
    }
    .buzz-card__column-override {
      grid-template-columns: auto !important;
    }
    .buzz-card__general-container {
      a {
        display: grid;
        grid-template-columns: minmax(140px, 170px) auto;
        grid-template-rows: auto;
        min-height: 140px;
        max-height: 145px;
        text-decoration: none;
        cursor: pointer;

        .buzz-card__image-container {
          .buzz-card__article-image {
            width: 100%;
            height: 100%;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;
          }
        }

        .buzz-card__text-container {
          padding: 1em;
          display: block;
          grid-template-columns: auto;
          grid-template-rows: repeat(3, auto);
          .buzz-card__title {
            align-self: center;
            margin-bottom: .5em;
            font-size: 14px;
            letter-spacing: 1px;
            font-weight: 500;
            letter-spacing: 1.39px;
            line-height: 16px;
            font-family: $font-stack-primary;
            text-transform: uppercase;
            text-align: left;
            color: $color-text-grey;
            overflow-wrap: break-word;
          }

          .buzz-card__meta-data {
            margin-bottom: 0.5em;
            font-size: 9px;
            letter-spacing: 1px;
            font-weight: 100;
            letter-spacing: 1.39px;
            line-height: 16px;
            font-family: $font-stack-primary;
            text-transform: uppercase;
            text-align: left;
            color: $color-text-grey-light;
            overflow-wrap: break-word;
          }

          .buzz-card__description {
            margin-bottom: 0.5em;
            word-break: break-word;
            flex: 1;
            img {
              display: none;
            }
            ul, ol {
              margin-left: 1em;
            }
            margin-bottom: 0px;
            font-size: 10px;
            letter-spacing: 1px;
            font-weight: 100;
            letter-spacing: 1.39px;
            line-height: 16px;
            font-family: $font-stack-primary;
            text-align: left;
            color: $color-text-grey;
            overflow: hidden;
            max-height: 45px;
          }
        }
      }
    }
  }

  @media screen and (min-width:998px) and (max-width: 1336px) {
    .buzz-card__general {
      color: red;
      grid-template-columns: auto;
      grid-template-rows: auto;
      .buzz-card__is-topics {
        height: 63px !important;
      }
      .buzz-card__column-override {
        grid-template-rows: auto !important;
      }
      .buzz-card__general-container {
        a {
          grid-template-columns: auto;
          grid-template-rows: minmax(40px, 80px) auto;
          max-height: unset;
          min-height: unset;
          height: 170px;
          overflow: hidden;
          cursor: pointer;
        }
      }
    }
  }
</style>
