<template>
  <div class="actor-detail-buzz">
    <template v-if="closeUpGraph">
      <BuzzChartCloseUp :isInsideChartCloseup="true" :hasFilterTable="false" :chart="closeUpGraph" :chartData="closeUpGraphData" @collapse="showCloseUpGraph(null)" />
    </template>
    <template v-else>
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div class="row">
            <div class="col-xs-12 col-md-6 col-lg-6">
              <NewCard fillAvailableHeight :style="{'height' : chartCardsHeight}" title="Main topics" ref="bubbleChartContainer" class="actor-detail-buzz__chart-container">
                <template v-slot:headerLeft>
                  <!--<template v-if="activeTopic">
                     <span class="new-card__title"> - (related posts: <span class="buzz_title-link" :style="{ color: ecosystemColor }" @click="openPostsSidePanel(activeTopic)">{{activeTopic.name}}</span>)</span>
                  </template>-->
                </template>
                <template v-slot:headerRight>
                  <ds-button
                    variant="default"
                    :icon="closeUpGraph !== 'topics' ? 'arrow-expand' : 'arrow-compress'"
                    size="extra-small"
                    @click="showCloseUpGraph('topics')"
                  />
                </template>
                <p>
                  <small>
                    Click on a topic to analyse the most important keywords and the main influencers for this topic over the
                    last 30 days.
                  </small>
                </p>
                <bubble-chart :chartData="topicsData" style="margin-top: -80px;" />
              </NewCard>
            </div>
            <div class="col-xs-12 col-md-6 col-lg-6">
              <NewCard fillAvailableHeight :style="{'max-height' : chartCardsHeight}" title="Timeline" ref="timelineChartContainer" class="actor-detail-buzz__chart-container">
                <template v-slot:headerRight>
                  <ds-button
                    variant="default"
                    :icon="closeUpGraph !== 'timeline' ? 'arrow-expand' : 'arrow-compress'"
                    size="extra-small"
                    @click="showCloseUpGraph('timeline')"
                  />
                </template>
                <timeline-chart :chartData="timelineData" />
              </NewCard>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import { fetchBuzzData } from '../../api/buzz.js'

  import NewCard from '../../components/NewCard/NewCard.vue'
  import BubbleChart from '../../components/Chart/BubbleChart.vue'
  import TimelineChart from '../../components/Chart/TimelineChart.vue'
  import RankedList from '../../components/RankedList/RankedList.vue'
  import ActorDetailProfileMainContentBlock from './ActorDetailProfileMainContentBlock.vue'
  import BuzzChartCloseUp from '../../components/Dashboard/BuzzChartCloseUp.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'

  export default {
    name: 'ActorDetailBuzz',
    props: {
      actor: Object,
    },
    data () {
      return {
        closeUpGraph: null,
        topicsData: [],
        timelineData: [],
        cardBorderColor: '#f5a623',
        paging: {
          limit: 3,
          offset: 0,
        },
        buzzPosts: {
          items: [],
        },
        chartCardsHeight: '500px'
      };
    },
    computed: {
      ecosystemColor () {
        return this.$store.state.config.primaryColor
      },
      activeTopic () {
        return this.$store.state.buzz.filters.find(k => k.facet === 'topic')
      },
      activeActor () {
        return this.$store.state.buzz.filters.find(k => k.facet === 'actor')
      },
      activeKeywordType () {
        return this.$store.state.buzz.filters.find(k => k.facet === 'keyword_type')
      },
      activeChannel () {
        return this.$store.state.buzz.filters.find(k => k.facet === 'channel')
      },
      activeTimeframe () {
        return this.$store.state.buzz.filters.find(k => k.facet === 'time')
      },
      portfolio () {
        return this.$store.state.filters.portfolio
      },
      closeUpGraphData() {
        switch (this.closeUpGraph) {
          case 'topics':
            return this.topicsData;
          case 'timeline':
            return this.timelineData;
        }

        return [];
      },
    },
    methods: {
      async fetchAllBuzzData() {
        this.topicsData = [];
        this.timelineData = [];

        // First fetch the topics and influencers, await the response then make the call for the timeline
        var topicsAndInfluencers = await this.fetchBuzzData('topics-influencers')

        if (topicsAndInfluencers.topics) {
          this.topicsData = topicsAndInfluencers.topics;

          var timeline = await this.fetchBuzzData('timeline')

          if (timeline.data) {
            this.timelineData = timeline;
          }
        }
      },
      fetchBuzzData (type) {
        var portfolio = {}
        var fullParameters = {}

        if (!this.$route.path.startsWith('/actors/') && this.$store.state.filters.portfolio) {
            portfolio = { portfolio: this.portfolio }
        }

        fullParameters = Object.assign(portfolio, this.$store.getters.buzzQueryParameters);
        fullParameters = Object.assign({type: type, actor: this.actor.id}, fullParameters);

        return fetchBuzzData(fullParameters);
      },
      /*async fetchAllBuzzData() {
        // If this Actor detail tab is brought back to life, check the Buzz.vue for the new approach on fetching buzz related data
        // topics and timeline must be called one after another preferrably
        this.topicsData = [];
        this.timelineData = [];

        async fetchAllBuzzData() {
          // First fetch the topics and influencers, await the response
          // then make the call for the timeline
          var topicsAndInfluencers = await this.fetchBuzzData('topics-influencers');

          if (topicsAndInfluencers.topics) {
            this.topicsData = topicsAndInfluencers.topics;
            this.influencersData = topicsAndInfluencers.influencers;
          }

          var timeline = await this.fetchBuzzData('timeline');

          if (timeline.data) {
            this.timelineData = timeline;
          }
        }
      },
      fetchBuzzData (type) {
        var portfolio = {}
        var fullParameters = {}

        if (!this.$route.path.startsWith('/actors/') && this.$store.state.filters.portfolio) {
            portfolio = {portfolio: this.portfolio}
        }

        fullParameters = Object.assign(portfolio, this.$store.getters.buzzQueryParameters);
        fullParameters = Object.assign({type: 'topics-influencers', actor: this.actor.id}, fullParameters);

        fetchBuzzData(fullParameters).then(response => {
          //var topicsAndInfluencers = await this.fetchBuzzData('topics-influencers');
          if (response.topics) {
            this.topicsData = response.topics;
            this.influencersData = response.influencers;
          }
          /*if (type == 'topics') {
            this.topicsData = response;
          } else if (type == 'timeline') {
            this.timelineData = response;
          }
        }).catch(errors => {
          console.log(errors, "Failed to fetch topics data.");
        })
      },*/
      showCloseUpGraph(graphId) {
        this.closeUpGraph = graphId;
      },
      openPostsSidePanel (topic) {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component:"posts-panel", metaData: { topic: topic.value, actor: this.actor.id, keywordType: topic.facet }});
      },
    },
    mounted() {
      this.fetchAllBuzzData();
    },
    watch: {
      // NOTE: the buzzFilter is an array and is quite likely therefore not triggered in this watch,
      // therefore we need to watch the individual parameters
      activeTopic () {
        this.fetchAllBuzzData();
      },
      activeKeywordType () {
        this.fetchAllBuzzData();
      },
      activeActor () {
        this.fetchAllBuzzData();
      },
      activeChannel () {
        this.fetchAllBuzzData();
      },
      activeTimeframe () {
        this.fetchAllBuzzData();
      },
      portfolio () {
        this.fetchAllBuzzData();
      },
      actor () {
        this.fetchAllBuzzData();
      }
    },
    components: {
      BuzzChartCloseUp,
      BubbleChart,
      TimelineChart,
      NewCard,
      RankedList,
      ActorDetailProfileMainContentBlock,
    }
  }
</script>

<style lang="scss">

</style>
