import { createJSONRequest, createResource } from './create-request'
import querify from '../util/querify'

export const Portfolios = createResource('/api/portfolios')

export const createPortfolioSync = (portfolio, data) => createJSONRequest({
  method: 'POST',
  url: `/api/portfolios/${portfolio}/sync`,
  data,
})

export const syncedPortfolios = (ecosystem, limit, offset) => createJSONRequest({
  method: 'GET',
  url: `/api/portfolios?type=synced&ecosystem=${ecosystem}&limit=${limit}&offset=${offset}`,
})

export const deletePortfolioSync = (portfolio) => createJSONRequest({
  method: 'DELETE',
  url: `/api/portfolios/${portfolio}/sync`,
})

export const removePortfolioActors = (portfolio) => createJSONRequest({
  method: 'DELETE',
  url: `/api/portfolios/${portfolio}/actors`,
})

export const favouritePortfolio = (portfolio) => createJSONRequest({
  method: 'POST',
  url: `/portfolios/${portfolio}/favourite`,
})

export const unfavouritePortfolio = (portfolio) => createJSONRequest({
  method: 'POST',
  url: `/portfolios/${portfolio}/unfavourite`,
})

export const fetchFavourite = (limit, offset, filters) => createJSONRequest({
  method: 'GET',
  url: `/api/portfolios?type=favourites&limit=${limit}&offset=${offset}&${querify(filters)}`,
})

export const fetchAllFavourites = (filters = {}) => createJSONRequest({
  method: 'GET',
  url: `/api/portfolios?type=favourites&${querify(filters)}`,
})

export const fetchFlaggedPortfolio = (type) => createJSONRequest({
  method: 'GET',
  url: `/api/portfolios?type=${type}`,
})

export const fetchPublishedPortfolios = (role) => createJSONRequest({
  method: 'GET',
  url: `/api/portfolios?type=published&role=${role}`,
})

export const fetchLatest = (limit, offset, filters) => createJSONRequest({
  method: 'GET',
  url: `/api/portfolios?type=latest&limit=${limit}&offset=${offset}&${querify(filters)}`,
})

// NOTE: Make sure this function returns a Promise, i.e. adding "cancelIfLate" would not work as this function is used in some components where a Promise is expected
export const fetchPortfolio = (filters) => createJSONRequest({
  method: 'GET',
  url: `/api/portfolios?${querify(filters)}`,
})

export const fetchAllPortfolios = (filters) => createJSONRequest({
  method: 'GET',
  url: `/api/portfolios?${querify(filters)}`,
})

/**
 * Token management
 */

export const getLinkedApiTokens = (portfolioId) => createJSONRequest({
  method: 'GET',
  url: `/api/portfolio-tokens/${portfolioId}`,
})

export const updateLinkedTokens = (portfolioId, data) => createJSONRequest({
  method: 'POST',
  url: `/api/portfolio-tokens/${portfolioId}`,
  data: data,
})

/**
 * User & access management
 */

//  if 'role' is included, only works with "portfolio_member", if this parameter is passed, that's the only value that is supported
export const setPortfolioMembers = ({ portfolioId, data }) => createJSONRequest({
  method: 'POST',
  url: `/portfolios/${portfolioId}/members`,
  data,
})

export const getPortfolioMembers = (portfolioId) => createJSONRequest({
  method: 'GET',
  url: `/portfolios/${portfolioId}/members`,
})

export const lockPortfolio = (portfolioId) => createJSONRequest({
  method: 'POST',
  url: `/portfolios/${portfolioId}/lock`,
})

export const unlockPortfolio = (portfolioId) => createJSONRequest({
  method: 'POST',
  url: `/portfolios/${portfolioId}/unlock`,
})

export const includeActorToPortfolio = ({ portfolioId, data }) => createJSONRequest({
  method: 'POST',
  url: `/api/portfolios/${portfolioId}/include`,
  data,
})

export const excludeActorFromPortfolio = ({ portfolioId, data }) => createJSONRequest({
  method: 'POST',
  url: `/api/portfolios/${portfolioId}/exclude`,
  data,
})

export const updatePortfolioEnrichmentScope = (data) => createJSONRequest({
  method: 'POST',
  url: `/api/enrichment-scope/portfolios`,
  data: data,
})

export const updatePortfolioMonitorScope = (data) => createJSONRequest({
  method: 'POST',
  url: `/api/monitor-scope/portfolios`,
  data: data,
})

export const updatePublishedPortfolios = (data) => createJSONRequest({
  method: 'POST',
  url: '/api/publisher/portfolios',
  data: data,
})

/**
 * Portfolio comments
 */

export const createPortfolioComment = (portfolioId, comment) => createJSONRequest({
  method: 'POST',
  url: `/portfolios/${portfolioId}/comments`,
  data: {
    comment,
  },
})

export const updatePortfolioComment = (portfolioId, commentId, comment) => createJSONRequest({
  method: 'PUT',
  url: `/portfolios/${portfolioId}/comments/${commentId}`,
  data: {
    comment,
  },
})

export const getPortfolioComments = (portfolioId, limit, offset) => createJSONRequest({
  method: 'GET',
  url: `/portfolios/${portfolioId}/comments?limit=${limit}&offset=${offset}`,
})

export const deletePortfolioComment = (portfolioId, commentId) => createJSONRequest({
  method: 'DELETE',
  url: `/portfolios/${portfolioId}/comments/${commentId}`,
})

export const getPortfolioActivityLogs = (portfolioId, limit, offset) => createJSONRequest({
  method: 'GET',
  url: `/portfolios/${portfolioId}/comments?type=activity&limit=${limit}&offset=${offset}`,
})

/**
 * Agents
 */
export const fetchAgentPortfolioStatus = (portfolioId) => createJSONRequest({
  url: `/api/portfolio-agents/${portfolioId}`,
  method: 'GET',
})

export const runAgentForPortfolio = (portfolioId, action) => createJSONRequest({
  url: `/api/portfolio-agents/${portfolioId}`,
  method: 'PUT',
  data: {
    action: action,
  },
})

export const decodePortfolioId = (portfolioId) => createJSONRequest({
  url: `/api/portfolios/normalize-id/${portfolioId}`,
  method: `GET`,
})
