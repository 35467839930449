import { createJSONRequest } from '../../../api/create-request.js'

export const fetchOrCreateConversation = (subjectItemType: string) => {
  return createJSONRequest({
    method: 'POST',
    url: '/api/qanda-conversations',
    data: { subjectItemType }
  })
}

export const fetchConversationMessages = (conversationId: number) => {
  return createJSONRequest({
    method: 'GET',
    url: `/api/qanda-conversations/${conversationId}`
  })
}

export const chat = (conversationId: number, userMessage: string, focus: string) => {
  return createJSONRequest({
    method: 'POST',
    url: `/api/qanda-conversations/${conversationId}/chat`,
    data: { message: userMessage, focus }
  })
}

export const updateChatFeedback = (conversationId: number, messageId: number, feedback: number) => {
  return createJSONRequest({
    method: 'POST',
    url: `/api/qanda-conversations/${conversationId}/chat/${messageId}/feedback`,
    data: { feedback }
  })
}
