<template>
  <div class="tabs" :class="{'is-simplified': isSimplified}">
    <ul class="tabs__navigation" v-if="tabs.length >= 1 || edit">
      <li
        class="tabs__navigation__item"
        :class="{ 'tabs__navigation__item--active': tab === activeTab, 'tabs__navigation__item--beta': beta.includes(tab), 'tabs__navigation__item--dev': dev.includes(tab) }"
        :style="navigationItemStyle"
        v-for="(tab, index) in tabs"
        v-tooltip.top="getTooltipForTab(tab)"
        :id="'introjs-tab-' + tab.replaceAll(' ', '_')"
        :key="tab"
        @click="setActiveTab(tab)"
        tabindex="0"
      >
        {{ getTabName(tab) }} <span v-if="tabCounters">{{tabCounters[index] === undefined ? '' : ` (${tabCounters[index]})` }}</span>
      </li>
      <li
        v-if="edit"
        class="tabs__navigation__item tabs__navigation__item--link"
        style="float: right; color: white"
        @click="handleClickEdit"
        tabindex="0"
      >
        <ds-button label="Edit" class="tabs__navigation__item--link-report" type="button" icon="edit" size="extra-small" variant="ouline"/>
      </li>
      <!--<li
        v-if="report"
        class="tabs__navigation__item tabs__navigation__item--link"
        style="float: right; color: white"
        @click="setActiveTab('Reports')"
        tabindex="0"
      >
        <ds-button :label="reportLabel" class="tabs__navigation__item--link-report" type="button" icon="reporting" size="extra-small" variant="ouline"/>
      </li>-->
      <li
        v-if="notes"
        class="tabs__navigation__item tabs__navigation__item--link"
        style="float: right; color: white"
        @click="setActiveTab('Notes')"
        tabindex="0"
      >
        <ds-button label="Notes" class="tabs__navigation__item--link-report" type="button" icon="reporting" size="extra-small" variant="ouline"/>
      </li>
      <li
        v-if="displaySimilarActors"
        class="tabs__navigation__item tabs__navigation__item--link"
        style="float: right; color: white"
        @click="handleClickSimilarActors"
        tabindex="0"
        >
        <ds-button label="Similar actors" icon="community" class="tabs__navigation__item--link-suggestions" type="button" size="extra-small" iconSize="12" />
      </li>
      <li
      v-if="displayAgents"
      class="tabs__navigation__item tabs__navigation__item--link"
      style="float: right; color: white"
      @click="handleClickAgents"
      tabindex="0"
      >
        <ds-button label="Agents" icon="agent-head" class="tabs__navigation__item--link-suggestions" :badgeCount="hasAgentSuggestions" :noCount="true" type="button" size="extra-small" iconSize="22" />
      </li>
      <li
        v-if="suggestions"
        class="tabs__navigation__item tabs__navigation__item--link"
        style="float: right; color: white"
        @click="handleClickSuggestions"
        tabindex="0"
      >
        <ds-button label="Suggestions" class="tabs__navigation__item--link-suggestions" :badgeCount="true" :noCount="true" type="button" size="extra-small" />
      </li>
    </ul>
    <div class="tabs__content" :class="contentClass">
      <slot :name="activeTab"></slot>
    </div>
  </div>
</template>

<script>
  import slugify from '../../util/slugify'
  import { trackHeapEvent } from '../../util/analytics'
  import OnboardingMixin from '../../util/OnboardingMixin.js'
  import { defineComponent } from 'vue'
  export default defineComponent({
    props: {
      onboardingTranslationTabKeys: {
        type: Boolean,
        default: false
      },
      translateTabKeys: {
        type: Boolean,
        default: false
      },
      tabTooltips: {
        type: Object,
      },
      beta: {
        type: Array,
        default: () => []
      },
      dev: {
        type: Array,
        default: () => []
      },
      edit: Boolean,
      report: Boolean,
      notes: {
        type: Boolean,
        default: false
      },
      suggestions: {
        type: Boolean,
        default: false
      },
      isSimplified: {
        type: Boolean,
        default: false
      },
      hasAgentSuggestions: {
        type: Boolean,
        default: false
      },
      tabs: {
        type: Array,
        default: []
      },
      // use a separate tab counter to prevent problems with url hash matching
      tabCounters: {
        type: Array,
        default: () => []
      },
      reportLabel: {
        type: String,
        default: 'Notes'
      },
      displayAgents: {
        type: Boolean,
        default: false
      },
      displaySimilarActors: {
        type: Boolean,
        default: false
      },
      saveToUrl: {
        type: Boolean,
        default: false
      },
      contentClass: {
        type: String
      },
      switchTabHeapEventName: {
        type: String,
        default: 'actorDetail.switchTab'
      }
    },
    data () {
      return {
        activeTab: null
      }
    },
    computed: {
      navigationItemStyle () {
        if (this.isSimplified) {
          return { width: `calc(100% / ${this.tabs.length})` }
        }
      }
    },
    methods: {
      getTabName (tab) {
        if (this.translateTabKeys) {
          return this.$t(tab)
        } else if (this.onboardingTranslationTabKeys) {
          return this.onboardingTextConfig[tab]
        } else {
          return tab
        }
      },
      getTooltipForTab (tab) {
        if (tab && this.tabTooltips && this.tabTooltips[tab]) {
          return this.tabTooltips[tab]
        }
      },
      setActiveTab (tab) {
        this.activeTab = tab

        this.$bus.emit('tab', tab)

        trackHeapEvent(this.switchTabHeapEventName, { tab })

        if (this.saveToUrl) {
          this.$router.push({ hash: '#' + slugify(this.activeTab) })
        }
      },
      handleClickSuggestions () {
        trackHeapEvent('actorDetail.clickSuggestions')
        this.$emit('suggestionMode', true)
      },
      handleClickAgents () {
        trackHeapEvent('actorDetail.clickAgents')
        this.$emit('agentsMode', true)
      },
      handleClickEdit () {
        trackHeapEvent('actorDetail.clickEdit')
        this.$emit('editMode', true)
      },
      handleClickSimilarActors () {
        trackHeapEvent('actorDetail.clickSimilarActors')
        this.$emit('similarMode', true)
      },
      hashchange (evt) {
        const hash = window.location.hash.replace('#', '')
        const tabInHash = this.tabs.concat(['Reports']).find(tab => slugify(tab) === hash)

        if (this.saveToUrl && tabInHash) {
          this.activeTab = tabInHash
        } else {
          this.activeTab = this.tabs[0]
        }

        if (evt && evt.preventDefault) {
          evt.preventDefault()
        }
      }
    },
    mounted () {
      // If in the actor profile page, we get the tab that is set in the actor settings
      if (this.$route.path.startsWith('/actors/') && this.$route.params.id) {
        this.activeTab = this.$store.getters.userSettings && this.$store.getters.userSettings.actorProfileActiveTab ? this.$store.getters.userSettings.actorProfileActiveTab : this.tabs[0]
      }

      // UB2310367 Fix strange behaviour when clicking the notes tab
      if (window.location && window.location.hash === '#notes') {
        this.activeTab = 'Notes'
      } else {
        this.hashchange()

        window.addEventListener('hashchange', this.hashchange)
      }

      this.$bus.emit('tab', this.activeTab)
    },
    beforeUnmount () {
      window.removeEventListener('hashchange', this.hashchange)
    },
    mixins: [OnboardingMixin]
  })
</script>
