<template>
    <div>
      <div class="emp-features__feature-checkbox">
        <checkbox :model-value="isFeatureSelectedForPackage" @update:modelValue="toggleFeatureForPackage()"/>
      </div>
    </div>
</template>

<script>
  import Checkbox from '../../Form/Checkbox.vue'

  export default {
    name: 'EMPFeatureCheckbox.vue',
    props: ['packageOption', 'featureId'],
    computed: {
      isFeatureSelectedForPackage () {
        return this.packageOption.features && this.packageOption.features.find(feature => feature.id === this.featureId)
      },
    },
    methods: {
      toggleFeatureForPackage () {
        this.$emit('toggleFeatureForPackage')
      }
    },
    components: {
      Checkbox
    }
  }
</script>

<style scoped lang="scss">
  .emp-features__feature-checkbox {
    display: flex;
    justify-content: center;
  }
</style>
