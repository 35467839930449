<template>
  <div class="authentication-modal authentication-modal--big">
    <modal
      :id="modalId"
      ref="modal"
      :title="title"
      :enable-mask="false"
      :is-closeable="true"
    >
      <template v-slot:body>
        <div>
          <p>
            {{ changes.length }}
            {{ $store.getters.actorStrings.toLowerCase() }}
            will be affected. Are you sure?
          </p>
          <p>
            <ds-button
              variant="secondary"
              :icon="busy ? 'spinner' : 'check'"
              :label="busy ? 'Saving...' : 'Remove'"
              @click="handleRemoveDomain"
            />
          </p>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from './Modal.vue'

import { updateBulkActor } from '../../api/actors'



export default {
  name: 'remove-relation-modal',
  data () {
    return {
      busy: false,
      errors: {},
      modalId: 'REMOVE_DOMAIN'
    }
  },
  computed: {
    changes () {
      const id = parseInt(this.$store.state.ui.modalContext.id)
      const actors = this.$store.state.ui.modalContext.actors || []
      return actors.map(a => {
        if (a._domains && a._domains.length && a._domains.filter(r => r.id === id).length) {
          const b = { id: a.id }
          b.domains = a._domains.filter(r => r.id !== id)
          return b
        }
      }).filter(Boolean)
    },
    title () {
      return 'Remove domain'
    }
  },
  methods: {
    handleRemoveDomain (relationType, actor) {
      this.busy = true
      updateBulkActor(this.changes).then(() => {
        this.busy = false
        this.$bus.emit('actorUpdated')
        this.$refs.modal.close()
      })
    }
  },
  components: {
    Modal
  }
}
</script>
