<template>
  <div class="content-block" :class="classes">
    <div :class="{'content-block__header': true, 'has-bordered-bottom': !useHeaderTitle}">
      <h2 v-if="useHeaderTitle" class="h2" :style="textStyle" >{{ title }}</h2>
      <span v-else>{{ title }}</span>
      <slot name="header"></slot>
      <div v-if="showSideImage" class="content-block__header__side-image" :style="getSideImage"></div>
      <a v-if="link" :href="link" target="_blank" style="float: right;margin-top: -3px;" :style="buttonDisabled ? 'pointer-events: none;' : ''">
        <ds-button :label="buttonLabel" type="button" :icon="icon" size="small" :disabled="buttonDisabled"/>
      </a>
    </div>

    <div class="content-block__body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import { darken } from 'polished'

  export default {
    name: 'content-block',
    props: [
      'useHeaderTitle',
      'title',
      'variant',
      'sideImgUrl',
      'buttonDisabled',
      'link',
      'buttonLabel',
      'icon',
    ],
    computed: {
      showSideImage(){
        if(this.sideImgUrl){
          return true
        } else {
          return false
        }
      },
      getSideImage(){
        return `background-image: url('${this.sideImgUrl}')`
      },
      classes() {
        return [
          this.variant && `content-block--${this.variant}`
        ]
      },
      textStyle () {
        return {
          color: darken(0.30, this.$store.getters.primaryColor),
          display: 'inline',
        }
      }
    }
  }
</script>
