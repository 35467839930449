import { ACTION_TYPES as CONVERSATION_ACTION_TYPES } from '../store/modules/conversations.js'

export const ConversationsMixin = {
  data () {
    return {
      unreadCount: 0
    }
  },
  computed: {
    hasAccessToConversations () {
      if (this.$store.getters.isStaging || this.$store.getters.isCommunityEcosystem) {
        return true
      }

      // Supports legacy ecosystems that still need the "message_dashboard" option enabled
      if (!this.$store.getters.forIntelligenceAndOperations) {
        return this.$store.getters.isFoodleap || this.$store.getters.isAriadne || this.$store.getters.isKlik || this.$store.getters.isS4Fashion
      }

      return this.$store.getters.isEcosystemOperations
    },
  },
  mounted () {
    if (!this.hasAccessToConversations) {
      return
    }

    if (this.$store.getters.isLoggedIn && !this.$store.getters.isMobile) {
      let counter = 0
      setTimeout(() => {
        // We have a wide variety of API calls made when loading the app, this call can be held off for a while
        this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_UNREAD_COUNT)
      }, 10000)

      setInterval(() => {
        // Refresh only every 2 minutes if the document is hidden
        counter++
        if (document.hidden && counter % 6) {
          return
        }
        this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_UNREAD_COUNT).then(() => {
          if (this.unreadCount !== this.$store.state.conversations.listData.unreadCount) {
            this.unreadCount = this.$store.state.conversations.listData.unreadCount
            this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
          }
        })
      }, 120000)
    }
  },
}
