<template>
  <div class="icon-hover-tag" :class="[hoverTagOrientation , tagOrientation == 'top' ? 'icon-hover-tag-center-content' : '']" v-if="isVisible">
    <div v-if="tagOrientation == 'left'" class="icon-hover-tag__arrow-left"></div>
    <div v-else-if="tagOrientation == 'right'" class="icon-hover-tag__arrow-right"></div>
    <div v-else class="icon-hover-tag__arrow-bottom"></div>
    <div class="icon-hover-tag__text-box ">
      <span> {{tagText}}</span>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
  name: "icon-hover-tag",
  props: {
    tagText: {
      type: String
    },
    isVisible: {
      type: Boolean
    },
    tagOrientation: {
      type: String
    }
  },
  computed:{
    hoverTagOrientation() {
      if (this.tagOrientation == 'left') {
        return 'push-right'
      } else if (this.tagOrientation == 'right') {
        return 'push-left'
      } else {
        return 'push-bottom'
      }
    }
  },
 })
</script>
