<template>
  <div>
    <div class="fixed-heading">
      <div class="card__general side-panel__header">
        <div
          class="card__title side-panel__announcement-card__title side-panel__announcement-header-card__title"
        >
          Matchmaking
        </div>
        <div
          class="pull-right side-panel__announcement-header-button-container"
          style="text-align: right;"
        >
          <ds-button
            v-if="availableTaxonomyFilters && availableTaxonomyFilters.length && activeTab === 'Actor recommendations'"
            label="filter"
            icon="filter-box"
            size="small"
            :badge-count="!!selectedFilters.length"
            :no-count="!!selectedFilters.length"
            @click="showFilters"
          />
          <ds-button
            icon="remove" variant="minimal" @click="hidePreview" size="small"
            class="side-panel__announcement-header-icon"
          />
        </div>
      </div>
    </div>
    <tabs :tabs="enabledTabs" class="side-panel-portfolio__tab-section" :tab-counters="tabCounters" v-if="enabledTabs.length > 1"/>
    <div class="scrollable side-panel__scrollable__content" style="padding: 20px;">
      <ChallengeActorRecommendationSidePanel
        v-if="activeTab === actorRecommendationTabName"
        :filters="selectedFilters"
      />

      <ChallengeUsersSidePanel
        v-else-if="activeTab === invitedUsersTabName"
      />

      <ChallengeShortListedActorsSidePanel
        v-else-if="activeTab === shortListedActorsTabName"
        />
    </div>
  </div>
</template>

<script lang="ts">
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '@/store/modules/ui'

  import CustomFiltersModal from '@/components/Modals/AllFiltersModal/CustomFiltersModal.vue'
  import MODAL_IDS from '@/constants/modal-ids.js'
  import { taxonomyMapping, } from '@/store/modules/taxonomies.js'
  import Tabs from '@/components/Tabs/Tabs.vue'
  import TranslationsMixin from '@/util/TranslationsMixin.js'
  import ChallengeActorRecommendationSidePanel from '@/Domain/Challenge/Components/ChallengeActorRecommendationSidePanel.vue'
  import ChallengeUsersSidePanel from '@/Domain/Challenge/Components/ChallengeUsersSidePanel.vue'
  import { defineComponent } from '~/vue'
  import ChallengeShortListedActorsSidePanel from '@/Domain/Challenge/Components/ChallengeShortListedActorsSidePanel.vue'

  export default defineComponent({
    name: 'ManageChallengeInvitees.vue',
    props: {
      challengeData: {
        type: Object,
      },
    },
    data () {
      return {
        errors: {},
        selectedTab: 'Actor recommendations',
        selectedFilters: [],
        componentKey: 1,
        tabCounters: [],
        actorRecommendationTabName: 'Actor recommendations',
        invitedUsersTabName: 'Invited Users',
        shortListedActorsTabName: 'Actor short-list'
      }
    },
    computed: {
      activeTab: {
        get () {
          if (!this.enabledTabs || !this.enabledTabs.length) {
            return
          }

          if (this.enabledTabs.length > 1) {
            return this.selectedTab
          }

          return this.enabledTabs[0]
        },
        set (tabToSelect: string) {
          this.selectedTab = tabToSelect
        }
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      isEcosystemMember () {
        return this.$store.getters.isActor
      },
      challengeMatchmakingEnabled () {
        return this.$store.getters.challengeMatchmakingEnabled
      },
      enabledTabs () {
        if (this.isOwner) {
          return [this.actorRecommendationTabName, this.shortListedActorsTabName,  this.invitedUsersTabName]
        }

        if (this.isEcosystemMember && this.challengeMatchmakingEnabled) {
          return [this.actorRecommendationTabName, this.shortListedActorsTabName]
        }

        return []
      },
      availableTaxonomyFilters () {
        const availableFilters = this.$store.state.config.filterControls
        const availableTaxonomies = this.$store.state.taxonomies
        return availableFilters
          .filter(filterName => availableTaxonomies[taxonomyMapping()[filterName]])
          .filter(filterName => {
            return this.$store.state.taxonomies[taxonomyMapping()[filterName]] && this.$store.state.taxonomies[taxonomyMapping()[filterName]].length
          })
      },
    },
    methods: {
      updateFilters (newFilters) {
        if (newFilters && this.selectedFilters && !newFilters.length && !this.selectedFilters.length) {
          // This fixes a problem where updateFilters is called twice instead of once, the first time it is called
          return
        }

        this.selectedFilters = newFilters
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          filtersToDisplay: this.availableTaxonomyFilters,
          selectedFilters: newFilters,
        })
      },
      showFilters () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          filtersToDisplay: this.availableTaxonomyFilters,
          selectedFilters: this.selectedFilters || [],
        })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CUSTOM_FILTERS)
      },
      hidePreview () {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
    },
    mounted () {
      this.$bus.on('tab', (tab) => {
        this.activeTab = tab
      })
    },
    mixins: [TranslationsMixin],
    beforeUnmount () {
      this.$bus.off('tab')
    },
    components: {
      ChallengeShortListedActorsSidePanel,
      ChallengeUsersSidePanel,
      ChallengeActorRecommendationSidePanel,
      CustomFiltersModal,
      Tabs,
    },
  })
</script>
