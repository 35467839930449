<template>
  <div class="simple-card simple-card--clickable" @click="handleClick" :key="resizeKey">
    <div class="simple-card__media"
         :style="{backgroundImage: `url(${imgUrl})`, height: fixedImageSize}"/>
    <div class="simple-card__title" ref="title">{{ title }}</div>
    <div class="simple-card__content">{{ content }}</div>
    <div class="simple-card__hover-effect"></div>
    <slot name="actions"></slot>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'SimpleCard',
    props: {
      imgUrl: String,
      title: String,
      content: String,
      forceAspectRatio: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['click'],
    data: () => {
      return {
        fixedImageSize: '220px',
        resizeKey: 0,
      }
    },
    methods: {
      handleClick () {
        this.$emit('click')
      },
      calculateImageHeight () {
        if (this.$refs && this.$refs.title && this.forceAspectRatio) {
          // aspect ratio 0.5625 == 16:9
          this.fixedImageSize = `calc(0.5625 * ${getComputedStyle(this.$refs.title).width})`
          this.resizeKey++
        }
      },
    },
    mounted () {
      if (this.forceAspectRatio) {
        this.calculateImageHeight()
        window.addEventListener('resize', this.calculateImageHeight)
      }
    },
    beforeUnmount () {
      if (this.forceAspectRatio) {
        window.removeEventListener('resize', this.calculateImageHeight)
      }
    },
  })
</script>

<style scoped lang="scss">
  .simple-card {
    position: relative;
    margin-top: 1rem;
  }

  .simple-card--clickable {
    &:hover {
      cursor: pointer;

      .simple-card__hover-effect {
        top: -0.75rem;
        bottom: -0.75rem;
        left: -0.75rem;
        right: -0.75rem;
        background-color: #f7f7f7;
      }
    }
  }

  .simple-card__media {
    height: 220px;
    background-size: cover;
    background-position: center;
  }

  .simple-card__title {
    margin-top: 0.5rem;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1rem;
  }

  .simple-card__content {
    margin-top: 0.25rem;
    line-height: 1.5;
  }

  .simple-card__hover-effect {
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    border-radius: 0.25rem;
    background: transparent;
    transition: all .2s ease-in-out;
  }
</style>
