<template>
  <div :class="classes" :title="title">
    <icon v-if="icon" :style="{fill: iconFill}" :name="icon" :size="iconSize"></icon>
    {{ label }}
  </div>
</template>

<script>
import Icon from '../Icon/Icon.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'Tag',
  props: {
    label: String,
    size: String,
    title: String,
    icon: String,
    iconSize: {
      type: String,
      default: '14'
    },
    iconFill: {
      type: String,
      default: 'white'
    },
    clickable: Boolean,
  },
  computed: {
    classes() {
      return {
        'tag': true,
        'clickable': this.clickable,
        [`tag--${this.size}`]: this.size,
      }
    }
  },
  components: {
    Icon
  }
})
</script>

<style lang="scss">
.tag {
  display: inline-block;
  background: #EEEEEE;
  border: 1px solid #CECECE;
  border-radius: 1px;
  padding: 0.25rem;
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 0.75rem;
  word-break: break-word;

  &.clickable {
    cursor: pointer;
  }

  &:last-child {
    margin-right: 0;
  }
}

.tag--small {
  padding: 0.1rem 0.2rem;
  margin-right: 0.35rem;
  font-size: 11px;
  height: 18px;
}
</style>
