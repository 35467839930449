<template>
  <div>
    <div class="overlay-plus-button main-overlay-button" :class="{ 'is-upgrade-button': isUpgradeButton}" @click="clickOverlayButton('member')" @mouseover="mouseOver('main-button')" @mouseout="mouseOut('main-button')"
         v-tooltip.left="label">
      <icon name="plus" class="overlay-svg-icon overlay-svg-plus-icon"/>
    </div>
    <template v-if="childPermissions">
      <div class="overlay-plus-button child-overlay-button" @click="clickOverlayButton(child.redirection, child.tab, child.meta)" v-tooltip.left="child.tagText"
           @mouseover="mouseOver(child.redirection)" @mouseout="mouseOut(child.redirection)" :class="canShowChild ? 'child-overlay-button-fadein-' + index : 'child-overlay-button-fadeout-' + index"
           v-for="(child, index) in children">
        <icon :name="child.icon" :class="['overlay-svg-icon', 'overlay-svg-' + child.icon + '-icon']"/>
      </div>
    </template>
  </div>
</template>
<script>
  import IconHoverTag from '../Icon/IconHoverTag.vue'
  import ICONS from '../../../icons/manifest.json'

  import { trackHeapEvent } from '../../util/analytics'

  import { SiteTemplate } from '../../store/modules/config.js'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'hover-overlay-button',
    data () {
      return {
        ICONS,
        isHover: false,
        currentButtonHovered: null,
        canShowChild: false,
        fade: null,
      }
    },
    props: {
      label: String,
      orientation: String,
      isOnlyChallengeButton: Boolean,
      isUpgradeButton: Boolean,
      children: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      canCreateChallenge () {
        return this.$store.getters.canCreateChallenge
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      isMember () {
        return this.$store.getters.isMember
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      isActor () {
        return this.$store.getters.isActor
      },
      hasActivePortfolio () {
        return this.$store.state.managePortfolio.activePortfolio.id || this.$store.getters.activePortfolio.id
      },
      currentActivePage () {
        return this.$route.name
      },
      currentActiveDashboard () {
        return this.$route.params && this.$route.params.panel ? this.$route.params.panel : ''
      },
      isInActorProfile () {
        return this.$route.params.id && this.$route.name === 'actor-detail'
      },
      canCreateAnnouncement () {
        return this.isLoggedIn && this.$store.getters.canUsePublicAnnouncements && this.$store.getters.canCreateAnnouncement
      },
      shouldShowBasicHomeSimplified () {
        return this.$store.state.config.siteTemplate === SiteTemplate.NEW_SIMPLIFIED && this.$store.getters.isLoggedIn
      },
      canCreateResource () {
        if (!this.isLoggedIn) {
          return false
        }

        return this.isMember ||
        (
            (this.$store.state.config.viewDashboard || []).includes('knowledge-base') &&
          this.$store.getters.canUserUploadContent
          )
      },
      // Only logged in users can access (possible) children on the overlay button
      childPermissions () {
        return this.isLoggedIn
      },
    },
    methods: {
      clickOverlayButton (evt, tab, meta) {
        this.mouseOut()
        this.$emit('openPanel', evt, tab, meta)

        trackHeapEvent('global.clickPlusButton', { name: evt, tab })
      },
      mouseOver (hoveredObject) {
        clearTimeout(this.fate)

        switch (hoveredObject) {
          case 'main-button':
            this.currentButtonHovered = hoveredObject
            this.canShowChild = true
            break
          case 'create-portfolio':
            this.canShowChild = true
            this.currentButtonHovered = hoveredObject
            break
          case 'edit-portfolio':
            this.canShowChild = true
            this.currentButtonHovered = hoveredObject
            break
          case 'actor-edit':
            this.canShowChild = true
            this.currentButtonHovered = hoveredObject
            break
          case 'edit-connections':
            this.canShowChild = true
            this.currentButtonHovered = hoveredObject
            break
          case 'create-announcement':
            this.canShowChild = true
            this.currentButtonHovered = hoveredObject
            break
          case 'create-challenge':
            this.canShowChild = true
            this.currentButtonHovered = hoveredObject
            break
          case 'create-resource':
            this.canShowChild = true
            this.currentButtonHovered = hoveredObject
            break
        }
        this.isHover = true
      },
      mouseOut () {
        this.currentButtonHovered = null
        this.fate = setTimeout(f => {
          this.canShowChild = false
          this.isHover = false
        }, 600)
      },
    },
    mixins: [TranslationsMixin],
    components: {
      IconHoverTag,
    },
  })
</script>
<style lang="scss">
  @import "../../../scss/_variables.scss";

  .tooltipOverrideChild {
    width: 160px;
    margin-top: 10px;
    right: 55px;
  }

  .tooltipOverrideMain {
    width: 130px;
    margin-top: 10px !important;
    right: 60px !important;
  }

  .overlay-plus-button {
    position: absolute;
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: $color-datascouts-primary;
    bottom: 35px;
    right: 60px;
    z-index: 5;
    cursor: pointer;

    @media (max-width: $screen-md) {
      display: none;
    }

    &.is-upgrade-button {
      background-color:  #ccc;
    }

    .overlay-svg-icon {
      margin: 10px;
      position: relative;
      width: 30px;
      height: 30px;
    }

    .overlay-svg-plus-icon {
      width: 30px !important;
      height: 30px !important;
    }

    .overlay-svg-add-user-icon {
      margin: 5px !important;
      width: 20px !important;
      height: 20px !important;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .overlay-svg-share-icon {
      margin: 5px !important;
      width: 20px !important;
      height: 20px !important;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .overlay-svg-comment-icon {
      margin: 5px !important;
      width: 20px !important;
      height: 20px !important;

      svg {
        width: 18px;
        height: 18px;
      }
    }

    .overlay-svg-edit-icon {
      margin: 5px !important;
      width: 20px !important;
      height: 20px !important;
    }

    .overlay-svg-icon svg path {
      fill: white;
    }

    .svg-icon--invite-user svg {
      margin-top: 5px;
    }
  }

  .child-overlay-button {
    height: 30px;
    width: 30px;
    bottom: 100px;
    right: 68px;
    background-color: $color-datascouts-primary !important;
  }

  .child-overlay-button-fadein-0 {
    visibility: visible;
    opacity: 1;
    bottom: 100px;
    transition: visibility .2s, opacity 0.2s, bottom 0.2s linear;
  }

  .child-overlay-button-fadeout-0 {
    visibility: hidden;
    opacity: 0;
    bottom: 30px;
    transition: visibility .2s, opacity 0.2s, bottom 0.2s linear;
  }

  .child-overlay-button-fadein-1 {
    visibility: visible;
    opacity: 1;
    bottom: 150px;
    transition: visibility .2s, opacity 0.2s, bottom 0.2s linear;
  }

  .child-overlay-button-fadeout-1 {
    visibility: hidden;
    opacity: 0;
    bottom: 30px;
    transition: visibility .2s, opacity 0.2s, bottom 0.2s linear;
  }

  .child-overlay-button-fadein-2 {
    visibility: visible;
    opacity: 1;
    bottom: 200px;
    transition: visibility .2s, opacity 0.2s, bottom 0.2s linear;
  }

  .child-overlay-button-fadeout-2 {
    visibility: hidden;
    opacity: 0;
    bottom: 30px;
    transition: visibility .2s, opacity 0.2s, bottom 0.2s linear;
  }
</style>
