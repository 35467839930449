<template>
  <div class="nace-code-picker__container">
    <template v-if="naceCodes">
      <nace-tree
        v-for="naceSection in naceCodes"
        :actorId="actorId"
        :actorNaceCodes="chosenCodes"
        :mainCode="chosenMainCode"
        :searchQuery="searchQuery"
        :root="naceSection"
        :path="[naceSection.id]"
        :maxDepth="depth"
        :key="naceSection.id"
        :isFilter="isFilter"
        class="bold-nace-tree"
        :isParent="false"
      />
    </template>
  </div>
</template>

<script>
  import { getNaceCodes } from '../../api/config'

  import NaceTree from './NaceTree.vue'

  import { _unique } from '../../util/helpers'

  export default {
    name: 'NaceCodePicker.vue',
    props: {
      actorNaceCodes: Array,
      actorId: String,
      searchQuery: String,
      mainCode: String,
      isFilter: Boolean,
    },
    data () {
      return {
        naceCodes: [],
        depth: 4,
      }
    },
    computed: {
      chosenCodes () {
        if (!this.actorNaceCodes || !this.actorNaceCodes) {
          return []
        }

        return this.actorNaceCodes || []
      },
      chosenMainCode () {
        if (!this.mainCode) {
          return ''
        }

        return this.mainCode
      },
    },
    async created () {
      try {
        this.naceCodes = await getNaceCodes()
      } catch (e) {
        console.log(e)
      }
    },
    mounted () {
      // Listen for events coming from the nace tree
      this.$bus.on('nace-removed', (context) => {
        if (this.actorId !== context.actorId && !this.isFilter) {
          return
        }

        var codes = this.actorNaceCodes

        codes = codes.filter(v => !context.removedCodes.includes(v))

        this.$emit('naceCodesChanged', { codes })
      })

      this.$bus.on('nace-added', (context) => {
        if (this.actorId !== context.actorId && !this.isFilter) {
          return
        }

        var codes = this.actorNaceCodes || []

        codes = codes.concat(context.addedCodes)
        codes = _unique(codes)

        this.$emit('naceCodesChanged', { codes })
      })

      this.$bus.on('main-code-updated', (context) => {
        if (context.actorId !== this.actorId && !this.isFilter) {
          return
        }

        this.$emit('mainNaceChanged', { mainCode: context.mainCode })
      })
    },
    beforeUnmount () {
      this.$bus.off('nace-removed')
      this.$bus.off('nace-added')
    },
    components: {
      NaceTree,
    },
  }
</script>

<style scoped lang="scss">
  .nace-code-picker__container {
    height: calc(80vh/* modal height */ - 44px/* title height */ - 184px /* padding */
    );
    overflow: auto;
  }

  .bold-nace-tree {
    font-size: 14px;

    > .item-line {
      font-weight: bold;
    }
  }
</style>
