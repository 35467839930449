<template>
  <div class="range-slider">
    <div class="range-slider__info">
      <div class="range-slider__title">Founded</div>
      <div class="range-slider__range-label">{{ rangeLabel }}</div>
    </div>
    <div ref="slider"></div>
  </div>
</template>

<script>
import noUiSlider from 'nouislider'
import {
  fromPhpTimeToYear,
} from './slider'
import { defineComponent } from 'vue'

const maxYear = new Date().getFullYear() + 1
const minYear = (window.minFoundingYear || maxYear - 14) < 1950 ? 1950 : (window.minFoundingYear || maxYear - 14);

export default defineComponent({
  data () {
    return {
      // Live values
      start: null,
      end: null
    }
  },
  computed: {
    state () {
      return this.$store.state.filters.dateRanges;
    },
    uiState () {
      return [
        fromPhpTimeToYear(this.state.foundingDateStart) || minYear,
        fromPhpTimeToYear(this.state.foundingDateEnd) || maxYear
      ]
    },
    rangeLabel () {
      const startValue = fromPhpTimeToYear(this.start || this.state.foundingDateStart) || minYear
      const endValue = fromPhpTimeToYear(this.end || this.state.foundingDateEnd) || maxYear

      if (startValue <= minYear && endValue >= maxYear) {
        return 'All time'
      }
      if (startValue <= minYear) {
        return `before ${endValue + 1}`
      }
      if (endValue >= maxYear) {
        return `starting from ${startValue}`
      }

      return `${startValue} - ${endValue}`
    }
  },
  mounted () {
    noUiSlider.create(this.$refs.slider, {
      start: this.uiState,
      connect: true,
      step: 1,
      range: {
        min: minYear,
        max: maxYear
      }
    })

    this.$refs.slider.noUiSlider.on('set', (a, handleId, values) => {
      // Trigger filter update
      const start = values[0] > minYear ? Date.UTC(values[0], 0, 1) / 1000 : null
      const end = values[1] < maxYear ? Date.UTC(values[1], 0, 1) / 1000 : null
      this.$emit('on-update', { start, end })
    })

    this.$refs.slider.noUiSlider.on('slide', (a, handleId, values) => {
      this.start = values[0] > minYear ? Date.UTC(values[0], 0, 1) / 1000 : null
      this.end = values[1] < maxYear ? Date.UTC(values[1], 0, 1) / 1000 : null
    })
  },
  watch: {
    state: {
      deep: true,
      handler (v) {
        if (v) {
          this.start = this.state.foundingDateStart
          this.end = this.state.foundingDateEnd
          this.$refs.slider.noUiSlider.set(this.uiState, true)
        }
      }
    }
  }
})
</script>
