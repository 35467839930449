<template>
  <li :title="facetDisplayLabel" class="keyword" :class="classes" @click="handleRemoveClick">
    <icon name="remove" v-if="removeable"/>
    <icon :name="icon" v-if="icon"/>
    <div class="keyword__label">
      <div>
        {{ facetDisplayLabel }}
      </div>
      <div class="keyword__label__facet">
        {{ facetTitle }}
      </div>
    </div>
  </li>
</template>

<script>
  import { humanize } from '../../constants/properties'

  import KeywordLabelMixin from '../../util/KeywordLabelMixin.js'

  import { getReportFieldById } from '../../util/helpers.js'
  import FiltersMixin from '../../util/FiltersMixin'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'keyword-with-facet',
    props: {
      icon: String,
      label: [String, Number, Boolean, Object],
      modelValue: null,
      removeable: Boolean,
      simple: Boolean,
    },
    computed: {
      classes () {
        return [
          this.removeable && 'keyword--removeable',
          this.simple && 'keyword--simple',
        ].join(' ')
      },
      facetDisplayLabel () {
        return this.getGeoOrLabelForKeyword(this.modelValue, this.label)
      },
      facetTitle () {
        return this.getFacetLabelForKeyword(this.modelValue)
      }
    },
    methods: {
      humanize,
      handleRemoveClick () {
        this.$emit('remove', this.modelValue)
      },
    },
    mixins: [KeywordLabelMixin, FiltersMixin]
  })
</script>
