<template>
  <div class="fixed-heading">
    <div class="heading">
      <h1 class="score-list__heading">Dynamic Dashboard</h1>
      <div class="dashboard-subtitle__container">
        <p class="guide_text">
          Get a dynamic snapshot of your ecosystem.
          <template v-if="reportData && reportData.isHistorical">
            &nbsp;You are viewing this dashboard based on a historical snapshot of the
            ecosystem. - <a href="#" @click.prevent="getNonHistoricalData">View dashboard
            based on live data</a>
          </template>
          <template v-else-if="reportData && reportData.hasHistoricalSnapshot">
            &nbsp;There is a historical snapshot available - <a href="#"
                                                                @click.prevent="getHistoricalSnapshotData">
            View dashboard based on a historical snapshot</a>
          </template>
        </p>
        <div class="select-quarter">
          <dropdown :options="quarterOptions" v-model="selectedQuarterDate"
                    @update:modelValue="fetchReportData"/>
          <ds-button v-if="$store.getters.isOwner"
                      class="pull-right"
                      variant="outline"
                      label="Export"
                      icon="export"
                      :href="exportDynamicReport"
          />
        </div>
      </div>
    </div>
    <div class="has-padding scrollable" v-if="reportData && hasData">
      <div class="category-stats__container"
           v-if="hasCategoryData">
        <div v-for="(category, index) of reportData.categories" :key="'category' + index"
             class="category-stats__child" @click="showActorsForCategory(category)">
          <div class="category-stats-title">{{ category.label }}</div>
          <div class="category-stats-count__container">
            <div class="category-stats-count__value">{{ toAmount(category.count) }}</div>
            <div class="category-stats-count__diff"
                 :class="{ 'is-positive': calculateDiff(category) > 0 }"
                 v-if="category.previous_count && calculateDiff(category)">
              <span v-if="calculateDiff(category) > 0">+</span>{{
                calculateDiff(category)
              }}%
            </div>
          </div>
        </div>
      </div>
      <div class="report-data__container"
           v-if="hasInvestmentsData">
        <div style="display: flex">
          <h3 class="h3">TOTAL INVESTMENTS
            ({{ totalFunding }})</h3>
          <icon size="22" :name="isVisible('investments') ? 'plus' : 'minus'"
                style="margin-left: auto" @click="toggleVisibility('investments')"></icon>
        </div>
        <div class="row" v-if="isVisible('investments')">
          <div class="col-xs-12 col-md-8 horizontal-block">
            <div class="report-data-stats__container">
              <h5 class="h5">
                TOP INVESTMENTS
              </h5>
              <div v-if="reportData.investments.top_investments.length"
                   ref="investmentsTable">
                <table class="table">
                  <tr>
                    <th>Name</th>
                    <th>Round</th>
                    <th>Amount</th>
                  </tr>
                  <tr
                    v-for="(investment, index) of reportData.investments.top_investments"
                    :key="'investment' + index">
                    <td>
                      <a @click="showActor(investment)">{{ investment.name }}</a>
                    </td>
                    <td>
                      {{ investment.funding_round_name }}
                    </td>
                    <td>
                      {{ formatMoney(investment.funding_amount) }}
                    </td>
                  </tr>
                </table>
                <br>
                <ds-button label="View more" variant="primary" size="small" @click="showRecentlyFundedActors"/>
              </div>
              <h6 class="h6" v-else>
                No data available
              </h6>
            </div>
            <div class="report-data-stats__container">
              <h5 class="h5">
                TOP INVESTORS
              </h5>
              <div ref="topInvestorsTable"
                   v-if="reportData.investments.top_investors.length">
                <table class="table">
                  <tr>
                    <th>Name</th>
                    <th style="text-align: center;">Number of rounds</th>
                  </tr>
                  <tr
                    v-for="(investor, index) of reportData.investments.top_investors"
                    :key="'investor' + index">
                    <td>
                      <a @click="showActor(investor)">{{ investor.name }}</a>
                    </td>
                    <td style="text-align: center">
                      {{ investor.number_of_rounds }}
                    </td>
                  </tr>
                </table>
              </div>
              <h6 class="h6" v-else>
                No data available
              </h6>
            </div>
          </div>
          <div class="col-xs-12 col-md-4 vertical-block"
               v-if="reportData.investments.map_results.total">
            <h5 class="h5">
              &nbsp;
            </h5>
            <div class="vertical-block__fill">
              <div class="report-data-map__container" :style="{height: mapHeight}">
                <map-view-allow-multiple
                  v-if="!delayMapLoad"
                  ref="mapPane"
                  :allow-multiple-maps="true"
                  :data="getMapDataFor('investments')"
                  :getMapData="fetchReportData"
                  :getData="() => {}"
                  :disable-mouse-wheel-zoom="true"
                  :noSearchWhileMoving="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="report-data__container"
           v-if="hasVacanciesData">
        <div style="display: flex">
          <h3 class="h3">VACANCIES ({{
              toAmount(reportData.vacancies.total_vacancies)
            }})</h3>
          <icon size="22" :name="isVisible('vacancies') ? 'plus' : 'minus'"
                style="margin-left: auto" @click="toggleVisibility('vacancies')"></icon>
        </div>
        <div class="row" v-if="isVisible('vacancies')">
          <div class="col-xs-12 col-md-8 horizontal-block">
            <div class="report-data-stats__container">
              <h5 class="h5">
                COMPANIES HIRING
              </h5>
              <div v-if="reportData.vacancies.top_hiring_actors.length">
                <table class="table" ref="vacanciesTable">
                  <tr>
                    <th>Name</th>
                    <th style="text-align: center;">Open Positions</th>
                  </tr>
                  <tr
                    v-for="(vacancy, index) of reportData.vacancies.top_hiring_actors.slice(0,5)"
                    :key="'top_hiring_actor' + index">
                    <td>
                      <a @click="showActor(vacancy)">{{ vacancy.name }}</a>
                    </td>
                    <td style="text-align: center">
                      {{ vacancy.count }}
                    </td>
                  </tr>
                </table>
              </div>
              <h6 class="h6" v-else>
                No data available
              </h6>
            </div>
            <div class="report-data-stats__container" ref="vacancyPlotContainer">
              <h5 class="h5">
                TOP VACANCIES
              </h5>
              <plotly v-if="vacanciesChart" :chart="vacanciesChart"
                      :should-transform="false"/>
              <h6 class="h6" v-else>
                No data available
              </h6>
            </div>
          </div>
          <div class="col-xs-12 col-md-4 vertical-block">
            <h5 class="h5">
              &nbsp;
            </h5>
            <div class="vertical-block__fill">
              <div class="report-data-map__container" :style="{height: mapHeight}">
                <map-view-allow-multiple
                  v-if="!delayMapLoad"
                  ref="mapPane"
                  :allow-multiple-maps="true"
                  :data="getMapDataFor('vacancies')"
                  :getMapData="fetchReportData"
                  :getData="() => {}"
                  :disable-mouse-wheel-zoom="true"
                  :noSearchWhileMoving="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="report-data__container"
           v-if="hasGrantsData">
        <div style="display: flex">
          <h3 class="h3">TOTAL GRANTS
            ({{ formatMoney(reportData.grants.total_grant) }})</h3>
          <icon size="22" :name="isVisible('grants') ? 'plus' : 'minus'"
                style="margin-left: auto" @click="toggleVisibility('grants')"></icon>
        </div>
        <div class="row" v-if="isVisible('grants')">
          <div class="col-xs-12 col-md-8 horizontal-block">
            <div class="report-data-stats__container" ref="grantsPlotContainer">
              <h5 class="h5">
                &nbsp;
              </h5>
              <plotly v-if="grantsChart" :chart="grantsChart"
                      :should-transform="false"/>
              <h6 class="h6" v-else>
                No data available
              </h6>
            </div>
            <div class="report-data-stats__container">
              <h5 class="h5">
                TOP GRANTED PROJECTS
              </h5>
              <div v-if="reportData.grants.top_granted_actors.length">
                <table class="table" ref="grantsTable">
                  <tr>
                    <th>Name</th>
                    <th>Amount</th>
                  </tr>
                  <tr
                    v-for="(actor, index) of reportData.grants.top_granted_actors"
                    :key="'top_granted_actor' + index">
                    <td>
                      <a @click="showActor(actor)">{{ actor.name }}</a>
                    </td>
                    <td>
                      {{ formatMoney(actor.grant_total) }}
                    </td>
                  </tr>
                </table>
              </div>
              <h6 class="h6" v-else>
                No data available
              </h6>
            </div>
          </div>
          <div class="col-xs-12 col-md-4 vertical-block">
            <h5 class="h5">
              &nbsp;
            </h5>
            <div class="vertical-block__fill">
              <div class="report-data-map__container" :style="{height: mapHeight}">
                <map-view-allow-multiple
                  v-if="!delayMapLoad"
                  ref="mapPane"
                  :allow-multiple-maps="true"
                  :data="getMapDataFor('grants')"
                  :getMapData="fetchReportData"
                  :getData="() => {}"
                  :disable-mouse-wheel-zoom="true"
                  :noSearchWhileMoving="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="has-padding scrollable" v-if="reportData && !hasData && !isLoading">
      <h3 class="h3">No data available<span
        v-if="portfolioId"> for the selected portfolio</span></h3>
    </div>
  </div>
</template>

<script lang="ts">
  import numeral from 'numeral'
  import { getReportForPortfolio } from '../../api/dynamic-report.js'
  import { toAmount } from '../../util/currency.js'
  import {
    mapDataTransformer,
  } from '../../store/modules/actors.js'
  import Dropdown from '../Dropdown/Dropdown.vue'
  import MapViewAllowMultiple from '../MapView/MapViewAllowMultiple.vue'
  import _get from 'lodash/get'
  import Plotly from '../Chart/Plotly.vue'

  import moment from 'moment'
  import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters.js'
  import { toPhpTime } from '../Slider/slider.js'

  import querify from '../../util/querify.js'
  import { defineComponent } from 'vue'

  const DEFAULT_CHART_COLOURS = ['#4FEEC6', '#D6FCF5', '#F2FEFC', '#85F6E2', '#EBF7F8', '#BFEBE9', '#99E1E4', '#4EEAE1', '#EBF7F8']
  const MIN_YEAR = 2018
  let selectedDate = moment()
  const quarterOptions = []

  while (selectedDate.year() >= MIN_YEAR) {
    quarterOptions.push(selectedDate.format('YYYY-MM-DD'))
    selectedDate = selectedDate.subtract(1, 'quarter')
  }

  export default defineComponent({
    name: 'dynamic-dashboard',
    data () {
      return {
        selectedQuarterDate: moment().format('YYYY-MM-DD'),
        quarterOptions: quarterOptions.map(option => {
          return {
            value: option,
            label: `Q${moment(option).quarter()} ${moment(option).year()}`,
          }
        }),
        reportData: null,
        mapData: null,
        sectionVisibility: { investments: true, vacancies: true, grants: true },
        chartSize: 0,
        isHistorical: false,
        isLoading: false,
        delayMapLoad: false,
      }
    },
    computed: {
      totalFunding () {
        return numeral(this.reportData.investments.total_funding).format('$0.00a')
      },
      hasData () {
        return this.hasCategoryData || this.hasInvestmentsData || this.hasVacanciesData || this.hasGrantsData
      },
      hasCategoryData () {
        return this.reportData && this.reportData.categories && this.reportData.categories.length
      },
      hasInvestmentsData () {
        return this.reportData && this.reportData.investments && this.reportData.investments.total_funding
      },
      hasVacanciesData () {
        return this.reportData && this.reportData.vacancies && this.reportData.vacancies.total_vacancies
      },
      hasGrantsData () {
        return this.reportData && this.reportData.grants && this.reportData.grants.total_grant
      },
      mapHeight () {
        const vacanciesTableHeight = this.getNumberOfPixels('vacanciesTable.clientHeight')
        const grantsTableHeight = this.getNumberOfPixels('grantsTable.clientHeight')
        const investmentsTableHeight = this.getNumberOfPixels('investmentsTable.clientHeight')
        const topInvestorsTableHeight = this.getNumberOfPixels('topInvestorsTable.clientHeight')
        const minHeight = 300

        return Math.max(this.chartSize, vacanciesTableHeight, grantsTableHeight, investmentsTableHeight, topInvestorsTableHeight, minHeight) + 'px'
      },
      portfolioId () {
        return this.$store.state.filters.portfolio
      },
      topGrantsChartData () {
        const result = {}
        result.values = this.reportData.grants.grant_split.slice(0, 5).map(category => category.grant_total)
        result.labels = this.reportData.grants.grant_split.slice(0, 5).map(category => category.category_label)
        const totalGrant = result.values.reduce((a, b) => a + b, 0)
        if (this.reportData.grants.total_grant > totalGrant) {
          result.values.push(this.reportData.grants.total_grant - totalGrant)
          result.labels.push('other')
        }
        return result
      },
      topVacanciesChartData () {
        const result = {}
        result.values = this.reportData.vacancies.top_vacancies.map(vacancy => vacancy.count)
        result.labels = this.reportData.vacancies.top_vacancies.map(vacancy => vacancy.title)
        const totalDisplayedVacanies = result.values.reduce((a, b) => a + b, 0)
        if (this.reportData.vacancies.total_vacancies > totalDisplayedVacanies) {
          result.values.push(this.reportData.vacancies.total_vacancies - totalDisplayedVacanies)
          result.labels.push('other')
        }
        return result
      },
      config () {
        return this.$store.state.config
      },
      vacanciesChart () {
        if (this.reportData && this.reportData.vacancies.total_vacancies && this.chartSize) {
          return {
            plotlyType: 'bar',
            layout: {
              showlegend: false,
              // legend: { 'orientation': 'h' },
              margin: { 't': 0, 'b': 0, 'l': 0, 'r': 0 },
              height: this.chartSize,
              width: this.chartSize,
              // colorway: DEFAULT_CHART_COLOURS,
            },
            datasets: [{
              orientation: 'h',
              x: this.topVacanciesChartData.values,
              y: this.topVacanciesChartData.labels,
              text: this.topVacanciesChartData.labels,
              marker: {
                color: DEFAULT_CHART_COLOURS
              },
              textposition: 'auto',
              // customdata: this.topVacanciesChartData.values.map((value, index) => {
              //   const percentage = value / this.reportData.vacancies.total_vacancies
              //   return percentage > 0.05 ? (toAmount(100 * percentage) + '%') : ''
              // }),
              // texttemplate: '%{customdata}',
              // hole: 0.7,
              type: 'bar',
            }],
          }
        }
      },
      grantsChart () {
        if (this.reportData && this.reportData.grants.top_granted_actors && this.chartSize) {
          return {
            plotlyType: 'pie',
            layout: {
              showlegend: true,
              legend: { 'orientation': 'h' },
              margin: { 't': 0, 'b': 0, 'l': 0, 'r': 0 },
              height: this.chartSize,
              width: this.chartSize,
              colorway: DEFAULT_CHART_COLOURS,

            },
            datasets: [{
              values: this.topGrantsChartData.values,
              labels: this.topGrantsChartData.labels,
              customdata: this.topGrantsChartData.values.map(value => {
                const percentage = value / this.reportData.grants.total_grant
                return percentage > 0.05 ? toAmount(value) : ''
              }),
              texttemplate: '%{customdata}',
              hole: 0.7,
              type: 'pie',
            }],
          }
        }
      },
      exportDynamicReport () {
        const params = {
          portfolio: this.portfolioId,
          historical: this.isHistorical,
          date: this.selectedQuarterDate,
          export: true
        }

        return `/dynamic-report/download?${querify(params)}`
      },
    },
    methods: {
      formatMoney (amount) {
        return numeral(amount).format('$0.00a')
      },
      showRecentlyFundedActors () {
        const selectedDate = moment(this.selectedQuarterDate)
        let start = selectedDate.year()
        start += (selectedDate.quarter() - 1) * 0.25
        const end = start + 0.25

        const selectedPortfolio = this.$store.state.filters.portfolio
        this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)
        this.$store.commit(FILTERS_MUTATION_TYPES.SET_PORTFOLIO, selectedPortfolio)
        this.$store.commit(FILTERS_MUTATION_TYPES.UPDATE_FUNDING_DATE_RANGE, {
          start: toPhpTime(start),
          end: toPhpTime(end),
        })
        this.$router.push('/actors')
      },
      showActorsForCategory (category) {
        const selectedPortfolio = this.$store.state.filters.portfolio
        this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)
        this.$store.commit(FILTERS_MUTATION_TYPES.SET_PORTFOLIO, selectedPortfolio)
        this.$store.commit(FILTERS_MUTATION_TYPES.TOGGLE_LEGEND_ITEM, {
          facet: 'category',
          value: category.id,
        })
        this.$router.push('/actors')
      },
      getMapDataFor (type) {
        if (_get(this.reportData, type + '.map_results.companies')) {
          return {
            companies: this.reportData[type].map_results.companies.map(mapDataTransformer),
          }
        }
      },
      isVisible (section) {
        return this.sectionVisibility[section]
      },
      toggleVisibility (section) {
        this.sectionVisibility[section] = !this.sectionVisibility[section]
        this.delayMapLoad = true
        this.$nextTick(() => {
          this.delayMapLoad = false
        })
      },
      showActor (actorContainer) {
        this.$router.push('/actors/' + actorContainer.id)
      },
      toAmount,
      calculateDiff (category) {
        return Math.round(100 * (category.count - category.previous_count) / category.previous_count) / 100
      },
      getNumberOfPixels (pixelString) {
        return _get(this, `$refs.${pixelString}`) || 0
      },
      calculateChartSize () {
        const vacancyChartSize = this.getNumberOfPixels('vacancyPlotContainer.clientWidth')
        const grantsChartSize = this.getNumberOfPixels('grantsPlotContainer.clientWidth')
        this.chartSize = Math.max(vacancyChartSize, grantsChartSize)
      },
      getHistoricalSnapshotData () {
        this.isHistorical = true
        this.fetchReportData()
      },
      getNonHistoricalData () {
        this.isHistorical = false
        this.fetchReportData()
      },
      fetchReportData () {
        const tl = this.$store.state.filters.mapBounds.tl
        const br = this.$store.state.filters.mapBounds.br

        const params = {
          portfolio: this.portfolioId,
          historical: this.isHistorical,
          date: this.selectedQuarterDate,
        }

        if (tl && br && tl.length && br.length && tl[0] !== br[0]) {
          params.tl = tl
          params.br = br
        }

        this.isLoading = true

        getReportForPortfolio(params)
          .then(result => {
            this.reportData = result
            this.delayMapLoad = true
            this.$nextTick(() => {
              this.calculateChartSize()
              this.delayMapLoad = false
            })
          })
          .finally(() => {
            this.isLoading = false
          })
      },
    },
    mounted () {
      this.fetchReportData()
      this.$bus.on('resize', () => {
        this.calculateChartSize()
      })
    },
    beforeUnmount () {
      this.$bus.off('resize')
    },
    watch: {
      portfolioId: {
        deep: true,
        handler () {
          this.fetchReportData()
        },
      },
    },
    components: {
      Plotly,
      Dropdown,
      MapViewAllowMultiple,
    },
  })
</script>

<style lang="scss" scoped>
  @import "../../../scss/_variables.scss";

  .category-stats__container {
    border: 1px solid $color-borders;
    display: inline-block;
    width: 100%;
    font-size: 20px;
    margin-bottom: 2rem;
  }

  .category-stats__child {
    width: 20%;
    float: left;
    text-align: center;
    margin: 2rem 0;
    display: flex;
    flex-direction: column;
    cursor: pointer;

    .category-stats-title {
      text-transform: uppercase;
      font-family: $font-stack-secondary;
      font-size: 12px;
      margin-bottom: 10px;
    }

    .category-stats-count__container {
      margin: 0 auto;

      .category-stats-count__value {
        font-size: 55px;
      }

      .category-stats-count__diff {
        color: red;
        font-family: $font-stack-secondary;
        font-size: 12px;
        text-align: right;

        &.is-positive {
          color: green;
        }
      }

    }

    @mixin with-count($n) {
      @if $n == 1 {
        &:first-child:nth-last-child(1) {
          @content;
        }
      } @else {
        &:first-child:nth-last-child(#{$n}),
        &:first-child:nth-last-child(#{$n}) ~ & {
          @content;
        }
      }
    }

    @for $i from 1 through 5 {
      @include with-count($i) {
        width: calc(100% / $i);
      }
    }

    @include with-count(6) {
      width: 33.3333%;
    }

    @include with-count(7) {
      width: 25%;
    }

    @include with-count(8) {
      width: 25%;
    }
  }

  .report-data-map__container {
    position: relative;
    height: 100%;
    width: 100%;
    border: 1px solid $color-borders;

    @media (max-width: $screen-lg) {
      height: 290px;
    }
  }

  .report-data__container {
    padding: 2rem;
    margin-bottom: 2rem;
    border: 1px solid $color-borders;
    font-size: 12px;

    .horizontal-block {
      display: flex;
    }

    .vertical-block {
      display: flex;
      flex-direction: column;

      .vertical-block__fill {
        flex-grow: 1;
      }
    }
  }

  .report-data-stats__container {
    width: 50%;
    margin-right: 2rem;
    display: flex;
    flex-direction: column;

    div:last-child {
      flex-grow: 1;

      :deep(.charts__plot) {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .has-padding {
    font-size: 0;
  }

  h3.h3 {
    font-size: 20px;
    line-height: 22px;

    &.clickable {
      cursor: pointer;
    }
  }

  h5.h5 {
    font-size: 16px;
    font-family: $font-stack-secondary;
    margin: 2rem 0 1rem 0;
    color: $color-text-grey-light;
  }

  .table {
    font-size: 16px;

    th, td {
      border-left: 0;
      padding: 10px;
    }

    th {
      height: 45px;
    }
  }

  .svg-icon {
    cursor: pointer;
  }

  @media print {
    .heading {
      display: none;
    }
  }

  a {
    cursor: pointer;
  }

  h6.h6 {
    font-size: 14px;
  }

  .dashboard-subtitle__container {
    display: flex;
    width: 100%;

    p {
      height: 100%;
      margin: auto 0;
      padding-right: 20px;
    }

    .select-quarter {
      width: 150px;
      margin-left: auto;
      display: flex;

      :deep(.multiselect__tags) {
        height: 100%;
      }
    }
  }
</style>
