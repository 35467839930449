<template>
  <div>
    <form-group :label="humanize(property)" v-for="(suggestions, property) in byProp" :key="property">
      <template v-if="property === 'reports'">
        <div v-for="(suggestion, key) in suggestions">
          <input class="checkbox--suggestion" type="checkbox" v-model="modelValue.status" true-value="selected" false-value="in_progress">
          <knowledge-report :actor="modelValue.actor" :reports="modelValue.value" />
        </div>
      </template>
      <template v-else-if="Array.isArray(modelValue.value) && typeof modelValue.value[0] === 'string'">
        <checkbox
          v-for="(suggestion, index) in suggestions"
          :key="'a' + index"
          multiple
          @update:modelValue="modelValue.accepted_value = $event"
          :model-value="modelValue.accepted_value"
          :label="toHandle(suggestion.value)"
          :val="suggestion.value"
          :href="suggestion.value"
        />
      </template>
      <template v-else-if="typeof modelValue.value === 'string'">
        <checkbox
          v-for="(suggestion, index) in suggestions"
          :key="'b' + index"
          @update:modelValue="modelValue.accepted_value = $event ? modelValue.value : null"
          :model-value="modelValue.accepted_value"
          :label="toHandle(suggestion.value)"
          :val="suggestion.value"
          :href="suggestion.value"
        />
      </template>
      <template v-else>
        Not supported
      </template>

      <p v-if="suggestions.length > 2 && property !== 'reports'">
        <checkbox label="Select all" @update:modelValue="selectAll" :model-value="suggestions.length === selected.length" />
      </p>
    </form-group>
  </div>
</template>

<script>
import _groupBy from 'lodash/groupBy'

import Checkbox from '../Form/Checkbox.vue'
import KnowledgeReport from '../KnowledgeSharing/KnowledgeReport.vue'

import { humanize } from '../../constants/properties'

function toHandle (s) {
  return s.slice(s.lastIndexOf('/') + 1)
}

function extractBy (item, by) {
  const value = item[by]
  if (Array.isArray(value)) {
    return value.map(v => Object.assign({}, item, { [by]: v }))
  }
  return [item]
}

export default {
  props: ['modelValue'],
  emits: ['update:modelValue'],
  data () {
    return {
      selected: []
    }
  },
  computed: {
    byProp () {
      return _groupBy(extractBy(this.modelValue, 'value'), 'property')
    },
  },
  methods: {
    humanize,
    toHandle,
    selectAll (value) {
      this.modelValue.accepted_value = this.modelValue.value
    }
  },
  components: {
    Checkbox,
    KnowledgeReport,
  }
}
</script>
