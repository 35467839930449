<template>
  <div>
    <vue-cal
      :locale="locale"
      style="min-height: 250px;padding: 0 10px;"
      class="vuecal--rounded-theme vuecal--green-theme"
      hide-view-selector
      :time="false"
      active-view="month"
      :disable-views="['years', 'year', 'week', 'day']"
      xsmall
      @cell-click="onCellClick($event)"
      :events="events"
      @view-change="loadMonth($event)"
      :on-event-click="onEventClick"
    />
    <!-- today-button -->
    <!-- show-week-numbers -->
    <!-- events-on-month-view="short" -->
  </div>
</template>

<script>
  // https://antoniandre.github.io/vue-cal/
  import VueCal from 'vue-cal'
  import 'vue-cal/dist/vuecal.css'
  // https://antoniandre.github.io/vue-cal/#ex--internationalization
  import 'vue-cal/dist/i18n/nl.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Calendar',
    props: {
      events: {
        type: Array,
        required: false,
      },
      selectedMonth: {
        type: Object,
        required: false,
      },
    },
    data () {
      return {
        selectedDate: null,
      }
    },
    computed: {
      locale () {
        const activeLocale = this.$store.getters.activeLocale
        if (!['nl', 'en'].includes(activeLocale)) {
          return 'en'
        }

        return activeLocale
      },
    },
    methods: {
      onEventClick (event) {
        window.location.replace(event.link)
      },
      onCellClick (event) {
        this.selectedDate = event

        this.$emit('cell-click', this.selectedDate)
      },
      loadMonth (event) {
        this.selectedDate = event

        this.$emit('month-click', this.selectedDate)
      },
    },
    components: {
      VueCal,
    },
  })
</script>

<style lang="scss">
  @import "../../scss/_variables.scss";

  .vuecal {
    color: var(--primary-community);
    border: 1px solid var(--primary-community-extra-lightest);
    padding-bottom: 15px;
    box-shadow: none !important;
  }

  .vuecal__title-bar {
    font-size: 12px !important;
    color: var(--primary-community);
    background-color: transparent !important;
    justify-content: center;
    padding: 15px 0;

    > .vuecal__title {
      font-weight: bold;
      // flex: 0 !important;
    }

    button, .angle {
      color: var(--primary-community);
    }
  }

  .vuecal--rounded-theme.vuecal--green-theme:not(.vuecal--day-view) .vuecal__cell--today .vuecal__cell-content {
    background-color: var(--primary-community-lighter);
  }

  .vuecal--rounded-theme.vuecal--green-theme:not(.vuecal--day-view) .vuecal__cell--selected .vuecal__cell-content {
    border-color: var(--primary-community);
    background-color: var(--primary-community);
    color: white;
  }

  .vuecal--green-theme .vuecal__cell-events-count, .vuecal--green-theme .vuecal__menu {
    background-color: var(--primary-community);
  }

  .vuecal--rounded-theme.vuecal--green-theme:not(.vuecal--day-view) .vuecal__cell-content {
    background-color: white;
  }

  .vuecal__cell--disabled {
    text-decoration: line-through;
  }

  .vuecal--rounded-theme .vuecal__cell.vuecal__cell--before-min {
    opacity: .4;
  }

  .vuecal__event-title {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .vuecal--no-time .vuecal__event {
    cursor: pointer;
    min-height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 1px 1px 6px #00000033;
  }

  // Non-event days return no results, which causes scrolling issues. For now, let's make those days unclickable
  // because we assume to always have at least one result when filtering
  .vuecal--rounded-theme .vuecal__cell:not(.vuecal__cell--has-events) {
    pointer-events: none !important;
  }

  /* Cell background indicator */
  .vuecal--rounded-theme {
    .vuecal__cell--has-events {
      > .vuecal__cell-content {
        border: 2px solid var(--primary-community);

        &:hover {
          cursor: pointer;
        }
      }
    }

    .vuecal__cell-content {
      width: 23px;
      height: 23px;
      border-radius: 23px;

      .vuecal__cell-date {
        font-size: .7rem;
      }

      &:hover {
        .vuecal__cell-date {
          font-size: .9rem;
        }
      }
    }
  }

  .vuecal__cell-events-count {
    display: none;
  }

  .vuecal--xsmall .vuecal__heading .small,
  .vuecal__heading .small {
    font-weight: bold;
    display: block !important;
  }

  .vuecal--small .vuecal__heading .full,
  .vuecal--small .vuecal__heading .xsmall,
  .vuecal--xsmall .vuecal__heading .full,
  .vuecal__heading .xsmall {
    display: none !important;
  }
</style>
