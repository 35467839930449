<template>
  <div v-if="enabled" class="card card--analytics"
       :class="{ 'card--split': splittable && expanded, 'card--expanded': expanded, 'card--collapsed': collapsed }">
    <div v-if="splittable && expanded" class="card__split">
      <div class="tree__parent">SPLIT BY</div>
      <div>
        <span class="tree__label">
          <radio-button :val="null" :model-value="splitBy" @update:modelValue="splitChart()" label="Don't split"
                        name="splitBy"/>
        </span>
      </div>
      <div v-for="opt in splitOptions" class="a--decorated">
        <span class="tree__label">
          <radio-button :val="opt" :model-value="splitBy" @update:modelValue="splitChart(opt)"
                        :title="splitOptionLabels[opt]" :label="splitOptionLabels[opt]" name="splitBy"/>
        </span>
      </div>
    </div>
    <div class="pull-right">
      <button
          class="card__btn card__btn--pct"
          v-if="showPercentualSwitch && !splitBy"
          title="Switch to a percentual view"
          @click="toggleYValues('percentual')"
          :style="percentualBtnStyle"
      >%
      </button>

      <button
          class="card__btn card__btn--pct"
          v-if="showAverageSwitch && !splitBy"
          title="Switch to an average per actor view"
          :style="avgBtnStyle"
          @click="toggleYValues('average')"
      >
        <icon name="account" size="14"></icon>
      </button>

      <button
          class="card__btn"
          v-if="switchable && !splitBy"
          title="Switch axis"
          @click="horizontal = ! horizontal"
      >
        <icon name="bar-graph" :class="horizontal ? 'svg-icon--90deg' : ''"/>
      </button>

      <ds-button
          v-if="collapse && !expanded" :title="collapsed ? 'Expand' : 'Collapse'" size="small"
          :icon="collapsed ? 'plus' : 'minus'" variant="minimal" @click="collapsed = !collapsed"
      />
      <ds-button
          v-if="(expand && !collapsed) || expanded" :title="expanded ? 'Back to normal' : 'Expand'" size="small"
          :icon="expanded ? 'arrow-compress' : 'arrow-expand'"
          variant="default" @click="expanded = !expanded"
      />
    </div>
    <div class="card__title" v-if="title">{{ title }}</div>
    <div class="card__description" v-if="!collapsed">
      <slot v-if="! hasEmptySplitByData"></slot>
      <div class="explore__empty-state" v-else>
        No results were found for the current selection.
      </div>
    </div>
  </div>
</template>

<script lang="ts">

import RadioButton from '../Form/RadioButton.vue'
import { humanize } from '../../constants/properties.js'
import { strUpto } from '../../util/string'

import ConfigMixin from '../../util/ConfigMixin.js'
import { getAllReportFields, getReportFieldByReportIdentifier } from '../../util/helpers.js'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    RadioButton
  },
  props: {
    collapse: Boolean,
    enable: Boolean,
    expand: Boolean,
    module: String,
    splittable: Boolean,
    title: String,
    customAreaSplitEnabled: {
      type: Boolean,
      default: false,
    },
    reportSplitEnabled: {
      type: Boolean,
      default: false,
    },
    isExpandedChart: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      splitBy: null,
      customFilters: []
    }
  },
  computed: {
    showPercentualSwitch() {
      return this.chart && (this.chart.type == 'Histogram' || this.chart.type == 'DateHistogram');
    },
    showAverageSwitch() {
      return this.chart && this.chart.type == 'DateHistogram' && this.chart.aggregate == 'sum';
    },
    percentualBtnStyle() {
      if (this.percentual) {
        return 'background-color: #eee';
      }

      return 'background-color: transparent';
    },
    avgBtnStyle() {
      if (this.average) {
        return 'background-color: #eee';
      }

      return 'background-color: transparent';
    },
    switchable() {
      return !this.expanded && this.chart && ['Histogram', 'TopActors'].includes(this.chart.type) && this.chart.property !== 'completeness' && (Math.max.apply(Math, this.chart.datasets.map(data => data.x ? data.x.length : 0)) < 22);
    },
    chart() {
      return this.$parent.charts.find(c => this.module == c.id);
    },
    collapsedAnalytics() {
      return ((this.$store.state.user.profile.settings || {}).collapsedAnalytics || '')
          .split(',')
          .filter(Boolean);
    },
    hasEmptySplitByData() {
      // If no split property is passed, return true, the card can handle empty data, unless the card has splitBy options
      // that result in 0 results
      if (this.chart.datasets && this.chart.datasets.length && this.chart.datasets[0].split) {
        return !this.chart.datasets[0].total || this.chart.datasets[0].total == 0;
      }

      // If we're in an expanded mode and we have a splitBy option enabled, then we must return true if there's no data in the split option
      if (this.expanded && this.splitBy) {
        var dataCount = 0;

        this.expanded.datasets.forEach(function (dataset) {
          if (dataset.x) {
            dataCount += dataset.x.length;
          } else if (dataset.split) {
            dataCount += dataset.split.length;
          }
        });

        return dataCount == 0
      }

      return false
    },
    collapsed: {
      get() {
        // Make sure legacy settings where cards are collapsed remain open
        return false;
      },
      set(v) {
        // Deprecated: Collapsed is no longer used
      }
    },
    horizontal: {
      get() {
        return this.chart.horizontal;
      },
      set(v) {
        this.$parent.flipHorizontal(this.chart);
      }
    },
    percentual: {
      get() {
        return this.chart.percentual;
      },
      set(v) {
        this.$parent.flipPercentual(this.chart);
      }
    },
    average: {
      get() {
        return this.chart.average;
      },
      set(v) {
        this.$parent.flipAverage(this.chart);
      }
    },
    expanded: {
      get() {
        return this.$store.state.analytics.charts.expandedChart;
      },
      set(v) {
        this.$store.dispatch('ANALYTICS/EXPAND_CHART', v ? this.chart : null);
      }
    },
    splitOptions() {
      var options = [
        'accelerated_by',
        this.activityLabels.length && 'activities',
        'category',
        'address.geoid',
        'address.country',
        'address.admin1_name',
        'address.admin2_name',
        'founding_date',
        'current_employees.size_code',
        'industries',
        'invested_by',
        'legal_form',
        'maturity',
        this.membershipLabels.length && 'membership',
        'domains',
        this.stageLabels.length && 'stage',
        'subIndustries',
        'status',
        'tags',
        'private_tags',
        this.technologyLabels.length && 'technology',
        'total_funding',
        'type',
        this.customAreaSplitEnabled && 'custom_areas'
      ]
          .filter(prop => prop && (
                  prop === 'custom_areas'
                  || this.$store.state.config.public_fields.includes(strUpto(prop, '.'))
              )
          )

      // If the report fields are enabled to be used as a split by, add them as well
      if (this.reportSplitEnabled) {
        for (var index in this.optionReportFields) {
          options.push(this.optionReportFields[index])
        }
      }

      return options
    },
    splitOptionLabels() {
      const labels = {}

      for (const option of this.splitOptions) {
        labels[option] = humanize(option)
      }

      return labels
    },
    enabled() {
      return this.enable || this.$store.getters.enabledAnalyticsModules.includes(this.module)
    },
    portfolio() {
      return this.$store.state.filters.portfolio;
    },
    optionReportFields() {
      var reportFields = getAllReportFields()

      if (!reportFields) {
        return []
      }

      var optionReportFields = [];

      for (var key in reportFields) {
        if (reportFields[key].type == 'options') {
          optionReportFields.push('report_field_' + reportFields[key].id);
        }
      }

      return optionReportFields;
    }
  },
  methods: {
    toggleYValues(value) {
      if (value == 'percentual') {
        this.percentual = !this.percentual;
      } else if (value == 'average') {
        this.average = !this.average;
      }
    },
    humanize(prop) {
      switch (prop) {
        case 'address.city':
          return 'City';
        case 'address.geoid':
          return 'City';
        case 'address.country':
          return 'Country';
        case 'current_employees.size_code':
          return 'Employees';
        case 'custom_areas':
          return 'Custom Areas';
      }

      if (this.reportSplitEnabled
          && this.optionReportFields
          && this.optionReportFields.length > 0
          && this.optionReportFields.includes(prop)
      ) {
        var reportField = getReportFieldByReportIdentifier(prop);

        if (!reportField) {
          return prop;
        }

        return reportField.label;
      }

      return humanize(prop)
    },
    splitChart(splitBy) {
      // Make sure the value is null, not "undefined"
      if (!splitBy) {
        this.splitBy = null;
      } else {
        this.splitBy = splitBy
      }

      var filters = this.$store.getters.baseFilterObject
      const splitParameters = { filters, splitBy }
      this.$store.dispatch('ANALYTICS/SPLIT_CHART', splitParameters)
    }
  },
  watch: {
    portfolio(v) {
      // Expanded charts are not responsive to changed portfolios, so we need to do the following to make it so
      if (this.expanded && this.expanded.id == this.module && this.isExpandedChart) {
        this.splitChart(this.splitBy);
      }
    }
  },
  mixins: [ConfigMixin]
})
</script>

<style lang="scss">
@import "../../../scss/variables";

.card.card--collapsed {
  padding-bottom: 0;
}

.card--analytics {
  .button {
    border: 1px solid $color-borders;
  }

  .button:hover {
    background-color: $color-secondary;

    .svg-icon {
      path {
        fill: white !important;
      }
    }
  }
}

.card.card--expanded {
  position: absolute;
  z-index: 38;
  top: 50px;
  left: 0;
  right: 0;
  bottom: 0;
  border: 0;
  padding: 5%;
  padding-left: 200px;
  overflow: auto;

  .analytics & {
    top: 0;
    margin-bottom: 0;
  }

  &:after {
    position: absolute;
    z-index: 1;
    top: calc(5vw - 2rem);;
    right: calc(5% - 1rem);
    bottom: calc(5vw - 2rem);;
    left: calc(200px - 1rem);;
    pointer-events: none;
    border: 1px solid #ccc;
    content: '';
  }
}

.card__split {
  position: absolute;
  left: 1rem;
  max-width: 160px;
}

.card__btn {
  background: none;
  border: none;
  padding: 4px;
  opacity: .5;

  &:hover {
    .svg-icon--bar-graph {
      transform-origin: center;
      transform: rotate(90deg);
    }

    .svg-icon--90deg {
      transform: rotate(0);
    }
  }

  &:hover, &:focus {
    outline: none;
    opacity: 1;
    background: #eee;
  }

  .svg-icon > svg {
    display: block;
  }

  .svg-icon--minus {
    padding: 2px;
  }

  .svg-icon--arrow-compress,
  .svg-icon--arrow-expand {
    padding: 4px;
  }
}

.card__btn--pct {
  padding: 8px;
  width: 28px;
  height: 30px;
  vertical-align: top;
}

.svg-icon--bar-graph {
  transition: transform .3s;
}
</style>
