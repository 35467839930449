<template>
  <div>
    <div class="exploration-settings__header">
      <h2>Refine your scope.</h2>
    </div>
    <div>
      <p>Give your actor search a name and refine the scope of the actors you are looking for by adding geographical filters.</p>
      <form-group label="" :error="titleError">
        <ds-input v-model="title" placeholder="Report Name" :disabled="isEditing"/>
      </form-group>
      <form-group label="Geographical context">
        <AutocompleteTagInput
          :class="{ 'disabled-while-editing': isEditing }"
          :tags="geographyContextTags"
          :options="geographyOptions"
          :addOnlyFromAutocomplete="true"
          placeholder="Search within specific areas"
          @tagChanged="updateGeographyOptions"
          @input:raw="updateGeographyContext"
        />
      </form-group>
    </div>
    <div class="exploration-settings__button">
      <ds-button variant="primary" label="Next step" @click="handleNextStep" :disabled="!canSubmit"/>
      <ds-button label="Cancel" @click="handleCancel" v-if="withCancel"/>
      <span v-if="!canSubmit && this.searchTopics.length > 0">Provide a title</span>
      <span v-if="!canSubmit && this.searchTopics.length === 0">Provide a title and search on context</span>
    </div>
  </div>
</template>

<script>
  import Dropdown from '../../components/Dropdown/Dropdown.vue'
  import RadioButton from '../../components/Form/RadioButton.vue'
  import ExplorationKeywordInput from '../../components/ExplorationKeywordInput/ExplorationKeywordInput.vue'
  import AutocompleteTagInput from '../../components/Form/AutocompleteTagInput.vue'
  import AutocompleteDropdown from '../../components/Dropdown/AutocompleteDropdown.vue'
  import { Dictionary, fetchConceptSuggestions, fetchGeographySuggestions, NewsGroups, validateTitle } from '../../api/exploration.js'
  import debounce from 'lodash/debounce'
  import ConceptSearchMixin from '../../util/ConceptSearchMixin'

  export default {
    props: {
      modelValue: {
        type: Object,
      },
      withCancel: {
        type: Boolean,
      },
    },
    emits: ['update:modelValue', 'next-step', 'cancel'],
    data () {
      return {
        dictionaries: null,
        geographyOptions: [],
        titleValidation: Promise.resolve(true),
        isTitleValid: true,
        titleError: null,
        newsGroupOptions: [],
      }
    },
    computed: {
      isEditing () {
        return this.$store.state.conceptSearchGuide.conceptSearch && this.$store.state.conceptSearchGuide.conceptSearch.id > 0
      },
      topicOptions () {
        if (!this.dictionaries) {
          return []
        }

        return this.dictionaries.map(item => ({
          value: item.id,
          label: item.value,
        }))
      },
      canSubmit () {
        return (this.searchTopics.length > 0 || this.context.length > 0) && this.title.length > 0 && this.isTitleValid
      },
      geographyContextTags () {
        return this.geographyContext.map(item => ({
          text: getGeographyItemLabel(item),
          optionValue: { geographyObject: item },
        }))
      },
      title: computedValueProperty('title'),
      description: computedValueProperty('description'),
      searchTopics: computedValueProperty('searchTopics'),
      context: computedValueProperty('context'),
      topics: computedValueProperty('topics'),
      exclude: computedValueProperty('exclude'),
      geographyContext: computedValueProperty('geographyContext'),
      timespan: computedValueProperty('timespan'),
      newsGroups: computedValueProperty('newsGroups'),
      languages: computedValueProperty('languages'),
    },
    methods: {
      validateTitle: debounce(function () {
        if (this.modelValue.title !== '') {
          this.titleValidation = validateTitle(this.modelValue.title, this.modelValue.id).then(({ valid }) => valid)
        } else {
          this.titleValidation = Promise.resolve(true)
        }

        this.isTitleValid = true // assume true
        this.titleValidation.then(valid => {
          this.isTitleValid = valid
          this.titleError = valid ? null : 'Title already exists'
        })
      }, 300),
      updateGeographyOptions: debounce(async function (input) {
        fetchGeographySuggestions({ query: input })
          .then(response => {
            this.geographyOptions = []

            if (!Array.isArray(response)) {
              return
            }

            response.forEach(item => {
              if (item.label.eng) {
                this.geographyOptions.push({
                  text: getGeographyItemLabel(item),
                  geographyObject: item,
                })
              }
            })
          })
          .catch(() => {
            this.geographyOptions = []
          })
      }, 150),
      updateGeographyContext (context) {
        this.geographyContext = context.map(item => {
          return item.optionValue.geographyObject
        })
      },
      autocompleteSearchTerm (query) {
        return fetchConceptSuggestions({ query: query, conceptType: 'any' })
          .then(response => {
            const options = []

            response.forEach(function (concept) {
              options.push({
                label: concept.value,
                value: concept.uri,
              })
            })

            return options
          })
          .catch(err => {
            console.log(err)
          })
      },
      handleNextStep () {
        this.titleValidation.then(valid => {
          if (valid) this.$emit('next-step')
        })
      },
      handleEditTopics () {
        this.$router.push('/settings/exploration')
      },
      handleCancel () {
        this.$emit('cancel')
      },
      updateNewsGroupOptions () {
        return NewsGroups
          .get()
          .then(response => {
            this.newsGroupOptions = response.map(newsGroup => {
              return {
                value: newsGroup.id,
                label: newsGroup.name,
              }
            })
          })
      },
      updateDictionaryOptions () {
        Dictionary
          .get()
          .then(dictionaries => {
            this.dictionaries = dictionaries
          })
      },
    },
    created () {
      this.updateNewsGroupOptions()
      this.updateDictionaryOptions()
    },
    watch: {
      'modelValue.title': function () {
        this.validateTitle()
      },
    },
    mixins: [ConceptSearchMixin],
    components: {
      RadioButton,
      Dropdown,
      ExplorationKeywordInput,
      AutocompleteTagInput,
      AutocompleteDropdown,
    },
  }

  function computedValueProperty (name) {
    return {
      get () {
        return this.modelValue[name]
      },
      set (value) {
        this.$emit('update:modelValue', { ...this.modelValue, [name]: value })
      },
    }
  }

  function getGeographyItemLabel (item) {
    if (!item || !item.label || !item.label.eng) {
      return '<no name>'
    }

    let label = item.label.eng

    if (item.type === 'place' && item.country && item.country.label && item.country.label.eng && !label.includes(item.country.label.eng) && !label.includes(',')) {
      label += ' (' + item.country.label.eng + ')'
    }

    return label
  }
</script>

<style lang="scss" scoped>
  .exploration-settings__header {
    margin-bottom: 15px;
  }

  .exploration-settings__advanced-link {
    display: block;
    margin-bottom: 20px;
  }

  .exploration-settings__button {
    display: flex;
    align-items: center;
    margin-top: auto;
    padding-top: 20px;

    span {
      margin-left: 20px;
    }
  }

  .disabled-while-editing {
    :deep(.vue-tags-input) {
      background: #FAFAFA;
    }

    :deep(input) {
      background: #FAFAFA;
      pointer-events: none;
    }

    :deep(.multiselect__tags) {
      background: #FAFAFA;
      pointer-events: none;
    }
  }
</style>
