<template>
  <div>
    <div v-if="loading" class="spider__loading">
      <icon name="loading"/>
    </div>
    <div v-else-if="hasAccessToScores">
      <div class="page__header">
        <h4 v-if="showNoSustainabilityInformation">No sustainability information found</h4>
        <ds-button label="Search sustainable information"
          :icon="(status && status !== 'finished') ? 'spinner' : ''"
          :disabled="(shouldShowSustainButton && (status && status !== 'finished'))"
          variant="primary"
          @click="startAgent"/>
      </div>

      <h1 class="category-stats__title" v-if="information.scores && information.scores.length">Scores</h1>
      <div class="category-stats__container" v-if="information.scores && information.scores.length">
        <div v-for="(score, index) of information.scores" :key="'category' + index" class="category-stats__child">
          <h3 class="category-stats-label">
            <a :href="score.url" target="_blank">{{ getScoresTitle(score.label) }}</a>
          </h3>
          <div class="category-stats-title" v-if="score.additionalInfo">{{ score.additionalInfo }}</div>
          <div v-else class="category-stats-title">{{ getScoresLabel(score.label) }}</div>
          <div class="category-stats-count__container">
            <div class="category-stats-count__value">{{ score.text }}</div>
          </div>
        </div>
      </div>

      <h1 v-if="information.certificates && information.certificates.length">Certificates</h1>
      <div class="category-stats__container" v-if="information.certificates && information.certificates.length">
        <div v-for="(certificate, index) of information.certificates" :key="'category' + index" class="category-stats__child">
          <h3 class="category-stats-label">
            <a :href="certificate.value" target="_blank">
              <img :class="certificate.type" :src="getImageForCertificate(certificate.type)" :alt="certificate.type">
            </a>
          </h3>
        </div>
      </div>

      <div class="sustainability__section" v-if="(information.externalUrls && information.externalUrls.length) || (information.keywords && information.keywords.length)">
        <div class="sustainability__section--info" v-if="information.externalUrls && information.externalUrls.length">

          <h1>Interesting External Urls</h1>
          <br>
          <table v-if="information && information.externalUrls && information.externalUrls.length" class="table table-borderless mb5 m-t-lg">
            <thead>
            <th>Name</th>
            <th>Url</th>
            </thead>

            <tbody>
            <tr v-for="info in information.externalUrls">
              <td>
                {{ info.label }}
              </td>
              <td>
                <a :href="info.value" target="_blank">{{ info.value }}</a>
              </td>
            </tr>
            </tbody>
          </table>
          <div v-else>
            No interesting urls found
          </div>
        </div>

        <div class="sustainability__section--info" v-if="(information.keywords && information.keywords.length)">
          <h1>Interesting Keywords</h1>
          <br>
          <table v-if="information && information.keywords && information.keywords.length" class="table table-borderless mb5 m-t-lg">
            <thead>
            <th style="width: 100px">Keyword</th>
            <th style="width: 100px">How many times occured on the site</th>
            </thead>

            <tbody>
            <tr v-for="info in information.keywords">
              <td>
                {{ info.keyword }}
              </td>
              <td>
                {{ info.count }}
              </td>
            </tr>
            </tbody>
          </table>
          <div v-else>
            No interesting keywords found on the actors website
          </div>
        </div>
      </div>

      <div class="sustainability__section" v-if="(information.urls && information.urls.length) || (information.files && information.files.length)">
        <div class="sustainability__section--info" v-if="information.urls && information.urls.length">
          <h1>Interesting Internal Urls</h1>
          <br>
          <table v-if="information && information.urls && information.urls.length" class="table table-borderless mb5 m-t-lg">
            <thead>
            <th>Name</th>
            <th>Url</th>
            </thead>

            <tbody>
            <tr v-for="info in information.urls">
              <td>
                {{ info.label }}
              </td>
              <td>
                <a :href="info.value" target="_blank">{{ info.value }}</a>
              </td>
            </tr>
            </tbody>
          </table>
          <div v-else>
            No interesting urls found
          </div>
        </div>

        <div class="sustainability__section--info" v-if="information.files && information.files.length">
          <h1>Interesting Files</h1>
          <br>
          <table v-if="information && information.files && information.files.length" class="table table-borderless mb5 m-t-lg">
            <thead>
            <th>Url</th>
            </thead>

            <tbody>
            <tr v-for="info in information.files">
              <td>
                <a :href="info.value" target="_blank">{{ info.value }}</a>
              </td>
            </tr>
            </tbody>
          </table>
          <div v-else>
            No interesting files found
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { fetchAgentStatus, runAgentForActor } from '../../api/actors'
  import { fetchSustainabilityInformation } from '../../store/modules/suggestions'
  import { fetchActorTelex } from '../../api/heartbeat'
  import { fetchKnowledgeBaseData } from '../../api/knowledge-base'

  export default {
    name: 'ActorSustainability',
    data () {
      return {
        information: {
          urls: null,
          keywords: null,
          files: null,
          externalUrls: null,
          scores: null,
          certificates: null,
          date: null,
        },
        interval: null,
        status: null,
        loading: false,
      }
    },
    props: {
      actor: {
        type: Object,
        default: [],
      },
    },
    computed: {
      shouldShowSustainButton () {
        return ((Date.now() - 12096e5) > this.information.date) || (this.information.urls || this.information.keywords || this.information.files || this.information.externalUrls)
      },
      hasAccessToScores () {
        return this.$store.getters.hasAccessToScores
      },
      showNoSustainabilityInformation () {
        return !(this.information.urls && this.information.urls.length) &&
          !(this.information.keywords && this.information.keywords.length) &&
          !(this.information.files && this.information.files.length) &&
          !(this.information.externalUrls && this.information.externalUrls.length) &&
          !(this.information.scores && this.information.scores.length) &&
          !(this.information.certificates && this.information.certificates.length)
      },
    },
    methods: {
      startAgent () {
        runAgentForActor(this.actor.id, 'crawl_sustainability_certificate')
          .then(response => {
            this.status = 'queued'
          })
          .catch()

        runAgentForActor(this.actor.id, 'crawl_sustainability_score')
          .then(response => {
            this.status = 'queued'
          })
          .catch()

        runAgentForActor(this.actor.id, 'crawl_sustainability')
          .then(response => {
            this.status = 'queued'
          })
          .catch()
      },
      fetchArticles () {
        this.fetchingContent = true

        var sortBy = 'date'

        var actorId = this.actorId
        if (this.$route.params && this.$route.params.id) {
          actorId = this.$route.params.id
        }

        const filters = {
          // media_types: ['rss', 'pr', 'blog'],
          top_actor: actorId,
          'ml-supported': false,
          limit: 15,
          query: 'sustainability',
          offset: 0,
          sortBy,
        }

        fetchKnowledgeBaseData(filters)
          .then(response => {
            this.articles = response.data.results

            this.fetchingContent = false
          })
          .catch(error => {
            console.log(error)
            this.fetchingContent = false
          })
      },
      fetchSustainability () {
        fetchSustainabilityInformation(this.actor.id)
          .then(response => {
            if (response.urls) {
              this.information.files = response.urls.filter((url) => url.type === 'file')
              this.information.externalUrls = response.urls.filter(url => url.status === 'external' && url.type === 'link')
              this.information.urls = response.urls.filter(url => url.status === 'internal' && url.type === 'link')
            }
            if (response.keywords) {
              this.information.keywords = response.keywords
            }

            if (response.scores) {
              const sortingArr = ['CSR Hub', 'S&P Global', 'Sustainalytics', 'CDP', 'MSCI']
              this.information.scores = response.scores.sort((a, b) => sortingArr.indexOf(a.label) - sortingArr.indexOf(b.label))
            }

            this.information.date = response.date

            if (response.certificates) {
              this.information.certificates = response.certificates
            }
          })
          .catch()
          .finally(() => {
            this.loading = false
          })
      },
      getImageForCertificate (certificate) {
        switch (certificate) {
          case 'BCorporation':
            return 'https://www.bcorporation.net/static/8edecee6ddcbce21abcd764f4239dbaa/131d8/bcorp-logo-no-border-gray.png'
          case 'CarbonTrust':
            return 'https://www.carbontrust.com/themes/custom/carbon_trust/dist/images/logo.svg'
          case 'TheClimateGroup':
            return 'https://www.wemeanbusinesscoalition.org/wp-content/uploads/2021/08/TCG-EP100-RGB_EP100-Colour.png'
        }
      },
      fetchSustainableActorContent () {
        fetchActorTelex(this.actor.id).then((data) => {
          console.log(data)
        }).catch((errors) => {
          console.log(errors)
        })
      },
      updateAgent () {
        fetchAgentStatus(this.actor.id)
          .then(response => {
            this.fetchSustainability()
            if (response && response.crawl_sustainability && response.crawl_sustainability.status) {
              this.status = response.crawl_sustainability.status
            }
          })
          .catch()
      },
      getScoresLabel (label) {
        switch (label.toLowerCase()) {
          case 'csrhub':
            return 'ESG Ranking (%)'
          case 'cdp':
            return ''
          case 'spglobal':
            return 'ESG Score'
          case 'msci':
            return ''
          case 'sustainalytics':
            return ''
        }
      },
      getScoresTitle (label) {
        switch (label.toLowerCase()) {
          case 'csrhub':
            return 'CSR Hub'
          case 'cdp':
            return 'Cdp'
          case 'spglobal':
            return 'S&P Global'
          case 'msci':
            return 'Msci'
          case 'sustainalytics':
            return 'Sustainalytics'
        }
      },
    },
    mounted () {
      if (!this.hasAccessToScores) {
        return
      }
      this.loading = true
      this.fetchSustainability()
      this.fetchSustainableActorContent()
      // To be implemented
      // Fetch articles from the actor that have sustainability tags or keywords
      // this.fetchArticles()

      // Check the agents status on reload
      this.updateAgent()
      if (this.interval == null) {
        this.interval = setInterval(() => this.updateAgent(), 15000)
      }
    },
    beforeUnmount () {
      this.information.urls = null
      this.information.keywords = null
      this.information.files = null

      // Make sure to kill the infinite loop function
      clearInterval(this.interval)
    },
    watch: {
      actor () {
        this.fetchSustainability()

        // Check the agents status when selecting another actor
        this.updateAgent()
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables.scss";

  .page__header {
    display: flex;
    width: fit-content;
    flex-direction: column;
    text-align: center;
    margin-bottom: 2rem;

    h1 {
      margin-top: 40px;
    }
  }

  .sustainability__section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .sustainability__section--info {
    width: 49%;

    h1 {
      margin-top: 25px;
      margin-bottom: 5px;
    }
  }

  .category-stats__container {
    border: 1px solid $color-borders;
    display: flex;
    width: 100%;
    font-size: 20px;
    margin-bottom: 2rem;

    justify-content: space-evenly;
  }

  .CarbonTrust {
    height: 100px;
  }

  .BCorporation {
    height: 100px;
  }

  .TheClimateGroup {
    height: 100px;
  }

  .category-stats__child {
    width: 20%;
    float: left;
    text-align: center;
    margin: 2rem 0;
    display: flex;
    flex-direction: column;

    .category-stats-title {
      text-transform: uppercase;
      font-family: $font-stack-secondary;
      font-size: 12px;
      margin-bottom: 10px;
    }

    .category-stats-label {
      text-transform: uppercase;
      font-family: $font-stack-secondary;
      font-size: 30px;
      margin-bottom: 15px;
      font-weight: bold;
    }

    .category-stats-count__container {
      margin: 0 auto;

      .category-stats-count__value {
        font-size: 55px;
      }

      .category-stats-count__diff {
        color: red;
        font-family: $font-stack-secondary;
        font-size: 12px;
        text-align: right;

        &.is-positive {
          color: green;
        }
      }
    }
  }
</style>
