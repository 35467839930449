import { cancelIfLate, createJSONRequest, createPagedRequest, createResource } from './create-request'
import querify from '../util/querify'
import escapeHTML from '../util/escapeHTML.js'

const teamId = window.config.id

export const fetchConfig = () => createJSONRequest({
  url: `/api/settings/ecosystem`,
  method: 'GET',
})

export const updateConfig = (data) => createJSONRequest({
  url: `/api/settings/ecosystem`,
  method: 'PUT',
  data,
})

export const updateSettings = (data) => createJSONRequest({
  url: `/api/settings/ecosystem/update-settings`,
  method: 'POST',
  data,
})

// Get all ecosystems (Developer only request!)
export const getAllEcosystems = () => createJSONRequest({
  url: `/api/settings/ecosystem/all`,
  method: 'GET',
})

export const fetchInvitations = (data) => createJSONRequest({
  url: `/settings/ecosystems/${teamId}/invitations`,
  method: 'GET',
})

export const sendInvitation = (data) => createJSONRequest({
  url: `/settings/ecosystems/${teamId}/invitations`,
  data,
})

export const sendMessage = (data) => createJSONRequest({
  url: `/api/messages`,
  method: 'POST',
  data,
})

export const sendSupportMessage = (data) => createJSONRequest({
  url: `/api/support`,
  method: 'POST',
  data,
})

// Settings
export const Settings = createResource('/api/settings')

export const recalculateScores = () => createJSONRequest({
  url: '/api/settings/scores/recalculateScores',
  method: 'POST',
})

export const searchUser = (user) => createJSONRequest({
  method: 'GET',
  url: `/api/settings/users/search?${querify(user)}`,
})

export const fetchUserDetail = (id, type = '') => createJSONRequest({
  url: `/api/settings/users/${id}?type=${type}`,
  method: 'GET',
})

// limit, offest, order, q
export const fetchUsers = (filters) => createPagedRequest({
  url: `/api/settings/users?${querify(filters)}`,
  method: 'GET',
})

export const updateUser = ({ id, role, team_verified, spotting_area = [] }) => createJSONRequest({
  url: `/api/settings/users/${id}`,
  method: 'PUT',
  data: { role, team_verified, spotting_area },
})

export const deleteUser = (user, transferDataToUserEmail = '') => createJSONRequest({
  url: `/api/settings/users/${user && user.id || user}`,
  method: 'DELETE',
  data: {
    transferDataToUserEmail,
  },
})

export const fetchMaintenainceRequests = (filters) => createPagedRequest({
  url: `/api/settings/maintenance-requests?${querify(filters)}`,
  method: 'GET',
})

export const approveMaintenanceRequests = ({ maintenance_request_id, ecosystem_id, email, user_id, role }) => createJSONRequest({
  url: `/api/settings/approve-maintenance-request/${maintenance_request_id}`,
  method: 'PUT',
  data: { maintenance_request_id, ecosystem_id, email, user_id, role },
})

export const fetchServiceUsage = () => createJSONRequest({
  url: '/api/settings/usage',
  method: 'GET',
})

export const updateAutomaticRefill = (automatic_refill) => createJSONRequest({
  url: `/api/settings/ecosystem/automatic-refill?flag=${automatic_refill}`,
  method: 'PUT',
})

export const updateMonthlyEnrichment = (monthly_enrichment) => createJSONRequest({
  url: `/api/settings/ecosystem/monthly-enrichment?flag=${monthly_enrichment}`,
  method: 'PUT',
})

// will return search results based on user name
export const userSuggestionSettings = cancelIfLate((query) => createJSONRequest({
  url: '/api/settings/users?' + `${querify(query)}`,
  method: 'GET',
}))

// will return search results based on user name and portfolio as role
export function userPortfolioMembersSuggestion () {
  return {
    url: '/api/settings/users?q={query}&role=portfolio_member',
    onResponse (data) {
      return {
        results: Object.values(data).map(user => ({
          title: escapeHTML(user.name),
          data: user,
        })),
      }
    },
  }
}

// will return search results based on user name
export function ecosystemMemberSuggestions () {
  return {
    url: '/api/settings/users/search?query={query}&role=actor',
    onResponse (data) {
      return {
        results: Object.values(data).map(user => ({
          title: escapeHTML(user.name),
          data: user,
        })),
      }
    },
  }
}

// Settings > API tokens
export const ApiTokens = createResource('/api/settings/api-tokens')

// Data sources
export const Sources = createResource('/api/import/files')

Sources.import = id => createJSONRequest({
  url: '/api/import/files/' + id + '/forceImport',
})

Sources.get = filters => createPagedRequest({
  url: '/api/import/files/' + `?${querify(filters)}`,
  method: 'GET',
})

// Fetch all import files and their jobs that have logs available for them
export const getAllImportsWithLogs = () => createJSONRequest({
  url: '/api/import/files/logs',
  method: 'GET',
})

// Fetch logs for a specific import file and job
export const getImportJobLogs = cancelIfLate((importConfig) => createPagedRequest({
  url: '/api/import/files/' + importConfig.fileId + '/logs/' + importConfig.jobId + `?${querify(importConfig.filters)}`,
  method: 'GET',
}))

// CRM synchronisation logs
export const fetchSyncLogs = (filters) => createPagedRequest({
  url: `/api/settings/synclogs?${querify(filters)}`,
  method: 'GET',
})

// Portfolio synchronisation logs
export const fetchPortfolioSyncLogs = (filters) => createPagedRequest({
  url: `/api/settings/synclogs?type=portfoliosync&${querify(filters)}`,
})

// Start a CRM sync tag job
export const syncCrmTag = (data) => createJSONRequest({
  url: `/api/settings/synctag`,
  method: 'POST',
  data: data,
})

// Start a CRM portfolio sync job
export const syncCrmPortfolio = (data) => createJSONRequest({
  url: `/api/settings/sync-portfolio`,
  method: 'POST',
  data: data,
})

// Nace codes

// Get a tree structure for the nace codes
export const getNaceCodes = () => createJSONRequest({
  url: `/api/nace-codes`,
  method: 'GET',
})

export const getFlatNaceCodes = () => createJSONRequest({
  url: `/api/nace-codes?type=flat`,
  method: 'GET',
})

// Available on $root
export const asyncConfig = {
  data () {
    return {
      sources: [],
    }
  },
  computed: {
    userRoles () {
      const roles = window.ROLES

      return Object.keys(roles).map(key => {
        return {
          value: key,
          text: roles[key],
        }
      })
    },
    userRoleMap () {
      return window.ROLES
    },
    allowedRoles () {
      const publisherEnabled = !!this.$store.getters.isPublisherEnabled
      var userRoles = !publisherEnabled ? this.userRoles.filter(r => r.value !== 'actor') : this.userRoles

      if (!this.$store.getters.hasAccessToPortfolioSharing) {
        userRoles = userRoles.filter(r => r.value !== 'portfolio_member')
      }

      if (this.$store.getters.isDeveloper) {
        // If the subscription is agency, then add the role "portfolio member"
        return userRoles
      }

      switch (this.$store.getters.userRole) {
        case 'actor':
          return userRoles.filter(r => r.value === 'actor')
        case 'member':
          return userRoles.filter(r => r.value !== 'owner')
        case 'owner':
          return userRoles
      }

      return []
    },
  },
}
