<template>
  <div>
    <div class="header">
      <h1>Analyse market</h1>
      <p>For {{ reportTypeLabel }}</p>
      <p v-if="specificDictionary">Analysing on {{ specificDictionaryName }}</p>
    </div>
    <div class="analyze-container">
      <div v-for="group in availableGroups" class="analyze-button" :class="{active: groupActive(group)}" @click="updateGroup(group)">
        <span>{{ groupLabel(group) }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  /**
   * @deprecated component
   */
  import DictionaryMixin from '../../../util/DictionaryMixin'
  import { defineComponent } from 'vue'

  export default defineComponent({
    mixins: [DictionaryMixin],
    props: {
      dictionaryGroup: String,
      reportTypeLabel: String,
      specificDictionary: undefined,
    },
    computed: {
      availableGroups() {
        return this.mixinDefaultDictionaries.reduce((groups, dictionary) => {
          if (dictionary.dictionary_group && !groups.includes(dictionary.dictionary_group)) {
            groups.push(dictionary.dictionary_group)
          }

          return groups
        }, [])
      },
      specificDictionaryName() {
        if (!this.specificDictionary) {
          return undefined
        }

        const dictionary = this.mixinDefaultDictionaries.find(d => d.id === this.specificDictionary)
        return dictionary ? dictionary.title : this.specificDictionary
      },
    },
    methods: {
      groupLabel(group) {
        return GROUP_LABELS[group] || group
      },
      groupActive(group) {
        return this.dictionaryGroup === group
      },
      updateGroup(group) {
        this.$emit('update:dictionaryGroup', this.dictionaryGroup !== group ? group : null)
      }
    },
  })

  const GROUP_LABELS = {
    pestel: "PESTEL Trends"
  }
</script>

<style lang="scss" scoped>
  $spacing: 10px;

  .header {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  h1 {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.5px;
  }

  .analyze-container {
    display: flex;
    flex-wrap: wrap;
  }

  .analyze-button {
    width: calc(50% - #{$spacing});
    height: 70px;
    margin-right: 10px;
    text-transform: uppercase;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.33);

    padding: 10px;
    background: #2A3646;
    color: #ffffff;
    margin-bottom: 20px;
    position: relative;

    &.disabled {
      opacity: 0.5;
      cursor: default;
    }

    .report-type__types__type__overlay {
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      align-items: center;
      justify-content: center;
      background: #2A3646;
      font-size: 18px;
    }

    &.disabled:hover .report-type__types__type__overlay {
      display: flex;
    }

    &:nth-child(2n) {
      margin-right: 0;
    }

    &.active {
      background-color: var(--primary-light);
    }

    &:hover:not(.active) {
      background-color: var(--primary-light);
    }
  }
</style>
