<template>
  <div class="keyword" :class="classes" @click="handleRemoveClick">
    <icon name="remove" v-if="removeable" />
    <icon :name="icon" v-if="icon" />
    <div class="keyword__label">{{ geoOrLabel }}</div>
  </div>
</template>

<script>
  import KeywordLabelMixin from '../../util/KeywordLabelMixin.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'keyword',
    props: {
      icon: String,
      label: [String, Number],
      value: [String, Object],
      removeable: Boolean,
      variant: String,
    },
    computed: {
      filterableRelationships () {
        return this.$store.getters.filterableRelationships
      },
      classes () {
        return {
          'keyword--removeable': this.removeable,
          [`keyword--${this.variant}`]: this.variant,
        }
      },
      geoOrLabel () {
        if (!this.value) {
          return this.label
        }

        if (this.filterableRelationships.includes(this.value.facet)) {
          return this.actorIdToLabel(this.value.value, this.$data.actorNames)
        }

        if (['tags', 'private_tags'].includes(this.value.facet)) {
          return this.tagIdToLabel(this.value.value, this.$data.tagNames)
        }

        return this.value && this.value.facet === 'geoid' ? this.geoToLabel(this.value, this.$data.geoNames) : this.label
      }
    },
    methods: {
      handleRemoveClick () {
        this.$emit('remove', this.value)
      }
    },
    mixins: [KeywordLabelMixin]
  })
</script>
