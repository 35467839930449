<template>
  <modal :id="modalId"
         ref="modal"
         :title="'Success'"
         :class="'success-modal'"
         @close="close"
         is-simplified-modal
  >
    <template v-slot:body>
        <div class="content-container">
            {{ message }}
        </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import {MUTATION_TYPES as UI_MUTATION_TYPES} from '../../store/modules/ui.js'

  export default {
    data () {
      return {
        modalId: MODAL_IDS.SUCCESS,
        message: '',
      }
    },
    computed: {
      modalContext () {
        return this.$store.state.ui.modalContext
      },
      config () {
        return this.$store.state.config
      },
    },
    props: {},
    methods: {
      close () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.ANNOUNCEMENT_ADDRESS)
        this.$emit('close')
      },
    },
    mounted () {
        if (this.modalContext.message) {
            this.message = this.modalContext.message
        }
    },
    components: {
      Modal,
    },
    mixins: [TranslationsMixin],
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/_variables.scss";

  .content-container {
    padding: 20px 20px 17px 20px;

    .row {
      // margin-bottom: 0px;
    }
  }

</style>
