<template>
  <div class="actor-contributors__container">
    <template v-if="contributors && contributors.list">

      <div
        class="actor-contributor"
        v-for="(contributor, index) in contributors.list"
        :key="'contributor' + index"
      >
        <div class="actor-contributor__avatar-container">
          <div
            @click="openUsersSidePanel(this, contributor)"
            class="actor-contributor__avatar"
            :style="{backgroundImage: `url(${contributor.photo_url})`}"
          />
        </div>
        <div class="actor-contributor__content">
          <div class="actor-contributor__info">
            <div class="actor-contributor__name">{{ contributor.user_name }}</div>
            <a
              v-if="showUserEmail"
              class="actor-contributor__email"
              :href="`mailto:${contributor.user_email}`"
            >{{ contributor.user_email }}</a>
          </div>
          <div
            v-if="showUserRole"
            class="actor-contributor__role"
          >
            <div class="actor-contributor__role-title">Actor Role</div>
            <div class="actor-contributor__role-text">{{
                capitalize(contributor.claim_type.split('-').join(' '))
              }}
            </div>
          </div>
          <div
            v-if="showUserDepartment"
            class="actor-contributor__department"
          >
            <div class="actor-contributor__department-title">Department</div>
            <div class="actor-contributor__department-text">{{ getDepartmentForContributor(contributor) }}</div>
          </div>
          <div
            v-if="showUserCommunities"
            class="actor-contributor__communities"
          >
            <div class="actor-contributor__communities-title">{{ capitalize($t('communities_generic')) }}</div>
            <div class="actor-contributor__communities-text">{{ getCommunitiesForContributor(contributor) }}</div>
          </div>
        </div>
        <div class="actor-contributor__contact">
          <div class="actor-contributor-phone">
            <ds-button
              v-if="!!contributor.phone && contributor.phone_published && contributor.user_id !== userId"
              size="small"
              icon-fill-color="white"
              icon="phone"
              variant="rounded"
              @click="openUsersSidePanel($event, contributor)"
            />
          </div>
          <div class="actor-contributor__conversation">
            <ds-button
              v-if="!!contributor.conversation_id"
              size="small"
              variant="rounded"
              label="open conversation"
              @click="openConversation($event, contributor)"
            />
            <ds-button
              v-else-if=" contributor.user_id !== userId"
              size="small"
              variant="rounded"
              label="start conversation"
              @click="startConversation($event, contributor)"
            />
          </div>
          <div
            class="actor-contributor__remove"
            v-if="canChangeContributors && contributor.claim_type === 'actor-owner' && (isOwner || userIsActorOwner)"
            v-tooltip.left="userIsActorOwner ? 'Only you can remove your claim' : 'Only owners can remove this claim'"
          >
            <ds-button
              icon="trash"
              size="small"
              variant="rounded"
              @click="removeClaim(contributor)"
            />
          </div>
          <div
            class="actor-contributor__remove"
            v-else-if="canChangeContributors"
            v-tooltip.left="contributor.claim_type === 'actor-owner' ? 'The actor owner can not be removed as contributor' : ''"
          >
            <ds-button
              icon="trash"
              size="small"
              variant="rounded"
              @click="deleteContributor(contributor)"
              :disabled="contributor.claim_type === 'actor-owner'"
            />
          </div>
        </div>
      </div>
      <p v-if="!contributors.list.length && !contributors.loading">
        <span v-if="canChangeContributors">This actor has not been claimed yet, click the button below to invite a contributor</span>
        <span v-if="!canChangeContributors">This actor has no contributors</span>
      </p>
      <div v-if="!contributors.loading">
        <ds-button
          variant="rounded"
          v-if="canChangeContributors"
          :label="$t('actor_contributors_invite_button')"
          @click="showContributorModal"
        />
      </div>
    </template>

    <Loading v-if="contributors.loading"/>

  </div>
</template>

<script>
  import { Conversations } from '../../api/conversations'
  import { ACTION_TYPES as CONVERSATION_ACTION_TYPES } from '../../store/modules/conversations'
  import Loading from '../../components/Dashboard/ConceptMap/Loading.vue'
  import { capitalize } from '../../util/string.ts'
  import CompanyMixin from '../../util/CompanyMixin'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'
  import { deleteClaim } from '../../api/actors'
  import { fetchProfile } from '../../api/user'
  import { ACTION_TYPES as ACTOR_ACTION_TYPES } from '../../store/modules/actors'

  export default {
    props: {
      contributors: {
        type: Object,
      },
      showUserEmail: {
        type: Boolean,
        default: false
      },
      showUserRole: {
        type: Boolean,
        default: false
      },
      showUserDepartment: {
        type: Boolean,
        default: false
      },
      showUserCommunities: {
        type: Boolean,
        default: false
      },
    },
    emits: ['track-event'],
    data () {
      return {}
    },
    computed: {
      isOwner () {
        return this.$store.getters.isOwner
      },
      isMember () {
        return this.$store.getters.isMember
      },
      canChangeContributors () {
        return this.userIsActorOwner || this.$store.getters.isMember
      },
      userId () {
        return this.$store.getters.userId
      },
      company () {
        return this.$store.getters.detailActor
      }
    },
    methods: {
      fetchDetail () {
        this.$store.dispatch(ACTOR_ACTION_TYPES.FETCH_ACTOR_DETAIL, this.company.id)
      },
      showContributorModal () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_CONTRIBUTOR_MODAL)
      },
      deleteContributor (contributor) {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          name: contributor.user_name,
          contributor: contributor,
          modalContextType: 'actor-contributor',
        })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
      },
      confirmDeleteContributor (contributor) {
        this.$emit('remove-contributor', contributor)
      },
      removeClaim (contributor) {
        // Display a pop-up that will delete the actor claim if the user clicks "confirm"
        const modalContextData = Object.assign({
          name: this.company.name,
          actorId: this.company.id,
          contributor: contributor,
          modalContextType: 'claim',
          modalContextClaimType: 'owner',
        })

        this.$store.commit('UI/SET_MODAL_CONTEXT', modalContextData)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
      },
      deleteClaim (contributor) {
        const isUserActorOwner = this.userIsActorOwner

        deleteClaim({ actorId: this.company.id, userId: contributor.user_id })
          .then(response => {
            this.fetchDetail()
            this.$bus.emit('updateContributors')

            if (isUserActorOwner) {
              fetchProfile()
                .then(profile => {
                  this.$store.commit('USER/UPDATE_PROFILE', profile)
                })
            }

            // Go to profile page
            this.$router.push(`/actors/${this.actor.id}#contributors`)
          })
      },
      getCommunitiesForContributor (contributor) {
        if (!contributor.communities || !contributor.communities.length) {
          return 'None'
        }
        return contributor.communities.join(', ')
      },
      getDepartmentForContributor (contributor) {
        if (!contributor.department || !contributor.department.length) {
          return 'None'
        }
        return contributor.department
      },
      capitalize,
      openConversation ($event, contributor) {
        this.$emit('track-event')

        $event.stopPropagation()
        this.$router.push('/profile/conversations/' + contributor.conversation_id)
      },
      openUsersSidePanel ($event, contributor) {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          component: 'user-information-side-panel',
          metaData: {
            userId: contributor.user_id,
            showPhoneNumber: true,
            allowDelete: false,
            highlightPhoneNumber: true,
          },
        })
      },
      startConversation ($event, contributor) {
        this.$emit('track-event')

        $event.stopPropagation()
        Conversations.post({ recipient_id: contributor.user_id }).then((conversation) => {
          this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
          this.$router.push('/profile/conversations/' + conversation.id).then(() => {
            this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
              component: 'user-information-side-panel',
              metaData: {
                userId: contributor.user_id,
                allowDelete: false,
              },
            })
          })
        })
      },
    },
    mixins: [CompanyMixin],
    mounted () {
      this.$bus.on('actorContributorDeleteConfirmation', (context) => {
        this.confirmDeleteContributor(context.contributor)
      })

      this.$bus.on('claimDeleteConfirmation', (context) => {
        if (context.actorId === this.company.id) {
          this.deleteClaim(context.contributor)
        }
      })
    },
    components: {
      Loading,
    },
  }
</script>
<style
  lang="scss"
  scoped
>
  @import "../../../scss/variables.scss";

  .actor-contributors__container {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0 20px 20px 20px;

    p {
      font-size: 14px;
    }

    .actor-contributor {
      display: flex;
      background: $color-background-lightest-grey;
      margin-bottom: 20px;
      padding: 0 20px;
      align-items: center;

      .actor-contributor__avatar-container {
        display: flex;
        align-items: center;

        .actor-contributor__avatar {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid black;
          padding: 10px 10px 10px 0;
          margin: 20px 10px 20px 0;

          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .actor-contributor__info, .actor-contributor__role, .actor-contributor__communities, .actor-contributor__department {
        display: flex;
        flex-direction: column;
        font-size: 12px;
        justify-content: center;
        width: 33.333333%;
        padding: 0 10px;

        .actor-contributor__name, .actor-contributor__role-title, .actor-contributor__communities-title, .actor-contributor__department-title {
          font-weight: 500;
        }

        .actor-contributor__communities-text {
          width: 350px;
        }

        .actor-contributor__email, .actor-contributor__role-text, .actor-contributor__communities-text, .actor-contributor__department-text {
          text-decoration: none;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          text-wrap: wrap;
        }
      }

      .actor-contributor__contact {
        width: 25%;
        justify-content: flex-end;
        display: flex;
        flex-direction: row;
      }

      .actor-contributor__remove, .actor-contributor__conversation .actor-contributor-phone {
        display: flex;
        justify-content: center;
        align-items: center;

        &.not-visible {
          pointer-events: none;
          opacity: 0;
        }
      }

      .actor-contributor__conversation, .actor-contributor__remove,
      .actor-contributor-phone {
        margin-left: 20px;
      }

      .actor-contributor__content {
        display: flex;
        overflow: hidden;
        flex-grow: 1;
      }
    }
  }
</style>
