<template>
  <div class="fixed-heading">
    <div class="heading">
      <h1 class="score-list__heading">Funding round list</h1>
      <div class="explore-content__filter-button-container" v-if="config.filterControls.length > 0">
        <div class="explore-content__filter-indicator"
             v-if="keywords.length > 0 && keywords.find((f) => f.facet !== 'actor_type')"></div>
        <ds-button variant="minimal" size="small" label="Filter" icon="filter-box"
                   class="explore-content__filter-button"
                   :class="{ 'explore-content__filter-button-active' : this.$store.state.filters.filter.visible }"
                   @click="toggleFilter"/>
      </div>
      <!--<badge :name="actorCount + ' Actors'" variant="primary" class="score-overview__actor-couter" />-->
      <p class="guide_text">The list of funding rounds</p>
    </div>
    <top-filters/>
    <div class="explore-pane explore-pane--right">
      <div class="scrollable" ref="scrollable">
        <div class="explore-pane__body">
          <sortable-list-view v-if="fundingData.data" :actors="fundingData.data" :user-columns="selectedColumns"/>
        </div>
      </div>
      <div class="explore-pane__footer">
        <page-items :paging="fundingData.paging"></page-items>
        <a v-if="$store.getters.isMember || $store.getters.isPortfolioMember"
           class="column-toggle a"
           @click="show.columns = !show.columns">
          Columns
        </a>
        <pagination :paging="fundingData.paging"/>
      </div>
    </div>

    <modal title="VISIBLE COLUMNS"
           v-if="show.columns"
           close-on-blur
           closeable
           @close="show.columns = false">
      <template v-slot:body>
        <div>
          <p class="guide_text">Drag the properties you like to display from the left to the right list. The order of
            the properties in the list of “Visible columns” defines the order of the
            columns.</p>
          <draggable-column-picker v-model="selectedColumns"
                                   :options="allColumns"
                                   :model-value="selectedColumns"/>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import Badge from '../Badge/Badge.vue'
import SortableListView from '../ListView/SortableListView.vue'
import PageItems from '../PageItems/PageItems.vue'
import Pagination from '../Pagination/Pagination.vue'
import Modal from '../Modals/Modal.vue'
import DraggableColumnPicker from '../Form/DraggableColumnPicker.vue'
import TopFilters from '../Filters/TopFilters.vue'
import { fetchActorsFundingList } from '../../api/actors.js'
import { inert } from '../../util/helpers.js'

import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
import { MUTATION_TYPES as FILTER_MUTATION_TYPES } from '../../store/modules/filters.js'

import { viewListOptions } from '../../constants/config.js'

export default defineComponent({
  components: {
    Badge,
    SortableListView,
    DraggableColumnPicker,
    PageItems,
    Pagination,
    Modal,
    TopFilters,
  },
  name: 'funding-list',
  data () {
    return {
      actorCount: 0,
      show: {
        columns: false,
      },
      fundingData: {},
      fundingListDefaultColumns: [
        'funding_date',
        'funding_type',
        'funding_amount',
        'total_investment',
        'total_funding',
        'founding_date',
      ],  // These are the default columns for the dashboard
    }
  },
  computed: {
    config () {
      return this.$store.state.config
    },
    filters () {
      return this.$store.getters.listFilterObject
    },
    order () {
      return this.$store.state.filters.paging.order
    },
    keywords () {
      return this.$store.state.filters.keywords
    },
    listViewColumnOptions () {
      // These columns also exist in the default columns and because of that we are excluding them here
      var unwantedColumns = ['funding_date', 'founding_date']

      unwantedColumns.forEach(column => {
        var index = viewListOptions.indexOf(column)
        viewListOptions.splice(index, 1)
      })

      return viewListOptions
    },
    fundingListColumnOptions () {
      return this.fundingListDefaultColumns
    },
    allColumns () {
      var columns = []
      return columns.concat(this.fundingListColumnOptions, this.listViewColumnOptions)
    },
    fundingListSelectedColumns () {
      return this.$store.getters.userSettings.fundingList
    },
    selectedColumns: {
      get () {
        var selected = []

        if (this.fundingListSelectedColumns && this.fundingListSelectedColumns.length > 0) {
          selected = selected.concat(this.fundingListSelectedColumns.split(','))
        } else {
          selected = selected.concat(this.fundingListDefaultColumns)
        }

        return selected
      },
      set (columns) {
        this.$store.commit('USER/STORE_SETTINGS', { fundingList: columns.join(',') })
      }
    },
  },
  methods: {
    toggleFilter () {
      if (this.$store.state.filters.filter.visible) {
        this.$store.commit(FILTER_MUTATION_TYPES.TOGGLE_FILTER, false)
      } else {
        this.$store.commit(FILTER_MUTATION_TYPES.TOGGLE_FILTER, true)
      }
    },
    updateActorCount () {
      /*actorCount(this.filters).then( number => {
        this.actorCount = number.count
      })*/
    },
    getData () {
      var fundingListFilters = inert(this.filters)

      fundingListFilters = Object.assign(fundingListFilters, { order: this.order })

      fetchActorsFundingList(fundingListFilters)
          .then(response => {
            this.fundingData = response
          })
          .catch(errors => {
            //console.log(errors)
          })
    },
  },
  mounted () {
    this.getData()
  },
  beforeUnmount () {
    this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
  },
  watch: {
    filters: {
      deep: true,
      handler () {
        this.getData()
        this.updateActorCount()
      },
    },
    selectedColumns () {
      this.getData()
      this.updateActorCount()
    },

  }
})
</script>

<style lang="scss">
.score-list__headeing {
  float: left;
}
</style>
