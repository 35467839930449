<template>
  <div class="ds-input" :class="{ 'ds-input--has-icon': icon }">
    <div class="keyword-search-bar-icon keyword-search-bar-container" v-if="icon">
      <icon :name="icon" />
    </div>
    <div class="knowledge-base_free-text-tags-section">
      <!-- <div class="keyword-search-bar-tags keyword-search-bar-container" v-if="freeTextKeywords.length > 0 && freeTextKeywords.find((f) => f.facet !== 'actor_type')">
        <keyword-with-facet
          v-for="(freeTextKeyword, index) in freeTextKeywords"
          v-if="freeTextKeyword.value !== '-' && canShowFreeTextTag(index) && validateFacetType(freeTextKeyword)"
          :label="freeTextKeyword.value"
          :model-value="freeTextKeyword"
          class="knowledge-base_top-filters_free-text-tag"
          removeable
          @remove="handleRemoveFilter"
          />
      </div> -->

      <div class="keyword-search-bar__search-bar keyword-search-bar-container">
        <ds-input :placeholder="placeholder" :model-value="modelValue" @update:modelValue="setFreeTextValue" @keyup.enter="handleAddFilter(false)"  />
      </div>
    </div>

    <div class="knowledge-base_other-tags-section">
      <div class="knowledge-base_other-tags-section-text">{{ otherKeywords.length > 0 && hasOtherFiltersThanMediaType ? 'Your search is now limited to:' : 'Select some filters to specify your search'}}</div>
      <div class="knowledge-base_other-tags-container">
          <keyword-with-facet
            v-for="(otherKeyword, index) in otherKeywords"
            :key="'other-keyword-with-facet__' + index"
            :label="humanizeKnowledgeBaseFacet(otherKeyword)"
            :title="otherKeyword.text"
            :model-value="otherKeyword"
            removeable
            @remove="handleRemoveFilter"
          />
      </div>
    </div>

    <modal title="Active filters"
      v-if="showMoreModal"
      close-on-blur
      closeable
      @close="showMoreModal = false">
      <template v-slot:body>
        <div style="display: flex; flex-wrap: wrap;" >
            <keyword-with-facet
              v-for="(keyword, index) in keywords"
              :key="'keyword-with-facet__' + index"
              :label="keyword.value"
              :model-value="keyword"
              :class="{'knowledge-base_top-filters_free-text-tag' : keyword.facet == 'free_text'}"
              removeable
              @remove="handleRemoveFilter"
            />
        </div>
      </template>
    </modal>

    <div class="keyword-search-bar__links keyword-search-bar-container" v-if="displayDeleteAllFilters">
      <a @click="clearAllFilters" style="text-decoration: none;">Delete All</a>
      <a @click="showMoreModal = true" v-if="otherKeywords.length > otherFilterTagNumberLimit || freeTextKeywords.length > freeTextFilterTagNumberLimit">View more</a>
    </div>
  </div>
</template>

<script>
  import KeywordWithFacet from '../Keyword/KeywordWithFacet.vue'
  import SuggestionInput from '../SuggestionInput/SuggestionInput.vue'
  import Modal from '../Modals/Modal.vue'


  import escapeHTML from '../../util/escapeHTML'
  import querify from '../../util/querify'
  import { trackHeapEvent } from '../../util/analytics'

  export default {
    name:"knowledge-base-keyword-suggestion-search-bar",
    data: () => ({
      searchText: '',
      showMoreModal: false,
      freeTextFilterTagNumberLimit: 2,
      otherFilterTagNumberLimit: 7,
      keywordSuggestionSettings: {
        api: {
          url: '/api/dashboards/knowledge?suggestion={query}',
          // TODO: filter out values that are already selected as a keyword
          onResponse: function (data) {
            if (Object.values(data).length == 0) {
              // This will only be shown if there are no data being returned
              // If so we return this option in the results and using to customise the "No results" message and the value that it should
              // Pass when clicked
              return { results: [{tag: 'Search for free text', isFreeText: true}] }
            }

            return { results: Object.values(data) }
          }
        },
        renderResults (response) {
          // First the returned valeus are sorted
          function orderArray(a,b) {
            const facetA = a.tag.toUpperCase();
            const facetB = b.tag.toUpperCase();

            // To set the 'Search for free text' as the last item in the list
            if (a.tag == 'Search for free text' || b.tag == 'Search for free text') {
              return 0
            }
            if (facetA < facetB) { return -1; }
            if (facetA > facetB) { return 1; }
            return 0;
          }

          var resultsCopy = [];
          response.results.forEach((item, index) => {
            resultsCopy.push(item);
          })

          if (response.results.filter(item => item.isFreeText).length == 0) {
            // This result will only be showed when there are results being returned from the response
            resultsCopy.push({ tag: 'Search for free text', isOtherFreeText: true });
          }

          return resultsCopy.map((r, index) => {
            // Only for when there ARE results being returned from the request
            if (r.isOtherFreeText) {
              return `<a class="result">
                <h4 class="">${escapeHTML(r.tag)}</h4>
              </a>`
            }
            // Only for when there ARE NO results being returned from the request
            if (r.isFreeText) {
              return `<a class="result">
                <h3>No results found</h3>
                <span class="keyword-search-bar__dropdown-item">${escapeHTML(r.tag)}</span>
              </a>`
            }
            return`<a class="result">
                <span class="keyword-search-bar__dropdown-item">${escapeHTML(r.tag)}</span>
              </a>`
          })
        }
      },
    }),
    props: {
      icon: {
        type: String
      },
      modelValue: null,
      placeholder: null,
      type: {
        type: String,
        default: 'text'
      },
      name: {
        type: String
      },
      precision: {
        default: 0
      },
      readonly: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
    },
    computed: {
       isMember () {
        return this.$store.getters.isMember
      },
      isPortfolioMember () {
        return this.$store.getters.isPortfolioMember;
      },
      keywords () {
         if (! this.$parent.filters) {
           return []
         }

        return [...this.$parent.filters]
          .filter(keyword => keyword.value !== '-' && keyword.facet !== 'actor_type' && keyword.facet !== 'legendProperty' && keyword.facet.indexOf('report_field_') == -1)
      },
      freeTextKeywords () {
        return this.keywords.filter(keyword => keyword.facet == 'free_text')
      },
      otherKeywords () {
        // If both public_ and private_resource are in the keywords, don't display them, only display if it matters, which is if only of them is in there
        var includesPrivateResource = false;
        var includesPublicResource = false;

        this.keywords.forEach(keyword => {
          if (keyword.value == 'private_resource') {
            includesPrivateResource = true;
          } else if (keyword.value == 'public_resource') {
            includesPublicResource = true;
          }
        });

        var displayKeywords = this.keywords;

        if (includesPrivateResource && includesPublicResource) {
          displayKeywords = displayKeywords.filter(keyword => keyword.value !== 'private_resource' && keyword.value !== 'public_resource');
        }

        // If the user does not have access to public and private resource, filter them out, doesn't make sense to diplay "resource"
        if (! this.isMember && ! this.isPortfolioMember) {
          displayKeywords = displayKeywords.filter(keyword => keyword.value !== 'private_resource' && keyword.value !== 'public_resource');
        }

        return displayKeywords
          .filter(keyword => keyword.facet !== 'free_text')
          .filter(keyword => keyword.value !== '-' && this.validateFacetType(keyword))
          .slice(0, this.otherFilterTagNumberLimit)
      },
      hasOtherFiltersThanMediaType () {
        return this.keywords.filter(keyword => keyword.facet !== 'media_types' || keyword.facet !== 'free_text').length > 0
      },
      formatedFilters () {
        return querify(this.$parent.$parent.formatFilters(this.keywords));
      },
      displayDeleteAllFilters () {
        return this.keywords.length > 0 || this.$store.state.knowledgeBase.conceptSearch;
      },
      enabled () {
        if (this.$store.getters.isWear && this.$route.query.actor_type === '') {
          return this.$store.state.config.filterControls.filter(f => !['founding_date'].includes(f));
        }
        return this.$store.state.config.filterControls || [];
      },
      dateRanges () {
        return this.$store.state.filters.dateRanges;
      },
    },
    methods: {
      setFreeTextValue (value) {
        this.searchText = value;
      },
      handleAddFilter (item) {
        // Given that the 'Search for free text' options is not part of the returned suggestions it will always return false or will contain 'isFreeText' property
        // If it is the case we know that the free text option was selected and we can pass the 'facet' and 'searchText' value
        if (item.isFreeText || item == false) {
          this.$emit('addedFilter', {facet: 'free_text', value: this.searchText} );
        } else {
          this.$emit('addedFilter', {facet: 'tags', value: item.tag} );
        }
      },
      handleRemoveFilter (item) {
        this.$emit('removedFilter', {value: item.value});
        if (this.keywords.length == 0) {
          this.showMoreModal = false;
        }
      },
      clearAllFilters () {
        this.$emit('clearAll');
        trackHeapEvent('knowledgeBase.clearAllFilters');
      },
      canShowFreeTextTag (index) {
        return (index + 1) <= this.freeTextFilterTagNumberLimit;
      },
      canShowOtherTag (index) {
        return (index + 1) <= this.otherFilterTagNumberLimit;
      },
      validateFacetType (filter) {
        switch(filter.facet) {
          case 'actor_type':
          case 'legendProperty':
          return false;
            break;
          case 'media_types':
            if (filter.value == 'private_resource' || filter.value == 'public_resource') {
              return true
            }
            return false;
            break;
          default:
            return true;
            break;
        }
      },
      humanizeKnowledgeBaseFacet (facet) {
        var value = facet.value;

        if (value == 'public_resource' && ! this.isMember && ! this.isPortfolioMember) {
          return 'Resource';
        }

        return facet.displayName || facet.label || value;
      },
      calculateFilterTagNumberLimits () {
        var containerExtraSpace = 100;
        if (document.querySelectorAll('.keyword').length > 0) {
          // this.freeTextFilterTagNumberLimit = Math.round((document.querySelectorAll('.knowledge-base_free-text-tags-section')[0].clientWidth - document.querySelectorAll('.keyword-search-bar__search-bar')[0].clientWidth - containerExtraSpace) / document.querySelectorAll('.keyword')[0].clientWidth)

          this.otherFilterTagNumberLimit = Math.round((document.querySelectorAll('.knowledge-base_other-tags-section')[0].clientWidth - document.querySelectorAll('.knowledge-base_other-tags-section-text')[0].clientWidth - containerExtraSpace) / document.querySelectorAll('.keyword')[0].clientWidth)
        }
      }
    },
    created () {
      // We need to include the queryfied filters in the api endpoint
      // In the data promise we cannot add the parameters there so we add them when creating the component
      this.keywordSuggestionSettings.api.url = `/api/dashboards/knowledge?suggestion={query}&${this.formatedFilters}`;
    },
    mounted () {
      this.$bus.on('resize', () => {
        this.calculateFilterTagNumberLimits()
      });

      //setTimeout(() => this.calculateFilterTagNumberLimits(), 200)
    },
    beforeUnmounted () {
      this.$bus.off('resize');
    },
    watch: {
      showMoreModal (state) {
        if (state) {
          trackHeapEvent('knowledgeBase.showMoreFilters');
        } else {
          trackHeapEvent('knowledgeBase.hideMoreFilters');
        }
      }
    },
    components: {
      KeywordWithFacet,
      SuggestionInput,
      Modal
    }
}
</script>
