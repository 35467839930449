<template>
  <div class="actor-suggestions">
    <div class="fixed-heading">
      <div class="card__general side-panel__header">
        <div class="card__title side-panel__actor-suggestions-card__title side-panel__actor-suggestions-header-card__title">Suggestions</div>
        <div class="pull-right side-panel__announcement-header-button-container"  style="text-align: right;">
          <ds-button icon="remove" variant="minimal" @click="hidePreview" size="small" class="side-panel__announcement-header-icon"/>
        </div>
      </div>
    </div>
    <div class="scrollable side-panel__scrollable__content has-padding" style="padding-bottom: 150px">
      <p class="form-group__label__text" style="margin-bottom: 0.5rem;">We have found some information about {{ actor.name }}, please review!</p>
      <div class="form-group__error" v-if="Object.keys(errors).length" style="font-size: 12px; margin-bottom: 0.5rem;">
        There {{ Object.keys(errors).length === 1 ? 'was 1 error' : 'were ' + Object.keys(errors).length + ' errors' }} while processing the suggestions:
        <div v-for="error in errors">
          {{ Array.isArray(error) ? error[0] : error }}
        </div>
      </div>
      <actor-suggestions
        :actor="actor"
        :suggestions="suggestions"
        :duplicates="duplicates"
        @openDuplicatesPanel="openDuplicatesPanel"
        @handledSuggestion="removeSuggestion"
        @change="fetch(false)"
        @error="catchErrors"
        class="side-panel__actor-suggestions"
      />
    </div>
  </div>
</template>

<script>
  import Card from '../../components/Card/Card.vue'
  import ActorSuggestions from '../../components/ActorSuggestions/ActorSuggestions.vue'

  import { Suggestions } from '../../api/heartbeat.js'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { ACTION_TYPES as ACTOR_ACTION_TYPES } from '../../store/modules/actors'
  import KEYCODES from '../../constants/keycodes'

  import { fetchDuplicateActorsForProperties } from '../../api/actors'

  export default {
    name: 'actor-suggestions-panel',
    data () {
      return {
        errors: {},
        suggestions: [],
        duplicateInput: {},
        duplicates: [],
      }
    },
    props: {
      actorId: String,
    },
    computed: {
      actor () {
        if (this.$store.state.actors.detail.data.id === this.actorId) {
          return this.$store.state.actors.detail.data;
        }
      },
      isOwner () {
        return this.$store.getters.isOwner;
      },
      isTeamMember () {
        return this.$store.getters.isTeamMember;
      },
      isDeveloper () {
        return this.$store.getters.isDeveloper;
      },
      isPrivate () {
        return this.$store.getters.isPrivate;
      },
      isPublic () {
        return this.$store.getters.isPublic;
      },
    },
    methods: {
      hidePreview (evt) {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL);
      },
      fetch (closePanel) {
        this.errors = {};
        this.fetchSuggestions();

        if (closePanel) {
          this.$store.dispatch(ACTOR_ACTION_TYPES.FETCH_ACTOR_DETAIL, this.actorId);
          this.hidePreview();
        }
      },
      fetchSuggestions () {
        if (!this.isOwner && ! this.isTeamMember) {
          return;
        }

        var actor_id = this.actorId;
        Suggestions.get('', { actor_id, limit: 100 })
          .then(data => {
            this.suggestions = data;

            if (this.suggestions.length == 0) {
              this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL);
            }

            this.fetchDuplicates(data)
          })
      },
      removeSuggestion (id) {
        this.suggestions = this.suggestions.filter(s => s.id !== id)
      },
      fetchDuplicates (suggestions) {
        let foundDuplicateInput = false

        for (const suggestion of suggestions) {
          if (DUPLICATE_SEARCH_PROPERTIES.includes(suggestion.property)) {
            if (this.duplicateInput[suggestion.property] === undefined) {
              this.duplicateInput[suggestion.property] = []
            }

            this.duplicateInput[suggestion.property].push(suggestion.identifier_id)
            foundDuplicateInput = true
          }
        }

        if (foundDuplicateInput) {
          fetchDuplicateActorsForProperties(this.actorId, this.duplicateInput).then(duplicates => { this.duplicates = duplicates })
        }
      },
      openDuplicatesPanel () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component: 'actor-duplicates-panel', metaData: {duplicateInput: this.duplicateInput, duplicatesAreVirtual: true}})
      },
      catchErrors(errors) {
        this.errors = errors;
      }
    },
    mounted () {
      window.addEventListener('keyup', (e) => {
        if (e.keyCode === KEYCODES.ESC) {
          this.hidePreview();
        }
      })

      this.fetch();
    },
    components: {
      Card,
      ActorSuggestions
    }
  }

  const DUPLICATE_SEARCH_PROPERTIES = [
    'url',
    'linkedin',
    'company_number',
  ]
</script>
