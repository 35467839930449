<template>
  <div class="dictionary-component">
    <form-group label="Name" class="dictionary-component__grow" style="min-width: 14rem; max-width: 14rem;">
      <ds-input :disabled="!allowRename" v-model="newsGroup.name" ref="input"/>
    </form-group>
    <form-group label="Add sources to the news group" style="flex-grow: 2">
      <autocomplete-tag-input
        :id="'news-group-component-' + newsGroup.id"
        ref="newsGroupComponent"
        :tags="sources"
        :options="sourceOptions"
        :addOnlyFromAutocomplete="true"
        @tagChanged="updateSourceOptions"
        :validation="validation"
        @input:raw="updateSources"
        :minInputLength="1"
        class="dictionary-component__grow"
        :for-sources="true"
        placeholder="Add a source"
      />
    </form-group>
    <div class="dictionary__buttons">
      <ds-button
        label="Remove News Group"
        size="small"
        icon="trash"
        @click="removeNewsGroup()"
        style="margin-left: auto;"
        v-if="showDeleteButton"
      />
    </div>
  </div>
</template>

<script>
  import TagInput from '../../Form/TagInput.vue'
  import AutocompleteTagInput from '../../Form/AutocompleteTagInput.vue'
  import { fetchSourceSuggestions } from '../../../api/exploration'
  import { debounce } from 'lodash'

  export default {
    name: 'NewsGroupComponent',
    props: ['newsGroup', 'index', 'showDeleteButton', 'allowRename'],
    data () {
      return {
        validation: [
          {
            classes: 'url',
            rule: /[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9](?:\.[a-zA-Z]{2,})+/,
            disableAdd: true,
          },
        ],
        sourceOptions: [],
      }
    },
    computed: {
      sources () {
        return this.transformSources()
      },
    },
    methods: {
      updateSourceOptions: debounce(function (query) {
        // Check if query is already added as a source
        let elements = document.getElementById('news-group-component-' + this.newsGroup.id)

        let inCurrentSelection = false
        const currentSelection = elements.getElementsByTagName('li');

        // Check if the current option is already added to the current news group
        if (query !== '') {
          for (var i = 0; i < currentSelection.length; i++) {
            let itemClass = currentSelection[i].className

            // Exclude the suggested tags
            if (itemClass.indexOf('ti-tag') <= -1) {
              continue;
            }

            let innerText = currentSelection[i].innerText

            // Remove the ER name
            if (innerText.split(' ').length > 0) {
              innerText =  innerText.split(' ')[innerText.split(' ').length - 1]
            }

            if (innerText === query) {
              inCurrentSelection = true
              alert('The source "' + query + '" is already added in this News Group.')
            }
          }
        }

        // Make suggestions
        fetchSourceSuggestions({ query })
          .then(response => {
            this.sourceOptions = response

            if (response.length === 0) {
              // Highlight matching existing options
              // console.log('no results')
            }
          })
          .catch(error => {
            //
          })
      }, 100),
      transformSources () {
        var sources = []

        if (this.newsGroup.sources.length > 0) {
          this.newsGroup.sources.forEach(keyword => {
            let keywordLabel = keyword.label
            // Append the ER name to the display text
            if (keyword.er_name) {
              keywordLabel = '(' + keyword.er_name + ') ' + keywordLabel
            }

            sources.push({
              text: keyword.label,
              optionValue: {
                text: keyword.label,
                displayText: keywordLabel,
                hoverText: keyword.er_name,
                source: keyword,
                sourceTagInactive: !keyword.serviced_by_er
              },
            })
          })
        }

        return sources
      },
      removeNewsGroup () {
        this.$emit('remove', this.index)
      },
      updateSources (sources) {
        var updatedSources = []

        sources.forEach(source => {
          if (source.optionValue && source.optionValue.source) {
            updatedSources.push(source.optionValue.source)
          } else {
            updatedSources.push({
              label: source.text,
              name: source.name,
              er_name: source.name,
              serviced_by_er: source.servicedByER,
            })
          }
        })

        this.newsGroup.sources = updatedSources
      },
      focus () {
        this.$refs.input.focus()
      },
    },
    components: {
      AutocompleteTagInput,
      TagInput,
    },
  }
</script>

<style lang="scss" scoped>
  .dictionary-component {
    display: flex;
    align-items: flex-start;

    .form-group {
      margin-bottom: 0px !important;
    }

    .dictionary-component__grow {
      min-width: 0;
      flex-basis: 0;
      flex-grow: 1;
      margin-right: 20px;
    }

    .dictionary__buttons {
      padding-top: 20px;
    }
  }
</style>
