<template>
  <div class="fixed-heading">
    <div class="heartbeat-container">
      <div class="dropdown">
        <Dropdown
          @update:modelValue="changeDate"
          :options="availableMonths"
          v-model="currentDate"
        />
      </div>

      <NewCard>
        <div v-if="data" class="data-consumption-cards">
          <EcosystemInfoCard title="Active data package">
            <p class="text-muted">Your plan includes a data package for base, full or custom enrichment.</p>
            <p class="data-package flex-bottom">
              {{ dataPackage }}
            </p>
          </EcosystemInfoCard>
          <EcosystemInfoCard title="Remaining actor credits">
            <p class="text-lg flex-bottom">
              {{ remainingCredits }} Actor Credits
            </p>
          </EcosystemInfoCard>
          <EcosystemInfoCard title="Data consumption" class="data-consumption-bars">
            <p class="text-muted">Your consumption of actor credits for this month.
              <router-link v-if="isOwner" to="/settings/subscription">Configure which actors will be enriched and how often.</router-link>
            </p>
            <DataConsumptionProgressBar :amount="data.consumed" :total="data.credits_leftover"/>
            <p class="text-lg text-right flex-bottom">
              You have consumed {{ data.consumed }} out of {{ data.credits_leftover }} Actor Credits
            </p>
          </EcosystemInfoCard>
          <EcosystemRefill
            :autoRefill="autoRefill"
            :remainingCredits="remainingCredits"
            :totalCredits="data.credits_leftover"
            @toggleAutoRefill="toggleAutoRefill"
          >
          </EcosystemRefill>
        </div>
      </NewCard>

      <DataConsumptionChart
        v-if="data"
        :histogram="data.histogram"
        :logs="data.logs"
      />

      <DataConsumptionTreeMap v-if="dataFreshness && Object.keys(dataFreshness).length > 0" :data="dataFreshness"/>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import Dropdown from '../Dropdown/Dropdown.vue'
  import NewCard from '../../components/NewCard/NewCard.vue'
  import DataConsumptionChart from './DataConsumption/DataConsumptionChart.vue'
  import DataConsumptionTreeMap from './DataConsumption/DataConsumptionTreeMap.vue'
  import DataConsumptionProgressBar from './DataConsumption/DataConsumptionProgressBar.vue'
  import EcosystemInfoCard from './Heartbeat/EcosystemInfoCard.vue'
  import EcosystemRefill from './DataConsumption/EcosystemRefill.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'

  import { fetchDataConsumption, fetchDataFreshness } from '../../api/heartbeat'
  import { updateAutomaticRefill } from '../../api/config.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    data () {
      return {
        autoRefill: window.config.automatic_refill,
        currentDate: '',
        data: null,
        dataFreshness: {},
      }
    },
    computed: {
      isOwner () {
        return this.$store.getters.isOwner
      },
      availableMonths () {
        var values = []
        const toDate = moment()

        var dates = [
          new Date('2019-03-01'), // Timestamp of the release of the billing feature, and thus only results starting from them are relevant
          new Date(window.config.created_at),
        ]

        var startDate = new Date(Math.max.apply(null, dates))

        for (
          let date = moment(startDate).startOf('month');
          date.diff(toDate, 'days') < 0 || (date.diff(toDate, 'days') == 0 && date.month() == toDate.month());
          date.add(1, 'month')
        ) {
          var timestamp = `${moment(date).format('YYYY-MM')}-01`

          values.push({
            label: moment(date).format('MMMM YYYY'),
            value: timestamp,
          })
        }

        return values.reverse()
      },
      dataPackage () {
        return window.config.data_package
      },
      remainingCredits () {
        return this.data.credits_leftover - this.data.consumed
      },
    },
    methods: {
      changeDate (date) {
        this.currentDate = date
        this.fetchDataConsumption()
      },
      fetchDataConsumption () {
        fetchDataConsumption(this.currentDate)
          .then((data) => {
            this.data = data
          })
      },
      fetchDataFreshness () {
        fetchDataFreshness(this.currentDate)
          .then((dataFreshness) => {
            if (dataFreshness && typeof dataFreshness === 'object' && Object.keys(dataFreshness).length > 0) {
              this.dataFreshness = dataFreshness
            }
          })
      },
      toggleAutoRefill () {
        // If the auto refill is "on" but needs to be turned "off", then ask for confirmation
        if (this.autoRefill) {
          var warningMessage = 'Warning: Turning this off will lead to outdated and incomplete data. Are you sure want to disable your data packages being automatically refilled?'

          if (this.$store.getters.hasAccessToScores) {
            warningMessage = 'Warning: Turning this off wil lead to outdated and incomplete data. Digital Footprints and Business Size scores need fresh data and will therefore no longer be calculated. Are you sure want to disable your data packages being automatically refilled?'
          }

          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, { confirmLabel: 'Yes', cancelLabel: 'No', isCancellable: true, title: 'Automatic Refill', body: warningMessage })
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CONFIRMATION)

          return
        }

        this.autoRefill = !this.autoRefill

        updateAutomaticRefill(this.autoRefill)
          .then(() => {
            window.config.automatic_refill = this.autoRefill
          })
          .catch()
      },
    },
    created () {
      if (this.$route.query && this.$route.query.heartbeat_date) {
        this.currentDate = this.$route.query.heartbeat_date
      } else {
        this.currentDate = this.availableMonths[0].value
      }

      this.fetchDataConsumption()
      this.fetchDataFreshness()

      this.$bus.on('confirmAction', () => {
        this.autoRefill = !this.autoRefill

        updateAutomaticRefill(this.autoRefill)
          .then(() => {
            window.config.automatic_refill = this.autoRefill
          })
          .catch()
      })
    },
    beforeUnmount () {
      this.$bus.off('confirmAction')
    },
    components: {
      DataConsumptionChart,
      DataConsumptionProgressBar,
      DataConsumptionTreeMap,
      Dropdown,
      EcosystemInfoCard,
      EcosystemRefill,
      NewCard,
    },
  })
</script>

<style lang="scss">
  @import '../../../scss/variables';

  .dropdown {
    position: absolute;
    right: 15px;
    top: 20px;;
    width: 200px;
  }

  .data-consumption-cards {
    display: flex;

    > * {
      flex: 1;

      + * {
        margin-left: 20px;
      }
    }

    .data-consumption-bars {
      flex: 2;
    }
  }

  .flex-bottom {
    align-items: flex-end;
    display: flex;
    flex: 1;
    justify-content: flex-end;
    padding-bottom: 8px;
  }

  .data-package {
    color: $color-primary;
    font-size: 16px;
    text-transform: uppercase;
  }

  .data-consumption-container {
    align-self: center;
    max-width: 1200px;
    width: 100%;

    .chart-container {
      display: flex;
      position: relative;
    }

    hr {
      background-color: $color-borders;
      border: 0;
      height: 1px;
      margin: 20px 0;
    }

    .chart-main {
      flex: 1;
      max-width: 100%;
      padding-right: 226px;
    }

    .chart-aside {
      position: absolute;
      right: 0;
      top: 0;
      width: 200px;
    }
  }
</style>
