<template>
  <div class="authentication-modal">
    <modal :id="modalId" ref="modal" title="Notification" :enable-mask="false" :is-closeable="true">
      <template v-slot:body>
        <div>
          <div class="scrollable">
            <div style="margin-bottom: 20px;">Taxonomies have been successfully updated.</div>
            <template v-for="messageProperty in messageProperties">
              <template v-if="hasMessages(messages[messageProperty])">
                <div class="taxonomies-modal__log">
                  <div class="taxonomies-modal__taxonomy">{{messageProperty.toUpperCase()}}</div>
                  <div class="taxonomies-modal__taxonomy-messages">
                    <template v-for="messageType in messageTypes">
                      <template v-if="messages[messageProperty][messageType] && messages[messageProperty][messageType].length > 0">
                        <div v-for="message in messages[messageProperty][messageType]">
                          <span v-html="message"></span>
                        </div>
                      </template>
                    </template>
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from './Modal.vue'
import MODAL_IDS from '../../constants/modal-ids'

export default {
  name: 'taxonomy-info-modal',
  data () {
    return {
      modalId: 'TAXONOMY_INFO',
      error: '',
      messageTypes: [
        'store',
        'update',
        'delete',
        'error'
      ]
    }
  },
  computed: {
    messages () {
     return this.$store.state.ui.modalContext.messages;
    },
    messageProperties () {
      return Object.keys(this.messages);
    },
    getMessages (messageProperty, messageType) {
      if (! messages[messageProperty]) {
        return [];
      }

      var messagesList = messages[messageProperty];

      if (! messagesList[messageType]) {
        return [];
      }

      return messagesList[messageType];
    }
  },
  methods: {
    getDisplayMessageType (messageType) {
      switch (messageType) {
        case 'store':
          return "Created";
          break;
        case 'update':
          return "Updated";
          break;
        case 'delete':
          return "Deleted";
          break;
        case 'error':
          return "Errors";
          break;
      }
    },
    hasMessages (messages) {
      if (! messages) {
        console.warn(this.messages, "messages undefined");

        return false;
      }
      return (messages.store && messages.store.length)
      || (messages.update && messages.update.length)
      || (messages.delete && messages.delete.length)
      || (messages.error && messages.error.length);
    }
  },
  components: {
      Modal
    }
  }
</script>

<style lang="scss">
  .taxonomies-modal__list-item-portfolio {
    margin-left: 25px;
  }

  .taxonomies-modal__log {
    margin-top: 0.5em;

    ul {
      margin-bottom: 6px;
    }
  }

  .taxonomies-modal__taxonomy {
    font-size: 16px;
    margin-bottom: 6px;
  }

  .taxonomies-modal__taxonomy-messages {
    margin-bottom: 20px;
  }
</style>
