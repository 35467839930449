<template>
  <li :title="title || label" @mouseover="mouseOver" @mouseout="mouseOut"
      v-tooltip.left="disableTooltip ? null : label">
    <a class="navigation__link" :href="to" v-if="isHomeLink">
      <div class="navigation__link__icon navigation__link__home-link-icon">
        <avatar :src="icon" :alt="label"/>
      </div>
    </a>
    <router-link :to="to" class="navigation__link" :class="{ 'navigation__link--active': active }" v-else>
      <div class="navigation__link__icon" v-if="icon">
        <icon :size="iconSize" :name="icon || 'line'" :style="{color: '#cecece'}"/>
      </div>
      <div class="navigation__link__label">{{ label }}</div>
    </router-link>
  </li>
</template>

<script>

import IconHoverTag from '../Icon/IconHoverTag.vue'
import Avatar from '../Avatar/Avatar.vue'

export default {
  data() {
    return {
      isHover: false,
    }
  },
  props: ['to', 'icon', 'label', 'active', 'title', 'orientation', 'isHomeLink', 'disableTooltip', 'iconSize'],
  components: {
    IconHoverTag,
    Avatar,
  },
  computed: {},
  methods: {
    mouseOver() {
      this.isHover = true;
    },
    mouseOut() {
      this.isHover = false;
    }
  }
}
</script>
