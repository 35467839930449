<template>
  <modal
    :id="modalId"
    ref="modal"
    class="add-actor-to-article__modal with-subtitle"
    @close="close"
    :title="$t('add_organisation_manually')"
    is-simplified-modal
  >
    <template v-slot:title>
      <h2 class="title">{{ $t('add_organisation_manually') }}
        <span
          class="mandatory-message">{{
            $t('all_fields_with_asterisk_are_mandatory')
          }}</span>
      </h2>
    </template>
    <template v-slot:body>
      <form-group :label="$t('search_ecosystem_for_organisations', { ecosystem: ecosystemDisplayName })" required class="add-actor-to-article__modal-content">
        <autocomplete-dropdown
          :required="true"
          is-simplified
          :model-value="actor"
          :options="allActorOptions"
          :do-search="fetchActorsDebounced"
          @update:modelValue="updateActors"
          :multiple="false"
          :allowClear="false"
          :placeholder="$t('search_organisations_by_name')"
          class="add-actor-to-article__dropdown"
          :margin-top="'0px'"
        />
        <p class="error">{{ error }}</p>
      </form-group>
    </template>
    <template v-slot:footer>
      <div class="add-actor-to-article__modal-footer">
        <ds-button
          class="add-actor-to-article__button"
          @click="submit"
          :label="$t('add_organisation')"
          size="extra-small"
          variant="rounded"
          :disabled="isLoading || isDisabled"
        />
      </div>
    </template>
  </modal>
</template>


<script>
  import MODAL_IDS from '../../constants/modal-ids.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import Modal from './Modal.vue'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import AutocompleteDropdown from '../Dropdown/AutocompleteDropdown.vue'
  import FormGroup from '../Form/FormGroup.vue'
  import { getSearchResultsForQueryType } from '../../api/general-search'
  import { mapNewGlobalSearchResultToLegacyFormat } from '../../util/helpers'
  import { getFileDetails } from '../../api/files'

  export default {
    data () {
      return {
        modalId: MODAL_IDS.ADD_ACTOR_TO_ARTICLE_MODAL,
        message: '',
        actor: [],
        allActorOptions: [],
        isLoading: false,
        linked_actor_elastic_ids: [],
        isDisabled: false,
        error: ''
      }
    },
    computed: {
      ecosystemDisplayName () {
        return this.$store.getters.ecosystemDisplayName
      },
      articleId () {
        return this.$route.params.fileId
      },
      modalContext () {
        return this.$store.state.ui.modalContext
      },
      config () {
        return this.$store.state.config
      }
    },
    methods: {
      close () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, this.modalId)
        this.$emit('close')
      },
      async fetchActorsDebounced (query) {
        this.isDisabled = false
        this.error = ''
        this.isLoading = false
        this.allActorOptions = []
        const result = await getSearchResultsForQueryType({ query, type: 'actors' })
        const combinedActorsWithoutDuplicates = mapNewGlobalSearchResultToLegacyFormat([...result.actors, ...result.products])
        this.allActorOptions = combinedActorsWithoutDuplicates.map(actor => {
          return {
            label: actor.name,
            value: actor.id
          }
        })

        return this.allActorOptions
      },
      updateActors (actor) {
        this.isDisabled = false
        if (this.linked_actor_elastic_ids.includes(actor.value)) {
          this.isDisabled = true
          this.error = 'This organisation is already linked to this article'
          this.actor = null
          return
        }
        this.allActorOptions = []
        this.actor = {
          label: actor.label,
          value: actor.value
        }
      },
      submit () {
        this.isLoading = true
        if (!this.actor.value) {
          this.error = 'Please select an organisation'
          this.isDisabled = true
          return
        }

        this.$bus.emit('actor-added-to-article', this.actor.value)
        setTimeout(() => {
          this.close()
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
            message: 'You have successfully added an organisation'
          })
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.SUCCESS)
          this.isLoading = false
        }, 2000)
      },
      fetchSimilarContent () {
        this.isLoadingSimilarContent = true
        getFileDetails(this.articleId).then((result) => {
          if (result.linked_actor_elastic_ids) {
            this.linked_actor_elastic_ids = result.linked_actor_elastic_ids
          }
        }).catch((error) => {
          console.log('error while searching similar articles: ', error)
        }).finally(() => {
          this.isLoadingSimilarContent = false
        })
      }
    },
    mounted () {
      this.fetchSimilarContent()
    },
    components: {
      Modal,
      AutocompleteDropdown,
      FormGroup
    },
    mixins: [TranslationsMixin]
  }
</script>


<style lang="scss" scoped>
  @import '../../../scss/_variables.scss';

  .add-actor-to-article__modal-content {
    box-shadow: none;
    border-radius: 0;
    margin: 10px;
    padding: 30px 10px 40px;
  }

  .add-actor-to-article__modal-label {
    font-size: 12px;
  }

  .error {
    color: $color-error;
  }

  .with-subtitle.modal__simplified :deep(.modal__wrapper .modal__container .modal__body) {
    overflow: visible;
  }
  :deep(.multiselect__content-wrapper) {
    max-height: 84px!important;
  }

</style>
