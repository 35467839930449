<template>
  <div class="knowledge-sharing">
    <div class="" v-if="hasSelectedReport && !isReportAccessibleForActor">
      No report available.
    </div>
    <div class="button--ks">
      <ds-button v-if="hasSelectedReport && showBackButton" variant="primary" class="button--small" :label="$t('actor_detail_reports_back')" @click="hideReport"/>
      <!-- additional span because disabled buttons can't have tooltips-->
      <span v-tooltip.left="$t('report_submit_disabled_tooltip')" style="margin-right: 10px">
      <ds-button variant="secondary" class="button--small" :label="submitting ? `${$t('actor_detail_reports_submitting')}...` : $t('action_submit')" @click="submitReport(false)" v-if="allowSubmitButton" :disabled="!requiredFieldsFilledIn"/>
      </span>
      <ds-button variant="secondary" class="button--small" :label="saving ? `${$t('actor_detail_reports_saving')}...` : $t('actor_detail_reports_save')" @click="save" :disabled="saving" v-if="canSave"/>
    </div>

    <div class="form-group__error" v-if="errors && errors['reports'] && errors['reports'].length > 0" style="font-size: 14px; margin-bottom: 1rem;">
      An error occurred: {{ errors['reports'][0] }}
    </div>

    <template v-for="(field, fieldIndex) in reportFields">
      <div class="ks__field" :key="'reportFieldsEmbedded' + fieldIndex" v-if="(enableReadMore && showAllFields) || !enableReadMore">
        <form-group v-if="field.type == 'embed' && validUrl(getValue(field))" label="hidden">
          <div class="embed-responsive">
            <iframe :key="getValue(field)" :src="validUrl(getValue(field))" frameborder="0" allowfullscreen></iframe>
          </div>
        </form-group>
      </div>
    </template>
    <template v-for="(field, fieldIndex) in reportFields">
      <div class="ks__field" :key="'reportFieldsOther' + fieldIndex" v-if="showAfterClickingReadMore(fieldIndex)">
      <span v-if="field.type === 'title'" class="card ks__title">
        {{ label(field) }}
      </span>
        <span v-else-if="field.type === 'subtitle'" class="guide_text ks__subtitle" :class="{'ks__field-push-right' : hasTitle}">
        {{ label(field) }}
      </span>

        <form-group :label="label(field)" :style="style(field)" :class="{'ks__field-push-right' : hasTitle}" :instructions="field.required_for_submission ? 'required' : ''" v-else>
          <ds-textarea
            v-if="field.type === 'text'"
            :model-value="getValue(field)"
            @update:modelValue="set(field, $event)"
            autoresize
          />
          <input-range
            v-else-if="field.type === 'score'"
            class="input-range--score"
            :label="field.label"
            :avg="getAverage(field)"
            :model-value="parseFloat(getValue(field) || 0)"
            @update:modelValue="set(field, $event)"
            :max="5"
          />
          <div v-else-if="field.type === 'file'">
            <file-list :model-value="existingFiles(field)" @fileRemoved="fetchDetail" :parentData="actor"/>
            <file-input
              @upload="fetchDetail"
              :height="50"
              :width="500"
              :actor-id="actor.id"
              :field-id="field.id"
            />
          </div>
          <div v-else-if="field.type === 'options'">
            <dropdown :options="field.options" :multiple="false" :model-value="getValue(field)" @update:modelValue="set(field, $event)"/>
          </div>
          <div v-else-if="field.type === 'multi_select'">
            <dropdown :options="field.options" multiple :model-value="getValue(field)" @update:modelValue="set(field, $event)"/>
          </div>
          <div v-else-if="field.type === 'checkbox'">
            <template v-for="(option, index) in field.options">
              <checkbox :model-value="getValue(field)" :multiple="true" @update:modelValue="set(field, $event)" :val="option" :display-label="true" :label="option"/>
            </template>
          </div>
          <ds-input v-else-if="field.type === 'number'"
                    :model-value="getValue(field)"
                    @update:modelValue="set(field, $event)"
                    type="number"
          />
          <div v-else-if="field.type === 'date'">
            <datepicker :model-value="getValue(field)" @update:modelValue="set(field, $event)"/>
          </div>
          <multi-select-actor v-else-if="field.type === 'multi_select_actor'" :model-value="getValue(field)" @input-json-string="set(field, $event)" :allow-input="true"/>
          <ds-input v-else
                    :model-value="getValue(field)"
                    @update:modelValue="set(field, $event)"
          />
        </form-group>
      </div>
    </template>
    <ds-button v-if="canSave" variant="secondary" :label="saving ? `${$t('actor_detail_reports_saving')}...` : $t('actor_detail_reports_save')" @click="save" :disabled="saving"/>

    <!-- on product detail page, display a read-more button when there is only one report template available, and there are more than 4 fields defined -->
    <ds-button v-if="enableReadMore && showReadMoreFields" variant="primary" :label="toggleLabelAllFields" @click="toggleAllFields"/>

    <!-- additional span because disabled buttons can't have tooltips-->
    <span v-tooltip.left="$t('report_submit_disabled_tooltip')">
      <ds-button variant="secondary" :label="submitting ? `${$t('actor_detail_reports_submitting')}...` : $t('action_submit')" @click="submitReport(false)" v-if="allowSubmitButton" :disabled="!requiredFieldsFilledIn"/>
    </span>
  </div>
</template>

<script>
  import _flatten from 'lodash/flatten'

  import Dropdown from './../Dropdown/Dropdown.vue'

  import DsTextarea from '../Form/DsTextarea.vue'
  import FileInput from '../Form/FileInput.vue'
  import FileList from '../Form/FileList.vue'
  import InputRange from '../Slider/InputRange.vue'
  import Datepicker from '../Form/Datepicker.vue'
  import Checkbox from '../Form/Checkbox.vue'
  import ReportMixin from '../../util/ReportMixin'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import MODAL_IDS from '../../constants/modal-ids.js'

  import MultiSelectActor from './MultiSelectActor.vue'

  export default {
    name: 'KnowledgeSharing',
    props: {
      actor: {
        type: Object,
      },
      enableReadMore: {
        type: Boolean,
        default: false,
      },
      showReadMoreStartingFromLenght: {
        type: Number,
        default: 4,
      },
      showBackButton: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        suggestions: [],
        hasTitle: false,
        errors: {},
        saving: false,
        submitting: false,
        showAllFields: false,
      }
    },
    computed: {
      emptyFields () {
        const result = []
        for (const field of this.reportFields) {
          switch (field.type) {
            case 'file':
              if (!this.existingFiles(field) || !this.existingFiles(field).length) {
                result.push(this.label(field))
              }
              break
            case 'score':
              if (!this.getValue(field)) {
                result.push(field.label)
              }
              break
            case 'multi_select_actor':
              if (!this.getValue(field) || this.getValue(field) === '[]') {
                result.push(this.label(field))
              }
              break
            case 'text':
            case 'embed':
            case 'options':
            case 'multi_select':
            case 'checkbox':
            case 'number':
            case 'date':
              if (!this.getValue(field)) {
                result.push(this.label(field))
              }
              break
          }
        }
        return result
      },
      canSave () {
        if (!this.isReportAccessibleForActor) {
          return false
        }

        return this.suggestions.length && !this.hasAlreadyBeenSubmitted
      },
      hasAlreadyBeenSubmitted () {
        if (!this.actor || !this.actor.current_report || this.selectedReportTemplate.is_call !== true) {
          return false
        }

        return this.reportHasBeenSubmitted(this.actor.current_report, this.selectedReportTemplate)
      },
      isMember () {
        return this.$store.getters.isMember
      },
      requiredFieldsFilledIn () {
        let requiredFieldsFilledIn = true

        this.reportFields.forEach(reportField => {
          if (!reportField.required_for_submission) {
            return
          }

          if (!this.getValue(reportField) || this.getValue(reportField).length < 1) {
            requiredFieldsFilledIn = false
          }
        })

        return requiredFieldsFilledIn
      },
      isReportAccessibleForActor () {
        let check = true

        if (!this.actor || !this.selectedReportTemplate) {
          return false
        }

        const reportTemplate = this.selectedReportTemplate
        const actor = this.actor

        if (!reportTemplate.portfolios) {
          return false
        }

        const actorPortfolios = this.actor.includedPortfolios || this.actor.portfolios

      // Filter out report templates of which portfolios are not configured to be accessible for the actor. NOTE: when no report template portfolios are configured, ignore the check
        if (reportTemplate.portfolios && reportTemplate.portfolios.length > 0) {
          const portfolioForReport = reportTemplate.portfolios.filter(portfolio => {
            if (!actorPortfolios) {
              return false
            }

            const allowedActorPortfolios = actorPortfolios.filter(actorPortfolio => {
              const actorPortfolioId = actorPortfolio.id || actorPortfolio

              return portfolio.id == actorPortfolioId
            })

            return allowedActorPortfolios.length && actorPortfolios.length > 0
          })

          // Return if actor is allowed to access report via accessible portfolios
          check = portfolioForReport.length && portfolioForReport.length > 0
        }

        return check
      },
      allowSubmitButton () {
        if (this.isMember || !this.actor || !this.selectedReportTemplate.due_date || !this.isReportAccessibleForActor) {
          return false
        }

        // dashed date formats are not supported on Safari => replace by /
        const dueDate = new Date(this.selectedReportTemplate.due_date.replaceAll('-', '/')).getTime()
        const currentDate = new Date().getTime()
        const hasAtLeastOneValue = this.suggestions.length || this.actor.current_report.length
        return this.selectedReportTemplate.is_call && dueDate > currentDate && !this.actor.current_report.submitted_at && hasAtLeastOneValue
      },
      canSubmit () {
        return this.allowSubmitButton && this.requiredFieldsFilledIn
      },
      isReportPublic () {
        if (this.isMember) {
          return this.$store.state.config.publisher.viewReportOfActorTypes && this.$store.state.config.publisher.viewReportOfActorTypes.includes(this.actor.actor_type)
        }

        return this.$store.state.config.viewReportOfActorTypes && this.$store.state.config.viewReportOfActorTypes.includes(this.actor.actor_type)
      },
      curation () {
        return this.$store.state.config.knowledgeSharing && this.$store.state.config.knowledgeSharing.curation && !this.$store.getters.isOwner
      },
      suggested () {
        // Flatten all suggestions for reports
        // And map their values
        return _flatten(((this.actor.suggestions || []).filter(s => s.property === 'reports')).map(r => r.value))
      },
      reportFields () {
        var actorReports = (this.actor.current_report || this.actor.reports || [])

        if (!this.isReportAccessibleForActor) {
          return []
        }

        return (this.selectedReportTemplate.reportFields || [])
          .map(r => {
            if (r.type === 'title') {
              this.hasTitle = true
            }
            return Object.assign({
              suggestion: this.suggestions.filter(d => d.ecosystem_report_field_id === r.id),
              suggested: _flatten(this.suggested.filter(d => d.ecosystem_report_field_id === r.id)),
              reports: _flatten(actorReports.filter(d => d.ecosystem_report_field_id === r.id)),
            }, r)
          })
      },
      showReadMoreFields () {
        return (this.reportFields.length > this.showReadMoreStartingFromLenght) || (this.reportFields.filter(reportField => reportField.type === 'embed').length > 0)
      },
      toggleLabelAllFields () {
        if (!this.showAllFields) {
          return this.$t('knowledge_sharing_read_more')
        }

        return this.$t('knowledge_sharing_read_less')
      },
    },
    methods: {
      submitReport (ignoreEmptyFields) {
        if (!ignoreEmptyFields && this.emptyFields.length) {
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
            title: `Submit ${this.reportLabel}`,
            body: `Be aware, the following fields are not completed: \n• ${this.emptyFields.join('\n• ')}\nAre you sure you want to submit?`,
            confirmLabel: `Yes`,
            modalBodyClasses: 'empty-fields-list',
            type: 'check-empty-fields',
            cancelLabel: 'No',
            isCancellable: true,
          })

          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CONFIRMATION)
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
            title: `Submit ${this.reportLabel}`,
            body: `When you submit the proposal, you cannot change it anymore. Are you sure you want to submit?`,
            confirmLabel: `Yes`,
            type: 'check-confirm',
            cancelLabel: 'No',
            isCancellable: true,
          })

          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CONFIRMATION)
        }
      },
      confirmSubmitReport () {
        this.submitting = true
        let existingAndNewReports = [...this.actor.current_report, ...this.suggestions].filter(reportField => this.isIncludedInTemplate(this.selectedReportTemplate, reportField))
        existingAndNewReports = existingAndNewReports.map(report => {
          report.submit = true
          return report
        })
        this.$store.dispatch('ACTORS/UPDATE_ACTOR', {
          id: this.actor.id,
          data: { reports: existingAndNewReports },
        })
          .then(() => {
            this.suggestions = []
            this.submitting = false
          })
          .catch(errors => {
            this.errors = errors
            this.submitting = false
          })
        this.$bus.emit('actorReportSubmitted')
      },
      label (field) {
        const suffix = this.curation ? (field.suggestion.length ? ' (suggestion)' : field.suggested.length ? ' (suggested)' : '') : ''
        switch (field.type) {
          case 'score':
            return 'hidden'
          case 'embed':
            return field.label + ' (Youtube or Vimeo) ' + suffix
        }
        return field.label + suffix
      },
      style (field) {
        return ['number', 'score'].includes(field.type) ? { maxWidth: '50%', marginBottom: '2rem' } : null
      },
      getValue (field) {
        return this.getFirst(field).value
      },
      getAverage ({ reports }) {
        return parseFloat((reports.length ? reports[0] : {}).avg_value) || 0
      },
      getFirst ({ suggestion, suggested, reports }) {
        return suggestion.length ? suggestion[0] : suggested.length ? suggested[0] : reports.length ? reports[0] : {}
      },
      getMany ({ suggestion, suggested, reports }) {
        return (suggestion.length ? suggestion : suggested.length ? suggested : reports).map(a => a.value)
      },
      set ({ id, suggestion }, value) {
        if (suggestion.length) {
          suggestion[0].value = value
        } else {
          this.suggestions.push({ ecosystem_report_field_id: id, value })
        }
      },
      existingFiles ({ suggested, reports }) {
        const fileIds = reports.concat(suggested).map(a => (a.value || '').toString())
        return (this.actor.files || []).filter(file => fileIds.includes(file.id.toString()))
      },
      fetchDetail () {
        this.$store.dispatch('ACTORS/FETCH_ACTOR_DETAIL', true)
      },
      preview (file) {
        return file && file[0] && file[0].preview_w320 ? { backgroundImage: 'url(' + file[0].preview_w320 + ')' } : null
      },
      validUrl (url) {
        if (typeof url !== 'string') {
          return
        }

        // Fix protocol
        if (!url.includes('://')) {
          url = 'https://' + url
        }

        // Fix html code
        if (url.includes('src=')) {
          url = /src=["']([^"']+)["']/.exec(url)[1]
        }

        // Restrict to vimeo and youtube
        if (!url.includes('youtu') && !url.includes('vimeo')) {
          return
        }

        // Double check
        if (/^https:\/\/[a-zA-Z0-9.\-_]+\..*$/.test(url)) {
          return url
        }
      },
      save () {
        this.errors = {}
        this.saving = true

        this.$store.dispatch('ACTORS/UPDATE_ACTOR',
          {
            id: this.actor.id,
            data: { reports: this.suggestions },
          })
          .then(() => {
            this.suggestions = []
            this.saving = false
          })
          .catch(errors => {
            this.errors = errors
            this.saving = false
          })
      },
      showAfterClickingReadMore (fieldIndex) {
        if (this.enableReadMore && !this.showAllFields && (fieldIndex > (this.showReadMoreStartingFromLenght - 1))) {
          return false
        }

        return true
      },
      toggleAllFields () {
        this.showAllFields = !this.showAllFields
      }
    },
    mixins: [ReportMixin],
    mounted () {
      this.$bus.on('confirmAction', (context) => {
        if (context.type === 'check-confirm') {
          this.confirmSubmitReport()
        } else if (context.type === 'check-empty-fields') {
          this.$nextTick(() => {
            this.submitReport(true)
          })
        }
      })
    },
    beforeUnmount () {
      this.$bus.off('confirmAction')
    },
    watch: {
      'actor.id' (v, o) {
        if (v !== o) {
          this.suggestions = []
        }
      },
    },
    components: {
      DsTextarea,
      FileInput,
      FileList,
      InputRange,
      Dropdown,
      Datepicker,
      Checkbox,
      MultiSelectActor,
    },
  }
</script>

<style lang="scss">
  @import "../../../scss/variables";

  .kr__field, .ks__field {
    .form-group__label__text,
    .range-slider__title {
      font-family: Sofia Pro, sans-serif;
      text-transform: uppercase;
      font-size: 12px;
      font-weight: 500;
      letter-spacing: 1px;
      color: #000;
    }

    .form-group {
      color: #555;
    }

    .ds-input__input {
      color: inherit;
    }

    .ks__title, .kr__title {
      border: 0;
      background: transparent;
      color: $color-text-grey;
      font-weight: 500;
      font-size: 20px;
      font-family: "FiraSans-Medium", sans-serif;
    }

    .ks__subtitle, .kr__subtitle {
      display: block;
      padding: 10px 0;
      margin-bottom: 10px;
      font-size: 16px;
      color: #383A48;
      font-family: "FiraSans-LightItalic", sans-serif;
      font-style: italic;
    }

    .ks__field-push-right, .kr__field-push-right {
      padding-left: 15px;
    }
  }

  .kr__field {
    .form-group__label__text,
    .range-slider__title {
      font-size: 14px;
    }
  }

  .knowledge-sharing {
    position: relative;
  }

  .button--ks {
    position: absolute;
    top: -58px;
    right: 95px;
  }

  .knowledge-sharing__warning {
    display: flex;
    font-size: 12px;
    color: #F77144;
    margin-bottom: 1rem;
  }
</style>
