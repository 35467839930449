<template>
  <div class="article-analysis">
    <h3 class="h3">
      <span class="h3-title">
        {{ $t('article_detail_classifications_tab') }}
      </span>
    </h3>
    <div class="row" v-if="article" style="margin-bottom: 0">
      <div
        class="col-sm-12 col-xs-12 col-md-12 col-lg-4 classification-list"
        v-for="(axis, index) in classificationAxes" :key="'classification_axis__' + axis"
      >
        <div class="classification-title" v-if="!isLoading" :title="reportTypeLabels[axis] || axis">
          {{ reportTypeLabels[axis] || axis }}
        </div>
        <div v-else class="classification-title__skeleton"></div>
        <tag-selector
          :selected="option.selected || localAcceptedDictionaries.includes(option.value)"
          :removed="option.denied || localDeniedDictionaries.includes(option.value)"
          v-for="(option, index) in acceptedOptionsForAxis(axis)"
          :is-loading="option.isLoading"
          :label="option.label"
          :highlight-first-letter="axis === 'market_trends'"
          :show-accept-button="!option.selected && !localAcceptedDictionaries.includes(option.value)"

          :show-deny-button="true"
          @deny="deselectClassification(option)"
          @accept="selectClassification(option)"
          :key="axis + index"
        />
       <div
          class="tag-selector"
          v-if="!isLoading"
          :class="{'add-more-active': showMoreForClassification === axis}"
        >
          <div
            class="tag-selector__add-more"
            @click="onClickShowMoreForClassification(axis)"
          >
            {{ getAddMoreTextForAxis(axis) }}
          </div>
          <icon
            name="chevron-down" style="margin-left: auto; cursor: pointer"
            @click="onClickShowMoreForClassification(axis)"
          />
          <div
            class="tag-selector__more-container"
            v-if="showMoreForClassification === axis"
          >
            <div
              class="tag-selector__more"
              v-for="(option, index) in additionalOptionsForAxis(axis)"
              :title="option.label"
              @click="selectClassification(option)"
              :key="'more' + axis + option.label"
            >
              <div
                v-if="axis === 'market_trends'"
                class="tag-selector__more-label"
              >
                <span style="font-weight: 500">{{
                    option.label.charAt(0)
                  }}
                </span>{{ option.label.slice(1) }}
              </div>
              <div v-else class="tag-selector__more-label">
                {{ option.label }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <h3 class="h3">
      <span class="h3-title">
        {{ $t('mentioned_actors') }}
      </span>
      <span class="title-cta" @click="addNewActor" v-if="!isLoading">+ {{
          $t('article_detail_add_actor')
        }}</span>
    </h3>
    <div v-if="mergedActors && mergedActors.length">
      <div
        class="row analysis-card-list"
      >
        <div
          class="col-sm-12 col-xs-12 col-md-12 col-lg-4"
          v-for="(actor, index) in slicedActors" :key="'actor' + index"
        >
          <div
            v-if="!actor.isLoading"
            class="analysis-card"
            :style="{paddingBottom: actor.description ? '0' : '10px'}"
            @click="onClickActorCard(actor)"
            :class="{'analysis-card__added': actor.state === 'added'}"
          >
            <h4 class="h4">{{ actor.name }}</h4>
            <p v-if="actor.description">{{ actor.description }}</p>
            <icon
              class="analysis-card-icon" name="plus"
              v-if="!actor.state || (actor.state === 'added' && !actor.id)"
            />
            <icon
              class="analysis-card-icon"
              style="float: right; margin-left: 15px;" name="spinner"
              v-if="actor.state === 'adding'"
            />
            <icon
              class="analysis-card-icon"
              style="float: right; margin-left: 15px;" name="link"
              v-if="actor.state === 'added' || actor.id"
            />
          </div>
          <skeleton-card v-else :wrapper-class="'analysis-card__skeleton'" />
        </div>
      </div>
      <div
        class="show-more-text" @click="showMoreForActors = !showMoreForActors"
        v-if="mergedActors.length > 3"
      >
        {{
          showMoreForActors ? $t('knowledge_sharing_read_less') : $t('knowledge_sharing_read_more')
        }}
      </div>
    </div>
    <div class="empty-text" v-else>
      {{ $t('article_detail_no_actors') }}
    </div>
    <h3 class="h3">
      <span class="h3-title">
        {{ $t('article_detail_mentioned_relationships') }}
      </span>
      <span
        v-if="isMember && !isLoading && actorsForNewRelations.length >= 2"
        class="title-cta"
        @click="addRelation()"
      >+ {{
          $t('article_detail_add_relation')
        }}</span>
    </h3>
    <div v-if="slicedRelations && slicedRelations.length">
      <div
        class="row analysis-card-list" v-if="slicedRelations.length > 0"
      >
        <div
          class="col-sm-12 col-xs-12 col-md-12 col-lg-4"
          v-for="(relation, index) in slicedRelations"
          :key="'relation' + index"
        >
          <div
            class="analysis-card"
            v-if="!relation.isLoading"
            :class="{'analysis-card__added': getRelationStatus(relation) === RELATION_STATUS.DONE}"
          >
            <h4 class="h4" style="padding-bottom: 20px">
              <span
                class="extracted-actor"
                :class="{clickable: actorExistsOnEcosystem(relation.entity_A_alias, relation.entity_A_type)}"
              >
                <a
                  :href="getConnectionsUrlForActor(relation.entity_A_alias, relation.entity_A_type, relation.entity_A_id)"
                  v-if="getConnectionsUrlForActor(relation.entity_A_alias, relation.entity_A_type, relation.entity_A_id)"
                >{{
                    relation.entity_A_alias
                  }}
                </a>
                <template v-else>{{ relation.entity_A_alias }}</template>
              </span>
              <mark>{{
                  relation.mappedRelation.label
                }}
              </mark>
              <span
                class="extracted-actor"
                :class="{clickable: actorExistsOnEcosystem(relation.entity_B_alias, relation.entity_B_type)}"
              >
              <a
                :href="getConnectionsUrlForActor(relation.entity_B_alias, relation.entity_B_type, relation.entity_B_id)"
                v-if="getConnectionsUrlForActor(relation.entity_B_alias, relation.entity_B_type, relation.entity_B_id)"
              >{{
                  relation.entity_B_alias
                }}
              </a>
              <template v-else>{{ relation.entity_B_alias }}</template>
            </span>
            </h4>
            <div
              class="extracted-relation-icon" @click="addRelationFromNER(relation)"
              :class="relationsInProgress.length ? 'disabled' : 'clickable'"
              v-if="getRelationStatus(relation) === RELATION_STATUS.OPEN"
            >+
            </div>
            <div
              class="extracted-relation-icon"
              v-else-if="getRelationStatus(relation) === RELATION_STATUS.DONE"
            >
              <icon name="link"/>
            </div>
            <div
              class="extracted-relation-icon"
              v-else-if="getRelationStatus(relation) === RELATION_STATUS.IN_PROGRESS"
            >
              <icon name="spinner"/>
            </div>
            <div
              class="extracted-relation-icon"
              v-else-if="relation.error"
            >
              ×
            </div>
          </div>
          <skeleton-card v-else :wrapper-class="'analysis-card__skeleton'" />
        </div>
      </div>
      <div
        class="show-more-text" @click="showMoreForRelations = !showMoreForRelations"
        v-if="annotatedRelations.length > 3"
      >
        {{
          showMoreForRelations ? $t('knowledge_sharing_read_less') : $t('knowledge_sharing_read_more')
        }}
      </div>
    </div>
    <div v-else class="empty-text">
      {{ $t('article_detail_no_relations') }}
    </div>
    <h3 class="h3">
      <span class="h3-title">
        {{ $t('article_detail_similar_content_tab') }}
      </span>
    </h3>
    <div v-if="similarContent.length > 0">
      <div
        class="row analysis-card-list"
      >
        <div
          class="col-xs-12"
          style="margin-bottom: 20px;"
          v-for="(article, index) in slicedSimilarContent"
          :key="'article' + article.id"
        >
          <announcement-or-resource-card
            v-if="!article.isLoading"
            :data="article"
            :allow-actions="false"
          />

          <skeleton-card v-else :wrapper-class="'analysis-card__skeleton'" />
        </div>
      </div>
      <div
        class="show-more-text"
        @click="showMoreForSimilarContent = !showMoreForSimilarContent"
        v-if="similarContent.length > 3 && !isLoadingSimilarContent"
      >
        {{
          showMoreForSimilarContent ? $t('knowledge_sharing_read_less') : $t('knowledge_sharing_read_more')
        }}
      </div>
    </div>
    <div v-else class="empty-text">
      {{ $t('article_detail_no_similar_content') }}
    </div>
  </div>
</template>

<script>
  import DictionaryMixin from '../../../util/DictionaryMixin.js'
  import { CONCEPT_SEARCH_REPORT_TYPES_LABELS } from '../../../constants/concept-search-constants.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui.js'
  import MODAL_IDS from '../../../constants/modal-ids.js'
  import { fetchActor, updateActor } from '../../../api/actors.js'
  import { getSimilarContent, updateEcosystemFileMetaData } from '../../../api/files.js'
  import { contentFeedback } from '../../../api/knowledge-base.js'
  import objectPath from 'object-path'
  import { getConnectionsForActor } from '../../../util/helpers.js'
  import AnnouncementOrResourceCard from '../../Simplified/AnnouncementOrResourceCard.vue'
  import SkeletonCard from '../../Skeleton/SkeletonCard.vue'
  import TagSelector from '../../Tag/TagSelector.vue'

  const RELATION_STATUS = { OPEN: 'open', IN_PROGRESS: 'in_progress', DONE: 'done' }
  export default {
    data () {
      return {
        showMoreForClassification: '',
        RELATION_STATUS,
        localAcceptedDictionaries: [],
        localDeniedDictionaries: [],
        showMoreForActors: false,
        showMoreForRelations: false,
        showMoreForSimilarContent: false,
        isLoadingSimilarContent: true,
        detailedActors: [],
        actorsForNewRelations: [],
        similarContent: [],
        relationsInProgress: [],
      }
    },
    emits: ['article-data-changed'],
    props: {
      article: {
        type: Object,
      },
      isLoading: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      isMember () {
        return this.$store.getters.isMember
      },
      ecosystemRelationships () {
        return this.$store.getters.fullActorRelationships
      },
      addedRelations () {
        let result = []
        for (const actor of this.detailedActors) {
          result = [...result, ...getConnectionsForActor(actor, this.ecosystemRelationships).map(relation => {
            return {
              entity_A_alias: relation.name,
              entity_A_id: relation.id,
              entity_A_type: 'detail',
              entity_B_alias: actor.name,
              entity_B_id: actor.id,
              entity_B_type: 'detail',
              relation_name: relation.label,
              mappedRelation: this.availableRelationships.find(rel => rel.id === relation.relation_id),
            }
          })]
        }
        return result
      },
      addedActors () {
        return this.mergedActors.filter(actor => actor.state === 'added' && actor.id)
      },
      slicedSimilarContent () {
        if (this.isLoadingSimilarContent) {
          return [{ isLoading: true }]
        }
        if (this.showMoreForSimilarContent) {
          return this.similarContent
        }
        return this.similarContent.slice(0, 3)
      },
      classification () {
        return objectPath.get(this.article.resource, 'dictionary_similarity', [])
      },
      slicedActors () {
        if (this.showMoreForActors) {
          return this.mergedActors
        }

        return this.mergedActors.slice(0, 3)
      },
      mergedActors () {
        if (this.isLoading) {
          return [{ isLoading: true, id: 1 }, {
            isLoading: true,
            id: 2,
          }, { isLoading: true, id: 3 }]
        }

        if (this.actors.organisations && this.actors.organisations.length) {
          return [...this.actors.organisations]
        }

        return []
      },
      actors () {
        return this.article.data && this.article.data.actors
      },
      slicedRelations () {
        if (this.showMoreForRelations) {
          return this.annotatedRelations
        } else {
          return this.annotatedRelations.slice(0, 3)
        }
      },
      annotatedRelations () {
        if (this.isLoading) {
          return [{ isLoading: true }, { isLoading: true }, { isLoading: true }]
        }
        if (!this.article || !this.article.data || !this.article.data.relations) {
          return []
        }

        const result = this.article.data.relations
          .filter(relation => ['invested_by', 'is_partner', 'invested', 'collaborates_with'].includes(relation.relation_label))
          .map(relation => {
            switch (relation.relation_label) {
              case 'invested':
                return {
                  ...relation,
                  mappedRelation: this.availableRelationships.find(rel => rel.name === 'invested'),
                }
              case 'is_partner':
                return {
                  ...relation,
                  mappedRelation: this.availableRelationships.find(rel => rel.name === 'is_partner'),
                }
              case 'collaborates_with':
                return {
                  ...relation,
                  mappedRelation: this.availableRelationships.find(rel => rel.name === 'collaborates_with'),
                }
              case 'invested_by':
                return {
                  entity_A_alias: relation.entity_B_alias,
                  entity_A_type: relation.entity_B_type,
                  entity_B_alias: relation.entity_A_alias,
                  entity_B_type: relation.entity_A_type,
                  relation_label: 'invested',
                  mappedRelation: this.availableRelationships.find(rel => rel.name === 'invested'),
                }
              default:
                break
            }
            return relation
          })
        const combinedRelations = [...result, ...this.addedRelations]
        const resultsWithoutDuplicates = combinedRelations
          .filter((relation, index) => {
            return index === combinedRelations.findIndex((matchingRelation) => {
              if (!matchingRelation.mappedRelation || !relation.mappedRelation) {
                return false
              }

              return matchingRelation.mappedRelation.name === relation.mappedRelation.name && ((matchingRelation.entity_A_alias === relation.entity_A_alias && matchingRelation.entity_B_alias === relation.entity_B_alias) || (matchingRelation.entity_A_alias === relation.entity_B_alias && matchingRelation.entity_B_alias === relation.entity_A_alias))
            })
          })
        return resultsWithoutDuplicates
      },
      availableRelationships () {
        return this.$store.state.actorRelationships.relationships
      },
      reportTypeLabels () {
        return CONCEPT_SEARCH_REPORT_TYPES_LABELS
      },
      classificationAxes () {
        return this
          .mixinDefaultDictionaries
          .reduce((axes, dictionary) => {
            if (dictionary.related_report && !axes.includes(dictionary.related_report)) {
              axes.push(dictionary.related_report)
            }

            return axes
          }, [])
      },
    },
    methods: {
      async addRelationFromNER (relation) {
        if (this.relationsInProgress.length) {
          return
        }

        this.relationsInProgress.push(relation)
        const firstActor = await this.getOrCreateActor(relation.entity_A_alias, relation.entity_A_type)
        const secondActor = await this.getOrCreateActor(relation.entity_B_alias, relation.entity_B_type)

        if (!firstActor || !secondActor) {
          this.relationsInProgress = []
          relation.error = true
          return
        }

        await this.createRelationBetween(firstActor, secondActor, relation.mappedRelation.name)
        this.$emit('article-data-changed')
      },
      async createRelationBetween (firstActor, secondActor, relationType) {
        let existingRelation = []

        if (firstActor[relationType]) {
          existingRelation = [...firstActor[relationType]]
        }

        existingRelation.push({
          to: secondActor.id,
        })

        await updateActor({
          id: firstActor.id,
          data: {
            [relationType]: existingRelation,
          },
        })
      },
      async getOrCreateActor (actorName, actorType) {
        let result = this.getRelatedActor(actorName, actorType)
        if (!result || result.state !== 'added') {
          result = await this.addActor(actorName, actorType)
        }
        return result
      },
      addRelation () {
        const modalContext = {
          date: this.article.created_at,
          availableActors: this.actorsForNewRelations,
        }
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, modalContext)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ARTICLE_RELATION_MODAL)
      },
      fetchAddedActorDetails () {
        if (!this.addedActors.length) {
          return
        }

        const fetchActorPromises = []
        for (const actor of this.addedActors) {
          fetchActorPromises.push(fetchActor(actor.id))
        }
        Promise.all(fetchActorPromises).then((results) => {
          let connectedResults = []
          for (const actor of results) {
            connectedResults = [...connectedResults, ...getConnectionsForActor(actor, this.ecosystemRelationships).map(relation => {
              return {
                name: relation.name,
                id: relation.id,
                to: relation.id,
              }
            })]
          }
          this.actorsForNewRelations = results
          this.detailedActors = [...results, ...connectedResults]
        })
      },
      viewArticle (article) {
        this.$router.push(`/files/${article.sql_media_id}/analysis`)
      },
      selectClassification (classificationOption) {
        this.localDeniedDictionaries = this.localDeniedDictionaries.filter(dictionaryId => dictionaryId !== classificationOption.value)
        this.localAcceptedDictionaries.push(classificationOption.value)
        this.updateClassification(classificationOption.value, 'attached')
        this.showMoreForClassification = ''
      },
      deselectClassification (classificationOption) {
        this.localAcceptedDictionaries = this.localAcceptedDictionaries.filter(dictionaryId => dictionaryId !== classificationOption.value)
        this.localDeniedDictionaries.push(classificationOption.value)
        this.updateClassification(classificationOption.value, 'removed')
      },
      updateClassification (dictionaryId, feedback) {
        const resourceSqlId = this.article.details.id

        if (!resourceSqlId) {
          console.log('No ID found for the article, cannot update classification')

          return
        }

        contentFeedback(resourceSqlId, {
          feedback: feedback,
          dictionary_id: dictionaryId,
        })
          .then(response => {
            this.article.resource['dictionary_similarity'] = [...this.classification]
              .map(dictionaryClassification => {
                if (dictionaryClassification.dictionary_id == dictionaryId) {
                  dictionaryClassification.feedback = feedback
                }

                return dictionaryClassification
              })
          })
      },
      onClickActorCard (actor) {
        // Not sure if this can happen on production, but some actors were 'added' but didn't have an id in some cases
        if (!actor.state || (actor.state === 'added' && !actor.id)) {
          this.addActorEcosystem(actor)
        } else if (actor.id) {
          this.$router.push('/actors/' + actor.id)
        }
      },
      addActorEcosystem (actor) {
        actor.state = 'adding'

        if (!actor.id) {
          const modalContext = {
            prefilled: {
              name: actor.name,
              actor_type: 'LegalEntity',
              company_type: 'Headquarter',
              category: 'Company',
              linkedin: actor.linkedin,
            },
          }

          // actor.state = 'added'

          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, modalContext)
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)
        }
      },
      addNewActor () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR_TO_ARTICLE_MODAL)
      },
      getRelatedActor (actorName, actorType) {
        if (actorType === 'ORG') {
          return this.article.data.actors.organisations.find(organisation => organisation.name.toLowerCase() === actorName.toLowerCase())
        }
        if (actorType === 'detail') {
          const result = this.detailedActors.find(organisation => organisation.name.toLowerCase() === actorName.toLowerCase())
          return { ...result, state: 'added' }
        }

        return this.article.data.actors.persons.find(person => person.name.toLowerCase() === person.name.toLowerCase())
      },
      getConnectionsUrlForActor (actorName, actorType, actorId) {
        if (actorId) {
          return `/actors/${actorId}/#connections`
        }
        const relatedActor = this.getRelatedActor(actorName, actorType)
        if (relatedActor && relatedActor.state === 'added' && relatedActor.id) {
          return `/actors/${relatedActor.id}/#connections`
        }
      },
      actorExistsOnEcosystem (actorName, actorType) {
        const relatedActor = this.getRelatedActor(actorName, actorType)
        return relatedActor && relatedActor.state === 'added'
      },
      getRelationStatus (relation) {
        if (this.relationsInProgress.includes(relation)) {
          return RELATION_STATUS.IN_PROGRESS
        }

        const relatedFirstActor = this.getRelatedActor(relation.entity_A_alias, relation.entity_A_type)
        const relatedSecondActor = this.getRelatedActor(relation.entity_B_alias, relation.entity_B_type)
        if (!relatedFirstActor || !relatedSecondActor) {
          return RELATION_STATUS.OPEN
        }

        const allMatchingRelations = relatedFirstActor[relation.mappedRelation.name] || []
        const allMatchingReverseRelations = relatedFirstActor[relation.mappedRelation.inverse_name] || []
        const relationExists = !![...allMatchingRelations, ...allMatchingReverseRelations].find(relation => {
          return relation.to === relatedSecondActor.id
        })
        // checking two ways is required because we don't fetch all detail pages of all relations of all found actors (as this would cause too much network traffic overhead)
        const allSecondMatchingRelations = relatedSecondActor[relation.mappedRelation.inverse_name] || []
        const allSecondMatchingReverseRelations = relatedSecondActor[relation.mappedRelation.name] || []
        const secondRelationExists = !![...allSecondMatchingRelations, ...allSecondMatchingReverseRelations].find(relation => {
          return relation.to === relatedFirstActor.id
        })
        return (relationExists || secondRelationExists) ? RELATION_STATUS.DONE : RELATION_STATUS.OPEN
      },
      onClickShowMoreForClassification (axis) {
        if (this.showMoreForClassification === axis) {
          this.showMoreForClassification = ''
        } else {
          this.showMoreForClassification = axis
        }
      },
      getAddMoreTextForAxis (axis) {
        switch (axis) {
          case 'market_trends':
            return this.$t('article_detail_add_pestel')
          case 'sdg':
            return this.$t('article_detail_add_SDG')
          case 'technology_radar':
            return this.$t('article_detail_add_technology')
          default:
            return ''
        }
      },
      additionalOptionsForAxis (axis) {
        return this.optionsForAxis(axis).filter(option => {
          return (option.denied || this.localDeniedDictionaries.includes(option.value)) || (!option.score && !option.selected && !this.localAcceptedDictionaries.includes(option.value))
        })
      },
      acceptedOptionsForAxis (axis) {
        if (this.isLoading) {
          return [{ isLoading: true, label: '', value: '' }, { isLoading: true, label: '', value: '' }, { isLoading: true, label: '', value: '' }]
        }

        return this.optionsForAxis(axis).filter(option => {
          return (option.score || option.selected || this.localAcceptedDictionaries.includes(option.value)) && !option.denied && !this.localDeniedDictionaries.includes(option.value)
        })
      },
      optionsForAxis (axis) {
        return this.mixinDefaultDictionaries.filter(d => d.related_report === axis).map(dictionary => {
          const dictionarySimilarity = this.getDictionarySimilarity(dictionary) || 0

          return {
            value: dictionary.id,
            label: this.labelForDefaultDictionary(dictionary),
            score: dictionarySimilarity.score,
            selected: dictionarySimilarity.feedback && dictionarySimilarity.feedback === 'attached',
            denied: dictionarySimilarity.feedback && dictionarySimilarity.feedback === 'removed',
          }
        }).sort((optionA, optionB) => {
          const optionAisSelected = optionA.selected || this.localAcceptedDictionaries.includes(optionA.value)
          const optionBisSelected = optionB.selected || this.localAcceptedDictionaries.includes(optionB.value)
          const optionAisDenied = optionA.denied || this.localDeniedDictionaries.includes(optionA.value)
          const optionBisDenied = optionB.denied || this.localDeniedDictionaries.includes(optionB.value)

          if (optionAisSelected === optionBisSelected && optionAisDenied === optionBisDenied) {
            return 0
          }
          if (optionBisDenied) {
            return -1
          }
          if (optionAisDenied) {
            return 1
          }
          return optionAisSelected ? -1 : 1
        })
      },
      getDictionarySimilarity (dictionary) {
        if (!this.article.details) {
          return
        }

        for (const dictionarySimilarity of this.article.details.dictionary_similarity || []) {
          if (dictionarySimilarity.dictionary_id === dictionary.id) {
            return dictionarySimilarity
          }
        }
      },
      labelForDefaultDictionary (dictionary) {
        if (dictionary.abbreviation && dictionary.abbreviation !== dictionary.title) {
          return `${dictionary.title}`
        }

        return dictionary.title
      },
      fetchSimilarContent () {
        getSimilarContent(this.$route.params.fileId).then((result) => {
          if (result.results) {
            this.similarContent = result.results
          }
        }).catch((error) => {
          console.log('error while searching similar articles: ', error)
        }).finally(() => {
          this.isLoadingSimilarContent = false
        })
      },
      linkActorToArticle (actorId) {
        const updatedFile = {
          title: this.article.details.title,
          linked_actor_id: (this.article.details.linked_actor_elastic_ids || []).map(id => {
            return {
              id,
            }
          }),
        }

        updatedFile.linked_actor_id.push({
          id: actorId,
        })

        updateEcosystemFileMetaData(this.article.resource.id, updatedFile).then(() => {
          this.$emit('article-data-changed')
        })
      },
    },
    components: { SkeletonCard, AnnouncementOrResourceCard, TagSelector },
    mounted () {
      this.$bus.on('article-relation-created', () => {
        this.fetchAddedActorDetails()
      })

      this.$bus.on('closeAddActorModal', () => {
        this.$emit('article-data-changed')
      })

      this.$bus.on('actor-added-to-article', (actorId) => {
        this.linkActorToArticle(actorId)
      })

      this.$bus.on('actorCreated', (createdActorData) => {
        this.linkActorToArticle(createdActorData.id)
      })

      this.fetchSimilarContent()
    },
    beforeUnmount () {
      this.$bus.off('article-relation-created')
      this.$bus.off('actorCreated')
      this.$bus.off('closeAddActorModal')
      this.$bus.off('actor-added-to-article')
    },
    watch: {
      addedActors () {
        this.fetchAddedActorDetails()
      },
      'article.resource.id': {
        handler () {
          this.isLoadingSimilarContent = true
          this.fetchSimilarContent()
        },
      },
    },
    mixins: [DictionaryMixin],
  }
</script>

<style scoped lang="scss">
  @import "../../../../scss/_variables.scss";

  .article-analysis {
    padding: 10px;

    h3.h3 {
      border-radius: $default-border-radius-narrow;
      color: $color-text-grey;
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      display: flex;
      padding: 6px 0;
      margin-bottom: 1rem;

      .h3-title {
        flex-grow: 1;
        display: flex;
        font-size: 15px;

        &:after {
          content: "";
          flex: 1;
          height: 1px;
          margin-left: 0.5rem;
          margin-top: 7px;
          background-color: $color-text-grey;
        }
      }
    }

    .classification-list {
      display: flex;
      flex-direction: column;
      font-size: 11px;
      margin-bottom: 20px;

      .classification-title {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        font-size: 12px;
        padding: 0 0 1rem 0;
        font-weight: 500;
      }

      .classification-title__skeleton {
        height: 14px;
        background: $color-background-lightest-grey;
        border-radius: $default-border-radius-narrow;
        width: 60%;
        margin-bottom: 10px;
      }
    }

    .analysis-card-list {
      margin-bottom: 0;

      .analysis-card {
        border: 1px solid $color-borders;
        border-radius: $default-border-radius-narrow;
        position: relative;
        cursor: pointer;
        margin-bottom: 1rem;
        word-break: break-word;
        height: calc(100% - 1rem);

        &.analysis-card__added {
          background: $color-background-lightest-grey;
          border-color: $color-background-lightest-grey;
          width: 100%;
        }

        .analysis-card__text {
          font-size: 12px;
          line-height: 16px;
          height: 4*16px;
          max-height: unset;
          display: -webkit-box;
          width: 100%;
          -webkit-line-clamp: 4;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          padding-bottom: 0;
          margin-bottom: 20px;
        }

        .analysis-card-eye {
          position: absolute;
          top: 10px;
          right: 10px;
        }

        .analysis-card-overlay {
          display: none;
          font-size: 14px;
          position: absolute;
          left: 0;
          top: 0;
          right: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
          background: $color-background-lightest-grey;
          justify-content: center;
          align-items: center;
          border-radius: $default-border-radius-narrow;
        }

        &:hover {
          .analysis-card-overlay {
            display: flex;
          }
        }

        .content__meta {
          padding: 0 20px;
          height: 23px;
          margin-bottom: 20px;
          overflow: hidden;

          .tag {
            background-color: var(--primary-community-lightest);
            border-color: var(--primary-community-lightest);
            border-radius: 0.5rem;
            border-bottom: 20px;
          }
        }

        .analysis-card-icon {
          position: absolute;
          top: 5px;
          right: 5px;
        }

        .extracted-actor {
          text-decoration: none;
          color: black;

          &.clickable {
            text-decoration: underline;
          }
        }

        h4.h4 {
          font-size: 14px;
          text-transform: uppercase;
          padding: 20px 20px 10px 20px;

          &.one-line {
            white-space: nowrap;
            text-overflow: ellipsis;
            height: 47px;
            line-height: 17px;
            overflow: hidden;
          }
        }

        p {
          font-size: 11px;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 4;
          line-height: 14px;
          max-height: 56px;
          padding: 0 20px 20px 20px;
          overflow: hidden;
        }
      }
    }

    .show-more-text {
      text-decoration: underline;
      display: inline-block;
      cursor: pointer;
      font-size: 13px;
      margin-bottom: 1rem;
    }

    .empty-text {
      padding: 0 0 20px 0;
      font-size: 13px;
    }

    .title-cta {
      cursor: pointer;
      font-size: 11px;
      line-height: 15px;
      margin-left: 5px;
    }

    .extracted-relation-icon {
      position: absolute;
      top: 2px;
      right: 6px;
      color: black;
      font-weight: 500;
      font-size: 23px;
      display: flex;
      align-items: center;

      &.clickable {
        cursor: pointer;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
</style>
