export default {
  methods: {
    carouselSettings (itemsToShow = 2) {
      return {
        itemsToShow: itemsToShow,
        itemsToScroll: 1,
        snapAlign: 'start',
        mouseDrag: true,
        wrapAround: false,
        transition: 600,
        modelValue: 0,
      }
    },
    carouselBreakpoints (itemsToShow = 2) {
      return {
        // Always show 1 item on small devices
        0: {
          itemsToShow: 1,
          snapAlign: 'start',
        },
        // 1024 and up
        1024: {
          itemsToShow: itemsToShow,
          snapAlign: 'start',
        },
      }
    },
  },
}
