<template>
  <modal
    :id="modalId"
    ref="modal"
    :title="'Access restricted'"
    @close="close"
    is-simplified-modal
  >
    <template v-slot:body>
      <div class="content-container" v-if="manager">
        <community-detail-manager-card :manager="manager" @click-contact-button="close">
          <p>{{ $t('community_access_restricted_message', { type: capitalize(modalContext.type) }) }}</p>
        </community-detail-manager-card>
      </div>
    </template>
  </modal>
</template>


<script>
  import MODAL_IDS from '../../constants/modal-ids.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import Modal from './Modal.vue'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import CommunityDetailManagerCard from '../Communities/CommunityDetailManagerCard.vue'
  import { ACTION_TYPES as COMMUNITY_ACTION_TYPES } from '../../store/modules/communities.js'
  import { capitalize } from '../../util/string.ts'

  export default {
    data () {
      return {
        modalId: MODAL_IDS.NO_ACCESS_TO_COMMUNITY_MODAL,
        message: '',
      }
    },
    computed: {
      modalContext () {
        return this.$store.state.ui.modalContext
      },
      community () {
        return this.modalContext.community
      },
      config () {
        return this.$store.state.config
      },
      manager () {
        if (this.community && this.community.manager && this.community.manager.name) {
          return this.community.manager
        }
      },
    },
    methods: {
      capitalize,
      close () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, this.modalId)
        this.$emit('close')
      },
    },
    mounted () {
    },
    components: {
      Modal,
      CommunityDetailManagerCard,
    },
    mixins: [TranslationsMixin],
  }
</script>


<style lang="scss" scoped>
  @import "../../../scss/variables.scss";

  .content-container {
    box-shadow: none;
    border-radius: 0px;
    margin: 0;

    :deep(.community-detail__manager) {
      padding-top: 20px;

      .community-detail__manager-container {
        height: unset;
        padding: 10px 30px 0 10px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @media (max-width: $screen-md-max) {
          padding: 0px;
        }
      }

      .community-detail__manager-photo-container {
        @media (max-width: $screen-md-max) {
          display: none;
        }
      }
    }

    :deep(.communities-title) {
      display: none;
    }

  }


</style>
