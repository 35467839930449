<template>
  <div>
    <SearchBarWithSuggestions
      :placeholder="isEvent ? $t('events_search_cta', {events: eventsLabel.toLowerCase()}) : $t('announcements_search_cta', {announcements: announcementsLabel.toLowerCase()})"
      @onSubmitQuery="searchAnnouncements"
      @onChangeQuery="handleInput"
    />
  </div>
</template>

<script>
  import FiltersMixin from '../../util/FiltersMixin.js'
  import AutocompleteDropdown from '../../components/Dropdown/AutocompleteDropdown.vue'
  import { annotateText } from '../../api/exploration'
  import _uniqBy from 'lodash/uniqBy.js'
  import { fetchMostUsedTags } from '../../api/notifications'
  import DsInput from '../Form/DsInput.vue'
  import SearchBarWithSuggestions from '../SearchBarWithSuggestions/SearchBarWithSuggestions.vue'

  import { MUTATION_TYPES } from '../../store/modules/notifications.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    data: () => ({
      suggestions: [],
      query: '',
    }),
    props: {
      modelValue: {
        type: Array,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: '',
      },
      suggestionSource: {
        type: String,
        default: '',
      },
      allowFreeTextTag: {
        type: Boolean,
        default: false,
      },
      maxTags: {
        type: Number,
      },
      isEvent: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      internalValue: {
        get () {
          return this.modelValue
        },
        set (value) {
          if (!this.maxTags || value.length <= this.maxTags) {
            this.$emit('update:modelValue', value)
          }
        },
      },
      valueValues () {
        return this.modelValue.map(x => x.value)
      },
      displayedSuggestions () {
        return this.suggestions.filter(suggestion => !this.valueValues.includes(suggestion.value))
      },
    },
    methods: {
      handleInput (value) {
        this.$emit('update:modelValue', value)
      },
      searchAnnouncements (query) {
        this.$store.commit(MUTATION_TYPES.UPDATE_QUERY_FILTER, query)

        this.$emit('search')
      },
      removeDuplicates (suggestions) {
        return _uniqBy(suggestions, 'value')
      },
      autocompleteSearchTerm (query) {
        return fetchMostUsedTags({
          query: query,
          everything: true,
          allow_free_text: this.allowFreeTextTag,
        })
          .then(suggestions => {
            const options = []
            suggestions = this.removeDuplicates(suggestions)

            suggestions.forEach(function (concept) {
              if (!concept.type) {
                options.push({
                  label: concept.label + ' (user-generated)',
                  value: concept.value,
                })
              } else {
                options.push({
                  label: concept.label,
                  value: concept.value,
                })
              }
            })

            return options
          })
          .catch(err => {
            console.log(err)
          })
      },
      fetchSuggestions () {
        this.$emit('fetching-suggestions', true)
        annotateText(this.suggestionSource).then(response => {
          this.suggestions = response.concepts || []
          this.$emit('fetching-suggestions', false)
        })
      },
      declineSuggestion ({ value }) {
        this.suggestions = this.suggestions.filter(suggestion => suggestion.value !== value)
      },
    },
    watch: {
      suggestionSource () {
        this.fetchSuggestions()
      },
    },
    mounted () {
      this.query = this.$store.state.notifications.listData.query
    },
    beforeUnmount () {
      this.$store.commit(MUTATION_TYPES.UPDATE_QUERY_FILTER, null)
    },
    mixins: [FiltersMixin],
    components: {
      SearchBarWithSuggestions,
      AutocompleteDropdown,
      DsInput,
    },
  })
</script>


<style lang="scss">
  @import "../../../scss/variables.scss";

  .announcement__search-bar {
    width: 100%;
  }

  .announcement__search-bar > input {
    border-radius: $default-border-radius;
  }
</style>
