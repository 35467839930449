<template>
  <div class="autocomplete-tags"
    :class="{'is-simplified': isSimplified, 'autocomplete-open': tag.length > minInputLength}">
    <tag-input
      v-model:input="tag"
      v-model="forwardedTags"
      :suggestions="suggestions"
      :placeholder="placeholder"
      :validation="tagValidation"
      :autocompleteItems="autocompleteOptions"
      :addOnlyFromAutocomplete="denyCustomInput"
      :nofocus="nofocus"
      :openUpwards="openUpwards"
      :maxTags="maxTags"
      :disabled="disabled"
      :allowAddingToDictionary="allowAddingToDictionary"
      @suggestion:accept="acceptSuggestion"
      @suggestion:decline="declineSuggestion"
      @input:raw="rawInput"
      :acceptSuggestionLabel="acceptSuggestionLabel"
      :denySuggestionLabel="denySuggestionLabel"
      :icon="icon"
      :for-sources="forSources"
    />
  </div>
</template>

<script lang="ts">
  import TagInput from '../Form/TagInput.vue'
  import { defineComponent } from 'vue'
  export default defineComponent({
    components: {
      TagInput,
    },
    props: {
      showErrorOnEmptyResults: {
        type: Boolean,
        default: false,
      },
      icon: {
        type: String,
        default: null,
      },
      tags: {
        type: Array,
        default: () => [],
      },
      suggestions: {
        type: Array,
        default: () => [],
      },
      placeholder: {
        type: String,
        default: 'Add some tags',
      },
      options: {
        type: Array,
        default: () => [],
      },
      nofocus: {
        type: Boolean,
        default: false,
      },
      forSources: {
        type: Boolean,
        default: false,
      },
      openUpwards: {
        type: Boolean,
        default: false,
      },
      addOnlyFromAutocomplete: {
        type: Boolean,
        default: false,
      },
      minInputLength: {
        type: Number,
        default: 2,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      maxTags: {
        type: Number,
        default: 100,
      },
      allowAddingToDictionary: {
        type: Boolean,
        default: false,
      },
      acceptSuggestionLabel: {
        type: String,
        default: 'Accept suggestion',
      },
      denySuggestionLabel: {
        type: String,
        default: 'Deny suggestion',
      },
      isSimplified: {
        type: Boolean,
        default: false,
      },
      validation: {
        type: Array,
        default: () => [],
      },
    },
    data () {
      return {
        tag: '',
        baseValidation: [{
          classes: 'min-length',
          rule: text => text.length <= this.minInputLength,
        }, {
          classes: 'avoid-item',
          rule: item => {
            if (item && item.text && item.optionValue === null && item.text.startsWith('No results found for ') && this.showErrorOnEmptyResults) {
              return true
            }
          },
          disableAdd: true,
        }],
      }
    },
    computed: {
      tagValidation () {
        if (!this.validation || !this.validation.length) {
          return this.baseValidation
        }

        return this.validation
      },
      denyCustomInput () {
        return this.addOnlyFromAutocomplete || !this.enableFreeTextTags
      },
      enableFreeTextTags () {
        // true by default -> returns true if undefined
        return this.$store.state.config.enableFreeTextTags !== false
      },
      forwardedTags: {
        get () {
          return this.tags
        },
        set (tags) {
          this.tag = ''
          this.$emit('tagsChanged', tags)
        },
      },
      autocompleteOptions () {
        if (!this.tag || this.tag.length <= this.minInputLength) {
          return []
        }

        if (!this.options || this.options.length === 0) {
          if (this.showErrorOnEmptyResults) {
            return [{ text: 'No results found for ' + this.tag, optionValue: null }]
          }
          return []
        }

        // We assume the array to be a list of strings, convert it to the expected format for the vue-tags-input
        var options = this.options.filter(option => {
          if (option.text) {
            return option.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1
          }

          if (option.label) { // Bit of a sticky solution, semantic tags are built up out of a label, uri, type, previously we named everything "text", support both for now, but this should be refactored to label
            return option.label.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1
          }

          return option.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1
        }).map(option => ({
          ...option,
          text: option.text || option.label || option,
          optionValue: option,
        }))

        return options
      },
    },
    methods: {
      acceptSuggestion (suggestion) {
        this.$emit('suggestion:accept', suggestion)
      },
      declineSuggestion (suggestion) {
        this.$emit('suggestion:decline', suggestion)
      },
      rawInput (rawInput) {
        this.$emit('input:raw', rawInput)
      },
    },
    watch: {
      tag (val) {
        this.$emit('tagChanged', val)
      },
    },
  })
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

  .autocomplete-tags .vue-tags-input .ti-autocomplete {
    max-height: 28px * 6;
    overflow-y: auto;
  }

  .autocomplete-tags.is-simplified {
    .ti-focus {
      border-radius: $simplified-input-border-radius;
    }

    &.autocomplete-open {
      .ti-focus {
        border-radius: $simplified-input-border-radius $simplified-input-border-radius 0 0;
      }
    }

    .ti-input {
      border-color: $simplified-input-border-color;
      border-radius: $simplified-input-border-radius;

      input::placeholder {
        color: var(--primary-community) !important;
      }
    }

    .ti-autocomplete {
      box-shadow: 0 0 0 2px var(--primary-community);
    }

    .datascouts-tag-input .vue-tags-input .ti-autocomplete .ti-item.ti-selected-item {
      background-color: var(--primary-community);
    }

    &.autocomplete-open {
      .ti-input {
        border-radius: $simplified-input-border-radius $simplified-input-border-radius 0 0;
      }
    }
  }
</style>
