<template>
  <div class="global-search__overlay" v-if="isVisible && !isMobile" @click="hide"/>
  <div
      :class="{ 'global-search--visible': isVisible, 'global-search': !isVisible }"
      ref="rootElement"
  >
    <ds-input
        :icon="isLoading ? 'spinner' : 'search'"
        type="search"
        ref="searchInput"
        class="input-field"
        :style="{'z-index': isVisible ? 901 : 1}"
        :class="{'global-search--visible': isVisible, 'global-search-without-overlay': !isVisible, 'global-search-without-dropdown': !recentlyViewedData.length && !query && isVisible}"
        :placeholder="isVisible ? 'Type to search, click outside to close' : placeholder"
        :modelValue="query"
        @focus="displayGlobalSearch(true)"
        @update:modelValue="search"
    />
    <div
        class="global-search__input" v-if="isVisible" :style="borderStyleDropdown"
    >
      <div class="global-search__nav">
        <GlobalSearchNavigation v-if="query || !query && isMobile" v-model="resultType" @close="hide"/>
        <div class="global-search__dropdown"
             :style="query ? 'border-radius: 0 0 12px 0' : 'border-radius: 0 0 12px 12px'">
          <div class="global-search__title" v-if="query && isMobile && globalSearchDataToShow.length && !isLoading">
            <p>
              <template v-if="query && resultType === GLOBAL_SEARCH_RESULT_TYPES.ALL">{{
                  $t('global_search_all')
                }}
              </template>
              {{ findType.charAt(0).toUpperCase() + findType.slice(1) }}
            </p>
          </div>
          <div class="global-search__results" style="color: black;">
            <!-- Display the exact matches -->
            <template
                v-if="!isLoading && query && allSearchData && allSearchData.directMatches && allSearchData.directMatches.length > 0">
              <div>
                <div class="global-search__title">
                  <p>{{ $t('global_search_matching_results') }}:</p>
                </div>
                <div
                    v-for="(result, index) in allSearchData.directMatches" :key="'result' + index"
                    class="global-search__recently_viewed_item"
                    :style="setSpaceForLoadingState"
                >
                  <template v-if="result.isLoading">
                    <div class="global-search__skeleton__icon"></div>
                    <div class="global-search__skeleton__info">
                      <div class="global-search__skeleton__sub-info"></div>
                    </div>
                  </template>
                  <template v-else>
                    <ResultsCard :result="result" @view-result="onViewResult"/>
                  </template>
                </div>
              </div>
            </template>

            <p v-if="!query && !isMobile" style="font-weight: normal;">{{ $t('global_search_recently_viewed') }}:</p>

            <div class="global-search__title">
              <p v-if="query && !isLoading && globalSearchDataToShow && globalSearchDataToShow.length > 0">{{
                  $t('global_search_search_results_mentioning', { query: query })
                }}:</p>
            </div>
            <div
                v-for="(result, index) in globalSearchDataToShow" :key="'result' + index"
                class="global-search__recently_viewed_item"
                :style="setSpaceForLoadingState"
            >
              <template v-if="result.isLoading">
                <div class="global-search__skeleton__icon"></div>
                <div class="global-search__skeleton__info">
                  <div class="global-search__skeleton__sub-info"></div>
                </div>
              </template>
              <template v-else>
                <ResultsCard :result="result" @view-result="onViewResult"/>
              </template>
            </div>
            <template
                v-if="query && (!allSearchData.directMatches || !allSearchData.directMatches.length) && !globalSearchDataToShow.length">
              <EmptyStateGlobalSearch :result-type="resultType" :text="`No ${findType} found for '${query}'`"/>
            </template>
            <template v-else-if="query">
              <EmptyStateGlobalSearch :result-type="resultType"/>
            </template>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script lang="ts">
import UiMixin from '../../../util/UiMixin.js'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui.js'
import { debounce, trim } from 'lodash'
import { getRecentlyViewedResults, getSearchResultsForQuery } from '../../../api/general-search.js'
import ResultsCard from './ResultsCard.vue'
import GlobalSearchNavigation from './GlobalSearchNavigation.vue'
import { GLOBAL_SEARCH_RESULT_TYPES } from '../../../util/GlobalSearchResultTypes.js'
import EmptyStateGlobalSearch from './EmptyStateGlobalSearch.vue'
import translationsMixin from '../../../util/TranslationsMixin.js'
import { trackHeapEvent } from '../../../util/analytics.js'

export default {
  name: 'GlobalSearch.vue',
  props: {
    placeholder: {
      type: String,
    },
    disabled: Boolean,
  },
  data() {
    return {
      resultType: GLOBAL_SEARCH_RESULT_TYPES.ALL,
      resultActorType: 'LegalEntity' || 'Product',
      isLoading: false,
      recentlyViewedData: [],
      allSearchData: {
        directMatches: [],
        actors: [],
        products: [],
        challenges: [],
        announcements: [],
        articles: [],
        resources: [],
        events: [],
      },
      allSearchResults: [],
      fuzzyResults: [],
      GLOBAL_SEARCH_RESULT_TYPES,
    }
  },
  computed: {
    setSpaceForLoadingState() {
      if (this.isLoading && this.isMobile) {
        return 'margin: 30px 15px 20px 15px'
      } else if (this.isLoading && !this.isMobile) {
        return 'margin: 15px 15px 20px 15px'
      }
    },
    dontShowNoExactResultsForResources() {
      return this.isMobile && this.resultType === GLOBAL_SEARCH_RESULT_TYPES.RESOURCE
    },
    dontShowNoExactResultsForChallenges() {
      return this.isMobile && this.resultType === GLOBAL_SEARCH_RESULT_TYPES.CHALLENGE
    },
    isMobile() {
      return this.ui.isMobile
    },
    displayAnnouncements() {
      return this.$store.getters.canUsePublicAnnouncements && this.$store.getters.areMessageBoardsEnabled
    },
    findType() {
      switch (this.resultType) {
        case GLOBAL_SEARCH_RESULT_TYPES.ALL:
          return this.$t('global_search_results')
        case GLOBAL_SEARCH_RESULT_TYPES.PRODUCT:
          return this.productsLabel
        case GLOBAL_SEARCH_RESULT_TYPES.ACTOR:
          return this.$t('global_search_actors')
        case GLOBAL_SEARCH_RESULT_TYPES.ANNOUNCEMENT:
          return this.announcementsLabel
        case GLOBAL_SEARCH_RESULT_TYPES.EVENT:
          return this.eventsLabel
        case GLOBAL_SEARCH_RESULT_TYPES.CHALLENGE:
          return this.challengesLabel
        case GLOBAL_SEARCH_RESULT_TYPES.RESOURCE:
          return this.$t('global_search_resource')
        case GLOBAL_SEARCH_RESULT_TYPES.ARTICLE:
          return this.$t('global_search_articles')
        default:
          return ''
      }
    },
    query() {
      return this.$store.state.ui.globalSearch.query
    },
    globalSearchDataToShow() {
      if (this.isLoading) {
        return [{ isLoading: true }, { isLoading: true }, { isLoading: true }, { isLoading: true }, { isLoading: true }, { isLoading: true }, { isLoading: true }, { isLoading: true }, { isLoading: true }, { isLoading: true }, { isLoading: true }, { isLoading: true }, { isLoading: true }, { isLoading: true }]
      }

      if (this.query) {
        return this.matchingDataResults
      }

      return this.recentlyViewedData
    },
    matchingDataResults() {
      switch (this.resultType) {
        case GLOBAL_SEARCH_RESULT_TYPES.ALL:
          // Make sure to sort by type (actors/products, then announcements/events, then articles/resources, etc)
          return this.sortByType(this.allSearchData)
        case GLOBAL_SEARCH_RESULT_TYPES.ACTOR:
          return this.sortByName(this.allSearchData.actors)
        case GLOBAL_SEARCH_RESULT_TYPES.PRODUCT:
          return this.sortByName(this.allSearchData.products)
        case GLOBAL_SEARCH_RESULT_TYPES.EVENT:
          return this.sortByName(this.allSearchData.events)
        case GLOBAL_SEARCH_RESULT_TYPES.ARTICLE:
          return this.sortByName(this.allSearchData.articles)
        case GLOBAL_SEARCH_RESULT_TYPES.RESOURCE:
          return this.sortByName(this.allSearchData.resources)
        case GLOBAL_SEARCH_RESULT_TYPES.CHALLENGE:
          return this.sortByName(this.allSearchData.challenges)
        case GLOBAL_SEARCH_RESULT_TYPES.ANNOUNCEMENT:
          return this.sortByName(this.allSearchData.announcements)
      }
    },
    isVisible() {
      return this.$store.state.ui && this.$store.state.ui.globalSearch && this.$store.state.ui.globalSearch.isVisible
    },
    borderStyleDropdown() {
      if (!this.recentlyViewedData.length && !this.query) {
        return 'display: none; border-radius: 12px'
      } else if (this.query) {
        return 'display: flex; border-radius: 0 0 12px 12px'
      }
    },
    shouldSeeChallenges() {
      if (this.$store.getters.isMember) {
        return true
      }

      return this.$store.getters.canCreateChallenge ||
          this.$store.getters.viewableChallengeStatusses.length > 0 ||
          this.$store.getters.accessibleChallengeStatusses.length > 0
    },
    displayChallenges() {
      return this.$store.state.config.challengesAreEnabled && this.shouldSeeChallenges
    },
    hasAccessToKnowledgeBase() {
      return this.$store.getters.hasAccessToKnowledgeBase && this.config.viewDashboard && this.config.viewDashboard.includes('knowledge-base')
    },
    displayExploreContent() {
      const hasAccess = (this.$store.getters.hasAccessToExploration || this.$store.getters.hasAccessToMonitoring)

      return this.$store.getters.isLoggedIn && hasAccess && this.config.viewDashboard && this.config.viewDashboard.includes('explore-content')
    },
    displayArticlesInKnowledgeBase() {
      return this.config.viewDashboard && this.config.viewDashboard.includes('knowledge-base') && this.$store.getters.hasAccessToAllKnowledgeBaseContent
    },
    config() {
      return this.$store.state.config
    },
    viewActorTypes() {
      return this.$store.getters.viewActorTypes
    },
    displayProducts() {
      return this.viewActorTypes.includes('Product')
    },
  },
  methods: {
    onViewResult() {
      trackHeapEvent('globalSearch.clickResult')

      this.hide()
    },
    handleToggleMobileNavigationDrawerVisibility() {
      this.$emit('toggleMobileNavigationDrawerVisibility')
    },
    findSortType(resultType) {
      switch (resultType) {
        case GLOBAL_SEARCH_RESULT_TYPES.ACTOR:
          return 9
        case GLOBAL_SEARCH_RESULT_TYPES.PRODUCT:
          return 8
        case GLOBAL_SEARCH_RESULT_TYPES.ANNOUNCEMENT:
          return 7
        case GLOBAL_SEARCH_RESULT_TYPES.EVENT:
          return 6
        case GLOBAL_SEARCH_RESULT_TYPES.CHALLENGE:
          return 5
        case GLOBAL_SEARCH_RESULT_TYPES.ARTICLE:
          return 4
        case GLOBAL_SEARCH_RESULT_TYPES.RESOURCE:
          return 3
      }

      return 0
    },
    sortByType(searchResults) {
      // remove whitespaces characters before and end of a string
      const query = trim(this.query.toLowerCase())

      let allSearchResults = [...searchResults.actors, ...searchResults.challenges, ...searchResults.products, ...searchResults.announcements, ...searchResults.articles, ...searchResults.resources, ...searchResults.events]

      // Keep track of any direct match results (if name from actor is equal with query)
      const directMatch = allSearchResults.filter((a) => {
        const title = a.result.title ?? a.result.name

        return title.toLowerCase() === query.toLowerCase() && a.type === 'actor'
      })
      this.allSearchData.directMatches = directMatch

      // Filter out the direct match results from the allSearchResults
      allSearchResults = allSearchResults.filter((a) => !directMatch.includes(a))

      return allSearchResults
          .sort((a, b) => {
            if (this.findSortType(a.type) > this.findSortType(b.type)) return -1
            if (this.findSortType(a.type) < this.findSortType(b.type)) return 1

            return 0
          })
    },
    sortByName(searchResults) {
      // remove whitespaces characters before and end of a string
      const query = trim(this.query.toLowerCase())

      // Keep track of any direct match results (if name from actor is equal with query)
      const directMatch = searchResults.filter((a) => {
        const title = a.result.title ?? a.result.name

        title.toLowerCase() === query.toLowerCase() && a.type === 'actor'
      })
      this.allSearchData.directMatches = directMatch

      // Filter out the direct match results from the allSearchResults
      searchResults = searchResults.filter((a) => !directMatch.includes(a))

      return searchResults.filter((element) => {
        return element !== undefined
      })
    },
    setQuery(query) {
      this.$store.commit(UI_MUTATION_TYPES.UPDATE_GLOBAL_SEARCH_QUERY, query)
    },
    searchDebounced: debounce(function () {
      trackHeapEvent('globalSearch.performQuery', { query: this.query })
      this.getAllSearchData(this.query)
    }, 400),
    search(query) {
      if (query) {
        this.displayGlobalSearch()
      }
      this.$store.commit(UI_MUTATION_TYPES.UPDATE_GLOBAL_SEARCH_QUERY, query)
      this.isLoading = !!this.query
      // search api query with this.query
      this.searchDebounced()
    },
    displayGlobalSearch(trackEvent) {
      if (trackEvent) {
        trackHeapEvent('topbar.clickGlobalSearch')
      }
      this.$store.commit(UI_MUTATION_TYPES.SHOW_GLOBAL_SEARCH)
    },
    getRecentlyViewedResults() {
      return getRecentlyViewedResults()
          .then(response => {
            this.recentlyViewedData = response
            this.isLoading = false
          })
          .catch(error => {
            console.log(error)
          })
    },
    getAllSearchData(query) {
      if (!query) {
        return
      }

      return getSearchResultsForQuery(query)
          .then(response => {
            if (response && query === this.query && this.query) {
              if (!this.displayAnnouncements) {
                response.announcements = []
                response.announcementsFuzzy = []
              }
              if (!this.displayChallenges) {
                response.challenges = []
                response.challenges_fuzzy = []
              }
              if (!this.hasAccessToKnowledgeBase) {
                response.resources = []
                response.resources_fuzzy = []
              }
              if (!this.displayExploreContent && !this.displayArticlesInKnowledgeBase) {
                response.articles = []
                response.articles_fuzzy = []
              }
              if (!this.displayProducts) {
                response.products = []
                response.products_fuzzy = []
              }

              this.isLoading = false

              this.allSearchData = response
            }
          })
          .catch(e => {
            console.log(e)

            this.isLoading = false
          })
    },
    hide() {
      this.getRecentlyViewedResults()
      this.$store.commit(UI_MUTATION_TYPES.HIDE_GLOBAL_SEARCH)
    },
    applySearchLoading(searching, didSearch) {
      this.didSearch = didSearch
      this.isSearching = searching
    },
  },
  beforeUnmount() {
    this.$bus.off('showGlobalSearch')
  },
  mounted() {
    window.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' && this.isVisible) {
        this.hide()
      }
    })

    this.$bus.on('showGlobalSearch', (value) => {
      this.search(value.query)
    })

    if (this.$store.getters.isLoggedIn) {
      // This component is loaded when the app starts, fetching the recently viewed is not a high priority for the initial load of the app
      setTimeout(() => {
        this.getRecentlyViewedResults()
      }, 10000)
    }
  },
  mixins: [UiMixin, translationsMixin],
  components: {
    EmptyStateGlobalSearch,
    ResultsCard,
    GlobalSearchNavigation,
  },
}
</script>


<style lang="scss" scoped>
@import "../../../../scss/variables.scss";

.global-search__overlay {
  opacity: 1;
  pointer-events: all;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $model-z-index-global-search;
  background: rgba(#000, 0.4);
  transition: all .2s;
  width: 100%;
}


@media (min-width: $screen-md) {
  .global-search--visible {
    position: relative;
  }
}

p {
  margin: 15px 28px;
  font-size: 14px;
}

hr {
  margin: 0 15px 20px 15px;
}

.global-search__dropdown {
  background-color: #fff;
  margin: 0 auto;
  width: 100%;
  padding: 0;
  border-radius: 0px 0px 12px 0px;
  overflow: hidden;
  overflow-y: auto;

  @media screen and (max-height: $global-search-height + 70px) {
    max-height: calc(100vh - 70px);
  }

  @media screen and (min-width: $screen-md) {
    max-height: $global-search-height;
  }

  @media (max-width: $screen-md-max) {
    z-index: 1;
    padding-bottom: 40px;
  }
}


.global-search__input {
  width: 100%;
  z-index: 901;
  display: flex;
  position: absolute;
  top: 36px;
  left: 0;
  right: 0;

  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-self: center;

  .global-search__nav {
    display: flex;
    @media (max-width: $screen-md-max) {
      flex-direction: column;
      height: calc(100vh - #{$top-bar-height});
    }
  }

  .global-search__skeleton__icon {
    background-color: $color-background-light-grey;
    width: 28px;
    height: 28px;
    border-radius: 4px;
  }

  .global-search__skeleton__info {
    width: 50%;
    background-color: $color-background-light-grey;
    height: 10px;
    margin: 16px 0 0 20px;
    border-radius: 2px;

    .global-search__skeleton__sub-info {
      width: 50%;
      height: 14px;
      margin-top: -16px;
      background-color: $color-background-light-grey;
    }
  }
}

// for recently viewed data
.global-search__recently_viewed_item {
  margin: 0;
  display: flex;

  p {
    margin-left: 18px;
  }

  p:first-letter {
    text-transform: capitalize;
  }

}

// cross button inputfield mobile


// mobile version

.global-search__title {
  p {
    font-size: 13px;
    margin: 0;
    display: flex;
    color: black;
    padding: 15px 24px 0 24px;
    // font-weight: 500;
  }
}

@media (max-width: $screen-md-max) {
  .global-search__input {
    width: 100%;
    z-index: 901;
    position: absolute;
    top: 75px;
    left: 0;
    right: 0;

    .global-search__dropdown {
      height: calc(100vh - #{$top-bar-height});
      overflow-x: hidden;
      overflow-y: auto;
    }
  }
}


</style>
