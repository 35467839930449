<template>
  <span class="simple-multi-select__select-option" :class="{selected}">
    <div class="content">
      <span>{{ label }}</span>
    </div>
    <div class="actions" v-if="!readOnly">
      <div class="action" :class="{disabled: selected}" aria-hidden="true" tabindex="1" @mousedown.prevent="select">
        <icon name="check"/>
      </div>
      <div class="action" :class="{disabled: !selected}" aria-hidden="true" tabindex="2" @mousedown.prevent="deselect">
        <icon name="remove"/>
      </div>
    </div>
  </span>
</template>

<script>
  export default {
    props: {
      modelValue: undefined,
      label: String,
      selected: Boolean,
      readOnly: {
        type: Boolean,
        default: false,
      }
    },
    emits: ['select', 'deselect'],
    methods: {
      select () {
        if (this.selected) {
          return
        }

        this.$emit('select', this.modelValue)
      },
      deselect () {
        if (!this.selected) {
          return
        }

        this.$emit('deselect', this.modelValue)
      },
    },
  }
</script>

<style lang="scss">
  @import "../../../scss/variables";

  .simple-multi-select__select-option {
    vertical-align: top;
    background-color: white;
    border: 1px solid #eee;
    font-weight: 200;
    font-size: 12px;
    color: inherit;
    border-radius: 0;
    height: 26px;
    padding: 3px 5px;
    margin: 2px;
    display: flex;
    min-width: 0;

    &.selected {
      background-color: #DED2E0;
      border-color: #C9A8E7;
    }

    .content {
      display: flex;
      align-items: center;
      min-width: 0;

      span {
        min-width: 0;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .actions {
      display: flex;
      align-items: center;

      .action {
        cursor: pointer;

        .svg-icon--remove {
          margin-top: 1px;
        }

        .svg-icon--check {
          width: 17px;
          height: 17px;
          margin-left: 5px;
          margin-top: 1px;
        }

        .svg-icon--remove g {
          stroke: $data-color-3;
        }

        .svg-icon--check path, .fill-fg {
          fill: $color-growth-positive;
        }

        &:hover:not(.disabled) {
          .svg-icon--remove g {
            stroke: #000;
          }

          .svg-icon--check path, .fill-fg {
            fill: #000;
          }
        }

        &:focus {
          outline: none;
        }

        &.disabled {
          opacity: 0.25;
          cursor: default;
        }
      }
    }
  }
</style>
