<template>
  <div>
    <h2 class="h2">Filters</h2>
    <form-group label="Default legend property">
      <dropdown v-model="form.defaultLegendProperty" :options="legendPropertyOptions" />
    </form-group>

    <form-group label="Product legend property" v-if="form.viewActorTypes && form.viewActorTypes.includes('Product')">
      <dropdown v-model="form.productLegendProperty" :options="legendPropertyOptions" />
    </form-group>

    <h3>Filter controls</h3>
    <form-group label="For Actors">
      <dropdown v-model="form.filterControls" :options="filterControlOptions" multiple :maxHeight="200" />
    </form-group>
    <form-group label="For Products" v-if="form.viewActorTypes && form.viewActorTypes.includes('Product')">
      <dropdown v-model="form.filterControlsProducts" :options="filterControlOptionsProduct" multiple :maxHeight="200" />
    </form-group>
  </div>
</template>

<script>
  import Dropdown from '../../Dropdown/Dropdown.vue'
  import ConfigMixin from '../../../util/ConfigMixin.js'

  export default {
    name: 'Filters',
    props: ['form'],
    computed: {
      legendPropertyOptions () {
        return [
          'category',
          'stage',
          'membership',
          'activities',
          'domains',
          'industries',
          'product_features_a',
          'product_features_b',
          'product_features_c',
        ]
      },
      filterControlOptionsProduct () {
        let options = this.translatedFilterControlOptions

        if (!this.$store.getters.hasScores) {
          options = options.filter(f => f.value !== 'score')
        }

        if (!this.$store.getters.hasCustomScores) {
          options = options.filter(f => f.value !== 'custom_score')
        }

        /* if (!this.$store.getters.isCompetitive) {
          options = options.filter(f => f.value !== 'country-focus');
        } */

        if (!this.$store.getters.knowledgeSharing) {
          options = options.filter(f => f.value !== 'reports')
        }

        // Obsolete feature flag
        /* if (! this.isDuplicateFeatureFlagEnabled) {
          options = options.filter(f => f.value !== 'possible_duplicate');
        } */

        if (!this.$store.getters.readinessTaxonomyEnabled) {
          options = options.filter(f => f.value !== 'readiness_levels')
        }

        if (!this.$store.getters.aspectTaxonomyEnabled) {
          options = options.filter(f => f.value !== 'aspects')
        }

        if (!this.$store.getters.businessAspectTaxonomyEnabled) {
          options = options.filter(f => f.value !== 'business_aspects')
        }

        if (this.$store.getters.isEpoEnabled) {
          options.push({ label: 'Patent count', value: 'patent_count' })
        }

        // if (this.$store.getters.hasAccessToExplorationOrSensing) {
        if (this.$store.getters.hasAccessToExploration) {
          options.push({ label: 'Relevant', value: 'relevant' })
        }

        if (this.$store.getters.isPublisherEnabled) {
          options.push({ label: 'Claimed', value: 'claimed' })
        }

        /* options.filter(control => (
          ![
            'domain'
          ].includes(control.value)
        )) */

        return options
      },
      filterControlOptions () {
        let options = this.translatedFilterControlOptions

        if (!this.$store.getters.hasScores) {
          options = options.filter(f => f.value !== 'score')
        }

        if (!this.$store.getters.hasCustomScores) {
          options = options.filter(f => f.value !== 'custom_score')
        }

        /* if (!this.$store.getters.isCompetitive) {
          options = options.filter(f => f.value !== 'country-focus');
        } */

        if (!this.$store.getters.knowledgeSharing) {
          options = options.filter(f => f.value !== 'reports')
        }

        // Obsolete feature flag
        /* if (! this.isDuplicateFeatureFlagEnabled) {
          options = options.filter(f => f.value !== 'possible_duplicate');
        } */

        if (!this.$store.getters.readinessTaxonomyEnabled) {
          options = options.filter(f => f.value !== 'readiness_levels')
        }

        if (!this.$store.getters.aspectTaxonomyEnabled) {
          options = options.filter(f => f.value !== 'aspects')
        }

        if (!this.$store.getters.businessAspectTaxonomyEnabled) {
          options = options.filter(f => f.value !== 'business_aspects')
        }

        if (this.$store.getters.isEpoEnabled) {
          options.push({ label: 'Patent count', value: 'patent_count' })
        }

        // if (this.$store.getters.hasAccessToExplorationOrSensing) {
        if (this.$store.getters.hasAccessToExploration) {
          options.push({ label: 'Relevant', value: 'relevant' })
        }

        if (this.$store.getters.isPublisherEnabled) {
          options.push({ label: 'Claimed', value: 'claimed' })
        }

        options.filter(control => (
          ![
            'product_features_a',
            'product_features_b',
            'product_features_c'
          ].includes(control.value)
        ))
        return options
      },
    },
    components: {
      Dropdown
    },
    mixins: [ConfigMixin]
  }
</script>

<style scoped>

</style>
