<template>
  <SimplifiedActorDetail v-if="shouldShowActorSimplifiedDetailPage || this.isProduct"/>
  <ActorDetail v-else/>
</template>

<script lang="ts">
  import ActorDetail from './ActorDetail.vue'
  import SimplifiedActorDetail from './SimplifiedActorDetail.vue'
  import UiMixin from '../../util/UiMixin.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    components: { SimplifiedActorDetail, ActorDetail },
    computed: {
      currentActor () {
        // This object always contains the data for the current view actor detail page
        return this.$store.getters.detailActor
      },
      isProduct () {
        return this.currentActor && this.currentActor['actor_type'] === 'Product'
      },
    },
    mixins: [UiMixin]
  })
</script>
