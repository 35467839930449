export const ANNOUNCEMENT_ACTION_TYPES = {
  LIKE: 'LIKE',
  COMMENT: 'COMMENT',
  DETAIL: 'DETAIL',
  PIN: 'PIN',
  FEATURE: 'FEATURE',
  EVENT_CALENDAR: 'EVENT_CALENDAR',
  SHARE_FACEBOOK: 'SHARE_FACEBOOK',
  SHARE_TWITTER: 'SHARE_TWITTER',
  SHARE_LINKEDIN: 'SHARE_LINKEDIN',
  EDIT: 'EDIT',
  DELETE: 'DELETE'
}
