<template>
  <div class="community-detail-members__container">
    <table class="table community-detail-member__table">
      <tr style="border: none">
        <td style="border: 0">
          {{$t('edit_actor_name')}}
        </td>
        <td style="border: 0">
          {{$t('top_investors_title')}}
        </td>
      </tr>
      <tr v-for="(actor, index) in actors" class="community-detail-member__member-row" :key="'community-detail-member__' + index" @click="goToActorDetailPage(actor.id)">
        <td style="border: 0;">
          <div>
            <avatar :src="actorLogo(actor)" :alt="actor.name" variant="border" style="margin-right: 0.5rem;"/>
            {{ actor.name }}
          </div>
        </td>
        <td style="border: 0;">
          {{ actor.number_of_rounds }}
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
  import Avatar from '../Avatar/Avatar.vue'

  import { actorLogo } from '../../util/actor'
  import { capitalize } from '../../util/string.ts'
  import SpottingAreaMixin from '../../util/SpottingAreaMixin.js'

  export default {
    name: 'SpottingAreaDetailInvestorsCard',
    props: ['actors'],
    methods: {
      capitalize,
      actorLogo,
      goToActorDetailPage (actorId) {
        if (this.isOwner || this.isTeamMember || this.$store.getters.isPortfolioMember) {
          this.$router.push({
            params: { section: 'actors', id: actorId },
          })
          return
        }

        if (this.isSpottingAreaScopeEnabled) {
          const accessibleSpottingArea = this.isSpottingAreaAccessibleForUser(this.spottingAreaId)

          if (!this.isLoggedIn && !accessibleSpottingArea) {
            this.$router.push(`/login?redirect_uri=/spotting-areas/${this.spottingAreaId}`)
            return
          }
        }

        this.$router.push({
          params: { section: 'actors', id: actorId },
        })
      }
    },
    components: {
      Avatar
    },
    mixins: [SpottingAreaMixin]
  }
</script>

<style lang="scss" scoped>
  .community-detail-member__table {
    border: 0;
  }

  .community-detail-member__member-row {
    height: 36px;
    border: 0;
    cursor: pointer;
    &:nth-child(even) {
      background-color: #F2F2F2;
    }
  }
</style>
