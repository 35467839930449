<template>
  <textarea class="textarea" :class="{'is-simplified': isSimplified}" :value="modelValue" @input="input" @change="change" :placeholder="defaultText" @focus="$emit('focus')" @click="$emit('click')" ref="textarea"></textarea>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      autoresize: Boolean,
      lazy: Boolean,
      rows: null,
      modelValue: String,
      defaultText: String,
      disabled: {
        type: Boolean,
        default: false
      },
      isSimplified: {
        type: Boolean,
        default: false
      },
    },
    emits: ['click', 'update:modelValue', 'focus', 'blur'],
    computed: {
      minHeight () {
        return 14 + (parseInt(this.rows, 10) || 1) * 21
      }
    },
    methods: {
      focus () {
        this.$refs.textarea.focus()
      },
      input (evt) {
        this.lazy || this.$emit('update:modelValue', evt.target.value, evt)
        this.resize()
      },
      change (evt) {
        this.lazy && this.$emit('update:modelValue', evt.target.value, evt)
        this.resize()
      },
      resize (shrink) {
        if (this.autoresize && this.$el) {
          if (!shrink && this.$el.offsetHeight > this.$el.scrollHeight - 10) {
            return
          }
          this.$el.style.height = '6px'
          this.$el.style.height = Math.max(this.minHeight, this.$el.scrollHeight) + 2 + 'px'
          setTimeout(() => {
            if (this.$el) {
              this.$el.style.height = Math.max(this.minHeight, this.$el.scrollHeight) + 2 + 'px'
            }
          })
        }
      }
    },
    mounted () {
      this.resize(1)
    },
    watch: {
      modelValue () {
        setTimeout(() => {
          this.resize()
        })
      }
    }
  })
</script>
