<template>
  <div class="actor-suggestions similar-actors-sidepanel">
    <div class="fixed-heading">
      <div class="card__general side-panel__header">
        <div class="card__title side-panel__actor-suggestions-card__title side-panel__actor-suggestions-header-card__title">
          Similar actors<span v-if="!searching"> ({{ allSimilarActors.length }})</span>
        </div>
        <div class="pull-right side-panel__announcement-header-button-container"
             style="text-align: right;">
          <ds-button icon="remove" variant="minimal" @click="hidePreview" size="small"
                     class="side-panel__announcement-header-icon"/>
        </div>
      </div>
    </div>
    <!--<similar-actors-filter-section
      :selected-classifications="selectedClassifications"
      @update-selected-classifications="selectedClassifications = $event; fetchSimilarActors()"
      :selectedFilters="selectedFilters"
      @update-selected-filters="selectedFilters = $event; fetchSimilarActors()"
    />-->
    <div class="scrollable side-panel__scrollable__content has-padding"
         style="padding-bottom: 150px">
      <div v-if="searching">
        <icon name="spinner" style="vertical-align: middle;"/>
        Searching...
      </div>


      <div
        class="global-search__result global-search__result--actor"
        v-for="actor in allSimilarActors"
      >
        <div class="global-search__result__visual"
             :style="{ backgroundImage: `url(${actor.website_screenshot || getDefaultFallbackImageUrl()})` }"
             @click.stop="showActor(actor)"
        >
        </div>
        <div class="global-search__result__info"
             @click.stop="showActor(actor)">
          <div class="global-search__result__title global-search__result__title--flex">
            <Avatar variant="border" :src="actorLogo(actor)" :alt="actor.name"/>
            <span style="margin-left: 5px;">{{ actor.name }}</span>
            <div style="margin-left: auto; padding-left: 10px;"
                 v-if="$store.getters.hasAccessToRelevancyUi">
              <relevant-edit-popup
                v-model="actor.relevant_in"
                :active-portfolio="activePortfolioId"
                @relevant="handleActorRelevant(actor, $event)"
                @not-relevant="handleActorNotRelevant(actor, $event)"
              />
            </div>
          </div>
          <div class="global-search__result__subtitle" v-if="$store.getters.isDeveloper">
            <span>Score: {{ actor.score }}</span>
          </div>
          <div class="global-search__result__description"
               v-if="actor.short_description || actor.activities_description">
            {{ textLimit(actor.short_description || actor.activities_description || '') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui.js'

  import Avatar from '../../Avatar/Avatar.vue'

  import { actorLogo } from '../../../util/actor.js'
  import { textLimit } from '../../../util/string.ts'
  import {
    searchSimilarActors, searchSimilarActorsFiltered,
    updateActorRelevancy,
  } from '../../../api/exploration.js'
  import { createActor } from '../../../api/actors.js'
  import RelevantEditPopup from '../../RelevantEditPopup/RelevantEditPopup.vue'
  import SimilarActorsFilterSection from './SimilarActorsFilterSection.vue'
  import { getDefaultFallbackImageUrl } from '../../../util/helpers.js'

  export default {
    props: ['actorId'],
    data () {
      return {
        similarActors: [],
        similarCentralActors: [],
        searching: true,
        addingActor: false,
        selectedFilters: ['description'],
        selectedClassifications: [],
      }
    },
    computed: {
      allSimilarActors () {
        return this.similarActors
          .concat(this.similarCentralActors)
          .sort((a, b) => b.score - a.score)
          .slice(0, 20)
      },
      activePortfolioId () {
        return this.$store.getters.activePortfolio.id
      },
      hasAccessToMonitoring () {
        return this.$store.getters.hasAccessToMonitoring
      },
      hasAccessToExploration () {
        return this.$store.getters.hasAccessToExploration
      },
    },
    methods: {
      getDefaultFallbackImageUrl,
      actorLogo,
      textLimit,
      hidePreview (evt) {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      async showActor (actor) {
        if (actor.id) {
          this.$router.push('/actors/' + actor.id)
        } else {
          const newActorId = await this.addActorFromCentral(actor)
          this.$router.push('/actors/' + newActorId)
          this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
        }
      },
      buildNewActor (actor) {
        return {
          name: actor.name,
          actor_type: actor.actor_type,
          url: actor.url,
          wikipedia: actor.wikipedia,
          crunchbase: actor.crunchbase,
          description: actor.description,
        }
      },
      async addActorFromCentral (actor) {
        if (this.addingActor) {
          return
        }
        this.addingActor = true

        let newActor = this.buildNewActor(actor)

        try {
          newActor = await createActor(newActor)
          this.addingActor = false
          return newActor.id
        } catch (e) {
          console.log(e)

          // If the error is an error that fails because the (or a similar) actor already exists, go to the profile
          if (e.id) {
            this.addingActor = false
            return e.id
          }

          this.addingActor = false
        }
      },
      async handleActorRelevant (actor, conceptSearchId) {
        if (actor.id) {
          updateActorRelevancy(actor.id, true, conceptSearchId)
        } else {
          const newActorId = await this.addActorFromCentral(actor)
          updateActorRelevancy(newActorId, true, conceptSearchId)
        }
      },
      async handleActorNotRelevant (actor, conceptSearchId) {
        if (actor.id) {
          updateActorRelevancy(actor.id, false, conceptSearchId)
        } else {
          const newActorId = await this.addActorFromCentral(actor)
          updateActorRelevancy(newActorId, false, conceptSearchId)
        }
      },
      fetchSimilarActors () {
        searchSimilarActorsFiltered(this.actorId, this.selectedFilters, this.selectedClassifications)
          .then(response => {
            this.searching = false
            this.similarActors = response['same-name'] || []
            this.similarCentralActors = response['central'] || []
          })
          .catch(err => {
            this.searching = false
            console.log(err)
          })
      },
    },
    mounted () {
      this.fetchSimilarActors()
    },
    components: {
      RelevantEditPopup,
      Avatar,
      SimilarActorsFilterSection,
    },
  }
</script>

<style lang="scss">
  .similar-actors-sidepanel {
    min-width: 650px;
  }

  .global-search__result__title--flex {
    display: flex;
    align-items: center;
  }
</style>
