<template>
  <div>
    <h2 class="h2">Configure which actors can be enriched, and how often</h2>
    <form-group>
      <h3 class="h3">For new and updated actor profiles:</h3>
      <radio-button
        v-model="form.profileEnrichment"
        label="Automatically enrich the profile."
        val="automatic"
        name="add_data_automatic"
      />
      <radio-button
        v-model="form.profileEnrichment"
        label="Manually enrich the profile. No credits will be consumed."
        val="manual"
        name="add_data_manual"
      />
    </form-group>

    <form-group>
      <h3 class="h3">For existing actor profiles, keep them up to date:</h3>
      <radio-button
        v-model="monthlyEnrichment"
        val="no"
        label="Manually. No new data will be added by DataScouts"
        name="existing_actors_manual"
      />
      <radio-button
        v-model="monthlyEnrichment"
        val="yes"
        label="Continuously. On a monthly basis DataScouts will keep actor profiles up to date"
        name="existing_actors_automatic"
      />
      <template v-if="monthlyEnrichment === 'yes'">
        <form-group label="Configure which actor profiles we should continuously monitor" style="margin-top: 15px;">
          <radio-button
            v-model="form.enrichmentScope"
            val="all"
            label="All actors in the ecosystem"
            name="enrich_all"
          />
          <radio-button
            v-model="form.enrichmentScope"
            val="scoped"
            label="All actors in the selected portfolios below"
            name="enrich_scoped"
          />
          <template v-if="form.enrichmentScope === 'scoped'">
            <AutocompleteTagInput
              :tags="selectedPortfolios"
              :options="portfolioOptions"
              :addOnlyFromAutocomplete="true"
              placeholder="Search for a portfolio"
              @tagChanged="updatePortfolioOptions"
              @input:raw="updateSelectedPortfolios"
              :minInputLength="1"
              style="width: 440px;"
            />
          </template>
        </form-group>
      </template>
    </form-group>

    <form-group v-if="displayContentMonitor">
      <h2 class="h2">Configure for which actors we need to monitor news for</h2>
      <form-group label="Configure which actor profiles we should continuously monitor news for." style="margin-top: 15px;">
        <span style="font-size: 12px; font-weight: 500;">Note: we only scout news for actors we have a Wikipedia link for.</span>
        <checkbox label="All actors in the ecosystem" v-model="form.newsMonitorScope" val="all"/>
        <checkbox label="All actors in the selected portfolios below" v-model="form.newsMonitorScope" val="scoped"/>
        <template v-if="form.newsMonitorScope === 'scoped'">
          <AutocompleteTagInput
            :tags="selectedNewsMonitorPortfolios"
            :options="portfolioOptions"
            :addOnlyFromAutocomplete="true"
            placeholder="Search for a portfolio"
            @tagChanged="updatePortfolioOptions"
            @input:raw="updateSelectedNewsMonitorPortfolios"
            :minInputLength="1"
            style="width: 440px;"
          />
        </template>
        <!--        <radio-button
                  v-model="form.enrichmentScope"
                  val="scoped"
                  label="All actors in the selected spotting areas below"
                  name="enrich_scoped_spotting_areas"
                />-->
        <checkbox label="All actors in the selected spotting areas below" v-model="form.newsMonitorScopeSpottingAreas" val="scoped_spotting_areas"/>

        <template v-if="form.newsMonitorScopeSpottingAreas === 'scoped_spotting_areas'">
          <AutocompleteTagInput
            :tags="selectedSpottingAreas"
            :options="spottingAreasOptions"
            :addOnlyFromAutocomplete="true"
            placeholder="Search for a spotting area"
            @tagChanged="updateSpottingAreaOptions"
            @input:raw="updateSelectedSpottingAreas"
            :minInputLength="1"
            style="width: 440px;"
          />
        </template>
      </form-group>
    </form-group>
  </div>
</template>

<script>
  import AutocompleteTagInput from '../../Form/AutocompleteTagInput.vue'
  import Checkbox from '../../Form/Checkbox.vue'
  import Dropdown from '../../Dropdown/Dropdown.vue'
  import DsTextarea from '../../Form/DsTextarea.vue'
  import ImageInput from '../../Form/ImageInput.vue'
  import TagInput from '../../Form/TagInput.vue'
  import RadioButton from '../../Form/RadioButton.vue'

  import { fetchFlaggedPortfolio, fetchPortfolio } from '../../../api/portfolios.js'
  import { fetchFlaggedSpottingAreas, fetchSpottingAreas } from '../../../api/spottingareas'

  import debounce from 'lodash/throttle'

  export default {
    name: 'data-enrichment-frequency',
    props: ['form'],
    data () {
      return {
        enrichPortfolios: [], // The portfolios that are configured to be enriched
        selectedPortfolios: [], // The model to inject into the autocomplete, consisting of objects that represent the portfolios that can be enriched
        selectedSpottingAreas: [],
        portfolioOptions: [],
        spottingAreasOptions: [],
        newsMonitorPortfolios: [],
        selectedNewsMonitorPortfolios: [],
      }
    },
    computed: {
      monthlyEnrichment: {
        get () {
          return this.form.monthly_enrichment == true ? 'yes' : 'no'
        },
        set (v) {
          this.form.monthly_enrichment = (v == 'yes')
        },
      },
      displayContentMonitor () {
        return this.$store.getters.hasAccessToMonitoring
      },
    },
    methods: {
      refreshEnrichmentScope () {
        fetchFlaggedPortfolio('enriched')
          .then(response => {
            if (!Array.isArray(response)) {
              return
            }

            var options = []

            response.forEach(portfolio => {
              options.push({
                text: portfolio.name,
                optionValue: {
                  text: portfolio.name,
                  id: portfolio.id,
                },
              })
            })

            // Update both the tags (used to display the results, and the array that keeps track of only the ids, used to communicate with the API)
            this.selectedPortfolios = options
            this.enrichPortfolios = options.map(portfolio => portfolio.optionValue.id)
            this.form.enrichPortfolios = this.enrichPortfolios
          })
          .catch(err => {
            console.log(err)
          })

        fetchFlaggedPortfolio('monitored')
          .then(response => {
            if (!Array.isArray(response)) {
              return
            }

            var options = []

            response.forEach(portfolio => {
              options.push({
                text: portfolio.name,
                optionValue: {
                  text: portfolio.name,
                  id: portfolio.id,
                },
              })
            })

            // Update both the tags (used to display the results, as well as the array that keeps track of only the ids, used to communicate with the API)
            this.selectedNewsMonitorPortfolios = options
            this.newsMonitorPortfolios = options.map(portfolio => portfolio.optionValue.id)
            this.form.newsMonitorPortfolios = this.newsMonitorPortfolios
          })
          .catch(err => {
            console.log(err)
          })

        fetchFlaggedSpottingAreas('monitored')
          .then(response => {
            if (!Array.isArray(response)) {
              return
            }

            var options = []

            response.forEach(spottingArea => {
              options.push({
                text: spottingArea.name,
                optionValue: {
                  text: spottingArea.name,
                  id: spottingArea.id,
                },
              })
            })

            // Update both the tags (used to display the results, as well as the array that keeps track of only the ids, used to communicate with the API)
            this.selectedSpottingAreas = options
            this.enrichSpottingAreas = options.map(spottingArea => spottingArea.optionValue.id)
            this.form.newsMonitorSpottingAreas = this.enrichSpottingAreas
          })
          .catch(err => {
            console.log(err)
          })
      },
      updatePortfolioOptions: debounce(async function (query) {
        if (!query) {
          return
        }

        // This will search for a portfolio which matches the input value in the search field
        var filters = Object.assign({}, { query: query })

        fetchPortfolio(filters)
          .then(portfolios => {
            this.portfolioOptions = []

            this.parsePortfolioOptions(portfolios)
          })
          .catch(errors => {
            console.log(errors)
          })
      }, 150),
      updateSpottingAreaOptions: debounce(async function (query) {
        if (!query) {
          return
        }

        fetchSpottingAreas()
          .then(spottingAreas => {
            this.spottingAreasOptions = []

            this.parseSpottingAreasOptions(spottingAreas)
          })
          .catch(errors => {
            console.log(errors)
          })
      }, 150),
      parsePortfolioOptions (portfolios) {
        if (!Array.isArray(portfolios)) {
          return
        }

        var options = []

        // Don't include virtual portfolios in the portfolios a user can choose from
        portfolios.forEach(portfolio => {
          if (portfolio.virtual) {
            return
          }

          options.push({
            text: portfolio.name,
            id: portfolio.id,
          })
        })

        this.portfolioOptions = options
      },
      parseSpottingAreasOptions (spottingAreas) {
        if (!Array.isArray(spottingAreas)) {
          return
        }

        var options = []

        // Don't include virtual portfolios in the portfolios a user can choose from
        spottingAreas.forEach(spottingArea => {
          if (spottingArea.virtual) {
            return
          }

          options.push({
            text: spottingArea.name,
            id: spottingArea.id,
          })
        })

        this.spottingAreasOptions = options
      },
      updateSelectedPortfolios (input) {
        this.enrichPortfolios = input.map(portfolio => portfolio.optionValue.id)
        this.form.enrichPortfolios = this.enrichPortfolios
        this.selectedPortfolios = input
      },
      updateSelectedNewsMonitorPortfolios (input) {
        this.newsMonitorPortfolios = input.map(portfolio => portfolio.optionValue.id)
        this.form.newsMonitorPortfolios = this.newsMonitorPortfolios
        this.selectedNewsMonitorPortfolios = input
      },
      updateSelectedSpottingAreas (input) {
        this.enrichSpottingAreas = input.map(spottingArea => spottingArea.optionValue.id)
        this.form.newsMonitorSpottingAreas = this.enrichSpottingAreas
        this.selectedSpottingAreas = input
      },
    },
    mounted () {
      // Fetch the portfolios & spotting areas configured to be enriched
      this.refreshEnrichmentScope()
    },
    components: {
      AutocompleteTagInput,
      Checkbox,
      Dropdown,
      DsTextarea,
      ImageInput,
      TagInput,
      RadioButton,
    },
  }
</script>
