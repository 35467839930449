<template>
  <modal
    ref="modal"
    title="Edit address"
    :enable-mask="false"
    :is-closeable="true"
    @close="close"
    @click="close"
  >
    <template v-slot:body>
      <div>
        <office-input :modelValue="office" editable="Save changes" @saved="close" />
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from './Modal.vue'
import OfficeInput from '../Form/OfficeInput.vue'

export default {
  name: 'address-modal',
  props: ['office'],
  methods: {
    close () {
      this.$emit('close')
    }
  },
  components: {
    Modal,
    OfficeInput,
  }
}
</script>
