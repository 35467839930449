<template>
  <div class="has-padding office-input office-input-card">
    <form-group :label="$t('edit_actor_segment_contact_name')" :errors="errors.name">
      <ds-input v-model="modelValue.name" />
    </form-group>

    <div class="row">
      <form-group class="col-xs-12 col-md-6" style="display: inline-block;" :label="$t('edit_actor_segment_contact_phone')" :errors="errors.phone">
        <ds-input v-model="modelValue.phone" />
      </form-group>

      <form-group class="col-xs-12 col-md-6" style="display: inline-block;" :label="$t('add_actor_company_number')" :errors="errors.company_number" >
        <div class="company_number">
          <dropdown :options="jurisdictionOptions" :model-value="modelValue.jurisdiction" v-model="modelValue.jurisdiction" width="70px" :search="true" />
          <ds-input :placeholder="$t('add_actor_company_number')" name="actor-vat-number" v-model="modelValue.company_number" />
        </div>
      </form-group>
    </div>

    <div v-if="modelValue.address">
      <div class="row">
        <form-group :label="$t('edit_actor_segment_contact_street')" class="col-xs-8 col-sm-8">
          <ds-input name="actor-address-street" v-model="modelValue.address.street" />
        </form-group>
        <form-group :label="$t('edit_actor_segment_contact_number')" class="col-xs-4 col-sm-4">
          <ds-input name="actor-address-number" v-model="modelValue.address.number" />
        </form-group>
        <form-group :label="$t('edit_actor_segment_contact_zipcode')" class="col-xs-4 col-sm-4">
          <ds-input name="actor-address-zip" v-model="modelValue.address.zip" />
        </form-group>
        <form-group :label="$t('edit_actor_segment_contact_city')" class="col-xs-8 col-sm-8">
          <ds-input name="actor-address-city" v-model="modelValue.address.city" />
        </form-group>
      </div>
      <div class="row">
        <form-group :label="$t('edit_actor_segment_contact_country')" class="col-xs">
          <country-dropdown @update:modelValue="handleCountryChange" :model-value="modelValue.address.country_code" />
        </form-group>
      </div>
    </div>

    <form-group :label="$t('actor_contributors_email')" :errors="errors.email">
      <ds-input v-model="modelValue.email" />
    </form-group>

    <div v-if="!modelValue.address">
      <ds-button variant="outline" icon="plus" :label="$t('edit_actor_segment_contact_add_office')" @click="setEmptyAddress" />
      <br>
      <br>
    </div>

    <ds-button variant="outline" :icon="busy === 2 ? 'check' : busy === 1 ? 'spinner' : editable === 'Add office' ? 'plus' : 'check'" :label="editable" @click="save" />
  </div>
</template>

<script>
import _intersection from 'lodash/intersection'

import { createActor, updateActor } from '../../api/actors'

import Checkbox from '../Form/Checkbox.vue'
import CountryDropdown from '../Dropdown/CountryDropdown.vue'
import DsTextarea from '../Form/DsTextarea.vue'
import Dropdown from '../Dropdown/Dropdown.vue'


import { emptyAddress } from '../../util/actor'
import { addressLine } from '../../util/helpers'
import { COUNTRIES } from '../../constants/countries'

export default {
  props: {
    editable: null,
    main: null,
    modelValue: {},
  },
  data () {
    return {
      busy: 0,
      errors: {},
      editing: !this.modelValue.id,
      subsidiary: this.modelValue.company_type === 'Subsidiary'
    }
  },
  computed: {
    addressLine () {
      return addressLine(this.modelValue.address)
    },
    jurisdictionOptions () {
      // Filter out the UK, it's not considered a legal jurisdiction
      return Object.keys(COUNTRIES).filter(countryCode => countryCode !== 'UK')
    },
  },
  methods: {
    handleCountryChange (country) {
      this.modelValue.address.country_code = country.code
      this.modelValue.address.country = country.name
    },
    setEmptyAddress () {
      this.modelValue.address = emptyAddress()
    },
    save () {
      this.$emit('beforeSave', this.modelValue)
      this.busy = 1

      if (this.modelValue.id) {
        return this.update()
      }

      createActor({
        actor_type: this.modelValue.actor_type || 'LegalEntity',
        address: this.modelValue.address || Object.assign(emptyAddress()),
        company_type: this.modelValue.company_type || 'Subsidiary',
        name: this.modelValue.name,
        jurisdiction: this.modelValue.jurisdiction,
        company_number: this.modelValue.company_number,
        main_company: [{
          id: this.main,
          to: this.main,
        }]
      }).then((newActor) => {
        this.busy = 2
        this.errors = {}
        this.$emit('saved', newActor)//this.value)
      }).catch(errors => {
        this.busy = 0
        this.errors = errors
      })
    },
    update () {
      this.busy = 1
      updateActor({
        id: this.modelValue.id,
        data: {
          name: this.modelValue.name,
          address: this.modelValue.address
        }
      }).then(actor => {
        this.busy = 2
        this.errors = {}
        this.$emit('saved', this.modelValue)
      }).catch(() => {
        this.busy = 0
        this.errors = errors
      })
    }
  },
  components: {
    Checkbox,
    CountryDropdown,
    DsTextarea,
    Dropdown
  }
}
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";

.office-input {
  display: block;
  margin-bottom: 1em;
  width: 100%;
  outline: 0;
  font-size: 14px;
  &.editable {
    border: 1px solid #CECECE;
    padding: 14px 10px 8px;
    background: #eee;
  }

  .svg-icon--chevron-down {
    vertical-align: top;
    margin: -3px 5px -10px;
  }
}
.office-input__btn {
  display: inline-block;
  margin: -8px 0;
  border: 2px solid transparent;
  padding: 3px;
  cursor: pointer;
  text-decoration: none;
  &:hover {
    background: white;
    border-color: $color-error;
  }
  .svg-icon {
    vertical-align: bottom;
    // display: block;
  }
}
.office-input__remove {
  &:hover {
    border-color: $color-error;
  }
}
.office-input__edit {
  padding: 3px 15px 3px 8px;
  a {
    color: black;
  }
  path {
    fill: black;
  }
  &:hover {
    border-color: $color-primary;
  }
}
.office-input__detail {
  margin-bottom: 3px;
}
.office-input__editing {
  padding-top: 10px;
  margin-bottom: -20px;
}
</style>
