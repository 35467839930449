<template>
  <div class="announcement-hero" :style="announcementHeroStyling">
    <div class="container" :style="announcementHeroContainerStyling">
      <BasicHero
        :style="basicHeroStyling"
        :title="isEvent ? onboardingTextConfig.eventsSimplifiedTitle : onboardingTextConfig.announcementsSimplifiedTitle"
        :subtitle="isEvent ? onboardingTextConfig.eventsSimplifiedSubtitle : onboardingTextConfig.announcementsSimplifiedSubtitle"
      >
        <announcements-search-bar
          @search="searchAnnouncements"
          @update:modelValue="handleInput"
          v-if="showSearch && !hasActiveAnnouncementFilter && activeTags.length == 0 && !isDetailPage"
          :is-event="isEvent"
        />
        <div v-else-if="activeTags && !isDetailPage" class="tags">
          <ds-button
            v-for="(tag, index) in activeTags"
            :key="index"
            :label="tag.label"
            size="extra-small"
            variant="rounded"
            class="filter-button"
            @click.stop="removeTag(tag)"
          />
        </div>
        <ds-button
          v-else-if="isDetailPage"
          :label="buttonLabelForButton"
          size="extra-small"
          variant="rounded"
          :style="goBackButtonStyling"
          class="go-back-button"
          @click="backToOverview"
        />
      </BasicHero>
    </div>
  </div>
</template>
<script>
  import AnnouncementsSearchBar from '../SearchBar/AnnouncementsSearchBar.vue'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import AnnouncementMixin from '../../util/AnnouncementMixin.js'
  import BasicHero from '../BasicHero/BasicHero.vue'
  import OnboardingMixin from '../../util/OnboardingMixin.js'
  import { MUTATION_TYPES as NOTIFICATION_MUTATION_TYPES } from '../../store/modules/notifications.js'
  import { SiteTemplate } from '../../store/modules/config.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      title: String,
      subtitle: String,
      showSearch: Boolean,
      isEvent: Boolean,
    },
    data () {
      return {
        collapseHeight: 0,
        minHeight: 300,
      }
    },
    components: {
      AnnouncementsSearchBar,
      BasicHero,
    },
    emits: ['search', 'update:modelValue', 'removeAnnouncementsFilter', 'removeTagFilter'],
    methods: {
      ecosystemDisplayName () {
        return this.$store.getters.ecosystemDisplayName
      },
      removeTag (tag) {
        this.$store.commit(NOTIFICATION_MUTATION_TYPES.TOGGLE_ACTIVE_TAGS, tag)
        this.$emit('search')
      },
      backToOverview () {
        if (this.isEvent) {
          this.$router.push('/events')
        }

        if (this.isEvent && this.isSimplifiedTemplateUsed) {
          this.$router.push('/events-simplified')
        }
        if (!this.isEvent) {
          this.$router.push('/announcements')
        }
      },
      searchAnnouncements () {
        this.$emit('search')
      },
      handleInput (value) {
        this.$emit('update:modelValue', value)
      },
    },
    computed: {
      config () {
        return this.$store.state.config
      },
      image () {
        if (this.$store.getters.isStaging) {
          return '/images/static-home-page/static-home-page-foodleap.png'
        }

        return this.config.homepage_knowledge_base_image
      },
      isCommunityEcosystem () {
        return this.$store.getters.isCommunityEcosystem
      },
      announcementHeroStyling () {
        if (this.isCommunityEcosystem) {
          return `background-image: url(${this.image}); min-height: ${this.minHeight}px; display: flex; justify-content: center; background-size: cover; background-repeat: no-repeat;`
        }

        return `background-color: var(--primary-community)`
      },
      announcementHeroContainerStyling () {
        if (this.isCommunityEcosystem) {
          return `display: flex; justify-content: center;`
        }
      },
      basicHeroStyling () {
        if (this.isCommunityEcosystem) {
          return `background: transparent; color: #323441; display: flex; flex-direction: column; justify-content: center; align-items: center; padding-top: 0;`
        }
      },
      goBackButtonStyling () {
        if (this.isCommunityEcosystem) {
          return `background-color: #323441;`
        }

        return `color: var(--primary);`
      },
      hasActiveAnnouncementFilter () {
        return !!this.selectedAnnouncementsFilter
      },
      activeTags () {
        return this.$store.state.notifications.listData.tags
      },
      selectedAnnouncementsFilter () {
        return this.$store.state.notifications.listData.selectedFilter
      },
      buttonLabelForButton () {
        if (this.isEvent) {
          return '<<  ' + this.$t('events_back', { events: this.eventsLabel })
        } else {
          return '<<  ' + this.$t('announcements_back', { title: this.title })
        }
      },
      isSimplifiedTemplateUsed () {
        return [SiteTemplate.NEW_SIMPLIFIED, SiteTemplate.SIMPLIFIED].includes(this.$store.getters.activeSiteTemplate)
      },
    },
    mixins: [
      TranslationsMixin,
      AnnouncementMixin,
      OnboardingMixin,
    ],
  })
</script>

<style lang="scss" scoped>

  .announcement-hero {
    z-index: 99;

    .container {
      margin: 0 auto;
    }


    .tags {
      display: flex;
      flex-direction: column;
    }

    .go-back-button, .filter-button {
      background-color: white;
      margin-top: 1.5rem;

      :hover {
        color: white;
      }
    }
  }
</style>
