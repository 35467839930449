import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../store/modules/filters.js'

export function handleRemoveNestedKeyword (store, keyword) {
  switch (keyword.facet) {
    case 'Funding Date':
      store.commit(FILTERS_MUTATION_TYPES.UPDATE_FUNDING_DATE_RANGE, {})
      break
    case 'Founding Date':
      store.commit(FILTERS_MUTATION_TYPES.UPDATE_FOUNDED_DATE_RANGE, {})
      break
    case 'Created At':
      store.commit(FILTERS_MUTATION_TYPES.UPDATE_CREATED_AT_START_DATE, {})
      store.commit(FILTERS_MUTATION_TYPES.UPDATE_CREATED_AT_END_DATE, {})
      break
    case 'Updated At':
      store.commit(FILTERS_MUTATION_TYPES.UPDATE_UPDATED_AT_START_DATE, {})
      store.commit(FILTERS_MUTATION_TYPES.UPDATE_UPDATED_AT_END_DATE, {})
      break
    case 'Claimed At':
      store.commit(FILTERS_MUTATION_TYPES.UPDATE_CLAIMED_AT_START_DATE, {})
      store.commit(FILTERS_MUTATION_TYPES.UPDATE_CLAIMED_AT_END_DATE, {})
      break
    case 'Employees':
      store.commit(FILTERS_MUTATION_TYPES.UPDATE_EMPLOYEE_RANGE, {})
      break
    case 'Patents':
      store.commit(FILTERS_MUTATION_TYPES.UPDATE_PATENT_COUNT_RANGE, {})
      break
    case 'Total Connections':
      store.commit(FILTERS_MUTATION_TYPES.UPDATE_RELATIONSHIP_COUNT_RANGE, {})
      break
    case 'Revenue':
      store.commit(FILTERS_MUTATION_TYPES.UPDATE_REVENUE_RANGE, {})
      break
    case 'Custom Score':
      store.commit(FILTERS_MUTATION_TYPES.UPDATE_CUSTOM_SCORE_RANGE, {})
      break
    case 'Completeness':
      store.commit(FILTERS_MUTATION_TYPES.UPDATE_COMPLETENESS_RANGE, {})
      break
    case 'Mentions':
      store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, { facet: 'mentionsMin', values: null })
      store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, { facet: 'mentionsMax', values: null })
      break
    case 'Total funding':
      store.commit(FILTERS_MUTATION_TYPES.UPDATE_TOTAL_FUNDING_RANGE, {})
      break
  }
}

export function handleRemoveReportKeyword (store, keyword) {
  let context
  if (keyword.type !== 'report') {
    return
  }

  if (keyword.meta.type === 'number' || keyword.meta.type === 'date') {
    const minContext = {}
    minContext.type = keyword.meta.type
    minContext.reportId = 'report_field_' + keyword.meta.id + '_min'
    minContext.value = null

    const maxContext = Object.assign({}, minContext)
    maxContext.reportId = 'report_field_' + keyword.meta.id + '_max'

    store.commit(FILTERS_MUTATION_TYPES.UPDATE_REPORT_FIELD, minContext)
    store.commit(FILTERS_MUTATION_TYPES.UPDATE_REPORT_FIELD, maxContext)
  } else if (keyword.meta.type === 'score') {
    context = {}
    context.type = keyword.meta.type
    context.reportId = keyword.meta.id
    context.start = null
    context.end = null

    store.commit(FILTERS_MUTATION_TYPES.UPDATE_REPORT_FIELD, context)
  } else if (['options', 'multi_select', 'checkbox'].includes(keyword.meta.type)) {
    context = {
      reportId: keyword.meta.id,
      value: keyword.value,
      type: 'options',
    }
    store.commit(FILTERS_MUTATION_TYPES.UPDATE_REPORT_FIELD, context)
  }
}
