<template>
  <div class="dictionary">
    <form-group :label="label" :errors="errors">
      <AutocompleteTagInput
        :tags="showResults ? dictionaryTags : []"
        :options="topicOptions"
        :addOnlyFromAutocomplete="true"
        :is-simplified="isSimplified"
        :minInputLength="1"
        :disabled="disabled"
        :maxTags="maxTags"
        placeholder=""
        @tagChanged="updateTopicOptions"
        @input:raw="updateDictionaryKeywords"
      />
    </form-group>
  </div>
</template>

<script>
  import FormGroup from '../../Form/FormGroup.vue'
  import AutocompleteTagInput from '../../../components/Form/AutocompleteTagInput.vue'
  import { fetchConceptSuggestions } from '../../../api/exploration'
  import debounce from 'lodash/throttle'

  export default {
    name: 'dictionary-input',
    props: {
      // A dictionary object
      showResults: {
        type: Boolean,
        default: true
      },
      isSimplified: {
        type: Boolean,
        default: false,
      },
      modelValue: {
        type: Array,
        required: true,
      },
      label: {
        type: String,
        required: true,
      },
      errors: {
        type: Array,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      maxTags: {
        type: Number,
        default: 20,
      },
      allowFreeText: {
        type: Boolean,
        default: false,
      },
      excludeTagObjectsFromSearchResults: {
        type: Array,
        default: []
      },
    },
    emits: ['update:modelValue'],
    data () {
      return {
        dictionaryTags: [],
        topicOptions: [],
      }
    },
    methods: {
      updateTopicOptions: debounce(function (input) {
        const excludeTagValues = (this.excludeTagObjectsFromSearchResults || []).map(i => { return i.value })

        fetchConceptSuggestions({
          query: input,
          conceptType: 'any',
          allow_free_text: this.allowFreeText,
        })
          .then(response => {
            this.topicOptions = []

            var options = []

            response.forEach(suggestion => {
              var tag = suggestion

              tag.label = tag.value
              tag.originalValue = tag.label
              if (!tag.hoverText) {
                tag.hoverText = ''
              } else {
                tag.label = `${tag.label} (${tag.hoverText})`
              }

              options.push({
                text: tag.label,
                hoverText: tag.hoverText,
                conceptObject: tag,
              })
            })

            this.topicOptions = options
          })
          .catch(err => {
            this.topicOptions = []
          })
      }, 500),
      updateDictionaryKeywords (keywords) {
        // Compose the dictionary value
        var updatedKeywords = keywords.map(keyword => {
          return keyword.optionValue.conceptObject
        })

        this.$emit('update:modelValue', updatedKeywords)
      },
      parseDictionaryTags (keywords) {
        var tags = []

        if (keywords.length > 0) {
          keywords.forEach(keyword => {
            if (!keyword.hoverText) {
              keyword.hoverText = ''
            }

            tags.push({
              text: keyword.label || keyword.value,
              optionValue: {
                text: keyword.originalValue || (keyword.label || keyword.value),
                hoverText: keyword.hoverText,
                conceptObject: keyword,
              },
            })
          })
        }

        this.dictionaryTags = tags
      },
    },
    mounted () {
      this.parseDictionaryTags(this.modelValue)
    },
    watch: {
      modelValue (v) {
        this.parseDictionaryTags(this.modelValue)
      },
    },
    components: {
      FormGroup,
      AutocompleteTagInput,
    },
  }
</script>

<style lang="scss" scoped>
  .dictionary {
    .form-group {
      margin-bottom: 10px !important;
    }
  }

  .dictionary__buttons {
    display: flex;
  }

</style>
