<template>
  <div>
    <div class="fixed-heading">
      <div class="card__general side-panel__header">
        <div class="card__title side-panel__announcement-card__title side-panel__announcement-header-card__title">{{
            challenge.id ? $t('edit_challenge_panel_title', { challenge: challengeLabel }) : $t('add_challenge_panel_title_v2', { challenge: challengeLabel })
          }}
        </div>
        <ds-button icon="trash" variant="minimal" @click="remove" size="small"
                   class="side-panel__announcement-header-icon" style="margin-left: auto"/>
        <ds-button icon="remove" variant="minimal" @click="close" size="small"
                   class="side-panel__announcement-header-icon"/>
      </div>
    </div>
    <div class="scrollable side-panel__scrollable__content has-padding">
      <div class="side-panel__announcement-form-container">
        <form>
          <form-group :label="$t('add_announcement_title')" :errors="errors.title" :required="true">
            <ds-input v-model="challenge.title"/>
          </form-group>

          <div class="side-panel__challenge_subtitle">
            {{ $t('manage_challenge_subtitle_description') }}
          </div>
          <br>
          <form-group :label="$t('manage_challenge_description', {challenge: challengeLabel})"
                      :errors="errors.description" :required="true">
            <rich-text-area v-model="challenge.description" :minimal="true"/>
          </form-group>
          <form-group :label="$t('manage_challenge_goal')" :errors="errors.objective" :required="true">
            <rich-text-area v-model="challenge.objective" :minimal="true"/>
          </form-group>
          <form-group :label="$t('add_challenge_invite_only')" v-if="isOwner" v-model="challenge.invite_only"
                      :errors="errors.invite_only" class="side-panel__challenge_invite_only">
            <ds-button v-for="(visibilityOption, index) in visibilityOptions" :key="'visibility' + index"
                       :label="visibilityOption.label" @click="toggleVisibilityOption(visibilityOption)"
                       :variant="challenge.invite_only == visibilityOption.value ? 'primary' : 'outline'"/>
          </form-group>
          <form-group label="Link to community" v-model="challenge.invite_only"
                      :errors="errors.invite_only" class="side-panel__challenge_invite_only">
            <dropdown
                :options="communityOptions"
                v-model="challenge.communityIds"
                :disabled="!isMember"
                allow-clear
                multiple
            />

          </form-group>
          <br>
          <div class="side-panel__challenge_subtitle">
            {{ $t('manage_challenge_subtitle_requirements') }}
          </div>
          <br>
          <div style="display: flex">
            <div style="width: 50%">
              <form-group :label="$t('add_challenge_sdg')" :errors="errors.sdgs">
                <ul class="side-panel__challenge_sdgs">
                  <li :title="item" v-for="(item, index) in sdgOptions"
                      class="sdg-filter"
                      v-tooltip.top="translatedSustainabilityGoals[index]"
                      :class="{ 'modal__body__active-filter-img': isSdgEnabledForChallenge(item)}">
                    <img :src="sdgImagesPath + (index + 1) + '.jpg'" :alt="translatedSustainabilityGoals[index]"
                         @click="handleToggleSustainabilityGoal(item)">
                  </li>
                  <li></li>
                  <li></li>
                  <li></li>
                  <li></li>
                </ul>
              </form-group>
              <form-group :label="$t('add_challenge_status')" v-model="challenge.status" v-if="isOwner"
                          :errors="errors.status">
                <dropdown v-model="challenge.status" :options="statusOptions" autoresize/>
              </form-group>
              <!--              <form-group label="Growth stage" v-if="growthStageOptions">-->
              <!--                <checkbox v-for="(stage, index) in growthStageOptions" :key="'stage' + index" :label="stage.name" v-model="challenge.growth_stages" inline multiple :val="stage.id"/>-->
              <!--              </form-group>-->
            </div>
            <div style="width: 50%; padding-left: 20px">
              <form-group :label="$t('add_challenge_geography_scope')">
                <dropdown v-model="challenge.geography_scope" :options="geographyOptions" search :multiple="true"/>
              </form-group>
              <form-group :label="$t('add_challenge_end_date')"
                          :info-text="$t('challenge_end_date_info', {challenge: challengeLabel})">
                <datepicker :model-value="challenge.end_date" @update:modelValue="challenge.end_date = $event"
                            :allow-reset="true"/>
              </form-group>
              <form-group :label="$t('add_announcement_tags')" :errors="errors.tags"
                          :info-text="$t('challenge_tags_info', {challenges: challengesLabel})">
                <AutocompleteTagInput :placeholder="$t('add_announcement_tags_placeholder')" @tagsChanged="updateTags"
                                      :tags="challenge.tags" @tagChanged="handleTagChanged" :options="tagOptions"/>
              </form-group>
              <form-group :label="$t('add_announcement_image')" :errors="errors.image"
                          :info-text="$t('add_challenge_image_help_text', {challenge: challengeLabel})">
                <image-input v-model="challenge.originalImage" @update:modelValue="updateImage" width="200"
                             height="100"/>
              </form-group>
            </div>
          </div>
        </form>
      </div>
    </div>
    <action-bar editing="1" class="side-panel__announcement-bottom-section">
      <ds-button variant="secondary" size="extra-small" :label="saveButtonLabel" @click="save()" :disabled="!canSave || saving"/>
    </action-bar>
  </div>
</template>

<script>
import { Challenges } from '../../Domain/Challenge/Api/challenges.ts'
import { fetchSdgs } from '../../api/taxonomies'
import { trackHeapEvent } from '../../util/analytics'

import DsTextarea from '../Form/DsTextarea.vue'
import AutocompleteTagInput from '../../components/Form/AutocompleteTagInput.vue'
import ActionBar from '../../components/Form/ActionBar.vue'
import ImageInput from '../../components/Form/ImageInput.vue'
import Datepicker from '../Form/Datepicker.vue'

import TagsMixin from '../../util/TagsMixin'

import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
import Dropdown from '../Dropdown/Dropdown.vue'
import RichTextArea from '../TextArea/RichTextArea.vue'
import MODAL_IDS from '../../constants/modal-ids.js'
import Checkbox from '../Form/Checkbox.vue'
import { CONTINENTS, COUNTRIES } from '../../constants/countries'
import ToggleButton from '../Form/ToggleButton.vue'
import TranslationsMixin from '../../util/TranslationsMixin.js'
import { sustainabilityGoals, sustainabilityGoalsNL } from '../../constants/config.js'
import UserMixin from '@/util/UserMixin'

export default {
  name: 'manage-challenge-panel',
  props: {
    challengeData: {
      type: Object,
    },
  },
  data () {
    return {
      challenge: {
        title: '',
        description: '',
        status: 'new',
        image: null,
        tags: [],
        objective: '',
        end_date: '',
        invite_only: false,
        geography_scope: [],
        sdgs: [],
        communityIds: []
      },
      visibilityOptions: [{ label: 'Private', value: 1 }, { label: 'Public', value: 0 }],
      saving: false,
      errors: {},
      statusOptions: [
        { label: 'New', value: 'new' },
        { label: 'Open', value: 'open' },
        { label: 'Solved', value: 'solved' },
      ],
      sdgOptions: [],
    }
  },
  computed: {
    canSave () {
      return this.challenge.title && this.challenge.description && this.challenge.objective
    },
    communityId () {
      return this.$route.params.communityId
    },
    communityOptions () {
      return this.$store.state.communities.listData.data.map((community) => {
        return {
          label: community.name,
          value: community.id
        }
      })
    },
    translatedSustainabilityGoals () {
      return this.$store.getters.activeLocale === 'nl' ? sustainabilityGoalsNL : sustainabilityGoals
    },
    sdgImagesPath () {
      return this.$store.getters.activeLocale === 'nl' ? '/images/SDG_nl/' : '/images/SDG/'
    },
    growthStageOptions () {
      // return [{label: 'Startup', value: 1}, {label: 'Start-up', value: 1} ,{label: 'Start-up', value: 1}]
      // return this.$store.state.config.stages
    },
    geographyOptions () {
      var geographyOptions = { ...CONTINENTS, ...COUNTRIES }

      var options = []

      Object.keys(geographyOptions)
          .forEach(key => {
            options.push({
              label: geographyOptions[key],
              value: key
            })
          })

      return options
    },
    canManageInviteOnly () {
      return this.$store.getters.canCreatePrivateChallenge
    },
    saveButtonLabel () {
      if (this.isEditMode) {
        if (this.challenge.curation === 'in_progress' && (this.isMember || (this.isPortfolioMember && this.$route.name === 'datalab'))) {
          return 'Approve & Update'
        } else {
          return this.$t('edit_challenge_button', { challenge: this.challengeLabel })
        }
      }
      return this.$t('add_challenge_save')
    },
    isEditMode () {
      return !!this.challenge.id
    },
  },
  methods: {
    toggleVisibilityOption (option) {
      this.challenge.invite_only = option.value
    },
    handleToggleSustainabilityGoal (sdgToToggle) {
      if (!this.isSdgEnabledForChallenge(sdgToToggle)) {
        this.challenge.sdgs = [...this.challenge.sdgs, sdgToToggle]
      } else {
        this.challenge.sdgs = this.challenge.sdgs.filter(sdg => sdgToToggle.value !== sdg.value)
      }
    },
    isSdgEnabledForChallenge (sdgToFind) {
      if (!this.challenge || !this.challenge.sdgs || !this.challenge.sdgs.length) {
        return false
      }
      return !!this.challenge.sdgs.find((s) => s.value === sdgToFind.value)
    },
    updateImage (image) {
      this.challenge.image = image
    },
    validateForm () {
      this.errors = []

      if (!this.challenge.description || this.challenge.description === '<p></p>') {
        this.errors.description = [this.$t('challenge_description_required')]
      }

      // if (!this.challenge.objective || this.challenge.objective === '<p></p>') {
      //   this.errors.objective = ['Objective is required']
      // }
      //
      if (!this.challenge.title) {
        this.errors.title = [this.$t('challenge_title_required')]
      }

      if (Object.keys(this.errors).length === 0) {
        return true
      }
    },
    async save () {
      trackHeapEvent('manageChallenge.save')

      if (!this.validateForm() || this.saving) {
        return
      }

      this.saving = true

      try {
        await Challenges.post(this.challenge)
        this.saving = false

        this.$bus.emit(this.isEditMode ? 'challengeUpdated' : 'challengeCreated')
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      } catch (errors) {
        this.saving = false
        console.log(errors)
        this.errors = errors
      }

      this.saving = false
    },
    async deleteChallenge () {
      await Challenges.delete(this.challenge.id)

      this.$bus.emit('challengeDeleted')
      trackHeapEvent('manageChallenge.remove')
      this.close()
    },
    remove () {
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
        name: this.challenge.title,
        resource: this.challenge,
        modalContextType: 'challenge',
      })
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
    },
    updateTags (tags) {
      this.challenge.tags = tags
    },
    close () {
      this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
    },
    handleTagChanged (tag) {
      // Update the options according to the given tag
      this.updateTagOptions(tag)
    },
    getSdgs () {
      //TODO @Jan: can we not replce this by
      // import { sustainabilityGoals } from '../../../constants/config.js'
      fetchSdgs()
          .then(response => {
            this.sdgOptions = response.map(function (sdg) {
              return { label: sdg.name, value: sdg.id }
            })
          })
    },
  },
  mounted () {
    if (this.challengeData) {
      this.challenge = {
        ...this.challengeData,
        ...{ originalImage: `/api/challenges/${this.challengeData.id}/image` },
      }
    }

    if (this.communityId && this.challenge.communityIds.length === 0) {
      this.challenge.communityIds.push(Number(this.communityId))
    }

    this.getSdgs()
  },
  created () {
    this.$bus.on('challengeDeleteConfirmation', (context) => {
      this.deleteChallenge(context.resource)
    })
  },
  beforeUnmount () {
    this.$bus.off('challengeDeleteConfirmation')
  },
  mixins: [TagsMixin, TranslationsMixin, UserMixin],
  components: {
    Checkbox,
    ActionBar,
    DsTextarea,
    ImageInput,
    AutocompleteTagInput,
    Dropdown,
    RichTextArea,
    Datepicker,
    ToggleButton,
  },
}
</script>

<style lang="scss" scoped>
@import "../../../scss/_variables";

.side-panel__announcement-form-container {
  :deep(.svg-icon--information-outline) {
    path {
      fill: #555;
    }
  }
}

.side-panel__challenge_subtitle {
  text-transform: uppercase;
  color: $color-text-grey-light;
  font-size: 16px;
}

.side-panel__challenge_invite_only {
  .button--outline {
    color: var(--primary);

    &:hover {
      color: white;
    }
  }
}

.side-panel__challenge_sdgs {
  width: 100%;
  display: flex;
  list-style-type: none;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;

  li {
    height: 0;
    width: 60px;
  }

  li.sdg-filter {
    cursor: pointer;
    height: 60px;
    margin-bottom: 1px;
    border: 4px solid transparent;

    &:hover {
      border: 4px solid $color-borders;
    }

    &.modal__body__active-filter-img {
      border: 4px solid var(--primary-light);
    }
  }

  img {
    width: 100%;
  }
}
</style>
