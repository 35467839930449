<template>
  <div class="with-hover-actions">
    <div class="with-hover-actions__actions">
      <slot name="actions"></slot>
    </div>
    <slot></slot>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'WithHoverActions'
  })
</script>

<style>
  .with-hover-actions {
    position: relative;
    width: 100%;
  }

  .with-hover-actions__actions {
    position: absolute;
    top: 1rem;
    right: 1rem;
    text-align: right;
  }
</style>
