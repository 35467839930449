<template>
  <div>
    <div class="row">
      <form-group class="col-xs-12 col-sm-5" label="Connection">
        <dropdown :options="types" v-model="relation" />
      </form-group>
      <form-group class="col-xs-12 col-sm-5" :label="expectedActor">
        <suggestion-input
          icon="search"
          :api-settings="suggestionSettings"
          :clear-on-select="false"
          @actor="actor = $event"
        />
        <span class="connection-successMsg" v-if="successMsg.length" v-text="successMsg"></span>
      </form-group>
      <form-group class="col-xs-12 col-sm-2" label=" ">
        <ds-button block :variant="actor ? 'secondary' : 'outline'" :disabled="isAddingRelation || !actor || !relation" label="Add" @click="create" />
      </form-group>
    </div>
  </div>
</template>


<script>
import Dropdown from '../Dropdown/Dropdown.vue';
import FormGroup from '../Form/FormGroup.vue';
import DsInput from '../Form/DsInput.vue';
import SuggestionInput from '../SuggestionInput/SuggestionInput.vue';

import { suggestionSettings } from '../../api/actors.js';

export default {
  props: {
    type: {
      default: 'accelerated_by'
    },
    isAddingRelation: {
      type: Boolean,
      default: false,
    },
    successMsg: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      relation: this.type,
      actor: null
    }
  },
  computed: {
    suggestionSettings,
    ecosystemRelationships () {
      return this.$store.getters.fullActorRelationships
    },
    expectedActor () {
      const type = this.ecosystemRelationships.find(t => t.name === this.relation) || {}
      return type.expects || this.$store.getters.actorString
    },
    types () {
      var types = this.ecosystemRelationships.filter(r => !['subsidiaries', 'main_company'].includes(r.name)).map(t => ({
        value: t.name,
        label: t.label || t.name
      })).reverse().sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0));

      return types;
    }
  },
  methods: {
    create () {
      this.$emit('create', this.relation, this.actor)
    }
  },
  components: {
    Dropdown,
    DsInput,
    FormGroup,
    SuggestionInput
  }
}
</script>
<style lang="scss">
  @import "../../../scss/_variables.scss";
  .connection-successMsg {
    color: $color-primary;
    font-size: 12px;
    font-family: $font-stack-primary;
  }
</style>
