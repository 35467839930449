<template>
  <div class="side-panel-actor" :class="{ 'side-panel-actor--visible': isVisible }">
    <card variant="actor-info">
        <ds-button
          class="side-panel-actor__header__close-button"
          icon="remove"
          variant="minimal"
          @click="hidePreview"
        />
        <div class="card__general side-panel-actor__header">
          <div class="card__ribbon" v-if="ribbonClass" :style="ribbonStyle"></div>
          <div class="card__logo">
            <avatar :src="companyLogoUrl" :alt="company.name" variant="border"></avatar>
          </div>
          <div class="card__title side-panel-actor__card__title">{{ company.name }}</div>
        </div>
      <!-- Commented until the side panel has more than 1 tab -->
      <!--<tabs :tabs="tabs" :saveToUrl="true" class="side-panel-actor__tab-section"></tabs>-->
      <div class="side-panel-actor__redirect-section">
        <ds-button
            icon="chevron-right"
            size="extra-small"
            label="View Full Page"
            variant="minimal"
            :to="redirectTo('#')"
          />
          <ds-button
            v-if="hasScores && isMember"
            icon="chevron-right"
            size="extra-small"
            label="Scores"
            variant="minimal"
            :to="redirectTo('#scores')"
          />
          <ds-button
            v-if="watchReports"
            icon="chevron-right"
            size="extra-small"
            label="Reports"
            variant="minimal"
            :href="redirectTo('#reports')"
          />
      </div>
      <div class="side-panel-actor__company-section">
        <div class="side-panel-actor__card__cover" :style="coverStyling"></div>
        <div class="side-panel-actor__company-info" v-if="company.id !== undefined">
            <div class="side-panel-actor__company-info__competidor-type side-panel-actor__company-info_text" v-if="company.category && company.category.length > 0">{{company.category}}</div>
            <div class="side-panel-actor__company-info__business-area side-panel-actor__company-info_text" v-if="company.industry && company.industry.length > 0">{{ company.industry[0] }}</div>
            <div class="side-panel-actor__company-info__small-title side-panel-actor__company-info_text" v-if="company.type && company.type.length > 0">{{company.type}}</div>
            <div class="side-panel-actor__company-info__description side-panel-actor__company-info_text" v-if="company.description && company.description.length > 0">{{company.description}}</div>
        </div>
      </div>
      <div class="card__info">
        <div class="side-panel-actor__competition-section" v-if="hasCompetitionScores">
          <h4 class="side-panel-actor__section-title">Competition</h4>
          <table class="table table--preview">
          <tbody>
            <tr>
              <td></td>
              <td class="text-right" v-for="date in mentionDates" v-text="toOnlyMonthString(date)" :title="date"></td>
            </tr>
            <tr v-for="(row, index) in mentionsTable" :class="{ 'text-bold': !index }">
              <td v-text="row.mentionProperty"></td>
              <td class="text-right" v-for="cell in row.values" v-text="cell"></td>
            </tr>
          </tbody>
        </table>
        </div>
        <div class="side-panel-actor__significant-change-section" v-if="getMostSignificantChange.length > 0">
          <h4 class="side-panel-actor__section-title">Significant Change</h4>
          <div>
            <icon name="arrow-graph"></icon>
          </div>
          <div class="side-panel-actor__significant-change"> {{getMostSignificantChange}}</div>
        </div>
        <div class="side-panel-actor__news-section" v-if="hasCompetitionScores && company.news && company.news.length > 0">
          <a :href="company.news[0].url" target="_blank">
            <h4 class="side-panel-actor__section-title">News</h4>
            <section>
              <div class="side-panel-actor__news-section__image" :style="newsImageStyle"></div>
            </section>
            <section>
              <div class="side-panel-actor__news-section__article-info">
                <h5 class="side-panel-actor__section-title">{{latestNewsPublisher}}</h5>
                <h5 class="side-panel-actor__news-date">{{latestNewsDate}}</h5>
                <h5 class="side-panel-actor__section-title">{{latestNewsTitle}}</h5>
              </div>
            </section>
          </a>
        </div>
        <div class="side-panel-actor__news-section" v-else-if="company.news && company.news.length > 0" v-for="(item,index) in 2">
          <a :href="latestActorNewsList[index].url" target="_blank">
            <h4 class="side-panel-actor__section-title">News</h4>
            <section>
              <div class="side-panel-actor__news-section__image" :style="newsImageStyle"></div>
            </section>
            <section>
              <div class="side-panel-actor__news-section__article-info">
                <h5 class="side-panel-actor__section-title">{{latestActorNewsList[index].publisher}}</h5>
                <h5 class="side-panel-actor__news-date">{{latestActorNewsList[index].date}}</h5>
                <h5 class="side-panel-actor__section-title">{{latestActorNewsList[index].title}}</h5>
              </div>
            </section>
          </a>
        </div>
        <!--<div class="side-panel-actor__last-update-section">
          <h4 class="side-panel-actor__section-title">Report Last Update</h4>
          <div>
            <icon name="tag"></icon>
          </div>
          <div> </div>
        </div>-->
      </div>
    </card>
  </div>
</template>

<script>
  import ActorUrls from '../ActorInfo/ActorUrls.vue'
  import Avatar from '../Avatar/Avatar.vue'
  import Card from '../../components/Card/Card.vue'
  import CardInfoSocial from '../../components/Card/CardInfoSocial.vue'
  import Keyword from '../../components/Keyword/Keyword.vue'
  import Tabs from '../Tabs/Tabs.vue'
  import CompanyMixin from '../../util/CompanyMixin'

  import KEYCODES from '../../constants/keycodes'
  import ICONS from '../../../icons/manifest.json'

  import { fetchSignificantChanges } from '../../api/heartbeat.js'

  import { toOnlyMonthString, toDateString } from '../../util/date'

  export default {
    name: 'side-panel-actor',
    data () {
      return {
        ICONS,
        mostSignificantChange: [],
        hasCompetitionScores: false
      }
    },
    props: {
      actor: Object
    },
    computed: {
      hasScores () {
        return this.$store.getters.hasScores
      },
      isMember () {
        return this.$store.getters.isMember
      },
      company () {
        if (this.actor.id !== undefined || this.actor.id !== null) {
          return this.actor
        }
      },
      isVisible () {
        return this.$store.state.ui.actorPreview.isVisible
      },
      getMostSignificantChange () {
        return this.mostSignificantChange
      },
      watchReports () {
        if (this.company.id !== undefined) {
          if (this.company.current_report[0] !== undefined) {
            if (this.company.current_report[0].created_at !== undefined || this.company.current_report[0].created_at !== '') {
              return this.company.current_report.length > 0
            }
          }
        }
      },
      getLatestReport () {
        // return this.company.reports[0].reports[0].value
      },
      mentionsHistory () {
        const actor = this.company
        if (actor !== undefined) {
          if (actor.won_lost_mentions_history) {
            return actor.won_lost_mentions_history || []
          }
        }
      },
      mentionDates () {
        if (this.mentionsHistory) {
          return this.mentionsHistory.map(c => Date.parse(c.date))
        }
      },
      mentionsTable () {
        const mentionProperties = ['mentions', 'losses', 'wins', 'churned']
        const mentionLabels = {
          churned: 'Customer lost',
          losses: 'Deals lost',
          mentions: 'Mentioned in deals',
          wins: 'Deals won',
        }

        if (this.mentionsHistory) {
          if (this.mentionsHistory.length > 0) {
            return mentionProperties.map(mentionProperty => ({
              mentionProperty: mentionLabels[mentionProperty],
              values: this.mentionsHistory.map(s => s[mentionProperty])
            }))
          } else {
            this.hasCompetitionScores = false
          }
        }
      },
      coverStyling () {
        if (this.featuredImage) {
          return { backgroundImage: `url(${this.featuredImage})` }
        }

        if (this.websiteScreenshot === this.getDefaultFallbackImageUrl()) {
          return { display: 'none' }
        }

        return { backgroundImage: `url(${this.websiteScreenshot})` }
      },
      newsImageStyle () {
        if (!this.company.news || !this.company.news[0].screenshot) {
          // TODO: logo or default image
        }
        return { backgroundImage: `url(${this.company.news[0].screenshot})` }
      },
      latestActorNewsList () {
        if (!this.company.news || !this.company.news[0]) {
          return
        }
        return this.company.news
      },
      latestActorNewsItem () {
        if (!this.company.news || !this.company.news[0]) {
          return
        }
        return this.company.news[0]
      },
      latestNewsDate () {
        if (!this.latestActorNewsItem || !this.latestActorNewsItem.date) {
          return
        }
        return toDateString(this.latestActorNewsItem.date)
      },
      latestNewsTitle () {
        if (!this.latestActorNewsItem || !this.latestActorNewsItem.title) {
          return
        }
        return this.latestActorNewsItem.title
      },
      latestNewsPublisher () {
        if (!this.latestActorNewsItem || !this.latestActorNewsItem.publisher) {
          return
        }
        return this.latestActorNewsItem.publisher
      }
    },
    methods: {
      toOnlyMonthString,
      hidePreview (evt) {
        this.$emit('closePanel', true)
      },
      redirectTo (destination) {
        if (destination == '#scores') {
          return '/actors/' + this.company.id + '/scores'
        } else {
          return '/actors/' + this.company.id + destination
        }
      }
    },
    created () {
      window.addEventListener('keyup', (e) => {
        if (e.keyCode === KEYCODES.ESC) {
          this.hidePreview()
        }
      })
      this.company
    },
    watch: {
      actor (val) {
        fetchSignificantChanges(this.actor.id).then(data => {
          if (data.data && data.data.length > 0) {
            this.mostSignificantChange = data.data[0].message
          } else {
            this.mostSignificantChange = []
          }
        })
      },
      mentionsTable (val) {
        if (val) {
          this.hasCompetitionScores = true
        }
      }
    },
    mixins: [CompanyMixin],
    components: {
      ActorUrls,
      Avatar,
      Card,
      CardInfoSocial,
      Keyword,
      Tabs
    }
  }
</script>
