<template>
  <div class="community-dropdown__container">
    <autocomplete-dropdown
      @update:modelValue="updateSelectedCommunity"
      :do-search="searchAndReturnCommunityOptions"
      placeholder="Search for a community"
      :noResultsHandlerEnabled="false"
      :model-value="this.modelValue"
      :required="true"
      :multiple="false"
      :allowClear="true"
    />
  </div>
</template>

<script>
  import AutocompleteDropdown from '../Dropdown/AutocompleteDropdown.vue'
  import { Communities } from '../../api/communities.js'

  export default {
    name: 'CommunityDropdown',
    emits: ['selected-community-changed'],
    props: ['initialCommunityId'],
    data () {
      return {
        communityOptions: [],
        modelValue: null
      }
    },
    methods: {
      updateSelectedCommunity (community) {
        if (Array.isArray(community) || !community || !community.value) {
          community = null
        }

        this.modelValue = community
        this.$emit('selected-community-changed', community)
      },
      searchAndReturnCommunityOptions (query) {
        return Communities
          .get('', { query })
          .then(response => {
            const options = []

            response.forEach(function (community) {
              options.push({
                label: community.name,
                value: community.id
              })
            })

            return options
          })
      }
    },
    components: {
      AutocompleteDropdown
    },
    async mounted () {
      if (this.initialCommunityId) {
        const community = await Communities.find(this.initialCommunityId)

        if (community.name) {
          this.modelValue = { value: community.id, label: community.name }
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .community-dropdown__container {
    max-width: 15rem;
    width: 15rem;
  }
</style>
