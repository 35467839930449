import { createJSONRequest } from './create-request'
import querify from '../util/querify'

export const fetchGroup = (group, filters) => createJSONRequest({
  url: `/api/actors?group=${group}&${querify(filters)}`,
  method: 'GET'
})

export const getTopCities = (filters) => createJSONRequest({
  url: `/api/actors?group=cities&${querify(filters)}`,
  method: 'GET'
})

export const getTypes = (filters) => createJSONRequest({
  url: `/api/actors?group=type&${querify(filters)}`,
  method: 'GET'
})

export const getCategories = (filters) => createJSONRequest({
  url: `/api/actors?group=category&${querify(filters)}`,
  method: 'GET'
})

export const getFoundingHistogram = (filters) => createJSONRequest({
  url: `/api/actors?group=histogram&${querify(filters)}`,
  method: 'GET'
})

export const getFundingHistogram = (filters) => createJSONRequest({
  url: `/api/actors?group=funding&${querify(filters)}`,
  method: 'GET'
})

export const getStage = (filters) => createJSONRequest({
  url: `/api/actors?group=stage&${querify(filters)}`,
  method: 'GET'
})

export const getAccelerators = (filters) => createJSONRequest({
  url: `/api/actors?group=accelerator&${querify(filters)}`,
  method: 'GET'
})

export const getFinancials = (filters, field) => createJSONRequest({
  url: `/api/actors?group=financials&${querify(Object.assign({}, filters, { field }))}`,
  method: 'GET'
})

export const getCompletion = (filters) => createJSONRequest({
  url: `/api/actors?group=completion`,
  method: 'GET'
})

export const fetchChart = (type, filters) => createJSONRequest({
  url: `/api/charts/${type}?${querify(filters)}`,
  method: 'GET'
})

export const fetchCharts = (filters) => createJSONRequest({
  url: `/api/charts?${querify(filters)}`,
  method: 'GET'
})

export const updateCharts = data => createJSONRequest({
  url: `/api/charts`,
  data
})

export const fetchAnalytics = (filters) => createJSONRequest({
  url: `/api/charts?chartType=analytics&${querify(filters)}`,
  method: 'GET'
})
