<template>
  <div>
    <ds-input
      v-model="actors.searchQuery" :placeholder="placeholder"
      :is-simplified="isSimplified" :disabled="disabled"
      @update:modelValue="fetchActorsDebounced" class="limited-width" v-if="allowInput"
      :icon="isSimplified ? 'search' : ''"/>
    <div
      class="search-actor-list"
      v-if="actors.searchQuery"
      :class="{'without-children': !showResults && !actors.searchQuery && !actors.searching && !notAddedActors.length}">
      <actor-block
        class="no-description" v-if="allowAdd && actors.searchQuery" mode="add"
        name="Create new actor" @add="clickAddNewActor" allow-input/>
      <template v-if="showResults">
        <actor-block
          v-for="actor in parsedValue"
          :key="actor.id"
          mode="remove"
          :id="actor.id"
          :name="actor.name"
          :class="{'no-description': !actor.description}"
          :description="actor.description"
          :url="getActorLink(actor)"
          @remove="denyActorRecommendation"
          :allow-input="allowInput"
        />
      </template>
      <div class="search-indicator" v-if="actors.searching">
        <icon name="spinner" style="vertical-align: middle;"/>
        Searching...
      </div>
      <div v-else>
        <actor-block
          v-for="actor in notAddedActors"
          :key="actor.id"
          mode="add"
          :disabled="editedActorIds.includes(actor.id)"
          :id="actor.id"
          :name="actor.name"
          :class="{'no-description': !actor.description}"
          :description="actor.short_description"
          :url="getActorLink(actor)"
          @add="onAddActor"
          :allow-input="allowInput"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import ActorBlock from '../Dashboard/EmailReports/ActorBlock.vue'
  import debounce from 'lodash/debounce.js'
  import {
    getSearchResultsForQueryType,
  } from '../../api/general-search.js'
  import { mapNewGlobalSearchResultToLegacyFormat } from '../../util/helpers'
  import { defineComponent } from 'vue'

  export default defineComponent({
    data () {
      return {
        actors: {
          searchQuery: '',
          lastUsedSearchQuery: '',
          searching: false,
          results: [],
        },
      }
    },
    emits: ['input-json-string', 'add-new-actor', 'remove-actor'],
    props: {
      placeholder: {
        type: String,
        default: 'Search for actors…',
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      isSimplified: {
        type: Boolean,
        default: false,
      },
      modelValue: {
        required: true,
      },
      allowAdd: {
        type: Boolean,
        default: false,
      },
      showResults: {
        type: Boolean,
        default: true,
      },
      allowInput: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      editedActorIds () {
        return this.parsedValue.map(a => a.id)
      },
      notAddedActors () {
        return this.actors.results.filter(a => !this.editedActorIds.includes(a.id))
      },
      parsedValue: {
        get () {
          if (!this.modelValue || this.modelValue === '{}' || this.modelValue === '[]') {
            return []
          }
          return JSON.parse(this.modelValue)
        },
        set (value) {
          if (value) {
            if (!value.length) {
              this.$emit('input-json-string', '')
            } else {
              this.$emit('input-json-string', JSON.stringify(value))
            }
          }
        },
      },
    },
    methods: {
      clickAddNewActor () {
        this.$emit('add-new-actor')
        this.actors.searchQuery = ''
        this.actors.searching = false
        this.actors.results = []
      },
      onAddActor (id) {
        this.addActor(id)
        this.actors = {
          searchQuery: '',
          lastUsedSearchQuery: '',
          searching: false,
          results: [],
        }
      },
      addActor (id) {
        const actor = this.actors.results.find(a => a.id === id)
        if (actor === undefined) {
          return
        }

        this.parsedValue = this.parsedValue.concat({
          id: actor.id,
          name: actor.name,
          description: actor.short_description,
        })
      },
      denyActorRecommendation (id) {
        this.$emit('remove-actor', id)
        this.parsedValue = this.parsedValue.filter(actor => actor.id !== id)
      },
      fetchActors () {
        if (this.actors.lastUsedSearchQuery === this.actors.searchQuery || this.actors.searchQuery.length <= 2) {
          return
        }

        this.actors.lastUsedSearchQuery = this.actors.searchQuery
        this.actors.searching = true

        getSearchResultsForQueryType({ query: this.actors.searchQuery, type: 'actors' })
          .then(result => {
            const combinedActorsWithoutDuplicates = mapNewGlobalSearchResultToLegacyFormat([...result.actors, ...result.products])

            this.actors.results = combinedActorsWithoutDuplicates.filter(result => result.id !== this.$route.params.id).slice(0, 10)
            this.actors.searching = false
          })
          .catch(error => {
            this.actors.searching = false
            throw error
          })
      },
      fetchActorsDebounced: debounce(function () {
        this.fetchActors()
      }, 500),
      getActorLink (actor) {
        return window.location.origin + '/actors/' + actor.id
      },
    },
    components: {
      ActorBlock,
    },
  })
</script>

<style lang="scss" scoped>
  .search-actor-list {
    max-height: 380px;
    overflow-y: scroll;
    margin-top: 2px;
    background-color: white;
    width: 54%;
    position: absolute;
    z-index: 901;
    padding: 15px;


    &.without-children {
      padding-top: 0;
      margin-top: 0;
    }
  }

  :deep(.ds-input) {
    &.is-simplified {
      input {
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px
      }
    }
  }

  .no-description {
    :deep(.title) {
      margin-top: 11px;
    }
  }
</style>
