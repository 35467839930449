<template>
  <div>
    <div v-if="! hasStripeKeys">
      <span>Before you can start building packages, we need you to fill in the <a href="/settings/integrations#stripe">Stripe configuration settings</a></span>
    </div>

    <div v-else>
      <div class="pull-right side-panel__announcement-header-button-container" style="text-align: right;">
        <dropdown v-model="selectedDropdownOption" :options="availablePackages" style="width: 20rem" v-if="availablePackages.length > 0"/>
        <ds-button label="Create package" icon="plus" @click="createNewPackage()" v-if="availablePackages.length < 4"/>
        <ds-button icon="trash" @click="destroy(selectedPackage)" v-if="selectedPackage && selectedPackage.id > 0"/>
      </div>

      <div v-if="selectedPackage.id !== -1">
        <h3 class="h3">Package Information</h3>
        <div class="ecosystem-member-packages__general-container">
          <div class="ecosystem-member-packages__general-info">
            <form-group label="Title" class="ecosystem-member-packages__general-input">
              <ds-input v-model="selectedPackage.title"/>
            </form-group>

            <form-group label="Subtitle" class="ecosystem-member-packages__general-input">
              <ds-input v-model="selectedPackage.sub_title"/>
            </form-group>

            <form-group label="Price per month in €" class="ecosystem-member-packages__general-input">
              <template v-if="isSelectedPackageNew">
                <ds-input v-model="selectedPackage.price"/>
              </template>
              <template v-else>
                <div class="ecosystem-member-packages__price-edit">
                  <ds-input v-model="selectedPackage.monthly_price"/>
                  <div v-if="monthlyPrice(selectedPackage) != selectedPackage.monthly_price" class="ecosystem-member-packages__price-edit-warning">Please be aware that changing the
                    price will not
                    affect existing subscriptions, only new subscriptions will be using this price.
                  </div>
                </div>
              </template>
            </form-group>

            <form-group label="Trial Period" class="ecosystem-member-packages__general-input" style="margin-bottom: 10px;">
              <dropdown :options="trialPeriodOptions" v-model="selectedPackage.trial_period" :disabled="!canTrialBeUpdated"/>
            </form-group>
            <span class="ecosystem-member-packages__trial-help-text">
              You can only have 1 package with a trial period and the price needs to be higher than 0.
            </span>

          </div>
        </div>

        <div class="scrollable">
          <h1 class="h1" style="margin-bottom: 0.5rem;">Package Permissions</h1>
          <ecosystem-member-package-permissions :EMPPermissions="selectedPackage.permissions" @permissionsUpdated="updatePermissions"
                                                @updateAccessibleSpottingAreas="updateAccessibleSpottingAreas" @updateAccessiblePortfolios="updateAccessiblePortfolios"/>
        </div>

        <div v-if="selectedPackage.id !== -1">
          <action-bar :editing="true" v-if="isSelectedPackageNew">
            <ds-button variant="secondary" label="Create Package" @click="storeNewPackage()" :disabled="!canStoreNewPackage || saving"/>
            <span class="action-bar__message" v-if="errors && errors.message">{{ errors.message }}</span>
          </action-bar>
          <action-bar :editing="true" v-else>
            <ds-button variant="secondary" label="Update Package" @click="updatePackage()" :disabled="saving"/>
            <span class="action-bar__message" v-if="errors && errors.message">{{ errors.message }}</span>
            <span class="action-bar__message" v-else-if="saved && !saving">Package has been updated</span>
          </action-bar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { EcosystemMemberPackages } from '../../../api/ecosystem-member-packages'
  import { emptyConfig } from '../../../util/config'
  import { propertyGroups } from '../../../constants/config'

  import Checkbox from '../../Form/Checkbox.vue'
  import EcosystemMemberPackagePermissions from './EcosystemMemberPackagePermissions.vue'
  import ActionBar from '../../Form/ActionBar.vue'
  import Dropdown from '../../Dropdown/Dropdown.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui'
  import MODAL_IDS from '../../../constants/modal-ids'

  export default {
    name: 'EcosystemMemberPackageManagement.vue',
    data () {
      return {
        packages: [],
        selectedPackage: {
          id: -1,
          title: null,
          sub_title: '',
          price: 0,
          trial_period: 0,
          permissions: {},
        },
        saving: false,
        errors: {},
        saved: false,
      }
    },
    computed: {
      trialPeriodOptions () {
        var options = []

        options.push({
          value: 0,
          label: 'No trial period',
        })

        for (var i = 1; i <= 8; i++) {
          options.push({
            value: i,
            label: i + (i === 1 ? ' week trial' : ' weeks trial'),
          })
        }

        return options
      },
      canTrialBeUpdated () {
        if (this.packageWithTrial && this.packageWithTrial !== this.selectedPackage.id && this.selectedPackage.trial_period === 0) {
          return false
        }

        return this.selectedPackage.monthly_price > 0 || this.selectedPackage.trial_period > 0
      },
      packageWithTrial () {
        var EMPackage = this.packages.filter(p => p.trial_period > 0)

        console.log(EMPackage)
        if (!EMPackage.length) {
          return
        }

        return EMPackage[0].id
      },
      availablePackages () {
        if (!this.packages || this.packages.length === 0) {
          return []
        }

        return this.packages.map(EMPackage => { // package is a reserved JS word
          return {
            value: EMPackage.id,
            label: EMPackage.title,
          }
        })
      },
      hasStripeKeys () {
        return this.$store.getters.hasStripeKeys
      },
      defaultPermissions () {
        return this.$store.getters.ecosystemMemberPermissions
      },
      isSelectedPackageNew () {
        return this.selectedPackage && !this.selectedPackage.id
      },
      canStoreNewPackage () {
        return this.selectedPackage
          && this.selectedPackage.title
          && this.selectedPackage.title.length > 0
          && this.selectedPackage.sub_title
          && this.selectedPackage.sub_title.length > 0
      },
      selectedDropdownOption: {
        get () {
          if (!this.selectedPackage || !this.selectedPackage.id) {
            return null
          }

          return {
            value: this.selectedPackage.id,
            label: this.selectedPackage.title,
          }
        },
        set (packageId) {
          var EMPackage = this.packages.find(p => p.id == packageId)

          this.selectedPackage = EMPackage
          this.errors = {}
        },
      },
    },
    methods: {
      destroy (EMPackage) {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          name: EMPackage.title,
          resource: EMPackage,
          modalContextType: 'ecosystem-member-package',
        })

        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
      },
      storeNewPackage () {
        this.saving = true

        var newPackage = Object.assign({}, this.selectedPackage)
        newPackage['prices'] = [
          {
            billing_cycle: 'month',
            price: this.selectedPackage.price,
          },
          {
            billing_cycle: 'year',
            price: this.selectedPackage.price * 12,
          },
        ]

        EcosystemMemberPackages.post(newPackage)
          .then(response => {
            var packageId = response.id

            EcosystemMemberPackages
              .get()
              .then(response => {
                this.packages = response
                this.selectedDropdownOption = packageId
              })
              .catch(errors => {
                console.log(errors)
              })

            this.saving = false
            this.$emit('packagesUpdated')
          })
          .catch(errors => {
            this.errors = errors
            this.saving = false
          })
      },
      updatePackage () {
        this.saving = true
        this.saved = false
        this.errors = {}

        var packageId = this.selectedPackage.id

        // Update the permission for accessible spotting areas
        let accessibleSpottingAreas = []
        if (this.selectedPackage.permissions && this.selectedPackage.permissions.accessibleSpottingAreas) {
          accessibleSpottingAreas = this.selectedPackage.permissions.accessibleSpottingAreas.filter(spa => spa) ?? []
        }

        if (accessibleSpottingAreas.length > 0) {
          this.selectedPackage.permissions.accessibleSpottingAreas = accessibleSpottingAreas.map(spottingArea => {
            if (!spottingArea) {
              return
            }

            return {
              text: spottingArea.text,
              id: spottingArea.id
            }
          })
        }

        EcosystemMemberPackages.post(this.selectedPackage)
          .then(response => {
            EcosystemMemberPackages
              .get()
              .then(response => {
                this.packages = response
                this.selectedDropdownOption = packageId
              })
              .catch(errors => {
                console.log(errors)
              })

            this.$emit('packagesUpdated')

            this.saved = true
            this.saving = false
          })
          .catch(errors => {
            this.errors = errors
            this.saving = false
          })
      },
      deletePackage (packageId) {
        EcosystemMemberPackages.delete(packageId)
          .then(response => {
            this.fetchPackages(true)
          })
          .catch(error => {
            console.log('Something went wrong', error)
          })
      },
      fetchPackages (selectFirst = false) {
        EcosystemMemberPackages
          .get()
          .then(response => {
            this.packages = response

            if (selectFirst && this.packages.length > 0) {
              this.selectedDropdownOption = this.packages[0].id
              this.selectedPackage = this.packages[0]
            }
          })
          .catch(errors => {
            console.log(errors)
          })
      },
      createNewPackage () {
        var newPermissions = this.createNewPermissions()

        this.selectedPackage = {
          title: 'New Package',
          sub_title: 'New Package subtitle',
          price: 0,
          permissions: newPermissions,
          id: null,
          trial_period: 0,
        }

        this.saving = false
        this.saved = false
        this.errors = {}
      },
      createNewPermissions () {
        // Empty permissions for reactivity
        var permissions = Object.assign(emptyConfig('actor'), {

          // Add defaults that MUST override the parent config
          // Also, let's copy the parent view config
          public_fields: propertyGroups.Base.slice(),
          viewActorTypes: (this.defaultPermissions.viewActorTypes || ['LegalEntity']).slice(),
          viewDashboard: this.defaultPermissions.viewDashboard || [],
          viewGallery: this.defaultPermissions.viewGallery || false,
          viewList: this.defaultPermissions.viewList || [],
          viewMap: this.defaultPermissions.viewMap || [],
          filterControls: this.defaultPermissions.filterControls || [],
          allowAllFilters: this.defaultPermissions.allowAllFilters || false,
          allowAllFiltersProducts: this.defaultPermissions.allowAllFiltersProducts || false,
          defaultLegendProperty: this.defaultPermissions.defaultLegendProperty,
          availableLegendItems: Object.assign({}, this.defaultPermissions.availableLegendItems),
        })

        permissions['viewDashboard'] = permissions['viewDashboard'].filter(d => !['buzz', 'connections', 'media-buzz', 'explore-content'].includes(d))

        delete permissions['publisher']

        return permissions
      },
      updatePermissions (value) {
        this.selectedPackage.permissions = value
      },
      updateAccessibleSpottingAreas (value) {
        this.selectedPackage.permissions.accessibleSpottingAreas = value
      },
      updateAccessiblePortfolios (value) {
        this.selectedPackage.permissions.accessiblePortfolios = value
      },
      monthlyPrice (packageOption) {
        var monthlyPrice = packageOption.prices[0].price

        packageOption.prices.forEach(price => {
          if (price.billing_cycle === 'month') {
            monthlyPrice = price.price
          }
        })

        return monthlyPrice
      },
    },
    components: {
      Checkbox,
      EcosystemMemberPackagePermissions,
      ActionBar,
      Dropdown,
    },
    mounted () {
      this.fetchPackages(true)

      this.$bus.on('confirmDeleteEcosystemMemberPackage', (context) => {
        this.deletePackage(context.resource.id)
      })
    },
    beforeUnmount () {
      this.$bus.off('confirmDeleteEcosystemMemberPackage')
    },
  }
</script>

<style scoped lang="scss">
  @import "../../../../scss/_variables.scss";

  .ecosystem-member-packages__general-input {
    width: 20em;
  }

  .ecosystem-member-packages__general-container {
    display: flex;
    flex-direction: row;
  }

  .ecosystem-member-packages__general-info {
    display: flex;
    flex-direction: column;
  }

  .ecosystem-member-packages__features {
    display: flex;
    margin-left: 1em;
  }

  .ecosystem-member-packages__price-edit-warning {
    margin-top: 0.5rem;
    color: $color-error;
  }

  .ecosystem-member-packages__trial-help-text {
    display: flex;
    font-size: 12px;
    color: $color-text-grey-light;
    margin-bottom: 0.5rem;
  }
</style>
