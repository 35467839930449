<template>
  <modal
    v-if="contributors"
    :title="$t('actor_contributors')"
    @close="close"
    close-on-blur is-closeable>
    <template v-slot:body>
      <form @submit.prevent="inviteContributor(contributors.email)">
        <form-group
          :label="$t('actor_contributors_invite')"
          :error="contributors.errors && (contributors.errors.email || contributors.errors.message)">
          <ds-input
            v-model="contributors.email"
            :placeholder="$t('actor_contributors_email')"/>
        </form-group>
        <checkbox
          :label="$t('edit_actor_segment_allow_this_contributor_to_be_contacted')"
          v-model="canBeContacted"
        />
        <ds-button
          style="margin-top: 10px;"
          type="submit" variant="secondary"
          :label="$t('actor_contributors_invite_contributor')"/>
        <br><br>
        <p v-if="contributors.message">
          {{ contributors.message }}
        </p>
        <div class="contributor" v-for="user in contributors.list">
          <span
            v-if="isOwner || userIsActorOwner"
            class="contributor__remove"
            role="presentation"
            @click="removeContributor(user.user_id)">&times;</span>
          {{ user.user_email }}
        </div>
      </form>
    </template>
  </modal>
</template>


<script>
  import Modal from './Modal.vue'
  import { fetchContributors, inviteContributor, removeContributor, updateContactableContributors } from '../../api/actors.js'
  import Checkbox from '../Form/Checkbox.vue'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'AddContributorModal',
    props: {},
    data () {
      return {
        canBeContacted: true,
        contributors: { email: '', list: [], errors: {}, message: null },
      }
    },
    emits: ['close'],
    computed: {
      modalContext () {
        return this.$store.state.ui.modalContext
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      userIsActorOwner () {
        if (this.$store.getters.isActor) {
          const claim = this.$store.state.user.profile.claims.find(claim => claim.id === this.$route.params.id) || {}
          return claim.claim_type === 'actor-owner' && claim.claim_verified
        }

        return this.$store.getters.userClaimedIds.includes(this.$route.params.id)
      },
    },
    methods: {
      close () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.ADD_CONTRIBUTOR_MODAL)
        this.$emit('close')
      },
      removeContributor (userId) {
        removeContributor({ user_id: userId, actorId: this.$route.params.id })
          .then(data => {
            Object.assign(this.contributors, { errors: {}, message: null }, data)
            this.showContributors()
            this.$bus.emit('updateContributors')
          })
          .catch(this.handleContributorError)
      },
      inviteContributor (email) {
        inviteContributor({ email, actor_id: this.$route.params.id })
          .then(data => {
            Object.assign(this.contributors, {
              errors: {},
              message: null,
              email: '',
            }, data)
            if (data.id && this.canBeContacted) {
              const contributorsThatAllowConversation = this.contributors.list.filter(contributor => contributor.allow_conversation)
              const contributorIdsThatAllowConversation = contributorsThatAllowConversation.map(contributor => contributor.user_id)
              updateContactableContributors(this.$route.params.id, [...contributorIdsThatAllowConversation, data.id])
            }
            this.$bus.emit('updateContributors')
            this.showContributors()
          })
          .catch(this.handleContributorError)
      },
      showContributors () {
        if (!this.contributors) {
          this.contributors = { email: '', list: [], errors: {}, message: null }
        }
        fetchContributors(this.$route.params.id, { includeOwners: false })
          .then(list => {
            Object.assign(this.contributors, { list })
          })
          .catch(this.handleContributorError)
      },
      handleContributorError (errors) {
        if (errors && errors.message === 'Unexpected error') {
          errors.message = 'You can only add contributors to published profiles.'
        }
      },
    },
    components: {
      Modal,
      Checkbox,
    },
    mounted () {
      this.showContributors()
    },
    mixins: [],
  })
</script>
