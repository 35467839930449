<template>
  <div class="chat-focus__button">
    <DsButton
      icon="filter"
      variant="accent"
      :disabled="disabled"
      style="margin-right: 0.25rem;"
      @click="toggleContainer"
      @blur="hideContainer"
    />

    <Card v-if="showContainer" class="chat-focus__button-container">
      <DsButton
        icon="agent-head"
        :variant="activeChatFocus === RAGFocus.none ? 'primary' : ''"
        v-tooltip.top="'Automatic focus'"
        @click="updateChatFocus(RAGFocus.none)"
      />

      <DsButton
        icon="building"
        :variant="activeChatFocus === RAGFocus.organisations ? 'primary' : ''"
        v-tooltip.top="'Focus on actors'"
        @click="updateChatFocus(RAGFocus.organisations)"
      />

      <DsButton
        icon="repository-icon"
        :variant="activeChatFocus === RAGFocus.content ? 'primary' : ''"
        v-tooltip.top="'Focus on content'"
        @click="updateChatFocus(RAGFocus.content)"
      />
    </Card>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import Card from '@/components/Card/Card.vue'
  import DsButton from '@/components/DsButton/DsButton.vue'
  import { ChatFocus } from '@/Domain/Chat/Enums/enums'

  export default defineComponent({
    name: 'ChatFocusButtonContainer',
    components: {
      Card,
      DsButton,
    },
    computed: {
      RAGFocus() {
        return ChatFocus
      },
    },
    emits: ['update-chat-focus'],
    props: {
      disabled: {
        type: Boolean,
        default: false,
      },
      activeChatFocus: {
        type: String,
        required: true,
      },
    },
    data() {
      return {
        showContainer: false,
      }
    },
    methods: {
      hideContainer() {
        setTimeout(() => {
          this.showContainer = false
        }, 300)
      },
      toggleContainer() {
        this.showContainer = !this.showContainer
      },
      updateChatFocus(buttonLabel: string) {
        this.$emit('update-chat-focus', buttonLabel)
      },
    },
  })
</script>

<style lang="scss" scoped>
  .chat-focus__button {
    position: relative;       // relative
    display: inline-block;    // inline-block
  }

  .chat-focus__button-container {
    width: auto !important; // !w-auto
    position: absolute; // absolute
    bottom: 100%; // bottom-full
    display: flex; // tw-flex
    padding: 0.5rem !important; // !p-2 (p-2 corresponds to 0.5rem)
    left: 0; // left-0
    margin-bottom: 0.5rem; // mb-2 (mb-2 corresponds to 0.5rem)
    background-color: white; // bg-white
    border: 1px solid #e5e7eb; // border (default border color in Tailwind is #e5e7eb)
    border-radius: 0.25rem; // rounded (rounded corresponds to 0.25rem radius)
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); // shadow-md
  }

</style>
