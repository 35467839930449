<template>
  <modal :id="modalId" ref="modal" :enable-mask="true" wrapperSize="flex" size="flex"
         :is-closeable="true" class="full-screen-modal" @close="close">
    <template v-slot:body>
      <div class="map-modal__container">
        <ds-button class="map-modal__close" @click="closeModal" label="×"/>
        <div class="map-modal__map">
          <map-view-allow-multiple
            :allow-multiple-maps="true"
            :data="data"
            :getMapData="modalContext.getMapData"
            :getData="modalContext.getMapData"
            :disable-mouse-wheel-zoom="true"
            :noSearchWhileMoving="true"
            :disable-expand-button="true"
          />
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import MapViewAllowMultiple from '../MapView/MapViewAllowMultiple.vue'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'

  export default {
    name: 'map-modal',
    props: {},
    data () {
      return {
        modalId: MODAL_IDS.MAP,
      }
    },
    computed: {
      modalContext () {
        return this.$store.state.ui.modalContext || {}
      },
      data () {
        return this.modalContext.data
      },
    },
    methods: {
      close () {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, this.modalId)
        // When closing the modal we reset to modal context.
        this.$store.commit('UI/SET_MODAL_CONTEXT', null)
      },
      closeModal () {
        this.$refs.modal.close()
      },
    },
    mounted () {
      this.modalContext.getMapData()
    },
    components: {
      MapViewAllowMultiple,
      Modal,
    },
  }
</script>

<style lang="scss" scoped>
  :deep(.modal__body) {
    margin: 0;
  }

  :deep(.modal__wrapper) {
    max-height: 100%;
  }

  :deep(.modal__container) {
    padding: 0
  }

  .full-screen-modal {
    .map-modal__map {
      height: 100%;
    }
  }
  .map-modal__container {
    position: relative;

    .map-modal__map {
      width: calc(100vw - 60px);
      height: calc(100vh - 105px);
    }
  }


  .map-modal__close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
    z-index: 1;
    cursor: pointer;

    :deep(.button__label) {
      font-size: 30px;
    }
  }
</style>
