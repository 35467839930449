import _differenceBy from 'lodash/differenceBy'
import _sortBy from 'lodash/sortBy'

import { fetchRecentNotifications } from '../api/notifications'
import { updateProfile } from '../api/user'

import debounce from 'lodash/debounce'

// Return the background color for the ribbon for the notification dropdown entry
export function notificationColor (notificationType) {
  return 'background-color: #1FDCB8;'
}

// Return the name of the icon to be used
export function notificationIconName (notificationType, notificationEvent) {
  if (notificationEvent == 'user_has_in_app_message') {
    return 'newsletter'
  }

  return 'notification-label'
}

// Return the notification class that holds the color fill for the icon
export function notificationColorClass (notificationType) {
  return 'icon--datascouts'
}

export const NotificationsMixin = {
  data () {
    return {
      'cache$notifications': window.initialNotifications || {
        notifications: [],
        displayedNotifications: []
      }
    }
  },
  computed: {
    unseenNotifications () {
      const { notifications } = this.cache$notifications

      const lastRead = this.$store.state.user.profile.last_read_announcements_at
      return notifications.filter(n => n.created_at > lastRead)
    },
    unreadNotificationsCount () {
      return this.$store.state.user.profile.unreadNotificationsCount
    },
    hasUnseenNotifications () {
      return this.$store.getters.isLoggedIn && this.unseenNotifications.length > 0
    },
    unreadNotifications () {
      const { notifications } = this.cache$notifications
      return _sortBy(notifications, 'created_at').reverse()
    },
    hasUnreadNotifications () {
      return this.$store.getters.isLoggedIn && this.unreadNotifications.length > 0
    }
  },
  methods: {
    displayNotifications () {
      var notifications = this.unseenNotifications.slice()
      var displayShowall = false

      if (notifications.length > 2) {
        notifications = notifications.splice(0, 2)
        notifications.push({
          'title': 'Show all notifications',
          'id': ''
        })
      }

      if (notifications.length) {
        this.$notify({
          clean: true,
          group: 'alerts'
        })

        notifications.map(item => {
          var cta = item.id

          if (item.action_url && item.action_url.startsWith('http')) {
            cta = item.action_url
          }

          this.$notify({
            title: item.title,
            text: cta,
            type: item.type,
            duration: 7000,
            group: 'alerts'
          })
        })
      }

      // Update the last_read_announcements_at timestamp
      this.markAsSeen()
    },
    fetchRecentNotifications () {
      if (this.$store.getters.isLoggedIn) {
        fetchRecentNotifications()
          .then(({ unreadNotificationsCount, last_read_announcements_at, notifications }) => {
            if (last_read_announcements_at) {
              this.$store.commit('USER/UPDATE_PROFILE', { last_read_announcements_at })
            }

            this.$store.commit('USER/UPDATE_PROFILE', { unreadNotificationsCount })

            if (Array.isArray(notifications)) {
              this.cache$notifications.notifications = notifications
            }

            if (this.hasUnseenNotifications) {
              this.displayNotifications()
            }
          })
      }
    },
    markAsSeen () {
      if (this.hasUnseenNotifications) {
        updateProfile({ last_read_announcements_at: new Date().toJSON() })
          .then(user => {
            this.$store.commit('USER/UPDATE_PROFILE', user)
          })
      }
    }
  },
  mounted () {
    if (this.$store.getters.isLoggedIn && !this.$store.getters.isMobile) {
      let counter = 0
      setTimeout(() => {
        this.fetchRecentNotifications()

        // Refresh every 20 seconds if user is active
        setInterval(() => {
          // Refresh only every 2 minutes if the document is hidden
          counter++
          if (document.hidden && counter % 6) {
            return
          }
          this.fetchRecentNotifications()
        }, 120000)
      }, 20000)
    }
  }
}
