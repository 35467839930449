import isEqual from 'lodash/isEqual'

/**
 * Detects the changes in two given objects.
 *
 * @param {object} a The modified object
 * @param {object} b The object to diff against
 * @returns {object|undefined}
 */
export default function diffObjects (a, b) {
  return Object.keys(a)
    .reduce(
      (changes, key) => {
        const modifiedValue = a[key]
        const originalValue = b[key]

        if (!isEqual(modifiedValue, originalValue)) {
          if (!changes) {
            changes = {}
          }

          changes[key] = modifiedValue
        }

        return changes
      },
      undefined
    )
}
