<template>
  <div class="row">
    <div style="height: 50%; margin-top: 16px;" :class="callToActionStyling">
      <CallToActionCard
        :text=" hasOfferingsToDisplay ? `<p style='font-size: 15px'>Add ${productsLabel}</p>` : `<p style='font-size: 15px'>There are no ${productsLabel} to display</p>`">
        <template v-slot:callToActionSlot>
          <div>
            <ds-button v-if="userCanAddProduct" class="call-to-action-card__action-button" variant="primary" :label="'Add ' + productLabel" @click="showAddProductModal()"/>
          </div>
        </template>
      </CallToActionCard>
    </div>
    <div class="col-md-6 col-xs-12" v-if="hasOfferingsToDisplay">
      <ActorDetailOfferingProductGallery :actor="actor"/>
    </div>
  </div>
</template>

<script>
  import _chunk from 'lodash/chunk'
  import moment from 'moment'

  import AsideSection from '../../components/AsideSection/AsideSection.vue'
  import DefinitionList from '../../components/DefinitionList/DefinitionList.vue'
  import NewCard from '../../components/NewCard/NewCard.vue'
  import FileThumbnail from '../../components/FileThumbnail/FileThumbnail.vue'
  import ArticlePreview from '../../components/ArticlePreview/ArticlePreview.vue'
  import CallToActionCard from '../../components/CallToActionCard/CallToActionCard.vue'
  import ActorDetailOfferingProductGallery from './ActorDetailOfferingProductGallery.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import CompanyMixin from '../../util/CompanyMixin'
  import ConfigMixin from '../../util/ConfigMixin'

  import Tag from '../../components/Tag/Tag.vue'
  import MODAL_IDS from '../../constants/modal-ids'

  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import { trackHeapEvent } from '../../util/analytics.js'

  export default {
    name: 'ActorDetailOffering',
    props: {
      actor: Object,
    },
    computed: {
      userCanAddProduct () {
        if (this.$store.getters.isMember) {
          return true
        }

        return this.userCanEdit && this.$store.getters.claimableActorTypes && this.$store.getters.claimableActorTypes.includes('Product')
      },
      company () {
        // Backwards compatibility for the CompanyMixin
        return this.actor
      },
      classes () {
        return [
          'article-preview',
          this.compact && 'article-preview--compact',
          !this.image && 'article-preview--no-image',
        ].join(' ')
      },
      domainsLabel () {
        if (this.doesTaxonomyAliasExist('domains')) {
          return this.getTaxonomyAlias('domains')
        }

        return 'Business Expertise'
      },
      productFeaturesALabel () {
        if (this.doesTaxonomyAliasExist('productFeaturesA')) {
          return this.getTaxonomyAlias('productFeaturesA')
        }

        return 'Product Features A'
      },
      productFeaturesBLabel () {
        if (this.doesTaxonomyAliasExist('productFeaturesB')) {
          return this.getTaxonomyAlias('productFeaturesB')
        }

        return 'Product Features B'
      },
      productFeaturesCLabel () {
        if (this.doesTaxonomyAliasExist('productFeaturesC')) {
          return this.getTaxonomyAlias('productFeaturesC')
        }

        return 'Product Features C'
      },
      technologyLabel () {
        if (this.doesTaxonomyAliasExist('technology')) {
          return this.getTaxonomyAlias('technology')
        }

        return 'Technologies'
      },
      expertiseLabel () {
        if (this.doesTaxonomyAliasExist('expertises')) {
          return this.getTaxonomyAlias('expertises')
        }

        return 'Expertise'
      },
      motivationsLabel () {
        if (this.doesTaxonomyAliasExist('motivations')) {
          return this.getTaxonomyAlias('motivations')
        }

        return 'Motivations'
      },
      productFeaturesA () {
        return this.converProductFeaturesToDescriptions(this.actor.product_features_a)
      },
      productFeaturesB () {
        return this.converProductFeaturesToDescriptions(this.actor.product_features_b)
      },
      productFeaturesC () {
        return this.converProductFeaturesToDescriptions(this.actor.product_features_c)
      },
      technologies () {
        const actor = this.actor

        if (!actor.technology || (actor.technology && actor.technology.length === 0)) {
          return []
        }

        return actor.technology.map(technology => ({
          term: technology.label,
          descriptions: [],
        }))
      },
      expertises () {
        const actor = this.actor

        if (!actor.expertises || (actor.expertises && actor.expertises.length === 0)) {
          return []
        }

        return actor.expertises.map(expertises => ({
          term: expertises.label,
          descriptions: [],
        }))
      },
      motivations () {
        const actor = this.actor

        if (!actor.motivations || (actor.motivations && actor.motivations.length === 0)) {
          return []
        }

        return actor.motivations.map(motivations => ({
          term: motivations.label,
          descriptions: [],
        }))
      },
      chunkedOfferings () {
        if (!this.actor.offerings) {
          return []
        }

        const offerings = this.actor.offerings.filter(offering => offering.type !== 'patent')

        return _chunk(offerings, 2)
      },
      chunkedPatents () {
        if (!this.actor.offerings) {
          return []
        }

        const patents = this.actor.offerings.filter(offering => offering.type === 'patent')

        return _chunk(patents, 2)
      },
      hasOfferingsToDisplay () {
        return (this.actor.has_product && this.actor.has_product.length > 0) || (this.actor.members && this.actor.members.filter(member => member.actor_type === 'Product').length > 0)
        // return this.chunkedOfferings.length > 0 || this.chunkedPatents.length > 0
      },
      callToActionStyling () {
        return 'col-xs-12 col-sm-12 col-md-4 col-lg-2 col-xl-4'
        /* if (this.businessExpertise.length > 0 || this.technologies.length > 0 || (this.actor.files && this.actor.files.length > 0)) {
          return 'col-lg-9 col-md-9 col-sm-12 col-xs-12'
        }
        return 'col-lg-8 col-md-8 col-sm-12 col-xs-12 col-md-offset-2 col-lg-offset-2' */
      },
    },
    methods: {
      converProductFeaturesToDescriptions (productFeatures) {
        const productFeaturesDescriptions = []
        const nested = []

        productFeatures.forEach((feature) => {
          if (!feature.parent_id) {
            return nested.push(feature)
          }
          const parent = nested.find(parent => parent.id === feature.parent_id)
          if (!parent.children) {
            parent.children = []
          }
          parent.children.push(feature)
        })

        if (nested && nested.length > 0) {
          nested.forEach(function (feature) {
            const description = { term: feature.name, descriptions: [] }

            if (feature.children && feature.children.length > 0) {
              const children = []

              feature.children.forEach(function (child) {
                children.push(child.name)
              })

              children.sort()

              description.descriptions = children
            }

            productFeaturesDescriptions.push(description)
          })
        }
        return productFeaturesDescriptions
      },
      /*
             - Vue 3 Warning: title already assigned in methods
             Vue 3 Warning: title already assigned in props
             title (offering) {
              if (offering.type === 'vacancy') {
                return offering.name
              }

              if (offering.date) {
                return offering.name + ' - ' + offering.date
              }

              return offering.name
            }, */
      addProduct () {
        const modalContext = {
          prefilled: {
            actor_type: 'Product',
          },
          successCallback: actor => {
            this.$bus.emit('productAddedViaOverlay', actor)
          },
        }

        if (this.actor.address && (this.actor.address.street || this.actor.address.city)) {
          modalContext.prefilled.address = { ...this.actor.address }
          modalContext.prefilled.parentName = this.actor.name
        }

        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, modalContext)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)
      },
      offeringLogo (offering) {
        if (!offering.logo) {
          return
        }

        return { backgroundImage: `url(${offering.logo})` }
      },
      timeDifference (offering) {
        if (!offering.date || !offering.end_date || offering.end_date < offering.date) {
          return
        }

        const start = moment(offering.date, 'YYYY/MM/DD')
        const end = moment(offering.end_date, 'YYYY/MM/DD')

        const diffInDays = end.diff(start, 'days')

        if (diffInDays == 0) {
          return 'today'
        }

        if (diffInDays == 1) {
          return 'yesterday'
        }

        return diffInDays + ' days ago'
      },
      showAddProductModal () {
        trackHeapEvent('addProduct')
        const modalContext = {
          prefilled: {
            prefilledParentId: this.actor.id,
          },
          successCallback: actor => {
            this.$bus.emit('productAddedViaOverlay', actor)
          },
        }

        if (this.actor.address && (this.actor.address.street || this.actor.address.city)) {
          modalContext.prefilled.address = { ...this.actor.address }
          modalContext.prefilled.parentName = this.actor.name
        }

        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, modalContext)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_PRODUCT)
      },
    },
    mixins: [CompanyMixin, ConfigMixin, TranslationsMixin],
    components: {
      ArticlePreview,
      AsideSection,
      DefinitionList,
      FileThumbnail,
      NewCard,
      CallToActionCard,
      Tag,
      ActorDetailOfferingProductGallery,
    },
  }
</script>

<style lang="scss">

</style>
