<template>
  <div class="home__section__article-section article-section__news_feed">
    <div class="article-preview__media-container">
      <div class="card__general article-section__items">
        <div class="article-item__container">
          <div class="article-item__content-container">
            <div class="article-item__data-container article-item__data-container--full">
              <div class="article-item__title-container">
                <a @click="$emit('link-click', $event)" target="_blank" class="article-item__article-section-title">{{ humanize(item.title) }}</a>
                <div class="article-item__title-container__right">
                  <span class="article-item__date">{{ toDateString(item.date * 1000) }}</span>
                </div>
              </div>
              <template v-if="item.actor_id && item.actor_id.length > 0">
                <div class="article-item__article-section-poster" v-for="mention in item.actor_id" @click="openActorSidePanel({actor_id: mention.id})">{{ mention.name }}</div>
              </template>
              <div class="article-item__description" v-if="item.description">{{ item.description }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { humanize } from '../../../constants/properties';
  import { toDateString } from '../../../util/date';

  export default {
    props: {
      item: Object,
      url: String,
      maxScore: Number,
    },
    computed: {
    },
    methods: {
      humanize,
      toDateString,
      openActorSidePanel(actorInfo) {
        this.$emit('open-actor-side-panel', actorInfo);
      },
    },
  }
</script>
