export default {
  methods: {
    changePublishState (state) {
      this.publishState = !state

      // If the publish state changed to "don't publish", reset the members of the portfolio members
      if (!this.publishState) {
        this.membersChanged = true
        this.members = this.emptyMembers
      }
    },
    addContributor (user) {
      var exists = false

      this.members.contributors.forEach(item => {
        if (item.user_id == user.id) {
          exists = true
        }
      })

      if (!exists) {
        this.members.contributors.push({ user_id: user.id, name: user.name, photo_url: user.photo_url })
        this.membersChanged = true
      }
    },
    addViewer (user) {
      var exists = false

      this.members.viewers.forEach(item => {
        if (item.user_id == user.id) {
          exists = true
        }
      })

      if (!exists) {
        this.members.viewers.push({ user_id: user.id, name: user.name, photo_url: user.photo_url })
        this.membersChanged = true
      }
    },
    addBenchmarker (user) {
      var exists = false

      this.members.benchmarkers.forEach(item => {
        if (item.user_id == user.id) {
          exists = true
        }
      })

      if (!exists) {
        this.members.benchmarkers.push({ user_id: user.id, name: user.name, photo_url: user.photo_url })
        this.membersChanged = true
      }
    },
    removeContributor (portfolioMember) {
      var index = -1

      this.members.contributors.forEach((item, itemIndex) => {
        if (item.user_id == portfolioMember.user_id) {
          index = itemIndex
        }
      })

      if (index > -1) {
        this.members.contributors.splice(index, 1)
        this.membersChanged = true
      }
    },
    removeViewer (portfolioMember) {
      var index = -1

      this.members.viewers.forEach((item, itemIndex) => {
        if (item.user_id == portfolioMember.user_id) {
          index = itemIndex
        }
      })

      if (index > -1) {
        this.members.viewers.splice(index, 1)
        this.membersChanged = true
      }
    },
    removeBenchmarker (portfolioMember) {
      var index = -1

      this.members.benchmarkers.forEach((item, itemIndex) => {
        if (item.user_id == portfolioMember.user_id) {
          index = itemIndex
        }
      })

      if (index > -1) {
        this.members.benchmarkers.splice(index, 1)
        this.membersChanged = true
      }
    },
  },
  computed: {
    isMember () {
      return this.$store.getters.isMember
    },
    isTeamMember () {
      return this.$store.getters.isTeamMember
    },
    isOwner () {
      return this.$store.getters.isOwner
    },
    isDeveloper () {
      return this.$store.getters.isDeveloper
    },
    isPortfolioMember () {
      return this.$store.getters.isPortfolioMember
    },
    listData () {
      return this.$store.state.actors.listData
    },
    keywords () {
      return this.$store.state.filters.keywords
    },
    enabledTabs () {
      if (this.isOwner || this.isTeamMember || this.isPortfolioOwner || this.isDeveloper) {
        if (this.portfolio.is_immutable) {
          return ['Description', `Actors (${this.totalActors})`]
        }

        return ['Description', `Actors (${this.totalActors})`, 'Sharing']
      }

      if (this.portfolio.is_immutable) {
        return ['Description']
      }

      return ['Description', `Actors (${this.totalActors})`]
    },
    hasAccessToPortfolioSharing () {
      return this.$store.getters.hasAccessToPortfolioSharing
    },
  },
}
