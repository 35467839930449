<template>
  <transition name="modal">
    <div class="modal__mask" @click="blur">
      <div class="modal__wrapper">
        <div class="modal__container" :class="classes" @click.stop>
          <div class="modal__header">
            <h4 class="title">{{title}}</h4>
            <hr class="divider">
          </div>
          <div class="modal__body" :class="modalContext.modalBodyClasses">
            <h4 class="confirmation__message">{{bodyText}}</h4>
          </div>
          <div class="modal__footer" style="text-align:center;">
            <ds-button
              :label="confirmLabel"
              variant="secondary"
              @click="confirm()"
            />
            <ds-button
            v-if="isCancellable"
            :label="cancelLabel"
            variant="secondary"
            @click="cancel()"
            />
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import KEYCODES from '../../constants/keycodes'
  import MODAL_IDS from '../../constants/modal-ids'



  export default {
    props: {
      closeOnBlur: Boolean,
      id: {
        type: String
      },
      isCloseable: {
        type: Boolean,
        default: true
      },
      size: {
        type: String,
        default: '',
        validator: val => ['large', ''].includes(val)
      }
    },
    created () {
      window.addEventListener('keyup', this.keyUpHandler)
    },
    methods: {
      keyUpHandler (e) {
        if (e.keyCode === KEYCODES.ESC) {
          this.close()
        }
      },
      blur () {
        if (this.closeOnBlur) {
          this.close()
        }
      },
      close () {
        if (this.isCloseable) {
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
          this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.CONFIRMATION)
          this.$emit('close')
        }
      },
      cancel () {
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
          this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.CONFIRMATION)
          this.$emit('close')
      },
      confirm () {
        this.$bus.emit('confirmAction', this.modalContext)

        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.CONFIRMATION)
        this.$emit('close')
      }
    },
    computed: {
      classes () {
        return {
          [`modal__container--${this.size}`]: this.size,
        }
      },
      modalContext () {
        return this.$store.state.ui.modalContext
      },
      title () {
        return (this.modalContext && this.modalContext.title) || 'Processing...'
      },
      bodyText () {
        return (this.modalContext && this.modalContext.body) || 'Do you confirm your action?'
      },
      confirmLabel () {
        if (!this.modalContext || !this.modalContext.confirmLabel) {
          return 'Ok'
        }

        return this.modalContext.confirmLabel
      },
      cancelLabel () {
        if (!this.modalContext || !this.modalContext.cancelLabel) {
          return 'Cancel'
        }

        return this.modalContext.cancelLabel
      },
      isCancellable () {
        return this.modalContext && this.modalContext.isCancellable
      }
    },
    mounted () {
      document.body.className = document.body.className + ' modal--open'
    },
    unmounted () {
      document.body.className = (document.body.className || '').replace('modal--open', '')
      window.removeEventListener('keyup', this.keyUpHandler)
    }
  }
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";

.modal--scrollable {
  display: block!important;
  padding: 100px 0;
  overflow: auto;
  bottom: 0;
  >.modal__wrapper {
    display: block;
    margin: 0 auto;
  }
}

.modal--open .main-view,
.modal--open .main-view .actor-detail {
  overflow: hidden
}

.confirmation__message {
  font-family: $font-stack-primary;
  font-size: 18px;
  font-weight: 300;
  line-height: 25px;
  text-align: center;
}

.modal__body.empty-fields-list .confirmation__message {
  white-space: pre-line;
  text-align: left;
  font-size: 15px;
  line-height: 21px;
}
</style>
