import ConfigMixin from './ConfigMixin'

export default {
  computed: {
    legendPropertyOptions () {
      return [
        'category',
        'stage',
        'membership',
        'activities',
        'domains',
        'industries',
        'product_features_a',
        'product_features_b',
        'product_features_c',
      ]
    },
  },
  methods: {
    getLegendOptions (property, permissions) {
      // Temporary fix
      // Problem we have currently 2 ways of saving available legend items:
      // array of taxonomy Objects
      // array of taxonomy labels
      // if there's difference between the options and the available legend items, the dropdown becomes unusable
      var availableItems = permissions.availableLegendItems[property]
      var itemType = 'label'

      if (availableItems && Array.isArray(availableItems) && availableItems.length > 0) {
        if (typeof availableItems[0] !== 'string') {
          itemType = 'object'
        }
      }

      switch (property) {
        case 'membership':
          if (itemType === 'label') {
            return this.membershipLabels || []
          }

          return this.membershipValuesAndLabels
        case 'category':
          if (itemType === 'label') {
            var categories = this.categoryLabels || []

            if (!categories) {
              return categories
            }

            var allCategories = []

            for (var type in categories) {
              allCategories = allCategories.concat(categories[type])
            }

            return allCategories
          }

          return this.categoryValuesAndLabels || []
        case 'activities':
          if (itemType === 'label') {
            return this.activityLabels || []
          }

          return this.activityValuesAndLabels || []
        case 'stage':
          if (itemType === 'label') {
            return this.stageLabels || []
          }

          return this.stageValuesAndLabels || []
        case 'domains':
          // Only domain labels are supported
          if (itemType === 'label') {
            var domains = this.$store.state.taxonomies.domains || []

            if (domains.length === 0) {
              return domains
            }

            return domains.map(domain => domain.name)
          }

          return this.domainValuesAndLabels || []
        case 'industries':
          if (itemType === 'label') {
            // Only industry labels are supported
            var industries = this.$store.state.taxonomies.industries || []

            if (industries.length === 0) {
              return industries
            }

            return industries.map(industry => industry.name)
          }

          return this.industryValuesAndLabels || []
        case 'product_features_a':
          var pfas = this.$store.state.taxonomies.productFeaturesA || []

          if (pfas.length === 0) {
            return pfas
          }

          return pfas.map(value => value.name)
        case 'product_features_b':
          var pfbs = this.$store.state.taxonomies.productFeaturesB || []

          if (pfbs.length === 0) {
            return pfbs
          }

          return pfbs.map(value => value.name)
        case 'product_features_c':
          var pfcs = this.$store.state.taxonomies.productFeaturesC || []

          if (pfcs.length === 0) {
            return pfcs
          }

          return pfcs.map(value => value.name)
      }
    },
  },
  mixins: [ConfigMixin]
}
