<template>
  <score-overview :type="scoreType" />
</template>

<script lang="ts">
import ScoreOverview from './ScoreOverview.vue'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      scoreType: 'business_size',
    }
  },
  components: {
    ScoreOverview
  }
})
</script>
