import ls from '../../util/ls'

export default {
  data () {
    return {
      collapsable: Object.assign({
        nav: false,
        subnav: false,
        filters: false
      }, ls('collapsable') || {})
    }
  },
  watch: {
    collapsable: {
      deep: true,
      handler (val) {
        ls('collapsable', val)
      }
    }
  }
}
