<template>
  <NewCard :title="`Inspire (${spottingAreasLabel} and ${productsLabel})`">
    <p>The graph below shows the evolution of number of all actors.</p>
    <div v-if="actors" class="chart-container">
      <div class="chart-main">
        <SimpleLineChart :data="actorsChartData">
        </SimpleLineChart>
      </div>

      <div class="chart-aside">
        <EcosystemInfoCard>
          <div class="text-muted">
            In total
          </div>
          <span class="text-lg">
            {{ totalActors }} actors
          </span>
          <div class="text-muted">
            are added
          </div>
        </EcosystemInfoCard>

        <ChartLegend :data="actorsChartData">
        </ChartLegend>
      </div>
    </div>

    <template v-if="claims && claims.total_claims">
      <p style="margin-top: 10px;">The graph below shows the evolution of number of claimed actors</p>

      <div class="chart-container">
        <div class="chart-main chart-main-right">
          <SimpleLineChart :data="claimsChartData">
          </SimpleLineChart>
        </div>

        <div class="chart-aside chart-aside-left">
          <EcosystemInfoCard>
            <div class="text-muted">
              In total
            </div>
            <span class="text-lg">
              {{ claims.total_claims }} actors
            </span>
            <div class="text-muted">
              are claimed
            </div>
          </EcosystemInfoCard>

          <ChartLegend :data="claimsChartData">
          </ChartLegend>
        </div>
      </div>
    </template>

    <br>
    <div class="row">
      <div class="col-xs-12" :class="mostPopularProducts ? 'col-sm-6' : 'col-sm-12'" v-if="showMostPopularActors">
        <table class="table table--fill table--hover table--striped table--borderless">
          <thead>
          <tr>
            <td colspan="4"><strong>Most popular actors / products</strong></td>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td><strong>Actor name</strong></td>
            <td><strong>Actor Type</strong></td>
            <td><strong>Page views</strong></td>
            <td><strong>Unique Visits</strong></td>
          </tr>
          <tr v-for="page in mostPopularActors">
            <td><a :href="convertUrl(page.url)">{{ page.actor.name || convertUrl(page.url) }}</a></td>
            <td>{{ page.actor.actor_type ? page.actor.actor_type : 'unknown' }}</td>
            <td>{{ page.views || page.visits }}</td>
            <td>{{ page.unique_views || page.unique_visitors }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <!--      <div class="col-xs-12" :class="mostPopularActors ? 'col-sm-6' : 'col-sm-12'" v-if="mostPopularProducts">-->
      <!--        <table class="table table&#45;&#45;fill table&#45;&#45;hover table&#45;&#45;striped table&#45;&#45;borderless">-->
      <!--          <thead>-->
      <!--          <tr>-->
      <!--            <td colspan="3"><strong>Most popular {{productsLabel}}</strong></td>-->
      <!--          </tr>-->
      <!--          </thead>-->
      <!--          <tbody>-->
      <!--          <tr>-->
      <!--            <td><strong>Page URL</strong></td>-->
      <!--            <td><strong>Page views</strong></td>-->
      <!--            <td><strong>Unique page views</strong></td>-->
      <!--          </tr>-->
      <!--          <tr v-for="page in mostPopularProducts">-->
      <!--            <td><a :href="convertUrl(page.url)">{{ convertUrl(page.url) }}</a></td>-->
      <!--            <td>{{ page.views }}</td>-->
      <!--            <td>{{ page.unique_views }}</td>-->
      <!--          </tr>-->
      <!--          </tbody>-->
      <!--        </table>-->
      <!--      </div>-->
    </div>
  </NewCard>
</template>

<script>
  import ChartLegend from './ChartLegend.vue'
  import EcosystemInfoCard from './EcosystemInfoCard.vue'
  import NewCard from '../../../components/NewCard/NewCard.vue'
  import SimpleLineChart from '../../../components/Chart/SimpleLineChart.vue'
  import { parseChartData } from '../../Chart/chartsMixin.js'
  import TranslationsMixin from '../../../util/TranslationsMixin.js'

  const sum = (a, b) => a + b
  const sumValues = obj => Object.values(obj).reduce(sum)

  export default {
    props: {
      actors: Object,
      claims: Object,
      mostPopularActors: Object,
      mostPopularProducts: Object,
    },
    components: {
      ChartLegend,
      EcosystemInfoCard,
      NewCard,
      SimpleLineChart,
    },
    data () {
      return {
        showMostPopularActors: false,
      }
    },
    computed: {
      actorsChartData () {
        if (!!this.mostPopularActors && typeof this.mostPopularActors === 'object') {
          this.showMostPopularActors = Object.keys(this.mostPopularActors).length > 0
        }
        const datasets = []

        if (!Array.isArray(this.actors.legal_entity_count)) {
          datasets.push({
            color: '#FBA52C',
            data: this.actors.legal_entity_count,
            cumulate: true,
            label: 'Organizations',
          })
        }

        if (!Array.isArray(this.actors.person_count)) {
          datasets.push({
            color: '#2E59FA',
            data: this.actors.person_count,
            cumulate: true,
            label: 'Persons',
          })
        }

        if (!Array.isArray(this.actors.product_count)) {
          datasets.push({
            color: '#AF52FF',
            data: this.actors.product_count,
            cumulate: true,
            label: 'Products',
          })
        }

        if (datasets.length > 0) {
          return parseChartData(datasets)
        } else {
          return null
        }
      },
      totalActors () {
        if (!this.actors) {
          return 0
        }
        return this.actors.total_legal_entities +
          this.actors.total_persons +
          this.actors.total_products
      },
      claimsChartData () {
        const datasets = []

        if (!this.claims) {
          return
        }

        if (!Array.isArray(this.claims.product_claim_count)) {
          datasets.push({
            color: '#B36ADD',
            data: this.claims.product_claim_count,
            cumulate: true,
            label: 'Products',
          })
        }

        if (!Array.isArray(this.claims.company_claim_count)) {
          datasets.push({
            color: '#FBA52C',
            data: this.claims.company_claim_count,
            cumulate: true,
            label: 'Organizations',
          })
        }

        if (!Array.isArray(this.claims.person_claim_count)) {
          datasets.push({
            color: '#2E59FA',
            data: this.claims.person_claim_count,
            cumulate: true,
            label: 'Individuals',
          })
        }

        if (datasets.length > 0) {
          return parseChartData(datasets)
        }

        return null
      },
    },
    methods: {
      convertUrl (pageUrl) {
        return `${window.location.protocol}//${window.location.hostname}${pageUrl}`
      },
    },
    mixins: [TranslationsMixin],
  }
</script>
