<template>
  <div>
    <h2 class="h2">Branding</h2>

    <form-group label="Display name">
      <ds-input v-model="form.displayName" :placeholder="form.name"/>
    </form-group>
    <form-group label="Title">
      <ds-input v-model="form.title"/>
    </form-group>

    <div class="row">
      <form-group
        class="col-xs-6" label="Logo (default)"
        instructions=".png or .jpg &middot; &plusmn;300&times;150"
      >
        <image-input v-model="form.logo_url" width="300" height="150"/>
      </form-group>
      <form-group
        class="col-xs-5" label="Favicon"
        instructions=".ico or .png &middot; square"
      >
        <image-input v-model="form.favicon_url" width="64" height="64"/>
      </form-group>
    </div>
    <div class="row">
      <form-group class="col-xs-6" label="Logo for darker backgrounds">
        <image-input v-model="form.logo_url_light" width="300" height="150" class="light-logo-upload-container"/>
      </form-group>
      <form-group class="col-xs-5" label="Logo for Onboarding" instructions=".png or .jpg &middot; &plusmn;600&times;600" v-if="$store.getters.isPublisherEnabled">
        <image-input v-model="form.onboarding_image_url" width="300" height="300"/>
      </form-group>
    </div>

    <form-group label="Email address">
      <ds-input v-model="form.email"/>
      <checkbox
        v-if="canDisplayUseAsSystemEmail"
        inline
        label="Send system emails from this address"
        v-model="useAsSystemEmail"
      />
    </form-group>
    <div style="display: flex;">
      <form-group label="URL" style="width: 20em;">
        <ds-input v-model="form.homepage"/>
      </form-group>
      <form-group v-if="$store.getters.isPublisherEnabled" label="URL Label" style="margin-left: 10px; width: 20em;">
        <ds-input v-model="form.externalUrlLabel" style="width: 96%;"/>
      </form-group>
    </div>
    <div style="display: flex;">
      <form-group label="URL" style="width: 20em; visibility: hidden;"/>
      <form-group
        label="URL Label (NL)" style="margin-left: 10px; width: 20em;"
        v-if="localeEnabled('nl')"
      >
        <ds-input v-model="form.translations.nl.externalUrlLabel" style="width: 96%;"/>
      </form-group>
    </div>
    <form-group label="Facebook">
      <ds-input v-model="form.facebookUrl"/>
    </form-group>
    <form-group label="Twitter">
      <ds-input v-model="form.twitterUrl"/>
    </form-group>
    <form-group label="LinkedIn">
      <ds-input v-model="form.linkedInUrl"/>
    </form-group>
    <form-group label="Instagram">
      <ds-input v-model="form.instagramUrl"/>
    </form-group>
    <form-group label="Terms of Use">
      <ds-input v-model="form.termsOfUseUrl"/>
    </form-group>
    <form-group label="Privacy Policy">
      <ds-input v-model="form.privacyPolicyUrl"/>
    </form-group>
    <form-group label="FAQ (Frequently Asked Questions)">
      <ds-input v-model="form.faqUrl"/>
    </form-group>
    <form-group label="Cookie Policy">
      <ds-input v-model="form.cookiePolicyUrl"/>
    </form-group>
    <div v-if="$store.getters.isPublisherEnabled">
      <h2 class="h2">SEO</h2>
      <form-group
        label="Fill in the fields below to provide detailed information about your ecosystem for search engines."
      ></form-group>
      <form-group label="Organisation name">
        <ds-input v-model="form.organisationName"/>
      </form-group>

      <form-group label="Description">
        <ds-textarea v-model="form.seoDescription" autoresize/>
      </form-group>

      <form-group label="Tags">
        <tag-input v-model="form.seoTags"/>
      </form-group>
    </div>

    <template v-if="$store.getters.isPublisherEnabled">
      <h2 class="h2">Footer</h2>
      <form-group
        label="Additional footer logo (for the simplified environment)"
      >
        <image-input v-model="form.footer_logo" width="300" height="150" :allow-remove="true" @remove="form.footer_logo = ''"/>
      </form-group>
      <form-group label="Footer Description">
        <ds-textarea v-model="form.footerDescription"/>
      </form-group>
      <form-group label="footer CTA (right-hand side)">
        <checkbox v-model="form.footerCtaEnabled" label="Enable Footer CTA"/>
      </form-group>
      <div style="display: flex;">
        <form-group label="footer text" style="width: 20em;">
          <ds-input v-model="form.footerCtaText"/>
        </form-group>
        <form-group label="URL Label" style="margin-left: 10px; width: 20em;">
          <ds-input v-model="form.footerCtaUrl" style="width: 96%;"/>
        </form-group>
      </div>
    </template>
  </div>
</template>

<script>
  import Dropdown from '../../Dropdown/Dropdown.vue'
  import DsTextarea from '../../Form/DsTextarea.vue'
  import ImageInput from '../../Form/ImageInput.vue'
  import TagInput from '../../Form/TagInput.vue'
  import Checkbox from '../../Form/Checkbox.vue'

  import RichTextArea from '../../../components/TextArea/RichTextArea.vue'
  import TranslationsMixin from '../../../util/TranslationsMixin'

  export default {
    name: 'branding',
    props: ['form'],
    computed: {
      canDisplayUseAsSystemEmail () {
        if (this.form.email && this.form.email.endsWith('@datascouts.eu') && this.form.email !== 'support@datascouts.eu' && this.form.email !== 'hello@datascouts.eu') {
          return true
        }

        return this.$store.getters.verifiedEmailDomains.some(domain => this.form.email.endsWith(domain))
      },
      useAsSystemEmail: {
        get () {
          if (!this.form.useEmailAsSystemEmail) {
            return false
          }

          return !!this.form.useEmailAsSystemEmail
        },
        set (v) {
          this.form.useEmailAsSystemEmail = v
        },
      },
    },
    mixins: [
      TranslationsMixin,
    ],
    components: {
      TagInput,
      DsTextarea,
      ImageInput,
      Dropdown,
      RichTextArea,
      Checkbox,
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../../scss/_variables.scss";

  .light-logo-upload-container {
    :deep(img) {
      background-color: $color-background-light-grey;
    }
  }
</style>
