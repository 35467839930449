<template>
  <div>
    <label class="radio">
      <input
        type="radio"
        :name="id"
        value="all"
        :checked="modelValue === 'all' || !modelValue"
        @change="handleChange"
      >
      <span class="radio__label">All content</span>
    </label>
    <label class="radio">
      <input
        type="radio"
        :name="id"
        value="curated"
        :checked="modelValue === 'curated'"
        @change="handleChange"
      >
      <span class="radio__label">Curated content only</span>
    </label>
  </div>
</template>

<script>
  import { randomId } from '../../../util/random'

  export default {
    props: {
      modelValue: {
        type: String,
      }
    },
    emits: ['update:modelValue'],
    data() {
      return {
        id: randomId(),
      }
    },
    methods: {
      handleChange(event) {
        this.$emit('update:modelValue', event.target.value)
      }
    },
  }
</script>
