<template>
  <component
    :is="url.startsWith('http') ? 'a' : 'router-link'"
    class="signal-card__container"
    :href="url"
    :to="url"
    target="_blank"
  >
    <div class="signal-card__top">
      <div class="signal-card__top-left" v-if="showImage" :style="{'backgroundImage': `url('${logoUrl}')`}">
        <span v-if="!logoUrl">{{ title.slice(0, 1) }}</span>
      </div>
      <div class="signal-card__top-right">
        <div class="signal-card__title" :title="title">
          {{ title }}
        </div>
        <div class="signal-card__sub-title">
          {{ subTitle }}
        </div>
      </div>
    </div>
    <div class="signal-card__middle">
      <slot name="body"/>
    </div>
    <div class="signal-card__bottom">
      <slot name="footer"/>
    </div>
  </component>
</template>

<script>
  export default {
    props: {
      title: {
        type: String,
        default: '',
      },
      subTitle: {
        type: String,
        default: '',
      },
      showImage: {
        type: Boolean,
        default: false,
      },
      logoUrl: {
        type: String,
        default: '',
      },
      url: {
        type: String,
        default: '',
      },
    },
    data () {
      return {
      }
    },
    methods: {},
    computed: {},
    mounted () {},
    components: {},
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/_variables.scss";

  a {
    text-decoration: none;
    color: black;
  }

  .signal-card__container {
    border-radius: $default-border-radius;
    border: 2px solid $color-borders;
    padding: 20px;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .signal-card__top {
    display: flex;
    border-bottom: 2px solid $color-borders;
    padding-bottom: 20px;

    .signal-card__top-left {
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      border-radius: 50%;
      height: 28px;
      width: 28px;
      box-shadow: $simplified-box-shadow-config;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 10px;
    }

    .signal-card__top-right {
      flex: 1;
      min-width: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .signal-card__title {
        font-size: 14px;
        color: var(--primary);
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }

      .signal-card__sub-title {
        font-size: 10px;
      }
    }
  }

  .signal-card__middle {
    font-size: 12px;
    flex-grow: 1;
    margin-top: 10px;
    // margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    line-height: 14px;
    // max-height: 44px;
  }

  .signal-card__bottom {
    color: $color-secondary;
    font-size: 10px;
  }
</style>
