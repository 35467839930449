<template>
  <div class="scrollable">
    <actor-deleted v-if="isDeleted" :actor="actor"></actor-deleted>
    <div
      class="actor-detail scrollable" ref="mainContent" @scroll="handleMainContentScroll"
      v-else-if="company && company.id"
    >
      <div class="actor-detail__header">
        <template v-if="$store.getters.hasAccessToRelevancyUi">
          <div class="actor-detail__top-right-btn-container">
            <relevant-edit-popup
              v-model="actorRelevantInIds"
              :active-portfolio="activePortfolioId"
              @relevant="handleActorRelevant"
              @not-relevant="handleActorNotRelevant"
            >
              <template v-slot:default="{icon}">
                <ds-button
                  class="relevant-edit-popup--reference"
                  :icon="icon"
                  icon-size="18"
                  label="Follow"
                  :noFill="false"
                  style="margin-right: 5px; cursor: pointer; border-color: #CECECE;"
                />
              </template>
            </relevant-edit-popup>
            <ds-button
              class="" label="Duplicates" @click="openDuplicatesPanel" style="border-color: #CECECE;"
              v-if="isMember && actor.possible_duplicate"
            />
          </div>
          <ds-button
            class="actor-detail__next-btn" style="border-color: #CECECE;" label="Next"
            icon="double-chevron-right" @click="goToNext"
          />
        </template>
        <template v-else>
          <ds-button
            class="actor-detail__duplicates-btn" style="border-color: #CECECE;" label="Duplicates"
            @click="openDuplicatesPanel"
            v-if="isMember && actor.possible_duplicate"
          />
          <ds-button
            class="actor-detail__next-btn" style="border-color: #CECECE;" label="Next"
            icon="double-chevron-right" @click="goToNext"
          />
        </template>

        <div class="actor-detail__intro-card" :style="{ borderLeftColor: categoryColor }">
          <div class="actor-detail__intro-card__header">
            <Avatar :src="actor.logo" :alt="actor.name"/>
            <div>
              <div class="actor-detail__intro-card__header__title">
                {{ actor.name }}
                <div
                  class="actor-detail__intro-card__header__title__underline"
                  :style="{ backgroundColor: primaryColor }"
                />
              </div>
              <div class="actor-detail__intro-card__header__subtitle" v-if="actor.acquired_by">
                <div v-for="acquired in actor.acquired_by">
                  has been acquired by <strong>{{ acquired.to_name }}</strong>
                </div>
              </div>
              <div class="actor-detail__intro-card__header__subtitle" v-if="actor.product">
                Also known as: <strong>{{ actor.product }}</strong>
              </div>
            </div>
          </div>
          <div class="actor-detail__intro-card__body">
            <p :style="shortDescription" v-html="shortDescription"></p>

            <CardInfoSocial :company="actor" iconSize="14" showWebsiteUrl/>
            <template v-if="ui.isMobile">
              <div class="row" v-if="displayContactButton">
                <div class="col-xs-12 col-sm-12">
                  <ds-button
                    :label="contactLabel"
                    size="small" variant="primary"
                    style="display: block; text-align: center"
                    :href="getLinkContactActor"
                    @click="handleClickContactActor"
                    v-tooltip.top="getLinkHelperTextContactActor"
                  />
                </div>
              </div>
            </template>
            <template v-else>
              <ds-button
                v-if="displayContactButton"
                :label="contactLabel"
                size="small" variant="primary"
                style="display: block; text-align: center"
                :href="getLinkContactActor"
                @click="handleClickContactActor"
                v-tooltip.top="getLinkHelperTextContactActor"
              />
            </template>
          </div>
        </div>
        <template v-if="!ui.isMobile">
          <div v-if="company.banner_image_url" class="actor-detail__featured embed-responsive hidden-sm-down">
            <div
              :style="{backgroundImage: 'url('+ company.banner_image_url + ')', backgroundSize: 'cover', backgroundReapet: 'no-repeat', width: '100%', height: '100%'}"
            ></div>
          </div>
          <div v-else class="actor-detail__map">
            <simple-map
              :location="company.location" :legend="legendValue" :offices="company.subsidiaries"
              :use-pin-point-icon="highlightedSpottingArea.id && company.is_inside_highlighted_spotting_area"
            />
          </div>
        </template>
      </div>


      <div class="actor-detail__content">
        <template v-if="!ui.isMobile">
          <tabs
            :tabs="tabs"
            class="tabs--dark"
            :edit="!!userCanEdit"
            :notes="!!userCanAccessNotes"
            :suggestions="hasActorSuggestions"
            :hasAgentSuggestions="hasAgentSuggestions"
            :saveToUrl="true"
            :displayAgents="displayAgents"
            :displaySimilarActors="displaySimilarActors"
            ref="tabs"
            @editMode="openEditSidePanel(company.id)"
            @agentsMode="openAgentsPanel(company.id)"
            @suggestionMode="openSuggestionsSidePanel(company.id)"
            @similarMode="openSimilarSidePanel(company.id)"
          >
            <template v-slot:Profile>
              <div>
                <ActorDetailProfile
                  :actor="company"
                  :connections="connections"
                  :mainContentData="profileData"
                  @clickCreateAnnouncement="handleClickCreateAnnouncement"
                  @clickViewAllConnections="handleRedirectToConnections"
                  @showClaimModal="showClaimModal"
                />
              </div>
            </template>
            <template v-slot:Info>
              <div>
                <ActorDetailInfo :actor="company" :mainContentData="profileData"/>
              </div>
            </template>
            <template v-slot:Connections>
              <div>
                <ActorDetailGraphConnections
                  :actor="company"
                  :connections="connections"
                  @openEditSidePanel="openEditSidePanel"
                  @addRelation="handleAddRelation"
                  @removeRelation="handleRemoveRelationById"
                  @connectionSuggestionApproved="handleConnectionSuggestionApproved"
                />
              </div>
            </template>
            <template v-slot:Contacts>
              <div>
                <ActorDetailContacts :actor="company" :contacts="companyContacts"/>
              </div>
            </template>
            <template v-slot:[productsLabel]>
              <div>
                <ActorDetailOffering :actor="company"/>
              </div>
            </template>
            <template v-slot:Contributors>
              <div>
                <ActorDetailContributors
                  :contributors="contributors"
                  @remove-contributor="removeContributor"
                  :show-user-department="true"
                  :show-user-communities="true"
                  :show-user-email="true"
                  :show-user-role="true"
                />
              </div>
            </template>
            <template v-slot:Buzz>
              <div>
                <ActorDetailBuzz :actor="company"/>
              </div>
            </template>
            <template v-slot:Notes v-if="userCanAccessNotes">
              <div>
                <ActorDetailNotes :actor="company"/>
              </div>
            </template>
            <template v-slot:Sustainability v-if="userCanAccessSustainability">
              <div>
                <ActorSustainability :actor="company"/>
              </div>
            </template>
            <template v-slot:Keywords v-if="actorHasKeywords">
              <div>
                <ActorScrapedKeywords :actor="company"/>
              </div>
            </template>
          </tabs>
        </template>
        <template v-if="ui.isMobile">
          <ActorDetailMobile
            :actor="actor"
            :connections="connections"
          />
        </template>
      </div>
    </div>
    <div v-else class="actor-detail actor-detail--empty">
      <div v-if="loading">
        <h3 class="h3">{{ $t('loading') }}</h3>
      </div>
      <div v-else-if="actorClaimInProgress">
        <h3 class="h3">{{ $t('actor_error_claim_processing') }}</h3>
        <ds-button style="margin-top: 15px;" variant="secondary" label="Go to homepage" @click="$router.push('/')"/>
      </div>
      <div v-else-if="! isLoggedIn">
        <h3 class="h3">{{ $t('actor_error_login_required') }}</h3>
        <ds-button style="margin-top: 15px;" variant="secondary" label="Login" @click="$router.push('/login')"/>
        <ds-button style="margin-top: 15px;" variant="secondary" label="Register" @click="$router.push('/register')"/>
      </div>
      <div v-else-if="confirmedRemovedActor">
        <actor-deleted v-if="isDeleted" :actor="actor"></actor-deleted>
      </div>
      <div v-else>
        <h3 class="h3">{{ noPermissions }}</h3>
        <ds-button style="margin-top: 15px;" variant="secondary" label="Go to homepage" @click="$router.push('/')"/>
        <ds-button
          :href="'mailto:' + emailAddress" style="margin-top: 15px;" variant="secondary" label="Get in touch"
          v-if="emailAddress"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import _debounce from 'lodash/debounce'
  import _sortBy from 'lodash/sortBy'
  import _uniqBy from 'lodash/uniqBy'
  import moment from 'moment'

  import { fetchAgentStatus, fetchContributors, fetchDuplicateActors, mergeDuplicateActors, removeActor, removeContributor, updateActor, } from '../../api/actors'
  import { fetchActorTelex, Suggestions } from '../../api/heartbeat.js'
  import { fetchBuzzData } from '../../api/buzz'
  import { updateActorRelevancy } from '../../api/exploration.js'
  import ActorSuggestions from '../../components/ActorSuggestions/ActorSuggestions.vue'
  import AddressModal from '../../components/Modals/AddressModal.vue'
  import Badge from '../../components/Badge/Badge.vue'
  import ClassicCard from '../../components/Card/ClassicCard.vue'
  import CompetitionNumbersContainer from '../../components/CompetitionNumbers/CompetitionNumbersContainer.vue'
  import ContentBlock from '../../components/ContentBlock/ContentBlock.vue'
  import DsTextarea from '../../components/Form/DsTextarea.vue'
  import FileList from '../../components/Form/FileList.vue'
  import FileInput from '../../components/Form/FileInput.vue'
  import KnowledgeSharing from '../../components/KnowledgeSharing/KnowledgeSharing.vue'
  import MiniChart from '../../components/MiniChart/MiniChart.vue'
  import Modal from '../../components/Modals/Modal.vue'
  import OfficeInput from '../../components/Form/OfficeInput.vue'
  import RelationCreate from '../../components/Relation/Create.vue'
  import RelationEdit from '../../components/Relation/Edit.vue'
  import ActorScores from '../../components/SignificantDevelopments/ActorScores.vue'
  import SimpleMap from '../../components/SimpleMap/SimpleMap.vue'
  import Tabs from '../../components/Tabs/Tabs.vue'
  import ActorDeleted from '../../components/Errors/ActorDeleted.vue'
  import BubbleChart from '../../components/Chart/BubbleChart.vue'
  import TimelineChart from '../../components/Chart/TimelineChart.vue'
  import BuzzChartCloseUp from '../../components/Dashboard/BuzzChartCloseUp.vue'
  import DuplicateActorsDropdown from '../../components/Dropdown/DuplicateActorsDropdown.vue'
  import AsideSection from '../../components/AsideSection/AsideSection.vue'
  import Keyword from '../../components/Keyword/Keyword.vue'
  import NewCard from '../../components/NewCard/NewCard.vue'
  import DefinitionList from '../../components/DefinitionList/DefinitionList.vue'
  import Avatar from '../../components/Avatar/Avatar.vue'
  import SocialItem from '../../components/Card/SocialItem.vue'
  import CardInfoSocial from '../../components/Card/CardInfoSocial.vue'
  import ActorDetailProfile from './ActorDetailProfile.vue'
  import ActorDetailInfo from './ActorDetailInfo.vue'
  import ActorDetailGraphConnections from './ActorDetailGraphConnections.vue'
  import ActorDetailOffering from './ActorDetailOffering.vue'
  import ActorDetailContacts from './ActorDetailContacts.vue'
  import ActorDetailBuzz from './ActorDetailBuzz.vue'
  import ActorDetailMobile from './ActorDetailMobile.vue'
  import ActorDetailReport from './ActorDetailReport.vue'
  import ActorDetailNotes from './ActorDetailNotes.vue'

  import MODAL_IDS from '../../constants/modal-ids'
  import { viewDashboardOptions } from '../../constants/config'

  import { ACTION_TYPES as ACTORS_ACTION_TYPES, MUTATION_TYPES as ACTORS_MUTATION_TYPES, } from '../../store/modules/actors'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'

  import CompanyMixin from '../../util/CompanyMixin'
  import FiltersMixin from '../../util/FiltersMixin'
  import UiMixin from '../../util/UiMixin'
  import TranslationsMixin from '../../util/TranslationsMixin'
  import { toFullMonth } from '../../util/date'
  import { getNested } from '../../util/getNested'
  import { toAmount } from '../../util/currency.js'
  import { addressLines, getConnectionsForActor, inert } from '../../util/helpers'
  import _isEmpty from 'lodash/isEmpty.js'

  import { trackHeapEvent, trackMatomoEvent } from '../../util/analytics'
  import RelevantEditPopup from '../../components/RelevantEditPopup/RelevantEditPopup.vue'
  import ReportMixin from '../../util/ReportMixin'
  import { Conversations } from '../../api/conversations.js'
  import { capitalize } from '../../util/string.ts'
  import ActorDetailContributors from './ActorDetailContributors.vue'
  import AnnouncementMixin from '../../util/AnnouncementMixin'
  import { ConversationsMixin } from '../../util/ConversationsMixin'
  import ActorSustainability from './ActorSustainability.vue'
  import ActorScrapedKeywords from './ActorScrapedKeywords.vue'
  import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from '../../constants/analytics-constants'

  export default {
    name: 'ActorDetail',
    data () {
      return {
        editing: false,
        confirmedRemovedActor: false,
        employeeModal: false,
        errors: {},
        saving: false,
        show: {
          addOffice: false,
          files: false,
          report: false,
          duplicateActors: false,
        },
        editingAddress: null,
        actor: inert(this.company || {}),
        actorRelevantInIds: [],
        suggestions: [],
        hasAgentSuggestions: false,
        unsavedOffice: { street: null },
        unsavedActor: {},
        duplicateActors: [],
        hasBuzzData: false,
        fetchNewDuplicateActors: false,
        profileData: {
          changes: [],
          tweets: [],
          reddit: [],
          patents: [],
        },
        hasScrolledAwayFromTop: false,
        hasSimilarActors: false,
        updatingFeaturedImage: false,
        contributors: {},
      }
    },
    computed: {
      highlightedSpottingArea () {
        return this.$store.state.spottingAreas.highlightedSpottingArea.data
      },
      noPermissions () {
        const id = this.$route.path.replace('/actors/', '')
        trackMatomoEvent(MATOMO_EVENT_CATEGORIES.NO_PERMISSIONS, MATOMO_EVENT_ACTIONS.ACTOR_VISIT, id)
        return 'You lack the permissions to view this content.'
      },
      ecosystemRelationships () {
        return this.$store.getters.fullActorRelationships
      },
      relationshipTypes () {
        return this.ecosystemRelationships.map(t => t.name).filter(r => !['subsidiaries', 'main_company'].includes(r)).reverse()
      },
      activePortfolioId () {
        return this.$store.getters.activePortfolio.id
      },
      emailAddress () {
        return this.$store.state.config.email
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      profile () {
        return this.$store.state.user && this.$store.state.user.profile
      },
      actorClaimInProgress () {
        if (!this.profile || !this.profile.claims) {
          return false
        }

        var claimIds = this.profile.claims.map(claim => claim.id)

        return claimIds.includes(this.$route.params.id)
      },
      getLinkContactActor () {
        if (this.linkContactActorType === 'mailto') {
          return 'mailto:' + this.actor.email
        }

        /*if (this.linkContactActorType === 'website') {
          // fix for warnings label expects string but gets boolean back
          return String(this.actor.contact_cta_url)
        }*/

        return ''
      },
      linkContactActorType () {
        /*if (this.actor.show_contact_cta_url) {
          return 'website'
        } else {*/
        if (!this.isActorClaimed && this.actor.email !== '') {
          return 'mailto'
        }

        if (this.isActorClaimed) {
          return 'conversation'
        }
        //}

        return ''
      },
      getLinkHelperTextContactActor () {
        if (this.linkContactActorType === 'conversation') {
          if (this.hasUserClaimedActor) {
            return this.$t('conversation_not_allowed_with_yourself')
          }

          return this.$t('conversation_start')
        }

        return ''
      },
      displaySimilarActors () {
        return this.isMember && (this.$store.getters.hasAccessToExploration || this.$store.getters.hasAccessToMonitoring || this.$store.getters.hasAccessToAgents)
      },
      categoryColor () {
        return this.ribbonColor
      },
      primaryColor () {
        return this.$store.getters.primaryColor
      },
      isActorClaimed () {
        // the "claimed" computed property should be coming from the transformed actor object
        return this.actor && this.actor.claimed
      },
      contactLabel () {
        if (this.actor.contact_cta_text && typeof this.actor.contact_cta_text === 'string') {
          return this.actor.contact_cta_text
        }

        return this.$t('get_in_touch')
      },
      displayContactButton () {
        if (!this.hasAccessToConversations) {
          return false
        }

        // Display the "contact button" if the option is turned on and if the user isn't the one who has claimed the profile
        const defaultBehaviour = this.$store.getters.publisher && this.$store.getters.canActorsBeContacted
        // const claimedBehaviour = (!this.hasUserClaimedActor && this.isActorClaimed)

        return defaultBehaviour
      },
      displayAgents () {
        return this.actor.company_type != 'Subsidiary' &&
          this.actor.actor_type != 'Person' &&
          this.$store.getters.hasAccessToAgents &&
          (
            this.canAdministratorAgentBeUsed ||
            this.canInvestorAgentBeUsed ||
            this.canProjectAgentBeUsed ||
            this.canCurrentTeamAgentBeUsed ||
            this.canLookalikeAgentBeUsed ||
            this.canFoundingTeamAgentBeUsed ||
            this.canQaStaffAgentBeUsed ||
            this.canAcquisitionAgentBeUsed
          )
      },
      isBuzzEnabled () {
        return this.$store.getters.canUseBuzz
      },
      fileTitle () {
        return this.companyFiles && this.companyFiles.length ? 'Files (' + this.companyFiles.length + ')' : 'Files'
      },
      hasActorSuggestions () {
        return this.suggestions && this.suggestions.length > 0
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      userCanSeeChanges () {
        return this.isOwner || this.$store.getters.isMember
      },
      showContributors () {
        return this.userIsActorOwner || this.isMember
      },
      isPublisherEnabled () {
        return !!this.$store.state.config.publisher
      },
      canAccessConnections () {
        return this.$store.state.config.publisher && this.$store.state.config.publisher.canViewActorConnections
      },
      tabs () {
        let tabs = ['Profile']
          .concat((this.userCanEdit || this.isPortfolioMember || this.canAccessConnections) ? ['Info', 'Connections'] : ['Info'])
          .concat(this.canShowBuzz && this.hasBuzzData ? 'Buzz' : [])
          .concat(this.canShowOfferingTab ? [this.productsLabel] : [])
          .concat(this.showContributors && this.isPublisherEnabled ? ['Contributors'] : [])
          .concat(this.userCanAccessSustainability ? ['Sustainability'] : [])
          .concat(this.actorHasKeywords ? ['Keywords'] : [])

        // Hide the Info tab for persons, nothing useful to show there for Person actors
        if (this.company.actor_type === 'Person') {
          tabs = tabs.filter(tab => tab !== 'Info')
        }

        // If the actor is not a Person and has employees, then add the Contacts tab
        if (this.company.actor_type !== 'Person' && this.companyContacts && this.companyContacts.length > 0) {
          tabs = tabs.concat(['Contacts'])
        }

        return tabs
      },
      labelDescription () {
        return ['Public Agency', 'Academia'].includes(this.company.category) ? 'Mission & Vision' : 'Description'
      },
      loading () {
        return this.$store.state.actors.detail.loading
      },
      connections () {
        return getConnectionsForActor(this.$store.getters.detailActor, this.ecosystemRelationships)
      },
      errorsRelations () {
        return this.ecosystemRelationships.map(r => this.errors[r.name] && this.errors[r.name].join(' ')).filter(Boolean)
      },
      canShowOfferingTab () {
        return this.userCanEdit || (this.actor.domains && this.actor.domains.length > 0) || (this.actor.technology && this.actor.technology.length > 0) || (this.actor.offerings && this.actor.offerings.length > 0)
      },
      company () {
        return this.$store.getters.detailActor
      },
      shortDescription () {
        if (this.actor.activities_description && !_isEmpty(this.actor.activities_description)) {
          return this.actor.activities_description
        }

        if (this.actor.description_stripped_tags) {
          return this.actor.description_stripped_tags
        }

        return this.actor.shortDescription
      },
      portfolioActor () {
        return this.$store.getters.portfolioActor
      },
      isMember () {
        return this.$store.getters.isMember
      },
      isActor () {
        return this.$store.getters.isActor
      },
      isPortfolioMember () {
        return this.$store.getters.isPortfolioMember
      },
      isDeleted () {
        return this.company && this.company.deleted_at
      },
      hasScores () {
        return this.$store.getters.hasScores || this.$store.getters.hasAccessToScores // Make hasScores obsolete
      },
      isCompetitive () {
        return this.$store.getters.isCompetitive
      },
      canCompare () {
        return this.portfolioActor.id && this.portfolioActor.id !== this.company.id
      },
      hasRelations () {
        for (var i = 0; i < this.relationshipTypes.length; i++) {
          if (this.company[this.relationshipTypes[i]] && this.company[this.relationshipTypes[i]].length) {
            return true
          }
        }
      },
      typeLabels () {
        const labels = {}
        for (var i = this.ecosystemRelationships.length - 1; i >= 0; i--) {
          labels[this.ecosystemRelationships[i].name] = this.ecosystemRelationships[i].label
        }
        return labels
      },
      competitionNumbers () {
        var mentions = 0
        var lost = 0
        var won = 0
        var churned = 0

        var previousMentions = 0
        var previousLost = 0
        var previousWon = 0
        var previousChurned = 0

        if (this.company.won_lost_mentions) {
          mentions = this.company.won_lost_mentions.mentions || mentions
          lost = this.company.won_lost_mentions.losses || lost
          won = this.company.won_lost_mentions.wins || won
          churned = this.company.won_lost_mentions.churned || churned
        }

        if (this.company.previous_won_lost_mentions) {
          previousMentions = this.company.previous_won_lost_mentions.mentions || previousMentions
          previousLost = this.company.previous_won_lost_mentions.losses || previousLost
          previousWon = this.company.previous_won_lost_mentions.wins || previousWon
          previousChurned = this.company.won_lost_mentions.previousChurned || previousChurned
        }

        return [{
          value: mentions,
          description: 'Times ' + this.company.name + ' has been mentioned in deals',
          previousDescription: this.company.name + ' has been mentioned ' + previousMentions + ' in the previous month',
        }, {
          value: lost,
          description: 'Deals lost to ' + this.company.name,
          previousDescription: previousLost + ' deals were lost to ' + this.company.name + ' in the previous month',
        }, {
          value: won,
          description: 'Deals won from ' + this.company.name,
          previousDescription: previousWon + ' deals were won from ' + this.company.name + ' in the previous month',
        },
          {
            value: churned,
            description: 'Customers lost to ' + this.company.name,
            previousDescription: previousChurned + ' customers were lost to ' + this.company.name + ' in the previous month',
          }]
      },
      lastScores () {
        return _sortBy((this.company.country_scores || []).filter(s => s.country_code === 'ALL'), 'date').reverse()[1] || {}
      },
      digitalFootprintData () {
        return this.company.scores && this.company.scores.digital_footprint
      },
      businessSizeData () {
        return this.company.scores && this.company.scores.business_size
      },
      significantDevelopments () {
        return this.company.scores
      },
      latestUpdate () {
        return moment(this.company.updated_at).format('D/M/YYYY')
      },
      canShowBuzz () {
        if (!this.company.twitter || this.company.twitter.length < 1) {
          return false
        }

        const configuredDashboards = this.$store.state.config.viewDashboard || []

        var options = viewDashboardOptions.filter(opt => configuredDashboards.includes(opt.value))

        return options.filter(item => item.value == 'buzz').length > 0 && this.isBuzzEnabled
      },
      isTeamMember () {
        return this.$store.getters.isTeamMember
      },
      companyContacts () {
        var hasEmployees = this.containsPerson('has_employee')
        var hasFounders = this.containsPerson('is_founded_by')
        var hasAdministrators = this.containsPerson('has_administrators')

        if (!hasEmployees && !hasFounders && !hasAdministrators) {
          return []
        }

        var employees = this.company.has_employee || []
        var founders = this.company.is_founded_by || []
        var administrators = this.company.has_administrators || []

        var possibleContacts = employees.concat(founders).concat(administrators)
        possibleContacts = possibleContacts.filter(possibleContact => possibleContact.actor_type == 'Person')

        var allContacts = possibleContacts.map(contact => {
          return {
            id: contact.to,
            name: contact.to_name,
            employment: contact.employment,
            phone: contact.phone,
            email: contact.email,
          }
        })

        return _uniqBy(allContacts, 'id')
      },
    },
    methods: {
      handleConnectionSuggestionApproved () {
        this.fetch()
      },
      removeContributor (contributor) {
        removeContributor({ user_id: contributor.user_id, actorId: this.$route.params.id })
          .then(data => {
            Object.assign(this.contributors, { errors: {}, message: null }, data)
            this.fetchContributors()
          })
      },
      fetchContributors () {
        // Reset the object
        this.contributors = { loading: true, email: '', list: [], errors: {}, message: null }

        if (!this.$route.params.id) {
          return
        }

        fetchContributors(this.$route.params.id, { includeOwners: true })
          .then(list => {
            const options = []

            // Object.keys(list)
            //   .forEach(key => {
            //     options.push({
            //       id: list[key].user_id,
            //       name: list[key].user_name,
            //       avatar: list[key].photo_url,
            //     })
            //   })

            Object.assign(this.contributors, { list, loading: false })
          })
          .catch(this.handleContributorError)
      },
      capitalize,
      toFullMonth,
      toAddressLine: addressLines,
      autosave (prop, value) {
        this.save()
      },
      lazySave: _debounce(function () {
        this.save()
      }, 1000),
      save () {
        const changes = Object.keys(this.unsavedActor).map(key => {
          return this.unsavedActor[key] !== undefined && this.unsavedActor[key] !== null
        }).filter(Boolean)
        if (!changes.length) {
          return
        }

        this.saving = true
        this.$store.dispatch('ACTORS/UPDATE_ACTOR', { id: this.company.id, data: this.unsavedActor })
          .then(() => {
            // If a value was changed while saving, keep it in unsavedActor
            Object.keys(this.unsavedActor).forEach(prop => {
              if (this.unsavedActor[prop] === this.company[prop] || typeof this.company[prop] === 'object') {
                this.unsavedActor[prop] = undefined
              }
            })
            this.saving = false
          })
      },
      discardChanges () {
        // Add missing props
        const missingProps = {
          subsidiaries: [],
        }
        for (const prop in missingProps) {
          if (!(prop in this.company) || this.company[prop] === null) {
            this.company[prop] = missingProps[prop]
          }
        }

        // Save editable copy
        this.actor = inert(this.company)
      },
      officeSaved () {
        this.show.addOffice = 0
        this.fetch(true)
      },
      rmOffice (office) {
        this.actor.subsidiaries.splice(this.actor.subsidiaries.indexOf(office), 1)
      },
      showClaimModal () {
        this.$store.commit(ACTORS_MUTATION_TYPES.SET_ACTOR_FOR_PREVIEW, this.company)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CLAIM)
      },
      handleAddRelation (relationType, actor) {
        const relation = this.actor[relationType] || []
        relation.push({
          from: this.actor.id,
          from_name: this.actor.name,
          to: actor.id,
          to_name: actor.name,
          start: null,
          end: null,
        })
        updateActor({
          id: this.actor.id,
          data: {
            [relationType]: relation,
          },
        }).then(() => {
          this.fetch(true)
        })
      },
      handleRemoveRelation (relationType, index) {
        if (!window.confirm('Are you sure you want to remove this connection?')) {
          return
        }
        this.actor[relationType].splice(index, 1)
        updateActor({
          id: this.actor.id,
          data: {
            [relationType]: this.actor[relationType],
          },
        }).then(() => {
          this.fetch(true)
        })
      },
      handleRemoveRelationById (relationType, id) {
        if (!window.confirm('Are you sure you want to remove this connection?')) {
          return
        }
        this.actor[relationType] = this.actor[relationType].filter(relation => relation.to !== id)
        updateActor({
          id: this.actor.id,
          data: {
            [relationType]: this.actor[relationType],
          },
        }).then(() => {
          this.fetch(true)
        })
      },
      setFeaturedImage (file) {
        updateActor({
          id: this.actor.id,
          data: {
            featured_image_url: file ? file.banner || file.preview_w766 || file.url : null,
          },
        }).then(() => {
          this.fetch(true)
        })
      },
      fetch (force) {
        this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_DETAIL, force === true || this.$route.params.id)
          .then(() => {
            trackHeapEvent('actorDetail.load', {
              actorType: this.actor.actor_type,
              id: this.actor.id,
              name: this.actor.name,
              category: this.actor.category ? this.actor.category.label : '',
            })
            if (force === true) {
              this.fetchSuggestions()
            }
          })
      },
      fetchSuggestions (complete) {
        if (!this.isOwner && !this.isTeamMember) {
          return
        }

        if (!this.company.id) {
          return
        }

        const actor_id = this.company.id

        Suggestions.get('', { actor_id, limit: 100 })
          .then(data => {
            // Check if it's still the same actor
            if (this.company.id === actor_id) {
              this.suggestions = data
            }
          })

        if (!this.isOwner || !this.displayAgents) {
          return
        }

        // Fetch the agent status
        fetchAgentStatus(actor_id, false)
          .then(response => {
            var statusses = response

            var agents = []

            // If any of the agents that the actor can use has meta data, change the flag
            if (this.canAdministratorAgentBeUsed) {
              agents.push('get_administrators')
            }

            if (this.canInvestorAgentBeUsed) {
              agents.push('get_investors')
              agents.push('get_investments')
            }

            if (this.canAcquisitionAgentBeUsed) {
              agents.push('get_acquisitions')
            }

            if (this.canFoundingTeamAgentBeUsed) {
              agents.push('get_founders')
            }

            if (this.canCurrentTeamAgentBeUsed) {
              agents.push('get_team')
            }

            /*if (this.canContactsAgentBeUsed) {
              agents.push('get_contacts')
            }*/

            if (this.$store.getters.isDeveloper) {
              agents.push('crawl_portfolio')
            }

            agents.forEach(agent => {
              if (!statusses[agent]) {
                return
              }

              if (statusses[agent].meta && statusses[agent].meta.length > 0) {
                this.hasAgentSuggestions = true
              }
            })
          })
          .catch()
      },
      getNestedNumeral (actor, prop) {
        return toAmount(getNested(actor, prop) || 0)
      },
      visitorsThroughAlexaRank (alexaRank) {
        var number = Math.round(Math.pow(alexaRank, -1.008) * 104943144672)
        return toAmount(number)
      },
      goToNext () {
        const { data } = this.$store.state.actors.listData
        const next = 1 + data.findIndex(a => a.id === this.company.id)

        this.suggestions = []
        this.hasAgentSuggestions = false

        if (data[next]) {
          // The next actor is cached
          return this.$router.push('/actors/' + data[next].id)
        }

        if (this.$store.state.filters.paging.offset < this.$store.getters.lastPageOffset) {
          // Fetch next page
          this.$store.commit('FILTERS/NEXT_PAGE')
        }

        return this.$store.dispatch('ACTORS/FETCH_ACTORS_LIST_NOW', this.$store.getters.listFilterObject)
          .then(() => {
            const { data } = this.$store.state.actors.listData
            // Load first actor on the page
            if (data && data.length) {
              this.$router.push('/actors/' + data[0].id)
            }
          })
      },
      roundScore (score) {
        if (score == null) {
          return
        }

        return Math.round(score)
      },
      openSidePanel (actorId) {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'scores', metaData: actorId })
      },
      openEditSidePanel (actorId) {
        // There's an issue that arises where the edit panel isn't popping in from the first try
        // Since we cannot reproduce this issue, it seems that the user, before encountering this issue
        // does a bunch of things on the platform, then eventually lands on the edit side panel but needs to click a couple of times on the "edit" before it shows up
        // Ideas checked:
        // - slow network - ruled out, the sidepanel appears and the data is just filled in slower
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'actor-edit', metaData: { id: actorId } })
      },
      openAgentsPanel (actorId) {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'agents', metaData: { id: actorId } })
      },
      openSuggestionsSidePanel (actorId) {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'actor-suggestions', metaData: actorId })
      },
      openSimilarSidePanel (actorId) {
        if (this.$store.getters.isExplorationOnly) {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
            component: 'similar-actors-exploration',
            metaData: actorId,
          })
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'similar-actors', metaData: actorId })
        }
      },
      openPostsSidePanel () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          component: 'posts-panel',
          metaData: { topic: this.buzzParameters.topic, actor: this.actor.id },
        })
      },
      openDuplicatesPanel () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'actor-duplicates-panel', metaData: {} })
      },
      resetBuzzParameters () {
        this.$store.commit(BUZZ_FILTER_MUTATION_TYPES.RESET_BUZZ_FILTERS)
      },
      fetchDuplicateActors () {
        fetchDuplicateActors(this.$route.params.id).then(data => {
          if (data.length > 0) {
            this.show.duplicateActors = true
            var filteredList = []
            // Get inside the duplicate actors array and start filtering out the data
            data.map(item => item.duplicates).map(item => {
              // Return array of objects with just the Id and Name
              return item.map(duplicate => {
                return { id: duplicate.elastic_id, name: duplicate.name }
              })
            }).map(item => {
              item.forEach(data => {
                // Get all the Ids inside the filtered list in order to avoid duplicate entries
                var idList = filteredList.map(listItem => {
                  return listItem.id
                })
                if (!idList.includes(data.id)) {
                  // Check if the duplicate is not the current actor
                  if (this.actor.id !== data.id) {
                    filteredList.push(data)
                  }
                }
              })
            })
            this.duplicateActors = filteredList
            this.fetchNewDuplicateActors = false
          } else {
            this.duplicateActors = []
            this.show.duplicateActors = false
          }
        }).catch(error => {
          console.log('Error while fetching duplicates', error)
          this.fetchNewDuplicateActors = false
        })
      },
      mergeDuplicateActor (duplicateActor) {
        var actorId = this.actor.id || this.$route.params.id
        if (actorId !== undefined && duplicateActor.id !== undefined) {
          mergeDuplicateActors(actorId, duplicateActor.id).then(data => {
            this.fetchNewDuplicateActors = true
          }).catch(error => {
            console.warn('Failed to merge actors', actorId, duplicateActor.id, error)
          })
        }
      },
      denyActorRecommendation (duplicateActor) {
        removeActor(duplicateActor.id)
          .then(data => {
            if (!data.deleted) {
              throw new Error(data)
            }
            this.fetchNewDuplicateActors = true
          })
          .catch(errors => {
            console.warn('Failed to delete actor', errors)
          })
      },
      setActiveTab (tabId) {
        this.$refs.tabs.setActiveTab(tabId)
      },
      fetchProfileData () {
        const actorId = this.$route.params.id

        if (!actorId || this.confirmedRemovedActor) {
          return
        }

        fetchActorTelex(actorId).then((data) => {
          this.profileData = data.data
        }).catch((errors) => {
          console.log(errors)
        })
      },
      fetchBuzzData () {
        var filter = {}

        filter = Object.assign({}, this.$store.getters.buzzQueryParameters)
        filter = Object.assign({ type: 'topics-influencers', actor: this.$route.params.id }, filter)

        return fetchBuzzData(filter)
      },
      handleMainContentScroll: _debounce(function (e) {
        this.hasScrolledAwayFromTop = e.target.scrollTop > 20
      }, 200, { leading: true }),
      handleClickContactActor () {
        if (!this.actor.show_contact_cta_url) {
          if (this.isActorClaimed) {
            trackHeapEvent('actorDetail.clickConversation', { name: this.actor.name })
            Conversations.post({ actor_id: this.actor.id }).then((conversation) => {
              this.$router.push('/profile/conversations/' + conversation.id)
            })
          }
        } else {
          trackHeapEvent('actorDetail.clickGetInTouch', { name: this.actor.name })
        }
      },
      handleClickCreateAnnouncement (type) {
        this.showCreateModal(type, this.$route.params.id)
      },
      handleRedirectToConnections () {
        // We set the currect active tab in the tabs component
        this.setActiveTab('Connections')
      },
      showMobileReportsSection () {
        // TODO for notes implementation: show notes on mobile
        // this.isMobileReportsSectionVisible = true
      },
      hideSidePanel () {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      saveTabToUserSettings (tab) {
        this.$store.commit('USER/STORE_SETTINGS', { actorProfileActiveTab: tab })
      },
      trackAnnouncementClick () {
        // console.log('Tracking announcement click')
        trackHeapEvent('actorDetailSimplified.clickAnnouncement')
      },
      setActorRelevantInIds (actor) {
        if (actor && actor.relevant_in) {
          this.actorRelevantInIds = actor.relevant_in.map(x => x.id)
        } else {
          this.actorRelevantInIds = []
        }
      },
      handleActorRelevant (conceptSearchId) {
        updateActorRelevancy(this.actor.id, true, conceptSearchId)
      },
      handleActorNotRelevant (conceptSearchId) {
        updateActorRelevancy(this.actor.id, false, conceptSearchId)
      },
    },
    async mounted () {
      this.discardChanges()
      this.fetchProfileData()
      this.fetchContributors()

      this.$bus.on('updateContributors', () => {
        this.fetchContributors()
      })

      this.$bus.on('removeActorConfirmed', () => {
        this.confirmedRemovedActor = true
        this.company.deleted_at = 'now'
        // the 'watch' will be triggered again
      })

      this.$bus.on('actorUpdated', (id) => {
        if (id === this.actor.id) {
          this.fetch(true)

          fetchActorTelex(this.actor.id)
            .then((data) => {
              this.profileData = data.data
            })
            .catch((errors) => {
              console.log(errors)
            })
        }
      })

      this.$bus.on('selectReportTemplate', () => {
        if (this.$refs && this.$refs.mainContent && this.$refs.mainContent.scrollTop) {
          this.$refs.mainContent.scrollTop = 0
        }
      })

      this.$bus.on('tab', (tabName) => {
        this.saveTabToUserSettings(tabName)
        this.hideReport()
      })

      this.$bus.on('productAddedViaOverlay', (actor) => {
        const relation = [...this.actor['has_product']] || []

        relation.push({
          to: actor.id,
          to_name: actor.name,
          start: null,
          end: null,
        })

        updateActor({
          id: this.actor.id,
          data: {
            'has_product': relation,
          },
        }).then(() => {
          this.fetch()
        })
      })

      this.$bus.on('announcementCreated', () => {
        this.fetch()
      })
    },
    created () {
      // When entering the actor profile we close any side panel that might be open
      this.hideSidePanel()

      this.fetch()

      if (this.isOwner) {
        this.fetchSuggestions()
      }

      this.setActorRelevantInIds(this.actor)
    },
    beforeUnmount () {
      this.$bus.off('actorUpdated')
      this.$bus.off('updateContributors')
      this.$bus.off('selectReportTemplate')
      this.$bus.off('tab')
      this.$bus.off('productAddedViaOverlay')

      this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
    },
    watch: {
      company (val) {
        this.discardChanges()
        this.actor = val

        if (this.confirmedRemovedActor) {
          // Don't call this.fetch as this will give an infinite loop!
          this.goToNext()
        } else {
          this.fetchProfileData()
        }
      },
      actor (val) {
        this.setActorRelevantInIds(val)
      },
      async 'company.id' (val, old) {
        if (val !== old) {
          // Reset data props
          this.hasAgentSuggestions = false
          this.hasBuzzData = false
          this.confirmedRemovedActor = false

          this.fetchSuggestions()

          if (!this.canShowBuzz || !this.isBuzzEnabled) {
            return
          }

          const buzzData = await this.fetchBuzzData()

          if (buzzData.topics && buzzData.topics.data.length > 0) {
            this.hasBuzzData = true
          }
        }
      },
      hasAgentSuggestions (v) {
        if (v && this.isOwner && this.$route.hash == '#agents') {
          this.openAgentsPanel(this.company.id)
        }
      },
      async '$route.params.id' (to, from) {
        // This is being called before the component is thrown away (i.e. user goes to a different page than the actor profile) so we need to check if we're on the applicable route
        if (to !== from && this.$route.path.startsWith('/actors')) {
          this.fetch()
          this.discardChanges()
          this.fetchProfileData()

          // This is being called before the component is destroyed (i.e. user goes to a different page than the actor profile) so we need to check if we're on the applicable route
          if (this.$route.path.startsWith('/actors')) {
            // Reset the avatars contributors list
            this.contributors = { email: '', list: [], errors: {}, message: null }

            this.fetchContributors()
          }

          // Reset data props
          // this.hasAgentSuggestions = false
          this.hasBuzzData = false
          this.confirmedRemovedActor = false

          if (!this.canShowBuzz || !this.isBuzzEnabled) {
            return
          }

          const buzzData = await this.fetchBuzzData()

          if (buzzData.topics && buzzData.topics.data.length > 0) {
            this.hasBuzzData = true
          }

          // If the tab isn't available, move to the default tab
          if (!this.$refs.tabs) {
            return
          }

          if (!this.tabs.includes(this.$refs.tabs.activeTab)) {
            this.setActiveTab(this.tabs[0])
          }
        }
      },
    },
    mixins: [
      AnnouncementMixin,
      ConversationsMixin,
      CompanyMixin,
      FiltersMixin,
      UiMixin,
      TranslationsMixin,
      ReportMixin,
    ],
    components: {
      ActorDetailContributors,
      RelevantEditPopup,
      SocialItem,
      Avatar,
      ActorDeleted,
      ActorScores,
      ActorSuggestions,
      AddressModal,
      Badge,
      ClassicCard,
      CompetitionNumbersContainer,
      ContentBlock,
      DsTextarea,
      FileList,
      FileInput,
      KnowledgeSharing,
      MiniChart,
      Modal,
      OfficeInput,
      RelationCreate,
      RelationEdit,
      SimpleMap,
      Tabs,
      BubbleChart,
      TimelineChart,
      BuzzChartCloseUp,
      DuplicateActorsDropdown,

      AsideSection,
      Keyword,
      NewCard,
      DefinitionList,
      CardInfoSocial,

      ActorDetailProfile,
      ActorDetailInfo,
      ActorDetailGraphConnections,
      ActorDetailOffering,
      ActorDetailBuzz,
      ActorDetailMobile,
      ActorDetailContacts,
      ActorDetailReport,
      ActorDetailNotes,
      ActorSustainability,
      ActorScrapedKeywords,
    },
  }
</script>
<style lang="scss" scoped>
  .highlight-spotting-area-button-map {
    position: absolute;
    left: 15px;
    bottom: 23px;
  }
</style>
<style lang="scss">
  @import "../../../scss/variables";
  @import "../../../scss/mixins/icon-color";

  .inactive_link {
    text-decoration: none !important;
    color: $color-text-grey;
  }

  .membership {
    position: relative;
    height: 0;
  }

  .membership__select {
    position: absolute;
    bottom: 2rem;
    right: 0;
    border: 0;
    height: 20px;
    font: inherit;
    color: inherit;
    background: white;
  }

  .membership__placeholder {
    position: absolute;
    bottom: 2rem;
    right: 1rem;
    width: 100px;
    line-height: 20px;
    pointer-events: none;
  }

  .country-scores {
    columns: 2;
    list-style-type: none;
    line-height: 1.5rem;
  }

  .country-scores__score {
    display: inline-block;
    min-width: 25px;
    opacity: .6;
  }

  .country-scores__flag {
    vertical-align: middle;
    display: inline-block;
    width: 16px;
    height: 12px;
    margin-right: 4px;
    background: #ccc;
    background-size: cover;
  }

  .contributor {
    display: inline-block;
    border-radius: 0;
    margin-top: 7px;
    margin-right: 4px;
    border: 0;
    padding: .5em 1em;
    background-color: #eee;
  }

  .contributor__remove {
    float: left;
    margin: -.5em .5em -.5em -1em;
    padding: .5em .75em;
    cursor: pointer;
    user-select: none;

    &:hover {
      background: #FD916D;
      color: white;
    }
  }

  a.card__sub-title {
    text-decoration: none;

    .card:hover & {
      text-decoration: underline;
    }
  }

  .actor-detail .tabs__navigation {
    margin-bottom: 10px;
  }

  .blurred_text {
    color: transparent;
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .actor-detail__header {
    position: relative;
    margin-bottom: 1rem;

    @media screen and (max-width: $screen-md) {
      margin: 1rem;
    }
  }

  .actor-detail__innovation_score {
    z-index: 1;
    position: absolute;
    top: 2rem;
    left: 25rem;
    background-color: #313340;
    color: white;
    padding: 8px;
    border-radius: 45px;
    width: fit-content;
    align-self: center;
    height: 30px;
    margin: auto;
    text-align: center;
    fill: white;

    .actor-detail__innovation_score-loading {
      :deep(.fill-fg) {
        fill: white;
      }
    }
  }

  .actor-detail__intro-card {
    width: 100%;
    max-width: 350px;
    max-height: 80%;
    position: absolute;
    top: 2rem;
    left: 2rem;
    padding: 1rem;
    z-index: 3;
    border-left: 8px solid;
    background-color: #31333F;
    color: $color-text-light;

    @media screen and (max-width: $screen-md) {
      display: block;
      position: initial;
      top: initial;
      left: 1rem;
      right: 1rem;
      height: 240px;
      max-width: none;
      width: auto;
      transform: none;
    }
  }

  .actor-detail--scrolled-from-top {
    .actor-detail__intro-card {
      height: 160px;
    }

    .actor-detail__content {
      margin-top: 200px !important;
    }

    .actor-detail__intro-card__body {
      > p {
        display: none;
      }
    }
  }

  .actor-detail__intro-card__header {
    display: flex;
    margin-bottom: 1rem;
    text-transform: uppercase;
  }

  .actor-detail__intro-card__header__title {
    font-size: 1rem;
    letter-spacing: 1px;
    position: relative;
    margin-bottom: 0.5rem;
  }

  .actor-detail__intro-card__header__title__underline {
    position: absolute;
    left: 0;
    bottom: -0.2rem;
    width: 1rem;
    height: 2px;
  }

  .actor-detail__intro-card__header__subtitle {
    font-style: italic;
    font-size: 0.75rem;
    color: rgba($color-text-light, 0.7);

    strong {
      color: $color-text-light;
    }
  }

  .actor-detail__intro-card__body {
    font-size: 0.75rem;
    @include iconColor(#fff);
    word-break: break-word;

    .card__info__social {
      padding: 0;
      margin-bottom: 0.5rem;
    }

    p {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: pre-line;
      line-clamp: 3;
      -webkit-line-clamp: 3;
      display: -webkit-box;
      -webkit-box-orient: vertical;
    }
  }

  .button.actor-detail__duplicates-btn {
    position: absolute !important;
    top: 1rem;
    right: 7rem;
    z-index: 10;

    @media screen and (max-width: $screen-sm) {
      display: none;
    }
  }

  .actor-detail__top-right-btn-container {
    position: absolute !important;
    top: 1rem;
    right: 7rem;
    z-index: 10;
    display: flex;

    @media screen and (max-width: $screen-sm) {
      display: none;
    }
  }

  .button.actor-detail__next-btn {
    position: absolute !important;
    top: 1rem;
    right: 1rem;
    z-index: 10;

    @media screen and (max-width: $screen-sm) {
      display: none;
    }
  }

  .actor-detail--mobile {
    .button--ks {
      display: none;
    }
  }
</style>
