<template>
  <div class="collapsable-tab__content-container">
    <editor-language-dropdown v-model="editingLocale" :show-auto-translate="true" :is-translating="isTranslating" @auto-translate="handleAutoTranslate" style="margin-bottom: 20px;"/>
    <form-section>
      <div class="row" style="margin-bottom: 0rem;">
        <form-group :label="$t('edit_actor_segment_description_activities_description')" class="col-xs-12">
          <ds-textarea autoresize rows="1" v-model="translatedActivitiesDescription" maxlength="100"/>
          <other-locale-preview :editing-locale="editingLocale" :values="activitiesDescriptionTranslations" style="margin-top: 10px;"/>
        </form-group>
      </div>
      <form-group
        :label="modifiedActor.actor_type === 'Product' ? $t('edit_actor_segment_description_additional_information', { product: productLabel }) : labelDescription"
        instructions=""
        :class="{'actor-edit__private-field': isPrivateField('description')}"
        class="collapsable-tab__actor-description">
        <rich-text-area v-model="translatedDescription"
                        class="announcement-detail-body-style"></rich-text-area>
        <other-locale-preview :editing-locale="editingLocale" :is-html="true"
                              :values="descriptionTranslations"
                              style="margin-top: 10px;"/>
      </form-group>
      <form-group :label="$t('edit_actor_segment_description_additional_information', {product: 'product'})"
                  :class="{'actor-edit__private-field': isPrivateField('additional_information')}"
                  v-if="isRelevantForActor('additional_information', modifiedActor)">
        <rich-text-area v-model="translatedAdditionalInformation"
                        class="announcement-detail-body-style"></rich-text-area>
        <other-locale-preview :editing-locale="editingLocale" :is-html="true"
                              :values="additionalInformationTranslations"
                              style="margin-top: 10px;"/>
      </form-group>
      <form-group :label="$t('edit_actor_segment_description_tags')" instructions="" :errors="errors.tags" :class="{'actor-edit__private-field': isPrivateField('tags')}">
        <AutocompleteTagInput
          :tags="actorTags"
          :options="tagOptions"
          :placeholder="$t('add_actor_tags_placeholder')"
          @tagChanged="handleTagChanged"
          @input:raw="updateTags"
        />
      </form-group>
      <form-group v-if="isOwner || isMember" label="Private tags" instructions="" :errors="errors.private_tags" :class="{'actor-edit__private-field': isPrivateField('private_tags')}">
        <AutocompleteTagInput
          :tags="actorPrivateTags"
          :options="tagOptions"
          @tagChanged="handleTagChanged"
          @input:raw="updatePrivateTags"
        />
      </form-group>
    </form-section>

  </div>
</template>

<script>

import {
  localizedEditProperty,
  localizedEditPropertyValues,
} from '../../../util/TranslationsMixin.js'
import { translateText } from '../../../api/contents.js'
import { DEFAULT_LOCALE } from '../../../store/modules/localization.js'
import TagsMixin from '../../../util/TagsMixin.js'
import EditorLanguageDropdown from '../../Translations/EditorLanguageDropdown.vue'
import DsTextarea from '../../Form/DsTextarea.vue'
import DsInput from '../../Form/DsInput.vue'
import Dropdown from '../../Dropdown/Dropdown.vue'
import FormSection from '../../Form/FormSection.vue'
import AutocompleteTagInput from '../../Form/AutocompleteTagInput.vue'
import RichTextArea from '../../TextArea/RichTextArea.vue'
import OtherLocalePreview from '../../Translations/OtherLocalePreview.vue'
import TagInput from '../../Form/TagInput.vue'
import { isRelevantForActor } from '../../../util/helpers.js'

export default {
  name: 'actor-edit-description',
  props: {
    modifiedActor: Object,
    errors: Object,
  },
  data () {
    return {
      isTranslating: false,
      editingLocale: this.$store.getters.activeLocale || DEFAULT_LOCALE,
    }
  },
  computed: {
    isPublisherEnabled () {
      return this.$store.getters.isPublisherEnabled
    },
    actorTags () {
      return (this.modifiedActor.tags || [])
        .map(tag => {
          return {
            text: tag.label,
            optionValue: {
              text: tag.label,
              value: tag.value || tag.label
            }
          }
        })
    },
    actorPrivateTags () {
      return (this.modifiedActor.private_tags || [])
        .map(tag => {
          return {
            text: tag.label,
            optionValue: {
              text: tag.label,
              value: tag.value || tag.label
            }
          }
        })
    },
    publicFields () {
      return this.$store.getters.publicFields
    },
    isMember () {
      return this.$store.getters.isMember
    },
    isOwner () {
      return this.$store.getters.isOwner
    },
    labelDescription () {
      return this.$t('edit_actor_segment_description_description')
    },
    actorName () {
      return this.$store.getters.detailActor.name
    },
    translatedDescription: localizedEditProperty('editingLocale', 'modifiedActor', 'description'),
    descriptionTranslations: localizedEditPropertyValues('editingLocale', 'modifiedActor', 'description'),
    translatedActivitiesDescription: localizedEditProperty('editingLocale', 'modifiedActor', 'activities_description'),
    activitiesDescriptionTranslations: localizedEditPropertyValues('editingLocale', 'modifiedActor', 'activities_description'),
    translatedAdditionalInformation: localizedEditProperty('editingLocale', 'modifiedActor', 'additional_information'),
    additionalInformationTranslations: localizedEditPropertyValues('editingLocale', 'modifiedActor', 'additional_information'),
  },
  methods: {
    isRelevantForActor,
    updateTags (tags) {
      this.modifiedActor.tags = tags.map(tag => {
        return {
          value: tag.optionValue ? tag.optionValue.value : null,
          label: tag.text
        }
      })
    },
    updatePrivateTags (tags) {
      this.modifiedActor.private_tags = tags.map(tag => {
        return {
          value: tag.optionValue ? tag.optionValue.value : null,
          label: tag.text
        }
      })
    },
    handleTagChanged (tag) {
      this.updateTagOptions(tag)
    },
    isPrivateField (field) {
      return this.isPublisherEnabled && !this.publicFields.includes(field)
    },
    async handleAutoTranslate () {
      this.isTranslating = true

      try {
        const result = (await translateText({ sourceLanguage: 'en', targetLanguage: this.editingLocale, body: this.modifiedActor.activities_description }))
        if (result && result.body) this.translatedActivitiesDescription = result.body
      } catch (e) {
        console.error(e)
      }

      try {
        const result = (await translateText({ sourceLanguage: 'en', targetLanguage: this.editingLocale, body: this.modifiedActor.description }))
        if (result && result.body) this.translatedDescription = result.body
      } catch (e) {
        console.error(e)
      }

      try {
        const result = (await translateText({ sourceLanguage: 'en', targetLanguage: this.editingLocale, body: this.modifiedActor.additional_information }))
        if (result && result.body) this.translatedAdditionalInformation = result.body
      } catch (e) {
        console.error(e)
      }

      this.isTranslating = false
    },
  },
  components: {
    EditorLanguageDropdown,
    DsTextarea,
    DsInput,
    Dropdown,
    FormSection,
    AutocompleteTagInput,
    RichTextArea,
    OtherLocalePreview,
    TagInput,
  },
  mixins: [TagsMixin]
}
</script>
