import { fetchAnalytics, fetchChart, getFinancials } from '../../api/analytics'

import { chartTransformer } from '../../components/Chart/chartsMixin.js'

const state = {
  charts: {
    data: [],
    splitBy: null,
    expandedChart: null,
    loading: false,
    error: null,
  },
  financials: {
    loading: false,
    data: [],
    selectedField: 'total_assets',
    error: null
  },
}

export const MUTATION_TYPES = {
  // Basic analytics groups
  FETCH_CHARTS_PENDING: 'ANALYTICS/FETCH_CHARTS_PENDING',
  FETCH_CHARTS_SUCCESS: 'ANALYTICS/FETCH_CHARTS_SUCCESS',
  FETCH_CHARTS_FAILURE: 'ANALYTICS/FETCH_CHARTS_GROUP_FAILURE',
  EXPAND_CHART: 'ANALYTICS/EXPAND_CHART',

  // Financials
  FETCH_FINANCIALS_PENDING: 'ANALYTICS/FETCH_FINANCIALS_PENDING',
  FETCH_FINANCIALS_SUCCESS: 'ANALYTICS/FETCH_FINANCIALS_SUCCESS',
  FETCH_FINANCIALS_FAILURE: 'ANALYTICS/FETCH_FINANCIALS_FAILURE',
  SET_FINANCIALS_FIELD: 'ANALYTICS/SET_FINANCIALS_FIELD',
}

export const ACTION_TYPES = {
  FETCH_CHARTS: 'ANALYTICS/FETCH_CHARTS',
  FETCH_FINANCIALS: 'ANALYTICS/FETCH_FINANCIALS',
}

export const mutations = {
  // Fetch analytics
  'ANALYTICS/FETCH_CHARTS_PENDING' (state) {
    state.charts.loading = true
  },
  'ANALYTICS/FETCH_CHARTS_SUCCESS' (state, data) {
    state.charts.data = data
    state.charts.loading = false
  },
  'ANALYTICS/FETCH_CHARTS_FAILURE' (state, errors) {
    state.charts.data = []
    state.charts.loading = false
    state.charts.errors = errors
  },
  'ANALYTICS/EXPAND_CHART_SUCCESS' (state, chart) {
    if (state.charts.expandedChart === chart) {
      chart
    }

    // Make sure the chart has the standard x-axis configuration (not the horizontally switched one)
    if (chart) {
      chart.horizontal = false
    }

    state.charts.splitBy = null
    state.charts.expandedChart = chartTransformer(chart) || chart

    // Trigger a re-render of all of the charts,
    // because underneath by changing configuration of the expanded chart
    // the render is called on the other charts as well
    // which make them being drawn on a 0 px wide pane so it seems
    var charts = state.charts.data.slice()
    state.charts.data = charts
  },

  'ANALYTICS/SPLIT_CHART_PENDING' (state, splitBy) {
    state.charts.splitBy = splitBy
  },

  'ANALYTICS/SPLIT_CHART_SUCCESS' (state, chart) {
    chart.percentual = state.charts.expandedChart.percentual

    state.charts.expandedChart = chartTransformer(chart) || chart
  },

  'ANALYTICS/FLIP_HORIZONTAL_SUCCESS' (state, chart) {
    chart.horizontal = !chart.horizontal
    var chartIndex = state.charts.data.findIndex(c => chart.id == c.id)

    var chartsCopy = state.charts.data.slice()
    chartsCopy[chartIndex] = chart

    state.charts.data = chartsCopy
  },

  'ANALYTICS/FLIP_PERCENTUAL_SUCCESS' (state, chart) {
    chart.average = false
    chart.percentual = !chart.percentual
    var chartIndex = state.charts.data.findIndex(c => chart.id == c.id)

    var chartsCopy = state.charts.data.slice()
    chartsCopy[chartIndex] = chart

    // Trigger a Vue reactivity event, changing the chart in the array doesn't do the trick
    state.charts.data = chartsCopy

    if (state.charts.expandedChart && chart.id == state.charts.expandedChart.id) {
      var expandedCopy = state.charts.expandedChart
      expandedCopy.percentual = chart.percentual

      state.charts.expandedChart = chartTransformer(expandedCopy)
    }
  },

  'ANALYTICS/FLIP_AVERAGE_SUCCESS' (state, chart) {
    chart.percentual = false
    chart.average = !chart.average
    var chartIndex = state.charts.data.findIndex(c => chart.id == c.id)

    var chartsCopy = state.charts.data.slice()
    chartsCopy[chartIndex] = chart

    // Trigger a Vue reactivity event, changing the chart in the array doesn't do the trick
    state.charts.data = chartsCopy

    if (state.charts.expandedChart && chart.id == state.charts.expandedChart.id) {
      var expandedCopy = state.charts.expandedChart
      expandedCopy.average = chart.average

      state.charts.expandedChart = chartTransformer(expandedCopy)
    }
  },

  // Financials
  [MUTATION_TYPES.FETCH_FINANCIALS_PENDING] (state) {
    state.financials.loading = true
  },
  [MUTATION_TYPES.FETCH_FINANCIALS_SUCCESS] (state, data) {
    state.financials.data = data
    state.financials.loading = false
  },
  [MUTATION_TYPES.SET_FINANCIALS_FIELD] (state, field) {
    state.financials.selectedField = field
  },
}

export const actions = {
  'ANALYTICS/FETCH_CHARTS' ({ commit }, filters) {
    commit('ANALYTICS/FETCH_CHARTS_PENDING')
    fetchAnalytics(filters)
      .then(data => commit('ANALYTICS/FETCH_CHARTS_SUCCESS', data))
      .catch((errors) => {
        commit('ANALYTICS/FETCH_CHARTS_GROUP_FAILURE', errors)
      })
  },
  'ANALYTICS/EXPAND_CHART' ({ commit }, chart) {
    commit('ANALYTICS/EXPAND_CHART_SUCCESS', chart)
  },
  'ANALYTICS/FLIP_HORIZONTAL' ({ commit }, chart) {
    commit('ANALYTICS/FLIP_HORIZONTAL_SUCCESS', chart)
  },
  'ANALYTICS/FLIP_PERCENTUAL' ({ commit }, chart) {
    commit('ANALYTICS/FLIP_PERCENTUAL_SUCCESS', chart)
  },
  'ANALYTICS/FLIP_AVERAGE' ({ commit }, chart) {
    commit('ANALYTICS/FLIP_AVERAGE_SUCCESS', chart)
  },
  'ANALYTICS/SPLIT_CHART' ({ commit }, { splitBy, filters }) {
    if (!state.charts.expandedChart) {
      return
    }

    commit('ANALYTICS/SPLIT_CHART_PENDING', splitBy)

    const { type, property } = state.charts.expandedChart
    const queryParameters = Object.assign({}, { property, splitBy }, filters)

    fetchChart(type, queryParameters)
      .then(data => commit('ANALYTICS/SPLIT_CHART_SUCCESS', data))
      .catch(() => { })
  },

  [ACTION_TYPES.FETCH_FINANCIALS] ({ commit }, { filters, field }) {
    commit(MUTATION_TYPES.FETCH_FINANCIALS_PENDING)
    getFinancials(filters, field).then(data => commit(MUTATION_TYPES.FETCH_FINANCIALS_SUCCESS, data))
      .catch(errors => {
        // Ignore
      })
  },
}

export default {
  state,
  mutations,
  actions
}
