<template>
  <div>
    <keyword
      v-if="$store.state.filters.portfolio && canRemove && topBarPortfolioName"
      class="top--bar--keyword--portfolio"
      :label="topBarPortfolioName"
      @remove="unsetPortfolio"
      :removeable="canRemove"
    />
    <keyword
      v-else-if="$store.state.filters.portfolio && topBarPortfolioName"
      class="top--bar--keyword--portfolio"
      :label="topBarPortfolioName"
    />
  </div>
</template>

<script>
  import Keyword from './Keyword.vue'
  import { MUTATION_TYPES as PORTFOLIO_MUTATION_TYPES } from '../../store/modules/managePortfolio'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'

  export default {
    computed: {
      typeOfPanel () {
        return this.$store.state.ui.sidePanel.selectedComponent
      },
      canRemove () {
        return (this.isPortfolioMember && this.$route.path !== '/dashboards/analytics') || !this.isPortfolioMember
      },
      topBarPortfolioName () {
        var portfolioName = this.$store.getters.activePortfolio.name

        if (!portfolioName) {
          return this.$store.state.managePortfolio.activePortfolio.name
        }

        return portfolioName
      },
      isPortfolioMember () {
        return this.$store.getters.isPortfolioMember
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
    },
    methods: {
      unsetPortfolio () {
        if (!this.canRemove) {
          return
        }

        this.$store.commit('FILTERS/SET_PORTFOLIO', null)
        this.$store.commit(PORTFOLIO_MUTATION_TYPES.CLEAR_ACTIVE_PORTFOLIO)

        if (this.typeOfPanel === 'edit-portfolio') {
          this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
        }
      },
    },
    components: {
      Keyword,
    },
  }
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

  .top--bar--keyword--portfolio::before {
    display: none !important;
  }

  .top--bar--keyword--portfolio {
    background-color: $color-primary !important;
    border: transparent !important;
    padding-left: 5px !important;
    padding-right: 20px !important;

    .keyword__label {
      color: white;
    }

    svg path {
      fill: white !important;
      stroke: white !important;
    }

    .svg-icon--remove {
      left: auto !important;
      right: 0px;
    }
  }

  .top--bar--keyword--portfolio:hover {
    background-color: $color-primary !important;
    border: 1px solid white !important;

    .keyword__label {
      color: white !important;
    }
  }
</style>
