<template>
  <div class="dropdown-button">
    <icon class="dropdown-button__icon" :class="{'color-kennis-west': isKennisWest}"
          :name="icon" :size="size"/>
    <select class="dropdown-button_select" @input="handleSelect" @click.stop="onClickButton">
      <option
          v-for="option in options"
          :value="JSON.stringify(option.value)"
          :disabled="option.disabled || false"
          :selected="option.selected || false"
      >
        {{ option.label }}
      </option>
    </select>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    icon: {
      type: String,
    },
    size: {
      type: String,
    },
    options: {
      type: Object,
    },
    isKennisWest: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onClickButton() {
      this.$emit('on-click-button')
    },
    handleSelect(event) {
      this.$emit('select', JSON.parse(event.target.value))
    },
  },
})
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.dropdown-button__icon {
  opacity: 0.5;
}

.dropdown-button {
  position: relative;
  display: inline-block;

  &:hover .dropdown-button__icon {
    opacity: 1;
  }
}

.dropdown-button_button {
  margin-right: 0;
  position: relative;
  z-index: 1;
  pointer-events: none;
}

.dropdown-button_select {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

:deep(.color-kennis-west) svg {
  path {
    fill: $kenniswest-icon-color !important;
  }
}
</style>

<style lang="scss">
.dropdown-button__icon .svg-icon path {
  fill: #555;
}
</style>
