<template>
  <transition name="modal">
    <div class="modal__mask" @click="blur">
      <all-filters-modal-content :filters-to-display="filtersToDisplay"
                                 :show-change-badges="true"
                                 @update-state="updateState" :is-closeable="isCloseable"
                                 @close="close" :selected-filters="selectedFilters"/>
    </div>
  </transition>
</template>
<script>
  import AllFiltersModalContent from './AllFiltersModalContent.vue'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui.js'
  import MODAL_IDS from '../../../constants/modal-ids.js'

  import { isTaxonomy } from '../../../store/modules/filters.js'
  import { getTranslationsForTaxonomyValue } from '../../../store/modules/taxonomies.js'

  export default {
    props: {
      isCloseable: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      filtersToDisplay () {
        return this.$store.state.ui.modalContext.filtersToDisplay || []
      },
      selectedFilters () {
        return this.$store.state.ui.modalContext.selectedFilters || []
      },
    },
    methods: {
      updateState ({ name, content }) {
        let updatedFilters = [...this.selectedFilters]
        let facet, value, values

        if (content) {
          facet = content.facet
          value = content.value
          values = content.values
        }

        switch (name) {
          case 'FILTERS/TOGGLE_LEGEND_ITEM':
            const index = updatedFilters.findIndex(k => k.value === value && k.facet === facet)

            if (index > -1) {
              updatedFilters.splice(index, 1)
            } else {
              updatedFilters.push({ facet, value })
            }
            break
          case 'FILTERS/SET_BY_FACET':
            updatedFilters = updatedFilters.filter(k => k.facet !== facet)

            // Accept single values
            if (!Array.isArray(values)) {
              values = values === null ? [] : [values]
            }

            values.forEach(v => {
              const existingEntry = updatedFilters.find(k => k.value === v && k.facet === facet)
              const keyword = { facet: facet, value: v }

              // If the keyword is a taxonomy value, then add the i18n labels to the keyword object
              if (isTaxonomy(facet)) {
                var translations = getTranslationsForTaxonomyValue(facet, v) || {}

                keyword['translations'] = translations
              }

              if (!existingEntry) {
                updatedFilters.push(keyword)
              }
            })
            break
          case 'FILTERS/CLEAR':
            updatedFilters = []
            break
          default:
            console.log('no filter configured for ' + name)
        }
        this.$bus.emit('customFiltersModal.update', updatedFilters)
      },
      blur () {
        if (this.closeOnBlur) {
          this.close()
        }
      },
      close () {
        if (this.isCloseable) {
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
          this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.CUSTOM_FILTERS)
          this.$emit('close')
        }
      },
    },
    components: { AllFiltersModalContent },
  }
</script>
