<template>
  <div class="ui search suggestion-input">
    <div class="suggestion-input__container">
      <input class="prompt" type="text" :placeholder="label" :value="modelValue" @input="handleValueChange" :id="id"
          ref="mainInput">
      <icon :name="icon"/>
    </div>
    <div class="results"></div>
  </div>
</template>

<script type="module">
import FormGroup from '../Form/FormGroup.vue'
import { actorLogo } from '@/util/actor.js'
import escapeHTML from '../../util/escapeHTML'
import { defineComponent } from 'vue'

function renderStandardResultsFromResponse (response) {
  // the avatar is based on Avatar.vue with plain JS for the error-fallback because we do not have access to Vue here
  return response.results.map(r => {
    const logoSrc = actorLogo(r.data)
    const avatar = logoSrc
        ? `<img class="avatar__img" src="${escapeHTML(logoSrc)}" alt="${escapeHTML(r.title)}" onerror="this.classList.add('is-hidden');this.nextElementSibling.classList.remove('is-hidden')" />
         <div class="avatar__text is-hidden">${escapeHTML(r.title[0])}</div>`
        : `<div class="avatar__text">${escapeHTML(r.title[0])}</div>`

    let subTitleHtml = ''

    if (window.config.includeCompanyNumberInSearch == true && r.data.company_number) {
      subTitleHtml = '<div class="title subtitle">' + escapeHTML(r.data.jurisdiction ? r.data.jurisdiction : '') + escapeHTML(r.data.company_number ? r.data.company_number : '') + '</div>'
    } else if (r.data.actor_type) {
      var actorType = r.data.actor_type || ''

      if (r.data.actor_type == 'LegalEntity') {
        actorType = 'Company'
      }

      subTitleHtml = '<div class="title subtitle">' + escapeHTML(actorType) + '</div>'
    }

    return `
        <a class="result">
          <div class="avatar avatar--border">
          ${avatar}
          </div>
          <div class="title">${escapeHTML(r.title)}</div>
          ${subTitleHtml}
        </a>
      `
  })
}

function getLabelFrom (val) {
  return typeof val === 'string' ? val : val && (val.name || val.label || val.title)
}

export default defineComponent({
  props: {
    id: null,
    modelValue: null,
    autofocus: null,
    options: null,
    placeholder: {
      type: String,
      default: 'Search...',
    },
    icon: {
      type: String,
      default: 'search',
    },
    renderResults: {
      type: Function,
      default: renderStandardResultsFromResponse,
    },
    apiSettings: {
      type: Object,
    },
    source: {
      type: Array,
    },
    clearOnSelect: {
      type: Boolean,
      default: false,
    },
    showNoResults: {
      type: Boolean,
      default: true,
    },
    isGlobalSearch: {
      type: Boolean,
      default: false,
    },
    humanLabel: '',
  },
  emits: ['update:modelValue', 'cancelCustomInput', 'customInput', 'on-select', 'actor'],
  data: () => ({
    query: '',
    selectedItem: false,
  }),
  computed: {
    label () {
      return this.humanLabel || getLabelFrom(this.modelValue) || this.placeholder
    },
  },
  methods: {
    clear () {
      $(this.$el).search('set value', '')
    },
    handleValueChange (e) {
      const value = e.target.value
      this.$emit('update:modelValue', value)

      if (value.length === 0) {
        this.query = ''
        this.$emit('cancelCustomInput')
      }
    },
    focus () {
      if (!this.isGlobalSearch) {
        this.$nextTick(() => {
          this.$refs.mainInput.focus()
        })
      }
    },
  },
  mounted () {
    const searchOptions = {
      cache: false,
      maxResults: 50,
      searchDelay: 30,
      showNoResults: this.showNoResults,
      onSelect: (item) => {
        this.selectedItem = true
        this.$emit('on-select', item)
        this.$emit('update:modelValue', item.data)
        this.$emit('actor', item.data)
        this.$emit('cancelCustomInput')

        this.$nextTick(() => {
          $(this.$el).search('set value', this.clearOnSelect ? '' : getLabelFrom(item))
        })
      },
      onSearchQuery: query => {
        this.query = query
        this.selectedItem = false
        this.$emit('customInput', query)
      },
      templates: {
        standard: this.renderResults,
      },
    }

    if (this.options) {
      Object.assign(searchOptions, this.options)
    }

    if (this.source) {
      searchOptions.source = this.source
    }

    if (this.apiSettings) {
      searchOptions.apiSettings = this.apiSettings
    }

    $('.ui.search').search(searchOptions)
  },
  components: {
    FormGroup,
  },
})
</script>
