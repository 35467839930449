<template>
  <div class="fixed-heading scrollable">
    <div class="heading">
      <h1 class="score-list__heading">News Overview</h1>
      <p class="guide_text">An overview of the most relevant news based on your configured searches and feedback.</p>
    </div>
    <div class="news-overview__container">
      <div class="scrollable news-overview__articles_container" ref="scrollable">
        <div style="margin-top: 1rem;">
          <h1 class="h1">Most Relevant Articles</h1>
        </div>
        <div style="margin-top: 1rem;">
          Timeframe: &nbsp;
          <label-filter
            @updateFilter="setDateFilter"
            v-for="(timeSpanOption, index) in timespanOptions"
            :model-value="timeSpanOption.value"
            :key="'timespanOptions' + index"
            :is-enabled="filters.timespan === timeSpanOption.value"
            :text="timeSpanOption.label"/>
        </div>

        <template v-if="fetching">
          <loading/>
        </template>
        <template v-else-if="articles.length > 0">
          <articles-panel
            :results="articles"
            :fetching-content="fetching"
            :user-can-edit="userCanEdit"
            :open-link-directly="true"
            @mark-relevant="markArticleRelevant"
            @remove-article="showDeleteArticleConfirmationModal"
            @like-article="likeArticle"
            relevancy-mode="global"
          />
        </template>
        <template v-else>
          <div style="margin-top: 1rem;">
            Unfortunately we didn't find any relevant content for your selected time frame, change your selection to search again.
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import { getRelevantNewsTimeline, updateFileRelevancy } from '../../api/exploration'
  import { deleteEcosystemFile } from '../../api/files'

  import ArticlesPanel from './ConceptMap/ArticlesPanel.vue'
  import Loading from './ConceptMap/Loading.vue'
  import LabelFilter from '../Filters/LabelFilter.vue'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'
  import { likeArticle } from '../../api/knowledge-base'


  export default {
    name: 'NewsOverview',
    data () {
      return {
        articles: [],
        filters: {
          timespan: 'day',
        },
        fetching: false,
        timespanOptions: [
          {
            value: 'day',
            label: 'last 24 hours',
          },
          {
            value: 'week',
            label: 'last 7 days',
          },
          {
            value: 'month',
            label: 'last 30 days',
          },
          {
            value: 'year',
            label: 'last year',
          },
        ],
      }
    },
    computed: {
      userCanEdit () {
        return this.$store.getters.isOwner || this.$store.getters.isTeamMember
      },
    },
    methods: {
      showDeleteArticleConfirmationModal (article) {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, { body: 'Do you want to delete this article?', modalContextType: 'global-article', article: article })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
      },
      deleteArticle (articleId) {
        deleteEcosystemFile(articleId)
          .then(response => {
            this.articles = this.articles.filter(article => article.sql_media_id !== articleId)
          })
          .catch(errors => {
            console.log(errors)
          })
      },
      fetchTimeline () {
        this.fetching = true

        getRelevantNewsTimeline(this.filters)
          .then(response => {
            if (response.results) {
              this.articles = response.results
            }

            this.fetching = false
          })
          .catch(error => {
            console.log(error)
            this.fetching = false
          })
      },
      setDateFilter (value) {
        this.filters.timespan = value
      },
      markArticleRelevant ({ article, isRelevant, conceptSearchId }) {
        const originalRelevant = article.relevant_in ? article.relevant_in.includes(conceptSearchId) : false

        updateFileRelevancy(article.sql_media_id, isRelevant, conceptSearchId)
          .catch(err => {
            article.relevant = originalRelevant
            console.log(err)
          })
          .then(() => {
            article.relevant = isRelevant
          })
      },
      likeArticle ({ resourceId, status }) {
        likeArticle(resourceId, status)
      },
    },
    mounted () {
      this.fetchTimeline()

      this.$bus.on('globalArticleDeleteConfirmation', (context) => {
        this.deleteArticle(context.article.resource.sql_media_id)
      })
    },
    beforeUnmount () {
      this.$bus.off('globalArticleDeleteConfirmation')
    },
    components: {
      ArticlesPanel,
      Loading,
      LabelFilter,
    },
    watch: {
      filters: {
        deep: true,
        handler () {
          this.fetchTimeline()
        },
      },
    },
  }
</script>

<style scoped>
  .news-overview__container {
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .news-overview__articles_container {
    width: 60%;
    min-height: 500px;
    max-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }

</style>
