<template>
  <with-configuration-tabs>
    <div class="profile fixed-heading">
      <div class="profile-update__header">
        <div class="profile-update__header__upper-container">
          <h2 class="profile-update__header__info">
            <span class="profile-update__header__title">{{ $t('conversations_title') }}</span>
          </h2>
          <a v-if="isOwner" href="/settings/users">Back to User Management</a>
        </div>
      </div>

      <div class="scrollable has-padding">
        <div class="row">
          <div class="col-lg-6 col-md-8 col-sm-12">
            <router-link :to="`/profile/conversations/${conversation.id}`"
                         v-for="(conversation, index) in conversations" :key="index">
              <div class="conversation-preview">
                <div class="conversation-preview__title"
                     v-if="conversation.creator_id === userId">
                  {{ conversation.recipient_name }}
                </div>
                <div class="conversation-preview__title"
                     v-else-if="conversation.recipient_id === userId">
                  {{ conversation.creator_name }}
                </div>
                <div class="conversation-preview__subtitle">{{ $t('conversations_last_message') }}:
                  {{ timeAgoTranslated(conversation.updated_at) }}
                </div>
                <div class="conversation-preview__content"
                     v-if="conversation.last_comment">
                  <rich-body-content :html="conversation.last_comment.comment"/>
                </div>
              </div>
            </router-link>
          </div>
        </div>

      </div>
    </div>
  </with-configuration-tabs>
</template>

<script>
  import WithConfigurationTabs from '../../pages/WithConfigurationTabs/WithConfigurationTabs.vue'
  import Card from '../Card/Card.vue'
  import { timeAgoTranslated } from '../../util/date.js'
  import RichBodyContent from '../RichBodyContent/RichBodyContent.vue'

  export default {
    data () {
      return {}
    },
    mixins: [],
    computed: {
      isOwner () {
        return this.$store.getters.isOwner
      },
      conversations () {
        return this.$store.state.conversations.listData.data
      },
      userId () {
        return this.$store.getters.userId
      },
    },
    mounted () {

    },
    methods: {
      timeAgoTranslated,
    },
    components: {
      RichBodyContent,
      WithConfigurationTabs,
      Card,
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/_variables.scss";

  a {
    text-decoration: none;
  }

  .conversation-preview {
    border: 1px solid $color-borders;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
    padding: 1rem;
    margin-bottom: 1em;
  }

  .conversation-preview__title {
    font-size: 14px;
    margin-bottom: 5px;
    letter-spacing: 1px;
    font-weight: 500;
    line-height: 16px;
    text-transform: uppercase;
    color: $color-text-grey;
  }

  .conversation-preview__subtitle {
    font-size: 12px;
    margin-bottom: 1rem;
    color: $color-text-grey-light
  }

  .conversation-preview__content {
    word-break: break-word;
    flex: 1;
    font-size: 11px;
    font-weight: 100;
    line-height: 16px;
    color: $color-text-grey;
    overflow: hidden;
    max-height: 100px;
  }

  :deep(.rich-body-content) {
    margin-bottom: 0;
  }
</style>
