<template>
  <div class="page-forgot-password" :class="{ 'page-authentication': !closeable }">
    <forgot-password-modal :is-closeable="closeable" @close="$router.push('/')"></forgot-password-modal>
  </div>
</template>

<script>
import ForgotPasswordModal from '../components/Modals/ForgotPasswordModal.vue'

export default {
  computed: {
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    },
    closeable () {
      return this.$store.getters.hasAccessToPublisher && this.$store.state.config.allow_anonymous
    }
  },
  mounted () {
    if (this.isLoggedIn) {
      this.$router.push('/')
    }
  },
  components: {
    ForgotPasswordModal
  }
}
</script>
