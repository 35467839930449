<template>
  <div class="non-ideal-state" :class="`non-ideal-state--${variant}`">
    <div class="non-ideal-state__title" v-if="title">{{title}}</div>
    <slot name="cta"></slot>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'NonIdealState',
  props: {
    title: {
      type: String
    },
    variant: {
      type: String,
      default: 'default'
    }
  }
})
</script>

<style lang="scss">
  .non-ideal-state {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 6rem 4rem;
  }

  .non-ideal-state__title {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 11px;
    margin-bottom: 1rem;
  }
</style>
