export const GLOBAL_SEARCH_RESULT_TYPES = {
  ANNOUNCEMENT: 'announcement',
  EVENT: 'event',
  CHALLENGE: 'challenge',
  ALL: 'all',
  ACTOR: 'actor',
  RESOURCE: 'resource',
  ARTICLE: 'article',
  PRODUCT: 'product',
}

export const GLOBAL_SEARCH_RESULT_TYPE_ICONS = {
  [GLOBAL_SEARCH_RESULT_TYPES.ALL]: 'view-gallery',
  [GLOBAL_SEARCH_RESULT_TYPES.ACTOR]: 'building',
  [GLOBAL_SEARCH_RESULT_TYPES.PRODUCT]: 'light-bulb',
  [GLOBAL_SEARCH_RESULT_TYPES.ANNOUNCEMENT]: 'bull-horn',
  [GLOBAL_SEARCH_RESULT_TYPES.EVENT]: 'calendar',
  [GLOBAL_SEARCH_RESULT_TYPES.CHALLENGE]: 'message',
  [GLOBAL_SEARCH_RESULT_TYPES.ARTICLE]: 'newspaper',
  [GLOBAL_SEARCH_RESULT_TYPES.RESOURCE]: 'layer-group',
}
