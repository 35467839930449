<template>
  <NewCard title="Inform (Knowledge Base)">
    <p>The graph below shows the evolution of total resources and the interaction amongst them.</p>

    <div v-if="inform.total_resources || inform.total_views || inform.total_downloads" class="chart-container">
      <div class="chart-main">
        <SimpleLineChart :data="chartData" :show-final-value="true"/>
      </div>
      <div class="chart-aside">
        <ChartLegend :data="chartData" :show-final-value="true"/>
      </div>
    </div>

    <br>
    <div v-if="showMostPopularResources">
      <table class="table table--fill table--hover table--striped table--borderless">
        <thead>
        <tr>
          <td colspan="4"><strong>Most popular resources</strong></td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td><strong>Resource title</strong></td>
          <td><strong>Unique users</strong></td>
        </tr>
        <tr v-for="resource in mostPopularResources">
          <td>{{ resource.title }}</td>
          <td>{{ resource.unique_views }}</td>
        </tr>
        </tbody>
      </table>
    </div>

  </NewCard>
</template>

<script>
  import NewCard from '../../../components/NewCard/NewCard.vue'
  import EcosystemInfoCard from './EcosystemInfoCard.vue'
  import ChartLegend from './ChartLegend.vue'
  import { parseChartData } from '../../Chart/chartsMixin.js'
  import SimpleLineChart from '../../Chart/SimpleLineChart.vue'

  export default {
    props: {
      inform: Object,
      mostPopularResources: Object,
    },
    components: {
      NewCard,
      EcosystemInfoCard,
      ChartLegend,
      SimpleLineChart,
    },
    data () {
      return {
        showMostPopularResources: false,
      }
    },
    computed: {
      chartData () {
        if (!!this.mostPopularResources && typeof this.mostPopularResources === 'object') {
          this.showMostPopularResources = Object.keys(this.mostPopularResources).length > 0
        }
        const datasets = []

        if (this.inform.total_resources != null) {
          datasets.push({
            color: '#FBA52C',
            data: this.inform.total_resources_per_month,
            finalValue: this.inform.total_resources,
            cumulate: true,
            label: 'Total resources',
          })
        }

        if (this.inform.total_views != null) {
          datasets.push({
            color: '#2E59FA',
            data: this.inform.total_views_per_month,
            finalValue: this.inform.total_views,
            cumulate: true,
            label: 'Total views',
          })
        }

        if (this.inform.total_downloads != null) {
          datasets.push({
            color: '#AF52FF',
            data: this.inform.total_downloads_per_month,
            cumulate: true,
            finalValue: this.inform.total_downloads,
            label: 'Total downloads',
          })
        }

        return parseChartData(datasets)
      },
    },
    methods: {
      convertUrl (pageUrl) {
        return `${window.location.protocol}//${window.location.hostname}${pageUrl}`
      },
    },
  }
</script>
