<template>
  <div class="container" v-if="conceptSearch">
    <!-- Left side -->
    <div class="concept-map">
      <div class="concept-map__results" style="margin-top: 0;">
        <div
          class="concept-map__results-header"
          v-if="!simplifiedUi && !displayedArticle.showing"
        >
          <div class="concept-map__results-header-left">
            <div class="header-tabs">
              <router-link
                v-if="!isActorScouting"
                class="concept-map__results-category"
                :class="{'concept-map__results-category--selected': selectedLeftTab === LEFT_TABS.CONTENT}"
                :to="{name: 'ConceptMap', params: {id: $route.params.id, tab: LEFT_TABS.CONTENT}}"
              >
                Content<span
                v-if="results.contentTotalCount > 0"
              > ({{ results.contentTotalCount > 100 ? 100 : results.contentTotalCount }})</span>
              </router-link>

              <router-link
                v-if="!isActorScouting"
                class="concept-map__results-category"
                :class="{'concept-map__results-category--selected': selectedLeftTab === LEFT_TABS.TOP_KEYWORDS}"
                :to="{name: 'ConceptMap', params: {id: $route.params.id, tab: LEFT_TABS.TOP_KEYWORDS}}"
              >
                Keywords
              </router-link>

              <router-link
                v-if="!isActorScouting"
                class="concept-map__results-category"
                :class="{'concept-map__results-category--selected': selectedLeftTab === LEFT_TABS.TOP_SOURCES}"
                :to="{name: 'ConceptMap', params: {id: $route.params.id, tab: LEFT_TABS.TOP_SOURCES}}"
              >
                Sources
              </router-link>

              <template v-if="canDisplayActors">
                <router-link
                  class="concept-map__results-category"
                  :class="{'concept-map__results-category--selected': selectedLeftTab === LEFT_TABS.ACTORS}"
                  :to="{name: 'ConceptMap', params: {id: $route.params.id, tab: LEFT_TABS.ACTORS}}"
                >
                  Long list<span v-if="displayedActorsCount > 0"> ({{
                    displayedActorsCount
                  }})</span>
                </router-link>
              </template>
            </div>
          </div>
        </div>
        <br v-if="simplifiedUi">
        <div class="concept-map__results-content" ref="scrollable">
          <template v-if="!displayedArticle.showing">
            <ds-input
              v-if="selectedLeftTab === LEFT_TABS.ACTORS"
              v-model="searchQueryActors"
              :class="searchQueryActors ? 'has-values' : ''"
              id="introjs-refine-research-scope"
              placeholder="Search Actors"
            />
            <ds-input
              v-else-if="selectedLeftTab === LEFT_TABS.CONTENT"
              :model-value="searchQuery"
              id="introjs-refine-research-scope"
              :class="searchQuery ? 'has-values' : ''"
              placeholder="Search on words, free text"
              @update:modelValue="updateAllSearchQuery"
              @pressedEnter="searchAll()"
            />
          </template>
          <template v-if="selectedLeftTab === LEFT_TABS.CONTENT">
            <template v-if="displayedArticle.showing">
              <article-panel
                :article="displayedArticle"
                :all-articles="results.content"
                :conceptSearchId="conceptSearch.id"
                @go-to-next="goToNextArticle"
                @mark-relevant="markArticleRelevant"
                @add-tag-to-filters="addTagToIncludedKeywords"
              />
            </template>
            <template v-else>
              <div style="display: flex">
                <div
                  class="like-filter"
                  :key="componentKey"
                  style="margin: 20px 0 0 auto"
                  v-if="simplifiedUi && (fetchingContent || (results.content && results.content.length > 0))"
                >
                  <span
                    v-if="displayFilters"
                    @click="toggleLikesFilter"
                    :key="componentKey"
                  >Show only&nbsp;
                    <icon :name="contentFilters.isLikedByUser ? 'thumbs-up' : 'thumbs-up-outline'"/>
                  /></span>
                </div>
              </div>

              <div v-if="!simplifiedUi" style="display: flex; margin-top: 1rem; margin-bottom: 1rem;">
                <div
                  class="concept-map__results-sort" id="introjs-rank"
                  v-if="displaySorting"
                >
                  <div>
                    RANK CONTENT BASED ON:
                    <label-filter
                      @updateFilter="updateContentFiltersSort('relevance')"
                      value="relevance"
                      :is-enabled="contentFilters.sort === 'relevance'"
                      text="Relevancy"
                    >
                    </label-filter>
                    <label-filter
                      @updateFilter="updateContentFiltersSort('recency')"
                      value="recency"
                      :is-enabled="contentFilters.sort === 'recency'"
                      text="Recency"
                    >
                    </label-filter>
                  </div>
                </div>

                <div class="concept-map__content-relevancy-filter">
                  <IconButton
                    v-if="showLikesFilter"
                    label="SHOW LIKED"
                    :icon="contentFilters.isLikedByUser ? 'thumbs-up' : 'thumbs-up-outline'"
                    :fill="contentFilters.isLikedByUser"
                    @click="toggleLikesFilter"
                    icon-size="14"
                  />

                  <IconButton
                    v-if="displayFilters"
                    style="margin-left: 0.5rem"
                    label="SHOW SHORTLIST"
                    icon="star"
                    :fill="contentFilters.isRelevant"
                    @click="contentFilters.isRelevant = !contentFilters.isRelevant"
                  />
                </div>
              </div>
              <articles-panel
                v-if="!fetchingContent && results.content && results.content.length > 0"
                :results="results.content || []"
                :fetching-content="fetchingContent"
                :user-can-edit="userCanUpdateRelevancy"
                :open-link-directly="simplifiedUi && !isMember"
                :conceptSearchId="conceptSearch.id"
                @add-tag-to-filters="addTagToIncludedKeywords"
                @mark-relevant="markArticleRelevant"
                @like-article="likeArticle"
                @remove-article="removeArticle"
                @open-article="openArticle"
              />
            </template>

            <template v-if="!displayedArticle.showing && fetchingContent">
              <div class="concept-map__loading">
                <loading/>
              </div>
            </template>
            <template
              v-if="(!results.content || !results.content.length) && !fetchingContent && !displayedArticle.showing">
              <div style="display: flex">
                <div
                  class="like-filter" :key="componentKey" style="margin: 20px 0 0 auto"
                  v-if="simplifiedUi"
                >
                  <span
                    v-if="displayFilters"
                    @click="contentFilters.isLikedByUser = !contentFilters.isLikedByUser; componentKey++"
                  >Show only&nbsp; <icon :name="contentFilters.isLikedByUser ? 'thumbs-up' : 'thumbs-up-outline'"/>
                  /></span>
                </div>
              </div>
              <div class="concept-map__no-results">
                <template v-if="computedUserCanEdit">
                  <template v-if="conceptSearch.status === 'in_progress'">
                    We are tapping into hundreds of data sources about the subject.
                    Feel free to explore the platform & results while we are still processing the research.
                  </template>
                  <template v-else-if="errorWhileFetchingContent">
                    {{ errorMessage }}
                  </template>
                  <template v-else>
                    {{ noResultsMessage }}
                  </template>
                </template>
                <template v-else>
                  {{ noResultsMessage }}
                </template>
              </div>
            </template>
          </template>

          <template v-if="selectedLeftTab === LEFT_TABS.TOP_KEYWORDS">
            <div style="display: block; cursor: default;">
              <p style="margin-bottom: 20px;">These are the top keywords, based on articles that match the search
                criteria in the "Content" tab. You can add a keyword to an
                existing exploration topic by click on the
                icon to the right
                of
                the keyword name. Keywords highlighted in green are already part of at least one exploration topic.</p>
              <top-keywords-list :keywords="topKeywords"/>
            </div>
          </template>

          <template v-if="selectedLeftTab === LEFT_TABS.TOP_SOURCES">
            <div style="display: block; cursor: default;">
              <p style="margin-bottom: 20px;">These are the most occurring sources, based on articles that match the
                search criteria in the "Content" tab.</p>
              <top-sources-list :sources="topSources"/>
            </div>
          </template>

          <template v-if="selectedLeftTab === LEFT_TABS.ACTORS">
            <div
              v-if="!simplifiedUi"
              style="display: flex; margin-top: 1rem; justify-content: flex-end;"
            >
              <div class="concept-map__results-sort">
                <div class="concept-map__content-relevancy-filter">
                  <IconButton
                    v-if="displayFilters"
                    label="SHOW SHORTLIST"
                    icon="star"
                    :fill="actorFilters.isRelevant"
                    @click="actorFilters.isRelevant = !actorFilters.isRelevant"
                  />
                </div>
              </div>
            </div>

            <template v-if="fetchingActors">
              <loading/>
            </template>
            <template v-else-if="displayedActors && displayedActors.length > 0">
              <actors-panel
                :actors="displayedActors" :fetching-actors="fetchingActors"
                :conceptSearchId="conceptSearch.id"
                @mark-relevant="onToggleActorRelevance"
              />
            </template>
            <template v-else>
              <div class="concept-map__no-results">
                No results found, try changing the filters or update your search
                configuration.
              </div>
            </template>
          </template>
        </div>
      </div>
    </div>

    <!-- Right side -->
    <div class="knowledge-base" v-if="userCanFilterContent">
      <div class="concept-search-deleting-overlay" v-if="deleting">
        Deleting...
      </div>
      <div class="concept-map-image">
        <div class="concept-map-image__title">
          <h1
            class="concept-map-title"
            v-tooltip.left="conceptSearch.title"
          >
            <template v-if="!editingScopeAndTitle">
              <router-link
                :to="`/dashboards/concept-map/${$route.params.id}/${$route.params.tab || ''}`"
              >
                {{ conceptSearch.title }}
              </router-link>
            </template>
            <template v-else>
              <form-group :error="validationErrors && validationErrors.title" style="max-width: 70%;">
                <ds-input v-model="updatedTitle"/>
              </form-group>
            </template>
          </h1>
          <div v-if="!isActorScouting" :style="{ display: computedUserCanEdit ? 'flex' : 'block', marginTop: '-15px'}">
            <ds-button
              class="concept-search__title-small-button"
              variant="primary"
              size="extra-small"
              :label="'Lexicons'"
              @click="handleManageLexicons"
            />
            <ds-button
              class="concept-search__title-small-button"
              variant="primary"
              size="extra-small"
              :label="'News groups'"
              @click="handleManageNewsGroups"
            />
          </div>
        </div>
        <div class="concept-map-image__subtitle">
          <template v-if="!editingScopeAndTitle">
            <div style="display: flex; flex-direction: column; padding-right: 5px; width: 100%;">
              <p
                class="scope-description" v-if="conceptSearch.scope_description"
                ref="scopeDescription"
                :class="{ 'scope-description-expanded': !!showMoreScopeDescription }"
              >
                {{ conceptSearch.scope_description }}</p>
              <p
                class="scope-description__show-more"
                @click="showMoreScopeDescription = !showMoreScopeDescription"
                v-if="showMoreScopeDescription !== null || $refs.scopeDescription && $refs.scopeDescription.scrollHeight !== $refs.scopeDescription.clientHeight"
              >
                show {{
                  showMoreScopeDescription ? 'less' :
                    'more'
                }}</p>

              <p
                class="status" v-if="conceptSearch.status !== 'in_progress'"
                :title="reportTypeLabel + ', last run: ' + lastRun"
              >
                {{ reportTypeLabel }}, last run: {{ lastRun }}
              </p>
              <div v-else style="display: flex; flex-direction: row;">
                <p
                  class="status"
                  :title="reportTypeLabel + ', searching in progress...'"
                  v-tooltip.bottom="'We are tapping into hundreds of data sources about the subject. Feel free to explore the platform & results while we are still processing the research.'"
                >
                  {{ reportTypeLabel }}, searching in progress...
                  <a v-if="conceptSearch.status !== 'done' && $store.getters.isUserOfDataScoutsTeam"
                     @click.prevent="resetStatus" class="link-cancel-search">
                    click here to stop searching
                  </a>
                </p>
              </div>
            </div>
            <ds-button
              size="extra-small" variant="minimal" icon="edit"
              @click="showEditScopeDescription()"
              class="edit-scope-description-button"
              v-if="conceptSearch.status !== 'in_progress' && computedUserCanEdit"
            />
          </template>
          <template v-else>
            <ds-textarea v-model="updatedScopeDescription" style="margin-right: 5px;"/>
            <ds-button
              size="extra-small" variant="minimal" icon="check"
              @click="confirmUpdateTitleAndDescription"
              class="edit-scope-description-button"
            />
          </template>
        </div>

        <div class="concept-map__actions" style="margin-top: 7px;">
          <div :style="{ display: computedUserCanEdit ? 'flex' : 'block'}">
            <template v-if="hasAccessToMonitoring && !editingScopeAndTitle">
              <follow-button
                class="a-button" v-model="followed"
                v-if="!showingPublishButton && !simplifiedUi"
              />
              <follow-button
                class="a-button" v-model="followed"
                v-if="hasAccessToMonitoring && simplifiedUi"
              />
              <publish-button
                class="a-button"
                :model-value="isPublished()"
                @update:modelValue="setPublished($event)"
                v-if="showingPublishButton"
              />
            </template>
            <checkbox
              v-if="computedUserCanEdit && !editingScopeAndTitle"
              inline
              v-tooltip.left="'Enable the auto-rank functionality to automatically recalculate the relevancy score and the ranking right after you tag an article or actor as relevant.'"
              label="auto-rank" :model-value="conceptSearchAutoReloadEnabled"
              @update:modelValue="conceptSearchAutoReloadEnabled = !conceptSearchAutoReloadEnabled"
              class="concept-search__title-auto-refresh"
            />
            <ds-button
              class="concept-search__title-button"
              variant="minimal"
              icon="trash"
              heapEvent="conceptMap.clickDeleteSearch"
              size="extra-small"
              @click="remove"
              v-if="conceptSearch.status === 'done' && computedUserCanEdit && !$route.params.sub"
            />
          </div>
        </div>
      </div>
      <template v-if="! displayedArticle.showing || selectedLeftTab !== LEFT_TABS.CONTENT">
        <div class="concept-map-overview" v-if="!editing">
          <div class="filters__mask"
               v-if="editingScopeAndTitle && (!validationErrors || validationErrors.length === 0)"></div>
          <template v-if="canDisplayFilters && tabHasFilters">
            <div style="display:flex; flex-direction:row; justify-content:space-between;">
              <h4 class="h4">Filter {{
                  selectedLeftTab === LEFT_TABS.ACTORS ? 'long list' :
                    selectedLeftTab
                }}</h4>
            </div>
            <div v-if="selectedLeftTab === LEFT_TABS.ACTORS">
              <p
                v-for="(actorFilter, index) in actorFilterOptions"
                style="margin-top: 20px"
                :key="'actorFilter' + index"
              >
                {{ actorFilter.label }}
                <AutocompleteTagInput
                  :class="actorFilters[actorFilter.type].length > 0 ? 'has-values' : ''"
                  v-if="actorFilter.type === 'geoTags'"
                  :tags="actorFilters[actorFilter.type]"
                  :options="actorFilter.options"
                  @tagChanged="actorFilter.changeFunction"
                  @input:raw="actorFilter.rawChangeFunction($event, actorFilter.type)"
                  :addOnlyFromAutocomplete="true"
                  :minInputLength="1"
                  :placeholder="actorFilter.placeholder"
                  :show-error-on-empty-results="true"
                />
                <dropdown
                  :placeholder="actorFilter.placeholder"
                  :class="actorFilters[actorFilter.type].length > 0 ? 'has-values' : ''"
                  :valueIsOption="true"
                  multiple
                  :search="true"
                  :options="actorFilter.options"
                  v-model="actorFilters[actorFilter.type]"
                  @addNewItem="actorFilter.rawChangeFunction($event, actorFilter.type)"
                  v-else
                />
              </p>
            </div>
            <div
              class="filters"
              v-if="selectedLeftTab === LEFT_TABS.CONTENT && canDisplayFilters"
            >
              <p v-if="$store.getters.isMember && mediaTypeOptions.length > 0">
                Content type:
                <label-filter
                  @updateFilter="setMediaTypeFilter"
                  v-for="(option, key) in mediaTypeOptions"
                  :model-value="option.value"
                  :key="'mediaTypeOptions' + key"
                  :is-enabled="(contentFilters.mediaTypes && contentFilters.mediaTypes.length > 0 && option.value === contentFilters.mediaTypes[0]) || ((!contentFilters.mediaTypes ||  !contentFilters.mediaTypes.length) && !option.value)"
                  :text="option.label"
                  :disabled="updating || fetchingContent"
                >
                </label-filter>
              </p>
              <p>
                Content timeline:
                <label-filter
                  @updateFilter="setDateFilter($event, index)"
                  v-for="(timeSpanOption, index) in timeSpanOptions"
                  :model-value="timeSpanOption.value"
                  :is-enabled="timeSpanOption.isEnabled"
                  :key="'timespanOptions' + index"
                  :text="timeSpanOption.label"
                  :disabled="updating || fetchingContent"
                />
              </p>
              <p v-if="showLexiconFilter">
                Lexicons
                <span v-if="validationErrors && validationErrors.context" class="form-group__error">
                  Please select at least one lexicon
                </span>
                <dropdown
                  :multiple="true"
                  :options="topicOptions"
                  v-model="contentFilters.lexicons"
                  :search="true"
                  :disabled="updating || fetchingContent"
                />
              </p>
              <p>
                Geographical scope:
                <AutocompleteTagInput
                  @input:raw="setContentGeoFilter"
                  :class="contentFilters.geoTags.length > 0 ? 'has-values' : ''"
                  :tags="contentFilters.geoTags"
                  @tagChanged="updateArticleGeoTagOptions"
                  :options="geoTagOptions"
                  :addOnlyFromAutocomplete="true"
                  :minInputLength="1"
                  placeholder="Show content within a specific area"
                  :disabled="updating || fetchingContent"
                />
              </p>
              <p v-if="!simplifiedUi && !$store.getters.isActor">
                Specific research scope:
                <dropdown
                  :multiple="false"
                  :class="dictionaryGroup && dictionaryGroup.length > 0 ? 'has-values' : ''"
                  :options="filterableDictionaryGroups"
                  v-model="dictionaryGroup"
                  placeholder="Show content within a specific research scope"
                  :allowClear="true"
                  :disabled="updating || fetchingContent"
                />
              </p>
              <p>
                Keywords:
                <span v-if="validationErrors && validationErrors.search_topics" class="form-group__error">
                  Please select at least one keyword
                </span>
                <AutocompleteTagInput
                  :tags="contentFilters.includedTags"
                  :class="contentFilters.includedTags.length > 0 ? 'has-values' : ''"
                  :options="includeFilterOptions"
                  @input:raw="setIncludeFilter"
                  :suggestions="includedTagSuggestions"
                  @tagChanged="updateIncludeTagOptions"
                  :addOnlyFromAutocomplete="true"
                  :minInputLength="2"
                  placeholder="Show content including specific keywords"
                  acceptSuggestionLabel="Add keyword as filter"
                  denySuggestionLabel=""
                  @suggestion:accept="addSuggestionAsIncludedTag"
                  @suggestion:decline="removeTagFromIncludedTagSuggestions"
                  :disabled="updating || fetchingContent"
                />
              </p>

              <p v-if="$store.getters.isMember">
                Portfolios:
                <AutocompleteTagInput
                  :tags="contentFilters.portfolios"
                  :class="contentFilters.portfolios.length > 0 ? 'has-values' : ''"
                  :options="portfolioOptions"
                  :addOnlyFromAutocomplete="true"
                  placeholder="Show content where actors of portfolios are mentioned in"
                  @tagChanged="updatePortfolioOptions"
                  @input:raw="updateSelectedPortfolios"
                  :minInputLength="1"
                  :disabled="updating || fetchingContent"
                />
              </p>

              <p v-if="$store.getters.isMember">
                Excluded Lexicons:
                <dropdown
                  :multiple="true"
                  :options="excludedTopicOptions"
                  v-model="contentFilters.excludedLexicons"
                  placeholder="Exclude groups of keywords"
                  :searchable="true"
                  :disabled="updating || fetchingContent"
                />
              </p>
              <p v-if="$store.getters.isMember">
                News Outlets:
                <dropdown
                  :multiple="true"
                  :class="contentFilters.newsGroups && contentFilters.newsGroups.length > 0 ? 'has-values' : ''"
                  :options="newsGroupOptions"
                  v-model="contentFilters.newsGroups"
                  :searchable="true"
                  placeholder="Show content coming from a specific group of news outlets"
                  :allowClear="true"
                  :disabled="updating || fetchingContent"
                />
              </p>
              <p>
                Languages:
                <dropdown
                  v-if="$store.getters.hasAccessToMultiLinguality"
                  :multiple="true"
                  :class="contentFilters.languages && contentFilters.languages.length > 0 ? 'has-values' : ''"
                  :options="supportedLanguages"
                  v-model="contentFilters.languages"
                  :searchable="true"
                  placeholder="Show content in a specific language"
                  :allowClear="true"
                  :disabled="updating || fetchingContent"
                />
              </p>
            </div>
          </template>
          <div class="concept-map-overview__buttons" v-if="!simplifiedUi">
            <ds-button
              size="small" label="View actors shortlist in directory"
              variant="secondary" @click="goToDirectory"
              heapEvent="conceptMap.clickDirectory"
              v-if="conceptSearch.portfolio && canDisplayActors && selectedLeftTab === LEFT_TABS.ACTORS"
            />
            <ds-button
              v-if="selectedLeftTab === LEFT_TABS.CONTENT && !editingScopeAndTitle"
              variant="secondary"
              size="extra-small"
              :label="updating ? 'Saving...' : (updated ? 'Saved': 'Save filters')"
              @click="update"
              :disabled="disabledSaveButton || updating || updated || fetchingContent"
              :title="!disabledSaveButton ? '' : 'We are tapping into hundreds of data sources about the subject. Feel free to explore the platform & results while we are still processing the research.'"
            />
            <ds-button
              size="small" label="View content shortlist in knowledge base"
              variant="secondary" @click="goToKnowledgeBase"
              heapEvent="conceptMap.clickKnowledgeBase"
              :disabled="disableViewInKnowledgeBaseButton"
              v-tooltip.top="disableViewInKnowledgeBaseButton ? 'To view the content, saving the filters is required.' : ''"
              v-if="selectedLeftTab === LEFT_TABS.CONTENT"
            />
            <ds-button
              size="small" label="Export actor shortlist"
              :href="exportActorsLink" variant="secondary"
              heapEvent="conceptMap.clickExport"
              v-if="userCanExport && containsRelevantActors && selectedLeftTab === LEFT_TABS.ACTORS"
            />
            <ds-button
              size="small" label="Export content shortlist"
              :href="exportContentDataLink" variant="secondary"
              heapEvent="conceptMap.clickExport"
              v-if="userCanExport && containsRelevantContent && selectedLeftTab === LEFT_TABS.CONTENT"
            />
          </div>
        </div>
        <div class="concept-map-overview" v-else>
          <ConceptSearchGuide
            :start-at-step="startAtStep" :with-cancel="true"
            @done="handleEditDone" @cancel="editing = false"
          />
        </div>
      </template>
      <template v-else>
        <div class="concept-map-overview">
          <tabs
            :tabs="tabs"
            v-if="!displayedArticle.loadingData && !displayedArticle.loadingAnnotations"
            :tab-tooltips="tabTooltips"
          >
            <template v-slot:topics v-if="tabs.includes('topics')">
              <div>
                <article-overview-panel
                  :displayed-article="displayedArticle"
                  :user-can-edit="computedUserCanEdit"
                >
                </article-overview-panel>
              </div>
            </template>


            <template v-slot:relationships v-if="tabs.includes('relationships')">
              <div>
                <div
                  v-for="(relation, index) in annotatedRelations"
                  :key="'relation' + index" class="extracted-relation__container"
                >
                  <div
                    class="extracted-relation"
                  >
                    <span
                      class="extracted-actor"
                      :style="{opacity: getRelationStatus(relation) === RELATION_STATUS.DONE ? '1' : '0.5'}"
                      :class="{clickable: actorExistsOnEcosystem(relation.entity_A_alias, relation.entity_A_type)}"
                    >
                      <a
                        :href="getConnectionsUrlForActor(relation.entity_A_alias, relation.entity_A_type)"
                        v-if="getConnectionsUrlForActor(relation.entity_A_alias, relation.entity_A_type)"
                      >{{
                          relation.entity_A_alias
                        }}</a><template v-else>{{
                        relation.entity_A_alias
                      }}</template></span>
                    <span
                      :style="{color: getTextColorForRelation(relation.mappedRelation), opacity: getRelationStatus(relation) === RELATION_STATUS.DONE ? '1' : '0.5'}"
                    >{{
                        relation.mappedRelation.label
                      }}</span>
                    <span
                      class="extracted-actor"
                      :style="{opacity: getRelationStatus(relation) === RELATION_STATUS.DONE ? '1' : '0.5'}"
                      :class="{clickable: actorExistsOnEcosystem(relation.entity_B_alias, relation.entity_B_type)}"
                    >
                      <a
                        :href="getConnectionsUrlForActor(relation.entity_B_alias, relation.entity_B_type)"
                        v-if="getConnectionsUrlForActor(relation.entity_B_alias, relation.entity_B_type)"
                      >{{
                          relation.entity_B_alias
                        }}</a>
                      <template v-else>{{ relation.entity_B_alias }}</template>
                    </span>
                  </div>
                  <div
                    class="extracted-relation-icon" @click="addRelation(relation)"
                    :class="relationsInProgress.length ? 'disabled' : 'clickable'"
                    v-if="getRelationStatus(relation) === RELATION_STATUS.OPEN"
                  >+
                  </div>
                  <div
                    class="extracted-relation-icon"
                    v-else-if="getRelationStatus(relation) === RELATION_STATUS.DONE"
                  >
                    <icon name="link"/>
                  </div>
                  <div
                    class="extracted-relation-icon"
                    v-else-if="getRelationStatus(relation) === RELATION_STATUS.IN_PROGRESS"
                  >
                    <icon name="spinner"/>
                  </div>
                  <div
                    class="extracted-relation-icon"
                    v-else-if="relation.error"
                  >
                    ×
                  </div>
                </div>
              </div>
            </template>

            <template v-slot:actors>
              <div>
                <article-actor-panel
                  :actors="recognisedActorsInArticle"
                  :file="displayedArticle.resource"
                  :conceptSearch="conceptSearch"
                  @mark-relevant="markActorRelevant"
                ></article-actor-panel>
              </div>
            </template>
          </tabs>
        </div>
      </template>
    </div>
  </div>
</template>

<script lang="ts">
  import moment from 'moment'
  import debounce from 'lodash/debounce.js'

  import TopKeywordsList from '../TopKeywordsList/TopKeywordsList.vue'
  import TopSourcesList from '../TopSourcesList/TopSourcesList.vue'
  import Avatar from '../Avatar/Avatar.vue'
  import ConceptSearchGuide from '../../components/ConceptSearchGuide/ConceptSearchGuide.vue'
  import Tabs from '../Tabs/Tabs.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters.js'
  import { MUTATION_TYPES as KNOWLEDGE_BASE_MUTATION_TYPES } from '../../store/modules/knowledgeBase.js'
  import { MUTATION_TYPES as PORTFOLIO_MUTATION_TYPES } from '../../store/modules/managePortfolio.js'
  import { MUTATION_TYPES as CONCEPT_SEARCH_GUIDE_MUTATION_TYPES, REPORT_TYPES } from '../../store/modules/conceptSearchGuide.js'

  import {
    buildExportActorsLink,
    deleteConceptSearch,
    Dictionary,
    explore,
    fetchActorsForSearch,
    fetchGeographySuggestions,
    NewsGroups,
    publishConceptSearch,
    resetExplorationStatus,
    unpublishConceptSearch,
    updateActorRelevancy,
    updateConceptSearchAndExplore,
    updateFileRelevancy,
  } from '../../api/exploration.js'
  import { annotateArticle, buildExportContentLink, fetchKnowledgeBaseData, likeArticle, removeArticle, trackArticle } from '../../api/knowledge-base.js'
  import DictionaryMixin from '../../util/DictionaryMixin.js'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import DictionaryTag from '../Tag/DictionaryTag.vue'
  import ActorsPanel from './ConceptMap/ActorsPanel.vue'
  import ArticlesPanel from './ConceptMap/ArticlesPanel.vue'
  import ArticleActorPanel from './ConceptMap/ArticleActorPanel.vue'
  import ArticlePanel from './ConceptMap/ArticlePanel.vue'
  import Dropdown from '../Dropdown/Dropdown.vue'
  import ArticleOverviewPanel from './ConceptMap/ArticleOverviewPanel.vue'
  import { getFileDetails } from '../../api/files.js'
  import LandscapePanel from './ConceptMap/LandscapePanel.vue'
  import AnalyzeOverviewPanel from './ConceptMap/AnalyzeOverviewPanel.vue'
  import FollowButton from '../FollowButton/FollowButton.vue'
  import PublishButton from '../PublishButton/PublishButton.vue'
  import ConceptSearchMixin from '../../util/ConceptSearchMixin.js'
  import Loading from './ConceptMap/Loading.vue'
  import LabelFilter from '../Filters/LabelFilter.vue'
  import AutocompleteTagInput from '../Form/AutocompleteTagInput.vue'
  import { fetchRichTagSuggestion } from '../../api/contents.js'
  import { _unique, _uniqueBy, inert } from '../../util/helpers.js'
  import { getKeywordsForValue } from '../../api/keywords.js'
  import Checkbox from '../Form/Checkbox.vue'
  import { ActionTypes as CONCEPT_SEARCHES_ACTION_TYPES, MutationTypes as CONCEPT_SEARCHES_MUTATION_TYPES } from '../../store/modules/concept-searches.js'
  import IntroJsMixin from '../../util/IntroJsMixin.js'
  import _uniqBy from 'lodash/uniqBy.js'
  import ManageConceptSearchPopup from '../ManageConceptSearchPopup/ManageConceptSearchPopup.vue'
  import DsTextarea from '../Form/DsTextarea.vue'
  import FiltersMixin from '../../util/FiltersMixin.js'
  import { createActor, fetchActor, updateActor } from '../../api/actors.js'
  import { fetchPortfolio } from '../../api/portfolios.js'
  import { defineComponent } from 'vue'
  import IconButton from '@/Domain/Shared/Components/IconButton.vue'

  const EMPTY_RESULTS = {
    actors: [],
    actorsFromContent: [], // Deprecated
    content: [],
    contentTypeCounts: {
      rss: 0,
      patent: 0,
    },
    contentTotalCount: 0,
    // totalActorCount: 0,
  }
  const RELATION_STATUS = { OPEN: 'open', IN_PROGRESS: 'in_progress', DONE: 'done' }

  export default defineComponent({
    mixins: [
      DictionaryMixin,
      ConceptSearchMixin,
      FiltersMixin,
      IntroJsMixin,
    ],
    data: () => ({
      RELATION_STATUS,
      portfolioOptions: [],
      relationsInProgress: [],
      showMoreScopeDescription: null,
      startAtStep: 1,
      triggeringSearch: false, // keep track of when a user is refreshing the results, i.e. asking to redo the search
      displayScores: false,
      editing: false,
      deleting: false,
      editingScopeAndTitle: false,
      validationErrors: null,
      updatedTitle: '',
      updatedScopeDescription: '',
      topKeywords: [],
      topSources: [],
      results: EMPTY_RESULTS,
      SEARCH_RESULT_CATEGORY_IDS: SEARCH_RESULT_CATEGORIES,
      selectedLeftTab: LEFT_TABS.CONTENT,
      hasManuallySelectedTab: false,
      fetchingContent: false,
      errorWhileFetchingContent: false,
      searchQuery: '',
      searchQueryActors: '',
      lastSearchQuery: '',
      fetchingActors: false,
      displayedArticle: {
        showing: false,
        resource: null,
        details: null,
        data: null,
        loadingData: false,
        loadingAnnotations: false,
        relevant_in: [],
        is_liked_by_user: false,
      },
      dictionaryGroup: [],
      filterOnSpecificDictionary: null,
      timeSpanOptions: [],
      includedTagSuggestions: [],
      contentFilters: {
        conceptSearchId: null,
        date: null,
        geoTags: [],
        includedTags: [],
        excludedTags: [], // @deprecated
        sort: 'relevance',
        timespan: '',
        mediaTypes: [],
        newsGroups: [],
        isRelevant: false,
        isLikedByUser: false,
        lexicons: [],
        excludedLexicons: [],
        languages: [],
        portfolios: [],
      },
      actorFilters: {
        geoTags: [],
        category: [],
        industries: [],
        technology: [],
        activities: [],
        maturity: [],
        isRelevant: false,
      },
      actorSource: 'similarity', // Deprecated, this is no longer used as it seems that "similarity" will be sufficient to do everything we want
      // Actor filter options
      actorGeoTagOptions: [],
      // Content filter options
      geoTagOptions: [],
      includeFilterOptions: [],
      excludeFilterOptions: [],
      componentKey: 1,
      actorsParameters: {},
      newsGroupOptions: [],
      dictionaries: [],
      excludedDictionaries: [],
      updating: false,
      updated: false,
      modifiedConceptSearch: {
        search_topics: [],
        geo_tags: [],
        languages: [],
        lexicons: [],
        excludedLexicons: [],
        portfolios: [],
        exclude: [], // @deprecated
        news_groups: [],
      },
      updated_search_topics: [],
    }),
    computed: {
      isActorScouting() {
        return this.conceptSearch.type === 'actor_scouting'
      },
      errorMessage() {
        return 'Something went wrong while fetching content. Please contact support.'
      },
      noResultsMessage() {
        /* if (window.config.migrating_content_data) {
          return 'We are currently updating the DataScouts model. This will take a few minutes.'
        } */

        return 'No results found, try changing the filters or update your search configuration.'
      },
      disabledSaveButton() {
        return (this.filterDiff()) || ((!this.conceptSearch) && (this.updating && this.updated) && (this.conceptSearch.status !== 'done'))
      },
      disableViewInKnowledgeBaseButton() {
        // Disable the button when filter differences have been detected, as only the concept-search-id is passed when clicking the button
        return !this.filterDiff()
      },
      conceptSearch() {
        return this.$store.state.conceptSearches.detail.data
      },
      topicOptions() {
        if (!this.dictionaries) {
          return []
        }

        return this.dictionaries.map(item => ({
          value: item.id,
          label: item.value,
        }))
      },
      excludedTopicOptions() {
        if (!this.excludedDictionaries) {
          return []
        }

        return this.excludedDictionaries.map(item => ({
          value: item.id,
          label: item.value,
        }))
      },
      showLexiconFilter() {
        return true
      },
      showLikesFilter() {
        return this.displayFilters && !this.isExplorationOnly && this.$store.getters.isPublisherEnabled
      },
      actorFilterOptions() {
        return [
          {
            type: 'category',
            label: 'Categories:',
            placeholder: 'Show actors with a specific category',
            options: this.legalEntityAndCommunityCategoryOptions,
            changeFunction: () => {
            },
            rawChangeFunction: this.setActorTaxonomyFilter,
          },
          {
            type: 'geoTags',
            label: 'Geographical scope:',
            placeholder: 'Show actors in a specific area',
            options: this.actorGeoTagOptions,
            changeFunction: this.updateActorGeoTagOptions,
            rawChangeFunction: this.setActorGeoFilter,
          },
          {
            type: 'industries',
            label: 'Industries:',
            placeholder: 'Show actors within a specific industry',
            options: this.industriesOptions.map(industry => {
              return { label: industry.text, value: industry.id }
            }),
            changeFunction: () => {
            },
            rawChangeFunction: this.setActorTaxonomyFilter,
          },
          {
            type: 'technology',
            label: 'Technology:',
            placeholder: 'Show actors containing a specific technology',
            options: this.technologyOptions.map(technology => {
              return { label: technology.text, value: technology.id }
            }),
            changeFunction: () => {
            },
            rawChangeFunction: this.setActorTaxonomyFilter,
          },
          {
            type: 'activities',
            label: 'Activities:',
            placeholder: 'Show actors with a specific activity',
            options: this.activityOptions,
            changeFunction: () => {
            },
            rawChangeFunction: this.setActorTaxonomyFilter,
          },
          {
            type: 'maturity',
            label: 'Maturity:',
            placeholder: 'Show actors with a specific maturity',
            options: this.maturityOptions,
            changeFunction: () => {
            },
            rawChangeFunction: this.setActorTaxonomyFilter,
          },
        ]
      },
      exportContentDataLink() {
        return buildExportContentLink({
          'export': true,
          relevantOnly: true,
          'concept-search-id': this.conceptSearch.id,
        })
      },
      exportActorsLink() {
        return buildExportActorsLink(this.conceptSearch.id, {
          'export': true,
          linked_concept_search_id: this.conceptSearch.id,
          'ml-supported': true,
        })
      },
      containsRelevantContent() {
        return this.results.content && this.results.content.filter(c => c.relevant).length > 0
      },
      containsRelevantActors() {
        return this.results.actors && this.results.actors.filter(a => a.relevant).length > 0
      },
      isLoggedIn() {
        return this.$store.getters.isLoggedIn
      },
      conceptSearchAutoReloadEnabled: {
        get() {
          return this.$store.getters.conceptSearchAutoReloadEnabled
        },
        set() {
          this.$store.commit(CONCEPT_SEARCHES_MUTATION_TYPES.TOGGLE_AUTO_RELOAD)
        },
      },
      isExplorationOnly() {
        return this.$store.getters.isExplorationOnly
      },
      displayedActors() {
        let displayedActors = []

        displayedActors = this.results.actors

        if (this.searchQueryActors) {
          const regex = new RegExp(this.searchQueryActors, 'i')
          displayedActors = displayedActors.filter(actor => regex.test(actor.name) || regex.test(actor.description))
        }

        return displayedActors
      },
      displayedActorsCount() {
        /* if (this.actorSource === 'content' && this.results && this.results.actorsFromContent) {
          return this.results.actorsFromContent.length
        } */ // No longer used

        if (this.results && this.results.actors && this.actorSource === 'similarity') {
          return this.results.actors.length
        }

        return 0
      },
      hasDifferentContentTypes() {
        var typesWithResults = 0

        for (const [type, count] of Object.entries(this.results.contentTypeCounts)) {
          if (count > 0) {
            typesWithResults++
          }
        }

        return typesWithResults > 1
      },
      mediaTypeOptions() {
        var options = [
          {
            value: null,
            label: 'all',
          },
          {
            value: 'rss',
            label: 'articles',
          },
          {
            value: 'pr',
            label: 'press releases',
          },
          {
            value: 'blog',
            label: 'blogs',
          },
        ]

        if (this.conceptSearch.report_type !== 'startup_radar') {
          options.push(
            {
              value: 'patent',
              label: 'patents',
            })
        }

        return options
      },
      canDisplayActors() {
        return this.$store.getters.hasAccessToExploration || this.$store.getters.hasAccessToMonitoring
      },
      recognisedActorsInArticle() {
        if (!this.displayedArticle || !this.displayedArticle.data) {
          return []
        }

        return this.displayedArticle.data.actors
      },
      displayFilters() {
        return !this.displayedArticle.showing
      },
      displaySorting() {
        return !this.displayedArticle.showing
      },
      canDisplayFilters() {
        return this.$store.getters.isMember || this.$store.getters.isActor
      },
      tabHasFilters() {
        return this.selectedLeftTab && [LEFT_TABS.ACTORS, LEFT_TABS.CONTENT].includes(this.selectedLeftTab)
      },
      canFetchActors() {
        return this.$store.getters.isMember
      },
      filterableDictionaryGroups() {
        const labelMapping = {
          pestel: 'PESTEL Trends',
          sustainability: 'Sustainability',
          'emerging technologies': 'Emerging Technologies',
          'global mega trends': 'Global Mega Trends',
        }

        var options = []

        this.mixinDefaultDictionaries
          .reduce((groups, dictionary) => {
              if (dictionary.dictionary_group && !groups.includes(dictionary.dictionary_group)) {
                groups.push(dictionary.dictionary_group)
              }

              return groups
            },
            [],
          )
          .forEach(group => {
            options.push({
              label: labelMapping[group] || group,
              value: group,
            })
          })

        return options
      },
      hasAccessToMonitoring() {
        return this.$store.getters.hasAccessToMonitoring
      },
      followed: {
        get() {
          return !!this.conceptSearchIsFollowed[this.conceptSearch.id]
        },
        set(followed) {
          if (followed) {
            this.followConceptSearch(this.conceptSearch.id)
          } else {
            this.unfollowConceptSearch(this.conceptSearch.id)
          }
        },
      },
      contentIncludeTags() {
        // Combination of geo tags + include tags
        var tagIds = []

        if (this.contentFilters.includedTags.length > 0) {
          this.contentFilters.includedTags.forEach(tag => {
            if (tag.optionValue && tag.optionValue.value) {
              tagIds.push(tag.optionValue.value)
            }
          })
        }

        if (this.contentFilters.geoTags.length > 0) {
          this.contentFilters.geoTags.forEach(tag => {
            if (tag.optionValue && tag.optionValue.value) {
              tagIds.push(tag.optionValue.value)
            }
          })
        }

        return tagIds
      },
      contentExcludeTags() {
        // @deprecated
        var tagIds = []

        return tagIds

        if (this.contentFilters.excludedTags.length > 0) {
          this.contentFilters.excludedTags.forEach(tag => {
            if (tag.optionValue && tag.optionValue.value) {
              tagIds.push(tag.optionValue.value)
            }
          })
        }

        return tagIds
      },
      contentExcludedLexicons() {
        if (!this.contentFilters.excludedLexicons || this.contentFilters.excludedLexicons.length === 0) {
          return []
        }

        var lexiconIds = []

        this.contentFilters.excludedLexicons.forEach(lexicon => {
          if (lexicon.value) {
            lexiconIds.push(lexicon.value)
          } else {
            lexiconIds.push(lexicon)
          }
        })

        return lexiconIds
      },
      contentLexicons() {
        if (this.contentFilters.lexicons.length === 0) {
          return []
        }

        var lexiconIds = []

        this.contentFilters.lexicons.forEach(lexicon => {
          if (lexicon.value) {
            lexiconIds.push(lexicon.value)
          } else {
            lexiconIds.push(lexicon)
          }
        })

        return lexiconIds
      },
      showingPublishButton() {
        return this.$store.getters.isAdmin || this.$store.getters.isOwner
      },
      computedUserCanEdit() {
        return this.$store.getters.isAdmin || this.$store.getters.isOwner
      },
      userCanFilterContent() {
        return this.$store.getters.isOwner || this.$store.getters.isMember
      },
      userCanUpdateRelevancy() {
        return this.$store.getters.isAdmin || this.$store.getters.isOwner || this.$store.getters.isMember
      },
      userCanExport() {
        return this.$store.getters.isOwner && this.$store.getters.hasAccessToExplorationOrMonitoring
      },
      tabs() {
        if (this.displayedArticle.loadingData || this.displayedArticle.loadingAnnotations) {
          return []
        }

        if (!this.displayedArticle.data ||
          !this.displayedArticle.data.actors ||
          (this.displayedArticle.data.actors.organisations.length === 0 && this.displayedArticle.data.actors.persons.length === 0)
        ) {
          return ['topics']
        }

        if (!this.annotatedRelations || this.annotatedRelations.length === 0) {
          return ['actors', 'topics']
        }

        return ['actors', 'relationships', 'topics']
      },
      tabTooltips() {
        return {
          actors: 'Recognized Actors',
          relationships: 'Recognized Relationships',
          topics: 'Recognized Topics',
        }
      },
      isMember() {
        return this.$store.getters.isMember
      },
      simplifiedUi() {
        return !this.$store.getters.isAdmin && !this.$store.getters.isOwner && !this.$store.getters.isTeamMember
      },
      LEFT_TABS() {
        return LEFT_TABS
      },
      availableLeftTabs() {
        return [
          LEFT_TABS.CONTENT,
          LEFT_TABS.TOP_KEYWORDS,
          LEFT_TABS.ACTORS,
          LEFT_TABS.TOP_SOURCES,
        ]
      },
      reportTypeLabel() {
        const reportType = REPORT_TYPES.find(t => t.id === this.conceptSearch.report_type)

        if (reportType) {
          return reportType.name
        } else {
          return 'Custom report'
        }
      },
      lastRun() {
        return moment(this.conceptSearch.explored_at).format('DD\u2011MM\u2011YYYY\xa0/\xa0HH:mm')
      },
      visibleResultCategories() {
        return { ...SEARCH_RESULT_CATEGORIES }
      },
      analysisDictionaries() {
        if (!this.dictionaryGroup) {
          return []
        }

        return this.mixinDefaultDictionaries.filter(d => d.dictionary_group === this.dictionaryGroup)
      },
      config() {
        return this.$store.state.config
      },
      availableRelationships() {
        return this.$store.state.actorRelationships.relationships
      },
      annotatedRelations() {
        if (!this.displayedArticle || !this.displayedArticle.data || !this.displayedArticle.data.relations) {
          return []
        }

        return this.displayedArticle.data.relations
          .filter(relation => ['invested_by', 'is_partner', 'invested', 'collaborates_with'].includes(relation.relation_label))
          .map(relation => {
            switch (relation.relation_label) {
              case 'invested':
                return {
                  ...relation,
                  mappedRelation: this.availableRelationships.find(rel => rel.name === 'invested'),
                }
              case 'is_partner':
                return {
                  ...relation,
                  mappedRelation: this.availableRelationships.find(rel => rel.name === 'is_partner'),
                }
              case 'collaborates_with':
                return {
                  ...relation,
                  mappedRelation: this.availableRelationships.find(rel => rel.name === 'collaborates_with'),
                }
              case 'invested_by':
                return {
                  entity_A_alias: relation.entity_B_alias,
                  entity_A_type: relation.entity_B_type,
                  entity_B_alias: relation.entity_A_alias,
                  entity_B_type: relation.entity_A_type,
                  relation_label: 'invested',
                  mappedRelation: this.availableRelationships.find(rel => rel.name === 'invested'),
                }
              default:
                break
            }
            return relation
          })
      },
    },
    methods: {
      filterDiff() {
        if (this.conceptSearch && this.conceptSearch.search_topics) {
          const conceptSearchTags = this.conceptSearch.search_topics.map(tag => {
            return tag.id
          })

          const contentFiltersTags = this.contentFilters.includedTags.map(tag => {
            return tag.optionValue.value
          })

          // @deprecated
          let conceptSearchExcludeTags = []
          if (this.conceptSearch.excludedTags) {
            conceptSearchExcludeTags = this.conceptSearch.excludedTags.map(tag => {
              return tag.id
            })
          }

          // @deprecated
          let contentExcludeTags = []
          if (this.contentFilters.excludedTags) {
            contentExcludeTags = this.contentFilters.excludedTags.map(tag => {
              return tag.optionValue.value
            })
          }

          let contentExcludedLexicons = []
          if (this.contentFilters.excludedLexicons) {
            contentExcludedLexicons = this.contentFilters.excludedLexicons.map(lexicon => {
              return {
                id: lexicon.value,
                value: lexicon.label,
              }
            })
          }

          const conceptSearchGeo = this.conceptSearch.geographyTags.map(tag => {
            return {
              label: tag.label,
            }
          })

          const contentGeo = this.contentFilters.geoTags.map(tag => {
            return {
              label: tag.text,
            }
          })

          const contentFiltersLexicon = this.contentFilters.lexicons.map(tag => {
            return {
              id: tag.value,
              value: tag.label,
            }
          })

          let contentFilterNewsGroups = []
          if (this.contentFilters.newsGroups) {
            contentFilterNewsGroups = this.contentFilters.newsGroups.map(newsGroup => {
              return {
                id: newsGroup.value,
                name: newsGroup.label,
              }
            })
          }

          const contentFiltersPortfolios = this.contentFilters.portfolios.map(portfolio => {
            return {
              id: portfolio.optionValue.value,
              name: portfolio.optionValue.text,
            }
          })

          return (JSON.stringify(conceptSearchTags) === JSON.stringify(contentFiltersTags)) &&
            // (JSON.stringify(conceptSearchExcludeTags) === JSON.stringify(contentExcludeTags)) && // @deprecated
            (JSON.stringify(conceptSearchGeo) === JSON.stringify(contentGeo)) &&
            (JSON.stringify(this.conceptSearch.contextValues) === JSON.stringify(contentFiltersLexicon)) &&
            (JSON.stringify(this.conceptSearch.excludedLexiconsValues) === JSON.stringify(contentExcludedLexicons)) &&
            (JSON.stringify(this.conceptSearch.newsGroups) === JSON.stringify(contentFilterNewsGroups)) &&
            (JSON.stringify(this.conceptSearch.search_portfolios) === JSON.stringify(contentFiltersPortfolios)) &&
            (JSON.stringify(this.conceptSearch.languages) === JSON.stringify(this.contentFilters.languages)) &&
            (JSON.stringify(this.conceptSearch.timespan) === JSON.stringify(this.contentFilters.timespan))
        }
      },
      handleManageLexicons() {
        window.open('/settings/exploration', '_blank')
      },
      handleManageNewsGroups() {
        window.open('/settings/exploration#newsGroups', '_blank')
      },
      isPublished() {
        return this.conceptSearch.published || false
      },
      setPublished(published) {
        if (published) {
          this.$store.commit(CONCEPT_SEARCHES_MUTATION_TYPES.PUBLISH_CONCEPT_SEARCH, this.conceptSearch.id)
          publishConceptSearch(this.conceptSearch.id)
        } else {
          this.$store.commit(CONCEPT_SEARCHES_MUTATION_TYPES.UNPUBLISH_CONCEPT_SEARCH, this.conceptSearch.id)
          unpublishConceptSearch(this.conceptSearch.id)
        }
      },
      updateSelectedPortfolios(selectedPortfolios) {
        this.contentFilters.portfolios = selectedPortfolios
      },
      updatePortfolioOptions: debounce(async function (query) {
        if (!query) {
          return
        }

        // This will search for a portfolio which matches the input value in the search field
        var filters = Object.assign({}, { query: query })

        fetchPortfolio(filters)
          .then(portfolios => {
            this.portfolioOptions = []

            this.parsePortfolioOptions(portfolios)
          })
          .catch(errors => {
            console.log(errors)
          })
      }, 150),
      parsePortfolioOptions(portfolios) {
        if (!Array.isArray(portfolios)) {
          return
        }

        var options = []

        // Don't include virtual portfolios in the portfolios a user can choose from
        portfolios.forEach(portfolio => {
          if (portfolio.virtual) {
            return
          }

          options.push({
            text: portfolio.name,
            value: portfolio.id,
          })
        })

        this.portfolioOptions = options
      },
      actorExistsOnEcosystem(actorName, actorType) {
        const relatedActor = this.getRelatedActor(actorName, actorType)
        return relatedActor && relatedActor.state === 'added'
      },
      getConnectionsUrlForActor(actorName, actorType) {
        const relatedActor = this.getRelatedActor(actorName, actorType)
        if (relatedActor && relatedActor.state === 'added') {
          return `/actors/${relatedActor.id}/#connections`
        }
      },
      getRelatedActor(actorName, actorType) {
        if (actorType === 'ORG') {
          return this.displayedArticle.data.actors.organisations.find(organisation => organisation.name.toLowerCase() === actorName.toLowerCase())
        }

        return this.displayedArticle.data.actors.persons.find(person => person.name.toLowerCase() === person.name.toLowerCase())
      },
      getRelationStatus(relation) {
        if (this.relationsInProgress.includes(relation)) {
          return RELATION_STATUS.IN_PROGRESS
        }

        const relatedFirstActor = this.getRelatedActor(relation.entity_A_alias, relation.entity_A_type)
        const relatedSecondActor = this.getRelatedActor(relation.entity_B_alias, relation.entity_B_type)
        if (!relatedFirstActor || !relatedSecondActor) {
          return RELATION_STATUS.OPEN
        }

        const allMatchingRelations = relatedFirstActor[relation.mappedRelation.name] || []
        const allMatchingReverseRelations = relatedFirstActor[relation.mappedRelation.inverse_name] || []
        const relationExists = !![...allMatchingRelations, ...allMatchingReverseRelations].find(relation => {
          return relation.to === relatedSecondActor.id
        })
        return relationExists ? RELATION_STATUS.DONE : RELATION_STATUS.OPEN
      },
      async addRelation(relation) {
        if (this.relationsInProgress.length) {
          return
        }

        this.relationsInProgress.push(relation)
        const firstActor = await this.getOrCreateActor(relation.entity_A_alias, relation.entity_A_type)
        const secondActor = await this.getOrCreateActor(relation.entity_B_alias, relation.entity_B_type)

        if (!firstActor || !secondActor) {
          this.relationsInProgress = []
          relation.error = true
          return
        }

        await this.createRelationBetween(firstActor, secondActor, relation.mappedRelation.name)
        this.refreshAnnotationData(this.displayedArticle.resource, true).finally(() => {
          this.relationsInProgress = []
        })
      },
      async createRelationBetween(firstActor, secondActor, relationType) {
        let existingRelation = []

        if (firstActor[relationType]) {
          existingRelation = [...firstActor[relationType]]
        }

        existingRelation.push({
          to: secondActor.id,
        })

        await updateActor({
          id: firstActor.id,
          data: {
            [relationType]: existingRelation,
          },
        })
      },
      async getOrCreateActor(actorName, actorType) {
        let result = this.getRelatedActor(actorName, actorType)
        if (!result || result.state !== 'added') {
          result = await this.addActor(actorName, actorType)
        }
        return result
      },
      async addActor(actorName, actorType) {
        let relatedActor = {
          name: actorName,
          actor_type: actorType === 'ORG' ? 'LegalEntity' : 'Person',
        }

        try {
          relatedActor = await createActor(relatedActor)
        } catch (existingActor) {
          if (existingActor && existingActor.id) {
            relatedActor = await fetchActor(existingActor.id)
          }
        }

        return relatedActor
      },
      buildNewActor(actor) {
        return {
          name: actor.name,
          actor_type: actor.actor_type,
          url: actor.url,
          wikipedia: actor.wikipedia,
          crunchbase: actor.crunchbase,
          description: actor.description,
        }
      },
      getBackgroundColorForRelation(relation) {
        const index = relation.id || 0
        return Object.values(this.$store.state.config.hexColours)[index % 30]
      },
      getTextColorForRelation(relation) {
        return '#000'
      },
      toggleLikesFilter() {
        this.contentFilters = {
          ...this.contentFilters,
          isLikedByUser: !this.contentFilters.isLikedByUser,
        }
        // This seems to be necessary to force the change in the nested object
        this.componentKey++
      },
      addSuggestionAsIncludedTag(acceptedTag) {
        var topic = (this.conceptSearch.search_topics || []).find(topic => topic.label === acceptedTag)

        if (!topic) {
          return // This shouldn't be possible, but just in case
        }

        var filters = this.contentFilters.includedTags

        filters.push(this.transformTagToFilterValue(topic))
        filters = _unique(filters)

        this.includedTagSuggestions = this.includedTagSuggestions.filter(suggestion => suggestion !== acceptedTag)
        this.contentFilters.includedTags = filters
      },
      removeTagFromIncludedTagSuggestions(deniedTag) {
        this.includedTagSuggestions = this.includedTagSuggestions.filter(suggestion => suggestion !== deniedTag)
      },
      updateContentFiltersSort(value) {
        this.contentFilters.sort = value
      },
      setActorTaxonomyFilter(selectedFilters, type) {
        this.actorFilters[type] = selectedFilters
      },
      update() {
        this.validationErrors = null
        this.updating = true

        // Make sure that we pass the correct format of tags, because the Tags components needs a certain data format in order to search

        // NOTE: pass the updated content filters when updating the concept search
        updateConceptSearchAndExplore(this.conceptSearch.id, {
          title: this.conceptSearch.title || [],
          // description: this.conceptSearch.description || '',
          // scope_description: this.conceptSearch.scope_description,
          geography_context: (this.contentFilters.geoTags || []).map(tag => tag.geographyObject),
          search_topics: (this.contentFilters.includedTags || []).map(tag => this.transformFilterValueToTagId(tag)),
          languages: this.contentFilters.languages || [],
          context: (this.contentFilters.lexicons || []).map(tag => this.transformFilterValueToTagId(tag)),
          search_portfolios: (this.contentFilters.portfolios || []).map(tag => this.transformFilterValueToTagId(tag)),
          news_groups: (this.contentFilters.newsGroups || []).map(tag => this.transformFilterValueToTagId(tag)),
          exclude: (this.contentFilters.excludedLexicons || []).map(tag => this.transformFilterValueToTagId(tag)), // @deprecated
          excluded_lexicons: (this.contentFilters.excludedLexicons || []).map(tag => this.transformFilterValueToTagId(tag)),
          timespan: this.contentFilters.timespan || '',
          ignore_build: true,
        })
          .then(async (result) => {
            this.updating = false
            this.updated = true
            this.modifiedConceptSearch = result

            this.$store.commit(CONCEPT_SEARCHES_MUTATION_TYPES.CLEAR_CONCEPT_SEARCH_FILTER, this.conceptSearch.id)

            await this.$store.dispatch(CONCEPT_SEARCHES_ACTION_TYPES.FETCH_CONCEPT_SEARCH_DETAIL, this.$route.params.id)
            this.setDefaultFilters()

            setTimeout(() => {
              this.updated = false
            }, 600)
          })
          .catch(error => {
            this.updating = false
            this.validationErrors = error
          })
      },
      confirmUpdateTitleAndDescription() {
        this.validationErrors = null

        // NOTE: we only pass the updated search_topics filters, the rest of the fields are required in order to proceed the update
        updateConceptSearchAndExplore(this.conceptSearch.id, {
          id: this.conceptSearch.id,
          title: this.updatedTitle || '',
          description: this.conceptSearch.description || '',
          scope_description: this.updatedScopeDescription,
          search_topics: (this.contentFilters.includedTags || []).map(tag => this.transformFilterValueToTagId(tag)),
          context: (this.contentFilters.lexicons || []).map(tag => this.transformFilterValueToTagId(tag)),
          news_groups: (this.conceptSearch.newsGroups || []).map(tag => this.transformFilterValueToTagId(tag)),
        })
          .then((result) => {
            this.$store.dispatch(CONCEPT_SEARCHES_ACTION_TYPES.FETCH_CONCEPT_SEARCH_DETAIL, this.$route.params.id)
            this.editingScopeAndTitle = false

            this.disabledSaveButton = true
          })
          .catch(error => {
            this.validationErrors = error
          })
      },
      showEditScopeDescription() {
        if (!this.conceptSearch) {
          return
        } else {
          this.updatedScopeDescription = this.conceptSearch.scope_description || ''
          this.updatedTitle = this.conceptSearch.title || ''
        }
        this.editingScopeAndTitle = true
      },
      removeArticle({ resource }) {
        this.confirmRemoveArticle(resource.sql_media_id)
      },
      confirmRemoveArticle(resourceId) {
        const articleIndexToRemove = this.results.content.findIndex((article) => article.sql_media_id === resourceId)
        this.results.content.splice(articleIndexToRemove, 1)

        removeArticle(resourceId, this.conceptSearch.id).then(() => {
          if (this.conceptSearchAutoReloadEnabled) {
            this.fetchContent()
          }
        }).catch(() => {
          console.log('error while removing article')
        })
      },
      likeArticle({ resourceId, status }) {
        likeArticle(resourceId, status)
      },
      goToNextArticle() {
        if (!this.results.content.length) {
          return
        }

        let nextArticleIndex = 1 + this.results.content.findIndex((article) => article.sql_media_id === this.displayedArticle.details.id)
        if (nextArticleIndex >= this.results.content.length) {
          nextArticleIndex = 0
        }

        const sqlId = this.results.content[nextArticleIndex].sql_media_id
        return this.$router.push(`/dashboards/concept-map/${this.$route.params.id}/content/${sqlId}`)
      },
      startIntroJs: function () {
        if (this.seenIntros.includes('conceptMap')) {
          return
        }

        const config = this.buildIntroJsConfig(this.config, this.$store.getters.userRole)
        if (!config || !config.conceptMap || !config.conceptMap.steps) {
          return
        }

        config.conceptMap.steps = this.getIntroJsStepsConfig(config.conceptMap.steps)
        const intro = this.introJs().setOptions(config.conceptMap)
        this.currentIntro = intro

        const componentScope = this
        intro.onbeforechange(this.introJsBeforeStepCallback.bind(this))
        intro.onafterchange(this.introJsAfterStepCallback.bind(this))
        intro.oncomplete(function () {
          componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
        })
        intro.onexit(function () {
          componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
        })

        intro.start()
      },
      startSearch() {
        this.triggeringSearch = true

        explore(this.conceptSearch.id)
          .then(response => {
            this.conceptSearch.status = 'in_progress'
            this.triggeringSearch = false
          })
          .catch(error => {
            console.log(error)
            this.triggeringSearch = false
          })
      },
      setDefaultFilters() {
        if (this.$store.state.conceptSearches.filters.find(search => search.conceptSearchId === this.conceptSearch.id) !== undefined && this.conceptSearch) {
          this.contentFilters = inert(this.$store.state.conceptSearches.filters.find(search => search.conceptSearchId === this.conceptSearch.id))
        } else {
          // Reset the filters to be the filters of the concept search: geo, exclude
          const tmpContentFilters = { ...this.contentFilters }

          tmpContentFilters.excludedTags = this.conceptSearch.excludeTags ? this.conceptSearch.excludeTags.map(tag => this.transformTagToFilterValue(tag)) : []  // @deprecated
          tmpContentFilters.includedTags = this.conceptSearch.search_topics ? this.conceptSearch.search_topics.map(tag => this.transformTagToFilterValue(tag)) : []
          tmpContentFilters.languages = this.conceptSearch.languages ? this.conceptSearch.languages : []
          tmpContentFilters.portfolios = this.conceptSearch.search_portfolios ? this.conceptSearch.search_portfolios.map(portfolio => this.transformTagToFilterValue(portfolio, 'id', 'name')) : []
          tmpContentFilters.newsGroups = this.conceptSearch.newsGroups ? this.conceptSearch.newsGroups.map(newsGroup => {
            return { value: newsGroup.id, label: newsGroup.name }
          }) : []
          tmpContentFilters.timespan = this.conceptSearch.timespan ? this.conceptSearch.timespan : ''

          tmpContentFilters.geoTags = this.conceptSearch.geography_context
            ? this.conceptSearch.geography_context.map(item => ({
              text: getGeographyItemLabel(item),
              geographyObject: item,
              optionValue: { text: item.label.eng },
            }))
            : []

          if (this.showLexiconFilter) {
            tmpContentFilters.lexicons = this.conceptSearch.contextValues.map(context => {
              return {
                value: context.id,
                label: context.value,
              }
            })
          }

          tmpContentFilters.excludedLexicons = []
          if (this.conceptSearch.excludedLexiconsValues) {
            tmpContentFilters.excludedLexicons = this.conceptSearch.excludedLexiconsValues.map(lexicon => {
              return {
                value: lexicon.id,
                label: lexicon.value,
              }
            })
          }

          this.contentFilters = tmpContentFilters
        }
      },
      transformTagToFilterValue(tag, valueKey = 'id', labelKey = 'label') {
        // the format that the frontend components expect
        return {
          text: tag[labelKey],
          // label: tag[labelKey],
          // value: tag['value'],
          optionValue: {
            value: tag[valueKey],
            text: tag[labelKey],
          },
        }
      },
      transformFilterValueToTagId(tag) {
        if (tag['optionValue'] && tag['optionValue']['value']) {
          return tag['optionValue']['value']
        }

        if (tag['id']) {
          return tag['value']
        }

        if (tag['value']) {
          return tag['value']
        }

        return tag
      },
      setTimeSpanFilters() {
        const today = new Date()
        today.setHours(0, 0, 0, 0)

        const oneWeek = new Date()
        oneWeek.setDate(today.getDate() - 7)

        const oneMonth = new Date()
        oneMonth.setMonth(today.getMonth() - 1)

        const oneYear = new Date()
        oneYear.setFullYear(today.getFullYear() - 1)

        this.timeSpanOptions = [
          { value: null, textValue: 'year', label: this.$t('timespan_all_time'), isEnabled: this.contentFilters.timespan === '' || this.contentFilters.timespan === 'year' },
          { value: Math.round(today.getTime() / 1000), textValue: 'today', label: this.$t('timespan_today'), isEnabled: this.contentFilters.timespan === 'today' },
          {
            value: Math.round(oneWeek.getTime() / 1000),
            textValue: 'week',
            label: this.$t('timespan_this_week'),
            isEnabled: this.contentFilters.timespan === 'week',
          },
          {
            value: Math.round(oneMonth.getTime() / 1000),
            textValue: 'month',
            label: this.$t('timespan_this_month'),
            isEnabled: this.contentFilters.timespan === 'month',
          },
          {
            value: Math.round(oneYear.getTime() / 1000),
            textValue: 'year',
            label: this.$t('timespan_this_year'),
            isEnabled: this.contentFilters.timespan === 'year',
          },
        ]

        // Set the date filter value to the enabled option
        this.contentFilters.date = this.timeSpanOptions.filter(option => option.isEnabled)[0].value
      },
      async updateActorGeoTagOptions(tag) {
        // Assign a value to the tagOptions that is a merge of
        // 1/ filtered tag suggestions
        // 2/ filtered taxonomy suggestions
        // taking into account the given tag
        // Return an empty array if the tag is empty
        if (!tag || tag.length == 0) {
          this.actorGeoTagOptions = []
        }

        // Fetch a list of tag suggestions and update the tagOptions
        try {
          var response = await getKeywordsForValue(tag, 'geo')

          if (!response || response.length === 0) {
            this.actorGeoTagOptions = []
          }

          var options = []

          for (var index = 0; index < response.length; index++) {
            var displayName = (response[index].display || '').split(',').slice(-1)[0]

            options.push({
              value: response[index].value,
              label: displayName,
              facet: response[index].facet,
            })
          }

          options = _unique(options)

          this.actorGeoTagOptions = options
        } catch (e) {
          this.actorGeoTagOptions = []
        }
      },
      updateArticleGeoTagOptions(tag) {
        fetchGeographySuggestions({ query: tag })
          .then(response => {
            this.geoTagOptions = []

            if (!Array.isArray(response)) {
              return
            }

            response.forEach(item => {
              if (item.label.eng) {
                this.geoTagOptions.push({
                  text: getGeographyItemLabel(item),
                  geographyObject: item,
                  optionValue: { text: item.label.eng },
                })
              }
            })
          })
          .catch(() => {
            this.geoTagOptions = []
          })
        // this.updateTagOptions(tag, 'geoTagOptions', 'geo')
      },
      updateIncludeTagOptions(tag) {
        this.updateTagOptions(tag, 'includeFilterOptions')
      },
      updateExcludeTagOptions(tag) {
        this.updateTagOptions(tag, 'excludeFilterOptions')
      },
      removeDuplicates(suggestions) {
        return _uniqBy(suggestions, 'label')
      },
      async updateTagOptions(tag, optionProperty, type) {
        try {
          // For include filter options we allow user generated keywords
          if (optionProperty === 'includeFilterOptions') {
            type = 'any'
          }

          var options = await this.fetchTagOptions(tag, type)

          if (!options || options.length == 0) {
            this[optionProperty] = []

            return
          }

          var geoOptions = []

          options = this.removeDuplicates(options)
          for (var index = 0; index < options.length; index++) {
            geoOptions.push({
              value: options[index].value,
              text: options[index].label,
            })
          }

          this[optionProperty] = geoOptions
        } catch (e) {
          this[optionProperty] = []
        }
      },
      addTagToIncludedKeywords(tag) {
        if (!tag.value) {
          return
        }

        // Skip if tag is already included
        const includedTags = this.contentFilters.includedTags.map(tag => tag.value)
        if (includedTags.includes(tag.value)) {
          return
        }

        tag = {
          text: tag.label ? tag.label : tag.value.label,
          value: tag.value ? tag.value : tag.value.value,
          optionValue: {
            text: tag.label ? tag.label : tag.value.label,
            value: tag.value ? tag.value : tag.value.value,
            hoverText: tag.label ? tag.label : tag.value.text,
          },
        }

        this.contentFilters.includedTags.push(tag)

        // It could be that the event comes from a click tag on the displayed article
        if (this.displayedArticle.showing) {
          this.displayedArticle.showing = false
          this.$router.push(`/dashboards/concept-map/${this.$route.params.id}/content`)
        }
      },
      setIncludeFilter(tags) {
        // Filter out suggestions
        tags = tags.filter(tag => !tag.isSuggestion)

        this.contentFilters.includedTags = tags
      },
      setExcludeFilter(tags) {
        // @deprecated
        this.contentFilters.excludedTags = tags
      },
      setContentGeoFilter(tags) {
        this.contentFilters.geoTags = tags
      },
      setDateFilter(value, index) {
        this.timeSpanOptions = this.timeSpanOptions.map((option, i) => ({
          ...option,
          isEnabled: (i === index) ? !option.isEnabled : false,
        }))

        if (this.contentFilters.date === value) {
          this.contentFilters.date = null

          return
        }

        this.contentFilters.date = value
        this.contentFilters.timespan = this.timeSpanOptions[index].textValue
      },
      setMediaTypeFilter(value) {
        if (this.contentFilters.mediaTypes.length > 0 && this.contentFilters.mediaTypes[0] == value) {
          this.contentFilters.mediaTypes = []

          return
        }

        this.contentFilters.mediaTypes = [value]
      },
      setActorGeoFilter(tags) {
        this.actorFilters.geoTags = tags
      },
      searchAll: debounce(function () {
        this.fetchContent()
        this.fetchActors()
      }, 1000),
      fetchTagOptions(tag, type) {
        return fetchRichTagSuggestion(tag, type)
      },
      updateAllSearchQuery(searchQuery) {
        this.searchQuery = searchQuery
        this.searchAll(!!this.searchQuery)
      },
      onToggleActorRelevance() {
        if (this.conceptSearchAutoReloadEnabled) {
          this.fetchActors()
        }
      },
      onToggleArticleRelevance() {
        if (this.conceptSearchAutoReloadEnabled) {
          this.fetchContent()
        }
      },
      markActorRelevant({ actor, isRelevant }) {
        updateActorRelevancy(actor.id, isRelevant, this.conceptSearch.id)
          .then(() => {
            this.onToggleActorRelevance()
          })
          .catch(err => {
            actor.relevant = !isRelevant
            console.log(err)
          })
      },
      markArticleRelevant({ article, isRelevant, conceptSearchId }) {
        const originalRelevant = article.relevant_in ? article.relevant_in.includes(conceptSearchId) : false

        updateFileRelevancy(article.sql_media_id, isRelevant, conceptSearchId)
          .then(() => {
            article.relevant = isRelevant // Trigger computed properties

            this.onToggleArticleRelevance()
          })
          .catch(err => {
            article.relevant = originalRelevant
            console.log(err)
          })
      },
      fetchContent: debounce(function () {
        try {
          /* if (this.fetchingContent) {
            return
          } */

          let currentScrollTop

          if (this.$refs.scrollable) {
            currentScrollTop = this.$refs.scrollable.scrollTop
          }

          const expectedQuery = this.searchQuery

          // Prep content parameters
          let similar_dictionaries = ''

          if (this.filterOnSpecificDictionary) {
            similar_dictionaries = String(this.filterOnSpecificDictionary)
          } else {
            similar_dictionaries = this.analysisDictionaries.map(d => d.id)
          }

          var tags = this.contentIncludeTags
          var exclude_tags = this.contentExcludeTags  // @deprecated, as this is now "excludedLexicons"
          var date = this.contentFilters.date
          var sortBy = (this.contentFilters.sort === 'recency' ? 'date' : null)
          var mediaTypes = this.contentFilters.mediaTypes ? this.contentFilters.mediaTypes : null
          var news_groups = this.contentFilters.newsGroups ? this.contentFilters.newsGroups.map(ng => ng.value ? ng.value : ng) : null
          var lexicons = this.contentLexicons
          var excluded_lexicons = this.contentExcludedLexicons
          var lang = this.contentFilters.languages
          var portfolio_ids = this.contentFilters.portfolios ? this.contentFilters.portfolios.map(portfolio => portfolio.optionValue ? portfolio.optionValue.value : portfolio) : null
          var geoTags = this.contentFilters.geoTags.map(geoTag => geoTag.optionValue.text)

          const contentParameters = {
            media_types: mediaTypes,
            limit: 100,
            offset: 0,
            'concept-search-id': this.conceptSearch.id,
            'relevantOnly': this.contentFilters.isRelevant,
            'likedByUserOnly': this.contentFilters.isLikedByUser,
            matchExactQuery: true,
            query: expectedQuery,
            similar_dictionaries,
            lexicons,
            news_groups,
            portfolio_ids,
            lang,
            tags,
            // exclude_tags, // @deprecated, as this is now "excludedLexicons"
            excluded_lexicons,
            date,
            geoTags,
            sortBy,
            'ml-supported': true,
            'remove-duplicate-content': true,
            'analyse': 'significant_tag_count,sql_handle_id_count,tag_count', // Get the facet counts for tags and the content sources
          }

          this.fetchingContent = true

          fetchKnowledgeBaseData(contentParameters)
            .then(result => {
              // Only include non-organisation keywords in the topKeywords
              let topKeywords = []

              if (result.data && result.data.facets) {
                // We assume that if facets are available, the default facets are always included
                topKeywords = [...result.data.facets.significant_tag_count, ...result.data.facets.tag_count]
                  .filter(tag => (!tag.type || tag.type !== 'org') && tag.count > 0)

                topKeywords = _uniqueBy(topKeywords, 'uri')
              }

              // If we're fetching a new page of content, only append the results, leave everything else be
              this.results.content = result.data.results
              this.results.contentTypeCounts = result.data.facets.media_type_counts
              this.results.contentTotalCount = result.total
              this.topKeywords = topKeywords
              this.topSources = result.data.facets.top_sources
              this.fetchingContent = false
            })
            .catch(error => {
              console.log('Something went wrong while fetching content: ', error)
              this.errorWhileFetchingContent = true
              this.fetchingContent = false
            })

          if (this.searchQuery !== expectedQuery) {
            // Dirty read
            return
          }

          if (this.conceptSearch.status === 'in_progress') {
            this.$nextTick(() => {
              if (this.$refs.scrollable && currentScrollTop) {
                this.$refs.scrollable.scrollTop = currentScrollTop
              }
            })
          }
        } catch (error) {
          console.log(error)
          this.fetchingContent = false
        }
      }, 300),
      fetchActors: async function (force = false, isAutoAdded = false) {
        if (!this.canFetchActors) {
          return
        }

        try {
          let currentScrollTop
          if (this.$refs.scrollable) {
            currentScrollTop = this.$refs.scrollable.scrollTop
          }

          // Don't show a loading icon when the search is in progress, to prevent double loading icons
          this.fetchingActors = this.conceptSearch.status !== 'in_progress'
          const expectedQuery = this.searchQueryActors
          // this.lastSearchQuery = this.searchQueryActors

          if (this.conceptSearch && this.conceptSearch.geographyTags && this.isActorScouting && isAutoAdded) {
            const test = []
            this.conceptSearch.geographyTags.forEach(tag => {
              test.push({
                text: tag.label,
                value: tag.id,
                optionValue: {
                  facet: 'country',
                  text: tag.label,
                  value: tag.label,
                  hoverText: tag.label,
                },
              })
            })

            this.actorFilters.geoTags = test
          }

          var geoid = this.actorFilters.geoTags.filter(t => t.optionValue.facet === 'geoid') || []
          geoid = geoid.map(t => t.optionValue.value).join(',')

          var countries = this.actorFilters.geoTags.filter(t => t.optionValue.facet === 'country') || []
          countries = countries.map(tag => tag.optionValue.value).join(',')
          const category = this.actorFilters.category.map(tax => tax.value).join(',')
          const industries = this.actorFilters.industries.map(tax => tax.value).join(',')
          const technology = this.actorFilters.technology.map(tax => tax.value).join(',')
          const activities = this.actorFilters.activities.map(tax => tax.value).join(',')
          const maturity = this.actorFilters.maturity.map(tax => tax.value).join(',')

          this.actorsParameters = {
            linked_concept_search_id: this.actorFilters.isRelevant ? this.conceptSearch.id : null,
            'address.country': countries,
            category,
            industries,
            technology,
            activities,
            maturity,
            'ml-supported': true,
          }

          // Based on the active actor source (similarity or content), add the article ids to the filter
          if (this.actorSource === 'content') {
            var articleIds = []

            if (this.results.content && this.results.content.length > 0) {
              articleIds = this.results.content.filter(result => ['rss', 'pr', 'blog'].includes(result.media_type)).map(result => result.sql_media_id) // TODO: when we incorporate patents into this view, we need to distinguish between articles and patents unless we can identify the type of file in the back-end
            }

            this.actorsParameters.contentFilter = articleIds
          }
          const result = await fetchActorsForSearch(this.conceptSearch.id, this.actorsParameters)

          if (this.searchQueryActors !== expectedQuery && !force) {
            // Dirty read
            return
          }

          this.displayScores = result.settings && result.settings.hasScoreConfiguration
          this.results.actors = result.actors

          this.fetchingActors = false

          if (this.conceptSearch.status === 'in_progress') {
            this.$nextTick(() => {
              if (this.$refs.scrollable && currentScrollTop) {
                this.$refs.scrollable.scrollTop = currentScrollTop
              }
            })
          }
        } catch (error) {
          this.fetchingActors = false
          console.error(error)
        }
      },
      goToDirectory() {
        if (this.conceptSearch.portfolio) {
          this.$store.commit(PORTFOLIO_MUTATION_TYPES.SET_ACTIVE_PORTFOLIO, this.conceptSearch.portfolio)
          this.$store.commit(FILTERS_MUTATION_TYPES.SET_PORTFOLIO, this.conceptSearch.portfolio.id)
        }

        this.$router.push('/actors')
      },
      goToKnowledgeBase() {
        this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.CLEAR)
        this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.SET_CONCEPT_SEARCH, this.conceptSearch.id)

        this.$router.push('/dashboards/knowledge-base')
      },
      resetStatus() {
        resetExplorationStatus(this.conceptSearch.id)
          .then(response => {
            this.conceptSearch.status = 'done'
          })
      },
      startEditing() { // @deprecated
        this.$store.commit(CONCEPT_SEARCH_GUIDE_MUTATION_TYPES.UPDATE_CONCEPT_SEARCH, {
          id: this.conceptSearch.id,
          reportType: this.conceptSearch.report_type,
          title: this.conceptSearch.title || '',
          description: this.conceptSearch.description || '',
          scopeDescription: this.conceptSearch.scope_description || '',
          scopeDescriptionAnalysis: {},
          searchTopics: this.conceptSearch.search_topics || [],
          context: this.conceptSearch.context || [], // The context to include in the search
          exclude: this.conceptSearch.exclude || [], // The chosen topics to exclude
          topics: this.conceptSearch.topics || [], // The chosen topics to analyse on
          geographyContext: this.conceptSearch.geography_context || [], // The full geography context
          customScores: this.conceptSearch.scoring_config || [],
          timespan: this.conceptSearch.timespan || 'month',
          newsGroups: this.conceptSearch.newsGroups || [],
        })

        this.startAtStep = 1

        this.editing = true
      },
      handleEditDone(conceptSearch) {
        this.editing = false
        this.conceptSearch = conceptSearch
      },
      remove() {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          body: 'Are you sure you want to delete this search?',
          resource: this.conceptSearch,
          modalContextType: 'concept-search',
        })

        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
      },
      openArticle(resource) {
        this.$router.push({
          name: 'ConceptMap',
          params: {
            id: this.$route.params.id,
            tab: LEFT_TABS.CONTENT,
            sub: resource.sql_media_id,
          },
        })
      },
      validateTab(tab) {
        return this.simplifiedUi || !Object.values(LEFT_TABS).includes(tab) || !this.availableLeftTabs.includes(tab) ? LEFT_TABS.CONTENT : tab
      },
      updateDisplayedArticle(id) {
        if (!id) {
          this.displayedArticle.showing = false

          return
        }

        this.displayedArticle.loadingData = true
        this.displayedArticle.showing = true

        let resourcePromise

        for (const resource of this.results.content) {
          if (resource.sql_media_id === id) {
            resourcePromise = Promise.resolve(resource)
            this.displayedArticle.loadingData = false
            break
          }
        }

        if (resourcePromise === undefined) {
          resourcePromise = getFileDetails(id)
            .then(resource => {
              resource.sql_media_id = id

              this.displayedArticle.loadingData = false
              return resource
            })
        }

        resourcePromise.then(resource => {
          this.displayedArticle.resource = resource
          this.displayedArticle.relevant_in = resource.relevant_in
          this.displayedArticle.data = null
          this.displayedArticle.details = null

          this.displayedArticle.loadingData = true

          if (!this.isLoggedIn) {
            trackArticle(this.$route.params.sub, this.$route.params.id, 'view_external')
            window.location = resource.original_article.url
          }

          this.refreshAnnotationData(resource)

          getFileDetails(resource.sql_media_id)
            .then(details => {
              this.displayedArticle.details = details
              this.displayedArticle.loadingData = false
            })
        })
      },
      refreshAnnotationData(resource, bustCache = false) {
        this.displayedArticle.loadingAnnotations = true

        return annotateArticle(resource.sql_media_id, this.conceptSearch.id, bustCache)
          .then(data => {
            this.displayedArticle.loadingData = false // This can probably be removed, however at the time writing we just need a fix for distinguishing annotation loading vs file detail loading
            this.displayedArticle.loadingAnnotations = false
            this.displayedArticle.data = data
            trackArticle(this.$route.params.sub, this.$route.params.id, 'view_in_app')
          })
          .catch(error => {
            this.displayedArticle.loadingData = false
            this.displayedArticle.loadingAnnotations = false

            if (error.statusCode === 403 && resource.original_article.url) {
              trackArticle(this.$route.params.sub, this.$route.params.id, 'view_external')
              window.location = resource.original_article.url
            }
          })
      },
      refreshConceptSearchUntilDone() {
        this.searchInterval = setInterval(() => {
          if (!this.conceptSearchAutoReloadEnabled) {
            clearInterval(this.searchInterval)
            return
          }

          this.$store.dispatch(CONCEPT_SEARCHES_ACTION_TYPES.FETCH_CONCEPT_SEARCH_DETAIL, this.$route.params.id).then((result) => {
            if (!this.isActorScouting) {
              this.fetchContent()
            }
            this.fetchActors()

            if (this.conceptSearch.status === 'done' || !this.conceptSearchAutoReloadEnabled) {
              clearInterval(this.searchInterval)
            }
          })
        }, 30000)
      },
      updateNewsGroupOptions() {
        return NewsGroups
          .get()
          .then(response => {
            this.newsGroupOptions = response.map(newsGroup => {
              return {
                value: newsGroup.id,
                label: newsGroup.name,
              }
            })
          })
      },
      updateDictionaryOptions() {
        Dictionary
          .get()
          .then(dictionaries => {
            this.dictionaries = dictionaries
            this.excludedDictionaries = dictionaries
          })
      },
      fetchContentDebounced: debounce(function () {
        this.fetchContent()
      }, 1000),
    },
    async created() {
      try {
        // Set a flag that prevents our watchers from fetching content, we're triggering that search ourselves manually later in this hook
        await this.$store.dispatch(CONCEPT_SEARCHES_ACTION_TYPES.FETCH_CONCEPT_SEARCH_DETAIL, this.$route.params.id)

        if (this.conceptSearch.status === 'in_progress' && this.conceptSearchAutoReloadEnabled) {
          this.refreshConceptSearchUntilDone()
        }

        this.updateDictionaryOptions()
        this.updateNewsGroupOptions()

        // This will trigger the search for content
        this.setDefaultFilters()
        this.setTimeSpanFilters()

        // If the user is not a member, default sort on recency
        if (!this.$store.getters.isMember) {
          this.contentFilters.sort = 'recency'
        }

        // Fetch content if it's not an actor scouting
        if (!this.isActorScouting) {
          this.fetchContent()
        }
        this.fetchActors(false, true)

        this.startIntroJs()
      } catch (e) {
        console.error(e)
      }

      if (this.isActorScouting) {
        this.selectedLeftTab = this.validateTab('actors')
      } else {
        this.selectedLeftTab = this.validateTab(this.$route.params.tab)
      }

      this.updateDisplayedArticle(this.$route.params.sub)
    },
    async beforeRouteUpdate(to, from, next) {
      if (to.params.id !== from.params.id && to.params.id) {
        try {
          await this.$store.dispatch(CONCEPT_SEARCHES_ACTION_TYPES.FETCH_CONCEPT_SEARCH_DETAIL, this.$route.params.id)
          this.setDefaultFilters()
          this.setTimeSpanFilters()

          // If the user is not a member, default sort on recency
          if (!this.$store.getters.isMember) {
            this.contentFilters.sort = 'recency'
          }

          if (!this.isActorScouting) {
            this.fetchContent()
          }
          this.$store.commit(CONCEPT_SEARCHES_MUTATION_TYPES.CLEAR_CONCEPT_SEARCH_DETAIL)
          this.fetchActors()
        } catch (e) {
          console.error(e)
        }
      }

      this.selectedLeftTab = this.validateTab(to.params.tab)
      this.updateDisplayedArticle(to.params.sub)

      next()
    },
    mounted() {
      this.$bus.on('articleDeleteConfirmation', (context) => {
        this.confirmRemoveArticle(context.resource.sql_media_id)
      })
      this.$bus.on('globalArticleDeleteConfirmation', (context) => {
        this.confirmRemoveArticle(context.article.sql_media_id)
      })

      this.$bus.on('conceptSearchDeleteConfirmation', (context) => {
        if (context.resource && context.resource.id == this.conceptSearch.id) {
          this.deleting = true
          deleteConceptSearch(this.conceptSearch.id)
            .then(response => {
              // Refresh the concept searches
              this.$bus.emit('refreshConceptSearches')
              this.deleting = false
              this.$router.push('/')
            })
            .catch(error => {
              this.deleting = false
              console.error(error)
            })
        }
      })
    },
    beforeUnmount() {
      // Because concept search guide data is currently in global state
      this.$store.commit(CONCEPT_SEARCH_GUIDE_MUTATION_TYPES.RESET_CONCEPT_SEARCH)

      // Set the concept search filters
      this.contentFilters.conceptSearchId = this.conceptSearch.id
      this.$store.commit(CONCEPT_SEARCHES_MUTATION_TYPES.SET_CONCEPT_SEARCH_FILTER, this.contentFilters)

      clearInterval(this.searchInterval)

      this.$bus.off('conceptSearchDeleteConfirmation')
      this.$bus.off('articleDeleteConfirmation')
      this.$bus.off('globalArticleDeleteConfirmation')
    },
    watch: {
      actorSource() {
        this.fetchActors(true)
      },
      dictionaryGroup() {
        // If the dictionary group changes, reset the specific dictionary ID
        this.filterOnSpecificDictionary = null

        if (!this.isActorScouting) {
          this.fetchContent()
        }
        this.fetchActors()
      },
      contentFilters: {
        deep: true,
        handler() {
          if (!this.isActorScouting) {
            this.fetchContentDebounced()
          }
          this.filterDiff()
        },
      },
      actorFilters: {
        deep: true,
        handler() {
          this.fetchActors()
        },
      },
    },
    components: {
      IconButton,
      TopSourcesList,
      Checkbox,
      PublishButton,
      FollowButton,
      AnalyzeOverviewPanel,
      LandscapePanel,
      ArticleOverviewPanel,
      Dropdown,
      ArticlePanel,
      ArticlesPanel,
      ActorsPanel,
      DictionaryTag,
      TopKeywordsList,
      Avatar,
      ConceptSearchGuide,
      ArticleActorPanel,
      Tabs,
      Loading,
      LabelFilter,
      AutocompleteTagInput,
      ManageConceptSearchPopup,
      DsTextarea,
    },
  })

  const SEARCH_RESULT_CATEGORIES = {
    ACTORS: 'Actors',
    CONTENT: 'Content',
  }

  const LEFT_TABS = {
    TOP_KEYWORDS: 'top-keywords',
    CONTENT: 'content',
    LANDSCAPE: 'landscape',
    TOP_SOURCES: 'top-sources',
    ACTORS: 'actors',
  }

  function getGeographyItemLabel(item) {
    if (!item || !item.label || !item.label.eng) {
      return '<no name>'
    }

    let label = item.label.eng

    if (item.type === 'place' && item.country && item.country.label && item.country.label.eng && !label.includes(item.country.label.eng) && !label.includes(',')) {
      label += ' (' + item.country.label.eng + ')'
    }

    return label
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/_variables.scss";

  .container {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .concept-map, .knowledge-base {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
    overflow-y: auto;
  }

  .extracted-relation__container {
    display: flex;
    justify-content: space-between;
    padding: 15px 10px 15px 0;
    border-bottom: 2px solid $color-borders;

    &:first-child {
      padding-top: 0;
    }

    .extracted-relation {
      padding: 3px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;

      span.extracted-actor {
        opacity: 0.5;

        &.clickable {
          cursor: pointer;
        }

        a {
          color: black;
        }
      }
    }

    .extracted-relation-icon {
      color: black;
      font-weight: 500;
      font-size: 23px;
      display: flex;
      align-items: center;

      &.clickable {
        cursor: pointer;
      }

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }

  .concept-map {
    width: 60%;
  }

  .knowledge-base {
    position: relative;
    border-left: 1px solid $color-body-borders;
    width: 40%;
  }

  .knowledge-base__top-right {
    position: absolute;
    top: 2px;
    right: 2px;
  }

  .like-filter {
    cursor: pointer;
  }

  .concept-map__results {
    display: flex;
    flex-direction: column;
    height: 100%;
    border: none;
  }

  .concept-map__results-header {
    padding: 1.5rem 1.5rem 0 1.5rem;
    flex-direction: row;

    .concept-map__results-header-left {
      flex-direction: column;
      width: 100%;

      .concept-search-bar {
        max-width: none;
        padding-right: 0;
      }

      :deep(input[type="text"]) {
        border: 0;
      }

      .header-tabs {
        justify-content: space-between;
        display: flex;

        @media (min-width: 1500px) {
          justify-content: space-around;
        }
      }
    }
  }

  .concept-map__results-content {
    max-height: none;
    min-height: 0;
    padding: calc(1.5rem - 15px) 1.5rem;
    height: 100%;

    .has-values {
      :deep(input) {
        border-color: var(--primary);
      }
    }
  }

  .concept-map__results-category {
    color: $color-text-grey-light;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1.5px;
    transition: all .2s;
    border-bottom: 2px solid transparent;
    margin-left: 0;
    white-space: nowrap;
    margin-right: 10px;
    text-decoration: none;
    padding: 0 0 0.5rem 0;

    @media (min-width: 1500px) {
      margin-left: 7rem;

      &:first-child {
        margin-left: 0;
      }
    }

    &:first-child {
      margin-left: 0;
    }

    &:not(.concept-map__results-category--selected):hover {
      cursor: pointer;
      color: $color-primary;
    }
  }

  .concept-map__results-category--selected {
    color: $color-primary;
    border-bottom: 2px solid $color-primary;
  }

  .link-cancel-search {
    cursor: pointer;
    text-decoration: underline;
    color: #888;
  }

  .status {
    color: #888;
    text-align: left;
    max-height: 18px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .concept-map-image {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    display: flex;
    flex-direction: column;
    color: white;
    padding: 20px;
    border-bottom: 1px solid $color-body-borders;

    &.no-filter {
      height: 150px;
    }

    .concept-map-image__title {
      display: flex;
      flex: auto;
      flex-direction: row;

      @media (max-width: $screen-lg) {
        flex-direction: column;

        > div {
          margin-top: 0px !important;
        }
      }

      .a-button {
        margin-top: auto;
        margin-bottom: auto;
      }
    }

    .edit-scope-description-button {
      margin-left: auto;
      padding-left: 5px;
      width: 26px;
      height: 26px;
      border: 1px solid $color-background-light-grey;
    }

    .concept-map-image__subtitle {
      display: flex;
      flex-direction: row;

      .concept-map__subtitle-status-info {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .scope-description {
        color: black;
        overflow: hidden;
        text-overflow: ellipsis;
        line-clamp: 3;
        -webkit-line-clamp: 3;
        margin: 3px 0;
        max-height: 54px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }

      .scope-description-expanded {
        max-height: none;
        display: block;
        overflow: visible;
      }

      .scope-description__show-more {
        color: black;
        text-decoration: underline;
        margin-bottom: 6px;
        cursor: pointer;
      }
    }

    .concept-map-title {
      flex-grow: 1;
      min-width: 0;
      margin: auto 0;

      a {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: block;
        text-decoration: none;
        color: black;
        font-weight: 400;
      }
    }

    .concept-search__title-button {
      border: 1px solid #eeeeee;
      padding: 12px;
      position: relative;
      margin-top: auto;
      margin-bottom: auto;

      :deep(.svg-icon) {
        path {
          fill: $color-text-grey-light !important;
        }
      }

      &:hover {
        :deep(path) {
          fill: $data-color-2 !important;
        }
      }
    }

    .concept-search__title-small-button {
      padding: 6px;
      position: relative;
      margin-top: auto;
      margin-bottom: auto;
    }

    .concept-search__title-auto-refresh {
      min-width: 104px;
      line-height: 23px;
      color: black;
      padding-left: 2px;
      align-items: center;
    }
  }

  .filters__mask {
    height: 100vh;
    width: 100vh;
    position: absolute;
    transition: opacity .3s ease;
    background-color: var(--primary-extra-lightest);
    opacity: .4;
    z-index: 99;
    top: 0;
    left: 0;
  }

  .concept-map-overview {
    position: relative;
    padding: 20px;

    .h4 {
      text-transform: uppercase;
    }

    .filters {
      line-height: 2;
      margin-top: 1em;

      p {
        margin-top: 1em;
      }
    }
  }

  .concept-map__no-results {
    display: flex;
    justify-content: center;
    margin-top: 2em;
  }

  .concept-map__tags {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;

    .concept-map__tags__tag {
      vertical-align: top;
      background-color: #eee;
      border: 2px solid #eee;
      font-weight: 200;
      font-size: 12px;
      color: inherit;
      border-radius: 0;
      height: 26px;
      padding: 3px 5px 3px 5px;
      margin: 2px 10px 2px 0;
    }
  }

  .concept-map-overview, .concept-map__results-sort {
    .label-filter__text {
      background: white;
      border-color: var(--primary);
      margin-bottom: 2px;

      &.selected {
        background: var(--primary);
        color: white;
      }
    }

    :deep(.datascouts-tag-input .vue-tags-input .ti-tag), :deep(.multiselect.multiselect--datascouts .multiselect__tag) {
      background-color: var(--primary);
      color: white;
      border-color: var(--primary);

      .actions .action .svg-icon--remove g {
        stroke: white;
      }
    }

    .has-values {
      :deep(.ti-input), :deep(.multiselect__tags) {
        border-color: var(--primary);
      }
    }
  }

  .concept-map-overview__buttons {
    display: flex;
    flex-wrap: wrap;

    .button {
      margin-top: 20px;
    }
  }

  .concept-map-overview__filer-buttons {
    border: 2px solid var(--primary);
    background-color: var(--primary);
    display: inline-block;
    color: white;
    padding: 5px;
    cursor: pointer;
    margin-top: 10px;

    &.clickable {
      border-color: var(--primary);
      background-color: transparent;
      color: black;
    }
  }

  .concept-map-overview__divider {
    margin: 20px -20px;
    border-color: transparent;
    border-bottom: 0;
    background-color: $color-borders;
    color: $color-borders;
  }

  .concept-map__content-relevancy-filter {
    display: flex;
    margin-left: auto;

    .relevant-filter, .like-filter, .export-link {
      display: flex;
      justify-content: center;
      margin-left: auto;
      cursor: pointer;

      .svg-icon {
        margin-left: 4px;
      }
    }

    .like-filter, .relevant-filter {
      margin-right: 8px;
    }
  }
</style>
