<template>
  <div>
    <div class="exploration-settings__header">
      <h2>Define the signal you like to monitor</h2>
    </div>
    <div>
      <form-group label="Title" :error="titleError">
        <ds-input v-model="conceptSearch.title" placeholder="Signal title" :disabled="saving"/>
      </form-group>

      <form-group label="Description">
        <ds-textarea v-model="conceptSearch.scopeDescription" placeholder="Describe the signal by adding a short description"/>
      </form-group>

      <form-group label="Which lexicons do you want to search on (max 3.)">
        <dropdown
          :multiple="true"
          :search="true"
          :options="topicOptions"
          v-model="conceptSearch.context"
          :disabled="saving"
          :class="{ 'disabled-while-editing': saving }"
          :maxSelectedOptions="3"
        />
        <template v-slot:instructions v-if="!saving && canUserEditExplorationTopics">
          <a href="javascript:;" @click="handleEditTopics">Edit exploration topics</a>
        </template>
      </form-group>

      <form-group label="Which portfolio do you want to search on (max 1.)">
        <AutocompleteTagInput
          :tags="selectedPortfolios"
          :options="portfolioOptions"
          :addOnlyFromAutocomplete="true"
          placeholder="Search for a portfolio"
          @tagChanged="updatePortfolioOptions"
          @input:raw="updateSelectedPortfolios"
          :minInputLength="1"
          :max-tags="1"
        />
      </form-group>

      <form-group label="Geographical context">
        <AutocompleteTagInput
          :class="{ 'disabled-while-editing': saving }"
          :tags="geographyContextTags"
          :options="geographyOptions"
          :addOnlyFromAutocomplete="true"
          placeholder="Search within specific areas"
          @tagChanged="updateGeographyOptions"
          @input:raw="updateGeographyContext"
        />
      </form-group>

      <form-group label="Languages" v-if="$store.getters.hasAccessToMultiLinguality">
        <dropdown :multiple="true" :options="supportedLanguages" v-model="conceptSearch.languages"/>
      </form-group>

      <!-- NOTE: do not allow for excluded lexicons to be selected because that won't work with the back-end as we will hit our max. of keywords we can use -->
      <form-group label="News Outlets">
        <dropdown :multiple="true" :options="newsGroupOptions" v-model="conceptSearch.newsGroups"/>
      </form-group>
    </div>
    <div class="exploration-settings__button">
      <ds-button variant="primary" :label="saving ? 'Saving...' : 'Create'" @click="save()" :disabled="!canSubmit"/>
      <ds-button label="Cancel" @click="cancel()" v-if="!saving"/>
    </div>
  </div>
</template>

<script lang="ts">
  import DsTextarea from '../Form/DsTextarea.vue'
  import AutocompleteTagInput from '../Form/AutocompleteTagInput.vue'
  import Dropdown from '../Dropdown/Dropdown.vue'

  import { Dictionary, fetchGeographySuggestions, NewsGroups, storeConceptSearch, updateConceptSearchAndExplore, validateTitle } from '../../api/exploration.js'
  import debounce from 'lodash/debounce.js'
  import ConceptSearchMixin from '../../util/ConceptSearchMixin.js'
  import { fetchPortfolio } from '../../api/portfolios.js'
  import { defineComponent } from 'vue'

  // Note: if this components become more of a guide, like ConceptSearchGuide, rework this component into a directory just like it
  export default defineComponent({
    name: 'ManageSignal',
    components: {
      DsTextarea,
      AutocompleteTagInput,
      Dropdown
    },
    data () {
      return {
        conceptSearch: {
          id: null,
          reportType: 'technology_radar',
          title: '',
          description: '',
          scopeDescription: '',
          searchTopics: [],
          context: [], // The context to include in the search
          topics: [], // The chosen topics to analyse on
          geographyContext: [], // The full geography context
          customScores: [],
          timespan: 'month',
          newsGroups: [],
          languages: [],
          search_portfolios: [],
        },
        saving: false,
        dictionaries: null,
        geographyOptions: [],
        portfolioOptions: [],
        selectedPortfolios: [],
        titleValidation: Promise.resolve(true),
        isTitleValid: true,
        titleError: null,
        newsGroupOptions: []
      }
    },
    computed: {
      canUserEditExplorationTopics () {
        return this.$store.getters.isOwner
      },
      canSubmit () {
        return (this.conceptSearch.context.length > 0 || this.conceptSearch.search_portfolios.length > 0) &&
        this.conceptSearch.title.length > 0 &&
        this.isTitleValid &&
        !this.saving
      },
      geographyContextTags () {
        return this
          .conceptSearch
          .geographyContext
          .map(item => ({
            text: getGeographyItemLabel(item),
            optionValue: { geographyObject: item },
          }))
      },
      topicOptions () {
        if (!this.dictionaries) {
          return []
        }

        return this.dictionaries.map(item => ({
          value: item.id,
          label: item.value,
        }))
      }
    },
    methods: {
      updateSelectedPortfolios (selectedPortfolios) {
        this.conceptSearch.search_portfolios = selectedPortfolios.map(portfolio => portfolio.optionValue.id)

        this.selectedPortfolios = selectedPortfolios
      },
      updatePortfolioOptions: debounce(async function (query) {
        if (!query) {
          return
        }

      // This will search for a portfolio which matches the input value in the search field
        var filters = Object.assign({}, { query: query })

        fetchPortfolio(filters)
        .then(portfolios => {
          this.portfolioOptions = []

          this.parsePortfolioOptions(portfolios)
        })
        .catch(errors => {
          console.log(errors)
        })
      }, 150),
      parsePortfolioOptions (portfolios) {
        if (!Array.isArray(portfolios)) {
          return
        }

        var options = []

        // Don't include virtual portfolios in the portfolios a user can choose from
        portfolios.forEach(portfolio => {
          if (portfolio.virtual) {
            return
          }

          options.push({
            text: portfolio.name,
            id: portfolio.id,
          })
        })

        this.portfolioOptions = options
      },
      validateTitle: debounce(function () {
        if (this.conceptSearch.title !== '') {
          this.titleValidation = validateTitle(this.conceptSearch.title, this.conceptSearch.id).then(({ valid }) => valid)
        } else {
          this.titleValidation = Promise.resolve(true)
        }

        this.isTitleValid = true
        this.titleValidation.then(valid => {
          this.isTitleValid = valid
          this.titleError = valid ? null : 'A signal with this title already exists'
        })
      }, 300),
      updateGeographyOptions: debounce(async function (input) {
        fetchGeographySuggestions({ query: input })
          .then(response => {
            this.geographyOptions = []

            if (!Array.isArray(response)) {
              return
            }

            response.forEach(item => {
              if (item.label.eng) {
                this.geographyOptions.push({
                  text: getGeographyItemLabel(item),
                  geographyObject: item,
                })
              }
            })
          })
          .catch(() => {
            this.geographyOptions = []
          })
      }, 150),
      updateGeographyContext (context) {
        this.conceptSearch.geographyContext = context.map(item => {
          return item.optionValue.geographyObject
        })
      },
      updateNewsGroupOptions () {
        return NewsGroups
          .get()
          .then(response => {
            this.newsGroupOptions = response.map(newsGroup => {
              return {
                value: newsGroup.id,
                label: newsGroup.name
              }
            })
          })
      },
      updateDictionaryOptions () {
        Dictionary
          .get()
          .then(dictionaries => {
            this.dictionaries = dictionaries
          })
      },
      handleEditTopics () {
        this.$router.push('/settings/exploration')
      },
      save () {
        this.saving = true
        this.response = null

        let promise

        if (this.conceptSearch.id === null) {
          promise = storeConceptSearch({
            title: this.conceptSearch.title,
            report_type: this.conceptSearch.reportType,
            context: this.conceptSearch.context,
            exclude: this.conceptSearch.exclude,
            news_groups: this.conceptSearch.newsGroups,
            search_topics: this.conceptSearch.searchTopics.map(topic => {
              if (topic.label.indexOf(' (user-generated)') > 0) {
                return {
                  label: topic.label.replace(' (user-generated)', ''),
                  value: topic.value,
                }
              }
              return { label: topic.label, value: topic.value }
            }),
            search_portfolios: this.conceptSearch.search_portfolios,
            dictionary_context_id: this.conceptSearch.topics,
            description: this.conceptSearch.description,
            geography_context: this.conceptSearch.geographyContext,
            scope_description: this.conceptSearch.scopeDescription,
            timespan: this.conceptSearch.timespan,
            scoring_config: this.conceptSearch.customScores,
            languages: this.conceptSearch.languages
          })
        } else {
          promise = updateConceptSearchAndExplore(this.conceptSearch.id, {
            title: this.conceptSearch.title,
            report_type: this.conceptSearch.reportType,
            context: this.conceptSearch.context,
            exclude: this.conceptSearch.exclude,
            news_groups: this.conceptSearch.newsGroups,
            search_topics: this.conceptSearch.searchTopics,
            dictionary_context_id: this.conceptSearch.topics,
            description: this.conceptSearch.description,
            geography_context: this.conceptSearch.geographyContext,
            scope_description: this.conceptSearch.scopeDescription,
            timespan: this.conceptSearch.timespan,
            scoring_config: this.conceptSearch.customScores,
            languages: this.conceptSearch.languages
          })
        }

        promise
          .then(response => {
            this.saving = false
            this.response = response
            this.$emit('saved', { conceptSearchId: response.concept_search_id })
          })
          .catch(() => {
            this.saving = false
          })
      },
      cancel () {
        this.$emit('cancel')
      }
    },
    created () {
      this.updateDictionaryOptions()
      this.updateNewsGroupOptions()
    },
    watch: {
      'conceptSearch.title': function () {
        this.validateTitle()
      },
    },
    mixins: [ConceptSearchMixin]
  })

  function getGeographyItemLabel (item) {
    if (!item || !item.label || !item.label.eng) {
      return '<no name>'
    }

    let label = item.label.eng

    if (item.type === 'place' && item.country && item.country.label && item.country.label.eng && !label.includes(item.country.label.eng) && !label.includes(',')) {
      label += ' (' + item.country.label.eng + ')'
    }

    return label
  }
</script>

<style scoped>

</style>
