<template>
  <div v-if="facetCounts && facetCounts.length">
    <div class="category-stats__container">
      <template v-for="(facetItem, facetIndex) in facetCountsWithFillers" :key="'facet-type-' + facetIndex">
        <div class="category-stats__child"
             @click="showActorsForFacet(facetItem.facetName, facetItem.value)"
             v-if="!facetItem.isFillerCard"
        >
          <div class="category-stats-title">
            {{
              (getLabelForTaxonomyValue(facetItem.facetName, facetItem.value) || facetItem.name || facetItem.label).split('>').slice(-1)[0].trim()
            }}
          </div>
          <div class="category-stats-count__container">
            <div class="category-stats-count__value">{{ facetItem.count }}</div>
          </div>
        </div>
        <div v-else class="category-stats__child is-filler"></div>
      </template>
    </div>
  </div>
</template>

<script>
  import TranslationsMixin from '../../util/TranslationsMixin'
  import FiltersMixin from '../../util/FiltersMixin'
  import { toAmount } from '../../util/currency'
  import { defineComponent } from 'vue'

  export default defineComponent({
    emits: ['click'],
    props: {
      source: {
        type: String,
        default: 'home',
      },
      facetCounts: {
        type: Array,
        default: [],
      },
      spottingAreaId: {
        type: Number,
        default: null,
      },
    },
    methods: {
      toAmount,
      showActorsForFacet (facetType, value) {
        this.$emit('click', { facetType, value })
      },
    },
    computed: {
      facetCountsWithFillers () {
        const idealNumberOfFacets = 6
        const result = [...this.facetCounts]

        if (this.facetCounts.length && this.facetCounts.length < idealNumberOfFacets) {
          const facetsToAdd = idealNumberOfFacets - this.facetCounts.length
          for (let i = 0; i < facetsToAdd; i++) {
            result.push({ isFillerCard: true })
          }
        }
        return result
      },
      config () {
        return this.$store.state.config
      },
      availableFilterControls () {
        return this.config.simplifiedFilterControls || []
      },
    },
    mixins: [TranslationsMixin, FiltersMixin],
  })
</script>
<style scoped lang="scss">
  @import "../../../scss/variables.scss";

  .category-stats__container {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    font-size: 20px;
    justify-content: space-between;
  }

  .category-stats__child {
    display: flex;
    border: 1px solid white;
    height: 150px;
    justify-content: center;
    background-color: var(--primary-extra-lightest);
    flex-direction: column;
    flex-basis: calc(100% / 6 - 1%);
    box-sizing: border-box;
    text-align: center;
    margin: 2rem 0;
    cursor: pointer;
    border-radius: $default-border-radius;
    transition: all 0.3s ease-in-out;

    &.is-filler {
      border: 0;
      box-shadow: none;
      height: 0;
      margin: 0;
    }

    @media screen and (max-width: $screen-lg) {
      flex-basis: 30%;
    }

    @media screen and (max-width: $screen-md) {
      flex-basis: 45%;
    }

    .category-stats-title {
      text-transform: uppercase;
      font-family: $font-stack-secondary;
      font-size: 12px;
      margin-bottom: 10px;
    }

    .category-stats-count__container {
      margin: 0 auto;

      .category-stats-count__value {
        font-size: 55px;
      }

      .category-stats-count__diff {
        color: red;
        font-family: $font-stack-secondary;
        font-size: 12px;
        text-align: right;

        &.is-positive {
          color: green;
        }
      }

    }
  }

  .category-stats__child:hover {
    background-color: #f7f7f7;
    border: 1px solid #f7f7f7;
  }

</style>
