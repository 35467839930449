import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters'

/* Computed */

export const portfolioFilter = {
  get () {
    return this.$store.state.filters.portfolio || '-'
  },
  set (v) {
    this.$store.commit('FILTERS/SET_PORTFOLIO', v)
  }
}

export const countryFocus = {
  get () {
    return this.$store.state.filters.countryFocus
  },
  set (v) {
    this.$store.commit(FILTERS_MUTATION_TYPES.SET_COUNTRY_FOCUS, v)
  }
}

export const customScoreMin = {
  get () {
    return this.$store.state.filters.customScoreRange.customScoreMin;
  },
  set(v) {
    this.$store.commit(FILTERS_MUTATION_TYPES.UPDATE_CUSTOM_SCORE_RANGE, {start: v, end: this.$store.state.filters.customScoreRange.customScoreMax});
  }
}

export const customScoreMax = {
  get () {
    return this.$store.state.filters.customScoreRange.customScoreMax;
  },
  set(v) {
    this.$store.commit(FILTERS_MUTATION_TYPES.UPDATE_CUSTOM_SCORE_RANGE, {start: this.$store.state.filters.customScoreRange.customScoreMin, end: v});
  }
}

/*export const scoreFilter = {
  get () {
    return {
      start: getFacetValue.call(this, 'scoreMin'),
      end: getFacetValue.call(this, 'scoreMax')
    }
  },
  set ({ start, end }) {
    this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, { facet: 'scoreMin', values: start })
    this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, { facet: 'scoreMax', values: end })
  }
}*/

export const mentionsFilter = {
  get () {
    return {
      start: getFacetValue.call(this, 'mentionsMin'),
      end: getFacetValue.call(this, 'mentionsMax')
    }
  },
  set ({ start, end }) {
    this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, { facet: 'mentionsMin', values: start })
    this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, { facet: 'mentionsMax', values: end })
  }
}

export const completenessFilter = {
  get () {
    return {
      start: this.$store.state.filters.completenessRange.completenessMin,
      end: this.$store.state.filters.completenessRange.completenessMax,
    }
  },
  set ({ start, end }) {
    this.$store.commit(FILTERS_MUTATION_TYPES.UPDATE_COMPLETENESS_RANGE, {
      start: start,
      end: end,
    })
  }
}

export const createdAtDatesFilter = {
  get () {
    return {
      start: this.$store.state.filters.dateRanges.createdAtStart,
      end: this.$store.state.filters.dateRanges.createdAtEnd,
    }
  },
}

export const claimedAtDatesFilter = {
  get () {
    return {
      start: this.$store.state.filters.dateRanges.claimedAtStart,
      end: this.$store.state.filters.dateRanges.claimedAtEnd,
    }
  },
}

export const updatedAtDatesFilter = {
  get () {
    return {
      start: this.$store.state.filters.dateRanges.updatedAtStart,
      end: this.$store.state.filters.dateRanges.updatedAtEnd,
    }
  },
}

/* Methods */

export function getFacetValue (facet) {
  return (this.$store.state.filters.keywords.find(k => k.facet === facet) || {}).value || null
}
