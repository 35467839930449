<template>
  <div class="has-action-bar">
    <div class="tabs-border">
      <ul class="tabs__navigation">
        <li class="tabs__navigation__label">
          Integrations:
        </li>
        <li
          v-for="tab in tabs"
          class="tabs__navigation__item"
          :class="{ 'tabs__navigation__item--active': activeTab === tab }"
          @click="activeTab = tab"
          v-text="tab"
        ></li>
      </ul>
    </div>

    <component :is="panel"/>
  </div>
</template>

<script>
  import ApiTokens from './ApiTokens.vue'
  import CrmIntegrations from './CrmIntegrations.vue'
  import Services from './Services.vue'
  import Slack from './Slack.vue'
  import PortfolioSyncLogs from './PortfolioSyncLogs.vue'
  import Stripe from './Stripe.vue'
  import Tracking from './Tracking.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    components: {
      ApiTokens,
      CrmIntegrations,
      Services,
      Slack,
      PortfolioSyncLogs,
      Stripe,
      Tracking,
    },
    data () {
      return {
        activeTab: 'crm',
      }
    },
    computed: {
      tabs () {
        var tabs = ['slack', 'API tokens', 'services', 'portfolio sync']

        if (!this.isPortfolioSyncEnabled) {
          tabs = ['slack', 'API tokens', 'services']
        }

        if (this.$store.getters.isOwn || this.$store.getters.isSales || ['teamleader', 'local', 'TeamleaderSegmentation', 'salesenablement', 'crypto'].includes(this.$store.getters.ecosystemName)) {
          tabs.push('crm')
        }

        if (this.$store.getters.hasAccessToEcosystemMemberPackages && this.$store.getters.isDeveloper) {
          tabs.push('stripe')
        }

        if (this.$store.getters.isDeveloper) {
          tabs.push('tracking')
        }

        return tabs
      },
      isPortfolioSyncEnabled () {
        return this.$store.getters.isPortfolioSyncEnabled
      },
      panel () {
        switch (this.activeTab) {
          case 'crm':
            return 'crm-integrations'
          case 'API tokens':
            return 'api-tokens'
          case 'portfolio sync':
            return 'portfolio-sync-logs'
          default:
            return this.activeTab
        }
      },
      form () {
        return this.$parent.$parent.form
      },
    },
    mounted () {
      if (this.tabs.includes('stripe') && this.$route.hash === '#stripe') {
        this.activeTab = 'stripe'
      }

      if (this.tabs.includes('API tokens') && this.$route.hash === '#api-tokens') {
        this.activeTab = 'API tokens'
      }
    },
    methods: {}
  })
</script>
