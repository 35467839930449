import { fetchProfile, getBillingInfo, getSubscription, logoutRequest, updateProfile } from '../../api/user.js'
import ls, { lsClear } from '../../util/ls.js'
import { identifyHeapUserById } from '../../util/analytics.js'
import { MUTATION_TYPES } from './user-helpers.js'

export const ACTION_TYPES = {
  LOGOUT_REQUEST: 'USER/LOGOUT_REQUEST',
  REFRESH_PROFILE: 'USER/REFRESH_PROFILE',
  FETCH_PACKAGE_SUBSCRIPTION: 'USER/FETCH_PACKAGE_SUBSCRIPTION',
  FETCH_BILLING_INFO: 'USER/FETCH_BILLING_INFO',
}

export const mutations = {
  [MUTATION_TYPES.STORE_PROFILE] (state, profile) {
    state.profile = profile
    window.user = profile
  },
  [MUTATION_TYPES.UPDATE_PROFILE] (state, profile) {
    Object.assign(state.profile, profile)
    Object.assign(window.user, profile)
    identifyHeapUserById(profile.id)
  },
  [MUTATION_TYPES.UPDATE_FOLLOWED_PORTFOLIOS] (state, profile) {
    Object.assign(state.profile, profile)
    Object.assign(window.user, profile)
  },
  [MUTATION_TYPES.UPDATE_OWNED_PORTFOLIOS] (state, profile) {
    Object.assign(state.profile, profile)
    Object.assign(window.user, profile)
  },
  [MUTATION_TYPES.UPDATE_FOLLOWED_SEARCHES] (state, conceptSearches) {
    state.profile = { ...state.profile, conceptSearches }
    Object.assign(window.user, state.profile)
  },
  [MUTATION_TYPES.FETCH_PACKAGE_SUBSCRIPTION_SUCCESS] (state, subscriptionInfo) {
    state.isFetchingPackageSubscription = false
    state.fetchPackageSubscriptionPromise = null
    state.hasFetchedPackageSubscription = true
    state.profile = { ...state.profile, subscriptionInfo }
    Object.assign(window.user, state.profile)
  },
  [MUTATION_TYPES.HAS_CLAIMED] (state, { id, name, actor_type, claim_verified }) {
    state.profile.claimableActorTypes = state.profile.claimableActorTypes.filter(t => t !== actor_type)
    state.profile.claims.push({ id, name, actor_type, claim_verified })
  },
  [MUTATION_TYPES.LOGOUT_SUCCESS] (state) {
    window.user = state.profile = { 'settings': {} }
  },
  [MUTATION_TYPES.STORE_SETTINGS] (state, settings) {
    state.profile.settings = Object.assign({}, state.profile.settings || {}, settings)

    if (state.profile.id) {
      updateProfile({ settings: state.profile.settings })
    }
  },
  [MUTATION_TYPES.SET_PACKAGE_SUBSCRIPTION_PROMISE] (state, promise) {
    state.fetchPackageSubscriptionPromise = promise
    state.isFetchingPackageSubscription = true
  },
  [MUTATION_TYPES.FETCH_BILLING_INFO_SUCCESS] (state, billingInfo) {
    state.billingInfo = billingInfo
  },
}

export const actions = {
  [ACTION_TYPES.REFRESH_PROFILE] ({ commit }) {
    return fetchProfile()
      .then(profile => {
        commit(MUTATION_TYPES.STORE_PROFILE, profile)
      })
  },
  [ACTION_TYPES.LOGOUT_REQUEST] ({ commit, getters }) {
    logoutRequest()
      .then(() => {
        lsClear()

        // commit(MUTATION_TYPES.LOGOUT_SUCCESS)

        // Must reload page to get new csrf token
        // because Laravel changes it after logout
        window.location.href = '/'

        setTimeout(() => {
          window.location.reload()
        }, 100)
      })
  },
  [ACTION_TYPES.FETCH_PACKAGE_SUBSCRIPTION] ({ commit }) {
    const promise = getSubscription()
      .then(response => {
        commit(MUTATION_TYPES.FETCH_PACKAGE_SUBSCRIPTION_SUCCESS, response)
      })

    commit(MUTATION_TYPES.SET_PACKAGE_SUBSCRIPTION_PROMISE, promise)
    return promise
  },
  [ACTION_TYPES.FETCH_BILLING_INFO] ({ commit }) {
    return getBillingInfo()
      .then(response => {
        commit(MUTATION_TYPES.FETCH_BILLING_INFO_SUCCESS, response)
      })
      .catch(e => {
        console.log(e)
      })
  },
}

export const getters = {
  isLoggedIn (state) {
    return state.profile.id
  },
  subscriptionInfo (state) {
    return state.profile.subscriptionInfo
  },
  hasAcceptedTerms (state) {
    return state.profile.accepted_terms
  },
  // Amount of unread notifications
  unreadNotificationsCount (state) {
    return state.profile.unreadNotificationsCount
  },
  // Has or can create/claim actor on this ecosystem
  // This getter can be used to determine if the user is an ecosystem member
  isActor (state, getters) {
    return getters.userRole === 'actor'
  },
  // Portfolio member
  isPortfolioMember (state, getters) {
    return getters.userRole === 'portfolio_member'
  },
  // Member of the team
  isMember (state, getters) {
    return getters.userRole === 'member' || getters.userRole === 'owner'
  },
  // TeamMember
  isTeamMember (state, getters) {
    return getters.userRole === 'member'
  },
  // Member of the team with access to configuration
  isOwner (state, getters) {
    return getters.userRole === 'owner'
  },
  // DataScouts developer
  isDeveloper (state, getters) {
    return state.profile && state.profile.isDeveloper && getters.userRole === 'owner'
  },
  // DataScouts user
  isUserOfDataScoutsTeam (state) {
    return state.profile && state.profile.email && state.profile.email.endsWith('@datascouts.eu')
  },
  // Can see spotting area features
  canSeeSpottingAreaMinervaFeatures (state, getters) {
    return state.profile && state.profile.email && state.profile.email.endsWith('@datascouts.eu') && ['member', 'owner'].includes(getters.userRole)
  },
  // roleIsVerified can be null, false or true
  roleIsUnverified (state) {
    return state.profile.roleIsVerified === false
  },
  userId (state) {
    return state.profile.id
  },
  userName (state) {
    return state.profile.name
  },
  userClaims (state) {
    return state.profile.claims
  },
  userEmail (state) {
    return state.profile.email
  },
  userRole (state) {
    const team = state.team && state.team.id && state.profile && state.profile.teams && state.profile.teams.find(t => t.id === state.team.id)
    return team && team.role
  },
  joinedCommunityIds (state) {
    return state.profile.joinedCommunityIds || []
  },
  requestedToJoinCommunityIds (state) {
    return state.profile.requestedToJoinCommunityIds || []
  },
  userContributionPortfolios (state) {
    if (state.profile && state.profile.contributePortfolios) {
      return state.profile.contributePortfolios
    }
    return []
  },
  userExplorePortfolios (state) {
    if (state.profile && state.profile.explorePortfolios) {
      return state.profile.explorePortfolios
    }
    return []
  },
  // Return the portfolios where the user ONLY has benchmark access to
  userBenchmarkPortfolios (state) {
    if (state.profile && state.profile.benchmarkPortfolios) {
      return state.profile.benchmarkPortfolios
    }
    return []
  },
  userSettings (state) {
    return state.profile.settings || {}
  },
  claimableActorTypes (state) {
    return state.profile.claimableActorTypes || []
  },
  canClaim (state, getters) {
    return getters.claimableActorTypes.length
  },
  userClaimedIds (state) {
    return (state.profile && state.profile.claimedIds) || []
  },
  hasAcceptedCookies (state) {
    if (ls('userAcceptedCookies')) {
      return ls('userAcceptedCookies')
    }

    return state.profile && state.profile.accepted_cookies
  },
  hasAccessToAllKnowledgeBaseContent (state, getters) {
    if (getters.isActor || !getters.isLoggedIn) {
      return getters.canViewAllKnowledgeBaseContentTypes
    }

    return true
  },
  userCanCreate (state, getters, rootState) {
    // Actors can add their actor, but only certain actor types
    if (getters.isActor) {
      return getters.canClaim
    }

    // Team members can always create actors
    if (getters.isMember) {
      return true
    }

    if (getters.isPortfolioMember && getters.userContributionPortfolios.length > 0) {
      return getters.isPortfolioMember
    }
  },
  ownedTeams (state, getters) {
    if (!state.profile || !state.profile.teams) {
      return
    }

    return state.profile.teams.filter(t => t.role === 'owner')
  },
  onlySeesCuratedContent (state, getters) {
    return getters.activeMonitoringContentMode === 'curated'
  },
}

export default {
  state: {
    team: {
      id: window.config && window.config.id,
    },
    profile: window.user || {
      settings: {},
    },
    isFetchingPackageSubscription: false,
    hasFetchedPackageSubscription: false,
    fetchPackageSubscriptionPromise: null,
    billingInfo: {
      billing_address: '',
      billing_address_line_2: '',
      billing_city: '',
      billing_zip: '',
      billing_country: 'BE',
      vat_id: '',
      company_name: '',
      phone: '',
    },
  },
  mutations,
  actions,
  getters,
}
