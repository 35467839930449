import { actorCount, claimActor, fetchActor, fetchActorsGraph, fetchActorsList, fetchActorsMap, newActorCount, updateActor, fetchProductsList, fetchProductsMap } from '../../api/actors.js'
import { MUTATION_TYPES as USER_MUTATION_TYPES } from './user-helpers.js'

import throttle from '../../util/throttle.js'
import { inert, isIE11, assertArray } from '../../util/helpers.js'
import Raven from 'raven-js'

const state = {
  previewedActor: {},
  previewedActorFetched: null,
  mapData: {
    loading: false,
    data: {
      companies: [],
      clusters: [],
      total: 0,
    },
    error: null,
  },
  listData: {
    count: null,
    loading: false,
    data: [],
    paging: {},
    total: 0,
    error: null,
  },
  newActorCount: 0, // Contains the count of new actors (excluding product actor type) since last viewed /actors or /actors-simplified
  productData: {
    count: null,
    loading: false,
    data: [],
    paging: {},
    total: 0,
    error: null,
  },
  newProductCount: 0, // Contains the count of product actors since lasted view /product-gallery or /products-simplified
  detail: {
    data: window.initialDetail || {},
    loading: false,
    error: null,
  },
  claimData: {
    loading: false,
    data: null,
    error: null,
  },
  graphData: {
    loading: false,
    data: [],
    error: null,
  },
  latestCompetitors: {
    loading: false,
    data: [],
    error: null,
  },
  portfolioActor: {
    data: {},
    loading: false,
    error: null,
  },
}

export const MUTATION_TYPES = {
  FLUSH_CACHE: 'ACTORS/FLUSH_CACHE',

  // Map
  FETCH_ACTORS_MAP_PENDING: 'ACTORS/FETCH_ACTORS_MAP_PENDING',
  FETCH_ACTORS_MAP_SUCCESS: 'ACTORS/FETCH_ACTORS_MAP_SUCCESS',
  FETCH_ACTORS_MAP_FAILURE: 'ACTORS/FETCH_ACTORS_MAP_FAILURE',

  // Actors List
  FETCH_NEW_ACTORS_COUNT_RESULT: 'ACTORS/FETCH_NEW_ACTORS_COUNT_RESULT',
  FETCH_ACTORS_COUNT_RESULT: 'ACTORS/FETCH_ACTORS_COUNT_RESULT',
  FETCH_ACTORS_LIST_PENDING: 'ACTORS/FETCH_ACTORS_LIST_PENDING',
  FETCH_ACTORS_LIST_SUCCESS: 'ACTORS/FETCH_ACTORS_LIST_SUCCESS',
  FETCH_MORE_ACTORS_LIST_SUCCESS: 'ACTORS/FETCH_MORE_ACTORS_LIST_SUCCESS',
  FETCH_ACTORS_LIST_FAILURE: 'ACTORS/FETCH_ACTORS_LIST_FAILURE',

  // Products
  FETCH_NEW_PRODUCTS_COUNT_RESULT: 'ACTORS/FETCH_NEW_PRODUCTS_COUNT_RESULT',
  FETCH_PRODUCTS_COUNT_RESULT: 'ACTORS/FETCH_PRODUCTS_COUNT_RESULT',
  FETCH_PRODUCTS_LIST_PENDING: 'ACTORS/FETCH_PRODUCTS_LIST_PENDING',
  FETCH_PRODUCTS_LIST_SUCCESS: 'ACTORS/FETCH_PRODUCTS_LIST_SUCCESS',
  FETCH_MORE_PRODUCTS_LIST_SUCCESS: 'ACTORS/FETCH_MORE_PRODUCTS_LIST_SUCCESS',
  FETCH_PRODUCTS_LIST_FAILURE: 'ACTORS/FETCH_PRODUCTS_LIST_FAILURE',

  // Graph
  FETCH_ACTOR_GRAPH_PENDING: 'ACTORS/FETCH_ACTOR_GRAPH_PENDING',
  FETCH_ACTOR_GRAPH_SUCCESS: 'ACTORS/FETCH_ACTOR_GRAPH_SUCCESS',
  FETCH_ACTOR_GRAPH_FAILURE: 'ACTORS/FETCH_ACTOR_GRAPH_FAILURE',

  // Actor preview
  SET_ACTOR_FOR_PREVIEW: 'ACTORS/SET_ACTOR_FOR_PREVIEW',
  SET_FETCHED_ACTOR_FOR_PREVIEW: 'ACTORS/SET_FETCHED_ACTOR_FOR_PREVIEW',

  // Claim
  CLAIM_ACTOR_PENDING: 'ACTORS/CLAIM_ACTOR_PENDING',
  CLAIM_ACTOR_SUCCESS: 'ACTORS/CLAIM_ACTOR_SUCCESS',
  CLAIM_ACTOR_FAILURE: 'ACTORS/CLAIM_ACTOR_FAILURE',
  CLAIM_ACTOR_RESET: 'ACTORS/CLAIM_ACTOR_RESET',

  // Actor detail
  FETCH_ACTOR_DETAIL_PENDING: 'ACTORS/FETCH_ACTOR_DETAIL_PENDING',
  FETCH_ACTOR_DETAIL_SUCCESS: 'ACTORS/FETCH_ACTOR_DETAIL_SUCCESS',
  FETCH_ACTOR_DETAIL_FAILURE: 'ACTORS/FETCH_ACTOR_DETAIL_FAILURE',

  // Portfolio actor
  FETCH_PORTFOLIO_ACTOR_PENDING: 'ACTORS/FETCH_PORTFOLIO_ACTOR_PENDING',
  FETCH_PORTFOLIO_ACTOR_SUCCESS: 'ACTORS/FETCH_PORTFOLIO_ACTOR_SUCCESS',
  FETCH_PORTFOLIO_ACTOR_FAILURE: 'ACTORS/FETCH_PORTFOLIO_ACTOR_FAILURE',

  // Update
  UPDATE_ACTOR_SUCCESS: 'ACTORS/UPDATE_ACTOR_SUCCESS',
}

export const ACTION_TYPES = {
  FETCH_ACTORS_MAP: 'ACTORS/FETCH_ACTORS_MAP',
  FETCH_PRODUCTS_MAP: 'ACTORS/FETCH_PRODUCTS_MAP',
  FETCH_ACTORS_GRAPH: 'ACTORS/FETCH_ACTORS_GRAPH',
  FETCH_NEW_ACTORS_COUNT: 'ACTORS/FETCH_NEW_ACTORS_COUNT',
  FETCH_ACTORS_COUNT: 'ACTORS/FETCH_ACTORS_COUNT',
  FETCH_ACTORS_LIST: 'ACTORS/FETCH_ACTORS_LIST',
  FETCH_MORE_ACTORS_LIST: 'ACTORS/FETCH_MORE_ACTORS_LIST',
  FETCH_NEW_PRODUCTS_COUNT: 'ACTORS/FETCH_NEW_PRODUCTS_COUNT',
  FETCH_PRODUCTS_COUNT: 'ACTORS/FETCH_PRODUCTS_COUNT',
  FETCH_PRODUCTS_LIST: 'ACTORS/FETCH_PRODUCTS_LIST',
  FETCH_MORE_PRODUCTS_LIST: 'ACTORS/FETCH_MORE_PRODUCTS_LIST',
  FETCH_ACTORS_LIST_NOW: 'ACTORS/FETCH_ACTORS_LIST_NOW',
  FETCH_MORE_ACTORS_LIST_NOW: 'ACTORS/FETCH_MORE_ACTORS_LIST_NOW',
  FETCH_PRODUCTS_LIST_NOW: 'ACTORS/FETCH_PRODUCTS_LIST_NOW',
  FETCH_MORE_PRODUCTS_LIST_NOW: 'ACTORS/FETCH_MORE_PRODUCTS_LIST_NOW',
  FETCH_ACTOR_FOR_PREVIEW: 'ACTORS/FETCH_ACTOR_FOR_PREVIEW',
  FETCH_ACTOR_DETAIL: 'ACTORS/FETCH_ACTOR_DETAIL',
  FETCH_PRODUCT_DETAIL: 'ACTORS/FETCH_PRODUCT_DETAIL',
  FETCH_PORTFOLIO_ACTOR: 'ACTORS/FETCH_PORTFOLIO_ACTOR',
  CLAIM_ACTOR: 'ACTORS/CLAIM_ACTOR',
  CLAIM_ACTOR_RESET: 'ACTORS/CLAIM_ACTOR_RESET',
  UPDATE_ACTOR: 'ACTORS/UPDATE_ACTOR',
}

export const mutations = {
  [MUTATION_TYPES.FLUSH_CACHE] (state) {
    state.previewedActor = {}
    state.previewedActorFetched = null
    state.mapData = {
      loading: false,
      data: {
        companies: [],
        clusters: [],
        total: 0,
      },
      error: null,
    }
    state.listData = {
      loading: false,
      data: [],
      paging: {},
      total: 0,
      error: null,
    }
    state.productData = {
      loading: false,
      data: [],
      paging: {},
      total: 0,
      error: null,
    }
    state.detail = {
      data: {},
      loading: false,
      error: null,
    }
    state.graphData = {
      loading: false,
      data: [],
      error: null,
    }
  },

  // Map
  [MUTATION_TYPES.FETCH_ACTORS_MAP_PENDING] (state) {
    state.mapData.loading = true
  },
  [MUTATION_TYPES.FETCH_ACTORS_MAP_SUCCESS] (state, data) {
    state.mapData.data = data
    state.mapData.loading = false
  },

  // List
  [MUTATION_TYPES.FETCH_NEW_ACTORS_COUNT_RESULT] (state, data) {
    state.newActorCount = data.count
  },
  [MUTATION_TYPES.FETCH_ACTORS_COUNT_RESULT] (state, data) {
    state.listData.count = data.count
  },
  [MUTATION_TYPES.FETCH_ACTORS_LIST_PENDING] (state) {
    state.listData.loading = true
  },
  [MUTATION_TYPES.FETCH_MORE_ACTORS_LIST_SUCCESS] (state, { data, paging, total }) {
    state.listData.data = [...state.listData.data, ...assertArray(data)]
    state.listData.paging = paging || {}
    state.listData.total = total || 0
    state.listData.loading = false
  },
  [MUTATION_TYPES.FETCH_ACTORS_LIST_SUCCESS] (state, { data, paging, total }) {
    state.listData.data = assertArray(data)
    state.listData.paging = paging || {}
    state.listData.total = total || 0
    state.listData.loading = false
    state.listData.error = null
  },
  [MUTATION_TYPES.FETCH_ACTORS_LIST_FAILURE] (state, error) {
    state.listData.data = []
    state.listData.error = error
    state.listData.loading = false
    state.listData.paging = {}
    state.listData.total = 0
  },

  // Product
  [MUTATION_TYPES.FETCH_NEW_PRODUCTS_COUNT_RESULT] (state, data) {
    state.newProductCount = data.count
  },
  [MUTATION_TYPES.FETCH_PRODUCTS_COUNT_RESULT] (state, data) {
    state.productData.count = data.count
  },
  [MUTATION_TYPES.FETCH_PRODUCTS_LIST_PENDING] (state) {
    state.productData.loading = true
  },
  [MUTATION_TYPES.FETCH_MORE_PRODUCTS_LIST_SUCCESS] (state, { data, paging, total }) {
    state.productData.data = [...state.productData.data, ...assertArray(data)]
    state.productData.paging = paging || {}
    state.productData.total = total || 0
    state.productData.loading = false
  },
  [MUTATION_TYPES.FETCH_PRODUCTS_LIST_SUCCESS] (state, { data, paging, total }) {
    state.productData.data = assertArray(data)
    state.productData.paging = paging || {}
    state.productData.total = total || 0
    state.productData.loading = false
  },
  [MUTATION_TYPES.FETCH_PRODUCTS_LIST_FAILURE] (state, error) {
    state.productData.error = error
    state.productData.loading = false
  },

  // Actor preview
  [MUTATION_TYPES.SET_ACTOR_FOR_PREVIEW] (state, actor) {
    state.previewedActor = actor
    state.previewedActorFetched = null
  },

  // Actor preview fetched from API
  [MUTATION_TYPES.SET_FETCHED_ACTOR_FOR_PREVIEW] (state, actor) {
    if (state.previewedActor.id === actor.id) {
      state.previewedActorFetched = actor
    } else {
      // This should only occur when quickly previewing multiple actors
      Raven.setExtraContext({
        previewed: state.previewedActor,
        fetched: state.previewedActorFetched,
      })
      Raven.captureMessage('Tried to preview one actor, but another was fetched.')
    }
  },

  // Claim
  [MUTATION_TYPES.CLAIM_ACTOR_PENDING] (state) {
    state.claimData.data = {}
    state.claimData.loading = true
  },
  [MUTATION_TYPES.CLAIM_ACTOR_SUCCESS] (state, claimData) {
    state.claimData.data = claimData
    state.claimData.loading = false
  },
  [MUTATION_TYPES.CLAIM_ACTOR_RESET] (state) {
    state.claimData.data = null
    state.claimData.loading = false
    state.claimData.error = null
  },

  // Actor detail
  [MUTATION_TYPES.FETCH_ACTOR_DETAIL_PENDING] (state, id) {
    state.detail.loading = id
  },
  [MUTATION_TYPES.FETCH_ACTOR_DETAIL_SUCCESS] (state, actor) {
    // Check if another detail page was requested in the meantime
    if (state.detail.loading === actor.id) {
      state.detail.data = actor
    }

    state.detail.error = null
    state.detail.loading = false
  },
  [MUTATION_TYPES.FETCH_ACTOR_DETAIL_FAILURE] (state, error) {
    state.detail.data = {}
    state.detail.errorId = state.detail.loading
    state.detail.error = error || 'Failed to fetch actor.'
    state.detail.loading = false
  },

  // Actor graph
  [MUTATION_TYPES.FETCH_ACTOR_GRAPH_PENDING] (state, id) {
    state.graphData.loading = true
  },
  [MUTATION_TYPES.FETCH_ACTOR_GRAPH_SUCCESS] (state, data) {
    state.graphData.data = assertArray(data)
    state.graphData.loading = false
  },
  [MUTATION_TYPES.FETCH_ACTOR_GRAPH_FAILURE] (state) {
    state.graphData.loading = false
  },

  // Updated actor
  [MUTATION_TYPES.UPDATE_ACTOR_SUCCESS] (state, actor) {
    state.detail.data = actor
  },

  // Portfolio actor
  [MUTATION_TYPES.FETCH_PORTFOLIO_ACTOR_PENDING] (state) {
    state.portfolioActor.loading = true
  },
  [MUTATION_TYPES.FETCH_PORTFOLIO_ACTOR_SUCCESS] (state, actor) {
    state.portfolioActor.data = actor
    state.portfolioActor.error = null
    state.portfolioActor.loading = false
  },
  [MUTATION_TYPES.FETCH_PORTFOLIO_ACTOR_FAILURE] (state, error) {
    state.portfolioActor.data = {}
    state.portfolioActor.errorId = state.portfolioActor.loading
    state.portfolioActor.error = error || 'Failed to fetch portfolio actor.'
    state.portfolioActor.loading = false
  },
}

export function mapDataTransformer ({ id, name, location, legend, category }) {
  return { id, name, location, legend, category }
}

export const actions = {
  // Map
  [ACTION_TYPES.FETCH_ACTORS_MAP] ({ commit }, filters) {
    commit(MUTATION_TYPES.FETCH_ACTORS_MAP_PENDING)
    actions.fetchMap(filters, false, commit)
  },
  [ACTION_TYPES.FETCH_PRODUCTS_MAP] ({ commit }, filters) {
    commit(MUTATION_TYPES.FETCH_ACTORS_MAP_PENDING)
    actions.fetchMap(filters, true, commit)
  },
  fetchMap: throttle((filters, isProductMap, commit) => {
    // If IE11 is being used, add a special flag
    var filtersClone = filters

    if (isIE11()) {
      filtersClone.isIE11 = true
    }

    const fetchFunction = isProductMap ? fetchProductsMap : fetchActorsMap

    fetchFunction(filters).then((mapData) => {
      mapData.companies = mapData.companies && mapData.companies.map(mapDataTransformer)
      commit(MUTATION_TYPES.FETCH_ACTORS_MAP_SUCCESS, mapData)
    }).catch(() => { /* Ignore errors */
    })
  }),

  // List
  [ACTION_TYPES.FETCH_NEW_ACTORS_COUNT] ({ commit }) {
    return newActorCount({}).then((response) => {
      commit(MUTATION_TYPES.FETCH_NEW_ACTORS_COUNT_RESULT, response)
    })
  },
  [ACTION_TYPES.FETCH_ACTORS_COUNT] ({ commit }, filters) {
    return actorCount(filters).then((response) => {
      commit(MUTATION_TYPES.FETCH_ACTORS_COUNT_RESULT, response)
    })
  },
  [ACTION_TYPES.FETCH_ACTORS_LIST]: throttle(({ dispatch }, filters) => {
    dispatch(ACTION_TYPES.FETCH_ACTORS_LIST_NOW, filters)
  }),
  [ACTION_TYPES.FETCH_MORE_ACTORS_LIST]: throttle(({ dispatch }, filters) => {
    dispatch(ACTION_TYPES.FETCH_MORE_ACTORS_LIST_NOW, filters)
  }),
  [ACTION_TYPES.FETCH_ACTORS_LIST_NOW] ({ commit }, filters) {
    commit(MUTATION_TYPES.FETCH_ACTORS_LIST_PENDING)

    return fetchActorsList(filters)
      .then(response => {
        commit(MUTATION_TYPES.FETCH_ACTORS_LIST_SUCCESS, response)
      })
      .catch(errors => {
        commit(MUTATION_TYPES.FETCH_ACTORS_LIST_FAILURE, errors)
      })
  },
  [ACTION_TYPES.FETCH_MORE_ACTORS_LIST_NOW] ({ commit }, filters) {
    commit(MUTATION_TYPES.FETCH_ACTORS_LIST_PENDING)

    return fetchActorsList(filters)
      .then(response => {
        commit(MUTATION_TYPES.FETCH_MORE_ACTORS_LIST_SUCCESS, response)
      })
      .catch(errors => {
        commit(MUTATION_TYPES.FETCH_ACTORS_LIST_FAILURE, errors)
      })
  },

  [ACTION_TYPES.FETCH_NEW_PRODUCTS_COUNT] ({ commit }) {
    return newActorCount({ actor_type: 'Product' }).then((response) => {
      commit(MUTATION_TYPES.FETCH_NEW_PRODUCTS_COUNT_RESULT, response)
    })
  },
  [ACTION_TYPES.FETCH_PRODUCTS_COUNT] ({ commit }, filters) {
    return actorCount(filters).then((response) => {
      commit(MUTATION_TYPES.FETCH_PRODUCTS_COUNT_RESULT, response)
    })
  },
  [ACTION_TYPES.FETCH_PRODUCTS_LIST]: throttle(({ dispatch }, filters) => {
    dispatch(ACTION_TYPES.FETCH_PRODUCTS_LIST_NOW, filters)
  }),
  [ACTION_TYPES.FETCH_MORE_PRODUCTS_LIST]: throttle(({ dispatch }, filters) => {
    dispatch(ACTION_TYPES.FETCH_MORE_PRODUCTS_LIST_NOW, filters)
  }),
  [ACTION_TYPES.FETCH_PRODUCTS_LIST_NOW] ({ commit }, filters) {
    commit(MUTATION_TYPES.FETCH_PRODUCTS_LIST_PENDING)

    return fetchProductsList(Object.assign({}, filters, { actor_type: 'Product' }))
      .then(response => {
        commit(MUTATION_TYPES.FETCH_PRODUCTS_LIST_SUCCESS, response)
      })
      .catch(errors => {
        commit(MUTATION_TYPES.FETCH_PRODUCTS_LIST_FAILURE, errors)
      })
  },
  [ACTION_TYPES.FETCH_MORE_PRODUCTS_LIST_NOW] ({ commit }, filters) {
    commit(MUTATION_TYPES.FETCH_PRODUCTS_LIST_PENDING)

    return fetchActorsList(Object.assign({}, filters, { actor_type: 'Product' }))
      .then(response => {
        commit(MUTATION_TYPES.FETCH_MORE_PRODUCTS_LIST_SUCCESS, response)
      })
      .catch(errors => {
        commit(MUTATION_TYPES.FETCH_PRODUCTS_LIST_FAILURE, errors)
      })
  },

  // Preview
  [ACTION_TYPES.FETCH_ACTOR_FOR_PREVIEW] ({ commit }, actor) {
    commit(MUTATION_TYPES.SET_ACTOR_FOR_PREVIEW, actor)

    return fetchActor(actor.id)
      .then(actor => {
        commit(MUTATION_TYPES.SET_FETCHED_ACTOR_FOR_PREVIEW, actor)
        return actor
      })
  },

  // Detail
  [ACTION_TYPES.FETCH_ACTOR_DETAIL] ({ commit }, id) {
    // Already got it
    if (state.detail.data.id === id) {
      // return console.log('already got detail', id)
    }

    // Deprecated: the list data doesn't contain all of the exact data we need at times, so make sure to fetch a fresh actor profile
    // Try to reuse list data
    const cachedActor = state.listData.data.find(a => a.id === id)
    if (cachedActor) {
      commit(MUTATION_TYPES.FETCH_ACTOR_DETAIL_PENDING, id)
      commit(MUTATION_TYPES.FETCH_ACTOR_DETAIL_SUCCESS, cachedActor)
      // Don't return here because we want to make sure the data is up-to-date by calling fetchActor although the data is cached
    }

    // Already loading
    if (state.detail.loading === id) {
      console.log('already loading actor detail', id)
      return
    }

    if (state.detail.errorId === id) {
      console.log('already tried loading actor detail', id)
      return
    }

    if (id === true) {
      id = state.detail.data.id
    }

    commit(MUTATION_TYPES.FETCH_ACTOR_DETAIL_PENDING, id)

    return fetchActor(id)
      .then(actor => {
        commit(MUTATION_TYPES.FETCH_ACTOR_DETAIL_SUCCESS, actor)
      })
      .catch(errors => {
        commit(MUTATION_TYPES.FETCH_ACTOR_DETAIL_FAILURE, 'An error occurred while fetching the actor.')
      })
  },

  // Product Detail
  [ACTION_TYPES.FETCH_PRODUCT_DETAIL] ({ commit }, id) {
    // Already got it
    if (state.detail.data.id === id) {
      // return console.log('already got detail', id)
    }

    // Try to reuse list data
    const cachedActor = state.productData.data.find(a => a.id === id)
    if (cachedActor) {
      commit(MUTATION_TYPES.FETCH_ACTOR_DETAIL_PENDING, id)
      commit(MUTATION_TYPES.FETCH_ACTOR_DETAIL_SUCCESS, cachedActor)
    }

    // Already loading
    if (state.detail.loading === id) {
      return console.log('already loading detail', id)
    }

    if (state.detail.errorId === id) {
      return console.log('already tried loading detail', id)
    }

    if (id === true) {
      id = state.detail.data.id
    }

    commit(MUTATION_TYPES.FETCH_ACTOR_DETAIL_PENDING, id)

    return fetchActor(id)
      .then(actor => {
        commit(MUTATION_TYPES.FETCH_ACTOR_DETAIL_SUCCESS, actor)
      })
      .catch(errors => {
        commit(MUTATION_TYPES.FETCH_ACTOR_DETAIL_FAILURE, 'An error occurred while fetching the actor.')
      })
  },

  // Claim
  [ACTION_TYPES.CLAIM_ACTOR] ({ commit }, actorId) {
    commit(MUTATION_TYPES.CLAIM_ACTOR_PENDING)

    return claimActor(actorId)
      .then(response => {
        commit(MUTATION_TYPES.CLAIM_ACTOR_SUCCESS, response)
        commit(USER_MUTATION_TYPES.HAS_CLAIMED, response)
      })
  },

  // Reset claim
  [ACTION_TYPES.CLAIM_ACTOR_RESET] ({ commit }) {
    commit(MUTATION_TYPES.CLAIM_ACTOR_RESET)
  },

  // Fetch actors graph
  [ACTION_TYPES.FETCH_ACTORS_GRAPH] ({ commit }, filters) {
    commit(MUTATION_TYPES.FETCH_ACTOR_GRAPH_PENDING)

    fetchActorsGraph(filters)
      .then(response => {
        commit(MUTATION_TYPES.FETCH_ACTOR_GRAPH_SUCCESS, response.data)
      }).catch(() => {
        commit(MUTATION_TYPES.FETCH_ACTOR_GRAPH_FAILURE)
        /* Ignore errors */
      })
  },

  // Update actor
  [ACTION_TYPES.UPDATE_ACTOR] ({ commit }, updateActorParam) {
    return updateActor(updateActorParam)
      .then(actor => {
        commit(MUTATION_TYPES.UPDATE_ACTOR_SUCCESS, actor)
      })
  },

  // Portfolio actor
  [ACTION_TYPES.FETCH_PORTFOLIO_ACTOR] ({ commit }, id) {
    if (!id) {
      // portfolioActor is not set in config
      return
    }
    if (state.portfolioActor.data.id === id) {
      return
    }
    if (state.portfolioActor.loading) {
      return
    }

    commit(MUTATION_TYPES.FETCH_PORTFOLIO_ACTOR_PENDING, id)

    fetchActor(id)
      .then(actor => {
        commit(MUTATION_TYPES.FETCH_PORTFOLIO_ACTOR_SUCCESS, actor)
      })
      .catch(errors => {
        commit(MUTATION_TYPES.FETCH_PORTFOLIO_ACTOR_FAILURE, 'An error occurred while fetching the actor.')
      })
  },
}

export const getters = {
  lastPageOffset (state) {
    // If there is no link to the last page, we are on the last page => prev + 1
    // If there is no link to the previous page either, the must be at most 1 page
    return (state.listData.paging.last && state.listData.paging.last.offset) ||
      (state.listData.paging.previous && (parseInt(state.listData.paging.previous.offset) + parseInt(state.listData.paging.previous.limit))) ||
      0
  },
  portfolioActor (state) {
    return state.portfolioActor.data || {}
  },
  previewedActor (state) {
    return state.previewedActorFetched || state.previewedActor || {}
  },
  detailActor (state) {
    return inert(state.detail.data || {})
  },
  totalActors () {
    return state.listData.total || state.mapData.total
  },
}

export default {
  state,
  mutations,
  actions,
  getters,
}
