<template>
  <div class="settings fixed-heading">
    <div class="heading">
      <h1>Invitations</h1>
      <p>Invite new members.</p>
    </div>
    <div class="has-padding scrollable">
      <users-invite />
      <br>
      <br>
      <users-invited />
    </div>
  </div>
</template>

<script>
import UsersInvite from '../Settings/UsersInvite.vue'
import UsersInvited from '../Settings/UsersInvited.vue'

export default {
  components: {
    UsersInvite,
    UsersInvited
  }
}
</script>
