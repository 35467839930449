<template>
  <modal :id="modalId"
    class="with-subtitle"
    ref="modal"
    title="You are inviting a new user"
    @close="close"
    is-simplified-modal
  >
    <template v-slot:title>
      <h2 class="title">
        You are inviting a new user
        <span
          class="mandatory-message">{{
            $t('all_fields_with_asterisk_are_mandatory')
          }}</span>
      </h2>
    </template>
    <template v-slot:body>
      <div>
        <div class="add-user__modal-content">
          <div class="row" v-if="emailStatus.error">
            <p class="form-group__success invitations-form__error col-xs-12"
              v-if="emailStatus.success.invalid_emails">
              <template v-for="(message, email) in emailStatus.success.invalid_emails">
                {{ email }}: {{ message }}<br/>
              </template>
            </p>
            <p class="form-group__info invitations-form__error col-xs-12"
              v-else-if="emailStatus.error">
              {{ emailStatus.error }}
            </p>
          </div>
          <div class="row">
            <form-group is-simplified label="Claims an actor (or contributes to when already claimed)"
              class="col-xs-12">
              <ds-input is-simplified v-model="actors.searchQuery" placeholder="Search for actors…"
                @update:modelValue="fetchActorsDebounced" class="limited-width"/>
              <div class="search-indicator" v-if="actors.searching">
                <icon name="spinner" style="vertical-align: middle;"/>
                Searching...
              </div>
              <div class="search-actor-list" v-else-if="user.actor.id">
                <form-group is-simplified label="Linked actor"
                  class="col-xs-12">
                  <actor-block
                    mode="remove"
                    :id="user.actor.id"
                    :name="user.actor.name"
                    :description="user.actor.description"
                    :url="user.actor.url"
                    @remove="denyActorRecommendation"
                    :allow-input="true"
                  />
                </form-group>
              </div>
              <div class="search-actor-list" v-else-if="actors.searchQuery">
                <actor-block
                  v-for="actor in notAddedActors"
                  :key="actor.id"
                  mode="add"
                  :id="actor.id"
                  :name="actor.name"
                  :description="actor.short_description"
                  :url="getActorLink(actor)"
                  @add="addActor"
                  :allow-input="true"
                />
              </div>
            </form-group>
            <form-group is-simplified label="Name*" :errors="errors.name"
              class="col-xs-12">
              <ds-input is-simplified v-model="user.name"/>
            </form-group>
            <form-group is-simplified label="Work email address*" :errors="errors.email"
              class="col-xs-12">
              <ds-input is-simplified v-model="user.email"/>
            </form-group>
            <form-group is-simplified label="Department" :errors="errors.department"
              :required="!departmentFunctionFieldsOptional"
              class="col-xs-12 col-sm-6">
              <dropdown is-simplified v-model="user.department"
                :options="departmentOptions"/>
            </form-group>
            <form-group is-simplified label="Experience level" :errors="errors.function"
              :required="!departmentFunctionFieldsOptional"
              class="col-xs-12 col-sm-6">
              <dropdown is-simplified v-model="user.function"
                :options="functionOptions"/>
            </form-group>
            <form-group is-simplified label="Personal Message"
              :errors="errors.invitationText"
              class="col-xs-12">
              <ds-textarea v-model="user.invitationText" is-simplified/>
            </form-group>
          </div>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div style="width: 100%;">
        <ds-button
          variant="rounded"
          @click="submit"
          v-if="!emailStatus.success && !emailStatus.error"
          label="Invite User"
          size="extra-small"
          :disabled="!canSubmit"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import ActorBlock from './../Dashboard/EmailReports/ActorBlock.vue'
  import Modal from './Modal.vue'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import DsTextarea from '../Form/DsTextarea.vue'
  import Dropdown from '../Dropdown/Dropdown.vue'
  import { asyncConfig, sendInvitation } from '../../api/config.js'
  import FiltersMixin from '../../util/FiltersMixin.js'
  import { getMainActorTypeActorSuggestions } from '../../api/general-search'
  import debounce from 'lodash/debounce'
  import { mapNewGlobalSearchResultToLegacyFormat } from '../../util/helpers'

  export default {
    data () {
      return {
        modalId: MODAL_IDS.MANAGE_USER,
        errors: {},
        emailStatus: {
          success: '',
          error: null,
          busy: false,
        },
        actors: {
          searchQuery: '',
          lastUsedSearchQuery: '',
          searching: false,
          results: [],
        },
        user: {
          actor: {},
          name: '',
          email: '',
          department: '',
          function: '',
          role: 'actor',
        },
        sending: false,
        invitationText: '',
      }
    },
    computed: {
      departmentFunctionFieldsOptional () {
        return !!this.$store.state.config.departmentFunctionFieldsOptional
      },
      notAddedActors () {
        return this.actors.results
      },
      roleOptions () {
        if (this.$store.getters.hasAccessToExploration) {
          return [{ label: 'Owner', value: 'owner' }]
        }
        const result = [
          {
            label: 'Owner',
            value: 'owner',
          },
          {
            label: 'Team Member',
            value: 'member',
          },
        ]
        if (this.canUserInvitePortfolioMember) {
          result.push({
            label: 'Portfolio Member',
            value: 'portfolio_member',
          })
        }
        if (this.canAddEcosystemMembers) {
          result.push({
            label: 'Ecosystem Member',
            value: 'actor',
          })
        }
        return result
      },
      canAddEcosystemMembers () {
        return this.$store.getters.publisher
      },
      canSubmit () {
        if (!this.departmentFunctionFieldsOptional &&
          (!this.user.department || !this.user.function)
        ) {
          return false
        }

        return (this.user.name.trim().length > 0) &&
          (this.user.email.trim().length > 0) &&
          this.isEmailValid
      },
      isEmailValid () {
        var regexValiation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        return this.user.email.trim() && regexValiation.test(this.user.email.trim())
      },
      canUserInvitePortfolioMember () {
        const availableRoleNames = this.allowedRoles.map(role => role.value)

        return availableRoleNames.includes('portfolio_member')
      },
    },
    methods: {
      getActorLink,
      fetchActors () {
        if (this.actors.lastUsedSearchQuery === this.actors.searchQuery || this.actors.searchQuery.length <= 2) {
          return
        }

        this.actors.lastUsedSearchQuery = this.actors.searchQuery
        this.actors.searching = true

        getMainActorTypeActorSuggestions(this.actors.searchQuery)
          .then(result => {
            const combinedActorsWithoutDuplicates = mapNewGlobalSearchResultToLegacyFormat([...result.actors, ...result.products])

            this.actors.results = combinedActorsWithoutDuplicates.slice(0, 10)
            this.actors.searching = false
          })
          .catch(error => {
            this.actors.searching = false
            throw error
          })
      },
      fetchActorsDebounced: debounce(function () {
        this.fetchActors()
      }, 500),
      addActor (id) {
        const actor = this.actors.results.find(a => a.id === id)
        if (actor === undefined) return

        this.user.actor = {
          id: actor.id,
          name: actor.name,
          description: actor.short_description,
          url: getActorLink(actor),
          date: actor.date,
        }
      },
      denyActorRecommendation () {
        this.user.actor = {}
      },
      submit () {
        const data = {}
        data.name = this.user.name
        data.linkedActorId = this.user.actor.id
        data.emails = [this.user.email.trim()]
        data.role = this.user.role
        data.invitationText = this.user.invitationText
        data.function = this.user.function
        data.department = this.user.department

        this.sending = true

        sendInvitation(data)
          .then(response => {
            if (response.success && response.invalid_emails.length === 0) {
              this.errors = []
              this.emailStatus.error = null
              this.emailStatus.success = response
            } else {
              this.errors.email = response.invalid_emails
              this.emailStatus.success = false
            }
          })
          .catch(response => {
            if (response.statusCode == 422) {
              this.errors = response
              this.emailStatus.success = false
              this.emailStatus.error = response.message
            } else {
              this.errors = []
              this.emailStatus.error = null
              this.emailStatus.success = response
            }
          })
          .finally(() => {
            if (Object.keys(this.errors).length === 0) {
              setTimeout(() => {
                this.success()
              }, 500)
            }
          })
      },
      success () {
        this.sending = false

        this.$bus.emit('invitationsAdded')

        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          message: this.user.name + ' has been invited successfully',
        })

        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.SUCCESS)
      },
      close () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.MANAGE_USER)
        this.$emit('close')
      },
    },
    mounted () {
      if (this.$store.state.ui.modalContext && this.$store.state.ui.modalContext.user) {
        this.user = this.$store.state.ui.modalContext.user
      }
    },
    mixins: [FiltersMixin, asyncConfig],
    components: {
      ActorBlock,
      Modal,
      Dropdown,
      DsTextarea,
    },
  }

  function getActorLink (actor) {
    return window.location.origin + '/actors/' + actor.id
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/_variables.scss";

  .add-user__title-container {
    background: var(--primary-extra-lightest);
    padding: 20px;
  }

  .add-user__modal-content {
    padding: 20px;

    :deep(input) {
      &::placeholder {
        color: var(--primary);
      }
    }

    .search-actor-list {
      margin-top: 10px;
    }
  }

  .modal__footer {
    width: 100%;

    .button {
      width: 100%;
      border-radius: $default-border-radius-narrow;
    }
  }

  .form-group:last-child {
    margin-bottom: 0;
  }
</style>
