<template>
  <div class="graph-view-connections">
    <NewCard title="Legend" collapsable class="legend_dropdown__legend-container"
             :isCollapsedOnInit="isCollapsed" @isCollapsed="changeCollapseState">
      <div class="connections-body">
        <template v-for="(relationType, index) of availableConnectionTypes" :key="index">
          <div v-if="hasConnectionTypes(relationType.name)">
            <div class="graph-view-connection-type graph-view-connection-type__title">
            <span>
              {{ relationType.label }}
            </span>
              <a class="toggle-eye" href="#"
                 @click="clickToggleAll($event, relationType)">
                <icon
                  :name="areAllHidden(relationType) ? 'eye' : 'eye-blocked'"
                />
              </a>
            </div>
            <template v-for="connectionType in connectionTypes" :key="connectionType.id">
              <div class="graph-view-connection-type" v-if="hasConnectionType(relationType.name, connectionType)">
                <div class="legend-line"
                     :style="{ backgroundColor: getBackgroundColor(connectionType.type) }"/>
                {{ connectionType.label }} ({{ connectionType.connections.length }})
                <a class="toggle-eye" href="#"
                   @click="clickToggle($event, connectionType.type)">
                  <icon
                    :name="isHidden(connectionType.type) ? 'eye-blocked' : 'eye'"
                  />
                </a>
              </div>
            </template>
          </div>
        </template>
      </div>
    </NewCard>
  </div>
</template>

<script>
  import NewCard from '../../components/NewCard/NewCard.vue'
  import FiltersMixin from '../../util/FiltersMixin'
  import { trackHeapEvent } from '../../util/analytics'

  export default {
    components: {
      NewCard,
    },
    data () {
      return {
        isCollapsed: false,
        availableConnectionTypes: [
          { name: 'data', label: 'Data' }, { name: 'business', label: 'Business' },
        ],
      }
    },
    props: {
      actor: Object,
      connectionTypes: Array,
      hiddenTypes: Array,
      remove: Function,
    },
    computed: {
      ecosystemMetaDataLists () {
        return this.$store.getters.relationshipMetaDataLists
      },
      hexColors () {
        return Object.values(this.$store.state.config.hexColours)
      },
      ecosystemRelationships () {
        return this.$store.getters.fullActorRelationships
      },
      dataEcosystemRelationships () {
        return this.ecosystemRelationships.filter(relationship => relationship.type === 'data')
      },
      businessEcosystemRelationships () {
        return this.ecosystemRelationships.filter(relationship => relationship.type === 'business')
      },
    },
    methods: {
      hasConnectionTypes (type) {
        return this.connectionTypes.filter(connectionType => this.hasConnectionType(type, connectionType)).length
      },
      hasConnectionType (type, connection) {
        const relationships = this.getEcosystemRelationshipsOfType(type)
        return relationships.find(relationship => {
          if (relationship.name === 'sends' && connection.type.startsWith('sends')) {
            return true
          }
          return relationship.name === connection.type
        })
      },
      getEcosystemRelationshipsOfType (type) {
        if (type === 'data') {
          return this.dataEcosystemRelationships
        } else if (type === 'business') {
          return this.businessEcosystemRelationships
        }
      },
      changeCollapseState () {
        trackHeapEvent('actorDetail.graphView.expandLegend')
        this.isCollapsed = !this.isCollapsed
      },
      clickToggleAll (event, relationType) {
        trackHeapEvent('actorDetail.graphView.toggleLegendItem', { relationType: relationType.name })
        event.preventDefault()
        const value = !this.areAllHidden(relationType)

        if (relationType.name === 'data') {
          for (const connectionType of this.connectionTypes) {
            if (connectionType.label.startsWith('sends')) {
              this.$emit('setVisibility', connectionType.label, value)
            }
          }
        } else {
          for (const connectionType of this.getEcosystemRelationshipsOfType(relationType.name)) {
            if (this.connectionTypes.find(type => type.type === connectionType.name)) {
              this.$emit('setVisibility', connectionType.name, value)
            }
          }
        }
      },
      clickToggle (event, type) {
        trackHeapEvent('actorDetail.graphView.toggleLegendItem', { type })
        event.preventDefault()
        this.$emit('setVisibility', type, this.isHidden(type))
      },
      getBackgroundColor (connectionType) {
        if (connectionType.startsWith('sends') && this.ecosystemMetaDataLists[0]) {
          const result = this.ecosystemMetaDataLists[0].tagColours[connectionType.split('sends ')[1]]
          if (result) {
            return result
          }
        } else {
          const relationshipType = this.ecosystemRelationships.find(r => r.name === connectionType)
          const index = this.ecosystemRelationships.indexOf(relationshipType)

          if (index > -1 && this.hexColors[index]) {
            return this.hexColors[index]
          }
        }
        return '#bbb'
      },
      areAllHidden (relationType) {
        const types = this.getEcosystemRelationshipsOfType(relationType.name)
        if (relationType.name === 'data') {
          return !this.hiddenTypes.find(hiddenType => this.connectionTypes.find(type => type.label.startsWith('sends') && type.label === hiddenType))
        }
        return !this.hiddenTypes.find(hiddenType => types.find(type => type.name === hiddenType))
      },
      isHidden (connectionType) {
        return this.hiddenTypes.some(hiddenType => hiddenType === connectionType)
      },
    },
    mixins: [FiltersMixin],
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables";

  .legend_dropdown__legend-container {
    cursor: inherit;
  }

  .connections-body {
    background-color: white;
    padding: 0 10px 10px 10px;
  }

  .graph-view-connection-type {
    align-items: center;
    display: flex;
    position: relative;
  }

  .graph-view-connection-type__title {
    font-weight: 500;
    margin-top: 10px;
  }

  .legend-line {
    bottom: 2px;
    left: -11px;
    position: absolute;
    top: 2px;
    width: 4px;
  }

  .toggle-eye {
    margin-left: auto;
  }

  .connections-list {
    background-color: white;

    ul {
      list-style-type: none;
      margin-left: 0;
    }

    li {
      align-items: center;
      display: flex;
      position: relative;
    }

    .remove-link {
      margin-left: auto;
    }

    a {
      color: black;
      text-decoration: none;
    }
  }

  .connections-list li + .connections-list li,
  .connections-list ul + .connections-list ul {
    margin-top: 5px;
  }
</style>
