<template>
  <div>
    <h1 class="h1">{{$t('forgot_password_title')}}</h1>
    <form class="form" @submit.prevent="submit">
      <form-group is-simplified label="hidden" :error="emailErrors" style="margin-top: 10px">
        <ds-input v-model="email" is-onboarding-simplified placeholder="email" autofocus @input:emailErrors="[]"/>
      </form-group>
      <p v-if="message" v-text="message"></p>
      <form-group is-simplified label="hidden">
        <ds-button icon="chevron-right" type="submit" variant="rounded" :label="$t('forgot_password_send_password_link')"
                   :disabled="sending"/>
      </form-group>
      <a href="#" @click.prevent="openLoginModal">Login</a>
    </form>
  </div>
</template>
<script>
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'

  import { forgotPasswordRequest } from '../../api/user'

  import MODAL_IDS from '../../constants/modal-ids'
  import { trackHeapEvent } from '../../util/analytics.js'

  export default {
    data () {
      return {
        email: this.$route.query.email || window.email || '',
        emailErrors: [],
        message: null,
        sent: false,
        sending: false,
      }
    },
    props: {
      isCloseable: {
        type: Boolean,
        default: true,
      },
    },
    methods: {
      performValidation () {
        if (!this.email) {
          this.emailErrors = ['The email field is required.']
        }
      },
      submit () {
        this.sending = true
        this.emailErrors = []

        this.performValidation()

        if (this.emailErrors.length) {
          trackHeapEvent('onboarding-forgot-password-invalid-form')
          return
        }

        const data = {
          email: this.email,
        }

        forgotPasswordRequest(data)
          .then(data => {
            this.sending = false
            this.sent = true
            this.message = data.message || 'We sent an email with a password reset link.'
            this.emailErrors = []
            trackHeapEvent('onboarding-forgot-password-successful')
          })
          .catch(errors => {
            this.sending = false
            this.message = errors.email ? '' : 'Failed to send a password reset link.'
            this.emailErrors = errors.email || []
            trackHeapEvent('onboarding-forgot-password-failed')
          })
      },
      openLoginModal () {
        if (this.$route.name === 'forgot-password') {
          this.$router.push('/login')
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
        }
        this.$router.push({
          query: { email: this.email },
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
</style>
