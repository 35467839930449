<template>
  <div>
    <p>
      Fill in a number (6) or a range (10-20)
    </p>
    <div class="historic-count row">
      <div class="col-xs-2">
        <div v-for="y in years" class="pick-year-item" :style="{ 'font-weight': y==year ? 'bold' : null }" :class="{'text-muted': y!=year }" v-text="y" @click="setYear(y)"></div>
      </div>
      <div class="col-xs-10">
        <div :class="{row:true, 'item-disabled':inFuture(year, month - 1)}" v-for="month in 12">
          <label class="col-xs-2 col-md-2">{{ toMonth(month - 1) }}</label>
          <div class="col-xs-9 col-md-4">
            <input type="text" :value="getValue(year, month - 1)" @change="change($event, year, month - 1)" :disabled="inFuture(year, month - 1)">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inert, toBounds, fromBounds, MONTHS } from '../../util/helpers.js'

export default {
  props: {
    modelValue: {
      default: () => ([])
    }
  },
  emits: ['update:modelValue'],
  data () {
    return {
      year: new Date().getFullYear()
    }
  },
  computed: {
    thisMonth () {
      return new Date().getMonth()
    },
    thisYear () {
      return new Date().getFullYear()
    },
    years () {
      return [0, 1, 2, 3, 4].map(y => this.thisYear - y)
    },
    valueOrDefault () {
      return this.modelValue || []
    }
  },
  methods: {
    getValue (year, month) {
      return fromBounds(this.valueOrDefault.find(v => v.date.startsWith(this.yearMonth(year, month))))
    },
    inFuture (year, month) {
      return year >= this.thisYear && month > this.thisMonth
    },
    yearMonth (year, month) {
      return year + '-' + (month < 9 ? '0' + (month + 1) : month + 1)
    },
    setYear (v) {
      this.year = v.toString()
    },
    toMonth (m) {
      return MONTHS[m]
    },
    change (evt, year, month) {
      var bounds = toBounds(evt.target.value)
      bounds.date = this.yearMonth(year, month) + '-01'

      const values = inert(this.valueOrDefault)

      const index = values.findIndex(v => v.date.startsWith(this.yearMonth(year, month)))
      if (index > -1 && evt.target.value) {
        // Replace
        Object.assign(values[index], bounds)
      } else if (index > -1) {
        // Remove
        values.splice(index, 1)
      } else if (evt.target.value) {
        // Add
        values.unshift(bounds)
      }
      values.sort((a, b) => (b.date || '').localeCompare(a.date || ''))

      this.$emit('update:modelValue', values)
    }
  }
}
</script>

<style>
.pick-year-item {
  cursor: pointer;
}
.item-disabled {
  pointer-events: none;
  opacity: .3;
}
</style>
