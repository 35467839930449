import ls from '../../util/ls.js'
import { Challenges, fetchChallenges, fetchChallengesCount } from '../../Domain/Challenge/Api/challenges.ts'
import { assertArray } from '../../util/helpers.js'
import throttle from '../../util/throttle.js'

const state = {
  detail: {
    data: {},
    loading: false,
    error: null,
    errorStatusCode: null,
  },
  listData: {
    fetchLimit: 10,
    loading: false,
    data: [],
    error: null,
    endOfFeed: false,
    flushCache: false,
  },
  challengesFilters: {
    tags: ls('challengesFilters') ? (ls('challengesFilters').tags ? ls('challengesFilters').tags : []) : [],
    sustainability_goals: ls('challengesFilters') ? (ls('challengesFilters').sustainability_goals ? ls('challengesFilters').sustainability_goals : []) : [],
    visibility: ls('challengesFilters') ? ls('challengesFilters').visibility : [],
    status: ls('challengesFilters') ? ls('challengesFilters').status : [],
    pinned: ls('challengesFilters') ? ls('challengesFilters').pinned : [],
    isCreator: ls('challengesFilters') ? !!ls('challengesFilters').isCreator : false,
    communityIds: ls('challengesFilters') ? ls('challengesFilters').communityIds : [],
  },
  challengesCount: null,
  newChallengesCount: null,
}

export const ACTION_TYPES = {
  FETCH_CHALLENGES_COUNT: 'CHALLENGES/FETCH_CHALLENGES_COUNT',
  FETCH_NEW_CHALLENGES_COUNT: 'CHALLENGES/FETCH_NEW_CHALLENGES_COUNT',
  FETCH_CHALLENGES_LIST_NOW: 'CHALLENGES/FETCH_CHALLENGES_LIST_NOW',
  FETCH_CHALLENGES_LIST: 'CHALLENGES/FETCH_CHALLENGES_LIST',
  FETCH_CHALLENGE_DETAIL: 'CHALLENGES/FETCH_CHALLENGE_DETAIL',
  UPDATE_CHALLENGE: 'CHALLENGES/UPDATE_CHALLENGE',
}

export const MUTATION_TYPES = {
  FLUSH_CACHE: 'CHALLENGES/FLUSH_CACHE',
  TOGGLE_CHALLENGES_FILTER: 'CHALLENGES/TOGGLE_CHALLENGES_FILTER',
  SET_CHALLENGES_FILTER: 'CHALLENGES/SET_CHALLENGES_FILTER',
  CLEAR_CHALLENGES_FILTER: 'CHALLENGES/CLEAR_CHALLENGES_FILTER',
  FETCH_CHALLENGES_COUNT_RESULT: 'CHALLENGES/FETCH_CHALLENGES_COUNT_RESULT',
  FETCH_NEW_CHALLENGES_COUNT_RESULT: 'CHALLENGES/FETCH_NEW_CHALLENGES_COUNT_RESULT',
  FETCH_CHALLENGES_LIST_PENDING: 'CHALLENGES/FETCH_CHALLENGES_LIST_PENDING',
  FETCH_CHALLENGES_LIST_SUCCESS: 'CHALLENGES/FETCH_CHALLENGES_LIST_SUCCESS',
  FETCH_CHALLENGES_LIST_FAILURE: 'CHALLENGES/FETCH_CHALLENGES_LIST_FAILURE',
  FETCH_CHALLENGE_DETAIL_PENDING: 'CHALLENGES/FETCH_CHALLENGE_DETAIL_PENDING',
  FETCH_CHALLENGE_DETAIL_SUCCESS: 'CHALLENGES/FETCH_CHALLENGE_DETAIL_SUCCESS',
  FETCH_CHALLENGE_DETAIL_FAILURE: 'CHALLENGES/FETCH_CHALLENGE_DETAIL_FAILURE',
  UPDATE_CHALLENGE_SUCCESS: 'CHALLENGES/UPDATE_CHALLENGE_SUCCESS',
}

export const actions = {
  [ACTION_TYPES.FETCH_CHALLENGES_COUNT] ({ commit }, filters) {
    return fetchChallengesCount(filters).then((response) => {
      commit(MUTATION_TYPES.FETCH_CHALLENGES_COUNT_RESULT, response)
    })
  },
  // This is the total of challenges that are new to the user
  [ACTION_TYPES.FETCH_NEW_CHALLENGES_COUNT] ({ commit }) {
    return fetchChallengesCount({ type: 'new' }).then((response) => {
      commit(MUTATION_TYPES.FETCH_NEW_CHALLENGES_COUNT_RESULT, response)
    })
  },
  [ACTION_TYPES.FETCH_CHALLENGES_LIST]: throttle(({ dispatch }, params) => {
    dispatch(ACTION_TYPES.FETCH_CHALLENGES_LIST_NOW, params)
  }),
  [ACTION_TYPES.FETCH_CHALLENGES_LIST_NOW] ({ commit }, params) {
    commit(MUTATION_TYPES.FETCH_CHALLENGES_LIST_PENDING)

    return fetchChallenges(params)
      .then(response => {
        commit(MUTATION_TYPES.FETCH_CHALLENGES_LIST_SUCCESS, response)
      })
      .catch(errors => {
        commit(MUTATION_TYPES.FETCH_CHALLENGES_LIST_FAILURE, errors)
      })
  },
  [ACTION_TYPES.FETCH_CHALLENGE_DETAIL] ({ commit }, id) {
    const cachedChallenge = reuseFromListData(id)
    if (cachedChallenge) {
      commit(MUTATION_TYPES.FETCH_CHALLENGE_DETAIL_PENDING, id)
      commit(MUTATION_TYPES.FETCH_CHALLENGE_DETAIL_SUCCESS, cachedChallenge)
    }

    if (state.detail.loading === id) {
      return console.log('already loading detail', id)
    }

    if (state.detail.errorId === id) {
      return console.log('already tried loading detail', id)
    }

    if (id === true) {
      id = state.detail.data.id
    }

    commit(MUTATION_TYPES.FETCH_CHALLENGE_DETAIL_PENDING, id)

    return Challenges.get(id)
      .then(challenge => {
        commit(MUTATION_TYPES.FETCH_CHALLENGE_DETAIL_SUCCESS, challenge)
      })
      .catch(errors => {
        commit(MUTATION_TYPES.FETCH_CHALLENGE_DETAIL_FAILURE, {
          message: ': An error occurred while fetching the challenge.',
          statusCode: errors.statusCode
        })
      })
  },
  [ACTION_TYPES.UPDATE_CHALLENGE] ({ commit }, updateChallengeParam) {
    return Challenges.post(updateChallengeParam)
      .then(challenge => {
        commit(MUTATION_TYPES.UPDATE_CHALLENGE_SUCCESS, challenge)
      })
  },
}

function reuseFromListData (id) {
  return state.listData.data.find(a => a.id === id)
}

export const mutations = {
  [MUTATION_TYPES.FETCH_CHALLENGE_DETAIL_SUCCESS] (state, challenge) {
    if (state.detail.loading === challenge.id) {
      state.detail.data = challenge
    }

    state.detail.error = null
    state.detail.loading = false
    state.detail.errorId = null
    state.detail.errorStatusCode = null
  },
  [MUTATION_TYPES.FETCH_CHALLENGE_DETAIL_FAILURE] (state, error) {
    state.detail.data = {}
    state.detail.errorId = state.detail.loading
    state.detail.error = error.message
    state.detail.loading = false
    state.detail.errorStatusCode = error.statusCode
  },
  [MUTATION_TYPES.FLUSH_CACHE] (state) {
    state.listData.endOfFeed = false
    state.listData.fetching = false
    state.listData.flushCache = true
  },
  [MUTATION_TYPES.FETCH_CHALLENGES_COUNT_RESULT] (state, data) {
    state.challengesCount = data.count
  },
  [MUTATION_TYPES.FETCH_CHALLENGE_DETAIL_PENDING] (state, id) {
    state.detail.loading = Number(id)
  },
  [MUTATION_TYPES.FETCH_NEW_CHALLENGES_COUNT_RESULT] (state, data) {
    state.newChallengesCount = data.count
  },
  [MUTATION_TYPES.CLEAR_CHALLENGES_FILTER] (state) {
    state.challengesFilters = {
      tags: [],
      sustainability_goals: [],
      visibility: [],
      status: [],
      pinned: [],
      isCreator: false,
      communityIds: [],
    }

    ls('challengesFilters', state.challengesFilters)
  },
  [MUTATION_TYPES.SET_CHALLENGES_FILTER] (state, { target, value }) {
    state.challengesFilters[target] = value

    ls('challengesFilters', state.challengesFilters)
  },
  [MUTATION_TYPES.TOGGLE_CHALLENGES_FILTER] (state, { target, value }) {
    if (target === 'isCreator') {
      state.challengesFilters['isCreator'] = value

      ls('challengesFilters', state.challengesFilters)

      return
    }

    const existingFilter = state.challengesFilters[target].indexOf(value)

    if (existingFilter >= 0) {
      state.challengesFilters[target] = state.challengesFilters[target].filter(id => id !== value)
    } else {
      state.challengesFilters[target].push(value)
    }

    console.log(state.challengesFilters)

    ls('challengesFilters', state.challengesFilters)
  },
  [MUTATION_TYPES.FETCH_CHALLENGES_LIST_PENDING] (state) {
    state.listData.loading = true
  },
  [MUTATION_TYPES.FETCH_CHALLENGES_LIST_SUCCESS] (state, data) {
    state.listData.endOfFeed = data.length < state.listData.fetchLimit
    if (state.listData.flushCache) {
      state.listData.data = assertArray(data)
    } else {
      for (const challenge of assertArray(data)) {
        if (!state.listData.data.find(existingChallenge => existingChallenge.id === challenge.id)) {
          state.listData.data.push(challenge)
        }
      }
    }

    state.listData.flushCache = false
    state.listData.loading = false
  },
  [MUTATION_TYPES.FETCH_CHALLENGES_LIST_FAILURE] (state, error) {
    state.listData.error = error
    state.listData.endOfFeed = true
    state.listData.loading = false
  },
  [MUTATION_TYPES.UPDATE_CHALLENGE_SUCCESS] (state, challenge) {
    state.detail.data = challenge
  },
}

export default {
  state,
  mutations,
  actions,
}
