<template>
  <div
    class="challenge-actor-recommendation__container"
    @click="openActorPageInNewTab"
  >
    <div class="challenge-actor-recommendation__title-container">
      <div class="challenge-actor-recommendation__title-left-side">
        <Avatar variant="border" :src="actorLogo(actor)" :alt="actor.name" style="margin-right: 10px;"/>
        <div>{{ actor.name }}</div>
      </div>

      <div class="challenge-actor-recommendation__title-right-side">
        <IconButton
          v-if="actor.isActorClaimed"
          :icon="invitationIcon"
          :fill="true"
          :tool-tip="invitationToolTip"
          @click="inviteUsersWithClaimOnActorToChallenge"
        />

        <IconButton
          style="margin-left: 0.2rem;"
          icon="star"
          :fill="actor.isActorShortListed"
          @click="toggleActorShortListStatus"
        />
      </div>
    </div>
    <div>
      <div v-if="actor.shortDescription">
        {{ textLimit(actor.shortDescription) }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from 'vue'
  import { textLimit } from '../../../util/string'
  import { actorLogo } from '@/util/actor'
  import { addShortListedActor, inviteUsersViaActor, removeShortListedActor } from '@/Domain/Challenge/Api/challenges'
  import TranslationsMixin from '@/util/TranslationsMixin'
  import Avatar from '@/components/Avatar/Avatar.vue'
  import { ChallengeActor } from '@/Domain/Challenge/Types/challenge-types'
  import IconButton from '@/Domain/Shared/Components/IconButton.vue'

  export default defineComponent({
    name: "ChallengeActorCard",
    components: {
      IconButton,
      Avatar
    },
    mixins: [TranslationsMixin],
    props: {
      actor: {
        type: Object as PropType<ChallengeActor>,
        required: true
      }
    },
    data() {
      return {
        componentKey: 0,
        addingInvitees: false
      }
    },
    computed: {
      invitationToolTip(): string {
        return `Invite users linked to the actor to this ${this.challengeLabel}`
      },
      invitationIcon(): string {
        if (this.addingInvitees) {
          return 'spinner'
        }

        if (this.actor.hasInviteesAttached) {
          return 'check'
        }

        return 'email-outline'
      },
      challengeId(): number {
        return Number(this.$route.params.id)
      }
    },
    methods: {
      actorLogo,
      textLimit,
      async toggleActorShortListStatus() {
        if (!this.actor.isActorShortListed) {
          await addShortListedActor(this.challengeId, this.actor.id)
        } else {
          await removeShortListedActor(this.challengeId, this.actor.id)
        }

        this.actor.isActorShortListed = !this.actor.isActorShortListed
      },
      openActorPageInNewTab() {
        window.open(`/actors/${this.actor.id}`, '_blank').focus()
      },
      inviteUsersWithClaimOnActorToChallenge() {
        if (this.actor.loadingInvitees) {
          return
        }

        this.addingInvitees = true

        // hack to reload icon name
        this.componentKey++

        inviteUsersViaActor(this.challengeId, this.actor.id)
          .then(() => {
            this.$bus.emit('challengeInviteesUpdated')
            this.actor.hasInviteesAttached = true
            this.addingInvitees = false
          })
          .catch((error) => {
            this.addingInvitees = false
          })
      },
    }
  })
</script>

<style scoped lang="scss">
  .challenge-actor-recommendation__container {
    margin-bottom: 1rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
  }

  .challenge-actor-recommendation__title-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0.5rem;
  }

  .challenge-actor-recommendation__title-left-side {
    display: flex;
    align-items: center;
  }

  .challenge-actor-recommendation__title-right-side {
    display: flex;
    align-items: center;
  }
</style>
