<template>
  <div class="side-panel__portfolio-permissions-items">
    <div v-if="loading">
      <icon name="spinner"/>
      Loading
    </div>

    <div
      v-for="(actor, index) in actors"
      :key="'challenge-actor-recommendation__' + index"
    >
      <ChallengeActorCard
        :actor="actor"
      />
    </div>

    <div v-if="!actors.length && !loading">
      There are no short listed actors yet.
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from '~/vue'
  import { ActorBaseInfo } from '@/Domain/Actor/Types/ActorTypes'
  import { getShortListedActors } from '@/Domain/Challenge/Api/challenges'
  import ChallengeActorCard from '@/Domain/Challenge/Components/ChallengeActorCard.vue'
  import { transformChallengeShortListedActorsToChallengeActorArray } from '@/Domain/Challenge/Api/challenge-api-transformers'

  declare interface BaseComponentData {
    actors: ActorBaseInfo[],
    loading: Boolean
  }

  export default defineComponent({
    name: 'ChallengeShortListedActorsSidePanel',
    components: { ChallengeActorCard },
    data(): BaseComponentData {
      return {
        actors: [],
        loading: false
      }
    },
    computed: {
      challengeId(): number {
        return Number(this.$route.params.id)
      }
    },
    mounted() {
      this.getShortListedActors()
    },
    methods: {
      getShortListedActors() {
        this.loading = true

        getShortListedActors(this.challengeId)
          .then((response: any[]) => {
            this.actors = transformChallengeShortListedActorsToChallengeActorArray(response)
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      }
    }
  })
</script>

<style scoped lang="scss">

</style>
