<template>
  <div class="actor-contributor" :style="{marginBottom: compact ? '0' : '20px'}">
    <div class="actor-contributor__avatar-container">
      <div
        @click="startConversation(null, member)"
        v-tooltip.top="$t('start_conversation') + ' ' + member.name"
        class="actor-contributor__avatar"
        style="cursor: pointer;"
        :style="{backgroundImage: `url(${member.photoUrl})`, margin: compact ? '6px' : '20px 10px 20px 0px'}"
      />
    </div>
    <div class="actor-contributor__content">
      <div class="actor-contributor__info" style="width: 200px;">
        <div class="actor-contributor__name">{{ member.name }}</div>
        <a
          v-if="showEmail"
          class="actor-contributor__email"
          :href="`mailto:${member.email}`"
        >{{ member.email }}</a>
      </div>

      <div
        v-if="showOrganization"
        class="actor-contributor__claim"
        style="width: 200px;"
      >
        <div v-if="showOrganizationTitle" class="actor-contributor__claim-title">Organization</div>
        <div class="actor-contributor__claim-text">
          <a v-if="showOrganizationLink && member.latestClaim && member.latestClaim.name && member.latestClaim.elastic_id"
             :href="latestClaimUrl">{{
              member.latestClaim && member.latestClaim.name ? member.latestClaim.name : '-'
            }}</a>
          <span v-else>{{ member.latestClaim && member.latestClaim.name ? member.latestClaim.name : '-' }}</span>
        </div>
      </div>

      <div
        v-if="showDepartment"
        class="actor-contributor__department"
        style="width: 200px;"
      >
        <div class="actor-contributor__department-title">Department</div>
        <div class="actor-contributor__department-text">
          {{ member.department.length > 0 ? member.department : ' - ' }}
        </div>
      </div>
    </div>

    <div
      v-if="showExperience"
      class="actor-contributor__department"
      style="width: 200px;"
    >
      <div class="actor-contributor__department-title">Experience</div>
      <div class="actor-contributor__department-text">{{
          member.function.length > 0 ? member.function : ' - '
        }}
      </div>
    </div>

    <div class="actor-contributor__actions" v-if="showActions"
         style="margin-left: auto; display: flex; flex-direction: row;">
      <ds-button
        v-if="member.id !== loggedInUserId"
        size="small"
        variant="rounded"
        label="start conversation"
        @click="startConversation($event, member)"
      />

      <ds-button
        v-if="member.id !== loggedInUserId"
        size="small"
        variant="rounded"
        label="View"
        @click="openUserSidePanel(member)"
      />

      <!--<ds-button
        v-if="canUserManageMembers"
        icon="trash"
        size="small"
        variant="rounded"
        @click="promptRemoveMemberConfirmationModal(member)"
      />-->
    </div>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import CommunityMixin from '@/util/CommunityMixin'
  import { Conversations } from '@/api/conversations'
  import { ACTION_TYPES as CONVERSATION_ACTION_TYPES } from '@/store/modules/conversations'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '@/store/modules/ui'

  export default defineComponent({
    name: 'CommunityMemberCard',
    mixins: [CommunityMixin],
    props: {
      member: {
        type: Object,
        required: true,
      },
      showActions: {
        type: Boolean,
        default: true
      },
      showExperience: {
        type: Boolean,
        default: true,
      },
      showDepartment: {
        type: Boolean,
        default: true,
      },
      showOrganization: {
        type: Boolean,
        default: true,
      },
      showOrganizationTitle: {
        type: Boolean,
        default: true,
      },
      showOrganizationLink: {
        type: Boolean,
        default: false,
      },
      compact: {
        type: Boolean,
        default: false,
      },
      showEmail: {
        type: Boolean,
        default: false,
      }
    },
    computed: {
      latestClaimUrl() {
        return `/communities/${this.communityId}/actors/${this.member.latestClaim.elastic_id}`
      },
      loggedInUserId() {
        return this.$store.getters.userId
      },
    },
    methods: {
      startConversation($event, member) {
        Conversations
          .post({ recipient_id: member.id })
          .then((conversation) => {
            this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
            this.$router.push('/profile/conversations/' + conversation.id)
              .then(() => {
                this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
                  component: 'user-information-side-panel',
                  metaData: {
                    userId: member.id,
                    allowDelete: false,
                  },
                })
              })
          })
      },
      openUserSidePanel(member) {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          component: 'user-information-side-panel',
          metaData: {
            userId: member.id,
            allowDelete: false,
          },
        })
      },
    }
  })
</script>
<style lang="scss" scoped>
  @import "../../../scss/variables.scss";

  .actor-contributor {
    display: flex;
    background: $color-background-lightest-grey;
    padding: 0 20px;
    align-items: center;
    border-radius: 5px;

    .actor-contributor__avatar-container {
      display: flex;
      align-items: center;

      .actor-contributor__avatar {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 1px solid black;
        padding: 10px 10px 10px 0;

        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
      }
    }

    .actor-contributor__info, .actor-contributor__role, .actor-contributor__communities, .actor-contributor__department, .actor-contributor__claim {
      display: flex;
      flex-direction: column;
      font-size: 12px;
      justify-content: center;
      padding: 0 10px;
      min-width: 200px;
      max-width: 200px;

      .actor-contributor__name, .actor-contributor__role-title, .actor-contributor__communities-title, .actor-contributor__department-title, .actor-contributor__claim-title {
        font-weight: 500;
      }

      .actor-contributor__email, .actor-contributor__role-text, .actor-contributor__communities-text, .actor-contributor__department-text, .actor-contributor__claim-text {
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;

        a {
          text-decoration: none;
        }
      }
    }

    .actor-contributor__remove, .actor-contributor__conversation .actor-contributor-phone {
      display: flex;
      justify-content: center;
      align-items: center;

      &.not-visible {
        pointer-events: none;
        opacity: 0;
      }
    }

    .actor-contributor__conversation {
      margin-right: 20px;
    }

    .actor-contributor-phone {
      margin-right: 20px;
    }

    .actor-contributor__name-container {
      display: flex;
      overflow: hidden;
      width: 250px;
    }

    .actor-contributor__content {
      display: flex;
      flex-direction: row;
      overflow: hidden;
    }
  }
</style>
