<template>
  <div id="bubble-chart-div" class="bubble-chart">
    <div v-show="!canShowChart && showLoader" class="spider__loading chart__loading__positioning">
      <icon name="loading" />
    </div>
    <svg ref="bubble" class="bubble-chart__graph"></svg>
  </div>
</template>

<script>
import * as d3v4 from 'd3'

import { inert } from '../../util/helpers';


import { fetchBuzzData, fetchBuzzDefaultTopics } from '../../api/buzz.js';
import { MUTATION_TYPES as BUZZ_MUTATION_TYPES } from '../../store/modules/buzz';
import { MUTATION_TYPES as MEDIA_BUZZ_MUTATION_TYPES } from '../../store/modules/mediabuzz';
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      showLoader: true
    }
  },
  props: ['chartData', 'isInsideChartCloseup'],
  emits: ['click', 'update-data'],
  computed: {
    chartContainerHeight() {
      // For when the user is in an actor profile
      if(this.currentRoute.path.startsWith('/actors')) {
        // This is the previous itiration of how we would fetch the height of the container that contained the chart
        // Since the placement of the container and the chart has changed, the route that was used to fetch the height was changed as well resulting in the chart
        //not working and displaying at all

        //Previoust iteration
        // return Math.floor(this.$options.parent.$slots.Buzz[0].context.$refs.bubbleChartContainer.offsetHeight)

        // Quick fix
        // TODO: Make the chart component less dependend of the $refs, instead try using the $parent object to fetch the containers height
        // The isInsideChartCloseup is for the actor prorfile, since both the individual chart and the closepu component are in the differnte "plane" fields
        // The routing for the parent changes and the way of getting the offsetHeight changes as well
        if (this.isInsideChartCloseup)  {
          return Math.floor(this.$parent.$refs.bubbleChartContainer.offsetHeight)
        } else {
          if (!this.$parent.$parent.$refs.bubbleChartContainer) {
            return 0
          }
          return Math.floor(this.$parent.$parent.$refs.bubbleChartContainer.$el.offsetHeight);
        }

      }
      return Math.floor(this.$parent.$refs.bubbleChartContainer.offsetHeight)
    },
    chartContainerWidth () {
      // For when the user is in an actor profile
      if(this.currentRoute.path.startsWith('/actors')) {
        // This is the previous itiration of how we would fetch the width of the container that contained the chart
        // Since the placement of the container and the chart has changed, the route that was used to fetch the width was changed as well resulting in the chart
        // not working and displaying at all

        // Previoust iteration
        //return Math.floor(this.$options.parent.$slots.Buzz[0].context.$refs.bubbleChartContainer.offsetWidth)

        // Quick fix
        // TODO: Make the chart component less dependend of the $refs, instead try using the $parent object to fetch the containers width
        // The isInsideChartCloseup is for the actor prorfile, since both the individual chart and the closepu component are in the differnte "plane" fields
        // The routing for the parent changes and the way of getting the offsetWidth changes as well
        if (this.isInsideChartCloseup)  {
          return Math.floor(this.$parent.$refs.bubbleChartContainer.offsetWidth)
        } else {
          if (!this.$parent.$parent.$refs.bubbleChartContainer) {
            return 0
          }
          return Math.floor(this.$parent.$parent.$refs.bubbleChartContainer.$el.offsetWidth);
        }
      }
      return Math.floor(this.$parent.$refs.bubbleChartContainer.offsetWidth)
    },
    canShowChart () {
      return this.chartData.data && this.chartData.data.length > 0
    },
    currentRoute () {
      return this.$route
    },
    ecosystemColor () {
      return this.$store.state.config.primaryColor
    }
  },
  methods: {
    render () {
      var data = this.chartData

      if (!this.canShowChart) {
        this.clearGraph()
        this.errorMessage()
        this.showLoader = false
        return
      }

      if (this.currentRoute.path.startsWith('/buzz') && this.$parent.$refs.bubbleChartContainer == undefined) {
        return
      }

      var newBubbleData = inert(data)
      this.clearGraph()

      var thisVue = this

      // The height has to be set ouside the render otherwise the chart will keep growing in size.
      this.outerHeight = this.chartContainerHeight

      // For when the user is in an actor profile
      if (this.currentRoute.path.startsWith('/actors')) {
        // This is the previous itiration of how we would fetch the width of the container that contained the chart
        // Since the placement of the container and the chart has changed, the route that was used to fetch the width was changed as well resulting in the chart
        // not working and displaying at all

        // Previoust iteration
        //this.outerWidth = Math.floor(this.$options.parent.$slots.Buzz[0].context.$refs.bubbleChartContainer.offsetWidth)

        // Quick fix
        // TODO: Make the chart component less dependend of the $refs, instead try using the $parent object to fetch the containers width
        // The isInsideChartCloseup is for the actor prorfile, since both the individual chart and the closepu component are in the differnte "plane" fields
        // The routing for the parent changes and the way of getting the offsetWidth changes as well
        if (this.isInsideChartCloseup)  {
          this.outerWidth = Math.floor(this.$parent.$refs.bubbleChartContainer.offsetWidth)
        } else {
          if (!this.$parent.$parent.$refs.bubbleChartContainer) {
            return 0
          }
          this.outerWidth = Math.floor(this.$parent.$parent.$refs.bubbleChartContainer.$el.offsetWidth)
        }

      } else if(this.currentRoute.params.panel == "buzz" || this.currentRoute.params.panel == "media-buzz") {
        this.outerWidth = Math.floor(this.$parent.$refs.bubbleChartContainer.offsetWidth)
      } else {
        this.outerWidth = Math.floor(this.$parent.$refs.bubbleChartContainer.offsetWidth)
        this.outerHeight = this.outerWidth
      }

      var hoverTooltip = d3v4.select("body").append("div")
        .attr("class", "bubble-chart__d3-tip-hover")
        .style("opacity", 0);

      var diameter = this.outerHeight,
      circleMargin = 1,
      format = d3v4.timeParse("%d-%b-%y"),
      color = '#4262d6',
      minimumColorTransparency = 0.80

      var bubble = d3v4.pack()
          .size([diameter, diameter])
          .padding(1.5);

      var root = d3v4.hierarchy(classes(newBubbleData.data));

      // Will get the smallest and largest values
      var smallerValue = d3v4.min(Object.values(root.data.children).map(item => { return item.value }));
      var largerValue = d3v4.max(Object.values(root.data.children).map(item => { return item.value }));

      // Domain will be set between the smallest and largest value from the data
      var sizeScale = d3v4.scaleSqrt().range([10,100]).domain([smallerValue, largerValue]);

      // Value will be calculated based on the range and domain of sizeScale so that the bubbles are always properly scale
      root.sum(function(d) {
        return sizeScale(d.value);
      })

      function classes(root) {
        var classes = [];

        function recurse(name, node) {
          if (node.length > 0) {
            node.forEach(function(child) { recurse(node.name, child); });
          } else {
            classes.push({
              id: node.keywordId,
              className: node.keywordName,
              type:node.keywordType,
              value: node.occurences,
              sentiment: node.sentiment,
              fillColor: node.fillColor,
              tooltip: node.tooltip,
              tooltipClass: node.tooltipClass,
              emitClickEvent: node.emitClickEvent,
            });
          }
        }

        recurse(null, root);
        return {children: classes};
      }

      bubble(root);

      var svg = d3v4.select(this.$refs.bubble)
        .attr("width", this.outerWidth)
        .attr("height", this.outerHeight)
        .attr("class", "bubble")
        .attr("style", `transform: scale(0.7, 0.7)`)
        .append("g")
        .attr("width", this.outerWidth)
        .attr("height", this.outerHeight)
        .attr("style", `transform: translate(${(this.outerWidth/2 - diameter/2)}px, ${this.outerHeight/2 - diameter/2}px);`)


      var node = svg.selectAll(".node")
        .data(root.children)
        .enter().append("g")
        .attr("class", function(d) {
          return `node bubble3-${d.data.className}`
        })
        .attr("transform", function(d) { return "translate(" + diameter/2 + "," + diameter/2 + ")"; })
        .attr("opacity", 0)
        .on('mousemove', function(d, i) {
          hoverTooltip
           .style("left", (d3v4.event.pageX) + "px")
           .style("top", (d3v4.event.pageY - 20) + "px");
        })
        .on('mouseover', function(d, i) {
          var toolTipHtml = '<div class="tooltip-heading ' + d.data.tooltipClass + '">' + (d.data.tooltip || d.data.className) + "</div>"
          hoverTooltip
           .transition()
           .duration(100)
           .style("opacity", 1)

          hoverTooltip.html(toolTipHtml)
           .style("left", (d3v4.event.pageX) + "px")
           .style("top", (d3v4.event.pageY - 20) + "px");
        })
        .on('mouseout', function () {
          hoverTooltip
            .transition()
            .duration(100)
            .style("opacity", 0)
        })
        .on("click", function(d, i) {
          // TODO: refactor all of these commits to the (media)buzz state to $emit's of events
          // Change the filters of the Buzz state
          if (d.data.emitClickEvent) {
            thisVue.$emit('click', d.data.emitClickEvent)
          } else if (thisVue.currentRoute.params.panel == "media-buzz") {
            thisVue.$store.commit(MEDIA_BUZZ_MUTATION_TYPES.UPDATE_BUZZ_FILTER, { name: d.data.className, facet: 'topic', value: d.data.id })
            thisVue.$emit('update-data')
          } else {
            thisVue.$store.commit(BUZZ_MUTATION_TYPES.UPDATE_BUZZ_FILTER, { name: d.data.className, facet: 'topic', value: d.data.className })
            thisVue.$emit('update-data')
          }
        });

      node.append("circle")
          .attr("r", function(d) { return d.r + circleMargin; })
          .attr("class", function(d) {
            return `bubble-${d.data.className}`
          })
          .style('opacity', function(d) {
            if (d.data.sentiment < minimumColorTransparency) {
              return minimumColorTransparency
            } else {
              return d.data.sentiment
            }
          })
          .attr("fill", function(d){
            if (d.data.fillColor) {
              return d.data.fillColor
            } else if (d.data.type == 'ds_event' || d.data.type == 'ds_job' || d.data.type == 'ds_funding') {
              return thisVue.ecosystemColor
            }
            return color;
          });

      node.append("text")
          .text(function(d) { return d.data.className })
          .style("text-anchor", "middle")
          .style("fill", "white")
          .style("font-size", function(d) {
            return Math.min(2 * d.r, (2 * d.r - 8) / this.getComputedTextLength() * 12) + "px";
          })
          .attr("dy", ".35em");


      function introAnimation () {
        node.transition()
            .duration(800)
            .delay(300)
            .attr("opacity", 1)
            .attr("transform", function(d) { return "translate(" + d.x + "," + d.y + ")"; });
      }

      introAnimation();
    },
    errorMessage () {
      var thisVue = this
      var svg = d3v4.select(this.$refs.bubble)
          .attr("width", this.chartContainerWidth)
          .attr("height",  this.chartContainerHeight)
          .attr("style", `transform: scale(0.7, 0.7); display:inherit; margin:auto`);

      var errorMessage = svg.append("g")
          .attr("class", "bubble-chart-error-message")
          .attr("transform", `translate(${ this.chartContainerWidth / 4} , ${ this.chartContainerHeight / 2 })`)

      errorMessage.append("text")
          .text("No related keywords found.")
          .attr("opacity", function(d){
          if (thisVue.canShowChart == false) {
            return 1
          } else {
            return 0
          }
        })
    },
    clearGraph () {
      d3v4.select(this.$refs.bubble)
        .selectAll('*')
        .remove()
      d3v4.select('.bubble-chart__d3-tip-hover').remove()
    }
  },
  watch: {
    chartData (val) {
      if (val) {
        this.render()
      }
    }
  },
  async mounted () {
    this.$bus.on('resize', () => {
      this.render()
    })

    if (this.chartData && this.chartData.data) {
      this.render()
    }
  },
  beforeUnmount () {
    this.clearGraph()
  }
})

</script>
