<template>
  <div class="relationship-edit">
    {{ from }}
    {{ typeLabel }}
    <!--<router-link :to="'/actors/' + value.to" v-text="value.to_name || value.name" />-->
    {{ modelValue.to_name || modelValue.name }}
    &nbsp;
    <ds-button size="small" icon="trash" variant="link" @click="remove" />
  </div>
</template>


<script>
import FormGroup from '../Form/FormGroup.vue'
import Dropdown from '../Dropdown/Dropdown.vue'
import SuggestionInput from '../SuggestionInput/SuggestionInput.vue'

export default {
  props: ['from', 'modelValue', 'type'],
  computed: {
    ecosystemRelationships () {
      return this.$store.getters.fullActorRelationships
    },
    typeLabel () {
      const type = this.ecosystemRelationships.find(t => t.name === this.type) || {}
      return type.label || 'error'
    },
  },
  methods: {
    remove () {
      this.$emit('remove')
    }
  },
  components: {
    Dropdown,
    FormGroup,
    SuggestionInput
  }
}
</script>

<style lang="scss">
.relationship-edit {
  margin-top: 0.5rem;
  h1 {
    font-weight: 500;
    font-size: 1rem;
  }
  .svg-icon--trash {
    left: 7px !important;
  }
}
</style>
