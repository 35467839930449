<template>
  <div class="home-simplified static-home-klik" @scroll="onScroll" ref="scrollable">
    <div class="shp__header-container">
      <div class="shp__header container">
        <a class="shp__header-link" @click="goToActors">ACTOREN</a>
        <a class="shp__header-link" @click="goToProducts">AANBOD</a>
        <a class="shp__header-link" @click="goToSpaces">RUIMTES</a>
        <router-link class="shp__header-link" to="/announcements">NIEUWS & AGENDA
        </router-link>
        <a class="shp__header-link" @click="openSigninModal">LOG-IN</a>
      </div>
    </div>
    <div class="shp__body-container">
      <div class="shp__graphic-container">
        <div class="shp__graphic-left">
          Connect. <br>
          Interact. <br>
          Share. <br>
          Create. <br>
          <img :src="$store.state.config.logo_url_light" class="shp__logo"
               alt="Open Creatives Gent">
        </div>
        <div class="shp__graphic-right">
          <!-- NOTE: the 'muted' tag must be present for the browser to allow autoplaying the video -->
          <video src="https://opencreatives.gent/OCG-zw-2.mp4" style="height: 100%; width: 100%" autoplay loop muted ref="ocvideo" @click="toggleMute"/>
        </div>
      </div>
      <div class="container shp__text">
        <p>
          Ben jij actief in de culturele of creatieve sector in de regio Gent? Welkom op
          ons online platform dat nu al
        </p>
        <div class="counter counter-left" ref="actorsCounter">
          <span>{{ actorCountPrefix }}</span><span>{{ animatedActorCount }}</span> <span style="cursor: pointer" @click="goToActors">actoren</span>
        </div>
        <p class="p-between-counters">samenbrengt en een overzicht biedt van</p>
        <div class="counter counter-center" ref="ruimtesCounter">
          <span>{{ ruimteCountPrefix }}</span><span>{{ animatedRuimteCount }}</span> <span style="cursor: pointer" @click="goToSpaces">ruimtes</span>.
        </div>
        <br>
        <br>
        <p>Hier vind je inderdaad een ruimte voor jouw creatieve praktijk. Letterlijk...
          én figuurlijk. Een ruimte om jouw eigen skills te presenteren, en om de talenten,
          projecten, infosessies en oproepen van anderen te ontdekken.</p>
        <br>
        <br>
        <p>Resultaat: jij vindt wie of wat je zoekt, anderen vinden jou, samen vind je een
          creatieve oplossing.</p>
      </div>
      <div class="container shp__text">
        <p style="font-size: 20px">Nog niet geregistreerd?</p>
        <p>
          Maak <span style="text-decoration: underline; cursor: pointer"
                     @click="showSignupModal">je profiel</span> aan
        </p>
      </div>
      <br>
      <br>
    </div>
    <div class="shp__header-container shp__footer-container">
      <div class="shp__header container">
        <a class="shp__header-link" @click="goToActors">ACTOREN</a>
        <a class="shp__header-link" @click="goToProducts">AANBOD</a>
        <a class="shp__header-link" @click="goToSpaces">RUIMTES</a>
        <router-link class="shp__header-link" to="/announcements">NIEUWS & AGENDA
        </router-link>
        <a class="shp__header-link" @click="openSigninModal">LOG-IN</a>
      </div>
    </div>
  </div>
</template>

<script>
  import { trackHeapEvent } from '../../util/analytics.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import { actorCount } from '../../api/actors.js'
  import ConfigMixin from '../../util/ConfigMixin.js'
  import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters.js'
  import { MUTATION_TYPES as LOCALIZATION_MUTATION_TYPES } from '../../store/modules/localization.js'
  import { defineComponent } from 'vue'

  const nrOfDigits = 7

  export default defineComponent({
    data: () => {
      return {
        memberPackages: [],
        actorCount: 0,
        animatedActorCount: 0,
        animatedRuimteCount: 0,
        ruimteCount: 0,
        spacesCategoryId: null,
        canStartActorCounter: false,
        canStartRuimteCounter: false,
      }
    },
    components: {},
    computed: {
      actorCountPrefix () {
        return this.getZeroesPrefix(this.animatedActorCount)
      },
      ruimteCountPrefix () {
        return this.getZeroesPrefix(this.ruimteCount)
      },
    },
    methods: {
      toggleMute () {
        var videoRef = this.$refs.ocvideo

        videoRef.muted = !videoRef.muted
      },
      startActorCounter () {
        if (this.animatedActorCount) {
          return
        }
        this.animatedActorCount = 0
        let waitTime = 1
        const animate = () => {
          const difference = this.actorCount - this.animatedActorCount
          const speed = difference > 100 ? (difference / 20) : 1
          if (this.animatedActorCount < this.actorCount) {
            this.animatedActorCount = Math.ceil(this.animatedActorCount + speed)
            if (difference < 10 && waitTime < 500) {
              waitTime += 50
            } else if (difference < 30 && waitTime < 100) {
              waitTime = difference
            }
            setTimeout(animate, waitTime)
          } else {
            this.animatedActorCount = this.actorCount
          }
        }
        animate()
      },
      startRuimteCounter () {
        if (this.animatedRuimteCount) {
          return
        }
        this.animatedRuimteCount = 0
        let waitTime = 1
        const animate = () => {
          const difference = this.ruimteCount - this.animatedRuimteCount
          const speed = difference > 100 ? (difference / 20) : 1
          if (this.animatedRuimteCount < this.ruimteCount) {
            this.animatedRuimteCount = Math.ceil(this.animatedRuimteCount + speed)
            if (difference < 10 && waitTime < 500) {
              waitTime += 50
            } else if (difference < 30 && waitTime < 100) {
              waitTime = difference
            }
            setTimeout(animate, waitTime)
          } else {
            this.animatedRuimteCount = this.ruimteCount
          }
        }
        animate()
      },
      goToActors () {
        this.$router.push('/actors-simplified')
        this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)
      },
      goToProducts () {
        this.$router.push('/products-simplified')
        this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)
      },
      goToSpaces () {
        this.$router.push('/products-simplified')
        if (this.spacesCategoryId) {
          this.$store.commit(FILTERS_MUTATION_TYPES.TOGGLE_LEGEND_ITEM, {
            facet: 'category',
            value: this.spacesCategoryId,
          })
        }
      },
      showSignupModal () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.SIGN_UP)
      },
      getZeroesPrefix (countString) {
        const nrOfZeroes = nrOfDigits - String(countString).length
        let result = ''
        for (let i = 0; i < nrOfZeroes; i++) {
          result += '0'
        }
        return result
      },
      openSigninModal () {
        trackHeapEvent('staticHomePage.topBarLogin')
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
      },
      onScroll () {
        if (this.$refs.actorsCounter && this.$refs.scrollable && this.$refs.ruimtesCounter) {
          const actors = this.$refs.actorsCounter
          const scrollable = this.$refs.scrollable
          const ruimtes = this.$refs.ruimtesCounter
          if (!this.animatedActorCount && (scrollable.scrollTop + scrollable.offsetHeight) > (actors.offsetTop + actors.offsetHeight - 79)) {
            if (this.actorCount) {
              this.startActorCounter()
            } else {
              this.canStartActorCounter = true
            }
          }
          if (!this.animatedRuimteCount && (scrollable.scrollTop + scrollable.offsetHeight) > (ruimtes.offsetTop + ruimtes.offsetHeight - 79)) {
            if (this.ruimteCount) {
              this.startRuimteCounter()
            } else {
              this.canStartRuimteCounter = true
            }
          }
        }
      },
    },
    mounted () {
      this.$store.commit(LOCALIZATION_MUTATION_TYPES.SET_ACTIVE_LOCALE, { locale: 'nl', i18n: this.$i18n })
      actorCount({ actor_type: ['LegalEntity', 'Person'] }).then((result) => {
        setTimeout(() => {
          this.actorCount = result.count
          if (this.canStartActorCounter && this.actorCount) {
            this.startActorCounter()
          } else {
            this.canStartActorCounter = true
          }
        }, 1000)
      })

      if (this.categoryValuesAndLabelsPerType && this.categoryValuesAndLabelsPerType.Product) {
        const spacesCategory = this.categoryValuesAndLabelsPerType.Product.find(category => category.label.toLowerCase().startsWith('space') || category.label.toLowerCase().startsWith('ruimte'))
        this.spacesCategoryId = spacesCategory.value
        const filters = { actor_type: 'Product', category: this.spacesCategoryId }
        actorCount(filters).then((result) => {
          this.ruimteCount = result.count
          if (this.canStartRuimteCounter && this.ruimteCount) {
            this.startRuimteCounter()
          } else {
            this.canStartRuimteCounter = true
          }
        })
      }
    },
    mixins: [ConfigMixin],
  })
</script>

<style lang="scss" scoped>
  @import "resources/assets/scss/variables";

  .shp__text {
    padding: 30px 30px 0 30px;
    //
    @media (min-width: $screen-md) {
      padding: 60px 60px 0 60px;
    }
    margin: 0 auto;
    font-family: $font-stack-secondary;
  }

  p {
    letter-spacing: 0.5px;
    font-size: 28px;
    margin-bottom: 0;
  }

  .p-between-counters {
    @media (min-width: $screen-md) {
      margin-left: 60px;
    }
  }

  .counter {
    font-size: 60px;

    &.counter-left {
      @media (min-width: $screen-md) {
        margin-left: -70px;
      }
    }

    &.counter-center {
      @media (min-width: $screen-md) {
        margin-left: calc(50% - 200px);
      }
    }

    span:first-child {
      color: #BBE4FB;
      font-family: monospace, sans-serif;
    }

    span:nth-child(2) {
      color: var(--primary);
      font-family: monospace, sans-serif;
    }
  }

  .static-home-klik {
    margin-top: 79px;
    height: calc(100% - 79px);
    @media (max-width: $screen-md) {
      margin-top: 0;
      height: 100%;
    }
    .shp__header-container, .shp__footer-container {
      position: absolute;
      top: 0;
      width: 100%;
      background: white;
      z-index: 1;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
      @media (max-width: $screen-md) {
        position: initial;
        width: 100%;
      }

      &.shp__footer-container {
        position: initial;
        box-shadow: none;
        @media (max-width: $screen-md) {
          display: none;
        }
      }

      .shp__header {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        background: white;
        @media (max-width: $screen-md) {
          flex-direction: column;
        }


        .shp__header-link {
          font-size: 18px;
          text-decoration: none;
          font-family: $font-stack-secondary;
          padding: 30px;
          cursor: pointer;
          color: $color-text-grey;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          &:hover {
            color: var(--primary);
          }
          @media (max-width: $screen-lg) {
            padding: 30px 10px;
          }
          @media (max-width: $screen-md) {
            border-bottom: 1px solid var(--primary);
            flex-direction: column;
            text-align: center;
            padding: 20px 5px;
          }
        }
      }
    }

    .shp__body-container {
      @media (max-width: $screen-md) {
        margin: 0;
      }
      overflow: auto;
    }

    .shp__graphic-container {
      display: flex;
      width: 100vw;
      height: calc(100vh - 79px);
      @media (max-width: $screen-md) {
        height: initial;
      }

      .shp__graphic-left {
        background: var(--primary);
        position: relative;
        font-size: 22px;
        color: white;
        padding: 40px;
        flex: 0.5;
        flex-basis: 200px;
        max-width: 500px;

        @media(min-width: $screen-md) {
          width: calc(100vw - 100vh + 79px);
          max-width: none;
          flex: initial;
          flex-basis: initial;
          min-width: 200px;
        }

        .shp__logo {
          position: absolute;
          right: 0;
          bottom: 40px;
          width: calc(100% - 80px);
          margin: 0 40px;
          max-width: 350px;
        }
      }

      .shp__graphic-right {
        background: var(--primary);
        font-size: 0; // remove 'empty' border under video
        flex: 1;

        @media(min-width: $screen-md) {
          width: calc(100vh - 79px);
        }
      }

      @media (max-width: $screen-md) {
        flex-direction: column;

        .shp__graphic-left {
          max-width: none;
          text-align: center;

          .shp__logo {
            position: initial;
            margin: 40px auto 0 auto;
            right: 0;
            max-width: 250px;
          }
        }
      }

    }
  }
</style>
