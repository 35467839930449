<template>
  <EcosystemInfoCard title="Refills" v-if="logs && logs.length">
    <ul class="refill-logs">
      <li v-for="log of logs">
        <div>{{ getLogLabel(log) }}</div>
        <time :datetime="log.date">{{ parseDate(log.date) }}</time>
      </li>
    </ul>
  </EcosystemInfoCard>
</template>

<script>
import moment from 'moment';
import EcosystemInfoCard from '../Heartbeat/EcosystemInfoCard.vue';

export default {
  props: {
    logs: Array
  },
  components: {
    EcosystemInfoCard,
  },
  methods: {
    parseDate(date) {
      return moment(date).format('DD-MM-YYYY');
    },
    getLogLabel(log) {
      switch (log.type) {
        case 'purchase_data_package':
          return log.amount + (log.amount > 1 ? " data packages" : " data package");
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.refill-logs {
  list-style-type: none;
  margin-left: 0;
}

time {
  color: #9b9b9b;
  font-size: 12px;
  font-weight: 300;
}
</style>
