<template>
  <div>
    <h2 class="h2">Cross platform navigation</h2>
    <editor-language-dropdown v-model="editingLocale" style="margin-bottom: 20px; max-width: 240px;"/>
    <div v-if="!form.homeLinks" style="margin-bottom: 20px;">
      <ds-button variant="secondary" label="Add links" @click="form.homeLinks = homeLinksObjectArray "/>
    </div>
    <template v-else v-for="(homeLink, index) in form.homeLinks">
      <div class="row" style="margin-left: 0px">
        <form-group class="col-md-2" label="Favicon">
          <image-input v-model="homeLink.image_url" width="64" height="64"/>
        </form-group>
        <form-group class="col-md-4" style="padding-top: 20px;" label="Navigation - Name">
          <ds-input :model-value="getHomeLinksText(index)" @update:modelValue="setHomeLinksText(index, $event)" placeholder="Insert name"/>
        </form-group>
        <form-group class="col-md-4" style="padding-top: 20px; padding-left:0px; padding-right:0px" label="Link">
          <ds-input :model-value="getHomeLinksUrl(index)" @update:modelValue="setHomeLinksUrl(index, $event)" placeholder="https://example.com"/>
        </form-group>
      </div>
      <div class="row" style="margin-left: 0px;" v-if="isLinkInternal(index)">
        <form-group instructions="Styling with <a href='https://guides.github.com/features/mastering-markdown/' target='_blank'>Markdown</a> is supported" class="col-md-10" style="padding: 0;">
          <ds-textarea v-model="homeLink.markdown" autoresize/>
        </form-group>
      </div>
    </template>
  </div>
</template>

<script>
  import EditorLanguageDropdown from '../../Translations/EditorLanguageDropdown.vue'
  import ImageInput from '../../Form/ImageInput.vue'
  import DsTextarea from '../../Form/DsTextarea.vue'
  import { DEFAULT_LOCALE } from '../../../store/modules/localization'
  import objectPath from 'object-path'

  export default {
    name: 'Navigation.vue',
    props: ['form'],
    data () {
      return {
        homeLinksObjectArray: [
          { text: '', image_url: '', url: '', markdown: '' },
          { text: '', image_url: '', url: '', markdown: '' },
          { text: '', image_url: '', url: '', markdown: '' },
          { text: '', image_url: '', url: '', markdown: '' },
          { text: '', image_url: '', url: '', markdown: '' },
        ],
        editingLocale: DEFAULT_LOCALE
      }
    },
    methods: {
      isLinkInternal (index) {
        var url = this.form.homeLinks[index].url ? this.form.homeLinks[index].url : ''

        var reg = /^[a-zA-Z0-9_-]+$/

        // Internal links can only be letters without whitespace or anything else
        return reg.test(url) && !url.startsWith('http') && !url.startsWith('www')
      },
      getHomeLinksText (index) {
        if (this.editingLocale === DEFAULT_LOCALE) {
          return this.form.homeLinks[index].text
        } else {
          return objectPath.get(this.form, `translations.${this.editingLocale}.homeLinksText${index}`)
        }
      },
      setHomeLinksText (index, value) {
        if (this.editingLocale === DEFAULT_LOCALE) {
          this.form.homeLinks[index].text = value
        } else {
          if (!this.form.translations) {
            this.form.translations = {}
          }

          if (!this.form.translations[this.editingLocale]) {
            this.form.translations[this.editingLocale] = {}
          }

          this.form.translations[this.editingLocale]['homeLinksText' + index] = value
        }
      },
      getHomeLinksUrl (index) {
        if (this.editingLocale === DEFAULT_LOCALE) {
          return this.form.homeLinks[index].url
        } else {
          return objectPath.get(this.form, `translations.${this.editingLocale}.homeLinksUrl${index}`)
        }
      },
      setHomeLinksUrl (index, value) {
        if (this.editingLocale === DEFAULT_LOCALE) {
          this.form.homeLinks[index].url = value
        } else {
          if (!this.form.translations) {
            this.form.translations = {}
          }

          if (!this.form.translations[this.editingLocale]) {
            this.form.translations[this.editingLocale] = {}
          }

          this.form.translations[this.editingLocale]['homeLinksUrl' + index] = value
        }
      },
    },
    components: {
      EditorLanguageDropdown,
      ImageInput,
      DsTextarea,
    },
  }
</script>

<style scoped>

</style>
