import { ChatMessage } from '@/Domain/Chat/Types/types'

export const transformChatResponseToChatMessage = (response: any): ChatMessage => {
  return {
    id: response.id,
    message: response.message,
    role: response.role,
    conversationId: response.conversationId,
    messageType: response.messageType,
    createdAt: response.createdAt,
    usedSources: response.usedSources,
    userFeedback: response.userFeedback,
  }
}
