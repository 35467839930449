import { fetchTranslations } from '../../api/onboarding.js'

const state = {
  onboardingTranslations: null,
  fetchingOnboardingTranslations: false
}

export const ACTION_TYPES = {
  FETCH_ONBOARDING_TRANSLATIONS: 'ONBOARDING/FETCH_ONBOARDING_TRANSLATIONS',
}

export const MUTATION_TYPES = {
  SET_ONBOARDING_TRANSLATIONS: 'ONBOARDING/SET_ONBOARDING_TRANSLATIONS',
  SET_FETCHING_ONBOARDING_TRANSLATIONS: 'ONBOARDING/SET_FETCHING_ONBOARDING_TRANSLATIONS'
}

export const actions = {
  [ACTION_TYPES.FETCH_ONBOARDING_TRANSLATIONS]: ({ commit }) => {
    commit(MUTATION_TYPES.SET_FETCHING_ONBOARDING_TRANSLATIONS, true)

    fetchTranslations().then(data => {
      const result = {questions: {}}
      for (const q in data) {
        if (data.hasOwnProperty(q)) {
          if (q.startsWith('question_')) {
            result.questions[q] = data[q]
          } else {
            result[q] = data[q]
          }
        }
      }

      commit(MUTATION_TYPES.SET_ONBOARDING_TRANSLATIONS, result)
      commit(MUTATION_TYPES.SET_FETCHING_ONBOARDING_TRANSLATIONS, false)
    })
  },
}

export const mutations = {
  [MUTATION_TYPES.SET_ONBOARDING_TRANSLATIONS] (state, data) {
    state.onboardingTranslations = data
  },
  [MUTATION_TYPES.SET_FETCHING_ONBOARDING_TRANSLATIONS] (state, data) {
    state.fetchingOnboardingTranslations = data
  },
}

export default {
  state,
  mutations,
  actions,
}
