<template>
  <div>
    <template v-for="type in ['organisations', 'persons']">
      <template v-if="actors[type] && actors[type].length">
        <h2 v-if="showTypeTitle" class="h2" style="margin-top: 25px; text-transform: capitalize">{{ typeTitle(type) }}</h2>
        <div
          class="article-actor__result"
          v-for="actor in actors[type]"
          @click.stop="actor.id ? showActorSidePanel(actor.id) : addActorEcosystem(actor, true)"
        >
          <div class="article-actor__result__visual"
               :style="{ backgroundImage: `url(${actor.website_screenshot || getDefaultFallbackImageUrl()})` }">
          </div>
          <div style="width: 90%">
            <div class="article-actor__result__title">
              <Avatar variant="border" :src="actorLogo(actor)" :alt="actor.name"/>
              <span>{{ actor.name }}</span>
              <template v-if="actor.website">
                -
                <icon name="world" @click.stop="goToExternalLink(actor.website)"
                      v-tooltip.top="'View website'"/>
              </template>
              <template v-else-if="actor.linkedin">
                -
                <icon name="linkedin-logo" class="icon--black"
                      @click.stop="goToExternalLink(actor.linkedin)"
                      v-tooltip.top="'View linkedin'"
                      v-if="actor.linkedin"/>
              </template>

              <icon style="float: right; margin-left: 15px;" name="plus"
                    @click.stop="addActorEcosystem(actor, true)"
                    v-tooltip.top="'Add actor'" v-if="!actor.state && !actor.id"/>
              <icon style="float: right; margin-left: 15px;" name="spinner"
                    v-tooltip.top="'Add actor'"
                    v-if="actor.state === 'adding'"/>
              <icon style="float: right; margin-left: 15px;" name="link"
                    @click.stop="showActorSidePanel(actor.id)"
                    v-tooltip.top="'See details'"
                    v-if="actor.state === 'added' || actor.id"/>
              <icon style="float: right; margin-left: 15px;"
                    :name="isRelevantForSearch(actor) ? 'star' : 'star-outline'"
                    v-tooltip.top="'Mark as relevant'"
                    @click.stop="toggleRelevance(actor)"
                    v-if="conceptSearch && actor.state === 'added'"/>
            </div>
            <div>
              <div v-if="actor.description">
                {{ textLimit(actor.description) }}
              </div>
            </div>
          </div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
  import Avatar from '../../../components/Avatar/Avatar.vue'

  import { actorLogo } from '../../../util/actor'
  import { textLimit } from '../../../util/string.ts'
  import { createActor } from '../../../api/actors'
  import { updateEcosystemFileMetaData } from '../../../api/files'
  import { fetchActorsForSearch } from '../../../api/exploration.js'
  import { trackHeapEvent } from '../../../util/analytics.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui'
  import MODAL_IDS from '../../../constants/modal-ids'
  import { getDefaultFallbackImageUrl } from '../../../util/helpers.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'ArticleActorPanel.vue',
    data () {
      return {
        relevantActors: [],
      }
    },
    props: {
      actors: {
        type: Object,
      },
      file: {
        type: Object,
      },
      conceptSearch: {
        type: Object,
      },
      prependTitle: {
        type: String,
        default: '',
      },
      showTypeTitle: {
        type: Boolean,
        default: true,
      },
      actorsTitle: {
        type: String,
        default: 'Organizations',
      },
    },
    computed: {
      organisations () {
        return this.actors['organisations']
      },
      persons () {
        return this.actors['persons']
      },
    },
    methods: {
      getDefaultFallbackImageUrl,
      typeTitle (type) {
        if (type === 'organisations') {
          return (this.prependTitle ? this.prependTitle + ' ' : '') + this.$t('organizations')
        }

        if (type === 'persons') {
          return (this.prependTitle ? this.$t(this.prependTitle) + ' ' : '') + this.$t('people')
        }

        return type
      },
      isRelevantForSearch (actor) {
        return !!this.relevantActors.find(relevantActor => actor.id === relevantActor.id)
      },
      toggleRelevance (actor) {
        const originalRelevance = this.isRelevantForSearch(actor)
        if (!originalRelevance) {
          this.relevantActors.push(actor)
        } else {
          this.relevantActors = this.relevantActors.filter(relevantActor => relevantActor.id !== actor.id)
        }
        this.$emit('mark-relevant', { actor, isRelevant: !originalRelevance })
        trackHeapEvent('exploration.clickRelevantActor')
      },
      async fetchRelevantActorsForSearch () {
        if (!this.conceptSearch.id) {
          return
        }

        const result = await fetchActorsForSearch(this.conceptSearch.id, { linked_concept_search_id: this.conceptSearch.id, searchQuery: '', 'ml-supported': true })
        this.relevantActors = result.actors
      },
      actorLogo,
      textLimit,
      goToExternalLink (link) {
        window.open(link, '_blank')
      },
      showActorSidePanel (actorId) {
        window.location = '/actors/' + actorId
      },
      addActorEcosystem (actor) {
        actor.state = 'adding'

        if (!actor.id) {
          const modalContext = {
            prefilled: {
              name: actor.name,
              actor_type: 'LegalEntity',
              company_type: 'Headquarter',
              category: 'Company',
              linkedin: actor.linkedin,
            },
          }

          // actor.state = 'added'

          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, modalContext)
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)

          return
        }

        createActor(actor)
          .then(response => {
            actor.id = response.id

            actor.state = 'added'

            // Link the actor with the article
            var updatedFile = {
              title: this.file.title,
              actor_id: (this.file.linked_actor_elastic_ids || []).map(id => {
                return {
                  id,
                  name: 'foo',
                }
              }),
            }

            updatedFile.actor_id.push({
              id: actor.id,
              name: actor.name,
            })

            updateEcosystemFileMetaData(this.file.id, updatedFile)
          })
          .catch(err => {
            actor.state = false
          })
      },
    },
    components: {
      Avatar,
    },
    async created () {
      if (this.conceptSearch) {
        await this.fetchRelevantActorsForSearch()
      }
    },
  })
</script>

<style lang="scss" scoped>
  @import "../../../../scss/_variables.scss";

  .article-actor__result {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    border-bottom: 1px solid $color-borders;
    background-color: transparent;
    transition: all .2s;

    &:hover {
      cursor: pointer;
      /*background-color: rgba(#555, 0.05);*/
    }

    &:last-child {
      border: 0;
    }
  }

  .article-actor__result__visual {
    margin-right: 1rem;
    background-size: cover;
    background-position: center;

    @media (min-width: $screen-md) {
      min-width: 220px;
      max-width: 220px;
      min-height: 100px;
    }
  }

  .article-actor__result__title {
    margin-bottom: 0.5rem;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;

    .avatar {
      margin-right: 0.25rem;
    }
  }
</style>
