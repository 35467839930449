import _flatten from 'lodash/flatten'
import _countBy from 'lodash/countBy'
import _uniq from 'lodash/uniq'
import _sortBy from 'lodash/sortBy'

export function fromDomains (a) {
  return a ? a.map(fromDomain).join(',\n') : a
}

export function fromDomain (a) {
  return a.name
}

export function toDomains (a) {
  return a && a.split ? a.split(/,+/).map(s => s.trim()) : a
}

export function DomainsEditor () {

  const editor = Handsontable.editors.TextEditor.prototype.extend() 

  editor.prototype.getValue = function() {
    return fromDomains(toDomains(this.TEXTAREA.value))
  }

  editor.prototype.setValue = function(newValue) {
    this.TEXTAREA.value = fromDomains(toDomains(newValue))
  }

  return editor
}

export function DomainsRenderer(instance, TD, row, col, prop, value, cellProperties) {
  Handsontable.renderers.TextRenderer.apply(this, [instance, TD, row, col, prop, fromDomains(value), cellProperties])
}

export function getDomainsMenu (actors) {

  // Relation removal items
  const existing = _flatten(actors.map(a => a['_domains'] || []))
  const counts = _countBy(existing, 'name')
  const unique = _uniq(existing)
  for (var i = unique.length - 1; i >= 0; i--) {
    unique[i].count = counts[unique[i].to]
  }
  const sorted = _sortBy(unique, 'count').reverse()

  const menu = {}

  for (i = 0; i < sorted.length; i++) {
    menu['REMOVE_DOMAIN' + sorted[i].id] = 'Remove ' + sorted[i].name
  }
  
  return menu
}
