<template>
  <div class="actor-plot">
    <div>
      Score:
      &nbsp;
      <radio-button
        v-for="label in ['datascouts']"
        :key="label"
        v-model="filters.score"
        name="score"
        inline
        :label="label"
        :val="label"
      />
    </div>
    <div>
      <div style="max-width: 20em;margin:1em 0;">
        <dropdown force v-model="graphOption" :options="graphOptions" />
      </div>
    </div>
    <div v-if="errors.message">{{ errors.message }}</div>
    <div ref="plotly"></div>
    <div>
      The scores in the graph are not normalized.
    </div>
  </div>
</template>

<script>
import { fetchActorApi } from '../../api/actors'

import Dropdown from '../Dropdown/Dropdown.vue'
import RadioButton from '../Form/RadioButton.vue'

import { loadChunk } from '../../util/chunk-loader'

export default {
  props: ['actor-id', 'actor-name'],
  data () {
    return {
      filters: {
        score: 'datascouts',
      },
      errors: {},
      graphOption: 0,
      response: {
        score: '',
        data: [{}]
      }
    }
  },
  computed: {
    layout () {
      return {
        yaxis: {
          title: this.response.score
        },
        margin: { l: 40, b: 40, t: 20 },
        showlegend: true
      }
    },
    graphOptions () {
      return this.response.data.map(({ score }, value) => ({ value, label: score }))
    }
  },
  methods: {
    fetch () {
      fetchActorApi(this.actorId, 'scores', this.filters)
        .then(response => {
          if (response && Array.isArray(response.data)) {
            response.data.forEach(d => {
              d.name = this.actorName
            })
            if (response.dataReferenceActor) {
              response.dataReferenceActor.forEach(d => {
                d.name = 'Reference actor'
              })
            }
            this.response = response
          } else {
            this.response = { score: 'No response', data: [] }
          }
        })
        .catch(errors => {
          this.errors = errors || { message: 'An error occurred' }
        })
    },
    render () {
      loadChunk('Plotly', this.actualRender)
    },
    actualRender () {
      if (this.$el && !this._isunmounted) {
        if (this.response.dataReferenceActor) {
          Plotly.newPlot(this.$refs.plotly, [
            this.response.data[this.graphOption],
            this.response.dataReferenceActor[this.graphOption]
          ].filter(Boolean), this.layout, { showLink: false })
        } else {
          Plotly.newPlot(this.$refs.plotly, [
            this.response.data[this.graphOption]
          ].filter(Boolean), this.layout, { showLink: false })
        }
      }
    }
  },
  mounted () {
    this.fetch()
  },
  watch: {
    id () {
      this.fetch()
    },
    filters: {
      deep: true,
      handler () {
        this.fetch()
      }
    },
    response () {
      this.render()
    },
    graphOption () {
      this.render()
    }
  },
  components: {
    Dropdown,
    RadioButton
  }
}
</script>

<style>
.actor-plot {
  min-height: 600px;
}
</style>
