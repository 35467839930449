<template>
  <tr class="suggestion-row">
    <td @click="toggle">
      <input type="checkbox" :checked="checked">
    </td>
    <td @click="openSidepanel(suggestion)">
      <a class="suggestion-row__verify" :href="'/actors/' + suggestion.actor.id" title="Go to actor profile" target="_blank">{{actorName}}</a>
    </td>
    <td style="white-space: pre-wrap" @click="openSidepanel(suggestion)">{{ widget ? widget.property : suggestion.property }}</td>

    <td v-if="widget" @click="openSidepanel(suggestion)">
      <span v-if="widget.label" v-text="widget.label"></span>
      <span v-if="widget.text" v-text="widget.text" style="white-space: pre-wrap"></span>
      <a v-if="widget.href" v-text="widget.href" :href="widget.href" target="_blank"></a>
      <file-row v-if="widget.files" :model-value="widget.files"/>
    </td>
    <td v-else @click="openSidepanel(suggestion)">
      <div>
        <template v-if="suggestion.property === 'tags'">
          <span v-for="suggest in suggested">{{ suggest.label }}, </span>
          <span class="suggestion-row__current" v-if="current">{{ current.join(', ') }}</span>
        </template>
        <template v-else-if="suggestion.property === 'address'">
          <span>{{ addressSuggestionLine }}</span>
        </template>
        <template v-else-if="isUrl">
          <a :href="suggested" target="_blank">{{ suggested }}</a>
        </template>
        <template v-else-if="isRelatedActorSuggestion">
          <template v-if="suggestion.value && suggestion.value.url">
            <a :href="suggestion.value.url">{{ suggestion.value.name }}</a>
          </template>
          {{ suggested[0] }}
        </template>
        <template v-else-if="Array.isArray(suggested) && suggested[0] && suggested[0].name">{{ suggested[0].name }}</template>
        <template v-else>{{ suggested }}</template>
      </div>
      <div class="suggestion-row__old">
        <template v-if="suggestion.property === 'tags'"></template>
        <a v-else-if="isUrl && current" :href="current" target="_blank">{{ current }}</a>
        <template v-else-if="current">{{ current }}</template>
      </div>
    </td>
    <td v-if="suggestion.meta && suggestion.meta.url" @click="openSidepanel(suggestion)"><a :href="suggestion.meta.url" target="_blank">{{meta}}</a></td>
    <td v-else @click="openSidepanel(suggestion)"> {{meta}}</td>
    <td @click="openSidepanel(suggestion)">{{ suggestion.user ? suggestion.user.name : suggestion.agent }}</td>
    <td class="text-right" :title="date(suggestion.created_at)" @click="openSidepanel(suggestion)">{{ timeAgo(suggestion.created_at) }}</td>
  </tr>
</template>

<script>
  import { Suggestions } from '../../api/heartbeat.js'

  import ActorSuggestion from '../ActorSuggestions/ActorSuggestion.vue'
  import FileRow from '../Form/FileRow.vue'

  import { timeAgo } from '../../util/date'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'

  import { addressLine } from '../../util/helpers'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: ['suggestion', 'selection'],
    emits: ['change'],
    computed: {
      filterableRelationships () {
        return this.$store.getters.filterableRelationships
      },
      actorName () {
        var name = this.suggestion.actor.name

        if (this.suggestion.actor.city) {
          name += ' - ' + this.suggestion.actor.city
        }

        if (this.suggestion.actor.country_code) {
          name += ' (' + this.suggestion.actor.country_code + ')'
        }

        return name
      },
      meta () {
        if (!this.suggestion.meta) {
          return
        }

        var meta = this.suggestion.meta

        if (this.suggestion.property == 'company_number') {
          return meta.name + (meta.address_line ? ' - ' + meta.address_line : '') + (meta.full && meta.full.incorporation_date ? ' - Founded at: ' + meta.full.incorporation_date : '') + (meta.full && meta.full.inactive ? ' but no longer active ' : ' and active')
        } else if (this.suggestion.property == 'vat_number') {
          return meta.name + (meta.address_line ? ' - ' + meta.address_line : '') + (meta.full && meta.full.founding_date ? ' - Founded at: ' + meta.full.founding_date : '') + (meta.full && meta.full.status == 'Actief' ? ' but no longer active ' : ' and active')
        }
      },
      isUrl () {
        return this.isHandle || this.suggestion.property === 'url'
      },
      isHandle () {
        return ['facebook', 'twitter', 'linkedin', 'instagram', 'youtube', 'vimeo'].includes(this.suggestion.property)
      },
      isRelatedActorSuggestion () {
        return this.filterableRelationships.includes(this.suggestion.property)
      },
      addressSuggestionLine () {
        return addressLine(this.suggestion.value)
      },
      current () {
        if (!this.suggestion.actor) {
          return
        }

        if (this.suggestion.property == 'address') {
          var a = addressLine(this.suggestion.actor)

          if (this.suggestion.actor.country_code) {
            a += ' (' + this.suggestion.actor.country_code + ')'
          }

          return a
        }

        return this.suggestion.actor[this.suggestion.property]
      },
      suggested () {
        return this.suggestion.value
      },
      checked () {
        return this.selection.includes(this.suggestion.id)
      },
      widget () {
        switch (this.suggestion.property) {
          case 'youtube':
            return {
              property: this.suggestion.property,
              href: this.suggestion.value,
            }
          case 'facebook':
            return {
              property: this.suggestion.property,
              href: this.suggestion.value[0].handle,
            }
          case 'twitter':
            return {
              property: this.suggestion.property,
              href: this.suggestion.value[0].handle,
            }
          case 'domains':
          case 'industries':
          case 'technology':
          case 'activities':
            var suggestionLabel = this.suggestion.value[0] && this.suggestion.value[0].name

            if (this.suggestion.value.length == 2) {
              suggestionLabel = this.suggestion.value[0].name + ' (' + this.suggestion.value[1].name + ')'
            }
            return {
              property: this.suggestion.property,
              label: suggestionLabel,
            }
        }
      },
    },
    methods: {
      addressLine,
      toggle () {
        const index = this.selection.indexOf(this.suggestion.id)
        if (index < 0) {
          this.selection.push(this.suggestion.id)
        } else {
          this.selection.splice(index, 1)
        }
      },
      accept () {
        this.suggestion.status = 'accepted'
        Suggestions.post([this.suggestion])
          .then(() => {
            this.$emit('change')
          })
          .catch(() => {
            this.suggestion.status = 'error'
          })
      },
      deny () {
        this.suggestion.status = 'denied'
        Suggestions.post([this.suggestion])
          .then(() => {
            this.$emit('change')
          })
      },
      openSidepanel (suggestion) {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'scores', metaData: suggestion.actor.id })
      },
      timeAgo,
      date (str) {
        return str ? str.slice(0, 19) : ''
      },
    },
    components: {
      ActorSuggestion,
      FileRow,
    },
  })
</script>

<style>
  .suggestion-row td {
    cursor: pointer;
    line-height: 26px !important;
  }

  .suggestion-row td {
    line-height: 26px !important;
  }

  .suggestion-row .button {
    margin: -6px -10px !important;
    display: block;
    width: calc(100% + 20px);
    font-size: 12px;
  }

  .suggestion-row .suggestion-row__onhold {
    background: none;
    color: #999;
  }

  .suggestion-row .form-group {
    margin-bottom: 5px !important;
  }

  .suggestion-row__remove {
    width: 36px !important;
    margin-right: -10px !important;
  }

  .suggestion-row--deleted {
    color: #f99;
  }

  .suggestion-row__current {
    opacity: .5;
  }

  .suggestion-row__old {
    opacity: .5;
    text-decoration: line-through;
  }

  .suggestion-row__old > a {
    color: inherit;
  }

  .checkbox--suggestion {
    margin-top: 6px;
    float: left;
  }

  .checkbox--suggestion + .knowledge-report {
    margin-left: 20px;
  }
</style>
