<template>
  <div class="list-view">
    <table class="table table--striped table--hover" v-if="contactsList.length">
      <thead>
        <tr>
          <th-sort by="name" width="200">Name</th-sort>
          <template v-for="column in columns">
            <th-sort v-if="column.sortable" :by="column.name" width="column.width">{{ column.text }}</th-sort>
            <th v-else width="column.width">{{ column.text }}</th>
          </template>
        </tr>
      </thead>
      <tbody>
        <sortable-list-view-row
          v-for="(contact, index) in contactsList"
          :columns="columns"
          :key="index"
          :with-exploration-column="false"
          :company="contact"
          @click="openSidePanel(contact)"
        />
      </tbody>
    </table>
    <div class="explore__empty-state" v-else>
      No contacts found.
    </div>
  </div>
</template>

<script>
  import SortableListView from '../../components/ListView/SortableListView.vue';
  import SortableListViewRow from '../../components/ListView/SortableListViewRow.vue';
  import ThSort from '../../components/ListView/ThSort.vue';

  import PageItems from '../../components/PageItems/PageItems.vue';
  import Pagination from '../../components/Pagination/Pagination.vue';

  import CompanyMixin from '../../util/CompanyMixin';

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui';
  import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../../store/modules/actors';

  export default {
    name: 'ActorDetailContacts',
    props: {
      actor: Object,
      contacts: Array
    },
    data () {
      return {
        sortByColumn: '',
        sortDirection: '',
        order: '',
        columns: [
          {
            name: 'email',
            sortable: true,
            text: 'Email',
            width: 100
          },
          {
            name: 'phone',
            sortable: true,
            text: 'Phone',
            width: 100
          },
          {
            name: 'title',
            sortable: true,
            text: 'Title',
            width: 100
          },
          {
            name: 'department',
            sortable: true,
            text: 'Department',
            width: 100
          },
           {
            name: 'seniority',
            sortable: true,
            text: 'Seniority',
            width: 100
          }
        ]
      }
    },
    computed: {
      company () {
        // Backwards compatibility for the CompanyMixin
        return this.actor;
      },
      contactsList () {
        if (! this.sortByColumn) {
          return this.contacts;
        }

        // Make a copy of the contacts list so that we don't sort the original data of the parent
        var contactsList = this.contacts.slice();

        // Get the property to sort on
        var propertyToSortOn = this.sortByColumn == 'department' ? 'role' : this.sortByColumn;

        contactsList.sort((a, b) => {
          var valueA = a;
          var valueB = b;

          if (['title', 'seniority', 'role'].includes(propertyToSortOn)) {
            valueA = a.employment || [];
            valueB = b.employment || [];
          }

          if ((valueA[propertyToSortOn] || '') > (valueB[propertyToSortOn] || '')) {
            return this.sortDirection == 'desc' ? -1 : 1;
          } else {
            return this.sortDirection == 'desc' ? 1 : -1;
          }
        });

        return contactsList;
      }
    },
    methods: {
      toggleOrder (column) {
        if (column == this.sortByColumn) {
          if (this.sortDirection == 'desc') {
            this.sortDirection = 'asc';
          } else if (this.sortDirection == 'asc') {
            this.sortDirection = null;
          } else {
            this.sortDirection = 'desc';
          }
        } else {
          this.sortDirection = 'desc';
        }

        if (this.sortDirection) {
          this.sortByColumn = column;
          this.order = this.sortDirection == 'desc' ? '-' + this.sortByColumn : this.sortByColumn;
        } else {
          this.order = null;
          this.sortByColumn = null;
        }
      },
      openSidePanel (actor) {
        this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_FOR_PREVIEW, actor);
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component:"scores", metaData: {actorId: actor.id, isPreview: true}});
      }
    },
    mixins: [CompanyMixin],
    components: {
      PageItems,
      Pagination,
      SortableListView,
      SortableListViewRow,
      ThSort,
    }
  }
</script>
