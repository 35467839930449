<template>
  <div>
    <form @submit.prevent="submit">
      <h1 class="h1">{{ onboardingTextConfig.onboardingTitle }}</h1>
      <h4 class="h4">{{ onboardingTextConfig.onboardingSubtitle }}</h4>
      <p v-if="allErrors.message" class="form-group__error" v-html="allErrors.message"></p>
      <form-group label="hidden" required :error="allErrors.name"
                  is-simplified>
        <ds-input v-model="name" is-onboarding-simplified icon="user"
                  :placeholder="$t('onboarding_full_name')"/>
      </form-group>
      <form-group label="hidden" required :error="allErrors.email"
                  is-simplified>
        <ds-input v-model="email" is-onboarding-simplified icon="at"
                  :placeholder="$t('notification_settings_email')"/>
      </form-group>
      <p v-if="! socialLoginDisabled">{{ $t('onboarding_login_social') }}</p>
      <div v-if="! socialLoginDisabled">
        <social-auth-buttons
            :method="$t('login_social_start_text')"></social-auth-buttons>
        <br>
      </div>
      <form-group :error="allErrors.register" is-simplified label="hidden">
        <ds-button variant="rounded" :label="$t('actor_detail_next')" type="submit"/>
      </form-group>
    </form>
  </div>
</template>
<script>
import OnboardingMixin from '../../util/OnboardingMixin.js'
import _isEmpty from 'lodash/isEmpty.js'
import SocialAuthButtons from '../../components/SocialAuthButtons/SocialAuthButtons.vue'
import { trackHeapEvent } from '../../util/analytics.js'

const regexValidation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export default {
  data() {
    return {
      errors: {},
    }
  },
  props: {
    registrationErrors: Object
  },
  emits: ['setRegistrationErrors', 'next'],
  methods: {
    performValidation() {
      this.errors = {}
      this.$emit('setRegistrationErrors', {})
      if (!window.name) {
        this.errors.name = ['The name field is required.']
      }

      if (!regexValidation.test(window.email)) {
        this.errors.email = ['The email address is invalid']
      }

      if (!window.email) {
        this.errors.email = ['The email field is required.']
      }
    },
    submit() {
      this.performValidation()

      if (!_isEmpty(this.errors)) {
        trackHeapEvent('onboarding-public-registration-invalid-form')
        return
      } else {
        trackHeapEvent('onboarding-public-registration-successful')
      }
      this.$emit('next')
    },
  },
  computed: {
    allErrors() {
      return { ...this.errors, ...this.registrationErrors }
    },
    socialLoginDisabled() {
      return this.$store.state.config.socialLoginDisabled == true || !this.$store.getters.canRegister
    },
    name: {
      get() {
        return window.name
      },
      set(name) {
        window.name = name
      },
    },
    email: {
      get() {
        return window.email
      },
      set(email) {
        window.email = email
      },
    },
  },
  mixins: [OnboardingMixin],
  components: {
    SocialAuthButtons,
  },
}
</script>
