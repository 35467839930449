<template>
  <div style="padding-bottom: 20px; margin-bottom: 20px">
    <template v-if="!viewOnly && selectedReportTemplate && selectedReportTemplate.show_historical_data">
      <classic-card v-for="(report, index) in companyReports" :key="index"
                    @click.prevent="showReport(report)"
                    class="historical-report-card">
        <div v-if="report.preview_w320" style="margin:-1rem -1rem 1rem -1rem">
          <div class="card__cover"
               :style="'background-image: url(' + report.preview_w320 + ')'"></div>
        </div>
        <a class="card__sub-title" :href="'#report/' + report.date.slice(0, 7)">{{$t('Historical Report')}}
          {{ toFullMonth(report.date) }}</a>
      </classic-card>
    </template>
    <div class="actor-detail__report-container">
      <content-block
        :title="reportLabel"
        :use-header-title="useHeaderTitle"
        key="knowledge-sharing"
        :link="reportExportCurrentLink"
        icon="export"
        :button-disabled="!currentReportValues || !currentReportValues.length"
        buttonLabel="Export"
        style="width: 100%;"
        v-if="!viewOnly"
      >
        <knowledge-sharing :actor="actor" :show-back-button="showBackButton" :enable-read-more="enableReadMore" />
      </content-block>
      <content-block
        :title="reportLabel + reportSubtitle"
        :use-header-title="useHeaderTitle"
        key="knowledge-report"
        :link="reportExportCurrentLink"
        icon="export"
        :button-disabled="!currentReportValues || !currentReportValues.length"
        style="width: 100%;"
        buttonLabel="Export"
        v-else
      >
        <knowledge-report :actor="actor" :reports="currentReportValues"
                          :show-back-button="showBackButton"
                          @back="hideReport"
                          :displayContent="true" v-if="actor">
        </knowledge-report>
      </content-block>
    </div>

    <modal v-if="show.report"
           class="modal--scrollable"
           :title="reportLabel + ' ' + toFullMonth(show.report.date)"
           @close="show.report = false"
           close-on-blur
           is-closeable>
      <template v-slot:body>
        <div>
          <a
            :href="'/actors/' + this.actor.id + '/reports/' + show.report.date.substring(0, 7) + '/download/' + this.selectedReportTemplate.id"
            target="_blank">
            <ds-button :label="'Export ' + reportLabel" type="button" icon="export"
                       size="small" style="margin-bottom: 20px;"/>
          </a>
          <knowledge-report :actor="actor" :reports="show.report.reports" avg :show-back-button="false"/>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
  import ClassicCard from '../../components/Card/ClassicCard.vue'
  import ContentBlock from '../../components/ContentBlock/ContentBlock.vue'
  import KnowledgeSharing from '../../components/KnowledgeSharing/KnowledgeSharing.vue'
  import _sortBy from 'lodash/sortBy'
  import ReportMixin from '../../util/ReportMixin'
  import KnowledgeReport from '../../components/KnowledgeSharing/KnowledgeReport.vue'
  import Modal from '../../components/Modals/Modal.vue'

  import { toFullMonth } from '../../util/date'
  import { _intersect, inert } from '../../util/helpers'
  import { darken } from 'polished'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'ActorDetailReport',
    props: {
      actor: {
        type: Object,
      },
      useHeaderTitle: {
        type: Boolean,
        default: false,
      },
      enableReadMore: {
        type: Boolean,
        default: false,
      },
      showBackButton: {
        type: Boolean,
        default: true
      },
    },
    data () {
      return {
        show: {
          report: null,
        },
      }
    },
    computed: {
      actorPortfolios () {
        return this.actor.includedPortfolios || []
      },
      currentReportValues () {
        if (!this.actor.current_report) {
          return []
        }
        return this.actor.current_report.filter(reportField => this.isIncludedInTemplate(this.selectedReportTemplate, reportField))
      },
      reportSubtitle () {
        if (this.isSubmittedCall) {
          return ` (${this.$t('actor_detail_reports_submitted_on')} ${new Date(this.submittedDate).toLocaleDateString()})`
        }

        return ''
      },
      companyReports () {
        if (!this.actor.reports || !this.actor.reports.length) {
          return
        }

        if (!this.$store.getters.reportSettings || this.$store.getters.reportSettings.length === 0 || !this.selectedReportTemplate || !this.selectedReportTemplate.reportFields || !this.selectedReportTemplate.reportFields.length) {
          return
        }

        // Recent first
        const result = _sortBy(inert(this.actor.reports), 'date')
          .reverse()
          .filter(report => {
            report.reports = report.reports.filter(reportField => this.isIncludedInTemplate(this.selectedReportTemplate, reportField))
            return report.reports.length
          })

        // Check if file uploads available, to get an image preview going
        if (this.fileReportFieldIds) {
          result.forEach(report => {
            // Get file uploads for report
            const fileIds = report.reports
              .filter(d => this.fileReportFieldIds.includes(d.ecosystem_report_field_id))
              .map(a => (a.value || '').toString())

            // Find first file that is linked to actor that we can use the image from
            const first = (this.actor.files || []).find(file => fileIds.includes(file.id.toString()) && file.preview_w320 && !file.deleted_at)

            // Assign to this months report
            report.preview_w320 = first ? first.preview_w320 : null
          })
        }

        return result
      },
      isEmptySubmittedCall () {
        return this.isSubmittedCall && (!this.currentReportValues || !this.currentReportValues.length)
      },
      reportExportCurrentLink () {
        if (this.isEmptySubmittedCall) {
          return ''
        }
        return '/actors/' + this.actor.id + '/reports/current/download/' + this.selectedReportTemplate.id
      },
      isMember () {
        return this.$store.getters.isMember
      },
      textStyle () {
        return {
          color: darken(0.30, this.$store.getters.primaryColor),
        }
      },
      viewOnly () {
        if (this.isReportTemplateACall && this.isOpenCallTemplateDueDateExpired(this.selectedReportTemplate)) {
          return true
        } else if (this.isSubmittedCall) {
          return true
        } else if (this.userCanEdit) {
          return false
        }

        return !this.isMember
      },
      userCanEdit () {
        // Actors cannot be edited on exploration-only platforms (scouting platform)
        if (this.$store.getters.isExplorationOnly) {
          return false
        }

        return (this.$store.getters.isMember && this.$store.getters.allowEdit) ||
          this.$store.getters.userClaimedIds.includes(this.actor.id) ||
          (_intersect(this.$store.getters.userContributionPortfolios, this.actorPortfolios).length > 0 && this.$store.getters.hasAccessToPortfolioSharing && this.$store.getters.isPortfolioMember) ||
          this.$store.getters.isOwner
      },
    },
    methods: {
      toFullMonth,
      showReport (report) {
        this.show.report = report.ecosystem_report_field_id ? {
          date: report.created_at,
          reports: [report],
        } : report
      },
    },
    mixins: [
      ReportMixin,
    ],
    components: {
      ClassicCard,
      ContentBlock,
      KnowledgeSharing,
      KnowledgeReport,
      Modal,
    },
  })
</script>

<style lang="scss" scoped>

  .actor-detail__report-container {
    background-color: white;
  }
  .historical-report-card {
    display: inline-block;
    margin-right: 20px;
  }
</style>
