import { cancelIfLate, createJSONRequest } from './create-request'

export const fetchPage = (slug) => createJSONRequest({
  url: `/api/contents/${slug}`,
  method: 'GET',
})

/**
 * REFACTOR NOTE: this should best be refactored to "keyword" because tags are a separate single purpose bucket, keywords can be anything (tags, geo, taxonomy, ...)
 */
export const fetchTagSuggestion = cancelIfLate((tag) => createJSONRequest({
  url: `/api/tags?tag=${tag}`,
  method: 'GET',
}))

export const fetchRichTagSuggestion = cancelIfLate((tag, type = '') => createJSONRequest({
  url: `/api/rich-tags?tag=${tag}&type=${type}`,
  method: 'GET',
}))

export const fetchTagById = (tagId) => createJSONRequest({
  url: `/api/rich-tags?id=${tagId}`,
  method: 'GET',
})

export const translateText = ({ body, sourceLanguage, targetLanguage }) => createJSONRequest({
  url: `/api/translate`,
  method: 'POST',
  data: { body, sourceLanguage, targetLanguage },
})
