<template>
  <div>
    <div class="package-picker__container">

      <!--<h2 class="h2">Update your subscription</h2>-->
      <div v-if="currentStep === 'choose_plan'">
        <!-- Display the available plans -->
        <div class="person_subscription__plan-cta" v-if="paymentError">
          <div v-if="paymentError && paymentError.route">
            <span>For security reasons, we need you to complete a secondary verification step, <a
              :href="paymentError.route">click here</a> to finalise your payment.</span>d
          </div>
          <div class="form-group__error" v-else-if="paymentError && paymentError.message">
            <ds-button size="small" label="Go back" @click="goBack()"
                       v-if="!updatingPlan"/>
            <span>Something went wrong while processing your payment, contact us so we can help you further.</span>
          </div>
          <div class="form-group__error" v-else-if="Object.keys(paymentError).length"
               style="font-size: 14px">
            Something went wrong while updating your subscription:
            <div v-if="!Array.isArray(paymentError)">
              {{ paymentError }}
            </div>
            <div v-for="error in paymentError" v-else>
              {{ Array.isArray(error) ? error[0] : error }}
            </div>
          </div>
        </div>
        <br>
        <div class="package-picker__plans-overview">
          <template v-if="loadingPlans || !hasFetchedPaymentInfo">
            <icon name="spinner"/>
          </template>
          <div class="row" v-if="hasFetchedPaymentInfo">
            <package-picker-item v-for="(packageOption, index) in packages"
                                 :title="packageOption.title"
                                 :sub-title="packageOption.sub_title"
                                 :prices="packageOption.prices"
                                 :features-info="packageOption.featuresInfo"
                                 :total-items="countedPackages"
                                 :key="'packageOption_option_' + index">
              <template v-slot:actions>
                <div class="package-picker__button"
                     v-if="packageOption.id !== currentPlan && packageOption.trial_period">
                  <ds-button
                    variant="primary"
                    :icon="shouldShowLoadingIcon(packageOption, true) ? 'spinner' : ''"
                    :label="`start ${packageOption.trial_period} month trial`"
                    @click="selectPackage(packageOption, true)"/>
                </div>
                <div class="package-picker__button">
                  <ds-button
                    :disabled="packageOption.id === currentPlan"
                    variant="primary"
                    :icon="shouldShowLoadingIcon(packageOption, false) ? 'spinner' : ''"
                    :label="choosePackageLabel(packageOption)"
                    @click="selectPackage(packageOption)"/>
                </div>
                <div class="package-picker__button"
                     v-if="isOnTrial && packageOption.id === currentPlan">
                  <ds-button
                    variant="primary"
                    label="Activate"
                    @click="selectPackage(packageOption, false)"/>
                </div>
              </template>
            </package-picker-item>
          </div>
        </div>

        <!-- plan picker CTAs - only show if at least one payment method exists -->
        <div class="person_subscription__plan-cta"
             v-if="(!paymentError || !paymentError.route) && paymentMethods && paymentMethods.length > 0">
          <ds-button label="Change payment information" size="small" @click="goBack()"
                     v-if="!updatingPlan"/>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
  import { EcosystemMemberPackages } from '../../../api/ecosystem-member-packages'
  import { getPaymentInfo, updateSubscription } from '../../../api/user'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui'
  import MODAL_IDS from '../../../constants/modal-ids'
  import PackagePickerItem from './PackagePickerItem.vue'

  import { trackHeapEvent } from '../../../util/analytics.js'

  const PACKAGE_PICKER_STEPS = {
    CHOOSE_PLAN: 'choose_plan',
    CONFIRM_NEW_PLAN: 'confirm_plan', // DEPRECATED: This step can probably be removed, we used this as an additional confirmation step, but this is likely not necessary
  }

  export default {
    name: 'PackagePicker.vue',
    props: ['currentPlan', 'isOnTrial'],
    data () {
      return {
        packages: [],
        selectedPackage: {},
        selectedPackageIsTrial: false,
        currentStep: PACKAGE_PICKER_STEPS.CHOOSE_PLAN,
        promoCode: null,
        paymentError: null,
        updatingPlan: false,
        loadingPlans: false,
        paymentMethods: [],
        hasFetchedPaymentInfo: false,
      }
    },
    computed: {
      currentEcosystem () {
        return this.$store.getters.currentEcosystem
      },
      countedPackages () {
        // Proxy object
        const packages = this.packages

        return packages.length
      },
      ecosystemName () {
        return this.currentEcosystem.displayName || this.currentEcosystem.name
      },
      trialPackage () {
        return this.packages.find(packageItem => packageItem.trial_period)
      },
      emailAddress () {
        return this.$store.state.config.email
      },
      billingInfoIsValid () {
        const billingInfo = this.$store.state.user.billingInfo
        return billingInfo && billingInfo.billing_country && billingInfo.billing_address && billingInfo.billing_city && billingInfo.vat_id
      },
    },
    methods: {
      shouldShowLoadingIcon (packageOption, isTrial) {
        return this.selectedPackage.id === packageOption.id && this.updatingPlan && !!this.selectedPackageIsTrial === isTrial
      },
      fetchUserPaymentInfo () {
        getPaymentInfo()
          .then(response => {
            this.hasFetchedPaymentInfo = true
            this.paymentMethods = response.payment_methods
            this.hasDefaultPaymentMethod = response.has_default_payment_method
          })
          .catch(error => {
            console.log(error)
          })
      },
      choosePackageLabel (packageOption) {
        if (!this.currentPlan) {
          return 'Choose ' + packageOption.title
        }

        return this.currentPlan === packageOption.id ? `Current plan${this.isOnTrial ? ' (trial)' : ''}` : 'Choose ' + packageOption.title
      },
      updateSubscription (useTrial) {
        this.updatingPlan = true
        this.paymentError = null

        updateSubscription({
          packageId: this.selectedPackage.id,
          useTrial: useTrial,
          promoCode: this.promoCode,
        })
          .then(response => {
            this.updatingPlan = false
            this.$emit('choseNewPlan')
            window.location.reload()
          })
          .catch(error => {
            this.updatingPlan = false
            this.$emit('choseNewPlan') // If a payment was incomplete, we need to re-fetch the info again, it can contain information to complete it

            if (error.route) {
              this.paymentError = error
            } else if (error.message) {
              this.paymentError = error.message
            } else {
              this.paymentError = error
            }
          })
      },
      goBack () {
        if (this.currentStep === PACKAGE_PICKER_STEPS.CHOOSE_PLAN) {
          this.closePlanPicker()

          return
        }

        this.currentStep = PACKAGE_PICKER_STEPS.CHOOSE_PLAN
      },
      selectPackage (selectedPackage, useTrial) {
        this.selectedPackage = selectedPackage
        this.selectedPackageIsTrial = useTrial
        if (this.billingInfoIsValid && (useTrial || selectedPackage.prices[0].price === 0)) {
          return this.updateSubscription(useTrial)
        }
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          selectedPackage: this.selectedPackage,
          paymentMethods: this.paymentMethods,
          useTrial,
        })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CONFIRM_PACKAGE)
      },
      closePlanPicker () {
        this.$emit('closePlanPicker')
      },
      contactOwners () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          sendTo: 'owners',
          placeholder: 'Hello,' +
            '\nI would like to know more about your custom packages.',
          title: 'Custom Package',
          subject: 'Custom Package', // The subject that will be set in the email we send to owners
        })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CONTACT_SUPPORT, {})
      },
    },
    mounted () {
      this.loadingPlans = true

      EcosystemMemberPackages
        .get()
        .then(packages => {
          this.loadingPlans = false
          this.packages = packages
        })
        .catch(errors => {
          this.loadingPlans = false
          console.log(errors)
        })

      this.fetchUserPaymentInfo()

      this.$bus.on('choseNewPlan', () => {
        trackHeapEvent('profileBillingPage.confirmPickPlan', { plan: this.selectedPackage.title })
        this.$emit('choseNewPlan')
      })

      this.$bus.on('paymentError', (paymentError) => {
        this.paymentError = paymentError
        this.$emit('choseNewPlan')
      })
    },
    beforeUnmount () {
      this.$bus.off('paymentError')
    },
    components: {
      PackagePickerItem,
    },
  }
</script>

<style scoped lang="scss">
  @import "../../../../scss/variables";

  .package-picker__container {
    display: flex;
    flex-direction: column;
  }

  .person_subscription__plan-cta {
    display: flex;
    flex-direction: row;
  }

  .package-picker__coupon-code {
    max-width: 200px;
    margin-top: 1em;
  }

</style>
