<template>
  <div>
    <h3 class="h2">Configure your Stripe API keys</h3>
    <div>
      <br/>
       To copy paste your API keys, head over to the <a href="https://stripe.com/docs/keys" target="_blank">Stripe documentation page</a>, which provides you with guidelines on how to find them in your Stripe account.
    </div>
    <br><br>
    <div class="row">
      <form-group class="col-xs-12" label="Stripe Secret">
        <ds-input v-model="stripe.stripe_secret" placeholder="sk_" />
      </form-group>
      <form-group class="col-xs-12" label="Stripe Publishable Key">
        <ds-input v-model="stripe.stripe_key" placeholder="pk_" />
      </form-group>
      <form-group class="col-xs-12" label="Stripe Webhook Secret">
        <ds-input v-model="stripe.webhook_secret" placeholder="whsec_" />
      </form-group>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Stripe.vue',
    computed: {
      stripe () {
        return this.$parent.$parent.$parent.form.stripe
      },
    },
    created () {
      if (! this.$parent.$parent.$parent.form.stripe) {
        this.$parent.$parent.$parent.form.stripe = {
          'stripe_secret': '',
          'stripe_key': ''
        }
      }
    },
    components: {
    }
  }
</script>

<style scoped>

</style>
