<template>
  <div class="authentication-modal authentication-modal--small">
    <modal :id="modalId" ref="modal" :title="title" :enable-mask="false" :is-closeable="isCloseable">
      <template v-slot:body>
        <div>
          <form v-if="hasRecentlyConfirmed" class="form" @submit.prevent="logout">
            <fieldset>
              <p>
                You successfully confirmed your email. <br><br>
                Because this is a private ecosystem, you will have to be verified by the ecosystem owner before you have access.<br><br>

                When your request is accepted or denied, we will notify you.<br><br>

                If you have any questions about this process, please do not hesitate to contact us.<br><br>
              </p>
            </fieldset>
            <fieldset class="action-buttons">
              <ds-button type="submit" variant="secondary" icon="chevron-right" label="Logout"></ds-button>
            </fieldset>
          </form>
          <form v-else-if="roleIsUnverified" class="form" @submit.prevent="logout">
            <fieldset>
              <p>
                We have received your request to join the ecosystem. Before you can be a part of this ecosystem, you will have to be verified by the owner.<br><br>

                When your request is accepted or denied, we will notify you.<br><br>

                If you have any questions about this process, please do not hesitate to contact us.<br><br>
              </p>
            </fieldset>
            <fieldset class="action-buttons">
              <ds-button type="submit" variant="secondary" icon="chevron-right" label="Logout"></ds-button>
            </fieldset>
          </form>
          <form v-else-if="allowCuratedAdd" class="form" @submit.prevent="requestAccess">
            <fieldset>
              <p>
                This is a private ecosystem. Before you can join this ecosystem, you’ll have to be verified by the owner.<br><br>

                When your request is accepted or denied, we will notify you.<br><br>
              </p>
            </fieldset>
            <fieldset class="action-buttons">
              <ds-button type="submit" variant="primary" icon="chevron-right" label="Request Access"></ds-button>
              <ds-button @click="logout" type="button" variant="secondary" icon="chevron-right" label="Logout"></ds-button>
            </fieldset>
          </form>
          <form v-else class="form" @submit.prevent="logout">
            <fieldset>
              <p class="form-msg error">
                Access to this ecosystem is restricted.
              </p>
              <p class="form-msg error">
                The currently logged in user has no access to this ecosystem.
                You can sign in with another email address after logging out.
              </p>
            </fieldset>
            <fieldset class="action-buttons">
              <ds-button type="submit" variant="secondary" icon="chevron-right" label="Logout"></ds-button>
            </fieldset>
          </form>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
  import { MUTATION_TYPES as USER_MUTATION_TYPES } from '../../store/modules/user-helpers.js'

  import { logoutRequest, requestAccessRequest } from '../../api/user'
  import { lsClear } from '../../util/ls'

  import Modal from './Modal.vue'
  import AuthenticationModal from './AuthenticationModal.vue'
  import MODAL_IDS from '../../constants/modal-ids'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'

  export default {
    extends: AuthenticationModal,
    name: 'restricted-modal',
    props: {
      isCloseable: {
        type: Boolean,
        default: true
      },
    },
    data () {
      return {
        modalId: 'RESTRICTED'
      }
    },
    computed: {
      ecosystemName () {
        return this.$store.getters.name
      },
      title () {
        return this.hasRecentlyConfirmed ? 'You can almost join the ecosystem'
             : this.roleIsUnverified ? 'Your registration is being reviewed'
             : this.allowCuratedAdd ? 'Request access' : 'Switch user'
      },
      hasRecentlyConfirmed () {
        return window.intent === 'confirmed'
      },
      roleIsUnverified () {
        return this.$store.getters.roleIsUnverified
      },
      allowCuratedAdd () {
        return this.$store.getters.allowCuratedAdd
      }
    },
    methods: {
      requestAccess () {
        requestAccessRequest()
          .then(() => {
            window.location.reload()
          })
      },
      logout () {
        logoutRequest()
          .then(() => {
            this.openLoginModal()
          })
          .catch(() => {
            this.$store.commit(USER_MUTATION_TYPES.STORE_PROFILE, {})
          })

          lsClear()
      },
      openLoginModal () {
        if (this.$route.name === 'restricted') {
          this.$router.push('/login')
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.RESTRICTED)
        }
      },
    },
    components: {
      Modal
    }
  }
</script>
