<template>
  <dropdown-button
    :icon="icon"
    :size="size"
    :options="options"
    :is-kennis-west="isKennisWest"
    @on-click-button="updateOptions"
    @select="handleSelect"
  />
</template>

<script>
  import DropdownButton from '../DropdownButton/DropdownButton.vue'
  import { NewsGroups } from '../../api/exploration'
  import NewsGroupMixin from '../../util/NewsGroupMixin'

  export default {
    mixins: [NewsGroupMixin],
    props: {
      source: {
        type: Object,
      },
      size: {
        type: String,
      },
      icon: {
        type: String,
        default: 'tag',
      },
      isKennisWest: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      options () {
        if (this.mixinNewsGroups === null || this.mixinNewsGroups.length === 0) {
          return [{ value: null, label: 'There are no news groups yet', disabled: true }]
        }

        return [
          { value: null, label: 'Select a news group to add the source to', disabled: true, selected: true },
          ...this.mixinNewsGroups.map(newsGroup => {
            const inNewsGroup = this.sourceExistsInNewsGroup(newsGroup, this.source.value)

            return {
              value: newsGroup.id,
              label: newsGroup.name + (inNewsGroup ? ' (already in this news group)' : ''),
              disabled: inNewsGroup,
            }
          }).filter(r => r),
        ]
      },
    },
    methods: {
      updateOptions () {
        this.fetchNewsGroups()
      },
      handleSelect (value) {
        const newsGroup = this.mixinNewsGroups.find(d => d.id === value)

        if (!newsGroup) {
          console.error(`No such exploration topic: ${value}`)
          return
        }

        if (!this.source.value) {
          console.error(`No exploration value passed for topic with ID ${value} and label ${this.source.label} `)
          return
        }

        newsGroup.sources.push({
          value: this.source.value,
          label: this.source.label,
        })

        NewsGroups
          .post(newsGroup)
          .catch(error => console.error(error))
      },
    },
    components: {
      DropdownButton,
    },
  }
</script>
