<template>
  <div>
    <template v-if="!signalSaved">
      <ManageSignal
        @saved="handleConceptSearchSaved"
        @cancel="cancel()"
      />
    </template>
    <template v-else>
      <signal-saved :concept-search-id="conceptSearchId" />
    </template>
  </div>
</template>

<script lang="ts">
  import ManageSignal from './ManageSignal.vue'
  import SignalSaved from './SignalSaved.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'SignalGuide',
    data () {
      return {
        signalSaved: false,
        conceptSearchId: null,
      }
    },
    methods: {
      handleConceptSearchSaved (response) {
        this.conceptSearchId = response.conceptSearchId
        this.signalSaved = true

        this.$emit('saved')
      },
      cancel () {
        this.$emit('cancel')
      },
    },
    components: {
      ManageSignal,
      SignalSaved,
    },
  })
</script>

<style scoped>

</style>
