<template>
  <component class="wrapper" :is="tag">
    <span><slot></slot></span>
  </component>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'SimpleHeading',
    props: {
      level: {
        type: String,
        default: '1'
      }
    },
    computed: {
      tag () {
        return `h${this.level}`
      }
    }
  })
</script>

<style scoped lang="scss">
  .wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: 1rem;
    color: var(--primary-community-darkest);
    font-size: 1rem;
    font-weight: normal;

    &:after {
      content: "";
      flex: 1;
      height: 2px;
      margin-left: 0.5rem;
      background-color: var(--primary-community-lightest);
    }
  }
</style>
