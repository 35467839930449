<template>
  <div class="other-locale-preview" v-if="shouldDisplay">
    <div class="other-locale-preview__box">
      <div class="other-locale-preview__title">English version</div>
      <div class="other-locale-preview__content" v-if="!isHtml">{{ content }}</div>
      <div class="other-locale-preview__content" v-html="purifiedContent" v-else />
    </div>
  </div>
</template>

<script>
  import { DEFAULT_LOCALE } from '../../store/modules/localization'
  import DOMPurify from 'dompurify'

  export default {
    props: {
      editingLocale: {
        type: String,
      },
      values: {
        type: Object,
        default: () => ({}),
      },
      isHtml: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      purifiedContent () {
        return DOMPurify.sanitize(this.content)
      },
      shouldDisplay () {
        return false
        //return this.editingLocale !== DEFAULT_LOCALE && this.values[DEFAULT_LOCALE]
      },
      content () {
        return this.values[DEFAULT_LOCALE]
      },
    },
  }
</script>

<style lang="scss" scoped>
  .other-locale-preview {
    color: #aaa;
    padding-top: 8px;
    position: relative;
  }

  .other-locale-preview__box {
    border: 1px solid #ddd;
    padding: 10px;
  }

  .other-locale-preview__title {
    background: white;
    display: inline-block;
    position: absolute;
    top: 0;
    left: 6px;
    padding: 0 5px;
    font-size: 10px;
    line-height: 12px;
  }
</style>
