<template>
  <NewCard title="Traffic">
    <p>The graph below shows the traffic during the month of {{ month }}.</p>

    <p v-if="!loading && Object.keys(errors.trafficCurrentMonth).length" class="text-warning">
      <icon name="warning" size="14"></icon>
      <span v-for="(error, index) in errors.trafficCurrentMonth" :key="index">
        {{ error.message }}
      </span>
    </p>

    <div v-if="loading">
      Loading...
    </div>
    <div v-else-if="traffic && Object.keys(traffic.users_current_month).length && !Object.keys(errors.trafficCurrentMonth).length" class="chart-container">
      <div class="chart-main">
        <SimpleLineChart :data="currentMonthChartData">
        </SimpleLineChart>
      </div>

      <div class="chart-aside">
        <EcosystemInfoCard labelColor="#F5A623" title="Sessions this month">
          <span class="text-lg">
            {{ sessionsCurrentMonth }}
            <span class="text-muted">
              <DeltaPercentage
                :current="sessionsCurrentMonth"
                :previous="sessionsPreviousMonth"
              >
              </DeltaPercentage>
            </span>
          </span>
        </EcosystemInfoCard>

        <EcosystemInfoCard labelColor="#B36ADD" title="Unique visitors this month">
          <span class="text-lg">
            {{ usersCurrentMonth }}
            <span class="text-muted">
              <DeltaPercentage
                :current="usersCurrentMonth"
                :previous="usersPreviousMonth"
              >
              </DeltaPercentage>
            </span>
          </span>
        </EcosystemInfoCard>
      </div>
    </div>

    <div v-if="traffic && !Object.keys(traffic.sessions_per_month).length">
      No data
    </div>

    <hr/>

    <p>The graph below shows the traffic compared to the previous months.</p>

    <p v-if="!loading && Object.keys(errors.trafficPreviousMonth).length" class="text-warning">
      <icon name="warning" size="14"></icon>
      <span v-for="(error, index) in errors.trafficPreviousMonth" :key="index">
        {{ error.message }}
      </span>
    </p>

    <div v-if="loading">
      Loading...
    </div>
    <div v-else-if="traffic && Object.keys(traffic.users_per_month).length && !Object.keys(errors.trafficPreviousMonth).length" class="chart-container">
      <div class="chart-main">
        <SimpleLineChart :data="allMonthsChartData">
        </SimpleLineChart>
      </div>

      <div class="chart-aside">
        <EcosystemInfoCard labelColor="#F5A623" title="Total sessions">
          <span class="text-lg">
            {{ sessionsToDate }}
          </span>
        </EcosystemInfoCard>

        <EcosystemInfoCard labelColor="#B36ADD" title="Average visitors per month">
          <span class="text-lg">
            {{ avgUsers }}
          </span>
        </EcosystemInfoCard>
      </div>
    </div>

    <div v-if="traffic && !Object.keys(traffic.users_per_month).length">
      No data
    </div>
  </NewCard>
</template>

<script>
  import DeltaPercentage from './DeltaPercentage.vue'
  import EcosystemInfoCard from './EcosystemInfoCard.vue'
  import NewCard from '../../../components/NewCard/NewCard.vue'
  import SimpleLineChart from '../../../components/Chart/SimpleLineChart.vue'
  import moment from 'moment'

  const sum = (a, b) => a + b
  const sumValues = obj => Object.values(obj).reduce(sum)
  const lastValue = obj => Object.values(obj)[Object.values(obj).length - 1]
  const secondLastValue = obj => Object.values(obj)[Object.values(obj).length - 2]

  export default {
    props: {
      traffic: Object,
      date: String,
      loading: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        errors: {
          trafficCurrentMonth: [],
          trafficPreviousMonth: [],
        },
      }
    },
    components: {
      DeltaPercentage,
      EcosystemInfoCard,
      NewCard,
      SimpleLineChart,
    },
    computed: {
      month () {
        var date = moment(this.date)

        return date.format('MMMM')
      },
      allMonthsChartData () {
        if (this.traffic.users_per_month.error || this.traffic.sessions_per_month.error) {
          return {}
        }

        // this.traffic.sessions_per_month = this.sortOnMonths(this.traffic.sessions_per_month)
        // this.traffic.users_per_month = this.sortOnMonths(this.traffic.users_per_month)

        return {
          labels: Object.keys(this.traffic.users_per_month),
          datasets: [{
            borderColor: '#B36ADD',
            backgroundColor: '#B36ADD64',
            label: 'Users',
            data: Object.values(this.traffic.users_per_month),
          }, {
            backgroundColor: '#F5A62364',
            borderColor: '#F5A623',
            label: 'Sessions',
            data: Object.values(this.traffic.sessions_per_month),
          }],
        }
      },
      currentMonthChartData () {
        return {
          labels: Object.keys(this.traffic.users_current_month),
          datasets: [{
            borderColor: '#B36ADD',
            backgroundColor: '#B36ADD64',
            label: 'Users',
            data: Object.values(this.traffic.users_current_month),
          }, {
            backgroundColor: '#F5A62364',
            borderColor: '#F5A623',
            label: 'Sessions',
            data: Object.values(this.traffic.sessions_current_month),
          }],
        }
      },
      sessionsToDate () {
        if (this.traffic.sessions_per_month.error) {
          return 0
        }

        return sumValues(this.traffic.sessions_per_month)
      },
      sessionsCurrentDay () {
        // @deprecated
        if (this.traffic.sessions_current_month.error) {
          return 0
        }

        return lastValue(this.traffic.sessions_current_month)
      },
      sessionsPreviousDay () {
        if (this.traffic.sessions_current_month.error) {
          return 0
        }

        return secondLastValue(this.traffic.sessions_current_month)
      },
      avgUsers () {
        if (this.traffic.users_per_month.error) {
          return 0
        }

        if (!this.traffic.users_per_month || Object.keys(this.traffic.users_per_month).length == 0) {
          return 0
        }

        return Math.round(sumValues(this.traffic.users_per_month) / Object.keys(this.traffic.users_per_month).length)
      },
      usersCurrentDay () {
        // @deprecated
        if (this.traffic.users_current_month.error) {
          return 0
        }

        return lastValue(this.traffic.users_current_month)
      },
      usersPreviousDay () {
        // @deprecated
        if (this.traffic.users_current_month.error) {
          return 0
        }

        return secondLastValue(this.traffic.users_current_month)
      },
      sessionsCurrentMonth () {
        if (this.traffic.sessions_per_month.error) {
          return 0
        }

        return lastValue(this.traffic.sessions_per_month)
      },
      sessionsPreviousMonth () {
        if (this.traffic.sessions_per_month.error) {
          return 0
        }

        return secondLastValue(this.traffic.sessions_per_month)
      },
      usersCurrentMonth () {
        if (this.traffic.users_per_month.error) {
          return 0
        }

        return lastValue(this.traffic.users_per_month)
      },
      usersPreviousMonth () {
        if (this.traffic.users_per_month.error) {
          return 0
        }

        return secondLastValue(this.traffic.users_per_month)
      },
    },
    methods: {
      sortOnMonths (values) {
        // @deprecated, as this logic should be handled by the api
        const monthNames = {
          'January': 1,
          'February': 2,
          'March': 3,
          'April': 4,
          'May': 5,
          'June': 6,
          'July': 7,
          'August': 8,
          'September': 9,
          'October': 10,
          'November': 11,
          'December': 12,
        }
        const ordered = {}
        Object.keys(values).sort((a, b) => {
          return monthNames[a] - monthNames[b]
        }).forEach(function (key) {
          ordered[key] = values[key]
        })
        return ordered
      },
    },
    watch: {
      traffic () {
        Object.entries(this.traffic).forEach(([key, item]) => {
          switch (key) {
            case 'users_current_month':
            case 'sessions_current_month':
              if (item.error) {
                if (this.errors.trafficCurrentMonth.findIndex(item => item.status) < 0) {
                  this.errors.trafficCurrentMonth.push({
                    'message': item.error,
                    'code': item.status,
                  })
                }
              }

              break
            case 'users_per_month':
            case 'sessions_per_month':
              if (item.error) {
                if (this.errors.trafficPreviousMonth.findIndex(item => item.status) < 0) {
                  this.errors.trafficPreviousMonth.push({
                    'message': item.error,
                    'code': item.status,
                  })
                }
              }

              break
          }
        })
      },
    },
  }
</script>
