<template>
  <div class="guide-progress">
    <div v-for="step in steps" class="guide-progress__step"
         :class="{active: modelValue === step.value, done: completedUntil >= step.value, disabled: disableNavigation}"
         @click="handleStepClick(step)">
      <!--      <div class="guide-progress__step__label">{{ step.label }}</div>-->
      <div class="guide-progress__step__value">
        <span v-if="completedUntil < step.value">{{ step.value }}</span>
        <span v-else>{{ step.value }}</span>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      modelValue: {
        type: Number,
      },
      completedUntil: {
        type: Number,
      },
      steps: {
        type: Array,
        default: [],
      },
      disableNavigation: {
        type: Boolean,
        default: false,
      },
    },
    emits: ['update:modelValue'],
    computed: {},
    methods: {
      handleStepClick (step) {
        if (step.value > this.completedUntil || this.disableNavigation) {
          return
        }

        this.$emit('update:modelValue', step.value)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .guide-progress {
    width: 100%;
    display: flex;
    text-align: center;
  }

  .guide-progress__step {
    $radius: 21px;

    cursor: default;
    flex-grow: 1;
    display: flex;
    flex-direction: column;

    .guide-progress__step__label {
      margin-bottom: 5px;
      flex-grow: 1;
    }

    .guide-progress__step__value {
      height: $radius;
      position: relative;
      color: black;

      span {
        line-height: $radius;
        position: relative;
        z-index: 1;
        font-weight: 500;
      }

      &:before {
        content: '';
        position: absolute;
        top: 50%;
        left: calc(50% + calc(#{$radius} / 2));
        background: black;
        width: calc(100% - #{$radius});
        height: 1px;
      }

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: calc(50% - calc(#{$radius} / 2));
        width: $radius;
        height: $radius;
        border-radius: 2px;
        border: 1px solid black;
        background: white;
      }
    }

    &.active {
      $color: var(--primary);

      cursor: pointer;

      .guide-progress__step__label {
        color: $color;
      }

      .guide-progress__step__value {
        span {
          color: white;
        }

        &:before {
          background: black;
        }

        &:after {
          background: $color;
          border-color: $color;
        }
      }
    }

    &.done {
      $color: var(--primary);
      $hover-color: var(--primary);

      cursor: pointer;

      .guide-progress__step__value {
        span {
          color: white;
        }

        &:before {
          background: $color;
        }

        &:after {
          background: $color;
          border-color: $color;
        }
      }

      &:not(.disabled):hover {
        .guide-progress__step__label {
          color: $hover-color;
        }

        .guide-progress__step__value {
          span {
            color: $hover-color;
          }

          &:after {
            background: var(--primary-lightest);
            border-color: $hover-color;
          }
        }
      }
    }

    &.done.disabled {
      cursor: default;
    }

    &:last-child {
      .guide-progress__step__value:before {
        display: none;
      }
    }
  }
</style>
