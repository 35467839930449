<template>
  <modal :id="modalId" ref="modal" :title="this.id !== undefined ? 'Edit Topic' : 'Create Topic'" :is-closeable="true" @close="close">
    <template v-slot:body>
      <div>
        <div class="row">
          <form-group class="col-xs-12" label="Name">
            <ds-input v-model="label" :disabled="this.id !== undefined" />
          </form-group>
          <form-group class="col-xs-12" label="Words">
            <tag-input v-model="keywords" :nofocus="true" />
          </form-group>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div>
        <ds-button
          label="Cancel"
          variant="outline"
          @click="close"
        />
        <ds-button
          label="Save"
          variant="secondary"
          @click="save"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import MODAL_IDS from '../../constants/modal-ids'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import TagInput from '../../components/Form/TagInput.vue'
  import { Dictionary } from '../../api/exploration'

  export default {
    data () {
      return {
        id: undefined,
        loaded: false,
        modalId: MODAL_IDS.EDIT_TOPIC,
        label: '',
        keywords: [],
        onClose: () => {},
      }
    },
    methods: {
      loadData () {
        Dictionary.get(this.id).then(dictionary => {
          this.label = dictionary.value || ''
          this.keywords = dictionary.keywords || []

          if (!this.label && !this.keywords) {
            this.id = undefined
          }
        })
      },
      close (id) {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, this.modalId)
        this.$emit('close')
        this.onClose(Number.isInteger(id) ? id : undefined)
      },
      save () {
        let getId

        if (!this.id) {
          getId = Dictionary.post({value: this.label})
        } else {
          getId = Promise.resolve({topicId: this.id})
        }

        let theId = undefined

        getId.then(
          ({topicId}) => {
            theId = topicId
            return Dictionary.post({id: topicId, keywords: this.keywords})
          }
        ).then(
          () => this.close(theId)
        ).catch(
          e => console.error(e)
        )
      },
    },
    created () {
      const modalContext = this.$store.state.ui.modalContext

      if (modalContext) {
        this.id = modalContext.id
        this.onClose = modalContext.onClose || (() => {})
      }

      if (this.id) {
        this.loadData()
      }
    },
    components: {
      Modal,
      TagInput,
    }
  }
</script>

<style lang="scss">

</style>
