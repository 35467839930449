<template>
  <div>
    <div class="datepicker-container">
      <input autocomplete="off" :id="inputId" :class="{'is-simplified': isSimplified}" type="text" class="datepicker" :value="modelValue" :placeholder="placeholder">
      <div class="controls" v-if="allowReset && modelValue">
        <span @click="clearDate()" class="clear-btn">
          <span class="char">×</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import Pikaday from 'pikaday'
  import moment from 'moment'
  import { defineComponent } from 'vue'
  import { randomId } from '@/util/random.js'

  export default defineComponent({
    data: () => {
      return {
        picker: null
      }
    },
    props: {
      isSimplified: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: '',
      },
      inputId: {
        type: String,
        default: 'datepicker-' + randomId(5),
      },
      firstDay: {
        type: Number,
        default: 1, // Monday
      },
      modelValue: {},
      reference: null,
      minDate: null,
      maxDate: null,
      yearRange: null,
      allowReset: {
        type: Boolean,
        default: true
      }
    },
    emits: ['update:modelValue'],
    methods: {
      clearDate () {
        this.$emit('update:modelValue', null)
      },
    },
    mounted () {

      var minDate = null;
      var maxDate = null;

      if (this.minDate) {
        minDate = new Date(this.minDate).toDateString()
      }

      if (this.maxDate) {
        maxDate = new Date(this.maxDate).toDateString()
      }

      this.picker = new Pikaday({
        format: 'YYYY-MM-DD',
        minDate: minDate,
        maxDate: maxDate,
        yearRange: this.yearRange,
        firstDay: this.firstDay,
        field: document.getElementById(this.inputId),
        onSelect: (date) => {
          console.log(date)
          if (!this.reference) {
            this.$emit('update:modelValue', moment(date).format('YYYY-MM-DD').toString())
          } else {
            this.$emit('update:modelValue', { value: moment(date).format('YYYY-MM-DD').toString(), reference: this.reference })
          }
        },
      })
    },
    watch: {
      minDate (value) {
        if (value) {
          value = new Date(value)
        } else {
          value = null
        }

        this.picker.destroy()

        this.picker = new Pikaday({
          minDate: value,
          maxDate: this.maxDate,
          yearRange: this.yearRange,
          firstDay: this.firstDay,
          field: document.getElementById(this.inputId),
          onSelect: (date) => {
            if (!this.reference) {
              this.$emit('update:modelValue', moment(date).format('YYYY-MM-DD'))
            } else {
              this.$emit('update:modelValue', { value: moment(date).format('YYYY-MM-DD'), reference: this.reference })
            }
          },
        })
      },
      maxDate (value) {
        if (value) {
          value = new Date(value)
        } else {
          value = null
        }

        this.picker.destroy()

        this.picker = new Pikaday({
          minDate: this.minDate,
          maxDate: value,
          firstDay: this.firstDay,
          field: document.getElementById(this.inputId),
          onSelect: (date) => {
            if (!this.reference) {
              this.$emit('update:modelValue', moment(date).format('YYYY-MM-DD'))
            } else {
              this.$emit('update:modelValue', { value: moment(date).format('YYYY-MM-DD'), reference: this.reference })
            }
          },
        })
      },
    },
    unmounted () {
      this.picker.destroy()
    },
  })
</script>
<style lang="scss" scoped>
  @import "../../../scss/variables.scss";

  .datepicker-container {
    display: inline-block;
    position: relative;
    font-size: 1em;
    width: 100%;
    font-family: sans-serif;
    vertical-align: middle;

    .controls {
      display: flex;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      z-index: 3;
      -webkit-box-orient: horizontal;
      -ms-flex-flow: row nowrap;
      flex-flow: row nowrap;
      -webkit-box-pack: end;
      -ms-flex-pack: end;
      justify-content: flex-end;
      -webkit-box-align: stretch;
      -ms-flex-align: stretch;
      align-items: stretch;
      pointer-events: none;
    }

    .controls > * {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-direction: normal;
      box-sizing: border-box;
      cursor: pointer;
      width: auto;
      -webkit-box-orient: vertical;
      -ms-flex-flow: column nowrap;
      flex-flow: column nowrap;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      padding: 0 0.35em;
      color: #d2d2d2;
      line-height: 100%;
      font-style: normal;
      pointer-events: auto;
      -webkit-transition: color .2s, opacity .2s;
      transition: color .2s, opacity .2s;
    }

    .controls > *:hover {
      color: #797979;
    }

    .controls .char {
      font-size: 1.1em;
      line-height: 100%;
      -webkit-margin-before: -0.15em;
    }

  }
</style>
