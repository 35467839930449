<template>
  <div class="resource-card" :class="`resource-card--layout-${layout}`" @click="handleClick">
    <div class="resource-card__visual" :style="visualStyles"></div>

    <div class="resource-card__content">
      <div class="resource-card__header">
        <div class="resource-card__title">{{resource.title}}</div>
        <div class="resource-card__actions"></div>
      </div>

      <div class="resource-card__tags">
        <RichTag v-for="(tag, index) in displayResourceTags" :key="index" :tag="tag" />
      </div>

      <div class="resource-card__date">
        <Date :date="resource.date * 1000" />
      </div>

      <div class="resource-card__description" v-html="getResourceDescription()"></div>
    </div>
  </div>
</template>

<script>
  /**
   * @deprecated component
   */
  import { getResourceFileLink } from '../../util/get-resource-file-link.js'

  import { textLimit } from '../../util/string.ts'
  import { trackHeapEvent } from '../../util/analytics'

  import Tag from '../Tag/Tag.vue'
  import RichTag from '../Tag/RichTag.vue'
  import Date from '../DataFormatters/Date.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'ResourceCard',
    props: {
      resource: Object,
      layout: {
        type: String,
        default: 'vertical'
      }
    },
    components: {
      Tag,
      Date,
      RichTag
    },
    computed: {
      displayResourceTags () {
        if (!this.resource.tags) {
          return []
        }

        return this.resource.tags.slice(0, 3)
      },
      humanizedMediaType () {
        switch (this.resource.media_type) {
          case 'public_resource':
          case 'private_resource': {
            return 'Resource'
          }
          case 'announcement': {
            return 'Announcement'
          }
          default: {
            return this.resource.media_type
          }
        }
      },
      visualStyles () {
        const fileTypeMetaEntry = this.resource.meta.find(entry => entry.key === 'file_type')

        if (this.resource.media_type === 'announcement') {
          return {
            backgroundImage: `url(/notifications/${this.resource.sql_media_id}/image)`
          }
        }

        if (fileTypeMetaEntry.value.includes('image')) {
          const preview320MetaEntry = this.resource.meta.find(entry => entry.key === 'file_preview_w320')
          return {
            backgroundImage: `url(${preview320MetaEntry && preview320MetaEntry.value})`
          }
        }

        return {
          backgroundImage: 'url(/images/placeholder-resource.png)'
        }
      }
    },
    methods: {
      handleClick () {
        trackHeapEvent('homepage.clickResourceCard') // homepage might be the wrong prefix, at the time of writing, this is fine and intentional

        switch (this.resource.media_type) {
          case 'announcement': {
            this.$router.push(`/announcements/${this.resource.sql_media_id}`)
            break
          }
          case 'public_resource':
          case 'private_resource': {}
            window.open(getResourceFileLink(this.resource))
            break
        }
      },
      getResourceDescription () {
        var description = this.resource.description || this.resource.shortRawText

        if (!description) {
          return ''
        }

        return textLimit(description, 200)
      }
    },
  })
</script>

<style lang="scss">
  @import "../../../scss/variables";

  $resource-card-vertical-visual-height: 150px;
  $resource-card-horizontal-visual-width: 400px;

  .resource-card {
    border: 1px solid #F5F5F5;
    height: 100%;
    box-shadow: 0 0 0 rgba(#000, 0.2);
    transition: box-shadow .2s;

    &:hover {
      cursor: pointer;
      box-shadow: 0 0 4px rgba(#000, 0.1);
    }
  }

  .resource-card--layout-vertical {
    .resource-card__visual {
      height: $resource-card-vertical-visual-height;
    }
  }

  .resource-card--layout-horizontal {
    display: flex;

    .resource-card__visual {
      min-width: 150px;
      max-width: 150px;

      @media (min-width: $screen-md) {
        min-width: 300px;
        max-width: 300px;
      }

      @media (min-width: 1600px) {
        min-width: 400px;
        max-width: 400px;
      }
    }
  }

  .resource-card__visual {
    position: relative;
    background-size: cover;
    background-position: center center;
  }

  .resource-card__content {
    padding: 1.25rem;
  }

  .resource-card__header {
    margin-bottom: 0.75rem;
  }

  .resource-card__title {
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;
    color: $color-alternative-four;
  }

  .resource-card__date {
    color: $color-text-grey-light;
    margin-bottom: 1rem;
  }

  .resource-card__description {

  }
</style>
