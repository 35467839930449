<template>
  <div class="ds-input ds-input--has-icon">
    <icon v-if="icon" :name="icon" />
    <div class="tag-input" :class="{ 'taggle--icon': icon, 'taggle--newline': newline }" ref="taggle"></div>
  </div>
</template>
<script>
  import _isEqual from 'lodash/isEqual'
  import Taggle from 'taggle'

  export default {
    props: {
      icon: {
        type: String
      },
      modelValue: {
        type: Array,
        default: () => []
      },
      placeholder: {
        type: String
      },
      newline: {
        type: Boolean,
        value: false
      }
    },
    emits: ['update:modelValue'],
    mounted () {
      this.taggle = new Taggle(this.$refs.taggle, {
        duplicateTagClass: 'taggle--duplicate',
        preserveCase: true,
        saveOnBlur: true,
        tags: this.modelValue || [],
        placeholder: this.placeholder,
        onBeforeTagAdd: function(event, tag) {
          // to validade the value of the domain
          var regexValiation = /^(?!:\/\/)([a-zA-Z0-9-]+\.){0,5}[a-zA-Z0-9-][a-zA-Z0-9-]+\.[a-zA-Z]{2,64}?$/gi;
          if (regexValiation.test(tag)) {
            return tag
          }else{
            return false
          }
        },
        onTagAdd: () => {
          this.$emit('update:modelValue', this.taggle.getTags().values)
        },
        onTagRemove: () => {
          this.$emit('update:modelValue', this.taggle.getTags().values)
        }
      })
    },
    watch: {
      modelValue (tags, old) {
        if (this.taggle && !_isEqual(tags, old)) {
          this.taggle.removeAll()
          this.taggle.add(tags)
        }
      }
    }
  }
</script>
