<template>
  <ul class="modal__body__filter-column filter-empty-values" @click.stop="handleToggleEmptyFiltersSelection(filter)">
    <li>
      <checkbox-button
        :val="hasEmptyFiltersSelection(filter)"
        :model-value="'_empty'"
        :label="emptyLabel"
        class="modal__body__list--items"
        :style="{ width: '50%' }"
        @input="handleToggleEmptyFiltersSelection(filter)"
      />
    </li>
  </ul>
</template>

<script>
  import CheckboxButton from '../../CheckboxButton/CheckboxButton.vue'

  export default {
    name: 'EmptyFieldFilterColumn',
    props: {
      filter: {
        type: String,
        default: '',
      },
      selectedFilters: {
        type: Array,
        default: []
      },
      label: {
        type: String,
        default: 'Show empty values'
      }
    },
    data () {
      return {}
    },
    computed: {
      keywords: {
        get () {
          return this.selectedFilters || []
        }
      },
      emptyLabel () {
        return this.label
      },
    },
    methods: {
      transformFilterToFacet (item) {
        let facetName = item.toLowerCase()

        if (facetName === 'sustainability goals') {
          facetName = 'sustainability_goal'
        }
        if (facetName === 'growth stages') {
          facetName = 'stage'
        }

        return facetName
      },
      hasEmptyFiltersSelection (item) {
        var keyword = this.keywords.filter(f => (
          f.facet == this.transformFilterToFacet(item) && f.value == '_empty'
        ))

        return keyword.length === 0
      },
      handleToggleEmptyFiltersSelection (item) {
        this.$emit('handle-toggle-empty-filters', item)
      },
    },
    components: {
      CheckboxButton,
    },
  }
</script>

<style lang="scss">
  .modal__body__filter-column.filter-empty-values {
    li .button {
      padding-bottom: 5px;
    }
  }
</style>
