<template>
  <div class="profile fixed-heading">
    <div class="heading">
      <h1>Ecosystems</h1>
      <p>
        You are part of {{ ecosystems.length }} DataScouts ecosystems.
      </p>
    </div>

    <div class="scrollable has-padding">
      <table class="table">
        <thead>
          <tr>
            <th></th>
            <th>Ecosystem</th>
            <th>Role</th>
            <th>Visit</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="ecosystem in ecosystems">
            <td><img class="profile__favicon" :src="ecosystem.favicon_url"></td>
            <td>{{ ecosystem.name }}</td>
            <td>{{ normaliseRole(ecosystem.role) }}</td>
            <td><a :href="ecosystem.url" class="button button--secondary">Visit</a></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ecosystems',
  methods: {
    normaliseRole (selectedRole) {
      if (window.ROLES) {
        return window.ROLES[selectedRole]
      }

      switch (selectedRole) {
        case 'actor':
          return 'Ecosystem Member'
        case 'portfolio_member':
          return 'Portfolio Member'
        case 'owner':
          return 'Owner'
        case 'member':
          return 'Team Member'
        default :
          return ''
      }
    },
  },
  computed: {
    ecosystems () {
      return this.$store.state.user.profile.teams || []
    }
  }
}
</script>

<style>
.profile__favicon {
  width: 40px;
  height: 40px;
}
</style>
