<template>
  <div class="concept-search-checkbox" @click="handleClick">
    <icon class="icon" size="18" :name="relevant ? 'star' : 'star-outline'"/>
    <span>{{ title }}</span>
  </div>
</template>

<script>
  export default {
    props: {
      id: undefined,
      relevant: Boolean,
      title: String,
    },
    methods: {
      handleClick() {
        this.$emit('toggle', {id: this.id, relevant: !this.relevant});
      },
    }
  }
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

  .concept-search-checkbox {
    position: relative;
    cursor: pointer;
    user-select: none;
    padding: 6px;
    padding-left: 30px;
    height: 30px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    &:hover {
      background: $color-primary-lighter;
    }

    .icon {
      position: absolute;
      top: calc(50% - 9px);
      left: 6px;

      svg > g {
        fill: white;
      }
    }

    > span {
      vertical-align: middle;
    }
  }
</style>
