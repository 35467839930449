<template>
  <div class="static-top-bar-foodleap" :class="{'static-top-bar-isFoodleap': isFoodleap }">
    <div class="static-top-bar-foodleap__content">
      <template v-if="config.homepage">
        <a :href="config.homepage">
          <img class="static-logo-image" :src="leftLogoUrl"/>
        </a>
      </template>
      <template v-else>
        <img class="static-logo-image" :src="leftLogoUrl"/>
      </template>

      <div class="static-top-bar-foodleap__navigation">
        <ul>
          <router-link
            to="/dashboards/home"
            target="_self"
            class="navigation_item"
          >
            <li>Home</li>
          </router-link>
          <router-link
            to="/dashboards/foodleap-about"
            target="_self"
            class="navigation_item"
          >
            <li>About</li>
          </router-link>
          <router-link
            to="/dashboards/foodleap-contact"
            target="_self"
            class="navigation_item"
          >
            <li>Contact</li>
          </router-link>

        </ul>
      </div>
      <div class="static-top-bar-foodleap__buttons">
        <div>
          <ds-button
            variant="secondary"
            :label="$t('action_join_register')"
            :size="null"
            :style="registerButtonStyle"
            @click="openSignupModal"
          />
          <ds-button
            class="login_button"
            variant="primary"
            label="Login"
            :style="loginButtonStyle"
            :size="null"
            @click="openSigninModal"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import { lighten } from 'polished'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import { trackHeapEvent } from '../../util/analytics.js'
  import NavigationItem from '../Navigation/NavigationItem.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {},
    data () {
      return {}
    },
    methods: {
      openSigninModal () {
        trackHeapEvent('staticHomePage.topBarLogin')

        this.$router.push('/login')
      },
      openSignupModal () {
        trackHeapEvent('staticHomePage.topBarRegister')

       if (this.$store.getters.hasAccessToEcosystemMemberPackages) {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.PACKAGES_SIGN_UP)
        } else {
         this.$router.push('/register')
        }
      },
    },
    computed: {
      leftLogoUrl () {
        return this.config.logo_url_light || this.config.logo_url
      },
      isFoodleap () {
        return this.$store.getters.isFoodleap
      },
      registerButtonStyle () {
        return {
          backgroundColor: '#21244A',
          color: 'white',
          fontFamily: '',
          fontWeight: '',
          height: 'auto',
          textTransform: 'capitalize',
        }
      },
      loginButtonStyle () {
        return {
          backgroundColor: 'white',
          fontFamily: '',
          fontWeight: '',
          color: '#21244A',
          height: 'auto',
          width: '90px',
          border: '2px solid white',
          borderRadius: '4px',
          textTransform: 'capitalize',

        }
      },
      config () {
        return this.$store.state.config
      },
    },
    mounted () {
    },
    beforeUnmount () {
    },
    mixins: [],
    components: {
      NavigationItem,
    },
  })
</script>

<style scoped lang="scss">
  @import "resources/assets/scss/variables";

  .static-logo-image {
    max-height: 60px;
    height: auto;
    max-width: calc(100vw - 100px - 190px);
    object-fit: contain;
  }

  .static-top-bar-foodleap {
    padding: 10px 50px 10px 50px;
    background: $foodleap-topbar-background-color;
    font-family: Museo, serif;

    @media (max-width: $screen-md-max) {
      padding: 10px 0px 0px 0px;
    }

    .static-top-bar-foodleap__navigation {
      display: flex;
      margin-left: auto;
      padding-left: 4%;

      ul {
        display: flex;
        align-items: center;
        justify-content: center;

        li {
          color: #FFF;
          margin-right: 40px;
          cursor: pointer;
          font-size: 14px;
        }
      }
    }

    .static-top-bar-foodleap__content {
      display: flex;

      .static-top-bar-foodleap__buttons {
        margin-left: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;

        :deep(.button__label) {
          font-family: Museo, serif;
        }
      }
    }

    .navigation_item {
      color: #21244A;
    }

    .navigation__link--active {
      color: white;
    }

    @media(min-width: $screen-sm) {
      .static-top-bar-foodleap__content {
        justify-content: center;
      }
    }
    @media (max-width: $screen-md-max) {
      .static-top-bar-foodleap__content {
        display: flex;
        margin-left: 0;
        align-items: center;

        .static-top-bar-foodleap__buttons {
          margin-left: 0;
          text-align: right;

          .login_button {
            width: 90px;
          }
        }

        .static-logo-image {
          display: none;
        }
      }
      .static-top-bar-foodleap__navigation {
        margin-left: 0;
        text-align: left;
        padding-left: 0;

        ul {
          flex-direction: column;
          display: flex;
          margin: 20px;

          li {
            margin-bottom: 10px;
            cursor: pointer;
            font-size: 18px;
          }
        }
      }
    }
  }
</style>
