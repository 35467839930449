<template>
  <div class="simple-pagination" v-show="p.next||p.previous">
    <button type="button" class="button" v-if="p.previous" @click="setOffset(0)">First</button>
    <button type="button" class="button" v-if="p.previous" @click="setOffset(p.previous.offset)">Previous</button>
    <button type="button" class="button" v-if="p.next" @click="setOffset(p.next.offset)">Next</button>
    <button type="button" class="button" v-if="p.last" @click="setOffset(p.last.offset)">Last</button>
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  props: ['paging'],
  emits: ['change'],
  computed: {
    p () {
      return this.paging || {}
    },
  },
  methods: {
    setOffset (offset) {
      this.$emit('change', offset)
    }
  }
})
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";

.simple-pagination {
  line-height: 50px;
  font-size: .9em;
  border-top: 1px solid $color-borders;
  text-align: right;
  padding: 0 20px;
  background: $color-bg-bar;
  user-select: none;
}
.simple-pagination--bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 50px;
}
.has-pagination {
  margin-bottom: 49px!important;
  overflow-x: auto;
}
</style>
