<template>
  <modal :id="modalId"
    ref="modal"
    :title="action === 'edit' ? {spottingArea: spottingAreaLabel}: $t('spotting_areas_create_title', {spottingArea: spottingAreaLabel})"
    @close="close"
    is-simplified-modal
  >
    <!-- Modal header tabs -->
    <template v-slot:body>
      <div>
        <div class="community-edit__title-container">
          <div class="mandatory-message">
            <i>{{ $t('all_fields_with_asterisk_are_mandatory') }}</i>
          </div>

          <ds-input
            v-model="spottingarea.name"
            :placeholder="$t('spotting_areas_title_placeholder') + ' *'"
            :maxLength="30"
          />
          <p v-if="fieldErrors.name" style="display: inline-block; margin-right: 1em;" class="guide_text form-group__error">{{ fieldErrors.name[0] }}</p>
        </div>


        <div class="community-edit__content-container">
          <form-group label="Description *">
            <ds-textarea
              :placeholder="`Provide a description of your ${spottingAreaLabel}`"
              v-model="spottingarea.description"
              class="spottingarea__description"
            />
          </form-group>
          <br>
          <form-group label="Configure access">
            <label v-if="$store.getters.isSpottingAreaScopeEnabled" class="form-group__header">
              <div class="form-group__label__text">
                <Icon name="information-outline" size="18" style="vertical-align: sub;"/>
                Don't forget to configure the accessible spotting areas per role in the section 'Accessible data' on
                <router-link to="/settings/publisher">
                  the permissions
                </router-link>
              </div>
            </label>

            <radio-button
              :model-value="spottingarea.type"
              @update:modelValue="spottingarea.type = $event"
              name="type"
              label="Public (for visitors and ecosystem members: always visible & only available through permission-based access, portfolio members can explore)"
              val="public"
            />

            <!--<radio-button
              :model-value="spottingarea.type"
              @update:modelValue="spottingarea.type = $event"
              name="type"
              label="Restricted (for visitors and ecosystem members: only visible & available through permission-based access, portfolio members can contribute)"
              val="restricted"
            />-->

            <radio-button
              :model-value="spottingarea.type"
              @update:modelValue="spottingarea.type = $event"
              name="type"
              label="Premium (for portfolio members: invite only, not for visitors and ecosystem members)"
              val="premium"
            />

            <radio-button
              :model-value="spottingarea.type"
              @update:modelValue="spottingarea.type = $event"
              name="type"
              label="Private (only for owners and team members)"
              val="private"
            />
          </form-group>
          <!--<image-input v-model="community.originalImage"
                       @update:modelValue="community.image = $event"
                       width="510"
                       height="131.443298969"
                       :is-simplified="true"/-->
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div style="width: 100%;">
        <ds-button
          variant="primary"
          @click="submit()"
          :label="action === 'edit' ? $t('spotting_areas_save') : $t('spotting_areas_create')"
          size="extra-small"
          :disabled="!canSubmit"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import DsTextarea from '../Form/DsTextarea.vue'
  import ImageInput from '../Form/ImageInput.vue'
  import RichTextArea from '../TextArea/RichTextArea.vue'
  import RadioButton from '../Form/RadioButton.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'

  import { ManageSpottingAreas } from '../../api/spottingareas'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import { SPOTTING_AREA_TYPES } from '@/store/modules/config.js'

  export default {
    name: 'CreateSpottingAreaModal',
    data () {
      return {
        modalId: MODAL_IDS.CREATE_SPOTTING_AREA,
        spottingarea: {
          name: '',
          description: '',
          image: '',
          type: SPOTTING_AREA_TYPES.PREMIUM,
        },
        errors: {},
        fieldErrors: {},
        saving: false,
      }
    },
    computed: {
      action () {
        return this.spottingarea.id ? 'edit' : 'create'
      },
      canSubmit () {
        return this.spottingarea.name &&
          this.spottingarea.name.length > 2 &&
          this.spottingareaBodyWithoutHtml.length >= 10 &&
          !this.saving
      },
      modalContext () {
        return this.$store.state.ui.modalContext
      },
      spottingareaBodyWithoutHtml () {
        return (this.spottingarea.description && this.spottingarea.description.replace(/<\/?[^>]+(>|$)/g, '')) || ''
      },
    },
    methods: {
      submit () {
        this.saving = true
        this.fieldErrors = {}
        this.errors = null

        ManageSpottingAreas
          .post(this.spottingarea)
          .then(response => {
            setTimeout(() => {
              this.$bus.emit('spottingAreaCreated')

              this.success()
            }, 1000)
          })
          .catch(errors => {
            this.saving = false
            this.fieldErrors = errors
            this.errors = Object.keys(errors).filter(key => key !== 'statusCode')
          })
      },
      success () {
        this.saving = false

        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          message: this.action === 'edit' ? this.$t('spottingarea_edit_success') : this.$t('spottingarea_create_success', { spottingArea: this.spottingAreasLabel }),
        })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.SUCCESS)
      },
      close () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.CREATE_SPOTTING_AREA)
        this.$emit('close')
      },
    },
    mounted () {
      if (this.modalContext && this.modalContext.spottingarea) {
        this.spottingarea = { ...this.spottingarea, ...this.modalContext.spottingarea }
      }
    },
    mixins: [TranslationsMixin],
    components: {
      DsTextarea,
      RichTextArea,
      ImageInput,
      Modal,
      RadioButton,
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/_variables.scss";

  .community-edit__title-container {
    background: var(--primary-extra-lightest);
    padding: 20px;

    :deep(input) {
      border: 0;
      border-radius: $default-border-radius-narrow;

      &::placeholder {
        color: var(--primary);
      }
    }
  }

  .community-edit__content-container {
    padding: 20px 20px 17px 20px;

    :deep(input) {
      border: 1;
      border-radius: $default-border-radius-narrow;
      border-color: var(--primary-extra-lightest) !important;

      &::placeholder {
        color: var(--primary);
      }
    }
  }

  .modal__footer {
    width: 100%;

    .button {
      width: 100%;
      border-radius: $default-border-radius-narrow;
    }
  }

  .d-flex {
    display: flex;
  }

  .form-group {
    margin-bottom: 0px;
  }
</style>
