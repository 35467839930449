<template>
  <div class="main-wrapper component-publish-button">
    <ds-button class="main-button" variant="primary" icon="world" label="Published" size="extra-small" @click="handleClick" :class="{displayed: modelValue}" />
    <ds-button class="main-button" variant="outline" icon="world" label="Publish" size="extra-small" @click="handleClick" :class="{displayed: !modelValue}" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    modelValue: Boolean,
  },
  methods: {
    handleClick () {
      this.$emit('update:modelValue', !this.modelValue)
    },
  },
})
</script>

<style lang="scss" scoped>
.main-wrapper {
  display: inline-grid;
  margin-right: 10px;

  .main-button {
    grid-row: 1;
    grid-column: 1;
    margin-right: 0;
    visibility: hidden;
    width: 100%;
    text-align: left;

    &.displayed {
      visibility: visible;
    }

    &.button--outline:not(:hover) {
      background: rgba(255, 255, 255, 0.75);
    }

    &.button--outline:hover {
      background: rgba(0, 0, 0, 0.25);
    }
  }

  &:last-child {
    margin-right: 0;
  }
}
</style>
