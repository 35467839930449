<template>
  <with-configuration-tabs>
    <div class="fixed-heading">
      <div class="heading" style="display: flex; align-items: center;">
        <div>
          <h1>Actor Relationships</h1>
          <p class="guide_text">Manage actor relationships.</p>
        </div>
      </div>

      <div class="scrollable has-padding" style="padding-bottom: 150px;">
        <tabs :tabs="tabs" :save-to-url="true">
          <template v-slot:Business>
            <div>
              <manage-business-relationships/>
            </div>
          </template>

          <template v-slot:Data>
            <div>
              <manage-data-relationships/>
            </div>
          </template>
        </tabs>
      </div>
    </div>
  </with-configuration-tabs>
</template>

<script>
  import ActionBar from '../Form/ActionBar.vue'
  import FormGroup from '../Form/FormGroup.vue'
  import WithConfigurationTabs
    from '../../pages/WithConfigurationTabs/WithConfigurationTabs.vue'
  import Tabs from '../Tabs/Tabs.vue'
  import ManageBusinessRelationships
    from './Relationships/ManageBusinessRelationships.vue'
  import ManageDataRelationships from './Relationships/ManageDataRelationships.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Relationships',
    components: {
      ActionBar,
      FormGroup,
      WithConfigurationTabs,
      Tabs,
      ManageBusinessRelationships,
      ManageDataRelationships,
    },
    data () {
      return {}
    },
    methods: {
      //
    },
    computed: {
      tabs () {
        return ['Business', 'Data']
      },
    }
  })
</script>

<style lang="scss" scoped>
  :deep(.manage-relationships__list) {
    margin-left: 1rem;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;

    .icon {
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    .manage-relationships__list-item-name {
      min-width: 400px;
      display: flex;
      flex-direction: row;
      line-height: 22px;
      padding-left: 6px;

      &.hoverable {
        line-height: 22px;
        min-width: 100%;

        span {
          cursor: pointer;

          svg {
            display: none;
            padding-right: 3px;
          }

          &:hover {
            svg {
              display: inline-block;
            }
          }
        }

        .buttons {
          display: none;
          height: 15px;

          .icon {
            cursor: pointer
          }
        }

        &:hover, &.selected {
          background-color: #f3f3f3;

          .buttons {
            display: block;
          }
        }

      }
    }

    li {
      padding-left: 6px;
      line-height: 22px;

      span {
        cursor: pointer;

        svg {
          display: none;
          padding-right: 3px;
        }

        &:hover {
          svg {
            display: inline-block;
          }
        }
      }
    }

    ul {
      list-style-type: none
    }

  }

  :deep(.subtitle) {
    color: #aaa;
    font-size: 85%;
    margin-bottom: 10px;
  }
</style>
