<template>
  <with-profile-tabs :available-tabs="['upgrade', 'billing', 'invoices']" :title="$t('profile_settings_my_subscription')">
    <div class="profile fixed-heading">
      <div class="scrollable has-padding" v-if="displayUserInvoices">
        <user-invoices/>
      </div>
      <div class="scrollable has-padding" v-else>
        <div class="personal_subscription__section">
          <!-- Current plan overview -->
          <div class="personal_subscription__section" v-if="!displayPlanPicker">
            <h2 class="h2">Billing Overview</h2>
            <div v-if="loadingSubscriptionInfo">
              <icon name="spinner"/>
              Loading...
            </div>
            <div v-else>
              <div class="personal_subscription__plan-info">
                <div v-if="! subscriptionInfo.packageTitle">
                  <div class="personal_subscription__plan-info-item">
                    You are currently using a free version of the platform.
                    &nbsp;<ds-button label="Upgrade" size="extra-small" @click="changeDisplayPlanPicker(true)" :disabled="loadingSubscriptionInfo" v-if="canUpdatePlan"/>
                  </div>
                </div>
                <div v-else>
                  <div class="personal_subscription__plan-info-item">
                    You are subscribed to <span class="personal_subscription__plan-info-accent">&nbsp;{{ subscriptionInfo.packageTitle }}</span>

                    <div class="personal_subscription__plan-info-cta" v-if="!isOnGracePeriod">
                      <ds-button label="Change plan" size="extra-small" @click="changeDisplayPlanPicker(true)" :disabled="loadingSubscriptionInfo" v-if="canUpdatePlan"/>
                      <ds-button label="Cancel plan" size="extra-small" @click="askToCancelPlan()" :disabled="loadingSubscriptionInfo" v-if="canCancelPlan && canUpdatePlan"/>
                    </div>
                  </div>

                  <div class="personal_subscription__plan-info-item" v-if="isOnTrial">
                    You are currently on a trial period, which ends on&nbsp;<span class="personal_subscription__plan-info-accent">{{ subscriptionInfo.trialEndsAt }}</span>.
                    <div v-if="!defaultPaymentMethodLastFour">
                      &nbsp;In order to continue using our product after that date, please enter your credit card details below.
                    </div>
                  </div>

                  <div class="personal_subscription__plan-info-item personal_subscription__plan-info-accent" v-if="hasIncompletePayment && !isOnGracePeriod">
                    Your last payment was not completed or has failed. Please&nbsp;<a :href="subscriptionInfo.paymentRoute">click here</a>&nbsp;in order to complete it.
                  </div>

                  <template v-if="!isOnGracePeriod && !hasIncompletePayment">
                    <div class="personal_subscription__plan-info-item" v-if="defaultPaymentMethodLastFour">
                      Your subscription will renew on<span class="personal_subscription__plan-info-accent">&nbsp;{{ subscriptionInfo.packageRenewalDate }}</span>, charging your credit card <span class="personal_subscription__plan-info-accent">&nbsp;{{ nextChargeAmount }}</span>.
                    </div>
                    <div class="personal_subscription__plan-info-item" v-if="defaultPaymentMethodLastFour">
                      Future charges will be billed to the card&nbsp;<span class="personal_subscription__plan-info-accent">&bull;&bull;&bull;&bull;&nbsp;{{ defaultPaymentMethodLastFour }}</span>. You can update your payment info in the section here below.
                    </div>
                    <div class="personal_subscription__plan-info-item" v-else-if="!isOnTrial">
                      In order to update your subscription add your payment info in the section here below.
                    </div>
                    <div class="personal_subscription__plan-info-item">
                      Billing emails will be sent to&nbsp;<span class="personal_subscription__plan-info-accent">{{userEmail}}</span>.
                    </div>
                  </template>
                  <template v-else-if="!hasIncompletePayment">
                    <div class="personal_subscription__grace-period">
                      <div>
                        You have cancelled your subscription, your access will end on {{subscriptionInfo.subscriptionEndsAt}}. <br/>
                      </div>
                      <div>
                        <ds-button label="Resume subscription" size="small" @click="resumePlan()" :disabled="loadingSubscriptionInfo"/>
                      </div>
                    </div>
                  </template>

                </div>
              </div>
            </div>
          </div>

          <div v-if="displayPlanPicker">
            <package-picker :current-plan="currentPlan" :is-on-trial="isOnTrial" @closePlanPicker="changeDisplayPlanPicker(false)" @choseNewPlan="fetchSubscriptionInfo()"/>
            <div style="display: inline-flex" v-if="!loadingPaymentInfo">
              <span style="display: flex; align-items: center;">Powered by</span>
              <a href="https://stripe.com/privacy-center/legal" target="_blank">
                <icon name="stripe" style="width: 50px; height: 50px; top: 0.2em;"/>
              </a>
            </div>
          </div>
        </div>

        <!-- Payment method overview -->
        <div class="personal_subscription__section" v-if="!displayPlanPicker">
          <user-payment-method :loading="loadingPaymentInfo" :paymentMethods="paymentMethods" @updatePaymentInfo="fetchUserPaymentInfo()"/>
        </div>

        <!-- Billing information overview -->
        <div class="personal_subscription__section" v-if="!displayPlanPicker">
          <user-billing-information @updateBillingInfo="updateUserBillingInfo" :updating-billing-info="updatingBillingInfo"/>
          <div style="display: inline-flex">
            <span style="display: flex; align-items: center;">Powered by</span>
            <a href="https://stripe.com/privacy-center/legal" target="_blank">
              <icon name="stripe" style="width: 50px; height: 50px; top: 0.2em;"/>
            </a>
          </div>
        </div>
      </div>
    </div>
  </with-profile-tabs>
</template>

<script>
  import UserPaymentMethod from './Subscription/UserPaymentMethod.vue'
  import PackagePicker from './Subscription/PackagePicker.vue'
  import PersonalSubscriptionInfo from './Subscription/PersonalSubscriptionInfo.vue'
  import UserBillingInformation from './Subscription/UserBillingInformation.vue'

  import {
    cancelSubscription,
    getPaymentInfo,
    resumeSubscription,
    updateBillingInfo,
  } from '../../api/user'
  import { ACTION_TYPES as USER_ACTION_TYPES } from '../../store/modules/user'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'

  import { trackHeapEvent } from '../../util/analytics.js'
  import WithProfileTabs from '../../pages/WithConfigurationTabs/WithProfileTabs.vue'
  import UserInvoices from './Subscription/UserInvoices.vue'

  export default {
    name: 'PersonalSubscription.vue',
    data () {
      return {
        updatingBillingInfo: false,
        paymentMethods: [],
        loadingSubscriptionInfo: false,
        loadingPaymentInfo: false,
        loadingBillingInfo: false,
      }
    },
    computed: {
      displayPlanPicker () {
        return this.$route.path === '/profile/subscription' && (this.$route.hash === '#upgrade' || !this.$route.hash)
      },
      displayUserInvoices () {
        return this.$route.hash === '#invoices'
      },
      subscriptionInfo () {
        return this.$store.state.user.profile.subscriptionInfo || {}
      },
      userEmail () {
        return this.$store.getters.userEmail
      },
      subscriptionTitle () {
        if (!this.currentPlan) {
          return 'Please select a package before continuing'
        }
        return 'Billing'
      },
      nextChargeAmount () {
        if (!this.subscriptionInfo.packagePrice) {
          return '€0'
        }

        return '€' + this.subscriptionInfo.packagePrice
      },
      defaultPaymentMethodLastFour () {
        if (this.paymentMethods.length === 0) {
          return
        }

        var defaultMethod = {}

        this.paymentMethods.forEach(paymentMethod => {
          if (paymentMethod.is_default) {
            defaultMethod = paymentMethod
          }
        })

        return defaultMethod.card_last4
      },
      hasIncompletePayment () {
        return this.subscriptionInfo && this.subscriptionInfo.hasIncompletePayment
      },
      currentPlan () {
        if (!this.subscriptionInfo) {
          return
        }

        // If the subscription is not active and there's no outstanding payment (i.e. incomplete or failed) then we assume it's cancelled or ended, so there's no current plan
        if (!this.subscriptionInfo.subscriptionActive && !this.hasIncompletePayment) {
          return
        }

        return this.subscriptionInfo && this.subscriptionInfo.package
      },
      canUpdatePlan () {
        return !this.hasIncompletePayment && !this.isOnGracePeriod && this.paymentMethods.length > 0
      },
      canCancelPlan () {
        if (!this.subscriptionInfo) {
          return false
        }

        return this.subscriptionInfo.subscriptionActive && !this.isOnGracePeriod
      },
      isOnGracePeriod () {
        if (!this.subscriptionInfo) {
          return false
        }

        return this.subscriptionInfo.subscriptionOnGracePeriod
      },
      isOnTrial () {
        if (!this.subscriptionInfo) {
          return false
        }

        return this.subscriptionInfo.isOnTrial
      }
    },
    methods: {
      updateUserBillingInfo (billingInfo) {
        this.updatingBillingInfo = true

        updateBillingInfo(billingInfo)
          .then(response => {
            this.$bus.emit('billingInfoUpdated')
            this.updatingBillingInfo = false
          })
          .catch(e => {
            console.log(e)
            this.updatingBillingInfo = false
          })
      },
      changeDisplayPlanPicker (value) {
        if (value === true) {
          trackHeapEvent('profileBillingPage.showPlanPicker')
          return this.$router.push('/profile/subscription#upgrade')
        }
        this.$router.push('/profile/subscription#billing')
      },
      fetchSubscriptionInfo () {
        this.loadingSubscriptionInfo = true

        this.$store.dispatch(USER_ACTION_TYPES.FETCH_PACKAGE_SUBSCRIPTION)
          .catch(e => {
            console.log(e)
          })
          .finally(() => {
            this.loadingSubscriptionInfo = false
          })
      },
      resumePlan () {
        // Re-use the "loading" flag, the same UI components need to be disabled when making this call as when loading new user info
        this.loadingSubscriptionInfo = true

        resumeSubscription()
          .then(response => {
            // Re-use the "loading" flag, the same UI components need to be disabled when making this call as when loading new user info
            this.loadingSubscriptionInfo = false
            this.fetchSubscriptionInfo()
          })
          .catch(e => {
            // Re-use the "loading" flag, the same UI components need to be disabled when making this call as when loading new user info
            this.loadingSubscriptionInfo = false

            console.log(e)
          })
      },
      askToCancelPlan () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          title: 'Subscription',
          body: 'Are you sure you want to cancel your subscription?',
          package: this.subscriptionInfo.package,
          confirmLabel: 'Yes, cancel my subscription',
          cancelLabel: 'No',
          isCancellable: true,
        })

        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CONFIRMATION)
      },
      cancelPlan () {
        // Re-use the "loading" flag, the same UI components need to be disabled when making this call as when loading new user info
        this.loadingSubscriptionInfo = true

        cancelSubscription()
          .then(response => {
            this.loadingSubscriptionInfo = false
            this.fetchSubscriptionInfo()
          })
          .catch(e => {
            this.loadingSubscriptionInfo = false
            console.log(e)
          })
      },
      removeStatusParameters () {
        // Remove any subscription related parameters inserted by Cashier routes
        const query = Object.assign({}, this.$router.query)
        delete query.message
        delete query.success
        this.$router.replace({ query })
      },
      fetchUserPaymentInfo () {
        this.loadingPaymentInfo = true

        getPaymentInfo()
          .then(response => {
            this.paymentMethods = response.payment_methods
            this.loadingPaymentInfo = false
          })
          .catch(error => {
            this.loadingPaymentInfo = false
            console.log(error)
          })
      },
    },
    mounted () {
      this.$store.dispatch(USER_ACTION_TYPES.FETCH_BILLING_INFO)
      this.fetchUserPaymentInfo()
      this.removeStatusParameters()

      this.$bus.on('confirmAction', (context) => {
        if (context.package === this.subscriptionInfo.package) {
          this.cancelPlan()
        }
      })
    },
    beforeUnmount () {
      this.$bus.off('confirmAction')
    },
    components: {
      PersonalSubscriptionInfo,
      WithProfileTabs,
      UserPaymentMethod,
      PackagePicker,
      UserInvoices,
      UserBillingInformation,
    },
  }
</script>

<style scoped lang="scss">
  .personal_subscription__section {
    margin-bottom: 1rem;
  }

  .personal_subscription__grace-period {
    display: flex;
    flex-direction: column;
  }

  .personal_subscription__plan-info {
    display: flex;
    flex-direction: column;
    margin-top: 1em;
  }

  .personal_subscription__plan-info-item {
    display: flex;
    flex-direction: row;
    margin-top: 0.4em;

    .button {
      line-height: 16px;
    }
  }

  .personal_subscription__plan-info-cta {
    margin-left: 1em;
    margin-top: -0.1em;
  }

  .personal_subscription__plan-info-accent {
    font-weight: 500;
  }

</style>
