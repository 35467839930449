<template>
  <div>
    <form-group label="Actor edit" v-if="role === 'member'">
      <checkbox label="Allow team members to edit any actor" v-model="form.allowEdit"/>
    </form-group>

    <form-group label="Directories" v-if="role !== 'portfolio_member' && form.viewActorTypes">
      <checkbox model-value="LegalEntity" label="Organization" inline disabled val="LegalEntity"/>
      <checkbox v-model="form.viewActorTypes" inline multiple val="Person"
        v-if="$parent.form.viewActorTypes.includes('Person')"/>
      <checkbox v-model="form.viewActorTypes" inline multiple val="Product" :label="productLabel"
        v-if="$parent.form.viewActorTypes.includes('Product')"/>
    </form-group>

    <template v-if="this.$store.getters.areChallengesEnabled && role === 'portfolio_member'">
      <h2 class="h2">{{ challengesLabel }}</h2>

      <checkbox v-model="form.canCreateChallenge" :label="`Can create ${challengeLabel}`" :val="true"/>
      <checkbox v-model="form.canCreatePrivateChallenge" :label="`Can choose to make their ${challengesLabel} private`" :val="true" :disabled="!form.canCreateChallenge"/>
      <checkbox v-model="form.challengeMatchmakingEnabled" :label="`Allow them to use matchmaking for their ${challengesLabel}`" :val="true" :disabled="!form.canCreateChallenge"
        v-if="isDeveloper"/>
      <br/>
      <form-group :label="`View ${challengesLabel}, except for the detail pages`">
        <checkbox v-model="form.viewableChallengeStatusses" inline multiple val="new"/>
        <checkbox v-model="form.viewableChallengeStatusses" inline multiple val="open"/>
        <checkbox v-model="form.viewableChallengeStatusses" inline multiple val="solved"/>
      </form-group>

      <form-group label="View challenge details">
        <checkbox v-model="form.accessibleChallengeStatusses" inline multiple val="new"/>
        <checkbox v-model="form.accessibleChallengeStatusses" inline multiple val="open"/>
        <checkbox v-model="form.accessibleChallengeStatusses" inline multiple val="solved"/>
      </form-group>
    </template>

    <h2 class="h2" style="margin-bottom: 0px;">{{ announcementsAndEventsLabel }}</h2>
    <form-group label="">
      <checkbox v-model="canViewPublicAnnouncements" :label="`Can view public ${announcementsAndEventsLabel}`" inline :disabled="!areMessageBoardsEnabled"/>
    </form-group>
    <br/>

    <form-group label="Directory views">
      <checkbox v-model="form.viewList" label="Show the list page"/>
      <checkbox v-model="form.viewGallery" label="Show the gallery page"/>
      <checkbox v-model="form.viewMap" label="Show the map page"/>
    </form-group>

    <template v-if="this.$store.getters.hasAccessToMonitoring">
      <h2 class="h2" style="margin-bottom: 0px;">Content</h2>
      <form-group label="Choose which type of articles content can be viewed">
        <CuratedContentSelection v-model="form.monitoringContentMode"/>
      </form-group>
    </template>

    <form-group label="Edit Reporting" v-if="role === 'portfolio_member'">
      <checkbox label="Allow portfolio members to edit a report." v-model="form.allowReportEdit"/>
    </form-group>

    <form-group label="List view columns" v-if="displayListViewColumnSelection">
      <dropdown v-model="form.viewList" multiple :options="viewListOptions"/>
    </form-group>
    <form-group label="Enabled dashboards">
      <dropdown multiple v-model="form.viewDashboard" :options="viewDashboardOptions"/>
    </form-group>

    <h2 class="h2">Directory settings</h2>

    <form-group label="Legend property">
      <dropdown v-model="form.defaultLegendProperty" :options="legendPropertyOptions"/>
    </form-group>

    <form-group :label="form.defaultLegendProperty">
      <dropdown multiple v-model="form.availableLegendItems[form.defaultLegendProperty]"
        :options="getLegendOptions(form.defaultLegendProperty,  form)"/>
    </form-group>

    <form-group label="Available filter controls for actors">
      <dropdown v-model="form.filterControls" :options="availableFilterControlOptionsActor" multiple/>
    </form-group>

    <template v-if="form.viewActorTypes && form.viewActorTypes.includes('Product')">
      <h2 class="h2">{{ productLabel }} Gallery settings</h2>

      <form-group label="Legend property">
        <dropdown v-model="productLegendProperty" :options="legendPropertyOptions"/>
      </form-group>

      <form-group :label="productLegendProperty" v-if="productLegendProperty">
        <dropdown multiple v-model="availableProductLegendItems"
          :options="getLegendOptions(productLegendProperty, form)"/>
      </form-group>
      <form-group label="Available filter controls for products">
        <dropdown v-model="form.filterControlsProducts" :options="availableFilterControlOptionsProduct" multiple/>
      </form-group>
    </template>

    <h3>Accessible data</h3>
    <form-group label="Configure what actors can be accessed by selecting one or more portfolios">
      <AutocompleteTagInput
        :disabled="role === 'member'"
        :tags="selectedPortfolios"
        :options="portfolioOptions"
        :addOnlyFromAutocomplete="true"
        :placeholder="role === 'member' ? 'Team members can access all portfolios, no need to configure' : 'Search for a portfolio'"
        @tagChanged="updatePortfolioOptions"
        @input:raw="updateSelectedPortfolios"
        :minInputLength="1"
        style="width: 440px;"
      />
    </form-group>

    <div class="row" v-if="canDataPropertyAccessBeManaged">
      <div class="col-xs-12 col-sm-6">
        <h4>Hidden data</h4>
        <p>
          <small>This user role does <b>NOT</b> have access to the following.</small>
        </p>
        <div class="property-group" v-for="g in privatePropertyGroups" @click.prevent="pushPublic(g.properties)">
          <div>
            {{ g.label }}
          </div>
          <div class="property-group__list">
            {{ g.properties.join(', ') }}
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6">
        <h4>Visible data</h4>
        <p>
          <small>This user role has access to the following.</small>
        </p>
        <h2 v-if="!form.public_fields || !form.public_fields.length">Click on a datapoint on the left to make it visible
          for visitors.</h2>
        <div class="property-group" v-for="g in publicPropertyGroups"
          :class="{ 'property-group--disabled': g.label === 'Minimum' }" @click.prevent="splicePublic(g.properties)">
          <div>
            {{ g.label }}
            <small v-if="g.label === 'Minimum'">must be published</small>
          </div>
          <div class="property-group__list">
            {{ g.properties.join(', ') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import flatten from 'lodash/flatten'
  import toArray from 'lodash/toArray'
  import difference from 'lodash/difference'

  import Checkbox from '../Form/Checkbox.vue'
  import Dropdown from '../Dropdown/Dropdown.vue'
  import DsInput from '../Form/DsInput.vue'
  import DsTextarea from '../Form/DsTextarea.vue'
  import FormGroup from '../Form/FormGroup.vue'
  import TagInput from '../Form/TagInput.vue'
  import AutocompleteTagInput from '../Form/AutocompleteTagInput.vue'
  import CountryDropdown from '../Dropdown/CountryDropdown.vue'

  import { propertyGroups as rawPropertyGroups, viewDashboardOptions, viewListOptions } from '../../constants/config'
  import { inert } from '../../util/helpers'
  import ConfigMixin from '../../util/ConfigMixin'
  import CuratedContentSelection from './Permissions/CuratedContentSelection.vue'
  import PermissionsMixin from '../../util/PermissionsMixin'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import debounce from 'lodash/throttle'
  import { fetchPortfolio } from '../../api/portfolios'

  export default {
    props: {
      form: null,
      role: String,
      selectedPortfolios: Array,
    },
    data () {
      return {
        portfolioOptions: [],
      }
    },
    computed: {
      displayListViewColumnSelection () {
        return !['member', 'portfolio_member'].includes(this.role)
      },
      canDataPropertyAccessBeManaged () {
        return this.role !== 'member' && this.role !== 'portfolio_member'
      },
      isPublic () {
        return this.$store.getters.isPublic
      },
      availableFilterControlOptionsProduct () {
        var eligibleFilterControlOptions = [...this.filterControlOptionsProduct]

        eligibleFilterControlOptions.push({ value: 'reports', label: 'Reports' })

        return eligibleFilterControlOptions
      },
      availableFilterControlOptionsActor () {
        var eligibleFilterControlOptions = [...this.filterControlOptionsActor]

        eligibleFilterControlOptions.push({ value: 'reports', label: 'Reports' })

        return eligibleFilterControlOptions
      },
      isDeveloper () {
        return this.$store.getters.isDeveloper
      },
      membershipOptions () {
        return this.membershipLabels || []
      },
      fillableFields () {
        return flatten(toArray(this.propertyGroups))
      },
      propertyGroups () {
        const propertyGroups = rawPropertyGroups

        return propertyGroups
      },
      canCreateChallenge: {
        get () {
          return this.form.canCreateChallenge || false
        },
        set (value) {
          if (!this.form.canCreateChallenge) {
            this.form.canCreateChallenge = false
          }

          this.form.canCreateChallenge = value
        },
      },
      productLegendProperty: {
        get () {
          return this.form.productLegendProperty || null
        },
        set (value) {
          if (!this.form.productLegendProperty) {
            this.form.productLegendProperty = ''
          }
          this.form.productLegendProperty = value
        },
      },
      availableProductLegendItems: {
        get () {
          if (this.form.availableLegendItems) {
            return this.form.availableLegendItems[this.productLegendProperty] || []
          }
          return []
        },
        set (value) {
          if (!this.form.availableLegendItems) {
            this.form.availableLegendItems = {}
          }

          if (!this.form.availableLegendItems[this.productLegendProperty]) {
            this.form.availableLegendItems[this.productLegendProperty] = []
          }

          this.form.availableLegendItems[this.productLegendProperty] = value
        },
      },
      canCreatePrivateChallenge: {
        get () {
          return this.form.canCreatePrivateChallenge || false
        },
        set (value) {
          if (!this.form.canCreatePrivateChallenge) {
            this.form['canCreatePrivateChallenge'] = false
          }

          this.form.canCreatePrivateChallenge = value
        },
      },
      challengeMatchmakingEnabled: {
        get () {
          return this.form.challengeMatchmakingEnabled || false
        },
        set (value) {
          if (!this.form.challengeMatchmakingEnabled) {
            this.form['challengeMatchmakingEnabled'] = false
          }

          this.form.challengeMatchmakingEnabled = value
        },
      },
      accessibleChallengeStatusses: {
        get () {
          return this.form.accessibleChallengeStatusses || []
        },
        set (value) {
          if (!this.form.accessibleChallengeStatusses) {
            this.form['accessibleChallengeStatusses'] = []
          }

          this.form.accessibleChallengeStatusses = value
        },
      },
      viewableChallengeStatusses: {
        get () {
          return this.form.viewableChallengeStatusses || []
        },
        set (value) {
          if (!this.form.viewableChallengeStatusses) {
            this.form['viewableChallengeStatusses'] = []
          }

          this.form.viewableChallengeStatusses = value
        },
      },
      canViewPublicAnnouncements: {
        get () {
          if (!this.form.hasOwnProperty('canViewPublicAnnouncements')) {
            this.form['canViewPublicAnnouncements'] = true
          }

          return !!this.form.canViewPublicAnnouncements
        },
        set (v) {
          this.form.canViewPublicAnnouncements = v
        },
      },
      errors () {
        const errorMap = {}
        const pub = this.form
        if (pub && pub.viewList && !pub.viewGallery && !pub.viewMap) {
          errorMap['publisher.viewMap'] = ['You must enable at least 1 view.']
        }

        errorMap.count = Object.keys(errorMap).length
        return errorMap
      },
      viewDashboardOptions () {
        let options = this.$parent.form.viewDashboard && viewDashboardOptions.filter(d => this.$parent.form.viewDashboard.includes(d.value)) || []
        // var options = this.$parent.$parent.form.viewDashboard && viewDashboardOptions.filter(d => this.$parent.$parent.form.viewDashboard.includes(d.value)) || []

        // Filter out old options
        options = options.filter(option => !['message_dashboard'].includes(option.value))

        if (this.role === 'member' && (this.$store.getters.hasAccessToExploration || this.$store.getters.hasAccessToMonitoring)) {
          options.push({ value: 'explore-content', icon: 'buzz', label: 'Exploration' })
          options.push({ value: 'media-buzz', icon: 'buzz', label: 'Media Buzz' })
        }

        if (['portfolio_member'].includes(this.role)) {
          options = options.filter(d => !['media-buzz', 'explore-content'].includes(d.value))
        }

        if (this.hasAccessToNewsOverview) {
          options.push({ value: 'news-overview', icon: 'buzz', label: 'News Overview' })
        }

        return options
      },
      hasAccessToNewsOverview () {
        return this.$store.getters.isUmicore ||
          ['carboncapture-monitoring', 'cas-monitoring', 'local', 'devo', 'colruyt-farma', 'martech', 'startup-ecosystem', 'ecosystems2030', 'competitive-intelligence'].includes(this.$store.getters.ecosystemName.toLowerCase())
      },
      areMessageBoardsEnabled () {
        return this.$store.getters.areMessageBoardsEnabled
      },
      viewListOptions () {
        if (!this.publicFields.includes('completeness')) {
          return viewListOptions.filter(opt => opt !== 'completeness')
        }

        return viewListOptions
      },
      publicFields () {
        if (this.form && this.form.public_fields) {
          return this.form.public_fields
        }
        return []
      },
      privateFields () {
        if (this.form && this.form.public_fields) {
          return difference(this.fillableFields, this.publicFields)
        }

        return this.fillableFields
      },
      publicPropertyGroups () {
        return this.filterPropertyGroups(property => this.publicFields.indexOf(property) !== -1)
      },
      privatePropertyGroups () {
        return this.filterPropertyGroups(property => this.privateFields.indexOf(property) !== -1)
      },
    },
    methods: {
      updatePortfolioOptions: debounce(async function (query) {
        if (!query) {
          return
        }

        // This will search for a portfolio which matches the input value in the search field
        var filters = Object.assign({}, { query: query })

        fetchPortfolio(filters)
          .then(portfolios => {
            this.portfolioOptions = []

            this.parsePortfolioOptions(portfolios)
          }).catch(errors => {
            console.log(errors)
          })
      }, 150),
      parsePortfolioOptions (portfolios) {
        if (!Array.isArray(portfolios)) {
          return
        }

        var options = []

        portfolios.forEach(portfolio => {
          if (portfolio.virtual) {
            return
          }

          options.push({
            text: portfolio.name,
            id: portfolio.id,
          })
        })

        this.portfolioOptions = options
      },
      updateSelectedPortfolios (input) {
        this.$emit('updatePortfolios', input)
      },
      handleDefaultCountry (country) {
        if (!country.code) {
          return
        }

        this.form['signUpDefaultCountry'] = country.code
      },
      addConfig (toggler, prop, value) {
        this.form[toggler] = true
        this.form[prop] = (value == null ? null : inert(value))
      },
      removeConfig (toggler, prop) {
        // Set to undefined
        this.form[prop] = undefined

        // Set to false
        if (toggler) {
          this.form[toggler] = false
        }
      },
      // Returns a mapped and filtered array of property groups
      filterPropertyGroups (func) {
        return Object.keys(this.propertyGroups)
          .filter(p => p !== 'Competitive Scores' || this.$parent.$parent.$parent.form.hasScores)
          .map(label => ({
            label,
            properties: this.propertyGroups[label].filter(func),
          })).filter(g => g.properties && g.properties.length)
      },
      // @param 1 or an array of properties
      pushPublic (f) {
        if (!this.form.public_fields) {
          this.form['public_fields'] = []
        }
        if (Array.isArray(f)) {
          f.forEach(this.pushPublic)
          return
        }
        if (this.form.public_fields.indexOf(f) < 0) {
          this.form.public_fields.push(f)
        }
      },
      // @param 1 or an array of properties
      splicePublic (f) {
        if (Array.isArray(f)) {
          f.forEach(this.splicePublic)
          return
        }
        if (this.form.public_fields.indexOf(f) >= 0) {
          this.form.public_fields.splice(this.form.public_fields.indexOf(f), 1)
        }
      },
    },
    watch: {
      canCreateChallenge (value) {
        if (!value) {
          // Can't create a private challenge if you lack the basic "can create a challenge"
          this.form.canCreatePrivateChallenge = false
          this.form.challengeMatchmakingEnabled = false
        }
      },
    },
    components: {
      CuratedContentSelection,
      AutocompleteTagInput,
      Checkbox,
      CountryDropdown,
      Dropdown,
      DsInput,
      DsTextarea,
      FormGroup,
      TagInput,
    },
    mixins: [ConfigMixin, PermissionsMixin, TranslationsMixin],
  }
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

  /* Select 2 custom css */
  .select2-results__option[aria-selected=true] {
    display: none;
  }

  .property-group__list {
    font-size: 12px;
    opacity: .5;
  }

  .property-group {
    margin-top: -1px;
    border: 1px solid $color-borders;
    padding: .5em 1em;
    cursor: pointer;

    &:hover {
      position: relative;
      z-index: 1;
      border: 1px solid;
    }
  }

  .property-group--disabled {
    pointer-events: none;
    color: #666;
    background: #eee;

    > a {
      color: #999;
    }

    small {
      float: right;
      font-size: 12px;
      line-height: 20px;
    }
  }
</style>
