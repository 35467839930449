<template>
  <div :style="{ height: type === 'IMAGE' && data.fillAvailableHeight ? '100%' : 'auto', minHeight: type === 'IMAGE' && data.fillAvailableHeight ? '100px' : 'none' }" style="margin-bottom: 1em;">
    <template v-if="type === 'IMAGE'">
      <div :style="imageStyles"></div>
    </template>
    <template v-else-if="type === 'TWEET'">
      <CardNews :item="data" />
    </template>

    <template v-else-if="type === 'NEWS'">
      <NewCard class="card__general" :showAccentColor="true" :accentColor="accentColor">
        <div class="side-panel__telex-container">
          <div class="detail-profile-content-block__header">
            <ds-button
              v-if="data.id"
              :to="'/actors/' + data.id"
              :label="data.actorName"
              icon="chevron-right"
              variant="secondary"
              class="side-panel__actor-name__news__telex"
              size="extra-small"
            />
            <ds-button
              v-else
              :href="data.url"
              :label="data.actorName"
              icon="chevron-right"
              variant="secondary"
              class="side-panel__actor-name__news__telex"
              size="extra-small"
            />
            <ds-button
              v-if="articleDetailLink"
              :to="articleDetailLink"
              icon="view-graph"
              class="detail-profile-content-block__lab"
              size="extra-small"
            />
            <h5 class="detail-profile-content-block__date">{{toDateString(data.date)}}</h5>
          </div>
          <div>
            <a :href="data.url" target="_blank" class="side-panel__news-telex-container">
              <div class="side-panel__news-section__article-info-conatiner__telex">
                <section class="side-panel__news-section__image__telex" v-if="newsImageStyle(data)">
                  <div class="side-panel__news-image__telex" :style="newsImageStyle(data)"></div>
                </section>
                <section class="side-panel__news-section__article-info__telex">
                  <h6 class="side-panel__section-title__telex">News</h6>
                  <h5 class="side-panel__text__news__telex">{{data.title}}</h5>
                </section>
              </div>
            </a>
          </div>
        </div>
      </NewCard>
    </template>
    <template v-else-if="type === 'SIGNIFICANT_CHANGE'">
      <NewCard title="Significant Change" :showAccentColor="true" :accentColor="accentColor">
        <p>{{significantChangeMessage}}</p>
        <template v-slot:headerRight v-if="data.date">
          <em><Date :date="data.date" format="D MMM"/></em>
        </template>
      </NewCard>
    </template>
  </div>
</template>

<script lang="ts">
  import NewCard from '../../components/NewCard/NewCard.vue'
  import CardNews from '../../components/Card/CardNews.vue'

  import { toDateString } from '../../util/date.js'
  import ResourceMixin from '../../util/ResourceMixin.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'ActorDetailProfileMainContentBlock',
    props: {
      type: String,
      accentColor: String,
      data: Object,
    },
    mixins: [ResourceMixin],
    components: {
      NewCard,
      CardNews,
    },
    computed: {
      articleDetailLink () {
        if (this.data.file_id && (this.$store.getters.hasAccessToExploration || this.$store.getters.hasAccessToMonitoring)) {
          this.data.sql_media_id = this.data.file_id

          return this.internalPageUrl(this.data)
        }
      },
      imageStyles () {
        return {
          width: '100%',
          height: this.data.fillAvailableHeight ? '100%' : '100vh',
          maxHeight: this.data.fillAvailableHeight ? 'none' : '260px',
          backgroundImage: `url(${this.data.url})`,
          backgroundSize: 'cover',
        }
      },
      significantChangeMessage () {
        var message = this.data.message

        if (
          (this.data.changeType == 'twitter_metrics' || this.data.changeType == 'facebook_metrics' || this.data.changeType == 'web_metrics') &&
        this.data.meta && this.data.meta.length > 0
        ) {
          message += ' (' + this.data.meta + ')'
        }

        return message
      },
    },
    methods: {
      toDateString,
      title (item) {
        if (!item.title) {
          return
        }

        const titleLength = 60

        if (item.title.length < titleLength) {
          return item.title
        }

        return item.title.substring(0, titleLength - 3) + '...'
      },
      text (item) {
        if (!item.text) {
          return
        }

        const textLength = 250

        if (item.text.length < textLength) {
          return item.text
        }

        return item.text.substring(0, textLength - 3) + '...'
      },
      newsImageStyle (item) {
        if (!item.screenshot && !item.logo) {
          return
        }

        if (item.screenshot) {
          return { backgroundImage: `url(${item.screenshot})` }
        }

        if (item.logo) {
          return { backgroundImage: `url(${item.logo})` }
        }
      },
      customImageStyle (item, imageProperty) {
        if (!item[imageProperty]) {
          return
        }

        return { backgroundImage: `url(${item[imageProperty]})` }
      },
    }
  })
</script>

<style lang="scss" scoped>
  @import "../../../scss/_variables.scss";

  .detail-profile-content-block__header {
    display: flex;
    flex-wrap: wrap;
  }

  .detail-profile-content-block__date {
    margin-left: auto;
    order: 2;
  }

  .detail-profile-content-block__lab {
    line-height: unset !important;
    height: 24px !important;
  }
</style>
