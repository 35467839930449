<template>
  <div class="intelligence-detail knowledge-base">
    <basic-simplified-hero :title="$t('intelligence_simplified_title')"
                           :subtitle="$t('intelligence_simplified_subtitle')"></basic-simplified-hero>
    <Container style="flex-grow: 1; max-width: 100%">
      <h2 class="h2">{{ $t('shp_latest_articles').toUpperCase() }}</h2>
      <div ref="scrollable">
        <div class="">
          <div class="">
            <div class="simplified-concept-search__wrapper" v-if="conceptSearch && conceptSearch.title">
              <div class="concept-search__wrapper"
                   :style="conceptSearchStyle(conceptSearch)">
                <div class="concept-map-image__title-and-description">
                  <h2 class="scope-description h2" v-if="conceptSearch.title">
                    {{ truncate(conceptSearch.title, 30) }}</h2>
                </div>
              </div>
              <div class="concept-search__status-container">
                <div>
                  <p class="status last-run"
                     v-if="conceptSearch.status !== 'in_progress'">
                    {{ reportTypeLabel }}, last run: {{ lastRun }}
                  </p>
                  <p class="status" v-else>
                    {{ reportTypeLabel }}, searching in progress...
                  </p>
                  <div class="concept-search__scope-description">{{ conceptSearch.scope_description }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-if="errors.length">
          <p> {{ errors }} </p>
        </template>
        <template v-else>
          <div>
            <div style="display: flex">
              <div class="like-filter" :key="componentKey">
                <span @click="commonFilters.isLikedByUser = !commonFilters.isLikedByUser; componentKey++">Show only&nbsp;
                <icon :name="commonFilters.isLikedByUser ? 'thumbs-up' : 'thumbs-up-outline'"/>
                </span>
              </div>
            </div>
            <div style="padding-top: 20px" v-if="fetching || !knowledgeBaseItems.length">
              <p v-if="fetching">{{ $t('loading') }}</p>
              <p v-if="!fetching && !knowledgeBaseItems.length">No results found.</p>
            </div>
            <AnnouncementOrResourceCard
                v-for="item in knowledgeBaseItems"
                :key="'item-' + item.id"
                :data="item"
                :preview-mode="false"
                :link="'/dashboards/knowledge-base/'"
                @addTag="addTagToFilters"
                @like-article="likeArticle"
                @resourceDeleted="resetArticles"
            />
          </div>
          <br>
          <br>
          <br>
          <br>
        </template>
      </div>
    </Container>
  </div>
</template>

<script>
import _throttle from 'lodash/throttle'
import { fetchKnowledgeBaseData, likeArticle } from '../../api/knowledge-base'

import KnowledgeBaseItem from '../../components/KnowledgeBase/KnowledgeBaseItem.vue'

import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
import { MUTATION_TYPES as KNOWLEDGE_BASE_MUTATION_TYPES } from '../../store/modules/knowledgeBase'
import MODAL_IDS from '../../constants/modal-ids'

import AnnouncementOrResourceCard from '../../components/Simplified/AnnouncementOrResourceCard.vue'
import BasicSimplifiedHero from '../../components/Simplified/BasicSimplifiedHero.vue'
import Container from '../../components/Container/Container.vue'
import { REPORT_TYPES } from '../../store/modules/conceptSearchGuide.js'
import moment from 'moment'
import { truncate } from '../../util/helpers.js'
import { trackHeapEvent } from '../../util/analytics.js'
import { ActionTypes } from '../../store/modules/concept-searches.js'

export default {
  name: 'intelligence-detail',
  data() {
    return {
      errors: {},
      fetching: false,
      endOfFeed: false,
      fetchLimit: 100,
      filters: [],
      commonFilters: {
        isLikedByUser: false,
      },
      contentFilters: {
        geoTags: [],
        excludedTags: [],
        newsGroups: [],
        lexicons: []
      },
      knowledgeBaseItems: [],
      collapsedTableSections: {},
      mediaTypeFacets: [],
      taxonomyFacets: [],
      actorFacets: [],
      sourceFacets: [],
      resourceToDelete: {},
      componentKey: 1,
    }
  },
  computed: {
    conceptSearch() {
      return this.$store.state.conceptSearches.detail.data
    },
    lastRun() {
      return moment(this.conceptSearch.explored_at).format('DD\u2011MM\u2011YYYY\xa0/\xa0HH:mm')
    },
    reportTypeLabel() {
      const reportType = REPORT_TYPES.find(t => t.id === this.conceptSearch.report_type)

      if (reportType) {
        return reportType.name
      }
      return 'Custom report'
    },
    isOwner() {
      return this.$store.getters.isOwner
    },
    isMember() {
      return this.$store.getters.isMember
    },
    isDeveloper() {
      return this.$store.getters.isDeveloper
    },
    isPortfolioMember() {
      return this.$store.getters.isPortfolioMember
    },
    contentTagFilter() {
      // Combination of geo tags + include tags
      var tagIds = []

      if (this.contentFilters.geoTags.length > 0) {
        this.contentFilters.geoTags.forEach(tag => {
          if (tag.optionValue && tag.optionValue.value) {
            tagIds.push(tag.optionValue.value)
          }
        })
      }

      return tagIds
    },
    contentExcludeTags() {
      var tagIds = []

      if (this.contentFilters.excludedTags.length > 0) {
        this.contentFilters.excludedTags.forEach(tag => {
          if (tag.optionValue && tag.optionValue.value) {
            tagIds.push(tag.optionValue.value)
          }
        })
      }

      return tagIds
    },
    contentLexicons() {
      if (this.contentFilters.lexicons.length === 0) {
        return []
      }

      var lexiconIds = []

      this.contentFilters.lexicons.forEach(lexicon => {
        if (lexicon.value) {
          lexiconIds.push(lexicon.value)
        } else {
          lexiconIds.push(lexicon)
        }
      })

      return lexiconIds
    },
  },
  methods: {
    trackHeapEvent,
    likeArticle({ resourceId, status }) {
      likeArticle(resourceId, status)
    },
    addTagToFilters(tag) {
      if (!tag.value) {
        return
      }

      this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.CLEAR)
      this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.SET_FACET_KEYWORD, tag)

      if (this.$route.name && this.$route.name.indexOf('knowledge-base') <= 0) {
        this.$router.push('/dashboards/knowledge-base')
      }
    },
    setDefaultFilters() {
      if (!this.conceptSearch) {
        return
      }

      const tmpContentFilters = { ...this.contentFilters }

      // Reset the filters to be the filters of the concept search: geo, exclude
      tmpContentFilters.excludedTags = this.conceptSearch.excludeTags ? this.conceptSearch.excludeTags.map(tag => this.transformTagToFilterValue(tag)) : []
      tmpContentFilters.geoTags = this.conceptSearch.geographyTags ? this.conceptSearch.geographyTags.map(tag => this.transformTagToFilterValue(tag)) : []
      tmpContentFilters.newsGroups = this.conceptSearch.newsGroups ? this.conceptSearch.newsGroups.map(newsGroup => {
        return { value: newsGroup.id, label: newsGroup.name }
      }) : []

      tmpContentFilters.lexicons = this.conceptSearch.contextValues ? this.conceptSearch.contextValues.map(context => {
        return {
          value: context.id,
          label: context.value
        }
      }) : []

      this.contentFilters = tmpContentFilters
    },
    transformTagToFilterValue(tag) {
      return {
        text: tag.label,
        optionValue: {
          value: tag.id,
          text: tag.label
        }
      }
    },
    conceptSearchStyle(conceptSearch) {
      if (!this.conceptSearch || !this.conceptSearch.image) {
        return
      }

      return 'background-image: url(' + conceptSearch.image + '); min-width: 300px;'
    },
    truncate,
    fetch(shouldReset) {
      if (this.fetching || (this.endOfFeed && !shouldReset)) {
        return
      }

      this.fetching = true

      const formattedFilters = {
        limit: this.fetchLimit,
        offset: shouldReset ? 0 : this.knowledgeBaseItems.length,
        tags: this.contentTagFilter,
        exclude_tags: this.contentExcludeTags
      }

      if (this.commonFilters.isLikedByUser) {
        formattedFilters['likedByUserOnly'] = true
      }

      formattedFilters['concept-search-id'] = this.$route.params.id
      formattedFilters['ml-supported'] = true
      formattedFilters['sortBy'] = 'date'

      formattedFilters['news_groups'] = this.contentFilters.newsGroups ? this.contentFilters.newsGroups.map(ng => ng.value ? ng.value : ng) : null
      formattedFilters['lexicons'] = this.conceptSearch.context // This part isn't interacteable for the end-user on this page, so we can just take it as a default value

      // Fetch fetch knowledge base data
      fetchKnowledgeBaseData(formattedFilters)
          .then(({ data }) => {
            this.endOfFeed = data.results.length < this.fetchLimit
            this.processContentTypeFacets(data.facets) // Process content type facet values
            this.processTaxonomyFacets(data.facets) // Process taxonomy facet values
            this.processActorFacets(data.facets)
            this.processSourceFacets(data.facets)

            const items = data.results.map(item => {
              if (item.media_type === 'rss' && item.sql_media_id) {
                item.detail_link = '/files/' + item.sql_media_id
              }

              return item
            })

            this.knowledgeBaseItems = shouldReset ? items : this.knowledgeBaseItems.concat(items)
          })
          .catch(() => {
            this.error = 'Oops! Something went wrong while fetching data.'
            this.endOfFeed = true
          })
          .finally(() => {
            this.fetching = false
          })
    },
    processContentTypeFacets(mediaTypeData) {
      const mediaTypesArray = Object.entries(mediaTypeData.media_type_counts)
      const mediaTypesData = []

      mediaTypesArray.forEach(mediaType => {
        mediaTypesData.push({
          facet: 'media_types',
          value: mediaType[0],
          counter: mediaType[1],
        })
      })

      this.mediaTypeFacets = mediaTypesData
    },
    processTaxonomyFacets(facets) {
      this.taxonomyFacets = facets.tag_count
    },
    processActorFacets(facets) {
      var topActors = facets.top_actors || []

      // Reset the top actor counts
      this.actorFacets = []

      topActors.forEach(item => {
        this.actorFacets.push({
          facet: 'actor',
          label: item.name,
          value: item.id,
          count: item.count,
        })
      })
    },
    processSourceFacets(facets) {
      var topSources = facets.top_sources || []

      // Reset the top actor counts
      this.sourceFacets = []

      topSources.forEach(item => {
        this.sourceFacets.push({
          facet: 'source',
          label: item.display_name,
          value: item.id,
          count: item.count,
        })
      })
    },
    hidePreview() {
      this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
    },
    showEditResourcePanel(resource) {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
        'component': 'edit-resource-panel',
        metaData: { resource: resource },
      })
    },
    resetArticles() {
      this.endOfFeed = false
      this.fetch(true)
      this.hidePreview()
    },
    showDeleteResourceConfirmationModal(resource) {
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
        body: 'Do you want to delete this resource?',
        resource: resource,
        modalContextType: 'file',
      })
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
    },
    showAnnouncementConfirmationModal(announcement) {
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
        body: 'Do you want to delete this announcement?',
        modalContextType: 'announcement',
        announcement: announcement,
      })
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
    },
    openActorSidePanel(actor) {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
        component: 'scores',
        metaData: actor.actor_id,
      })
    },
    openAnnouncementEditSidePanel(announcement) {
      // We need the "id" property to edit the resource.
      var copyOfAnnouncemnt = Object.assign({}, announcement, { id: announcement.sql_media_id })
      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
        component: 'edit-announcement-panel',
        metaData: copyOfAnnouncemnt,
      })
    },
    scrollKnowledgeBase: _throttle(function () {
      const elem = this.$refs.scrollable
      if (elem && elem.offsetHeight + elem.scrollTop > 0.95 * elem.scrollHeight - 1500) {
        this.fetch()
      }
    }, 1000),
  },
  async created() {
    await this.$store.dispatch(ActionTypes.FETCH_CONCEPT_SEARCH_DETAIL, this.$route.params.id)

    this.setDefaultFilters()
    this.fetch()
  },
  async mounted() {
    this.$refs.scrollable.addEventListener('scroll', this.scrollKnowledgeBase, { passive: true })
  },
  components: {
    AnnouncementOrResourceCard,
    BasicSimplifiedHero,
    Container,
    KnowledgeBaseItem,
  },
  watch: {
    commonFilters: {
      deep: true,
      handler() {
        this.knowledgeBaseItems = []
        this.fetch(true)
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

.intelligence-detail {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden auto;

  .like-filter {
    color: $color-text-grey-light;
    margin: 20px 15px 0 auto;
    cursor: pointer;
  }

  :deep(.article-preview__media-container) {
    max-width: 100%;
  }

  .concept-search__wrapper {
    display: flex;
    cursor: default;

    .concept-map-image__title-and-description {
      margin-top: auto;
    }
  }

  .search__wrapper__wrapper {
    display: flex;
  }

  .concept-search__status-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding-left: 20px;
  }

  .simplified-concept-search__wrapper {
    display: flex;

    @media (max-width: $screen-md) {
      flex-direction: column;
    }
  }

  .concept-search__scope-description {
    @media (max-width: $screen-md) {
      display: none;
    }
  }

  .last-run {
    color: $color-text-grey-light;
  }
}
</style>
