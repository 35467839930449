/* Localstorage helper */

if (!window.localStorage) {
  window.localStorage = {}
}

export default function ls (key, value) {
  try {
    if (typeof key === 'undefined') {
      return window.localStorage
    }
    if (typeof value === 'undefined') {
      return window.localStorage[key] && JSON.parse(window.localStorage[key])
    }
    window.localStorage[key] = JSON.stringify(value)
  } catch (e) {
    console.warn('LocalStorage error', e)
  }
}

export function lsDefault (key, value) {
  if (!key || typeof value === 'undefined') {
    return console.warn('lsDefault: key & value expected')
  }
  if (!ls(key)) {
    ls(key, value)
  }
}

export function lsClear () {
  if (typeof window.localStorage.clear !== "undefined") {
    window.localStorage.clear()
  } else {
    for (var key in window.localStorage) {
      if (window.localStorage.hasOwnProperty(key)) {
        window.localStorage[key] = ''
      }
    }
  }
}