<template>
  <div>
    <h3 class="h2">Configure your tracking scripts</h3>
    <div class="row">
      <form-group class="col-xs-12"
                  v-for="(script, index) in trackingScripts"
                  :key="index" style="display: flex">
        <ds-input v-model="script.url"/>
        <ds-button icon="trash" @click="trackingScripts.splice(index, 1)" style="margin-left: 5px"/>
      </form-group>
      <form-group class="col-xs-12">
        <ds-button @click="trackingScripts.push({url: ''})" label="Add new script"/>
      </form-group>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Tracking.vue',
    data () {
      return {
      }
    },
    computed: {
      trackingScripts () {
        return this.$parent.$parent.$parent.form.tracking_scripts
      },
    },
    created () {
      if (!this.$parent.$parent.$parent.form.tracking_scripts) {
        this.$parent.$parent.$parent.form.tracking_scripts = []
      }
    },
    components: {
    }
  }
</script>

<style scoped>

</style>
