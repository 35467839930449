<template>
  <div class="community-card">
    <div class="community-card__content">
      <div class="community-card__header">
        <div class="community-card__header-title" @click="goToCommunity">
          {{ community.name }}
        </div>

        <div class="community-header-cta">
          <ds-button
            v-if="isOwner && isCreator"
            variant="rounded-active"
            size="extra-small"
            :label="$t('community_edit')"
            @click="openCreateCommunityModal()"
          />
          <ds-button
            v-if="isOwner"
            variant="rounded-active"
            size="extra-small"
            :label="$t('communities_manage_members')"
            @click="openManageCommunityMembersModal()"
          />
        </div>
      </div>

      <div v-if="canStartConversation && community.members && community.members.length > 0" class="community-card__members">
        <AvatarList
          :members="community.members"
          :label="'Start conversation with '"
        />
      </div>

      <div class="community-card__description">
        <div v-html="community.description">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import AvatarList from '../../components/Avatar/AvatarList.vue'
  import { MUTATION_TYPES as NOTIFICATION_MUTATION_TYPES } from '../../store/modules/notifications'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'
  import { ConversationsMixin } from '../../util/ConversationsMixin'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      community: {
        type: Object,
      },
    },
    data () {
      return {}
    },
    computed: {
      isOwner () {
        return this.$store.getters.isOwner
      },
      isCreator () {
        return this.community.created_by === this.$store.getters.userId
      },
      canStartConversation () {
        if (!this.hasAccessToConversations) {
          return false
        }

        return this.$store.getters.canActorsBeContacted || this.isOwner
      }
    },
    methods: {
      openCreateCommunityModal () {
        const modalContext = {
          communityId: this.community.id,
          community: this.community,
        }

        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, modalContext)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CREATE_COMMUNITY)
      },
      openManageCommunityMembersModal () {
        const modalContext = {
          communityId: this.community.id
        }

        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, modalContext)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.MANAGE_COMMUNITY_MEMBERS)
      },
      goToCommunity () {
        this.$router.push('/communities/' + this.community.id)
      }
    },
    mixins: [ConversationsMixin],
    components: {
      AvatarList,
    }
  })
</script>

<style lang="scss" scoped>
</style>
