<template>
  <div>
    <h2 class="h2">Encourage your users to Subscribe to a paid package.</h2>
    <table class="table">
      <tr>
        <th style="width: 100px">Enabled</th>
        <th style="width: 100px">Type</th>
        <th>Explanation</th>
        <th>Text (please enclose the upgrade link text in "{}", if applicable)</th>
      </tr>
      <tr v-for="(cta, index) in options" :key="'cta' + index">
        <td>
          <checkbox style="margin-top: 10px" :model-value="isCtaEnabled(cta.name)" @update:modelValue="toggleCta(cta.name)"/>
        </td>
        <td>{{ cta.label }}</td>
        <td>{{ cta.explanation }}</td>
        <td><ds-input :model-value="getCtaText(cta.name)" @update:modelValue="setCtaText(cta.name, $event)" :disabled="!isCtaEnabled(cta.name)" :placeholder="cta.placeholder" /></td>
      </tr>
    </table>
  </div>
</template>

<script>
  import Checkbox from '../../Form/Checkbox.vue'
  import TranslationsMixin from '../../../util/TranslationsMixin.js'

  export default {
    name: 'CtaConfig',
    props: ['form'],
    data () {
      return {
        options: [
          // this option is disabled for now as the use case is unclear
          // { name: 'actor', label: 'Actor', placeholder: 'Please {upgrade} your subscription to view this actor', explanation: 'Displayed when an actor detail is not accessible' },
          {
            name: 'product',
            label: this.productLabel,
            placeholder: this.$t('default_product_upgrade_cta', { product: this.productLabel }),
            explanation: `Displayed when the user is not allowed to create a new ${this.productLabel} (plus button, global search)`,
          },
          {
            name: 'challenge',
            label: this.challengeLabel,
            placeholder: 'Please {upgrade} your subscription to view the content',
            explanation: this.$t('default_challenge_upgrade_cta', { challenge: this.challengeLabel }),
          },
          { name: 'intelligence', label: 'Intelligence', placeholder: this.$t('default_intelligence_upgrade_cta'), explanation: 'show a locked intelligence + cta on homepage and top bar' },
        ],
      }
    },
    methods: {
      isCtaEnabled (cta) {
        return this.form.packageCTAs && this.form.packageCTAs[cta] && this.form.packageCTAs[cta].enabled
      },
      getCtaText (cta) {
        if (this.form.packageCTAs && this.form.packageCTAs[cta]) {
          return this.form.packageCTAs[cta].text
        }
        return ''
      },
      toggleCta (cta) {
        const updatedCTA = {
          ...this.form.packageCTAs,
          [cta]: { name: cta, enabled: !this.isCtaEnabled(cta), text: this.getCtaText(cta) },
        }
        this.form.packageCTAs = updatedCTA
      },
      setCtaText (cta, text) {
        const updatedCTA = {
          ...this.form.packageCTAs,
          [cta]: { name: cta, enabled: this.isCtaEnabled(cta), text: text },
        }
        this.form.packageCTAs = updatedCTA
      }
    },
    mixins: [TranslationsMixin],
    components: {
      Checkbox,
    }
  }
</script>

<style scoped>
  td label.radio:last-child {
    margin-bottom: 10px;
  }

</style>
