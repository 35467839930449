<template>
  <div
    class="colored-container actor-detail__connections"
    v-if="(userCanEdit || connections.length)"
  >
    <h2 class="h2">{{ $t('actor_detail_connections') }}
      <div class="title-count">
        {{ connections.length }}
      </div>
      <template v-if="userCanEdit">
        <ds-button
          icon="arrow-expand" :label="$t('actor_edit_connections')"
          variant="rounded" @click="openConnectionsSidepanel"
          size="small"
        />
      </template>
    </h2>
    <Carousel
      v-if="displayableConnectionsWithAddButtons.length"
      :class="{'carousel--minicards': true, 'has-navigation': (displayableConnectionsWithAddButtons.length > Math.min(displayableConnectionsWithAddButtons.length, connectionCardsPerCarouselPage))}"
      :items-to-show="5"
      :mouse-drag="true"
      style="width: calc(100% + 64px)"
    >
      <template
        v-for="(connection, index) in displayableConnectionsWithAddButtons"
        :key="'connection' + index"
      >
        <Slide>
          <WithHoverActions>
            <div
              v-if="connection.type && connection.type === 'add-more'"
              :class="{'disabled': !userCanEdit}"
              class="connection__add-more"
              @click="userCanEdit ? openConnectionsSidepanel : ''"
            >+
            </div>
            <simplified-image-card
              :actor="actor"
              :connection="connection"
              :is-connection="true"
              :subject-name="connection.name"
              :sub-title="connection.name"
              :image="connection.logo"
              :link="'/actors/' + connection.id"
              v-else
              style="height: 100%"
              @click="trackHeapEvent('actorDetailSimplified.clickConnection')"
            />
          </WithHoverActions>
        </Slide>
      </template>
      <template #addons>
        <Navigation
          v-if="displayableConnectionsWithAddButtons.length > connectionCardsPerCarouselPage"
        />
      </template>
    </Carousel>
    <div v-else-if="userCanEdit">
      {{ $t('actor_add_connection') }}
    </div>
  </div>
</template>
<script>
  import { defineComponent } from 'vue'
  import WithHoverActions from '@/components/WithHoverActions/WithHoverActions.vue'
  import SimplifiedImageCard from '@/components/Connection/SimplifiedImageCard.vue'
  import { trackHeapEvent } from '@/util/analytics'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '@/store/modules/ui'
  import { Carousel, Navigation, Slide } from 'vue3-carousel'

  export default defineComponent({
    name: 'SimplifiedActorDetailConnections',
    methods: {
      trackHeapEvent,
      openConnectionsSidepanel () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          component: 'actor-connections-panel',
        })
      },
    },
    components: { SimplifiedImageCard, WithHoverActions, Slide, Carousel, Navigation },
    props: {
      userCanEdit: {
        type: Boolean,
        default: false,
        required: true,
      },
      connections: {
        type: Array,
        default: [],
        required: true,
      },
      actor: {
        type: Object,
        required: true,
      },
      cardsPerCarouselPage: {
        type: Number,
        required: true,
      }
    },
    computed: {
      displayableConnectionsWithAddButtons () {
        const result = [...this.connections]
        if (this.connections.length < this.connectionCardsPerCarouselPage) {
          for (let i = 0; i < this.connectionCardsPerCarouselPage - this.connections.length; i++) {
            result.push({ type: 'add-more' })
          }
        }
        return result
      },
      connectionCardsPerCarouselPage () {
        return this.cardsPerCarouselPage
      },
    },
  })
</script>
<style lang="scss" scoped>
</style>
