<template>
  <div>
    <div class="fixed-heading">
      <div class="card__general side-panel__header">
        <div class="card__title side-panel__portfolio-card__title side-panel__portfolio-header-card__title">Portfolio Settings</div>
        <div class="pull-right side-panel__portfolio-header-button-container" v-if="portfolio.id">
          <ds-button
            icon="star" variant="minimal" size="small" @click="toggleFavourite" class="side-panel__portfolio-header-icon side-panel__portfolio-favourited"
            v-if="portfolio.favourited"
          />
          <ds-button icon="star-outline" variant="minimal" size="small" @click="toggleFavourite" class="side-panel__portfolio-header-icon" v-else/>
          <ds-button
            icon="trash" variant="minimal" @click="remove" size="small" class="side-panel__portfolio-header-icon"
            v-if="(isOwner || isTeamMember || isPortfolioOwner) && ! portfolio.is_immutable && ! portfolio.published"
          />
          <!--<template v-if="(isOwner || isTeamMember || isPortfolioOwner) && ! portfolio.is_immutable">
              <ds-button icon="edit" variant="minimal" @click="changeEditState" size="small" class="side-panel__portfolio-header-icon side-panel__portfolio-edit-enabled" v-if="editState == true" />
              <ds-button icon="edit" variant="minimal" @click="changeEditState" size="small" class="side-panel__portfolio-header-icon" v-else/>
          </template>-->
          <ds-button icon="remove" variant="minimal" @click="hidePreview" size="small" class="side-panel__portfolio-header-icon"/>
          <div v-if="errors.delete">{{ errors.delete.join(' ') }}</div>
        </div>
      </div>
    </div>
    <tabs :tabs="enabledTabs" class="side-panel-portfolio__tab-section"></tabs>
    <div class="scrollable side-panel__scrollable__content" ref="scrollable">
      <div class="has-padding" style="margin-bottom:260px;" v-if="!loading">
        <template v-if="activeTab == 'Description'">
          <div class="row side-panel__portfolio-actor-details-coontainer" v-if="editState == true">
            <div class="col-xs-5">
              <form-group label="Image" instructions=".png or .jpg &middot; &plusmn;320&times;160" :errors="errors.image">
                <image-input v-model="portfolio.image" width="320" height="160"/>
              </form-group>
            </div>
            <div class="col-xs-7">
              <form-group label="Portfolio name" :errors="errors.name">
                <ds-input v-model="portfolio.name"/>
              </form-group>
            </div>
          </div>
          <form-group class="row has-padding side-panel__portfolio-section" label="Description" :errors="errors.description" v-if="editState == true">
            <ds-textarea placeholder="Enter a description" v-model="portfolio.description"/>
          </form-group>
          <form-group class="row has-padding side-panel__portfolio-section side-panel__portfolio-description-container" :errors="errors.description" v-else>
            <ds-button icon="reporting" label="Description" :disabled="true" variant="minimal" class="side-panel__portfolio-info-section-item-icon"/>
            <span class="side-panel__portfolio-info-section-description"> {{ portfolio.description }} </span>
          </form-group>
          <table class="row has-padding side-panel__portfolio-section">
            <tbody class="side-panel__portfolio-info-section">
            <tr class="side-panel__portfolio-info-section-item" v-if="(portfolio.tags && portfolio.tags.length > 0) || editState == true">
              <td>
                <ds-button icon="notification-label" label="Tags" :disabled="true" variant="minimal" class="side-panel__portfolio-info-section-item-icon"/>
              </td>
              <td style="width: 100%;">
                <form-group :errors="errors.tags" class="side-panel__portfolio-info-section-item-input">
                  <tag-input v-model="portfolio.tags" placeholder="Add a tag..." v-if="editState == true"/>
                  <div class="side-panel__portfolio-filters-textarea" v-else>
                    <keyword v-for="(tag, index) in portfolio.tags" :key="index" :label="tag" style="width: auto;"/>
                  </div>
                </form-group>
              </td>
            </tr>
            <tr class="side-panel__portfolio-info-section-item">
              <td>
                <ds-button icon="edit" label="Created by" :disabled="true" variant="minimal" class="side-panel__portfolio-info-section-item-icon"/>
              </td>
              <td>
                <avatar :src="portfolio.creatorLogo" :alt="portfolio.creatorName"/>
                <span>{{ portfolio.creatorName }}</span>
              </td>
            </tr>
            <tr class="side-panel__portfolio-info-section-item">
              <td>
                <ds-button icon="clock" label="Creation Date" :disabled="true" variant="minimal" class="side-panel__portfolio-info-section-item-icon"/>
              </td>
              <td>
                <span>{{ toDateStringSlashed(portfolio.created_at) }}</span>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="row has-padding side-panel__portfolio-section" style="padding-top: 1em">
            <ds-button icon="comment" :disabled="true" variant="minimal" class="side-panel__portfolio-info-section-item-icon"/>
            <ds-textarea placeholder="Write a comment..." v-model="newComment" @keyup.enter="addComment" class="side-panel__portfolio-comments-textarea"/>
            <div class="side-panel__portfolio-comments-container">
              <div v-for="comment in comments" class="side-panel__portfolio-comment">
                <div class="side-panel__portfolio-comment-user-avatar">
                  <avatar class="newcomment__avatar" :src="comment.user_photo_url" :alt="comment.user_name"/>
                </div>
                <div class="side-panel__portfolio-comment-body">
                  <div class="side-panel__portfolio-comment-user-name">
                    {{ comment.user_name }}
                    <time class="comment__time">{{ timeAgo(comment.created_at.date) }}</time>
                    <div class="side-panel__portfolio-comment-controlers-container">
                      <ds-button
                        icon="trash" variant="minimal" @click="removeComment(comment)" size="small" class="side-panel__portfolio-header-icon"
                        v-if="isOwner || isTeamMember || isPortfolioOwner || canEditComment(comment.user_id)"
                      />
                      <ds-button
                        icon="edit" variant="minimal" @click="toggleCommentEdit(comment)" size="small" class="side-panel__portfolio-header-icon"
                        v-if="canEditComment(comment.user_id)"
                      />
                    </div>
                  </div>
                  <ds-textarea
                    placeholder="Add a comment" v-model="editComment.updatedComment" @keyup.enter="updateComment(comment)"
                    v-if="editComment.state == true && editComment.commentId == comment.id"
                  />
                  <div class="side-panel__portfolio-comment-text" v-else>
                    {{ comment.comment }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="side-panel__portfolio-activity-log-container-fixed-bottom" v-if="! editState && ! isContributor">
            <li :title="activityLog.comment" class="side-panel__portfolio-activity-log" v-for="activityLog in activityLogs"><span>{{ activityLog.comment }}</span>
              <time class="comment__time">{{ timeAgo(activityLog.updated_at) }}</time>
            </li>
          </div>
          <div class="side-panel__portfolio-activity-log-container" v-else>
            <li :title="activityLog.comment" class="side-panel__portfolio-activity-log" v-for="activityLog in activityLogs"><span>{{ activityLog.comment }}</span>
              <time class="comment__time">{{ timeAgo(activityLog.updated_at) }}</time>
            </li>
          </div>
        </template>


        <template v-if="activeTab === 'Sharing'">
          <!--          <div class="row has-padding side-panel__portfolio-section">
                      <div class="side-panel__portfolio-permissions-section">
                        <div class="side-panel__portfolio-permissions-items side-panel__portfolio-sharing-permissions" style="margin-bottom:1em" v-if="editState == true">
                          <span class="side-panel__portfolio-sharing-permissions-title">VISIBILITY</span>
                          <p>
                            <radio-button
                              v-model="portfolio.private"
                              name="share-option"
                              label="Private"
                              :val="true"
                            />
                            <span class="side-panel__portfolio-sharing-permissions-guide-text">(can be viewed and updated by you and ecosystem owners)</span>
                          </p>
                          <p>
                            <radio-button
                              v-model="portfolio.private"
                              name="share-option"
                              label="Shared"
                              :val="false"
                            />
                            <span class="side-panel__portfolio-sharing-permissions-guide-text">(can be viewed and updated by you, ecosystem owners and your team members)</span>
                          </p>
                        </div>
                        <div class="side-panel__portfolio-permissions-items" style="margin-bottom:1em" v-else>
                          <span> <icon name="bookmark"/> VISIBILITY</span>
                          <h5>
                            {{ portfolio.private == true ? 'Private' : 'Shared' }}
                          </h5>
                        </div>
                        <div class="side-panel__portfolio-permissions-items" style="margin-bottom:1em" v-if="editState == true && (isOwner || isMember) && hasAccessToPortfolioSharing">
                          <span>PUBLISH PORTFOLIO</span>
                          <div>
                            <input
                              type="checkbox" name="publish-option" class="side-panel__portfolio-permissions-publish-option" :checked="canPublish || publishState"
                              @click="changePublishState(publishState)"
                            >
                            <h5>Publish this portfolio in order to provide invited portfolio members access to explore and contribute to actors within this portfolio. </h5>
                          </div>
                        </div>
                        <div class="side-panel__portfolio-permissions-items" style="margin-bottom:1em" v-if="(isOwner || isMember) && hasAccessToPortfolioSharing">
                          <span>OVERVIEW OF WHO HAS ACCESS TO THIS PORTFOLIO</span>
                        </div>
                        <div class="side-panel__portfolio-permissions-items" v-if="(isOwner || isMember) && hasAccessToPortfolioSharing">
                          <div class="side-panel__portfolio-permissions-items-tilte-container">
                            <span> <icon name="edit"/>  TO CONTRIBUTE <small> i.e. view the directory of actors and their profiles,  add and exclude actors from the portfolio, update actor profiles, use the portfolio for benchmarking with other portfolios</small></span>
                            <span>{{ errors.name }}</span>
                          </div>
                          <div>
                            <form-group>
                              <div class="side-panel__portfolio-permissions-items-keyword-container">
                                <template v-if="owners.length > 0">
                                  <avatar-keyword v-for="(owner, index) in owners" :key="'a' + index" :src="owner.photo_url" :alt="owner.name" :label="owner.name"/>
                                </template>
                                <template v-if="teamMembers.length > 0 && portfolio.private == false">
                                  <avatar-keyword v-for="(teamMember, index) in teamMembers" :key="'b' + index" :src="teamMember.photo_url" :alt="teamMember.name" :label="teamMember.name"/>
                                </template>
                                <template v-if="editState == true && publishState == true">
                                  <avatar-keyword
                                    v-for="(user, index) in members.contributors" :key="'c' + index" removeable :userData="user" :label="user.name" @remove="removeContributor(user)"
                                  />
                                </template>
                                <template v-else>
                                  <avatar-keyword v-for="(user, index) in members.contributors" :key="'d' + index" :userData="user" :label="user.name"/>
                                </template>
                              </div>
                              <suggestion-input
                                @actor="addContributor"
                                icon="search"
                                placeholder="Add user"
                                :api-settings="userPortfolioMembersSuggestion"
                                :clear-on-select="true"
                                v-if="editState == true && publishState == true"
                              />
                            </form-group>
                          </div>
                        </div>
                        <div class="side-panel__portfolio-permissions-items" v-if="isOwner && hasAccessToPortfolioSharing">
                          <div class="side-panel__portfolio-permissions-items-tilte-container">
                            <span> <icon
                              name="share"
                            /> TO EXPLORE <small> i.e. view the directory of actors and their profiles and use the portfolio for benchmarking with other portfolios</small></span>
                            <span>{{ errors.name }}</span>
                          </div>
                          <div>
                            <form-group>
                              <div class="side-panel__portfolio-permissions-items-keyword-container">
                                <template v-if="editState == true && publishState == true">
                                  <avatar-keyword v-for="(user, index) in members.viewers" :key="'a' + index" removeable :userData="user" :label="user.name" @remove="removeViewer(user)"/>
                                </template>
                                <template v-else>
                                  <avatar-keyword v-for="(user, index) in members.viewers" :key="'b' + index" :userData="user" :label="user.name"/>
                                </template>
                              </div>
                              <suggestion-input
                                @actor="addViewer"
                                icon="search"
                                placeholder="Add user"
                                :api-settings="userPortfolioMembersSuggestion"
                                :clear-on-select="true"
                                v-if="editState == true && publishState == true"
                              />
                            </form-group>
                          </div>
                        </div>
                        <div class="side-panel__portfolio-permissions-items" v-if="isOwner && hasAccessToPortfolioSharing">
                          <div class="side-panel__portfolio-permissions-items-tilte-container">
                            <span> <icon name="share"/>  TO BENCHMARK <small> i.e. only use the portfolio for benchmarking with other portfolios</small></span>
                            <span>{{ errors.name }}</span>
                          </div>
                          <div>
                            <form-group>
                              <div class="side-panel__portfolio-permissions-items-keyword-container">
                                <template v-if="editState == true && publishState == true">
                                  <avatar-keyword
                                    v-for="(user, index) in members.benchmarkers" :key="'a' + index" removeable :userData="user" :label="user.name" @remove="removeBenchmarker(user)"
                                  />
                                </template>
                                <template v-else>
                                  <avatar-keyword v-for="(user, index) in members.benchmarkers" :key="'b' + index" :userData="user" :label="user.name"/>
                                </template>
                              </div>
                              <suggestion-input
                                @actor="addBenchmarker"
                                icon="search"
                                placeholder="Add user"
                                :api-settings="userPortfolioMembersSuggestion"
                                :clear-on-select="true"
                                v-if="editState == true && publishState == true"
                              />
                            </form-group>
                          </div>
                        </div>
                      </div>
                    </div>-->
          <div v-if="displayLinkedTokens" style="margin-top: 10px;">
            Provide access to actors in this portfolio to 3rd party clients. Clients can access actors by using ID: <span style="font-style: italic;">{{ this.id }}</span>
            <dropdown
              v-model="linkedApiTokens"
              :options="availableApiTokens"
              multiple
            />
          </div>
          <div v-else>
            Create an api token in order to configure it here. <a class="portfolio__api-token-button" @click="$router.push('/settings/integrations#api-tokens')">Click here to
            create a new token</a>
          </div>
        </template>

        <template v-if="activeTab.includes('Actors')">
          <template v-if="! portfolio.is_immutable">
            <div class="row has-padding side-panel__portfolio-section side-panel__portfolio-filters">
              <div class="side-panel__portfolio-clear-filters-section">
                <h4 class="side-panel__section-title">Portfolio Member Information</h4>
                <p class="guide_text" style="margin-bottom: 1em">The portfolio is based on the following filters, which can be changed via the directory - list view.</p>
                <div class="side-panel__portfolio-filters-textarea">
                  <template v-for="(keyword, index) in keywords">
                    <keyword-with-facet
                      :key="index"
                      v-if="keyword.value !== '-' && keyword.facet !== 'legendProperty'"
                      :label="keyword.value"
                      :model-value="keyword"
                    />
                  </template>
                </div>
              </div>
              <div class="form-group__error" v-if="errors.filters">{{ errors.filters.join(' ') }}</div>
            </div>
            <form-group
              class="row has-padding side-panel__portfolio-section side-panel__portfolio-add-actors-input" label="Add an Actor"
              v-if="isOwner || isTeamMember || isPortfolioOwner || isContributor"
            >
              <suggestion-input
                @actor="includeActor"
                @update:modelValue="actorAddedState = false"
                icon="search"
                placeholder="Search an actor"
                :api-settings="suggestionSettings"
                :clear-on-select="true"
              />
              <div class="form-group__instructions" style="float:left;font-size: 11px;" v-if="actorAddedState">Actor included.</div>
            </form-group>

            <form-group
              :label="lockSectionTitle" class="row has-padding side-panel__portfolio-section side-panel__portfolio-locking-container"
              v-if="isOwner || isTeamMember || isPortfolioOwner"
            >
              <ds-button icon="padlock" size="small" variant="secondary" @click="toggleLockState" v-if="portfolio.locked == '1'"/>
              <ds-button icon="padlock-unlocked" size="small" variant="secondary" @click="toggleLockState" v-else/>
              <span class="side-panel__portfolio-locking-text">By locking the portfolio, you fix the list of actors linked to this portfolio.</span>
            </form-group>
          </template>

          <template v-if="isOwner || isTeamMember">
            <form-group label="Data enrichment" class="side-panel__portfolio-locking-container" style="margin-bottom: 0px;">
              <div class="row has-padding side-panel__portfolio-section side-panel__portfolio-filters">
                <div class="side-panel__portfolio-clear-filters-section">
                  <ds-button variant="secondary" label="Enrich actors in this portfolio" @click="enrichPortfolio" v-if="! this.hasEnrichingStarted"/>
                  <label v-else>We have started to enrich this portfolio, updates on the actor profiles will follow soon.</label>
                </div>
              </div>
            </form-group>

            <form-group label="Delete the portfolio and its actors from your ecosystem" class="side-panel__portfolio-locking-container" v-if="! portfolio.is_immutable && isOwner">
              <div class="row has-padding side-panel__portfolio-section side-panel__portfolio-filters">
                <div class="side-panel__portfolio-clear-filters-section">
                  <ds-button class="red-variant" variant="primary" label="Delete actors & portfolio" icon="trash"
                             @click="removePortfolioAndActors"/>
                </div>
              </div>
            </form-group>

            <form-group
              style="padding-top: 0px;" label="Portfolio synchronisation" class="side-panel__portfolio-locking-container"
              v-if="!portfolio.is_immutable && isDeveloper && $store.getters.hasAccessToSharing"
            >
              <div class="row has-padding side-panel__portfolio-section side-panel__portfolio-filters">
                <div class="side-panel__portfolio-clear-filters-section" v-if="! portfolio.is_immutable">
                  <div v-if="portfolio.ecosystemSync">
                    <div>All changes in this portfolio are automatically synced with "{{ portfolio.ecosystemSync }}."</div>
                    <ds-button variant="secondary" icon="trash" label="Stop syncing" @click="deleteSync()" size="small"/>
                  </div>
                  <div v-else-if="syncableEcosystems">
                    <div v-if="syncStored">
                      The sync has been saved and a job has been started in order to perform an initial syncing with the selected ecosystem.
                    </div>
                    <div v-else>
                      <form-group>
                        Configure this portfolio to be synced with an ecosystem:
                        <dropdown v-model="syncedEcosystem" :options="syncableEcosystems"/>
                        <checkbox v-model="syncedEcosystemOverwrite" label="Overwrite values of mergeable fields with the values of this ecosystem"/>
                        <ds-button variant="secondary" v-if="syncedEcosystem" label="Create sync" @click="createSync()" :disabled="syncIsSaving"/>
                      </form-group>
                    </div>
                  </div>
                </div>
              </div>
            </form-group>
          </template>
        </template>
      </div>
      <action-bar editing="1" class="side-panel__portfolio-bottom-section" v-if="(editState == true || isContributor)">
        <ds-button variant="secondary" :disabled="saving" :label="saving ? 'Saving...' : 'Save changes'" @click="save"/>
        <ds-button variant="" label="cancel" @click="hidePreview"/>
        <ds-button icon="chevron-right" @click="closePanel" variant="secondary" size="small" class="side-panel__collapse-button"/>
      </action-bar>
    </div>
  </div>
</template>

<script>
  import Card from '../../components/Card/Card.vue'
  import CardInfoSocial from '../../components/Card/CardInfoSocial.vue'
  import KeywordWithFacet from '../../components/Keyword/KeywordWithFacet.vue'
  import Keyword from '../../components/Keyword/Keyword.vue'
  import ActionBar from '../../components/Form/ActionBar.vue'
  import Checkbox from '../../components/Form/Checkbox.vue'
  import RadioButton from '../../components/Form/RadioButton.vue'
  import ImageInput from '../../components/Form/ImageInput.vue'
  import SuggestionInput from '../../components/SuggestionInput/SuggestionInput.vue'
  import TagInput from '../../components/Form/TagInput.vue'
  import Badge from '../../components/Badge/Badge.vue'
  import Dropdown from '../../components/Dropdown/Dropdown.vue'
  import AvatarKeyword from '../../components/Keyword/AvatarKeyword.vue'
  import Tabs from '../../components/Tabs/Tabs.vue'
  import DsTextarea from '../../components/Form/DsTextarea.vue'
  import Avatar from '../../components/Avatar/Avatar.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { MUTATION_TYPES as PORTFOLIO_MUTATION_TYPES } from '../../store/modules/managePortfolio'
  import KEYCODES from '../../constants/keycodes'
  import MODAL_IDS from '../../constants/modal-ids'
  import ICONS from '../../../icons/manifest.json'

  import { timeAgo, toDateStringSlashed } from '../../util/date'
  import InfiniteScroll from '../../util/infinite-scroll'

  import { suggestionSettings } from '../../api/actors.js'
  import { enrichPortfolio } from '../../api/enriching.js'
  import {
    createPortfolioComment,
    createPortfolioSync,
    deletePortfolioComment,
    deletePortfolioSync,
    favouritePortfolio,
    getLinkedApiTokens,
    getPortfolioActivityLogs,
    getPortfolioComments,
    getPortfolioMembers,
    lockPortfolio,
    Portfolios,
    unfavouritePortfolio,
    unlockPortfolio,
    updateLinkedTokens,
    updatePortfolioComment,
  } from '../../api/portfolios'
  import { fetchProfile } from '../../api/user.js'
  import { ApiTokens, fetchUsers, syncCrmTag, userPortfolioMembersSuggestion } from '../../api/config'

  import PortfolioMixin from '../../util/PortfolioMixin.js'
  import { trackMatomoEvent } from '../../util/analytics'
  import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from '../../constants/analytics-constants'
  import _uniq from 'lodash/uniq.js'

  export default {
    name: 'edit-portfolio',
    props: {
      config: null,
    },
    data () {
      return {
        ICONS,
        errors: {},
        loading: false,
        portfolio: {
          private: true,
          filters: {},
          actor_ids: [],
          actor_ids_excluded: [],
          actors: [],
          id: null,
          name: null,
          image: null,
          tags: null,
          locked: false,
          creatorName: '',
          creatorLogo: '',
          member_count: 0,
          favourited: false,
        },
        saving: false,
        userProfile: {},
        publishState: false,
        activeTab: 'Description',
        owners: [],
        teamMembers: [],
        actorAddedState: false,
        members: {
          benchmarkers: [],
          contributors: [],
          viewers: [],
        },
        emptyMembers: {
          benchmarkers: [],
          contributors: [],
          viewers: [],
        },
        membersChanged: false,
        newComment: '',
        comments: [],
        editComment: {
          state: false,
          commentId: '',
          updatedComment: '',
        },
        activityLogs: [],
        savedFilters: {},
        hasEnrichingStarted: false,
        syncedEcosystem: null,
        syncedEcosystemOverwrite: false,
        syncStored: false,
        syncIsSaving: false,
        syncTagFormSubmitted: false,
        syncTagForm: {
          type: 'actors',
          submitting: false,
        },
        form: {},
        portfolioList: [],
        availableApiTokens: [],
        linkedApiTokens: [],
        originalLinkedApiTokens: [],
      }
    },
    computed: {
      suggestionSettings,
      userPortfolioMembersSuggestion,
      syncableEcosystems () {
        var configurations = this.$store.getters.syncableEcosystems

        if (!configurations) {
          return
        }

        var ecosystems = []

        configurations.forEach(config => {
          ecosystems.push(config.ecosystem)
        })

        return ecosystems
      },
      id () {
        return this.$route.params && this.$route.params.id ? this.$route.params.id : this.config.id
      },
      portfolioFilters () {
        return Object.keys(this.portfolio.filters)
          .map(facet => ({
            facet,
            value: this.portfolio.filters[facet],
          }))
          .filter(f => f.value)
      },
      totalActors () {
        return this.portfolio.member_count || 0
      },
      displayLinkedTokens () {
        return this.isOwner && this.availableApiTokens.length > 0
      },
      filters () {
        const filters = Object.assign({}, this.$store.getters.listFilterObject)

        delete filters.br
        delete filters.group
        delete filters.limit
        delete filters.offset
        delete filters.order
        delete filters.portfolio
        delete filters.tl
        delete filters.view

        return filters
      },
      editState () {
        // Deprecated
        return true
      },
      lockState () {
        return this.portfolio.locked
      },
      isPortfolioOwner () {
        return this.userProfile.portfolios && this.userProfile.portfolios.find(item => item == this.portfolio.id)
      },
      canPublish () {
        // return this.publishState == true && this.editState == true; //&& (this.members.contributors.length > 0 || this.members.viewers.length > 0 || this.members.benchmarkers.length > 0);
        return this.publishState && this.editState
      },
      excludedCompanyIds () {
        return this.$store.state.managePortfolio.portfolio.actor_ids_excluded
      },
      addedCompanyIds () {
        return this.$store.state.managePortfolio.portfolio.actor_ids
      },
      lockSectionTitle () {
        return `Locking the portfolio ${this.portfolio.locked == true || this.portfolio.locked == '1' ? '(Locked)' : '(Unlocked)'} `
      },
      isContributor () {
        return this.userProfile.contributePortfolios && this.userProfile.contributePortfolios.includes(this.id)
      },
      teamleaderConfigured () {
        return this.$store.getters.isTeamleaderConfigured
      },
      isExternalSyncEnabled () {
        return this.$store.getters.isExternalSyncEnabled
      },
      canShowSync () {
        return this.$store.getters.isOwn && this.portfolio.tags && this.portfolio.tags.length > 0
      },
    },
    methods: {
      toDateStringSlashed,
      timeAgo,
      fetchPortfolios () {
        var thisVue = this
        Portfolios.get()
          .then(portfolios => {
            portfolios.forEach(function (portfolio) {
              var portfolio = {
                'value': portfolio.id,
                'label': portfolio.name,
              }
              thisVue.portfolioList.push(portfolio)
            })
          })
          .catch(errors => {
          })
      },
      removePortfolioAndActors () {
        trackMatomoEvent(MATOMO_EVENT_CATEGORIES.INTERACTIONS, MATOMO_EVENT_ACTIONS.REMOVE_PORTFOLIO_BUTTON_SIDEPANEL, `{userId: ${this.$store.getters.userId},portfolioId: ${this.id}}`)
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, { portfolio_name: this.portfolio.name, actor_count: this.totalActors })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.PORTFOLIO_ACTORS_REMOVE)
      },
      enrichPortfolio () {
        enrichPortfolio(this.portfolio.id)
          .then()
          .catch()

        this.hasEnrichingStarted = true
      },
      startTagSync () {
        this.syncTagForm.portfolioId = this.portfolio.id

        syncCrmTag(this.syncTagForm)
          .then(data => {
            this.errors = {}
            this.syncTagFormSubmitted = true
          })
          .catch(errors => {
            this.errors = errors
          })
      },
      // Change the object type to sync tags with
      changeSyncType (val) {
        this.syncTagForm.type = val
      },
      resetForm () {
        this.syncTagForm.tags = []
        this.syncTagFormSubmitted = false
      },
      hidePreview (evt) {
        this.$store.commit(PORTFOLIO_MUTATION_TYPES.SET_EDIT_STATE, false)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      includeActor (actor, fromFilter) {
        this.portfolio.actor_ids_excluded = this.portfolio.actor_ids_excluded.filter(a => a !== actor.id)
        this.portfolio.actor_ids = this.portfolio.actor_ids.filter(a => a !== actor.id)

        if (!fromFilter) {
          this.portfolio.actor_ids.push(actor.id)

          const existing = this.portfolio.actors.find(a => a.id === actor.id)

          if (!existing) {
            this.$store.commit(PORTFOLIO_MUTATION_TYPES.ACTOR_IDS, { id: actor.id, name: actor.name })
            this.portfolio.actors.push({ id: actor.id, name: actor.name })
            this.actorAddedState = true
          }
        }
      },
      excludeActor (actorId, fromFilter) {
        this.portfolio.actors = this.portfolio.actors.filter(a => a.id !== actorId)
        this.portfolio.actor_ids = this.portfolio.actor_ids.filter(a => a !== actorId)
        if (fromFilter) {
          this.portfolio.actor_ids_excluded = this.portfolio.actor_ids_excluded.filter(a => a !== actorId)
          this.portfolio.actor_ids_excluded.push(actorId)
        }
      },
      async save () {
        this.saving = true

        if (this.portfolio.ecosystemSync && !window.confirm('You are about to update a synced portfolio, this means that any changes to this portfolio will affect the synced portfolio as well. Are you sure you want to save?')) {
          this.saving = false

          return
        }

        this.errors = {}

        if (this.hasAccessToPortfolioSharing && (this.isOwner || this.isMember) && this.membersChanged) {
          // Deprecated
          // await setPortfolioMembers({ portfolioId: this.portfolio.id, data: this.members })
        }

        // Check if the linked tokens have changed
        if (JSON.stringify(this.linkedApiTokens) !== JSON.stringify(this.originalLinkedApiTokens)) {
          try {
            var response = updateLinkedTokens(this.id, this.linkedApiTokens)
          } catch (err) {
            // console.warn(err);
          }
        }

        var params = { ...this.portfolio }
        delete params['ishPublishedViaApi']

        params.actor_ids = _uniq([...params.actor_ids, ...this.addedCompanyIds])
        params.actor_ids_excluded = _uniq([...params.actor_ids_excluded, ...this.excludedCompanyIds])

        Portfolios.post(params)
          .then(portfolio => {
            this.saving = false
            this.hidePreview()

            this.loadPortfolio(this.id)
          })
          .catch(errors => {
            this.saving = false
            this.errors = errors
          })
      },
      cancel () {
        this.$store.commit(PORTFOLIO_MUTATION_TYPES.SET_EDIT_STATE, false)
      },
      remove () {
        if (!window.confirm('Are you sure you want to remove portfolio ' + this.portfolio.name + '?')) {
          return
        }

        Portfolios.delete(this.portfolio.id)
          .then(portfolio => {
            this.hidePreview()
            this.$store.commit('FILTERS/CLEAR')
            this.$root.cache$portfolios = []
          })
          .catch(errors => {
            this.errors = errors
          })
      },
      canEditComment (userId) {
        return this.userProfile.id == userId
      },
      /* changeEditState () {
        if (this.editState == true) {
          this.$store.commit(PORTFOLIO_MUTATION_TYPES.SET_EDIT_STATE, false);
        } else {
          this.$store.commit(PORTFOLIO_MUTATION_TYPES.SET_EDIT_STATE, true);
        }
      }, */
      toggleLockState () {
        if (this.portfolio.locked == 0) {
          lockPortfolio(this.portfolio.id).then(response => {
            if (response.updated == true && response.locked == 1) {
              this.portfolio.locked = response.locked
            }
          })
            .catch(errors => {
              console.log(errors, 'error while locking portfolio')
            })
        } else {
          unlockPortfolio(this.portfolio.id).then(response => {
            if (response.updated == true && response.locked == 0) {
              this.portfolio.locked = response.locked
            }
          })
            .catch(errors => {
              console.log(errors, 'error while unlocking portfolio')
            })
        }
      },
      toggleFavourite () {
        if (this.portfolio.favourited == true) {
          // remove portfolio from favourites
          unfavouritePortfolio(this.portfolio.id).then(response => {
            if (response.updated == true) {
              this.portfolio.favourited = response.favourited
            }
          })
            .catch(errors => {
              console.log(errors, 'error while unfavouriting')
            })
        } else {
          // add portfolio to favourites
          favouritePortfolio(this.portfolio.id).then(response => {
            if (response.updated == true) {
              this.portfolio.favourited = response.favourited
            }
          })
            .catch(errors => {
              console.log(errors, 'error while favouriting')
            })
        }
      },
      toggleCommentEdit (comment) {
        if (this.editComment.state == false) {
          this.editComment.state = true
          this.editComment.commentId = comment.id
          this.editComment.updatedComment = comment.comment
        } else {
          this.editComment.state = false
          this.editComment.commentId = ''
          this.editComment.updatedComment = ''
        }
      },
      /* changePublishState (state) {
        this.publishState = !state;

        // If the publish state changed to "don't publish", reset the members of the portfolio members
        if (! this.publishState) {
          this.membersChanged = true;
          this.members = this.emptyMembers;
        }
      }, */
      getComments () {
        this.hasMore = 0

        if (this.portfolio.id) {
          getPortfolioComments(this.portfolio.id, 20, 0).then(response => {
            this.comments = response
            this.hasMore = response.length > 0
          })
            .catch(errors => {
              console.log(errors, 'error fetching comments')
            })
        }
      },
      getMoreComments () {
        if (!this.hasMore) {
          return
        }

        if (this.portfolio.id) {
          getPortfolioComments(this.portfolio.id, 20, this.comments.length).then(response => {
            this.comments = this.comments.concat(response)
            this.hasMore = response.length > 0
          })
            .catch(errors => {
              console.log(errors, 'error fetching more comments')
            })
        }
      },
      addComment () {
        if (this.portfolio.id && this.newComment.length > 5 && this.newComment.length !== ' ') {
          createPortfolioComment(this.portfolio.id, this.newComment).then(response => {
            this.newComment = ''
            this.getComments()
          })
            .catch(errors => {
              console.log(errors, 'error adding comment')
            })
        }
      },
      updateComment (comment) {
        if (this.portfolio.id && comment !== undefined && this.editComment.updatedComment.length > 5 && this.editComment.updatedComment.length !== ' ') {
          updatePortfolioComment(this.portfolio.id, comment.id, this.editComment.updatedComment).then(response => {
            this.editComment.updatedComment = ''
            this.editComment.state = false
            this.getComments()
          })
            .catch(errors => {
              console.log(errors, 'error updating comment')
            })
        }
      },
      removeComment (comment) {
        if (!window.confirm('Are you sure you want to remove the comment?')) {
          return
        }

        if (this.portfolio.id && comment !== undefined) {
          deletePortfolioComment(this.portfolio.id, comment.id).then(response => {
            this.getComments()
          }).catch(errors => {
            console.log(errors, 'error removing comment')
          })
        }
      },
      getActivityLog () {
        if (this.portfolio.id) {
          getPortfolioActivityLogs(this.portfolio.id, 4, 0).then(response => {
            this.activityLogs = response
          }).catch(errors => {
            console.log(errors, 'error fetching activity logs')
          })
        }
      },
      loadPortfolio (portfolioId) {
        Portfolios.get(portfolioId)
          .then(portfolio => {
            this.loading = false

            if (!portfolio.actor_ids_excluded) {
              portfolio.actor_ids_excluded = []
            }

            if (!portfolio.actor_ids) {
              portfolio.actor_ids = []
            }

            if (!portfolio.actors) {
              portfolio.actors = portfolio.actor_ids.map(id => ({ id, name: id, excluded: false }))
            }

            portfolio.private = portfolio.private || false

            this.portfolio = portfolio

            // This object will save the portfolios existing filters so that when saving the edit of the portfolio, the user cannot save with new filters if he does't not have permissions
            this.savedFilters = this.portfolio.filters

            // Set the state for the exluded Ids so that the portfolio detail page (portfolio actor list) can now have the current list of actors
            this.portfolio.actor_ids_excluded.forEach(item => {
              this.$store.commit(PORTFOLIO_MUTATION_TYPES.ACTOR_EXCLUDED_IDS, { id: item })
            })

            // Set the state for the included Ids so that the portfolio detail page (portfolio actor list) can now have the current list of actors
            this.portfolio.actors.forEach(item => {
              this.$store.commit(PORTFOLIO_MUTATION_TYPES.ACTOR_IDS, { id: item.id, name: item.name })
            })

            // this.$store.commit('FILTERS/SET_PORTFOLIO', portfolio.id) // This is no longer required, this just sets the ID of the active portfolio in the filter state, which is not required ot make the edit portfolio work
            this.$store.commit('FILTERS/LOAD_PORTFOLIO', portfolio)

            // Get activity logs for the portfolio
            this.getActivityLog()

            // Get comments  for the portfolio
            this.getComments()
          })
          .catch(() => {
            this.loading = false
          })
      },
      createSync () {
        this.syncIsSaving = true

        createPortfolioSync(this.portfolio.id, { ecosystem: this.syncedEcosystem, overwrite: this.syncedEcosystemOverwrite })
          .then(response => {
            this.syncStored = true
          })
          .catch()
      },
      deleteSync () {
        if (!window.confirm('Are you sure you want to stop syncing this portfolio?')) {
          return
        }

        deletePortfolioSync(this.portfolio.id).then(response => {
          this.portfolio.ecosystemSync = null
        }).catch()
      },
      closePanel () {
        this.$store.commit(UI_MUTATION_TYPES.TOGGLE_SIDE_PANEL, true)
      },
    },
    async mounted () {
      window.addEventListener('keyup', (e) => {
        if (e.keyCode === KEYCODES.ESC) {
          this.hidePreview()
        }
      })

      this.$store.commit('FILTERS/CLEAR_BY_FACET', 'actor_type')

      this.loading = true
      this.loadPortfolio(this.id)

      // Fetch api tokens if the user is an owner
      if (this.isOwner) {
        try {
          var availableApiTokens = await ApiTokens.get()
          var linkedApiTokens = await getLinkedApiTokens(this.id)

          this.availableApiTokens = availableApiTokens
            .map(token => ({
              value: token.id,
              label: token.name,
            }))

          this.linkedApiTokens = linkedApiTokens
            .map(token => token.id)

          // Keep track of the original tokens
          this.originalLinkedApiTokens = this.linkedApiTokens
        } catch (err) {
          // console.warn(err)
        }
      }

      // Fetch all the owners and team members from the ecosystem if the user has access to the sharing tab
      if (this.hasAccessToPortfolioSharing && (this.isOwner || this.isMember)) {
        // getPortfolioMembers(this.portfolio.id).then(members => {
        getPortfolioMembers(this.id)
          .then(members => {
            this.members = members

            this.publishState = this.members.contributors.length > 0 || this.members.viewers.length > 0 || this.members.benchmarkers.length > 0
          })

        // Fetch all owners from the ecosystem
        var owners = Object.assign({}, { role: 'owner' })

        fetchUsers(owners).then(response => {
          if (response.data) {
            this.owners = response.data.filter(user => !user.invited_user)
          }
        })

        // Fetch all team members from the ecosystem
        var members = Object.assign({}, { role: 'member' })

        fetchUsers(members).then(response => {
          if (response.data) {
            this.teamMembers = response.data.filter(user => !user.invited_user)
          }
        })
      }

      // Fetch user profile
      fetchProfile().then(profile => {
        this.userProfile = profile
      })

      // Infinite scroll
      this.inf = new InfiniteScroll(this.$refs.scrollable, this.getMoreComments)

      // Detect which tab is active
      this.$bus.on('tab', (tab) => {
        this.activeTab = tab
      })

      // The whole point of this edit panel is to edit it, so the edit state
      // this.$store.commit(PORTFOLIO_MUTATION_TYPES.SET_EDIT_STATE, true);
    },
    beforeUnmount () {
      if (this.inf) {
        this.inf.destroy()
      }

      this.$bus.off('tab')

      this.$store.commit(UI_MUTATION_TYPES.TOGGLE_SIDE_PANEL, true)
    },
    watch: {
      filters (v) {
        this.portfolio.filters = Object.assign({}, v)
      },
      excludedCompanyIds (excludedList) {
        this.portfolio.actor_ids_excluded = excludedList
      },
      addedCompanyIds (includedList) {
        this.portfolio.actor_ids = includedList
      },
      id (newId) {
        this.loadPortfolio(newId)
      },
    },
    mixins: [PortfolioMixin],
    components: {
      ActionBar,
      Avatar,
      AvatarKeyword,
      Badge,
      Card,
      CardInfoSocial,
      Checkbox,
      Dropdown,
      DsTextarea,
      ImageInput,
      InfiniteScroll,
      Keyword,
      KeywordWithFacet,
      RadioButton,
      SuggestionInput,
      Tabs,
      TagInput,
    },
  }
</script>

<style scoped lang="scss">
  .red-variant {
    background-color: red;

    &:hover {
      background-color: lightcoral;
    }
  }

  .portfolio__api-token-button {
    &:hover {
      cursor: pointer;
    }
  }
</style>
