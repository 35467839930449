<template>
  <div>
    <template v-for="filterableReportField in filterableReportFields">
      <div>
        <div class="top-filter-section">
          <h6 class="top-filter-section__title"
              style="max-width: unset; position: relative">
            {{ filterableReportField.label }}</h6>
        </div>
        <template v-if="filterableReportField.type === 'options'">
          <ul class="modal__body__filter-column"
              :style="columnCounter(filterableReportField.options)">
            <li :title="item" v-for="item in filterableReportField.options">
              <ds-button
                :class="{ 'modal__body__active-filter-text': isReportFieldActive(filterableReportField, item)}"
                class="modal__body__list--items"
                :label="item"
                variant="minimal"
                @click="handleChangedOptionReportField(filterableReportField, item)"
                size="small"
              />
            </li>
          </ul>
        </template>
        <template v-if="filterableReportField.type === 'multi_select'">
          <ul class="modal__body__filter-column"
              :style="columnCounter(filterableReportField.options)">
            <li :title="item" v-for="item in filterableReportField.options">
              <ds-button
                :class="{ 'modal__body__active-filter-text': isReportFieldActive(filterableReportField, item)}"
                class="modal__body__list--items"
                :label="item"
                variant="minimal"
                @click="handleChangedMultiSelectReportField(filterableReportField, item)"
                size="small"
              />
            </li>
          </ul>
        </template>
        <template v-if="filterableReportField.type === 'checkbox'">
          <ul class="modal__body__filter-column"
              style="margin-left: 1rem; font-size: 11px;"
              :style="columnCounter(filterableReportField.options)">
            <checkbox
              v-for="option in filterableReportField.options"
              :key="'checkbox__' + option"
              :val="option"
              :model-value="activeReportFieldCheckbox('report_field_' + filterableReportField.id)"
              class="modal__body__list--items"
              :label="option"
              :multiple="true"
              :reference="'' + filterableReportField.id"
              @update:modelValue="handleToggleCheckboxReportField"
            />
          </ul>
        </template>
        <template v-else-if="filterableReportField.type === 'number'">
                <span class="score-overview__filter-label">
                  <ds-input
                    :model-value="activeReportFieldNumber('report_field_' + filterableReportField.id + '_min')"
                    @update:modelValue="handleChangedNumberReportField"
                    :reference="'report_field_' + filterableReportField.id + '_min'"
                    class="score-overview__filter_above_bellow filter_above"/> -
                  <ds-input
                    :model-value="activeReportFieldNumber('report_field_' + filterableReportField.id + '_max')"
                    @update:modelValue="handleChangedNumberReportField"
                    :reference="'report_field_' + filterableReportField.id + '_max'"
                    class="score-overview__filter_above_bellow filter_bellow"/>
                </span>
        </template>
        <template v-else-if="filterableReportField.type === 'date'">
          <div class="row modal__body modal__body__all-filters"
               style="margin-left: 20px;">
            <form-group style="display: inline-block;" label="Start date">
              <datepicker style="width: 300px;"
                          input-id="startDate"
                          :model-value="activeReportFieldNumber('report_field_' + filterableReportField.id + '_min')"
                          @update:modelValue="handleChangedDateReportField"
                          :reference="'report_field_' + filterableReportField.id + '_min'"
                          class="form-group col-xs-12 col-sm-4"/>
            </form-group>
            <form-group style="display: inline-block;" label="End date">
              <datepicker style="width: 300px;"
                          input-id="endDate"
                          :model-value="activeReportFieldNumber('report_field_' + filterableReportField.id + '_max')"
                          @update:modelValue="handleChangedDateReportField"
                          :reference="'report_field_' + filterableReportField.id + '_max'"
                          class="form-group col-xs-12 col-sm-4"/>
            </form-group>
          </div>
        </template>
        <template v-else-if="filterableReportField.type === 'score'">
          <slider class="top-filter-slider"
                  :model-value="activeReportFieldScore(filterableReportField)"
                  :reference="filterableReportField.id"
                  :label="filterableReportField.label"
                  lazy
                  style="margin-left: 1em;"
                  @update:modelValue="handleChangedScoreReportField" max="5"/>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
  import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../../store/modules/filters'

  import Slider from '../../Slider/Slider.vue'
  import DsButton from '../../DsButton/DsButton.vue'
  import Checkbox from '../../Form/Checkbox.vue'
  import DsInput from '../../Form/DsInput.vue'
  import Datepicker from '../../Form/Datepicker.vue'

  export default {
    props: {
      reportTemplate: {
        type: Object,
        required: true
      }
    },
    name: 'ReportTemplateFilter.vue',
    computed: {
      isMobile () {
        return this.$store.getters.isMobile
      },
      filterableReportFields () {
        return this.reportTemplate.filterableReportFields
      }
    },
    methods: {
      isReportFieldActive (reportField, item) {
        var options = this.activeReportFieldsOptions(reportField)

        return options.find((f) => f == item)
      },
      activeReportFieldsOptions (reportField) {
        // Return the active report field options
        var reportFieldName = 'report_field_' + reportField.id

        if (!this.$store.state.filters.reportFilters[reportFieldName]) {
          return []
        }

        return this.$store.state.filters.reportFilters[reportFieldName]
      },
      activeReportFieldCheckbox (reportField) {
        if (!this.$store.state.filters.reportFilters[reportField]) {
          return false
        }

        return this.$store.state.filters.reportFilters[reportField] == '1'
      },
      handleToggleCheckboxReportField (context) {
        var context = { reportId: context.reference, value: context.value[0], type: 'checkbox' }
        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.UPDATE_REPORT_FIELD,
          content: context,
        })
      },
      activeReportFieldScore (reportField) {
        var minReportFieldName = 'report_field_' + reportField.id + '_min'
        var maxReportFieldName = 'report_field_' + reportField.id + '_max'

        var start = null
        var end = null

        if (this.$store.state.filters.reportFilters[minReportFieldName]) {
          start = this.$store.state.filters.reportFilters[minReportFieldName]
        }

        if (this.$store.state.filters.reportFilters[maxReportFieldName]) {
          end = this.$store.state.filters.reportFilters[maxReportFieldName]
        }

        return { start: start, end: end }
      },
      activeReportFieldNumber (reportFieldIdentifier) {
        if (this.$store.state.filters.reportFilters[reportFieldIdentifier]) {
          return this.$store.state.filters.reportFilters[reportFieldIdentifier]
        }

        return null
      },
      handleChangedScoreReportField (context) {
        // Since scores are sliders, we need to transform the start/end values to 2 different report field properties (= min/max)
        context.type = 'score'
        context.reportId = context.reference

        this.$emit('update-state', {
          name: FILTERS_MUTATION_TYPES.UPDATE_REPORT_FIELD,
          content: context,
        })
      },
      handleChangedNumberReportField (context) {
        context.type = 'number'
        context.reportId = context.reference
        this.$emit('update-state', { name: FILTERS_MUTATION_TYPES.UPDATE_REPORT_FIELD, content: context })
      },
      handleChangedDateReportField (context) {
        context.type = 'date'
        context.reportId = context.reference
        this.$emit('update-state', { name: FILTERS_MUTATION_TYPES.UPDATE_REPORT_FIELD, content: context })
      },
      handleChangedOptionReportField (reportField, value) {
        var context = { reportId: reportField.id, value: value, type: 'options' }
        this.$emit('update-state', { name: FILTERS_MUTATION_TYPES.UPDATE_REPORT_FIELD, content: context })
      },
      handleChangedMultiSelectReportField (reportField, value) {
        var context = { reportId: reportField.id, value: value, type: 'multi_select' }
        this.$emit('update-state', { name: FILTERS_MUTATION_TYPES.UPDATE_REPORT_FIELD, content: context })
      },
      columnCounter (items) {
        if (this.isMobile) {
          return `column-count:${1}; width:100%;`
        }
        var itemCounter = items.length
        items.map(f => {
          if (f.children !== undefined && f.children.length > 0) {
            itemCounter += f.children.length
          }
          if (f.industries !== undefined && f.industries.length > 0) {
            itemCounter += f.industries.length
          }
        })
        if (itemCounter <= 10) {
          this.hasScroll = false
          return `column-count:${1}; width:150px;`
        }
        if (itemCounter > 10 && itemCounter <= 20) {
          this.hasScroll = false
          return `column-count:${2}; width:350px;`
        }
        if (itemCounter > 20 && itemCounter <= 30) {
          this.hasScroll = false
          return `column-count:${3};`
        }
        if (itemCounter > 30) {
          this.hasScroll = true
          return `column-count:${3};`
        }
      },
    },
    components: {
      Slider,
      DsButton,
      Checkbox,
      DsInput,
      Datepicker
    }
  }
</script>

<style scoped>

</style>
