<template>
  <div class="collapsable-panel" :class="{ 'collapsable-panel--collapsed': isCollapsed }">
    <header class="collapsable-panel__header">
      <div class="collapsable-panel__header__title-container" @click="toggle">
        <ds-button :icon="isCollapsed ? 'chevron-down' : 'chevron-up'" :label="title" :variant="buttonVariant" class="collapsable-panel__header__title"/>
      </div>
    </header>
    <div class="collapsable-panel__body">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { trackHeapEvent } from '../../util/analytics'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      isCollapsed: false,
    }
  },
  props: {
    title: String,
    buttonVariant: {
      type: String,
      default: 'secondary'
    }
  },
  methods: {
    toggle () {
      trackHeapEvent('global.toggleCollapsedPanel')
      this.isCollapsed = !this.isCollapsed
    }
  }
})
</script>

<style lang="scss">
  @import "../../../scss/variables";
  @import "../../../scss/mixins/icon-color";

  $header-accent-color: #383A48;

  .collapsable-panel__header {
    position: relative;
    margin: 1rem 0;

    &:after {
      /*z-index: -1;*/
      z-index: 1;
      content: "";
      height: 1px;
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      background-color: #9B9B9B;
    }

    @media (max-width: $screen-md) {
      text-align: center;
    }
  }

  .collapsable-panel__header__title-container {
    display: inline-block;
    margin-left: 25px;
    padding: 0 0 2px 0;
    line-height: 19px;
    border: 10px solid #fff;
    text-transform: uppercase;
    color: #fff;
    font-size: 14px;
    letter-spacing: 0.6px;
    font-weight: 500;
    position: relative;
    z-index: 2;

    &:hover {
      cursor: pointer;
    }

    .svg-icon {
      top: 4px;
      position: relative;
    }

    .collapsable-panel__header__title {
      vertical-align: text-bottom;
      font-style: normal;
      padding-right: 13px;
      padding-left: 13px;
      line-height: 24px;
      .svg-icon {
        left: 4px;
      }
    }

    @include iconColor(#fff);

    @media (max-width: $screen-md) {
      margin: 0;
    }
  }

  .collapsable-panel--collapsed {
    .collapsable-panel__body {
      display: none;
    }
  }
</style>
