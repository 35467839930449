<template>
  <autocomplete-dropdown
    v-model="internalValue"
    :placeholder="placeholder"
    :suggestions="displayedSuggestions"
    :do-search="autocompleteSearchTerm"
    :multiple="true"
    :allowClear="true"
    :disabled="disabled"
    @suggestion:decline="declineSuggestion"
  />
</template>

<script>
  import AutocompleteDropdown from '../../components/Dropdown/AutocompleteDropdown.vue'
  import { annotateText, fetchConceptSuggestions } from '../../api/exploration'
  import _uniqBy from 'lodash/uniqBy.js'

  export default {
    components: {
      AutocompleteDropdown,
    },
    props: {
      modelValue: {
        type: Array,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      placeholder: {
        type: String,
        default: ''
      },
      suggestionSource: {
        type: String,
        default: '',
      },
      allowFreeTextTag: {
        type: Boolean,
        default: false,
      },
      maxTags: {
        type: Number,
      },
    },
    emits: ['update:modelValue'],
    data () {
      return {
        suggestions: [],
      }
    },
    computed: {
      internalValue: {
        get () {
          return this.modelValue
        },
        set (value) {
          if (!this.maxTags || value.length <= this.maxTags) {
            this.$emit('update:modelValue', value)
          }
        },
      },
      valueValues () {
        return this.modelValue.map(x => x.value)
      },
      displayedSuggestions () {
        return this.suggestions.filter(suggestion => !this.valueValues.includes(suggestion.value))
      },
    },
    methods: {
      removeDuplicates (suggestions) {
        return _uniqBy(suggestions, 'value')
      },
      autocompleteSearchTerm (query) {
        return fetchConceptSuggestions({
          query: query,
          conceptType: 'any',
          allow_free_text: this.allowFreeTextTag,
        })
          .then(suggestions => {
            const options = []
            suggestions = this.removeDuplicates(suggestions)

            suggestions.forEach(function (concept) {
              if (!concept.type) {
                options.push({
                  label: concept.value + ' (user-generated)',
                  value: concept.uri,
                })
              } else {
                options.push({
                  label: concept.value,
                  value: concept.uri,
                })
              }

            })

            return options
          })
          .catch(err => {
            console.log(err)
          })
      },
      fetchSuggestions () {
        this.$emit('fetching-suggestions', true)
        annotateText(this.suggestionSource).then(response => {
          this.suggestions = response.concepts || []
          this.$emit('fetching-suggestions', false)
        })
      },
      declineSuggestion ({ value }) {
        this.suggestions = this.suggestions.filter(suggestion => suggestion.value !== value)
      },
    },
    watch: {
      suggestionSource () {
        this.fetchSuggestions()
      },
    },
  }
</script>
