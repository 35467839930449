<template>
  <article-file-detail-simplified
    v-if="['rss','patent','blog','pr','event'].includes(file.mimetype) && hasAccessToExplorationOrMonitoring"
    :file="file"
  />
</template>

<script>
  import ArticleFileDetailSimplified from './FileDetail/ArticleFileDetailSimplified.vue'

  import { getFileDetails } from '../api/files'

  export default {
    name: 'FileDetail.vue',
    data () {
      return {
        file: {},
      }
    },
    computed: {
      hasAccessToExplorationOrMonitoring () {
        return this.$store.getters.hasAccessToExplorationOrMonitoring
      },
    },
    methods: {
      getFileData () {
        getFileDetails(this.$route.params.fileId)
          .then(response => {
            this.file = response
          })
      },
    },
    mounted () {
      if (!this.$route.params.fileId) {
        console.log('A file ID was expected, check the route.')

        return
      }

      this.getFileData()
    },
    watch: {
      '$route.params.fileId': {
        handler () {
          this.getFileData()
        },
      },
    },
    components: {
      ArticleFileDetailSimplified,
    },
  }
</script>

<style scoped>
</style>
