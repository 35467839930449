<template>
  <span class="filters--dropdown" v-click-away="closeDropdown">
    <ds-button
      :icon="open ? 'chevron-up' : 'chevron-down'"
      class="filters__dropdown__button"
      :class="filtersDropdownClass"
      :label="label"
      :variant="variant"
      :size="size"
      @blur="blur"
      @click="toggle"
      @focus="focus"
    />
    <div v-if="open" class="filters--dropdown__options">
      <div class="scrollable filters--dropdown__background" :class="filtersDropdownClass">
        <div>
          <li :title="item.name || item.label || item.text || item" v-for="item in options">
            <radio-box-button
              v-if="isRadioBox"
              name="radio-buttons"
              :val="item.name || item.label || item.text || item"
              :model-value="selection"
              :label="getLabelForLegendItem(item)"
              block
              variant="minimal"
              size="extra-small"
              @blur="blur"
              @focus="focus"
              @update:modelValue="input(item.value || item.id || item)"
            />
            <ds-button
              v-else
              class="filters--dropdown__options--items"
              :label="getLabelForLegendItem(item)"
              :variant="variant"
              size="small"
              @blur="blur"
              @focus="focus"
              @click="input(item.value || item.id || item)"
            />
          </li>
        </div>
      </div>
    </div>
  </span>
</template>

<script lang="ts">
  import CheckboxButton from '../CheckboxButton/CheckboxButton.vue'
  import RadioBoxButton from '../Form/RadioBoxButton.vue'

import FiltersMixin from '../../util/FiltersMixin.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'filters-dropdown',
    props: ['options', 'label', 'variant', 'selection', 'icon', 'size', 'isRadioBox', 'dropdownSize', 'useTaxonomyAlias'],
    data () {
      return {
        open: false,
      }
    },
    computed: {
      filtersDropdownClass () {
        return this.dropdownSize == 'large' ? 'filters__dropdown__button--large' : ''
      },
    },
    methods: {
      getLabelForLegendItem (item) {
        const actualValue = item.text || item.label || item.value || item
        if (this.useTaxonomyAlias) {
          return this.getTaxonomyAlias(actualValue).replaceAll('_', ' ')
        }
        return actualValue
      },
      toggle () {
        this.open = !this.open || this.active
      },
      focus () {
        this.active = true
        this.open = true
        setTimeout(() => {
          this.active = false
        }, 500)
      },
      input (value) {
        this.$emit('update:modelValue', value)
      },
      blur () {
        setTimeout(() => {
          if (this.$el && document.activeElement && !this.$el.contains(document.activeElement)) {
            this.open = false
          }
        }, 0)
      },
      closeDropdown (event) {
        if (event.target.parentElement !== null && event.target.parentElement.classList.value !== 'filters--dropdown') {
          this.open = false
        }
      },
    },
    mixins: [FiltersMixin],
    components: {
      CheckboxButton,
      RadioBoxButton,
    },
  })
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

  .hidden__option {
    display: none !important;
  }

  .filters--dropdown {
    position: relative;
    border: none;
    background: none;
    margin-right: 1em;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 2px $color-primary;

      .fill-fg {
        fill: $color-primary;
      }
    }

    &:hover .fill-fg {
      fill: $color-primary;
    }

    .button {
      text-align: left;
    }

    > .svg-icon {
      display: block;
    }
  }

  .filters__dropdown__button {
    width: fit-content;
    max-width: 200px;
    height: 33px;
    border: 1px solid $color-borders !important;
    margin-right: 0px !important;

    span {
      color: $color-text-grey-light;
      padding-left: 0px !important;
      text-transform: capitalize;
      margin-right: 2rem;
    }

    .svg-icon {
      left: auto !important;
      right: 15px;

      path {
        fill: $color-secondary
      }
    }

    svg {
      width: 20px;
      height: 20px;
      margin-top: -1px;
    }

    .button__label {
      font-family: $font-stack-primary;
      line-height: 19px;
      letter-spacing: .06em;
      margin-right: 2rem;
    }
  }

  .filters__dropdown__button--large {
    width: 180px;
  }

  .filters--dropdown__options {
    /* z-index:6;*/
    z-index: 999;
    min-width: 100%;
    margin-top: 5px;
    position: absolute;
    max-height: 250px;
    top: 100%;
    margin-bottom: 50px;
    left: 0px;
    white-space: nowrap;
    font-size: 14px;
    text-align: left;
    background: $color-secondary;

    > .button {
      &:focus {
        z-index: 1;
      }
    }

    .svg-icon {
      width: 12px;
      height: 12px;
    }

    .filters--dropdown__background {
      background: white;
      border-left: 1px solid $color-borders;
      border-bottom: 1px solid $color-borders;
      border-right: 1px solid $color-borders;
      max-height: 250px;

      li {
        list-style: none;
        padding: 2px 0;
      }

      .button {
        text-align: left;
        max-height: 27px;
      }

      .button__label {
        font-style: normal !important;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        -ms-text-overflow: ellipsis;
        width: 100%;
        font-family: $font-stack-primary;
      }

      .filters--dropdown__background--large {
        width: 180px;
      }

    }

    ::-webkit-scrollbar {
      width: 6px;
      height: 4px;
      background-color: $color-secondary;
      background: $color-secondary
    }

    ::-webkit-scrollbar-thumb {
      background-color: $color-background-grey;
      -webkit-border-radius: 1ex;
    }
  }

  .filters--dropdown__options--items {
    width: 100%;

    .button__label {
      text-transform: capitalize;
    }
  }

  .filters--dropdown__options--items:hover {
    background-color: $color-borders !important;
  }

  .cb-dropdown__options {
    position: absolute;
    z-index: 2;
    height: 200px;
    top: 100%;
    margin-bottom: 50px;
    left: 0px;
    white-space: nowrap;
    font-size: 14px;
    text-align: left;
    background: $color-secondary;

    > .button {
      &:focus {
        z-index: 1;
      }
    }

    .svg-icon {
      width: 12px;
      height: 12px;
    }

    ::-webkit-scrollbar {
      width: 6px;
      height: 4px;
      background-color: $color-secondary;
      background: $color-secondary
    }

    ::-webkit-scrollbar-thumb {
      background-color: $color-background-grey;
      -webkit-border-radius: 1ex;
    }
  }

  .cb-dropdown__button {
    width: 95%;
    background-color: $color-background-grey !important;
    color: white !important;
    text-align: center !important;
    margin-bottom: 4px;
    margin-left: 4px;

    &:hover {
      background-color: rgba(255, 255, 255, .3);
    }
  }

  .cb-dropdown__button-container {
    background: $color-secondary;
    width: 100%;
  }

  .cb-dropdown__inactive_button {
    width: 95%;
    background-color: white !important;
    text-align: center !important;

    &:hover {
      background-color: rgba(255, 255, 255, .3);
    }
  }
</style>
