<template>
  <div class="datascouts-tag-input" :class="{nofocus, 'open-upwards': openUpwards, 'has-icon': icon, 'disabled': disabled}">
    <icon v-if="icon" :name="icon" class="tag-input-icon"/>
    <vue-tags-input
      v-model="internalInput"
      :tags="internalTags"
      :placeholder="placeholder"
      :avoidAddingDuplicates="false"
      :deleteOnBackslash="false"
      :autocompleteItems="autocompleteItems"
      :autocompleteAlwaysOpen="autocompleteAlwaysOpen"
      :addOnlyFromAutocomplete="addOnlyFromAutocomplete"
      :addOnKey="[13,9]"
      :disabled="disabled"
      :maxTags="maxTags"
      :validation="validation"
      @tags-changed="tagsChanged"
    >
      <template v-slot:tag-center="tag">
        <div :class="{'source-tag-inactive': isSourceTagInactive(tag)}">
          <icon v-if="showEyeIcon(tag)" :name="getEyeIcon(tag)" class="eye-icon" />
          <span :title="getHoverText(tag)">{{ getDisplayText(tag) }}</span>
        </div>
      </template>
      <template v-slot:tag-actions="{tag, index, performDelete}" :acceptSuggestion="acceptSuggestion" :declineSuggestion="declineSuggestion"
        :allowAddingToDictionary="allowAddingToDictionary">
        <add-to-dictionary-button class="datascouts-tag-input__add-to-dictionary" :keyword="tag" size="small"
          v-if="allowAddingToDictionary && $store.getters.hasAccessToExplorationOrSensing && tag.uri"/>
        <div class="action" @click="performDelete(index)" v-if="!tag.isSuggestion">
          <icon name="remove"/>
        </div>
        <template v-else>
          <div class="action" aria-hidden="true" tabindex="1" @click="acceptSuggestion(tag)" v-tooltip.top="acceptSuggestionLabel">
            <icon name="check"/>
          </div>
          <div class="action" aria-hidden="true" tabindex="2" @click="declineSuggestion(tag)" v-tooltip.top="denySuggestionLabel">
            <icon name="remove"/>
          </div>
        </template>
      </template>
    </vue-tags-input>
  </div>
</template>

<script>
  // import VueTagsInput from '@johmun/vue-tags-input'
  import VueTagsInput from '@sipec/vue3-tags-input'
  import AddToDictionaryButton from '../AddToDictionaryButton/AddToDictionaryButton.vue'
  import DictionaryMixin from '../../util/DictionaryMixin.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    components: {
      VueTagsInput,
      AddToDictionaryButton,
    },
    mixins: [DictionaryMixin],
    props: {
      input: {
        type: String,
        default: () => null,
      },
      icon: {
        type: String,
      },
      modelValue: {
        type: Array,
        default: () => [],
      },
      suggestions: {
        type: Array,
        default: () => [],
      },
      placeholder: {
        type: String,
        default: '',
      },
      newline: {
        type: Boolean,
        value: false,
      },
      forSources: {
        type: Boolean,
        value: false,
      },
      autocompleteItems: {
        type: Array,
        default: () => [],
      },
      autocompleteAlwaysOpen: {
        type: Boolean,
        default: false,
      },
      nofocus: {
        type: Boolean,
        default: false,
      },
      openUpwards: {
        type: Boolean,
        default: false,
      },
      addOnlyFromAutocomplete: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      maxTags: {
        type: Number,
        default: 100,
      },
      allowAddingToDictionary: {
        type: Boolean,
        default: false,
      },
      acceptSuggestionLabel: {
        type: String,
        default: 'Accept suggestion',
      },
      denySuggestionLabel: {
        type: String,
        default: 'Deny suggestion',
      },
      validation: {
        type: Array,
        default: () => [],
      },
    },
    data: () => ({
      autoInput: '',
    }),
    computed: {
      ensuredValue () {
        return this.modelValue ? this.modelValue : []
      },
      internalInput: {
        get () {
          return this.input !== null ? this.input : this.autoInput
        },
        set (input) {
          if (this.input !== null) {
            this.$emit('update:input', input)
          } else {
            this.autoInput = input
          }
        },
      },
      internalTags () {
        return this.ensuredValue.map(tag => {
          let result

          // Take into account tags with an object attached to it
          if (tag.text) {
            result = {
              ...tag,
              text: tag.text,
              optionValue: tag.optionValue,
            }
          } else {
            result = { text: tag }
          }

          if (this.allowAddingToDictionary && this.$store.getters.hasAccessToExplorationOrSensing && this.keywordExistsInAnyDictionary(tag)) {
            result.classes = 'tag--dictionary'
          }

          return result
        }).concat(this.suggestions.map(tag => ({
          text: tag,
          isSuggestion: true,
          classes: 'tag--suggestion',
        })))
      },
    },
    methods: {
      openExternalLink (event, tag) {
        event.preventDefault()

        window.open('https://' + this.getValueText(tag))
      },
      getValueText (tag) {
        if (tag && tag.tag && tag.tag.optionValue && tag.tag.optionValue.text) {
          return tag.tag.optionValue.text
        }

        if (tag.tag.text) {
          return tag.tag.text
        }

        return ''
      },
      getDisplayText (tag) {
        if (tag && tag.tag && tag.tag.optionValue && tag.tag.optionValue.displayText) {
          return tag.tag.optionValue.displayText
        }

        if (tag && tag.tag && tag.tag.optionValue && tag.tag.optionValue.text) {
          return tag.tag.optionValue.text
        }

        if (tag.tag.text) {
          return tag.tag.text
        }

        return ''
      },
      isSourceTagInactive (tag) {
        if (tag && tag.tag && tag.tag.optionValue && tag.tag.optionValue.sourceTagInactive) {
          return tag.tag.optionValue.sourceTagInactive
        }

        return false
      },
      getTagText (tag) {
        if (tag && tag.tag && tag.tag.optionValue && tag.tag.optionValue.text) {
          return tag.tag.optionValue.text
        }

        if (tag.tag.text) {
          return tag.tag.text
        }

        return ''
      },
      getHoverText (tag) {
        if (tag && tag.tag && tag.tag.optionValue && tag.tag.optionValue.hoverText) {
          return tag.tag.optionValue.hoverText
        }
        return ''
      },
      showEyeIcon (tag) {
        if (tag && tag.tag && tag.tag.optionValue && tag.tag.optionValue.showEye) {
          return true
        }

        return false
      },
      getEyeIcon (tag) {
        if (tag && tag.tag && tag.tag.optionValue && tag.tag.optionValue.invalidOption) {
          return 'eye-blocked'
        }

        return 'eye'
      },
      tagsChanged (nextTags) {
        const nextValues = []

        // Emit the raw values, this allows "tags" that are objects to be updated properly
        this.$emit('input:raw', nextTags)

        for (const tag of nextTags) {
          if (tag.isSuggestion) continue

          const text = tag.text.trim()

          if (!nextValues.includes(text)) {
            nextValues.push(text)
          }

          if (this.suggestions.includes(text)) {
            this.$emit('suggestion:accept', text)
          }
        }

        this.$emit('update:modelValue', nextValues)
      },
      acceptSuggestion (suggestion) {
        this.$emit('suggestion:accept', suggestion.text)
        this.$emit('update:modelValue', [...this.ensuredValue, suggestion.text])
      },
      declineSuggestion (suggestion) {
        this.$emit('suggestion:decline', suggestion.text)
      },
    }
  })
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables.scss";

  .eye-icon {
    width: 13px;
    height: 13px;
    margin-right: 5px;
    padding-top: 5px;
  }

  .datascouts-tag-input {
    &.has-icon {
      position: relative;

      :deep(.ti-tags) {
        padding-left: 30px;
      }
    }

    .tag-input-icon {
      position: absolute;
      height: 100%;
      left: 10px;
      z-index: 1;

      :deep(svg) {
        path, g {
          fill: rgba(0, 0, 0, 0.3)
        }
      }
    }

    :deep(.source-tag-inactive) {
      color: red;
    }

    &.disabled {
      :deep(.ti-tag) {
        background: $color-primary !important;
        opacity: 0.6;
        border: none;
        cursor: text;
        pointer-events: none;
        color: white;
      }
    }
  }
</style>
