<template>
  <div class="authentication-modal authentication-modal--big">
    <modal
      :id="modalId"
      ref="modal"
      :title="title"
      :enable-mask="false"
      :is-closeable="true"
    >
      <template v-slot:body>
        <div>
          <p>
            {{ changes.length }}
            {{ $store.getters.actorStrings.toLowerCase() }}
            will be affected. Are you sure?
          </p>
          <p>
            <ds-button
              variant="secondary"
              :icon="busy ? 'spinner' : 'check'"
              :label="busy ? 'Saving...' : 'Remove'"
              @click="handleRemoveRelation"
            />
          </p>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from './Modal.vue'

import { updateBulkActor } from '../../api/actors'



export default {
  name: 'remove-relation-modal',
  data () {
    return {
      busy: false,
      errors: {},
      modalId: 'REMOVE_RELATION'
    }
  },
  computed: {
    type () {
      return this.$store.state.ui.modalContext.prop || 'accelerated_by'
    },
    changes () {
      const _type = '_' + this.type
      const to = this.$store.state.ui.modalContext.to
      const actors = this.$store.state.ui.modalContext.actors || []
      return actors.map(a => {
        if (a[_type] && a[_type].length && a[_type].filter(r => r.to === to).length) {
          const b = { id: a.id }
          b[this.type] = a[_type].filter(r => r.to !== to)
          return b
        }
      }).filter(Boolean)
    },
    title () {
      return 'Remove relation'
    }
  },
  methods: {
    handleRemoveRelation (relationType, actor) {
      this.busy = true
      updateBulkActor(this.changes).then(() => {
        this.busy = false
        this.$bus.emit('actorUpdated')
        this.$refs.modal.close()
      })
    }
  },
  components: {
    Modal
  }
}
</script>
