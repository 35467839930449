<template>
  <div class="page-info scrollable">
    <div class="page-info__content">
      <div class="page-container about about-container" v-html="purifiedContent"></div>
    </div>

    <div class="page-info__content" v-if="infoPage == ''">
      <div class="page-container about about-container">
        <h3>Pexels</h3>
        <i18n-t scope="global" keypath="pexel_disclaimer" tag="span">
          <template v-slot:pexel_link>
            <a href="https://pexels.com/" target="_blank" rel="noopener noreferrer">Pexels</a>
          </template>
        </i18n-t>
      </div>
    </div>

    <!--    <div class="page-info__content" v-if="infoPage == ''">
          <div class="page-container about about-container">
            <h3>New Zealand Business Number</h3>
            <span>
              The NZBN Register contains information about New Zealand businesses, including all companies and other entity types from Companies Office registers, all public sector entities, and sole traders, partnerships and trusts that have chosen to register for an NZBN. You can find out more on the <a href="https://www.nzbn.govt.nz/whats-an-nzbn/about" target="_blank" rel="noopener noreferrer">NZBN website</a>.
            </span>
          </div>
        </div>-->
  </div>
</template>

<script>
  import { fetchPage } from '../api/contents.js'
  import TranslationsMixin from '../util/TranslationsMixin.js'
  import DOMPurify from 'dompurify'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'info',
    data () {
      return {
        contents: {
          description: '',
          translations: {},
        },
        infoPage: this.$route.params.infoPage || '',
      }
    },
    computed: {
      purifiedContent () {
        return DOMPurify.sanitize(this.localizedDisplayProperty(this.contents, 'description'))
      }
    },
    created () {
      var page = 'branding_info'

      if (this.infoPage && this.infoPage.length > 0 && this.infoPage != '/') {
        page = this.infoPage
      }

      // Fetch static content
      fetchPage(page)
        .then(contents => {
          this.contents = contents
        })
    },
    mixins: [TranslationsMixin],
  })
</script>

<style lang="scss">
  .about-container {
    font-size: 14px;

    p {
      font-size: 14px;
    }

    img {
      max-height: 400px !important;
      max-width: 1200px !important;
      display: block;
      margin: auto;
    }

    li {
      margin: 0;

      p {
        margin: 0;
      }
    }
  }
</style>
