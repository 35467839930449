<template>
  <div class="communities-card">
    <div class="communities-card__title">
      {{ title }}
    </div>
    <div class="communities-card__subtitle">
      {{ subtitle }}
    </div>
    <div class="communities-card__content">
      {{ content }}
    </div>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      title: String,
      subtitle: String,
      content: String,
    },
  })
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables";

  .communities-card {
    box-shadow: $simplified-box-shadow-config;
    border-radius: $default-border-radius;
    margin-bottom: 20px;
    padding: 1rem;

    .communities-card__title {
      text-overflow: ellipsis;
      font-size: 16px;
      margin-bottom: 1rem;
      font-weight: 500;
      text-align: center;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
    }

    .communities-card__subtitle {
      font-size: 14px;
      color: $color-text-grey;
      margin-bottom: 1rem;
    }

    .communities-card__content {
      font-size: 12px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      line-height: 14px;
      max-height: 70px;
      text-align: center;
    }
  }

</style>
