<template>
  <span :class="`highlighted-text highlighted-text--color-scheme-${colorScheme}`">
    <span
      class="highlighted-text__section"
      v-for="section in sections"
      :class="{ 'highlighted-text__section--highlighted': section.highlighted }"
      v-html="section.string"
    ></span>
  </span>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: "HighlightedText",
    props: {
      caseSensitive: {
        type: Boolean,
        default: false,
      },
      colorScheme: {
        type: String,
        default: 'default',
        validator: val => ['default'].includes(val)
      },
      string: String,
      stringToHighlight: String,
    },
    computed: {
      sections() {
        if (!this.stringToHighlight) {
          return [{
            string: this.string,
            highlighted: false,
          }];
        }

        const regex = new RegExp(`(${this.stringToHighlight})`, this.caseSensitive ? 'g' : 'gi');
        return this.string.split(regex).map((token) => {
          return {
            string: token,
            highlighted: this.caseSensitive ? token === this.stringToHighlight : token.toLowerCase() === this.stringToHighlight.toLowerCase(),
          }
        });
      }
    }
  })
</script>

<style lang="scss">
  @import "../../../scss/variables";

  .highlighted-text--color-scheme-default {
    .highlighted-text__section--highlighted {
      background-color: #E1FAF6;
      color: #3BDDBB;
    }
  }


</style>
