<template>
  <div>
    <h2 class="h2">Configure optional actor profile features</h2>
    <p>
      These options are applied to all actor profiles, not taking into account the enrichment configuration provided in
      the previous tabs.
    </p>

    <form-group>
      <h3 class="h3">Smart Directory & Analytics Dashboards</h3>
      <checkbox label="Enabled Customised Reporting" v-model="isKnowledgeSharingEnabled"/>
      <checkbox
        label="Enable automatically translating descriptions, twitter bio and tags"
        v-model="form.translationEnabled"
      />
    </form-group>

    <div class="settings__ecosystem-full-width" v-if="hasAccessToPublisher">
      <div class="row">
        <div class="col-md-6 col-xs-12">
          <h3 class="h3">Ecosystem Publisher</h3>
          <checkbox label="Make your ecosystem publicly accessible." v-model="isPublisherEnabled"/>
        </div>
      </div>

      <div class="row" v-if="isPublisherEnabled">
        <div class="col-md-6 col-xs-12">
          <h3 class="h3">Community</h3>
          <form-group label="User interaction">
            <div class="checkbox-with-helper-icon">
              <checkbox v-model="form.actorsCanBeContacted" label="Allow users to contact other users."/>
              &nbsp;<div
              v-tooltip.top-center="{content: this.tooltipContent('actorsCanBeContacted'),html: true,popperClass: ['large-tooltip']}"
            >
              <icon name="information" size="14"/>
            </div>
            </div>
            <checkbox
              v-model="form.ecosystemMemberWeeklyDigestEnabled"
              label="Activate sending an automated weekly digest to all ecosystem members in the ecosystem."
            />
            <!--            <checkbox label="Activate ecosystem newsletters, this allows you to configure and send custom newsletters to your ecosystem members." v-model="form.newsletter"/>-->
            <checkbox v-model="form.enableFreeTextTags" label="Allow users enter and search on free text tags."/>
          </form-group>
          <div class="choose-ecosystem-owner">
            <p class="h3">Select an ecosystem owner to serve as the primary contact for your ecosystem.</p>
            <dropdown
              placeholder="Select an ecosystem owner that can be contacted"
              v-model="form.ecosystem_owner_contact"
              :search="true"
              :options="owners"
              @search-change="fetchUsers"
            />
          </div>
        </div>
        <div class="col-md-6 col-xs-12">
          <form-group
            label="Enable additional taxonomies"
            v-if="isPublisherEnabled && hasAccessToPublisher && $store.getters.isDeveloper"
            style="margin-top: 1rem;"
          >
            <checkbox label="Activate Project Readiness" v-model="form.taxonomyReadinessEnabled"/>
            <checkbox label="Activate Business Aspects" v-model="form.taxonomyBusinessAspectsEnabled"/>
            <checkbox label="Activate Process steps" v-model="form.taxonomyProcessStepsEnabled"/>
            <checkbox label="Activate Aspects" v-model="form.taxonomyAspectsEnabled"/>
          </form-group>
        </div>
      </div>

      <div class="row" v-if="canUseI18N">
        <div class="col-md-6 col-xs-12">
          <h3 class="h3">Languages</h3>
          <span class="form-group__label__text" style="margin-bottom: 10px;">Only applicable on <b>simplified pages</b> for visitors and ecosystem members</span>
          <dropdown multiple v-model="form.languages" :options="supportedLanguages" style="max-width: 250px;"/>
        </div>
      </div>
    </div>

    <form-group>
      <h3 class="h3">Rules for calculating revenue</h3>

      <div class="row">
        <form-group
          label="Average € revenue per FTE (typically between 100K and 200K)" class="col-xs-10"
          style="padding-right: 5px"
        >
          <div class="average-revenue__per-fte-input">
            <ds-input
              type="amount" precision="5" v-model="form.averageRevenuePerFTE" placeholder="Amount"
              style="width: 6rem;"
            />
          </div>
        </form-group>
      </div>
    </form-group>

  </div>
</template>

<script>
  import Checkbox from '../../Form/Checkbox.vue'
  import Dropdown from '../../Dropdown/Dropdown.vue'
  import DsInput from '../../Form/DsInput.vue'
  import DsTextarea from '../../Form/DsTextarea.vue'
  import ImageInput from '../../Form/ImageInput.vue'
  import TagInput from '../../Form/TagInput.vue'
  import ToggleButton from '../../Form/ToggleButton.vue'

  import { emptyConfig } from '../../../util/config'
  import { propertyGroups } from '../../../constants/config'
  import { searchUser } from '@/api/config.js'

  export default {
    name: 'subscription-other',
    props: ['form'],
    data () {
      return {
        currencies: ['AUD', 'CAD', 'CHF', 'EUR', 'GBP', 'JPY', 'USD', 'ZAR'],
        supportedLanguages: ['nl'],
        owners: [],
      }
    },
    mounted () {
      this.fetchUsers()
    },
    methods: {
      fetchUsers (search) {
        searchUser({ query: search, role: 'owner' }).then(response => {
          if (response) {
            this.owners = response.filter(user => !user.invited_user)

            this.owners = this.owners.map(user => {
              return {
                value: user.id,
                label: user.name + ' (' + user.email + ')',
              }
            })
          }
        })
      },
      enablePublisher () {
        // Empty config for reactivity
        const config = Object.assign(emptyConfig(), {

          // Add defaults that MUST override the parent config
          // Also, let's copy the parent view config
          public_fields: propertyGroups.Base.slice(),
          viewActorTypes: (this.form.viewActorTypes || ['LegalEntity']).slice(),
          viewDashboard: this.form.viewDashboard || [],
          viewGallery: this.form.viewGallery || false,
          viewList: this.form.viewList || [],
          viewMap: this.form.viewMap || [],
          defaultLegendProperty: this.form.defaultLegendProperty,
          availableLegendItems: this.form.availableLegendItems,

          // Add previous publisher config
        }, this.form.publisherDisabled || {}, {

          // These options are defined in the parent config
          allow_anonymous: null,
          allowAdd: null,
          allowRegister: null,
        })

        this.form.publisher = config

        delete this.form.publisherDisabled
      },
      disablePublisher () {
        this.form.publisherDisabled = this.form.publisher
        this.form.publisher = null
        this.form.allow_anonymous = null
        this.form.allowRegister = null
        this.form.allowAdd = null
      },
      resetPublisher () {
        delete this.form.publisherDisabled

        this.form.publisher = null
      },
      tooltipContent (type) {
        var description = ''

        if (type === 'actorsCanBeContacted') {
          const message = `Let users interact with each other by:
          \n- starting new conversations throughout the application;\r\n- showing suggested conversations while searching the Conversations List;\r\n- mentioning users in comments.`

          description += message.replace(/(?:\r\n|\r|\n)/g, '<br>')
        }

        return description
      },
    },
    computed: {
      canUseI18N () {
        return true;
        //return ['startupsbe', 'mom', 'makersplatform', 'klik', 'stadgent', 'local', 'own', 'local-onboarding', 'opencreativesleuven'].includes(this.$store.getters.ecosystemName.toLowerCase())
      },
      hasAccessToBuzz () {
        return this.$store.getters.hasAccessToBuzz
      },
      isKnowledgeSharingEnabled: {
        get () {
          return this.form.knowledgeSharing ? this.form.knowledgeSharing.enabled : false
        },
        set (v) {
          if (this.form.knowledgeSharing) {
            this.form.knowledgeSharing.enabled = v
          } else {
            this.form.knowledgeSharing = {
              actorTypes: [],
              curation: false,
              enabled: v,
              interval: null,
            }
          }
        },
      },
      languages: {
        get () {
          if (!this.form.languages) {
            this.form.languages = []
          }

          return this.form.languages
        },
        set (v) {
          console.log(v)
        },
      },
      hasAccessToPublisher () {
        return this.$store.getters.hasAccessToPublisher
      },
      isPublisherEnabled: {
        get () {
          return !!this.form.publisher
        },
        set (v) {
          if (v) {
            this.enablePublisher()
          } else {
            this.disablePublisher()
          }
        },
      }
    },
    components: {
      Checkbox,
      Dropdown,
      DsInput,
      DsTextarea,
      ImageInput,
      TagInput,
      ToggleButton,
    },
  }
</script>
<style scoped>
  .checkbox-with-helper-icon {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    vertical-align: middle;
    align-items: center;
  }

  .average-revenue__per-fte-input, .average-margin__input {
    display: flex;
    align-items: center;
    position: relative;
  }

  .average-revenue__per-fte-input .dropdown {
    position: absolute;
    left: 0;
    z-index: 1;
  }

  .choose-ecosystem-owner {
    p {
      margin-bottom: 0.5rem;
    }

    .multiselect {
      width: 20rem !important;
    }

  }
</style>
