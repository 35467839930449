<template>
  <div class="general-error">{{ message }}</div>
</template>

<script>
export default {
  props: ['title', 'message']
}
</script>

<style lang="scss">
.general-error {
  padding-top: calc(20vh);
  font-size: 4vh;
  line-height: 1em;
  text-align: center;
  opacity: .3;
}
.general-error--middle {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0;
  height: 100%;
  width: 100%;
}
</style>
