<template>
  <form class="form-horizontal" role="form" @submit.prevent="send">
    <h2 class="h2">Send Invitation</h2>

    <form-group label="Email address" :errors="errors.email">
      <ds-input v-model="form.email" />
    </form-group>
    <form-group label="Role" v-if="$root.allowedRoles.length > 1" :errors="errors.role">
      <radio-button
        v-for="(role, index) in $root.allowedRoles"
        :key="index"
        v-model="form.role"
        name="role"
        :label="role.text"
        :val="role.value"
        :required="true"
      />
    </form-group>

    <ds-button type="submit" variant="secondary" label="Send invitation" :disabled="form.busy" />

    <span v-text="success"></span>
  </form>
</template>

<script>
import DsInput from '../Form/DsInput.vue'
import FormGroup from '../Form/FormGroup.vue'
import RadioButton from '../Form/RadioButton.vue'

import { sendInvitation } from '../../api/config'



export default {
  data () {
    return {
      success: '',
      errors: {},
      form: {
        busy: false,
        role: 'actor',
        email: null
      }
    }
  },
  methods: {
    send () {
      this.form.busy = true
      sendInvitation(this.form)
        .then(() => {
          this.success = 'An invitation email has been sent to ' + this.form.email
          this.form.email = ''
          this.$bus.emit('fetchInvites')
        })
        .catch(errors => {
          this.errors = errors
        })
        .then(() => {
          this.form.busy = false
        })
    }
  },
  components: {
    DsInput,
    FormGroup,
    RadioButton
  }
}
</script>
