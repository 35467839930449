<template>
  <div class="image-input">
    <img v-if="validImage" class="image-input__image" :src="modelValue" @error="reset">
    <div v-else class="image-input__text">{{ placeholder || 'Company logo' }}</div>
    <div class="image-input__text image-input__action">Upload</div>
    <input @change="previewThumbnail" class="image-input__input" name="thumbnail" type="file">
  </div>
</template>

<script>
  export default {
    props: ['modelValue', 'placeholder'],
    emits: ['update:modelValue'],
    data () {
      return {
        error: null
      }
    },
    computed: {
      validImage () {
        return this.modelValue && this.modelValue !== this.error && this.modelValue !== 'images/logos/undefined'
      }
    },
    methods: {
      previewThumbnail (event) {
        const input = event.target || event.srcElement

        if (input.files && input.files[0]) {
          const reader = new window.FileReader()

          reader.onload = (e) => {
            this.$emit('update:modelValue', e.target.result)
          }

          reader.readAsDataURL(input.files[0])
        }
      },
      reset () {
        console.warn('ImageInput received an invalid image:', this.modelValue)
        this.error = this.modelValue
      }
    }
  }
</script>
