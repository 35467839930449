<template>
  <AutocompleteDropdown
    :model-value="modelValue"
    :placeholder="placeholder"
    :do-search="autocompleteSearchTerm"
    :noResultsHandlerEnabled="true"
    @addNewItem="handleCustomKeyword"
    @update:modelValue="handleInput"
  />
</template>

<script lang="ts">
  import AutocompleteDropdown from '../../components/Dropdown/AutocompleteDropdown.vue'
  import { fetchConceptSuggestions } from '../../api/exploration.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: ['modelValue', 'placeholder'],
    emits: ['update:modelValue'],
    methods: {
      autocompleteSearchTerm (query) {
        return fetchConceptSuggestions({ query: query, conceptType: 'any' })
          .then(response => {
            const options = []

            response.forEach(function (concept) {
              options.push({
                label: concept.value,
                value: concept.uri
              })
            })

            return options
          })
          .catch(err => {
            console.log(err)
          })
      },
      handleInput (value) {
        this.$emit('update:modelValue', value)
      },
      handleCustomKeyword (value) {
        this.$emit('update:modelValue', {
          value,
          label: value,
        })
      },
    },
    components: {
      AutocompleteDropdown,
    },
  })
</script>
