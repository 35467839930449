<template>
  <div class="authentication-modal authentication-modal--large">
    <modal :id="modalId" ref="modal" :title="$t('forgot_password_title')" :enable-mask="false" :is-closeable="isCloseable" @close="$emit('close')">
      <template v-slot:body>
        <form class="form" @submit.prevent="submit">
          <fieldset>
            <div class="form-group" :class="{ error: emailErrors && emailErrors.length}">
              <input class="input" placeholder="mail@address.com" id="password-reset-email" v-model="email" @input="emailErrors=[]" style="max-width: 310px;">
            </div>
            <p v-for="msg in emailErrors" v-text="msg === 'forgotpassword.forgot_password_passwords_user' ? 'Please enter a valid email address' : msg" class="form-msg error"></p>
            <p v-if="message" v-text="message"></p>
            <span></span>
          </fieldset>
          <fieldset class="action-buttons" v-if="!sent">
            <ds-button type="submit" variant="secondary" icon="chevron-right" :label="$t('forgot_password_send_password_link')" :disabled="sending"></ds-button>
          </fieldset>
        </form>

        <br>

        <ul class="links">
          <li><a href="#" @click.prevent="openLoginModal">Login</a></li>
        </ul>
      </template>
    </modal>
  </div>
</template>

<script>
  import { forgotPasswordRequest } from '../../api/user'

  import Modal from './Modal.vue'
  import AuthenticationModal from './AuthenticationModal.vue'
  import MODAL_IDS from '../../constants/modal-ids'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'

  export default {
    extends: AuthenticationModal,
    name: 'forgot-password-modal',
    data () {
      return {
        email: this.$route.query.email || window.email || '',
        emailErrors: [],
        message: null,
        modalId: MODAL_IDS.FORGOT_PASSWORD,
        sent: false,
        sending: false
      }
    },
    props: {
      isCloseable: {
        type: Boolean,
        default: true
      },
    },
    methods: {
      performValidation () {
        if (!this.email) {
          this.emailErrors = ['The email field is required.']
        }
      },
      submit () {
        this.sending = true
        this.emailErrors = []

        this.performValidation()

        if (this.emailErrors.length) {
          return
        }

        const data = {
          email: this.email,
        }

        forgotPasswordRequest(data)
          .then(data => {
            this.sending = false
            this.sent = true
            this.message = data.message || 'We sent an email with a password reset link.'
            this.emailErrors = []
          })
          .catch(errors => {
            this.sending = false
            this.message = errors.email ? '' : 'Failed to send a password reset link.'
            this.emailErrors = errors.email || []
          })
      },
      openLoginModal () {
        if (this.$route.name === 'forgot-password') {
          this.$router.push('/login')
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
        }
        this.$router.push({
          query: { email: this.email }
        })
      },
    },
    components: {
      Modal
    }
  }
</script>
