<template>
  <div class="items" v-show="items.query && items.query.length > 1">
    <template v-if="items.data && items.data.length">
      <button
        class="item"
        :class="{ 'is-selected': index === selectedIndex }"
        v-for="(item, index) in items.data"
        :key="index"
        @click="selectItem(index)"
      >
        <i v-if="item.icon" :class="item.icon"></i> {{ item.name }}
      </button>
    </template>
    <div class="item" v-else>
      {{ $t('actor_mention_no_result') }}
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      items: {
        type: Object,
        default: () => {
          return { query: '', data: [] }
        },
      },
      command: {
        type: Function,
        required: true,
      },
    },
    data () {
      return {
        selectedIndex: 0,
      }
    },
    methods: {
      onKeyDown ({ event }) {
        if (event.key === 'ArrowUp') {
          this.upHandler()
          return true
        }

        if (event.key === 'ArrowDown') {
          this.downHandler()
          return true
        }

        if (event.key === 'Enter') {
          this.enterHandler()
          return true
        }

        return false
      },
      upHandler () {
        this.selectedIndex = ((this.selectedIndex + this.items.data.length) - 1) % this.items.data.length
      },
      downHandler () {
        this.selectedIndex = (this.selectedIndex + 1) % this.items.data.length
      },
      enterHandler () {
        this.selectItem(this.selectedIndex)
      },
      selectItem (index) {
        const item = this.items.data[index]

        if (item) {
          this.command({ id: item.value, label: item.name })
        }
      },
    },
    watch: {
      'items.data' () {
        this.selectedIndex = 0
      },
    },
  }
</script>

<style lang="scss" scoped>
  .items {
    padding: 0.2rem;
    position: relative;
    border-radius: 0.5rem;
    background: #FFF;
    color: rgba(0, 0, 0, 0.8);
    overflow: hidden;
    font-size: 0.9rem;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.05),
    0px 10px 20px rgba(0, 0, 0, 0.1);
  }

  .item {
    display: block;
    margin: 0;
    width: 100%;
    text-align: left;
    background: transparent;
    border-radius: 0.4rem;
    border: 1px solid transparent;
    padding: 0.2rem 0.4rem;

    &.is-selected {
      border-color: #000;
    }
  }
</style>
