<template>
  <div class="card" :class="cardClasses" @click="onClick">
    <div class="pull-right" style="cursor: pointer" v-if="closable"><icon name="remove" @click.stop="onClose" /></div>
    <div class="card__cover" :style="coverStyling" v-if="cover"></div>
    <div class="card__title" v-if="title">{{ title }}</div>
    <div class="card__sub-title" v-if="subTitle">{{ subTitle }}</div>
    <div class="card__description">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  import Avatar from './../Avatar/Avatar.vue'
  import Icon from '../Icon/Icon.vue'

  export default {
    props: [
      'cover',
      'title',
      'subTitle',
      'description',
      'variant',
      'avatar',
      'closable'
    ],
    emits: ['click'],
    data() {
      return {
        cardClasses: [
          this.variant && `card--${this.variant}`,
          this.cover ? 'card--cover' : null,
        ]
      }
    },
    computed: {
      coverStyling() {
        return { backgroundImage: `url(${this.cover})` };
      }
    },
    methods: {
      onClick(evt) {
        this.$emit('click', evt);
      },
      onClose(evt) {
        this.$emit('close', evt);
      }
    },
    components: {
      Avatar,
      Icon
    }
  }
</script>
