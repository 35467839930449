<template>
  <modal
    :id="modalId"
    ref="modal"
    :title="$t('community_create')"
    @close="close"
    is-simplified-modal
  >
    <!-- Modal header tabs -->
    <template v-slot:body>
      <div class="community-edit__title-container">
        <div class="mandatory-message">
          <i>{{ $t('all_fields_with_asterisk_are_mandatory') }}</i>
        </div>

        <ds-input
          v-model="community.name"
          :placeholder="$t('community_edit_title') + ' *'"
          :maxLength="30"
        />
        <p v-if="fieldErrors && fieldErrors.name != null" style="display: inline-block; margin-right: 1em;" class="guide_text form-group__error">{{ fieldErrors.name[0] }}</p>
      </div>

      <div class="community-edit__content-container">
        <form-group label="Draft" instructions="Draft communities will be kept hidden from the general public.">
          <dropdown
            v-model="community.draft"
            :options="[{ value: false, label: 'No' }, { value: true, label: 'Yes' }]"
          />
        </form-group>

        <form-group label="Visibility" instructions="Public communities are visible to all users. Private only to members.">
          <dropdown
            v-model="community.visibility"
            :options="visibilityOptions"
          />
        </form-group>

        <form-group
          label="Accessibility"
          info-text="Members of the community will need to adhere to the access level set here. If the access level requires a claimed profile, users will need to claim an actor profile even though they are members of the community."
          instructions="Define which type of users can access the community"
        >
          <dropdown
            v-model="community.accessLevel"
            :options="accessLevelOptions"
          />
        </form-group>

        <form-group
          label="Community manager *"
          class="ecosystem-member-communities__general-input"
        >
          <autocomplete-dropdown
            is-simplified
            v-model="community.communityManager"
            :placeholder="$t('communities_search_for_users')"
            :suggestions="displayedUserSuggestions"
            :allow-clear="true"
            :do-search="autocompleteSearchTerm"
            :multiple="false"
          />
        </form-group>
        <br>

        <form-group label="Description *">
          <ds-textarea
            placeholder="Provide a description of your community"
            v-model="community.description"
            class="communities__description"
          />
        </form-group>
        <br>
      </div>
    </template>
    <template v-slot:footer>
      <div style="width: 100%;">
        <ds-button
          size="extra-small"
          variant="primary"
          :label="$t('community_create')"
          :disabled="!canSubmit"
          @click="submit()"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import DsTextarea from '../Form/DsTextarea.vue'
  import ImageInput from '../Form/ImageInput.vue'
  import AutocompleteDropdown from '../Dropdown/AutocompleteDropdown.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'

  import { ManageCommunities } from '../../api/communities'
  import { searchUser } from '../../api/config'
  import Dropdown from '@/components/Dropdown/Dropdown.vue'
  import CommunityMixin from '@/util/CommunityMixin'

  export default {
    name: 'CreateCommunityModal',
    data () {
      return {
        modalId: MODAL_IDS.CREATE_COMMUNITY,
        community: {
          name: '',
          description: '',
          communityManager: null,
          color: '#000000',
          draft: true,
          accessLevel: 'any_user',
          visibility: 'public'
        },
        fieldErrors: {},
        userSuggestions: [],
        errors: {},
        saving: false
      }
    },
    mixins: [CommunityMixin],
    computed: {
      displayedUserSuggestions () {
        return this.userSuggestions
      },
      canSubmit () {
        return this.community.name &&
          this.community.name.length > 2 &&
          (this.community.communityManager && this.community.communityManager.value) &&
          this.community.description.length >= 10 &&
          !this.saving
      },
    },
    methods: {
      autocompleteSearchTerm (query) {
        return searchUser({
          query: query,
          role: 'member,owner,portfolio_member'
        })
          .then(suggestions => {
            const options = []
            suggestions = this.removeDuplicates(suggestions)

            suggestions
              .forEach(function (user) {
                options.push({
                  label: user.name + ' (' + user.email + ')',
                  value: user.id
                })
              })

            return options
          })
          .catch(err => {
            console.log(err)
          })
      },
      removeDuplicates (suggestions) {
        return suggestions
      },
      submit () {
        this.saving = true
        this.fieldErrors = null
        this.errors = null

        const newCommunity = {...this.community}
        newCommunity.communityManagerId = newCommunity.communityManager.value

        ManageCommunities
          .post(newCommunity)
          .then(response => {
            setTimeout(() => {
              this.$bus.emit('communityCreated', {id: response.id})

              this.success()
            }, 1000)
          })
          .catch(errors => {
            this.saving = false
            this.fieldErrors = errors
            this.errors = Object.keys(errors).filter(key => key !== 'statusCode')
          })
      },
      success () {
        this.saving = false

        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          message: this.$t('community_create_portfolio_success')
        })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.SUCCESS)
      },
      close () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.CREATE_COMMUNITY)
        this.$emit('close')
      }
    },
    components: {
      Dropdown,
      DsTextarea,
      ImageInput,
      Modal,
      AutocompleteDropdown
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/_variables.scss";

  .community-edit__title-container {
    background: var(--primary-extra-lightest);
    padding: 20px;

    :deep(input) {
      border: 0;
      border-radius: $default-border-radius-narrow;

      &::placeholder {
        color: var(--primary);
      }
    }
  }

  .communities__description {
    min-height: 75px;
    margin-bottom: 20px;
  }

  .community-edit__content-container {
    padding: 20px 20px 17px 20px;

    :deep(input) {
      border: 1;
      border-radius: $default-border-radius-narrow;
      border-color: var(--primary-extra-lightest) !important;

      &::placeholder {
        color: var(--primary);
      }
    }
  }

  .modal__footer {
    width: 100%;

    .button {
      width: 100%;
      border-radius: $default-border-radius-narrow;
    }
  }

  .d-flex {
    display: flex;
  }
</style>
