import {
  createJSONRequest,
} from './create-request'

export const fetchRelationships = () => createJSONRequest({
  url: '/api/actor-relationships',
  method: 'GET',
})

export const createRelationship = (data) => createJSONRequest({
  url: '/api/actor-relationships',
  method: 'POST',
  data
})

export const updateRelationship = (relationshipId, data) => createJSONRequest({
  url: `/api/actor-relationships/${relationshipId}`,
  method: 'PUT',
  data
})

export const deleteRelationship = (relationshipId) => createJSONRequest({
  url: `/api/actor-relationships/${relationshipId}`,
  method: 'DELETE'
})

export const countRelationships = (relationshipId) => createJSONRequest({
  url: `/api/actor-relationships/${relationshipId}/count`,
  method: 'GET'
})

