<template>
  <div>
    <form-group label="Site template">
      <SiteTemplateSelection v-model="siteTemplate"/>
    </form-group>

    <h2 class="h2">Actor Profile Management</h2>

    <form-group label="Available actor types" v-if="permissions.viewActorTypes">
      <checkbox model-value="LegalEntity" label="Organization" inline disabled val="LegalEntity"/>
      <checkbox v-model="permissions.viewActorTypes" inline multiple val="Person"/>
      <checkbox v-model="permissions.viewActorTypes" inline multiple val="Product" :label="productLabel"/>
    </form-group>

    <div class="row">
      <div class="col-xs-12 col-md-6 col-lg-4 col-xl-3">
        <form-group :label="`How many Organizations can a user create`">
          <checkbox type="radio" v-model="createLegalEntityPolicy" val="none"/>
          <checkbox type="radio" v-model="createLegalEntityPolicy" val="one"/>
          <checkbox type="radio" v-model="createLegalEntityPolicy" val="many"/>
        </form-group>
        <span class="actor-types__help-text">
          Person remains at 'one' and can be toggled off by using the checkboxes with the actor types
        </span>
      </div>

      <div class="col-xs-12 col-md-6 col-lg-4 col-xl-3">
        <form-group :label="`How many ${productsLabel} can a user create`">
          <checkbox type="radio" v-model="createProductPolicy" val="none"/>
          <checkbox type="radio" v-model="createProductPolicy" val="one"/>
          <checkbox type="radio" v-model="createProductPolicy" val="many"/>
        </form-group>
      </div>
    </div>

    <template v-if="this.$store.getters.hasAccessToMonitoring">
      <h2 class="h2" style="margin-bottom: 0px;">Content</h2>
      <form-group label="Choose which type of articles content can be viewed">
        <CuratedContentSelection v-model="permissions.monitoringContentMode"/>
      </form-group>
    </template>

    <template v-if="permissions.viewDashboard && permissions.viewDashboard.includes('knowledge-base') && $store.getters.isNewKnowledgeBaseEnabled">
      <h2 class="h2" style="margin-bottom: 0px;">Knowledge Base</h2>
      <form-group label="View content">
        <checkbox type="radio" v-model="knowledgeBaseViewType" val="all" label="Everything"/>
        <checkbox type="radio" v-model="knowledgeBaseViewType" val="upload_only" label="Uploaded content only"/>
      </form-group>

      <form-group label="Upload Content">
        <checkbox label="Allow to upload content" v-model="canUploadContentToKnowledgeBase" inline :val="true"/>
        <checkbox label="Curation: owners must accept uploaded resources" v-model="shouldCurateContentToKnowledgeBase" inline :val="true"/>
      </form-group>
    </template>

    <template v-if="$store.getters.areChallengesEnabled">
      <h2 class="h2">Challenges</h2>

      <checkbox v-model="permissions.canCreateChallenge" :label="`Can create ${challengeLabel}`" :val="true"/>
      <checkbox
        v-model="permissions.canCreatePrivateChallenge"
        :label="`Can choose to make their ${challengesLabel} private`"
        :val="true"
        :disabled="!permissions.canCreateChallenge"
      />
      <checkbox
        v-model="permissions.challengeMatchmakingEnabled"
        :label="`Allow them to use matchmaking for their ${challengesLabel}`"
        :val="true"
        :disabled="!permissions.canCreateChallenge"
        v-if="isDeveloper"
      />

      <br/>
      <form-group :label="`View ${challengesLabel}, except for the detail pages`">
        <checkbox v-model="permissions.viewableChallengeStatusses" inline multiple val="new"/>
        <checkbox v-model="permissions.viewableChallengeStatusses" inline multiple val="open"/>
        <checkbox v-model="permissions.viewableChallengeStatusses" inline multiple val="solved"/>
      </form-group>

      <form-group :label="`View ${challengeLabel} details`">
        <checkbox v-model="permissions.accessibleChallengeStatusses" inline multiple val="new"/>
        <checkbox v-model="permissions.accessibleChallengeStatusses" inline multiple val="open"/>
        <checkbox v-model="permissions.accessibleChallengeStatusses" inline multiple val="solved"/>
      </form-group>
    </template>

    <h2 class="h2">{{ announcementsAndEventsLabel }}</h2>
    <checkbox v-model="canCreateAnnouncement" :label="`Can create ${announcementsAndEventsLabel}`" :disabled="!areMessageBoardsEnabled"/>
    <checkbox v-model="canViewPublicAnnouncements" :label="`Can view public ${announcementsAndEventsLabel}`" :disabled="!areMessageBoardsEnabled"/>
    <br/><br/>

    <h2 class="h2" style="margin-top: 1em;">Views</h2>

    <template v-if="siteTemplate === SiteTemplate.TRADITIONAL">
      <form-group label="Homepage - Buzz">
        <checkbox v-model="enableFundingBuzz" label="Display the latest funding rounds."/>
        <checkbox v-model="enableEventsBuzz" label="Display the latest events from your ecosystem."/>
        <checkbox v-model="enableInfluencersBuzz" label="Display the most influential actors from your ecosystem."/>
      </form-group>

      <form-group label="Directory views">
        <checkbox v-model="permissions.viewList" label="Show the list page"/>
        <checkbox v-model="permissions.viewGallery" label="Show the gallery page"/>
        <checkbox v-model="permissions.viewMap" label="Show the map page"/>
      </form-group>
    </template>

    <form-group label="Enabled views">
      <checkbox v-model="permissions.views" inline multiple val="actors/detail" label="View full actor detail"/>
      <checkbox v-model="canViewActorConnections" label="View actor connections"/>
    </form-group>

    <form-group label="Actor Reports - Grant access to actor reports of the actor types selected below" style="width: 30rem;">
      <dropdown :options="selectableActorTypes" v-model="viewReportOfActorTypes" :multiple="true"/>
    </form-group>

    <template v-if="siteTemplate === SiteTemplate.TRADITIONAL">
      <form-group label="List view columns">
        <dropdown v-model="permissions.viewList" multiple :options="viewListOptions"/>
      </form-group>
    </template>

    <form-group label="Enabled dashboards">
      <dropdown multiple v-model="permissions.viewDashboard" :options="viewDashboardOptions"/>
    </form-group>

    <h2 class="h2">Directory settings</h2>

    <form-group label="Legend property">
      <dropdown v-model="permissions.defaultLegendProperty" :options="legendPropertyOptions"/>
    </form-group>

    <form-group :label="permissions.defaultLegendProperty">
      <dropdown
        multiple v-model="availableLegendItems"
        :options="getLegendOptions(permissions.defaultLegendProperty, permissions)"
      />
    </form-group>

    <form-group label="Available filter controls for actors" style="margin-top: 10px;">
      <dropdown v-model="permissions.filterControls" :options="availableFilterControlOptionsActor" multiple @update:modelValue="onUpdateActorFilterControls"/>
    </form-group>
    <form-group label="Configure what filter controls are visible on the directory and on the actor page" style="margin-top: 10px">
      <dropdown
        :model-value="permissions.simplifiedFilterControls ? ['sustainability_goal', ...permissions.simplifiedFilterControls] : ['sustainability_goal']"
        @update:modelValue="permissions['simplifiedFilterControls'] = $event.filter(value => value !== 'sustainability_goal')"
        :options="visibleFilterControlOptionsActor" multiple
        class="dropdown-with-hardcoded-sdg"
      />
    </form-group>

    <form-group v-if="siteTemplate === SiteTemplate.NEW_SIMPLIFIED">
      <checkbox
        v-model="permissions.allowAllFilters"
        :label="`Show \'more search options\' on Simplified Actors overview`"
      />
    </form-group>

    <template v-if="permissions.viewActorTypes && permissions.viewActorTypes.includes('Product')">
      <h2 class="h2">{{ productLabel }} Gallery settings</h2>

      <form-group label="Legend property">
        <dropdown v-model="productLegendProperty" :options="legendPropertyOptions"/>
      </form-group>

      <form-group :label="productLegendProperty" v-if="productLegendProperty">
        <dropdown
          multiple v-model="availableProductLegendItems"
          :options="getLegendOptions(productLegendProperty, permissions)"
        />
      </form-group>
      <form-group label="Available filter controls for products" style="margin-top: 10px;">
        <dropdown v-model="permissions.filterControlsProducts" :options="availableFilterControlOptionsProduct" multiple @update:modelValue="onUpdateProductFilterControls"/>
      </form-group>
      <form-group
        v-if="siteTemplate === SiteTemplate.NEW_SIMPLIFIED"
        label="Configure what filter controls are visible on the product gallery and on the product page"
        style="margin-top: 10px"
      >
        <dropdown
          :model-value="permissions.simplifiedFilterControlsProducts ? ['sustainability_goal', ...permissions.simplifiedFilterControlsProducts] : ['sustainability_goal']"
          :options="visibleFilterControlOptionsProduct"
          @update:modelValue="permissions['simplifiedFilterControlsProducts'] = $event.filter(value => value !== 'sustainability_goal')" multiple
          class="dropdown-with-hardcoded-sdg"
        />
      </form-group>
      <form-group v-if="siteTemplate === SiteTemplate.NEW_SIMPLIFIED">
        <checkbox
          v-model="permissions.allowAllFiltersProducts"
          :label="`Show \'more search options\' on Simplified ${productsLabel} overview`"
        />
      </form-group>
    </template>

    <template v-if="$store.getters.hasAccessToNewSpottingAreas && $store.getters.isSpottingAreaScopeEnabled">
      <h3>Accessible data</h3>

      <form-group label="Configure what actors can be accessed by selecting one or more portfolios">
        <AutocompleteTagInput
          :tags="viewAccessiblePortfolios"
          :options="portfolioOptions"
          :addOnlyFromAutocomplete="true"
          placeholder="Search for a portfolio"
          @tagChanged="updatePortfolioOptions"
          @input:raw="updateSelectedPortfolios"
          :minInputLength="1"
          style="width: 440px;"
        />
      </form-group>

      <form-group label="Configure what spotting areas & actors can be accessed by selecting one or more spotting areas">
        <label class="form-group__header">
          <div class="form-group__label__text">
            <Icon name="information-outline" size="18" style="vertical-align: sub;"/>
            In order to display the spotting areas, make sure to set their access to 'public' defined on
            <router-link to="/settings/spotting-areas">
              Spotting Areas
            </router-link>
          </div>
        </label>
        <AutocompleteTagInput
          :tags="viewAccessibleSpottingAreas"
          :options="spottingAreaOptions"
          :addOnlyFromAutocomplete="true"
          placeholder="Search for a spotting area"
          @tagChanged="updateSpottingAreaOptions"
          @input:raw="updateSelectedAccessibleSpottingAreas"
          :minInputLength="1"
          style="width: 440px;"
        />
      </form-group>
    </template>

    <div class="row">
      <div class="col-xs-12 col-sm-6">
        <h4>Hidden data</h4>
        <p>
          <small>This user role does <b>NOT</b> have access to the following.</small>
        </p>
        <div class="property-group" v-for="g in privatePropertyGroups" @click.prevent="pushPublic(g.properties)">
          <div>
            {{ g.label }}
          </div>
          <div class="property-group__list">
            {{ g.properties.join(', ') }}
          </div>
        </div>
      </div>
      <div class="col-xs-12 col-sm-6">
        <h4>Visible data</h4>
        <p>
          <small>This user role has access to the following.</small>
        </p>
        <h2 v-if="!permissions.public_fields || !permissions.public_fields.length">Click on a data point on
          the left to make it visible for ecosystem members.</h2>
        <div
          class="property-group" v-for="g in publicPropertyGroups"
          :class="{ 'property-group--disabled': g.label === 'Minimum' }" @click.prevent="splicePublic(g.properties)"
        >
          <div>
            {{ g.label }}
            <small v-if="g.label === 'Minimum'">must be published</small>
          </div>
          <div class="property-group__list">
            {{ g.properties.join(', ') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Checkbox from '../../Form/Checkbox.vue'
  import SiteTemplateSelection from '../Permissions/SiteTemplateSelection.vue'
  import CuratedContentSelection from '../Permissions/CuratedContentSelection.vue'
  import Dropdown from '../../Dropdown/Dropdown.vue'
  import AutocompleteTagInput from '../../Form/AutocompleteTagInput.vue'

  import { SiteTemplate, SPOTTING_AREA_TYPES } from '../../../store/modules/config'
  import { fetchPortfolio } from '../../../api/portfolios'

  import debounce from 'lodash/throttle'
  import { propertyGroups as rawPropertyGroups, viewDashboardOptions, viewListOptions, } from '../../../constants/config'
  import flatten from 'lodash/flatten'
  import toArray from 'lodash/toArray'
  import difference from 'lodash/difference'
  import ConfigMixin from '../../../util/ConfigMixin'
  import PermissionsMixin from '../../../util/PermissionsMixin'
  import TranslationsMixin from '../../../util/TranslationsMixin.js'
  import FormGroup from '@/components/Form/FormGroup.vue'
  import { fetchSpottingAreas } from '@/api/spottingareas'

  export default {
    name: 'EcosystemMemberPackagePermissions.vue',
    props: {
      EMPPermissions: Object,
      selectedSpottingAreas: {
        type: Array,
      },
      selectedPortfolios: {
        type: Array,
      }
    },
    emits: ['permissionsUpdated', 'updateAccessiblePortfolios', 'updateAccessibleSpottingAreas'],
    data () {
      return {
        SiteTemplate,
        spottingAreaOptions: [],
        portfolioOptions: [],
      }
    },
    computed: {
      availableFilterControlOptionsProduct () {
        var eligibleFilterControlOptions = [...this.filterControlOptionsProduct]

        if (this.viewReportOfActorTypes && this.viewReportOfActorTypes.length > 0 && this.viewReportOfActorTypes.includes('Product')) {
          eligibleFilterControlOptions.push({ value: 'reports', label: 'Reports' })
        }

        return eligibleFilterControlOptions
      },
      availableFilterControlOptionsActor () {
        var eligibleFilterControlOptions = [...this.filterControlOptionsActor]

        if (this.viewReportOfActorTypes && this.viewReportOfActorTypes.length > 0) {
          eligibleFilterControlOptions.push({ value: 'reports', label: 'Reports' })
        }

        return eligibleFilterControlOptions
      },
      isDeveloper () {
        return this.$store.getters.isDeveloper
      },
      selectableSpottingAreas () {
        return this.permissions.accessibleSpottingAreas ? this.permissions.accessibleSpottingAreas.map(spottingArea => {
          return { label: spottingArea.name, value: spottingArea.id }
        }) : []
      },
      selectableActorTypes () {
        return this.permissions.viewActorTypes ? this.permissions.viewActorTypes.map(type => {
          const actorTypeLabel = type === 'Product' ? this.productLabel : (type === 'LegalEntity' ? 'Organization' : type)

          return { label: actorTypeLabel, value: type }
        }) : []
      },
      canCreateAnnouncement: {
        get () {
          if (!this.permissions.hasOwnProperty('canCreateAnnouncement')) {
            return true
          }

          return !!this.permissions.canCreateAnnouncement
        },
        set (value) {
          this.permissions.canCreateAnnouncement = value
        },
      },
      canViewActorConnections: {
        get () {
          return this.permissions.canViewActorConnections
        },
        set (value) {
          this.permissions.canViewActorConnections = value
        },
      },
      viewAccessiblePortfolios: {
        get () {
          if (!this.permissions.accessiblePortfolios) {
            return []
          }

          return this.permissions.accessiblePortfolios.filter(portfolio => portfolio) ?? []
        },
        set (value) {
          this.permissions.accessiblePortfolios = value
        }
      },
      viewAccessibleSpottingAreas: {
        get () {
          if (!this.permissions.accessibleSpottingAreas) {
            return []
          }

          return this.permissions.accessibleSpottingAreas.filter(spa => spa) ?? []
        },
        set (value) {
          this.permissions.accessibleSpottingAreas = value
        },
      },
      viewReportOfActorTypes: {
        get () {
          return this.permissions.viewReportOfActorTypes
        },
        set (value) {
          this.permissions.viewReportOfActorTypes = value
        },
      },
      knowledgeBaseViewType: {
        get () {
          return this.permissions.knowledgeBaseViewType || 'all'
        },
        set (value) {
          if (!this.permissions.knowledgeBaseViewType) {
            this.permissions.knowledgeBaseViewType = 'all'
          }

          this.permissions.knowledgeBaseViewType = value
        }
      },
      canUploadContentToKnowledgeBase: {
        get () {
          return !!this.permissions.canUploadContentToKnowledgeBase
        },
        set (value) {
          if (!this.permissions.canUploadContentToKnowledgeBase) {
            this.permissions.canUploadContentToKnowledgeBase = true
          }

          this.permissions.canUploadContentToKnowledgeBase = !!value
        }
      },
      shouldCurateContentToKnowledgeBase: {
        get () {
          return !!this.permissions.shouldCurateContentToKnowledgeBase
        },
        set (value) {
          if (!this.permissions.shouldCurateContentToKnowledgeBase) {
            this.permissions.shouldCurateContentToKnowledgeBase = true
          }

          this.permissions.shouldCurateContentToKnowledgeBase = !!value
        }
      },
      createLegalEntityPolicy: {
        get () {
          return this.permissions.createLegalEntityPolicy || 'one'
        },
        set (value) {
          this.permissions.createLegalEntityPolicy = value
        },
      },
      createProductPolicy: {
        get () {
          return this.permissions.createProductPolicy || 'many'
        },
        set (value) {
          this.permissions.createProductPolicy = value
        },
      },
      productLegendProperty: {
        get () {
          return this.permissions.productLegendProperty || null
        },
        set (value) {
          this.permissions.productLegendProperty = value
        },
      },
      availableProductLegendItems: {
        get () {
          if (this.permissions.availableLegendItems) {
            return this.permissions.availableLegendItems[this.productLegendProperty] || []
          }
          return []
        },
        set (value) {
          if (!this.permissions.availableLegendItems) {
            this.permissions['availableLegendItems'] = {}
          }

          if (!this.permissions.availableLegendItems[this.productLegendProperty]) {
            this.permissions.availableLegendItems[this.productLegendProperty] = []
          }

          this.permissions.availableLegendItems[this.productLegendProperty] = value
        },
      },
      permissions: {
        get () {
          return this.EMPPermissions
        },
        set (value) {
          this.$emit('permissionsUpdated', value)
        },
      },
      availableLegendItems: {
        get () {
          return this.permissions.availableLegendItems[this.permissions.defaultLegendProperty] || []
        },
        set (value) {
          var items = {}

          // This is the only way the property is reactive and triggers a "set"
          items[this.permissions.defaultLegendProperty] = value

          this.permissions.availableLegendItems = items
        },
      },
      defaultPermissions () {
        return this.$store.getters.ecosystemMemberPermissions
      },
      siteTemplate: {
        get () {
          return this.permissions.siteTemplate || SiteTemplate.TRADITIONAL
        },
        set (value) {
          if (!this.permissions.siteTemplate) {
            this.permissions['siteTemplate'] = ''
          }

          if (value === SiteTemplate.SIMPLIFIED || value === SiteTemplate.NEW_SIMPLIFIED) {
            if (!this.permissions.views) {
              this.permissions.views = []
            }

            if (!this.permissions.views.includes('actors/detail')) {
              this.permissions.views.push('actors/detail')
            }
          }

          this.permissions.siteTemplate = value
        },
      },
      canCreateChallenge: {
        get () {
          return this.permissions.canCreateChallenge || false
        },
        set (value) {
          this.permissions.canCreateChallenge = value
        },
      },
      canCreatePrivateChallenge: {
        get () {
          return this.permissions.canCreatePrivateChallenge || false
        },
        set (value) {
          this.permissions.canCreatePrivateChallenge = value
        },
      },
      challengeMatchmakingEnabled: {
        get () {
          return this.permissions.challengeMatchmakingEnabled || false
        },
        set (value) {
          this.permissions.challengeMatchmakingEnabled = value
        },
      },
      viewableChallengeStatusses: {
        get () {
          return this.permissions.viewableChallengeStatusses || []
        },
        set (value) {
          this.permissions.viewableChallengeStatusses = value
        },
      },
      accessibleChallengeStatusses: {
        get () {
          return this.permissions.accessibleChallengeStatusses || []
        },
        set (value) {
          this.permissions.accessibleChallengeStatusses = value
        },
      },
      canViewPublicAnnouncements: {
        get () {
          return !!this.permissions.canViewPublicAnnouncements
        },
        set (value) {
          this.permissions.canViewPublicAnnouncements = value
        },
      },
      areMessageBoardsEnabled () {
        return this.$store.getters.areMessageBoardsEnabled
      },
      viewListOptions () {
        if (!this.publicFields.includes('completeness')) {
          return viewListOptions.filter(opt => opt !== 'completeness')
        }

        return viewListOptions
      },
      visibleFilterControlOptionsProduct () {
        if (!this.permissions || !this.permissions.filterControlsProducts) {
          return [{ value: 'sustainability_goal', label: 'Sustainability Goals' }]
        }

        let defaultFilters = [
          { value: 'sustainability_goal', label: 'Sustainability Goals' }
        ]

        if (this.permissions.filterControls.includes('reports')) {
          defaultFilters.push({ value: 'reports', label: 'Reports' })
        }

        return [
          ...defaultFilters,
          ...this.permissions.filterControlsProducts.map((control) => {
            return this.translatedFilterControlOptions.find(filterOption => {
              return filterOption.value === control
            })
          })
            .filter((control) => {
              if (!control) {
                return
              }

              // Only allow choosing options that are available on the simplified products page
              return control.value !== 'sustainability_goal' && this.translatedSimplifiedFilterControlOptions.find(filterOption => {
                return filterOption.value === control.value
              })
            })]
      },
      visibleFilterControlOptionsActor () {
        if (!this.permissions || !this.permissions.filterControls) {
          return [{ value: 'sustainability_goal', label: 'Sustainability Goals' }]
        }

        let defaultFilters = [
          { value: 'sustainability_goal', label: 'Sustainability Goals' }
        ]

        if (this.permissions.filterControls.includes('reports')) {
          defaultFilters.push({ value: 'reports', label: 'Reports' })
        }

        return [
          ...defaultFilters,
          ...this.permissions.filterControls.map((control) => {
            return this.translatedFilterControlOptions.find(filterOption => {
              return filterOption.value === control
            })
          }).filter((control) => {
            if (!control) {
              return
            }

            // Only allow choosing options that are available on the simplified actors page
            return control.value !== 'sustainability_goal' && this.translatedSimplifiedFilterControlOptions.find(filterOption => {
              return filterOption.value === control.value
            })
          })]
      },
      fillableFields () {
        return flatten(toArray(this.propertyGroups))
      },
      propertyGroups () {
        const propertyGroups = rawPropertyGroups

        delete propertyGroups.ContactDetails

        return propertyGroups
      },
      publicFields () {
        if (this.permissions && this.permissions.public_fields) {
          return this.permissions.public_fields
        }
        return []
      },
      privateFields () {
        if (this.permissions && this.permissions.public_fields) {
          return difference(this.fillableFields, this.publicFields)
        }

        return this.fillableFields
      },
      publicPropertyGroups () {
        return this.filterPropertyGroups(property => this.publicFields.indexOf(property) !== -1)
      },
      privatePropertyGroups () {
        return this.filterPropertyGroups(property => this.privateFields.indexOf(property) !== -1)
      },
      viewDashboardOptions () {
        var options = this.defaultPermissions.viewDashboard && viewDashboardOptions.filter(d => this.defaultPermissions.viewDashboard.includes(d.value)) || []
        options = options.filter(d => !['message_dashboard', 'buzz', 'media-buzz', 'connections'].includes(d.value))

        return options
      },
      enableFundingBuzz: {
        get () {
          // Make sure legacy settings where cards are collapsed remain open
          if (!this.permissions.enableFundingBuzz == undefined) {
            return true
          }

          return this.permissions.enableFundingBuzz
        },
        set (v) {
          this.permissions.enableFundingBuzz = v
        },
      },
      enableEventsBuzz: {
        get () {
          // Make sure legacy settings where cards are collapsed remain open
          if (this.permissions.enableEventsBuzz == undefined) {
            return true
          }

          return this.permissions.enableEventsBuzz
        },
        set (v) {
          this.permissions.enableEventsBuzz = v
        },
      },
      enableInfluencersBuzz: {
        get () {
          // Make sure legacy settings where cards are collapsed remain open
          if (this.permissions.enableInfluencersBuzz == undefined) {
            return true
          }

          return this.permissions.enableInfluencersBuzz
        },
        set (v) {
          this.permissions.enableInfluencersBuzz = v
        },
      },
    },
    methods: {
      onUpdateActorFilterControls () {
        // filter to only allow available controls
        if (this.permissions.simplifiedFilterControls) {
          this.permissions.simplifiedFilterControls = this.permissions.simplifiedFilterControls.filter(filterControl => filterControl !== 'sustainability_goal' && this.visibleFilterControlOptionsActor.find(filterControlOption => filterControlOption.value === filterControl))
        }
      },
      onUpdateProductFilterControls () {
        // filter to only allow available controls
        if (this.permissions.simplifiedFilterControlsProducts) {
          this.permissions.simplifiedFilterControlsProducts = this.permissions.simplifiedFilterControlsProducts.filter(filterControl => filterControl !== 'sustainability_goal' && this.visibleFilterControlOptionsProduct.find(filterControlOption => filterControlOption.value === filterControl))
        }
      },
      filterPropertyGroups (func) {
        // Returns a mapped and filtered array of property groups
        return Object.keys(this.propertyGroups)
          .filter(p => p !== 'Competitive Scores' || this.defaultPermissions.hasScores)
          .map(label => ({
            label,
            properties: this.propertyGroups[label].filter(func),
          })).filter(g => g.properties && g.properties.length)
      },
      updatePortfolioOptions: debounce(async function (query) {
        if (!query) {
          return
        }

        // This will search for a portfolio which matches the input value in the search field
        var filters = Object.assign({}, { query: query })

        fetchPortfolio(filters)
          .then(portfolios => {
            this.portfolioOptions = []

            this.parsePortfolioOptions(portfolios)
          })
          .catch(errors => {
            console.log(errors)
          })
      }, 150),
      updateSpottingAreaOptions: debounce(async function (query) {
        console.log('update options')

        if (!query) {
          return
        }

        // This will search for a portfolio which matches the input value in the search field
        var filters = Object.assign({}, { query: query })

        fetchSpottingAreas(filters)
          .then(spottingAreas => {
            this.spottingAreaOptions = []

            this.parseSpottingAreaOptions(spottingAreas)
          }).catch(errors => {
          console.log(errors)
        })
      }, 150),
      parsePortfolioOptions (portfolios) {
        if (!Array.isArray(portfolios)) {
          return
        }

        var options = []

        portfolios.forEach(portfolio => {
          if (portfolio.virtual) {
            return
          }

          options.push({
            text: portfolio.name,
            id: portfolio.id,
          })
        })

        this.portfolioOptions = options
      },
      parseSpottingAreaOptions (spottingAreas) {
        if (!Array.isArray(spottingAreas)) {
          return
        }

        var options = []

        spottingAreas.forEach(spottingArea => {
          let showEye = true
          let invalidOption = spottingArea.type === SPOTTING_AREA_TYPES.PREMIUM || spottingArea.type === SPOTTING_AREA_TYPES.PRIVATE

          options.push({
            text: spottingArea.name,
            id: spottingArea.id,
            /*optionValue: {
              value: spottingArea.id,
              text: spottingArea.name,
              showEye: showEye,
              invalidOption: invalidOption,
            }*/
          })
        })

        this.spottingAreaOptions = options
      },
      updateSelectedPortfolios (input) {
        this.$emit('updateAccessiblePortfolios', input)
      },
      updateSelectedAccessibleSpottingAreas (input) {
        var inputValues = []

        input.forEach(spottingArea => {
          inputValues.push({
            text: spottingArea.text,
            id: spottingArea.id,
            optionValue: spottingArea.optionValue,
          })
        })

        this.$emit('updateAccessibleSpottingAreas', inputValues)
      },
      pushPublic (f) {
        if (!this.permissions.public_fields) {
          this.permissions['public_fields'] = []
        }
        if (Array.isArray(f)) {
          f.forEach(this.pushPublic)
          return
        }
        if (this.permissions.public_fields.indexOf(f) < 0) {
          this.permissions.public_fields.push(f)
        }
      },
      // @param 1 or an array of properties
      splicePublic (f) {
        if (Array.isArray(f)) {
          f.forEach(this.splicePublic)
          return
        }
        if (this.permissions.public_fields.indexOf(f) >= 0) {
          this.permissions.public_fields.splice(this.permissions.public_fields.indexOf(f), 1)
        }
      },
    },
    mounted () {
      this.onUpdateProductFilterControls()
      this.onUpdateActorFilterControls()
    },
    watch: {
      canCreateChallenge (value) {
        if (!value) {
          // Can't create a private challenge if you lack the basic "can create a challenge"
          this.permissions.canCreatePrivateChallenge = false
          this.permissions.challengeMatchmakingEnabled = false
        }
      },
      viewReportOfActorTypes (val) {
        if (val && val.length > 0) {
          return
        }

        if (this.permissions.filterControlsProducts) {
          this.permissions.filterControlsProducts = this.permissions.filterControlsProducts.filter(filter => filter !== 'reports')
        }

        if (this.permissions.filterControls) {
          this.permissions.filterControls = this.permissions.filterControls.filter(filter => filter !== 'reports')
        }
      },
    },
    components: {
      FormGroup,
      SiteTemplateSelection,
      CuratedContentSelection,
      Checkbox,
      Dropdown,
      AutocompleteTagInput,
    },
    mixins: [ConfigMixin, PermissionsMixin, TranslationsMixin],
  }
</script>

<style scoped lang="scss">
  @import "../../../../scss/_variables.scss";

  .actor-types__help-text {
    display: flex;
    font-size: 12px;
    color: $color-text-grey-light;
    margin-bottom: 0.5rem;
  }
</style>
