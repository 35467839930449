<template>
  <dl class="definition-list">
    <template v-for="entry in entries">
      <dt>{{entry.term}}</dt>
      <template v-for="description in (entry.descriptions || [entry.description])">
        <template v-if="typeof description == 'string' || typeof description == 'number' && description">
          <dd style="">
            <div aria-hidden="true" class="definition-list__item__orb" :style="{ backgroundColor: primaryColor }"></div>
            {{description}}
          </dd>
        </template>
        <template v-else-if="typeof description == 'object'">
          <dd style="" v-if="description.description">
            <div aria-hidden="true" class="definition-list__item__orb" :style="{ backgroundColor: primaryColor }"></div>
            {{description.description}}
          </dd>
          <template v-for="subDescription in description.subDescriptions">
            <dd style="margin-left: 2.5rem;" v-if="subDescription">
              <div aria-hidden="true" class="definition-list__item__orb" :style="{ backgroundColor: primaryColor }" v-if="subDescription"></div>
              {{subDescription.description ? subDescription.description : subDescription}}
            </dd>

            <template v-for="remark in subDescription.subDescriptions">
              <dd style="margin-left: 3.5rem;" v-if="subDescription">
                <div aria-hidden="true" class="definition-list__item__orb" :style="{ backgroundColor: primaryColor }" v-if="subDescription"></div>
                {{remark}}
              </dd>
            </template>
          </template>
        </template>
      </template>
    </template>
  </dl>
</template>

<script>
  export default {
    name: 'DefinitionList',
    props: {
      entries: Array
    },
    computed: {
      primaryColor() {
        return this.$store.getters.primaryColor;
      },
    }
  }
</script>

<style lang="scss">
  @import "../../../scss/variables";

  $definition-list-description-orb-dimensions: 5px;

  .definition-list {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  dt {
    margin-top: 0.5rem;
    font-size: 0.75rem;
    line-height: 1.25rem;
    color: $color-text-grey-light;
    letter-spacing: 0.5px;
    text-transform: uppercase;

    &:last-child {
      margin-top: 0;
    }
  }

  dd {
    position: relative;
    padding-left: 0.75rem;
    line-height: 1.5rem;
    margin-left: 1.5rem;

    .definition-list__item__orb {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: $definition-list-description-orb-dimensions;
      height: $definition-list-description-orb-dimensions;
      border-radius: 50%;
    }

    .sub-item {
      margin-left: 2.5rem !important;
    }
  }
</style>
