<template>
  <div class="home-simplified">
    <div class="home__report_call_countdown_container no-margin" v-if="callCountdownTemplate && isLoggedIn">
      <span class="home__report_call_countdown" v-html="callTimeDifference"></span><span class="home__report_call_text">{{
        headlineText
      }}</span>
      <ds-button :icon="applyButtonIcon" :label="applyButtonLabel" @click="onClickApplyForCallOnHomepage"
                 v-tooltip.left="ownedLegalEntityReportUrl ? null : applyButtonTooltip"
                 variant="primary" class="home__report_call_button" size="small"/>
    </div>
    <SimpleHero/>
    <Container class="home-simplified__container">
      <template v-if="displayAnnouncementType === 'challenges-only'">
        <div class="row">
          <div class="col-md-6 col-xs-12" style="display: flex; flex-direction:column;">
            <div class="home-simplified__subtitle extra-padding">
              <router-link to="/challenges">{{
                  $t('shp_featured_challenges', { challenge: challengeLabel })
                }}
              </router-link>
            </div>
            <div class="vertical-challenge-container">
              <challenge-card :challenge="challenges[0]"
                              @toggle-pin-status="togglePinStatus"
                              :is-container="false"
                              :is-vertical="true"
                              :is-accessible-for-user="isAccessibleForUser"/>
            </div>
          </div>
          <div class="col-md-6 col-xs-12">
            <div class="home-simplified__subtitle extra-padding">
              <router-link to="/challenges">{{ $t('shp_latest_challenge', { challenge: challengeLabel }) }}
              </router-link>
            </div>
            <div class="vertical-challenge-container">
              <challenge-card :is-container="false"
                              :is-vertical="true"
                              :challenge="challenges[1]"
                              @toggle-pin-status="togglePinStatus"
                              :is-accessible-for-user="isAccessibleForUser"/>
            </div>
          </div>
        </div>
      </template>
      <template
          v-else-if="displayAnnouncementType === 'announcements-only' || displayAnnouncementType === 'both'">
        <div class="row">
          <div class="col-md-6 col-xs-12" style="display: flex; flex-direction:column;">
            <div class="home-simplified__subtitle extra-padding">
              <router-link v-if="featuredNotification.id"
                           @click="trackHeapEvent('basicHomepageSimplified.titleAnnouncements')"
                           to="/announcements-simplified">
                {{ $t('shp_featured_announcement', { announcement: announcementLabel }) }}
              </router-link>
              <router-link v-else
                           @click="displayAnnouncementType === 'both' ? trackHeapEvent('basicHomepageSimplified.titleAnnouncements') : trackHeapEvent('basicHomepageSimplified.titleChallenges')"
                           :to="displayAnnouncementType === 'both' ? '/announcements-simplified' : '/challenges'">
                {{
                  displayAnnouncementType === 'both' ? $t('shp_latest_announcement', { announcement: announcementLabel }) : $t('shp_latest_announcements', { announcements: announcementsLabel })
                }}
              </router-link>
            </div>
            <div class="vertical-challenge-container">
              <simplified-announcement-preview
                  :is-vertical="true"
                  :is-container="false"
                  :announcement="featuredNotification.id ? featuredNotification : publicNotifications.data[0]"/>
            </div>
          </div>
          <div class="col-md-6 col-xs-12">
            <div class="home-simplified__subtitle extra-padding">
              <router-link
                  @click="displayAnnouncementType === 'both' ? trackHeapEvent('basicHomepageSimplified.titleChallenges') : trackHeapEvent('basicHomepageSimplified.titleAnnouncements')"
                  :to="displayAnnouncementType === 'both' ? '/challenges' : '/announcements-simplified'">
                {{
                  displayAnnouncementType === 'both' ? $t('shp_latest_challenge', { challenge: challengeLabel }) : (featuredNotification.id ? $t('shp_latest_announcement', { announcement: announcementLabel }) : '&nbsp;')
                }}
              </router-link>
            </div>
            <div class="vertical-challenge-container">
              <challenge-card v-if="displayAnnouncementType === 'both' && challenges[0]"
                              :is-container="false"
                              :is-vertical="true"
                              :challenge="challenges[0]"
                              @toggle-pin-status="togglePinStatus"
                              :is-accessible-for-user="isAccessibleForUser"/>
              <simplified-announcement-preview
                  :is-vertical="true"
                  :is-container="false"
                  v-if="displayAnnouncementType === 'announcements-only'"
                  :announcement="announcementsWithoutLeftAnnouncement[0]"
              />
            </div>
          </div>
        </div>
      </template>

      <template v-if="actors && actors.data && actors.data.length >= 1">
        <div class="home-simplified__subtitle">
          <router-link to="/actors-simplified"
                       @click="trackHeapEvent('basicHomepageSimplified.Actors')">
            {{ $t('shp_latest_actors') }}
          </router-link>
        </div>
        <div class="row">
          <div
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 home-simplified__space-vertical"
              v-for="(actor, index) in actors.data.slice(0, 3)"
              :key="'actor' + index">
            <SimpleCard
                :imgUrl="actor.featured_image_url || actor.website_screenshot || getDefaultFallbackImageUrl()"
                :force-aspect-ratio="true"
                :title="actor.name"
                :content="localizedDisplayProperty(actor, 'short_description') || localizedDisplayProperty(actor, 'activities_description')"
                @click="handleClickActorCard(actor)"
            />
          </div>
        </div>
      </template>

      <template v-if="products && products.data && products.data.length">
        <div class="home-simplified__subtitle">
          <router-link to="/products-simplified"
                       @click="trackHeapEvent('basicHomepageSimplified.titleProducts')">
            {{ productsTitle }}
          </router-link>
        </div>
        <div class="row">
          <div
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 home-simplified__space-vertical"
              v-for="(product, index) in products.data.slice(0, 3)"
              :key="'product' + index">
            <SimpleCard
                :imgUrl="product.featured_image_url || product.website_screenshot || getDefaultFallbackImageUrl()"
                :force-aspect-ratio="true"
                :title="product.name"
                :content="localizedDisplayProperty(product, 'short_description') || localizedDisplayProperty(product, 'activities_description')"
                @click="handleClickProductCard(product)"
            />
          </div>
        </div>
      </template>

      <template v-if="spotlightActors.length > 0">
        <div class="home-simplified__subtitle">
          <router-link to="/actors-simplified"
                       @click="trackHeapEvent('basicHomepageSimplified.titleSpotlightActors')">
            {{ spotlightPortfolioName }}
          </router-link>
        </div>
        <Carousel
            class="carousel-spotlight carousel--minicards"
            :mouse-drag="true"
            :settings="carouselSettings(4)"
            :breakpoints="carouselBreakpoints(4)"
            :autoplay="10000"
        >
          <Slide
              v-for="(actor, index) in spotlightActors"
              :key="index"
          >
            <router-link :to="'/actors/' + actor.id" class="carousel-spotlight__actor-image-container"
                         style="text-decoration: none; height: 100%">
              <ActorMiniCard :actor="actor"/>
              <!--              <div
                                class="carousel-spotlight__actor-image"
                                :style="getFeaturedImageStyle(actor)"><span v-if="!actor.logo">{{ actor.name }}</span></div>-->
            </router-link>
          </Slide>
          <template #addons>
            <Navigation v-if="spotlightActors.length > cardsPerCarouselPage"/>
          </template>
        </Carousel>
      </template>

      <template v-if="homePageIsFullyLoaded && showIntelligenceUpgradeCTA">
        <div class="home-simplified__subtitle extra-padding">
          <router-link to="/profile/subscription#upgrade"
                       @click="trackHeapEvent('basicHomepageSimplified.intelligenceUpgradeCTA')">
            <icon name="lock"/>
            {{
              $t('shp_latest_intelligence')
            }}
          </router-link>
        </div>
        <div class="row home-simplified__articles-container">
          <div class="col col-xs-12 col-sm-4 col-md-3 home-simplified__article"
               v-for="index in [1,2,3,4]"
               :key="'fake-article' + index">
            <div class="carousel__slide-container">
              <UnstyledLink target="_blank" class="inaccessible-article__cta-container">
                <div class="intelligence-cta__overlay">
                  <h3 class="h3">
                    <router-link to="/profile/subscription#upgrade">{{ splitCtaText[1] }}</router-link>
                  </h3>
                </div>
                <icon name="lock" class="lock-icon"/>
                <MiniCard
                    class="inaccessible-article__cta"
                    title="inaccessible - upgrade required"
                    subtitle="inaccessible"
                    content="This content is not accessible because an upgrade to a higher-level package is required"
                />
              </UnstyledLink>
            </div>
          </div>
        </div>
      </template>

      <template v-if="displayExploreContent && desktopNewsFeed.length > 0">
        <div class="home-simplified__subtitle extra-padding">
          <router-link to="/intelligence-simplified"
                       @click="trackHeapEvent('basicHomepageSimplified.titleIntelligence')">
            {{
              $t('shp_latest_intelligence')
            }}
          </router-link>
        </div>
        <div class="row home-simplified__articles-container">
          <div class="col col-xs-12">
            <Carousel
                :class="{'carousel--minicards': false, 'has-navigation': false}"
                :settings="carouselSettings(2)"
                :breakpoints="carouselBreakpoints(2)"
            >
              <Slide
                  v-for="(article, index) in desktopNewsFeed"
                  style="align-items: flex-start"
                  :key="'intelligence-card-' + index"
              >
                <div style="max-width: 100%; padding: 10px; text-align: left; height: 100%;">
                  <AnnouncementOrResourceCard
                      :key="'announcement-or-resource-card-' + index"
                      :data="article"
                      :preview-mode="false"
                      @like-article="likeArticle"
                      :show-image="false"
                      @resourceDeleted="this.fetchLatestNews()"
                  />
                </div>
              </Slide>
              <template #addons>
                <Navigation v-if="desktopNewsFeed.length > 2"/>
              </template>
            </Carousel>
          </div>
        </div>
      </template>

      <div class="home-simplified__subtitle extra-padding" v-show="showEcosystemMap">
        <a href="#" style="cursor: default">{{ $t('shp_ecosystem_map') }}</a>
      </div>

      <div style="position: relative;" :style="{ height: 450 + 'px', opacity: homePageIsFullyLoaded ? 1 : 0 }">
        <MapView
            ref="mapPane"
            :data="mapData.data"
            :getMapData="getMapData"
            :getData="getMapData"
            :noSearchWhileMoving="true"
            :disableMouseWheelZoom="true"
        />
      </div>
    </Container>

    <SimplifiedFooter/>
  </div>
</template>

<script>
import Container from '../Container/Container.vue'
import SimpleCard from '../SimpleCard/SimpleCard.vue'
import { fetchPublicNotifications } from '../../api/notifications'
import SimpleArticlePreview from '../SimpleArticlePreview.vue'
import SimplifiedFooter from '../SimplifiedFooter/SimplifiedFooter.vue'
import MapView from '../MapView/MapView.vue'
import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../../store/modules/actors'
import FiltersMixin from '../../util/FiltersMixin'
import TranslationsMixin from '../../util/TranslationsMixin'
import CarouselMixin from '../../util/CarouselMixin'

import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
import { fetchGalleryData, fetchLatestNews, fetchStatsData } from '../../api/homepage'
import { trackHeapEvent } from '../../util/analytics'
import { getTranslationsForTaxonomyValue } from '../../store/modules/taxonomies'
import { fetchChallenges, updatePinStatus } from '../../Domain/Challenge/Api/challenges.ts'
import ChallengeCard from '../Simplified/ChallengeCard.vue'
import SimplifiedAnnouncementPreview from '../Simplified/SimplifiedAnnouncementPreview.vue'
import AnnouncementOrResourceCard from '../Simplified/AnnouncementOrResourceCard.vue'
import {
  getCardsPerCarouselPageBasedOnWindowWidth,
  getDefaultFallbackImageUrl,
  shortTitle,
  splitCtaText,
  truncate
} from '../../util/helpers.js'
import UnstyledLink from '../UnstyledLink/UnstyledLink.vue'
import MiniCard from '../MiniCard/MiniCard.vue'
import { getCallTimeDifference, toDayFullMonth } from '../../util/date.js'
import _get from 'lodash/get'
import ICON_MANIFEST from '../../../icons/manifest.json'
import ActorMiniCard from '../ActorMiniCard/ActorMiniCard.vue'
import { Carousel, Navigation, Slide } from 'vue3-carousel'

import ReportMixin from '../../util/ReportMixin.js'
import SimpleHero from '../SimpleHero/SimpleHero.vue'
import { likeArticle } from '../../api/knowledge-base'
import { defineComponent } from 'vue'
import ActorInfoCard from '@/components/ActorInfo/ActorInfoCard.vue'
import ActorSuggestionCard from '@/components/SpottingAreas/ActorSuggestionCard.vue'
import ChallengeActorCard from '@/Domain/Challenge/Components/ChallengeActorCard.vue'

export default defineComponent({
  name: 'BasicHomeSimplified',
  components: {
    ChallengeActorCard,
    ActorSuggestionCard,
    ActorInfoCard,
    SimpleHero,
    UnstyledLink,
    MiniCard,
    AnnouncementOrResourceCard,
    SimplifiedAnnouncementPreview,
    ChallengeCard,
    SimpleArticlePreview,
    Container,
    SimpleCard,
    MapView,
    SimplifiedFooter,
    ActorMiniCard,
    Carousel,
    Slide,
    Navigation,
  },
  data: () => {
    return {
      cardsPerCarouselPage: 2 * getCardsPerCarouselPageBasedOnWindowWidth(window.innerWidth),
      carouselIcons: {
        navigationLeft: ICON_MANIFEST['chevron-left'],
        navigationRight: ICON_MANIFEST['chevron-right'],
      },
      spotlightActors: [],
      spotlightPortfolioName: '',
      latestNews: [],
      challenges: [],
      publicNotifications: {
        data: [],
        isLoading: false,
      },
      featuredNotification: {},
      legendCounts: {},
      actorFilters: { actor_type: ['LegalEntity', 'Community', 'Person'] },
      productFilters: { actor_type: 'Product' },
      callTimeDifference: '',
      callTimeInterval: null,
      mapHeight: 400,
    }
  },
  created () {
    /*if (this.$store.getters.hasAccessToNewSpottingAreas && !this.$store.state.spottingAreas.listData.loading) {
      this.$store.dispatch(SPOTTING_AREAS_ACTION_TYPES.FETCH_SPOTTING_AREAS_LIST)
    }*/
  },
  async mounted () {
    this.fetchPublicNotifications()
    this.$bus.on('resize', () => {
      this.cardsPerCarouselPage = 2 * getCardsPerCarouselPageBasedOnWindowWidth(window.innerWidth)
    })

    // By making fetching challenges a synchronised call, this holds up the other API calls from being triggered
    // This makes it so that the items for the top of the homepage are not only fetched first
    // But gives the back-end some breathing room when receiving a burst of API calls, making it much more likely that
    // From an end user point of view, the top things are loaded first and stay there, previously map data could flash on top, then shortly after challenges and announcements were taking its place
    try {
      var challenges = await this.fetchChallenges()

      if (challenges.length > 0) {
        this.challenges = challenges
      }
    } catch (e) {
      //
    }

    this.fetchStatsData()
    this.getMapData()
    this.fetchFilteredActors()

    if (this.callCountdownTemplate) {
      this.callTimeInterval = setInterval(() => {
        this.callTimeDifference = this.getCallTimeDifference(this.callCountdownTemplate.due_date)
      }, 1000)
    }

    const spotlightIsEnabled = this.config.homepageCarrouselPortfolio && this.config.homepageTemplate.find(section => section.id === 'spotlight' && section.isVisible)
    if (spotlightIsEnabled) {
      this.fetchGalleryData()
    }

    setTimeout(async () => {
      await this.fetchLatestNews()
    }, 2000)
  },
  beforeUnmount () {
    this.$bus.off('resize')
    if (this.callTimeInterval) {
      clearInterval(this.callTimeInterval)
    }
  },
  computed: {
    headlineText () {
      return this.localizedDisplayProperty(this.config, 'headlineText')
    },
    productsTitle () {
      return this.$t('shp_latest_products', { products: this.productsLabel, interpolation: { escapeValue: false } })
    },
    announcementsWithoutLeftAnnouncement () {
      if (this.featuredNotification.id) {
        return this.publicNotifications.data.filter(announcement => announcement.id !== this.featuredNotification.id)
      } else {
        return this.publicNotifications.data.filter(announcement => announcement.id !== this.publicNotifications.data[0].id)
      }
    },
    splitCtaText () {
      return splitCtaText(this.intelligenceCtaUpgradeText)
    },
    intelligenceCtaUpgradeText () {
      if (this.showIntelligenceUpgradeCTA) {
        return this.config.packageCTAs.intelligence.text || this.$t('default_intelligence_upgrade_cta')
      }
      return ''
    },
    showIntelligenceUpgradeCTA () {
      return this.isLoggedIn && !this.displayExploreContent && this.config.packageCTAs && this.config.packageCTAs.intelligence && this.config.packageCTAs.intelligence.enabled
    },
    homePageIsFullyLoaded () {
      if (!_get(this, 'challenges.length') && !_get(this, 'products.data.length') && !_get(this, 'actors.data.length') && !_get(this, 'publicNotifications.data.length')) {
        return false
      }
      return true
    },
    showEcosystemMap () {
      if (!this.homePageIsFullyLoaded) {
        return false
      }
      return _get(this, 'mapData.data.companies.length') || _get(this, 'mapData.data.clusters.length')
    },
    logo () {
      return this.config.logo_url_light || this.config.logo_url
    },
    desktopNewsFeed () {
      const newsFeedItemList = this.latestNews

      return newsFeedItemList
    },
    displayExploreContent () {
      const hasAccess = (this.$store.getters.hasAccessToExploration || this.$store.getters.hasAccessToMonitoring)

      return this.$store.getters.isLoggedIn && hasAccess && this.config.viewDashboard && this.config.viewDashboard.includes('explore-content')
    },
    displayAnnouncementType () {
      if (this.challenges.length >= 1 && (this.featuredNotification.id || this.publicNotifications.data.length >= 2)) {
        return 'both'
      } else if (this.challenges.length >= 2) {
        return 'challenges-only'
      } else if (this.publicNotifications.data.length >= 2) {
        return 'announcements-only'
      }

      return ''
    },
    accessibleChallengeStatusses () {
      return this.$store.getters.accessibleChallengeStatusses
    },
    canCreateChallenge () {
      return this.$store.getters.canCreateChallenge
    },
    filters () {
      return this.$store.getters.baseFilterObject
    },
    products () {
      return this.$store.state.actors.productData
    },
    actors () {
      return this.$store.state.actors.listData
    },
    config () {
      return this.$store.state.config
    },
    locale () {
      return this.$store.getters.activeLocale
    },
    legendItems () {
      if (!this.legendCounts.stats) {
        return []
      }

      return this.legendCounts.stats.map(result => {
        const translations = getTranslationsForTaxonomyValue(this.legendCounts.property, result.value)
        let label

        if (translations && translations[this.locale] && translations[this.locale].name) {
          label = translations[this.locale].name
        } else {
          label = translations['en'].name
        }

        result['label'] = label

        return result
      })
    },
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    },
    title () {
      return this.isLoggedIn
          ? this.localizedDisplayProperty(this.config, 'introTitleLoggedIn')
          : this.localizedDisplayProperty(this.config, 'introTitleLoggedOut')
    },
    image () {
      return this.config.homepage_knowledge_base_image
    },
    tagline () {
      return this.isLoggedIn
          ? this.localizedDisplayProperty(this.config, 'tagline')
          : this.localizedDisplayProperty(this.config, 'taglineLoggedOut')
    },
    mapFilter () {
      return this.$store.getters.mapFilterObject
    },
    mapData () {
      return this.$store.state.actors.mapData
    },
  },
  mixins: [CarouselMixin, FiltersMixin, TranslationsMixin, ReportMixin],
  methods: {
    getDefaultFallbackImageUrl,
    likeArticle ({ resourceId, status }) {
      likeArticle(resourceId, status)
    },
    getCallTimeDifference,
    getFeaturedImageStyle (actor) {
      return `background-image: url(${actor.logo}); background-size: contain;`
    },
    fetchGalleryData () {
      fetchGalleryData({ limit: 50 }).then(response => {
        if (response.data.actors) {
          this.spotlightActors = response.data.actors
        }
        if (response.data.portfolio_name) {
          this.spotlightPortfolioName = response.data.portfolio_name
        }
      }).catch(errors => {
        console.log(errors)
      })
    },
    toDayFullMonth,
    shortTitle,
    trackHeapEvent,
    truncate,
    conceptSearchStyle (conceptSearch) {
      return 'background-image: url(' + conceptSearch.image + ');'
    },
    handleConceptSearchClick (conceptSearch) {
      if (conceptSearch.status !== 'done') {
        return
      }

      this.$router.push({
        name: 'intelligence-detail',
        params:
            { id: conceptSearch.id },
      })
    },
    togglePinStatus (challenge) {
      challenge.is_pinned = !challenge.is_pinned

      updatePinStatus(challenge.id, challenge.is_pinned)
    },
    isAccessibleForUser (challenge) {
      if (!challenge) {
        return false
      }

      if (this.isMember) {
        return true
      }
      return this.accessibleChallengeStatusses.includes(challenge.status)
    },
    async fetchLatestNews () {
      try {
        var news = await fetchLatestNews({ limit: 10, offset: 0, transform: 'news-monitoring' })

        if (news.length > 0) {
          var items = news
              .map(response => {
                if (['rss', 'pr', 'blog'].includes(response.media_type) && response.sql_media_id) {
                  response.detail_link = '/files/' + response.sql_media_id
                }
                return response
              })
              .slice(0, 10)

          this.latestNews = items
        }
      } catch (e) {
        // console.log(e)
      }
    },
    fetchChallenges () {
      return fetchChallenges({
        limit: 2,
        offset: 0,
        filters: {},
      })
    },
    openCreateChallengePanel () {
      trackHeapEvent('basicHomepageSimplified.ctaCreateChallenge')
      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'manage-challenge-panel' })
    },
    handleClickProductCard (product) {
      trackHeapEvent('basicHomepageSimplified.clickProductCard')
      this.$router.push(`/actors/${product.id}`)
    },
    handleClickActorCard (actor) {
      trackHeapEvent('basicHomepageSimplified.clickActorCard')

      this.$router.push(`/actors/${actor.id}`)
    },
    fetchFilteredActors () {
      const listFilters = { ...this.$store.getters.listFilterObject }
      this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_LIST, Object.assign({}, this.actorFilters, listFilters))

      if (this.$store.getters.isProductEnabled) {
        this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_PRODUCTS_LIST, Object.assign({}, this.productFilters, listFilters))
      }
    },
    fetchPublicNotifications () {
      if (!this.$store.getters.canUsePublicAnnouncements) {
        return {}
      }

      const filters = {}

      if (this.locale) {
        filters['locale'] = this.locale
      }

      fetchPublicNotifications(2, 0, filters).then(items => {
        this.publicNotifications.data = items
      })
      fetchPublicNotifications(1, 0, { featured: 'true' }).then((items) => {
        if (items && items.length > 0) {
          this.featuredNotification = items[0]
        }
      })
    },
    getMapData () {
      let filters
      if (this.$store.state.config && this.$store.state.config.viewActorTypes && this.$store.state.config.viewActorTypes.length) {
        filters = Object.assign({}, this.$store.getters.mapFilterObject, { actor_type: this.$store.state.config.viewActorTypes })
      } else {
        filters = Object.assign({}, this.$store.getters.mapFilterObject)
      }

      if (filters.tl) {
        this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_MAP, filters)
      }
    },
    renderMap () {
      this.$refs.mapPane && this.$refs.mapPane[0] && this.$refs.mapPane[0].invalidateSize()
    },
    fetchStatsData () {
      const parameters = {}

      fetchStatsData(parameters)
          .then(response => {
            this.legendCounts = response.data
          })
          .catch(errors => {
            //
          })
    },
  },
  watch: {
    filters: {
      deep: true,
      handler () {
        this.fetchFilteredActors()
        this.getMapData()
      },
    },
    'mapData': {
      handler (newValue, oldValue) {
        if (newValue.length !== oldValue.length) {
          setTimeout(this.renderMap, 200)
        }
      },
    },
    'mapFilter': {
      handler () {
        this.getMapData()
      },
    },
    'locale': {
      handler () {
        // Allow for the locale to be persist in the back-end first
        this.fetchPublicNotifications()
      },
    },
  },
})
</script>

<style lang="scss" scoped>
@import "resources/assets/scss/variables";

.home-simplified {
  display: flex;
  flex-direction: column;

  .home-simplified__container {
    flex-grow: 1;
    width: 100%;
    padding: 0 20px;
  }

  .home-simplified__articles-container {
    & > div {
      display: block;
      position: relative;
      margin-bottom: 10px;
    }

    .home-simplified__article:nth-child(4) {
      @media (max-width: $screen-lg) {
        display: none;
      }
    }

    .carousel__slide-container {
      height: 100%;
    }
  }

  .home-simplified__subtitle {
    padding-top: 20px;
    font-size: 30px;
    color: black;

    :deep(a) {
      color: black;
    }

    &.extra-padding {
      padding-bottom: 15px;
    }

    :deep(a) {
      text-decoration: none;
    }
  }

  .vertical-challenge-container {
    flex-grow: 1;

    :deep(.challenge-container) {
      .lock-icon {
        right: 8px;
      }

      &:last-child {
        .challenge {
          margin-bottom: 0;
        }
      }
    }
  }

  .headline {
    margin: 0 auto;
    max-width: 600px;
    padding-bottom: 20px;

    :deep(input) {
      background-color: white;
    }
  }

}

:deep(.inaccessible-article__cta-container) {
  position: relative;
  overflow-y: hidden;
  display: block;

  .inaccessible-article__cta {
    div {
      color: transparent;
      text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
    }
  }

  .lock-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    fill: var(--primary)
  }

  &:hover,
  &:focus {
    .intelligence-cta__overlay {
      transform: translate3d(0, 0, 0);
    }
  }

}

.intelligence-cta__overlay {
  margin: 1px;
  padding: 0 5px;
  position: absolute;
  height: calc(100% - 1rem - 2px);
  width: calc(100% - 2px);
  background: rgba(255, 255, 255, 0.7);
  top: 0;
  transition: transform 300ms;
  transform: translate3d(0, 100%, 0);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.carousel-spotlight__actor-image-container {
  margin: 20px;
  display: block;
  width: 100%;

  .carousel-spotlight__actor-image {
    background-repeat: no-repeat;
    width: 100%;
    height: 100px;
    background-position: center;
    display: flex;
    align-items: center;
    text-align: center;
    color: green;

    span {
      margin: 0 auto;
      color: black;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 4;
      line-height: 14px;
      text-align: center;
      max-height: 56px;
      max-width: 120px;
    }
  }
}

.carousel-spotlight {
  margin-left: -20px;
  margin-right: -20px;
  width: calc(100% + 40px);
}
</style>
