<template>
  <div class="spotting-area__trends">
    <Container style="flex-grow: 1">

      <div v-if="spottingAreaShowIntelligenceUpgradeCTA" class="spotting-area__trends_article">
        <div class="row mt-3 home-simplified__articles-container">
          <div class="col col-xs-12 col-sm-12 col-md-12 home-simplified__article"
               v-for="index in [1,2,3,4,5,6,7]"
               :key="'fake-article' + index">
            <div class="carousel__slide-container">
              <UnstyledLink target="_blank" class="inaccessible-article__cta-container">
                <div class="intelligence-cta__overlay">
                  <h3 class="h3">
                    <router-link to="/profile/subscription#upgrade">{{ spottingAreaSplitCtaText[1] }}</router-link>
                  </h3>
                </div>
                <icon name="lock" class="lock-icon"/>
                <MiniCard
                  class="inaccessible-article__cta"
                  title="inaccessible - upgrade required"
                  subtitle="inaccessible"
                  content="This content is not accessible because an upgrade to a higher-level package is required"
                />
              </UnstyledLink>
            </div>
          </div>
        </div>
      </div>

      <div v-if="!spottingAreaShowIntelligenceUpgradeCTA" style="display: flex; justify-content: space-between; margin-top: 1rem;">
        <h2>{{ $t('shp_latest_articles').toUpperCase() }}</h2>
        <div class="global-search__results-sort" id="introjs-rank"
          v-if="displaySorting">
          <div>
            RANK CONTENT BASED ON:
            <label-filter
              @updateFilter="updateContentFiltersSort('relevance')"
              value="relevance"
              :is-enabled="contentFilters.sort === 'relevance'"
              text="Relevancy">
            </label-filter>
            <label-filter
              @updateFilter="updateContentFiltersSort('recency')"
              value="recency"
              :is-enabled="contentFilters.sort === 'recency'"
              text="Recency">
            </label-filter>
          </div>
        </div>
      </div>

      <div v-if="!spottingAreaShowIntelligenceUpgradeCTA" class="spotting-area__trends_article" style="padding-top: 10px;">
        <template>
        </template>
        <template v-if="fetchingContent">
          <loading/>
        </template>
        <template v-for="(item, index) in articles" :key="index" v-else-if="articles && articles.length > 0">
          <AnnouncementOrResourceCard
            style="max-width: 100%"
            :data="item"
            :preview-mode="false"
            @like-article="likeArticle"
            @addTag="addTagToFilters"
            @resourceDeleted="this.fetchArticles()"
          />
        </template>
        <template v-else>
          <div style="display: flex; justify-content: center; margin-top: 1rem;">
            No results found
          </div>
        </template>
      </div>
    </Container>
  </div>
</template>

<script>
  import { fetchKnowledgeBaseData, likeArticle } from '../../api/knowledge-base.js'
  import Container from '../../components/Container/Container.vue'
  import AnnouncementOrResourceCard from '../Simplified/AnnouncementOrResourceCard.vue'
  import LabelFilter from '../Filters/LabelFilter.vue'
  import Loading from '../Dashboard/ConceptMap/Loading.vue'
  import { MUTATION_TYPES as KNOWLEDGE_BASE_MUTATION_TYPES } from '../../store/modules/knowledgeBase'
  import { defineComponent } from 'vue'
  import MiniCard from '@/components/MiniCard/MiniCard.vue'
  import UnstyledLink from '@/components/UnstyledLink/UnstyledLink.vue'
  import SpottingAreaMixin from '@/util/SpottingAreaMixin.js'

  export default defineComponent({
    components: { UnstyledLink, MiniCard, Loading, Container, AnnouncementOrResourceCard, LabelFilter },
    props: {
      spottingAreaId: {
        type: Number,
        required: true,
      },
    },
    data () {
      return {
        articles: [],
        fetchingContent: false,
        contentFilters: {
          sort: 'relevance',
        },
      }
    },
    computed: {
      displaySorting () {
        return this.$store.getters.isOwner || this.$store.getters.isMember
      },
    },
    methods: {
      likeArticle ({ resourceId, status }) {
        likeArticle(resourceId, status)
      },
      updateContentFiltersSort (sortBy) {
        this.contentFilters.sort = sortBy

        this.fetchArticles()
      },
      addTagToFilters (tag) {
        if (!tag.value) {
          return
        }

        this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.CLEAR)
        this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.SET_FACET_KEYWORD, tag)

        if (this.$route.name && this.$route.name.indexOf('knowledge-base') <= 0) {
          this.$router.push('/dashboards/knowledge-base')
        }
      },
      fetchArticles () {
        this.fetchingContent = true

        var sortBy = (this.contentFilters.sort === 'recency' ? 'date' : null)

        const filters = {
          media_types: ['rss', 'pr', 'blog'],
          spotting_area: this.spottingAreaId,
          'ml-supported': true,
          // relevantOnly: false,
          limit: 100,
          sortBy,
        }

        fetchKnowledgeBaseData(filters)
          .then(response => {
            this.articles = response.data.results

            this.fetchingContent = false
          })
          .catch(error => {
            console.log(error)
            this.fetchingContent = false
            this.articles = null
          })
      },
    },
    mounted () {
      this.fetchArticles()
    },
    mixins: [SpottingAreaMixin]
  })
</script>

<style lang="scss" scoped>
  :deep(.inaccessible-article__cta-container) {
    position: relative;
    overflow-y: hidden;
    display: block;

    .inaccessible-article__cta {
      div {
        color: #0000001c;
        text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
      }
    }

    .lock-icon {
      position: absolute;
      right: 10px;
      top: 10px;
      fill: var(--primary)
    }

    &:hover,
    &:focus {
      .intelligence-cta__overlay {
        transform: translate3d(0, 0, 0);
      }
    }

  }

  .intelligence-cta__overlay {
    padding: 50px;
    margin: 1px;
    // padding: 0 5px;
    position: absolute;
    height: calc(100% - 1rem - 2px);
    width: calc(100% - 2px);
    background: rgba(255, 255, 255, 0.7);
    top: 0;
    transition: transform 300ms;
    transform: translate3d(0, 100%, 0);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
</style>
