<template>
  <div class="buzz__individual-table-container">
    <buzz-topics-dropdown :options="searchBoxDropdownTopics" @change="searchDropdownData" @update:modelValue="selectedItem" v-if="isMember || isOwner"/>
    <template v-for="(row, key) in tableList">
      <div class="buzz__individual-table-section-container" v-if="(row && row.length > 0) && !hiddenTables.includes(key)">
        <ds-button variant="primary" size="small" icon="chevron-right" :label="key" @click="collapseTable(key)" class="buzz__individual-table-section-header"/>
        <div v-if="expandedTables.includes(key)">
          <template v-for="(item, index) in row">
            <template v-if="key == 'filter'">
              <template v-if="activeKeywordType">
                <li :title="item.text" v-if="item.text == activeKeywordType">
                  <ds-button
                    variant="minimal"
                    size="small"
                    :label="index+1 + ' | ' + (item.text)"
                    @click="updateBuzzFilters(item, item.type)"
                    class="buzz__individual-table-section-item"
                    :disabled="disabled"
                    :class="{'active-table-item': tableItemIsActive(item)}"/>
                </li>
              </template>
              <template v-else>
                <li :title="item.text">
                  <ds-button :disabled="disabled" variant="minimal" size="small" :label="index+1 + ' | ' + (item.text)" @click="updateBuzzFilters(item, item.type)" class="buzz__individual-table-section-item"
                             :class="{'active-table-item': tableItemIsActive(item)}"/>
                </li>
              </template>
            </template>
            <template v-else>
              <li :title="item.text || item.name || item.actorName || item">
                <ds-button :disabled="disabled" variant="minimal" size="small" :label="index+1 + ' | ' + (item.name || item.actorName || item)" @click="updateBuzzFilters(item, key)"
                           class="buzz__individual-table-section-item" :class="{'active-table-item': tableItemIsActive(item)}"/>
              </li>
            </template>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import BuzzTopicsDropdown from '../Dropdown/BuzzTopicsDropdown.vue'

  import { fetchBuzzData, fetchBuzzDropdownData } from '../../api/buzz.js'

  import { MUTATION_TYPES as BUZZ_MUTATION_TYPES } from '../../store/modules/buzz'
  import debounce from 'lodash/throttle'
  import { MUTATION_TYPES as MEDIA_BUZZ_MUTATION_TYPES } from '../../store/modules/mediabuzz.js'


  export default {
    name: 'BuzzTable',
    data () {
      return {
        keywordTimeRange: {
          day: { name: 'day', text: 'Today', type: 'time' },
          week: { name: 'week', text: 'Past week', type: 'time' },
          month: { name: 'month', text: 'Past month', type: 'time' },
        },
        keywordTypes: {
          ds_event: { name: 'ds_event', text: 'Events', type: 'keyword_type' },
          ds_job: { name: 'ds_job', text: 'Jobs', type: 'keyword_type' },
          ds_funding: { name: 'ds_funding', text: 'Funding', type: 'keyword_type' },
          all: { name: 'ds_all', text: 'All', type: 'keyword_type' },
        },
        tableData: {
          channels: {},
          influencers: [],
          postTypes: {},
          topics: [],
        },
        expandedTables: ['filter', 'channels', 'topics', 'influencers'],
        searchBoxDropdownTopics: [],
      }
    },
    props: {
      hiddenTables: {
        type: Array,
        default: () => [],
      },
      disabled: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      activeKeywordType () {
        const existingEntry = this.$store.state.buzz.filters.find(k => k.facet === 'keyword_type')

        return existingEntry && existingEntry.name
      },
      filters () {
        return this.$store.state.filters
      },
      topics () {
        if (this.tableData && this.tableData.topics) {
          return this.tableData.topics
        }
      },
      channels () {
        if (this.tableData && this.tableData.channels) {
          return Object.keys(this.tableData.channels)
        }
      },
      influencers () {
        if (this.tableData && this.tableData.influencers) {
          return this.tableData.influencers
        }
      },
      filterTypeList () {
        // Only display keyword types that are in the scope of the meta information
        var filterablePostTypes = Object.keys(this.tableData.postTypes)
        var filterTypeList = [this.keywordTypes.all]

        if (filterablePostTypes && filterablePostTypes.length > 0) {
          for (var i = 0; i < filterablePostTypes.length; i++) {
            var type = filterablePostTypes[i]
            var postType = this.keywordTypes[type]

            if (postType) {
              filterTypeList.push(postType)
            }
          }
        }

        return filterTypeList
      },
      tableList () {
        return { filter: this.filterTypeList, channels: this.channels, topics: this.topics, influencers: this.influencers }
      },
      portfolio () {
        return this.$store.state.filters.portfolio || {}
      },
      activeParameters () {
        return this.$store.state.buzz.filters
      },
      isMember () {
        return this.$store.getters.isMember
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
    },
    methods: {
      resetFilters () {
        this.$store.commit(BUZZ_MUTATION_TYPES.RESET_BUZZ_FILTERS)
      },
      collapseTable (table) {
        var index = this.expandedTables.indexOf(table)

        if (index > -1) {
          this.expandedTables.splice(index, 1)
        } else {
          this.expandedTables.push(table)
        }
      },
      updateBuzzFilters (filterValue, filterType) {
        const updateMutation = this.$route.params.panel === 'media-buzz' ? MEDIA_BUZZ_MUTATION_TYPES.UPDATE_BUZZ_FILTER : BUZZ_MUTATION_TYPES.UPDATE_BUZZ_FILTER
        switch (filterType) {
          case 'topics':
            this.$store.commit(updateMutation, { name: filterValue.name, facet: 'topic', value: filterValue.id })
            break
          case 'influencers':
            this.$store.commit(updateMutation, { name: filterValue.actorName, facet: 'actor', value: filterValue.actorId })
            break
          case 'channels':
            this.$store.commit(updateMutation, { name: filterValue, facet: 'channel', value: filterValue })
            break
          case 'keyword_type':
            this.$store.commit(updateMutation, { name: filterValue.text, facet: 'keyword_type', value: filterValue.name })
            break
        }
      },
      searchDropdownData: debounce(function (searchValue) {
        if (searchValue.length < 2) {
          this.searchBoxDropdownTopics = []

          return
        }

        var portfolio = {}

        if (this.$store.state.filters.portfolio) {
          portfolio = { portfolio: this.$store.state.filters.portfolio }
        }

        // Fetch the selected keyword type and the selected timeframe and add it to the query
        var searchParameters = { query: searchValue }

        searchParameters = Object.assign(portfolio, searchParameters)
        searchParameters = Object.assign(portfolio, this.$store.getters.buzzQueryParameters)

        fetchBuzzDropdownData(searchParameters)
          .then(response => {
            this.searchBoxDropdownTopics = []
            response.forEach(item => {
              this.searchBoxDropdownTopics.push(item)
            })
          })
          .catch(error => {
            console.log(error, 'Error while fetching dropdown data')
          })

      }, 500),
      selectedItem (item) {
        this.updateBuzzFilters(item, 'topics')
      },
      fetchTableData () {
        var portfolio = {}
        var fullParameters = {}

        if (this.$store.state.filters.portfolio) {
          portfolio = { portfolio: this.$store.state.filters.portfolio }
        }

        // Assign the portfolio to the parameters
        fullParameters = Object.assign(portfolio, this.$store.getters.buzzQueryParameters)
        fullParameters = Object.assign({ type: 'meta' }, fullParameters)

        fetchBuzzData(fullParameters).then(response => {
          this.tableData = response
        }).catch(errors => {
          console.log(errors, 'An error occurred while fetching meta data for the Buzz.')
        })
      },
      tableItemIsActive (item) {
        if (this.activeParameters.length > 0) {
          var parameters = this.activeParameters.map(parameter => parameter.value)

          // The parameters in the meta-table have different structures as they represent different types of data (i.e. an actor has a name and an ID, a channel only has a name)
          return parameters.includes(item.id) || parameters.includes(item.actorId) || parameters.includes(item.name) || parameters.includes(item)
        }

        return false
      },
    },
    mounted () {
      this.fetchTableData()
    },
    watch: {
      activeParameters (parameters) {
        this.fetchTableData()
      },
      filters: {
        deep: true,
        handler () {
          this.fetchTableData()
        },
      },
    },
    components: {
      BuzzTopicsDropdown,
    },
  }
</script>
