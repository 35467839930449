export const proofPointGroupByOptionTypes = {
  PROOF_POINT_TYPE: 'proofPointType',
  PESTEL: 'pestel',
  PROOF_POINT_ANALYSIS_TYPE: 'proofPointAnalysisType',
  PROOF_POINT_ITEM_TYPE: 'proofPointItemType',
}

export const proofPointTypes = {
  SIGNAL: 'signal',
  BLIND_SPOT: 'blind_spot',
  PROOF_POINT: 'proof_point',
}

export const proofPointItemTypes = {
  ACTOR: 'actor',
  CONTENT: 'content',
  FILE: 'file',
  ARTICLE: 'article',
  HIGHLIGHT: 'highlight',
}

export const proofPointAnalysisTypes = {
  KEY_TRENDS: 'key_trends',
  INDUSTRY_FORCES: 'industry_forces',
}
