<template>
  <div class="simplified-footer" :class="{'withMargin': withMargin}">
    <div class="row">
      <div class="col-xs-11" style="margin: auto;">
        <div class="simplified-footer__content">
          <div class="simplified-footer__top">
            <div class="simplified-footer__top__left" :style="{width: '50%'}">
              <div class="simplified-footer-background-image">
                <img src="/images/static-home-page/community-footer-icon-ds.svg" alt="DataScouts">
              </div>

              <a :href="externalUrl" v-if="config.footer_logo" class="logo__container">
                <div
                  v-if="logo"
                  :style="`background-image: url('${config.footer_logo}');`"
                  class="simplified-footer__logo_image"
                />
              </a>
              <div class="simplified-footer__about-description">
                Gain market insights. <span>More efficient and faster.</span><br/><br/>
              </div>

              <div class="simplified-footer__description" v-if="config.footerDescription">
                {{ config.footerDescription }}<br/><br/>
              </div>
              <!--<a
                href="/info" target="_blank" rel="noopener noreferrer"
                @click="trackHeapEvent('simplifiedFooter.information')">{{
                  $t('link_information')
                }}</a>-->
              <div v-if="facebookUrl || twitterUrl || linkedInUrl || instagramUrl" style="margin: 5px 0;">
                <br v-if="config.footerCtaEnabled">
                <div class="simplified-footer__icons">
                  <a :href="facebookUrl" target="_blank" rel="noopener noreferrer" v-if="facebookUrl">
                    <icon name="facebook-logo"/>
                  </a>
                  <a :href="twitterUrl" target="_blank" rel="noopener noreferrer" v-if="twitterUrl">
                    <icon name="twitter-logo"/>
                  </a>
                  <a :href="linkedInUrl" target="_blank" rel="noopener noreferrer" v-if="linkedInUrl">
                    <icon name="linkedin-logo"/>
                  </a>
                  <a :href="instagramUrl" target="_blank" rel="noopener noreferrer" v-if="instagramUrl">
                    <icon name="instagram-logo-white" style="top: 2px"/>
                  </a>
                </div>
                <br/>
              </div>
              <a :href="'https://www.datascouts.eu/en/kmo-portefeuille'" class="logo__container">
                <div
                  :style="`background-image: url('${kmoLogo}');`"
                  class="simplified-footer__logo_image"
                />
              </a>
            </div>
            <div class="simplified-footer__top__middle__left" :style="{width: '25%'}">
              <div style="margin-bottom: 12px;text-transform: uppercase;"><b>{{ ecosystemName }}</b></div>
              <div class="simplified-footer__contact">
                <router-link :to="'/actors-simplified'">{{ onboardingTextConfig.actorsSecondTopBarTitle }}</router-link>
                <router-link :to="'/products-simplified'" v-if="displayProducts">{{ productsLabel }}</router-link>
                <router-link :to="'/announcements'" v-if="displayAnnouncements">{{ announcementsLabel }}</router-link>
                <a @click="goToAnnouncementsWithPredefinedCategory"
                  v-if="displayAnnouncements && preFilteredAnnouncementCategory && preFilteredAnnouncementCategory.id">{{ preFilteredAnnouncementCategory.name }}</a>
                <router-link :to="'/events'" v-if="displayEvents">{{ eventsLabel }}</router-link>
                <router-link :to="'/dashboards/knowledge-base'" v-if="displayKnowledgeBase">{{ knowledgeBaseLabel }}</router-link>
              </div>
            </div>
            <div class="simplified-footer__top__middle__right" :style="{width: '25%'}">
              <div style="margin-bottom: 12px;"><b>OUR COMPANY</b></div>
              <div class="simplified-footer__contact">
                <a :href="'https://www.datascouts.eu/en/about'" title="About">About</a>
                <a :href="'https://www.datascouts.eu/en/vacancies'" title="Vacancies">Vacancies</a>
                <a :href="'https://www.datascouts.eu/en/contact'" title="Contact">Contact</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="simplified-footer--bottom-row">
    <div class="row">
      <div class="col-xs-11" style="margin: auto;">
        <div class="simplified-footer__content-bottom-row">
          <a
            :href="privacyPolicyUrl" target="_blank" rel="noopener noreferrer"
            @click="trackHeapEvent('simplifiedFooter.privacyPolicy')">
            Privacy
          </a>
          <a
            :href="termsOfUseUrl" target="_blank" rel="noopener noreferrer"
            @click="trackHeapEvent('simplifiedFooter.termsOfUse')">
            Terms
          </a>
          <a
            :href="cookiePolicyUrl" target="_blank" rel="noopener noreferrer"
            @click="trackHeapEvent('simplifiedFooter.cookiePolicy')">
            Cookie
          </a>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  import objectPath from 'object-path'
  import Container from '../Container/Container.vue'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import AnnouncementMixin from '../../util/AnnouncementMixin.js'
  import OnboardingMixin from '../../util/OnboardingMixin.js'
  import { DEFAULT_LOCALE } from '../../store/modules/localization'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'
  import { trackHeapEvent } from '../../util/analytics.js'
  import { DEFAULT_URLS } from '../../constants/default-urls.js'
  import UiMixin from '../../util/UiMixin.js'
  import { defineComponent } from 'vue'
  import { MUTATION_TYPES as NOTIFICATION_MUTATION_TYPES } from '@/store/modules/notifications'

  export default defineComponent({
    props: {
      withMargin: {
        type: Boolean,
        default: true,
      },
    },
    data: () => {
      return {
        preFilteredAnnouncementCategory: {},
      }
    },
    computed: {
      ecosystemName () {
        return this.$store.getters.ecosystemDisplayName
      },
      numberOfTopSections () {
        let result = 1

        if (this.emailAddress) {
          result++
        }
        if (this.config.footerDescription) {
          result++
        }
        if (this.config.footerCtaEnabled) {
          result++
        }

        return result
      },
      isMobile () {
        return this.ui.isMobile
      },
      bottomSectionWidth () {
        if (this.isMobile) {
          return '100%'
        }
        return '33.333%'
      },
      topSectionWidth () {
        if (this.isMobile) {
          return '100%'
        }

        switch (this.numberOfTopSections) {
          case 1:
            return '100%'
          case 2:
            return '50%'
          case 3:
            return '33.333%'
          case 4:
            return '25%'
        }
      },
      displayProducts () {
        return this.$store.getters.viewActorTypes.includes('Product')
      },
      displayAnnouncements () {
        return this.$store.getters.canUsePublicAnnouncements && this.$store.getters.areMessageBoardsEnabled
      },
      displayEvents () {
        return this.displayAnnouncements
      },
      displayChallenges () {
        return this.$store.state.config.challengesAreEnabled && this.shouldSeeChallenges
      },
      displayKnowledgeBase () {
        return this.$store.getters.hasAccessToKnowledgeBase && this.config.viewDashboard && this.config.viewDashboard.includes('knowledge-base')
      },
      shouldSeeChallenges () {
        if (this.$store.getters.isMember) {
          return true
        }

        return this.$store.getters.canCreateChallenge ||
          this.$store.getters.viewableChallengeStatusses.length > 0 ||
          this.$store.getters.accessibleChallengeStatusses.length > 0
      },
      logo () {
        return this.config.logo_url_light || this.config.logo_url
      },
      config () {
        return this.$store.state.config
      },
      homeLinks () {
        return (window.config.homeLinks || []).reduce((acc, link) => {
          // [...] we don't support multiline homelinks anymore
          acc.push(link)
          return acc
        }, [])
      },
      userLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      emailAddress () {
        return this.config.email
      },
      facebookUrl () {
        return this.config.facebookUrl
      },
      twitterUrl () {
        return this.config.twitterUrl
      },
      linkedInUrl () {
        return this.config.linkedInUrl
      },
      instagramUrl () {
        return this.config.instagramUrl
      },
      termsOfUseUrl () {
        return this.config.termsOfUseUrl || DEFAULT_URLS.TERMS_OF_USE
      },
      privacyPolicyUrl () {
        return this.config.privacyPolicyUrl || DEFAULT_URLS.PRIVACY_POLICY
      },
      cookiePolicyUrl () {
        return this.config.cookiePolicyUrl || DEFAULT_URLS.COOKIE_POLICY
      },
      externalUrl () {
        return this.config.homepage
      },
      externalUrlLabel () {
        return this.localizedDisplayProperty(this.config, 'externalUrlLabel')
      },
      kmoLogo () {
        return this.config.url + '/images/static-home-page/Logo-KMO-Portefeuille.jpg'
      },
    },
    mounted () {
      this.fetchPreFilteredAnnouncementCategory()
    },
    methods: {
      trackHeapEvent,
      openContactModal () {
        trackHeapEvent('simplifiedFooter.contactModal')
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          sendTo: 'owners',
          placeholder: '',
          title: 'Get in touch',
          subject: 'Get in touch', // The subject that will be set in the email we send to owners
        })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CONTACT_SUPPORT, {})
      },
      localizedLinkText (index) {
        let result = this.homeLinks[index].text

        if (this.locale !== DEFAULT_LOCALE) {
          result = objectPath.get(this.config, `translations.${this.locale}.homeLinksText${index}`, result)
        }

        return result
      },
      localizedLinkUrl (index) {
        let result = this.homeLinks[index].url

        if (this.locale !== DEFAULT_LOCALE) {
          result = objectPath.get(this.config, `translations.${this.locale}.homeLinksUrl${index}`, result)
        }

        return result
      },
      fetchPreFilteredAnnouncementCategory () {
        const announcementCategory = this.availableAnnouncementCategories.filter(category => category.name === 'Product Q&A')

        if (!announcementCategory) {
          return {}
        }

        this.preFilteredAnnouncementCategory = announcementCategory[0]
      },
      goToAnnouncementsWithPredefinedCategory () {
        const announcementCategory = this.preFilteredAnnouncementCategory

        if (!announcementCategory || !announcementCategory.id) {
          this.$router.push('/announcements')

          return
        }

        this.$store.commit(NOTIFICATION_MUTATION_TYPES.TOGGLE_ACTIVE_ANNOUNCEMENT_CATEGORIES_FILTER, { announcementCategory: announcementCategory, overwriteExists: true })

        this.$router.push('/announcements')
      },
    },
    mixins: [
      UiMixin,
      TranslationsMixin,
      OnboardingMixin,
      AnnouncementMixin,
    ],
    components: {
      Container,
    },
  })
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables";

  $spacing: 30px;

  .simplified-footer {
    position: relative;
    background: #323441;
    font-size: 12px;
  }

  .simplified-footer.withMargin {
    margin-top: auto;
  }

  .simplified-footer-background-image {
    position: absolute;
    top: -55px;

    img {
      max-height: 50px;
      width: 100%;
    }
  }

  .simplified-footer__content {
    margin-top: 30px;
    position: relative;
    color: #EBEBEC;
    line-height: 1.5;
    padding: calc(#{$spacing} / 2);

    a {
      color: #EBEBEC;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .simplified-footer__top {
    display: flex;

    .logo__container {
      display: flex;
      justify-content: left;

      &:first-child {
        margin-bottom: 10px;
      }

      &:last-child {
        margin-top: 10px;
      }
    }
  }

  .simplified-footer__top__right {
    margin-left: auto;
  }

  a.simplified-footer__cta_button {
    color: var(--primary);
    white-space: normal;
    word-break: break-word;

    &:hover {
      color: var(--primary);
      background-color: #EBEBEC;
    }
  }

  .simplified-footer__logo_image {
    min-height: 80px;
    width: 200px;
    background-repeat: no-repeat;
    background-position: left;
    background-size: contain;
  }

  .simplified-footer__bottom__right {
    margin-left: auto;
    text-transform: uppercase;
  }

  .simplified-footer__top__left, .simplified-footer__top__right {
    padding: calc($spacing / 2);
  }

  .simplified-footer__bottom__left, .simplified-footer__bottom__middle {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }

  .simplified-footer__contact {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    a {
      margin-bottom: 12px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .simplified-footer__about-description {
    font-size: 21px;
    font-weight: bold;
    max-width: 300px;

    span {
      color: #00EFC3;
    }
  }

  .simplified-footer__description {
    max-width: 350px;
  }

  .simplified-footer__bottom__left {
    padding: calc($spacing / 2);
  }

  .simplified-footer__bottom__right {
    padding: calc($spacing / 2);
  }

  .simplified-footer__bottom__middle {
    padding: calc($spacing / 2);
    flex-grow: 1;

    & > div {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }

  .simplified-footer__icons {
    font-size: 0;
    display: flex;
    flex-direction: row;
    gap: 10px;
    width: 100px;
    white-space: initial;

    a {
      display: inline-block;

      .svg-icon--world {
        top: 2px;
      }

      :deep(g) {
        fill: #EBEBEC !important;
      }
    }
  }

  .simplified-footer__bottom {
    display: flex;
  }

  .simplified-footer__top__left {
    .no-link:hover {
      cursor: initial;
      text-decoration: none;
    }
  }

  .simplified-footer__top__middle__left, .simplified-footer__top__middle__right {
    padding: calc($spacing / 2);
  }

  @media screen and (max-width: $screen-md) {
    .simplified-footer__logo_image {
      background-position: center;
    }

    .simplified-footer__top {
      flex-wrap: wrap;

      .logo__container {
        justify-content: flex-start;
      }
    }

    .simplified-footer__description {
      width: 50%;
    }

    .simplified-footer__bottom {
      display: block;
    }

    .simplified-footer__top__left, .simplified-footer__top__right, .simplified-footer__bottom__left, .simplified-footer__bottom__right, .simplified-footer__top__middle__right, .simplified-footer__top__middle__left, .simplified-footer__bottom__middle {
      width: 100%;
      margin: 0 auto;
      justify-content: flex-start;
      flex-grow: 1;
    }

    .simplified-footer__description {
      width: 100%;
    }
  }

  .simplified-footer--bottom-row {
    background-color: #EBFEFA;
    padding-bottom: 15px;
    color: #323441;

    .simplified-footer__content-bottom-row {
      margin-top: 30px;
      margin-bottom: 15px;
      padding-left: 30px;
      display: flex;
      flex-direction: row;
      gap: 30px;
      justify-content: flex-start;

      a {
        text-decoration: none;
        color: #323441;
        font-weight: bold;
      }
    }
  }

  .simplified-footer__icons {
    .fill-fg, #Fill-1, path {
      fill: #EBEBEC !important;
    }
  }

  .fill-fg {
    fill: #EBEBEC !important;
  }
</style>
