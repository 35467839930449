<template>
  <div class="slider-arrow__container" :style="cssProperties"  @click="arrowClick">
    <div class="slider-arrow__list-items">
        <ds-button variant="minimal" size="large" v-if="arrowOrientation == 'right'" icon="chevron-right" class="slider-arrow__button slider-arrow__push-right"/>
        <ds-button variant="minimal" size="large" v-else icon="chevron-right" class="slider-arrow__button slider-arrow__flip"/>
    </div>
  </div>
</template>

<script>
import DsButton from '../DsButton/DsButton.vue'
import ICONS from '../../../icons/manifest.json'
import slugify from '../../util/slugify'
export default {
    name: "slider-arrow",
    data () {
        return {
            ICONS,
        }
    },
    props: {
        arrowOrientation:String,
        positionElement: String,
        currentChart:String
    },
    computed: {
        cssProperties () {
            if(this.positionElement == "left") {
                return "left: 10px"
            }
            if (this.positionElement == "right") {
                return "right: 10px"
            }
        }
    },
    methods: {
        arrowClick () {
            this.$emit('clickDirection', this.arrowOrientation)
        }
    },
    watch: {
        currentChart (val) {
            this.currentChart = val;
        }
    },
    components: {
        DsButton
    }
}
</script>
<style lang="scss">
@import "../../../scss/_variables.scss";

.slider-arrow__container {
    /*margin:auto;
    text-align: center;*/
    width: 50px;
    top: 55%;
    position:absolute
}

.slider-arrow__list-items :first-child {
    float:left;
}
.slider-arrow__list-items :last-child {
    float:right;
}
.slider-arrow__list-items {
    display:inline-block;
    list-style:none;
    margin-bottom: -100%;
}

.slider-arrow__button {
    font-size:23px !important;
    color: $color-text-grey !important;
}
.slider-arrow__flip {
    transform: rotate(180deg);
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
}
.slider-arrow__push-right {
    margin-left: 10px;
}

.slider-arrow__button > div {
    width: 50px;
    height: 50px;
    margin-left: -13px;
    margin-top: -26px !important;
}
</style>
