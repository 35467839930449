<template>
  <div class="configuration-tabs">
    <template v-for="(item, index) in items" :key="index">
      <configuration-tabs-item :item="item" v-if="item"/>
    </template>
  </div>
</template>

<script>
  import ConfigurationTabsItem from './ConfigurationTabsItem.vue'

  export default {
    components: { ConfigurationTabsItem },
    props: {
      items: Array,
    },
  }
</script>

<style lang="scss" scoped>
  .configuration-tabs {
    padding: 10px;
  }
</style>
