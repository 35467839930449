<template>
  <div class="keyword" :class="{ 'keyword--removeable': removeable }" @click="handleRemoveClick">
    <div class="avatar avatar--border" :class="extraClasses">
      <img class="avatar__img" v-if="src&&error!==src" :src="src" @error="error=src" :alt="src" />
      <div class="avatar__text" v-else>{{ initials }}</div>
    </div>
    <icon name="remove" v-if="removeable" />
    <icon :name="icon" v-if="icon" />
     <div class="keyword__label">{{ humanizeLabel }}</div>
  </div>
</template>

<script>
  import Avatar from '../Avatar/Avatar.vue'

  export default {
    name: 'avatar-keyword',
    props: {
      icon: String,
      label: [String, Number],
      modelValue: null,
      removeable: Boolean,
      src: {
        type: String
      },
      alt: {
        type: String
      },
      variant: {
        type: String,
        default: 'default'
      }
    },
    data () {
      return {
        // Error will contain the url of the last image that threw an error
        error: false,
        extraClasses: {
          [`avatar--${this.variant}`]: this.variant
        }
      }
    },
    computed: {
      initials () {
        return this.label.charAt(0) || ''
      },
      humanizeLabel () {
        return this.label
      },
    },
    methods: {
      handleRemoveClick () {
        this.$emit('remove', this.modelValue)
      }
    },
    components: {
      Avatar
    }
  }
</script>
