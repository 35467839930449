<template>
  <div class="agritech scrollable" style="background-color: white;">
    <div style="padding-bottom: 100px; background-color: #F2F2F2">
      <SimpleVideoHero :is-agritech="true" video-source="/images/static-home-page/agritech/agritech_short.mp4"
                       style="max-height: 400px; height: 500px; position: relative;"/>

      <Container>
        <div class="agritech__info">
          <div class=" agritech__cards">
            <div v-for="data in cardData" class="col-xs-12 col-sm-10 col-md-6 col-lg-4 agritech__card">
              <h2>{{ data.title }}</h2>
              <p style="height: 70px;">{{ data.description }}</p>
              <h4>{{ data.subtitle }}</h4>
              <div class="agritech__card_content" v-if="data.key === 'latest_actors'">
                <div v-for="item in latestActors" class="agritech__card_item" @click="goToActorDetailPage(item)">
                  <avatar :src="item.logo" :alt="item.name" variant="border"></avatar>
                  <p style="font-weight: 700;">{{ item.name }}</p>
                </div>
              </div>
              <div class="agritech__card_content" v-else-if="data.key === 'latest_challenges'">
                <div v-for="item in latestChallenges" class="agritech__card_item"
                     @click="goToChallengeDetailPage(item)">
                  <avatar :src="'/api/challenges/'+item.id+'/image'" :alt="item.title" variant="border"></avatar>
                  <p style="font-weight: 700;">{{ item.title }}</p>
                </div>
              </div>
              <a class="static-home-agritech__card_button" style="cursor: pointer;"
                 @click="goToPage(data.buttonHref + '?openModal=true')">{{
                  data.buttonText
                }}</a>
            </div>
          </div>
        </div>
      </Container>
      <Container v-if="isLoggedIn && featuredChallenges.length > 1" class="agritech__communities"
                 style="margin-top: 5rem">
        <h1 style="padding-bottom: 20px;">Featured challenges</h1>
        <div class="row">
          <div class="col-md-6 col-xs-12" v-for="challenge in featuredChallenges">
            <simplified-challenge-card
                :challenge="challenge"
                :is-accessible-for-user="isAccessibleForUser"
                :is-vertical="true"
                button-text="Read more"
                :is-agritech="true"
            />
          </div>
        </div>
      </Container>

      <div></div>
      <Container v-if="hasAccessToSpottingAreas && spottingAreas.length > 0">
        <div class="agritech__communities" style="margin-top: 5rem">
          <h1>Explore opportunity areas</h1>
          <p>Uncover growth potential, capitalize on emerging trends, and harness technological advancements as we <br/>
            guide
            you through the strategic domains where innovation meets agriculture's future.</p>

          <div class="agritech__communities_list_view">
            <div v-for="item in spottingAreas" class="agritech__communities_list">
              <div class="agritech__communities_cards">
                <SimplifiedCommunityCard
                    style="height: 470px"
                    :title="item.name" :description="item.description" :image="getImageForSpottingArea(item)"
                    button-text="Find out more"
                    :href="'/spotting-areas/' + item.id"
                    background-color="#FFF"
                    :is-agritech="true"
                />
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
    <Container v-if="spotlightActors.length > 0">
      <div class="agritech__communities" style="margin-top: 5rem; margin-bottom: 5rem">
        <h1>The opportunity hub members</h1>

        <div class="agritech__communities_list_view">
          <template v-if="spotlightActors.length > 0">
            <router-link v-for="actor in spotlightActors" :to="isLoggedIn ? '/actors/' + actor.id : ''"
                         style="text-decoration: none; width: 100px; height: auto">
              <div class="carousel-spotlight__actor-image" :style="getFeaturedImageStyle(actor)">
                <span v-if="!actorLogo(actor)">{{ actor.name }}</span>
              </div>
            </router-link>
          </template>
        </div>
      </div>
    </Container>
    <Container v-if="spotlightActors.length > 0">
      <div class="agritech__communities" style="margin-top: 5rem; margin-bottom: 5rem">
        <h1>The opportunity hub orchestrator</h1>

        <div class="agritech__communities_list_view">
          <template v-if="spotlightActors.length > 0">
            <a href="https://agritechnz.org.nz/" target="_blank">
              <img style="width: 100px;" src="/images/static-home-page/agritech/agritech-logo.svg"
                   alt="shoshinworks">
            </a>
          </template>
        </div>
      </div>
    </Container>
    <div style="background-color: #F2F2F2; width: 100%; padding-bottom: 50px;" v-if="isLoggedIn">
      <Container class="agritech__communities" :style="{'margin-top: 5rem': spotlightActors.length > 0}">
        <h1 style="padding: 50px">Opportunity hub map</h1>

        <div style="height: 500px; position: relative; padding-bottom: 50px;"
             :style="{ opacity: homePageIsFullyLoaded ? 1 : 0 }">
          <MapView
              ref="mapPane"
              :data="mapData.data"
              :getMapData="getMapData"
              :getData="getMapData"
              :noSearchWhileMoving="true"
              :disableMouseWheelZoom="true"
          />
        </div>
      </Container>
    </div>
    <SimplifiedFooter :with-margin="!isLoggedIn"/>
  </div>
</template>

<script lang="ts">
import Avatar from '../Avatar/Avatar.vue'
import { fetchPublicNotifications } from '../../api/notifications.js'
import DsButton from '../DsButton/DsButton.vue'
import SimplifiedCommunityCard from '../Simplified/SimplifiedCommunityCard.vue'
import { getDefaultFallbackImageUrl, getImageForCommunity, getImageForSpottingArea } from '../../util/helpers.js'
import SimplifiedFooter from '../SimplifiedFooter/SimplifiedFooter.vue'
import { fetchGalleryAndLatestActors, fetchLatestActors } from '../../api/homepage.js'
import Container from '../Container/Container.vue'
import { getFormatEventDate } from '../../util/date.js'
import moment from 'moment'
import { defineComponent } from "vue";
import SimpleVideoHero from "@/components/SimpleHero/SimpleVideoHero.vue";
import { MUTATION_TYPES } from "@/store/modules/challenges";
import { fetchSpottingAreas } from "@/api/spottingareas";
import SimplifiedImageCardWithCta from "@/components/SpottingAreas/SimplifiedImageCardWithCta.vue";
import { trackHeapEvent } from "@/util/analytics";
import Navigation from "@/App.vue";
import { actorLogo } from "@/util/actor";
import SimplifiedAnnouncementPreview from "@/components/Simplified/SimplifiedAnnouncementPreview.vue";
import MapView from "@/components/MapView/MapView.vue";
import _get from "lodash/get";
import { ACTION_TYPES as ACTORS_ACTION_TYPES } from "@/store/modules/actors";
import { MUTATION_TYPES as UI_MUTATION_TYPES } from "@/store/modules/ui";
import MODAL_IDS from "@/constants/modal-ids";
import SimplifiedChallengeCard from "@/components/Simplified/SimplifiedChallengePreview.vue";
import { fetchChallenges, fetchChallengesWithCount } from "@/Domain/Challenge/Api/challenges";

export default defineComponent({
  data: () => {
    return {
      events: [],
      latestActors: [],
      latestAnnouncements: [],
      latestChallenges: [],
      featuredChallenges: [],
      spottingAreas: [],
      spotlightActors: [],
      publicNotifications: {
        data: [],
        isLoading: false,
      },
      cardData: [
        {
          key: 'latest_actors',
          title: 'Explore Innovative Solutions',
          description: 'Embark on a journey of agricultural innovation as you discover a curated selection of forward-thinking companies offering transformative solutions.',
          subtitle: 'Latest solutions',
          partners: [],
          buttonHref: '/actors-simplified',
          buttonText: 'Share your solution',
        },
        {
          key: 'latest_challenges',
          title: 'Find Strategic Partnerships',
          description: 'Share your company\'s unique challenges, cultivate partnerships, and fortify your future on our collaborative platform for agricultural innovation.',
          subtitle: 'Latest challenges',
          partners: [],
          buttonHref: '/challenges',
          buttonText: 'Post your challenge',
        },
      ],

    }
  },
  computed: {
    mapData() {
      return this.$store.state.actors.mapData
    },
    homePageIsFullyLoaded() {
      if (!_get(this, 'latestChallenges.length') && !_get(this, 'latestActors.length') && !_get(this, 'latestAnnouncements.length') && !_get(this, 'publicNotifications.data.length')) {
        return false
      }
      return true
    },
    showEcosystemMap() {
      if (!this.homePageIsFullyLoaded) {
        return false
      }
      return _get(this, 'mapData.data.companies.length') || _get(this, 'mapData.data.clusters.length')
    },
    registerButtonStyle() {
      return {
        backgroundColor: '#0099DA',
        color: 'white',
        fontFamily: '',
        fontWeight: 'bold',
        height: 'auto',
        width: '',
        textTransform: 'capitalize',
        borderRadius: '4px',
        alignSelf: 'center',
        textAlign: 'center',
        marginTop: '50px',
        fontSize: '16px',
      }
    },
    hasAccessToCommunities() {
      return this.$store.getters.hasAccessToCommunities
    },
    hasAccessToSpottingAreas() {
      return this.$store.getters.hasAccessToNewSpottingAreas
    },
    communities() {
      return this.$store.state.communities.listData.data
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    announcements() {
      return this.$store.state.notifications.featuredAnnouncements
    },
    isMember() {
      return this.$store.getters.isMember
    },
    accessibleChallengeStatusses() {
      return this.$store.getters.accessibleChallengeStatusses
    },
  },
  watch: {
    'mapData': {
      handler(newValue, oldValue) {
        if (newValue.length !== oldValue.length) {
          setTimeout(this.renderMap, 200)
        }
      },
    },
    'mapFilter': {
      handler() {
        this.getMapData()
      },
    },
  },
  methods: {
    isAccessibleForUser(challenge) {
      if (!challenge) {
        return false
      }

      if (this.isMember) {
        return true
      }
      return this.accessibleChallengeStatusses.includes(challenge.status)
    },
    showLoginModal() {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
    },
    goToPage(route) {
      if (!this.isLoggedIn) {
        return this.showLoginModal()
      }

      return this.$router.push(route)
    },
    getMapData() {
      let filters
      if (this.$store.state.config && this.$store.state.config.viewActorTypes && this.$store.state.config.viewActorTypes.length) {
        filters = Object.assign({}, this.$store.getters.mapFilterObject, { actor_type: this.$store.state.config.viewActorTypes })
      } else {
        filters = Object.assign({}, this.$store.getters.mapFilterObject)
      }

      if (filters.tl) {
        this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_MAP, filters)
      }
    },
    renderMap() {
      this.$refs.mapPane && this.$refs.mapPane[0] && this.$refs.mapPane[0].invalidateSize()
    },
    actorLogo,
    getFeaturedImageStyle(actor) {
      return `background-image: url(${actorLogo(actor)}); background-size: contain; width: 100px; min-height: 100px`
    },
    trackHeapEvent,
    getImageForSpottingArea,
    formatEventDate(date, format = 'ddd, Do MMM YYYY, hh:mmA') {
      date = moment(date).format() // Date() has issues cross browser if it's not a standard ISO format: https://stackoverflow.com/questions/39969570/deprecation-warning-in-moment-js-not-in-a-recognized-iso-format
      return moment(new Date(date).toLocaleString('en-US', { timeZone: 'Europe/Brussels' })).format(format)
    },
    getFormatEventDate,
    getImageForCommunity,
    getDefaultFallbackImageUrl,
    goToChallengeDetailPage(challenge) {
      if (!this.isLoggedIn) {
        return this.showLoginModal()
      }

      this.$router.push(`/challenges/${challenge.id}`)
    },
    goToActorDetailPage(actor) {
      if (!this.isLoggedIn) {
        return this.showLoginModal()
      }

      this.$router.push(`/actors/${actor.id}`)
    },
    goToAnnouncementDetailPage(announcement) {
      if (!this.isLoggedIn) {
        return this.showLoginModal()
      }

      this.$router.push(`/announcements/${announcement.slug || announcement.id}`)
    },
    goToViewMore(data) {
      this.$router.push(data.buttonHref)
    },
    goToEvents() {
      this.$router.push('/events-simplified')
    },
    fetchGalleryAndLatestActors() {
      fetchGalleryAndLatestActors({ limit: 5 }).then(response => {
        if (response.latestActors) {
          this.latestActors = response.latestActors.slice(0, 3)
        }

        if (response.gallery.actors) {
          this.spotlightActors = response.gallery.actors
        }
      }).catch(errors => {
        console.log(errors)
      })
    },
    fetchLatestActors() {
      const actorsParameters = { limit: 3 }

      fetchLatestActors(actorsParameters).then((actors) => {
        this.latestActors = actors
      })
    },
    fetchLatestAnnouncements() {
      fetchPublicNotifications(3, 0, { is_event: false })
          .then(items => {
            this.latestAnnouncements = items || []
          })
    },
    fetchLatestChallenges() {
      fetchChallengesWithCount({
        limit: 3,
        offset: 0,
        filters: [],
      })
          .then(response => {
            this.latestChallenges = response.challenges.slice(0, 3)

            if (this.isLoggedIn) {
              this.featuredChallenges = response.challenges.slice(0, 2)
            }
          })
          .catch(errors => {
            this.$store.commit(MUTATION_TYPES.FETCH_CHALLENGES_LIST_FAILURE, errors)
          })
    },
    fetchSpottingAreas() {
      fetchSpottingAreas({ limit: 3, role: 'visitor' })
          .then((spottingAreas) => {
            this.spottingAreas = spottingAreas
          })
    },
    fetchFeaturedChallenges() {
      fetchChallenges({
        limit: 2,
        offset: 0,
        filters: {}
      })
          .then((response) => {
            this.featuredChallenges = response
          })
    },
  },
  mounted() {
    this.fetchGalleryAndLatestActors()
    this.fetchLatestChallenges()
    this.fetchSpottingAreas()
    this.getMapData()
  },
  components: {
    SimplifiedChallengeCard,
    MapView,
    SimplifiedAnnouncementPreview,
    Navigation,
    SimplifiedImageCardWithCta,
    SimpleVideoHero,
    SimplifiedCommunityCard,
    Avatar,
    DsButton,
    SimplifiedFooter,
    Container,
  },
})
</script>


<style lang="scss" scoped>
@import "resources/assets/scss/variables";

.carousel-spotlight__actor-image {
  transition: all .2s ease-in-out;

  &:hover {
    scale: 1.1;
  }
}

.agritech__info {
  margin-bottom: 4em;
  font-family: Museo, serif;

  p {
    font-family: 'Zen Kaku Gothic New', sans-serif;
  }

  .agritech__cards {
    display: flex;
    justify-content: center;
    margin-top: -85px;
    gap: 2rem;

    .agritech__card {
      width: 400px;
      max-width: 340px;
      background-color: white;
      filter: drop-shadow(0 3px 6px rgba(#000, 0.16));
      height: 100%;
      border-radius: 8px;
      padding: 30px 20px 25px 20px;
      position: relative;
      color: $agritech-blue;

      .agritech__card_content {
        margin-top: 20px;

        .avatar {
          background-color: rgba(255, 255, 255, .20) !important;
        }
      }

      h2 {
        font-weight: 500;
        color: $agritech-blue;
      }

      .agritech__card_item {
        display: flex;
        margin-bottom: 25px;
        align-items: center;

        &:hover {
          cursor: pointer;
        }

        p {
          margin: 0 10px;
          color: $agritech-blue;
          font-size: 14px;
          text-transform: uppercase;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .avatar {
          width: 35px;
          height: 35px;
          background-color: rgba(33, 36, 74, .20) !important;

          :deep(.avatar__img) {
            width: 35px;
            height: 35px;
          }
        }
      }

      p {
        margin: 20px 0 20px 0;
        color: #788088;
      }

      .static-home-agritech__card_button {
        background: black;
        padding: 10px 20px;
        border-radius: 5px;
        color: white;
        text-decoration: none;

        &:hover {
          background-color: $agritech-blue;
        }
      }
    }
  }
}

.agritech__upcoming-event {
  background-color: #DAE1DA;

  .agritech_events {
    padding: 3em 1em;

    .agritech_last_event {
      display: flex;

      .agritech_card_info {
        h1:first-child {
          text-transform: uppercase;
          color: #707070;
          font-weight: 400;
          font-size: 24px;
        }

        h1:nth-child(2) {
          margin-top: 10px;
        }

        .agritech__date {
          display: flex;
          align-items: center;
          margin-top: 10px;

          p {
            margin-left: 10px;
            font-size: 14px;
          }
        }

        .agritech_description {
          color: #707070;
          margin-top: 20px;

          p {
            white-space: pre-line;
            width: 97%;
            font-size: 14px;
          }
        }
      }

      .agritech_image {
        margin-top: 20px;

        img {
          border: 8px solid white;
        }
      }
    }
  }
}

.agritech__communities {
  h1 {
    color: $agritech-blue;
    text-align: center;
  }

  p {
    text-align: center;
    margin-top: 20px;
    color: #707070;
  }

  .agritech__communities_list {
    width: 340px;
  }

  .agritech__communities_list_view {
    display: flex;
    justify-content: center;
    margin-top: 3rem;
    gap: 2rem;
  }
}

.agritech {
  @media(max-width: $screen-xl) {
    .agritech__cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      gap: 2rem;
    }

    .agritech__communities_list_view {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      align-items: center;
      gap: 2rem;
    }
  }

  @media(max-width: $screen-lg) {
    .agritech__cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap: 2rem;
      margin-top: -100px;
      align-items: center;

      .agritech__card {
        width: 100% !important;
        margin: 20px;
        max-width: 100% !important;
      }
    }

    .agritech_events {
      display: flex;
      flex-direction: column;
      padding: 3em 2em;

      .agritech_last_event {
        display: flex;
        flex-direction: column;

        .agritech_card_info .agritech_description p {
          width: 100%;
        }
      }

      .agritech_image {
        margin-top: 20px;
      }

      .static-home-agritech_information {
        p {
          width: 100%;

        }
      }
    }

    .agritech__communities_list {
      width: 300px;
    }

    .agritech__communities_list_view {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 2rem;
    }

  }
}

@media(max-width: $screen-md-max) {
  .agritech__cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 2rem;
    align-items: normal !important;

    .agritech__card {
      width: 100% !important;
      margin: 20px;
    }
  }

  .agritech_events {
    display: flex;
    flex-direction: column;
    padding: 3em 2em;

    .agritech_last_event {
      display: flex;
      flex-direction: column;

      .agritech_card_info .agritech_description p {
        width: 100%;
      }
    }

    .agritech_image {
      margin-top: 20px;
    }

    .static-home-agritech_information {
      p {
        width: 100%;

      }
    }
  }

  .agritech__communities_list {
    width: 200px;
  }

  .agritech__communities_list_view {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
  }
}

</style>
