<template>
  <div :class="{ 'modal-no-footer': !selectedPackage.id }">
    <modal
      :id="modalId"
      ref="modal"
      wrapper-size="flex"
      size="flex"
      :title="title"
      :show-divider="false"
      :enable-mask="false"
      :is-closeable="!isForced"
      variant="rounded"
    >
      <template v-slot:title>
        <div>
          <GuideProgress style="min-width: 500px" v-model="CURRENT_STEP" :completed-until="CURRENT_STEP" :steps="STEPS"/>
        </div>
      </template>
      <template v-slot:body>
        <div>
          <package-picker v-show="!selectedPackage.id"
                          @packageSelected="updateChosenPackage"/>
          <h4 class="h4" v-if="selectedPackage.id && currentStep !== 'billing_information'">Add coupon code or credit card information for account verification. Payment will be effective once you complete the registration.</h4>
          <br v-if="selectedPackage.id && currentStep !== 'billing_information'">
          <div v-show="selectedPackage.id && currentStep !== 'billing_information'" style="display: flex; justify-content: center">
            <div :class="{ 'promocode-validation__container': !hasPaymentInfoOnCreation }">
              <h3 class="h3">Add a coupon code</h3>
              <ask-coupon-code v-model="promoCode"
                               @setCouponValidation="setCouponValidation"
                               :coupon-validation="couponValidation"
                               :payment-error="paymentError"
                               :updating-plan="updatingPlan"
                               :selected-package="selectedPackage"
              />
            </div>
            <div :class="{ 'payment-info__container' : !hasPaymentInfoOnCreation }">
              <template v-if="!paymentMethod.id">
                <div class="personal-payment-info__cc-information"
                     v-show="showCreditCardEntry">
                  <h3 class="h3">Provide your credit card information</h3>

                  <br>
                  <form-group label="Name on the card" :error="nameOnCardError">
                    <ds-input id="card-holder-name" v-model="nameOnCard"/>
                  </form-group>

                  <!-- Stripe Elements Placeholder -->
                  <form-group label="Credit card details" style="margin-bottom: 0px;" :instructions="instructions">
                    <div id="card-element"></div>
                  </form-group>

                  <br>
                  <div style="color: #FD916D" v-if="paymentMethodError && paymentMethodError.paymentMethodId">
                    Please enter the credit card number, expiry date and CVC code of your credit card
                  </div>
                  <ds-button label="Add payment method" size="small" id="card-button"
                             :data-secret="stripeIntent" @click="storeNewPaymentMethod()"
                             :disabled="updatingPaymentMethod"/>

                </div>
                <div v-show="!showCreditCardEntry"
                     class="personal-payment-info__cc-information">
                  <h3 class="h3">Credit card not required because the
                    {{ useTrial ? 'trial' : 'package' }} is free</h3>
                </div>
              </template>
              <template v-else-if="!hasPaymentInfoOnCreation">
                <span>Your credit card information is verified.</span>
              </template>
            </div>
          </div>
          <user-billing-information v-if="currentStep === 'billing_information'"
                                    :is-in-modal="true"
                                    :updating-billing-info="updatingBillingInfo"
                                    @updateBillingInfo="updateUserBillingInfo"/>
        </div>
      </template>
      <template v-slot:footer v-if="selectedPackage.id && currentStep !== 'billing_information'">
        <div style="text-align: center;">
          <div class="person_subscription__plan-cta" v-if="paymentError && currentStep !== 'billing_information'">
            <div v-if="paymentError && paymentError.route">
            <span>For security reasons, we need you to complete a secondary verification step, <a
              :href="paymentError.route">click here</a> to finalise your payment.</span>
            </div>
            <div class="form-group__error" v-else-if="paymentError && paymentError.message">
              <span>Something went wrong while processing your payment, contact us so we can help you further.</span>
            </div>
            <div class="form-group__error" v-else-if="Object.keys(paymentError).length"
                 style="font-size: 14px">
              Something went wrong while updating your subscription:
              <div v-if="!Array.isArray(paymentError)">
                {{ paymentError }}
              </div>
              <div v-for="error in paymentError" v-else>
                {{ Array.isArray(error) ? error[0] : error }}
              </div>
            </div>
            <br>
          </div>

          <ds-button
            variant="primary"
            :label="confirmLabel"
            v-if="paymentMethod.id || !showCreditCardEntry"
            @click="updateSubscription()"
            :disabled="currentCouponCodeIsInvalid || updatingPlan || (!promoCode && !paymentMethod.id)"/>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
  import Modal from './Modal.vue'
  import {
    addPaymentMethod, getStripeIntent, updateBillingInfo,
    updateSubscription, validateCouponCode,
  } from '../../api/user.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'

  import PackagePicker from './PackagesSignUpModal/PackagePicker.vue'
  import AskCouponCode from './PackagesSignUpModal/AskCouponCode.vue'
  import GuideProgress from '../ConceptSearchGuide/GuideProgress.vue'
  import UserBillingInformation from '../Profile/Subscription/UserBillingInformation.vue'

  export default {
    name: 'confirm-package-modal',
    data () {
      return {
        promoCode: '',
        modalId: 'CONFIRM_PACKAGE',
        couponValidation: '',
        updatingPlan: false,
        paymentMethod: {},
        defaultSteps: [
          'choose_plan',
          'add_payment_method',
          'billing_information',
        ],
        nameOnCard: '',
        paymentMethodError: '',
        card: '', // Stripe element, card info NEVER touches our code
        stripe: '', // Stripe elements requirements
        elements: '', // Stripe elements
        updatingPaymentMethod: false,
        stripeIntent: '',
        paymentError: '',
        hasPaymentInfoOnCreation: false,
        nameOnCardError: '',
        currentStep: 'choose_plan',
        updatingBillingInfo: false,
      }
    },
    computed: {
      billingInfo () {
        return this.$store.state.user.billingInfo
      },
      confirmLabel () {
        if (!this.billingInfoIsValid) {
          return 'Continue'
        }
        return this.updatingPlan ? this.$t('confirm_package_updating') : this.$t('confirm_package_update_plan')
      },
      instructions () {
        return 'Powered by <a href="https://stripe.com/privacy-center/legal" target="_blank">Stripe</a>'
      },
      billingInfoIsValid () {
        return this.billingInfo && this.billingInfo.billing_country && this.billingInfo.billing_address && this.billingInfo.billing_city && this.billingInfo.vat_id
      },
      steps () {
        if (this.couponValidation && this.couponValidation.valid && this.couponValidation.discount === 100) {
          if (this.billingInfoIsValid) {
            return ['choose_plan', 'add_payment_method']
          }

          return this.defaultSteps
        } else if (!this.selectedPackage.id) {
          return this.defaultSteps
        } else if (this.useTrial || !this.selectedPackage.monthly_price) {
          return ['choose_plan', 'billing_information']
        } else if (this.selectedPackage.id) {
          if (this.billingInfoIsValid) {
            return ['choose_plan', 'add_payment_method']
          } else {
            return this.defaultSteps
          }
        }
        return this.defaultSteps
      },
      CURRENT_STEP: {
        get () {
          return this.steps.indexOf(this.currentStep) + 1
        },
        set (value) {
          if (value === 1 && this.steps[0] === 'choose_plan') {
            this.updateChosenPackage({ selectedPackage: {}, isTrial: false })
          }
          this.currentStep = this.steps[value - 1]
        }
      },
      STEPS () {
        return this.steps.map((step, index) => {
          return {
            label: this.getTitleFromStep(step), value: index + 1,
          }
        })
      },
      currentCouponCodeIsInvalid () {
        return this.couponValidation && this.couponValidation.valid === false
      },
      showCreditCardEntry () {
        return !this.couponValidation || !this.couponValidation.valid || this.couponValidation.discount !== 100
      },
      isForced () {
        return this.$store.state.ui.modalContext.isForced || false
      },
      paymentMethods () {
        return this.$store.state.ui.modalContext.paymentMethods || []
      },
      selectedPackage () {
        return this.$store.state.ui.modalContext.selectedPackage || {}
      },
      useTrial () {
        return (this.$store.state.ui.modalContext.useTrial && this.$store.state.ui.modalContext.selectedPackage.trial_period) || false
      },
      title () {
        if (this.selectedPackage.id) {
          if (this.useTrial) {
            return 'Free trial for ' + this.selectedPackage.title
          } else if (!this.couponValidation || this.currentCouponCodeIsInvalid) {
            return `${this.selectedPackage.title} for €${this.selectedPackage.monthly_price * 12} per year`
          } else {
            return `${this.selectedPackage.title} for €${this.selectedPackage.monthly_price * 12 / 100 * (100 - this.couponValidation.discount)} per year`
          }
        }
        return 'Choose your plan'
      },
      registrationRequiresPayment () {
        return !this.couponValidation || !this.couponValidation.valid || this.couponValidation.discount !== 100
      },
    },
    methods: {
      updateUserBillingInfo (billingInfo) {
        this.updatingBillingInfo = true

        this.updateSubscription()
        updateBillingInfo(billingInfo)
          .then(response => {
            this.$bus.emit('billingInfoUpdated')
            this.updatingBillingInfo = false
            this.confirmUpdateSubscription()
          })
          .catch(e => {
            console.log(e)
            this.updatingBillingInfo = false
          })
      },
      getTitleFromStep (step) {
        switch (step) {
          case 'choose_plan':
            return 'Choose your plan'
          case 'add_payment_method':
            return 'Add payment method'
          case 'billing_information':
            return 'Add billing information'
          default:
            return 'Choose your plan'
        }
      },
      setCouponValidation (value) {
        this.couponValidation = value
      },
      updateChosenPackage (packageConfig) {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, { selectedPackage: packageConfig.selectedPackage, useTrial: packageConfig.useTrial, paymentMethods: this.paymentMethods, isForced: this.isForced })
        if (packageConfig.selectedPackage.id && (packageConfig.selectedPackage.prices[0].price === 0 || packageConfig.useTrial)) {
          if (this.billingInfoIsValid) {
            this.updateSubscription()
          } else {
            this.currentStep = 'billing_information'
          }
        } else {
          this.currentStep = 'add_payment_method'
        }
      },
      async validate () {
        this.paymentMethodError = ''
        const { paymentMethod, error } = await this.stripe.createPaymentMethod(
          'card',
          this.card,
          {
            billing_details: { name: this.nameOnCard },
          }
        )

        if (error) {
          // Display "error.message" to the user...
          this.paymentMethodError = error
        } else {
          // The card has been verified successfully...
          this.paymentMethod = paymentMethod
        }
      },
      async storeNewPaymentMethod () {
        // Clear the payment method error
        if (!this.nameOnCard) {
          this.nameOnCardError = 'The Name field is required'
          return
        } else {
          this.nameOnCardError = ''
        }

        this.paymentMethodError = ''

        this.updatingPaymentMethod = true

        try {
          // Make sure it's a valid card
          await this.validate()
        } catch (e) {
          console.log(e)

          this.paymentMethodError = e
          return
        }

        // All is well, update the payment method
        this.updatingPaymentMethod = true

        addPaymentMethod({
          paymentMethodId: this.paymentMethod.id,
        })
          .then(response => {
            // Update payment methods
            this.fetchUserPaymentInfo()
            this.card.clear()
            this.updatingPaymentMethod = false
            this.displayAddNewPaymentMethodFlag = false
          })
          .catch(error => {
            this.updatingPaymentMethod = false

            this.paymentMethodError = error.message || error
          })
      },
      configureStripe () {
        this.stripe = window.Stripe(this.$store.getters.publicStripeKey)

        this.elements = this.stripe.elements()
        this.card = this.elements.create('card')

        // prevent attempting to mount after visiting a different page
        if (document.getElementById('card-element')) {
          this.card.mount('#card-element')
        }
      },
      validateCouponCode () {
        validateCouponCode(this.promoCode, this.selectedPackage.id).then((result) => {
          this.couponValidation = result
        })
      },
      updateSubscription () {
        if (this.selectedPackage.prices[0].price && this.selectedPackage.prices[0].price > 0 && !this.paymentMethod.id && !this.promoCode) {
          return
        }
        if (!this.billingInfoIsValid) {
          this.currentStep = 'billing_information'
          return
        }

        this.updatingPlan = true
        this.confirmUpdateSubscription()
      },
      confirmUpdateSubscription () {
        updateSubscription({
          packageId: this.selectedPackage.id,
          useTrial: this.useTrial,
          promoCode: this.promoCode,
        })
          .then(response => {
            this.updatingPlan = false

            this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, this.modalId)
            // When closing the modal we reset to modal context.
            this.$store.commit('UI/SET_MODAL_CONTEXT', null)
            this.$bus.emit('choseNewPlan')
            this.$router.push('/profile/subscription#upgrade')
            window.location.reload()
          })
          .catch(error => {
            this.updatingPlan = false
            let paymentError
            if (error.route) {
              paymentError = error
            } else if (error.message) {
              paymentError = error.message
            } else {
              paymentError = error
            }

            this.paymentError = paymentError || error
          })
      }
    },
    components: {
      AskCouponCode,
      PackagePicker,
      Modal,
      GuideProgress,
      UserBillingInformation,
    },
    async mounted () {
      if (this.paymentMethods && this.paymentMethods.length > 0) {
        this.paymentMethod = this.paymentMethods.find(method => method.is_default)
        this.hasPaymentInfoOnCreation = true
      }

      if (this.selectedPackage.id) {
        if (this.useTrial || !this.selectedPackage.monthly_price) {
          this.currentStep = 'billing_information'
        } else {
          this.currentStep = 'add_payment_method'
        }
      }

      try {
        this.stripeIntent = await getStripeIntent()
      } catch (e) {
        console.log('Failed to fetch a stripe intent: ' + e)
      }

      this.configureStripe()
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../scss/_variables.scss";
  .promocode-validation__container {
    border-right: 1px solid $color-borders;
    padding-right: 15px;
    margin-right: 15px;
    width: 50%;
  }

  .payment-info__container {
    width: calc(50% - 15px);
  }

  .payment-unnecessary {
    background: $color-background-lightest-grey;
  }

  :deep(.modal__mask) {
    padding-top: 0
  }

  :deep(.modal__header .divider) {
    max-width: 100%;
  }

  :deep(.modal__wrapper) {
    max-height: 90%;
  }

  .modal-no-footer {
    :deep(.modal__body) {
      margin-bottom: 0;
    }
  }
</style>
