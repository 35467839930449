<template>
  <div class="home__section__article-section article-section__news_feed">
    <div class="article-preview__media-container">
      <div class="card__general article-section__items">
        <div class="article-item__container">
          <div class="article-item__content-container">
            <div class="article-item__data-container article-item__data-container--full">
              <div class="article-item__title-container">
                <a @click="handleClick(item)" target="_blank" class="article-item__article-section-title">{{ humanize(item.title) }}</a>
                <div class="article-item__title-container__right">
                  <template v-if="isMember">
                    <ds-button size="extra-small" variant="outline" icon="edit" @click="showEditResourcePanel(item)"/>
                    <ds-button size="extra-small" variant="outline" icon="trash" @click="showDeleteResourceConfirmationModal(item)"/>
                  </template>
                  <span class="article-item__date">{{ toDateString(item.date * 1000) }}</span>
                </div>
              </div>
              <template v-if="item.actor_id && item.actor_id.length > 0">
                <template v-for="mention in item.actor_id">
                  <div class="article-item__article-section-poster"  @click="openActorSidePanel({actor_id: mention.id})">{{ mention.name }}</div>
                </template>
              </template>
              <template v-if="item.tags">
                <div class="article-item__article-section-tags">
                  <rich-tag v-for="(tag, index) in item.tags" :key="index" size="small" :tag="tag" @click="addTagToFilters({value:tag, origin: 'tags'})"/>
                </div>
              </template>
              <div class="article-item__description" v-if="item.description">{{ item.description }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  /**
   * @deprecated component
   */
  import RichTag from '../../Tag/RichTag.vue';
  import { humanize } from '../../../constants/properties';
  import { toDateString } from '../../../util/date';
  import { getResourceFileLink } from '../../../util/get-resource-file-link'

  export default {
    components: { RichTag },
    props: {
      item: Object,
      maxScore: Number,
    },
    computed: {
      isMember () {
        return this.$store.getters.isMember;
      },
    },
    methods: {
      humanize,
      toDateString,
      handleClick (resource) {
        switch (resource.media_type) {
          case 'announcement': {
            this.$router.push(`/announcements/${resource.sql_media_id}`)
            break
          }
          case 'public_resource':
          case 'private_resource': {
            window.open(getResourceFileLink(resource))
            break
          }
        }
      },
      showEditResourcePanel() {
        this.$emit('show-edit-resource-panel', this.item);
      },
      showDeleteResourceConfirmationModal() {
        this.$emit('show-delete-resource-confirmation-modal', this.item);
      },
      openActorSidePanel(actorInfo) {
        this.$emit('open-actor-side-panel', actorInfo);
      },
      addTagToFilters(tagInfo) {
        this.$emit('add-tag-to-filters', tagInfo);
      },
    }
  }
</script>
