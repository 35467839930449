export default function querify (filterState) {
  return Object.keys(filterState)
    .filter(key => {
        // The Timeframe filters that are created when changing the founding and funding time frames cause a problem when trying to filter actors based on them
        // The actors weren't being filtered when changing the time frames
        // This prevents the bug from happening
      if (key === 'Timeframe') {
        return
      }
      return Array.isArray(filterState[key]) ? filterState[key].length : (filterState[key] !== null && filterState[key] !== undefined)
    })
    .map(key => {
      const value = Array.isArray(filterState[key]) ? filterState[key].join(',') : filterState[key]
      return key + '=' + encodeURIComponent(value)
    })
    .join('&')
}

export function convertObjectToQueryArray (objectToConvert, attributeName, valueName) {
  return objectToConvert.reduce((acc, filterObject) => {
    return { ...acc, [filterObject[attributeName]]: filterObject[valueName] }
  }, {})
}
