export function getResourceFileLink (resource) {
  // We check to see if the resource is of the link type
  // If so we fetch the type of link and its value
  var indexOfFileType = 0
  var fileType = resource.meta.map((item,index) => {
    if (item.key == "file_type") {
      indexOfFileType = index
      return item.value
    }
  })[indexOfFileType]

  // If its of the link type we fetch the link value
  if (fileType == 'link' || fileType == 'video_link') {
    var indexOfLink = 0
    var link = resource.meta.map((item, index) => {
      if (item.key == "url") {
        indexOfLink = index
        return item.value
      }
    })[indexOfLink]

    // @todo as owner/TM, link to the internal page via a new parameter

    // If there is a link associated to the resource we return the link
    if (link && link.length > 0) {
      return link
    }
  }

  // If a file is associated to an actor
  if (resource.actor_id && resource.actor_id.length > 0) {
    return `${window.location.origin}/api/actors/${resource.actor_id[0].id}/files/${resource.sql_media_id}`;
  }
  // If a file is associated to the ecosystem
  return `${window.location.origin}/api/files/${resource.sql_media_id}`
}

export function getMetaPropertyValues (propertyName, item) {
  if (!propertyName) {
    return ''
  }

  if (! item.meta) {
    return ''
  }

  var metaProperty = item.meta.filter(data => data.key == propertyName)

  if (metaProperty.length == 0) {
    return ''
  }

  return metaProperty[0].value
}
