<template>
  <div class="fixed-heading">
    <div class="heading">
      <h1 class="funnel__title">Funnel</h1>
      <badge :name="totalCount + ' Actors'" variant="primary" class="score-overview__actor-couter" />
    </div>
    <div class="scrollable has-padding" v-if="data && data.nodes && data.nodes.length > 0">
      <sankey :data="data" />
    </div>
    <div class="spider__loading noData" v-else>
      <template v-if="fetching">
        Fetching data...
      </template>
      <template v-else>
        For the selected actors, we found no stage information.
      </template>
    </div>
  </div>
</template>

<script lang="ts">
import Sankey from '../Chart/Sankey.vue'
import Badge from '../Badge/Badge.vue'

import { fetchChart } from '../../api/analytics.js'

import { toFullMonth } from '../../util/date.js'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      data: null,
      totalCount : 0,
      error: '',
      fetching: false,
    }
  },
  computed: {
    portfolio () {
      return this.$store.state.filters.portfolio
    }
  },
  methods: {
    fetch () {
      this.fetching = true
      var filters = {}

      if (this.portfolio) {
        filters = Object.assign(filters, {"portfolio": this.portfolio})
      }

      fetchChart('sankey', filters)
        .then(data => {
          if (data && data.sankeyData && data.sankeyData.nodes) {
            data.sankeyData.groups = data.sankeyData.groups.map(({ name }) => ({ name: toFullMonth(name) }))
            this.data = data.sankeyData
            this.totalCount = data.totalCount
          } else {
            this.data = null
            this.totalCount = 0
          }
        })

      this.fetching = false
    }
  },
  mounted () {
    this.fetch()
  },
  components: {
    Sankey,
    Badge
  },
  watch: {
    portfolio: {
      deep: true,
      handler () {
        this.fetch()
      }
    }
  }
})
</script>
<style lang="scss">
.funnel__title {
  display:inline-block;
}

.noData {
  top: calc(65% - 100px) !important;
  left: calc(40% - 100px) !important;
  padding: 60px;
  background-color: rgba(0,0,0,0.03);
  border-radius: 9px;
  color: grey;
}
</style>
