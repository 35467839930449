import Analytics from '../../util/analytics'
import { MUTATION_TYPES as FILTER_MUTATION_TYPES } from '../modules/filters'

const analytics = new Analytics()

export default store => {
  store.subscribe((mutation, state) => {
    switch (mutation.type) {
      case FILTER_MUTATION_TYPES.ADD_KEYWORD:
        const keywords = state.filters.keywords
        const keyword = keywords[keywords.length - 1]

        if (keyword) {
          analytics.trackEvent('filter', 'Add keyword', mutation.payload.facet, keyword.value)
        }
        break
      case FILTER_MUTATION_TYPES.SET_BY_FACET:
        const v = mutation.payload.values
        analytics.trackEvent('filter', 'Set facet', mutation.payload.facet, Array.isArray(v) ? v.join(', ') : v)
        break
      case FILTER_MUTATION_TYPES.TOGGLE_LEGEND_ITEM:
        analytics.trackEvent('filter', 'Toggle legend', mutation.payload.facet, mutation.payload.value)
        break;
      case FILTER_MUTATION_TYPES.SET_PORTFOLIO:
        analytics.trackEvent('filter', 'Set portfolio', 'portfolio', mutation.payload);
        break;
    }
  })
}
