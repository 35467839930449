<template>
  <label class="radio" :class="[{ 'radio--inline': inline }, `radio--${variant}`]">
    <template v-if="inlineLink && inlineLink.length > 4">
      <a :href="inlineLink" target="_blank">
        <input
        :type="tickType"
        :value="modelValue"
        :checked="checked"
        :disabled="disabled"
        @change="handleChange"
        >
        <span class="radio__label" :class="{'radio__label-disabled' : disabled}">{{ label || val }}</span>
      </a>
    </template>
    <template v-else>
      <input
      :type="tickType"
      :value="modelValue"
      :checked="checked"
      :disabled="disabled"
      @change="handleChange"
      :class="{'is-simplified': isSimplified}"
      >
      <span class="radio__label" :class="{'radio__label-disabled' : disabled}" v-if="displayLabel">{{ label || val }}</span>
    </template>
    <span v-if="meta">({{meta}})</span>
    <a v-if="href && typeof href !== 'object' && href.includes('://')" :href="href" target="_blank">
      <icon name="open-in-new" size="12" />
    </a>
    <slot></slot>
  </label>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      disabled: Boolean,
      href: String, // External link to visit and put after the value of the checkbox
      inline: Boolean,
      label: String,
      multiple: Boolean,
      meta: String,
      inlineLink: String,
      isSimplified: {
        type: Boolean,
        default: false,
      },
      type: String,
      // The value(s) that the value (val) can be compared against to evaluate if the
      // checkbox needs to be checked or not
      modelValue: {
        required: true
      },
      displayLabel: {
        type: Boolean,
        default: true
      },
      // The value of the checkbox
      val: null,
      variant: {
        type: String,
        validator: val => ['round', 'square'].includes(val),
        default: 'round',
      },
      reference:null
    },
    emits: ['update:modelValue'],
    computed: {
      checked () {
        if (this.multiple) {
          return (this.modelValue || []).includes(this.val)
        }

        return this.val ? this.modelValue == this.val : this.modelValue
      },
      tickType () {
        if (this.type == 'radio') {
          return 'radio'
        }

        return 'checkbox'
      }
    },
    methods: {
      handleChange (evt) {
        // Multiple value model
        if (this.multiple) {
          var value = Array.isArray(this.modelValue) ? this.modelValue : []
          const index = value.indexOf(this.val)

          if (index < 0) {
            value.push(this.val)
          } else {
            value = value.filter(v => v !== this.val)
          }

          value = value.filter(item => item)

          if (this.reference) {
            return this.$emit('update:modelValue', { value, reference: this.reference })
          }

          return this.$emit('update:modelValue', value)
        }

        // Normal checkbox
        var checkboxValue = !this.val ? !this.modelValue : (this.checked ? null : this.val)

        if (this.reference) {
          return this.$emit('update:modelValue', { reference: this.reference, value: checkboxValue })
        }

        this.$emit('update:modelValue', checkboxValue)
      }
    }
  })
</script>
