<template>
  <div class="change-batch clearfix">
    <div class="change-details">
      <span v-if="batch.approved=='accepted'"><b class="change-approved">&checkmark; Approved</b> change from</span>
      <span v-else><b class="change-rejected">&cross; Rejected</b> change proposed by</span>
      <span class="change-agent">{{ fromAgent(batch.agent) }}</span>
      <span class="change-time" :data-tooltip="batch.created_at" data-position="top center">{{ timeAgo(batch.created_at) }}</span>
      <span class="change-reason-mini" :data-tooltip="batch.reason" data-position="top center">
        <icon name="information-outline" size="14" />
        <span>Reason</span>
      </span>
      <span class="change-reason">Reason: {{batch.reason}}</span>
    </div>
    <table class="change-batch-table">
      <tbody>
        <tr class="change-row" v-for="(c, index) in batch.changes">
          <td v-if="!index" :rowspan="batch.changes.length" class="change-company">
            <a :href="'/actors/'+c.id">{{ c.actor_name }}</a>
          </td>
          <td class="change-property">{{ c.property }}</td>
          <td class="change-delta break">
            <div class="change-delta-html" v-html="diff(c)"></div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { timeAgo } from '../../util/date'
import { diffString } from '../../util/dmp.js'
import escapeHTML from '../../util/escapeHTML'
import { fromChange, fromAgent } from '../../util/helpers.js'
import { defineComponent } from 'vue'

function toString(t) {
  return t && typeof t === 'string' ? t : ''
}

export default defineComponent({
  props: ['batch'],
  methods: {
    fromAgent,
    timeAgo,
    diff (c) {
      c = fromChange(c)
      if (c.property === 'founding_date' && c.value.slice(0, 10) === c.previous_value.slice(0, 10)) {
        return
      }
      // Location should be longer than 32 chars ("Latitude: 0.000 Longitude: 0.000".length === 32)
      if (c.property === 'location' && c.value.length <= 32) {
        return
      }
      const prev = toString(c.previous_value)
      const next = toString(c.value)
      return prev.length < 500 && next.length < 500 ? diffString(prev, next) : escapeHTML(next)
    }
  }
})
</script>
