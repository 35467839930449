<template>
  <div class="fixed-heading">
    <div class="score-overview__heading">
      <div class="heading score-overview__title">
        <h1>{{title}}</h1>
        <open-tabs :tabs="chartTitles" :saveToUrl="false" :selectedTab="selectedChart" class="open-tabs__container"></open-tabs>
        <badge :name="badgeCounter + ' Actors'" variant="primary" class="score-overview__actor-couter" />
        <ds-button variant="minimal" size="small" icon="filter-box" class="score-overview__filter-button" @click="toggleFilter"/>
        <template v-if="title=='Digital Footprint'">
          <p class="score-overview__small-heading">The digital footprint score reflects for each actor DataScouts' prediction of business growth based upon it's digital presence, social media performance and find-ability on the web.</p>
          <p v-if="selectedChart == 'ranking'" class="score-overview__small-heading">Ranking of actors based upon their digital footprint score of the last full month.</p>
          <p v-else-if="selectedChart == 'landscape'" class="score-overview__small-heading">Competitive positioning of actors combining their digital footprint score of the last full month (x-axis) with the growth of the digital footprint score since the former month (y-axis).</p>
          <p v-else-if="selectedChart == 'evolution'" class="score-overview__small-heading">Rolling 12 month evolution of the top ranked actors based upon their digital footprint score.</p>
        </template>
        <template v-else>
          <p class="score-overview__small-heading">The business size score reflects for each actor DataScouts' prediction of business growth based upon financial performance indicators.</p>
          <p v-if="selectedChart == 'ranking'" class="score-overview__small-heading">Ranking of actors based upon their business size score of the last full month.</p>
          <p v-else-if="selectedChart == 'landscape'" class="score-overview__small-heading">Competitive positioning of actors combining their business size score of the last full month (x-axis) with the growth of the business size score since the former month (y-axis).</p>
          <p v-else-if="selectedChart == 'evolution'" class="score-overview__small-heading">Rolling 12 month evolution of the top ranked actors based upon their business size score.</p>
        </template>
      </div>
      <div v-if="showFilters" class="score-overview__filters-box">
        <div class="score-overview__filters">
          <div class="score-overview__filter">
            <span class="score-overview__filter-label">Score Quality</span>
            <radio-button name="score-quality" type="radio-button" val="A" :model-value="filters.completeness" @update-modelValue="completenessChanged" :inline="true" label="75%+"/>
            <radio-button name="score-quality" type="radio-button" val="B" :model-value="filters.completeness" @update-modelValue="completenessChanged" :inline="true" label="50%+"/>
            <radio-button name="score-quality" type="radio-button" val="C" :model-value="filters.completeness" @update-modelValue="completenessChanged" :inline="true" label="25%+"/>
          </div>
        </div>
        <div class="score-overview__filters">
          <div class="score-overview__filter above_bellow_filter">
            <span class="score-overview__filter-label">Scores between
              <ds-input placeholder="0" :model-value="filters.minScore" @update-modelValue="minRangeChanged" @blur="validateMinRange" class="score-overview__filter_above_bellow filter_above"/> -
              <ds-input placeholder="100" :model-value="filters.maxScore" @update-modelValue="maxRangeChanged" @blur="validateMaxRange" class="score-overview__filter_above_bellow filter_bellow"/>
            </span>
          </div>
        </div>
        <div class="score-overview__filters" v-if="mentionsEnabled()">
          <div class="score-overview__filter score-overview__filter-mention">
            <span class="score-overview__filter-label">Actors who have mentions last month</span>
            <checkbox name="score-quality" type="checkbox" :model-value="filters.hasMentions" @update-modelValue="mentionsChanged" :inline="true"/>
          </div>
        </div>
      </div>
      <div class="score-overview__header">
        <div class="score-overview__header__title"></div>
        <div class="score-overview__header__legend">
          <div class="score-overview__header__legend__item" v-if="showReferenceActorlabel && referenceActorName.name !== 'N/A'">
            <img src="/images/competitive-landscape-reference2.svg" alt="Danger">
            <label>{{ referenceActorName.name || 'Average position of all actors'}}</label>
          </div>
          <div class="score-overview__header__legend__item" v-if="showMentionslabel && selectedChart=='landscape'">
            <img src="/images/competitive-landscape-mentions.svg" alt="Danger">
            <label>Mentions</label>
          </div>
          <div class="score-overview__header__legend__item" v-if="selectedChart !== 'evolution'">
            <img src="/images/competitive-landscape-new2.svg" alt="Danger">
            <label>Significant change</label>
          </div>
        </div>
      </div>
    </div>
    <div class="has-padding scrollable">
      <slider-arrow @clickDirection="changeChart" arrowOrientation="left" positionElement="left" v-if="selectedChart !== 'ranking'" :currentChart="selectedChart"></slider-arrow>
      <div class="score-overview__chart-area" v-if="scoresAvailable">
        <template v-if="selectedChart == 'ranking'">
          <div v-show="rankChartLoading" class="spider__loading chart__loading__positioning">
            <icon name="loading" />
          </div>
          <rank v-if="!rankChartLoading && rankChartData.data && rankChartData.data.length > 0" :data="rankChartData" :type="type"/>
          <div v-else-if="!rankChartLoading && (rankChartData.data == undefined || rankChartData.data.length == 0)" class="explore__empty-state">
            No actors found!
          </div>
        </template>
        <template v-if="selectedChart == 'landscape'">
          <div v-show="landscapeLoading" class="spider__loading chart__loading__positioning">
            <icon name="loading" />
          </div>
          <landscape-chart v-if="!landscapeLoading && landscapeData.data && landscapeData.data.length > 0" :type="type" :data="landscapeData"/>
          <div v-else-if="!landscapeLoading && (landscapeData.data == undefined || landscapeData.data.length == 0)" class="explore__empty-state">
            No actors found!
          </div>
        </template>
        <template v-if="selectedChart == 'evolution'">
          <div v-show="rankEvolutionLoading" class="spider__loading chart__loading__positioning">
            <icon name="loading" />
          </div>
          <rank-evolution v-if="!rankEvolutionLoading && rankEvolutionData && rankEvolutionData.length > 0" :data="rankEvolutionData" :type="type"/>
          <div v-else-if="!rankEvolutionLoading && (rankEvolutionData.data == undefined || rankEvolutionData.data.length == 0)" class="explore__empty-state">
            No actors found!
          </div>
        </template>
      </div>
      <div v-else class="spider__loading noScores">
          Scores will be available starting next month.
      </div>
      <slider-arrow @clickDirection="changeChart" arrowOrientation="right" positionElement="right" v-if="selectedChart !== 'evolution'" :currentChart="selectedChart"></slider-arrow>
    </div>
  </div>
</template>

<script>
import RankEvolution from '../Chart/RankEvolution.vue'
import LandscapeChart from '../Chart/LandscapeChart.vue'
import Rank from '../Chart/Rank.vue'
import OpenTabs from '../Tabs/OpenTabs.vue'
import SliderArrow from '../Navigation/SliderArrow.vue'
import Badge from '../Badge/Badge.vue'

import Checkbox from '../Form/Checkbox.vue'
import RadioButton from '../Form/RadioButton.vue'

import { fetchChart } from '../../api/analytics.js'
import { fetchActor } from '../../api/actors.js'
import { toFullMonth } from '../../util/date'
import { inert } from '../../util/helpers'
import { actions } from '../../store/modules/scores.js'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
import slugify from '../../util/slugify'



export default {
  props: ['type'],
  data () {
    return {
      error: '',
      fetching: false,
      filters: {
        completeness: 'A',
        minScore:0,
        maxScore:100,
        hasMentions:false,
        portfolio: null
      },
      selectedChart: "landscape" ,
      chartTitles: ["ranking" , "landscape", "evolution"],
      showFilters:false,
      referenceActorName: String
    }
  },
  computed: {
    scoresAvailable (){
      return this.$store.state.config.scoresAvailable
    },
    rankChartLoading(){
      return this.$store.state.scores.ranking.loading
    },
    landscapeLoading(){
      return this.$store.state.scores.landscape.loading
    },
    rankEvolutionLoading(){
      return this.$store.state.scores.rankevolution.loading
    },
    rankChart () {
      return this.$store.state.scores.ranking
    },
    rankChartData () {
      if(this.rankChart.data.datasets && this.rankChart.data.datasets.data) {
        return this.rankChart.data.datasets
      }
      return {}
    },
    rankEvolution () {
      return this.$store.state.scores.rankevolution
    },
    rankEvolutionData () {
      if (this.rankEvolution.data.datasets) {
        return this.rankEvolution.data.datasets
      }
      return {}
    },
    landscape () {
      return this.$store.state.scores.landscape
    },
    landscapeData () {
      if(this.landscape.data.datasets && this.landscape.data.datasets.data) {
        return this.landscape.data.datasets
      }
      return {}
    },
    title () {
      if (this.type == 'digital_footprint') {
        return "Digital Footprint"
      } else if (this.type == 'business_size') {
        return "Business Size"
      }
    },
    getPortfolio () {
      this.filters.portfolio = this.$store.state.filters.portfolio
    },
    filters () {
      return this.$store.state.filters
    },
    showReferenceActorlabel () {
      var foundReference;
      if (this.selectedChart == "evolution") {
        if (this.rankEvolutionData !== undefined && this.rankEvolutionData.length > 0) {
           foundReference = this.rankEvolutionData.find(a => a.isReference)
          if (foundReference) {
            return foundReference.isReference
          }
        }
      } else if(this.selectedChart == "ranking"){
        if (this.rankChartData.data !== undefined && this.rankChartData.data.length > 0) {
          foundReference = this.rankChartData.data.find(a => a.isReference)
          if (foundReference) {
            return foundReference.isReference
          }
        }
      } else {
        return true
      }
    },
    showMentionslabel () {
      if (this.landscapeData.data !== undefined && this.landscapeData.data.length > 0) {
        const mentionCounter = this.landscapeData.data.find(a => a.mentions)
        if (mentionCounter) {
          return mentionCounter.mentions > 0
        }
      }
    },
    badgeCounter () {
      if (this.landscapeData.data && this.landscape.data.total_count) {
        return this.landscape.data.total_count
      }
      return 0
    }
  },
  methods: {
    mentionsEnabled () {
      return window.hasMentions
    },
    changeChart (d) {
      switch (d) {
        case "left":
          switch (this.selectedChart) {
            case "evolution":
              this.selectedChart = "landscape"
              break;
            case "landscape":
              this.selectedChart = "ranking"
              break;
          }
          break;
        case "right":
          switch(this.selectedChart){
            case "ranking":
              this.selectedChart = "landscape"
              break;
            case "landscape":
              this.selectedChart = "evolution"
              break;
          }
          break;
      }
    },
    fetch () {
      var filters = this.makeFilters()

      this.$store.dispatch('SCORES/FETCH_RANK', filters)
      this.$store.dispatch('SCORES/FETCH_RANK_EVOLUTION', filters)
      this.$store.dispatch('SCORES/FETCH_LANDSCAPE', filters)
    },
    validateMinRange () {
      if(this.filters.minScore < 0) {
        this.filters.minScore = 0
      }
    },
    minRangeChanged (val) {
      this.filters.minScore = val
    },
    validateMaxRange (){
      if(this.filters.maxScore > 100) {
        this.filters.maxScore = 100
      }
    },
    maxRangeChanged (val) {
      this.filters.maxScore = val
    },
    completenessChanged (val) {
      this.filters.completeness = val
    },
    mentionsChanged (val) {
      this.filters.hasMentions = val
    },
    makeFilters () {
      var filters = {
        score_type: this.type
      }
      if (this.filters.portfolio) {
         Object.assign(filters,  {'portfolio':this.filters.portfolio})
      }

      Object.assign(filters, this.filters)
      return filters
    },
    toggleFilter () {
      if (this.showFilters) {
        this.showFilters = false
      } else {
        this.showFilters = true
      }
    },
  },
  mounted () {
    this.$bus.on('tab', (tab) => {
      this.selectedChart = tab
    })
    this.fetch()
  },
  watch : {
    filters: {
      deep: true,
      handler () {
        this.fetch()
      }
    },
    getPortfolio (val) {
      this.filters.portfolio = val
    },
    landscapeData (val) {
      if(val.data) {
        this.referenceActorName = this.landscapeData.data.find(a => a.isReference) || this.average || { name: 'N/A' }
      }
    },
    selectedChart (val) {
      this.changeChart(this.selectedChart)
    }
  },
  beforeUnmount() {
    this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
  },
  components: {
    RankEvolution,
    Checkbox,
    LandscapeChart,
    Rank,
    SliderArrow,
    Badge,
    OpenTabs,
    RadioButton
  }
}
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";

.chart__loading__positioning {
  top: calc(60% - 100px) !important;
  left: calc(50% - 100px) !important;
}

.noScores {
  top: calc(65% - 100px) !important;
  left: calc(40% - 100px) !important;
  padding: 60px;
  background-color: rgba(0,0,0,0.03);
  border-radius: 9px;
  color: grey;
}

.score-overview__heading {
  padding-bottom: 0px !important;
}

.score-overview__small-heading {
  font-size: 12px;
  line-height: 1.5;
  opacity:0.7;
  font-family: $font-stack-primary;
  margin:0;
}

.score-overview__filters:first-child {
  margin-left:15px
}

.score-overview__filters {
  margin-top: 15px;
  margin-right: 5px;
  margin-bottom: 15px;
  display: inline-block;
}

.score-overview__filter {
  border: 1px solid $color-borders;
  color: #444;
  display: inline-block;
  padding-left: 5px;
  padding-right: 5px;
}

.score-overview__filter-label {
  margin-right: 10px;
  text-transform: uppercase;
  font-family: $font-stack-primary;
  color:$color-text-grey-light;
  font-style:italic;
}

.above_bellow_filter > span, .score-overview__filter_above_bellow, .above_bellow_filter > div {
  display:inline-block;
}

.score-overview__filter_above_bellow {
  margin: 4px 0;
}
.score-overview__filter_above_bellow, .score-overview__filter_above_bellow > input {
  height: 19px;
  width:50px !important;
  line-height: 1px;
}

.score-preview__divider {
  margin-top: 100px;
  margin: 50px;
}

.score-overview__header {
  align-items: center;
  padding: 20px 10px 0px;
  display: inline-block;
  float: right;
}

.score-overview__header__title {
  font-size: 20px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-family: $font-stack-secondary;
  flex: 1;
}

.score-overview__header__legend {
  margin-left: 40px;
  display: flex;
}

.score-overview__header__legend__item {
  margin-right: 20px;
  display: flex;

  img {
    margin-right: 4px;
  }

  &:last-child {
    margin-right: 0;
  }
}

.score-overview__chart-area {
  width:93%;
  margin:auto;
}
.score-overview__title {
  padding-bottom:10px;
  display: block;
}

.score-overview__title > h1 {
  display: inline-block;
}

.score-overview__title > .open-tabs__container > .tabs__navigation{
  margin: 0 -10px 0px !important;
}

.score-overview__filter-button {
  display:inline-block;
  float:right;
  padding:15px 0px 5px 15px;
}

.score-overview__filters-box {
  border-bottom: solid 1px $color-borders;
  border-top: solid 1px $color-borders;
}
.score-overview__filter-mention {
  height: 29px;
}

.score-overview__filter-mention > label > .radio__label{
  display:none;
}

.score-overview__actor-couter {
  display:inline-block;
  float:right;
  margin-left:20px;
}
.open-tabs__container {
    margin:auto;
    text-align: center;
    padding: 15px 0px 5px 15px;
    display: inline-block;
}
</style>
