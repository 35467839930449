<template>
  <div class="home-simplified static-home-foodleap">
    <div class="banner_image">
      <div class="static-home-foodleap_body">
        <h1>{{ $t('static_home_foodvalley_about_title') }}</h1>
      </div>
    </div>
    <Container>
      <div class="static-home-foodleap_about-body">
        <div class="col-12 static-home-foodleap_about-body-flex">
          <div class="static-home-foodleap_about_mission col-sm-12 col-md-8 col-lg-8">
            <h1>{{ $t('static_home_foodvalley_about_mission_title') }}</h1>
            <p>{{ $t('static_home_foodvalley_about_mission_description') }}</p>
            <div class="col-12 static-home-foodleap_about_team">
              <h1>{{ $t('static_home_foodvalley_about_team_title') }}</h1>
              <div class="static-home-foodleap_about_team_items">
                <div class="team_avatars" v-for="team in teamData">
                  <avatar :src="team.image" :alt="team.name" variant="border"></avatar>
                  <p>{{ team.name }}</p>
                  <p style="font-weight: 400;">{{ team.description }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="static-home-foodleap_about col-12 col-sm-12 col-md-4 col-lg-4">
            <h1>{{ $t('static_home_foodvalley_about_title_about') }}</h1>
            <p>{{ $t('static_home_foodvalley_about_description') }}</p>
            <ds-button
              variant="secondary"
              :label="$t('action_visit_site')"
              :size="null"
              :style="registerButtonStyle"
              href="https://www.foodvalley.nl/"
            />
          </div>
        </div>
      </div>
    </Container>
    <SimplifiedFooterFoodleap style="position: relative; margin-top: 5rem;" class="footer"/>
  </div>
</template>


<script>
  import Avatar from '../Avatar/Avatar.vue'
  import SimplifiedFooterFoodleap from '../SimplifiedFooter/SimplifiedFooterFoodleap.vue'
  import Container from '../Container/Container.vue'

  export default {
    components: { SimplifiedFooterFoodleap, Avatar, Container },
    data: () => {
      return {
        teamData: [
          {
            name: 'Guido Laman',
            description: 'Project Leader',
            image: '/images/static-home-page/team-images-static-home-page-foodvalley/guido_image.jpg',
          },
          {
            name: 'Esther de Nijs',
            description: 'Platform Support',
            image: '/images/static-home-page/team-images-static-home-page-foodvalley/esther_image.jpg',
          },
          {
            name: 'Emmanuel Anom',
            description: 'Innovation Facility Specialist',
            image: '/images/static-home-page/team-images-static-home-page-foodvalley/emmanuel_image.jpg',
          },
          {
            name: 'Leo Koning',
            description: 'Community Manager Protein Shift',
            image: '/images/static-home-page/team-images-static-home-page-foodvalley/leo_image.jpg',
          },
          {
            name: 'Caroline Duivenvoorden',
            description: 'Community Manager Upcycling',
            image: '/images/static-home-page/team-images-static-home-page-foodvalley/caroline_image.jpg',
          },
          {
            name: 'Judith van der Horst',
            description: 'Community Manager Personalised Nutrition',
            image: '/images/static-home-page/team-images-static-home-page-foodvalley/judith_image.jpg',
          },
        ],
      }
    },
    computed: {
      registerButtonStyle () {
        return {
          backgroundColor: '#21244A',
          color: 'white',
          fontFamily: '',
          fontWeight: '',
          height: 'auto',
          width: '125px',
          textTransform: 'capitalize',
          borderRadius: '4px',
          alignSelf: 'center',
          display: 'flex',
          justifyContent: 'center',
        }
      },
    },
  }
</script>


<style lang="scss" scoped>
  @import "resources/assets/scss/variables";

  .static-home-foodleap {
    font-family: Museo, serif;

    p {
      font-family: 'Zen Kaku Gothic New', sans-serif;
    }

    .banner_image {
      background-image: url("/images/static-home-page/banner_image_static_home_about.png");
      width: 100%;
      height: 285px;
      background-size: cover;
      background-position: center;
    }

    .static-home-foodleap_body {
      text-align: center;
      display: flex;
      flex-direction: column;
      height: 100%;

      h1 {
        color: white;
        text-align: center;
        width: 40%;
        margin: auto;
      }

      button {
        margin-top: 26px;
      }

      a {
        text-decoration: underline;
        color: white;
        margin-top: 8px;
      }
    }

    .static-home-foodleap_about-body {
      padding: 70px 0px;

      .static-home-foodleap_about-body-flex {
        display: flex;

        p {
          font-family: 'Zen Kaku Gothic New', sans-serif;

          white-space: pre-line;
          width: 90%;
          font-size: 16px;
          margin-top: 20px;
          color: #707070;
        }

        .static-home-foodleap_about_mission {
          h1 {
            color: #21244A;
          }
        }
      }

      .static-home-foodleap_about {
        background-color: #DAE1DA;
        height: 50%;
        padding: 30px;

        p {
          font-family: 'Zen Kaku Gothic New', sans-serif;
          white-space: pre-line;
          font-size: 16px;
          margin-top: 20px;
          width: 93%;
          color: #707070;
        }

        h1 {
          color: #21244A;
        }
      }

      .static-home-foodleap_about_team {
        margin-top: 7rem;

        h1 {
          color: #21244A;
          margin-top: 5rem;
        }

        .static-home-foodleap_about_team_items {
          display: flex;
          flex-wrap: wrap;
          gap: 5rem;
          margin-top: 20px;
          justify-content: center;
          row-gap: 2rem;

          .team_avatars {
            align-items: center;
            display: flex;
            flex-direction: column;
            width: 20%;

            p {
              width: 185px;
              margin-bottom: 0px;
            }
          }

          .avatar {
            width: 100px;
            height: 100px;


            :deep(.avatar__img) {
              width: 100px;
              height: 100px;
              object-fit: cover;
            }
          }

          p {
            text-align: center;
            color: #21244A;
            text-transform: capitalize;
            margin-top: 10px;
            font-weight: 700;
          }
        }

      }
    }

    @media(max-width: $screen-md-max) {
      .banner_image {
        object-fit: cover;
        height: 200px;

        .static-home-foodleap_body {
          h1 {
            font-size: 24px;
            width: 50%;
            align-items: center;
            display: flex;
            justify-content: center;
          }
        }
      }

      .static-home-foodleap_about-body {
        padding: 20px;

        .static-home-foodleap_about-body-flex {
          display: flex;
          flex-direction: column;

          .static-home-foodleap_about {
            max-width: 100%;
          }

          .static-home-foodleap_about_mission {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            flex-basis: 100%;
            max-width: 100%;

            p {
              width: 100%;
              margin-bottom: 40px;
              text-align: center;
            }
          }

        }
      }
      .static-home-foodleap_about_team {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-top: 0 !important;

        .static-home-foodleap_about_team_items {
          width: 100% !important;
          justify-content: center;

          .team_avatars {
            p {
              width: 185px !important;
            }
          }
        }
      }
    }

    @media(max-width: $screen-lg) {
      .static-home-foodleap_about-body-flex {
        display: flex;
        flex-direction: column;

        .static-home-foodleap_about_mission {
          max-width: 100% !important;
          flex-basis: 100% !important;

          p {
            width: 100%;
          }
        }

        .static-home-foodleap_about {
          margin-top: 4rem;
        }

        .static-home-foodleap_about_team {
          margin-top: 4rem;

          .static-home-foodleap_about_team_items {
            gap: 7rem;
            row-gap: 3rem;

            .team_avatars {
              p {
                width: 185px;
              }
            }
          }
        }
      }
    }

  }
</style>
