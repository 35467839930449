<template>
  <div class="home-simplified static-home-simplified">
    <div style="font-size: 0; background-color: #F3F3F3;">
      <img src="/images/static-home-page/PnG-cover.png" alt="" style="width: 100%;">
      <div class="curved-background-container">
        <Container class="main-shP-container">

          <div class="row">
            <div class="col-xs-12" style="margin-bottom: 60px">
              <h3 class="h3">
                INQBET SCOUTING & TREND MONITORING<span style="color: #FEDA00">.</span>
              </h3>
              <br>
              <p class="shP-text">
                Are you looking for startups or technology providers to develop a novel
                solution? Are you eager to understand which startups, enablers,
                corporates, projects or investors invest in carbon capture especially in
                the field of manufacturing and packaging? Would you like to learn more
                about new negative CO<span class="CO2-sub">2</span> footprint materials?
              </p>
              <p class="shP-text">
                The inQbet Scouting & Trend Monitoring platform is accessible for P&G
                employees that are involved or interested in Open Innovation, Emerging
                Technologies and understanding emerging trends. Please register & use the
                inQbet Scouting & Trend Monitoring platform to:
              </p>
              <p class="shP-text">
                - Receive updates on innovations and startups linked to P&G's
                sustainability strategy
                <br>
                - Read the flash reports on use case specific short listed startups and
                technology providers
                <br>
                - Conduct your own market research on you area of interest
              </p>
              <ds-button
                class="PnG-button"
                @click="openSignupModal"
                label="REGISTER NOW"
              />
            </div>
          </div>
        </Container>
      </div>
      <div style="clip-path: circle(180vw at 50% 180vw); }">
        <div style="height: 10vw; background: white"></div>
      </div>
    </div>
    <div>
      <Container class="main-shP-container">
        <div class="row shP-info-circles-container">
          <div class="col-xs-12 col-sm-6 shp-info-circles-image">
            <img src="/images/static-home-page/PnG-info-1.png" alt="">
          </div>
          <div class="col-xs-12 col-sm-6 shp-info-circles-text">
            <h4 class="h4">
              INQBET STARTUP TRACKING<span style="color: #FEDA00">.</span>
            </h4>
            <p>Explore the startups that are engaging with P&G and their partners on a multitude of innovation & sustainability challenges, product improvements as well as solutions to optimize the customer journey.</p>
            <div>
              <ds-button
                class="PnG-button"
                label="REGISTER NOW"
                @click="openSignupModal"
              />
            </div>
          </div>
          <div class="col-xs-12 col-sm-6 shp-info-circles-text" v-if="!isMobile">
            <h4 class="h4">
              INQBET STARTUP SCOUTING<span style="color: #FEDA00">.</span>
            </h4>
            <p>Are you looking for startups or technology providers to help you move forward on a very specific R&D challenge? Get a complete picture of the startup ecosystem and identify the most relevant technologies, the most promising application domains and the ultimately the most suitable technology provides to collaborate with.</p>
<!--            <div>-->
<!--              <ds-button-->
<!--                class="PnG-button"-->
<!--                @click="openSignupModal"-->
<!--                label="REGISTER NOW"-->
<!--              />-->
<!--            </div>-->
          </div>
          <div class="col-xs-12 col-sm-6 shp-info-circles-image">
            <img src="/images/static-home-page/PnG-info-2.png" alt="">
          </div>
          <div class="col-xs-12 col-sm-6 shp-info-circles-text" v-if="isMobile">
            <h4 class="h4">
              INQBET STARTUP SCOUTING<span style="color: #FEDA00">.</span>
            </h4>
            <p>Are you looking for startups or technology providers to help you move forward on a very specific R&D challenge? Get a complete picture of the startup ecosystem and identify the most relevant technologies, the most promising application domains and the ultimately the most suitable technology provides to collaborate with.</p>
<!--            <div>-->
<!--              <ds-button-->
<!--                class="PnG-button"-->
<!--                @click="openSignupModal"-->
<!--                label="REGISTER NOW"-->
<!--              />-->
<!--            </div>-->
          </div>
          <div class="col-xs-12 col-sm-6 shp-info-circles-image">
            <img src="/images/static-home-page/PnG-info-3.png" alt="">
          </div>
          <div class="col-xs-12 col-sm-6 shp-info-circles-text">
            <h4 class="h4">
              INQBET TREND MONITORING<span style="color: #FEDA00">.</span>
            </h4>
            <p>Would you like to stay informed about the trends and evolutions in specific innovation domains? Feel free to register to the InQbet trend monitoring and receive daily/weekly alerts on this subject!</p>
            <div>
              <ds-button
                class="PnG-button"
                @click="openSignupModal"
                label="REGISTER NOW"
              />
            </div>
          </div>
        </div>
      </Container>
    </div>

    <div style="clip-path: circle(180vw at 50% 180vw); }">
      <div style="height: 10vw; background: var(--primary)"></div>
    </div>
    <SimplifiedFooter class="footer">
    </SimplifiedFooter>
  </div>
</template>

<script>
  import Container from '../Container/Container.vue'
  import SimplifiedFooter from '../SimplifiedFooter/SimplifiedFooter.vue'
  import { lighten } from 'polished'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import { EcosystemMemberPackages } from '../../api/ecosystem-member-packages.js'
  import RichBodyContent from '../RichBodyContent/RichBodyContent.vue'
  import PackagePickerItem from '../Profile/Subscription/PackagePickerItem.vue'
  import { trackHeapEvent } from '../../util/analytics.js'
  import UiMixin from '../../util/UiMixin.js'

  export default {
    name: 'StaticHomePnG',
    components: {
      PackagePickerItem,
      RichBodyContent,
      Container,
      SimplifiedFooter,
    },
    data: () => {
      return {
        memberPackages: [],
      }
    },
    mounted () {
      EcosystemMemberPackages.get().then(response => {
        // hard coded marketing text for pre-release packages
        if (!this.ecosystemMemberPackagesEnabled && response[2]) {
          response[0].marketingTitle = 'Free Membership'
          response[1].marketingTitle = 'Welcome offer'
          response[1].marketingSubTitle = 'free 2 month trial'
          response[2].marketingTitle = 'Available in Q2 2021'
        }
        this.memberPackages = response
      })
    },
    beforeUnmount () {
    },
    computed: {
      isMobile () {
        return this.ui.isMobile
      },
      config () {
        return this.$store.state.config
      },
    },
    mixins: [UiMixin],
    methods: {
      openSignupModal (eventName) {
        trackHeapEvent('staticHomePage.' + eventName)
        if (this.$store.getters.hasAccessToEcosystemMemberPackages) {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.PACKAGES_SIGN_UP)
        } else if (this.config.allowAdd) {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ACTOR_SIGN_UP)
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.SIGN_UP)
        }
      },
    },
    watch: {},
  }
</script>

<style lang="scss" scoped>
  @import "resources/assets/scss/variables";

  .static-home-simplified {
    text-align: center;

    .main-shP-container {
      flex-grow: 1;
      max-width: 800px;
    }

    .curved-background-container {
      background-color: #F3F3F3;
    }

    h3.h3 {
      font-size: 30px;
      font-weight: 500;
      color: var(--primary);
      margin: 20px 0;
    }

    h4.h4 {
      font-size: 17px;
      font-weight: 600;
      color: var(--primary);
      margin-bottom: 20px;
    }

    .shP-text {
      color: var(--primary);
      line-height: 25px;
    }

    .PnG-button {
      font-family: $font-stack-primary;
      background-color: #FEDA00;
      font-weight: 600;
      color: white;
      line-height: 25px;
      padding: 3px 20px 0 20px;
    }

    .shP-info-circles-container {
      margin-bottom: 100px;
    }

    .shp-info-circles-image {
      img {
        width: 70%;
        margin: 0 auto;
      }

      display: flex;
      flex-direction: column;
      justify-content: center;
      margin: 20px 0;
    }

    .shp-info-circles-text {
      color: var(--primary);
      line-height: 25px;
      max-width: 300px;
      margin: 20px auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    @media (max-width: $screen-md) {
      .shp-info-circles-image {
        img {
          width: 50%;
        }
      }
      .shp-info-circles-text {
        max-width: 90%;
      }
    }

    .simplified-footer {
      margin-top: 0;
      background: var(--primary);
    }

    .CO2-sub {
      vertical-align: sub;
      font-size: 10px;
    }
  }
</style>
