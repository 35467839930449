<template>
  <modal ref="modal" :id="modalId" @close="$emit('close')">
    <template v-slot:body>
      <div>
        {{ $t('detach_profile_warning') }}
      </div>
      <div style="margin-top: 1rem;" v-if="hasDataToRemove">
        <div style="margin-top: 0.5rem; margin-bottom: 0.5rem;">{{ $t('detach_profile_explain_user_generated_data') }}</div>
        <ul style="list-style-type: circle; margin-left: 1rem;">
          <li v-if="amountOfAnnouncements > 0">{{ $t('remove_profile_explain_user_generated_data_announcements', { announcements: announcementsLabel }) }}</li>
          <li v-if="amountOfChallenges > 0">{{ $t('remove_profile_explain_user_generated_data_challenges', { challenges: challengesLabel }) }}</li>
          <li v-if="amountOfFiles > 0">{{ $t('remove_profile_explain_user_generated_data_uploaded_files') }}</li>
        </ul>
      </div>
    </template>
    <template v-slot:footer>
      <div>
        <ds-button :disabled="leaving" :label="$t('detach_user_confirm')" variant="primary" @click="transferDataAndLeaveEcosystem()" v-if="hasDataToRemove && candidateUserToTransferDataTo.length > 0"/>
        <ds-button :disabled="leaving" :label="hasDataToRemove ? $t('detach_user_and_linked_data') : $t('detach_user_confirm')" variant="primary" @click="removeDataAndLeaveEcosystem()"/>
        <ds-button :disabled="leaving" :label="$t('detach_user_cancel')" variant="secondary" @click="close()"/>
        <p class="form-msg error" v-if="error" v-html="error"></p>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import MODAL_IDS from '../../constants/modal-ids'
  import Dropdown from '../../components/Dropdown/Dropdown.vue'
  import RadioButton from '../Form/RadioButton.vue'

  import { getCandidateUsersToTransferDataTo, getTransferableDataInformation, leaveEcosystem } from '../../api/user'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'

  export default {
    data () {
      return {
        error: null,
        modalId: MODAL_IDS.USER_LEAVE_ECOSYSTEM,
        leaving: false,
        candidateUserToTransferDataTo: [],
        amountOfChallenges: 0,
        amountOfAnnouncements: 0,
        amountOfFiles: 0,
      }
    },
    computed: {
      hasDataToRemove () {
        return this.amountOfChallenges > 0 || this.amountOfAnnouncements > 0 || this.amountOfFiles > 0
      }
    },
    methods: {
      transferDataAndLeaveEcosystem () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.USER_LEAVE_ECOSYSTEM_AND_TRANSFER_DATA)
      },
      async removeDataAndLeaveEcosystem () {
        this.leaving = true

        try {
          await leaveEcosystem()

          window.location.href = '/'
        } catch (e) {
          console.log(e)
        }
      },
      close () {
        this.$refs.modal.close()
      }
    },
    mixins: [TranslationsMixin],
    async mounted () {
      const transferableData = await getTransferableDataInformation()

      this.amountOfChallenges = transferableData.challenge_count
      this.amountOfAnnouncements = transferableData.announcement_count
      this.amountOfFiles = transferableData.file_count

      this.candidateUserToTransferDataTo = await getCandidateUsersToTransferDataTo()
    },
    components: {
      Modal,
      Dropdown,
      RadioButton
    }
  }
</script>
