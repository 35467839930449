<template>
  <canvas width="400" height="400" ref="canvas"/>
</template>

<script>
  import { Chart } from 'chart.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      data: Object,
      options: Object,
      updateConfig: {
        type: Object,
        default: () => ({}),
      },
    },
    mounted() {
      this.chart = new Chart(this.$refs.canvas, {
        type: 'radar',
        data: this.data,
        options: this.options,
      })
    },
    beforeUnmount () {
      this.chart.destroy()
    },
    watch: {
      data(data) {
        this.chart.data = data
        this.chart.update(this.updateConfig)
      },
      options(options) {
        this.chart.options = options
        this.chart.update(this.updateConfig)
      },
    }
  })
</script>
