const DEFAULT_STATE = {
  conceptSearch: null,
  spottingArea: null,
  actor: null,
  mediaType: null,
  facetKeyword: null,
  knowledgeBaseFilters: null,
}

const state = { ...DEFAULT_STATE }

export const MUTATION_TYPES = {
  SET_CONCEPT_SEARCH: 'KNOWLEDGE_BASE/SET_CONCEPT_SEARCH',
  SET_SPOTTING_AREA: 'KNOWLEDGE_BASE/SET_SPOTTING_AREA',
  SET_ACTOR: 'KNOWLEDGE_BASE/SET_ACTOR',
  SET_MEDIA_TYPE: 'KNOWLEDGE_BASE/SET_MEDIA_TYPE',
  SET_FACET_KEYWORD: 'KNOWLEDGE_BASE/SET_FACET_KEYWORD',
  CLEAR: 'KNOWLEDGE_BASE/CLEAR',
  SET_KNOWLEDGE_BASE_FILTERS: 'KNOWLEDGE_BASE/SET_KNOWLEDGE_BASE_FILTERS',
}

export const mutations = {
  [MUTATION_TYPES.CLEAR] (state) {
    state = DEFAULT_STATE

    // ls('knowledgeBaseKeywords', {}) // We don't store the state in the ls anymore
  },
  [MUTATION_TYPES.SET_CONCEPT_SEARCH] (state, conceptSearch) {
    state.conceptSearch = conceptSearch
  },
  [MUTATION_TYPES.SET_SPOTTING_AREA] (state, spottingArea) {
    state.spottingArea = spottingArea
  },
  [MUTATION_TYPES.SET_MEDIA_TYPE] (state, mediaType) {
    state.mediaType = mediaType
  },
  [MUTATION_TYPES.SET_FACET_KEYWORD] (state, keyword) {
    state.facetKeyword = keyword
  },
  [MUTATION_TYPES.SET_ACTOR] (state, actor) {
    state.actor = actor
  },
  [MUTATION_TYPES.SET_KNOWLEDGE_BASE_FILTERS] (state, filters) {
    state.knowledgeBaseFilters = filters
  }
}

export default {
  state,
  mutations,
}
