<template>
  <div class="basic-simplified-hero" :style="basicSimplifiedHeroStyling">
    <BasicHero
      :style="basicHeroStyling"
      :title="title"
      :subtitle="subtitle"
    >
      <slot></slot>
    </BasicHero>

  </div>
</template>

<script>
  import BasicHero from '../BasicHero/BasicHero.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'basic-simplified-hero',
    props: {
      title: String,
      subtitle: String,
    },
    data () {
      return {
        minHeight: 300,
      }
    },

    components: {
      BasicHero
    },
    computed: {
      config () {
        return this.$store.state.config
      },
      image () {
        if (this.$store.getters.isStaging) {
          return '/images/static-home-page/static-home-page-foodleap.png'
        }

        return this.config.homepage_knowledge_base_image
      },
      isCommunityEcosystem () {
        return this.$store.getters.isCommunityEcosystem
      },
      basicSimplifiedHeroStyling () {
        if (this.isCommunityEcosystem) {
          return `background-image: url(${this.image}); min-height: ${this.minHeight}px; display: flex; justify-content: center; background-size: cover; background-repeat: no-repeat;`
        }

        return `background-color: var(--primary)`
      },
      basicHeroStyling () {
        if (this.isCommunityEcosystem) {
          return `background: transparent; color: #323441; display: flex; flex-direction: column; justify-content: center; align-items: center; padding-top: 0;`
        }
      }
    },
  })
</script>

<style lang="scss" scoped>
.basic-simplified-hero {
  width: 100%;
  margin-bottom: 50px;

  .basic-simplified-hero__title {
    letter-spacing: 2px;
    color: white;
    font-size: 28px;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: auto;
  }

  .basic-simplified-hero__subtitle {
    color: white;
    font-size: 20px;
    margin-left: auto;
    margin-right: auto;
  }
}


</style>
