import { strAfter, strUpto } from './string.ts'

export function taxonomyLabel (taxonomy, locale) {
  if (locale && taxonomy.translations && taxonomy.translations[locale]) {
    return taxonomy.translations[locale].name
  }

  return taxonomy.name
}

export function twoLevelTaxonomiesToOptions (twoLevelTaxonomies, childrenName) {
  const options = []
  for (let i = 0; i < twoLevelTaxonomies.length; i++) {
    const option = {
      text: twoLevelTaxonomies[i].name,
      id: twoLevelTaxonomies[i].id,
      children: [{
        id: twoLevelTaxonomies[i].id,
        text: twoLevelTaxonomies[i].name,
      }],
    }

    const subOptions = twoLevelTaxonomies[i][childrenName] || []
    for (let j = 0; j < subOptions.length; j++) {
      option.children.push({
        id: subOptions[j].id,
        text: subOptions[j].name,
      })
    }

    options.push(option)
  }

  return options
}

export function toTwoLevelTaxonomyId (str, twoLevelTaxonomies, childrenName) {
  const upto = strUpto(str, '>').trim()
  const twoLevelTaxonomy = twoLevelTaxonomies.find(d => d.name === upto)
  if (!twoLevelTaxonomy) {
    return
  }

  // Part after >
  const after = strAfter(str, '>').trim()
  if (!after) {
    return twoLevelTaxonomy.id
  }

  // Find subdomain
  const subItem = twoLevelTaxonomy[childrenName].find(d => d.name === after)
  return subItem && subItem.id
}

export function fromTwoLevelTaxonomyId (id, twoLevelTaxonomies, locale, childrenName) {
  for (let i = twoLevelTaxonomies.length - 1; i >= 0; i--) {
    if (Number(twoLevelTaxonomies[i].id) === Number(id)) {
      return taxonomyLabel(twoLevelTaxonomies[i], locale)
    }

    for (let j = twoLevelTaxonomies[i][childrenName].length - 1; j >= 0; j--) {
      if (Number(twoLevelTaxonomies[i][childrenName][j].id) === Number(id)) {
        return taxonomyLabel(twoLevelTaxonomies[i], locale) + ' > ' + taxonomyLabel(twoLevelTaxonomies[i][childrenName][j], locale)
      }
    }
  }
}

export function isTaxonomyAChild (taxonomyName, id) {

}
