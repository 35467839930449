<template>
  <div ref="main">
    <div class="charts__plot" ref="plot" style="width:100%;height:100%;max-height:100%;"></div>
  </div>
</template>

<script lang="ts">
import _cloneDeep from 'lodash/cloneDeep'
import { fetchChart } from '../../api/analytics.js'
import Checkbox from '../Form/Checkbox.vue'

import { chartTransformer } from './chartsMixin.js'
import { inert } from '../../util/helpers.js'
import { loadChunk } from '../../util/chunk-loader.js'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    chart: null,
    expanded: Boolean,
    fullSize: Boolean,
    shouldTransform: {
      type: Boolean,
      default: true,
    }
  },

  data () {
    return {
      splitChartData: null,
      width: 0,
      initialWidth: 0
    }
  },

  computed: {
    transformed () {
      const chart = _cloneDeep(this.chart)
      chart.layout = chart.layout || {}
      chart.layout.xaxis = chart.layout.xaxis || chart.xaxis || {}
      chart.layout.yaxis = chart.layout.yaxis || chart.yaxis || {}
      chart.layout.xaxis.fixedrange = true
      chart.layout.xaxis.categoryorder = 'total descending'
      chart.layout.yaxis.fixedrange = true

      if (!this.shouldTransform) {
        chart.data = chart.datasets
        return chart
      }

      chart.maintainAspectRatio = false

      // Axis
      if (!this.expanded && !this.fullSize) {
        chart.layout.height = Math.floor(0.5 * document.body.clientHeight - 180)
        chart.data = chart.datasets.map(data => {
          // Apply array reverse when the x-as is indicating years, so that the most recent year occurs at the most right side of the graph
          if (chart.type === 'DateHistogram') {
            data.x = data.x.reverse().slice(0, 12)
            data.y = data.y.reverse().slice(0, 12)
          }

          data.x = data.x.slice(0, 12)
          data.y = data.y.slice(0, 12)
          return data
        })
      } else {
        chart.layout.height = Math.floor(0.9 * document.body.clientHeight - 180)
        chart.data = chart.datasets
      }

      // Automargin
      let charsLeft = 10
      let charsBottom = 7

      chart.data.forEach((d, i) => {
        const xmax = Math.max.apply(Math, d.x.map(a => a && a.toString && a.toString().length || 0))
        const ymax = Math.max.apply(Math, d.y.map(a => a && a.toString && a.toString().length || 0))
        charsBottom = Math.max(charsBottom, xmax * (d.x.length > 15 ? 1.5 : 1))
        charsLeft = Math.max(charsLeft, ymax)
      })

      chart.layout.margin = { t: 10, l: charsLeft * 7, b: Math.min(charsBottom * 4, 120) }

      // Percentual
      if ((chart.type === 'Histogram' || chart.type === 'DateHistogram') && chart.percentual) {
        chart.layout.yaxis.tickformat = ',.0%'
        chart.data
            .filter(dataset => dataset.total)
            .forEach(dataset => {
              var total = dataset.total;

              // If the chart is a stacked bar chart, make sure we take the overall total, instead of the individual bar total
              if (dataset.queryTotal) {
                total = dataset.queryTotal;
              }

              dataset.y = dataset.y.map(y => y / total);
            })

        if (chart.horizontal) {
          charsBottom = 10
        } else {
          charsLeft = 10
        }

        // If the maximum value is less than 1%, change the tickformat of the y-axis
        if (Math.max.apply(Math, chart.data[0].y) <= 0.02) {
          chart.layout.yaxis.tickformat = ',.2%'
        }
      } else if (chart.average && chart.type === 'DateHistogram') {
        //chart.layout.yaxis.tickformat = ',.0'
        chart.data
          .filter(dataset => dataset.total)
          .forEach(dataset => {
            var total = dataset.total;

            // If the chart is a stacked bar chart, make sure we take the overall total, instead of the individual bar total
            if (dataset.queryTotal) {
              total = dataset.queryTotal;
            }

            dataset.y = dataset.y.map((y, index) => {
              var valueCount = dataset.number_of_actors[index]; // The amount of actors that made the Y value to what is it right now

              if (valueCount != 0) {
                return y / valueCount;
              }

              return y;
            });
          });

        if (chart.horizontal) {
          charsBottom = 10
        } else {
          charsLeft = 10
        }

        // If the maximum value is less than 1%, change the tickformat of the y-axis
        if (Math.max.apply(Math, chart.data[0].y) <= 0.02) {
          //chart.layout.yaxis.tickformat = ',.2'
        }
      }

      // Horizontal
      chart.layout.orientation = chart.horizontal ? 'h' : 'v'

      if (chart.horizontal) {
        chart.layout.margin = { t: 10, l: charsBottom * 7, b: Math.min(charsLeft * 4, 120) }

        // Switch data
        chart.data.forEach((d, i) => {
          const tmp = d.x
          d.x = d.y
          d.y = tmp
          if (d.orientation === 'h') {
            delete d.orientation
          } else {
            d.orientation = 'h'
          }
        })

        // Switch axis
        const tmp = chart.layout.xaxis
        chart.layout.xaxis = chart.layout.yaxis
        chart.layout.yaxis = tmp
      }

      // Fix issue where width size does not adapt upon shrinking the graph
      chart.layout.autosize = true
      if (!this.expanded && !this.fullSize) {
        chart.layout.width = this.initialWidth
      } else {
        delete chart.layout.width;
      }

      return chart
    }
  },

  methods: {
    render () {
      if (this.$el && this.$refs.plot && this.$refs.plot.offsetWidth > 0) {
        this.width = this.$refs.plot.offsetWidth
        this.initialWidth = this.$refs.plot.offsetWidth
      }
      loadChunk('Plotly', this.actualRender)
    },
    actualRender () {
      if (this.$el && this.$refs.plot && !this._isunmounted) {
        window.Plotly.newPlot(this.$refs.plot, inert(this.transformed.data), inert(this.transformed.layout), {
          displaylogo: false,
          displayModeBar: false,
          showLink: false,
          showTips: false,
          responsive: false,
        })

        this.$refs.plot.on('plotly_click', (data) => {
          if (this.chart.property && data.points[0]) {
            // Check if there's an xFilter that needs to be passed
            // instead of the value of the (original) x-axis
            var facet = this.chart.property
            var value = String(data.points[0].x)

            var barData = data.points[0].data

            // If a custom filter facet is passed, use the custom facet
            if (barData && barData.xFilterFacet) {
              facet = barData.xFilterFacet
            }

            // If a custom filter value is passed, use the custom filter value
            if (barData && barData.xFilters && barData.xFilters[data.points[0].x]) {
              value = String(barData.xFilters[data.points[0].x])
            }

            this.$emit('filter', {
              facet: facet,
              value: value,
              display: String(data.points[0].x)
            })
          }
        })
      }
    },
    switchAxis () {
      this.chart.horizontal = !this.chart.horizontal

      // updateHorizontal(this.$refs.plot, this.chart)
      // window.Plotly.redraw(this.$refs.plot)
      this.$emit('change', this.chart)
    },
    resize () {
      this.$nextTick(() => {
        if (window.Plotly && this.$el && this.$refs.plot) {
          this.render()
        }
      })
    },
    splitChart (by) {
      this.chart.splitBy = by
      fetchChart(this.chart.type, _pick(this.chart, ['type', 'property', 'splitBy']))
        .then(chart => {
          this.splitChartData = chartTransformer(chart)
          this.resize()
        })
    }
  },
  emits: ['change', 'filter'],
  watch: {
    chart: {
      deep: true,
      handler () {
        this.render()
      }
    },
    /*'chart.horizontal': {
      deep: true,
      handle () {
        this.render()
        this.$parent.$parent.save && this.$parent.$parent.save()
      }
    },*/
    'chart.horizontal' () {
        this.render()
        this.$parent.$parent.save && this.$parent.$parent.save()
    },
    'chart.percentual' () {
      this.render()
      this.$parent.$parent.save && this.$parent.$parent.save()
    },
    'chart.average' () {
      this.render()
      this.$parent.$parent.save && this.$parent.$parent.save()
    }
  },
  mounted () {
    this.render()
    this.$bus.on('resize300', this.resize)
  },
  beforeUnmount () {
    this.$bus.off('resize300', this.resize)
    if (window.Plotly && this.$el && this.$refs.plot) {
      window.Plotly.purge(this.$refs.plot)
    }
  },
  components: {
    Checkbox
  }
})
</script>
