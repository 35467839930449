<template>
  <div ref="job_logs">
  <div class="sources fixed-heading">
    <h3 class="h2">Data sources</h3>
    <div class="guide_text">
      Import data using Google Spreadsheets.
    </div>
    <div>
      <div>
        <p v-if="sourcesByOthers">
          You can only edit sources that you created.
        </p>
        <table class="table table--has-button">
          <thead>
            <tr>
              <!--<th>#</th>-->
              <th-sort by="name">Name</th-sort>
              <th>Created by</th>
              <th-sort by="last_imported" width="250" >Status</th-sort>
              <th colspan="2">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(source, index) in sources">
              <!--<td>{{ index + 1 }}</td>-->
              <td v-if="source.user_id === user_id"><a :href="source.web_link" target="_blank">{{ source.name }}</a></td>
              <td v-else>{{ source.name }}</td>
              <td>{{ source.user_name }}</td>
              <td>{{ source.status }}</td>
              <td>
                <ds-button icon="upload" @click="importNow(source)" label="Import" />
              </td>
              <td>
                <ds-button icon="trash" @click="remove(source)" />
              </td>
            </tr>
          </tbody>
        </table>
        <pagination class="simple-pagination" :paging="importFilesPaging" @change="setImportFilesOffset" />
      </div>
    </div>
    <div>
      <form class="panel panel-default" v-if="showAdd" @submit.prevent="createSpreadsheet" style="margin-top: 25px;">
        <h3 class="h2">Link a Google Spreadsheet</h3>
        <form-group label="Filename" errors="filename" :errors="errors.filename" >
          <ds-input v-model="form.filename" />
        </form-group>
        <form-group label="Email address" errors="email" :errors="errors.email" instructions="The spreadsheet will be created by the DataScouts platform and shared with this email address">
          <ds-input v-model="form.email" />
        </form-group>
        <ds-button variant="secondary" type="submit" :icon="adding ? 'spinner' : 'plus'" label="Create spreadsheet" :disabled="adding"/>
        <ds-button variant="outline" label="Cancel" @click="showAdd = false" :disabled="adding"/>
      </form>
      <div style="margin-top: 15px;" v-else>
        <ds-button icon="plus" label="Add a data source" @click="addSource" />
      </div>
    </div>

    <template v-if="sourcesContainLogs">
      <div style="margin-top: 15px;" id="logs">
        <h3 class="h2">Import logs</h3>
        <form class="form-horizontal">
          <form-group label="Select the name of the spreadsheet you want to see the logs for" class="spreadsheet__log-filter" style="padding-left: 0px;">
            <dropdown :search="true" :options="sourcesWithLogs" v-model="selectedSpreadsheet" />
          </form-group>

          <form-group label="Select the specific import you want to see the logs for, based on when the import started." class="spreadsheet__log-filter"  style="padding-left: 0px;" v-if="imports.length > 0">
            <dropdown :options="imports" v-model="selectedImport" allow-clear />
          </form-group>
        </form>
      </div>
    </template>

    <template v-if="displayImportLogs">
      <div class="spreadsheet-logs">
        <form class="form-horizontal">
          <form-group class="spreadsheet__log-filter" label="Search on name">
            <ds-input
            v-model="actorName"
            />
          </form-group>
        </form>
      </div>

      <table class="table table--fill table--hover" style="table-layout: fixed">
        <thead>
          <tr>
            <th>Name</th>
            <th>Line number</th>
            <th-filter by="action" :options="filterOptions.action" v-model="logAction">Action</th-filter>
            <th-filter by="success" :options="filterOptions.success" v-model="successLogs">Success {{ logsMeta.total ? '(' + logsMeta.success + '/' + logsMeta.total + ')' : ''}}</th-filter>
            <th>Context</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="log in logs">
            <td @click="showSidePanel(log.actor_id)">{{ log.name }}</td>
            <td @click="showSidePanel(log.actor_id)">{{ log.line_number }}</td>
            <td @click="showSidePanel(log.actor_id)">{{ log.action }}</td>
            <td @click="showSidePanel(log.actor_id)">{{ log.success ? 'Yes' : 'No' }}</td>
            <template v-if="getDuplicateIdFromLog(log)">
              <td  @click.prevent="showSidePanel(getDuplicateIdFromLog(log))">
                {{ fetchLogContext(log.context) }} ({{ getDuplicateNameFromLog(log) }})
              </td>
            </template>
            <template v-else>
              <td >
                {{ fetchLogContext(log.context) }}
              </td>
            </template>
          </tr>
        </tbody>
      </table>
      <pagination class="simple-pagination" :paging="paging" @change="setOffset" />
    </template>

    <modal v-if="log" :title="'Import jobs for ' + log.name" @close="log = null" size="large">
      <template v-slot:body>
        <div>
          <div v-for="(job, index) in log.jobs">
            <h4>
              <b>Import job</b> #{{ job.id }}<b>, last updated at</b> {{ job.updated_at }} <b>with status</b> {{ job.status }}
            </h4>
            <br>
            <pre @click="job.open = !job.open" v-bind:class="{ 'collapse-open': job.open, 'collapse': !job.open }"><template v-for="item in job.import_logs"><span class="bg"><b>[{{ item.added_on }}]</b></span> {{ item.message }}
</template></pre>
            <br><br>
          </div>
        </div>
      </template>
    </modal>
    <modal v-if="newSource" title="Datasource was added" @close="newSource = false">
      <template v-slot:body>
        <div>
          A new Google spreadsheet was created.<br><br>
          <a :href="newSource.web_link" target="_blank" class="button button--secondary">Open Google spreadsheet</a><br>
        </div>
      </template>
    </modal>
  </div>
</div>
</template>

<script>
import Checkbox from '../Form/Checkbox.vue'
import Modal from '../Modals/Modal.vue'

import DsInput from '../Form/DsInput.vue'
import FormGroup from '../Form/FormGroup.vue'
import Pagination from '../Pagination/SimplePagination.vue'
import ThSort from '../Settings/ThSort.vue'
import ThFilter from '../Settings/ThFilter.vue'
import SuggestionInput from '../SuggestionInput/SuggestionInput.vue'
import Dropdown from '../Dropdown/Dropdown.vue'

import { Sources, getImportJobLogs, getAllImportsWithLogs } from '../../api/config'
import { suggestionSettings } from '../../api/actors.js'

import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../../store/modules/actors'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
import { defineComponent } from 'vue'

export default defineComponent({
  data () {
    return {
      newSource: false,
      adding: false,
      showAdd: false,
      delay: 200,
      timeout: 0,
      sources: [],
      sourcesWithLogs: [],
      logs: [],
      logsMeta: {},
      form: {
        email: this.$store.state.user.profile.email,
        filename: this.$store.state.config.name
      },
      successLogs: '',
      logAction: '',
      actorName: '',
      selectedSpreadsheet: '',
      selectedImport: null,
      paging: {
      },
      importFilesPaging: {
      },
      filters: {
        order: null
      },
      filterOptions: {
        success: [{ 'text': 'Show All', 'value': 'Show All' }, { 'text': 'Yes', 'value': 'Yes' }, { 'text': 'No', 'value': 'No' }],
        action: [{ 'text': 'Show All', 'value': 'Show All' }, { 'text': 'Create', 'value': 'create' }, { 'text': 'Update', 'value': 'update' }]
      },
      log: null,
      errors: {},
      orderByProperty: null,
      orderByDirection: ''
    }
  },
  computed: {
    suggestionSettings,
    sourcesContainLogs () {
      return this.sourcesWithLogs && this.sourcesWithLogs.length > 0
    },
    displayImportLogs () {
      return this.selectedSpreadsheet && this.selectedImport
    },
    user_id () {
      return this.$store.state.user.profile.id
    },
    sourcesByOthers () {
      return (this.sources || []).filter(s => s.user_id !== this.user_id).length
    },
    successOptions () {
      return ['Yes', 'No']
      return [
        { 'label': 'Yes', 'value': 'Yes' },
        { 'label': 'No', 'value': 'No' },
      ]
    },
    spreadsheets () {
      var spreadsheets = this.sources

      if (!spreadsheets || spreadsheets.length == 0) {
        return []
      }

      return spreadsheets.map(spreadsheet => {
        return {
          value: spreadsheet.file_id,
          label: spreadsheet.name
        }
      })
    },
    imports () {
      if (!this.selectedSpreadsheet) {
        return []
      }

      // Find the imports for the selected spreadsheet
      var spreadsheets = this.sourcesWithLogs.slice()// this.sources.slice();
      var spreadsheet = spreadsheets.filter(s => s.value == this.selectedSpreadsheet)

      if (spreadsheet.length == 0 || !spreadsheet[0].import_jobs || spreadsheet[0].import_jobs.length == 0) {
        return []
      }

      return spreadsheet[0].import_jobs.map(job => {
        return {
          value: job.id,
          label: job.created_at
        }
      })
    }
  },
  methods: {
    fetchLogContext (context) {
      var message = ''

      if (context.relationships && context.relationships.message) {
        message += context.relationships.message
      }

      if (context.fields && context.fields.message) {
        message += ' ' + context.fields.message
      }

      return message
    },
    getDuplicateIdFromLog (log) {
      return log.context && log.context.fields && log.context.fields.duplicate_actor && log.context.fields.duplicate_actor.id
    },
    getDuplicateNameFromLog (log) {
      return log.context && log.context.fields && log.context.fields.duplicate_actor && log.context.fields.duplicate_actor.name
    },
    changeActor (value) {
      this.actor = {}
    },
    showSidePanel (actorId) {
      if (!actorId) {
        return
      }

      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'scores', metaData: actorId })
    },
    fetch (force, offset) {
      var delay = this.delay

      if (force) {
        delay = 0
      }

      // Keep fetching more slowly over time
      // this.delay = force ? 0 : this.delay;

      return Sources.get({ offset: offset, limit: 10, order: this.filters.order })
      .then(sources => {
        this.sources = sources.data
        this.importFilesPaging = sources.paging

        clearTimeout(this.timeout)

        this.timeout = setTimeout(this.fetch, delay * 1000)
        /* if (force) {
          this.timeout = setTimeout(this.fetch, this.delay * 1000);
        } */
      })
    },
    fetchLogs () {
      getAllImportsWithLogs()
        .then(data => {
          this.sourcesWithLogs = []

          if (data) {
            this.sourcesWithLogs = data.map(importFile => {
              return {
                value: importFile.file_id,
                label: importFile.name,
                import_jobs: importFile.all_import_jobs
              }
            })
          }
        })
    },
    importNow (source) {
      Sources.import(source.file_id)
        .then(() => {
          var index = this.sources.find(file_id => source.file_id)

          this.sources.splice(index, 1)

          source.status = 'Running'
          this.sources.push(source)

          this.fetch(1)
        })
    },
    getImportJobLogs (offset) {
      // Clear the logs
      this.logs = []
      this.logsMeta = {}

      if (!this.selectedSpreadsheet || !this.selectedImport) {
        return
      }

      var success = this.successLogs == 'Yes' ? true : this.successLogs == 'No' ? false : null

      var filters = {
        success: success,
        logAction: this.logAction,
        actorName: this.actorName,
        offset: offset || 0,
        limit: 30
      }

      getImportJobLogs({ fileId: this.selectedSpreadsheet, jobId: this.selectedImport, filters: filters })
        .then(response => {
          this.logs = response.data.logs
          this.logsMeta = response.data.meta
          this.paging = response.paging
        })
        .catch(error => {
          this.logs = []
        })
    },
    addSource () {
      this.showAdd = 1
    },
    createSpreadsheet () {
      this.adding = true

      Sources.post(this.form)
        .then((source) => {
          this.sources.push(source)
          this.sources = this.sources.sort((a, b) => b.name > a.name)
          this.showAdd = false
          this.adding = false
          this.newSource = source
        })
        .catch(errors => {
          this.fetch(1)
          this.errors = errors || { filename: ['Something went wrong'] }
          this.adding = false
        })
    },
    remove (source) {
      if (!window.confirm('Are you sure you don\'t want to import this spreadsheet ever again?')) {
        return
      }

      Sources.delete(source.file_id)
        .then(() => {
          this.fetch()
        })
    },
    setOffset (offset) {
      // this.paging = Object.assign(this.paging, { offset: offset });
      this.getImportJobLogs(offset)
    },
    setImportFilesOffset (offset) {
      // this.importFilesPaging = Object.assign(this.importFilesPaging, { offset: offset });
      this.fetch(null, offset)
    },
    orderBy (property) {
      if (this.orderByProperty === property) {
        this.orderByDirection = (this.orderByDirection === '') ? '-' : ''
      } else {
        this.orderByProperty = property
      }

      this.filters.order = this.orderByDirection + this.orderByProperty

      this.fetch(1)
    }
  },
  created () {
    if (window.location.hash.startsWith('#log/')) {
      const data = window.location.hash.split('/')

      if (data.length >= 3) {
        this.selectedSpreadsheet = data[1]
        this.selectedImport = parseInt(data[2], 10)
      }
    }
  },
  mounted () {
    this.fetch()
    this.fetchLogs()
  },
  beforeUnmount () {
    clearTimeout(this.timeout)
  },
  watch: {
    selectedImport (val) {
      this.getImportJobLogs()
    },
    successLogs (val) {
      this.paging.offset = 0
      this.getImportJobLogs()
    },
    logAction (val) {
      this.paging.offset = 0
      this.getImportJobLogs()
    },
    actorName (val) {
      this.paging.offset = 0
      if (val.length > 0 && val.length <= 2) {
        return
      }

      this.getImportJobLogs()
    }
  },
  components: {
    Checkbox,
    DsInput,
    FormGroup,
    Modal,
    Pagination,
    SuggestionInput,
    ThSort,
    ThFilter,
    Dropdown
  }
})
</script>

<style lang="scss">
pre.collapse{
    height: 18px;
    overflow: hidden;
    font-family: Consolas, Menlo, Monaco, Lucida Console, Liberation Mono, DejaVu Sans Mono, Bitstream Vera Sans Mono, Courier New, monospace, serif;
    cursor: pointer;
}

.spreadsheet__log-filter {
  display: inline-block;
  width: 350px;
  margin-right: 40px;
}

pre.collapse-open{
    opacity: 1;
    background: none;
    overflow: auto;
    max-height: 450px;
    height: auto;
    cursor: initial;
}
.table--has-button {
  td {
    line-height: 26px!important;
  }
  .button {
    margin: -6px -10px!important;
    display: block;
    width: calc(100% + 20px);
    font-size: 12px;
    line-height: 32px;
    &:focus {
      z-index: 1;
    }
  }
}
</style>
