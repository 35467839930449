import { fetchDefaultDictionaries } from '../../api/exploration'

const state = {
  defaultDictionaries: [],
}

export const MUTATION_TYPES = {
  UPDATE_DEFAULT_DICTIONARIES: 'DEFAULT_DICTIONARIES/UPDATE_DEFAULT_DICTIONARIES',
}


export const mutations = {
  [MUTATION_TYPES.UPDATE_DEFAULT_DICTIONARIES] (state, data) {
    state.defaultDictionaries = data
  },
}

export const ACTION_TYPES = {
  FETCH_DEFAULT_DICTIONARIES: 'DEFAULT_DICTIONARIES/FETCH_DEFAULT_DICTIONARIES',
}

export const actions = {
  [ACTION_TYPES.FETCH_DEFAULT_DICTIONARIES] ({ commit }) {
    fetchDefaultDictionaries()
      .then(data => {
        commit(MUTATION_TYPES.UPDATE_DEFAULT_DICTIONARIES, data)
      })
  },
}

export const getters = {
  defaultDictionaries (state) {
    return state.defaultDictionaries
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
