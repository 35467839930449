export function significantDigits (n) {
  return parseFloat(n.toFixed(5))
}

export function getMinMaxYear (step = 0.25, total = 12, d) {
  d = d || new Date()
  const thisMonth = d.getFullYear() + d.getMonth() / 12
  return {
    min: Math.floor(thisMonth) - total * step,
    max: (Math.ceil(thisMonth / step) * step) + step
  }
}

// year, step => string
export function formatTime (input, step) {
  if (step === 1 / 4) {
    return Math.floor(input) + ' Q' + Math.round(1 + (input % 1) * 4)
  }
  return Math.floor(input) + '-' + Math.round(1 + (input % 1) * 12)
}

// year => seconds since epoch
export function toPhpTime (year) {
  return !year ? null : Date.UTC(Math.floor(year), Math.round((year % 1) * 12), 1) / 1000
}

// seconds since epoch => year + months in decimals
export function fromPhpTime (n) {
  var d = new Date(1000 * n)
  return !n ? 0 : d.getFullYear() + d.getMonth() / 12
}

// seconds since epoch => year
export function fromPhpTimeToYear (n) {
  var d = new Date(1000 * n)
  return !n ? 0 : d.getFullYear()
}
