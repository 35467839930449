<template>
  <card variant="gallery-vertical" @click="onClick">
    <div class="card__stat" v-if="company.total" @click.stop="$emit('stat')">{{ company.total }} {{ company.label }}</div>
    <div class="card__ribbon" v-if="ribbonClass" :style="ribbonStyle"></div>
    <div class="card__general">
      <div class="card__logo">
        <avatar :src="companyLogoUrl" :alt="company.name" variant="border"></avatar>
      </div>
      <div class="card__title">{{ company.name }}</div>
      <div class="card__ranking" v-if="$store.getters.hasScores && (businessSizeScore || digitalFootprintScore) && ! $store.getters.isActor">
        <div class="card__growth-score" v-if="businessSizeScore">
          <icon style="margin-right: 1rem;" name="business-size"/>
          {{ businessSizeScore }} Business Size Score
        </div>
        <div class="card__growth-score" v-if="digitalFootprintScore">
          <icon style="margin-right: 1rem;" name="digital-footprint"/>
          {{ digitalFootprintScore }} Digital Footprint Score
        </div>
      </div>
      <div class="card__content" v-if="content">{{ content }}</div>
      <div class="card__description">{{ shortDescription }}</div>
    </div>

    <div class="card__cover" :style="coverStyling"></div>
  </card>
</template>

<script lang="ts">
  import Avatar from '../../components/Avatar/Avatar.vue'
  import Card from '../../components/Card/Card.vue'

  import CompanyMixin from '../../util/CompanyMixin.js'
  import { getDefaultFallbackImageUrl } from '../../util/helpers.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      actor: {
        type: Object,
      },
      content: null,
    },
    emits: ['click'],
    computed: {
      company () {
        return this.actor || {}
      },
      coverStyling () {
        if (this.actor.featured_image_url) {
          return { backgroundImage: `url(${this.actor.featured_image_url})` }
        }

        return { backgroundImage: `url(${this.websiteScreenshot})` }
      },
      businessSizeScore () {
        return 15
        if (this.actor.business_size) {
          return this.actor.business_size.toPrecision(3)
        }
      },
      digitalFootprintScore () {
        if (this.actor.digital_footprint) {
          return this.actor.digital_footprint.toPrecision(3)
        }
      },
    },
    methods: {
      onClick () {
        this.$emit('click', this.company)
      },
    },
    components: {
      Avatar,
      Card,
    },
    mixins: [CompanyMixin],
  })
</script>

<style lang="scss">
  .card__growth-score {
    display: flex;
    align-items: end;
  }
</style>
