<template>
  <div>
    <vue-tags-input
      v-model="tag"
      :tags="formattedActors"
      :autocompleteAlwaysOpen="autocompleteOptions && autocompleteOptions.length > 0"
      :placeholder="placeholder"
      :autocomplete-items="autocompleteOptions"
      :add-only-from-autocomplete="true"
      @tags-changed="actorsChanged"
      ref="taginput"
    />
  </div>
</template>

<script>
import VueTagsInput from '@sipec/vue3-tags-input'

export default {
  components: {
    VueTagsInput,
  },
  props: {
    actors: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: 'Add actors'
    },
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tag: '',
      validation: [{
        type: 'min-length',
        rule: text => text.length < 1,
      }]
    };
  },
  methods: {
    actorsChanged (newActors) {
      // Don't confuse this emit event with tagChanged
      // actorsChanged means that the array of tags has been changed
      // the tagChanged means that the tag that the user is inputting has changed
      if (! newActors || newActors.length == 0) {
        this.$emit('actorsChanged', []);
      }

      var tags = newActors.map((newTag) => {
        return {
          name: newTag.text.trim(),
          id: newTag.value
        }
      });

      this.$emit('actorsChanged', tags);
    }
  },
  computed: {
    formattedActors () {
      // Return the tags in a format the vue plugin expects them to be in
      return this.actors.map(function (tag) {
        return {text: tag.name, value: tag.id};
      });
    },
    autocompleteOptions () {
      if (! this.options || this.options.length == 0) {
        return [];
      }

      // We assume the array to be a list of strings, convert it to the expected format for the vue-tags-input
      var options = this.options.map(function (option) {
        return {text: option.name, value: option.id};
      });

      if (! this.tag || this.tag.length < 2) {
        this.$refs.taginput.$refs.newTagInput.focus();

        return [];
      }

      options = options.filter(i => {
        return i.text.toLowerCase().indexOf(this.tag.toLowerCase()) !== -1;
      });

      return options;
    }
  },
  watch: {
    tag (val) {
      this.$emit('tagChanged', val);
    }
  }
};
</script>
