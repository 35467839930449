<template>
  <div class="colored-container outlined">
    <div class="row">
      <div class="col-md-4 col-xs-6">
        <div class="simplified-actor-detail__taxonomies">
          <div class="simplified-actor-detail__taxonomies_title">
            {{ $t('actor_detail_stage') }}:
          </div>
          <div class="simplified-actor-detail__taxonomies_value">
            {{
              (actor.stage && actor.stage.label) ? actor.stage.label : $t('actor_detail_not_defined')
            }}
          </div>
        </div>
        <div class="simplified-actor-detail__taxonomies">
          <div class="simplified-actor-detail__taxonomies_title">
            {{ $t('actor_detail_founding_date') }}:
          </div>
          <div class="simplified-actor-detail__taxonomies_value">
            {{ actor.founding_date || $t('actor_detail_not_defined') }}
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xs-6">
        <div class="simplified-actor-detail__taxonomies">
          <div class="simplified-actor-detail__taxonomies_title">
            {{ $t('actor_detail_maturity') }}:
          </div>
          <div class="simplified-actor-detail__taxonomies_value">
            {{ actor.maturity || $t('actor_detail_not_defined') }}
          </div>
        </div>
        <div
          class="simplified-actor-detail__taxonomies"
          v-if="isMember || (publicFieldsForRole && publicFieldsForRole.includes('funding_rounds'))"
        >
          <div class="simplified-actor-detail__taxonomies_title">
            {{ $t('actor_detail_last_funding_round') }}:
          </div>
          <div
            class="simplified-actor-detail__taxonomies_value"
            v-if="actor.funding_rounds && actor.funding_rounds.length"
          >
            {{
              actor.funding_rounds[actor.funding_rounds.length - 1].started_at || $t('actor_detail_not_defined')
            }}
          </div>
          <div class="simplified-actor-detail__taxonomies_value" v-else>
            {{ $t('actor_detail_not_defined') }}
          </div>
        </div>
      </div>
      <div class="col-md-4 col-xs-6">
        <div class="simplified-actor-detail__taxonomies">
          <div class="simplified-actor-detail__taxonomies_title">
            {{ $t('actor_detail_employees') }}:
          </div>
          <div class="simplified-actor-detail__taxonomies_value">
            {{ employeeSize }}
          </div>
        </div>
        <div
          class="simplified-actor-detail__taxonomies"
          v-if="isMember || (publicFieldsForRole && publicFieldsForRole.includes('total_funding'))"
        >
          <div class="simplified-actor-detail__taxonomies_title">
            {{ $t('actor_detail_funding') }}:
          </div>
          <div
            class="simplified-actor-detail__taxonomies_value"
            v-if="actor.total_funding"
          >
            {{ actorTotalFunding }}
          </div>
          <div class="simplified-actor-detail__taxonomies_value" v-else>
            {{ $t('actor_detail_not_defined') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { defineComponent } from 'vue'
  import { toAmount } from '@/util/currency'

  export default defineComponent({
    name: 'SimplifiedActorDetailTaxonomySection',
    props: {
      actor: {
        type: Object,
        required: true,
      }
    },
    computed: {
      isMember () {
        return this.$store.getters.isMember
      },
      employeeSize () {
        if (!this.actor.current_employees || !this.actor.current_employees.length) {
          return this.$t('actor_detail_not_defined')
        }

        var currentEmployees = this.actor.current_employees[0]

        if (currentEmployees.lower_bound == currentEmployees.upper_bound) {
          return currentEmployees.lower_bound
        }

        return currentEmployees.lower_bound + ' - ' + currentEmployees.upper_bound
      },
      actorTotalFunding () {
        if (this.actor.total_funding) {
          return '€ ' + toAmount(this.actor.total_funding, 5)
        }

        return this.$t('actor_detail_not_defined')
      },
      publicFieldsForRole () {
        return this.$store.getters.publicFieldsForRole
      },
    }
  })
</script>
<style lang="scss" scoped>
</style>
