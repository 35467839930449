<template>
  <div class="office-input">
    <form-group label="Name" :errors="errors.name" required>
      <ds-input v-model="modifiedModel.name"/>
    </form-group>

    <div v-if="modifiedModel.address">
      <div class="row">
        <form-group label="Street" class="col-xs-8 col-sm-5">
          <ds-input name="actor-address-street" v-model="modifiedModel.address.street"/>
        </form-group>
        <form-group label="Number" class="col-xs-4 col-sm-2">
          <ds-input name="actor-address-number" v-model="modifiedModel.address.number"/>
        </form-group>
        <form-group label="Zipcode" class="col-xs-4 col-sm-2">
          <ds-input name="actor-address-zip" v-model="modifiedModel.address.zip"/>
        </form-group>
        <form-group label="City" class="col-xs-8 col-sm-3" required>
          <ds-input name="actor-address-city" v-model="modifiedModel.address.city"/>
        </form-group>
      </div>
      <div class="row">
        <form-group label="Country" class="col-xs" required>
          <country-dropdown @update:modelValue ="handleCountryChange" :model-value="modifiedModel.address.country_code"/>
        </form-group>
      </div>
    </div>
    <div v-else>
      <ds-button variant="outline" icon="plus" label="Add address" @click="setEmptyAddress"/>
      <br>
      <br>
    </div>

    <ds-button
      :disabled="!canNewOfficeBeAdded"
      variant="outline"
      :icon="busy === 2 ? 'check' : busy === 1 ? 'spinner' : editable === 'Add office' ? 'plus' : 'check'"
      :label="editable"
      @click="save"
    />
  </div>
</template>

<script>
  import { createActor, updateActor } from '../../api/actors'

  import Checkbox from '../Form/Checkbox.vue'
  import CountryDropdown from '../Dropdown/CountryDropdown.vue'

  import { emptyAddress } from '../../util/actor'
  import { addressLine } from '../../util/helpers'

  export default {
    props: {
      editable: null,
      main: null,
      modelValue: null,
    },
    data () {
      return {
        busy: 0,
        errors: {},
        editing: !this.modelValue?.id,
        subsidiary: this.modelValue?.company_type === 'Subsidiary',
        modifiedModel: { ...this.modelValue }
      }
    },
    computed: {
      canNewOfficeBeAdded () {
        return this.modifiedModel.address
          && this.modifiedModel.address.city
          && this.modifiedModel.address.country
          && this.modifiedModel.name.length > 0
      },
      addressLine () {
        return addressLine(this.modifiedModel.address)
      }
    },
    methods: {
      handleCountryChange (country) {
        this.modifiedModel.address.country_code = country.code
        this.modifiedModel.address.country = country.name
      },
      setEmptyAddress () {
        this.modifiedModel.address = emptyAddress()
      },
      save () {
        this.$emit('beforeSave', this.modifiedModel)
        this.busy = 1

        if (this.modifiedModel.id) {
          return this.update()
        }

        createActor({
          actor_type: this.modifiedModel.actor_type || 'LegalEntity',
          address: this.modifiedModel.address || Object.assign(emptyAddress()),
          company_type: this.modifiedModel.company_type || 'Subsidiary',
          name: this.modifiedModel.name,
          main_company: [{
            id: this.main,
            to: this.main,
          }]
        }).then(() => {
          this.busy = 2
          this.errors = {}
          this.$emit('saved', this.modifiedModel)
        }).catch(errors => {
          this.busy = 0
          this.errors = errors
        })
      },
      update () {
        this.busy = 1
        updateActor({
          id: this.modifiedModel.id,
          data: {
            name: this.modifiedModel.name,
            address: this.modifiedModel.address
          }
        }).then(actor => {
          this.busy = 2
          this.errors = {}
          this.$emit('saved', this.modifiedModel)
        }).catch(() => {
          this.busy = 0
          this.errors = errors
        })
      }
    },
    components: {
      Checkbox,
      CountryDropdown,
    }
  }
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

  .office-input {
    display: block;
    margin-bottom: 1em;
    width: 100%;
    outline: 0;
    font-size: 14px;

    &.editable {
      border: 1px solid #CECECE;
      padding: 14px 10px 8px;
      background: #eee;
    }

    .svg-icon--chevron-down {
      vertical-align: top;
      margin: -3px 5px -10px;
    }
  }

  .office-input__btn {
    display: inline-block;
    margin: -8px 0;
    border: 2px solid transparent;
    padding: 3px;
    cursor: pointer;
    text-decoration: none;

    &:hover {
      background: white;
      border-color: $color-error;
    }

    .svg-icon {
      vertical-align: bottom;
      // display: block;
    }
  }

  .office-input__remove {
    &:hover {
      border-color: $color-error;
    }
  }

  .office-input__edit {
    padding: 3px 15px 3px 8px;

    a {
      color: black;
    }

    path {
      fill: black;
    }

    &:hover {
      border-color: $color-primary;
    }
  }

  .office-input__detail {
    margin-bottom: 3px;
  }

  .office-input__editing {
    padding-top: 10px;
    margin-bottom: -20px;
  }
</style>
