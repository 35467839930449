export const SECTIONS = {
  DIRECTORY: {
    id: 'directory',
    displayName: 'Directory'
  },
  SPOTLIGHT: {
    id: 'spotlight',
    displayName: 'Spotlight'
  },
  NEWS: {
    id: 'news',
    displayName: 'News'
  },
  FUNDING: {
    id: 'funding',
    displayName: 'Funding'
  },
  EVENTS: {
    id: 'events',
    displayName: 'Events'
  },
  INFLUENCERS: {
    id: 'influencers',
    displayName: 'Influencers'
  },
  ANNOUNCEMENTS: {
    id: 'announcements',
    displayName: 'Latest announcements'
  },
  QUICK_SEARCH: {
    id: 'quick-search',
    displayName: 'Quick Search'
  },
  KNOWLEDGE_BASE: {
    id: 'knowledge-base',
    displayName: 'Knowledge Base'
  },
  EXPLORATION: {
    id: 'exploration',
    displayName: 'Exploration'
  },
  EXPLORATION_RESULTS_ONLY: {
    id: 'exploration-results-only',
    displayName: 'Exploration Results'
  },
  COMMUNITY: {
    id: 'community',
    displayName: 'Community'
  },
  SPOTTING_AREAS: {
    id: 'spotting-areas',
    displayName: 'Spotting Areas'
  },
}

export const TEMPLATES = {
  ACTOR_FOCUSED: {
    displayName: 'Focus on actors',
    sections: [
      {
        id: SECTIONS.DIRECTORY.id,
        canBeHidden: false,
      },
      {
        id: SECTIONS.SPOTLIGHT.id,
        canBeHidden: true,
      },
      {
        id: SECTIONS.ANNOUNCEMENTS.id,
        canBeHidden: false,
      },
      {
        id: SECTIONS.FUNDING.id,
        canBeHidden: true,
      },
      {
        id: SECTIONS.NEWS.id,
        canBeHidden: true,
      },
      {
        id: SECTIONS.INFLUENCERS.id,
        canBeHidden: true,
      },
      {
        id: SECTIONS.EVENTS.id,
        canBeHidden: true,
      },
    ],
  },
  CONTENT_FOCUSED: {
    displayName: 'Focus on content',
    sections: [
      {
        id: SECTIONS.KNOWLEDGE_BASE.id,
        canBeHidden: false,
      },
      {
        id: SECTIONS.SPOTLIGHT.id,
        canBeHidden: true,
      },
      {
        id: SECTIONS.ANNOUNCEMENTS.id,
        canBeHidden: false,
      },
      {
        id: SECTIONS.FUNDING.id,
        canBeHidden: true,
      },
      {
        id: SECTIONS.NEWS.id,
        canBeHidden: true,
      },
      {
        id: SECTIONS.INFLUENCERS.id,
        canBeHidden: true,
      },
      {
        id: SECTIONS.EVENTS.id,
        canBeHidden: true,
      },
    ],
  },
  EXPLORATION_FOCUSED: {
    displayName: 'Focus on exploration',
    sections: [
      {
        id: SECTIONS.EXPLORATION.id,
        canBeHidden: false,
      },
    ],
  },
  MONITORING_FOCUSED: {
    displayName: 'Focus on monitoring',
    sections: [
      {
        id: SECTIONS.DIRECTORY.id,
        canBeHidden: false,
      },
      {
        id: SECTIONS.EXPLORATION_RESULTS_ONLY.id,
        canBeHidden: false,
      },
      {
        id: SECTIONS.SPOTLIGHT.id,
        canBeHidden: true,
      },
      {
        id: SECTIONS.ANNOUNCEMENTS.id,
        canBeHidden: false,
      },
      {
        id: SECTIONS.FUNDING.id,
        canBeHidden: true,
      },
      {
        id: SECTIONS.NEWS.id,
        canBeHidden: true,
      },
      {
        id: SECTIONS.INFLUENCERS.id,
        canBeHidden: true,
      },
      {
        id: SECTIONS.EVENTS.id,
        canBeHidden: true,
      },
    ]
  },
  COMMUNITY_FOCUSED: {
    displayName: 'Focus on spotting area/community',
    sections: [
      {
        id: SECTIONS.COMMUNITY.id,
        canBeHidden: false,
      },
      {
        id: SECTIONS.SPOTTING_AREAS.id,
        canBeHidden: false,
      },
      {
        id: SECTIONS.DIRECTORY.id,
        canBeHidden: false,
      },
      {
        id: SECTIONS.SPOTLIGHT.id,
        canBeHidden: false,
      },
      {
        id: SECTIONS.ANNOUNCEMENTS.id,
        canBeHidden: false,
      },
      {
        id: SECTIONS.FUNDING.id,
        canBeHidden: false,
      },
      {
        id: SECTIONS.NEWS.id,
        canBeHidden: false,
      },
      {
        id: SECTIONS.INFLUENCERS.id,
        canBeHidden: false,
      },
      {
        id: SECTIONS.EVENTS.id,
        canBeHidden: false,
      },
    ]
  }
}
