<template>
  <div>
    <div class="report-type__header">
      <h2>Choose the type of report you are interested in</h2>
      <p>Choose one of the options below</p>
    </div>
    <div class="report-type__types container-fluid">
      <div class="row">
        <div v-for="reportType in reportTypes" class="col-xs-6 report-type__types__container" style="display: flex">
          <div class="report-type__types__type" :class="{active: modelValue.reportType === reportType.id, disabled: reportType.disabled}" @click="handleClick(reportType)">
            <h3 class="report-type__types__type__name">{{reportType.name}}</h3>
            <div v-html="reportType.description"></div>
            <div class="report-type__types__type__overlay" v-if="reportType.disabled">Coming soon.</div>
          </div>
        </div>
      </div>
    </div>
    <div class="report-type__button">
      <ds-button variant="primary" label="Next step" @click="handleNextStep" :disabled="modelValue.reportType === null"/>
      <ds-button label="Cancel" @click="handleCancel" v-if="withCancel"/>
      <span v-if="modelValue.reportType === null">Please select a report type</span>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      modelValue: {
        type: Object,
      },
      reportTypes: {
        type: Array,
      },
      withCancel: {
        type: Boolean
      },
    },
    emits: ['update:modelValue', 'next-step', 'cancel'],
    methods: {
      handleClick(reportType) {
        if (reportType.disabled) {
          return
        }

        if (this.modelValue.reportType !== reportType.id) {
          this.$emit('update:modelValue', {
            ...this.modelValue,
            ...reportType.presets,
            reportType: reportType.id,
          })
        }

        this.handleNextStep()
      },
      handleNextStep() {
        this.$emit('next-step')
      },
      handleCancel() {
        this.$emit('cancel')
      },
    }
  })
</script>

<style lang="scss" scoped>
@import "../../../scss/_variables.scss";
  .report-type__header {
    margin-bottom: 20px;
  }

  .report-type__types {
    margin-bottom: 40px;
    padding: 0;
  }

  .report-type__types__container {
    .report-type__types__type {
      padding: 10px;
      background-color: $color-primary;
      color: #ffffff;
      cursor: pointer;
      margin-bottom: 20px;
      position: relative;

      &.active {
        background: var(--primary-light);
        border: 3px solid $color-borders;
      }

      &:hover:not(.disabled) {
        background: #00b392;
      }

      &.disabled {
        opacity: 0.5;
        cursor: default;
      }

      .report-type__types__type__overlay {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        align-items: center;
        justify-content: center;
        background: #2A3646;
        font-size: 18px;
      }

      &.disabled:hover .report-type__types__type__overlay {
        display: flex;
      }
    }

    &:nth-child(2){
      .report-type__types__type {
        background: #98b3cd;

        &.active {
          border: 3px solid $color-borders;
        }

        &:hover:not(.disabled) {
          background: #5481ab;
        }
      }
    }

  }

.report-type__types__type__name {
    margin-bottom: 8px;
  }

  .report-type__button {
    margin-top: auto;
    display: flex;
    align-items: center;

    span {
      margin-left: 20px;
    }
  }
</style>
