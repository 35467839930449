<template>
  <div class="actor-detail--mobile">
    <template v-if="hasSelectedReport">
      <ds-button :label="$t('actor_detail_reports_back')" variant="primary" @click="hideReport"/>
      <br>
      <br>
      <knowledge-sharing :actor="actor"/>
    </template>
    <template v-else>
      <template v-if="actor.featured_image_url">
        <img :src="actor.featured_image_url" :alt="actor.name" style="width: 100%; display: block;">
        <br>
      </template>

      <CollapsablePanel title="Company focus" v-if="companyFocusDefinitionListEntries.length > 0">
        <NewCard>
          <DefinitionList :entries="companyFocusDefinitionListEntries"/>
        </NewCard>
      </CollapsablePanel>

      <CollapsablePanel title="About the Company">
        <NewCard>
          <div v-html="actor.short_description"></div>
        </NewCard>
      </CollapsablePanel>

      <CollapsablePanel title="Product & Offering">
        <NewCard>
          <div v-html="actor.additional_information"></div>
        </NewCard>
      </CollapsablePanel>

      <CollapsablePanel title="Reports" v-if="userCanAccessReport && availableReportTemplatesForCurrentActor.length > 0">
        <NewCard>
          <div class="actor-detail-mobile__reports-container">
            <div v-for="reportTemplate in availableReportTemplatesForCurrentActor" class="actor-detail-mobile__report">
              <div>
                {{ reportTemplate.name }}
              </div>
              <div v-if="reportTemplate.is_call" style="margin-top: 7px">
                <tag :label="getOpenCallStatus(actor, reportTemplate)" style="margin-bottom: 0;"/>
              </div>
              <div style="margin: 5px 0">
                {{ new Date(reportTemplate.due_date).toLocaleDateString() }}
              </div>
              <ds-button
                icon="chevron-right"
                :label="'VIEW REPORT'"
                :disabled="isOpenCallEmptyAndExpired(actor.current_report, reportTemplate)"
                size="small" variant="secondary"
                @click="selectReportTemplate(reportTemplate)"
                style="width: fit-content; margin: 0 auto"/>
            </div>
          </div>
        </NewCard>
      </CollapsablePanel>

      <CollapsablePanel title="Connections" v-if="chunkedConnections.length">
        <NewCard>
          <div class="row" v-for="connectionsRow in chunkedConnections">
            <div class="col-xs-6 col-sm-6 col-lg-6 flex" v-for="connection in connectionsRow">
              <Connection :actor="actor" :connection="connection" style="height: 100%;"/>
            </div>
          </div>
        </NewCard>
      </CollapsablePanel>

      <CollapsablePanel title="Company info">
        <NewCard>
          <table class="table table--minimal">
            <tbody>
            <tr v-for="row in companyInfo">
              <td>{{ row.term }}</td>
              <td class="pre-line" style="text-align: right;">{{ row.description }}</td>
            </tr>
            </tbody>
          </table>
        </NewCard>
      </CollapsablePanel>

      <CollapsablePanel title="Segmentation">
        <NewCard>
          <table class="table table--minimal">
            <tbody>
            <tr v-for="row in segmentation">
              <td>{{ row.term }}</td>
              <td class="pre-line" style="text-align: right;">{{ row.description || spaced(row.descriptions) }}</td>
            </tr>
            </tbody>
          </table>
        </NewCard>
      </CollapsablePanel>
      <collapsablePanel title="Tags" v-if="actor.tags.length > 0 || actor.private_tags.length > 0">
        <Keyword v-for="(tag, index) in actor.tags" :key="'a' + index" :label="tag.label ? tag.label : tag"/>
        <Keyword v-for="(tag, index) in actor.private_tags" :key="'b' + index" :label="tag.label ? tag.label : tag" variant="private"/>
      </collapsablePanel>
      <CollapsablePanel title="Growth Scores" v-if="actor.scores">
        <NewCard>
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 flex">
              <Metric label="Digital footprint score" :model-value="scores.digitalFootprint.value"/>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 flex">
              <Metric label="Digital footprint growth" :model-value="scores.digitalFootprint.growth"/>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 flex">
              <Metric label="Business size score" :model-value="scores.businessSize.value"/>
            </div>
            <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 flex">
              <Metric label="Business size growth" :model-value="scores.businessSize.growth"/>
            </div>
          </div>
        </NewCard>
      </CollapsablePanel>
    </template>
  </div>
</template>

<script>
  import numeral from 'numeral'
  import _chunk from 'lodash/chunk'

  import { _unique, addressLines } from '../../util/helpers'
  import Connection from '../../components/Connection/Connection.vue'
  import NewCard from '../../components/NewCard/NewCard.vue'
  import Keyword from '../../components/Keyword/Keyword.vue'
  import Metric from '../../components/Metric/Metric.vue'
  import DefinitionList from '../../components/DefinitionList/DefinitionList.vue'
  import CollapsablePanel from '../../components/CollapsablePanel/CollapsablePanel.vue'
  import KnowledgeSharing from '../../components/KnowledgeSharing/KnowledgeSharing.vue'
  import ReportMixin from '../../util/ReportMixin.js'
  import CompanyMixin from '../../util/CompanyMixin.js'
  import Tag from '../../components/Tag/Tag.vue'
  import ConfigMixin from '../../util/ConfigMixin'

  export default {
    name: 'ActorDetailMobile',
    props: {
      actor: Object,
      connections: Array,
    },
    computed: {
      company () {
        return this.$store.getters.detailActor
      },
      chunkedConnections () {
        return _chunk(this.connections, 2)
      },
      companyInfo () {
        const actor = this.actor

        var vatNumber = null
        var companyNumber = null
        var naceCodes = null

        if (actor.vat_number) {
          vatNumber = `${(actor.jurisdiction || '').toUpperCase()} ${actor.vat_number}`
        }

        if (actor.company_number) {
          companyNumber = `${(actor.jurisdiction || '').toUpperCase()} ${actor.company_number}`
        }

        if (actor.industry_codes && actor.industry_codes.length > 0) {
          naceCodes = actor.industry_codes.length > 0 ? actor.industry_codes.map(code => code.code).join(', ') : null
        }

        return [
          {
            term: 'Company Name:',
            description: this.actor.name,
          },
          {
            term: 'Juridical Form:',
            description: actor.legal_form,
          },
          {
            term: 'Company Number:',
            description: companyNumber,
          },
          {
            term: 'VAT Number:',
            description: vatNumber,
          },
          {
            term: 'Founding Date:',
            description: actor.founding_date,
          },
          {
            term: 'Legal Address:',
            description: addressLines(actor.address),
          },
          {
            term: 'NACE Codes:',
            description: naceCodes,
          },
        ].filter(entry => entry.description)
      },
      segmentation () {
        const actor = this.actor
        return [
          {
            term: 'Is SME:',
            description: actor.is_sme ? 'Yes' : 'No',
          },
          {
            term: 'Maturity:',
            description: actor.maturity,
          },
          {
            term: 'Funding Stage:',
            description: actor.funding_stage,
          },
          {
            term: this.getTaxonomyAlias('stage', false, 'Growth Stage') + ':',
            description: this.fetchSimpleSingleTaxonomyLabel(actor.stage),
          },
          {
            term: this.getTaxonomyAlias('funnel_stage', false, 'CRM Stage') + ':',
            description: this.fetchSimpleSingleTaxonomyLabel(actor.funnel_stage),
          },
          {
            term: this.getTaxonomyAlias('membership', false, 'Memberships') + ':',
            descriptions: this.fetchSimpleTaxonomyLabels(actor.membership),
          },
        ].filter(entry => entry.description || (entry.descriptions && entry.descriptions.length))
      },
      scores () {
        if (!this.actor.scores) {
          return
        }

        const { digital_footprint, business_size } = this.actor.scores
        return {
          digitalFootprint: {
            value: this.formatScore(digital_footprint.value),
            growth: this.formatGrowth(digital_footprint.growth),
          },
          businessSize: {
            value: this.formatScore(business_size.value),
            growth: this.formatGrowth(business_size.growth),
          },
        }
      },
      companyFocusDefinitionListEntries () {
        const actor = this.actor
        return [
          {
            term: 'Business Type:',
            description: actor.type,
          },
          {
            term: actor.industry && `Target ${actor.industry.length === 1 ? 'Industry' : 'Industries'}:`,
            descriptions: actor.industry,
          },
          {
            term: actor.market && `Target ${actor.market.length === 1 ? 'Market' : 'Markets'}:`,
            descriptions: actor.market,
          },
          {
            term: 'Technology Focus:',
            descriptions: actor.technology,
          },
          {
            term: 'Membership:',
            descriptions: this.fetchSimpleTaxonomyLabels(actor.membership),
          },
        ].filter(entry => entry.description || (entry.descriptions && entry.descriptions.length))
      },
    },
    methods: {
      formatGrowth (growth) {
        return `${numeral(growth * 100).format('0.00a')}` + '%'
      },
      formatScore (score) {
        return `${numeral(score).format('0.00a')}`
      },
      spaced (list) {
        return list && list.length ? list.join(', ') : ''
      },
      fetchSimpleTaxonomyLabels (taxonomyValues) {
        if (!taxonomyValues || taxonomyValues.length == 0) {
          return []
        }

        var labels = taxonomyValues.map(taxonomy => taxonomy.label)

        return _unique(labels)
      },
    },
    mixins: [ConfigMixin, CompanyMixin, ReportMixin],
    components: {
      KnowledgeSharing,
      Keyword,
      CollapsablePanel,
      DefinitionList,
      NewCard,
      Connection,
      Metric,
      Tag,
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/_variables.scss";

  .actor-detail-mobile__reports-container {
    display: flex;
    flex-direction: column;
    margin: -10px 0;

    .actor-detail-mobile__report {
      width: 100%;
      border: 1px solid $color-borders;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: space-between;
      margin: 10px 0;
      padding: 10px 0;
    }
  }
</style>
