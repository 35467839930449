import { fetchMediaBuzzData } from '../../api/buzz.js'

export const MUTATION_TYPES = {
  UPDATE_BUZZ_FILTER: 'MEDIA_BUZZ/UPDATE_BUZZ_FILTER',
  REMOVE_BUZZ_FILTER: 'MEDIA_BUZZ/REMOVE_BUZZ_FILTER',
  RESET_BUZZ_FILTERS: 'MEDIA_BUZZ/RESET_BUZZ_FILTERS',
}

const state = {
  filters: [],
}

export const mutations = {
  [MUTATION_TYPES.UPDATE_BUZZ_FILTER] (state, newFilter) {
    if (state.filters.length > 0) {
      var filterIndex  = state.filters.findIndex(filter => filter.facet == newFilter.facet)

      if (filterIndex > -1) {
        state.filters.splice(filterIndex, 1)
      }
    }

    state.filters.push(newFilter)
  },
  [MUTATION_TYPES.REMOVE_BUZZ_FILTER] (state, removeFilter) {
    state.filters = state.filters.filter(item =>  item.facet !== removeFilter.facet)
  },
  [MUTATION_TYPES.RESET_BUZZ_FILTERS] (state) {
    state.filters = []
  },
}

export const getters = {
  // Return the buzz filters as key-value pairs
  mediaBuzzQueryParameters (state) {
    var queryParameters = {};

    for (var i = 0; i < state.filters.length; i++) {
      var filter = state.filters[i]

      queryParameters[filter.facet] = filter.value
    }

    return queryParameters
  }
}

const keywords = keywordsFromQueryParams(window.location.search.substring(1));

export function keywordsFromQueryParams (query) {
  var match
  var output = []
  var pl = /\+/g
  var search = /([^&=]+)=?([^&]*)/g
  var decode = function (s) {
    return decodeURIComponent(s.replace(pl, ' '))
  }

  // eslint-disable-next-line no-cond-assign
  while (match = search.exec(query)) {
    output = output.concat(
      decode(match[2])
        .split(',')
        .map((value) => {
          const facet = decode(match[1])

          if (facet === 'geoid') {
            value = parseInt(value)
            if (!window.GEONAMES || !window.GEONAMES.find(g => g.value === value)) {
              return
            }
          }

          return { facet, value }
        }
      ).filter(Boolean)
    )
  }

  return output;
}

export default {
  state: {
    filters: (keywords || ls('actorKeywords')).filter(isBuzzKeyword),
  },
  mutations,
  getters
}

export function isBuzzKeyword (f) {
  var facetAllowed = [
    'topic',
    'topic_name',
    'actor',
    'time',
    'channel',
    'keyword_type'
  ].includes(f.facet);

  return facetAllowed && f.value != '-';
}
