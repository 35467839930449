<template>
  <div>
    <div class="fixed-heading">
      <div class="card__general side-panel__header">
        <div class="card__title side-panel__portfolio-card__title side-panel__portfolio-header-card__title">Create Portfolio</div>
        <div class="pull-right side-panel__portfolio-header-button-container" style="text-align: right;">
          <ds-button icon="star" variant="minimal" size="small" @click="toggleFavourite(portfolio.favourited)" class="side-panel__portfolio-header-icon"
            v-if="portfolio.favourited"/>
          <ds-button icon="star-outline" variant="minimal" size="small" @click="toggleFavourite(portfolio.favourited)" class="side-panel__portfolio-header-icon" v-else/>
          <ds-button icon="remove" variant="minimal" @click="hidePreview" size="small" class="side-panel__portfolio-header-icon"/>
          <div v-if="errors.delete">{{ errors.delete.join(' ') }}</div>
        </div>
      </div>
    </div>
    <tabs :tabs="enabledTabs" class="side-panel-portfolio__tab-section"></tabs>
    <div class="scrollable side-panel__scrollable__content">
      <div class="has-padding has-action-bar-extra-space">
        <template v-if="activeTab == 'Description'">
          <p v-if="errors.message" class="form-group__error" v-text="errors.message"></p>
          <div class="row side-panel__portfolio-actor-details-coontainer">
            <div class="col-xs-5">
              <form-group label="Image" instructions=".png or .jpg &middot; &plusmn;320&times;160" :errors="errors.image">
                <image-input v-model="portfolio.image" width="320" height="160"/>
              </form-group>
            </div>
            <div class="col-xs-7">
              <form-group label="Portfolio name" :errors="errors.name">
                <ds-input v-model="portfolio.name"/>
              </form-group>
            </div>
          </div>
          <form-group class="row has-padding side-panel__portfolio-section" label="Description" :errors="errors.description">
            <ds-textarea placeholder="Enter a description" v-model="portfolio.description"/>
          </form-group>
          <div class="row has-padding side-panel__portfolio-section">
            <div class="side-panel__portfolio-info-section">
              <div class="side-panel__portfolio-info-section-item">
                <ds-button icon="notification-label" label="Tags" :disabled="true" variant="minimal" class="side-panel__portfolio-info-section-item-icon"/>
                <form-group :errors="errors.tags" class="side-panel__portfolio-info-section-item-input" style="width: 79% !important">
                  <tag-input v-model="portfolio.tags" placeholder="Add a tag..."/>
                </form-group>
              </div>
            </div>
          </div>
        </template>
        <template v-if="activeTab == 'Sharing'">
          <div class="row has-padding side-panel__portfolio-section">
            <div class="side-panel__portfolio-permissions-section">
              <div class="side-panel__portfolio-permissions-items side-panel__portfolio-sharing-permissions" style="margin-bottom:1em">
                <span class="side-panel__portfolio-sharing-permissions-title"> <icon name="bookmark"/> VISIBILITY</span>
                <p>
                  <radio-button
                    v-model="portfolio.private"
                    name="share-option"
                    label="Private"
                    :val="true"
                    :model-value="true"
                  />
                  <span class="side-panel__portfolio-sharing-permissions-guide-text">(can be viewed and updated by you and ecosystem owners)</span>
                </p>
                <p>
                  <radio-button
                    v-model="portfolio.private"
                    name="share-option"
                    label="Shared"
                    :val="false"
                    :model-value="false"
                  />
                  <span class="side-panel__portfolio-sharing-permissions-guide-text">(can be viewed and updated by you, ecosystem owners and your team members)</span>
                </p>
              </div>
              <div class="side-panel__portfolio-permissions-items" style="margin-bottom:1em" v-if="isOwner && hasAccessToPortfolioSharing">
                <span>PUBLISH PORTFOLIO</span>
                <div>
                  <input type="checkbox" name="publish-option" class="side-panel__portfolio-permissions-publish-option" :checked="canPublish || publishState"
                    @click="changePublishState(publishState)">
                  <h5>Publish this portfolio in order to provide invited portfolio members access explore and contribute. </h5>
                </div>
              </div>
              <div class="side-panel__portfolio-permissions-items" style="margin-bottom:1em" v-if="isOwner &&  hasAccessToPortfolioSharing">
                <span>OVERVIEW OF WHO HAS ACCESS TO THIS PORTFOLIO</span>
                <h5 style="margin: 1em 0px;"> This portfolio is shared with users below:</h5>
              </div>
              <div class="side-panel__portfolio-permissions-items" v-if="isOwner && hasAccessToPortfolioSharing">
                <div class="side-panel__portfolio-permissions-items-tilte-container">
                  <span> <icon name="edit"/>  TO CONTRIBUTE <small> i.e. view the directory of actors and their profiles,  add and exclude actors from the portfolio, update actor profiles, use the portfolio for benchmarking with other portfolios</small></span>
                  <span>{{ errors.name }}</span>
                </div>
                <div>
                  <form-group>
                    <div class="side-panel__portfolio-permissions-items-keyword-container">
                      <template v-if="owners.length > 0">
                        <avatar-keyword v-for="(owner, index) in  owners" :key="'a' + index" :src="owner.photo_url" :alt="owner.name" :label="owner.name"/>
                      </template>
                      <template v-if="teamMembers.length > 0 && portfolio.private == false">
                        <avatar-keyword v-for="(teamMember, index) in  teamMembers" :key="'b' + index" :src="teamMember.photo_url" :alt="teamMember.name" :label="teamMember.name"/>
                      </template>
                      <template v-if="publishState == true">
                        <avatar-keyword v-for="(user, index) in members.contributors" :key="'c' + index" removeable :userData="user" :label="user.name"
                          @remove="removeContributor(user)"/>
                      </template>
                      <template v-else>
                        <avatar-keyword v-for="(user, index) in members.contributors" :key="'d' + index" :userData="user" :label="user.name"/>
                      </template>
                    </div>
                    <suggestion-input
                      @actor="addContributor"
                      icon="search"
                      placeholder="Add user name"
                      :api-settings="userPortfolioMembersSuggestion"
                      :clear-on-select="true"
                      v-if="publishState == true"
                    />
                  </form-group>
                </div>
              </div>
              <div class="side-panel__portfolio-permissions-items" v-if="isOwner && hasAccessToPortfolioSharing">
                <div class="side-panel__portfolio-permissions-items-tilte-container">
                  <span> <icon
                    name="share"/> TO EXPLORE <small> i.e. view the directory of actors and their profiles and use the portfolio for benchmarking with other portfolios</small></span>
                  <span>{{ errors.name }}</span>
                </div>
                <div>
                  <form-group>
                    <div class="side-panel__portfolio-permissions-items-keyword-container">
                      <template v-if="publishState == true">
                        <avatar-keyword v-for="(user, index) in  members.viewers" :key="'a' + index" removeable :userData="user" :label="user.name" @remove="removeViewer(user)"/>
                      </template>
                      <template v-else>
                        <avatar-keyword v-for="(user, index) in  members.viewers" :key="'b' + index" :userData="user" :label="user.name"/>
                      </template>
                    </div>
                    <suggestion-input
                      @actor="addViewer"
                      icon="search"
                      placeholder="Add user name"
                      :api-settings="userPortfolioMembersSuggestion"
                      :clear-on-select="true"
                      v-if="publishState == true"
                    />
                  </form-group>
                </div>
              </div>
              <!--<div class="side-panel__portfolio-permissions-items" v-if="isOwner && hasAccessToPortfolioSharing">
                  <div class="side-panel__portfolio-permissions-items-tilte-container">
                    <span> <icon name="share"/>  TO BENCHMARK <small> i.e. only use the portfolio for benchmarking with other portfolios</small></span>
                    <span>{{ errors.name }}</span>
                  </div>
                  <div>
                    <form-group>
                      <div class="side-panel__portfolio-permissions-items-keyword-container">
                        <template v-if="publishState == true">
                          <avatar-keyword v-for="(user, index) in  members.benchmarkers" :key="'a' + index" removeable :userData="user" :label="user.name" @remove="removeBenchmarker(user)"/>
                        </template>
                        <template v-else>
                          <avatar-keyword v-for="(user, index) in  members.benchmarkers" :key="'b' + index" :userData="user" :label="user.name"/>
                        </template>
                      </div>
                      <suggestion-input
                      @actor="addBenchmarker"
                      icon="search"
                      placeholder="Add user name"
                      :api-settings="userPortfolioMembersSuggestion"
                      :clear-on-select="true"
                      v-if="publishState == true"
                      />
                    </form-group>
                  </div>
              </div>-->
            </div>
          </div>
        </template>
        <!-- Since the tab contains an variable number for the actors, using the other tabs comparison to determine if the user is in the actors tab -->
        <template v-if="activeTab !== 'Description' && activeTab != 'Sharing'">
          <div class="row has-padding side-panel__portfolio-section side-panel__portfolio-filters">
            <div class="side-panel__portfolio-clear-filters-section">
              <h4 class="side-panel__section-title">Portfolio Member Information</h4>
              <p class="guide_text" style="margin-bottom: 1em">The portfolio is based on the following filters, which can be changed via the directory - list view.</p>
              <div class="side-panel__portfolio-filters-textarea">
                <keyword-with-facet
                  v-for="(keyword, index) in keywords.filter((kword) => kword !== 'legendProperty')"
                  :key="index"
                  :label="keyword.value"
                  :model-value="keyword"
                />
              </div>
            </div>
            <div class="form-group__error" v-if="errors.filters">{{ errors.filters.join(' ') }}</div>
          </div>
          <form-group class="row has-padding side-panel__portfolio-section side-panel__portfolio-add-actors-input" label="Add an Actor">
            <suggestion-input
              @actor="includeActor"
              @update:modelValue="actorAddedState = false"
              icon="search"
              placeholder="Search an actor"
              :api-settings="suggestionSettings"
              :clear-on-select="true"
            />
            <div class="form-group__instructions" style="float:left;font-size: 11px;" v-if="actorAddedState">Actor included.</div>
          </form-group>
          <form-group :label="lockSectionTitle" class="row has-padding side-panel__portfolio-section side-panel__portfolio-locking-container">
            <ds-button icon="padlock" size="small" variant="secondary" @click="toggleLockState(portfolio.locked)" v-if="portfolio.locked == true"/>
            <ds-button icon="padlock-unlocked" size="small" variant="secondary" @click="toggleLockState(portfolio.locked)" v-else/>
            <span class="side-panel__portfolio-locking-text">By locking the portfolio, you fix the list of actors linked to this portfolio.</span>
          </form-group>
        </template>
      </div>
      <action-bar editing="1" class="side-panel__portfolio-bottom-section">
        <template v-if="! saved">
          <ds-button variant="secondary" label="Create portfolio" @click="save" :disabled="saving" v-if="! saving"/>
          <ds-button variant="secondary" label="Creating portfolio..." :disabled="true" v-else/>
          <ds-button variant="" label="cancel" @click="hidePreview"/>
        </template>
      </action-bar>
    </div>
  </div>
</template>

<script>
  import Card from '../../components/Card/Card.vue'
  import CardInfoSocial from '../../components/Card/CardInfoSocial.vue'
  import KeywordWithFacet from '../../components/Keyword/KeywordWithFacet.vue'
  import ActionBar from '../../components/Form/ActionBar.vue'
  import Checkbox from '../../components/Form/Checkbox.vue'
  import RadioButton from '../../components/Form/RadioButton.vue'
  import ImageInput from '../../components/Form/ImageInput.vue'
  import SuggestionInput from '../../components/SuggestionInput/SuggestionInput.vue'
  import TagInput from '../../components/Form/TagInput.vue'
  import Tabs from '../../components/Tabs/Tabs.vue'
  import Badge from '../../components/Badge/Badge.vue'
  import Dropdown from '../../components/Dropdown/Dropdown.vue'
  import DsTextarea from '../../components/Form/DsTextarea.vue'
  import AvatarKeyword from '../../components/Keyword/AvatarKeyword.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import KEYCODES from '../../constants/keycodes'
  import ICONS from '../../../icons/manifest.json'

  import { suggestionSettings } from '../../api/actors.js'
  import { favouritePortfolio, Portfolios } from '../../api/portfolios'
  import { fetchProfile } from '../../api/user.js'
  import { userPortfolioMembersSuggestion } from '../../api/config'

  import PortfolioMixin from '../../util/PortfolioMixin.js'

  export default {
    name: 'create-portfolio',
    data () {
      return {
        ICONS,
        errors: {},
        saving: false,
        saved: false,
        portfolio: {
          private: true,
          filters: {},
          actor_ids: [],
          actor_ids_excluded: [],
          actors: [],
          id: null,
          name: '',
          image: null,
          tags: [],
          locked: false,
          favourited: false,
        },
        activeTab: 'Description',
        actorAddedState: false,
        publishState: false,
        owners: [],
        teamMembers: [],
        members: {
          benchmarkers: [],
          viewers: [],
          contributors: [],
        },
        emptyMembers: {
          benchmarkers: [],
          contributors: [],
          viewers: [],
        },
        membersChanged: false,
      }
    },
    props: ['config'],
    computed: {
      suggestionSettings,
      userPortfolioMembersSuggestion,
      totalActors () {
        if (this.portfolio.locked) {
          return this.portfolio.actor_ids.length
        }

        return Number(this.listData.total) + Number(this.portfolio.actors.length)
      },
      /* listData () {
        return this.$store.state.actors.listData;
      },
      keywords () {
        return this.$store.state.filters.keywords;
      }, */
      filters () {
        const filters = Object.assign({}, this.$store.getters.listFilterObject)

        delete filters.br
        delete filters.group
        delete filters.limit
        delete filters.offset
        delete filters.order
        delete filters.portfolio
        delete filters.tl
        delete filters.view

        return filters
      },
      canPublish () {
        return this.publishState == true && (this.members.contributors.length > 0 || this.members.viewers.length > 0 || this.members.benchmarkers.length > 0)
      },
      lockSectionTitle () {
        return `Locking the portfolio ${this.portfolio.locked == true || this.portfolio.locked == '1' ? '(Locked)' : '(Unlocked)'} `
      },
    },
    methods: {
      hidePreview (evt) {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      includeActor (actor, fromFilter) {
        this.portfolio.actor_ids_excluded = this.portfolio.actor_ids_excluded.filter(a => a !== actor.id)
        this.portfolio.actor_ids = this.portfolio.actor_ids.filter(a => a !== actor.id)

        if (!fromFilter) {
          this.portfolio.actor_ids.push(actor.id)

          const existing = this.portfolio.actors.find(a => a.id === actor.id)

          if (!existing) {
            this.portfolio.actors.push({ id: actor.id, name: actor.name })
            this.actorAddedState = true
          }
        }
      },
      save () {
        this.saving = true
        this.errors = {}

        if (this.portfolio.locked) {
          this.portfolio.filters = []
        }

        Portfolios.post(this.portfolio)
          .then(portfolio => {
            // Set lock state for the portfolio, default is locked = false, so we only need to change if the locked is set to true
            /* if (this.portfolio.locked) {
              lockPortfolio(portfolio.id).then(response => {
              })
              .catch(errors => {
              })
            } */

            if (this.hasAccessToPortfolioSharing && this.isOwner && this.membersChanged) {
              // Deprecated
              /* setPortfolioMembers({ portfolioId: portfolio.id, data: this.members })
                .then(output => {
                })
                .catch(errors => {
                }) */
            }

            // Set the portfolio favourite state
            if (this.portfolio.favourited == true) {
              // add portfolio to favourites
              favouritePortfolio(portfolio.id).then(response => {
                if (response.updated == true) {
                  this.portfolio.favourited = response.favourited
                }
              })
                .catch(errors => {
                })
            }

            Portfolios.get()
              .then(portfolios => {
                // We concat the the created portfolio into the array of portfolios because when creating the portfolio and assigning it to the window.PORTFOLIOS array, the new portfolio is not there yet.
                window.PORTFOLIOS = portfolios.concat(portfolio)

                this.hidePreview()

                // Update the user object
                fetchProfile()
                  .then(profile => {
                    this.$store.commit('USER/UPDATE_OWNED_PORTFOLIOS', profile)
                  })
              })
              .catch(() => {
              })

            this.saved = true
          })
          .catch(errors => {
            this.saved = false
            this.saving = false
            this.errors = errors
          })
      },
      cancel () {
        this.$router.push('.')
      },
      toggleLockState (lockState) {
        this.portfolio.locked = !lockState
      },
      toggleFavourite (favouriteState) {
        this.portfolio.favourited = !favouriteState
      },
    },
    mounted () {
      window.addEventListener('keyup', (e) => {
        if (e.keyCode === KEYCODES.ESC) {
          this.hidePreview()
        }
      })

      this.$store.commit('FILTERS/CLEAR_BY_FACET', 'actor_type')

      // Set the portfolio filters to the current selected filters
      this.portfolio.filters = this.filters

      if (this.config && this.config.title) {
        this.portfolio.name = this.config.title
        this.portfolio.actor_ids = this.config.actor_ids
        this.portfolio.tags = this.config.tags
        this.portfolio.description = this.config.description
        this.portfolio.locked = this.config.locked
        this.portfolio.filters = this.config.filters
      }

      // Detect which tab is active
      this.$bus.on('tab', (tab) => {
        this.activeTab = tab
      })
    },
    beforeUnmount () {
      this.$bus.off('tab')
    },
    watch: {
      filters (v) {
        this.portfolio.filters = Object.assign({}, v)
      },
    },
    mixins: [PortfolioMixin],
    components: {
      ActionBar,
      AvatarKeyword,
      Badge,
      Card,
      CardInfoSocial,
      Checkbox,
      Dropdown,
      DsTextarea,
      ImageInput,
      KeywordWithFacet,
      RadioButton,
      SuggestionInput,
      Tabs,
      TagInput,
    },
  }
</script>
