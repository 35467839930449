import { fetchRelationships } from '../../api/actor-relationships'
import { EcosystemRelationshipsMetaDataLists, linkRelationshipWithMetaDataList } from '../../api/ecosystem-meta-data'

const state = {
  relationships: [],
  metaDataLists: [],
  fetchingMetaDataLists: false,
  metaDataListsPromise: null,
}

export const MUTATION_TYPES = {
  UPDATE_RELATIONSHIPS: 'ACTOR_RELATIONSHIPS/UPDATE_RELATIONSHIPS',
  UPDATE_RELATIONSHIPS_META_DATA: 'ACTOR_RELATIONSHIPS/UPDATE_RELATIONSHIPS_META_DATA',
  FETCHING_RELATIONSHIPS_META_DATA: 'ACTOR_RELATIONSHIPS/FETCHING_RELATIONSHIPS_META_DATA',
}

export const ACTION_TYPES = {
  FETCH_RELATIONSHIPS: 'ACTOR_RELATIONSHIPS/FETCH_RELATIONSHIPS',
  FETCH_RELATIONSHIPS_META_DATA: 'ACTOR_RELATIONSHIPS/FETCH_RELATIONSHIPS_META_DATA',
  LINK_RELATIONSHIP_WITH_LIST: 'ACTOR_RELATIONSHIPS/LINK_RELATIONSHIP_WITH_LIST',
}

export const mutations = {
  [MUTATION_TYPES.UPDATE_RELATIONSHIPS] (state, relationships) {
    relationships = relationships.map(relationship => {
      const listIds = (relationship.lists || []).map(list => list.list_id)

      relationship.listIds = listIds

      return relationship
    })

    state.relationships = relationships
  },
  [MUTATION_TYPES.FETCHING_RELATIONSHIPS_META_DATA] (state, promise) {
    state.fetchingMetaDataLists = true
    state.metaDataListsPromise = promise
  },
  [MUTATION_TYPES.UPDATE_RELATIONSHIPS_META_DATA] (state, lists) {
    state.metaDataLists = lists
    state.fetchingMetaDataLists = false
  },
}

export const actions = {
  [ACTION_TYPES.FETCH_RELATIONSHIPS] ({ commit }) {
    fetchRelationships()
      .then(response => {
        commit(MUTATION_TYPES.UPDATE_RELATIONSHIPS, response)
      })
      .catch(err => {
        console.log(err)
      })
  },
  [ACTION_TYPES.LINK_RELATIONSHIP_WITH_LIST] ({ commit }, data) {
    var relationshipId = data.relationshipId
    var listId = data.listId

    linkRelationshipWithMetaDataList(relationshipId, listId)
      .then(response => {
        commit(MUTATION_TYPES.UPDATE_RELATIONSHIPS, response)
      })
      .catch(error => {
        console.log(error)
      })
  },
  [ACTION_TYPES.FETCH_RELATIONSHIPS_META_DATA] ({ commit }) {
    const metaDataListsPromise = EcosystemRelationshipsMetaDataLists
      .get()
      .then(response => {
        commit(MUTATION_TYPES.UPDATE_RELATIONSHIPS_META_DATA, response)
      })
      .catch(error => {
        console.log(error)
      })
    commit(MUTATION_TYPES.FETCHING_RELATIONSHIPS_META_DATA, metaDataListsPromise)
  },
}

export const getters = {
  actorRelationships (state) {
    // Return the actor relationship objects, 1 object = 1 connection configuration
    // i.e. 1 object for collaborates / has collaboration with
    return state.relationships
  },
  fullActorRelationships (state) {
    // Return all relationships, with "forward" and "inverse" being individual objects in the return object
    var relationships = []

    if (!state.relationships) {
      return relationships
    }

    state.relationships.forEach(relationship => {
      relationships.push({
        name: relationship.name,
        label: relationship.label,
        inverse_name: relationship.inverse_name,
        inverse_label: relationship.inverse_label,
        type: relationship.type,
        is_forward_connection: true,
        listIds: relationship.listIds,
      })

      relationships.push({
        name: relationship.inverse_name,
        label: relationship.inverse_label,
        inverse_name: relationship.name,
        inverse_label: relationship.label,
        type: relationship.type,
        is_forward_connection: false,
        listIds: relationship.listIds,
      })
    })

    return relationships
  },
  filterableRelationships (state, getters) {
    var relationshipNames = getters.fullActorRelationships.filter(rel => !['main_company', 'subsidiaries'].includes(rel.name))

    return relationshipNames.map(rel => rel.name)
  },
  relationshipMetaDataLists (state) {
    return state.metaDataLists
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
}
