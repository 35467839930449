<template>
  <NewCard title="Interact (Forum)">
    <p>The graph below shows the evolution of total posts.</p>

    <div v-if="posts" class="chart-container">
      <div class="chart-main">
        <SimpleLineChart :data="postsChartData" :show-final-value="true"/>
      </div>
      <div class="chart-aside">
        <ChartLegend :data="postsChartData" :show-final-value="true"/>
      </div>
    </div>

    <br>
    <p>The graph below shows the evolution of total posts interactions.</p>
    <div v-if="postsInteractions" class="chart-container">
      <div class="chart-aside chart-aside-left">
        <ChartLegend :data="postsInteractionsChartData" :show-final-value="true"/>
      </div>
      <div class="chart-main chart-main-right">
        <SimpleLineChart :data="postsInteractionsChartData"/>
      </div>
    </div>

    <br>
    <div v-if="mostPopularPages">
      <table class="table table--fill table--hover table--striped table--borderless">
        <thead>
        <tr>
          <td colspan="4"><strong>Most popular announcements / events</strong></td>
        </tr>
        </thead>
        <tbody>
        <tr>
          <td><strong>Title</strong></td>
          <td><strong>Type</strong></td>
          <td><strong>Page views</strong></td>
          <td><strong>Unique Visits</strong></td>
        </tr>
        <tr v-for="page in mostPopularPages">
          <td><a :href="convertUrl(page.url)">{{ page.notification.title ?? convertUrl(page.url) }}</a></td>
          <td>{{ page.notification.type ? page.notification.type : 'unknown' }}</td>
          <td>{{ page.visits }}</td>
          <td>{{ page.unique_visitors }}</td>
        </tr>
        </tbody>
      </table>
    </div>

  </NewCard>
</template>

<script>
  import NewCard from '../../../components/NewCard/NewCard.vue'
  import EcosystemInfoCard from './EcosystemInfoCard.vue'
  import ChartLegend from './ChartLegend.vue'
  import { parseChartData } from '../../Chart/chartsMixin.js'
  import SimpleLineChart from '../../Chart/SimpleLineChart.vue'

  export default {
    props: {
      posts: Object,
      postsInteractions: Object,
      mostPopularPages: Object,
    },
    components: {
      NewCard,
      EcosystemInfoCard,
      ChartLegend,
      SimpleLineChart,
    },
    computed: {
      postsInteractionsChartData () {
        const datasets = []
        if (!this.postsInteractions) {
          return
        }
        if (this.postsInteractions.total_likes != null) {
          datasets.push({
            color: '#FBA52C',
            data: this.postsInteractions.total_likes_per_month,
            cumulate: true,
            finalValue: this.postsInteractions.total_likes,
            label: 'Total Likes',
          })
        }
        if (this.postsInteractions.total_comments != null) {
          datasets.push({
            color: '#2E59FA',
            data: this.postsInteractions.total_comments_per_month,
            cumulate: true,
            finalValue: this.postsInteractions.total_comments,
            label: 'Total Comments',
          })
        }
        if (this.postsInteractions.total_unique_posters != null) {
          datasets.push({
            color: '#AF52FF',
            data: this.postsInteractions.total_unique_posters_per_month,
            cumulate: true,
            finalValue: this.postsInteractions.total_unique_posters,
            label: 'Total unique posters',
          })
        }
        return parseChartData(datasets)
      },
      postsChartData () {
        const datasets = []

        if (!this.posts) {
          return
        }

        if (this.posts.total_announcements_per_month) {
          datasets.push({
            color: '#FBA52C',
            data: this.posts.total_announcements_per_month,
            finalValue: this.posts.total_announcements,
            cumulate: true,
            label: 'Total Announcements',
          })
        }
        if (this.posts.total_events_per_month) {
          datasets.push({
            color: '#2E59FA',
            data: this.posts.total_events_per_month,
            finalValue: this.posts.total_events,
            cumulate: true,
            label: 'Total Events',
          })
        }
        if (this.posts.total_posts) {
          datasets.push({
            color: '#AF52FF',
            data: this.posts.total_posts,
            cumulate: true,
            finalValue: Number(this.posts.total_events) + Number(this.posts.total_announcements),
            label: 'Total Posts',
          })
        }

        return parseChartData(datasets)
      },
    },
    methods: {
      convertUrl (pageUrl) {
        return `${window.location.protocol}//${window.location.hostname}${pageUrl}`
      },
    },
  }
</script>
