<template>
  <div class="home-simplified static-home-innovaud">
    <div class="background-logo"></div>
    <div class="body">
      <div class="static-home-innovaud__logo">
        <img src="/images/static-home-page/logoInnovaud.png" alt=""/>
      </div>
      <div class="static-home-innovaud__information">
        <h2>We have shut down vaud.digital</h2>
        <p>vaud.digital was introduced in 2018 as a directory of innovative businesses in Vaud’s digital landscape. It grew into a community of over 300 users until May 2022.
          <br>
          <br>

          Vaud’s innovation ecosystem has changed considerably in the past four years, however, as have the needs of Innovaud’s stakeholders. A number of other community-building platforms, such as Trust Valley, have emerged and proved successful in showcasing local high-tech businesses.
          <br>
          <br>
          Innovaud has therefore decided to shut down the vaud.digital directory, effective 13 May 2022. User accounts have been deleted and company profiles are no longer visible or accessible.
          <br>
          <br>

          Innovaud will continue to draw attention to innovative businesses in the canton of Vaud in other ways. If you want to keep in touch with the Innovaud community and stay informed of the latest developments, sign up for our newsletters. You can also contact us for more information (see contact button on this page).
        </p>
      </div>
    </div>
    <SimplifiedFooter style="position: relative; margin-top: 5rem;" class="footer">
    </SimplifiedFooter>
  </div>
</template>


<script>
  import Container from '../Container/Container.vue'
  import SimplifiedFooter from '../SimplifiedFooter/SimplifiedFooter.vue'

  export default {
    components: { Container, SimplifiedFooter },
  }
</script>


<style lang="scss" scoped>
  .static-home-innovaud {
    .background-logo {
      background-image: url("/images/static-home-page/Digital_society.jpeg");
      width: 100%;
      height: 100%;
      position: absolute;
      background-size: cover;
    }

    .body {
      position: relative;
      display: flex;
      flex-direction: column;
      height: 75vh;
      margin: 0;

      p {
        font-weight: 300;
      }
    }

    &__information {
      background-color: rgba(0,0,0,.8);
      color: white;
      padding: 40px 29px;
      width: 60%;
      margin-left: 3%;
      display: flex;
      align-self: center;
      flex-direction: column;

      h2 {
        margin-bottom: 10px;
      }
    }
  }

</style>
