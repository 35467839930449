<template>
  <div id="influencer-chart-div" class="influencer-chart">
    <div v-show="!canShowChart && showLoader" class="spider__loading chart__loading__positioning">
      <icon name="loading" />
    </div>
    <svg ref="influencer" class="influencer-chart__graph"></svg>
  </div>
</template>

<script>
  import * as d3v4 from 'd3'

  import { inert } from '../../util/helpers'

  import { MUTATION_TYPES as BUZZ_MUTATION_TYPES } from '../../store/modules/buzz'
  import { MUTATION_TYPES as MEDIA_BUZZ_MUTATION_TYPES } from '../../store/modules/mediabuzz'

  export default {
    data () {
      return {
        showLoader: true,
        actor: null, // The actor to focus on if it's in the result set
      }
    },
    props: ['chartData', 'isInsideChartCloseup'],
    emits: ['update-data'],
    computed: {
      activeInfluencerId () {
        const existingEntry = this.$store.state.buzz.filters.find(k => k.facet === 'actor')

        return existingEntry && existingEntry.value
      },
      // TODO: move common buzz chart computed properties to a buzz mixin
      chartContainerHeight () {
        // For when the user is in an actor profile
        if (this.currentRoute.path.startsWith('/actors')) {
          // This is the previous itiration of how we would fetch the height of the container that contained the chart
          // Since the placement of the container and the chart has changed, the route that was used to fetch the height was changed as well resulting in the chart
          // not working and displaying at all

        // Previoust iteration
        //return Math.floor(this.$options.parent.$slots.Buzz[0].context.$refs.influencerChartContainer.offsetHeight)

        // Quick fix
        // TODO: Make the chart component less dependend of the $refs, instead try using the $parent object to fetch the containers height
        // The isInsideChartCloseup is for the actor prorfile, since both the individual chart and the closepu component are in the differnte "plane" fields
        // The routing for the parent changes and the way of getting the offsetHeight changes as well
        if (this.isInsideChartCloseup)  {
          return Math.floor(this.$parent.$refs.influencerChartContainer.offsetHeight)
        } else {
          return Math.floor(this.$parent.$parent.$refs.influencerChartContainer.$el.offsetHeight);
        }
      }
      return Math.floor(this.$parent.$refs.influencerChartContainer.offsetHeight)
    },
    chartContainerWidth () {
      // For when the user is in an actor profile
      if (this.currentRoute.path.startsWith('/actors')) {
        // This is the previous itiration of how we would fetch the width of the container that contained the chart
        // Since the placement of the container and the chart has changed, the route that was used to fetch the width was changed as well resulting in the chart
        // not working and displaying at all

        // Previoust iteration
        //return Math.floor(this.$options.parent.$slots.Buzz[0].context.$refs.influencerChartContainer.offsetWidth)

        // Quick fix
        // TODO: Make the chart component less dependend of the $refs, instead try using the $parent object to fetch the containers width
        // The isInsideChartCloseup is for the actor prorfile, since both the individual chart and the closepu component are in the differnte "plane" fields
        // The routing for the parent changes and the way of getting the offsetWidth changes as well
         if (this.isInsideChartCloseup)  {
          return Math.floor(this.$parent.$refs.influencerChartContainer.offsetWidth)
        } else {
          return Math.floor(this.$parent.$parent.$refs.influencerChartContainer.$el.offsetWidth);
        }
      }
      return Math.floor(this.$parent.$refs.influencerChartContainer.offsetWidth)
    },
    filters () {
      return this.$store.state.filters
    },
    canShowChart () {
      return this.chartData.data && this.chartData.data.actors.length > 0
    },
    currentRoute () {
      return this.$route
    }
  },
  methods: {
    render () {
      var data = this.chartData;

      if (!this.canShowChart) {
        this.clearGraph()
        this.errorMessage()
        this.showLoader = false
        return
      }

      if (this.currentRoute.path.startsWith('/buzz') && this.$parent.$refs.influencerChartContainer == undefined) {
        return
      }
      // This is the previous itiration of how we would check if the 'Buzz' slot on the actor profile was activated
      // Previoust iteration
     /* if (this.currentRoute.path.startsWith('/actors') && this.$options.parent.$slots.Buzz[0].context.$refs.influencerChartContainer == undefined) {
        return
      }*/

      var newInfluencerData = inert(data)
      this.clearGraph()
      var thisVue = this

      // The height has to be set ouside the render otherwise the chart will keep growing in size.
      // For when the user is in an actor profile
      if (this.currentRoute.path.startsWith('/actors')) {
        // This is the previous itiration of how we would fetch the width of the container that contained the chart
        // Since the placement of the container and the chart has changed, the route that was used to fetch the width was changed as well resulting in the chart
        // not working and displaying at all

        // Previoust iteration
        //this.outerWidth = Math.floor(this.$options.parent.$slots.Buzz[0].context.$refs.influencerChartContainer.offsetWidth)

        // Quick fix
        // TODO: Make the chart component less dependend of the $refs, instead try using the $parent object to fetch the containers width
        // The isInsideChartCloseup is for the actor prorfile, since both the individual chart and the closepu component are in the differnte "plane" fields
        // The routing for the parent changes and the way of getting the offsetWidth changes as well
        if (this.isInsideChartCloseup)  {
          this.outerWidth = Math.floor(this.$parent.$refs.influencerChartContainer.offsetWidth)
        } else {
          this.outerWidth = Math.floor(this.$parent.$parent.$refs.influencerChartContainer.$el.offsetWidth)
        }
      } else if(this.currentRoute.params.panel == "buzz" || this.currentRoute.params.panel == 'media-buzz') {
        this.outerWidth = Math.floor(this.$parent.$refs.influencerChartContainer.offsetWidth)
      }
      this.outerHeight = this.chartContainerHeight

      var color = '#4262d6',
      minimumColorTransparency = 0.80

      var svg = d3v4.select(this.$refs.influencer);
      var circleSizes = Object.values(data.data.actors).map(item => { return item.volume });
      var rscale = d3v4.scaleLinear()
          .domain([0,d3v4.max(circleSizes)])
          .range([5,40]);
      var curveAngle = 1.8;

      svg.attr("width", this.outerWidth)
          .attr("height", this.outerHeight)
          .attr("style", `transform: scale(0.7, 0.7)`);

      // To center the force pull of the nodes and set the strength of the pull.
      var forceX = d3v4.forceX(this.outerWidth / 2).strength(0.02)
      var forceY = d3v4.forceY(this.outerHeight / 2).strength(0.02)

      var simulation = d3v4.forceSimulation()
          .force("link", d3v4.forceLink().id(function(d) { return d.id; }).distance(50).strength(0.06))
          .force("charge", d3v4.forceManyBody().strength(-15))
          .force("collide", d3v4.forceCollide().radius(function(d) {return rscale(d.volume) + 1.5; }).strength(1).iterations(2))
          .force("center", d3v4.forceCenter(this.outerWidth / 2, this.outerHeight / 2))
          .force('x', forceX)
          .force('y',  forceY)

      // Links that connect the nodes
      var link = svg.append("g").selectAll(".link")
          .data(data.data.connections)
          .enter().append("path")
          .attr("class", function(d, i) { return `link influencer-link-${d.source.id}` })
          .attr("stroke", function(d){
            return color
          })
          .attr("stroke-width", function(d) { return 2 })

      // Nodes that conatins the circle and the text
      var node = svg.append("g")
          .attr("class", "nodes")
          .selectAll(".circleContainer")
          .data(data.data.actors)
          .enter()
          .append("g")
          .call(d3v4.drag()
              .on("start", dragstarted)
              .on("drag", dragged)
              .on("end", dragended));

      // Create the filter element which will contain the drop shadow
      // filters go in defs element
      var defs = svg.append("defs");

      // create filter with id #drop-shadow
      // height=130% so that the shadow is not clipped
      var filter = defs.append("filter")
          .attr("id", "drop-shadow")
          .attr("height", "130%");

      // SourceAlpha refers to opacity of graphic that this filter will be applied to
      // convolve that with a Gaussian with standard deviation 3 and store result
      // in blur
      filter.append("feGaussianBlur")
          .attr("in", "SourceAlpha")
          .attr("stdDeviation", 4)
          .attr("result", "blur");

      // translate output of Gaussian blur to the right and downwards with 2px
      // store result in offsetBlur
      filter.append("feOffset")
          .attr("in", "blur")
          .attr("dx", 0)
          .attr("dy", 0)
          .attr("result", "offsetBlur");

      // overlay original SourceGraphic over translated blurred opacity by using
      // feMerge filter. Order of specifying inputs is important!
      var feMerge = filter.append("feMerge");

      feMerge.append("feMergeNode")
          .attr("in", "offsetBlur")
      feMerge.append("feMergeNode")
          .attr("in", "SourceGraphic");

      // Circles
      var circle = node.append("circle")
        .attr("class", function(d, i) {
          if (thisVue.activeInfluencerId == d.id) {
            return `influencer-text-circle influencer-text-circle-active influencer-text-circle-${i}`
          }
          return `influencer-text-circle influencer-text-circle-${i}`
        })
        .attr("r", function(d) { return rscale(d.volume) })
        .attr("fill", function(d) {
          return color
        })
        .attr("opacity", function(d) {
         if (d.sentiment < minimumColorTransparency) {
            return minimumColorTransparency
          } else {
            return d.sentiment
          }
        })
        .style("filter", function(d) {
          if (thisVue.activeInfluencerId == d.id) {
            return "url(#drop-shadow)"
          }
        })

      // Texts
      var text = node.append("text")
        .text(function(d) { return d.name })
        .attr("dx", function(d){ return this.getComputedTextLength()/2}) //Center text relative to circle
        .attr("dy", function(d) { return -rscale(d.volume)-5 }) //Place text on top of circle
        .attr("class", function(d, i){ return `influencer-text influencer-text-${d.id}`})
        .style("text-anchor", "end")
        .style("fill", "black")
        .style("font-size", function(d) {
          return Math.min(2 * d.r, (2 * d.r - 8) / this.getComputedTextLength() * 12) + "px";
        })

       node.on("mouseover", function(d, i){
          // Animate Circles
          d3v4.selectAll( `.influencer-text-circle`)
              .transition()
              .duration(100)
              .attr("opacity", minimumColorTransparency)

          d3v4.select( `.influencer-text-circle-${i}`)
              .transition()
              .duration(100)
              .attr("opacity", 1)

          //Animate Texts
          d3v4.selectAll( `.influencer-text`)
              .transition()
              .duration(100)
              .attr("opacity", 0.4)

          d3v4.selectAll( `.influencer-text-${d.id}`)
              .transition()
              .duration(100)
              .attr("opacity", 1)

          // Animate links
          d3v4.selectAll( `.link`)
              .transition()
              .duration(100)
              .attr("opacity", 0.1)

          d3v4.selectAll( `.influencer-link-${d.id}`)
              .transition()
              .duration(100)
              .attr("opacity", 1)
      })
       .on("mouseout", function(d, i){
         // Animate Circles
          d3v4.selectAll( `.influencer-text-circle`)
              .transition()
              .duration(100)
              .attr("opacity", function(d) {
                if (d.sentiment < minimumColorTransparency) {
                  return minimumColorTransparency
                } else {
                  return d.sentiment
                }
              })

         // Animate Texts
          d3v4.selectAll( `.influencer-text`)
              .transition()
              .duration(100)
              .attr("opacity", 1)

           // Animate links
          d3v4.selectAll( `.link`)
              .transition()
              .duration(100)
              .attr("opacity", 1)
      })
       .on("click", function(d) {
        if (thisVue.currentRoute.params.panel == "media-buzz") {
          thisVue.$store.commit(MEDIA_BUZZ_MUTATION_TYPES.UPDATE_BUZZ_FILTER, { name: d.name, facet: 'actor', value: d.id });
          thisVue.$emit('update-data')
        } else {
          thisVue.$store.commit(BUZZ_MUTATION_TYPES.UPDATE_BUZZ_FILTER, { name: d.name, facet: 'actor', value: d.id });
          thisVue.$emit('update-data')
        }
       });

      simulation.nodes(data.data.actors)
          .on("tick", ticked);

      simulation.force("link")
          .links(data.data.connections);

      function ticked () {
        // Create curved links
        link.attr("d", function (d) {
          var dx = d.target.x - d.source.x,
          dy = d.target.y - d.source.y;
          var dr = 0; // Link curve value

          if (curveAngle == 0) {
            dr = Math.sqrt(dx * dx + dy * dy)
          } else {
            dr = Math.sqrt(dx * dx + dy * dy) / curveAngle;
          }

          return "M" +
          d.source.x + "," +
          d.source.y + "A" +
          dr + "," + dr + " 0 0,1 " +
          d.target.x + "," +
          d.target.y;
        })

        node
          .attr("cx", function(d) { return d.x; })
          .attr("cy", function(d) { return d.y; });

        circle
          .attr("cx", function(d) { return d.x; })
          .attr("cy", function(d) { return d.y; });

        text
          .attr("x", function(d) { return d.x; })
          .attr("y", function(d) { return d.y; });

        // If there is a selected influencer, set it to be centered in the chart
        if (thisVue.activeInfluencerId) {
          d3v4.selectAll(".influencer-text-circle-active")
              .attr("cx", function(d) { return thisVue.outerWidth/2; })
              .attr("cy", function(d) { return thisVue.outerHeight/2; });

          d3v4.selectAll(".influencer-text-circle-active circle")
              .attr("cx", function(d) { return thisVue.outerWidth/2; })
              .attr("cy",  function(d) { return thisVue.outerHeight/2; });

          d3v4.selectAll(`.influencer-text-${thisVue.activeInfluencerId}`)
              .attr("x", function(d) { return thisVue.outerWidth/2; })
              .attr("y", function(d) { return thisVue.outerHeight/2; });
        }
      }

      function dragstarted (d) {
        //To avoid triggering the click when dragging
        if (d3v4.event.defaultPrevented) { return};

        if (!d3v4.event.active) simulation.alphaTarget(0.3).restart();
        d.fx = d.x;
        d.fy = d.y;
      }

      function dragged(d) {
        d.fx = d3v4.event.x;
        d.fy = d3v4.event.y;
      }

      function dragended(d) {
        if (!d3v4.event.active) simulation.alphaTarget(0);
        d.fx = null;
        d.fy = null;
      }

    },
    errorMessage () {
      var thisVue = this
      var svg = d3v4.select(this.$refs.influencer)
          .attr("width", this.chartContainerWidth)
          .attr("height",  this.chartContainerHeight)
          .attr("style", `transform: scale(0.7, 0.7); display:inherit; margin:auto`);

      var errorMessage = svg.append("g")
          .attr("class", "influencer-chart-error-message")
          .attr("transform", `translate(${ this.chartContainerWidth / 4} , ${ this.chartContainerHeight / 2 })`)

      errorMessage.append("text")
          .text("No related actors found.")
          .attr("opacity", function(d){
          if (thisVue.canShowChart == false) {
            return 1
          } else {
            return 0
          }
        })
    },
    clearGraph () {
      d3v4.select(this.$refs.influencer)
        .selectAll('*')
        .remove()
    }
  },
  watch: {
    chartData (val) {
      if (val) {
        this.render()
      }
    }
  },
  async mounted () {
    this.$bus.on('resize', () => {
      this.render()
    })

    if (this.chartData && this.chartData.data) {
      this.render()
    }
  },
  beforeUnmount () {
    this.clearGraph()
  }
}

</script>
