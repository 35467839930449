<template>
  <div class="actor-suggestions">
    <div class="fixed-heading">
      <div class="card__general side-panel__header">
        <div class="card__title side-panel__actor-suggestions-card__title side-panel__actor-suggestions-header-card__title">Duplicates</div>
        <div class="pull-right side-panel__announcement-header-button-container" style="text-align: right;">
          <ds-button icon="remove" variant="minimal" @click="hideSidePanel" size="small" class="side-panel__announcement-header-icon"/>
        </div>
      </div>
    </div>
    <div class="scrollable side-panel__scrollable__content has-padding" style="padding-bottom: 150px">
      <template v-if="isLoadingDuplicates">
        Searching for duplicates...
      </template>
      <template v-else>
        <p v-if="this.duplicates.length > 0">
          Found {{ this.duplicates.length }} possible {{ this.duplicates.length > 1 ? 'duplicates' : 'duplicate' }}.
        </p>
        <p v-else>
          We found no duplicates for this actor.
        </p>

        <!-- List all of the duplicate actors -->
        <template v-for="(duplicate, index) in duplicates">
          <NewCard :title="duplicate.actor.name" :link="getActorLink(duplicate.actor)" hideHeaderSeparator>
            <div>
              <badge size="extra-small" name="claimed" variant="secondary" class="duplicate-actor__badge" v-if="duplicate.actor.claimed" />
              <div style="margin-left: 0px;">
                The profile of {{ duplicate.actor.name }} <template v-if="duplicate.actor.completeness">is {{ Math.round((duplicate.actor.completeness * 100)) }}%
                complete and</template> has the same {{ humanizedDuplicateProperties(duplicate) }}.
              </div>
              <div class="pull-left"  style="text-align: left; margin-top: 12px;">
                <!--<ds-button label="View actor" size="extra-small"  @click="viewDuplicate(duplicate.actor.id)" />-->
                <ds-button label="Merge" icon="shuffle-variant" size="extra-small" @click="mergeDuplicate(duplicate.actor.id)" />
                <ds-button label="Not a duplicate" size="extra-small" @click="markNotADuplicate(duplicate.actor.id)" v-if="! displaysVirtualDuplicates" :disabled="isBlackListing" />
                <ds-button label="Remove actor" icon="trash" size="extra-small" @click="showDeleteConfirmationModal(duplicate.actor)" />
              </div>
            </div>
          </NewCard>
        </template>
      </template>
    </div>
  </div>
</template>

<script>
  import NewCard from '../../components/NewCard/NewCard.vue'
import Badge from '../../components/Badge/Badge.vue'

import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'

import { fetchDuplicateActors, fetchDuplicateActorsForProperties, blackListDuplicate, removeActor } from '../../api/actors'

import { humanize } from '../../constants/properties'
import MODAL_IDS from '../../constants/modal-ids'
import KEYCODES from '../../constants/keycodes'

export default {
    props: {
      meta: Object,
    },
    data () {
      return {
        duplicates: [],
        isLoadingDuplicates: true,
        deleteContext: null,
        isBlackListing: false,
      }
  },
    computed: {
      actor () {
        return this.$store.state.actors.detail.data
      },
      displaysVirtualDuplicates () {
        // Returns true if the listed duplicates are actual conflicting duplicate actors.
        // There are 2 use cases:
        // - actual conflicting actors (i.e. same company number)
        // - suggested url that already exists, or a company number that was edited in the actor edit panel that was already present
        return !!this.meta.duplicatesAreVirtual
      }
    },
    methods: {
      viewDuplicate (actorId) {
        window.open(window.location.protocol + '//' + window.location.host + '/actors/' + actorId, '_blank')
      },
      mergeDuplicate (actorId) {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'actor-merge-panel', metaData: { ...this.meta, actorId: actorId }})
      },
      hideSidePanel (evt) {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      getActorLink (actor) {
        return window.location.protocol + '//' + window.location.host + '/actors/' + actor.id
      },
      showDeleteConfirmationModal (actor) {
        this.deleteContext = { ...actor, modalContextType: 'actor' }
        this.$store.commit('UI/SET_MODAL_CONTEXT', this.deleteContext)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
      },
      async denyActorRecommendation (actorId) {
        await removeActor(actorId)

        this.isLoadingDuplicates = true

        fetchDuplicateActors(this.actor.id)
        .then(data => {
          this.duplicates = data
          this.isLoadingDuplicates = false
        })
        .catch(err => {
          this.isLoadingDuplicates = false
        })
      },
      humanizedDuplicateProperties (duplicate) {
        if (duplicate.properties.length === 1) {
          return humanize(duplicate.properties[0])
        }

        const humanized = duplicate.properties.map(p => humanize(p))
        return humanized.slice(0, -1).join(', ') + ' and ' + humanized.slice(-1)[0]
      },
      async markNotADuplicate (duplicateActorId) {
        this.isBlackListing = true

        await blackListDuplicate(this.actor.id, duplicateActorId)

        this.isLoadingDuplicates = true
        this.isBlackListing = false

        fetchDuplicateActors(this.actor.id)
        .then(data => {
          this.duplicates = data
          this.isLoadingDuplicates = false

        // Fetch the actor detail, it may be that it's no longer a duplicate
          this.$store.dispatch('ACTORS/FETCH_ACTOR_DETAIL', true)
        })
        .catch(err => {
          this.isLoadingDuplicates = false
        })
      }
    },
    mounted () {
      if (!this.meta.duplicateInput) {
        fetchDuplicateActors(this.actor.id)
          .then(data => {
            this.duplicates = data
            this.isLoadingDuplicates = false
          })
          .catch(err => {
            this.isLoadingDuplicates = false
          })
      } else {
        fetchDuplicateActorsForProperties(this.actor.id, this.meta.duplicateInput)
          .then(data => {
            this.duplicates = data
            this.isLoadingDuplicates = false
          })
          .catch(err => {
            this.isLoadingDuplicates = false
          })
      }

      window.addEventListener('keyup', (e) => {
        if (e.keyCode === KEYCODES.ESC) {
          this.hideSidePanel()
        }
      })
  },
    created () {
      this.$bus.on('actorDeleteConfirmation', (context) => {
        // Be absolutely sure that we remove the actor that we just got confirmation for to delete
        if (context.id && context.id === this.deleteContext.id) {
          this.denyActorRecommendation(context.id)
        }
      })
  },
    beforeUnmount () {
      this.$bus.off('actorDeleteConfirmation')
  },
    components: {
      NewCard,
      Badge
    }
  }
</script>

<style lang="scss">
@import "../../../scss/variables";

.duplicate-actor__badge {
  font-size: 11px !important;
  min-height: 14px !important;
  line-height: 15px !important;
}

</style>
