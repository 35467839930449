<template>
  <div
    class="tree-root"
    :class="{'accepting-drop': isAcceptingDrop}"
    @dragover="handleDragOver"
    @dragenter="handleDragEnter"
    @dragleave="handleDragLeave"
    @drop="handleDrop"
  >
    <div
      :draggable="!root.disableDrag"
      @dragstart="handleDragStart"
      class="item-line"
    >
      <icon :name="collapsed ? 'chevron-right' : 'chevron-down'" @click="handleToggleCollapsed" class="icon"
        :class="root.children !== undefined && !atMaximumDepth ? '' : 'hidden'"/>
      <span v-if="!isEditing" class="name-span">
        <template v-if="this.root.translations">
          <template v-if="hasLocaleValue">{{ currentLocaleValue }}</template>
          <span class="other-locale-value" v-else>{{ otherLocaleValue }}</span>
        </template>
        <template v-else>{{ this.root.name }}</template>

        <template v-if="this.root.defaultTaxonomyValue">
          <span style="color: #AAAAAA;">  ({{ this.root.defaultTaxonomyValue }})</span>
        </template>
      </span>
      <input v-else v-model="nameInput" :placeholder="placeholder" class="name-input" ref="nameInput" @keydown="handleNameInputKeydown" @blur="handleNameInputBlur"/>
      <div class="buttons" v-if="isOwner">
        <icon class="icon" name="edit" @click="handleEditClick" v-if="!root.disableEdit"/>
        <icon class="icon" name="trash" @click="handleDeleteClick" v-if="!root.disableDelete"/>
        <icon class="icon add-child" name="plus" @click="handleAddChildClick" v-if="!atMaximumDepth && !root.disableAddChild"/>
      </div>
    </div>

    <div v-if="root.description" class="description">{{ root.description }}</div>

    <div class="children" v-if="!collapsed && (isAddingChild || (root.children && root.children.length > 0))">
      <input class="child-input" v-if="isAddingChild" v-model="childNameInput" ref="childNameInput" @keydown="handleChildNameInputKeydown" @blur="handleChildNameInputBlur"/>
      <tree
        v-for="child in root.children"
        :bus="bus"
        :locale="locale"
        :root="child"
        :path="[...path, child.id]"
        :maxDepth="maxDepth"
        :key="child.id"
      />
    </div>
  </div>
</template>

<script>
  import objectPath from 'object-path'

  export default {
    name: 'tree',
    props: {
      bus: {
        type: Object,
      },
      root: {
        type: Object,
      },
      description: {
        type: String,
      },
      path: {
        type: Array,
      },
      maxDepth: {
        type: Number,
      },
      locale: {
        type: String,
      },
    },
    data () {
      return {
        nameInput: '',
        childNameInput: '',
        isAcceptingDrop: false,
        isEditing: false,
        isAddingChild: false,
        collapsed: true,
      }
    },
    computed: {
      isOwner () {
        return this.$store.getters.isOwner
      },
      atMaximumDepth () {
        return this.path.length >= this.maxDepth
      },
      hasLocaleValue () {
        return objectPath.has(this.root, `translations.${this.locale}.name`)
      },
      currentLocaleValue () {
        return this.root.translations[this.locale].name
      },
      otherLocaleValue () {
        for (const locale of this.$store.state.localization.enabledLocales) {
          if (this.root.translations[locale] && this.root.translations[locale].name) {
            return `${this.root.translations[locale].name} (${locale})`
          }
        }

        return this.root.name
      },
      placeholder () {
        return this.hasLocaleValue ? this.currentLocaleValue : this.otherLocaleValue
      },
    },
    methods: {
      handleToggleCollapsed () {
        this.collapsed = !this.collapsed
      },
      handleDragStart (event) {
        event.dataTransfer.setData(DRAG_MIME_TYPE, JSON.stringify(this.path))
        event.dataTransfer.dropEffect = 'move'
        this.bus.emit('drag-start', { path: this.path })
      },
      handleDragEnter (e) {
        if (!this.isValidDragEvent(e)) {
          return
        }

        e.preventDefault()
        e.stopPropagation()
        this.isAcceptingDrop = true
      },
      handleDragOver (e) {
        if (!this.isValidDragEvent(e)) {
          return
        }

        e.preventDefault()
        e.stopPropagation()
        this.isAcceptingDrop = true
      },
      handleDragLeave (e) {
        this.isAcceptingDrop = false
      },
      handleDrop (e) {
        if (!this.isValidDragEvent(e)) {
          return
        }

        e.preventDefault()
        e.stopPropagation()
        this.isAcceptingDrop = false

        const prevPath = JSON.parse(e.dataTransfer.getData(DRAG_MIME_TYPE))
        const nextPath = this.path
        this.bus.emit('move', { prevPath, nextPath })
      },
      handleEditClick () {
        this.nameInput = objectPath.get(this.root, `translations.${this.locale}.name`, '')
        this.isEditing = true

        setTimeout(() => {
          this.$refs.nameInput.select()
        }, 0)
      },
      handleNameInputKeydown (e) {
        if (e.key === 'Enter') {
          e.preventDefault()
          e.stopPropagation()
          this.finishEditing()
        } else if (e.key === 'Escape') {
          e.preventDefault()
          e.stopPropagation()
          this.cancelEditing()
        }
      },
      handleNameInputBlur () {
        this.finishEditing()
      },
      finishEditing () {
        if (!this.isEditing) return
        this.isEditing = false
        this.bus.emit('rename', { path: this.path, locale: this.locale, name: this.nameInput })
      },
      cancelEditing () {
        if (!this.isEditing) return
        this.isEditing = false
      },
      handleAddChildClick () {
        this.childNameInput = ''
        this.isAddingChild = true
        this.collapsed = false

        setTimeout(() => {
          this.$refs.childNameInput.select()
        }, 0)
      },
      handleChildNameInputKeydown (e) {
        if (e.key === 'Enter') {
          e.preventDefault()
          e.stopPropagation()
          this.finishAddingChild()
        } else if (e.key === 'Escape') {
          e.preventDefault()
          e.stopPropagation()
          this.cancelAddingChild()
        }
      },
      handleChildNameInputBlur () {
        this.finishAddingChild()
      },
      finishAddingChild () {
        if (!this.isAddingChild) return
        this.isAddingChild = false
        this.bus.emit('add', { path: this.path, locale: this.locale, name: this.childNameInput })
      },
      cancelAddingChild () {
        if (!this.isAddingChild) return
        this.isAddingChild = false
      },
      handleDeleteClick () {
        this.bus.emit('delete', { path: this.path })
      },
      isValidDragEvent (e) {
        if (this.root.disableDrop) {
          return false
        }

        if (this.atMaximumDepth) {
          return false
        }

        return true
      },
    },
  }

  const DRAG_MIME_TYPE = 'application/'
</script>

<style lang="scss" scoped>
  .children {
    padding-left: 20px;
  }

  .tree-root {
    border: 1px solid transparent;
    padding-bottom: 5px;
    user-select: none;
  }

  .accepting-drop {
    border: 1px solid var(--primary-lighter);

    > .item-line > .name-span {
      color: var(--primary-lighter);
    }
  }

  .name-input {
    font-size: inherit;
    padding: 0 6px;
    position: relative;
    left: -7px;
    width: 300px;
  }

  .item-line {
    display: flex;
    margin-bottom: 5px;
    height: 24px;
    line-height: 24px;
  }

  .add-child {
    margin-left: 10px;
  }

  .hidden {
    visibility: hidden;
  }

  .buttons {
    margin-left: 10px;
  }

  .item-line {
    .buttons {
      display: none;
    }

    &:hover {
      background-color: #f3f3f3;

      .buttons {
        display: block;
      }
    }
  }

  .icon {
    cursor: pointer;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .description {
    margin-left: 20px;
    color: #aaa;
    font-size: 85%;
    margin-bottom: 10px;
  }

  .child-input {
    margin-left: 20px;
    margin-bottom: 10px;
    font-size: inherit;
    padding: 0 6px;
    position: relative;
    left: -7px;
    width: 300px;
  }

  .other-locale-value {
    color: #ccc;
    font-style: italic;
  }
</style>
