<template>
  <div class="ds-input" :class="{ 'ds-input--has-icon': icon }">
    <div class="keyword-search-bar-icon keyword-search-bar-container" v-if="icon">
      <icon :name="icon" />
    </div>
    <div class="keyword-search-bar-tags keyword-search-bar-container" v-if="keywords.length > 0 && keywords.find((f) => f.facet !== 'actor_type')">
      <template v-for="(keyword, index) in keywords">
        <keyword-with-facet
          :key="index"
          v-if="keyword.value !== '-' && canShowTag(index) && keyword.facet !== 'actor_type' && keyword.facet !== 'legendProperty'"
          :label="keyword.value"
          :model-value="keyword"
          removeable
          @remove="handleRemoveFilter"
        />
      </template>
    </div>

    <div class="keyword-search-bar__search-bar keyword-search-bar-container">
      <suggestion-input
        :placeholder="placeholder"
        :api-settings="keywordSuggestionSettings.api"
        :renderResults="keywordSuggestionSettings.renderResults"
        :clear-on-select="true"
        @on-select="handleAddFilter"
      />
    </div>
    <div class="keyword-search-bar__links keyword-search-bar-container" v-if="displayDeleteAllFilters">
        <a @click="clearAllFilters" style="text-decoration: none;">Delete All</a>
        <a @click="showMoreModal" v-if="keywords.length > filterTagNumberLimit">View more</a>
      </div>
  </div>
</template>

<script>
  import Keyword from '../Keyword/Keyword.vue'
  import KeywordWithFacet from '../Keyword/KeywordWithFacet.vue'
  import SuggestionInput from '../SuggestionInput/SuggestionInput.vue'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'
  import escapeHTML from '../../util/escapeHTML'
  import FiltersMixin from '../../util/FiltersMixin'
  import { defineComponent } from 'vue'

  function reduceAmbiguity (results) {
    // Calculate titles
    results.forEach((result, i) => {
      result.title = result.display ? result.display.slice(1 + result.display.lastIndexOf(',')) : result.value
    })
    const titles = results.map(r => r.title)

    // Set result.title and find duplicates
    const duplicates = []
    titles.forEach((title, i) => {
      if (titles.indexOf(title) !== i && duplicates.indexOf(title) < 0) {
        duplicates.push(title)
      }
    })

    // Update result.title if necessary
    if (duplicates.length) {
      results.forEach(result => {
        if (duplicates.includes(result.title) && result.display) {
          const parts = result.display.split(',').reverse()

          if (parts[1]) {
            result.title = parts[0] + ', ' + parts[1]
          }
        }
      })
    }

    return results
  }

  export default defineComponent({
    name:"keyword-suggestion-search-bar",
    data: () => ({
      keywordSuggestionSettings: {
        api: {
          url: '/api/keywords?value={query}',
          // TODO: filter out values that are already selected as a keyword
          onResponse: data => ({ results: Object.values(data) })
        },
        renderResults (response) {
          // Hack: Added `r.facet` because IE11 iterates over the Array.find polyfill
          var hasTitle = false;
          var storedTitle = "";

          response.results = response.results.filter(result => result.facet !== undefined && result.facet !== null);

          // First the returned valeus are sorted
          function orderArray(a, b) {
            const facetA = a && a.facet ? a.facet.toUpperCase() : '';
            const facetB = b && b.facet ? b.facet.toUpperCase() : '';

            if (facetA < facetB) { return -1; }
            if (facetA > facetB) { return 1; }

            return 0;
          }

          reduceAmbiguity(response.results).sort(orderArray);

          var orderedList = reduceAmbiguity(response.results)

          return orderedList.map(r => {
            if(r.facet == storedTitle){
              hasTitle = true
            } else {
              hasTitle = false
            }
            // If there is no title for the current array value, its added the title and the first item in the group, otherwise the first item for each group is not included in the dropdown
            if (!hasTitle) {
              storedTitle = r.facet
              return r.facet && `
              <div class="keyword-search-bar__dropdown-group-title">
                ${ r.facet == 'geoid' ? 'location' : escapeHTML( this.humanizeFacet(r.facet) ) }
              </div>
              <a class="result">
                <span class="keyword-search-bar__dropdown-item">${escapeHTML(r.title)}</span>
              </a>`
            }
            // If there is already a title for the group, only the group item is added to the dropdown
            return r.facet && `
              <a class="result">
                <span class="keyword-search-bar__dropdown-item">${escapeHTML(r.title)}</span>
              </a>`
          })
        }
      },
    }),
    props: {
      icon: {
        type: String
      },
      modelValue: null,
      placeholder: null,
      type: {
        type: String,
        default: 'text'
      },
      name: {
        type: String
      },
      precision: {
        default: 0
      },
      readonly: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      filterTagNumberLimit: {
        type: Number,
        default: 5
      }
    },
    computed: {
      displayDeleteAllFilters () {
        return (this.keywords.length > 0 && this.keywords.find((f) => f.facet !== 'actor_type'))
          || this.$store.state.filters.employeeRange.employeeLowerBound
          || this.$store.state.filters.employeeRange.employeeUpperBound
          || this.$store.state.filters.totalFunding.minTotalFunding
          || this.$store.state.filters.totalFunding.maxTotalFunding
          || this.$store.state.filters.patentCountRange.patentCountMin
          || this.$store.state.filters.patentCountRange.patentCountMax
          || this.$store.state.filters.relationshipCountRange.relationshipCountMin
          || this.$store.state.filters.relationshipCountRange.relationshipCountMax
          || this.$store.state.filters.revenueRange.revenueMin
          || this.$store.state.filters.revenueRange.revenueMax
          || this.$store.state.filters.dateRanges.foundingDateEnd
          || this.$store.state.filters.dateRanges.foundingDateStart
          || this.$store.state.filters.dateRanges.fundingDateEnd
          || this.$store.state.filters.dateRanges.fundingDateStart
          || this.$store.state.filters.customScoreRange.customScoreMin
          || this.$store.state.filters.customScoreRange.customScoreMax
          || this.$store.state.filters.portfolio
          || this.reportFiltersActive;
      },
      reportFiltersActive () {
        if (!this.$store.getters.reportSettings || this.$store.getters.reportSettings.length === 0 || !this.$store.state.filters.reportFilters) {
          return false
        }

        var reportFilterEnabled = false

        for (var key in this.$store.state.filters.reportFilters) {
          if (this.$store.state.filters.reportFilters[key] && this.$store.state.filters.reportFilters[key].length > 0) {
            reportFilterEnabled = true
          }
        }

        return reportFilterEnabled
      },
      enabled () {
        if (this.$store.getters.isWear && this.$route.query.actor_type === '') {
          return this.$store.state.config.filterControls.filter(f => !['founding_date'].includes(f))
        }
        return this.$store.state.config.filterControls || []
      },
      dateRanges () {
        return this.$store.state.filters.dateRanges
      },
      foundingDateRange () {
        return {start: this.dateRanges.foundingDateEnd, end: this.dateRanges.foundingDateStart}
      },
      fundingDateRange () {
        return {start: this.dateRanges.fundingDateEnd, end: this.dateRanges.fundingDateStart}
      },
      keywords () {
        return this.$store.state.filters.keywords
      },
    },
    methods: {
      handleAddFilter (item) {
        this.$emit('addedFilter', item)
      },
      handleRemoveFilter (item) {
        this.$emit('removedFilter', item)
      },
      showMoreModal () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.MORE_FILTER_TAGS)
      },
      clearAllFilters () {
        this.$emit('clearAll')
      },
      canShowTag (index) {
        return (index + 1) <= this.filterTagNumberLimit
      }
    },
    watch: {
      foundingDateRange (rangeData) {
        // We check if the founding time frame has changed, if so then we add the keyword tag in order to display it in the search bar
        // We only add the keyword if in the keywords list we don't have an item with the value 'Founding'
        var foundingRangeIndex = -1;

        this.keywords.forEach((item, index) => {
          if (item.value == 'Founding') {
            foundingRangeIndex = index
          }
        })
        if (rangeData.start !== null || rangeData.end !== null) {
          if (foundingRangeIndex < 0) {
            this.handleAddFilter({ facet: 'Timeframe', value: 'Founding' })
          }
        } else {
          if (foundingRangeIndex > -1) {
            this.handleRemoveFilter(this.keywords[foundingRangeIndex])
          }
        }
      },
      fundingDateRange (rangeData) {
        // We check if the funding time frame has changed, if so then we add the keyword tag in order to display it in the search bar
        // We only add the keyword if in the keywords list we don't have an item with the value 'Funding'
        var fundingRangeIndex = -1;

        this.keywords.forEach((item, index) => {
          if (item.value == 'Funding') {
            fundingRangeIndex = index;
          }
        })
        if (rangeData.start !== null || rangeData.end !== null) {
          if (fundingRangeIndex < 0) {
            this.handleAddFilter({ facet: 'Timeframe', value: 'Funding' });
          }
        } else {
          if (fundingRangeIndex > -1) {
            this.handleRemoveFilter(this.keywords[fundingRangeIndex]);
          }
        }
      }
    },
    mounted () {
      // We check if the founding time frame has changed, if so then we add the keyword tag in order to display it in the search bar
      // We only add the keyword if in the keywords list we don't have an item with the value 'Founding'
      var foundingRandeIndex = -1;

      this.keywords.forEach((item, index) => {
        if (item.value == 'Founding') {
          foundingRandeIndex = index;
        }
      })
      if (this.foundingDateRange.start !== null || this.foundingDateRange.end !== null) {
        if (foundingRandeIndex < 0) {
          this.handleAddFilter({ facet: 'Timeframe', value: 'Founding' });
        }
      } else {
        if (foundingRandeIndex > -1) {
          this.handleRemoveFilter(this.keywords[foundingRandeIndex]);
        }
      }

      // We check if the funding time frame has changed, if so then we add the keyword tag in order to display it in the search bar
      // We only add the keyword if in the keywords list we don't have an item with the value 'Funding'
      var fundingRandeIndex = -1;

      this.keywords.forEach((item, index) => {
        if (item.value == 'Funding') {
          fundingRandeIndex = index;
        }
      });

      if (this.fundingDateRange.start !== null || this.fundingDateRange.end !== null) {
        if (fundingRandeIndex < 0) {
          this.handleAddFilter({ facet: 'Timeframe', value: 'Funding' });
        }
      } else {
        if (fundingRandeIndex > -1) {
          this.handleRemoveFilter(this.keywords[fundingRandeIndex]);
        }
      }
    },
    mixins: [FiltersMixin],
    components: {
      Keyword,
      KeywordWithFacet,
      SuggestionInput
    }
})
</script>
