<template>
  <div class="page-restricted page-authentication">
    <restricted-modal :is-closeable="false"></restricted-modal>
  </div>
</template>

<script>
import RestrictedModal from '../components/Modals/RestrictedModal.vue'

export default {
  components: {
    RestrictedModal
  }
}
</script>
