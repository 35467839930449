<template>
  <div class="global-search__navigation">
    <div class="global-search__navigation-items">
      <template v-for="(item, index) in navigationItems" :key="'-item' + index">
        <a
          :class="{'selected_global-search__item': modelValue === item.type}"
          class="global-search__navigation-item" @click="selectNavigationItem(item)" v-if="canShowNavigationItem(item)"
        >
          <icon style="top: 2px;" class="icon" :name="tabIcons[item.type]" size="22"/>
          <h4 v-if="!query && isMobile" style="font-weight: normal;">{{ $t('global_search_recently_viewed') }}</h4>
          <p>{{ item.name }}</p>
        </a>
      </template>
    </div>
    <div class="global-search__userBack" v-if="!isMobile">
      <p>{{ $t('help_title_feedback') }}
        <a @click="openFeedbackWindow(locale)">{{ $t('help_global_search_feedback').replace(' ', '&nbsp;') }}</a>
      </p>
    </div>
    <div class="global-search__userBack" style="position: fixed; height: 50px; bottom: 0px;" v-if="isMobile && query">
      <p>{{ $t('help_title_feedback') }}
        <a @click="openFeedbackWindow(locale)">{{ $t('help_global_search_feedback').replace(' ', '&nbsp;') }}</a>
      </p>
    </div>
  </div>
</template>


<script>
  import { GLOBAL_SEARCH_RESULT_TYPE_ICONS, GLOBAL_SEARCH_RESULT_TYPES } from '../../../util/GlobalSearchResultTypes.js'
  import TranslationsMixin from '../../../util/TranslationsMixin.js'
  import UiMixin from '../../../util/UiMixin.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui.js'

  export default {
    name: 'GlobalSearchNavigation.vue',
    emits: ['update:modelValue', 'close'],
    props: {
      type: String,
      title: String,
      modelValue: String,
      result: Object,
    },
    data () {
      return {
        GLOBAL_SEARCH_RESULT_TYPE_ICONS,
      }
    },
    computed: {
      isMobile () {
        return this.ui.isMobile
      },
      tabIcons () {
        return { ...GLOBAL_SEARCH_RESULT_TYPE_ICONS, back: 'arrow-left' }
      },
      tabs () {
        if (this.ui.isMobile) {
          return { ...GLOBAL_SEARCH_RESULT_TYPES, BACK: 'back' }
        }
        return GLOBAL_SEARCH_RESULT_TYPES
      },
      query () {
        return this.$store.state.ui.globalSearch.query
      },
      navigationItems () {
        if (!this.query) {
          return [
            {
              name: '',
              type: this.tabs.BACK,
            }]
        }
        return [
          {
            name: 'test',
            type: this.tabs.BACK,
          },
          {
            name: 'All Categories',
            type: this.tabs.ALL,
          },
          {
            name: 'Actors',
            type: this.tabs.ACTOR,
          },
          {
            name: this.productsLabel,
            type: this.tabs.PRODUCT,
          },
          {
            name: this.announcementsLabel,
            type: this.tabs.ANNOUNCEMENT,
          },
          {
            name: this.eventsLabel,
            type: this.tabs.EVENT,
          },
          {
            name: this.challengesLabel,
            type: this.tabs.CHALLENGE,
          },
          {
            name: 'Articles',
            type: this.tabs.ARTICLE,
          },
          {
            name: 'Resources',
            type: this.tabs.RESOURCE,
          },
        ]
      },
      userCanAddProduct () {
        return this.$store.getters.userCanCreate && this.$store.getters.claimableActorTypes && this.$store.getters.claimableActorTypes.includes('Product')
      },
      viewActorTypes () {
        return this.$store.getters.viewActorTypes
      },
      hasAccessToKnowledgeBase () {
        return this.$store.getters.hasAccessToKnowledgeBase && this.config.viewDashboard && this.config.viewDashboard.includes('knowledge-base')
      },
      displayAnnouncements () {
        return this.$store.getters.canUsePublicAnnouncements && this.$store.getters.areMessageBoardsEnabled
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      config () {
        return this.$store.state.config
      },
      shouldSeeChallenges () {
        if (this.$store.getters.isMember) {
          return true
        }

        return this.$store.getters.canCreateChallenge ||
          this.$store.getters.viewableChallengeStatusses.length > 0 ||
          this.$store.getters.accessibleChallengeStatusses.length > 0
      },
      displayChallenges () {
        return this.$store.state.config.challengesAreEnabled && this.shouldSeeChallenges
      },
      displayExploreContent () {
        const hasAccess = (this.$store.getters.hasAccessToExploration || this.$store.getters.hasAccessToMonitoring)

        return this.$store.getters.isLoggedIn && hasAccess && this.config.viewDashboard && this.config.viewDashboard.includes('explore-content')
      },
      displayArticlesInKnowledgeBase () {
        return this.config.viewDashboard && this.config.viewDashboard.includes('knowledge-base') && this.$store.getters.hasAccessToAllKnowledgeBaseContent
      },
    },
    methods: {
      selectNavigationItem (item) {
        if (item.type === 'back') {
          this.$emit('close')
        } else {
          this.$emit('update:modelValue', item.type)
        }
      },
      hide () {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_GLOBAL_SEARCH)
      },
      openFeedbackWindow () {
        document.querySelector('.userback-button-e').click()
      },
      canShowNavigationItem (item) {
        switch (item.type) {
          case this.tabs.PRODUCT:
            return this.viewActorTypes.includes('Product')
          case this.tabs.ANNOUNCEMENT:
          case this.tabs.EVENT:
            return this.displayAnnouncements
          case this.tabs.RESOURCE:
            return this.hasAccessToKnowledgeBase
          case this.tabs.ARTICLE:
            return this.displayArticlesInKnowledgeBase || this.displayExploreContent
          case this.tabs.CHALLENGE:
            return this.displayChallenges
          case this.tabs.ALL:
          case this.tabs.ACTOR:
            return true
          case this.tabs.BACK:
            return this.ui.isMobile
        }
      },
    },
    mixins: [TranslationsMixin, UiMixin],
  }
</script>


<style lang="scss" scoped>
  @import "../../../../scss/variables.scss";

  .global-search__navigation {
    background-color: $color-background-lightest-grey;
    min-width: 225px;
    border-radius: 0 0 0 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: $screen-md-max) {
      height: $global-search-height;
    }


    .global-search__navigation-item {
      display: flex;
      cursor: pointer;
      padding: 20px 0 20px 20px;
      flex: 1;

      &.selected_global-search__item, &:hover {
        background-color: $color-background-light-grey;
        margin: 0;
      }

      p {
        color: var(--primary);
        margin-left: 10px;
        font-size: 14px;

        @media (max-width: $screen-md-max) {
          display: none;
        }
      }


      @media (max-width: $screen-md-max) {
        float: left;
        padding: 27px;
        cursor: pointer;

        h4 {
          margin: 8px 10px 0 16px;
          color: black;
        }
      }

      .svg-icon {
        fill: var(--primary);

        :deep(.fill-fg) {
          fill: var(--primary) !important;
        }
      }
    }

    .global-search__navigation-items {
      padding-bottom: 20px;

      @media (max-width: $screen-md-max) {
        display: inline-block;
        padding-bottom: 0;
      }
    }

    .global-search__userBack {
      color: black;
      padding: 0 20px 20px 20px;
      width: 100%;
      white-space: break-spaces;

      @media (max-width: $screen-md-max) {
        border-top: 2px solid $color-borders;
        background-color: #fff;
        height: 20px;
        z-index: 2;

        p {
          margin-top: 15px;
        }
      }

      p {
        margin-bottom: 5px;
      }

      a {
        text-decoration: underline;
        cursor: pointer;
      }
    }



    .icon {
      @media (max-width: $screen-md-max) {
        height: 18px;
        width: 18px;
      }
    }
  }

  @media (max-width: $screen-md-max) {
    .global-search__navigation {
      border-radius: 0;
      flex: 1;
    }
  }

</style>
