<template>
  <div class="top-filters knowledge-base_top-filters" v-if="displayFilters">
    <knowledge-base-keyword-suggestion-search-bar placeholder="Search on keyword, locations, tags..." :model-value="activeFreeTextFilterValue" icon="search" class="top-filters__search-bar knowledge-base_top-filters_suggestion-search-bar" @addedFilter="handleAddKeyword" @removedFilter="handleRemoveKeyword" @clearAll="clearAllFilters"/>
    <div class="knowledge-base_top-filters_media-type-tags-container" style="padding: 1em 1.5em">
      <Tag v-for="(mediaType, index) in formattedMediaTypeProperties" :key="index" :label="getMediaTagLabel(mediaType)" @click="toggleMediaTypeKeyword(mediaType)" :class="{ 'knowledge-base_top-filters_active-media-type-tag' : activeMediaTypeTags.includes(mediaType.value) } "/>
    </div>
  </div>
  </template>

  <script>

    import FiltersDropdown from '../Dropdown/FiltersDropdown.vue'
    import HierarchyDropdown from '../Dropdown/HierarchyDropdown.vue'
    import KnowledgeBaseKeywordSuggestionSearchBar from '../SearchBar/KnowledgeBaseKeywordSuggestionSearchBar.vue'
    import Tag from '../Tag/Tag.vue'
    import { knowledgeBaseContentTypes } from '../../constants/config'
    import FiltersMixin from '../../util/FiltersMixin'
    import { humanize } from '../../constants/properties'

    import { getFacetValue } from './Filters'
    import TranslationsMixin from '../../util/TranslationsMixin.js'
    import { defineComponent } from 'vue'

    export default defineComponent({
      name: 'top-filter',
      data () {
        return {
          defaultSelection: {
            legends: true,
            membership: true,
            stages: true,
          },
        }
      },
    computed: {
      filters () {
        return this.$parent.filters;
      },
      mediaTypeFacets () {
        return this.$parent.mediaTypeFacets || []
      },
      formattedMediaTypeProperties () {
        var knowledgeBaseMediaData = []

        knowledgeBaseContentTypes.forEach(mediaType => {

          // The 'private_resource' and 'public_resource' types are not to be displayed so they are ignored
          if (mediaType == 'private_resource' || mediaType == 'public_resource') {
            return
          }

          if (this.mediaTypeFacets.filter(item => item.value == mediaType).length > 0) {
            knowledgeBaseMediaData = knowledgeBaseMediaData.concat(this.mediaTypeFacets.filter(item => item.value == mediaType))
          } else {
            if (mediaType == 'resource') {
              // The media type resource is the sum of both private and public resources
              knowledgeBaseMediaData.push({facet: 'media_types', value: mediaType, counter: this.privatePublicResourcesSum })
            } else {
              knowledgeBaseMediaData.push({facet: 'media_types', value: mediaType, counter: 0 })
            }
          }
        })

        // We filter out items that contain a counter value of 0
        knowledgeBaseMediaData = knowledgeBaseMediaData.filter(item => item.counter !== 0)

        return knowledgeBaseMediaData
      },
      activeMediaTypeTags () {
        return this.filters.map(item => {
          if(item.facet == 'media_types') {
            // If the either of the 'private_resource' or 'public_resource' are part of the filters we set the 'resource' as being active
            if (item.value == 'private_resource' || item.value == 'public_resource') {
              return 'resource'
            }
            return item.value
          }
        })
      },
      activeFreeTextFilterValue () {
        var freeTextValue = ''
        this.filters.map(item => {
          if(item.facet == 'free_text') {
            freeTextValue = item.value
          }
        })

        // We use this to set the value of the input field.
        // If there is an active free text filter we display that value in the input field.
        return freeTextValue
      },
      config () {
        return this.$store.state.config
      },
      displayFilters () {
        return this.$store.state.filters.filter.visible
      },
      hasFilterControls () {
        var filterControlsList = ["category", "membership", "stage", "activities", "industries", "technology", "domains", "maturity", "type"]

        if (! this.config.filterControls) {
          return []
        }

        return this.config.filterControls.filter(item => filterControlsList.indexOf(item) > -1)
      },
      privatePublicResourcesSum () {
        var publicPrivateResources = this.mediaTypeFacets.filter(item => item.value == 'public_resource' || item.value == 'private_resource')
        var publicPrivateCount = 0

        if (publicPrivateResources.length > 0) {
          publicPrivateResources.forEach(resource => {
            publicPrivateCount += resource.counter;
          });
        }

        return publicPrivateCount;
      }
    },
    methods: {
      humanize,
      getFacetValue,
      toggleMediaTypeKeyword (keyword) {
        if (this.activeMediaTypeTags.includes(keyword.value)) {
          this.handleRemoveKeyword(keyword)
        } else {
          this.handleAddKeyword(keyword)
        }
      },
      getMediaTagLabel (tag) {
        // We use this to change the displayed text in the media tags
        switch (tag.value) {
          case 'rss':
            return `Articles (${tag.counter})`;
          case 'pr':
            return `Press Releases (${tag.counter})`;
          case 'blog':
            return `Blogs (${tag.counter})`;
          case 'crunchbase_pro':
            return `News aggregator (${tag.counter})`;
          case 'announcement':
            return `${this.announcementsLabel} (${tag.counter})`;
          case 'resource':
            return `Resources (${tag.counter})`;
          case 'public_resource':
          case 'private_resource':
            // Do nothing
            break;
          case 'vacancy':
            return `Vacancies (${tag.counter})`;
          case 'patent':
            return `Patents (${tag.counter})`;
          case 'product_service_offering':
            return `Products & Services (${tag.counter})`;
          default:
            return tag.value
        }
      },
      handleAddKeyword (keyword) {
        this.$emit('addedFilter', keyword);
      },
      handleRemoveKeyword (keyword) {
        this.$emit('removedFilter', keyword);
      },
      clearAllFilters () {
        this.$emit('clearFilters');
      },
    },
    mixins: [FiltersMixin, TranslationsMixin],
    components: {
      FiltersDropdown,
      HierarchyDropdown,
      KnowledgeBaseKeywordSuggestionSearchBar,
      Tag,
    }
  })
  </script>
