<template>
  <div class="has-action-bar">
    <div>
      <form-group style="max-width: 30%;" label="Select the synced ecosystem that you want to see logs for">
        <dropdown :options="configuredEcosystems" v-model="selectedEcosystem"/>
      </form-group>

      <form-group style="max-width: 30%;" label="Optional: Select a specific portfolio you want to see the logs for"  v-if="portfolios.length > 0">
        <dropdown :options="portfolios" v-model="selectedPortfolioId" allow-clear/>
      </form-group>
    </div>

    <div>
      <form class="form-horizontal">
        <form-group class="portfolio-sync__filter" style="width: 300px;" label="name">
          <suggestion-input
            icon="search"
            :api-settings="suggestionSettings"
            :clear-on-select="false"
            @actor="actor = $event"
            @update:modelValue="changeActor"
          />
        </form-group>

        <form-group class="portfolio-sync__filter" label="Success">
          <dropdown
            :options="successOptions"
            label="Success"
            v-model="filterOptions.success"
            allow-clear
          />
        </form-group>

        <form-group class="portfolio-sync__filter" label="Start date">
          <datepicker v-model="filterOptions.startDate" input-id="startDate"/>
        </form-group>

        <form-group class="portfolio-sync__filter" label="End date">
          <datepicker v-model="filterOptions.endDate" input-id="endDate"/>
        </form-group>
      </form>
    </div>

    <h3 class="h2">Portfolio sync logs</h3>
    <table class="table table--fill table--hover" style="table-layout: fixed">
      <thead>
      <tr>
        <th>Name</th>
        <th>Synced at</th>
        <th>Succes</th>
        <th>Fields</th>
        <th>Context</th>
      </tr>
      </thead>
      <tbody>
        <tr v-for="log in logs" @click="showSidePanel(log.objectId, log.objectType)">
          <td>{{ log.actor_source_name }}</td>
          <td>{{ log.synced_at }}</td>
          <td>{{ log.success == 1 ? 'Yes' : 'No' }}</td>
          <td>{{ log.fields && log.fields.length > 0 ? log.fields : '' }}</td>
          <td>{{ humanizeMeta(log) }}</td>
        </tr>
      </tbody>
    </table>
    <pagination class="simple-pagination" :paging="paging" @change="setOffset"/>
  </div>
</template>

<script>

  import _throttle from 'lodash/throttle'

  import DsInput from '../Form/DsInput.vue'
  import FormGroup from '../Form/FormGroup.vue'
  import Checkbox from '../Form/Checkbox.vue'
  import TagInput from '../Form/TagInput.vue'
  import Pagination from '../Pagination/SimplePagination.vue'
  import Datepicker from '../Form/Datepicker.vue'
  import ThSort from '../ListView/ThSort.vue'
  import SuggestionInput from '../SuggestionInput/SuggestionInput.vue'

  import Tabs from '../Tabs/Tabs.vue'
  import DsButton from '../DsButton/DsButton.vue'
  import Dropdown from '../Dropdown/Dropdown.vue'
  import FiltersDropdown from '../Dropdown/FiltersDropdown.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'

  import { suggestionSettings } from '../../api/actors.js'
  import { syncedPortfolios, Portfolios } from '../../api/portfolios'
  import { fetchPortfolioSyncLogs } from '../../api/config.js'

  export default {
    data () {
      return {
        selectedEcosystem: this.$store.getters.portfolioSyncConfigurations[0].ecosystem,
        selectedPortfolioId: '',
        logs: [],
        paging: {
          offset: 0,
          limit: 100
        },
        errors: {},
        rawPortfolios: [],
        filterOptions: {
          success: null,
          startDate: null,
          endDate: null
        },
        actor: {}
      }
    },
    computed: {
      suggestionSettings,
      configuredSynchronizations () {
        return this.$store.getters.portfolioSyncConfigurations
      },
      configuredEcosystems () {
        if (!this.configuredSynchronizations || this.configuredSynchronizations.length == 0) {
          return []
        }

        return this.configuredSynchronizations.map(c => c.ecosystem)
      },
      portfolios () {
        return this.rawPortfolios && this.rawPortfolios.map(p => { return {'value': p.id, 'label': p.name}})
      },
      successOptions () {
        return [
          { 'label': 'Yes', 'value': 'Yes' },
          { 'label': 'No', 'value': 'No' },
        ]
      }
  },
  methods: {
    changeActor(value) {
      this.actor = {}
    },
    fetchPortfolios () {
      syncedPortfolios(this.selectedEcosystem, 100, 0)
        .then(portfolios => {
          this.rawPortfolios = portfolios
        })
        .catch(errors => {
        })
    },
    humanizeMeta (log) {
      if (typeof log == 'string') {
        return log
      } else if (log && log.meta && log.meta.error) {
        return log.meta.error
      } else if (log && log.meta && log.meta.message) {
        return log.meta.message
      }
    },
    fetchLogs (offset) {
      if (! this.selectedPortfolioId) {
        this.resetLogs()
      }

      var filters = Object.assign(this.filterOptions, {'actor': this.actor && this.actor.id,'ecosystem': this.selectedEcosystem, 'portfolio': this.selectedPortfolioId}, {offset: offset, limit: 100})

      fetchPortfolioSyncLogs(filters).then(response => {
        this.logs = response.data;
        this.paging = response.paging;
        this.numberOfResults = response.total;
      })
    },
    resetLogs() {
      this.logs = []
      this.paging = {
        offset: 0,
        limit: 100
      }
      this.numberOfResults = 0
    },
    setOffset (offset) {
      this.fetchLogs(offset);
    },
    showSidePanel (id, type) {
      if (type == 'actor') {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component:"scores", metaData: id})
      }
    }
  },
  watch: {
    selectedEcosystem (v) {
      this.resetLogs()
      this.fetchLogs()
      this.fetchPortfolios()
    },
    selectedPortfolioId () {
      this.resetLogs()
      this.fetchLogs()
    },
    filterOptions: {
      deep: true,
      handler () {
        this.resetLogs()
        this.fetchLogs()
      }
    },
    actor () {
      this.resetLogs()
      this.fetchLogs()
    }
  },
  created () {
    this.fetchPortfolios()
    this.fetchLogs()
  },
  components: {
    Checkbox,
    Dropdown,
    DsButton,
    DsInput,
    FormGroup,
    Pagination,
    Tabs,
    TagInput,
    ThSort,
    FiltersDropdown,
    Datepicker,
    SuggestionInput,
  }
}
</script>

<style>
  .portfolio-sync__filter {
    width: 150px;
    display: inline-block;
    margin-right: 30px;
  }

  .form-horizontal {
    display: flex;
  }
</style>
