<template>
  <div class="ecosystem-switcher" :class="{ 'ecosystem-switcher--visible': isVisible }" @mouseleave="close">
    <div class="ecosystem-switcher__search" v-if="ecosystems.length > 5">
      <icon name="search" />
      <input type="text" placeholder="Search ecosystems..." v-model="searchQuery">
    </div>
    <ul class="ecosystem-switcher__list">
      <li v-for="ecosystem in filteredEcosystems" :key="ecosystem.id">
        <a :href="ecosystem.url" rel="noopener" class="ecosystem-switcher__ecosystem" :class="{ 'ecosystem-switcher__ecosystem--active': ecosystem.id === currentEcosystem.id }">
          <div class="ecosystem-switcher__ecosystem__name">{{ ecosystemDisplayName(ecosystem) }}</div>
          <div class="ecosystem-switcher__ecosystem__role">{{ normaliseRole(ecosystem.role) }}</div>
        </a>
      </li>
    </ul>
    <!--<div class="ecosystem__button-container">
      <ds-button label="Create new" variant="primary" block />
    </div>-->
  </div>
</template>

<script>
  import KEYCODES from '../../constants/keycodes'
  import { MUTATION_TYPES } from '../../store/modules/ui.js'

  export default {
    created: function () {
      window.addEventListener('keyup', (e) => {
        if (e.keyCode === KEYCODES.ESC) {
          this.close()
        }
      })
    },
    methods: {
      close () {
        this.$store.commit(MUTATION_TYPES.HIDE_ECOSYSTEM_PANEL)
      },
      ecosystemDisplayName (ecosystem) {
        if (ecosystem.displayName) {
          return ecosystem.displayName
        }

        return ecosystem.name
      },
      normaliseRole (selectedRole) {
        if (window.ROLES) {
          return window.ROLES[selectedRole]
        }

        switch (selectedRole) {
          case 'actor':
            return 'Ecosystem Member'
          case 'portfolio_member':
            return 'Portfolio Member'
          case 'owner':
            return 'Owner'
          case 'member':
            return 'Team Member'
          default :
            return ''
        }
      },
    },
    data () {
      return {
        searchQuery: '',
        currentEcosystem: this.$store.getters.currentEcosystem,
        ecosystems: this.$store.state.user.profile.teams || [],
      }
  },
    computed: {
      filteredEcosystems: function () {
        return this.ecosystems.filter(e => e.name.includes(this.searchQuery))
      },
      isVisible: function () {
        return this.$store.state.ui.ecosystemSwitcher.isVisible
      },
    }
  }
</script>
