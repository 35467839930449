<template>
  <div class="authentication-modal authentication-modal--small">
    <modal :id="modalId" ref="modal" :enable-mask="false">
      <template v-slot:body>
        <div>
          <form class="form">
            <fieldset style="text-align:center">
              <p>
                {{ $t('edit_actor_unsaved_changes') }}
              </p>
            </fieldset>
            <fieldset class="action-buttons" style="text-align:center">
              <ds-button variant="secondary" label="Confirm" @click="accept"></ds-button>
              <ds-button variant="outline" label="Cancel" @click="closeModal"></ds-button>
            </fieldset>
          </form>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
  import Modal from './Modal.vue'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'



  export default {
    name: 'cancel-actor-edit-confirmation-modal',
    props: {
      isCloseable: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        modalId: 'CANCEL_ACTOR_EDIT_CONFIRMATION',
    }
  },
  computed: {
    modalContext () {
     return this.$store.state.ui.modalContext
    },
  },
  methods: {
    accept () {
      this.$bus.emit('cancelActorEditConfirmation', true)
    },
    closeModal () {
      this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, this.modalId)
    }
  },
  components: {
      Modal
    }
  }
</script>
