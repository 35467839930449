<template>
  <NewCard
      title="Grants"
      collapsable
      :isCollapsedOnInit="!hasGrants"
      :force-open="showAddGrant"
      @isCollapsed="setCardCollapseState($event, 'grants')"
  >
    <template v-slot:headerRight>
      <ds-button v-if="displayAgent" icon="agent-head" label="Projects Agent" size="extra-small" variant="outline"
                 @click="handleClickProjectsAgent()" style="margin-right:20px"/>
      <ds-button icon="plus" label="Add grant" size="extra-small" variant="outline" @click="toggleShowAddGrant()"
                 style="margin-right:20px"/>
    </template>

    <div class="row">
      <div class="col-sm-6">
        <Metric label="Number of grants:" :model-value="grantsLength" orientation="horizontal"/>
      </div>
      <div class="col-sm-6">
        <Metric label="Total grants:" :model-value="totalGrants" orientation="horizontal"/>
      </div>
    </div>
    <br>

    <div class="actor-detail-info__add-grant" v-if="showAddGrant">
      <h4 class="h4" style="margin-bottom: 0.2rem;">Add Grant</h4>
      <div style="display: flex; justify-content: flex-start; flex-direction: row;">
        <div style="display: flex; justify-content: flex-start; flex-direction: column;">
          <div style="display: flex; justify-content: flex-start; flex-direction: row;">
            <dropdown :options="currencies" v-model="newGrant.original_currency" style="max-width: 70px;"/>
            <ds-input type="amount" placeholder="25000" precision="5" v-model="newGrant.original_grant_total"
                      style="max-width: 150px;"/>
            <datepicker :allow-reset="false" v-model="newGrant.started_at"
                        style="min-width: 100px; margin-left: 0.5rem;"/>
          </div>

          <div style="display: flex; margin-top: 0.5rem;">
            <ds-input placeholder="Project" v-model="newGrant.project_name" style="max-width: 200px;"/>
            <ds-input placeholder="Grant provider" v-model="newGrant.provider_name"
                      style="max-width: 200px; margin-left: 0.5rem;"/>
          </div>

          <div>
            <ds-textarea placeholder="Short description" v-model="newGrant.short_description"
                         style="margin-top: 0.5rem;"/>
          </div>
        </div>
        <div>
          <ds-button label="Add Grant" @click="addGrant" :disabled="!canAddNewGrant || addingGrant"
                     style="margin-left: 0.5rem;"/>
        </div>
      </div>
    </div>

    <!-- Hide grants from the last 24 months + add button 'Show all'-->
    <table class="table table-grants" v-if="grants.length > 0">
      <caption v-if="hasOutdatedGrants" style="margin-bottom: 0.5rem;">
        <div class="wrapper-input-search-grants">
          <ds-input
              :icon="loadingGrants ? 'spinner' : 'search'"
              :model-value="filterTableByTitleValue"
              @update:modelValue="searchGrants"
              placeholder="Search for project title or description"
          />
        </div>
        <checkbox-button
            :model-value="showFullDescriptionsSelection"
            :label="'Show full descriptions'"
            class="modal__body__list--items"
            :style="{ width: 'auto' }"
            @update:modelValue="handleToggleFullDescriptionsSelection"
        />
        <ds-button :label="showAllGrants ? 'Hide oldest' : 'Show all'"
                   :icon="showAllGrants ? 'chevron-up' : 'chevron-down'" size="small" @click="toggleAllGrants"
                   style=""/>
      </caption>
      <thead>
      <tr>
        <th style="width: 120px;">Date</th>
        <th style="width: 100px;">Amount</th>
        <th style="min-width: 150px;">Project</th>
        <th style="width: 100px;">Provider</th>
        <th style="width: 600px;">Short Description</th>
        <th style="width: 20px;"></th>
      </tr>
      </thead>
      <tbody>
      <template v-if="loadingGrants">
        <template v-for="n in 5">
          <skeleton-table-rows :wrapper-class="'analysis-card__skeleton'"/>
        </template>
      </template>
      <template v-if="!loadingGrants" v-for="(grant, index) in grantsList">
        <tr v-if="grantIsVisible(grant)" :class="{'hidden':grantIsInPast(grant) && !showAllGrants}">
          <td><strong>
            <Date :date="grant.started_at" format="MMMM YYYY"/>
          </strong></td>
          <td v-if="grant.grant_total > 0"><strong>
            <Currency :amount="grant.grant_total" format="0.0a"/>
            {{ grant.currency }}</strong></td>
          <td v-else><strong>-</strong></td>

          <td v-if="!grant.cordis_id"><span
              v-tooltip.top-center="{content: tooltipContent(grant), popperClass: ['large-tooltip']}">{{
              grant.project_name
            }}</span></td>
          <td v-else><a :href="'https://cordis.europa.eu/project/id/' + grant.cordis_id" target="_blank"><span
              v-tooltip.top-center="tooltipContent(grant)">{{
              grant.project_name
            }}</span></a></td>

          <td>{{ grant.provider_name }}</td>

          <td style="max-width: 200px; text-overflow: ellipsis">
            {{ grantShortDescription(index, grant) }}
            <a
                @click="toggleGrantShortDescription(index)"
                v-if="grant.short_description.length > 160"
            >
              {{ grantReadMoreLabel(index, grant) }}
            </a>
          </td>

          <td style="width: 20px;">
            <ds-button
                v-if="displayAddProjectButton(grant)"
                :icon="processingSuggestion ? 'spinner' : 'plus'"
                :disabled="disableAddProjectButton(grant.cordis_id)"
                label="Add Project"
                size="extra-small"
                variant="outline"
                @click="addProject(grant.cordis_id)"
                style="margin-right:20px"
            />
            <ds-button
                v-if="displayViewProjectButton(grant)"
                :icon="'chevron-right'"
                label="View Project"
                size="extra-small"
                variant="outline"
                @click="viewProject(grant)"
                style="margin-right:20px"
            />
            <ds-button
                class="actor-detail-info_office-delete-btn"
                icon="trash"
                variant="outline"
                size="small"
                @click="showDeleteConfirmationModal(index)"
                style="margin-right:5px"
            />
          </td>
        </tr>
        <tr v-else>
          <td class="blurred_text"><strong>
            <Date :date="grant.started_at" format="MMMM YYYY"/>
          </strong></td>
          <td class="blurred_text">
            <Currency amount="100000" format="0a"/>
            {{ grant.currency }}
          </td>
          <td v-if="!grant.cordis_id"><span v-tooltip.top-center="tooltipContent(grant)">{{ grant.project_name }}</span>
          </td>
          <td v-else><a :href="'https://cordis.europa.eu/project/id/' + grant.cordis_id" target="_blank"><span
              v-tooltip.top-center="tooltipContent(grant)">{{
              grant.project_name
            }}</span></a></td>
          <td style="width: 20px;">
            <ds-button
                class="actor-detail-info_office-delete-btn"
                icon="trash"
                variant="outline"
                size="small"
                @click="showDeleteConfirmationModal(index)"
                style="margin-right:5px"
            />
          </td>
        </tr>
      </template>
      </tbody>
    </table>
  </NewCard>
</template>

<script>
import numeral from 'numeral'
import moment from 'moment'
import isNumber from 'lodash/isNumber'

import Metric from '../../../components/Metric/Metric.vue'
import NewCard from '../../../components/NewCard/NewCard.vue'
import Dropdown from '../../../components/Dropdown/Dropdown.vue'
import Datepicker from '../../../components/Form/Datepicker.vue'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui'
import SkeletonTableRows from '@/components/Skeleton/SkeletonTableRows.vue'
import MODAL_IDS from '../../../constants/modal-ids'
import debounce from 'lodash/debounce'
import CheckboxButton from '@/components/CheckboxButton/CheckboxButton.vue'
import { trackHeapEvent } from '@/util/analytics.js'
import CompanyMixin from '@/util/CompanyMixin'
import { fetchAgentStatus } from '@/api/actors'
import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '@/store/modules/actors'
import { Suggestions } from '@/api/heartbeat'
import DsTextarea from '@/components/Form/DsTextarea.vue'
import { toAmount } from '@/util/currency'

export default {
  name: 'Grants',
  props: ['actor'],
  data () {
    return {
      currencies: ['AUD', 'CAD', 'CHF', 'EUR', 'GBP', 'JPY', 'NZD', 'USD', 'ZAR'],
      showAddGrant: false,
      showAllGrants: true,
      hasOutdatedGrants: false,
      newGrant: {
        original_grant_total: 0,
        original_currency: 'EUR',
        type: 'Grant',
        project_name: '',
        short_description: '',
        started_at: null,
      },
      addingGrant: false,
      loadingGrants: false,
      filterTableByTitleValue: '',
      grantsList: [],
      toggledGrantShortDescriptionIndices: [],
      agentResults: {},
      processingSuggestion: false,
      showFullDescriptionsSelection: false
    }
  },
  computed: {
    isDeveloper () {
      return this.$store.getters.isDeveloper
    },
    company () {
      // Required for the company mixin, we need to do a small refactor once there's time
      return this.actor
    },
    displayAgent () {
      return this.actor.company_type != 'Subsidiary' &&
          this.actor.actor_type != 'Person' &&
          this.$store.getters.hasAccessToAgents &&
          (
              this.canProjectAgentBeUsed
          )
    },
    grants () {
      let grants = (this.actor.grants || [])

      grants = grants
          .slice()
          .sort((a, b) => (b.started_at || '').localeCompare(a.started_at || ''))

      this.grantsList = grants

      return grants
    },
    grantsLength () {
      return this.actor.grants && this.actor.grants.length ? this.actor.grants.length : '0'
    },
    hasGrants () {
      return this.actor.grants && this.actor.grants.length > 0
    },
    totalGrants () {
      if (this.actor.total_grants) {
        return numeral(this.actor.total_grants).format('$0.00a')
      }

      return numeral(0).format('$0.00a')
    },
    canAddNewGrant () {
      return this.newGrant.original_grant_total && this.newGrant.original_grant_total > 0 && isNumber(this.newGrant.original_grant_total)
    },
  },
  methods: {
    grantIsVisible (grant) {
      return !grant.hidden || this.$store.getters.isDeveloper
    },
    grantIsInPast (grant) {
      const startedAt = moment(grant.started_at)
      const today = moment().subtract(24, 'months')
      const inPast = today.diff(startedAt) > 0

      if (inPast) {
        this.hasOutdatedGrants = true
      }

      return inPast
    },
    toggleShowAddGrant () {
      this.showAddGrant = !this.showAddGrant
    },
    setCardCollapseState (value, type) {
      var userSettingsCollapsedCardsCopy = Object.assign({}, this.$store.getters.userSettings.collapsedCards, { [type]: value })

      this.$store.commit('USER/STORE_SETTINGS', { collapsedCards: userSettingsCollapsedCardsCopy })
    },
    showDeleteConfirmationModal (grantIndex) {
      var modalContextData = Object.assign({
        grantIndex: grantIndex,
        modalContextType: 'grant',
        actorId: this.actor.id
      })

      this.$store.commit('UI/SET_MODAL_CONTEXT', modalContextData)
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
    },
    deleteGrant (grantIndex) {
      var grants = [...this.actor.grants]

      grants.splice(grantIndex, 1)

      this.$emit('updateGrants', grants)
    },
    toggleAllGrants () {
      this.showAllGrants = !this.showAllGrants
    },
    toggleGrantShortDescription (index) {
      this.toggledGrantShortDescriptionIndices.push(index)
    },
    handleClickProjectsAgent () {
      trackHeapEvent('actorDetail.clickProjectsAgent')
      this.$emit('agentsMode', true)
    },
    handleToggleFullDescriptionsSelection (value) {
      this.showFullDescriptionsSelection = value

      // When disabled, reset the indices value
      if (!value) {
        this.toggledGrantShortDescriptionIndices = []

        return
      }

      // When enabled, push all indices values
      this.grantsList.map((grant, index) => {
        this.toggledGrantShortDescriptionIndices.push(index)
      })
    },
    searchGrants (inputValue) {
      this.loadingGrants = true
      this.filterTableByTitleValue = inputValue

      this.filterTableByTitle(inputValue)
    },
    filterTableByTitle: debounce(function (inputValue) {
      // make sure all grants are displayed
      this.showAllGrants = true

      // filter grants list by title or short description
      this.grantsList = this.grants.filter((grant) => {
        return (grant.project_name.toLowerCase().indexOf(inputValue.toLowerCase()) > -1) || (grant.short_description.toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
      });

      this.loadingGrants = false
    }, 1000),
    addGrant () {
      this.addingGrant = true

      var grants = [...(this.actor.grants || [])]

      grants.push(this.newGrant)

      this.$emit('updateGrants', grants)

      this.newGrant = {
        original_grant_total: 0,
        original_currency: 'EUR',
        started_at: null,
        type: 'Grant',
        project_name: '',
        short_description: ''
      }

      this.addingGrant = false
      this.showAddGrant = false
    },
    grantReadMoreLabel (index) {
      const showMore = this.toggledGrantShortDescriptionIndices.filter(grantIndex => {
        return grantIndex === index
      })

      if (showMore.length > 0) {
        return ''
      }

      return 'Read more'
    },
    grantShortDescription (index, grant) {
      if (!grant.short_description.length) {
        return ''
      }

      const showMore = this.toggledGrantShortDescriptionIndices.filter(grantIndex => {
        return grantIndex === index
      })

      if (showMore.length > 0) {
        return grant.short_description
      }

      if (grant.short_description.length > 160) {
        return grant.short_description.slice(0, 160) + '...'
      }

      return grant.short_description
    },
    tooltipContent (grant) {
      var description = ''

      if (grant.short_description) {
        description += grant.short_description.replace(/(?:\r\n|\r|\n)/g, '<br>')
      }

      return description
    },
    fetchProjectsAgent () {
      this.loadingGrants = true

      fetchAgentStatus(this.actor.id)
          .then(response => {
            if (!response || !response['get_projects']) {
              this.loadingGrants = false

              return
            }

            const meta = response['get_projects'].meta

            this.agentResults = meta

            this.loadingGrants = false
          })
    },
    addProject (cordisId) {
      // approve the suggestion
      const suggestion = this.agentResults.filter(suggestion => {
        return suggestion.value.cordis_id === cordisId
      })

      if (suggestion.length === 0) {
        return
      }

      this.acceptSuggestion(suggestion[0])
    },
    viewProject (grant) {
      if (!grant.matching_actor_id || grant.matching_actor_id === null) {
        return false
      }

      const actorId = grant.matching_actor_id

      // Close the side panel
      this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)

      // Open Side panel
      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
        component: 'scores',
        metaData: { actorId: actorId, isPreview: true }
      })

      // Go to the actor detail page of the clicked actor
      // this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_DETAIL, actorId)
      //this.$router.push('/actors/' + actorId)
    },
    async acceptSuggestion (suggestion) {
      this.processingSuggestion = true

      const data = [{
        id: suggestion.id,
        status: 'accepted'
      }]

      try {
        await Suggestions.post(data)

        // this.fetchSuggestions
        // this.$emit('update:suggestions', suggestions)

        // Update the actor detail, accepting a suggestion from an agent usually means adding a new connection
        // so we need to update the connections of the actor we're currently on
        // this should also refresh the grants list
        this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_DETAIL, this.actor.id)

        this.processingSuggestion = false
      } catch (errors) {
        console.log(errors)
      }
    },
    displayAddProjectButton (grant) {
      if (!this.canProjectAgentBeUsed) {
        return false
      }

      if (grant.matching_actor_id) {
        return false
      }

      const cordisId = grant.cordis_id

      if (!cordisId || !this.hasGrantAgentSuggestion(cordisId)) {
        return false
      }

      const suggestion = this.getGrantAgentSuggestion(cordisId)

      return suggestion.status === 'in_progress'
    },
    displayViewProjectButton (grant) {
      if (!grant.matching_actor_id || grant.matching_actor_id === null) {
        return false
      }

      return true
    },
    disableAddProjectButton (cordisId) {
      if (!this.canProjectAgentBeUsed) {
        return true
      }

      if (!this.hasGrantAgentSuggestion(cordisId)) {
        return true
      }

      const suggestion = this.getGrantAgentSuggestion(cordisId)

      return this.processingSuggestion || suggestion.status !== 'in_progress'
    },
    hasGrantAgentSuggestion (cordisId) {
      const results = this.agentResults

      if (!results || typeof results.length === 'undefined' || results.length === 0) {
        return false
      }

      const suggestion = results.filter(suggestion => {
        return suggestion.value.cordis_id === cordisId
      })

      return suggestion.length > 0
    },
    getGrantAgentSuggestion (cordisId) {
      const results = this.agentResults

      if (!results || typeof results.length === 'undefined' || results.length === 0) {
        return false
      }

      const suggestion = results.filter(suggestion => {
        return suggestion.value.cordis_id === cordisId
      })

      if (suggestion.length === 0) {
        return {}
      }

      return suggestion[0]

    },
  },
  components: {
    DsTextarea,
    CheckboxButton,
    Metric,
    NewCard,
    Dropdown,
    Datepicker,
    SkeletonTableRows,
  },
  mounted () {
    this.newGrant.started_at = moment().format('YYYY-MM-DD')

    this.$bus.on('deleteGrantConfirmation', (context) => {
      // This event will be thrown if we delete the a grant
      if (!context.actorId || context.actorId !== this.actor.id) {
        return
      }

      this.deleteGrant(context.grantIndex)
    })


    if (this.canProjectAgentBeUsed) {
      this.fetchProjectsAgent()
    }
  },
  beforeUnmount () {
    this.$bus.off('deleteGrantConfirmation')
  },
  mixins: [CompanyMixin],
}
</script>

<style scoped>
.table-grants caption {
  .wrapper-input-search-grants {
    display: inline-block;
    width: 500px;
    margin-right: 10px;
  }

  :deep(.ds-input__input) {
    margin-right: 10px;
    border-radius: 12px;
  }

  input[type=checkbox]:after {
    border: 0px solid white !important;
  }

  :deep(input[type=checkbox]) {
    &::after {
      border: 0px solid white !important;
    }
  }
}

.table caption {
  text-align: right;
}

.hidden {
  display: none;
}

.actor-detail-info__add-grant {
  margin-top: -1rem;
  margin-bottom: 1rem;
}

.alert-warning {
  display: inline-flex;
  position: relative;
  padding: 3px 5px;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;
  line-height: 1rem;
  min-height: 24px;
  max-height: 95%;
  border: 1px solid #ff805a;
  background-color: #ebebeb;
  color: #ff805a;
  align-items: center;
  font-size: 0.75rem;
}
</style>
