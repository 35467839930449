<template>
  <div class="side-panel__actor-preview fixed-heading" >
    <div class="card__general side-panel__header">
      <ds-button
      class="side-panel__actor-preview__header__close-button"
      icon="remove"
      variant="minimal"
      @click="hidePreview"
      />
      <div class="card__title side-panel__card__title__telex">Telex</div>
      <div class="side-panel__telex-filters">
          <radio-button
            inline
            name="telexFilters"
            label="Show All"
            checked="all"
            val="all"
            value="all"
            @update:modelValue="selectFilter('all')"
          />
          <radio-button
            inline
            name="telexFilters"
            label="Show News"
            val=""
            value="news"
            @update:modelValue="selectFilter('news')"
          />
          <radio-button
            inline
            name="telexFilters"
            label="Show Changes"
            val=""
            value="changes"
            @update:modelValue="selectFilter('changes')"
          />
          <radio-button
          inline
          name="telexFilters"
          label="Show Reddit"
          val=""
          value="reddit"
          @update:modelValue="selectFilter('reddit')"
          v-if="redditFeedEnabled"
          />
      </div>
    </div>
    <div class="scrollable" ref="scrollable">
      <div  class="card__general side-panel__header side-panel__telex-items" :class="[item.telex_type=='news' ? 'side-panel__telex-items__news-border-color' : 'side-panel__telex-items__significant-change-border-color']"  v-for="(item,index) in items">
        <!-- news -->
        <div v-if="item.telex_type == 'news'" class="side-panel__telex-container">
          <ds-button :to="'/actors/' + item.id" :label="item.actorName" icon="chevron-right" variant="secondary" class="side-panel__actor-name__news__telex" size="extra-small"/>
          <h5 class="side-panel__news-date__telex">{{toOnlyDayMonthString(item.date)}}</h5>
          <a :href="item.url" target="_blank"  class="side-panel__news-telex-container">
            <div class="side-panel__news-section__article-info-conatiner__telex">
              <section class="side-panel__news-section__image__telex">
                <div class="side-panel__news-image__telex" :style="newsImageStyle(item)"></div>
              </section>
              <section class="side-panel__news-section__article-info__telex">
                <h6 class="side-panel__section-title__telex">News</h6>
                <h5 class="side-panel__text__news__telex">{{item.title}}</h5>
              </section>
            </div>
          </a>
        </div>

        <!-- reddit -->
        <div v-else-if="item.telex_type == 'reddit'" class="side-panel__telex-container">
          <ds-button :to="'/actors/' + item.id" :label="item.actorName" icon="chevron-right" variant="secondary" class="side-panel__actor-name__news__telex" size="extra-small" v-if="item.id"/>
          <ds-button :href="'https://reddit.com' + item.reddit_link" :label="item.sub_reddit" icon="chevron-right" variant="secondary" class="side-panel__actor-name__news__telex" size="extra-small" v-else/>
          <h5 class="side-panel__news-date__telex">{{toOnlyDayMonthString(item.post_saved_at * 1000)}}</h5>
          <a :href="'https://reddit.com' + item.reddit_link" target="_blank"  class="side-panel__news-telex-container">
            <div class="side-panel__news-section__article-info-conatiner__telex">
              <section class="side-panel__news-section__image__telex" v-if="item.thumbnail && item.thumbnail.length > 0">
                <div class="side-panel__news-image__telex" :style="redditThumbnail(item)"></div>
              </section>
              <section class="side-panel__news-section__article-info__telex">
                <h6 class="side-panel__section-title__telex">{{title(item)}}</h6>
                <h5 class="side-panel__text__news__telex">{{text(item)}}</h5>
              </section>
            </div>
          </a>
        </div>

        <!-- standard telex item -->
        <div v-else class="side-panel__telex-container">
          <section class="side-panel__info__telex">
            <ds-button :to="'/actors/' + item.id" :label="item.actorName" icon="chevron-right" variant="minimal" class="side-panel__actor-name__significant-change__telex telex-text" size="extra-small"/>
            <h5 class="side-panel__significant-change-date__telex telex-text" v-if="item.date !== null">{{toOnlyDayMonthString(item.date)}}</h5>
            <h5 class="side-panel__significant-change-date__telex telex-text" v-else>{{toOnlyDayMonthString(item.created_at)}}</h5>
            <h5 class="side-panel__text__significant-change__telex telex-text">{{ humaniseSigChange(item) }}</h5>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import Keyword from '../../components/Keyword/Keyword.vue'
  import RadioButton from '../../components/Form/RadioButton.vue'

  import InfiniteScroll from '../../util/infinite-scroll'
  import ICONS from '../../../icons/manifest.json'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { fetchTelex } from '../../api/heartbeat.js'
  import {fetchActor} from '../../api/actors.js'
  import { toOnlyDayMonthString } from '../../util/date'
  import KEYCODES from '../../constants/keycodes'


  export default {
    name: 'telex-side-panel',
    data () {
      return {
        ICONS,
        items:[],
        paging: {
          limit: 50,
          offset: 0,
        },
        telexType: 'all'
      }
    },
    computed: {
      isMember () {
        return this.$store.getters.isMember
      },
      redditFeedEnabled () {
        return this.$store.state.config.redditFeedEnabled
      }
    },
    methods: {
      toOnlyDayMonthString,
      title (item) {
        if (! item.title) {
          return
        }

        const titleLength = 60

        if (item.title.length < titleLength) {
          return item.title
        }

        return item.title.substring(0, titleLength - 3) + '...'
      },
      // Note: this should be a helper method as the functionality is 100% the same as other "max length string" operations
      text (item) {
        if (! item.text) {
          return
        }

        const textLength = 250

        if (item.text.length < textLength) {
          return item.text
        }

        return item.text.substring(0, textLength - 3) + '...'
      },
      humaniseSigChange (sigChange) {
        var title = sigChange.title

        if ((sigChange.changeType == 'twitter_metrics' || sigChange.changeType == 'facebook_metrics' || sigChange.changeType == 'web_metrics')
          && sigChange.meta && sigChange.meta.length > 0){
          title += '(' + sigChange.meta + ')'
        }

        return title
      },
      newsImageStyle (item) {
         if (! item.screenshot && ! item.logo) {
          return;
        }

        if (item.screenshot) {
          return { backgroundImage: `url(${item.screenshot})` };
        }

        if (item.logo) {
          return { backgroundImage: `url(${item.logo})` };
        }
      },
      redditThumbnail (item) {
        return { backgroundImage: `url(${item.thumbnail})`}
      },
      hidePreview () {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      fetch () {
        this.hasMore = 0

        const filters = Object.assign({'type' : this.telexType}, this.paging, { offset: 0 })

        fetchTelex(filters)
        .then(posts => {
          this.items = posts.data
          this.hasMore = this.items.length > 49
        }, this.fetchError)
      },
      fetchMore () {
        if (!this.hasMore) {
          return
        }
        const filters = Object.assign({'type' : this.telexType}, this.paging, { offset: this.items.length })

        fetchTelex(filters)
          .then(posts => {
            this.items = this.items.concat(posts.data)
            this.hasMore = this.items.length > 49
          })
      },
      selectFilter (filter) {
        this.telexType = filter
        this.paging = {
          limit: 50,
          offset: 0,
        }

        this.fetch()
      }
    },
    mounted () {
      this.fetch()
      this.inf = new InfiniteScroll(this.$refs.scrollable, this.fetchMore)
    },
    beforeUnmount () {
      this.inf.destroy()
    },
    created () {
      window.addEventListener('keyup', (e) => {
        if (e.keyCode === KEYCODES.ESC) {
          this.hidePreview()
        }
      })
    },
    components: {
      Keyword,
      InfiniteScroll,
      RadioButton
    }
  }
</script>
