import { ChallengeActor } from '@/Domain/Challenge/Types/challenge-types'

export const transformChallengeActorRecommendationsToChallengeActorArray = (apiResponse: any[]): ChallengeActor[] => {
  return apiResponse
    .map((challengeActor: any) => {
      return {
        id: challengeActor.id,
        name: challengeActor.name,
        description: challengeActor.description || challengeActor.short_description || '',
        shortDescription: challengeActor.short_description || '',
        actorType: challengeActor.actor_type,
        url: challengeActor.url || '',
        websiteScreenshot: challengeActor.website_screenshot || '',
        logo: challengeActor.logo || '',
        hasInviteesAttached: challengeActor.has_invitees_attached > 0,
        isActorShortListed: challengeActor.is_actor_short_listed,
        isActorClaimed: challengeActor.is_actor_claimed
      }
    })
}

export const transformChallengeShortListedActorsToChallengeActorArray = (apiResponse: any[]): ChallengeActor[] => {
  return transformChallengeActorRecommendationsToChallengeActorArray(apiResponse) // Same type of API response is given
}
