<template>
  <div class="badge" :class="extraClasses">
    <template v-if="icon">
        <span v-text="name"></span>
        <icon v-if="icon" :name="icon" size="small"/>
    </template>
    <template v-else>
      <span v-text="name"></span>
    </template>
  </div>
</template>

<script lang="ts">
  import Icon from '../Icon/Icon.vue'
  import { defineComponent } from 'vue'
  export default defineComponent({
    components: {
      Icon
    },
    props: {
      name: {
        type: String,
        required: true
      },
      variant: {
        type: String,
        default: 'default'
      },
      size: {
        type: String,
        validator: val => ['extra-small', 'tiny'].includes(val)
      },
      icon: {
        type: String,
        required: false
      }
    },
    computed: {
      extraClasses () {
        return {
          [`badge--${this.variant}`]: this.variant,
          [`badge--${this.size}`]: this.size,
        }
      }
    }
  })
</script>
