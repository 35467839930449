<template>
  <div class="collapsable-tab__content-container">
    <form-section>
      <div class="collapsable-tab__offering-card-container">
        <div class="collapsable-tab__offering-card" v-for="(offer, index) in modifiedActor.offerings" @click="setOfferingEditIndex(index)">
          <div class="collapsable-tab__offering-card-background-image" :style="offeringCardBackgroundImage(offer.logo)"></div>
          <span>{{offer.name}}</span>
        </div>
        <div class="collapsable-tab__offering-card-add-product" @click="openOfferingCreationCard('Product')">
          <span>Add Product</span>
        </div>
        <div class="collapsable-tab__offering-card-add-service" @click="openOfferingCreationCard('Service')">
          <span>Add Service</span>
        </div>
        <div class="collapsable-tab__offering-card-add-technology" @click="openOfferingCreationCard('Technology')">
          <span>Add Technology</span>
        </div>
        <div class="collapsable-tab__offering-card-add-technology" @click="openOfferingCreationCard('Vacancy')">
          <span>Add Vacancy</span>
        </div>
        <div class="collapsable-tab__offering-card-add-other" @click="openOfferingCreationCard('Other')">
          <span>Add Other</span>
        </div>
        <div class="collapsable-tab__offering-edit-card" v-if="show.editOffering && modifiedActor.offerings && modifiedActor.offerings[offeringEditIndex]">
          <div class="collapsable-tab__offering-edit-card-header">
            <ds-button variant="minimal" class="col-xs-1 col-xs-offset-11" icon="trash" @click="deleteOffering(modifiedActor.offerings[offeringEditIndex].id)"/>
          </div>
          <div class="row">
            <div class="col-xs-6">
              <form-group label="Logo" :errors="errors.logo">
                <image-input v-model="modifiedActor.offerings[offeringEditIndex].logo" width="140" height="100"/>
              </form-group>
            </div>
            <div class="col-xs-6">
              <form-group class="col-xs-12" :label="offeringTitle.name" :errors="errors.name">
                <ds-input v-model="modifiedActor.offerings[offeringEditIndex].name"/>
              </form-group>
            </div>
          </div>
          <div class="row">
            <form-group class="col-xs-12" :label="offeringTitle.description" :errors="errors.description">
              <ds-textarea autoresize v-model="modifiedActor.offerings[offeringEditIndex].description"/>
            </form-group>
          </div>
          <div class="row" v-if="offeringFields(modifiedActor.offerings[offeringEditIndex].type).includes('Date')">
            <form-group class="col-xs-12" :label="offeringTitle.date" :errors="errors.date">
              <datepicker v-model="modifiedActor.offerings[offeringEditIndex].date"/>
            </form-group>
          </div>
          <div class="row" v-if="offeringFields(modifiedActor.offerings[offeringEditIndex].type).includes('Website')">
            <form-group class="col-xs-12" :label="offeringTitle.website" :errors="errors.website">
              <ds-input v-model="modifiedActor.offerings[offeringEditIndex].website"/>
            </form-group>
          </div>
          <div class="row" v-if="offeringFields(modifiedActor.offerings[offeringEditIndex].type).includes('Features')">
            <form-group class="col-xs-12" label="Key feature tags" :errors="errors.features">
              <tag-input v-model="modifiedActor.offerings[offeringEditIndex].features"/>
            </form-group>
          </div>
          <div class="row" v-if="offeringFields(modifiedActor.offerings[offeringEditIndex].type).includes('Pricing')">
            <form-group class="col-xs-12" label="Pricing" :errors="errors.pricing">
              <ds-input v-model="modifiedActor.offerings[offeringEditIndex].pricing"/>
            </form-group>
          </div>
          <div class="row">
            <div class="col-xs-12 collapsable-tab__file-list-container" v-if="modifiedActor.offerings[offeringEditIndex].files && modifiedActor.offerings[offeringEditIndex].files.length > 0">
              <file-list
                :model-value="modifiedActor.offerings[offeringEditIndex].files"
                :backgroundImage="true"
                :showDate="false"
                @remove="removeFile"
                :parentId="offeringEditIndex"
                @privateFileListUpdate="updateFileVisibilityState"
                :parentData="modifiedActor"
                :privateFilesIds="privateFilesIds"
              />
            </div>
            <file-input
              class="col-xs-4 collapsable-tab__file-upload-button"
              window-drop
              :actor-id="modifiedActor.id"
              @upload="addFileOffering"
              @click="show.files = !show.files"
              v-if="modifiedActor.offerings[offeringEditIndex].type !== 'vacancy'"
            />
          </div>
        </div>
      </div>
      <offering-input-card :actorId="modifiedActor.id" editable="Save" :offerings="modifiedActor.offerings" :type="offeringType" v-if="show.addOffering" @saved="offeringSaved"/>
    </form-section>
  </div>
</template>

<script>
import FormGroup from '../../Form/FormGroup.vue'
import TagInput from '../../Form/TagInput.vue'
import FormSection from '../../Form/FormSection.vue'
import ImageInput from '../../Form/ImageInput.vue'
import DsTextarea from '../../Form/DsTextarea.vue'
import DsInput from '../../Form/DsInput.vue'
import Datepicker from '../../Form/Datepicker.vue'
import FileList from '../../Form/FileList.vue'
import FileInput from '../../Form/FileInput.vue'
import OfferingInputCard from '../../Form/OfferingInputCard.vue'

export default {
  name: 'actor-edit-product-service-offering',
  props: {
    modifiedActor: Object,
    show: Object,
    errors: Object,
  },
  data () {
    return {
      offeringEditIndex: 0,
      offeringType: 'Product',
      privateFilesIds: [],
    }
  },
  computed: {
    offeringTitle () {
      let dateTitle = 'Date'

      if (this.modifiedActor.offerings[this.offeringEditIndex].type === 'patent') {
        dateTitle = 'Publication date'
      }

      return {
        name: `${this.modifiedActor.offerings[this.offeringEditIndex].type} name`,
        description: `${this.modifiedActor.offerings[this.offeringEditIndex].type} description`,
        website: `${this.modifiedActor.offerings[this.offeringEditIndex].type} website`,
        date: dateTitle,
      }
    },
  },
  methods: {
    updateFileVisibilityState (fileId) {
      // Make sure the user can change the visibility
      if (this.userCanEdit) {
        const fileIdIndex = this.privateFilesIds.indexOf(fileId)

        if (fileIdIndex < 0) {
          this.privateFilesIds.push(fileId)
        } else {
          this.privateFilesIds.splice(fileIdIndex, 1)
        }
      }
    },
    offeringFields (type) {
      switch (type) {
        case 'product':
          return ['Name', 'Description', 'Website', 'Features', 'Pricing']
        case 'service':
        case 'technology':
          return ['Name', 'Description', 'Website', 'Pricing', 'Features']
        case 'patent':
          return ['Name', 'Description', 'Website', 'Date']
        case 'vacancy':
          return ['Name', 'Description', 'Website', 'Features']
        case 'other':
          return ['Name', 'Description']
      }

      return []
    },
    deleteOffering (offeringId) {
      if (offeringId) {
        const index = this.modifiedActor.offerings.map(item => {
          return item.id
        }).indexOf(offeringId)
        this.modifiedActor.offerings.splice(index, 1)

        if (this.modifiedActor.offerings.length === 0) {
          this.show.editOffering = false
        }
      }
    },
    addFileOffering (fileArray) {
      fileArray.forEach(file => {
        const modifiedFile = Object.assign(file, { actor_offerings_id: this.modifiedActor.offerings[this.offeringEditIndex].id })
        this.modifiedActor.files.push(modifiedFile)
      })

      // We assume that the index passed from the remove file is an index of the offering
      if (!this.modifiedActor.offerings[this.offeringEditIndex].files) {
        this.modifiedActor.offerings[this.offeringEditIndex].files = []
      }

      fileArray.forEach(file => {
        this.modifiedActor.offerings[this.offeringEditIndex].files.push(file)
      })
    },
    removeFile (removedFile, offeringEditIndex) {
      this.modifiedActor.files.forEach((file, index) => {
        if (file.id === removedFile.id) {
          this.modifiedActor.files.splice(index, 1)
        }
      })

      // We assume that the index passed from the remove file is an index of the offering
      if (!isNaN(offeringEditIndex) && this.modifiedActor.offerings && this.modifiedActor.offerings[offeringEditIndex] && this.modifiedActor.offerings[offeringEditIndex].files && this.modifiedActor.offerings[offeringEditIndex].files.length > 0) {
        this.modifiedActor.offerings[offeringEditIndex].files.forEach((file, index) => {
          if (file.id === removedFile.id) {
            this.modifiedActor.offerings[offeringEditIndex].files.splice(index, 1)
          }
        })
      }
    },
    setOfferingEditIndex (index) {
      this.offeringEditIndex = index
      this.show.editOffering = true
    },
    offeringCardBackgroundImage (imageUrl) {
      return `background-image:url('${imageUrl}'); background-size: cover; background-position: center center;`
    },
    openOfferingCreationCard (type, data, index) {
      this.offeringType = type
      this.show.addOffering = true
      this.show.editOffering = false
    },
    offeringSaved (newOfferings) {
      this.modifiedActor.offerings = newOfferings
      this.show.addOffering = false
    },
  },
  created () {
    if (this.modifiedActor.files && this.modifiedActor.files.length > 0) {
      this.modifiedActor.files.forEach(file => {
        if (file.is_private) {
          this.privateFilesIds.push(file.id)
        }
      })
    }
  },
  components: {
    FormGroup,
    TagInput,
    FormSection,
    ImageInput,
    DsInput,
    DsTextarea,
    Datepicker,
    FileList,
    FileInput,
    OfferingInputCard,
  },
  mixins: []
}
</script>
