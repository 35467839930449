<template>
  <div class="form-section">
    <div class="form-section__header">
      <div class="form-section__title" v-if="title">{{ title }}</div>
      <slot name="actions"></slot>
    </div>
    <div class=form-section__content>
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      title: {
        type: String
      }
    }
  }
</script>
