<template>
  <modal :id="modalId" ref="modal" @close="$emit('close')">
    <template v-slot:body>
      <div>
        <p>
        <div class="contact-modal__header">
          <icon name="email-outline" size="regular" class="contact-modal__header-icon"/>
          <div class="contact-modal__header-title">{{ maybeTranslate('contact_modal_title') }}</div>
          <ds-button
            class="contact-modal__header-close-btn"
            icon="remove"
            variant="minimal"
            @click="close"
          />
        </div>
        <div class="contact-modal__message-area">
          <div class="contact-modal__actor-info">
            <div class="contact-modal__actor-avatar">
              <avatar :src="companyLogoUrl" :alt="company.name" variant="border"></avatar>
            </div>
            <div class="contact-modal__actor-name">{{ company.name }}</div>
          </div>
          <form-group>
            <div class="contact-modal__message-warning" v-if="showMessageWarning">{{ maybeTranslate('contact_modal_message_warning') }}</div>
            <ds-textarea v-model="form.message" :defaultText="maybeTranslate('contact_modal_placeholder_message')" class="contact-modal__message-area__text-area"/>
          </form-group>
        </div>
        <div class="contact-modal__contact-info-area">
          <div class="contact-modal__email-input">
            <form-group :label="maybeTranslate('contact_modal_email')" class="col-xs" :error="maybeTranslate('contact_modal_email_invalid')"
                        v-if="mailIsValid == false && mailIsValid !== null">
              <ds-input v-model="form.email" @blur="validateEmail()"/>
            </form-group>
            <form-group :label="maybeTranslate('contact_modal_email')" class="col-xs" v-else>
              <div class="contact-modal__email-warning" v-if="showEmailWarning">{{ maybeTranslate('contact_modal_email_warning') }}</div>
              <ds-input v-model="form.email" :placeholder="maybeTranslate('contact_modal_email_placeholder')" @blur="validateEmail()"/>
            </form-group>
          </div>
          <div class="contact-modal__phone-input">
            <form-group :label="maybeTranslate('contact_modal_phone')" class="col-xs">
              <ds-input v-model="form.phone" :placeholder="maybeTranslate('contact_modal_phone_placeholder')"/>
            </form-group>
          </div>
        </div>
        </p>
      </div>
    </template>
    <template v-slot:footer>
      <div>
        <p v-if="errors">
          <span class="contact-modal__error-message"> {{ errors }} </span>
        </p>
        <p v-if="success !== ''">
          <span class="contact-modal__success-message"> {{success}} </span>
          <ds-button
            label="Ok"
            variant="primary"
            size="small"
            @click="close"
          />
        </p>
        <p v-else>
          <vue-recaptcha
            :style="{ visibility: canSend ? 'visible' : 'hidden' }"
            size="invisible"
            ref="recaptcha"
            @verify="onVerify"
            @expired="onExpired"
            :loadRecaptchaScript="true"
            :sitekey="captchaSiteKey">
            <ds-button
              :label="maybeTranslate('contact_modal_send')"
              variant="secondary"
              size="small"
              @click="sendMessage"
            />
          </vue-recaptcha>
        </p>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import DsInput from '../Form/DsInput.vue'
  import FormGroup from '../Form/FormGroup.vue'
  import DsTextarea from '../Form/DsTextarea.vue'
  import Avatar from '../Avatar/Avatar.vue'
  import VueRecaptcha from 'vue-recaptcha'

  import MODAL_IDS from '../../constants/modal-ids'
  import CompanyMixin from '../../util/CompanyMixin'
  import TranslationsMixin from '../../util/TranslationsMixin'

  import { sendMessage } from '../../api/config'

  export default {
    data () {
      return {
        errors: null,
        modalId: MODAL_IDS.CONTACT,
        success: '',
        captchaSiteKey: window.CATPCHA_PUBLIC_KEY,
        form: {
          busy: false,
          email: '',
          message: '',
          phone: null,
          actorId: null,
        },
        mailIsValid: null,
        showMessageWarning: false,
        showEmailWarning: false,
      }
    },
    computed: {
      canSend () {
        return this.isEmailValid && this.form.message && this.form.message.length >= 10
      },
      isEmailValid () {
        var regexValiation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

        return this.form.email && regexValiation.test(this.form.email)
      },
      company () {
        return this.$store.getters.detailActor
      },
    },
    methods: {
      close () {
        this.$refs.modal.close()
      },
      onSubmit: function () {
        this.$refs.invisibleRecaptcha.execute()
      },
      onVerify: function (response) {
        this.sendMessage()
      },
      onExpired: function () {
        console.log('Expired')
      },
      resetRecaptcha () {
        this.$refs.recaptcha.reset()
      },
      sendMessage () {
        if (!this.form.busy && (this.form.message !== null && this.form.message !== '') && (this.form.email !== null && this.form.email !== '')) {
          this.form.busy = true

          sendMessage(this.form)
            .then(() => {
              this.success = this.maybeTranslate('contact_modal_success')
              this.form.email = ''
              this.form.message = null
              this.form.phone = null
              this.errors = null
              this.form.busy = false
              this.resetRecaptcha()
            })
          .catch(errors => {
            this.errors = errors.message.toString()
            this.form.busy = false
            this.resetRecaptcha()
          });
        }

        if (this.form.email == null || this.form.email == "") {
          this.showEmailWarning = true
        }

        if (this.form.message == null || this.form.message == "") {
          this.showMessageWarning = true
        } else {
          this.showMessageWarning = false
        }
      },
      validateEmail () {
        if (this.form.email == null || this.form.email == "") {
          this.mailIsValid = true
          return
        } else {
          this.showEmailWarning = false
          var regexValiation = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

          if (regexValiation.test(this.form.email)) {
            this.mailIsValid = true
          } else {
            this.form.email = null
            this.mailIsValid = false
          }
        }
      }
    },
    created () {
      this.form.actorId = this.$store.getters.detailActor.id
      this.form.email = this.$store.getters.userEmail
    },
    mixins: [CompanyMixin, TranslationsMixin],
    components: {
      Modal,
      FormGroup,
      DsTextarea,
      DsInput,
      Avatar,
      VueRecaptcha,
    },
    watch: {
      canSend (v) {
        if (v) {
          this.resetRecaptcha()
        }
      },
    },
  }
</script>

<style lang="scss">
  .contact__custom-send-button {
    -webkit-box-direction: normal;
    margin: 0;
    box-sizing: inherit;
    transition: unset !important;
    border-radius: unset;
    border: 1px solid transparent;
    cursor: pointer;
    font-family: "Sofia Pro", "Roboto", sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    position: relative;
    padding: 4px 12px 0;
    display: inline-block;
    white-space: nowrap;
    outline: 0;
    line-height: 24px;
    font-size: 12px;
    background-color: #303240;
    color: #F7F7F7;
    margin-right: 0;
  }
</style>
