<template>
  <th class="th-filterable" :width="width">
    <slot></slot>
    <icon class="th-filterable__filter" name="filter" @click.prevent="show = !show" v-if="options">filter</icon>
    <div class="filter-dropdown" v-if="options && show" v-click-away="closeDropdown">
      <radio-button
          v-for="(option, index) in options"
          :key="index"
          :model-value="modelValue"
          name="th-filterable-dropdown"
          :label="option.text"
          :val="option.value"
          @update:modelValue="$emit('update:modelValue', $event)"
      />
    </div>
  </th>
</template>

<script>
import RadioButton from '../Form/RadioButton.vue';
export default {
  props: ['by', 'width', 'options', 'modelValue'],
  emits: ['update:modelValue'],
  data () {
    return {
      'show' : false
    }
  },
  methods: {
    closeDropdown () {
      this.show = false;
    }
  },
  components: {
    RadioButton
  },
}
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";

.filter-dropdown {
  text-transform: none;
  position: absolute;
  z-index: 1;
  background: $color-background-lightest-grey;
  border: 1px #CECECE solid;
  padding: 8px;
  top: calc(100% - 8px);
  left: 4px;
  font-weight: 400;
  font-size: 0.8rem;
  font-family: "Fira Sans", sans-serif;
  white-space: pre;
}
.svg-icon.th-filterable__filter {
  vertical-align: top;
  padding: 3px;
  opacity: .6;
  &:hover {
    opacity: 1;
  }
}
</style>
