<template>
  <div class="rich-body-content" v-html="sanitizedHtml"></div>
</template>

<script>
  import DOMPurify from 'dompurify'
  import { defineComponent } from 'vue'
  import { Conversations } from '@/api/conversations'
  import { ACTION_TYPES as CONVERSATION_ACTION_TYPES } from '@/store/modules/conversations'

  export default defineComponent({
    name: 'RichBodyContent',
    props: {
      html: String,
    },
    mounted () {
      setTimeout(() => {
        // Open conversation when clicking a mentioned user
        const actorUserMentions = document.querySelectorAll('.data-mention-type--user');
        const _self = this

        actorUserMentions.forEach(mention => {
          mention.addEventListener('click', function(e) {
            e.preventDefault()
            const element = e.target
            const userId = element.getAttribute('rel')

            Conversations.post({ recipient_id: userId }).then((conversation) => {
              _self.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
              _self.$router.push('/profile/conversations/' + conversation.id)
            })
          })
        })

      }, 300)
    },
    computed: {
      sanitizedHtml () {
        // Allow HTML attributes for parsed elements in rich body content
        return DOMPurify.sanitize(this.html, { ADD_TAGS: ['iframe', 'a'], ALLOWED_ATTR: ['src', 'frameborder', 'allowfullscreen', 'href', 'target', 'data-mention-id', 'rel', 'class'] })
      },
    },
  })
</script>

<style lang="scss">
  .rich-body-content {
    font-size: 0.875rem;
    margin-bottom: 3rem;

    a {
      line-break: anywhere;
    }

    p,
    ul,
    ol {
      font-size: inherit;
      margin-bottom: 1rem;
    }

    ul,
    ol {
      margin-left: 1.5rem;
    }

    img,
    iframe {
      display: flex;
      justify-content: center;
      margin: auto;
    }

    iframe {
      height: 340px;
      width: 560px;
    }

    ul li {
      list-style-type: disc;
    }

    ol li {
      list-style-type: decimal;
    }
  }
</style>
