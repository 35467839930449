import { createJSONRequest, createResource } from './create-request'
import querify from '../util/querify.js'

export const ManageSpottingAreas = createResource('/api/settings/spotting-areas')
export const SpottingAreas = createResource('/api/spotting-areas')

export const fetchSpottingAreas = (filters) => createJSONRequest({
  url: `/api/spotting-areas?${querify(filters)}`,
  method: 'GET',
})

export const fetchAccessibleSpottingAreas = (role) => createJSONRequest({
  method: 'GET',
  url: `/api/spotting-areas?type=public&role=${role}`,
})

export const updateAccessibleSpottingAreas = (data) => createJSONRequest({
  method: 'POST',
  url: '/api/publisher/spotting-areas',
  data: data,
})

export const fetchFlaggedSpottingAreas = (type) => createJSONRequest({
  method: 'GET',
  url: `/api/settings/spotting-areas/monitoring?type=${type}`,
})

export const fetchSpottingArea = ({ spottingAreaId }) => createJSONRequest({
  url: `/api/spotting-areas/${spottingAreaId}`,
  method: 'GET',
})

export const fetchLatestActors = ({ spottingAreaId }) => createJSONRequest({
  url: `/api/spotting-areas/${spottingAreaId}/latest-actors`,
  method: 'GET',
})

export const fetchActorSuggestions = ({ spottingAreaId, bustCache }) => createJSONRequest({
  url: `/api/spotting-areas/${spottingAreaId}/actor-recommendations?bustCache=${bustCache}`,
  method: 'GET',
})

export const approveActorSuggestion = ({
  spottingAreaId,
  actorOrCentralProfileId,
  segmentId = null,
}) => createJSONRequest({
  url: `/api/spotting-areas/${spottingAreaId}/actor-recommendations/${actorOrCentralProfileId}`,
  method: 'PUT',
  data: {
    status: 'accepted',
    segment_id: segmentId,
  },
})

export const denyActorSuggestion = ({ spottingAreaId, actorOrCentralProfileId }) => createJSONRequest({
  url: `/api/spotting-areas/${spottingAreaId}/actor-recommendations/${actorOrCentralProfileId}`,
  method: 'PUT',
  data: {
    status: 'denied',
  },
})

export const getFundingSpottingArea = ({ spottingAreaId }) => createJSONRequest({
  url: `/api/spotting-areas/${spottingAreaId}/funding`,
  method: 'GET',
})

export const updateSpottingAreaMembers = ({ spottingAreaId, data }) => createJSONRequest({
  url: `/api/settings/spotting-areas/${spottingAreaId}/members`,
  method: 'PUT',
  data,
})

export const deleteSpottingArea = ({ spottingAreaId }) => createJSONRequest({
  url: `/api/settings/spotting-areas/${spottingAreaId}/destroy`,
  method: 'DELETE',
})

export const deleteSegment = ({ spottingAreaId, segmentId }) => createJSONRequest({
  url: `/api/spotting-areas/${spottingAreaId}/segments/${segmentId}`,
  method: 'DELETE',
})

export const createRelation = ({ spottingAreaId, segmentId, data }) => createJSONRequest({
  url: `/api/spotting-areas/${spottingAreaId}/segments/${segmentId}/relationships`,
  method: 'POST',
  data,
})

export const createSegment = ({ spottingAreaId, data }) => createJSONRequest({
  url: `/api/spotting-areas/${spottingAreaId}/segments`,
  method: 'POST',
  data,
})

export const updateSegment = ({ spottingAreaId, segmentId, data }) => createJSONRequest({
  url: `/api/spotting-areas/${spottingAreaId}/segments/${segmentId}`,
  method: 'PUT',
  data,
})

export const removeActorFromSegment = ({ spottingAreaId, segmentId, data }) => createJSONRequest({
  url: `/api/spotting-areas/${spottingAreaId}/segments/${segmentId}/actors`,
  method: 'DELETE',
  data,
})

export const addActorToSegment = ({ spottingAreaId, segmentId, data }) => createJSONRequest({
  url: `/api/spotting-areas/${spottingAreaId}/segments/${segmentId}/actors`,
  method: 'POST',
  data,
})

export const getSegments = ({ spottingAreaId }) => createJSONRequest({
  url: `/api/spotting-areas/${spottingAreaId}/segments`,
  method: 'GET',
})

export const deleteActorFromSpottingArea = (spottingAreaId, actorId) => createJSONRequest({
  url: `/api/spotting-areas/${spottingAreaId}/actors/${actorId}`,
  method: 'DELETE',
})

export const fetchHighlightedSpottingArea = () => createJSONRequest({
  url: '/api/spotting-areas-highlight',
  method: 'GET',
})
