<template>
  <knowledge-base-simplified
    :enable-create="$store.getters.canUserUploadContent"
  />
</template>

<script lang="ts">
  import KnowledgeBaseSimplified from './KnowledgeBaseSimplified.vue'
  import UiMixin from '../../util/UiMixin.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'KnowledgeBase',
    components: {
      KnowledgeBaseSimplified
    },
    mixins: [UiMixin]
  })
</script>
