<template>
  <div class="authentication-modal authentication-modal--big">
    <modal
      :id="modalId"
      ref="modal"
      :title="title"
      :enable-mask="false"
      :is-closeable="true"
    >
      <template v-slot:body>
        <div>
          <relation-create :type="type" @create="handleAddRelation" />
          <p :style="{ visibility: busy ? 'visible' : 'hidden' }">
            <ds-button
              variant="secondary"
              :icon="busy === 'Saving...' ? 'spinner' : 'check'"
              :label="busy"
            />
          </p>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import Modal from './Modal.vue'
import RelationCreate from '../Relation/Create.vue'

import { updateBulkActor } from '../../api/actors'


import { inert } from '../../util/helpers'

export default {
  name: 'add-relation-modal',
  data () {
    return {
      busy: '',
      errors: {},
      modalId: 'ADD_RELATION'
    }
  },
  computed: {
    type () {
      return this.$store.state.ui.modalContext.prop || 'accelerated_by'
    },
    actors () {
      return this.$store.state.ui.modalContext.actors || []
    },
    title () {
      return 'Add relation to ' + (this.actors && this.actors.length > 1 ? this.actors.length + ' ' + this.$store.getters.actorStrings : (this.actors.length === 1 ? this.actors[0].name : 'unknown'))
    }
  },
  methods: {
    handleAddRelation (relationType, actor) {
      const changes = []
      this.actors.forEach(subject => {
        const relations = inert(subject['_' + relationType] || [])
        relations.push({
          from: subject.id,
          from_name: subject.name,
          to: actor.id,
          to_name: actor.name,
          start: null,
          end: null
        })

        const change = {
          id: subject.id
        }
        change[relationType] = relations
        changes.push(change)
      })

      this.busy = 'Saving...'
      updateBulkActor(changes).then(() => {
        this.busy = 'Done!'
        this.$bus.emit('actorUpdated')
        setTimeout(() => {
          this.$refs.modal.close()
        }, 1000)
      })
    }
  },
  components: {
    Modal,
    RelationCreate
  }
}
</script>
