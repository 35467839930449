<template>
  <div class="range-slider">
    <div class="range-slider__info">
      <div class="range-slider__title">{{ label }}</div>
      <div class="range-slider__range-label">{{ rangeLabel }}</div>
    </div>
    <div ref="slider"></div>
  </div>
</template>

<script>
import _isFinite from 'lodash/isFinite'
import noUiSlider from 'nouislider'

import { significantDigits } from './slider'
import { defineComponent } from 'vue'

function toFloat (n) {
  n = parseInt(n, 10) || null
  return _isFinite(n) ? parseFloat(n.toFixed(5)) : null
}

export default defineComponent({
  props: {
    label: null,
    max: null,
    min: null,
    step: null,
    lazy: {
      default: false,
      type: Boolean
    },
    reference: null, // an ID that will be passed with the emit if the change of the ranges
    options: {
      default: () => ({})
    },
    modelValue: {
      default: () => ({ start: null, end: null })
    }
  },
  emits: ['update:modelValue'],
  data () {
    const { start, end } = this.modelValue || { start: null, end: null }
    return { start, end }
  },
  computed: {
    uiState () {
      return [this.start || this.opts.range.min, this.end || this.opts.range.max]
    },
    rangeLabel () {
      if (this.start <= this.opts.range.min || this.start == null) {
        if (this.end >= this.opts.range.max || this.end == null) {
          return ''
        }
        return `max. ${this.end}`
      }
      if (this.end >= this.opts.range.max || this.end == null) {
        return `min. ${this.start}`
      }

      return `${this.start} - ${this.end}`
    },
    opts () {
      return Object.assign({
        connect: true,
        step: parseInt(this.step || 1, 10),
        range: {
          min: parseInt(this.min || 0, 10),
          max: parseInt(this.max || 1000, 10)
        }
      }, this.options)
    }
  },
  mounted () {
    noUiSlider.create(this.$refs.slider, Object.assign(this.opts, {
      start: this.uiState
    }));

    if (this.lazy) {
      this.$refs.slider.noUiSlider.on('set', (a, handleId, [start, end]) => {
        start = start > this.opts.range.min ? significantDigits(start) : null
        end = end < this.opts.range.max ? significantDigits(end) : null

        if (! this.reference) {
          this.$emit('update:modelValue', { start, end });
        } else {
          var reference = this.reference;
          this.$emit('update:modelValue', { start, end, reference });
        }
      });
    }

    this.$refs.slider.noUiSlider.on('slide', (a, handleId, [start, end]) => {
      this.start = significantDigits(start)
      this.end = significantDigits(end)
      if (!this.lazy) {
        start = this.start > this.opts.range.min ? this.start : null
        end = this.end < this.opts.range.max ? this.end : null

        if (! this.reference) {
          this.$emit('update:modelValue', { start, end });
        } else {
          var reference = this.reference;
          this.$emit('update:modelValue', { start, end, reference });
        }
      }
    })
  },
  watch: {
    modelValue (v) {
      let { start, end } = v
      start = toFloat(start)
      end = toFloat(end)
      if ((start && start !== this.start) || (end && end !== this.end)) {
        this.start = start || this.start
        this.end = end || this.end
        this.$refs.slider.noUiSlider.set(this.uiState)
      }
    }
  }
})
</script>
