<template>
  <div class="fixed-heading">
    <ds-button size="small" label="Add news group" icon="plus" variant="secondary"
      @click="addNewsGroup" style="margin-left: auto;"/>

    <div class="scrollable has-padding has-action-bar" style="padding-bottom: 150px;">
      <div class="news-group" :key="'news-group__' + index" v-for="(newsGroup, index) in newsGroups">
        <news-group-component
          :news-group="newsGroup"
          :index="index"
          @remove="removeNewsGroup" ref="newsGroups"
          :show-delete-button="true"
          :allow-rename="true"
        />
      </div>

      <hr class="line" v-if="showExcludedNewsSource">

      <div class="news-group" v-if="showExcludedNewsSource">
        <news-group-component
          :news-group="excludedNewsSource"
          @remove="removeNewsGroup" ref="excludedNewsGroup"
          :show-delete-button="false"
          :allow-rename="false"
        />
      </div>
    </div>

    <action-bar :editing="isEditing || isSaving">
      <ds-button variant="secondary" label="Save changes" @click="save"/>
      <ds-button variant="" label="Cancel" @click="cancel"/>
      <span class="action-bar__message" v-if="successMessage">{{ successMessage }}</span>
    </action-bar>
  </div>
</template>

<script>
  import ActionBar from '../../Form/ActionBar.vue'
  import FormGroup from '../../Form/FormGroup.vue'
  import TagInput from '../../../components/Form/TagInput.vue'
  import NewsGroupComponent from './NewsGroupComponent.vue'

  import { deleteExcludedNewsSource, getExcludedNewsSources, NewsGroups, storeExcludedNewsSource } from '../../../api/exploration'
  import { inert } from '../../../util/helpers'

  export default {
    data () {
      return {
        newsGroups: [],
        originalNewsGroups: [],
        originalExcludedNewsSources: [],
        wasJustLoaded: false,
        isEditing: false,
        isSaving: false,
        showExcludedNewsSource: false,
        successMessage: '',
        excludedNewsSource: {
          name: 'Excluded News',
          sources: []
        }
      }
    },
    methods: {
      addNewsGroup () {
        this.newsGroups.unshift({ name: 'New News Group', sources: [] })

        setTimeout(() => {
          this.$refs.newsGroups[0].$refs.input.focus()
        }, 0)
      },
      removeNewsGroup (index) {
        var copy = [...this.newsGroups]

        copy.splice(index, 1)

        this.newsGroups = []
        this.newsGroups = copy
      },
      cancel () {
        this.newsGroups = inert(this.originalNewsGroups) // inert used as a deep clone here
        this.isEditing = false
        this.wasJustLoaded = true
      },
      save () {
        const toAdd = [] // news group objects (without ID)
        const toUpdate = [] // news group objects (with ID)
        const foundIds = []

        for (const newsGroup of this.newsGroups) {
          if (!newsGroup.id) {
            if (newsGroup.name) {
              toAdd.push(newsGroup)
            }
          } else {
            foundIds.push(newsGroup.id)

            const original = this.originalNewsGroups.find(d => d.id === newsGroup.id)

            if (JSON.stringify(newsGroup) !== JSON.stringify(original)) {
              toUpdate.push(newsGroup)
            }
          }
        }

        const toDelete = this.originalNewsGroups.filter(d => !foundIds.includes(d.id)).map(d => d.id) // IDs

        this.isSaving = true
        this.successMessage = ''

        Promise.all([
          ...toAdd.map(newsGroup => NewsGroups.post(newsGroup).then(({ newsGroupId }) => {
            newsGroup.id = newsGroupId
            NewsGroups.post(newsGroup)
          })),
          ...toUpdate.map(newsGroup => NewsGroups.post(newsGroup)), //
          ...toDelete.map(id => NewsGroups.delete(id))
        ]).then(() => {
          this.isEditing = false
          this.isSaving = false
          this.successMessage = 'The news groups are successfully updated'

          NewsGroups
            .get()
            .then(newsGroups => {
              const sortedNewsGroups = newsGroups.sort((a, b) => a.name.localeCompare(b.name))

              this.originalNewsGroups = inert(sortedNewsGroups) // inert used as a deep clone here
              this.newsGroups = sortedNewsGroups
              this.wasJustLoaded = true
            })
        }).catch(e => {
          this.isSaving = false
          console.error(e)
        })

        this.saveExcludedNewsGroups()
      },
      saveExcludedNewsGroups () {
        const toAdd = []
        const foundIds = []

        this.excludedNewsSource.sources.forEach(source => {
          if (!source.value) {
            if (source.label) {
              toAdd.push(source.label)
            }
          } else {
            foundIds.push(source.value)
          }
        })

        const toDelete = this.originalExcludedNewsSources.filter(d => !foundIds.includes(d.value)).map(d => d.value) // IDs

        this.isSaving = true
        this.successMessage = ''

        Promise.all([
          ...toAdd.map(newsGroup => storeExcludedNewsSource({ url: newsGroup })),
          ...toDelete.map(id => deleteExcludedNewsSource(id))
        ]).then(() => {
          this.isEditing = false
          this.isSaving = false
          this.successMessage = 'The news groups are successfully updated'

          getExcludedNewsSources()
            .then(newsGroups => {
              this.originalExcludedNewsSources = inert(newsGroups) // inert used as a deep clone here
              this.excludedNewsSource.sources = newsGroups
              this.wasJustLoaded = true
            })
        }).catch(e => {
          this.isSaving = false
          console.error(e)
        })
      }
    },
    created () {
      NewsGroups
        .get()
        .then(newsGroups => {
          const sortedNewsGroups = newsGroups.sort((a, b) => a.name.localeCompare(b.name))

          this.originalNewsGroups = inert(sortedNewsGroups) // inert used as a deep clone here
          this.newsGroups = sortedNewsGroups
          this.wasJustLoaded = true
        })

      getExcludedNewsSources()
        .then(excludedNewsSource => {
          const sortedNewsGroups = excludedNewsSource.sort((a, b) => a.label.localeCompare(b.label))

          this.originalExcludedNewsSources = inert(sortedNewsGroups)
          this.excludedNewsSource.sources = sortedNewsGroups
          this.wasJustLoaded = true
          this.showExcludedNewsSource = true
        })
    },
    watch: {
      newsGroups: {
        deep: true,
        handler () {
          if (!this.wasJustLoaded) {
            this.isEditing = true
          } else {
            this.isEditing = false
            this.wasJustLoaded = false
          }
        }
      },
      excludedNewsSource: {
        deep: true,
        handler () {
          if (!this.wasJustLoaded) {
            this.isEditing = true
          } else {
            this.isEditing = false
            this.wasJustLoaded = false
          }
        }
      }
    },
    components: {
      ActionBar,
      FormGroup,
      TagInput,
      NewsGroupComponent
    }
  }
</script>

<style lang="scss" scoped>
  .news-group {
    border: 1px solid #eee;
    padding: 10px;
    margin-bottom: 10px;
    padding-bottom: 0;
  }

  .dictionary__buttons {
    display: flex;
  }

  .line {
    border: 0.5px solid lightgrey;
    margin-top: 20px;
    margin-bottom: 20px;
  }
</style>
