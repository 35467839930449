<template>
  <div
    class="new-card"
    :class="{ 'new-card--fill-available-height': fillAvailableHeight, 'new-card--collapsed': collapsable && isCollapsed }"
    :style="showAccentColor ? { borderLeft: '8px solid ' + accentColor } : null"
  >
    <div v-if="title" class="new-card__header" :class="hideHeaderSeparator ? 'no-separator' : ''">
      <div class="new-card__title" v-if="! isTitleClickable">{{title}}</div>
      <div class="new-card__title" v-else>
        <a :href="link" target="_blank">{{title}}</a>
      </div>
      <div class="new-card__header__left-section" v-if="$slots.headerLeft">
        <slot name="headerLeft"></slot>
      </div>
      <div class="new-card__header__right-section" v-if="$slots.headerRight">
        <slot name="headerRight"></slot>
      </div>
      <div class="new-card__header__collapse-button" v-if="collapsable">
        <ds-button
          v-if="alternateCollapseButton && !compactCollapseButton"
          variant="default"
          :icon="isCollapsed ? 'chevron-down' : 'chevron-up'"
          size="extra-small"
          @click="toggleCollapsedState"
        />
        <icon
          v-if="alternateCollapseButton && compactCollapseButton"
          :name="isCollapsed ? 'chevron-down' : 'chevron-up'"
          size="extra-small"
          @click="toggleCollapsedState"
        />
        <ds-button
          v-if="!alternateCollapseButton"
          variant="default"
          :icon="isCollapsed ? 'arrow-expand' : 'arrow-compress'"
          size="extra-small"
          @click="toggleCollapsedState"
        />
      </div>
    </div>
    <div class="new-card__content" v-if="!isCollapsed">
      <slot></slot>
    </div>
    <div class="new-card__content" v-if="isCollapsed && hasIgnoreSlot">
      <slot name="ignoreSlot"></slot>
    </div>
  </div>
</template>

<script>
  import { trackHeapEvent } from '../../util/analytics'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'NewCard',
    props: {
      title: String,
      fillAvailableHeight: Boolean,
      hideHeaderSeparator: {
        type: Boolean,
        default: false,
      },
      showAccentColor: {
        type: Boolean,
        default: false,
      },
      accentColor: {
        type: String,
        default: '#FEC949',
      },
      collapsable: {
        type: Boolean,
        default: false,
      },
      forceOpen: {
        type: Boolean,
        default: false,
      },
      isCollapsedOnInit: {
        type: Boolean,
        default: false,
      },
      hasIgnoreSlot: {
        type: Boolean,
        default: false,
      },
      alternateCollapseButton: {
        type: Boolean,
        default: false,
      },
      compactCollapseButton: {
        type: Boolean,
        default: false,
      },
      link: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        isCollapsed: this.isCollapsedOnInit,
      }
    },
    computed: {
      isTitleClickable () {
        return this.link && this.link.length > 0
      }
    },
    methods: {
      toggleCollapsedState () {
        this.isCollapsed = !this.isCollapsed
        this.$emit('isCollapsed', this.isCollapsed)
        trackHeapEvent('global.card.toggleCardExpansion', { isCollapsed: this.isCollapsed })
      },
    },
    watch: {
      forceOpen () {
        if (this.forceOpen) {
          this.isCollapsed = false
        }
      }
    }
  })
</script>

<style lang="scss">
  @import "../../../scss/variables";

  .new-card {
    padding: 0.75rem;
    margin-bottom: 1rem;
    border: 1px solid $color-body-borders;

    &:last-child {
      margin-bottom: 0;
    }

    .new-card__header__collapse-button {
      .svg-icon {
        cursor: pointer;
      }

      .button {
        height: 22px;
        border: 1px solid $color-borders;
        .svg-icon {
          width: 15px;
          height: 15px;
        }
        &:hover {
          background-color: $color-secondary;
        }
      }
    }
  }

  .new-card--fill-available-height {
    height: 100%;
  }

  .new-card--collapsed {
    .new-card__header {
      margin-bottom: 0;
      border-bottom: 0;
      padding-bottom: 0;
    }
  }

  .new-card__header {
    display: flex;
    align-items: center;
    padding: 0 0 0.5rem 0;
    margin-bottom: 1rem;
    border-bottom: 1px solid #CECECE;

    &.no-separator {
      border-bottom: 0;
      margin-bottom: 0;
    }
  }

  .new-card__title {
    flex: 1;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 12px;
  }
  .new-card__header__left-section {
    flex: 7;
  }
  .new-card__content {
    word-break: break-word;
  }
</style>
