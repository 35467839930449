<template>
  <span class="label-filter__text"
        :class="{disabled: disabled, selected: isEnabled, transparent: variant === 'transparent'}"
        @click="onClick">
    {{ text }}
    <icon v-if="icon" :name="icon" size="14"/></span>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'label-filter',
  props: {
    isEnabled: Boolean,
    text: String,
    modelValue: String | Number,
    icon: String,
    variant: {
      type: String,
      validator: value => ['default', 'transparent'].includes(value),
      default: () => 'default',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {},
  methods: {
    onClick() {
      if (!this.disabled) {
        this.$emit('updateFilter', this.modelValue)
      }
    },
  },
})
</script>

<style lang="scss" scoped>

@import "../../../scss/_variables.scss";

.label-filter__text {
  border: 1px solid $color-background-light-grey;
  text-transform: uppercase;
  cursor: pointer;
  margin-right: 3px;
  display: inline-block;
  padding: 3px 6px;
  user-select: none;

  &.selected {
    background: $color-background-light-grey;
    color: #333;
  }

  &.transparent {
    background: none;
    border: none;
  }

  &.disabled {
    border: 1px solid $color-background-light-grey !important;
    color: $color-background-light-grey !important;;
  }

  &.disabled &.selected {
    border: 1px solid $color-background-light-grey !important;;
    background: $color-background-light-grey !important;;
    color: white;
  }
}
</style>
