<template>
  <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
    <div class="row" style="width: 100%; position: relative; flex-direction: column">
      <template v-if="filteredConnections.length > 0">
        <div class="connections_view_buttons">
          <div :class="{'active': !listView}">
            <ds-button label="Nodes" size="small" @click="changeBody('nodes')" icon="circle-nodes" variant="primary"/>
          </div>
          <div :class="{'active': listView}">
            <ds-button label="List" size="small" @click="changeBody('list')" icon="bars" variant="primary"/>
          </div>
          <ds-button class="connections_view_button" :label="numberOfConnections" size="small" variant="primary"/>
          <ds-button v-if="userCanEdit" @click="clickEditConnections" label="Edit connections" size="small" icon="pen" variant="primary"/>
        </div>
        <div v-if="listView">
          <ListView @actorUpdated="this.$emit('connectionSuggestionApproved')"/>
        </div>
        <template v-else>
          <graph-view
            :actor="actor"
            :connections="filteredConnections"
            @openEditSidePanel="$emit('openEditSidePanel')"
          >
          </graph-view>
        </template>
      </template>
      <template v-else>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6 col-md-offset-3 col-lg-offset-3">
          <CallToActionCard text="There are no connections to display">
            <template v-if="userCanEdit" v-slot:callToActionSlot>
              <div>
                <ds-button class="call-to-action-card__action-button" variant="primary" label="Add Connection" @click="openSidepanel"/>
              </div>
            </template>
          </CallToActionCard>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
  import _groupBy from 'lodash/groupBy'

  import GraphView from './GraphView.vue'
  import NewCard from '../../components/NewCard/NewCard.vue'
  import CallToActionCard from '../../components/CallToActionCard/CallToActionCard.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { trackHeapEvent } from '../../util/analytics'
  import CompanyMixin from '../../util/CompanyMixin'

  import { enrichActor } from '../../api/actors.js'
  import ListView from '../ListView.vue'
  import { _intersect } from '../../util/helpers'

  const MAX_COLUMNS = 4

  export default {
    name: 'ActorDetailConnections',
    props: {
      actor: Object,
      connections: Array,
    },
    data () {
      return {
        hiddenTypes: [],
        fetchingData: false,
        suggestions: [],
        listView: false,
      }
    },
    emits: ['connectionSuggestionApproved'],
    computed: {
      numberOfConnections () {
        return this.connections.filter(connection => connection.has_access === true).length + '&nbsp; connections'
      },
      userCanEdit () {
        // Actors cannot be edited on exploration-only platforms (aka scouting platform)
        if (this.$store.getters.isExplorationOnly) {
          return false
        }

        return (this.$store.getters.isMember && this.$store.getters.allowEdit) ||
          this.$store.getters.userClaimedIds.includes(this.company.id) ||
          (_intersect(this.$store.getters.userContributionPortfolios, this.actorPortfolios).length > 0 && this.$store.getters.hasAccessToPortfolioSharing && this.$store.getters.isPortfolioMember) ||
          this.$store.getters.isOwner
      },
      ecosystemRelationships () {
        return this.$store.getters.fullActorRelationships
      },
      company () {
        return this.$store.getters.detailActor
      },
      filteredConnections () {
        const data = this.connections.filter(connection => connection.has_access === true &&
          !this.hiddenTypes.some(hiddenType => hiddenType === connection.type) && connection.name && connection.name.length > 0,
        )

        return data
      },
      connectionsByType () {
        const connectionsGroupedByType = _groupBy(this.connections, 'type')
        return Object.keys(connectionsGroupedByType).reduce(
          (list, type) => {
            list.push({
              type: type,
              label: this.ecosystemRelationships.find(r => r.name === type).label,
              connections: connectionsGroupedByType[type],
            })

            return list
          },
          [],
        )
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
    },
    methods: {
      trackHeapEvent,
      handleRemoveRelation (relationType, id) {
        this.$emit('removeRelation', relationType, id)
      },
      openSidepanel () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          component: 'actor-connections-panel',
        })
      },
      crawlPortfolio () {
        enrichActor(this.actor.id, { enricher: 'portfolio_crawler' })
          .then(() => {
            this.portfolioCrawlingState = 'crawling_dispatched'
            this.hasTriggeredPortfolioCrawling = true
          })
          .catch(() => {
            this.portfolioCrawlingState = 'crawling_dispatched'
            this.hasTriggeredPortfolioCrawling = true
          })
      },
      crawlPortfolioClicked () {
        if (this.crawlAction == 'discover') {
          this.crawlPortfolio()
        } else if (this.crawlAction == 'open_suggestions') {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'actor-suggestions', metaData: this.actor.id })
        }
      },
      clickEditConnections (e) {
        e.preventDefault()
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          component: 'actor-connections-panel',
        })
      },
      changeBody (type) {
        this.$store.commit(UI_MUTATION_TYPES.SET_ACTOR_DETAIL_CONNECTIONS_VIEW_TYPE, type)
        if (type === 'list') {
          this.listView = true
        }
        this.listView = (type === 'list')
      },
    },
    mounted () {
      if (this.$store.state.ui.actorDetailConnectionsViewType) {
        this.changeBody(this.$store.state.ui.actorDetailConnectionsViewType)
      }
    },
    mixins: [CompanyMixin],
    components: {
      ListView,
      GraphView,
      NewCard,
      CallToActionCard,
    },
  }
</script>

<style lang="scss">
  .graph-view-aside {
    padding-right: 10px;
    padding-top: 80px;
    position: absolute;
    right: 0;
    top: 0;
    width: 240px;
  }

  .discover-new-actors__button {
    font-size: 13px !important;
    width: 100%;
  }

  .row {
    button {
      margin-bottom: 14px;
      border-radius: 4px;
    }

    .connections_view_buttons {
      display: flex;

      button {
        margin-right: 10px;
        background-color: #fff;
        border: 1px solid #CCCCCC;
        color: #A5A5A5;

        .svg-icon path {
          fill: #A5A5A5;
        }
      }

      .active button {
        color: var(--primary);
        background-color: #F9F9F9;

        .svg-icon path {
          fill: var(--primary);
        }
      }

      .connections_view_button {
        background-color: var(--primary);
        color: #fff;
        border-color: var(--primary);
      }
    }
  }
</style>
