import { fetchHighlightedSpottingArea, SpottingAreas } from '../../api/spottingareas.js'
import { assertArray } from '../../util/helpers.js'

const state = {
  detail: {
    data: {},
    loading: false,
    error: null,
    errorStatusCode: null,
  },
  listData: {
    fetchLimit: 10,
    loading: false,
    data: [],
    error: null,
    endOfFeed: false,
    flushCache: false,
  },
  highlightedSpottingArea: {
    data: {},
    loading: false,
    error: null,
    errorStatusCode: null,
    editedData: {
      id: null,
      color: '',
    },
  },
}

export const ACTION_TYPES = {
  FETCH_SPOTTING_AREAS_LIST: 'SPOTTING_AREAS/FETCH_SPOTTING_AREAS_LIST',
  FETCH_SPOTTING_AREA_DETAIL: 'SPOTTING_AREAS/FETCH_SPOTTING_AREA_DETAIL',
  FETCH_SPOTTING_AREA_HIGHLIGHT: 'SPOTTING_AREAS/FETCH_SPOTTING_AREA_HIGHLIGHT',
  EDITED_SPOTTING_AREA_HIGHLIGHT: 'SPOTTING_AREAS/EDITED_SPOTTING_AREA_HIGHLIGHT',
}

export const MUTATION_TYPES = {
  FETCH_SPOTTING_AREAS_LIST_PENDING: 'SPOTTING_AREAS/FETCH_SPOTTING_AREAS_LIST_PENDING',
  FETCH_SPOTTING_AREAS_LIST_SUCCESS: 'SPOTTING_AREAS/FETCH_SPOTTING_AREAS_LIST_SUCCESS',
  FETCH_SPOTTING_AREAS_LIST_FAILURE: 'SPOTTING_AREAS/FETCH_SPOTTING_AREAS_LIST_FAILURE',
  FETCH_SPOTTING_AREA_DETAIL_PENDING: 'SPOTTING_AREAS/FETCH_SPOTTING_AREA_DETAIL_PENDING',
  FETCH_SPOTTING_AREA_DETAIL_SUCCESS: 'SPOTTING_AREAS/FETCH_SPOTTING_AREA_DETAIL_SUCCESS',
  FETCH_SPOTTING_AREA_DETAIL_FAILURE: 'SPOTTING_AREAS/FETCH_SPOTTING_AREA_DETAIL_FAILURE',
  FETCH_SPOTTING_AREA_HIGHLIGHT_PENDING: 'SPOTTING_AREAS/FETCH_SPOTTING_AREA_HIGHLIGHT_PENDING',
  FETCH_SPOTTING_AREA_HIGHLIGHT_SUCCESS: 'SPOTTING_AREAS/FETCH_SPOTTING_AREA_HIGHLIGHT_SUCCESS',
  FETCH_SPOTTING_AREA_HIGHLIGHT_FAILURE: 'SPOTTING_AREAS/FETCH_SPOTTING_AREA_HIGHLIGHT_FAILURE',
  UPDATE_SPOTTING_AREA_HIGHLIGHT_PENDING: 'SPOTTING_AREAS/UPDATE_SPOTTING_AREA_HIGHLIGHT_PENDING',
  UPDATE_SPOTTING_AREA_HIGHLIGHT_SUCCESS: 'SPOTTING_AREAS/UPDATE_SPOTTING_AREA_HIGHLIGHT_SUCCESS',
  UPDATE_SPOTTING_AREA_HIGHLIGHT_FAILURE: 'SPOTTING_AREAS/UPDATE_SPOTTING_AREA_HIGHLIGHT_FAILURE',
  UPDATE_EDITED_SPOTTING_AREA_HIGHLIGHT: 'SPOTTING_AREAS/EDITED_SPOTTING_AREA_HIGHLIGHT',
}

export const actions = {
  [ACTION_TYPES.FETCH_SPOTTING_AREAS_LIST]: ({ commit }) => {
    return SpottingAreas.get()
      .then(response => {
        commit(MUTATION_TYPES.FETCH_SPOTTING_AREAS_LIST_SUCCESS, response)
      })
      .catch(errors => {
        commit(MUTATION_TYPES.FETCH_SPOTTING_AREAS_LIST_FAILURE, errors)
      })
  },
  [ACTION_TYPES.FETCH_SPOTTING_AREA_DETAIL] ({ commit }, id) {
    if (!id) {
      console.warn('The Spotting area ID is expected to be a number')
      return new Promise((resolve) => resolve())
    }

    const cachedSpottingArea = reuseFromListData(id)
    if (cachedSpottingArea) {
      commit(MUTATION_TYPES.FETCH_SPOTTING_AREA_DETAIL_PENDING, id)
      commit(MUTATION_TYPES.FETCH_SPOTTING_AREA_DETAIL_SUCCESS, cachedSpottingArea)
      return new Promise((resolve) => resolve())
    }

    if (state.detail.loading === id) {
      console.log('already loading detail', id)
      return new Promise((resolve) => resolve())
    }

    if (state.detail.errorId === id) {
      console.log('already tried loading detail', id)
      return new Promise((resolve) => resolve())
    }

    commit(MUTATION_TYPES.FETCH_SPOTTING_AREA_DETAIL_PENDING, id)

    return SpottingAreas.get(id)
      .then(spottingArea => {
        commit(MUTATION_TYPES.FETCH_SPOTTING_AREA_DETAIL_SUCCESS, spottingArea)
      })
      .catch(errors => {
        commit(MUTATION_TYPES.FETCH_SPOTTING_AREA_DETAIL_FAILURE, {
          message: ': An error occurred while fetching the spotting area.',
          statusCode: errors.statusCode,
        })
      })
  },
  [ACTION_TYPES.FETCH_SPOTTING_AREA_HIGHLIGHT] ({ commit }) {
    commit(MUTATION_TYPES.FETCH_SPOTTING_AREA_HIGHLIGHT_PENDING)

    return fetchHighlightedSpottingArea()
      .then((spottingArea) => {
        commit(MUTATION_TYPES.FETCH_SPOTTING_AREA_HIGHLIGHT_SUCCESS, spottingArea)
      })
      .catch((errors) => {
        commit(MUTATION_TYPES.FETCH_SPOTTING_AREA_HIGHLIGHT_FAILURE, {
          message: 'An error occurred while fetching the highlighted spotting area.',
          statusCode: errors.statusCode,
        })
      })
  },
  [ACTION_TYPES.EDITED_SPOTTING_AREA_HIGHLIGHT] ({ commit }, editedSpottingArea) {
    console.log('edited', editedSpottingArea)
    commit(MUTATION_TYPES.UPDATE_EDITED_SPOTTING_AREA_HIGHLIGHT, editedSpottingArea)
  },
}

function reuseFromListData (id) {
  return state.listData.data.find(a => a.id === id)
}

function isStillOnRequestedPage (id) {
  return state.detail.loading === id
}

export const mutations = {
  [MUTATION_TYPES.FETCH_SPOTTING_AREA_DETAIL_SUCCESS] (state, spottingArea) {
    if (isStillOnRequestedPage(spottingArea.id)) {
      state.detail.data = spottingArea
    }

    state.detail.error = null
    state.detail.loading = false
    state.detail.errorId = null
    state.detail.errorStatusCode = null
  },
  [MUTATION_TYPES.FETCH_SPOTTING_AREA_DETAIL_FAILURE] (state, error) {
    state.detail.data = {}
    state.detail.errorId = state.detail.loading
    state.detail.error = error.message
    state.detail.loading = false
    state.detail.errorStatusCode = error.statusCode
  },
  [MUTATION_TYPES.FETCH_SPOTTING_AREA_DETAIL_PENDING] (state, id) {
    state.detail.loading = Number(id)
  },
  [MUTATION_TYPES.FETCH_SPOTTING_AREAS_LIST_PENDING] (state) {
    state.listData.loading = true
  },
  [MUTATION_TYPES.FETCH_SPOTTING_AREAS_LIST_SUCCESS] (state, data) {
    state.listData.endOfFeed = data.length < state.listData.fetchLimit
    if (state.listData.flushCache) {
      state.listData.data = assertArray(data)
    } else {
      // Reset the listData
      state.listData.data = []
      for (const spottingArea of assertArray(data)) {
        state.listData.data.push(spottingArea)
      }
    }

    state.listData.flushCache = false
    state.listData.loading = false
  },
  [MUTATION_TYPES.FETCH_SPOTTING_AREAS_LIST_FAILURE] (state, error) {
    state.listData.error = error
    state.listData.endOfFeed = true
    state.listData.loading = false
  },
  [MUTATION_TYPES.FETCH_SPOTTING_AREA_HIGHLIGHT_SUCCESS] (state, spottingArea) {
    state.highlightedSpottingArea.data = spottingArea
    state.highlightedSpottingArea.editedData = spottingArea
    state.highlightedSpottingArea.error = null
    state.highlightedSpottingArea.loading = false
    state.highlightedSpottingArea.errorStatusCode = null
  },
  [MUTATION_TYPES.FETCH_SPOTTING_AREA_HIGHLIGHT_FAILURE] (state, error) {
    state.highlightedSpottingArea.data = {}
    state.highlightedSpottingArea.editedData = {}
    state.highlightedSpottingArea.error = error.message
    state.highlightedSpottingArea.loading = false
    state.highlightedSpottingArea.errorStatusCode = error.statusCode
  },
  [MUTATION_TYPES.FETCH_SPOTTING_AREA_HIGHLIGHT_PENDING] (state) {
    state.highlightedSpottingArea.loading = true
  },
  [MUTATION_TYPES.UPDATE_SPOTTING_AREA_HIGHLIGHT_SUCCESS] (state, spottingArea) {
    state.highlightedSpottingArea.data = spottingArea
    state.highlightedSpottingArea.editedData = spottingArea
    state.highlightedSpottingArea.error = null
    state.highlightedSpottingArea.loading = false
    state.highlightedSpottingArea.errorStatusCode = null
  },
  [MUTATION_TYPES.UPDATE_SPOTTING_AREA_HIGHLIGHT_FAILURE] (state, error) {
    state.highlightedSpottingArea.data = {}
    state.highlightedSpottingArea.editedData = {}
    state.highlightedSpottingArea.error = error.message
    state.highlightedSpottingArea.loading = false
    state.highlightedSpottingArea.errorStatusCode = error.statusCode
  },
  [MUTATION_TYPES.UPDATE_SPOTTING_AREA_HIGHLIGHT_PENDING] (state) {
    state.highlightedSpottingArea.loading = true
  },
  [MUTATION_TYPES.UPDATE_EDITED_SPOTTING_AREA_HIGHLIGHT] (state, editedSpottingArea) {
    state.highlightedSpottingArea.editedData = editedSpottingArea
  },
}

export default {
  state,
  mutations,
  actions,
}
