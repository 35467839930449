<template>
  <div :class="classes" :title="tag.text">
    <add-to-dictionary-button
        v-if="(isOwner || isTeamMember) && $store.getters.hasAccessToExplorationOrMonitoring && tag.uri && !disableAddToDictionary"
        :keyword="tag" :icon="icon" size="small"/>
    {{ tag.label }}
  </div>
</template>

<script>
import AddToDictionaryButton from '../AddToDictionaryButton/AddToDictionaryButton.vue'
import DictionaryMixin from '../../util/DictionaryMixin'
import { defineComponent } from 'vue'

export default defineComponent({
  components: {
    AddToDictionaryButton,
  },
  mixins: [DictionaryMixin],
  name: 'rich-tag',
  props: {
    tag: Object,
    size: String,
    disableAddToDictionary: {
      type: Boolean,
      default: false,
    },
    tagIcon: {
      type: String,
    }
  },
  computed: {
    icon() {
      if (this.tagIcon) {
        return this.tagIcon
      }

      if (this.tag.type === 'loc') {
        return 'globe'
      }

      if (this.tag.type === 'org') {
        return 'building'
      }
    },
    isOwner() {
      return this.$store.getters.isOwner
    },
    isTeamMember() {
      return this.$store.getters.isTeamMember;
    },
    classes() {
      return {
        'tag': true,
        [`tag--${this.size}`]: this.size,
        [`tag--active`]: this.tag.isActive,
        [`tag--dictionary`]: this.$store.getters.hasAccessToExplorationOrMonitoring && this.keywordExistsInAnyDictionary(this.tag),
      }
    }
  }
})
</script>

<style lang="scss">
.tag {
  display: inline-block;
  background: #EEEEEE;
  border: 1px solid #CECECE;
  border-radius: 1px;
  padding: 0.25rem;
  margin-right: 0.75rem;
  margin-bottom: 0.75rem;
  font-size: 0.75rem;

  &:last-child {
    margin-right: 0;
  }
}

.tag--small {
  padding: 0.1rem 0.2rem;
  margin-right: 0.35rem;
  font-size: 11px;
  height: 18px;
}

.tag--dictionary {
  .dropdown-button {
    font-weight: bold;

    svg > path {
      fill: var(--primary);
    }
  }
}
</style>
