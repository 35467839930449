export function fromArray (a) {
  return Array.isArray(a) ? a.join(',\n') : a
}

export function toArray (a) {
  return a && a.split ? a.split(/,+/).map(s => s.trim()) : []
}

export function ArrayEditor () {
  const editor = Handsontable.editors.TextEditor.prototype.extend()

  editor.prototype.getValue = function() {
    return fromArray(toArray(this.TEXTAREA.value))
  }

  editor.prototype.setValue = function(newValue) {
    this.TEXTAREA.value = fromArray(toArray(newValue))
  }

  return editor
}

export function ArrayRenderer(instance, TD, row, col, prop, value, cellProperties) {
  Handsontable.renderers.TextRenderer.apply(this, [instance, TD, row, col, prop, fromArray(value), cellProperties])
}
