<template>
  <modal ref="modal" :id="modalId" @close="$emit('close')">
    <template v-slot:body>
      <div>
        <template v-if="action === 'edit'">
          Change <b>{{ user.name }}</b> role to<br><br>
          <form-group label="Role" v-if="roleOptions.length > 1">
            <radio-button
              v-for="(role, index) in roleOptions"
              :key="index"
              v-model="user.role"
              name="role"
              :label="role.text"
              :val="role.value"
            />
          </form-group>
        </template>
        <form-group label="Select (premium only) or create a Spotting Area" v-if="user.role === 'portfolio_member'">
          <autocomplete-tag-input
            :tags="selectedSpottingArea"
            :options="spottingAreas"
            :addOnlyFromAutocomplete="false"
            :minInputLength="1"
            class="dictionary-component__grow"
            :max-tags="1"
            @input:raw="updateChosenSpottingArea($event)"
            placeholder="Add or select a spotting area"
          />

        </form-group>

        <form-group label="Permissions on te spotting area (Note: the user will receive this permission for all portfolios in the spotting area)"
          v-if="this.selectedSpottingArea && this.selectedSpottingArea[0]">
          <radio-button
            v-model="pickedPortfolioPermission"
            name="permission"
            label="Contributor"
            :val="roles.CONTRIBUTOR"
            :required="true"
            v-if="user.role === 'portfolio_member'"
          />
          <radio-button
            v-model="pickedPortfolioPermission"
            name="permission"
            label="Explorer"
            :val="roles.EXPLORER"
            :required="true"
            v-if="user.role === 'portfolio_member'"
          />
        </form-group>
        <template v-if="action === 'accept'">
          Accept <b>{{ user.name }}</b> (<a :href="'mailto:' + user.email">{{ user.email }}</a>) into this ecosystem?
        </template>
        <template v-if="action === 'deny'">
          Deny <b>{{ user.name }}</b> (<a :href="'mailto:' + user.email">{{ user.email }}</a>) from this ecosystem?
        </template>
        <template v-if="action === 'cancel-invitation'">
          Do you want to cancel the invitation for {{ user.email }}?
        </template>
        <template v-if="action === 'resend-invitation'">
          Do you want to resend the invitation email for {{ user.email }}? The last invitation was sent at {{ user.updated_at }}.
        </template>
        <template v-if="action === 'resend-confirmation'">
          Do you want to resend the confirmation email for {{ user.email }}?
        </template>
        <p v-if="errors && !selectedSpottingArea" class="form-group__error">{{ errors }}</p>
      </div>
    </template>
    <template v-slot:footer>
      <div>
        <ds-button label="Ok" variant="primary" @click="save"/>
        <ds-button label="Cancel" variant="secondary" @click="close"/>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import MODAL_IDS from '../../constants/modal-ids'
  import Dropdown from '../../components/Dropdown/Dropdown.vue'
  import RadioButton from '../Form/RadioButton.vue'

  import { inert } from '../../util/helpers.js'
  import { asyncConfig, deleteUser, updateUser } from '../../api/config.js'
  import { cancelInvitation, resendConfirmation, resendInvitation } from '../../api/user.js'
  import AutocompleteTagInput from '../Form/AutocompleteTagInput.vue'
  import { roles } from '../../store/modules/portfolios.js'

  export default {
    data () {
      return {
        errors: null,
        modalId: MODAL_IDS.USER_EDIT,
        user: {},
        action: 'edit',
        spottingAreas: null,
        selectedSpottingArea: [],
        pickedPortfolioPermission: '',
        roles,
      }
    },
    mixins: [asyncConfig],
    computed: {
      roleOptions () {
        if (this.isOwner) {
          return this.allowedRoles
        } else if (this.isOwner) {
          return this.allowedRoles.filter(role => role.value !== 'portfolio_member')
        }

        return this.allowedRoles.filter(role => role.value !== 'owner' && role.value !== 'portfolio_member')
      },
      isPublicEcosystem () {
        return this.$store.getters.isPublic
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
    },
    methods: {
      transformSpottingAreaData () {
        const spottingAreas = this.$store.state.spottingAreas.listData.data

        const transformedSpottingAreas = []
        spottingAreas.forEach((spottingArea) => {
          transformedSpottingAreas.push({
            label: spottingArea.name,
            value: spottingArea.id,
          })
        })

        this.spottingAreas = transformedSpottingAreas
      },
      updateChosenSpottingArea (event) {
        this.selectedSpottingArea = event
      },
      save () {
        const { id } = this.user
        if (this.action === 'deny') {
          updateUser({ id, team_verified: false }).then(() => {
            this.$bus.emit('userUpdated')
            this.close()
          })
        } else if (this.action === 'accept') {
          updateUser({ id, team_verified: true }).then(() => {
            this.$bus.emit('userUpdated')
            this.close()
          })
        } else if (this.action === 'edit') {
          const spottingArea = {}
          if (this.selectedSpottingArea && this.selectedSpottingArea[0]) {
            spottingArea.label = this.selectedSpottingArea[0].text ? this.selectedSpottingArea[0].text : null
            spottingArea.value = this.selectedSpottingArea[0].value ? this.selectedSpottingArea[0].value : null
            spottingArea.portfolio_permission = this.pickedPortfolioPermission
          }

          updateUser({ id, role: this.user.role, spotting_area: spottingArea }).then(() => {
            this.$bus.emit('userUpdated')
            this.close()
          })
        } else if (this.action === 'remove') {
          deleteUser({ id }).then(() => {
            this.$bus.emit('userDeleted')
            this.$bus.emit('userUpdated')
            this.close()
          })
        } else if (this.action === 'cancel-invitation') {
          cancelInvitation({ id }).then(() => {
            this.$bus.emit('userUpdated')
            this.close()
          })
        } else if (this.action === 'resend-invitation') {
          resendInvitation({ id }).then(() => {
            this.$bus.emit('userUpdated')
            this.close()
          })
        } else if (this.action === 'resend-confirmation') {
          resendConfirmation({ id }).then(() => {
            this.$bus.emit('userUpdated')
            this.close()
          })
        }
      },
      close () {
        this.$refs.modal.close()
      },
    },
    mounted () {
      this.user = inert(this.$store.state.ui.modalContext.user)
      this.action = this.$store.state.ui.modalContext.action
    },
    created () {
      this.transformSpottingAreaData()
    },
    components: {
      AutocompleteTagInput,
      Modal,
      Dropdown,
      RadioButton,
    },
  }
</script>
