<template>
  <div class="home-simplified">
    <div class="home__report_call_countdown_container no-margin" v-if="callCountdownTemplate && isLoggedIn">
      <span class="home__report_call_countdown" v-html="callTimeDifference"></span><span class="home__report_call_text">{{ headlineText }}</span>
      <ds-button :icon="applyButtonIcon" :label="applyButtonLabel" @click="onClickApplyForCallOnHomepage" v-tooltip.left="ownedLegalEntityReportUrl ? null : applyButtonTooltip" variant="primary" class="home__report_call_button" size="small"></ds-button>
    </div>
    <div class="simple-hero" :style="`background-image: url(${image})`" style="padding-bottom: 0.5rem">
      <Container class="simple-hero__container">
        <template v-if="$store.state.config.homepage">
          <a :href="$store.state.config.homepage" target="_blank">
            <img class="simple-hero__logo" :src="logo"/>
          </a>
        </template>
        <template v-else>
          <img class="simple-hero__logo" :src="logo"/>
        </template>
        <div class="simple-hero__content">
          <div class="simple-hero__title">{{ title }}</div>
          <div class="simple-hero__description">
            <div v-html="tagline"></div>
          </div>
        </div>
      </Container>
    </div>

    <Container>
      <ul class="category-list">
        <li class="category category--active">
          <button :style="getCategoryButtonStyles(null)" @click="handleClickClearAllKeywords">{{ $t('categories_all') }}</button>
        </li>
        <li class="category" v-for="legendItem in legendItems">
          <button
            :style="getCategoryButtonStyles(legendItem.value)"
            @click="handleSelectLegendItem(legendItem.value)"
          >
            {{ legendItem.label }}
          </button>
        </li>
      </ul>

      <div class="home-simplified__global-search-link home-simplified__space-vertical">
        <a href="#" @click="displayGlobalSearch">&#8594; {{ $t('search_popup_link') }}</a>
      </div>

      <template v-if="!hasKeywords">
        <template v-for="(_, index) in noKeywordsGroupCount">
          <div class="row">
            <div
              class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 home-simplified__space-vertical"
              v-for="actor in actors.data.slice(index * 3, (index + 1) * 3)"
            >
              <SimpleCard
                :imgUrl="actor.featured_image_url || actor.website_screenshot || getDefaultFallbackImageUrl()"
                :force-aspect-ratio="true"
                :title="actor.name"
                :content="localizedDisplayProperty(actor, 'short_description') || localizedDisplayProperty(actor, 'activities_description')"
                @click="handleClickActorCard(actor)"
              />
            </div>
          </div>
          <SimpleArticlePreview
            v-if="publicNotifications.data[index]"
            class="home-simplified__space-vertical"
            :imagePosition="index % 2 === 0 ? 'left' : 'right'"
            :notificationId="publicNotifications.data[index].id"
            :title="localizedDisplayProperty(publicNotifications.data[index], 'title')"
            :body="localizedDisplayProperty(publicNotifications.data[index], 'shortHtml')"
            :commentCount="Number(publicNotifications.data[index].comments)"
            :likeCount="Number(publicNotifications.data[index].likes)"
            :cta="publicNotifications.data[index].action_url"
            :ctaText="publicNotifications.data[index].action_text"
            @click="trackAnnouncementClick"
            :image="getImageForAnnouncement(publicNotifications.data[index])"
            :to="`/announcements/${publicNotifications.data[index].slug || publicNotifications.data[index].id}`"
          />
        </template>
      </template>
      <template v-if="hasKeywords">
        <div class="row">
          <div
            class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 home-simplified__space-vertical"
            v-for="actor in actors.data.slice(0, actorLimitWithActiveKeywords)"
          >
            <SimpleCard
              :imgUrl="actor.featured_image_url || actor.website_screenshot || getDefaultFallbackImageUrl()"
              :force-aspect-ratio="true"
              :title="actor.name"
              :content="localizedDisplayProperty(actor, 'short_description') || localizedDisplayProperty(actor, 'activities_description')"
              @click="handleClickActorCard(actor)"
            />
          </div>
        </div>
      </template>

      <br>

      <div class="text-center" v-if="isSeeMoreButtonVisible">
        <DsButton variant="secondary" :label="$t('see_more_results')" @click="handleClickSeeMore"/>
        <br>
        <br>
      </div>

      <div style="height: 400px; position: relative;">
        <MapView
          ref="mapPane"
          :data="mapData.data"
          :getMapData="getMapData"
          :getData="getMapData"
          :noSearchWhileMoving="true"
          :disableMouseWheelZoom="true"
        />
      </div>
    </Container>

    <SimplifiedFooter/>
  </div>
</template>

<script lang="ts">
  import Container from '../Container/Container.vue'
  import SimpleCard from '../SimpleCard/SimpleCard.vue'
  import { fetchPublicNotifications } from '../../api/notifications.js'
  import SimpleArticlePreview from '../SimpleArticlePreview.vue'
  import SimplifiedFooter from '../SimplifiedFooter/SimplifiedFooter.vue'
  import MapView from '../MapView/MapView.vue'
  import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../../store/modules/actors.js'
  import FiltersMixin from '../../util/FiltersMixin.js'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import { fetchStatsData } from '../../api/homepage.js'
  import { trackHeapEvent } from '../../util/analytics.js'
  import { getTranslationsForTaxonomyValue } from '../../store/modules/taxonomies.js'
  import { getCallTimeDifference } from '../../util/date.js'
  import ReportMixin from '../../util/ReportMixin.js'
  import { getDefaultFallbackImageUrl, getImageForAnnouncement } from '../../util/helpers.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'HomeSimplified',
    components: {
      SimpleArticlePreview,
      Container,
      SimpleCard,
      MapView,
      SimplifiedFooter,
    },
    data: () => {
      return {
        noKeywordsGroupCount: 3,
        actorLimitWithActiveKeywords: 9,
        publicNotifications: {
          data: [],
          isLoading: false,
        },
        legendCounts: {},
        callTimeDifference: '',
        callTimeInterval: null,
      }
    },
    mounted () {
      this.fetchPublicNotifications()
      this.fetchStatsData()
      this.getMapData()
      this.fetchFilteredActors()

      if (this.callCountdownTemplate) {
        this.callTimeInterval = setInterval(() => {
          this.callTimeDifference = this.getCallTimeDifference(this.callCountdownTemplate.due_date)
        }, 1000)
      }
    },
    beforeUnmount () {
      if (this.callTimeInterval) {
        clearInterval(this.callTimeInterval)
      }
    },
    computed: {
      headlineText () {
        return this.localizedDisplayProperty(this.config, 'headlineText')
      },
      logo () {
        return this.config.logo_url_light || this.config.logo_url
      },
      hasKeywords () {
        return this.$store.state.filters.keywords.length > 0
      },
      isSeeMoreButtonVisible () {
        if (this.hasKeywords) {
          const canShowMoreActors = this.actors.data.length > this.actorLimitWithActiveKeywords
          return canShowMoreActors
        }

        const canShowMoreActors = this.actors.data.length > this.noKeywordsGroupCount * 3
        const canShowMoreAnnouncements = Boolean(this.publicNotifications.data[this.noKeywordsGroupCount + 1])
        return canShowMoreActors || canShowMoreAnnouncements
      },
      filters () {
        return this.$store.getters.baseFilterObject
      },
      actors () {
        return this.$store.state.actors.listData
      },
      config () {
        return this.$store.state.config
      },
      locale () {
        return this.$store.getters.activeLocale
      },
      legendItems () {
        if (!this.legendCounts.stats) {
          return []
        }

        return this.legendCounts.stats.map(result => {
          const translations = getTranslationsForTaxonomyValue(this.legendCounts.property, result.value)
          let label = ''

          if (translations && translations[this.locale] && translations[this.locale].name) {
            label = translations[this.locale].name
          } else {
            label = translations['en'].name
          }

          result['label'] = label

          return result
        })
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      title () {
        return this.isLoggedIn
          ? this.localizedDisplayProperty(this.config, 'introTitleLoggedIn')
          : this.localizedDisplayProperty(this.config, 'introTitleLoggedOut')
      },
      image () {
        return this.config.homepage_knowledge_base_image
      },
      tagline () {
        return this.isLoggedIn
          ? this.localizedDisplayProperty(this.config, 'tagline')
          : this.localizedDisplayProperty(this.config, 'taglineLoggedOut')
      },
      mapFilter () {
        return this.$store.getters.mapFilterObject
      },
      mapData () {
        return this.$store.state.actors.mapData
      },
    },
    mixins: [FiltersMixin, TranslationsMixin, ReportMixin],
    methods: {
      getDefaultFallbackImageUrl,
      getImageForAnnouncement,
      getCallTimeDifference,
      handleClickSeeMore () {
        if (this.hasKeywords) {
          this.actorLimitWithActiveKeywords = this.actorLimitWithActiveKeywords + 3
        } else {
          this.noKeywordsGroupCount = this.noKeywordsGroupCount + 1
        }

        // Track the click
        trackHeapEvent('homepageSimplified.clickSeeMore')
      },
      handleClickActorCard (actor) {
        // Track the click
        trackHeapEvent('homepageSimplified.clickActorCard')

        this.$router.push(`/actors/${actor.id}`)
      },
      handleSelectLegendItem (legendItem) {
        this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR_LEGEND_ITEMS)
        this.$store.commit(FILTERS_MUTATION_TYPES.TOGGLE_LEGEND_ITEM, {
          facet: this.config.legendProperty,
          value: legendItem,
        })

        // Track the click
        trackHeapEvent('homepageSimplified.clickLegendItem', { legendItem })
      },
      handleClickClearAllKeywords () {
        this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR_KEYWORDS)
      },
      fetchFilteredActors () {
        const listFilters = { ...this.$store.getters.listFilterObject }
        this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_LIST, listFilters)
      },
      fetchPublicNotifications () {
        const filters = {}

        if (this.locale) {
          filters['locale'] = this.locale
        }

        fetchPublicNotifications(5, 0, filters).then(items => {
          this.publicNotifications.data = items
        })
      },
      getCategoryButtonStyles (value) {
        const activeKeywords = this.$store.state.filters.keywords
        const activeCategoryButtonStyles = {
          backgroundColor: this.config.primaryColor,
          color: '#fff',
        }
        const inactiveCategoryButtonStyles = {
          backgroundColor: '#fff',
          color: 'black',
        }

        if (!value) {
          const containsNoFilters = activeKeywords.length === 0
          return containsNoFilters
            ? activeCategoryButtonStyles
            : inactiveCategoryButtonStyles
        } else {
          const isActive = Boolean(
            activeKeywords.find(
              k => k.facet === this.config.legendProperty && k.value === value,
            ),
          )
          return isActive
            ? activeCategoryButtonStyles
            : inactiveCategoryButtonStyles
        }
      },
      getMapData () {
        let filters
        if (this.config && this.config.viewActorTypes && this.config.viewActorTypes.length) {
          filters = Object.assign({}, this.$store.getters.mapFilterObject, { actor_type: this.config.viewActorTypes })
        } else {
          filters = Object.assign({}, this.$store.getters.mapFilterObject)
        }

        if (filters.tl) {
          this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_MAP, filters)
        }
      },
      renderMap () {
        this.$refs.mapPane && this.$refs.mapPane[0] && this.$refs.mapPane[0].invalidateSize()
      },
      displayGlobalSearch () {
        // Track the click
        trackHeapEvent('homepageSimplified.clickGlobalSearchLink')

        this.$store.commit(UI_MUTATION_TYPES.TOGGLE_GLOBAL_SEARCH)
      },
      fetchStatsData () {
        var parameters = {}

        fetchStatsData(parameters)
          .then(response => {
            this.legendCounts = response.data
          })
          .catch(errors => {
            //
          })
      },
      trackAnnouncementClick () {
        trackHeapEvent('homepageSimplified.clickAnnouncement')
      },
    },
    watch: {
      filters: {
        deep: true,
        handler () {
          this.fetchFilteredActors()
          this.getMapData()
        },
      },
      'mapData': {
        handler (newValue, oldValue) {
          if (newValue.length !== oldValue.length) {
            setTimeout(this.renderMap, 200)
          }
        },
      },
      'mapFilter': {
        handler () {
          this.getMapData()
        },
      },
      'locale': {
        handler () {
          // Allow for the locale to be persist in the back-end first
          this.fetchPublicNotifications()
        },
      },
    },
  })
</script>

<style lang="scss">
@import "resources/assets/scss/variables";

.home-simplified {
  display: block;
  width: 100%;
  height: 100%;
  overflow: hidden;
  overflow-y: auto;
}

.home-simplified__global-search-link {
  display: flex;
  justify-content: flex-end;
}

.simple-hero {
  margin-bottom: 1rem;
  background-size: cover;
  background-position: center;
}

.simple-hero__container {
  position: relative;
  height: 100%;
  padding-top: 1rem;

  ul, ol {
    margin-left: 1rem;
  }
}

.simple-hero__title {
  font-size: 2rem;
}

.simple-hero__content {
  padding: 1rem;
  color: #fff;
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.5);
}

.simple-hero__description {
  * {
    font-size: 0.875rem;
  }
}

.category-list {
  list-style-type: none;
}

.category {
  display: inline-block;
  margin-right: 0.25rem;
  margin-bottom: 0.25rem;

  &:last-child {
    margin-right: 0;
  }

  button {
    padding: 0.5rem;
    border: 1px solid black;
    font-family: $font-stack-primary;
    font-size: 1rem;
    opacity: 0.8;
    transition: all 0.2s;
  }

  &:hover {
    button {
      cursor: pointer;
    }
  }
}

.category--active {
  button {
    font-weight: bold;
    opacity: 1;
  }
}

.home-simplified__space-vertical {
  margin-bottom: 30px;
}
</style>
