<template>
  <div>
    <div class="taxonomies-input" v-for="(mainTaxonomy, d) in mainTaxonomies">
      <checkbox :label="getTaxonomyName(mainTaxonomy)" :model-value="enabled(mainTaxonomy.id)" @update:modelValue="toggle(mainTaxonomy, $event)"/>
      <div :class="{ 'taxonomies-input--muted': !enabled(mainTaxonomy.id, true), 'taxonomies-input__wrapper': true}" v-if="mainTaxonomy.children.length > 0" @mousedown="toggle(mainTaxonomy, true)">
        <div class="taxonomies-input__sub" v-for="child in mainTaxonomy.children" @click.stop>
          <checkbox inline :label="getChildName(child)" :model-value="enabled(child.id)" @update:modelValue="toggle(child, $event)"/>
          <ds-textarea
            v-if="enabled(child.id) && isRemarkable"
            style="border: 1px solid white; border-bottom: 1px solid black; width: 100%"
            autoresize
            class="taxonomies-input__remark"
            placeholder="Add details or remarks"
            :model-value="getRemark(child.id)"
            @update:modelValue="setRemark(child.id, $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Checkbox from './Checkbox.vue'
  import DsTextarea from './DsTextarea.vue'
  import { inert } from '../../util/helpers'
  import TranslationsMixin from '../../util/TranslationsMixin'

  export default {
    props: {
      modelValue: Array,
      taxonomyName: String,
      mainTaxonomies: Array
    },
    emits: ['update:modelValue'],
    data () {
      return {
        collapsed: this.$store.state.taxonomies[this.taxonomyName].map(() => true),
      }
    },
    computed: {
      allIds () {
        return (this.modelValue || []).map(d => d.id)
      },
      parentIds () {
        return (this.modelValue || []).map(d => d.parentId)
      },
      isRemarkable () {
        return ['productFeaturesA', 'productFeaturesB', 'productFeaturesC', 'domains'].includes(this.taxonomyName)
      },
    },
    methods: {
      getTaxonomyName (taxonomy) {
        return this.getLabelForTaxonomyValue(this.taxonomyName, taxonomy.id)
      },
      getChildName (taxonomy) {
        const label = this.getLabelForTaxonomyValue(this.taxonomyName, taxonomy.id)

        if (label.includes(' > ')) {
          return label.split('>').pop()
        }

        return label
      },
      enabled (id, parent) {
        return this.allIds.includes(id) || (parent && this.parentIds.includes(id))
      },
      toggle (taxonomy, value) {
        const current = this.modelValue || []
        if (value) {
          if (this.enabled(taxonomy.id)) {
            return
          }
          taxonomy = inert(taxonomy)
          value = current.concat(inert(taxonomy))
        } else {
          value = current.filter(d => d.id !== taxonomy.id)

          // In case the selected taxonomy is a parent, deselect the children as well
          if (taxonomy.children) {
            taxonomy.children.map(c => {
              value = value.filter(d => d.id !== c.id)
            })
          }
        }

        this.$emit('update:modelValue', value)
      },
      getRemark (id) {
        const model = (this.modelValue.find(d => d.id === id) || {})
        if (model != null && model.pivot != null && model.pivot.remark != null) {
          model.remark = model.pivot.remark
          return model.remark
        }
        return model.remark
      },
      setRemark (id, value) {
        (this.modelValue.find(d => d.id === id) || {}).remark = value
        if ((this.modelValue.find(d => d.id === id) || {}).pivot) {
          (this.modelValue.find(d => d.id === id) || {}).pivot.remark = value
        }
      },
    },
    mixins: [TranslationsMixin],
    components: {
      Checkbox,
      DsTextarea,
    },
  }
</script>

<style lang="scss">
.taxonomies-input {
  + .taxonomies-input {
    margin-top: 1rem;
  }
}
.taxonomies-input__wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.taxonomies-input__sub {
  padding-right: 0;
  padding-left: 1rem;
  flex-basis: 33%;
}
.taxonomies_with_remarks-input__remark {
  width: calc(100%);
}
.taxonomies-input--muted {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  column-width: 12em;

  .taxonomies-input__sub {
    pointer-events: none;
    color: #999;
    padding-right: 12px;

    .radio > input {
      display: none;
    }
  }
}
</style>
