import {
  cancelIfLate,
  createJSONRequest,
  createPagedRequest,
  createResource,
  default as createRequest,
} from './create-request'
import querify from '../util/querify'

export const fetchRecentNotifications = () => createJSONRequest({
  method: 'GET',
  url: '/notifications/recent',
})

// Public notification == public announcement
export const fetchPublicNotifications = (limit, offset = 0, filters = {}) => createJSONRequest({
  method: 'GET',
  url: '/notifications/public?' + [limit && `limit=${limit}`, offset && `offset=${offset}`, querify(filters)].filter(p => p).join('&'),
})

export const fetchPublicNotificationsFacets = (limit, offset = 0, filters = {}) => createJSONRequest({
  method: 'GET',
  url: '/notifications/public-facets?' + [limit && `limit=${limit}`, offset && `offset=${offset}`, querify(filters)].filter(p => p).join('&'),
})

export const fetchPublicNotificationsCount = (filters = {}) => createJSONRequest({
  method: 'GET',
  url: `/notifications/public-count?${querify(filters)}`,
})

export const fetchEventsCal = (limit, offset = 0, filters = {}) => createJSONRequest({
  method: 'GET',
  url: '/notifications/events-cal?' + [limit && `limit=${limit}`, offset && `offset = ${offset}`, querify(filters)].filter(p => p).join('&'),
})

export const fetchPublicNotification = (id) => createJSONRequest({
  method: 'GET',
  url: `/notifications/public/${id}`,
})

export const fetchNotificationPage = (limit, offset = 0, filters = {}) => createJSONRequest({
  method: 'GET',
  url: '/notifications/all?limit=' + limit + '&offset=' + offset + '&' + querify(filters),
})

export const fetchNotification = (id) => createRequest({
  url: `/notifications/${id}?`,
})

export const readNotifications = (notifications) => createJSONRequest({
  method: 'PUT',
  url: '/notifications/read',
  data: {
    notifications,
  },
})
export const readAllNotifications = () => createJSONRequest({
  method: 'POST',
  url: '/notifications/allread',
})

export const likeNotification = (id, like) => createJSONRequest({
  method: 'PUT',
  url: `/notifications/${id}/like`,
  data: {
    like,
  },
})

export const featureNotification = (id) => createJSONRequest({
  method: 'PUT',
  url: `/notifications/${id}/feature`,
})

export const unfeatureNotification = (id) => createJSONRequest({
  method: 'PUT',
  url: `/notifications/${id}/unfeature`,
})

export const pinNotification = (id, pin) => createJSONRequest({
  method: 'PUT',
  url: `/notifications/${id}/pin`,
  data: {
    pin,
  },
})

export const eventCalendarNotification = (id, eventCalendar) => createJSONRequest({
  method: 'PUT',
  url: `/notifications/${id}/event-calendar`,
  data: {
    'event-calendar': eventCalendar,
  },
})

/**
 * Curate public announcements
 */
export const acceptPublicAnnouncement = (id) => createJSONRequest({
  method: 'PUT',
  url: `/notifications/${id}/accept`,
})

export const denyPublicAnnouncement = (id) => createJSONRequest({
  method: 'PUT',
  url: `/notifications/${id}/deny`,
})

export const fetchMostUsedTags = cancelIfLate((filters) => createJSONRequest({
  method: 'GET',
  url: `/api/announcements/most-used-tags?${querify(filters)}`,
}))

/**
 * Notification resource
 */
export const Notifications = createResource('/notifications')

/**
 * Notification comments
 */

export const fetchUsersToMention = (filters) => createPagedRequest({
  url: `/notifications/comments/suggested-mentions?${querify(filters)}`,
  method: 'GET',
})

export const createComment = (id, comment) => createJSONRequest({
  method: 'POST',
  url: `/notifications/${id}/comments`,
  data: {
    comment,
  },
})

export const getComments = (id) => createJSONRequest({
  method: 'GET',
  url: `/notifications/${id}/comments`,
})

export const deleteComment = (id, commentId) => createJSONRequest({
  method: 'DELETE',
  url: `/notifications/${id}/comments/${commentId}`,
})

/**
 * Event registration
 */
export const registerForEvent = (notificationId, title) => createJSONRequest({
  method: 'POST',
  url: `/api/event-registration`,
  data: {
    notificationId: notificationId,
    eventTitle: title,
  },
})
