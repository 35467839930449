<template>
  <div class="completion-bar">
    <div
      v-for="bar in bars"
      class="completion-bar__section"
      :class="'completion-bar__section-' + bar.index"
      :style="{ width: bar.width }"
    />
  </div>
</template>

<script>
  const AMOUNT_OF_SECTIONS_IN_COMPLETION_BAR = 5;

  export default {
    name: 'CompletionBar',
    props: {
      completion: Number,
    },
    computed: {
      bars() {
        return new Array(5).fill('').map((_, index) => {
          const width = Math.min(
            100 / AMOUNT_OF_SECTIONS_IN_COMPLETION_BAR,
            Math.max(
              0,
              this.completion - (index * (100 / AMOUNT_OF_SECTIONS_IN_COMPLETION_BAR))
            )
          );
          return {
            index: index + 1,
            width: `${width}%`,
          };
        });
      }
    }
  }
</script>

<style lang="scss">
  @import "../../../scss/variables";

  $completion-bar-section-colors-by-index: (
    1: #376FB3,
    2: #2D87B0,
    3: #22A6AC,
    4: #269074,
    5: #29824D,
  );

  .completion-bar {
    width: 100%;
    height: 15px;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid $color-borders;
  }

  .completion-bar__section {
    border-right: 1px solid #fff;
    width: 20%;
    height: 15px;
    display: inline-block;

    &:last-child {
      border-right: 0;
    }
  }

  @each $index, $fillColor in $completion-bar-section-colors-by-index {
    .completion-bar__section-#{$index} {
      background-color: $fillColor;
    }
  }
</style>
