import { cancelIfLate, createJSONRequest, createPagedRequest } from './create-request'
import querify from '../util/querify'

// Fetch data for knowledge base
// Accepted parameters: tags & query
export const fetchKnowledgeBaseData = cancelIfLate((parameters) => createPagedRequest({
  method: 'GET',
  url: `/api/dashboards/knowledge?${querify(parameters)}`,
}))

export const buildExportContentLink = (parameters) => {
  return `/api/dashboards/knowledge?${querify(parameters)}`
}

export const acceptContent = (fileId) => createJSONRequest({
  url: `/api/files/${fileId}/accept`,
  method: 'PUT',
})

export const denyContent = (fileId) => createJSONRequest({
  url: `/api/files/${fileId}/deny`,
  method: 'PUT',
})

export const contentFeedback = (fileId, { feedback, dictionary_id }) => createJSONRequest({
  url: `/api/files/${fileId}/feedback`,
  method: 'PUT',
  data: { feedback, dictionary_id },
})

export const annotateArticle = (id, conceptSearchId, bustCache = false) => createJSONRequest({
  method: 'GET',
  url: `/api/annotate/${id}?concept_search_id=${conceptSearchId}&bustCache=${bustCache}`,
})

export const trackArticle = (fileId, conceptSearchId, type) => createJSONRequest({
  method: 'PUT',
  url: `/api/files/${fileId}/track`,
  data: { conceptSearchId, type },
})

export const likeArticle = (fileId, status) => createJSONRequest({
  method: 'PUT',
  url: `/api/files/${fileId}/like`,
  data: { status },
})

export const removeArticle = (fileId, conceptSearchId) => createJSONRequest({
  method: 'PUT',
  url: `/api/files/${fileId}/remove-from-search`,
  data: { conceptSearchId },
})
