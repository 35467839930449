<template>
  <transition name="modal">
    <div class="modal__mask" @click="blur" :class="{'modal__simplified': isSimplifiedModal, 'without-footer': !showFooter}">
      <div class="modal__wrapper" :class="wrapperClasses">
        <div class="modal__container" :class="classes" @click.stop>
          <div class="modal__header" v-if="title || showTitleTabs" :class="{'modal__header--has-multiple-tabs': showTitleTabs}">
            <h2 class="title" v-if="!customTitle && !showTitleTabs">{{title}}&nbsp;&nbsp;</h2>
            <div v-else v-html="title"></div>
            <slot name="title"></slot>
            <hr class="divider" v-if="showDivider">
            <button class="close" @click="close" v-if="isCloseable">×</button>
          </div>

          <div class="modal__body">
            <slot name="body"></slot>
          </div>

          <div class="modal__footer" v-if="showFooter">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import KEYCODES from '../../constants/keycodes'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      closeOnBlur: Boolean,
      id: {
        type: String
      },
      isSimplifiedModal: {
        type: Boolean,
        default: false
      },
      isCloseable: {
        type: Boolean,
        default: true
      },
      showDivider: {
        type: Boolean,
        default: true
      },
      showFooter: {
        type: Boolean,
        default: true
      },
      title: {
        type: String,
        default: ''
      },
      customTitle: {
        type: Boolean,
        default: false,
      },
      showTitleTabs: {
        type: Boolean,
        default: false,
      },
      enableMask: {
        type: Boolean,
        default: true,
      },
      size: {
        type: String,
        default: '',
        validator: val => ['large', '', 'flex'].includes(val),
      },
      wrapperSize: {
        type: String,
        default: '',
        validator: val => ['large', '', 'flex'].includes(val),
      },
      allowOverflow: {
        type: Boolean,
        default: false,
      },
      variant: {
        // NOTE: if variant "rounded" needs to be integrated in the "simplified" version of the modal, then just use that class and remove this property
        type: String,
        default: ''
      }
    },
    created () {
      window.addEventListener('keyup', this.keyUpHandler)
    },
    methods: {
      keyUpHandler (e) {
        if (e.keyCode === KEYCODES.ESC) {
          this.close()
        }
      },
      blur () {
        if (this.closeOnBlur) {
          this.close()
        }
      },
      close () {
        if (this.isCloseable) {
          this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, this.id)
          this.$emit('close')
        }
      }
    },
    computed: {
      doesntContainFooterClass () {
        return this.classes && this.classes.filter(a => a === 'without-footer').length === 0
      },
      classes () {
        return {
          [`modal__container--${this.size}`]: this.size,
          'modal__container__rounded': this.variant === 'rounded'
        }
      },
      wrapperClasses () {
        return {
          [`modal__wrapper--${this.wrapperSize}`]: this.wrapperSize,
          'modal__wrapper--overflow': this.allowOverflow
        }
      },
    },
    mounted () {
      document.body.className = document.body.className + ' modal--open'
    },
    unmounted () {
      document.body.className = (document.body.className || '').replace('modal--open', '')
      window.removeEventListener('keyup', this.keyUpHandler)
    }
  })
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

.modals__container .modal__wrapper--overflow {
  overflow: visible;
}
.modal--scrollable {
  display: block!important;
  padding: 100px 0;
  overflow: auto;
  bottom: 0;
  >.modal__wrapper {
    display: block;
    margin: 0 auto;
  }
}
.modal__container__rounded {
  border-radius: $default-border-radius;
}
.modal--open .main-view,
.modal--open .main-view .actor-detail {
  overflow: hidden
}

.modal__header {
  flex-direction: row;
  justify-content: space-between;
  display: flex;
}


</style>
