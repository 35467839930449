<template>
  <label class="radio" :class="{ 'radio--inline': inline, 'radio--disabled': disabled }">
    <input
      type="radio"
      :disabled="disabled"
      :name="name"
      :checked="checked"
      @change="handleChange"
      :required="required"
    >
    <span class="radio__label">{{ label }}</span>
    <span v-if="typeof disabled === 'string'" v-text="disabled"></span>
  </label>
</template>

<script type="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      disabled: Boolean,
      inline: {
        type: Boolean,
        default: false
      },
      required: {
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        required: true
      },
      name: {
        type: String,
        required: true
      },
      // Value if checked
      val: {
        required: true
      },
      // Current value of v-model
      modelValue: {
        required: true
      }
    },
    emits: ['update:modelValue'],
    computed: {
      checked () {
        return this.val === this.modelValue
      }
    },
    methods: {
      handleChange (evt) {
        if (evt.target.checked) {
          this.$emit('update:modelValue', this.val)
        }
      }
    }
  })
</script>
