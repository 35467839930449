<template>
  <div class="concept-map__loading">
    <icon name="spinner"/> {{$t('loading')}}
  </div>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    //
  })
</script>

<style lang="scss" scoped>
  .concept-map__loading {
    margin-top: 20px;
    text-align: center;
    transform: scale(1.5);

    .svg-icon {
      position: relative;
      top: 5px;
    }
  }
</style>

<style lang="scss">
  @import "../../../../scss/variables";

  .concept-map__loading .svg-icon .fill-fg {
    fill: $color-primary;
  }
</style>
