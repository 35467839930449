<template>
  <NewCard title="Report status">
    <p v-if="template && reports && totalReports > 0">The graph below shows the evolution of <b>{{ reportTitle }} for "{{ template }}"</b>.</p>

    <div v-if="template && reports && totalReports > 0" class="chart-container">
      <div class="chart-main">
        <SimpleLineChart :data="reportsChartData" :y-step-size="1">
        </SimpleLineChart>
      </div>

      <div class="chart-aside">
        <EcosystemInfoCard>
          <div class="text-muted">
            Total reports
          </div>
          <span class="text-lg">
            {{ totalReports }}
          </span>
        </EcosystemInfoCard>

        <ChartLegend :data="reportsChartData">
        </ChartLegend>
      </div>
    </div>
    <div v-else>
      No results were found for the current selection.
    </div>

    <div v-if="!template">
      <!-- Loading... -->
      No reporting template selected...
    </div>
  </NewCard>
</template>

<script>
import ChartLegend from './../../Settings/Heartbeat/ChartLegend.vue';
import EcosystemInfoCard from './../../Settings/Heartbeat/EcosystemInfoCard.vue';
import NewCard from '../../../components/NewCard/NewCard.vue'
import SimpleLineChart from '../../../components/Chart/SimpleLineChart.vue'
import { parseChartData } from '../../Chart/chartsMixin.js'

const sum = (a, b) => a + b;
const sumValues = obj => Object.values(obj).reduce(sum);

export default {
  props: {
    reportTitle: String,
    reports: Object,
    template: String,
  },
  components: {
    ChartLegend,
    EcosystemInfoCard,
    NewCard,
    SimpleLineChart
  },
  computed: {
    reportsChartData() {
      const datasets = [];

      if (typeof this.reports.submitted_count != 'undefined') {
        if (!Array.isArray(this.reports.submitted_count)) {
          datasets.push({
            color: '#4A90E2',
            data: this.reports.submitted_count,
            label: 'Submitted',
          });
        }
      }

      if (typeof this.reports.started_count != 'undefined') {
        if (!Array.isArray(this.reports.started_count)) {
          datasets.push({
            color: '#FDB6FF',
            data: this.reports.started_count,
            label: 'Started',
          });
        }
      }

      if (typeof this.reports.updated_count != 'undefined') {
        if (!Array.isArray(this.reports.updated_count)) {
          datasets.push({
            color: '#3C8088',
            data: this.reports.updated_count,
            label: 'Updated',
          });
        }
      }

      if (datasets.length > 0) {
        return parseChartData(datasets);
      } else {
        return null;
      }
    },
    totalReports () {
      var $amount = 0

      if (!this.reports) {
        return $amount
      }

      if (typeof this.reports.total_submitted != 'undefined') {
        $amount += this.reports.total_submitted
      }

      if (typeof this.reports.total_started != 'undefined') {
        $amount += this.reports.total_started
      }

      return $amount
    },
  },
  methods: {
  }
}
</script>
