<template>
  <modal :id="modalId" ref="modal" @close="close" :enable-mask="true"
         :is-closeable="true" :title="title">
    <template v-slot:body>
      <div>
        <rich-body-content :html="config.tagline"/>
      </div>
    </template>
    <template v-slot:footer>
      <div>
        <checkbox class="checkbox-inline" :label="isOwner ? $t('hide_welcome_message_owner') : $t('hide_welcome_message')" v-model="hasSeenWelcomeMessage" style="float: left;"/>
        <ds-button @click="close" label="Ok"/>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import MODAL_IDS from '../../constants/modal-ids'
  import RichBodyContent from '../RichBodyContent/RichBodyContent.vue'
  import { MUTATION_TYPES as USER_MUTATION_TYPES } from '../../store/modules/user-helpers.js'
  import Checkbox from '../Form/Checkbox.vue'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'

  export default {
    data () {
      return {
        hasSeenWelcomeMessage: false,
        modalId: MODAL_IDS.WELCOME_MESSAGE,
        success: '',
      }
    },
    computed: {
      isOwner () {
        return this.$store.getters.isOwner;
      },
      config () {
        return this.$store.state.config
      },
      title () {
        return this.config.introTitleLoggedIn
      },
    },
    methods: {
      close () {
        if (this.hasSeenWelcomeMessage) {
          this.$store.commit(USER_MUTATION_TYPES.STORE_SETTINGS, { hasSeenWelcomeMessage: this.hasSeenWelcomeMessage })
        }
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, this.modalId)
        this.$emit('close')
      },
    },
    created () {
    },
    components: {
      Modal,
      RichBodyContent,
      Checkbox,
    },
  }
</script>

<style lang="scss" scoped>
</style>
