<template>
  <div class="empty-state__global-search" :class="{'has-fuzzy-results': hasFuzzyResults}">
    <p class="empty-state__global-search__text">{{ text }}</p>
    <div
        :class="{'empty-state__global-search__items': resultType === GLOBAL_SEARCH_RESULT_TYPES.ALL && isLoggedIn}"
        :style="isMobile && GLOBAL_SEARCH_RESULT_TYPES.RESOURCE && GLOBAL_SEARCH_RESULT_TYPES.CHALLENGE ? 'padding: 30px 0 30px 0;' : ''">
      <template
          v-if="(resultType === GLOBAL_SEARCH_RESULT_TYPES.ACTOR || resultType === GLOBAL_SEARCH_RESULT_TYPES.ALL) && userCanAddActor">
        <div
            :class="{'empty-state__global-search__items': resultType !== GLOBAL_SEARCH_RESULT_TYPES.ALL, 'empty-state__global-search__searchItems': resultType}">
          <div class="empty-state__global-search__item">
            <icon :name="GLOBAL_SEARCH_RESULT_TYPE_ICONS[GLOBAL_SEARCH_RESULT_TYPES.ACTOR]" size="18"/>
            <a @click="createInput(GLOBAL_SEARCH_RESULT_TYPES.ACTOR)">{{
                $t('global_search_add_type', {
                  type: GLOBAL_SEARCH_RESULT_TYPES.ACTOR,
                  query: query
                })
              }}</a>
          </div>
        </div>
      </template>
      <template
          v-if="(resultType === GLOBAL_SEARCH_RESULT_TYPES.PRODUCT || resultType === GLOBAL_SEARCH_RESULT_TYPES.ALL) && userCanAddProduct">
        <div
            :class="{'empty-state__global-search__items': resultType !== GLOBAL_SEARCH_RESULT_TYPES.ALL , 'empty-state__global-search__searchItems': resultType}">
          <div class="empty-state__global-search__item">
            <icon :name="GLOBAL_SEARCH_RESULT_TYPE_ICONS[GLOBAL_SEARCH_RESULT_TYPES.PRODUCT]" size="18"/>
            <a @click="createInput(GLOBAL_SEARCH_RESULT_TYPES.PRODUCT)">{{
                $t('global_search_add_type', {
                  type: this.productLabel.toLowerCase(),
                  query: query
                })
              }}</a>
          </div>
        </div>
      </template>
      <template
          v-if="(resultType === GLOBAL_SEARCH_RESULT_TYPES.ANNOUNCEMENT || resultType === GLOBAL_SEARCH_RESULT_TYPES.ALL) && canCreateAnnouncement">
        <div
            :class="{'empty-state__global-search__items': resultType !== GLOBAL_SEARCH_RESULT_TYPES.ALL , 'empty-state__global-search__searchItems': resultType}">
          <div class="empty-state__global-search__item">
            <icon :name="GLOBAL_SEARCH_RESULT_TYPE_ICONS[GLOBAL_SEARCH_RESULT_TYPES.ANNOUNCEMENT]" size="18"/>
            <a @click="createInput(GLOBAL_SEARCH_RESULT_TYPES.ANNOUNCEMENT)">{{
                $t('global_search_add_type', {
                  type: this.announcementLabel.toLowerCase(),
                  query: query
                })
              }}</a>
          </div>
        </div>
      </template>
      <template
          v-if="(resultType === GLOBAL_SEARCH_RESULT_TYPES.EVENT || resultType === GLOBAL_SEARCH_RESULT_TYPES.ALL) && canCreateAnnouncement">
        <div
            :class="{'empty-state__global-search__items': resultType !== GLOBAL_SEARCH_RESULT_TYPES.ALL , 'empty-state__global-search__searchItems': resultType}">
          <div class="empty-state__global-search__item">
            <icon :name="GLOBAL_SEARCH_RESULT_TYPE_ICONS[GLOBAL_SEARCH_RESULT_TYPES.EVENT]" size="18"/>
            <a @click="createInput(GLOBAL_SEARCH_RESULT_TYPES.EVENT)">{{
                $t('global_search_add_type', {
                  type: this.eventLabel.toLowerCase(),
                  query: query
                })
              }}</a>
          </div>
        </div>
      </template>
      <template
          v-if="(resultType === GLOBAL_SEARCH_RESULT_TYPES.CHALLENGE || resultType === GLOBAL_SEARCH_RESULT_TYPES.ALL) && canCreateChallenge && !isMobile">
        <div
            :class="{'empty-state__global-search__items': resultType !== GLOBAL_SEARCH_RESULT_TYPES.ALL , 'empty-state__global-search__searchItems': resultType}">
          <div class="empty-state__global-search__item">
            <icon :name="GLOBAL_SEARCH_RESULT_TYPE_ICONS[GLOBAL_SEARCH_RESULT_TYPES.CHALLENGE]" size="18"/>
            <a @click="createInput(GLOBAL_SEARCH_RESULT_TYPES.CHALLENGE)">{{
                $t('global_search_add_type', {
                  type: this.challengeLabel.toLowerCase(),
                  query: query
                })
              }}</a>
          </div>
        </div>
      </template>
      <template v-if="(resultType === GLOBAL_SEARCH_RESULT_TYPES.ARTICLE) && canUserUploadContent">
        <div
            :class="{'empty-state__global-search__items': resultType !== GLOBAL_SEARCH_RESULT_TYPES.ALL , 'empty-state__global-search__searchItems': resultType}">
          <div class="empty-state__global-search__item">
            <icon :name="GLOBAL_SEARCH_RESULT_TYPE_ICONS[GLOBAL_SEARCH_RESULT_TYPES.ARTICLE]" size="18"/>
            <a @click="createInput(GLOBAL_SEARCH_RESULT_TYPES.ARTICLE)">{{
                $t('global_search_add_type', {
                  type: GLOBAL_SEARCH_RESULT_TYPES.ARTICLE,
                  query: query
                })
              }}</a>
          </div>
        </div>
      </template>
      <template
          v-if="(resultType === GLOBAL_SEARCH_RESULT_TYPES.RESOURCE || resultType === GLOBAL_SEARCH_RESULT_TYPES.ALL) && canUserUploadContent && !isMobile">
        <div
            :class="{'empty-state__global-search__items': resultType !== GLOBAL_SEARCH_RESULT_TYPES.ALL , 'empty-state__global-search__searchItems': resultType}">
          <div class="empty-state__global-search__item">
            <icon :name="GLOBAL_SEARCH_RESULT_TYPE_ICONS[GLOBAL_SEARCH_RESULT_TYPES.RESOURCE]" size="18"/>
            <a @click="createInput(GLOBAL_SEARCH_RESULT_TYPES.RESOURCE)">{{
                $t('global_search_add_type', {
                  type: GLOBAL_SEARCH_RESULT_TYPES.RESOURCE,
                  query: query
                })
              }}</a>
          </div>
        </div>
      </template>
    </div>
    <p class="empty-state__global-search__text"></p>
  </div>
</template>


<script>
import { GLOBAL_SEARCH_RESULT_TYPE_ICONS, GLOBAL_SEARCH_RESULT_TYPES } from '../../../util/GlobalSearchResultTypes.js'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui.js'
import MODAL_IDS from '../../../constants/modal-ids.js'
import TranslationsMixin from '../../../util/TranslationsMixin.js'
import UiMixin from '../../../util/UiMixin.js'

export default {
  data () {
    return {
      GLOBAL_SEARCH_RESULT_TYPES,
      GLOBAL_SEARCH_RESULT_TYPE_ICONS,
    }
  },
  props: {
    hasFuzzyResults: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
    },
    resultType: {
      type: String,
    },
  },
  computed: {
    isMobile () {
      return this.ui.isMobile
    },
    query () {
      return this.$store.state.ui.globalSearch.query
    },
    isMember () {
      return this.$store.getters.isMember
    },
    userCanAddActor () {
      return this.isMember || (this.$store.getters.userCanCreate && (this.$store.getters.claimableActorTypes.includes('LegalEntity') || this.$store.getters.claimableActorTypes.includes('Person')))
    },
    userCanAddProduct () {
      return this.isMember || (this.$store.getters.userCanCreate && this.$store.getters.claimableActorTypes.includes('Product'))
    },
    viewActorTypes () {
      return this.$store.getters.viewActorTypes
    },
    canUserUploadContent () {
      return this.$store.getters.hasAccessToKnowledgeBase && this.config.viewDashboard && this.config.viewDashboard.includes('knowledge-base') && this.$store.getters.canUserUploadContent
    },
    canCreateAnnouncement () {
      return this.isLoggedIn && this.$store.getters.canUsePublicAnnouncements && this.$store.getters.canCreateAnnouncement
    },
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    },
    config () {
      return this.$store.state.config
    },
    shouldSeeChallenges () {
      if (this.$store.getters.isMember) {
        return true
      }

      return this.$store.getters.canCreateChallenge ||
          this.$store.getters.viewableChallengeStatusses.length > 0 ||
          this.$store.getters.accessibleChallengeStatusses.length > 0
    },
    canCreateChallenge () {
      return this.$store.state.config.challengesAreEnabled && this.shouldSeeChallenges && this.$store.getters.canCreateChallenge
    },
    createResource (meta) {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
        component: 'create-resource-panel',
        metaData: meta,
      })
    },
  },
  methods: {
    createInput (type) {
      this.hideGlobalSearchModal()
      switch (type) {
        case GLOBAL_SEARCH_RESULT_TYPES.ACTOR:
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
            globalsearchSearchQuery: this.query,
            afterCreate: 'goToActor'
          })
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)
          break
        case GLOBAL_SEARCH_RESULT_TYPES.PRODUCT:
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
            globalsearchSearchQuery: this.query,
            afterCreate: 'goToActor'
          })
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_PRODUCT)
          break
        case GLOBAL_SEARCH_RESULT_TYPES.ANNOUNCEMENT:
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.MANAGE_ANNOUNCEMENT)
          break
        case GLOBAL_SEARCH_RESULT_TYPES.EVENT:
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.MANAGE_ANNOUNCEMENT)
          break
        case GLOBAL_SEARCH_RESULT_TYPES.CHALLENGE:
          this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'manage-challenge-panel' })
          break
        case GLOBAL_SEARCH_RESULT_TYPES.RESOURCE:
        case GLOBAL_SEARCH_RESULT_TYPES.ARTICLE:
          return this.createResource
      }
    },
    hideGlobalSearchModal () {
      this.$store.commit(UI_MUTATION_TYPES.HIDE_GLOBAL_SEARCH)
    },
  },
  mixins: [TranslationsMixin, UiMixin],
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/variables.scss";

.empty-state__global-search {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: $screen-md-max) {
    margin: 15px 28px;
  }

  p {
    font-size: 14px;
    margin-top: 26px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 90%;
  }

  .empty-state__global-search__searchItems {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 90%;
  }

  .empty-state__global-search__items {
    background-color: $color-background-lightest-grey;
    border-radius: 8px;
    opacity: 1;
    width: 300px;
    max-height: 260px;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    padding: 20px 0 20px 0;
    flex-direction: column;

    .svg-icon {
      fill: var(--primary);

      :deep(.fill-fg) {
        fill: var(--primary) !important;
      }
    }

    .empty-state__global-search__item {
      display: flex;
      margin: 9px;
      cursor: pointer;
      width: 100%;
      padding: 0 20px;
      justify-content: center;

      a {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-left: 9px;
        padding-right: 5px;
      }
    }
  }

  &.has-fuzzy-results {
    align-items: unset;
    padding: 15px;

    @media (max-width: $screen-md-max) {
      padding: 0;
    }

    p {
      margin-top: 0;
      margin-bottom: 0;
    }

    .empty-state__global-search__items {
      background-color: transparent;
      text-align: left;
      max-height: 150px;
      display: inline-block;
      width: 100%;

      .empty-state__global-search__item {
        float: left;
        width: 50%;
        margin: 0;
        padding: 10px 0;
        justify-content: unset;

        @media (max-width: $screen-md-max) {
          width: 100%;
        }
      }
    }

    @media (max-width: $screen-md-max) {
      .empty-state__global-search__items {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-height: unset
      }
    }
  }

}
</style>
