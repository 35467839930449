<template>
  <span>{{formattedDate}}</span>
</template>

<script>
  import moment from 'moment';

  export default {
    name: 'Date',
    props: {
      date: [Date, String, Number],
      format: {
        type: String,
        default: 'DD MMM YYYY',
      }
    },
    computed: {
      formattedDate () {
        return moment(this.date).format(this.format)
      }
    }
  }
</script>

<style lang="scss">

</style>
