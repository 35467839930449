<template>
  <div class="data-hj-suppress">
    <div id="map-view__map" class="leaflet-container leaflet-fade-anim leaflet-container-with-legend" :class="classes" :style="customStyling"></div>
    <map-legend v-if="data && (data.companies || data.clusters)" :loading="loading" :companies="data.companies" :clusters="data.clusters"/>
  </div>
</template>

<script lang="ts">
  import {CustomFilters} from '../../api/customfilters'

  import {MUTATION_TYPES} from '../../store/modules/filters'
  import {MUTATION_TYPES as UI_MUTATION_TYPES} from '../../store/modules/ui'

  import {createClusterIcon, createClusterLayer, createIcons, init} from './MapUtils.js'

  import {loadChunk} from '../../util/chunk-loader'
  import escapeHTML from '../../util/escapeHTML.js'
  import ls from '../../util/ls.js'
  import {getDefaultFallbackImageUrl, isIE11} from '../../util/helpers.js'

  import {fetchCoordinates, fetchMunicipalities, fetchNisZipMapping} from '../../api/geo.js'
  import {trackHeapEvent} from '../../util/analytics.js'
  import MapLegend from './MapLegend.vue'
  import {fetchActor} from '../../api/actors.js'
  import _groupBy from 'lodash/groupBy'

  import MODAL_IDS from '../../constants/modal-ids.js'
  import {defineComponent} from 'vue'

  var map
  const MAX_ZOOM_VALUE = 18
  const loadingIcon = '<svg style="width: 20px" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path class="fill-fg spinning" d="M7.61 13.89a.963.963 0 0 1-.294.708.963.963 0 0 1-.707.293.961.961 0 0 1-.703-.297.961.961 0 0 1-.297-.703c0-.276.098-.512.293-.707a.963.963 0 0 1 .707-.293c.276 0 .512.097.707.293a.963.963 0 0 1 .293.707zm3.89 1.61a.963.963 0 0 1-.293.707.963.963 0 0 1-.707.293.963.963 0 0 1-.707-.293.963.963 0 0 1-.293-.707c0-.276.098-.512.293-.707a.963.963 0 0 1 .707-.293c.276 0 .512.098.707.293a.963.963 0 0 1 .293.707zM6 10a.963.963 0 0 1-.293.707A.963.963 0 0 1 5 11a.963.963 0 0 1-.707-.293A.963.963 0 0 1 4 10c0-.276.098-.512.293-.707A.963.963 0 0 1 5 9c.276 0 .512.098.707.293A.963.963 0 0 1 6 10zm9.39 3.89c0 .271-.098.506-.296.704a.961.961 0 0 1-.703.297.963.963 0 0 1-.707-.293.963.963 0 0 1-.293-.707c0-.276.097-.512.293-.707a.963.963 0 0 1 .707-.293c.276 0 .511.097.707.293a.963.963 0 0 1 .293.707zM7.86 6.11c0 .343-.123.637-.368.882s-.539.367-.883.367c-.343 0-.638-.122-.882-.367a1.204 1.204 0 0 1-.368-.883c0-.343.123-.638.368-.882.244-.245.539-.368.882-.368.344 0 .638.123.883.368.245.244.367.539.367.882zM17 10a.963.963 0 0 1-.293.707A.963.963 0 0 1 16 11a.963.963 0 0 1-.707-.293A.963.963 0 0 1 15 10c0-.276.098-.512.293-.707A.963.963 0 0 1 16 9c.276 0 .512.098.707.293A.963.963 0 0 1 17 10zm-5-5.5c0 .417-.146.77-.438 1.063A1.447 1.447 0 0 1 10.5 6c-.417 0-.77-.146-1.063-.438A1.447 1.447 0 0 1 9 4.5c0-.417.146-.77.438-1.063A1.447 1.447 0 0 1 10.5 3c.417 0 .77.146 1.063.438.291.291.437.645.437 1.062zm4.14 1.61c0 .484-.171.897-.515 1.238a1.69 1.69 0 0 1-1.234.511c-.485 0-.898-.17-1.239-.511a1.687 1.687 0 0 1-.511-1.239c0-.479.17-.89.511-1.234a1.68 1.68 0 0 1 1.239-.516c.479 0 .89.172 1.234.516.344.344.516.755.516 1.234z" fill="#9B9B9B" fill-rule="evenodd"/></svg>'

  function getFirstLegend(a) {
    return (Array.isArray(a) ? a[0] : a) || 'Other'
  }

  export default defineComponent({
    props: ['enableOnClick', 'data', 'getMapData', 'getData', 'noSearchWhileMoving', 'searchWhileMovingPosition', 'noCluster', 'clusterPosition', 'disableMouseWheelZoom', 'disableExpandButton', 'customStyling'],
    data() {
      const clustering = ls('map.clustering')
      return {
        clustering: typeof clustering === 'boolean' ? clustering : this.$store.getters.viewMapOptions.clustering !== false,
        defaultIcon: null,
        customFilters: [],
        municipalityData: {}, // Only used when we have to draw a map overlay
        zipNisMapping: {}, // Only used when we have to draw a map overlay
        cityCoordinates: {}, // Only used when we have to draw a map overlay
        hoveringActorId: null,
        hoveringCluster: null,
        loading: false,
      }
    },
    computed: {
      overlayDataLoaded() {
        return this.municipalityData.features && this.municipalityData.features.length > 0 && this.zipNisMapping && this.cityCoordinates
      },
      municipalityGeometries() {
        return this.municipalityData.features
      },
      customFiltersIds() {
        // Return the IDs of the custom filters
        return this.customFilters.map(item => item.id)
      },
      customAreas() {
        // We need to return each custom area that is present in the filter, or return all custom areas
        // we add to each custom area the geographical info that is needed to draw it on the map
        // as well as the total sum of actors within that area
        var customAreas = this.customFilters.filter(customFilter => customFilter.filters[0].zip_codes && customFilter.filters[0].zip_codes.length > 0)

        // If there's only a specific set of custom filters active, only process those and leave out the others
        if (this.activeCustomAreas.length > 0) {
          customAreas = customAreas.filter(customArea => this.activeCustomAreas.includes(customArea.name))
        }

        var zipNisMapping = this.zipNisMapping
        var municipalityGeometries = this.municipalityGeometries
        var zipCodeCountList = this.zipCodeCountList
        var occupiedNisCodes = []

        // Add per custom area the corresponding NIS codes based on the ZIP codes in the custom area
        customAreas.map(function (customArea) {
          // Fetch the corresponding NIS codes and while we're iterating the zip codes, we might as well make the total sum of the actors for the custom area
          var nisCodes = []
          var totalAreaCount = 0
          var zipLargestCity
          var largestCount = 0

          customArea.filters[0].zip_codes.forEach(function (zipCode) {
            var mapping = zipNisMapping[Number(zipCode)]

            // If a NIS mapping exist, add it to the NIS code list
            if (mapping && mapping.nis_code) {
              nisCodes.push(Number(mapping.nis_code))
            }

            // If a zip (city) contains actors, increase the total count of the region with the amount of actors
            var fullZipCode = customArea.filters[0].country_code + zipCode
            var countForZip = zipCodeCountList ? zipCodeCountList[fullZipCode] : 0

            if (countForZip > 0) {
              totalAreaCount += countForZip

              if (countForZip > largestCount) {
                zipLargestCity = zipCode
              }
            }
          })

          // We filter out the nis codes that haven't been drawn yet
          var drawableNisCodes = nisCodes.filter(nisCode => !occupiedNisCodes.includes(Number(nisCode)))

          // Fetch all geometries based on the NIS codes for the custom area that haven't been drawn yet
          // NOTE: this is a very crude way of fetching the data, it would be better if the municipality data was mapped based on the NIS CODE (future speed improvement)
          var geometries = municipalityGeometries.filter(municipalityGeoData => drawableNisCodes.includes(Number(municipalityGeoData.properties.nis)))

          // After geeting the geometries we add the nis codes to the list of occupied nis codes that cannot be used again to draw the shapes
          occupiedNisCodes = occupiedNisCodes.concat(nisCodes)

          geometries = geometries.map(function (geometry) {
            return geometry.geometry
          })

          // Assign the processed properties to the customArea
          customArea.nisCodes = nisCodes
          customArea.geometry = geometries
          customArea.totalCount = totalAreaCount
          customArea.zipLargestCity = zipLargestCity
        })

        return customAreas
      },
      hexColors() {
        return Object.values(this.$store.state.config.hexColours)
      },
      classes() {
        return {
          clustering: this.clustering,
          'list-sync': this.listSync,
          'has-clusters': this.clusters,
        }
      },
      icons() {
        // WARNING: this function returns a 0-30 list of coloured icons, but does not have the map hex0 => colour
        // The hex property was deprecated
        // var icons = createIcons(this.$store.state.config.hex, this.$store.state.config.hexBorders)
        // console.log(this.$store.state.config.hexColours);
        var icons = createIcons(Object.values(this.$store.state.config.hexColours), this.$store.state.config.hexBorders)

        return icons.concat(createIcons(['#bbb'], ['#bbb']))
      },
      legendLookup() {
        return this.$store.state.filters.legendLookup
      },
      legendProperty() {
        return this.$store.state.config.legendProperty || this.$store.state.config.legendeProperty
      },
      defaultLegendIcon() {
        // The last icon
        return this.icons[this.icons.length - 1]
      },
      companies() {
        return this.data && this.data.companies || null
      },
      clusters() {
        return this.data && this.data.clusters || null
      },
      total() {
        return this.data && this.data.total || 0
      },
      zipCodeCountList() {
        return this.data && this.data.zip_code_count || null
      },
      listSync() {
        return this.$store.state.filters.listSync
      },
      viewDirectory() {
        return this.$store.getters.userSettings.viewDirectory
      },
      zipFilters() {
        return this.$store.state.filters.keywords.filter(k => k.facet == 'zip')
      },
      customFiltersKeywords() {
        return this.$store.state.filters.keywords.filter(k => k.facet == 'custom_filters')
      },
      activeCustomAreas() {
        return this.customFiltersKeywords.map(keyword => keyword.value)
      },
      isbelgianMapOverlayEnabled() {
        // Map can only be drawn if the option is enabled and the browser is not an IE 11 browser
        return window.config.belgianMapOverlayEnabled && !isIE11()
      },
      cannotDrawMarkers() {
        return !this.zipFilters.length > 0 && !this.activeCustomAreas.length > 0
      },
      actors() {
        return this.$store.state.actors.listData.data
      },
    },
    methods: {
      getDefaultFallbackImageUrl,
      render() {
        loadChunk('L', this.actualRender)
      },
      actualRender() {
        // Initialize Leaflet
        if (!map) {
          /* //We set the state belgian map overaly layers to display the it the map
          map = init(this) */

          // The "uiParameters" variable will determine if in the leaflet map we add the ui options in the top corners of the map
          // By default the "Search While Moving" and "Cluster" options in the leaflet map should be "on" by default
          // The postionig of the controls for the "clustering" and "search while moving" can also be set by using these values:
          // topleft || topright || bottomleft || bottomright
          // By default the positionoing will be set to "topleft"
          var uiParameters = {
            noSearchWhileMoving: typeof this.noSearchWhileMoving === 'boolean' ? this.noSearchWhileMoving : false,
            noCluster: typeof this.noCluster === 'boolean' ? this.noCluster : false,
            clusterPosition: typeof this.clusterPosition === 'string' ? this.clusterPosition : 'topleft',
            searchWhileMovingPosition: typeof this.searchWhileMovingPosition === 'string' ? this.searchWhileMovingPosition : 'topleft',
          }

          const label = this.$t ? this.$t('cluster_markers') : 'Cluster markers'
          map = init(this, uiParameters, label, this.disableExpandButton)

          // In case we want to disable the mouse wheel scroll zoom functionality
          if (this.disableMouseWheelZoom) {
            map.scrollWheelZoom.disable()
          }

          this.$store.commit(MUTATION_TYPES.UPDATE_MAP_BOUNDS, this.getBounds())
          map.on('zoomend', () => {
            this.$store.commit(MUTATION_TYPES.UPDATE_MAP_BOUNDS, this.getBounds())
            this.$parent.getData ? this.$parent.getData() : this.getData()
            map.closePopup()
          })
          map.on('dragend', () => {
            this.$store.commit(MUTATION_TYPES.UPDATE_MAP_BOUNDS, this.getBounds())
            this.$parent.getData ? this.$parent.getData() : this.getData()
          })

          if (this.enableOnClick) {
            map.on('click', (e) => {
              var popLocation = e.latlng
              var mapZoom = map.getZoom()

              this.getSelectedLatLng(popLocation, mapZoom)

              var popup = L.popup()
                .setLatLng(popLocation)
                .setContent('Lat: ' + popLocation.lat + ', Lng: ' + popLocation.lng + ', Zoom level: ' + mapZoom)
                .openOn(map)

              map.panTo(popLocation)
            })
          }
        }

        // Only if the belgian map overlay option is enabled and we have the assets loaded to draw it on the map, draw it
        if (this.isbelgianMapOverlayEnabled && this.overlayDataLoaded) {
          this.drawCustomAreas()
          this.createBelgiumOverlayMarkers()
        }

        var arr = []

        if (this.companies) {
          arr = this.companyMarkers()
        } else if (this.clusters) {
          arr = this.clusterMarkers()
        }
        if (this.markers) {
          map.removeLayer(this.markers)
        }

        if (!arr) {
          return
        }

        if (this.clustering) {
          this.markers = createClusterLayer(this.$store.state.filters.legendLookup)
          this.markers.on('clustermouseover', this.clustermouseover)
          this.markers.addLayer(L.layerGroup(arr))
        } else {
          this.markers = L.layerGroup(arr)
        }

        map.addLayer(this.markers)
      },
      companyMarkers() {
        var arr = []

        // If the belgian map overlay option is enabled we don't return the markers that area created ouside of belgium
        // If however there is a filter for a custom area or a filter for a zip code, we display the markers as they will be filtered and displayed only for that custom area
        if (this.isbelgianMapOverlayEnabled && this.cannotDrawMarkers) {
          return arr
        }

        for (let i = this.companies.length - 1; i >= 0; i--) {
          const company = this.companies[i]
          if (!company.location || !company.location.lon) {
            continue
          }

          var marker = new L.marker([
            parseFloat(company.location.lat),
            parseFloat(company.location.lon),
          ], {
            company: {
              id: company.id,
              location: company.location,
              name: company.name,
              legend: getFirstLegend(company['legend']),
            },
            legend: getFirstLegend(company['legend']),
            icon: this.getIcon(company),
          })

          marker.bindPopup(company.name)
          marker.on('mouseover', this.mouseover)
          marker.on('click', this.click)
          marker.on('popupopen', this.popupopen)

          arr.push(marker)
        }
        return arr
      },
      clusterMarkers() {
        var arr = []

        // If the belgian map overlay option is enabled we don't return the markers that area created ouside of belgium
        // If however there is a filter for a custom area or a filter for a zip code, we display the markers as they will be filtered and displayed only for that custom area
        if (this.isbelgianMapOverlayEnabled && this.cannotDrawMarkers) {
          return arr
        }

        for (let i = this.clusters.length - 1; i >= 0; i--) {
          const cluster = this.clusters[i]
          var marker = new L.marker([
            parseFloat(cluster.coordinates.lat),
            parseFloat(cluster.coordinates.lon),
          ], {
            count: cluster.total_companies,
            legend: cluster.legend || {},
            icon: createClusterIcon(cluster.total_companies, cluster.legend || {}, this.$store.state.filters.legendLookup),
          })
          if (cluster.name || cluster.country_code) {
            marker.bindPopup(cluster.name || cluster.country_code)
          }
          marker.on('click', this.setZoomAround)
          marker.on('mouseover', this.clustermouseover)
          marker.on('mouseout', function () {
            this.closePopup()
          })
          arr.push(marker)
        }
        return arr
      },
      zoomTo(company) {
        // Show company card
        // this.$dispatch('activeCompany', company, 'focus')

        // Prep location
        if (!company.location) {
          return
        }
        company.location = {
          lat: parseFloat(company.location.lat),
          lng: parseFloat(company.location.lng || company.location.lon),
        }

        // Center on this company
        map.setView(company.location, 14)

        // Making sure the map is animated
        var self = this
        setTimeout(function () {
          self.forceVisibleMarker(company)
        }, 500)
      },
      setZoomAround(evt) {
        const zoom = evt.target.options.count === 1 ? 5 : 1
        map.setZoomAround(evt.latlng, map._zoom + zoom)
      },
      forceVisibleMarker(company) {
        if (!map) {
          return console.warn('map is not loaded')
        }

        // Remove previous extra marker
        if (this.extraMarker) {
          map.removeLayer(this.extraMarker)
        }

        // Find that company marker
        var marker
        for (const i in this.markers._layers) {
          if (this.markers._layers[i] && this.markers._layers[i].feature.properties && this.markers._layers[i].feature.properties.id == company.id) {
            marker = this.markers._layers[i]
            break
          }
        }

        // Open its popup or add a marker with a popup
        if (marker && marker._map) {
          marker.openPopup()
        } else if (company.location && company.location.lat) {
          this.extraMarker = L.marker(company.location, {icon: this.getIcon(company)}).bindPopup(escapeHTML(company.name)).addTo(map).openPopup()
        }
      },
      showActorInPopup(actor, popup) {
        const actorImageUrl = actor.featured_image_url || actor.website_screenshot || getDefaultFallbackImageUrl()
        let description = actor.short_description || 'No description found'
        if (description.length > 100) {
          description = description.substring(0, 100) + '...'
        }

        const label = this.$t('shp_map_info_actor')

        const content = `<h1 class="h1">${actor.name}</h1><div class="map-popup__image-container"><img src="${actorImageUrl}"><span style="float: left">${description}</span></div><div class="map-popup__instructions">${label}</div>`
        if (popup.getContent() !== content) {
          popup.setContent(content)
          popup.update()
        }
      },
      closeclusterpopup() {
        map.closePopup()
      },
      flattenLegendValuesForCluster(clusterLegend) {
        const result = {}

        for (const subCluster of clusterLegend) {
          const keys = Object.keys(subCluster)

          for (const key of keys) {
            if (!result[key]) {
              result[key] = 0
            }

            result[key] += subCluster[key]
          }
        }

        return result
      },
      clustermouseover(cluster) {
        if (map._zoom >= MAX_ZOOM_VALUE) { // don't show popup if zoomed in to max value
          return
        }

        let childMarkers
        let clusterLatLng
        if (cluster.layer) {
          childMarkers = cluster.layer.getAllChildMarkers()
          clusterLatLng = cluster.layer.getLatLng()
        }
        this.hoveringCluster = cluster
        let clusterHtml

        if (!cluster.layer) {
          if (!cluster.target || !cluster.target.getPopup()) {
            return
          }
          const clusterLegend = cluster.target.getPopup()._source.options.legend
          clusterLatLng = cluster.latlng
          clusterHtml = Object.keys(clusterLegend).map(clusterLegendKey => {
            const legend = this.legendLookup[clusterLegendKey]
            const color = legend ? legend.hex : '#bbb'
            const label = legend ? legend.label : 'Other'
            return `<div><span class="cluster-popup-cluster__circle" style="border: 2px solid ${color}">${clusterLegend[clusterLegendKey]}</span><span class="cluster-popup-cluster__text">${label}</span></div>`
          }).join(' ')
        } else if (this.clusters) {
          const clusterLegends = childMarkers.map(marker => marker.options.legend)
          const flattenedClusterLegends = this.flattenLegendValuesForCluster(clusterLegends)
          clusterHtml = Object.keys(flattenedClusterLegends).map(clusterLegendKey => {
            const legend = this.legendLookup[clusterLegendKey]
            const color = legend ? legend.hex : '#bbb'
            const label = legend ? legend.label : 'Other'
            return `<div><span class="cluster-popup-cluster__circle" style="border: 2px solid ${color}">${flattenedClusterLegends[clusterLegendKey]}</span><span class="cluster-popup-cluster__text">${label}</span></div>`
          }).join(' ')
        } else {
          const companies = childMarkers.map(marker => marker.options.company)
          if (companies.length > 10) {
            const groupedCompanies = _groupBy(companies, 'legend')
            clusterHtml = Object.keys(groupedCompanies).map(companyLegendKey => {
              const legend = this.legendLookup[companyLegendKey]
              const color = legend ? legend.hex : '#bbb'
              const label = legend ? legend.label : 'Other'
              return `<div><span class="cluster-popup-cluster__circle" style="border: 2px solid ${color || '#bbb'}">${groupedCompanies[companyLegendKey].length}</span><span class="cluster-popup-cluster__text">${label}</span></div>`
            }).join(' ')
          } else {
            clusterHtml = companies.map(company => {
              const legend = Object.values(this.legendLookup).find(legend => legend.value === company.legend)
              const color = legend ? legend.hex : '#bbb'
              return `<div><span class="cluster-popup-company__circle" style="background-color: ${color || '#bbb'}"></span><a onclick="event.stopPropagation()" style="color: var(--primary)" href="/actors/${company.id}">${company.name}</a></div>`
            }).join(' ')
          }
        }

        const label = this.$t('shp_map_info_cluster')
        const popupContent = `<h1 class="h1">Actors</h1><div class="cluster-popup-company__container">${clusterHtml}</div><div class="map-popup__instructions">${label}</div>`
        const popup = L.popup()

        if (popup.getContent() !== popupContent) {
          popup.setLatLng(clusterLatLng)
            .setContent(popupContent)
            .openOn(map)
        }

        $('.leaflet-popup').click(() => {
          if (!this.hoveringCluster || !this.hoveringCluster.layer) {
            return
          }

          if (!this.hoveringCluster.layer._group._map) {
            // Hack to fix "Cannot read property 'getBoundsZoom' of null" that seems to have a 1/20 chance of happening without explanation. This might be fixed in a more recent version of leaflet
            this.hoveringCluster.layer._group._map = map
          }
          this.hoveringCluster.layer.zoomToBounds()
        }).css('cursor', 'pointer')
      },
      mouseover(event) {
        if (!event || !event.target || !event.target.getPopup) {
          return
        }

        const popup = event.target.getPopup()
        let actorId

        if (popup._source.options.company.id) {
          actorId = popup._source.options.company.id
          this.hoveringActorId = actorId
        } else if (this.hoveringActorId) {
          actorId = this.hoveringActorId
        } else {
          return
        }

        event.target.openPopup()
        const existingActor = this.actors.find(actor => actor.id === actorId)

        if (!existingActor) {
          if (popup.getContent().indexOf('fill-fg spinning') < 0 && popup.getContent().indexOf('<img src') < 0) {
            popup.setContent(`<h1 class="h1">${popup.getContent()}</h1>` + loadingIcon)
            popup.update()
          }
          // fetch from db
          fetchActor(actorId).then((actor) => {
            this.showActorInPopup(actor, popup)
          })
          return
        }
        this.showActorInPopup(existingActor, popup)
      },
      click(event) {
        if (!this.$store.getters.views.includes('actors/detail')) {
          return
        }

        event.target.closePopup()

        const company = event.target.options.company
        trackHeapEvent('mapview.clickActor', {name: company.name})

        // this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_FOR_PREVIEW, company);
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component: 'scores', metaData: company.id})
      },
      popupopen(event) {
        /* if (!this.$store.getters.views.includes('actors/detail')) {
          return
        } */
        if (!event || !event.target || !event.target.options || !event.target.options.company) {
          return
        }
        $('.leaflet-popup').click(() => {
          trackHeapEvent('mapview.clickActor', {name: event.target.options.company.name})
          this.$router.push('/actors/' + event.target.options.company.id)
        }).css('cursor', 'pointer')
      },
      getSelectedLatLng(popLocation, zoom) {
        this.$emit('selectedLat', {latLng: popLocation, zoom: zoom})
      },
      getBounds() {
        if (!map) {
          return {}
        }
        var b = map.getBounds()
        return {
          tl: [b.getNorth(), Math.max(b.getWest(), -180)],
          br: [b.getSouth(), Math.min(b.getEast(), 180)],
        }
      },
      getIcon(company) {
        var companyLegend = company[this.legendProperty]

        if (!companyLegend) {
          companyLegend = company.legend
        }

        const legendItem = this.legendLookup[companyLegend] || {}

        if (!legendItem.hexName) {
          return this.defaultLegendIcon
        }

        // Get the icon based on the hex index
        const hexIndex = legendItem.hexName.replace('hex', '')

        return this.icons[hexIndex] || this.defaultLegendIcon
      },
      invalidateSize() {
        setTimeout(() => {
          map && map.invalidateSize(true)
        }, 100)
        setTimeout(() => {
          map && map.invalidateSize(true)
        }, 300)
        setTimeout(() => {
          map && map.invalidateSize(true)
        }, 500)
      },
      fetchCustomFilters() {
        CustomFilters.get()
          .then(data => {
            this.customFilters = data
          })
          .catch(errors => {
            console.log(errors)
          })
      },
      setCustomFilter(region) {
        // Setting the filter by custom filter area
        this.$store.commit(MUTATION_TYPES.ADD_KEYWORD, {facet: 'custom_filters', value: region})
      },
      openListView() {
        // Open the list vew side panel
        this.$store.commit('USER/STORE_SETTINGS', {viewDirectory: this.viewDirectory.includes('LIST') ? this.viewDirectory : this.viewDirectory.split(',').concat(['LIST']).join(',')})
      },
      createBelgiumOverlayMarkers() {
        /* var thisVue = this;
        // Note: since we're drawing the number on the city with the largest count, in case of overlapping regions, this might lead to numbers overlapping each other
        // TODO: see if this is an edge case we need to take into account

        // Before drawing the markers we delete all of the layers identified as 'belgiumOverlayMarker'
        map.eachLayer(function (layer) {
          if (layer.options && layer.options.classList) {
            if (layer.options.classList == 'belgiumOverlayMarker') {
              map.removeLayer(layer);
            }
          }
        });

        // If there is a zip filter applied, we do not draw the belgium overlay markers
        if (this.zipFilters.length > 0) {
          return
        }

        var cityCoordinates = this.cityCoordinates;
        var legendLookup = this.$store.state.filters.legendLookup;

        // Foreach custom area, draw the total of that area on the map
        this.customAreas.forEach(function (customArea) {
            // If there's at least one filter for custom areas, we do not create the total count markers
            if (thisVue.activeCustomAreas && thisVue.activeCustomAreas.length > 0) {
              return
            }

            var zipLargestCity = customArea.zipLargestCity;
            var coordinates = cityCoordinates[customArea.zipLargestCity];

            if (! coordinates) {
              //console.log("No largest city found, probably there's no data for the region: ", customArea);
              return;
            }

            var marker = new L.marker([
              coordinates.lat,
              coordinates.lng
            ], {
              classList: 'belgiumOverlayMarker',
              count: customArea.totalCount,
              legend: customArea.totalCount || {},
              icon: createClusterIcon(customArea.totalCount, customArea.totalCount || {}, legendLookup)
            })

            map.addLayer(marker)
        }); */
      },
      drawCustomAreas() {
        var thisVue = this
        var popup = L.popup()

        // console.log(thisVue.customFiltersIds, "custom filter ids");
        // Use this to remove all of the layers representing the cities in the custom areas
        map.eachLayer(function (layer) {
          if (layer.options && layer.options.id && thisVue.customFiltersIds.includes(layer.options.id)) {
            map.removeLayer(layer)
          }
        })

        // If there is a zip filter applied, we do not draw the custom area layers
        if (this.zipFilters.length > 0) {
          return
        }

        // We sort the areas by length so that the bigger areas are drawn firtst
        this.customAreas.sort(function (a, b) {
          return b.geometry.length - a.geometry.length
        })

        // Get the coordinates for each city
        var cityCoordinates = this.cityCoordinates

        // We iterate through all custom areas and create the layers for them
        this.customAreas.forEach((customArea, index) => {
          // optionValues will store the styling and the region value
          var optionValues = {}

          // We create an object that will store the styling values for each layer
          var customAreaStyle = {
            fillColor: this.hexColors[index],
            weight: 2,
            opacity: 0.4,
            dashArray: '3',
            fillOpacity: 0.4,
          }

          optionValues = Object.assign({style: customAreaStyle, country_code: customArea.filters[0].country_code})

          if (customArea.name) {
            // Each customArea city will have the same 'name' value, so we'll get the value from the first one, also pass in some other option data
            var coordinates = cityCoordinates[customArea.zipLargestCity]

            optionValues = Object.assign(optionValues, {name: customArea.name, id: customArea.id, totalCount: customArea.totalCount})
          }

          var geometry = Object.assign({}, {geometry: customArea.geometry, id: customArea.id})
          var area = L.geoJson(customArea.geometry, optionValues).addTo(map)

          // Only show a tooltip of the custom area when there's no custom area selected as a filter
          if (this.activeCustomAreas.length == 0) {
            area.on('mouseover', function (data) {
              // On mouse over we set the coordinates of the pop up based on the hovered layer
              popup.setLatLng([data.latlng.lat, data.latlng.lng])
                .setContent(data.layer.options.name + '<br/>' + data.layer.options.totalCount + ' actors')

              // Setting and offset so that the pop up doesn't stay on the way of the mouse when trying to click on a custom area
              popup.options.offset = [0, -25]

              // We set a small delay to avoid the pop up from flashing while moving along the hovered area
              self.setTimeout(function () {
                popup.openOn(map)
              }, 250)
            })

            area.on('mouseout', function (data) {
              // We set a small delay to avoid the pop up from flashing while moving along the hovered area
              self.setTimeout(function () {
                map.closePopup(popup)
              }, 10)
            })
          }

          area.on('click', function (data) {
            // Setting the filter by custom filter area
            thisVue.setCustomFilter(data.target.options.name)

            // Open the list vew side panel
            thisVue.openListView()
          })
        })
      },
    },
    mounted() {
      if (this.isbelgianMapOverlayEnabled) {
        // Load the defined custom filters of the ecosystem
        this.fetchCustomFilters()

        fetchMunicipalities()
          .then(data => this.municipalityData = data)

        fetchCoordinates()
          .then(data => this.cityCoordinates = data)

        fetchNisZipMapping()
          .then(data => this.zipNisMapping = data)
      }

      this.$parent.getMapData ? this.$parent.getMapData() : this.getMapData()
      this.render()

      if (!this.disableExpandButton) {
        this.$bus.on('enlargeMap', (data) => {
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, data)
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.MAP)
        })
      }

      const resizeObserver = new ResizeObserver(() => {
        if (map) {
          map.invalidateSize()
        }
      })

      resizeObserver.observe(document.getElementById('map-view__map'))
    },
    beforeUnmount() {
      if (!this.disableExpandButton) {
        this.$bus.off('enlargeMap')
      }
      if (map) {
        window.viewMapOptions = {
          zoom: map.getZoom(),
          center: map.getCenter(),
        }
      }
      // TODO: keep this variable and reuse it when navigating back
      setTimeout(function () {
        if (map) {
          map.remove()
          map = null
        }
      }, 100)
      this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
    },
    components: {
      MapLegend,
    },
    watch: {
      data() {
        this.render()
        if (!this.disableExpandButton && this.$store.state.ui.modalContext && this.$store.state.ui.modalContext.getMapData) {
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
            data: this.data,
            getMapData: this.getMapData,
          })
        }
      },
      clustering(enabled) {
        ls('map.clustering', enabled)
      },
      overlayDataLoaded() {
        // Note if the overlay data is loading incredibly fast (takes about 3 seconds right now),
        // faster than the mapData can load, then we might need to add a check as well so that all assets are ready before we start drawing
        if (this.overlayDataLoaded) {
          this.render()
        }
      },
    },
  })
</script>

<style lang="scss">
  .leaflet-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &.leaflet-container-with-legend {
      min-height: 100px;
      height: calc(100% - 3rem) !important;
    }
  }
</style>
