<template>
  <div class="communities-container scrollable" ref="scrollable">
    <basic-simplified-hero
      :title="onboardingTextConfig.spottingAreasSimplifiedTitle"
      :subtitle="onboardingTextConfig.spottingAreasSimplifiedSubtitle"
    >
    </basic-simplified-hero>
    <Container style="flex-grow: 1">
      <div class="communities__card-container" id="introjs-all-spotting-areas">
        <div v-if="!loading && spottingAreas && (accessibleSpottingAreas.length > 0 || nonAccessibleSpottingAreas.length > 0)">
          <h1 v-if="accessibleSpottingAreas.length > 0 && isPortfolioMember && !isForwardFooding">My {{ spottingAreasLabel }}</h1>
          <h1 v-else-if="accessibleSpottingAreas.length > 0 && !isForwardFooding">{{ spottingAreasLabel }}</h1>
          <div v-if="accessibleSpottingAreas.length > 0" class="row">
            <div class="col-xs-12 col-sm-4" v-for="(spottingArea, index) in accessibleSpottingAreas" :key="index">
              <div style="width: 100%; height: calc(100% - 20px); padding: 0px 10px 10px 10px">
                <simplified-image-card
                  v-if="spottingArea.user_has_access"
                  :name="spottingArea.name" :full-size="true"
                  background-color="white"
                  :image="getImageForSpottingArea(spottingArea)"
                  :sub-title="spottingArea.description"
                  :link="'/spotting-areas/' + spottingArea.id"
                  class="spotting-area-simplified-image-card"
                  :left-align-text="true"
                  :tags="getSpottingAreaTags(spottingArea)"
                />
              </div>
            </div>
          </div>

          <h1 v-if="nonAccessibleSpottingAreas.length > 0 && !isForwardFooding">Other interesting {{ spottingAreasLabel }}</h1>
          <div v-if="nonAccessibleSpottingAreas.length > 0 && !isForwardFooding" class="row">
            <div class="col-xs-12 col-sm-4" v-for="(spottingArea, index) in nonAccessibleSpottingAreas" :key="index">
              <div style="width: 100%; height: calc(100% - 20px); padding: 0px 10px 10px 10px">
                <template v-if="spottingArea.user_can_login_or_upgrade">
                  <simplified-image-card-with-cta
                    :spotting-area="spottingArea"
                  />
                </template>
                <template v-else>
                  <simplified-image-card
                    :name="spottingArea.name" :full-size="true"
                    background-color="white"
                    :image="getImageForSpottingArea(spottingArea)"
                    :sub-title="spottingArea.description"
                    class="spotting-area-simplified-image-card"
                    :left-align-text="true"
                    :disabled="true"
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="loading">
          <Loading/>
        </div>
        <div v-else-if="spottingAreas && accessibleSpottingAreas.length === 0 && nonAccessibleSpottingAreas.length === 0">
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
  import BasicSimplifiedHero from '../components/Simplified/BasicSimplifiedHero.vue'
  import Container from '../components/Container/Container.vue'
  import SimplifiedImageCard from '../components/Connection/SimplifiedImageCard.vue'
  import SimplifiedImageCardWithCta from '../components/SpottingAreas/SimplifiedImageCardWithCta.vue'
  import { Carousel, Navigation, Slide } from 'vue3-carousel'
  import FollowButton from '../components/FollowButton/FollowButton.vue'
  import PublishButton from '../components/PublishButton/PublishButton.vue'

  import CommunityCard from '../components/Simplified/CommunityCard.vue'
  import Loading from '../components/Dashboard/ConceptMap/Loading.vue'
  import { getImageForSpottingArea, truncate } from '../util/helpers.js'
  import OnboardingMixin from '../util/OnboardingMixin.js'
  import { followSpottingArea, unfollowSpottingArea } from '../api/exploration.js'
  import SimplifiedFooter from '../components/SimplifiedFooter/SimplifiedFooter.vue'
  import IntroJsMixin from '../util/IntroJsMixin.js'
  import { ACTION_TYPES as SPOTTING_AREAS_ACTION_TYPES } from '../store/modules/spottingareas.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../store/modules/ui.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'SpottingAreas',
    data () {
      return {
        spottingAreaIsFollowed: [],
        accessibleSpottingAreas: [],
        nonAccessibleSpottingAreas: [],
      }
    },
    computed: {
      isForwardFooding () {
        return ['forwardfooding', 'local'].includes(this.$store.getters.ecosystemName.toLowerCase())
      },
      hasAccessToNewSpottingAreas () {
        return this.$store.getters.hasAccessToNewSpottingAreas
      },
      subscriptionInfo () {
        return this.$store.state.user.profile.subscriptionInfo || {}
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      isActor () {
        return this.$store.getters.isActor
      },
      isMember () {
        return this.$store.getters.isMember
      },
      isPortfolioMember () {
        return this.$store.getters.isPortfolioMember
      },
      spottingAreas () {
        let spottingareas = this.$store.state.spottingAreas.listData.data

        if (spottingareas.length > 0) {
          spottingareas = [...spottingareas].sort((a, b) => b.user_has_access - a.user_has_access)
          spottingareas = [...spottingareas].sort((a, b) => b.type - a.type)

          if (spottingareas.length > 0) {
            this.accessibleSpottingAreas = spottingareas.filter(spottingArea => spottingArea.user_has_access === true)
            this.accessibleSpottingAreas = this.accessibleSpottingAreas.sort((a, b) => {
              return (a.spotting_area_access < b.spotting_area_access ? -1 : (a.spotting_area_access > b.spotting_area_access ? 1 : 0))
            })
            this.nonAccessibleSpottingAreas = spottingareas.filter(spa => spa.user_has_access === false)
          }
        }

        return spottingareas
      },
      loading () {
        return this.$store.state.spottingAreas.listData.loading
      }
    },
    methods: {
      truncate,
      getImageForSpottingArea,
      async fetchSpottingAreas () {
        if (this.spottingAreas.length === 0) {
          await this.$store.dispatch(SPOTTING_AREAS_ACTION_TYPES.FETCH_SPOTTING_AREAS_LIST)
          setTimeout(() => {
            this.startIntroJs()
            // This timeout is required to wait for the simplifiedImageCard timeout
          }, 200)
        } else {
          // timeout to prevent two introJS instances at once after final personal home "afterClick"
          setTimeout(() => {
            this.startIntroJs()
          })
        }
      },
      isFollowed (spottingArea) {
        return this.spottingAreaIsFollowed[spottingArea.id]
      },
      setFollowed (spottingArea, followed) {
        if (followed) {
          this.followSpottingArea(spottingArea)
        } else {
          this.unfollowSpottingArea(spottingArea)
        }
      },
      spottingAreaStyle (spottingArea) {
        if (spottingArea.image) {
          return 'background-image: url(' + window.location.protocol + '//' + window.location.hostname + '/api/spotting-areas/' + spottingArea.id + '/image' + ');'
        }

        return 'background-image: url(images/ds-fallback.png)'
      },
      showingPublishButton () {
        return this.isOwner
      },
      handleSpottingAreaClick (spottingArea) {
        this.$router.push(`/spotting-areas/${spottingArea.id}`)
      },
      followSpottingArea (spottingArea) {
        followSpottingArea(spottingArea.id)
          .then(() => {
            this.spottingAreaIsFollowed[spottingArea.id] = true
          })
      },
      unfollowSpottingArea (spottingArea) {
        unfollowSpottingArea(spottingArea.id)
          .then(() => {
            this.spottingAreaIsFollowed[spottingArea.id] = false
          })
      },
      startIntroJs () {
        if (this.seenIntros.includes('spottingAreasHome')) {
          return
        }
        const config = this.buildIntroJsConfig(this.config, this.$store.getters.userRole)
        if (!config || !config.spottingAreasHome || !config.spottingAreasHome.steps) {
          return
        }

        config.spottingAreasHome.steps = this.getIntroJsStepsConfig(config.spottingAreasHome.steps)
        const intro = this.introJs().setOptions(config.spottingAreasHome)
        this.currentIntro = intro

        const componentScope = this
        intro.onbeforechange(this.introJsBeforeStepCallback.bind(this))
        intro.onafterchange(this.introJsAfterStepCallback.bind(this))
        intro.oncomplete(function () {
          componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
        })
        intro.onexit(function () {
          componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
        })

        intro.start()
      },
      getSpottingAreaTags (spottingArea) {
        const tags = []
        if (!this.isPortfolioMember || !spottingArea.spotting_area_access) {
          return tags
        }

        tags.push(spottingArea.spotting_area_access.charAt(0).toUpperCase() + spottingArea.spotting_area_access.slice(1))

        return tags
      },
    },
    async mounted () {
      if (!this.hasAccessToNewSpottingAreas) {
        this.$router.push('/')
      }
      await this.fetchSpottingAreas()
      this.$store.commit(UI_MUTATION_TYPES.SET_NAVIGATION_VISIBILITY, true)
    },
    mixins: [OnboardingMixin, IntroJsMixin],
    components: {
      BasicSimplifiedHero,
      Container,
      CommunityCard,
      Loading,
      Carousel,
      SimplifiedFooter,
      Navigation,
      Slide,
      SimplifiedImageCard,
      SimplifiedImageCardWithCta,
      FollowButton,
      PublishButton,
    },
  })
</script>

<style lang="scss" scoped>
  .communities-container {
    height: 100%;
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .simplified-connection.disabled {
    filter: grayscale(100%);
  }
</style>
