<template>
  <modal :id="modalId" ref="modal" @close="$emit('close')">
    <template v-slot:body>
      <div>
        <p>
        <div class="contact-modal__header">
          <icon name="email-outline" size="regular" class="contact-modal__header-icon"/>
          <div class="contact-modal__header-title">{{ supportTitle }}</div>
          <ds-button
            class="contact-modal__header-close-btn"
            icon="remove"
            variant="minimal"
            @click="close"
          />
        </div>
        <div class="contact-modal__message-area" style="padding-bottom: 0px;">
          <form-group>
            <div class="contact-modal__message-warning" v-if="showMessageWarning">Please fill the message box </div>
            <ds-textarea v-model="form.message" defaultText="Write a message" class="contact-modal__message-area__text-area"/>
          </form-group>
          <form-group label="Support" class="col-xs">
            <div class="contact-modal__email">You will be contacted via your email address: {{userEmail}}.</div>
          </form-group>
        </div>
        </p>
      </div>
    </template>
    <template v-slot:footer>
      <div>
        <p v-if="errors">
          <span class="contact-modal__error-message"> {{ errors }} </span>
          <ds-button
            label="Send"
            variant="secondary"
            size="small"
            @click="sendMessage"
            :disabled="this.form.busy"
          />
        </p>
        <p v-else-if="success !== ''">
          <span class="contact-modal__success-message"> {{success}} </span>
          <ds-button
            label="Ok"
            variant="secondary"
            size="small"
            @click="close"
          />
        </p>
        <p v-else>
          <ds-button
            label="Send"
            variant="secondary"
            size="small"
            @click="sendMessage"
          />
        </p>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import DsInput from '../Form/DsInput.vue'
  import FormGroup from '../Form/FormGroup.vue'
  import DsTextarea from '../Form/DsTextarea.vue'
  import Avatar from '../Avatar/Avatar.vue'

  import MODAL_IDS from '../../constants/modal-ids'

  import { sendSupportMessage } from '../../api/config'

  export default {
    data () {
      return {
        errors: null,
        modalId: MODAL_IDS.CONTACT_SUPPORT,
        success: '',
        form: {
          busy: false,
          message: null,
        },
        showMessageWarning: false,
      }
    },
    computed: {
      userEmail () {
        return this.$store.getters.userEmail
      },
      modalContext () {
        return this.$store.state.ui.modalContext
      },
      supportTitle () {
        if (this.modalContext.title) {
          return this.modalContext.title
        }

        return 'Get in touch with our support team.'
      },
      sendTo () {
        return this.modalContext.sendTo
      },
      placeholder () {
        return this.modalContext.placeholder
      },
      subject () {
        return this.modalContext.subject
      }
    },
    methods: {
      close () {
        this.$refs.modal.close()
      },
      sendMessage () {
        if (!this.form.busy && (this.form.message !== null && this.form.message !== '')) {
          this.form.busy = true

          // By default we send the support message to ourselves (DataScouts support team), the other option is to send it to the ecosystem owners
          if (this.sendTo && ['owners'].includes(this.sendTo)) {
            this.form.sendTo = this.sendTo
          }

          this.form.subject = this.subject

          sendSupportMessage(this.form)
            .then(() => {
              this.success = 'Your message has been sent, we will get in touch with you soon.'
              this.form.message = null
              this.form.phone = null
              this.form.busy = false
              this.errors = null
            })
            .catch(errors => {
              this.errors = errors.message.toString()
              this.form.busy = false
            })
        }

        this.showMessageWarning = !this.form.message
      }
    },
    created () {
      this.form.message = this.placeholder
    },
    components: {
      Modal,
      FormGroup,
      DsTextarea,
      DsInput,
      Avatar
    }
  }
</script>
