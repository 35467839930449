<template>
  <modal
    v-if="modalContext && spottingArea"
    :title="'Remove actor from ' + spottingArea.name"
    @close="close"
    :id="modalId"
    ref="modal"
    is-simplified-modal
    is-closeable
  >
    <template v-slot:body>
      <form-group class="modal__text-remove-actor-from-spotting-area" v-if="(spottingArea && spottingArea.name) && (actor && actor.name)">
        <span v-if="!success">Are you sure you want to remove '{{ actor.name }}' from {{ spottingArea.name }}</span>
        <span v-else>Successfully removed {{ actor.name }} from {{ spottingArea.name }}</span>
      </form-group>
    </template>
    <template v-slot:footer>
      <div style="width: 100%;" v-if="!success">
        <ds-button
          variant="rounded"
          size="extra-small"
          @click="close"
          label="Cancel"
        />
        <ds-button
          variant="rounded"
          size="extra-small"
          @click="deleteActor"
          label="Confirm"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import { deleteActorFromSpottingArea, fetchSpottingArea } from '../../api/spottingareas'
  import { fetchActor } from '../../api/actors'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'RemoveActorFromSpottingAreaModal',
    data () {
      return {
        modalId: MODAL_IDS.REMOVE_ACTOR_FROM_SPOTTING_AREA_MODAL,
        spottingArea: null,
        actor: null,
        success: false,
      }
    },
    computed: {
      modalContext () {
        return this.$store.state.ui.modalContext
      },
      spottingAreaId () {
        return this.modalContext.spottingArea
      },
      actorId () {
        return this.modalContext.actorId
      },
    },
    methods: {
      close () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.REMOVE_ACTOR_FROM_SPOTTING_AREA_MODAL)
        this.$emit('close')
      },
      fetchSpottingArea () {
        fetchSpottingArea({ spottingAreaId: this.spottingAreaId })
          .then((response) => {
            this.spottingArea = response
          })
      },
      fetchActor () {
        fetchActor(this.actorId)
          .then((response) => {
            this.actor = response
          })
      },
      deleteActor () {
        this.success = false
        deleteActorFromSpottingArea(this.spottingAreaId, this.actorId)
          .then((response) => {
            if (response.message === 'success') {
              this.success = true

              setTimeout(() => {
                this.$router.push(`/spotting-areas/${this.spottingAreaId}/actors`)
                this.close()
              }, 2000)
            }
          })
      },
    },
    mounted () {
      setTimeout(() => {
        this.fetchSpottingArea()
        this.fetchActor()
      }, 100)
    },
    watch: {
      spottingAreaId () {
        this.fetchSpottingArea()
      },
      actorId () {
        this.fetchActor()
      },
    },
    components: {
      Modal,
    },
  })
</script>

<style scoped>
  .modal__text-remove-actor-from-spotting-area {
    padding-left: 30px;
    padding-right: 30px;
  }
</style>
