<template>
  <modal :id="modalId" ref="modal" @close="$emit('close')">
    <template v-slot:body>
      <div>
        <p v-if="errors">
          {{ errors.message }}
        </p>
        <p v-else-if="feedbackMessage">
          {{ feedbackMessage }}
        </p>
        <p v-else-if="memberClaim">
          {{ $t('claim_basic') }}
        </p>
        <p v-else-if="immediateClaim">
          {{ $t('claim_email_match') }}<br>
          {{ $t('claim_explanation_last_line') }}
        </p>
        <p v-else-if="previewedActor.claimViaCuration">
          {{ $t('claim_via_curation') }}<br>
          {{ $t('claim_explanation_last_line') }}
        </p>
        <p v-else>
          {{ $t('claim_via_email_of_profile') }}<br>
          {{ $t('claim_explanation_last_line') }}
        </p>
      </div>
    </template>
    <template v-slot:footer>
      <div>
        <p v-if="errors">
          <ds-button
              label="Ok"
              variant="primary"
              @click="close"
          />
        </p>
        <p v-else-if="feedbackMessage">
          <ds-button
              label="Ok"
              variant="primary"
              @click="close"
          />
        </p>
        <p v-else>
          <ds-button
              :label="$t('generic_cancel')"
              variant="secondary"
              @click="close"
              :disabled="submittingClaim"
          />
          <ds-button
              :label="submittingClaim ? 'Claiming...' : 'Claim'"
              variant="primary"
              @click="claim"
              :disabled="submittingClaim"
          />
        </p>
      </div>
    </template>
  </modal>
</template>

<script>
import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../../store/modules/actors'

import Modal from './Modal.vue'
import MODAL_IDS from '../../constants/modal-ids'
import { getDomainFromUrl } from '../../util/helpers.js'
import Dropdown from '../Dropdown/Dropdown.vue'
import { trackMatomoEvent } from '@/util/analytics'
import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from '@/constants/analytics-constants'

export default {

  data () {
    const modalContext = this.$store.state.ui.modalContext || {}
    return {
      errors: null,
      modalId: MODAL_IDS.CLAIM,
      submittingClaim: false,
      productData: emptyProduct({
        category: null,
        ...modalContext.prefilled,
      }),
    }
  },
  computed: {
    memberClaim () {
      return this.$store.getters.isMember
    },
    feedbackMessage () {
      const response = this.$store.state.actors.claimData.data
      return response ? response.message : null
    },
    previewedActor () {
      return this.$store.getters.previewedActor
    },
    immediateClaim () {
      if (!this.previewedActor.url) {
        return false
      }

      return this.$store.state.user.profile.email.endsWith(getDomainFromUrl(this.previewedActor.url))
    },
    userProfile () {
      return this.$store.state.user.profile
    },
    nonProductClaims () {
      let actorClaims = this.userProfile.claims

      if (!this.userProfile.claims || this.userProfile.claims.length === 0) {
        return []
      }
      actorClaims = actorClaims.filter(claim => claim.actor_type !== 'Product')

      // eslint-disable-next-line no-unreachable
      return actorClaims.map((claim) => {
        return { value: claim.id, label: claim.name }
      })
    },
  },
  methods: {
    claim () {
      this.submittingClaim = true
      this.$store.dispatch(ACTORS_ACTION_TYPES.CLAIM_ACTOR, this.previewedActor.id)
          .then(() => {
            this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_DETAIL, true)

            trackMatomoEvent(MATOMO_EVENT_CATEGORIES.ACTOR_CLAIM, MATOMO_EVENT_ACTIONS.CLAIM, this.previewedActor.id)
          })
          .catch(errors => {
            this.errors = errors || {}
            this.submittingClaim = false
          })
    },
    close () {
      this.$store.commit(ACTORS_ACTION_TYPES.CLAIM_ACTOR_RESET)
      this.submittingClaim = false
      this.$refs.modal.close()
    },
  },
  components: {
    Modal,
    Dropdown,
  },
}

function emptyProduct ({ ...other }) {
  return {
    id: null,
    actor_type: 'Product',
    name: null,
    actor_name: null,
    url: null,
    tags: [],
    ...other,
  }
}
</script>


<style lang="scss" scoped>
.actor_dropdown {
  margin-top: 5%;
  padding-right: 0;
  padding-left: 0;
}
</style>

