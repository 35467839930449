<template>
  <transition name="modal">
    <div class="modal__mask" @click="blur">
      <all-filters-modal-content
        :filters-to-display="visibleFilters"
        :is-closeable="isCloseable"
        :selected-filters="keywords"
        :is-products-page="isProductsPage"
        @update-state="updateState"
        @close="close"
      />
    </div>
  </transition>
</template>
<script>
  import AllFiltersModalContent from './AllFiltersModalContent.vue'
  import FiltersMixin from '../../../util/FiltersMixin.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui.js'
  import MODAL_IDS from '../../../constants/modal-ids.js'

  export default {
    props: {
      isCloseable: {
        type: Boolean,
        default: true,
      },
    },
    computed: {
      keywords () {
        return this.$store.state.filters.keywords
      },
    },
    methods: {
      updateState (stateChange) {
        this.$store.commit(stateChange.name, stateChange.content)
      },
      blur () {
        if (this.closeOnBlur) {
          this.close()
        }
      },
      close () {
        if (this.isCloseable) {
          this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.ALL_FILTERS)
          this.$emit('close')
        }
      },
    },
    components: { AllFiltersModalContent },
    mixins: [FiltersMixin],
  }
</script>
