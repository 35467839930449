import _flatten from 'lodash/flatten'
import _countBy from 'lodash/countBy'
import _uniq from 'lodash/uniq'
import _sortBy from 'lodash/sortBy'

export function fromRelation (rels) {
  return Array.isArray(rels) ? rels.map(rel => rel.to_name || rel.to).join(',\n') : ''
}

/*export function isRelation (prop) {
  return types.find(t => t.name === prop)
}*/

export function getRelationMenu (prop, actors) {

  // Relation removal items
  const existing = _flatten(actors.map(a => a['_' + prop] || []))
  const counts = _countBy(existing, 'to')
  const unique = _uniq(existing)
  for (var i = unique.length - 1; i >= 0; i--) {
    unique[i].count = counts[unique[i].to]
  }
  const sorted = _sortBy(unique, 'count').reverse()

  const menu = {}
  if (actors.length > 1) {
    menu.add = 'Add ' + actors.length + ' relationships'
  } else {
    menu.add = 'Add relationship'
  }

  if (sorted.length) {
    menu.sep = '---------'
  }

  for (i = 0; i < sorted.length; i++) {
    menu['REMOVE_RELATION' + sorted[i].to] = 'Unlink ' + sorted[i].to_name
  }

  return menu
}
