<template>
  <modal :id="modalId"
         ref="modal"
         :title="$t('select_your_preferred_calendar')"
         :class="'announcement-calendar-modal'"
         @close="close"
         is-simplified-modal
         :show-footer="false"
  >
    <template v-slot:body>
      <div v-if="isLoggedIn" class="announcement-calendar-container">
        <div class="row">
          <div
              v-for="(service, index) in calendarServices"
              :key="'calendar-service-' + index"
              class="col-xs-12 calendar-service-item"
              @click="getCalendarLink(service)"
          >
            <div
                v-tooltip.top="service.action === 'url' ? 'Open URL' : 'Download .ics file'"
            >
              <icon :name="service.logo" :style="{fill: service.color}" class="calendar-service-item__icon"/>
              {{ service.name }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from './Modal.vue'
import MODAL_IDS from '../../constants/modal-ids.js'
import TranslationsMixin from '../../util/TranslationsMixin.js'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'

import { GoogleCalendar, ICalendar, OutlookCalendar, YahooCalendar } from 'datebook'
import _isEmpty from 'lodash/isEmpty.js'
import AnnouncementMixin from '../../util/AnnouncementMixin'
import { trackHeapEvent } from '../../util/analytics'

export default {
  data() {
    return {
      modalId: MODAL_IDS.ANNOUNCEMENT_CALENDAR,
    }
  },
  computed: {
    modalContext() {
      return this.$store.state.ui.modalContext
    },
    announcement() {
      return this.modalContext.announcement
    },
    config() {
      return this.$store.state.config
    },
    calendarServices() {
      return [
        { type: 'apple', 'action': 'download', logo: 'apple', 'name': 'Apple', color: 'gray' },
        { type: 'google', 'action': 'url', logo: 'google', 'name': 'Google', color: 'red' },
        { type: 'outlook', 'action': 'url', logo: 'envelope', 'name': 'Outlook', color: 'blue' },
        { type: 'yahoo', 'action': 'url', logo: 'yahoo', 'name': 'Yahoo', color: 'yellow' },
        { type: 'download', 'action': 'download', logo: 'file', 'name': 'download .ics', color: 'black' },
      ]
    },
  },
  props: {},
  methods: {
    getCalendarConfig() {
      let startDate = this.announcement.start_date
      let endDate = this.announcement.end_date

      if (typeof endDate === 'undefined' || _isEmpty(endDate)) {
        endDate = this.announcement.start_date
      }

      let calendarDescription = this.announcement.short_url
      if (!_isEmpty(this.announcement.shortDescription)) {
        calendarDescription = this.announcement.shortDescription + ' - ' + calendarDescription
      }

      return {
        title: this.announcement.title,
        location: this.announcement.address_line ? this.announcement.address_line : '',
        description: calendarDescription,
        start: new Date(startDate),
        end: new Date(endDate),
      }
    },
    getCalendarLink(service) {
      if (!this.announcement.user_event_calendar) {
        this.toggleEventCalendarStatus(this.announcement)
      }

      let calendarUrl = ''

      if (service.action === 'download') {
        const calendar = new ICalendar(this.getCalendarConfig())

        trackHeapEvent('announcementDetail.addToCalendar', { 'action': 'download ics' })

        // This will return an .ics file
        return calendar.download()
      }

      if (service.type === 'google') {
        const calendar = new GoogleCalendar(this.getCalendarConfig())
        calendarUrl = calendar.render()
      }

      if (service.type === 'outlook') {
        const calendar = new OutlookCalendar(this.getCalendarConfig())

        // Valid hosts:
        // - live - For personal Outlook accounts (default)
        // - office - For Office365 Outlook accounts
        calendar.setHost('office')
        calendar.setParam('online', 'true') // Whether or not this meeting is an online (i.e., Skype) meeting

        calendarUrl = calendar.render()
      }

      if (service.type === 'yahoo') {
        const calendar = new YahooCalendar(this.getCalendarConfig())
        if (!_isEmpty(this.announcement.address_line)) {
          calendar.setParam('in_st', this.announcement.address_line)
        }

        calendarUrl = calendar.render()
      }

      if (calendarUrl !== '') {
        trackHeapEvent('announcementDetail.addToCalendar', { 'service': service.type })

        window.open(calendarUrl, '_blank')
      }
    },
    close() {
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
      this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.ANNOUNCEMENT_CALENDAR)
      this.$emit('close')
    },
  },
  mounted() {
  },
  components: {
    Modal,
  },
  mixins: [TranslationsMixin, AnnouncementMixin],
}
</script>

<style lang="scss" scoped>
@import "../../../scss/_variables.scss";

.modal__body {
  margin: 56px 0 0 0;
}

.announcement-calendar-container {
  .row {
    margin: 0;

    .calendar-service-item {
      cursor: pointer;
      padding: 20px;
      border-bottom: 1px solid var(--primary-community-extra-lightest);

      &:hover {
        background-color: var(--primary-community-extra-lightest);
      }

      &__icon {
        margin-right: 10px;
      }
    }
  }
}
</style>
