<template>
  <dropdown
      :is-simplified="isSimplified"
      :data="data"
      :suggestions="suggestions"
      :force="force"
      :multiple="multiple"
      :allowClear="allowClear"
      :cls="cls"
      :noResultsHandlerEnabled="noResultsHandlerEnabled"
      :custom-no-result-message="customNoResultMessage"
      :noResultMessage="noResultMessage"
      :reference="reference"
      :placeholder="placeholder"
      :width="width"
      :marginTop="marginTop"
      :maxHeight="maxHeight"
      @suggestion:accept=""
      @suggestion:decline=""
      @update:suggestions=""
      :options="options"
      v-model="internalValue"
      :search="true"
      :valueIsOption="true"
      :loading="activePromise !== null"
      :disabled="disabled"
      @search-change="handleSearchChange"
  />
</template>

<script lang="ts">
  import debounce from 'lodash/debounce.js'
  import Dropdown from './Dropdown.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    inheritAttrs: false,
    props: {
      modelValue: null,
      disabled: {
        type: Boolean,
        default: false,
      },
      minimumLength: {
        type: Number,
        default: 2,
      },
      debounceDelay: {
        type: Number,
        default: 200,
      },
      doSearch: Function, // function that receives search input and returns a promise
      doSearchOnCreated: {
        type: Boolean,
        default: false
      },

      isSimplified: {
        type: Boolean,
        default: false
      },

      // Dropdown props that are passed through. Sure there is a better way?
      data: Array, // DEPRECATED, {text, id[, children]}, please use `options` instead for new code
      suggestions: Array, // array of values, only supported together with an array `value`, can also include something that doesn't exist in the options, in which case format is {label: String, <any>...} and entire object is passed to suggestion events
      force: Boolean,
      multiple: Boolean,
      allowClear: Boolean, // enables a "Clear value" button on the right of the input
      cls: null,
      noResultsHandlerEnabled: Boolean,
      customNoResultMessage: String,
      noResultMessage: {
        type: Object,
        default: () => {
          return {
            text: 'Other',
            smallText: 'Create new item'
          }
        }
      },
      reference: null,
      placeholder: {
        type: String,
        default: ''
      },
      width: {
        type: String,
        default: '100%'
      },
      maxHeight: {
        type: Number,
        default: 300,
      },
      marginTop: {
        type: String,
      },
    },
    emits: ['update:modelValue', 'search-change'],
    data: () => ({
      options: [],
      activePromise: null,
    }),
    computed: {
      internalValue: {
        get () {
          return this.modelValue
        },
        set (value) {
          if (value) {
            this.$emit('update:modelValue', value)
          } else {
            this.$emit('update:modelValue', [])
          }
        },
      }
    },
    methods: {
      handleSearchChange (input) {
        if (input.length < 1) {
          return
        }

        this.executeSearch(input)
      },
      executeSearch (input) {
        const promise = this.doSearch(input)
        this.activePromise = promise

        promise.then(options => {
          if (this.activePromise !== promise) {
            return
          }

          this.activePromise = null
          this.options = options
        })
      },
    },
    created () {
      this.executeSearch = debounce(this.executeSearch, this.debounceDelay)

      if (this.doSearchOnCreated) {
        this.executeSearch('')
      }
    },
    components: {
      Dropdown,
    }
  })
</script>
