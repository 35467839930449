import {
  eventCalendarNotification,
  featureNotification,
  likeNotification,
  Notifications,
  pinNotification,
  unfeatureNotification
} from '../api/notifications.js'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../store/modules/ui.js'
import MODAL_IDS from '../constants/modal-ids.js'
import { validAnnouncementFilters } from '../store/modules/notifications.js'
import { ANNOUNCEMENT_ACTION_TYPES } from '../constants/announcement-constants.js'
import { trackMatomoEvent } from '@/util/analytics'
import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from '@/constants/analytics-constants'

export default {
  data () {
    return {
      announcementsError: '',
      eventsError: '',
    }
  },
  computed: {
    ecosystemColor () {
      return this.$store.state.config.primaryColor
    },
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    },
    filteredListData () {
      return this.$store.state.notifications.filteredListData
    },
    isDetailPage () {
      return this.$route.name === 'announcements-detail-new' || this.$route.name === 'announcements-detail' || this.$route.name === 'events-detail'
    },
    hasAtLeastOneFilteredAnnouncement () {
      for (const announcementFilterType of validAnnouncementFilters) {
        if (this.filteredListData[announcementFilterType] && this.filteredListData[announcementFilterType].length) {
          return true
        }
      }
      return false
    },
    availableCommunities () {
      return this.filteredListData.communities
    },
    availableAnnouncementCategories () {
      return this.$store.state.taxonomies.announcementCategories
    },
    availableEventCategories () {
      return this.$store.state.taxonomies.eventCategories
    },
    selectedCommunity () {
      return this.$store.state.notifications.listData.community
    },
  },
  methods: {
    getAvailableActionsForCommunityEcosystemForAnnouncement (announcement) {
      return [
        {
          tooltip: 'Leave a comment',
          type: ANNOUNCEMENT_ACTION_TYPES.COMMENT,
          icon: 'comments',
        },
        {
          tooltip: announcement.user_like ? 'Unlike' : 'Like',
          type: ANNOUNCEMENT_ACTION_TYPES.LIKE,
          icon: 'thumbs-up',
        },
      ]
    },
    getAvailableActionsForAnnouncement (announcement) {
      if (this.$store.getters.isMember) {
        return [
          {
            tooltip: announcement.featured ? 'Unset as featured' : 'Set as featured',
            type: ANNOUNCEMENT_ACTION_TYPES.FEATURE,
            icon: announcement.featured ? 'bookmark-filled' : 'bookmark',
          },
          {
            tooltip: 'Leave a comment',
            type: ANNOUNCEMENT_ACTION_TYPES.COMMENT,
            icon: 'comments',
          },
          {
            tooltip: announcement.user_like ? 'Unlike' : 'Like',
            type: ANNOUNCEMENT_ACTION_TYPES.LIKE,
            icon: announcement.user_like ? 'thumbs-up' : 'thumbs-up-outline',
          },
          {
            type: ANNOUNCEMENT_ACTION_TYPES.SHARE_FACEBOOK,
          },
          {
            type: ANNOUNCEMENT_ACTION_TYPES.SHARE_TWITTER,
          },
          {
            type: ANNOUNCEMENT_ACTION_TYPES.SHARE_LINKEDIN,
          },
          {
            tooltip: 'Edit post',
            type: ANNOUNCEMENT_ACTION_TYPES.EDIT,
            icon: 'edit',
          },
          {
            tooltip: 'Delete post',
            type: ANNOUNCEMENT_ACTION_TYPES.DELETE,
            icon: 'trash',
          },
        ]
      } else {
        const result = [
          {
            tooltip: announcement.user_pin ? 'Unpin' : 'Pin',
            type: ANNOUNCEMENT_ACTION_TYPES.PIN,
            icon: 'pin',
          },
          {
            tooltip: 'Leave a comment',
            type: ANNOUNCEMENT_ACTION_TYPES.COMMENT,
            icon: 'comments',
          },
          {
            tooltip: announcement.user_like ? 'Unlike' : 'Like',
            type: ANNOUNCEMENT_ACTION_TYPES.LIKE,
            icon: 'thumbs-up',
          },
          {
            type: ANNOUNCEMENT_ACTION_TYPES.SHARE_FACEBOOK,
          },
          {
            type: ANNOUNCEMENT_ACTION_TYPES.SHARE_TWITTER,
          },
          {
            type: ANNOUNCEMENT_ACTION_TYPES.SHARE_LINKEDIN,
          },
        ]
        if ((this.$store.getters.userId && this.$store.getters.userId == announcement.created_by) || (this.$store.getters.isOwner)) {
          result.push({
            tooltip: 'Edit post',
            type: ANNOUNCEMENT_ACTION_TYPES.EDIT,
            icon: 'edit',
          })

          result.push({
            tooltip: 'Delete post',
            type: ANNOUNCEMENT_ACTION_TYPES.DELETE,
            icon: 'trash',
          })
        }
        return result
      }
    },
    showCreateModal (type, actorId) {
      const announcement = {
        is_event: (type === 'event'),
      }

      if (actorId) {
        announcement.actor_id = actorId
      }

      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
        announcement: announcement,
      })

      if (type === 'event') {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.MANAGE_EVENT)
      } else {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.MANAGE_ANNOUNCEMENT)
      }
    },
    showEditModal (announcement) {
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
        announcement: announcement,
      })

      if (announcement.is_event) {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.MANAGE_EVENT)
      } else {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.MANAGE_ANNOUNCEMENT)
      }
    },
    showAddressModal (announcement) {
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
        announcement: announcement,
      })

      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ANNOUNCEMENT_ADDRESS)
    },
    clickActionButton (actionType, announcement) {
      switch (actionType) {
        case ANNOUNCEMENT_ACTION_TYPES.FEATURE:
          return this.toggleFeatureStatus(announcement)
        case ANNOUNCEMENT_ACTION_TYPES.LIKE:
          return this.toggleLikeStatus(announcement)
        case ANNOUNCEMENT_ACTION_TYPES.EDIT:
          return this.showEditModal(announcement)
        case ANNOUNCEMENT_ACTION_TYPES.DELETE:
          return this.showDeleteConfirmationModal(announcement)
        case ANNOUNCEMENT_ACTION_TYPES.DETAIL:
          return this.showDetails(announcement, false)
        case ANNOUNCEMENT_ACTION_TYPES.COMMENT:
          if (!this.isDetailPage) {
            return this.showDetails(announcement, true)
          }

          return false
        case ANNOUNCEMENT_ACTION_TYPES.EVENT_CALENDAR:
          return this.toggleEventCalendarStatus(announcement)
        case ANNOUNCEMENT_ACTION_TYPES.PIN:
          return this.togglePinStatus(announcement)
        default:
          console.log(actionType, ' not defined')
      }
    },
    togglePinStatus (announcement) {
      if (!this.isLoggedIn) {
        this.showLoginModal()
        return
      }
      pinNotification(announcement.id, !announcement.user_pin)
        .then(response => {
          this.$bus.emit('announcementUpdated', {
            id: announcement.id,
            filteredTypes: ['pinned'],
          })

          trackMatomoEvent(MATOMO_EVENT_CATEGORIES.ANNOUNCEMENT_PIN, MATOMO_EVENT_ACTIONS.PIN, announcement.id)
        })
        .catch(e => {
          if (e && e.error) {
            this.error = e.error
          }
        })
    },
    toggleEventCalendarStatus (announcement) {
      if (!this.isLoggedIn) {
        this.showLoginModal()
        return
      }
      eventCalendarNotification(announcement.id, !announcement.user_event_calendar)
        .then(response => {
          this.$bus.emit('announcementUpdated', {
            id: announcement.id,
            filteredTypes: ['calendar'],
          })
        })
        .catch(e => {
          if (e && e.error) {
            this.error = e.error
          }
        })
    },
    toggleLikeStatus (announcement) {
      if (!this.isLoggedIn) {
        this.showLoginModal()

        return
      }
      likeNotification(announcement.id, !announcement.user_like)
        .then(response => {
          this.$bus.emit('announcementUpdated', {
            id: announcement.id,
            filteredTypes: ['liked'],
          })
        })
        .catch(e => {
          if (e && e.error) {
            this.error = e.error
          }
        })
    },
    toggleFeatureStatus (announcement) {
      if (announcement.featured) {
        unfeatureNotification(announcement.id)
          .then(data => {
            this.$bus.emit('announcementUpdated', {
              id: announcement.id,
              filteredTypes: ['featured'],
            })
          })
          .catch(e => {
            if (e && e.error) {
              this.error = e.error
            }
          })
      } else {
        featureNotification(announcement.id)
          .then(data => {
            this.$bus.emit('announcementUpdated', {
              id: announcement.id,
              filteredTypes: ['featured'],
            })
          })
          .catch(e => {
            if (e && e.error) {
              this.error = e.error
            }
          })
      }
    },
    showDeleteConfirmationModal (announcement) {
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
        modalContextType: 'announcement',
        name: announcement.title,
        announcement: announcement,
      })

      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
    },
    deleteAnnouncement (announcement) {
      Notifications.delete(announcement.id)
        .then(() => {
          this.$bus.emit('announcementDeleted', {
            id: announcement.id,
            filteredTypes: ['liked', 'pinned', 'featured', 'is_event', 'upcoming_events'],
          })
        })
    },
    showLoginModal () {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
    },
    showDetails (announcement, scrollToComments) {
      const announcementSlug = announcement.slug || announcement.id
      const isEvent = announcement.is_event

      if (this.$route.name === 'community-detail') {
        this.$router.push(`/communities/${this.$route.params.communityId}/${isEvent ? 'events' : 'announcements'}/${announcementSlug}${scrollToComments ? '#comments' : ''}`)
      } else if (this.$route.name === 'events' || isEvent) {
        this.$router.push(`/events/${announcementSlug}${scrollToComments ? '#comments' : ''}`)
      } else if (this.$route.name === 'events-simplified' || isEvent) {
        this.$router.push(`/events/${announcementSlug}${scrollToComments ? '#comments' : ''}`)
      } else {
        this.$router.push(`/announcements/${announcementSlug}${scrollToComments ? '#comments' : ''}`)
      }
    },
    getAnnouncementRouterLink (announcement, scrollToComments) {
      const announcementSlug = announcement.slug || announcement.id
      const isEvent = announcement.is_event

      if (this.$route.name === 'community-detail') {
        return `/communities/${this.$route.params.communityId}/${isEvent ? 'events' : 'announcements'}/${announcementSlug}${scrollToComments ? '#comments' : ''}`
      } else if (this.$route.name === 'events' || isEvent) {
        return `/events/${announcementSlug}${scrollToComments ? '#comments' : ''}`
      } else if (this.$route.name === 'events-simplified' || isEvent) {
        return `/events/${announcementSlug}${scrollToComments ? '#comments' : ''}`
      } else {
        return `/announcements/${announcementSlug}${scrollToComments ? '#comments' : ''}`
      }
    },
  },
}
