<template>
  <div class="compare fixed-heading">
    <div class="heading">
      <h1>Compare</h1>
    </div>

    <div class="compare__scrollable scrollable">
      <div class="compare__row row">
        <div class="compare__col" v-for="actor in actors">
          <classic-card :title="actor.name" closable="true" @close="removeCard(actor)" @click="gotoActor(actor)">
            <div class="card__ranking" v-if="$store.getters.hasScores">
              <!--<icon name="certificate" /> {{ actor.current_score }} DataScouts Score-->
              <div v-if="getBusinessSizeScore(actor)">
                <icon name="business-size"/>
                {{ getBusinessSizeScore(actor) }} Business Size Score
              </div>
              <div v-if="getDigitalFootprintScore(actor)">
                <icon name="digital-footprint"/>
                {{ getDigitalFootprintScore(actor) }} Digital Footprint Score
              </div>
            </div>
            <div class="card__cover card__cover--classic" :style="'background-image: url('+ actor.website_screenshot +')'"></div>

            <div class="compare__line" :class="{'compare__line-title' : line.title}" v-for="line in lines" v-html="compareActorForProp(actor, line)"/>
          </classic-card>
        </div>
        <div class="compare__col" v-if="actors.length < 4">
          <classic-card title="Compare">
            <div class="card__description">
              Choose {{ $store.getters.anActorString }} to compare...
            </div>
            <suggestion-input
              icon="search"
              :api-settings="suggestionSettings"
              :clear-on-select="true"
              @actor="addColumn($event)"
            />
          </classic-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import _sortBy from 'lodash/sortBy'
  import _uniqBy from 'lodash/uniqBy'

  import Avatar from '../../components/Avatar/Avatar.vue'
  import ClassicCard from '../Card/ClassicCard.vue'
  import SuggestionInput from '../SuggestionInput/SuggestionInput.vue'

  import { fetchActor, suggestionSettings } from '../../api/actors.js'
  import escapeHTML from '../../util/escapeHTML.js'
  import { getNested } from '../../util/getNested.js'
  import { _unique } from '../../util/helpers.js'
  import ls from '../../util/ls.js'

  import { humanize } from '../../constants/properties.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    data () {
      return {
        compare: {
          actors: []
        }
      }
    },
    computed: {
      actors () {
        return this.compare.actors
      },
      lines () {
        var domains = this.getComparableDomains(this.actors)
        var productFeaturesA = this.getComparableValues(this.actors, 'product_features_a', 'productFeaturesA')
        var productFeaturesB = this.getComparableValues(this.actors, 'product_features_b', 'productFeaturesB')
        var productFeaturesC = this.getComparableValues(this.actors, 'product_features_c', 'productFeaturesC')

        var lines = []

        if (domains && domains.length > 0) {
          lines = lines.concat({ title: this.humanize('domains') })
          lines = lines.concat(domains)
        }

        if (productFeaturesA && productFeaturesA.length > 0) {
          lines = lines.concat({ title: this.humanize('product_features_a') })
          lines = lines.concat(productFeaturesA)
        }

        if (productFeaturesB && productFeaturesB.length > 0) {
          lines = lines.concat({ title: this.humanize('product_features_b') })
          lines = lines.concat(productFeaturesB)
        }

        if (productFeaturesC && productFeaturesC.length > 0) {
          lines = lines.concat({ title: this.humanize('product_features_c') })
          lines = lines.concat(productFeaturesC)
        }

        return lines
      },
      suggestionSettings,
    },
    methods: {
      humanize,
      compareActorForProp (actor, prop) {
        if (prop.title) {
          return '<h3 class="h3">' + prop.title + '</h3>'
        }

        return (typeof prop === 'object' ? deepCompare(actor, prop) : getNested(actor, prop)) || ''
      },
      removeCard (actor) {
        this.compare.actors = this.actors.filter(a => a.id !== actor.id)
        ls('compare.actors' + this.$store.state.user.profile.id, this.actors)
      },
      getBusinessSizeScore (actor) {
        if (actor.scores && actor.scores.business_size && actor.scores.business_size.value) {
          return actor.scores.business_size.value.toPrecision(3)
        }
      },
      getDigitalFootprintScore (actor) {
        if (actor.scores && actor.scores.digital_footprint && actor.scores.digital_footprint.value) {
          return actor.scores.digital_footprint.value.toPrecision(3)
        }
      },
      addColumn (actor) {
        if (!this.actors.find(a => a.id === actor.id)) {
          this.actors.push(actor)
        }
        fetchActor(actor.id)
          .then(fullActor => {
            const index = this.actors.findIndex(a => a.id === fullActor.id)
            if (index >= 0) {
              this.actors.splice(index, 1, fullActor)
              ls('compare.actors' + this.$store.state.user.profile.id, this.actors)
            }
          })

      },
      gotoActor (actor) {
        this.$router.push('/actors/' + actor.id)
      },
      getComparableDomains (actors) {
        // Combines all unique domains of list of actors
        const domains = [].concat.apply([], actors.map(a => getNested(a, 'domains') || []))

        // Get all parent domains
        const parentIds = _unique(domains.map(d => d.parentId || d.id))
        var parents = parentIds.map(p => this.$store.state.taxonomies.domains.find(main => p === main.id)).filter(Boolean)

        // Build a list of domains to compare
        let list = []

        // Sort by name
        parents = parents.sort((a, b) => (a.name || '').localeCompare(b.name || ''))

        parents.forEach(p => {
          const children = domains.filter(d => d.parentId === p.id)

          // Add the parent and its children
          list = list.concat(p, children.length ? _uniqBy(_sortBy(children, 'name'), 'id') : { remark: p.remark, id: p.id, parentId: -1 })
        })

        // Add prop:domains
        list = list.map(({ id, name, parentId }) => ({ prop: 'domains', id, name, parentId }))

        // Remove empty lines
        list = list.filter(line => {
          return actors
            .map(actor => deepCompare(actor, line))
            .filter(text => text !== '&nbsp;')
            .length
        })

        return list
      },
      getComparableValues (actors, property, taxonomyProperty) {
        // Build up a list of generic hierarchical values, i.e. product is product_features_a, b or c or any other "generic" 2-level taxonomy

        // Combines all unique taxonomy values for the list of actors
        var taxonomyValues = [].concat.apply([], actors.map(a => getNested(a, property) || []))
        taxonomyValues = taxonomyValues.map(r => {
          r.parentId = r.parent_id

          return r
        })

        // Get all parent taxonomy values
        const parentIds = _unique(taxonomyValues.map(d => d.parentId || d.id))
        var parents = parentIds.map(p => this.$store.state.taxonomies[taxonomyProperty].find(main => p === main.id)).filter(Boolean)

        // Build a list of taxonomy values to compare
        let list = []

        // Sort by name
        parents = parents.sort((a, b) => (a.name || '').localeCompare(b.name || ''))

        parents.forEach(p => {
          const children = taxonomyValues.filter(d => d.parentId === p.id)

          // Add the parent and its children
          list = list.concat(p, children.length ? _uniqBy(_sortBy(children, 'name'), 'id') : [])
        })

        // Add property
        list = list.map(({ id, name, parentId }) => ({ prop: property, id, name, parentId }))

        // Remove empty lines
        list = list.filter(line => {
          return actors
            .map(actor => deepCompare(actor, line))
            .filter(text => text !== '&nbsp;')
            .length
        })

        return list
      },
    },
    mounted () {
      // Persist existing actors
      const compareActors = ls('compare.actors' + this.$store.state.user.profile.id) || []
      compareActors.forEach(this.addColumn)

      if (this.actors.length) {
        return
      }

      // Add reference actor as default
      if (this.$store.getters.portfolioActor.id) {
        this.addColumn(this.$store.getters.portfolioActor)
      } else if (this.$store.state.config.portfolio_actor) {
        this.addColumn({ id: this.$store.state.config.portfolio_actor, name: 'Loading...' })
      }
    },
    components: {
      Avatar,
      ClassicCard,
      SuggestionInput,
    },
  })

  function deepCompare (actor, prop) {
    switch (prop.prop) {
      case 'domains':
        var value = getNested(actor, 'domains') || []
        var found = value.find(d => d.id === prop.id) || value.find(d => d.parentId === prop.id)

        if (prop.parentId === -1) {
          return found && found.remark ? '<b class="compare__check compare__nested">&checkmark;</b> ' + escapeHTML(found.remark)
            : '&nbsp;'
        }

        if (!prop.parentId) {
          return '<h4 class="' + (found
            ? 'compare__check"<b>&checkmark;</b> ' + escapeHTML(prop.name)
            : 'compare__nope"><b>&times;</b> ' + escapeHTML(prop.name)) +
            '</h4>'
        }

        return '<span class="compare__nested ' + (found
          ? 'compare__check"><b>&checkmark;</b> ' + escapeHTML(found.name)
          : 'compare__nope" ><b>&times;</b> ' + escapeHTML(prop.name)) +
          '</span>'
      case 'product_features_a':
      case 'product_features_b':
      case 'product_features_c':
        var value = getNested(actor, prop.prop) || []
        var found = value.find(d => d.id === prop.id) || value.find(d => d.parentId === prop.id)

        /*if (prop.parentId === -1) {
          return found && found.remark ? '<b class="compare__check compare__nested">&checkmark;</b> ' + escapeHTML(found.remark)
            : '&nbsp;'
        }*/

        if (!prop.parentId) {
          return '<h4 class="' + (found
            ? 'compare__check"<b>&checkmark;</b> ' + escapeHTML(prop.name)
            : 'compare__nope"><b>&times;</b> ' + escapeHTML(prop.name)) +
            '</h4>'
        }

        return '<span class="compare__nested ' + (found
          ? 'compare__check"><b>&checkmark;</b> ' + escapeHTML(found.name)
          : 'compare__nope" ><b>&times;</b> ' + escapeHTML(prop.name)) +
          '</span>'
    }
  }
</script>

<style lang="scss">
  .compare__scrollable.scrollable {
    overflow: auto;
    display: flex;
  }

  .compare__row.row {
    width: 40rem;
    flex-basis: calc(72rem + 10px);
    flex-shrink: 0;
    margin: 10px;
  }

  .compare__col {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: 18rem;
    width: 18rem;
    padding: 8px;
  }

  .card__title,
  .compare__line {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .compare__line > h3 {
    margin-top: 1rem;
    overflow: visible;
    white-space: normal;
  }

  .compare__line > h4 {
    margin: .5rem 0;
    overflow: visible;
    white-space: normal;
  }

  .compare__line > h3 + h4 {
    margin-top: -1rem;
  }

  .card__cover.card__cover--classic {
    margin: 0 -1rem;
    width: calc(17rem - 2px);
    height: 8.5rem;
  }

  .compare__nope {
    font-weight: 300;
    color: #999;

    b {
      color: #FD916D;
    }
  }

  .compare__nested {
    padding-left: 1rem;
  }
</style>
