<template>
  <div class="collapsable-tab__content-container">
    <form-group
      class="col-xs-12" :label="membershipLabel" :errors="errors.membership"
      v-if="($store.getters.isMember || $store.getters.isPortfolioMember || ['wear', 'wearables'].includes($store.getters.currentEcosystem.name)) && membershipOptions.length"
      :class="{'actor-edit__private-field': isPrivateField('membership')}"
    >
      <dropdown
        placeholder="" multiple :options="membershipOptions"
        v-model="modifiedActor.membership" allow-clear :searchable="true"
      />
    </form-group>

    <form-group
      class="col-xs-12" :label="$t('actor_edit_maturity')" :errors="errors.maturity"
      v-if="isRelevantForActor('maturity', modifiedActor)"
      :class="{'actor-edit__private-field': isPrivateField('maturity')}"
    >
      <dropdown :options="maturityOptions" v-model="modifiedActor.maturity"/>
    </form-group>

    <form-group
      class="col-xs-12" :label="stageLabel" :errors="errors.stage"
      v-if="isMember && canSeeProperty('stage') && stageOptions.length && !['Person', 'Product'].includes(modifiedActor.actor_type)"
      :class="{'actor-edit__private-field': isPrivateField('stage')}"
    >
      <dropdown
        placeholder="Growth Stage" :options="stageOptions"
        v-model="modifiedActor.stage" allow-clear
      />
    </form-group>

    <form-group
      class="col-xs-12" :label="funnelStageLabel" :errors="errors.funnel_stage"
      v-if="isMember && canSeeProperty('funnel_stage') && funnelStageOptions.length && !['Product'].includes(modifiedActor.actor_type)"
      :class="{'actor-edit__private-field': isPrivateField('funnel_stage')}"
    >
      <dropdown
        placeholder="CRM Stage" :options="funnelStageOptions"
        v-model="modifiedActor.funnel_stage" allow-clear
      />
    </form-group>

    <form-group
      v-if="userCanEditPortfolio" class="col-xs-12" :label="$t('actor_edit_portfolios')"
      :errors="errors.portfolios"
      :class="{'actor-edit__private-field': isPrivateField('portfolios')}"
    >
      <autocomplete-dropdown
        multiple
        placeholder="Portfolios"
        :options="portfolioOptions"
        :do-search="searchPortfolios"
        :searchable="true"
        :model-value="portfolios"
        :noResultsHandlerEnabled="false"
        @update:modelValue="applyPortfolios"
      />
    </form-group>

    <form-group
      v-if="modifiedActor.is_zombie && (isOwner || isTeamMember)"
      class="col-xs-12 card"
      label="This actor has been flagged as a zombie. Click on the button below to remove the flag."
      :errors="errors.portfolios"
    >
      <br>
      <ds-button
        variant="secondary" label="Remove flag" class="col-xs-12"
        @click="removeZombieFlag"
      />
    </form-group>
  </div>
</template>

<script lang="ts">
  import FormGroup from '../../Form/FormGroup.vue'
  import TagInput from '../../Form/TagInput.vue'
  import Dropdown from '../../Dropdown/Dropdown.vue'
  import ConfigMixin from '../../../util/ConfigMixin.js'
  import { isRelevantForActor } from '@/util/helpers.js'
  import AutocompleteDropdown from '@/components/Dropdown/AutocompleteDropdown.vue'
  import { fetchPortfolio } from '@/api/portfolios.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'actor-edit-segmentation',
    props: {
      modifiedActor: Object,
      show: Object,
      errors: Object,
      userCanEditPortfolio: Boolean,
    },
    data() {
      return {
        portfolioOptions: [],
      }
    },
    computed: {
      portfolios() {
        return this.modifiedActor.portfolios.map((portfolio) => {
            return {
              value: portfolio.id, label: portfolio.name
            }
          }
        )
      },
      funnelStageOptions() {
        return this.funnelStageValuesAndLabels
      },
      funnelStageLabel() {
        return this.getTaxonomyAlias('funnel_stage', false, this.$t('actor_edit_crm_stage'))
      },
      stageOptions() {
        return this.stageValuesAndLabels
      },
      isMember() {
        return this.$store.getters.isMember
      },
      stageLabel() {
        return this.getTaxonomyAlias('stage', false, this.$t('actor_edit_growth_stage'))
      },
      maturityOptions() {
        return [{
          value: 'formation', label: this.$t('actor_edit_formation'),
        }, {
          value: 'validation', label: this.$t('actor_edit_validation'),
        }, {
          value: 'growth', label: this.$t('actor_edit_growth'),
        }, {
          value: 'mature', label: this.$t('actor_edit_mature'),
        }, {
          value: 'acquired', label: this.$t('actor_edit_acquired'),
        }, {
          value: 'dead', label: this.$t('actor_edit_dead'),
        }]
      },
      membershipLabel() {
        return this.getTaxonomyAlias('memberships', false, this.$t('actor_edit_memberships'))
      },
      publicFields() {
        return this.$store.getters.publicFields
      },
      isPublisherEnabled() {
        return this.$store.getters.isPublisherEnabled
      },
      membershipOptions() {
        return this.membershipValuesAndLabels
      },
      isTeamMember() {
        return this.$store.getters.isTeamMember
      },
      isOwner() {
        return this.$store.getters.isOwner
      },
    },
    methods: {
      searchPortfolios(searchQuery: string): Promise {
        return fetchPortfolio({ type: 'all', limit: 10, query: searchQuery })
          .then(portfolios => {
            return portfolios
              .filter(i => !i.virtual && !i.is_immutable)
              .map(i => {
                return { value: i.id, label: i.name }
              })
          }).catch(errors => {
            console.warn('Failed to fetch portfolios', errors)

            return []

          })
      },
      applyPortfolios(allChosenPortfolios) {
        this.modifiedActor.portfolios = allChosenPortfolios
          .map(portfolio => {
            return { id: portfolio.value, name: portfolio.label }
          })
      },
      removeZombieFlag() {
        if (this.modifiedActor.is_zombie) {
          this.modifiedActor.is_zombie = false
        }
      },
      canSeeProperty(property) {
        return this.$store.state.config.public_fields && this.$store.state.config.public_fields.includes(property)
      },
      isPrivateField(field) {
        return this.isPublisherEnabled && !this.publicFields.includes(field)
      },
      isRelevantForActor,
    },
    components: {
      AutocompleteDropdown,
      FormGroup,
      TagInput,
      Dropdown,
    },
    mixins: [ConfigMixin]
  })
</script>
