<template>
  <div class="spotting-area__trends" style="display: flex;">
    <Container style="flex-grow: 1; max-width: 100%;">
      <div class="spotting-area__trends_article row">
        <template v-if="fetchingContent">
          <div class="col-md-12">
            <loading/>
          </div>
        </template>
        <template v-for="(item, index) in articles" :key="index" v-else-if="articles && articles.length > 0">
          <div class="col-md-6" style="width: 100%;margin-bottom: 10px;">
            <AnnouncementOrResourceCard
              style="max-width: 100%"
              :data="item"
              :preview-mode="false"
              @like-article="likeArticle"
              @addTag="addTagToFilters"
              @resourceDeleted="this.fetchArticles()"
            />
          </div>
        </template>
        <template v-else>
          <div class="col-md-12" style="display: flex; justify-content: flex-start; margin-bottom: 1rem;">
            <p><small>No results found</small></p>
          </div>
        </template>
      </div>

      <div v-if="!fetchingContent && articles && articles.length > 0" style="margin-top: 10px;">
        <button @click="addActorToFilters" type="button" class="a subtle-button">Show all resources</button>
      </div>
    </Container>
  </div>
</template>

<script>
  import { fetchKnowledgeBaseData, likeArticle } from '../../api/knowledge-base.js'
  import { MUTATION_TYPES as KNOWLEDGE_BASE_MUTATION_TYPES } from '../../store/modules/knowledgeBase'
  import Container from '../../components/Container/Container.vue'
  import AnnouncementOrResourceCard from '../../components/Simplified/AnnouncementOrResourceCard.vue'
  import LabelFilter from '../../components/Filters/LabelFilter.vue'
  import Loading from '../../components/Dashboard/ConceptMap/Loading.vue'

  export default {
    components: { Loading, Container, AnnouncementOrResourceCard, LabelFilter },
    props: {
      actorId: {
        type: String,
        required: true,
      }
    },
    data () {
      return {
        articles: [],
        fetchingContent: false,
      }
    },
    computed: {
      displaySorting () {
        return this.$store.getters.isOwner || this.$store.getters.isMember
      }
    },
    methods: {
      likeArticle ({ resourceId, status }) {
        likeArticle(resourceId, status)
      },
      addTagToFilters (tag) {
        if (!tag.value) {
          return
        }

        this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.CLEAR)
        this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.SET_FACET_KEYWORD, tag)

        if (this.$route.name && this.$route.name.indexOf('knowledge-base') <= 0) {
          this.$router.push('/dashboards/knowledge-base')
        }
      },
      addActorToFilters () {
        this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.CLEAR)
        this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.SET_ACTOR, { facet: 'top_actor', value: this.actorId })

        if (this.$route.name && this.$route.name.indexOf('knowledge-base') <= 0) {
          this.$router.push('/dashboards/knowledge-base')
        }
      },
      fetchArticles () {
        this.fetchingContent = true

        var sortBy = 'date'

        var actorId = this.actorId
        if (this.$route.params && this.$route.params.id) {
          actorId = this.$route.params.id
        }

        const filters = {
          // media_types: ['rss', 'pr', 'blog'],
          top_actor: actorId,
          'ml-supported': false,
          relevantOnly: true,
          limit: 10,
          sortBy
        }

        fetchKnowledgeBaseData(filters)
          .then(response => {
            this.articles = response.data.results

            this.fetchingContent = false
          })
          .catch(error => {
            console.log(error)
            this.fetchingContent = false
          })
      },
    },
    mounted () {
      this.fetchArticles()
    },
    watch: {
      '$route.params.id': {
        handler () {
          this.fetchArticles()
        },
        immediate: false, // calls the handler on component mount/create as well, so no need to call fn on mounted again
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables.scss";

  @media (max-width: $screen-lg) {
    .spotting-area__trends_article.row {
      margin-right: 0;
      margin-left: 0;
    }
  }

</style>
