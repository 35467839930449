<template>
  <div class="drawer" :class="{ 'drawer--open': isOpen, 'drawer--padded': padded, 'enable-animation': !enableAnimation }" ref="drawer" :style="{left: pixelsMovedToLeft + 'px'}">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    name: 'Drawer',
    data () {
      return {
        touchStartX: 0,
        touchMoveX: 0,
        touchEndX: 0,
      }
    },
    props: {
      isOpen: Boolean,
      padded: {
        type: Boolean,
        default: false,
      }
    },
    computed: {
      enableAnimation () {
        return this.touchMoveX
      },
      pixelsMovedToLeft () {
        if (this.touchStartX && this.touchMoveX && this.touchMoveX < this.touchStartX) {
          return this.touchMoveX - this.touchStartX
        }
        return this.isOpen ? 0 : -350
      }
    },
    methods: {
    },
    mounted () {
      this.$refs.drawer.addEventListener('touchstart', (event) => {
        if (!this.isOpen) {
          return
        }

        this.touchStartX = event.changedTouches[0].screenX
      })
      this.$refs.drawer.addEventListener('touchmove', (event) => {
        if (!this.isOpen || !this.touchStartX) {
          return
        }

        this.touchMoveX = event.changedTouches[0].screenX
      })
      this.$refs.drawer.addEventListener('touchend', (event) => {
        if (!this.isOpen) {
          return
        }

        this.touchEndX = event.changedTouches[0].screenX

        if (this.touchEndX + 20 < this.touchStartX) {
          this.$emit('close')
        }

        this.touchMoveX = 0
        this.touchStartX = 0
        this.touchEndX = 0
      })
    }
  }
</script>

<style lang="scss">
  @import "../../../scss/variables";
  @import "../../../scss/components/TopBar";
  @import "../../../scss/mixins/icon-color";

  .drawer {
    transform: translateX(0px);
    position: fixed;
    top: $top-bar-height;
    left: -350px;
    bottom: 0;
    width: 80vw;
    max-width: 350px;
    z-index: 100;
    padding-top: 1rem;
    background-color: $color-background-lightest-grey;

    &.enable-animation {
      transition: left .2s ease-in-out;
    }

    @media (max-width: $screen-md) {
      top: 75px;
    }

  }

  .drawer--padded {
    padding: 1rem;
  }

  .drawer--open {
    left: 0;
    overflow-y: auto;
  }

</style>
