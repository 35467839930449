<template>
  <div class="user-social-buttons">
    <a target="_blank" :href="user.linkedin" v-if="user.linkedin">
      <icon name="linkedin-logo" :style="{'--icon-color': color}" />
    </a>
    <a target="_blank" :href="user.instagram" v-if="user.instagram">
      <icon name="instagram-logo" :style="{'--icon-color': color}" />
    </a>
    <a target="_blank" :href="user.twitter" v-if="user.twitter">
      <icon name="twitter-logo" :style="{'--icon-color': color}" />
    </a>
  </div>
</template>

<script lang="ts">

import { defineComponent } from 'vue'
import SimpleSocialItem from '@/components/Card/SimpleSocialItem.vue'
import { UserBaseInfoData } from '@/Domain/User/Types/types'

export default defineComponent({
  name: "UserSocialButtons",
  props: {
    user: {
      type: Object as PropType<UserBaseInfoData>,
      required: true
    },
    color: {
      type: String,
      default: 'var(--primary)'
    }
  },
  components: {
    SimpleSocialItem
  }
})
</script>

<style scoped lang="scss">

.user-social-buttons {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;

  :deep(g), :deep(path) {
    stroke: var(--icon-color);
    fill: var(--icon-color);
  }

  .svg-icon--instagram-logo {
    margin-top: 4px;
  }

  .svg-icon--twitter-logo {
    margin-top: 4px;
  }
}

</style>
