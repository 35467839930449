<template>
  <div class="ds-input" :class="{ 'ds-input--has-icon': icon }">
    <div class="keyword-ds-input-icon keyword-ds-input-container" v-if="icon">
      <icon :name="icon" />
    </div>
    <div class="keyword-ds-input-tags keyword-ds-input-container" v-if="activeKeywords.length > 0">
      <keyword
        v-for="(keyword, index) in activeKeywords.slice(0, 5)"
        :label="keywordLabel(keyword)"
        :value="keyword"
        removeable
        @remove="handleRemoveKeyword"
      />
    </div>
    <div class="keyword-ds-input-show-more keyword-ds-input-container" v-if="activeKeywords.length > 5">
      <a @click="showMoreModal">Show more...</a>
    </div>
    <div class="keyword-ds-input-search-bar keyword-ds-input-container">
      <input
        :disabled="disabled"
        :model-value="model"
        :name="name"
        :placeholder="placeholder"
        :readonly="readonly"
        :type="type"
        class="ds-input__input"
        @blur="$emit('blur')"
        @change="handleChange"
        @input="handleInput"
      >
    </div>
  </div>
</template>

<script lang="ts">
  import Keyword from '../Keyword/Keyword.vue'
  import { toAmount, fromAmount } from '../../util/currency.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import { defineComponent } from 'vue'
  export default defineComponent({
    name: 'keyword-ds-input',
    props: {
      icon: {
        type: String
      },
      modelValue: null,
      placeholder: null,
      type: {
        type: String,
        default: 'text'
      },
      name: {
        type: String
      },
      precision: {
        default: 0
      },
      readonly: {
        type: Boolean,
        default: false
      },
      disabled: {
        type: Boolean,
        default: false
      },
      keywordsFor: {
        type: String,
        default: 'portfolio'
      }
    },
    emits: ['update:modelValue', 'blur', 'removedKeyword'],
    computed: {
      model () {
        if (this.type === 'amount' && this.precision > 0) {
          return toAmount(this.modelValue, this.precision)
        }
        return this.modelValue
      },
      enabled () {
        if (this.$store.getters.isWear && this.$route.query.actor_type === '') {
          return this.$store.state.config.filterControls.filter(f => !['founding_date'].includes(f))
        }
        return this.$store.state.config.filterControls || []
      },
      activeKeywords () {
        if (this.keywordsFor === 'portfolio') {
          return this.activePortfolioKeywords
        }

        return this.activeAnnouncementKeywords
      },
      activeAnnouncementKeywords () {
        return this.$store.getters.activeAnnouncementKeywords
      },
      activePortfolioKeywords () {
        return this.$store.getters.activePortfolioKeywords
      },
    },
    methods: {
      keywordLabel (keyword) {
        // If a label property is set, use that instead of the value property
        if (keyword.label) {
          return keyword.label
        }

        return keyword.value
      },
      handleInput (evt) {
        if (this.type === 'amount' && this.precision > 0) {
          return
        }
        let value = evt.target.value
        this.$emit('update:modelValue', value)
      },
      handleChange (evt) {
        if (this.type === 'amount' && this.precision > 0) {
          this.$emit('update:modelValue', fromAmount(evt.target.value, this.precision))
        }
      },
      handleRemoveKeyword (keyword) {
        this.$emit('removedKeyword', keyword)
      },
      showMoreModal () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.MORE_TAGS)
      }
    },
    created () {
      this.$bus.on('portfolioRemovedTag', (keyword) => {
        if (keyword !== undefined) {
          this.handleRemoveKeyword(keyword)
        }
      })
    },
    beforeUnmount () {
      this.$bus.off('portfolioRemovedTag')
    },
    components: {
      Keyword
    }
})
</script>
