<template>
  <div>
    <form-group :label="$t('confirm_package_promocode_confirmation')"
                style="margin-top: 15px"
                :error="paymentError.promoCode"
                class="package-picker__coupon-code">
      <ds-input :model-value="model" @update:modelValue="handleInput" is-simplified/>
      <p>
        <span v-if="couponValidation">{{
            couponValidation.valid ? `This coupon is valid for a ${couponValidation.discount}% discount` : 'The coupon code is not valid'
          }}</span>
      </p>
      <ds-button
        label="validate"
        size="small"
        @click="validateCouponCode()"
        :disabled="!selectedPackage.id || updatingPlan"
      />
    </form-group>

  </div>
</template>

<script>
  import { validateCouponCode } from '../../../api/user'

  export default {
    name: 'AskCouponCode.vue',
    props: ['selectedPackage', 'couponValidation', 'paymentError', 'updatingPlan', 'modelValue'],
    emits: ['update:modelValue', 'setCouponValidation'],
    data () {
      return {
        errorMessage: '',
      }
    },
    computed: {
      model () {
        return this.modelValue
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
    },
    methods: {
      handleInput (value) {
        this.$emit('update:modelValue', value)
        this.setCouponValidation(null)
      },
      setCouponValidation (value) {
        this.$emit('setCouponValidation', value)
      },
      validateCouponCode () {
        validateCouponCode(this.modelValue, this.selectedPackage.id).then((result) => {
          this.setCouponValidation(result)
        })
      },
    },
  }
</script>

<style scoped>

</style>
