import { createJSONRequest } from './create-request'
import querify from '../util/querify'

//Fetch default main topics
export const fetchBuzzDefaultTopics = (parameters) => createJSONRequest({
  method: 'GET',
  url: `/api/dashboards/buzz?type=topics&${querify(parameters)}`
});

// Fetch the buzz data
// type defines the type of buzz API, values can be: topics, timeline, influencers, segmentation
// dimension defines the dimension on which the data needs to be gathered, values can be: default, influencer, topic
// dimension_value is the value of the dimension, for example the ID of an influencer, the value of a topic
// Note: for "default" dimension, there's no need for a dimension value
export const fetchBuzzData = (parameters) => createJSONRequest({
  method: 'GET',
  url: `/api/dashboards/buzz?${querify(parameters)}`
});

// Fetch data for the buzz search dropdown
export const fetchBuzzDropdownData = cancelIfLate((parameters) => createJSONRequest({
  method: 'GET',
  url: `/api/dashboards/buzz/search?${querify(parameters)}`
}));

// Fetch data for the media buzz
export const fetchMediaBuzzData = (parameters) => createJSONRequest({
  method: 'GET',
  url: `/api/dashboards/media-buzz?${querify(parameters)}`
});


// This function will assign ascending numbers to each request
// The number of the last request is saved in `received`
// When a response is received from a request with a number lower than `received`, it will get rejected
function cancelIfLate (request) {
  let sent = 0
  let received = 0

  return (a, b) => {
    const req = sent += 1
    return request(a, b)
      .then(data => {
        //console.log(req, 'req');
        received !== req - 1 && console.log(received, req)
        if (received < req) {
          received = req
          return data
        }

        // Request Time-out
        return Promise.reject({ code: 408 })
      })
  }
};
