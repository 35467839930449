<template>
  <div>
    <h2 class="h2">Payment methods</h2>
    <ds-button label="Add a payment method" size="small" @click="addNewPaymentMethod()"/>

    <div style="margin-top: 1em;">
      <div v-for="(method, index) in paymentMethods" :key="'pm_' + index" class="user-payment-method__existing-pm">
        <icon name="credit-card" size="14"/>
        <div>&nbsp;&bull;&bull;&bull;&bull; {{ method.card_last4 }} &nbsp;&nbsp; Expires at
          {{ method.card_exp_month }}/{{ method.card_exp_year }}&nbsp;
        </div>

        <div class="user-payment-method__existing-pm-cta">
          <badge name="default" size="tiny" v-if="method.is_default"/>

          <ds-button icon="trash" @click="removePaymentMethod(method.id)" size="extra-small" v-if="!method.is_default"
                     :disabled="loading || updatingPaymentMethod"/>
          <ds-button
              @click="makePaymentMethodDefault(method.id)"
              label="set as default"
              style="line-height: 16px;"
              size="extra-small"
              v-if="!method.is_default"
              :disabled="loading || updatingPaymentMethod"
          />
        </div>
      </div>
    </div>

    <div class="personal-payment-info__cc-information"
         :class="{'person-payment-info__hidden' : !displayAddNewPaymentMethodFlag}">
      <h3 class="h3">Provide your billing information</h3>
      <form-group label="Name on the card" style="margin-top: 15px;"
                  :error="!nameOnCard && nameOnCardError">
        <ds-input required id="card-holder-name" v-model="nameOnCard"
                  style="width: 300px;"/>
      </form-group>

      <!-- Stripe Elements Placeholder -->
      <form-group label="Credit card details" :error="paymentMethodError"
                  style="margin-bottom: 0px;">
        <div id="card-element"
             class="form-control user-payment-method__credit-card-info"></div>
      </form-group>

      <div style="margin-top: 0.5em;">
        <ds-button label="Add payment method" size="small" id="card-button"
                   :data-secret="stripeIntent" @click="storeNewPaymentMethod()"
                   :disabled="updatingPaymentMethod"/>
        <ds-button label="Cancel" size="small" @click="cancelAddNewPaymentMethod()"
                   :disabled="updatingPaymentMethod"/>
      </div>
    </div>

  </div>
</template>

<script>
import { addPaymentMethod, deletePaymentMethod, getStripeIntent, updateDefaultPayment } from '../../../api/user'

import Badge from '../../Badge/Badge.vue'

export default {
  name: 'UserPaymentMethod',
  props: {
    paymentMethods: Array,
    loading: Boolean,
  },
  data() {
    return {
      // Payment & Stripe information
      stripe: '', // Stripe elements requirements
      elements: '', // Stripe elements
      card: '', // Stripe element, card info NEVER touches our code
      nameOnCard: '',
      stripeIntent: '',
      paymentMethod: {},
      paymentMethodError: '',
      stripeLoaded: false,
      nameOnCardError: '',

      // Status variables
      updatingPaymentMethod: false,

      // Payment information
      displayAddNewPaymentMethodFlag: false,
    }
  },
  methods: {
    addNewPaymentMethod() {
      this.displayAddNewPaymentMethodFlag = true
    },
    cancelAddNewPaymentMethod() {
      this.displayAddNewPaymentMethodFlag = false
    },
    // Validate card information via Stripe
    async storeNewPaymentMethod() {
      // Clear the payment method error
      if (!this.nameOnCard) {
        this.nameOnCardError = 'Required'
        return
      } else {
        this.nameOnCardError = ''
      }

      this.paymentMethodError = ''

      this.updatingPaymentMethod = true

      try {
        // Make sure it's a valid card
        await this.validate()
      } catch (e) {
        console.log(e)

        this.paymentMethodError = e
        return
      }

      // All is well, update the payment method
      this.updatingPaymentMethod = true

      addPaymentMethod({
        paymentMethodId: this.paymentMethod.id,
      })
          .then(response => {
            // Update payment methods
            this.fetchUserPaymentInfo()
            this.card.clear()
            this.updatingPaymentMethod = false
            this.displayAddNewPaymentMethodFlag = false
          })
          .catch(error => {
            this.updatingPaymentMethod = false

            this.paymentMethodError = error.message
          })
    },
    makePaymentMethodDefault(paymentMethodId) {
      this.updatingPaymentMethod = true

      updateDefaultPayment({ paymentMethodId })
          .then(response => {
            this.updatingPaymentMethod = false
            this.fetchUserPaymentInfo()
          })
          .catch(e => {
            this.updatingPaymentMethod = false
          })
    },
    removePaymentMethod(paymentMethodId) {
      this.updatingPaymentMethod = true

      deletePaymentMethod({ paymentMethodId })
          .then(response => {
            this.updatingPaymentMethod = false
            this.fetchUserPaymentInfo()
          })
          .catch(e => {
            this.updatingPaymentMethod = false
            console.log(e)
          })
    },
    async validate() {
      this.paymentMethodError = ''
      const { paymentMethod, error } = await this.stripe.createPaymentMethod(
          'card',
          this.card,
          {
            billing_details: { name: this.nameOnCard },
          },
      )

      if (error) {
        // Display "error.message" to the user...
        this.paymentMethodError = error
      } else {
        // The card has been verified successfully...
        this.paymentMethod = paymentMethod
      }
    },
    fetchUserPaymentInfo() {
      this.$emit('updatePaymentInfo')
    },
    // Includes Stripe.js dynamically, if we pull it in via main.blade we need to use window.Stripe I believe, but haven't tested it
    /*includeStripe (URL, callback) {
      let documentTag = document, tag = 'script',
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0]
      object.src = '//' + URL

      if (callback) {
        object.addEventListener('load', function (e) {
          callback(null, e)
        }, false)
      }
      scriptTag.parentNode.insertBefore(object, scriptTag)
    },*/
    configureStripe() {
      this.stripe = window.Stripe(this.$store.getters.publicStripeKey)

      this.elements = this.stripe.elements()
      this.card = this.elements.create('card')

      // prevent attempting to mount after visiting a different page
      if (document.getElementById('card-element')) {
        this.card.mount('#card-element')
      }
    },
  },
  async mounted() {
    /*if ($('script[src="//js.stripe.com/v3/"]').length === 0) {
      this.includeStripe('js.stripe.com/v3/', function () {
        this.configureStripe()
      }.bind(this))
    }*/

    try {
      this.stripeIntent = await getStripeIntent()
    } catch (e) {
      console.log('Failed to fetch a stripe intent: ' + e)
    }

    this.configureStripe()

    // Fetch payment info
    this.fetchUserPaymentInfo()
  },
  components: {
    Badge,
  },
}
</script>

<style scoped lang="scss">
.user-payment-method__credit-card-info {
  width: 20rem;
}

.user-payment-method__existing-pm {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-bottom: 0.5em;
}

.user-payment-method__existing-pm-cta {
  display: flex;
  align-items: flex-end;
  margin-left: 0.3em;
}

.person-payment-info__hidden {
  visibility: hidden;
  height: 0px;
}

.personal-payment-info__cc-information {
  margin-top: 1em;
}
</style>
