<template>
  <a class="button" :class="classes" rel="noopener" :target="openInCurrentTab ? '_self' : '_blank'" :href="href"
     :tabindex="tabindex" v-if="href">
    <icon v-if="icon" :name="icon" :style="{fill: iconFillColor ?? ''}" :size="sizeOfIcon"/>
    <span class="button__label">{{ label }}</span>
  </a>
  <router-link class="button" :class="classes" :to="to" @click="handleClick" :tabindex="tabindex" v-else-if="to">
    <icon v-if="icon" :name="icon" :style="{fill: iconFillColor ?? ''}" :size="sizeOfIcon"/>
    <span class="button__label">{{ label }}</span>
    <badge-counter class="counter-on-button" :count="badgeCount" v-if="badgeCount"></badge-counter>
  </router-link>
  <button class="button" :type="type" :class="classes" :title="title" @click="handleClick" :disabled="disabled"
          :tabindex="tabindex" @mouseenter="hovered = true" @mouseleave="hovered = false" v-else>
    <icon v-if="icon" :name="icon" :style="{fill: iconFillColor ?? ''}" :size="sizeOfIcon" :noFill="noFill"/>
    <span v-if="!withoutLabel" class="button__label"
          v-html="hovered && hoverLabel ? hoverLabel : (label || '&nbsp;')"></span>
    <span v-if="!withoutLabel" class="button__sub-label" v-html="subLabel"></span>
    <template v-if="badgeCount && noCount">
      <badge-counter :noCount="noCount" v-if="badgeCount"></badge-counter>
    </template>
    <template v-else>
      <badge-counter :count="badgeCount" v-if="badgeCount"></badge-counter>
    </template>
  </button>
</template>

<script>
import Icon from '../Icon/Icon.vue'
import BadgeCounter from '../Badge/BadgeCounter.vue'
import { trackHeapEvent } from '../../util/analytics'

export default {
  data() {
    return {
      hovered: false
    }
  },
  props: {
    label: {
      type: String
    },
    subLabel: {
      type: String
    },
    title: {
      type: String
    },
    hoverLabel: {
      type: String
    },
    href: {
      type: String
    },
    to: {
      type: String
    },
    icon: {
      type: String
    },
    block: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false
    },
    withoutLabel: {
      type: Boolean,
      default: false
    },
    variant: {
      type: String,
      default: 'default'
    },
    size: {
      type: String,
      validator: val => ['tiny', 'extra-small', 'small', 'large'].includes(val)
    },
    iconSize: {
      type: String,
      validator: value => ['regular', 'large', 'small', 'extra-small', '12', '14', '18', '22'].includes(value),
    },
    faIconColor: {
      // For FontAwesome icons (faIcon) only
      type: String,
      default: 'inherit',
    },
    iconFillColor: {
      type: String,
      default: ''
    },
    noFill: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: 'button'
    },
    heapEvent: {
      type: String
    },
    badgeCount: null,
    noCount: {
      type: Boolean,
      default: false,
    },
    openInCurrentTab: {
      type: Boolean,
      default: false,
    },
    tabindex: {},
  },
  emits: ['click'],
  computed: {
    classes() {
      return {
        'button--block': this.block !== undefined,
        'button--icon': !this.label && this.icon,
        [`button--${this.variant}`]: this.variant,
        [`button--${this.size}`]: this.size,
      }
    },
    faIconStyle() {
      if (this.faIconColor) {
        return { color: this.faIconColor }
      } else {
        return undefined
      }
    },
    isBadgeCountBoolean() {
      return typeof this.badgeCount
    },
    sizeOfIcon() {
      if (this.iconSize) {
        return this.iconSize
      }

      return this.size
    }
  },
  methods: {
    handleClick(evt) {
      if (this.disabled) {
        return
      }

      this.$emit('click', evt)

      if (this.heapEvent && this.heapEvent.length) {
        console.log('track heap event')
        trackHeapEvent(this.heapEvent)
      }
    }
  },
  components: {
    Icon,
    BadgeCounter
  }
}
</script>

<style lang="scss" scoped>
@import "../../../scss/_variables.scss";

.svg-inline--fa {
  position: relative;
  left: -4px;
}

.counter-on-button {
  :deep(.counter__badge) {
    right: -3px;
    top: -2px;
    background-color: var(--primary);
  }
}

// Make sure to display the title on hover when disabled
.button:disabled, .button[disabled=disabled], .button[disabled] {
  pointer-events: auto;
  cursor: auto;
}

.button:hover .svg-inline--fa {
  color: inherit !important;
}

.fa-icon-only {
  .svg-inline--fa {
    left: 0;
  }

  .button__label {
    display: none;
  }

  &.button--small {
    padding-left: 8px;
    padding-right: 8px;
  }

  &.button--extra-small {
    padding-left: 5px;
    padding-right: 5px;
  }
}
</style>
