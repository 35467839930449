<template>
  <div class="instant-monitoring">
    <div class="instant-monitoring__results-panel">
      <div class="results-panel__search-section">
        <div class="results-panel__top-bar">
          <div class="title">
            {{ conceptSearch.title || 'TREND MONITORING' }}
          </div>
          <div class="results-panel__actions">
            <checkbox inline label="auto-refresh" v-model="autoRank"
                      @update-modelValue="onChangeAutoRank"/>
          </div>
        </div>
        <form-group
          label="Add your keywords here, or select them from the right-hand side">
          <keywords-with-suggestions-dropdown v-model="selectedKeywords"
                                              @update-modelValue="trackHeapEvent('instantMonitoring.changeKeywordInput')"
                                              :disabled="loadCount !== 0"
                                              :allowFreeTextTag="false"/>
        </form-group>
        <div class="results-section__title">SHOWING TOP 100 RESULTS.</div>
      </div>
      <div class="results-panel__results-section" v-if="loadCount === 0">
        <div class="results-section__monitoring-cards">
          <!-- NOTE: this card should be deprecated, instead we should use the knowledge-base-item/announcement-or-resource component -->
          <instant-monitoring-card
            :article="article"
            v-for="(article, index) in articles"
            :key="'article' + index"/>

          <div class="filler-instant-monitoring-card"/>
          <div class="filler-instant-monitoring-card"/>
          <div class="filler-instant-monitoring-card"/>
          <div class="filler-instant-monitoring-card"/>
        </div>
      </div>
      <div v-else>
        <Loading/>
      </div>
    </div>
    <div class="instant-monitoring__filters-panel">
      <div class="filters-panel__actions-section"
           v-if="conceptSearch.explored_at || (isOwner && conceptSearch.id)">
        <span class="actions-section__last-run" v-if="conceptSearch.explored_at">
        last run: {{ lastRun }}
        </span>
        <div class="actions-section__button-container">
          <publish-button class="a-button" v-model="published"
                          v-if="isOwner && conceptSearch.id"/>
          <ds-button
            style="border: 1px solid #eeeeee"
            variant="minimal"
            icon="trash"
            heapEvent="conceptMap.clickDeleteSearch"
            size="extra-small"
            @click="remove"
            v-if="isOwner && conceptSearch.id"
          />
        </div>
      </div>
      <div class="filters-panel__description">
        Filter by the most relevant keywords, actors, locations and sources to refine your
        trend monitoring. We will use your parameters to retrieve relevant content
      </div>
      <div class="filters-panel__filters-section">
        <p v-if="showInsufficientParamsMessage && hasSearchedAtLeastOnce" style="color: red">Please choose at
          least one keyword to start a search</p>
        <div class="filters-panel__filters-subsection"
             v-for="(filterSection, index) in filterSections"
             :key="'filterSection' + index">
          <h4 class="h4 filters-title">{{ filterSection.title }}</h4>
          <p class="filters-subtitle">{{ filterSection.subTitle }}</p>
          <div class="filters-section__clickable-keywords">
            <div class="clickable-keyword"
                 v-for="(keyword, index) in filterSection.open ? filterSection.clickableKeywords : filterSection.clickableKeywords.slice(0, 10)"
                 :key="index + componentKey + 'keyword'"
                 :class="{selected: keywordIsSelected(keyword), disabled: loadCount !== 0}"
                 :title="keyword.label"
                 @click="toggleKeyword(keyword, filterSection.title)">
              {{ keyword.label }}
            </div>
            <div class="clickable-keyword clickable-keyword-more"
                 v-if="filterSection.clickableKeywords.length > 10"
                 @click="filterSection.open = !filterSection.open; componentKey++">
              {{ filterSection.open ? 'show less' : 'show more' }}
            </div>
            <div class="clickable-keyword clickable-keyword-invisible"></div>
            <div class="clickable-keyword clickable-keyword-invisible"></div>
            <div class="clickable-keyword clickable-keyword-invisible"></div>
            <div class="clickable-keyword clickable-keyword-invisible"></div>
          </div>
        </div>
      </div>
      <div class="filters-panel__submit-section">
        <ds-button label="SAVE YOUR TREND MONITORING CONFIGURATION" variant="primary"
                   :disabled="!hasSufficientParamsToStartSearch || loadCount !== 0"
                   @click="save"/>
        <icon name="spinner" style="height: 100%;" v-if="savingConceptSearch"/>
        <icon name="check" style="height: 100%;" v-if="showSaveSuccessfulIcon"/>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import Checkbox from '../../Form/Checkbox.vue'
  import KeywordsWithSuggestionsDropdown
    from '../../Dropdown/KeywordsWithSuggestionsDropdown.vue'
  import {
    deleteConceptSearch,
    exploreLiveSearch,
    fetchConceptSearch,
    getRelevantNewsTimeline,
    publishConceptSearch,
    unpublishConceptSearch,
    updateConceptSearchAndExplore,
  } from '../../../api/exploration.js'
  import PublishButton from '../../PublishButton/PublishButton.vue'
  import InstantMonitoringCard from './InstantMonitoringCard.vue'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui.js'
  import MODAL_IDS from '../../../constants/modal-ids.js'

  import moment from 'moment'
  import Loading from '../ConceptMap/Loading.vue'
  import _map from 'lodash/map.js'
  import _groupBy from 'lodash/groupBy.js'
  import { trackHeapEvent } from '../../../util/analytics.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'InstantMonitoring',
    data () {
      return {
        componentKey: 0,
        autoRank: true,
        selectedKeywords: [],
        selectedLocations: [],
        selectedSourceTags: [],
        selectedActors: [],
        conceptSearch: {},
        savingConceptSearch: false,
        showSaveSuccessfulIcon: false,
        articles: [],
        hasSearchedAtLeastOnce: false,
        loadCount: 0,
        lastLoadIndex: 0,
        defaultArticles: [],
        filterSections: [],
        removedArticles: [],
        defaultTags: [],
        defaultSources: [],
        defaultLocations: [],
        defaultActors: [],
        relevantArticles: [],
      }
    },
    computed: {
      lastRun () {
        return moment(this.conceptSearch.explored_at).format('DD\u2011MM\u2011YYYY\xa0/\xa0HH:mm')
      },
      published: {
        get () {
          return this.conceptSearch.published
        },
        set (published) {
          this.conceptSearch.published = published

          if (published) {
            publishConceptSearch(this.conceptSearch.id)
          } else {
            unpublishConceptSearch(this.conceptSearch.id)
          }
        },
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      paramsForLiveSearch () {
        return {
          search_topics: this.selectedKeywords,
          geography_context: this.selectedLocations,
          actors_context: this.selectedActors,
          news_sources: this.selectedSourceTags.map(newsSource => newsSource.value),
        }
      },
      showInsufficientParamsMessage () {
        return !this.hasSufficientParamsToStartSearch
      },
      hasSufficientParamsToStartSearch () {
        return !!this.paramsForLiveSearch.search_topics.length
      },
    },
    methods: {
      trackHeapEvent,
      getRelevantFacets () {
        const keywords = []
        const actors = []
        const locations = []

        for (const article of this.relevantArticles) {
          for (const tag of article.tags) {
            if (!tag.uri) {
              continue
            }

            if (tag.type === 'org') {
              if (!this.selectedActors.find(selectedActor => !selectedActor.isSelectedThroughRelevance && selectedActor.value === tag.uri)
              ) {
                actors.push({
                  ...tag,
                  value: tag.uri,
                  type: 'top_actors',
                  actorUri: tag.uri,
                  isSelectedThroughRelevance: true,
                })
              }
            } else if (tag.type === 'loc') {
              if (!this.selectedLocations.find(selectedLocation => !selectedLocation.isSelectedThroughRelevance && selectedLocation.value === tag.uri)) {
                locations.push({
                  ...tag,
                  value: tag.uri,
                  wikiUri: tag.uri,
                  type: 'top_geography',
                  isSelectedThroughRelevance: true,
                })
              }
            } else if (!this.selectedKeywords.find(selectedKeyword => !selectedKeyword.isSelectedThroughRelevance && selectedKeyword.value === tag.uri)
            ) {
              keywords.push({ ...tag, value: tag.uri, isSelectedThroughRelevance: true })
            }
          }
        }

        return {
          keywords: this.getTopFiveFacets(keywords),
          locations: this.getTopFiveFacets(locations),
          actors: this.getTopFiveFacets(actors),
        }
      },
      getTopFiveFacets (facetList) {
        return _map(_groupBy(facetList, 'value'))
          .sort((a, b) => b.length - a.length)
          .slice(0, 5)
          .map(sortedFacetList => sortedFacetList[0])
      },
      markArticleRelevant ({ article }) {
        trackHeapEvent('instantMonitoring.markArticleRelevant')
        if (!this.relevantArticles.find(relevantArticle => this.getUrlFromArticle(relevantArticle) === this.getUrlFromArticle(article))) {
          this.relevantArticles.push(article)
        }
        const relevantFacets = this.getRelevantFacets()
        this.selectedActors = [...relevantFacets.actors, ...this.selectedActors.filter(facet => !facet.isSelectedThroughRelevance)]
        this.selectedLocations = [...relevantFacets.locations, ...this.selectedLocations.filter(facet => !facet.isSelectedThroughRelevance)]
        this.selectedKeywords = [...relevantFacets.keywords, ...this.selectedKeywords.filter(facet => !facet.isSelectedThroughRelevance)]
      },
      getUrlFromArticle (article) {
        if (!article || !article.meta || !article.meta.length) {
          return ''
        }
        const result = article.meta.find(meta => meta.key === 'link')
        return result ? result.value : ''
      },
      async loadDefaultState () {
        await getRelevantNewsTimeline({ limit: 100, analyse: true }).then(results => {
          this.defaultArticles = results.results

          if (results.facets) {
            if (results.facets.significant_tag_count) {
              this.defaultTags = results.facets.significant_tag_count
            } else if (results.facets.tag_count) {
              this.defaultTags = results.facets.tag_count
            }
            if (results.facets.top_sources) {
              this.defaultSources = results.facets.top_sources
            }
            if (results.facets.top_geography) {
              this.defaultLocations = results.facets.top_geography
            }
          }
        })
      },
      onChangeAutoRank () {
        if (this.autoRank) {
          this.getLiveResults()
        }
      },
      removeArticle (article) {
        trackHeapEvent('instantMonitoring.removeArticle')
        this.removedArticles.push(article.title)
      },
      toggleKeyword (keyword, keywordType) {
        if (this.loadCount !== 0) {
          return
        }

        const keywordCollection = this.getKeywordsetForType(keyword.type)
        const selectedIndex = keywordCollection.findIndex(selectedKeyword => selectedKeyword.value === keyword.value)
        if (selectedIndex >= 0) {
          trackHeapEvent('instantMonitoring.removeKeyword', { keyword, keywordType })
          keywordCollection.splice(selectedIndex, 1)
        } else {
          trackHeapEvent('instantMonitoring.clickAddKeyword', { keyword, keywordType })
          keywordCollection.push(keyword)
        }
        this.getLiveResults()
      },
      getKeywordsetForType (type) {
        switch (type) {
          case 'top_geography':
            return this.selectedLocations
          case 'top_sources':
            return this.selectedSourceTags
          case 'top_actors':
            return this.selectedActors
          default:
            return this.selectedKeywords
        }
      },
      keywordIsSelected (keyword) {
        const keywordCollection = this.getKeywordsetForType(keyword.type)
        return !!keywordCollection.find(selectedKeyword => selectedKeyword.value === keyword.value)
      },
      confirmCreateInstantMonitoring (conceptSearchId) {
        this.savingConceptSearch = false
        trackHeapEvent('instantMonitoring.initial-save-successful')
        this.$router.push('/dashboards/instant-monitoring/' + conceptSearchId)
        this.loadConceptSearch(conceptSearchId)
      },
      save () {
        if (!this.hasSufficientParamsToStartSearch) {
          trackHeapEvent('instantMonitoring.save-unsuccessful')
          return
        }

        this.savingConceptSearch = true
        if (this.conceptSearch.id && this.conceptSearch.title) {
          updateConceptSearchAndExplore(this.conceptSearch.id, {
            search_topics: this.paramsForLiveSearch.search_topics,
            title: this.conceptSearch.title,
            geography_context: this.paramsForLiveSearch.geography_context,
            news_sources: this.paramsForLiveSearch.news_sources,
            actors_context: this.paramsForLiveSearch.actors_context,
          }).finally(() => {
            trackHeapEvent('instantMonitoring.update-successful')
            this.savingConceptSearch = false
            this.showSaveSuccessfulIcon = true
            setInterval(() => {
              this.showSaveSuccessfulIcon = false
            }, 3000)
          })
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, { ...this.paramsForLiveSearch })
          this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_INSTANT_MONITORING)
        }
      },
      isPublished (conceptSearch) {
        return conceptSearch.published || false
      },
      setPublished (conceptSearch, published) {
        this.conceptSearch.published = published

        if (published) {
          publishConceptSearch(conceptSearch.id)
        } else {
          unpublishConceptSearch(conceptSearch.id)
        }
      },
      remove () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          body: 'Are you sure you want to delete this search?',
          resource: this.conceptSearch,
          modalContextType: 'concept-search',
        })

        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
      },
      confirmRemove (context) {
        if (context.resource && context.resource.id == this.conceptSearch.id) {
          this.deleting = true
          deleteConceptSearch(this.conceptSearch.id)
            .then(response => {
              // Refresh the concept searches
              this.$bus.emit('refreshConceptSearches')
              this.deleting = false
              this.$router.push('/')
            })
            .catch(error => {
              this.deleting = false
              console.error(error)
            })
        }
      },
      resetToDefaultState () {
        this.filterSections = []
        this.articles = this.defaultArticles

        if (this.defaultTags && this.defaultTags.length) {
          this.applyKeywordFacets(this.defaultTags)
        }

        // we don't show actors in the initial view, because they don't have a wikipedia uri
        if (this.$route.params.id) {
          this.applyActorFacets(this.defaultActors)
        }

        if (this.defaultLocations && this.defaultLocations.length) {
          this.applyGeographyFacets(this.defaultLocations)
        }

        if (this.defaultSources && this.defaultSources.length) {
          this.applySourceFacets(this.defaultSources)
        }
      },
      getLiveResults () {
        const params = this.paramsForLiveSearch

        if (!this.hasSufficientParamsToStartSearch) {
          return
        }

        this.loadCount++
        // make sure the dataset we're loading is the last one that was requested
        this.lastLoadIndex++
        const currentLoadIndex = this.lastLoadIndex

        exploreLiveSearch(params).then(result => {
          if (this.lastLoadIndex !== currentLoadIndex) {
            return
          }

          if (!result || !result.results || !result.facets) {
            this.resetToDefaultState()
            return
          }

          this.articles = result.results
          this.hasSearchedAtLeastOnce = true
          this.applyFacetsFromLiveSearch(result.facets)
        }).catch(e => {
          this.resetToDefaultState()
          console.log(e)
        }).finally(() => {
          this.loadCount--
        })
      },
      applyFacetsFromLiveSearch (facets) {
        this.filterSections = []

        if ((facets.tag_count && facets.tag_count.length) || this.selectedLocations.length) {
          this.applyKeywordFacets(facets.tag_count || [])
        }

        if ((facets.top_actors && facets.top_actors.length) || this.selectedActors.length) {
          this.applyActorFacets(facets.top_actors || [])
        }

        if ((facets.top_geography && facets.top_geography.length) || this.selectedLocations.length) {
          this.applyGeographyFacets(facets.top_geography || [])
        }

        if ((facets.top_sources && facets.top_sources.length) || this.selectedSourceTags.length) {
          this.applySourceFacets(facets.top_sources || [])
        }
      },
      applyKeywordFacets (keywordFacets) {
        this.filterSections.push({
          title: 'Significant KEYWORDS',
          // subTitle: 'Suggested keywords',
          type: 'tag_count',
          clickableKeywords: [...this.selectedKeywords, ...keywordFacets.map(keyword => {
            return { label: keyword.label, value: keyword.uri }
          }).filter(keyword => {
            return !this.keywordIsSelected(keyword)
          })],
        })
      },
      applySourceFacets (sourceFacets) {
        this.filterSections.push({
          title: 'Significant SOURCES',
          // subTitle: 'Suggested sources',
          type: 'top_sources',
          clickableKeywords: [...this.selectedSourceTags, ...sourceFacets.map(source => {
            return {
              label: source.display_name,
              sourceUrl: source.url,
              value: source.url,
              type: 'top_sources',
            }
          }).filter(keyword => {
            return !this.keywordIsSelected(keyword)
          })],
        })
      },
      applyGeographyFacets (geoFacets) {
        this.filterSections.push({
          title: 'Significant LOCATIONS',
          // subTitle: 'Suggested locations',
          type: 'top_geography',
          clickableKeywords: [...this.selectedLocations, ...geoFacets.map(keyword => {
            return {
              label: keyword.label,
              wikiUri: keyword.uri,
              type: 'top_geography',
              value: keyword.uri,
            }
          }).filter(keyword => {
            return !this.keywordIsSelected(keyword)
          })],
        })
      },
      applyActorFacets (actorFacets) {
        this.filterSections.push({
          title: 'Significant ACTORS',
          // subTitle: 'Suggested actors',
          type: 'top_actors',
          clickableKeywords: [...this.selectedActors, ...actorFacets.map(keyword => {
            return {
              label: keyword.label,
              actorUri: keyword.uri,
              type: 'top_actors',
              value: keyword.uri,
            }
          }).filter(keyword => {
            return !this.keywordIsSelected(keyword)
          })],
        })
      },
      loadConceptSearch (conceptSearchId) {
        this.loadCount++
        fetchConceptSearch(conceptSearchId).then((result) => {
          this.conceptSearch = result
          this.selectedKeywords = this.conceptSearch.search_topics.filter(keyword => keyword.value.startsWith('http'))
          this.selectedLocations = this.conceptSearch.geography_context.map(keyword => {
            return {
              label: keyword.label,
              wikiUri: keyword.wikiUri,
              type: 'top_geography',
              value: keyword.wikiUri,
            }
          })
        }).finally(() => {
          this.loadCount--
        })
      },
    },
    async mounted () {
      if (this.$route.params.id) {
        this.loadConceptSearch(this.$route.params.id)
        this.loadDefaultState()
      } else {
        await this.loadDefaultState()
        this.resetToDefaultState()
      }
      this.$bus.on('conceptSearchDeleteConfirmation', this.confirmRemove)
      this.$bus.on('confirmCreateInstantMonitoring', this.confirmCreateInstantMonitoring)
    },
    watch: {
      selectedKeywords () {
        if (this.autoRank) {
          this.getLiveResults()
        }
      },
    },
    beforeUnmount () {
      this.$bus.off('conceptSearchDeleteConfirmation')
      this.$bus.off('confirmCreateInstantMonitoring')
    },
    components: {
      Checkbox,
      KeywordsWithSuggestionsDropdown,
      PublishButton,
      InstantMonitoringCard,
      Loading,
    },
  })
</script>

<style lang="scss" scoped>
  @import "../../../../scss/variables";

  .instant-monitoring {
    display: flex;
    height: 100%;

    .instant-monitoring__results-panel {
      display: flex;
      flex-direction: column;
      border-right: 1px solid $color-borders;

      .results-panel__search-section {
        display: flex;
        flex-direction: column;
        padding: 20px;

        .results-panel__top-bar {
          display: flex;
          margin-bottom: 10px;

          .title {
            font-size: 24px;
            flex-grow: 1;
            text-transform: uppercase;
            line-height: 1.2em;
            font-weight: 300;
            letter-spacing: .06em;
          }

          .title-input {
            flex-grow: 1;
            width: auto;
            margin-right: 10px;
          }

          .results-panel__actions {
            display: flex;

            label {
              line-height: 18px;
              white-space: nowrap;
            }
          }
        }

        .results-section__title {
          font-weight: bold;
          font-size: 14px;
        }

      }

      .results-panel__results-section {
        display: flex;
        overflow-y: auto;
        padding: 0 20px 20px 20px;
        width: 100%;

        .results-section__monitoring-cards {
          display: flex;
          justify-content: space-between;
          width: 100%;
          flex-wrap: wrap;
        }
      }
    }

    .instant-monitoring__filters-panel {
      display: flex;
      flex-direction: column;

      .filters-panel__actions-section {
        display: flex;
        padding: 20px 20px 0 20px;

        .actions-section__last-run {
          line-height: 25px;
          color: $color-text-grey;
        }

        .actions-section__button-container {
          margin-left: auto;
          white-space: nowrap;
        }
      }

      .filters-panel__description {
        padding: 20px;
        color: $color-text-grey;
        border-bottom: 1px solid $color-borders;
      }

      .filters-panel__filters-section {
        display: flex;
        flex-direction: column;
        padding: 20px;
        overflow-y: auto;

        .filters-panel__filters-subsection {
          margin-bottom: 20px;

          .filters-title {
            color: $color-text-grey;
          }

          .filters-subtitle {
            color: $color-text-grey;
            margin-bottom: 10px;
          }

          &:last-child {
            margin-bottom: -10px;
          }
        }

        .filters-section__clickable-keywords {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          width: 100%;

          .clickable-keyword {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            border: 2px dashed var(--primary-lighter);
            color: $color-text-grey-light;
            margin-bottom: 10px;
            cursor: pointer;
            text-align: center;
            padding: 3px;

            &.selected {
              border: 2px solid $color-alternative-four;
              color: $color-text-grey;
              background: $color-background-light-grey;

              &.disabled {
              }
            }

            &.disabled {
              cursor: not-allowed;
              opacity: 0.5;
            }
          }

          .clickable-keyword-more {
            border: none;
          }

          .clickable-keyword-invisible {
            border: none;
            height: 0;
            padding: 0;
            margin: 0;
          }
        }
      }

      .filters-panel__submit-section {
        display: flex;
        padding: 20px;
        border-top: 1px solid $color-borders;
        margin-top: auto;

        .button {
          width: 100%;
          text-align: center;
          overflow: hidden;
          line-height: 0;
        }

        :deep(.button__label) {
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          line-height: 32px;
          width: 100%;
          font-weight: 700;
        }
      }
    }

    $navigationWidth: 40px;
    @media (min-width: 1500px) {
      $leftWidth: 5*200 + 6*20px;
      .instant-monitoring__results-panel {
        min-width: $leftWidth;
        max-width: $leftWidth;
      }

      .instant-monitoring__filters-panel {
        width: calc(100vw - #{$leftWidth} - #{$navigationWidth});
      }
      .clickable-keyword {
        width: calc(25% - 7.5px);
      }
    }
    @media (max-width: 1500px) {
      $leftWidth: 4*200 + 5*20px;
      .instant-monitoring__results-panel {
        min-width: $leftWidth;
        max-width: $leftWidth;
      }

      .instant-monitoring__filters-panel {
        width: calc(100vw - #{$leftWidth} - #{$navigationWidth});
      }
      .clickable-keyword {
        width: calc(33.33% - 6.66px);
      }
    }
    @media (max-width: $screen-xl) {
      $leftWidth: 3*200 + 4*20px;
      .instant-monitoring__results-panel {
        min-width: $leftWidth;
        max-width: $leftWidth;
      }

      .instant-monitoring__filters-panel {
        width: calc(100vw - #{$leftWidth} - #{$navigationWidth});
      }
      .clickable-keyword {
        width: calc(50% - 10px);
      }
    }
    @media (max-width: $screen-lg) {
      $leftWidth: 2*200 + 3*20px;
      .instant-monitoring__results-panel {
        min-width: $leftWidth;
        max-width: $leftWidth;
      }

      .instant-monitoring__filters-panel {
        width: calc(100vw - #{$leftWidth} - #{$navigationWidth});
      }
      .clickable-keyword {
        width: calc(50% - 10px);
      }
    }
    @media (max-width: $screen-md) {
      $leftWidth: 2*200 + 3*20px;
      .instant-monitoring__results-panel {
        min-width: $leftWidth;
        max-width: $leftWidth;
      }

      .instant-monitoring__filters-panel {
        width: calc(100vw - #{$leftWidth});
      }
      .clickable-keyword {
        width: 100%;
      }
    }
    @media (max-width: $screen-sm) {
      $leftWidth: 200 + 2*20px;
      .instant-monitoring__results-panel {
        min-width: $leftWidth;
        max-width: $leftWidth;
      }

      .instant-monitoring__filters-panel {
        width: calc(100vw - #{$leftWidth});
      }
    }
  }

  .filler-instant-monitoring-card {
    width: 200px;
  }
</style>
