<template>
  <div class="side-panel__portfolio-permissions-items">
    <div v-if="loadingMatchingActors">
      <icon name="spinner"/>
      Loading
    </div>

    <div
      v-for="(actor, index) in matchingActors"
      :key="'challenge-actor-recommendation__' + index"
    >
      <ChallengeActorCard
        :actor="actor"
      />
    </div>

    <div v-if="!matchingActors.length && !loadingMatchingActors">
      {{ $t('actor_recommendations_empty_state_message') }}
    </div>
  </div>
</template>

<script lang="ts">

  import { defineComponent } from '~/vue'
  import { convertToFilterObject, stringifyProperties } from '@/store/modules/filters'
  import { getMatchingActors } from '@/Domain/Challenge/Api/challenges'
  import TranslationsMixin from '@/util/TranslationsMixin'
  import { getDefaultFallbackImageUrl } from '@/util/helpers'
  import { actorLogo } from '@/util/actor'
  import { textLimit } from '@/util/string'
  import Avatar from '@/components/Avatar/Avatar.vue'
  import ChallengeActorCard from '@/Domain/Challenge/Components/ChallengeActorCard.vue'
  import { ChallengeActor } from '@/Domain/Challenge/Types/challenge-types'
  import { transformChallengeActorRecommendationsToChallengeActorArray } from '@/Domain/Challenge/Api/challenge-api-transformers'

  export default defineComponent({
    components: { ChallengeActorCard, Avatar },
    props: {
      filters: {
        type: Array,
        required: true
      }
    },
    mixins: [TranslationsMixin],
    data() {
      return {
        matchingActors: [],
        loadingMatchingActors: false,
        errors: {},
        actorRecommendationFilters: [],
        componentKey: 1,
      }
    },
    computed: {
      challengeId (): number {
        return Number(this.$route.params.id)
      }
    },
    mounted() {
      this.getMatchingActors()
      this.$bus.on('customFiltersModal.update', this.updateFilters)
    },
    beforeUnmount() {
      this.$bus.off('customFiltersModal.update', this.updateFilters)
    },
    watch: {
      actorRecommendationFilters: {
        deep: true,
        handler() {
          this.getMatchingActors()
        }
      }
    },
    methods: {
      textLimit,
      actorLogo,
      getDefaultFallbackImageUrl,
      getMatchingActors() {
        const stringifiedFilterObject = stringifyProperties(convertToFilterObject(this.actorRecommendationFilters))
        this.loadingMatchingActors = true

        getMatchingActors(this.challengeId, stringifiedFilterObject)
          .then((result) => {
            this.matchingActors = transformChallengeActorRecommendationsToChallengeActorArray(result)
            this.tabCounters = [result.length]
          })
          .finally(() => {
            this.loadingMatchingActors = false
          })
      },
    }
  })
</script>

<style scoped lang="scss">

</style>
