<template>
  <modal :id="modalId"
         ref="modal"
         :title="$t('communities_manage_members')"
         @close="close"
         is-simplified-modal
  >
    <!-- Modal header tabs -->
    <template v-slot:body>
      <div>
        <div class="community-manage-members__title-container"
             v-html="community.name"
        >
        </div>

        <div class="community-manage-members__content-container">
          <autocomplete-dropdown
            is-simplified
            :placeholder="$t('communities_search_for_users')"
            :suggestions="displayedUserSuggestions"
            :allow-clear="true"
            :do-search="autocompleteSearchTerm"
            v-model="newMembers"
            :multiple="true"
          />
        </div>
      </div>
    </template>

    <template v-slot:footer>
      <div style="width: 100%;">
        <ds-button
          variant="primary"
          @click="submit()"
          :label="$t('communities_update_members')"
          size="extra-small"
          :disabled="saving"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import DsTextarea from '../Form/DsTextarea.vue'
  import ImageInput from '../Form/ImageInput.vue'
  import AutocompleteDropdown from '../Dropdown/AutocompleteDropdown.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'

  import { Communities, updateCommunityMembers } from '../../api/communities'
  import { searchUser } from '../../api/config'

  export default {
    name: 'ManageCommunityMembersModal',
    data () {
      return {
        modalId: MODAL_IDS.MANAGE_COMMUNITY_MEMBERS,
        community: {
          name: '',
          description: '<div></div>',
          image: '',
          members: []
        },
        newMembers: [],
        saving: false,
        searchQuery: '',
        userSuggestions: []
      }
    },
    computed: {
      modalContext () {
        return this.$store.state.ui.modalContext
      },
      displayedUserSuggestions () {
        return this.userSuggestions
      }
    },
    methods: {
      autocompleteSearchTerm (query) {
        return searchUser({
          query: query,
          role: 'member'
        })
          .then(suggestions => {
            const options = []
            suggestions = this.removeDuplicates(suggestions)

            suggestions
              .forEach(function (user) {
                options.push({
                  label: user.name + ' (' + user.email + ')',
                  value: user.id
                })
              })

            return options
          })
          .catch(err => {
            console.log(err)
          })
      },
      removeDuplicates (suggestions) {
        return suggestions
      },
      submit () {
        this.saving = true

        const data = {
          members: this.newMembers.map(member => {
            return { id: member.value }
          })
        }

        updateCommunityMembers({ communityId: this.community.id, data })
          .then(response => {
            this.saving = false
            this.close()
          })
          .catch(error => {
            console.log(error)
          })
      },
      close () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.MANAGE_COMMUNITY_MEMBERS)
        this.$emit('close')
      }
    },
    async mounted () {
      try {
        this.community = await Communities.find(this.modalContext.communityId)
        this.newMembers = this.community.members.map(user => {
          return {
            label: user.name + ' (' + user.email + ')',
            value: user.id
          }
        })
      } catch (error) {
        console.log(error)
      }
    },
    components: {
      AutocompleteDropdown,
      DsTextarea,
      ImageInput,
      Modal
    }
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/_variables.scss";

  .community-manage-members__title-container {
    background: var(--primary-extra-lightest);
    padding: 20px;

    :deep(input) {
      border: 0;
      border-radius: $default-border-radius-narrow;

      &::placeholder {
        color: var(--primary);
      }
    }
  }

  .community-manage-members__content-container {
    padding: 20px 20px 17px 20px;
    min-height: 300px;

    :deep(input) {
      border: 1;
      border-radius: $default-border-radius-narrow;
      border-color: var(--primary-extra-lightest) !important;

      &::placeholder {
        color: var(--primary);
      }
    }
  }

  .community-manage-members__members-container {
    display: flex;
    flex-direction: column;
  }

  .modal__footer {
    width: 100%;

    .button {
      width: 100%;
      border-radius: $default-border-radius-narrow;
    }
  }

  .d-flex {
    display: flex;
  }

  .form-group {
    margin-bottom: 0px;
  }
</style>
