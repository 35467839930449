<template>
  <NewCard title="Data consumption">
    <p class="text-muted">The below graph shows the number of actor credits consumed for keeping your ecosystem up to date. Your ecosystem will consume one actor credit for each automatically updated actor profile in the course of a month.</p>

    <div v-if="histogram" class="chart-container">
      <div class="chart-main">
        <SimpleLineChart type="bar" :data="histogramChartData" />
      </div>

      <div class="chart-aside">
        <ChartLegend :data="histogramChartData" />
        <RefillLogs :logs="logs" />
      </div>
    </div>
  </NewCard>
</template>

<script>
import moment from 'moment';
import ChartLegend from '../Heartbeat/ChartLegend.vue';
import RefillLogs from './RefillLogs.vue';
import NewCard from '../../../components/NewCard/NewCard.vue';
import SimpleLineChart from '../../../components/Chart/SimpleLineChart.vue';

const cumulative = arr => {
  const result = [];
  arr.reduce((a, b, i) => result[i] = a + b, 0);
  return result;
};

export default {
  props: {
    histogram: Object,
    logs: Array,
  },
  components: {
    ChartLegend,
    NewCard,
    RefillLogs,
    SimpleLineChart
  },
  computed: {
    histogramChartData() {
      return {
        labels: Object.keys(this.histogram).map(label => moment(label).format('DD/MM/YY')),
        datasets: [{
          borderColor: ['#1fdcb8', '#3c8088'],
          data: cumulative(Object.values(this.histogram)),
          fill: false,
          icon: 'line-gradient',
          label: 'All consumed actor credits',
          pointBackgroundColor: '#1fdcb8',
          pointBorderColor: '#fff',
          pointBorderWidth: 3,
          pointHoverBorderColor: '#fff',
          pointHoverBorderWidth: 3,
          pointHoverRadius: 8,
          pointRadius: 5,
          type: 'line',
        }, {
          backgroundColor: '#d8d8d8',
          hoverBackgroundColor: '#1fdcb8',
          icon: 'bar-chart',
          label: 'Daily consumed actor credits',
          data: Object.values(this.histogram),
          type: 'bar'
        }],
      };
    },
  },
}
</script>
