<template>
  <NewCard title="Submission status">
    <div class="horizontal-cards" v-if="template && reports">
      <EcosystemInfoCard title="Started reports">
        <span class="text-lg">
          {{ reports.total_started }}
        </span>
      </EcosystemInfoCard>
      <EcosystemInfoCard title="Submitted reports">
        <span class="text-lg">
          {{ reports.total_submitted }}
        </span>
      </EcosystemInfoCard>
      <EcosystemInfoCard title="Total actors engaged">
        <span class="text-lg">
          {{ reports.total_actors }}
        </span>
      </EcosystemInfoCard>
    </div>

    <div v-if="!template">
      <!-- Loading... -->
      No reporting template selected...
    </div>
  </NewCard>
</template>

<script>
import EcosystemInfoCard from './../../Settings/Heartbeat/EcosystemInfoCard.vue'
import NewCard from '../../../components/NewCard/NewCard.vue'

export default {
  props: {
    reports: Object,
    template: String,
  },
  components: {
    EcosystemInfoCard,
    NewCard
  }
}
</script>

<style lang="scss" scoped>
@import "../../../../scss/variables";

.horizontal-cards {
  display: flex;

  > * {
    flex: 1;
  }

  > * + * {
    margin-left: 20px;
  }
}
.heartbeat__new-users {
  color: $color-primary;
}
</style>
