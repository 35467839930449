<template>
  <div :class="classes">
    <div class="ranked-list__header" :style="{ backgroundColor: color }">{{title}}</div>
    <ol class="ranked-list__items">
      <template v-for="(item, index) in items">
        <li v-if="!item.url" class="ranked-list__item">
          <div class="ranked-list__item__position">{{index + 1}}</div>
          <div class="ranked-list__item__label">{{item.name}}</div>
        </li>
        <li v-if="item.url" class="ranked-list__item ranked-list__item--is-link" @click="setParameter(item)">
            <div class="ranked-list__item__position">{{index + 1}}</div>
            <div class="ranked-list__item__label">{{item.name}}</div>
        </li>
      </template>
    </ol>
  </div>
</template>

<script>
  import UiMixin from '../../util/UiMixin';

  import { MUTATION_TYPES as BUZZ_MUTATION_TYPES } from '../../store/modules/buzz';
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui';
  import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../../store/modules/actors';

  import { fetchActor } from '../../api/actors.js'

  export default {
    name: 'RankedList',
    props: {
      items: Array,
      title: String,
      compact: {
        type: Boolean,
        default: false,
      },
      color: {
        type: String,
        default: '#303240',
      }
    },
    computed: {
      classes() {
        return [
          'ranked-list',
          this.compact && 'ranked-list--compact',
        ]
      }
    },
    methods: {
      setParameter (item) {
        if (item.id) {
          this.$store.commit(BUZZ_MUTATION_TYPES.UPDATE_BUZZ_FILTER, { name: item.name, facet: 'topic', value: item.id });
        }

        // If the link is a link to an actor, take into account the logic to either display the side panel or go to the full profile
        if (item.actorId) {
          if (this.ui.isMobile) {
            this.$router.push(`/actors/${item.actorId}`);
          } else {
            this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component:"scores", metaData: item.actorId});
          }
        } else {
          this.$router.push(item.url)
        }
      }
    },
    mixins: [UiMixin]
  }
</script>

<style lang="scss">
  @import "../../../scss/variables";

  .ranked-list {
    display: flex;
    flex-direction: column;
    padding: 0.5rem;
    background-color: $color-background-lightest-grey;
    font-size: 0.75rem;

    @media (max-width: $screen-md) {
      display: block;
      font-size: 0.625rem;
      margin-bottom: 1rem;

      .ranked-list__header,
      .ranked-list__item__position,
      .ranked-list__item__label {
        padding: 0.5rem;
      }

      .ranked-list__items {
        display: flex;

        .ranked-list__item {
          flex: 1;
          margin-bottom: 0;
          margin-right: 0.5rem;

          &:nth-child(3) {
            margin-right: 0;
          }

          &:nth-child(n+4) {
            display: none;
          }
        }
      }
    }
  }

  .ranked-list--compact {
    padding: 0.5rem;

    .ranked-list__header {
      margin-bottom: 0.5rem;
      padding: 0.4rem;
    }

    .ranked-list__item {
      margin-bottom: 0.5rem;
    }

    .ranked-list__item__position,
    .ranked-list__item__label {
      padding: 0.5rem;
    }
  }

  .ranked-list__header {
    margin-bottom: 0.5rem;
    padding: 1rem 0.5rem;
    color: #fff;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    text-align: center;
    font-weight: 500;
  }

  .ranked-list__items {
    list-style: none;
  }

  .ranked-list__item {
    display: flex;
    margin-bottom: 0.5rem;
    background-color: #fff;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .ranked-list__item--is-link {
    background-color: #fff;
    cursor: pointer;
    a {
      display: flex;
      width: 100%;
      text-decoration: none;
    }

    .ranked-list__item__position {
      color: initial;
      text-decoration: none;
    }

    .ranked-list__item__label {
      color: inherit;
    }
  }
  .ranked-list__item--is-link:hover {
    background-color: $color-background-light-grey;
  }

  .ranked-list__item__position,
  .ranked-list__item__label {
    display: flex;
    align-items: center;
    padding: 1rem 0.5rem;
  }

  .ranked-list__item__position {
    border-right: 1px solid rgba($color-borders, 0.7);
  }

  .ranked-list__item__label {
    flex: 1;
    font-size: 14px;
  }
</style>
