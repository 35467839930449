import { cancelIfLate, createJSONRequest, createResource } from './create-request'
import querify from '../util/querify'

// Lexicons resource
export const Dictionary = createResource('/api/dictionaries')

export const fetchDefaultDictionaries = () => createJSONRequest({
  method: 'GET',
  url: '/api/default-dictionaries',
})

// News groups lexicons
export const NewsGroups = createResource('/api/news-groups')

export const ExploreConceptSearches = createResource('/api/explore-concept-searches')

// Crud Methods for Excluded News Sources
export const getExcludedNewsSources = () => createJSONRequest({
  method: 'GET',
  url: `/api/excluded-news-sources`,
})

export const storeExcludedNewsSource = (excludedNewsSourceUrl) => createJSONRequest({
  method: 'POST',
  url: `/api/excluded-news-sources`,
  data: excludedNewsSourceUrl,
})

export const deleteExcludedNewsSource = (excludedNewsSourceUrl) => createJSONRequest({
  method: 'DELETE',
  url: `/api/excluded-news-sources/${excludedNewsSourceUrl}`,
})

// Crud Methods for Excluded Tags
export const getExcludedTags = () => createJSONRequest({
  method: 'GET',
  url: `/api/excluded-tags`,
})

export const storeExcludedTag = (excludedTag) => createJSONRequest({
  method: 'POST',
  url: `/api/excluded-tags`,
  data: excludedTag,
})

export const deleteExcludedTag = (ecosystemTagId) => createJSONRequest({
  method: 'DELETE',
  url: `/api/excluded-tags/${ecosystemTagId}`,
})

export const fetchSourceSuggestions = cancelIfLate((query) => createJSONRequest({
  method: 'GET',
  url: `/api/explore-suggest-source?${querify(query)}`,
}))

/**
 * CRUD methods for concept searches
 */
export const storeConceptSearch = (parameters) => createJSONRequest({
  method: 'POST',
  url: `/api/explore-concept-searches`,
  data: parameters,
})

export const fetchConceptSearch = (id) => createJSONRequest({
  method: 'GET',
  url: `/api/explore-concept-searches/${id}`,
})

export const updateConceptSearch = (id, params) => createJSONRequest({
  method: 'PUT',
  url: `/api/explore-concept-searches/${id}`,
  data: params,
})

export const updateConceptSearchAndExplore = (id, params) => createJSONRequest({
  method: 'PUT',
  url: `/api/explore-concept-searches/${id}/update-content`,
  data: params,
})

export const deleteConceptSearch = (id) => createJSONRequest({
  method: 'DELETE',
  url: `/api/explore-concept-searches/${id}`,
})

export const followConceptSearch = (id) => createJSONRequest({
  method: 'POST',
  url: `/api/explore-concept-searches/${id}/follow`,
})

export const unfollowConceptSearch = (id) => createJSONRequest({
  method: 'POST',
  url: `/api/explore-concept-searches/${id}/unfollow`,
})

export const publishConceptSearch = (id) => createJSONRequest({
  method: 'POST',
  url: `/api/explore-concept-searches/${id}/publish`,
})

export const unpublishConceptSearch = (id) => createJSONRequest({
  method: 'POST',
  url: `/api/explore-concept-searches/${id}/unpublish`,
})

/**
 * UTILITY METHODS
 */
export const resetExplorationStatus = (id) => createJSONRequest({
  method: 'POST',
  url: `/api/explore-concept-searches/${id}/reset`,
})

export const explore = (id) => createJSONRequest({
  method: 'POST',
  url: `/api/explore-concept-searches/${id}/explore`,
})

export const fetchAllConceptSearches = (filters) => createJSONRequest({
  method: 'GET',
  url: `/api/explore-concept-searches?${querify(filters)}`,
})

export const fetchGeographySuggestions = cancelIfLate((filters) => createJSONRequest({
  method: 'GET',
  url: `/api/explore-suggest-geography?${querify(filters)}`,
}))

export const fetchConceptSuggestions = cancelIfLate((filters) => createJSONRequest({
  method: 'GET',
  url: `/api/explore-suggest-concept?${querify(filters)}`,
}))

export const updateActorRelevancy = (actorId, relevant, conceptSearchId) => createJSONRequest({
  method: 'PUT',
  url: `/api/relevancy/actors/${actorId}`,
  data: { relevant, conceptSearchId },
})

export const updateFileRelevancy = (fileId, relevant, conceptSearchId) => createJSONRequest({
  method: 'PUT',
  url: `/api/relevancy/files/${fileId}`,
  data: { 'relevant': relevant, conceptSearchId },
})

export const fetchActorsForSearch = (conceptSearchId, parameters = {}) => createJSONRequest({
  method: 'GET',
  url: `/api/explore-concept-searches/${conceptSearchId}/actors?${querify(parameters)}`,
})

export const buildExportActorsLink = (conceptSearchId, parameters) => {
  return `/api/explore-concept-searches/${conceptSearchId}/actors?${querify(parameters)}`
}

export const annotateText = (text) => createJSONRequest({
  method: 'POST',
  url: '/api/explore/annotate',
  data: { text },
})

export const validateTitle = (title, id) => createJSONRequest({
  method: 'GET',
  url: `/api/explore/validate-title?${querify({ title, id })}`,
})

/**
 * Find (similar) actors
 */
export const externalSearch = (query, filters) => createJSONRequest({
  url: `/api/external-search?query=${encodeURIComponent(query)}${filters ? '&' + querify(filters) : ''}`,
  method: 'GET',
})

export const searchSimilarActors = (actorId) => createJSONRequest({
  url: `/api/similar-actors?actorId=${actorId}`,
  method: 'GET',
})

export const searchSimilarActorsFiltered = (actorId, similarityType, similarProperties) => createJSONRequest({
  url: `/api/similar-actors?actorId=${actorId}&similar_properties=${similarProperties.join(',')}&similarity_type=${similarityType.join(',')}`,
  method: 'GET',
})

export const searchSimilarLocalActors = (actorId) => createJSONRequest({
  url: `/api/similar-actors?actorId=${actorId}&scope=local`,
  method: 'GET',
})

export const searchSimilarLocalActorsFiltered = (actorId, similarityType, similarProperties) => createJSONRequest({
  url: `/api/similar-actors?actorId=${actorId}&scope=local&similar_properties=${similarProperties.join(',')}&similarity_type=${similarityType.join(',')}`,
  method: 'GET',
})

/**
 * Find relevant news on a global level, not attached to a search
 */
export const getRelevantNewsTimeline = (filters) => createJSONRequest({
  url: `/api/news-overview?${querify(filters)}`,
  method: 'GET',
})

export const exploreLiveSearch = (params) => createJSONRequest({
  url: `/api/explore/live-search`,
  method: 'POST',
  data: params,
})

export const followSpottingArea = (id) => createJSONRequest({
  method: 'POST',
  url: `/api/spotting-areas/${id}/follow`,
})

export const unfollowSpottingArea = (id) => createJSONRequest({
  method: 'POST',
  url: `/api/spotting-areas/${id}/unfollow`,
})

export function validConceptSearchTitle (title) {
  let currentTitle = title
  let nextNumber = 2

  const handleValidationResult = function ({ valid }) {
    if (valid) {
      return currentTitle
    }

    currentTitle = `${title} (${nextNumber++})`

    return validateTitle(currentTitle).then(handleValidationResult)
  }

  return validateTitle(currentTitle).then(handleValidationResult)
}
