<template>
  <div class="ds-input email-tag-input ds-input--has-icon">
    <icon v-if="icon" :name="icon" />
    <div class="tag-input" :class="{ 'taggle--icon': icon, 'taggle--newline': newline }" ref="taggle"></div>
  </div>
</template>
<script>
  import _isEqual from 'lodash/isEqual'
  import Taggle from 'taggle'

  export default {
    props: {
      icon: {
        type: String
      },
      modelValue: {
        type: Array,
        default: () => []
      },
      placeholder: {
        type: String
      },
      newline: {
        type: Boolean,
        value: false
      }
    },
    emits: ['update:modelValue'],
    mounted () {
      this.taggle = new Taggle(this.$refs.taggle, {
        duplicateTagClass: 'taggle--duplicate',
        preserveCase: true,
        saveOnBlur: true,
        tags: this.modelValue || [],
        placeholder: this.placeholder,
        onTagAdd: (event, tag) => {
          // to validade the value of email
          var regexValiation = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          var _this = this

          if (event !== null) {
            var splitedValues = tag.split(/[\s,;]+/)

            _this.taggle.remove(tag)

            var validEmails = []

            splitedValues.forEach(function(val){
                if (regexValiation.test(val)) {
                  validEmails.push(val)
                  _this.taggle.add(val)
                }
            })
          }
          this.$emit('update:modelValue', this.taggle.getTags().values)
        },
        onTagRemove: () => {
          this.$emit('update:modelValue', this.taggle.getTags().values)
        }
      })
    },
    watch: {
      value (tags, old) {
        if (this.taggle && !_isEqual(tags, old)) {
          this.taggle.removeAll()
          this.taggle.add(tags)
        }
      }
    }
  }
</script>

