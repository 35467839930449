<template>
  <NewCard title="Updated data fields">
    <p class="text-muted">
      The visualisation below shows the most frequently updated data fields across all actors in your ecosystem. The larger the tile, the more updates this month. The darker the
      shade of green, the higher the completeness rate of the data field across all actors in your ecosystem. When you click on a data field, you get an overview of the most
      relevant data sources. Left clicking will zoom into the chart, right clicking will zoom out again.
    </p>
    <GChart
      type="TreeMap"
      :settings="{ packages: ['treemap'] }"
      :data="treeMapData"
      :options="chartOptions"
    />
  </NewCard>
</template>

<script>
  import { GChart } from 'vue-google-charts'
  import NewCard from '../../../components/NewCard/NewCard.vue'

  import { humanize } from '../../../constants/properties'

  export default {
    props: {
      data: {
        type: Object
      }
    },
    data () {
      return {
        rootNodeName: 'Data Fields',
        chartOptions: {
          height: 400,
          minColor: '#D0F8F0',
          midColor: '#55e4c9',
          maxColor: 'var(--primary-lighter)',
          showScale: true,
          generateTooltip: this.showStaticTooltip
        }
      }
    },
    methods: {
      showStaticTooltip (row, size, value) {
        if (row == 0) {
          return '<div></div>'
        }

        var info = this.treeMapData[row + 1]

        if (info[1] != this.rootNodeName) {
          return '<div></div>'
        }

        return '<div style="background: #eee; padding: 10px;">' + humanize(info[0]) + ': ' + info[2] + ' updates.</div>'
      }
    },
    computed: {
      treeMapData () {
        if (!this.data || this.data.length) {
          return []
        }

        // Transform the raw data into treeMap data
        var rootNodeName = this.rootNodeName
        var topLevelEntries = []
        var childLevelEntries = []

        var treeMapData = [
          ['Key', 'Parent', 'Sources'],
          [rootNodeName, null, 0]
        ]

        var data = this.data

        Object.keys(data).forEach(function (key) {
          var property = humanize(key)

          treeMapData.push([
            property, rootNodeName, data[key].count
          ])

          Object.keys(data[key].sources).forEach(function (source) {
            var count = data[key].sources[source]

            treeMapData.push([
              humanize(source) + ' provided ' + count + ' new values for ' + property + '.', property, count
            ])
          })
        })

        return treeMapData
      }
    },
    components: {
      GChart,
      NewCard
    }
  }
</script>

<style lang="scss" scoped>
</style>
