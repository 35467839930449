import { getRelativeTimestamp } from '../../util/MetricsMixin'

const EMPTY_CONCEPT_SEARCH = {
  id: null,
  reportType: null,
  title: '',
  description: '',
  scopeDescription: '',
  scopeDescriptionAnalysis: {},
  searchTopics: [],
  context: [], // The context to include in the search
  topics: [], // The chosen topics to analyse on
  exclude: [], // The chosen topics to exclude
  geographyContext: [], // The full geography context
  customScores: [],
  timespan: 'month',
  newsGroups: [],
  languages: []
}

const state = {
  step: 1,
  conceptSearch: EMPTY_CONCEPT_SEARCH,
}

export const MUTATION_TYPES = {
  UPDATE_STEP: 'CONCEPT_SEARCH_GUIDE/UPDATE_STEP',
  UPDATE_CONCEPT_SEARCH: 'CONCEPT_SEARCH_GUIDE/UPDATE_CONCEPT_SEARCH',
  RESET_CONCEPT_SEARCH: 'CONCEPT_SEARCH_GUIDE/RESET_CONCEPT_SEARCH',
}

// IMPORTANT: If one preset below sets a certain property, then all presets must set it (even if it's just back to
// a default empty value), because otherwise switching between types might not reset things completely (a complete reset
// is what a user would expect).

export const REPORT_TYPES = [
  {
    id: 'startup_radar',
    name: 'Startup scouting',
    description: '<br>• Scan all connected startup directories<br><br>• Discover startups that fit your challenges<br><br>• Find hidden gems in startup related news articles',
    presets: {
      topics: [],
      customScores: [
        {
          "metric": "founding_date",
          "weight": 50,
          "parameters": [
            {
              "value": {
                "from": getRelativeTimestamp(15, 'year'),
                "to": null
              },
              "weight": 100
            }
          ]
        },
        {
          "metric": "growth_stage",
          "weight": 50,
          "parameters": [
            {
              "value": 1,
              "weight": 100
            },
            {
              "value": 2,
              "weight": 100
            }
          ]
        },
        {
          "metric": "total_funding",
          "weight": 50,
          "parameters": [
            {
              "value": {
                "from": 0,
                "to": 1000000
              },
              "weight": 100
            },
            {
              "value": {
                "from": 1000000,
                "to": 10000000
              },
              "weight": 100
            }
          ]
        }
      ],
    },
  },
  {
    id: 'technology_radar',
    name: 'Trend Monitor',
    description: '<br>• Scan patents, specialised press as well as news papers<br><br>• Develop a technology landscape mapping emerging technology players<br><br>• Understand market trends and emerging technologies',
    presets: {
      topics: [],
      customScores: [],
    },
  },
  // {
  //   id: 'market_trend_watch',
  //   name: 'Market Trends',
  //   description: 'Search for market trends and industry trends.',
  //   presets: {
  //     topics: [],
  //     customScores: [],
  //   },
  // },
  // {
  //   id: 'competitor_radar',
  //   disabled: true,
  //   name: 'Competitor Radar',
  //   description: 'Identify Competitors for your specific service or solution.',
  //   presets: {
  //     topics: [],
  //     customScores: [],
  //   },
  // },
]

export const mutations = {
  [MUTATION_TYPES.UPDATE_STEP] (state, step) {
    state.step = step
  },

  [MUTATION_TYPES.UPDATE_CONCEPT_SEARCH] (state, conceptSearch) {
    state.conceptSearch = conceptSearch
  },

  [MUTATION_TYPES.RESET_CONCEPT_SEARCH] (state) {
    state.conceptSearch = EMPTY_CONCEPT_SEARCH
  },
}

export default {
  state,
  mutations,
}
