<template>
  <div :class="classes">
    <add-to-news-group-button
      v-if="(isOwner || isTeamMember) && $store.getters.hasAccessToExplorationOrSensing"
      :icon="icon"
      :source="source"
      :size="isKennisWest ? '14' : 'small'"
      :is-kennis-west="isKennisWest"
    />
    {{ source.label }}
  </div>
</template>

<script lang="ts">
  import NewsGroupMixin from '../../util/NewsGroupMixin.js'
  import AddToNewsGroupButton from '../AddToNewsGroupButton/AddToNewsGroupButton.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'NewsSource',
    props: {
      source: {
        type: Object,
      },
      icon: {
        type: String,
        default: 'tag',
      },
      isKennisWest: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      isOwner () {
        return this.$store.getters.isOwner
      },
      isTeamMember () {
        return this.$store.getters.isTeamMember
      },
      classes () {
        return {
          'tag': true,
          [`tag--dictionary`]: this.$store.getters.hasAccessToExplorationOrSensing && this.sourceExistsInAnyNewsGroup(this.source.value),
        }
      },
    },
    components: {
      AddToNewsGroupButton,
    },
    mixins: [NewsGroupMixin],
  })
</script>

<style lang="scss" scoped>
  .tag {
    display: inline-block;
    background: #EEEEEE;
    border: 1px solid #CECECE;
    border-radius: 1px;
    padding: 0.25rem;
    margin-right: 0.75rem;
    margin-bottom: 0.75rem;
    font-size: 0.75rem;

    &:last-child {
      margin-right: 0;
    }
  }

  .tag--small {
    padding: 0.1rem 0.2rem;
    margin-right: 0.35rem;
    font-size: 11px;
    height: 18px;
  }

  .tag--dictionary {
    font-weight: bold;
    border-color: var(--primary-light);
    background-color: var(--primary-lighter);
  }
</style>
