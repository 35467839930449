<template>
  <div class="ecosystem-info-card">
    <div v-if="title" class="title">
      <span
        class="label-indicator"
        v-if="labelColor"
        :style="{ backgroundColor: labelColor }">
      </span>
      {{title}}
    </div>
    <slot>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    labelColor: String,
    title: String
  }
}
</script>

<style lang="scss" scoped>
.ecosystem-info-card {
  background-color: #f8f8f8;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  padding: 10px;
  align-items: center;
}

.title {
  align-items: center;
  color: black;
  display: flex;
  margin-bottom: 10px;
  text-transform: uppercase;
  font-weight: bolder;
}

.label-indicator {
  border-radius: 8px;
  height: 8px;
  margin-right: 10px;
  width: 8px;
}

.text-lg {
  font-size: 16px;
  font-weight: 500;
}

.text-muted {
  color: #9B9B9B;
}
</style>
