export enum ChatRole {
  user = 'user',
  assistant = 'assistant',
  system = 'system'
}

export enum MessageType {
  organisationSearch = 'organisation_search',
  contentSearch = 'content_search',
  other = 'other',
}

export enum ChatFocus {
  organisations = 'organisations',
  content = 'content',
  none = 'none',
}
