<template>
  <VDropdown
    :triggers="[]"
    :placement="placement"
    :shown="isOpen"
    :delay="{show: 0, hide: 150}"
    :auto-hide="true"
    popover-base-class="tooltip tooltip--no-transition tooltip--no-focus tooltip--relevant-edit-popup popover"
    popover-inner-class="relevant-popover--inner"
    @show="handleShowPopover"
    @apply-hide="handleHidePopover"
  >
    <span @mouseenter="handleReferenceMouseEnter" @mouseleave="handleReferenceMouseLeave" @click="handleRelevanceStarClick">
      <slot :relevant="isRelevant" :icon="buttonIcon">
        <icon :id="iconId" :name="buttonIcon" size="18"/>
      </slot>
    </span>

    <template #popper>
      <div class="relevant-edit-popup-popper" @mouseenter="handleReferenceMouseEnter" @mouseleave="handleReferenceMouseLeave">
        <ConceptSearchCheckbox
          v-if="shouldShowActiveSearch"
          :id="activeSearchData.id"
          :relevant="activeSearchData.relevant"
          :title="activeSearchData.title"
          @toggle="handleToggle"
        />
        <ConceptSearchCheckbox
          v-for="conceptSearch in sortedTopSearchesData"
          :key="conceptSearch.id"
          :id="conceptSearch.id"
          :relevant="conceptSearch.relevant"
          :title="conceptSearch.title"
          @toggle="handleToggle"
        />
        <div class="not-found text-muted" v-if="showingNoSearchesMessages">
          No concept searches.
        </div>
        <template v-if="hasRemainingSearches">
          <hr class="horizontal-ruler" v-if="shouldShowHr"/>
          <template v-if="showingRemainingSearches">
            <ds-input
              v-if="filterInputWasVisible"
              v-model="filter"
              ref="filterInput"
              placeholder="Type to filter below…"
              class="filter-input"
            />
            <div class="remaining-wrapper" v-if="sortedFilteredRemainingSearchesData.length > 0">
              <ConceptSearchCheckbox
                v-for="conceptSearch in sortedFilteredRemainingSearchesData"
                :key="conceptSearch.id"
                :id="conceptSearch.id"
                :relevant="false"
                :title="conceptSearch.title"
                @toggle="handleToggle"
              />
            </div>
            <div class="not-found text-muted" v-else>
              No matches.
            </div>
          </template>
          <template v-else>
            <div class="show-more-trigger text-muted" @click="handleShowMoreClick">
              Show more…
            </div>
          </template>
        </template>
      </div>
    </template>
  </VDropdown>
</template>

<script>
  // IMPORTANT NOTE: The "tooltip" class in the "popover-base-class" prop of v-popover above makes the popover actually
  //                 be positioned correctly. I haven't been able to figure out exactly why. Until we understand what
  //                 exactly is going on there, that class is required.

  import ConceptSearchCheckbox from './ConceptSearchCheckbox.vue'
  import { defineComponent } from 'vue'

  const REMAINING_SEARCHES_VISIBLE = 5 // Multiplier on .remaining-wrapper should match this value
  const MOUSE_LEAVE_TIME = 100

  export default defineComponent({
    props: {
      modelValue: Array, // Array of IDs
      placement: {
        type: String,
        default: 'bottom',
      },
      iconId: {
        type: String
      },
      activeSearch: undefined, // ID
      activePortfolio: undefined, // ID
    },
    emits: ['update:modelValue', 'relevant', 'not-relevant'],
    data () {
      return {
        conceptSearchesLoaded: false,
        isOpenByHover: false,
        showingRemainingSearches: false,
        shownAtTop: [],
        filter: '',
        filterInputWasVisible: false,
      }
    },
    mouseLeaveTimeout: undefined,
    computed: {
      allConceptSearchesPromise () {
        return this.$store.state.conceptSearches.allConceptSearchesPromise
      },
      allConceptSearches () {
        return this.$store.state.conceptSearches.allConceptSearches
      },
      safeValue () {
        return this.modelValue || []
      },
      showingNoSearchesMessages () {
        return this.allConceptSearches !== null && this.allConceptSearches.length === 0 && this.safeValue.length === 0
      },
      actualActiveSearch () {
        if (this.activeSearch) {
          return this.activeSearch
        }

        if (this.activePortfolio && this.allConceptSearches !== null) {
          const conceptSearch = this.allConceptSearches.find(x => x.portfolio && x.portfolio.id === this.activePortfolio)
          return conceptSearch ? conceptSearch.id : undefined
        }

        return undefined
      },
      isRelevant () {
        return this.safeValue.length > 0
      },
      buttonIcon () {
        if (this.activeSearch) {
          return this.safeValue.includes(this.activeSearch) ? 'star' : 'star-outline'
        }
        return this.isRelevant ? 'star' : 'star-outline'
      },
      sortedTopSearchesData () {
        return [...this.safeValue, ...this.shownAtTop.filter(id => !this.safeValue.includes(id))]
          .filter(id => id !== this.actualActiveSearch)
          .map(this.getConceptSearchData)
          .sort((a, b) => a.title.localeCompare(b.title));
      },
      activeSearchData () {
        return this.getConceptSearchData(this.actualActiveSearch);
      },
      sortedFilteredRemainingSearchesData () {
        const lowercaseFilter = this.filter.toLowerCase();

        return this.remainingSearches
          .filter(conceptSearch => this.getTitle(conceptSearch).toLowerCase().includes(lowercaseFilter))
          .sort((a, b) => a.title.localeCompare(b.title));
      },
      remainingSearches () {
        if (this.allConceptSearches === null) {
          return [];
        }

        return this.allConceptSearches
          .filter(conceptSearch => (
            conceptSearch.id !== this.actualActiveSearch &&
            !this.safeValue.includes(conceptSearch.id) &&
            !this.shownAtTop.includes(conceptSearch.id)
          ));
      },
      conceptSearchesById () {
        if (this.allConceptSearches === null) {
          return {};
        }

        const result = {};

        for (const conceptSearch of this.allConceptSearches) {
          result[conceptSearch.id] = conceptSearch;
        }

        return result;
      },
      shouldShowHr () {
        return this.safeValue.length > 0 || this.shownAtTop.length > 0 || this.actualActiveSearch;
      },
      shouldShowActiveSearch () {
        return this.actualActiveSearch !== undefined;
      },
      hasRemainingSearches () {
        return this.remainingSearches.length > 0;
      },
      shouldShowFilterInput () {
        return this.filter !== '' || this.remainingSearches.length > REMAINING_SEARCHES_VISIBLE;
      },
      isOpen () {
        return this.isOpenByHover && this.conceptSearchesLoaded;
      },
    },
    methods: {
      getConceptSearchData (id) {
        const conceptSearch = this.conceptSearchesById[id] || { id };

        return {
          id: id,
          title: this.getTitle(conceptSearch),
          relevant: this.safeValue.includes(conceptSearch.id),
        }
      },
      getTitle (conceptSearch) {
        return conceptSearch.title || String(conceptSearch.id)
      },
      handleToggle ({ id, relevant }) {
        if (relevant) {
          if (!this.safeValue.includes(id)) {
            this.$emit('update:modelValue', [...this.safeValue, id])
            this.$emit('relevant', id)
          }
        } else {
          if (this.safeValue.includes(id)) {
            this.$emit('update:modelValue', this.safeValue.filter(x => x !== id))
            this.$emit('not-relevant', id)
          }
        }

        if (!this.shownAtTop.includes(id)) {
          this.shownAtTop.push(id)
        }

        if (this.filter !== '' && this.sortedFilteredRemainingSearchesData.length === 0) {
          this.filter = ''
        }

        this.focusFilterInput()
      },
      handleShowPopover () {
        this.isOpenByHover = true
        this.showingRemainingSearches = (
          ((this.safeValue.length === 0) && !this.actualActiveSearch) ||
          this.sortedFilteredRemainingSearchesData.length === 1
        )

        this.filterInputWasVisible = this.shouldShowFilterInput
      },
      handleHidePopover () {
        this.shownAtTop = []
        this.isOpenByHover = false
      },
      handleReferenceMouseEnter () {
        this.isOpenByHover = true
        if (this.$options.mouseLeaveTimeout) clearTimeout(this.$options.mouseLeaveTimeout)
      },
      handleReferenceMouseLeave () {
        this.isOpenByHover = false
        if (this.$options.mouseLeaveTimeout) clearTimeout(this.$options.mouseLeaveTimeout)
        this.$options.mouseLeaveTimeout = setTimeout(() => this.isOpenByHover = false, MOUSE_LEAVE_TIME)
      },
      handleRelevanceStarClick () {
        if (this.activeSearch && this.safeValue.includes(this.activeSearch)) {
          this.$emit('update:modelValue', this.safeValue.filter(x => x !== this.activeSearch))
          this.$emit('not-relevant', this.activeSearch)
        } else if (this.activeSearch) {
          this.$emit('update:modelValue', [...this.safeValue, this.activeSearch])
          this.$emit('relevant', this.activeSearch)
        }
      },
      handleShowMoreClick () {
        this.showingRemainingSearches = true

        setTimeout(() => {
          this.focusFilterInput()
        }, 0)
      },
      focusFilterInput () {
        if (this.$refs.filterInput) {
          this.$refs.filterInput.focus()
        }
      },
    },
    created () {
      this.allConceptSearchesPromise
        ?.then(() => {
          this.filterInputWasVisible = this.shouldShowFilterInput
          this.conceptSearchesLoaded = true
        })
    },
    components: {
      ConceptSearchCheckbox,
    },
  })
</script>

<style lang="scss" scoped>
  @import "../../../scss/_variables.scss";

  .relevant-edit-popup-popper {
    background: white;
    border: 1px solid #999999;
    z-index: 10000;
    width: 256px;
    box-shadow: 1px 1px 1px 0 rgba(0, 0, 0, 0.1);
  }

  .reference-wrapper {
    display: inline-block;
  }

  .show-more-trigger {
    cursor: pointer;
    text-align: center;
    text-decoration: underline;
    padding: 6px;
    height: 30px;

    &:hover {
      background: $color-primary-lighter;
    }
  }

  .v-popover {
    display: inline-block;
  }

  .not-found {
    text-align: center;
    padding: 6px;
    height: 30px;
  }

  .remaining-wrapper {
    max-height: 30px * 5 + 15px; // Multiplier should be REMAINING_SEARCHES_VISIBLE
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .horizontal-ruler {
    border: none;
    height: 1px;
    background: #cecece;
  }
</style>

<style lang="scss">
  .tooltip--relevant-edit-popup {
    padding: 0 !important;
    background: none !important;
  }

  .relevant-edit-popup-popper .filter-input {
    > input {
      border: none;
      padding: 7px;
      margin: 1px;
      line-height: normal;
      width: calc(100% - 2px);
      height: auto;
      background: #f2f2f2;

      &:focus {
        outline: none;
        box-shadow: none !important;
      }
    }
  }
</style>
