<template>
  <div class="top-bar__parent">
    <div class="top-bar" :class="{ 'top-bar-foodleap': isFoodleap, 'is-simplified': isNewSimplified}"
         @mouseleave="dropdown = false">
      <ds-button
        v-if="ui.isMobile"
        icon="view-list"
        :variant="isNewSimplified ? 'primary' : 'secondary'"
        @click="handleToggleMobileNavigationDrawerVisibility"
      />
      <div
        class="top-bar__ecosystem-indicator" v-if="currentEcosystem"
        @click="navigateToHome">
        <avatar :src="currentEcosystem.favicon_url" :alt="name " variant="primary" v-if="!ui.isMobile"/>
        <div class="top-bar__ecosystem-indicator__name" id="introjs-message-board-home-secondtopbar">{{ name }}</div>
        <ds-button
          @click="toggleEcosystemSwitcher"
          v-if="canSwitchEcosystems"
          class="top-bar__ecosystem-switcher"
          :icon="isEcosystemSwitcherVisible ? 'chevron-up' : 'chevron-down'"
          variant="minimal"
        />
      </div>
      <portfolio-dropdown
        v-if="canShowPortfolioDropdownItems && !ui.isMobile"
        :options="portfolioList"
        :selection="selection"
        icon="chevron-down"
        size="small"
        variant="secondary"
        label="Portfolios"
        @input="updateSelection"
      ></portfolio-dropdown>
      <top-bar-portfolio-keyword
        style="margin-left:1em"
        v-if="canShowPortfolioDropdownItems && !ui.isMobile"/>
      <div class="top-bar__search full-width">
        <GlobalSearch ref="globalSearch"
                      :placeholder="$t('global_search_placeholder', { ecosystem: ecosystemDisplayName })"/>
        <div>
          <ds-button
            v-if="canDisplayChatbot"
            block
            class="chatbot-icon"
            :label="chatbotLabel"
            @click="showSidePanel"
          />
        </div>
      </div>
      <ds-button
        v-if="!ui.isMobile && canShowTelex"
        block
        class="telex-icon"
        icon="line-graph"
        variant="secondary"
        @click="openSidePanel()"
      />
      <div
        v-if="hasAccessToConversations && isLoggedIn && !ui.isMobile"
        class="top-bar__conversations" @click="showFirstConversation">
        <ds-button
          style="padding: 3px 15px 0; margin-left: 5px"
          icon="comment-filled"
          icon-size="14"
          :badge-count="unreadConversationsCount"
          v-tooltip.top-center="$t('tooltip_conversations_topbar')"
        />
      </div>
      <div
        v-if="enableNotifications && !ui.isMobile"
        class="top-bar__notifications"
        :class="{ 'top-bar__user--active': dropdown === 'notifications' }"
        @click="toggleDropdown('notifications')"
      >
        <ds-button
          block
          icon="bell"
          :badge-count="unreadNotificationsBadgeCount"
          v-tooltip.top-center="$t('tooltip_notifications_topbar')"
        />
        <div class="top-bar__user__dropdown">
          <div class="top-bar__dropdown__scroll">
            <template v-for="item in sortedUnreadNotifications">
              <div class="top-bar__dropdown__card">
                <div
                  :class="item.action_text ? 'top-bar__ribbon' : 'top-bar__small__ribbon'"
                  :style="notificationColor(item.type)" v-if="false"></div>
                <a
                  class="top-bar__notification" :href="notificationLink(item)"
                  @click.prevent="redirectNotification(item)">
                  <icon
                    class="top-bar__notification__icon"
                    :class="notificationColorClass(item.type)"
                    :name="notificationIconName(item.type, item.event)"></icon>
                  <div class="top-bar__notification__content">
                    {{ item.title || item.body }}
                    <div class="news__info">{{
                        item.announcement ? announcementLabel : $t('generic_notification')
                      }} {{
                        item.ecosystem ? $t('generic_from') + ' ' + (item.ecosystem.name === $store.state.config.name ? $store.getters.ecosystemDisplayName : item.ecosystem.name) : ''
                      }}
                    </div>
                    <!--                <ds-button variant="secondary button&#45;&#45;small" v-if="item.action_text" :label="item.action_text" @click="redirectNotification(item)" :target="item.is_external ? '_blank' : null" />-->
                  </div>
                </a>
              </div>
            </template>
          </div>

          <ds-button
            class="hidden-notification-down"
            :label="$t('notifications_show_all_notifications')"
            variant="secondary button--small" to="/notifications"
            @click="dropdown = false" block/>
        </div>
      </div>
      <div
        class="top-bar__help" :class="{ 'top-bar__user--active': dropdown === 'help' }"
        id="introjs-after-onboarding-faq" v-if="!ui.isMobile">
        <ds-button
          block
          @click="toggleDropdown('help')"
          icon="information"
          v-tooltip.top-center="$t('tooltip_support_topbar')"
        />
        <div class="top-bar__user__dropdown">
          <ds-button
            block
            :href="faqUrl"
            @click="trackHeapEvent('topbar.faq')"
            target="_blank"
            :label="$t('help_dropdown_faq')"
            variant="link"
          />
          <!-- hide feedback button for now -->
          <ds-button
            block
            @click="openFeedbackWindow(locale)"
            variant="link"
            :label="$t('help_dropdown_feedback')"
          />
          <ds-button
            block v-if="introJsType" @click="startIntro" label="Show tutorial"
            variant="link"/>
        </div>
      </div>
      <div
        class="top-bar__user" :class="{'top-bar__user--active': dropdown === 'language'}"
        @click="toggleDropdown('language')"
        v-if="isSimplifiedTemplateUsed && enabledLocales.length > 1 && !ui.isMobile"
        v-tooltip.top-center="$t('generic_languages')">
        <div class="top-bar__user__name">
          {{ $store.state.localization.activeLocale.toUpperCase() }}
        </div>
        <icon name="chevron-down"/>
        <div class="top-bar__user__dropdown" @click.stop>
          <template v-if="isSimplifiedTemplateUsed">
            <ds-button
              v-for="locale in enabledLocales"
              :key="locale"
              block
              @click="setLanguage(locale)"
              :label="LOCALE_LABELS[locale]"
              variant="link"
            />
          </template>
          <ds-button
            block
            @click="setLanguage('cimode')"
            :label="LOCALE_LABELS['cimode']"
            variant="link"
            v-if="showCiMode"
          />
        </div>
      </div>
      <div
        v-if="isLoggedIn" class="top-bar__user"
        :class="{'top-bar__user--active': dropdown === 'user'}"
        @click="toggleDropdown('user')"
        v-tooltip.top-center="$t('generic_settings')"
        style="margin-right: 10px"
        id="introjs-user-profile">
        <avatar :src="profile.photo_url" :alt="profile.name" id="introjs-after-onboarding-icon-user-profile"/>
        <icon name="chevron-down"/>
        <div class="top-bar__user__dropdown" @click.stop>
          <ds-button
            block
            v-if="canAccessConfiguration"
            id="introjs-user-dropdown-configuration"
            class="hidden-user-down"
            :to="configurationPageUrl"
            icon="wrench"
            :label="ui.isMobile ? 'Curation' : 'Ecosystem Configuration'"
            variant="link"
          />
          <ds-button
            block
            v-for="community in communitiesOfWhichUserIsMember"
            class="hidden-user-down"
            :to="`/communities/${community.id}`"
            icon="users"
            :label="community.name"
            variant="link"
          />
          <ds-button
            block
            v-if="canShowUserBilling"
            class="hidden-user-down"
            to="/profile/subscription#upgrade"
            icon="file-invoice-dollar"
            label="Upgrade"
            variant="link"
          />
          <ds-button
            block
            class="hidden-user-down"
            :to="isPersonalHomeDisabled ? '/profile/update#notification-settings' : '/profile/update#personal-home'"
            icon="home"
            :label="isPersonalHomeDisabled ? $t('profile_settings_personal_settings') : $t('profile_settings_personal_home')"
            variant="link"
          />
          <ds-button
            block
            @click="logout"
            icon="sign-out"
            :label="$t('user_dropdown_sign_out')"
            variant="link"
          />
        </div>
      </div>
      <div class="top-bar__user top-bar__user--actions" style="margin-right: 10px;"
           v-if="!isLoggedIn && isCommunityEcosystem">
        <ds-button label="Join the community" variant="secondary" @click.prevent="$router.push('/register')"/>
      </div>
      <div class="top-bar__user top-bar__user--actions" style="margin-right: 10px;" v-if="!isLoggedIn">
        <ds-button label="Login" variant="primary" @click.prevent="$router.push('/login')"/>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import { ACTION_TYPES as USER_ACTION_TYPES } from '../../store/modules/user.js'
  import { MUTATION_TYPES as USER_MUTATION_TYPES } from '../../store/modules/user-helpers.js'
  import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../../store/modules/actors.js'
  import { ACTION_TYPES as CHALLENGES_ACTION_TYPES } from '../../store/modules/challenges.js'
  import { ACTION_TYPES as NOTIFICATIONS_ACTION_TYPES } from '../../store/modules/notifications.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import { fetchAllFavourites, Portfolios } from '@/api/portfolios.js'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import { DEFAULT_URLS } from '@/constants/default-urls'

  import Avatar from '../Avatar/Avatar.vue'
  import SuggestionInput from '../SuggestionInput/SuggestionInput.vue'
  import PortfolioDropdown from '../Dropdown/PortfolioDropdown.vue'
  import TopBarPortfolioKeyword from '../Keyword/TopBarPortfolioKeyword.vue'
  import ToggleButton from '../Form/ToggleButton.vue'
  import IconHoverTag from '../Icon/IconHoverTag.vue'

  import UiMixin from '../../util/UiMixin.js'
  import { ConversationsMixin } from '@/util/ConversationsMixin'
  import escapeHTML from '../../util/escapeHTML'

  import { readNotifications } from '@/api/notifications.js'

  import { notificationColor, notificationColorClass, notificationIconName, NotificationsMixin } from '@/util/NotificationsMixin'
  import { trackHeapEvent } from '@/util/analytics'
  import { LOCALE_LABELS } from '@/store/modules/localization.js'
  import TranslationsMixin from '../../util/TranslationsMixin.js'

  import { SiteTemplate } from '@/store/modules/config'
  import IntroJsMixin from '../../util/IntroJsMixin.js'
  import { ACTION_TYPES as CONVERSATION_ACTION_TYPES } from '../../store/modules/conversations.js'
  import GlobalSearch from './GlobalSearch/GlobalSearch.vue'
  import { defineComponent } from 'vue'
  import CommunityMixin from '@/util/CommunityMixin'

  export default defineComponent({
    components: {
      Avatar,
      SuggestionInput,
      PortfolioDropdown,
      TopBarPortfolioKeyword,
      ToggleButton,
      IconHoverTag,
      GlobalSearch,
    },
    props: {
      notifications: false,
    },
    data () {
      return {
        dropdown: false,
        actorSuggestionSettings: {
          api: {
            url: '/api/actors?name={query}',
            onResponse: data => ({
              results: Object.values(data).map(company => ({
                title: escapeHTML(company.name),
                data: company,
              })),
            }),
          },
        },
        selection: [],
        errors: {},
        portfolioList: null,
        hasFavouritePortfolios: false,
        query: '',
      }
    },
    computed: {
      chatbotLabel () {
        if (this.$store.getters.chatbotCustomization) {
          return this.$store.getters.chatbotCustomization.chatbotCTA
        }

        return 'Ask a question'
      },
      canDisplayChatbot () {
        return !this.ui.isMobile && this.$store.getters.isQandaChatBotEnabled && this.$store.getters.isLoggedIn
      },
      isTeamMember () {
        return this.$store.getters.isTeamMember
      },
      isFoodleap () {
        return this.$store.getters.isFoodleap
      },
      isCommunityEcosystem () {
        return this.$store.getters.isCommunityEcosystem
      },
      canAccessConfiguration () {
        if (this.isCommunityManagerAndPortfolioManager) {
          return true
        }

        if (!this.isMember) {
          return false
        }

        return !this.ui.isMobile || !this.isExplorationOnly
      },
      configurationPageUrl () {
        if (this.ui.isMobile) {
          return '/datalab/curation'
        }

        if (this.$store.getters.isStakeholderMappingOnly) {
          return '/datalab/spreadsheet'
        }

        return '/datalab/curation'
      },
      communitiesOfWhichUserIsMember () {
        if (this.canShowMyCommunities) {
          return this.joinedCommunities
        }
        return []
      },
      canShowMyCommunities () {
        return this.$store.getters.isLoggedIn && !this.$store.getters.isOwner && (this.$store.getters.isActor || this.$store.getters.isMember) && this.hasAccessToNewCommunities
      },
      hasAccessToNewCommunities () {
        return this.$store.getters.hasAccessToCommunities
      },
      isPersonalHomeAvailable () {
        return !this.isPersonalHomeDisabled
      },
      isPersonalHomeDisabled () {
        return !!this.$store.state.config.personalHomeDisabled
      },
      isSimplifiedTemplateUsed () {
        return [SiteTemplate.NEW_SIMPLIFIED, SiteTemplate.SIMPLIFIED].includes(this.$store.getters.activeSiteTemplate)
      },
      isExplorationOnly () {
        return this.$store.getters.isExplorationOnly
      },
      intelligenceCtaUpgradeText () {
        if (this.showIntelligenceUpgradeCTA) {
          return this.config.packageCTAs.intelligence.text || this.$t('default_intelligence_upgrade_cta')
        }
        return ''
      },
      showIntelligenceUpgradeCTA () {
        return this.isLoggedIn && !this.displayExploreContent && this.config.packageCTAs && this.config.packageCTAs.intelligence && this.config.packageCTAs.intelligence.enabled
      },
      canShowEcosystemPackages () {
        return (this.$store.getters.hasStripeKeys || this.$store.getters.isDeveloper) &&
          this.$store.getters.hasAccessToEcosystemMemberPackages
      },
      canShowUserBilling () {
        return this.canShowEcosystemPackages && this.$store.getters.isLoggedIn && this.$store.getters.isActor
      },
      totalNewActorsCount () {
        return this.$store.state.actors.newActorCount
      },
      totalNewNotificationsCount () {
        return this.$store.state.notifications.newNotificationsCount
      },
      totalNewChallengesCount () {
        return this.$store.state.challenges.newChallengesCount
      },
      totalNewProductsCount () {
        return this.$store.state.actors.newProductCount
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      displayExploreContent () {
        const hasAccess = (this.$store.getters.hasAccessToExploration || this.$store.getters.hasAccessToMonitoring)

        return this.$store.getters.isLoggedIn && hasAccess && this.config.viewDashboard && this.config.viewDashboard.includes('explore-content')
      },
      displayAnnouncements () {
        return this.$store.getters.canUsePublicAnnouncements && this.$store.getters.areMessageBoardsEnabled
      },
      isNewSimplified () {
        return this.config && this.config.siteTemplate === SiteTemplate.NEW_SIMPLIFIED
      },
      viewActorTypes () {
        return this.$store.getters.viewActorTypes
      },
      displayChallenges () {
        return this.$store.state.config.challengesAreEnabled && this.shouldSeeChallenges
      },
      displayKnowledgeBase () {
        return this.$store.getters.hasAccessToKnowledgeBase && this.config.viewDashboard && this.config.viewDashboard.includes('knowledge-base')
      },
      shouldSeeChallenges () {
        if (this.$store.getters.isMember) {
          return true
        }

        return this.$store.getters.canCreateChallenge ||
          this.$store.getters.viewableChallengeStatusses.length > 0 ||
          this.$store.getters.accessibleChallengeStatusses.length > 0
      },
      enabledLocales () {
        return this.$store.state.localization.enabledLocales
      },
      showCiMode () {
        return import.meta.env.APP_ENV === 'local'
      },
      LOCALE_LABELS () {
        return LOCALE_LABELS
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      isActor () {
        return this.$store.getters.isActor
      },
      isGlobalSearchVisible () {
        return this.$store.state.ui.globalSearch.isVisible
      },
      enableNotifications () {
        return this.isLoggedIn && this.notifications
      },
      unreadConversationsCount () {
        return this.$store.state.conversations.listData.unreadCount
      },
      conversations () {
        return this.$store.state.conversations.listData.data
      },
      unreadNotificationsBadgeCount () {
        return this.unreadNotificationsCount
      },
      sortedUnreadNotifications () {
        return this.unreadNotifications.sort((a, b) => {
          const aMoment = moment(a.created_at)
          const bMoment = moment(b.created_at)

          if (!aMoment.isValid() && !bMoment.isValid()) {
            return 0
          } else if (!aMoment.isValid()) {
            return 1
          } else if (!bMoment.isValid()) {
            return -1
          }

          return bMoment.diff(aMoment)
        })
      },
      config () {
        return this.$store.state.config
      },
      profile () {
        return this.$store.state.user.profile
      },
      currentEcosystem () {
        return this.$store.getters.currentEcosystem
      },
      name () {
        return this.currentEcosystem.displayName || this.currentEcosystem.name
      },
      canSwitchEcosystems () {
        return (this.$store.state.user.profile.teams || []).length > 1
      },
      isEcosystemSwitcherVisible () {
        return this.$store.state.ui.ecosystemSwitcher.isVisible
      },
      userHasPortfolioAccess () {
        return this.isLoggedIn && (this.$store.getters.isAdmin || this.$store.getters.isOwner || this.$store.getters.isMember)
      },
      canShowPortfolioDropdownItems () {
        return !(this.$route.path === '/dashboards/home' ||
          this.$route.path === '/portfolios' ||
          this.$route.path === '/actors/create' ||
          this.$route.path === '/dashboards/compare' ||
          this.$route.path === '/dashboards/charts' ||
          this.$route.path === '/dashboards/knowledge-base' ||
          this.$route.path === '/spotting-areas' ||
          this.$route.path.includes('/spotting-areas/') ||
          (this.$route.name === 'datalab' && this.$route.path !== '/datalab/spreadsheet' && this.$route.path !== '/datalab/suggestions') ||
          this.$route.name === 'settings' ||
          (this.$route.path === '/actors' && (this.$route.query.actor_type === 'Community' || this.$route.query.actor_type === 'Person'))
        ) && this.userHasPortfolioAccess
      },
      canShowTelex () {
        // Deprecated
        return false
      },
      personalHomepageEnabled () {
        // Hard coded until we bring back the personalisation on the home page
        return false
        // return this.$store.getters.userSettings && this.$store.getters.userSettings.personalHomepageEnabled
      },
      introJsType () {
        const config = this.buildIntroJsConfig(this.config, this.$store.getters.userRole, true)
        if (this.$route.path === '/dashboards/home' && ![SiteTemplate.SIMPLIFIED, SiteTemplate.NEW_SIMPLIFIED].includes(this.$store.getters.activeSiteTemplate) && config.home && config.home.steps) {
          return 'home'
        }
        if (this.$route.path.startsWith('/settings/ecosystem') && config.settings && config.settings.steps) {
          return 'settings'
        }
        if (this.$route.path.startsWith('/dashboards/concept-map/') && config.conceptMap && config.conceptMap.steps) {
          return 'conceptMap'
        }
        if (this.$route.path.startsWith('/dashboards/explore-content') && config.exploreContent && config.exploreContent.steps) {
          return 'exploreContent'
        }
        if (this.$route.path.startsWith('/profile/update') && (!this.$route.hash || this.$route.hash === '#personal-home') && config.personalHome && config.personalHome.steps) {
          return 'personalHome'
        }
        // if (this.$route.path.startsWith('/actors') && config.simplifiedActorDetail && config.simplifiedActorDetail.steps && this.$route.name === 'actor-detail' && [SiteTemplate.SIMPLIFIED, SiteTemplate.NEW_SIMPLIFIED].includes(this.$store.state.config.siteTemplate)) {
        //   return 'simplifiedActorDetail'
        // }
        // if (this.$route.path.startsWith('/dashboards/home' && [SiteTemplate.SIMPLIFIED].includes(this.$store.getters.activeSiteTemplate) && config.homeCommunities && config.homeCommunities.steps)) {
        //   return 'homeCommunities'
        // }
        // if (this.$route.name === 'communities' && config.communitiesOverviewPage && config.communitiesOverviewPage.steps) {
        //   return 'communitiesOverviewPage'
        // }
        // if (this.$route.name === 'community-detail' && config.communitiesDetailPage && config.communitiesDetailPage.steps) {
        //   return 'communitiesDetailPage'
        // }
        // if (this.$route.name === 'spotting-areas' && config.spottingAreasHome && config.spottingAreasHome.steps) {
        //   return 'spottingAreasHome'
        // }
        // if (this.$route.name === 'spotting-area-detail' && config.spottingAreasDetailPage && config.spottingAreasDetailPage.steps) {
        //   return 'spottingAreasDetailPage'
        // }
        // if (this.$route.path.startsWith('/announcements-simplified') && config.messageBoard && config.messageBoard.steps) {
        //   return 'messageBoard'
        // }
        if (config.personalHome) {
          // If there's a configuration for personalHome and the user is not on one of the pages that has a tutorial, redirect to personal home and start it
          return 'personalHomeRedirect'
        }
      },
      canShowCounters () {
        const isEcosystemMember = this.$store.getters.isLoggedIn && this.$store.getters.userRole === 'actor'
        const canSeeSimplified = this.$store.getters.isPublic || isEcosystemMember
        return canSeeSimplified && this.isNewSimplified
      },
      faqUrl () {
        if (this.isOwner) {
          return DEFAULT_URLS.FAQ
        }
        if (this.config.faqUrl) {
          return 'https://' + this.config.faqUrl
        } else {
          return DEFAULT_URLS.FAQ
        }
      },
      isMember () {
        return this.$store.getters.isMember
      },
      isPortfolioMember () {
        return this.$store.getters.isPortfolioMember
      },
    },
    methods: {
      showSidePanel () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'chat-bot-side-panel' })
      },
      showFirstConversation () {
        let firstConversationId
        if (this.conversations && this.conversations[0] && this.conversations[0].id) {
          firstConversationId = this.conversations[0].id
        }

        // Note: undefined param when conversations are not fully loaded in
        this.$router.push({
          name: 'profile',
          params: {
            panel: 'conversations',
            id: firstConversationId,
          },
        })
      },
      startIntro () {
        const config = this.buildIntroJsConfig(this.config, this.$store.getters.userRole, true)

        if (['personalHome', 'personalHomeRedirect'].includes(this.introJsType)) {
          // clear all intros to allow re-entering the entire flow
          const seenIntrosWithoutCommunityRelatedIntros = this.seenIntros.filter(intro => !['personalHome', 'simplifiedActorDetail', 'spottingAreasHome', 'spottingAreasDetailPage', 'messageBoard', 'homeCommunities', 'communitiesOverviewPage', 'communitiesDetailPage'].includes(intro))
          this.$store.commit(USER_MUTATION_TYPES.STORE_SETTINGS, { seenIntros: seenIntrosWithoutCommunityRelatedIntros.join(',') })
        }

        /*if (this.introJsType === 'personalHomeRedirect' && !['/profile/update#personal-home', '/profile/update'].includes(this.$route.fullPath)) {
          this.$router.push('/profile/update#personal-home')
          return
        }*/

        const intro = this.introJs()
        this.currentIntro = intro
        config[this.introJsType].steps = this.getIntroJsStepsConfig(config[this.introJsType].steps)

        intro.setOptions(config[this.introJsType])

        const componentScope = this
        intro.onbeforechange(this.introJsBeforeStepCallback.bind(this))
        intro.onafterchange(this.introJsAfterStepCallback.bind(this))
        intro.oncomplete(function () {
          componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
        })
        intro.onexit(function () {
          componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
        })

        this.$bus.emit('start-intro-' + this.introJsType)
        this.$nextTick(() => intro.start())
      },
      trackHeapEvent,
      fetchActorsCount () {
        this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_NEW_ACTORS_COUNT)
        this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_NEW_PRODUCTS_COUNT)
      },
      fetchChallengesCount () {
        if (this.$store.state.config.challengesAreEnabled) {
          this.$store.dispatch(CHALLENGES_ACTION_TYPES.FETCH_NEW_CHALLENGES_COUNT)
        }
      },
      fetchConversations () {
        if (!this.hasAccessToConversations) {
          return false
        }

        if (!this.$store.state.conversations.listData.loading) {
          setTimeout(() => {
            // The initial load of the app is very API intensive, fetching the conversations has a low priority
            this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
          }, 10000)
        }
      },
      fetchNotificationsCount () {
        this.$store.dispatch(NOTIFICATIONS_ACTION_TYPES.FETCH_NEW_NOTIFICATIONS_COUNT)
      },
      openFeedbackWindow () {
        document.querySelector('.userback-button-e').click()
      },
      handleToggleMobileNavigationDrawerVisibility () {
        this.$emit('toggleMobileNavigationDrawerVisibility')
        this.$store.commit(UI_MUTATION_TYPES.HIDE_GLOBAL_SEARCH)
      },
      handleToggleMobileSearchDrawerVisibility () {
        this.$bus.emit('toggleMobileSearchDrawerVisibility')
      },
      // Redirect the user to the notification and open the link in a different tab, also mark the notification as read
      redirectNotification (notification) {
        // Mark the notification as read
        readNotifications([notification.id])

        if (notification.action_url && notification.action_url.startsWith('http')) {
          window.location.href = notification.action_url
        } else {
          window.location.href = '/notifications/' + notification.id
        }
      },
      notificationColor,
      notificationColorClass,
      notificationIconName,
      notificationLink (notification) {
        if (notification.action_url && notification.action_url.startsWith('http')) {
          return notification.action_url
        } else {
          return '/notifications/' + notification.id
        }
      },
      togglePersonalHomepage (state) {
        trackHeapEvent('global.personalHomepageEnabled', state)
        this.$store.commit('USER/STORE_SETTINGS', { personalHomepageEnabled: state })
      },
      toggleDropdown (key) {
        if (this.unreadNotificationsBadgeCount === 0 && key == 'notifications') {
          return this.$router.push('/notifications')
        }

        this.dropdown = this.dropdown === key ? false : key
      },
      setLanguage (locale) {
        this.$store.dispatch('LOCALIZATION/UPDATE_ACTIVE_LOCALE', { locale, i18n: this.$i18n })
        this.dropdown = false
      },
      logout () {
        this.$store.dispatch(USER_ACTION_TYPES.LOGOUT_REQUEST)

        setTimeout(() => {
          location.reload()
        }, 1000)
      },
      handleSelectActor (actor) {
        trackHeapEvent('global.search.clickSearchedActor', {
          id: actor.id,
          medium: 'desktop',
        })

        if (this.$store.getters.views.includes('actors/detail')) {
          // Be sure to fetch it, because ActorDetail may not.
          this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_DETAIL, actor.id)
          this.$router.push(`/actors/${actor.id}`)
        } else if (this.$store.getters.isMember) {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
            component: 'scores',
            metaData: actor.id,
          })
        } else {
          this.$router.push(`/actors`)
          /* this.$store.dispatch('ACTORS/FETCH_ACTOR_FOR_PREVIEW', actor)
            .then(actor => {
              try {
                this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component:"scores", metaData: actor.id})
                this.$root.$children[0].$refs.view.$refs.mapPane.forceVisibleMarker(actor)
              } catch (e) {}
            }) */
          try {
            this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
              component: 'scores',
              metaData: actor.id,
            })
            this.$root.$children[0].$refs.view.$refs.mapPane.forceVisibleMarker(actor)
          } catch (e) {
          }
          // this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component:"scores", metaData: actor.id})
        }
      },
      navigateToHome () {
        this.$router.push('/')
      },
      toggleEcosystemSwitcher () {
        if (document.body.clientWidth < 768) {
          this.$router.push('/')
          return
        }

        if (this.canSwitchEcosystems) {
          this.$store.commit(UI_MUTATION_TYPES.TOGGLE_ECOSYSTEM_PANEL)
        } else {
          this.$router.push('/')
        }
      },
      updateSelection (value) {
        this.selection = value
      },
      openSidePanel () {
        if (this.$store.state.ui.sidePanel.isVisible) {
          this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
        } else {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'telex' })
        }
      },
      showConfirmationModal () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          title: 'Personal Homepage',
          body: 'You don\'t have favourite Portfolios. Better information will be provided by having a favourite portfolio',
        })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CONFIRMATION)
      },
      fetchFavouritePortfolios () {
        // Fetch all portfolios
        fetchAllFavourites().then(favouritePortfolios => {
          this.hasFavouritePortfolios = favouritePortfolios.length > 0
        }).catch(errors => {
          console.log(errors)
        })
      },
      fetch () {
        // Fetch all portfolios
        Portfolios.get()
          .then(portfolios => {
            this.portfolioList = portfolios
          })
          .catch(errors => {
            this.errors = errors
          })
      },
    },
    mounted () {
      if (this.isLoggedIn && !this.isActor) {
        this.fetchFavouritePortfolios()
      }

      if (this.canShowCounters) {
        this.fetchActorsCount()
        this.fetchChallengesCount()

        this.$bus.on('actorCreated', this.fetchActorsCount)
        this.$bus.on('challengeCreated', this.fetchChallengesCount)
        this.$bus.on('announcementCreated', this.fetchNotificationsCount)
      }

      if (this.$store.getters.isLoggedIn) {
        this.fetchNotificationsCount()
        this.fetchConversations()
      }

      /*if (this.$store.getters.isLoggedIn && this.hasAccessToNewCommunities && !this.$store.state.communities.listData.loading) {
        this.$store.dispatch(COMMUNITY_ACTION_TYPES.FETCH_COMMUNITIES_LIST)
      }*/
    },
    beforeUnmount () {
      if (this.canShowCounters) {
        this.$bus.off('actorCreated', this.fetchActorsCount)
        this.$bus.off('challengeCreated', this.fetchChallengesCount)
        this.$bus.off('announcementCreated', this.fetchNotificationsCount)
      }
    },
    watch: {
      userSettings (isPersonalHomePage) {
        if (isPersonalHomePage && !this.hasFavouritePortfolios) {
          this.showConfirmationModal()
        }
      },
    },
    mixins: [NotificationsMixin, ConversationsMixin, UiMixin, TranslationsMixin, IntroJsMixin, CommunityMixin],
  })
</script>

<style scoped lang="scss">
  @import "resources/assets/scss/variables";

  .top-bar__user__dropdown {
    :deep(.button .svg-inline--fa + .button__label) {
      padding-left: 3px
    }
  }

  .top-bar-foodleap, .top-bar-foodleap.is-simplified {
    background: $foodleap-topbar-background-color;
  }


  .locked-button {
    :deep(.svg-icon) {
      fill: white;
    }
  }

  .chatbot-icon {
    background-color: #843DFF;
    color: white;
    border-radius: 0.75rem;
    margin-left: 0.5rem;
    margin-right: 1rem;
    display: flex;
    align-items: center;

    :deep(.button__label) {
      text-transform: lowercase;
      //text-overflow: ellipsis;
      //white-space: nowrap;
      //overflow: hidden;
      //max-width: 90%;
    }

    :deep(.button__label:first-letter) {
      text-transform: capitalize !important;
    }

    :hover {
      background-color: #843DFF !important;
    }
  }

  .top-bar__search {
    display: flex;
    align-items: center;

    :deep(.global-search) {
      width: 100%;
      max-width: 600px;
    }
  }
</style>
