<template>
  <div class="agent-card" :class="{ 'is-enabled': agent.isEnabled }">
    <badge-counter
      v-if="numberOfSuggestions && numberOfSuggestions > 0"
      :count="numberOfSuggestions"
    />

    <div class="agent-card-body">
      <div class="agent-card__button">
        <ds-button icon="play_button" label="" @click="startAgent(agent)" style="background-color: transparent;"
          :disabled="agent.status == 'running' || agent.status == 'queued' || !canRun"/>
      </div>
      <div class="agent-main">
        <h4 class="agent-name">
          {{ agent.displayName }}
        </h4>
        <p class="agent-description" :class="{ 'is-disabled': !agent.isEnabled }" style="margin-bottom: 0">
          {{ agent.description }}
        </p>
        <similar-actors-filter-section
          v-if="agent.isEnabled && agent.name === 'lookalike_agent' && actor && (hasAtLeastOneClassification) && classificationCounts"
          :allow-edit="true"
          :selectedFilters="selectedFilters"
          :classification-counts="classificationCounts"
          @update-selected-filters="selectedFilters = $event;"
        />
      </div>
    </div>

    <div
      class="agent-card-footer loading-indicator"
      v-if="agent.isEnabled && (agent.status == 'queued' || agent.status == 'running')"
    >
      <icon name="spinner"/>
      {{ agent.status == 'queued' ? '30' : '75' }}% Loading
    </div>
    <div
      class="agent-card-footer loading-indicator"
      v-else-if="agent.isEnabled && (agent.status == 'finished')"
      :style="agent.added_count && agent.added_count > 0 ? 'display: flex; flex-direction: column;' : ''"
    >
      <p>{{ getFinishedMessage(agent) }}</p>
      <p v-if="agent.added_count && agent.added_count > 0">{{ agent.added_count }} new relationships added automatically</p>
    </div>

    <div
      class="agent-card-footer"
      v-if="agent.isEnabled && !agent.isLoading && suggestions && suggestions.length"
    >
      <a class="footer-toggle" href="#" @click="toggleSuggestions">
        <icon :name="suggestionsVisible ? 'chevron-up' : 'chevron-down'"/>
      </a>

      <div v-if="suggestionsVisible">
        <template v-for="suggestion of suggestions">
          <agent-suggestion
            @acceptSuggestion="acceptSuggestion(suggestion)"
            @declineSuggestion="declineSuggestion(suggestion)"
            :suggestion="suggestion"
            :disabled="suggestion.isAdded || processingSuggestion"
          />
        </template>
      </div>
    </div>
  </div>
</template>

<script>
  import Checkbox from '../../Form/Checkbox.vue'
  import BadgeCounter from '../../Badge/BadgeCounter.vue'
  import AgentSuggestion from './AgentSuggestion.vue'

  import { runAgentForActor, runSimilarityAgentForActor } from '../../../api/actors.js'
  import { Suggestions } from '../../../api/heartbeat.js'
  import { trackHeapEvent } from '../../../util/analytics'
  import SimilarActorsFilterSection from '../SimilarActors/SimilarActorsFilterSection.vue'
  import { getDefaultTaxonomiesForType } from '../../../store/modules/taxonomies.js'
  import TranslationsMixin from '../../../util/TranslationsMixin.js'

  import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../../../store/modules/actors'

  export default {
    data () {
      return {
        suggestionsVisible: false,
        toggleEnabled: Function,
        selectedFilters: [{ type: 'description', value: true }],
        agentsWithMeta: [
          'get_founders',
          'get_team',
          'get_administrators',
          'get_investors',
          'get_investments',
          'get_acquisitions',
          'get_portfolio'
        ],
        agentsWithSuggestions: [
          //'get_contacts'
        ],
        classificationCounts: [],
        firstTimeSuggestionsAreUpdated: false, // Only applicable for look-a-like agent,
        processingSuggestion: false
      }
    },
    props: {
      agent: {
        type: Object
      },
      actor: {
        type: Object
      },
      suggestions: {
        type: Array,
        default: () => []
      }
    },
    computed: {
      canRun () {
        if (this.agent && this.agent.name === 'lookalike_agent') {
          return this.selectedFilters && this.selectedFilters.length > 0
        }

        return true
      },
      numberOfSuggestions () {
        return this.suggestions.length
      },
      hasAtLeastOneClassification () {
        return this.actor.category ||
          (this.actor.address && this.actor.address.country_code) ||
          (this.actor.industries && this.actor.industries.length) ||
          (this.actor.technology && this.actor.technology.length) ||
          (this.actor.activities && this.actor.activities.length)
      }
    },
    methods: {
      toggleSuggestions (e) {
        // Deprecated
        e.preventDefault()
        this.suggestionsVisible = !this.suggestionsVisible
      },
      updateSelectedFilters () {
        if (this.suggestions.length <= 0) {
          return
        }

        var meta = this.suggestions[0].meta

        if (!meta) {
          return
        }

        Object.keys(meta).forEach((key) => {
          if (key == 'address.country_code') {
            this.selectedFilters.push({ type: 'country_code', value: [meta[key]] })
          } else {
            var metaValue = Array.isArray(meta[key]) ? meta[key] : [meta[key]]

            this.selectedFilters.push({ type: key, value: metaValue })
          }
        })

        this.firstTimeSuggestionsAreUpdated = true
      },
      showDefaultTaxonomiesForActor () {
        this.classificationCounts = {}

        if (this.actor.address && this.actor.address.country_code && this.actor.address.country) {
          this.classificationCounts['address.country_code'] = [{
            label: this.actor.address.country,
            value: this.actor.address.country_code,
            count: 1
          }]
        }

        if (this.actor.maturity && this.actor.maturity !== 'unknown') {
          this.classificationCounts.maturity = [{
            label: this.actor.maturity,
            value: this.actor.maturity,
            count: 1
          }]
        }

        if (this.actor.category && getDefaultTaxonomiesForType('category').includes(this.actor.category.label)) {
          this.classificationCounts.category = [{
            label: this.actor.category.label,
            value: this.actor.category.value,
            count: 1
          }]
        }

        for (const taxonomy of ['industries', 'technology', 'activities']) {
          this.classificationCounts[taxonomy] = []
          const defaultTaxonomyValues = getDefaultTaxonomiesForType(taxonomy)

          if (this.actor[taxonomy] && this.actor[taxonomy].length) {
            for (const taxonomyValue of this.actor[taxonomy]) {
              let label, value

              if (taxonomy === 'industries') {
                label = this.getLabelForTaxonomyValue('industries', taxonomyValue)
                value = taxonomyValue
              } else {
                label = taxonomyValue.label
                value = taxonomyValue.value
              }

              if (defaultTaxonomyValues.includes(label)) {
                this.classificationCounts[taxonomy].push({ label, value, count: 1 })
              }
            }
          }
        }
      },
      startAgent (agent) {
        if (agent.name === 'lookalike_agent') {
          runSimilarityAgentForActor(this.actor.id, agent.action, this.selectedFilters)
            .then(response => {
              this.agent.status = 'queued'
            })
            .catch()
        } else {
          runAgentForActor(this.actor.id, agent.action)
            .then(response => {
              this.agent.status = 'queued'
            })
            .catch()
        }

        trackHeapEvent('agents.manualTrigger.' + agent.action)
      },
      getFinishedMessage (agent) {
        if (!agent.suggestions || agent.suggestions.length === 0) {
          return 'Last ran at ' + agent.updated_at + ' - No new suggestions.'
        } else if (!agent.suggestions) {
          return 'Last ran at ' + agent.updated_at
        }

        return 'Last ran at ' + agent.updated_at + ' - ' + this.numberOfSuggestions + ' new suggestions' + `${this.agent.name === 'lookalike_agent' ? ' for the selected filters.' : '.'}`
      },
      async acceptSuggestion (suggestion) {
        this.processingSuggestion = true

        const data = [{
          id: suggestion.suggestionId,
          status: 'accepted'
        }]

        try {
          await Suggestions.post(data)

          var suggestions = [...this.suggestions]
          var suggestionId = suggestion.suggestionId

          suggestions.forEach(function (suggestion) {
            if (suggestion.suggestionId == suggestionId) {
              suggestion.state = 'accepted'
              suggestion.statusMessage = suggestion.name + ' has been successfully added.'
            }
          })

          this.$emit('update:suggestions', suggestions)

          // Update the actor detail, accepting a suggestion from an agent usually means adding a new connection
          // so we need to update the connections of the actor we're currently on
          this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTOR_DETAIL, this.$route.params.id)

          this.processingSuggestion = false
        } catch (errors) {
          console.log(errors)
        }
      },
      async declineSuggestion (suggestion) {
        this.processingSuggestion = true

        const data = [{
          id: suggestion.suggestionId,
          status: 'denied'
        }]

        try {
          await Suggestions.post(data)

          var suggestions = [...this.suggestions]
          var suggestionId = suggestion.suggestionId

          suggestions.forEach(function (suggestion) {
            if (suggestion.suggestionId == suggestionId) {
              suggestion.state = 'denied'
              suggestion.statusMessage = suggestion.name + ' has been removed as a suggested connection.'
            }
          })

          this.$emit('update:suggestions', suggestions)
        } catch (errors) {
          //
        }

        this.processingSuggestion = false
      }
    },
    mounted () {
      this.showDefaultTaxonomiesForActor()
    },
    watch: {
      suggestions (v) {
        if (this.firstTimeSuggestionsAreUpdated || this.agent.name !== 'lookalike_agent') {
          return
        }

        this.updateSelectedFilters()
      }
    },
    mixins: [TranslationsMixin],
    components: {
      AgentSuggestion,
      BadgeCounter,
      Checkbox,
      SimilarActorsFilterSection
    }
  }
</script>

<style lang="scss">
  @import "../../../../scss/variables";

  .agent-card__button {
    margin-left: -1em;
    margin-top: -0.8em;
  }

  .agent-card {
    border: 1px solid white;
    box-shadow: 0 0 3px rgba(0, 0, 0, .1);
    margin-bottom: 10px;
    position: relative;

    .counter__badge {
      box-shadow: 0 0 3px red;
      right: -8px;
      top: -8px;
    }

    &.is-enabled {
      border-color: $color-text-grey;
    }

    .agent-card-body {
      display: flex;
      padding: 10px;
    }

    .radio {
      margin-right: 5px;
      margin-top: 0;
    }

    .agent-name {
      margin-bottom: 3px;
      text-transform: uppercase;
    }

    .agent-description {
      font-style: italic;

      &.is-disabled {
        color: $color-text-grey-light;
      }
    }

    .footer-toggle {
      display: block;
    }

    .agent-card-footer {
      border-top: 1px solid $color-text-grey;
      padding: 5px;
      text-align: center;
    }

    .loading-indicator {
      align-items: center;
      display: flex;
      font-weight: 500;
      justify-content: center;

      .svg-icon {
        margin-left: -10px;
        margin-right: 3px;
      }
    }
  }
</style>

<style lang="scss" scoped>
  @import "../../../../scss/_variables.scss";

  .similarity-buttons__container {
    display: flex;
    padding: 3px 0 5px 0;
    font-size: 12px;


    .similarity-button {
      cursor: pointer;
      margin-right: 5px;
      color: $color-borders;
      font-size: 12px;

      &.selected {
        text-decoration: underline;
        color: var(--primary);
        font-weight: 500;
      }
    }

    .classification-button {
      border: 2px dashed $color-borders;
      cursor: pointer;
      padding: 3px 7px;
      margin-right: 10px;
      color: $color-borders;
      font-size: 12px;

      &.selected {
        border-color: var(--primary);
        border-style: solid;
        color: var(--primary);
      }
    }
  }
</style>
