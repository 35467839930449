<template>
  <div v-if="upcomingEvents.length > 0" :style="'position: relative !important'">
    <div class="call-to-action-top-bar-parent" :style="{ backgroundColor: backgroundColorStyle }">
      <div class="call-to-action-top-bar">
        <div class="top-bar__upcoming-event-wrapper">
          <div class="top-bar__upcoming-event">
            <router-link :to="getAnnouncementRouterLink(upcomingEvents[0], false)">
              <div>Upcoming event: <b>'{{ upcomingEvents[0].title }}'</b></div>

              <div class="source__avatar" v-if="upcomingEvents[0].user && upcomingEvents[0].user.photo_url">
                <img :src="upcomingEvents[0].user.photo_url" :alt="'test'">
              </div>

              <div v-html="getCountdownDate(upcomingEvents[0])"></div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { trackHeapEvent } from '../../util/analytics.js'
  import UiMixin from '../../util/UiMixin.js'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import AnnouncementMixin from '../../util/AnnouncementMixin.js'
  import NavigationMixin from '../../util/NavigationMixin.js'
  import OnboardingMixin from '../../util/OnboardingMixin.js'
  import Avatar from '../Avatar/Avatar.vue'
  import { fetchPublicNotifications } from '../../api/notifications.js'
  import { getEventTimeDifference } from '../../util/date.js'

  export default {
    props: {
    },
    data () {
      return {
        backgroundColorStyle: 'white',
        borderBottomStyle: '1px solid #CECECE',
        upcomingEvents: [],
      }
    },
    computed: {
      ecosystemName () {
        return this.$store.getters.ecosystemDisplayName
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      config () {
        return this.$store.state.config
      },
    },
    created () {
    },
    mounted () {
      this.fetchUpcomingEvent()
    },
    methods: {
      getEventTimeDifference,
      getCountdownDate (post) {
        return this.getEventTimeDifference(post.start_date, post.end_date, true)
      },
      trackHeapEvent,
      fetchUpcomingEvent () {
        if (!this.$store.getters.canUsePublicAnnouncements) {
          return {}
        }

        return fetchPublicNotifications(1, 0, { is_event: true, upcoming_events: true })
          .then(response => {
            this.upcomingEvents = response
          })
          .catch(error => {
            console.log(error)
          })
      },
    },
    mixins: [UiMixin, TranslationsMixin, AnnouncementMixin, NavigationMixin, OnboardingMixin],
    components: {
      Avatar,
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables.scss";

  .source__avatar {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 0.25rem;
    border-radius: 50%;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .top-bar__upcoming-event-wrapper {
    padding: 18px 10px 18px;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid rgb(206, 206, 206);
    height: calc(69px + 1px);

    .top-bar__upcoming-event {
      a {
        line-height: 32px;
        text-decoration: none;
        color: #323441;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 10px;
        // border: 1px solid transparent;
      }
    }
  }
</style>
