<template>
  <span>{{formattedCurrency}}</span>
</template>

<script>
  import numeral from 'numeral';

  export default {
    name: 'Currency',
    props: {
      amount: Number,
      format: {
        type: String,
        default: '$ 0.00 a',
      }
    },
    computed: {
      formattedCurrency() {
        return numeral(this.amount).format(this.format);
      }
    }
  }
</script>

