<template>
  <div class="exit-round">
    <div class="exit-round__header col-xs-12">
      <span>{{label}}</span>
      <ds-button  icon="trash" variant="minimal" @click="handleClickRemove" class="exit-round__delete-button col-xs-1" />
    </div>
    <div class="row">
      <form-group :label="$t('actor_edit_type')" class="col-xs-7">
        <dropdown :options="types" v-model="data.type" />
      </form-group>
      <form-group :label="$t('actor_edit_exit_at')" class="col-xs-5">
        <div class="exit-round__date-input">
          <div class="col-xs-6"  style="padding: 0px">
            <dropdown :options="months" v-model="startMonth" />
          </div>
          <div class="col-xs-6" style="padding: 0px">
            <dropdown :options="years" v-model="startYear" />
          </div>
        </div>
      </form-group>
    </div>
    <div class="row">
      <form-group :label="$t('actor_edit_acquisition_total')" class="col-xs-6" >
        <div class="exit-round__money-input">
          <dropdown :options="currencies" v-model="data.original_currency" />
          <ds-input type="amount" precision="5" v-model="data.original_capital_raised" />
        </div>
      </form-group>
    </div>
    <div class="row" v-if="data.type == 'acquisition' || 'merger'">
      <form-group v-if="userCanAddNewActors" class="col-xs-12 form-group--investors" :label="$t('actor_edit_acquired_by')" :errors="errors.name">
        <div class="actor-edit__new-activity-container" style="margin-bottom: 1em; display: flex;">
          <suggestion-input
            icon="search"
            ref="investorInput"
            :api-settings="suggestionSettings"
            :clear-on-select="false"
            @on-select="createNewRelation($event)"
            @update:modelValue="investorCustomInput = $event"
            @cancelCustomInput="investorCustomInput = null"
          />
          <ds-button
            type="button"
            icon="plus"
            variant="add-actor"
            @click="addNewInvestorNewActor"
            style="margin-left: 4px; line-height: 30px;"
            v-tooltip.left="'Add new actor'"
          />
        </div>
      </form-group>
      <div class="col-xs-12 form-group--investors">
        <div class="actor-edit__relationships">
          <template v-for="(acquirer, index) in acquirers">
            <relation-edit :from="parentData.name" :type="relationType.name" :model-value="acquirer"
                           @remove="handleRemoveRelation(relationType.name, index)" />
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import numeral from 'numeral'

  import FormGroup from '../Form/FormGroup.vue'
  import Dropdown from '../Dropdown/Dropdown.vue'
  import DsInput from '../Form/DsInput.vue'
  import SuggestionInput from '../SuggestionInput/SuggestionInput.vue'
  import RelationEdit from '../Relation/Edit.vue'

  import { fetchProfile } from '../../api/user.js'
  import { suggestionSettings, createActor, updateActor } from '../../api/actors'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui';
  import MODAL_IDS from '../../constants/modal-ids';

  import escapeHTML from '../../util/escapeHTML'
  import CompanyMixin from '../../util/CompanyMixin'
  import { trackHeapEvent } from '../../util/analytics'

  export default {
    props: ['types', 'data', 'modelValue', 'acquirers', 'label', 'parentData'],
    data() {
      return {
        currencies: ['AUD', 'CAD', 'CHF', 'EUR', 'GBP', 'JPY', 'USD', 'ZAR'],
        months: moment.localeData().monthsShort(),
        years: new Array(20).fill('').map((y, index) => +moment().format('YYYY') - index),
        lastSetValue: null,
        noResultMessage: {
          text: 'Other',
          smallText: 'Create new investor'
        },
        showNewAcquirer: false,
        newAcquirerData: {
          name: '',
          actor_type: 'LegalEntity',
          company_type: 'Headquarter',
          category: 'Company'
        },
        relationType: {
          name: 'acquired_by',
          label: 'has been acquired by'
        },
        errors: {},
        busy: false,
        actorSuggestionSettings: {
          apiSettings: {
            url: '/actors/autocomplete?query={query}',
            onResponse: data => ({
              results: Object.values(data).map(({ name, logo, domain }) => ({
                name,
                logo,
                url: domain
              }))
            })
          },
          showNoResults: false,
          renderResults (response) {
            return response.results.map(r => {
              return `
                <a class="result company-suggestion">
                  <img class="company-suggestion__avatar" src="${r.logo}" />
                  <div class="company-suggestion__info">
                    <div class="company-suggestion__title">${escapeHTML(r.name)}</div>
                    <div class="company-suggestion__domain">${escapeHTML(r.url)}</div>
                  </div>
                </a>
              `
            })
          }
        },
        investorCustomInput: null,
      }
    },
    computed: {
      suggestionSettings,
      isMember () {
        return this.$store.getters.isMember
      },
      isEcosystemMember () {
        return this.$store.getters.isActor
      },
      isPortfolioMember () {
        return this.$store.getters.isPortfolioMember
      },
      enableSuggestions () {
        return this.$store.getters.canPremiumEnrich && this.parentData.actor_type === 'LegalEntity'
      },
      startMonth: {
        get () {
          return this.months[parseInt(this.data.started_at.slice(5, 7), 10) - 1]
        },
        set (v) {
          // If the start date is set in the front-end, that means that we know very specifically when
          // a round occured, set the ended_at property to the new date as well
          this.data.started_at = moment(this.data.started_at).month(v).format('YYYY-MM-DD')
          this.data.ended_at = this.data.started_at
        }
      },
      startYear: {
        get () {
          return parseInt(this.data.started_at.slice(0, 4), 10)
        },
        set (v) {
          // If the start date is set in the front-end, that means that we know very specifically when
          // a round occured, set the ended_at property to the new date as well
          this.data.started_at = moment(this.data.started_at).year(v).format('YYYY-MM-DD')
          this.data.ended_at = this.data.started_at
        }
      },
      /*investorOptions () {
        return (this.acquirers || []).map(i => { return i.name })
      }*/
    },
    methods: {
      handleClickRemove() {
        this.$emit('remove')
      },
      addNewInvestorNewActor () {
        trackHeapEvent('exitRound.addNewActorInvestor', {name: this.investorCustomInput || ""});

        const modalContext = {
          prefilled: {
            actor_type: 'LegalEntity',
            company_type: 'Headquarter',
            category: 'Company',
            name: this.investorCustomInput || "",
          },
          successCallback: actor => {
            this.createNewRelation(actor)
          },
        }

        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, modalContext)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)
      },
      resetErrors () {
        this.errors = {}
      },
      handleErrors (errors) {
        this.errors = errors || {}
      },
      createNewRelation (actor) {
        if (! this.parentData) {
          console.error('The parent actor id must be passed');

          return;
        }

        const relation = this.parentData[this.relationType.name] || [];

        var relatedActor = {
          from: this.parentData.id,
          from_name: this.parentData.name ,
          to: actor.data ? actor.data.id : actor.id,
          to_name: actor.data ? actor.data.name : actor.name,
          start: null,
          end: null
        };

        relation.push(relatedActor);

        updateActor({
          id: this.parentData.id,
          data: {
            [this.relationType.name]: relation
          }
        }).then(data => {
          // console.log(data)
          this.showNewAcquirer = false
        });
      },
      handleRemoveRelation(relationType, index) {
        if (index !== undefined) {
          this.parentData[relationType].splice(index, 1)
        }
      },
    },
    watch: {
      showNewAcquirer (state) {
        if (state == true) {
          this.resetErrors()
        }
      }
    },
    mixins: [CompanyMixin],
    components: {
      FormGroup,
      Dropdown,
      DsInput,
      SuggestionInput,
      RelationEdit
    }
  }
</script>
