import { followSpottingArea, unfollowSpottingArea } from '../api/exploration.js'
import { MUTATION_TYPES } from '../store/modules/user-helpers.js'
import { splitCtaText } from '@/util/helpers.js'

export default {
  computed: {
    config () {
      return this.$store.state.config
  },
    isOwner () {
      return this.$store.getters.isOwner
    },
    isLoggedIn () {
      return this.$store.getters.isLoggedIn
    },
    isTeamMember () {
      return this.$store.getters.isTeamMember
    },
    isPortfolioMember () {
      return this.$store.getters.isPortfolioMember
    },
    followedSpottingAreaIds () {
      return this.$store.state.user.profile.conceptSearches || []
    },
    spottingAreaIsFollowed () {
      const map = {}

      for (const id of this.followedSpottingAreaIds) {
        map[id] = true
      }

      return map
    },
    spottingAreaSplitCtaText () {
      if (!this.isLoggedIn) {
        return splitCtaText('This content is not available. Please login')
      }

      return splitCtaText(this.spottingAreaIntelligenceCtaUpgradeText)
    },
    spottingAreaIntelligenceCtaUpgradeText () {
      if (this.spottingAreaShowIntelligenceUpgradeCTA) {
        return this.config.packageCTAs.intelligence.text || this.$t('default_intelligence_upgrade_cta')
      }
      return ''
    },
    spottingAreaShowIntelligenceUpgradeCTA () {
      if (!this.isLoggedIn) {
        return !this.spottingAreaDisplayExploreContent

      }
      return this.isLoggedIn && !this.spottingAreaDisplayExploreContent && this.config.packageCTAs && this.config.packageCTAs.intelligence && this.config.packageCTAs.intelligence.enabled
    },
    spottingAreaDisplayExploreContent () {
      const hasAccess = (this.$store.getters.hasAccessToExploration || this.$store.getters.hasAccessToMonitoring)

      if (!this.isLoggedIn) {
        return  hasAccess && this.config.viewDashboard && this.config.viewDashboard.includes('explore-content')
      }

      return this.$store.getters.isLoggedIn && hasAccess && this.config.viewDashboard && this.config.viewDashboard.includes('explore-content')
    },
  },
  methods: {
    followSpottingArea (id) {
      if (!this.followedSpottingAreaIds.includes(id)) {
        this.$store.commit(MUTATION_TYPES.UPDATE_FOLLOWED_SEARCHES, [...this.followedSpottingAreaIds, id])
      }

      followSpottingArea(id)
    },
    unfollowSpottingArea (id) {
      if (this.followedSpottingAreaIds.includes(id)) {
        this.$store.commit(MUTATION_TYPES.UPDATE_FOLLOWED_SEARCHES, this.followedSpottingAreaIds.filter(x => x !== id))
      }

      unfollowSpottingArea(id)
    },
    isSpottingAreaAccessibleForUser (id) {
      // always accessible for owners, team members and portfolio members
      if (this.isOwner || this.isTeamMember || this.isPortfolioMember) {
        return true
      }

      const spottingAreaScope = this.$store.state.config.spottingAreaScope

      return spottingAreaScope.filter(s => s.id !== id).length > 0
    },
  },
}
