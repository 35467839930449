<template>
  <div class="fixed-heading">
    <div class="heartbeat-container">
      <div>
        <div v-if="true" class="dropdown-communities">
          <community-dropdown
            v-if="hasAccessToNewCommunities"
            :initial-community-id="filters.community"
            @selected-community-changed="updateCommunityFilter"
          />
        </div>
        <div class="dropdown">
          <Dropdown
            @update:modelValue="changeDate($event)"
            :options="availableMonths"
            v-model="currentDate"
          />
        </div>
      </div>

      <HeartbeatUsers
        :users="insights.users"
        :loading="trafficLoading"
        :traffic="insights.traffic"
        :date="currentDate"
      />

      <HeartbeatActors
        :actors="insights.actors" :claims="insights.claims"
        :most-popular-actors="insights.actors.most_popular_actor_pages"
      />
      <!--                       :most-popular-products="insights.actors.most_popular_actor_pages"/>-->
      <HeartbeatInteract
        :posts="insights.posts"
        :postsInteractions="insights.postsInteractions"
        :most-popular-pages="insights.posts.most_popular_pages"
      />
      <HeartbeatInform
        :inform="insights.inform"
        :most-popular-resources="insights.inform.most_popular_resources"
      />
      <HeartbeatUserBehaviour
        v-if="isOwner"
        :behaviour="userBehaviour"
        :default-user-behaviour="defaultUserBehaviour"
        :date="currentDate"
        @user-behaviour-analytics-user-changed="fetchUserBehaviourInsights"
        @fetch-user-behaviour="fetchUserBehaviour"
      />
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import Dropdown from '../Dropdown/Dropdown.vue'
  import HeartbeatDataQuality from './Heartbeat/HeartbeatDataQuality.vue'
  import HeartbeatUsers from './Heartbeat/HeartbeatUsers.vue'
  import HeartbeatActors from './Heartbeat/HeartbeatActors.vue'
  import HeartbeatInteract from './Heartbeat/HeartbeatInteract.vue'
  import HeartbeatTraffic from './Heartbeat/HeartbeatTraffic.vue'
  import HeartbeatInform from './Heartbeat/HeartbeatInform.vue'
  import { fetchDefaultUserBehaviour, fetchInsights, fetchUserInsights } from '../../api/heartbeat'
  import CommunityDropdown from '../Communities/CommunityDropdown.vue'
  import { fetchCommunity } from '../../api/communities'
  import { defineComponent } from 'vue'
  import HeartbeatUserBehaviour from '@/components/Settings/Heartbeat/HeartbeatUserBehaviour.vue'

  export default defineComponent({
    data () {
      return {
        currentDate: '',
        selectedCommunity: {},
        insights: {
          traffic: null,
          users: null,
          claims: {},
          actors: {},
          posts: {},
          inform: {},
          postsInteractions: {},
          data_quality: null,
        },
        userBehaviour: [],
        defaultUserBehaviour: [],
        dayDateOption: null,
        filters: {
          community: '',
        },
        trafficLoading: false,
        userBehaviourFilters: {
          user_id: null,
          range: null,
          specificDate: null,
        },
      }
    },
    created () {
      if (this.$route.query && this.$route.query.heartbeat_date) {
        this.currentDate = this.$route.query.heartbeat_date
      } else {
        this.currentDate = this.availableMonths[0].value
      }

      this.filters.community = this.$route && this.$route.query && this.$route.query.community

      this.fetchInsights()

      this.$router.replace({}) // The users page can have some query params that are being used as on-load filters, after fetching data we reset those query params
    },
    computed: {
      isOwner () {
        return this.$store.getters.isOwner
      },
      isDeveloper () {
        return this.$store.getters.isDeveloper
      },
      isUserOfDataScoutsTeam () {
        return this.$store.getters.isUserOfDataScoutsTeam
      },
      hasAccessToNewCommunities () {
        return this.$store.getters.hasAccessToCommunities
      },
      availableMonths () {
        const values = []
        const toDate = moment()
        if (this.selectedCommunity && this.selectedCommunity.id && this.selectedCommunity.id === this.filters.community) {
          for (
            let date = moment(this.selectedCommunity.created_at).startOf('month');
            date.diff(toDate, 'days') <= 0;
            date.add(1, 'month')
          ) {
            values.push({
              label: moment(date).format('MMMM YYYY'),
              value: `${moment(date).format('YYYY-MM')}-01`,
            })
          }

          values.sort(function (a, b) {
            if (a.value > b.value) {
              return -1
            } else {
              return 1
            }
          })

          return values
        } else {
          for (
            let date = moment(window.config.created_at).startOf('month');
            date.diff(toDate, 'days') <= 0;
            date.add(1, 'month')
          ) {
            values.push({
              label: moment(date).format('MMMM YYYY'),
              value: `${moment(date).format('YYYY-MM')}-01`,
            })
          }

          values.sort(function (a, b) {
            if (a.value > b.value) {
              return -1
            } else {
              return 1
            }
          })

          return values
        }
      },
    },
    methods: {
      async updateCommunityFilter (community) {
        if (!community) {
          this.filters.community = null
          this.selectedCommunity = null
        } else {
          this.filters.community = community.value

          this.selectedCommunity = await fetchCommunity({ communityId: this.filters.community })
        }

        this.fetchInsights()
      },
      changeDate (val) {
        this.currentDate = val

        if (this.userBehaviourFilters.range === 'Monthly') {
          this.fetchUserBehaviourInsights(null)
        }

        this.fetchInsights()
      },
      fetchInsight: async function (key) {
        var loadingKey = key + 'Loading'
        this[loadingKey] = true

        this.insights[key] = await fetchInsights(key, this.currentDate, this.filters.community ? this.filters.community : '')

        if ((key === 'claims' || key === 'actors') && this.insights[key].length === 0) {
          // Default values are empty objects, that's what the template components expect
          this.insights[key] = {}
        }

        this[loadingKey] = false
      },
      fetchInsights () {
        for (const key of Object.keys(this.insights)) {
          this.fetchInsight(key)
        }
      },
      async fetchUserBehaviourInsights (data) {
        if (data) {
          this.userBehaviourFilters.user_id = data.user_id
          this.userBehaviourFilters.range = data.range
          this.userBehaviourFilters.specificDate = data.specificDate
        }
        if (data.user_id) {
          this.userBehaviour = await fetchUserInsights('userBehaviour', this.currentDate, this.userBehaviourFilters.user_id, this.userBehaviourFilters.range, this.userBehaviourFilters.specificDate)
        }
      },
      async fetchUserBehaviour (data) {
        this.defaultUserBehaviour = await fetchDefaultUserBehaviour(data.user_id)
      },
    },
    beforeUnmount () {
    },
    components: {
      HeartbeatUserBehaviour,
      Dropdown,
      HeartbeatActors,
      HeartbeatInteract,
      HeartbeatDataQuality,
      HeartbeatTraffic,
      HeartbeatUsers,
      HeartbeatInform,
      CommunityDropdown,
    },
  })
</script>

<style lang="scss">
  @import '../../../scss/variables';

  .dropdown {
    position: absolute;
    right: 15px;
    top: 20px;;
    width: 200px;
  }

  .dropdown-communities {
    position: absolute;
    right: 270px;
    top: 20px;
    width: 200px;
  }

  .heartbeat-container {
    align-self: center;
    max-width: 1200px;
    width: 100%;

    .chart-container {
      display: flex;
      position: relative;
    }

    hr {
      background-color: $color-borders;
      border: 0;
      height: 1px;
      margin: 20px 0;
    }

    .chart-main {
      flex: 1;
      max-width: 100%;
      padding-right: 226px;

      &.chart-main-right {
        padding-left: 226px;
        padding-right: 0;
      }
    }

    .chart-aside {
      position: absolute;
      right: 0;
      top: 0;
      width: 200px;

      &.chart-aside-left {
        left: 0;
        right: initial;
      }
    }
  }
</style>
