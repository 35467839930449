import { propertyGroups } from '../constants/config'

export function actorLogo (actor) {
  // Check if the logo property is present
  // if not, try clearbit, if that fails, get the default image
  if (actor.logo) {
    return actor.logo.startsWith('http') || actor.logo.startsWith('/') ? actor.logo : 'https://public.datascouts.eu/' + actor.logo
  }

  // I get the response back here...should be the return value of either error or success?
  if (actor.url) {
    return 'https://logo.clearbit.com/' + extractDomain(actor.url)
  }
}

export function extractDomain (url) {
  var domain = url
  if (url.indexOf('://') > -1) {
    domain = url.split('/')[2]
  }
  return domain.split('/')[0]
}

export function emptyActor () {
  const actor = {}
  const props = [].concat.apply([], Object.values(propertyGroups))
  for (var i = props.length - 1; i >= 0; i--) {
    actor[props[i]] = null
  }

  // funnel stage is not in the property groups as it's an "always private" property
  actor['funnel_stage'] = null

  return Object.assign(actor, {
    activities: [],
    expertises: [],
    motivations: [],
    sustainability_goal: [],
    domains: [],
    funding_structure: [],
    subsidiaries: [],
    tags: [],
    technology: [],
    industries: [],
    subIndustries: [],
  })
}

export function emptyAddress () {
  return {
    city: null,
    country: null,
    country_code: null,
    locality: null,
    number: null,
    street: null,
    zip: null,
  }
}
