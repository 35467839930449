import { defineComponent } from 'vue'
import { Community } from '@/Domain/Community/types'
import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '@/store/modules/filters'

export default defineComponent({
  data() {
    return {
      accessLevelOptions: [
        { value: 'any_user', label: 'Any registered user' },
        { value: 'user_with_claims', label: 'Users with a claimed profile' },
      ],
      visibilityOptions: [
        { value: 'public', label: 'Public' },
        { value: 'private', label: 'Private' },
      ],
    }
  },
  computed: {
    doesUserHaveFullAccessToCommunity(): boolean {
      if (!this.$store.getters.isLoggedIn) {
        return false
      }

      if (this.isMember) {
        return true
      }

      if (this.isLoggedInUserCommunityManager) {
        return true
      }

      return this.$store.getters.joinedCommunityIds.includes(this.community?.id) && this.hasCommunityAccessBasedOnAccessLevel(this.community)
    },
    displayRequestAccessButton(): boolean {
      return !this.$store.getters.joinedCommunityIds.includes(this.community?.id)
    },
    hasUserRequestedAccessToCommunity(): boolean {
      return this.$store.getters.requestedToJoinCommunityIds.includes(this.community?.id)
    },
    hasAccessToCommunities(): boolean {
      return this.$store.getters.hasAccessToCommunities
    },
    hasAccessToAnnouncementsAndEventsForCommunities(): boolean {
      return this.$store.getters.hasAccessToAnnouncementsAndEventsForCommunities
    },
    canCreateAnnouncement(): boolean {
      return this.doesUserHaveFullAccessToCommunity && this.$store.getters.canCreateAnnouncement
    },
    displayCommunityKnowledgeBase(): boolean {
      return this.doesUserHaveFullAccessToCommunity && this.$store.getters.hasAccessToKnowledgeBase && this.config.viewDashboard && this.config.viewDashboard.includes('knowledge-base')
    },
    displayCommunityAnnouncements(): boolean {
      return this.$store.getters.canUsePublicAnnouncements && this.doesUserHaveFullAccessToCommunity && this.hasAccessToAnnouncementsAndEventsForCommunities
    },
    areChallengesEnabled(): boolean {
      return this.$store.getters.areChallengesEnabled
    },
    displayCommunityChallenges(): boolean {
      return this.doesUserHaveFullAccessToCommunity && (
          this.$store.getters.canCreateChallenge ||
          this.$store.getters.viewableChallengeStatusses.length > 0 ||
          this.$store.getters.accessibleChallengeStatusses.length > 0
      )
    },
    canUserManageMembers(): boolean {
      return this.isMember || this.isLoggedInUserCommunityManager
    },
    isLoggedInUserCommunityManager(): boolean {
      return this.community?.communityManager?.id == this.$store.getters.userId
    },
    isMember(): boolean {
      return this.$store.getters.isMember
    },
    // Return all communities the user is either a verified member of or a community manager of
    joinedCommunities(): Community[] {
      return this.$store.state.communities.listData.data.filter((community: any) => {
        return this.$store.getters.joinedCommunityIds.includes(community.id)
      })
    },
    isCommunityManagerAndPortfolioManager(): boolean {
      return this.$store.state.communities.listData.data
          .filter((community: any) => {
            return community.communityManagerId === this.$store.getters.userId
          }).length > 0
    },
    communityId(): Number {
      return Number(this.$route.params.communityId)
    },
    communities(): Community[] {
      return this.$store.state.communities.listData.data
    },
  },
  methods: {
    hasCommunityAccessBasedOnAccessLevel(community: Community): boolean {
      if (community.accessLevel !== 'user_with_claims') {
        return true
      }

      if (this.isMember) {
        return true
      }

      return this.$store.getters.userClaims.length > 0
    },
    isCommunityManager(community: Community): boolean {
      return Number(community.communityManagerId) === Number(this.$store.getters.userId)
    },
    viewCommunityActors(): void {
      this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)
      this.$store.commit(FILTERS_MUTATION_TYPES.SET_PORTFOLIO, this.community.portfolioId)

      this.$router.push('/communities/' + this.community.id + '/actors')
    },
    viewCommunityMembers(): void {
      this.$router.push('/communities/' + this.community.id + '/members')
    },
    viewCommunityAnnouncements(): void {
      this.$router.push('/communities/' + this.community.id + '/announcements')
    },
    viewCommunityEvents(): void {
      this.$router.push('/communities/' + this.community.id + '/events')
    },
  },
})
