<template>
  <div class="chat-bot">
    <img :src="chatbotLogo" alt="Ecosystem Favicon"/>
    <div class="chat-bot-card">
      <div
        class="chat-bot-message"
      >
        <div class="chat-bot-message__user">
          <div>
            <b>{{ messageOriginLabel }}</b>
          </div>
        </div>

        <div class="chat-bot-message__answer" v-html="message.message"></div>

        <div class="chat-bot-related-sources" v-if="message.usedSources?.length">
          <template v-if="message.messageType === MessageType.organisationSearch">
            <ul>
              <li
                class="chat-bot-related-sources__source"
                :key="index"
                v-for="(source, index) in message.usedSources"
              >
                <template v-if="source.value.startsWith('http')">
                  <a :href="source.value" target="_blank">{{ source.label }}</a>
                </template>

                <template v-else>
                  <router-link :to="'/actors/' + source.value">{{ source.label }}</router-link>
                </template>
              </li>
            </ul>
          </template>

          <template v-else>
            <div>
              Related sources:
            </div>
            <ul>
              <li
                class="chat-bot-related-sources__source"
                :key="index"
                v-for="(source, index) in message.usedSources"
              >
                <a :href="source.label" target="_blank">{{ baseUrl(source.label) }}</a>
              </li>
            </ul>
          </template>
        </div>

        <div class="chat-bot-user-feedback-buttons" v-if="displayUserFeedbackButtons">
          <icon
            :name="message.userFeedback == 1 ? 'thumbs-up' : 'thumbs-up-outline'"
            class="icon"
            v-tooltip.bottom="'Good response'"
            @click="toggleFeedback(1)"
          />

          <icon
            :name="message.userFeedback == -1 ? 'thumbs-down-filled' : 'thumbs-down'"
            class="icon"
            :class="{'icon--active': true}"
            v-tooltip.bottom="'Bad response'"
            @click="toggleFeedback(-1)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import { defineComponent, PropType } from '~/vue'
  import { ChatMessage, ChatMessageSource } from '@/Domain/Chat/Types/types'
  import { baseUrl } from '@/util/helpers'
  import { updateChatFeedback } from '@/Domain/Chat/Api/chat'
  import { MessageType } from '@/Domain/Chat/Enums/enums'

  export default defineComponent({
    name: 'ChatMessageCard',
    props: {
      message: {
        type: Object as PropType<ChatMessage>,
        required: true,
      },
    },
    emits: ['feedback-updated'],
    computed: {
      MessageType() {
        return MessageType
      },
      chatbotCustomization() : any | null {
        return this.$store.getters.chatbotCustomization
      },
      displayUserFeedbackButtons(): boolean {
        return this.message.role === 'assistant'
      },
      messageOriginLabel(): string {
        switch (this.message.role) {
          case 'user':
            return 'You'
          case 'assistant':
            return this.chatbotCustomization?.assistantName || 'Answer'
          case 'system':
            return 'System'
          default:
            return ''
        }
      },
      usedSources(): ChatMessageSource[] {
        return (this.message.usedSources || [])
          .filter(source => source.value && source.label)
      },
      spottingAreaId() {
        return Number(this.$route.params.spottingAreaId)
      },
      userProfile() {
        return this.$store.state.user.profile
      },
      chatbotLogo(): string {
        if (this.message.role === 'user') {
          return this.userProfile.photo_url
        }

        if (this.chatbotCustomization?.assistantLogo) {
          return this.chatbotCustomization.assistantLogo
        }

        return '/images/minerva-logo.svg'
      },
    },
    methods: {
      baseUrl,
      toggleFeedback(feedback: number) {
        if (this.message.userFeedback === feedback) {
          feedback = 0
        }

        updateChatFeedback(this.message.conversationId, this.message.id, feedback)
          .then(() => {
            this.$emit('feedback-updated', { messageId: this.message.id, feedback })
          })
          .catch((error) => {
            console.log(error)
          })
      },
    },
  })
</script>

<style lang="scss" scoped>
  .chat-bot {
    display: flex;

    img {
      border-radius: 50%;
      width: 24px;
      height: 24px;
      margin-top: 0.9rem;
      margin-left: 0.5rem;
    }
  }

  .chat-bot-card {
    display: flex;
    flex-direction: column;

    .chat-bot-message {
      display: flex;
      flex-direction: column;
      padding: 1rem;

      .chat-bot-message__user {
        display: flex;
        align-items: center;

        b {
          color: #2C0076;
        }
      }
    }

    .chat-bot-related-sources {
      margin-top: 1rem;
    }

    .chat-bot-related-sources__source {
      list-style: disc;
      margin-left: 14px;
      font-size: 14px;
      color: #843DFF;

      a {
        color: #843DFF;
        text-decoration: underline;
      }
    }

    .chat-bot-user-feedback-buttons {
      display: flex;
      justify-content: flex-start;
      margin-top: 0.5rem;

      .svg-icon {
        width: 14px;
        height: 14px;
      }

      :deep(.icon--active) {
        fill: #843DFF;
        stroke: #843DFF;
      }
    }

    .icon {
      margin-right: 0.25rem;
      cursor: pointer;
    }
  }
</style>
