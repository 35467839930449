import { createJSONRequest } from '../../api/create-request.js'

export const fetchSustainabilityInformation = (actorId) => createJSONRequest({
  url: `/api/suggestions/sustainability-crawler/${actorId}`,
  method: 'GET',
})

export const approveActorConnection = (actorId, data) => createJSONRequest({
  url: `/api/suggestions/actor-connections/${actorId}`,
  method: 'POST',
  data,
})
