<template>
  <modal ref="modal" :id="modalId" @close="$emit('close')">
    <template v-slot:body>
      <div style="height: 150px;">
        <form-group :label="$t('detach_user_choose_candidate_to_transfer_data_to')">
          <dropdown :options="candidateUserToTransferDataTo" v-model="chosenCandidate"/>
        </form-group>
      </div>
    </template>
    <template v-slot:footer>
      <div v-if="chosenCandidate">
        <ds-button :disabled="leaving" :label="isOwnerRemovingOtherUser ? 'Transfer data and remove user' : $t('detach_user_and_transfer_data_confirm')" variant="primary"
          @click="leaveEcosystem()"/>
        <ds-button :disabled="leaving" :label="$t('detach_user_cancel')" variant="secondary" @click="close()"/>
        <p class="form-msg error" v-if="error" v-html="error"></p>
      </div>
    </template>
  </modal>
</template>

<script>
  import Dropdown from '../Dropdown/Dropdown.vue'
  import Modal from './Modal.vue'

  import { getCandidateUsersToTransferDataTo, leaveEcosystem } from '../../api/user.js'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import { inert } from '../../util/helpers.js'
  import { deleteUser } from '../../api/config.js'

  export default {
    data () {
      return {
        candidateUserToTransferDataTo: [],
        leaving: false,
        error: null,
        modalId: MODAL_IDS.USER_LEAVE_ECOSYSTEM_AND_TRANSFER_DATA,
        chosenCandidate: null,
        user: null
      }
    },
    computed: {
      isMember () {
        return this.$store.getters.isMember
      },
      isOwnerRemovingOtherUser () {
        return this.isMember && this.user && this.user.id
      }
    },
    methods: {
      async leaveEcosystem () {
        this.leaving = true
        this.error = null

        try {
          // This modal is used by either a user wanting to leave the ecosystem and transfer its data
          // or by an owner choosing to remove a user and transfer its data in the users overview
          if (this.isOwnerRemovingOtherUser) {
            await deleteUser(this.user.id, this.chosenCandidate)

            this.$bus.emit('userDeleted')
            this.close()
          } else {
            await leaveEcosystem(this.chosenCandidate)

            window.location.href = '/'
          }
        } catch (e) {
          console.log(e)

          this.leaving = false
          this.error = e.message
        }
      },
      close () {
        this.$refs.modal.close()
      }
    },
    async mounted () {
      if (this.isMember && this.$store.state.ui.modalContext && this.$store.state.ui.modalContext.user) {
        this.user = inert(this.$store.state.ui.modalContext.user)
      }

      let userId = ''

      if (this.user && this.user.id) {
        userId = this.user.id
      }

      const candidates = await getCandidateUsersToTransferDataTo(userId)

      if (candidates.length > 0) {
        this.candidateUserToTransferDataTo = candidates.map(candidate => {
          return {
            value: candidate.email,
            label: candidate.name
          }
        })

        this.chosenCandidate = this.candidateUserToTransferDataTo[0].value
      }
    },
    components: {
      Dropdown,
      Modal
    }
  }
</script>

<style scoped>

</style>
