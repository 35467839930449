<template>
  <ds-input
    icon="search"
    type="search"
    v-if="!noInput"
    ref="searchInput"
    v-model="query"
    :placeholder="placeholder || defaultPlaceholderText"
    @pressedEnter="displayGlobalSearch(query)"
  />
  <ds-button icon="search" variant="minimal" v-else @click="displayGlobalSearch('')"
             :label="placeholder || defaultPlaceholderText"/>
</template>

<script>
  import { trackHeapEvent } from '../../util/analytics'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import debounce from 'lodash/debounce'
  import UiMixin from '../../util/UiMixin.js'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import { SiteTemplate } from '../../store/modules/config.js'

  export default {
    name: 'TopBarGlobalSearch.vue',
    props: {
      noInput: Boolean,
      disabled: Boolean,
      placeholder: {
        type: String,
      },
    },
    data () {
      return {
        query: '',
      }
    },
    computed: {
      defaultPlaceholderText () {
        if (this.ui.isSmallMobile) {
          return this.$t('actor_detail_search')
        }
        return this.$t('top_bar_search_placeholder', {
          ecosystem: this.ecosystemDisplayName,
          products: this.productsLabel,
        })
      },
      ecosystemDisplayName () {
        return this.$store.getters.ecosystemDisplayName
      },
    },
    methods: {
      displayGlobalSearch (value) {
        if (this.disabled) {
          return
        }
        // Track the click
        if (value === this.query) {
          trackHeapEvent('topbar.clickGlobalSearch')
          this.$store.commit(UI_MUTATION_TYPES.UPDATE_GLOBAL_SEARCH_QUERY, value)
          this.$store.commit(UI_MUTATION_TYPES.SHOW_GLOBAL_SEARCH)
          this.query = ''
        }
      },
      displayGlobalSearchOnInput (value) {
        if (value) {
          debounce(function () {
            this.displayGlobalSearch(value)
          }, 400).call(this)
        }
      },
    },
    mixins: [UiMixin, TranslationsMixin],
    watch: {
      query (value) {
        this.displayGlobalSearchOnInput(value)
      },
    },
  }
</script>

<style scoped lang="scss">
  @import "../../../scss/variables.scss";

  .button {
    background-color: white;
    border-radius: 12px;
  }

  :deep(.button__label) {
    text-align: left;
    justify-content: flex-start;
    text-transform: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    color: $color-text-grey-light;
  }
</style>
