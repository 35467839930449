<template>
  <div class="collapsable-tab__content-container">
    <form-section>
      <div class="row" v-if="isRelevantForActor('url', modifiedActor) || isRelevantForActor('other_urls', modifiedActor)">
        <form-group :label="$t('edit_actor_segment_digital_presence_main_website')" :errors="errors.url ? errors.url[0] : ''" class="col-xs-12"
          :class="{'actor-edit__private-field': isPrivateField('url')}" v-if="isRelevantForActor('url', modifiedActor)">
          <a class="collapsable-tab__delete-all-button" @click="$emit('deleteAll', 'url')">{{ $t('edit_actor_delete_all') }}</a>
          <ds-input name="actor-website" v-model="modifiedActor.url"/>
        </form-group>
        <form-group :label="$t('edit_actor_segment_digital_presence_additional_website')" :errors="errors.other_urls" class="col-xs-12"
          :class="{'actor-edit__private-field': isPrivateField('other_urls')}" v-if="isRelevantForActor('other_urls', modifiedActor)">
          <a class="collapsable-tab__delete-all-button" @click="$emit('deleteAll', 'other_urls')">{{ $t('edit_actor_delete_all') }}</a>
          <tag-input newline name="actor-website" v-model="modifiedActor.other_urls"/>
        </form-group>
      </div>
      <div class="row">
        <form-group class="col-xs-12" label="Twitter" :errors="errors.twitter" :class="{'actor-edit__private-field': isPrivateField('twitter')}">
          <a class="collapsable-tab__delete-all-button" @click="$emit('deleteAll', 'twitter')">{{ $t('edit_actor_delete_all') }}</a>
          <tag-input newline icon="twitter-logo" name="actor-twitter-handle" v-model="modifiedActor.twitter" placeholder=""/>
        </form-group>
      </div>
      <div class="row">
        <form-group class="col-xs-12" label="Linkedin" :errors="errors.linkedin" :class="{'actor-edit__private-field': isPrivateField('linkedin')}">
          <ds-input icon="linkedin-logo" name="actor-linkedin-handle" v-model="modifiedActor.linkedin"/>
        </form-group>
      </div>
      <div class="row">
        <form-group class="col-xs-12" label="Facebook" :errors="errors.facebook" :class="{'actor-edit__private-field': isPrivateField('facebook')}">
          <a class="collapsable-tab__delete-all-button" @click="$emit('deleteAll', 'facebook')">{{ $t('edit_actor_delete_all') }}</a>
          <tag-input newline icon="facebook-logo" name="actor-facebook-handle" v-model="modifiedActor.facebook" placeholder=""/>
        </form-group>
      </div>
      <div class="row" v-if="isRelevantForActor('wikipedia', modifiedActor)">
        <form-group class="col-xs-12" label="Wikipedia" :errors="errors.wikipedia" :class="{'actor-edit__private-field': isPrivateField('wikipedia')}">
          <ds-input icon="wikipedia-logo" name="actor-wikipedia-handle" v-model="modifiedActor.wikipedia"/>
        </form-group>
      </div>
      <div class="row" v-if="isRelevantForActor('rss_feed', modifiedActor)">
        <form-group class="col-xs-12" label="RSS Feed" :errors="errors.rss_feed" :class="{'actor-edit__private-field': isPrivateField('rss_feed')}">
          <ds-input icon="rss" name="actor-rss-feed-handle" v-model="modifiedActor.rss_feed"/>
        </form-group>
      </div>
      <div class="row">
        <form-group class="col-xs-12" label="Instagram" :errors="errors.instagram" :class="{'actor-edit__private-field': isPrivateField('instagram')}">
          <a class="collapsable-tab__delete-all-button" @click="$emit('deleteAll', 'instagram')">{{ $t('edit_actor_delete_all') }}</a>
          <ds-input icon="instagram-logo" name="actor-instagram-handle" v-model="modifiedActor.instagram"/>
        </form-group>
      </div>
      <div class="row" v-if="show.addVimeo && isRelevantForActor('vimeo', modifiedActor)">
        <form-group class="col-xs-12" label="Vimeo" :errors="errors.vimeo" :class="{'actor-edit__private-field': isPrivateField('vimeo')}">
          <a class="collapsable-tab__delete-all-button" @click="$emit('deleteAll', 'vimeo')">{{ $t('edit_actor_delete_all') }}</a>
          <ds-input icon="vimeo-logo" name="actor-vimeo-handle" v-model="modifiedActor.vimeo"/>
        </form-group>
      </div>
      <div class="row" v-if="show.addYoutube && isRelevantForActor('youtube', modifiedActor)">
        <form-group class="col-xs-12" label="Youtube" :errors="errors.youtube" :class="{'actor-edit__private-field': isPrivateField('youtube')}">
          <a class="collapsable-tab__delete-all-button" @click="$emit('deleteAll', 'youtube')">{{ $t('edit_actor_delete_all') }}</a>
          <ds-input icon="youtube-play" name="actor-youtube-handle" v-model="modifiedActor.youtube"/>
        </form-group>
      </div>
      <div class="row">
        <form-group class="col-xs-12" label="Crunchbase" :errors="errors.crunchbase" :class="{'actor-edit__private-field': isPrivateField('crunchbase')}">
          <a class="collapsable-tab__delete-all-button" @click="$emit('deleteAll', 'crunchbase')">{{ $t('edit_actor_delete_all') }}</a>
          <ds-input icon="crunchbase-logo-letters" name="actor-crunchbase-handle" v-model="modifiedActor.crunchbase" placeholder=""/>
        </form-group>
      </div>
      <div class="row" v-if="showKennisWest">
        <form-group class="col-xs-12" label="KennisWest" :errors="errors.kenniswest">
          <a class="collapsable-tab__delete-all-button" @click="$emit('deleteAll', 'kenniswest')">{{ $t('edit_actor_delete_all') }}</a>
          <ds-input icon="west-flanders" name="actor-kenniswest-handle" v-model="modifiedActor.kenniswest" placeholder=""/>
        </form-group>
      </div>
      <div class="row">
        <form-group class="col-xs-12" label="Subreddit" :errors="errors.reddit" :class="{'actor-edit__private-field': isPrivateField('reddit')}">
          <a class="collapsable-tab__delete-all-button" @click="$emit('deleteAll', 'reddit')">{{ $t('edit_actor_delete_all') }}</a>
          <tag-input newline icon="reddit-logo" name="actor-reddit-handle" v-model="modifiedActor.reddit" placeholder=""/>
        </form-group>
      </div>
      <div class="col-xs-12 collapsable-tab__all-social-link-button-conatiner">
        <div class="collapsable-tab__social-link-button-conatiner" @click="show.addVimeo = !show.addVimeo">
          <ds-button icon="vimeo-logo" class="collapsable-tab__social-link" size="large" variant="outline"/>
          <div class="collapsable-tab__social-link-text">Vimeo</div>
        </div>
        <div class="collapsable-tab__social-link-button-conatiner" @click="show.addYoutube = !show.addYoutube">
          <ds-button icon="youtube-play" class="collapsable-tab__social-link" size="large" variant="outline"/>
          <div class="collapsable-tab__social-link-text">Youtube</div>
        </div>
      </div>
    </form-section>
  </div>
</template>

<script>
  import FormGroup from '../../Form/FormGroup.vue'
  import TagInput from '../../Form/TagInput.vue'
  import FormSection from '../../Form/FormSection.vue'

  import { isRelevantForActor } from '../../../util/helpers.js'

  export default {
    name: 'actor-edit-digital-presence',
    props: {
      modifiedActor: Object,
      show: Object,
      errors: Object,
    },
    data () {
      return {}
    },
    computed: {
      publicFields () {
        return this.$store.getters.publicFields
      },
      isPublisherEnabled () {
        return this.$store.getters.isPublisherEnabled
      },
      showKennisWest () {
        return ['local', 'own', 'pomwvl'].includes(this.$store.getters.ecosystemName.toLowerCase())
      },
    },
    methods: {
      isRelevantForActor,
      isPrivateField (field) {
        return this.isPublisherEnabled && !this.publicFields.includes(field)
      },
    },
    components: {
      FormGroup,
      TagInput,
      FormSection,
    },
    mixins: [],
  }
</script>
