<template>
  <div class="authentication-modal package-sign-up__credentials-step">
    <div class="personal-payment-info__container">
      <template v-if="!paymentMethod.id">

        <form class="form personal-payment-info__form" @submit.prevent="validate">
          <div class="personal-payment-info__cc-information">
            <h3 class="h3">Provide your credit card information</h3>

            <form-group label="Name on the card" style="margin-top: 15px;">
              <ds-input id="card-holder-name" v-model="nameOnCard" is-simplified/>
            </form-group>

            <!-- Stripe Elements Placeholder -->
            <form-group label="Credit card details" style="margin-bottom: 0px;" :instructions="instructions">
              <div id="card-element"></div>
            </form-group>

            <!--<ds-button label="Validate" id="card-button" :data-secret="stripeIntent" @click="validate">
            </ds-button>-->

            <span v-if="paymentMethodError">{{paymentMethodError}}</span>
          </div>

          <fieldset class="action-buttons">
            <ds-button id="card-button" :data-secret="stripeIntent" type="submit" size="small" label="Verify" :disabled="verifying || !nameOnCard"/>
          </fieldset>
        </form>
      </template>
    </div>
  </div>
</template>

<script>
  import { getStripeIntent } from '../../../api/user'

  export default {
    name: 'AskPaymentMethod.vue',
    data () {
      return {
        // Payment & Stripe information
        stripe_key: '',
        stripe: '', // Stripe elements requirements
        elements: '', // Stripe elements
        card: '', // Stripe element, card info NEVER touches our code
        nameOnCard: '',
        stripeIntent: '',
        paymentMethod: {},
        paymentMethodError: '',
        verifying: false,
        verified: false,
      }
    },
    computed: {
      instructions () {
        return 'Powered by <a href="https://stripe.com/privacy-center/legal" target="_blank">Stripe</a>'
      },
    },
    // All stripe related code, kudos to https://serversideup.net/using-stripe-elements-in-a-vuejs-component/
    methods: {
      // Validate card information via Stripe
      async validate () {
        this.verifying = true

        this.paymentMethodError = ''
        const { paymentMethod, error } = await this.stripe.createPaymentMethod(
          'card',
          this.card,
          {
            billing_details: { name: this.nameOnCard },
          },
        )

        if (error) {
          // Display "error.message" to the user...
          this.paymentMethodError = error.message || error
          this.verifying = false
        } else {
          // The card has been verified successfully...
          this.paymentMethod = paymentMethod

          this.$emit('updatePaymentMethod', this.paymentMethod)
        }
      },
      configureStripe () {
        this.stripe = window.Stripe(this.$store.getters.publicStripeKey)

        this.elements = this.stripe.elements()
        this.card = this.elements.create('card')

        // prevent attempting to mount after visiting a different page
        if (document.getElementById('card-element')) {
          this.card.mount('#card-element')
        }
      },
    },
    async mounted () {
      this.stripe_key = this.$store.getters.publicStripeKey

      try {
        this.stripeIntent = await getStripeIntent()

        this.configureStripe()
      } catch (e) {
        console.log('Failed to fetch a stripe intent: ' + e)
      }
    },
  }
</script>

<style scoped>

</style>
