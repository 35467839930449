<template>
  <div class="fixed-headings">
    <h3 class="h2">Content Api's</h3>
    <div class="guide_text">
      Configure external content api's that will be used to enrich data into your DataScouts platform.
    </div>
    <div class="content-api__container">
      <div class="toggle-button-content-api" v-if="canShowKennisWest">
        <toggle-button color="success" :enabled="contentApis.kennisWest"
          @update:modelValue="contentApis.kennisWest = $event;" :typeBold="false"
          textEnabled="ON" textDisabled="OFF"/>
        <h4>KennisWest</h4>
      </div>
    </div>

    <action-bar :editing="isEditing || isSaving">
      <ds-button variant="secondary" label="Save changes" @click="save"/>
      <ds-button variant="" label="Cancel" @click="cancel"/>
      <span class="action-bar__message" v-if="successMessage">{{ successMessage }}</span>
    </action-bar>
  </div>
</template>

<script>
  import ToggleButton from '../Form/ToggleButton.vue'
  import ActionBar from '../Form/ActionBar.vue'
  import { updateSettings } from '../../api/config'
  import { inert } from '../../util/helpers'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'ContentApis',
    data () {
      return {
        contentApis: {
          kennisWest: false,
        },
        oldContentApis: {
          kennisWest: false,
        },
        availableContentApis: [
          'kenniswest',
        ],
        successMessage: null,
        isSaving: false,
      }
    },
    computed: {
      canShowKennisWest () {
        return ['local', 'own', 'POMWVL', 'central'].includes(this.$store.getters.ecosystemName)
      },
      isEditing () {
        return this.contentApis.kennisWest !== this.oldContentApis.kennisWest
      },
    },
    methods: {
      fetchContentApiSettings () {
        if (window.config.contentApis && window.config.contentApis.kennisWest) {
          this.oldContentApis.kennisWest = window.config.contentApis.kennisWest
          this.contentApis.kennisWest = window.config.contentApis.kennisWest
        }
      },
      cancel () {
        this.isSaving = true
      },
      save () {
        this.isSaving = true

        updateSettings({ contentApis: this.contentApis })
          .then((config) => {
            this.successMessage = 'The configuration has been updated!'
            this.$store.commit('CONFIG_SET', inert(config))

            this.oldContentApis = config.contentApis
            this.contentApis = config.contentApis
          })
          .catch((err) => {
            console.log(err)
          })
          .finally(() => {
            this.isSaving = false
          })
      },
    },
    mounted () {
      this.fetchContentApiSettings()
    },
    components: {
      ToggleButton,
      ActionBar,
    },
  })
</script>

<style lang="scss" scoped>
  .content-api__container {
    margin-top: 20px;

    .toggle-button-content-api {
      display: flex;
      align-items: center;

      h4 {
        margin-left: 15px;
      }
    }
  }
</style>
