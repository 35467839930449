<template>
  <span class="benchmark-dropdown" v-click-away="closeDropdown">
    <ds-button
      :icon="open ? 'chevron-up' : 'chevron-down'"
      class="benchmark__dropdown__button"
      :label="label"
      :variant="open ? variant : 'outline'"
      :size="size"
      @blur="blur"
      @click="toggle"
      @focus="focus"
    />
    <div v-if="open" class="benchmark-dropdown__options benchmark-dropdown__duplicate-actors-options">
      <div class="scrollable benchmark-dropdown__background">
        <div class="row">
          <div v-for="(option,index) in options" class="col-xs-12 benchmark-dropdown__item-container" @focus="focus" @blur="blur">
            <a :href="'/actors/' + option.id" :target="newWindow ? '_blank' : '_self'" class="benchmark-dropdown__item-name col-xs-12"> {{ option.name }} </a>
            <div class="benchmark-dropdown__item-buttons col-xs-12">
              <ds-button class="benchmark-dropdown__item-merge-button" :disabled="busy.state && busy.index == index" :icon="busy.state && busy.index == index && busy.type == 'merge' ? 'spinner' : 'shuffle-variant'" label="Merge" variant="extra-small" @click="mergeActor(option, index)"/>
              <ds-button class="benchmark-dropdown__item-delete-button" :disabled="busy.state && busy.index == index" :icon="busy.state && busy.index == index && busy.type == 'delete' ? 'spinner' : 'trash'" label="Delete" variant="extra-small" @click="showDeleteConfirmationModal(option, index)"/>
            </div>
            <div class="navigation__divider"></div>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script>
import CheckboxButton from '../CheckboxButton/CheckboxButton.vue'

import _throttle from 'lodash/throttle'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'

import MODAL_IDS from '../../constants/modal-ids'

export default {
  props: {
    options: {
      type: Array,
      default: () => []
    },
    label: String,
    variant: String,
    id: String,
    icon: String,
    size: String,
    newWindow: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      open: false,
      portfolioQuery: null,
      hasFavourites: false,
      portfolioList: null,
      // The busy data point is used to controll the icons to be displayed in each button when one of the actions is triggered(merge/delete) and disable the buttons apon triggering one of them
      busy: {
        state: false,
        index: 0,
        type: ''
      },
    }
  },
  methods: {
    toggle () {
      this.open = !this.open || this.active
    },
    focus () {
      this.active = true
      this.open = true
      setTimeout(() => {
        this.active = false
      }, 500)
    },
    input (value) {
      this.$emit('update:modelValue', value)
    },
    blur () {
      setTimeout(() => {
        if (this.$el && document.activeElement && !this.$el.contains(document.activeElement)) {
          this.open = false
        }
      }, 0)
    },
    closeDropdown (event) {
      if (event.target.parentElement && event.target.parentElement.classList.value !== 'benchmark-dropdown') {
        this.open = false
      }
    },
    mergeActor (duplicateActor, index) {
      this.busy.state = true
      this.busy.index = index
      this.busy.type = 'merge'
      this.$emit('merge', duplicateActor)
    },
    showDeleteConfirmationModal (duplicateActor, index) {
      var modalContextData = Object.assign(duplicateActor, { index: index }, { modalContextType: 'actor' })
      this.$store.commit('UI/SET_MODAL_CONTEXT', modalContextData)
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
    },
    denyActorRecommendation (duplicateActor, index) {
      this.busy.state = true
      this.busy.index = index
      this.busy.type = 'delete'
      this.$emit('delete', duplicateActor)
    }
  },
  created () {
    // TODO: DO NOT LISTEN ON A GENERIC DELETE CONFIRMATION WITHOUT KNOWING 100% SURE THE DELETE CONFIRMATION WAS MEANT FOR THE ACTOR
    // Meaning: the confirmation needs to pass the ID for which the pop-up modal was presented to the user in the first place, check how it's done in the edit/scores side panel
    /* this.$bus.on('actorDeleteConfirmation', (data) => {
      this.removeActor(data, data.index)
    }) */
  },
  watch: {
    options () {
      // Once the component gets new options after either a merge or delete, the busy properties are reset
      this.busy.state = false
      this.busy.index = 0
      this.busy.type = ''
    }
  },
  components: {
    CheckboxButton
  }
}
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";
.hidden__option{
  display:none !important;
}

.benchmark-dropdown {
  position: relative;
  border: none;
  background: none;
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px $color-primary;
    .fill-fg {
      fill: $color-primary;
    }
  }
  &:hover .fill-fg {
    fill: $color-primary;
  }
  .button {
    text-align: left;
  }
  >.svg-icon {
    display: block;
  }
}
.benchmark-dropdown__duplicate-actors-options {
  max-width: 220px !important;
  .benchmark-dropdown__background {
    background-color: white !important;
    border: 1px solid $color-borders;
  }
}

.benchmark-dropdown__options {
  position: absolute;
  z-index: 2;
  height: 200px;
  top: 100%;
  margin-bottom: 50px;
  left: 0px;
  white-space: nowrap;
  font-size: 14px;
  text-align: left;
  background: $color-secondary;
  > .button {
    &:focus {
      z-index: 1;
    }
  }
  .svg-icon {
    width: 12px ;
    height: 12px ;
    path {
      fill: $color-primary;
    }
  }

  .benchmark-dropdown__background {
    background: $color-secondary;
  }

  .benchmark-dropdown__item-container {
    a {
      margin: 1em 0;
      display: block;
    }
    .benchmark-dropdown__item-merge-button, .benchmark-dropdown__item-delete-button {
      .svg-icon {
        left: 7px;
      }
    }
    .benchmark-dropdown__item-delete-button {
      .svg-icon {
        width: 18px;
        height: 18px;
        left: 4px;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 6px;
    height: 4px;
    background-color: $color-secondary;
    background: $color-secondary
  }

  ::-webkit-scrollbar-thumb {
    background-color: $color-background-grey;
    -webkit-border-radius: 1ex;
  }
}

.benchmark-dropdown__button {
  width: 95%;
  background-color: $color-background-grey !important;
  color: white !important;
  text-align: center !important;
  margin-bottom: 4px;
  margin-left: 4px;
  &:hover {
    background-color: rgba(255, 255, 255, .3);
  }
}

.benchmark-dropdown__button-container {
  background: $color-secondary;
  width: 100%;
}

.benchmark-dropdown__inactive_button {
  width: 95%;
  background-color: white !important;
  text-align: center !important;
  &:hover {
    background-color: rgba(255, 255, 255, .3);
  }
}
</style>
