import { state as config } from './config'

export const MUTATION_TYPES = {
  SET_EDIT_STATE: 'PORTFOLIO_EDIT/SET_EDIT_STATE',
  ACTOR_IDS: 'PORTFOLIO_EDIT/ACTOR_IDS',
  ACTOR_EXCLUDED_IDS: 'PORTFOLIO_EDIT/ACTOR_EXCLUDED_IDS',
  ADD_ACTOR: 'PORTFOLIO_EDIT/ADD_ACTOR',
  SET_ACTIVE_PORTFOLIO: 'PORTFOLIOS/SET_ACTIVE_PORTFOLIO',
  CLEAR_ACTIVE_PORTFOLIO: 'PORTFOLIOS/CLEAR_ACTIVE_PORTFOLIO'
}

export const mutations = {
  // Change the state of the portfolio when editing or creating a portfolio
  [MUTATION_TYPES.SET_EDIT_STATE] (state, portfolioState) {
    state.portfolio.isEditMode = portfolioState
  },

  // Add an actor to the portfolio "included actor ids"
  [MUTATION_TYPES.ACTOR_IDS] (state, actor) {
    if (!state.portfolio.actor_ids.includes(actor.id)) {
      state.portfolio.actor_ids.push(actor.id)
    }

    if (!state.portfolio.actors.filter(item => item.id == actor.id)) {
      state.portfolio.actors.push({ id: actor.id, name: actor.name })
    }

    // Remove the excluded Ids
    var indexExcludedIds = state.portfolio.actor_ids_excluded.indexOf(actor.id)
    if (state.portfolio.actor_ids_excluded.includes(actor.id)) {
      state.portfolio.actor_ids_excluded.splice(indexExcludedIds, 1)
    }
  },

  // Add an actor to the portfolio "excluded actor ids"
  [MUTATION_TYPES.ACTOR_EXCLUDED_IDS] (state, actor) {
    if (!state.portfolio.actor_ids_excluded.includes(actor.id)) {
      state.portfolio.actor_ids_excluded.push(actor.id)
    }

    // Remove the actor from the included ids if it was in there
    var indexActorsIds = state.portfolio.actor_ids.indexOf(actor.id)
    var indexActors
    state.portfolio.actors.forEach((item, index) => {
      if (item.id == actor.id) {
        indexActors = index
      }
    })

    if (state.portfolio.actor_ids.includes(actor.id)) {
      state.portfolio.actor_ids.splice(indexActorsIds, 1)
    }

    if (indexActors > -1) {
      state.portfolio.actors.splice(indexActors, 1)
    }
  },

  [MUTATION_TYPES.SET_ACTIVE_PORTFOLIO] (state, portfolio) {
    state.activePortfolio = Object.assign({}, { id: portfolio.id, name: portfolio.name })
    state.portfolio = {
      isEditMode: false,
      actor_ids: [],
      actor_ids_excluded: [],
      actors: [],
    }
  },

  [MUTATION_TYPES.CLEAR_ACTIVE_PORTFOLIO] (state) {
    state.activePortfolio = {}
    state.portfolio = {
      isEditMode: false,
      actor_ids: [],
      actor_ids_excluded: [],
      actors: [],
    }
  },
}

export const getters = {
  isEditMode (state) {
    return state.portfolio.isEditMode
  },
  actors (state) {
    return state.portfolio.actors
  },
  actorsIds (state) {
    return state.portfolio.actor_ids
  },
  excludedActorsIds (state) {
    return state.portfolio.actor_ids_excluded
  },
}

export default {
  state: {
    portfolio: {
      isEditMode: false,
      actor_ids: [],
      actor_ids_excluded: [],
      actors: [],
    },
    activePortfolio: {}
  },
  getters,
  mutations,
}
