<template>
  <div class="has-action-bar">
    <h2 class="h2">Email</h2>
    <form-group label="Email subscriptions">
      <checkbox label="Enable ecosystem newsletters" v-model="form.newsletter"/>
      <!--<checkbox label="Allow owners / team members to subscribe to weekly reports" v-model="ownerReports" />
      <div class="row" v-if="form.ownerReports" style="max-width:30rem;margin-top:10px;">
        <form-group label="Send weekly reports starting at" class="col-xs">
          <dropdown v-model="form.ownerReports.byweekday" :options="dayOptions" placeholder="Weekday" />
        </form-group>
        <form-group label=" " class="col-xs" instructions="timezone: Europe/Brussels">
          <ds-input v-model="form.ownerReports.byhour" />
        </form-group>
      </div>-->
    </form-group>

    <!-- @deprecated -->
    <!--
    <div class="row">
      <form-group class="col-xs-12" label="Newsletter SendGrid template">
        <ds-input v-model="form.newsletterTemplate" placeholder="Overrides the default template for newsletters" />
      </form-group>
    </div>-->

    <h2 class="h2">For all ecosystems</h2>
    <div class="row">
      <form-group class="col-xs-6" label="DS Google Analytics code">
        <ds-input v-model="form.google_analytics" placeholder="UA-..."/>
      </form-group>
      <form-group class="col-xs-6" label="DS Google Types Manager id">
        <ds-input v-model="form.google_tag_manager" placeholder="GTM-..."/>
      </form-group>
    </div>

    <template v-if="$store.getters.hasAccessToSharing">
      <h2 class="h2">Ecosystem Sync Configuration</h2>
      <h5>Configure the ecosystems that this ecosystem can sync with, including the fields and/or taxonomies that will be synced</h5>
      <div class="grouped-sync-config" v-for="(syncConfig, syncConfigKey) in this.form.ecosystemSyncConfigurations" :key="syncConfigKey">
        <div style="display:flex; flex-direction:row; justify-content:space-between;">
          <form-group style="width:250px;">
            <label>Choose target ecosystem:</label>
            <dropdown v-model="syncConfig.ecosystem" :options="sortedEcosystems" :disabled="true"/>
          </form-group>
          <form-group style="text-align: right;">
            <ds-button icon="trash" label="Remove configuration" @click="removeSyncConfig(syncConfig.ecosystem)" class="danger"/>
          </form-group>
        </div>
        <form-group>
          <label>Choose fields to sync:</label>
          <dropdown v-model="syncConfig.fields" :options="fields" multiple/>
          <ds-button size="small" label="Add all properties" @click="addAllProperties(syncConfig)"/>
        </form-group>
        <form-group>
          <label>Add a taxonomy to sync:</label>
          <div style="display:flex; flex-direction:row; justify-content:flex-start;">
            <dropdown v-model="syncConfig.newTaxonomy" :options="allowedTaxonomies(syncConfig)" style="width:250px;"/>
            <ds-button icon="plus" @click="addTaxonomyMappingToConfig(syncConfigKey, syncConfig)"/>
          </div>

          <table class="table table--has-button">
            <thead>
            <tr>
              <!--<th>#</th>-->
              <th by="name">Taxonomy</th>
              <th>Source ({{ currentEcosystemName }})</th>
              <th>Target ({{ syncConfig.ecosystem }})</th>
              <th>Actions</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(taxonomyMapping, mappingKey) in syncConfig.taxonomyMappings" :key="syncConfigKey + '-' + mappingKey">
              <td>{{ taxonomyMapping.taxonomy }} <span v-if="taxonomyMapping.taxonomy === 'category'">(LegalEntity)</span></td>
              <td>
                <dropdown
                  v-model="taxonomyMapping.source" :options="getSourceTaxonomyOptionsForEcosystem(syncConfigKey, taxonomyMapping.taxonomy)" :search="true" allow-clear
                  placeholder="Select source option" style="width:250px;" :class="{'not-valid': !isValidTaxonomyMapping(syncConfigKey, taxonomyMapping)}"/>
              </td>
              <td>
                <dropdown
                  v-model="taxonomyMapping.target" :options="getTargetTaxonomyOptionsForEcosystem(syncConfigKey, taxonomyMapping.taxonomy)" :search="true" allow-clear
                  placeholder="Select source option" style="width:250px;" :class="{'not-valid': !isValidTaxonomyMapping(syncConfigKey, taxonomyMapping, true)}"/>
              </td>
              <td>
                <!-- @todo -->
                <ds-button icon="trash" @click="removeTaxonomyMapping(syncConfig, mappingKey)"/>
              </td>
            </tr>
            </tbody>
          </table>
        </form-group>
      </div>

      <br/>

      <form class="form-horizontal">
        <form-group>
          <dropdown v-model="newEcosystem" :options="sortedEcosystems" :searchable="true"/>
          <ds-button @click="addSyncConfig" label="Add new config" :disabled="! newEcosystem"/>
        </form-group>
      </form>
    </template>

  </div>
</template>

<script>
  import DsInput from '../Form/DsInput.vue'
  import FormGroup from '../Form/FormGroup.vue'
  import Checkbox from '../Form/Checkbox.vue'
  import Dropdown from '../../components/Dropdown/Dropdown.vue'

  import { getAllEcosystems } from '../../api/config.js'
  import { getExternalTaxonomyValuesByProperty } from '../../api/external-taxonomies.js'

  export default {
    data () {
      return {
        newEcosystem: null,
        syncConfigKey: 0,
        ecosystems: [],
        fields: [
          'aboutme',
          'activities',
          'address',
          'category',
          'company_number',
          'cordis_id',
          'crunchbase',
          'description',
          'domains',
          'email',
          'expertises',
          'facebook',
          'founding_date',
          'funding_rounds',
          'funding_structure',
          'industries',
          'subindustries',
          'instagram',
          'jurisdiction',
          'juridical',
          'juridical_situation',
          'legal_address',
          'linkedin',
          'maturity',
          'membership',
          'motivations',
          'not_for_profit',
          'other_urls',
          'product',
          'phone',
          'reddit',
          'stage',
          'seo_keywords',
          'funnel_stage',
          'status',
          'stop_date',
          'tags',
          'technology',
          'twitter',
          'twitter_bio',
          'type',
          'url',
          'vat_number',
          'vimeo',
          'youtube',
          'website_screenshot',
        ],
        taxonomies: [
          'category',
          'domains',
          'industries',
          'subindustries',
          'expertises',
          'activities',
          'stage',
          'funnelStages', // ??
          'memberships',
          'technology',
          'motivations',
        ],
        mappedTaxonomyToClass: {
          'category': 'categories',
          'technology': 'technologies',
        },
        sourceTaxonomyEcosystemMapping: [],
        targetTaxonomyEcosystemMapping: [],
      }
    },
    methods: {
      addSyncConfig () {
        if (!this.form.ecosystemSyncConfigurations) {
          this.form.ecosystemSyncConfigurations = []
        }

        this.syncConfigKey++

        this.form.ecosystemSyncConfigurations.push({ 'ecosystem': this.newEcosystem, 'fields': [], 'taxonomyMappings': [] })

        // Remove the selected ecosystem from ecosystems options
        this.ecosystems.splice(this.ecosystems.indexOf(this.newEcosystem), 1)
        // Reset the selected ecosystem
        this.newEcosystem = null
      },
      removeSyncConfig (ecosystem) {
        this.syncConfigKey--

        this.form.ecosystemSyncConfigurations = this.form.ecosystemSyncConfigurations.filter(syncConfig => syncConfig.ecosystem != ecosystem)

        // Add it back to the ecosystems options
        this.ecosystems.push(ecosystem)
      },
      addAllProperties (syncConfig) {
        syncConfig.fields = this.fields
      },
      addTaxonomyMappingToConfig (syncConfigKey, syncConfig) {
        if (typeof syncConfig.newTaxonomy === 'undefined') {
          return
        }

        const newTaxonomyMapping = {
          taxonomy: syncConfig.newTaxonomy,
          source: null,
          target: null,
        }

        if (typeof syncConfig.taxonomyMappings === 'undefined') {
          syncConfig.taxonomyMappings = []
        }

        syncConfig.taxonomyMappings.push(newTaxonomyMapping)

        this.fetchSourceTaxonomiesForTaxonomyMapping(syncConfigKey, syncConfig.newTaxonomy)
        this.fetchTargetTaxonomiesForTaxonomyMapping(syncConfigKey, syncConfig, syncConfig.newTaxonomy)
      },
      removeTaxonomyMapping (syncConfig, key) {
        if (!window.confirm('Are you sure you want to remove the taxonomy mapping for ecosystem: ' + syncConfig.ecosystem + '?')) {
          return
        }

        syncConfig.taxonomyMappings.splice(key, 1)
      },
      getSourceTaxonomyOptionsForEcosystem (syncConfigKey, taxonomyName) {
        const syncConfigTaxonomyOptions = this.sourceTaxonomyEcosystemMapping[syncConfigKey]

        if (typeof syncConfigTaxonomyOptions === 'undefined') {
          return []
        }

        const taxonomyOptions = syncConfigTaxonomyOptions.find((mapping) => mapping.taxonomy === taxonomyName)

        if (typeof taxonomyOptions === 'undefined') {
          return []
        }

        return taxonomyOptions.options
      },
      getTargetTaxonomyOptionsForEcosystem (syncConfigKey, taxonomyName) {
        const syncConfigTaxonomyOptions = this.targetTaxonomyEcosystemMapping[syncConfigKey]

        if (typeof syncConfigTaxonomyOptions === 'undefined') {
          return []
        }

        const taxonomyOptions = syncConfigTaxonomyOptions.find((mapping) => mapping.taxonomy === taxonomyName)

        if (typeof taxonomyOptions === 'undefined') {
          return []
        }

        return taxonomyOptions.options
      },
      fetchSourceTaxonomiesForTaxonomyMapping (syncConfigKey, taxonomy) {
        let taxonomyClass = taxonomy

        if (taxonomy === 'category') {
          taxonomyClass = 'categories'
        }
        if (taxonomy === 'technology') {
          taxonomyClass = 'technologies'
        }

        const taxonomyValues = this.$store.state.taxonomies[taxonomyClass]
        const taxonomies = taxonomyValues.map(taxonomy => ({
          value: taxonomy.id, label: taxonomy.name
        }))

        // Add the subtaxonomies
        if (['domains', 'industries'].includes(taxonomyClass)) {
          taxonomyValues.map(taxonomy => {
            return taxonomy[taxonomyClass].map(subtaxonomy => (
              taxonomies.push({ value: subtaxonomy.id, label: '--' + subtaxonomy.name })
            ))
          })
        }

        if (['technologies'].includes(taxonomyClass)) {
          taxonomyValues.forEach(taxonomyValue => {
            return taxonomyValue.children.map(childTaxonomy => {
              taxonomies.push({ value: childTaxonomy.id, label: '--' + childTaxonomy.name })
            })
          })
        }

        // bind the fetched options to the taxonomy ecosystem mapping
        if (typeof this.sourceTaxonomyEcosystemMapping[syncConfigKey] === 'undefined') {
          this.sourceTaxonomyEcosystemMapping[syncConfigKey] = []
        }

        this.sourceTaxonomyEcosystemMapping[syncConfigKey].push({
          taxonomy: taxonomy,
          options: taxonomies
        })
      },
      fetchTargetTaxonomiesForTaxonomyMapping (syncConfigKey, syncConfig, taxonomy) {
        const targetTaxonomies = []

        let taxonomyClass = taxonomy

        if (taxonomy === 'category') {
          taxonomyClass = 'categories'
        }
        if (taxonomy === 'technology') {
          taxonomyClass = 'technologies'
        }

        // Map the taxonomy target category options
        getExternalTaxonomyValuesByProperty({ taxonomy: taxonomyClass, targetEcosystem: syncConfig.ecosystem })
          .then(taxonomyValues => {
            for (const taxonomyValue of taxonomyValues) {
              targetTaxonomies.push({ value: taxonomyValue.id, label: taxonomyValue.name })

              if (['technologies'].includes(taxonomyClass)) {
                taxonomyValue.children.map(childTaxonomy => {
                  targetTaxonomies.push({ value: childTaxonomy.id, label: '--' + childTaxonomy.name })
                })
              }
            }

            // bind the fetched options to the taxonomy ecosystem mapping
            if (typeof this.targetTaxonomyEcosystemMapping[syncConfigKey] === 'undefined') {
              this.targetTaxonomyEcosystemMapping[syncConfigKey] = []
            }

            this.targetTaxonomyEcosystemMapping[syncConfigKey].push({
              taxonomy: taxonomy,
              options: targetTaxonomies
            })
          })
          .catch()
      },
      allowedTaxonomies (syncConfig) {
        // Note: category => categories (in fields)
        // @todo test how this applies on multi values
        const allowedTaxonomies = this.taxonomies
        const options = []

        allowedTaxonomies.map(taxonomy => {
          // taxonomyClass = mappedTaxonomyToClass
          if (typeof syncConfig.fields.find((field) => field === taxonomy) !== 'undefined') {
            options.push(taxonomy)
          }
        })

        return options
      },
      isValidTaxonomyMapping (syncConfigKey, taxonomyMapping, target) {
        if (typeof taxonomyMapping === 'undefined' || (target ? !taxonomyMapping.target : !taxonomyMapping.source)) {
          return true
        }

        const taxonomyMappings = target ? this.targetTaxonomyEcosystemMapping[syncConfigKey] : this.sourceTaxonomyEcosystemMapping[syncConfigKey]

        if (typeof taxonomyMappings === 'undefined') {
          return
        }

        const taxonomyMappingConfig = taxonomyMappings.find((mapping) => mapping.taxonomy === taxonomyMapping.taxonomy)

        if (typeof taxonomyMappingConfig === 'undefined') {
          return
        }

        return taxonomyMappingConfig.options.find(option => option.value === (target ? taxonomyMapping.target : taxonomyMapping.source))
      }
    },
    computed: {
      sortedEcosystems () {
        return this.ecosystems.sort()
      },
      currentEcosystem () {
        return this.$store.getters.currentEcosystem
      },
      currentEcosystemName () {
        return this.currentEcosystem.displayName || this.currentEcosystem.name
      },
      form () {
        return this.$parent.$parent.form
      },
      actorReports: {
        get () {
          return !!this.form.actorReports
        },
        set (v) {
          this.form.actorReports = v ? { byweekday: 1, byhour: '12:00' } : null
        }
      },
      dayOptions () {
        return ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
          .map((v, i) => ({
            value: i,
            label: v
          }))
      },
    },
    mounted () {
      getAllEcosystems().then(data => {
        this.ecosystems = data
      }).then(() => {
        // Load the source and target options for already saved sync configs
        this.form.ecosystemSyncConfigurations.map((syncConfig, syncConfigKey) => {
          // remove the private_tags field from all ecosystems
          syncConfig.fields = syncConfig.fields.filter(field => field !== 'private_tags')

          // Remove the selected ecosystem from ecosystems options
          this.ecosystems.splice(this.ecosystems.indexOf(syncConfig.ecosystem), 1)

          if (!syncConfig.taxonomyMappings) {
            syncConfig.taxonomyMappings = []
          }

          syncConfig.taxonomyMappings.map(taxonomyMapping => {
            this.fetchSourceTaxonomiesForTaxonomyMapping(this.syncConfigKey, taxonomyMapping.taxonomy)
            this.fetchTargetTaxonomiesForTaxonomyMapping(this.syncConfigKey, syncConfig, taxonomyMapping.taxonomy)
          })

          this.syncConfigKey++
        })
      })
        .catch()
    },
    components: {
      Checkbox,
      Dropdown,
      DsInput,
      FormGroup,
    },
  }
</script>
<style lang="scss">
  // @import "../scss/_variables.scss";

  .grouped-sync-config {
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
    border-radius: .25rem !important;
    margin: 15px 0;
    padding: 15px;
  }

  .not-valid {
    border: 1px solid red;
  }
</style>
