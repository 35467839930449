<template>
  <div>
    <div class="scope-description__header">
      <description-with-keywords v-model:description="scopeDescription" v-model:keywords="introJsMockData" :part="part" v-if="introJsMockData"/>
      <description-with-keywords v-model:description="scopeDescription" v-model:keywords="searchTopics" :part="part" v-else/>
    </div>
    <div class="scope-description__button">
      <ds-button variant="primary" label="Next step" @click="handleNextStep" :disabled="!canSubmit"/>
      <ds-button label="Cancel" @click="handleCancel" v-if="withCancel"/>
    </div>
  </div>
</template>

<script lang="ts">
  import DescriptionWithKeywords from './DescriptionWithKeywords.vue'
  import { defineComponent } from 'vue'

  const MINIMUM_LENGTH = 100

  export default defineComponent({
    props: {
      part: Number,
      modelValue: {
        type: Object,
      },
      introJsMockData: {
        type: Array,
      },
      withCancel: {
        type: Boolean
      },
    },
    emits: ['update:modelValue', 'next-step', 'cancel'],
    data () {
      return {
        MINIMUM_LENGTH,
        submitting: false,
      }
    },
    computed: {
      scopeDescription: computedValueProperty('scopeDescription'),
      searchTopics: computedValueProperty('searchTopics'),
      hasAtLeastOneWikipediaTag () {
        const prefix = 'http://en.wikipedia.org/wiki/'
        return !!this.searchTopics.find(topic => topic.value.startsWith(prefix))
      },
      canSubmit () {
        return !this.submitting && !(this.part === 2 && this.modelValue.scopeDescription.length === 0) && !(this.part === 1 && this.searchTopics.length === 0)
      },
    },
    methods: {
      handleNextStep () {
        this.$emit('next-step')
      },
      handleCancel () {
        this.$emit('cancel')
      },
    },
    components: {
      DescriptionWithKeywords,
    },
  })

  function computedValueProperty (name) {
    return {
      get () {
        return this.modelValue[name]
      },
      set (value) {
        this.$emit('update:modelValue', { ...this.modelValue, [name]: value })
      },
    }
  }
</script>

<style lang="scss" scoped>
  .scope-description__header {
    margin-bottom: 20px;
  }

  :deep(.scope-description__items) {
    margin-top: 15px;
  }

  .scope-description__button {
    display: flex;
    align-items: center;
    margin-top: auto;

    span {
      margin-left: 20px;
    }
  }
</style>
