<template>
  <div class="knowledge-report">
    <div class="button--kr" v-if="showBackButton">
      <ds-button variant="primary" class="button--small" :label="$t('actor_detail_reports_back')" @click="$emit('back')"/>
    </div>
    <!-- put videos on top -->
    <div class="kr__field" style="margin-bottom: 1rem;">
      <template v-for="field in fields">
        <div v-if="field.type === 'embed' && allowVideos && field.value" class="embed-responsive">
          <iframe :key="getOne(field)" :src="validUrl(getOne(field))" frameborder="0" allowfullscreen></iframe>
        </div>
      </template>
    </div>

    <!-- cycle through the rest of the non embed fields, we can do this easier by putting the embed fields on top and just have 1 loop -->
    <div class="kr__field" v-for="field in fields">
      <span v-if="field.type === 'title'" :style="style(field)"  class="kr__title">
        {{ label(field) }}
      </span>
      <span v-else-if="field.type === 'subtitle'" class="guide_text kr__subtitle" :class="{'kr__field-push-right' : hasTitle}">
        {{ label(field) }}
      </span>
      <form-group :label="label(field)" v-else-if="field.type === 'multi_select_actor'">
        <multi-select-actor :model-value="getOne(field)" :allow-input="false"/>
      </form-group>
      <form-group :label="label(field)" :style="style(field)" v-else-if="field.type !== 'embed'">
        <input-range v-if="field.type === 'score'"
                     class="input-range--score card"
                     :label="field.label"
                     :model-value="parseFloat((avg ? getAvg : getOne)(field) || 0)"
                     :max="5"
                     disabled
        />
        <div v-else-if="field.type === 'file'" class="card">
          <file-list :model-value="existingFiles(field)" :parentData="actor" :allow-edit="false"/>
        </div>
        <div v-else style="white-space: pre-wrap;word-wrap: break-word;" class="card">{{ getOne(field) }}</div>
      </form-group>
    </div>
  </div>
</template>

<script lang="ts">
  import _flatten from 'lodash/flatten.js'

  import DsTextarea from '../Form/DsTextarea.vue'
  import FileList from '../Form/FileList.vue'
  import InputRange from '../Slider/InputRange.vue'
  import { getAllReportFields } from '../../util/helpers.js'
  import MultiSelectActor from './MultiSelectActor.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      actor: null,
      reports: null,
      avg: Boolean,
      showBackButton: {
        type: Boolean,
        default: true
      },
      allowVideos: {
        type: Boolean,
        default: true
      },
      displayContent: {
        type: Boolean,
        default: false
      }
    },
    data () {
      return {
        hasTitle: false
      }
    },
    computed: {
      ecosystemEmail () {
        return this.$store.state.config.email
      },
      ecosystemName () {
        return this.$store.getters.currentEcosystem.displayName || this.$store.getters.currentEcosystem.name
      },
      fields () {
        return (getAllReportFields() || [])
          .map(r => {
            if (r.type == 'title') {
              this.hasTitle = true
            }
            return Object.assign({
              reports: _flatten(this.reports.filter(d => d.ecosystem_report_field_id === r.id)),
            }, r)
          }).filter(r => r.reports.length)
      },
    },
    methods: {
      label (field) {
        return field.type === 'score' ? 'hidden' : field.label
      },
      style (field) {
        return field.type === 'score' ? { maxWidth: '50%', marginBottom: '2rem' } : null
      },
      getAvg ({ reports }) {
        return reports.length ? reports[0].avg_value : null
      },
      getOne ({ reports }) {
        if (reports.length === 0) {
          return
        }

        if (Array.isArray(reports[0].value)) {
          return reports[0].value.join(', ')
        }

        return reports[0].value
      },
      getMany ({ reports }) {
        return reports.map(a => a.value)
      },
      existingFiles ({ reports }) {
        const fileIds = reports.map(a => (a.value || '').toString())
        return (this.actor.files || []).filter(file => fileIds.includes(file.id.toString()))
      },
      preview (file) {
        return file && file.mimetype && file.mimetype.startsWith('image') ? { backgroundImage: 'url(' + file.url + ')' } : null
      },
      validUrl (url) {
        if (typeof url !== 'string') {
          return
        }

        // Fix protocol
        if (!url.includes('://')) {
          url = 'https://' + url
        }

        // Fix html code
        if (url.includes('src=')) {
          url = /src=["']([^"']+)["']/.exec(url)[1]
        }

        // Restrict to vimeo and youtube
        if (!url.includes('youtu') && !url.includes('vimeo')) {
          return
        }

        // Double check
        if (/^https:\/\/[a-zA-Z0-9.\-_]+\..*$/.test(url)) {
          return url
        }
      }
    },
    components: {
      DsTextarea,
      FileList,
      InputRange,
      MultiSelectActor
    }
  })
</script>

<style lang="scss" scoped>
  .knowledge-report {
    position: relative;
  }

  .button--kr {
    position: absolute;
    top: -73px;
    right: 95px;
  }
</style>
