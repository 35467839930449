<template>
  <div class="proof-point-card__new">
    <div class="proof-point-card__new-delete">
      <checkbox @click="selectProofPoint" type="checkbox" v-model="checked"/>
    </div>
    <div class="split__line__first"></div>
    <div class="proof-point__card-new-avatars">
      <avatar-list-new :members="members"/>
    </div>
    <div class="split__line__second"></div>
    <div class="proof-point__card-new-body">
      <div class="proof-point__card-new-text">
        <p>{{ argumentation }}</p>
      </div>
      <div style="display: inline-block">
        <div class="proof-point-card__new-highlight" v-if="highlight">
          <avatar icon="pen" background-color="#5F94F9"/>
          <p>{{ highlight }}</p>
          <a :href="url">
            <icon name="arrow-link" size="14"/>
          </a>
        </div>
        <div class="proof-point-card__new-actor" v-if="actor">
          <avatar :src="logo" :alt="actor"/>
          <p>{{ actor }}</p>
          <a :href="'/actors/' + url">
            <icon name="arrow-link" size="14"/>
          </a>
        </div>
        <div class="proof-point-card__new-article" v-if="article && !highlight">
          <avatar icon="newspaper" background-color="#5F94F9"/>
          <p>{{ article }}</p>
          <a :href="url">
            <icon name="arrow-link" size="14"/>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { proofPointItemTypes } from '../../constants/proofPoints.js'
  import Checkbox from '../Form/Checkbox.vue'
  import Avatar from '../Avatar/Avatar.vue'
  import AvatarList from '../Avatar/AvatarList.vue'
  import AvatarListNew from '../Avatar/AvatarListNew.vue'

  export default {
    emits: ['selectProofPoint'],
    props: {
      proofPoint: {
        type: Object,
        default: () => {
          return {}
        }
      },
      argumentation: {
        type: String
      },
      highlight: {
        type: String
      },
      actor: {
        type: String
      },
      article: {
        type: String
      },
      logo: {
        type: String
      },
      url: {
        type: String
      },
      id: {
        type: Number
      },
      members: {
        type: Object
      }
    },
    data () {
      return {
        proofPointItemTypes,
        active: false,
        checked: false
      }
    },
    computed: {},
    methods: {
      selectProofPoint () {
        this.active = !this.active

        this.$emit('selectProofPoint', { active: this.active, id: this.id })
      },
      showPestel () {
        this.letters.map((letter) => {

        })
      }
    },
    mounted () {
    },
    components: {
      AvatarListNew,
      AvatarList,
      Avatar,
      Checkbox

    }
  }
</script>


<style lang="scss" scoped>
  .proof-point-card__new {
    background: white;
    max-width: 100%;
    max-height: 100%;
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 4px;
    margin: 5px;
    position: relative;

    .proof-point-card__new-delete {
      margin-left: 1%;
    }

    .proof-point__card-new-avatars {
      min-width: 200px;
      margin-left: 5%;
      height: 100%;
    }

    .proof-point__card-new-body {
      margin-left: 2%
    }

    .proof-point__card-new-text {
      margin-bottom: 10px;
    }

    .proof-point-card__new-highlight {
      display: flex;
      background-color: #F6F9FE;
      width: 100%;
      padding: 10px;
      align-items: center;

      p {
        margin-bottom: 0;
      }

      a {
        margin-left: 10px;
      }

      :deep(.icon .svg-icon path) {
        fill: white;
      }

      :deep(.svg-icon path) {
        fill: #85ADFA;
      }
    }

    .proof-point-card__new-actor {
      display: flex;
      background-color: #F6F9FE;
      align-items: center;
      width: 100%;
      padding: 10px;

      p {
        margin-bottom: 0;
      }

      a {
        margin-left: 10px;
      }

      :deep(.svg-icon path) {
        fill: #85ADFA;
      }
    }

    .proof-point-card__new-article {
      display: flex;
      background-color: #F6F9FE;
      align-items: center;
      width: 100%;
      padding: 10px;

      p {
        margin-bottom: 0;
      }

      a {
        margin-left: 10px;
      }

      :deep(.icon .svg-icon path) {
        fill: white;
      }

      :deep(.svg-icon path) {
        fill: #85ADFA;
      }
    }
  }

  .split__line__first {
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: lightgrey;
    left: 75px;
  }

  .split__line__second {
    position: absolute;
    height: 100%;
    width: 1px;
    background-color: lightgrey;
    left: 303px;
  }
</style>
