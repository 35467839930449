<template>
  <div class="fixed-heading">
    <ds-button size="small" label="Add lexicon" icon="plus" variant="secondary"
               @click="addDictionary" style="margin-left: auto;"/>

    <div class="scrollable has-padding has-action-bar" style="padding-bottom: 150px;">
      <template v-for="(dictionary, index) in dictionaries">
        <div class="dictionary" v-if="! dictionary.default">
          <dictionary-component
            :dictionary="dictionary"
            :index="index"
            :show-delete-button="true"
            :allow-rename="true"
            @remove="removeDictionary"
          />
        </div>
      </template>

      <hr class="line">

      <div class="dictionary">
        <dictionary-component
          :dictionary="excludedTags"
          :show-delete-button="false"
          :allow-rename="false"
        />
      </div>
    </div>

    <action-bar :editing="isEditing || isSaving">
      <ds-button variant="secondary" label="Save changes" @click="save"/>
      <ds-button variant="" label="Cancel" @click="cancel"/>
      <span class="action-bar__message" v-if="successMessage">{{
          successMessage
        }}</span>
    </action-bar>
  </div>
</template>

<script>
  import ActionBar from '../../Form/ActionBar.vue'
  import FormGroup from '../../Form/FormGroup.vue'
  import TagInput from '../../../components/Form/TagInput.vue'
  import DictionaryComponent from './DictionaryComponent.vue'

  import { deleteExcludedTag, Dictionary, getExcludedTags, storeExcludedTag } from '../../../api/exploration'
  import { inert } from '../../../util/helpers'
  import WithConfigurationTabs
    from '../../../pages/WithConfigurationTabs/WithConfigurationTabs.vue'
  import DictionaryMixin from '../../../util/DictionaryMixin'

  export default {
    data () {
      return {
        dictionaries: [],
        originalDictionaries: [],
        wasJustLoaded: false,
        isEditing: false,
        isSaving: false,
        successMessage: '',
        originalExcludedTags: [],
        excludedTags: {
          value: 'Excluded Topics',
          included_keywords: []
        }
      }
    },
    methods: {
      addDictionary () {
        this.dictionaries.unshift({ value: '', included_keywords: [], excluded_keywords: [] })

        setTimeout(() => {
          this.$refs.dictionaries[0].focus()
        }, 0)
      },
      removeDictionary (index) {
        var copy = [...this.dictionaries]

        copy.splice(index, 1)

        this.dictionaries = []
        this.dictionaries = copy
      },
      cancel () {
        this.dictionaries = inert(this.originalDictionaries) // inert used as a deep clone here
        this.isEditing = false
        this.wasJustLoaded = true
      },
      save () {
        const toAdd = [] // dictionary objects (without ID)
        const toUpdate = [] // dictionary objects (with ID)
        const foundIds = []

        for (const dictionary of this.dictionaries) {
          if (dictionary.id === undefined) {
            if (dictionary.value) {
              toAdd.push(dictionary)
            }
          } else {
            foundIds.push(dictionary.id)

            const original = this.originalDictionaries.find(d => d.id === dictionary.id)

            if (JSON.stringify(dictionary) !== JSON.stringify(original)) {
              toUpdate.push(dictionary)
            }
          }
        }

        const toDelete = this.originalDictionaries.filter(d => !foundIds.includes(d.id)).map(d => d.id) // IDs

        this.isSaving = true
        this.successMessage = ''

        Promise.all([
          ...toAdd.map(dictionary => Dictionary.post(dictionary).then(({ topicId }) => {
            dictionary.id = topicId // This modifies data inside this.dictionaries, and is required
            Dictionary.post(dictionary)
          })),
          ...toUpdate.map(dictionary => Dictionary.post(dictionary)),
          ...toDelete.map(id => Dictionary.delete(id))
        ]).then(() => {
          this.isEditing = false
          this.isSaving = false
          this.successMessage = 'The lexicons are successfully updated'
          this.originalDictionaries = inert(this.dictionaries) // inert used as a deep clone here
          this.fetchDictionaries()
        }).catch(error => {
          this.isSaving = false
          this.successMessage = error && error.value && error.value[0]
          console.error(e)
        })

        this.saveExcludedTags()
      },
      saveExcludedTags () {
        const toAdd = []
        const foundIds = []

        this.excludedTags.included_keywords.forEach(tag => {
          if (tag.id) {
            foundIds.push(tag.id)
          } else {
            toAdd.push(tag)
          }
        })

        const toDelete = this.originalExcludedTags.filter(d => !foundIds.includes(d.id)).map(d => d.id)

        this.isSaving = true
        this.successMessage = ''

        Promise.all([
          ...toAdd.map(tag => storeExcludedTag({ tag: tag })),
          ...toDelete.map(tagId => deleteExcludedTag(tagId))
        ]).then(() => {
          this.isEditing = false
          this.isSaving = false
          this.successMessage = 'The topics are successfully updated'

          getExcludedTags()
            .then(tags => {
              this.originalExcludedTags = inert(tags) // inert used as a deep clone here
              this.excludedTags.included_keywords = tags
              this.wasJustLoaded = true
            })
        }).catch(e => {
          this.isSaving = false
          console.error(e)
        })
      }
    },
    created () {
      Dictionary
        .get()
        .then(dictionaries => {
          const sortedDictionaries = dictionaries.sort((a, b) => a.value.localeCompare(b.value))

          this.originalDictionaries = inert(sortedDictionaries) // inert used as a deep clone here
          this.dictionaries = sortedDictionaries
          this.wasJustLoaded = true
        })

      getExcludedTags()
        .then(tag => {
          const sortedTags = tag.sort((a, b) => a.label.localeCompare(b.label))

          this.originalExcludedTags = inert(sortedTags)
          this.excludedTags.included_keywords = sortedTags
          this.wasJustLoaded = true
        })
    },
    watch: {
      dictionaries: {
        deep: true,
        handler () {
          if (!this.wasJustLoaded) {
            this.isEditing = true
          } else {
            this.isEditing = false
            this.wasJustLoaded = false
          }
        }
      },
      excludedTags: {
        deep: true,
        handler () {
          if (!this.wasJustLoaded) {
            this.isEditing = true
          } else {
            this.isEditing = false
            this.wasJustLoaded = false
          }
        }
      },
    },
    components: {
      ActionBar,
      FormGroup,
      TagInput,
      DictionaryComponent,
      WithConfigurationTabs,
    },
    mixins: [DictionaryMixin]
  }
</script>

<style lang="scss" scoped>
  .dictionary {
    border: 1px solid #eee;
    padding: 10px;
    margin-bottom: 10px;
    padding-bottom: 0;
  }

  .dictionary__buttons {
    display: flex;
  }
</style>
