<template>
  <div v-if="article.resource">
    <!-- header -->
    <div class="header">
      <h4 @click="handleClickResourceResult(article.resource, true)" class="h4 article-panel__header-title">
        {{ title }} &nbsp;<icon name="open-in-new" size="12" style="margin-top: 0.1rem;"/>
      </h4>
      <ds-button v-if="conceptSearchId" style="margin-left: auto;" size="small" @click="$emit('go-to-next')"
                 label="next"/>
      <ds-button v-if="simplified" style="margin-left: auto;" size="small" @click="goToBack" label="Go back"/>
      <relevant-edit-popup
          v-if="isOwner || isTeamMember"
          v-model="article.relevant_in"
          :active-search="conceptSearchId"
          @relevant="handleArticleRelevant"
          @not-relevant="handleArticleNotRelevant"
          style="margin-top: 6px; cursor: pointer"
      />

      <span class="article_detail_like_and_delete" @click="likeArticle" :key="componentKey">
        <icon style="margin-left: 1rem;" :name="isLikeByUser ? 'thumbs-up' : 'thumbs-up-outline'"/>
      </span>
      <div class="article_detail_like_and_delete" @click="remove">
        <icon style="margin-left: 1rem;" name="trash"/>
      </div>
    </div>

    <!-- small meta-data -->
    <div v-if="simplified">
      <div class="article-panel__header-tags" v-if="article">
        <div class="content__meta">
          <ul>
            <!-- Published on date -->
            <li v-if="resourceDate">
              <span class="timestamp">
                Published on {{ resourceDate }}
              </span>
            </li>
            <!-- Source name -->
            <li>
              <news-source
                  icon="rss"
                  class="source__label__tag"
                  :source="transformDetailedSource"
                  @click="filterSource($event)"
              />
            </li>
          </ul>
        </div>
      </div>

      <div style="display: flex; flex-direction: row;" class="article-panel__header-tags"
           v-if="(tags && tags.length > 0)"> <!--  || (actors && actors.length > 0) -->
        <div :class="{'tags__simplified': true, 'show-all': showAllTags}">
          <div class="content__meta" v-if="simplified && tags && tags.length > 0">
            <ul>
              <li
                  v-for="(tag, index) in tags"
                  :key="'tag__' + tag.value + index"
              >
                <RichTag
                    :tag="tag"
                    :title="tag.type === 'patent_cpc' ? tag.text : ''"
                    @click="addTagToFilters($event, {value: tag.value, label: tag.label, facet: 'tags'})"
                    :disable-add-to-dictionary="false"
                />
              </li>
            </ul>
          </div>

          <div class="content__meta" v-if="simplified && locationTags && locationTags.length > 0">
            <ul>
              <li
                  v-for="(tag, index) in locationTags"
                  :key="'tag__' + tag.value + index"
              >
                <RichTag
                    tag-icon="globe"
                    :tag="tag"
                    :title="''"
                    @click="addTagToFilters($event, {value: tag.value, label: tag.label, facet: 'tags'})"
                    :disable-add-to-dictionary="false"
                />
              </li>
            </ul>
          </div>

          <!--
          <div class="content__meta" v-if="simplified && ((actors && actors.length > 0) || (actorTags && actorTags.length > 0))">
            <i class="fa fa-building"></i>
            <ul>
              <li
                v-for="(actor, index) in actors"
                :key="'actor__' + actor.id + index"
              >
                <button
                  :class="actor.isActive ? 'tag tag--active' : 'tag'"
                  @click="openActorSidePanel(actor)"
                >
                  {{ actor.name }}
                </button>
              </li>
              <li
                v-for="(tag, index) in actorTags"
                :key="'tag__' + tag.value + index"
              >
                <RichTag
                  :tag-icon="'fa fa-plus'"
                  :tag="tag"
                  :title="''"
                  @click="addTagToFilters($event,{value: tag.value, label: tag.label, facet: 'tags'})"
                  :disable-add-to-dictionary="false"
                />
              </li>
            </ul>
          </div>
          -->
        </div>
        <div :class="{'show-more-container': true, 'show-all': showAllTags}">
          <a
              class="show-more-link"
              href="#"
              @click="toggleShowAllTags"
          >
            {{ showAllTags ? $t('article_detail_show_less_tags') : $t('article_detail_show_all_tags') }}
          </a>
        </div>
      </div>
    </div>

    <div v-if="!simplified">
      <div class="article-panel__header-actors" v-if="actors && actors.length > 0">
        <template v-for="(actor, index) in actors">
          <div
              class="article-panel__header-actor-clickable" v-if="actor.id"
              @click="openActorSidePanel(actor)"
          >{{ actor.name }}
          </div>
          <div class="article-panel__header-actor" v-else>{{ actor.name }}
          </div>
        </template>
      </div>

      <RichTag
          v-if="tags && tags.length > 0"
          v-for="(tag, index) in tags" :key="index"
          size="small"
          :tag="tag"
          :title="tag.type === 'patent_cpc' ? tag.text : ''"
          @click="addTagToFilters($event, {value: tag.value, label: tag.label, facet: 'tags'})"
          :disable-add-to-dictionary="false"
      />
    </div>

    <!-- content -->
    <template v-if="article.loadingData || article.loadingAnnotations">
      <div class="article-panel__loading">
        <h3 class="h3">Loading...</h3>
      </div>
    </template>
    <template v-else>
      <template v-if="article.data && article.data.html">
        <h4 class="h4" style="margin-bottom: 0.6rem; margin-top: 1rem;">Content</h4>
        <div id="manualTooltip" v-tooltip.top="manualTooltip"></div>
        <!-- display the content -->

        <div class="highlight-proof_point">
          <div
              class="article" v-if="article.data !== null" v-html="purifiedArticleHtml"
          />
          <br/>
        </div>

        <!-- display more elaborate meta-data if applicable -->
        <template v-if="meta && meta.length > 0">
          <div style="font-weight: 500; margin-bottom: 0.5rem;">Meta data</div>
          <table class="table article-panel__meta-data-table">
            <tbody>
            <tr v-for="row in meta">
              <td style="width: 12rem;">{{ row.term }}</td>
              <td style="word-break: break-word;" v-if="row.type === 'link'"><a :href="row.label"
                                                                                target="_blank">{{ row.label }}</a>
              </td>
              <td style="word-break: break-word;" v-else>{{ row.label }}</td>
            </tr>
            </tbody>
          </table>

          <template v-if="itemIsPatent(article.resource) && cpcInfo">
            <div style="font-weight: 500; margin-bottom: 0.5rem; margin-top: 1rem;">CPC codes</div>
            <table class="table article-panel__meta-data-table">
              <tbody>
              <tr v-for="cpcCode in Object.keys(cpcInfo)">
                <td style="width: 12rem;">
                  {{ cpcCode }}
                </td>
                <td style="word-break: break-word">
                  {{ cpcInfo[cpcCode] }}
                </td>
              </tr>
              </tbody>
            </table>
          </template>
        </template>
      </template>
      <template v-else>
        The content you're looking for hasn't been found, contact our support.
      </template>
    </template>
  </div>
</template>

<script>
import NewsSource from '../../NewsSource/NewsSource.vue'
import DictionaryTag from '../../Tag/DictionaryTag.vue'
import ResourceMixin from '../../../util/ResourceMixin'
import DictionaryMixin from '../../../util/DictionaryMixin'
import { trackHeapEvent } from '../../../util/analytics'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui.js'
import MODAL_IDS from '../../../constants/modal-ids'
import Loading from '../ConceptMap/Loading.vue'
import RelevantEditPopup from '../../RelevantEditPopup/RelevantEditPopup.vue'
import RichTag from '../../Tag/RichTag.vue'
import DOMPurify from 'dompurify'
import Nl2br from 'vue3-nl2br'
import { MUTATION_TYPES as KNOWLEDGE_BASE_MUTATION_TYPES } from '../../../store/modules/knowledgeBase'
import { likeArticle, removeArticle } from '../../../api/knowledge-base.js'
import { deleteConceptSearch } from '../../../api/exploration.js'
import { deleteEcosystemFile } from '../../../api/files.js'

import {
  ActionTypes,
  MutationTypes as CONCEPT_SEARCHES_MUTATION_TYPES
} from '../../../store/modules/concept-searches.js'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    article: Object,
    allArticles: Array,
    includedTags: Array,
    conceptSearchId: Number,
    simplified: {
      type: Boolean,
      default: false,
    },
    addSourceToTitle: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tooltipContent: null,
      showAllTags: false,
      componentKey: 0,
      highlight: null,
    }
  },
  computed: {
    conceptSearchAutoReloadEnabled: {
      get() {
        return this.$store.getters.conceptSearchAutoReloadEnabled
      },
      set() {
        this.$store.commit(CONCEPT_SEARCHES_MUTATION_TYPES.TOGGLE_AUTO_RELOAD)
      },
    },
    isLikeByUser() {
      if (!this.article || !this.article.details || !this.article.details.is_liked_by) {
        return false
      }
      return this.article.details.is_liked_by.filter(id => id === this.userId).length
    },
    userId() {
      return this.$store.getters.userId
    },
    transformDetailedSource() {
      return {
        label: this.sourceTitle,
        value: this.article.details.main_url_id,
      }
    },
    isOwner() {
      return this.$store.getters.isOwner
    },
    isTeamMember() {
      return this.$store.getters.isTeamMember
    },
    purifiedArticleHtml() {
      if (!this.article || !this.article.data) {
        return
      }

      // return this.article.data.html
      return DOMPurify.sanitize(this.articleHtmlWithHighlightedKeywords)
    },
    onlySeesCuratedContent() {
      return this.$store.getters.onlySeesCuratedContent
    },
    manualTooltip() {
      // TODO TIM CUSTOM TOOLTIP
      return {
        content: this.tooltipContent,
        show: this.tooltipContent,
        trigger: 'manual',
      }
    },
    title() {
      var title = this.article.resource.title

      if (!this.article.data || !this.article.data.meta) {
        return title // The rest of the meta-data is still loading, note that conceptmap / loading in detail data might need some refactoring, it's too much decoupled and scattered
      }

      if (this.addSourceToTitle) {
        if (this.itemIsPatent(this.article.resource)) {
          title += ' - PATSTAT'
        } else if (this.itemIsArticle(this.article.resource) && this.article.data.meta['source']) {
          title += ' - ' + this.sourceTitle
        }
      }

      if (this.addSourceToTitle && this.date) {
        title += ' - ' + this.date
      }

      return title
    },
    date() {
      if (this.article.details && this.article.details.meta && this.article.details.meta.meta && this.article.details.meta.meta.date) {
        return this.publishedDate(this.article.details.meta.meta.date)
      }

      // Get the resources' published date
      if (this.article.details && this.article.details.meta && this.article.details.meta.date) {
        return this.publishedDate(this.article.details.meta.date)
      }

      // Fallback
      if (this.article.data && this.article.data.meta && this.article.data.meta.date) {
        return this.publishedDate(this.article.data.meta.date)
      }

      return null
    },
    resourceDate() {
      return this.date
    },
    sourceTitle() {
      if (!this.itemIsArticle(this.article.resource) && !this.itemIsPatent(this.article.data)) {
        return ''
      }

      if (!this.article.data.meta || !this.article.data.meta.source) {
        return ''
      }

      const sourceUrl = this.article.data.meta.source

      try {
        const fullUrl = new URL(sourceUrl)

        return fullUrl.host.replace('www.', '')
      } catch (error) {
        //
      }
    },
    meta() {
      var meta = []

      if (this.itemIsPatent(this.article.resource)) {
        meta.push(
            {
              term: 'Filing date',
              label: this.article.data.meta['date'],
            },
            {
              term: 'Priority date',
              label: this.article.data.meta['earliest_filing_date'],
            },
        )
      }

      meta = meta.filter(item => item.label)

      return meta
    },
    cpcInfo() {
      // Idea: since the meta-data for different types of content are not 100% overlapping, it might be a good idea to split this up component wise, making meta-data component that this component uses
      return this.article.data.meta && this.article.data.meta.cpc_info && Object.keys(this.article.data.meta.cpc_info).length > 0 && this.article.data.meta.cpc_info
    },
    allTags() {
      let tags = []

      if (this.article.details) {
        tags = this.article.details.tags
      } else if (this.article.resource) {
        tags = this.article.resource.tags
      }

      if (!tags) {
        return []
      }
      return tags
    },
    tags() {
      return this.allTags.filter(tag => !['loc', 'org', 'person'].includes(tag.type))
    },
    locationTags() {
      return this.allTags.filter(tag => ['loc'].includes(tag.type))
    },
    actorTags() {
      return this.allTags.filter(tag => ['org', 'person'].includes(tag.type))
    },
    actors() {
      return this.article.data && this.article.data.actors && this.article.data.actors.organisations
    },
    conceptSearch() {
      return this.$store.state.conceptSearches.detail.data
    },
    articleHtmlWithHighlightedKeywords() {
      let articleWords = this.article.data.html
      if ((this.conceptSearch.search_topics && this.conceptSearch.search_topics.length > 0) || (this.dictionaryOptions && this.dictionaryOptions.length > 0)) {
        if (this.conceptSearch.id && this.conceptSearch.search_topics.length > 0) {
          const keywords = this.conceptSearch.search_topics.map(function (item) {
            return item['label']
          })

          /* const regex = new RegExp(`(${keywords.join('|')})`, 'ig')
          articleWords = articleWords.replaceAll(regex, `<span class="highlight">$1</span>`) */

          keywords.forEach(keyword => {
            articleWords = articleWords.replace(keyword, `<span class="highlight">${keyword}</span>`)
            articleWords = articleWords.replace(keyword.toLowerCase(), `<span class="highlight">${keyword.toLowerCase()}</span>`)
          })
        } else if (this.dictionaryOptions && this.dictionaryOptions.length && !this.conceptSearch.id) {
          if (!this.dictionaryOptions[0] || this.dictionaryOptions[0].value != null) {
            return articleWords
          }

          const allKeywords = []
          const allKeywordValues = []
          this.dictionaryOptions.forEach(item => {
            if (item.included_keywords) {
              allKeywords.push(item.included_keywords)
            }
          })

          allKeywords.forEach(keyword => {
            keyword.forEach(key => {
              articleWords = articleWords.replace(key.label, `<span class="highlight">${key.label}</span>`)
              articleWords = articleWords.replace(key.label.toLowerCase(), `<span class="highlight">${key.label.toLowerCase()}</span>`)
            })
          })

          /* const regex = new RegExp(`(${allKeywordValues.join('|')})`, 'ig')
          console.log(regex)
          articleWords = articleWords.replaceAll(regex, `<span class="highlight">$1</span>`) */
        }
      }

      return articleWords
    },
  },
  methods: {
    getSelectedText() {
      const exactElement = document.getElementById('proof-point-button')
      if (!exactElement.isCollapsed) {
        const selection = window.getSelection()
        const element1 = document.createRange()
        element1.selectNode(document.getElementById('cal1'))
        const element2 = document.createRange()
        element2.selectNode(document.getElementById('cal2'))
        this.highlight = selection.toString()
        const position = selection.getRangeAt(0).getBoundingClientRect()
        const rb1 = element1.getBoundingClientRect()
        const rb2 = element2.getBoundingClientRect()
        exactElement.style.top = (position.bottom - rb2.top) * 100 / (rb1.top - rb2.top) - 60 + 'px'
        exactElement.style.left = (position.left - rb2.left) * 100 / (rb1.left - rb2.left) + 'px'

        if (this.highlight.length > 200) {
          exactElement.style.top = (position.bottom - rb2.top) * 100 / (rb1.top - rb2.top) - 90 + 'px'
        }

        // check if article match with highlight
        if (selection.anchorNode.parentElement.className !== 'article') {
          exactElement.style.display = 'none'
          return
        }
      }

      if (this.highlight && this.highlight.length > 5 && this.highlight.length < 1000) {
        exactElement.style.display = 'block'
      } else if (this.highlight.length > 1000) {
        exactElement.style.display = 'none'
      } else {
        exactElement.style.display = 'none'
      }
    },
    openCreateProofPointModal(withHighlight) {
      if (!withHighlight) {
        this.highlight = null
      }
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
        highlight: this.highlight,
        chosenSignalType: { type: 'file', value: this.$route.params.fileId }
      })
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CREATE_PROOF_POINT)
    },
    deleteArticleFromEcosystem() {
      deleteEcosystemFile(this.$route.params.fileId)
          .then(response => {
            if (window.history.state.position === 0 || !window.history.state.back) {
              this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.CLEAR)
              this.$router.push('/dashboards/knowledge-base')
            } else {
              this.$router.back()
            }
          })
          .catch(errors => {
            console.log(errors)
          })
    },
    removeArticle() {
      this.confirmRemoveArticle(this.$route.params.fileId)
    },
    confirmRemoveArticle(resourceId) {
      removeArticle(resourceId, this.conceptSearch.id).then(() => {
        if (this.conceptSearchAutoReloadEnabled) {
          return this.article.data
        }
      }).catch(() => {
        console.log('error while removing article')
      })
    },
    remove() {
      // other modalContextType to make sure it doesn't delete the simular articles with it
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
        body: 'Do you want to delete this article?',
        modalContextType: 'article-detail',
        article: this.article
      })
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
    },
    likeArticle() {
      likeArticle(this.$route.params.fileId, !this.isLikeByUser)
      if (!this.isLikeByUser) {
        this.article.details.is_liked_by.push(this.userId)
        // this fixes problems when rapidly clicking the like button multiple times
        this.componentKey++
      } else {
        const index = this.article.details.is_liked_by.indexOf(this.userId)
        if (index > -1) {
          this.article.details.is_liked_by.splice(index, 1)
        }
        // this fixes problems when rapidly clicking the like button multiple times
        this.componentKey++
      }
    },
    toggleShowAllTags() {
      this.showAllTags = !this.showAllTags
    },
    goToBack() {
      if (window.history.state.position === 0 || !window.history.state.back) {
        this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.CLEAR)
        this.$router.push('/dashboards/knowledge-base')
      } else {
        this.$router.back()
      }
    },
    filterSource(event) {
      this.addTagToFilters(event, {
        value: this.article.details.main_url_id,
        label: this.sourceTitle,
        facet: 'source',
      })
    },
    openActorSidePanel(actor) {
      // View patents in the knowledge base
      this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.CLEAR)
      this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.SET_ACTOR, {
        facet: 'top_actor',
        value: actor.id,
        label: actor.name
      })

      this.$router.push('/dashboards/knowledge-base')
      // this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component: 'scores', metaData: actor.id})
    },
    addTagToFilters(event, tagInfo) {
      const tagFilter = { facet: tagInfo.facet, value: tagInfo.value, label: tagInfo.label }

      this.$emit('add-tag-to-filters', tagFilter)
    },
    handleArticleRelevant(conceptSearchId) {
      this.markArticleRelevant(true, conceptSearchId)
    },
    handleArticleNotRelevant(conceptSearchId) {
      this.markArticleRelevant(false, conceptSearchId)
    },
    markArticleRelevant(isRelevant, conceptSearchId) {
      this.$emit('mark-relevant', { article: this.article.resource, isRelevant, conceptSearchId })

      if (!this.allArticles) {
        return
      }

      const article = this.allArticles.find(article => this.article.details.id === article.sql_media_id)

      if (!article) {
        if (this.allArticles) {
          this.allArticles.push({
            sql_media_id: this.article.details.id,
            relevant_in: [this.conceptSearchId],
          })
        }
        return
      }

      if (isRelevant) {
        if (article.relevant_in) {
          article.relevant_in.push(this.conceptSearchId)
        }
      } else {
        if (article.relevant_in) {
          article.relevant_in = article.relevant_in.filter(id => id !== this.conceptSearchId)
        }
      }
    },
    handleDocumentHover(e) {
      if (!e.target || e.target.tagName !== 'MARK') {
        this.tooltipContent = null

        return
      }

      if (e.target.dataset.type !== 'PERSON') {
        this.tooltipContent = null

        return
      }

      if (!['local', 'VOPAK-monitoring', 'colruyt-actormapping'].includes(this.$store.getters.ecosystemName.toLowerCase())) {
        return
      }

      var d = document.getElementById('manualTooltip')
      var offset = this.getOffset(e.target)

      d.style.position = 'absolute'
      d.style.left = offset.left + 'px'
      d.style.top = (offset.top - 50) + 'px'

      var entityName = e.target.childNodes[0].textContent
      this.tooltipContent = entityName

      // Search for meta-data
      var actors = this.article.data.actors

      if (!actors || !actors.persons || actors.persons.length == 0) {
        return
      }

      var persons = actors.persons
      var meta = persons.filter(a => a.nameInArticle.toLowerCase() == entityName.toLowerCase())

      if (!meta || meta.length == 0) {
        return
      }

      this.tooltipContent = meta[0]['name']
      this.tooltipContent += '<ul>'

      if (meta[0]['employer']) {
        this.tooltipContent += '<li>Works for: ' + meta[0]['employer'] + '</li>'
      }

      if (meta[0]['position'] && meta[0]['positionScore'] > 0.4) {
        this.tooltipContent += '<li>Position: ' + meta[0]['position'] + '</li>'
      }

      this.tooltipContent += '</ul>'
    },
    getOffset(el) {
      const rect = el.getBoundingClientRect()
      return {
        left: rect.left + window.scrollX,
        top: rect.top + window.scrollY,
      }
    },
    handleDocumentClick(e) {
      if (!e.target || e.target.tagName !== 'MARK') {
        return
      }

      var existingNames = {}

      if (this.article.data.actors && this.article.data.actors.organisations && this.article.data.actors.organisations.length > 0) {
        existingNames = this.article.data.actors.organisations.reduce(function (map, obj) {
          map[obj.name.toLowerCase()] = obj.id || ''
          return map
        }, {})
      }

      switch (e.target.dataset.type) {
        case 'ORG':
          var actorName = e.target.childNodes[0].textContent

          if (!actorName) {
            return
          }

          actorName = actorName.toLowerCase()

          if (Object.keys(existingNames).includes(actorName) && existingNames[actorName] && existingNames[actorName].length > 0) {
            window.location = '/actors/' + existingNames[actorName]

            return
          }
          this.addActor(e.target.childNodes[0].textContent)

          break
        default:
          break
      }
    },
    addActor(name) {
      trackHeapEvent('conceptMapArticle.addActor', { name })

      const modalContext = {
        prefilled: { name },
        afterCreate: 'addMore',
      }

      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, modalContext)
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)
    },
  },
  mounted() {
    this.$bus.on('articleDetailDeleteConfirmation', (context) => {
      if (this.conceptSearch.id) {
        this.confirmRemoveArticle(this.$route.params.fileId)
      }
      this.deleteArticleFromEcosystem(this.$route.params.fileId)
    })

    if (!this.conceptSearch || !this.conceptSearch.id || this.conceptSearchId !== this.conceptSearch.id) {
      if (this.conceptSearchId || this.dictionaryOptions.include_keywords) {
        this.$store.dispatch(ActionTypes.FETCH_CONCEPT_SEARCH_DETAIL, this.conceptSearchId)
      } else {
        console.log('no concept search id')
      }
    }

    this.$bus.on('conceptSearchDeleteConfirmation', (context) => {
      if (context.resource && context.resource.id === this.conceptSearch.id) {
        this.deleting = true
        deleteConceptSearch(this.conceptSearch.id)
            .then(response => {
              // Refresh the concept searches
              this.$bus.emit('refreshConceptSearches')
              this.deleting = false
              this.$router.push('/')
            })
            .catch(error => {
              this.deleting = false
              console.error(error)
            })
      }
    })
  },
  beforeUnmount() {
    if (this.$route.name !== (`/dashboards/concept-map/${this.conceptSearch.id}`)) {
      this.$store.commit(CONCEPT_SEARCHES_MUTATION_TYPES.CLEAR_CONCEPT_SEARCH_DETAIL)
    }
    clearInterval(this.searchInterval)

    this.$bus.off('conceptSearchDeleteConfirmation')
    this.$bus.off('articleDetailDeleteConfirmation')
  },
  mixins: [ResourceMixin, DictionaryMixin],
  components: {
    Nl2br,
    RelevantEditPopup,
    DictionaryTag,
    NewsSource,
    Loading,
    RichTag,
  },
})
</script>

<style lang="scss" scoped>
@import "../../../../scss/variables.scss";

$accent-color: var(--primary);
$border-and-background-color: var(--primary-extra-lightest);
$hover-color: var(--primary-lightest);
$title-height: 40px;
$nrOfLines: 4;
$lineHeight: 16px;

.header {
  display: flex;
  margin-top: 10px;
  margin-bottom: 0.5rem;

  .article_detail_like_and_delete {
    margin-top: 6px;
    cursor: pointer;
  }
}

.relevant-button {
  margin-left: auto;
}

</style>

<style lang="scss" scoped>
@import "../../../../scss/_variables.scss";

$hover-color: var(--primary-community-lightest);
$border-and-background-color: var(--primary-community-extra-lightest);

.article-panel__header-title {
  margin-top: 0.5rem;
  text-decoration: underline;
  cursor: pointer;
}

.article-panel__header-actors {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 0.5rem;
}

.article-panel__header-actor {
  font-family: $font-stack-primary;
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  line-height: 13px;
}

.article-panel__header-actor-clickable {
  font-family: $font-stack-primary;
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  line-height: 13px;
  color: var(--primary-lighter) !important;
  cursor: pointer;
}

.article-panel__header-tags {
  cursor: pointer;
}

.article-panel__meta-data-table {
  max-width: 80%;
  border-left-style: solid !important;

  td {
    text-align: left;
    padding-left: 0.5rem;
    border-left-style: solid !important;
  }
}

.concept-map__article-panel__classification {
  margin-left: 10px;
  text-align: right;
  text-transform: none;
  white-space: nowrap;

  .dictionary-tag {
    margin-left: 0.25rem;
    margin-bottom: 0.25rem;
    margin-right: 0;
  }
}

.article-panel__loading {
  margin-left: auto;
  margin-top: 50%;
  display: flex;
  justify-content: center;
}

.article {
  mark {
    background-color: transparent;
    padding: 0 5px;
    display: inline-block;
    border-radius: 4px;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-decoration-color: var(--primary);

    span {
      font-size: 75%;
      padding-left: 5px;
      font-weight: bold;
      pointer-events: none;
    }

    &[data-type=KEYWORD] {
    }

    &[data-type=ORG] {
      //text-decoration-style: dotted;
      cursor: pointer;
    }

    &[data-type=GPE] {
      text-decoration-color: #F7CB81;
    }

    &[data-type=LOC] {
      text-decoration-color: #F1996C;
    }

    &[data-type=PERSON] {
      text-decoration-color: #aa9df1;
      cursor: pointer;
    }
  }

  :deep(span.highlight) {
    background: yellow;
  }

}

//highlight text
.highlight-proof_point {
  position: relative;
}

.highlight-proof_point__button {
  position: absolute;
  display: none;
  background: white;
  text-transform: lowercase;
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.25);
  width: 140px;
  height: 31px;
  font-size: 12px;
  line-height: 0;
}

.button:hover {
  color: var(--primary);
  background-color: white;

  :deep(.svg-icon path) {
    fill: var(--primary);
  }
}

#cal1 {
  position: absolute;
  height: 0px;
  width: 0px;
  top: 100px;
  left: 100px;
  overflow: none;
  z-index: -100;
}

#cal2 {
  position: absolute;
  height: 0px;
  width: 0px;
  top: 0px;
  left: 0px;
  overflow: none;
  z-index: -100;
}

.tags__simplified {
  display: flex;
  flex-direction: column;
  height: 29px;
  overflow: hidden;
  flex: 1;
  margin-right: 20px;

  &.show-all {
    height: auto;
    overflow-y: visible;
  }
}

.show-more-container {
  display: flex;
  justify-content: flex-end;
  width: 70px;
  margin-left: auto;
  align-items: center;

  &.show-all {
    margin-top: auto;
  }

  .show-more-link {
    display: flex;
    width: 100%;
    line-height: 29px;
    text-align: right;
  }
}

.content__meta {
  display: flex;
  margin-bottom: 0.125rem;
  margin-top: auto;

  svg {
    color: var(--primary-dark);
    margin-right: 0.5rem;
    margin-top: 0.25rem;
    width: 1.25rem !important;
  }

  .tag--active svg {
    margin: 0px;
    width: 1rem;
  }

  ul {
    list-style-type: none;
    display: -webkit-box;
    /*overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical ;*/

    li {
      display: inline-block;

      &:not(:last-child) {
        margin-right: 0.25rem;
        margin-bottom: 0.25rem;
      }
    }
  }

  .timestamp {
    display: flex;
    align-items: center;
    margin-left: auto;
    font-size: 12px;
    color: var(--primary-community);
  }

  .tag {
    margin: 0;
    padding: 6px;
    border-radius: 0.5rem;
    border: 1px solid $border-and-background-color;
    background: transparent;
    font-family: inherit;
    font-size: 11px;

    &:hover {
      cursor: pointer;
      background-color: $hover-color;
      border-color: $hover-color;
    }
  }

  .tag--active, .tag--dictionary {
    .dropdown-button {
      font-weight: bold;

      svg > path {
        fill: var(--primary);
      }
    }
  }
}
</style>
