<template>
  <div class="collapsable-tab__content-container">
    <form-section>
      <div class="row" style="margin-bottom: 0rem;">
        <form-group class="col-xs-12" :label="modifiedActor.actor_type === 'Product' ? $t('actor_edit_type') : $t('actor_edit_business_type')"
                    v-if="isRelevantForActor('type', modifiedActor)"
                    :errors="errors.type || errors.not_for_profit">
          <checkbox
            v-for="(type, index) in ['B2B', 'B2C']"
            v-model="actorType"
            :key="index"
            multiple
            inline
            name="type"
            :label="type"
            :val="type"
          />
          <checkbox
            v-if="isRelevantForActor('not_for_profit', modifiedActor)"
            inline
            :label="$t('edit_actor_segment_activities_not_for_profit')"
            v-model="modifiedActor.not_for_profit"
          />
        </form-group>
      </div>
      <div class="row">
        <form-group :label="activitiesLabel" class="col-xs-12" :errors="errors.activities"
                    v-if="activityOptions.length && isEcosystemMember && isRelevantForActor('activities', modifiedActor)"
                    :class="{'actor-edit__private-field': isPrivateField('activities')}">
          <dropdown placeholder="" multiple :valueIsOption="true" :options="activityOptions" v-model="modifiedActor.activities"/>
        </form-group>
        <form-group :label="activitiesLabel" class="col-xs-12" :errors="errors.activities"
                    v-if="activityOptions.length && !isEcosystemMember && isRelevantForActor('activities', modifiedActor)"
                    :class="{'actor-edit__private-field': isPrivateField('activities')}">
          <div v-if="show.addActivity" class="actor-edit__new-activity-container">
            <ds-input name="actor-new-taxonomy" placeholder="New activity" v-model="newActivityName" @keypress.enter="addNewActivity" ref="newActivityNameInput"
                      class="col-xs-10"/>
            <ds-button variant="minimal" icon="undo" @click="show.addActivity = false" class="col-xs-2"/>
          </div>
          <dropdown
            :placeholder="getTaxonomyAlias('activities')"
            :valueIsOption="true"
            multiple
            :options="activityOptions"
            :suggestions="pendingActivitySuggestions"
            @suggestion:accept="value => decideActivitySuggestion(value, 'accepted')"
            @suggestion:decline="value => decideActivitySuggestion(value, 'denied')"
            v-model="modifiedActor.activities"
            :noResultsHandlerEnabled="true"
            :noResultMessage="noResultMessageActivity"
            @addNewItem="startAddingActivity"
            v-else
          />
        </form-group>
      </div>

      <div v-if="isRelevantForActor('industries', modifiedActor)" class="row">
        <form-group
          :label="industriesLabel"
          class="col-xs-12" :errors="errors.industries"
          :class="{'actor-edit__private-field': isPrivateField('industries')}"
          v-if="industriesOptions.length"
        >
          <div class="card">
            <two-level-taxonomies v-model="modifiedActor.industries" taxonomy-name="industries" :main-taxonomies="industries"/>
          </div>
        </form-group>
      </div>

      <div class="row">
        <form-group
          :label="technologyLabel"
          class="col-xs-12" :errors="errors.technology"
          :class="{'actor-edit__private-field': isPrivateField('technology')}"
          v-if="technologies.length > 0 && isRelevantForActor('technology', modifiedActor)"
        >
          <div class="card">
            <two-level-taxonomies v-model="modifiedActor.technology" taxonomy-name="technologies" :main-taxonomies="technologies"/>
          </div>
        </form-group>
      </div>

      <div class="row">
        <form-group :label="domainsLabel" class="col-xs-12" :errors="errors.domains" :class="{'actor-edit__private-field': isPrivateField('domains')}"
                    v-if="isRelevantForActor('domains', modifiedActor) && domains.length > 0">
          <div class="card">
            <domains v-model="modifiedActor.domains"/>
          </div>
        </form-group>
        <form-group :label="productFeaturesALabel" class="col-xs-12" :errors="errors.product_features_a"
                    :class="{'actor-edit__private-field': isPrivateField('product_features_a')}"
                    v-if="productFeaturesA.length > 0 && isRelevantForActor('product_features_a', modifiedActor)">
          <div class="card">
            <two-level-taxonomies v-model="modifiedActor.product_features_a" taxonomy-name="productFeaturesA" :main-taxonomies="productFeaturesA" />
          </div>
        </form-group>
        <form-group :label="productFeaturesBLabel" class="col-xs-12" :errors="errors.product_features_b"
                    :class="{'actor-edit__private-field': isPrivateField('product_features_b')}"
                    v-if="productFeaturesB.length > 0 && isRelevantForActor('product_features_b', modifiedActor)">
          <div class="card">
            <two-level-taxonomies v-model="modifiedActor.product_features_b" taxonomy-name="productFeaturesB" :main-taxonomies="productFeaturesB"/>
          </div>
        </form-group>
        <form-group :label="productFeaturesCLabel" class="col-xs-12" :errors="errors.product_features_c"
                    :class="{'actor-edit__private-field': isPrivateField('product_features_c')}"
                    v-if="productFeaturesC.length > 0 && isRelevantForActor('product_features_c', modifiedActor)">
          <div class="card">
            <two-level-taxonomies v-model="modifiedActor.product_features_c" taxonomy-name="productFeaturesC" :main-taxonomies="productFeaturesC"/>
          </div>
        </form-group>

        <form-group :label="$t('add_actor_sdg')" class="col-xs-12" :errors="errors.sustainability_goal"
                    :class="{'actor-edit__private-field': isPrivateField('sustainability_goal')}">
          <dropdown
            placeholder=""
            multiple
            :search="true"
            :options="sustainabilityGoals"
            v-model="modifiedActor.sustainability_goal"
            :suggestions="pendingSustainabilityGoalSuggestions"
            @suggestion:accept="value => decideSustainabilityGoalSuggestion(value, 'accepted')"
            @suggestion:decline="value => decideSustainabilityGoalSuggestion(value, 'denied')"
          />
        </form-group>

        <form-group :label="$t('edit_actor_target_markets')" class="col-xs-12" :errors="errors.market" :class="{'actor-edit__private-field': isPrivateField('market')}">
          <dropdown multiple :search="true" :options="marketTargetOptions" v-model="modifiedActor.market"/>
        </form-group>
      </div>
    </form-section>
  </div>
</template>

<script>
  import Dropdown from '../../Dropdown/Dropdown.vue'
  import DsInput from '../../Form/DsInput.vue'
  import FormSection from '../../Form/FormSection.vue'
  import FormGroup from '../../Form/FormGroup.vue'
  import ConfigMixin from '../../../util/ConfigMixin.js'
  import DsTextarea from '../../Form/DsTextarea.vue'
  import { Activities } from '../../../api/taxonomies.js'
  import { ACTION_TYPES as TAXONOMY_ACTION_TYPES } from '../../../store/modules/taxonomies.js'
  import TranslationsMixin from '../../../util/TranslationsMixin.js'
  import {
    industryValueForSuggestionValue,
    isRelevantForActor,
    unArrayify,
  } from '../../../util/helpers.js'
  import Raven from 'raven-js'
  import { marketOptions, marketCountryOptions } from '../../../constants/config.js'
  import Domains from '../../Form/Domains.vue'
  import Checkbox from '../../Form/Checkbox.vue'
  import TwoLevelTaxonomies from '../../Form/TwoLevelTaxonomies.vue'
  import AutocompleteDropdown from '../../Dropdown/AutocompleteDropdown.vue'

  export default {
    name: 'actor-edit-activities',
    props: {
      modifiedActor: Object,
      errors: Object,
      allIndustrySuggestions: Array,
      pendingSuggestionsForProperty: Function,
      taxonomyValueForSuggestionValue: Function,
      decideSuggestion: Function,
      show: Object,
    },
    data () {
      return {
        newActivityName: '',
        newIndustryName: '',
        newTechnologyName: '',
        noResultMessageActivity: {
          text: 'Other',
          smallText: 'Create new activity',
        },
        noResultMessageTechnology: {
          text: 'Other',
          smallText: 'Create new technology',
        },
        noResultMessageIndustry: {
          text: 'Other',
          smallText: 'Create new industry',
        },
        marketOptions,
        marketCountryOptions,
      }
    },
    computed: {
      domainsLabel () {
        return this.getTaxonomyAlias('domains', false, this.$t('actor_edit_domains'))
      },
      productFeaturesALabel () {
        return this.getTaxonomyAlias('productFeaturesA', false, 'Product Features A')
      },
      productFeaturesBLabel () {
        return this.getTaxonomyAlias('productFeaturesB', false, 'Product Features B')
      },
      productFeaturesCLabel () {
        return this.getTaxonomyAlias('productFeaturesC', false, 'Product Features C')
      },
      domains () {
        return this.$store.state.taxonomies.domains
      },
      productFeaturesA () {
        return this.$store.state.taxonomies.productFeaturesA
      },
      productFeaturesB () {
        return this.$store.state.taxonomies.productFeaturesB
      },
      productFeaturesC () {
        return this.$store.state.taxonomies.productFeaturesC
      },
      sustainabilityGoals () {
        return [
          { 'value': 'SDG 1: No poverty', 'label': this.$t('edit_actor_sdg_1') },
          { 'value': 'SDG 2: Zero hunger', 'label': this.$t('edit_actor_sdg_2') },
          { 'value': 'SDG 3: Good health and well-being for people', 'label': this.$t('edit_actor_sdg_3') },
          { 'value': 'SDG 4: Quality education', 'label': this.$t('edit_actor_sdg_4') },
          { 'value': 'SDG 5: Gender equality', 'label': this.$t('edit_actor_sdg_5') },
          { 'value': 'SDG 6: Clean water and sanitation', 'label': this.$t('edit_actor_sdg_6') },
          { 'value': 'SDG 7: Affordable and clean energy', 'label': this.$t('edit_actor_sdg_7') },
          { 'value': 'SDG 8: Decent work and economic growth', 'label': this.$t('edit_actor_sdg_8') },
          { 'value': 'SDG 9: Industry; Innovation; and Infrastructure', 'label': this.$t('edit_actor_sdg_9') },
          { 'value': 'SDG 10: Reducing inequalities', 'label': this.$t('edit_actor_sdg_10') },
          { 'value': 'SDG 11: Sustainable cities and communities', 'label': this.$t('edit_actor_sdg_11') },
          { 'value': 'SDG 12: Responsible consumption and production', 'label': this.$t('edit_actor_sdg_12') },
          { 'value': 'SDG 13: Climate action', 'label': this.$t('edit_actor_sdg_13') },
          { 'value': 'SDG 14: Life below water', 'label': this.$t('edit_actor_sdg_14') },
          { 'value': 'SDG 15: Life on land', 'label': this.$t('edit_actor_sdg_15') },
          { 'value': 'SDG 16: Peace; justice and strong institutions', 'label': this.$t('edit_actor_sdg_16') },
          { 'value': 'SDG 17: Partnerships for the goals', 'label': this.$t('edit_actor_sdg_17') },
        ]
      },
      pendingSustainabilityGoalSuggestions () {
        return this.pendingSuggestionsForProperty('sustainability_goal')
          .map(v => v.value[0].name)
      },
      pendingTechnologySuggestions () {
        return this.pendingSuggestionsForProperty('technology')
          .map(v => this.taxonomyValueForSuggestionValue(v.value[0], 'technologies'))
      },
      technologies () {
        return this.$store.state.taxonomies.technologies
      },
      technologyLabel () {
        return this.getTaxonomyAlias('technology', false, this.$t('actor_edit_technology'))
      },
      // @deprecated
      subindustries () {
        if (!this.modifiedActor.industries || !this.modifiedActor.industries.length) {
          return []
        }

        const subIndustries = []
        this.modifiedActor.industries.forEach(id => {
          const industry = this.industries.find(i => i.id === parseInt(id))
          if (industry && industry.industries) {
            industry.industries.forEach(i => {
              subIndustries.push({ value: i.id, label: this.getLabelForTaxonomyValue('industries', i.id) })
            })
          } else if (!industry) {
            Raven.setExtraContext({
              actor_id: this.modifiedActor.id,
              industry_id: id,
              industry_id_type: typeof id,
              industries: this.modifiedActor.industries,
            })
            Raven.captureMessage('Unexpected industry in ActorEdit')
          }
        })

        return subIndustries
      },
      marketTargetOptions () {
        return this.marketOptions.concat(this.marketCountryOptions)
      },
      allPendingIndustrySuggestions () {
        return this.pendingSuggestionsForProperty('industries').map(s => unArrayify(s.value))
      },
      pendingIndustrySuggestions () {
        return this.allPendingIndustrySuggestions
          .filter(v => this.isValidIndustry(this.allIndustrySuggestions, v) && !v.industry_id)
          .map(v => industryValueForSuggestionValue(v, this.industries))
      },
      pendingSubindustrySuggestions () {
        if (!this.allPendingIndustrySuggestions) {
          return []
        }

        return this.allPendingIndustrySuggestions
          .filter(v => this.isValidIndustry(this.allIndustrySuggestions || [], v) && v.industry_id)
          .map(v => industryValueForSuggestionValue(v, this.industries))
      },
      industries () {
        return this.$store.state.taxonomies.industries.map(i => {
          return { ...i, children: i.industries }
        })
      },
      industriesOptions () {
        return this.industries.map(i => {
          return { value: i.id, label: this.getLabelForTaxonomyValue('industries', i.id) }
        })
      },
      industriesLabel () {
        return this.getTaxonomyAlias('industries', false, this.$t('actor_edit_industries'))
      },
      // @deprecated
      subIndustriesLabel () {
        return this.getTaxonomyAlias('subindustries', false, this.$t('actor_edit_subindustries'))
      },
      pendingActivitySuggestions () {
        return this.pendingSuggestionsForProperty('activities')
          .map(v => this.taxonomyValueForSuggestionValue(v.value[0], 'activities'))
      },
      actorType: {
        get () {
          return (this.modifiedActor.type && this.modifiedActor.type.split('/').map(t => t.trim())) || []
        },
        set (v) {
          if (v && v.length) {
            v.sort()
            this.modifiedActor.type = v.filter(t => t !== 'other').join('/')
          } else {
            this.modifiedActor.type = 'other'
          }
        },
      },
      activitiesLabel () {
        return this.getTaxonomyAlias('activities', false, this.$t('actor_edit_activities'))
      },
      isPublisherEnabled () {
        return this.$store.getters.isPublisherEnabled
      },
      publicFields () {
        return this.$store.getters.publicFields
      },
      activityOptions () {
        return this.activityValuesAndLabels
      },
      isEcosystemMember () {
        return this.$store.getters.isActor
      }
    },
    methods: {
      isValidIndustry (suggestedIndustries, industry) {
        if (!suggestedIndustries || !this.industries) {
          return false
        }

        if (!industry.industry_id) {
          return true
        } else if (this.industries.find(i => i.id === industry.industry_id) !== undefined) {
          return true
        } else {
          return suggestedIndustries.find(i => i.id === industry.industry_id) !== undefined
        }
      },
      acceptIndustrySuggestion (id) {
        const value = this.industrySuggestionValueForValue(id)
        this.decideSuggestion('industries', value, 'accepted')

        // Also accept or add parent industry if it's not there yet
        if (
          value.industry_id &&
          this.modifiedActor.industries.find(i => i === value.industry_id) === undefined &&
          this.modifiedActor.industries.find(i => i.value && i.value.id === value.industry_id) === undefined
        ) {
          const suggestedIndustry = this.allIndustrySuggestions.find(s => s.id === value.industry_id)

          if (suggestedIndustry) {
            this.modifiedActor.industries.push({ value: suggestedIndustry, label: suggestedIndustry.name })
            this.decideSuggestion('industries', suggestedIndustry, 'accepted')
          } else {
            this.modifiedActor.industries.push(value.industry_id)
          }
        }
      },
      denyIndustrySuggestion (id) {
        const value = this.industrySuggestionValueForValue(id)
        this.decideSuggestion('industries', value, 'denied')
      },
      industrySuggestionValueForValue (value) {
        const industry = this.industries.find(i => i.id === value)

        if (industry) {
          return industry.name
        } else if (value.value !== undefined) {
          return value.value
        } else {
          return value
        }
      },
      decideTechnologySuggestion (value, decision) {
        this.decideSuggestion('technology', value, decision)
      },
      decideActivitySuggestion (value, decision) {
        this.decideSuggestion('activities', value, decision)
      },
      decideSustainabilityGoalSuggestion (value, decision) {
        this.decideSuggestion('sustainability_goal', value, decision)
      },
      isPrivateField (field) {
        return this.isPublisherEnabled && !this.publicFields.includes(field)
      },
      isRelevantForActor: isRelevantForActor,
      addNewActivity () {
        const activityList = this.activityLabels
        const name = this.newActivityName
        const index = activityList.indexOf(this.newActivityName)
        if (index < 0) {
          Activities
            .post([{
              name,
              translations: {
                en:
                  { name },
              },
            }])
            .catch(errors => {
              console.log('Failed to add activity', errors)
            })

          this.$store.dispatch(TAXONOMY_ACTION_TYPES.REFRESH_ALL_TAXONOMIES)
          this.modifiedActor.activities.push(this.newActivityName)
          this.show.addActivity = false
        } else {
          this.errors.activities = this.$t('actor_edit_taxonomy_exists')
        }
      },
      startAddingActivity (name) {
        this.show.addActivity = true
        this.newActivityName = name

        setTimeout(() => {
          this.$refs.newActivityNameInput.focus()
        }, 0)
      },
    },
    components: {
      AutocompleteDropdown,
      TwoLevelTaxonomies,
      Dropdown,
      DsInput,
      FormSection,
      FormGroup,
      Domains,
      Checkbox,
      DsTextarea,
    },
    mixins: [ConfigMixin, TranslationsMixin],
  }
</script>
