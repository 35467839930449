<template>
  <div>
    <div class="manage-search-button__container" style="display: flex">
      <ds-button variant="minimal" class="manage-search-button" size="extra-small"
                 icon="cog"
                 @click="toggleOpen"/>
    </div>
    <transition name="modal" v-if="isOpen">
      <div class="modal__mask" @click="blur">
        <div class="modal__wrapper modal__wrapper-large">
          <div class="modal__container modal__container__all-filters">
            <div class="modal__navigation scrollable">
              <div class="modal__navigation__button-container" v-for="item in navigationTitles">
                <ds-button
                  :class="{ 'modal__navigation__button-active' : item === selectedTab } "
                  :label="getTitleForTab(item)"
                  variant="minimal"
                  size="small"
                  @click="selectedTab = item"
                />
              </div>
            </div>
            <div class="modal__body modal__body__manage-concept-search">
              <div class="modal__header">
                <h4 class="title">{{getTitleForTab()}} settings for {{conceptSearch.title}}</h4>
                <hr class="divider">
                <button class="close" @click="isOpen = false">×</button>
              </div>
              <h2 class="h2"></h2>
              <div v-if="selectedTab === 'refresh'">
                <p style="margin-bottom: 10px; opacity: 0.7;">
                  Refresh the results of this search to include the most recent articles
                </p>
                <ds-button variant="primary" class="a-button" size="extra-small"
                           :label="triggeringSearch ? 'refreshing...' : 'refresh'"
                           id="introjs-refresh" @click="$emit('refresh-now'); triggeringSearch = true"
                           style="margin-top: 5px"
                           :disabled="triggeringSearch || conceptSearch.status === 'in_progress'"/>
              </div>
              <div v-if="selectedTab === 'timespan'">
                <p style="margin-bottom: 10px; opacity: 0.7;">
                  Select which timespan you would like to find articles for
                </p>
                <radio-button :model-value="conceptSearch.timespan" name="timespan" val="month"
                              label="month" :inline="true" type="radio-button"
                              style="color: rgb(71,71,71);" @update:modelValue="updateTimespan"/>
                <radio-button :model-value="conceptSearch.timespan" name="timespan" val="year"
                              label="year" :inline="true" type="radio-button"
                              style="color: rgb(71,71,71);" @update:modelValue="updateTimespan"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
  import Checkbox from '../Form/Checkbox.vue'
  import RadioButton from '../Form/RadioButton.vue'
  import Modal from '../Modals/Modal.vue'
  import { defineComponent } from 'vue'
  export default defineComponent({
    props: {
      conceptSearch: Object,
    },
    data () {
      return {
        triggeringSearch: false,
        isOpen: false,
        selectedTab: 'refresh',
        navigationTitles: ['refresh', 'timespan']
      }
    },
    computed: {
      isExplorationOnly () {
        return this.$store.getters.isExplorationOnly
      },
    },
    methods: {
      blur () {
        if (this.closeOnBlur) {
          this.isOpen = false
        }
      },
      getTitleForTab (tab) {
        switch (tab || this.selectedTab) {
          case 'refresh':
            return 'Refresh'
          case 'timespan':
            return 'Timespan'
          default:
            return ''
        }
      },
      updateTimespan (newValue) {
        if (newValue !== this.conceptSearch.timespan) {
          this.$emit('update-timespan', newValue)
        }
      },
      toggleOpen () {
        this.isOpen = true
      },
    },
    components: {
      RadioButton,
      Checkbox,
      Modal,
    },
  })
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

  .modal__body__manage-concept-search {
    color: black;
    padding: 0 20px;
    width: 100%;
    text-align: left;
    text-shadow: none;
  }

  .manage-search-button__container {
    display: flex;
    height: 100%;

    .manage-search-button.button {
      padding: 12px;
      margin: 0 0 5px auto;
      border: 1px solid $color-background-light-grey;
      background-color: white;

      path {
        fill: $color-text-grey-light;
      }
      &:hover {
        path {
          fill: $color-secondary;
        }
      }
    }
  }
</style>
