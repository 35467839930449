<template>
  <transition name="action-bar" appear>
    <div class="action-bar" v-if="editing">
      <slot />
    </div>
  </transition>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
  props: ['editing']
})
</script>

<style lang="scss">
  @import "../../../scss/variables.scss";

.has-action-bar {
  margin-bottom: 60px!important;
}
.action-bar {
  z-index: $model-z-index-action-bar;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  border-top: 1px solid #ccc;
  background: white;
  height: 60px;
  transition: transform .3s;
  display: flex;
  align-items: center;
  padding: 0 20px;
}
.action-bar-enter-from,
.action-bar-leave-to {
  transform: translateY(50px);
}

.action-bar__message {
  margin-left: 1rem;
}
</style>
