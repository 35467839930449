<template>
  <modal :id="modalId" ref="modal" :enable-mask="false" :allow-overflow="true" wrapperSize="flex" size="flex" :title="$t('edit_actor_nace_codes')" :is-closeable="true">
    <template v-slot:body>
      <div class="edit-nace-codes__body">
        <ds-input icon="search" :model-value="naceSearchQuery" @update:modelValue="updateQuery" :placeholder="$t('edit_actor_nace_codes_search_cta')" class="edit-nace-codes__search-input"/>
        <nace-code-picker
          :actorId="actorId"
          :actorNaceCodes="naceCodes"
          :mainCode="mainCode"
          :searchQuery="query"
          @naceCodesChanged="updateNaceCodes"
          @mainNaceChanged="updateMainCode"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import NaceCodePicker from '../NaceCodePicker/NaceCodePicker.vue'

  import MODAL_IDS from '../../constants/modal-ids'


  import debounce from 'lodash/debounce'

  export default {
    name: 'EditNaceCodes.vue',
    data () {
      return {
        modalId: MODAL_IDS.EDIT_NACE_CODES,
        naceSearchQuery: '',
        query: '',
      }
    },
    computed: {
      actorId () {
        return this.modalContext.actorId
      },
      naceCodes () {
        return [...this.modalContext.naceCodes]
      },
      mainCode () {
        return this.modalContext.mainCode
      },
      modalContext () {
        return this.$store.state.ui.modalContext
      },
    },
    methods: {
      updateNaceCodes (naceCodes) {
        this.$bus.emit('actorNaceCodesUpdated', {
          actorId: this.actorId,
          naceCodes: naceCodes.codes,
          mainCode: naceCodes.mainCode
        })
      },
      updateMainCode (mainCode) {
        this.$bus.emit('actorMainNaceCodeUpdate', {
          actorId: this.actorId,
          mainCode: mainCode.mainCode
        })
      },
      updateQuery: debounce(function (value) {
        value = value.replace('.', '')
        this.query = value
      }, 1000),
    },
    components: {
      NaceCodePicker,
      Modal,
    },
  }
</script>

<style scoped lang="scss">
  .edit-nace-codes__body {
    min-width: 800px;
  }

  .edit-nace-codes__search-input {
    margin-bottom: 0.5em;
  }
</style>
