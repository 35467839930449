<template>
  <div class="knowledge-base-hero" :style="knowledgeBaseHeroStyling">
    <div class="container" :style="knowledgeBaseHeroContainerStyling">
      <BasicHero
        :style="basicHeroStyling"
        :title="onboardingTextConfig.knowledgeBaseSimplifiedTitle"
        :subtitle="onboardingTextConfig.knowledgeBaseSimplifiedSubtitle"
      >
        <slot v-if="$slots.searchbar" name="searchbar">
        </slot>
      </BasicHero>
    </div>
  </div>
</template>


<script>
  import BasicHero from '../BasicHero/BasicHero.vue'
  import OnboardingMixin from '../../util/OnboardingMixin.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {},
    data () {
      return {
        minHeight: 300,
      }
    },
    computed: {
      config () {
        return this.$store.state.config
      },
      image () {
        if (this.$store.getters.isStaging) {
          return '/images/static-home-page/static-home-page-foodleap.png'
        }

        return this.config.homepage_knowledge_base_image
      },
      isCommunityEcosystem () {
        return this.$store.getters.isCommunityEcosystem
      },
      knowledgeBaseHeroStyling () {
        if (this.isCommunityEcosystem) {
          return `background-image: url(${this.image}); min-height: ${this.minHeight}px; display: flex; justify-content: center; background-size: cover; background-repeat: no-repeat;`
        }

        return `background-color: var(--primary-community)`
      },
      knowledgeBaseHeroContainerStyling () {
        if (this.isCommunityEcosystem) {
          return `display: flex; justify-content: center;`
        }
      },
      basicHeroStyling () {
        if (this.isCommunityEcosystem) {
          return `background: transparent; color: #323441; display: flex; flex-direction: column; justify-content: center; align-items: center; padding-top: 0;`
        }
      },
    },
    methods: {},
    mixins: [OnboardingMixin],
    components: {
      BasicHero,
    },
  })
</script>

<style lang="scss" scoped>
  .knowledge-base-hero {
    z-index: 99;

    .container {
      margin: 0 auto;
    }
  }
</style>
