<template>
  <button class="button button--small" @click="handleChange" :class="classes">
    <input
      type="checkbox"
      :value="modelValue"
      :checked="checked"
      :disabled="disabled"
    >
    <icon :name="icon" class="checkbox__icon" v-if="icon !== undefined" />
    <span class="radio__label">{{ label || val }}</span>
  </button>
</template>

<script>
  import ICONS from '../../../icons/manifest.json'
  export default {
    data() {
      return {
        ICONS,
      }
    },
    props: {
      disabled: Boolean,
      block: String,
      label: String,
      multiple: Boolean,
      modelValue: {
        required: true
      },
      val: null,
      variant: {
        type: String,
        default: 'default'
      },
      size: {
        type: String,
        validator: val => ['extra-small', 'small', 'large'].includes(val)
      },
      icon:String
    },
    emits: ['update:modelValue'],
    computed: {
      checked () {
        if (this.multiple) {
          return (this.modelValue || []).includes(this.val)
        }
        return this.val ? this.modelValue == this.val : this.modelValue
      },
      classes () {
        return {
          'button--block': this.block !== undefined,
          [`button--${this.variant}`]: this.variant,
          [`button--${this.size}`]: this.size,
        }
      }
    },
    methods: {
      handleChange () {
        if (this.disabled) {
          return
        }

        // Multiple value model
        if (this.multiple) {
          var values = Array.isArray(this.modelValue) ? this.modelValue : []
          const index = values.indexOf(this.val)

          if (index < 0) {
            values.push(this.val)
          } else {
            values = values.filter(v => v !== this.val)
          }

          return this.$emit('update:modelValue', values)
        }

        // Normal checkbox
        this.$emit('update:modelValue', !this.val ? !this.modelValue : (this.checked ? null : this.val))
      }
    }
  }
</script>
