import { getCompletion } from '../../api/analytics'

const state = {
  loading: false,
  error: null,
  paging: {
    limit: 50,
    offset: 0
  },
  data: {
    "total_companies": 0,
    "labels": {},
    "fields": {},
    "past": []
  }
}

export const MUTATION_TYPES = {
  FETCH_COMPLETION_PENDING: 'COMPLETION/FETCH_COMPLETION_PENDING',
  FETCH_COMPLETION_SUCCESS: 'COMPLETION/FETCH_COMPLETION_SUCCESS',
  FETCH_COMPLETION_FAILURE: 'COMPLETION/FETCH_COMPLETION_FAILURE'
}

export const ACTION_TYPES = {
  FETCH_COMPLETION: 'COMPLETION/FETCH_COMPLETION'
}

export const mutations = {
  [MUTATION_TYPES.FETCH_COMPLETION_PENDING](state) {
    state.loading = true
  },
  [MUTATION_TYPES.FETCH_COMPLETION_SUCCESS](state, data) {
    if (!data || !data.fields) {
      return console.warn(JSON.stringify(data))
    }
    state.data = data
    state.loading = false
  }
}

export const actions = {
  [ACTION_TYPES.FETCH_COMPLETION]({ commit }) {
    commit(MUTATION_TYPES.FETCH_COMPLETION_PENDING)

    getCompletion()
      .then(data => {
        commit(MUTATION_TYPES.FETCH_COMPLETION_SUCCESS, data)
      })
  }
}

export const getters = {
  completionTable (state) {
    const total = state.data.total_companies
    var fields = state.data.fields

    if (!fields) return []

    var labels = state.data.labels

    var fieldsArr = Object.keys(fields)
    var history = Object.keys(state.data.past).reverse()

    var rows = []
    for (let i = fieldsArr.length - 1; i >= 0; i--) {
      const row = {
        prop: fieldsArr[i],
        title: labels[fieldsArr[i]] || fieldsArr[i],
        lower: (labels[fieldsArr[i]] || fieldsArr[i]).toLowerCase(),
        history: []
      }
      let previousValue = -1
      for (let j = history.length - 1; j >= 0; j--) {
        const value = state.data.past[history[j]].fields[fieldsArr[i]] || 0
        const htotal = state.data.past[history[j]].total_companies || total
        if (row.history.length) {
          row.history[row.history.length - 1].change = value - previousValue
          row.history[row.history.length - 1].changeStr = round2digits(100 * (value - previousValue) / htotal) || 0
        }
        row.history.push({
          value: value,
          pctStr: Math.round(value / htotal * 1000) / 10
        })
        previousValue = value
      }
      const value = fields[fieldsArr[i]] || 0
      if (row.history.length) {
        row.history[row.history.length - 1].change = value - previousValue
        row.history[row.history.length - 1].changeStr = round2digits(100 * (value - previousValue) / total) || 0
      }
      row.value = value
      row.total = total
      row.pctStr = Math.round(value / total * 1000) / 10
      rows.push(row)
    }
    return rows
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
}

export function round2digits (n) {
  return Math.round(n * 100) / 100
}
