<template>
  <div class="side-panel--wide">
    <div class="fixed-heading">
      <div class="card__general side-panel__header">
        <div class="card__title side-panel__announcement-card__title side-panel__announcement-header-card__title">{{ $t('edit_announcement_panel_title', {announcement: announcementLabel}) }}</div>
        <div class="pull-right side-panel__announcement-header-button-container"  style="text-align: right;">
          <ds-button icon="trash" variant="minimal" @click="remove(announcement)" size="small" class="side-panel__announcement-header-icon"/>
          <ds-button icon="remove" variant="minimal" @click="closeSidePanel" size="small" class="side-panel__announcement-header-icon"/>
        </div>
      </div>
    </div>
    <div class="scrollable side-panel__scrollable__content has-padding">
      <div class="side-panel__announcement-form-container">
        <form-group :label="$t('announcement_define_language', {announcement: announcementLabel})" v-if="$store.getters.isI18nEnabled">
          <dropdown :options="$store.getters.languageOptions" v-model="announcement.locale"/>
        </form-group>

        <form-group :label="$t('add_announcement_title')" :errors="errors.title">
          <ds-textarea v-model="localizedTitle" autoresize/>
          <other-locale-preview :editing-locale="editingLocale" :values="localizedTitleValues" style="margin-top: 10px;"/>
        </form-group>

        <form-group :label="announcementLabel" :errors="errors.body">
          <rich-text-area v-model="localizedBody" v-if="announcement.body" class="announcement-detail-body-style"></rich-text-area>
          <other-locale-preview :editing-locale="editingLocale" :is-html="true" :values="localizedBodyValues" style="margin-top: 10px;"/>
        </form-group>
        <form-group :label="$t('add_announcement_action_button_text')" :errors="errors.action_text">
          <ds-input v-model="announcement.action_text"/>
        </form-group>
        <form-group :label="$t('add_announcement_action_button_url')" :errors="errors.action_url">
          <ds-input v-model="announcement.action_url"/>
        </form-group>
        <form-group :label="$t('add_announcement_tags')" :errors="errors.tags" v-if="!challengesAreEnabled">
          <AutocompleteTagInput :placeholder="$t('add_announcement_tags_placeholder')"
                                @input:raw="updateTags" :tags="this.announcement.tags"
                                @tagChanged="handleTagChanged" :options="richTagOptions"/>
        </form-group>
        <form-group :label="$t('add_announcement_tags')" :errors="errors.tags" v-if="challengesAreEnabled">
          <AutocompleteTagInput :tags="announcement.tags" @tagChanged="handleTagChanged"
                                :options="tagOptions" :addOnlyFromAutocomplete="true"
                                :minInputLength="1"
                                @input:raw="updateTags"
                                :placeholder="$t('add_announcement_tags_placeholder')"/>
        </form-group>
        <form-group :label="$t('add_announcement_video')" :errors="errors.embedded_video">
          <ds-input :placeholder="$t('add_announcement_video_placeholder')" v-model="announcement.embedded_video"/>
        </form-group>
        <form-group :label="$t('add_announcement_image')" :errors="errors.image">
          <span class="featured-image__help-text">{{ $t('add_announcement_image_help_text', {announcement: announcementLabel}) }}</span>
          <image-input :model-value="announcementImage" width="200" height="100" @update:modelValue="updateImage"/>
        </form-group>
        <form-group>
          <div v-if="announcementData.id">
            <label class="label">
              This announcement has been sent to {{ announcementData.ecosystem_id ? 'the current ecosystem' : 'the entire platform'}} and is visible for every
              {{humanizeReach(announcementData.event)}}.
            </label>
          </div>
        </form-group>
      </div>
    </div>
    <action-bar editing="1" class="side-panel__announcement-bottom-section">
      <ds-button variant="secondary" size="extra-small" :label="$t('edit_announcement_update', {announcement: announcementLabel})" @click="save(announcement)"
                 v-if="announcementData.curation != 'in_progress' || $store.getters.isActor"/>
      <ds-button variant="secondary" size="extra-small" label="Approve & Update" @click="approve(announcement)" v-else/>
    </action-bar>
  </div>
</template>

<script>
  import RichTextArea from '../../components/TextArea/RichTextArea.vue'
  import AutocompleteTagInput from '../../components/Form/AutocompleteTagInput.vue'
  import Card from '../../components/Card/Card.vue'
  import ActionBar from '../../components/Form/ActionBar.vue'
  import RadioButton from '../../components/Form/RadioButton.vue'
  import ImageInput from '../../components/Form/ImageInput.vue'
  import Dropdown from '../../components/Dropdown/Dropdown.vue'
  import DsTextarea from '../../components/Form/DsTextarea.vue'
  import EditorLanguageDropdown from '../Translations/EditorLanguageDropdown.vue'
  import OtherLocalePreview from '../Translations/OtherLocalePreview.vue'

  import { Notifications } from '../../api/notifications'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import KEYCODES from '../../constants/keycodes'


  import { timeAgo } from '../../util/date'
  import diffObjects from '../../util/diff-objects'
  import TagsMixin from '../../util/TagsMixin'
  import { localizedEditProperty, localizedEditPropertyValues } from '../../util/TranslationsMixin'
  import { DEFAULT_LOCALE } from '../../store/modules/localization'
  import { inert } from '../../util/helpers'
  import { translateText } from '../../api/contents'
  import TranslationsMixin from '../../util/TranslationsMixin.js'

  export default {
    name: 'edit-announcement-panel',
    data () {
      return {
        announcement: {},
        errors: {},
        announcementImage: '',
        members: [],
        editor: null,
        editingLocale: DEFAULT_LOCALE,
        isTranslating: false,
      }
    },
    props: {
      announcementData: {
        type: Object,
        default: () => {}
      }
    },
    computed: {
      challengesAreEnabled () {
        return this.$store.state.config.challengesAreEnabled
      },
      messageBoardsAreEnabled () {
        return this.$store.state.config.messageBoardsAreEnabled
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      isDeveloper () {
        return this.$store.getters.isDeveloper
      },
      isPrivate () {
        return this.$store.getters.isPrivate
      },
      isPublic () {
        return this.$store.getters.isPublic
      },
      correctImagePath () {
        // The 'announcementData.image' only returns the name of the image, which is not a proper route to fetch the image that will be displayed in the image input field
        // Because of that the route bellow is used to get the image
        return `/notifications/${this.announcementData.id}/image`
      },
      localizedTitle: localizedEditProperty('editingLocale', 'announcement', 'title'),
      localizedTitleValues: localizedEditPropertyValues('editingLocale', 'announcement', 'title'),
      localizedBody: localizedEditProperty('editingLocale', 'announcement', 'body'),
      localizedBodyValues: localizedEditPropertyValues('editingLocale', 'announcement', 'body'),
    },
    methods: {
      timeAgo,
      /* handleMention (chunk) {
        //this.members = await fetchFromRemote(chunk)
        this.members = ['grace', 'kelly'];
      }, */
      humanizeReach (event) {
        switch (event) {
          case 'member':
            return 'team member'
          case 'actor':
            return 'ecosystem member'
          case 'owner':
            return 'ecosystem owner'
          case 'all':
            return 'user'
        }

        return 'user'
      },
      updateImage (imageData) {
        this.announcementImage = imageData
      },
      closeSidePanel () {
        if (this.announcementDiff() !== undefined) {
          if (window.confirm(this.$t('edit_announcement_unsaved_changes'))) {
            this.hidePreview()
            return
          }
          return
        }
        this.hidePreview()
      },
      hidePreview (evt) {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      save (item) {
        this.errors = {}
        const changes = this.announcementDiff()

        if (!changes) {
          // If there are no changes to the announcement we remove the image property as it is will not correctly formatted
          delete item.image
        } else {
          // If there are changes but none of them are for image property we remove the image property as it is will not correctly formatted
          if (changes.image == undefined) {
            delete item.image
          }
        }

        Notifications.post(item)
          .then(() => {
            // An event is sent to the Notifications page that will refetch the the notifications
            this.$bus.emit('announcementUpdated', {
              id: item.id,
              filteredTypes: ['liked', 'pinned', 'featured', 'calendar', 'ecosystem'],
            })
          })
          .then(() => {
            this.announcement = false
            this.hidePreview()
          })
          .catch(errors => {
            this.errors = errors
          })
      },
      updateTags (tags) {
        this.announcement.tags = tags.map(item => {
          if (item.optionValue) {
            return item.optionValue
          }

          // New keywords are identified by having both their value and text the same value
          return {
            label: item.text,
            value: item.text,
            text: item.text, // The autocomplete input library only supports "text", map it to the object that it expects, while also keeping the model our internal logic expects (=label)
          }
        })
      },
      handleTagChanged (tag) {
        // Update the options according to the given tag
        this.updateTagOptions(tag)
      },
      remove (item) {
        if (!window.confirm('Are you sure you want to remove this announcement?')) {
          return
        }
        Notifications.delete(item.id)
          .then(() => {
            this.announcement = false
            this.$bus.emit('announcementDeleted')
            this.hidePreview()
          })
      },
      approve (item) {
        Notifications.post(item)
          .then(() => {
            // An event is sent to the Notifications page that will refetch the the notifications
            this.$bus.emit('editAndAcceptedAnnouncement')
            this.hidePreview()
          })
          .catch(errors => {
            this.errors = errors
          })
      },
      announcementDiff () {
        return diffObjects(this.announcementData, this.announcement)
      },
      async handleAutoTranslate () {
        this.isTranslating = true

        try {
          const result = (await translateText({
            sourceLanguage: 'en',
            targetLanguage: this.editingLocale,
            body: this.announcement.title,
          }))
          if (result && result.body) this.localizedTitle = result.body
        } catch (e) {
          console.error(e)
        }

        try {
          const result = (await translateText({
            sourceLanguage: 'en',
            targetLanguage: this.editingLocale,
            body: this.announcement.body,
          }))
          if (result && result.body) this.localizedBody = result.body
        } catch (e) {
          console.error(e)
        }

        this.isTranslating = false
      },
    },
    mixins: [TagsMixin, TranslationsMixin],
    mounted () {
      window.addEventListener('keyup', (e) => {
        if (e.keyCode === KEYCODES.ESC) {
          this.hidePreview()
        }
      })

      // We make a copy of the data so that we can edit it
      this.announcement = inert(this.announcementData)

      if (!this.announcement.body) {
        this.announcement.body = '<div></div>'
      }

      // We fetch the image using the computed property 'correctImagePath' with the correct route because
      // the image property only returns the name of the image
      this.announcementImage = this.correctImagePath
    },
    watch: {
      announcement: {
        deep: true,
        handler () {
          this.announcementDiff()
        },
      },
      announcementImage (imageData) {
        // Check if the data for the image is different from the initial route given when loading the side panel
        // If so we update the announcement object which in turn will be sent to the back end
        if (imageData !== this.correctImagePath) {
          this.announcement.image = imageData
        }
      }
    },
    components: {
      EditorLanguageDropdown,
      OtherLocalePreview,
      Card,
      ActionBar,
      RadioButton,
      ImageInput,
      Dropdown,
      DsTextarea,
      AutocompleteTagInput,
      RichTextArea
    }
  }
</script>
