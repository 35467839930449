<template>
  <modal :allow-overflow="true" ref="modal" :id="modalId" @close="close" :title="$t('add_new_product', { product: productLabel })" :is-closeable="true">
    <template v-slot:body>
      <form-group :label="$t('add_actor_product_name', { product: productLabel })" :stable-height="true" :errors="errors.name">
        <ds-input
          v-model="productData.name"
          :placeholder="$t('add_actor_product_name_placeholder', { product: productLabel })"
          @input="resetErrors"
        />
      </form-group>
      <form-group :label="$t('add_actor_company_url')" :stable-height="true" :errors="errors.url" :style="showKeepAddressCheckbox && 'margin-bottom: 0'">
        <ds-input placeholder="" @input="resetErrors"/>
      </form-group>

      <template v-if="showKeepAddressCheckbox">
        <checkbox
          inline
          :label="$t('profile_use_same_address', {parentName: parentName || (selectedParent && selectedParent.name)})"
          v-model="keepAddress"
        />
        <br>
        <br>
      </template>

      <div class="row">
        <form-group class="col-xs-12" :label="getTaxonomyAliasWithDefault('category')" :key="productData.product_type"
          :class="{'col-sm-6' : !prefilledParentId}"
          v-if="categoryOptions && categoryOptions.length > 0">
          <dropdown force :options="categoryOptions" v-model="productData.category"/>
        </form-group>
        <form-group class="col-xs-12 col-sm-6" :label="$t('link_actor')" v-if="!prefilledParentId">
          <dropdown force :options="nonProductClaims" v-model="parentId"/>
        </form-group>
      </div>
      <form-group label="Tags">
        <!-- For some reason, vue-tags-input doesn't correctly lose focus in this modal, which is why nofocus is true (disables focus outline) -->
        <AutocompleteTagInput
          :tags="productData.tags"
          :options="tagOptions"
          :nofocus="true"
          :openUpwards="true"
          :placeholder="$t('add_actor_tags_placeholder')"
          @tagChanged="handleTagChanged"
          @tagsChanged="updateTags"
        />
      </form-group>
    </template>
    <template v-slot:footer>
      <ds-button
        @click="close"
        :label="addedProducts.length ? 'Stop adding' : $t('add_actor_cancel_button')"
        variant="outline"
        :disabled="busy"
      />
      <ds-button
        :label="$t('add_product_add_button', { product: productLabel })"
        variant="secondary"
        :icon="busy ? 'spinner' : 'plus'"
        :disabled="busy"
        @click="addProduct"
      />
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import MODAL_IDS from '../../constants/modal-ids'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import ConfigMixin from '../../util/ConfigMixin'
  import TagsMixin from '../../util/TagsMixin'
  import Dropdown from '../Dropdown/Dropdown.vue'
  import AutocompleteTagInput from '../../components/Form/AutocompleteTagInput.vue'
  import { fetchProfile } from '../../api/user.js'
  import { trackHeapEvent } from '../../util/analytics.js'
  import { createActor } from '../../api/actors.js'
  import Checkbox from '../Form/Checkbox.vue'

  export default {
    data () {
      const modalContext = this.$store.state.ui.modalContext || {}
      return {
        modalId: MODAL_IDS.ADD_PRODUCT,
        errors: {},
        productData: emptyProduct({
          category: null,
          ...modalContext.prefilled,
        }),
        parentId: null,
        selectedParent: null,
        prefilledParentId: '',
        keepAddress: false,
        parentName: '',
        busy: false,
        addedProducts: [],
        addedActors: [],
      }
    },
    computed: {
      showKeepAddressCheckbox () {
        return this.productData.address ||
          (this.selectedParent &&
            (
              (this.selectedParent.address && (this.selectedParent.address.city || this.selectedParent.address.street)) ||
              this.selectedParent.phone ||
              this.selectedParent.email
            )
          )
      },
      typeOptions () {
        return this.$store.getters.viewProductTypes
      },
      categoryOptions () {
        const list = this.categoryValuesAndLabelsPerType[this.productData.actor_type] || []

        if (list.length === 0) {
          return []
        }

        var categories = list.slice(0)

        categories = categories.sort((a, b) => {
          return a.label > b.label
        })

        return categories.map(item => ({
          value: item.value,
          label: item.label,
        }))
      },
      userProfile () {
        return this.$store.state.user.profile
      },
      nonProductClaims () {
        let actorClaims = this.userProfile.claims

        if (!this.userProfile.claims || this.userProfile.claims.length === 0) {
          return []
        }

        actorClaims = actorClaims.filter(claim => claim.actor_type !== 'Product')

        // eslint-disable-next-line no-unreachable
        return actorClaims.map((claim) => {
          return { value: claim.id, label: claim.name }
        })
      },
      modalContext () {
        return this.$store.state.ui.modalContext || {}
      },
    },
    methods: {
      resetErrors () {
        this.errors = {}
      },
      close () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.ADD_PRODUCT)
        this.$emit('close')
      },
      handleTagChanged (tag) {
        // Update the options according to the given tag
        this.updateTagOptions(tag)
      },
      updateTags (tags) {
        this.productData.tags = tags
      },
      addProduct () {
        trackHeapEvent('addProductModal.addProduct')

        this.busy = true

        if (!this.keepAddress) {
          delete this.productData.address
        } else if (this.selectedParent) {
          if (this.selectedParent.address && (this.selectedParent.address.street || this.selectedParent.address.city)) {
            this.productData.address = { ...this.selectedParent.address }
          }

          if (this.selectedParent.phone) {
            this.productData.phone = this.selectedParent.phone
          }

          if (this.selectedParent.email) {
            this.productData.email = this.selectedParent.email
          }
        }

        createActor(this.productData)
          .catch(error => {
            if (error && error.id) {
              if (!this.isMember) {
                // Go to existing product page
                this.$router.push('/actors/' + error.id)
                this.close()
              }
            }

            console.error(error)
          })
          .then(actor => {
            if (!actor || !actor.id) {
              throw new Error('finishCompletion expected an actor as return value')
            }

            this.$bus.emit('actorCreated')
            trackHeapEvent('global.addActor', { name: actor.name, id: actor.id })

            if (this.modalContext.successCallback) {
              try {
                actor.parentId = this.prefilledParentId
                if (this.selectedParent) {
                  actor.existingProductClaimsOfParent = this.selectedParent.has_product
                  actor.parentId = this.selectedParent.id
                }
                this.modalContext.successCallback(actor)
              } catch (e) {
                console.error('Problem with success callback (from modal context)')
                console.error(e)
              }
            }

            this.errors = {}

            if (!this.modalContext.afterCreate) {
              // Update user profile
              this.$store.commit('USER/HAS_CLAIMED', actor)
              fetchProfile()
                .then(profile => {
                  this.$store.commit('USER/UPDATE_PROFILE', profile)
                  this.$bus.emit('updateUserProfile')

                  this.close()
                })
            } else if (this.modalContext.afterCreate === 'goToActor') {
              // Update user profile
              this.$store.commit('USER/HAS_CLAIMED', actor)
              fetchProfile()
                .then(profile => {
                  this.$store.commit('USER/UPDATE_PROFILE', profile)
                  this.$bus.emit('updateUserProfile')

                  // Go to profile page
                  this.$router.push('/actors/' + actor.id)
                  this.close()
                })
            } else {
              this.close()
            }
          })
          .catch(this.handleErrors)
          .then(() => {
            this.busy = false
          })
      },
    },
    mounted () {
      if (this.productData.prefilledParentId) {
        this.prefilledParentId = this.productData.prefilledParentId
        // Delete from data because backend doesn't accept it
        delete this.productData.prefilledParentId
      }

      if (this.modalContext.globalsearchSearchQuery) {
        this.productData.name = this.modalContext.globalsearchSearchQuery
      }

      if (this.productData.address) {
        this.keepAddress = true
        this.parentName = this.productData.parentName
        delete this.productData.parentName
      }
    },
    mixins: [ConfigMixin, TagsMixin],
    components: {
      Modal,
      Dropdown,
      AutocompleteTagInput,
      Checkbox,
    },
    watch: {
      parentId: {
        handler () {
          if (this.parentId) {
            this.selectedParent = this.userProfile.claims.find(claim => claim.id === this.parentId)
          } else {
            this.selectedParent = null
          }
        },
      },
    },
  }

  function emptyProduct ({ ...other }) {
    return {
      id: null,
      actor_type: 'Product',
      name: null,
      actor_name: null,
      url: null,
      tags: [],
      ...other,
    }
  }
</script>

<style lang="scss" scoped>
  .row {
    margin-bottom: 0;
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-right: 0.5%;
  }
</style>
