<template>
  <div class="side-panel__portfolio-permissions-items">
    <div class="side-panel__portfolio-permissions-items-tilte-container">
      <h3>
        Manage users that are invited to this {{ challengeLabel }}
      </h3>
    </div>

    <div>
      <form-group>
        <div class="side-panel__portfolio-permissions-items-keyword-container">
          <avatar-keyword
            v-for="(user, index) in invitedUsers"
            removeable
            :key="'invited-user__' + index"
            :label="user.name"
            @remove="removeInvitee(user)"
          />
        </div>
        <suggestion-input
          icon="search"
          placeholder="Add user"
          :api-settings="ecosystemMemberSuggestions"
          :clear-on-select="true"
          @actor="addInvitee"
        />
      </form-group>
    </div>
  </div>

  <action-bar editing="1" class="side-panel__announcement-bottom-section" v-if="invitedUsersChanged">
    <ds-button
      style="margin-top: 15px;"
      variant="secondary" size="extra-small" label="Update invited users"
      @click="saveInvitees()"
    />
  </action-bar>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import AvatarKeyword from '@/components/Keyword/AvatarKeyword.vue'
  import SuggestionInput from '@/components/SuggestionInput/SuggestionInput.vue'
  import ActionBar from '@/components/Form/ActionBar.vue'
  import TranslationsMixin from '@/util/TranslationsMixin'
  import { ecosystemMemberSuggestions } from '@/api/config'
  import { fetchInvitees, updateInvitees } from '@/Domain/Challenge/Api/challenges'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '@/store/modules/ui'

  declare interface BaseComponentData {
    invitedUsers: Array<any>,
    invitedUsersChanged: boolean,
  }

  export default defineComponent({
    name: "ChallengeUsersSidePanel",
    components: {
      ActionBar,
      SuggestionInput,
      AvatarKeyword
    },
    mixins: [TranslationsMixin],
    data (): BaseComponentData {
      return {
        invitedUsers: [],
        invitedUsersChanged: false,
      }
    },
    mounted () {
      this.fetchInvitees()
      this.$bus.on('challengeInviteesUpdated', this.fetchInvitees)
    },
    beforeUnmount() {
      this.$bus.off('challengeInviteesUpdated', this.fetchInvitees)
    },
    computed: {
      ecosystemMemberSuggestions,
    },
    methods: {
      addInvitee (user) {
        let inviteesChanged = false

        this.invitedUsers.forEach(item => {
          if (item.user_id === user.id) {
            inviteesChanged = true
          }
        })

        if (!inviteesChanged) {
          this.invitedUsers.push({
            user_id: user.id,
            name: user.name,
            photo_url: user.photo_url,
          })
          this.invitedUsersChanged = true
        }
      },
      removeInvitee (invitee) {
        let index = -1

        this.invitedUsers.forEach((item, itemIndex) => {
          if (item.user_id === invitee.user_id) {
            index = itemIndex
          }
        })

        if (index > -1) {
          this.invitedUsers.splice(index, 1)
          this.invitedUsersChanged = true
        }
      },
      fetchInvitees () {
        fetchInvitees(this.$route.params.id)
          .then(response => {
            this.invitedUsers = response
          })
      },
      saveInvitees () {
        updateInvitees(this.$route.params.id, this.invitedUsers)
          .then(response => {
            this.invitedUsers = response
            this.$bus.emit('challengeInviteesUpdated')
            this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
          })
      },
    }
  })
</script>

<style scoped lang="scss">

</style>
