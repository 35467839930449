<template>
  <div class="concept-search-page__container">
    <div class="heading">
      <div class="explore__title">
        <h1>Actor Scouting</h1>
        <p class="guide_text">Manage Actor Scoutings</p>
      </div>
    </div>
    <template v-if="!showingConceptSearchGuide">
      <div class="find-concept-search-input">
        <keyword-ds-input
          v-model="searchName" icon="search"
          placeholder="Find Concept searches..."/>
      </div>
      <div class="find-concept-search-input" v-if="isOwner">
        <div style="margin: 15px">
          <alternate-dropdown
            :options="allUsers"
            :selection="selectedUser"
            icon="chevron-down"
            size="small"
            variant="minimal"
            label="Created by"
            :close-on-selection="true"
            @input="selectUser"
          ></alternate-dropdown>
          <alternate-dropdown
            :options="[{name: 'recency', id: 1}, {name: 'name', id: 2}]"
            :selection="selectedSorting"
            icon="chevron-down"
            size="small"
            variant="minimal"
            label="Sort by"
            :close-on-selection="true"
            @input="selectSorting"
          ></alternate-dropdown>
        </div>
      </div>
    </template>
    <div class="scrollable">
      <section class="home__section" v-show="showingConceptSearchGuide">
        <NewCard
          :title="'SCOUT FOR RELEVANT ACTORS'" class="new-card--no-list-padding"
          id="introjs-create-concept-guide">
          <ActorScoutingGuide @created="handleConceptSearchCreated" @done="handleConceptSearchGuideDone"/>
        </NewCard>
      </section>

      <section class="home__section">
        <NewCard title="Explore your world">
          <p
            v-if="!hasArticleDigestEnabled && followedConceptSearchIds.length > 0"
            style="color: red">
            {{ $t('relevant_articles_cta_1') }}
            <router-link to="/profile/update#notification-settings">Notification
              Settings
            </router-link>
            {{ $t('relevant_articles_cta_2') }}
          </p>
          <template v-slot:headerRight v-if="canUserEditConceptSearch">
            <div>
              <ds-button
                v-if="canDisplayCreateMonitor"
                size="small"
                id="introjs-create-a-new-concept-search"
                :label="'START ACTOR SCOUTING'"
                icon="plus"
                variant="secondary"
                @click="canShowInstantMonitoring ? startInstantMonitoring() : showConceptSearchGuide()"
              />
            </div>
          </template>

          <p><em></em></p>
          <div class="row home__category_count_container">
            <div
              class="col col-xs-12 col-sm-4 col-md-3"
              style="display: block; position: relative;"
              v-for="conceptSearch in filteredConceptSearches">
              <div
                @click="handleConceptSearchClick(conceptSearch)"
                class="concept-search__wrapper__wrapper">
                <div
                  class="concept-search__wrapper"
                  :class="{'concept-search__wrapper--disabled': conceptSearch.status !== 'done'}"
                  :style="conceptSearchStyle(conceptSearch)">
                  <div class="concept-search__container">
                    <div class="top-right-buttons" @click.stop>
                      <ds-button
                        v-if="showingDeleteButton && conceptSearch.status === 'done'"
                        class="a-button delete-concept-search-button"
                        icon="trash"
                        :label="'Delete'"
                        heapEvent="conceptMap.clickDeleteSearch"
                        size="extra-small"
                        variant="outline"
                        @click="removeConceptSearch(conceptSearch)"
                      />
                    </div>
                    <div class="concept-search__title-and-description">
                      <div class="concept-search__title">
                        {{ truncate(conceptSearch.title, 30) }}
                      </div>
                      <div class="concept-search__description">
                        {{ conceptSearch.description }}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  class="concept-search__spinner"
                  v-if="conceptSearch.status !== 'done'">
                  <div>
                    <icon name="spinner"/>
                    Processing...
                  </div>
                  <div style="display: block; position: absolute; margin-top: 30px;">
                    <ds-button
                      size="small" label="Reset" variant="secondary"
                      @click.stop="resetStatus(conceptSearch.id)"
                      style="margin-left: auto; color: white;"
                      v-if="$store.getters.isUserOfDataScoutsTeam"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </NewCard>
      </section>
    </div>
  </div>
</template>

<script>
  import ExplorationKeywordInput from '../../components/ExplorationKeywordInput/ExplorationKeywordInput.vue'
  import AutocompleteDropdown from '../../components/Dropdown/AutocompleteDropdown.vue'
  import ConceptSearchGuide from '../../components/ConceptSearchGuide/ConceptSearchGuide.vue'

  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'
  import { humanize } from '../../constants/properties'

  import {
    deleteConceptSearch,
    explore,
    fetchConceptSuggestions,
    publishConceptSearch,
    resetExplorationStatus,
    storeConceptSearch,
    unpublishConceptSearch,
    updateConceptSearchAndExplore,
    validConceptSearchTitle,
  } from '../../api/exploration.js'

  import UiMixin from '../../util/UiMixin'

  import { trackHeapEvent } from '../../util/analytics'
  import { toDateString, toDayFullMonth, toOnlyDayMonthString } from '../../util/date.js'
  import NewCard from '../NewCard/NewCard.vue'
  import { truncate } from '../../util/helpers.js'
  import FollowButton from '../FollowButton/FollowButton.vue'
  import PublishButton from '../PublishButton/PublishButton.vue'
  import ConceptSearchMixin from '../../util/ConceptSearchMixin'
  import ls from '../../util/ls.js'
  import IntroJsMixin from '../../util/IntroJsMixin.js'
  import { ActionTypes as ConceptSearchesActionTypes, MutationTypes as CONCEPT_SEARCHES_MUTATION_TYPES } from '../../store/modules/concept-searches.js'
  import { fetchUsers } from '../../api/config.js'

  import BenchmarkDropdown from '../Dropdown/BenchmarkDropdown.vue'
  import KeywordDsInput from '../Form/KeywordDsInput.vue'
  import AlternateDropdown from '../Dropdown/AlternateDropdown.vue'
  import ManageConceptSearchPopup from '../ManageConceptSearchPopup/ManageConceptSearchPopup.vue'
  import SignalGuide from '../Signals/SignalGuide.vue'
  import ActorScoutingGuide from '../ActorScoutingGuide/ActorScoutingGuide.vue'

  const Tooltips = {
    KNOWLEDGE_BASE: 'Explore the knowledge base to get an overview of available content',
  }

  export default {
    name: 'dashboard',
    data () {
      return {
        homepageTemplate: this.$store.state.config.homepageTemplate || [],
        filters: {
          type: 'actor_scouting',
        },
        selectedSorting: { name: 'recency', id: 1 },
        selectedUser: null,
        allUsers: null,
        isFirstVisitForUser: !window.localStorage.getItem('datascouts.hasSeenEcosystemIntroduction'),
        searchTopics: [],
        Tooltips,
        searching: false,
        showingConceptSearchGuide: false,
        showingCreateSignal: false,
        searchName: '',
      }
    },
    computed: {
      canDisplayCreateSignalButton () {
        return this.isMember
      },
      canDisplayCreateMonitor () {
        return this.isOwner
      },
      canShowInstantMonitoring () {
        if (this.isExplorationOnly) {
          return false
        }

        return this.config.viewDashboard.includes('instant-monitoring')
      },
      conceptSearchAutoReloadEnabled: {
        get () {
          return this.$store.getters.conceptSearchAutoReloadEnabled
        },
        set () {
          this.$store.commit(CONCEPT_SEARCHES_MUTATION_TYPES.TOGGLE_AUTO_RELOAD)
        },
      },
      allConceptSearches () {
        return this.$store.state.conceptSearches.allConceptSearches || []
      },
      filteredConceptSearches () {
        let result = this.conceptSearches.filter(search => {
          if (this.searchName && search.title) {
            return search.title.toLowerCase().indexOf(this.searchName.toLowerCase()) >= 0
          }
          return true
        })

        if (this.selectedSorting && this.selectedSorting.name !== 'recency') {
          result = result.sort((s1, s2) => s1.title.localeCompare(s2.title))
        }

        return result
      },
      conceptSearches () {
        return this.allConceptSearches.slice(0).sort((a, b) => b.explored_at.localeCompare(a.explored_at))
      },
      allConceptSearchesPromise () {
        return this.$store.state.conceptSearches.allConceptSearchesPromise
      },
      isExplorationOnly () {
        return this.$store.getters.isExplorationOnly
      },
      hasArticleDigestEnabled () {
        return ['daily', 'weekly'].includes(this.$store.getters.userSettings.contentDigest)
      },
      canUserEditConceptSearch () {
        return this.isMember
      },
      isOwner () {
        return this.$store.getters.isOwner
      },
      onlySeesCuratedContent () {
        return this.$store.getters.onlySeesCuratedContent
      },
      showingDeleteButton () {
        return this.isOwner
      },
      config () {
        return this.$store.state.config
      },
      isDeveloper () {
        return this.$store.getters.isDeveloper
      },
      isMember () {
        return this.$store.getters.isMember
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      canSeeKnowledgeBase () {
        return this.$store.getters.hasAccessToKnowledgeBase && this.config.viewDashboard && this.config.viewDashboard.includes('knowledge-base')
      },
    },
    mounted () {
      this.fetchConceptSearches()
      this.$bus.on('refreshConceptSearches', () => {
        this.fetchConceptSearches()
        this.searching = false
      })

      this.$bus.on('stop-intro', (type) => {
        if (type === 'explore-content') {
          this.showingConceptSearchGuide = false
        }
      })

      if (window.createConceptSearch) {
        this.showConceptSearchGuide()
        window.createConceptSearch = false
      }

      this.$bus.on('conceptSearchDeleteConfirmation', (context) => {
        if (context.resource && context.resource.id) {
          deleteConceptSearch(context.resource.id)
            .then(response => {
              this.$bus.emit('refreshConceptSearches')
            })
            .catch(error => {
              console.error(error)
            })
        }
      })
    },
    beforeUnmount () {
      this.$bus.off('refreshConceptSearches')
      this.$bus.off('stop-intro')
    },
    methods: {
      truncate,
      toOnlyDayMonthString,
      toDayFullMonth,
      toDateString,
      trackHeapEvent,
      humanize,
      updateTimespan (newTimespan, conceptSearch) {
        updateConceptSearchAndExplore(conceptSearch.id, {
          ...conceptSearch, timespan: newTimespan,
        }).then(() => {
          this.fetchConceptSearches()
        })
      },
      startSearch (conceptSearch) {
        explore(conceptSearch.id)
          .then(response => {
            conceptSearch.status = 'in_progress'
          })
      },
      startIntroJs () {
        if (this.seenIntros.includes('exploreContent') || !this.isLoggedIn) {
          return
        }

        const config = this.buildIntroJsConfig(this.config, this.$store.getters.userRole)
        if (!config || !config.exploreContent || !config.exploreContent.steps) {
          return
        }

        config.exploreContent.steps = this.getIntroJsStepsConfig(config.exploreContent.steps)
        const intro = this.introJs().setOptions(config.exploreContent)
        this.currentIntro = intro

        const componentScope = this
        intro.onbeforechange(this.introJsBeforeStepCallback.bind(this))
        intro.onafterchange(this.introJsAfterStepCallback.bind(this))
        intro.oncomplete(function () {
          componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
        })
        intro.onexit(function () {
          componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
        })

        intro.start()
      },
      selectSorting (sortType) {
        this.selectedSorting = sortType
      },
      selectUser (user) {
        if (this.selectedUser && this.selectedUser.id === user.id) {
          this.selectedUser = null
          this.filters = {
            type: 'actor_scouting',
          }
        } else {
          this.selectedUser = user
          this.filters = { creatorEmail: user.email, type: 'actor_scouting' }
        }

        this.fetchConceptSearches()
      },
      startInstantMonitoring () {
        this.$router.push({ name: 'InstantMonitoring' })
      },
      showConceptSearchGuide () {
        this.showingConceptSearchGuide = true
        this.showingCreateSignal = false
        this.$nextTick(() => {
          if (this.currentIntro) {
            window.currentIntro.refresh()
          }
        })
      },
      showCreateSignal () {
        this.showingConceptSearchGuide = false
        this.showingCreateSignal = true
      },
      isFollowed (conceptSearch) {
        return this.conceptSearchIsFollowed[conceptSearch.id]
      },
      setFollowed (conceptSearch, followed) {
        if (followed) {
          this.followConceptSearch(conceptSearch.id)
        } else {
          this.unfollowConceptSearch(conceptSearch.id)
        }
      },
      isPublished (conceptSearch) {
        return conceptSearch.published || false
      },
      setPublished (conceptSearch, published) {
        if (published) {
          this.$store.commit(CONCEPT_SEARCHES_MUTATION_TYPES.PUBLISH_CONCEPT_SEARCH, conceptSearch.id)
          publishConceptSearch(conceptSearch.id)
        } else {
          this.$store.commit(CONCEPT_SEARCHES_MUTATION_TYPES.UNPUBLISH_CONCEPT_SEARCH, conceptSearch.id)
          unpublishConceptSearch(conceptSearch.id)
        }
      },
      removeConceptSearch (conceptSearch) {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          body: 'Are you sure you want to delete this search?',
          resource: conceptSearch,
          modalContextType: 'concept-search',
        })

        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
      },
      resetStatus (conceptSearchId) {
        resetExplorationStatus(conceptSearchId)
          .then(response => {
            this.fetchConceptSearches()
          })
      },
      openExploreModal () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          search_topics: this.searchTopics,
          onSaved: () => {
            this.searchTopics = []
            this.fetchConceptSearches()
          },
        })

        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.EXPLORE_CONTENT)
      },
      fetchConceptSearches () {
        this.$store.dispatch(ConceptSearchesActionTypes.FETCH_ALL_CONCEPT_SEARCHES, this.filters)

        this.allConceptSearchesPromise.then(() => {
          this.refreshSearchesIfContainsInProgress()
        })
      },
      refreshSearchesIfContainsInProgress () {
        if (this.conceptSearches.find(search => search.status === 'in_progress')) {
          setTimeout(() => {
            this.fetchConceptSearches()
          }, 30000)
        }
      },
      goToKnowledgeBase (selectFilterTag) {
        // First we clear the existing knowledgeBaseKeywords
        ls('knowledgeBaseKeywords', [])

        // After clearing we set the filter value
        ls('knowledgeBaseKeywords', [{ facet: 'tags', value: selectFilterTag }])
        this.$router.push('/dashboards/knowledge-base')
      },
      handleConceptSearchClick (conceptSearch) {
        this.$router.push({
          name: this.canShowInstantMonitoring ? 'InstantMonitoring' : 'ConceptMap',
          params:
            { id: conceptSearch.id },
        })
      },
      handleNewConceptSearchClick () {
        if (this.searchTopics.length === 0) {
          return
        }

        const searchTopics = this.searchTopics
        this.searching = true

        validConceptSearchTitle(this.searchTopics[0].label)
          .then(title => {
            return storeConceptSearch({
              title,
              report_type: null,
              context: [],
              exclude: [],
              search_topics: searchTopics,
              dictionary_context_id: [],
              description: '',
              geography_context: [],
              scope_description: '',
              scoring_config: [],
            })
          })
          .then(response => {
            this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
              result: response,
            })

            this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.EXPLORE_CONTENT)

            this.searching = false
            this.fetchConceptSearches()
          })
          .catch(err => {
            console.error(err)
            this.searching = false
          })

        this.searchTopics = []
      },
      openConceptSearchSidePanel (conceptSearch) {
        const params = { conceptSearchId: conceptSearch.id, allowManualSearch: true }

        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          component: 'concept-search-panel',
          metaData: params,
        })
      },
      conceptSearchStyle (conceptSearch) {
        return 'background-image: url(' + conceptSearch.image + ');'
      },
      handleConceptSearchCreated () {
        this.fetchConceptSearches()
      },
      handleConceptSearchGuideDone () {
        this.showingConceptSearchGuide = false
      },
      handleSignalGuideDone () {
        this.showingCreateSignal = false
      },
      autocompleteSearchTerm (query) {
        return fetchConceptSuggestions({ query: query, conceptType: 'any' })
          .then(response => {
            const options = []

            response.forEach(function (concept) {
              options.push({
                label: concept.value,
                value: concept.uri,
              })
            })

            return options
          })
          .catch(err => {
            console.log(err)
          })
      },
    },
    created () {
      // copied from portfolioFilters.vue..
      if (this.isOwner) {
        fetchUsers({ role: 'owner' }).then((response) => {
          this.allUsers = response.data.filter(user => user.verified)
        })
        this.$nextTick(() => this.startIntroJs())
      }
    },
    mixins: [UiMixin, ConceptSearchMixin, IntroJsMixin],
    components: {
      ActorScoutingGuide,
      SignalGuide,
      ManageConceptSearchPopup,
      AlternateDropdown,
      KeywordDsInput,
      BenchmarkDropdown,
      PublishButton,
      FollowButton,
      ConceptSearchGuide,
      ExplorationKeywordInput,
      NewCard,
      AutocompleteDropdown,
    },
  }
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

  .find-concept-search-input {
    border-bottom: 1px solid $color-borders;
    padding-bottom: 2px;

    input {
      height: 62px;
      margin-top: 2px;
    }

    .svg-icon.svg-icon--search {
      top: 2px;

      path {
        fill: var(--primary);
      }
    }
  }

  .concept-search-page__container {
    display: flex;
    flex-direction: column;
    height: 100%;

    .scrollable {
      padding-top: 1rem;
    }
  }

  .concept-search__wrapper {
    padding: 10px;
    padding-left: 20px;
    border: 1px solid #ccc;
    height: 225px;
    background-position: center;
    background-size: cover;
    color: white;
    text-shadow: 1px 1px 1px #000;
    cursor: pointer;
    font-size: 18px;
  }

  .concept-search__wrapper__wrapper {
    position: relative;
  }

  .concept-search__wrapper--disabled {
  }

  .concept-search__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;

    .top-right-buttons {
      text-align: right;

      .a-button {
        margin-right: 0;
        margin-bottom: 6px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .concept-search__edit {
    position: absolute;
    right: 1.5rem;
    top: 0.5rem;
    z-index: 1;

    .svg-icon {
      left: 3px !important;
    }

    .svg-icon--cog {
      left: 3px !important;
    }
  }

  .concept-search__title-and-description {
    margin-top: auto;
  }

  .concept-search__spinner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .concept-search__title {
    font-size: 36px;
  }

  .concept-search__description {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-height: 14px;
    max-height: 44px;
    padding-bottom: 2px;
  }

  .concept-search__stats {
    margin-top: auto;
    text-align: right;
    font-size: 14px;
  }

  .plus-container {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 120px;
    cursor: pointer;
  }

  .concept-search-bar {
    display: flex;
    align-items: center;
    padding-right: 1px;
    border: 1px solid #CECECE;
    max-width: 700px;
    margin: 0 auto;

    .button {
      margin-right: 0;
    }

    .multiselect.multiselect--datascouts {
      margin-right: 0;

      .multiselect__tags {
        border: none;
      }
    }
  }
</style>

<style lang="scss">
  .concept-search__spinner .fill-fg {
    fill: #000000 !important;
  }

  .concept-search__spinner .svg-icon {
    position: relative;
    top: 5px;
  }
</style>
