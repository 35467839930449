<template>
  <div>
    <div class="package-picker__container">
      <div v-if="countedPackages <= 0">
        <loading />
      </div>
      <div v-if="countedPackages > 0" class="row" style="width: 100%">
        <package-picker-item
          v-for="(packageOption, index) in packages"
          :title="packageOption.title"
          :sub-title="packageOption.sub_title"
          :prices="packageOption.prices"
          :features-info="packageOption.featuresInfo"
          :total-items="countedPackages"
          :key="'packageOption_option_' + index">
          <template v-slot:actions>
            <div class="package-picker__button">
              <ds-button :label="'Choose ' + packageOption.title"
                         variant="primary"
                         @click="selectPackage(packageOption, false)"/>
            </div>
            <div class="package-picker__button">
              <ds-button :label="'Start ' + packageOption.trial_period + '-week free trial'"
                         variant="primary"
                         @click="selectPackage(packageOption, true)"
                         v-if="packageOption.trial_period && packageOption.trial_period > 0"/>
            </div>
          </template>
        </package-picker-item>
      </div>
    </div>
  </div>
</template>

<script>
  import { EcosystemMemberPackages } from '../../../api/ecosystem-member-packages'
  import PackagePickerItem from '../../Profile/Subscription/PackagePickerItem.vue'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui.js'
  import MODAL_IDS from '../../../constants/modal-ids.js'
  import Loading from '../../../components/Dashboard/ConceptMap/Loading.vue'

  export default {
    name: 'PackagePicker.vue',
    data () {
      return {
        packages: [],
      }
    },
    computed: {
      currentEcosystem () {
        return this.$store.getters.currentEcosystem
      },
      countedPackages () {
        // Proxy object
        const packages = this.packages

        return packages.length
      },
      ecosystemName () {
        return this.currentEcosystem.displayName || this.currentEcosystem.name
      },
      emailAddress () {
        return this.$store.state.config.email
      },
      trialPackage () {
        return this.packages.find(packageItem => packageItem.trial_period)
      }
    },
    methods: {
      contactOwners () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          sendTo: 'owners',
          placeholder: 'Hello,' +
            '\nI would like to know more about your custom packages.',
          title: 'Custom Package',
          subject: 'Custom Package', // The subject that will be set in the email we send to owners
        })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CONTACT_SUPPORT, {})
      },
      selectPackage (selectedPackage, choseTrial) {
        this.$emit('packageSelected', { selectedPackage, useTrial: choseTrial })
      },
    },
    mounted () {
      EcosystemMemberPackages
        .get()
        .then(packages => {
          this.packages = packages
        })
        .catch(errors => {
          console.log(errors)
        })
    },
    components: {
      PackagePickerItem,
      Loading,
    },
  }
</script>

<style scoped lang="scss">
  @import "../../../../scss/variables";

  .package-picker__container {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
  }


  :deep(.modal__header .divider) {
    max-width: 100%;
  }
</style>
