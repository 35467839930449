<template>
  <div class="home__section__article-section article-section__news_feed">
    <div class="article-preview__media-container">
      <div class="card__general article-section__items">
        <div class="article-item__container">
          <div class="article-item__content-container">
            <div class="article-item__data-container article-item__data-container--full">
              <div class="article-item__title-container">
                <a :href="'/announcements/' + item.sql_media_id" target="_blank" class="article-item__article-section-title">{{ item.title }}</a>
                <div class="article-item__title-container__right">
                  <template v-if="isOwner">
                    <ds-button size="extra-small" variant="outline" icon="edit" @click="openAnnouncementEditSidePanel(item)"/>
                    <ds-button size="extra-small" variant="outline" icon="trash" @click="showAnnouncementConfirmationModal(item)"/>
                  </template>
                  <span class="article-item__date">{{ toDateString(item.date * 1000) }}</span>
                </div>
              </div>
              <template v-if="item.actor_id && item.actor_id.length > 0">
                <div class="article-item__article-section-poster"  v-for="mention in item.actor_id" @click="openActorSidePanel({actor_id: mention.id})">{{ mention.name }}</div>
              </template>
              <rich-tag v-for="(tag, index) in item.tags" :key="index" size="small" :tag="tag" @click="addTagToFilters({value:tag, origin: 'tags'})"/>
              <div class="article-item__description" v-if="item.shortRawText">{{ item.shortRawText }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import RichTag from '../../Tag/RichTag.vue';
  import { toDateString } from '../../../util/date';

  export default {
    components: { RichTag },
    props: {
      item: Object,
      maxScore: Number,
    },
    computed: {
      isOwner () {
        return this.$store.getters.isOwner;
      },
    },
    methods: {
      toDateString,
      openAnnouncementEditSidePanel() {
        this.$emit('open-announcement-edit-side-panel', this.item);
      },
      showAnnouncementConfirmationModal() {
        this.$emit('show-announcement-confirmation-modal', this.item);
      },
      openActorSidePanel(actorInfo) {
        this.$emit('open-actor-side-panel', actorInfo);
      },
      addTagToFilters(tagInfo) {
        this.$emit('add-tag-to-filters', tagInfo);
      },
    }
  }
</script>
