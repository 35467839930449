<template>
  <div class="fixed-heading">
    <div class="reporting-dashboard-container">
      <div class="reporting-dropdown">
        <form-group :label="'Choose reporting template'" style="display:flex;">
          <Dropdown
            :options="availableTemplates"
            v-model="currentTemplate"
            :model-value="currentTemplate"
          />
        </form-group>
      </div>

      <div v-if="showLoader" style="text-align: center; display: flex;width: 100%; margin: 50px auto; justify-content: center; align-items: center; flex-direction: column;">
        <span v-if="currentTemplateTitle" style="margin-bottom: 20px;">Loading report "{{ currentTemplateTitle }}"</span> <icon name="spinner" size="large"/>
      </div>

      <div v-if="!showLoader" style="padding: 10px 20px 0px 20px;">
        <h3 v-if="currentTemplateTitle">{{ currentTemplateTitle }}</h3>
      </div>

      <tabs v-if="!showLoader" :tabs="tabs" :saveToUrl="true">
        <template v-slot:[getTabName(0)]>
          <div>
            <div>
              <div class="has-padding">
                <div class="keyword" v-if="numberOfResults" style="height: 28px;padding-top: 0px;padding-bottom: 0px;">
                  <div class="keyword__label">{{ numberOfResults }} listed actors</div>
                </div>
                <ds-button :disabled="reportingData.length == 0" size="small" label="Export"
                           @click="exportCsv"/>
              </div>
              <div class="explore-pane explore-pane--right report-overview-table">
                <div style="overflow-x: auto;">
                  <table v-if="reportingData.length" class="table table--fill table--hover"
                         style="border: 0; border-top: 1px solid #CECECE">
                    <thead>
                    <tr>
                      <th-sort by="name">Name</th-sort>
                      <th-sort by="role" v-if="enablePartners" :options="filterOptions.role"
                               v-model="filters.role">Is partner/Submittee
                      </th-sort>
                      <th-sort by="created_at">Started at</th-sort>
                      <th-sort by="last_updated_at">Last update</th-sort>
                      <th-sort by="submitted_at">Submission at</th-sort>
                      <th-sort by="value" v-if="enablePartners">Partners</th-sort>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row, index) in reportingData" :key="'reporting-' + index">
                      <td>
                        <avatar :src="actorLogo(row)"
                                variant="border"></avatar>&nbsp;{{ row.name }}
                      </td>
                      <td v-if="enablePartners">
                        {{ row.role }}
                      </td>
                      <td>
                        {{ row.created_at }}
                      </td>
                      <td>
                        {{ row.last_updated_at }}
                      </td>
                      <td>
                        {{ row.submitted_at }}
                      </td>
                      <td v-if="enablePartners">
                        <div v-if="row.role == 'Submittee' && row.value">
                          <ul v-for="(v, i) in getParsedValue(row)" :key="'partner' + i"
                              style="list-style-type: none">
                            <li>{{ v.name }}</li>
                          </ul>
                        </div>
                        <span v-if="row.role == 'Partner' && row.value">{{ row.value }}</span>
                      </td>
                    </tr>
                    </tbody>
                  </table>
                  <div v-else class="explore__empty-state">
                    No actors found!
                  </div>
                </div>
                <pagination class="simple-pagination--bottom" :paging="paging"
                            v-if="numberOfResults > filters.limit" @change="setOffset"/>
              </div>

            </div>
          </div>
        </template>
        <template v-slot:[getTabName(1)]>
          <div class="has-padding">
            <div v-if="reportsLoading" style="text-align: center;">
              <icon name="spinner" size="large"/>
            </div>
            <template v-if="!reportsLoading">
              <SubmissionStatus :reports="insights.reports"
                                :template="currentTemplateTitle"/>
              <ReportSubmissions :reports="insights.submitted"
                                 :reportTitle="'completion of all reports'"
                                 :template="currentTemplateTitle"/>
              <ReportSubmissions :reports="insights.actors"
                                 :reportTitle="'number of submitted reports'"
                                 :template="currentTemplateTitle"/>
            </template>
          </div>
        </template>
      </tabs>

    </div>
  </div>
</template>

<script>
  // import ThSort from '../ListView/ThSort.vue'
  import ThSort from './ThSort.vue'
  import _throttle from 'lodash/throttle'
  import Avatar from '../Avatar/Avatar.vue'
  import SidePanelActor from '../ActorPreview/SidePanelActor.vue'
  import { actorLogo } from '../../util/actor'
  import PageItems from '../PageItems/PageItems.vue'
  import Pagination from '../Pagination/SimplePagination.vue'
  import Dropdown from '../Dropdown/Dropdown.vue'
  import SubmissionStatus from './Reporting/SubmissionStatus.vue'
  import ReportSubmissions from './Reporting/ReportSubmissions.vue'
  import { fetchReportInsights, fetchReportInsightsData } from '../../api/heartbeat'
  import Tabs from '../Tabs/Tabs.vue'
  import ReportMixin from '../../util/ReportMixin.js'
  import querify from '../../util/querify'
  import { defineComponent } from 'vue'

  export default defineComponent({
    mixins: [
      ReportMixin,
    ],
    data () {
      return {
        showLoader: true,
        reportsLoading: true,
        enablePartners: true,
        paging: {},
        numberOfResults: null,
        filters: {
          limit: 100,
          offset: null,
          order: null,
          role: null,
        },
        orderByProperty: null,
        orderByDirection: '',
        show: {
          columns: false,
        },
        reportingData: {},
        reportingListDefaultColumns: [
          'is_partner_submittee',
        ],
        insights: {
          reports: {},
          submitted: {},
          actors: {},
        },
        currentTemplate: '',
        currentTemplateTitle: '',
      }
    },
    created () {

      // if (this.$route.query && this.$route.query.heartbeat_date) {
      //  this.currentDate = this.$route.query.heartbeat_date
      // } else {
      // this.currentTemplate = this.availableTemplates[0].value
      // }

      // this.fetchInsights()
    },
    computed: {
      tabs () {
        return ['Report overview', 'Analytics']
      },
      selectedColumns () {
        var selected = []

        // if (this.reportingListSelectedColumns && this.reportingListSelectedColumns.length > 0) {
        //   selected = selected.concat(this.reportingListSelectedColumns.split(','))
        // } else {
        selected = selected.concat(this.reportingListDefaultColumns)
        // }

        return selected
      },
      availableTemplates () {
        if (!this.reportSettings || this.reportSettings.length === 0) {
          return []
        }

        // Set the first available open call / report
        if (!this.currentTemplate) {
          if (this.getOpenCall() && this.getOpenCall().id) {
            this.currentTemplate = this.getOpenCall().id
          } else {
            this.currentTemplate = this.reportSettings[0].id
          }
        }

        var reportOptions = this.reportSettings.map(report => {
          if (this.currentTemplate == report.id) {
            this.enablePartners = report.reportFields.filter(field => field.type == 'multi_select_actor').length > 0
          }

          return {
            value: report.id,
            label: report.name,
          }
        })

        return reportOptions
      },
      filterOptions () {
        var roles = [
          {
            text: 'Is partner',
            value: 'partner',
          },
          {
            text: 'Submittee',
            value: 'submittee',
          },
        ]

        var options = {
          role: [{ 'text': 'Show all', 'value': null }].concat(roles || []),
        }

        return options
      },
    },
    methods: {
      getTabName (index) {
        return this.tabs[index]
      },
      getParsedValue (row) {
        if (!row.value) {
          return
        }

        return JSON.parse(row.value)
      },
      actorLogo,
      fetchInsight: async function (key) {
        var loadingKey = key + 'Loading'
        this[loadingKey] = true

        this.insights[key] = await fetchReportInsights(key, this.currentTemplate)

        if ((key == 'actors') && this.insights[key].length == 0) {
          // Default values are empty objects, that's what the template components expect
          this.insights[key] = {}
        }

        if ((key == 'reports')) {
          this.currentTemplateTitle = this.insights['reports']['template_title']
        }

        this[loadingKey] = false
      },
      fetchInsights () {
        // Spinner
        this.showLoader = true

        for (const key of Object.keys(this.insights)) {
          this.fetchInsight(key)
        }

        // this.showLoader = false
      },
      setOffset (offset) {
        this.filters = Object.assign({}, this.filters, { offset })
      },
      exportCsv () {
        if (!this.currentTemplate) {
          return
        }

        document.location = `/api/settings/insights-reporting-data?template=${this.currentTemplate}&${querify(this.filters)}&export=true`
      },
      async fetch () {
        if (!this.currentTemplate) {
          return
        }

        this.showLoader = true
        await fetchReportInsightsData(this.filters, this.currentTemplate).then((response) => {
          this.showLoader = false
          this.reportingData = response.data
          this.paging = response.paging
          this.numberOfResults = response.total
        })
      },
      orderBy (property) {
        if (this.orderByProperty === property) {
          this.orderByDirection = (this.orderByDirection === '') ? '-' : ''
        } else {
          this.orderByProperty = property
        }

        this.filters.order = this.orderByDirection + this.orderByProperty
      },
    },
    watch: {
      currentTemplate (v) {
        this.fetch()
        this.fetchInsights()
      },
      filters: {
        deep: true,
        handler: _throttle(function () {
          this.fetch()
        }, 300, {
          trailing: true,
        }),
      },
    },
    components: {
      ThSort,
      Avatar,
      SidePanelActor,
      PageItems,
      Pagination,
      SubmissionStatus,
      ReportSubmissions,
      Dropdown,
      Tabs,
    },
  })
</script>
<style lang="scss">
  @import '../../../scss/variables';

  .reporting-dashboard-container {
    align-self: center;
    width: 100%;

    .tabs__navigation {
      margin: 0;
      padding: 20px;
      border-bottom: 1px solid $color-borders;

      .tabs__navigation__item {
        margin-bottom: 0;
      }
    }

    .reporting-dropdown {
      position: absolute;
      right: 15px;
      top: 20px;
      width: 100%;

      .form-group {
        display: flex;
        width: 100%;

        .form-group__header {
          margin-left: auto;
          line-height: 36px;
          margin-right: 5px;
          padding-bottom: 0;
        }
      }

      .multiselect {
        width: auto !important;
        min-width: 250px;
        cursor: pointer;
      }
    }

    .explore-pane {
      padding-bottom: 49px;
    }

    .chart-container {
      display: flex;
      position: relative;
    }

    hr {
      background-color: $color-borders;
      border: 0;
      height: 1px;
      margin: 20px 0;
    }

    .chart-main {
      flex: 1;
      max-width: 100%;
      padding-right: 226px;
    }

    .chart-aside {
      position: absolute;
      right: 0;
      top: 0;
      width: 200px;
    }

    .keyword {
      background-color: $color-primary !important;
      border: transparent !important;

      .keyword__label {
        color: white;
      }
    }
  }
</style>
