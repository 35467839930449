<template>
  <div class="proof-point-overview" @scroll="onScroll">
    <div class="container">
      <div class="buttons-container">
        <h4 class="proofpoint_filter--title">Filter by: </h4>
        <ds-button
          icon="circle-user"
          :class="hasActiveTeamFilter ? 'proofpoint_filter--button active-filter' : 'proofpoint_filter--button'"
          label="Team"
          @click="toggleFilterVisible('team')"
          icon-size="extra-small"
        />
        <multi-select-filter
          class="multiselect--filter"
          v-if="filters.team.active"
          :data="duplicate"
          :active-data="filters.team.value"
          type="team"
          @ProofPointFilterChanged="updateFilters"
          style="left: 75px;"
        />

        <ds-button
          icon="clock-outline"
          :class="hasActiveDateFilter ? 'proofpoint_filter--button active-filter' : 'proofpoint_filter--button'"
          label="Date"
          @click="toggleFilterVisible('date')"
          icon-size="extra-small"
        />

        <div style="left: 166px;" class="multiselect--filter multiselect--filter_datepicker" v-if="filters.date.active">
          <datepicker placeholder="Start date" v-model="filters.date.value.begin" input-id="date-start"/>
          <datepicker style="margin-top: 10px;" placeholder="End date" v-model="filters.date.value.end" input-id="date-end"/>
        </div>

        <ds-button
          icon="file-line"
          :class="hasActiveTypeFilter ? 'proofpoint_filter--button active-filter' : 'proofpoint_filter--button'"
          label="Type"
          @click="toggleFilterVisible('type')"
          icon-size="extra-small"
        />

        <multi-select-filter
          class="multiselect--filter"
          v-if="filters.type.active"
          :data="filters.type.data"
          :active-data="filters.type.value"
          type="type"
          @ProofPointFilterChanged="updateFilters"
          style="left: 255px;"
        />

        <ds-button
          icon="comment"
          :class="hasActiveArgumentFilter ? 'proofpoint_filter--button active-filter' : 'proofpoint_filter--button'"
          label="Argumentation"
          @click="toggleFilterVisible('argumentation')"
          icon-size="extra-small"
        />

        <div style="left: 341px;" class="multiselect--filter multiselect--filter_argumentation" v-if="filters.argumentation.active">
          <input placeholder="Search..." v-model="filters.argumentation.value">
        </div>

        <ds-button
          icon="analysis"
          :class="hasActiveAnalysisFilter ? 'proofpoint_filter--button active-filter' : 'proofpoint_filter--button'"
          label="Analysis"
          @click="toggleFilterVisible('analysis')"
          icon-size="extra-small"
        />

        <multi-select-filter
          class="multiselect--filter"
          v-if="filters.analysis.active"
          :data="filters.analysis.data"
          :active-data="filters.analysis.value"
          type="analysis"
          @ProofPointFilterChanged="updateFilters"
          style="left: 520px"
        />

        <p class="clear_filters--button" @click="clearFilters">Clear filters</p>

        <div class="proof-point-action-button-container">
          <ds-button :disabled="!hasSelectedProofPoints" @click="removeSelectedProofPoints" class="proofpoint__button-remove" label="Remove" variant="normal"/>
        </div>
      </div>

      <div v-if="isLoadingProofPoints">
        <Loading/>
      </div>
      <div v-else>
        <!--          <h4 class="h4">-->
        <!--            {{ proofPointGroup.type }}-->
        <!--          </h4>-->
        <div v-if="transformedProofPoints.length">
          <div v-for="proofPointData in transformedProofPoints">
            <h3 class="proofpoint__group-date">{{ proofPointData.date }}</h3>
            <div v-for="proofPoint in proofPointData.proofPoints" :key="'proofPoint' + proofPoint.id" class="proof-point-item">
              <!--              <proof-point-card :proof-point="proofPoint"/>-->
              <ProofPointCardNew
                :highlight="proofPoint.signalHighlight"
                :argumentation="proofPoint.argumentation"
                :actor="proofPoint.signalItem.name"
                :article="proofPoint.signalItem.title"
                :url="proofPoint.signalItem.signalItemType === 'actor' ? proofPoint.signalItem.id : proofPoint.signalItem.url"
                :logo="proofPoint.signalItem.logo"
                :id="proofPoint.id"
                :members="proofPoint"
                @selectProofPoint="updateSelectedProofPoints"
              />
            </div>
          </div>
        </div>
        <!--          <div v-else class="proof-point-item">-->
        <!--            {{ $t('signal_analysis_type_empty', { type: proofPointGroup.type, spottingArea: spottingAreaLabel }) }}-->
        <!--          </div>-->
        <div v-if="isLoadingMoreProofPoints">
          <Loading/>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import { deleteProofPoint, fetchProofPointsForSpottingArea } from '../../api/ProofPoints.js'
  import Dropdown from '../Dropdown/Dropdown.vue'
  import { proofPointAnalysisTypes, proofPointItemTypes } from '../../constants/proofPoints.js'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import Loading from '../Dashboard/ConceptMap/Loading.vue'
  import { ACTION_TYPES as SPOTTING_AREAS_ACTION_TYPES } from '../../store/modules/spottingareas'
  import ProofPointCard from './ProofPointCard.vue'
  import '../../../scss/_variables.scss'
  import Datepicker from '../../components/Form/Datepicker.vue'
  import MultiSelectFilter from '../../components/Filters/MultiSelectFilter.vue'
  import debounce from 'lodash/debounce'
  import _debounce from 'lodash/debounce'
  import ProofPointCardNew from './ProofPointCardNew.vue'
  import moment from 'moment'

  export default {
    data () {
      return {
        filters: {
          team: {
            active: false,
            data: [],
            value: []
          },
          date: {
            active: false,
            data: null,
            value: {
              begin: null,
              end: null
            }
          },
          type: {
            active: false,
            date: [],
            value: []
          },
          argumentation: {
            active: false,
            data: null,
            value: null
          },
          analysis: {
            active: false,
            data: [],
            value: []
          }
        },
        paging: {
          limit: 50,
          offset: 0
        },
        proofPoints: [],
        proofPointItemTypes,
        isLoadingProofPoints: false,
        selectedProofPoints: [],
        hasMore: true,
        length: 0,
        proofPointsData: [],
        isLoadingMoreProofPoints: false
      }
    },
    computed: {
      transformedProofPoints () {
        return [...new Set(this.groupProofPointsByDate(this.proofPoints))]
      },
      duplicate () {
        return [...new Map(this.filters.team.data.map(item => [JSON.stringify(item), item])).values()]
      },
      hasSelectedProofPoints () {
        return this.selectedProofPoints.length > 0
      },
      hasActiveTeamFilter () {
        return this.filters.team.value.length > 0
      },
      hasActiveDateFilter () {
        return this.filters.date.value.begin != null || this.filters.date.value.end != null
      },
      hasActiveTypeFilter () {
        return this.filters.type.value.length > 0
      },
      hasActiveArgumentFilter () {
        return this.filters.argumentation.value !== '' && this.filters.argumentation.value != null
      },
      hasActiveAnalysisFilter () {
        return this.filters.analysis.value.length > 0
      }
    },
    methods: {
      onScroll: _debounce(function (e) {
        if ((e.target.scrollTop + e.target.offsetHeight + 1000) > e.target.scrollHeight) {
          this.getMoreProofPoints()
        }
      }, 200),
      removeSelectedProofPoints () {
        this.selectedProofPoints.forEach(proofPoint => {
          deleteProofPoint(proofPoint)
            .then(response => {
              if (response.success) {
                this.fetchDataDebounced()
              }
            }).catch(err => {
            console.log(err)
          })
        })
      },
      updateSelectedProofPoints (data) {
        if (!this.selectedProofPoints.includes(data.id)) {
          this.selectedProofPoints.push(data.id)
        } else {
          this.selectedProofPoints = this.selectedProofPoints.filter(val => val !== data.id)
        }
      },
      setOffset (offset) {
        this.paging.offset = offset
        this.fetchDataDebounced()
      },
      setDefaultFilters () {
        this.filters.type.data = [
          {
            label: 'Article',
            icon: 'newspaper',
            value: proofPointItemTypes.FILE
          },
          {
            label: 'Highlight',
            icon: 'highlighter',
            value: proofPointItemTypes.HIGHLIGHT
          },
          {
            label: 'Actor',
            icon: 'building',
            value: proofPointItemTypes.ACTOR
          }
        ]
        this.filters.analysis.data = [
          {
            label: 'Landscape',
            icon: 'mountain',
            value: proofPointAnalysisTypes.INDUSTRY_FORCES
          },
          {
            label: 'Key trends',
            icon: 'signs-post',
            value: proofPointAnalysisTypes.KEY_TRENDS
          }
        ]

        this.filters.type.data.forEach(type => {
          this.filters.type.value.push(type.value)
        })

        this.filters.analysis.data.forEach(analysis => {
          this.filters.analysis.value.push(analysis.value)
        })
      },
      clearFilters () {
        this.filters.type.data.forEach(type => {
          this.filters.type.value.push(type.value)
        })

        this.filters.analysis.data.forEach(analysis => {
          this.filters.analysis.value.push(analysis.value)
        })

        this.filters.team.data.forEach(team => {
          this.filters.team.value.push(team.value)
        })

        this.filters.date.value.end = null
        this.filters.date.value.begin = null
        this.filters.argumentation.value = null

        this.fetchDataDebounced()
      },
      toggleFilterVisible (filter) {
        if (filter === 'team') {
          this.filters.team.active = !this.filters.team.active
          this.filters.date.active = false
          this.filters.type.active = false
          this.filters.analysis.active = false
          this.filters.argumentation.active = false
        } else if (filter === 'date') {
          this.filters.date.active = !this.filters.date.active
          this.filters.team.active = false
          this.filters.type.active = false
          this.filters.analysis.active = false
          this.filters.argumentation.active = false
        } else if (filter === 'type') {
          this.filters.type.active = !this.filters.type.active
          this.filters.team.active = false
          this.filters.date.active = false
          this.filters.analysis.active = false
          this.filters.argumentation.active = false
        } else if (filter === 'analysis') {
          this.filters.analysis.active = !this.filters.analysis.active
          this.filters.team.active = false
          this.filters.date.active = false
          this.filters.type.active = false
          this.filters.argumentation.active = false
        } else if (filter === 'argumentation') {
          this.filters.argumentation.active = !this.filters.argumentation.active
          this.filters.team.active = false
          this.filters.date.active = false
          this.filters.type.active = false
          this.filters.analysis.active = false
        }
      },
      updateFilters (data) {
        switch (data.type) {
          case 'team':
            this.filters.team.value = data.active
            break
          case 'type':
            this.filters.type.value = data.active
            break
          case 'analysis':
            this.filters.analysis.value = data.active
            break
        }

        this.fetchDataDebounced()
      },
      openCreateProofPointModal () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CREATE_PROOF_POINT)
      },
      fetchData () {
        this.isLoadingProofPoints = true
        if (this.$route.params.spottingAreaId) {
          const filters = {
            user_id: this.filters.team.value,
            start_date: this.filters.date.begin,
            end_date: this.filters.date.value.end,
            signal_analysis_type: this.filters.analysis.value,
            search_query: this.filters.argumentation.value,
            signal_item_type: this.filters.type.value,
            limit: this.limit,
            offset: this.offset,
            first: true
          }
          fetchProofPointsForSpottingArea(this.$route.params.spottingAreaId, filters).then((result) => {
            this.proofPoints = result
            const users = []

            this.proofPoints.map((proofPoint) => {
              const userObject = {
                label: proofPoint.userName,
                image: proofPoint.user_avatar,
                value: proofPoint.userId
              }

              if (!users.includes(userObject)) {
                users.push(userObject)
              }

              this.filters.team.value.push(userObject.value)
            })

            this.filters.team.data = [...new Set(users)]

            this.offset = this.proofPoints.length

            this.isLoadingProofPoints = false
            this.hasMore = result.length > 0
          }).catch((err) => {
            console.log(err)
          }).finally(() => {
            this.setDefaultFilters()
          })
        } else {
          this.$router.push('/spotting-areas')
        }
      },
      fetchDataDebounced: debounce(function () {
        this.isLoadingProofPoints = true
        const filters = {
          user_id: this.filters.team.value,
          start_date: this.filters.date.begin,
          end_date: this.filters.date.value.end,
          signal_analysis_type: this.filters.analysis.value,
          search_query: this.filters.argumentation.value,
          signal_item_type: this.filters.type.value,
          offset: 0,
          limit: this.paging.limit
        }
        fetchProofPointsForSpottingArea(this.$route.params.spottingAreaId, filters).then((result) => {
          this.proofPoints = result
          this.isLoadingProofPoints = false

          if (result.length > 0) {
            this.hasMore = true
          }

          this.offset = this.proofPoints.length
        })
      }, 500),
      getMoreProofPoints: debounce(function () {
        if (!this.hasMore) {
          return
        }

        this.isLoadingMoreProofPoints = true

        const filters = {
          user_id: this.filters.team.value,
          start_date: this.filters.date.begin,
          end_date: this.filters.date.value.end,
          signal_analysis_type: this.filters.analysis.value,
          search_query: this.filters.argumentation.value,
          signal_item_type: this.filters.type.value,
          offset: this.proofPoints.length,
          limit: this.paging.limit
        }

        fetchProofPointsForSpottingArea(this.$route.params.spottingAreaId, filters).then((result) => {
          this.isLoadingMoreProofPoints = false
          if (result.length === 0) {
            this.hasMore = false
            return
          }

          this.proofPoints = this.proofPoints.concat(result)

          let users = []

          this.proofPoints.map((proofPoint) => {
            const userObject = {
              label: proofPoint.userName,
              image: proofPoint.user_avatar,
              value: proofPoint.userId
            }

            if (!users.includes(userObject)) {
              users.push(userObject)
            }

            this.filters.team.value.push(userObject.value)
          })

          this.filters.team.data = [...new Set(users)]

          this.paging.offset = this.proofPoints.length
        })

      }, 200),
      groupProofPointsByDate (proofPoints) {
        const groups = proofPoints.reduce((groups, proofPoint) => {
          const date = proofPoint.createdAt.split(' ')[0]
          if (!groups[date]) {
            groups[date] = []
          }
          groups[date].push(proofPoint)
          return groups
        }, {})

        proofPoints = Object.keys(groups).map((date) => {
          let data = groups[date]
          let today = moment().format('YYYY/MM/DD')
          let yesterday = moment().subtract(1, 'days').format('YYYY/MM/DD')
          let formatted = moment(date).format('YYYY/MM/DD')
          if (today === formatted) {
            date = 'Today'
          } else if (yesterday === formatted) {
            date = 'Yesterday'
          } else {
            date = moment(date).format('DD/MM/YYYY')
          }
          return {
            date: date,
            proofPoints: data
          }
        })
        return proofPoints
      }
    },
    watch: {
      'filters.date.value.begin' () {
        this.fetchDataDebounced()
      },
      'filters.date.value.end' () {
        this.fetchDataDebounced()
      },
      'filters.argumentation.value' () {
        this.fetchDataDebounced()
      }
    },
    mounted () {
      if (!this.$store.state.spottingAreas.listData.loading && !this.$store.state.spottingAreas.listData.data.length) {
        this.$store.dispatch(SPOTTING_AREAS_ACTION_TYPES.FETCH_SPOTTING_AREAS_LIST)
      }

      document.querySelector('.scrollable').addEventListener('scroll', this.onScroll)

      this.fetchData()
    },
    beforeUnmount () {
      if (this.inf) {
        this.inf.destroy()
      }
      document.querySelector('.scrollable').removeEventListener('scroll', this.onScroll)
    },
    mixins: [TranslationsMixin],
    components: {
      ProofPointCardNew,
      ProofPointCard,
      Dropdown,
      Loading,
      Datepicker,
      MultiSelectFilter
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../scss/_variables.scss";

  .proof-point-item {
    margin-bottom: 20px;
  }

  .proof-point-overview {
    display: flex;
    min-height: 50vh;
  }

  .container {
    margin: 0 auto;
    padding: 20px 0;
  }

  @media (max-width: $screen-md) {
    .container {
      padding: 20px;
      max-width: 100%;
    }
  }

  @media (max-width: $screen-sm) {
    .container {
      width: 100%;
    }
  }

  h4.h4 {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 20px;
  }

  .filter-button {
    display: inline-flex;
    min-width: 200px;
    cursor: pointer;
  }

  .buttons-container {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid black;
    position: relative;
    z-index: 1;
  }

  .proofpoint_filter--title {
    margin-right: 20px;
  }

  .proofpoint_filter--button {
    background-color: #FFF;
    border: 1px solid $color-background-light-grey;
    border-radius: 5px;
    height: 30px;
    line-height: 0;
    color: black;


    &:hover {
      background-color: $color-background-light-grey;
      color: #000;

      :deep(.svg-icon path) {
        fill: black;
      }
    }
  }

  .multiselect--filter {
    min-width: 300px;
    max-width: 300px;
    min-height: 50px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.95);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.5);

    position: absolute;
    top: 39px;
    background-color: white;
  }

  .clear_filters--button {
    cursor: pointer;
    text-decoration: underline;
    margin-bottom: 0px;

    &:hover {

    }
  }

  .multiselect--filter_datepicker {
    padding: 15px;
  }

  .multiselect--filter_argumentation {
    padding: 15px;

    input {
      height: 30px;
      width: 100%;
      padding: 5px;
    }
  }

  .proof-point-action-button-container {
    position: absolute;
    right: 0px;
  }

  .active-filter {
    background-color: var(--primary-lighter)
  }

  .proofpoint__group-date {
    padding: 10px;
  }

  .proofpoint__button-remove {
    color: black;
  }
</style>
