<template>
  <div class="editor-language-dropdown" v-if="shouldShow">
    <span style="white-space: nowrap">{{ $t('edit_actor_segment_description_language') }}:&nbsp;</span>
    <Dropdown v-model="internalValue" :options="options" :disabled="isTranslating"/>
    <ds-button size="small" icon="comment" :label="$t('edit_actor_segment_description_translate')" :disabled="isDefaultLocale || isTranslating" @click="handleAutoTranslate"
               v-if="showAutoTranslate"/>
  </div>
</template>

<script>
  import Dropdown from '../Dropdown/Dropdown.vue'
  import { DEFAULT_LOCALE, LOCALE_LABELS } from '../../store/modules/localization'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      modelValue: {
        type: String,
        default: DEFAULT_LOCALE,
      },
      showAutoTranslate: {
        type: Boolean,
        default: false,
      },
      isTranslating: {
        type: Boolean,
        default: false,
      },
      missingForLocale: {
        type: Object,
        default: () => ({}),
      },
    },
    emits: ['update:modelValue', 'auto-translate'],
    computed: {
      internalValue: {
        get () {
          return this.modelValue
        },
        set (value) {
          this.$emit('update:modelValue', value)
        },
      },
      enabledLocales () {
        return this.$store.state.localization.enabledLocales
      },
      options () {
        return this.enabledLocales.map(locale => ({
          value: locale,
          label: this.labelForLocale(locale),
        }))
      },
      isDefaultLocale () {
        return this.modelValue === DEFAULT_LOCALE
      },
      shouldShow () {
        return this.enabledLocales.length > 1
      },
    },
    methods: {
      labelForLocale (locale) {
        if (!this.missingForLocale[locale]) {
          return LOCALE_LABELS[locale]
        }

        if (this.missingForLocale[locale] === true) {
          return `${LOCALE_LABELS[locale]} (missing)`
        }

        return `${LOCALE_LABELS[locale]} (missing ${this.missingForLocale[locale]})`
      },
      handleAutoTranslate () {
        this.$emit('auto-translate')
      },
    },
    components: {
      Dropdown,
    },
  })
</script>

<style lang="scss" scoped>
  .editor-language-dropdown {
    display: flex;
    align-items: baseline;
    background: var(--primary);
    padding: 6px;
    color: white;
    border-radius: 4px;
  }
</style>
