<template>
  <tr @click="$emit('click', $event)" v-if="canViewExcluded(company)">
    <td>
      <input type="checkbox" @change="updateCompanySelection(company)" :checked="checked(company)">
    </td>
    <td class="list-view__company-name">
      <div class="card__ribbon" v-if="ribbonClass" :style="ribbonStyle"></div>
      <div class="card__ribbon-after">
        <avatar :src="companyLogoUrl" :alt="company.name" variant="border"/>
        <span class="list-view__company-name__label">
          {{ company.name }}
          <br>
          <badge v-if="company.claimed" name="Claimed" variant="primary" size="extra-small"/>
        </span>
      </div>
    </td>
    <template v-for="column in columns">
      <td v-text="getContent(column)" :class="{'text-right' : column == 'completeness'}"></td>
    </template>
  </tr>
</template>

<script>
  import Avatar from '../Avatar/Avatar.vue'
  import Checkbox from '../Form/Checkbox.vue'
  import Badge from '../Badge/Badge.vue'

  import CompanyMixin from '../../util/CompanyMixin'
  import { toAmount, toSymbol } from '../../util/currency.js'
  import { MUTATION_TYPES as PORTFOLIO_MUTATION_TYPES } from '../../store/modules/managePortfolio'
  import { actorTaxonomyProperties } from '../../constants/config'
  import TranslationsMixin from '../../util/TranslationsMixin'

  export default {
    props: ['company', 'columns'],
    emits: ['click'],
    computed: {
      /*columns () {
        return this.$parent.displayedColumns || {}
      },*/
      lastFundingRound () {
        return this.fundingRounds[0] || {}
      },
      lastFundingAmount () {
        return typeof this.lastFundingRound.capital_raised !== 'undefined' ? toAmount(this.lastFundingRound.capital_raised) || 'Undisclosed amount' : ''
      },
      lastFundingCurrency () {
        return this.lastFundingRound.capital_raised ? toSymbol(this.lastFundingRound.currency || 'EUR') : ''
      },
      fundingCurrency () {
        return this.fundingTotal && !this.fundingTotal.startsWith('Un') ? toSymbol('EUR') : ''
      },
      fundingTotal () {
        const c = this.company
        return (typeof c.total_funding === 'number' || typeof c.total_funding === 'string') && c.total_funding > 0 ? c.total_funding && toAmount(c.total_funding) || 'Undisclosed amount' : ''
      },
      fundingDate () {
        const c = this.company
        return c.funding_rounds && c.funding_rounds[0] && c.funding_rounds[0].started_at && c.funding_rounds[0].started_at.slice(0, 10)
      },
      excludedCompanyList () {
        return this.$store.state.managePortfolio.portfolio.actor_ids_excluded
      },
      addedCompanyIds () {
        return this.$store.state.managePortfolio.portfolio.actor_ids
      },
      activePortfolioId () {
        return this.$route.params.id
      },
      userProfile () {
        return this.$store.state.user.profile
      },
      isContributor () {
        return this.userProfile.contributePortfolios
      },
    },
    methods: {
      getContent (columnName) {
        if (actorTaxonomyProperties.includes(columnName)) {
          var content = []
          var taxonomyIds = this.company[columnName]

          if (!Array.isArray(taxonomyIds)) {
            taxonomyIds = [taxonomyIds]
          }

          taxonomyIds.forEach(taxonomyId => {
            var label = this.getLabelForTaxonomyValue(columnName, taxonomyId, 'en')

            if (label) {
              content.push(label)
            }
          })

          return this.spaced(content)
        }

        switch (columnName) {
          case 'total_funding':
            return `${this.fundingCurrency} ${this.fundingTotal}`
            break
          /* case 'total_investment':
            return this.investmentsCounter
            break; */
          case 'funding_date':
            return this.fundingDate
            break
          /* case 'funding_type':
            return this.company.funding_round.type
            break; */
          /* case 'funding_amount':
            return this.fundingAmount
            break; */
          case 'founding_date':
            return this.company.founding_date || ''
            break
          case 'zip':
            return this.company.address && this.company.address.zip
            break
          case 'city':
            return this.company.address && this.company.address.city
            break
          case 'country':
            return this.company.address && (this.company.address.country || this.company.address.country_code)
            break
          /* case 'industries':
            return this.spaced(this.company.industries)
            break; */
          case 'lastFundingType':
            return this.lastFundingRound.type || ''
            break
          case 'lastFundingDate':
            return (this.lastFundingRound.started_at || '').slice(0, 7)
            break
          /* case 'stage':
            return this.company.stage
            break; */
          /* case 'funnel_stage':
            return this.company.funnel_stage
            break; */
          /* case 'category':
            return this.company.category
            break;
          case 'subIndustries':
            return this.spaced(this.company.subIndustries)
            break;
          case 'membership':
            return this.company.membership
            break; */
          case 'maturity':
            return this.company.maturity
            break
          /* case 'funding_stage':
            return this.company.funding_stage
            break;
          case 'technology':
            return this.spaced(this.company.technology)
            break;
          case 'activities':
            return this.spaced(this.company.activities)
            break; */
          /* case 'possible_duplicate':
            return this.boolean(this.company.possible_duplicate)
            break;
          case 'is_zombie':
            return this.boolean(this.company.is_zombie)
            break; */
          case 'completeness':
            return this.company.completeness ? Math.round(100 * this.company.completeness) + '%' : ''
            break
        }
      },
      checked (actor) {
        if (actor.id) {
          return !this.excludedCompanyList.includes(actor.id)
        }
      },
      getFundingRounds (company) {
        return company.funding_rounds.length ? company.funding_rounds.length : '0'
      },
      spaced (list) {
        return list && list.length ? list.join(', ') : ''
      },
      updateCompanySelection (company) {
        if (this.checked(company)) {
          this.excludeActor(company)
        } else {
          this.includeActor(company)
        }
      },
      includeActor (company) {
        this.$store.commit(PORTFOLIO_MUTATION_TYPES.ACTOR_IDS, company)
      },
      excludeActor (company) {
        this.$store.commit(PORTFOLIO_MUTATION_TYPES.ACTOR_EXCLUDED_IDS, company)
      },
      canViewExcluded (actor) {
        // If the user is a portfolio member contributor, the exclueded actors won't be visible in the list.
        if (this.activePortfolioId !== undefined && this.isContributor !== undefined) {
          if (this.isContributor.includes(this.activePortfolioId)) {
            return !this.excludedCompanyList.includes(actor.id)
          }
        }
        return true
      },
      toAmount,
      toSymbol,
    },
    mixins: [CompanyMixin, TranslationsMixin],
    components: {
      Avatar,
      Checkbox,
      Badge,
    },
  }
</script>
