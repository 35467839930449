<template>
  <div class="communities scrollable" ref="scrollable">
    <SimpleHero/>

    <div class="container communities-container" v-if="atLeastOneElementIsLoading || latestAnnouncementsAreLoading">
      <div class="communities-home-loading">
        <Loading/>
      </div>
    </div>

    <div class="home__report_call_countdown_container no-margin" v-if="callCountdownTemplate && isLoggedIn"
         style="margin-top: -1rem !important;">
      <span class="home__report_call_countdown" v-html="callTimeDifference"></span><span class="home__report_call_text">{{
        headlineText
      }}</span>
      <ds-button :icon="applyButtonIcon" :label="applyButtonLabel" @click="onClickApplyForCallOnHomepage"
                 v-tooltip.left="ownedLegalEntityReportUrl ? null : applyButtonTooltip"
                 variant="primary" class="home__report_call_button" size="small" v-if="isEcosystemMember"/>
      <div v-if="isMember && submittedReportsCount !== null" class="home__report_call_submit_count">
        {{ submittedReportsCount }} reports submitted so far
      </div>
    </div>

    <div class="container communities-container">
      <router-link v-if="facetCounts.length" to="/" class="communities-title">
        <div>
          {{ $t('ecosystem_title_numbers', { ecosystem: ecosystemDisplayName }) }}
        </div>
      </router-link>
      <facet-card
          :source="'home'"
          :facet-counts="facetCounts"
          @click="facetCardClick"
      />
    </div>

    <div v-if="!latestAnnouncementsAreLoading" class="container communities-container">
      <template v-if="displayAnnouncementType === 'challenges-only'">
        <router-link
            v-if="displayChallenges && challenges.length > 0" to="/challenges"
            class="communities-title"
        >
          <div>{{ $t('shp_featured_challenges', { challenge: challengeLabel }) }}</div>
        </router-link>
        <div
            v-if="displayChallenges && challenges.length > 0"
            class="communities-announcements row"
            style="display: flex; justify-content: space-between;"
        >
          <div
              class="vertical-challenge-container col-xs-12 col-sm-12 col-md-6"
              v-for="(challenge, index) in challenges"
              :key="'challenge' + index"
          >
            <simplified-challenge-preview :challenge="challenge"
                                          @toggle-pin-status="togglePinStatus"
                                          :is-container="false"
                                          :is-vertical="true"
                                          :is-accessible-for-user="isAccessibleForUser"/>
          </div>
        </div>
      </template>
      <template v-else-if="displayAnnouncementType === 'both'">
        <div
            class="communities-announcements row"
            style="display: flex; justify-content: space-between;"
        >
          <div class="vertical-challenge-container col-xs-12 col-sm-12 col-md-6">
            <router-link to="/announcements-simplified" class="communities-title">
              <div>{{ $t('shp_featured_announcement', { announcement: announcementLabel }) }}</div>
            </router-link>
            <simplified-announcement-preview
                :is-vertical="true"
                :is-simplified="true"
                :is-container="false"
                :announcement="recentAnnouncements[0]"
            />
          </div>
          <div class="vertical-challenge-container col-xs-12 col-sm-12 col-md-6">
            <router-link to="/challenges" class="communities-title">
              <div>{{ $t('shp_featured_challenges', { challenge: challengeLabel }) }}</div>
            </router-link>
            <simplified-challenge-preview :challenge="challenges[0]"
                                          @toggle-pin-status="togglePinStatus"
                                          :is-container="false"
                                          :is-vertical="true"
                                          :is-accessible-for-user="isAccessibleForUser"/>
          </div>
        </div>
      </template>
      <template v-else-if="displayAnnouncementType === 'announcements-only'">
        <router-link
            v-if="displayAnnouncements && recentAnnouncements.length" to="/announcements-simplified"
            class="communities-title"
        >
          <div>
            {{ $t('latest_ecosystem_announcements') }}
          </div>
        </router-link>
        <div
            v-if="displayAnnouncements"
            class="communities-announcements row"
            style="display: flex; justify-content: space-between;"
        >
          <div
              class="vertical-challenge-container col-xs-12 col-sm-12 col-md-6"
              v-for="(announcement, index) in recentAnnouncements"
              :key="'announcement' + index"
          >
            <simplified-announcement-preview
                :is-vertical="true"
                :is-simplified="true"
                :is-container="false"
                :announcement="announcement"
            />
          </div>
        </div>
        <div v-if="displayAnnouncements && displayEvents">
          <router-link v-if="events.length" to="/events-simplified" class="communities-title">
            <div>
              {{ $t('announcements_upcoming_events_title') }}
            </div>
          </router-link>
          <div class="communities-events" v-if="events.length">
            <Carousel
                :items-to-show="eventCardsPerCarouselPage"
                :mouse-drag="false"
                :settings="carouselSettings(eventCardsPerCarouselPage)"
                :breakpoints="carouselBreakpoints(eventCardsPerCarouselPage)"
            >
              <Slide
                  v-for="(event, index) in events"
                  :key="'event' + index"
              >
                <div style="min-width: 100%; padding: 10px; text-align: left;">
                  <event-card
                      :event="event"
                      automatically-calculate-image-height
                      class="event-simplified-image-card"
                      :link="'/events/' + (event.slug || event.id)"
                  />
                </div>
              </Slide>
              <template #addons>
                <Navigation v-if="events.length > eventCardsPerCarouselPage"/>
              </template>
            </Carousel>
          </div>
        </div>
      </template>

      <template v-if="communities.length">
        <router-link to="/communities" class="communities-title" style="margin-bottom: 0px;" id="introjs-communities">
          <div>
            {{
              $t('communities_generic')
            }}
          </div>
        </router-link>
        <Carousel
            :items-to-show="cardsPerCarouselPage"
            :mouse-drag="false"
            class="communities-carousel"
        >
          <Slide
              v-for="(community, index) in communities"
              :key="'communities-' + index"
          >
            <div style="width: 100%; height: calc(100% - 20px); padding: 0px 10px 10px 10px">
              <simplified-image-card
                  :name="community.name" :full-size="true"
                  background-color="white"
                  :image="getImageForCommunity(community)"
                  :sub-title="community.description"
                  :link="'/communities/' + community.id"
                  class="community-simplified-image-card"
                  :tag-icons="getCommunityIcons(community)"
                  :left-align-text="true"
              />
            </div>
          </Slide>
          <template #addons>
            <Navigation v-if="communities.length > cardsPerCarouselPage"/>
          </template>
        </Carousel>
      </template>
      <template v-if="spottingAreas.length">
        <router-link v-if="!highlightedSpottingArea.id" to="/spotting-areas" class="spotting-areas-title"
                     id="introjs-spotting-areas">
          <div>
            {{ spottingAreasLabel }}
          </div>
        </router-link>
        <template v-if="highlightedSpottingArea.id">
          <div class="spotting-areas-highlighted__section">
            <div class="spotting-areas-highlighted__background"></div>
            <div class="spotting-areas-highlighted__section__container">
              <div class="spotting-areas-highlighted__section" style="width: 48%">
                <div class="spotting-areas-title">
                  {{ highlightedSpottingArea.name }}
                </div>
                <div class="spotting-areas-highlighted">
                  <div class="highlighted_spotting-area__content">
                    <p v-html="highlightedSpottingArea.description"></p>
                    <ds-button
                        class="highlight-spotting-area-button"
                        icon="location-marker"
                        :icon-fill-color="'#ffffff'"
                        v-if="highlightedSpottingArea.id"
                        :style="{backgroundColor: highlightedSpottingArea.highlight_color}"
                        @click="goToHighlightedSpottingAreaDetail"
                        :label="highlightedSpottingArea.name"
                    ></ds-button>
                  </div>
                </div>
              </div>
              <div
                  v-if="(highlightedSpottingAreaImage || getDefaultFallbackImageUrl())"
                  :style="{ backgroundImage: `url(${highlightedSpottingAreaImage || getDefaultFallbackImageUrl()})` }"
                  class="highlighted_spotting-area__content image"
                  style="width: 50%; height: 350px; background-repeat: round; align-self: center;margin-left: 30px;"
                  :class="{ 'full-size': true, 'fallback-image': !highlightedSpottingAreaImage}"
              >
                <div
                    v-if="(highlightedSpottingAreaImage || getDefaultFallbackImageUrl())"
                    :style="{ backgroundImage: `url(${highlightedSpottingAreaImage || getDefaultFallbackImageUrl()})`}"
                    class="simplified-connection__logo"
                    :class="{ 'full-size': true, 'fallback-image': !highlightedSpottingAreaImage}"
                />
              </div>
            </div>
          </div>
        </template>
        <Carousel
            v-else
            :items-to-show="cardsPerCarouselPage"
            :mouse-drag="false"
        >
          <Slide
              v-for="(spottingArea, index) in spottingAreas"
              :key="'spotting-areas-' + index"
          >
            <div style="width: 100%; height: calc(100% - 20px); padding: 0px 10px 10px 10px">
              <template v-if="spottingArea.user_has_access">
                <simplified-image-card
                    :name="spottingArea.name" :full-size="true"
                    background-color="white"
                    :image="getImageForSpottingArea(spottingArea)"
                    :sub-title="spottingArea.description"
                    :link="'/spotting-areas/' + spottingArea.id"
                    class="spotting-area-simplified-image-card"
                    :left-align-text="true"
                />
              </template>
              <template v-else-if="spottingArea.user_can_login_or_upgrade">
                <simplified-image-card-with-cta
                    :spotting-area="spottingArea"
                />
              </template>
              <template v-else>
                <simplified-image-card
                    :name="spottingArea.name" :full-size="true"
                    background-color="white"
                    :image="getImageForSpottingArea(spottingArea)"
                    :sub-title="spottingArea.description"
                    :link="'/spotting-areas/' + spottingArea.id"
                    class="spotting-area-simplified-image-card"
                    :left-align-text="true"
                />
              </template>
            </div>
          </Slide>
          <template #addons>
            <Navigation v-if="spottingAreas.length > cardsPerCarouselPage"/>
          </template>
        </Carousel>
      </template>

      <template v-if="displayKnowledgeBase && resources.length">
        <router-link to="/dashboards/knowledge-base" class="communities-title">
          <div>
            {{ $t('knowledge_base_title') }}
          </div>
        </router-link>

        <Carousel
            :key="'carousel-communities-resources'"
            :items-to-show="2"
            :mouse-drag="false"
            :settings="carouselSettings(2)"
            :breakpoints="carouselBreakpoints(2)"
        >
          <Slide
              v-for="(resource, index) in resources"
              :key="'resource-communities-' + index"
          >
            <div style="width: 100%; height: calc(100% - 20px); padding: 0px 10px 10px 10px">
              <AnnouncementOrResourceCard
                  :key="'resource' + resource.id"
                  :data="resource"
                  :preview-mode="false"
                  @addTag="addTagToFilters"
                  :show-image="false"
                  @like-article="likeArticle"
                  :link="'/dashboards/knowledge-base/'"
                  @resourceDeleted="this.fetchLatestNews()"
              />
            </div>
          </Slide>
          <template #addons>
            <Navigation v-if="resources.length > 2"/>
          </template>
        </Carousel>
      </template>
      <router-link to="/spotting-areas" class="spotting-areas-title">
        <div>
          {{
            $t('shp_ecosystem_map', { ecosystem: ecosystemDisplayName })
          }}
        </div>
      </router-link>
      <div
          class="home__map-container" v-if="!isIE11()"
          style="height: 450px; margin-top: 20px;"
      >
        <map-view
            ref="mapPane"
            :data="mapData.data"
            :getMapData="getMapData"
            :getData="getMapData"
            :noSearchWhileMoving="true"
            :disableMouseWheelZoom="true"
            style="width: 100%;"
        />
      </div>
    </div>

    <SimplifiedFooter/>
  </div>
</template>
<script lang="ts">
import ReportMixin from '../../util/ReportMixin.js'
import FiltersMixin from '../../util/FiltersMixin.js'
import TranslationsMixin from '../../util/TranslationsMixin.js'
import CarouselMixin from '../../util/CarouselMixin.js'
import FacetCard from '../../pages/Simplified/FacetCard.vue'
import BasicSimplifiedHero from '../Simplified/BasicSimplifiedHero.vue'
import {
  getCardsPerCarouselPageBasedOnWindowWidth,
  getDefaultFallbackImageUrl,
  getImageForCommunity,
  getImageForSpottingArea,
  isIE11
} from '../../util/helpers.js'
import CommunitiesCard from '../Communities/CommunitiesCard.vue'
import SimplifiedImageCard from '../Connection/SimplifiedImageCard.vue'
import SimplifiedImageCardWithCta from "@/components/SpottingAreas/SimplifiedImageCardWithCta.vue";
import { Carousel, Navigation, Slide } from 'vue3-carousel'
import SimplifiedAnnouncementPreview from '../Simplified/SimplifiedAnnouncementPreview.vue'
import SimplifiedChallengePreview from '../Simplified/SimplifiedChallengePreview.vue'
import { fetchPublicNotifications } from '../../api/notifications.js'
import { ACTION_TYPES as ACTORS_ACTION_TYPES } from '../../store/modules/actors.js'
import AnnouncementOrResourceCard from '../Simplified/AnnouncementOrResourceCard.vue'
import EventCard from '../Simplified/EventCard.vue'
import { likeArticle } from '../../api/knowledge-base.js'
import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters.js'
import MapView from '../MapView/MapView.vue'
import SimplifiedFooter from '../SimplifiedFooter/SimplifiedFooter.vue'
import { fetchLatestNews, fetchStatsData } from '../../api/homepage.js'
import { getTranslationsForTaxonomyValue } from '../../store/modules/taxonomies.js'
import Loading from './ConceptMap/Loading.vue'
import Container from '../Container/Container.vue'
import SimpleHero from '../SimpleHero/SimpleHero.vue'
import IntroJsMixin from '../../util/IntroJsMixin.js'
import { MUTATION_TYPES as KNOWLEDGE_BASE_MUTATION_TYPES } from '../../store/modules/knowledgeBase.js'
import NavigationMixin from '../../util/NavigationMixin.js'
import { getCallTimeDifference } from '../../util/date.js'
import { countSubmittedReports } from '../../api/reporting.js'
import { defineComponent } from 'vue'
import { fetchChallenges, updatePinStatus } from "../../Domain/Challenge/Api/challenges";
import ChallengeCard from "@/components/Simplified/ChallengeCard.vue";
import Tag from "@/components/Tag/Tag.vue";
import { trackMatomoEvent } from '@/util/analytics'
import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from '@/constants/analytics-constants'
import CommunityMixin from '@/util/CommunityMixin'
import UserMixin from '@/util/UserMixin'
import { Community } from '@/Domain/Community/types'

export default defineComponent({
  props: {
    manager: Object,
  },
  data: () => {
    return {
      atLeastOneElementIsLoading: true,
      latestAnnouncementsAreLoading: true,
      cardsPerCarouselPage: getCardsPerCarouselPageBasedOnWindowWidth(window.innerWidth),
      featuredNotifications: [],
      challenges: [],
      events: [],
      resources: [],
      facetCounts: [],
      nonFeaturedAnnouncements: [],
      submittedReportsCount: null,
      callTimeDifference: '',
    }
  },
  computed: {
    highlightedSpottingArea() {
      return this.$store.state.spottingAreas.highlightedSpottingArea.data
    },
    highlightedSpottingAreaImage() {
      return getImageForSpottingArea(this.highlightedSpottingArea)
    },
    headlineText() {
      return this.localizedDisplayProperty(this.config, 'headlineText')
    },
    spottingAreas() {
      return this.$store.state.spottingAreas.listData.data
    },
    eventCardsPerCarouselPage() {
      return 4
    },
    availableFilterControls() {
      return this.config.simplifiedFilterControls || []
    },
    displayKnowledgeBase() {
      return this.$store.getters.hasAccessToKnowledgeBase && this.config.viewDashboard && this.config.viewDashboard.includes('knowledge-base')
    },
    displayExploreContent() {
      const hasAccess = (this.$store.getters.hasAccessToExploration || this.$store.getters.hasAccessToMonitoring)

      return this.$store.getters.isLoggedIn && hasAccess && this.config.viewDashboard && this.config.viewDashboard.includes('explore-content')
    },
    displayAnnouncementType() {
      if (this.challenges.length > 0 && (this.recentAnnouncements.length > 0)) {
        return 'both'
      } else if (this.challenges.length > 0) {
        return 'challenges-only'
      } else if (this.recentAnnouncements.length > 0) {
        return 'announcements-only'
      }

      return ''
    },
    config() {
      return this.$store.state.config
    },
    ecosystemDisplayName() {
      return this.$store.getters.ecosystemDisplayName
    },
    mapData() {
      return this.$store.state.actors.mapData
    },
    recentAnnouncements() {
      return [...this.featuredNotifications, ...this.nonFeaturedAnnouncements].slice(0, 2)
    },
    accessibleChallengeStatusses() {
      return this.$store.getters.accessibleChallengeStatusses
    },
  },
  methods: {
    isIE11,
    getImageForSpottingArea,
    getImageForCommunity,
    getDefaultFallbackImageUrl,
    getCallTimeDifference,
    goToHighlightedSpottingAreaDetail() {
      trackMatomoEvent(MATOMO_EVENT_CATEGORIES.HIGHLIGHTED_SPOTTING_AREA, MATOMO_EVENT_ACTIONS.HOMEPAGE_BUTTON, this.highlightedSpottingArea.id)
      this.$router.push(`/spotting-areas/${this.highlightedSpottingArea.id}`)
    },
    countSubmittedReports() {
      if (this.isMember && this.selectedReportTemplateForHeadline) {
        countSubmittedReports(this.selectedReportTemplateForHeadline.id).then(result => {
          if (result && typeof result === 'string') {
            this.submittedReportsCount = result
          }
        })
      }
    },
    likeArticle({ resourceId, status }) {
      likeArticle(resourceId, status)
    },
    getCommunityIcons(community: Community) {
      const icons: string[] = []

      if (this.isCommunityManager(community)) {
        icons.push("user-check")
      }

      if (this.joinedCommunities.filter((c) => c.id === community.id).length > 0) {
        icons.push("users")
      }

      return icons
    },
    facetCardClick(facet) {
      this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)
      this.$store.commit(FILTERS_MUTATION_TYPES.TOGGLE_LEGEND_ITEM, {
        facet: facet.facetType,
        value: facet.value,
      })

      const listFilters = { ...this.$store.getters.listFilterObject }

      this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_LIST, listFilters)

      if (this.isMember) {
        this.$router.push('/actors')
      } else {
        this.$router.push('/actors-simplified')
      }
    },
    togglePinStatus(challenge) {
      challenge.is_pinned = !challenge.is_pinned

      updatePinStatus(challenge.id, challenge.is_pinned)
    },
    isAccessibleForUser(challenge) {
      if (!challenge) {
        return false
      }

      if (this.isMember) {
        return true
      }
      return this.accessibleChallengeStatusses.includes(challenge.status)
    },
    fetchChallenges() {
      return fetchChallenges({
        limit: 2,
        offset: 0,
        filters: { pinned: true },
      })
    },
    fetchPublicNotifications() {
      if (!this.$store.getters.canUsePublicAnnouncements) {
        this.atLeastOneElementIsLoading = false

        return {}
      }

      fetchPublicNotifications(2, 0, { featured: true, is_event: false }).then(items => {
        this.featuredNotifications = items || []
        this.atLeastOneElementIsLoading = false
      })
      fetchPublicNotifications(2, 0, { featured: false, is_event: false })
          .then(items => {
            this.nonFeaturedAnnouncements = items || []
            this.atLeastOneElementIsLoading = false
          })
    },
    addTagToFilters(tag) {
      if (!tag.value) {
        return
      }

      this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.CLEAR)
      this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.SET_FACET_KEYWORD, tag)

      if (this.$route.name && this.$route.name.indexOf('knowledge-base') <= 0) {
        this.$router.push('/dashboards/knowledge-base')
      }
    },
    showActorsForFacet(facetType, value) {
      this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)

      this.$store.commit(FILTERS_MUTATION_TYPES.TOGGLE_LEGEND_ITEM, {
        facet: facetType,
        value: value,
      })

      // this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_LIST)
      this.$router.push('/actors-simplified')
    },
    fetchFacetCounts() {
      const parameters = {}

      fetchStatsData(parameters)
          .then(response => {
            const resultOrdered = response.data.stats.map(result => {
              const translations = getTranslationsForTaxonomyValue(response.data.property, result.value)
              let label = ''

              if (translations && translations[this.locale] && translations[this.locale].name) {
                label = translations[this.locale].name
              } else {
                label = translations['en'].name
              }

              result['label'] = label
              result['count'] = result.total
              result['facetName'] = response.data.property

              return result
            })

            // Only return the top 6
            this.facetCounts = resultOrdered
                .sort((a, b) => b.count - a.count)
                .slice(0, 6)
            this.atLeastOneElementIsLoading = false
          })
          .catch(errors => {
            //
          })
    },
    getMapData() {
      let filters
      if (this.$store.state.config && this.$store.state.config.viewActorTypes && this.$store.state.config.viewActorTypes.length) {
        filters = Object.assign({}, this.$store.getters.mapFilterObject, { actor_type: this.$store.state.config.viewActorTypes })
      } else {
        filters = Object.assign({}, this.$store.getters.mapFilterObject)
      }

      this.$store.dispatch(ACTORS_ACTION_TYPES.FETCH_ACTORS_MAP, filters)
    },
    renderMap() {
      this.$refs.mapPane && this.$refs.mapPane[0] && this.$refs.mapPane[0].invalidateSize()
    },
    fetchEvents() {
      return fetchPublicNotifications(6, 0, { is_event: true, upcoming_events: true })
          .then(response => {
            this.events = response
          })
          .catch(error => {
            console.log(error)
          })
    },
    fetchLatestNews() {
      return fetchLatestNews({ limit: 10, offset: 0, transform: 'news-monitoring' })
          .then(news => {
            var items = news
                .map(response => {
                  if (['rss', 'pr', 'blog'].includes(response.media_type) && response.sql_media_id) {
                    response.detail_link = '/files/' + response.sql_media_id
                  }
                  return response
                })

            this.resources = items
          })
          .catch(error => {
            console.log(error)
          })
    },
    startIntroJs() {
      if (this.seenIntros.includes('homeCommunities')) {
        return
      }

      const config = this.buildIntroJsConfig(this.config, this.$store.getters.userRole)

      if (!config || !config.homeCommunities || !config.homeCommunities.steps) {
        return
      }

      config.homeCommunities.steps = this.getIntroJsStepsConfig(config.homeCommunities.steps)
      const intro = this.introJs().setOptions(config.homeCommunities)
      this.currentIntro = intro

      const componentScope = this
      intro.onbeforechange(this.introJsBeforeStepCallback.bind(this))
      intro.onafterchange(this.introJsAfterStepCallback.bind(this))
      intro.oncomplete(function () {
        componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
      })
      intro.onexit(function () {
        componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
      })

      intro.start()
    },
  },
  async mounted() {
    this.fetchPublicNotifications()
    await this.fetchEvents()

    // By making fetching challenges a synchronised call, this holds up the other API calls from being triggered
    // This makes it so that the items for the top of the homepage are not only fetched first
    // But gives the back-end some breathing room when receiving a burst of API calls, making it much more likely that
    // From an end user point of view, the top things are loaded first and stay there, previously map data could flash on top, then shortly after challenges and announcements were taking its place
    try {
      const challenges = await this.fetchChallenges();

      if (challenges.length > 0) {
        this.challenges = challenges
      }
    } catch (e) {
      //
    }

    this.latestAnnouncementsAreLoading = false

    this.$bus.on('resize', () => {
      this.cardsPerCarouselPage = getCardsPerCarouselPageBasedOnWindowWidth(window.innerWidth)
    })

    this.fetchFacetCounts()
    this.getMapData()

    if (this.callCountdownTemplate) {
      this.callTimeInterval = setInterval(() => {
        this.callTimeDifference = this.getCallTimeDifference(this.callCountdownTemplate.due_date)
      }, 1000)
      this.countSubmittedReports()
    }

    setTimeout(async () => {
      await this.fetchLatestNews()
    }, 2000)
  },
  watch: {
    'mapData': {
      handler(newValue, oldValue) {
        if (newValue.length !== oldValue.length) {
          setTimeout(this.renderMap, 200)
        }
      },
    },
  },
  created() {
    const introJsConfig = this.buildIntroJsConfig(this.config, this.$store.getters.userRole)
  },
  beforeUnmount() {
    this.$bus.off('resize')
  },
  mixins: [CarouselMixin, FiltersMixin, TranslationsMixin, IntroJsMixin, ReportMixin, NavigationMixin, CommunityMixin, UserMixin],
  components: {
    Tag,
    ChallengeCard,
    SimpleHero,
    FacetCard,
    Loading,
    AnnouncementOrResourceCard,
    EventCard,
    BasicSimplifiedHero,
    CommunitiesCard,
    SimplifiedImageCard,
    SimplifiedImageCardWithCta,
    SimplifiedAnnouncementPreview,
    SimplifiedChallengePreview,
    MapView,
    Slide,
    Carousel,
    Navigation,
    SimplifiedFooter,
    Container,
  },
})
</script>

<style lang="scss" scoped>
.spotting-areas-highlighted__section__container {
  display: flex;
  flex-direction: row;

  padding-top: 50px;
  padding-bottom: 50px;
}

.spotting-areas-highlighted__section {
  position: relative;

  .spotting-areas-highlighted__background {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    overflow: hidden;
    width: 100vw;
    height: 100%;
    background-color: var(--primary-extra-lightest); /* Vervang dit door jouw gewenste kleurcode */
    z-index: -1;
  }

  .spotting-areas-highlighted {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: var(--primary-extra-lightest);
    position: relative;
    padding-bottom: 50px;

    .highlight-spotting-area-button {
      margin-left: 0;
    }

    :deep(ul) {
      list-style-type: disc !important;
      margin-left: 15px;
    }

    .highlighted_spotting-area__content {

      &.image {
        padding-top: 50px;
      }

      h1 {
        margin-bottom: 12px;
      }

      p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
        -webkit-line-clamp: 12;
        position: relative;
        text-overflow: ellipsis;


        max-height: 300px;
        list-style-type: disc;
      }
    }
  }
}

</style>
