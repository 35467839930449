<template>
  <div class="side-panel__actor-preview fixed-heading" >
    <div class="card__general side-panel__header">
      <ds-button
      class="side-panel__actor-preview__header__close-button"
      icon="remove"
      variant="minimal"
      @click="hidePreview"
      />
      <div class="card__title side-panel__card__title__telex">Related Posts</div>
      <p class="form-group__error" v-if="error">{{error}}</p>
    </div>
    <div class="scrollable" ref="scrollable">
      <div class="side-panel__tweet-container col-xs-12">
          <div class="news__column">
            <template v-if="news.items && news.items.length > 0">
              <card-news v-for="(item, index) in news.items"
                :key="index"
                :item="item"
              />
            </template>
            <template v-else-if="state == 'ready' && news.items && news.items.length == 0">
              <div class="side-panel__tweet">
                <div class="side-panel__tweet-text col-xs-11">
                  No related posts available.
                </div>
              </div>
            </template>
            <template v-else-if="state == 'loading'">
              <div class="side-panel__tweet-text col-xs-11"  style="margin-top: 20px;">
                Searching for related posts...
              </div>
            </template>
          </div>
        </div>
    </div>
  </div>
</template>
<script>
  import _throttle from 'lodash/throttle'
  // import Tweet from 'tweet-to-html'

  import PostCard from '../Card/PostCard.vue';
  import CardNews from '../Card/CardNews.vue';

  import { fetchNews } from '../../api/dashboard';

  import ICONS from '../../../icons/manifest.json'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import { timeAgo } from '../../util/date'

  import InfiniteScroll from '../../util/infinite-scroll'
  import KEYCODES from '../../constants/keycodes'

  export default {
    name: 'posts-panel',
    data () {
      return {
        ICONS,
        error: '',
        lastTweetId: null,
        state: 'loading',
        news: {
          items: [],
        },
        filters: {
          portfolio: '',
          topic: '',
          actor: '',
        }
      }
    },
    props: ['keywordData'],
    computed: {
      portfolio () {
        return this.$store.state.filters.portfolio
      }
    },
    methods: {
      hidePreview () {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      fetch () {
        this.hasMore = 0

        //const filters = Object.assign({}, this.paging, { offset: 0 }, this.filters)

        fetchNews(this.filters)
        .then(items => {
          this.state = 'ready';
          this.error = '';

          items = items.filter(n => (n.html && n.html.length > 0) || (n.title && n.title.length > 0));

          this.news.items = items;
          this.hasMore = items.length < 50;
        }).catch(error => {
          this.state = 'ready';
          this.error = 'Something went wrong while fetching the posts.'
        })
      },
      fetchMore () {
        if (! this.hasMore) {
          return;
        }

        var offset = {};

        // If the next page is requested, then use the latest tweet ID to pass as the ID to start from to the tweet API
        var tweetIds = this.news.items.map(tweet => parseInt(tweet.tweet_id));

        var lastTweetId = Math.min(...tweetIds);

        if (isFinite(lastTweetId)) {
          offset = { lastTweetId: lastTweetId };
        }

        const filters = Object.assign({}, offset, this.filters)

        fetchNews(filters)
        .then(items => {
          this.error = ''
          items.forEach(n => {
            n.ago = timeAgo(n.date)
            n.username = n.handle
            n.html = '' /*Tweet.parse(n.meta).html
              .replace(/href="http/g, 'target="_blank" href="http')
              .replace(/http:\/\/pbs.twimg.com/g, 'https://pbs.twimg.com')
              .replace(/http:\/\/twemoji.maxcdn.com/g, 'https://twemoji.maxcdn.com')*/
          });

          var tweetIds = this.news.items.map(tweet => parseInt(tweet.tweet_id));

          var newLastTweetId = Math.min(...tweetIds);

          this.hasMore = newLastTweetId != lastTweetId;

          console.log(this.hasMore, "Has More");

          this.news.items = this.news.items.concat(items)
        }).catch(() => {
          this.error = 'Something went wrong while fetching the posts.'
        })
      },
    },
    mounted () {
      this.fetch()
      this.inf = new InfiniteScroll(this.$refs.scrollable, this.fetchMore)
    },
    beforeUnmount () {
      this.inf.destroy()
    },
    created () {
      window.addEventListener('keyup', (e) => {
        if (e.keyCode === KEYCODES.ESC) {
          this.hidePreview()
        }
      })

      this.filters = Object.assign({},
          this.keywordData && this.keywordData.topic ? { topic: this.keywordData.topic} : {},
          this.keywordData && this.keywordData.actor ? { actor: this.keywordData.actor} : {},
          this.keywordData && this.keywordData.keywordType ? {postType: this.keywordData.keywordType} : {},
          this.portfolio ? {portfolio: this.portfolio} : {})

      this.$bus.on('chartParameters', (parameters) => {
        var oldFilters = this.keywordData

        // If the new parameters are differnt from the current ones, then assign the new filters
        // which will trigger a fetch of posts, we need to clear the current posts and paging parameters for that first though
        if (oldFilters.actor != parameters.actor || oldFilters.topic != parameters.topic) {
          this.paging.offset = 0
          this.news.items = []
        }

        this.filters = Object.assign(this.filters, parameters)
        this.fetch()
      })
    },
    watch: {
      portfolio (v) {
        this.filters.portfolio = v
        this.fetch()
      }
    },
    components: {
      InfiniteScroll,
      CardNews
    }
  }
</script>
