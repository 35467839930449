import AnalyticsCard from '../Card/AnalyticsCard.vue'
import Plotly from '../Chart/Plotly.vue'
import _difference from 'lodash/difference'
import { Chart, registerables } from 'chart.js'

import { inert } from '../../util/helpers.js'
import { defineComponent } from 'vue'

Chart.register(...registerables)

export function chartTransformer (chart) {
  if (!chart || !chart.datasets || chart.error) {
    return
  }

  chart = inert(chart)
  chart.layout = chart.layout || {}
  chart.layout.showlegend = true

  if (chart.datasets[0] && chart.datasets[0].split) {
    chart.datasets = chart.datasets[0].split
    chart.layout.barmode = chart.layout.barmode || 'stack'
  }

  chart.plotlyType = chart.plotlyType || (chart.type === 'DateHistogram' ? 'scatter' : 'bar')
  chart.datasets = chart.datasets.map((d, i) => {
    /* if (!d.x.filter(Boolean).length) {
      return null
    }
    if (!d.y.filter(Boolean).length) {
      return null
    } */

    d.type = chart.plotlyType
    d.fill = d.fill || 'tozeroy'
    // d.fillcolor = 'rgba(0, 0, 0, .06)'
    d.name = d.name || d.label || d.title
    d.marker = d.marker || {
      color: window.config.hex[i + 1],
    }

    return d
  }).filter(Boolean)

  if (!chart.datasets.length) {
    return
  }

  chart.is = 'plotly'
  chart.layout = chart.layout || {}
  chart.layout.barmode = chart.layout.barmode || 'group'

  return chart
}

export function parseChartData (datasets) {
  if (!datasets[0] || !datasets[0].data) {
    return null
  }

  return {
    labels: datasets[0] ? Object.keys(datasets[0].data) : [],
    datasets: datasets.map(dataset => {
      const result = {
        borderColor: dataset.color,
        backgroundColor: `${dataset.color}64`,
        label: dataset.label,
        finalValue: dataset.finalValue,
      }
      if (!dataset.onlyShowInLegend) {
        result.data = Object.values(dataset.data) || '0'
      }
      if (dataset.cumulate) {
        result.data = result.data.reduce((acc, value, index) => {
          if (index > 0) {
            acc.push(acc[index - 1] + value)
          } else {
            acc.push(value)
          }
          return acc
        }, [])
      }
      return result
    }),
  }
}

export default defineComponent({
  components: {
    AnalyticsCard,
    Plotly,
  },
  computed: {
    emptyChartIds () {
      return _difference(this.charts.map(chart => chart.id), this.enabledChartIds)
    },
    enabledChartIds () {
      return this.enabledCharts.map(chart => chart.id)
    },
    enabledCharts () {
      return this.charts.map(chartTransformer).filter(Boolean)
    },
  },
})
