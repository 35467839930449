import { logActivity } from '../api/user.js'

export function logUserActivity (path, name, id) {
  // Strip any trailing slashes
  path = path.replace(/\/$/, '')

  if (name === 'fileDetail') {
    path = path.replace('/analysis', '')

    id = path.substr(path.lastIndexOf('/') + 1)
  }

  if (path && userActivityPages.includes(path)) {
    if (id) {
      logActivity(path, id)
    } else {
      logActivity(path)
    }
  } else if (name && userActivityPages.includes(name)) {
    if (id) {
      logActivity(name, id)
    } else {
      logActivity(name)
    }
  }
}

export const userActivityPages = [
  '/actors',
  '/announcements',
  '/dashboards/analytics',
  '/dashboards/business-size',
  // '/dashboards/buzz',
  '/dashboards/digital-footprint',
  '/dashboards/home',
  '/dashboards/knowledge-base',
  // '/dashboards/news',
  '/datalab/curation',
  '/datalab/spreadsheet',
  '/datalab/suggestions',
  '/settings/users',
  'actor-detail',
  'announcements-detail',
  'challenges-detail',
  'file-detail', // Clicking view on Announcement Or Resource card
  'fileDetail', // Article analysis page
  '/challenges',
  '/actors-simplified',
  '/products-simplified',
  '/announcements-simplified',
  '/product-gallery',
]

export function humanizeUserActivity (activity) {
  var mapping = {
    'home_page': 'home page',
    'directory': 'directory',
    'actor_detail': 'actor profile',
    'social_monitoring': 'buzz',
    'announcements': 'announcements overview',
    'announcements_detail': 'specific announcements',
    'analytics': 'analytics',
    'digital_footprint': 'digital footprint scores',
    'business_size': 'business size scores',
    'buzz': 'buzz',
    'knowledge_base': 'knowledge base',
    'user_settings': 'users panel',
    'curation': 'curation panel',
    'suggestions': 'suggestions panel',
    'spreadsheet': 'spreadsheet panel',
    'create_actor': 'Created an actor',
    'update_actor': 'Updated an actor',
    'create_announcement': 'Created an announcement',
    'update_announcement': 'Updated an announcement',
    'create_resource': 'Created a resource',
    'update_resource': 'Updated a resource',
    'challenges_overview': 'challenges overview',
    'products_simplified': 'product overview',
    'actors_simplified': 'actors overview',
  }

  if (mapping[activity]) {
    return mapping[activity]
  }

  return activity
}
