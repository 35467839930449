<template>
  <div class="filter-section-container" :class="{'with-padding': withPadding}">
    <div class="similarity-buttons__container"
         ref="showMoreContainer"
         :class="{'show-more': showMore, 'disable-click': !allowEdit}" v-if="actor">
      <div class="classification-button selected"
           :disabled="true"
           v-if="actor.description">Similar
        Description
      </div>
      <template v-for="taxonomyClassification in taxonomyClassifications">
        <div class="classification-button"
             :class="{'selected': isFilterSelected({type: taxonomyClassification.type, value: taxonomyClassification.value})}"
             v-if="taxonomyClassification.label"
             @click="toggleFilter({type: taxonomyClassification.type, value: taxonomyClassification.value})">
          {{ taxonomyClassification.label }}
        </div>
      </template>
      <div class="classification-button unusable"
           v-tooltip.left="allowEdit ? null : `The Description can be filled in via the edit panel for ${actor.name}`"
           @click="openEditPanel('')"
           v-if="!actor.description">
        Similar Description
      </div>
      <div class="classification-button unusable"
           v-tooltip.left="allowEdit  ? null : `The Category can be filled in via the edit panel for ${actor.name}`"
           @click="openEditPanel('')"
           v-if="!actor.category || !actor.category.label">
        Category
      </div>
      <div class="classification-button unusable"
           v-tooltip.left="allowEdit  ? null : `The Country can be filled in via the edit panel for ${actor.name}`"
           @click="openEditPanel('Contact Details')"
           v-if="!actor.address || !actor.address.country_code">
        Country
      </div>
      <div class="classification-button unusable"
           v-tooltip.left="allowEdit  ? null : `The Industry can be filled in via the edit panel for ${actor.name}`"
           @click="openEditPanel('Activities')"
           v-if="!actor.industries || !actor.industries.length">
        Industry
      </div>
      <div class="classification-button unusable"
           v-tooltip.left="allowEdit  ? null : `The Technology can be filled in via the edit panel for ${actor.name}`"
           @click="openEditPanel('Activities')"
           v-if="!actor.technology || !actor.technology.length">
        Technology
      </div>
      <div class="classification-button unusable"
           v-tooltip.left="allowEdit  ? null : `The Activity can be filled in via the edit panel for ${actor.name}`"
           @click="openEditPanel('Activities')"
           v-if="!actor.activities || !actor.activities.length">
        Activity
      </div>
      <div class="classification-button unusable"
           v-tooltip.left="allowEdit  ? null : `The Maturity can be filled in via the edit panel for ${actor.name}`"
           @click="openEditPanel('Segmentation')"
           v-if="!actor.maturity || actor.maturity === 'unknown'">
        Maturity
      </div>
    </div>
    <div v-if="hasExceededShowMoreHeight" @click="showMore = !showMore"
         class="show-more-button">{{ showMore ? 'show less' : 'show more' }}
    </div>
    <div v-if="hasAtLeastOneEmptyFilterOption" class="unusable-text">The criteria
      highlighted in orange, indicate that this information is missing for
      {{ actor.name }}.
    </div>
  </div>
</template>

<script>
  import { capitalize } from '../../../util/string.ts'
  import TranslationsMixin from '../../../util/TranslationsMixin.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui.js'

  export default {
    props: {
      selectedFilters: {
        default: null
      },
      withPadding: {
        default: null
      },
      allowEdit: {
        default: null
      },
      classificationCounts: {
        default: null
      },
      hiddenFields: {
        default: null
      },
    },
    data () {
      return {
        showMore: false,
        hasExceededShowMoreHeight: false,
      }
    },
    computed: {
      actor () {
        return this.$store.getters.detailActor
      },
      taxonomyClassifications () {
        const source = this.classificationCounts || []
        const result = Object.keys(source).reduce((acc, taxonomyKey) => {
          const taxonomyCount = source[taxonomyKey]
          const taxonomyType = taxonomyKey === 'address.country_code' ? 'country_code' : taxonomyKey

          if (['category', 'country_code', 'industries', 'technology', 'activities', 'maturity'].includes(taxonomyType)) {
            for (let i = 0; i < taxonomyCount.length; i++) {
              const singleTaxonomyCount = {
                type: taxonomyType,
                ...taxonomyCount[i],
                ...{ label: this.getLabelForTaxonomyCount(taxonomyType, taxonomyCount[i]) },
              }

              if (this.isPropertyEnabledForActor(singleTaxonomyCount)) {
                acc.push(singleTaxonomyCount)
              }
            }
          }

          return acc
        }, [])

        return result.sort((taxA, taxB) => taxB.count - taxA.count)
      },
      hasAtLeastOneEmptyFilterOption () {
        return !this.actor.category ||
          !this.actor.address || !this.actor.address.country_code ||
          !this.actor.industries || !this.actor.industries.length ||
          !this.actor.technology || !this.actor.technology.length ||
          !this.actor.maturity || this.actor.maturity === 'unknown' ||
          !this.actor.activities || !this.actor.activities.length
      },
      hasAccessToSimilarActorAgent () {
        return this.hasAccessToAgents && this.canLookalikeAgentBeUsed
      },
      hasAccessToAgents () {
        return this.$store.getters.hasAccessToAgents
      },
      activePortfolioId () {
        return this.$store.getters.activePortfolio.id
      },
      hasAccessToMonitoring () {
        return this.$store.getters.hasAccessToMonitoring
      },
      hasAccessToExploration () {
        return this.$store.getters.hasAccessToExploration
      },
    },
    methods: {
      getLabelForTaxonomyCount (taxonomyType, taxonomyCount) {
        switch (taxonomyType) {
          case 'maturity':
            return this.capitalize(taxonomyCount.label)
          case 'country_code':
            return this.actor.address.country
          default:
            return taxonomyCount.label
        }
      },
      isPropertyEnabledForActor (taxonomyCount) {
        switch (taxonomyCount.type) {
          case 'category':
            return this.actor.category
          case 'country_code':
            return this.actor.address && this.actor.address.country && this.actor.address.country_code
          case 'technology':
          case 'activities':
            return this.actor[taxonomyCount.type] &&
              this.actor[taxonomyCount.type].length &&
              this.actor[taxonomyCount.type].find(taxonomy => taxonomy.value === taxonomyCount.value)
          case 'industries':
            return this.actor.industries && this.actor.industries.includes(taxonomyCount.value)
          case 'maturity':
            return this.actor.maturity && this.actor.maturity !== 'unknown'
          default:
            return false
        }
      },
      getTaxonomyValue (type, taxonomy) {
        if (type === 'industries') {
          return taxonomy
        } else {
          return taxonomy.value
        }
      },
      getTaxonomyLabel (type, taxonomy) {
        if (type === 'industries') {
          return this.getIndustryLabel(taxonomy)
        } else {
          return taxonomy.label
        }
      },
      openEditPanel (tab) {
        if (!this.allowEdit) {
          return
        }
        let params = { id: this.actor.id }
        if (tab) {
          params = Object.assign(params, { openTab: tab })
        }
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          component: 'actor-edit',
          metaData: params,
        })
      },
      getIndustryLabel (industryId) {
        return this.getLabelForTaxonomyValue('industries', industryId)
      },
      capitalize,
      isFilterSelected (filterableObject) {
        return !!this.selectedFilters.find(filter => {
          return filter.type === filterableObject.type && (filter.value === filterableObject.value || filter.value.includes(filterableObject.value))
        })
      },
      toggleFilter (filterableObject) {
        let result = this.selectedFilters
        const existingFilter = result.find(filter => filterableObject.type === filter.type)

        if (existingFilter && this.isFilterSelected(filterableObject)) {
          if (filterableObject.value === true || existingFilter.value.length === 1) {
            result = this.selectedFilters.filter(selectedFilter => {
              return selectedFilter.type !== filterableObject.type || (selectedFilter.value !== filterableObject.value && !selectedFilter.value.includes(filterableObject.value))
            })
          } else {
            existingFilter.value = existingFilter.value.filter(existingValue => existingValue !== filterableObject.value)
          }
        } else {
          if (existingFilter) {
            existingFilter.value = [...existingFilter.value, filterableObject.value]
          } else {
            result = [...this.selectedFilters, {
              type: filterableObject.type,
              value: filterableObject.value === true ? true : [filterableObject.value],
            }]
          }
        }

        this.$emit('update-selected-filters', result)
      },
    },
    watch: {
      classificationCounts (val) {
        setTimeout(() => {
          if (val) {
            this.hasExceededShowMoreHeight = this.$refs && this.$refs.showMoreContainer && this.$refs.showMoreContainer.offsetHeight && this.$refs.showMoreContainer.offsetHeight > 69
          }
        })
      },
    },
    mixins: [TranslationsMixin],
  }
</script>

<style lang="scss" scoped>
  @import "../../../../scss/variables";

  .filter-section-container {
    font-size: 12px;
    padding: 5px 0;

    &.with-padding {
      border-bottom: 1px solid $color-borders;
      padding: 15px 20px 20px 20px;
    }

    .similarity-buttons__container {
      display: flex;
      font-size: 12px;
      flex-wrap: wrap;
      max-height: 70px;
      overflow-y: hidden;

      &.show-more {
        max-height: none;
        overflow-y: visible;
      }

      .classification-button {
        border: 2px solid $color-borders;
        cursor: pointer;
        padding: 3px 7px;
        margin: 5px 10px 5px 0;
        font-size: 12px;
        border-color: var(--primary);
        color: black;

        &.selected {
          color: white;
          background: var(--primary);
          font-weight: 500;
        }

        &.unusable {
          color: $color-error-light;
          border-color: $color-error-light;
          border-style: dashed;
        }
      }

      &.disable-click {
        .unusable {
          cursor: not-allowed;
        }
      }
    }

    .show-more-button {
      text-decoration: underline;
      cursor: pointer;
      padding-top: 5px;
    }

    .unusable-text {
      color: $color-error-light;
      margin-top: 10px;
    }
  }
</style>
