import { NewsGroups } from '../api/exploration'
import { reactive } from '@vue/reactivity'

const globalState = reactive({
  promise: null,
  data: null,
})

export default {
  computed: {
    newsGroupPromise () {
      return globalState.promise
    },
    mixinNewsGroups () {
      return globalState.data
    },
  },
  created () {
    if (!globalState.promise) {
      this.fetchNewsGroups()
    }
  },
  methods: {
    fetchNewsGroups () {
      globalState.promise = NewsGroups.get()
      globalState.promise.then(newsGroups => globalState.data = newsGroups)
    },
    sourceExistsInAnyNewsGroup (sourceId) {
      if (globalState.data === null) {
        return false
      }

      for (const newsGroup of globalState.data) {
        if (this.sourceExistsInNewsGroup(newsGroup, sourceId)) {
          return true
        }
      }

      return false
    },
    sourceExistsInNewsGroup (newsGroup, sourceId) {
      const newsGroupSources = newsGroup.sources.map(source => source.value) || []

      return newsGroupSources.includes(sourceId)
    },
  },
}
