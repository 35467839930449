<template>
  <div>
    <div v-for="template in availableTemplates">
      <label class="radio">
        <input
          type="radio"
          :name="id"
          :value="template.value"
          :checked="modelValue === template.value"
          @change="handleChange"
        >
        <span class="radio__label">{{ template.label }}</span>
      </label>
    </div>
  </div>
</template>

<script>
  import { randomId } from '../../../util/random'
  import { SiteTemplate } from '../../../store/modules/config'

  export default {
    props: {
      modelValue: {
        type: String,
      },
      isForVisitors: Boolean
    },
    emits: ['update:modelValue'],
    data () {
      return {
        id: randomId(),
      }
    },
    computed: {
      availableTemplates () {
        return Object.values(SiteTemplate).map(template => ({
          value: template,
          label: TEMPLATE_LABELS[template] || template,
        }))
      },
    },
    methods: {
      handleChange (event) {
        this.$emit('update:modelValue', event.target.value)
      },
    },
  }

  const TEMPLATE_LABELS = {
    [SiteTemplate.NEW_SIMPLIFIED]: 'Simplified (All in)',
    [SiteTemplate.SIMPLIFIED]: 'Simplified (Actors Only)',
    [SiteTemplate.TRADITIONAL]: 'Advanced',
  }
</script>
