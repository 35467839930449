<template>
  <div id="timeline-chart-div" class="timeline-chart">
    <div v-show="!canShowChart && showLoader" class="spider__loading chart__loading__positioning">
      <icon name="loading" />
    </div>
    <svg ref="timeline" class="timeline-chart__graph"></svg>
  </div>
</template>

<script>
import * as d3v4 from 'd3'

import { MUTATION_TYPES as BUZZ_MUTATION_TYPES } from '../../store/modules/buzz';
import { MUTATION_TYPES as MEDIA_BUZZ_MUTATION_TYPES } from '../../store/modules/mediabuzz';

import { inert } from '../../util/helpers';
import { humanize } from '../../constants/properties';

export default {
  data () {
    return {
      showLoader: true,
      margins: {
        xAxis: 40,
        yAxis: 100,
        yTextMargin: -10
      }
    }
  },
  emits: ['update-data'],
  props: ['chartData', 'isInsideChartCloseup'],
  computed: {
    chartContainerHeight() {
      // For when the user is in an actor profile
      if(this.currentRoute.path.startsWith('/actors')) {
        // This is the previous itiration of how we would fetch the height of the container that contained the chart
        // Since the placement of the container and the chart has changed, the route that was used to fetch the height was changed as well resulting in the chart
        // not working and displaying at all

        // Previoust iteration
        // return Math.floor(this.$options.parent.$slots.Buzz[0].context.$refs.timelineChartContainer.offsetHeight)

        // Quick fix
        // TODO: Make the chart component less dependend of the $refs, instead try using the $parent object to fetch the containers height
        // The isInsideChartCloseup is for the actor prorfile, since both the individual chart and the closepu component are in the differnte "plane" fields
        // The routing for the parent changes and the way of getting the offsetHeight changes as well
        if (this.isInsideChartCloseup)  {
          return Math.floor(this.$parent.$refs.timelineChartContainer.offsetHeight)
        } else {
          return Math.floor(this.$parent.$parent.$refs.timelineChartContainer.$el.offsetHeight);
        }
      }

      if (this.$parent.$refs.timelineChartContainer) {
        return Math.floor(this.$parent.$refs.timelineChartContainer.offsetHeight);
      }

      return Math.max(350, Math.floor(this.$parent.$el.offsetHeight));
    },
    chartContainerWidth () {
      // For when the user is in an actor profile
      if(this.currentRoute.path.startsWith('/actors')) {
        // This is the previous itiration of how we would fetch the width of the container that contained the chart
        // Since the placement of the container and the chart has changed, the route that was used to fetch the width was changed as well resulting in the chart
        // not working and displaying at all

        // Previoust iteration
        // return Math.floor(this.$options.parent.$slots.Buzz[0].context.$refs.timelineChartContainer.offsetWidth)

        // Quick fix
        // TODO: Make the chart component less dependend of the $refs, instead try using the $parent object to fetch the containers width
        // The isInsideChartCloseup is for the actor prorfile, since both the individual chart and the closepu component are in the differnte "plane" fields
        // The routing for the parent changes and the way of getting the offsetWidth changes as well
         if (this.isInsideChartCloseup)  {
          return Math.floor(this.$parent.$refs.timelineChartContainer.offsetWidth)
        } else {
          return Math.floor(this.$parent.$parent.$refs.timelineChartContainer.$el.offsetWidth);
        }
      }

      if (this.$parent.$refs.timelineChartContainer) {
        return Math.floor(this.$parent.$refs.timelineChartContainer.offsetWidth);
      }

      return Math.floor(this.$parent.$el.offsetWidth);
    },
    canShowChart () {
      return this.chartData.data && this.chartData.data.length > 0 && Object.keys(this.chartData.data[0].data).length > 2
    },
    filters () {
      return this.$store.state.filters
    },
    currentRoute () {
      return this.$route
    }
  },
  methods: {
    render () {
      var data = this.chartData

      if (!this.canShowChart) {
        this.clearGraph()
        this.errorMessage()
        this.showLoader = false
        return
      }

      if (this.currentRoute.path.startsWith('/buzz') && this.$parent.$refs.timelineChartContainer == undefined) {
        return
      }

      var newtimelineData = inert(data)
      this.clearGraph()

      if(this.currentRoute.path.startsWith('/actors')){
        // This is the previous itiration of how we would fetch the width of the container that contained the chart
        // Since the placement of the container and the chart has changed, the route that was used to fetch the width was changed as well resulting in the chart
        // not working and displaying at all

        // Previoust iteration
        // this.outerWidth = Math.floor(this.$options.parent.$slots.Buzz[0].context.$refs.timelineChartContainer.offsetWidth)

        // Quick fix
        // TODO: Make the chart component less dependend of the $refs, instead try using the $parent object to fetch the containers width
        // The isInsideChartCloseup is for the actor prorfile, since both the individual chart and the closepu component are in the differnte "plane" fields
        // The routing for the parent changes and the way of getting the offsetWidth changes as well
        if (this.isInsideChartCloseup)  {
          this.outerWidth = Math.floor(this.$parent.$refs.timelineChartContainer.offsetWidth)
        } else {
          this.outerWidth = Math.floor(this.$parent.$parent.$refs.timelineChartContainer.$el.offsetWidth)
        }
      } else if(this.currentRoute.params.panel == "media-buzz") {
        this.outerWidth = Math.floor(this.$parent.$refs.timelineChartContainer.offsetWidth)
      } else {
        this.outerWidth = Math.floor(this.$parent.$el.offsetWidth)
      }
      this.outerHeight = this.chartContainerHeight

      var n = newtimelineData.data.length, // number of layers
          m = Object.keys(newtimelineData.data[0].data).length,
          dates = Object.keys(newtimelineData.data[0].data),
          formatDate = d3v4.timeFormat("%d / %m");
      var thisVue = this

      var stack = d3v4.stack().keys(d3v4.range(n)).offset(d3v4.stackOffsetWiggle);

      var newData = []
      newtimelineData.data.forEach(stream => {
        newData.push(Object.values(stream.data))
      })

      var layers0 = stack(d3v4.transpose(d3v4.range(n).map(function(d, i) { return newData[i] })));

      var svg = d3v4.select(this.$refs.timeline)
          .attr("width", this.outerWidth)
          .attr("height",  this.outerHeight)
          .attr("style", `transform: scale(0.9, 0.7)`);

      // Depricated
      // var color = window.config.hex;

      var color = Object.values(this.$store.state.config.hexColours);

      var x = d3v4.scaleLinear()
          .domain([0, m - 1])
          .range([0, this.outerWidth - this.margins.yAxis - 10]); // -10 to avoid the clipping of the last item in the axis

      // Range for the streams
      var yArea = d3v4.scaleLinear()
          .domain([d3v4.min(layers0, stackMin), d3v4.max(layers0, stackMax)])
          .range([ this.outerHeight - this.margins.xAxis, 0]);

      // Range for the y axis
      var y = d3v4.scaleLinear()
          .domain([0, n - 1])
          .range([ this.outerHeight - this.margins.xAxis, 5]); // 5 to avoid the clipping of the first item in the axis

      var hoverTooltip = d3v4.select("body").append("div")
            .attr("class", "timeline-chart__d3-tip-hover")
            .style("opacity", 0);

      var area = d3v4.area()
          .x(function(d, i) { return x(i); })
          .y0(function(d) { return yArea(d[0]); })
          .y1(function(d) { return yArea(d[1]); })
          .curve(d3v4.curveBasis);

      var yAxisData = d3v4.axisLeft(y)
          .ticks(n - 1)
          .tickFormat( (item, i) => {
            if(newtimelineData.data[i] !== undefined) {
              return humanize(newtimelineData.data[i].keyword)
            }
          })

      var xAxisData = d3v4.axisBottom(x)
          .ticks(m - 1)
          .tickSize(-this.outerHeight)
          .tickFormat( (item, i) => {
            if(dates !== undefined && dates.length > 0 ) {
              return formatDate(Date.parse(dates[i]))
            }
          })

     var layer = svg.append("g")
          .style("class", "timeline-area-container")

     layer.attr("width", this.outerWidth - this.margins.yAxis)
          .attr("height", this.outerHeight - this.margins.xAxis)
          .style("transform", `translateX(${ this.margins.yAxis }px)`)
          .selectAll("path")
          .data(layers0)
          .enter()
          .append("path")
          .attr("d", area)
          .attr("class", function(d, i) {
            return `layer layer-${newtimelineData.data[d.index].keywordId}`
          })
          .attr("opacity", 1)
          .attr("stroke-width", 1)
          .attr("stroke", function(d, i) {
            var colorRange = newtimelineData.data[i].sentiment
            return color[i];
          })
          .attr("fill", function(d, i) {
            var colorRange = newtimelineData.data[i].sentiment
            return color[i];
          });

          d3v4.selectAll(".layer")
          .on("mouseover", function(d){
            if (thisVue.canShowChart == true) {
              d3v4.selectAll(`.layer`)
                .transition()
                .duration(400)
                .attr("opacity", 0.2)

            d3v4.select(`.layer-${newtimelineData.data[d.index].keywordId}`)
                .transition()
                .duration(400)
                .attr("opacity", 1)

            d3v4.selectAll(`.yAxis-tick-text`)
                .transition()
                .duration(400)
                .attr("opacity", 0.3)

            d3v4.select(`.yAxis-tick-text-${newtimelineData.data[d.index].keywordId}`)
                .transition()
                .duration(400)
                .attr("opacity", 1)

            var toolTipHtml = '<div class="tooltip-body"> ' + humanize(newtimelineData.data[d.index].keyword) + '</div>'

            hoverTooltip
               .transition()
               .duration(100)
               .style("opacity", 1)
               .style("background-color",  color[d.index]);

            hoverTooltip.html(toolTipHtml)
                 .style("left", (d3v4.event.pageX) + "px")
                 .style("top", (d3v4.event.pageY - 20) + "px");
            }
          })
          .on("mouseout", function(d){
            if (thisVue.canShowChart == true) {
              d3v4.selectAll(`.layer`)
                  .transition()
                  .duration(400)
                  .attr("opacity",1)

              d3v4.selectAll(`.yAxis-tick-text`)
                  .transition()
                  .duration(400)
                  .attr("opacity", 1)

              hoverTooltip
                  .transition()
                  .duration(100)
                  .style("opacity", 0)
            }
          }).on("click", function(d){
            if (thisVue.currentRoute.params.panel == "media-buzz") {
              thisVue.$store.commit(MEDIA_BUZZ_MUTATION_TYPES.UPDATE_BUZZ_FILTER, { name: newtimelineData.data[d.index].keyword, facet: 'topic', value: newtimelineData.data[d.index].keywordId});
              thisVue.$emit('update-data')
            } else {
              thisVue.$store.commit(BUZZ_MUTATION_TYPES.UPDATE_BUZZ_FILTER, { name: newtimelineData.data[d.index].keyword, facet: 'topic', value: newtimelineData.data[d.index].keywordId});
              thisVue.$emit('update-data')
            }
          });

      // Y axis
      var yAxis = svg.append("g")
         .attr("class", "y axis")
         .attr("width", this.margins.yAxis )
         .style("transform", `translateX(${ this.margins.yAxis }px)`)
         .call(yAxisData)
         .selectAll("text")
         .call(wrap, this.margins.yAxis)
         .attr("opacity", 1)
         .attr("class", function(d, i){
            if(newtimelineData.data[i] !== undefined) {
              return `yAxis-tick-text yAxis-tick-text-${newtimelineData.data[i].keywordId}`
            }
          })
         .on("mouseover", function(d, i){
            d3v4.selectAll(`.yAxis-tick-text`)
                .transition()
                .duration(400)
                .attr("opacity", 0.3)

            d3v4.selectAll(`.yAxis-tick-text-${newtimelineData.data[i].keywordId}`)
                .transition()
                .duration(400)
                .attr("opacity", 1)

            d3v4.selectAll(`.layer`)
                .transition()
                .duration(400)
                .attr("opacity", 0.2)

            d3v4.select(`.layer-${newtimelineData.data[i].keywordId}`)
                .transition()
                .duration(400)
                .attr("opacity", 1)
          })
          .on("mouseout", function(d){
              d3v4.selectAll(`.layer`)
                  .transition()
                  .duration(400)
                  .attr("opacity",1)

              d3v4.selectAll(`.yAxis-tick-text`)
                  .transition()
                  .duration(400)
                  .attr("opacity", 1)
          })
          .on("click", function(d, i){
            if (thisVue.currentRoute.params.panel == "media-buzz") {
              thisVue.$store.commit(MEDIA_BUZZ_MUTATION_TYPES.UPDATE_BUZZ_FILTER, { name: newtimelineData.data[i].keyword, facet: 'topic', value: newtimelineData.data[d.index] })
              thisVue.$emit('update-data')
            } else {
              thisVue.$store.commit(BUZZ_MUTATION_TYPES.UPDATE_BUZZ_FILTER, { name: newtimelineData.data[d.index].keyword, facet: 'topic', value: newtimelineData.data[d.index].keywordId});
              thisVue.$emit('update-data')
            }
          });

      // X axis
      var xAxis = svg.append("g")
          .attr("class", "x axis")
          .attr("width",this.outerWidth - this.margins.yAxis)
          .attr("transform", `translate(${ this.margins.yAxis },${ this.outerHeight - this.margins.xAxis })`)
          .call(xAxisData)
          .selectAll("text")
          .attr("y", 0)
          .attr("x", - this.margins.xAxis)
          .attr("dy", ".8em")
          .attr("style", "font-size: .8em")
          .attr("transform", "rotate(-90)")
          .style("text-anchor", "start");

        // case there is not enough space the text is wraped
        function wrap(text, width) {
          text.each(function() {
            var text = d3v4.select(this),
                words = text.text().split(/\s+/).reverse(),
                word,
                line = [],
                lineNumber = 0,
                lineHeight = 1.1, // ems
                y = text.attr("y"),
                dy = parseFloat(text.attr("dy")),
                tspan = text.text(null).append("tspan").attr("x", thisVue.margins.yTextMargin).attr("y", -y).attr("dy", dy + "em")
            while (word = words.pop()) {
              line.push(word)
              tspan.text(line.join(" "))
              if (tspan.node().getComputedTextLength() > width) {
                line.pop()
                tspan.text(line.join(" "))
                line = [word]
                tspan = text.append("tspan").attr("x", thisVue.margins.yTextMargin).attr("y", -y).attr("dy", `${++lineNumber * lineHeight + dy}em`).text(word)
              }
            }
          })
        }

      function stackMax(layer) {
        return d3v4.max(layer, function(d) { return d[1]; });
      }

      function stackMin(layer) {
        return d3v4.min(layer, function(d) { return d[0]; });
      }
    },
    errorMessage () {
      var thisVue = this
      var svg = d3v4.select(this.$refs.timeline)
          .attr("width", this.chartContainerWidth + this.margins.yAxis)
          .attr("height",  this.chartContainerHeight)
          .attr("style", `transform: scale(0.7, 0.7); display:inherit; margin:auto`);

      var errorMessage = svg.append("g")
          .attr("class", "timeline-chart-error-message")
          .attr("transform", `translate(${ (this.chartContainerWidth - this.margins.yAxis) / 4} , ${ (this.chartContainerHeight - this.margins.xAxis) / 2 })`)

      errorMessage.append("text")
          .text("Not enough data to create a timeline for.")
          .attr("opacity", function(d){
          if (thisVue.canShowChart == false) {
            d3v4.selectAll(".layer").attr("opacity", 0)
            return 1
          } else {
            return 0
          }
        })
    },
    clearGraph () {
      d3v4.select(this.$refs.timeline)
        .selectAll('*')
        .remove()
      d3v4.select('.timeline-chart__d3-tip-hover').remove()
    }
  },
  watch: {
    chartData (val) {
     if (val) {
        this.render()
      }
    }
  },
  async mounted () {
    this.$bus.on('resize', () => {
      this.render()
    })

    if (this.chartData && this.chartData.data) {
      this.render()
    }
  },
  beforeUnmount () {
    this.clearGraph()
  }
}

</script>
