<template>
  <div>
    <div v-if="$store.getters.isOwner">
      <h2 class="h2">Custom areas</h2>
      <table class="table" v-if="customFilters.data.length">
        <thead>
        <tr>
          <th>Area</th>
          <th>Filters</th>
          <th></th>
          <th></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="item in customFilters.data">
          <td>{{ item.name }}</td>
          <td>
            <div v-for="filter in item.filters">
              <div v-if="filter.country_code">Country: {{ filter.country_code }}</div>
              <div v-if="filter.cities">Cities: {{ filter.cities.join(', ') }}</div>
              <div v-if="filter.zip_codes">Zip codes: {{ filter.zip_codes.join(', ') }}</div>
            </div>
          </td>
          <td class="fields__tdbtn">
            <ds-button icon="edit" size="small" @click="editCustomFilter(item)"/>
          </td>
          <td class="fields__tdbtn">
            <ds-button icon="remove" size="small" @click="removeCustomFilter(item)"/>
          </td>
        </tr>
        </tbody>
      </table>
      <br>
      <div v-if="customFilters.new" ref="customFilter">
        <h2 class="h2">{{ customFilters.new.id ? 'Edit' : 'Add' }} custom area</h2>
        <p v-if="customFilters.errors.message" class="form-group__error" v-text="customFilters.errors.message"></p>
        <form-group label="Area name" :error="customFilters.errors.name">
          <ds-input v-model="customFilters.new.name" placeholder="The name for the area"/>
        </form-group>
        <div class="row" v-for="(filter, key) in customFilters.new.filters">
          <form-group class="col-xs-6" label="Country" :error="customFilters.errors['filters.' + key + '.country_code']">
            <country-dropdown @update:modelValue="filter.country_code = $event.code" :model-value="filter.country_code"/>
          </form-group>
          <form-group class="col-xs-6" label="City names" v-if="filter.cities" :error="customFilters.errors.filters">
            <tag-input v-model="filter.cities" :max-tags="1000"/>
          </form-group>
          <form-group class="col-xs-6" label="Zip codes" v-else-if="filter.zip_codes" :error="customFilters.errors.filters">
            <tag-input v-model="filter.zip_codes" :max-tags="1000"/>
          </form-group>
          <form-group class="col-xs-6" label="Choose filter" v-else>
            <ds-button variant="outline" label="Cities" @click="filter.cities = []"/>
            <ds-button variant="outline" label="Zip codes" @click="filter.zip_codes = []"/>
          </form-group>
        </div>
        <p v-if="!customFilters.new.filters.length">
          <ds-button variant="secondary" label="Filter by zip codes" @click="customFilters.new.filters.push({ country_code: null, zip_codes: [] })"/>
          <ds-button variant="secondary" label="Filter by cities" @click="customFilters.new.filters.push({ country_code: null, cities: [] })"/>
        </p>
        <p>
          <ds-button v-if="customFilters.new.filters.length" variant="secondary" icon="check" :label="(customFilters.new.id ? 'Save' : 'Add') + ' custom area'"
            @click="saveCustomFilter(customFilters.new)"/>
          <ds-button v-if="customFilters.new.filters.find(f => f.zip_codes)" variant="outline" label="Filter by cities"
            @click="customFilters.new.filters = [{ country_code: null, cities: [] }]"/>
          <ds-button v-if="customFilters.new.filters.find(f => f.cities)" variant="outline" label="Filter by zip codes"
            @click="customFilters.new.filters = [{ country_code: null, zip_codes: [] }]"/>
          <ds-button variant="outline" label="cancel" @click="customFilters.new = null"/>
        </p>
        <br>
      </div>
      <p v-else>
        <ds-button variant="secondary" label="New custom area" @click="newCustomFilter"/>
        <br>
        <br>
      </p>
    </div>
  </div>
</template>

<script>
  import DsButton from '../../DsButton/DsButton.vue'
  import CountryDropdown from '../../Dropdown/CountryDropdown.vue'

  import { CustomFilters } from '../../../api/customfilters'
  import { inert } from '../../../util/helpers'
  import TagInput from '../../Form/TagInput.vue'

  export default {
    name: 'CustomAreas',
    props: ['form'],
    data () {
      return {
        customFilters: {
          data: [],
          errors: {},
          new: null,
        },
      }
    },
    methods: {
      fetchCustomFilters () {
        CustomFilters.get()
          .then(data => {
            this.customFilters.data = data
          })
          .catch(errors => {
            this.customFilters.errors = errors
          })
      },
      newCustomFilter (customFilter) {
        this.customFilters.errors = {}
        this.customFilters.new = { name: null, filters: [] }
      },
      saveCustomFilter (customFilter) {
        CustomFilters.post(customFilter)
          .then(data => {
            this.customFilters.new = null
            this.fetchCustomFilters()
          })
          .catch(errors => {
            this.customFilters.errors = errors
          })
      },
      editCustomFilter (customFilter) {
        this.customFilters.new = inert(customFilter)
        this.$nextTick(() => {
          this.$el.scrollTop = this.$refs.customFilter.offsetTop - 100
        })
      },
      removeCustomFilter (customFilter) {
        if (!window.confirm('Are you sure you want to remove this custom area filter?')) {
          return
        }
        CustomFilters.delete(customFilter.id)
          .then(data => {
            this.fetchCustomFilters()
          })
          .catch(errors => {
            this.customFilters.errors = errors
          })
      },
    },
    mounted () {
      this.$store.getters.isOwner && this.fetchCustomFilters()
    },
    components: {
      DsButton,
      TagInput,
      CountryDropdown,
    },
  }
</script>

<style scoped>

</style>
