<template>
  <div class="file-input" :class="inputClass">
    <label class="file-input__button" :class="{ 'file-input--moved': moved, 'file-input--dragging': dragging && !busy }">
      <div class="button button--secondary"><icon v-if="! noIcon" :name="busy ? 'spinner' : 'plus'" /> <span class="button__label">{{ busy ? 'Uploading...' : label ? label : 'Upload' }}</span></div>
      <span v-text="errors" class="form-group__error"></span>
      <input :multiple="multiple" @change="change" class="file-input__input" type="file" name="files">
    </label>
  </div>
</template>

<script>
import dragDrop from 'drag-drop'

import { createFileRequest } from '../../api/create-request.js'

import FileList from '../Form/FileList.vue'

import { getFilesError } from '../../util/getNested.js'

export default {
  props: {
    modelValue: null,
    placeholder: null,
    width: null,
    height: null,
    actorId: null,
    fieldId: null,
    fieldType: null,
    multiple: {
      type: Boolean,
      default: true
    },
    url: null,
    noIcon: {
      type: Boolean,
      default: false
    },
    parentId: null, // Can be used if the file belongs to a property with a unique identifier (i.e. offerings)
    label: String,
    windowDrop: Boolean
  },
  emits: ['upload', 'update:modelValue'],
  data () {
    return {
      busy: false,
      errors: null,
      dragging: 0,
      moved: null
    }
  },
  computed: {
    current () {
      return this.modelValue || []
    },
    inputClass () {
      return 'file-input--empty'
    }
  },
  methods: {
    change (event) {
      const input = event.target || event.srcElement
      if (input.files) {
        const form = new window.FormData()
        for (var i = 0, numFiles = input.files.length; i < numFiles; i++) {
          form.append('files[]', input.files[i]) // TODO: UNCOMMENT THIS PART AGAIN, THIS WAS JUST TO TEST OUT THE "LINK" INPUT
        }

        if (this.fieldId && this.fieldType == 'offerings') {
          form.append('actor_offerings_id', this.fieldId)
        } else if (this.fieldId) {
          form.append('ecosystem_report_field_id', this.fieldId)
        }

        form.append('title', 'File')

        var url = null

        if (this.url) {
          // For custom endpoint url
          // Like for uploading to the ecosystem
          url = this.url
        } else {
          // For uploading files to an actor
          url = '/api/actors/' + this.actorId + '/files'
        }

        this.busy = true
        this.errors = null

        return createFileRequest({
          url: url,
          body: form
        })
        .then(value => {
          this.$emit('upload', value)
          this.$emit('update:modelValue', this.current.concat(value))
        })
        .catch(errors => {
          this.errors = errors && (errors.message || (errors.unexpected ? 'Unexpected error' : null) || getFilesError(errors)) || 'Something went wrong'
        })
        .then(() => {
          this.busy = false
          this.moveBack()
        })
      }
    },
    dragenter () {
      if (this.dragging === 0) {
        this.moveOnTop()
      }
      this.dragging++
    },
    dragleave () {
      if (this.dragging > 0) {
        this.dragging--
      } else {
        this.$nextTick(() => {
          if (!this.busy) {
            this.moveBack()
          }
        })
      }
    },
    moveOnTop () {
      this.errors = null
      if (this.$el.firstChild) {
        this.moved = this.$el.firstChild
        $(this.$el).closest('.card').append(this.moved)
      }
    },
    moveBack () {
      if (this.moved) {
        this.$el.appendChild(this.moved)
        this.moved = null
        this.dragging = 0
      }
    }
  },
  mounted () {
    if (this.windowDrop) {
      this.dragging = 0
      window.addEventListener('dragenter', this.dragenter)
      window.addEventListener('dragleave', this.dragleave)
    }
  },
  beforeUnmount () {
    window.removeEventListener('dragenter', this.dragenter)
    window.removeEventListener('dragleave', this.dragleave)
    this.dragging = 0
  },
  components: {
    FileList
  }
}
</script>

<style lang="scss">
@import "../../../scss/_variables.scss";

.file-input {
  position: relative;
  .file-list + & {
    margin-top: 1rem;
  }
}
.file-input__button {
  display: block;
  &.file-input--moved {
    position: absolute;
    z-index: 1010;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex!important;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 1);
  }
  &.file-input--dragging {
    top: -1px;
    left: -1px;
    right: -1px;
    bottom: -1px;
    border: 4px dashed $color-primary;
    animation: scale-sm 1s infinite;
    transition: border .5s, transform .2s;
    &:hover {
      transform: scale(1.25);
    }
  }
}
.file-input__input {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  width: 100%;
  max-width: 100%;
  opacity: 0;
  cursor: pointer;
  pointer-events: all;
}


@-webkit-keyframes scale-sm {
  0%   {-webkit-transform: scale(1);}
  20%   {-webkit-transform: scale(1.1);}
  40%   {-webkit-transform: scale(1);}
}
</style>
