<template>
  <tr style="cursor:default;">
    <td style="cursor: pointer;" @click.prevent="openUserSidePanel">
      {{ communityJoinRequest.user.name }}
    </td>

    <td style="cursor: default;">
      <router-link :to="'/communities/' + communityJoinRequest.communityId">
        {{ communityJoinRequest.communityName }}
      </router-link>
    </td>

    <td>
      {{ communityJoinRequest.dateString }}
    </td>

    <td style="display: flex; flex-direction: row; width: 300px;">
      <template v-if="!isRequestProcessed">
        <DsButton
          class="row-curation__verify"
          size="small"
          icon="check"
          label="Accept"
          @click.stop="acceptRequest"
        />

        <DsButton
          class="row-curation__verify"
          size="small"
          icon="trash"
          label="Deny"
          @click.stop="denyRequest"
        />
      </template>

      <template v-else>
        <span v-if="requestDecision === 'accept'">Request accepted</span>
        <span v-else-if="requestDecision === 'deny'">Request denied</span>
      </template>
    </td>
  </tr>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { CommunityJoinRequest } from '@/Domain/Community/types'
  import { PropType } from '~/vue'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '@/store/modules/ui'
  import { acceptRequestToJoinCommunity, denyRequestToJoinCommunity } from '@/api/communities'

  export default defineComponent({
    name: "CommunityJoinRequestCurationRow",
    props: {
      communityJoinRequest: Object as PropType<CommunityJoinRequest>,
      required: true
    },
    data () {
      return {
        isRequestProcessed: false,
        requestDecision: '',
      }
    },
    methods: {
      acceptRequest() {
        acceptRequestToJoinCommunity(this.communityJoinRequest.communityId, this.communityJoinRequest.id)
          .then(() => {
            this.isRequestProcessed = true
            this.requestDecision = 'accept'
          })

        this.requestDecision = 'accept'
      },
      denyRequest() {
        denyRequestToJoinCommunity(this.communityJoinRequest.communityId, this.communityJoinRequest.id)
          .then(() => {
            this.isRequestProcessed = true
            this.requestDecision = 'deny'
          })

        this.requestDecision = 'deny'
      },
      openUserSidePanel() {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          component: 'user-information-side-panel',
          metaData: {
            userId: this.communityJoinRequest.user.id,
            allowDelete: false,
          }
        })
      }
    }
  })
</script>

<style scoped lang="scss">

</style>
