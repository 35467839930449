<template>
  <div class="community-detail__manager" style="width: 30rem; margin: auto; margin-top: 2rem;">
    <div class="community-detail__manager-container">
      <div class="community-detail__manager-photo-container">
        <div class="community-detail__manager-photo"
             :style="{ backgroundImage: `url(${manager.photo_url})` }">
        </div>
      </div>
      <div class="community-detail__manager-info" style="justify-content: flex-end;">
        <div class="community-detail__manager-bio">
          {{ $t('community_cannot_access_members') }}
        </div>
        <ds-button
          variant="rounded"
          v-if="canStartConversation"
          @click.stop="startConversation(manager.id)"
          :label="$t('community_contact_cta')"
        />
      </div>
    </div>
  </div>
</template>

<script>
  import { Conversations } from '../../api/conversations'
  import { ACTION_TYPES as CONVERSATION_ACTION_TYPES } from '../../store/modules/conversations'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      manager: {
        type: Object
      }
    },
    computed: {
      canStartConversation () {
        return this.userId !== this.manager.id && this.manager.name !== 'removed user'
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      userId () {
        return this.$store.getters.userId
      }
    },
    methods: {
      showLoginModal () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
      },
      startConversation (userId) {
        if (!this.isLoggedIn) {
          this.showLoginModal()
          return
        }

        if (userId == this.userId) {
          return
        }

        // actor_id: actorId,
        Conversations.post({ recipient_id: userId }).then((conversation) => {
          this.$store.dispatch(CONVERSATION_ACTION_TYPES.FETCH_CONVERSATIONS_LIST)
          this.$router.push('/profile/conversations/' + conversation.id).then(() => {
            this.$store.commit (UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
              component: 'user-information-side-panel',
              metaData: {
                userId: userId,
                allowDelete: false,
              },
            })
          })
        })
      },
    }
  })
</script>

<style scoped>

</style>
