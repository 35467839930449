<template>
  <router-link
    :to="link"
    :target="newWindow ? '_blank' : '_self'"
    @click="onClick"
    class="simplified-connection"
    :class="{'disabled': disabled}"
    :key="resizeKey"
  >
    <div class="simplified-connection__title" ref="title" v-if="isConnection"
         :style="{textAlign: leftAlignText ? 'left' : 'center'}">{{ relationship }}
    </div>
    <div v-if="(image || getDefaultFallbackImageUrl() || fullSize) && !logoName"
         class="simplified-connection__logo-wrapper">
      <div
        v-if="(image || getDefaultFallbackImageUrl() || fullSize) && !logoName"
        :style="{ backgroundImage: `url(${image || getDefaultFallbackImageUrl()})`, height: fixedImageSize, backgroundColor }"
        class="simplified-connection__logo"
        :class="{ 'full-size': fullSize, 'fallback-image': !image}"
      >
        <div class="simplified-connection__tags" v-if="tag || tagIcons">
          <Tag v-if="tag" :label="tag" :icon="tag"></Tag>
          <Tag v-else v-for="(tagIcon, keyTag) in tagIcons" :icon="tagIcon" :key="'tag-' + keyTag"></Tag>
        </div>

        <div class="simplified-connection__tags" v-if="tags">
          <Tag v-if="tags" v-for="(tagLabel, keyTag) in tags" :label="tagLabel" :key="'tag-' + keyTag"></Tag>
        </div>
      </div>
    </div>
    <div
      v-else-if="subjectName || logoName"
      class="simplified-connection__logo"
      :style="{backgroundColor, height: fixedImageSize}"
    >
      <span v-if="subjectName" :style="{ color }">{{ subjectName }}</span>
      <Icon v-else-if="logoName" :name="logoName" size="large"
            class="simplified-connection__logo-icon"
            :style="{ height: fixedImageSize }"
      />
      <div class="simplified-connection__banner" v-if="bannerText" v-html="bannerText"/>
    </div>
    <div class="simplified-connection__title" ref="title" v-if="!isConnection"
         :style="{textAlign: leftAlignText ? 'left' : 'center'}">
      <icon class="pinpoint-location-highlighted-spotting-area-icon"
            v-if="highlightedSpottingArea && showHighlightedSpottingArea"
            :style="{fill: highlightedSpottingArea.highlight_color }" :name="'location-marker'"/>
      <span style="font-weight: 500; font-size: 14px">{{ name }}</span>
    </div>
    <div class="simplified-connection__subtitle" v-if="subTitle"
         :style="{textAlign: leftAlignText ? 'left' : 'center'}">
      <p>{{ stripHtmlTagsWithSpaces(subTitle) }}</p>
    </div>
  </router-link>
</template>

<script lang="ts">
  import FiltersMixin from '../../util/FiltersMixin.js'
  import { darken } from 'polished'
  import Tag from '../Tag/Tag.vue'
  import { getDefaultFallbackImageUrl, stripHtmlTagsWithSpaces } from '../../util/helpers.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      showHighlightedSpottingArea: {
        type: Boolean,
        default: false,
      },
      actor: Object,
      name: String,
      image: String,
      logoName: String,
      tagIcons: {
        type: Array,
        default: []
      },
      subjectName: String,
      connection: Object,
      tag: String,
      tags: Array,
      subTitle: String,
      bannerText: String,
      disabled: {
        type: Boolean,
        default: false,
      },
      leftAlignText: {
        type: Boolean,
        default: false,
      },
      newWindow: {
        type: Boolean,
        default: false,
      },
      fullSize: {
        type: Boolean,
        default: false,
      },
      isConnection: {
        type: Boolean,
        default: false,
      },
      link: {
        type: String,
        default: '',
      },
      showRemoveIcon: {
        type: Boolean,
        default: false,
      },
    },
    data: () => {
      return {
        fixedImageSize: 0,
        resizeKey: 0,
      }
    },
    computed: {
      highlightedSpottingArea() {
        return this.$store.state.spottingAreas.highlightedSpottingArea.data
      },
      ecosystemRelationships() {
        return this.$store.getters.fullActorRelationships
      },
      color() {
        if (this.backgroundColor === '#eeeeee') {
          return 'black'
        }
        return darken(0.30, this.backgroundColor)
      },
      backgroundColor() {
        let result
        if (this.connection) {
          result = this.legendColor(this.connection.legend)
        } else {
          result = this.legendColor()
        }
        if (result === '#bbbbbb') {
          result = '#eeeeee'
        }
        return result
      },
      relationship() {
        const inverseRelationType = this.ecosystemRelationships.find(relationshipType => relationshipType.name === this.connection.type)
        return this.getTranslationForRelationName(inverseRelationType.label)
      },
    },
    methods: {
      stripHtmlTagsWithSpaces,
      getDefaultFallbackImageUrl,
      onClick() {
        if (!this.link) {
          this.$emit('click-link')
        }
      },
      calculateImageHeight() {
        if (this.$refs.title) {
          // aspect ratio 0.5625 == 16:9
          this.fixedImageSize = (!this.isConnection) ? `calc(0.5625 * ${getComputedStyle(this.$refs.title).width})` : getComputedStyle(this.$refs.title).width
          this.resizeKey++
        }
      },
    },
    mounted() {
      setTimeout(this.calculateImageHeight)
      this.$bus.on('resize', () => {
        this.calculateImageHeight()
      }, 100)
    },
    components: {
      Tag,
    },
    beforeUnmount() {
      this.$bus.off('resize')
    },
    mixins: [FiltersMixin],
  })
</script>

<style lang="scss">
  @import "../../../scss/variables";

  .simplified-connection {
    background-color: white;
    display: inline-block;
    color: inherit;
    text-decoration: none;
    width: 100%;
    position: relative;
    transition: all .3s ease-in-out;

    &:hover {
      transform: scale(1.05);
      cursor: pointer;
    }

    &.disabled {
      cursor: not-allowed;
    }

    .simplified-connection__title {
      margin: 10px;
      text-transform: uppercase;
      font-weight: 500;
      white-space: nowrap;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      text-wrap: wrap;
      overflow: auto;

      span {
        text-wrap: wrap;
        overflow: auto;
      }
    }

    .simplified-connection__subtitle {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: auto;
      text-wrap: wrap;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      margin: 10px;
      position: relative;
      text-overflow: ellipsis;

      ul {
        list-style-type: disc !important;
        margin-left: 15px;

        li {
          list-style-type: disc !important;
        }
      }

      li {
        list-style-type: disc;
      }
    }

    .simplified-connection__tags {
      position: absolute;
      top: 82%;
      left: 10px;
      color: var(--primary);
    }

    .tag {
      background: var(--primary);
      color: white;
      border: var(--primary)
    }

    .simplified-connection__banner {
      background: #FF3D00;
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 36px;
      color: white;
      z-index: 1;
      font-size: 12px;
      line-height: 36px;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .simplified-connection__logo-wrapper {
      border-radius: 12px 12px 0 0;
      overflow: hidden;
    }

    .simplified-connection__logo {
      background-repeat: no-repeat;
      background-position: center;
      margin: 10px;
      background-color: white;
      color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      transition: all .3s ease-in-out;

      &.full-size {
        background-size: contain;
      }

      &.fallback-image {
        background-size: cover;
      }

      & > span {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 4;
        line-height: 14px;
        text-align: center;
        max-height: 56px;
        text-transform: uppercase;
        word-break: break-word;
      }

      .simplified-connection__logo-icon {
        width: 33%;
        color: var(--primary);

        svg {
          width: 100%;
        }
      }
    }

    .pinpoint-location-highlighted-spotting-area-icon {
      width: 15px;
      height: 15px;
      margin-right: 5px;
    }
  }
</style>
