<template>
  <div class="row" v-show="show">
    <form-group :label="person ? 'Add expertise' : 'Add domain'" class="col-sm-12">
      <dropdown search :data="domainOptions" v-model="domain" @update:modelValue="create" cls="dropdown-groupselect" :maxHeight="100"/>
    </form-group>
  </div>
  <div v-if="show === 0">
    <p>There are no domains available. You can add domains <a href="/datalab/taxonomies" target="_blank">here</a>.</p>
  </div>
</template>

<script>
  import FormGroup from '../Form/FormGroup.vue'
  import Dropdown from '../Dropdown/Dropdown.vue'

  import { twoLevelTaxonomiesToOptions } from '../../util/hierarchy-taxonomy'

  export default {
    props: ['actorType'],
    data () {
      return {
        domain: null,
      }
    },
    computed: {
      show () {
        return this.$store.state.taxonomies.domains.length
      },
      person () {
        return this.actorType === 'Person'
      },
      domainOptions () {
        return twoLevelTaxonomiesToOptions(this.$store.state.taxonomies.domains, 'domains')
      },
    },
    methods: {
      create (value) {
        this.domain = value
        this.$nextTick(() => {
          if (!this.domain) {
            return
          }
          this.$emit('create', { id: parseInt(value) })
          this.domain = null
        })
      },
    },
    components: {
      Dropdown,
      FormGroup,
    },
  }
</script>
