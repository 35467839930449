// import { createI18n } from '~/vue-i18n'

// import all the json files
import en from './translations/en.json'
import nl from './translations/nl.json'
export function loadLocaleMessages() {
  const locales = [{ en: en }, { nl: nl }]
  const messages = {}
  locales.forEach(lang => {
    const key = Object.keys(lang)
    messages[key] = lang[key]
  })
  return messages
}

/*console.log('log create i18n');

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: 'en',
  fallbackLocale: 'nl',
  formatFallbackMessages: true,
  messages: loadLocaleMessages(),
})*/
