import { createJSONRequest, createResource } from './create-request'

export const Conversations = createResource('/api/conversations')

// Note: we don't pass limit/offset params yet.
// This is not an issue for now, but could be in the long run
export const getAllConversations = () => createJSONRequest({
  method: 'get',
  url: `/api/conversations`,
})

export const getSearchQueryConversations = (searchQuery) => createJSONRequest({
  method: 'get',
  url: `/api/conversations?query=${searchQuery}`,
})

export const getSuggestedConversations = (searchQuery) => createJSONRequest({
  method: 'get',
  url: `/api/suggested-conversations?query=${searchQuery}`,
})

export const getConversationsUnreadCount = () => createJSONRequest({
  method: 'get',
  url: `/api/conversations-unread-count`,
})

export const createComment = (id, comment) => createJSONRequest({
  method: 'POST',
  url: `/api/conversations/${id}/comments`,
  data: {
    comment,
  },
})

export const fetchComments = (id, limit, offset = 0) => createJSONRequest({
  method: 'GET',
  url: `/api/conversations/${id}/comments?` + [limit && `limit=${limit}`, offset && `offset=${offset}`].filter(p => p).join('&'),
})

export const fetchEmailLogs = (id, limit, offset = 0) => createJSONRequest({
  method: 'GET',
  url: `/api/conversations/${id}/email-logs?` + [limit && `limit=${limit}`, offset && `offset=${offset}`].filter(p => p).join('&'),
})
