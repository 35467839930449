<template>
  <div>
    <card class="catalogue-card__cover__section catalogue-card">
      <div class="catalogue-card__logo" :style="{ backgroundImage: 'url(' + coverStyling + ')' }">
        <!--<img class="catalogue-card__cover" :src="coverStyling">-->
      </div>
      <div class="catalogue-card__website">
          <div class="card__content"> <icon name="world" class="catalogue-card_icon"/> <a :href="website.url" target="_blank">{{ website.name ? website.name : website.url }}</a></div>
      </div>
      <badge name="Pro Enricher" variant="secondary" class="catalogue-card__pro__tag" v-if="isPro"/>
    </card>
    <card class="catalogue-card__description__section catalogue-card">
      <ds-button v-if="!isExpanded"
        icon="chevron-down"
        variant="link"
        class="catalogue-card__description_button"
        @click="toggleDescription"
      />
      <ds-button v-else
        icon="chevron-up"
        variant="link"
        class="catalogue-card__description_button"
        @click="toggleDescription"
      />
      <div :class="{expandDescription : isExpanded}">
        <div v-if="isExpanded" class="card__description__text">{{ description }}</div>
      </div>
    </card>
  </div>
</template>

<script>
import isNumber from 'lodash/isNumber'

import Avatar from '../../components/Avatar/Avatar.vue'
import Card from '../../components/Card/Card.vue'
import Badge from '../Badge/Badge.vue'

import CompanyMixin from '../../util/CompanyMixin'
import ICONS from '../../../icons/manifest.json'
import { defineComponent } from 'vue'

export default defineComponent({
  name: "catalogue-card",
  data () {
    return {
      isExpanded: false,
      shortDescription: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum",
      data: this.$store.getters
    }
  },
  props: {
    actor: {
      type: Object
    },
    website: {
      type: Object
    },
    description: {
      type: String
    },
    isPro: {
      type: Boolean
    },
    image: {
      type: String
    },
    content: null
  },
  computed: {
    coverStyling () {
      return  "/images/logos/"+this.image
    }
  },
  methods: {
    toggleDescription (){
      if(this.isExpanded) {
        this.isExpanded = false;
      } else {
        this.isExpanded = true;
      }
    }
  },
  components: {
    Avatar,
    card: Card,
    Badge
  },
  mixins: [CompanyMixin]
})
</script>
