<template>
  <div class="homepage-directory-search" v-if="displayedFilters.length > 0">
    <div class="homepage-directory-search__search-and-filters">
      <template v-for="filter in displayedFilters">
        <template v-if="!filter.model">
          <FiltersDropdown
            variant="minimal"
            :options="filter.options"
            :label="filter.label"
            @update:modelValue="filter.onInput"
          />
        </template>
        <template v-else>
          <template v-if="!filter.hierarchical">
            <FiltersDropdown
                    variant="minimal"
                    :options="filter.options"
                    :label="filter.label"
                    :modelValue="filter.model.get"
                    @update:modelValue="filter.model.set"
            />
          </template>
        </template>
      </template>
      <div class="homepage-directory-search__view-all-filters">
        <ds-button label="More filters" variant="link" size="small" @click="handleClickViewAllFilters" />
      </div>
    </div>
    <div class="homepage-directory-search__keywords-container" v-if="visibleKeywords.length > 0">
      <div class="homepage-directory-search__keywords">
        <KeywordWithFacet
                v-for="(keyword, index) in visibleKeywords"
                :key="index"
                :label="getLabelForTaxonomyValue(keyword.facet, keyword.value)"
                :model-value="keyword"
                removeable
                @remove="handleRemoveFilter"
        />
      </div>
      <ds-button label="Delete all" variant="link" size="small" @click="handleClearFilters" />
    </div>
  </div>
</template>

<script>
  import debounce from 'lodash/debounce'

  import FiltersMixin from '../../util/FiltersMixin.js'
  import ConfigMixin from '../../util/ConfigMixin.js'
  import KeywordLabelMixin from '../../util/KeywordLabelMixin'
  import UiMixin from '../../util/UiMixin'
  import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids'
  import * as keywordsApi from '../../api/keywords.js'

  import FiltersDropdown from '../Dropdown/FiltersDropdown.vue'
  import HierarchyDropdown from '../Dropdown/HierarchyDropdown.vue'
  import KeywordWithFacet from '../Keyword/KeywordWithFacet.vue'
  import { toTwoLevelTaxonomyId } from '../../util/hierarchy-taxonomy.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'HomePageDirectorySearch',
    components: { KeywordWithFacet, HierarchyDropdown, FiltersDropdown },
    props: {
      filterTagNumberLimit: {
        type: Number,
        default: 5,
      },
    },
    data () {
      return {
        windowWidth: window.innerWidth,
        categories: [],
        legendSelection: [],
        membershipSelection: [],
        stagesSelection: [],
        marketSelection: [],
        industrySelection: [],
        technologySelection: [],
        domainSelection: [],
        productFeatureASelection: [],
        productFeatureBSelection: [],
        productFeatureCSelection: [],
        officeTypeSelection: [],
        typeSelection: [],
        maturitySelection: [],
        defaultSelection: {
          legends: true,
          membership: true,
          stages: true
        },
      }
    },
    mixins: [UiMixin, KeywordLabelMixin, FiltersMixin, ConfigMixin],
    methods: {
      handleToggleFilter (filter) {
        return (item) => {
          if (this.defaultSelection[filter]) {
            this.defaultSelection[filter] = false
            this.setByFacet(filter, [])
          }
          this.$store.commit(FILTERS_MUTATION_TYPES.TOGGLE_LEGEND_ITEM, {
            facet: filter,
            value: item,
          })
          this.$emit('toggleFilter')
        }
      },
      setByFacet (facet, values) {
        this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, { facet, values })
      },
      handleAddFilter (item) {
        this.$emit('addFilter', item)
      },
      handleRemoveFilter (item) {
        this.$emit('removeFilter', item)
      },
      handleClearFilters () {
        this.$emit('clearFilters')
      },
      handleClickViewAllFilters () {
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ALL_FILTERS)
      },
      canShowTag (index) {
        return (index + 1) <= this.filterTagNumberLimit
      },
      handleSearchInputQueryChange: debounce(async function (value) {
        if (value.length < 2) {
          return
        }

        const response = await keywordsApi.getKeywordsForValue(value, true)
        const keywordsByFacet = response.reduce((map, keyword) => {
          if (!map[keyword.facet]) {
            map[keyword.facet] = [keyword]
          } else {
            map[keyword.facet] = [...map[keyword.facet], keyword]
          }

          return map
        }, {})

        this.categories = Object.entries(keywordsByFacet).map(([facetName, keywords]) => ({
          title: this.humanizeFacet(facetName),
          options: keywords.map(keyword => {
            if (facetName === 'geoid') {
              return {
                ...keyword,
                display: this.geoToLabel(keyword, this.$data.geoNames),
              }
            }

            return keyword
          })
        }))
      }, 300)
    },
    computed: {
      displayedFilters () {
        const controls = this.visibleFilterControls.map((filter) => {
          const additionalFilterProperties = {
            label: '',
            options: [],
            onInput: () => {
            },
            model: null,
          }

          switch (filter) {
            case 'category': {
              additionalFilterProperties.options = this.categoryOptions
              additionalFilterProperties.label = this.getTaxonomyAlias('categories', false, 'Category')
              additionalFilterProperties.onInput = this.handleToggleFilter('category')
              break
            }
            case 'membership': {
              additionalFilterProperties.options = this.membershipOptions
              additionalFilterProperties.label = this.getTaxonomyAlias('memberships', false, 'Memberships')
              additionalFilterProperties.onInput = this.handleToggleFilter('membership')
              break
            }
            case 'stage': {
              additionalFilterProperties.options = this.stageOptions
              additionalFilterProperties.label = this.getTaxonomyAlias('stage', false, 'Growth Stages')
              additionalFilterProperties.onInput = this.handleToggleFilter('stage')
              break
            }
            case 'activities': {
              additionalFilterProperties.options = this.activityOptions
              additionalFilterProperties.label = this.getTaxonomyAlias('activities')
              additionalFilterProperties.onInput = this.handleToggleFilter('activities')
              break
            }
            case 'industries': {
              additionalFilterProperties.options = this.industriesOptions
              additionalFilterProperties.label = this.getTaxonomyAlias('industries')
              additionalFilterProperties.model = {
                get: () => {
                  return this.$store.getters.selectedIndustries
                },
                set: (values) => {
                  this.industrySelection = this.$store.getters.selectedIndustries.slice()
                  const index = this.industrySelection.indexOf(values)
                  if (index > -1) {
                    this.industrySelection.splice(index, 1)
                  } else {
                    this.industrySelection.push(values)
                  }
                  this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
                    facet: 'industries',
                    values: this.industrySelection
                  })
                }
              }
              break
            }
            case 'technology': {
              additionalFilterProperties.options = this.technologyOptions
              additionalFilterProperties.label = this.getTaxonomyAlias('technology')
              additionalFilterProperties.model = {
                get: () => {
                  return this.keywords.filter(k => k.facet === 'technology').map(k => k.value)
                },
                set: (values) => {
                  this.technologySelection = this.keywords.filter(k => k.facet === 'technology').map(k => k.value)
                  const index = this.technologySelection.indexOf(values)
                  if (index > -1) {
                    this.technologySelection.splice(index, 1)
                  } else {
                    this.technologySelection.push(values)
                  }
                  this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
                    facet: 'technology',
                    values: this.technologySelection
                  })
                }
              }
              break
            }
            case 'maturity': {
              additionalFilterProperties.options = this.maturityOptions
              additionalFilterProperties.label = 'Maturity'
              additionalFilterProperties.model = {
                get: () => {
                  return this.keywords.filter(k => k.facet === 'maturity').map(k => k.value)
                },
                set: (values) => {
                  this.maturitySelection = this.keywords.filter(k => k.facet === 'maturity').map(k => k.value)
                  const index = this.maturitySelection.indexOf(values)
                  if (index > -1) {
                    this.maturitySelection.splice(index, 1)
                  } else {
                    this.maturitySelection.push(values)
                  }
                  this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
                    facet: 'maturity',
                    values: this.maturitySelection
                  })
                }
              }
              break
            }
            case 'type': {
              additionalFilterProperties.options = this.businessTypeOptions
              additionalFilterProperties.label = 'Type'
              additionalFilterProperties.model = {
                get: () => {
                  return this.keywords.filter(k => k.facet === 'type').map(k => k.value)
                },
                set: (values) => {
                  this.typeSelection = this.keywords.filter(k => k.facet === 'type').map(k => k.value)
                  const index = this.typeSelection.indexOf(values)
                  if (index > -1) {
                    this.typeSelection.splice(index, 1)
                  } else {
                    this.typeSelection.push(values)
                  }
                  this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, { facet: 'type', values: this.typeSelection })
                }
              }
              break
            }
            case 'domains': {
              additionalFilterProperties.options = this.domainsOptions
              additionalFilterProperties.label = this.getTaxonomyAlias('domains')
              additionalFilterProperties.hierarchical = true
              additionalFilterProperties.model = {
                get: () => {
                  return this.keywords
                    .filter(k => k.facet === 'domains')
                    .map(k => k.value)
                    .map(k => +k > 0 ? k : toTwoLevelTaxonomyId(k, this.$store.state.taxonomies.domains, 'domains'))
                },
                set: (values) => {
                  this.domainSelection = this.keywords.filter(k => k.facet === 'domains').map(k => k.value)
                  const domainSelectionIndex = this.domainSelection.indexOf(values)
                  if (domainSelectionIndex > -1) {
                    this.domainSelection.splice(domainSelectionIndex, 1)
                  } else {
                    this.domainSelection.push(values)
                  }
                  this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
                    facet: 'domains',
                    values: this.domainSelection
                  })
                }
              }
              break
            }
            case 'product_features_a': {
              additionalFilterProperties.options = this.productFeaturesAOptions
              additionalFilterProperties.label = this.getTaxonomyAlias('product_features_a')
              additionalFilterProperties.hierarchical = true
              additionalFilterProperties.model = {
                get: () => {
                  return this.keywords
                    .filter(k => k.facet === 'product_features_a')
                    .map(k => k.value)
                    .map(k => +k > 0 ? k : toTwoLevelTaxonomyId(k, this.$store.state.taxonomies.productFeaturesA, 'children'))
                },
                set: (values) => {
                  this.productFeatureASelection = this.keywords.filter(k => k.facet === 'product_features_a').map(k => k.value)
                  const selectionIndex = this.productFeatureASelection.indexOf(values)
                  if (selectionIndex > -1) {
                    this.productFeatureASelection.splice(selectionIndex, 1)
                  } else {
                    this.productFeatureASelection.push(values)
                  }
                  this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
                    facet: 'product_features_a',
                    values: this.productFeatureASelection
                  })
                }
              }
              break
            }
            case 'product_features_b': {
              additionalFilterProperties.options = this.productFeaturesBOptions
              additionalFilterProperties.label = this.getTaxonomyAlias('product_features_b')
              additionalFilterProperties.hierarchical = true
              additionalFilterProperties.model = {
                get: () => {
                  return this.keywords
                    .filter(k => k.facet === 'product_features_b')
                    .map(k => k.value)
                    .map(k => +k > 0 ? k : toTwoLevelTaxonomyId(k, this.$store.state.taxonomies.productFeaturesB, 'children'))
                },
                set: (values) => {
                  this.productFeatureBSelection = this.keywords.filter(k => k.facet === 'product_features_b').map(k => k.value)
                  const selectionIndex = this.productFeatureBSelection.indexOf(values)
                  if (selectionIndex > -1) {
                    this.productFeatureBSelection.splice(selectionIndex, 1)
                  } else {
                    this.productFeatureBSelection.push(values)
                  }
                  this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
                    facet: 'product_features_b',
                    values: this.productFeatureBSelection
                  })
                }
              }
              break
            }
            case 'product_features_c': {
              additionalFilterProperties.options = this.productFeaturesCOptions
              additionalFilterProperties.label = this.getTaxonomyAlias('product_features_c')
              additionalFilterProperties.hierarchical = true
              additionalFilterProperties.model = {
                get: () => {
                  return this.keywords
                    .filter(k => k.facet === 'product_features_c')
                    .map(k => k.value)
                    .map(k => +k > 0 ? k : toTwoLevelTaxonomyId(k, this.$store.state.taxonomies.productFeaturesC, 'children'))
                },
                set: (values) => {
                  this.productFeatureCSelection = this.keywords.filter(k => k.facet === 'product_features_c').map(k => k.value)
                  const selectionIndex = this.productFeatureCSelection.indexOf(values)
                  if (selectionIndex > -1) {
                    this.productFeatureCSelection.splice(selectionIndex, 1)
                  } else {
                    this.productFeatureCSelection.push(values)
                  }
                  this.$store.commit(FILTERS_MUTATION_TYPES.SET_BY_FACET, {
                    facet: 'product_features_c',
                    values: this.productFeatureCSelection
                  })
                }
              }
              break
            }
          }

          return {
            filter,
            ...additionalFilterProperties,
          }
        })

        if (this.windowWidth <= 768) {
          return controls.slice(0, 1)
        } else if (this.windowWidth <= 1000) {
          return controls.slice(0, 2)
        } else if (this.windowWidth <= 1440) {
          return controls.slice(0, 3)
        } else {
          return controls.slice(0, 4)
        }
      },
      visibleKeywords () {
        return this.keywords.filter((keyword, index) => keyword.value !== '-' && this.canShowTag(index) && keyword.facet !== 'actor_type' && keyword.facet !== 'legendProperty' && keyword.facet.indexOf('report_field_') === -1)
      },
      config () {
        return this.$store.state.config
      },
      displayFilters () {
        return this.$store.state.filters.filter.visible
      },
      hasFilterControls () {
        const filterControlsList = ['category', 'membership', 'stage', 'activities', 'industries', 'technology', 'domains', 'product_features_a', 'product_features_b', 'product_features_c', 'maturity', 'type']

        if (!this.config.filterControls) {
          return []
        }

        return this.config.filterControls.filter(item => filterControlsList.indexOf(item) > -1)
      },
      keywords () {
        return this.$store.state.filters.keywords
      },
      hiddenFacets () {
        return this.enabled.concat(this.legendProperty)
      },
      activeKeywords () {
        return this.$store.getters.activeKeywords.filter(k => !this.hiddenFacets.includes(k.facet) && k.value !== 'LegalEntity' && k.facet !== 'exclude_twitter')
      },
      hiddenTwitterHandles () {
        return this.$store.getters.activeKeywords.filter(k => k.facet === 'exclude_twitter')
      },
      activeMemberships () {
        return this.keywords
          .filter(k => k.facet === 'membership')
          .map(k => k.value)
          .concat('')
      },
      activeStages () {
        return this.keywords
          .filter(k => k.facet === 'stage')
          .map(k => k.value)
          .concat('')
      },
      mapBoundsEnabled () {
        return this.$store.state.filters.mapBounds.tl || this.$store.state.filters.mapBounds.br
      },
    },
    mounted () {
      this.resizeListener = window.addEventListener('resize', debounce(() => {
        this.windowWidth = window.innerWidth
      }, 300))
    },
    beforeUnmount () {
      window.removeEventListener('resize', this.resizeListener)
    }
  })
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

  .homepage-directory-search__search-and-filters {

    .filters__dropdown__button {
      .button__label {
        font-style:italic;
        font-family:$font-stack-primary;
        font-size:12px;
      }
    }

    .categorized-search-input {
      flex: 1;
    }

    .filters__dropdown__button {
      height: 36px;
      width: auto;
      padding-right: 2.25rem;
    }
    .filters--dropdown {
      display: inline;
    }
    .homepage-directory-search__view-all-filters {
      display: inline;
    }
  }

  .homepage-directory-search__keywords-container {
    display: flex;
    margin-top: 0.5rem;
  }

  .homepage-directory-search__keywords {
    flex: 1;
  }
</style>
