<template>
  <div>
    <h2 class="h2">Advanced</h2>
    <div class="row" v-if="form.viewMap &&  form.viewMapOptions">
      <form-group class="col-xs-12 col-md-6" label="Initial map center">
        <div class="row" v-if="form.viewMapOptions.center">
          <ds-input class="col-xs-6" v-model="form.viewMapOptions.center[0]" placeholder="Lat"/>
          <ds-input class="col-xs-6" v-model="form.viewMapOptions.center[1]" placeholder="Lon"/>
        </div>
        <ds-button v-else @click="form.viewMapOptions.center = [ 50.85, 4.35 ]" label="Change map center"/>
      </form-group>
      <form-group class="col-xs-6 col-md-2" label="Map zoom">
        <ds-input v-model="form.viewMapOptions.zoom" placeholder="Zoom"/>
      </form-group>
      <form-group class="col-xs-6 col-md-4" label="Clustering">
        <checkbox style="margin-top: 10px" :model-value="form.viewMapOptions.clustering" @update:modelValue="form.viewMapOptions.clustering = $event" label="Cluster by default"/>
      </form-group>
    </div>

    <div>
      <h4>Zoom in/out to set the "map zoom". Then, click on the map to set the default center points for "Lat" and "Long".</h4>
      <div
        class="home__map-container"
        style="height: 450px; margin-top: 20px;"
      >
        <mapView clusterPosition="bottomleft"
                 searchWhileMovingPosition="bottomleft"
                 :data="[]"
                 :getMapData="getMapData"
                 :getData="getMapData"
                 ref="mapPane"
                 @selectedLat="getSelectedLat"
                 enableOnClick="true"
                 :mapEnabled="true">

        </mapView>
      </div>
    </div>

    <div v-if="form.viewMap && !form.viewMapOptions">
      <ds-button @click="form.viewMapOptions = { zoom: 5, center: [ 50.85, 4.35 ]}" label="Change map options"/>
    </div>

    <form-group label="Colours" style="width:650px;" :instructions="form.hex && (form.hex.length + ' / 30 colors')">
      <color-picker title="Ecosystem Colour" :colors="formHex" @update:modelValue="updateColors" @updatePrimary="updatePrimaryColor" :initial-value="form.primaryColor"/>
    </form-group>

    <h4 style="margin-bottom: 0.5rem;">Taxonomy aliases</h4>

    <editor-language-dropdown v-model="editingLocale" style="margin-bottom: 20px; margin-top: 10px; max-width: 250px;"/>

    <!--<div class="row" v-if="form.languages && form.languages.length > 0">-->
    <div class="col-md-6 col-xs-12">
      <form-group label="Category">
        <ds-input v-model="categoriesAlias"/>
      </form-group>
      <form-group label="Activities">
        <ds-input v-model="activitiesAlias"/>
      </form-group>
      <form-group label="Membership">
        <ds-input v-model="membershipsAlias"/>
      </form-group>
      <form-group label="Technologies">
        <ds-input v-model="technologiesAlias"/>
      </form-group>
      <form-group label="Domains">
        <ds-input v-model="domainsAlias"/>
      </form-group>
      <form-group label="Stage">
        <ds-input v-model="stagesAlias"/>
      </form-group>
      <form-group label="Funnel Stage">
        <ds-input v-model="funnelStageAlias"/>
      </form-group>
    </div>

    <div class="col-md-6 col-xs-12">
      <form-group label="Industries">
        <ds-input v-model="industriesAlias"/>
      </form-group>
      <form-group label="Sub-Industries">
        <ds-input v-model="subindustriesAlias"/>
      </form-group>
      <form-group label="Expertises">
        <ds-input v-model="expertisesAlias"/>
      </form-group>
      <form-group label="Motivations">
        <ds-input v-model="motivationsAlias"/>
      </form-group>
      <form-group label="Readiness levels" v-if="form.taxonomyReadinessEnabled">
        <ds-input v-model="readinessLevelsAlias"/>
      </form-group>
      <form-group label="Process steps" v-if="form.taxonomyProcessStepsEnabled">
        <ds-input v-model="processStepsAlias"/>
      </form-group>
      <form-group label="Business aspects" v-if="form.taxonomyBusinessAspectsEnabled">
        <ds-input v-model="businessAspectsAlias"/>
      </form-group>
      <form-group label="Aspects" v-if="form.taxonomyAspectsEnabled">
        <ds-input v-model="aspectsAlias"/>
      </form-group>
      <template v-if="$store.getters.isProductEnabled">
        <form-group label="Product Features A">
          <ds-input v-model="productFeaturesAAlias"/>
        </form-group>
        <form-group label="Sub-Product Features A">
          <ds-input v-model="subProductFeaturesAAlias"/>
        </form-group>
        <form-group label="Product Features B">
          <ds-input v-model="productFeaturesBAlias"/>
        </form-group>
        <form-group label="Sub-Product Features B">
          <ds-input v-model="subProductFeaturesBAlias"/>
        </form-group>
        <form-group label="Product Features C">
          <ds-input v-model="productFeaturesCAlias"/>
        </form-group>
        <form-group label="Sub-Product Features C">
          <ds-input v-model="subProductFeaturesCAlias"/>
        </form-group>
      </template>
    </div>

    <h4 style="margin-bottom: 0.5rem;">Dashboard aliases</h4>
    <div class="col-md-6 col-xs-12">
      <form-group label="Product">
        <ds-input v-model="productAlias"/>
      </form-group>
      <form-group label="Products (plural)">
        <ds-input v-model="productsAlias"/>
      </form-group>
      <form-group label="Announcement">
        <ds-input v-model="announcementAlias"/>
      </form-group>
      <form-group label="Announcements (plural)">
        <ds-input v-model="announcementsAlias"/>
      </form-group>
      <form-group label="Knowledge Base">
        <ds-input v-model="knowledgeBaseAlias"/>
      </form-group>
      <form-group label="Event">
        <ds-input v-model="eventAlias"/>
      </form-group>
      <form-group label="Events (plural)">
        <ds-input v-model="eventsAlias"/>
      </form-group>
      <form-group label="Challenge">
        <ds-input v-model="challengeAlias"/>
      </form-group>
      <form-group label="Challenges (plural)">
        <ds-input v-model="challengesAlias"/>
      </form-group>
      <form-group label="Spotting area">
        <ds-input v-model="spottingAreaAlias"/>
      </form-group>
      <form-group label="Spotting Areas (plural)">
        <ds-input v-model="spottingAreasAlias"/>
      </form-group>
    </div>
    <!--</div>-->

    <!--<form-group label="Alias for activities">
      <ds-input v-model="form.activities_alias" placeholder="activities" />
    </form-group>
    <form-group label="Alias for technologies">
      <ds-input v-model="form.technology_alias" placeholder="technology" />
    </form-group>
    <form-group label="Alias for industries">
      <ds-input v-model="form.industries_alias" placeholder="industries" />
    </form-group>
    <form-group label="Alias for sub industries">
      <ds-input v-model="form.subindustries_alias" placeholder="sub industries" />
    </form-group>
    <form-group label="Alias for domains">
      <ds-input v-model="form.domains_alias" placeholder="domains" />
    </form-group>
    <form-group label="Alias for expertises">
      <ds-input v-model="form.expertises_alias" placeholder="expertises" />
    </form-group>-->
  </div>
</template>

<script>
  import TranslationsMixin, { localizedEditProperty } from '../../../util/TranslationsMixin'
  import DsTextarea from '../../Form/DsTextarea.vue'
  import ImageInput from '../../Form/ImageInput.vue'
  import Dropdown from '../../Dropdown/Dropdown.vue'
  import RichTextArea from '../../TextArea/RichTextArea.vue'
  import ColorPicker from '../../Form/ColorPicker.vue'
  import TagInput from '../../Form/TagInput.vue'
  import Checkbox from '../../Form/Checkbox.vue'
  import MapView from '../../../components/MapView/MapView.vue'
  import { DEFAULT_LOCALE } from '../../../store/modules/localization'
  import EditorLanguageDropdown from '../../Translations/EditorLanguageDropdown.vue'

  export default {
    name: 'advanced',
    props: ['form'],
    emits: ['selectedLat'],
    data () {
      return {
        editingLocale: DEFAULT_LOCALE,
      }
    },
    methods: {
      getMapData () {
        return []
      },
      getSelectedLat (event) {
        this.form.viewMapOptions.center[0] = event.latLng.lat
        this.form.viewMapOptions.center[1] = event.latLng.lng
        this.form.viewMapOptions.zoom = event.zoom
      },
      updateColors (allColorsObject, changedColorObject) {
        // We update the colors for the color picker
        this.formHex = allColorsObject
      },
      updatePrimaryColor (val) {
        this.form.primaryColor = val
      },
    },
    computed: {
      formHex: {
        get () {
          return (this.form.hexColours || {})
        },
        set (colorsObject) {
          this.form.hexColours = colorsObject
        },
      },
      categoriesAlias: localizedEditProperty('editingLocale', 'form', 'categoriesAlias'),
      activitiesAlias: localizedEditProperty('editingLocale', 'form', 'activitiesAlias'),
      membershipsAlias: localizedEditProperty('editingLocale', 'form', 'membershipsAlias'),
      technologiesAlias: localizedEditProperty('editingLocale', 'form', 'technologiesAlias'),
      domainsAlias: localizedEditProperty('editingLocale', 'form', 'domainsAlias'),
      stagesAlias: localizedEditProperty('editingLocale', 'form', 'stagesAlias'),
      funnelStageAlias: localizedEditProperty('editingLocale', 'form', 'funnelStageAlias'),
      industriesAlias: localizedEditProperty('editingLocale', 'form', 'industriesAlias'),
      subindustriesAlias: localizedEditProperty('editingLocale', 'form', 'subindustriesAlias'),
      expertisesAlias: localizedEditProperty('editingLocale', 'form', 'expertisesAlias'),
      motivationsAlias: localizedEditProperty('editingLocale', 'form', 'motivationsAlias'),
      readinessLevelsAlias: localizedEditProperty('editingLocale', 'form', 'readinessLevelsAlias'),
      processStepsAlias: localizedEditProperty('editingLocale', 'form', 'processStepsAlias'),
      businessAspectsAlias: localizedEditProperty('editingLocale', 'form', 'businessAspectsAlias'),
      productFeaturesAAlias: localizedEditProperty('editingLocale', 'form', 'productFeaturesAAlias'),
      subProductFeaturesAAlias: localizedEditProperty('editingLocale', 'form', 'subProductFeaturesAAlias'),
      productFeaturesBAlias: localizedEditProperty('editingLocale', 'form', 'productFeaturesBAlias'),
      subProductFeaturesBAlias: localizedEditProperty('editingLocale', 'form', 'subProductFeaturesBAlias'),
      productFeaturesCAlias: localizedEditProperty('editingLocale', 'form', 'productFeaturesCAlias'),
      subProductFeaturesCAlias: localizedEditProperty('editingLocale', 'form', 'subProductFeaturesCAlias'),
      aspectsAlias: localizedEditProperty('editingLocale', 'form', 'aspectsAlias'),
      productAlias: localizedEditProperty('editingLocale', 'form', 'productAlias'),
      productsAlias: localizedEditProperty('editingLocale', 'form', 'productsAlias'),
      announcementAlias: localizedEditProperty('editingLocale', 'form', 'announcementAlias'),
      announcementsAlias: localizedEditProperty('editingLocale', 'form', 'announcementsAlias'),
      knowledgeBaseAlias: localizedEditProperty('editingLocale', 'form', 'knowledgeBaseAlias'),
      eventAlias: localizedEditProperty('editingLocale', 'form', 'eventAlias'),
      eventsAlias: localizedEditProperty('editingLocale', 'form', 'eventsAlias'),
      challengeAlias: localizedEditProperty('editingLocale', 'form', 'challengeAlias'),
      challengesAlias: localizedEditProperty('editingLocale', 'form', 'challengesAlias'),
      spottingAreaAlias: localizedEditProperty('editingLocale', 'form', 'spottingAreaAlias'),
      spottingAreasAlias: localizedEditProperty('editingLocale', 'form', 'spottingAreasAlias'),
    },
    mixins: [
      TranslationsMixin,
    ],
    components: {
      TagInput,
      DsTextarea,
      ImageInput,
      Dropdown,
      Checkbox,
      MapView,
      RichTextArea,
      EditorLanguageDropdown,
      ColorPicker,
    },
  }
</script>

<style lang="scss" scoped>

</style>
