<template>
  <div class="connection-card">
    <div class="legend-line" :style="{ backgroundColor: legendColorForActor(connection) }" />
    <router-link :to="'/actors/' + connection.id">
      {{ connection.name }}
    </router-link>

    <div class="card-buttons">
      <a
        href="#"
        @click="handleClickEdit($event)"
        v-if="! ['subsidiaries', 'main_company'].includes(relation)"
      >
        <icon name="edit" size="extra-small" />
      </a>
      <a
        class="remove-link"
        href="#"
        @click="handleRemoveRelation($event)"
        v-if="! ['subsidiaries', 'main_company'].includes(relation)"
      >
        <icon name="trash" size="extra-small" />
      </a>
    </div>
  </div>
</template>

<script>
import Dropdown from '../../Dropdown/Dropdown.vue'
import FiltersMixin from '../../../util/FiltersMixin'

export default {
  data () {
    return {
      editVisible: false,
      relation: this.connection.type,
    }
  },
  props: {
    connection: Object,
    remove: Function,
    edit: Function,
  },
  mixins: [FiltersMixin],
  components: {
    Dropdown,
  },
  computed: {
    ecosystemRelationships () {
      return this.$store.getters.fullActorRelationships
    },
  },
  methods: {
    handleClickEdit (e) {
      e.preventDefault()
      this.$emit('edit', this.connection)
    },
    handleRemoveRelation (e) {
      e.preventDefault()
      this.$emit('remove')
    },
  },
}
</script>

<style lang="scss" scoped>
.connection-card {
  align-items: center;
  border: 1px solid #dadada;
  display: flex;
  margin-bottom: 8px;
  padding: 0 8px 0 20px;
  position: relative;

  a {
    color: #a0a0a0;
    text-decoration: none;
    text-transform: uppercase;
  }
}

.legend-line {
  box-shadow: inset 1px 1px 0 rgba(0, 0, 0, .1), inset 0 -1px rgba(0, 0, 0, .1);
  bottom: -1px;
  left: -1px;
  position: absolute;
  top: -1px;
  width: 12px;
}

.card-buttons {
  margin-left: auto;
}

.edit-popup {
  background-color: white;
  border: 1px solid #dadada;
  left: -1px;
  padding: 10px 5px 5px;
  position: absolute;
  right: -1px;
  top: 100%;
  z-index: 1;

  .form-group {
    margin-bottom: 5px;
  }
}
</style>
