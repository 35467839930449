export const MUTATION_TYPES = {
  STORE_PROFILE: 'USER/STORE_PROFILE',
  UPDATE_PROFILE: 'USER/UPDATE_PROFILE',
  HAS_CLAIMED: 'USER/HAS_CLAIMED',
  LOGOUT_SUCCESS: 'USER/LOGOUT_SUCCESS',
  STORE_SETTINGS: 'USER/STORE_SETTINGS',
  UPDATE_FOLLOWED_PORTFOLIOS: 'USER/UPDATE_FOLLOWED_PORTFOLIOS',
  UPDATE_OWNED_PORTFOLIOS: 'USER/UPDATE_OWNED_PORTFOLIOS',
  UPDATE_FOLLOWED_SEARCHES: 'USER/UPDATE_FOLLOWED_SEARCHES',
  FETCH_PACKAGE_SUBSCRIPTION_SUCCESS: 'USER/FETCH_PACKAGE_SUBSCRIPTION_SUCCESS',
  SET_PACKAGE_SUBSCRIPTION_PROMISE: 'USER/SET_PACKAGE_SUBSCRIPTION_PROMISE',
  FETCH_BILLING_INFO_SUCCESS: 'USER/FETCH_BILLING_INFO_SUCCESS',
}
