<template>
  <modal
    :id="modalId"
    ref="modal"
    class="edit-user-profile__modal with-subtitle"
    @close="close"
    :title="$t('profile_edit_title')"
    is-simplified-modal
  >
    <template v-slot:title>
      <h2 class="title">{{ $t('profile_edit_title') }}
        <span
          class="mandatory-message"
        >{{
            $t('all_fields_with_asterisk_are_mandatory')
          }}</span>
      </h2>
    </template>
    <template v-slot:body>
      <div class="edit-user-profile__modal-content">
        <div
          class="row"
          style="margin-bottom: 0;"
        >
          <form-group
            is-simlified
            :label="$t('profile_name_label')"
            required
            :error="errors.name"
            class="col-xs-12"
          >
            <ds-input
              is-simplified
              v-model="user.name"
            />
          </form-group>
          <form-group
            is-simplified
            :label="$t('profile_edit_short_description')"
            required
            :error="errors.bio"
            class="col-xs-12"
          >
            <ds-textarea
              is-simplified
              v-model="user.bio"
            />
          </form-group>
          <form-group
            is-simplified
            :label="$t('profile_edit_department')"
            :required="!departmentFunctionFieldsOptional"
            :error="errors.department"
            v-if="departmentOptions && departmentOptions.length"
            class="col-xs-12 col-sm-6"
          >
            <dropdown
              is-simplified
              v-model="user.department"
              :options="departmentOptions"
            />
          </form-group>
          <form-group
            is-simplified
            :label="$t('profile_edit_function')"
            :required="!departmentFunctionFieldsOptional"
            :error="errors.function"
            v-if="functionOptions && functionOptions.length"
            class="col-xs-12 col-sm-6"
          >
            <dropdown
              is-simplified
              v-model="user.function"
              :options="functionOptions"
            />
          </form-group>

          <form-group
            is-simplified
            :label="$t('profile_edit_expertises', {expertises: getTaxonomyAliasWithDefault('expertises', false, $t('actor_edit_expertises'))})"
            required
            :error="errors.expertises"
            v-if="expertiseOptions && expertiseOptions.length"
            class="col-xs-12 col-sm-6"
          >
            <dropdown
              is-simplified
              v-model="user.expertises"
              multiple
              :options="expertiseOptions"
            />
          </form-group>
          <form-group
            is-simplified
            :label="$t('profile_edit_motivations', {motivations: getTaxonomyAlias('motivations', false, $t('actor_edit_motivations'))})"
            required
            v-if="motivationOptions && motivationOptions.length"
            :error="errors.motivations"
            class="col-xs-12 col-sm-6"
          >
            <dropdown
              is-simplified
              v-model="user.motivations"
              multiple
              :options="motivationOptions"
            />
          </form-group>
          <form-group
            is-simplified
            :label="$t('profile_edit_linkedin')"
            :error="errors.linkedin_url"
            class="col-xs-12 col-sm-6"
          >
            <ds-input
              is-simplified
              v-model="user.linkedin_url"
            />
          </form-group>
          <form-group
            is-simplified
            :label="$t('profile_edit_instagram')"
            :error="errors.instagram_url"
            class="col-xs-12 col-sm-6"
          >
            <ds-input
              is-simplified
              v-model="user.instagram_url"
            />
          </form-group>
          <form-group
            is-simplified
            :label="$t('profile_edit_twitter')"
            :error="errors.twitter_url"
            class="col-xs-12"
          >
            <ds-input
              is-simplified
              v-model="user.twitter_url"
            />
          </form-group>
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div style="width: 100%;">
        <ds-button
          variant="primary"
          @click="submit"
          :label="$t('announcement_save')"
          size="extra-small"
          :disabled="saving"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import {MUTATION_TYPES as UI_MUTATION_TYPES} from '../../store/modules/ui'
  import MODAL_IDS from '../../constants/modal-ids.js'
  import {updatePhoneNumber, updateProfile} from '../../api/user.js'
  import {prepareUserForEditing} from '../../util/user-settings.js'
  import DsTextarea from '../Form/DsTextarea.vue'
  import Dropdown from '../Dropdown/Dropdown.vue'
  import FiltersMixin from '../../util/FiltersMixin.js'
  import _isEmpty from 'lodash/isEmpty'
  import {MUTATION_TYPES as USER_MUTATION_TYPES} from '../../store/modules/user-helpers.js'
  import ConfigMixin from '../../util/ConfigMixin.js'
  import Checkbox from "@/components/Form/Checkbox.vue";

  export default {
    data () {
      return {
        modalId: MODAL_IDS.EDIT_USER_PROFILE,
        errors: {},
        user: this.currentUser(),
        saving: false
      }
    },
    computed: {
      departmentFunctionFieldsOptional () {
        return !!this.$store.state.config.departmentFunctionFieldsOptional
      },
    },
    methods: {
      currentUser () {
        const profile = this.$store.state.user.profile
        return prepareUserForEditing(profile)
      },
      validateForm () {
        this.errors = {}
        if (!this.user.name) {
          this.errors.name = this.$t('profile_error_name_required')
        }
        if (!this.user.bio) {
          this.errors.bio = this.$t('profile_error_bio_required')
        }
        if (this.expertiseOptions && this.expertiseOptions.length && this.user.expertises.length === 0) {
          this.errors.expertises = this.$t('profile_error_expertise_required')
        }
        if (this.motivationOptions && this.motivationOptions.length && this.user.motivations.length === 0) {
          this.errors.motivations = this.$t('profile_error_motivation_required')
        }

        if (!this.departmentFunctionFieldsOptional) {
          if (this.departmentOptions && this.departmentOptions.length && (!this.user.department || this.user.department.length === 0)) {
            this.errors.department = this.$t('profile_error_department_required')
          }
          if (this.functionOptions && this.functionOptions.length && (!this.user.function || this.user.function.length === 0)) {
            this.errors.function = this.$t('profile_error_function_required')
          }
        }
      },
      async submit () {
        this.saving = true
        this.validateForm()

        if (!_isEmpty(this.errors)) {
          this.saving = false
          return
        }

        updateProfile({
          name: this.user.name,
          bio: this.user.bio,
          expertises: this.user.expertises,
          motivations: this.user.motivations,
          department: this.user.department,
          function: this.user.function,
          linkedin_url: this.user.linkedin_url,
          instagram_url: this.user.instagram_url,
          twitter_url: this.user.twitter_url,
        })
          .then(data => {
            this.$store.commit(USER_MUTATION_TYPES.UPDATE_PROFILE, data)
            this.$bus.emit('updateUserProfile')
            this.close()
            this.saving = false
          })
          .catch(errors => {
            this.errors = errors
            this.saving = false
          })
      },
      close () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, MODAL_IDS.EDIT_USER_PROFILE)
        this.$emit('close')
      },
    },
    mixins: [FiltersMixin, ConfigMixin],
    mounted () {

    },
    components: {
      Checkbox,
      Modal,
      DsTextarea,
      Dropdown,
    },
  }
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

  .edit-user-profile__modal.modal__simplified.with-subtitle {
    .modal__footer {
      width: 100%;

      .button {
        width: 100%;
        border-radius: $default-border-radius-narrow;
      }
    }

    .edit-user-profile__modal-content {
      padding: 20px;
    }

    .form-group {
      .form-group__label__text {
        color: var(--primary);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
</style>
