<template>
  <header class="basic-hero" :class="{'collapse-hero': collapseHero, 'wide': isAgritech}">
    <div class="basic-hero__header">
      <h2 class="basic-hero__title" :class="{'is-agritech': isAgritech}" :style="basicHeroTitleStying">{{ title }}</h2>
      <h3 v-if="!ui.isMobile && subtitleText" :class="{'is-community': isCommunity}" class="basic-hero__subtitle subtitle"
          ref="subtitle"
          id="subtitle"
          v-html="subtitleText"
          :style="basicHeroSubtitleStying">
      </h3>
      <div
        class="basic-hero__read-more-button"
        v-if="isOverflowed && showExpandButton"
        @click="toggleReadMore"
      >
        {{ isReadMore ? $t('knowledge_sharing_read_less') : $t('knowledge_sharing_read_more') }}
      </div>
    </div>
    <div class="basic-hero__slot" v-if="$slots.default">
      <slot></slot>
    </div>
  </header>
</template>

<script>
  import UiMixin from '../../util/UiMixin.js'
  import { defineComponent } from 'vue'
  import { stripHtmlTags } from '@/util/helpers.js'

  export default defineComponent({
    name: 'BasicHero',
    mixins: [UiMixin],
    props: {
      title: String,
      subtitle: String,
      collapseHero: {
        type: Boolean,
        default: false,
      },
      isAgritech: {
        type: Boolean,
        default: false,
      },
      showExpandButton: {
        type: Boolean,
        default: false,
      }
    },
    data () {
      return {
        isReadMore: false,
        isOverflowed: false,
      }
    },
    computed: {
      subtitleText () {
        return (this.isOverflowed && !this.isReadMore) ? this.subtitle.slice(0, 500).concat('...') : this.subtitle
      },
      isCommunityEcosystem () {
        return this.$store.getters.isCommunityEcosystem
      },
      basicHeroTitleStying () {
        if (this.isAgritech) {
          return `font-size: 32px`
        }
        if (this.isCommunityEcosystem) {
          return `font-weight: 500;`
        }
      },
      basicHeroSubtitleStying () {
        if (this.isCommunityEcosystem) {
          return `font-size: 0.875rem;`
        }
      },
      isCommunity () {
        return this.$route.name === 'community-detail'
      },
    },
    methods: {
      toggleReadMore () {
        this.isReadMore = !this.isReadMore

        //document.getElementById('subtitle').classList.toggle('read-more')
      },
    },
    mounted () {
      setTimeout(() => {
        if (this.subtitle && stripHtmlTags(this.subtitle).length > 500 && this.showExpandButton) {
          this.isOverflowed = true
        }
      }, 500)
    }
  })
</script>

<style scoped lang="scss">
  @import "../../../scss/variables";

  .basic-hero.wide {
    max-width: 1450px !important;
  }

  .basic-hero__read-more-button {
    text-decoration: underline;
    margin-top: 15px;
    margin-bottom: 15px;
    margin-left: 10px;

    &:hover {
      cursor: pointer;
    }
  }

  .subtitle {
    overflow: hidden;
  }

  .subtitle.read-more {
    max-height: fit-content !important;
    overflow: visible !important
  }

  .subtitle.overflow {
    max-height: 195px;
  }

  .basic-hero {
    padding: 2.5rem 0;
    background: var(--primary-community);
    color: #fff;
    text-align: center;

    :deep(a) {
      color: white !important;
    }

    &.collapse-hero {
      padding-top: 0;
    }
  }

  .home-hero {
    .basic-hero__title, .basic-hero__subtitle {
      text-shadow: 0 3px 6px rgba(0, 0, 0, 0.6);
    }
  }

  .basic-hero__title, .basic-hero__subtitle {
    padding: 0 10px 10px 10px;
  }

  .basic-hero__title {
    font-weight: normal;
    font-size: 2rem;
  }

  @media screen and (max-width: $screen-lg) {
    .basic-hero__title.is-agritech {
      font-size: 20px !important;
    }
  }

  .is-community {
    max-width: 750px;
  }

  .basic-hero__subtitle {
    font-weight: normal;
    font-size: 1rem;
    margin-top: 2px;
    margin-left: auto;
    margin-right: auto;
  }

  .basic-hero__subtitle {
    :deep(ul) {
      list-style-type: disc;
      margin-left: 15px;
    }
  }

  .basic-hero__slot {
    display: inline-block;
    width: 600px;
    margin-top: 8px;

    .tags {
      flex-direction: row;
      justify-content: center;
    }
  }

  .collapse-hero {
    .basic-hero__header {
      display: none;
    }

    .basic-hero__slot {
      margin-top: 0;
    }
  }

  @media screen and (max-width: 768px) {
    .basic-hero__slot {
      width: 90% !important;
    }
  }
</style>
