<template>
  <div>
    <template v-if="hasAccessToScores || hasAccessToCustomScores">
      <h2 class="h2" style="margin-top:20px;">Systematic Scoring & Ranking</h2>
      <checkbox v-model="form.hasCustomScores" label="Activate Custom Scoring" v-if="hasAccessToCustomScores"/>
      <checkbox v-model="form.hasScores" label="Activate Competitive Dashboards" v-if="hasAccessToScores"/>

      <form-group label="Reference Actor" errors="portfolio_actor" style="margin-top: 20px;">
        <AutocompleteDropdown v-model="portfolioActor" placeholder="Search..." :do-search="fetchActorSuggestions"/>
      </form-group>
    </template>

    <h2 class="h2" style="margin-top:20px;">Discovery Agents</h2>
    <checkbox label="Enable pulling in offices for companies, these offices are based on existing registry information." :val="true" :model-value="areOfficesEnabled"
      @update:modelValue="toggleDisableSubsidiaries"/>
    <template v-if="hasAccessToAgents">
      <checkbox
        label="Office Agent. Enabling this agent will search for offices everytime a URL changes on an actor profile of a company or community and will add them as a suggestion."
        v-model="form.officeSearcherEnabled"/>
      <checkbox
        label="Investor Agent. DataScouts will search and add actors that existing actors have received investments from or have invested in. This happens only for actors that have been added by a user."
        v-model="form.canReceiveRelatedInvestors"/>
      <checkbox
        label="Projects Agent. DataScouts will search and add actors (projects, organisations, universities) that existing actors have partnered with. This happens only for actors that have been added by a user."
        v-model="form.canReceiveRelatedProjects"/>
      <checkbox
        label="Administrator Agent. DataScouts will search and add actors that administer existing actors. This agent will only for search for related actors for profiles that have been added by a user."
        v-model="form.canReceiveRelatedAdministrators"/>
      <checkbox
        label="Founder Agent. DataScouts will search and add actors that have (co-)founded existing actors. This agent will only for search for related actors for profiles that have been added by a user."
        v-model="form.canReceiveRelatedFounders" v-if="isFeatureFlagEnabled"/>
      <checkbox
        label="Team Agent. DataScouts will search and add actors that make up the current team that runs existing actors. This agent will only for search for related actors for profiles that have been added by a user."
        v-model="form.canReceiveRelatedTeam" v-if="isFeatureFlagEnabled"/>
      <checkbox
        label="(Beta) Portfolio Agent. Enabling this agent will display a button on the connections tab of an actor, upon clicking that button an agent will search for portfolio members via the URL of that actor."
        v-model="form.portfolioCrawlerEnabled"/>
      <checkbox label="News Agent. Enabling this agent will pull in CB Pro news and make it available on the actor profiles, as well as in the knowledge base."
        v-model="form.cbProNewsEnabled"/>
      <checkbox label="Acquisition Agent. Enabling this agent will pull in companies that the actor acquired or the company that acquired an actor."
        v-model="form.canReceiveRelatedAcquisitions"/>
    </template>

    <h2 class="h2" style="margin-top:20px;">Suggestions</h2>
    <span>Activity Suggestions</span>
    <form-group>
      <toggle-button color="success" :enabled="form.activitySuggestionEnabled"
        @update:modelValue="form.activitySuggestionEnabled = $event; ! form.activitySuggestionEnabled ? form.activitySuggestionAutoAcceptEnabled = false : '';" :typeBold="false"
        textEnabled="ON" textDisabled="OFF"/>
      <toggle-button color="success" :enabled="form.activitySuggestionAutoAcceptEnabled"
        @update:modelValue="form.activitySuggestionAutoAcceptEnabled = $event && form.activitySuggestionEnabled" :typeBold="false" textEnabled="auto" textDisabled="auto"
        style="margin-left:15px;"/>
    </form-group>
    <span>Technology Suggestions</span>
    <form-group>
      <toggle-button color="success" :enabled="form.technologySuggestionEnabled"
        @update:modelValue="form.technologySuggestionEnabled = $event; ! form.technologySuggestionEnabled ? form.technologySuggestionAutoAcceptEnabled = false : '';"
        :typeBold="false" textEnabled="ON" textDisabled="OFF"/>
      <toggle-button color="success" :enabled="form.technologySuggestionAutoAcceptEnabled"
        @update:modelValue="form.technologySuggestionAutoAcceptEnabled = $event && form.technologySuggestionEnabled" :typeBold="false" textEnabled="auto" textDisabled="auto"
        style="margin-left:15px;"/>
    </form-group>
    <span>Industry Suggestions</span>
    <form-group>
      <toggle-button color="success" :enabled="form.industrySuggestionEnabled"
        @update:modelValue="form.industrySuggestionEnabled = $event; ! form.industrySuggestionEnabled ? form.industrySuggestionAutoAcceptEnabled = false : '';" :typeBold="false"
        textEnabled="ON" textDisabled="OFF"/>
      <toggle-button color="success" :enabled="form.industrySuggestionAutoAcceptEnabled"
        @update:modelValue="form.industrySuggestionAutoAcceptEnabled = $event && form.industrySuggestionEnabled" :typeBold="false" textEnabled="auto" textDisabled="auto"
        style="margin-left:15px;"/>
    </form-group>
    <span>Domain Suggestions</span>
    <form-group>
      <toggle-button color="success" :enabled="form.domainSuggestionEnabled"
        @update:modelValue="form.domainSuggestionEnabled = $event; ! form.domainSuggestionEnabled ? form.domainSuggestionAutoAcceptEnabled = false : '';" :typeBold="false"
        textEnabled="ON" textDisabled="OFF"/>
      <toggle-button color="success" :enabled="form.domainSuggestionAutoAcceptEnabled"
        @update:modelValue="form.domainSuggestionAutoAcceptEnabled = $event && form.domainSuggestionEnabled" :typeBold="false" textEnabled="auto" textDisabled="auto"
        style="margin-left:15px;"/>
    </form-group>
    <span>Category Suggestions</span>
    <form-group>
      <toggle-button color="success" :enabled="form.categorySuggestionEnabled"
        @update:modelValue="form.categorySuggestionEnabled = $event; ! form.categorySuggestionEnabled ? form.categorySuggestionAutoAcceptEnabled = false : '';" :typeBold="false"
        textEnabled="ON" textDisabled="OFF"/>
      <toggle-button color="success" :enabled="form.categorySuggestionAutoAcceptEnabled"
        @update:modelValue="form.categorySuggestionAutoAcceptEnabled = $event && form.categorySuggestionEnabled" :typeBold="false" textEnabled="auto" textDisabled="auto"
        style="margin-left:15px;"/>
    </form-group>
  </div>
</template>

<script>
  import AutocompleteDropdown from '../../Dropdown/AutocompleteDropdown.vue'
  import Checkbox from '../../Form/Checkbox.vue'
  import Dropdown from '../../Dropdown/Dropdown.vue'
  import DsTextarea from '../../Form/DsTextarea.vue'
  import ImageInput from '../../Form/ImageInput.vue'
  import TagInput from '../../Form/TagInput.vue'
  import ToggleButton from '../../Form/ToggleButton.vue'

  import { fetchActor, suggestActors } from '../../../api/actors.js'

  export default {
    name: 'data-enrichment',
    props: ['form'],
    data () {
      return {
        referenceActorLabel: '',
      }
    },
    methods: {
      toggleDisableSubsidiaries () {
        this.form.disableSubsidiaries = !this.form.disableSubsidiaries
      },
      fetchActorSuggestions (query) {
        return suggestActors(query)
          .then(response => {
            var options = []

            response.forEach(function (actor) {
              options.push({
                label: actor.name,
                value: actor.id,
              })
            })

            return options
          })
          .catch(err => {
            // console.log(err)
          })
      },
    },
    computed: {
      portfolioActor: {
        get () {
          return {
            label: this.referenceActorLabel,
            value: this.form.portfolio_actor,
          }
        },
        set (v) {
          this.form.portfolio_actor = v.value
          this.referenceActorLabel = v.label
        },
      },
      areOfficesEnabled () {
        return !this.form.disableSubsidiaries
      },
      hasAccessToAgents () {
        return this.$store.getters.hasAccessToAgents
      },
      hasAccessToScores () {
        return this.$store.getters.hasAccessToScores
      },
      hasAccessToCustomScores () {
        return this.$store.getters.hasAccessToCustomScores && this.$store.getters.isDeveloper
      },
      isFeatureFlagEnabled () {
        return ['Workero', 'Satisco', 'own', 'salesenablement', 'local'].includes(this.$store.getters.ecosystemName)
      },
    },
    async mounted () {
      if (this.hasAccessToScores && this.form.portfolio_actor) {
        var result = await fetchActor(this.form.portfolio_actor)

        if (result && result.name) {
          this.referenceActorLabel = result.name
        }
      }
    },
    components: {
      Checkbox,
      Dropdown,
      DsTextarea,
      ImageInput,
      TagInput,
      ToggleButton,
      AutocompleteDropdown,
    },
  }
</script>
