<template>
  <div class="card__info__social simplified-actor-social-icons">
    <SimpleSocialItem :urls="actor.facebook">
      <icon size="18" name="facebook-logo"/>
    </SimpleSocialItem>
    <SimpleSocialItem :urls="actor.twitter">
      <icon size="18" name="twitter-logo"/>
    </SimpleSocialItem>
    <SimpleSocialItem :urls="actor.reddit">
      <icon size="18" name="reddit-logo"/>
    </SimpleSocialItem>
    <SimpleSocialItem :urls="actor.linkedin">
      <icon size="18" name="linkedin-logo"/>
    </SimpleSocialItem>
    <SimpleSocialItem :urls="actor.vimeo">
      <icon size="18" name="vimeo-logo"/>
    </SimpleSocialItem>
    <SimpleSocialItem :urls="actor.youtube">
      <icon size="18" name="youtube-play"/>
    </SimpleSocialItem>
    <SimpleSocialItem :urls="actor.instagram">
      <icon size="18" name="instagram-logo"/>
    </SimpleSocialItem>
    <SimpleSocialItem :urls="actor.rss_feed">
      <icon size="18" name="rss"/>
    </SimpleSocialItem>
    <SimpleSocialItem :urls="actor.crunchbase">
      <icon size="18" name="crunchbase-logo-letters"/>
    </SimpleSocialItem>
    <SimpleSocialItem :urls="actor.url">
      <icon size="18" name="world"/>
    </SimpleSocialItem>
  </div>
</template>

<script>
  import SimpleSocialItem from './SimpleSocialItem.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'SimpleSocialItems',
    props: {
      actor: {
        type: Object
      },
    },
    components: {
      SimpleSocialItem,
    },
  })
</script>

<style lang="scss">
  @import "../../../scss/variables";
  @import "../../../scss/mixins/icon-color";

  .simplified-actor-social-icons {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 10px;

    @media (max-width: $screen-md) {
      justify-content: center;
      align-items: center;
    }

    .simple-social-item {
      margin-right: 0.5rem;

    &:last-child {
       margin-right: 0;
     }
    }

    .svg-icon--facebook-logo,
    .svg-icon--twitter-logo,
    .svg-icon--linkedin-logo {
      position: relative;
      top: -3px;
    }

    svg {
      path {
        fill: $color-primary !important;
      }
    }
  }
</style>
