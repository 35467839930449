<template>
  <div class="configuration-tabs-item"
       :id="'introjs-config-tab-' + item.label.replaceAll(' ', '_').replaceAll('&', 'And')" v-if="item">
    <router-link class="configuration-tabs-item--label"
                 :class="{active: this.item.path === this.$route.fullPath.split('?')[0].split('#')[0], 'is-child': indentation}"
                 :to="item.path" v-if="item.path">
      <icon v-if="item.icon" :name="item.icon" size="18"/>
      <span :class="indentation ? 'lowercase' : 'uppercase'">{{ item.label }}</span>
    </router-link>
    <div class="configuration-tabs-item--label"
         :class="{active: this.item.path === this.$route.fullPath, 'is-child': indentation}"
         @click.stop="handleLabelClick" v-else>
      <icon v-if="item.icon" :name="item.icon" size="18"/>
      <span class="uppercase">
        <span class="ellipsis" v-if="item.children">···</span>
        {{ item.label }}
      </span>
    </div>
    <div v-show="expanded || (item.label === 'Ecosystem Configuration' && !!currentIntro)">
      <template v-for="(child, index) in item.children" :key="index">
        <configuration-tabs-item :item="child" :indentation="indentation + 1" v-if="child"/>
      </template>
    </div>
  </div>
</template>

<script>
import IntroJsMixin from '../../util/IntroJsMixin.js'

export default {
  name: 'configuration-tabs-item',
  props: {
    item: Object,
    indentation: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      expanded: false,
    }
  },
  computed: {
    itemIsAlreadyOpenDuringIntro() {
      return this.currentIntro && this.expanded
    }
  },
  methods: {
    handleLabelClick() {
      if (this.item.children && !this.itemIsAlreadyOpenDuringIntro) {
        this.expanded = !this.expanded
      }
      if (this.currentIntro) {
        this.$nextTick(() => {
          window.currentIntro.refresh()
        })
      }
    },
  },
  created() {
    if (this.item && this.item.children && this.item.children.find(child => child && child.path === this.$route.fullPath.split('#')[0].split('?')[0])) {
      this.expanded = true
    }
  },
  mixins: [IntroJsMixin]
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";
@import "../../../scss/components/Navigation";

.configuration-tabs-item--label {
  padding: 8px 29px 8px 12px;
  margin: 7px 0;
  display: block;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  font-family: "Sofia Pro", "Roboto", sans-serif;
  font-size: 14px;
  fill: $navigation-link-inactive-text-color;
  color: $navigation-link-inactive-text-color;

  &.is-child {
    padding-left: 40px;
  }

  :deep(.svg-icon svg path) {
    fill: $navigation-link-inactive-text-color;
    color: $navigation-link-inactive-text-color;
    transition: none;
  }

  &:hover, &.active {
    background: $color-background-light-grey;
    color: black;

    :deep(.svg-icon svg path) {
      color: black;
      fill: black;
    }
  }

  &:hover {
    background: $color-background-lightest-grey;
  }

  > * {
    vertical-align: middle;
  }

  .ellipsis {
    position: absolute;
    right: 10px;
  }
}

.uppercase {
  text-transform: uppercase;
}

.uppercase, .lowercase {
  margin-left: 7px;
}
</style>
