<template>
  <div class="lightbox" v-if="show" @click="close">
    <div class="lightbox__close" @click="close">&times;</div>
      <div
        class="lightbox__arrow lightbox__arrow--left"
        :class="{ 'lightbox__arrow--invisible': !hasPrev }"
        @click.stop="prev"
      >
      <svg class="lightbox__svg" viewBox="0 0 24 24">
        <path class="fill-fg" d="M15.41,16.58L10.83,12L15.41,7.41L14,6L8,12L14,18L15.41,16.58Z" />
      </svg>
    </div>
    <transition :name="transition">
      <div class="lightbox__preview" v-if="images[index]" :key="images[index].id">
        <img @click.stop="next" class="lightbox__image" :src="images[index].src" v-if="images[index].mimetype.startsWith('image')">
        <div @click.stop class="lightbox__download" v-else>
          <icon name="file" />
          <p>
            {{ file.title && file.title.length && file.title !== 'File' ? file.title : file.originalName }}<br>
            {{ toDatetimeString(file.created_at) }}<br>
            {{ toAmount(file.size) }}
          </p>
          <ds-button label="Download" :href="file.url" download />
        </div>
      </div>
    </transition>
    <div
      class="lightbox__arrow lightbox__arrow--right"
      :class="{ 'lightbox__arrow--invisible': !hasNext }"
      @click.stop="next"
    >
      <svg class="lightbox__svg" viewBox="0 0 24 24">
        <path class="fill-fg" d="M8.59,16.58L13.17,12L8.59,7.41L10,6L16,12L10,18L8.59,16.58Z" />
      </svg>
    </div>
  </div>
</template>

<script>
import { toAmount } from '../../util/currency'
import { toDatetimeString } from '../../util/date'

export default {
  props: [
    'images',
    // Id of file to show
    'show',
  ],
  data () {
    return {
      // Direction of the transition
      transition: '',
    }
  },
  computed: {
    index: {
      get () {
        const image = this.images.findIndex(img => img.id === this.show)

        return this.show && image !== -1 ? image : 0
      },
      set (index) {
        this.$emit('update:show', this.images[index].id)
      }
    },
    file () {
      return this.images[this.index]
    },
    hasPrev () {
      return (this.index - 1 >= 0)
    },
    hasNext () {
      return (this.index + 1 < this.images.length)
    },
  },
  methods: {
    close () {
      this.$emit('update:show', null)
    },
    prev () {
      if (this.hasPrev) {
        this.transition = 'slide-prev'
        this.index -= 1
      }
    },
    next () {
      if (this.hasNext) {
        this.transition = 'slide-next'
        this.index += 1
      }
    },
    preload (index) {
      const image = this.images[index]
      if (image && image.mimetype.startsWith('image')) {
        setTimeout(() => {
          if (this && !this[image.src]) {
            this[image.src] = new Image()
            this[image.src].src = image.src
          }
        }, 50)
      }
    },
    keydown (e) {
      switch (e.key) {
        case 'ArrowRight':
          this.next()
          break
        case 'ArrowLeft':
          this.prev()
          break
        case 'ArrowDown':
        case 'ArrowUp':
        case ' ':
          e.preventDefault()
          break
        case 'Escape':
          this.close()
          break
      }
    },
    toAmount,
    toDatetimeString,
  },
  mounted () {
    // Move this component so that it can overlap everything
    document.body.appendChild(this.$el)
  },
  beforeUnmount () {
    window.removeEventListener('keydown', this.keydown)
  },
  watch: {
    show (val, old) {
      if (val && !old) {
        window.addEventListener('keydown', this.keydown)
      }
      if (!val && old) {
        window.removeEventListener('keydown', this.keydown)
      }
      this.preload(this.index + 1)
      this.preload(this.index - 1)
    }
  },
}
</script>

<style lang="scss">
.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, .8);
  width: 100%;
  height: 100%;
  z-index: 1000;
  user-select: none;
}

.lightbox__close {
  position: absolute;
  z-index: 4;
  top: 0;
  right: 0;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  color: #fff;
  cursor: pointer;
  transition: transform .2s;
  &:hover {
    transform: scale(1.5);
  }
}

.lightbox__arrow {
  position: absolute;
  z-index: 3;
  top: 0;
  bottom: 0;
  padding: 1rem;
  width: 20%;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: opacity .5s;
  .fill-fg {
    fill: #fff;
  }
  >.lightbox__svg {
    pointer-events: none;
    transition: transform .2s;
  }
  &:hover {
    >.lightbox__svg {
      transform: scale(2);
    }
  }
}

.lightbox__arrow--right {
  right: 0;
  justify-content: flex-end;
  background: linear-gradient(to right, transparent, rgba(0, 0, 0, .3));
}

.lightbox__arrow--left {
  left: 0;
  justify-content: flex-start;
  background: linear-gradient(to left, transparent, rgba(0, 0, 0, .3));
}

.lightbox__arrow--invisible {
  opacity: 0;
  transition: opacity .5s .2s;
  >.lightbox__svg {
    transform: scale(.2);
  }
}

.lightbox__svg {
  width: 24px;
  height: 24px;
}

.lightbox__preview {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.lightbox__image {
  width: auto;
  height: inherit !important;
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.lightbox__download {
  user-select: auto;
  text-align: center;
  .svg-icon {
    width: 40px;
    height: 40px;
  }
  .fill-fg {
    fill: white;
  }
}

@media (min-width: 768px) {
  .lightbox__preview {
    left: 4rem;
    right: 4rem;
  }

  .lightbox__arrow {
    top: 25%;
    bottom: 25%;
    background: none;
  }
}

.slide-prev-leave-active,
.slide-prev-enter-active,
.slide-next-leave-active,
.slide-next-enter-active {
  transition: .3s;
}
.slide-prev-leave-to,
.slide-next-enter-from {
  transform: translateX(100%);
}
.slide-prev-enter-from,
.slide-next-leave-to {
  transform: translateX(-100%);
}

@media (min-width: 768px) {
  .slide-prev-leave-to,
  .slide-next-enter-from {
    transform: translateX(calc(100% + 4rem));
  }
  .slide-prev-enter-from,
  .slide-next-leave-to {
    transform: translateX(calc(-100% - 4rem));
  }
}
</style>
