<template>
  <div class="card__info__social">
    <a class="laicos-item" @click="handleClickSendEmail" v-tooltip.top="company.email" v-if="company.email && showEmail" :href="'mailto:' + company.email" target="_blank"
       style="bottom: -1px;">
      <icon :size="iconSize" name="email"/>
    </a>
    <a class="laicos-item" @click="handleClickPhone" v-tooltip.top="company.phone" v-if="company.phone && showPhone" :href="'tel:' + company.phone" target="_blank"
       style="bottom: -1px;">
      <icon :size="iconSize" name="phone"/>
    </a>
    <social-item class="laicos-item" :urls="company.facebook">
      <icon size="18" :style="{marginBottom: Array.isArray(company.facebook) && company.facebook.length > 1 ? '4px' : '0px' }" name="facebook-logo"/>
    </social-item>
    <social-item :urls="company.twitter">
      <icon size="14" style="margin-bottom: -2px;" name="twitter-logo"/>
    </social-item>
    <social-item :urls="company.reddit">
      <icon :size="iconSize" name="reddit-logo"/>
    </social-item>
    <a class="laicos-item" v-if="company.linkedin" :href="company.linkedin" target="_blank">
      <icon size="18" name="linkedin-logo"/>
    </a>
    <a class="laicos-item" v-if="company.vimeo" :href="company.vimeo" target="_blank" style="bottom: -2px;">
      <icon :size="iconSize" name="vimeo-logo"/>
    </a>
    <a class="laicos-item" v-if="company.youtube" :href="company.youtube" target="_blank" style="bottom: -2px;">
      <icon :size="iconSize" name="youtube-play"/>
    </a>
    <a class="laicos-item" v-if="company.instagram" :href="company.instagram" target="_blank">
      <icon :size="iconSize" style="margin-bottom: -1px" name="instagram-logo"/>
    </a>
    <a class="laicos-item" v-if="company.kenniswest" :href="company.kenniswest" target="_blank" style="bottom: -1px;">
      <icon :size="iconSize" name="west-flanders"/>
    </a>
    <a class="laicos-item" v-if="company.crunchbase" :href="company.crunchbase" target="_blank" style="bottom: 1px;">
      <icon size="18" style="margin-bottom: -1px" name="crunchbase-logo-letters"/>
    </a>
    <a class="laicos-item" v-if="company.wikipedia" :href="company.wikipedia" target="_blank" style="bottom: 1px;">
      <icon :size="iconSize" name="wikipedia-logo"/>
    </a>
    <a class="laicos-item" v-if="company.rss_feed" :href="company.rss_feed" target="_blank" style="bottom: 1px;">
      <icon :size="iconSize" name="rss"/>
    </a>
    <a class="laicos-item" v-if="companyUrl && showWebsiteUrl" :href="companyUrl" target="_blank" style="bottom: -1px;">
      <icon :size="iconSize" name="world"/>
    </a>
    <!--<template v-if="expandOtherUrls && company.other_urls">
      <template v-for="url in company.other_urls">
        <a class="laicos-item" :href="url" :title="url" target="_blank" style="bottom: -1px;">
          <icon :size="iconSize" name="world"/>
        </a>
      </template>
    </template>-->
    <!--<template v-if="!expandOtherUrls && company.other_urls && company.other_urls.length > 0">
      <a class="laicos-item" :href="company.other_urls[0]" :title="company.other_urls[0]" target="_blank" style="bottom: -1px;">
        <icon :size="iconSize" name="world"/>
      </a>
      <button v-if="company.other_urls.length > 1" style="display: inline-flex;width: auto !important; vertical-align: top; padding: 0.5em;" type="button" class="a subtle-button"
              @click.prevent="toggleExpandOtherUrls">Show {{ company.other_urls.length - 1 }} more urls...
      </button>
    </template>-->
  </div>
</template>

<script>
  import SocialItem from './SocialItem.vue'
  import { defineComponent } from 'vue'
  import { trackMatomoEvent } from '@/util/analytics'
  import { MATOMO_EVENT_ACTIONS, MATOMO_EVENT_CATEGORIES } from '@/constants/analytics-constants'

  export default defineComponent({
    props: {
      company: Object,
      iconSize: {
        type: String,
        default: 'regular',
      },
      showWebsiteUrl: {
        type: Boolean,
        default: false,
      },
      showEmail: {
        type: Boolean,
        default: false,
      },
      showPhone: {
        type: Boolean,
        default: false,
      },
    },
    data () {
      return {
        expandOtherUrls: false
      }
    },
    components: {
      SocialItem,
    },
    computed: {
      companyUrl () {
        return this.company.url || this.company.other_urls && this.company.other_urls[0]
      }
    },
    methods: {
      toggleExpandOtherUrls () {
        this.expandOtherUrls = !this.expandOtherUrls
      },
      handleClickSendEmail () {
        trackMatomoEvent(MATOMO_EVENT_CATEGORIES.ACTOR_SEND_EMAIL, MATOMO_EVENT_ACTIONS.CLICK, this.company.id)
      },
      handleClickPhone () {
        trackMatomoEvent(MATOMO_EVENT_CATEGORIES.ACTOR_PHONE_NUMBER, MATOMO_EVENT_ACTIONS.BUTTON_CLICK, this.company.id)
      },
    },
  })
</script>
<style lang="scss" scoped>
  @import "../../../scss/variables";

  a {
    &:hover {
      :deep(.svg-icon) {
        path {
          fill: var(--primary-lighter) !important;
        }
      }
    }
  }

  .laicos-item {
    &:hover {
      cursor: pointer;

      :deep(.svg-icon) {
        path {
          fill: var(--primary-light) !important;
        }
      }
    }
  }
</style>
