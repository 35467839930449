<template>
  <div id="" v-if="data && data.groups && data.nodes">
    <div class="sankey__groups">
      <div class="sankey__group" v-for="group in data.groups" :style="groupStyle">{{ group.name }}</div>
    </div>
    <div>
      <svg id="sankey" ref="sankey"></svg>
    </div>
  </div>
</template>

<script>
import * as d3v4 from 'd3'

import d3Sankey from 'd3-sankey/src/sankey'
import { right } from 'd3-sankey/src/align'
import sankeyLinkHorizontal from 'd3-sankey/src/sankeyLinkHorizontal'

import { inert } from '../../util/helpers'

export default {
  props: { data: null },
  computed: {
    dataCopy () {
      return inert(this.data)
    },
    groupStyle () {
      return { width: 100 / ((this.dataCopy.groups.length - 1) || 1) + '%' }
    }
  },
  methods: {
    render () {
      d3v4.select('#sankey')
        .selectAll('*')
        .remove()

      this.$nextTick(this.actualRender)
    },
    actualRender () {
      if (!this.dataCopy || !this.dataCopy.nodes) {
        return
      }

      const svg = d3v4.select('#sankey')
      const width = +this.$refs.sankey.clientWidth || 960
      const height = +this.$refs.sankey.clientHeight || 600

      const formatNumber = d3v4.format(',.0f')
      const format = function (d) { return formatNumber(d) + ' actors' }
      const color = d3v4.scaleOrdinal(d3v4.schemeCategory10)

      const sankey = d3Sankey()
        .nodeAlign(right)
        .nodeWidth(10)
        .nodePadding(20)
        .extent([[1, 1], [width - 1, height - 6]])

      var link = svg.append('g')
          .attr('class', 'links')
          .attr('fill', 'none')
          .attr('stroke', '#000')
          .attr('stroke-opacity', 0.1)
        .selectAll('path')

      var node = svg.append('g')
          .attr('class', 'nodes')
          .attr('font-family', 'sans-serif')
          .attr('font-size', 10)
        .selectAll('g')

      const energy = inert(this.dataCopy)
      sankey(energy)

      link = link
        .data(energy.links)
        .enter().append('path')
          .attr('class', 'link')
          .attr('d', sankeyLinkHorizontal())
          .attr('stroke-width', function (d) { return Math.max(1, d.width) })

      link.append('title')
          .text(function (d) { return d.source.name + ' → ' + d.target.name + '\n' + format(d.value) })

      node = node
        .data(energy.nodes)
        .enter().append('g')

      node.append('rect')
          .attr('x', function (d) { return d.x0 })
          .attr('y', function (d) { return d.y0 })
          .attr('height', function (d) { return d.y1 - d.y0 })
          .attr('width', function (d) { return d.x1 - d.x0 })
          .attr('fill', function (d) { return color(d.name.replace(/ .*/, '')) })
          // .attr('stroke', '#000')

      node.append('text')
          .attr('x', function (d) { return d.x0 - 6 })
          .attr('y', function (d) { return (d.y1 + d.y0) / 2 })
          .attr('dy', '0.35em')
          .attr('text-anchor', 'end')
          .text(function (d) { return d.name })
        .filter(function (d) { return d.x0 < width / 2 })
          .attr('x', function (d) { return d.x1 + 6 })
          .attr('text-anchor', 'start')

      node.append('title')
          .text(function (d) { return d.name + '\n' + format(d.value) })
    }
  },
  async mounted () {
    this.render()
  },
  watch: {
    data () {
      this.render()
    }
  }
}
</script>

<style lang="scss">
#sankey {
  width: 100%;
  height: calc(100vh - 140px);

  .link:hover {
    stroke-opacity: .5;
  }
}
.sankey__groups {
  position: relative;
  margin-right: 1rem;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}
.sankey__group {
  float: left;
  padding: 1em 0;
  &:last-child {
    position: absolute;
    right: -1em;
    float: none;
    text-align: right;
  }
}
</style>
