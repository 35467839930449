<template>
  <div class="competition-numbers">
    <competition-number v-for="(number, index) in data" :key="index" :number="number">
    </competition-number>
  </div>
</template>
<script>

import CompetitionNumber from './CompetitionNumber.vue'

export default {
  name: 'competition-numbers-container',
  props: {
    data: {
      type: Array,
      required: true
    }
  },
  components: {
    CompetitionNumber
  }
}
</script>
