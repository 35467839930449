export default class InfiniteScroll {
  constructor (el, cb) {
    this.cb = cb
    this.el = el
    this.el.addEventListener('scroll', this.scroll.bind(this))

    this.ticking = 0
  }

  scroll () {
    this.tick()
  }

  tick () {
    if (!this.ticking) {
      this.ticking = true
      setTimeout(this.update.bind(this), 500)
    }
  }

  updated () {
    if (this.el.offsetHeight + this.el.scrollTop > this.el.scrollHeight - 1000) {
      this.cb && this.cb()
    }
    this.ticking = 0
  }

  destroy () {
    this.el.removeEventListener('scroll', this.scroll)
  }
}
