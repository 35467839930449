<template>
  <div class="agents-info-block">
    <img class="agent-avatar" src="/images/agent-avatar.svg" />
    <p>
      DataScouts provides several agents to search for related information. Use the agents below to start searching.
    </p>
  </div>
</template>

<script>
</script>

<style lang="scss">
@import "../../../../scss/variables";

.agents-info-block {
  align-items: center;
  border: 2px dashed $color-error;
  display: flex;
  margin-bottom: 10px;
  padding: 10px;
}

.agent-avatar {
  margin-right: 10px;
  width: 60px;
  max-height: 60px;
}
</style>
