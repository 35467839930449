<template>
  <article class="article-preview__container">
    <div :class="classes">
      <template v-if="image && ! video && ! link && notificationId">
        <div class="article-preview__media-container"
             :class="{'article-preview__media-container--aspect-ratio': this.aspectRatio}">
          <router-link :to="getRouterLink" class="article-preview__media"
                       :style="{ backgroundImage: 'url(' + image + ')' }">
            <div class="article-preview__tags" v-if="showTags && compact">
              <RichTag v-for="(tag, index) in tags.slice(0, numberOfVisibleTags)" :key="index" :tag="tag"
                       @click="setTagFilter(tag)"/>
              <template v-if="tags && tags.length">
                <!--<Types v-if="numberOfVisibleTags < tags.length" v-once label="..."/>-->
              </template>
            </div>
          </router-link>
        </div>
      </template>
      <template v-else-if="image && ! video && link && notificationId">
        <div class="article-preview__media-container"
             :class="{'article-preview__media-container--aspect-ratio': this.aspectRatio}">
          <router-link :to="getRouterLink" class="article-preview__media"
                       :style="{ backgroundImage: 'url(' + image + ')' }">
            <div class="article-preview__tags" v-if="showTags && compact">
              <RichTag v-for="(tag, index) in tags.slice(0, numberOfVisibleTags)" :key="index" :tag="tag"
                       @click="setTagFilter(tag)"/>
              <template v-if="tags && tags.length">
                <!--<Types v-if="numberOfVisibleTags < tags.length" v-once label="..."/>-->
              </template>
            </div>
          </router-link>
        </div>
      </template>
      <template v-else-if="image && video">
        <div class="article-preview__media-container"
             :class="{'article-preview__media-container--aspect-ratio': this.aspectRatio}">
          <a rel="noopener" target="_blank" :href="video" class="article-preview__media"
             :style="{ backgroundImage: 'url(' + image + ')' }">
            <div class="article-preview__tags" v-if="showTags && compact">
              <Tag v-for="(tag, index) in tags.slice(0, numberOfVisibleTags)" :key="index" :label="tag"
                   @click="setTagFilter(tag)"/>
              <template v-if="tags && tags.length">
                <!--<Types v-if="numberOfVisibleTags < tags.length" v-once label="..." />-->
              </template>
            </div>
            <div class="article-preview__play-icon" v-if="video">
              <Icon name="play" size="large"/>
            </div>
          </a>
        </div>
      </template>
      <div class="article-preview__content-container">
        <div v-if="isLoggedIn && notification && ! notification.user_read" class="article-preview__new-label"> New</div>
        <div v-if="canFeatureNotification && notification" class="article-preview__pin-article"
             :class="{'article-preview__pin-article-mobile' : ui.isMobile}">
          <ds-button variant="minimal-primary"
                     size="extra-small"
                     :icon="notification.featured ? 'bookmark-filled' : 'bookmark'"
                     @click="changeNotificationPinStatus(notification.featured)"/>
        </div>
        <div class="article-preview__content">
          <div class="article-preview__tags" v-if="showTags && !compact">
            <RichTag v-for="(tag, index) in tags.slice(0, numberOfVisibleTags)" :key="index" :tag="tag"
                     @click="setTagFilter(tag)"/>
            <template v-if="tags && tags.length">
              <!--<Types v-if="numberOfVisibleTags < tags.length" v-once label="..."/>-->
            </template>
          </div>
          <div class="article-preview__title-meta-container">
            <!-- <h3 v-if="notificationId" class="article-preview__title">
              <router-link :to="'/notifications/' + notificationId" @click="trackNotificationClick" v-if="notificationType != 'announcement'">{{title}}</router-link>
              <router-link :to="'/announcements/' + notificationId" @click="trackNotificationClick" v-else>{{title}}</router-link>
            </h3> -->
            <h3 class="article-preview__title">
              <template v-if="isActorLinkEnabled">
                <router-link :to="/actors/ + actorIdTitle">{{ title }}</router-link>
              </template>
              <template v-else>
                <router-link :to="getRouterLink">{{ title }}</router-link>
              </template>
            </h3>
            <template v-if="canShowMetaData">
              <p class="article-preview__meta" v-if="humanReadableDate && actorName">Posted on {{ humanReadableDate }}
                for
                <router-link :to="'/actors/' + actorId" @click="trackNotificationClick">{{ actorName }}</router-link>
              </p>
              <p class="article-preview__meta" v-else-if="showAutorData && humanReadableDate && author">Posted on
                {{ humanReadableDate }} by {{ author }}</p>
              <p class="article-preview__meta" v-else-if="!compact && humanReadableDate && author">Posted on
                {{ humanReadableDate }} by {{ author }}</p>
            </template>
          </div>
          <p v-if="canShowBody" class="article-preview__body" v-html="shortenedBody"></p>
        </div>
        <div class="article-preview__action-container">
          <div class="article-preview__actions article-preview__actions--left">
            <template v-if="type !== 'user'">
              <div :disabled="!canLikeComment" class="button button--small notification-comment__cta"
                   :class="{'liked' : notification && notification.user_like == true}" type="button"
                   @click="toggleLike()"
                   v-tooltip.right="(notification && notification.likers) ? getTooltipForLikes(notification.likers) : ''">
                <icon :name="notification && notification.user_like ? 'thumbs-up' : 'thumbs-up-outline'"/>
                <span class="button__label">&nbsp;{{
                    (notification && notification.likers) ? notification.likers.length : 0
                  }}</span>
              </div>
              <div :disabled="!canLikeComment"
                   class="button button--small notification-comment__cta notification-button__comment" type="button"
                   @click="goToAnnouncement(notificationId)">
                <icon name="comment" class="comment__icon"/>
                <span class="button__label">&nbsp;{{ commentsCount || 0 }}</span>
              </div>
            </template>
          </div>
          <div class="article-preview__actions article-preview__actions--right" v-if="canShowActionButton">
            <ds-button variant="primary" size="small" :label="actionButtonText" :to="getRouterLink"
                       @click="trackClickAction"/>
            <div v-if="type !== 'user' && canEdit" class="article-preview__action-delete">
              <ds-button size="extra-small" variant="outline" icon="edit" @click="showEditAnnouncementPanel"/>
            </div>
            <div v-if="type !== 'user' && canDelete" class="article-preview__action-delete">
              <ds-button size="extra-small" variant="outline" icon="trash" @click="displayConfirmation"/>
            </div>


          </div>
        </div>
      </div>

      <!-- Comment box -->
      <div v-if="type !== 'user' && activeCommentBoxId == notificationId && canLikeComment"
           class="notification-detail__comments-container" v-click-away="closeCommentsArea">
        <div class="notification-detail__comments-arrow"></div>
        <div class="notification-detail__comments">
          <div class="comment__row">
            <div class="notification-detail__newcomment">
              <form-group class="newcomment_form" :errors="errors.comment">
                <avatar class="newcomment__avatar" :src="this.$store.state.user.profile.photo_url"
                        :alt="this.$store.state.user.profile.name"/>
                <ds-textarea class="newcomment__input" placeholder="Your comment..." lazy v-model="newComment"/>
                <ds-button @click="addComment()" class="notification-detail__submit" size="small" variant="outline"
                           label="Submit"/>
              </form-group>
            </div>
          </div>
          <div class="notification-detail__divider"></div>
          <div class="notification-detail__comment" v-for="comment in comments">
            <div class="comment__title">
              <avatar :src="comment.user.photo_url" :alt="comment.user.name"/>
              <span class="comment__user">{{ comment.user.name }}&nbsp;&nbsp;<time
                  class="comment__time">{{ timeAgo(comment.created_at) }}</time></span>
              <div class="comment__cta" v-if="editable(comment)">
                <ds-button @click="deleteComment(comment)" class="button--minimal button--default" icon="trash"/>
              </div>
            </div>
            <div class="comment__body">
              {{ comment.comment }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script lang="ts">
import moment from 'moment'

import Tag from '../Tag/Tag.vue'
import RichTag from '../Tag/RichTag.vue'
import DsTextarea from '../Form/DsTextarea.vue'
import Avatar from '../Avatar/Avatar.vue'

import { timeAgo, toDateString } from '../../util/date.js'

import UiMixin from '../../util/UiMixin.js'

import {
  createComment,
  deleteComment,
  featureNotification,
  getComments,
  likeNotification,
  Notifications,
  unfeatureNotification
} from '../../api/notifications.js'

import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters.js'

import MODAL_IDS from '../../constants/modal-ids.js'
import { trackHeapEvent } from '../../util/analytics.js'
import { getTooltipForLikes } from '../../util/helpers.js'
import { defineComponent } from 'vue'

// The body without html tags
const BODY_MAX_LENGTH = 250

export default defineComponent({
  name: 'ArticlePreview',
  props: {
    image: String,
    video: String,
    title: String,
    notificationId: String,
    createdAt: String,
    author: String,
    body: String,
    actionText: String,
    actionUrl: String,
    articleLink: String,
    type: String,
    aspectRatio: {
      type: Boolean,
      default: false,
    },
    actorIdTitle: '',
    actorTitle: {
      type: Boolean,
      default: false,
    },
    canDelete: {
      type: Boolean,
      default: false,
    },
    canEdit: {
      type: Boolean,
      default: false,
    },
    notification: {
      type: Object,
      default: () => {
      }
    },
    tags: {
      type: Array,
      default: () => []
    },
    compact: {
      type: Boolean,
      default: false,
    },
    showAutorData: {
      type: Boolean,
      default: false,
    },
    showTags: {
      type: Boolean,
      default: true
    },
    canLikeComment: {
      type: Boolean,
      default: false,
    },
    canFeatureNotification: {
      type: Boolean,
      default: false,
    },
    canShowActionButton: {
      type: Boolean,
      default: true,
    },
    actionButtonText: {
      type: String,
      default: 'Read more'
    },
    canShowMetaData: {
      type: Boolean,
      default: true,
    },
    canShowBody: {
      type: Boolean,
      default: true,
    },
    notificationType: ''
  },
  data() {
    return {
      errors: {},
      numberOfVisibleTags: 5,
      activeCommentBoxId: '',
      newComment: '',
      comments: [],
      commentsCount: 0,
    }
  },
  computed: {
    isOwner() {
      return this.$store.getters.isOwner
    },
    isEcosystemMember() {
      return this.$store.getters.isActor
    },
    humanReadableDate() {
      return moment(this.createdAt).format('MMMM Do YYYY')
    },
    actorName() {
      return this.notification && this.notification.actor_name
    },
    actorId() {
      return this.notification && this.notification.actor_id
    },
    getRouterLink() {
      if (this.articleLink) {
        return this.articleLink
      }

      return '/announcements/' + this.notificationId
    },
    link() {
      // NOTE: this needs to be refactored, there's logic here that defines the link of the title, but the link of the title should be passed instead of computed in this component
      // this makes it so that we don't need actorIdTitle for example

      // This is the link that should be used when clicking on the image or title of the article preview
      // If there's a notification/announcement, link to the announcement, except for when we're on the announcements page itself!
      var link = this.actionUrl

      if (this.notificationId && this.notificationType == 'announcement' && this.$route.name != 'announcements' && this.$route.name != 'announcements-detail') {
        link = window.location.protocol + '//' + window.location.hostname + '/announcements/' + this.notificationId
      } else if (this.notificationId && this.notificationType != 'announcement') {
        link = window.location.protocol + '//' + window.location.hostname + '/notifications/' + this.notificationId
      } else if (this.video) {
        link = this.video
      }

      return link
    },
    internalLink() {
      // Return the relative path of the link so the router-link can use it
      if (!this.link) {
        return
      }

      return this.link.replace(window.location.protocol + '//' + window.location.hostname, '')
    },
    classes() {
      return [
        'article-preview',
        this.compact && 'article-preview--compact',
        !this.image && 'article-preview--no-image',
      ].join(' ')
    },
    shortenedBody() {
      if (!this.body) {
        return
      }

      // Get the length of the text without the HTML content <p ....>text to count</p>
      var strippedHtml = this.body.replace(/<(?:.|\n)*?>/gm, '')

      if (strippedHtml.length <= BODY_MAX_LENGTH) {
        return this.body
      }

      return this.body.slice(0, BODY_MAX_LENGTH) + '...'
    },
    isLoggedIn() {
      return this.$store.getters.isLoggedIn
    },
    isActorLinkEnabled() {
      return this.actorTitle && this.actorIdTitle
    },
    isLinkInternal() {
      return this.link && this.link.includes(window.location.hostname)
    },
    isLinkExternal() {
      return this.link && !this.link.includes(window.location.hostname)
    }
  },
  methods: {
    timeAgo,
    toDateString,
    getTooltipForLikes,
    editable(comment) {
      return comment.user.id == this.$store.state.user.profile.id
    },
    trackClickAction() {
      trackHeapEvent('global.articlePreview.clickReadMoreButton')
    },
    toggleLike() {
      if (!this.isLoggedIn) {
        this.showLoginModal()
        return
      }

      var like = this.notification && !this.notification.user_like

      likeNotification(this.notificationId, like)
          .then(() => {
            this.$bus.emit('announcementUpdated', {
              id: this.notificationId,
              filteredTypes: ['liked'],
            })
          })
          .catch(err => {
            // log the error to sentry
            console.log(err, 'like error')
          })
    },
    setTagFilter(tag) {
      trackHeapEvent('global.articlePreview.clickTag')
      var tagFilter = { facet: 'tags', value: tag.value, label: tag.label }

      this.$emit('clickedTag', tagFilter)
      this.$store.commit(FILTERS_MUTATION_TYPES.ADD_KEYWORD, tagFilter)
    },
    trackNotificationClick() {
      trackHeapEvent('global.articlePreview.clickNotificationTitle')
    },
    showLoginModal() {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.LOGIN)
    },
    // Deprecated - all comment related modals and operations shouldn't be in the preview anymore
    openCommentsArea(notificationID) {
      if (!this.isLoggedIn) {
        this.showLoginModal()
        return
      }
      this.activeCommentBoxId = notificationID
    },
    closeCommentsArea() {
      this.activeCommentBoxId = ''
    },
    addComment() {
      createComment(this.notificationId, this.newComment)
          .then(() => {
            this.newComment = ''
            this.fetchComments()
          })
          .catch(errors => {
            this.errors = errors
          })
    },
    deleteComment(comment) {
      deleteComment(this.notificationId, comment.id)
          .then(() => {
            this.fetchComments()
          })
    },
    fetchComments() {
      if (!this.canLikeComment) {
        return
      }

      if (this.notificationId) {
        getComments(this.notificationId)
            .then(data => {
              this.comments = data.comments
              this.commentsCount = data.count
            })
            .catch(err => {
              // log the error to sentry
            })
      }
    },
    goToAnnouncement(announcementId) {
      this.$router.push('/announcements/' + announcementId)
    },
    changeNotificationPinStatus(isPinned) {
      if (isPinned) {
        unfeatureNotification(this.notificationId)
            .then(data => {
              this.$emit('featureStatusChanged')
            })
            .catch(err => {
              // log the error to sentry
            })
      } else {
        featureNotification(this.notificationId)
            .then(data => {
              this.$emit('featureStatusChanged')
            })
            .catch(err => {
              // log the error to sentry
            })
      }
    },
    displayConfirmation() {
      this.$store.commit('UI/SET_MODAL_CONTEXT', { modalContextType: 'message', notificationId: this.notificationId })
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.DELETE_CONFIRMATION)
    },
    deleteAnnouncement() {
      Notifications.delete(this.notificationId)
          .then(() => {
            this.$bus.emit('fetchNewAnnouncements')
          })
    },
    showEditAnnouncementPanel() {
      trackHeapEvent('global.articlePreview.editAnnouncement')
      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { 'component': 'edit-announcement-panel', metaData: this.notification })
    }
  },
  mounted() {
    this.$bus.on('resize', () => {
      /* if (this.$root.$el.clientWidth > 1200) {
        this.numberOfVisibleTags = 2
      }
      if (this.$root.$el.clientWidth <= 1200) {
        this.numberOfVisibleTags = 5
      } */
    })

    this.$bus.on('messageDeleteConfirmation', (context) => {
      if (context.notificationId && context.notificationId == this.notificationId) {
        this.deleteAnnouncement()
      }
    })

    /* if (this.$root.$el.clientWidth > 1200) {
      this.numberOfVisibleTags = 2
    }
    if (this.$root.$el.clientWidth <= 1200) {
      this.numberOfVisibleTags = 5
    } */

    if (this.isLoggedIn) {
      this.fetchComments()
    }
  },
  beforeUnmount() {
    this.$bus.off('messageDeleteConfirmation')
  },
  mixins: [UiMixin],
  components: {
    Tag,
    RichTag,
    DsTextarea,
    Avatar
  },
})
</script>

<style lang="scss">
@import "../../../scss/variables";
@import "../../../scss/mixins/icon-color";

.article-preview__container {
  margin-bottom: 1em;
  position: relative;

  .notification-detail__comments-container {
    .notification-detail__comments-arrow {
      left: unset;
      right: 6.5em;
    }
  }
}

.article-preview {
  display: flex;
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.12);
  position: relative;
  height: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  .article-preview__media-container {
    border: 1px solid $color-borders;
    border-right: none;
  }

  @media (max-width: $screen-md) {
    display: block;

    .article-preview__media-container {
      display: block;
      width: 100%;
      height: 100vh;
      max-height: 130px;
      max-width: none;
    }

    .article-preview__content-container {
      border-top: 0;
      border-left: 1px solid $color-body-borders;
    }
  }
}

.article-preview__new-label {
  color: white;
  text-transform: uppercase;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.5px;
  line-height: 17px;
  font-family: $font-stack-primary;
  background-color: $color-alternative-three;
  display: inline-flex;
  padding: 2px 5px;
  position: absolute;
  left: 2em;
  top: -5px;
}

.article-preview__pin-article-mobile {
  margin-left: unset;
  right: 1em;
}

.article-preview__pin-article {
  position: absolute;
  margin-left: -4em;

  .button {
    background-color: rgba(0, 0, 0, 0) !important;

    .svg-icon {
      path {
        fill: $color-primary
      }
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0) !important;

      .svg-icon {
        path {
          fill: $color-primary
        }
      }
    }
  }
}

.article-preview__content {
  overflow: hidden;
  width: 100%;
}

@media (min-width: $screen-xl) {
  .article-preview__content {
    margin-bottom: .5rem;
  }
}

@media (max-width: $screen-xl) {
  .article-preview__content-container {
    flex: 3;
  }
}

@media (max-width: $screen-md) {
  .article-preview__media-container {
    max-width: unset;
  }
}

.article-preview__content {
  flex-grow: 1
}

.article-preview--no-image {
  .article-preview__content-container {
    border-left: 1px solid $color-body-borders;
  }
}

.article-preview--compact {
  .article-preview__title {
    font-size: 1rem;
    text-align: left;
  }

  .article-preview__tags {
    position: absolute;
    bottom: 0.75rem;
    left: 0.75rem;
    right: 0.75rem;
    margin-bottom: 0;

    .tag {
      color: $color-text-grey;
      cursor: pointer;
    }
  }
}

.article-preview__media-container {
  position: relative;
  flex: 4;

  max-width: 40%;
}

.article-preview__media-container--aspect-ratio {
  padding-bottom: 22.5%;
}

.article-preview__media {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center 25%;

  &:hover {
    cursor: pointer;

    .article-preview__play-icon {
      opacity: 1;
    }
  }
}

.article-preview__play-icon {
  @include iconColor(#fff);
  position: absolute;
  top: 50%;
  left: 50%;
  opacity: 0.7;
  transform: translate(-50%, -50%);
  transition: all .2s;
}

.article-preview__content-container {
  border: 1px solid $color-body-borders;
  border-left: none;
  padding: 0.8rem !important;
  padding-left: 1rem !important;
  display: flex !important;
  flex-flow: column;
  flex: 1;
}

.article-preview__tags {
  margin-bottom: .5rem;

  .tag {
    color: $color-text-grey;
    cursor: pointer;
  }
}

.article-preview__title {
  font-size: 14px;
  margin-bottom: 5px;
  letter-spacing: 1px;
  font-weight: 500;
  letter-spacing: 1.39px;
  line-height: 16px;
  font-family: $font-stack-primary;
  text-transform: uppercase;
  text-align: left;

  a {
    text-decoration: none;
    color: $color-text-grey;
  }

  &:hover {
    a {
      color: $color-primary;
    }
  }
}

.article-preview__action-container {
  display: flex;
  margin-top: 5px;

  .article-preview__action-delete {
    justify-content: center;
    align-items: center;
    align-self: center;

    .button {
      width: 25px;
      height: 25px;

      &:hover {
        background-color: $color-background-light-grey;

        .svg-icon {
          path {
            fill: $color-background-grey;
          }
        }
      }
    }
  }

  .comment__row {
    display: flex;
    flex: 1;
    align-items: flex-end;
    align-self: center;
    min-width: 110px;
  }

  .article-preview__actions {
    min-height: 40px;
    flex: 1;
    display: flex;
    align-items: center;

    .button--primary {
      background-color: $color-primary-darker;

      &:hover {
        background-color: $color-primary;
      }
    }
  }

  .article-preview__actions--left {
    text-align: left;
    justify-content: flex-start;
  }

  .article-preview__actions--right {
    text-align: right;
    justify-content: flex-end;
    margin-left: 20px;
    margin-top: -6px;
  }
}

.article-preview__meta {
  font-size: 12px;
  color: $color-text-grey-light;
  margin-bottom: 0.8rem !important;
}

.article-preview__body {
  word-break: break-word;
  flex: 1;

  img {
    display: none;
  }

  ul, ol {
    padding-left: 1rem;
  }

  p {
    margin-bottom: 2px;
    font-size: 14px;
  }

  margin-bottom: 0px;
  font-size: 11px;
  font-weight: 100;
  line-height: 16px;
  font-family: $font-stack-primary;
  text-align: left;
  color: $color-text-grey;
  /*text-overflow: ellipsis;*/
  overflow: hidden;
  /*white-space: nowrap;*/
  max-height: 100px;
}
</style>
