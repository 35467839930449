import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../store/modules/filters'
import { twoLevelTaxonomiesToOptions } from './hierarchy-taxonomy'
import ConfigMixin from './ConfigMixin'
import { getAllReportFields, getAllReportTemplates, getReportFieldById } from './helpers'
import { SiteTemplate } from '../store/modules/config.js'

const WHITELISTED_FILTERS = [
  'category',
  'membership',
  'stage',
  'activities',
  'industries',
  'domains',
  'technology',
  'maturity',
  'type',
]

export default {
  computed: {
    productCategoryOptions () {
      if (this.categoryValuesAndLabelsPerType && this.categoryValuesAndLabelsPerType.Product) {
        return this.categoryValuesAndLabelsPerType.Product
      }
      return this.$store.getters.categoryValuesAndLabels
    },
    legalEntityAndCommunityCategoryOptions () {
      return [...this.categoryValuesAndLabelsPerType.LegalEntity, ...this.categoryValuesAndLabelsPerType.Community]
    },
    nonProductCategoryOptions () {
      if (this.categoryValuesAndLabelsPerType && this.categoryValuesAndLabelsPerType.LegalEntity && this.categoryValuesAndLabelsPerType.Community && this.categoryValuesAndLabelsPerType.Person) {
        return this.categoryValuesAndLabelsPerType.LegalEntity
          .concat(this.categoryValuesAndLabelsPerType.Community)
          .concat(this.categoryValuesAndLabelsPerType.Person)
      }
      return this.$store.getters.categoryValuesAndLabels
    },
    categoryOptions () {
      return this.$store.getters.categoryValuesAndLabels
    },
    employeeSizeOptions () {
      return ['XS', 'S', 'M', 'L', 'XL', 'XXL']
    },
    exitRoundOptions () {
      return ['acquisition', 'IPO', 'merger']
    },
    stageOptions () {
      return this.stageValuesAndLabels
    },
    membershipOptions () {
      return this.membershipValuesAndLabels
    },
    activityOptions () {
      return this.activityValuesAndLabels
    },
    departmentOptions () {
      return [
        { label: 'Business Administration', value: 'business administration' },
        { label: 'Marketing & Sales', value: 'marketing & sales' },
        { label: 'Finance', value: 'finance' },
        { label: 'Human Resources', value: 'human resources' },
        { label: 'Technology', value: 'technology' },
        { label: 'Research & Development', value: 'research & development' },
        { label: 'Operations', value: 'operations' },
        { label: 'Innovation', value: 'innovation' },
        { label: 'Strategy', value: 'strategy' },
      ]
    },
    functionOptions () {
      return [
        { label: 'Executive', value: 'executive' },
        { label: 'Director', value: 'director' },
        { label: 'Manager', value: 'manager' },
        { label: 'Junior', value: 'junior' },
        { label: 'Senior', value: 'senior' },
      ]
    },
    expertiseOptions () {
      return this.expertiseValuesAndLabels
    },
    motivationOptions () {
      return this.motivationValuesAndLabels
    },
    businessAspectOptions () {
      return this.businessAspectValuesAndLabels
    },
    processStepOptions () {
      return this.processStepValuesAndLabels
    },
    announcementCategoryOptions () {
      return this.announcementCategoryValuesAndLabels
    },
    eventCategoryOptions () {
      return this.eventCategoryValuesAndLabels
    },
    readinessLevelOptions () {
      return this.readinessLevelValuesAndLabels
    },
    // Note the code below could use a refactor, it's always the same code
    domainsOptions () {
      const domains = twoLevelTaxonomiesToOptions(this.$store.state.taxonomies.domains, 'domains')

      domains.map(k => k.children).map(f => {
        if (f.length > 0) {
          f.shift()
        }
      })

      return domains
    },
    domainsChildrenOptions () {
      const domains = this.$store.state.taxonomies.domains

      var children = []

      domains.forEach((domain) => {
        if (!domain.domains) {
          return
        }

        domain.domains.forEach((child) => {
          children.push(child)
        })
      })

      return children
    },
    productFeaturesAOptions () {
      const productFeaturesA = twoLevelTaxonomiesToOptions(this.$store.state.taxonomies.productFeaturesA, 'children')

      productFeaturesA.map(k => k.children).map(f => {
        if (f.length > 0) {
          f.shift()
        }
      })

      return productFeaturesA
    },
    productFeaturesBOptions () {
      const productFeaturesB = twoLevelTaxonomiesToOptions(this.$store.state.taxonomies.productFeaturesB, 'children')

      productFeaturesB.map(k => k.children).map(f => {
        if (f.length > 0) {
          f.shift()
        }
      })

      return productFeaturesB
    },
    productFeaturesCOptions () {
      const productFeaturesC = twoLevelTaxonomiesToOptions(this.$store.state.taxonomies.productFeaturesC, 'children')

      productFeaturesC.map(k => k.children).map(f => {
        if (f.length > 0) {
          f.shift()
        }
      })

      return productFeaturesC
    },
    productFeaturesAChildrenOptions () {
      const productFeaturesA = this.$store.state.taxonomies.productFeaturesA

      var children = []

      productFeaturesA.forEach((productFeature) => {
        if (!productFeature.children) {
          return
        }

        productFeature.children.forEach((child) => {
          children.push(child)
        })
      })

      return children
    },
    productFeaturesBChildrenOptions () {
      const productFeaturesB = twoLevelTaxonomiesToOptions(this.$store.state.taxonomies.productFeaturesB, 'children')

      var children = []

      productFeaturesB.forEach((productFeature) => {
        if (!productFeature.children) {
          return
        }

        productFeature.children.forEach((child) => {
          children.push(child)
        })
      })

      return children
    },
    productFeaturesCChildrenOptions () {
      const productFeaturesC = twoLevelTaxonomiesToOptions(this.$store.state.taxonomies.productFeaturesC, 'children')

      var children = []

      productFeaturesC.forEach((productFeature) => {
        if (!productFeature.children) {
          return
        }

        productFeature.children.forEach((child) => {
          children.push(child)
        })
      })

      return children
    },
    industriesOptions () {
      var industries = twoLevelTaxonomiesToOptions(this.$store.state.taxonomies.industries, 'industries')

      industries.map(k => k.children).map(f => {
        if (f.length > 0) {
          f.shift()
        }
      })

      return industries
    },
    industriesChildrenOptions () {
      const industries = this.$store.state.taxonomies.industries

      var children = []

      industries.forEach((industry) => {
        if (!industry.industries) {
          return
        }

        industry.industries.forEach((child) => {
          children.push(child)
        })
      })

      return children
    },
    allIndustriesOptions () {
      return this.$store.state.taxonomies.industries
    },
    aspectOptions () {
      var aspects = this.$store.state.taxonomies.aspects || []

      return aspects.map(i => i.name)
    },
    allAspectOptions () {
      return this.$store.state.taxonomies.aspects
    },
    technologyOptions () {
      const technologies = twoLevelTaxonomiesToOptions(this.$store.state.taxonomies.technologies, 'children')

      technologies.map(k => k.children).map(f => {
        if (f.length > 0) {
          f.shift()
        }
      })

      return technologies
    },
    technologyChildrenOptions () {
      const technologies = this.$store.state.taxonomies.technologies

      var children = []

      technologies.forEach((technology) => {
        if (!technology.children) {
          return
        }

        technology.children.forEach((child) => {
          children.push(child)
        })
      })

      return children
    },
    funnelStageOptions () {
      return this.funnelStageValuesAndLabels
    },
    businessTypeOptions () {
      return ['B2B', 'B2C']
    },
    possibleDoubleOptions () {
      return [true, false]
    },
    maturityOptions () {
      return [
        'formation',
        'validation',
        'growth',
        'mature',
        'acquired',
        'dead',
      ]
    },
    isSimpilfiedUser () {
      return window.config.siteTemplate && [SiteTemplate.NEW_SIMPLIFIED, SiteTemplate.SIMPLIFIED].includes(window.config.siteTemplate)
    },
    isProductsPage () {
      return this.$route.name && this.$route.name.indexOf('product') >= 0
    },
    isSpottingAreaDetailPage () {
      return this.$route.name && this.$route.name.indexOf('spotting-area-detail') >= 0
    },
    enabled () {
      const filterSettingToUse = this.isProductsPage ? this.$store.state.config.filterControlsProducts : this.$store.state.config.filterControls
      if (this.$store.getters.isWear && this.$route.query.actor_type === '') {
        return filterSettingToUse.filter(f => !['founding_date'].includes(f))
      }

      return filterSettingToUse || []
    },
    visibleFilterControls () {
      return this.visibleFilters
        .filter(filter => WHITELISTED_FILTERS.includes(filter))
    },
    visibleFilters () {
      var visibleFilters = []

      var excludedFilters = ['portfolio', 'company_type', 'score']

      for (var i = 0; i < this.enabled.length; i++) {
        if (this.isFilterVisible(this.enabled[i]) && !excludedFilters.includes(this.enabled[i])) {
          visibleFilters.push(this.enabled[i])
        }
      }

      if (this.$route.name && this.$route.name.indexOf('simplified') >= 0 && !this.isMember) {
        visibleFilters = visibleFilters.filter(filter => {
          //const filterControls = this.isProductsPage ? this.$store.state.config.simplifiedFilterControlsProducts : this.$store.state.config.simplifiedFilterControls
          return filter !== 'sustainability_goal' //&& !filterControls.includes(filter)
        })
      }
      visibleFilters = visibleFilters.filter(filter => filter)

      // Filter out "reports" if there a none to be used as filter
      if ((!this.filterableReportTemplates || this.filterableReportTemplates.length === 0) && visibleFilters.includes('reports')) {
        visibleFilters = visibleFilters.filter(visibleFilter => visibleFilter !== 'reports')
      }

      return visibleFilters
    },
    filterableReportFields () {
      var reportFields = getAllReportFields()

      if (this.isProductsPage) {
        reportFields = getAllReportFields(true)
      }

      var filterableTypes = ['options', 'number', 'date', 'score', 'checkbox', 'multi_select']

      return reportFields.filter(f => filterableTypes.includes(f.type) && f.can_be_used_as_filter)
    },
    filterableReportTemplates () {
      var filterableReportTemplates = []
      var filterableTypes = ['options', 'number', 'date', 'score', 'checkbox', 'multi_select']

      var reportTemplates = getAllReportTemplates()

      if (this.isProductsPage) {
        reportTemplates = reportTemplates.filter(template => template.enabled_for_product)
      } else if (this.isSimpilfiedUser) {
        reportTemplates = reportTemplates.filter(template => template.enabled_for_legal_entity || template.enabled_for_person || template.enabled_for_community)
      }

      // Filter out all report templates that are not accessible to visitors/ecosystem members based on the viewable report types
      if (!this.$store.getters.isLoggedIn || this.$store.getters.isActor) {
        var viewReportOfActorTypes = this.$store.state.config.viewReportOfActorTypes || []
        viewReportOfActorTypes = viewReportOfActorTypes.map(actorType => {
          actorType = actorType.toLowerCase()

          if (actorType === 'legalentity') {
            actorType = 'legal_entity'
          }

          return 'enabled_for_' + actorType
        })

        reportTemplates = reportTemplates.filter(template => {
          if (viewReportOfActorTypes.length === 0) {
            return false
          }

          for (var index = 0; index < viewReportOfActorTypes.length; index++) {
            if (template[viewReportOfActorTypes[index]]) {
              return true
            }
          }

          return false
        })
      }

      reportTemplates.forEach(function (reportTemplate) {
        var filterableReportTemplate = { ...reportTemplate }
        var filterableReportFields = reportTemplate.reportFields.filter(f => filterableTypes.includes(f.type) && f.can_be_used_as_filter)

        if (filterableReportFields.length > 0) {
          filterableReportTemplate.filterableReportFields = filterableReportFields
          filterableReportTemplates.push(filterableReportTemplate)
        }
      })

      return filterableReportTemplates
    },
    legendItems () {
      return this.getLegendItems(this.legendProperty)
    },
    legendProperty () {
      return this.$store.state.filters.legendProperty
    },
    legendPropertyDisplayName () {
      return this.getTaxonomyAlias(this.legendProperty)
    },
    availableLegendColors () {
      // The hex property has been deprecated
      // return this.$store.state.config.hex
      return Object.values(this.$store.state.config.hexColours)
    },
    allLegendProperties () {
      return [
        'membership',
        'stage',
        'category',
        'activities',
        'domains',
        'product_features_a',
        'product_features_b',
        'product_features_c',
        'industries',
      ]
    },
    legendMapping () {
      return this.$store.state.config.legendMapping
    },
    hexColours () {
      return this.$store.getters.hexColours
    },
    ecosystemRelationships () {
      return this.$store.getters.fullActorRelationships
    },
  },
  methods: {
    humanizeFacet (f, keywords) {
      if (f == 'possible_duplicate') {
        return 'Possible duplicates'
      }

      if (f == 'is_zombie') {
        return 'Zombies'
      }

      switch (f) {
        case 'admin1_name':
          return 'State/Province'
        case 'admin2_name':
          return 'Region'
        case 'hq_and_local_offices_locality':
          return 'Locality (HQs / Local offices)'
        case 'hq_and_local_offices_country_code':
          return 'Country (HQs / Local offices)'
        case 'geoid':
          return 'city'
        case 'tags':
          return 'tag'
        case 'description_annotation_tags':
          return 'annotation tags'
        case 'address.locality':
          return 'locality'
        case 'address.country':
          return 'country'
        case 'custom_filters':
          return 'custom area'
        case 'funnel_stage':
          return 'CRM stage'
        case String(f.match(/^report_field_.*/)):
          // Fetch the report field label
          var reportFieldId = f.replace('report_field_', '')

          reportFieldId = reportFieldId.replace('_min', '')
          reportFieldId = reportFieldId.replace('_max', '')

          var reportField = getReportFieldById(reportFieldId)

          if (!reportField) {
            return 'report'
          }

          return reportField.label
      }

      // Relationship values
      const relationshipMapping = {}
      this.ecosystemRelationships.forEach(function (relationship) {
        relationshipMapping[relationship.name] = relationship.label
      })

      if (relationshipMapping[f]) {
        return relationshipMapping[f]
      }

      // Taxonomy aliases
      // TODO: this should follow the same thing as the humanize() helper function
      if (['activities', 'technology', 'domains', 'industries', 'subindustries', 'expertises', 'product_features_a', 'product_features_b', 'product_features_c'].includes(f)) {
        var taxonomyAlias = f + '_alias'

        if (window.config[taxonomyAlias] && window.config[taxonomyAlias].length > 0) {
          var alias = window.config[taxonomyAlias]

          f = alias
        }
      }

      return f.replace(/[._]/g, ' ')
    },
    getLegendItems (legendProperty) {
      switch (legendProperty) {
        case 'membership':
          return this.$store.getters.membershipValuesAndLabels
        case 'stage':
          return this.$store.getters.stageValuesAndLabels
        case 'category':
          return this.$store.getters.categoryValuesAndLabels
        case 'activities':
          return this.$store.getters.activityValuesAndLabels
        case 'domains':
          return this.$store.getters.domainValuesAndLabels
        case 'product_features_a':
          return this.$store.getters.productFeatureAValuesAndLabels
        case 'product_features_b':
          return this.$store.getters.productFeatureBValuesAndLabels
        case 'product_features_c':
          return this.$store.getters.productFeatureCValuesAndLabels
        case 'industries':
          return this.$store.getters.industryValuesAndLabels
      }

      return []
    },
    getAvailableLegendItems (legendProperty) {
      switch (legendProperty) {
        case 'membership':
          return this.$store.getters.memberships
        case 'stage':
          return this.$store.getters.stages
        case 'category':
          return this.$store.getters.categories
        case 'activities':
          return this.$store.getters.activities
        case 'domains':
          return this.$store.getters.domains
        case 'product_features_a':
          return this.$store.getters.productFeatureA
        case 'product_features_b':
          return this.$store.getters.productFeatureB
        case 'product_features_c':
          return this.$store.getters.productFeatureC
        case 'industries':
          return this.$store.getters.industries
      }

      return []
    },
    isFilterVisible (filterName) {
      return ![
          'technology',
          'industries',
          'membership',
          'stage',
          'activities',
          'domains',
          'product_features_a',
          'product_features_b',
          'product_features_c',
          'category',
        ].includes(filterName) ||
        (filterName === 'technology' && this.technologyOptions && this.technologyOptions.length > 0) ||
        (filterName === 'industries' && this.industriesOptions && this.industriesOptions.length > 0) ||
        (filterName === 'membership' && this.membershipOptions && this.membershipOptions.length > 0) ||// && (this.$store.getters.isMember ? this.membershipOptions.length > 1 : this.membershipOptions.length > 0))
        (filterName === 'stage' && this.stageOptions && this.stageOptions.length > 0) ||// && (this.$store.getters.isMember ? this.stageOptions.length > 1 : this.stageOptions.length > 0))
        (filterName === 'activities' && this.activityOptions && this.activityOptions.length > 0) ||// && (this.$store.getters.isMember ? this.activityOptions.length > 1 : this.activityOptions.length > 0))
        (filterName === 'domains' && this.domainsOptions && this.domainsOptions.length > 0) ||// && (this.$store.getters.isMember ? this.domainsOptions.length > 1 : this.domainsOptions.length > 0))
        (filterName === 'product_features_a' && this.productFeaturesAOptions && this.productFeaturesAOptions.length > 0) ||
        (filterName === 'product_features_b' && this.productFeaturesBOptions && this.productFeaturesBOptions.length > 0) ||
        (filterName === 'product_features_c' && this.productFeaturesCOptions && this.productFeaturesCOptions.length > 0) ||
        ((filterName === 'category' || filterName === 'legend') && this.categoryOptions && this.categoryOptions.length > 0)// && (this.$store.getters.isMember ? this.categoryOptions.length > 1 : this.categoryOptions.length > 0))
    },
    getLegendValue (actor) {
      var legendValue = actor.legend

      if (this.$store.state.filters.legendProperty && actor[this.$store.state.filters.legendProperty]) {
        legendValue = actor[this.$store.state.filters.legendProperty]
      }

      // If the legend value is an array, then use the first item to use as the legend value
      if (Array.isArray(legendValue)) {
        legendValue = legendValue[0]
      }

      if (!legendValue) {
        return ''
      }

      if (legendValue.value) {
        return legendValue.value
      }

      return legendValue
    },
    legendColorForActor (actor) {
      var legendValue = this.getLegendValue(actor)

      var ribbonClass = legendValue && this.legendMapping[this.legendProperty] && this.legendMapping[this.legendProperty][legendValue] && this.hexColours[this.legendMapping[this.legendProperty][legendValue]] !== undefined

      if (ribbonClass) {
        return this.hexColours[this.legendMapping[this.legendProperty][legendValue]]
      }

      return '#bbb'
    },
    legendColor (legendItem) {
      var colorConfig = this.$store.state.filters.legendLookup

      if (!legendItem || !legendItem.value) {
        return '#bbbbbb'
      }

      if (colorConfig[legendItem.value]) {
        return colorConfig[legendItem.value].hex
      }

      return '#bbbbbb'
    },
    orderBySimplifiedFilterControls (facetCounts, availableFilterControls) {
      if (!facetCounts || !availableFilterControls) {
        return []
      }

      facetCounts = Object
        .keys(facetCounts).map((key, index) => {
          return { name: key, count: facetCounts[key] }
        })
        .sort((facetA, facetB) => {
          const startsWithReportFieldA = facetA.name.startsWith('report_field')
          const startsWithReportFieldB = facetB.name.startsWith('report_field')

          // If only one of the names starts with "report_field", move it to the back
          if (startsWithReportFieldA && !startsWithReportFieldB) {
            return 1 // Move facetA to the back
          }

          if (!startsWithReportFieldA && startsWithReportFieldB) {
            return -1 // Move facetB to the back
          }

          return availableFilterControls.indexOf(facetA.name) - availableFilterControls.indexOf(facetB.name)
        })

      // Extract from Product Features the children and list them as separate facets
      var result = []

      for (let index = 0; index < facetCounts.length; index++) {
        var facetCount = facetCounts[index]

        if (!['product_features_a', 'product_features_b', 'product_features_c', 'technology'].includes(facetCount.name)) {
          result.push(facetCount)

          continue
        }

        var parentFacets = facetCount.count.filter(f => !f.parent_id)
        var parentIds = parentFacets.map(f => f.value) || []

        var childFacets = []
        var childHasAtLeastOneValue = false

        parentIds.forEach((parentId) => {
          var subFacet = {}
          subFacet.name = facetCount.name
          subFacet.count = facetCount.count.filter(f => f.parent_id == parentId)
          subFacet.parent_id = parentId
          subFacet.parent_name = parentFacets.find(f => f.value == parentId).label

          if (subFacet.count.length > 0) {
            childHasAtLeastOneValue = true
          }

          childFacets.push(subFacet)
        })

        // If no children exist, then push the 1st level facets
        if (!childHasAtLeastOneValue) {
          // Add the parents as a main layer of filters
          result.push({ ...facetCount, count: parentFacets })
        }

        childFacets
          .sort((a, b) => a.parent_name > b.parent_name)
          .forEach((childFacet) => {
            result.push(childFacet)
          })
      }

      return result
    },
    flattenAndLimitFacet (result, limit = 6) {
      const limitedResults = []

      // Map the result to a flattened array result
      result.map(facet => {
        facet['count'].map(facetItem => {
          const item = { ...facetItem, facetName: facet['name'] }

          limitedResults.push(item)
        })
      })

      // Order the result by highest count and set a limit
      return limitedResults
        .sort((a, b) => b.count - a.count)
        .slice(0, limit)
    },
  },
  created () {
    // We cannot invoke the "set legend" from within the filters.js file
    // This needs to be done so the lookUp color mapping is triggered
    this.$store.commit(FILTERS_MUTATION_TYPES.SET_LEGEND_PROPERTY, {
      property: this.legendProperty,
      items: this.getLegendItems(this.legendProperty),
    })
  },
  mixins: [ConfigMixin],
}
