export function getFirst (a) {
  // TODO: sort by date
  return (Array.isArray(a) ? a[0] : a) || {}
}

export function getNested (a, prop) {
  prop = prop.split('.')
  if (!a) {
    return
  }
  if (prop.length === 1) {
    return a[prop[0]]
  }
  if (prop.length === 2) {
    return getFirst(a[prop[0]])[prop[1]]
  }
  if (prop[2] === 'monthly') {
    const item = (getFirst(a[prop[0]])[prop[1]] || [])
      .find(s => s.time_range_factor === 'months' && s.time_range_count == 1) || {}
    return item[prop[3]]
  }
  const [filterProp, filterValue] = prop[2].split(':')
  const list = getFirst(a[prop[0]])[prop[1]]
  const item = (list || []).find(s => console.log(s[filterProp]) || s[filterProp] === filterValue) || {}
  return item[prop[3]] || (list ? 'not found' : 'empty list')
}

export function getFilesError (errors) {
  const keys = Object.keys(errors || {})
  for (var i = 0; i < keys.length; i++) {
    if (keys[i].startsWith('files.')) {
      return getFirst(errors[keys[i]])
    }
  }
  if (keys.length) {
    return getFirst(errors[keys[0]])
  }
}
