<template>
  <div class="items" v-show="items.query && (items.query.length > 1 || items.message)">
    <template v-if="items.data && items.data.length">
      <div>
        <h5 style="margin-bottom: 15px;">{{ mentionText }}</h5>
        <div style="display: flex; flex-direction: row; max-height: 300px;">
          <div v-if="actors.length > 0" :class="{'results-left-side': true, 'full-width': users.length === 0}">
            <h5 style="margin-bottom: 5px;" v-if="users.length > 0">Actors</h5>
            <button
                class="item"
                :class="{ 'is-selected': selectedType === 'actor' && index === selectedIndex }"
                v-for="(item, index) in actors"
                :key="index"
                @click="selectItem(index, 'actor')"
            >
              {{ item.name }}
            </button>
          </div>
          <div v-if="users.length > 0" class="results-right-side">
            <h5 v-if="actors.length > 0" style="margin-bottom: 5px;">Users</h5>
            <button
                class="item"
                :class="{ 'is-selected': selectedType === 'user' && index === selectedUserIndex }"
                v-for="(item, index) in users"
                :key="index"
                @click="selectItem(index, 'user')"
            >
              {{ item.name }}
            </button>
          </div>
          <div v-if="users.length === 0 && actors.length === 0">
            No results found.
          </div>
        </div>
      </div>
    </template>

    <div v-else-if="items.message" class="item">
      {{ items.message }}
    </div>

    <div v-else class="item">
      {{ $t('actor_mention_no_result') }}
    </div>
  </div>
</template>

<script>

export default {
  props: {
    items: {
      type: Object,
      default: () => {
        return { query: '', data: [], message: '' }
      },
    },
    command: {
      type: Function,
      required: true,
    }
    /*routeParameters: {
      type: Object,
      default: {}
    }*/
  },
  data () {
    return {
      selectedType: 'actor',
      selectedIndex: 0,
      selectedUserIndex: 0,
    }
  },
  computed: {
    mentionText () {
      let mentionText = ''

      if (this.actors.length > 0) {
        mentionText = 'actor'
      }

      if (this.users.length > 0) {
        mentionText = (mentionText !== '' ? 'an ' + mentionText + ' or ' : 'a ') + '(verified) user'
      } else {
        mentionText = 'an ' + mentionText
      }

      return 'Mention ' + mentionText + ' by selecting the name:'
    },
    actors () {
      return this.items.data.filter(item => item.type === 'actor')
    },
    users () {
      return this.items.data.filter(item => item.type === 'user')
    }
  },
  methods: {
    onKeyDown ({ event }) {
      if (event.key === 'ArrowUp') {
        this.upHandler()
        return true
      }

      if (event.key === 'ArrowLeft') {
        // Not allowed to arrow-left, as the select-cursor will move but there is no content available
        if (this.actors.length === 0 || this.users.length === 0) {
          return false
        }

        this.leftHandler()
        return true
      }

      if (event.key === 'ArrowRight') {
        // Not allowed to arrow-left, as the select-cursor will move but there is no content available
        if (this.actors.length === 0 || this.users.length === 0) {
          return false
        }

        this.rightHandler()
        return true
      }

      if (event.key === 'ArrowDown') {
        this.downHandler()
        return true
      }

      if (event.key === 'Enter') {
        this.enterHandler()
        return true
      }

      return false
    },
    upHandler () {
      if (this.selectedType === 'actor') {
        this.selectedIndex = ((this.selectedIndex + this.actors.length) - 1) % this.actors.length
      }

      if (this.selectedType === 'user') {
        this.selectedUserIndex = ((this.selectedUserIndex + this.users.length) - 1) % this.users.length
      }
    },
    downHandler () {
      if (this.selectedType === 'actor') {
        this.selectedIndex = (this.selectedIndex + 1) % this.actors.length
      }

      if (this.selectedType === 'user') {
        this.selectedUserIndex = (this.selectedUserIndex + 1) % this.users.length
      }
    },
    rightHandler () {
      this.selectedType = 'user'
    },
    leftHandler () {
      this.selectedType = 'actor'
    },
    enterHandler () {
      let index = this.selectedIndex

      if (this.selectedType === 'user') {
        index = this.selectedUserIndex
      }

      this.selectItem(index, this.selectedType)
    },
    selectItem (index, type) {
      let item = {}

      if (type === 'actor') {
        item = this.actors[index]
      }

      if (type === 'user') {
        item = this.users[index]
      }

      if (item.value) {
        this.command({ id: item.value, label: item.name, type: type })
      }

    },
  },
  watch: {
    'items.data' () {
      this.selectedIndex = 0
      this.selectedUserIndex = 0
    },
  },
}
</script>

<style lang="scss" scoped>
.items {
  position: relative;
  min-width: 500px;
  max-width: 500px;
  padding: 0.7rem;
  border-radius: 0.5rem;
  background: #FFF;
  color: rgba(0, 0, 0, 0.8);
  overflow: hidden;
  font-size: 1rem;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.55), 0px 10px 20px rgba(0, 0, 0, 0.55);
}

.item {
  display: block;
  margin: 0;
  width: 100%;
  text-align: left;
  background: transparent;
  border-radius: 0.4rem;
  border: 2px solid transparent;
  padding: 0.2rem 0.4rem;

  &.is-selected {
    border-color: var(--primary-community);
  }
}

.results-left-side {
  padding: 0 10px 0 0;
  overflow: hidden;
  overflow-y: auto;
  border-right: 1px solid var(--primary-community-lighter);

  &.full-width {
    border-right: none;
    width: 100%;
  }
}

.results-right-side {
  padding: 0 0 0 10px;
  overflow: hidden;
  overflow-y: auto;
}
</style>
