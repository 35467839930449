<template>
  <div class="tree-root"
    :style="{marginBottom: naceDescription.length > maxLengthForNextLine && isFilter ? '20px' : '', marginTop: isParent && longParent && isFilter ? '20px' : '' }"
    v-if="displayItem">
    <div class="item-line">
      <icon :name="shouldCollapse ? 'chevron-right' : 'chevron-down'" @click="handleToggleCollapsed" class="icon"
        :class="(root.children !== undefined && root.children.length > 0) && !atMaximumDepth ? '' : 'hidden'"/>
      <checkbox :model-value="chosenCodes" :val="root.code" multiple :displayLabel="false" @update:modelValue="handleChangedValue">
        <div class="nace-tree__item-description" :class="{'nace-tree__main-item-description' : root.code === mainCode}">{{ naceDescription }}</div>
      </checkbox>
      <ds-button @click="setMainCode()" label="main activity" class="nace-tree__item-cta" v-if="showSetAsMainButton && !isFilter"/>
    </div>

    <div class="children" v-if="!this.shouldCollapse && root.children && root.children.length > 0">
      <nace-tree
        v-for="child in root.children"
        :actorNaceCodes="chosenCodes"
        :mainCode="chosenMainCode"
        :searchQuery="searchQuery"
        :actorId="actorId"
        :parentId="root.code"
        :parentIds="allParentIds"
        :root="child"
        :path="[...path, child.id]"
        :maxDepth="maxDepth"
        :key="child.id"
        :is-filter="isFilter"
        :longParent="naceDescription && naceDescription.length > maxLengthForNextLine"
        :isParent="true"
      />
    </div>
  </div>
</template>

<script>
  import Checkbox from '../Form/Checkbox.vue'

  import { _unique } from '../../util/helpers'

  export default {
    name: 'nace-tree',
    components: { Checkbox },
    props: {
      actorId: {
        type: String,
      },
      root: {
        type: Object,
      },
      description: {
        type: String,
      },
      path: {
        type: Array,
      },
      maxDepth: {
        type: Number,
      },
      actorNaceCodes: {
        type: Array,
      },
      mainCode: String,
      parentId: null,
      parentIds: {
        type: Array,
        default: () => [],
      },
      searchQuery: {
        type: String,
        default: () => '',
      },
      isFilter: {
        type: Boolean,
        default: false,
      },
      longParent: {
        type: Boolean,
      },
      isParent: {
        type: Boolean,
      },
    },
    data () {
      return {
        collapsed: true,
        childDescriptions: [],
        childCodes: [],
        maxLengthForNextLine: 80,
      }
    },
    computed: {
      showSetAsMainButton () {
        return this.chosenCodes.includes(this.root.code)
      },
      naceDescription () {
        var description = this.root.code + ' - ' + this.root.description

        if (this.root.code === this.mainCode) {
          return description + ' - main NACE activity'
        }

        return description
      },
      displayItem () {
        if (!this.searchQuery || this.searchQuery.length < 2) {
          return true
        }

        var regex = new RegExp(`.*${this.searchQuery}.*`, 'ig')

        if (regex.test(this.root.description)) {
          return true
        }

        if (regex.test(this.root.code)) {
          return true
        }

        for (var i = 0; i < this.childDescriptions.length; i++) {
          if (regex.test(this.childDescriptions[i])) {
            return true
          }
        }

        for (var i = 0; i < this.childCodes.length; i++) {
          if (regex.test(this.childCodes[i])) {
            return true
          }
        }

        return false
      },
      shouldCollapse () {
        if (this.searchQuery && this.searchQuery.length >= 2) {
          return false
        }

        return this.collapsed
      },
      allParentIds () {
        var ids = []

        if (!this.parentId) {
          return ids
        }

        ids.push(this.parentId)

        if (this.parentIds && this.parentIds.length > 0) {
          ids = ids.concat(this.parentIds)
        }

        return ids
      },
      atMaximumDepth () {
        return this.path.length >= this.maxDepth
      },
      chosenCodes () {
        if (!this.actorNaceCodes) {
          return []
        }

        return this.actorNaceCodes
      },
      chosenMainCode () {
        return this.mainCode
      },
    },
    methods: {
      setMainCode () {
        this.$bus.emit('main-code-updated', {
          mainCode: this.root.code,
          actorId: this.actorId,
        })
      },
      handleToggleCollapsed () {
        this.collapsed = !this.collapsed
      },
      handleChangedValue (value) {
        // If a value got removed, emit an event
        if (!value.includes(this.root.code)) {
          var removedCodes = [this.root.code]

          if (this.childCodes) {
            removedCodes = removedCodes.concat(this.childCodes)
          }

          this.$bus.emit('nace-removed', {
            removedCodes: removedCodes,
            actorId: this.actorId,
          })
        } else {
          var addedCodes = [this.root.code]

          if (this.allParentIds) {
            addedCodes = addedCodes.concat(this.allParentIds)
          }

          this.$bus.emit('nace-added', {
            addedCodes,
            actorId: this.actorId,
          })
        }
      },
      buildChildCodes () {
        if (!this.root.children || this.root.children.length === 0) {
          return []
        }

        var childCodes = []

        this.root.children.forEach((child) => {
          childCodes.push(child.code)
          childCodes = childCodes.concat(getChildrenCodes(child))
        })

        this.childCodes = _unique(childCodes)
      },
      buildChildDescriptions () {
        if (!this.root.children || this.root.children.length === 0) {
          return []
        }

        var childDescriptions = []

        this.root.children.forEach((child) => {
          childDescriptions.push(child.description)
          childDescriptions = childDescriptions.concat(getChildrenDescriptions(child))
        })

        this.childDescriptions = _unique(childDescriptions)
      },
    },
    mounted () {
      this.buildChildCodes()
      this.buildChildDescriptions()
    },
  }

  function getChildrenCodes (child) {
    var childrenCodes = []

    childrenCodes.push(child.code)

    if (child.children && child.children.length > 0) {
      for (var i = 0; i < child.children.length; i++) {
        child.children.forEach((grandChild) => {
          childrenCodes = childrenCodes.concat(getChildrenCodes(grandChild))
        })
      }
    }

    return childrenCodes
  }

  function getChildrenDescriptions (child) {
    var descriptions = []

    descriptions.push(child.description)

    if (child.children && child.children.length > 0) {
      for (var i = 0; i < child.children.length; i++) {
        child.children.forEach((grandChild) => {
          descriptions = descriptions.concat(getChildrenDescriptions(grandChild))
        })
      }
    }

    return descriptions
  }
</script>

<style lang="scss" scoped>
  .children {
    padding-left: 20px;
  }

  .tree-root {
    border: 1px solid transparent;
    padding-bottom: 5px;
    user-select: none;
  }

  .accepting-drop {
    border: 1px solid #00C3AF;

    > .item-line > .name-span {
      color: #00C3AF;
    }
  }

  .name-input {
    font-size: inherit;
    padding: 0 6px;
    position: relative;
    left: -7px;
    width: 300px;
  }

  .hidden {
    visibility: hidden;
  }

  .item-line {
    display: flex;
    height: 24px;
    line-height: 24px;
  }

  .item-line {
    .buttons {
      display: none;
    }

    &:hover {
      background-color: #f3f3f3;

      .buttons {
        display: block;
      }
    }

    label {
      display: flex;
      margin-top: 3px;
    }
  }

  .icon {
    cursor: pointer;
    opacity: 0.5;

    &:hover {
      opacity: 1;
    }
  }

  .nace-tree__item-description {
    margin-left: 10px;
    color: #aaa;
    font-size: 85%;
    margin-bottom: 10px;
    margin-top: -3px;
  }

  .nace-tree__main-item-description {
    font-weight: 500;
  }

  .nace-tree__item-cta {
    font-size: 10px;
    line-height: 10px;
    padding: 3px;
    height: fit-content;
    top: 0.3em;
    margin-left: 0.8em;
  }
</style>
