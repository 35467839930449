<template>
  <div class="has-action-bar">
    <div class="tabs-border">
      <ul class="tabs__navigation">
        <li
          v-for="tab in tabs"
          class="tabs__navigation__item"
          :class="{ 'tabs__navigation__item--active': activeTab === tab }"
          @click="activateTab(tab)"
          v-text="tab"
        ></li>
      </ul>
    </div>

    <component :is="panel"></component>
  </div>
</template>

<script>
  import Dictionaries from './Exploration/Dictionaries.vue'
  import NewsGroups from './Exploration/NewsGroups.vue'
  import slugify from '../../util/slugify'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'Exploration',
    data () {
      return {
        activeTab: 'lexicons',
        tabs: [
          'lexicons',
          'news groups'
        ]
      }
    },
    computed: {
      panel () {
        switch (this.activeTab) {
          case 'news groups':
            return 'NewsGroups'
          case 'lexicons':
            return 'Dictionaries'
          default:
            return this.activeTab
        }
      }
    },
    methods: {
      activateTab (tab) {
        this.activeTab = tab

        let sluggedActiveTab = slugify(this.activeTab)
        if (this.activeTab === 'news groups') {
          sluggedActiveTab = 'newsGroups'
        }

        this.$router.push({ hash: '#' + sluggedActiveTab })
      }
    },
    mounted () {
      if (window.location && window.location.hash === '#newsGroups') {
        this.activeTab = 'news groups'
      }
    },
    components: {
      Dictionaries,
      NewsGroups
    }
  })
</script>

<style scoped>

</style>
