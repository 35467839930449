<template>
  <div class="progress-bar">
    <div class="active-bar" v-for="n in nFullBars" :key="n"></div>
    <div class="partial-bar">
      <div class="partial-bar-inner" :style="{ width: partialBarWidth }">
        <div class="percentage">
          {{ percentage }}
        </div>
      </div>
    </div>
    <div v-for="(n, index) in nEmptyBars"></div>
  </div>
</template>

<script>
  export default {
    props: {
      amount: Number,
      total: Number,
    },
    computed: {
      nFullBars () {
        return Math.floor(this.amount / 500)
      },
      partialBarWidth () {
        return `${(this.amount / 500) % 1 * 100}%`
      },
      nEmptyBars () {
        return Math.floor((this.total - this.amount) / 500)
      },
      percentage () {
        if (this.total == 0) {
          return
        }

        return `${Math.round(this.amount / this.total * 100)}%`
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import '../../../../scss/variables';

  .progress-bar {
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 25px;
    padding: 1px;
    width: 100%;

    > * {
      flex: 1;
      height: 14px;

      &:first-child,
      &:first-child .partial-bar-inner {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
      }

      &:last-child {
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
      }

      + * {
        margin-left: 1px;
      }
    }
  }

  .percentage {
    color: #d8d8d8;
    font-size: 12px;
    position: absolute;
    right: 0;
    top: 24px;
    width: 30px;
    transform: translateX(50%);

    &::before {
      border-left: 1px solid #d8d8d8;
      content: '';
      left: 50%;
      margin-left: -1px;
      position: absolute;
      height: 7px;
      top: -9px;
    }
  }

  .active-bar {
    background-color: $color-primary;
  }

  .partial-bar-inner {
    background-color: $color-primary;
    height: 14px;
    position: relative;
  }
</style>
