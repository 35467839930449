<template>
  <div class="range-slider" style="width: 100%">
    <div class="range-slider__info">
      <div class="range-slider__title">{{ label }}</div>
      <div class="range-slider__range-label">{{ modelValue }} {{ max ? '' : '%' }}</div>
    </div>
    <div ref="slider" v-if="!disabled"></div>
  </div>
</template>

<script>
import noUiSlider from 'nouislider'

import { significantDigits } from './slider'
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    disabled: Boolean,
    label: null,
    max: null,
    min: null,
    step: null,
    modelValue: null,
    avg: null
  },
  emits: ['update:modelValue'],
  computed: {
    stepNum () {
      return parseInt(this.step, 10) || 1
    },
    stepped () {
      return value => significantDigits(Math.round(value / this.stepNum) * this.stepNum)
    },
    pipOptions () {
      const avgPercentage = 100 * this.avg / ((this.max || 100) - (this.min || 0))
      return this.avg ? {
        mode: 'positions',
        values: [Math.max(0.1, Math.min(99.9, avgPercentage))],
        density: 200,
        format: { to: value => 'Current rating: ' + Math.round(value * 10) / 10 }
      } : null
    },
    opts () {
      return {
        start: this.modelValue || this.min || 0,
        connect: true,
        step: parseInt(this.step || 1),
        pips: this.pipOptions,
        range: {
          min: parseInt(this.min || 0),
          max: parseInt(this.max || 100)
        }
      }
    }
  },
  methods: {
    render () {
      noUiSlider.create(this.$refs.slider, this.opts)

      this.$refs.slider.noUiSlider.on('slide', (a, handleId, [start]) => {
        start = this.stepped(start)
        this.$emit('update:modelValue', start)
        this.start = start
      });
    }
  },
  mounted () {
    this.render()
  },
  watch: {
    modelValue (v) {
      if (v !== this.start) {
        this.$refs.slider.noUiSlider.set(v, true)
      }
    },
    pipOptions (v) {
      this.$refs.slider.noUiSlider.off()
      this.$refs.slider.noUiSlider.destroy()
      this.render()
    }
  }
})
</script>

<style lang="scss">
.input-range--score {
  .noUi-pips-horizontal {
    padding: 0;
  }
  .noUi-marker.noUi-marker-large {
    margin-top: -11px;
    margin-left: -10px;
    height: 20px;
    width: 20px;
    background: #CECECE;
    border-radius: 10px;
  }
  .noUi-marker.noUi-marker-normal {
    display: none;
  }
  .noUi-value-horizontal {
    left: calc(100% + 3rem)!important;
    right: -100%;
    top: -.6rem;
    width: 80%;
    text-align: left;
    padding-top: 0;
  }
}
</style>
