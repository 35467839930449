import { actorTypeNav } from '../constants/config.js'
import { ACTION_TYPES as COMMUNITY_ACTION_TYPES } from '../store/modules/communities.js'

export default {
  computed: {
    config () {
      return this.$store.state.config
    },
    viewActorTypes () {
      return this.$store.getters.viewActorTypes
    },
    directories () {
      return this.$store.getters.viewActorTypes.map(actorType => actorTypeNav.find(n => n.actor_type === actorType) || {
        actor_type: actorType,
        label: actorType,
      })
    },
    currentRoute () {
      return this.$route
    },
    activeActorType () {
      return this.$route.query.actor_type || 'LegalEntity'
    },
    hasAccessToSpottingAreas () {
      return this.$store.getters.hasAccessToNewSpottingAreas
    },
    spottingAreas () {
      return this.$store.state.spottingAreas.listData.data
    },
    isBuzzEnabled () {
      return this.$store.getters.canUseBuzz
    },
    hasAccessToMonitoring () {
      return this.$store.getters.hasAccessToMonitoring
    },
    isExplorationOnly () {
      return this.$store.getters.isExplorationOnly
    },
    isDeveloper () {
      return this.$store.getters.isDeveloper
    },
    displayExploreContent () {
      if (this.$store.getters.isLoggedIn && this.$store.getters.hasAccessToExploration) {
        return true
      }

      const hasAccess = this.$store.getters.hasAccessToMonitoring

      return this.$store.getters.isLoggedIn && hasAccess && this.config.viewDashboard && this.config.viewDashboard.includes('explore-content')
    },
    displayActorScouting () {
      return this.$store.getters.isLoggedIn && (this.isMember || this.isOwner) && this.config.enableActorScouting
    },
    displayNewsOverview () {
      const hasAccess = (this.$store.getters.hasAccessToExploration || this.$store.getters.hasAccessToMonitoring)

      return this.$store.getters.isLoggedIn && hasAccess && this.config.viewDashboard && this.config.viewDashboard.includes('news-overview')
    },
    displayPortfolios () {
      return this.isOwner ||
        this.isMember // && this.$store.getters.views && this.$store.getters.views.includes('portfolios'))
    },
    announcementsUrl () {
      return '/announcements'
    },
    displayAnnouncements () {
      return this.$store.getters.canUsePublicAnnouncements && !this.isExplorationOnly && this.$store.getters.areMessageBoardsEnabled
      // && ((!this.$store.getters.isOwner && !this.$store.getters.isTeamMember) ? this.$store.state.notifications.listData.data.length > 0 : true)
    },
    displayEvents () {
      return this.$store.getters.canUsePublicAnnouncements && !this.isExplorationOnly && this.$store.getters.areMessageBoardsEnabled
      // && ((!this.$store.getters.isOwner && !this.$store.getters.isTeamMember) ? this.$store.state.notifications.eventListData.data.length > 0 : true)
    },
    shouldSeeChallenges () {
      if (this.isMember) {
        return true
      }

      return this.$store.getters.canCreateChallenge ||
        this.$store.getters.viewableChallengeStatusses.length > 0 ||
        this.$store.getters.accessibleChallengeStatusses.length > 0
    },
    displayChallenges () {
      return this.$store.state.config.challengesAreEnabled && this.shouldSeeChallenges
    },
    displayCommunities () {
      return this.$store.getters.hasAccessToCommunities
    },
    communities () {
      return this.$store.state.communities.listData.data
    },
    displayInstantMonitoring () {
      // temporarily disable monitoring check b/c staging doesn't have it
      return !this.$store.getters.isExplorationOnly && this.config.viewDashboard && this.config.viewDashboard.includes('instant-monitoring')
    },
    displayKnowledgeBase () {
      if (this.$store.getters.isExplorationOnly) {
        return true
      }

      return this.$store.getters.hasAccessToKnowledgeBase && this.config.viewDashboard && this.config.viewDashboard.includes('knowledge-base')
    },
    userCanCreate () {
      return this.$store.getters.userCanCreate
    },
    isPortfolioMember () {
      return this.$store.getters.isPortfolioMember
    },
    isMember () {
      return this.$store.getters.isMember
    },
    isEcosystemMember () {
      return this.$store.getters.isActor
    },
    isOwner () {
      return this.$store.getters.isOwner
    },
  },
}
