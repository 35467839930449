<template>
  <div class="card" :class="cardClasses" @click="onClick">
    <slot></slot>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: [
      'variant'
    ],
    computed: {
      cardClasses () {
        return [
          this.variant && `card--${this.variant}`,
        ]
      }
    },
    emits: ['click'],
    methods: {
      onClick (evt) {
        this.$emit('click', evt)
      }
    }
  })
</script>
