<template>
  <div class="container">
    <!-- Left side -->
    <div class="article-left-side">
      <!--<img :src="articleImage" width="200">-->
      <article-panel
        v-if="article && !isLoading"
        :article="article"
        :add-source-to-title="false"
        :simplified="true"
        :go-to-next="goToNextArticle"
        @mark-relevant="markArticleRelevant"
        @add-tag-to-filters="addTagToKnowledgeBaseKeywords"
      />
      <template v-else>
        <loading/>
      </template>
    </div>

    <!-- Right side -->
    <div class="article-right-side">
      <article-analysis-panel
        @article-data-changed="fetchArticleAnnotationData(true)"
        :isLoading="isLoading"
        v-if="article" :article="article"
      />
    </div>
  </div>
</template>

<script>
  import ArticlePanel from '../../components/Dashboard/ConceptMap/ArticlePanel.vue'
  import ArticleOverviewPanel from '../../components/Dashboard/ConceptMap/ArticleOverviewPanel.vue'
  import ArticleActorPanel from '../../components/Dashboard/ConceptMap/ArticleActorPanel.vue'
  import BasicHero from '../../components/BasicHero/BasicHero.vue'
  import SpottingAreaHero from '../../components/Simplified/SpottingAreaHero.vue'
  import AnnouncementOrResourceCard from '../../components/Simplified/AnnouncementOrResourceCard.vue'
  import KnowledgeBaseItem from '../../components/KnowledgeBase/KnowledgeBaseItem.vue'

  import { annotateArticle } from '../../api/knowledge-base'
  import UiMixin from '../../util/UiMixin'
  import ConfigMixin from '../../util/ConfigMixin'
  import FiltersMixin from '../../util/FiltersMixin'
  import TranslationsMixin from '../../util/TranslationsMixin'
  import RichTag from '../../components/Tag/RichTag.vue'
  import { updateFileRelevancy } from '../../api/exploration'
  import { MUTATION_TYPES as KNOWLEDGE_BASE_MUTATION_TYPES } from '../../store/modules/knowledgeBase'
  import AnalyzeOverviewPanel from '../../components/Dashboard/ConceptMap/AnalyzeOverviewPanel.vue'
  import Loading from '../../components/Dashboard/ConceptMap/Loading.vue'
  import ArticleAnalysisPanel from '../../components/Dashboard/ConceptMap/ArticleAnalysisPanel.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'ArticleFileDetailSimplified',
    props: ['file', 'spottingAreaId'],
    data () {
      return {
        dataAvailable: false, // This can be removed?
        relationsInProgress: [],
        article: {
          resource: {
            title: '',
          },
          data: {
            actors: {},
            html: null,
          },
        },
        loadingAnnotations: false,
      }
    },
    computed: {
      actors () {
        return this.article.data && this.article.data.actors
      },
      userCanEdit () {
        return this.$store.getters.isOwner
      },
      isLoading () {
        //return !this.article.data.html || this.file.id !== this.fileId
        return !this.article.details || this.loadingAnnotations
      },
      fileId () {
        return this.file.id
      },
    },
    methods: {
      markArticleRelevant ({ article, isRelevant, conceptSearchId }) {
        const originalRelevant = article.relevant_in ? article.relevant_in.includes(conceptSearchId) : false

        updateFileRelevancy(article.id, isRelevant, conceptSearchId)
          .catch(err => {
            article.relevant = originalRelevant
            console.log(err)
          })
          .then(() => {
            article.relevant = isRelevant

            if (this.conceptSearchAutoReloadEnabled) {
              this.fetchContent()
            }
          })
      },
      addTagToKnowledgeBaseKeywords (tag) {
        if (!tag.value) {
          return
        }

        this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.CLEAR)
        this.$store.commit(KNOWLEDGE_BASE_MUTATION_TYPES.SET_FACET_KEYWORD, tag)

        if (this.$route.name && this.$route.name.indexOf('knowledge-base') <= 0) {
          this.$router.push('/dashboards/knowledge-base')
        }
      },
      goToNextArticle () {
        // this should go next in the list
        if (!this.results || !this.results.content || this.results.content.length === 0) {
          return
        }

        let nextArticleIndex = 1 + this.results.content.findIndex((article) => article.sql_media_id === this.article.details.id)
        if (nextArticleIndex >= this.results.content.length) {
          nextArticleIndex = 0
        }

        const sqlId = this.results.content[nextArticleIndex].sql_media_id
        return this.$router.push(`/dashboards/concept-map/${this.$route.params.id}/content/${sqlId}`)
      },
      fetchArticleAnnotationData (bustCache = false) {
        this.loadingAnnotations = true

        annotateArticle(this.fileId, '', bustCache)
          .then(data => {
            this.dataAvailable = true
            this.article.data = data

            this.loadingAnnotations = false
          })
          .catch(error => {
            console.log(error)

            this.loadingAnnotations = false
          })
      },
      applyFileDataToArticleObject () {
        this.article.resource = {
          id: this.file.id,
          title: this.file.title,
          media_type: 'rss',
          dictionary_similarity: this.file.dictionary_similarity,
          actor_id: this.file.actor_id,
          body: this.file.description,
        }

        this.article.relevant_in = this.file.relevant_in
        this.article.relevant_in_context = this.file.relevant_in_context

        if (this.file.url) {
          this.article.resource.meta = [
            {
              key: 'link',
              value: this.file.url,
            },
          ]
        } else if (this.file.original_article) {
          this.article.resource.meta = [
            {
              key: 'link',
              value: this.file.original_article.url,
            },
          ]

          if (this.file.original_article.meta && this.file.original_article.meta.image) {
            this.article.image = this.file.original_article.meta.image
          }
        } else if (this.file.meta && this.file.meta.patent_uri) {
          this.article.resource.meta = [
            {
              key: 'link',
              value: this.file.meta.patent_uri,
            },
          ]
        }

        this.article.details = this.file
        this.article.resource.title = this.file.title
      },
    },
    mounted () {
      this.applyFileDataToArticleObject()
      this.fetchArticleAnnotationData()
    },
    watch: {
      fileId () {
        this.applyFileDataToArticleObject()
        this.fetchArticleAnnotationData()
      },
    },
    mixins: [UiMixin, ConfigMixin, FiltersMixin, TranslationsMixin],
    components: {
      Loading,
      AnalyzeOverviewPanel,
      RichTag,
      ArticlePanel,
      ArticleOverviewPanel,
      ArticleActorPanel,
      BasicHero,
      SpottingAreaHero,
      AnnouncementOrResourceCard,
      KnowledgeBaseItem,
      ArticleAnalysisPanel,
    },
  })
</script>

<style lang="scss">
  .page-article-file-detail {
    .article-panel__header-tags {
      cursor: default;
    }
  }
</style>

<style lang="scss" scoped>
  @import "../../../scss/variables";

  $accent-color: var(--primary);
  $border-and-background-color: var(--primary-extra-lightest);
  $hover-color: var(--primary-lightest);
  $title-height: 40px;
  $nrOfLines: 4;
  $lineHeight: 16px;

  .page-spotting-area-detail {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .page-spotting-area-detail {
    h1.h1 {
      font-size: 26px;
      text-transform: uppercase;
      font-family: $font-stack-secondary;
      font-weight: 200;
    }

    h4.h4 {
      font-family: $font-stack-secondary;
      font-weight: 200;
      font-size: 16px;
    }

    .spotting-areas-title {
      text-transform: uppercase;
      font-size: 1.5rem;
      color: black;
      text-decoration: none;
      display: block;
      margin-top: 2rem;
      white-space: nowrap;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .content__meta {
    display: flex;
    margin-bottom: 0.125rem;
    margin-top: auto;

    svg {
      color: var(--primary-dark);
      margin-right: 0.5rem;
      margin-top: 0.25rem;
      width: 1.25rem !important;
    }

    .tag--active svg {
      margin: 0px;
      width: 1rem;
    }

    ul {
      list-style-type: none;
      display: -webkit-box;
      /*overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical ;*/

      li {
        display: inline-block;

        &:not(:last-child) {
          margin-right: 0.25rem;
          margin-bottom: 0.25rem;
        }
      }
    }

    .tag {
      margin: 0;
      padding: 6px;
      border-radius: 0.5rem;
      border: 1px solid $border-and-background-color;
      background: transparent;
      font-family: inherit;
      font-size: 11px;

      &:hover {
        cursor: pointer;
        background-color: $hover-color;
        border-color: $hover-color;
      }
    }

    .tag--active, .tag--dictionary {
      font-weight: bold;
      // color: $border-and-background-color;
      background-color: $border-and-background-color;
    }
  }

  .container {
    display: flex;
    width: 100%;
    height: 100%;
  }

  .article-left-side, .article-right-side {
    flex-grow: 1;
    flex-shrink: 1;
    min-width: 0;
    overflow-y: auto;
  }

  .article-left-side {
    padding: 20px;
    width: 60%;
  }

  .article-right-side {
    position: relative;
    border-left: 1px solid $color-body-borders;
    width: 40%;
    padding: 20px;
  }

</style>
