<template>
  <div class="container--members">
    <div v-if="label">
      {{ label }}
    </div>
    <div class="members-list">
      <avatar class="member__avatar" :src="members.user_avatar" :alt="members.userName"/>
      <avatar :icon="showIcons" background-color="#5F94F9"/>
      <avatar v-if="members.isPolitical" background-color="#FDBA2C" alt="P"/>
      <avatar v-if="members.isEconomical" background-color="#FC3F60" alt="E"/>
      <avatar v-if="members.isSocial" background-color="#2AC2AD" alt="S"/>
      <avatar v-if="members.isTechnological" background-color="#3BC7E2" alt="T"/>
      <avatar v-if="members.isEnvironmental" background-color="#7F7399" alt="E"/>
      <avatar v-if="members.isLegal" background-color="#2993FC" alt="L"/>

    </div>
  </div>
</template>

<script>
  import Avatar from './Avatar.vue'
  import { proofPointAnalysisTypes } from '../../constants/proofPoints.js'

  export default {
    name: 'avatar-list-new',
    props: {
      members: {
        type: Object
      },
      label: {
        type: String
      },
      tooltipPrefixLabel: {
        type: String
      },
      letters: {
        type: Array,
        default: []
      }
    },
    data () {
      return {
        proofPointAnalysisTypes
      }
    },
    computed: {
      userId () {
        return this.$store.state.user.profile.id
      },
      showIcons () {
        if (this.proofPointAnalysisTypes.INDUSTRY_FORCES === this.members.analysisType) {
          return 'mountain'
        }
        if (this.proofPointAnalysisTypes.KEY_TRENDS === this.members.analysisType) {
          return 'signs-post'
        }
      }
    },
    methods: {},
    components: {
      Avatar
    }
  }
</script>

<style lang="scss" scoped>
  .container--members {
    position: relative;
    align-items: center;

    .members-list {
      display: flex;

      &--item {
        z-index: 20;

        &:hover {
          z-index: 90;
        }

        &:last-child {
          left: 0px;

          .avatar {
            width: 30px;
            height: 30px;
          }
        }
      }
    }

    .member__avatar {
      :deep(.avatar__text) {
        color: var(--primary);
        border: 1px solid black;
        border-radius: 15px;
        width: 30px;
        height: 30px;
        padding: 8px;
      }
    }

    .avatar--default {
      -webkit-box-shadow: 0 0 0 2px white;
      box-shadow: 0 0 0 2px white;
      margin-left: -15px;
      width: 30px;
      height: 30px;
    }

    :deep(.avatar__img) {
      width: 30px;
      height: 30px;
    }

    :deep(.avatar__text) {
      color: white;
    }

    .avatar--border {
      -webkit-box-shadow: 0 0 0 2px white;
      box-shadow: 0 0 0 2px white;

      &:hover {
        -webkit-box-shadow: 0 0 0 2px var(--primary);
        box-shadow: 0 0 0 2px var(--primary);
      }
    }

    &:hover {
      .clickable {
        cursor: pointer;

        .avatar {
          &:hover {
            -webkit-box-shadow: 0 0 0 2px var(--primary);
            box-shadow: 0 0 0 2px var(--primary);
            transform: scale(1.15);
          }
        }

      }
    }
  }
</style>
