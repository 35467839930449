<template>
  <div class="collapsable-tab__content-container">
    <form-group :label="$t('actor_edit_funding_structure')" :class="{'actor-edit__private-field': isMemberField('funding_structure')}">
      <checkbox multiple :label="$t('actor_edit_private_funding')" val="Private funding" v-model="modifiedActor.funding_structure"/>
      <checkbox multiple :label="$t('actor_edit_gifts_donations')" val="Gifts & donations" v-model="modifiedActor.funding_structure"/>
      <checkbox multiple :label="$t('actor_edit_grants')" val="Grants" v-model="modifiedActor.funding_structure"/>
      <checkbox multiple :label="$t('actor_edit_loan_financing')" val="Loan financing" v-model="modifiedActor.funding_structure"/>
      <checkbox multiple :label="$t('actor_edit_equitiy_capital')" val="Equity capital" v-model="modifiedActor.funding_structure"/>
    </form-group>
    <form-group :label="$t('actor_edit_stock_symbol')" :class="{'actor-edit__private-field': isMemberField('funding_structure')}">
      <div class="actor-edit__stock-symbol-container">
        <div style="max-width: 150px;">
          <ds-input placeholder="PREFIX:TICKER" v-model="modifiedActor.stock_symbol" name="actor-stock-symbol"/>
        </div>
      </div>
    </form-group>
    <form-section v-if="isRelevantForActor('funding_rounds', modifiedActor)">
      <div class="row">
        <form-group :label="$t('actor_edit_summary')" class="collapsable-tab__finacial-summary col-xs-12" :errors="errors.original_total_funding"
                    :class="{'actor-edit__private-field': isMemberField('original_total_funding')}">
          <form-group class="col-xs-12" :errors="errors.original_total_funding" :label="$t('actor_edit_total_funding')">
            <div class="financial-round__money-input">
              <dropdown :options="currencies" v-model="modifiedActor.original_total_funding_currency" class="col-xs-5" style="padding: 0px"/>
              <ds-input type="amount" precision="2" v-model="modifiedActor.original_total_funding" class="col-xs-7" style="padding: 0px"/>
            </div>
          </form-group>
          <form-group class="col-xs-12" :errors="errors.original_total_funding" :class="{'actor-edit__private-field': isMemberField('original_total_funding')}">
            <div class="financial-round__money-input">
              <ds-input type="text" :placeholder="$t('actor_edit_nr_of_rounds')" class="col-xs-10" style="padding: 0px" disabled/>
              <ds-input type="amount" precision="2" :placeholder="modifiedActor.funding_rounds && modifiedActor.funding_rounds.length" class="col-xs-2" style="padding: 0px" disabled/>
            </div>
          </form-group>
        </form-group>
      </div>
      <template v-for="(fundingRound, index) in modifiedActor.funding_rounds">
        <financial-round :types="fundingRoundTypes" :data="fundingRound" :allInvestors="investors" :parentData="modifiedActor" @remove="handleRemoveFundingRound(index)"
                         v-if="! fundingRound.hidden || isDeveloper"/>
      </template>
      <ds-button :label="$t('actor_edit_add_funding_round')" type="button" icon="plus" size="small" @click="handleAddFundingRound" class="collapsable-tab__funding-button col-xs-12"/>
    </form-section>
  </div>
</template>

<script>
  import FormGroup from '../../Form/FormGroup.vue'
  import FormSection from '../../Form/FormSection.vue'
  import Checkbox from '../../Form/Checkbox.vue'
  import SuggestionInput from '../../SuggestionInput/SuggestionInput.vue'
  import Dropdown from '../../Dropdown/Dropdown.vue'
  import FinancialRound from '../../FinancialRound/FinancialRound.vue'
  import escapeHTML from '../../../util/escapeHTML.js'
  import { isRelevantForActor } from '../../../util/helpers.js'
  import moment from 'moment'

  export default {
    name: 'actor-edit-financials',
    props: {
      modifiedActor: Object,
      errors: Object,
      resetErrors: Function,
    },
    data () {
      return {
        fundingRoundTypes: [
          'Angel',
          'Convertible Note',
          'Corporate Round',
          'Crowdfunding',
          'Debt Financing',
          'Grant',
          'ICO',
          'Post-IPO Debt',
          'Post-IPO Equity',
          'Post-IPO Secondary',
          'Pre-Seed',
          'Seed',
          'Series A',
          'Series B',
          'Series C',
          'Series D',
          'Series E',
          'Series F',
          'Series G',
          'Series H',
          'Series I',
          'Series J',
          'Other',
          'vc',
        ],
        stockSuggestionSettings: {
          apiSettings: {
            url: '/api/stocksymbols?value={query}',
            onResponse: data => ({
              results: Object.values(data),
            }),
          },
          showNoResults: false,
          renderResults (response) {
            return response.results.map(r => {
              return `
                <a class="result company-suggestion">
                  <div class="company-suggestion__info">
                    <div class="company-suggestion__title">${escapeHTML(r.name)}</div>
                    <div class="company-suggestion__domain">${escapeHTML(r.exchange_desc)} : ${escapeHTML(r.symbol)}</div>
                  </div>
                </a>
              `
            })
          },
        },
      }
    },
    computed: {
      investors () {
        return (this.modifiedActor.invested_by || []).map(({ to, to_name }) => ({ id: to, name: to_name }))
      },
      publicFields () {
        return this.$store.getters.publicFields
      },
      isPublisherEnabled () {
        return this.$store.getters.isPublisherEnabled
      },
      currencies () {
        return window.CURRENCIES
      },
      isDeveloper () {
        return this.$store.getters.isDeveloper
      },
      config () {
        return this.$store.state.config
      },
      allPublicFields () {
        let allPublicFields = []

        if (this.config.permissions && this.config.publisher) {
          allPublicFields = [
            ...this.getPublicFieldsForPermissionSet(this.config.permissions.visitor),
            ...this.getPublicFieldsForPermissionSet(this.config.permissions.portfolio_member),
            ...this.getPublicFieldsForPermissionSet(this.config.publisher),
          ]
        }

        if (window.publicPackageFields && window.publicPackageFields.length) {
          for (let i = 0; i < window.publicPackageFields.length; i++) {
            allPublicFields = [...allPublicFields, ...window.publicPackageFields[i]]
          }
        }

        return allPublicFields
      },
    },
    methods: {
      isRelevantForActor,
      getPublicFieldsForPermissionSet (permissionSet) {
        if (permissionSet && permissionSet.public_fields) {
          return permissionSet.public_fields
        }
        return []
      },
      isMemberField (field) {
        if (!this.isPublisherEnabled) {
          return false
        }

        return !this.allPublicFields.includes(field)
      },
      handleRemoveFundingRound (index) {
        this.modifiedActor.funding_rounds.splice(index, 1)
      },
      handleAddFundingRound () {
        if (!Array.isArray(this.modifiedActor.funding_rounds)) {
          this.modifiedActor.funding_rounds = []
        }

        this.modifiedActor.funding_rounds.push({
          type: this.fundingRoundTypes[0],
          original_capital_raised: null,
          original_currency: 'EUR',
          started_at: moment().format('YYYY-MM-DD'),
        })
      },
      clearStockSymbol () {
        this.modifiedActor.stock_symbol = ''
      },
      isPrivateField (field) {
        return this.isPublisherEnabled && !this.publicFields.includes(field)
      },
      setStockSymbol (actor) {
        this.modifiedActor.stock_symbol = `${actor.exchange}:${actor.symbol}`
      },
    },
    components: {
      FormGroup,
      FormSection,
      Checkbox,
      SuggestionInput,
      Dropdown,
      FinancialRound,
    },
    mixins: [],
  }
</script>
