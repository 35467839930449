<template>
  <div class="leaflet-container"></div>
</template>

<script>
import { createIcons, setup } from '../MapView/MapUtils'

import { loadChunk } from '../../util/chunk-loader'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'simple-map',
  props: ['location', 'legend', 'offices', 'disableMouseWheelZoom', 'usePinPointIcon'],
  computed: {
    icons () {
      if (this.usePinPointIcon) {
        return createIcons([this.$store.state.spottingAreas.highlightedSpottingArea.data.highlight_color], this.$store.state.config.hexBorders, true)
      }

      return createIcons(Object.values(this.$store.state.config.hexColours), this.$store.state.config.hexBorders)
    },
    defaultLegendIcon () {
      return this.icons[this.icons.length - 1]
    },
    getIcon () {
      const legendItem = this.$store.state.filters.legendLookup[this.legend] || {}//this.$store.getters.legendLookup[this.legend] || {}

      if (!legendItem.hexName) {
        return this.defaultLegendIcon
      }

      const hexIndex = legendItem.hexName.replace('hex', '')
      return (this.icons && this.icons[hexIndex]) || this.defaultLegendIcon
    },
  },
  methods: {
    render () {
      loadChunk('L', this.actualRender)
    },
    actualRender () {
      const L = window.L
      if (!this.map) {
        // We set false to disable the belgian map overlay layers
        this.map = setup(this.$el)
        this.map.touchZoom.disable()
        this.map.scrollWheelZoom.disable()
        this.map.boxZoom.disable()
        this.map.keyboard.disable()
      }

      // In case we want to disable the mouse wheel scroll zoom functionality
      if (this.disableMouseWheelZoom) {
        this.map.scrollWheelZoom.disable()
      }

      if (this.featureGroup) {
        this.map.removeLayer(this.featureGroup)
      }

      this.markers = []

      // HQ
      if (this.location && this.location.lat) {
        const markerLatLng = new L.LatLng(this.location.lat, this.location.lon)
        this.markers.push(L.marker(markerLatLng, {
          icon: this.getIcon,
        }))
      }

      // Offices
      if (this.$store.state.ui.showOffices && this.offices && this.offices.length) {
        this.offices.forEach(office => {
          if (office.location && office.location.lat) {
            const markerLatLng = new L.LatLng(office.location.lat, office.location.lon)
            this.markers.push(L.marker(markerLatLng, {
              icon: this.getIcon,
            }))
          }
        })
      }

      if (this.markers.length) {
        this.featureGroup = L.featureGroup(this.markers).addTo(this.map)
        this.map.fitBounds(this.featureGroup.getBounds())
        this.map.setZoom(Math.min(10, this.map.getZoom()))
      } else {
        this.map.setZoom((window.config.viewMapOptions && window.config.viewMapOptions.zoom || 6) - 1)
      }
    },
  },
  mounted () {
    this.render()
  },
  beforeUnmount () {
    if (this.map) {
      this.map.remove()
    }
  },
  watch: {
    location () {
      this.render()
    },
    offices () {
      this.render()
    },
    '$store.state.ui.showOffices' () {
      this.render()
    },
  },
})
</script>
