<template>
  <tr :class="wrapperClass">
    <td colspan="3">
      <div class="analysis-card__title-skeleton"></div>
      <div class="analysis-card__title-skeleton"></div>
      <div class="analysis-card__title-skeleton"></div>
    </td>
    <td colspan="2">
      <div class="analysis-card__text-skeleton"></div>
      <div class="analysis-card__text-skeleton"></div>
      <div class="analysis-card__text-skeleton"></div>
    </td>
  </tr>
</template>

<script>
  export default {
    props: {
      wrapperClass: {
        type: String,
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../scss/variables.scss";

  .analysis-card__skeleton {
    background: $color-background-lightest-grey;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: $default-border-radius-narrow;

    .analysis-card__title-skeleton {
      background: grey;
      height: 14px;
      border-radius: $default-border-radius-narrow;
      margin-bottom: 10px;

      &:first-child {
        width: 70%;
      }

      &:nth-child(2) {
        width: 80%;
      }

      &:nth-child(3) {
        width: 60%;
        margin-bottom: 20px;
      }
    }

    .analysis-card__text-skeleton {
      height: 14px;
      border-radius: $default-border-radius-narrow;
      width: 100%;
      background: lightgrey;
      margin-bottom: 10px;
    }
  }
</style>
