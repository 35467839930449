<template>
  <div class="simple-multiselect">
    <select-option
      v-for="(option, index) in options"
      :key="index"
      :model-value="option.value"
      :label="option.label"
      :selected="option.selected"
      :read-only="readOnly"
      @select="select"
      @deselect="deselect"
    />
  </div>
</template>

<script>
  import SelectOption from './SelectOption.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    components: { SelectOption },
    props: {
      modelValue: Array,
      options: Array,
      readOnly: {
        type: Boolean,
        default: false,
      }
    },
    emits: ['select', 'deselect'],
    methods: {
      select (option) {
        this.$emit('select', option)
      },
      deselect (option) {
        this.$emit('deselect', option)
      },
    }
  })
</script>

<style lang="scss">
  .simple-multiselect {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
</style>
