import { createJSONRequest, createResource } from '../../../api/create-request.js'
import querify from '../../../util/querify.js'

export const Challenges = createResource('/api/challenges')

export const fetchChallenges = ({ limit, offset = 0, filters }) => createJSONRequest({
  method: 'GET',
  url: '/api/challenges?' + [limit && `limit=${limit}`, offset && `offset=${offset}`, querify(filters)].filter(p => p).join('&'),
})

export const fetchChallengesCount = (filters = {}) => createJSONRequest({
  method: 'GET',
  url: `/api/challenges-count?${querify(filters)}`,
})

export const getFilterableTags = () => createJSONRequest({
  method: 'GET',
  url: `/api/challenges-tags`,
})

export const acceptChallenge = (id: number) => createJSONRequest({
  method: 'PUT',
  url: `/api/challenges/${id}/accept`,
})

export const denyChallenge = (id: number) => createJSONRequest({
  method: 'PUT',
  url: `/api/challenges/${id}/deny`,
})

export const createComment = (id: number, comment: string) => createJSONRequest({
  method: 'POST',
  url: `/api/challenges/${id}/comments`,
  data: {
    comment,
  },
})

export const createReplyComment = (id: number, comment: string, replyToId: number) => createJSONRequest({
  method: 'POST',
  url: `/api/challenges/${id}/comments`,
  data: {
    comment,
    reply_to_id: replyToId,
  },
})

export const updateLikeStatus = (id: number, commentId: number, status: string) => createJSONRequest({
  method: 'PUT',
  url: `/api/challenges/${id}/comments/${commentId}/like`,
  data: {
    status,
  },
})

export const deleteComment = (id: number, commentId: number) => createJSONRequest({
  method: 'DELETE',
  url: `/api/challenges/${id}/comments/${commentId}`,
})

export const getComments = (id: number) => createJSONRequest({
  method: 'GET',
  url: `/api/challenges/${id}/comments`,
})

export const fetchInvitees = (id: number) => createJSONRequest({
  method: 'GET',
  url: `/api/challenges/${id}/invitees`,
})

export const updateInvitees = (id: number, invitees) => createJSONRequest({
  method: 'PUT',
  url: `/api/challenges/${id}/invitees`,
  data: {
    invitees,
  },
})

export const updatePinStatus = (id: number, status: string) => createJSONRequest({
  method: 'PUT',
  url: `/api/challenges/${id}/pin`,
  data: {
    status,
  },
})

export const getMatchingActors = (id: number, filters: object) => createJSONRequest({
  method: 'GET',
  url: `/api/challenges/${id}/matching-actors?${querify(filters)}`,
})

export const getShortListedActors = (challengeId: number) => createJSONRequest({
  method: 'GET',
  url: `/api/challenges/${challengeId}/actors`,
})

export const removeShortListedActor = (challengeId: number, actorId: string) => createJSONRequest({
  method: 'DELETE',
  url: `/api/challenges/${challengeId}/actors/${actorId}`,
})

export const addShortListedActor = (challengeId: number, actorId: string) => createJSONRequest({
  method: 'PUT',
  url: `/api/challenges/${challengeId}/actors/${actorId}`,
})

export const inviteUsersViaActor = (challengeId: number, actorId: string) => createJSONRequest({
  method: 'POST',
  url: `/api/challenges/${challengeId}/invite-via-actor/${actorId}`,
})

export const fetchSimilarChallenges = (challengeId: number) => createJSONRequest({
  method: 'GET',
  url: `/api/challenges/${challengeId}/matching-challenges`,
})

export const fetchChallengesWithCount = ({ limit, offset = 0, filters }) => createJSONRequest({
  method: 'GET',
  url: `/api/challenges-with-count`,
})
