<template>
  <modal ref="modal" :id="modalId" @close="$emit('close')">
    <template v-slot:body>
      <div>
        Are you sure you want to remove <b>{{ user.name }}</b>? (<a :href="'mailto:' + user.email">{{ user.email }}</a>)
      </div>
      <div style="margin-top: 1rem;" v-if="hasDataToRemove">
        <div style="margin-top: 0.5rem; margin-bottom: 0.5rem;">Tell us what you like to do with the content {{ user.name }} added to the platform, i.e. announcements, challenges
          and uploaded files.
          You can "remove user and content" or just "remove user" and assign all great content that was created to a colleague or an admin of the platform.
        </div>
        <ul style="list-style-type: circle; margin-left: 1rem;">
          <li v-if="amountOfAnnouncements > 0">{{ announcementsLabel }}</li>
          <li v-if="amountOfChallenges > 0">{{ challengesLabel }}</li>
          <li v-if="amountOfFiles > 0">uploaded files</li>
        </ul>
      </div>
    </template>
    <template v-slot:footer>
      <div v-if="user.id">
        <ds-button :disabled="removing" label="Remove user" variant="primary" @click="transferDataAndRemoveFromEcosystem()"
          v-if="hasDataToRemove && candidateUserToTransferDataTo.length > 0"/>
        <ds-button :disabled="removing" :label="hasDataToRemove ? 'Remove user and content' : 'Remove user'" variant="primary" @click="removeDataAndLeaveEcosystem()"/>
        <ds-button :disabled="removing" label="Cancel" variant="secondary" @click="close()"/>
        <p class="form-msg error" v-if="error" v-html="error"></p>
      </div>
      <div v-else>
        <ds-button :disabled="removing" label="Cancel" variant="secondary" @click="close()"/>
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import MODAL_IDS from '../../constants/modal-ids'
  import Dropdown from '../../components/Dropdown/Dropdown.vue'
  import RadioButton from '../Form/RadioButton.vue'

  import { getCandidateUsersToTransferDataTo, getTransferableDataInformation } from '../../api/user'
  import TranslationsMixin from '../../util/TranslationsMixin.js'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
  import { inert } from '../../util/helpers.js'
  import { deleteUser } from '../../api/config.js'

  export default {
    data () {
      return {
        error: null,
        modalId: MODAL_IDS.REMOVE_USER_FROM_ECOSYSTEM,
        removing: false,
        user: {},
        candidateUserToTransferDataTo: [],
        amountOfChallenges: 0,
        amountOfAnnouncements: 0,
        amountOfFiles: 0
      }
    },
    computed: {
      hasDataToRemove () {
        return this.amountOfChallenges > 0 || this.amountOfAnnouncements > 0 || this.amountOfFiles > 0
      }
    },
    methods: {
      transferDataAndRemoveFromEcosystem () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, { user: this.user })
        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.USER_LEAVE_ECOSYSTEM_AND_TRANSFER_DATA)
      },
      async removeDataAndLeaveEcosystem () {
        this.removing = true

        try {
          await deleteUser(this.user.id)

          this.$bus.emit('userDeleted')
          this.close()
        } catch (e) {
          console.log(e)
        }
      },
      close () {
        this.$refs.modal.close()
      }
    },
    mixins: [TranslationsMixin],
    async mounted () {
      this.user = inert(this.$store.state.ui.modalContext.user)

      const transferableData = await getTransferableDataInformation(this.user.id)

      this.amountOfChallenges = transferableData.challenge_count
      this.amountOfAnnouncements = transferableData.announcement_count
      this.amountOfFiles = transferableData.file_count

      this.candidateUserToTransferDataTo = await getCandidateUsersToTransferDataTo(this.user.id)
    },
    components: {
      Modal,
      Dropdown,
      RadioButton
    }
  }
</script>

<style scoped>

</style>
