export const transformLoggedInUserToUserBaseInfoData = (user: any): UserBaseInfoData => {
  return {
    id: user.id,
    name: user.name,
    email: user.email,
    photoUrl: user.photo_url,
    bio: user.bio,
    linkedin: user.linkedin_url,
    twitter: user.twitter_url,
    instagram: user.instagram_url,
  };
}
