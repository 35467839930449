const state = {
  introIsActive: false,
}

export const MUTATION_TYPES = {
  SET_INTRO_IS_ACTIVE: 'INTRO_JS/SET_INTRO_IS_ACTIVE',
}

export const mutations = {
  [MUTATION_TYPES.SET_INTRO_IS_ACTIVE] (state, intro) {
    state.introIsActive = intro
  },
}

export default {
  state,
  mutations,
}
