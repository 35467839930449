<template>
    <div>
      <h2 class="h2">
        Current plan
      </h2>

      <div v-if="loading">
        <icon name="spinner"/> Loading...
      </div>
      <div v-else>
        <div class="personal_subscription__plan-info">
          <div v-if="! subscriptionInfo.packageTitle">
            No plan selected, you are currently using a free version of the platform.
          </div>
          <div v-else>
            <div>
              <h3 class="h3">{{subscriptionInfo.packageTitle}}</h3>
            </div>
            <div>
              <h4 class="h4">€{{ subscriptionInfo.packagePrice }}/{{ subscriptionInfo.packageBillingCycle }}</h4>
            </div>
          </div>
        </div>

        <div class="personal_subscription__payment-required" v-if="hasIncompletePayment && !isOnGracePeriod">
          Your last payment was not completed or has failed. Please <a :href="subscriptionInfo.paymentRoute">click here</a> in order to complete your payment.
        </div>
      </div>
    </div>
</template>

<script>
  export default {
    name: 'PersonalSubscriptionInfo.vue',
    props: {
      subscriptionInfo: Object,
      loading: Boolean
    },
    computed: {
      hasIncompletePayment () {
        return this.subscriptionInfo && this.subscriptionInfo.subscriptionStatus === 'incomplete'
      },
      isOnGracePeriod () {
        if (!this.subscriptionInfo) {
          return false
        }

        return this.subscriptionInfo.subscriptionOnGracePeriod
      }
    }
  }
</script>

<style scoped lang="scss">
  .personal_subscription__plan-info {
    display: flex;
    flex-direction: column;
  }

  .personal_subscription__plan-info-item {
    display: flex;
    flex-direction: row;
  }

  .personal_subscription__payment-required {
    margin-top: 1rem;
  }
</style>
