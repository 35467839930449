<template>
  <div class="actor-suggestions similar-actors-sidepanel">
    <div class="fixed-heading">
      <div class="card__general side-panel__header" style="flex-direction: column">
        <div style="display: flex; width: 100%">
          <div
            class="card__title side-panel__actor-suggestions-card__title side-panel__actor-suggestions-header-card__title">
            SIMILAR ACTORS IN THE ECOSYSTEM
          </div>
          <div class="pull-right side-panel__announcement-header-button-container"
               style="text-align: right;">
            <ds-button icon="remove" variant="minimal" @click="hidePreview" size="small"
                       class="side-panel__announcement-header-icon"/>
          </div>
        </div>
        <!--<div style="align-self: normal; ">Find similar actors based on similar description and/or same criteria (i.e. industry, technology) in relation to {{company.name}}. Select the relevant filters to filter on those criteria.</div>-->
      </div>
      <!--<similar-actors-filter-section
        :selected-classifications="selectedClassifications"
        @update-selected-classifications="selectedClassifications = $event; fetchSimilarActors()"
        :selectedFilters="selectedFilters"
        :classification-counts="classificationCounts"
        :allow-edit="true"
        :with-padding="true"
        @update-selected-filters="selectedFilters = $event; fetchSimilarActors()"
      />-->
    </div>
    <div class="scrollable side-panel__scrollable__content has-padding"
         style="padding-bottom: 150px">
      <div v-if="searching">
        <icon name="spinner" style="vertical-align: middle;"/>
        Searching...
      </div>

      <div v-if="!hasAccessToSimilarActorAgent && !searching && !similarActors.length">
        No actors with similar descriptions or criteria were found in your ecosystem.
      </div>
      <div v-if="hasAccessToSimilarActorAgent && !searching && !similarActors.length">
        No actors with similar descriptions or criteria were found in your ecosystem.
        You can use the <span @click="showAgentsPanel"
                              class="agents-panel-link">look-a-like agent</span>
        to discover more actors outside of your ecosystem.
      </div>

      <div
        class="similar-actor-result similar-actor-result--actor"
        v-for="actor in similarActors"
      >
        <div class="similar-actor-result__visual"
             :style="{ backgroundImage: `url(${actor.website_screenshot || getDefaultFallbackImageUrl()})` }"
             @click.stop="showActorSidePanel(actor.id)"
        >
        </div>
        <div class="similar-actor-result__info"
             @click.stop="showActorSidePanel(actor.id)">
          <div
            class="similar-actor-result__title similar-actor-result__title--flex">
            <Avatar variant="border" :src="actorLogo(actor)" :alt="actor.name"/>
            <span style="margin-left: 5px;">{{ actor.name }}</span>
            <div style="margin-left: auto; padding-left: 10px;"
                 v-if="$store.getters.hasAccessToRelevancyUi">
              <relevant-edit-popup
                v-model="actor.relevant_in"
                :active-portfolio="activePortfolioId"
                @relevant="handleActorRelevant(actor.id, $event)"
                @not-relevant="handleActorNotRelevant(actor.id, $event)"
              />
            </div>
          </div>
          <div class="similar-actor-result__subtitle"
               v-if="$store.getters.isDeveloper">
            <span>Score: {{ actor.score }}</span>
          </div>
          <div class="similar-actor-result__description"
               v-if="actor.short_description || actor.activities_description">
            {{
              textLimit(actor.short_description || actor.activities_description || '')
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui.js'

  import Avatar from '../../Avatar/Avatar.vue'

  import { actorLogo } from '../../../util/actor.js'
  import { textLimit } from '../../../util/string.ts'
  import {
    searchSimilarActors, searchSimilarActorsFiltered,
    searchSimilarLocalActors, searchSimilarLocalActorsFiltered,
    updateActorRelevancy,
  } from '../../../api/exploration.js'

  import { createActor } from '../../../api/actors.js'
  import RelevantEditPopup from '../../RelevantEditPopup/RelevantEditPopup.vue'
  import { trackHeapEvent } from '../../../util/analytics.js'
  import CompanyMixin from '../../../util/CompanyMixin.js'
  import SimilarActorsFilterSection from './SimilarActorsFilterSection.vue'
  import { getDefaultFallbackImageUrl } from '../../../util/helpers.js'

  export default {
    props: ['actorId'],
    data () {
      return {
        similarActors: [],
        searching: true,
        selectedFilters: [{ type: 'description', value: true }],
        classificationCounts: null,
      }
    },
    computed: {
      hasAccessToSimilarActorAgent () {
        return this.hasAccessToAgents && this.canLookalikeAgentBeUsed
      },
      company () {
        return this.$store.getters.detailActor
      },
      hasAccessToAgents () {
        return this.$store.getters.hasAccessToAgents
      },
      activePortfolioId () {
        return this.$store.getters.activePortfolio.id
      },
      hasAccessToMonitoring () {
        return this.$store.getters.hasAccessToMonitoring
      },
      hasAccessToExploration () {
        return this.$store.getters.hasAccessToExploration
      },
    },
    methods: {
      getDefaultFallbackImageUrl,
      actorLogo,
      textLimit,
      showAgentsPanel () {
        trackHeapEvent('similarActorsPanel.clickAgents')
        this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
          component: 'agents',
          metaData: { id: this.actorId },
        })
      },
      addActorEcosystem (actor) {
        actor.state = 'adding'

        createActor(actor)
          .then(response => {
            actor.id = response.id

            // Mark the actor as relevant - deprecated
            //updateActorRelevancy(response.id, true)

            actor.state = 'added'
          })
          .catch(err => {
            actor.state = false
          })
      },
      hidePreview (evt) {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
      showActorSidePanel (actorId) {
        window.location = '/actors/' + actorId
      },
      handleActorRelevant (actorId, conceptSearchId) {
        updateActorRelevancy(actorId, true, conceptSearchId)
      },
      handleActorNotRelevant (actorId, conceptSearchId) {
        updateActorRelevancy(actorId, false, conceptSearchId)
      },
      fetchSimilarActors () {
        let similarActorsFunction = searchSimilarActors

        if (!this.hasAccessToExploration && this.hasAccessToMonitoring) {
          similarActorsFunction = searchSimilarLocalActors
        }

        this.searching = true
        similarActorsFunction(this.actorId, this.selectedFilters)
          .then(response => {
            this.searching = false
            this.similarActors = response['same-name'] || []
            if (response['description-facet-count']) {
              this.classificationCounts = response['description-facet-count']
            }
            // this.similarCentralActors = response['central'] || []
          })
          .catch(err => {
            this.searching = false
            console.log(err)
          })
      },
    },
    mounted () {
      this.fetchSimilarActors()
    },
    mixins: [CompanyMixin],
    components: {
      RelevantEditPopup,
      Avatar,
      SimilarActorsFilterSection,
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../../../scss/variables";

  .similar-actors-sidepanel {
    min-width: 650px;
  }

  .similar-actor-result__title--flex {
    display: flex;
    align-items: center;
  }

  .agents-panel-link {
    text-decoration: underline;
    cursor: pointer;
  }
  .similar-actors-panel__results-title {
    font-size: 22px;
    color: var(--primary);
    padding: 20px 0;
  }

  .similar-actor-result {
    display: flex;
    align-items: center;
    padding: 1rem 0;
    border-bottom: 1px solid $color-borders;
    background-color: transparent;
    transition: all .2s;

    &:hover {
      cursor: pointer;
      /*background-color: rgba(#555, 0.05);*/
    }

    &:last-child {
      border: 0;
    }
  }

  .similar-actor-result__visual {
    margin-right: 1rem;
    background-size: cover;
    background-position: center;

    @media (min-width: $screen-md) {
      min-width: 220px;
      max-width: 220px;
      min-height: 100px;
    }
  }

  .similar-actor-result__title {
    margin-bottom: 0.5rem;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 14px;

    .avatar {
      margin-right: 0.25rem;
    }
  }

  .similar-actor-result__subtitle {
    margin-bottom: 0.5rem;
    text-transform: uppercase;
    font-size: 12px;
    color: $color-text-grey-light;
  }
</style>
