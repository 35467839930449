<template>
  <div :class="classes">
    <div class="metric__header">
      <div class="metric__label">{{label}}</div>
      <div class="metric__value">{{modelValue}}</div>
    </div>
    <div class="metric__info">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Metric',
    props: {
      label: [String, Number],
      modelValue: [String, Number],
      sentiment: {
        default: 'default',
        validator: (value) => ['default', 'warning', 'success', 'info'].includes(value),
      },
      orientation: {
        type: String,
        default: 'vertical',
      }
    },
    computed: {
      classes() {
        return [
          'metric',
          `metric--${this.orientation}`,
          `metric--${this.sentiment}`,
        ];
      },
    }
  }
</script>

<style lang="scss">
  @import "../../../scss/variables";

  .metric {
    background-color: $color-background-lightest-grey;
    padding: 0.5rem;
    width: 100%;

    .divider {
      opacity: 0.6;
      margin: 0.5rem 0;
    }
  }

  .metric--horizontal {
    .metric__header {
      display: flex;
      align-items: center;
    }

    .metric__label,
    .metric__value {
      flex: 1;
      margin: 0;
    }

    .metric__value {
      text-align: right;
    }
  }

  .metric__label {
    color: $color-text-grey-light;
    text-transform: uppercase;
    font-size: 0.625rem;
    margin-bottom: 0.5rem;
  }

  .metric__value {
    font-size: 1rem;
    font-weight: 500;
  }

  .metric__info {
    font-size: 0.75rem;
    color: $color-text-grey-light;
  }
</style>
