<template>
    <div class="call-to-action-card">
      <NewCard :title="title" fillAvailableHeight>
        <div v-if="image && image.length > 0" class="call-to-action-card__image" :style="cardBackgroundImage"></div>
        <br>
        <p v-if="text && text.length > 0" class="call-to-action-card__lable" v-html="text"></p>
        <br>
        <div class="call-to-action-card__action-container">
          <slot name="callToActionSlot" ></slot>
        </div>
      </NewCard>
    </div>
</template>

<script>
  import NewCard from '../../components/NewCard/NewCard.vue';

  export default {
    name: 'CallToActionCard',
    props: {
      title: String,
      text: String,
      buttonText: String,
      image: String,
      buttonVariant: {
        type: String,
        default: 'default'
      }
    },
    computed: {
        cardBackgroundImage () {
            return `background-image: url(${this.image});`
        }
    },
    components: {
      NewCard,
    }
  }
</script>

<style lang="scss">
  @import "../../../scss/_variables.scss";

  .call-to-action-card {
    height: 100%;
    .call-to-action-card__image {
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center center;
      min-height: 100px;
    }
    .call-to-action-card__lable {
      text-align: center;
      font-family: $font-stack-primary;
      margin-bottom: 0;
    }
    .call-to-action-card__action-container {
      display:flex;
      flex-flow: column;
      align-items: center;
    }
    .image-inp  {
      border:none;
    }
  }

</style>
