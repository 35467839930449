<template>
  <div class="profile-container">
    <div class="profile-title" :class="{ 'align-left': showCards}">
      {{ title }}
    </div>
    <div class="profile-tabs-container">
      <div class="with-profile-tabs--tabs has-3-tabs" :class="{ 'align-left': showCards}">
        <router-link
            to="/profile/update#personal-home" class="profile-tab"
            v-if="availableTabs.includes('personal-home') && !this.isPersonalHomeDisabled"
            :title="$t('profile_settings_personal_home')"
            :class="{'selected': $route.hash === '#personal-home'}"
        >
          {{ $t('profile_settings_personal_home') }}
        </router-link>
        <router-link
            to="/profile/update#notification-settings" class="profile-tab"
            v-if="availableTabs.includes('notification-settings')"
            :title="$t('profile_settings_notification_settings')"
            :class="{'selected': $route.hash === '#notification-settings' || !$route.hash}"
        >
          {{ $t('profile_settings_notification_settings') }}
        </router-link>
        <router-link
            to="/profile/update#password-and-security" class="profile-tab"
            v-if="availableTabs.includes('password-and-security')"
            :title="$t('profile_settings_password')"
            :class="{'selected': $route.hash === '#password-and-security'}"
        >
          {{ $t('profile_settings_password') }}
        </router-link>
        <router-link
            to="/profile/subscription#upgrade" class="profile-tab"
            v-if="availableTabs.includes('upgrade')"
            :title="$t('upgrade')"
            :class="{'selected': $route.hash === '#upgrade' || !$route.hash}"
        >
          {{ $t('upgrade') }}
        </router-link>
        <router-link
            :to="{ name: 'profile', params: { panel: 'subscription'}, hash: '#billing' }"
            class="profile-tab"
            v-if="availableTabs.includes('billing')"
            :title="$t('profile_settings_billing')"
            :class="{'selected': $route.hash === '#billing'}"
        >
          {{ $t('profile_settings_billing') }}
        </router-link>
        <router-link
            to="/profile/subscription#invoices" class="profile-tab"
            v-if="availableTabs.includes('invoices')"
            :title="$t('profile_settings_invoices')"
            :class="{'selected': $route.hash === '#invoices'}"
        >
          {{ $t('profile_settings_invoices') }}
        </router-link>
      </div>
    </div>
    <div class="profile-cards" v-if="showCards">
      <div class="profile-card profile-card--user">
        <div class="profile-card--user-info-container">
          <div class="profile-card--user__image-section">
            <div class="image-upload-container">
              <avatar
                  :src="user.photo_url" :alt="user.name" allow-edit
                  :edit-text="'Upload Avatar'"
              />
              <image-input
                  v-model="user.photo_url" @update:modelValue="updateProfilePicture"
                  width="100" height="100"
              />
            </div>
            <br>
          </div>
          <div>
            <div class="profile-card--name">
              {{ user.name }}
            </div>
            <div class="profile-card--social-buttons">
              <UserSocialButtons :user="userBaseInfoData" v-if="userBaseInfoData"/>
            </div>
          </div>
        </div>
        <!--        <div class="profile-card&#45;&#45;role">-->
        <!--          this space will contain the role field, which will be added in the "onboarding" release-->
        <!--        </div>-->
        <hr v-if="user.bio"/>
        <div class="profile-card--bio">
          {{ user.bio }}
        </div>
        <ds-button
            size="small" variant="rounded" :label="$t('profile_settings_update')"
            @click="showUpdateProfileModal"
        />
      </div>
      <div class="profile-card profile-card--claims" v-if="showClaims" id="introjs-after-onboarding-actors">
        <div class="profile-card--title">
          {{ $t('profile_settings_my_actors') }}
        </div>
        <br>
        <p v-if="!nonProductClaims || !nonProductClaims.length">
          {{ onboardingTextConfig.myActorsEmptyText }}
        </p>
        <div class="profile-card--claims">
          <div
              class="profile-card--claim-container"
              v-for="(claim, index) in slicedNonProductClaims" :key="'actor' + index"
          >
            <div class="profile-card--claim">
              <div
                  :style="imageStyle(claim)" :src="companyLogoUrl(claim)"
                  class="profile-card--actor-logo" :alt="claim.name"
                  v-if="companyLogoUrl(claim)"
              />
              <div class="profile-card--actor-logo" v-else>
                <span v-if="claim.name">{{ claim.name }}{{ index }}</span>
              </div>
              <div class="profile-card--claim-text">
                <div class="profile-card--claim-name">
                  {{ claim.name }}
                </div>
                <router-link
                    :to="'/actors/' + claim.id" class="profile-card--claim-url"
                    @click="trackHeapEvent('viewClaimedActor', {name: claim.name})"
                >
                  {{ $t('profile_settings_actor_view') }}
                </router-link>
              </div>
            </div>
            <hr
                v-if="index < (nonProductClaims.length - 1) || nonProductClaims.length > 5"
            >
            <a
                v-if="nonProductClaims.length > 5 && index === (slicedNonProductClaims.length - 1)"
                @click="showMoreNonProductClaims = !showMoreNonProductClaims"
            >{{
                showMoreNonProductClaims ? $t('actor_detail_show_less') : $t('actor_detail_show_all')
              }}</a>
          </div>
        </div>
        <br v-if="showAddActorButton && slicedNonProductClaims && slicedNonProductClaims.length">
        <ds-button
            size="small" variant="rounded"
            :label="$t('profile_settings_add_actor')"
            @click="showCreateActorModal" v-if="showAddActorButton"
        />
      </div>
      <div
          class="profile-card profile-card-products"
          v-if="showProductClaims"
      >
        <div class="profile-card--title">
          {{ $t('profile_settings_my_products', { products: productsLabel }) }}
        </div>
        <br>
        <p v-if="!slicedProductClaims || !slicedProductClaims.length">
          {{ onboardingTextConfig.myProductsEmptyText }}
        </p>
        <div class="profile-card--claims">
          <div
              class="profile-card--claim-container"
              v-for="(claim, index) in slicedProductClaims" :key="'product' + index"
          >
            <div class="profile-card--claim">
              <div class="profile-card--claim-text">
                <div class="profile-card--claim-name">
                  {{ claim.name }}
                </div>
                <router-link
                    :to="'/actors/' + claim.id" class="profile-card--claim-url"
                    @click="trackHeapEvent('viewClaimedProduct', {name: claim.name})"
                >
                  {{ $t('profile_settings_product_view') }}
                </router-link>
              </div>
            </div>
            <hr v-if="index < (productClaims.length - 1) || productClaims.length > 5">
            <a
                v-if="productClaims.length > 5 && index === (slicedProductClaims.length - 1)"
                @click="showMoreProductClaims = !showMoreProductClaims"
            >{{
                showMoreProductClaims ? $t('actor_detail_show_less') : $t('actor_detail_show_all')
              }}</a>
          </div>
        </div>
        <br v-if="slicedProductClaims && slicedProductClaims.length">
        <!--        {{actors}}-->
        <ds-button
            size="small" variant="rounded"
            :label="$t('add_product', { product: productLabel })"
            v-if="showAddProductButton"
            style="text-align: center"
            @click="nonProductClaims.length === 0 ? showRestrictedModal() : showAddProductModal() "
        />
      </div>
      <!--get "my content" before showing this-->
      <div class="profile-card profile-card-knowledge" v-if="displayKnowledgeBase">
        <div class="profile-card--title">
          {{ $t('profile_settings_my_knowledge') }}
        </div>
        <br>
        <p v-if="!myResources || !myResources.length">
          {{ onboardingTextConfig.myKnowledgeEmptyText }}
        </p>
        <div class="profile-card--claims">
          <div
              class="profile-card--claim-container"
              v-for="(resource, index) in myResources" :key="'resources' + index"
          >
            <div class="profile-card--claim">
              <div class="profile-card--claim-text" style="width: 100%;">
                <div class="profile-card--claim-name" style="display: flex; justify-content: space-between;">
                  <div>
                    {{ resource.title }}
                  </div>
                  <div>
                    <icon
                        v-if="resource.approved === false" name="eye-slash" title="In curation"
                        style="margin-left: 10px;"
                    />
                    <icon v-else name="eye" title="Approved" style="margin-left: 10px;"/>
                    <icon v-if="resource.is_private" name="lock" title="Private" style="margin-left: 10px;"/>
                  </div>
                </div>
                <a
                    class="profile-card--claim-url"
                    target="_blank"
                    :href="getResourceUrl(resource)"
                >
                  {{ $t('profile_settings_resource_view') }}
                </a>
              </div>
            </div>
            <hr v-if="index < myResources.length - 1">
          </div>
        </div>
        <br v-if="myResources && myResources.length">
        <ds-button
            size="small" variant="rounded"
            :label="$t('profile_settings_add_resource')"
            @click="showCreateResourcePanel" v-if="canCreateResource"
        />
      </div>
      <div
          class="profile-card profile-card-communities"
          v-if="$store.getters.canUsePublicAnnouncements && $store.getters.hasAccessToCommunities && communities && communities.length"
      >
        <div class="profile-card--title">
          {{ $t('profile_settings_my_communities') }}
        </div>
        <br>
        <div class="profile-card--claims">
          <div
              class="profile-card--claim-container"
              v-for="(community, index) in slicedCommunities" :key="'product' + index"
          >
            <div class="profile-card--claim profile-card--communities">
              <div class="profile-card--claim-text profile-card--communities-text">
                <div class="profile-card--claim-name">
                  {{ community.name }}
                </div>
                <span class="profile-card--communities-warning" v-if="!hasCommunityAccessBasedOnAccessLevel(community)">
                  <icon name="warning" size="14"></icon>
                  {{ $t('communities_warning_claim_actor_short') }}
                </span>
                <div class="profile-card--communities-actions">
                  <a class="profile-card--claim-url" style="margin-top: 1rem;" @click="viewCommunity(community)">
                    {{ $t('profile_settings_community_view') }}
                  </a>
                  <DsButton
                      :label="isCommunityManager(community) ? $t('communities_manage') : $t('communities_leave')"
                      variant="rounded"
                      size="small"
                      @click="isCommunityManager(community) ? $router.push('/communities/' + community.id + '/members') : openConfirmationModal(community)"
                  />
                  <!--                      v-tooltip.top=" ? 'You cannot leave the community because you are the manager' : ''"-->
                </div>
              </div>
            </div>
            <hr v-if="index < (communities.length - 1) || communities.length > 5">
            <a
                v-if="communities.length > 5 && index === (slicedCommunities.length - 1)"
                @click="showMoreCommunities = !showMoreCommunities"
            >{{
                showMoreCommunities ? $t('actor_detail_show_less') : $t('actor_detail_show_all')
              }}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="with-profile-tabs--content">
      <slot/>
    </div>
  </div>
</template>


<script>

import Avatar from '../../components/Avatar/Avatar.vue'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
import MODAL_IDS from '../../constants/modal-ids.js'
import ImageInput from '../../components/Form/ImageInput.vue'
import { fetchProfile, getLastUploadedFiles, updateProfile, } from '../../api/user.js'
import { MUTATION_TYPES as USER_MUTATION_TYPES } from '../../store/modules/user-helpers.js'
import { prepareUserForEditing } from '../../util/user-settings.js'
import { trackHeapEvent } from '../../util/analytics.js'
import { actorLogo } from '../../util/actor'
import TranslationsMixin from '../../util/TranslationsMixin.js'
import { SiteTemplate } from '../../store/modules/config.js'
import OnboardingMixin from '../../util/OnboardingMixin.js'
import AddProductWarningModal from '../../components/Modals/AddProductWarningModal.vue'
import { ACTION_TYPES as COMMUNITY_ACTION_TYPES } from '../../store/modules/communities.js'
import { MUTATION_TYPES as FILTERS_MUTATION_TYPES } from '../../store/modules/filters'
import { updateActor } from '../../api/actors.js'
import UserSocialButtons from '@/Domain/User/Components/UserSocialButtons.vue'
import { transformLoggedInUserToUserBaseInfoData } from '@/Domain/User/Api/UserApiTransformers'
import CommunityMixin from '@/util/CommunityMixin'
import { leaveCommunity } from '@/api/communities.js'


export default {
  props: {
    availableTabs: {
      type: Array,
      default: []
    },
    title: {
      type: String
    },
    showCards: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      updatedImage: null,
      user: this.currentUser(),
      showMoreNonProductClaims: false,
      showMoreProductClaims: false,
      showMoreCommunities: false,
      showMoreResources: false,
      myResources: [],
      restricted: '',
    }
  },
  computed: {
    userBaseInfoData () {
      if (!this.originalProfile?.id) {
        return null
      }

      return transformLoggedInUserToUserBaseInfoData(this.originalProfile)
    },
    modalContext () {
      return this.$store.state.ui.modalContext
    },
    productsUrl () {
      if (this.isSimplifiedTemplateUsed) {
        return '/products-simplified'
      } else {
        return '/product-gallery'
      }
    },
    isOwner () {
      return this.$store.getters.isOwner
    },
    isSimplifiedTemplateUsed () {
      return [SiteTemplate.NEW_SIMPLIFIED, SiteTemplate.SIMPLIFIED].includes(this.$store.getters.activeSiteTemplate)
    },
    displayKnowledgeBase () {
      return this.$store.getters.hasAccessToKnowledgeBase && this.$store.state.config.viewDashboard && this.$store.state.config.viewDashboard.includes('knowledge-base') && ((this.myResources && this.myResources.length) || this.canCreateResource)
    },
    canCreateResource () {
      return this.$store.getters.canUserUploadContent
    },
    isProductEnabled () {
      return this.$store.getters.viewActorTypes.includes('Product')
    },
    isPersonalHomeDisabled () {
      return !!this.$store.state.config.personalHomeDisabled
    },
    originalProfile () {
      return this.$store.state.user.profile
    },
    slicedNonProductClaims () {
      if (this.showMoreNonProductClaims) {
        return this.nonProductClaims
      }
      return this.nonProductClaims.slice(0, 5)
    },
    nonProductClaims () {
      if (!this.originalProfile || !this.originalProfile.claims) {
        return []
      }
      return this.checkForDuplicates(this.originalProfile.claims.filter(claim => claim.actor_type !== 'Product'))
    },
    showClaims () {
      if (this.nonProductClaims.length > 0) {
        return true
      }
      return this.$store.getters.userCanCreate &&
          (this.$store.getters.claimableActorTypes.includes('LegalEntity') || this.$store.getters.claimableActorTypes.includes('Person'))
    },
    showProductClaims () {
      if (this.nonProductClaims.length > 0) {
        return true
      }
      return this.$store.getters.userCanCreate && this.$store.getters.claimableActorTypes.includes('Product')
    },
    productClaimsFromActors () {
      let result = []
      if (this.nonProductClaims.length > 0) {
        for (const nonProductClaim of this.nonProductClaims) {
          if (nonProductClaim && nonProductClaim.products && nonProductClaim.products.length) {
            result = [...result, ...nonProductClaim.products]
          }
        }
      }
      return result
    },
    slicedProductClaims () {
      if (this.showMoreProductClaims) {
        return this.productClaims
      }
      return this.productClaims.slice(0, 5)
    },
    productClaims () {
      if (!this.originalProfile || !this.originalProfile.claims) {
        return []
      }
      return this.checkForDuplicates([...this.productClaimsFromActors, ...this.originalProfile.claims.filter(claim => claim.actor_type === 'Product')])
    },
    showAddActorButton () {
      if (this.isMember) {
        return true
      }
      return this.$store.getters.userCanCreate && this.$store.getters.claimableActorTypes && this.$store.getters.claimableActorTypes.length > 0
    },
    showAddProductButton () {
      if (this.isMember) {
        return true
      }
      return this.$store.getters.userCanCreate && this.$store.getters.claimableActorTypes && this.$store.getters.claimableActorTypes.includes('Product')
    },
    isMember () {
      return this.$store.getters.isMember
    },
    slicedCommunities () {
      if (this.showMoreCommunities) {
        return this.communities
      }

      return this.communities.slice(0, 5)
    },
    communities () {
      return this.joinedCommunities
    },
    products () {
      return this.$store.state.actors.productData
    },
    productsTitle () {
      return this.$t('shp_latest_products', { products: this.productsLabel, interpolation: { escapeValue: false } })
    },
    actors () {
      return this.$store.state.actors.listData
    },
  },
  methods: {
    openConfirmationModal (community) {
      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
        title: this.$t('communities_leave_confirmation_title', { community: community.name }),
        body: this.$t('communities_leave_confirmation_message'),
        confirmLabel: this.$t('personal_home_relevant_yes'),
        modalBodyClasses: 'empty-fields-list',
        type: 'check-empty-fields',
        cancelLabel: this.$t('personal_home_relevant_no'),
        isCancellable: true,
        communityId: community.id,
      })

      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.CONFIRMATION)
    },
    leaveCommunity () {
      leaveCommunity(this.modalContext?.communityId)
          .then(() => {
            fetchProfile()
                .then(profile => {
                  this.$store.commit('USER/UPDATE_PROFILE', profile)
                  this.$bus.emit('updateUserProfile')
                })
          })

      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
    },
    getResourceUrl (resource) {
      if (!resource.mimetype) {
        return ''
      }

      if (resource.mimetype === 'video_link' || resource.mimetype === 'link') {
        return resource.originalName
      }

      if (resource.actor_id && resource.actor_id.length > 0) {
        return `${window.location.origin}/api/actors/${resource.actor_id}/files/${resource.id}`
      }

      return `${window.location.origin}/api/files/${resource.id}`
    },
    getLastUploadedFiles () {
      getLastUploadedFiles().then((result) => {
        this.myResources = result
      })
    },
    viewCommunity (community) {
      this.$store.commit(FILTERS_MUTATION_TYPES.CLEAR)
      this.$store.commit(FILTERS_MUTATION_TYPES.SET_PORTFOLIO, community.portfolioId)

      this.$router.push('/communities/' + community.id)
    },
    showCreateResourcePanel () {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {
        component: 'create-resource-panel',
        metaData: { reach: 'public' },
      })
    },
    imageStyle (claim) {
      if (this.companyLogoUrl(claim)) {
        return { backgroundImage: `url(${this.companyLogoUrl(claim)})` }
      }
    },
    companyLogoUrl (claim) {
      return actorLogo(claim)
    },
    trackHeapEvent,
    currentUser () {
      const profile = this.$store.state.user.profile
      return prepareUserForEditing(profile)
    },
    updateProfilePicture () {
      updateProfile({ photo_url: this.user.photo_url })
          .then(data => {
            this.errors = {}
            this.$store.commit(USER_MUTATION_TYPES.UPDATE_PROFILE, data)
            this.user = this.currentUser()
            this.$emit('updatedProfilePicture')
            trackHeapEvent('updateProfilePicture.success')
          })
          .catch(errors => {
            this.errors = errors
            trackHeapEvent('updateProfilePicture.failure', { errorMessage: JSON.stringify(errors) })
          })
    },
    showCreateActorModal () {
      trackHeapEvent('clickAddActorOnUserProfile')

      if (this.$store.getters.isPublisherEnabled) {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, { forceClaim: true })
      }
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_ACTOR)
    },
    showUpdateProfileModal () {
      trackHeapEvent('clickUpdateProfile')
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.EDIT_USER_PROFILE)
    },
    loadCommunities () {
      if ((!this.communities || !this.communities.length) && !this.$store.state.communities.listData.loading) {
        this.$store.dispatch(COMMUNITY_ACTION_TYPES.FETCH_COMMUNITIES_LIST)
      }
    },
    showRestrictedModal () {
      trackHeapEvent('restrictedModal')
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.RESTRICTED_MODAL)
    },
    showAddProductModal () {
      trackHeapEvent('addProduct')
      const modalContext = {
        prefilled: {},
        successCallback: actor => {
          this.$bus.emit('productAddedViaOverlay', actor)
        },
      }

      if (this.$store.getters.detailActor && this.$store.getters.detailActor.address && (this.$store.getters.detailActor.address.street || this.$store.getters.detailActor.address.city)) {
        modalContext.prefilled.address = {
          ...this.$store.getters.detailActor.address,
        }
        modalContext.prefilled.parentName = this.$store.getters.detailActor.name
      }

      this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, modalContext)
      this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.ADD_PRODUCT)
    },
    checkForDuplicates (array) {
      const filteredArray = []
      const claims = array.map(function (item) {
        return item.name
      })
      const filteredClaims = [...new Set(claims)]

      filteredClaims.map(function (value) {
        // Find
        const claim = array.find(function (item) {
          return item.name === value
        })
        // Set
        if (!filteredArray.includes(claim)) {
          filteredArray.push(claim)
        }
      })
      return filteredArray
    }
  },
  components: {
    Avatar,
    ImageInput,
    AddProductWarningModal,
    UserSocialButtons,
  },
  mounted () {
    if (this.$route.hash === '#personal-home' && this.isPersonalHomeDisabled) {
      this.$router.push('/profile/update#notification-settings')
    }

    this.$bus.on('confirmAction', this.leaveCommunity)

    this.$bus.on('productAddedViaOverlay', (actor) => {
      const relation = [...actor.existingProductClaimsOfParent] || []

      relation.push({
        to: actor.id,
        to_name: actor.name,
        start: null,
        end: null,
      })

      updateActor({
        id: actor.parentId,
        data: {
          'has_product': relation,
        },
      }).then(() => {
        fetchProfile()
            .then(profile => {
              this.$store.commit('USER/UPDATE_PROFILE', profile)
              this.$bus.emit('updateUserProfile')
            })
      })
    })

    this.$bus.on('updateUserProfile', () => {
      this.user = this.currentUser()
    })

    this.$bus.on('fetchNewResources', () => {
      this.getLastUploadedFiles()
    })

    if (this.$store.getters.hasAccessToCommunities) {
      this.loadCommunities()
    }

    this.getLastUploadedFiles()

    fetchProfile()
        .then(profile => {
          this.$store.commit('USER/UPDATE_PROFILE', profile)
          this.$bus.emit('updateUserProfile')
        })
  },
  beforeUnmount () {
    this.$bus.off('updateUserProfile')
    this.$bus.off('fetchNewResources')
    this.$bus.off('confirmAction')
  },
  mixins: [TranslationsMixin, OnboardingMixin, CommunityMixin],
}
</script>

<style lang="scss" scoped>
@import "../../../scss/variables";

$profileCardsWidthXL: 350px;
$profileCardsWidthLG: 300px;
$profileWidthXL: 1130px;
$profileWidthLG: 970px;
.profile-container {
  position: relative;
  height: 100%;
  overflow: auto;
  transition: height .3s;

  hr {
    flex-grow: 1;
    height: 0;
    border: 1px solid var(--primary-extra-lightest);
  }
}

.profile-card--social-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.profile-title {
  width: 100%;
  background-color: var(--primary);
  color: white;
  font-size: 26px;
  height: 150px;
  line-height: 150px;
  text-align: center;

  &.align-left {
    padding-right: calc($profileCardsWidthXL / 2);
  }
}

.image-upload-container {
  position: relative;
  display: flex;

  &:hover {
    :deep(.avatar) {
      .edit-icon {
        display: flex;
      }
    }
  }

  :deep(.image-inp) {
    width: 100px;
    border: 0;
    position: absolute;
    left: calc(50% - 50px);
    height: 80px;
    opacity: 0;
    z-index: 1;
  }
}

.image-upload-container {
  :deep(.avatar) {
    height: 80px;
    width: 80px;
    border: 1px solid var(--primary);
    margin: 0 auto;
    background-color: black;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.profile-tabs-container {
  background-color: var(--primary-extra-lightest);

  .with-profile-tabs--tabs {
    display: flex;

    .profile-tab {
      color: var(--primary);
      width: 33.3333%;
      height: 50px;
      line-height: 50px;
      cursor: pointer;
      padding: 0 20px;
      text-decoration: none;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;


      &:hover, &.selected {
        background: var(--primary-lightest);
        font-weight: 500;
      }
    }

    &.has-3-tabs {
      .profile-tab {
        @media (min-width: $screen-lg) {
          width: 33%;
        }
      }

      &.align-left {
        .profile-tab {
          @media (min-width: $screen-lg) {
            width: 25%;
          }
        }
      }
    }
  }
}

.profile-cards {
  @media (min-width: $screen-lg) {
    position: absolute;
    top: 100px;
    left: calc(50% + calc($profileCardsWidthXL / 2));
  }

  .profile-card {
    background: white;
    border-radius: $default-border-radius;
    width: 100%;
    margin-bottom: 20px;
    box-shadow: $simplified-box-shadow-config;
    word-break: break-word;
    display: flex;
    flex-direction: column;
    padding: 14px;

    &.profile-card--user {
      position: relative;

      .button {
        background-color: var(--primary);
        color: white;
        font-weight: 500;

        &:hover {
          background-color: var(--primary-darker);
          color: white;
        }
      }

    }

    .profile-card--user-info-container {
      @media (max-width: $screen-lg) {
        display: flex;
        justify-content: space-evenly;

        .profile-card--user__image-section {
          justify-content: center;
          flex-direction: column;
          display: flex;
          margin: 20px 0;
        }
      }
    }

    .profile-card--title {
      text-align: left;
      color: var(--primary);
      font-weight: 500;
    }

    .profile-card--name {
      text-align: center;
      color: var(--primary);
      font-weight: 500;
      font-size: 16px;
      margin-bottom: 5px;
    }

    .profile-card--role {
      text-align: center;
      font-size: 12px;
      margin: 5px 0;
    }

    .profile-card--bio {
      font-size: 12px;
      line-height: 16px;
      margin: 10px 0;
    }

    .profile-card--social-logos {
      display: flex;
      justify-content: space-evenly;
      margin: 0 auto 10px;
      width: 100px;

      :deep(g), :deep(path) {
        stroke: var(--primary);
        fill: var(--primary);
      }

      .svg-icon--instagram-logo {
        margin-top: 3px;
      }
    }

    .profile-card--communities {
      width: auto;

      .profile-card--communities-text {
        width: 100%;

        .profile-card--communities-warning {
          font-size: 12px;
          margin-top: 0.25rem;
        }

        .profile-card--communities-actions {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 0.75rem;
        }
      }
    }

    .profile-card--claims {
      .profile-card--claim-container {
        .profile-card--claim {
          display: flex;

          a {
            text-decoration: underline;
          }

          .profile-card--claim-text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-left: 12px;

            .profile-card--claim-name {
              font-weight: 500;
            }
          }

        }

        hr {
          margin: 10px 0;
        }
      }
    }
  }
}

.profile-card--actor-logo {
  z-index: 3;
  border: 1px solid var(--primary-lightest);
  height: 60px;
  width: 60px;
  background-color: white;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  color: black;
  display: flex;
  justify-content: center;
  align-items: center;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    line-height: 14px;
    text-align: center;
    max-height: 56px;
    width: 100%;
  }
}

.with-profile-tabs--content, .with-profile-tabs--tabs {
  margin: 0 auto;
}

p {
  margin-bottom: 10px;
  text-align: center;
}

@media (min-width: $screen-xl) {
  .with-profile-tabs--content, .with-profile-tabs--tabs {
    &.align-left {
      padding-right: calc($profileCardsWidthXL / 2);
    }

    width: $profileWidthXL;
  }
  .profile-cards {
    margin-right: calc(-1 * (($profileWidthXL / 3) - $profileCardsWidthXL));
    width: $profileCardsWidthXL;
  }
}

@media (max-width: $screen-xl) {
  .with-profile-tabs--content, .with-profile-tabs--tabs {
    &.align-left {
      padding-right: calc($profileCardsWidthLG / 2);
    }

    width: $profileWidthLG;
  }
  .profile-cards {
    margin-right: calc(-1 * (($profileWidthLG / 3) - $profileCardsWidthLG));
    width: $profileCardsWidthLG;
    left: calc(50% + calc($profileCardsWidthLG / 2));
  }
  .profile-title.align-left {
    padding-right: calc($profileCardsWidthLG / 2);
  }
}

@media (max-width: $screen-lg) {
  .with-profile-tabs--content, .with-profile-tabs--tabs {
    &.align-left {
      padding-right: 0;
    }

    width: 100%
  }
  .profile-cards {
    margin: 0 auto;
    width: auto;
    max-width: 590px;
    padding: 20px 20px 0 20px;
  }
}

@media (max-width: $screen-md) {
  .profile-title {
    padding-right: 0;
  }
}

a {
  cursor: pointer;
}
</style>
