import { createJSONRequest, createResource } from './create-request'

export const EcosystemRelationshipsMetaDataLists = createResource('/api/relationship-meta-data')

export const linkRelationshipWithMetaDataList = (relationshipId, listId) => createJSONRequest({
  method: 'PUT',
  url: '/api/link-relationship-meta-data',
  data: {
    relationshipId,
    listId,
  },
})

export const countTagUsage = (listId, tag) => createJSONRequest({
  url: `/api/relationship-meta-data/${listId}/tag-count?tag=${tag}`,
  method: 'GET',
})

export const deleteTag = (listId, tag) => createJSONRequest({
  url: `/api/relationship-meta-data/${listId}/tags?tag=${tag}`,
  method: 'DELETE',
})

export const updateTag = (listId, oldTag, newTag) => createJSONRequest({
  url: `/api/relationship-meta-data/${listId}/tags?tag=${oldTag}`,
  method: 'PUT',
  data: {
    updatedTag: newTag,
  },
})
