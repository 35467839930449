<template>
  <div>
    <div @click="selectOption(object.value)" class="multiselect--filter__data" v-for="object in data">
      <input :checked="isChecked(object.value)" class="checkbox--multiselect" type="checkbox" name="include"/>
      <div class="multiselect__content">
        <avatar v-if="object.image" class="multiselect__content--profile_picture" :src="object.image" :alt="object.label"/>
        <icon v-if="object.icon" class="multiselect__content--profile_picture icon" :name="object.icon" size="14"/>
        <p class="multiselect__content--name">{{ object.label }}</p>
      </div>
    </div>
  </div>
</template>

<script>
  import '../../../scss/_variables.scss'
  import Avatar from '../Avatar/Avatar.vue'

  export default {
    name: 'MultiSelectFilter',
    emits: ['ProofPointFilterChanged'],
    props: {
      data: {
        type: Array,
        default: []
      },
      type: {
        type: String,
        default: null
      },
      activeData: {
        type: Array,
        default: []
      }
    },
    computed: {},
    data () {
      return {
        active: []
      }
    },
    methods: {
      selectOption (value) {
        if (this.active.includes(value)) {
          this.active = this.active.filter(val => val !== value)
        } else {
          this.active.push(value)
        }

        this.$emit('ProofPointFilterChanged', { active: this.active, type: this.type })
      },
      isChecked (value) {
        return this.active.includes(value)
      }
    },
    mounted () {
      this.activeData.map((active) => {
        this.active.push(active)
      })
    },
    components: {
      Avatar
    }
  }
</script>

<style scoped lang="scss">
  .multiselect--filter__data {
    display: flex;
    padding: 15px;
    border-bottom: 1px solid lightgrey;

    &:last-child {
      border-bottom: none;
    }

    &:hover {
      &:last-child {
        border-bottom: none;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      &:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      background-color: #f9f9f9;
      cursor: pointer;
    }

  }

  .checkbox--multiselect {
    padding: 6px;
  }

  .multiselect__content {
    display: flex;
    margin-left: 20px;

    :deep(.icon) {
      width: 15px;
      height: 15px;
    }

    :deep(.avatar__text) {
      border: 1px solid black;
      padding: 8px;
      border-radius: 15px;
      width: 30px;
      height: 30px;
    }
  }

  .multiselect__content--profile_picture {
    width: 30px;
    height: 30px;
    border-radius: 20px;
    margin-left: 10px;
  }

  .multiselect__content--name {
    margin-left: 10px;
    padding: 6px;
  }

  .icon {
    margin-top: 5px;
  }

</style>
