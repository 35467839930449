<template>
  <div class="ui dropdown">
    <div class="text"><slot name="trigger"></slot></div>
    <div class="menu">
      <slot name="menu"></slot>
    </div>
  </div>
</template>

<script lang="ts">
  import $ from 'jquery'
import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'SemanticDropdown',
    mounted () {
      $(this.$el).dropdown()
    }
  })
</script>

<style lang="scss">

</style>
