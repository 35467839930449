<template>
  <div>
    <div class="saving-and-result__header">
      <h2>We are processing all of your valuable input...</h2>
    </div>
    <div>
      <p>
        We are tapping into hundreds of data sources about the subject.<br>
        Feel free to explore the platform & results while we are still processing the
        research.
      </p>
      <p>
        Processing all info can take up to 30 minutes.<br>
        We will notify you when all information is processed and the most relevant results are available in your DataScouts environment!
      </p>
    </div>
    <loading v-if="response === null"/>
    <div class="saving-and-result__button">
      <ds-button label="View results" @click="handleDone" :disabled="response === null"/>
    </div>
  </div>
</template>

<script>
  import Loading from '../Dashboard/ConceptMap/Loading.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      response: {
        type: Object,
      },
    },
    methods: {
      handleDone () {
        this.$emit('done')
      },
    },
    components: {
      Loading,
    }
  })
</script>

<style lang="scss" scoped>
  .saving-and-result__header {
    margin-bottom: 20px;
  }

  .saving-and-result__button {
    display: flex;
    margin-top: auto;
    padding-top: 20px;
  }
</style>
