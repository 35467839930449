<template>
  <div>
    <form-group v-if="hasAccessToNewSpottingAreas" label="Configure what spotting areas will be listed under 'Inspire' on the 'Personal Home' tab of the User Profile (max 3)">
      <AutocompleteTagInput
        :tags="selectedSpottingAreas"
        :options="spottingAreaOptions"
        :addOnlyFromAutocomplete="true"
        placeholder="Search for a spotting area"
        @tagChanged="updateSpottingAreaOptions"
        @input:raw="updateSelectedSpottingAreasFromInput"
        :minInputLength="1"
        style="width: 440px;"
        :max-tags="3"
      />
    </form-group>

    <form-group v-else label="Configure what portfolios will be listed under 'Inspire' on the 'Personal Home' tab of the User Profile (max 3)">
      <AutocompleteTagInput
        :tags="selectedPortfolios"
        :options="portfolioOptions"
        :addOnlyFromAutocomplete="true"
        placeholder="Search for a portfolio"
        @tagChanged="updatePortfolioOptions"
        @input:raw="updateSelectedPortfoliosFromInput"
        :minInputLength="1"
        style="width: 440px;"
        :max-tags="3"
      />
    </form-group>

    <form-group label="Configure what concept searches will be listed under 'Inform' on the 'Personal Home' tab of the User Profile (max 3)"
                v-if="$store.getters.hasAccessToMonitoring">
      <AutocompleteTagInput
        :tags="selectedConceptSearches"
        :options="conceptSearchOptions"
        :addOnlyFromAutocomplete="true"
        placeholder="Search for a concept search"
        @tagChanged="updateConceptSearchOptions"
        @input:raw="updateSelectedConceptSearchesFromInput"
        :minInputLength="1"
        style="width: 440px;"
        :max-tags="3"
      />
    </form-group>

    <action-bar :editing="true">
      <ds-button variant="secondary" label="Save changes" @click="save"/>
      <span class="action-bar__message" v-if="success">{{ success }}</span>
    </action-bar>
  </div>
</template>

<script>
  import debounce from 'lodash/throttle'
  import { fetchPortfolio } from '../../../api/portfolios'

  import AutocompleteTagInput from '../../Form/AutocompleteTagInput.vue'
  import { fetchPersonalisation, updatePersonalisation } from '../../../api/onboarding'
  import ActionBar from '../../Form/ActionBar.vue'
  import { fetchAllConceptSearches } from '../../../api/exploration'
  import { fetchSpottingAreas } from '../../../api/spottingareas'
  import { SPOTTING_AREA_TYPES } from '@/store/modules/config'

  export default {
    name: 'Personalisation',
    data () {
      return {
        portfolioOptions: [],
        spottingAreaOptions: [],
        conceptSearchOptions: [],
        selectedPortfolios: [],
        selectedSpottingAreas: [],
        selectedConceptSearches: [],
        success: null
      }
    },
    computed: {
      hasAccessToNewSpottingAreas () {
        return this.$store.getters.hasAccessToNewSpottingAreas
      },
    },
    methods: {
      updatePortfolioOptions: debounce((async function (query) {
        if (!query) {
          return
        }

        // This will search for a portfolio which matches the input value in the search field
        var filters = Object.assign({}, { query: query })

        fetchPortfolio(filters)
          .then(portfolios => {
            this.portfolioOptions = []

            this.parsePortfolioOptions(portfolios)
          }).catch(errors => {
          console.log(errors)
        })
      }), 150),
      parsePortfolioOptions (portfolios) {
        if (!Array.isArray(portfolios)) {
          return
        }

        var options = []

        portfolios.forEach(portfolio => {
          if (portfolio.virtual) {
            return
          }

          options.push({
            text: portfolio.name,
            id: portfolio.id,
          })
        })

        this.portfolioOptions = options
      },
      updateSpottingAreaOptions: debounce((async function () {
        fetchSpottingAreas({})
          .then(spottingAreas => {
            this.spottingAreaOptions = []

            this.parseSpottingAreaOptions(spottingAreas)
          }).catch(errors => {
          console.log(errors)
        })
      }), 150),
      parseSpottingAreaOptions (spottingAreas) {
        if (!Array.isArray(spottingAreas)) {
          return
        }

        var options = []

        spottingAreas.forEach(spottingArea => {
          let showEye = true
          let invalidOption = spottingArea.type === SPOTTING_AREA_TYPES.PREMIUM || spottingArea.type === SPOTTING_AREA_TYPES.PRIVATE

          options.push({
            text: spottingArea.name,
            id: spottingArea.id,
            showEye: showEye,
            invalidOption: invalidOption,
          })
        })

        this.spottingAreaOptions = options
      },
      updateConceptSearchOptions: debounce((async function (query = '') {
        fetchAllConceptSearches({ q: query })
          .then(response => {
            this.conceptSearchOptions = response.map(search => {
              return {
                text: search.title,
                id: search.id,
              }
            })
          })
          .catch(error => {
            console.log(error)
          })
      }), 150),
      updateSelectedPortfoliosFromInput (input) {
        this.selectedPortfolios = input
      },
      updateSelectedPortfoliosFromApiCall (portfolios) {
        var tmp = []

        portfolios.forEach(portfolio => {
          tmp.push({
            text: portfolio.name,
            optionValue: {
              id: portfolio.id,
              text: portfolio.name
            }
          })
        })

        this.selectedPortfolios = tmp
      },
      updateSelectedSpottingAreasFromInput (input) {
        this.selectedSpottingAreas = input
      },
      updateSelectedSpottingAreasFromApiCall (spottingAreas) {
        var tmp = []

        spottingAreas.forEach(spottingArea => {
          tmp.push({
            text: spottingArea.name,
            optionValue: {
              id: spottingArea.id,
              text: spottingArea.name
            }
          })
        })

        this.selectedSpottingAreas = tmp
      },
      updateSelectedConceptSearchesFromInput (input) {
        this.selectedConceptSearches = input
      },
      updateSelectedConceptSearchesFromApiCall (searches) {
        var tmp = []

        searches.forEach(search => {
          tmp.push({
            text: search.name,
            optionValue: {
              id: search.id,
              text: search.name
            }
          })
        })

        this.selectedConceptSearches = tmp
      },
      save () {
        this.success = null

        const portfolios = this.selectedPortfolios.map(portfolio => {
          return {
            id: portfolio.optionValue.id,
            text: portfolio.text
          }
        })

        const spottingAreas = this.selectedSpottingAreas.map(spottingArea => {
          return {
            id: spottingArea.optionValue.id,
            text: spottingArea.text
          }
        })

        const conceptSearches = this.selectedConceptSearches.map(search => {
          return {
            id: search.optionValue.id,
            text: search.text
          }
        })

        updatePersonalisation({ portfolios, spotting_areas: spottingAreas, concept_searches: conceptSearches })
          .then(response => {
            this.updateSelectedPortfoliosFromApiCall(response.portfolios)
            this.updateSelectedSpottingAreasFromApiCall(response.spotting_areas)
            this.updateSelectedConceptSearchesFromApiCall(response.concept_searches)

            this.success = 'Settings saved successfully'
          })
          .catch(error => {
            console.log(error)
          })
      }
    },
    async mounted () {
      try {
        var personalisation = await fetchPersonalisation()

        this.updateSelectedPortfoliosFromApiCall(personalisation.portfolios)
        this.updateSelectedSpottingAreasFromApiCall(personalisation.spotting_areas)
        this.updateSelectedConceptSearchesFromApiCall(personalisation.concept_searches)
      } catch (error) {
        console.log(error)
      }

      this.updatePortfolioOptions('')
      this.updateSpottingAreaOptions('')
      this.updateConceptSearchOptions()
    },
    components: {
      AutocompleteTagInput,
      ActionBar
    }
  }
</script>

<style scoped>

</style>
