<template>
  <div class="chat-bot-sidePanel">
    <div class="chat-bot-header">
      <h3>{{ chatbotName }}</h3>
      <DsButton
        icon="x"
        variant="tertiary"
        size="extra-small"
        @click="hideChatBotSidePanel"
      />
    </div>

    <div class="side-panel-aside-content" :class="{'is-empty-state': messages.length === 0}" id="asideScrollTarget">
      <div class="side-panel-aside-content__empty-state" v-if="messages.length === 0">
        <img :src="assistantLogo" alt="Ecosystem Favicon"/>
        <h2>{{ chatbotEmptyStateMessage }}</h2>
      </div>

      <ChatMessageCard
        v-else
        v-for="(message, index) in messages"
        :key="index"
        :message="message"
        @feedback-updated="updateMessageFeedback"
      />
    </div>
    <div class="side-panel-aside-container">
      <div>
        <div class="side-panel-aside-content-bottom__empty-state" v-if="messages.length === 0 && $store.getters.isAriadne">
          <p style="margin-bottom: 0.5rem;">{{ chatbotEmptyStateMessageQuestions }}</p>
          <div
            class="side-panel-aside-content-bottom__cards "
            v-for="(message, index) in chatBotRecentMessages" :key="index + 'message'" @click="sendMessage(message)">
            <p>{{ message.message }}</p>
            <DsButton
              icon="arrow-up"
              variant="tertiary"
              icon-size="12"
              class="ml-1"
              size="extra-small"
              v-tooltip.left="'Click to send'"
              @click="sendMessage(message)"
            />
          </div>
        </div>
        <div class="side-panel-aside-content-bottom">
          <ChatFocusButtonContainer
            :active-chat-focus="chatFocus"
            :disabled="isLoadingChatResponse"
            @update-chat-focus="updateChatFocus"
          />

          <DsInput
            style="margin-right: 0.5rem;"
            :model-value="userMessage"
            :disabled="isLoadingChatResponse"
            :placeholder="isLoadingChatResponse ? 'Thinking...' : placeholder"
            @update:modelValue="userMessage = $event"
            @keyup.enter="sendMessage"
          />

          <DsButton
            icon="arrow-up"
            icon-size="12"
            variant="tertiary"
            class="ml-1"
            v-tooltip.left="'Ask question'"
            :disabled="isLoadingChatResponse"
            @click="sendMessage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
  import {defineComponent, inject} from 'vue'
  import DsInput from '../../../components/form/DsInput.vue'
  import {ChatMessage} from "@/Domain/Chat/Types/types"
  import {chat, fetchConversationMessages, fetchOrCreateConversation} from "@/Domain/Chat/Api/chat"
  import {transformChatResponseToChatMessage} from "@/Domain/Chat/Types/chat-api-transformers"
  import ChatMessageCard from "@/Domain/Chat/Components/ChatMessageCard.vue"
  import { ChatFocus, ChatRole, MessageType } from "@/Domain/Chat/Enums/enums"
  import {MUTATION_TYPES as UI_MUTATION_TYPES} from "@/store/modules/ui"
  import ChatFocusButtonContainer from '@/Domain/Chat/Components/ChatFocusButtonContainer.vue'

  declare interface BaseComponentData {
    userMessage: string,
    messages: ChatMessage[],
    isLoadingChatResponse: boolean,
    conversationId: number | null,
    recentMessages: ChatMessage[],
    chatFocus: string,
  }

  const DEFAULT_CHATBOT_CONFIG: {
    chatbotCTA: string,
    chatbotEmptyStateMessage: string,
    chatbotEmptyStateMessageQuestions: string,
    placeholder: string,
    assistantLogo: string,
  } = {
    chatbotCTA: 'Ask a question',
    chatbotEmptyStateMessage: 'Ask a question...',
    chatbotEmptyStateMessageQuestions: 'People have recently asked:',
    placeholder: 'Ask a question...',
    assistantLogo: '/images/minerva-logo.svg',
  }

  export default defineComponent({
    name: 'ChatBotSidePanel',
    components: {
      ChatFocusButtonContainer,
      DsInput,
      ChatMessageCard,
    },
    emits: ['scroll-to-bottom'],
    data(): BaseComponentData {
      return {
        userMessage: '',
        messages: [],
        isLoadingChatResponse: false,
        conversationId: null,
        recentMessages: [],
        chatFocus: ChatFocus.none,
      }
    },
    computed: {
      chatbotCustomization(): any | null {
        return this.$store.getters.chatbotCustomization
      },
      assistantLogo(): string {
        if (this.chatbotCustomization && this.chatbotCustomization.assistantLogo) {
          return this.chatbotCustomization.assistantLogo
        }

        return '/images/minerva-logo.svg'
      },
      ecosystemDisplayName(): string {
        return this.$store.getters.ecosystemDisplayName
      },
      chatbotName(): string {
        return this.chatbotCustomization?.chatbotCTA || DEFAULT_CHATBOT_CONFIG.chatbotCTA
      },
      chatbotEmptyStateMessage(): string {
        return this.chatbotCustomization?.chatbotEmptyStateMessage || DEFAULT_CHATBOT_CONFIG.chatbotEmptyStateMessage
      },
      chatbotEmptyStateMessageQuestions(): string {
        // this part is commented out because we are not using it yet
        // TODO: when we implement the other chatbot versions we need to change this
        return 'People have recently asked Minerva:'
      },
      chatBotRecentMessages(): any {
        // return 2 hardcoded messages for now
        if (!this.$store.getters.isAriadne) {
          return []
        }

        return [
          {
            message: 'What are examples of new innovative textile raw materials?'
          },
          {
            message: 'Who is active in mechanical textile recycling?'
          },
        ]
      },
      placeholder(): string {
        return this.chatbotCustomization?.placeholder || DEFAULT_CHATBOT_CONFIG.placeholder
      },
      currentEcosystem() {
        return this.$store.getters.currentEcosystem
      },
    },
    created() {
      const $bus = inject('emitter') as any
      this.$bus = $bus
    },
    async mounted() {
      await this.fetchConversationId()
      await this.fetchConversationHistory()

      this.scrollToBottomOfChat()
    },
    methods: {
      updateChatFocus (focus: string) {
        this.chatFocus = focus
      },
      updateMessageFeedback(context: any) {
        const message = this.messages.find((message) => message.id === context.messageId)

        if (message) {
          message.userFeedback = context.feedback
        }
      },
      scrollToBottomOfChat() {
        this.$bus.emit('scroll-to-bottom')
      },
      async fetchConversationId() {
        try {
          const conversation = await fetchOrCreateConversation('global_chat')

          this.conversationId = conversation.id
        } catch (e) {
          console.log(e)
        }
      },
      async fetchConversationHistory() {
        try {
          if (!this.conversationId) {
            return
          }

          const response = await fetchConversationMessages(this.conversationId)

          this.messages = response.map(transformChatResponseToChatMessage)

          this.scrollToBottomOfChat()
        } catch (e) {
          console.log(e)
        }
      },
      async sendMessage(message) {
        if (!this.conversationId) {
          return
        }

        const recentMessages = this.chatBotRecentMessages
        const clickedMessage = recentMessages.find(msg => msg.message === message.message)
        let userMessage = this.userMessage
        if (clickedMessage) {
          userMessage = clickedMessage.message
        }

        this.userMessage = ''

        this.isLoadingChatResponse = true

        this.messages.push({
          id: null,
          message: userMessage,
          role: ChatRole.user,
          messageType: MessageType.other,
          conversationId: this.conversationId,
          createdAt: new Date().toISOString(),
          usedSources: [],
          userFeedback: 0,
        })

        this.scrollToBottomOfChat()

        try {
          const response = await chat(this.conversationId, userMessage, this.chatFocus)

          const message = transformChatResponseToChatMessage(response)

          this.messages.push(message)

          this.scrollToBottomOfChat()
        } catch (e) {
          console.log(e)
        }

        this.isLoadingChatResponse = false
      },
      hideChatBotSidePanel() {
        this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
      },
    },
  })
</script>

<style lang="scss" scoped>
  .chat-bot-sidePanel {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #FFF;
  }

  .side-panel-aside-container {
    display: flex;
    flex-direction: column;
    padding: 15px;
  }

  .side-panel-aside-content {
    overflow-y: auto;
    flex: 1;
    //margin-top: 1.5rem;
    //margin-bottom: 1rem;
    //padding: 15px;

    &.is-empty-state {
      display: flex;
      justify-content: center;
    }

    .side-panel-aside-content__empty-state {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      margin-top: 50%;

      img {
        border-radius: 50%;
        width: 60px;
        height: 60px;
      }

      h2 {
        color: #686F8F;
        margin-top: 1rem;
      }
    }
  }

  .side-panel-aside-content-bottom {
    margin-top: auto;
    display: flex;
    align-items: center;
    //margin-bottom: 1rem;
    //padding: 15px;

    .ds-input {
      margin-right: 1rem;

      :deep(.ds-input__input) {
        border-radius: 4px;

        &:focus {
          border: 2px solid #BEA6FF !important;
          outline: none;
          box-shadow: 0 0 0 2px #BEA6FF !important;
        }
      }
    }

    .button {
      border-radius: 4px;
      background-color: #843DFF;

      :deep(.svg-icon svg path) {
        fill: white;
      }
    }
  }

  .side-panel-aside-content-bottom__empty-state {
    p {
      color: #2C0076;
      margin-bottom: 0rem;
    }

    .side-panel-aside-content-bottom__cards {
      background-color: #F3F1FF;
      border: 1px solid #BEA6FF;
      border-radius: 4px;
      width: 100%;
      padding: 0.5rem;
      margin-bottom: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;


      .button {
        background-color: #D9CEFF;
        border-radius: 4px;

        :deep(.svg-icon svg path) {
          fill: #2C0076;
        }
      }
    }
  }

  .chat-bot-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #B1B5C8;
    padding: 15px;
    height: 49px;
    background-color: white;

    h3 {
      color: #2C0076;
    }


    .button {
      background-color: #F3F1FF;
      border-radius: 4px;

      :deep(.svg-icon svg path) {
        fill: #2C0076;
      }
    }

    .button:hover {
      background-color: #D9CEFF;
    }
  }
</style>
