<template>
  <div>
    <h2 class="h2">Billing Information</h2>
    <p class="form-group__label__text">fields marked with <span style="color: red">*</span> are required</p>

    <div class="row">
      <div class="col-sm-6 col-xs-12 user-billing-information__form-input">
        <form-group label="Address line 1" :required="true">
          <ds-input class="user-billing-information__input" placeholder="" v-model="billingInfo.billing_address" is-simplified/>
        </form-group>
      </div>
      <div class="col-sm-6 col-xs-12 user-billing-information__form-input">
        <form-group label="Address line 2">
          <ds-input class="user-billing-information__input" placeholder="" v-model="billingInfo.billing_address_line_2" is-simplified/>
        </form-group>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6 col-xs-12 user-billing-information__form-input">
        <form-group label="City" :required="true">
          <ds-input class="user-billing-information__input" placeholder="" v-model="billingInfo.billing_city" is-simplified/>
        </form-group>
      </div>
      <div class="col-sm-6 col-xs-12 user-billing-information__form-input">
        <form-group label="Zip code">
          <ds-input class="user-billing-information__input" placeholder="" v-model="billingInfo.billing_zip" is-simplified/>
        </form-group>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-6 col-xs-12 user-billing-information__form-input">
        <form-group label="Country" :required="true">
          <country-dropdown @update:modelValue="handleChangedCountry"
                            :model-value="billingInfo.billing_country" is-simplified/>
        </form-group>
      </div>
      <div class="col-sm-6 col-xs-12 user-billing-information__form-input">
        <form-group label="Phone">
          <ds-input class="user-billing-information__input" v-model="billingInfo.phone" is-simplified/>
        </form-group>
      </div>
    </div>
    <div class="row">

      <div class="col-sm-6 col-xs-12 user-billing-information__form-input">
        <form-group label="Company name" :required="true"
                    :class="{'form-group--has-error' : billingInfo.vat_id && !billingInfo.vat_id_valid && originalBillingInfo.vat_id === billingInfo.vat_id && !isInModal}">
          <div>
            <ds-input class="user-billing-information__input"
                      v-model="billingInfo.company_name" placeholder="Company name" is-simplified/>
          </div>

        </form-group>
      </div>
      <div class="col-sm-6 col-xs-12 user-billing-information__form-input">
        <form-group label="Company info">
          <div class="user-billing-information__vat-input">
            <dropdown
              :options="jurisdictionOptions"
              :model-value="billingInfo.billing_country"
              @update:modelValue="handleChangedJurisdiction"
              width="50px"
              :search="true"
              is-simplified
            />
            <ds-input class="user-billing-information__input" placeholder="VAT ID"
                      v-model="billingInfo.vat_id" is-simplified/>
            <div>
              <icon style="margin-left: 0.2em; margin-top: 45%;" name="check"
                    v-if="billingInfo.vat_id_verified"></icon>
            </div>
          </div>
        </form-group>
      </div>
    </div>

    <div>
      <ds-button @click="updateUserBillingInfo()" :icon="updatingBillingInfo ? 'spinner' : ''" :label="submitLabel" variant="primary" :disabled="!canSubmit"/>
    </div>
  </div>
</template>

<script>
  import CountryDropdown from '../../Dropdown/CountryDropdown.vue'
  import Dropdown from '../../Dropdown/Dropdown.vue'

  import diffObjects from '../../../util/diff-objects'
  import { COUNTRIES } from '../../../constants/countries'

  import { ACTION_TYPES as USER_ACTION_TYPES } from '../../../store/modules/user.js'

  export default {
    name: 'UserBillingInformation.vue',
    props: ['updatingBillingInfo', 'isInModal'],
    data () {
      return {
        billingInfo: {
          billing_address: '',
          billing_address_line_2: '',
          billing_city: '',
          billing_zip: '',
          billing_country: 'BE',
          vat_id: '',
          company_name: '',
          phone: '',
        },
      }
    },
    computed: {
      originalBillingInfo () {
        return this.$store.state.user.billingInfo
      },
      submitLabel () {
        if (this.isLoggedIn) {
          return this.updatingBillingInfo ? 'Updating...' : 'Submit'
        }
        return 'Next'
      },
      canSubmit () {
        return !this.updatingBillingInfo && this.billingInfo.billing_country && this.billingInfo.billing_address && this.billingInfo.billing_city && this.billingInfo.company_name
      },
      isLoggedIn () {
        return this.$store.getters.isLoggedIn
      },
      hasBillingInfoChanged () {
        // No longer used, a user must be able to submit when it chooses a different package without the need to update the billing info as this component is part of that flow
        return diffObjects(this.billingInfo, this.originalBillingInfo)
      },
      jurisdictionOptions () {
        // Filter out the UK, it's not considered a legal jurisdiction
        return Object.keys(COUNTRIES).filter(countryCode => countryCode !== 'UK').sort()
      },
    },
    methods: {
      updateUserBillingInfo () {
        this.$emit('updateBillingInfo', this.billingInfo)
      },
      handleChangedCountry (value) {
        this.billingInfo.billing_country = value.code
      },
      handleChangedJurisdiction (value) {
        this.billingInfo.billing_country = value
      },
      getUserBillingInfo () {
        this.$store.dispatch(USER_ACTION_TYPES.FETCH_BILLING_INFO).then(() => {
          this.billingInfo = Object.assign({}, this.originalBillingInfo)
        })
      },
    },
    mounted () {
      if (this.isLoggedIn) {
        this.$bus.on('billingInfoUpdated', () => {
          this.getUserBillingInfo()
        })
        this.getUserBillingInfo()
      }
    },
    beforeUnmount () {
      if (this.isLoggedIn) {
        this.$bus.off('billingInfoUpdated')
      }
    },
    components: {
      CountryDropdown,
      Dropdown,
    },
  }
</script>

<style scoped lang="scss">
  .row {
    margin-bottom: 0rem;
  }

  .user-billing-information__input {
    margin-bottom: 0.5em;
  }

  .user-billing-information__vat-input {
    max-width: 300px;
    display: flex;
    flex-direction: row;
  }

  .user-billing-information__form-input {
    max-width: 300px;
    margin-top: 3px;
  }

  .center-button {
    display: flex;
    justify-content: center;
  }
</style>
