<template>
  <modal :id="modalId" ref="modal" :enable-mask="true" wrapperSize="flex" size="flex"
         :title="detailImageName" :is-closeable="true" class="full-screen-modal">
    <template v-slot:body>
      <div class="image-detail__container">
        <img class="image-detail" :src="detailImage" :alt="detailImageName">
      </div>
    </template>
  </modal>
</template>

<script>
  import Modal from './Modal.vue'
  import MODAL_IDS from '../../constants/modal-ids.js'

  export default {
    name: 'image-detail-modal',
    props: {},
    data () {
      return {
        modalId: MODAL_IDS.IMAGE_DETAIL,
      }
    },
    computed: {
      detailImageName () {
        return this.$store.state.ui.modalContext.detailImageName
      },
      detailImage () {
        return this.$store.state.ui.modalContext.detailImage
      },
    },
    methods: {},
    components: {
      Modal,
    },
  }
</script>

<style lang="scss" scoped>
.image-detail__container {
  display: flex;
  justify-content: center;

  .image-detail {
    max-width: calc(100vw - 60px);
    max-height: calc(100vh - 150px);
  }
}

.full-screen-modal {
  padding-top: 0;

  :deep(.modal__wrapper) {
    max-height: none;

    .title {
      margin-right: 20px;
    }

    .modal__header .divider {
      max-width: none;
    }

    .modal__container {
      max-width: fit-content;
    }
  }
}

</style>
