<template>
  <div class="communities-container" ref="scrollable">
    <basic-simplified-hero
      :title="onboardingTextConfig.communitiesSimplifiedTitle"
      :subtitle="onboardingTextConfig.communitiesSimplifiedSubtitle"
    >
    </basic-simplified-hero>
    <Container style="flex-grow: 1">
      <div class="communities__card-container" id="introjs-all-communities">
        <div v-if="!loading" class="row">
          <div class="col col-xs-12 col-sm-4" v-for="(community, index) in communities" :key="index">
            <div style="width: 100%; height: calc(100% - 20px); padding: 0px 10px 10px 10px;">
              <simplified-image-card
                background-color="white"
                class="community-simplified-image-card"
                :name="community.name"
                :full-size="true"
                :image="getImageForCommunity(community)"
                :sub-title="community.description"
                :link="'/communities/' + community.id"
                :left-align-text="true"
                :tag-icons="getCommunityIcons(community)"
              />
            </div>
          </div>
        </div>
        <div v-else>
          <Loading/>
        </div>
      </div>
    </Container>
  </div>
</template>

<script lang="ts">
  import BasicSimplifiedHero from '../components/Simplified/BasicSimplifiedHero.vue'
  import Container from '../components/Container/Container.vue'
  import SimplifiedImageCard from '../components/Connection/SimplifiedImageCard.vue'
  import CommunityCard from '../components/Simplified/CommunityCard.vue'
  import Loading from '../components/Dashboard/ConceptMap/Loading.vue'
  import { ACTION_TYPES as COMMUNITY_ACTION_TYPES } from '../store/modules/communities.js'
  import OnboardingMixin from '@/util/OnboardingMixin'
  import { getImageForCommunity, truncate } from '@/util/helpers.js'
  import IntroJsMixin from '@/util/IntroJsMixin'
  import { defineComponent } from 'vue'
  import CommunityMixin from '@/util/CommunityMixin'
  import { Community } from '@/Domain/Community/types'

  export default defineComponent({
    name: 'Communities',
    components: {
      BasicSimplifiedHero,
      SimplifiedImageCard,
      Container,
      CommunityCard,
      Loading,
    },
    data() {
      return {}
    },
    computed: {
      communities() {
        return this.$store.state.communities.listData.data
      },
      loading() {
        return this.$store.state.communities.listData.loading
      },
      isOwner() {
        return this.$store.getters.isOwner
      },
      isLoggedIn() {
        return this.$store.getters.isLoggedIn
      },
      userId() {
        return this.$store.getters.userId
      },
    },
    methods: {
      truncate,
      getImageForCommunity,
      getCommunityIcons(community: Community) {
        const icons: string[] = []

        if (this.isCommunityManager(community)) {
          icons.push("user-check")
        }

        if (this.joinedCommunities?.filter((c) => c.id === community.id).length > 0) {
          icons.push("users")
        }

        return icons
      },
      fetchCommunities() {
        this.$store.dispatch(COMMUNITY_ACTION_TYPES.FETCH_COMMUNITIES_LIST)
          .then(() => {
            // timeout without milliseconds to allow the community cards to render before starting the intro
            setTimeout(() => {
              this.startIntroJs()
              // wait for the simplified image card timeout
            }, 200)
          })
      },
      startIntroJs() {
        if (this.seenIntros.includes('communitiesOverviewPage') || !this.isLoggedIn) {
          return
        }
        const config = this.buildIntroJsConfig(this.config, this.$store.getters.userRole)
        if (!config || !config.communitiesOverviewPage || !config.communitiesOverviewPage.steps) {
          return
        }

        config.communitiesOverviewPage.steps = this.getIntroJsStepsConfig(config.communitiesOverviewPage.steps)
        const intro = this.introJs().setOptions(config.communitiesOverviewPage)
        this.currentIntro = intro

        const componentScope = this
        intro.onbeforechange(this.introJsBeforeStepCallback.bind(this))
        intro.onafterchange(this.introJsAfterStepCallback.bind(this))
        intro.oncomplete(function () {
          componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
        })
        intro.onexit(function () {
          componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
        })

        intro.start()
      },
    },
    mounted() {
      if (!this.$store.getters.hasAccessToCommunities) {
        this.$router.push('/')
      }

      this.fetchCommunities()
    },
    mixins: [OnboardingMixin, IntroJsMixin, CommunityMixin]
  })
</script>

<style lang="scss" scoped>
  .communities-container {
    height: 100%;
    overflow: auto;
    width: 100%;
    display: flex;
    flex-direction: column;

    .communities__card-container {
      margin-bottom: 50px;
    }
  }
</style>
