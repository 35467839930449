<template>
  <form @submit.prevent="save" :class="{'settings__ecosystem-introJS': isIntroEnabled}"
        class="settings__ecosystem has-action-bar">
    <tabs :tabs="tabs" :saveToUrl="true">
      <template v-slot:[getTabName(0)]>
        <div>
          <branding v-model:form="form"/>
        </div>
      </template>
      <template v-slot:[getTabName(1)]>
        <div>
          <views v-model:form="form"/>
        </div>
      </template>
      <template v-slot:[getTabName(2)]>
        <div>
          <homepage v-model:form="form"/>
        </div>
      </template>
      <template v-slot:[getTabName(3)]>
        <div>
          <filters v-model:form="form"/>
        </div>
      </template>
      <template v-slot:[getTabName(4)]>
        <div>
          <navigation v-model:form="form"/>
        </div>
      </template>
      <template v-slot:[getTabName(5)] v-if="$store.getters.isOwner">
        <div>
          <custom-areas v-model:form="form"/>
        </div>
      </template>
      <template v-slot:[getTabName(6)]>
        <div>
          <advanced v-model:form="form"/>
        </div>
      </template>
      <template v-slot:[getTabName(7)]>
        <div>
          <other v-model:form="form"/>
        </div>
      </template>
    </tabs>
  </form>
</template>

<script>
  import Checkbox from '../Form/Checkbox.vue'
  import CountryDropdown from '../Dropdown/CountryDropdown.vue'
  import Dropdown from '../Dropdown/Dropdown.vue'
  import DsTextarea from '../Form/DsTextarea.vue'
  import ImageInput from '../Form/ImageInput.vue'
  import TagInput from '../Form/TagInput.vue'
  import ColorPicker from '../Form/ColorPicker.vue'
  import Tabs from '../Tabs/Tabs.vue'
  import EditorLanguageDropdown from '../Translations/EditorLanguageDropdown.vue'
  import CustomScores from './CustomScores.vue'
  import RadioButton from '../Form/RadioButton.vue'

  import UiMixin from '../../util/UiMixin'
  import ConfigMixin from '../../util/ConfigMixin'
  import TranslationsMixin from '../../util/TranslationsMixin'

  import Branding from './EcosystemTabs/Branding.vue'
  import Views from './EcosystemTabs/Views.vue'
  import Homepage from './EcosystemTabs/Homepage.vue'
  import Advanced from './EcosystemTabs/Advanced.vue'
  import Filters from './EcosystemTabs/Filters.vue'
  import Navigation from './EcosystemTabs/Navigation.vue'
  import CustomAreas from './EcosystemTabs/CustomAreas.vue'
  import Other from './EcosystemTabs/Other.vue'

  import IntroJsMixin from '../../util/IntroJsMixin.js'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'ecosystem',
    data () {
      return {
        isIntroEnabled: false,
      }
    },
    computed: {
      tabs () {
        return ['Branding', 'Views', 'Homepage', 'Filters', 'Navigation', 'Custom areas', 'Advanced', 'Other']
      },
      isPublisherEnabled () {
        return this.$store.getters.isPublisherEnabled === true
      },
      form () {
        return this.$parent.$parent.form
      },
      errors () {
        const errorMap = {}
        if (!this.form.viewList && !this.form.viewGallery && !this.form.viewMap) {
          errorMap.viewMap = ['You must enable at least 1 view.']
        }

        if (this.$parent.$parent.errors && this.$parent.$parent.errors.portfolioModePortfolio) {
          errorMap.presentationModePortfolio = ['The presentation mode must have a portfolio selected']
        }

        if (this.$parent.$parent.errors && this.$parent.$parent.errors.homepage_knowledge_base_tags) {
          errorMap.homepage_knowledge_base_tags = ['The knowledge-base section must have tags selected']
        }

        if (this.$parent.$parent.errors && this.$parent.$parent.errors.homepage_knowledge_base_image) {
          errorMap.enableHomepageKnowledgeBase = ['The knowledge-base section must be enabled in order to save the knowledge-base image']
        }

        if (this.$parent.$parent.errors && this.$parent.$parent.errors.embedded_knowledge_base_video_url) {
          errorMap.enableHomepageKnowledgeBase = ['The knowledge-base section must be enabled in order to save the knowledge-base video']
        }

        if (this.$parent.$parent.errors && this.$parent.$parent.errors.enableHomepageKnowledgeBase) {
          errorMap.enableHomepageKnowledgeBase = ['The knowledge-base section must be enabled in order to save the knowledge-base filter tags']
        }

        errorMap.count = Object.keys(errorMap).length
        return errorMap
      },
      viewListSelectedColumns: {
        get () {
          if (this.form.viewList && this.form.viewList.length) {
            // If there is a list of columns defined we return that list
            return this.form.viewList
          }
          // Case there is no defined list of columns we assign all of the columns to the view list so that it displays all of the columns
          // And return all of the columns
          this.form.viewList = viewListOptions
          return viewListOptions
        },
        set (v) {
          this.form.viewList = v
        },
      },
      industries () {
        return this.$store.state.taxonomies.industries
      },
      domains () {
        return this.$store.state.taxonomies.domains
      },
      aspects () {
        return this.$store.state.taxonomies.aspects
      },
      industriesOptions () {
        return this.industries.map(i => {
          return i.name
        })
      },
      domainOptions () {
        return this.domains.map(x => {
          return x.name
        })
      },
      subIndustries () {
        var subIndustries = []

        this.industries.forEach(item => {
          item.industries.forEach(subIndustry => {
            if (!subIndustries.includes(subIndustry.name)) {
              subIndustries.push(subIndustry.name)
            }
          })
        })

        return subIndustries || []
      },
      aspectOptions () {
        return this.aspects.map(i => {
          return i.name
        })
      },
      categoryOptions () {
        var categoryList = []
        var categories = Object.values(this.categoryLabels)

        categories.forEach(category => {
          category.forEach(item => {
            categoryList.push(item)
          })
        })

        return categoryList || []
      },
    },
    methods: {
      getTabName (index) {
        return this.tabs[index]
      },
      dash (d) {
        return this.viewDashboard.includes(d)
      },
      getLegendOptions (property) {
        switch (property) {
          case 'membership':
            return this.membershipLabels || []
          case 'category':
            const categories = this.categoryLabels || []

            if (categories.length === 0) {
              return categories
            }

            let allCategories = []

            for (const type in categories) {
              allCategories = allCategories.concat(categories[type])
            }

            return allCategories
          case 'activities':
            return this.activityLabels || []
          case 'stage':
            return this.stageLabels || []
          case 'domains':
            const domains = this.$store.state.taxonomies.domains || []

            if (domains.length === 0) {
              return domains
            }

            return domains.map(domain => domain.name)
        }
      },
      buildIntroJs () {
        if (this.seenIntros.includes('settings')) {
          return
        }

        const config = this.buildIntroJsConfig(this.config, this.$store.getters.userRole)

        if (!config || !config.settings || !config.settings.steps) {
          return
        }
        config.settings.steps = this.getIntroJsStepsConfig(config.settings.steps)
        const intro = this.introJs().setOptions(config.settings)
        this.currentIntro = intro

        const componentScope = this
        intro.onbeforechange(this.introJsBeforeStepCallback.bind(this))
        intro.onafterchange(this.introJsAfterStepCallback.bind(this))
        intro.oncomplete(function () {
          componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
        })
        intro.onexit(function () {
          componentScope.introJsOnCompleteCallback(this._options, this._currentStep, componentScope)
        })

        this.isIntroEnabled = true

        // This is a hack, removing this seems to cause an error as this is auto-triggered after the homepage intro in some cases
        setTimeout(() => {
          intro.start()
        }, 1000)
      }
    },
    mounted () {
      this.buildIntroJs()
      this.$bus.on('start-intro-settings', () => this.isIntroEnabled = true)
    },
    beforeUnmount () {
      this.$bus.off('start-intro-settings')
    },
    components: {
      Branding,
      Checkbox,
      ColorPicker,
      CountryDropdown,
      CustomScores,
      Dropdown,
      DsTextarea,
      ImageInput,
      RadioButton,
      Filters,
      Tabs,
      TagInput,
      Homepage,
      Views,
      EditorLanguageDropdown,
      Advanced,
      Navigation,
      CustomAreas,
      Other,
    },
    mixins: [
      UiMixin,
      ConfigMixin,
      TranslationsMixin,
      IntroJsMixin,
    ],
  })
</script>

<style lang="scss">
  .settings__ecosystem .row {
    max-width: calc(40em + 2rem);
  }

  .settings__ecosystem .form-group {
    max-width: 40em;
  }

  .settings__ecosystem-full-width .row {
    max-width: 100% !important;
  }

  .settings__ecosystem-introJS {
    // This prevent a scrolling bug with introJS
    padding-top: 0 !important;
    margin-top: 20px;
  }
</style>
