import router from '../../router'

export default function (store) {
  store.subscribe(() => {
    if ([
      'explore',
    ].includes(router.currentRoute.name) || [
      '/dashboards/analytics',
      '/dashboards/connectedness',
      '/dashboards/funding',
      // '/dashboards/news',
      '/dashboards/competitive-landscape',
      '/dashboards/scores-list',
    ].includes(router.currentRoute.path)) {
      router.replace({ query: store.getters.exploreFilterObject })
    } else if (router.currentRoute.path === '/datalab/spreadsheet') {
      router.replace({ query: store.getters.spreadsheetFilterObject })
    }
  })
}
