<template>
  <div>
    <tree
      v-for="taxonomy in taxonomies"
      :bus="bus"
      :locale="locale"
      :root="taxonomy"
      :path="[taxonomy.id]"
      :maxDepth="taxonomy.maxDepth + 1"
      :key="taxonomy.id"
      class="bold-taxonomy-tree"
    />
  </div>
</template>

<script>
  import Tree from './Tree.vue'
  import { DEFAULT_LOCALE } from '../../store/modules/localization'
  import mitt from 'mitt'
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      taxonomies: {
        type: Array,
      },
      locale: {
        type: String,
        default: DEFAULT_LOCALE,
      }
    },
    data () {
      return {
        bus: new mitt(),
      }
    },
    created () {
      const forwarded = ['rename', 'move', 'add', 'delete']

      for (const eventName of forwarded) {
        this.bus.on(eventName, args => {
          this.$emit(eventName, args)
        })
      }
    },
    components: {
      Tree,
    }
  })
</script>

<style lang="scss">
  .bold-taxonomy-tree {
    margin-bottom: 20px !important;
    font-size: 14px;

    > .item-line {
      font-weight: bold;
    }
  }
</style>
