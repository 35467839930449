<template>
  <actor-changes :actor-id="$route.params.id" />
</template>

<script>
import ActorChanges from '../components/ActorChanges/ActorChanges.vue'

export default {
  name: 'Actor',
  components: {
    ActorChanges
  }
}
</script>
