<template>
  <tag :label="label" :class="classes" v-tooltip.bottom="tooltipOptions"/>
</template>

<script>
  import Tag from './Tag.vue'
  import slugify from '../../util/slugify'
  import { defineComponent } from 'vue'

  // A visual component for a "default dictionary", not an ecosystem dictionary
  export default defineComponent({
    components: { Tag },
    props: ['id'],
    computed: {
      dictionary () {
        const dictionary = this.$store.getters.defaultDictionaries.filter(d => d.id == this.id)

        if (!dictionary || dictionary.length == 0) {
          return
        }

        return dictionary[0]
      },
      label () {
        if (!this.dictionary) return String(this.id)
        return this.dictionary.abbreviation || this.dictionary.title
      },
      classes () {
        const classes = { 'dictionary-tag': true }
        if (this.dictionary && this.dictionary.dictionary_group) {
          classes[`dictionary-tag--${this.dictionary.dictionary_group}--${slugify(this.dictionary.title)}`] = true
        }

        return classes
      },
      tooltipOptions () {
        if (!this.dictionary.display_description) {
          return undefined
        }

        return this.dictionary.display_description
      },
    },
  })
</script>

<style lang="scss">
  @import "../../../scss/functions";

  .dictionary-tag {
    //
  }

  @mixin colorize-tag($color) {
    background-color: $color;
    border-color: mix(black, $color, 25%);
    color: choose-contrast-color($color);
  }

  .dictionary-tag--pestel--politics {
    @include colorize-tag(#6b00bd);
  }

  .dictionary-tag--pestel--economics {
    @include colorize-tag(#780acc);
  }

  .dictionary-tag--pestel--social {
    @include colorize-tag(#8116d9);
  }

  .dictionary-tag--pestel--technology {
    @include colorize-tag(#8a22e6);
  }

  .dictionary-tag--pestel--environment {
    @include colorize-tag(#9d30f0);
  }

  .dictionary-tag--pestel--legal {
    @include colorize-tag(#ac40ff);
  }
</style>
