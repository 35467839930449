<template>
  <a rel="noopener" target="_blank" :href="path" :class="classes">
    <div class="file-thumbnail__private-indicator" v-if="isPrivate && publisher">
      <Icon name="lock" size="small" />
    </div>
    <div class="file-thumbnail__visual" :style="visualStyles">
      <Icon v-if="icon" :name="icon" size="large" />
    </div>
    <div class="file-thumbnail__filename">{{filename}}</div>
  </a>
</template>

<script>
  import { defineComponent } from 'vue'

  const FILE_CATEGORIES = {
    IMAGE: 'image',
    PDF: 'pdf',
    PRESENTATION: 'presentation',
    SPREADSHEET: 'spreadsheet',
  }

export default defineComponent({
    name: 'FileThumbnail',
    props: {
      filename: String,
      mimeType: String,
      path: String,
      preview: String,
      isPrivate: {
        type: Boolean,
        default: false,
      },
      largePreview: {
        type: Boolean,
        default: false
      }
    },
    computed: {
      publisher () {
        return this.$store.getters.publisher
      },
      classes () {
        return [
          'file-thumbnail',
          `file-thumbnail--${this.category}`,
          this.isPrivate && this.publisher ? 'file-thumbnail--private' : '',
          this.largePreview ? 'file-thumbnail--fill' : ''
        ].join(' ')
      },
      category () {
        if (this.mimeType.startsWith('image')) {
          return FILE_CATEGORIES.IMAGE
        } else if (this.mimeType.includes('pdf')) {
          return FILE_CATEGORIES.PDF
        } else if (this.mimeType.includes('presentation')) {
          return FILE_CATEGORIES.PRESENTATION
        } else if (this.mimeType.includes('spreadsheet')) {
          return FILE_CATEGORIES.SPREADSHEET
        }

        return 'default'
      },
      visualStyles () {
        const styles = {}

        if (this.category === FILE_CATEGORIES.IMAGE) {
          styles.backgroundImage = `url(${this.preview})`
        }

        return styles
      },
      icon () {
        switch (this.category) {
          case FILE_CATEGORIES.PDF: {
            return 'file-pdf'
          }
          case FILE_CATEGORIES.PRESENTATION: {
            return 'file-presentation'
          }
          case FILE_CATEGORIES.SPREADSHEET: {
            return 'file-spreadsheet'
          }
          case FILE_CATEGORIES.IMAGE: {
            return null
          }
          default: {
            return 'file'
          }
        }
      }
    }
  })
</script>

<style lang="scss">
  @import "../../../scss/variables";
  @import "../../../scss/mixins/icon-color";

  $file-thumbnail-max-width: 80px;
  $file-category-styles: (
    default: (
      iconColor: #fff,
      backgroundColor: #444,
    ),
    pdf: (
      iconColor: #fff,
      backgroundColor: #E4453D,
    ),
    presentation: (
      iconColor: #fff,
      backgroundColor: #FCB334,
    ),
    spreadsheet: (
      iconColor: #fff,
      backgroundColor: #55B172,
    )
  );

  .file-thumbnail {
    display: inline-block;
    position: relative;
    width: 100%;
    max-width: $file-thumbnail-max-width;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    text-decoration: none;

    &:last-child {
      margin-right: 0;
      margin-bottom: 0;
    }
  }

  .file-thumbnail--fill {
    max-width: 100% !important;
  }

  .file-thumbnail--private {
    .file-thumbnail__filename {
      background-color: $color-private-data;
    }
  }

  .file-thumbnail__private-indicator {
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-private-data;
    @include iconColor(#fff);
  }

  @each $category, $styles in $file-category-styles {
    .file-thumbnail--#{$category} {
      .file-thumbnail__visual {
        background-color: map_get($styles, backgroundColor);
        @include iconColor(map_get($styles, iconColor));
      }
    }
  }

  .file-thumbnail__visual {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $file-thumbnail-max-width;
    background-size: cover;
    background-position: center;
  }

  .file-thumbnail__filename {
    padding: 0.25rem;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    background-color: #383B48;
    color: #fff;
    text-align: center;
    font-size: 10px;
  }
</style>
