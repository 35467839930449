import { createJSONRequest } from './create-request'

export const makeFilePrivate = (actorId, fileId) => createJSONRequest({
  url: `/api/actors/${actorId}/files/${fileId}/private`,
  method: 'PUT',
})

export const makeFilePublic = (actorId, fileId) => createJSONRequest({
  url: `/api/actors/${actorId}/files/${fileId}/public`,
  method: 'PUT',
})

// To update files created on an ecosystem level
export const getFileDetails = (fileId) => createJSONRequest({
  url: `/api/files/${fileId}`,
  method: 'GET',
})

export const updateEcosystemFileMetaData = (fileId, fileData) => createJSONRequest({
  url: `/api/files/${fileId}`,
  method: 'PUT',
  data: fileData,
})

// To delete files created on an ecosystem level
export const deleteEcosystemFile = (fileId) => createJSONRequest({
  url: `/api/files/${fileId}`,
  method: 'DELETE',
})

// To update files created on an actor level
export const updateActorFileMetaData = (actorId, fileId, fileData) => createJSONRequest({
  url: `/api/actors/${actorId}/files/${fileId}`,
  method: 'PUT',
  data: fileData,
})

// To delete files created on an actor level
export const deleteActorFile = (actorId, fileId) => createJSONRequest({
  url: `/api/actors/${actorId}/files/${fileId}`,
  method: 'DELETE',
})

// Make copies of the files and link them to the passed actor
export const copyFiles = (actorId, files) => createJSONRequest({
  url: `/api/actors/${actorId}/copy-files`,
  method: 'PUT',
  data: files,
})

export const getSimilarContent = (fileId) => createJSONRequest({
  url: `/api/files/${fileId}/similar-content`,
  method: 'GET',
})

export const verifyArticle = (article) => createJSONRequest({
  url: `/api/files/verify-article`,
  method: 'POST',
  data: article,
})
