<template>
  <div class="competition-numbers__number">
    <div class="competition-numbers__number__value">
      {{valueFormatted}}
    </div>
    <div class="competition-numbers__number__description">
      {{number.description}}
    </div>
    <div v-if="number.previousDescription" class="competition-numbers__footer">
      {{ number.previousDescription }}
    </div>
  </div>
</template>

<script>
import numeral from 'numeral'

export default {
  name: 'competition-number',
  props: {
    number: {
      type: Object,
      required: true
    }
  },
  computed: {
    valueFormatted () {
      return numeral(this.number.value).format('0,0')
    }
  }
}
</script>
