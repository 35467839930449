<template>
  <modal :id="modalId" ref="modal" title="Explore" :is-closeable="true" @close="close">
    <template v-slot:body>
      <div v-if="result === null">
        <div class="row">
          <div class="col-xs-12 col-sm-12">
            <form-group label="Title" :errors="errorMessages.title">
              <ds-input v-model="title" placeholder="Title"/>
            </form-group>
            <form-group label="Search for..." :errors="errorMessages.search_topics" v-if="displaySearchTopics">
              <AutocompleteDropdown v-model="searchTopics" placeholder="" :do-search="autocompleteSearchTerm"
                                    :multiple="true" :maxTags="5"/>
            </form-group>
            <form-group label="Search context" :errors="errorMessages.context">
              <dropdown :multiple="true" :options="topicOptions" v-model="context"/>
            </form-group>
            <form-group label="Analyse on">
              <dropdown :multiple="true" :options="topicOptions" v-model="topics"/>
            </form-group>
            <form-group label="Exclude">
              <dropdown :multiple="false" :options="topicOptions" v-model="exclude" :allowClear="true"/>
            </form-group>
            <form-group label="Geographical context">
              <AutocompleteTagInput
                :tags="geographyContextTags"
                :options="geographyOptions"
                :addOnlyFromAutocomplete="true"
                placeholder="Search within specific areas"
                @tagChanged="updateGeographyOptions"
                @input:raw="updateGeographyContext"
              />
            </form-group>
            <form-group label="Description (optional)">
              <ds-input v-model="description" />
            </form-group>
          </div>
        </div>
      </div>
      <div v-else>
        {{resultMessage}}
      </div>
    </template>
    <template v-slot:footer>
      <div v-if="result === null">
        <ds-button
          label="Cancel"
          variant="outline"
          :disabled="saving"
          @click="close"
        />
        <ds-button
          label="Explore"
          variant="secondary"
          :disabled="!canSubmit || saving"
          :icon="saving ? 'spinner' : undefined"
          @click="explore"
        />
      </div>
      <div v-else>
        <ds-button
          label="Close"
          variant="outline"
          @click="close"
        />
      </div>
    </template>
  </modal>
</template>

<script>
  import Dropdown from '../../components/Dropdown/Dropdown.vue'
  import Modal from './Modal.vue'
  import AutocompleteTagInput from '../../components/Form/AutocompleteTagInput.vue'
  import AutocompleteDropdown from '../../components/Dropdown/AutocompleteDropdown.vue'
  import ExplorationKeywordInput from '../../components/ExplorationKeywordInput/ExplorationKeywordInput.vue'

  import MODAL_IDS from '../../constants/modal-ids'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui'

  import {
    Dictionary,
    fetchConceptSuggestions,
    fetchGeographySuggestions,
    storeConceptSearch,
  } from '../../api/exploration.js'

  import debounce from 'lodash/debounce'

  export default {
    name: 'explore-content-modal',
    data () {
      return {
        modalId: MODAL_IDS.EXPLORE_CONTENT,
        title: '',
        searchTopics: [],
        description: '',
        context: [], // The context to include in the search
        exclude: [], // The dictionary to exclude when searching
        topics: [], // The chosen topics
        dictionaries: [], // The available topics to choose from
        geographyContextTags: [], // The labels for the configured geography
        geographyContext: [], // The full geography context
        geographyOptions: [],
        saving: false,
        result: null,
        errorMessages: {},
        onSaved: () => {
        },
      }
    },
    computed: {
      topicOptions () {
        if (!this.dictionaries) {
          return []
        }

        return this.dictionaries.map(item => ({
          value: item.id,
          label: item.value,
        }))
      },
      canSubmit () {
        //return true
        return (this.searchTopics.length > 0 || this.context.length > 0) && this.title.length > 0
      },
      displaySearchTopics () {
        return !this.$route.path.startsWith('/datalab')
      },
      displayActorsFound () {
        return !this.$route.path.startsWith('/datalab')
      },
      resultMessage () {
        if (!this.result) {
          return
        }

        if (!this.displayActorsFound) {
          return 'We have started searching for new content.'
        }

        return 'Your search have been saved. We are searching for new actors and new content.' //We found ' + this.result.organisation_count + ' organisations and ' + this.result.article_count + ' articles.'
      },
    },
    methods: {
      autocompleteSearchTerm (query) {
        return fetchConceptSuggestions({query: query, conceptType: 'any' })
          .then(response => {
            var options = [];

            response.forEach(function (concept) {
              options.push({
                label: concept.value,
                value: concept.uri
              });
            });

            return options;
          })
          .catch(err => {
            console.log(err);
          })
      },
      updateGeographyOptions: debounce((async function (input) {
        fetchGeographySuggestions({query: input})
          .then(response => {
            this.geographyOptions = [];

            if (! Array.isArray(response)) {
              return;
            }

            response.forEach(item => {
              if (item.label.eng) {
                var label = item.label.eng;

                if (item.type == 'place' && item.country && item.country.label && item.country.label.eng && !label.includes(item.country.label.eng) && !label.includes(',')) {
                  label += ' (' + item.country.label.eng + ')';
                }

                this.geographyOptions.push({
                  text: label,
                  geographyObject: item
                });
              }
            });
          })
          .catch(err => {
            this.geographyOptions = [];
          });
      }), 150),
      updateGeographyContext (context) {
        this.geographyContext = context.map(item => {
          return item.optionValue.geographyObject
        });
      },
      close () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, null)
        this.$store.commit(UI_MUTATION_TYPES.HIDE_MODAL, this.modalId)
        this.$emit('close')
      },
      explore () {
        this.saving = true

        storeConceptSearch({
          title: this.title,
          context: this.context,
          search_topics: this.searchTopics,
          exclude: this.exclude,
          dictionary_context_id: this.topics,
          description: this.description,
          geography_context: this.geographyContext,
        })
        .then(response => {
          this.saving = false
          this.result = response
          this.onSaved()
        })
        .catch(err => {
          this.errorMessages = err
          this.saving = false
        })
      },
      restoreSelf (additionalTopic) {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          title: this.title,
          search_topics: this.searchTopics,
          exclude: this.exclude,
          description: this.description,
          topics: additionalTopic ? [...this.topics, additionalTopic] : this.topics,
          dictionaries: this.dictionaries,
          geography_context: this.geographyContext,
          onSaved: this.onSaved,
        })

        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.EXPLORE_CONTENT)
      },
      createNewTopic () {
        this.$store.commit(UI_MUTATION_TYPES.SET_MODAL_CONTEXT, {
          onClose: (id) => {
            this.restoreSelf(id)
          },
        })

        this.$store.commit(UI_MUTATION_TYPES.SHOW_MODAL, MODAL_IDS.EDIT_TOPIC)
      },
    },
    created () {
      Dictionary.get().then(dictionaries => {
        this.dictionaries = dictionaries
      });

      const modalContext = this.$store.state.ui.modalContext

      if (modalContext) {
        this.result = modalContext.result || null
        this.title = modalContext.title || ''
        this.description = modalContext.description || ''
        this.searchTopics = modalContext.search_topics || ''
        this.topics = modalContext.topics || []
        this.dictionaries = modalContext.dictionaries || []
        this.onSaved = modalContext.onSaved || (() => {})
      }
    },
    mounted () {
      //this.$refs.keywordInput.focus();
    },
    components: {
      AutocompleteDropdown,
      AutocompleteTagInput,
      Dropdown,
      ExplorationKeywordInput,
      Modal,
    }
  }
</script>
