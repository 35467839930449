<template>
  <div class="actor-changes fixed-heading" :class="{ 'change-single': actorId }">
    <div class="heading">
      <h1>Recent changes</h1>
      <p class="guide_text">Review the latest changes by scrolling through the descriptions.</p>
    </div>

    <div class="scrollable has-padding">
      <change-batch :batch="batch" v-for="(batch, key) in batches" :key="key"></change-batch>
    </div>
    <pagination :paging="paging" @change="setOffset"></pagination>
  </div>
</template>

<script lang="ts">
import ChangeBatch from './ChangeBatch.vue'
import Pagination from '../Pagination/SimplePagination.vue'

import { toQuery, getPaging, haveInCommon } from '../../util/helpers.js'
import { fetchChanges } from '../../api/heartbeat.js'
import { defineComponent } from 'vue'
export default defineComponent({
  components: {
    ChangeBatch,
    Pagination
  },
  props: ['actor-id'],
  data () {
    return {
      changes: [],
      paging: null,
      filters: {
        companyId: this.actorId || null,
        limit: 50,
        offset: 0
      }
    }
  },
  computed: {
    batches () {
      const next = this.changes[0]
      if (!next) {
        return []
      }
      const batches = [{
        changes: [next],
        agent: next.agent,
        approved: next.approved,
        created_at: next.created_at,
        reason: next.reason
      }]

      for (let i = 1; i < this.changes.length; i++) {
        const prev = this.changes[i - 1]
        const next = this.changes[i]

        if (haveInCommon(prev, next, ['approved', 'id', 'reason'])) {
          batches[batches.length - 1].changes.push(next)
        } else {
          batches.push({
            changes: [next],
            agent: next.agent,
            approved: next.approved,
            created_at: next.created_at,
            reason: next.reason
          })
        }
      }

      return batches
    }
  },
  methods: {
    fetch () {
      fetchChanges(this.filters)
        .then(({ data, paging }) => {
          this.changes = data
          this.paging = paging
        }, this.fetchError)
    },
    fetchError (error) {
      //
    },
    setOffset (offset) {
      this.filters.offset = offset
      this.fetch()
    }
  },
  events: {
    pageChange () {
      this.fetch()
    }
  },
  mounted () {
    this.fetch()
  }
})
</script>
