<template>
  <div class="actor-detail__notes-container">
    <content-block
      title="Notes"
      key="notes"
      style="width: 100%; margin-bottom: 0"
    >
      <div class="actor-detail__notes-ui">
        <div class="actor-detail__notes-text-area">
          <rich-text-area v-model="actor.notes"
                          class="announcement-detail-body-style"></rich-text-area>
        </div>
        <div style="margin-top: 1rem;">
          <ds-button @click="save" :label="saving ? 'saving' : 'save'" :disabled="saving"/>
        </div>
      </div>
    </content-block>
  </div>
</template>

<script>
  import ContentBlock from '../../components/ContentBlock/ContentBlock.vue'
  import RichTextArea from '../../components/TextArea/RichTextArea.vue'
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'ActorDetailNotes.vue',
    props: ['actor'],
    data () {
      return {
        saving: false,
        errors: {}
      }
    },
    computed: {
    },
    methods: {
      save () {
        this.saving = true

        this.$store.dispatch('ACTORS/UPDATE_ACTOR',
          {
            id: this.actor.id,
            data: { notes: this.actor.notes },
          })
          .then(() => {
            this.saving = false
          })
          .catch(errors => {
            this.errors = errors
            this.saving = false
          })
      },
    },
    components: {
      ContentBlock,
      RichTextArea,
    },
  })
</script>

<style scoped>
  .actor-detail__notes-container {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: white;
  }

  .actor-detail__notes-ui {
    flex-direction: column;
    display: flex;
  }

  .actor-detail__notes-text-area {
    min-width: 600px;
  }
</style>
