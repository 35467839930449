export function hexToRgb(hex) {
  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  result = result ? {
    r: parseInt(result[1], 16),
    g: parseInt(result[2], 16),
    b: parseInt(result[3], 16)
  } : null;

  if (result) {
    return result.r + ',' + result.g + ',' + result.b
  }
}

// From https://stackoverflow.com/a/9733420
function luminance(r, g, b) {
  let a = [r, g, b].map(function (v) {
    v /= 255;
    return v <= 0.03928
      ? v / 12.92
      : Math.pow((v + 0.055) / 1.055, 2.4);
  });
  return a[0] * 0.2126 + a[1] * 0.7152 + a[2] * 0.0722;
}

function contrast(rgb1, rgb2) {
  return (luminance(rgb1[0], rgb1[1], rgb1[2]) + 0.05)
    / (luminance(rgb2[0], rgb2[1], rgb2[2]) + 0.05);
}

export function doColorsHaveSufficientContrast(hex1, hex2) {
  const rgb1 = hexToRgb(hex1).split(',').map(x => parseInt(x));
  const rgb2 = hexToRgb(hex2).split(',').map(x => parseInt(x));
  return contrast(rgb1, rgb2) > 3;
}
