<template>
  <div>
    <h3 class="h2">{{crmDisplayName}} synchronisation logs</h3>
    <table class="table table--fill table--hover" style="table-layout: fixed">
      <thead>
      <tr>
        <th>Name</th>
        <th>Type</th>
        <th>Synced at</th>
        <th>Success</th>
        <th>Context</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="log in logs" @click="showSidePanel(log.objectId, log.objectType)">
        <td>{{ log.name }}</td>
        <td>{{ log.objectType }}</td>
        <td>{{ log.synced_at }}</td>
        <td>{{ log.succes == true ? 'Yes' : 'No' }}</td>
        <td>{{ humanizeMeta(log.meta) }}</td>
      </tr>
      </tbody>
    </table>
    <pagination class="simple-pagination" :paging="paging" @change="setOffset"/>
  </div>
</template>

<script>
  import Pagination from '../../Pagination/SimplePagination.vue'

  import { fetchSyncLogs } from '../../../api/config'
  import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../../store/modules/ui'

  export default {
    props: ['crmDisplayName', 'crm'],
    name: 'SyncLogs',
    data () {
      return {
        logs: [],
        paging: {
          offset: 20,
        },
        filters: {},
      }
    },
    methods: {
      humanizeMeta (meta) {
        if (typeof meta === 'string') {
          return meta
        } else if (meta && meta.intent) {
          return meta.intent
        } else if (meta && meta.message) {
          return meta.message
        }
      },
      fetchLogs () {
        const logParameters = { ...this.filters }
        logParameters.crm = this.crm

        fetchSyncLogs(logParameters)
          .then(response => {
            this.logs = response.data
            this.paging = response.paging
            this.numberOfResults = response.total
          })
      },
      setOffset (offset) {
        this.filters = Object.assign({}, this.filters, { offset })

        this.fetchLogs()
      },
      showSidePanel (id, type) {
        if (type === 'actor' && id) {
          this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, { component: 'scores', metaData: id })
        }
      },
    },
    components: {
      Pagination,
    },
    mounted () {
      this.fetchLogs()
    },
    watch: {
      crmDisplayName (v) {
        // If the CRM changes, the display name will as well, that's the signal to fetch logs that are applicable to the selected CRM
        this.paging = {
          offset: 20,
        }

        this.fetchLogs()
      },
    },
  }
</script>

<style scoped>

</style>
