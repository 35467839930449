<template>
  <div class="form-group" :class="{ 'form-group--has-error': error, 'is-simplified': isSimplified }">
    <label class="form-group__header" v-if="label !== 'hidden'">
      <span class="form-group__label__text">{{ label }}<span class="required-asterisk" v-if="required"> *</span> <icon size="14" name="information-outline" v-if="infoText" v-tooltip.left="infoText"></icon></span>
      <div class="form-group__instructions" v-if="instructions" v-html="instructions"></div>
      <div class="form-group__instructions" v-if="!!$slots.instructions"><slot name="instructions"></slot></div>
    </label>
    <slot></slot>
    <p class="form-group__error" v-for="msg in err">{{ msg }}</p>
    <p class="form-group__error" v-if="(!err || !err.length) && stableHeight">&nbsp;</p>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'

  export default defineComponent({
    props: {
      label: String,
      error: Array | String,
      errors: Array | String,
      instructions: String,
      stableHeight: Boolean,
      required: Boolean,
      infoText: String,
      isSimplified: {
        type: Boolean,
        default: false,
      }
    },
    computed: {
      err () {
        if (typeof this.errors === 'string') {
          return (this.$parent.errors || this.$parent.$parent.errors || {})[this.errors]
        }
        if (typeof this.error === 'string') {
          return [this.error]
        }
        if (Array.isArray(this.error)) {
          return this.error
        }
        return this.errors
      }
    }
  })
</script>

<style>
  .form-group .suggestion-input {
    margin-bottom: 0;
  }
</style>
