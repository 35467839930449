<template>
  <div class="fixed-heading scrollable">
    <div class="heading">
      <h1 class="score-list__heading">Score list</h1>
      <badge :name="actorCount + ' Actors'" variant="primary" class="score-overview__actor-couter" />
      <p class="guide_text">The score list provides an overview of how all actors are performing in terms of business growth and digital footprint.</p>
    </div>
    <div class="scrollable" ref="scrollable">
      <table class="table table--fill table--hover" style="table-layout: fixed">
        <thead>
        <tr>
          <th>Name</th>
          <th-sort by="digital_footprint">Digital Footprint Score</th-sort>
          <th-sort by="digital_footprint_growth">Digital Footprint growth</th-sort>
          <th-sort by="business_size">Business Size Score</th-sort>
          <th-sort by="business_size_growth">Business Size growth</th-sort>
        </tr>
        </thead>
        <tbody>
          <tr v-for="row in actors" @click="openSidePanel(row)">
            <td>
                <avatar :src="actorLogo(row)" variant="border"></avatar>&nbsp;{{row.name}}
            </td>
            <td>
              {{formatScore(row.digital_footprint)}}
            </td>
            <td>
              {{formatGrowth(row.digital_footprint_growth)}}
            </td>
            <td>
              {{formatScore(row.business_size)}}
            </td>
            <td>
              {{formatGrowth(row.business_size_growth)}}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import numeral from 'numeral'
import ThSort from '../ListView/ThSort.vue'
import Avatar from '../Avatar/Avatar.vue'
import SidePanelActor from '../ActorPreview/SidePanelActor.vue'
import Badge from '../Badge/Badge.vue'

import { fetchScores } from '../../api/heartbeat.js'
import { fetchActor, actorCount } from '../../api/actors.js'

import InfiniteScroll from '../../util/infinite-scroll.js'

import { actorLogo } from '../../util/actor.js'
import { MUTATION_TYPES as UI_MUTATION_TYPES } from '../../store/modules/ui.js'
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'significant-changes',
  data () {
    return {
      offset: 0,
      actors: [],
      paging: {
        limit: 100,
        offset: 0,
        order: '-digital_footprint',
      },
      actorCount: 0
    }
  },
  computed: {
    order () {
      return this.paging.order
    },
    filters () {
      return this.$store.getters.listFilterObject
    },
  },
  methods: {
    actorLogo,
    updateActorCount () {
      actorCount(this.filters).then( number => {
        this.actorCount = number.count
      })
    },
    setOffset (offset) {
      this.paging.offset = offset
      this.fetch()
    },
    toggleOrder (val) {
      if (this.paging.order === val) {
        this.paging.order = '-' + val
      } else {
        this.paging.order = val
      }

      this.fetch()
    },
    fetch () {
      this.hasMore = 0

      const filters = Object.assign({}, this.paging, { offset: 0 }, this.filters)

      fetchScores(filters)
        .then(actors => {
          this.actors = actors.data
          this.hasMore = actors.data.length > 49
        }, this.fetchError)
    },
    fetchMore () {
      if (!this.hasMore) {
        return
      }

      const filters = Object.assign({}, this.paging, { offset: this.actors.length }, this.filters)

      fetchScores(filters)
        .then(actors => {
          this.actors = this.actors.concat(actors.data)
          this.hasMore = actors.data.length > 49
        })
    },
    fetchError (error) {
      //console.log('fetched error', error)
    },
    formatGrowth (growth) {
      return `${numeral(growth * 100).format('0.00a')}` + '%'
    },
    formatScore (score) {
      return `${numeral(score).format('0.00a')}`
    },
    openSidePanel (val) {
      this.$store.commit(UI_MUTATION_TYPES.SHOW_SIDE_PANEL, {component:"scores", metaData: val.id})
    }
  },
  mounted () {
    this.fetch()
    this.inf = new InfiniteScroll(this.$refs.scrollable, this.fetchMore)
    this.$router.replace({ query: this.$store.getters.exploreFilterObject })
    this.updateActorCount()
  },
  beforeUnmount () {
    this.inf.destroy()
    this.$store.commit(UI_MUTATION_TYPES.HIDE_SIDE_PANEL)
  },
  watch: {
    filters: {
      deep: true,
      handler () {
        this.fetch()
        this.updateActorCount()
      }
    }
  },
  components: {
    InfiniteScroll,
    ThSort,
    Avatar,
    SidePanelActor,
    Badge
  }
})
</script>

<style lang="scss">
.score-list__headeing {
  float:left;
}
</style>
